import { AiloRN } from "@ailo/ailorn";
import { useCurrentAgencyOrg, useTimezone } from "local/common";
import { useGetPendingRoutineInspectionAppointmentsQuery } from "local/graphql";
import {
  getInspectionDays,
  InspectionDay
} from "../groupInspectionAppointments";

export interface InspectionDayQueryReturn {
  data: InspectionDay[];
  loading: boolean;
  error: boolean;
}

export function useGetInspectionDays(
  inspectingAgentAilorn: AiloRN
): InspectionDayQueryReturn {
  const timezone = useTimezone();
  const { ailoRN: organisationAilorn } = useCurrentAgencyOrg();

  const { data, loading, error } =
    useGetPendingRoutineInspectionAppointmentsQuery({
      variables: {
        inspectingAgentAilorn: inspectingAgentAilorn.toString(),
        organisationAilorn
      }
    });

  const inspectionAppointments =
    data?.inspectionAppointmentConnection.edges.map(
      (appointmentEdge) => appointmentEdge.node
    ) ?? [];

  return {
    data: getInspectionDays(inspectionAppointments, timezone),
    loading: loading,
    error: !!error
  };
}
