import React, { useCallback, useEffect } from "react";
import { FileKind, InspectionFragment } from "local/graphql";
import { InspectionSynced } from "./InspectionSynced";
import { useEnvironment } from "@ailo/services";
import { InspectionViewUI } from "./InspectionViewUI";
import { Colors, Link, Text } from "@ailo/primitives";
import { useGetClaimedFilesCount } from "local/domain/file";
import { ErrorAlertScreen } from "@ailo/ui";

export function InspectionView({
  actionId,
  projectId,
  inspection
}: {
  actionId: string;
  projectId: string;
  inspection: InspectionFragment;
}): React.ReactElement {
  const { AILO_INSPECTION_APP_LINK } = useEnvironment();
  const openInspectionAppLink = useCallback(() => {
    window.open(AILO_INSPECTION_APP_LINK, "_blank");
  }, [AILO_INSPECTION_APP_LINK]);

  const {
    data: inspectionFilesCount,
    loading,
    error,
    refetch,
    startPolling,
    stopPolling
  } = useGetClaimedFilesCount(inspection.ailorn, FileKind.InspectionFile);

  useEffect(() => {
    if (inspectionFilesCount) {
      const { totalFiles, validFiles } = inspectionFilesCount;
      if (validFiles !== totalFiles) {
        startPolling(5000);
      } else {
        stopPolling();
      }
    }
  }, [inspectionFilesCount, startPolling, stopPolling]);

  if (loading && !inspectionFilesCount) {
    return <Text.Loading variant={"BodyM"} width={100} />;
  }

  if (error || !inspectionFilesCount) {
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the inspection information."}
        onRetry={refetch}
      />
    );
  }

  const { totalFiles, validFiles } = inspectionFilesCount;

  if (validFiles === totalFiles) {
    return (
      <InspectionSynced
        actionId={actionId}
        projectId={projectId}
        inspection={inspection}
        inspectionFilesCount={inspectionFilesCount}
      />
    );
  }

  return (
    <InspectionViewUI
      inspection={inspection}
      inspectionFilesCount={inspectionFilesCount}
      description={
        <Text.BodyM weight={"medium"}>
          {"Check the "}
          <Link onPress={openInspectionAppLink} variant={"primary"}>
            {"Ailo Inspect"}
          </Link>
          {
            " app on your phone for syncing progress. Connect your phone Wi-Fi to speed up the upload."
          }
        </Text.BodyM>
      }
      footer={
        <Text.BodyS
          style={{ marginTop: 12 }}
          color={Colors.TEXT.DARK.SECONDARY}
        >
          {"You’ll be able to create PDF once the sync is complete."}
        </Text.BodyS>
      }
      button={{
        label: "Create PDF",
        disabled: true
      }}
    />
  );
}
