import { Colors, Text } from "@ailo/primitives";
import React, { useMemo } from "react";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { TeamListItem } from "./TeamListItem";
import { SpecialTeamListItem } from "./SpecialTeamListItem";
import { useTeamGroups } from "./useTeamGroups";
import { ChatTeam, ChatTeamId, SpecialTeamId } from "@ailo/domains";

interface TeamGroup {
  label: string;
  teams: ChatTeam[];
}

interface Props {
  onSelect: (teamId: ChatTeamId) => void;
  selectedTeamId?: ChatTeamId;
  teams: ChatTeam[];
}

function createTeamOptionGroups({
  teamGroups,
  selectedTeamId,
  onSelect
}: {
  teamGroups: TeamGroup[];
  selectedTeamId?: string;
  onSelect: (teamId: string) => void;
}): React.ReactElement[] {
  return teamGroups.map((teamGroup, teamGroupIndex) => (
    <FilterGroupWrapper key={`TeamGroup ${teamGroupIndex}`}>
      <FilterGroupLabel color={Colors.TEXT.DARK.SECONDARY}>
        {teamGroup.label}
      </FilterGroupLabel>

      {teamGroup.teams.map((team) => (
        <TeamListItem
          key={team.ailorn.internalId}
          team={team}
          selectedTeamId={selectedTeamId}
          onSelect={onSelect}
        />
      ))}

      {teamGroupIndex < teamGroups.length - 1 && <Divider />}
    </FilterGroupWrapper>
  ));
}

export function FilterSelector({
  onSelect,
  selectedTeamId,
  teams
}: Props): React.ReactElement {
  const teamGroups = useTeamGroups(teams);
  const teamOptionGroups = useMemo(
    () => createTeamOptionGroups({ teamGroups, selectedTeamId, onSelect }),
    [onSelect, selectedTeamId, teamGroups]
  );

  return (
    <ScrollView style={{ backgroundColor: Colors.WHITE, flex: 1 }}>
      <ChatFilterWrapper>
        {[SpecialTeamId.MyChats, SpecialTeamId.AllChats].map((teamId) => (
          <SpecialTeamListItem
            key={teamId}
            teamId={teamId}
            selectedTeamId={selectedTeamId}
            onSelect={onSelect}
          />
        ))}
        <Divider />
      </ChatFilterWrapper>

      {teamOptionGroups}
    </ScrollView>
  );
}

const ChatFilterWrapper = styled(View)`
  padding-top: 10px;
  padding-left: 8px;
  padding-right: 8px;
`;

const FilterGroupWrapper = styled(View)`
  padding-top: 24px;
  padding-left: 8px;
  padding-right: 8px;
`;

const FilterGroupLabel = styled(Text.Subheading)`
  margin-left: 16px;
  margin-bottom: 8px;
`;

const Divider = styled(View)`
  height: 1px;
  background-color: ${Colors.GRAYSCALE.OUTLINE};
  margin-left: 16px;
  margin-top: 20px;
  margin-right: -8px;
`;
