import React from "react";
import { PersonAvatar } from "@ailo/ui";
import { ActionDetailsFragment } from "local/graphql";
import { View } from "react-native";

export function ActionCardFooterAvatar({
  assignee
}: {
  assignee: ActionDetailsFragment["assignee"];
}): React.ReactElement | null {
  if (!assignee) {
    return null;
  }

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        flex: 1
      }}
    >
      <PersonAvatar person={assignee} size={20} />
    </View>
  );
}
