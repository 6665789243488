import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AFC } from "@ailo/primitives";
import { BondDetailsFormData } from "./BondDetailsFormData";

const BondDetailsFormContext: AFC = ({ children }) => {
  const formMethods = useForm<BondDetailsFormData>({
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
};

export { BondDetailsFormContext };
