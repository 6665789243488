import React, { memo, ReactElement, useCallback } from "react";
import { ReceiptBaseModal } from "../../components";
import { useForm, FormProvider } from "react-hook-form";
import { WalletReceiptMainForm } from "./WalletReceiptMainForm";
import { CreateReceiptFormData } from "../../types";
import { ModalVisibility } from "@ailo/ui";
import { WalletReceiptAllocationsInput } from "local/tabs/trustaccounting/transactions/TransactionsScreen/WalletReceiptModal/WalletReceiptAllocationsInput";

function WalletReceiptModalComponent(props: {
  visibility: ModalVisibility<void>;
}): ReactElement {
  const defaultValues = {};
  const form = useForm<CreateReceiptFormData>({
    defaultValues,
    mode: "onBlur"
  });
  const renderAllocations = useCallback(
    (): React.ReactElement => <WalletReceiptAllocationsInput />,
    []
  );

  return (
    <FormProvider {...form}>
      <ReceiptBaseModal
        {...props}
        title={"New Wallet Receipt"}
        renderAllocationsComponent={renderAllocations}
      >
        <WalletReceiptMainForm />
      </ReceiptBaseModal>
    </FormProvider>
  );
}

export const WalletReceiptModal = memo(WalletReceiptModalComponent);
