import { EditIcon, Text } from "@ailo/primitives";
import React from "react";
import { View } from "react-native";
import { Button } from "@ailo/ui";

interface Props {
  title: string;
  onEditPress?(): void;
  children?: React.ReactNode;
}

export function BaseReviewDetailsSection({
  title,
  onEditPress,
  children
}: Props): React.ReactElement {
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 20
        }}
      >
        <Text.BodyL weight={"medium"}>{title}</Text.BodyL>
        <Button.Text leftIcon={EditIcon} onPress={onEditPress}>
          {"Edit"}
        </Button.Text>
      </View>
      {children}
    </View>
  );
}
