import { Text } from "@ailo/primitives";
import { Card } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Section } from "../../VisualizationScreen/reportsDetails";
import { ReportCard } from "./ReportCards";

export function SectionCard({ title, reportList }: Section): ReactElement {
  const reportComponentList = reportList.map((report, index) => {
    const isLastRow = reportList.length - 1 === index;
    return (
      <ReportCard
        key={report.reportHeading}
        isLastRow={isLastRow}
        report={report}
      />
    );
  });
  return (
    <Card
      key={title}
      style={{ marginHorizontal: 0, marginBottom: 6, height: "fit-content" }}
    >
      <Text.DisplayS
        style={{ paddingTop: 16, paddingLeft: 16, paddingBottom: 12 }}
      >
        {title}
      </Text.DisplayS>
      {reportComponentList}
    </Card>
  );
}
