import {
  AiloSentry,
  MutationResultDataMissingError,
  useAnalytics
} from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import { MutationResult } from "@apollo/client";
import {
  ManagementAgreementFragment,
  UpdateManagementAgreementMutation,
  useUpdateManagementAgreementMutation
} from "local/graphql";
import { useCallback } from "react";

export type SetInspectionFrequencyInput = {
  managementAgreementId: string;
  inspectionFrequency: string;
};

export function useSetInspectionFrequency({
  onCompleted,
  onError
}: {
  onCompleted?: (managementAgreement: ManagementAgreementFragment) => void;
  onError?: (error?: unknown) => void;
}): [
  ({
    managementAgreementId,
    inspectionFrequency
  }: SetInspectionFrequencyInput) => Promise<void>,
  MutationResult<UpdateManagementAgreementMutation>
] {
  const analytics = useAnalytics();
  const toast = useToastContext();
  const [updateManagementAgreement, result] =
    useUpdateManagementAgreementMutation();

  const setInspectionFrequency = useCallback(
    async ({
      managementAgreementId,
      inspectionFrequency
    }: SetInspectionFrequencyInput): Promise<void> => {
      try {
        const result = await updateManagementAgreement({
          variables: {
            input: {
              id: managementAgreementId,
              inspectionFrequency
            }
          }
        });

        const managementAgreement =
          result.data?.updateManagementAgreement.managementAgreement;
        if (!managementAgreement) {
          throw new MutationResultDataMissingError(
            "updateManagementAgreement",
            result
          );
        }
        await onCompleted?.(managementAgreement);
        toast.show({
          type: "success",
          message: "Inspection frequency updated"
        });
      } catch (error) {
        AiloSentry.captureException(error);
        toast.show({
          type: "error",
          message: "Error updating inspection frequency"
        });
        return onError?.(error);
      }

      analytics.trackInspectionFrequencyUpdated();
    },
    [updateManagementAgreement, onCompleted, onError, analytics, toast]
  );

  return [setInspectionFrequency, result];
}
