import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { DescriptionList, Separator } from "@ailo/ui";
import { AgreementDetailsSection } from "./AgreementDetailsSection";
import { OwnerDetailsSection } from "./OwnerDetailsSection";
import { useGetManagementDetailsForActionQuery } from "local/graphql";
import { formatAddress } from "@ailo/domain-helpers";

export function DisplayManagementDetails({
  managementId
}: {
  managementId: string;
}): ReactElement {
  const { data, loading } = useGetManagementDetailsForActionQuery({
    variables: { id: managementId }
  });

  if (loading) {
    return <></>;
  }

  const management = data?.management;

  if (!management) {
    return <></>;
  }

  const { managementAgreements, ownerships, property } = management;

  const initialManagementAgreement = managementAgreements?.items[0];
  const agreementStartDate = initialManagementAgreement?.startDate;
  const agreementEndDate = initialManagementAgreement?.fixedTermEndDate;
  const managementFeePercent =
    initialManagementAgreement?.managementFeeSchedules?.items[0]?.percent;

  const owners = ownerships?.items;

  return (
    <>
      <DescriptionList
        size={"small"}
        items={[
          {
            label: "Address",
            descriptionBottom: formatAddress(property.address) || "N/A"
          }
        ]}
      />
      <StyledSeparator />
      <AgreementDetailsSection
        agreementStartDate={agreementStartDate}
        agreementEndDate={agreementEndDate}
        managementFeePercent={managementFeePercent}
      />
      {owners && (
        <>
          <StyledSeparator />
          <OwnerDetailsSection owners={owners} />
        </>
      )}
      <StyledSeparator />
    </>
  );
}

const StyledSeparator = styled(Separator)`
  margin-top: 40px;
  margin-left: 0;
  margin-right: -32px;
`;
