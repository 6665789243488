import { EditIcon } from "@ailo/primitives";
import { DropdownMenuOption } from "@ailo/ui";
import React from "react";

export function getEditLeaseRenewalOption({
  onSelect,
  disabled
}: {
  onSelect: () => void;
  disabled: boolean;
}): DropdownMenuOption {
  return {
    label: "Edit lease renewal",
    icon: <EditIcon />,
    onSelect,
    disabled
  };
}
