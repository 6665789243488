import { SharedNavigationSetup } from "@ailo/domains";
import React, { ReactElement, ReactNode } from "react";
import { generateLinkToProperty } from "local/domain/propertyManagement";
import { generateLinkToStartNewChat } from "local/tabs/comms/navigation";

function AgencyWebSharedNavigationSetupComponent({
  children
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <SharedNavigationSetup
      property={generateLinkToProperty}
      startNewChat={generateLinkToStartNewChat}
    >
      {children}
    </SharedNavigationSetup>
  );
}

export const AgencyWebSharedNavigationSetup = React.memo(
  AgencyWebSharedNavigationSetupComponent
);
