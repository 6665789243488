import { MaybeFileDetails } from "@ailo/domains";
import { Separator } from "@ailo/ui";
import React from "react";
import { ManagementFileAttachments } from "./ManagementFileAttachments";
import { ManagementSummary } from "./ManagementSummary";
import { ManagementData, TeamData } from "./useGetManagementDetailsAndTeams";

export function ManagementSidebarDetails({
  managementData,
  teamData,
  files
}: {
  managementData: ManagementData;
  teamData: TeamData;
  files: MaybeFileDetails[];
}): React.ReactElement {
  return (
    <>
      <ManagementSummary managementData={managementData} teamData={teamData} />

      <Separator
        style={{
          marginTop: 24,
          marginLeft: 0,
          marginRight: -32,
          marginBottom: 32
        }}
      />

      <ManagementFileAttachments
        managementId={managementData.managementId}
        files={files}
      />
    </>
  );
}
