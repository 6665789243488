import { DisbursementPeriods } from "./DisbursementPeriods";

import { SelectInput } from "@ailo/ui";
import React from "react";
import { View } from "react-native";

export function DisbursementPeriodFilter({
  selectedDisbursementPeriod,
  onChange
}: {
  selectedDisbursementPeriod?: { value: string; label: string };
  onChange?: ({ value, label }?: { value: string; label: string }) => void;
}): React.ReactElement {
  const disbursementPeriods = DisbursementPeriods();
  const viewStyle = { width: 400 };

  if (!(disbursementPeriods.length > 0)) {
    return (
      <View style={viewStyle}>
        <SelectInput.Placeholder
          component
          placeholder={"Loading transfer periods..."}
        />
      </View>
    );
  }
  const disbursementPeriodSelections = disbursementPeriods.map((d) => {
    return { value: d.disbursementPeriodSk, label: d.disbursementDisplayName };
  });
  return (
    <View style={viewStyle}>
      <SelectInput
        value={selectedDisbursementPeriod}
        onChange={onChange}
        placeholder={"Select transfer period..."}
        options={disbursementPeriodSelections}
        useTextInputHeight
      />
    </View>
  );
}
