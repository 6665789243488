import React from "react";
import { TenancyInspectionScheduleFragment } from "local/graphql";
import { DescriptionList } from "@ailo/ui";
import { getInspectionScheduleDescriptionListItems } from "./getInspectionScheduleDescriptionListItems";

interface Props {
  inspectionSchedule: TenancyInspectionScheduleFragment;
}

export function InspectionScheduleDescriptionList({
  inspectionSchedule
}: Props): React.ReactElement {
  return (
    <DescriptionList
      size={"small"}
      items={getInspectionScheduleDescriptionListItems(inspectionSchedule).map(
        (item) => ({ label: item.label, descriptionBottom: item.description })
      )}
    />
  );
}
