import { Money, TrendCell } from "@ailo/ui";
import React, { ReactElement } from "react";

const formatTrendDollars = (dollars: number | null | undefined): string => {
  if (dollars == null) return "—";
  return (dollars > 0 ? "+" : "") + Money.fromDollars(dollars).format();
};

export const TrendValue = ({
  dollars,
  changePct
}: {
  dollars?: number | null;
  changePct?: number | null;
}): ReactElement => {
  return (
    <TrendCell value={formatTrendDollars(dollars)} changePercent={changePct} />
  );
};
