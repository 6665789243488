import React, { ReactElement } from "react";
import { View } from "react-native";
import { PrimaryText } from "local/tabs/properties/components/TenancyBondScreen/components/PrimaryText";
import { SecondaryText } from "local/tabs/properties/components/TenancyBondScreen/components/SecondaryText";
import { Money } from "@ailo/ui";
import { DateTimeWithTimeZone } from "@ailo/date";

export function ClaimStepRecordedClaim({
  amountClaimed,
  bondAmount
}: {
  amountClaimed: Money;
  bondAmount: Money;
}): ReactElement {
  const claimSetupDate = DateTimeWithTimeZone.now().format("D MMM YYYY");

  const amountToRenters = Money.fromCents(
    bondAmount.cents - amountClaimed.cents
  );

  return (
    <View style={{ paddingVertical: 20 }}>
      <PrimaryText weight={"medium"}>
        {"We’ve recorded the claim in Ailo"}
      </PrimaryText>
      <SecondaryText>
        {"Requested by agency: " + amountClaimed.format()}
      </SecondaryText>
      <SecondaryText>
        {"Release to renters: " + amountToRenters.format()}
      </SecondaryText>
      <SecondaryText style={{ marginTop: 12 }}>{claimSetupDate}</SecondaryText>
    </View>
  );
}
