import React from "react";
import { AiloIcon, Pressable, SFC } from "@ailo/primitives";
import { Screens, useNavigation } from "local/common";

const DrawerHeader: SFC = ({ style }) => {
  const { navigate } = useNavigation();
  const onPress = (): void => navigate(Screens.AnalyticsDashboardTab);

  return (
    <Pressable style={style} onPress={onPress}>
      <AiloIcon style={{ marginTop: 32, marginLeft: 24 }} />
    </Pressable>
  );
};

export { DrawerHeader };
