import { useHasFeature } from "@ailo/domains";
import {
  GetManagementOwnerEndDetailsQuery,
  PlatformFeatureId
} from "local/graphql";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { ManageMenu } from "./ManageMenu";
import { MessageContactsIconButton } from "../PropertyContentCard";
import styled from "styled-components/native";
import { AiloRN } from "@ailo/ailorn";

interface Props {
  management: GetManagementOwnerEndDetailsQuery["management"];
  participantContactAilorns: AiloRN<"contact:contact">[];
}

export function ManagementCardTitleRight({
  management,
  participantContactAilorns
}: Props): ReactElement | null {
  const hasChatFeature = useHasFeature(PlatformFeatureId.Chat);

  if (!management) return null;

  const manageMenu = <ManageMenu managementEndDetails={management} />;

  if (!hasChatFeature || participantContactAilorns.length === 0) {
    return manageMenu;
  }

  return (
    <TitleRightContainer>
      <MessageContactsIconButton
        participantContactAilorns={participantContactAilorns}
      />
      <VerticalSeparator />
      {manageMenu}
    </TitleRightContainer>
  );
}

const TitleRightContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const VerticalSeparator = styled(View)`
  border-right-style: solid;
  border-right-color: rgba(28, 30, 38, 0.2);
  border-right-width: 1px;
  height: 20px;
  margin-left: 20px;
  margin-right: 20px;
`;
