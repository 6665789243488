import { formatAddress } from "@ailo/domain-helpers";
import { SelectOption } from "@ailo/ui";

interface ActiveManagement {
  managementId: string;
  address?: {
    unitStreetNumber?: string | null;
    streetName?: string | null;
    suburb?: string | null;
  } | null;
}

export const convertManagementToSelectOption = (
  item: ActiveManagement
): SelectOption => {
  const address = item?.address;

  if (!address) return { value: "", label: "" };

  const label = formatAddress(address, {
    format: "street, suburb"
  });
  return {
    value: item.managementId,
    label: label || ""
  };
};
