import React, { ReactElement } from "react";
import { ManagementPortfolioListItem } from "../ManagementPortfolioListItem";
import { Badge, BriefcaseAvatar, PersonAvatar } from "@ailo/ui";
import { PersonVerificationIcon } from "local/domain/authz";
import { formatPersonName } from "@ailo/domain-helpers";
import { Colors } from "@ailo/primitives";

export type ManagementPortfolioOwner = { id: string; isPrimary: boolean } & (
  | PersonOwner
  | CompanyOwner
);

type PersonOwner = {
  __typename: "Person";
  firstName: string;
  lastName?: string | null;
  photo?: null | {
    url?: string | null;
    thumbnailUrl?: string | null;
  };
  isVerified: boolean;
};

type CompanyOwner = {
  __typename: "Company";
  registeredEntityName: string;
};

interface Props {
  owner: ManagementPortfolioOwner;
  isLastItem: boolean;
}

export function ManagementPortfolioOwnersListItem({
  owner,
  isLastItem
}: Props): ReactElement {
  const isPerson = owner.__typename === "Person";
  const leftContent = isPerson ? (
    <PersonAvatar
      size={40}
      person={owner}
      initialsColor={Colors.TEXT.LIGHT.PRIMARY}
    />
  ) : (
    <BriefcaseAvatar size={40} />
  );

  return (
    <ManagementPortfolioListItem
      leftContent={leftContent}
      rightContent={owner.isPrimary ? <Badge>{"Primary"}</Badge> : undefined}
      isLastItem={isLastItem}
    >
      {isPerson ? (
        <>
          {formatPersonName(owner)}
          <PersonVerificationIcon
            verified={owner.isVerified}
            height={20}
            width={20}
            key={0}
          />
        </>
      ) : (
        owner.registeredEntityName
      )}
    </ManagementPortfolioListItem>
  );
}
