import { ExpenseStatusBadge } from "@ailo/domains";
import { useSynchronisedLoad } from "@ailo/primitives";
import { useOnFocus } from "@ailo/services";
import { CellContainer, GridRow, LocalDate, Money } from "@ailo/ui";
import React from "react";
import styled from "styled-components/native";
import { Address, DueDate, Payer, Supplier, Total } from "./components";
import { useGetBillByIdQuery } from "local/graphql";
import { billPayerNames } from "local/domain/bill/billPayerNames";

export type ColWidths = [number, number, number, number, number, number];

interface GridRowProps {
  gridColWidths: ColWidths;
  billId: string;
  index: number;
  isLastRow?: boolean;
  onRowPress?(): void;
}

interface Statics {
  Loading: typeof Loading;
}

const BillGridRow: React.FC<GridRowProps> & Statics = ({
  gridColWidths,
  billId,
  index,
  isLastRow,
  onRowPress,
  ...rest
}) => {
  const { loading, data, refetch } = useGetBillByIdQuery({
    variables: { billId }
  });

  useOnFocus(refetch);

  if (useSynchronisedLoad(loading)) {
    return <Loading gridColWidths={gridColWidths} />;
  }

  const managementId = data?.billById?.management?.id;

  const dueDate = data?.billById?.dueDateV2;
  if (!dueDate) throw Error("Cannot find due date");

  const amountInCents = data?.billById?.amount?.cents;
  if (!amountInCents) throw Error("Cannot find amount");

  const supplierName =
    data?.billById?.supplier?.internalLegalEntity?.organisation?.name ||
    data?.billById?.supplier?.name;

  const payers = billPayerNames(data?.billById?.payer).map((p) => p.name);

  if (supplierName == null) throw Error("Cannot find supplier name");

  const agencyStatus = data?.billById?.agencyStatus;
  if (!agencyStatus) throw Error("Cannot find agencyStatus");

  return (
    <StyledGridRow
      {...rest}
      colWidths={gridColWidths}
      key={index}
      isLastRow={!!isLastRow}
      onPress={onRowPress}
      testID={`bill-grid-row-${index}`}
    >
      <DueDate
        date={LocalDate.from(dueDate)}
        isFirstRow={index === 0}
        isLastRow={!!isLastRow}
      />
      <Address managementId={managementId!} />
      <Supplier name={supplierName} />
      <Payer name={payers.join(", ")} />
      <CellContainer>
        <ExpenseStatusBadge status={agencyStatus} />
      </CellContainer>
      <Total amount={Money.fromCents(amountInCents)} testID={`amount-text`} />
    </StyledGridRow>
  );
};

const Loading: React.FC<{
  gridColWidths: ColWidths;
}> = ({ gridColWidths }) => {
  return (
    <>
      {[1, 2, 3].map((index) => (
        <StyledGridRow colWidths={gridColWidths} key={index} isLastRow={true}>
          <DueDate.Loading isFirstRow={index === 1} isLastRow={index === 3} />
          <Address.Loading />
          <Supplier.Loading />
          <Payer.Loading />
          <CellContainer>
            <ExpenseStatusBadge.Loading />
          </CellContainer>
          <Total.Loading />
        </StyledGridRow>
      ))}
      <StyledGridRowEmpty colWidths={[1]} />
    </>
  );
};

BillGridRow.Loading = Loading;

const StyledGridRow = styled(GridRow)<{ isLastRow: boolean }>`
  height: 72px;
  align-items: center;
  ${({ isLastRow }): string => (isLastRow ? "border-bottom-width: 0" : "")}
`;

const StyledGridRowEmpty = styled(GridRow)`
  height: 1584px;
`;

export { BillGridRow };
