import { formatLegalEntityName } from "@ailo/domain-helpers";
import { BillPayerType } from "@ailo/domains";
import { isPresent } from "ts-is-present";

type LegalEntityForName =
  | {
      __typename: "Person";
      firstName: string;
      lastName?: string | null;
    }
  | {
      __typename: "Company";
      registeredEntityName: string;
    };

type Tenancy = {
  __typename: "Tenancy";
  tenantships?: {
    items?: { tenant?: LegalEntityForName | null }[];
  } | null;
};

type Management = {
  __typename: "Management";
  ownerships?: { items: { owner?: LegalEntityForName | null }[] } | null;
};

type LegalEntityCompanion = {
  __typename: "LegalEntityCompanion";
};

export type BillPayerForNames = Tenancy | Management | LegalEntityCompanion;

/** returns a list of payer names and what type they are */
export function billPayerNames(payer: BillPayerForNames | undefined | null): {
  entityType: BillPayerType;
  name: string;
}[] {
  const payers =
    payer?.__typename === BillPayerType.Tenancy
      ? (payer.tenantships?.items ?? [])
          .map((tenantship) => tenantship?.tenant)
          .filter(isPresent)
      : payer?.__typename === BillPayerType.Management
      ? (payer.ownerships?.items ?? [])
          .map((ownership) => ownership?.owner)
          .filter(isPresent)
      : [];

  return extractName(payers, payer?.__typename as BillPayerType);
}

function extractName(
  payers: LegalEntityForName[],
  payerType?: BillPayerType
): {
  entityType: BillPayerType;
  name: string;
}[] {
  return payers.filter(isPresent).map((payer) => ({
    entityType: payerType ?? BillPayerType.LegalEntity,
    name: formatLegalEntityName(payer)
  }));
}
