import { SFC } from "@ailo/primitives";
import { DateInputFormField, DateTimeWithTimeZone } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { View } from "react-native";

export const IssueDateInput: SFC = ({ style }) => {
  const { control, watch, setValue, errors } =
    useFormContext<AddBillFormData>();
  const issueDate = watch("issueDate");
  const dueDate = watch("dueDate");

  useEffect(() => {
    if (issueDate && !dueDate) {
      const nextDueDate = DateTimeWithTimeZone.fromLocalDate(issueDate)
        .add(31, "days")
        .toLocalDateString();
      setValue("dueDate", nextDueDate, { shouldValidate: true });
    }
  }, [dueDate, issueDate, setValue]);

  return (
    <View style={style}>
      <Controller
        render={({ value, onBlur, onChange, ref }): React.ReactElement => (
          <DateInputFormField
            inputRef={ref}
            onBlur={onBlur}
            error={errors.issueDate?.message}
            onChange={(date): void => onChange(date ?? null)}
            onFocus={() => {
              setValue("fieldUnderInvoiceNumberWasFocused", true);
            }}
            label={"Issue Date"}
            placeholder={"Issue Date"}
            autoCorrect={false}
            autoCompleteType={"off"}
            value={value}
            maxDate={dueDate ?? undefined}
            testID={"IssueDateInputFormField"}
          />
        )}
        name={"issueDate"}
        defaultValue={null}
        control={control}
        rules={{
          required: "Enter issue date"
        }}
      />
    </View>
  );
};
