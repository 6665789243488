import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { useCallback } from "react";
import {
  ReopenProjectMutation,
  useReopenProjectMutation,
  GetManagementProjectsDocument,
  ProjectDetailsFragment
} from "local/graphql";

export function useReopenProject({
  onCompleted,
  onError
}: {
  onCompleted: ({ project }: { project: ProjectDetailsFragment }) => void;
  onError: () => void;
}): [
  (id: string, managementId?: string) => void,
  MutationResult<ReopenProjectMutation>
] {
  const [reopenProjectMutation, result] = useReopenProjectMutation();

  const reopenProject = useCallback(
    async (id: string, managementId?: string): Promise<void> => {
      let result: ExecutionResult<ReopenProjectMutation>;

      try {
        result = await reopenProjectMutation({
          variables: { id },
          refetchQueries: managementId
            ? [
                {
                  query: GetManagementProjectsDocument,
                  variables: { managementId }
                }
              ]
            : []
        });
        throwIfMutationFailed(result, { dataKey: "reopenProject" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }
      const reopenProject = result.data?.reopenProject;
      if (!reopenProject) {
        throw new Error("Something went wrong, cannot reopen project");
      }

      onCompleted({ project: reopenProject });
    },
    [reopenProjectMutation, onCompleted, onError]
  );

  return [reopenProject, result];
}
