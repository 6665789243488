import { useCurrentAgencyOrg } from "local/common";
import { partition, sortBy } from "lodash";
import { useCallback, useMemo } from "react";
import { useGetTeamsByOrganisationQuery } from "local/graphql";
import { AiloRN } from "@ailo/ailorn";

export const UnassignedOption = { value: "Unassigned", label: "Unassigned" };
interface Team {
  myTeam: boolean;
  name: string;
  id: string;
}

export interface Option {
  label: string;
  value: string;
}
export interface OptionGroup {
  label: string;
  options: Option[];
}

interface ReturnType {
  optionGroups: OptionGroup[];
  selectedOptions: Option[];
  loading: boolean;
  error: boolean;
  refetch: () => Promise<void>;
}

export function useGetDataForTeamsFilter(
  selectedTeamIds: string[],
  unassignedSelected: boolean
): ReturnType {
  const { id: organisationId } = useCurrentAgencyOrg();

  const {
    data,
    loading,
    error,
    refetch: refetchData
  } = useGetTeamsByOrganisationQuery({
    variables: {
      organisationId,
      organisationAilorn: AiloRN.of(
        "authz:organisation",
        organisationId
      ).toString()
    }
  });

  const refetch = useCallback(async () => {
    await refetchData({ organisationId });
  }, [organisationId, refetchData]);

  const teams = data?.organisation?.teams;
  const unassignedPropertyCount = data?.managementsForOrg?.pageInfo.total;

  const selectedOptions = useMemo(() => {
    const selectedTeamOptions = (teams || [])
      .filter(({ id }) => selectedTeamIds.includes(id))
      .map(({ id, name }) => ({ value: id, label: name }));

    return unassignedSelected && unassignedPropertyCount
      ? [...selectedTeamOptions, UnassignedOption]
      : selectedTeamOptions;
  }, [teams, unassignedPropertyCount, selectedTeamIds, unassignedSelected]);

  const optionGroups = useMemo(
    () => teamsToOptionGroups(teams, unassignedPropertyCount),
    [teams, unassignedPropertyCount]
  );

  return {
    selectedOptions,
    optionGroups,
    loading,
    error: !!error,
    refetch
  };
}

function teamsToOptionGroups(
  teams: Team[] = [],
  unassignedPropertyCount: number = 0
): OptionGroup[] {
  const [myTeams, otherTeams] = partition(sortBy(teams, "name"), "myTeam");

  return [
    {
      label: "My teams",
      options: myTeams.map(teamToDropdownOption)
    },
    {
      label: "Other",
      options: unassignedPropertyCount > 0 ? [UnassignedOption] : []
    },
    {
      label: "Teams",
      options: otherTeams.map(teamToDropdownOption)
    }
  ].filter((optionGroup) => optionGroup.options.length);
}

function teamToDropdownOption(team: Team): Option {
  return {
    value: team.id,
    label: team.name
  };
}
