import React from "react";
import { Text } from "@ailo/primitives";

interface Props {
  title: string;
  children?: React.ReactNode;
}

export function SidebarSection({ title, children }: Props): React.ReactElement {
  return (
    <>
      <Text.BodyL weight={"medium"} style={{ marginBottom: 16 }}>
        {title}
      </Text.BodyL>
      {children}
    </>
  );
}
