import React, { ReactElement } from "react";
import {
  ReiFormDeletedAlert,
  ActionRequiredListItem,
  DefaultRequestSignatureActionDescription
} from "local/domain/project";
import { BaseActionActivity } from "../../BaseActionActivity";

interface Props {
  formDeleted?: boolean;
}

export function NoFormFinalisedView({
  formDeleted = false
}: Props): ReactElement {
  return (
    <BaseActionActivity
      description={<DefaultRequestSignatureActionDescription />}
    >
      {formDeleted && <ReiFormDeletedAlert />}
      <ActionRequiredListItem
        header={"No form has been finalised"}
        subHeader={"Create and finalise a form before returning to this action"}
      />
    </BaseActionActivity>
  );
}
