import React, { ReactElement } from "react";
import { Button, Spinner } from "@ailo/ui";
import { Colors } from "@ailo/primitives";

export function RefreshInProgressButton(): ReactElement {
  return (
    <Button.Secondary
      type={"small"}
      leftIcon={() => (
        <Spinner
          size={20}
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ marginRight: 4 }}
        />
      )}
      contentStyle={{
        alignItems: "center",
        justifyContent: "space-between",
        minWidth: 86,
        paddingRight: 8
      }}
      style={{ paddingVertical: 3, paddingHorizontal: 8 }}
      contentColor={Colors.TEXT.DARK.SECONDARY}
    >
      {"Data Refreshing…"}
    </Button.Secondary>
  );
}
