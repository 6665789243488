import moment from "moment";

interface AddressData {
  streetName?: string | null;
  suburb?: string | null;
  unitStreetNumber?: string | null;
}

const getStreetAndStreetNumber = (address: AddressData): string => {
  if (!address.unitStreetNumber) throw Error("Cannot find unit street number");
  if (!address.streetName) throw Error("Cannot find street name");

  return `${address.unitStreetNumber} ${address.streetName}`;
};

const hasLeaseEnded = (tenancyEndDate?: string | null): boolean => {
  const now = moment();
  return !!tenancyEndDate && moment(tenancyEndDate).isBefore(now, "day");
};

export { getStreetAndStreetNumber, hasLeaseEnded };
