import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError, BaseQueryOptions } from "@apollo/client";
import {
  GetPropertyKeyQueryVariables,
  KeyFragment,
  useGetPropertyKeyQuery
} from "local/graphql";

export function useGetPropertyKey(
  id: string,
  fetchPolicy?: BaseQueryOptions["fetchPolicy"]
): MappedQueryResult<KeyFragment, GetPropertyKeyQueryVariables> {
  const result = useGetPropertyKeyQuery({
    variables: {
      id
    },
    fetchPolicy
  });

  return useMappedQueryResult(result, (data) => {
    if (!data?.key?.id) {
      throw new ApolloError({
        errorMessage: `[Management property key] Key "${id}" not found`
      });
    }

    return data.key;
  });
}
