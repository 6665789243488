import React, { PropsWithChildren } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { Colors, opacify } from "@ailo/primitives";

export function SelectedRowsHeader({
  style,
  children
}: PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
}>): React.ReactElement {
  return <Container style={style}>{children}</Container>;
}

const Container = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  align-items: center;
  background-color: ${Colors.OCEAN};
  border-bottom-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`;
