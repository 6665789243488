import { Badge } from "@ailo/ui";
import React, { ComponentProps } from "react";

type Props = ComponentProps<typeof Badge> & { locked: boolean };

export function LockedStatusBadge({
  children,
  locked,
  ...props
}: Props): React.ReactElement {
  const label = locked ? "Locked" : "Open";
  const type = locked ? "success" : "attention";
  return (
    <Badge type={type} {...props}>
      {children ?? label}
    </Badge>
  );
}
