import { ErrorAlertScreen, useGlobalModal } from "@ailo/ui";
import {
  TenancyFragment,
  useGetManagementDetailsForAssignTenancyActionQuery
} from "local/graphql";
import { TenancyModalForm } from "local/modals/TenancyModalForm";
import React, { ReactElement } from "react";
import { BaseActionActivity } from "../../BaseActionActivity";

export function CreateTenancyView({
  managementId,
  onCreateSuccess
}: {
  managementId: string;
  onCreateSuccess: (tenancy: TenancyFragment) => void;
}): ReactElement {
  const modal = useGlobalModal();
  const { data, loading } = useGetManagementDetailsForAssignTenancyActionQuery({
    variables: {
      managementId
    }
  });

  if (loading) {
    return <></>;
  }

  const management = data?.management;

  if (!management) {
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the action information."}
      />
    );
  }

  const ingoingTenancy = management.nextTenancy;
  const currentTenancy = management.currentTenancy;

  const disabledTooltipText = getDisabledTooltipText({
    currentTenancyId: currentTenancy?.id,
    currentTenancyEndDate: currentTenancy?.endDate,
    ingoingTenancyId: ingoingTenancy?.id,
    managementEndDate: management.endDate
  });

  return (
    <BaseActionActivity
      button={{
        label: "Set up new tenancy",
        onPress: () => {
          modal.show(
            <TenancyModalForm
              managementId={managementId}
              onDismiss={modal.hide}
              onSuccess={onCreateSuccess}
            />
          );
        },
        disabled: !!disabledTooltipText,
        tooltipText: disabledTooltipText
      }}
      description={"Enter the details for the new tenancy"}
    />
  );
}
function getDisabledTooltipText({
  currentTenancyId,
  currentTenancyEndDate,
  ingoingTenancyId,
  managementEndDate
}: {
  currentTenancyId?: string | null;
  currentTenancyEndDate?: string | null;
  ingoingTenancyId?: string;
  managementEndDate?: string | null;
}): string | undefined {
  if (managementEndDate) {
    return "Cannot create a tenancy for an ending management";
  }

  if (currentTenancyId && !currentTenancyEndDate) {
    return "Current tenancy does not have an end date";
  }

  if (ingoingTenancyId) {
    return "An ingoing tenancy already exists";
  }

  return undefined;
}
