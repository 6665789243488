import React from "react";
import { useGetPaidToDateFromOwingQuery } from "local/graphql";
import { ErrorCell } from "./ErrorCell";
import { BasePropertyListRow } from "../BasePropertyListRow";
import { LocalDate } from "@ailo/date";
import { TextCell } from "@ailo/ui";

export function PaidToDateFromOwingCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetPaidToDateFromOwingQuery({
    variables: { managementId }
  });

  if (loading && !data) return <TextCell.Loading />;

  const rentOwing = data?.management?.mostRecentTenancy?.rent;
  if (!rentOwing)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const paidToDate = new LocalDate(rentOwing.progress.paidTo.effective.date);

  return <TextCell>{paidToDate.format("DD MMM YYYY")}</TextCell>;
}

PaidToDateFromOwingCell.Loading = TextCell.Loading;
