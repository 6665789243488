import React, { ReactElement } from "react";
import { ManagementPortfolioListItem } from "../ManagementPortfolioListItem";
import { formatAddress } from "@ailo/domain-helpers";
import { Colors, opacify, PropertyIcon } from "@ailo/primitives";
import { PortfolioPropertyManagement } from "./useGetManagementPortfolioProperties";
import styled from "styled-components/native";
import { ManagementPortfolioPropertyOccupancyBadge } from "./ManagementPortfolioPropertyOccupancyBadge";

interface Props {
  management: PortfolioPropertyManagement;
  isLastItem: boolean;
}

export function ManagementPortfolioPropertiesListItem({
  management,
  isLastItem
}: Props): ReactElement | null {
  const address = formatAddress(management.property.address, {
    format: "street, suburb"
  });
  if (!address) return null;
  return (
    <ManagementPortfolioListItem
      leftContent={<StyledPropertyIcon color={Colors.TEXT.DARK.PLACEHOLDER} />}
      rightContent={
        <ManagementPortfolioPropertyOccupancyBadge occupancy={management} />
      }
      isLastItem={isLastItem}
    >
      {address}
    </ManagementPortfolioListItem>
  );
}

const StyledPropertyIcon = styled(PropertyIcon)`
  width: 32px;
  height: 32px;
  background-color: ${Colors.WHITE};
  border-radius: 4px;
  box-shadow: 0 1px 4px ${opacify(Colors.SPACE_BLACK, 0.2)};
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
  padding: 8px;
`;
