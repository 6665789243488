import { useAnalytics } from "@ailo/services";
import { useCallback } from "react";

export function usePropertyKeyDeletedAnalytics(): {
  trackPropertyKeyDeleted: () => void;
} {
  const analytics = useAnalytics();

  const trackPropertyKeyDeleted = useCallback(() => {
    analytics.track("Property Key Removed");
  }, [analytics]);

  return {
    trackPropertyKeyDeleted
  };
}
