import React, { ReactElement } from "react";
import { useGetMigrationDetails } from "../hooks";
import { RefreshButtonAndInfo } from "./RefreshButtonAndInfo";

interface Props {
  refetchList: () => void;
}

export function PropertyOnboardingRefresh({
  refetchList
}: Props): ReactElement | null {
  const {
    hasActiveExternalTrustAccount,
    lastRefreshedAt,
    refreshStatus,
    loading,
    error
  } = useGetMigrationDetails({ refetchList });

  if (hasActiveExternalTrustAccount === false) {
    return null;
  }

  if (loading) {
    return <RefreshButtonAndInfo.Loading />;
  }

  return (
    <RefreshButtonAndInfo
      refreshStatus={refreshStatus}
      lastRefreshedAt={lastRefreshedAt}
      error={Boolean(error)}
    />
  );
}
