import { DateTimeWithTimeZone } from "@ailo/date";
import { Money } from "@ailo/ui";
import {
  GqlBond,
  GqlFundsReceived
} from "local/domain/bond/graphql/parseBondDetails/types";

export function extractFundsReceived(bond: GqlBond): Money | undefined {
  if (!bond.fundsReceived || bond.fundsReceived.length == 0) {
    return undefined;
  }
  return sortFundsReceived(bond.fundsReceived)
    ? Money.from(bond.fundsReceived[0].amount)
    : undefined;
}

export function sortFundsReceived(
  fundsReceived: GqlFundsReceived[]
): GqlFundsReceived[] {
  return fundsReceived.sort((a, b) =>
    DateTimeWithTimeZone.fromString(a.receivedAt).isAfter(
      DateTimeWithTimeZone.fromString(b.receivedAt)
    )
      ? -1
      : 1
  );
}
