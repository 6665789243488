import { ErrorAlertScreen, SidebarTitle } from "@ailo/ui";
import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

export function LegalEntitySidebarContentError({
  onRetry,
  onClose
}: {
  onRetry: () => void;
  onClose: () => void;
}): ReactElement {
  return (
    <Container>
      <SidebarTitle closeButton onClose={onClose} />
      <AlertContainer>
        <ErrorAlertScreen
          variant={"sidebar"}
          title={"There was a problem loading\nProfile"}
          onRetry={onRetry}
        />
      </AlertContainer>
    </Container>
  );
}

const Container = styled(View)`
  flex: 1;
  padding-bottom: 20px;
`;

const AlertContainer = styled(View)`
  flex: 1;
  justify-content: "center";
`;
