import { Alert } from "@ailo/ui";
import React, { ReactElement } from "react";

export function getEditBondClaimsInfoHeader(): ReactElement {
  return <ClaimLiabilitiesStepInfoHeader />;
}

function ClaimLiabilitiesStepInfoHeader(): ReactElement {
  return (
    <Alert type={"info"} style={{ marginBottom: 40 }}>
      {"In Ailo you can only edit the funds owing for bills and rent to be paid from the bond. " +
        "Please contact the bond authority directly if you need to edit the actual claim."}
    </Alert>
  );
}
