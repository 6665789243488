import { ClaimTenancyData } from "local/domain/bond";
import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Text } from "@ailo/primitives";
import { SecondaryText as CategoryText } from "../../../../SecondaryText";
import { BillClaimInput } from "./BillClaimInput";
import { BillClaimEmpty } from "./BillClaimEmpty";

export function BillClaimInputList({
  data,
  style
}: {
  data: ClaimTenancyData;
  style: StyleProp<ViewStyle>;
}): ReactElement {
  return (
    <View style={style}>
      <Text.BodyL weight={"medium"} style={{ marginBottom: 20 }}>
        {"Settle bills"}
      </Text.BodyL>

      {data.bills.length > 0 ? (
        <View>
          <CategoryText>{"Select bills to be paid from bond"}</CategoryText>
          {data.bills.map((value, index) => (
            <BillClaimInput bill={value} key={index} index={index} />
          ))}
        </View>
      ) : (
        <BillClaimEmpty />
      )}
    </View>
  );
}
