import { useFormContext } from "react-hook-form";
import { FilterConfig } from "../FilterFactory";
import { getFilterInputParams } from "./getFilterInputParams";
import { doesRowsColumnsContainValuesToMatch } from "./doesRowsColumnsContainValueToMatch";
import { ReportTableRow } from "../ReportTable";

export const useFilter = <Row extends ReportTableRow>(
  filterConfigs?: FilterConfig<Row>[]
): ((row: Row) => boolean) => {
  const formContext = useFormContext();
  if (filterConfigs == undefined) return () => true;

  const filterInputs = formContext?.watch?.();
  const filterInputParams =
    filterInputs && filterConfigs
      ? getFilterInputParams(filterConfigs, filterInputs)
      : [];

  return (row: Row) =>
    filterInputParams
      .map((filterInput) =>
        doesRowsColumnsContainValuesToMatch(row, filterInput)
      )
      .every((inputMatch) => inputMatch === true);
};
