import React, { ReactElement } from "react";
import { PlatformFeatureId, useGetAllTenanciesQuery } from "local/graphql";
import { BondErrorAlertContent } from "./BondErrorAlertContent";
import { useHasFeature } from "@ailo/domains";
import { Moment } from "moment";
import { parseTenancies } from "./sortTenancies";
import { StyleProp, ViewStyle } from "react-native";

interface Props {
  managementId: string;
  style?: StyleProp<ViewStyle>;
}

export interface Tenancy {
  id: string;
  bond?: {
    status: string;
    failureReason?: string;
    fundsReceived?: {
      receivedAt: Moment;
    }[];
  };
}

export interface TenancyWithStringDates {
  id: string;
  bond?: {
    status: string;
    failureReason?: string;
    fundsReceived?: {
      receivedAt: string;
    }[];
  };
}

export function BondErrorAlert({
  managementId,
  style
}: Props): ReactElement | null {
  const { data, loading } = useGetAllTenanciesQuery({
    variables: { managementId }
  });

  const hasEditBondClaimFeature = useHasFeature(
    PlatformFeatureId.EditTenancyBondClaim
  );

  if (
    !hasEditBondClaimFeature ||
    loading ||
    !data ||
    !data.management ||
    !data.management.tenancies
  ) {
    return null;
  }

  const tenancies = parseTenancies(
    data.management.tenancies.items as TenancyWithStringDates[]
  );

  const mismatchedTenancy = tenancies.find(fundReceivedDifferentThanClaimed);

  return (
    <>
      {!!mismatchedTenancy && (
        <BondErrorAlertContent
          tenancyId={mismatchedTenancy.id}
          managementId={managementId}
          style={style}
        />
      )}
    </>
  );
}

function fundReceivedDifferentThanClaimed(tenancy: Tenancy): boolean {
  return (
    tenancy.bond?.status === "DisbursementFailed" &&
    ["ReceivedLessFundsThanClaimed", "ReceivedMoreFundsThanClaimed"].includes(
      tenancy.bond?.failureReason || ""
    )
  );
}
