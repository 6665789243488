import { Link, Text } from "@ailo/primitives";
import { LocalDate } from "@ailo/ui";
import {
  DepositData,
  DepositSummaryCard,
  DepositSummaryManageMenu
} from "local/domain/propertyManagement";
import React, { ReactElement } from "react";
import { BaseActionActivity } from "../BaseActionActivity";
import { useEnvironment } from "@ailo/services";
import { Platform, View } from "react-native";
import styled from "styled-components/native";

interface ActionActivityDepositSummaryCardBaseProps {
  managementId?: string;
  depositData?: DepositData & { createdAt: string };
}

export function ActionActivityDepositSummaryCardBase({
  managementId,
  depositData
}: ActionActivityDepositSummaryCardBaseProps): ReactElement {
  const { INITIAL_PAYMENT_HELP_URL } = useEnvironment();

  const description = depositData
    ? depositDataDescription(depositData)
    : noDepositDescription(INITIAL_PAYMENT_HELP_URL);

  return (
    <BaseActionActivity
      description={
        <Container>
          <Text.BodyL weight={"medium"}>{description}</Text.BodyL>
          {depositData && managementId && (
            <DepositSummaryManageMenu
              deposit={depositData}
              managementId={managementId}
            />
          )}
        </Container>
      }
    >
      <DepositSummaryCard managementId={managementId} deposit={depositData} />
    </BaseActionActivity>
  );
}

function depositDataDescription({ createdAt }: { createdAt: string }): string {
  const date = LocalDate.from(createdAt).format("D MMM");
  return `Initial payment requested on ${date}`;
}

function noDepositDescription(initialPaymentHelpUrl: string): ReactElement {
  return (
    <Text.BodyS>
      {"Request and collect an "}
      <Link
        variant={"primary"}
        onPress={() => {
          Platform.OS === "web" && window.open(initialPaymentHelpUrl, "_blank");
        }}
        style={{ textDecorationLine: "underline" }}
      >
        {"initial payment"}
      </Link>{" "}
      {"for renters via the Ailo app."}
    </Text.BodyS>
  );
}

const Container = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;
