import { CopyToClipboardButton } from "@ailo/ui";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { SecondaryText } from "../../../SecondaryText";

export function BondReference({
  bondReference
}: {
  bondReference?: string;
}): ReactElement {
  return (
    <View style={{ marginTop: 16 }}>
      <SecondaryText style={{ marginBottom: 4 }}>
        {"Bond reference"}
      </SecondaryText>
      <CopyToClipboardButton value={bondReference} />
    </View>
  );
}
