import React, { ReactElement, useCallback, useState } from "react";
import { Colors, IconProps, Pressable, Text } from "@ailo/primitives";
import { View, ViewProps } from "react-native";
import styled from "styled-components/native";
import {
  useNavigation,
  Screens,
  AppDrawerNavigatorParamList
} from "local/common";

interface Props {
  label: string;
  icon?: React.FC<IconProps>;
  screenName?: keyof AppDrawerNavigatorParamList;
  focused?: boolean;
  rightComponent?: React.ReactElement | null;
  onDrawerItemPressed?: () => void;
}

export function NavigationDrawerItem({
  label,
  icon,
  screenName,
  focused = false,
  rightComponent,
  onDrawerItemPressed,
  ...props
}: Props & ViewProps): ReactElement {
  const navigation = useNavigation();
  const [isPressed, setPressed] = useState(false);

  const onPress = useCallback(() => {
    setPressed(false);
    if (onDrawerItemPressed) return onDrawerItemPressed();
    if (screenName)
      navigation.navigate(Screens.AppNavigator, { screen: screenName });
  }, [navigation, screenName, onDrawerItemPressed]);

  const testId = `${label.toLowerCase().replace(/ /g, "-")}-drawer`;

  return (
    <Container
      onPress={onPress}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      isPressed={isPressed}
      isActive={focused}
      testID={testId}
      {...props}
    >
      {({ hovered }) => {
        const color = focused || hovered ? Colors.WHITE : Colors.SMOKE;

        return (
          <>
            <IconTextContainer>
              {icon &&
                React.createElement(icon, {
                  style: { marginRight: 12, width: 20, height: 20 },
                  color
                })}
              <Text.BodyM color={color} weight={"medium"}>
                {label}
              </Text.BodyM>
            </IconTextContainer>
            {rightComponent}
          </>
        );
      }}
    </Container>
  );
}

const Container = styled(Pressable)<{
  isPressed: boolean;
  isActive: boolean;
}>`
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 4px;
  height: 44px;
  width: auto;
  margin: 0 8px;
  padding: 0 18px;
  background-color: ${({ isPressed, isActive }): string =>
    isPressed || isActive ? Colors.SPACE_BLACK : Colors.CHARCOAL};
`;

const IconTextContainer = styled(View)`
  flex-direction: row;
  align-items: center;
`;
