import { Colors, Skeleton } from "@ailo/primitives";
import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

const TableLoadingWrapper = styled(View)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: [heading] 48px 41px 41px 41px;
  row-gap: 33px 41px;
  height: 360px;
`;

const TableLoadingRowWrapper = styled(View)`
  display: grid;
  grid-template-columns: 1.8fr 1fr 1fr 1fr;
  align-items: flex-end;
`;

const TextSkeleton = ({
  size = "large"
}: {
  size?: "small" | "large";
}): ReactElement => (
  <Skeleton
    style={{
      width: size === "large" ? 140 : 80,
      height: 12,
      marginBottom: 12,
      marginLeft: 16
    }}
  />
);

export function SimpleReportTableLoading(): ReactElement {
  return (
    <TableLoadingWrapper>
      <TableLoadingRowWrapper
        style={{
          borderBottomColor: Colors.GRAYSCALE.OUTLINE,
          borderBottomWidth: 1,
          borderStyle: "solid"
        }}
      >
        <TextSkeleton />
        <TextSkeleton size={"small"} />
        <TextSkeleton />
        <TextSkeleton size={"small"} />
      </TableLoadingRowWrapper>

      <TableLoadingRowWrapper>
        <TextSkeleton />
        <TextSkeleton size={"small"} />
        <TextSkeleton />
        <TextSkeleton size={"small"} />
      </TableLoadingRowWrapper>

      <TableLoadingRowWrapper style={{ opacity: 0.6 }}>
        <TextSkeleton />
        <TextSkeleton size={"small"} />
        <TextSkeleton />
        <TextSkeleton size={"small"} />
      </TableLoadingRowWrapper>

      <TableLoadingRowWrapper style={{ opacity: 0.6 }}>
        <TextSkeleton />
        <TextSkeleton size={"small"} />
        <TextSkeleton />
        <TextSkeleton size={"small"} />
      </TableLoadingRowWrapper>
    </TableLoadingWrapper>
  );
}
