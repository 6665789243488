import { Alert, Button } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { OwnerEntry } from "./OwnerEntry";
import { AddOrEditOwnerModalForm } from "./AddOrEditOwnerModalForm";
import { OwnerDetails } from "../../useCreateManagement";
import { View } from "react-native";
import { useAnalytics } from "@ailo/services";
import { getTotalShares } from "./getTotalShares";
import { NewManagementFormData } from "../../NewManagementFormData";

enum FormModes {
  Add = "Add",
  Edit = "Edit"
}

export function OwnerDetailsStep({
  display
}: {
  display: boolean;
}): ReactElement {
  const { control, setValue, clearErrors, errors, getValues } =
    useFormContext<NewManagementFormData>();

  const analytics = useAnalytics();
  useEffect(() => {
    if (display === true) {
      analytics.trackScreenVisited("New Management — Owners");
    }
  }, [display, analytics]);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState<
    | {
        formMode: FormModes.Add;
      }
    | { formMode: FormModes.Edit; editOwnerIndex: number }
    | null
  >(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "owners"
  });

  const totalShares = getTotalShares(getValues().owners);

  const addOwner = useCallback(
    (ownerDetails: OwnerDetails): void => {
      append({
        name: "owners",
        details: ownerDetails
      });
      setModalVisible(false);
      clearErrors("owners");
    },
    [append, clearErrors]
  );

  const editOwner = useCallback(
    (ownerDetails: OwnerDetails, index: number): void => {
      setValue(`owners[${index}].details`, ownerDetails);
      setModalVisible(false);
    },
    [setValue]
  );

  return (
    <View style={{ display: display ? undefined : "none" }}>
      <Text.BodyL weight={"medium"} style={{ marginBottom: 8 }}>
        {"Owners"}
      </Text.BodyL>
      <Text.BodyM
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginBottom: 24 }}
      >
        {
          "Please add all the owners connected to this new management, ensuring you include at least one owner with shares."
        }
      </Text.BodyM>
      {errors.ownersError?.message && (
        <Alert
          style={{ marginBottom: 24 }}
          type={"error"}
          message={errors.ownersError.message}
        />
      )}
      {fields.map((item, index) => {
        return (
          <OwnerEntry
            key={item.id}
            initialOwnerDetails={item.details}
            index={index}
            deleteButtonOnPress={(): void => remove(index)}
            editButtonOnPress={(): void => {
              setModalVisible(true);
              setModalMode({
                formMode: FormModes.Edit,
                editOwnerIndex: index
              });
            }}
            totalShares={totalShares}
            style={{ marginBottom: 12 }}
          />
        );
      })}
      <Button.Secondary
        onPress={(): void => {
          setModalVisible(true);
          setModalMode({ formMode: FormModes.Add });
        }}
        style={fields.length > 0 ? { marginTop: 4 } : {}}
      >
        {"Add owner"}
      </Button.Secondary>
      {modalVisible && modalMode && (
        <AddOrEditOwnerModalForm
          otherOwnerEmails={fields
            .map((owner) => owner.details.email)
            .filter((email, index) => {
              if (
                modalMode.formMode === FormModes.Edit &&
                modalMode.editOwnerIndex === index
              )
                return false;
              return !!email;
            })}
          onSubmit={(ownerDetails: OwnerDetails): void => {
            if (modalMode.formMode === FormModes.Add) {
              addOwner(ownerDetails);
            } else {
              editOwner(ownerDetails, modalMode.editOwnerIndex);
            }
          }}
          onCancel={(): void => setModalVisible(false)}
          confirmText={
            modalMode.formMode === FormModes.Add ? "Add owner" : "Edit owner"
          }
          defaultValues={
            modalMode.formMode === FormModes.Edit
              ? getValues(`owners[${modalMode.editOwnerIndex}].details`)
              : undefined
          }
        />
      )}
    </View>
  );
}
