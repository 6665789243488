import React, { ReactElement } from "react";
import { DefaultProps, DisbursementsIcon } from "@ailo/primitives";
import { View } from "react-native";
import { EmptyGrid } from "@ailo/ui";

export function EmptyCard(
  props: DefaultProps<{
    message: string;
    secondaryMessage: string;
  }>
): ReactElement {
  return (
    <EmptyGrid
      {...props}
      bannerImage={
        <View style={{ alignItems: "center" }}>
          <DisbursementsIcon />
        </View>
      }
    />
  );
}
