import { Colors, ErrorSolidIcon } from "@ailo/primitives";
import { ErrorBoundary } from "@ailo/services";
import React, { ReactNode } from "react";
import { CellContainer, TextCellContent } from "@ailo/ui";
import { View } from "react-native";

export function OccupancyStatusErrorBoundary({
  children
}: {
  children?: ReactNode;
}): React.ReactElement {
  return (
    <ErrorBoundary fallbackComponent={OccupancyStatusError}>
      {children}
    </ErrorBoundary>
  );
}

function OccupancyStatusError(): React.ReactElement {
  return (
    <CellContainer>
      <View style={{ flexDirection: "row" }}>
        <ErrorSolidIcon
          width={20}
          height={20}
          color={Colors.TEXT.DARK.SECONDARY}
          secondaryColor={Colors.WHITE}
          style={{ marginRight: 4 }}
        />
        <TextCellContent color={Colors.TEXT.DARK.SECONDARY}>
          {"Status unavailable"}
        </TextCellContent>
      </View>
    </CellContainer>
  );
}
