import React, { ReactElement, useCallback } from "react";
import { SplitScreenContainer } from "local/common";
import { ClaimBondFormContext } from "./components";
import { TenancyDetails } from "./components/tenancyDetails";
import { ClaimTenancyData } from "local/domain/bond";
import { useOnFocus } from "@ailo/services";
import { useNavigateToProperty } from "local/domain/propertyManagement";
import { ClaimBondFormLoading } from "./components/form/ClaimBondFormLoading";
import { useGetClaimTenancyBondDetails } from "local/domain/bond/graphql";

function BaseClaimTenancyBondScreen({
  tenancyId,
  managementId,
  formConstructor,
  title
}: {
  tenancyId: string;
  managementId: string;
  formConstructor: (
    data: ClaimTenancyData,
    onClose: () => void
  ) => ReactElement;
  title: string;
}): ReactElement {
  const { data, loading, refetch } = useGetClaimTenancyBondDetails({
    tenancyId,
    includePaidBills: false
  });
  useOnFocus(refetch);

  const navigateToProperty = useNavigateToProperty();

  const navigateToPropertyScreen = useCallback((): void => {
    navigateToProperty(managementId);
  }, [managementId, navigateToProperty]);

  if (loading) {
    return <LoadingClaimTenancyBondScreen title={title} />;
  }

  return (
    <SplitScreenContainer
      leftSectionSize={"medium"}
      left={
        <ClaimBondFormContext>
          {formConstructor(data!, navigateToPropertyScreen)}
        </ClaimBondFormContext>
      }
      right={<TenancyDetails data={data} />}
    />
  );
}

function LoadingClaimTenancyBondScreen({
  title
}: {
  title: string;
}): ReactElement {
  return (
    <SplitScreenContainer
      leftSectionSize={"medium"}
      left={<ClaimBondFormLoading title={title} />}
      right={<TenancyDetails.Loading />}
    />
  );
}

export { BaseClaimTenancyBondScreen };
