import { Image } from "react-native";

import React, { ReactNode } from "react";

import {
  CapitalTerritoryLogo,
  Colors,
  NewSouthWalesLogo,
  QueenslandLogo,
  SouthAustraliaLogo,
  TasmaniaLogo,
  VictoriaLogo,
  WestAustraliaLogo
} from "@ailo/primitives";
import { AustralianState } from "local/common";

export function getBondAuthorityData(
  state: AustralianState | undefined
): BondAuthority | undefined {
  if (!state) {
    return undefined;
  }
  return BondAuthorityData[state];
}

const BondAuthorityData: Record<AustralianState, BondAuthority | undefined> = {
  NSW: {
    name: "NSW Rental Bonds Online",
    displayUrl: "rbo.fairtrading.nsw.gov.au",
    url: "https://rbo.fairtrading.nsw.gov.au/agent/login",
    logo: (
      <Image style={{ width: 28, height: 32 }} source={NewSouthWalesLogo} />
    ),
    color: Colors.WHITE
  },
  ACT: {
    name: "Rental Bonds Portal",
    displayUrl: "act.gov.au",
    url: "https://rentalbonds.revenue.act.gov.au/",
    logo: (
      <Image style={{ width: 32, height: 32 }} source={CapitalTerritoryLogo} />
    ),
    color: Colors.WHITE
  },
  VIC: {
    name: "RTBA Online",
    displayUrl: "vic.gov.au",
    url: "https://rentalbonds.vic.gov.au/",
    logo: <Image style={{ width: 32, height: 28 }} source={VictoriaLogo} />,
    color: Colors.WHITE
  },
  SA: {
    name: "Residential Bonds Online",
    displayUrl: "sa.gov.au",
    url: "https://tenancies.applyonline.sa.gov.au/rbo/bond/",
    logo: (
      <Image style={{ width: 32, height: 32 }} source={SouthAustraliaLogo} />
    ),
    color: Colors.WHITE
  },
  WA: {
    name: "BondsOnline",
    displayUrl: "wa.gov.au",
    url: "https://bonds.commerce.wa.gov.au/bonds/pages/external/registered/login.xhtml",
    logo: (
      <Image style={{ width: 32, height: 24 }} source={WestAustraliaLogo} />
    ),
    color: "rgb(0, 139, 145)"
  },
  NT: undefined,
  QLD: {
    name: "RTA Web Services",
    displayUrl: "qld.gov.au",
    url: "https://rta.qld.gov.au/rta-web-services/online-bond-refund",
    logo: <Image style={{ width: 32, height: 32 }} source={QueenslandLogo} />,
    color: "rgb(115, 24, 44)"
  },
  TAS: {
    name: "MyBond Portal",
    displayUrl: "tas.gov.au",
    url: "https://mybond.justice.tas.gov.au/",
    logo: <Image style={{ width: 32, height: 30 }} source={TasmaniaLogo} />,
    color: "rgb(29, 55, 75)"
  }
};

export const BondAuthorityName: Record<AustralianState, string | undefined> = {
  NSW: "NSW Rental Bonds Online",
  ACT: undefined,
  VIC: undefined,
  SA: undefined,
  WA: undefined,
  NT: undefined,
  QLD: undefined,
  TAS: undefined
};

export type BondAuthority = {
  name: string;
  url: string;
  displayUrl: string;
  logo: ReactNode;
  color: string;
};
