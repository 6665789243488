import { ErrorAlertScreen, FormFieldRow, TextInputFormField } from "@ailo/ui";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { EndTenancyData } from "../useGetDataForEndTenancy";
import { EndTenancyDateInput } from "./EndTenancyDateInput";
import { EndTenancyNoteInput } from "./EndTenancyNoteInput";
import { EndTenancyReasonInput } from "./EndTenancyReasonInput";
import { RentLiabilityEndDateInput } from "./RentLiabilityEndDateInput";
import { Text } from "@ailo/primitives";
import styled from "styled-components/native";

export function EndTenancyStep({
  display,
  endTenancyData
}: {
  display: boolean;
  endTenancyData?: EndTenancyData;
}): ReactElement {
  return (
    <View style={{ display: display ? undefined : "none" }}>
      <EndTenancyStepFormContent endTenancyData={endTenancyData} />
    </View>
  );
}

function EndTenancyStepFormContent({
  endTenancyData
}: {
  endTenancyData?: EndTenancyData;
}): ReactElement {
  return (
    <>
      <FormFieldRow style={{ marginBottom: 24 }}>
        <EndTenancyDateInput
          nextTenancyDateInfo={endTenancyData?.nextTenancyDateInfo}
          tenancyAgreementDateInfo={endTenancyData?.tenancyAgreementDateInfo}
          tenancyEndDate={endTenancyData?.tenancyEndDate?.format("YYYY-MM-DD")}
          validEndDateRange={endTenancyData?.validEndDateRange}
        />
        <RentLiabilityEndDateInput />
      </FormFieldRow>
      <View style={{ marginBottom: 24 }}>
        <EndTenancyReasonInput reason={endTenancyData?.vacatingReason} />
      </View>
      <EndTenancyNoteInput notes={endTenancyData?.vacatingNotes} />
    </>
  );
}

EndTenancyStep.Loading = function Loading(): ReactElement {
  return (
    <Container>
      <Text.BodyS.Loading width={120} style={{ marginBottom: 40 }} />
      <FormFieldRow style={{ marginBottom: 24 }}>
        <TextInputFormField.Loading />
        <TextInputFormField.Loading />
      </FormFieldRow>
      <TextInputFormField.Loading
        style={{ marginBottom: 24 }}
        labelWidth={120}
      />
      <TextInputFormField.Loading
        labelWidth={120}
        numberOfLines={4}
        multiline
      />
    </Container>
  );
};

EndTenancyStep.Error = function Error({
  onRetry
}: {
  onRetry: () => void;
}): React.ReactElement {
  return (
    <Container>
      <View style={{ justifyContent: "center" }}>
        <ErrorAlertScreen
          variant={"medium"}
          title={"There was a problem loading\nEnd tenancy"}
          onRetry={onRetry}
        />
      </View>
    </Container>
  );
};

const Container = styled(View)`
  max-width: 680px;
  width: 100%;
  flex: 1;
  align-self: flex-end;
  padding-right: 60px;
  padding-left: 60px;
  margin-top: 32px;
`;
