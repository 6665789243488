import { DateInputFormField } from "@ailo/ui";
import { CreateRecurringFeeFormData } from "local/domain/expense";
import { FeeFrequency } from "local/graphql";
import moment from "moment";
import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export function DateInput(): ReactElement {
  const { setValue, watch, control, errors } =
    useFormContext<CreateRecurringFeeFormData>();
  const feeBlueprint = watch("feeBlueprint");
  const frequency = feeBlueprint.frequency;
  const defaultStartDate = feeBlueprint.startDate;

  const validDate = (date: string): boolean => {
    if (frequency === FeeFrequency.Weekly) return true;
    return moment(date).date() <= 28;
  };

  const minDate = moment().subtract(2, "months").format("YYYY-MM-DD");
  const maxDate = moment().add(1, "years").format("YYYY-MM-DD");

  useEffect(() => {
    // `defaultValue` passed to the `Controller` don't get seen by `watch` so use `setValue` instead
    // https://github.com/react-hook-form/react-hook-form/issues/1943#issue-643902427
    setValue("startDate", defaultStartDate);
  }, [defaultStartDate, setValue]);

  return (
    <Controller
      control={control}
      name={"startDate"}
      defaultValue={null}
      render={({ value, onChange, onBlur }) => (
        <DateInputFormField
          {...{ value, onChange, onBlur }}
          label={"Start Date"}
          placeholder={"Start Date"}
          autoCorrect={false}
          autoCompleteType={"off"}
          minDate={minDate}
          maxDate={maxDate}
          webDateEnabled={validDate}
          error={errors.startDate?.message}
          style={{ marginBottom: 24 }}
          testID={"StartDateInputFormField"}
        />
      )}
      rules={{
        required: "Enter start date"
      }}
    />
  );
}
