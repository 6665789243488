import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { useCallback } from "react";
import { LeaseType } from "./LeaseRenewalFormData";

import { ReviewLeaseMutation, useReviewLeaseMutation } from "local/graphql";

export function useReviewLease({
  onCompleted,
  onError
}: {
  onCompleted: () => void;
  onError: () => void;
}): [
  ({
    agreementId,
    tenancyId,
    startDate,
    fixedTermEndDate,
    leaseType
  }: {
    agreementId?: string;
    tenancyId: string;
    startDate: string;
    fixedTermEndDate?: string | null;
    leaseType: LeaseType;
  }) => Promise<void>,
  MutationResult<ReviewLeaseMutation>
] {
  const [reviewLeaseMutation, result] = useReviewLeaseMutation();

  const reviewLease = useCallback(
    async ({
      agreementId,
      tenancyId,
      startDate,
      fixedTermEndDate,
      leaseType
    }): Promise<void> => {
      const allowCurrentTenancyAgreementToLapse =
        leaseType === LeaseType.AllowCurrentToBePeriodic;
      const agreementDetails = !allowCurrentTenancyAgreementToLapse
        ? {
            id: agreementId,
            startDate,
            fixedTermEndDate: fixedTermEndDate || null
          }
        : undefined;

      const mutationInput = {
        tenancyId,
        agreementDetails,
        allowCurrentTenancyAgreementToLapse
      };

      try {
        const result = await reviewLeaseMutation({
          variables: mutationInput
        });
        throwIfMutationFailed(result, {
          dataKey: "reviewLease"
        });
        onCompleted?.();
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
      }
    },
    [onCompleted, onError, reviewLeaseMutation]
  );

  return [reviewLease, result];
}
