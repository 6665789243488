import { useCurrentAgencyOrg } from "local/common";
import { PlatformFeatureId } from "local/graphql";
import { entries, groupBy, values } from "lodash";
import { useCallback, useMemo } from "react";
import { useAuth } from "@ailo/services";
import { getAvailableFeatures } from "../../../main/AppDataSetup/getAvailableFeatures";
import { useGetSetupData } from "../../../main/AppDataSetup/useGetSetupData";

export enum ReportSection {
  HEALTH = "Health",
  REVENUE = "Revenue",
  CUSTOMER = "Customer activation",
  OTHER = "Other"
}

export interface ReportCfg {
  reportHeading: string;
  reportDescription: string;
  isPopular?: boolean;
  reportSection?: ReportSection;
  hasOwnTeamFilter?: boolean | PlatformFeatureId;
  reportAccess?: PlatformFeatureId[];
  reportPermissions?: string[];
  parentReport?: string;
}
export type Report = ReportCfg & { name: ReportName; parentReport: ReportName };

export interface Section {
  reportList: Report[];
  title: ReportSection;
}

const raw_reports: Record<string, ReportCfg> = {
  property_issues: {
    reportHeading: "Property health issues",
    reportDescription:
      "Summary of property issues including missing fees, arrears and expired leases",
    isPopular: true,
    reportSection: ReportSection.HEALTH
  },
  property_issues_missing_fee: {
    reportHeading: "Property not being charged recurring fees",
    reportDescription: "",
    hasOwnTeamFilter: true,
    parentReport: "property_issues"
  },
  investors: {
    reportHeading: "Investor account health",
    reportDescription:
      "Investors upcoming bills, Ailo app activation and notification status",
    isPopular: false,
    reportSection: ReportSection.HEALTH
  },
  bill_issues: {
    reportHeading: "Bills at risk",
    reportDescription: "Summary of bills at risk of not being paid",
    isPopular: false,
    reportSection: ReportSection.HEALTH
  },
  bills_due: {
    reportHeading: "Bills due and overdue",
    reportDescription:
      "Bills coming up in the next 30 days and overdue bills for both renter and investor",
    isPopular: true,
    reportSection: ReportSection.HEALTH
  },
  financial_performance: {
    reportHeading: "Financial performance dashboard",
    reportDescription: "Revenue totals and trends",
    isPopular: false,
    reportPermissions: ["legal_entity:financial:view"],
    reportSection: ReportSection.REVENUE
  },
  revenue: {
    reportHeading: "Revenue over time",
    reportDescription: "Compare revenue over different time scales",
    isPopular: false,
    reportSection: ReportSection.REVENUE,
    reportAccess: [PlatformFeatureId.RevenueReport],
    hasOwnTeamFilter: true
  },
  income_by_chart_of_account: {
    reportHeading: "Income by fee type",
    reportDescription:
      "Total income by chart of account, view by date range or transfer period",
    isPopular: true,
    reportSection: ReportSection.REVENUE,
    hasOwnTeamFilter: PlatformFeatureId.ReportingModalFilter
  },
  income_by_property: {
    reportHeading: "Income by property",
    reportDescription: "Fee income and rent collected, listed by property",
    isPopular: false,
    reportSection: ReportSection.REVENUE,
    hasOwnTeamFilter: PlatformFeatureId.ReportingModalFilter
  },
  first_rent_payments: {
    reportHeading: "First rent payment made",
    reportDescription:
      "Properties where the first rent payment has been made using the Ailo app",
    isPopular: false,
    reportSection: ReportSection.CUSTOMER
  },
  property_activation: {
    reportHeading: "Properties activated on Ailo",
    reportDescription:
      "Properties with an active management and tenancy in Ailo",
    isPopular: false,
    reportSection: ReportSection.CUSTOMER
  },
  renter_on_app: {
    reportHeading: "Renter on app",
    reportDescription:
      "Renters that have onboarded and verified their phone in the Ailo app",
    isPopular: false,
    reportSection: ReportSection.CUSTOMER
  },
  bonds: {
    reportHeading: "Bond report",
    reportDescription: "Bond information and claim status by tenancy",
    isPopular: false,
    reportAccess: [
      PlatformFeatureId.TenancyBonds,
      PlatformFeatureId.BondsReport
    ],
    reportSection: ReportSection.OTHER
  },
  centrepay: {
    reportHeading: "Renters paying through Centrepay",
    reportDescription:
      "View latest Centrepay payment dates and amounts by renter",
    isPopular: false,
    hasOwnTeamFilter: true,
    reportAccess: [PlatformFeatureId.Centrepay],
    reportSection: ReportSection.OTHER
  },
  property_notifications: {
    reportHeading: "Customer notifications sent by Ailo",
    reportDescription:
      "Notifications sent by Ailo to customers, listed by property",
    isPopular: false,
    reportSection: ReportSection.OTHER
  },
  properties: {
    reportHeading: "All properties",
    reportDescription:
      "Property list with investor and renter names and date migrated to Ailo",
    isPopular: false,
    reportSection: ReportSection.OTHER
  }
};

export const reports: Record<ReportName, Report> = Object.fromEntries(
  entries(raw_reports).map(([name, report]) => [
    name,
    { ...report, name, parentReport: report.parentReport as ReportName }
  ])
);

export function useReportSection(): Section[] {
  const hasAccess = useReportAccess();
  return useMemo(
    () =>
      entries(
        groupBy(
          values(reports).filter((r) => r.reportSection && hasAccess(r)),
          (r) => r.reportSection
        )
      ).map(
        ([title, reportList]) =>
          ({
            title,
            reportList
          } as Section)
      ),
    [hasAccess]
  );
}

export type ReportSections = { [key in ReportSection]: Section };
export type ReportName = keyof typeof raw_reports;

export function useReportAccess(): (report: Report) => boolean {
  const { isAuthenticated } = useAuth();
  const { ailoRN, legalEntities } = useCurrentAgencyOrg();
  const { data } = useGetSetupData({
    skip: !isAuthenticated,
    notifyOnNetworkStatusChange: true
  });

  const userPermissions = legalEntities.flatMap(
    ({ currentUserPermissions }) => currentUserPermissions
  );

  function displayReport(report: Report): boolean {
    if (!ailoRN) {
      return false;
    }
    if (data == undefined) {
      return false;
    }
    const { effectiveUser } = data;
    const availableFeatures = getAvailableFeatures(effectiveUser, ailoRN);

    const hasAccess =
      report.reportAccess?.every((feature) =>
        availableFeatures.includes(feature)
      ) ?? true;
    const hasPermissions =
      report.reportPermissions?.every((permission) =>
        userPermissions.includes(permission)
      ) ?? true;
    return hasAccess && hasPermissions;
  }
  return useCallback(displayReport, [ailoRN, data, userPermissions]);
}
