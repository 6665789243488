import React, { ReactElement, useCallback } from "react";
import { DepositData } from "local/domain/propertyManagement";
import { getMenuOptions } from "./getMenuOptions";
import { StyleProp, View, ViewStyle } from "react-native";
import { ManageMenu } from "local/common";
import { DepositTransferConfirmModal } from "./DepositTransferModal";
import { CancelDepositConfirmationModal } from "./CancelDepositConfirmationModal";
import { ErrorModal } from "@ailo/ui";
import { useMountedState } from "@ailo/primitives";
import styled from "styled-components/native";

type Props = {
  deposit: DepositData & { createdAt: string };
  managementId: string;
  isFormerTenancy?: boolean;
  style?: StyleProp<ViewStyle>;
};
export const DepositSummaryManageMenu = ({
  deposit,
  managementId,
  isFormerTenancy,
  style
}: Props): ReactElement | null => {
  const [showConfirmTransformModal, setShowConfirmTransferModal] =
    useMountedState(false);
  const [showConfirmCancelModal, setShowConfirmCancelModal] =
    useMountedState(false);
  const [showErrorModal, setShowErrorModal] = useMountedState(false);

  const onTransferClick = useCallback(() => {
    setShowConfirmTransferModal(true);
  }, [setShowConfirmTransferModal]);

  const onCancelClick = useCallback(() => {
    setShowConfirmCancelModal(true);
  }, [setShowConfirmCancelModal]);

  const menuOptions = getMenuOptions({
    onCancelClick,
    onTransferClick,
    deposit,
    isFormerTenancy
  });

  const { tenancyDepositAilorn } = deposit;

  if (menuOptions.length > 0) {
    return (
      <>
        <VerticalSeparator />
        <ManageMenu options={menuOptions} style={style} />

        <DepositTransferConfirmModal
          tenancyDepositAilorn={tenancyDepositAilorn}
          managementId={managementId}
          visible={showConfirmTransformModal}
          onSuccess={() => setShowConfirmTransferModal(false)}
          onCancel={() => setShowConfirmTransferModal(false)}
          onError={() => {
            setShowErrorModal(true);
            setShowConfirmTransferModal(false);
          }}
        />
        <CancelDepositConfirmationModal
          tenancyDepositAilorn={tenancyDepositAilorn}
          managementId={managementId}
          visible={showConfirmCancelModal}
          onSuccess={() => setShowConfirmCancelModal(false)}
          onCancel={() => setShowConfirmCancelModal(false)}
          onError={() => {
            setShowErrorModal(true);
            setShowConfirmCancelModal(false);
          }}
        />

        <ErrorModal
          visible={showErrorModal}
          onDismiss={(): void => setShowErrorModal(false)}
        />
      </>
    );
  }
  return null;
};

const VerticalSeparator = styled(View)`
  border-right-style: solid;
  border-right-color: rgba(28, 30, 38, 0.2);
  border-right-width: 1px;
  height: 20px;
  margin-left: 12px;
  margin-right: 12px;
`;
