import { TableRow } from "@ailo/ui";
import styled from "styled-components/native";

const columns = [
  { flex: 1, paddingLeft: 16 },
  { width: 126, paddingLeft: 16 },
  { width: 40, paddingRight: 16 }
];
export const AppointmentTableRowContainer = styled(TableRow).attrs({
  columns
})`
  flex-direction: row;
  align-items: center;
`;
