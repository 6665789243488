import {
  Colors,
  opacify,
  PropertyIcon,
  RightChevronIcon,
  Text
} from "@ailo/primitives";
import { Badge, ListItem } from "@ailo/ui";
import { useLinkProps } from "@react-navigation/native";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";

export interface OwnershipListItemProps {
  ownership: {
    sharesPercentage: string;
    address: {
      unitStreetNumber: string;
      streetName: string;
      suburb: string;
    };
    path: string;
  };
  isLastElement: boolean;
}

export function OwnershipListItem({
  ownership,
  isLastElement
}: OwnershipListItemProps): React.ReactElement {
  const { onPress, ...linkProps } = useLinkProps({
    to: ownership.path
  });

  const { unitStreetNumber, streetName, suburb } = ownership.address;

  return (
    <View
      {...{
        ...linkProps,
        onClick: onPress
      }}
    >
      <ListItem
        style={{
          borderType: isLastElement ? "none" : undefined
        }}
        onPress={onPress}
        leftComponent={
          <IconContainer>
            <PropertyIcon
              width={20}
              height={20}
              color={Colors.TEXT.DARK.PLACEHOLDER}
            />
          </IconContainer>
        }
        header={
          <Text.BodyM weight={"medium"} numberOfLines={1}>
            {unitStreetNumber} {streetName}
          </Text.BodyM>
        }
        subHeader={
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {suburb && (
              <>
                <Text.BodyS
                  color={Colors.TEXT.DARK.SECONDARY}
                  numberOfLines={1}
                >
                  {suburb}
                </Text.BodyS>
                <Text.BodyS
                  color={Colors.TEXT.DARK.SECONDARY}
                  style={{ marginHorizontal: 8 }}
                >
                  {"·"}
                </Text.BodyS>
              </>
            )}
            <Badge>{`${ownership.sharesPercentage}% property share`}</Badge>
          </View>
        }
        rightComponent={<RightChevronIcon height={20} width={20} />}
      />
    </View>
  );
}

const IconContainer = styled(View)`
  background-color: ${Colors.WHITE};
  margin-right: 16px;
  border-radius: 4px;
  box-shadow: 0px 1px 4px ${opacify(Colors.SPACE_BLACK, 0.2)};
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
`;
