import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { ChatSearchBarWeb } from "./ChatSearchBarWeb";
import {
  Screens,
  useCurrentAgencyOrg,
  useNavigation,
  useRoute
} from "local/common";
import { useIsFocused } from "@react-navigation/native";
import { useLayout } from "@react-native-community/hooks";
import { ChatScreenContainer } from "../ChatScreenContainer";
import {
  BaseWelcomeMessage,
  ChatContactsCard,
  ChatDraft,
  ChatFileContext,
  ComposeBox,
  Contact,
  MAX_THREAD_SUBJECT_LENGTH,
  NEW_CHAT_DRAFT_PARENT,
  NewChatMessageHeader,
  useChatAnalytics,
  useChatContext,
  useCreateNewChatFromDraft,
  useFoundOrNewChat,
  useThreadListContext,
  useWatchChatNavigationParams,
  validateThread
} from "@ailo/domains";
import { AiloRN } from "@ailo/ailorn";
import { Colors } from "@ailo/primitives";
import { ScreenComponent } from "@ailo/services";
import { AgencyThreadList } from "../AgencyThreadList";
import { View } from "react-native";
import styled from "styled-components/native";
import {
  useNavigateToChatThreadScreen,
  useNavigateToEmptyChat,
  useSelectFoundChat
} from "local/tabs/comms/navigation";

interface Props {
  externallySelectedContacts: Contact[];
}

export function NewChatMessageScreen({
  externallySelectedContacts
}: Props): ReactElement {
  const { onLayout } = useLayout();
  const currentAgencyOrg = useCurrentAgencyOrg();
  const currentAgencyAilorn = useMemo(
    () =>
      AiloRN.from(currentAgencyOrg.ailoRN, {
        expected: "authz:organisation"
      }),
    [currentAgencyOrg]
  );

  const { chatId } = useRoute<Screens.NewChatMessage>().params || {};
  const navigation = useNavigation<Screens.NewChatMessage>();
  const isFocused = useIsFocused();
  useWatchChatNavigationParams({ params: { chatId } });

  const navigateToEmptyChat = useNavigateToEmptyChat();
  const navigateToChatThreadScreen = useNavigateToChatThreadScreen();
  const selectFoundChat = useSelectFoundChat();
  const { trackChatOpened } = useChatAnalytics();

  const { selectedChatAilorn, selectedChat, drafts } = useChatContext();

  const {
    headerState: { setSmall }
  } = useThreadListContext();

  const { participants, chatAilorn, contacts, selectParticipants } =
    useFoundOrNewChat({
      currentAgencyOrg: currentAgencyAilorn
    });

  useEffect(() => {
    if (!isFocused) return;
    if (selectedChatAilorn?.internalId === chatAilorn?.internalId) return;
    // Page has been reloaded and route chatId does not match state chat from useFoundOrNewChat
    // Removing chatId from route brings the route back in alignment with state
    selectFoundChat(undefined);
  }, [isFocused, selectFoundChat, selectedChatAilorn, chatAilorn]);

  const { createChatFromDraft } = useCreateNewChatFromDraft();

  const goBack = useCallback(() => {
    navigation.canGoBack() ? navigation.goBack() : navigateToEmptyChat();
  }, [navigateToEmptyChat, navigation]);

  const navigateToChat = useCallback(
    (givenChatAilorn: AiloRN<"chat:chat">) => {
      navigateToChatThreadScreen({ chatId: givenChatAilorn.internalId });
    },
    [navigateToChatThreadScreen]
  );

  const navigateToThread = useCallback(
    (threadAilorn: AiloRN<"chat:thread">) => {
      if (chatAilorn) {
        navigateToChatThreadScreen({
          chatId: chatAilorn.internalId,
          threadId: threadAilorn.internalId
        });
      }
    },
    [chatAilorn, navigateToChatThreadScreen]
  );

  useEffect(() => {
    if (!isFocused) return;
    selectFoundChat(chatAilorn?.internalId);

    if (chatAilorn) {
      trackChatOpened({ chatAilorn, from: "Agency Web New Chat Screen" });
    }
  }, [chatAilorn, selectFoundChat, isFocused, trackChatOpened]);

  const analyticsTrackPayload = useMemo(
    () => ({ chatId: selectedChatAilorn?.internalId }),
    [selectedChatAilorn]
  );

  const createNewChat = useCallback(
    async (draft: ChatDraft, completeSending: () => void) => {
      const createdChatAilorn = await createChatFromDraft({
        draft,
        completeSending,
        participants,
        organisationAilorn: currentAgencyAilorn
      });
      if (createdChatAilorn) {
        navigateToChat(createdChatAilorn);
      }
    },
    [createChatFromDraft, currentAgencyAilorn, navigateToChat, participants]
  );

  return (
    <ChatFileContext
      drafts={drafts}
      messageParent={selectedChatAilorn ?? NEW_CHAT_DRAFT_PARENT}
      dropDisabled={participants.length === 0}
    >
      <ScreenComponent
        style={{ backgroundColor: Colors.CLOUD }}
        analyticsTrackPayload={analyticsTrackPayload}
      >
        <ChatScreenContainer onLayout={onLayout}>
          {!selectedChat.error ? (
            <NewChatMessageHeader onCancel={goBack} />
          ) : null}

          <ChatSearchBarWeb
            selectParticipants={selectParticipants}
            externallySelectedContacts={externallySelectedContacts}
          />
          {selectedChatAilorn && selectedChat.chat ? (
            <AgencyThreadList
              openThread={navigateToThread}
              onThreadCreated={navigateToChat}
            />
          ) : (
            participants.length > 0 && (
              <>
                <FillSpace />
                <InnerContainer>
                  <BaseWelcomeMessage>
                    <ChatContactsCard contacts={contacts} />
                  </BaseWelcomeMessage>
                </InnerContainer>
                <ComposeBox
                  hasSubject={true}
                  messageParent={NEW_CHAT_DRAFT_PARENT}
                  drafts={drafts}
                  onSend={createNewChat}
                  validate={validateThread}
                  maxSubjectLength={MAX_THREAD_SUBJECT_LENGTH}
                  onOpenClose={setSmall}
                />
              </>
            )
          )}
        </ChatScreenContainer>
      </ScreenComponent>
    </ChatFileContext>
  );
}

const FillSpace = styled(View)`
  flex-grow: 1;
`;

const InnerContainer = styled(View)`
  flex-shrink: 1;
  flex-grow: 0;
  overflow-y: auto;
  flex-basis: auto;
`;
