import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextInputFormField } from "@ailo/ui";
import { BondDetailsFormData } from "../BondDetailsFormData";

export function ReferenceInput({
  defaultValue,
  disabled = false
}: {
  defaultValue?: string;
  disabled?: boolean;
}): React.ReactElement {
  const { control, errors } = useFormContext<BondDetailsFormData>();
  const name = "reference";
  return (
    <Controller
      name={name}
      defaultValue={defaultValue || null}
      control={control}
      render={({ onChange, onBlur, value, ref }): React.ReactElement => (
        <TextInputFormField
          value={value}
          inputRef={ref}
          error={errors[name]?.message}
          inputTestID={"ReferenceInput"}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
      )}
    />
  );
}
