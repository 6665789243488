import React, { ReactElement } from "react";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { MigratingManagementSidebarHeader } from "./MigratingManagementSidebarHeader";
import { Button, SidebarStickyBottom, SpinnerOverlay } from "@ailo/ui";
import { MigratingManagementSidebarFragment } from "local/graphql";

interface Props {
  migratingManagement: MigratingManagementSidebarFragment;
  closeSidebar: () => void;
  footer: React.ReactNode;
  children: React.ReactNode;
  loading: boolean;
}

function BaseMigratingManagementSidebarContent({
  migratingManagement,
  closeSidebar,
  footer,
  children,
  loading
}: Props): ReactElement {
  return (
    <SidebarContentContainer>
      <PaddedScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <MigratingManagementSidebarHeader
          migratingManagement={migratingManagement}
          closeSidebar={closeSidebar}
        />
        {children}
      </PaddedScrollView>
      <SidebarStickyBottom>{footer}</SidebarStickyBottom>

      {loading ? <SpinnerOverlay /> : null}
    </SidebarContentContainer>
  );
}

interface LoadingProps {
  closeSidebar: () => void;
}

function Loading({ closeSidebar }: LoadingProps): ReactElement {
  return (
    <SidebarContentContainer>
      <PaddedScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <MigratingManagementSidebarHeader.Loading closeSidebar={closeSidebar} />
      </PaddedScrollView>
      <SidebarStickyBottom>
        <Button.Loading />
      </SidebarStickyBottom>
    </SidebarContentContainer>
  );
}

BaseMigratingManagementSidebarContent.Loading = Loading;

export { BaseMigratingManagementSidebarContent };

const SidebarContentContainer = styled(View)`
  display: flex;
  height: 100%;
`;

const PaddedScrollView = styled(ScrollView)`
  flex: 1;
  padding: 32px 32px 0 32px;
`;
