import React, { ReactElement } from "react";
import { BaseActionActivity } from "local/tabs/projects/actions/ActionSidebar/ActionActivity/BaseActionActivity";
import { LinkReiAccountSuggestion } from "../LinkReiAccountSuggestion";
import { NoReiTokenDescription } from "../descriptions/NoReiTokenDescription";
import { ActionRequiredListItem } from "local/domain/project";

export function AgreementCreatedNoTokenView(): ReactElement {
  return (
    <BaseActionActivity description={<NoReiTokenDescription />}>
      <ActionRequiredListItem
        header={"Agreement has been created"}
        subHeader={"Please connect your forms account to view"}
      />
      <LinkReiAccountSuggestion style={{ marginTop: 32 }} />
    </BaseActionActivity>
  );
}
