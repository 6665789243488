import {
  AiloSentry,
  MutationResultDataMissingError,
  throwIfMutationFailed,
  useAnalytics,
  withErrorBoundary
} from "@ailo/services";
import { ErrorAlertScreen, useToastContext } from "@ailo/ui";
import { ExecutionResult } from "graphql";
import { Screens, useCurrentAgencyOrg, useNavigation } from "local/common";
import {
  CreateFeeBlueprintMutation,
  FeeBlueprintType,
  TaxTreatment,
  useCreateFeeBlueprintMutation
} from "local/graphql";
import React, { ReactElement } from "react";
import { TabContent } from "../../common";
import { ManageFeeBlueprintForm, ManageFeeBlueprintFormData } from "../common";

export const CreateFeeTab = withErrorBoundary(
  function CreateFeeTab(): ReactElement {
    const { ailoRN: organisationAiloRN } = useCurrentAgencyOrg();
    const navigation = useNavigation<Screens.SettingsTab>();
    const toasts = useToastContext();
    const analytics = useAnalytics();

    const [createMutation] = useCreateFeeBlueprintMutation();

    const submit = async (data: ManageFeeBlueprintFormData): Promise<void> => {
      let result: ExecutionResult<CreateFeeBlueprintMutation>;
      try {
        result = await createMutation({
          variables: {
            input: {
              name: data.name!,
              type: data.type!,
              taxCategoryId: data.taxCategoryId!,
              fixedAmount: data.price ? { cents: data.price.cents } : undefined,
              oneWeekRentPercentage: data.oneWeekRentPercentage,
              taxTreatment: data.includesGst
                ? TaxTreatment.Inclusive
                : TaxTreatment.Exclusive,
              event:
                data.type === FeeBlueprintType.EventBasedFee
                  ? data.event
                  : null,
              frequency: data.frequency,
              ...(data.frequency === "weekly"
                ? {
                    anniversaryDay: data.chargeDateDayOfWeek!
                  }
                : data.frequency === "monthly"
                ? {
                    anniversaryDay: data.chargeDateDayOfMonth!
                  }
                : data.frequency === "annually"
                ? {
                    anniversaryDay: data.chargeDateDayOfMonth!,
                    anniversaryMonth: data.chargeDateMonth!
                  }
                : {}),
              organisationId: organisationAiloRN
            }
          }
        });
        throwIfMutationFailed(result, { dataKey: "blueprint" });
      } catch (error) {
        if (
          error instanceof MutationResultDataMissingError &&
          error.result.errors?.length === 1 &&
          error.result.errors[0].path?.join(".") === "blueprint" &&
          error.result.errors[0].extensions?.code === "BAD_USER_INPUT"
        ) {
          toasts.show({
            type: "error",
            message: error.result.errors[0].message
          });
          return;
        }
        AiloSentry.captureException(error);
        toasts.showFormSubmitError();
        return;
      }

      toasts.show({
        type: "success",
        message: "Fee template created"
      });
      navigation.navigate(Screens.SettingsTab, {
        tab: "fees"
      });
      analytics.track("Fee Blueprint Created", {
        feeBlueprintId: result.data!.blueprint!.id,
        feeFrequency: data.frequency ?? "one-off"
      });
    };

    return (
      <TabContent
        title={"Create fee template"}
        onGoBack={() =>
          navigation.navigate(Screens.SettingsTab, {
            tab: "fees"
          })
        }
      >
        <ManageFeeBlueprintForm
          mode={"create"}
          style={{ marginTop: 40 }}
          onSubmit={submit}
        />
      </TabContent>
    );
  },
  {
    fallbackComponent: function Fallback(props) {
      const navigation = useNavigation<Screens.SettingsTab>();
      return (
        <TabContent
          onGoBack={() =>
            navigation.navigate(Screens.SettingsTab, {
              tab: "fees"
            })
          }
        >
          <ErrorAlertScreen
            title={"There was a problem loading Create New Fee Template"}
            onRetry={props.retry}
          />
        </TabContent>
      );
    }
  }
);
