import React, { ReactElement, useCallback, useRef } from "react";
import { ConfirmModal, useToastContext } from "@ailo/ui";
import { Text, useIsMountedRef } from "@ailo/primitives";
import { AiloSentry } from "@ailo/services";
import {
  CancelRecurringFeeDetails,
  useCancelRecurringFee
} from "./useCancelRecurringFee";

export type CancelRecurringFeeConfirmModalData = CancelRecurringFeeDetails & {
  feeName: string;
};

interface Props {
  data?: CancelRecurringFeeConfirmModalData;
  onDismiss?: () => void;
}

function pluraliseFeeName(feeName: string): string {
  if (feeName.slice(feeName.length - 4).toLowerCase() === "fees")
    return feeName;
  if (feeName.slice(feeName.length - 3).toLowerCase() === "fee")
    return `${feeName}s`;
  return `${feeName} fees`;
}

export function CancelRecurringFeeConfirmModal({
  data,
  onDismiss
}: Props): ReactElement {
  const toast = useToastContext();
  const feeNameRef = useRef("");
  const isMountedRef = useIsMountedRef();
  const [cancelRecurringFee, submitting] = useCancelRecurringFee();

  const submit = useCallback(async () => {
    if (!data) return;

    try {
      await cancelRecurringFee({
        feeAiloRN: data.feeAiloRN,
        managementId: data.managementId,
        amount: data.amount,
        blueprintId: data.blueprintId,
        blueprintAmount: data.blueprintAmount,
        frequency: data.frequency
      });
      toast.show({
        type: "success",
        message: "Fee removed"
      });
    } catch (error) {
      AiloSentry.captureException(error);
      toast.show({
        type: "error",
        message: "Unable to remove fee. Please try again."
      });
    }
    if (isMountedRef) onDismiss?.();
  }, [data, onDismiss, cancelRecurringFee, toast, isMountedRef]);

  if (data) {
    feeNameRef.current = data.feeName;
  }

  return (
    <ConfirmModal
      visible={!!data}
      title={`Remove ${feeNameRef.current}?`}
      onConfirm={submit}
      onCancel={onDismiss}
      destructive={true}
      confirmLabel={"Remove"}
      loading={submitting}
    >
      <Text.BodyM weight={"book"}>
        {`All upcoming ${pluraliseFeeName(
          feeNameRef.current
        )} will be cancelled. Any fees currently outstanding will still be payable.`}
      </Text.BodyM>
    </ConfirmModal>
  );
}
