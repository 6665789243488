import React, { memo, ReactElement, useCallback, useMemo } from "react";
import { TextInputFormField } from "@ailo/ui";
import { Controller, useFormContext } from "react-hook-form";
import { ViewStyle } from "react-native";
import { RenderData } from "./ReconciliationDateControl";

interface ReconciliationDateControlProps {
  style?: ViewStyle;
  name: string;
  label: string;
}

function TextControlComponent({
  style,
  label,
  name
}: ReconciliationDateControlProps): ReactElement {
  const { errors } = useFormContext();
  const errorMessage = errors[name]?.message;

  const rules = useMemo(
    () => ({
      required: `You need to enter ${label.toLowerCase()}`
    }),
    [label]
  );

  const render = useCallback(
    ({ onChange, ...data }: RenderData<string>) => (
      <TextInputFormField
        style={style}
        label={label}
        error={errorMessage}
        onChangeText={onChange}
        {...data}
      />
    ),
    [errorMessage, label, style]
  );
  return (
    <Controller name={name} render={render} rules={rules} defaultValue={""} />
  );
}

export const TextControl = memo(TextControlComponent);
