import { Colors, LeftChevronIcon, Text } from "@ailo/primitives";
import { Screens, useNavigation, useRoute } from "local/common";
import React, { ReactElement, useCallback } from "react";
import { Pressable, View } from "react-native";
import { ReportName, reports } from "./reportsDetails";

interface DrilldownDetails {
  route: string;
  name: string;
}
type DrilldownName = "propertyIssue" | "billIssue";

const drilldownMap: Record<DrilldownName, DrilldownDetails> = {
  propertyIssue: { route: "property_issues", name: "Property Issues" },
  billIssue: { route: "bill_issues", name: "Bill Issues" }
};

function useGetParentReport(): ReportName | undefined {
  const route = useRoute<Screens.ReportVisulisation>();
  if (!route?.params) return;
  const params = route.params;
  const currentDrilldownName = Object.keys(params)
    .filter((paramKey) => {
      return Object.keys(drilldownMap).includes(paramKey);
    })
    .filter(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (drilldownNameFromParamKey) => params[drilldownNameFromParamKey]
    )[0] as DrilldownName;
  const parentDrill =
    (currentDrilldownName
      ? (drilldownMap[currentDrilldownName]?.route as ReportName)
      : null) ?? reports[params.reportName]?.parentReport;
  return parentDrill;
}

export function useReportsBreadCrumb(): () => ReactElement {
  const { navigate } = useNavigation<Screens.ReportVisulisation>();
  const parentReport = useGetParentReport();
  const parentReportTitle = parentReport
    ? reports[parentReport]?.reportHeading
    : "Reports";
  const onPress = useCallback(() => {
    if (parentReport) {
      navigate(Screens.ReportVisulisation, {
        reportName: parentReport
      });
      return;
    }
    navigate(Screens.ReportList);
  }, [parentReport, navigate]);

  return function BackButton() {
    return (
      <Pressable onPress={onPress}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <LeftChevronIcon />
          <Text.DisplayS color={Colors.AILO_RED}>
            {parentReportTitle}
          </Text.DisplayS>
        </View>
      </Pressable>
    );
  };
}
