import { useAnalytics } from "@ailo/services";
import { useCallback } from "react";
import { GqlClaim } from "local/domain/bond/graphql/parseBondDetails/types";
import { findRentClaim } from "local/domain/bond/graphql/parseBondDetails/parseRent";
import { findBillClaims } from "local/domain/bond/graphql/parseBondDetails/parseBills";
import { nsEntities, AiloRN } from "@ailo/ailorn";

type BondAnalytics = {
  bondId?: string;
  managementId?: string;
  state?: string;
};

type BondFields = {
  amount: { cents: number };
  reference?: string;
};

type BondUpdatedAnalytics = BondAnalytics & {
  original: BondFields;
  updated: BondFields;
};

type BondClaimUpdatedAnalytics = BondAnalytics & {
  claims?: GqlClaim[];
};

type BondClaimCreatedAnalytics = BondClaimUpdatedAnalytics;

interface UseBondAnalytics {
  onBondCreated: (data: BondAnalytics) => void;
  onBondUpdated: (data: BondUpdatedAnalytics) => void;
  onBondClaimCreated: (data: BondClaimCreatedAnalytics) => void;
  onBondClaimUpdated: (data: BondClaimUpdatedAnalytics) => void;
  onBondClaimRemoved: (data: BondAnalytics) => void;
}

export function useBondAnalytics(): UseBondAnalytics {
  const analytics = useAnalytics();

  const onBondCreated = useCallback(
    (data: BondAnalytics) => {
      analytics.track("Bond Created", {
        bond_id: data.bondId
          ? AiloRN.of(
              nsEntities.propertymanagement.bond,
              data.bondId
            ).toString()
          : undefined,
        management_id: data.managementId
          ? AiloRN.of(
              nsEntities.propertymanagement.management,
              data.managementId
            ).toString()
          : undefined,
        state: data.state
      });
    },
    [analytics]
  );

  const onBondUpdated = useCallback(
    (data: BondUpdatedAnalytics) => {
      const changed = getChangedBondsFields(data.original, data.updated);

      analytics.track("Bond Updated", {
        bond_id: data.bondId
          ? AiloRN.of(
              nsEntities.propertymanagement.bond,
              data.bondId
            ).toString()
          : undefined,
        management_id: data.managementId
          ? AiloRN.of(
              nsEntities.propertymanagement.management,
              data.managementId
            ).toString()
          : undefined,
        state: data.state,
        changed
      });
    },
    [analytics]
  );

  const onBondClaimCreated = useCallback(
    (data: BondClaimCreatedAnalytics) => {
      const claims = data.claims;

      const rentClaim = findRentClaim(claims);
      const hasClaimedRent = (rentClaim?.amount.cents || 0) > 0;

      const billClaims = findBillClaims(claims);
      const numberClaimedBills = billClaims.length;

      analytics.track("Bond Claimed", {
        bond_id: data.bondId
          ? AiloRN.of(
              nsEntities.propertymanagement.bond,
              data.bondId
            ).toString()
          : undefined,
        management_id: data.managementId
          ? AiloRN.of(
              nsEntities.propertymanagement.management,
              data.managementId
            ).toString()
          : undefined,
        state: data.state,
        rent_paid_from_bond: hasClaimedRent,
        num_bills_paid_from_bond: numberClaimedBills
      });
    },
    [analytics]
  );

  const onBondClaimUpdated = useCallback(
    (data: BondClaimUpdatedAnalytics) => {
      const claims = data.claims;

      const rentClaim = findRentClaim(claims);
      const hasClaimedRent = (rentClaim?.amount.cents || 0) > 0;

      const billClaims = findBillClaims(claims);
      const numberClaimedBills = billClaims.length;

      analytics.track("Bond Claim Updated", {
        bond_id: data.bondId
          ? AiloRN.of(
              nsEntities.propertymanagement.bond,
              data.bondId
            ).toString()
          : undefined,
        management_id: data.managementId
          ? AiloRN.of(
              nsEntities.propertymanagement.management,
              data.managementId
            ).toString()
          : undefined,
        state: data.state,
        rent_paid_from_bond: hasClaimedRent,
        num_bills_paid_from_bond: numberClaimedBills
      });
    },
    [analytics]
  );

  const onBondClaimRemoved = useCallback(
    (data: BondAnalytics) => {
      analytics.track("Bond Claim Removed", {
        bond_id: data.bondId
          ? AiloRN.of(
              nsEntities.propertymanagement.bond,
              data.bondId
            ).toString()
          : undefined,
        management_id: data.managementId
          ? AiloRN.of(
              nsEntities.propertymanagement.management,
              data.managementId
            ).toString()
          : undefined,
        state: data.state
      });
    },
    [analytics]
  );

  return {
    onBondCreated,
    onBondUpdated,
    onBondClaimCreated,
    onBondClaimUpdated,
    onBondClaimRemoved
  };
}

function getChangedBondsFields(
  original: BondFields,
  updated: BondFields
): string[] {
  const changed = [];
  if (original.amount.cents !== updated.amount.cents) {
    changed.push("amount");
  }
  if (original.reference !== updated.reference) {
    changed.push("reference");
  }
  return changed;
}
