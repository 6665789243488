import { AiloRN, services } from "@ailo/ailorn";
import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import {
  GetManagementPropertyKeysQueryVariables,
  KeyFragment,
  useGetManagementPropertyKeysQuery
} from "local/graphql";

export function useGetManagementPropertyKeys(
  managementId: string,
  organisationAilorn: string
): MappedQueryResult<
  { propertyAilorn: string; keys: KeyFragment[] },
  GetManagementPropertyKeysQueryVariables
> {
  const result = useGetManagementPropertyKeysQuery({
    variables: {
      id: managementId,
      organisationAilorn
    }
  });

  return useMappedQueryResult(result, (data) => {
    if (!data?.management) {
      throw new ApolloError({
        errorMessage: `[Management property keys] Management "${managementId}" not found`
      });
    }

    const propertyId = data.management.property?.id;

    if (!propertyId) {
      throw new ApolloError({
        errorMessage: `[Management property id] Property ID not found for management "${managementId}"`
      });
    }

    if (!data.management.property.keys) {
      throw new ApolloError({
        errorMessage: `[Management property keys] Property ${propertyId} keys not found`
      });
    }

    return {
      propertyAilorn: AiloRN.of(
        services.PropertyManagement.property,
        propertyId
      ).toString(),
      keys: data.management?.property.keys
    };
  });
}
