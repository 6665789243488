import React from "react";
import { View } from "react-native";
import { Button } from "@ailo/ui";
import { Colors, DownCaretIcon, Text } from "@ailo/primitives";
import styled from "styled-components/native";

interface Props {
  label: string;
  onPress?: () => void;
  rightComponent?: React.ReactElement;
}

export function FilterSelectorButton({
  label,
  onPress,
  rightComponent
}: Props): React.ReactElement {
  return (
    <StyledButton
      variant={"secondary"}
      onPress={onPress}
      disabled={!onPress}
      backgroundColor={{
        default: Colors.WHITE,
        disabled: Colors.WHITE,
        hovered: Colors.INTERACTION.WHITE.HOVER,
        pressed: Colors.INTERACTION.WHITE.PRESSED
      }}
      contentContainerStyle={{ flex: 1 }}
    >
      <TeamSelectorContainer>
        <View>
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{"Filter"}</Text.BodyS>
          <View style={{ flexDirection: "row" }}>
            <Text.BodyM weight={"medium"}>{label}</Text.BodyM>
            {onPress && (
              <DownCaretIcon
                width={20}
                height={20}
                color={Colors.SPACE_BLACK}
              />
            )}
          </View>
        </View>
        {rightComponent}
      </TeamSelectorContainer>
    </StyledButton>
  );
}

const TeamSelectorContainer = styled(View)`
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  padding-horizontal: 0; // Required, otherwise padding-left: 24px isn't respected
  padding-left: 24px;
  padding-right: 16px;
  border-radius: 0;
  border-width: 0;
  border-bottom-width: 1px;
  flex-direction: row;
  justify-content: flex-start;
  height: 72px;
`;
