import React, { useCallback } from "react";
import { useCurrentAgencyOrg } from "local/common";
import { useToastContext } from "@ailo/ui";
import { useSynchronisedLoad } from "@ailo/primitives";
import { useCreateActionAnalytics } from "./useCreateActionAnalytics";
import { useGetAssignees } from "../../useGetAssignees";
import { useCreateAction } from "./useCreateAction";
import { ActionForm } from "../ActionForm";
import { ActionFormData } from "../ActionFormData";
import { AiloRN, services } from "@ailo/ailorn";

interface Props {
  projectId: string;
  onFormSubmitting: (isSubmitting: boolean) => void;
  onDismiss: () => void;
  setSubmitError: (hasError: boolean) => void;
}

export function CreateActionForm({
  projectId,
  onFormSubmitting,
  onDismiss,
  setSubmitError
}: Props): React.ReactElement {
  const { id: organisationId } = useCurrentAgencyOrg();
  const toast = useToastContext();
  const { onFormOpened, onValueUpdated, onSubmitSuccess } =
    useCreateActionAnalytics(projectId);

  const { assignees, loading: assigneesLoading } = useGetAssignees({
    organisationId
  });
  const loading = useSynchronisedLoad(assigneesLoading);

  const [createAction] = useCreateAction({
    onSuccess: ({ action, formValues }) => {
      toast.show({
        type: "success",
        message: "Action created"
      });

      onDismiss();
      onSubmitSuccess(action, formValues);
    },
    onError: () => {
      setSubmitError(true);
    }
  });

  const submit = useCallback(
    async (data: ActionFormData): Promise<void> => {
      const { name, description, assignee, dueDate, type } = data;

      if (!assignee) {
        throw new Error("Assignee cannot be empty");
      }

      await createAction(
        {
          projectId,
          name,
          description,
          assignee: AiloRN.of(services.AuthZ.legalEntity, assignee.id),
          dueDate,
          type
        },
        data
      );
    },
    [createAction, projectId]
  );

  return (
    <ActionForm
      type={"create"}
      onSubmit={submit}
      onCancel={onDismiss}
      assignees={assignees}
      loading={loading}
      style={{
        maxWidth: 680,
        width: "100%",
        flex: 1,
        alignSelf: "flex-end"
      }}
      onValueUpdated={onValueUpdated}
      onFocus={onFormOpened}
      onFormSubmitting={onFormSubmitting}
    />
  );
}
