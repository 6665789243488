import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback
} from "react";
import {
  Badge,
  CellContainer,
  CheckboxCellLoading,
  CheckboxInput,
  CheckboxThreeStateInput,
  GridColumnOptions,
  HeaderCellContainer,
  TextCell,
  TextCellContent
} from "@ailo/ui";
import { DisbursementRow } from "./useGetDisbursements";

interface DisbursementGridColumn extends GridColumnOptions<DisbursementRow> {
  renderLoadingCell: GridColumnOptions<DisbursementRow>["renderCell"];
  renderLoadingHeaderCell?: GridColumnOptions<DisbursementRow>["renderHeaderCell"];
}

function useDisbursementListColumns({
  selectedDisbursementIds,
  setSelectedDisbursementIds,
  disbursementIds,
  total
}: {
  selectedDisbursementIds: string[];
  setSelectedDisbursementIds: Dispatch<SetStateAction<string[]>>;
  disbursementIds: string[];
  total: number;
}): DisbursementGridColumn[] {
  const DisbursementCheckBoxCell = useCallback(
    ({ row, rowIndex }: { row: DisbursementRow; rowIndex: number }) => {
      return (
        <CheckBoxCell
          row={row}
          rowIndex={rowIndex}
          selectedDisbursementIds={selectedDisbursementIds}
          setSelectedDisbursementIds={setSelectedDisbursementIds}
        />
      );
    },
    [selectedDisbursementIds, setSelectedDisbursementIds]
  );

  const DisbursementCheckBoxHeaderCell = useCallback(() => {
    return (
      <CheckBoxHeaderCell
        selectedDisbursementIds={selectedDisbursementIds}
        setSelectedDisbursementIds={setSelectedDisbursementIds}
        disbursementIds={disbursementIds}
        total={total}
      />
    );
  }, [
    selectedDisbursementIds,
    setSelectedDisbursementIds,
    disbursementIds,
    total
  ]);
  const columns: DisbursementGridColumn[] = [
    {
      name: "select",
      renderCell: DisbursementCheckBoxCell,
      renderLoadingCell: CheckboxCellLoading,
      renderHeaderCell: DisbursementCheckBoxHeaderCell,
      renderLoadingHeaderCell: CheckBoxHeaderCellLoading,
      maxWidth: 40
    },
    {
      name: "Names",
      renderCell: NameCell,
      renderLoadingCell: NamesLoadingCell,
      sortable: true,
      key: "names"
    },
    {
      name: "Folio ID",
      renderCell: FolioCell,
      renderLoadingCell: TextCell.Loading
    },
    {
      name: "Withheld",
      renderCell: WithheldCell,
      renderLoadingCell: TextCell.Loading,
      horizontalAlign: "right"
    },
    {
      name: "Payouts",
      renderCell: PayoutsCell,
      renderLoadingCell: TextCell.Loading,
      horizontalAlign: "right"
    }
  ];

  return columns;
}

function PayoutsCell({
  row
}: {
  row: DisbursementRow;
  rowIndex: number;
}): ReactElement {
  return <TextCell>{row.payouts.format()}</TextCell>;
}

function WithheldCell({
  row
}: {
  row: DisbursementRow;
  rowIndex: number;
}): ReactElement {
  return <TextCell>{row.withheld.format()}</TextCell>;
}

function FolioCell({
  row
}: {
  row: DisbursementRow;
  rowIndex: number;
}): ReactElement {
  return <TextCell>{row.folioId}</TextCell>;
}

function NameCell({
  row
}: {
  row: DisbursementRow;
  rowIndex: number;
}): ReactElement {
  const namesFormatted = row.names.join(", ");
  return (
    <CellContainer>
      <TextCellContent weight={"medium"}>{namesFormatted}</TextCellContent>
      <Badge type={row.category} style={{ alignSelf: "flex-start" }} />
    </CellContainer>
  );
}

function CheckBoxCell({
  row,
  selectedDisbursementIds,
  setSelectedDisbursementIds
}: {
  row: DisbursementRow;
  rowIndex: number;
  selectedDisbursementIds: string[];
  setSelectedDisbursementIds: Dispatch<SetStateAction<string[]>>;
}): ReactElement {
  const onChange = useCallback(
    (nextValue: boolean) =>
      setSelectedDisbursementIds((prev) =>
        nextValue ? [...prev, row.id] : prev.filter((id) => id !== row.id)
      ),
    [row.id, setSelectedDisbursementIds]
  );

  return (
    <CellContainer style={{ paddingRight: 0 }}>
      <CheckboxInput
        value={selectedDisbursementIds.includes(row.id)}
        onChange={onChange}
      />
    </CellContainer>
  );
}

function CheckBoxHeaderCell({
  selectedDisbursementIds,
  setSelectedDisbursementIds,
  disbursementIds,
  total
}: {
  selectedDisbursementIds: string[];
  setSelectedDisbursementIds: Dispatch<SetStateAction<string[]>>;
  disbursementIds: string[];
  total: number;
}): ReactElement {
  const atLeastOneDisbursementSelected = selectedDisbursementIds.length > 0;
  const checkboxValue =
    selectedDisbursementIds.length === total && total > 0
      ? "checked"
      : atLeastOneDisbursementSelected
      ? "partial"
      : "unchecked";

  return (
    <HeaderCellContainer style={{ paddingRight: 0 }}>
      <CheckboxThreeStateInput
        disabled={disbursementIds.length === 0}
        value={checkboxValue}
        onChange={(value) => {
          setSelectedDisbursementIds(
            value === "checked" ? disbursementIds : []
          );
        }}
      />
    </HeaderCellContainer>
  );
}

function NamesLoadingCell(): ReactElement {
  return (
    <CellContainer>
      <TextCellContent.AdjustableLoading width={120} />
      <Badge.Loading width={80} />
    </CellContainer>
  );
}

function CheckBoxHeaderCellLoading(): ReactElement {
  return (
    <HeaderCellContainer>
      <CheckboxInput disabled />
    </HeaderCellContainer>
  );
}

export { useDisbursementListColumns };
