import { ClaimTenancyData } from "local/domain/bond";
import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import {
  GetClaimTenancyBondDetailsQueryVariables,
  useGetClaimTenancyBondDetailsQuery
} from "local/graphql";
import { parseBondDetails } from "./parseBondDetails";

export function useGetClaimTenancyBondDetails({
  tenancyId,
  includePaidBills = false
}: {
  tenancyId: string;
  includePaidBills: boolean;
}): MappedQueryResult<
  ClaimTenancyData | undefined,
  GetClaimTenancyBondDetailsQueryVariables
> {
  const paidBillsFilter = includePaidBills ? null : false; // a null filter will not filter bills by its paid status

  const result = useGetClaimTenancyBondDetailsQuery({
    variables: { tenancyId, paidBills: paidBillsFilter }
  });

  return useMappedQueryResult(result, (data) => {
    return parseBondDetails(data);
  });
}
