import React from "react";
import { Badge, CellContainer } from "@ailo/ui";
import { useGetDaysInArrearsFromOwingQuery } from "local/graphql";
import { ErrorCell } from "./ErrorCell";
import { BasePropertyListRow } from "../BasePropertyListRow";
import { LocalDate } from "@ailo/date";

export function DaysInArrearsFromOwingCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetDaysInArrearsFromOwingQuery({
    variables: { managementId }
  });

  if (loading && !data) return <Loading />;

  const rentOwing = data?.management?.mostRecentTenancy?.rent;
  if (!rentOwing)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  //TODO: query from owing instead of manually calculating
  const daysInArrears = LocalDate.today().diff(
    new LocalDate(rentOwing.progress.arrears.date),
    "days"
  );

  return (
    <CellContainer style={{ alignItems: "flex-end" }}>
      <Badge type={"critical"}>
        {`${daysInArrears} ${Math.abs(daysInArrears) !== 1 ? "days" : "day"}`}
      </Badge>
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer style={{ alignItems: "flex-end" }}>
      <Badge.Loading
        style={{
          width: 60
        }}
      />
    </CellContainer>
  );
}

DaysInArrearsFromOwingCell.Loading = Loading;
