import { fileIsLoaded, FilesContextProvider } from "@ailo/domains";
import { Colors, opacify } from "@ailo/primitives";
import {
  Alert,
  Button,
  ErrorModal,
  FormFieldRow,
  Money,
  Separator,
  SpinnerOverlay,
  TextInputFormField,
  useToastContext
} from "@ailo/ui";
import { FullPageHeader, Screens, useRoute } from "local/common";
import {
  ApplyManagementFeeCheckboxInput,
  PayeeInput,
  PayerInput
} from "local/domain/bill/AddBillForm";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import { AddExpenseFormData, useAddExpense } from "local/domain/expense";
import {
  CreateSupplierModal,
  CreateSupplierPaymentMethodModal
} from "local/domain/supplier";
import { FileKind } from "local/graphql";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ScrollView, StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { BillInputs } from "./BillInputs";
import { PaymentDestinationInput } from "./BillInputs/PaymentDestinationInput";
import { FeeInputs } from "./FeeInputs";

/**
 * Used to force supplier creation and payment method modals to be the same height.
 */
const MODAL_FIXED_HEIGHT = 600;

export interface AddExpenseFormProps {
  managingEntityAilorn: string;
  managementAgreementId: string;
  propertyAddress: string;
  weeklyRent?: Money;
  onViewFeeSchedulePress: () => void;
  onClose: () => void;
  style?: StyleProp<ViewStyle>;
}

export function AddExpenseForm({
  managingEntityAilorn,
  managementAgreementId,
  propertyAddress,
  weeklyRent,
  onViewFeeSchedulePress,
  onClose,
  style
}: AddExpenseFormProps): ReactElement {
  const { managementId } = useRoute<Screens.AddExpense>().params!;

  const toast = useToastContext();
  const form = useFormContext<AddBillFormData>();
  const { setValue, register, unregister, watch } = form;

  const { submit, sending, formError, dismissFormError } = useAddExpense({
    managementId,
    managementAgreementId,
    managingEntityAilorn,
    propertyAddress,
    weeklyRent,
    onSubmitSuccess: () => {
      toast.show({
        type: "success",
        message: "Expense Added"
      });
      form.reset();
      onClose();
    }
  });

  const [fieldsInIntermediateState, setFieldsInIntermediateState] = useState(
    new Set<keyof AddExpenseFormData>()
  );

  function toggleFieldsInIntermediateState(
    field: keyof AddExpenseFormData,
    inIntermediateState: boolean
  ): void {
    if (inIntermediateState) {
      fieldsInIntermediateState.add(field);
    } else {
      fieldsInIntermediateState.delete(field);
    }
    setFieldsInIntermediateState(fieldsInIntermediateState);
  }

  const [createSupplierModalOpen, toggleCreateSupplierModal] = useState(false);
  const [
    createSupplierPaymentMethodModalOpen,
    toggleCreateSupplierPaymentMethodModal
  ] = useState(false);

  useEffect(() => {
    register({ name: "fieldUnderInvoiceNumberWasFocused" });
    return (): void => {
      unregister("fieldUnderInvoiceNumberWasFocused");
    };
  }, [register, unregister]);

  const payerOption = watch("payerId");
  const selectedSupplier = watch("supplierReference");
  const supplierName = selectedSupplier?.label;
  const supplierReference = selectedSupplier?.value;

  const attachmentsFiles = watch("attachmentsFiles");
  const attachmentsAreUploading = !(attachmentsFiles ?? []).every(fileIsLoaded);

  const payerAndPayeePopulated = payerOption?.value && supplierReference;
  const payerIsTenancy = payerOption?.type === "tenancy";
  const payeeIsAgency = supplierReference === managingEntityAilorn;

  const isFee = payerAndPayeePopulated && !payerIsTenancy && payeeIsAgency;
  const isBill = payerAndPayeePopulated && !payeeIsAgency;

  const formSubmissionDisabled =
    fieldsInIntermediateState.size > 0 ||
    form.formState.isSubmitting ||
    !form.formState.isDirty ||
    attachmentsAreUploading ||
    (payerIsTenancy && payeeIsAgency);

  const scrollViewRef = useRef<ScrollView>(null);

  return (
    <FilesContextProvider kind={FileKind.BillBillAttachment}>
      <View style={style}>
        <ScrollView
          style={{ paddingHorizontal: 60, paddingBottom: 60 }}
          ref={scrollViewRef}
        >
          <FullPageHeader header={"Add Expense"} />

          <FormFieldRow>
            <PayerInput managementId={managementId} />
            <PayeeInput
              defaultToAgency
              managementId={managementId}
              onCreateSupplierClick={() => toggleCreateSupplierModal(true)}
            />
          </FormFieldRow>

          {payerIsTenancy && payeeIsAgency && (
            <Alert type={"error"} style={{ marginTop: 16 }}>
              {"Agency fees cannot be charged to renters"}
            </Alert>
          )}

          {isBill && (
            <>
              <PaymentDestinationInput
                onCreatePaymentMethodClick={() =>
                  toggleCreateSupplierPaymentMethodModal(true)
                }
                style={{ marginTop: 16 }}
              />
              <ApplyManagementFeeCheckboxInput
                managementId={managementId}
                style={{ marginTop: 16 }}
              />
            </>
          )}

          {(isFee || isBill) && (
            <Separator style={{ marginLeft: 0, marginTop: 40 }} />
          )}

          {isBill && <BillInputs scrollViewRef={scrollViewRef} />}

          {isFee && (
            <FeeInputs
              managementId={managementId}
              weeklyRent={weeklyRent}
              toggleFieldsInIntermediateState={toggleFieldsInIntermediateState}
              onViewFeeSchedulePress={onViewFeeSchedulePress}
            />
          )}

          {formError && (
            <ErrorModal
              title={formError.title}
              description={formError.message}
              onDismiss={dismissFormError}
            />
          )}
        </ScrollView>

        <ActionsContainer>
          <Button.Secondary
            disabled={form.formState.isSubmitting}
            onPress={() => {
              form.reset();
              onClose();
            }}
          >
            {"Cancel"}
          </Button.Secondary>
          <Button.Primary
            disabled={formSubmissionDisabled}
            style={{ marginLeft: "auto" }}
            testID={"SubmitButton"}
            onPress={form.handleSubmit(submit)}
          >
            {"Add Expense"}
          </Button.Primary>
        </ActionsContainer>

        {sending && <SpinnerOverlay />}

        {createSupplierModalOpen ? (
          <CreateSupplierModal
            height={MODAL_FIXED_HEIGHT}
            onCancel={() => toggleCreateSupplierModal(false)}
            onSubmit={(data) => {
              setValue("supplierReference", {
                label: data.name,
                value: data.ailoRN
              });
              toggleCreateSupplierModal(false);
              setTimeout(
                () => toggleCreateSupplierPaymentMethodModal(true),
                100
              );
            }}
          />
        ) : null}

        {createSupplierPaymentMethodModalOpen &&
        supplierReference &&
        supplierName ? (
          <CreateSupplierPaymentMethodModal
            height={MODAL_FIXED_HEIGHT}
            supplier={{
              ailoRN: supplierReference,
              name: supplierName
            }}
            onCancel={() => toggleCreateSupplierPaymentMethodModal(false)}
            onSubmit={() => {
              toggleCreateSupplierPaymentMethodModal(false);
            }}
          />
        ) : null}
      </View>
    </FilesContextProvider>
  );
}

AddExpenseForm.Loading = function Loading({
  onClose,
  style
}: Pick<AddExpenseFormProps, "onClose" | "style">) {
  return (
    <View style={style}>
      <ScrollView style={{ paddingHorizontal: 60, paddingBottom: 60 }}>
        <FullPageHeader header={"Add Expense"} />

        <FormFieldRow>
          <TextInputFormField.Loading />
          <TextInputFormField.Loading />
        </FormFieldRow>
      </ScrollView>
      <ActionsContainer>
        <Button.Secondary onPress={onClose}>{"Cancel"}</Button.Secondary>
        <Button.Primary
          disabled={true}
          style={{ marginLeft: "auto" }}
          testID={"SubmitButton"}
        >
          {"Add Expense"}
        </Button.Primary>
      </ActionsContainer>
    </View>
  );
};

const ActionsContainer = styled(View)`
  display: flex;
  flex-direction: row;
  border-top-width: 1px;
  border-top-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  padding-horizontal: 60px;
  padding-top: 16px;
  padding-bottom: 24px;
`;
