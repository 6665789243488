import { ExpenseStatusBadge, getSupplierName } from "@ailo/domains";
import {
  ListItem,
  ListItemDate,
  ListItemStyle,
  LocalDate,
  Money
} from "@ailo/ui";
import { AmountAndStatus } from "local/domain/expense";
import { UpcomingAndPaidBillFieldsFragment } from "local/graphql";
import React, { ReactElement } from "react";

interface Props {
  bill: UpcomingAndPaidBillFieldsFragment;
  onPress?: () => void;
  style?: ListItemStyle;
}

export function BillListItem({ bill, onPress, style }: Props): ReactElement {
  return (
    <ListItem
      testID={undefined}
      header={getSupplierName(bill.supplier)}
      subHeader={bill.taxCategory.name}
      leftComponent={
        <ListItemDate date={bill.issueDateV2} showSticks={false} />
      }
      rightComponent={
        <AmountAndStatus
          amount={Money.from(bill.amount)}
          hasRightChevronIcon
          statusBadge={
            <ExpenseStatusBadge
              status={bill.agencyStatus}
              dueHint={LocalDate.from(bill.dueDateV2).format("D MMM")}
            />
          }
        />
      }
      onPress={() => onPress?.()}
      style={style}
    />
  );
}
