import { useGetManagementFirstPublishedQuery } from "local/graphql";
import { useEnvironment } from "@ailo/services";
import moment from "moment";

export function useManagementPublishedRecently(managementId: string): {
  publishedRecently: boolean | undefined;
  loading: boolean;
} {
  const { MANAGEMENT_PUBLISHED_RECENTLY_CUTOFF } = useEnvironment();

  const { data, loading } = useGetManagementFirstPublishedQuery({
    variables: {
      id: managementId
    }
  });

  if (loading || !data) {
    return { publishedRecently: undefined, loading };
  }

  const firstPublishedAt = data.management?.firstPublishedAt;
  let publishedRecently = false;
  if (firstPublishedAt) {
    const daysSinceFirstPublished = moment().diff(
      moment(firstPublishedAt),
      "days"
    );

    publishedRecently =
      daysSinceFirstPublished < MANAGEMENT_PUBLISHED_RECENTLY_CUTOFF;
  }

  return { publishedRecently, loading };
}
