import { CenteredSpinner, MessageList } from "@ailo/domains";
import React, { ReactElement } from "react";
import { useCloseThread } from "local/tabs/comms/navigation";

interface Props {
  loading: boolean;
}

export function ChatSidebarContents({ loading }: Props): ReactElement {
  const closeThread = useCloseThread();

  if (loading) {
    return <CenteredSpinner />;
  }

  return <MessageList onError={closeThread} />;
}
