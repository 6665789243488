import React from "react";
import { View } from "react-native";
import { SFC, Skeleton, Text } from "@ailo/primitives";
import { Card } from "@ailo/ui";

const originalIconContainerSize = 24;
const originalIconDiameter = 20;
const iconContainerSize = 20;
const iconDiameter =
  (iconContainerSize / originalIconContainerSize) * originalIconDiameter;

const PropertyHealthCardLoading: SFC = ({ style }) => (
  <View style={style}>
    <Card
      style={{
        margin: 0,
        padding: 16
      }}
    >
      <View style={{ marginBottom: 12, flexDirection: "row" }}>
        <View
          style={{
            width: iconContainerSize,
            height: iconContainerSize,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Skeleton
            style={{
              width: iconDiameter,
              height: iconDiameter,
              borderRadius: iconDiameter / 2
            }}
          />
        </View>
        <Text.BodyM.Loading width={40} style={{ marginLeft: 6 }} />
      </View>
      <Text.BodyS.Loading width={80} />
      <Text.BodyS.Loading width={128} style={{ marginTop: 8 }} />
    </Card>
  </View>
);

export { PropertyHealthCardLoading };
