import React, { ReactElement } from "react";
import { TenancyInspectionScheduleFragment } from "local/graphql";
import { BaseActionActivity } from "../BaseActionActivity";
import { ActionActivitySectionHeader } from "../ActionActivitySectionHeader";
import {
  InspectionScheduleDescriptionList,
  InspectionScheduleManageMenu
} from "local/domain/inspection";

interface Props {
  inspectionSchedule: TenancyInspectionScheduleFragment;
}

export function ConfirmInspectionScheduleView({
  inspectionSchedule
}: Props): ReactElement {
  return (
    <BaseActionActivity>
      <ActionActivitySectionHeader
        style={{ marginBottom: 20 }}
        title={"Routine inspection"}
        rightColumn={
          <InspectionScheduleManageMenu
            inspectionSchedule={inspectionSchedule}
          />
        }
      />
      <InspectionScheduleDescriptionList
        inspectionSchedule={inspectionSchedule}
      />
    </BaseActionActivity>
  );
}
