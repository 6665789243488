import { GridRenderRowContainerProps, Table } from "@ailo/ui";
import {
  FunctionalReportTable,
  ReportTableColumn,
  ReportTableRow
} from "../../../components/ReportTable";

import { ContactsTabView, Screens, useNavigation } from "local/common";
import React, { useCallback } from "react";
import { useGetInvestorOnAppTableDataQuery } from "local/graphql";

import { View } from "react-native";
import { useReportTeamContext } from "../../..";
import { reports } from "../../../reportsDetails";

function RowContainer({
  row,
  style,
  children
}: GridRenderRowContainerProps<{
  key: string;
  legalEntityId: string;
}>): React.ReactElement {
  const navigation = useNavigation();
  const viewInvestor = useCallback(
    (investorId: string) => {
      navigation.navigate(Screens.ContactsTab, {
        tab: ContactsTabView.CUSTOMERS,
        contactId: investorId,
        contactType: "Person"
      });
    },
    [navigation]
  );

  return (
    // This `onClick: onPress` is a workaround for
    // bugged behaviour of links in `react-native-web`.
    // Probably we'll be able to remove it
    // once we upgrade to `react-native-web >= 0.13.0`
    // ( https://github.com/necolas/react-native-web/pull/1353#issuecomment-678441915 ).
    <View
      {...{ onClick: (): void => viewInvestor(row.legalEntityId) }}
      style={style}
    >
      {children}
    </View>
  );
}

export function InvestorOnAppTable(): React.ReactElement {
  // construct variables
  const variables = useReportTeamContext();
  // get data
  const visualisationData = useGetInvestorOnAppTableDataQuery({ variables });
  const reportData = visualisationData.data?.investorOnAppReport;
  if (!reportData?.rows) return <Table.Loading />;

  if (reportData.rows.length < 1) return <Table.Loading />;
  // if a column filter exists, filter the columns
  const rows = reportData.rows.map((r) => {
    return r as ReportTableRow;
  }) as [ReportTableRow];
  const columns = reportData.columns as [ReportTableColumn];

  return (
    <FunctionalReportTable
      reportTitle={reports.investors.reportHeading}
      columns={columns}
      rowContainer={RowContainer}
      rows={rows}
    />
  );
}
