import { EditButton, useGlobalModal } from "@ailo/ui";
import { RentFragment } from "local/graphql";
import { RentReviewModalForm } from "local/modals/RentReviewModalForm";
import React from "react";

export function EditRentButton({
  tenancyId,
  assignedRent,
  editableRent
}: {
  tenancyId: string;
  assignedRent: RentFragment;
  editableRent?: RentFragment | null;
}): React.ReactElement {
  const modal = useGlobalModal();
  if (assignedRent.id === editableRent?.id) {
    return (
      <EditButton
        onPress={() => {
          modal.show(
            <RentReviewModalForm
              editableRent={assignedRent}
              tenancyId={tenancyId}
            />
          );
        }}
      />
    );
  }
  return <EditButton.Disabled tooltipText={"Rent no longer editable"} />;
}
