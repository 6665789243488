import React, { useCallback, useState } from "react";
import { useAnalytics } from "local/common";
import { useOnFocus } from "@ailo/services";
import { MIN_NO_CHARS_TO_START_SEARCH } from "../usePaginationRequest";
import { SearchBox } from "@ailo/ui";
import styled from "styled-components/native";
import { View } from "react-native";
import { Colors } from "@ailo/primitives";

function BasicContactsList({
  tabName,
  tabNavigation,
  ContactsListContent
}: {
  tabName: "Customer" | "Other Contact";
  tabNavigation: (queryString?: string) => React.ReactElement;
  clearSidebarInfo: () => void;
  ContactsListContent: ({
    queryString,
    header
  }: {
    queryString: string;
    header: (showSearchBar?: boolean) => React.ReactElement;
  }) => React.ReactElement;
}): React.ReactElement {
  const analytics = useAnalytics();

  const onFocus = useCallback((): void => {
    analytics.trackScreenVisited(`${tabName} List`, {});
  }, [tabName, analytics]);
  useOnFocus(onFocus);

  const [queryString, setQueryString] = useState<string>("");

  const updateQueryString = (currentQueryString: string): void => {
    const shouldUpdate =
      currentQueryString.length >= MIN_NO_CHARS_TO_START_SEARCH ||
      currentQueryString.length == 0;
    if (shouldUpdate) {
      setQueryString(currentQueryString);
      return;
    }
  };

  const clearQueryString = (): void => {
    setQueryString("");
  };

  const headerComponent = (showSearchBar?: boolean): React.ReactElement => {
    return (
      <HeaderContainer>
        {tabNavigation(queryString)}
        {showSearchBar && (
          <SearchBoxContainer>
            <SearchBox
              onClear={clearQueryString}
              onRetry={updateQueryString}
              onInputFinish={updateQueryString}
              onFocus={(): void => {
                analytics.trackContactsSearchFocus(tabName);
              }}
              placeholder={"Search name, email address or phone number"}
              testID={`${tabName}sSearchBox`}
              initialQueryString={queryString}
              contractedWidth={800}
              expandedWidth={1000}
              isMulti={false}
              controlType={"full-width"}
            />
          </SearchBoxContainer>
        )}
      </HeaderContainer>
    );
  };

  return (
    <ContactsListContent header={headerComponent} queryString={queryString} />
  );
}

const SearchBoxContainer = styled(View)`
  padding: 0 16px;
  min-height: 72px;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  background-color: ${Colors.WHITE};
  box-shadow: 0 1px 4px rgba(28, 30, 38, 0.2);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

const HeaderContainer = styled(View)`
  width: 100%;
  background-color: ${Colors.CLOUD};
`;

export { BasicContactsList };
