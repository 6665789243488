import React from "react";
import { Colors } from "@ailo/primitives";
import { Money, TextCell } from "@ailo/ui";
import { useGetBalanceOwedQuery } from "local/graphql";
import { ErrorCell } from "./ErrorCell";
import { BasePropertyListRow } from "../BasePropertyListRow";

function BalanceOwedCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetBalanceOwedQuery({
    variables: { managementId }
  });

  if (loading && !data) return <Loading />;

  const liability = data?.management?.mostRecentTenancy?.liability;
  if (!liability)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const overdueAmount = liability.overdueAmount.cents;
  if (overdueAmount == null)
    return <TextCell color={Colors.TEXT.DARK.SECONDARY}>{"—"}</TextCell>;

  return <TextCell>{Money.fromCents(overdueAmount).format()}</TextCell>;
}

function Loading(): React.ReactElement {
  return <TextCell.Loading />;
}

BalanceOwedCell.Loading = Loading;

export { BalanceOwedCell };
