import { isPresent } from "ts-is-present";
import { FileDomainType, fragmentToFile } from "@ailo/domains";
import { GetProjectDetailsQuery } from "local/graphql";

export type ProjectFile = FileDomainType & { projectFileId: string };

export function getFiles(
  files: NonNullable<GetProjectDetailsQuery["project"]>["files"]
): ProjectFile[] {
  return (
    files?.items
      ?.map(({ id, file }) => {
        const processedFile = fragmentToFile(file!);
        return processedFile ? { ...processedFile, projectFileId: id } : null;
      })
      .filter(isPresent) ?? []
  );
}
