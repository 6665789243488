import { DateRange, LocalDate } from "@ailo/date";
export type Digit = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";

export abstract class DatePeriod {
  public date: LocalDate;

  constructor(input: string | LocalDate) {
    this.date = typeof input === "string" ? this.parse(input) : input;
  }

  public abstract get startDate(): LocalDate;
  public abstract get endDate(): LocalDate;

  public abstract shortFormat(): string;
  public abstract longFormat(): string;
  public abstract from(input: LocalDate | string): DatePeriod;
  public abstract parse(input: string | null | undefined): LocalDate;

  public get value(): string {
    return this.date.toString();
  }

  public nextPeriod(): DatePeriod {
    return this.from(this.endDate.add(1, "day"));
  }

  public previousPeriod(): DatePeriod {
    return this.from(this.startDate.subtract(1, "day"));
  }

  public get dateRange(): DateRange {
    return new DateRange({ start: this.startDate, end: this.endDate });
  }
}
