import React from "react";
import { LedgerDetailsErrorBoundary } from "local/domain/ledger";
import { SidebarContent } from "@ailo/ui";
import { AiloRN, services } from "@ailo/ailorn";
import { useOnFocus } from "@ailo/services";
import { useGetPropertyWalletMoneyManagementSettingsQuery } from "local/graphql";
import { View } from "react-native";
import {
  AutoBillPaymentSettingsDisplay,
  AutoWithdrawSettingsDisplay,
  Divider
} from "./components";
import styled from "styled-components/native";

interface Props {
  managementId: string;
  onClose: () => void;
  onLegalEntityPress?: (id: string, entityType: "Person" | "Company") => void;
}

export function PropertyWalletMoneyManagementSettingsSidebarContent({
  managementId,
  onLegalEntityPress,
  onClose
}: Props): React.ReactElement {
  return (
    <LedgerDetailsErrorBoundary
      sectionName={"Property Wallet Money Management Settings"}
    >
      <SidebarContent title={"Property Wallet"} closeButton onClose={onClose}>
        <Content
          managementId={managementId}
          onLegalEntityPress={onLegalEntityPress}
        />
      </SidebarContent>
    </LedgerDetailsErrorBoundary>
  );
}

function Content({
  managementId,
  onLegalEntityPress
}: {
  managementId: string;
  onLegalEntityPress?: (id: string, entityType: "Person" | "Company") => void;
}): React.ReactElement {
  const managementRef = AiloRN.of(
    services.PropertyManagement.management,
    managementId
  ).toString();

  const result = useGetPropertyWalletMoneyManagementSettingsQuery({
    variables: {
      walletOwnerReference: managementRef
    }
  });
  const { refetch } = result;
  useOnFocus(refetch);

  return (
    <Container>
      <AutoBillPaymentSettingsDisplay result={result} />
      <Divider />
      <AutoWithdrawSettingsDisplay
        result={result}
        onLegalEntityPress={onLegalEntityPress}
      />
    </Container>
  );
}

function Loading(): React.ReactElement | null {
  return (
    <Container>
      <AutoBillPaymentSettingsDisplay.Loading />
      <Divider />
      <AutoWithdrawSettingsDisplay.Loading />
    </Container>
  );
}

const Container = styled(View)`
  margin-top: -20px;
`;

PropertyWalletMoneyManagementSettingsSidebarContent.Loading = Loading;
