import {
  ChatFileContext,
  useMessageContext,
  useThreadContext
} from "@ailo/domains";
import { Colors, opacify } from "@ailo/primitives";
import { useLayout } from "@react-native-community/hooks";
import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { CloseButton } from "local/common";
import { ChatScreenContainer } from "../ChatScreenContainer";
import { ChatSidebarTitle } from "./ChatSidebarTitle";
import { ChatSidebarContents } from "./ChatSidebarContents";
import { useCloseThread } from "local/tabs/comms/navigation";

export const ChatSidebar = React.memo(function ChatSidebar(): ReactElement {
  const { onLayout } = useLayout();
  const closeThread = useCloseThread();

  const {
    selectedThread: { thread, loading: threadLoading, error: threadError },
    selectedThreadAilorn,
    drafts
  } = useThreadContext();

  const {
    replies: { loading: repliesLoading, error: repliesError }
  } = useMessageContext();

  const errorOnThreadOrReplies = threadError || repliesError;
  const stillLoadingThreadOrReplies =
    (threadLoading || repliesLoading) && !errorOnThreadOrReplies;

  return (
    <ChatScreenContainer onLayout={onLayout}>
      <ChatFileContext
        drafts={drafts}
        messageParent={selectedThreadAilorn}
        dropDisabled={thread?.readonly}
      >
        <ChatSidebarHeader>
          <ChatSidebarTitle
            loading={threadLoading}
            error={threadError}
            subject={thread?.subject}
            numberOfReplies={thread?.numberOfReplies}
          />
          <CloseButton
            style={{ marginLeft: 20, marginRight: 20 }}
            onDismiss={closeThread}
          />
        </ChatSidebarHeader>

        <ChatSidebarContents loading={stillLoadingThreadOrReplies} />
      </ChatFileContext>
    </ChatScreenContainer>
  );
});

const ChatSidebarHeader = styled(View)`
  align-items: center;
  padding-top: 16px;
  padding-left: 20px;
  padding-bottom: 16px;
  height: 72px;
  background-color: ${Colors.WHITE};
  border-bottom-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  flex-direction: row;
`;
