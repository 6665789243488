import React, { FC, useCallback, useState } from "react";
import { useHasFeature, WalletOwnerTransactionsList } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { AiloRN, services } from "@ailo/ailorn";
import { SwitcherHeader } from "local/common";
import { EmptyTransactionsList } from "../EmptyTransactionsList";
import { LedgerDetailsErrorBoundary } from "local/domain/ledger";
import styled from "styled-components/native";
import { View } from "react-native";
import { SwitchableTenancyTransactionList } from "./SwitchableTenancyTransactionList";
import { ManagementWalletCard } from "./ManagementWalletCard";

interface Props {
  managementId: string;
  initialTenancyId: string;
  onLedgerDetailEntryPress: (liabilityEntryId: string) => void;
  onMoneyManagementSettingsPress: () => void;
}

interface Statics {
  Loading: typeof Loading;
}

const TenancyManagementLedgerDetails: FC<Props> & Statics = ({
  managementId,
  initialTenancyId,
  onLedgerDetailEntryPress,
  onMoneyManagementSettingsPress
}) => {
  const tabLabels = ["Tenancy", "Management"];
  const [selectedTab, setSelectedTab] = useState(0);
  const setSelectedTabCallback = useCallback(
    (i: number): void => setSelectedTab(i),
    [setSelectedTab]
  );

  const managementLedgerOwnerRef = AiloRN.of(
    services.PropertyManagement.management,
    managementId
  ).toString();

  const showManagementWallet = useHasFeature(
    PlatformFeatureId.InvestorPropertyWallet
  );

  return (
    <>
      <SwitcherHeader
        options={tabLabels}
        onPress={setSelectedTabCallback}
        selected={selectedTab}
      />
      {selectedTab === 0 ? (
        <LedgerDetailsErrorBoundary sectionName={tabLabels[selectedTab]}>
          <SwitchableTenancyTransactionList
            managementId={managementId}
            initialTenancyId={initialTenancyId}
            onLedgerDetailEntryPress={onLedgerDetailEntryPress}
          />
        </LedgerDetailsErrorBoundary>
      ) : (
        <LedgerDetailsErrorBoundary sectionName={tabLabels[selectedTab]}>
          <WalletOwnerTransactionsList
            headerComponent={
              showManagementWallet ? (
                <WalletContainer>
                  <ManagementWalletCard
                    managementId={managementId}
                    onMoneyManagementSettingsPress={
                      onMoneyManagementSettingsPress
                    }
                  />
                </WalletContainer>
              ) : undefined
            }
            ownerRef={managementLedgerOwnerRef}
            emptyView={EmptyTransactionsList}
            type={"management"}
          />
        </LedgerDetailsErrorBoundary>
      )}
    </>
  );
};

const Loading: FC = () => {
  return (
    <>
      <SwitcherHeader.Loading />
      <WalletOwnerTransactionsList.Loading />
    </>
  );
};

TenancyManagementLedgerDetails.Loading = Loading;

const WalletContainer = styled(View)`
  padding: 0 24px 32px;
`;
export { TenancyManagementLedgerDetails };
