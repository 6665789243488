import React from "react";
import styled from "styled-components/native";
import {
  Link,
  Text,
  Colors,
  Skeleton,
  WarningSubtractedIcon
} from "@ailo/primitives";
import { View } from "react-native";
import { TeamMetric } from "./dashboardCardTeamMetric";
import { useCurrentAgencyOrg } from "local/common";
import { useGetDashboardCardDetailsQuery } from "local/graphql";
import { useReportTeamContext } from "local/tabs/reports/VisualizationScreen";
import { CardErrorWrapper, CardWrapper } from "./DashboardCardWrapper";
import { useOnFocus } from "@ailo/services";
const CardTitle = styled(Text.BodyL)`
  margin-right: auto;
`;

const TitleRowWrapper = styled(View)`
  flex-direction: row;
  margin-bottom: 4px;
  padding-right: 16px;
  padding-left: 16px;
`;

const DrilldownLink = styled(Link)`
  color: ${Colors.AILO_RED};
  text-decoration: none;
`;

const TotalValue = styled(Text.DisplayM)`
  margin-bottom: 16px;
  padding-left: 16px;
`;

export interface DashboardCardProps {
  title: string;
  viewButtonOnPress: () => void;
  noTeam?: boolean;
}

export function DashboardCard({
  title,
  viewButtonOnPress,
  noTeam = false
}: DashboardCardProps): React.ReactElement<DashboardCardProps> {
  const managingEntityId = useCurrentAgencyOrg().id;
  const { teamId, includeUnassigned } = useReportTeamContext({
    excludeUnassignedFromTeamId: true
  });
  const { data, loading, error, refetch } = useGetDashboardCardDetailsQuery({
    variables: {
      name: title,
      managingEntityId,
      teamId: teamId?.length && !noTeam ? teamId : undefined,
      includeUnassigned
    }
  });
  useOnFocus(refetch);

  if (loading) {
    return <DashboardCard.Loading title={title} />;
  }

  if (
    data?.dashboardFigureByName?.data?.__typename !==
      "DashboardFigureTeamPeriodComparison" ||
    data?.dashboardFigureByName?.data?.teams == null ||
    error
  ) {
    return <DashboardCard.Error title={title} refetch={refetch} />;
  }

  const { teams, currentTotal } = data.dashboardFigureByName.data;

  return (
    <CardWrapper>
      <TitleRowWrapper>
        <CardTitle>{title}</CardTitle>
        <DrilldownLink onPress={viewButtonOnPress}>{"View"}</DrilldownLink>
      </TitleRowWrapper>
      <TotalValue>{currentTotal}</TotalValue>
      <View style={{ marginBottom: teams.length !== 0 ? 4 : 0 }} />
      {!noTeam &&
        teams.map(({ currentValue, teamName, id }) => {
          return (
            <TeamMetric
              key={teamName + title}
              teamId={id}
              teamName={teamName}
              value={currentValue}
              dashboardCardNavigation={viewButtonOnPress}
            />
          );
        })}
    </CardWrapper>
  );
}

function Loading({
  title
}: Omit<DashboardCardProps, "viewButtonOnPress">): React.ReactElement {
  return (
    <CardWrapper>
      <TitleRowWrapper>
        <CardTitle>{title}</CardTitle>
        <Skeleton style={{ minWidth: 40, maxHeight: 20 }} />
      </TitleRowWrapper>
      <Skeleton
        style={{
          maxWidth: 88,
          minHeight: 28,
          marginBottom: 24,
          marginLeft: 16
        }}
      />
    </CardWrapper>
  );
}

function ErrorCard({
  title,
  refetch
}: Omit<DashboardCardProps, "viewButtonOnPress"> & {
  refetch: () => void;
}): React.ReactElement {
  return (
    <CardErrorWrapper>
      <WarningSubtractedIcon
        width={36.67}
        height={33.33}
        style={{ margin: "auto", marginBottom: 19 }}
        color={Colors.STATUS.WARNING}
      />
      <Text.BodyM weight={"book"} style={{ marginBottom: 8 }}>
        {"There was a problem loading tenancies in rental arrears "}
        {title.toLowerCase()}
      </Text.BodyM>
      <Text.BodyS
        onPress={() => refetch()}
        weight={"medium"}
        style={{ marginBottom: 8, color: Colors.AILO_RED }}
      >
        {"Reload"}
      </Text.BodyS>
    </CardErrorWrapper>
  );
}

DashboardCard.Loading = Loading;
DashboardCard.Error = ErrorCard;
