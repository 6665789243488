import { LocalDate } from "@ailo/date";
import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import {
  UpdateEndOfTenancyMutation,
  useUpdateEndOfTenancyMutation
} from "local/graphql";
import { useCallback } from "react";
import { SetRequired } from "type-fest";
import { EndTenancyFormData } from "../EndTenancyFormData";

type EndTenancyInput = SetRequired<EndTenancyFormData, "endDate" | "notes">;

export function useEndTenancy({
  onCompleted,
  onError
}: {
  onCompleted?: () => void;
  onError?: () => void;
}): [
  ({ formData }: { formData: EndTenancyInput }) => Promise<void>,
  MutationResult<UpdateEndOfTenancyMutation>
] {
  const toast = useToastContext();
  const [updateEndOfTenancyMutation, result] = useUpdateEndOfTenancyMutation();

  const endTenancy = useCallback(
    async ({
      formData: { endDate, notes, reason, tenancyId }
    }: {
      formData: EndTenancyInput;
    }): Promise<void> => {
      let result: ExecutionResult<UpdateEndOfTenancyMutation>;

      try {
        result = await updateEndOfTenancyMutation({
          variables: {
            id: tenancyId,
            endDate,
            reason,
            notes
          }
        });
        throwIfMutationFailed(result, { dataKey: "updateEndOfTenancy" });
        await onCompleted?.();
        toast.show({
          type: "success",
          message: generateEndDateMessage({ endDate: new LocalDate(endDate) })
        });
      } catch (error) {
        AiloSentry.captureException(error);
        return onError?.();
      }
    },
    [onCompleted, onError, updateEndOfTenancyMutation, toast]
  );

  return [endTenancy, result];
}

function generateEndDateMessage({ endDate }: { endDate: LocalDate }): string {
  const formattedDate = endDate.format("DD MMM YYYY");
  return endDate.isSameOrBefore(LocalDate.today())
    ? `Tenancy ended on ${formattedDate}`
    : `Tenancy ending on ${formattedDate}`;
}
