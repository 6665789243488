import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { Alert, LocalDate, Money } from "@ailo/ui";
import { ProRataPreviewFragment } from "local/graphql";

const DATE_FORMAT = "DD MMM";
export function OutOfCycleInfoAlert({
  proRataPreview: { amountInCents, startDate, endDate },
  style
}: {
  proRataPreview: ProRataPreviewFragment;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  const amount = Money.fromCents(amountInCents).format();
  const startDateFormatted = LocalDate.from(startDate).format(DATE_FORMAT);
  const endDateFormatted = LocalDate.from(endDate).format(DATE_FORMAT);

  return (
    <Alert
      type={"info"}
      message={
        `A rent payment of ${amount} ` +
        `will be required for the period of ` +
        `${startDateFormatted} – ${endDateFormatted}`
      }
      style={style}
    />
  );
}

function Loading({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return <Alert.Loading type={"info"} style={style} />;
}

function Error({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <Alert
      type={"error"}
      message={
        "Unable to fetch important rent information. " +
        "Please try a different date or contact Ailo if error persists."
      }
      style={style}
    />
  );
}

OutOfCycleInfoAlert.Loading = Loading;
OutOfCycleInfoAlert.Error = Error;
