import React, { ReactElement, useEffect, useCallback } from "react";
import { useAnalytics } from "@ailo/services";
import { FormFieldRow, useToastContext } from "@ailo/ui";
import { FormFooter } from "local/common";
import { NoteInput } from "./fields";
import {
  ManagementNoteFormProvider,
  useManagementNoteForm
} from "./ManagementNoteFormContext";
import { useSubmit } from "./useSubmit";

export type ManagementNoteFormMethods = {
  onDismiss: () => void;
  onSuccess: () => void;
  onFormSubmitting?: (isSubmitting: boolean) => void;
  visible: boolean;
};
export type ManagementNoteFormProps = {
  managementId: string;
  managementNoteId?: string;
  note?: string;
} & ManagementNoteFormMethods;

export function ManagementNoteForm({
  managementId,
  managementNoteId,
  note,
  ...props
}: ManagementNoteFormProps): ReactElement {
  const data = {
    mode: managementNoteId && note ? "edit" : "create",
    id: managementNoteId,
    managementId,
    note
  } as const;
  return (
    <ManagementNoteFormProvider data={data}>
      <ManagementNoteFormContent {...props} />
    </ManagementNoteFormProvider>
  );
}
function ManagementNoteFormContent({
  onDismiss,
  onSuccess,
  onFormSubmitting,
  visible
}: ManagementNoteFormMethods): ReactElement {
  const Toast = useToastContext();
  const {
    clearErrors,
    formState: { isSubmitting },
    reset,
    data
  } = useManagementNoteForm();
  const analytics = useAnalytics();

  useEffect(() => {
    if (visible) {
      reset({
        managementId: data.managementId,
        id: data.id || "",
        note: data.note || ""
      });
    }
  }, [visible, reset, data]);

  useEffect(() => {
    onFormSubmitting?.(isSubmitting);
  }, [onFormSubmitting, isSubmitting]);

  const onSubmit = useSubmit({
    onSuccess: () => {
      Toast.show({
        type: "success",
        message: data.mode === "create" ? "Notes added" : "Notes updated"
      });
      analytics.track("Property Note Saved");
      onSuccess();
    },
    onError: () => {
      Toast.show({
        type: "error",
        message:
          data.mode === "create"
            ? "Unable to add notes. Please try again."
            : "Unable to update notes. Please try again."
      });
    }
  });

  const onDismissForm = useCallback(() => {
    clearErrors();
    onDismiss();
  }, [clearErrors, onDismiss]);
  return (
    <>
      <FormFieldRow>
        <NoteInput mode={data.mode} />
      </FormFieldRow>
      <FormFooter
        type={"modal"}
        submitVisible={true}
        onCancel={onDismissForm}
        submitText={data.mode === "create" ? "Add notes" : "Update notes"}
        canGoBack={false}
        style={{ marginTop: 0, paddingBottom: 20 }}
        onSubmit={onSubmit}
        onGoBack={onDismissForm}
        isSubmitting={isSubmitting}
        submitButtonDisabled={isSubmitting}
        cancelButtonDisabled={isSubmitting}
      />
    </>
  );
}
