import { RecurringDate } from "@ailo/date";
import { AutoPayIcon, Colors, Text } from "@ailo/primitives";
import { Alert, ListItem, ListItemStyle, Money } from "@ailo/ui";
import { GetManagementRecurringFeesQuery } from "local/graphql";
import { capitalize } from "lodash";
import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { FeeItemMenu } from "./FeeItemMenu";
import { ListItemWithFooter } from "./ListItemWithFooter";

type RecurringFee = NonNullable<
  NonNullable<GetManagementRecurringFeesQuery["recurringFees"]>[number]
>;

interface AutomaticFeeListItemProps {
  fee: RecurringFee;
  onPress?: () => void;
  onEditPress?: () => void;
  onCancelPress?: () => void;
  style?: ListItemStyle;
}

export function AutomaticFeeListItem({
  fee,
  onEditPress,
  onCancelPress,
  style
}: AutomaticFeeListItemProps): ReactElement | null {
  const date = new RecurringDate({
    frequency: fee.frequency,
    startDate: fee.startDate
  });

  const price =
    fee.nextOccurrence?.taxInclusiveAmount ??
    fee.currentOrNextSchedule?.taxInclusiveAmount;

  const priceText = price
    ? `${Money.from(price).format()} ${date.format({
        type: "/FF"
      })}`
    : "";

  const taxText = "incl. GST";

  return (
    <ListItemWithFooter
      header={fee.blueprint.name}
      subHeader={
        fee.nextOccurrence ? (
          <View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <AutoPayIcon
                width={16}
                height={16}
                color={Colors.TEXT.DARK.SECONDARY}
              />
              <Text.BodyS
                color={Colors.TEXT.DARK.SECONDARY}
                style={{ marginLeft: 6 }}
              >
                {capitalize(date.format({ type: "F-ly on D" }))}
              </Text.BodyS>
            </View>
          </View>
        ) : (
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
            {"No further charges"}
          </Text.BodyS>
        )
      }
      rightComponent={
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <AmountAndTaxContainer>
            <Text.BodyM style={{ textAlign: "right" }} weight={"medium"}>
              {priceText}
            </Text.BodyM>
            <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
              {taxText}
            </Text.BodyS>
          </AmountAndTaxContainer>

          <FeeItemMenu
            onEditFeePress={onEditPress}
            onCancelFeePress={onCancelPress}
          />
        </View>
      }
      footer={
        !fee.nextOccurrence && fee.currentOrNextSchedule ? (
          <Alert
            style={{ marginTop: 12 }}
            type={"info"}
            message={`This fee has ${Money.from(
              // fee.liability will be null if the fee was quickly cancelled
              // after it was created so the ledger service still hasn't published
              // a liabilityStateChanged event to the PM service.
              // In such a case, assume that the liability hasn't been paid off so
              //current schedule amount is outstanding.
              fee.liability?.overdueAmount ||
                fee.currentOrNextSchedule.taxInclusiveAmount
            ).format()} outstanding. This will be paid from next rent received.`}
          />
        ) : undefined
      }
      style={[{ paddingRight: 32 }, style]}
    />
  );
}

AutomaticFeeListItem.Loading = function Loading({
  color,
  style
}: {
  color?: string;
  style?: ListItemStyle;
}) {
  return (
    <ListItem
      style={{
        verticalAlignCenter: "top",
        verticalAlignRight: "top",
        ...style
      }}
      header={<Text.BodyM.Loading width={120} color={color} />}
      subHeader={<Text.BodyS.Loading width={80} color={color} />}
    />
  );
};

const AmountAndTaxContainer = styled(View)`
  align-items: flex-end;
  justify-content: space-between;
  margin-right: 12px;
`;
