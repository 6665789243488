import { LocalDate } from "@ailo/date";
import { DatePeriod } from "../periods";
import { RawRow, RevenueReportRow } from "./getRowColumns";

type Row = RawRow & {
  incomeAmount: number;
  rowDate: LocalDate;
  period: DatePeriod;
};

export function calculateRowsPeriodAndYearIncomeDelta(
  row: Row,
  index: number,
  arr: Row[]
): RevenueReportRow {
  const prior = arr[index - 1] ?? null;
  const { periodIncomeDelta, periodIncomeDeltaPct } = calculateIncomeDelta(
    row.incomeAmount,
    prior?.incomeAmount ?? null
  );
  const priorYearIncome = arr.find(({ rowDate }) =>
    row.rowDate.setYear(row.rowDate.year - 1).isSame(rowDate)
  )?.incomeAmount;
  const {
    periodIncomeDelta: priorYearIncomeDelta,
    periodIncomeDeltaPct: priorYearIncomeDeltaPct
  } = calculateIncomeDelta(row.incomeAmount, priorYearIncome);

  return {
    ...row,
    periodInProgress: LocalDate.today().isSameOrBefore(row.period.endDate),
    priorYearIncome,
    periodIncomeDelta,
    periodIncomeDeltaPct,
    priorYearIncomeDelta,
    priorYearIncomeDeltaPct
  };
}

export const calculateIncomeDelta = (
  currentIncome: number,
  priorIncome?: number
): {
  periodIncomeDelta?: number | null;
  periodIncomeDeltaPct?: number | null;
} => {
  if (priorIncome == null || priorIncome == 0)
    return { periodIncomeDelta: currentIncome, periodIncomeDeltaPct: null };

  const periodIncomeDelta = currentIncome - priorIncome;
  if (
    (currentIncome > 0 && priorIncome < 0) ||
    (priorIncome > 0 && currentIncome < 0)
  )
    return { periodIncomeDelta, periodIncomeDeltaPct: null };

  const periodIncomeDeltaPct = periodIncomeDelta / Math.abs(priorIncome);
  return { periodIncomeDelta, periodIncomeDeltaPct };
};
