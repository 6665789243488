import * as Apollo from "@apollo/client";
import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import {
  GetProjectFilesQuery,
  GetProjectFilesQueryVariables,
  useGetProjectFilesQuery
} from "local/graphql";

type GetFilesQuery = {
  files: NonNullable<GetProjectFilesQuery["project"]>["files"];
};
type GetFilesQueryVariables = GetProjectFilesQueryVariables;

export const useGetFilesQuery = (
  baseOptions: Omit<
    Apollo.QueryHookOptions<GetFilesQuery, GetFilesQueryVariables>,
    "onCompleted"
  >
): MappedQueryResult<GetFilesQuery, GetFilesQueryVariables> => {
  const result = useGetProjectFilesQuery(baseOptions);

  const modifiedQuery = useMappedQueryResult(result, (data) => {
    return {
      files: data.project?.files
    };
  });

  return modifiedQuery;
};
