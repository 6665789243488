import { LocalDate } from "@ailo/date";
import { DatePeriod } from "./DatePeriod";

export class MonthPeriod extends DatePeriod {
  public from(input: string): MonthPeriod {
    return new MonthPeriod(input);
  }

  public get startDate(): LocalDate {
    return this.date.setDayOfMonth(1);
  }

  public get endDate(): LocalDate {
    return this.date.setDayOfMonth(this.date.daysInMonth);
  }

  public shortFormat(): string {
    return `${this.date.format("MMM")} ‘${this.date.format("YY")}`;
  }

  public longFormat(): string {
    return `${this.date.format("MMMM")} ${this.date.format("YYYY")}`;
  }

  public parse(input: string): LocalDate {
    return LocalDate.parse(input) ?? LocalDate.today();
  }
}
