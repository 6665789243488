import { formatRentPaymentSchedule } from "@ailo/domains";
import { formatDateDefaultToNA } from "@ailo/services";
import { ModalRow, ModalTextWithHeader } from "@ailo/ui";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { useRentReviewForm } from "../RentReviewFormContext";
import { RentReviewFields } from "../RentReviewFormData";

export function RentReviewDetails(): ReactElement {
  const { watch } = useRentReviewForm();

  const rentReviewAmount = watch(RentReviewFields.amount);
  const currentRentPeriod = watch(RentReviewFields.period);
  const rentReviewEffectiveDate = watch(RentReviewFields.effectiveDate);
  const scheduledRentReviewDate = watch(
    RentReviewFields.scheduledRentReviewDate
  );

  return (
    <View>
      <ModalRow>
        <ModalTextWithHeader
          header={"Rent amount"}
          text={formatRentPaymentSchedule({
            amountInCents: rentReviewAmount.cents.toString(),
            period: currentRentPeriod
          })}
        />
        <ModalTextWithHeader
          header={"Effective date"}
          text={formatDateDefaultToNA(rentReviewEffectiveDate)}
          style={{ marginLeft: 12 }}
        />
      </ModalRow>
      <ModalRow style={{ marginTop: 16 }}>
        <ModalTextWithHeader
          header={"Scheduled next rent review"}
          text={formatDateDefaultToNA(scheduledRentReviewDate)}
        />
      </ModalRow>
    </View>
  );
}
