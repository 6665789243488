import { isARecurringFeeBlueprint, useHasFeature } from "@ailo/domains";
import {
  ArchiveIcon,
  EditIcon,
  MoreOptionsIcon,
  RefreshIcon,
  Text
} from "@ailo/primitives";
import {
  didQueryNotLoadYet,
  throwIfQueryFailed,
  useOnFocus
} from "@ailo/services";
import {
  AlertScreen,
  Button,
  DropdownMenu,
  formatRecurringAmount,
  Grid,
  GridColumnOptions,
  Money,
  TabNavigation,
  TextCell,
  CellContainer,
  ActionCellLoading
} from "@ailo/ui";
import { Screens, useCurrentAgencyOrg, useNavigation } from "local/common";
import {
  GetFeeBlueprintsQuery,
  useGetFeeBlueprintsQuery,
  FeeBlueprintType,
  PlatformFeatureId
} from "local/graphql";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { View } from "react-native";
import { TabContent } from "../../common";
import { useChangeFeeBlueprintArchiveStatus } from "../common";

const tabs = ["Active", "Archived"] as const;

export type Blueprint = NonNullable<
  GetFeeBlueprintsQuery["blueprints"]
>["items"][number];

const columnsBase = [
  {
    key: "name",
    name: "Name",
    verticalAlign: "middle" as const
  },
  {
    key: "type",
    name: "Type",
    maxWidth: 108,
    verticalAlign: "middle" as const
  },
  {
    key: "price",
    name: "Price",
    maxWidth: 152,
    horizontalAlign: "right" as const,
    verticalAlign: "middle" as const
  },
  {
    key: "more",
    maxWidth: 64,
    horizontalAlign: "right" as const,
    verticalAlign: "middle" as const
  }
];

export function FeeBlueprintList(): ReactElement {
  const navigation = useNavigation<Screens.SettingsTab>();
  const { id: organisationId } = useCurrentAgencyOrg();
  const [selectedTab, selectTab] = useState<typeof tabs[number]>("Active");

  const blueprintsResult = useGetFeeBlueprintsQuery({
    variables: {
      conditions: {
        organisationId,
        archived: selectedTab === "Archived"
      }
    }
  });
  throwIfQueryFailed(blueprintsResult, { dataKey: "blueprints" });
  useOnFocus(blueprintsResult.refetch);

  const { unarchive, archive } = useChangeFeeBlueprintArchiveStatus();
  const editBlueprint = useCallback(
    (blueprint: Blueprint): void => {
      navigation.navigate(Screens.SettingsTab, {
        tab: "fees",
        id: blueprint.id
      });
    },
    [navigation]
  );

  const canManageEventBasedFees = useHasFeature(
    PlatformFeatureId.EventBasedFee
  );

  const rows = useMemo(
    () =>
      blueprintsResult.data?.blueprints?.items
        .filter(
          (blueprint) =>
            canManageEventBasedFees ||
            blueprint.type !== FeeBlueprintType.EventBasedFee
        )
        .map((blueprint) => ({
          ...blueprint,
          key: blueprint.id
        })),
    [blueprintsResult, canManageEventBasedFees]
  );

  const columns: GridColumnOptions<Blueprint>[] = useMemo(
    () => [
      {
        ...columnsBase[0],
        renderCell({ row }) {
          return (
            <TextCell weight={"medium"} TextComponent={Text.BodyM}>
              {row.name}
            </TextCell>
          );
        }
      },
      {
        ...columnsBase[1],
        renderCell({ row }) {
          return (
            <TextCell>
              {row.type === FeeBlueprintType.RecurringFee
                ? "Recurring"
                : row.type === FeeBlueprintType.EventBasedFee
                ? "Event-based"
                : row.type === FeeBlueprintType.OneOffFee
                ? "Manual"
                : ""}
            </TextCell>
          );
        }
      },
      {
        ...columnsBase[2],
        renderCell({ row }) {
          return (
            <TextCell>
              {isARecurringFeeBlueprint(row)
                ? formatRecurringAmount(
                    Money.from(row.fixedAmount).format(),
                    row.frequency
                  )
                : row.fixedAmount
                ? Money.from(row.fixedAmount).format()
                : row.oneWeekRentPercentage != undefined
                ? `${row.oneWeekRentPercentage} weeks of rent`
                : ""}
            </TextCell>
          );
        }
      },
      {
        ...columnsBase[3],
        renderCell({ row }) {
          return (
            <CellContainer>
              <DropdownMenu
                options={[
                  {
                    label: "Edit",
                    icon: <EditIcon />,
                    onSelect() {
                      editBlueprint(row);
                    }
                  },
                  row.archived
                    ? {
                        label: "Restore",
                        icon: <RefreshIcon width={20} height={20} />,
                        onSelect() {
                          unarchive(row);
                        }
                      }
                    : {
                        label: "Archive",
                        icon: <ArchiveIcon />,
                        onSelect() {
                          archive(row);
                        }
                      }
                ]}
                renderToggle={({ open, toggle }) => (
                  <Button.Secondary
                    type={"small"}
                    square
                    active={open}
                    onPress={toggle}
                  >
                    <MoreOptionsIcon />
                  </Button.Secondary>
                )}
              />
            </CellContainer>
          );
        }
      }
    ],
    [archive, editBlueprint, unarchive]
  );

  const tabNavigation = (
    <TabNavigation
      options={tabs.map((label) => ({
        key: label,
        label
      }))}
      selectedOptionKey={selectedTab}
      style={{ marginBottom: 16 }}
      onChange={({ key }) => selectTab(key)}
    />
  );

  if (didQueryNotLoadYet(blueprintsResult)) {
    return (
      <>
        {tabNavigation}
        <View style={{ marginHorizontal: -TabContent.padding }}>
          <Grid
            scrollView={false}
            flat
            rows={[
              { key: 1, opacity: 0.75 },
              { key: 2, opacity: 0.5 },
              { key: 3, opacity: 0.25 }
            ]}
            renderRowContainer={({ row, style, children }) => (
              <View style={[style, { opacity: row.opacity }]}>{children}</View>
            )}
            rowStyle={{ paddingHorizontal: 16 }}
            rowHeadStyle={{ paddingHorizontal: 16 }}
            columns={[
              {
                ...columnsBase[0],
                renderCell() {
                  return (
                    <CellContainer>
                      <Text.BodyM.Loading width={120} />
                    </CellContainer>
                  );
                }
              },
              {
                ...columnsBase[1],
                renderCell: TextCell.Loading
              },
              {
                ...columnsBase[2],
                renderCell: TextCell.Loading
              },
              {
                ...columnsBase[3],
                renderCell: ActionCellLoading
              }
            ]}
          />
        </View>
      </>
    );
  }

  if (rows && rows.length === 0) {
    return (
      <>
        {tabNavigation}
        <AlertScreen
          style={{ minHeight: 300 }}
          type={"plant-and-document"}
          title={`No ${selectedTab} Fee Templates`}
          description={
            selectedTab === "Active"
              ? "Create new fee template to get started"
              : "Any archived fee templates will go here"
          }
        />
      </>
    );
  }

  return (
    <>
      {tabNavigation}
      <View style={{ marginHorizontal: -TabContent.padding }}>
        <Grid
          scrollView={false}
          flat
          rows={rows!}
          columns={columns}
          rowStyle={{ paddingHorizontal: 16 }}
          rowHeadStyle={{ paddingHorizontal: 16 }}
          onRowPress={editBlueprint}
        />
      </View>
    </>
  );
}
