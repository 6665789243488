import React from "react";
import { Badge, BadgeCellLoading, CellContainer } from "@ailo/ui";
import { useGetOccupancyStatusQuery } from "local/graphql";
import { ErrorCell } from "./ErrorCell";
import { getOccupancyBadgeType } from "./OccupancyStatusCell/components/OccupancyStatusLogic/utils";
import { BasePropertyListRow } from "../BasePropertyListRow";

function StatusBadgeOnlyCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetOccupancyStatusQuery({
    variables: { managementId }
  });

  if (loading && !data) {
    return <BadgeCellLoading />;
  }

  const occupancyStatus = data?.management?.occupancyStatus;
  if (!occupancyStatus)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const badgeType = getOccupancyBadgeType(
    occupancyStatus,
    data?.management?.nextTenancy?.startDate
  );

  return (
    <CellContainer>
      <Badge type={badgeType} style={{ alignSelf: "flex-start" }}>
        {occupancyStatus}
      </Badge>
    </CellContainer>
  );
}

StatusBadgeOnlyCell.Loading = BadgeCellLoading;

export { StatusBadgeOnlyCell };
