import { Colors, opacify, Text } from "@ailo/primitives";
import { GridHeadRow, GridRow, GridRowProps, LocalDate, Money } from "@ailo/ui";
import { useNavigation } from "local/common";
import { AdjustmentLiabilityEntry } from "local/graphql";
import moment from "moment";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { RentAdjustmentStatusBadge } from "../../RentAdjustmentStatusBadge";

const columns: NonNullable<GridRowProps["columns"]> = [
  {
    key: "effectiveAt",
    name: "Date",
    width: 120
  },
  {
    name: "Detail",
    width: 272
  },
  {
    name: "Debit",
    horizontalAlign: "right",
    width: 100
  },
  {
    name: "Credit",
    horizontalAlign: "right",
    width: 100
  },
  {
    width: 44
  }
];

export function RentAdjustmentsListTableRow({
  style,
  rentAdjustment
}: {
  style?: StyleProp<ViewStyle>;
  rentAdjustment: AdjustmentLiabilityEntry;
}): React.ReactElement {
  const navigation = useNavigation();

  const {
    amount,
    description,
    effectiveAt,
    descriptionIsCustom: showDescriptionToUsers
  } = rentAdjustment;

  /**
   * Credit (entry with negative amount)
   * Debit (entry with positive amount)
   */
  const isDebit = Money.from(amount).isPositive;

  const formattedDate = moment(effectiveAt).format("DD MMM YY");
  const formattedDetailsHeader = `Rent ${isDebit ? "Debit" : "Credit"}`;

  return (
    <GridRow
      style={style}
      columns={columns}
      onPress={() => navigation.setParams({ adjustmentId: rentAdjustment.id })}
    >
      <Text.BodyS>{formattedDate}</Text.BodyS>

      <View>
        <Text.BodyM weight={"medium"}>{formattedDetailsHeader}</Text.BodyM>
        {description && showDescriptionToUsers && (
          <Text.BodyS color={opacify(Colors.TEXT.DARK.SECONDARY, 0.6)}>
            {description}
          </Text.BodyS>
        )}
      </View>

      <RentAdjustmentAmountDisplay
        display={isDebit}
        rentAdjustment={rentAdjustment}
      />

      <RentAdjustmentAmountDisplay
        display={!isDebit}
        rentAdjustment={rentAdjustment}
      />

      {/* The Fragment below is for rendering an empty column with 44px width */}
      <></>
    </GridRow>
  );
}

function RentAdjustmentAmountDisplay({
  display,
  rentAdjustment
}: {
  display: boolean;
  rentAdjustment: AdjustmentLiabilityEntry;
}): React.ReactElement {
  const formattedAmount = Money.from(rentAdjustment.amount).abs().format();

  return (
    <View style={{ alignItems: "flex-end" }}>
      <Text.BodyS>{display ? formattedAmount : "—"}</Text.BodyS>
      {display && (
        <RentAdjustmentStatusBadge
          effectiveAt={LocalDate.from(rentAdjustment.effectiveAt, {
            keepTimeZone: false
          })}
          reversed={!!rentAdjustment.reversedByAdjustment}
        />
      )}
    </View>
  );
}

RentAdjustmentsListTableRow.Header =
  function RentAdjustmentsListTableRowHeader({
    style
  }: {
    style?: StyleProp<ViewStyle>;
  }): React.ReactElement {
    return (
      <GridHeadRow
        style={style}
        columns={columns}
        sortOrder={{ columnKey: "effectiveAt", direction: "DESC" }}
      />
    );
  };

RentAdjustmentsListTableRow.Loading =
  function RentAdjustmentsListTableRowLoading({
    style
  }: {
    style?: StyleProp<ViewStyle>;
  }): React.ReactElement {
    return (
      <GridRow style={style} columns={columns}>
        <Text.BodyS.Loading width={60} />
        <View>
          <Text.BodyS.Loading width={160} />
          <Text.BodyS.Loading width={80} />
        </View>
        <Text.BodyS.Loading width={60} />
        <Text.BodyS.Loading width={60} />
        <></>
      </GridRow>
    );
  };
