import React from "react";
import { FileListItem, ListItemStyle } from "@ailo/ui";
import styled from "styled-components/native";
import { View } from "react-native";
import { Colors, opacify, Text } from "@ailo/primitives";
import { MaybeFileDetails, useOpenFile } from "@ailo/domains";
import { isPresent } from "ts-is-present";

interface Props {
  files: MaybeFileDetails[];
  showEmpty?: boolean;
  emptyFileListMsg: string;
}

export function AttachmentsFileList({
  files,
  showEmpty,
  emptyFileListMsg
}: Props): React.ReactElement {
  if (files.length > 0) {
    return <NonEmptyFileList files={files} />;
  }

  if (showEmpty) {
    return <EmptyFileList emptyFileListMsg={emptyFileListMsg} />;
  }

  return <></>;
}

function NonEmptyFileList({
  files
}: Omit<Props, "emptyFileListMsg">): React.ReactElement {
  const { openFile } = useOpenFile(files.filter(isPresent));

  return (
    <View>
      {files.map((file, index) =>
        file ? (
          <FileListItem
            key={`File-${file.fileName}-${index}`}
            fileName={file.fileName}
            fileUrl={file.url}
            fileSize={file.fileSize}
            style={getFileListItemStyle(index === files.length - 1)}
            onPress={() => openFile(file)}
          />
        ) : (
          <FileListItem.Error
            key={`Failed-file-${index}`}
            style={getFileListItemStyle(index === files.length - 1)}
          />
        )
      )}
    </View>
  );
}

function getFileListItemStyle(isLastItem: boolean): ListItemStyle {
  return {
    borderType: "around",
    borderRadius: 8,
    marginBottom: isLastItem ? 0 : 12
  };
}

function EmptyFileList({
  emptyFileListMsg
}: {
  emptyFileListMsg: string;
}): React.ReactElement {
  return (
    <EmptyFilesContainer>
      <Text.BodyL weight={"medium"}>{"No Files"}</Text.BodyL>
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY} style={{ marginTop: 8 }}>
        {emptyFileListMsg}
      </Text.BodyS>
    </EmptyFilesContainer>
  );
}

const EmptyFilesContainer = styled(View)`
  height: 92px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${opacify(Colors.SPACE_BLACK, 0.1)};
  margin-bottom: 12px;
`;
