import { Platform } from "react-native";
import { useFonts as useExpoFonts } from "expo-font";

// Ideally we should have it defined only once in `@ailo/primitives`,
// but currently there are issues with fonts being loaded in monorepos
// See:
// - https://github.com/expo/expo/issues/8423
// - https://github.com/expo/expo/pull/8419
export function useFonts(): boolean {
  const [fontsLoaded] = useExpoFonts({
    "circular-book": Platform.select({
      web: require("./CircularXXWeb-Book.woff"),
      default: require("./CircularXXTT-Book.ttf")
    }),
    "circular-medium": Platform.select({
      web: require("./CircularXXWeb-Medium.woff"),
      default: require("./CircularXXTT-Medium.ttf")
    })
  });

  return fontsLoaded;
}
