import {
  useGetTenanciesForMgmtQuery,
  GetTenanciesForMgmtQuery
} from "local/graphql";
import { useOnFocus } from "@ailo/services";
import moment from "moment";
import { isPresent } from "ts-is-present";
import { useSynchronisedLoad } from "@ailo/primitives";
import { useCallback } from "react";

export type Tenant = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<GetTenanciesForMgmtQuery["management"]>["tenancies"]
        >["items"][0]
      >["tenantships"]
    >["items"][0]
  >["tenant"]
>;

export enum TenancyType {
  INGOING = "Ingoing Tenancy",
  ACTIVE = "Active Tenancy",
  FORMER = "Former Tenancy"
}

export interface Tenancy {
  id: string;
  type: TenancyType;
  tenants: Tenant[];
  startDate: string;
  endDate?: string;
  voidedAt?: string;
  totalTenants: number;
}

export function useGetManagementTenancies(managementId?: string): {
  loading: boolean;
  data?: Tenancy[];
} {
  const { data, loading, refetch, error } = useGetTenanciesForMgmtQuery({
    skip: !managementId,
    variables: { managementId: managementId ?? "" },
    // TODO Remove this hardcoded fetchPolicy here
    // (so we use "cache-first" default)
    // JT: I haven't done it now because I'm not sure why somebody had set it in the first place
    // (Like, was it to make sure tenancy appears after it is created or something?)
    fetchPolicy: "cache-and-network"
  });

  if (error) throw error;

  const _refetch = useCallback(() => {
    if (managementId) refetch();
  }, [managementId, refetch]);

  useOnFocus(_refetch);

  const isLoading = useSynchronisedLoad(
    loading && (!data || data.management?.id !== managementId)
  );
  if (isLoading) {
    return {
      loading: true,
      data: []
    };
  }

  if (managementId && !data) throw new Error("Cannot get tenancies");

  const tenancies = getTenanciesFromRawAPIData(data);

  return {
    loading: false,
    data: tenancies
  };
}

function getTenanciesFromRawAPIData(
  data?: GetTenanciesForMgmtQuery
): Tenancy[] {
  return (data?.management?.tenancies?.items || [])
    .filter(isPresent)
    .map((apiTenancy) => {
      if (!apiTenancy) {
        throw new Error("filtering does not work");
      }

      if (!apiTenancy.startDate)
        throw new Error(`Start date missing for tenancy ${apiTenancy.id}`);

      const tenants: Tenant[] = (apiTenancy.tenantships?.items || [])
        .filter(isPresent)
        .map((tenantship) => tenantship.tenant)
        .filter(isPresent);

      return {
        id: apiTenancy.id,
        endDate: apiTenancy.endDate || undefined,
        voidedAt: apiTenancy.voidedAt || undefined,
        startDate: apiTenancy.startDate,
        totalTenants: apiTenancy.tenantships?.pageInfo.total || 0,
        tenants,
        type: getTenancyType(apiTenancy.startDate, apiTenancy.endDate)
      };
    });
}

function getTenancyType(
  startDate: string,
  endDate?: string | null
): TenancyType {
  if (moment().isBefore(moment(startDate), "day")) return TenancyType.INGOING;

  if (!endDate || moment().isSameOrBefore(moment(endDate), "day"))
    return TenancyType.ACTIVE;

  return TenancyType.FORMER;
}
