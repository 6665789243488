import React from "react";
import { SFC, Colors, Text, LeftChevronIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { Screens, useNavigation } from "local/common";
import { View } from "react-native";

const BackButton: SFC = ({ style }) => {
  const navigation = useNavigation();

  return (
    <Button.Text
      onPress={(): void =>
        navigation.navigate(Screens.PropertiesTab, {
          screen: Screens.PropertyList
        })
      }
      style={style}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <LeftChevronIcon />
        <Text.BodyM color={Colors.AILO_RED} weight={"medium"}>
          {"Properties"}
        </Text.BodyM>
      </View>
    </Button.Text>
  );
};

export { BackButton };
