import { ActionActivityContainer } from "local/tabs/projects/actions/ActionSidebar/ActionActivity";
import React, { ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";

export function ReiFormButtons({
  children,
  style
}: React.PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
}>): React.ReactElement {
  return (
    <ActionActivityContainer style={[{ flexDirection: "row" }, style]}>
      {applyStylesToChildren(children)}
    </ActionActivityContainer>
  );
}

function applyStylesToChildren(children: ReactNode): ReactNode {
  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    const childStyles = child.props.style || [];

    return React.cloneElement(child, {
      style: [...ReiFormButtonStyles, ...childStyles]
    });
  });
}

const ReiFormButtonStyles = [
  {
    marginRight: 12
  }
];
