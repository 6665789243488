import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Text, Colors, IconSkeleton } from "@ailo/primitives";
import { DescriptionList } from "@ailo/ui";
import { Action } from "local/domain/project";

interface Props {
  action: Action;
}

export function ActionDetails({ action }: Props): ReactElement {
  const items = [
    {
      label: "Description",
      description: action.description || "—"
    },
    {
      label: "Type",
      description: action.typeLabel
    }
  ];

  const renderLabel = (label: string): React.ReactNode => (
    <Text.BodyS
      style={{ width: 100, marginRight: 8 }}
      weight={"book"}
      color={Colors.TEXT.DARK.SECONDARY}
    >
      {label}
    </Text.BodyS>
  );

  const renderDescription = (description: string): React.ReactNode => (
    <Text.BodyS weight={"book"}>{description}</Text.BodyS>
  );

  return (
    <>
      <Title>{action.name}</Title>
      <DescriptionList
        size={"small"}
        items={items.map(({ label, description }) => ({
          label: renderLabel(label),
          description: renderDescription(description)
        }))}
      />
    </>
  );
}

ActionDetails.Loading = function ActionDetailsLoading(): ReactElement {
  return (
    <>
      <LoadingTitle width={240} />
      <LoadingDescriptionRow>
        <IconSkeleton style={{ borderRadius: 4, marginRight: 12 }} />
        <Text.BodyS.Loading width={67} style={{ marginRight: 49 }} />
        <Text.BodyS.Loading width={240} />
      </LoadingDescriptionRow>
      <LoadingDescriptionRow>
        <IconSkeleton style={{ borderRadius: 4, marginRight: 12 }} />
        <Text.BodyS.Loading width={28} style={{ marginRight: 88 }} />
        <Text.BodyS.Loading width={120} />
      </LoadingDescriptionRow>
    </>
  );
};

const Title = styled(Text.DisplayS)`
  margin-top: 32px;
  margin-bottom: 20px;
`;

const LoadingTitle = styled(Title.Loading)`
  margin-top: 38px;
  margin-bottom: 10px;
`;

const LoadingDescriptionRow = styled(View)`
  flex-direction: row;
  margin-top: 16px;
  align-items: center;
`;
