import { mapLazyQueryHook } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import { useSearchAgencyPropertiesLazyQuery } from "local/graphql";

export interface AgencyPropertiesQuery {
  agencyProperties: Array<{
    id: string;
    address: {
      unitStreetNumber?: string | null;
      streetName: string;
      suburb?: string | null;
    };
  }>;
}

export const useMappedSearchAgencyPropertiesLazyQuery = mapLazyQueryHook(
  useSearchAgencyPropertiesLazyQuery,
  (data): AgencyPropertiesQuery => {
    if (!data.searchAgencyProperties) {
      throw new ApolloError({
        errorMessage:
          "useMappedSearchAgencyPropertiesLazyQuery returned empty `searchAgencyProperties`"
      });
    }

    const agencyProperties = data.searchAgencyProperties.items.map((item) => ({
      id: item.id,
      address: item.address
    }));

    return { agencyProperties };
  }
);
