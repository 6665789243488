import React from "react";
import { Colors, Text } from "@ailo/primitives";
import { formatAddress } from "@ailo/domain-helpers";
import { View } from "react-native";
import styled from "styled-components/native";
import { useGetManagementAddressQuery } from "@ailo/domains";
import { AiloSentry } from "@ailo/services";

export function PropertyAddress({
  managementId
}: {
  managementId: string;
}): React.ReactElement {
  const { loading, data, error } = useGetManagementAddressQuery({
    variables: { managementId }
  });

  if (loading) {
    return <Loading />;
  }

  const address = data?.management?.property.address;

  if (!address || error) {
    AiloSentry.captureException(
      error ?? new Error(`Cannot get address for management ${managementId}`)
    );

    return <></>;
  }

  return (
    <Container>
      <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
        {"Property"}
      </Text.BodyS>
      <Text.BodyS weight={"book"}>
        {formatAddress(address, { format: "street" })}
      </Text.BodyS>
    </Container>
  );
}

function Loading(): React.ReactElement {
  return (
    <Container>
      <Text.BodyS.Loading width={60} />
      <Text.BodyS.Loading width={120} />
    </Container>
  );
}

const Container = styled(View)`
  margin-top: 80px;
  margin-left: 32px;
`;
