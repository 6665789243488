import { FilesStateFile } from "@ailo/domains";
import { Colors } from "@ailo/primitives";
import { LoadingMore, Tooltip } from "@ailo/ui";
import React from "react";

export function FileUploading({
  file,
  width = 84,
  height = 80
}: {
  file: FilesStateFile;
  width?: number;
  height?: number;
}): React.ReactElement {
  return (
    <Tooltip tooltipContent={file.fileName}>
      <LoadingMore
        style={{
          height,
          width,
          marginRight: 8,
          marginBottom: 8,
          borderWidth: 1,
          borderColor: Colors.GRAYSCALE.OUTLINE,
          borderStyle: "solid",
          borderRadius: 4,
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden"
        }}
      />
    </Tooltip>
  );
}
