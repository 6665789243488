import React, { useCallback, useState } from "react";
import { TenancyInspectionScheduleFragment } from "local/graphql";
import { CalendarFrequencyIcon, CalendarIcon } from "@ailo/primitives";
import { DropdownMenuOption } from "@ailo/ui";
import { ManageMenu } from "local/common";
import { InspectionFrequencyModalForm } from "local/modals/InspectionFrequencyModalForm";
import { DateDuration } from "@ailo/date";
import { SetRoutineInspectionDueDateModalForm } from "local/modals/SetRoutineInspectionDueDateModalForm";

interface Props {
  inspectionSchedule: TenancyInspectionScheduleFragment;
}

export function InspectionScheduleManageMenu({
  inspectionSchedule
}: Props): React.ReactElement {
  const [dueDateModalOpen, setDueDateModalOpen] = useState(false);
  const closeDueDateModal = useCallback(() => {
    setDueDateModalOpen(false);
  }, [setDueDateModalOpen]);
  const [frequencyModalOpen, setFrequencyModalOpen] = useState(false);
  const closeFrequencyModal = useCallback(() => {
    setFrequencyModalOpen(false);
  }, [setFrequencyModalOpen]);

  const managementAgreement =
    inspectionSchedule.management?.currentOrNextManagementAgreement;

  const manageMenuOptions: DropdownMenuOption[] = [
    {
      label: "Edit inspection due date",
      onSelect: () => {
        setDueDateModalOpen(true);
      },
      icon: <CalendarIcon />
    },
    {
      label: "Inspection frequency",
      onSelect: () => {
        setFrequencyModalOpen(true);
      },
      icon: <CalendarFrequencyIcon />,
      disabled: !managementAgreement
    }
  ];

  return (
    <>
      <ManageMenu options={manageMenuOptions} />
      {dueDateModalOpen && (
        <SetRoutineInspectionDueDateModalForm
          existingSchedule={inspectionSchedule}
          onCancel={closeDueDateModal}
          onDueDateSet={closeDueDateModal}
        />
      )}
      {frequencyModalOpen && managementAgreement && (
        <InspectionFrequencyModalForm
          managementAgreementId={managementAgreement.id}
          onCancel={closeFrequencyModal}
          onInspectionFrequencyUpdated={closeFrequencyModal}
          defaultValues={{
            inspectionFrequency: managementAgreement.inspectionFrequency
              ? managementAgreement.inspectionFrequency
              : DateDuration.from({ months: 3 }).toISOString()
          }}
        />
      )}
    </>
  );
}
