import React, { ReactElement } from "react";
import { Button, Tooltip } from "@ailo/ui";
import { DeleteIcon } from "@ailo/primitives";

export function DeleteButton({
  setShowDeleteModal
}: {
  setShowDeleteModal: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void;
}): ReactElement {
  return (
    <Tooltip tooltipContent={"Remove Centrepay"}>
      <Button.Secondary
        type={"small"}
        style={{ marginLeft: 8 }}
        square
        onPress={() => {
          setShowDeleteModal(true);
        }}
      >
        <DeleteIcon height={20} width={20} />
      </Button.Secondary>
    </Tooltip>
  );
}
