import { DownCaretIcon } from "@ailo/primitives";
import {
  Button,
  DropdownMenu,
  DropdownMenuOptionGroup,
  DropdownMenuToggleProps
} from "@ailo/ui";
import React from "react";
import { isPresent } from "ts-is-present";
import { BondMenuOptions, useGetBondOptionsGroup } from "../../BaseTenancyCard";
import { useGetRentAdjustmentsOption } from "../../manageMenuOptions";
import styled from "styled-components/native";

export function ManageMenu({
  onRentAdjustmentsPress,
  bondMenuOptions,
  isFormerTenancy
}: {
  onRentAdjustmentsPress?: () => void;
  bondMenuOptions: BondMenuOptions;
  isFormerTenancy: boolean;
}): React.ReactElement | null {
  const rentAdjustmentsOption = useGetRentAdjustmentsOption({
    onSelect: (): void => onRentAdjustmentsPress?.(),
    disabled: !onRentAdjustmentsPress
  });

  const tenancyOptionsGroup: DropdownMenuOptionGroup = {
    options: [rentAdjustmentsOption].filter(isPresent),
    type: "group"
  };
  const bondOptionsGroup = useGetBondOptionsGroup({
    ...bondMenuOptions,
    isFormerTenancy
  });

  const options = [tenancyOptionsGroup, bondOptionsGroup].filter(isPresent);

  return <DropdownMenu renderToggle={renderDropdownButton} options={options} />;
}

function renderDropdownButton({
  toggle,
  open
}: DropdownMenuToggleProps): React.ReactElement {
  return (
    <StyledTextButton onPress={toggle} rightIcon={DownCaretIcon} active={open}>
      {"Manage"}
    </StyledTextButton>
  );
}

const StyledTextButton = styled(Button.Text)`
  border-width: 0;
`;
