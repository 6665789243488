import { Dispatch, SetStateAction, useCallback } from "react";
import { CLAIM_LIABILITIES_STEP, Step } from "./stepNames";

export function useGoToStep(
  setSubmitButtonDisabled: Dispatch<SetStateAction<boolean>>,
  setCurrentStep: Dispatch<SetStateAction<number>>
): (step: Step) => void {
  return useCallback(
    (step: Step) => {
      if (step === CLAIM_LIABILITIES_STEP) {
        setSubmitButtonDisabled(false);
      }
      setCurrentStep(step);
    },
    [setCurrentStep, setSubmitButtonDisabled]
  );
}
