import { PercentInputFormField } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  NewManagementFormData,
  NewManagementFormFields
} from "../../NewManagementFormData";

export function ManagementFeePercentInput(): ReactElement {
  const { control, errors } = useFormContext<NewManagementFormData>();

  return (
    <Controller
      control={control}
      render={({ value, onBlur, onChange }): React.ReactElement => (
        <PercentInputFormField
          onBlur={onBlur}
          onChange={(date): void => {
            onChange(date ?? null);
          }}
          autoCorrect={false}
          autoCompleteType={"off"}
          value={value}
          label={"Management Fee %"}
          error={errors.managementFeePercent?.message}
        />
      )}
      name={NewManagementFormFields.managementFeePercent}
      defaultValue={null}
      rules={{
        required: "Please enter a value",
        validate: {
          greaterThanZero: (value: number) =>
            value <= 0 ? "Enter a number greater than 0" : undefined,
          oneHundredOrLess: (value: number) =>
            value > 1
              ? "Cannot charge more than 100% management fee"
              : undefined
        }
      }}
    />
  );
}
