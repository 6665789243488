import { AiloRN, services } from "@ailo/ailorn";
import { Alert } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import { Maybe, useGetFormerTenanciesUnpaidBillsQuery } from "local/graphql";
import React, { ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { isPresent } from "ts-is-present";

export function UnpaidExpensesForFormerTenanciesAlert({
  management,
  style
}: {
  management: { id: string; mostRecentTenancy?: Maybe<{ id: string }> };
  style?: StyleProp<ViewStyle>;
}): ReactElement | null {
  const { ailoRN: organisationAilorn } = useCurrentAgencyOrg();

  const resultItemsLimit = 10;
  const result = useGetFormerTenanciesUnpaidBillsQuery({
    variables: {
      organisationAilorn,
      managementAilorn: AiloRN.of(
        services.PropertyManagement.management,
        management.id
      ).toString(),
      currentTenancyAilorns: management.mostRecentTenancy
        ? [
            AiloRN.of(
              services.PropertyManagement.tenancy,
              management.mostRecentTenancy.id
            ).toString()
          ]
        : [],
      limit: resultItemsLimit
    }
  });

  const tenancyIds = (result.data?.bills?.items ?? [])
    .map((bill) =>
      bill.payer?.__typename === "Tenancy" ? bill.payer.id : undefined
    )
    .filter(isPresent);
  const tenancyId = tenancyIds[0];
  if (!tenancyId) {
    return null;
  }

  const billsCount =
    tenancyId && result.data!.bills!.items.length < resultItemsLimit
      ? result.data!.bills!.items.filter(
          (bill) =>
            bill.payer?.__typename === "Tenancy" && bill.payer.id === tenancyId
        ).length
      : undefined;

  return (
    <Alert type={"warning"} style={style}>
      {billsCount ?? ""} {"unpaid expense"}
      {billsCount && billsCount > 1 ? "s" : ""} {"for former tenancy"}
    </Alert>
  );
}
