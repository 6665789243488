import { MockedQueryResult, useApolloClient } from "@ailo/services";
import { ApolloError, FetchPolicy } from "@apollo/client";
import { useCurrentAgencyOrg } from "local/common";
import { useCallback, useEffect, useState } from "react";
import {
  GetBillsCountDocument,
  GetBillsCountQuery,
  GetBillsCountQueryVariables
} from "local/graphql";
import { getBillsQueryTabVariables } from "./getBillsQueryTabVariables";
import { TabId, tabsWithCounters } from "./tabs";

export type TabCounts = {
  [key in TabId]?: number;
};

export function useTabCounts(): Omit<
  MockedQueryResult<TabCounts>,
  "networkStatus"
> {
  const currentOrganisation = useCurrentAgencyOrg();
  const client = useApolloClient();

  const [state, setState] = useState<{
    loading: boolean;
    data?: TabCounts;
    error?: ApolloError;
  }>({
    loading: true
  });

  const fetch = useCallback(
    async function fetch({
      fetchPolicy = "network-only"
    }: {
      fetchPolicy?: FetchPolicy;
    } = {}): Promise<{
      data: TabCounts;
    }> {
      setState((state) => ({
        ...state,
        error: undefined,
        loading: true
      }));

      try {
        const results = await Promise.all(
          tabsWithCounters.map(async (tab) => {
            return {
              tab,
              result: await client.query<
                GetBillsCountQuery,
                GetBillsCountQueryVariables
              >({
                query: GetBillsCountDocument,
                variables: {
                  ...getBillsQueryTabVariables(tab),
                  organisationId: currentOrganisation.ailoRN
                },
                fetchPolicy
              })
            };
          })
        );
        const data = Object.fromEntries(
          results.map(({ tab, result }) => {
            return [tab, result.data.bills?.pageInfo.total ?? 0];
          })
        ) as TabCounts;
        setState({
          loading: false,
          error: undefined,
          data
        });
        return { data };
      } catch (error: any) {
        setState({
          loading: false,
          error
        });
        throw error;
      }
    },
    [client, currentOrganisation.ailoRN]
  );

  useEffect(() => {
    fetch({ fetchPolicy: "cache-first" });
  }, [fetch]);

  return {
    data: undefined,
    ...state,
    refetch: fetch
  };
}
