import { useGetAgencyTrustAccountsQuery } from "local/graphql";
import { useAgencyLegalEntityOfCurrentProperty } from "local/tabs";
import React, { ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { BankAccountDetailsBox } from "./BankAccountDetailsBox";
import { parseTrustBankAccounts } from "./parseTrustBankAccounts";

function TrustAccountDetailsBox({
  reference,
  headerContent,
  style
}: {
  reference?: string;
  showReference?: boolean;
  headerContent?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}): ReactElement {
  const agency = useAgencyLegalEntityOfCurrentProperty();
  const { data, loading } = useGetAgencyTrustAccountsQuery({
    variables: { agency: agency!.toString() },
    skip: !agency
  });
  const showReference = false;

  if (loading) {
    return (
      <BankAccountDetailsBox.Loading
        style={style}
        showReference={showReference}
        headerContent={headerContent}
      />
    );
  }

  const trustAccount = parseTrustBankAccounts(data);

  return (
    <>
      {trustAccount && (
        <BankAccountDetailsBox
          style={style}
          bankAccount={trustAccount}
          reference={reference}
          showReference={showReference}
          headerContent={headerContent}
        />
      )}
    </>
  );
}

export { TrustAccountDetailsBox };
