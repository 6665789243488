import React, { useMemo } from "react";
import { useCurrentAgencyOrg } from "local/common";
import { ErrorAlertScreen } from "@ailo/ui";
import {
  CommsContextProviders,
  useCurrentUserContactAilornForOrganisation
} from "@ailo/domains";
import { useGetTeamList } from "./useGetTeamList";
import { ChatTabScreenContent } from "./ChatTabScreenContent";
import { AiloRN, ID } from "@ailo/ailorn";
import {
  useGenerateOpenChatLink,
  useNavigateToEmptyChat,
  useNavigateToNewChatMessage,
  useOpenChat,
  useOpenThread
} from "local/tabs/comms/navigation";
import { useGetCommsTabSelectedTeam } from "./useGetCommsTabSelectedTeam";

function ChatTabScreen(): React.ReactElement {
  const currentAgencyOrg = useCurrentAgencyOrg();
  const currentUserContactAilorn = useCurrentUserContactAilornForOrganisation(
    currentAgencyOrg.id as ID<"authz:organisation">
  );

  const { teams, loading, error, refetch } = useGetTeamList();
  const selectedTeam = useGetCommsTabSelectedTeam({
    teams,
    currentUserContactAilorn
  });

  const chatListFilters = useMemo(() => {
    const teamContactAilornsInput = selectedTeam?.contactAilorns?.map(
      (contactAilorn) => ({
        contactAilorn: contactAilorn.toString()
      })
    );

    return { mustIncludeOneOf: teamContactAilornsInput };
  }, [selectedTeam?.contactAilorns]);

  const organisationAilorn = useMemo(
    () =>
      AiloRN.from(currentAgencyOrg.ailoRN, {
        expected: "authz:organisation"
      }),
    [currentAgencyOrg.ailoRN]
  );

  if (error) {
    return (
      <ErrorAlertScreen
        variant={"medium"}
        title={"There was a problem loading Chat"}
        onRetry={refetch}
      />
    );
  }

  if (!selectedTeam || (loading && !teams)) {
    return <ChatTabScreenContent.Loading />;
  }

  return (
    <CommsContextProviders
      organisationAilorn={organisationAilorn}
      chatListFilters={chatListFilters}
      agencyName={currentAgencyOrg.name}
      navigationHooks={{
        useNavigateToNewChatMessage,
        useNavigateToChatList: useNavigateToEmptyChat,
        useOpenChat,
        useOpenThread,
        useGenerateOpenChatLink
      }}
    >
      <ChatTabScreenContent
        selectedTeamId={selectedTeam.teamId}
        teams={teams || []}
      />
    </CommsContextProviders>
  );
}

export { ChatTabScreen };
