import React, { useEffect } from "react";
import { Platform } from "react-native";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { AiloSentry, useAuth, useEnvironment } from "@ailo/services";
import { useCurrentUser, useHasFeature } from "@ailo/domains";
import { useNavigation } from "local/common";
import { PlatformFeatureId } from "local/graphql";
import { useDebouncedCallback } from "use-debounce";
import { Zindex } from "@ailo/primitives";
import { formatPersonName } from "@ailo/domain-helpers";

function ZendeskWidget(): React.ReactElement {
  const widgetEnabled = useHasFeature(PlatformFeatureId.ZendeskWidget);
  const restrictedFormsEnabled = useHasFeature(
    PlatformFeatureId.ZendeskWidgetRestrictedForms
  );
  const {
    ZENDESK_KEY,
    ZENDESK_WIDGET_JWT_URL,
    ZENDESK_WIDGET_FILTER_CATEGORY,
    ZENDESK_WIDGET_FORMS_CSV,
    ZENDESK_WIDGET_RESTRICTED_FORMS_CSV
  } = useEnvironment();
  const { getToken } = useAuth();
  const currentUser = useCurrentUser();
  const navigation = useNavigation();

  const { callback: updateHelpSuggestion } = useDebouncedCallback(() => {
    ZendeskAPI("webWidget", "helpCenter:setSuggestions", {
      search: window.location.pathname.split("/")[1]
    });
    ZendeskAPI("webWidget", "updatePath", {
      url: window.location.href,
      title: document.title
    });
  }, 1000);

  useEffect(() => {
    return navigation.addListener("state", updateHelpSuggestion);
  }, [navigation, updateHelpSuggestion]);

  if (
    !ZENDESK_KEY ||
    !ZENDESK_WIDGET_JWT_URL ||
    !widgetEnabled ||
    Platform.OS !== "web"
  ) {
    return <></>;
  }

  const jwtFn =
    (jwtKey: "widgetJwt" | "chatJwt") => (callback: (jwt: string) => void) => {
      (async () => {
        try {
          const jwtUrl = new URL(ZENDESK_WIDGET_JWT_URL);
          jwtUrl.searchParams.set("jwt", await getToken());

          const jwt = await fetch(jwtUrl.toString())
            .then((response) => response.json())
            .then((json) => json[jwtKey]);

          if (jwt) {
            callback(jwt);
          } else {
            throw new Error(`ZendeskWidget: Could not get JWT key=${jwtKey}`);
          }
        } catch (error) {
          callback("");
          AiloSentry.captureException(error);
        }
      })();
    };

  const onWidgetLoad = (): void => {
    ZendeskAPI("webWidget:on", "chat:status", (status: string) => {
      ZendeskAPI("webWidget", "updateSettings", {
        webWidget: {
          zIndex: Zindex.ZENDESK,
          launcher: {
            label: {
              "*": status === "offline" ? "Help" : "Live Chat"
            }
          }
        }
      });
    });
  };

  const zendeskConfig = {
    helpCenter: {
      filter: {
        category: ZENDESK_WIDGET_FILTER_CATEGORY
      }
    },
    contactForm: {
      fields: [
        { id: "name", prefill: { "*": formatPersonName(currentUser.person) } },
        { id: "email", prefill: { "*": currentUser.person.emailAddress } }
      ],
      ticketForms: getAvailableTicketForms(
        restrictedFormsEnabled,
        ZENDESK_WIDGET_FORMS_CSV,
        ZENDESK_WIDGET_RESTRICTED_FORMS_CSV
      )
    },
    authenticate: {
      jwtFn: jwtFn("widgetJwt"),
      chat: { jwtFn: jwtFn("chatJwt") }
    }
  };

  return (
    <Zendesk
      zendeskKey={ZENDESK_KEY}
      onLoaded={onWidgetLoad}
      {...zendeskConfig}
    />
  );
}

interface ZendeskContactForm {
  id: string;
  title: boolean;
}

function getAvailableTicketForms(
  includeRestrictedForms: boolean,
  defaultFormsCSV: string | undefined,
  restrictedFormsCSV: string | undefined
): ZendeskContactForm[] {
  const availableForms = parseFormIdCSV(defaultFormsCSV);

  if (includeRestrictedForms) {
    availableForms.push(...parseFormIdCSV(restrictedFormsCSV));
  }

  return availableForms;
}

function parseFormIdCSV(csv: string | undefined): ZendeskContactForm[] {
  if (!csv) return [];

  return csv.split(",").map((id) => ({
    id,
    title: false
  }));
}

export { ZendeskWidget };
