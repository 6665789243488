import { CellContainer, CheckboxCellLoading, CheckboxInput } from "@ailo/ui";
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback
} from "react";
import {
  ApprovedMigratingManagementFragment,
  ExcludedMigratingManagementFragment
} from "local/graphql";

function CheckboxCell({
  row,
  selectedMigratingManagementIds,
  setSelectedMigratingManagementIds
}: {
  row:
    | ApprovedMigratingManagementFragment
    | ExcludedMigratingManagementFragment;
  rowIndex: number;
  selectedMigratingManagementIds: string[];
  setSelectedMigratingManagementIds: Dispatch<SetStateAction<string[]>>;
}): ReactElement {
  const onChange = useCallback(
    (nextValue: boolean) =>
      setSelectedMigratingManagementIds((prev) =>
        nextValue ? [...prev, row.id] : prev.filter((id) => id !== row.id)
      ),
    [row.id, setSelectedMigratingManagementIds]
  );

  return (
    <CellContainer style={{ paddingRight: 0 }}>
      <CheckboxInput
        style={{ height: 20, width: 20, alignItems: "center" }}
        value={selectedMigratingManagementIds.includes(row.id)}
        onChange={onChange}
      />
    </CellContainer>
  );
}

CheckboxCell.Loading = CheckboxCellLoading;

export { CheckboxCell };
