import React, { useEffect } from "react";
import { View } from "react-native";
import { SFC } from "@ailo/primitives";
import { BPayMethod, BPayTypeName } from "@ailo/domains";
import { useFormContext } from "react-hook-form";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import { CustomerReferenceInput } from "local/domain/bill/AddBillForm";
import { BillerCodeInput } from "./BillerCodeInput";

interface Props {
  bpayMethod?: BPayMethod;
  shouldRender?: boolean;
}

const BPayInput: SFC<Props> = ({ bpayMethod, shouldRender, style }) => {
  const { setValue } = useFormContext<AddBillFormData>();

  useEffect(() => {
    if (shouldRender)
      setValue("payment.type", BPayTypeName, { shouldValidate: true });
  }, [setValue, shouldRender]);

  if (shouldRender && bpayMethod?.billerCode == null)
    throw new Error(
      `Supplier has bpay but missing data ${JSON.stringify(bpayMethod)}`
    );

  return (
    <View style={shouldRender ? style : undefined} testID={"BPayFormGroup"}>
      <BillerCodeInput
        billerCode={bpayMethod?.billerCode!}
        shouldRender={shouldRender}
      />
      <CustomerReferenceInput
        style={{ marginTop: 24 }}
        shouldRender={shouldRender}
      />
    </View>
  );
};

export { BPayInput };
