import { FormFieldRow } from "@ailo/ui";
import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { useRentReviewForm } from "../RentReviewFormContext";
import { EffectiveDateInput } from "./EffectiveDateInput";
import { LastRentReviewInfoAlert } from "./LastRentReviewInfoAlert";
import { PreviewPlansSection } from "./PreviewPlansSection";
import { RentReviewAmountInput } from "./RentReviewAmountInput";
import { ScheduledRentReviewDateInput } from "./ScheduledRentReviewDateInput";

interface Props {
  style?: StyleProp<ViewStyle>;
}

export function RentReviewSection({ style }: Props): ReactElement {
  const { data } = useRentReviewForm();

  return (
    <View style={style}>
      {data.lastRentReview && (
        <LastRentReviewInfoAlert
          lastRentReview={data.lastRentReview}
          style={{ marginBottom: 20 }}
        />
      )}
      <View>
        <FormFieldRow>
          <RentReviewAmountInput />
          <EffectiveDateInput />
        </FormFieldRow>
        <PreviewPlansSection style={{ marginTop: 16 }} />
        <ScheduledRentReviewDateInput style={{ marginTop: 20 }} />
      </View>
    </View>
  );
}
