import React, { ComponentType, ReactElement, useEffect } from "react";
import {
  BadgeQueryParams,
  DisbursementListView,
  Screens,
  useNavigation,
  useRoute
} from "local/common";
import {
  DisbursementCategory,
  DisbursementsListTabs
} from "local/tabs/trustaccounting";

import { DisbursementsCountBadge } from "./DisbursementsCountBadge";
import { AllDisbursementsList } from "./AllDisbursements";
import { InvestorDisbursementsList } from "./InvestorDisbursements";
import { SupplierDisbursementsList } from "./SupplierDisbursements";

interface DisbursementTabData {
  label: DisbursementListView;
  ListComponent: ComponentType<{
    tabNavigation: ReactElement;
    category?: DisbursementCategory;
  }>;
  badge?: ComponentType<{
    active?: boolean;
    params?: BadgeQueryParams;
  }>;
  category?: DisbursementCategory;
}

const DISBURSEMENT_TAB_DATA: DisbursementTabData[] = [
  {
    label: DisbursementListView.ALL_DISBURSALS,
    ListComponent: AllDisbursementsList,
    badge: DisbursementsCountBadge
  },
  {
    label: DisbursementListView.INVESTOR_DISBURSALS,
    ListComponent: InvestorDisbursementsList
  },
  {
    label: DisbursementListView.SUPPLIER_DISBURSALS,
    ListComponent: SupplierDisbursementsList
  }
  // {
  //   label: DisbursementListView.HISTORY,
  //   ListComponent: DefaultDisbursementsList
  // }
];

export function DisbursementsList(): ReactElement | null {
  const { params } = useRoute<Screens.DisbursementsList>();
  const navigation = useNavigation<Screens.DisbursementsList>();

  const currentIndex = DISBURSEMENT_TAB_DATA.findIndex((data) =>
    data.label.toLowerCase().includes(params?.tab?.toLowerCase() ?? "")
  );

  useEffect(() => {
    if (currentIndex < 0) {
      navigation.setParams({ tab: DisbursementListView.ALL_DISBURSALS });
    }
  }, [currentIndex, navigation]);

  if (currentIndex < 0) {
    return null;
  }

  const { ListComponent } = DISBURSEMENT_TAB_DATA[currentIndex];

  return (
    <ListComponent
      tabNavigation={
        <DisbursementsListTabs
          tabs={DISBURSEMENT_TAB_DATA}
          selectedTabIndex={currentIndex}
        />
      }
    />
  );
}
