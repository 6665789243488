import React, { ReactElement, useCallback, useState } from "react";
import { useToastContext, ErrorModal } from "@ailo/ui";
import { ManagementFragment } from "local/graphql";
import { EditManagementForm } from "./EditManagementForm";
import { NewManagementForm } from "./NewManagementForm";

interface Props {
  managementId?: string;
  onDismiss: () => void;
  onSuccess: (management: ManagementFragment) => void;
}

export function AddOrEditManagementForm({
  managementId,
  onDismiss,
  onSuccess
}: Props): ReactElement {
  const toast = useToastContext();
  const [showErrorModal, setShowErrorModal] = useState(false);

  const success = useCallback(
    async (management: ManagementFragment) => {
      toast.show({
        type: "success",
        message: managementId ? "Management Updated" : "New management created"
      });
      await onSuccess(management);
    },
    [toast, managementId, onSuccess]
  );

  const onError = useCallback(() => {
    setShowErrorModal(true);
  }, []);

  return (
    <>
      {managementId ? (
        <EditManagementForm
          managementId={managementId}
          onSuccess={success}
          onError={onError}
          onCancel={onDismiss}
          style={{
            maxWidth: 680,
            width: "100%",
            flex: 1,
            alignSelf: "flex-end"
          }}
        />
      ) : (
        <NewManagementForm
          onManagementCreated={success}
          onError={onError}
          onCancel={onDismiss}
          style={{
            maxWidth: 680,
            width: "100%",
            flex: 1,
            alignSelf: "flex-end"
          }}
        />
      )}
      <ErrorModal
        visible={showErrorModal}
        onDismiss={() => setShowErrorModal(false)}
      />
    </>
  );
}
