import React from "react";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";
import { BorderedBox, Button } from "@ailo/ui";

type Props = {
  onPress?: () => void;
};

export function AddBond({ onPress }: Props): React.ReactElement {
  return (
    <BorderedBox style={{ margin: 16, padding: 12 }}>
      <View style={{ alignItems: "center" }}>
        <Text.BodyS
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ textAlign: "center", paddingBottom: 16 }}
        >
          {"There is no bond information for this tenancy"}
        </Text.BodyS>
        <Button.Secondary onPress={onPress}>
          {"Add bond details"}
        </Button.Secondary>
      </View>
    </BorderedBox>
  );
}
