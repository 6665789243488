import { ChatNavigationParams, DEFAULT_CHAT_TEAM_ID } from "@ailo/domains";

export function generateCommsTabUrl({
  teamId = DEFAULT_CHAT_TEAM_ID,
  chatId,
  threadId
}: Partial<ChatNavigationParams>): string {
  if (!chatId) {
    return `/comms/${teamId}`;
  }

  if (!threadId) {
    return `/comms/${teamId}/${chatId}`;
  }

  return `/comms/${teamId}/${chatId}/${threadId}`;
}
