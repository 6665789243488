import { ReportCellValue, ReportTableRow } from "../ReportTable";
import { orArray } from "./arrayPredicates";
import { FilterInputParams, FilterMatchMode } from "./getFilterInputParams";

export type FilterInputCfg = Omit<FilterInputParams, "valuesToMatch">;

export const doesRowsColumnsContainValuesToMatch = (
  row: ReportTableRow,
  filterParams: FilterInputParams
): boolean =>
  filterParams.valuesToMatch
    ?.map((valueToMatch: string) =>
      doesRowsColumnsContainValueToMatch(row, filterParams, valueToMatch)
    )
    .includes(true);

export const doesRowsColumnsContainValueToMatch = (
  row: ReportTableRow,
  filterParams: FilterInputCfg,
  filterInputValue: string | number
): boolean =>
  filterParams.columnsToFilter
    ?.map((column) =>
      matchBasedOnType(row[column], filterInputValue, filterParams.mode)
    )
    .reduce(orArray, false);

function matchBasedOnType(
  cellValue: ReportCellValue,
  filterInputValue: string | number | boolean | undefined | string[] | null,
  mode?: FilterMatchMode
): boolean {
  if (cellValue == null || filterInputValue == null) return false;

  switch (typeof cellValue) {
    case "string":
      return Boolean(
        mode == "search" || mode == null
          ? cellValue?.match(new RegExp(filterInputValue?.toString(), "i"))
          : cellValue == filterInputValue
      );
    case "number":
      return cellValue === filterInputValue;
    default:
      return false;
  }
}
