import React, { ReactElement } from "react";
import { DescriptionList } from "@ailo/ui";
import { EndTenancyDetailsFragment } from "local/graphql";
import { formatDateDefaultToNA } from "@ailo/services";
import { getLabelForTenancyVacatingReason } from "local/domain/propertyManagement";
export function EndTenancyDescriptionList({
  tenancy
}: {
  tenancy: EndTenancyDetailsFragment;
}): ReactElement {
  return (
    <>
      <DescriptionList
        items={[
          {
            label: "End of tenancy date",
            descriptionBottom: formatDateDefaultToNA(tenancy?.endDate),
            columnIndex: 0
          },
          {
            label: "Tenancy end reason",
            descriptionBottom: tenancy?.vacatingReason
              ? getLabelForTenancyVacatingReason(tenancy?.vacatingReason)
              : "-",
            columnIndex: 0
          },
          {
            label: "Rent liability end date",
            descriptionBottom: formatDateDefaultToNA(tenancy?.endDate),
            columnIndex: 1
          }
        ]}
        itemStyle={{ marginBottom: 16 }}
        columns={2}
        size={"small"}
      />
      <DescriptionList
        items={[
          {
            label: "Tenancy end note",
            descriptionBottom: tenancy?.vacatingNotes ?? "-"
          }
        ]}
      />
    </>
  );
}
