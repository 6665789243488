import React, { ReactElement } from "react";
import {
  CheckboxInput,
  DateInputFormField,
  FormField,
  FormFieldRow,
  InfoBox,
  LocalDate,
  MoneyInput,
  TextInputFormField,
  validateMaxLength
} from "@ailo/ui";
import { Controller, UseFormMethods } from "react-hook-form";
import { View } from "react-native";
import { CreateRentAdjustmentFormData } from "../CreateRentAdjustmentForm";
import { useEnvironment } from "@ailo/services";
import { effectiveDateStatus, getEffectiveDateStatus } from "local/tabs";
import _ from "lodash";

export function CreateRentAdjustmentStep({
  form,
  display,
  tenancyStartDate,
  tenancyCurrentDate
}: {
  form: UseFormMethods<CreateRentAdjustmentFormData>;
  display: boolean;
  tenancyStartDate?: string | null;
  tenancyCurrentDate: string;
}): ReactElement {
  const showInfoBox = _.isEqual(
    getEffectiveDateStatus({
      date: form.watch("effectiveDate"),
      tenancyCurrentDate
    }),
    effectiveDateStatus.PAST
  );

  const { RENT_CREDIT_IN_THE_PAST_HELP_URL } = useEnvironment();

  return (
    <View style={{ display: display ? undefined : "none" }}>
      <FormFieldRow style={{ marginBottom: 24, width: "50%" }}>
        <Controller
          control={form.control}
          name={"amount"}
          render={({ value, onChange, onBlur }) => (
            <MoneyInput
              {...{ value, onChange, onBlur }}
              error={form.errors["amount"]?.message}
              label={"Credit amount"}
              testID={"CreateRentAdjustmentCreditAmountInputFormField"}
            />
          )}
          hasError={true}
          rules={{
            required: "Please enter a credit amount",
            validate: (amount): boolean | string =>
              amount.cents > 0 || "Please enter a credit amount"
          }}
        />
      </FormFieldRow>
      <FormFieldRow style={{ marginBottom: 24 }}>
        <Controller
          control={form.control}
          name={"description"}
          render={({ value, onChange, onBlur }) => (
            <TextInputFormField
              {...{ value, onChange, onBlur }}
              error={form.errors["description"]?.message}
              helperText={
                "Important: your description will be visible to both renters and investors"
              }
              label={"Description"}
              multiline
              numberOfLines={3}
              placeholder={"Reason for rent credit"}
              softCharacterLimit={{
                enforcementLevel: "error",
                limit: 160
              }}
              testID={"CreateRentAdjustmentDescriptionInputFormField"}
            />
          )}
          rules={{
            required: "Please enter a description",
            validate: { maxLength: validateMaxLength(160) }
          }}
        />
      </FormFieldRow>
      <FormFieldRow style={{ width: "50%" }}>
        <Controller
          control={form.control}
          name={"effectiveDate"}
          render={({ value, onChange, onBlur }) => (
            <DateInputFormField
              label={"Credit date"}
              minDate={tenancyStartDate ?? tenancyCurrentDate}
              onBlur={onBlur}
              onChange={(date): void => onChange(date ?? null)}
              testID={"CreateRentAdjustmentCreditDateInputFormField"}
              error={form.errors["effectiveDate"]?.message}
              value={value}
              webPopoverHorizontalAlign={"start"}
            />
          )}
          rules={{
            required: "Please choose a credit date",
            validate: {
              isAfterTenancyStartDate: (value): boolean | string => {
                const minAllowedDate = LocalDate.from(
                  tenancyStartDate ?? tenancyCurrentDate
                );
                return (
                  LocalDate.from(value).isSameOrAfter(minAllowedDate) ||
                  "Must be after the tenancy start date"
                );
              }
            }
          }}
        />
      </FormFieldRow>
      {showInfoBox && (
        <InfoBox
          content={
            "Scheduling a rent credit in the past will affect the status of unpaid or overdue rent in Ailo."
          }
          url={RENT_CREDIT_IN_THE_PAST_HELP_URL}
          style={{ marginTop: 12 }}
        />
      )}
      <FormField label={"Send notifications"} style={{ marginTop: 26 }}>
        <Controller
          control={form.control}
          name={"notifyTenants"}
          render={({ value, onChange }) => (
            <CheckboxInput
              {...{ value, onChange }}
              testID={"sendRentCreditTenantsNotificationCheckboxInput"}
              label={"Send notification to renters"}
            />
          )}
        />

        <Controller
          control={form.control}
          name={"notifyInvestors"}
          render={({ value, onChange }) => (
            <CheckboxInput
              {...{ value, onChange }}
              testID={"sendRentCreditInvestorsNotificationCheckboxInput"}
              label={"Send notification to investors"}
              style={{ marginTop: 12 }}
            />
          )}
        />
      </FormField>
    </View>
  );
}
