import { AiloRN, services } from "@ailo/ailorn";
import { archivableExpenseStatuses } from "@ailo/domains";
import { CancelFeeIcon } from "@ailo/primitives";
import { Button, Separator } from "@ailo/ui";
import {
  CancelOneOffFeeConfirmModal,
  CancelOneOffFeeConfirmModalData,
  onCancelFeePress
} from "local/domain/fee";
import React, { ReactElement, useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import {
  ExpenseMergedType,
  ExpenseType,
  ExpenseUnionType
} from "../../graphql/useGetExpense";

interface ExpenseCancelProps {
  expense?: ExpenseUnionType;
  onCancelExpensePress?: (ailorn: AiloRN) => void;
  loading?: boolean;
}

export function ExpenseCancel({
  expense,
  onCancelExpensePress,
  loading
}: ExpenseCancelProps): ReactElement | null {
  const [modalData, setModalData] = useState<CancelOneOffFeeConfirmModalData>();
  const onModalDismiss = (): void => setModalData(undefined);

  const showCancelButton =
    archivableExpenseStatuses.includes(expense?.status ?? "") &&
    !(
      expense?.ailorn?.isA(services.Bill.bill) &&
      !(expense as ExpenseMergedType).archivableByOrganisation
    );

  if (loading || !expense || !showCancelButton) return null;

  const onPress = onCancelExpensePress
    ? () => onCancelExpensePress(expense.ailorn)
    : expense?.expenseType === ExpenseType.FEE && !!expense.ailorn
    ? onCancelFeePress({
        fee: expense,
        setModalData
      })
    : undefined;

  if (!onPress) return null;

  const cancelButtonLabel = expense?.ailorn?.isA(services.Bill.bill)
    ? "Cancel Bill"
    : expense?.ailorn?.isA(services.PropertyManagement.fee) ||
      expense?.ailorn?.isA(services.PropertyManagement.recurringFee)
    ? "Cancel Fee"
    : "Cancel Expense";

  return (
    <StyledContainer>
      <Separator style={{ marginLeft: 0, marginTop: 40 }} />
      <Button.Text
        onPress={onPress}
        leftIcon={(): ReactElement => (
          <CancelFeeIcon width={16.67} height={16.67} />
        )}
        style={{ marginTop: 24, alignSelf: "flex-start" }}
      >
        {cancelButtonLabel}
      </Button.Text>
      <CancelOneOffFeeConfirmModal
        data={modalData}
        onDismiss={onModalDismiss}
      />
    </StyledContainer>
  );
}

const StyledContainer = styled(View)`
  padding: 0 32px;
`;
