import { Money } from "@ailo/ui";
import { BondDisbursalFailureReason } from "local/graphql";
import { LocalDate } from "@ailo/date";

export type TenancyBond = {
  id: string;
  amount: Money;
  reference?: string;
  status: TenancyBondStatus;
  failureReason?: BondDisbursalFailureReason;
  fundsReceived?: Money;
  createdAt?: LocalDate;
  modifiedAt?: LocalDate;
};

export enum TenancyBondStatus {
  Unclaimed = "Unclaimed",
  AwaitingFunds = "AwaitingFunds",
  FundsReceived = "FundsReceived",
  Disbursing = "Disbursing",
  ClaimSuccessful = "ClaimSuccessful",
  DisbursementFailed = "DisbursementFailed",
  BondResolved = "BondResolved"
}
