import { Table, GridRenderRowContainerProps } from "@ailo/ui";
import {
  FunctionalReportTable,
  ReportTableColumn,
  ReportTableRow
} from "../../components/ReportTable";
import { useCurrentAgencyOrg } from "local/common";
import React from "react";
import { View } from "react-native";
import { useGetPropertiesTableDataQuery } from "local/graphql";

import { useNavigateToProperty } from "local/domain/propertyManagement";

import { useNavigation } from "@react-navigation/native";
import { useReportTeamContext } from "../..";
import { reports } from "../../reportsDetails";

const defaultPropertyColumns: string[] = [
  "propertyAddress",
  "renterNames",
  "investorNames",
  "propertyOnAppAt"
];
// need to exclude dates and dollar amounts because of string formatting
const sortablePropertyColumns: string[] = [
  "propertyAddress",
  "arrearsDays",
  "vacantDays",
  "daysSincePreviousDisbursement",
  "daysSinceLeaseExpiry",
  "overdueDays"
];

function RowContainer({
  row,
  style,
  children
}: GridRenderRowContainerProps<{
  key: string;
  managementId: string;
  drilldownFields?: [string];
}>): React.ReactElement {
  const navigateToProperty = useNavigateToProperty();
  return (
    // This `onClick: onPress` is a workaround for
    // bugged behaviour of links in `react-native-web`.
    // Probably we'll be able to remove it
    // once we upgrade to `react-native-web >= 0.13.0`
    // ( https://github.com/necolas/react-native-web/pull/1353#issuecomment-678441915 ).
    <View
      {...{
        onClick: (): void => {
          navigateToProperty(row.managementId);
        }
      }}
      style={style}
    >
      {children}
    </View>
  );
}

export function PropertiesTable(): React.ReactElement {
  const { id } = useCurrentAgencyOrg();
  const { teamId } = useReportTeamContext();

  // get query params
  const navigationState = useNavigation().getState();
  const navigationParams = navigationState.routes[navigationState.index]
    .params as { propertyIssue?: string; propertyColumns?: string };
  const propertyIssue = navigationParams?.propertyIssue;
  // pull out propertyColumns
  const propertyColumns = navigationParams?.propertyColumns
    ? navigationParams.propertyColumns.split(",")
    : defaultPropertyColumns;
  // construct variables
  const variables = { managingOrgId: id, propertyIssue, teamId };
  // get data
  const visualisationData = useGetPropertiesTableDataQuery({ variables });
  if (!visualisationData.data?.propertiesReport?.rows) return <Table.Loading />;

  if (visualisationData.data?.propertiesReport.rows.length < 1)
    return <Table.Loading />;

  const rows = visualisationData.data.propertiesReport.rows.map((r) => {
    return r as ReportTableRow;
  }) as [ReportTableRow];
  let columns = visualisationData.data.propertiesReport.columns as [
    ReportTableColumn
  ];
  // if a column filter exists, filter the columns
  if (propertyColumns !== undefined) {
    columns = columns.filter((reportTableColumn) => {
      return propertyColumns.includes(reportTableColumn.key);
    }) as [ReportTableColumn];
  }
  const reportTitle = propertyIssue
    ? propertyIssue
    : reports.properties.reportHeading;
  return (
    <FunctionalReportTable
      reportTitle={reportTitle}
      columns={columns}
      rows={rows}
      rowContainer={RowContainer}
      sortableColumns={sortablePropertyColumns}
    />
  );
}
