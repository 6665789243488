import React, { ReactElement } from "react";
import { Button } from "@ailo/ui";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import styled from "styled-components/native";
import { View } from "react-native";
import { Colors } from "@ailo/primitives";

export function InProgressMigratingManagementFooter(): ReactElement {
  const hasPublishPropertyFeature = useHasFeature(
    PlatformFeatureId.OnboardingTabPublishProperty
  );

  const UnapproveButton = hasPublishPropertyFeature
    ? StyledSecondaryButton
    : StyledPrimaryLeftButton;

  return (
    <ButtonContainer>
      <UnapproveButton
        disabled
        borderColor={{
          default: Colors.GRAYSCALE.OUTLINE,
          disabled: Colors.GRAYSCALE.OUTLINE
        }}
      >
        {"Unapprove Property"}
      </UnapproveButton>

      {hasPublishPropertyFeature ? (
        <StyledPrimaryRightButton disabled>
          {"Set Property Live"}
        </StyledPrimaryRightButton>
      ) : null}
    </ButtonContainer>
  );
}

const ButtonContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const StyledPrimaryLeftButton = styled(Button.Primary)`
  flex: 1;
`;

const StyledPrimaryRightButton = styled(Button.Primary)`
  flex: 1;
  margin-left: 12px;
`;

const StyledSecondaryButton = styled(Button.Secondary)`
  flex: 1;
`;
