import { useHasFeature } from "@ailo/domains";
import { useAnalytics } from "@ailo/services";
import { Button, useGlobalModal } from "@ailo/ui";
import {
  SwitcherHeaderOption,
  Screens,
  TabNavigation,
  useNavigation,
  ProjectListTab
} from "local/common";
import { PlatformFeatureId } from "local/graphql";
import { InspectionPlanner } from "local/modals/InspectionPlanner";
import React, { useCallback } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { useShowCreateProjectModal } from "./useShowCreateProjectModal";

interface Props {
  tabs: (SwitcherHeaderOption | ProjectListTab)[];
  selectedTabIndex: number;
}

export function ProjectListHeader({
  tabs,
  selectedTabIndex
}: Props): React.ReactElement {
  const navigation = useNavigation<Screens.ProjectList>();
  const showCreateProjectModal = useShowCreateProjectModal();
  const analytics = useAnalytics();
  const modal = useGlobalModal();

  const hasCreateRoutineInspectionProjectFeature = useHasFeature(
    PlatformFeatureId.CreateRoutineInspectionProject
  );

  const handleCreateProject = (): void => {
    analytics.track("Create Project Clicked");
    showCreateProjectModal();
  };
  const showInspectionPlanner = useCallback(() => {
    modal.show(<InspectionPlanner />);
  }, [modal]);
  return (
    <Container>
      <StyledTabNavigation
        options={tabs}
        selected={selectedTabIndex}
        navigation={navigation}
      />
      {hasCreateRoutineInspectionProjectFeature && (
        <Button
          style={{ marginLeft: 12 }}
          variant={"secondary"}
          onPress={showInspectionPlanner}
        >
          {"Plan inspections"}
        </Button>
      )}
      <Button style={{ marginLeft: 12 }} onPress={() => handleCreateProject()}>
        {"Create project"}
      </Button>
    </Container>
  );
}

const Container = styled(View)`
  flex-direction: row;
  margin-bottom: 16px;
  margin-top: 32px;
  align-items: center;
`;

const StyledTabNavigation = styled(TabNavigation)`
  flex: 1;
`;
