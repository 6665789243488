import { Colors, opacify, Text } from "@ailo/primitives";
import {
  Badge,
  DescriptionList,
  LocalDate,
  Separator,
  SidebarContent,
  TextInput
} from "@ailo/ui";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { isPresent } from "ts-is-present";
import { ExpenseMergedType } from "../../graphql/useGetExpense";

type ExpenseDetailsProps = {
  expense?: ExpenseMergedType;
  loading?: boolean;
};

const StyledContainer = styled(View)`
  padding: 0 32px;
  margin-top: 32px;
`;

export function ExpenseDetails({
  expense,
  loading
}: ExpenseDetailsProps): React.ReactElement | null {
  if (loading || !expense) {
    return (
      <StyledContainer>
        <DescriptionList.Loading
          size={"small"}
          itemsWidths={[
            [50, 120],
            [55, 100],
            [66, 60],
            [64, 60],
            [50, 70],
            [117, 120],
            [37, 100]
          ]}
        />
      </StyledContainer>
    );
  }

  const payerEntityType = expense.payers?.length
    ? expense.payers?.[0].entityType
    : undefined;

  const mainItems = [
    {
      label: "Property",
      description: expense.propertyName
    },
    {
      label: "Category",
      description: expense.taxCategory
    },
    {
      label: "Invoice Number",
      description: expense.invoiceNumber
    },
    {
      label: "Description",
      description: expense.description
    },
    ...(expense.paymentMethod?.__typename === "BPay"
      ? [
          {
            label: "BPAY Biller Code",
            description: expense.paymentMethod.billerCode
          },
          {
            label: "Customer Reference Number",
            description: expense.paymentReference?.crn?.trim()
          }
        ]
      : expense.paymentMethod?.__typename === "BankAccount"
      ? [
          {
            label: "Bank Details",
            description:
              `${expense.paymentMethod.bsb} ${expense.paymentMethod.accountNumber}`.trim()
          }
        ]
      : []),
    {
      label: expense.paidOn ? "Paid by" : "To be paid by",
      description: (
        <View>
          <View style={{ paddingVertical: 2, marginLeft: "auto" }}>
            {payerEntityType === "Tenancy" ? (
              <Badge type={"tenant"} />
            ) : payerEntityType === "Management" ? (
              <Badge type={"investor"} />
            ) : undefined}
          </View>

          {expense.payers?.map((payer, index) => (
            <Text.BodyS
              key={index}
              numberOfLines={3}
              style={{ textAlign: "right", marginTop: 4 }}
            >
              {payer.name}
            </Text.BodyS>
          ))}
        </View>
      )
    }
  ]
    .filter(isPresent)
    .filter((item) => item.description);

  const dateItems = [
    {
      label: "Issued on",
      description: expense?.issueDate?.format("D MMMM YYYY")
    },
    {
      label: "Created in Ailo",
      description: expense.createdAt
        ? LocalDate.from(expense.createdAt.toString(), {
            keepTimeZone: false
          }).format("D MMMM YYYY")
        : undefined
    },
    { label: "Due on", description: expense.dueDate?.format("D MMM YYYY") },
    { label: "Paid on", description: expense.paidOn?.format("D MMM YYYY") },
    {
      label: "To be paid",
      description: expense.toBePaid
    }
  ].filter((item) => item.description);

  return (
    <StyledContainer>
      {expense.organisationArchiveReason && (
        <View
          style={{
            paddingBottom: 40,
            borderBottomWidth: 1,
            borderBottomColor: opacify(Colors.SPACE_BLACK, 0.1),
            marginBottom: 32
          }}
        >
          <TextInput
            value={expense.organisationArchiveReason}
            editable={false}
            multiline
            numberOfLines={"auto"}
            focusLabel={"Reason for cancelling"}
          />
        </View>
      )}
      <DescriptionList
        size={"small"}
        items={mainItems}
        testID={"ExpenseDetailsMainItems"}
      />
      <Separator
        style={{
          marginTop: 24,
          marginBottom: 30,
          marginLeft: 0,
          marginRight: -SidebarContent.paddingHorizontal
        }}
      />
      <DescriptionList
        size={"small"}
        items={dateItems}
        testID={"ExpenseDetailsDateItems"}
      />
    </StyledContainer>
  );
}
