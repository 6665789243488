import React, { ReactElement, useCallback } from "react";
import { View, ScrollView } from "react-native";
import styled from "styled-components/native";
import { Colors, opacify } from "@ailo/primitives";
import {
  didQueryNotLoadYet,
  didQuerySucceed,
  useOnFocus
} from "@ailo/services";
import { ErrorAlertScreen, Separator } from "@ailo/ui";
import { useGetActionDetailsQuery } from "local/graphql";
import { ActionHeader } from "./ActionHeader";
import { ActionDetails } from "./ActionDetails";
import { ActionActivity } from "./ActionActivity";
import { useActionAnalytics } from "local/domain/project";
import { ActionStatusContextProvider } from "../ActionStatusContextProvider";

interface Props {
  projectId: string;
  actionId: string;
  onDismiss: () => void;
}

export function ActionSidebar({
  projectId,
  actionId,
  onDismiss
}: Props): ReactElement {
  const result = useGetActionDetailsQuery({
    variables: {
      id: actionId
    },
    fetchPolicy: "cache-and-network"
  });

  const { onViewAction } = useActionAnalytics(projectId);
  useOnFocus(
    useCallback(() => onViewAction(actionId), [onViewAction, actionId])
  );

  if (didQueryNotLoadYet(result, ["action"])) {
    return <ActionSidebar.Loading onDismiss={onDismiss} />;
  }

  if (!didQuerySucceed(result)) {
    return (
      <ActionSidebar.Error onDismiss={onDismiss} onRetry={result.refetch} />
    );
  }

  const action = result?.data?.action!;

  return (
    <ActionStatusContextProvider projectId={projectId} action={action}>
      <Container>
        <ActionHeader
          action={action}
          projectId={projectId}
          onDismiss={onDismiss}
        />
        <SectionSeparator />
        <ActionDetails action={action} />
        <SectionSeparator style={{ marginTop: 32, marginBottom: 32 }} />
        <ActionActivity action={action} projectId={projectId} />
      </Container>
    </ActionStatusContextProvider>
  );
}

ActionSidebar.Loading = function ActionSidebarLoading({
  onDismiss
}: {
  onDismiss: () => void;
}): ReactElement {
  return (
    <Container>
      <ActionHeader.Loading onDismiss={onDismiss} />
      <SectionSeparator />
      <ActionDetails.Loading />
    </Container>
  );
};

ActionSidebar.Error = function ActionSidebarError({
  onDismiss,
  onRetry
}: {
  onDismiss: () => void;
  onRetry: () => void;
}): ReactElement {
  return (
    <Container>
      <ActionHeader.Error onDismiss={onDismiss} />
      <ErrorContainer>
        <ErrorAlertScreen
          variant={"sidebar"}
          title={"There was a problem loading\nthe Action"}
          onRetry={onRetry}
        />
      </ErrorContainer>
    </Container>
  );
};

const Container = styled(ScrollView)`
  padding: 16px 32px
  height: 100%;
  border-left-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;

const SectionSeparator = styled(Separator)`
  margin: 0px -32px 0px 0px;
`;

const ErrorContainer = styled(View)`
  width: 100%
  height: 100%;
  align-items: center;
  justify-content: center;
`;
