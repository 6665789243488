import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { useCallback } from "react";
import { Action } from "local/domain/project";
import {
  CompleteActionMutation,
  useCompleteActionMutation
} from "local/graphql";
import { useActionAnalytics } from "./useActionAnalytics";

export function useCompleteAction({
  projectId,
  onCompleted,
  onError
}: {
  projectId: string;
  onCompleted?: ({ action }: { action: Action }) => void;
  onError?: () => void;
}): [(actionId: string) => void, MutationResult<CompleteActionMutation>] {
  const { onActionStatusChange } = useActionAnalytics(projectId);
  const [completeActionMutation, result] = useCompleteActionMutation();

  const completeAction = useCallback(
    async (id: string): Promise<void> => {
      let result: ExecutionResult<CompleteActionMutation>;

      try {
        result = await completeActionMutation({ variables: { id } });
        throwIfMutationFailed(result, { dataKey: "completeAction" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError?.();
        return;
      }

      const completedAction = result.data!.completeAction!;

      onActionStatusChange(completedAction);
      onCompleted?.({ action: completedAction });
    },
    [onActionStatusChange, completeActionMutation, onCompleted, onError]
  );

  return [completeAction, result];
}
