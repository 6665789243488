import { RightChevronIcon, Text } from "@ailo/primitives";
import { ListItem, ListItemProps, BriefcaseAvatar } from "@ailo/ui";
import React from "react";
import { formatABN } from "./formatABN";

export interface CompanyListItemProps
  extends Omit<ListItemProps, "header" | "subHeader" | "leftComponent"> {
  company: {
    registeredEntityName: string;
    registeredEntityId?: string | null;
  };
  onPress?: () => void;
}

export function CompanyListItem({
  company,
  onPress,
  ...props
}: CompanyListItemProps): React.ReactElement {
  return (
    <ListItem
      header={company.registeredEntityName}
      subHeader={
        company.registeredEntityId &&
        `ABN ${formatABN(company.registeredEntityId)}`
      }
      leftComponent={
        <BriefcaseAvatar size={40} iconSize={20} style={{ marginRight: 16 }} />
      }
      rightComponent={
        onPress ? <RightChevronIcon height={20} width={20} /> : undefined
      }
      onPress={onPress}
      {...props}
    />
  );
}

CompanyListItem.Loading = function CompanyListItem(
  props: Omit<CompanyListItemProps, "company">
): React.ReactElement {
  return (
    <ListItem
      header={<Text.BodyS.Loading width={120} />}
      subHeader={<Text.BodyS.Loading width={80} />}
      leftComponent={
        <BriefcaseAvatar.Loading size={40} style={{ marginRight: 16 }} />
      }
      {...props}
    />
  );
};
