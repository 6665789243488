import { Alert } from "@ailo/ui";
import React, { ReactElement } from "react";

export function ReiFormDeletedAlert(): ReactElement {
  return (
    <Alert
      type={"warning"}
      iconType={"warning"}
      message={
        "The form created is no longer available. Please generate a new form to start again"
      }
      style={{
        marginBottom: 24
      }}
    />
  );
}
