import { EditButton } from "@ailo/ui";
import { PropertyScreenCardErrorBoundary } from "local/domain/propertyManagement";
import { ManagementNoteModalForm } from "local/modals/ManagementNoteModalForm";
import React, { useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { ReadMore } from "./ReadMore";
import { useGetManagementNotes } from "local/domain/propertyManagement/hooks/useGetManagementNote";
import { PropertyContentCard } from "../PropertyContentCard";

interface Props {
  managementId: string;
  style?: StyleProp<ViewStyle>;
}

function ManagementNotesCard({
  style,
  managementId
}: Props): React.ReactElement {
  const { data, error } = useGetManagementNotes(managementId);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  if (error) {
    throw error;
  }

  if (!data?.note) {
    return <></>;
  }

  const { id, note } = data;

  return (
    <>
      <PropertyContentCard
        title={"Notes"}
        titleRight={
          <EditButton
            onPress={() => setIsEditModalVisible(true)}
            iconWidth={16.67}
            iconHeight={16.67}
          />
        }
        style={style}
      >
        <ReadMore numberOfLines={3} note={note} readLessText={"Read less"} />
      </PropertyContentCard>
      <ManagementNoteModalForm
        managementId={managementId}
        managementNoteId={id}
        note={note}
        onDismiss={() => {
          setIsEditModalVisible(false);
        }}
        onSuccess={() => {
          setIsEditModalVisible(false);
        }}
        visible={isEditModalVisible}
      />
    </>
  );
}

function ManagementNotesCardWithErrorBoundary({
  style,
  managementId
}: Props): React.ReactElement {
  return (
    <PropertyScreenCardErrorBoundary style={style} cardTitle={"Notes"}>
      <ManagementNotesCard style={style} managementId={managementId} />
    </PropertyScreenCardErrorBoundary>
  );
}

export { ManagementNotesCardWithErrorBoundary as ManagementNotesCard };
