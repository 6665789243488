import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import { Text, Colors } from "@ailo/primitives";
import { isEmpty } from "lodash";

export interface Column {
  label?: string | ReactElement;
  content: string | ReactElement | null;
}

const Container = styled(View)`
  align-items: stretch;
  margin: 60px 60px 0 32px;
  max-width: 308px;
`;

const Row = styled(View)`
  flex-direction: row;
  margin-bottom: 18px;
`;

const Column = styled(View)`
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
`;

function StyledLabel({ label }: Pick<Column, "label">): ReactElement | null {
  if (isEmpty(label) || label === undefined) return null;

  return typeof label === "string" ? (
    <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
      {label}
    </Text.BodyS>
  ) : (
    label
  );
}

function StyledContent({
  content
}: Pick<Column, "content">): ReactElement | null {
  if (typeof content === "string")
    return (
      <Text.BodyM
        weight={"book"}
        style={{
          marginTop: 2
        }}
      >
        {content}
      </Text.BodyM>
    );

  return content;
}

export function SplitScreenInfoPanel({
  rows
}: {
  rows: Column[][];
}): ReactElement {
  const rowsWithContent: Column[][] = rows
    .map((columns: Column[]) => columns.filter(({ content }) => !!content))
    .filter((row) => row.length);

  return (
    <Container>
      {rowsWithContent.map((columns, rowIndex) => (
        <Row key={rowIndex}>
          {columns.map(({ label, content }, columnIndex) => (
            <Column key={`${label}-${columnIndex}`}>
              <StyledLabel label={label} />
              <StyledContent content={content} />
            </Column>
          ))}
        </Row>
      ))}
    </Container>
  );
}

SplitScreenInfoPanel.Loading = function SplitScreenInfoPanelLoading({
  fullSize
}: {
  fullSize?: boolean;
}): React.ReactElement {
  return (
    <Container>
      <Text.BodyS.Loading width={60} />
      <Text.BodyS.Loading width={120} style={{ marginBottom: 12 }} />
      <Text.BodyS.Loading width={60} />
      <Text.BodyS.Loading width={120} style={{ marginBottom: 12 }} />
      {fullSize && (
        <>
          <Text.BodyS.Loading width={60} />
          <Text.BodyS.Loading width={120} style={{ marginBottom: 12 }} />
          <Text.BodyS.Loading width={307} />
          <Text.BodyS.Loading width={307} />
          <Text.BodyS.Loading width={154} />
        </>
      )}
    </Container>
  );
};
