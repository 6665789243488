import { DueDate } from "@ailo/domains";
import { SFC } from "@ailo/primitives";
import React from "react";
import { ErrorIcon, SuccessIcon } from "./icons";
import { SectionHeader } from "./sections";

interface Props {
  paidToDate: string | null | undefined;
  isGeneratingIncome: boolean;
}

const RentHealthHeader: SFC<Props> = ({
  style,
  paidToDate,
  isGeneratingIncome
}) => {
  const rentIsPaidUpToDate =
    !!paidToDate && !new DueDate(paidToDate).isOverdue();
  const isHealthy = isGeneratingIncome && rentIsPaidUpToDate;
  const HeaderIcon = isHealthy ? SuccessIcon : ErrorIcon;
  return <SectionHeader icon={HeaderIcon} title={"Rent"} style={style} />;
};

export { RentHealthHeader };
