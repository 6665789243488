import { ApolloError } from "@apollo/client";
import { useCurrentAgencyOrg } from "local/common";
import {
  ActivePropertiesQuery,
  useSearchManagementPropertiesLazyQuery
} from "local/domain/propertyManagement";
import { useCallback } from "react";

type SearchPropertyCallback = {
  searchProperties: (queryString: string) => void;
  data?: ActivePropertiesQuery;
  error?: ApolloError;
};
export function useGetSearchPropertiesCallback(): SearchPropertyCallback {
  const currentOrg = useCurrentAgencyOrg();
  const [
    searchManagementProperties,
    { data: searchManagementData, error: searchManagementError }
  ] = useSearchManagementPropertiesLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  const searchManagements = useCallback(
    (queryString: string): void => {
      searchManagementProperties({
        variables: {
          search: queryString,
          orgAilorn: currentOrg.ailoRN
        }
      });
    },
    [currentOrg.ailoRN, searchManagementProperties]
  );

  return {
    searchProperties: searchManagements,
    data: searchManagementData,
    error: searchManagementError
  };
}
