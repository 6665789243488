import React, { ReactElement, useCallback } from "react";
import { ConfirmModal, useToastContext } from "@ailo/ui";
import { Text } from "@ailo/primitives";
import { useTransferDeposit } from "../useTransferDeposit";

type Props = {
  tenancyDepositAilorn: string;
  managementId: string;
  visible: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  onError: () => void;
};

export function DepositTransferConfirmModal({
  tenancyDepositAilorn,
  managementId,
  visible,
  onSuccess,
  onCancel,
  onError
}: Props): ReactElement {
  const Toast = useToastContext();

  const submitSuccess = useCallback(() => {
    Toast.show({
      type: "success",
      message: "Initial payment transferred"
    });
    onSuccess();
  }, [onSuccess, Toast]);

  const [transferDeposit] = useTransferDeposit({
    onSuccess: submitSuccess,
    onError
  });

  const submit = useCallback(
    (): void =>
      transferDeposit({
        tenancyDepositAilorn,
        managementId
      }),
    [transferDeposit, tenancyDepositAilorn, managementId]
  );

  return (
    <ConfirmModal
      visible={visible}
      title={"Transfer initial payment to the investor's property wallet?"}
      onConfirm={submit}
      onCancel={onCancel}
    >
      <Text.BodyM weight={"book"}>
        {
          "Any outstanding agency fees will be automatically deducted upon transfer"
        }
      </Text.BodyM>
    </ConfirmModal>
  );
}
