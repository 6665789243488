import React, { ReactElement } from "react";
import { FormProvider, useForm, UseFormMethods } from "react-hook-form";
import {
  MoneyControl,
  ReconciliationDateControl,
  TextControl
} from "../components";
import { ReconciliationPeriod } from "../ReconciliationViewScreen/types";
import styled from "styled-components/native";

type AddAdjustmentFormFields = {
  applyOn: string;
  details: string;
  amount: string;
};

export function useAddAdjustmentForm(): UseFormMethods<AddAdjustmentFormFields> {
  return useForm<AddAdjustmentFormFields>();
}

type AddAdjustmentFormProps = {
  period: ReconciliationPeriod;
} & UseFormMethods<AddAdjustmentFormFields>;

export function AddAdjustmentForm({
  period,
  ...rest
}: AddAdjustmentFormProps): ReactElement {
  return (
    <FormProvider {...rest}>
      <ApplyOnController period={period} />
      <DetailsController />
      <AmountController />
    </FormProvider>
  );
}

const ApplyOnController = styled(ReconciliationDateControl).attrs({
  name: "applyOn",
  label: "Apply on"
})`
  margin-top: 12px;
`;

const DetailsController = styled(TextControl).attrs({
  name: "details",
  label: "Details"
})`
  margin-top: 24px;
`;

const AmountController = styled(MoneyControl).attrs({
  name: "amount",
  label: "Amount"
})`
  margin-top: 24px;
`;
