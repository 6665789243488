import { AFC } from "@ailo/primitives";
import { createStackNavigator } from "@react-navigation/stack";
import { ReportsTabStackNavigatorParamList, Screens } from "local/common";
import React from "react";
import { ReportScreen } from "./ReportScreen";
import { ReportListScreen } from "./ReportListScreen";
import { VisualizationScreen } from "./VisualizationScreen";

const Stack = createStackNavigator<ReportsTabStackNavigatorParamList>();

export const ReportsTabScreen: AFC = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, gestureEnabled: false }}
      initialRouteName={Screens.ReportList}
    >
      <Stack.Screen
        name={Screens.ReportList}
        options={{ title: "Reports" }}
        component={ReportListScreen}
      />
      <Stack.Screen
        name={Screens.ReportEmbed}
        options={{ title: "Reports" }}
        component={ReportScreen}
      />
      <Stack.Screen
        name={Screens.ReportVisulisation}
        options={{ title: "Reports" }}
        component={VisualizationScreen}
      />
    </Stack.Navigator>
  );
};
