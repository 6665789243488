import React, { useState } from "react";
import { View } from "react-native";
import { Colors, opacify, Text } from "@ailo/primitives";
import { CloseButton } from "local/common";
import { SpinnerOverlay } from "@ailo/ui";
import styled from "styled-components/native";

interface ModalFormProps {
  title: string;
  renderFormComponent: (
    onFormSubmitting: (isSubmitting: boolean) => void
  ) => React.ReactElement;
  renderRightComponent?: () => React.ReactElement;
  onDismiss: () => void;
}

export function SplitScreen({
  title,
  renderFormComponent,
  renderRightComponent,
  onDismiss
}: ModalFormProps): React.ReactElement {
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  return (
    <View>
      <HeaderContainer>
        <Text.BodyM weight={"medium"}>{title}</Text.BodyM>
        <CloseButton onDismiss={onDismiss} />
      </HeaderContainer>
      <View style={{ flexDirection: "row", flex: 1 }}>
        <LeftHandSide>
          {renderFormComponent((isSubmitting: boolean) =>
            setSpinnerVisible(isSubmitting)
          )}
        </LeftHandSide>
        <RightHandSide>
          {renderRightComponent && renderRightComponent()}
        </RightHandSide>
      </View>
      {spinnerVisible && <SpinnerOverlay visible={spinnerVisible} />}
    </View>
  );
}

const LeftHandSide = styled(View)`
  width: 420px;
`;
const RightHandSide = styled(View)`
  flex: 1;
  border-left-width: 1;
  border-left-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;

const HeaderContainer = styled(View)`
  max-height: 56px;
  flex-direction: row;
  flex: 1;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  align-items: center;
  justify-content: space-between;
`;
