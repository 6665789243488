import React, { useCallback } from "react";
import {
  KeyedApprovedMigratingManagementFragment,
  PropertyOnboardingListColumn
} from "../../types";
import {
  AddressCell,
  ApprovedByCell,
  ApprovedOnCell,
  CheckboxCell,
  CheckboxHeaderCell,
  PortfolioCell,
  PublishStatusCell
} from "../../PropertyOnboardingListItemCell";
import { ApprovedPropertyOnboardingListColumnNames } from "./useApprovedPropertyOnboardingList";
import { ApprovedPropertyOnboardingListSelect } from "./useApprovedPropertyOnboardingListSelect";

interface Params {
  select: ApprovedPropertyOnboardingListSelect;
  refetchList: () => void;
}

export type ApprovedPropertyOnboardingListColumns =
  PropertyOnboardingListColumn<KeyedApprovedMigratingManagementFragment>[];

export function useApprovedPropertyOnboardingListColumns({
  select: {
    selectedMigratingManagementIds,
    selectableMigratingManagementIds,
    setSelectedMigratingManagementIds
  },
  refetchList
}: Params): ApprovedPropertyOnboardingListColumns {
  const ApprovedCheckboxHeaderCell = useCallback(() => {
    return (
      <CheckboxHeaderCell
        disabled={selectableMigratingManagementIds.length === 0}
        value={
          selectableMigratingManagementIds.length !== 0 &&
          selectableMigratingManagementIds.length ===
            selectedMigratingManagementIds.length
        }
        onChange={(selected) => {
          setSelectedMigratingManagementIds(
            selected ? selectableMigratingManagementIds : []
          );
        }}
      />
    );
  }, [
    setSelectedMigratingManagementIds,
    selectableMigratingManagementIds,
    selectedMigratingManagementIds.length
  ]);

  const ApprovedCheckboxCell = useCallback(
    ({
      row,
      rowIndex
    }: {
      row: KeyedApprovedMigratingManagementFragment;
      rowIndex: number;
    }) => {
      return (
        <CheckboxCell
          row={row}
          rowIndex={rowIndex}
          selectedMigratingManagementIds={selectedMigratingManagementIds}
          setSelectedMigratingManagementIds={setSelectedMigratingManagementIds}
        />
      );
    },
    [setSelectedMigratingManagementIds, selectedMigratingManagementIds]
  );

  return [
    {
      maxWidth: "36px",
      renderHeaderCell: ApprovedCheckboxHeaderCell,
      renderCell: ApprovedCheckboxCell,
      renderLoadingCell: CheckboxCell.Loading,
      name: "checkbox",
      sortable: false
    },
    {
      width: 2,
      renderCell: AddressCell,
      renderLoadingCell: AddressCell.Loading,
      name: ApprovedPropertyOnboardingListColumnNames.Address,
      sortable: true,
      caretPosition: "right"
    },
    {
      width: 1,
      renderCell: PortfolioCell,
      renderLoadingCell: PortfolioCell.Loading,
      name: ApprovedPropertyOnboardingListColumnNames.Portfolio,
      sortable: true,
      caretPosition: "right"
    },
    {
      width: 1,
      renderCell: ApprovedByCell,
      renderLoadingCell: ApprovedByCell.Loading,
      name: ApprovedPropertyOnboardingListColumnNames.ApprovedBy,
      sortable: false,
      caretPosition: "right"
    },
    {
      width: 1,
      renderCell: ApprovedOnCell,
      renderLoadingCell: ApprovedOnCell.Loading,
      name: ApprovedPropertyOnboardingListColumnNames.ApprovedOn,
      sortable: true,
      caretPosition: "right"
    },
    {
      width: 1,
      renderCell({ row }) {
        return (
          <PublishStatusCell
            migratingManagementId={row.id}
            refetchList={refetchList}
          />
        );
      },
      renderLoadingCell: PublishStatusCell.Loading,
      name: ApprovedPropertyOnboardingListColumnNames.PublishStatus,
      horizontalAlign: "right",
      sortable: true,
      caretPosition: "left"
    }
  ];
}
