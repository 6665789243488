import React, { ReactElement } from "react";
import { MoneyInput } from "@ailo/ui";
import { AllocationFieldInputProps } from "./types";

export function AllocationAmountInput({
  allocationPurpose,
  value,
  ref,
  ...rest
}: AllocationFieldInputProps): ReactElement {
  return (
    <MoneyInput
      {...rest}
      value={
        allocationPurpose.name !== "Bill" ? value : allocationPurpose.amountDue
      }
      minHeight={36}
      inputStyle={{
        height: 36,
        textAlign: "right",
        fontSize: 13,
        fontWeight: "normal",
        lineHeight: 20
      }}
      inputRef={ref}
      editable={allocationPurpose.name !== "Bill"}
      withTrailingZeros
    />
  );
}
