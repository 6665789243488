import { AddExpenseFormData } from "local/domain/expense";
import React, { ComponentProps, ReactElement } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AddExpenseForm } from "./AddExpenseForm";

export function AddExpenseFormContext(
  props: ComponentProps<typeof AddExpenseForm>
): ReactElement {
  const form = useForm<AddExpenseFormData>();

  return (
    <FormProvider {...form}>
      <AddExpenseForm {...props} />
    </FormProvider>
  );
}

AddExpenseFormContext.Loading = function Loading(
  props: ComponentProps<typeof AddExpenseForm.Loading>
) {
  return <AddExpenseForm.Loading {...props} />;
};
