import { LocalDate } from "@ailo/date";
import { DatePeriod, Digit } from "./DatePeriod";

export class FinancialYearPeriod extends DatePeriod {
  public from(input: string | LocalDate): FinancialYearPeriod {
    if (input === null || input === undefined)
      throw Error(`QuarterPeriod can't be initialized by null or undefined`);
    return new FinancialYearPeriod(input);
  }

  public get startDate(): LocalDate {
    return (
      this.date.monthOfYear >= 6
        ? this.date.setYear(this.date.year)
        : this.date.setYear(this.date.year - 1)
    )
      .setDayOfMonth(1)
      .setMonthOfYear(7);
  }

  public get endDate(): LocalDate {
    return this.startDate.subtract(1, "day").setYear(this.startDate.year + 1);
  }

  public shortFormat(): string {
    return `FY ${this.date.year}/${this.date.add(1, "year").format("YY")}`;
  }

  public longFormat(): string {
    return `FY ${this.date.year}/${this.date.add(1, "year").format("YY")}`;
  }

  public parse(input: `20${Digit}${Digit}` | null | undefined): LocalDate {
    if (input === null || input === undefined)
      throw Error(
        `FinancialYearPeriod can't be initialized by null or undefined`
      );

    return LocalDate.from(`${input}-07-01`);
  }
}
