import { ActionType } from "local/graphql";
import React, { ReactElement } from "react";
import { RefinedActionActivityProps } from "../ActionActivityProps";
import { ConfirmInspectionScheduleView } from "./ConfirmInspectionScheduleView";
import { NoInspectionView } from "../CommonInspectionActionViews/NoInspectionView";
import { NoInspectionAppointmentView } from "../CommonInspectionActionViews/NoInspectionAppointmentView";

export function ConfirmInspectionScheduleActionActivity({
  projectId,
  action
}: RefinedActionActivityProps<ActionType.ConfirmInspectionSchedule>): ReactElement {
  const inspectionAppointment = action.meta?.inspectionAppointment;
  const tenancy = action.meta?.tenancy;
  if (!inspectionAppointment || !tenancy) {
    return (
      <NoInspectionAppointmentView action={action} projectId={projectId} />
    );
  }

  const { inspection } = inspectionAppointment;
  if (!inspection) {
    return <NoInspectionView />;
  }

  return <ConfirmInspectionScheduleView inspectionSchedule={tenancy} />;
}
