import {
  ConfirmModal,
  DateInputFormField,
  FormFieldRow,
  LocalDate,
  PercentInputFormField,
  Separator,
  TextInputFormField
} from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import React, { ReactElement, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { OwnerDetails } from "../../useCreateManagement";
import styled from "styled-components/native";
import { ExistingPersonInfoBox, DuplicateEmailError } from "./EmailInputAlerts";
import { View } from "react-native";
import { isValidPhoneNumber } from "libphonenumber-js";
import { isAValidEmail } from "@ailo/domains";

interface Props {
  onCancel(): void;
  onSubmit(ownerDetails: OwnerDetails): void;
  defaultValues?: OwnerDetails;
  otherOwnerEmails: string[];
  confirmText: string;
}

export function AddOrEditOwnerModalForm({
  onCancel,
  onSubmit,
  defaultValues,
  otherOwnerEmails,
  confirmText
}: Props): ReactElement {
  const form = useForm<OwnerDetails>({
    defaultValues,
    mode: "onBlur"
  });

  const { reset } = form;

  const submit = form.handleSubmit(onSubmit);
  const cancel = useCallback(() => {
    reset();
    onCancel();
  }, [onCancel, reset]);
  const email = form.watch("email");

  return (
    <ConfirmModal
      title={"Add owner"}
      onConfirm={submit}
      onCancel={cancel}
      showFooterBorder={true}
      confirmLabel={confirmText}
      borderRadius={12}
    >
      <Text.BodyL weight={"medium"} style={{ marginTop: 4, marginBottom: 8 }}>
        {"Ailo Account"}
      </Text.BodyL>
      <Text.BodyS
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginBottom: 24 }}
      >
        {
          "An Ailo account will be created if one doesn’t already exist. The supplied email may be used for important notices or formal  correspondence."
        }
      </Text.BodyS>
      <View style={{ marginBottom: 24 }}>
        <FormFieldRow>
          <Controller
            control={form.control}
            name={"email"}
            defaultValue={null}
            render={({ value, onChange, onBlur }) => (
              <TextInputFormField
                {...{ value, onBlur }}
                label={"Email Address"}
                placeholder={"Email address"}
                error={form.errors.email?.message}
                onChangeText={(text): void => {
                  const trimmedEmail = text.replace(/\s/g, "");
                  if (!trimmedEmail) {
                    onChange(null);
                  } else {
                    onChange(trimmedEmail);
                  }
                }}
              />
            )}
            rules={{
              validate: {
                pattern: (email) => {
                  if (email == null) {
                    return true;
                  }

                  if (!isAValidEmail(email)) {
                    return "Please enter a valid e-mail address";
                  }
                },
                noDuplicateEmails: (email) => !otherOwnerEmails.includes(email)
              }
            }}
          />
        </FormFieldRow>
        {form.errors.email?.type === "noDuplicateEmails" && (
          <DuplicateEmailError style={{ marginTop: 16 }} />
        )}
        <Controller
          control={form.control}
          name={"isExistingUser"}
          defaultValue={undefined}
          render={({ value, onChange }) => (
            <ExistingPersonInfoBox
              email={email}
              value={value}
              onChange={onChange}
              style={{ marginTop: 16 }}
            />
          )}
          rules={{
            validate: {
              requiredIfEmailProvided: (value) =>
                email ? value !== undefined : true
            }
          }}
        />
      </View>
      <StyledFormFieldRow>
        <Controller
          control={form.control}
          name={"phoneNumber"}
          defaultValue={null}
          render={({ value, onChange, onBlur }) => (
            <TextInputFormField
              {...{ value, onChange, onBlur }}
              label={"Mobile Number"}
              error={form.errors.phoneNumber?.message}
              placeholder={"+614 123 456 789"}
              onChangeText={(text): void => {
                const number = text.replace(/[^\d+()-]/g, "");
                if (!number) {
                  onChange(null);
                } else {
                  onChange(number);
                }
              }}
            />
          )}
          rules={{
            validate: {
              pattern: (number) => {
                if (number == null) {
                  return true;
                }

                if (!isValidPhoneNumber(number, "AU"))
                  return "Please enter a valid mobile number";
              }
            }
          }}
        />
      </StyledFormFieldRow>
      <Separator
        style={{
          marginTop: 16,
          marginBottom: 32,
          marginLeft: 0,
          marginRight: -20
        }}
      />
      <Text.BodyL weight={"medium"} style={{ marginBottom: 20 }}>
        {"Personal Details"}
      </Text.BodyL>
      <StyledFormFieldRow>
        <Controller
          control={form.control}
          name={"firstName"}
          defaultValue={null}
          render={({ value, onChange, onBlur }) => (
            <TextInputFormField
              {...{ value, onChange, onBlur }}
              label={"First Name"}
              error={form.errors.firstName?.message}
              placeholder={"First name"}
            />
          )}
          rules={{
            required: "First name required"
          }}
        />
        <Controller
          control={form.control}
          name={"preferredName"}
          defaultValue={null}
          render={({ value, onChange, onBlur }) => (
            <TextInputFormField
              {...{ value, onChange, onBlur }}
              label={"Preferred Name (optional)"}
              placeholder={"Preferred name"}
            />
          )}
        />
      </StyledFormFieldRow>
      <StyledFormFieldRow>
        <Controller
          control={form.control}
          name={"middleName"}
          defaultValue={null}
          render={({ value, onChange, onBlur }) => (
            <TextInputFormField
              {...{ value, onChange, onBlur }}
              label={"Middle Name (optional)"}
              placeholder={"Middle name"}
            />
          )}
        />
        <Controller
          control={form.control}
          name={"lastName"}
          defaultValue={null}
          render={({ value, onChange, onBlur }) => (
            <TextInputFormField
              {...{ value, onChange, onBlur }}
              label={"Last Name"}
              error={form.errors.lastName?.message}
              placeholder={"Last name"}
            />
          )}
          rules={{
            required: "Last name required"
          }}
        />
      </StyledFormFieldRow>
      <FormFieldRow style={{ marginBottom: 24 }}>
        <Controller
          control={form.control}
          render={({ value, onBlur, onChange }): React.ReactElement => {
            return (
              <DateInputFormField
                webPopoverHorizontalAlign={"start"}
                onBlur={onBlur}
                onChange={(date): void => {
                  onChange(date ?? null);
                }}
                placeholder={"DD/MM/YYYY"}
                autoCorrect={false}
                autoCompleteType={"off"}
                value={value}
                label={"Date of Birth (optional)"}
                displayDatePicker={false}
                dateFormat={"DD/MM/YYYY"}
                error={form.errors.dateOfBirth?.message}
              />
            );
          }}
          name={"dateOfBirth"}
          defaultValue={null}
          rules={{
            validate: (date) => {
              if (!date) return;
              const dateIsAfterToday = LocalDate.fromString(date).isAfter(
                LocalDate.today()
              );
              return dateIsAfterToday
                ? "Date cannot be in the future"
                : undefined;
            }
          }}
        />
        <></>
      </FormFieldRow>
      <Separator
        style={{
          marginTop: 16,
          marginBottom: 32,
          marginLeft: 0,
          marginRight: -20
        }}
      />
      <Text.BodyL weight={"medium"} style={{ marginBottom: 20 }}>
        {"Management Details"}
      </Text.BodyL>
      <StyledFormFieldRow cols={2}>
        <Controller
          control={form.control}
          name={"sharesOwned"}
          defaultValue={null}
          render={({ value, onChange, onBlur }) => (
            <PercentInputFormField
              {...{ value, onChange, onBlur }}
              label={"Shares owned"}
              format={"decimal"}
              decimalPlaces={0}
              error={form.errors.sharesOwned?.message}
              placeholder={0}
            />
          )}
          rules={{
            required: "Shares owned is required"
          }}
        />
      </StyledFormFieldRow>
    </ConfirmModal>
  );
}

const StyledFormFieldRow = styled(FormFieldRow)`
  margin-bottom: 24px;
`;
