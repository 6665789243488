import React, { ReactElement } from "react";
import { ErrorCell } from "./ErrorCell";
import { MigratingManagementPublishStatus } from "local/graphql";
import { useGetMigratingManagementPublishStatus } from "./hooks";
import { Badge, BadgeCellLoading, CellContainer } from "@ailo/ui";

function PublishStatusCell({
  migratingManagementId,
  refetchList
}: {
  migratingManagementId: string;
  refetchList: () => void;
}): ReactElement {
  const { data, loading, error } = useGetMigratingManagementPublishStatus({
    migratingManagementId,
    refetchList
  });

  if (loading) {
    return <BadgeCellLoading />;
  }

  if (error || !data?.migratingManagement) {
    return <ErrorCell />;
  }

  if (
    data.migratingManagement.publishStatus ===
    MigratingManagementPublishStatus.InProgress
  ) {
    return (
      <CellContainer>
        <Badge type={"success"}>{"In Progress"}</Badge>
      </CellContainer>
    );
  }

  if (
    data.migratingManagement.publishStatus ===
    MigratingManagementPublishStatus.Failed
  ) {
    return (
      <CellContainer>
        <Badge type={"critical"}>{"Failed"}</Badge>
      </CellContainer>
    );
  }

  return (
    <CellContainer>
      <Badge>{"Approved"}</Badge>
    </CellContainer>
  );
}

PublishStatusCell.Loading = BadgeCellLoading;

export { PublishStatusCell };
