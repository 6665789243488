import { Text } from "@ailo/primitives";
import React, { ReactElement, useMemo, useState } from "react";
import { View } from "react-native";
import { useReportSection } from "../../VisualizationScreen/reportsDetails";
import {
  AllReportsWrapper,
  ReportSectionWrapper
} from "./ReportComponentWrappers";
import { SectionCard } from "./ReportSectionCards";

function SectionColumns(narrowWidth: boolean): ReactElement {
  const sectionCards = useReportSection().map((section) => (
    <SectionCard key={section.title} {...section} />
  ));

  if (narrowWidth) {
    return <View>{sectionCards}</View>;
  }

  const sectionKeysLeft = sectionCards.filter((_, index) => !(index % 2));
  const sectionKeysRight = sectionCards.filter((_, index) => index % 2);
  return (
    <View>
      <ReportSectionWrapper>
        <View>{sectionKeysLeft}</View>
        <View>{sectionKeysRight}</View>
      </ReportSectionWrapper>
    </View>
  );
}

export function ReportColumns(): ReactElement {
  const [width, setWidth] = useState<number>();
  const narrowWidth: boolean = useMemo(() => {
    if (width) {
      return width < 1152;
    }
    return false;
  }, [width]);

  const reportDisplay = SectionColumns(narrowWidth);

  return (
    <View>
      <Text.DisplayS style={{ paddingBottom: 20 }}>
        {"All reports"}
      </Text.DisplayS>
      <AllReportsWrapper
        narrowWidth={narrowWidth}
        onLayout={(event) => {
          setWidth(event.nativeEvent.layout.width);
        }}
      >
        {reportDisplay}
      </AllReportsWrapper>
    </View>
  );
}
