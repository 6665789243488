import { FilterBar, RadioButtonFilterInput, SearchBox } from "@ailo/ui";
import React, { Dispatch, ReactElement, SetStateAction, useMemo } from "react";
import { LeftColumn, RightColumn } from "../components";
import {
  TransactionCategory,
  TransactionListFilters
} from "./useGetTransactions";

const valueToLabelOptionDefinitions: Record<TransactionCategory, string> = {
  "this-month": "This Month",
  "last-month": "Last Month",
  all: "All"
};

export function TransactionsFilterBar({
  filters,
  setFilters
}: {
  filters: TransactionListFilters;
  setFilters: Dispatch<SetStateAction<TransactionListFilters>>;
}): ReactElement {
  const filterOptions = useMemo(() => {
    return (
      Object.keys(valueToLabelOptionDefinitions) as TransactionCategory[]
    ).map((val: TransactionCategory) => ({
      label: valueToLabelOptionDefinitions[val],
      value: val,
      onSelect: () => {
        setFilters((f) => ({
          ...f,
          category: val
        }));
      }
    }));
  }, [setFilters]);

  return (
    <FilterBar>
      <LeftColumn>
        <SearchBox
          onInputFinish={(queryString) =>
            setFilters((f) => ({ ...f, searchQuery: queryString }))
          }
        />
      </LeftColumn>
      <RightColumn>
        <RadioButtonFilterInput
          options={filterOptions}
          defaultValue={valueToLabelOptionDefinitions[filters.category]}
        />
      </RightColumn>
    </FilterBar>
  );
}
