import React, { useCallback, useEffect, useState } from "react";
import { ErrorBoundary, useAnalytics, useOnFocus } from "@ailo/services";
import { tenancyVacatingReasons } from "local/domain/propertyManagement";
import {
  ErrorAlertScreen,
  ErrorModal,
  SidebarContent,
  SidebarTitle,
  SpinnerOverlay,
  Separator
} from "@ailo/ui";
import styled from "styled-components/native";
import { View } from "react-native";
import {
  VacateReasonAndNotes,
  ConfirmEditEndTenancyModal,
  SidebarBottom,
  TenancyEndDate
} from "./components";
import {
  useGetDataForEditEndTenancySidebar,
  useTenancyEndDateChange
} from "./hooks";
import { useSubmit } from "./hooks/useSubmit";
import { VacatingReason } from "local/graphql";

interface EditEndTenancySidebarProps {
  isEdit: boolean;
  managementId: string;
  tenancyId: string;
  onDismiss: () => void;
}

function EditEndTenancySidebar({
  isEdit,
  managementId,
  tenancyId,
  onDismiss
}: EditEndTenancySidebarProps): React.ReactElement {
  const analytics = useAnalytics();
  useEffect(() => {
    analytics.trackScreenVisited(
      isEdit ? "Edit End of Tenancy" : "End of Tenancy"
    );
  }, [analytics, isEdit]);

  const { data, error, refetch } = useGetDataForEditEndTenancySidebar(
    tenancyId,
    managementId
  );

  useOnFocus(refetch);

  const { onTenancyEndDateChange, tenancyEndDate, amountDueAtEndDate } =
    useTenancyEndDateChange({
      liabilityId: data?.liabilityId,
      oldTenancyEndDate: data?.tenancyEndDate
    });

  const [vacatingReason, setVacatingReason] = useState<VacatingReason>();
  const [vacatingNotes, setVacatingNotes] = useState(data?.vacatingNotes || "");

  const { submitting, errorModal, confirmModal, onSubmit } = useSubmit({
    isEdit,
    managementId,
    tenancyEndDate,
    tenancyId,
    vacatingNotes,
    vacatingReason: vacatingReason!,
    onSubmitSuccess: onDismiss
  });

  useEffect(() => {
    setVacatingReason(data?.vacatingReason ?? tenancyVacatingReasons[0].value);
    setVacatingNotes(data?.vacatingNotes || "");
  }, [data?.vacatingReason, data?.vacatingNotes]);

  const Footer = useCallback(
    (): React.ReactElement => (
      <SidebarBottom
        disableSave={!tenancyEndDate}
        onCancel={onDismiss}
        onSave={(): void => {
          analytics.track(
            isEdit
              ? "Clicked Save Edit End Tenancy"
              : "Clicked Save End Tenancy",
            {
              selectedType: vacatingReason,
              noteAdded: !!vacatingNotes,
              selectedEndDate: tenancyEndDate?.toString()
            }
          );
          confirmModal.open();
        }}
      />
    ),
    [
      tenancyEndDate,
      onDismiss,
      isEdit,
      confirmModal,
      analytics,
      vacatingReason,
      vacatingNotes
    ]
  );

  if (error)
    return (
      <EditEndTenancySidebarError
        isEdit={isEdit}
        onDismiss={onDismiss}
        onRetry={refetch}
      />
    );

  return (
    <SidebarContent
      footer={<Footer />}
      style={{ paddingBottom: 48 }}
      title={isEdit ? "Edit End Tenancy" : "End Tenancy"}
      onClose={onDismiss}
    >
      <TenancyEndDate
        nextTenancyDateInfo={data?.nextTenancyDateInfo}
        tenancyAgreementDateInfo={data?.tenancyAgreementDateInfo}
        tenancyEndDate={tenancyEndDate}
        validEndDateRange={data?.validEndDateRange}
        onTenancyEndDateChange={onTenancyEndDateChange}
      />
      <Separator
        style={{
          marginVertical: 32,
          marginRight: -32,
          marginLeft: 0
        }}
      />
      <VacateReasonAndNotes
        allVacatingReasons={tenancyVacatingReasons}
        vacatingNotes={vacatingNotes}
        vacatingReason={vacatingReason!}
        onVacatingNoteChanged={setVacatingNotes}
        onVacatingReasonChanged={setVacatingReason}
      />

      <ConfirmEditEndTenancyModal
        amountDueAtEndDate={amountDueAtEndDate}
        currentPaidToDate={data?.effectivePaidToDate}
        isEdit={isEdit}
        tenancyEndDate={tenancyEndDate!}
        vacatingNotes={vacatingNotes}
        vacatingReason={vacatingReason!}
        visible={confirmModal.visible}
        depositStatus={data?.depositStatus}
        onCancel={confirmModal.close}
        onConfirm={onSubmit}
      />

      <ErrorModal
        description={"Please try again."}
        title={"Failed to process request"}
        visible={errorModal.visible}
        onDismiss={errorModal.close}
      />

      <SpinnerOverlay visible={submitting} />
    </SidebarContent>
  );
}

function EditEndTenancySidebarWithErrorBoundary({
  isEdit,
  managementId,
  tenancyId,
  onDismiss
}: EditEndTenancySidebarProps): React.ReactElement {
  return (
    <ErrorBoundary
      fallbackComponent={({ retry }): React.ReactElement => (
        <EditEndTenancySidebarError
          isEdit={isEdit}
          onDismiss={onDismiss}
          onRetry={retry}
        />
      )}
    >
      <EditEndTenancySidebar
        isEdit={isEdit}
        managementId={managementId}
        tenancyId={tenancyId}
        onDismiss={onDismiss}
      />
    </ErrorBoundary>
  );
}

function EditEndTenancySidebarError({
  isEdit,
  onDismiss,
  onRetry
}: {
  isEdit: boolean;
  onDismiss: () => void;
  onRetry?: () => void;
}): React.ReactElement {
  const title = isEdit ? "Edit End Tenancy" : "End Tenancy";

  return (
    <Container>
      <SidebarTitle title={title} closeButton onClose={onDismiss} />
      <ErrorAlertScreen
        title={`There was a problem loading\n${title}`}
        variant={"sidebar"}
        onRetry={onRetry}
      />
    </Container>
  );
}

const Container = styled(View)`
  flex: 1;
`;

export { EditEndTenancySidebarWithErrorBoundary as EditEndTenancySidebar };
