import React from "react";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";
import { Row } from "local/common";
import { BorderedBox } from "@ailo/ui";
import { TenancyBond } from "local/tabs";
import { AddReferenceLink } from "./AddReferenceLink";
import { BondStatusBadge } from "local/domain/bond";

type Props = {
  bond: TenancyBond;
  showStatus?: boolean;
  onPressEdit?: () => void;
};

export function BondDetails({
  bond,
  showStatus,
  onPressEdit
}: Props): React.ReactElement {
  return (
    <BorderedBox
      style={{
        borderRadius: 8,
        margin: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 12,
        paddingBottom: 12
      }}
    >
      <Row style={{ width: "100%" }}>
        <View style={{ flex: 1 }}>
          <Row>
            <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
              {"Bond reference"}
            </Text.BodyS>

            {bond.reference ? (
              <Text.BodyS color={Colors.TEXT.DARK.PRIMARY}>
                {bond.reference}
              </Text.BodyS>
            ) : (
              <AddReferenceLink onPress={onPressEdit} />
            )}
          </Row>

          <Row style={{ marginTop: 8 }}>
            <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
              {"Bond amount"}
            </Text.BodyS>

            <Text.BodyS color={Colors.TEXT.DARK.PRIMARY}>
              {bond.amount.format()}
            </Text.BodyS>
          </Row>

          {showStatus && (
            <Row style={{ marginTop: 8 }}>
              <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
                {"Status"}
              </Text.BodyS>

              <Text.BodyS color={Colors.TEXT.DARK.PRIMARY}>
                <BondStatusBadge
                  status={bond.status}
                  failureReason={bond.failureReason}
                />
              </Text.BodyS>
            </Row>
          )}
        </View>
      </Row>
    </BorderedBox>
  );
}
