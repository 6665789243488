import React from "react";
import { Button } from "@ailo/ui";
import { StyleProp, ViewStyle } from "react-native";

export function ViewAllButton({
  onPress,
  style
}: {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <Button variant={"secondary"} onPress={onPress} style={style}>
      {"View All"}
    </Button>
  );
}

ViewAllButton.Loading = Button.Loading;
