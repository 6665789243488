import { Colors, Text } from "@ailo/primitives";
import React, { ReactElement, useEffect } from "react";
import { UseFormMethods } from "react-hook-form";
import { NewTenancyFormData } from "../NewTenancyForm";
import { View } from "react-native";
import { AgreementDetailsSection } from "./AgreementDetailsSection";
import { TenantDetailsSection } from "./TenantDetailsSection";
import { useAnalytics } from "@ailo/services";
import { DepositDetailsSection } from "./DepositDetailsSection";
import { FilesState, useHasFeature } from "@ailo/domains";
import { BaseFormSeparator } from "local/modals/ui/BaseFormSeparator";
import { PlatformFeatureId } from "local/graphql";

interface Props {
  form: UseFormMethods<NewTenancyFormData>;
  filesState?: FilesState;
  display: boolean;
  onEditLeasePress?(): void;
  onEditTenantsPress?(): void;
  onEditDepositPress?(): void;
}

export function ReviewTenancyDetailsStep({
  form,
  filesState,
  display,
  onEditLeasePress,
  onEditTenantsPress,
  onEditDepositPress
}: Props): ReactElement {
  const analytics = useAnalytics();
  useEffect(() => {
    if (display === true) {
      analytics.trackScreenVisited("New Tenancy — Review");
    }
  }, [display, analytics]);

  const hasDepositsFeature = useHasFeature(PlatformFeatureId.Deposits);

  return (
    <View style={{ display: display ? undefined : "none" }}>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
        {"Review new tenancy details"}
      </Text.BodyM>
      <BaseFormSeparator />
      <AgreementDetailsSection
        form={form}
        onEditPress={onEditLeasePress}
        filesState={filesState}
      />
      <BaseFormSeparator />
      <TenantDetailsSection form={form} onEditPress={onEditTenantsPress} />
      {hasDepositsFeature && (
        <>
          <BaseFormSeparator />
          <DepositDetailsSection form={form} onEditPress={onEditDepositPress} />
        </>
      )}
    </View>
  );
}
