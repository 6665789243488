import { OwnerDetails } from "./useCreateManagement";

export const NewManagementFormFields = {
  agreementStartDate: "agreementStartDate",
  agreementEndDate: "agreementEndDate",
  managementFeePercent: "managementFeePercent",
  owners: "owners",
  ownersError: "ownersError",
  address: "address"
} as const;

export const AddressFields = {
  unitStreetNumber: "unitStreetNumber",
  streetName: "streetName",
  suburb: "suburb",
  state: "state",
  country: "country",
  postcode: "postcode"
} as const;

export const AddressFieldsList = Object.keys(AddressFields);

export interface AddressFormData {
  [AddressFields.unitStreetNumber]?: string;
  [AddressFields.streetName]?: string;
  [AddressFields.suburb]?: string;
  [AddressFields.state]?: string;
  [AddressFields.country]?: string;
  [AddressFields.postcode]?: string;
}

export interface NewManagementFormData {
  [NewManagementFormFields.agreementStartDate]?: string;
  [NewManagementFormFields.agreementEndDate]?: string;
  [NewManagementFormFields.managementFeePercent]?: number;
  [NewManagementFormFields.owners]?: { details: OwnerDetails }[];
  [NewManagementFormFields.ownersError]?: boolean; //dummy field to store errors against all the owners together
  [NewManagementFormFields.address]?: AddressFormData;
}
