import { Button, useGlobalModal } from "@ailo/ui";
import {
  SwitcherHeaderOption,
  Screens,
  TabNavigation,
  useNavigation,
  useRoute
} from "local/common";
import { ActionModalForm } from "local/modals/ActionModalForm/ActionModalForm";
import React, { useCallback } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

interface Props {
  tabs: SwitcherHeaderOption[];
  selectedTabIndex: number;
}

export function ActionListHeader({
  tabs,
  selectedTabIndex
}: Props): React.ReactElement {
  const { id: projectId } = useRoute<Screens.Project>().params!;

  if (!projectId) {
    throw new Error("Project id cannot be null in this screen");
  }

  const navigation = useNavigation<Screens.Project>();
  const modal = useGlobalModal();

  const cancel = useCallback((): void => {
    modal.hide();
  }, [modal]);

  return (
    <Container>
      <StyledTabNavigation
        options={tabs}
        selected={selectedTabIndex}
        navigation={navigation}
      />
      <Button
        style={{ marginLeft: 12 }}
        onPress={() =>
          modal.show(
            <ActionModalForm projectId={projectId} onDismiss={cancel} />
          )
        }
      >
        {"Create action"}
      </Button>
    </Container>
  );
}

const Container = styled(View)`
  width: 100%;
  max-width: 576px;
  padding: 0 64px 0 4px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledTabNavigation = styled(TabNavigation)`
  flex: 1;
`;
