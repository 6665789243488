import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { useCallback } from "react";
import { MutationResult } from "@apollo/client";
import {
  GetAllTenanciesDepositsDocument,
  CancelTenancyDepositMutation,
  useCancelTenancyDepositMutation
} from "local/graphql";
import { ExecutionResult } from "graphql";

interface DepositDetails {
  tenancyDepositAilorn: string;
  managementId: string;
}

export function useCancelDeposit({
  onSuccess,
  onError
}: {
  onSuccess: () => void;
  onError: () => void;
}): [
  (details: DepositDetails) => void,
  MutationResult<CancelTenancyDepositMutation>
] {
  const [cancelDepositMutation, result] = useCancelTenancyDepositMutation();

  const cancelTenancyDeposit = useCallback(
    async ({ tenancyDepositAilorn, managementId }: DepositDetails) => {
      let result: ExecutionResult<CancelTenancyDepositMutation>;

      try {
        result = await cancelDepositMutation({
          variables: {
            tenancyDepositAilorn
          },
          refetchQueries: [
            {
              query: GetAllTenanciesDepositsDocument,
              variables: { managementId }
            }
          ]
        });

        throwIfMutationFailed(result, { dataKey: "cancelTenancyDeposit" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }

      onSuccess();
    },
    [cancelDepositMutation, onSuccess, onError]
  );

  return [cancelTenancyDeposit, result];
}
