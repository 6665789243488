import { formatAddress } from "@ailo/domain-helpers";
import { Colors, Text } from "@ailo/primitives";
import { Badge } from "@ailo/ui";
import { LocalDate } from "@ailo/date";
import { PendingInspectionAppointmentFragment } from "local/graphql";
import moment from "moment";
import React from "react";
import { View } from "react-native";

interface Props {
  appointment: PendingInspectionAppointmentFragment;
}

export function AppointmentAddress({ appointment }: Props): React.ReactElement {
  const appointmentMissed = LocalDate.fromMoment(
    moment(appointment.startTime)
  ).isBefore(LocalDate.today());

  const address = appointment.property.address;

  return (
    <View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Text.BodyM weight={"medium"} color={Colors.TEXT.DARK.PRIMARY}>
          {formatAddress(address, {
            format: "street"
          })}
        </Text.BodyM>
        {appointmentMissed && (
          <Badge type={"critical"} style={{ marginLeft: 8 }}>
            {"Missed"}
          </Badge>
        )}
      </View>
      {address.suburb && (
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
          {address.suburb}
        </Text.BodyS>
      )}
    </View>
  );
}
