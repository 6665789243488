import { DateInputFormField } from "@ailo/ui";
import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext, Validate } from "react-hook-form";
import {
  NewManagementFormData,
  NewManagementFormFields
} from "../../NewManagementFormData";
import { LocalDate } from "@ailo/date";

export function AgreementEndDateInput({
  validate,
  minEndDate
}: {
  validate?: Validate | Record<string, Validate>;
  minEndDate?: LocalDate;
}): ReactElement {
  const { watch, trigger, control, errors } =
    useFormContext<NewManagementFormData>();

  const agreementStartDate = watch(NewManagementFormFields.agreementStartDate);

  useEffect(() => {
    if (agreementStartDate) trigger(NewManagementFormFields.agreementEndDate);
  }, [agreementStartDate, trigger]);

  return (
    <Controller
      control={control}
      render={({ value, onBlur, onChange }): React.ReactElement => (
        <DateInputFormField
          webPopoverHorizontalAlign={"start"}
          onBlur={onBlur}
          onChange={(date): void => {
            onChange(date ?? null);
          }}
          minDate={minEndDate?.toString()}
          placeholder={"End date"}
          autoCorrect={false}
          autoCompleteType={"off"}
          allowAnyTypedDate={true}
          value={value}
          label={"Agreement End"}
          error={errors.agreementEndDate?.message}
        />
      )}
      name={NewManagementFormFields.agreementEndDate}
      defaultValue={null}
      rules={{
        validate
      }}
    />
  );
}
