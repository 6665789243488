import React from "react";
import { BasePropertyListRow } from "../BasePropertyListRow";
import { useGetManagementAddressQuery } from "@ailo/domains";
import { AddressCellUI } from "./AddressCellUI";

export function AddressCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetManagementAddressQuery({
    variables: { managementId }
  });

  if (loading && !data) {
    return <AddressCellUI.Loading />;
  }

  const address = data?.management?.property?.address;

  if (!address)
    return (
      <AddressCellUI.Error
        onReload={(): void => {
          refetch();
        }}
      />
    );

  return <AddressCellUI address={address} />;
}

AddressCell.Loading = AddressCellUI.Loading;
