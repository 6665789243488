import React from "react";
import { ActionType } from "local/graphql";
import { RefinedActionActivityProps } from "local/tabs/projects/actions/ActionSidebar/ActionActivity/ActionActivityProps";
import { Text, Colors } from "@ailo/primitives";
import { GenericActionCard } from "../GenericActionCard";

export function ViewWithoutReiToken({
  action,
  projectId
}: RefinedActionActivityProps<ActionType.TrackDocusign>): React.ReactElement {
  return (
    <GenericActionCard action={action} projectId={projectId}>
      <Text.BodyXS>
        <Text.BodyXS>{"Agreement has been created"}</Text.BodyXS>
        <Text.BodyXS color={Colors.TEXT.DARK.SECONDARY}>
          {` · Please connect your forms account to view`}
        </Text.BodyXS>
      </Text.BodyXS>
    </GenericActionCard>
  );
}
