import React from "react";
import { Card, Button } from "@ailo/ui";
import styled from "styled-components/native";
import { StyleProp, View, ViewStyle } from "react-native";
import { WarningSolidIcon, Colors, Text } from "@ailo/primitives";

interface Props {
  style?: StyleProp<ViewStyle>;
  cardTitle: string;
  onRetry?: () => void;
}

export function ErrorCard({
  style,
  cardTitle,
  onRetry: retry
}: Props): React.ReactElement {
  return (
    <StyledCard style={style}>
      <Container>
        <WarningSolidIcon
          color={Colors.STATUS.WARNING}
          secondaryColor={Colors.WHITE}
          width={38}
          height={38}
        />
        <Title weight={"medium"}>
          {"There was a problem loading "}
          {cardTitle}
        </Title>
        <Subtitle color={Colors.TEXT.DARK.SECONDARY}>
          {"Try"}
          <Button.Text onPress={retry}>
            <Text.BodyS weight={"medium"} color={Colors.AILO_RED}>
              &nbsp;{"reloading"}
            </Text.BodyS>
          </Button.Text>
        </Subtitle>
      </Container>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  margin: 0;
  padding: 16px;
`;

const Container = styled(View)`
  align-items: center;
  margin: 16px 0 24px;
`;

const Title = styled(Text.BodyL)`
  text-align: center;
  margin-top: 12px;
`;

const Subtitle = styled(Text.BodyS)`
  text-align: center;
  margin-top: 8px;
`;
