import { Screens } from "./Screens";
import {
  getStateFromPath as defaultGetStateFromPath,
  getStateFromPath,
  LinkingOptions,
  NavigationState,
  PartialState,
  PathConfigMap
} from "@react-navigation/native";
import {
  ContactsTabView,
  MonthYear,
  OnboardingListView,
  ProjectListTab,
  PropertyListView
} from "./screenParams";
import { getRedirectURLPath } from "./redirectDotNewRoute";

export const linking: LinkingOptions = {
  prefixes: [],
  config: {
    screens: {
      [Screens.AppNavigator]: {
        screens: {
          [Screens.AnalyticsDashboardTab]: {
            path: "dashboard"
          },
          [Screens.ContactsTab]: {
            path: "contacts/:contactId?",
            parse: {
              tab: (tab?: string): string => {
                if (!tab) {
                  return ContactsTabView.CUSTOMERS;
                }
                if (tab === "other-contacts") {
                  return ContactsTabView.OTHER_CONTACTS;
                }
                return tab;
              }
            },
            stringify: {
              contactId: (contactId: string): string => {
                return contactId || "";
              },
              tab: (tab: string): string => {
                if (tab === ContactsTabView.OTHER_CONTACTS) {
                  return "other-contacts";
                }
                return tab.toLowerCase();
              }
            }
          },
          [Screens.BillsTab]: {
            path: "bills/:billId?/:billSubview?",
            stringify: {
              billId: (billId: string): string => {
                return billId || "";
              },
              billSubview: (billSubview: string): string => {
                return billSubview || "";
              }
            }
          },
          [Screens.ProjectsTab]: {
            path: "projects",
            screens: {
              [Screens.ProjectList]: {
                path: "",
                parse: {
                  tab: (tab?: string): ProjectListTab => (tab ? tab : "open"),
                  create: (create?: string): boolean => create === "1"
                },
                stringify: {
                  tab: (tab?: ProjectListTab): string => {
                    const lowerCaseTab = tab?.toLowerCase() || "open";
                    return lowerCaseTab === "open"
                      ? (undefined as any)
                      : lowerCaseTab;
                  },
                  create: (create?: boolean): string =>
                    create ? "1" : (undefined as any)
                }
              },
              [Screens.Action]: {
                path: ":projectId/actions/:id"
              },
              [Screens.Project]: {
                path: ":id",
                stringify: {
                  previousScreen: () => undefined as any
                }
              }
            }
          },
          [Screens.CommsTab]: {
            path: "comms/:teamId",
            screens: {
              [Screens.EmptyChat]: "",
              [Screens.NewChatMessage]: {
                path: "new-message/:chatId?",
                stringify: {
                  chatId: (chatId: string): string => {
                    return chatId || "";
                  }
                }
              },
              [Screens.ChatThread]: {
                path: ":chatId/:threadId?",
                stringify: {
                  threadId: (threadId: string): string => {
                    return threadId || "";
                  },
                  chatId: (chatId: string): string => {
                    return chatId || "";
                  }
                }
              }
            }
          },
          [Screens.Portfolio]: {
            path: "portfolios/:portfolioId"
          },
          [Screens.PropertiesTab]: {
            path: "properties",
            screens: {
              [Screens.PropertyList]: {
                path: "",
                parse: {
                  tab: (tab?: string): string => {
                    if (!tab) {
                      return PropertyListView.PROPERTIES;
                    }
                    if (tab === "renewals-reviews") {
                      return PropertyListView.RENEWALS_AND_REVIEWS;
                    }
                    return tab;
                  },
                  statuses: (statuses?: string): string[] =>
                    statuses ? statuses.split(" ") : []
                },
                stringify: {
                  tab: (tab: string): string => {
                    if (tab === PropertyListView.PROPERTIES) {
                      return undefined as any;
                    }
                    if (tab === PropertyListView.RENEWALS_AND_REVIEWS) {
                      return "renewals-reviews";
                    }
                    return tab.toLowerCase();
                  },
                  statuses: (): string => {
                    return undefined as any;
                  }
                }
              },
              [Screens.Property]: {
                path: ":managementId"
              },
              [Screens.AddExpense]: {
                path: ":managementId/expenses/new"
              },
              [Screens.ClaimTenancyBond]: {
                path: ":managementId/tenancies/:tenancyId/claim-tenancy-bond"
              },
              [Screens.EditTenancyBondClaim]: {
                path: ":managementId/tenancies/:tenancyId/edit-tenancy-bond-claim"
              },
              [Screens.ClaimTenancyBondSuccess]: {
                path: ":managementId/tenancies/:tenancyId/claim-tenancy-bond-success"
              },
              [Screens.RentAdjustments]: {
                path: ":managementId/tenancies/:tenancyId/adjustments"
              },
              [Screens.CreateRentAdjustment]: {
                path: ":managementId/tenancies/:tenancyId/create-rent-adjustment"
              }
            }
          },
          [Screens.ReportsTab]: {
            path: "reports",
            screens: {
              [Screens.ReportList]: "",
              [Screens.ReportEmbed]: {
                path: "v1/:metabaseEmbedToken",
                parse: {
                  metabaseEmbedToken: (metabaseEmbedToken?: string) => {
                    return metabaseEmbedToken || "";
                  }
                }
              },
              [Screens.ReportVisulisation]: {
                path: "v2/:reportName",
                parse: {
                  reportName: (reportName: string) => {
                    return reportName;
                  }
                }
              }
            }
          },
          [Screens.SettingsTab]: {
            path: "settings/:tab?/:id?",
            stringify: {
              id: stringifyOptionalStringParam
            }
          },
          [Screens.WalletTab]: {
            path: "wallet/:legalEntityId?"
          },
          [Screens.OnboardingTab]: {
            path: "onboarding/:migratingManagementId?",
            parse: {
              tab: (tab?: string): string => {
                if (!tab || tab === "awaiting-approval") {
                  return OnboardingListView.AWAITING_APPROVAL;
                }
                return tab;
              }
            },
            stringify: {
              migratingManagementId: (
                migratingManagementId?: string
              ): string => {
                return migratingManagementId || "";
              },
              tab: (tab?: string): string => {
                if (tab === OnboardingListView.AWAITING_APPROVAL) {
                  return undefined as any;
                }
                return tab?.toLowerCase() ?? (undefined as any);
              }
            }
          },
          [Screens.DisbursementsList]: {
            path: "trust/disbursements",
            parse: {
              tab: (tab?: string): string | undefined => {
                return tab?.replace(/-/g, " ");
              }
            },
            stringify: {
              tab: (tab?: string): string => {
                return (
                  tab?.replace(/ /g, "-").toLowerCase() ?? (undefined as any)
                );
              }
            }
          },
          [Screens.TransactionsList]: {
            path: "trust/transactions"
          },
          [Screens.TrustReconciliationStackNavigator]: {
            path: "trust/reconciliation",
            screens: {
              [Screens.TrustReconciliationList]: {
                path: ""
              },
              [Screens.TrustReconciliationView]: {
                path: ":monthYear",
                parse: {
                  monthYear: MonthYear.parse
                },
                stringify: {
                  monthYear: MonthYear.stringify
                }
              },
              [Screens.TrustReconciliationAdjustmentList]: {
                path: ":monthYear/adjustment",
                parse: {
                  monthYear: MonthYear.parse
                },
                stringify: {
                  monthYear: MonthYear.stringify
                }
              }
            }
          }
        }
      },
      [Screens.RecommendAiloInsight]: "insights",
      [Screens.RecommendConsumerApp]: "consumer"
    }
  },
  getStateFromPath: (path, options): any => {
    if (path.startsWith("/chat")) {
      return getStateFromPath("/comms/-", options);
    }

    if (path.startsWith("/new")) {
      return processNewRedirect(path, options);
    }

    return defaultGetStateFromPath(path, options);
  }
};

type Options = {
  initialRouteName?: string;
  screens: PathConfigMap;
};
type NestedPartialState = PartialState<NavigationState> & {
  state?: NestedPartialState;
};

/**
 * See: https://ailo.atlassian.net/wiki/spaces/TS/pages/2878832770/https+ailo.new+redirection
 */
function processNewRedirect(path: string, options?: Options): any {
  const urlParts = path.match(/^\/new\/?([^/]+)?/);
  const redirectTo = (urlParts && urlParts[1]) ?? "";

  const redirectRoute = getRedirectURLPath(redirectTo);
  return getStateFromPath(redirectRoute, options);
}

function modifyRouteParams(
  routeName: Screens,
  newParams: object,
  navState?: NestedPartialState
): boolean {
  if (!navState) return false;

  return (
    modifyRouteParamsOnCurrentLevel(routeName, newParams, navState) ||
    modifyRoundParamsOnChildren(routeName, newParams, navState)
  );
}

function modifyRouteParamsOnCurrentLevel(
  routeName: Screens,
  newParams: object,
  navState: NestedPartialState
): boolean {
  const index = navState.routes.findIndex((route) => route.name === routeName);
  if (index === -1) return false;

  const route = navState.routes[index];

  navState.routes[index] = {
    ...route,
    params: { ...route.params, ...newParams }
  };
  return true;
}

function modifyRoundParamsOnChildren(
  routeName: Screens,
  newParams: object,
  navState: NestedPartialState
): boolean {
  for (const childRoute of navState.routes) {
    const routeUpdated = modifyRouteParams(
      routeName,
      newParams,
      childRoute.state
    );
    if (routeUpdated) return true;
  }

  return false;
}

/**
 * Use this if you prefer to delete the param completely when it equals to "undefined".
 */
function stringifyOptionalStringParam(value: string): string {
  return !!value && value !== "undefined" ? value : (undefined as any);
}
