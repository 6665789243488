import { AiloRN, services } from "@ailo/ailorn";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { TabNavigation } from "@ailo/ui";
import { AddExpenseButton } from "local/domain/expense";
import { ExpensesList } from "local/domain/expense/ExpensesList";
import { AutomaticFeesList } from "local/domain/fee";
import { PropertyScreenCardErrorBoundary } from "local/domain/propertyManagement";
import React, { ReactElement, useCallback, useState } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { TenancyTabContent } from "./TenancyTabContent";
import { PropertyContentCard } from "../PropertyContentCard";

const tabs = [
  { key: "tenancy" as const, label: "Tenancy" },
  { key: "management" as const, label: "Management" },
  { key: "recurring" as const, label: "Recurring" }
];
interface ExpensesCardProps {
  managementId: string;
  onExpensePress?: (ailorn: AiloRN) => void;
  onViewMoreExpensesPress: ({
    tabId,
    tenancyId
  }: {
    tabId: "tenancy" | "management" | "recurring";
    tenancyId?: string;
  }) => void;
  style?: StyleProp<ViewStyle>;
}

export function ExpensesCardContent({
  managementId,
  onExpensePress,
  onViewMoreExpensesPress
}: Omit<ExpensesCardProps, "style">): ReactElement | null {
  const hasManagementFeeBlueprintFeature = useHasFeature(
    PlatformFeatureId.ManagementFeeBlueprint
  );

  const [selectedTab, setSelectedTab] = useState(tabs[1]);
  const onTabChange = useCallback(
    (tab): void => setSelectedTab(tab),
    [setSelectedTab]
  );

  const managementAilorn = AiloRN.of(
    services.PropertyManagement.management,
    managementId
  );

  const tabContent =
    selectedTab.key === "tenancy" ? (
      <TenancyTabContent
        managementId={managementId}
        onExpensePress={onExpensePress}
        onViewMoreExpensesPress={(tenancyId) =>
          onViewMoreExpensesPress?.({ tabId: "tenancy", tenancyId })
        }
      />
    ) : selectedTab.key === "management" ? (
      <ExpensesList
        payerAilorn={managementAilorn}
        onExpensePress={onExpensePress}
        onViewMoreExpensesPress={() =>
          onViewMoreExpensesPress?.({ tabId: "management" })
        }
      />
    ) : (
      <AutomaticFeesList
        managementId={managementId}
        onViewMoreExpensesPress={() =>
          onViewMoreExpensesPress?.({ tabId: "recurring" })
        }
      />
    );

  return (
    <View style={{ flexGrow: 1 }}>
      <TabNavigation
        options={tabs.filter((tab) =>
          hasManagementFeeBlueprintFeature ? tab.key !== "recurring" : true
        )}
        selectedOptionKey={selectedTab.key}
        onChange={onTabChange}
        style={{ paddingLeft: 16 }}
      />
      {tabContent}
    </View>
  );
}

export function ExpensesCard({
  style,
  ...rest
}: ExpensesCardProps): ReactElement {
  return (
    <PropertyScreenCardErrorBoundary cardTitle={"Expenses"} style={style}>
      <PropertyContentCard
        title={"Expenses"}
        titleRight={<AddExpenseButton managementId={rest.managementId} />}
        style={[{ minHeight: 397 }, style]}
        hasTitleSeparator={false}
      >
        <ExpensesCardContent {...rest} />
      </PropertyContentCard>
    </PropertyScreenCardErrorBoundary>
  );
}
