import { AiloRN } from "@ailo/ailorn";
import { TenantCentrepayInfo } from "local/tabs/properties/components/PropertyScreen/components/TenancySidebarContent/components/CentrepaySection/TenantCentrepayInfo";

export function getTenantsCentrepayStatus(
  centrepayRenters: { tenantLegalEntity: AiloRN }[] | undefined,
  tenants: TenantCentrepayInfo[]
): TenantCentrepayInfo[] {
  return tenants.map((tenant) => ({
    ...tenant,
    centrepaySetUp:
      (centrepayRenters || []).filter((centrepayRenter) => {
        return (
          centrepayRenter.tenantLegalEntity.toString() ===
          tenant.legalEntity.toString()
        );
      }).length > 0
  }));
}
