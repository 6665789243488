import { getExpenseStatusBadgeType, getExpenseStatusText } from "@ailo/domains";
import {
  EditExpenseModalFormController,
  EditExpenseModalFormData
} from "local/domain/expense";
import {
  ExpenseType,
  ExpenseUnionType
} from "local/domain/expense/graphql/useGetExpense";
import React, { useState } from "react";
import { isPresent } from "ts-is-present";
import { ExpenseHeaderUI } from "./ExpenseHeaderUI";

interface ExpenseHeaderProps {
  expense?: ExpenseUnionType;
  title?: string;
  onGoBack?(): void;
  onClose?(): void;
  loading?: boolean;
}

export function ExpenseHeader({
  expense,
  title,
  onGoBack,
  onClose,
  loading
}: ExpenseHeaderProps): React.ReactElement | null {
  const [editExpenseModalData, setEditExpenseModalData] = useState<
    EditExpenseModalFormData | undefined
  >(undefined);
  const closeEditExpenseModal = (): void => setEditExpenseModalData(undefined);
  const showEditExpenseModal =
    expense?.expenseType === "Fee"
      ? (): void => setEditExpenseModalData(expense)
      : undefined;

  if (loading || !expense) {
    return <ExpenseHeader.Loading />;
  }

  const { payeeName } = expense;
  if (!payeeName) {
    throw new TypeError(`payeeName is undefined`);
  }

  const statusBadge = expense.status
    ? {
        testID: "Status",
        type: getExpenseStatusBadgeType(expense.status),
        text: getExpenseStatusText(expense.status)
      }
    : undefined;

  const badges = [statusBadge].filter(isPresent);

  const editButton =
    expense.expenseType === ExpenseType.FEE && expense.status === "Due"
      ? { label: "Edit", onPress: showEditExpenseModal }
      : undefined;

  const rightButtons = [editButton].filter(isPresent);

  return (
    <>
      <ExpenseHeaderUI
        title={title}
        label={payeeName}
        totalAmount={expense.totalAmount}
        badges={badges}
        rightButtons={rightButtons}
        onGoBack={onGoBack}
        onClose={onClose}
      />
      <EditExpenseModalFormController
        expense={editExpenseModalData}
        onDismiss={closeEditExpenseModal}
      />
    </>
  );
}

ExpenseHeader.Loading = ExpenseHeaderUI.Loading;
