import React, { ReactElement, useCallback } from "react";
import { KeyedLiveMigratingManagementFragment } from "../../types";
import { useNavigateToProperty } from "local/domain/propertyManagement";

interface Params {
  tabNavigation: ReactElement;
}

export interface LivePropertyOnboardingListContent {
  headerCaptionContent: (showGridHeader: boolean) => ReactElement;
  rowClickAction: (rowData: KeyedLiveMigratingManagementFragment) => void;
}

export function useLivePropertyOnboardingListContent({
  tabNavigation
}: Params): LivePropertyOnboardingListContent {
  const navigateToProperty = useNavigateToProperty();
  const headerCaptionContent = useCallback((): React.ReactElement => {
    return tabNavigation;
  }, [tabNavigation]);

  const rowClickAction = useCallback(
    (rowData: KeyedLiveMigratingManagementFragment) => {
      if (rowData.ailoManagement) {
        navigateToProperty(rowData.ailoManagement.id);
      }
    },
    [navigateToProperty]
  );

  return {
    headerCaptionContent,
    rowClickAction
  };
}
