import React, { ReactElement } from "react";
import { Colors, Link, Text } from "@ailo/primitives";
import {
  Alert,
  FormFieldRow,
  LabelledTextInput,
  useOpenUrlTab
} from "@ailo/ui";
import { StyleProp, View, ViewStyle } from "react-native";
import { ManagementEndDateInput } from "./ManagementEndDateInput";
import { ManagementEndReasonInput } from "./ManagementEndReasonInput";
import { ManagementEndNoteInput } from "./ManagementEndNoteInput";
import { ManagementLostReasonInput } from "./ManagementLostReasonInput";
import styled from "styled-components/native";
import {
  EndingManagementDetailsFragment,
  ManagementEndDetailsFragment,
  ManagementEndReasonFragment
} from "local/graphql";
import { useEnvironment } from "@ailo/services";
import { LocalDate } from "@ailo/date";

interface Props {
  style?: StyleProp<ViewStyle>;
  tenancyEndDate?: LocalDate;
  management: EndingManagementDetailsFragment & ManagementEndDetailsFragment;
  endReasons: ManagementEndReasonFragment[];
}

export function EndManagementSection({
  management,
  tenancyEndDate,
  endReasons
}: Props): ReactElement {
  return (
    <EndManagementSectionContent
      management={management}
      tenancyEndDate={tenancyEndDate}
      endReasons={endReasons}
    />
  );
}

function EndManagementSectionContent({
  style,
  tenancyEndDate,
  management,
  endReasons
}: Props): ReactElement {
  const { startDate, endDate, endNote, endReason } = management;
  const { AILO_HELP_CENTER_CHANGE_OWNERSHIP_URL } = useEnvironment();
  const contactAiloSupport = useOpenUrlTab(
    AILO_HELP_CENTER_CHANGE_OWNERSHIP_URL
  );
  return (
    <View style={style}>
      <Text.BodyM
        color={Colors.TEXT.DARK.SECONDARY}
        style={{
          marginBottom: 20
        }}
      >
        {
          "Ended managements appear in the former management tab. Investors and renters will have ongoing access to past communication and data within their Ailo app."
        }
      </Text.BodyM>
      <Alert type={"info"} style={{ marginBottom: 24 }}>
        {"Need to manage a change of ownership? Visit our "}
        <Link variant={"primary"} onPress={contactAiloSupport}>
          {"Help Center"}
        </Link>
        {"."}
      </Alert>
      <InputContainer>
        <ManagementEndDateInput
          initialValue={endDate}
          managementStartDate={LocalDate.from(startDate || "")}
          tenancyEndDate={tenancyEndDate!}
        />
      </InputContainer>
      <InputContainer>
        <ManagementEndReasonInput
          initialValue={endReason?.code}
          endReasons={endReasons}
        />
      </InputContainer>

      <ManagementLostReasonInput
        initialValue={endReason?.causes.map((cause) => cause.code)}
        endReasons={endReasons}
      />

      <InputContainer style={{ marginBottom: 0 }}>
        <ManagementEndNoteInput initialValue={endNote} />
      </InputContainer>
    </View>
  );
}

EndManagementSection.Loading = function Loading(): ReactElement {
  return (
    <View>
      <View
        style={{
          marginTop: 20,
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <LabelledTextInput.Loading style={{ flex: 1, marginRight: 16 }} />
        <LabelledTextInput.Loading style={{ flex: 1 }} />
        <LabelledTextInput.Loading style={{ flex: 1 }} />
      </View>
    </View>
  );
};

export const InputContainer = styled(FormFieldRow)`
  margin-bottom: 24px;
`;
