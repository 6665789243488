export enum Screens {
  Action = "Action",
  AddExpense = "Add Expense",
  AppNavigator = "App Navigator",
  AnalyticsDashboardTab = "Dashboard",
  BillsTab = "Bill Drawer",
  ChatImageCarousel = "Chat Image Carousel",
  NewChatMessage = "New Chat Message",
  ChatMessage = "Chat Message List",
  ChatThread = "Chat Thread List",
  ChatThreadMessage = "Chat Messages",
  CommsTab = "Root Comms Navigator",
  CreateRentAdjustment = "Create Rent Adjustment",
  EditTenancy = "Edit Tenancy",
  EmptyChat = "Empty Chat",
  Fees = "Fees",
  Fees2 = "Fees 2",
  Login = "Login",
  NewTenancy = "Create New Tenancy",
  OnboardingTab = "OnboardingTab",
  Project = "Project",
  ProjectsTab = "Projects Navigator",
  ProjectList = "Projects List",
  PropertiesTab = "Property Navigator",
  Portfolio = "Portfolio",
  Property = "Property",
  PropertyList = "Property List",
  PropertyOnboardingList = "Property Onboarding List",
  RecommendAiloInsight = "Recommend Ailo Insight",
  RecommendConsumerApp = "Recommend Consumer App",
  RentAdjustments = "RentAdjustments",
  ReportEmbed = "Report Embed",
  ReportList = "Report List",
  ReportVisulisation = "Report Visualization",
  ReportsTab = "Reports Tab",
  SettingsTab = "Settings",
  FormsConnectionCallback = "FormsConnectionCallback",
  WalletTab = "Wallet",
  ContactsTab = "Contacts",
  ClaimTenancyBond = "ClaimTenancyBond",
  ClaimTenancyBondSuccess = "ClaimTenancyBondSuccess",
  EditTenancyBondClaim = "EditTenancyBondClaim",
  TrustAccountingTab = "Trust Accounting",
  DisbursementsList = "Disbursements List",
  TransactionsList = "Transactions",
  TrustReconciliationList = "Reconciliations",
  TrustReconciliationView = "Reconciliation",
  TrustReconciliationAdjustmentList = "Adjustments",
  TrustReconciliationStackNavigator = "Reconciliation Stack Navigator"
}
