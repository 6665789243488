import React, { ReactElement, useCallback } from "react";
import { getState } from "local/common";
import {
  ClaimTenancyData,
  getBondAuthorityData,
  useBondAnalytics
} from "local/domain/bond";
import { BaseClaimBondForm } from "./BaseClaimBondForm";
import { useNavigateToSuccessScreen } from "./useFormSteps/useNavigateToSuccessScreen";
import { isPresent } from "ts-is-present";

type Props = {
  data: ClaimTenancyData;
  onClose(): void;
};

export function ClaimBondForm({ data, onClose }: Props): ReactElement {
  const { onBondClaimCreated } = useBondAnalytics();
  const navigateToSuccessScreen = useNavigateToSuccessScreen({ data, onClose });

  const onSubmitSuccess = useCallback(
    (result) => {
      const claims = result.data?.upsertTenancyBondClaims;
      const state = getState(data?.property.address.state);

      onBondClaimCreated({
        bondId: data.bond.id,
        managementId: data.managementId,
        state,
        claims: claims?.filter(isPresent)
      });

      navigateToSuccessScreen();
    },
    [navigateToSuccessScreen, onBondClaimCreated, data]
  );

  const bondAuthority = getBondAuthorityData(
    getState(data.property.address.state || undefined)
  );

  const formConfiguration = {
    title: "Claim bond",
    submitButtonText: "Record claim in Ailo",
    onSubmitSuccess,
    claimLiabilitiesStep: {
      nextButtonLabel: "Confirm amount"
    },
    confirmClaimStep: {
      actionDescription:
        "Please confirm the details are correct before submitting the bond claim",
      bondAuthorityActionDescription: bondAuthority
        ? `Submit the bond claim and/or amount to be released to renters to ${bondAuthority.name}`
        : undefined,
      submitEnabledByDefault: false
    }
  };

  return (
    <BaseClaimBondForm
      data={data}
      formConfiguration={formConfiguration}
      onClose={onClose}
    />
  );
}
