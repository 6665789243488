import { useToastContext } from "@ailo/ui";

export function useReiFormDownloadButtonError(): () => void {
  const toast = useToastContext();

  return (error?: string) => {
    toast.show({
      type: "error",
      message: error || "Unable to download form. Please try again."
    });
  };
}
