import { AiloRN, services } from "@ailo/ailorn";
import { getPriceChangeDecription } from "@ailo/domains";
import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { Money, MoneyInterface, useToastContext } from "@ailo/ui";
import Big from "big.js";
import { ExecutionResult } from "graphql";
import { useAddBillForm } from "local/domain/bill/AddBillForm";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import { AgencyFeeBlueprintOption } from "local/domain/fee/managementFeeBlueprint/ManagementFeeBlueprintModalForm";
import { useCreateManagementFeeBlueprint } from "local/domain/fee/managementFeeBlueprint/ManagementFeeBlueprintModals/useCreateManagementFeeBlueprint";
import {
  CreateBillMutation,
  CreateFeeMutation,
  CreateManagementFeeBlueprintMutation,
  CreateRecurringFeeMutation,
  FeeBaseAmountType,
  FeeBlueprintChargeType,
  FeeBlueprintType,
  FeeType,
  GetManagementRecurringFeesDocument,
  TaxTreatment,
  useCreateFeeMutation,
  useCreateRecurringFeeMutation
} from "local/graphql";
import { useState } from "react";
import { OneOffFeeBlueprintOption } from "../useGetOneOffFeeOptions";
import { RecurringFeeBlueprintOption } from "../useGetRecurringFeeOptions";

export type CreateEventBasedFeeFormData = {
  feeBlueprint: AgencyFeeBlueprintOption;
  feeType: FeeBlueprintType;
  blueprintId: string;
  fixedAmount?: MoneyInterface;
  oneWeekRentPercentage?: number;
  includesGst?: boolean;
  description?: string;
};

export type CreateRecurringFeeFormData = {
  feeBlueprint: RecurringFeeBlueprintOption;
  feeType: FeeBlueprintType;
  blueprintId: string;
  managementAgreementId: string;
  totalAmount: MoneyInterface;
  startDate: string;
  description?: string;
};

export type CreateOneOffFeeFormData = {
  feeBlueprint: OneOffFeeBlueprintOption;
  feeType: FeeBlueprintType;
  chargeType: FeeBlueprintChargeType;
  blueprintId: string;
  managementId: string;
  taxCategoryId: string;
  totalAmount: MoneyInterface;
  oneWeekRentPercentage?: number;
  baseAmount?: MoneyInterface;
  description?: string;
};

// Should really be a union but then react-hook-form doesn't like that
export type AddExpenseFormData = AddBillFormData &
  CreateRecurringFeeFormData &
  CreateOneOffFeeFormData &
  CreateEventBasedFeeFormData;

type AddedExpense = NonNullable<
  | CreateFeeMutation["fee"]
  | CreateBillMutation["createBill"]
  | CreateRecurringFeeMutation["createRecurringFee"]
  | CreateManagementFeeBlueprintMutation["createManagementFeeBlueprint"]
>;

interface Props {
  managementId: string;
  managementAgreementId: string;
  managingEntityAilorn: string;
  propertyAddress?: string;
  weeklyRent?: Money;
  onSubmitSuccess?: (expense?: AddedExpense) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAddExpense({
  managementId,
  managementAgreementId,
  managingEntityAilorn,
  propertyAddress,
  weeklyRent,
  onSubmitSuccess
}: Props) {
  const toast = useToastContext();
  const analytics = useAnalytics();
  const [submitFeeSending, setSubmitFeeSending] = useState(false);

  const {
    submit: submitBill,
    sending: submitBillSending,
    formError,
    dismissFormError,
    setFormError
  } = useAddBillForm({
    managementId,
    managingEntityAilorn,
    propertyAddress,
    onSubmitSuccess
  });

  const [createEventBasedFee] = useCreateManagementFeeBlueprint();

  const submitEventBasedFee = async (
    data: CreateEventBasedFeeFormData
  ): Promise<void> => {
    setSubmitFeeSending(true);

    const result = await createEventBasedFee({
      managementId,
      feeBlueprintId: data.feeBlueprint.value,
      fixedAmount: data.fixedAmount
        ? { cents: data.fixedAmount.cents }
        : undefined,
      oneWeekRentPercentage: data.oneWeekRentPercentage,
      taxTreatment: data.includesGst
        ? TaxTreatment.Inclusive
        : TaxTreatment.Exclusive,
      description: data.description
    });

    if (result?.id) onSubmitSuccess?.(result);

    setSubmitFeeSending(false);
  };

  const [createRecurringFee] = useCreateRecurringFeeMutation();
  const submitRecurringFee = async (
    data: CreateRecurringFeeFormData
  ): Promise<void> => {
    let result: ExecutionResult<CreateRecurringFeeMutation>;
    try {
      setSubmitFeeSending(true);
      const { feeBlueprint, totalAmount, startDate, description } = data;
      result = await createRecurringFee({
        variables: {
          input: {
            blueprintId: feeBlueprint.value,
            managementAgreementId: AiloRN.of(
              services.PropertyManagement.managementAgreement,
              managementAgreementId
            ).toString(),
            schedules: [
              {
                taxAmount: {
                  cents:
                    feeBlueprint.taxTreatment === TaxTreatment.NoTax
                      ? 0
                      : new Big(totalAmount.cents)
                          .div(11)
                          .round(0, 0)
                          .toNumber()
                },
                taxInclusiveAmount: {
                  cents: totalAmount.cents
                },
                startDate
              }
            ],
            description
          }
        },
        refetchQueries: [
          {
            query: GetManagementRecurringFeesDocument,
            variables: {
              managementId,
              managementAiloRN: AiloRN.of(
                services.PropertyManagement.management,
                managementId
              ).toString()
            }
          }
        ]
      });
      throwIfMutationFailed(result, { dataKey: "createRecurringFee" });
      analytics.track("Fee Added to Property", {
        feeFrequency: feeBlueprint.frequency,
        feeBlueprintId: AiloRN.fromString(feeBlueprint.value).internalId,
        feePrice: getPriceChangeDecription({
          current: totalAmount,
          original: Money.from(feeBlueprint.price).multiply(
            feeBlueprint.taxTreatment === TaxTreatment.Exclusive ? 1.1 : 1
          )
        })
      });
      if (result.data?.createRecurringFee)
        onSubmitSuccess?.(result.data.createRecurringFee);
    } catch (error) {
      AiloSentry.captureException(error);
      toast.showFormSubmitError();
    }
    setSubmitFeeSending(false);
  };

  const [createOneOffFee] = useCreateFeeMutation();
  const submitOneOffFee = async (
    data: CreateOneOffFeeFormData
  ): Promise<void> => {
    let result: ExecutionResult<CreateFeeMutation>;
    try {
      setSubmitFeeSending(true);
      const { feeBlueprint, totalAmount, oneWeekRentPercentage, description } =
        data;
      const blueprintAilorn = AiloRN.fromString(feeBlueprint.value);
      result = await createOneOffFee({
        variables: {
          input: {
            managementId,
            feeBlueprintId: blueprintAilorn.isA(
              "propertymanagement:feeblueprint"
            )
              ? blueprintAilorn.internalId
              : undefined,
            managementFeeBlueprintId: blueprintAilorn.isA(
              "propertymanagement:managementfeeblueprint"
            )
              ? blueprintAilorn.internalId
              : undefined,
            type: FeeType.OneOffFee,
            taxCategoryId: feeBlueprint.taxCategoryId,
            amount: { cents: totalAmount.cents },
            taxAmount: {
              cents:
                feeBlueprint.taxTreatment === TaxTreatment.NoTax
                  ? 0
                  : new Big(totalAmount.cents).div(11).round(0, 0).toNumber()
            },
            ...(oneWeekRentPercentage !== null &&
            oneWeekRentPercentage !== undefined &&
            weeklyRent
              ? {
                  percentage: oneWeekRentPercentage,
                  baseAmount: { cents: weeklyRent.cents },
                  baseAmountType: FeeBaseAmountType.OneWeekRent
                }
              : {}),
            description
          }
        }
      });
      throwIfMutationFailed(result, { dataKey: "fee" });
      const priceState =
        totalAmount && feeBlueprint.price
          ? {
              current: totalAmount,
              original: Money.from(feeBlueprint.price).multiply(
                feeBlueprint.taxTreatment === TaxTreatment.Exclusive ? 1.1 : 1
              )
            }
          : oneWeekRentPercentage != undefined &&
            feeBlueprint.oneWeekRentPercentage != undefined
          ? {
              current: oneWeekRentPercentage,
              original: new Big(feeBlueprint.oneWeekRentPercentage)
                .mul(
                  feeBlueprint.taxTreatment === TaxTreatment.Exclusive ? 1.1 : 1
                )
                .round(4)
                .toNumber()
            }
          : undefined;
      analytics.track("Fee Added to Property", {
        feeFrequency: "one-off",
        feeBlueprintId: AiloRN.fromString(feeBlueprint.value).internalId,
        feePrice: priceState ? getPriceChangeDecription(priceState) : "Unknown"
      });
      if (result.data?.fee) onSubmitSuccess?.(result.data.fee);
    } catch (error) {
      AiloSentry.captureException(error);
      toast.showFormSubmitError();
    }
    setSubmitFeeSending(false);
  };

  const submit = (data: AddExpenseFormData): Promise<void> => {
    const { supplierReference, managingEntityAilorn, feeType } = data;
    const isEventBased =
      (data as CreateEventBasedFeeFormData).feeBlueprint?.type ===
      FeeBlueprintType.EventBasedFee;

    const isFee =
      supplierReference && supplierReference.value === managingEntityAilorn;
    if (isEventBased) return submitEventBasedFee(data);
    if (isFee && feeType === FeeBlueprintType.RecurringFee)
      return submitRecurringFee(data);
    else if (isFee && feeType === FeeBlueprintType.OneOffFee)
      return submitOneOffFee(data);
    else return submitBill(data);
  };

  return {
    submit,
    submitRecurringFee,
    submitEventBasedFee,
    sending: submitBillSending || submitFeeSending,
    formError,
    dismissFormError,
    setFormError
  };
}
