import React, { ReactElement } from "react";
import { View } from "react-native";
import { CheckboxInput } from "@ailo/ui";
import { IconSkeleton } from "@ailo/primitives";

function CheckboxCell({
  isSelected,
  onChange
}: {
  isSelected: boolean;
  onChange: (nextValue: boolean) => void;
}): ReactElement {
  return (
    <CheckboxInput
      style={{ height: 20, width: 28, alignItems: "center" }}
      value={isSelected}
      onChange={onChange}
    />
  );
}

function Loading(): React.ReactElement {
  return (
    <View style={{ flex: 1, justifyContent: "center" }}>
      <IconSkeleton style={{ height: 16, width: 16 }} />
    </View>
  );
}

CheckboxCell.Loading = Loading;

export { CheckboxCell };
