import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { AFC } from "@ailo/primitives";
import {
  Screens,
  PropertiesTabStackNavigatorParamList,
  PropertyListView
} from "local/common";
import {
  AddExpenseScreen,
  PropertyListScreen,
  PropertyScreen,
  ClaimTenancyBondScreen,
  RentAdjustmentListScreen,
  CreateRentAdjustmentScreen,
  ClaimSuccessScreen,
  EditTenancyBondClaimScreen
} from "./components";

const Stack = createStackNavigator<PropertiesTabStackNavigatorParamList>();

const PropertiesTabScreen: AFC = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, gestureEnabled: false }}
      initialRouteName={Screens.PropertyList}
    >
      <Stack.Screen
        name={Screens.PropertyList}
        options={{ title: "Properties" }}
        component={PropertyListScreen}
        initialParams={{ tab: PropertyListView.PROPERTIES }}
      />
      <Stack.Screen
        options={{ title: "Properties" }}
        name={Screens.Property}
        component={PropertyScreen}
      />
      <Stack.Screen
        options={{ title: "Add Expense" }}
        name={Screens.AddExpense}
        component={AddExpenseScreen}
      />
      <Stack.Screen
        options={{ title: "Claim bond" }}
        name={Screens.ClaimTenancyBond}
        component={ClaimTenancyBondScreen}
      />
      <Stack.Screen
        options={{ title: "Claim bond" }}
        name={Screens.ClaimTenancyBondSuccess}
        component={ClaimSuccessScreen}
      />
      <Stack.Screen
        options={{ title: "Edit bond claim" }}
        name={Screens.EditTenancyBondClaim}
        component={EditTenancyBondClaimScreen}
      />
      <Stack.Screen
        options={{ title: "Rent Adjustments" }}
        name={Screens.RentAdjustments}
        component={RentAdjustmentListScreen}
      />
      <Stack.Screen
        options={{ title: "Create Rent Adjustment" }}
        name={Screens.CreateRentAdjustment}
        component={CreateRentAdjustmentScreen}
      />
    </Stack.Navigator>
  );
};

export { PropertiesTabScreen };
