import { BondIcon } from "@ailo/primitives";
import { DropdownMenuOption } from "@ailo/ui";
import React from "react";

export function getCreateLeaseRenewalOption({
  onSelect,
  disabled
}: {
  onSelect: () => void;
  disabled: boolean;
}): DropdownMenuOption {
  return {
    label: "Lease renewal",
    icon: <BondIcon />,
    onSelect,
    disabled
  };
}
