import React, { useCallback } from "react";
import { UnscheduledTenanciesFilterType } from "../types";
import { UpdateUnscheduledTenanciesFilterStateCallback } from "../useUnscheduledTenanciesListFilterState";
import { PropertyAddressFilter } from "./PropertyAddressFilter";

export function usePropertyAddressFilter({
  searchQuery,
  updateFilterState
}: {
  searchQuery?: string;
  updateFilterState: UpdateUnscheduledTenanciesFilterStateCallback;
}): UnscheduledTenanciesFilterType {
  const onChangeFilter = useCallback(
    (value?: string) => {
      updateFilterState({ propertyAddress: value });
    },
    [updateFilterState]
  );

  return {
    isFilterActive: searchQuery != null,
    component: (
      <PropertyAddressFilter
        searchQuery={searchQuery}
        onChangeFilter={onChangeFilter}
        minInputLengthForInteraction={0}
      />
    )
  };
}
