import { AiloRN, services } from "@ailo/ailorn";
import { ScreenComponent } from "@ailo/services";
import { Screens, useCurrentAgencyOrg, useRoute } from "local/common";
import React, { FC } from "react";
import { WalletTabScreenContent } from "./WalletTabScreenContent";

export const WalletTabScreen: FC = () => {
  const route = useRoute<Screens.WalletTab>();

  const currentAgencyOrg = useCurrentAgencyOrg();
  const transferFundsSidebarOpen = !!route.params?.transfer;
  const legalEntityId =
    route.params?.legalEntityId ||
    currentAgencyOrg.defaultWalletLegalEntity?.id;
  const legalEntityAiloRN = legalEntityId
    ? AiloRN.of(services.AuthZ.legalEntity, legalEntityId).toString()
    : undefined;

  return (
    <ScreenComponent
      name={"Wallet"}
      analyticsTrackPayload={{
        legalEntityId: legalEntityAiloRN,
        transferPanelOpen: transferFundsSidebarOpen
      }}
    >
      <WalletTabScreenContent />
    </ScreenComponent>
  );
};
