import { isReactText, mapChildren, Text } from "@ailo/primitives";
import React, { Fragment, ReactNode, ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

interface Props {
  children: ReactNode;
}

export function ManagementPortfolioListItemCenterContent({
  children
}: Props): ReactElement {
  return (
    <ManagementPortfolioListItemCenterContentsContainer>
      {mapChildren(children, (child, key, index) => (
        <Fragment key={key}>
          {index !== 0 && <Spacer />}
          {isReactText(child) ? (
            <Text.BodyM weight={"medium"}>{child}</Text.BodyM>
          ) : (
            child
          )}
        </Fragment>
      ))}
    </ManagementPortfolioListItemCenterContentsContainer>
  );
}

const ManagementPortfolioListItemCenterContentsContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
`;

const Spacer = styled(View)`
  width: 8px;
`;
