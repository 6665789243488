import styled from "styled-components/native";
import { View } from "react-native";
import { AiloRN } from "@ailo/ailorn";
import React, { ReactElement, useState } from "react";
import { useGetCrnQuery, useGetPersonNameQuery } from "local/graphql";
import { PersonAvatar, useToastContext } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import { CentrepaySetupModal } from "../CentrepaySetupModal/CentrepaySetupModal";
import { Address } from "@ailo/domains";
import { formatLegalEntityName } from "@ailo/domain-helpers";
import { DeleteButton } from "./DeleteButton";
import { EditButton } from "./EditButton";
import { useDeleteCentrepay } from "./useDeleteCentrepay";
import { DeleteCentrepayModal } from "./DeleteCentrepayModal";

export function CentrepayRenter({
  managingEntity,
  tenancy,
  renter,
  onDelete,
  property,
  onEdit
}: {
  managingEntity: AiloRN;
  tenancy: AiloRN;
  renter: AiloRN;
  onDelete: () => Promise<void>;
  property?: {
    address?: Address;
  };
  onEdit: () => Promise<unknown>;
}): ReactElement | null {
  const deleteCentrepay = useDeleteCentrepay(
    managingEntity,
    tenancy,
    renter,
    onDelete
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const toast = useToastContext();

  const { data } = useGetPersonNameQuery({
    variables: {
      id: renter.toString()
    }
  });
  const {
    data: crnData,
    loading,
    refetch
  } = useGetCrnQuery({
    variables: {
      input: {
        legalEntity: renter.toString(),
        managingEntity: managingEntity.toString()
      }
    }
  });
  if (!data || loading || data.legalEntity?.__typename !== "Person") {
    return null;
  }
  const fullName = formatLegalEntityName(data.legalEntity);

  return (
    <CentrepayRenterContainer>
      <PersonAvatar
        size={40}
        person={data.legalEntity}
        initialsColor={Colors.TEXT.LIGHT.PRIMARY}
        style={{ marginRight: 16 }}
      />
      <Text.BodyL weight={"medium"} style={{ marginRight: 8 }}>
        {fullName}
      </Text.BodyL>
      <StyledTitleButtons style={{ marginLeft: "auto" }}>
        <EditButton setShowEditModal={setShowEditModal} />
        <DeleteButton setShowDeleteModal={setShowDeleteModal} />
      </StyledTitleButtons>
      <DeleteCentrepayModal
        visible={showDeleteModal}
        renterName={fullName}
        onPress={async () => {
          setShowDeleteModal(false);
          await deleteCentrepay();
        }}
        onDismiss={() => {
          setShowDeleteModal(false);
        }}
      />
      <CentrepaySetupModal
        managingEntity={managingEntity}
        tenants={[
          { legalEntity: renter, name: fullName, crn: crnData?.getCrn?.crn }
        ]}
        tenancy={tenancy}
        property={property}
        visible={showEditModal}
        onSuccess={async () => {
          setShowEditModal(false);
          toast.show({ type: "success", message: "CRN has been edited" });
          await refetch();
          await onEdit();
        }}
        onFailure={async () => {
          setShowEditModal(false);
          toast.show({ type: "error", message: "CRN editing failed" });
          await refetch();
        }}
        onDismiss={() => {
          setShowEditModal(false);
        }}
        title={"Edit tenancy's Centrepay"}
        buttonLabel={"Update Centrepay"}
        createCentrepayDirective={false}
      />
    </CentrepayRenterContainer>
  );
}

const CentrepayRenterContainer = styled(View)`
  border: 1px solid rgba(28, 30, 38, 0.1);
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(28, 30, 38, 0.2);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;

const StyledTitleButtons = styled(View)`
  flex-direction: row;
  justify-content: flex-end;
  height: 32px;
`;
