import React, { useMemo } from "react";
import { View } from "react-native";
import { FilterSelectorButton as TeamSelectorButtonComponent } from "./FilterSelectorButton";
import { FilterSelector } from "./FilterSelector";
import { AgencyChatList } from "./AgencyChatList";
import {
  ChatTeam,
  ChatTeamId,
  getTeamName,
  NewMessageButton,
  NewMessageChatListItem,
  useChatNavigationContext
} from "@ailo/domains";
import styled from "styled-components/native";
import { Screens, useIsOnScreen } from "local/common";

interface Props {
  selectedTeamId: ChatTeamId;
  onSelectTeam: (teamId: ChatTeamId) => void;
  selectorOpen?: boolean;
  onPressSelectorButton?: () => void;
  teams: ChatTeam[];
}

export function ChatListWithFilterSelector({
  selectedTeamId,
  teams,
  onSelectTeam,
  selectorOpen = false,
  onPressSelectorButton
}: Props): React.ReactElement {
  const { useNavigateToNewChatMessage } = useChatNavigationContext();
  const navigateToNewChatMessage = useNavigateToNewChatMessage();
  const isOnNewChatMessageScreen = useIsOnScreen(Screens.NewChatMessage);

  const teamName = useMemo(() => {
    return getTeamName(selectedTeamId, teams);
  }, [selectedTeamId, teams]);

  const chatListHeader = useMemo(() => {
    return isOnNewChatMessageScreen ? <NewMessageChatListItem /> : undefined;
  }, [isOnNewChatMessageScreen]);

  return (
    <Container>
      <TeamSelectorButton
        onFilterSelectorPress={onPressSelectorButton}
        onNewMessagePress={navigateToNewChatMessage}
        label={teamName}
      />
      <AgencyChatList visible={!selectorOpen} header={chatListHeader} />
      {selectorOpen ? (
        <FilterSelector
          onSelect={onSelectTeam}
          selectedTeamId={selectedTeamId}
          teams={teams}
        />
      ) : null}
    </Container>
  );
}

const TeamSelectorButton = React.memo(function TeamSelectorButton({
  onFilterSelectorPress,
  onNewMessagePress,
  label = "Unassigned"
}: {
  onFilterSelectorPress?: () => void;
  onNewMessagePress?: () => void;
  label?: string;
}): React.ReactElement {
  return (
    <TeamSelectorButtonComponent
      label={label}
      onPress={onFilterSelectorPress}
      rightComponent={
        onNewMessagePress ? (
          <NewMessageButton onPress={onNewMessagePress} />
        ) : undefined
      }
    />
  );
});

function Loading(): React.ReactElement {
  return (
    <Container>
      <AgencyChatList.Loading />
    </Container>
  );
}

const Container = styled(View)`
  width: 360px;
`;

ChatListWithFilterSelector.Loading = Loading;
