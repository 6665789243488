import {
  Screens,
  SplitScreenContainer,
  useNavigation,
  useRoute
} from "local/common";
import React, { ReactElement } from "react";
import { RentAdjustments } from "./RentAdjustments";
import { PropertyDetails } from "local/modals/components/PropertyDetails";
import { ContentWithSidebar } from "@ailo/ui";
import { RentAdjustmentSidebar } from "./RentAdjustmentSidebar/RentAdjustmentSidebar";

export function RentAdjustmentListScreen(): ReactElement {
  const navigation = useNavigation();
  const { tenancyId, managementId, adjustmentId } =
    useRoute<Screens.RentAdjustments>().params!;

  return (
    <ContentWithSidebar
      open={!!adjustmentId}
      sidebarContent={
        adjustmentId ? (
          <RentAdjustmentSidebar
            onDismiss={() => navigation.setParams({ adjustmentId: undefined })}
            adjustmentId={adjustmentId}
            tenancyId={tenancyId}
          />
        ) : null
      }
      onDismiss={() => navigation.setParams({ adjustmentId: undefined })}
    >
      <SplitScreenContainer
        left={
          <RentAdjustments
            tenancyId={tenancyId}
            onClose={(): void =>
              navigation.navigate(Screens.AppNavigator, {
                screen: Screens.PropertiesTab,
                params: { screen: Screens.Property, params: { managementId } }
              })
            }
            style={{ flex: 1, maxWidth: 636, alignSelf: "flex-end" }}
          />
        }
        leftSectionSize={"medium"}
        right={<PropertyDetails tenancyId={tenancyId} />}
      />
    </ContentWithSidebar>
  );
}
