import { isPresent } from "ts-is-present";
import React, { ReactElement, useMemo } from "react";
import { ManagementPortfolioOwnersChatButton } from "./ManagementPortfolioOwnersChatButton";
import { useGetManagementPortfolioOwners } from "./useGetManagementPortfolioOwners";
import { ManagementPortfolioOwnersList } from "./ManagementPortfolioOwnersList";
import {
  ManagementPortfolioContentCard,
  ManagementPortfolioContentProps
} from "../ManagementPortfolioScreen";

export function ManagementPortfolioOwnersCard({
  portfolioAilorn
}: ManagementPortfolioContentProps): ReactElement {
  const { data, loading, error, refetch } =
    useGetManagementPortfolioOwners(portfolioAilorn);
  const portfolio = data?.managementFolio;

  const owners = useMemo(
    () =>
      (portfolio?.owners || [])
        .map((ownership) => {
          const owner = ownership?.owner;
          if (!owner) return null;
          return {
            ...owner,
            isPrimary: ownership.isPrimary
          };
        })
        .filter(isPresent)
        .map((owner) => {
          return owner.__typename === "Person"
            ? {
                ...owner,
                isVerified: !!owner.user?.onboardingCompletedAt
              }
            : owner;
        }),
    [portfolio?.owners]
  );

  if (loading) {
    return (
      <ManagementPortfolioContentCard.Loading
        numberOfItems={2}
        leftIconShape={"circle"}
      />
    );
  }
  if (error || !data) {
    return (
      <ManagementPortfolioContentCard.Error
        entityDisplayType={"Owners"}
        onRetry={() => {
          refetch();
        }}
      />
    );
  }

  return (
    <ManagementPortfolioContentCard
      title={"Owners"}
      titleRight={<ManagementPortfolioOwnersChatButton owners={owners} />}
    >
      <ManagementPortfolioOwnersList owners={owners} />
    </ManagementPortfolioContentCard>
  );
}
