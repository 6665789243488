import React, { useEffect, useState, useMemo, useRef } from "react";
import { AiloRN, ID } from "@ailo/ailorn";
import {
  ChatSubscriptionContextProvider,
  useSetCurrentUserEmailInErrorContext
} from "@ailo/domains";
import {
  didQueryFail,
  didQueryNotLoadYet,
  staticEnvironment,
  useAuth,
  useLogApolloResultFailed
} from "@ailo/services";
import { AlertScreen, LoadingSplashPage } from "@ailo/ui";
import { setGlobal, useGlobal } from "reactn";
import { State as GlobalState } from "reactn/default";
import { useFonts } from "./useFonts";
import { getAvailableFeatures } from "./getAvailableFeatures";
import { getDefaultAgencyOrg } from "./getDefaultAgencyOrg";
import { NetworkStatus } from "@apollo/client";
import { useGetSetupData } from "./useGetSetupData";
import { CurrentAgencyProvider, AgencyOrganisation } from "local/common";

const DEFAULT_GLOBAL = {
  availableFeatures: [],
  usePasswordLogin: true
};

setGlobal<GlobalState>(DEFAULT_GLOBAL);

export function AppDataSetup({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement | null {
  const [, setGlobal] = useGlobal<GlobalState>();
  const { isAuthenticated, login } = useAuth();
  const [isSetup, setIsSetup] = useState(false);
  const currentAgencyRef = useRef<AgencyOrganisation>();

  const result = useGetSetupData({
    skip: !isAuthenticated,
    notifyOnNetworkStatusChange: true
  });

  const logResultError = useLogApolloResultFailed({
    operationName: "getAvailableFeatures"
  });

  const fontsLoaded = useFonts();

  // TODO This should be moved to AuthProvider
  useEffect(() => {
    if (!isAuthenticated) {
      setIsSetup(false);
      login();
    }
  }, [isAuthenticated, login, setGlobal]);

  const currentAgencyOrg = useMemo(
    () =>
      result.data
        ? getDefaultAgencyOrg(result.data.effectiveUser.organisations)
        : undefined,
    [result.data]
  );

  if (currentAgencyOrg) {
    currentAgencyRef.current = currentAgencyOrg;
  }

  if (!staticEnvironment.isProd) {
    // Ignoring the rule because this hook can be conditional,
    // as `ENV` won't be changed during the runtime
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSetCurrentUserEmailInErrorContext({ isAuthenticated });
  }

  useEffect(() => {
    if (!result.data) {
      return;
    }

    const currentUser = result.data.effectiveUser;
    setGlobal({
      availableFeatures: getAvailableFeatures(
        currentUser,
        currentAgencyOrg?.ailoRN
      ),
      currentUser: {
        id: currentUser.id,
        ailoRN: AiloRN.of("authz:user", currentUser.id).toString(),
        person: {
          ...currentUser.person,
          lastName: currentUser.person.lastName || undefined,
          emailAddress: currentUser.person.emailAddress || undefined
        },
        contacts: currentUser.organisations.reduce((acc, val) => {
          const contact =
            (val.effectiveUserContact &&
              AiloRN.from(val.effectiveUserContact.ailorn, {
                expected: "contact:contact"
              })) ||
            undefined;

          acc.set(val.id as ID<"authz:organisation">, contact);
          return acc;
        }, new Map<ID<"authz:organisation">, AiloRN<"contact:contact"> | undefined>())
      }
    });

    setIsSetup(true);
  }, [result.data, setGlobal, currentAgencyOrg]);

  const loading =
    didQueryNotLoadYet(result) ||
    // TODO possibly for the refetch, designers would prefer to show a loading indicator on the alertscreen
    (!result.data && result.networkStatus === NetworkStatus.refetch) ||
    !isAuthenticated ||
    !fontsLoaded;

  const resultFailed = !loading && didQueryFail(result);

  if (!isAuthenticated || !isSetup) {
    return <LoadingSplashPage />;
  }

  if (resultFailed) {
    logResultError(result);
    return (
      <AlertScreen
        variant={"large"}
        title={"There's a problem\nloading this page"}
        description={
          "We're sorry, there was a technical problem loading this page. Try reloading this page, or contact Ailo support if the problem persists."
        }
        inlineButton={{
          label: "Retry",
          onPress: (): void => {
            // TODO if 401 error, logout instead
            result.refetch();
          }
        }}
      />
    );
  }

  if (currentAgencyRef.current) {
    return (
      <CurrentAgencyProvider value={currentAgencyRef.current}>
        <ChatSubscriptionContextProvider>
          {children}
        </ChatSubscriptionContextProvider>
      </CurrentAgencyProvider>
    );
  }

  return (
    <ChatSubscriptionContextProvider>
      {children}
    </ChatSubscriptionContextProvider>
  );
}
