import { Bill } from "local/domain/bond";
import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ClaimBondFormData } from "../../../ClaimBondFormData";
import { CheckboxInput } from "@ailo/ui";

export function FormControlledCheckbox({
  bill,
  index
}: {
  bill: Bill;
  index: number;
}): ReactElement {
  const { control, register, unregister, setValue } =
    useFormContext<ClaimBondFormData>();
  useEffect(() => {
    const ailoRNFieldName = `bills.${index}.reference`;
    register(ailoRNFieldName);
    setValue(ailoRNFieldName, bill.ailoRN);

    return () => {
      unregister(ailoRNFieldName);
    };
  }, [index, bill, register, unregister, setValue]);

  const name = `bills.${index}.claimed`;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={bill.isSelected}
      render={({ onChange, onBlur, value }): React.ReactElement => (
        <CheckboxInput
          style={{ marginRight: 8 }}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
    />
  );
}
