import { Column, DefaultProps, Row } from "@ailo/primitives";
import { Button, ButtonProps } from "@ailo/ui";
import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components/native";
import { Promisable } from "type-fest";

type ModalFooterProps = DefaultProps<{
  onCancelPress?: () => Promisable<void>;
  onActionPress?: () => Promisable<void>;
  cancel?: ReactNode;
  action?: ReactNode;
  actionButtonVariant?: ButtonProps["variant"];
}>;

export function ModalFooter({
  children,
  style,
  onCancelPress,
  onActionPress,
  cancel,
  action,
  actionButtonVariant = "primary"
}: ModalFooterProps): ReactElement {
  return (
    <FooterContainer style={style}>
      {children}
      <FooterRow>
        <Button.Secondary onPress={onCancelPress}>
          {cancel ?? "Cancel"}
        </Button.Secondary>
        {action && (
          <Button
            variant={actionButtonVariant}
            onPress={onActionPress}
            disabled={!onActionPress}
          >
            {action}
          </Button>
        )}
      </FooterRow>
    </FooterContainer>
  );
}

const FooterContainer = styled(Column)`
  flex: 1;
`;

const FooterRow = styled(Row)`
  justify-content: space-between;
`;
