import React from "react";
import { DateTimeWithTimeZone, TextCell } from "@ailo/ui";
import { useGetAgreementOfMostRecentTenancyQuery } from "local/graphql";
import { ErrorCell } from "./ErrorCell";
import { BasePropertyListRow } from "../BasePropertyListRow";

function AgreementStartDateCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetAgreementOfMostRecentTenancyQuery({
    variables: { managementId }
  });

  if (loading && !data) {
    return <Loading />;
  }

  const tenancyAgreement =
    data?.management?.mostRecentTenancy?.latestTenancyAgreement;
  if (!tenancyAgreement)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const startDate = tenancyAgreement.startDate;

  if (!startDate) {
    return <></>;
  }

  const ailoStartDate = DateTimeWithTimeZone.fromLocalDate(startDate);

  return <TextCell>{ailoStartDate.format("DD MMM YYYY")}</TextCell>;
}

function Loading(): React.ReactElement {
  return <TextCell.Loading />;
}

AgreementStartDateCell.Loading = Loading;

export { AgreementStartDateCell };
