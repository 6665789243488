import moment from "moment";
import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import { MAX_DATE, MIN_DATE, DateRange } from "@ailo/primitives";
import { AgreementDateType, getTenancyAgreementDateInfo } from "@ailo/domains";
import {
  GetDataForEditEndTenancySidebarQueryVariables,
  useGetDataForEditEndTenancySidebarQuery,
  TenancyDepositStatus,
  VacatingReason,
  useGetNextTenancyStartDateQuery
} from "local/graphql";

interface DataForEditEndTenancySidebar {
  liabilityId: string;
  effectivePaidToDate?: moment.Moment;
  tenancyAgreementDateInfo?: string;
  nextTenancyDateInfo?: string;
  tenancyEndDate?: moment.Moment;
  vacatingNotes?: string | null;
  vacatingReason?: VacatingReason | null;
  validEndDateRange: DateRange;
  depositStatus?: TenancyDepositStatus;
}

export function useGetDataForEditEndTenancySidebar(
  tenancyId: string,
  managementId: string
): MappedQueryResult<
  DataForEditEndTenancySidebar,
  GetDataForEditEndTenancySidebarQueryVariables
> {
  const result = useGetDataForEditEndTenancySidebarQuery({
    variables: { id: tenancyId }
  });

  const { nextTenancyStartDate } = useGetNextTenancyStartDate(managementId);

  return useMappedQueryResult(result, (data) => {
    const tenancy = data?.tenancy;

    if (!tenancy) {
      throw new ApolloError({
        errorMessage: `Tenancy ID="${tenancyId}" not found`
      });
    }

    const {
      liability,
      endDate,
      mostRecentTenancyAgreement,
      vacatingNotes,
      vacatingReason,
      validVacateEndDateRange,
      deposit
    } = tenancy;

    if (!liability) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} does not have liability`
      });
    }

    if (!validVacateEndDateRange) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} does not have valid end date range`
      });
    }

    return {
      liabilityId: liability.id,
      effectivePaidToDate: getMomentOrUndefined(liability.effectivePaidToDate),
      tenancyAgreementDateInfo: getTenancyAgreementDateInfoText(
        mostRecentTenancyAgreement
      ),
      nextTenancyDateInfo: getNextTenancyDateInfoText(nextTenancyStartDate),
      tenancyEndDate: getMomentOrUndefined(endDate),
      vacatingNotes,
      vacatingReason,
      validEndDateRange: {
        startDate:
          getMomentOrUndefined(validVacateEndDateRange?.startDate) ?? MIN_DATE,
        endDate:
          getMomentOrUndefined(nextTenancyStartDate)?.subtract(1, "day") ??
          MAX_DATE
      },
      depositStatus: deposit?.status
    };
  });
}

function getMomentOrUndefined(
  date?: Date | string | null
): moment.Moment | undefined {
  return date ? moment(date) : undefined;
}

function getTenancyAgreementDateInfoText(
  mostRecentTenancyAgreement?: {
    startDate?: string | null;
    fixedTermEndDate?: string | null;
    allowedToLapseAt?: string | null;
  } | null
): string | undefined {
  if (!mostRecentTenancyAgreement?.startDate) return;

  const { dateType, date } = getTenancyAgreementDateInfo({
    agreementStartDate: mostRecentTenancyAgreement.startDate,
    fixedTermAgreementEndDate: mostRecentTenancyAgreement.fixedTermEndDate,
    agreementAllowedToLapse: !!mostRecentTenancyAgreement.allowedToLapseAt
  });

  return (
    (dateType === AgreementDateType.PeriodicAgreementSince
      ? "Periodic agreement since "
      : dateType === AgreementDateType.PeriodicSince
      ? "Periodic since "
      : dateType === AgreementDateType.AgreementExpired
      ? "Tenancy agreement expired "
      : "Tenancy agreement expires ") + date
  );
}

function useGetNextTenancyStartDate(managementId: string): {
  nextTenancyStartDate?: string | null;
} {
  const { data } = useGetNextTenancyStartDateQuery({
    variables: { managementId }
  });

  const management = data?.management;

  if (!management)
    throw new Error(
      `Management "${managementId}" not found when requesting tenancies`
    );

  return { nextTenancyStartDate: management.nextTenancy?.startDate };
}
function getNextTenancyDateInfoText(
  nextTenancyStartDate: string | null | undefined
): any {
  const date = getMomentOrUndefined(nextTenancyStartDate);

  if (!date) return undefined;

  return `A new tenancy starts ${date.format("DD MMM YYYY")}`;
}
