import React, { useCallback } from "react";
import { useGetTenancyDepositData } from "local/domain/propertyManagement";
import { LocalDate } from "@ailo/date";
import { ConfirmModal, useToastContext } from "@ailo/ui";
import { Text } from "@ailo/primitives";
import { RecoverableError, useAnalytics } from "@ailo/services";
import { AiloRN, nsEntities } from "@ailo/ailorn";
import { useVoidTenancy } from "./useVoidTenancy";

export function VoidIngoingTenancyModal({
  tenancyId,
  tenancyStartDate,
  onDismiss,
  onConfirm,
  isVisible,
  setIsVisible
}: {
  tenancyId: string;
  tenancyStartDate?: LocalDate;
  onDismiss?: () => void;
  onConfirm?: () => void;
  isVisible: boolean;
  setIsVisible: (v: boolean) => void;
}): React.ReactElement | null {
  const {
    data: depositData,
    loading,
    error,
    refetch
  } = useGetTenancyDepositData(tenancyId);
  const toast = useToastContext();
  const analytics = useAnalytics();
  const [voidTenancy] = useVoidTenancy({
    tenancyId,
    onCompleted: () => {
      toast.show({
        type: "success",
        message: "Ingoing tenancy voided"
      });
      analytics.track("Ingoing Tenancy Voided", {
        tenancy_id: AiloRN.of(
          nsEntities.propertymanagement.tenancy,
          tenancyId
        ).toString(),
        tenancy_start_date: tenancyStartDate?.format("DD/MM/YYYY")
      });
      setIsVisible(false);
    },
    onError: () => {
      toast.show({
        type: "error",
        message: "Unable to void ingoing tenancy. Please try again."
      });
    }
  });

  const onConfirmPress = useCallback(() => {
    voidTenancy();
    onConfirm?.();
  }, [voidTenancy, onConfirm]);

  const onCancel = useCallback(() => {
    setIsVisible(false);
    onDismiss?.();
  }, [setIsVisible, onDismiss]);

  if (loading || !tenancyStartDate) {
    return null;
  }

  if (error) {
    throw new RecoverableError("Failed to get tenancy deposit data", refetch);
  }

  const formattedTenancyEndDate = tenancyStartDate.format("D MMMM YYYY");
  return (
    <ConfirmModal
      onCancel={onCancel}
      onConfirm={onConfirmPress}
      title={"Void ingoing tenancy"}
      confirmLabel={"Void ingoing tenancy"}
      destructive
      visible={isVisible}
    >
      <Text.BodyM>
        {"This action will void the tenancy due to begin on "}
        <Text weight={"medium"}>{formattedTenancyEndDate}</Text>
        {"."}
        {depositData &&
          " Renters will be blocked from making an initial payment into Ailo."}
      </Text.BodyM>
    </ConfirmModal>
  );
}
