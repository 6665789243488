import React from "react";
import { Colors, opacify } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";
import { ProfileMenu } from "./ProfileMenu";
import { GlobalSearchBox } from "./GlobalSearchBox";

const ScreenHeader: React.FC = () => {
  return (
    <Container style={{ justifyContent: "space-between" }}>
      <View>
        <GlobalSearchBox />
      </View>
      <ProfileMenu />
    </Container>
  );
};

const Container = styled(View)`
  background-color: ${Colors.WHITE};
  height: 56px;
  flex-direction: row;
  flex: 1;
  padding-left: 24px;
  padding-right: 20px;
  border-bottom-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  align-items: center;
`;

export { ScreenHeader };
