import { ProjectItemDetailsFragment } from "local/graphql";
import React, { ComponentProps } from "react";
import { View } from "react-native";
import { ProjectListItem } from ".";

type Props = {
  projectItems: ProjectItemDetailsFragment[];
  itemsLimit?: number;
  onProjectPress?: ComponentProps<typeof ProjectListItem>["onPress"];
};
export function ProjectList({
  projectItems,
  itemsLimit,
  onProjectPress
}: Props): React.ReactElement {
  const truncatedProjectItems =
    itemsLimit != null ? projectItems.slice(0, itemsLimit) : projectItems;

  return (
    <View>
      {truncatedProjectItems.map((project, index) => {
        const isLastItem = index === truncatedProjectItems.length - 1;
        const isViewAllButtonNotVisible =
          itemsLimit && projectItems.length <= itemsLimit;

        return (
          <ProjectListItem
            key={project.id}
            project={project}
            style={
              isViewAllButtonNotVisible && isLastItem
                ? { borderType: "none" }
                : undefined
            }
            link={{ to: `/projects/${project.id}` }}
            onPress={onProjectPress}
          />
        );
      })}
    </View>
  );
}

ProjectList.Loading = function ProjectListLoading({
  itemsLimit
}: {
  itemsLimit: number;
}): React.ReactElement {
  return (
    <>
      {[...Array(itemsLimit)].map((item, index) => (
        <ProjectListItem.Loading key={index} />
      ))}
    </>
  );
};
