import { Colors, opacify, Skeleton, Text } from "@ailo/primitives";
import { DescriptionList } from "@ailo/ui";
import React, { ReactElement, ReactNode } from "react";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";

export function ManagementOrTenancySidebarLoading({
  children
}: {
  children?: ReactNode;
}): ReactElement {
  return (
    <ScrollView style={{ padding: 32 }}>
      {children}
      <Text.BodyL.Loading
        width={50}
        style={{ marginBottom: 16, marginTop: 40 }}
      />
      <DescriptionList.Loading
        size={"small"}
        itemsWidths={[
          [108, 80],
          [125, 80],
          [134, 80],
          [75, 120]
        ]}
      />
      <Separator style={{ marginTop: 40, marginBottom: 36 }} />
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Skeleton style={{ width: 33, height: 12 }} />
        <Skeleton style={{ width: 80, height: 12 }} />
      </View>
    </ScrollView>
  );
}

const Separator = styled(View)`
  margin-right: -32px;
  height: 1px;
  background-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;
