import React, { ReactElement } from "react";
import { capitaliseFirstLetter } from "local/domain/utils";
import { formatAddress } from "@ailo/domain-helpers";
import { sumBy } from "lodash";
import { Money, TextCell } from "@ailo/ui";
import { TransactionRow } from "./useGetTransactions";

export function AuditNumberCell({
  row: { auditNumber }
}: {
  row: TransactionRow;
  rowIndex: number;
}): ReactElement {
  return <TextCell>{auditNumber}</TextCell>;
}

export function ReferenceNumberCell({
  row: { referenceNumber, type }
}: {
  row: TransactionRow;
  rowIndex: number;
}): ReactElement {
  return (
    <TextCell>
      {["RECEIPT", "REVERSAL", "DISBURSEMENT"].includes(type)
        ? referenceNumber
        : ""}
    </TextCell>
  );
}

export function TransactionTypeCell({
  row: { type }
}: {
  row: TransactionRow;
  rowIndex: number;
}): ReactElement {
  return <TextCell>{capitaliseFirstLetter(type.toLowerCase())}</TextCell>;
}

export function PropertyCell({
  row: { propertyAddress }
}: {
  row: TransactionRow;
  rowIndex: number;
}): ReactElement {
  return (
    <TextCell>
      {propertyAddress
        ? formatAddress(propertyAddress, {
            format: "street, suburb"
          })
        : null}
    </TextCell>
  );
}

export function DetailsCell({
  row: { description }
}: {
  row: TransactionRow;
  rowIndex: number;
}): ReactElement {
  return <TextCell>{description}</TextCell>;
}

export function AmountCell({
  row
}: {
  row: TransactionRow;
  rowIndex: number;
}): ReactElement {
  const { allocations } = row;
  const total = sumBy(allocations, (alloc) => {
    return alloc.amount?.cents ?? 0;
  });
  return <TextCell>{Money.fromCents(total).format()}</TextCell>;
}

export function TransactionDateCell({
  row: { createdAt }
}: {
  row: TransactionRow;
  rowIndex: number;
}): ReactElement {
  return <TextCell>{createdAt?.format("D MMM YYYY")}</TextCell>;
}

export const columns = [
  {
    key: "auditNumber",
    name: "Audit",
    renderCell: AuditNumberCell,
    sortable: true,
    renderLoadingCell: TextCell.Loading
  },
  {
    key: "createdAt",
    name: "Date",
    renderCell: TransactionDateCell,
    sortable: false,
    renderLoadingCell: TextCell.Loading
  },
  {
    key: "referenceNumber",
    name: "Ref",
    renderCell: ReferenceNumberCell,
    sortable: false,
    renderLoadingCell: TextCell.Loading
  },
  {
    key: "type",
    name: "Type",
    renderCell: TransactionTypeCell,
    sortable: false,
    renderLoadingCell: TextCell.Loading
  },
  {
    key: "managementId",
    name: "Property",
    width: 3,
    renderCell: PropertyCell,
    sortable: false,
    renderLoadingCell: TextCell.Loading
  },
  {
    key: "description",
    name: "Details",
    width: 4,
    renderCell: DetailsCell,
    sortable: false,
    renderLoadingCell: TextCell.Loading
  },
  {
    key: "amount",
    name: "Amount",
    renderCell: AmountCell,
    sortable: false,
    renderLoadingCell: TextCell.Loading
  }
];

export const loadingColumns = columns.map((column) => {
  return { ...column, renderCell: column.renderLoadingCell };
});
