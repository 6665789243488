import { Screens, useNavigation } from "local/common";
import { NavigateToNewChatMessage } from "@ailo/domains";
import { useCallbackRef } from "@ailo/primitives";

/**
 * To be used only when inside Screens.CommsTab
 */
export function useNavigateToNewChatMessage(): NavigateToNewChatMessage {
  const navigation = useNavigation<Screens.CommsTab>();

  return useCallbackRef(() => {
    navigation.push(Screens.NewChatMessage, {
      chatId: undefined,
      initialContactIds: undefined
    });
  }, [navigation]);
}
