import { FormField, FormFieldErrorObject, SelectInputLoading } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import { LegalEntityInput } from "local/domain/authz";
import { PersonNamesFragment } from "local/graphql";
import React, { ReactNode, useEffect } from "react";
import { sortPeopleAlphabeticallyByName } from "local/domain/utils/sortPeopleAlphabeticallyByName";
import { useCurrentOrgMembers } from "./useCurrentOrgMembers";

export function AssigneeFormField({
  onChange,
  value,
  onBlur,
  label,
  error
}: {
  onChange: (value: PersonNamesFragment | undefined) => void;
  value: PersonNamesFragment;
  onBlur: () => void;
  label?: string;
  error?: string | ReactNode | FormFieldErrorObject;
}): React.ReactElement {
  const { id: organisationId } = useCurrentAgencyOrg();

  const {
    data,
    loading,
    error: graphqlError
  } = useCurrentOrgMembers(organisationId);

  useEffect(() => {
    if (!value && data?.currentUserMember) {
      onChange(data?.currentUserMember);
    }
  }, [data?.allMembers, data?.currentUserMember, onChange, value]);

  if (loading) {
    return <SelectInputLoading />;
  }

  if (graphqlError || !data?.allMembers?.length) {
    throw new Error("Could not load assignees");
  }

  const { allMembers } = data;

  return (
    <FormField label={label} error={error}>
      <LegalEntityInput
        {...{ onBlur }}
        value={value}
        onBlur={onBlur}
        placeholder={`Select Assignee`}
        options={sortPeopleAlphabeticallyByName(allMembers)}
        controlShouldRenderValue={!!value}
        onChange={onChange}
        testID={"ProjectAssigneeInputFormField"}
        hasError={!!error}
      />
    </FormField>
  );
}
