import { Colors, Text } from "@ailo/primitives";
import { withErrorBoundary } from "@ailo/services";
import { Button, ErrorAlertScreen } from "@ailo/ui";
import { useLinkBuilder, useLinkProps } from "@react-navigation/native";
import { Screens } from "local/common";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { TabContent } from "../../common";
import { FeeBlueprintList } from "./FeeBlueprintList";

export const FeeListTab = withErrorBoundary(
  function FeeListTab(): ReactElement {
    const createFeeBlueprintLinkProps = useLinkProps({
      to: useLinkBuilder()(Screens.SettingsTab, {
        tab: "fees",
        id: "new"
      })!
    });

    return (
      <TabContent title={"Agency fee templates"}>
        <Text.BodyM
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ marginTop: 12 }}
        >
          {
            "Set up templates for different types of fees charged by your agency"
          }
        </Text.BodyM>
        <View style={{ alignItems: "flex-start", marginBottom: 32 }}>
          <Button style={{ marginTop: 24 }} {...createFeeBlueprintLinkProps}>
            {"Create fee template"}
          </Button>
        </View>
        <FeeBlueprintList />
      </TabContent>
    );
  },
  {
    fallbackComponent: function Fallback(props) {
      return (
        <TabContent title={"Fees"}>
          <ErrorAlertScreen
            title={"There was a problem loading Fee Templates"}
            onRetry={props.retry}
          />
        </TabContent>
      );
    }
  }
);
