import {
  AiloSentry,
  MutationResultDataMissingError,
  useAnalytics
} from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { useCallback } from "react";
import { EditTenancyFormData } from "../TenancyForms/EditTenancyFormWithData";
import { getRentFrequencyInputForMutation } from "../TenancyForms/getRentFrequenceInputForMutation";
import {
  UpdateIngoingTenancyMutation,
  useUpdateIngoingTenancyMutation,
  TenancyFragment,
  RentFrequency,
  RentCategory
} from "local/graphql";

type EditTenancyInput = EditTenancyFormData & {
  agreementStartDate: string;
  rentFrequency: RentFrequency;
  rentStartDate: string;
};

export function useUpdateIngoingTenancy({
  onCompleted,
  onError
}: {
  onCompleted?: (tenancy: TenancyFragment) => void;
  onError?: (error?: unknown) => void;
}): [
  ({
    formData,
    tenancyId
  }: {
    formData: EditTenancyInput;
    tenancyId: string;
  }) => Promise<void>,
  MutationResult<UpdateIngoingTenancyMutation>
] {
  const analytics = useAnalytics();
  const [updateIngoingTenancy, result] = useUpdateIngoingTenancyMutation();

  const editTenancy = useCallback(
    async ({
      formData: {
        agreementStartDate,
        agreementEndDate,
        rentAmount,
        rentFrequency,
        rentReviewDate: scheduledRentReviewDate,
        rentStartDate
      },
      tenancyId
    }: {
      formData: EditTenancyInput;
      tenancyId: string;
    }): Promise<void> => {
      try {
        const rentAmountInCents = rentAmount?.cents.toString() ?? "0";

        const result = await updateIngoingTenancy({
          variables: {
            tenancyDetails: {
              tenancyId,
              tenancyAgreement: {
                startDate: agreementStartDate,
                fixedTermEndDate: agreementEndDate
              },
              rents: [
                {
                  amountInCents: rentAmountInCents,
                  category: RentCategory.NewTenancy,
                  effectiveDate: rentStartDate,
                  period: getRentFrequencyInputForMutation(rentFrequency)
                }
              ],
              scheduledRentReviewDate
            }
          }
        });

        const tenancy = result.data?.updateIngoingTenancy;
        if (!tenancy) {
          throw new MutationResultDataMissingError(
            "updateIngoingTenancy",
            result
          );
        }
        await onCompleted?.(tenancy);
      } catch (error) {
        AiloSentry.captureException(error);
        return onError?.(error);
      }

      analytics.trackIngoingTenancyUpdated();
    },
    [updateIngoingTenancy, onCompleted, onError, analytics]
  );

  return [editTenancy, result];
}
