import { AiloRN } from "@ailo/ailorn";
import { Colors, opacify } from "@ailo/primitives";
import { useActionEventContext, useOnFocus } from "@ailo/services";
import { AlertScreen, ErrorAlertScreen } from "@ailo/ui";
import { ViewAllButton } from "local/common";
import React, { ReactElement, useEffect } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { useGetUpcomingAndPaidBillsAndFees } from "../graphql";
import { ExpensesListItem } from "./ExpensesListItem";

interface ExpensesListProps {
  payerAilorn: AiloRN;
  itemsLimit?: number;
  onExpensePress?: (ailorn: AiloRN) => void;
  onViewMoreExpensesPress?: () => void;
}

export function ExpensesList({
  payerAilorn,
  itemsLimit = 3,
  onExpensePress,
  onViewMoreExpensesPress
}: ExpensesListProps): ReactElement {
  const { upcomingExpenses, paidExpenses, loading, refetch, error } =
    useGetUpcomingAndPaidBillsAndFees({
      payerAilorn,
      pageSize: itemsLimit
    });

  useOnFocus(refetch);

  const actionEventContext = useActionEventContext();
  useEffect(() => {
    return actionEventContext.subscribe((event) => {
      if (event.type === "BillCreated") {
        // Bill will be visible only after Bill Service -> Ledger Service -> Bill Service
        // events are handled. We need to wait. And it needs to work with 100% guarantee
        // for e2e tests (if we ever write it). Thus, let's refetch it after 4s (should work in ~90% of cases),
        // and again after 12s (should be ok in the other cases).
        setTimeout(refetch, 4000);
        setTimeout(refetch, 12000);
      }
    });
  }, [actionEventContext, refetch]);

  if (loading) {
    return <ExpensesList.Loading itemsLimit={itemsLimit} />;
  }

  if (error) {
    return (
      <ErrorAlertScreen
        title={"There was a problem loading Expenses"}
        onRetry={refetch}
      />
    );
  }

  const expenses = [...upcomingExpenses, ...paidExpenses];

  if (!expenses.length) {
    return <ExpensesList.Empty payerAilorn={payerAilorn} />;
  }

  return (
    <Container>
      <View>
        {expenses.map((expense) => (
          <ExpensesListItem
            key={expense.ailorn}
            expense={expense}
            onExpensePress={() =>
              onExpensePress?.(AiloRN.fromString(expense.ailorn))
            }
          />
        ))}
      </View>
      {onViewMoreExpensesPress && (
        <ViewAllButton
          onPress={onViewMoreExpensesPress}
          style={{ margin: 16 }}
        />
      )}
    </Container>
  );
}

ExpensesList.Loading = function Loading({
  itemsLimit = 3,
  style
}: {
  itemsLimit?: number;
  style?: StyleProp<ViewStyle>;
}): ReactElement {
  const listItems = [];
  for (let i = 0; i < itemsLimit; i++) {
    const color = opacify(
      Colors.CHARCOAL,
      0.75 * ((itemsLimit - i) / itemsLimit)
    );
    listItems.push(
      <ExpensesListItem.Loading
        key={i}
        color={color}
        style={{ borderType: "none" }}
      />
    );
  }

  return (
    <Container style={style}>
      <View>{listItems}</View>
    </Container>
  );
};

ExpensesList.Empty = function Empty({
  payerAilorn
}: {
  payerAilorn?: AiloRN;
}): ReactElement {
  const description = payerAilorn
    ? `No expenses have been added yet for this ${payerAilorn.entity}`
    : " No expenses have been added yet";
  return (
    <AlertScreen
      type={"plant-and-house-and-document"}
      description={description}
    />
  );
};

const Container = styled(View)`
  flex-grow: 1;
  justify-content: space-between;
`;
