import { DateInputFormField, TextInputFormField, Tooltip } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RelativeTimeInDays } from "local/common";
import { EndManagementFormData } from "../EndManagementFormData";
import { CalendarIcon, Colors, Text } from "@ailo/primitives";
import { LocalDate } from "@ailo/date";

interface Props {
  managementStartDate: LocalDate;
  tenancyEndDate?: LocalDate;
  initialValue?: string | null;
}

export function ManagementEndDateInput({
  managementStartDate,
  tenancyEndDate,
  initialValue
}: Props): ReactElement {
  const { control, errors } = useFormContext<EndManagementFormData>();
  const isManagementEndedAfter4Weeks =
    !!initialValue &&
    LocalDate.from(initialValue).isBefore(
      LocalDate.today().subtract(4, "weeks")
    );

  return (
    <Controller
      name={"managementEndDate"}
      render={({ value, onChange, onBlur }): ReactElement => {
        return (
          <>
            {isManagementEndedAfter4Weeks ? (
              <Tooltip
                tooltipContent={
                  "Please contact Support to change the end date."
                }
              >
                <TextInputFormField
                  value={LocalDate.from(value).format("D MMM YYYY")}
                  endAdornment={
                    <CalendarIcon
                      width={20}
                      height={20}
                      color={Colors.TEXT.DARK.PLACEHOLDER}
                    />
                  }
                  disabled
                />
              </Tooltip>
            ) : (
              <DateInputFormField
                webPopoverHorizontalAlign={"start"}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                label={"Management end date"}
                placeholder={"End date"}
                autoCorrect={false}
                autoCompleteType={"off"}
                minDate={managementStartDate.toString()}
                error={errors.managementEndDate?.message}
              />
            )}

            {value ? (
              <RelativeTimeInDays style={{ marginTop: 4 }} date={value} />
            ) : tenancyEndDate ? (
              <Text.BodyXS
                style={{ marginTop: 4 }}
                color={Colors.TEXT.DARK.SECONDARY}
              >
                {`Tenancy ends ${tenancyEndDate?.format("D MMM YYYY")}`}
              </Text.BodyXS>
            ) : null}
          </>
        );
      }}
      defaultValue={initialValue}
      control={control}
      rules={{
        required: "Please select a end date"
      }}
    />
  );
}
