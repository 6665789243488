import React from "react";
import { GradientAmountHeader, Money } from "@ailo/ui";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { BondIcon } from "@ailo/primitives";
import {
  ClaimTenancyData,
  getBondDisplayStatus,
  useUpdateBondStatus
} from "local/domain/bond";
import { TenancyBond, TenancyBondStatus } from "local/tabs";
import { canEditClaim } from "./canEditClaim";
import { useState } from "reactn";
import { RemoveBondClaimModal } from "./RemoveBondClaimModal";

interface BondClaimHeaderProps {
  onEdit: () => void;
  onClose(): void;
  data?: ClaimTenancyData;
  requestedAmount?: Money;
  loading?: boolean;
}

export function BondClaimHeader({
  data,
  requestedAmount,
  loading,
  onEdit,
  onClose
}: BondClaimHeaderProps): React.ReactElement {
  const hasEditBondClaimFeature = useHasFeature(
    PlatformFeatureId.EditTenancyBondClaim
  );

  const bond = data?.bond;

  const updateBondStatus = useUpdateBondStatus(
    bond,
    TenancyBondStatus.Unclaimed,
    "Bond marked as claim required"
  );
  const setBondUnclaimed = (): void => {
    updateBondStatus();
    onClose();
  };

  const [modalActive, setModalActive] = useState(false);
  const toggleModal = (): void => {
    setModalActive(!modalActive);
  };

  if (loading || !bond) {
    return <BondClaimHeader.Loading />;
  }

  const displayStatus = getBondDisplayStatus(bond.status, bond.failureReason);
  const bondStatusBadge = {
    testID: "Status",
    type: displayStatus.type,
    children: displayStatus.children
  };

  const actionButtons = getActionButtons(
    hasEditBondClaimFeature,
    bond,
    toggleModal,
    onEdit,
    setBondUnclaimed
  );

  return (
    <>
      <GradientAmountHeader
        label={"Requested by agency"}
        amount={requestedAmount}
        badges={[bondStatusBadge]}
        rightButtons={actionButtons}
        onClose={onClose}
        icon={BondIcon}
      />
      <RemoveBondClaimModal
        toggleModal={toggleModal}
        visible={modalActive}
        data={data}
        closeSidebar={onClose}
      />
    </>
  );
}

BondClaimHeader.Loading = GradientAmountHeader.Loading;

function getActionButtons(
  hasEditBondClaimFeature: boolean,
  bond: TenancyBond,
  toggleModal: () => void,
  onEdit: () => void,
  setBondUnclaimed: () => void
): { label: string; onPress: () => void }[] {
  const canRemoveClaim =
    hasEditBondClaimFeature &&
    [
      TenancyBondStatus.AwaitingFunds,
      TenancyBondStatus.DisbursementFailed
    ].includes(bond.status);
  const canMarkAsClaimRequired = bond.status === TenancyBondStatus.BondResolved;

  const rightButtons: { label: string; onPress: () => void }[] = [];

  if (canRemoveClaim) {
    rightButtons.push({ label: "Remove claim", onPress: toggleModal });
  }
  if (canEditClaim(hasEditBondClaimFeature, bond)) {
    rightButtons.push({ label: "Edit", onPress: onEdit });
  }
  if (canMarkAsClaimRequired) {
    rightButtons.push({
      label: "Mark as claim required",
      onPress: setBondUnclaimed
    });
  }
  return rightButtons;
}
