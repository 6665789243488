import { ArchiveIcon, MoreIcon, SuccessIcon, Text } from "@ailo/primitives";
import {
  Button,
  ConfirmModal,
  DropdownMenu,
  DropdownMenuToggleProps
} from "@ailo/ui";
import React, { useState } from "react";
import { isPresent } from "ts-is-present";
import { useChangeStatusOption } from "./useChangeStatusOption";
import { useArchiveProjectOption } from "./useArchiveProjectOption";
import { ProjectDetailsFragment } from "local/graphql";
import { getManagementIfExists } from "local/domain/project/getManagementIfExists";

export function ProjectMenu({
  project
}: {
  project: ProjectDetailsFragment;
}): React.ReactElement | null {
  const management = getManagementIfExists(project);
  const managementId = management ? management.id : undefined;
  const [showCloseModal, setShowCloseModal] = useState(false);
  const { dropdownMenuOption, closeProject } = useChangeStatusOption({
    projectId: project.id,
    projectStatus: project.status,
    managementId,
    setShowCloseModal,
    icon: <SuccessIcon />
  });
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const [archiveProjectOption, archiveProject] = useArchiveProjectOption({
    projectId: project.id,
    managementId,
    onArchiveProject: setShowArchiveModal,
    icon: <ArchiveIcon />
  });

  const options = [dropdownMenuOption, archiveProjectOption].filter(isPresent);

  if (options.length === 0) return null;

  return (
    <>
      <DropdownMenu renderToggle={renderDropdownButton} options={options} />
      <ProjectConfirmModal
        showModal={showArchiveModal}
        actionText={"Archive project"}
        onConfirm={() => {
          archiveProject(project);
          setShowArchiveModal(false);
        }}
        onCancel={() => {
          setShowArchiveModal(false);
        }}
        message={
          "Once archived, the project will not be visible or accessible."
        }
      />
      <ProjectConfirmModal
        showModal={showCloseModal}
        actionText={"Close project"}
        onConfirm={() => {
          closeProject(project.id, managementId);
          setShowCloseModal(false);
        }}
        onCancel={() => {
          setShowCloseModal(false);
        }}
        message={
          "Close this project to mark it as complete. Closed projects can be reopened if you need to continue working on them in future."
        }
      />
    </>
  );
}

function renderDropdownButton({
  open,
  toggle
}: DropdownMenuToggleProps): React.ReactElement {
  return (
    <Button.Secondary type={"small"} square active={open} onPress={toggle}>
      <MoreIcon width={20} height={20} />
    </Button.Secondary>
  );
}

function ProjectConfirmModal({
  showModal,
  actionText,
  message,
  onConfirm,
  onCancel
}: {
  showModal: boolean;
  actionText: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}): React.ReactElement {
  return (
    <ConfirmModal
      visible={showModal}
      title={actionText}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmLabel={actionText}
    >
      <Text.BodyM weight={"book"}>{message}</Text.BodyM>
    </ConfirmModal>
  );
}
