import { AiloRN } from "@ailo/ailorn";
import {
  useAddCentrepayDirectiveMutation,
  useUpsertLegalEntitiesCrnMutation
} from "local/graphql";
import { useCallback } from "react";
import { AddCentrepayerFormData } from "./CentrepaySetupModal";

interface params {
  managingEntity: AiloRN<string>;
  tenancy: AiloRN<string>;
  onSuccess: () => void;
  onFailure: () => void;
  createCentrepayDirective: boolean;
  setError: (name: "crn", error: { message: string }) => void;
}

export function useSubmitCentrepaySetupForm({
  managingEntity,
  tenancy,
  onSuccess,
  onFailure,
  createCentrepayDirective,
  setError
}: params): (data: AddCentrepayerFormData) => Promise<void> {
  const [upsertCrn] = useUpsertLegalEntitiesCrnMutation();
  const [addCentrepayDirective] = useAddCentrepayDirectiveMutation();
  return useCallback(
    async (data: AddCentrepayerFormData) => {
      const { errors } = await upsertCrn({
        variables: {
          input: {
            legalEntity: data.renter!,
            managingEntity: managingEntity.toString(),
            crn: data.crn?.toUpperCase()!
          }
        }
      });
      if (!errors && createCentrepayDirective) {
        const { errors: directiveErrors } = await addCentrepayDirective({
          variables: {
            input: {
              legalEntity: data.renter!,
              tenancy: tenancy.toString(),
              managingEntity: managingEntity.toString()
            }
          }
        });
        if (directiveErrors) {
          onFailure();
          return;
        }
      }
      if (errors) {
        if (
          errors[0].message.includes(
            "The same CRN may only be used once at an agency"
          )
        ) {
          setError("crn", { message: "CRN is already in use" });
          return;
        }
        onFailure();
        return;
      }
      onSuccess();
    },
    [
      upsertCrn,
      addCentrepayDirective,
      managingEntity,
      tenancy,
      onSuccess,
      onFailure,
      createCentrepayDirective,
      setError
    ]
  );
}
