import React, { ReactElement } from "react";
import { BondDetailsFormContext } from "../common/BondDetailsFormContext";
import { EditBondDetailsSidebarContent } from "./EditBondDetailsSidebarContent";
import { BondDetailsProps } from "../common/BondDetailsProps";

export function EditBondReferenceSidebar(
  props: BondDetailsProps
): ReactElement {
  return (
    <BondDetailsFormContext>
      <EditBondDetailsSidebarContent
        title={"Edit bond reference"}
        onSuccessMessage={"Bond reference updated"}
        onFailureMessage={"Unable to update reference. Please try again."}
        {...props}
      />
    </BondDetailsFormContext>
  );
}
