import { AiloRN } from "@ailo/ailorn";
import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import {
  ActionType,
  CreateActionMutation,
  useCreateActionMutation,
  GetProjectActionsDocument,
  ActionDetailsFragment
} from "local/graphql";
import { useCallback } from "react";
import { ActionFormData } from "../ActionFormData";

interface ActionDetails {
  projectId: string;
  name: string;
  description: string;
  assignee: AiloRN;
  dueDate: string;
  type: ActionType;
}

export function useCreateAction({
  onSuccess,
  onError
}: {
  onSuccess: ({
    action,
    formValues
  }: {
    action: ActionDetailsFragment;
    formValues: ActionFormData;
  }) => void;
  onError: () => void;
}): [
  (details: ActionDetails, formValues: ActionFormData) => void,
  MutationResult<CreateActionMutation>
] {
  const [createActionMutation, result] = useCreateActionMutation();

  const createAction = useCallback(
    async (
      { projectId, assignee, ...otherActionDetails }: ActionDetails,
      formValues: ActionFormData
    ): Promise<void> => {
      const mutationInput = {
        ...otherActionDetails,
        projectId: projectId,
        assigneeAilorn: assignee.toString()
      };

      let result: ExecutionResult<CreateActionMutation>;

      try {
        result = await createActionMutation({
          variables: { input: mutationInput },
          refetchQueries: [
            {
              query: GetProjectActionsDocument,
              variables: {
                projectId: projectId,
                pageSize: 10
              }
            }
          ]
        });
        throwIfMutationFailed(result, { dataKey: "createAction" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }

      const actionType = result.data!.createAction!.type;
      if (!actionType) {
        throw new Error("Action was created with no type.");
      }

      onSuccess({
        action: { ...result.data!.createAction!, type: actionType },
        formValues
      });
    },
    [createActionMutation, onSuccess, onError]
  );

  return [createAction, result];
}
