import React, { ReactElement } from "react";
import { BaseActionActivity } from "./BaseActionActivity";
import { DescriptionList, DescriptionListItem } from "@ailo/ui";
import { ActionActivitySectionHeader } from "./ActionActivitySectionHeader";

interface Props {
  title: string | React.ReactNode;
  headerRightColumn?: React.ReactNode;
  descriptionListData: DescriptionListData;
}

export type DescriptionListData = { label: string; description: string }[];

export function InputDetailsSection({
  title,
  headerRightColumn,
  descriptionListData
}: Props): ReactElement {
  return (
    <BaseActionActivity>
      <ActionActivitySectionHeader
        style={{ marginBottom: 4 }}
        title={title}
        rightColumn={headerRightColumn}
      />
      <DescriptionList
        items={getDescriptionListItems(descriptionListData)}
        rowStyle={{ marginTop: 16 }}
        columns={2}
        size={"small"}
      />
    </BaseActionActivity>
  );
}

function getDescriptionListItems(
  data: DescriptionListData
): DescriptionListItem[] {
  return data.map(({ label, description }, index) => ({
    label,
    descriptionBottom: description,
    columnIndex: index % 2
  }));
}
