import { LocalDate } from "@ailo/date";

const pluralize = (count: number): string => {
  return Math.abs(count) === 1 ? "day" : "days";
};

export function getRelativeTime(daysDiff: number): string {
  if (daysDiff === 0) return "Today";

  return `${Math.abs(daysDiff)} ${pluralize(daysDiff)} ${
    daysDiff < 0 ? "ago" : "from today"
  }`;
}

export function getRelativeTimeFromDateString(
  date?: string
): string | undefined {
  if (!date) return undefined;

  const daysDiff = LocalDate.fromString(date).diff(LocalDate.today());

  return getRelativeTime(daysDiff);
}
