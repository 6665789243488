import { AiloRN, services } from "@ailo/ailorn";
import { getPriceChangeDecription } from "@ailo/domains";
import { throwIfMutationFailed, useAnalytics } from "@ailo/services";
import { MoneyInterface } from "@ailo/ui";
import {
  GetManagementRecurringFeesDocument,
  useCancelRecurringFeeMutation,
  FeeFrequency
} from "local/graphql";
import { useCallback } from "react";

export interface CancelRecurringFeeDetails {
  feeAiloRN: string;
  managementId: string;
  endDate?: string;
  amount: MoneyInterface;
  blueprintId: string;
  blueprintAmount: MoneyInterface;
  frequency: FeeFrequency;
}

export function useCancelRecurringFee(): [
  cancelRecurringFee: (input: CancelRecurringFeeDetails) => Promise<void>,
  loading: boolean
] {
  const analytics = useAnalytics();

  const [cancelRecurringFeeMutation, { loading: submitting }] =
    useCancelRecurringFeeMutation();

  const cancelRecurringFee = useCallback(
    async ({
      feeAiloRN,
      managementId,
      endDate,
      amount,
      blueprintId,
      blueprintAmount,
      frequency
    }: CancelRecurringFeeDetails) => {
      if (submitting) return;

      const result = await cancelRecurringFeeMutation({
        variables: {
          input: { id: feeAiloRN, endDate }
        },
        //TODO: emit event trigger expenses list to refresh and remove the refetch query
        refetchQueries: [
          {
            query: GetManagementRecurringFeesDocument,
            variables: {
              managementId,
              managementAiloRN: AiloRN.of(
                services.PropertyManagement.management,
                managementId
              ).toString()
            }
          }
        ]
      });
      throwIfMutationFailed(result, { dataKey: "cancelRecurringFee" });

      analytics.track("Fee Cancelled on Property", {
        feeFrequency: frequency,
        feeBlueprintId: blueprintId,
        feePrice: getPriceChangeDecription({
          current: amount,
          original: blueprintAmount
        })
      });
    },
    [cancelRecurringFeeMutation, submitting, analytics]
  );

  return [cancelRecurringFee, submitting];
}
