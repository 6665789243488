import React from "react";
import { PropertyScreenCardErrorBoundary } from "local/domain/propertyManagement";
import { StyleProp, ViewStyle } from "react-native";
import { ManagementCard } from "./ManagementCard";

interface Props {
  style?: StyleProp<ViewStyle>;
  managementId: string;
  onOwnerPress?: (id: string, entityType: "Person" | "Company") => void;
  onManagementDetailsPress: (managementId: string) => void;
  onViewManagementFeeBlueprintsPress: (managementId: string) => void;
}

export function ManagementCardWithErrorBoundary({
  style,
  managementId,
  onOwnerPress,
  onManagementDetailsPress,
  onViewManagementFeeBlueprintsPress
}: Props): React.ReactElement {
  return (
    <PropertyScreenCardErrorBoundary
      style={style}
      cardTitle={"Management details"}
    >
      <ManagementCard
        managementId={managementId}
        style={style}
        onDetailsPress={(): void => onManagementDetailsPress(managementId)}
        onViewManagementFeeBlueprintsPress={(): void =>
          onViewManagementFeeBlueprintsPress(managementId)
        }
        onOwnerPress={onOwnerPress}
      />
    </PropertyScreenCardErrorBoundary>
  );
}
