import React, { ReactElement, useCallback, useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { useRefWidth } from "@ailo/domains";
import { useOnFocus, withScreenComponent } from "@ailo/services";
import {
  Screens,
  useRoute,
  SplitScreenContainer,
  useNavigation
} from "local/common";
import { ProjectSideTabs } from "./ProjectTabs/ProjectSideTabs";
import { Colors } from "@ailo/primitives";
import { ContentWithSidebar } from "@ailo/ui";
import { ActionSidebar } from "../actions";
import { ProjectFilesSidebar } from "./ProjectFilesSidebar/ProjectFilesSidebar";
import { ProjectScreenLeftSection } from "./ProjectTabs/ProjectScreenLeftSection";

interface Props {
  id: string;
  actionId?: string;
}

export const ProjectsScreen = withScreenComponent(
  { analytics: false },
  (): ReactElement | null => {
    const route = useRoute<Screens.Project>();
    if (!route.params?.id) return null;

    return <ProjectScreen id={route.params.id} />;
  }
);

function ProjectScreen({ id }: Props): ReactElement {
  const { actionId, resetActionId } = useActionId();
  const [sideBarWidth, rightContentRef] = useRefWidth();
  const [projectFilesOpen, setProjectFilesOpen] = useState(false);

  return (
    <ContentWithSidebar
      open={projectFilesOpen}
      hasBackdrop={false}
      sidebarContent={
        projectFilesOpen ? (
          <ProjectFilesSidebar
            projectId={id}
            onDismiss={() => setProjectFilesOpen(false)}
          />
        ) : null
      }
    >
      <ContentWithSidebar
        open={!!actionId}
        hasBackdrop={false}
        sideBarWidth={sideBarWidth}
        sidebarContent={
          actionId ? (
            <ActionSidebar
              projectId={id}
              actionId={actionId}
              onDismiss={resetActionId}
            />
          ) : null
        }
      >
        <SplitScreenContainer
          screenContainerProps={{ analytics: false }}
          rightRef={rightContentRef}
          left={
            <LeftSection>
              <ProjectScreenLeftSection
                id={id}
                setProjectFilesOpen={() => setProjectFilesOpen(true)}
              />
            </LeftSection>
          }
          right={
            <RightSection>
              <ProjectSideSection>
                <ProjectSideTabs />
              </ProjectSideSection>
            </RightSection>
          }
          type={"raised"}
          leftSectionSize={"small"}
          scrollable
        />
      </ContentWithSidebar>
    </ContentWithSidebar>
  );
}

const ProjectSideSection = styled(View)`
  padding-top: 32px;
  flex: 1;
  width: 100%;
  align-items: flex-start;
`;

const LeftSection = styled(View)`
  padding: 32px 60px 0;
  flex-grow: 1;
  width: 100%;
`;

const RightSection = styled(View)`
  flex: 1;
  background-color: ${Colors.WHITE};
`;

function useActionId(): {
  actionId: string | undefined;
  resetActionId: () => void;
} {
  const { actionId } = useRoute<Screens.Project>().params!;

  const navigation = useNavigation<Screens.Project>();

  const resetActionId = useCallback(() => {
    if (actionId) {
      navigation.setParams({ actionId: undefined });
    }
  }, [navigation, actionId]);

  useOnFocus(useCallback(() => resetActionId, [resetActionId]));

  return {
    actionId,
    resetActionId
  };
}
