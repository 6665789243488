import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { useCallback } from "react";
import { ProjectFileAnalytics } from "./useProjectAnalytics";
import {
  AddProjectFilesMutation,
  useAddProjectFilesMutation
} from "local/graphql";
import { UploadedFile } from "@ailo/ui";
import { AiloRN } from "@ailo/ailorn";

export type ProjectFileType = {
  fileId: string;
  file: UploadedFile;
};

export type AddProjectFileType = {
  projectId: string;
  ailorn: AiloRN<"file:file">;
  file?: ProjectFileType;
};

interface ProjectFileDetails {
  id: string;
  file?: { id: string } | null;
  contentType?: string;
  fileSize?: number;
}

export function useAddProjectFile({
  onStart,
  onCompleted,
  onError
}: {
  onStart?: (file: ProjectFileType) => void;
  onCompleted?: (fileDetails: ProjectFileDetails) => void;
  onError?: (file: ProjectFileAnalytics) => void;
} = {}): [
  ({ projectId, ailorn, file }: AddProjectFileType) => Promise<void>,
  MutationResult<AddProjectFilesMutation>
] {
  const [addProjectFilesMutation, result] = useAddProjectFilesMutation();
  const addProjectFiles = useCallback(
    async ({ projectId, ailorn, file }: AddProjectFileType): Promise<void> => {
      let result: ExecutionResult<AddProjectFilesMutation>;

      try {
        if (file) onStart?.(file);

        result = await addProjectFilesMutation({
          variables: {
            projectId: projectId,
            fileAilorns: [ailorn.toString()]
          }
        });
        throwIfMutationFailed(result, { dataKey: "addProjectFiles" });
      } catch (error) {
        AiloSentry.captureException(error);
        if (file) {
          const {
            file: { size: fileSize, name: fileName }
          } = file;
          onError?.({ fileSize, fileName });
        }
        return;
      }

      onCompleted?.(result.data?.addProjectFiles?.[0]!);
    },
    [addProjectFilesMutation, onCompleted, onError, onStart]
  );

  return [addProjectFiles, result];
}
