import { Colors, DownCaretIcon, opacify, Skeleton } from "@ailo/primitives";
import { Button, Separator } from "@ailo/ui";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import Popover from "react-tiny-popover";
import styled from "styled-components/native";
import { TenancySwitcherItem } from "./components";
import { Tenancy, TenancyType } from "./useGetManagementTenancies";

export function TenancySwitcher({
  value: selectedTenancyId,
  tenancies,
  style,
  onChange
}: {
  value: string;
  tenancies: Tenancy[];
  managementId: string;
  style?: StyleProp<ViewStyle>;
  onChange: (tenancyId: string) => void;
}): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false);

  const nonVoidedTenancies = tenancies.filter((tenancy) => !tenancy.voidedAt);

  const activeAndIngoingTenancies: Tenancy[] = useMemo(
    () =>
      sortTenancies(nonVoidedTenancies).filter(
        (tenancy) => tenancy.type !== TenancyType.FORMER
      ),
    [nonVoidedTenancies]
  );

  const formerTenancies: Tenancy[] = useMemo(
    () =>
      sortTenancies(nonVoidedTenancies).filter(
        (tenancy) => tenancy.type === TenancyType.FORMER
      ),
    [nonVoidedTenancies]
  );

  const selectedTenancy = nonVoidedTenancies.find(
    (tenancy) => tenancy.id === selectedTenancyId
  );

  if (!selectedTenancy) {
    throw new Error("Selected tenancy not loaded");
  }

  return (
    <View style={[{ marginHorizontal: 24, marginBottom: 20 }, style]}>
      <Popover
        isOpen={isOpen}
        position={"bottom"}
        onClickOutside={() => {
          setIsOpen(false);
        }}
        containerStyle={{
          boxShadow: `0px 2px 12px ${opacify(Colors.BLACK, 0.2)}`,
          borderRadius: "8px",
          overflow: "hidden",
          paddingTop: "8px",
          paddingBottom: "8px",
          backgroundColor: Colors.WHITE
        }}
        padding={0}
        content={({ targetRect }) => (
          <View style={{ width: targetRect.width }}>
            {activeAndIngoingTenancies.map((tenancy) => (
              <TenancySwitcherItem
                key={`Tenancy-Switcher-Menu-Item-${tenancy.id}`}
                style={{
                  paddingVertical: 6,
                  borderType: "none"
                }}
                tenancy={tenancy}
                onPress={() => {
                  setIsOpen(false);
                  onChange(tenancy.id);
                }}
              />
            ))}
            {!!activeAndIngoingTenancies.length && !!formerTenancies.length && (
              <Separator style={{ marginVertical: 16 }} />
            )}
            {formerTenancies.map((tenancy) => (
              <TenancySwitcherItem
                key={`Tenancy-Switcher-Menu-Item-${tenancy.id}`}
                style={{
                  paddingVertical: 6,
                  borderType: "none"
                }}
                tenancy={tenancy}
                onPress={() => {
                  setIsOpen(false);
                  onChange(tenancy.id);
                }}
              />
            ))}
          </View>
        )}
      >
        <View>
          <Button.Secondary
            style={{
              flex: 1,
              paddingVertical: 0,
              paddingLeft: 16,
              paddingRight: 10,
              borderColor: opacify(Colors.SPACE_BLACK, 0.1),
              borderWidth: 1,
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "center"
            }}
            onPress={() => {
              setIsOpen(!isOpen);
            }}
            contentContainerStyle={{ flex: 1 }}
            contentStyle={{
              justifyContent: "space-between",
              alignItems: "center"
            }}
            contentColor={{
              default: Colors.TEXT.DARK.PRIMARY,
              hovered: Colors.TEXT.DARK.PRIMARY,
              active: Colors.TEXT.DARK.PRIMARY,
              pressed: Colors.TEXT.DARK.PRIMARY,
              disabled: Colors.TEXT.DARK.PRIMARY
            }}
            active={isOpen}
            rightIcon={() => (
              <DownCaretIcon color={Colors.TEXT.DARK.SECONDARY} />
            )}
          >
            {selectedTenancy.type}
          </Button.Secondary>
        </View>
      </Popover>
    </View>
  );
}

const Loading = styled(Skeleton)`
  height: 40px;
  margin: 0 24px 20px 24px;
  border-radius: 20px;
`;

TenancySwitcher.Loading = Loading;

export function sortTenancies(tenancies: Tenancy[] = []): Tenancy[] {
  return [
    ...tenancies.filter((tenancy) => tenancy.type === TenancyType.ACTIVE),
    ...tenancies.filter((tenancy) => tenancy.type === TenancyType.INGOING),
    ...tenancies
      .filter((tenancy) => tenancy.type === TenancyType.FORMER)
      .sort((tenancy1, tenancy2) => {
        if (!tenancy1.endDate)
          throw new Error(`Former tenancy without end date: ${tenancy1.id}`);
        if (!tenancy2.endDate)
          throw new Error(`Former tenancy without end date: ${tenancy2.id}`);

        if (moment(tenancy1.endDate).isAfter(moment(tenancy2.endDate)))
          return -1;

        if (moment(tenancy1.endDate).isBefore(moment(tenancy2.endDate)))
          return 1;

        return 0;
      })
  ];
}
