import { Text, Colors, opacify, LeftArrow } from "@ailo/primitives";
import { Row } from "local/common";

import { Card } from "@ailo/ui";

import React from "react";
import { View } from "react-native";

export interface ReportMetricCardProps {
  label: string;
  value: string | number | undefined | null;
  loading?: boolean;
  previousValue?: string;
  periodLabel?: String;
  changeValue?: String;
  changeType?: "positive" | "negative";
}
export function ReportMetricCard({
  label,
  value,
  loading,
  previousValue,
  periodLabel,
  changeValue,
  changeType
}: ReportMetricCardProps): React.ReactElement {
  const includePreviousPeriod = previousValue ? true : false;
  let changeColour: string = "";
  let arrowRotation: string = "";
  if (includePreviousPeriod) {
    changeColour = changeType === "positive" ? "#479E32" : "#E64A19";
    arrowRotation =
      parseFloat(changeValue!?.replace("$", "")) >= 0 ? "90deg" : "270deg";
  }

  return (
    <Card
      style={{
        minWidth: includePreviousPeriod ? 450 : 300,
        borderRadius: 4,
        shadowColor: opacify(Colors.BLACK, 0.2),
        shadowOffset: { height: 1, width: 0 },
        marginLeft: 0,
        marginRight: 16,
        marginBottom: 16,
        marginTop: 0
      }}
      innerStyle={{
        paddingHorizontal: 26,
        paddingVertical: 20
      }}
      contentStyle={{
        flexDirection: "column",
        justifyContent: "flex-start"
      }}
    >
      <Row style={{ justifyContent: "space-between", alignItems: "flex-end" }}>
        <View
          style={{
            justifyContent: "center",
            marginLeft: 8
          }}
        >
          {loading ? (
            <>
              <Text.DisplayL.Loading />
              <Text.BodyS.Loading />
            </>
          ) : (
            <>
              {" "}
              <Text.DisplayL color={Colors.TEXT.DARK.PRIMARY}>
                {value}
              </Text.DisplayL>
              <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
                {label}
              </Text.BodyS>
            </>
          )}
        </View>
        {includePreviousPeriod && (
          <View
            style={{
              justifyContent: "center",
              alignItems: "flex-end",
              marginLeft: 8
            }}
          >
            <Row style={{ justifyContent: "flex-start", alignItems: "center" }}>
              <LeftArrow
                color={changeColour}
                style={{ transform: [{ rotate: arrowRotation }] }}
              />
              {loading ? (
                <Text.DisplayM.Loading />
              ) : (
                <Text.DisplayM color={changeColour}>
                  {changeValue}
                </Text.DisplayM>
              )}
            </Row>
            {loading ? (
              <Text.BodyS.Loading />
            ) : (
              <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
                {previousValue}
                {" last "}
                {periodLabel}
              </Text.BodyS>
            )}
          </View>
        )}
      </Row>
    </Card>
  );
}
