import { DisbursementsIcon, IconProps, Text } from "@ailo/primitives";
import { Card } from "@ailo/ui";
import React, { ReactElement } from "react";
import styled from "styled-components/native";

interface Props {
  icon?: React.FC<IconProps>;
  primaryText: string;
  secondaryText: string;
}

export function EmptyGridCard({
  icon: Icon = DisbursementsIcon,
  primaryText,
  secondaryText
}: Props): ReactElement {
  return (
    <EmptyCardContainer
      contentStyle={{ justifyContent: "center", alignItems: "center" }}
    >
      <Icon style={{ paddingBottom: 20 }} />
      <Text.BodyL weight={"medium"}>{primaryText}</Text.BodyL>
      <Text.BodyS style={{ paddingTop: 8 }}>{secondaryText}</Text.BodyS>
    </EmptyCardContainer>
  );
}

const EmptyCardContainer = styled(Card)`
  justify-content: center;
  align-items: center;
  height: 360px;
  margin: 0;
`;
