import React, { ReactElement } from "react";
import { View } from "react-native";
import { Skeleton, Text } from "@ailo/primitives";
import { Button, StickyBottom } from "@ailo/ui";
import styled from "styled-components/native";

export function ClaimBondFormLoading({
  title
}: {
  title: string;
}): ReactElement {
  return (
    <View
      style={{
        paddingLeft: 60,
        paddingTop: 32,
        width: 680,
        flex: 1,
        alignSelf: "flex-end"
      }}
    >
      <Text.DisplayM weight={"medium"}>{title}</Text.DisplayM>
      <Bone style={{ marginTop: 44 }} />
      <Bone style={{ marginTop: 28 }} />
      <View style={{ paddingRight: 60 }}>
        <EmptyCard
          style={{
            marginBottom: 12,
            marginTop: 12,
            width: "100%"
          }}
        />
        <EmptyCard style={{ width: "100%" }} />
      </View>

      <StickyBottom>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: 60,
            paddingTop: 12,
            paddingBottom: 16
          }}
        >
          <Button.Secondary>{"Cancel"}</Button.Secondary>
          <Button disabled>{"Confirm amount"}</Button>
        </View>
      </StickyBottom>
    </View>
  );
}

const Bone = styled(Skeleton)`
  width: 120px;
  height: 12px;
  border-radius: 4px;
`;
const EmptyCard = styled(View)`
  height: 72px;
  border-radius: 4px;
  border-width: 1px;
  border-color: rgba(28, 30, 38, 0.1);
`;
