import { formatAddress } from "@ailo/domain-helpers";
import { didQueryNotLoadYet, didQuerySucceed } from "@ailo/services";
import { ContentWithSidebar, ErrorAlertScreen, Money } from "@ailo/ui";
import {
  Screens,
  SplitScreenContainer,
  useNavigation,
  useRoute
} from "local/common";
import { ManagementFeeBlueprintsSidebar } from "local/domain/fee/managementFeeBlueprint";
import React, { ReactElement, useMemo, useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { AddExpenseFormContext } from "./AddExpenseFormContext";
import { AddExpenseInfoPanel } from "./AddExpenseInfoPanel";
import { useGetAddExpenseScreenDetailsQuery } from "local/graphql";

export function AddExpenseScreen(): ReactElement | null {
  const navigation = useNavigation();
  const route = useRoute<Screens.AddExpense>();
  const managementId = route.params!.managementId;

  const [
    showManagementFeeBlueprintsSidebar,
    setShowManagementFeeBlueprintsSidebar
  ] = useState(false);

  const result = useGetAddExpenseScreenDetailsQuery({
    variables: { managementId },
    skip: !managementId,
    fetchPolicy: "cache-and-network"
  });

  const onClose = (): void =>
    // TODO can use navigate if form.clear() clears nested values
    // like supplierReference
    navigation.replace(Screens.AppNavigator, {
      screen: Screens.PropertiesTab,
      params: { screen: Screens.Property, params: { managementId } }
    });

  const management = result.data?.management;
  const propertyAddress = management?.property.address;
  const formattedAddress = propertyAddress
    ? formatAddress(propertyAddress, {
        format: "street, suburb"
      })
    : undefined;

  const managingEntityAilorn = management?.managingEntity?.ailoRN;
  const managementAgreementId =
    management?.currentOrNextManagementAgreement?.id;

  const nextTenancyRents = management?.nextTenancy?.rents.items;
  const nextTenancyFirstRent = nextTenancyRents?.length
    ? nextTenancyRents[0]
    : undefined;

  const mostRecentTenancyRents = management?.mostRecentTenancy?.rents.items;
  const mostRecentTenancyLastRent = mostRecentTenancyRents?.length
    ? mostRecentTenancyRents[0]
    : undefined;

  const dailyRate =
    nextTenancyFirstRent?.dailyRate ??
    mostRecentTenancyLastRent?.dailyRate ??
    management?.mostRecentTenancy?.currentRent?.dailyRate;

  const weeklyRent = useMemo(
    () => (dailyRate ? Money.fromCents(dailyRate * 7) : undefined),
    [dailyRate]
  );

  // route.params and therefore managementId becomes undefined when using
  // the browser's back button to go back to the property page
  if (!managementId) return null;

  if (didQueryNotLoadYet(result))
    return (
      <SplitScreenContainer
        left={
          <LeftSection>
            <AddExpenseFormContext.Loading
              onClose={onClose}
              style={{ flex: 1, flexGrow: 1 }}
            />
          </LeftSection>
        }
        leftSectionSize={"medium"}
        right={<AddExpenseInfoPanel.Loading />}
      />
    );

  if (
    !didQuerySucceed(result) ||
    !management ||
    !formattedAddress ||
    !managementAgreementId ||
    !managingEntityAilorn
  ) {
    return <ErrorAlertScreen onRetry={result.refetch} />;
  }

  return (
    <ContentWithSidebar
      open={showManagementFeeBlueprintsSidebar}
      sidebarContent={
        showManagementFeeBlueprintsSidebar ? (
          <ManagementFeeBlueprintsSidebar
            managementId={managementId}
            showChargeAFeeButton={false}
            onClose={() => setShowManagementFeeBlueprintsSidebar(false)}
          />
        ) : null
      }
      onDismiss={() => setShowManagementFeeBlueprintsSidebar(false)}
    >
      <SplitScreenContainer
        left={
          <LeftSection>
            <AddExpenseFormContext
              propertyAddress={formattedAddress}
              managingEntityAilorn={managingEntityAilorn}
              weeklyRent={weeklyRent}
              managementAgreementId={managementAgreementId}
              onViewFeeSchedulePress={() =>
                setShowManagementFeeBlueprintsSidebar(true)
              }
              onClose={onClose}
              style={{ flex: 1, flexGrow: 1 }}
            />
          </LeftSection>
        }
        leftSectionSize={"medium"}
        right={<AddExpenseInfoPanel management={management} />}
      />
    </ContentWithSidebar>
  );
}

const LeftSection = styled(View)`
  width: 100%;
  flex: 1;
`;
