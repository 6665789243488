import React from "react";
import { useFormContext } from "react-hook-form";
import { View, StyleProp, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { MultiSelectFilter, StringFilter } from "./filters";
import { FilterConfig } from "./types";
import { TeamsFilter } from "local/domain/propertyManagement";
import { ReportTableRow } from "../ReportTable";

type FilterFactoryProps<Row extends ReportTableRow> = {
  filters?: FilterConfig<Row>[];
  rows?: Row[] | null;
  loading?: boolean;
  appendComponents?: JSX.Element[];
  style?: StyleProp<ViewStyle>;
};

const FilterWrapper = styled(View)`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: none;
  padding: 16px;
`;

const SearchFilterContainer = styled(View)`
  flex-grow: 1;
  min-width: 250px;
`;

export const FilterFactory = <Row extends ReportTableRow>({
  rows,
  filters,
  loading = false,
  appendComponents,
  style
}: FilterFactoryProps<Row>): React.ReactElement<FilterFactoryProps<Row>> => {
  const { control } = useFormContext();

  const FilterComponents = (filters ?? [])
    .map((filter) => {
      switch (filter.type) {
        case "string":
          return (
            <SearchFilterContainer key={filter.name}>
              <StringFilter
                defaultValue={filter.defaultValue}
                name={filter.name}
                control={control}
                placeholder={filter.placeholder}
                loading={loading}
              />
            </SearchFilterContainer>
          );
        case "team":
          return <TeamsFilter key={"team"} />;
        case "multiSelect":
          return (
            <MultiSelectFilter
              key={filter.name}
              name={filter.name}
              namePlural={filter.namePlural}
              control={control}
              rows={rows}
              columnName={filter.columns[0]}
              defaultValue={filter.defaultValue}
              loading={loading}
              menuWidth={filter.menuWidth}
            />
          );
      }
    })
    .concat(appendComponents ?? []);
  if (FilterComponents.length == 0) return <></>;

  const hasSearch = filters?.find((f) => f.type == "string");

  return (
    <FilterWrapper
      style={[{ justifyContent: hasSearch ? undefined : "flex-end" }, style]}
    >
      {FilterComponents}
    </FilterWrapper>
  );
};

export function FilterFactoryLoading<TRow extends ReportTableRow>({
  filters,
  appendComponents,
  style
}: Pick<
  FilterFactoryProps<TRow>,
  "filters" | "appendComponents" | "style"
>): React.ReactElement {
  return (
    <FilterFactory
      loading
      filters={filters}
      appendComponents={appendComponents}
      style={style}
    />
  );
}
