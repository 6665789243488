import { FormField, TextInputFormField } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LegalEntityInput } from "local/domain/authz";
import { ActionFormData } from "../ActionFormData";
import { PersonDisplayDetailsFragment } from "local/graphql";
import { sortPeopleAlphabeticallyByName } from "local/domain/utils/sortPeopleAlphabeticallyByName";

export function ActionAssigneeField({
  assignees,
  onFieldUpdated,
  label
}: {
  assignees: PersonDisplayDetailsFragment[];
  onFieldUpdated(fieldName: keyof ActionFormData): void;
  label?: string;
}): ReactElement {
  const { control, errors } = useFormContext<ActionFormData>();
  const error = errors.assignee?.message;
  return (
    <Controller
      control={control}
      name={"assignee"}
      defaultValue={null}
      render={({ value, onChange, onBlur }) => (
        <FormField label={label} error={error}>
          <LegalEntityInput
            {...{ value, onBlur }}
            placeholder={"Select assignee..."}
            options={sortPeopleAlphabeticallyByName(assignees)}
            onChange={(selectedValue) => {
              onChange(selectedValue);
              onFieldUpdated("assignee");
            }}
            hasError={!!error}
          />
        </FormField>
      )}
      rules={{
        required: "Please select a team member for the action"
      }}
    />
  );
}

ActionAssigneeField.Loading = function Loading() {
  return (
    <TextInputFormField.Loading labelWidth={60} style={{ marginBottom: 20 }} />
  );
};
