import { TextInputFormField, validateMaxLength } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  EndTenancyFormData,
  EndTenancyFormFields
} from "../EndTenancyFormData";

export function EndTenancyNoteInput({
  notes
}: {
  notes?: string | null;
}): ReactElement {
  const { control, errors } = useFormContext<EndTenancyFormData>();
  return (
    <Controller
      control={control}
      name={EndTenancyFormFields.notes}
      defaultValue={notes}
      render={({ value, onChange, onBlur }): ReactElement => {
        return (
          <TextInputFormField
            {...{ value, onChange, onBlur }}
            label={"Tenancy end note (optional)"}
            softCharacterLimit={{
              limit: 140,
              enforcementLevel: "error"
            }}
            multiline
            numberOfLines={3}
            inputStyle={{
              height: 92
            }}
            error={errors.notes?.message}
            helperText={"Private note only visible within your agency"}
          />
        );
      }}
      rules={{
        validate: {
          maxLength: validateMaxLength(140)
        }
      }}
    />
  );
}
