import { useMountedState } from "@ailo/primitives";
import { useCallback } from "react";
import { useOnFocus } from "@ailo/services";

export const MIN_NO_CHARS_TO_START_SEARCH = 2;

export const CONTACTS_LIST_PAGE_SIZE = 25;

export interface ContactsListLoadingRow {
  key: string;
}

export interface RequestPage {
  page: number;
  pageSize: number;
}

export interface RequestPaginationParams {
  page: number;
  pageSize: number;
}

interface PaginationState {
  currentPage: number;
  requestNewPage: (pageNo: number) => void;
  requestFromBeginning: () => void;
}

export function usePaginationRequest(
  sendRequest: (paginationParams?: RequestPaginationParams) => void
): PaginationState {
  const [currentPage, setCurrentPageNo] = useMountedState<number>(1);

  const onFocus = useCallback((): void => {
    setCurrentPageNo(1);
    sendRequest();
  }, [sendRequest, setCurrentPageNo]);

  useOnFocus(onFocus);

  const _request = useCallback(
    (page: number): void => {
      sendRequest({ page, pageSize: CONTACTS_LIST_PAGE_SIZE });
      setCurrentPageNo(page);
    },
    [sendRequest, setCurrentPageNo]
  );

  const requestNewPage = useCallback(
    (pageNo: number): void => {
      _request(pageNo);
    },
    [_request]
  );

  const requestFromBeginning = useCallback((): void => {
    _request(1);
  }, [_request]);

  return {
    requestNewPage,
    requestFromBeginning,
    currentPage
  };
}
