import { didQueryNotLoadYet, didQuerySucceed } from "@ailo/services";
import { useCurrentAgencyOrg } from "local/common";
import {
  FeeBlueprintType,
  GetManagementFeeBlueprintsQuery,
  useGetFeeBlueprintsQuery
} from "local/graphql";
import { useState } from "react";
import { createAgencyBlueprintOptions } from "../ManagementFeeBlueprintModalForm";

interface UseManagementFeeBlueprintModalsProps {
  managementId: string;
}

export type AgencyFeeBlueprintQuery = ReturnType<
  typeof useManagementFeeBlueprintModals
>["agencyOneOffBlueprints"];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useManagementFeeBlueprintModals({
  managementId
}: UseManagementFeeBlueprintModalsProps) {
  const [showAddBlueprintModal, setShowAddBlueprintModal] = useState(false);
  const [showEditBlueprintModal, setShowEditBlueprintModal] = useState(false);
  const [blueprintToEdit, setBlueprintToEdit] =
    useState<
      NonNullable<
        GetManagementFeeBlueprintsQuery["managementFeeBlueprints"]
      >["items"][number]
    >();

  const currentOrg = useCurrentAgencyOrg();
  const agencyOneOffFeeBlueprintsResult = useGetFeeBlueprintsQuery({
    variables: {
      conditions: {
        type: FeeBlueprintType.OneOffFee,
        notAppliedToManagementId: managementId,
        organisationId: currentOrg.id,
        archived: false
      }
    },
    fetchPolicy: "network-only"
  });

  const agencyEventBasedFeeBlueprintsResult = useGetFeeBlueprintsQuery({
    variables: {
      conditions: {
        type: FeeBlueprintType.EventBasedFee,
        notAppliedToManagementId: managementId,
        organisationId: currentOrg.id,
        archived: false
      }
    },
    fetchPolicy: "network-only"
  });

  return {
    modalState: {
      showAddBlueprintModal,
      setShowAddBlueprintModal,
      showEditBlueprintModal,
      setShowEditBlueprintModal,
      blueprintToEdit,
      setBlueprintToEdit
    },
    agencyOneOffBlueprints: {
      loaded: !didQueryNotLoadYet(agencyOneOffFeeBlueprintsResult),
      error:
        !didQueryNotLoadYet(agencyOneOffFeeBlueprintsResult) &&
        !didQuerySucceed(agencyOneOffFeeBlueprintsResult),
      options: createAgencyBlueprintOptions(
        agencyOneOffFeeBlueprintsResult.data?.blueprints?.items
      ),
      refetch: agencyOneOffFeeBlueprintsResult.refetch
    },
    agencyEventBasedBlueprints: {
      loaded: !didQueryNotLoadYet(agencyEventBasedFeeBlueprintsResult),
      error:
        !didQueryNotLoadYet(agencyEventBasedFeeBlueprintsResult) &&
        !didQuerySucceed(agencyEventBasedFeeBlueprintsResult),
      options: createAgencyBlueprintOptions(
        agencyEventBasedFeeBlueprintsResult.data?.blueprints?.items
      ),
      refetch: agencyEventBasedFeeBlueprintsResult.refetch
    }
  };
}
