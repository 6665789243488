import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import { ReiFormErrorCode } from "local/graphql";

export function getReiFormError(
  errorCode: ReiFormErrorCode,
  error?: ApolloError
): GraphQLError | undefined {
  return error?.graphQLErrors.find((e) => e?.extensions?.code === errorCode);
}
