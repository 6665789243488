import { useCurrentAgencyOrg, notEmpty } from "local/common";
import React from "react";
import { useGetBillsDueTableDataQuery } from "local/graphql";

import { useNavigation } from "@react-navigation/native";
import {
  dateForSort,
  floatForSort,
  useReportQuery,
  useReportTeamContext
} from "../..";
import { ReportPage } from "../../components";
import { FilterComponentType } from "../../components/ReportPage/FilterFactory";
import { billRowLink } from "../../components/ReportPage/utils/tableNavigation";
import { reports } from "../../reportsDetails";

const defaultColumns: string[] = [
  "propertyAddress",
  "billDate",
  "billType",
  "payerEntityType",
  "supplierName",
  "billInvoiceNumber",
  "billAmount",
  "billCreatedAt",
  //"billPaymentStatus",
  "billDueDays"
];

export function BillsTable(): React.ReactElement {
  const { id } = useCurrentAgencyOrg();
  const { teamId } = useReportTeamContext();
  // get query params
  const navigationState = useNavigation().getState();
  const navigationParams = navigationState.routes[navigationState.index]
    .params as { billIssue?: string; billColumns?: string };
  const billIssue = navigationParams?.billIssue;
  // pull out propertyColumns
  const billColumns = navigationParams?.billColumns
    ? navigationParams.billColumns.split(",")
    : defaultColumns;
  // get data
  const queryResult = useReportQuery(useGetBillsDueTableDataQuery, {
    managingOrgId: id,
    billIssue,
    teamId
  });
  const reportData = queryResult.data?.billsDueReport!;
  const rows = reportData?.rows?.filter(notEmpty);
  const columns = reportData?.columns
    ?.filter(notEmpty)
    ?.filter((c) => !billColumns || billColumns.includes(c.key));

  const reportTitle = billIssue ? billIssue : reports.bills_due.reportHeading;
  return (
    <ReportPage
      title={reportTitle}
      data={{
        ...queryResult,
        rows,
        columns
      }}
      columnsConfig={{
        propertyAddress: {
          width: 2
        },
        billAmount: {
          sortValue: (r) => floatForSort(r.billAmount)
        },
        billDueDays: {
          width: 0.5
        },
        billDate: {
          sortValue: (r) => dateForSort(r.billDate)
        },
        billCreatedAt: {
          sortValue: (r) => dateForSort(r.billCreatedAt)
        }
      }}
      defaultSort={{ columnKey: "billDueDays", direction: "DESC" }}
      filterConfig={[
        {
          name: "search",
          columns: ["propertyAddress", "billInvoiceNumber", "supplierName"],
          type: FilterComponentType.Search,
          defaultValue: "",
          placeholder: "Search properties, invoices or suppliers"
        },
        {
          name: "Bill type",
          namePlural: "Bill types",
          columns: ["billType"],
          defaultValue: [],
          type: FilterComponentType.MultiSelect
        },
        {
          name: "Payer",
          namePlural: "Payers",
          columns: ["payerEntityType"],
          defaultValue: [],
          type: FilterComponentType.MultiSelect
        }
      ]}
      rowLink={billRowLink}
    />
  );
}
