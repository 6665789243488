import React, { ReactElement } from "react";
import { DateInputFormButton, DateTimeWithTimeZone } from "@ailo/ui";
import { Skeleton } from "@ailo/primitives";
import { View, ViewStyle } from "react-native";

export function DueDateInputSelector({
  date,
  onChange,
  style
}: {
  date: string;
  onChange: (newDate: string) => void;
  style?: ViewStyle;
}): ReactElement {
  const isOverdue =
    DateTimeWithTimeZone.fromLocalDate(date).daysBeforeToday() > 0;

  return (
    <View style={style}>
      <DateInputFormButton
        label={isOverdue ? "Overdue" : "Due"}
        warning={isOverdue}
        webPopoverHorizontalAlign={"end"}
        webPopoverContainerStyle={{ marginTop: "-8px" }}
        value={date}
        dateFormat={"D MMM"}
        onChange={onChange}
      />
    </View>
  );
}

DueDateInputSelector.Loading = function DueDateInputSelectorLoading({
  style
}: {
  style?: ViewStyle;
}): ReactElement {
  return (
    <Skeleton style={{ ...style, width: 80, height: 32, borderRadius: 16 }} />
  );
};
