import React, { createContext, useContext } from "react";
import { SidebarForm } from "../SidebarForm";

interface PropertyScreenSidebarData {
  setSidebarForm: (sidebarFormParams: SidebarForm | undefined) => void;
}

export const PropertyScreenSidebarContext =
  createContext<PropertyScreenSidebarData>({
    setSidebarForm: () => {
      throw new Error("Context not available");
    }
  });

function PropertyScreenSidebarContextProvider({
  setSidebarForm,
  children
}: {
  setSidebarForm: (sidebarFormParams: SidebarForm | undefined) => void;
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <PropertyScreenSidebarContext.Provider value={{ setSidebarForm }}>
      {children}
    </PropertyScreenSidebarContext.Provider>
  );
}

function usePropertyScreenSidebar(): PropertyScreenSidebarData {
  return useContext(PropertyScreenSidebarContext);
}

export { PropertyScreenSidebarContextProvider, usePropertyScreenSidebar };
