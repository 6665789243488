import { ConfirmModal, DateInputFormField } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller, useForm } from "react-hook-form";
import { LocalDate } from "@ailo/date";
import { View } from "react-native";
import { TenancyInspectionScheduleFragment } from "local/graphql";
import { useSetRoutineInspectionDueDate } from "./useSetRoutineInspectionDueDate";
import { getRelativeTimeFromDateString } from "local/common";
import { InspectionFrequencyAlert } from "./InspectionFrequencyAlert";

interface FormData {
  dueDate: string;
}

interface Props {
  existingSchedule: TenancyInspectionScheduleFragment;
  onCancel(): void;
  onDueDateSet?(tenancy: TenancyInspectionScheduleFragment): void;
}

export function SetRoutineInspectionDueDateModalForm({
  existingSchedule,
  onCancel,
  onDueDateSet
}: Props): ReactElement {
  const { id: tenancyId, routineInspectionDueDate } = existingSchedule;

  const form = useForm<FormData>({
    mode: "onBlur",
    defaultValues: routineInspectionDueDate
      ? { dueDate: routineInspectionDueDate }
      : undefined
  });
  const [setInspectionDueDate] = useSetRoutineInspectionDueDate({
    onCompleted: onDueDateSet
  });

  const submit = form.handleSubmit((data: FormData) => {
    setInspectionDueDate({ ...data, tenancyId });
  });

  return (
    <ConfirmModal
      title={"Set inspection due date"}
      onConfirm={submit}
      onCancel={onCancel}
      showFooterBorder={true}
      borderRadius={12}
    >
      <View style={{ paddingBottom: 44 }}>
        <InspectionFrequencyAlert
          style={{ marginBottom: 16 }}
          existingSchedule={existingSchedule}
        />
        <Controller
          control={form.control}
          name={"dueDate"}
          render={({ value, onBlur, onChange }): React.ReactElement => (
            <DateInputFormField
              webPopoverHorizontalAlign={"start"}
              onBlur={onBlur}
              onChange={(date): void => {
                onChange(date ?? null);
              }}
              minDate={LocalDate.today().toString()}
              placeholder={"No due date set"}
              autoCorrect={false}
              autoCompleteType={"off"}
              value={value}
              label={"Inspection due date"}
              error={form.errors.dueDate?.message}
              helperText={getRelativeTimeFromDateString(value)}
            />
          )}
        />
      </View>
    </ConfirmModal>
  );
}
