import React, { useCallback } from "react";
import { useGlobalModal } from "@ailo/ui";
import { Screens, useNavigation } from "local/common";
import { ProjectModalForm } from "local/modals/ProjectModalForm/ProjectModalForm";
import { useAnalytics } from "@ailo/services";

interface UseCreateProject {
  onCreateProjectPress: () => void;
}

export function useHandleCreateProjectButton(
  managementId: string
): UseCreateProject {
  const navigation = useNavigation();
  const modal = useGlobalModal();
  const analytics = useAnalytics();

  const onCreateProjectPress = useCallback(() => {
    analytics.track("Create Project Clicked");
    modal.show(
      <ProjectModalForm
        managementId={managementId}
        onDismiss={modal.hide}
        onSuccess={(projectId: string) =>
          navigation.navigate(Screens.AppNavigator, {
            screen: Screens.ProjectsTab,
            params: {
              screen: Screens.Project,
              params: {
                id: projectId
              }
            }
          })
        }
      />
    );
  }, [managementId, modal, navigation, analytics]);

  return { onCreateProjectPress };
}
