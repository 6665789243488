import { useMountedState } from "@ailo/primitives";
import {
  Button,
  ErrorAlertScreen,
  ErrorModal,
  SidebarStickyBottom,
  SidebarTitle,
  SpinnerOverlay
} from "@ailo/ui";
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect
} from "react";
import { ScrollView, StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { RentReviewSection } from "./RentReviewSection";
import { RentReviewConfirmModal } from "./RentReviewConfirmModal";
import { RentReviewMode, RentReviewPrefill } from "./RentReviewFormData";
import { useGetRentReviewData } from "./useGetRentReviewData";
import { useAnalytics } from "@ailo/services";
import { merge } from "lodash";
import {
  RentReviewFormProvider,
  useRentReviewForm
} from "./RentReviewFormContext";

type RentReviewFormProps = {
  tenancyId: string;
  prefill?: RentReviewPrefill;
} & RentReviewContainerProps;

export function RentReviewForm({
  tenancyId,
  prefill,
  ...containerProps
}: RentReviewFormProps): ReactElement {
  const { data, loading, refetch } = useGetRentReviewData({
    tenancyId,
    mode: containerProps.mode
  });

  return (
    <RentReviewContainer {...containerProps}>
      {loading && !data ? (
        <RentReviewFormContent.Loading />
      ) : !data ? (
        <RentReviewFormContent.Error onRetry={refetch} />
      ) : (
        <RentReviewFormProvider
          prefill={merge({ tenancyId }, data.prefill, prefill)}
          data={data}
        >
          <RentReviewFormContent onDismiss={containerProps.onDismiss} />
        </RentReviewFormProvider>
      )}
    </RentReviewContainer>
  );
}

function RentReviewFormContent({
  onDismiss
}: {
  onDismiss: () => void;
}): ReactElement {
  const analytics = useAnalytics();
  const [showConfirmModal, setShowConfirmModal] = useMountedState(false);
  const [showErrorModal, setShowErrorModal] = useMountedState(false);
  const {
    formState: { isValid, isSubmitting },
    trigger
  } = useRentReviewForm();

  useEffect(() => {
    analytics.trackScreenVisited("Rent Review");
  }, [analytics]);

  const handleSubmit = useCallback(() => {
    if (!isValid) {
      trigger();
    } else {
      setShowConfirmModal(true);
    }
  }, [isValid, setShowConfirmModal, trigger]);

  return (
    <>
      <FormContainer>
        <RentReviewSection />
      </FormContainer>
      <SidebarStickyBottom>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Button.Secondary onPress={onDismiss}>{"Cancel"}</Button.Secondary>
          <Button.Primary onPress={handleSubmit} disabled={isSubmitting}>
            {"Confirm Details"}
          </Button.Primary>
        </View>
      </SidebarStickyBottom>

      <RentReviewConfirmModal
        visible={showConfirmModal && !isSubmitting}
        onSuccess={(): void => {
          setShowConfirmModal(false);
          onDismiss();
        }}
        onError={(): void => {
          setShowErrorModal(true);
          setShowConfirmModal(false);
        }}
        onCancel={(): void => setShowConfirmModal(false)}
      />

      <ErrorModal
        visible={showErrorModal}
        onDismiss={(): void => setShowErrorModal(false)}
      />

      <SpinnerOverlay visible={isSubmitting} />
    </>
  );
}

RentReviewFormContent.Loading = function Loading(): ReactElement {
  return (
    <FormContainer>
      <RentReviewSection.Loading />
    </FormContainer>
  );
};

RentReviewFormContent.Error = function Error({
  onRetry
}: {
  onRetry: () => void;
}): ReactElement {
  return (
    <FormContainer>
      <ErrorAlertScreen
        variant={"sidebar"}
        title={"There was a problem loading\nRent Review"}
        onRetry={onRetry}
      />
    </FormContainer>
  );
};

interface RentReviewContainerProps {
  mode: RentReviewMode;
  onDismiss: () => void;
  style?: StyleProp<ViewStyle>;
}

function RentReviewContainer({
  mode,
  onDismiss,
  style,
  children
}: PropsWithChildren<RentReviewContainerProps>): ReactElement {
  return (
    <View style={[{ flex: 1 }, style]}>
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        <SidebarTitle
          title={mode === "create" ? "Rent Review" : "Edit Rent Review"}
          closeButton
          onClose={onDismiss}
        />
        {children}
      </ScrollView>
    </View>
  );
}

const FormContainer = styled(View)`
  padding: 0 32px 60px;
`;
