import { useHasFeature } from "@ailo/domains";
import { formatProjectType, MultiSelectButton } from "@ailo/ui";
import { PlatformFeatureId, ProjectType } from "local/graphql";
import React, { useCallback, useMemo } from "react";
import { isPresent } from "ts-is-present";

type FilterProps = {
  onChange: (selectedValues: ProjectType[]) => void;
  values: string[];
};

export function ProjectTypesFilter({
  onChange,
  values
}: FilterProps): React.ReactElement {
  const hasNewTenancyProjectFeature = useHasFeature(
    PlatformFeatureId.CreateNewTenancyProject
  );
  const hasLeaseRenewalProjectFeature = useHasFeature(
    PlatformFeatureId.CreateLeaseRenewalProject
  );
  const hasNewManagementProjectFeature = useHasFeature(
    PlatformFeatureId.CreateNewManagementProject
  );
  const hasGeneralProjectFeature = useHasFeature(
    PlatformFeatureId.CreateGeneralProject
  );
  const hasVacatingTenancyProjectFeature = useHasFeature(
    PlatformFeatureId.CreateVacatingTenancyProject
  );
  const routineInspectionProjectFeature = useHasFeature(
    PlatformFeatureId.CreateRoutineInspectionProject
  );

  const newTenancyOption = hasNewTenancyProjectFeature
    ? getOption(ProjectType.NewTenancy)
    : undefined;
  const leaseRenewalOption = hasLeaseRenewalProjectFeature
    ? getOption(ProjectType.LeaseRenewal)
    : undefined;
  const newManagementOption = hasNewManagementProjectFeature
    ? getOption(ProjectType.NewManagement)
    : undefined;
  const generalOption = hasGeneralProjectFeature
    ? getOption(ProjectType.General)
    : undefined;
  const vacatingTenancyOption = hasVacatingTenancyProjectFeature
    ? getOption(ProjectType.VacatingTenancy)
    : undefined;
  const routineInspection = routineInspectionProjectFeature
    ? getOption(ProjectType.RoutineInspection)
    : undefined;

  const options = [
    generalOption,
    leaseRenewalOption,
    newManagementOption,
    newTenancyOption,
    routineInspection,
    vacatingTenancyOption
  ].filter(isPresent);
  const optionGroups = useMemo(() => [{ label: "", options }], [options]);
  const selectedOptions = useMemo(
    () =>
      values
        .map((value) => {
          return options.find((option) => value === option.value);
        })
        .filter(isPresent),
    [options, values]
  );
  const onChangeOption = useCallback(
    (options: { label: string; value: string }[]) => {
      onChange(options.map((opt) => opt.value).filter(isProjectType));
    },
    [onChange]
  );

  return (
    <MultiSelectButton
      entityType={"Project types"}
      optionGroups={optionGroups}
      value={selectedOptions}
      onChange={onChangeOption}
    />
  );
}

function isProjectType(str: string): str is ProjectType {
  return str in ProjectType;
}

function getOption(type: ProjectType): { label: string; value: ProjectType } {
  return {
    label: formatProjectType(type),
    value: type
  };
}
