import { AiloRN, services } from "@ailo/ailorn";
import { useHasFeature } from "@ailo/domains";
import { CheckboxInput, formatPercentage } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";
import {
  PlatformFeatureId,
  useGetManagementCurrentOrNextFeeScheduleQuery
} from "local/graphql";

export function ApplyManagementFeeCheckboxInput({
  managementId: managementIdProp,
  style
}: {
  managementId?: string;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement | null {
  const { control, watch, setValue } = useFormContext<AddBillFormData>();
  const hasFeature = useHasFeature(PlatformFeatureId.BillManagementFee);
  const payeeAiloRN = watch("supplierReference")?.value;
  const payerOption = watch("payerId");
  const managementId = managementIdProp || watch("managementId")?.value;

  const result = useGetManagementCurrentOrNextFeeScheduleQuery({
    variables: managementId
      ? {
          managementId
        }
      : undefined,
    skip: !hasFeature || !managementId
  });
  const feeSchedule = result.data?.management?.managementFeeSchedule;

  const visible =
    hasFeature &&
    !!managementId &&
    !!feeSchedule?.percent &&
    payerOption?.type === "tenancy" &&
    payeeAiloRN &&
    AiloRN.from(payeeAiloRN).isA(services.PropertyManagement.management);

  useEffect(() => {
    if (!visible) {
      setValue("applyManagementFee", false);
    }
  }, [visible, setValue]);

  if (!visible) {
    return null;
  }

  return (
    <View style={style}>
      <Controller
        name={"applyManagementFee"}
        defaultValue={false}
        control={control}
        render={({ onChange, value }) => (
          <CheckboxInput
            testID={"ApplyManagementFeeCheckboxInput"}
            label={`Charge management fee ${
              feeSchedule!.percent
                ? `(${formatPercentage(feeSchedule!.percent)})`
                : ""
            } to investor once bill is paid.`}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </View>
  );
}
