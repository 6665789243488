import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { throttle } from "lodash";
import { useRentReviewForm } from "../RentReviewFormContext";
import { RentReviewFields } from "../RentReviewFormData";
import {
  useReviewRentMutation,
  useEditRentReviewMutation
} from "local/graphql";

interface Props {
  onSuccess: () => void;
  onError: () => void;
}

export function useSubmit({
  onSuccess,
  onError
}: Props): () => Promise<void> | void {
  const { handleSubmit, data } = useRentReviewForm();
  const [reviewRent] = useReviewRentMutation();
  const [editRentReview] = useEditRentReviewMutation();

  return throttle(
    handleSubmit(
      async ({
        amount,
        leaseRenewalChecked,
        ...formDetails
      }: RentReviewFields) => {
        const rentDetails = {
          ...formDetails,
          amountInCents: amount.cents.toString()
        };

        try {
          if (data.mode === "edit") {
            throwIfMutationFailed(
              await editRentReview({
                variables: {
                  input: {
                    rentId: data.rentAilorn.internalId,
                    setsChargeDate: false,
                    ...rentDetails
                  }
                }
              }),
              { dataKey: "editRentReview" }
            );
          } else {
            throwIfMutationFailed(
              await reviewRent({
                variables: {
                  tenancyId: data.tenancyId,
                  rentDetails
                }
              }),
              { dataKey: "reviewRent" }
            );
          }
        } catch (error) {
          AiloSentry.captureException(error);
          onError();
          return;
        }

        onSuccess();
      }
    ),
    500
  );
}
