import React from "react";
import { Badge, CellContainer } from "@ailo/ui";
import { useGetDaysInArrearsQuery } from "local/graphql";
import { ErrorCell } from "./ErrorCell";
import { BasePropertyListRow } from "../BasePropertyListRow";

export function DaysInArrearsCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetDaysInArrearsQuery({
    variables: { managementId }
  });

  if (loading && !data) return <Loading />;

  const liability = data?.management?.mostRecentTenancy?.liability;
  if (!liability)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const daysInArrears = liability.daysInArrears;

  return (
    <CellContainer>
      <Badge type={"critical"}>
        {`${daysInArrears} ${Math.abs(daysInArrears) !== 1 ? "days" : "day"}`}
      </Badge>
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer style={{ alignItems: "flex-end" }}>
      <Badge.Loading
        style={{
          width: 60
        }}
      />
    </CellContainer>
  );
}

DaysInArrearsCell.Loading = Loading;
