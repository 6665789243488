import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import {
  ManagementFragment,
  useCompleteAssignNewManagementActionMutation
} from "local/graphql";
import { useCallback } from "react";

export interface CompleteAssignNewManagementActionInput {
  management: ManagementFragment;
  actionId: string;
}

export function useCompleteAssignNewManagementAction(): {
  completeAssignNewManagementAction: (
    input: CompleteAssignNewManagementActionInput
  ) => Promise<void>;
} {
  const toast = useToastContext();

  const [completeAssignNewManagementActionMutation] =
    useCompleteAssignNewManagementActionMutation();

  const completeAssignNewManagementAction = useCallback(
    async ({
      management,
      actionId
    }: CompleteAssignNewManagementActionInput): Promise<void> => {
      try {
        const result = await completeAssignNewManagementActionMutation({
          variables: {
            input: {
              id: actionId,
              managementAilorn: management.ailoRN.toString()
            }
          }
        });
        throwIfMutationFailed(result, {
          dataKey: "completeAssignNewManagementAction"
        });
      } catch (error) {
        AiloSentry.captureException(error);
        toast.showFormSubmitError();
      }
    },
    [completeAssignNewManagementActionMutation, toast]
  );

  return { completeAssignNewManagementAction };
}
