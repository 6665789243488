import { Colors, opacify } from "@ailo/primitives";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";

export function BaseModal({
  children,
  style
}: {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return <ContentWrapper style={style}>{children}</ContentWrapper>;
}

const ContentWrapper = styled(View)`
  flex: 1;
  width: 100vw;
  max-width: 1280px;
  background-color: ${Colors.WHITE};
  border-radius: 12px;
  box-shadow: 0 1px 20px ${opacify(Colors.BLACK, 0.4)};
  height: 100vh;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
`;
