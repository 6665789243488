import moment, { Moment } from "moment";
import { TenancyWithStringDates, Tenancy } from "./BondErrorAlert";
import { isPresent } from "ts-is-present";

export function parseTenancies(
  unparsedTenancies: TenancyWithStringDates[]
): Tenancy[] {
  const protoTenancies = unparsedTenancies.filter(isPresent);

  const tenancies = protoTenancies.map(stringDatesToTenancy);

  return sortTenancies(tenancies);
}

function getFundsReceived(
  stringFundsReceived?: { receivedAt: string }[]
): { receivedAt: Moment }[] | undefined {
  return stringFundsReceived
    ?.map(({ receivedAt }) => {
      return { receivedAt: moment(receivedAt) };
    })
    .sort(dateDifference);
}

function stringDatesToTenancy(protoTenancy: TenancyWithStringDates): Tenancy {
  const mappedBond = protoTenancy.bond
    ? {
        ...protoTenancy.bond,
        fundsReceived: getFundsReceived(protoTenancy.bond?.fundsReceived)
      }
    : undefined;

  return {
    ...protoTenancy,
    bond: mappedBond
  };
}

function dateDifference(
  a: { receivedAt: Moment },
  b: { receivedAt: Moment }
): number {
  return a.receivedAt.diff(b.receivedAt);
}

function sortTenancies(tenancies: Tenancy[]): Tenancy[] {
  return tenancies.sort(
    (a: Tenancy, b: Tenancy) =>
      a.bond?.fundsReceived?.[0]?.receivedAt?.diff(
        b.bond?.fundsReceived?.[0]?.receivedAt
      ) || 0
  );
}
