import React, { useCallback } from "react";
import { BasicContactsList } from "../BasicContactsList";
import { ContactsTabView, Screens, useNavigation } from "local/common";
import { CustomersListContent } from "./CustomersListContent";

function CustomersList({
  tabNavigation
}: {
  tabNavigation: (queryString?: string) => React.ReactElement;
}): React.ReactElement {
  const navigation = useNavigation<Screens.ContactsTab>();
  const clearSidebarInfo = useCallback(() => {
    navigation.navigate(Screens.ContactsTab, {
      tab: ContactsTabView.CUSTOMERS
    });
  }, [navigation]);

  const onCustomerPress = (
    id: string,
    contactType?: "Person" | "Company"
  ): void => {
    navigation.navigate(Screens.ContactsTab, {
      tab: ContactsTabView.CUSTOMERS,
      contactId: id,
      contactType
    });
  };

  const renderListContent = ({
    queryString,
    header
  }: {
    queryString: string;
    header: (showSearchBar?: boolean) => React.ReactElement;
  }): React.ReactElement => {
    return (
      <CustomersListContent
        header={header}
        queryString={queryString}
        onCustomerPress={onCustomerPress}
      />
    );
  };

  return (
    <BasicContactsList
      tabNavigation={tabNavigation}
      tabName={"Customer"}
      clearSidebarInfo={clearSidebarInfo}
      ContactsListContent={renderListContent}
    />
  );
}

export { CustomersList };
