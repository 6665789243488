import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import {
  FormerOrVoidedTenancyFragment,
  useGetFormerAndVoidedTenanciesQuery
} from "local/graphql";
import { sortTenanciesByEndDateOrVoidedAt } from "./utils/sortTenanciesByEndDateOrVoidedAt";

export function useGetFormerAndVoidedTenancies(
  managementId: string
): MappedQueryResult<FormerOrVoidedTenancyFragment[]> {
  return useMappedQueryResult(
    useGetFormerAndVoidedTenanciesQuery({
      variables: {
        managementId
      }
    }),
    (data) => {
      if (
        !data?.management ||
        !data.management.formerTenancies ||
        !data.management.voidedTenancies
      ) {
        throw new ApolloError({
          errorMessage: `Failed to load management former tenancies and voided tenancies`
        });
      }

      const tenancies: FormerOrVoidedTenancyFragment[] = [];

      data.management.formerTenancies.items.forEach((former) =>
        tenancies.push(former)
      );
      data.management.voidedTenancies.items.forEach((voided) =>
        tenancies.push(voided)
      );

      return sortTenanciesByEndDateOrVoidedAt(tenancies);
    }
  );
}
