import {
  Colors,
  Pressable,
  PressableStyle,
  usePressableStyle
} from "@ailo/primitives";
import { InspectionDay } from "local/domain/inspection";
import React, { useCallback } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { DayOfMonth } from "./DayOfMonth";
import { useInspectionPlanner } from "../../InspectionPlannerContext";
import { InspectionDayListItemHeader } from "./InspectionDayListItemHeader";
import { InspectionDayListItemSubheader } from "./InspectionDayListItemSubheader";

interface Props {
  inspectionDay: InspectionDay;
  style?: StyleProp<ViewStyle>;
}

export function InspectionDayListItem({
  inspectionDay,
  style
}: Props): React.ReactElement {
  const { date } = inspectionDay;
  const { setSelectedInspectionDate, selectedInspectionDate } =
    useInspectionPlanner();
  const isSelected = selectedInspectionDate?.isSame(date) || false;

  const pressableStyle = usePressableStyle(
    {
      ...CONTAINER_STYLE,
      ...(isSelected ? SELECTED_BACKGROUND_STYLE : DEFAULT_BACKGROUND_STYLE)
    },
    style
  );

  const onPress = useCallback(() => {
    if (isSelected) {
      setSelectedInspectionDate(null);
      return;
    }
    setSelectedInspectionDate(date);
  }, [isSelected, setSelectedInspectionDate, date]);

  return (
    <Pressable style={pressableStyle} onPress={onPress}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <DayOfMonth date={date} />
        <View style={{ marginLeft: 16 }}>
          <InspectionDayListItemHeader inspectionDay={inspectionDay} />
          <InspectionDayListItemSubheader inspectionDay={inspectionDay} />
        </View>
      </View>
    </Pressable>
  );
}

const DEFAULT_BACKGROUND_STYLE: PressableStyle = {
  backgroundColor: {
    default: Colors.WHITE,
    pressed: Colors.INTERACTION.WHITE.PRESSED,
    hovered: Colors.INTERACTION.WHITE.HOVER
  }
};

const SELECTED_BACKGROUND_STYLE: PressableStyle = {
  backgroundColor: Colors.INTERACTION.WHITE.PRESSED
};

const CONTAINER_STYLE = {
  borderRadius: 4,
  paddingVertical: 8,
  paddingHorizontal: 12
};

InspectionDayListItem.Loading = function Loading({
  color,
  style
}: {
  color?: string;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <View
      style={[
        {
          flexDirection: "row",
          alignItems: "center",
          ...CONTAINER_STYLE
        },
        style
      ]}
    >
      <DayOfMonth.Loading color={color} />
      <View style={{ marginLeft: 16 }}>
        <InspectionDayListItemHeader.Loading color={color} />
        <InspectionDayListItemSubheader.Loading color={color} />
      </View>
    </View>
  );
};
