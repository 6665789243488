import { TeamFilterInput } from "@ailo/domains";
import { GridSortOrder } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import {
  SearchTenanciesReturn,
  useSearchTenancies,
  useSelectedTeams
} from "local/domain/propertyManagement";
import {
  Address,
  SortDirection,
  TenanciesSortField,
  TenanciesSortParams
} from "local/graphql";
import { FilterStateType } from "./filters";
import { UnscheduledTenanciesColumns } from "./TenanciesGrid/useGetTenanciesGridColumns";

export interface UnscheduledTenanciesListRow {
  propertyAddress: Address;
  lastInspectionDate?: string;
  key: string;
  dueDate?: string;
}

type GetUnscheduledTenanciesRowsResult = Omit<SearchTenanciesReturn, "data"> & {
  data: UnscheduledTenanciesListRow[];
};

export function useGetUnscheduledTenanciesRows({
  sortOrder,
  filterState
}: {
  sortOrder?: GridSortOrder;
  filterState?: FilterStateType;
}): GetUnscheduledTenanciesRowsResult {
  const { ailoRN: organisationAilorn } = useCurrentAgencyOrg();
  const {
    propertyListSelectedTeamIds: teamIds,
    propertyListUnassignedSelected: unassignedSelected
  } = useSelectedTeams();
  const query = filterState?.propertyAddress;

  const team: TeamFilterInput | undefined =
    teamIds.length > 0 || unassignedSelected
      ? {
          ailornIn: teamIds,
          includeUnassigned: unassignedSelected
        }
      : undefined;

  const { data, ...rest } = useSearchTenancies({
    filters: {
      query,
      team,
      managingEntityOrgAilorn: organisationAilorn,
      excludeWithPendingRoutineAppointment: true
    },
    sort: generateSortOrder(sortOrder),
    pageSize: 50
  });

  return {
    data: data
      ? data.items.map((tenancy) => ({
          key: tenancy.id,
          propertyAddress: tenancy.property.address,
          lastInspectionDate:
            tenancy.lastCompletedRoutineInspection?.completedAt || "",
          dueDate: tenancy.routineInspectionDueDate || ""
        }))
      : [],
    ...rest
  };
}

function generateSortOrder(sortOrder?: GridSortOrder): TenanciesSortParams[] {
  let field: TenanciesSortField | undefined;
  switch (sortOrder?.columnKey) {
    case UnscheduledTenanciesColumns.InspectionDue:
    default:
      field = TenanciesSortField.RoutineInspectionDueDate;
      break;
  }

  return [
    {
      field,
      direction:
        sortOrder?.direction === "DESC" ? SortDirection.Desc : SortDirection.Asc
    }
  ];
}
