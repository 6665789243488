import React, { ReactElement } from "react";
import { Colors, DateRange, Text } from "@ailo/primitives";
import {
  EndTenancyFormData,
  EndTenancyFormFields
} from "../EndTenancyFormData";
import { Controller, useFormContext } from "react-hook-form";
import { DateInputFormField } from "@ailo/ui";

type TenancyEndDateProps = {
  validEndDateRange?: DateRange;
  tenancyEndDate?: string;
  tenancyAgreementDateInfo?: string;
  nextTenancyDateInfo?: string;
};

export function EndTenancyDateInput({
  validEndDateRange,
  tenancyEndDate,
  tenancyAgreementDateInfo,
  nextTenancyDateInfo
}: TenancyEndDateProps): ReactElement {
  const { control, errors } = useFormContext<EndTenancyFormData>();

  return (
    <Controller
      name={EndTenancyFormFields.endDate}
      render={({ value, onChange, onBlur }): ReactElement => {
        return (
          <>
            <DateInputFormField
              webPopoverHorizontalAlign={"start"}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              label={"Tenancy end date"}
              placeholder={"Select date..."}
              autoCorrect={false}
              autoCompleteType={"off"}
              minDate={validEndDateRange?.startDate?.format("YYYY-MM-DD")}
              maxDate={validEndDateRange?.endDate?.format("YYYY-MM-DD")}
              error={errors.endDate?.message}
              style={{ marginBottom: 4 }}
            />
            {tenancyAgreementDateInfo && (
              <Text.BodyXS color={Colors.TEXT.DARK.SECONDARY}>
                {tenancyAgreementDateInfo}
                {nextTenancyDateInfo && `. ${nextTenancyDateInfo}`}
              </Text.BodyXS>
            )}
          </>
        );
      }}
      defaultValue={tenancyEndDate}
      control={control}
      rules={{
        required: "Please select a end date"
      }}
    />
  );
}
