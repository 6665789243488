import { Text } from "@ailo/primitives";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { PersonVerificationIcon } from "local/domain/authz";

interface Props {
  firstName: string;
  lastName?: string | null;
  verified?: boolean;
}

export function PersonPrimaryDetails({
  firstName,
  lastName,
  verified
}: Props): React.ReactElement {
  return (
    <DetailsContainer
      style={{
        flexDirection: "row",
        alignItems: "center",
        //high z-index to ensure tooltip is on top
        zIndex: 10
      }}
    >
      <Text.BodyM weight={"medium"} style={{ marginRight: 8 }}>
        {getFullName(firstName, lastName)}
      </Text.BodyM>
      <PersonVerificationIcon verified={verified} height={20} width={20} />
    </DetailsContainer>
  );
}

const DetailsContainer = styled(View)`
  flex-direction: row;
  align-items: center;
`;

function getFullName(firstName: string, lastName?: string | null): string {
  return [firstName, lastName].filter(Boolean).join(" ");
}
