import React, { useCallback, useMemo } from "react";
import {
  Button,
  DropdownMenu,
  DropdownMenuOption,
  DropdownMenuOptionGroup,
  DropdownMenuToggleProps
} from "@ailo/ui";
import {
  Colors,
  DownCaretIcon,
  IconSkeleton,
  PortfolioIcon,
  TeamIcon,
  Text
} from "@ailo/primitives";
import { View } from "react-native";
import styled from "styled-components/native";
import { partition } from "lodash";

interface Team {
  id: string;
  name: string;
  myTeam: boolean;
}

interface Props {
  allTeams: Team[];
  assignedTeamId?: string;
  onTeamChange?: (teamDetails: { teamId: string; teamName: string }) => void;
}

export function TeamDropdownMenu({
  allTeams,
  assignedTeamId,
  onTeamChange
}: Props): React.ReactElement {
  const teamToDropdownOption = useCallback(
    (team: Team): DropdownMenuOption => ({
      label: team.name,
      icon: <TeamIcon color={Colors.TEXT.DARK.SECONDARY} />,
      onSelect: (): void => {
        onTeamChange?.({ teamId: team.id, teamName: team.name });
      },
      disabled: team.id === assignedTeamId
    }),
    [assignedTeamId, onTeamChange]
  );

  const optionGroups: DropdownMenuOptionGroup[] = useMemo(() => {
    const [myTeams, otherTeams] = partition(allTeams, "myTeam");
    return [
      {
        type: "group",
        groupLabel: "My teams",
        options: myTeams.map(teamToDropdownOption)
      },
      {
        type: "group",
        groupLabel: "Teams",
        options: otherTeams.map(teamToDropdownOption)
      }
    ];
  }, [allTeams, teamToDropdownOption]);

  const renderLeftIcon = useCallback(
    () => <DropdownButtonLeftIcon isUnassigned={assignedTeamId == null} />,
    [assignedTeamId]
  );

  const renderDropdownButton = useCallback(
    ({ toggle }: DropdownMenuToggleProps) => {
      return (
        <Button.Text
          onPress={toggle}
          leftIcon={renderLeftIcon}
          rightIcon={DownCaretIcon}
          contentColor={Colors.TEXT.DARK.PRIMARY}
        >
          {assignedTeamNameFromId({ assignedTeamId, allTeams })}
        </Button.Text>
      );
    },
    [assignedTeamId, allTeams, renderLeftIcon]
  );

  return (
    <DropdownMenu
      options={optionGroups}
      renderToggle={renderDropdownButton}
      itemSize={"small"}
    />
  );
}

TeamDropdownMenu.Loading = function Loading(): React.ReactElement {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <IconSkeleton style={{ borderRadius: 4 }} />
      <Text.BodyS.Loading width={120} style={{ marginLeft: 8 }} />
    </View>
  );
};

TeamDropdownMenu.Error = function Error({
  onReload
}: {
  onReload?: () => void;
}): React.ReactElement {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
        {"Failed to load"}
      </Text.BodyS>
      <Button.Text style={{ marginLeft: 8 }} onPress={onReload}>
        <Text.BodyS weight={"medium"}>{"Reload"}</Text.BodyS>
      </Button.Text>
    </View>
  );
};

function DropdownButtonLeftIcon({
  isUnassigned
}: {
  isUnassigned: boolean;
}): React.ReactElement {
  return (
    <Container>
      {isUnassigned ? (
        <PortfolioIcon
          width={16}
          height={16}
          color={Colors.TEXT.DARK.SECONDARY}
        />
      ) : (
        <TeamIcon width={16} height={16} color={Colors.TEXT.DARK.SECONDARY} />
      )}
    </Container>
  );
}

const Container = styled(View)`
  border: 1px solid ${Colors.GRAYSCALE.OUTLINE};
  border-radius: 4px;
  margin-right: 4px;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
`;

function assignedTeamNameFromId({
  assignedTeamId,
  allTeams
}: {
  assignedTeamId?: string;
  allTeams: { id: string; name: string }[];
}): string {
  if (assignedTeamId == null) return "Unassigned";

  const assignedTeam = allTeams.find(({ id }) => id === assignedTeamId);

  // we already have validation to make sure that if there is assigned team id,
  // it must be in the team list for managements in useGetManagementSummary
  return assignedTeam?.name || "Unassigned";
}
