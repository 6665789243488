import { DescriptionList, EditButton, Money, Separator } from "@ailo/ui";
import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { UseFormMethods } from "react-hook-form";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { formatDate } from "@ailo/services";
import { CreateRentAdjustmentFormData } from "../CreateRentAdjustmentForm";

const RentCreditEditContainer = styled(View)`
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: space-between;
`;

export function ReviewRentAdjustmentStep({
  form,
  display,
  setCurrentStepNumber
}: {
  form: UseFormMethods<CreateRentAdjustmentFormData>;
  display: boolean;
  setCurrentStepNumber: Dispatch<SetStateAction<number>>;
}): ReactElement {
  const amount = form.watch("amount");
  const description = form.watch("description");
  const effectiveDate = form.watch("effectiveDate");

  return (
    <View style={{ display: display ? undefined : "none" }}>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
        {"Please confirm the details are correct"}
      </Text.BodyM>
      <Separator
        style={{
          marginTop: 32,
          marginBottom: 32,
          marginLeft: 0,
          marginRight: -60
        }}
      />
      <RentCreditEditContainer>
        <Text.BodyL color={Colors.TEXT.DARK.PRIMARY} weight={"medium"}>
          {"Rent Credit"}
        </Text.BodyL>
        <EditButton onPress={() => setCurrentStepNumber(1)} />
      </RentCreditEditContainer>
      <DescriptionList
        size={"small"}
        columns={2}
        items={[
          {
            label: "Credit amount",
            descriptionBottom: Money.from(amount ?? { cents: 0 }).format(),
            columnIndex: 0
          },
          {
            label: "Credit date",
            descriptionBottom: effectiveDate
              ? formatDate(effectiveDate)
              : "N/A",
            columnIndex: 1
          }
        ]}
      />
      <DescriptionList
        size={"small"}
        items={[
          {
            label: "Description",
            descriptionBottom: description
          }
        ]}
        style={{ marginTop: 16 }}
      />
    </View>
  );
}
