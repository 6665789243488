import React, { memo, ReactElement } from "react";
import { useModalVisibility } from "@ailo/ui";
import { ComposeMessageModal } from "@ailo/domains";
import { ActionType } from "local/graphql";
import { RefinedActionActivityProps } from "../ActionActivityProps";
import { BaseActionActivity } from "../BaseActionActivity";
import { useMessageData } from "./useMessageData";

function AssignMessageActionActivityComponent({
  action
}: RefinedActionActivityProps<ActionType.AssignMessage>): ReactElement {
  const { show, hide, isVisible } = useModalVisibility();
  const messageData = useMessageData(action);

  return (
    <>
      {isVisible && (
        <ComposeMessageModal
          visible={isVisible}
          onCancelPress={hide}
          onSendSuccess={hide}
          {...messageData}
        />
      )}
      <BaseActionActivity
        description={"Send a message via chat, email or both"}
        button={{
          label: "Compose message",
          onPress: show
        }}
      />
    </>
  );
}

export const AssignMessageActionActivity = memo(
  AssignMessageActionActivityComponent
);
