import { LocalDate } from "@ailo/date";
import {
  sortTenancyByEndDateDesc,
  TenancyWithEndDate
} from "./sortTenanciesByEndDateDesc";

export function getLastEndedTenancyEndDate<T extends TenancyWithEndDate>(
  tenancies: T[]
): LocalDate | undefined {
  const sortedTenancies = sortTenancyByEndDateDesc(tenancies);
  if (sortedTenancies[0] && sortedTenancies[0].endDate) {
    return LocalDate.from(sortedTenancies[0].endDate);
  }
  return undefined;
}
