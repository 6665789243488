import { Text } from "@ailo/primitives";
import { formatPercentage, Money } from "@ailo/ui";
import { BillAgencyStatus } from "local/graphql";
import React, { ReactElement } from "react";
import { ExpenseMergedType } from "../../graphql/useGetExpense";

type ExpenseManagementFeeProps = {
  expense?: Pick<
    ExpenseMergedType,
    "fees" | "applyManagementFee" | "management" | "status" | "expenseType"
  >;
  loading?: boolean;
};

export function ExpenseManagementFeeDescription({
  expense,
  loading
}: ExpenseManagementFeeProps): ReactElement | null {
  if (loading || !expense?.fees) {
    return null;
  }

  const fee = expense.fees.items[0];
  if (fee) {
    return (
      <Text.BodyS style={{ marginTop: 16, marginHorizontal: 32 }}>
        {"A management fee ("}
        {fee.percentage
          ? formatPercentage(fee.percentage)
          : Money.from(fee.amount).format()}
        {") was charged to investor."}
      </Text.BodyS>
    );
  }

  const applyManagementFee = expense.applyManagementFee;
  const feeSchedule = expense.management!.currentOrNextManagementFeeSchedule;
  if (
    !applyManagementFee ||
    !feeSchedule ||
    !feeSchedule.percent ||
    expense.status === BillAgencyStatus.Cancelled
  ) {
    return null;
  }

  return (
    <Text.BodyS style={{ marginTop: 16, marginHorizontal: 32 }}>
      {"A management fee ("}
      {formatPercentage(feeSchedule.percent)}
      {") will be charged"}
      {"to investor once bill is paid."}
    </Text.BodyS>
  );
}
