import { Colors, RightChevronIcon, Text } from "@ailo/primitives";
import {
  ListItem,
  ListItemStyle,
  PersonAvatar,
  PersonAvatarProps
} from "@ailo/ui";
import React from "react";
import { PersonPrimaryDetails } from "./PersonPrimaryDetails";
import { PhoneNumberEmailDisplay } from "./PhoneNumberEmailDisplay";

export interface PersonListItemProps {
  person: PersonAvatarProps["person"] & {
    phoneNo?: string | null;
    emailAddress?: string | null;
    verified: boolean;
  };
  style?: ListItemStyle;
  onPress?: () => void;
}

function PersonListItem({
  person,
  style,
  onPress
}: PersonListItemProps): React.ReactElement {
  const { firstName, lastName, phoneNo, emailAddress, verified } = person;

  return (
    <ListItem
      header={
        <PersonPrimaryDetails
          firstName={firstName}
          lastName={lastName}
          verified={verified}
        />
      }
      subHeader={
        <PhoneNumberEmailDisplay
          phoneNo={phoneNo}
          emailAddress={emailAddress}
        />
      }
      leftComponent={
        <PersonAvatar
          size={40}
          person={person}
          initialsColor={Colors.TEXT.LIGHT.PRIMARY}
          style={{ marginRight: 16 }}
        />
      }
      style={style}
      onPress={onPress}
      rightComponent={
        onPress ? <RightChevronIcon height={20} width={20} /> : undefined
      }
    />
  );
}

const Loading: React.FC<{ style?: ListItemStyle }> = ({ style }) => (
  <ListItem
    header={<Text.BodyS.Loading width={120} />}
    subHeader={<Text.BodyS.Loading width={80} />}
    leftComponent={
      <PersonAvatar.Loading style={{ marginRight: 16 }} size={40} />
    }
    style={style}
  />
);

PersonListItem.Loading = Loading;

export { PersonListItem };
