import { isReactText, Text } from "@ailo/primitives";
import {
  Alert,
  BaseModal as UIBaseModal,
  BaseModalProps as UIBaseModalProps
} from "@ailo/ui";
import React, { memo, ReactElement } from "react";
import { ModalFooter, PleaseWaitTitle } from "../../components";

import { ReconciliationPeriod } from "../types";

export type ConfiguredModalProps = UIBaseModalProps & {
  period: ReconciliationPeriod;
  onCancelPress?: () => void;
};

type BaseModalProps = ConfiguredModalProps & {
  errorText?: string;
  loading: boolean;
  cancelText?: string;
  actionText: string;
  onActionPress?: () => void;
};
function LockUnlockBaseModalComponent({
  errorText,
  loading,
  cancelText,
  actionText,
  onActionPress,
  onCancelPress,
  children,
  ...props
}: BaseModalProps): ReactElement {
  const title = errorText ? (
    "An error has occurred"
  ) : loading ? (
    <PleaseWaitTitle />
  ) : (
    `${actionText} ${props.period.monthName} ${props.period.year}`
  );

  const footer = (
    <ModalFooter
      cancel={cancelText}
      onCancelPress={onCancelPress}
      action={loading ? undefined : errorText ? "Retry" : actionText}
      onActionPress={onActionPress}
    />
  );

  return (
    <UIBaseModal title={title} footer={footer} showFooterBorder {...props}>
      {errorText ? (
        <Alert type={"warning"}>{errorText}</Alert>
      ) : isReactText(children) ? (
        <Text.BodyM>{children}</Text.BodyM>
      ) : (
        children
      )}
    </UIBaseModal>
  );
}

export const LockUnlockBaseModal = memo(LockUnlockBaseModalComponent);
