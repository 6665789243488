import React from "react";
import { InspectionFragment } from "local/graphql";
import { isPresent } from "ts-is-present";
import styled from "styled-components/native";
import { Image, View } from "react-native";
import { opacify, Colors } from "@ailo/primitives";

export function InspectionThumbnails({
  inspection
}: {
  inspection: InspectionFragment;
}): React.ReactElement {
  const thumbnails = getInspectionThumbnailURLs(inspection);

  return (
    <InspectionThumbnailsWrapper>
      {thumbnails.map((thumbnail, index) => (
        <InspectionThumbnail
          key={index}
          source={{
            uri: thumbnail
          }}
        />
      ))}
      <ThumbnailPlaceholders startFrom={thumbnails.length} />
    </InspectionThumbnailsWrapper>
  );
}

function ThumbnailPlaceholders({
  startFrom
}: {
  startFrom: number;
}): React.ReactElement {
  return (
    <>
      {[1, 0.8, 0.6, 0.4].slice(startFrom, 4).map((opacity) => (
        <ThumbnailPlaceholder
          key={`placeholder-${opacity}`}
          style={{ opacity }}
        />
      ))}
    </>
  );
}

function getInspectionThumbnailURLs(inspection: InspectionFragment): string[] {
  const { areaFilesForThumbnail, featureFilesForThumbnail } = inspection;

  const thumbnails = areaFilesForThumbnail.length
    ? areaFilesForThumbnail
    : featureFilesForThumbnail.length
    ? featureFilesForThumbnail
    : [];
  return thumbnails
    .map((file) => file.file.thumbnailUrl)
    .filter(isPresent)
    .filter((url) => url.trim() !== "");
}

const InspectionThumbnailsWrapper = styled(View)`
  flex-direction: row;
  gap: 2;
`;

const ThumbnailPlaceholder = styled(View)`
  width: 20;
  height: 20;
  border-radius: 4;
  border: 1px solid ${opacify(Colors.SPACE_BLACK, 0.1)};
  background: ${Colors.CLOUD};
`;

const InspectionThumbnail = styled(Image)`
  width: 20;
  height: 20;
  border-radius: 4;
  background: ${Colors.CLOUD};
`;
