import React from "react";
import { Image } from "react-native";
import styled from "styled-components/native";
import { Text, DocumentWarning, SFC } from "@ailo/primitives";
import { Card } from "@ailo/ui";

export const EmptyBillGrid: SFC = ({ style }) => (
  <Container style={style}>
    <StyledImage source={DocumentWarning} />
    <StyledBodyL weight={"medium"}>
      {"There are no bills to display"}
    </StyledBodyL>
  </Container>
);

const Container = styled(Card)`
  justify-content: center;
  align-items: center;
  height: 284px;
`;

const StyledBodyL = styled(Text.BodyL)`
  margin-bottom: 8px;
  width: 280px;
  text-align: center;
  margin-top: 16px;
`;

const StyledImage = styled(Image)`
  width: 120px;
  height: 120px;
  align-self: center;
`;
