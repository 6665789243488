import React, { ReactElement, useCallback } from "react";
import { BaseActionActivity } from "../BaseActionActivity";
import { ActionType, ProjectType, RentFragment } from "local/graphql";
import { RefinedActionActivityProps } from "../ActionActivityProps";
import { useCompleteAssignRentAction } from "./useCompleteAssignRentAction";
import { ErrorAlertScreen, LocalDate, useGlobalModal } from "@ailo/ui";
import { RentReviewModalForm } from "local/modals/RentReviewModalForm";
import { isProjectOfTypeWithMeta } from "local/domain/project/project";
import { AiloSentry } from "@ailo/services";
import {
  DescriptionListData,
  InputDetailsSection
} from "../InputDetailsSection";
import { formatRentPaymentSchedule } from "@ailo/domains";
import { EditRentButton } from "./EditRentButton";

export function AssignRentActionActivity({
  action
}: RefinedActionActivityProps<ActionType.AssignRent>): ReactElement {
  const assignedRent = action.meta.rent;
  const { completeAssignRentAction } = useCompleteAssignRentAction();
  const modal = useGlobalModal();

  const onCreateSuccess = useCallback(
    async (rent: RentFragment) => {
      await completeAssignRentAction({ rent, actionId: action.id });
    },
    [action.id, completeAssignRentAction]
  );

  const project = action.project;

  if (!isProjectOfTypeWithMeta(project, ProjectType.LeaseRenewal)) {
    AiloSentry.captureException(
      new Error("Project is not of Lease Renewal type")
    );
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the action information."}
      />
    );
  }

  const projectTenancy = project.meta.leaseRenewalTenancy;
  const {
    id: tenancyId,
    editableRentReview,
    rentReviewAllowed
  } = projectTenancy;

  if (assignedRent) {
    return (
      <InputDetailsSection
        title={"Rent review"}
        headerRightColumn={
          <EditRentButton
            tenancyId={tenancyId}
            assignedRent={assignedRent}
            editableRent={editableRentReview?.rent}
          />
        }
        descriptionListData={getDescriptionListData({
          rent: assignedRent,
          scheduledRentReviewDate: assignedRent.tenancy?.scheduledRentReviewDate
        })}
      />
    );
  }

  return (
    <BaseActionActivity
      button={{
        label: "Enter rent review",
        onPress: () => {
          modal.show(
            <RentReviewModalForm
              tenancyId={tenancyId}
              onSuccess={onCreateSuccess}
            />
          );
        },
        disabled: !rentReviewAllowed
      }}
    />
  );
}

function getDescriptionListData({
  rent,
  scheduledRentReviewDate
}: {
  rent: RentFragment;
  scheduledRentReviewDate?: string | null;
}): DescriptionListData {
  return [
    {
      label: "Rent amount",
      description: formatRentPaymentSchedule(rent)
    },
    {
      label: "Effective date",
      description: new LocalDate(rent.effectiveDate).format("DD MMM YYYY")
    },

    {
      label: "Next rent review",
      description: scheduledRentReviewDate
        ? new LocalDate(scheduledRentReviewDate).format("DD MMM YYYY")
        : "N/A"
    }
  ];
}
