import React, { ReactElement } from "react";
import { ViewStyle } from "react-native";
import { Badge } from "@ailo/ui";

interface ProjectReferenceBadgeProps {
  reference: string;
  style?: ViewStyle;
}

export function ProjectReferenceBadge({
  reference,
  style
}: ProjectReferenceBadgeProps): ReactElement {
  return <Badge style={style}>{reference}</Badge>;
}

ProjectReferenceBadge.Loading = function ProjectReferenceBadgeLoading({
  style
}: {
  style?: ViewStyle;
}): React.ReactElement {
  return <Badge.Loading style={style} />;
};
