import {
  formatAddress,
  formatLegalEntityName,
  formatNames
} from "@ailo/domain-helpers";
import { Colors, Text } from "@ailo/primitives";
import { didQueryNotLoadYet, didQuerySucceed } from "@ailo/services";
import { useGetPropertyAddressAndInvestorsQuery } from "local/graphql";
import React, { ReactElement } from "react";
import { isPresent } from "ts-is-present";

interface ManagementFeeBlueprintsSidebarSubtitleProps {
  managementId: string;
}

export function ManagementFeeBlueprintsSidebarSubtitle({
  managementId
}: ManagementFeeBlueprintsSidebarSubtitleProps): ReactElement | null {
  const result = useGetPropertyAddressAndInvestorsQuery({
    variables: { id: managementId }
  });

  if (didQueryNotLoadYet(result)) {
    return <Text.BodyS.Loading width={200} />;
  }

  if (!didQuerySucceed(result) || !result.data.management?.owners) {
    return null;
  }

  const ownerNames = result.data.management?.owners
    .map((ownership) => ownership?.owner)
    .filter(isPresent)
    .map((owner) => formatLegalEntityName(owner, { onlyFirstName: true }));
  const ownerText = formatNames(ownerNames, { longForm: true });

  const address = result.data.management?.property.address;

  return (
    <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
      {"For "}
      {ownerText}
      {", "}
      {formatAddress(address, { format: "street, suburb" })}
    </Text.BodyS>
  );
}
