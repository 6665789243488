import { Colors, Text } from "@ailo/primitives";
import { formatDate } from "@ailo/services";
import { ListItem, ListItemStyle } from "@ailo/ui";
import React from "react";
import { Tenancy, TenancyType } from "../useGetManagementTenancies";

interface Props {
  tenancy: Tenancy;
  onPress?: () => void;
  style?: ListItemStyle;
}

export const TenancySwitcherItem: React.FC<Props> = ({
  style,
  tenancy,
  onPress
}) => {
  const timeRange = getTimeRange({
    id: tenancy.id,
    type: tenancy.type,
    start: tenancy.startDate,
    end: tenancy.endDate
  });

  const rightComponent =
    tenancy.type === TenancyType.FORMER ? (
      <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
        {timeRange}
      </Text.BodyS>
    ) : undefined;

  return (
    <ListItem
      onPress={onPress}
      style={style}
      header={<Text.BodyL weight={"book"}>{tenancy.type}</Text.BodyL>}
      rightComponent={rightComponent}
    />
  );
};

function getTimeRange({
  id,
  type,
  start,
  end
}: {
  id: string;
  type: TenancyType;
  start: string;
  end?: string;
}): string {
  if (type === TenancyType.ACTIVE) {
    if (end) return `Ending ${formatDate(end)}`;

    return `Started ${formatDate(start)}`;
  }

  if (type === TenancyType.INGOING) {
    return `Starting ${formatDate(start)}`;
  }

  // former tenancy
  if (end) return `${formatDate(start)} – ${formatDate(end)}`;
  else {
    throw new Error(`Former tenancy missing an end date: ${id}`);
  }
}
