import {
  BankAccountMethod,
  BankAccountTypeName,
  BPayMethod,
  BPayTypeName,
  useHasFeature
} from "@ailo/domains";
import { DefaultBPayIcon, SquareBankIcon } from "@ailo/primitives";
import { Alert, Button, DescriptionBox, FormField } from "@ailo/ui";
import {
  PaymentFormState,
  useGetSupplierPaymentMethod
} from "local/domain/bill/AddBillForm";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import { PlatformFeatureId } from "local/graphql";
import { isEmpty } from "lodash";
import React, { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";

type Props = {
  style?: StyleProp<ViewStyle>;
  onCreatePaymentMethodClick: () => void;
};

function getBankAccountDescription(paymentMethod: BankAccountMethod): string {
  const { accountNumber, bsb } = paymentMethod;
  return `BSB: ${bsb} · Acc: ${accountNumber}`;
}

function getBPayDescription(paymentMethod: BPayMethod): string {
  const { billerCode } = paymentMethod;
  return `BPAY Biller Code: ${billerCode}`;
}

export function PaymentDestinationInput({
  style,
  onCreatePaymentMethodClick
}: Props): React.ReactElement {
  const ref = useRef<View>(null);
  const { watch, register, unregister, setValue } =
    useFormContext<AddBillFormData>();

  const hasCreateSupplierFeature = useHasFeature(
    PlatformFeatureId.AgencyAppSupplierCreate
  );
  const selectedSupplier = watch("supplierReference");
  const supplierReference = selectedSupplier?.value;
  const { paymentFormState, paymentMethod } = useGetSupplierPaymentMethod({
    supplierReference
  });

  const isInternalSupplier = paymentFormState === PaymentFormState.INTERNAL;

  useEffect(() => {
    const paymentType =
      paymentFormState === PaymentFormState.BANK_ACCOUNT
        ? BankAccountTypeName
        : paymentFormState === PaymentFormState.BPAY
        ? BPayTypeName
        : undefined;

    setValue("payment.type", paymentType, { shouldValidate: true });
    if (paymentFormState === PaymentFormState.BPAY) {
      register(
        { name: "payment.billerCode" },
        {
          validate: (billerCode): boolean | string =>
            paymentFormState !== PaymentFormState.BPAY ||
            !isEmpty(billerCode) ||
            "Bpay payment method missing biller code"
        }
      );
      setValue("payment.billerCode", (paymentMethod as BPayMethod).billerCode, {
        shouldValidate: true
      });

      return (): void => unregister("payment.billerCode");
    }
  }, [setValue, register, paymentFormState, paymentMethod, unregister]);

  const description =
    paymentFormState === PaymentFormState.BANK_ACCOUNT
      ? getBankAccountDescription(paymentMethod as BankAccountMethod)
      : paymentFormState === PaymentFormState.BPAY
      ? getBPayDescription(paymentMethod as BPayMethod)
      : "";

  return (
    <Controller
      name={"payment.id"}
      defaultValue={null}
      rules={{
        required: !isInternalSupplier ? "Payment method required" : false,
        validate: () =>
          ![
            PaymentFormState.LOADING,
            PaymentFormState.NO_SELECTION,
            PaymentFormState.NO_PAYMENT_METHOD
          ].includes(paymentFormState) || "Payment method required"
      }}
      onFocus={() => ref.current?.focus()}
      render={() => (
        <View
          style={style}
          accessible={true}
          testID={"PaymentTypeFormGroup"}
          ref={ref}
        >
          {[PaymentFormState.BANK_ACCOUNT, PaymentFormState.BPAY].includes(
            paymentFormState
          ) ? (
            <DescriptionBox
              label={"Supplier Payment Destination"}
              description={description}
              icon={
                paymentFormState === PaymentFormState.BANK_ACCOUNT ? (
                  <SquareBankIcon width={24} height={24} />
                ) : paymentFormState === PaymentFormState.BPAY ? (
                  <DefaultBPayIcon width={24} height={24} />
                ) : null
              }
              iconStyle={{ padding: 8 }}
            />
          ) : paymentFormState === PaymentFormState.NO_PAYMENT_METHOD ? (
            <FormField>
              <Alert
                type={"error"}
                message={"There is no payment method for this supplier"}
              />
              {hasCreateSupplierFeature && (
                <Button.Secondary
                  onPress={() => onCreatePaymentMethodClick()}
                  style={{ marginTop: 24 }}
                >
                  {"Add Payment Method"}
                </Button.Secondary>
              )}
            </FormField>
          ) : paymentFormState === PaymentFormState.LOADING ? (
            <DescriptionBox.Loading />
          ) : null}
        </View>
      )}
    />
  );
}
