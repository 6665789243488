import { Screens, Row, useCurrentAgencyOrg } from "local/common";
import React from "react";
import {
  ManagementFilterTypes,
  PropertyIssuesSummaryData,
  useGetManagementsForOrgCountQuery,
  useGetPropertyIssueTableDataQuery
} from "local/graphql";
import { ReportMetricCard } from "../../components/ReportMetricCard";
import { CommonActions } from "@react-navigation/native";
import { GridRow, ReportPage } from "../../components";
import { useReportQuery, useReportTeamContext } from "../..";
import { NavigateCfg } from "../../components/ReportPage/utils/tableNavigation";
import { useSelectedTeams } from "local/domain/propertyManagement";
import { isPresent } from "ts-is-present";

export const issueRowLink = <
  TRow extends {
    propertyIssueId: string;
    propertyIssue: string;
    drilldownFields: string[];
  }
>({
  propertyIssueId,
  propertyIssue,
  drilldownFields
}: GridRow<TRow>): NavigateCfg =>
  propertyIssue === "Property in arrears"
    ? { to: "/properties?tab=arrears" }
    : {
        to: "/reports",
        action: CommonActions.navigate(
          propertyIssueId == "property_missing_recurring_fees"
            ? {
                name: Screens.ReportVisulisation,
                params: {
                  reportName: "property_issues_missing_fee"
                }
              }
            : {
                name: Screens.ReportVisulisation,
                key: propertyIssue,
                params: {
                  reportName: "properties",
                  propertyIssue: propertyIssue,
                  propertyColumns: drilldownFields?.join(",")
                }
              }
        )
      };

export function PropertyIssuesTable(): React.ReactElement {
  const { ailoRN } = useCurrentAgencyOrg();
  const { propertyListSelectedTeamIds } = useSelectedTeams();
  const reportTeam = useReportTeamContext();

  const queryResult = useReportQuery(
    useGetPropertyIssueTableDataQuery,
    reportTeam
  );

  const arrearsIssue = useGetManagementsForOrgCountQuery({
    variables: {
      organisationId: ailoRN,
      filters: [
        {
          name: ManagementFilterTypes.HasActiveTenancyInArrears
        },
        ...(propertyListSelectedTeamIds.length > 0
          ? [
              {
                name: ManagementFilterTypes.BelongToTeamsAndUnassigned,
                args: {
                  BelongToTeamsAndUnassigned: {
                    teamIds: propertyListSelectedTeamIds
                  }
                }
              }
            ]
          : [])
      ],
      pageCursor: {
        cursor: null,
        pageSize: 0,
        paginateBackward: false
      }
    }
  });

  const reportData = queryResult?.data?.propertyIssuesSummaryReport;
  const propertiesInArrears =
    arrearsIssue.data?.managementsForOrg?.pageInfo?.total ?? 0;
  const propertyCount = Math.max(
    reportData?.headerFields?.totalPropertyCount || 0,
    propertiesInArrears
  );
  const arrearsPercentage = propertyCount
    ? (100 * propertiesInArrears) / propertyCount
    : null;

  const arrearsRow: Omit<PropertyIssuesSummaryData, "propertyId"> = {
    propertyIssueId: "property_in_arrears",
    propertyIssue: "Property in arrears",
    propertyCount: propertiesInArrears ?? 0,
    propertyCountPercent: arrearsPercentage
      ? `${arrearsPercentage.toPrecision(3)}%`
      : "n/a"
  };
  const rows = [
    arrearsRow,
    ...(reportData?.rows?.filter(
      (row) => row?.propertyIssueId !== "property_in_arrears"
    ) ?? [])
  ]
    .filter(isPresent)
    .map((r) => ({
      ...r,
      drilldownFields: r.drilldownFields?.filter(isPresent)
    }));
  type Row = NonNullable<typeof rows>[0];
  const columns = reportData?.columns?.filter(isPresent);

  const totalNumberOfPropertiesWithIssues = Math.max(
    reportData?.headerFields?.propertyWithIssueCount ?? 0,
    propertiesInArrears
  );

  const totalWithIssuesPercent = propertyCount
    ? ((totalNumberOfPropertiesWithIssues / propertyCount) * 100).toPrecision(3)
    : "n/a";

  return (
    <>
      <ReportPage<Row>
        title={"Property health issues"}
        data={{
          ...queryResult,
          columns,
          rows
        }}
        columnsConfig={{
          propertyIssue: { width: 2, header: "Properties" },
          propertyCount: { header: "# Properties", type: "count" },
          propertyCountPercent: {
            header: "% Properties",
            horizontalAlign: "right",
            sortValue: (v) => v.propertyCount
          }
        }}
        defaultSort={{ columnKey: "propertyCount", direction: "DESC" }}
        componentAboveHeader={
          <Row style={{ justifyContent: "flex-start" }}>
            <ReportMetricCard
              value={propertyCount}
              label={"Properties"}
              loading={queryResult?.loading}
            />
            <ReportMetricCard
              value={totalNumberOfPropertiesWithIssues}
              label={"Properties with Issues"}
              loading={queryResult?.loading}
            />
            <ReportMetricCard
              value={totalWithIssuesPercent}
              label={"% with Issues"}
              loading={queryResult?.loading}
            />
          </Row>
        }
        rowLink={issueRowLink}
      />
    </>
  );
}
