import React, { ReactElement } from "react";
import { AutoPayIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { useStartMigrations } from "../hooks/useStartMigrations";
import { StyleProp, ViewStyle } from "react-native";

const BASE_PROPS = {
  type: "small",
  leftIcon: AutoPayIcon,
  contentStyle: {
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 84
  },
  style: {
    paddingVertical: 3,
    paddingLeft: 11,
    paddingRight: 15
  }
} as const;

interface BaseRefreshButtonProps {
  text?: string;
  contentStyle?: StyleProp<ViewStyle>;
}

function BaseRefreshButton({
  text = "Refresh",
  contentStyle = {}
}: BaseRefreshButtonProps): ReactElement {
  const [startMigrations, { loading }] = useStartMigrations();

  return (
    <Button.Primary
      {...BASE_PROPS}
      contentStyle={[BASE_PROPS.contentStyle, contentStyle]}
      onPress={startMigrations}
      disabled={loading}
    >
      {text}
    </Button.Primary>
  );
}

function RefreshButton(): ReactElement {
  return <BaseRefreshButton />;
}

function Retry(): ReactElement {
  return (
    <BaseRefreshButton
      text={"Retry Refresh"}
      contentStyle={{ minWidth: 125 }}
    />
  );
}

function Loading(): ReactElement {
  return (
    <Button.Primary disabled {...BASE_PROPS}>
      {"Refresh"}
    </Button.Primary>
  );
}

RefreshButton.Retry = Retry;
RefreshButton.Loading = Loading;

export { RefreshButton };
