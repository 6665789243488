import React, { ReactElement } from "react";
import { UseFormMethods } from "react-hook-form";
import { LeaseTypes, NewTenancyFormData } from "../NewTenancyForm";
import { BaseReviewDetailsSection } from "./BaseReviewDetailsSection";
import { DescriptionList, Money } from "@ailo/ui";
import { getDateValue } from "./getDateValue";
import { NewTenancyFileListItem } from "./NewTenancyFileListItem";
import { FilesState, useOpenFile } from "@ailo/domains";
import { capitaliseFirstLetter } from "local/domain/utils";

interface Props {
  form: UseFormMethods<NewTenancyFormData>;
  filesState?: FilesState;
  onEditPress?(): void;
}

export function AgreementDetailsSection({
  form,
  filesState,
  onEditPress
}: Props): ReactElement {
  const formData = form.getValues();
  const files = form.watch("files");
  const { openFile } = useOpenFile();

  return (
    <BaseReviewDetailsSection title={"Lease details"} onEditPress={onEditPress}>
      <DescriptionList
        size={"small"}
        columns={2}
        items={[
          {
            label: "Lease type",
            descriptionBottom: formData.leaseType,
            columnIndex: 0
          },
          {
            label: "Agreement start",
            descriptionBottom: getDateValue(formData.agreementStartDate),
            columnIndex: 1
          },
          {
            label: "Agreement end",
            descriptionBottom:
              formData.leaseType === LeaseTypes.FixedTerm
                ? getDateValue(formData.agreementEndDate)
                : "N/A",
            columnIndex: 0
          },
          {
            label: "Rent start date",
            descriptionBottom: getDateValue(formData.rentStartDate),
            columnIndex: 1
          },
          {
            label: "Rent review date",
            descriptionBottom: getDateValue(formData.rentReviewDate),
            columnIndex: 0
          },
          {
            label: "Rent frequency",
            descriptionBottom: formData.rentFrequency
              ? capitaliseFirstLetter(formData.rentFrequency)
              : "N/A",
            columnIndex: 1
          },
          {
            label: "Rent amount",
            descriptionBottom: formData.rentAmount
              ? Money.from(formData.rentAmount).format()
              : undefined,
            columnIndex: 0
          }
        ]}
        rowStyle={{
          marginTop: 16
        }}
        style={{ marginBottom: files && files?.length > 0 ? 32 : 0 }}
      />
      {files?.map((file, index) => (
        <NewTenancyFileListItem
          key={`New-Tenancy-File-${index}`}
          file={file}
          filesState={filesState}
          isLastItem={index === files.length - 1}
          onPress={(fileDetails) =>
            openFile(fileDetails, { showDownloadButton: false })
          }
        />
      ))}
    </BaseReviewDetailsSection>
  );
}
