import { AiloRN, services } from "@ailo/ailorn";
import {
  BankAccountTypeName,
  BPayTypeName,
  PaymentMethodTypeDomainEnum
} from "@ailo/domains";
import { AiloSentry, useActionEventContext } from "@ailo/services";
import { Money } from "@ailo/ui";
import { useAnalytics, useCurrentAgencyOrg } from "local/common";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import {
  BillPaymentStatus,
  BillStatus,
  CreateBillMutation,
  useCreateBillLiabilityStateMutation,
  useCreateBillMutation,
  useFindOrCreateInternalSupplierMutation,
  useFindOrCreatePaymentReferenceMutation
} from "local/graphql";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { isPresent } from "ts-is-present";

const PAYMENT_DESCRIPTION_MAX_LIMIT = 9;

export interface AddBillFormError {
  title: string;
  message: string;
}

export interface AddBillForm {
  sending: boolean;
  formError?: AddBillFormError;
  setFormError(error: AddBillFormError): void;
  dismissFormError(): void;
  submit: (data: AddBillFormData) => Promise<void>;
}

export interface UseAddBillFormProps {
  managementId?: string;
  managingEntityAilorn?: string;
  propertyAddress?: string;
  onSubmitSuccess?(bill: NonNullable<CreateBillMutation["createBill"]>): void;
}

export const useAddBillForm = ({
  managementId: managementIdProp,
  managingEntityAilorn: managingEntityAilornProp,
  propertyAddress: propertyAddressProp,
  onSubmitSuccess
}: UseAddBillFormProps): AddBillForm => {
  const actionEventContext = useActionEventContext();
  const currentAgencyOrg = useCurrentAgencyOrg();

  const analytics = useAnalytics();

  const [formError, setFormError] = useState<AddBillFormError>();
  const [sending, setSending] = useState(false);

  const [createBillMutation] = useCreateBillMutation();
  const [createBillLiabilityStateMutation] =
    useCreateBillLiabilityStateMutation();
  const [findOrCreatePaymentReferenceMutation] =
    useFindOrCreatePaymentReferenceMutation();
  const [findOrCreateInternalSupplierMutation] =
    useFindOrCreateInternalSupplierMutation();

  let managementId: string;

  const handleSubmitError = (e: any): void => {
    AiloSentry.captureException(e, {
      extras: { managementId }
    });
    setFormError({
      title: "Error",
      message:
        "There was an error with uploading the bill. Please try again and if the error persists please contact Ailo support."
    });
  };

  const dismissFormError = useCallback(() => {
    setFormError(undefined);
  }, []);

  // TODO: Future improvement: These three graphql mutations can be turned into one to
  // improve performance and readability
  const submit = async (formData: AddBillFormData): Promise<void> => {
    if (sending) return; // to prevent double click causing multiple bill creations
    setSending(true);
    try {
      managementId = managementIdProp || formData.managementId!.value;
      const managingEntityAilorn =
        managingEntityAilornProp || formData.managingEntityAilorn;
      const propertyAddress = propertyAddressProp || formData.propertyAddress;

      const supplierAiloRN = formData.supplierReference?.value;
      if (!supplierAiloRN) {
        setSending(false);
        return handleSubmitError(new Error("No supplier reference provided"));
      }

      const getSupplierInternalId = async (): Promise<string | undefined> => {
        if (AiloRN.fromString(supplierAiloRN).isA(services.Bill.supplier)) {
          return AiloRN.fromString(supplierAiloRN).internalId;
        }

        const result = await findOrCreateInternalSupplierMutation({
          variables: {
            supplierDetails: {
              internalReference: supplierAiloRN,
              organisationReference: currentAgencyOrg.ailoRN,
              name: formData.supplierReference?.label
            }
          }
        });
        const ailoRN = result.data?.findOrCreateInternalSupplier?.ailoRN;
        if (!ailoRN) {
          return undefined;
        }
        return AiloRN.fromString(ailoRN).internalId;
      };

      const supplierInternalId = await getSupplierInternalId();
      if (!supplierInternalId) {
        setSending(false);
        return handleSubmitError(
          new Error("Internal supplier creation did not return id")
        );
      }

      const payment = formData?.payment;
      const getSupplierPaymentDescription = (
        invoiceNumber: string | undefined,
        propertyAddress: string
      ): string => {
        if (invoiceNumber?.trim()) {
          const desc = invoiceNumber.trim();
          return desc.length <= PAYMENT_DESCRIPTION_MAX_LIMIT
            ? desc
                .padEnd(
                  Math.min(desc.length + 1, PAYMENT_DESCRIPTION_MAX_LIMIT),
                  " "
                )
                .padEnd(PAYMENT_DESCRIPTION_MAX_LIMIT, "‐")
            : "*".concat(
                desc.slice(desc.length - PAYMENT_DESCRIPTION_MAX_LIMIT + 1)
              );
        } else {
          const desc = propertyAddress.trim();
          return desc.length <= PAYMENT_DESCRIPTION_MAX_LIMIT
            ? desc
                .padEnd(
                  Math.min(desc.length + 1, PAYMENT_DESCRIPTION_MAX_LIMIT),
                  " "
                )
                .padEnd(PAYMENT_DESCRIPTION_MAX_LIMIT, "‐")
            : desc.slice(0, PAYMENT_DESCRIPTION_MAX_LIMIT - 1).concat("*");
        }
      };
      const paymentReference = await findOrCreatePaymentReferenceMutation({
        variables: {
          paymentReferenceDetails: {
            supplierId: supplierInternalId,
            supplierPaymentMethodReference: payment?.id
              ? AiloRN.of(
                  services.PaymentGateway.paymentMethod,
                  payment.id
                ).toString()
              : undefined,
            crn:
              payment?.type === BPayTypeName
                ? payment.customerReference
                : undefined,
            paymentDescription:
              payment?.type === BankAccountTypeName
                ? getSupplierPaymentDescription(
                    formData.invoiceNumber,
                    propertyAddress
                  )
                : undefined
          }
        }
      });

      const paymentReferenceId =
        paymentReference?.data?.findOrCreatePaymentReference?.id;
      if (paymentReferenceId == null) {
        setSending(false);
        return handleSubmitError(
          new Error("created payment reference has no id")
        );
      }

      const billResult = await createBillMutation({
        variables: {
          billDetails: {
            paymentReferenceId,
            description: formData.billDescription,
            toBePaidBy: formData.payerId.value,
            toBePaidByLegalEntities:
              formData.payerId.type === "management"
                ? formData.payerId.investorsAilorns
                : formData.payerId.tenantsAilorns,
            supplierId: supplierInternalId,
            taxCategoryId: formData.taxCategoryId!,
            dueDateV2: formData.dueDate!,
            issueDateV2: formData.issueDate!,
            invoiceNumber: !isEmpty(formData.invoiceNumber)
              ? formData.invoiceNumber
              : undefined,
            status: "APPROVED" as BillStatus,
            relatingToManagement: AiloRN.of(
              services.PropertyManagement.management,
              managementId
            ).toString(),
            relatingToOrganisation: currentAgencyOrg.ailoRN,
            taxAutoCalculated: formData.taxAutoCalculated,
            lineItems: formData
              .lineItems!.map((lineItem) => {
                return {
                  amount: { cents: lineItem.amount!.cents },
                  taxAmount: {
                    cents: formData.taxAutoCalculated
                      ? Money.from(lineItem.amount!).divide(11).cents
                      : Money.zero().cents
                  },
                  description: lineItem.description,
                  isTax: false
                };
              })
              .concat(
                !formData.taxAutoCalculated
                  ? [
                      {
                        amount: { cents: formData.taxAmount?.cents ?? 0 },
                        taxAmount: { cents: formData.taxAmount?.cents ?? 0 },
                        description: "GST",
                        isTax: true
                      }
                    ]
                  : []
              ),
            attachmentsFileIds: (formData.attachmentsFiles || [])
              .map((f) => f.ailorn?.toString())
              .filter(isPresent),
            propertyAddress,
            managingEntityAilorn,
            applyManagementFee: formData.applyManagementFee
          }
        }
      });

      const createdBill = billResult?.data?.createBill;

      if (!createdBill) {
        setSending(false);
        return handleSubmitError(
          new Error("Bill created but returned id is undefined")
        );
      }

      await createBillLiabilityStateMutation({
        variables: {
          billLiabilityStateDetails: {
            billId: createdBill.ailoRN,
            paymentStatus: "DUE" as BillPaymentStatus
          }
        }
      });

      const hasCrn =
        payment?.type === PaymentMethodTypeDomainEnum.BPay &&
        !!payment?.customerReference;

      analytics.trackBillCreated({
        billId: createdBill.ailoRN,
        payableBy: AiloRN.fromString(formData.payerId.value).entity,
        managementId: AiloRN.of(
          services.PropertyManagement.management,
          managementId
        ).toString(),
        ...(hasCrn && {
          crnValid: !payment!.customerReferenceWarningCurrentlyShown,
          crnInvalidWarningShown: !!payment!.customerReferenceWarningEverShown
        }),
        ...(!isEmpty(formData.invoiceNumber) && {
          invoiceNumber: formData.invoiceNumber,
          invoiceNumberUnique: !formData.invoiceNumberWarning
        })
      });

      actionEventContext.emit({ type: "BillCreated" });
      setSending(false);
      onSubmitSuccess?.(createdBill);
    } catch (e) {
      setSending(false);
      handleSubmitError(e);
    }
  };

  return {
    submit,
    formError,
    setFormError,
    dismissFormError,
    sending
  };
};
