import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";
import { ProjectType, Tenancy } from "local/graphql";
import { ProjectSecondStep } from "./ProjectSecondStep";
import { ProjectFirstStep } from "./ProjectFirstStep";
import { MultiStepForm } from "local/common";
import { ProjectFormData, ProjectFormFields } from "./ProjectFormData";
import { Separator } from "@ailo/ui";

import { ProjectSearchProperty } from "./ProjectSearchProperty";

export enum ProjectFormStep {
  ProjectType = "Project Type",
  ProjectDetails = "Project Details"
}

interface Props {
  type: "create" | "update";
  initialValue?: ProjectFormData;
  currentTenancy?: Pick<Tenancy, "id" | "ailoRN" | "endDate">;
  currentTenancyLoading?: boolean;
  style?: StyleProp<ViewStyle>;
  onSubmit(data: ProjectFormData): void;
  onCancel(): void;
  onFormSubmitting: (isSubmitting: boolean) => void;
  onStepStarted(formStep: ProjectFormStep): void;
  onValueUpdated: (
    fieldName: string,
    isValid: boolean,
    formValues: ProjectFormData
  ) => void;
  onTypeSelected?: (type?: ProjectType) => void;
}

export function ProjectForm({ ...props }: Props): React.ReactElement {
  return <ProjectMultiStepForm {...props} />;
}
export function ProjectMultiStepForm({
  type,
  currentTenancy,
  currentTenancyLoading,
  style,
  onSubmit,
  onCancel,
  onFormSubmitting,
  onStepStarted,
  onValueUpdated,
  onTypeSelected
}: Props): ReactElement {
  const {
    formState,
    handleSubmit,
    getValues,
    register,
    watch,
    setValue,
    reset
  } = useFormContext<ProjectFormData>();

  const { isSubmitting, isDirty, isValid, errors } = formState;
  const [currentStep, setCurrentStep] = useState<number>(1);

  const management = watch(ProjectFormFields.management);

  useEffect(() => {
    onFormSubmitting(isSubmitting);
  }, [isSubmitting, onFormSubmitting]);

  register({ name: ProjectFormFields.type });
  // hidden field for storing project metadata
  register({ name: ProjectFormFields.meta });

  const projectType = watch(ProjectFormFields.type);

  const resetProjectType = useCallback(() => {
    setValue(ProjectFormFields.type, undefined);
    onTypeSelected?.(undefined);
  }, [setValue, onTypeSelected]);

  const submit = handleSubmit(onSubmit);
  const cancel = useCallback(() => {
    reset();
    resetProjectType();
    setCurrentStep(1);
    onCancel();
  }, [onCancel, resetProjectType, reset]);

  const isUploading =
    watch(ProjectFormFields.files)?.some((file) => !file.id) ?? false;

  const onFirstStepStarted = useCallback(
    () => onStepStarted?.(ProjectFormStep.ProjectType),
    [onStepStarted]
  );

  const onFieldUpdated = useCallback(
    (fieldName: keyof ProjectFormData) => {
      onValueUpdated?.(fieldName, !errors[fieldName], getValues());
    },
    [errors, getValues, onValueUpdated]
  );

  const onSecondStepStarted = useCallback(
    () => onStepStarted?.(ProjectFormStep.ProjectDetails),
    [onStepStarted]
  );

  const onProjectTypeSelected = useCallback(
    (type?: ProjectType) => {
      onTypeSelected?.(type);
      setCurrentStep(2);
    },
    [onTypeSelected]
  );

  const renderStep1 = useCallback(
    (visible: boolean): React.ReactElement => {
      return (
        <View
          style={{ display: visible ? undefined : "none" }}
          key={"Project-Form-Step-1"}
        >
          <ProjectSearchProperty />
          {management?.value && (
            <>
              <Separator
                style={{ marginLeft: 0, marginTop: 40, marginBottom: 32 }}
              />
              <ProjectFirstStep
                currentTenancy={currentTenancy}
                currentTenancyLoading={currentTenancyLoading}
                onFocus={onFirstStepStarted}
                onFieldUpdated={onFieldUpdated}
                onTypeSelected={onProjectTypeSelected!}
                managementId={management.value}
              />
            </>
          )}
        </View>
      );
    },
    [
      currentTenancy,
      currentTenancyLoading,
      onFieldUpdated,
      onFirstStepStarted,
      onProjectTypeSelected,
      management
    ]
  );

  const renderStep2 = useCallback(
    (visible: boolean): React.ReactElement => (
      <View
        style={{ display: visible ? undefined : "none" }}
        key={"Project-Form-Step-2"}
      >
        <ProjectSecondStep
          onFocus={onSecondStepStarted}
          onFieldUpdated={onFieldUpdated}
        />
      </View>
    ),
    [onFieldUpdated, onSecondStepStarted]
  );

  const onChange = useCallback(
    (newStepValue: number) => {
      setCurrentStep(newStepValue);
      if (newStepValue === 1) {
        resetProjectType();
      }
    },
    [resetProjectType]
  );

  return (
    <MultiStepForm
      style={style}
      title={type === "create" ? "Create a new project" : "Edit project"}
      submitButtonText={type === "create" ? "Create project" : "Save changes"}
      submitButtonVisible={!!projectType}
      stepValue={currentStep}
      onChange={onChange}
      onSubmit={submit}
      onCancel={cancel}
      isDirty={isDirty}
      cancelButtonDisabled={isSubmitting}
      submitButtonDisabled={!isDirty || !isValid || isUploading}
      steps={type === "create" ? [renderStep1, renderStep2] : [renderStep2]}
      headerBackButtonDisabled
      hideCancelButton
      showFooterBackButton
    />
  );
}
