import { AiloRN } from "@ailo/ailorn";
import {
  AgencyWelcomeMessage,
  CenteredSpinner,
  ThreadList,
  useChatContext,
  useThreadListContext
} from "@ailo/domains";
import React, { ReactElement } from "react";
import { useNavigateToEmptyChat } from "local/tabs/comms/navigation";

interface AgencyThreadListProps {
  openThread: (threadAilorn: AiloRN<"chat:thread">) => void;
  onThreadCreated?: (
    chatAilorn: AiloRN<"chat:chat">,
    threadAilorn: AiloRN<"chat:thread">
  ) => void;
}

export const AgencyThreadList = React.memo(function AgencyThreadList({
  openThread,
  onThreadCreated
}: AgencyThreadListProps): ReactElement {
  const navigateToEmptyChat = useNavigateToEmptyChat();
  const {
    selectedChat: { loading: selectedChatLoading }
  } = useChatContext();
  const {
    threadList: { loading: threadListLoading }
  } = useThreadListContext();

  if (selectedChatLoading || threadListLoading) {
    return <CenteredSpinner />;
  }

  return (
    <ThreadList
      readonlyBannerType={"agency-chat"}
      welcomeMessage={<AgencyWelcomeMessage />}
      openThread={openThread}
      onThreadCreated={onThreadCreated}
      onError={navigateToEmptyChat}
    />
  );
});
