import React from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import { Colors, opacify, RentCreditIcon, Text } from "@ailo/primitives";
import { Badge, Button, SidebarTitle, Tile } from "@ailo/ui";
import { LinearGradient } from "expo-linear-gradient";
import { Address, formatAddress } from "@ailo/domain-helpers";
import { AdjustmentEntryDetails } from "./AdjustmentEntryDetails";
import { adjustmentTypeFromAmount } from "./adjustmentTypeFromAmount";
import {
  getRentAdjustmentStatus,
  RentAdjustmentStatus
} from "../getRentAdjustmentStatus";
import { RentAdjustmentStatusBadge } from "../RentAdjustmentStatusBadge";

interface Props {
  adjustmentDetails: AdjustmentEntryDetails;
  address?: Address;
  onClose(): void;
  onReverseButtonPress?(): void;
}

export function RentAdjustmentSidebarHeader({
  adjustmentDetails,
  address,
  onClose,
  onReverseButtonPress
}: Props): React.ReactElement {
  const { amount, effectiveAt, reversed } = adjustmentDetails;

  const formattedAddress =
    address &&
    formatAddress(address, {
      format: "street suburb state"
    });

  return (
    <StyledLinearGradient
      colors={
        styleToGradient[getRentAdjustmentStatus({ effectiveAt, reversed })]
      }
      start={[0, 1]}
      end={[1, 1]}
    >
      <SidebarTitle
        title={<Tile icon={RentCreditIcon} width={40} height={40} />}
        closeButton
        additionalButtons={
          onReverseButtonPress ? (
            <Button.Secondary
              type={"small"}
              style={{ height: 32 }}
              onPress={onReverseButtonPress}
            >
              {"Reverse Credit"}
            </Button.Secondary>
          ) : undefined
        }
        onClose={onClose}
        style={{ marginTop: 0, marginBottom: 0, marginRight: 0, marginLeft: 0 }}
      />

      <Text.BodyM
        style={{ marginTop: 16, color: opacify(Colors.SPACE_BLACK, 0.6) }}
      >
        {`Rent Adjustment  ·  ${adjustmentTypeFromAmount(amount)}`}
      </Text.BodyM>

      <Text.DisplayL testID={"total-amount-text"}>
        {amount.abs().format()}
      </Text.DisplayL>

      <BadgeContainer>
        {formattedAddress && <Badge>{formattedAddress}</Badge>}
        <RentAdjustmentStatusBadge
          effectiveAt={effectiveAt}
          reversed={reversed}
        />
      </BadgeContainer>
    </StyledLinearGradient>
  );
}

RentAdjustmentSidebarHeader.Loading = RentAdjustmentSidebarHeaderLoading;

function RentAdjustmentSidebarHeaderLoading({
  onClose
}: {
  onClose(): void;
}): React.ReactElement {
  return (
    <StyledLinearGradient
      colors={[opacify(Colors.CHARCOAL, 0), opacify(Colors.CHARCOAL, 0.075)]}
      start={[0, 1]}
      end={[1, 1]}
    >
      <SidebarTitle
        title={<Tile.Loading width={40} height={40} />}
        closeButton
        onClose={onClose}
        style={{ marginTop: 0, marginBottom: 0, marginRight: 0, marginLeft: 0 }}
      />
      <View>
        <Text.BodyM.Loading style={{ marginTop: 16 }} />
        <Text.DisplayL.Loading width={120} />
        <BadgeContainer>
          <Badge.Loading width={80} />
        </BadgeContainer>
      </View>
    </StyledLinearGradient>
  );
}

const styleToGradient = {
  [RentAdjustmentStatus.REVERSED]: [
    opacify(Colors.STATUS.ERROR, 0),
    opacify(Colors.STATUS.ERROR, 0.05)
  ],
  [RentAdjustmentStatus.PENDING]: [
    opacify(Colors.STATUS.WARNING, 0),
    opacify(Colors.STATUS.WARNING, 0.05)
  ],
  [RentAdjustmentStatus.DEFAULT]: [
    opacify(Colors.SKY, 0),
    opacify(Colors.SKY, 0.1)
  ]
};

const StyledLinearGradient = styled(LinearGradient)`
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 20px;
`;

const BadgeContainer = styled(View)`
  margin-top: 16px;
  flex-direction: row;
  justify-content: space-between;
`;
