import React, { ReactElement, ReactNode } from "react";
import {
  Colors,
  DefaultProps,
  DisbursementsIcon,
  opacify,
  Text
} from "@ailo/primitives";
import { EmptyGrid, ErrorCard } from "@ailo/ui";
import { Money } from "@ailo/money";
import { View } from "react-native";
import { reduce } from "lodash";
import styled from "styled-components/native";
import { useFormContext } from "react-hook-form";
import { AllocationPurpose, CreateReceiptFormData } from "local/tabs";
import { useGetAvailablePurposesData } from "./useGetAvailablePurposesData";
import { CellContainer, FooterRowContainer } from "./AllocationsTableRow";

type AllocationsComponentInputProps = DefaultProps<{
  headerRow: ReactNode;
  loadingRow: ReactNode;
  renderRow: (purpose: AllocationPurpose, i: number) => ReactNode;
  type?: string;
  payableBy?: string;
  payableTo?: string;
}>;

export type AllocationsInputProps = {
  headerRow: ReactNode;
  loadingRow: ReactNode;
  renderRow: (purpose: AllocationPurpose, i: number) => ReactNode;
  type?: string;
  emptyTitle: string;
  walletFilter: "payableBy" | "payableTo";
};

export function AllocationsInput({
  emptyTitle,
  headerRow,
  renderRow,
  loadingRow,
  walletFilter,
  type
}: AllocationsInputProps): React.ReactElement {
  const { watch } = useFormContext<CreateReceiptFormData>();
  const targetWalletOption = watch("targetWallet") as {
    label?: string;
    value?: string;
  };
  if (!targetWalletOption?.value) {
    return (
      <CenterAlignedContainer>
        <EmptyGridNoShadow
          message={"Allocation"}
          secondaryMessage={emptyTitle}
          bannerImage={
            <View style={{ alignItems: "center" }}>
              <DisbursementsIcon />
            </View>
          }
        />
      </CenterAlignedContainer>
    );
  } else {
    const filterProps = { [walletFilter]: targetWalletOption.value };
    return (
      <AllocationsInputComponent
        {...filterProps}
        renderRow={renderRow}
        headerRow={headerRow}
        loadingRow={loadingRow}
        type={type}
      />
    );
  }
}

const EmptyGridNoShadow = styled(EmptyGrid)`
  box-shadow: none;
`;

const ErrorCardNoShadow = styled(ErrorCard)`
  box-shadow: none;
`;

function AllocationsInputComponent({
  payableBy,
  payableTo,
  type,
  renderRow,
  headerRow,
  loadingRow
}: AllocationsComponentInputProps): React.ReactElement {
  const { watch } = useFormContext<CreateReceiptFormData>();
  const result = useGetAvailablePurposesData({
    payableBy,
    payableTo,
    type
  });

  if (result.error) {
    return (
      <CenterAlignedContainer>
        <ErrorCardNoShadow
          message={"There's a problem loading allocations"}
          onReload={result.refetch}
        />
      </CenterAlignedContainer>
    );
  }
  if (result.loading) {
    return (
      <View
        style={{
          padding: 32
        }}
      >
        <TitleRow>{"Allocation"}</TitleRow>
        <AllocationsPanel>
          {headerRow}
          {Array.from({ length: 3 }).map((_, i) => (
            <View key={i}>{loadingRow}</View>
          ))}
        </AllocationsPanel>
      </View>
    );
  }
  const formAllocations = watch("allocations");

  return (
    <View
      style={{
        padding: 32
      }}
    >
      <TitleRow>{"Allocation"}</TitleRow>
      <AllocationsPanel>
        {headerRow}
        {(result.data || []).map((item, i) => renderRow(item, i))}
        <TotalRow>
          <></>
          <LeftAlignedText weight={"medium"}>
            {"Total allocation"}
          </LeftAlignedText>
          <></>
          <></>
          <MoneyText weight={"medium"}>
            {totalAmount(formAllocations).format()}
          </MoneyText>
        </TotalRow>
      </AllocationsPanel>
    </View>
  );
}

function totalAmount(allocations: AllocationPurpose[] | undefined): Money {
  return allocations
    ? reduce(
        allocations,
        function (total, allocation) {
          return allocation.included
            ? total.add(allocation.amount || Money.zero())
            : total;
        },
        Money.zero()
      )
    : Money.zero();
}

const AllocationsPanel = styled(View)`
  padding: 0;
  border: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  border-radius: 4px;
`;

const TitleRow = styled(Text.BodyL).attrs({ weight: "medium" })`
  padding: 0;
  padding-bottom: 24px;
`;

export const LeftAlignedText = styled(Text.BodyS).attrs({ weight: "medium" })`
  text-align: left;
`;

export const LeftAlignedTextLoading = LeftAlignedText.withComponent(
  Text.BodyS.Loading
);

export const RightAlignedText = styled(Text.BodyS).attrs({ weight: "medium" })`
  text-align: right;
`;

export function RightAlignedTextLoading(): ReactElement {
  return (
    <View style={{ alignItems: "flex-end" }}>
      <Text.BodyS.Loading />
    </View>
  );
}

export const BoldText = styled(Text.BodyM).attrs({ weight: "medium" })`
  text-align: left;
  font-style: bold;
  flex: 1;
  flex-basis: auto;
`;

const TotalRow = styled(FooterRowContainer).attrs((props) => ({
  columns: props.columns ?? [{ flex: 1 }, { flex: 1 }],
  cellContainer: CellContainer
}))`
  padding-top: 8px
  padding-bottom: 8px;
`;

const MoneyText = styled(Text.BodyL).attrs({ weight: "medium" })`
  text-align: right;
`;

const CenterAlignedContainer = styled(View)`
  margin-top: 2px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`;
