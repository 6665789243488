import React, { useState } from "react";
import { SFC } from "@ailo/primitives";
import { ImageCarousel, useDownloadFile } from "@ailo/domains";
import { ActionButtonBar } from "./ActionButtonBar";
import { View } from "react-native";

interface Props {
  onClosePress: () => void;
  imageUrls: string[];
  initialImageIndex: number;
  showDownloadButton?: boolean;
}

export const ImageCarouselScreen: SFC<Props> = ({
  style,
  imageUrls,
  initialImageIndex,
  showDownloadButton,
  onClosePress
}) => {
  const [focusedImageIndex, setFocussedImageIndex] =
    useState(initialImageIndex);
  const { downloadFile } = useDownloadFile();

  return (
    <View
      style={[
        {
          height: "100%",
          width: "100%",
          paddingBottom: 48,
          overflow: "hidden"
        },
        style
      ]}
    >
      <ActionButtonBar
        onClosePress={onClosePress}
        onDownloadPress={
          showDownloadButton
            ? (): void => downloadFile(imageUrls[focusedImageIndex])
            : undefined
        }
      />
      <ImageCarousel
        imageUrls={imageUrls}
        initialImageIndex={initialImageIndex}
        onIndexChanged={setFocussedImageIndex}
      />
    </View>
  );
};
