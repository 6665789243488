import React from "react";
import { ErrorAlertScreen } from "@ailo/ui";
import { withErrorBoundary } from "@ailo/services";
import { TabContent } from "../common";
import {
  BankingDetailsForm,
  BankingDetailsFormContext
} from "./BankingDetailsForm";
import { useAgencyDefaultWalletLegalEntity } from "local/common";
import { useFindEftPaymentSettingsQuery } from "local/graphql";

export const BankingDetailsFormTab = withErrorBoundary(
  function BankingDetailsFormTab(): React.ReactElement {
    const agency = useAgencyDefaultWalletLegalEntity();
    const { data, loading, refetch } = useFindEftPaymentSettingsQuery({
      variables: {
        agency: agency.toString()
      }
    });

    if (loading) {
      return (
        <TabContent title={"Banking"} style={{ height: "100%" }}>
          <BankingDetailsForm.Loading />
        </TabContent>
      );
    }

    return (
      <>
        <TabContent title={"Banking"} style={{ height: "100%" }}>
          <BankingDetailsFormContext
            defaultValues={
              data?.findEftPaymentSettings
                ? {
                    abaFileFormat: data.findEftPaymentSettings.abaFileFormat,
                    directEntryUserId:
                      data.findEftPaymentSettings.directEntryUserId || undefined
                  }
                : undefined
            }
          >
            <BankingDetailsForm refetchData={refetch} />
          </BankingDetailsFormContext>
        </TabContent>
      </>
    );
  },
  {
    fallbackComponent: function Fallback(props) {
      return (
        <TabContent>
          <ErrorAlertScreen
            title={"There was a problem loading banking settings"}
            onRetry={props.retry}
          />
        </TabContent>
      );
    }
  }
);
