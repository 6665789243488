import { Colors, opacify } from "@ailo/primitives";
import { ScreenComponent, ScreenComponentProps } from "@ailo/services";
import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { PageContent } from "./PageContent";
import { LinearGradient } from "expo-linear-gradient";
import { useDimensions } from "@react-native-community/hooks";

type SectionSize = "small" | "medium" | "large";
interface Props extends SplitProps {
  screenContainerProps?: ScreenComponentProps;
}

interface SplitProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
  leftSectionSize: SectionSize;
  scrollable?: boolean;
  rightRef?: React.Ref<View>;
  type?: "inset" | "raised";
}

export function SplitScreenContainer({
  screenContainerProps,
  ...splitProps
}: Props): ReactElement {
  return (
    <ScreenComponent {...screenContainerProps}>
      <SplitContainer {...splitProps} />
    </ScreenComponent>
  );
}

export function SplitContainer({
  left,
  right,
  leftSectionSize,
  scrollable,
  rightRef,
  type = "inset"
}: SplitProps): ReactElement {
  const {
    window: { width: windowWidth }
  } = useDimensions();
  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      {scrollable ? (
        <LeftSectionWrapper windowWidth={windowWidth} size={leftSectionSize}>
          <PageContent
            style={{ flex: 1, flexGrow: 1 }}
            contentContainerStyle={{ padding: 0, flexGrow: 1 }}
          >
            {left}
          </PageContent>
        </LeftSectionWrapper>
      ) : (
        <UnscrollableLeftSection
          windowWidth={windowWidth}
          size={leftSectionSize}
        >
          {left}
        </UnscrollableLeftSection>
      )}
      <RightSection
        ref={rightRef}
        windowWidth={windowWidth}
        size={leftSectionSize}
      >
        {right && type === "raised" && (
          <LinearGradient
            style={{ width: 28 }}
            colors={[Colors.CLOUD, Colors.WHITE]}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 1 }}
          />
        )}
        {right}
      </RightSection>
    </View>
  );
}

interface SectionProps {
  windowWidth: number;
  size: SectionSize;
}

const LeftSectionWrapper = styled(View)<SectionProps>`
  flex: 1;
  min-width: ${({ size, windowWidth }: SectionProps) =>
    getLeftSectionWidth(size, windowWidth)}px;
  background-color: ${Colors.WHITE};
  margin-right: -1px;
  border-right-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  box-shadow: 0px 1px 4px ${opacify(Colors.SPACE_BLACK, 0.2)};
  z-index: 2;
`;

const UnscrollableLeftSection = styled(LeftSectionWrapper)<SectionProps>`
  flex-direction: column;
  align-items: flex-start;
  border-right-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  box-shadow: 0px 1px 4px ${opacify(Colors.SPACE_BLACK, 0.2)};
  z-index: 2;
`;

const RightSection = styled(View)<SectionProps>`
  flex: 1;
  min-width: ${({ size, windowWidth }: SectionProps) =>
    getRightSectionWidth(size, windowWidth)}px;
  z-index: 1;
  flex-direction: row;
`;

function getLeftSectionWidth(size: SectionSize, screenWidth: number): number {
  const containerWidth = screenWidth - 200; // 200px of left hand side menu
  const maxContentWidth = 1080; // left + right content width
  switch (size) {
    case "small": {
      // For small screens 480px on left section for a total of 1080px
      const smallWidthLeft = 480;
      return containerWidth > maxContentWidth
        ? smallWidthLeft + Math.max(containerWidth - maxContentWidth, 0) / 2
        : (containerWidth * smallWidthLeft) / maxContentWidth;
    }
    case "medium": {
      // For medium screens 680px on left section for a total of 1080px
      const mediumWidthLeft = 680;
      return containerWidth > maxContentWidth
        ? mediumWidthLeft + Math.max(containerWidth - maxContentWidth, 0) / 2
        : (containerWidth * mediumWidthLeft) / maxContentWidth;
    }
    case "large": {
      // For large screens 780px on left section for a total of 1080px
      const mediumWidthLeft = 780;
      return containerWidth > maxContentWidth
        ? mediumWidthLeft + Math.max(containerWidth - maxContentWidth, 0) / 2
        : (containerWidth * mediumWidthLeft) / maxContentWidth;
    }
    default:
      return 0;
  }
}

function getRightSectionWidth(size: SectionSize, screenWidth: number): number {
  const containerWidth = screenWidth - 200; // 200px of left hand side menu
  return containerWidth - getLeftSectionWidth(size, screenWidth);
}
