import { useOnFocus } from "@ailo/services";
import { Screens, useNavigation, useRoute } from "local/common";
import { OccupancyStatus } from "local/graphql";
import React, { useCallback, useEffect } from "react";
import { PropertyListFilterType } from "../types";
import { UpdatePropertyFilterStateCallback } from "../usePropertyListFilterState";
import { OccupancyStatusFilter } from "./OccupancyStatusFilter";

export function useOccupancyStatusFilter({
  isFilterDisabled = false,
  occupancyStatuses,
  updateFilterState
}: {
  isFilterDisabled?: boolean;
  occupancyStatuses: string[];
  updateFilterState: UpdatePropertyFilterStateCallback;
}): PropertyListFilterType {
  const { params } = useRoute<Screens.PropertyList>();
  const { setParams } = useNavigation();
  const initialStatuses = params?.statuses ?? [];

  useOnFocus(
    () => {
      if (isFilterDisabled) {
        return;
      }

      if (initialStatuses && initialStatuses?.length > 0) {
        updateFilterState({ occupancyStatuses: initialStatuses });
        return;
      }

      if (occupancyStatuses?.length !== 0) {
        updateFilterState({ occupancyStatuses: [] });
      }
    },
    { fireOncePerFocus: true }
  );

  useEffect(() => {
    if (isFilterDisabled && occupancyStatuses?.length === 0) {
      updateFilterState({ occupancyStatuses: [] });
    }
  }, [isFilterDisabled, occupancyStatuses, updateFilterState]);

  const onChangeFilter = useCallback(
    (values: OccupancyStatus[]) => {
      updateFilterState({ occupancyStatuses: values.sort() });
      setParams({ statuses: values });
    },
    [setParams, updateFilterState]
  );

  return {
    isFilterActive: !!occupancyStatuses?.length,
    component: isFilterDisabled ? null : (
      <OccupancyStatusFilter
        key={"PropertyList-OccupancyStatusFilter"}
        onChange={onChangeFilter}
        values={occupancyStatuses ?? []}
      />
    )
  };
}
