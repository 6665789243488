import React, { useState } from "react";
import { Image, View } from "react-native";
import styled from "styled-components/native";
import { FileDomainType } from "@ailo/domains";
import { ThumbnailStatus } from "local/graphql";
import {
  Colors,
  DeleteIcon,
  MoreIcon,
  opacify,
  Pressable,
  Text,
  useHover
} from "@ailo/primitives";
import {
  Button,
  DropdownMenu,
  DropdownMenuToggleProps,
  FileAvatar,
  formatFileInfo,
  OverlayContainer,
  Separator,
  Tooltip
} from "@ailo/ui";
import { ProjectFile } from "./getFiles";
import { useRemoveFileOption } from "./useRemoveFileOption";
import { LinearGradient } from "expo-linear-gradient";

export type FilePreviewType = "small" | "medium-details";

interface FilePreviewProps {
  projectId: string;
  file: ProjectFile;
  onPress: (file: FileDomainType) => void;
  onDelete: (fileId: string) => void;
  type?: FilePreviewType;
  textOverlay?: string;
  showOptions?: boolean;
  tooltipContent?: string;
}

export function FilePreview({
  projectId,
  file,
  onPress,
  onDelete,
  type = "small",
  textOverlay,
  showOptions = true,
  tooltipContent
}: FilePreviewProps): React.ReactElement {
  const hasThumbnail =
    file.thumbnailStatus == ThumbnailStatus.Generated && file.thumbnailUrl;

  const option = useRemoveFileOption({
    projectId,
    file,
    icon: <DeleteIcon />,
    onDelete
  });

  const [hoverRef, isHovered] = useHover();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Tooltip tooltipContent={tooltipContent || file.fileName}>
      <FileContainer
        ref={hoverRef}
        onPress={() => onPress(file)}
        width={type === "small" ? 84 : 133}
        height={type === "small" ? 80 : 160}
      >
        {hasThumbnail ? (
          <FileImage source={{ uri: file.thumbnailUrl }} />
        ) : (
          <FileAvatarContainer height={type === "small" ? 80 : 104}>
            <FileAvatar />
          </FileAvatarContainer>
        )}
        {showOptions && (isHovered || isDropdownOpen) && (
          <PreviewOverlay>
            <DropdownMenu
              renderToggle={renderDropdownButton}
              options={[option]}
              align={"start"}
              onSetIsPopoverOpen={(state: boolean) => setIsDropdownOpen(state)}
            />
          </PreviewOverlay>
        )}
        {textOverlay && (
          <TextOverlay>
            <Text.DisplayM color={Colors.WHITE}>{textOverlay}</Text.DisplayM>
          </TextOverlay>
        )}
        {type === "medium-details"
          ? getFileDetails(file, !!hasThumbnail)
          : null}
      </FileContainer>
    </Tooltip>
  );
}

function getFileDetails(
  file: ProjectFile,
  hasThumbnail: boolean
): React.ReactElement {
  if (hasThumbnail) {
    return (
      <PreviewDetailsOverlay>
        <LinearGradient
          style={{ minHeight: 56 }}
          start={[0, 0]}
          end={[0, 1]}
          colors={[opacify(Colors.BLACK, 0), opacify(Colors.BLACK, 0.65)]}
          locations={[0, 0.3393]}
        >
          <Text.BodyS
            style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 8 }}
            numberOfLines={1}
            ellipsizeMode={"tail"}
            color={Colors.WHITE}
          >
            {file.fileName}
          </Text.BodyS>
          <Text.BodyS
            style={{ paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}
            numberOfLines={1}
            ellipsizeMode={"tail"}
            color={opacify(Colors.WHITE, 0.8)}
          >
            {formatFileInfo(file.fileName, file.fileSize)}
          </Text.BodyS>
        </LinearGradient>
      </PreviewDetailsOverlay>
    );
  }

  return (
    <PreviewDetailsOverlay>
      <Separator style={{ marginLeft: 0 }} />
      <Text.BodyS
        style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 8 }}
        numberOfLines={1}
        ellipsizeMode={"tail"}
        color={Colors.TEXT.DARK.PRIMARY}
        weight={"medium"}
        fontWeight={500}
      >
        {file.fileName}
      </Text.BodyS>
      <Text.BodyS
        style={{ paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}
        numberOfLines={1}
        ellipsizeMode={"tail"}
        color={Colors.TEXT.DARK.SECONDARY}
      >
        {formatFileInfo(file.fileName, file.fileSize)}
      </Text.BodyS>
    </PreviewDetailsOverlay>
  );
}

function renderDropdownButton({
  open,
  toggle
}: DropdownMenuToggleProps): React.ReactElement {
  return (
    <Button.Secondary
      type={"extra-small"}
      square
      active={open}
      onPress={toggle}
      style={{ marginTop: 4, marginRight: 4 }}
    >
      <MoreIcon width={16} height={16} />
    </Button.Secondary>
  );
}
interface FileContainerProps {
  height: number;
  width: number;
}

const FileContainer = styled(Pressable)<FileContainerProps>`
  ${({ height }): string => `height: ${height}px`};
  ${({ width }): string => `width: ${width}px`};
  margin-right: 8px;
  margin-bottom: 8px;
  border: 1px solid ${Colors.GRAYSCALE.OUTLINE};
  border-radius: 4px;
  align-items: center;
  overflow: hidden;
`;

const PreviewOverlay = styled(OverlayContainer)`
  background-color: ${opacify(Colors.BLACK, 0.1)};
  align-items: flex-end;
  justify-content: end;
`;

const PreviewDetailsOverlay = styled(OverlayContainer)`
  flex-direction: column;
  align-self: flex-end;
  min-height: 56px;
  top: 104px;
`;

const FileImage = styled(Image)`
  height: 100%;
  width: 100%;
`;

const TextOverlay = styled(OverlayContainer)`
  background-color: ${opacify(Colors.BLACK, 0.5)};
  justify-content: center;
  align-items: center;
`;

const FileAvatarContainer = styled(View)<{ height: number }>`
  ${({ height }): string => `height: ${height}px`};
  justify-content: center;
`;
