import { SearchBox, SearchBoxOptionGroup } from "@ailo/ui";
import { useAnalytics } from "local/common";
import { useNavigateToProperty } from "local/domain/propertyManagement";
import { usePropertyOrAgencyPropertySearch } from "local/domain/propertyManagement/hooks/usePropertyOrAgencyPropertySearch";
import { usePropertySearchAnalytics } from "local/domain/propertyManagement/hooks/usePropertySearchAnalytics";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

const MIN_NO_CHARS_TO_START_SEARCH = 2;

export const GlobalSearchBox: React.FC = () => {
  const {
    searchHandler: searchProperties,
    error,
    options,
    transformToSelectedManagementOption
  } = usePropertyOrAgencyPropertySearch();
  const { searchVersion } = usePropertySearchAnalytics();

  const analytics = useAnalytics();
  const navigateToProperty = useNavigateToProperty();

  const [pristine, setPristine] = useState(true);

  const searchBoxOptionGroups: SearchBoxOptionGroup[] | undefined =
    useMemo(() => {
      if (pristine) {
        return;
      }

      if (!options?.length) {
        return;
      }

      return [
        {
          label: "Properties",
          options
        }
      ].filter((groups) => groups.options.length > 0);
    }, [pristine, options]);

  const queryStringToBeTrackedRef = useRef<string | undefined>();
  useEffect(() => {
    if (
      searchBoxOptionGroups?.length === 0 &&
      queryStringToBeTrackedRef.current
    ) {
      analytics.trackGlobalSearchNoResults({
        searchString: queryStringToBeTrackedRef.current,
        searchVersion
      });
      queryStringToBeTrackedRef.current = undefined;
    }
  }, [analytics, searchBoxOptionGroups, searchVersion]);

  const search = useCallback(
    (queryString: string): void => {
      const shouldSearch = queryString.length >= MIN_NO_CHARS_TO_START_SEARCH;
      if (!shouldSearch) {
        setPristine(true);
        return;
      }

      setPristine(false);
      queryStringToBeTrackedRef.current = queryString;
      searchProperties(queryString);
    },
    [searchProperties]
  );

  const setAsPristine = useCallback((): void => {
    if (!pristine) {
      setPristine(true);
    }
  }, [pristine]);

  return (
    <SearchBox
      optionGroups={searchBoxOptionGroups}
      isError={!!error}
      placeholder={"Search properties"}
      onInputFinish={search}
      onClear={setAsPristine}
      onRetry={search}
      onFocus={(): void => {
        analytics.trackGlobalSearchFocus({ searchVersion });
      }}
      onOptionSelect={async (selectedOption, searchString): Promise<void> => {
        const option = await transformToSelectedManagementOption(
          selectedOption
        );

        if (!option) {
          return;
        }

        navigateToProperty(option.value);
        setAsPristine();
        analytics.trackGlobalSearchItemSelected({
          searchResultCategory: "Property",
          searchResultClickedString: option.label,
          searchString,
          searchVersion
        });
      }}
      testID={"GlobalSearchBox"}
      isMulti={false}
    />
  );
};
