import { ID } from "@ailo/ailorn";
import { ParamListBase } from "@react-navigation/native";
import { Screens } from "../Screens";
import { CommsTabStackNavigatorParamList } from "./CommsTabStackNavigatorParamList";
import { ProjectTabStackNavigatorParamList } from "./ProjectTabStackNavigatorParamList";
import { PropertiesTabStackNavigatorParamList } from "./PropertiesTabStackNavigatorParamList";
import { ReportsTabStackNavigatorParamList } from "./ReportsTabStackNavigatorParamList";
import { StackNavigatorParams } from "./StackNavigatorParams";
import { ContactsTabStackNavigatorParamList } from "./ContactsTabStackNavigatorParamList";
import { OnboardingTabParamList } from "./OnboardingTabParamList";
import { WalletTabStackNavigatorParamList } from "./WalletTabStackNavigatorParamList";
import { BillPayerType } from "local/graphql";
import { DisbursementsTabParamList } from "./DisbursementsTabParamList";
import { TransactionsTabParamList } from "./TransactionsTabParamList";

export interface AppDrawerNavigatorParamList extends ParamListBase {
  [Screens.CommsTab]: StackNavigatorParams<CommsTabStackNavigatorParamList>;
  [Screens.PropertiesTab]: StackNavigatorParams<PropertiesTabStackNavigatorParamList>;
  [Screens.ProjectsTab]: StackNavigatorParams<ProjectTabStackNavigatorParamList>;
  [Screens.ContactsTab]: StackNavigatorParams<ContactsTabStackNavigatorParamList>;
  [Screens.BillsTab]?: {
    tab?: "upcoming" | "overdue" | "paid" | "cancelled";
    payerType?: BillPayerType;
    billId?: "new" | string;
    billSubview?: "cancel";
  };
  [Screens.WalletTab]?: StackNavigatorParams<WalletTabStackNavigatorParamList>;
  [Screens.ReportsTab]?: StackNavigatorParams<ReportsTabStackNavigatorParamList>;
  [Screens.OnboardingTab]?: OnboardingTabParamList;
  [Screens.SettingsTab]?:
    | undefined
    | {
        tab: "fees" | "emails" | "forms" | "banking";
        id?: "new" | string;
      };
  [Screens.DisbursementsList]?: DisbursementsTabParamList;
  [Screens.TransactionsList]?: TransactionsTabParamList;
  [Screens.Portfolio]: {
    portfolioId: ID<"propertymanagement:managementfolio">;
  };
}
