import { AiloRN, services } from "@ailo/ailorn";
import { AiloSentry, useApolloClient, useAuth } from "@ailo/services";
import { AnalyticsService } from "local/common";
import { useCallback, useEffect } from "react";
import {
  GetAnalyticsDataDocument,
  GetAnalyticsDataQuery,
  useGetAnalyticsDataQuery
} from "local/graphql";

function setAnalyticsContext(
  analytics: AnalyticsService,
  data: GetAnalyticsDataQuery | undefined
): void {
  analytics.identify({
    traits: {
      firstName: data?.effectiveUser?.person.firstName,
      lastName: data?.effectiveUser?.person.lastName,
      email: data?.effectiveUser?.person.emailAddress
    }
  });

  const defaultOrg = data?.effectiveUser?.organisations?.[0];
  const defaultOrgAilorn = defaultOrg
    ? AiloRN.of(services.AuthZ.organisation, defaultOrg.id).toString()
    : undefined;

  analytics.mergeContext({
    organisation_id: defaultOrgAilorn ? [defaultOrgAilorn] : []
  });
}

export function useAnalyticsContext(analytics: AnalyticsService): {
  reload(): Promise<void>;
} {
  const client = useApolloClient();
  const { isAuthenticated } = useAuth();
  const result = useGetAnalyticsDataQuery({
    skip: !isAuthenticated
  });

  const reload = useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }

    const { data } = await client
      .query<GetAnalyticsDataQuery, {}>({
        query: GetAnalyticsDataDocument
      })
      .catch((error) => {
        if (error.networkError) {
          AiloSentry.captureMessage(
            "useAnalyticsContext.reload() failed because of a network error. Analytics events may contain incomplete information."
          );
        } else {
          AiloSentry.captureException(error);
        }
        return { data: undefined };
      });

    setAnalyticsContext(analytics, data);
  }, [analytics, client, isAuthenticated]);

  useEffect(() => {
    setAnalyticsContext(analytics, result.data);
  }, [analytics, result.data]);

  return { reload };
}
