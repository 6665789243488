import { Money } from "@ailo/ui";
import Big from "big.js";
import React, { ReactElement } from "react";
import { ExpenseFormData, ExpenseModalForm } from "../ExpenseModalForm";
import {
  buildEditExpenseDetail,
  EditOneOffFeeDetail
} from "./buildEditExpenseDetail";
import { EditExpenseModalFormData } from "./types";
import {
  useUpdateManagementFee,
  useUpdateOneOffFee,
  useUpdateRecurringFee
} from "./useUpdateExpense";

interface EditExpenseModalFormControllerProps {
  expense?: EditExpenseModalFormData;
  onDismiss: () => void;
}

export function EditExpenseModalFormController({
  expense: expenseProp,
  onDismiss
}: EditExpenseModalFormControllerProps): ReactElement | null {
  const [updateManagementFee, submittingManagementFee] =
    useUpdateManagementFee();
  const [updateRecurringFee, submittingRecurringFee] = useUpdateRecurringFee();
  const [updateOneOffFee, submittingOneOffFee] = useUpdateOneOffFee();

  if (!expenseProp) return null;

  const expense = buildEditExpenseDetail(expenseProp);

  const initialValues =
    expense.type === "ManagementFeeSchedule"
      ? {
          percentage: expense.percentage,
          description: null
        }
      : expense.type === "RecurringFee" || expense.type === "OneOffFee"
      ? {
          totalAmount: expense.totalAmount,
          description: expense.description
        }
      : {
          totalAmount: Money.zero(),
          description: null
        };

  const defaultValues =
    expense.type === "RecurringFee"
      ? {
          totalAmount: expense.blueprint.amountIncludingTax
        }
      : expense.type === "OneOffFee" &&
        canCalculateDefaultTotalAmountForOneOffFee(expense)
      ? {
          totalAmount:
            expense.blueprint.amountIncludingTax ??
            Money.fromCents(
              expense.blueprint.oneWeekRentPercentageIncludingTax! *
                expense.currentWeeklyRent!.cents
            )
        }
      : undefined;

  const submit = async (data: ExpenseFormData): Promise<void> => {
    if (
      expense.type === "ManagementFeeSchedule" &&
      data.percentage != undefined
    ) {
      await updateManagementFee({
        managementAgreementAiloRN: expense.managementAgreementAilorn.toString(),
        managementId: expense.managementAilorn.internalId,
        percentage: data.percentage
      });
    } else if (expense.type === "RecurringFee" && data.totalAmount) {
      await updateRecurringFee({
        ailorn: expense.ailorn,
        managementId: expense.managementAilorn.internalId,
        futureSchedules:
          expense.nextOccurrence &&
          data.totalAmount.cents !== expense.totalAmount.cents
            ? [
                {
                  amount: { cents: data.totalAmount.cents },
                  startDate: expense.nextOccurrence.date
                }
              ]
            : undefined,
        description: data.description ?? null,
        blueprintId: expense.blueprint.ailorn.internalId,
        blueprintAmount: expense.blueprint.amount,
        frequency: expense.frequency
      });
    } else if (expense.type === "OneOffFee" && data.totalAmount) {
      await updateOneOffFee({
        ailorn: expense.ailorn,
        totalAmount: data.totalAmount,
        taxAmount: Money.from(data.totalAmount).divide(11),
        percentage: isPercentageBasedFee(expense)
          ? new Big(data.totalAmount.cents)
              .div(expense.currentWeeklyRent!.cents)
              .round(4)
              .toNumber()
          : undefined,
        baseAmount: isPercentageBasedFee(expense)
          ? expense.currentWeeklyRent
          : undefined,
        description: data.description ?? null,
        blueprintId: expense.blueprint.ailorn.internalId,
        blueprintAmount: expense.blueprint.amount,
        blueprintPercent: expense.blueprint.oneWeekRentPercentage
      });
    }
    onDismiss();
  };

  return (
    <ExpenseModalForm
      expenseType={expense.type}
      mode={"edit"}
      title={`Edit ${expense.name}`}
      initialValues={initialValues}
      defaultValues={defaultValues}
      frequency={
        expense.type === "RecurringFee" ? expense.displayFrequency : undefined
      }
      onSubmit={submit}
      submitting={
        submittingManagementFee || submittingRecurringFee || submittingOneOffFee
      }
      onCancel={onDismiss}
      chargeDescription={expense.chargeDescription}
      hasNextOccurrence={
        expense.type === "RecurringFee" ? !!expense.nextOccurrence : undefined
      }
      visible={!!expense}
    />
  );
}

function canCalculateDefaultTotalAmountForOneOffFee(
  fee: EditOneOffFeeDetail
): fee is EditOneOffFeeDetail & {
  blueprint:
    | { amountIncludingTax: Money }
    | { oneWeekRentPercentageIncludingTax: number };
} {
  return (
    fee.blueprint.amountIncludingTax !== undefined ||
    (fee.currentWeeklyRent !== undefined &&
      fee.blueprint.oneWeekRentPercentageIncludingTax !== undefined)
  );
}

function isPercentageBasedFee(fee: EditOneOffFeeDetail): boolean {
  return (
    fee.blueprint.amount === undefined &&
    fee.percentage !== undefined &&
    !!fee.currentWeeklyRent
  );
}
