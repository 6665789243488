import { FeeBlueprintType } from "@ailo/domains";
import {
  Alert,
  CheckboxInput,
  ConfirmModal,
  feeMaxMoney,
  FormField,
  Money,
  MoneyInput,
  PercentInput,
  SelectInput,
  TextInputFormField,
  validateMaxLength
} from "@ailo/ui";
import { GstBox, ResetButton } from "local/domain/expense";
import { MAX_DESCRIPTION_LENGTH } from "local/domain/expense/ExpenseModalForm";
import { isEmpty } from "lodash";
import React, { ReactElement, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { View } from "react-native";
import { AgencyFeeBlueprintOption } from "./createAgencyBlueprintOptions";

export interface ManagementFeeBlueprintModalFormData {
  feeBlueprint: AgencyFeeBlueprintOption;
  fixedAmount?: Money;
  oneWeekRentPercentage?: number;
  includesGst: boolean;
  description?: string;
}

export interface ManagementFeeBlueprintModalFormProps {
  mode: "add" | "edit";
  title: string;
  agencyFeeBlueprints: Array<AgencyFeeBlueprintOption>;
  initialValues?: ManagementFeeBlueprintModalFormData;
  onSubmit: (data: ManagementFeeBlueprintModalFormData) => Promise<void> | void;
  submitting: boolean;
  onCancel: () => void;
  visible: boolean;
}

type IncompleteForm<Form> = {
  [Key in keyof Form]?: Form[Key] | null;
};
type Form = IncompleteForm<ManagementFeeBlueprintModalFormData>;

export function ManagementFeeBlueprintModalForm({
  mode,
  title,
  agencyFeeBlueprints,
  initialValues,
  onSubmit,
  submitting,
  onCancel,
  visible
}: ManagementFeeBlueprintModalFormProps): ReactElement {
  const { setValue, watch, errors, handleSubmit, control } = useForm<Form>({
    defaultValues: initialValues
  });
  const submit = handleSubmit(onSubmit);
  const fixedAmount = watch("fixedAmount");
  const oneWeekRentPercentage = watch("oneWeekRentPercentage");
  const includesGst = watch("includesGst");
  const feeBlueprint = watch("feeBlueprint");

  useEffect(() => {
    if (visible) {
      setValue("feeBlueprint", initialValues?.feeBlueprint);
      setValue("fixedAmount", initialValues?.fixedAmount);
      setValue("oneWeekRentPercentage", initialValues?.oneWeekRentPercentage);
      setValue("includesGst", initialValues?.includesGst);
      setValue("description", initialValues?.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (feeBlueprint?.type !== FeeBlueprintType.EventBasedFee) {
      setValue("description", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeBlueprint?.type]);

  const resetPrice = useCallback(
    (blueprint?: AgencyFeeBlueprintOption): void => {
      setValue("fixedAmount", blueprint?.fixedAmount ?? null, {
        shouldValidate: true
      });
      setValue(
        "oneWeekRentPercentage",
        blueprint?.oneWeekRentPercentage ?? null,
        {
          shouldValidate: true
        }
      );
    },
    [setValue]
  );

  const resetPriceButton = (
    <ResetButton
      visible={
        fixedAmount?.cents !== feeBlueprint?.fixedAmount?.cents ||
        oneWeekRentPercentage != feeBlueprint?.oneWeekRentPercentage
      }
      onPress={feeBlueprint ? () => resetPrice(feeBlueprint) : undefined}
    />
  );

  const resetPriceAndTaxTreatmentToDefault = (
    blueprint: AgencyFeeBlueprintOption
  ): void => {
    resetPrice(blueprint);
    setValue("includesGst", blueprint.includesGst, { shouldValidate: true });
  };

  return (
    <ConfirmModal
      title={title}
      onConfirm={submit}
      onCancel={onCancel}
      showFooterBorder={true}
      confirmLabel={mode === "add" ? "Add" : "Update"}
      borderRadius={12}
      loading={submitting}
      loadingButtonWidth={mode === "add" ? 62.61 : 84.11}
      visible={visible}
      headerAndContentContainerStyle={{ paddingBottom: 60 }}
    >
      <>
        <Controller
          name={"feeBlueprint"}
          control={control}
          defaultValue={initialValues?.feeBlueprint ?? null}
          render={({ value, onChange, ref }): ReactElement => {
            return (
              <View
                ref={ref}
                style={{ display: mode === "edit" ? "none" : undefined }}
              >
                <FormField
                  label={"Fee template"}
                  testID={"FeeBlueprintInputFormField"}
                  error={
                    (errors.feeBlueprint as { message: string } | undefined)
                      ?.message
                  }
                >
                  <SelectInput
                    inputRef={ref}
                    disabled={mode === "edit"}
                    value={
                      value &&
                      (agencyFeeBlueprints.find(
                        (opt) => opt.value === value.value
                      ) ??
                        value)
                    }
                    onChange={(value) => {
                      onChange(value);
                      resetPriceAndTaxTreatmentToDefault(value);
                    }}
                    placeholder={"Select fee template"}
                    options={agencyFeeBlueprints}
                    noOptionsLabel={
                      "No fee templates available. Fee templates can be created by administrators within agency settings."
                    }
                    useTextInputHeight
                  />
                </FormField>
              </View>
            );
          }}
          rules={{
            validate: (option): boolean | string =>
              (!isEmpty(option?.label) && !isEmpty(option?.value)) ||
              "Select fee template"
          }}
        />
        <Controller
          control={control}
          defaultValue={
            initialValues?.fixedAmount ?? feeBlueprint?.fixedAmount ?? null
          }
          name={"fixedAmount"}
          render={({ value, onChange, onBlur, ref }) => (
            <View
              ref={ref}
              style={{
                marginTop: mode === "add" ? 24 : 0,
                display: !feeBlueprint?.fixedAmount ? "none" : undefined
              }}
            >
              <FormField
                label={"Price"}
                labelRight={resetPriceButton}
                error={
                  (errors.fixedAmount as { message?: string } | undefined)
                    ?.message
                }
              >
                <MoneyInput
                  {...{
                    value,
                    onBlur
                  }}
                  onChange={(money) =>
                    onChange(money ? Money.from(money) : null)
                  }
                  allowNull
                  withTrailingZeros
                />
              </FormField>
            </View>
          )}
          rules={{
            required:
              feeBlueprint?.fixedAmount != undefined
                ? "Price is required."
                : false,
            validate: (fixedAmount: Money | null): boolean | string =>
              fixedAmount != undefined
                ? fixedAmount.cents < 0
                  ? `Price can't be less than ${Money.zero().format()}.`
                  : fixedAmount.cents > feeMaxMoney.cents
                  ? `Price can't be more than ${feeMaxMoney.format()}.`
                  : true
                : true
          }}
        />
        <Controller
          control={control}
          defaultValue={
            initialValues?.oneWeekRentPercentage ??
            feeBlueprint?.oneWeekRentPercentage ??
            null
          }
          name={"oneWeekRentPercentage"}
          render={({ value, onChange, onBlur, ref }) => (
            <View
              ref={ref}
              style={{
                marginTop: mode === "add" ? 24 : 0,
                display:
                  feeBlueprint?.oneWeekRentPercentage == undefined
                    ? "none"
                    : undefined
              }}
            >
              <FormField
                label={"Price"}
                labelRight={resetPriceButton}
                error={errors.oneWeekRentPercentage?.message}
              >
                <PercentInput
                  {...{
                    value,
                    onChange,
                    onBlur
                  }}
                  format={"decimal"}
                  decimalPlaces={4}
                  endAdornment={"weeks of rent"}
                />
              </FormField>
            </View>
          )}
          rules={{
            required:
              feeBlueprint?.oneWeekRentPercentage != undefined
                ? "Price is required."
                : false,
            validate: (
              oneWeekRentPercentage: number | null
            ): boolean | string =>
              oneWeekRentPercentage != undefined
                ? oneWeekRentPercentage < 0
                  ? "Price can't be less than 0%."
                  : true
                : true
          }}
        />
        <Controller
          name={"includesGst"}
          defaultValue={
            initialValues?.includesGst ?? feeBlueprint?.includesGst ?? true
          }
          control={control}
          render={({ onChange, value }) => (
            <CheckboxInput
              testID={"IncludeGstCheckboxInput"}
              label={"Includes GST"}
              style={{
                marginTop: 16,
                display: !feeBlueprint ? "none" : undefined
              }}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {feeBlueprint?.fixedAmount && (
          <GstBox {...{ includesGst, fixedAmount }} />
        )}
        {feeBlueprint?.type === FeeBlueprintType.EventBasedFee && (
          <Controller
            control={control}
            name={"description"}
            defaultValue={null}
            render={({ value, onChange, onBlur, ref }) => (
              <View ref={ref}>
                <TextInputFormField
                  {...{ value, onChange, onBlur }}
                  label={"Enter a description for the payer (optional)"}
                  error={errors.description?.message}
                  placeholder={"Description"}
                  softCharacterLimit={{
                    limit: MAX_DESCRIPTION_LENGTH,
                    enforcementLevel: "error"
                  }}
                  style={{
                    marginTop: 24,
                    display:
                      feeBlueprint?.type === FeeBlueprintType.EventBasedFee
                        ? undefined
                        : "none"
                  }}
                />
              </View>
            )}
            rules={{
              validate: validateMaxLength(MAX_DESCRIPTION_LENGTH)
            }}
          />
        )}
        {mode === "edit" && (
          <Alert type={"info"} style={{ marginTop: 16 }}>
            {
              "Editing templates will only affect future fees. It will not change fees that have already been charged."
            }
          </Alert>
        )}
      </>
    </ConfirmModal>
  );
}
