import React from "react";
import { DateTimeWithTimeZone, TextCell } from "@ailo/ui";
import { useGetEndDateQuery } from "local/graphql";
import { ErrorCell } from "./ErrorCell";
import { BasePropertyListRow } from "../BasePropertyListRow";

function EndDateCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetEndDateQuery({
    variables: { managementId }
  });

  if (loading && !data) {
    return <Loading />;
  }

  const management = data?.management;
  if (!management)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const endDate = management.endDate;

  if (!endDate) {
    return <></>;
  }

  const ailoEndDate = DateTimeWithTimeZone.fromLocalDate(endDate);

  return <TextCell>{ailoEndDate.format("DD MMM YYYY")}</TextCell>;
}

function Loading(): React.ReactElement {
  return <TextCell.Loading />;
}

EndDateCell.Loading = Loading;

export { EndDateCell };
