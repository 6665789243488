import { TextInputFormField, validateMaxLength } from "@ailo/ui";
import React from "react";
import { Controller } from "react-hook-form";
import { useManagementNoteForm } from "../ManagementNoteFormContext";
import { ManagementNoteFields } from "../ManagementNoteFormData";

export function NoteInput({
  mode
}: {
  mode: "edit" | "create";
}): React.ReactElement {
  const { control, watch, errors } = useManagementNoteForm();
  const currentNote = watch(ManagementNoteFields.note);

  return (
    <Controller
      control={control}
      name={ManagementNoteFields.note}
      defaultValue={currentNote || ""}
      render={({ value, onChange, onBlur }) => (
        <TextInputFormField
          {...{ value, onChange, onBlur }}
          placeholder={"Enter notes here..."}
          multiline
          numberOfLines={10}
          style={{ marginBottom: 20 }}
          error={errors.note?.message}
          testID={"ManagementNoteNote"}
          softCharacterLimit={{
            limit: 500,
            enforcementLevel: "error"
          }}
        />
      )}
      rules={{
        validate: {
          maxLength: validateMaxLength(500),
          required: (value?: string | null) => {
            if (mode === "create" && !value) return "Please enter a note";
            return true;
          }
        }
      }}
      accessibilityLabel={`management-note-note`}
    />
  );
}
