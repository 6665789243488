import React, { ReactElement } from "react";
import { Colors, opacify, Text, WarningSubtractedIcon } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

interface Props {
  title: string;
}

export function HealthIssuesHeader({ title }: Props): ReactElement {
  return (
    <>
      <WarningGradient
        colors={[
          opacify(Colors.STATUS.WARNING, 0.1),
          opacify(Colors.STATUS.WARNING, 0.0)
        ]}
      />
      <HeaderContents>
        <WarningSubtractedIcon />
        <Text.BodyM weight={"medium"}>{title}</Text.BodyM>
      </HeaderContents>
    </>
  );
}

const HeaderContents = styled(View)`
  padding: 16px;
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  gap: 16px;
`;

const WarningGradient = styled(LinearGradient)`
  position: absolute;
  height: 56px;
  width: 100%;
`;
