import React, { ReactElement, ReactNode } from "react";
import { View } from "react-native";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { MessageContactsIconButton } from "../../PropertyContentCard";
import styled from "styled-components/native";
import { AiloRN } from "@ailo/ailorn";

interface Props {
  tenantContactAilorns: AiloRN<"contact:contact">[];
  headerRightComponent?: ReactNode;
}

export function BaseTenancyCardTitleRight({
  tenantContactAilorns,
  headerRightComponent
}: Props): ReactElement {
  const hasChatFeature = useHasFeature(PlatformFeatureId.Chat);

  if (!hasChatFeature || tenantContactAilorns.length === 0) {
    return <>{headerRightComponent}</>;
  }

  return (
    <TitleRightContainer>
      <MessageContactsIconButton
        participantContactAilorns={tenantContactAilorns}
      />
      <VerticalSeparator />
      {headerRightComponent}
    </TitleRightContainer>
  );
}

const TitleRightContainer = styled(View)`
  display: flex;
  flex-direction: row;
`;

const VerticalSeparator = styled(View)`
  border-right-style: solid;
  border-right-color: rgba(28, 30, 38, 0.2);
  border-right-width: 1px;
  height: 20px;
  margin-left: 20px;
  margin-right: 20px;
`;
