import { formatAddress } from "@ailo/domain-helpers";
import { useGetDefaultWalletForManagementQuery } from "@ailo/domains";
import { Text } from "@ailo/primitives";
import { Money, Separator } from "@ailo/ui";
import { SplitScreenInfoPanel } from "local/common";
import { InvestorChips, TenantChips } from "local/domain/propertyManagement";
import moment from "moment";
import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { isPresent } from "ts-is-present";
import { GetAddExpenseScreenDetailsQuery } from "local/graphql";

interface AddExpenseInfoPanelProps {
  management: NonNullable<GetAddExpenseScreenDetailsQuery["management"]>;
}

export function AddExpenseInfoPanel({
  management
}: AddExpenseInfoPanelProps): ReactElement {
  const walletResult = useGetDefaultWalletForManagementQuery({
    variables: {
      walletOwnerReference: management.ailoRN
    }
  });

  const availableBalanceText = walletResult.data?.walletByWalletOwnerReference
    ?.availableBalance
    ? Money.from(
        walletResult.data.walletByWalletOwnerReference.availableBalance
      ).format()
    : undefined;

  const { property, mostRecentTenancy } = management;

  const mostRecentTenancyIsActive =
    !mostRecentTenancy?.endDate ||
    moment(mostRecentTenancy?.endDate).isSameOrAfter(moment().startOf("day"));

  const rows = [
    [
      {
        label: "Property",
        content:
          formatAddress(property.address, {
            format: "street suburb state"
          }) || null
      }
    ],
    [
      {
        label: "Property Wallet Balance",
        content: availableBalanceText || null
      }
    ],
    [
      {
        content: <StyledSeparator />
      }
    ],
    [
      {
        label: "Investors",
        content: management?.owners ? (
          <InvestorChips
            owners={management.owners
              .map((ownership) => ownership?.owner)
              .filter(isPresent)}
          />
        ) : null
      }
    ],
    [
      {
        label: "Current Renters",
        content:
          mostRecentTenancyIsActive && mostRecentTenancy?.tenantships ? (
            <TenantChips
              tenants={mostRecentTenancy?.tenantships.items
                .map((tenantship) => tenantship.tenant)
                .filter(isPresent)}
            />
          ) : (
            <Text.BodyM>{"Vacant"}</Text.BodyM>
          )
      }
    ]
  ];

  return <SplitScreenInfoPanel rows={rows} />;
}

AddExpenseInfoPanel.Loading = function Loading() {
  const rows = [
    [
      {
        label: <Text.Loading variant={"BodyS"} width={60} />,
        content: <Text.Loading variant={"BodyS"} width={120} />
      }
    ],
    [
      {
        label: <Text.Loading variant={"BodyS"} width={60} />,
        content: <Text.Loading variant={"BodyS"} width={120} />
      }
    ]
  ];
  return <SplitScreenInfoPanel rows={rows} />;
};

const StyledSeparator = styled(Separator)`
  margin-top: 2px;
  margin-bottom: 4px;
  margin-left: 0;
  width: 308px;
`;
