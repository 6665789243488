import { DropdownMenuOption } from "@ailo/ui";
import { EditIcon } from "@ailo/primitives";
import React from "react";

export function getEditIngoingTenancyOption(
  onEditPress?: () => void,
  disabledTooltip?: string
): DropdownMenuOption {
  return {
    label: "Edit ingoing tenancy",
    icon: <EditIcon />,
    onSelect: (): void => onEditPress?.(),
    disabled: !onEditPress,
    tooltip: disabledTooltip
  };
}
