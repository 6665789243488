import { ManagementFilterParams, ManagementFilterTypes } from "local/graphql";
import { PropertyListSelectedTeams } from "./usePropertyListSelectedTeams";

export function getTeamsManagementFilterParams(
  selectedTeams: PropertyListSelectedTeams
): ManagementFilterParams[] {
  return selectedTeams.teamIds.length > 0 || selectedTeams.unassignedSelected
    ? [
        {
          name: ManagementFilterTypes.BelongToTeamsAndUnassigned,
          args: {
            BelongToTeamsAndUnassigned: {
              teamIds: selectedTeams.teamIds,
              includeUnassigned: selectedTeams.unassignedSelected
            }
          }
        }
      ]
    : [];
}
