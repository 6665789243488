import { Colors } from "@ailo/primitives";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { useInspectionPlanner } from "../InspectionPlannerContext";
import { AppointmentTable } from "./AppointmentTable/AppointmentTable";
import { AppointmentMap } from "./AppointmentMap";
import { InspectionDayDisplayHeader } from "./InspectionDayDisplayHeader";

export function InspectionDayDisplay(): React.ReactElement | null {
  const { selectedInspectionDay } = useInspectionPlanner();

  if (!selectedInspectionDay) {
    return null;
  }

  return (
    <View style={{ flex: 1 }}>
      <SectionWithBorder>
        <InspectionDayDisplayHeader style={{ marginBottom: 32 }} />
        <AppointmentTable />
      </SectionWithBorder>
      <Section>
        <AppointmentMap />
      </Section>
    </View>
  );
}

const Section = styled(View)`
  padding-top: 32px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 40px;
`;

const SectionWithBorder = styled(Section)`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${Colors.GRAYSCALE.OUTLINE};
`;
