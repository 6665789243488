import { RecoverableError } from "@ailo/services";
import { useCurrentAgencyOrg } from "local/common";
import {
  RelayPageInfo,
  useListTrustAccountAdjustmentsQuery
} from "local/graphql";
import { useMemo } from "react";
import { ReconciliationPeriod } from "../ReconciliationViewScreen/types";
import { RowData } from "./gridConfig";
import { AdjustmentListFilter } from "./types";

interface AdjustmentGridDataArgs {
  filter: AdjustmentListFilter;
  period: ReconciliationPeriod;
  pageSize: number;
  onDeletePress: (id: string) => void;
}

type QueryResult = ReturnType<typeof useListTrustAccountAdjustmentsQuery>;

type AdjustmentGridData =
  | { loading: true }
  | ({ loading: boolean; error: Error } & Pick<QueryResult, "refetch">)
  | ({
      loading: false;
      error?: undefined;
      pageInfo: RelayPageInfo;
      total: number;
      rows: RowData[];
    } & Pick<QueryResult, "fetchMore">);

export function useAdjustmentGridData({
  filter,
  period,
  pageSize,
  onDeletePress
}: AdjustmentGridDataArgs): AdjustmentGridData {
  const trustAccountAilorn =
    useCurrentAgencyOrg().defaultWalletLegalEntity?.defaultTrustAccount?.ailorn;
  const periodEnd = period.periodEnd.toString();
  const filtersForCurrentAdjustments = [
    {
      applyOn: { lte: periodEnd }
    },
    {
      or: [{ removedFrom: { is: null } }, { removedFrom: { gt: periodEnd } }]
    }
  ];
  const { loading, data, refetch, fetchMore } =
    useListTrustAccountAdjustmentsQuery({
      variables: {
        filter: {
          and: [
            {
              reportPeriod: { trustAccountAilorn: { eq: trustAccountAilorn } }
            },
            ...(filter === "current" ? filtersForCurrentAdjustments : [])
          ]
        },
        paging: {
          first: pageSize
        }
      }
    });

  const {
    edges,
    pageInfo,
    totalCount: total
  } = data?.trustAccountReconciliationAdjustments ?? {};

  const { locked } = period;
  const rows = useMemo<RowData[] | undefined>(
    () =>
      edges?.map(({ node }) => ({
        ...node,
        isPeriodLocked: locked,
        onDeletePress: () => onDeletePress(node.id),
        key: node.id
      })),
    [edges, onDeletePress, locked]
  );

  if (loading && !data) return { loading };
  if (rows === undefined || !pageInfo || total === undefined) {
    return {
      loading,
      error: new RecoverableError(
        "Adjustment information did not load",
        refetch
      ),
      refetch
    };
  }
  return {
    loading: false,
    fetchMore,
    pageInfo,
    total,
    rows
  };
}
