import React, { PropsWithChildren, ReactElement } from "react";
import { Button } from "@ailo/ui";
import { Text } from "@ailo/primitives";

export function NotesAndKeysButton({
  children,
  onPress
}: PropsWithChildren<{
  onPress?: () => void;
}>): ReactElement {
  return (
    <Button
      variant={"secondary"}
      type={"text-extra-small"}
      onPress={onPress}
      style={{
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 8,
        paddingRight: 8,
        borderRadius: 13,
        marginRight: 8
      }}
    >
      <Text.BodyXS weight={"medium"}>{children}</Text.BodyXS>
    </Button>
  );
}
