import React, { ReactElement } from "react";
import { ActionType } from "local/graphql";
import { RefinedActionActivityProps } from "local/tabs/projects/actions";
import { InspectionAppointmentExists } from "./InspectionAppointmentExists";
import { NoInspectionAppointmentView } from "../CommonInspectionActionViews";

export function TrackInspectionCompletedActionActivity({
  action,
  projectId
}: RefinedActionActivityProps<ActionType.TrackInspectionCompleted>): ReactElement {
  const inspectionAppointment = action.meta.inspectionAppointment;

  if (inspectionAppointment) {
    return (
      <InspectionAppointmentExists
        projectId={projectId}
        inspection={inspectionAppointment.inspection}
      />
    );
  }

  return <NoInspectionAppointmentView projectId={projectId} action={action} />;
}
