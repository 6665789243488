import { Colors, Text } from "@ailo/primitives";
import React, { ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { getRelativeTimeFromDateString } from "../utils";

export function RelativeTimeInDays({
  style,
  date
}: {
  style?: StyleProp<ViewStyle>;
  date?: string;
}): ReactElement | null {
  if (!date) return null;

  return (
    <Text.BodyXS style={style} color={Colors.TEXT.DARK.SECONDARY}>
      {getRelativeTimeFromDateString(date)}
    </Text.BodyXS>
  );
}
