import { Colors, Text } from "@ailo/primitives";
import { withErrorBoundary } from "@ailo/services";
import { ErrorAlertScreen } from "@ailo/ui";
import React from "react";
import styled from "styled-components/native";
import { TabContent } from "../common";
import { FormList } from "./FormList";

export const FormConnectionListTab = withErrorBoundary(
  function FormConnectionListTab(): React.ReactElement {
    return (
      <TabContent title={"Form Connections"}>
        <Description>
          {
            "To seamlessly send forms and agreements to customers, connect your 3rd party account to Ailo"
          }
        </Description>
        <FormList />
      </TabContent>
    );
  },
  {
    fallbackComponent: function Fallback(props) {
      return (
        <TabContent>
          <ErrorAlertScreen
            title={"There was a problem loading Forms"}
            onRetry={props.retry}
          />
        </TabContent>
      );
    }
  }
);

const Description = styled(Text.BodyM)`
  margin-top: 12px;
  color: ${Colors.TEXT.DARK.SECONDARY};
`;
