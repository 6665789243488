import { AiloRN } from "@ailo/ailorn";
import {
  useDeleteCentrepayDirectiveMutation,
  useDeleteCrnMutation
} from "local/graphql";
import { useCallback } from "react";

export function useDeleteCentrepay(
  managingEntity: AiloRN<string>,
  tenancy: AiloRN<string>,
  renter: AiloRN<string>,
  onDelete: () => Promise<void>
): () => Promise<void> {
  const [deleteCentrepayDirectiveMutation] =
    useDeleteCentrepayDirectiveMutation();
  const [deleteCrnMutation] = useDeleteCrnMutation();
  return useCallback(async () => {
    const directiveResponse = await deleteCentrepayDirectiveMutation({
      variables: {
        input: {
          managingEntity: managingEntity.toString(),
          tenancy: tenancy.toString(),
          legalEntity: renter.toString()
        }
      }
    });
    const crnResponse = await deleteCrnMutation({
      variables: {
        input: {
          managingEntity: managingEntity.toString(),
          legalEntity: renter.toString()
        }
      }
    });

    if (
      directiveResponse?.data?.deleteCentrepayDirective ||
      crnResponse?.data?.deleteCrn
    ) {
      await onDelete();
    }
  }, [
    deleteCentrepayDirectiveMutation,
    deleteCrnMutation,
    managingEntity,
    tenancy,
    renter,
    onDelete
  ]);
}
