import { AttachmentsInput, FileFormAdapter } from "@ailo/domains";
import { Text } from "@ailo/primitives";
import React from "react";
import { useFormContext } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";

export function FilesInput({
  label = "Attachments",
  onAttached,
  style
}: {
  label?: string;
  onAttached?: () => void;
  style?: StyleProp<ViewStyle>;
}): JSX.Element {
  const { control } = useFormContext();

  return (
    <View style={style}>
      <Text.BodyL weight={"medium"} style={{ marginBottom: 20 }}>
        {label}
      </Text.BodyL>
      <AttachmentsInput clearContextOnUnmount={true} />
      <FileFormAdapter
        control={control}
        name={"attachmentsFiles"}
        onChange={({ wasFileAdded }) => {
          if (onAttached && wasFileAdded) {
            onAttached();
          }
        }}
      />
    </View>
  );
}
