import { ErrorAlertScreen } from "@ailo/ui";
import { AdjustmentLiabilityEntry } from "local/graphql";
import React from "react";
import { RentAdjustmentsListTableRow } from "./RentAdjustmentsListTableRow";

export function RentAdjustmentsListTable({
  rentAdjustments
}: {
  rentAdjustments: AdjustmentLiabilityEntry[];
}): React.ReactElement {
  return (
    <>
      <RentAdjustmentsListTableRow.Header />
      {rentAdjustments.map((rentAdjustment: any) => (
        <RentAdjustmentsListTableRow
          key={rentAdjustment.id}
          rentAdjustment={rentAdjustment}
        />
      ))}
    </>
  );
}

RentAdjustmentsListTable.Loading =
  function RentAdjustmentsListTableLoading(): React.ReactElement {
    return (
      <>
        <RentAdjustmentsListTableRow.Header />
        <RentAdjustmentsListTableRow.Loading />
        <RentAdjustmentsListTableRow.Loading style={{ opacity: 0.6 }} />
        <RentAdjustmentsListTableRow.Loading style={{ opacity: 0.3 }} />
      </>
    );
  };

RentAdjustmentsListTable.Error = function RentAdjustmentsListTableError({
  onRetry
}: {
  onRetry?(): void;
}): React.ReactElement {
  return (
    <ErrorAlertScreen
      style={{ minHeight: 300 }}
      title={`
      There was a problem loading
      Rent Adjustments
      `}
      onRetry={onRetry}
    />
  );
};
