import { Text } from "@ailo/primitives";
import { formatDate, formatDateDefaultToNA } from "@ailo/services";
import { Alert, ConfirmModal, formatMoney } from "@ailo/ui";
import { getLabelForTenancyVacatingReason } from "local/domain/propertyManagement";
import { TenancyDepositStatus, VacatingReason } from "local/graphql";
import moment from "moment";
import React from "react";

interface ConfirmationModalProps {
  isEdit: boolean;
  amountDueAtEndDate?: number;
  currentPaidToDate?: moment.Moment;
  tenancyEndDate: moment.Moment;
  vacatingReason: VacatingReason;
  vacatingNotes: string;
  visible: boolean;
  depositStatus?: TenancyDepositStatus;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}

export function ConfirmEditEndTenancyModal({
  amountDueAtEndDate,
  currentPaidToDate,
  isEdit,
  tenancyEndDate,
  vacatingReason,
  vacatingNotes,
  visible,
  depositStatus,
  onCancel,
  onConfirm
}: ConfirmationModalProps): React.ReactElement {
  const title = isEdit
    ? "Confirm Edit End of Tenancy"
    : "Confirm End of Tenancy";

  const showDepositFundsWarning =
    depositStatus &&
    [TenancyDepositStatus.Pending, TenancyDepositStatus.Paid].includes(
      depositStatus
    );

  return (
    <ConfirmModal
      title={title}
      onCancel={onCancel}
      onConfirm={onConfirm}
      visible={visible}
    >
      <Text.BodyS style={{ marginBottom: 4 }}>
        {"End of tenancy date:"}
      </Text.BodyS>
      <Text.BodyM weight={"medium"}>
        {formatDateDefaultToNA(tenancyEndDate)}
      </Text.BodyM>

      {vacatingReason && (
        <>
          <Text.BodyS style={{ marginTop: 16, marginBottom: 4 }}>
            {"Vacating reason:"}
          </Text.BodyS>
          <Text.BodyM weight={"medium"}>
            {getLabelForTenancyVacatingReason(vacatingReason)}
          </Text.BodyM>
        </>
      )}

      {vacatingNotes && (
        <>
          <Text.BodyS style={{ marginTop: 16, marginBottom: 4 }}>
            {"Vacating notes:"}
          </Text.BodyS>
          <Text.BodyM weight={"medium"}>{vacatingNotes}</Text.BodyM>
        </>
      )}

      <Text.BodyS style={{ marginTop: 16, marginBottom: 4 }}>
        {"Rent liability will end on:"}
      </Text.BodyS>
      <Text.BodyM weight={"medium"}>
        {formatDateDefaultToNA(tenancyEndDate)}
      </Text.BodyM>

      {currentPaidToDate && (
        <>
          <Text.BodyS style={{ marginTop: 16, marginBottom: 4 }}>
            {"Current rent is paid to:"}
          </Text.BodyS>
          <Text.BodyM weight={"medium"}>
            {formatDate(currentPaidToDate)}
          </Text.BodyM>
        </>
      )}

      {!!amountDueAtEndDate && amountDueAtEndDate < 0 && (
        <Alert
          style={{ marginTop: 20 }}
          type={"warning"}
          message={`A total of ${formatMoney(
            { cents: -1 * amountDueAtEndDate },
            { withSign: true }
          )} in rent has been overpaid based on the end of tenancy date`}
        />
      )}

      {showDepositFundsWarning && (
        <Alert
          style={{ marginTop: 20 }}
          type={"warning"}
          message={
            "Please contact Ailo support to assist you transferring or refunding the Initial Payment related to this tenancy."
          }
        />
      )}
    </ConfirmModal>
  );
}
