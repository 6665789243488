import { CheckboxInput, HeaderCellContainer } from "@ailo/ui";
import React, { ReactElement } from "react";

export function CheckboxHeaderCell({
  value,
  onChange,
  disabled
}: {
  value: boolean;
  disabled: boolean;
  onChange: (nextValue: boolean) => void;
}): ReactElement {
  return (
    <HeaderCellContainer style={{ paddingRight: 0 }}>
      <CheckboxInput
        style={{ height: 20, width: 20, alignItems: "center" }}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </HeaderCellContainer>
  );
}
