import { formatPhoneNumber } from "@ailo/domains";
import { Colors, DeleteIcon, EditIcon, Text } from "@ailo/primitives";
import { Button, Card, ListItem, PersonAvatar, Tooltip } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller, UseFormMethods } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";
import { TenantDetails } from "../../useCreateTenancy";
import { NewTenancyFormData } from "../NewTenancyForm";
import { TenantNoEmailWarning } from "../TenantNoEmailWarning";

interface Props {
  form: UseFormMethods<NewTenancyFormData>;
  initialTenantDetails: TenantDetails;
  index: number;
  deleteButtonOnPress?(): void;
  editButtonOnPress?(): void;
  style?: StyleProp<ViewStyle>;
}

export function TenantEntry({
  form,
  initialTenantDetails,
  index,
  deleteButtonOnPress,
  editButtonOnPress,
  style
}: Props): ReactElement {
  const formTenantDetails: TenantDetails = form.watch(
    `tenants[${index}].details`
  );
  const tenantDetails = formTenantDetails ?? initialTenantDetails;
  return (
    <Card
      style={[
        {
          borderRadius: 8,
          marginTop: 0,
          marginBottom: 0,
          marginHorizontal: 0
        },
        style
      ]}
      innerStyle={{ borderRadius: 8 }}
    >
      <Controller
        control={form.control}
        name={`tenants[${index}].details`}
        defaultValue={tenantDetails}
        render={({ value }) => {
          const { firstName, preferredName, lastName, email } = value;
          return (
            <ListItem
              style={
                email ? { borderType: "none" } : { borderType: "under-all" }
              }
              leftComponent={
                <PersonAvatar
                  size={40}
                  person={{ firstName, lastName }}
                  style={{ marginRight: 16 }}
                />
              }
              header={`${preferredName ?? firstName} ${lastName || ""}`}
              subHeader={
                <>
                  <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
                    {email ? email : "Email not supplied"}
                  </Text.BodyS>
                  <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
                    {value.phoneNumber
                      ? formatPhoneNumber(value.phoneNumber)
                      : "Mobile not supplied"}
                  </Text.BodyS>
                </>
              }
              rightComponent={
                <View style={{ flexDirection: "row" }}>
                  {!!editButtonOnPress && (
                    <Tooltip tooltipContent={"Edit renter"}>
                      <Button.Secondary
                        type={"small"}
                        onPress={editButtonOnPress}
                        square
                      >
                        <EditIcon width={20} height={20} />
                      </Button.Secondary>
                    </Tooltip>
                  )}
                  <View style={{ width: 8 }} />
                  {!!deleteButtonOnPress && (
                    <Tooltip tooltipContent={"Remove renter"}>
                      <Button.Secondary
                        type={"small"}
                        onPress={deleteButtonOnPress}
                        square
                      >
                        <DeleteIcon width={20} height={20} />
                      </Button.Secondary>
                    </Tooltip>
                  )}
                </View>
              }
            />
          );
        }}
      />
      {!tenantDetails.email && (
        <View style={{ padding: 16 }}>
          <TenantNoEmailWarning />
        </View>
      )}
    </Card>
  );
}
