import { Team } from "./useGetManagementPortfolioTeams";
import { useMemo } from "react";
import { isEmpty } from "lodash";

export function useManagementPortfolioTeamsSummary(teams?: Team[]): string {
  return useMemo(() => {
    if (!teams || isEmpty(teams)) return "Unassigned";
    return teams.map((team) => team.name).join(", ");
  }, [teams]);
}
