import { Alert } from "@ailo/ui";
import React, { ReactElement } from "react";
import { DateDuration } from "@ailo/date";
import { StyleProp, ViewStyle } from "react-native";
import { TenancyInspectionScheduleFragment } from "local/graphql";
import { DEFAULT_INSPECTION_FREQUENCY } from "local/domain/inspection";

interface Props {
  existingSchedule: TenancyInspectionScheduleFragment;
  style?: StyleProp<ViewStyle>;
}

export function InspectionFrequencyAlert({
  existingSchedule,
  style
}: Props): ReactElement {
  const frequency =
    existingSchedule.management?.currentOrNextManagementAgreement
      ?.inspectionFrequency;
  const inspectionFrequencyDisplay = `Property is inspected every ${
    frequency
      ? DateDuration.from(frequency).format()
      : DEFAULT_INSPECTION_FREQUENCY.format()
  }`;
  return (
    <Alert type={"info"} style={style} message={inspectionFrequencyDisplay} />
  );
}
