import { LocalDate } from "@ailo/date";
import { DatePeriod } from "./DatePeriod";
import { FinancialYearPeriod } from "./FinancialYearPeriod";
import { MonthPeriod } from "./MonthPeriod";
import { QuarterPeriod } from "./QuarterPeriod";

export function getPeriodConstructor(
  timePeriod: string
): (date: string | LocalDate) => DatePeriod | undefined {
  return (date) => {
    switch (timePeriod) {
      case "month":
        return new MonthPeriod(date);
      case "quarter":
        return new QuarterPeriod(date);
      case "financial year":
        return new FinancialYearPeriod(date);
      default:
        return undefined;
    }
  };
}
