import { MultiSelectButton } from "@ailo/ui";
import { OccupancyStatus } from "local/graphql";
import React, { useCallback, useMemo } from "react";
import { isPresent } from "ts-is-present";

interface FilterProps {
  onChange: (selectedValues: OccupancyStatus[]) => void;
  values: string[];
}

interface Option {
  label: string;
  value: string;
}

export function OccupancyStatusFilter({
  onChange,
  values
}: FilterProps): React.ReactElement {
  const selectedOptions = useMemo(
    () =>
      values
        .map((value) => {
          return options.find((option) => value === option.value);
        })
        .filter(isPresent),
    [values]
  );
  const onChangeOption = useCallback(
    (options: Option[]) => {
      onChange(options.map((opt) => opt.value).filter(isOccupancyStatus));
    },
    [onChange]
  );

  return (
    <MultiSelectButton
      entityType={selectedOptions.length >= 2 ? "Statuses" : "Status"}
      optionGroups={optionGroups}
      value={selectedOptions}
      onChange={onChangeOption}
    />
  );
}

const presetOrderedOccupancyStatuses = [
  OccupancyStatus.Occupied,
  OccupancyStatus.Vacating,
  OccupancyStatus.Vacant
];

const options = getOccupancyOptions(presetOrderedOccupancyStatuses);
const optionGroups = [{ label: "", options }];

function getOccupancyOptions(presetOrders: OccupancyStatus[]): Option[] {
  const remainingTypes = Object.keys(OccupancyStatus)
    .filter(isOccupancyStatus)
    .filter((type) => !presetOrders.includes(type));

  return [...presetOrders, ...remainingTypes].map((type) => ({
    label: type,
    value: type
  }));
}

function isOccupancyStatus(str: string): str is OccupancyStatus {
  return str in OccupancyStatus;
}
