import React, { ReactElement } from "react";
import { ListItem } from "@ailo/ui";
import { Colors, RightChevronIcon, Text } from "@ailo/primitives";

interface Props {
  onPress: () => void;
}
export function MoneySettingButton({ onPress }: Props): ReactElement | null {
  return (
    <ListItem
      style={{ borderType: "none" }}
      header={
        <Text.BodyM weight={"medium"} color={Colors.AILO_RED}>
          {"Wallet Settings"}
        </Text.BodyM>
      }
      rightComponent={<RightChevronIcon height={20} width={20} />}
      onPress={onPress}
    />
  );
}

MoneySettingButton.Loading =
  function MoneySettingButtonLoading(): ReactElement | null {
    return (
      <ListItem
        style={{ borderType: "none" }}
        header={<Text.BodyM.Loading width={120} />}
      />
    );
  };
