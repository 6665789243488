import React from "react";
import {
  ManagementFilterTypes,
  useGetManagementsForOrgCountQuery
} from "local/graphql";
import { useCurrentAgencyOrg } from "local/common";
import { Badge } from "@ailo/ui";
import { getTeamsManagementFilterParams } from "./PropertyList/hooks/getTeamsManagementFilterParams";
import { usePropertyListSelectedTeams } from "./PropertyList/hooks/usePropertyListSelectedTeams";

interface Props {
  active?: boolean;
}

export function ManagementCountBadgeRenderFunction(
  listType: ManagementFilterTypes
): (props: Props) => React.ReactElement {
  function ManagementCountBadge({ active = true }: Props): React.ReactElement {
    const currentOrg = useCurrentAgencyOrg();
    const selectedTeams = usePropertyListSelectedTeams();

    const { data, loading } = useGetManagementsForOrgCountQuery({
      variables: {
        organisationId: currentOrg.ailoRN,
        filters: [
          { name: listType },
          ...getTeamsManagementFilterParams(selectedTeams)
        ],
        pageCursor: {
          cursor: null,
          pageSize: 0,
          paginateBackward: false
        }
      }
    });

    const pageTotal = data?.managementsForOrg?.pageInfo?.total;

    if (loading) {
      return <Badge.Loading width={27} style={{ marginLeft: 8 }} />;
    }

    return (
      <Badge type={active ? "ailo" : "outline"} style={{ marginLeft: 8 }}>
        {pageTotal}
      </Badge>
    );
  }

  return ManagementCountBadge;
}
