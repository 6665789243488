import React from "react";
import { DescriptionList, LocalDate, Money, Separator } from "@ailo/ui";
import { AdjustmentEntryDetails } from "./AdjustmentEntryDetails";
import { adjustmentTypeFromAmount } from "./adjustmentTypeFromAmount";
import styled from "styled-components/native";
import { View } from "react-native";

interface Props {
  adjustmentDetails: AdjustmentEntryDetails;
  reversalDetails?: Omit<
    AdjustmentEntryDetails,
    "createdAt" | "amount" | "reversed"
  >;
}

export function RentAdjustmentSidebarDetails({
  adjustmentDetails,
  reversalDetails
}: Props): React.ReactElement {
  const { amount, createdAt, effectiveAt, description } = adjustmentDetails;

  const items = [
    {
      label: "Created date",
      descriptionBottom: createdAt.format("D MMM YY"),
      columnIndex: 0
    },
    {
      label: "Amount",
      descriptionBottom: Money.fromCents(amount.cents).abs().format(),
      columnIndex: 0
    },
    {
      label: `${adjustmentTypeFromAmount(amount)} date`,
      descriptionBottom: effectiveAt.format("D MMM YY"),
      columnIndex: 1
    }
  ];

  return (
    <Container>
      {reversalDetails && (
        <ReversalDetails
          effectiveAt={reversalDetails.effectiveAt}
          description={reversalDetails.description}
        />
      )}
      <DescriptionListWithMargin
        items={items}
        columns={2}
        columnStyle={{ minWidth: 200, maxWidth: 200 }}
        rowStyle={{ marginTop: 16 }}
      />
      {description && (
        <DescriptionListWithMargin
          items={[{ label: "Description", descriptionBottom: description }]}
        />
      )}
    </Container>
  );
}

function ReversalDetails({
  effectiveAt,
  description
}: {
  effectiveAt: LocalDate;
  description?: string;
}): React.ReactElement {
  return (
    <>
      <DescriptionListWithMargin
        items={[
          {
            label: "Reverse date",
            descriptionBottom: effectiveAt.format("D MMM YY")
          }
        ]}
        rowStyle={{ marginTop: 16 }}
      />
      {description && (
        <DescriptionListWithMargin
          items={[{ label: "Description", descriptionBottom: description }]}
        />
      )}
      <Separator style={{ marginTop: 40, marginLeft: 0, marginRight: -32 }} />
    </>
  );
}

RentAdjustmentSidebarDetails.Loading = RentAdjustmentSidebarDetailsLoading;

function RentAdjustmentSidebarDetailsLoading(): React.ReactElement {
  return (
    <Container>
      <DescriptionList.Loading columns={2} style={{ marginTop: 32 }} />
    </Container>
  );
}

const DescriptionListWithMargin = styled(DescriptionList).attrs({
  size: "small"
})`
  margin-top: 16px;
`;

const Container = styled(View)`
  margin-left: 32px;
  margin-right: 32px;
`;
