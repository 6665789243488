import {
  BasePropertyOnboardingListContent,
  ExcludedPropertyOnboardingListQuery,
  PropertyOnboardingListLoadingContent,
  PropertyOnboardingListReorder,
  useBasePropertyOnboardingListContent,
  useExcludedPropertyOnboardingListQuery,
  usePropertyOnboardingListLoadingContent,
  usePropertyOnboardingListReorder
} from "../../hooks";
import { GridSortOrder } from "@ailo/ui";
import { lowerCase, startCase } from "lodash";
import {
  ExcludedPropertyOnboardingListSelect,
  useExcludedPropertyOnboardingListSelect
} from "./useExcludedPropertyOnboardingListSelect";
import {
  ExcludedPropertyOnboardingListColumns,
  useExcludedPropertyOnboardingListColumns
} from "./useExcludedPropertyOnboardingListColumns";
import {
  ExcludedPropertyOnboardingListContent,
  useExcludedPropertyOnboardingListContent
} from "./useExcludedPropertyOnboardingListContent";
import { ReactElement } from "react";
import { KeyedExcludedMigratingManagementFragment } from "../../types";

export enum ExcludedPropertyOnboardingListColumnNames {
  Address = "Address",
  Portfolio = "Portfolio",
  ExcludedBy = "Excluded by",
  ExcludedOn = "Excluded on",
  ExclusionReason = "Reason"
}

const SORT_ORDER: GridSortOrder[] = [
  {
    columnKey: ExcludedPropertyOnboardingListColumnNames.ExcludedOn,
    direction: "DESC"
  },
  {
    columnKey: ExcludedPropertyOnboardingListColumnNames.Address,
    direction: "ASC"
  },
  {
    columnKey: ExcludedPropertyOnboardingListColumnNames.Portfolio,
    direction: "ASC"
  }
];

function getAnalyticsInfo(sortOrder: GridSortOrder): {
  eventName: string;
  orderBy: string;
} {
  return {
    eventName: "Excluded Property Onboarding List Reordered",
    orderBy: `${sortOrder.columnKey} ${startCase(
      lowerCase(sortOrder.direction)
    )}`
  };
}

interface Params {
  tabNavigation: ReactElement;
}

interface ExcludedPropertyOnboardingListData {
  queryData: ExcludedPropertyOnboardingListQuery;
  reorder: PropertyOnboardingListReorder;
  select: ExcludedPropertyOnboardingListSelect;
  columns: ExcludedPropertyOnboardingListColumns;
  content: ExcludedPropertyOnboardingListContent;
  baseContent: BasePropertyOnboardingListContent<KeyedExcludedMigratingManagementFragment>;
  loadingContent: PropertyOnboardingListLoadingContent;
}

export function useExcludedPropertyOnboardingList({
  tabNavigation
}: Params): ExcludedPropertyOnboardingListData {
  const queryData = useExcludedPropertyOnboardingListQuery({
    initialSortOrder: SORT_ORDER
  });

  const reorder = usePropertyOnboardingListReorder(
    SORT_ORDER,
    queryData.requestFromBeginning,
    getAnalyticsInfo
  );

  const select = useExcludedPropertyOnboardingListSelect({
    migratingManagements: queryData.data.migratingManagements
  });

  const columns = useExcludedPropertyOnboardingListColumns({
    select
  });

  const content = useExcludedPropertyOnboardingListContent({
    tabNavigation,
    queryData,
    select
  });

  const baseContent = useBasePropertyOnboardingListContent({
    queryData,
    headerCaptionContent: content.headerCaptionContent,
    columns,
    sortOrder: reorder.sortOrder,
    onSortOrderChange: reorder.onSortOrderChange,
    isRowSelected: select.isRowSelected
  });

  const loadingContent = usePropertyOnboardingListLoadingContent({ columns });

  return {
    queryData,
    reorder,
    select,
    columns,
    content,
    baseContent,
    loadingContent
  };
}
