import React, { useCallback, useState } from "react";

import { ErrorModal } from "@ailo/ui";
import {
  ProjectDetailsFragment,
  useGetPropertyAddressByManagementIdQuery
} from "local/graphql";
import { useFormContext } from "react-hook-form";

import { BaseSplitScreenModalForm } from "../BaseSplitScreenModalForm";
import { CreateOrUpdateProjectForm } from "./ProjectForm/CreateOrUpdateProjectForm";
import { ProjectFormContext } from "./ProjectForm/ProjectFormContext";
import {
  ProjectFormData,
  ProjectFormFields
} from "./ProjectForm/ProjectFormData";
import { getFormInitialValues } from "./ProjectForm/UpdateProjectForm/UpdateProjectForm";
import { ProjectInfo } from "./ProjectInfo";
import { convertManagementToSelectOption } from "local/domain/propertyManagement/utils";

interface Props {
  project?: ProjectDetailsFragment;
  managementId?: string;
  onDismiss: () => void;
  onSuccess?: (projectId: string) => void;
}

export function ProjectModalForm({
  project,
  managementId,
  onDismiss,
  onSuccess
}: Props): React.ReactElement {
  const { data, loading } = useGetPropertyAddressByManagementIdQuery({
    variables: managementId ? { id: managementId } : undefined,
    skip: !managementId
  });

  if (!data && loading) {
    return <></>;
  }

  const address = data?.management?.property.address;
  const managementOption =
    managementId && address
      ? convertManagementToSelectOption({
          managementId,
          address
        })
      : null;

  const initialValue: ProjectFormData = {
    management: managementOption
  };

  return (
    <ProjectFormContext
      initialValue={project ? getFormInitialValues(project) : initialValue}
    >
      <DisplayForm
        project={project}
        onDismiss={onDismiss}
        onSuccess={onSuccess}
      />
    </ProjectFormContext>
  );
}

function DisplayForm({
  project,
  onDismiss,
  onSuccess
}: Props): React.ReactElement {
  const [submitError, setSubmitError] = useState<boolean>(false);

  const { watch } = useFormContext<ProjectFormData>();
  const selectedManagement = watch(ProjectFormFields.management);

  const renderFormComponent = useCallback(
    (onFormSubmitting: (isSubmitting: boolean) => void): React.ReactElement => (
      <CreateOrUpdateProjectForm
        project={project}
        onFormSubmitting={(isSubmitting) => onFormSubmitting(isSubmitting)}
        onDismiss={onDismiss}
        onSuccess={onSuccess}
        setSubmitError={setSubmitError}
      />
    ),
    [project, onDismiss, onSuccess]
  );
  return (
    <>
      <BaseSplitScreenModalForm
        title={!project ? "Create project" : "Edit project"}
        renderFormComponent={renderFormComponent}
        renderRightComponent={
          selectedManagement?.value
            ? () => <ProjectInfo managementId={selectedManagement.value} />
            : undefined
        }
        onDismiss={onDismiss}
      />

      <ErrorModal
        visible={submitError}
        onDismiss={() => setSubmitError(false)}
      />
    </>
  );
}
