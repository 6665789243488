import React, { ReactElement } from "react";
import { ActionType, ProjectType } from "local/graphql";
import { isProjectOfTypeWithMeta } from "local/domain/project/project";
import { parseDepositData } from "local/domain/propertyManagement";
import { ManageDepositActionCardBase } from "./ManageDepositActionCardBase";
import { RefinedActionActivityProps } from "local/tabs/projects/actions";

type Props = RefinedActionActivityProps<ActionType.ManageDeposit> & {
  tenancyId?: string;
};

export function ManageDepositActionCard({
  projectId,
  action
}: Props): ReactElement {
  if (!isProjectOfTypeWithMeta(action.project, ProjectType.NewTenancy)) {
    throw new Error("Project is not type of NewTenancy");
  }

  const { newTenancy: tenancy } = action.project.meta;

  return (
    <ManageDepositActionCardBase
      action={action}
      projectId={projectId}
      depositData={parseDepositData(tenancy)}
    />
  );
}
