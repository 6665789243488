import React, { ReactElement } from "react";
import { View } from "react-native";
import { HealthIssue, HealthIssuesHeader } from "./components";
import { Card } from "@ailo/ui";
import styled from "styled-components/native";

export function FailedMigratingManagementBody(): ReactElement {
  return (
    <View>
      <View style={{ marginTop: 32, marginBottom: 60 }}>
        <StyledCard borderRadius={8}>
          <HealthIssuesHeader title={"Unable to make property live"} />
          <HealthIssue
            text={"Please contact Ailo support to resolve this issue"}
          />
        </StyledCard>
      </View>
    </View>
  );
}

const StyledCard = styled(Card)`
  margin: 0;
`;
