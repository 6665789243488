import React, { ReactElement } from "react";
import { Colors, Link, Text, WarningSubtractedIcon } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";
import { MigratingManagementSidebarHeader } from "./MigratingManagementSidebarHeader";

interface Props {
  refetch: () => void;
  closeSidebar: () => void;
}

export function MigratingManagementSidebarError({
  refetch,
  closeSidebar
}: Props): ReactElement {
  return (
    <>
      <Header>
        <MigratingManagementSidebarHeader.Error closeSidebar={closeSidebar} />
      </Header>
      <Container>
        <StyledWarningIcon color={Colors.STATUS.WARNING} />
        <StyledBodyL weight={"medium"}>
          {"There was a problem loading this property"}
        </StyledBodyL>
        <ReloadRow>
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{"Try "}</Text.BodyS>
          <Link variant={"primary"} onPress={() => refetch()}>
            {"reloading"}
          </Link>
        </ReloadRow>
      </Container>
    </>
  );
}

const Header = styled(View)`
  padding: 32px 32px 0 32px;
`;

const Container = styled(View)`
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const StyledWarningIcon = styled(WarningSubtractedIcon)`
  width: 40px;
  height: 40px;
  align-self: center;
`;

const StyledBodyL = styled(Text.BodyL)`
  text-align: center;
  margin-top: 16px;
`;

const ReloadRow = styled(View)`
  flex-direction: row;
  margin-top: 8px;
  align-items: center;
`;
