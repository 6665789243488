import { ClaimTenancyData } from "local/domain/bond";
import { StepRenderFunction } from "./StepRenderFunction";
import React, { ReactElement } from "react";
import { ClaimLiabilitiesStep, ConfirmClaimStep } from "../steps";
import { ClaimBondFormConfiguration } from "local/tabs";

export function createSteps(
  data: ClaimTenancyData,
  formConfiguration: ClaimBondFormConfiguration,
  onEditPress: () => void,
  enableSubmitButton: () => void
): StepRenderFunction[] {
  const claimLiabilities = (visible: boolean): ReactElement => (
    <ClaimLiabilitiesStep
      visible={visible}
      data={data}
      key={"step1"}
      formConfiguration={formConfiguration}
    />
  );

  const confirmClaim = (visible: boolean): ReactElement => (
    <ConfirmClaimStep
      visible={visible}
      formConfiguration={formConfiguration}
      data={data}
      key={"step2"}
      onEditPress={onEditPress}
      enableSubmitButton={enableSubmitButton}
    />
  );

  return [claimLiabilities, confirmClaim];
}
