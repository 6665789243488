import React from "react";
import {
  Colors,
  SuccessSubtractedIcon,
  Text,
  WarningSubtractedIcon
} from "@ailo/primitives";
import { Badge } from "@ailo/ui";
import styled from "styled-components/native";

const BadgeBase = styled(Badge).attrs({
  height: 28
})`
  padding-left: 12px;
  padding-right: 12px;
`;

export const BalancedBadge = styled(BadgeBase).attrs({
  type: "success",
  leftComponent: <SuccessSubtractedIcon width={16} height={16} />,
  children: (
    <Text.BodyM color={Colors.TEXT.GREEN}>
      {"The bank is in balance"}
    </Text.BodyM>
  )
})``;

export const UnBalancedBadge = styled(BadgeBase).attrs({
  type: "warning",
  leftComponent: <WarningSubtractedIcon width={16} height={16} />,
  children: (
    <Text.BodyM color={Colors.TEXT.ORANGE}>
      {"The bank isn't in balance"}
    </Text.BodyM>
  )
})``;

export const BadgeLoading = styled(Badge.Loading).attrs({
  height: 28,
  width: 194
})``;
