import { ErrorAlertScreen, Separator } from "@ailo/ui";
import { PersonListItem } from "local/domain/authz";
import {
  LegalEntityList,
  LegalEntityListProps
} from "local/domain/authz/LegalEntityList";
import { PropertyScreenCardListSubHeader } from "local/domain/propertyManagement";
import React, { ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { TenancyBondsSection } from "./TenancyBondSection";
import { ListItemAction } from "../../common/ListItemAction";
import { BondMenuOptions } from "./useGetBondOptionsGroup";
import {
  PropertyContentCard,
  PropertyContentCardLoading
} from "../../PropertyContentCard";
import styled from "styled-components/native";
import { AiloRN } from "@ailo/ailorn";
import { BaseTenancyCardTitleRight } from "./BaseTenancyCardTitleRight";

interface Props {
  title: string;
  tenants: LegalEntityListProps["legalEntities"];
  managementId: string;
  tenantContactAilorns: AiloRN<"contact:contact">[];
  displayBondStatus?: boolean;
  headerRightComponent?: ReactNode;
  onDetailsPress: () => void;
  onTenantPress?: (id: string, entityType: "Person" | "Company") => void;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  bondMenuOptions: BondMenuOptions;
}

export function BaseTenancyCard({
  style,
  title,
  tenants,
  managementId,
  tenantContactAilorns,
  displayBondStatus,
  children,
  headerRightComponent,
  onDetailsPress,
  onTenantPress,
  bondMenuOptions
}: Props): React.ReactElement {
  const tenancyBondsFeatureOn = useHasFeature(PlatformFeatureId.TenancyBonds);

  return (
    <PropertyContentCard
      style={style}
      title={title}
      titleRight={
        <BaseTenancyCardTitleRight
          tenantContactAilorns={tenantContactAilorns}
          headerRightComponent={headerRightComponent}
        />
      }
    >
      {children}
      <PropertyScreenCardListSubHeader
        style={{ marginTop: 4, marginHorizontal: 16, marginBottom: 16 }}
      >
        {"Contacts"}
      </PropertyScreenCardListSubHeader>
      <LegalEntityList
        legalEntities={tenants}
        hideLastItemBorder={true}
        onItemPress={onTenantPress}
      />
      <StyledSeparator />
      <PropertyScreenCardListSubHeader
        style={{ marginTop: 20, marginHorizontal: 16 }}
      >
        {"Tenancy"}
      </PropertyScreenCardListSubHeader>

      <ListItemAction header={"Tenancy details"} onPress={onDetailsPress} />

      {tenancyBondsFeatureOn && (
        <>
          <StyledSeparator />
          <TenancyBondsSection
            managementId={managementId}
            bond={bondMenuOptions.bond}
            onPressAdd={bondMenuOptions.addBondDetails}
            onPressEdit={bondMenuOptions.editBondDetails}
            showStatus={displayBondStatus}
          />
        </>
      )}
    </PropertyContentCard>
  );
}

function Loading({
  style,
  headerRightComponentLoading
}: {
  style?: StyleProp<ViewStyle>;
  headerRightComponentLoading?: React.ReactElement;
}): React.ReactElement {
  return (
    <PropertyContentCardLoading
      style={style}
      titleRight={headerRightComponentLoading}
      titleWidth={40}
    >
      <PersonListItem.Loading style={{ borderType: "none" }} />
      <PersonListItem.Loading style={{ borderType: "none" }} />
    </PropertyContentCardLoading>
  );
}

function Error({ onRetry }: { onRetry(): void }): React.ReactElement {
  return (
    <ErrorAlertScreen
      variant={"medium"}
      title={"There was a problem loading\nTenancy Card"}
      onRetry={onRetry}
      style={{ paddingVertical: 40 }}
    />
  );
}

BaseTenancyCard.Loading = Loading;
BaseTenancyCard.Error = Error;

const StyledSeparator = styled(Separator)`
  margin-left: 0;
  margin-top: 16px;
`;
