import { Colors, ViewDetailsIcon } from "@ailo/primitives";
import { Card, ListItem, ListItemProps } from "@ailo/ui";
import React, { ReactElement } from "react";
import styled from "styled-components/native";

export interface FormListItemProps {
  formName: string;
  formStatus: string;
  style?: ListItemProps;
}

export function FormListItem({
  formName,
  formStatus,
  style
}: FormListItemProps): ReactElement {
  return (
    <ListItem
      header={formName}
      subHeader={formStatus}
      leftComponent={
        <LeftIconContainer>
          <ViewDetailsIcon color={Colors.OCEAN} />
        </LeftIconContainer>
      }
      style={{
        height: 72,
        borderType: "around",
        borderRadius: 8,
        alignItems: "center",
        ...style
      }}
    />
  );
}

const LeftIconContainer = styled(Card)`
  width: 40px;
  height: 40px;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 0;
  margin-right: 16px;
  justify-content: center;
  align-items: center;
`;
