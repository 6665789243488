import {
  AnalyticsSetup as BaseAnalyticsSetup,
  staticEnvironment,
  useEnvironment
} from "@ailo/services";
import { AnalyticsService } from "local/common";
import React, { useMemo } from "react";
import { useAnalyticsContext } from "./useAnalyticsContext";

export function AnalyticsSetup({
  children
}: {
  children?: React.ReactNode;
}): React.ReactElement {
  const environment = useEnvironment();
  const analytics = useMemo(
    () =>
      new AnalyticsService({
        segment: environment,
        debug: !!staticEnvironment.releaseEnvironment.DEBUG_ANALYTICS
      }),
    [environment]
  );
  const { reload } = useAnalyticsContext(analytics);

  return (
    <BaseAnalyticsSetup analytics={analytics} onBeforeInitialize={reload}>
      {children}
    </BaseAnalyticsSetup>
  );
}
