import { AiloRN } from "@ailo/ailorn";
import { RentFrequency } from "@ailo/domains";
import { Money } from "@ailo/ui";

export const RentReviewFields = {
  period: "period",
  amount: "amount",
  effectiveDate: "effectiveDate",
  scheduledRentReviewDate: "scheduledRentReviewDate",
  leaseRenewalChecked: "leaseRenewalChecked"
} as const;

export interface RentReviewFields {
  [RentReviewFields.period]: RentFrequency;
  [RentReviewFields.amount]: Money;
  [RentReviewFields.effectiveDate]: string;
  [RentReviewFields.scheduledRentReviewDate]: string;
  [RentReviewFields.leaseRenewalChecked]: boolean;
}

export type RentReviewMode = "create" | "edit";

export type RentReviewFormData = (
  | { mode: "create" }
  | { mode: "edit"; rentAilorn: AiloRN }
) & {
  tenancyId: string;
  nextChargeCreateDate: string;
  previousRentAmount: Money;
  createLeaseReviewAllowed: boolean;
  tenancyEndDate?: string;
  lastRentReview?: { effectiveDate: string; rentIncreaseAmount: Money };
};

export type RentReviewPrefill = Partial<RentReviewFields>;
