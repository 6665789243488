import { AiloRN } from "@ailo/ailorn";
import { formatPersonName } from "@ailo/domain-helpers";
import { Colors, Text } from "@ailo/primitives";
import {
  DropdownMenu,
  DropdownMenuOption,
  DropdownMenuToggleProps,
  Button
} from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import { sortPeopleAlphabeticallyByName } from "local/domain/utils";
import { useGetMembersByOrganisationQuery } from "local/graphql";
import React, { useCallback, useMemo } from "react";
import { View } from "react-native";
import { useInspectionPlanner } from "../../InspectionPlannerContext";
import { AssigneeDropdownButton } from "./AssigneeDropdownButton";

export function ScheduledInspectionsAssigneeFilter(): React.ReactElement {
  const { id: organisationId } = useCurrentAgencyOrg();
  const { assignee, setAssignee, setSelectedInspectionDate } =
    useInspectionPlanner();
  const { data, loading } = useGetMembersByOrganisationQuery({
    variables: {
      organisationId
    }
  });
  const orgMembers = sortPeopleAlphabeticallyByName(
    data?.organisation?.members?.items || []
  );
  const selectedPerson = orgMembers.find(
    (member) => member.ailoRN === assignee.toString()
  );

  const options: DropdownMenuOption[] = useMemo(
    () =>
      orgMembers.map((person) => ({
        label: formatPersonName(person),
        onSelect: () => {
          if (!assignee.equals(AiloRN.from(person.ailoRN))) {
            setAssignee(AiloRN.from(person.ailoRN));
            setSelectedInspectionDate(null);
          }
        }
      })),
    [orgMembers, setAssignee, assignee, setSelectedInspectionDate]
  );

  const renderDropdownButton = useCallback(
    ({ toggle, open }: DropdownMenuToggleProps) => {
      return selectedPerson ? (
        <AssigneeDropdownButton
          active={open}
          onPress={toggle}
          person={selectedPerson}
        />
      ) : (
        <Button.Loading />
      );
    },
    [selectedPerson]
  );

  if (loading && !data) {
    return (
      <DropdownMenu.Loading renderToggle={renderDropdownButton} options={[]} />
    );
  }

  return (
    <View style={{ alignItems: "flex-start" }}>
      <Text.BodyS
        style={{ marginBottom: 12 }}
        weight={"book"}
        color={Colors.TEXT.DARK.SECONDARY}
      >
        {"Assignee"}
      </Text.BodyS>
      <DropdownMenu
        align={"start"}
        options={options}
        renderToggle={renderDropdownButton}
        itemSize={"small"}
      />
    </View>
  );
}
