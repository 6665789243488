import { Money } from "@ailo/money";

export interface Allocation {
  amount?: Money | null;
  description: string;
  purposeId: string;
}

export interface AllocationPurpose {
  purposeId: string;
  description: string | null;
  included: boolean;
  amountDue?: Money | null;
  dueDate?: string | null;
  name: string;
  amount?: Money;
}

export type ReceiptPaymentMethod =
  | "EFT_INTO_TRUST_ACCOUNT"
  | "CASH_INTO_TRUST_ACCOUNT"
  | "CHEQUE_INTO_TRUST_ACCOUNT";

export interface CreateReceiptFormData {
  targetWallet?: string;
  from?: { wallet: string } | { name: string };
  category?: string;
  receivedOn?: string;
  amount?: string;
  paymentMethod?: string;
  allocations?: AllocationPurpose[];
}

export enum WalletSearchMode {
  TENANCY,
  NON_TENANCY
}
