import React from "react";
import { EmptySection } from "@ailo/ui";

export const EmptyUpcomingExpensesSection = (
  <EmptySection header={"Nice work! There are no expenses to pay"} />
);

export const EmptyPaidExpensesSection = (
  <EmptySection header={"No settled invoices were found"} />
);
