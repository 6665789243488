import React from "react";
import { useGetManagementRentQuery } from "local/graphql";
import { formatRentPaymentSchedule } from "@ailo/domains";
import { ErrorCell } from "./ErrorCell";
import { BasePropertyListRow } from "../BasePropertyListRow";
import { TextCell } from "@ailo/ui";

function RentCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetManagementRentQuery({
    variables: { managementId }
  });

  if (loading && !data) {
    return <TextCell.Loading />;
  }

  const rent = data?.management?.mostRecentTenancy?.displayRent;

  if (!rent)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  return <TextCell>{formatRentPaymentSchedule(rent)}</TextCell>;
}

RentCell.Loading = TextCell.Loading;

export { RentCell };
