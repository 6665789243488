import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { useCallback } from "react";
import { MutationResult } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { isPresent } from "ts-is-present";
import {
  GetPagedAwaitingApprovalMigratingManagementsDocument,
  GetPagedExcludedMigratingManagementsDocument,
  GetMigratingManagementSidebarDocument,
  IncludeMigratingManagementMutation,
  useIncludeMigratingManagementMutation
} from "local/graphql";
import { AiloRN, services } from "@ailo/ailorn";

interface IncludeMigratingManagementsProps {
  migratingManagementId: string;
  onSuccess: (result: IncludeMigratingManagementMutation) => void;
  onError: () => void;
}

type IncludeMigratingManagements = [
  () => void,
  MutationResult<IncludeMigratingManagementMutation>
];

const refreshOperationNames = [
  getOperationName(GetPagedAwaitingApprovalMigratingManagementsDocument),
  getOperationName(GetPagedExcludedMigratingManagementsDocument)
];

export function useIncludeMigratingManagement({
  migratingManagementId,
  onSuccess,
  onError
}: IncludeMigratingManagementsProps): IncludeMigratingManagements {
  const analytics = useAnalytics();

  const [includeMigratingManagementMutation, mutationResult] =
    useIncludeMigratingManagementMutation({
      variables: { migratingManagementId },
      refetchQueries: [
        {
          query: GetMigratingManagementSidebarDocument,
          variables: {
            migratingManagementId
          }
        },
        ...refreshOperationNames.filter(isPresent)
      ]
    });

  const includeMigratingManagement = useCallback(async () => {
    try {
      analytics.track("Migrating Property Included", {
        migrating_management_id: AiloRN.of(
          services.Migration.migratingManagement,
          migratingManagementId
        ).toString()
      });

      const result = await includeMigratingManagementMutation();
      throwIfMutationFailed(result, {
        dataKey: "includeMigratingManagements"
      });

      if (!result.data) {
        onError();
        return;
      }

      onSuccess(result.data);
    } catch (error) {
      AiloSentry.captureException(error);
      onError();
    }
  }, [
    includeMigratingManagementMutation,
    onSuccess,
    onError,
    analytics,
    migratingManagementId
  ]);

  return [includeMigratingManagement, mutationResult];
}
