import React, { useRef } from "react";
import { StyleProp, ViewStyle } from "react-native";
import {
  ProRataPreviewFragment,
  useProRataSchedulesForNewRentQuery,
  useProRataSchedulesForRentUpdateQuery,
  RentFrequency
} from "local/graphql";
import { OutOfCycleInfoAlert } from "./OutOfCycleInfoAlert";
import { LocalDate } from "@ailo/date";
import { AiloRN } from "@ailo/ailorn";

type ProposedRent = {
  amountInCents: string;
  period: RentFrequency;
  effectiveDate: string;
};

export function OutOfCycleInfoForCreate({
  tenancyId,
  proposedRent,
  style
}: {
  tenancyId: string;
  proposedRent: ProposedRent;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  const { loading, error, data } = useProRataSchedulesForNewRentQuery({
    variables: {
      tenancyId,
      proposedRent: {
        ...proposedRent,
        setsChargeDate: false
      }
    },
    fetchPolicy: "cache-and-network"
  });

  const proRataSchedules = data?.tenancy?.proRataSchedulesForNewRent;

  return (
    <OutOfCycleInfo
      proposedRent={proposedRent}
      proRataSchedules={proRataSchedules}
      loading={loading}
      error={!!error}
      style={style}
    />
  );
}

export function OutOfCycleInfoForUpdate({
  rentAilorn,
  proposedRent,
  style
}: {
  rentAilorn: AiloRN;
  proposedRent: ProposedRent;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  const { loading, error, data } = useProRataSchedulesForRentUpdateQuery({
    variables: {
      rentAilorn: rentAilorn.toString(),
      proposedRent: {
        ...proposedRent,
        setsChargeDate: false
      }
    },
    fetchPolicy: "cache-and-network"
  });

  const proRataSchedules = data?.rent?.proRataSchedulesForRentUpdate;

  return (
    <OutOfCycleInfo
      proposedRent={proposedRent}
      proRataSchedules={proRataSchedules}
      loading={loading}
      error={!!error}
      style={style}
    />
  );
}

function OutOfCycleInfo({
  proposedRent,
  proRataSchedules,
  loading,
  error,
  style
}: {
  proposedRent: ProposedRent;
  proRataSchedules?: ProRataPreviewFragment[];
  loading: boolean;
  error: boolean;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement | null {
  const shouldLoadVisibly = useRef(false);

  if (error) return <OutOfCycleInfoAlert.Error style={style} />;

  const proRataPreview = proRataSchedules?.find((schedule) =>
    LocalDate.from(schedule.startDate).isBefore(
      LocalDate.from(proposedRent.effectiveDate)
    )
  );

  if (!proRataPreview && loading && shouldLoadVisibly.current) {
    return <OutOfCycleInfoAlert.Loading style={style} />;
  }

  if (!proRataPreview) {
    shouldLoadVisibly.current = false;
    return null;
  }

  shouldLoadVisibly.current = true;
  return <OutOfCycleInfoAlert proRataPreview={proRataPreview} style={style} />;
}
