import { AiloRN } from "@ailo/ailorn";
import { applyFeatureOverride } from "@ailo/domains";
import {
  PlatformFeatureId,
  Organisation,
  GetAvailableFeaturesQuery
} from "local/graphql";
import { flatMap } from "lodash";

export function getAvailableFeatures(
  currentUser: GetAvailableFeaturesQuery["effectiveUser"],
  orgAiloRN?: string
): PlatformFeatureId[] {
  if (!orgAiloRN) return [];

  const currentOrganisation = currentUser?.organisations?.find(
    (org) => org.id === AiloRN.from(orgAiloRN).internalId
  );

  if (!currentOrganisation) {
    throw new Error("Could not load organisation data");
  }

  let features = currentOrganisation.availableFeatures.map(
    (feature) => feature?.id
  );

  features = applyWalletFeatures(features, currentOrganisation);

  features = applyFeatureOverride(features);

  return features;
}

function getWalletPermissions(
  currentOrganisation: DeepPartial<Organisation>
): string[] {
  const permissions = flatMap(
    currentOrganisation.legalEntities,
    (le) => le?.currentUserPermissions
  );

  const walletPermissions = [];
  if (permissions.includes("legal_entity:financial:view"))
    walletPermissions.push("view");
  if (permissions.includes("legal_entity:financial:manage"))
    walletPermissions.push("withdraw");
  return walletPermissions;
}

type DeepPartial<T> = {
  [K in keyof T]?: DeepPartial<T[K]>;
};

function applyWalletFeatures(
  features: PlatformFeatureId[],
  currentOrganisation: DeepPartial<Organisation>
): PlatformFeatureId[] {
  const walletPermissions = getWalletPermissions(currentOrganisation);

  return features
    .slice()
    .filter(
      (featureId) =>
        (featureId !== "ViewAgencyWallet" ||
          walletPermissions.includes("view")) &&
        (featureId !== "AgencyWalletManualWithdrawal" ||
          walletPermissions.includes("withdraw"))
    );
}
