import React from "react";
import moment from "moment";
import { View } from "react-native";
import { DateInputFormField } from "@ailo/ui";
import { Text, DateRange, Colors } from "@ailo/primitives";

type TenancyEndDateProps = {
  validEndDateRange?: DateRange;
  tenancyEndDate?: moment.Moment;
  tenancyAgreementDateInfo?: string;
  nextTenancyDateInfo?: string;
  onTenancyEndDateChange?: (endDate: moment.Moment | undefined) => void;
};

export function TenancyEndDate({
  validEndDateRange,
  tenancyEndDate,
  tenancyAgreementDateInfo,
  nextTenancyDateInfo,
  onTenancyEndDateChange
}: TenancyEndDateProps): React.ReactElement {
  return (
    <>
      <Text.BodyL weight={"medium"} style={{ marginBottom: 32 }}>
        {"Tenancy end date"}
      </Text.BodyL>

      <View>
        <DateInputFormField
          placeholder={!validEndDateRange ? "Loading..." : "Tenancy end date"}
          onChange={(d): void => {
            onTenancyEndDateChange?.(d ? moment(d, "YYYY-MM-DD") : undefined);
          }}
          webPopoverHorizontalAlign={"start"}
          webPopoverContainerStyle={{ marginLeft: "-4px", marginTop: "-4px" }}
          minDate={validEndDateRange?.startDate?.format("YYYY-MM-DD")}
          maxDate={validEndDateRange?.endDate?.format("YYYY-MM-DD")}
          webDateEnabled={validEndDateRange ? undefined : (): boolean => false}
          value={tenancyEndDate?.format("YYYY-MM-DD")}
        />
        {tenancyAgreementDateInfo && (
          <Text.BodyXS
            color={Colors.TEXT.DARK.SECONDARY}
            style={{ marginLeft: 12, marginTop: 4 }}
          >
            {tenancyAgreementDateInfo}
            {nextTenancyDateInfo && `. ${nextTenancyDateInfo}`}
          </Text.BodyXS>
        )}
      </View>
    </>
  );
}
