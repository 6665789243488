import { Column, Colors, DeleteIcon } from "@ailo/primitives";
import {
  ActionCellLoading,
  Badge,
  BadgeCellLoading,
  Button,
  CellContainer,
  GridColumnOptions,
  LocalDate,
  Money,
  TextCell,
  TextCellContent,
  Tooltip
} from "@ailo/ui";
import { TrustAccountReconciliationAdjustmentFragmentFragment } from "local/graphql";
import React, { ComponentType } from "react";
import { View } from "react-native";

export type RowData = TrustAccountReconciliationAdjustmentFragmentFragment & {
  isPeriodLocked: boolean;
  onDeletePress: () => void;
  key: string;
};

interface CellProps {
  row: RowData;
}

function AppliedOnCell({ row }: CellProps): React.ReactElement {
  return (
    <TextCell>{LocalDate.from(row.applyOn).format("DD MMM YYYY")}</TextCell>
  );
}

function DetailsCell({ row }: CellProps): React.ReactElement {
  return <TextCell>{row.details}</TextCell>;
}

function RemovingReasonCell({ row }: CellProps): React.ReactElement {
  if (row.removedFrom) {
    const formattedDate = LocalDate.from(row.removedFrom).format("DD MMM YYYY");
    return (
      <CellContainer style={{ flexDirection: "column" }}>
        <TextCellContent>{row.removedReason ?? "—"}</TextCellContent>
        <TextCellContent
          color={Colors.TEXT.DARK.SECONDARY}
        >{`Removed at ${formattedDate}`}</TextCellContent>
      </CellContainer>
    );
  }
  return <TextCell>{"—"}</TextCell>;
}

function StatusCell({ row }: CellProps): React.ReactElement {
  return (
    <CellContainer>
      <Badge
        style={{ alignSelf: "flex-start" }}
        type={row.removedFrom ? "critical" : "success"}
      >
        {row.removedFrom ? "Removed" : "Current"}
      </Badge>
    </CellContainer>
  );
}

function AmountCell({ row }: CellProps): React.ReactElement {
  return <TextCell>{Money.fromCents(row.amountInCents).format()}</TextCell>;
}

function RemoveActionCell({ row }: CellProps): React.ReactElement {
  if (row.removedFrom) {
    return <></>;
  }
  return (
    <CellContainer>
      <Tooltip tooltipContent={"Remove"}>
        <Button.Text onPress={row.onDeletePress} disabled={row.isPeriodLocked}>
          <DeleteIcon width={20} height={20} />
        </Button.Text>
      </Tooltip>
    </CellContainer>
  );
}

type Column = GridColumnOptions<RowData> & { loading: ComponentType };

export const columns: Column[] = [
  {
    name: "Applied on",
    renderCell: AppliedOnCell,
    loading: TextCell.Loading,
    maxWidth: 120,
    verticalAlign: "middle"
  },
  {
    name: "Details",
    renderCell: DetailsCell,
    loading: TextCell.Loading,
    verticalAlign: "middle"
  },
  {
    name: "Removing reason",
    renderCell: RemovingReasonCell,
    loading: TextCell.Loading,
    verticalAlign: "middle"
  },
  {
    name: "Status",
    renderCell: StatusCell,
    loading: BadgeCellLoading,
    maxWidth: 120,
    verticalAlign: "middle"
  },
  {
    name: "Amount",
    renderCell: AmountCell,
    loading: TextCell.Loading,
    maxWidth: 120,
    horizontalAlign: "right" as const,
    verticalAlign: "middle"
  },
  {
    key: "Delete",
    renderCell: RemoveActionCell,
    loading: ActionCellLoading,
    maxWidth: 52,
    horizontalAlign: "right" as const,
    verticalAlign: "middle"
  }
];

export const loadingColumns = columns.map((column) => {
  const Loading = column.loading;
  const renderCell = ({
    row: { opacity }
  }: {
    row: { opacity: number };
  }): React.ReactElement => (
    <View style={{ opacity }}>
      <Loading />
    </View>
  );
  return { ...column, renderCell };
});

export const loadingRows = [...Array(5)].map((_, index) => ({
  key: index,
  opacity: Math.max(0.75 - index * 0.25, 0)
}));
