import { ActionType } from "local/graphql";
import React, { ReactElement } from "react";
import { GeneratedFormView } from "./GeneratedFormView";
import { SelectTemplateView } from "./SelectTemplateView";
import { Text } from "@ailo/primitives";
import { RefinedActionActivityProps } from "../ActionActivityProps";
import { ReminderActionActivity } from "../ReminderActionActivity";
import { useGetReiTokens } from "local/domain/project/useGetReiTokens";
import { ErrorAlertScreen } from "@ailo/ui";
import {
  AgreementCreatedNoTokenView,
  LinkReiAccountSuggestion
} from "local/domain/project";

export function AssignFormActionActivity({
  action,
  projectId
}: RefinedActionActivityProps<ActionType.AssignForm>): ReactElement {
  const { data: reiTokensForOrg, error, refetch, loading } = useGetReiTokens();

  if (error) {
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the form information."}
        onRetry={refetch}
      />
    );
  }

  if (loading) {
    return <Text.BodyS.Loading width={200} />;
  }

  const formId = action.meta.form?.id;

  if (!reiTokensForOrg || reiTokensForOrg.length === 0) {
    if (formId) {
      return <AgreementCreatedNoTokenView />;
    }
    return (
      <ReminderActionActivity action={action} projectId={projectId}>
        <LinkReiAccountSuggestion style={{ marginTop: 32 }} />
      </ReminderActionActivity>
    );
  }

  if (formId) {
    return (
      <GeneratedFormView
        formId={formId}
        action={action}
        projectId={projectId}
      />
    );
  }

  return <SelectTemplateView action={action} projectId={projectId} />;
}
