import {
  useGetManagementPortfolioPropertiesQuery,
  GetManagementPortfolioPropertiesQuery,
  ManagementPropertyFragment,
  OccupancyStatus
} from "local/graphql";
import { AiloRN } from "@ailo/ailorn";
import { ApolloQueryResult } from "@apollo/client";
import { isPresent } from "ts-is-present";

export interface PortfolioPropertyManagement
  extends ManagementPropertyFragment,
    PortfolioPropertyOccupancy {}

export interface PortfolioPropertyOccupancy {
  occupancyStatus?: OccupancyStatus | null;
  mostRecentTenancy?: {
    endDate?: string | null;
  } | null;
}

export function useGetManagementPortfolioProperties(
  portfolioAilorn: AiloRN<"propertymanagement:managementfolio">
): {
  data: PortfolioPropertyManagement[] | undefined;
  loading: boolean;
  error?: Error | undefined;
  refetch: () => Promise<
    ApolloQueryResult<GetManagementPortfolioPropertiesQuery>
  >;
} {
  const { data, ...rest } = useGetManagementPortfolioPropertiesQuery({
    variables: {
      managementPortfolioId: portfolioAilorn.internalId,
      managementsFilter: {
        isCurrent: true
      }
    }
  });
  const managements =
    getManagementsFromGetManagementPortfolioPropertiesQuery(data);
  return { data: managements, ...rest };
}

export function getManagementsFromGetManagementPortfolioPropertiesQuery(
  portfolio?: GetManagementPortfolioPropertiesQuery
): PortfolioPropertyManagement[] | undefined {
  return portfolio?.managementFolio?.allManagements.items
    .map((portfolioToManagement) => portfolioToManagement.management)
    .filter(isPresent);
}
