import { Button, Tooltip } from "@ailo/ui";
import React from "react";

export function ProjectEditButton({
  onPress
}: {
  onPress: () => void;
}): React.ReactElement | null {
  return (
    <Tooltip tooltipContent={"Edit project"}>
      <Button.Secondary
        onPress={onPress}
        type={"small"}
        style={{ marginRight: 12 }}
      >
        {"Edit"}
      </Button.Secondary>
    </Tooltip>
  );
}
