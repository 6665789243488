import { useHasFeature } from "@ailo/domains";
import { RightChevronIcon } from "@ailo/primitives";
import {
  Button,
  FormField,
  FormFieldGroup,
  FormFieldRow,
  Money,
  RadioButtonGroup,
  Separator,
  TextInputFormField,
  validateMaxLength
} from "@ailo/ui";
import { AddExpenseFormData } from "local/domain/expense";
import {
  FeeBlueprintChargeType,
  FeeBlueprintType,
  PlatformFeatureId
} from "local/graphql";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DateInput } from "./DateInput";
import { OneOffFeeBlueprintInput } from "./OneOffFeeBlueprintInput";
import { PriceInput } from "./PriceInput";
import { RecurringFeeBlueprintInput } from "./RecurringFeeBlueprintInput";
import { RecurringFeeChargeDescription } from "./RecurringFeeChargeDescription";
import { RentBasedFeeInputs } from "./RentBasedFeeInputs";

const MAX_DESCRIPTION_LENGTH = 60;
interface Props {
  managementId: string;
  weeklyRent?: Money;
  onViewFeeSchedulePress: () => void;
  toggleFieldsInIntermediateState(
    field: keyof AddExpenseFormData,
    inIntermediateState: boolean
  ): void;
}
export function FeeInputs({
  managementId,
  weeklyRent,
  onViewFeeSchedulePress,
  toggleFieldsInIntermediateState
}: Props): ReactElement {
  const { control, watch, errors } = useFormContext<AddExpenseFormData>();
  const feeType = watch("feeType");
  const feeBlueprint = watch("feeBlueprint");

  const defaultFeeType = FeeBlueprintType.OneOffFee;

  const hasManagementFeeBlueprintFeature = useHasFeature(
    PlatformFeatureId.ManagementFeeBlueprint
  );

  return (
    <>
      <FormFieldGroup
        label={"Fee details"}
        labelRight={
          hasManagementFeeBlueprintFeature ? (
            <Button.Text
              rightIcon={() => <RightChevronIcon height={20} />}
              onPress={onViewFeeSchedulePress}
            >
              {"View fee schedule"}
            </Button.Text>
          ) : undefined
        }
        style={{ marginTop: 32 }}
      >
        <Controller
          control={control}
          name={"feeType"}
          defaultValue={defaultFeeType}
          render={({ value, onChange, onBlur }) => (
            <FormField
              label={"What type of fee would you like to charge?"}
              error={errors.feeType?.message}
              style={{
                display: hasManagementFeeBlueprintFeature ? "none" : undefined,
                marginBottom: 24
              }}
            >
              <RadioButtonGroup
                {...{ value, onChange, onBlur }}
                variant={"button-solid-group"}
                options={[
                  {
                    value: FeeBlueprintType.OneOffFee,
                    label: "Manual fee"
                  },
                  {
                    value: FeeBlueprintType.RecurringFee,
                    label: "Recurring fee"
                  }
                ]}
              />
            </FormField>
          )}
          rules={{
            required: "Select type of fee"
          }}
        />
        {(feeType ?? defaultFeeType) === "OneOffFee" ? (
          <OneOffFeeBlueprintInput
            managementId={managementId}
            toggleFieldsInIntermediateState={toggleFieldsInIntermediateState}
            onViewFeeSchedulePress={onViewFeeSchedulePress}
          />
        ) : (
          <RecurringFeeBlueprintInput
            toggleFieldsInIntermediateState={toggleFieldsInIntermediateState}
          />
        )}

        {!!feeBlueprint && (
          <>
            {feeBlueprint.chargeType ===
            FeeBlueprintChargeType.OneWeekRentPercentage ? (
              <RentBasedFeeInputs weeklyRent={weeklyRent} />
            ) : (
              <FormFieldRow cols={2} style={{ marginTop: 24 }}>
                <PriceInput />
                {feeBlueprint.type === FeeBlueprintType.RecurringFee && (
                  <DateInput />
                )}
              </FormFieldRow>
            )}

            <Controller
              control={control}
              name={"description"}
              defaultValue={null}
              render={({ value, onChange, onBlur }) => (
                <TextInputFormField
                  {...{ value, onChange, onBlur }}
                  label={"Enter a description for the payer (optional)"}
                  error={errors.description?.message}
                  placeholder={"Description"}
                  softCharacterLimit={{
                    limit: MAX_DESCRIPTION_LENGTH,
                    enforcementLevel: "error"
                  }}
                  style={{ marginTop: 24 }}
                />
              )}
              rules={{
                validate: validateMaxLength(MAX_DESCRIPTION_LENGTH)
              }}
            />
          </>
        )}
      </FormFieldGroup>
      {!!feeBlueprint && (feeType ?? defaultFeeType) === "RecurringFee" && (
        <>
          <Separator style={{ marginLeft: 0, marginTop: 40 }} />
          <RecurringFeeChargeDescription style={{ marginTop: 32 }} />
        </>
      )}
    </>
  );
}
