import { useCallback, useState } from "react";

export type FilterStateType = {
  propertyAddress?: string;
};

export type UpdateUnscheduledTenanciesFilterStateCallback = (
  mergeState: FilterStateType
) => void;

export function useUnscheduledTenanciesFilterState(): {
  filterState: FilterStateType;
  updateFilterState: UpdateUnscheduledTenanciesFilterStateCallback;
} {
  const [filterState, setFilterState] = useState<FilterStateType>({});

  const updateFilterState =
    useCallback<UpdateUnscheduledTenanciesFilterStateCallback>(
      (mergeState) => {
        setFilterState({ ...filterState, ...mergeState });
      },
      [filterState, setFilterState]
    );

  return { filterState, updateFilterState };
}
