import { formatAddress } from "@ailo/domain-helpers";
import { Text } from "@ailo/primitives";
import { ConfirmModal, LocalDate, ErrorModal } from "@ailo/ui";
import { PropertyIdAndAddressFragment } from "local/graphql";
import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { EndTenancyFormData } from "./EndTenancyFormData";
import {
  AmountDueAtEndDateWarningMessage,
  DepositFundsWarningMessage
} from "./WarningMessages";

export function EndTenancyConfirmModal({
  onCancel,
  onConfirm,
  visible,
  loading,
  showDepositFundsWarning,
  property,
  amountDueAtEndDate
}: {
  onCancel: () => void;
  onConfirm: () => void;
  visible: boolean;
  loading: boolean;
  showDepositFundsWarning: boolean;
  property: PropertyIdAndAddressFragment;
  amountDueAtEndDate?: number;
}): ReactElement {
  const form = useFormContext<EndTenancyFormData>();
  const formData = form.getValues();

  const endDate = formData.endDate;

  if (!endDate) {
    return <ErrorModal visible={visible} onDismiss={onCancel} />;
  }

  const formattedEndDate = new LocalDate(formData.endDate).format("D MMM YYYY");

  const formattedAddress = formatAddress(property.address, {
    format: "street"
  });

  return (
    <ConfirmModal
      title={"End of tenancy"}
      onCancel={onCancel}
      onConfirm={onConfirm}
      visible={visible}
      destructive
      confirmLabel={"End tenancy"}
      loading={loading}
    >
      <Text.BodyM>{`The tenancy for ${formattedAddress} will end on ${formattedEndDate}`}</Text.BodyM>
      <DepositFundsWarningMessage
        showDepositFundsWarning={showDepositFundsWarning}
        style={{ marginTop: 16 }}
      />
      <AmountDueAtEndDateWarningMessage
        amountDueAtEndDate={amountDueAtEndDate}
        style={{ marginTop: 16 }}
      />
    </ConfirmModal>
  );
}
