import {
  KeyedMigratingManagement,
  PropertyOnboardingListColumn,
  PropertyOnboardingListQueryData,
  PropertyOnboardingListRequestExtraVariables
} from "../types";
import React, { useCallback, useMemo } from "react";
import {
  GridHeadRow,
  GridRenderRowContainerProps,
  GridSortOrder
} from "@ailo/ui";
import { Navigation, Screens, useNavigation, useRoute } from "local/common";
import { PropertyOnboardingHeader } from "../../PropertyOnboardingHeader";
import { PropertyOnboardingListPaginationNavigation } from "../PropertyOnboardingListPaginationNavigation";
import { PropertyOnboardingListRowContainer } from "../PropertyOnboardingListRowContainer";

interface PropertyOnboardingListContentProps<
  TMigratingManagement extends KeyedMigratingManagement,
  TExtraVariables extends PropertyOnboardingListRequestExtraVariables
> {
  headerCaptionContent: (showGridHeader: boolean) => React.ReactElement;
  headerOverride?: React.ReactElement;
  queryData: PropertyOnboardingListQueryData<
    TMigratingManagement,
    TExtraVariables
  >;
  sortOrder: GridSortOrder[];
  columns: PropertyOnboardingListColumn<TMigratingManagement>[];
  onSortOrderChange?: (sortOrder: GridSortOrder) => void;
  isRowSelected?: (row: TMigratingManagement) => boolean;
  rowClickAction?: (row: TMigratingManagement, navigation: Navigation) => void;
}

export interface BasePropertyOnboardingListContent<
  TMigratingManagement extends KeyedMigratingManagement
> {
  headerCaption: (showGridHeader: boolean) => React.ReactElement;
  styledHeader: React.ReactElement;
  footerCaption: React.ReactElement;
  renderRowContainer: (
    props: GridRenderRowContainerProps<TMigratingManagement>
  ) => React.ReactElement;
  onRowPress: (row: TMigratingManagement) => void;
}

export function useBasePropertyOnboardingListContent<
  TMigratingManagement extends KeyedMigratingManagement,
  TExtraVariables extends PropertyOnboardingListRequestExtraVariables
>({
  queryData,
  headerCaptionContent,
  columns,
  sortOrder,
  onSortOrderChange,
  isRowSelected,
  rowClickAction
}: PropertyOnboardingListContentProps<
  TMigratingManagement,
  TExtraVariables
>): BasePropertyOnboardingListContent<TMigratingManagement> {
  const navigation = useNavigation();
  const { params } = useRoute<Screens.OnboardingTab>();

  const { data, requestNewPage } = queryData;

  const headerCaption = useCallback(
    (showGridHeader: boolean): React.ReactElement => {
      return (
        <>
          <PropertyOnboardingHeader refetchList={queryData.refetch} />
          {headerCaptionContent(showGridHeader)}
        </>
      );
    },
    [queryData.refetch, headerCaptionContent]
  );

  const styledHeader = useMemo(() => {
    return (
      <GridHeadRow
        columns={columns}
        sortOrder={sortOrder[0]}
        style={{
          paddingTop: 20,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}
        onSortOrderChange={onSortOrderChange}
      />
    );
  }, [columns, sortOrder, onSortOrderChange]);

  const footerCaption = useMemo(() => {
    return (
      <PropertyOnboardingListPaginationNavigation
        pageInfo={data.pageInfo}
        requestNewPage={requestNewPage}
        sortOrder={sortOrder}
      />
    );
  }, [data.pageInfo, requestNewPage, sortOrder]);

  const renderRowContainer = useCallback(
    ({ row, ...rest }: GridRenderRowContainerProps<TMigratingManagement>) => {
      const selected = Boolean(isRowSelected && isRowSelected(row));

      return (
        <PropertyOnboardingListRowContainer selected={selected} {...rest} />
      );
    },
    [isRowSelected]
  );

  const onRowPress = useMemo(() => {
    if (rowClickAction) {
      return (rowData: TMigratingManagement) => {
        rowClickAction(rowData, navigation);
      };
    }

    // Default click action => open sidebar
    return (rowData: TMigratingManagement) => {
      navigation.navigate(Screens.OnboardingTab, {
        tab: params?.tab,
        migratingManagementId: rowData.id
      });
    };
  }, [rowClickAction, navigation, params?.tab]);

  return {
    headerCaption,
    styledHeader,
    footerCaption,
    renderRowContainer,
    onRowPress
  };
}
