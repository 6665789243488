import { Factory } from "rosie";
import { uuid } from "@ailo/services";
import { PendingInspectionAppointmentFragment } from "local/graphql";
import moment from "moment";
import { addressFactory } from "@ailo/domains";

export const pendingInspectionAppointmentFactory =
  new Factory<PendingInspectionAppointmentFragment>()
    .attr("id", uuid)
    .attr("property", () => ({ id: uuid(), address: addressFactory.build() }))
    .attr("startTime", () => moment().toISOString());
