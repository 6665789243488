import { ListItemStyle } from "@ailo/ui";
import {
  CompanyListItem,
  CompanyListItemProps,
  PersonListItem,
  PersonListItemProps
} from "local/domain/authz";
import React from "react";

type LegalEntity =
  | ({
      __typename: "Person";
    } & PersonListItemProps["person"])
  | ({
      __typename: "Company";
    } & CompanyListItemProps["company"]);

export interface LegalEntityListItemProps {
  legalEntity: LegalEntity;
  onPress?: () => void;
  style?: ListItemStyle;
}

export function LegalEntityListItem({
  legalEntity,
  onPress,
  style
}: LegalEntityListItemProps): React.ReactElement {
  return legalEntity.__typename === "Person" ? (
    <PersonListItem person={legalEntity} onPress={onPress} style={style} />
  ) : (
    <CompanyListItem company={legalEntity} onPress={onPress} style={style} />
  );
}
