import { useMemo } from "react";
import { isPresent } from "ts-is-present";
import { useAssigneesFilter, useProjectListFilterState } from "./filters";
import { useProjectTypesFilter } from "./filters/ProjectTypesFilter";

type ProjectFiltersReturn = {
  filterElements: React.ReactElement[];
  isAnyFilterActive: boolean;
} & Pick<ReturnType<typeof useProjectListFilterState>, "filterState">;

export function useProjectFilters(options?: {
  assigneesDisabled?: boolean;
  projectTypesDisabled?: boolean;
}): ProjectFiltersReturn {
  const { assigneesDisabled = false, projectTypesDisabled = false } =
    options || {};

  const { filterState, updateFilterState } = useProjectListFilterState();

  const assigneesFilter = useAssigneesFilter({
    assigneeAilorns: filterState.assigneeAilorns || [],
    updateFilterState,
    isFilterDisabled: assigneesDisabled
  });

  const projectTypesFilter = useProjectTypesFilter({
    projectTypes: filterState.projectTypes || [],
    updateFilterState,
    isFilterDisabled: projectTypesDisabled
  });

  const filters = useMemo(
    () => [projectTypesFilter, assigneesFilter],
    [assigneesFilter, projectTypesFilter]
  );

  const filterElements = useMemo(
    () => filters.map((filter) => filter.component).filter(isPresent),
    [filters]
  );

  return {
    filterElements,
    isAnyFilterActive: filters.some((filter) => filter.isFilterActive),
    filterState
  };
}
