import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AiloRN: string;
  /** Base64-encoded binary file */
  Binary: string;
  /** Cursor for paging through collections */
  ConnectionCursor: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  GeneratedKey: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: Record<string, any>;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** Date string. (e.g. "2007-12-03") */
  LocalDate: string;
  Long: number;
  /** Markdown syntax string */
  Markdown: any;
  /** IANA Time Zone name. (e.g. "America/Los_Angeles") */
  TimeZone: string;
  /** Url syntax string */
  Url: any;
  URL: any;
  ZoneId: any;
};

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["Boolean"]>;
  abrCompanyLookup?: Maybe<AbrCompanyLookupResult>;
  accountOwner?: Maybe<AccountOwner>;
  accountTagBalances?: Maybe<PaginatedAccountTagBalances>;
  accounts?: Maybe<PaginatedAccounts>;
  action?: Maybe<Action>;
  /**
   * query activeManagements will eventually be deprecated
   * consider to enhance/use managementsForOrg instead
   */
  activeManagements?: Maybe<BidirectionalPaginatedManagements>;
  actualUser?: Maybe<User>;
  agencyFinancialPerformanceReport?: Maybe<AgencyFinancialPerformanceReport>;
  agencyIncomeByChartOfAccountReport?: Maybe<AgencyIncomeByChartOfAccountReport>;
  agencyIncomeByMonthReport?: Maybe<AgencyIncomeByMonthReport>;
  agencyIncomeByPropertyReport?: Maybe<AgencyIncomeByPropertyReport>;
  agencyIncomeDisbursementPeriodReport?: Maybe<AgencyIncomeDisbursementPeriodReport>;
  /** A query to resolve all needed data from the AgencyIncome cube */
  agencyIncomeReport?: Maybe<AgencyIncomeReport>;
  agencyProperties: PaginatedAgencyProperties;
  agencyProperty?: Maybe<AgencyProperty>;
  agencyPropertyByOrgAndProperty?: Maybe<AgencyProperty>;
  ailoForm: AiloForm;
  ailoFormTemplate: AiloFormTemplate;
  ailoFormTemplateByType: AiloFormTemplate;
  ailoFormTemplates: Array<AiloFormTemplate>;
  ailoForms: Array<AiloForm>;
  /** Needs admin permissions to query. */
  allTenancies: BidirectionalPaginatedTenancies;
  /**
   * Amount due to be paid up til the 00am of the given timestamp minus all payment made against this liability up til now.
   * e.g. if liability is $100/wk rent. Tenant paid nothing, and input time is Wed 10am, this will return 2days * 100/7
   */
  amountDueToDate?: Maybe<Money>;
  atpEmptyStarterSchemaNoop?: Maybe<Scalars["Boolean"]>;
  autoPayLiabilityStatus?: Maybe<AutoPayLiabilityStatus>;
  autoPayLiabilityStatuses?: Maybe<Array<Maybe<AutoPayLiabilityStatus>>>;
  autoWithdrawPlanById?: Maybe<AutoWithdrawPlan>;
  autoWithdrawPlans?: Maybe<PaginatedAutoWithdrawPlans>;
  bankReconciliationDetailReport?: Maybe<PaginatedReconciliationDetail>;
  bankReconciliationReport?: Maybe<BankReconciliationReport>;
  billById: Bill;
  billIssuesDrilldownReport?: Maybe<BillIssuesDrilldownReport>;
  billIssuesSummaryReport?: Maybe<BillIssuesSummaryReport>;
  bills?: Maybe<PaginatedBills>;
  /** @deprecated Use `bills` instead */
  billsByOrganisation: PaginatedBills;
  billsDueReport?: Maybe<BillsReport>;
  bondsReport?: Maybe<BondsReport>;
  canCreateProjectOfType?: Maybe<CreateProjectAbility>;
  centrepayReport?: Maybe<CentrepayReport>;
  chatV2: ChatV2;
  checkSagaStatus?: Maybe<SagaStatusResponse>;
  claimedFilesCount?: Maybe<ClaimedFilesCount>;
  /** Lists companies in which the current person has "legal_entity:data:view" permission. */
  companies?: Maybe<Array<Company>>;
  contacts: PaginatedContacts;
  contactsByEmails: Array<Contact>;
  contactsByIds: Array<Contact>;
  contactsByManagement: ContactsByManagement;
  contactsByTenancy: ContactsByTenancy;
  contactsByUserAilorns: Array<Contact>;
  /** @deprecated Use 'effectiveUser' instead */
  currentUser?: Maybe<User>;
  dashboardFigureByName?: Maybe<DashboardFigure>;
  effectiveUser?: Maybe<User>;
  embeddableReports?: Maybe<Array<Maybe<EmbeddableReport>>>;
  emptyAutopayment?: Maybe<Scalars["Boolean"]>;
  entries?: Maybe<PaginatedEntries>;
  fee?: Maybe<Fee>;
  feeBlueprint?: Maybe<FeeBlueprint>;
  feeBlueprints?: Maybe<PaginatedFeeBlueprints>;
  feeCatagories?: Maybe<Array<FeeCatagory>>;
  feeEvents?: Maybe<Array<FeeEvent>>;
  feeTaxCategories?: Maybe<Array<FeeTaxCategory>>;
  fees?: Maybe<PaginatedFees>;
  file?: Maybe<File>;
  files: Array<Maybe<File>>;
  findChatsV2: PaginatedChatsV2;
  findEftPaymentSettings?: Maybe<EftPaymentSettings>;
  firstAndLastEntries?: Maybe<FirstAndLastEntries>;
  form: Form;
  gatewayCapturePaymentDetails?: Maybe<GatewayCapturePaymentDetails>;
  getCentrepayFeeSetting: CentrepayFeeSetting;
  getCentrepayPaymentDirectives: Array<CentrepayDirective>;
  getCentrepayers: Array<CentrepayDirective>;
  getCrn?: Maybe<GetCrnOutput>;
  getLegalEntityByCrn?: Maybe<GetLegalEntityByCrnOutput>;
  getTrustAccountLock?: Maybe<TrustAccountLock>;
  getTrustAccounts: Array<TrustBankAccount>;
  ignoreMe?: Maybe<IgnoreMe>;
  inAppReports?: Maybe<Array<Maybe<InAppReport>>>;
  incomeAndExpenseByTaxCategory?: Maybe<IncomeAndExpenseByTaxCategoryData>;
  inspection: Inspection;
  inspectionAppointment: InspectionAppointment;
  inspectionAppointmentConnection: InspectionAppointmentConnection;
  inspectionArea: InspectionArea;
  inspectionAreas: Array<InspectionArea>;
  inspectionConnection: InspectionConnection;
  inspectionFeature: InspectionFeature;
  inspectionFeatures: Array<InspectionFeature>;
  inspections: Array<Inspection>;
  investorOnAppReport?: Maybe<InvestorOnAppReport>;
  key?: Maybe<Key>;
  ledgerEvent?: Maybe<LedgerEvent>;
  legalEntity?: Maybe<LegalEntity>;
  liabilityById?: Maybe<Liability>;
  liabilityCalculation?: Maybe<LiabilityCalculation>;
  liabilityEntry?: Maybe<LiabilityEntry>;
  liabilityPaymentPlanById?: Maybe<LiabilityPaymentPlan>;
  liabilityPaymentPlans?: Maybe<PaginatedLiabilityPaymentPlans>;
  liabilityPlanChangePreview?: Maybe<LiabilityChangePreview>;
  liabilityReportIgnoreMe?: Maybe<LiabilityReport>;
  listBankAccountBlacklistByOwner?: Maybe<Array<BankAccountBlacklist>>;
  listPaymentMethodAiloFeesWaiverByOwner?: Maybe<
    Array<Maybe<PaymentMethodAiloFees>>
  >;
  mailByIds: Array<Mail>;
  management?: Maybe<Management>;
  managementEndReasons: Array<ManagementEndReason>;
  managementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  managementFeeBlueprints?: Maybe<PaginatedManagementFeeBlueprints>;
  managementFeeSchedule: ManagementFeeSchedule;
  managementFolio?: Maybe<ManagementFolio>;
  managementFolioIgnoreMe?: Maybe<ManagementFolio>;
  managementFolios?: Maybe<PaginatedManagementFolios>;
  managements?: Maybe<PaginatedManagements>;
  managementsForOrg?: Maybe<BidirectionalPaginatedManagements>;
  messageV2: MessageV2;
  migratingManagement?: Maybe<MigratingManagement>;
  migratingManagements?: Maybe<PaginatedMigratingManagements>;
  notificationsReport?: Maybe<NotificationsReport>;
  organisation?: Maybe<Organisation>;
  organisations?: Maybe<PaginatedOrganisations>;
  /** @deprecated Use `bills` instead */
  paidBills: PaginatedBills;
  paidToDatePreview: PaidTo;
  payablePurposes?: Maybe<Array<Purpose>>;
  paymentMethodById?: Maybe<PaymentMethod>;
  paymentMethods?: Maybe<PaginatedPaymentMethods>;
  /** @deprecated Use `paymentMethods` instead. */
  paymentMethodsByOwner?: Maybe<PaginatedPaymentMethods>;
  paymentReferences?: Maybe<PaginatedPaymentReferences>;
  personEmailInUse: Scalars["Boolean"];
  previewMultipleRentReviews?: Maybe<Array<Maybe<RentReviewPlan>>>;
  project?: Maybe<Project>;
  projectsByOrganisation?: Maybe<PaginatedProjects>;
  properties?: Maybe<PaginatedProperties>;
  propertiesReport?: Maybe<PropertiesReport>;
  property?: Maybe<Property>;
  propertyIssuesDrilldownReport?: Maybe<PropertyIssuesDrilldownReport>;
  propertyIssuesMissingFeesReport?: Maybe<PropertyIssuesMissingFeesReport>;
  propertyIssuesSummaryReport?: Maybe<PropertyIssuesSummaryReport>;
  propertyLiveReport?: Maybe<PropertyLiveReport>;
  reconciliationReportPeriod?: Maybe<ReconciliationReportPeriod>;
  reconciliationReportPeriodByDate?: Maybe<ReconciliationReportPeriod>;
  reconciliationReportPeriods: ReconciliationReportPeriodConnection;
  recurringFee?: Maybe<RecurringFee>;
  /** @deprecated Use `feeBlueprint` instead. */
  recurringFeeBlueprint?: Maybe<RecurringFeeBlueprint>;
  /** @deprecated Use `feeBlueprints` instead. */
  recurringFeeBlueprints?: Maybe<Array<RecurringFeeBlueprint>>;
  /** @deprecated Use `feeBlueprints` instead. */
  recurringFeeBlueprintsV2?: Maybe<PaginatedRecurringFeeBlueprints>;
  recurringFees?: Maybe<Array<RecurringFee>>;
  recurringOwing?: Maybe<RecurringOwing>;
  recurringOwingByReference?: Maybe<RecurringOwing>;
  recurringOwingPreviewSummary: Array<OwingSummary>;
  reiForm: ReiForm;
  reiToken: ReiToken;
  reiTokens: Array<ReiToken>;
  rent?: Maybe<Rent>;
  rentReviewPreview?: Maybe<Array<Maybe<RentReviewPlan>>>;
  renterOnAppReport?: Maybe<RenterOnAppReport>;
  roles?: Maybe<Array<Role>>;
  /** @deprecated Use `LegalEntity.legalEntityMembershipsAsLegalEntity` instead. */
  rolesAtLegalEntity?: Maybe<Array<LegalEntityPersonRole>>;
  searchAgencyProperties?: Maybe<PaginatedAgencyPropertySearchItems>;
  searchContacts?: Maybe<PaginatedContactSearchItems>;
  searchTenancies?: Maybe<PaginatedTenancySearchResultItems>;
  searchTrustAccountWallets?: Maybe<PaginatedTrustAccountWalletSearchItems>;
  statement?: Maybe<Statement>;
  /** @deprecated The return type is deprecated. This is only being used for integration tests and will be removed eventually. */
  statementByAccount?: Maybe<DeprecatedStatementData>;
  statementProgress?: Maybe<StatementProgress>;
  statementProgresses?: Maybe<PaginatedStatementProgresses>;
  statements?: Maybe<PaginatedStatements>;
  supplier?: Maybe<Supplier>;
  /** @deprecated Use `supplier` instead */
  supplierById?: Maybe<Supplier>;
  suppliers: PaginatedSuppliers;
  taxCategories: Array<TaxCategory>;
  taxCategoryById?: Maybe<TaxCategory>;
  taxSummaryStatementData: TaxSummaryStatementData;
  tenancies?: Maybe<PaginatedTenancies>;
  tenancy?: Maybe<Tenancy>;
  tenancyAgreement?: Maybe<TenancyAgreement>;
  tenancyFirstRentPaymentReport?: Maybe<TenancyFirstRentPaymentReport>;
  /**
   * Query to only get the tenancy ledger line items that are related to initial payment. Intended as a stop gap until the dedicated
   * deposit service exists
   */
  tenancyLedgerInitialPaymentLineItems: Array<TenancyLedgerLineItem>;
  tenancyLedgerLineItems: Array<TenancyLedgerLineItem>;
  tenancyLedgerReport?: Maybe<TenancyLedgerReport>;
  threadV2: ThreadV2;
  totalClearedRentReceivedInWallets: TotalClearedRentReceived;
  transactionDetailsById?: Maybe<TransactionDetails>;
  transactionReport?: Maybe<PaginatedTransactionReport>;
  transactions?: Maybe<PaginatedTransactions>;
  transferSummaryStatementData?: Maybe<WalletStatementData>;
  /**
   * Query to get the range of a transfer summary statement.
   * The input businessTx and matching toDateTime corresponds to the transfer from management wallet to an investor's bank account.
   * This query returns the timestamp of that transfer (toDateTime), and the timestamp of the previous non-failed transfer (fromDateTime).
   * TransferSummaryStatementRange is to be applied as part of TransferSummaryStatementInput for the transferSummaryStatementData endpoint.
   */
  transferSummaryStatementRange?: Maybe<TransferSummaryStatementRange>;
  trustAccount?: Maybe<TrustAccount>;
  trustAccountReconciliationAdjustments: TrustAccountReconciliationAdjustmentConnection;
  trustAccountWallet?: Maybe<TrustAccountWallet>;
  trustAccountWallets: TrustAccountWalletConnection;
  trustAccounts: TrustAccountConnection;
  unreadMessagesCount: Scalars["Int"];
  /** @deprecated Use `bills` instead */
  upcomingBills: PaginatedBills;
  user?: Maybe<User>;
  users: Array<User>;
  walletBalanceReport?: Maybe<WalletBalanceReport>;
  walletById?: Maybe<Wallet>;
  walletByWalletOwnerReference?: Maybe<Wallet>;
  /** @deprecated Use `walletEntriesByOwnerRef` instead */
  walletEntriesWithStatusByOwnerRef?: Maybe<PaginatedWalletEntriesWithBtStatus>;
  walletOwnerLiabilityReport?: Maybe<WalletOwnerLiabilityReport>;
  walletStatement?: Maybe<WalletStatementData>;
};

export type QueryAbrCompanyLookupArgs = {
  abnOrAcn: Scalars["String"];
};

export type QueryAccountOwnerArgs = {
  reference: Scalars["AiloRN"];
};

export type QueryAccountTagBalancesArgs = {
  filter?: Maybe<ListAccountTagBalancesFilters>;
  paginationParams?: PaginationParams;
};

export type QueryAccountsArgs = {
  filters: ListAccountsFilters;
  pagination?: PaginationParams;
  sorts?: Array<ListAccountsSortParams>;
};

export type QueryActionArgs = {
  id: Scalars["ID"];
};

export type QueryActiveManagementsArgs = {
  pageCursor: ActiveManagementCursor;
  search?: Maybe<Scalars["String"]>;
};

export type QueryAgencyFinancialPerformanceReportArgs = {
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryAgencyIncomeByChartOfAccountReportArgs = {
  managingOrgId: Scalars["ID"];
  disbursementPeriodSk?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryAgencyIncomeByMonthReportArgs = {
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryAgencyIncomeByPropertyReportArgs = {
  managingOrgId: Scalars["ID"];
  disbursementPeriodSk?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
  includeActiveProperties?: Maybe<Scalars["Boolean"]>;
};

export type QueryAgencyIncomeDisbursementPeriodReportArgs = {
  managingOrgId: Scalars["ID"];
};

export type QueryAgencyIncomeReportArgs = {
  managingOrgId: Scalars["ID"];
  disbursementPeriodSk?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["LocalDate"]>;
  endDate?: Maybe<Scalars["LocalDate"]>;
  teamIds?: Maybe<Array<Scalars["ID"]>>;
  dimensions?: Maybe<Array<AgencyIncomeDimension>>;
  feeCategories?: Maybe<Array<Scalars["String"]>>;
};

export type QueryAgencyPropertiesArgs = {
  input: AgencyPropertiesQueryInput;
};

export type QueryAgencyPropertyArgs = {
  id: Scalars["ID"];
};

export type QueryAgencyPropertyByOrgAndPropertyArgs = {
  organisationAilorn: Scalars["AiloRN"];
  propertyId: Scalars["ID"];
};

export type QueryAiloFormArgs = {
  id: Scalars["ID"];
};

export type QueryAiloFormTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryAiloFormTemplateByTypeArgs = {
  version?: Maybe<Scalars["Int"]>;
  state: AustralianState;
  type: AiloFormType;
};

export type QueryAllTenanciesArgs = {
  input: AllTenanciesQueryInput;
};

export type QueryAmountDueToDateArgs = {
  amountDueToDateInput: AmountDueToDateInput;
};

export type QueryAutoPayLiabilityStatusArgs = {
  payerId?: Maybe<Scalars["AiloRN"]>;
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryAutoPayLiabilityStatusesArgs = {
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryAutoWithdrawPlanByIdArgs = {
  autoWithdrawPlanId: Scalars["ID"];
  payerLegalEntityId?: Maybe<Scalars["AiloRN"]>;
};

export type QueryAutoWithdrawPlansArgs = {
  disableOwnerFilter?: Maybe<Scalars["Boolean"]>;
  enabled: Scalars["Boolean"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryBankReconciliationDetailReportArgs = {
  cursor?: Maybe<BidirectionalPageCursor>;
  filter?: Maybe<Filter>;
};

export type QueryBankReconciliationReportArgs = {
  filter?: Maybe<DateRangeFilter>;
};

export type QueryBillByIdArgs = {
  billId: Scalars["ID"];
};

export type QueryBillIssuesDrilldownReportArgs = {
  managingOrgId: Scalars["ID"];
  billIssue?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryBillIssuesSummaryReportArgs = {
  managingOrgId?: Maybe<Scalars["ID"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryBillsArgs = {
  id?: Maybe<Array<Scalars["AiloRN"]>>;
  organisationId?: Maybe<Array<Scalars["AiloRN"]>>;
  payerId?: Maybe<Array<Scalars["AiloRN"]>>;
  payerIdNotIn?: Maybe<Array<Scalars["AiloRN"]>>;
  payerType?: Maybe<Array<BillPayerType>>;
  payeeId?: Maybe<Array<Scalars["AiloRN"]>>;
  taxCategoryId?: Maybe<Array<Scalars["String"]>>;
  taxCategoryIdNotIn?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Array<BillStatus>>;
  paymentStatus?: Maybe<Array<BillPaymentStatus>>;
  management?: Maybe<Array<Scalars["AiloRN"]>>;
  team?: Maybe<Array<Scalars["AiloRN"]>>;
  paid?: Maybe<Scalars["Boolean"]>;
  dueDate?: Maybe<LocalDateRangeCondition>;
  payByDate?: Maybe<LocalDateRangeCondition>;
  paymentDate?: Maybe<LocalDateRangeCondition>;
  invoiceNumber?: Maybe<Array<Scalars["String"]>>;
  agencyStatus?: Maybe<Array<BillAgencyStatus>>;
  sort?: Maybe<BillSortParams>;
  cursor?: Maybe<PageCursorWithoutSort>;
  excludeBillsWithEmptyPaymentStatus?: Maybe<Scalars["Boolean"]>;
  includeUnassigned?: Maybe<Scalars["Boolean"]>;
};

export type QueryBillsByOrganisationArgs = {
  organisationReference: Scalars["AiloRN"];
  taxCategoryIdIn?: Maybe<Array<Scalars["String"]>>;
  taxCategoryIdNotIn?: Maybe<Array<Scalars["String"]>>;
  ids?: Maybe<Array<Scalars["AiloRN"]>>;
  cursor?: Maybe<BillPageCursor>;
};

export type QueryBillsDueReportArgs = {
  managingOrgId: Scalars["ID"];
  billIssue?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryBondsReportArgs = {
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
  searchTerm?: Maybe<Scalars["String"]>;
};

export type QueryCanCreateProjectOfTypeArgs = {
  managementAilorn: Scalars["AiloRN"];
  type: ProjectType;
};

export type QueryCentrepayReportArgs = {
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
  searchTerm?: Maybe<Scalars["String"]>;
};

export type QueryChatV2Args = {
  chatAilorn: Scalars["AiloRN"];
};

export type QueryCheckSagaStatusArgs = {
  sagaId?: Maybe<Scalars["ID"]>;
};

export type QueryClaimedFilesCountArgs = {
  claimerAilorn: Scalars["AiloRN"];
  fileKind?: Maybe<FileKind>;
};

export type QueryContactsArgs = {
  input: ContactsQueryInput;
};

export type QueryContactsByEmailsArgs = {
  organisationAilorn: Scalars["AiloRN"];
  emails: Array<Scalars["String"]>;
};

export type QueryContactsByIdsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type QueryContactsByManagementArgs = {
  managementAilorn: Scalars["AiloRN"];
  filters?: Maybe<ContactsFilters>;
};

export type QueryContactsByTenancyArgs = {
  tenancyAilorn: Scalars["AiloRN"];
  filters?: Maybe<ContactsFilters>;
};

export type QueryContactsByUserAilornsArgs = {
  organisationAilorn: Scalars["AiloRN"];
  userAilorns: Array<Scalars["AiloRN"]>;
};

export type QueryDashboardFigureByNameArgs = {
  dashboardFigureName: Scalars["String"];
  includeUnassigned?: Maybe<Scalars["Boolean"]>;
  agencyId: Scalars["ID"];
  teams?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryEmbeddableReportsArgs = {
  managingOrgId?: Maybe<Scalars["ID"]>;
};

export type QueryEntriesArgs = {
  filters: ListEntriesFilters;
  pagination?: PaginationParams;
  sorts?: Array<ListEntriesSortParams>;
};

export type QueryFeeArgs = {
  id: Scalars["ID"];
};

export type QueryFeeBlueprintArgs = {
  id: Scalars["ID"];
};

export type QueryFeeBlueprintsArgs = {
  conditions?: Maybe<FeeBlueprintsQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
};

export type QueryFeeCatagoriesArgs = {
  managingOrgId: Scalars["ID"];
  teamIds?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryFeesArgs = {
  conditions?: Maybe<FeesQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
  sort?: Maybe<Array<FeeSort>>;
};

export type QueryFileArgs = {
  id: Scalars["AiloRN"];
};

export type QueryFilesArgs = {
  ailorns: Array<Scalars["AiloRN"]>;
};

export type QueryFindChatsV2Args = {
  input: PaginatedChatsInputV2;
};

export type QueryFindEftPaymentSettingsArgs = {
  agency: Scalars["AiloRN"];
};

export type QueryFirstAndLastEntriesArgs = {
  filters: ListEntriesFilters;
};

export type QueryFormArgs = {
  id: Scalars["ID"];
};

export type QueryGatewayCapturePaymentDetailsArgs = {
  owner: Scalars["AiloRN"];
};

export type QueryGetCentrepayFeeSettingArgs = {
  escrowAccount: Scalars["AiloRN"];
};

export type QueryGetCentrepayPaymentDirectivesArgs = {
  input: GetCentrepayPaymentDirectivesFilter;
};

export type QueryGetCentrepayersArgs = {
  input: GetCentrepayersInput;
};

export type QueryGetCrnArgs = {
  input: GetCrnInput;
};

export type QueryGetLegalEntityByCrnArgs = {
  input: GetLegalEntityByCrnInput;
};

export type QueryGetTrustAccountLockArgs = {
  trustAccountAilorn: Scalars["AiloRN"];
};

export type QueryGetTrustAccountsArgs = {
  input: GetTrustAccountInput;
};

export type QueryInAppReportsArgs = {
  managingOrgId: Scalars["ID"];
  legalEntityId?: Maybe<Scalars["ID"]>;
};

export type QueryIncomeAndExpenseByTaxCategoryArgs = {
  input?: Maybe<IncomeAndExpenseByTaxCategoryInput>;
};

export type QueryInspectionArgs = {
  id: Scalars["ID"];
};

export type QueryInspectionAppointmentArgs = {
  id: Scalars["ID"];
};

export type QueryInspectionAppointmentConnectionArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<InspectionAppointmentFilter>;
  sorting?: Maybe<Array<InspectionAppointmentSort>>;
};

export type QueryInspectionAreaArgs = {
  id: Scalars["ID"];
};

export type QueryInspectionConnectionArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<InspectionFilter>;
  sorting?: Maybe<Array<InspectionSort>>;
};

export type QueryInspectionFeatureArgs = {
  id: Scalars["ID"];
};

export type QueryInvestorOnAppReportArgs = {
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryKeyArgs = {
  id: Scalars["ID"];
};

export type QueryLedgerEventArgs = {
  id: Scalars["ID"];
};

export type QueryLegalEntityArgs = {
  id: Scalars["AiloRN"];
};

export type QueryLiabilityByIdArgs = {
  liabilityId: Scalars["ID"];
};

export type QueryLiabilityCalculationArgs = {
  liabilityCalculationInput: LiabilityCalculationInput;
};

export type QueryLiabilityEntryArgs = {
  liabilityEntryId: Scalars["ID"];
};

export type QueryLiabilityPaymentPlanByIdArgs = {
  liabilityPaymentPlanId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryLiabilityPaymentPlansArgs = {
  enabled: Scalars["Boolean"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryLiabilityPlanChangePreviewArgs = {
  planToPreview: LiabilityPlanInput;
};

export type QueryListBankAccountBlacklistByOwnerArgs = {
  owner: Scalars["AiloRN"];
};

export type QueryListPaymentMethodAiloFeesWaiverByOwnerArgs = {
  owner: Scalars["AiloRN"];
};

export type QueryMailByIdsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type QueryManagementArgs = {
  managementId: Scalars["ID"];
};

export type QueryManagementFeeBlueprintArgs = {
  id: Scalars["ID"];
};

export type QueryManagementFeeBlueprintsArgs = {
  conditions?: Maybe<ManagementFeeBlueprintsQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
};

export type QueryManagementFeeScheduleArgs = {
  id: Scalars["ID"];
};

export type QueryManagementFolioArgs = {
  id: Scalars["ID"];
};

export type QueryManagementFoliosArgs = {
  id?: Maybe<Array<Scalars["ID"]>>;
  ownerId?: Maybe<Array<Scalars["ID"]>>;
  managingEntityId?: Maybe<Array<Scalars["ID"]>>;
  paginationParams?: PaginationParams;
};

export type QueryManagementsForOrgArgs = {
  organisationId: Scalars["AiloRN"];
  pageCursor: ManagementCursor;
  filters?: Array<ManagementFilterParams>;
};

export type QueryMessageV2Args = {
  messageAilorn: Scalars["AiloRN"];
};

export type QueryMigratingManagementArgs = {
  id: Scalars["ID"];
};

export type QueryMigratingManagementsArgs = {
  pagination: PaginationParams;
  filters: MigratingManagementsFilters;
  sort: Array<MigratingManagementSortParams>;
};

export type QueryNotificationsReportArgs = {
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
  searchTerm?: Maybe<Scalars["String"]>;
};

export type QueryOrganisationArgs = {
  id: Scalars["ID"];
};

export type QueryOrganisationsArgs = {
  pageCursor?: Maybe<PageCursor>;
  orgType?: Maybe<OrganisationType>;
  search?: Maybe<Scalars["String"]>;
};

export type QueryPaidBillsArgs = {
  toBePaidBy: Scalars["AiloRN"];
  cursor?: Maybe<BillPageCursor>;
};

export type QueryPaidToDatePreviewArgs = {
  owing: RecurringOwingCalculationInput;
  totalAmountPaid?: Maybe<MoneyInput>;
};

export type QueryPayablePurposesArgs = {
  filters: ListPurposesFilters;
};

export type QueryPaymentMethodByIdArgs = {
  paymentMethodId: Scalars["ID"];
};

export type QueryPaymentMethodsArgs = {
  conditions?: Maybe<PaymentMethodSearchOptions>;
};

export type QueryPaymentMethodsByOwnerArgs = {
  options?: Maybe<PaymentMethodSearchOptions>;
  owner: Scalars["AiloRN"];
};

export type QueryPaymentReferencesArgs = {
  cursor?: Maybe<BillDefaultPageCursor>;
  conditions?: Maybe<PaymentReferencesQueryConditions>;
};

export type QueryPersonEmailInUseArgs = {
  email: Scalars["String"];
};

export type QueryPreviewMultipleRentReviewsArgs = {
  proposedPlan: PreviewMultipleRentReviewsInput;
};

export type QueryProjectArgs = {
  id: Scalars["ID"];
};

export type QueryProjectsByOrganisationArgs = {
  organisationAilorn: Scalars["AiloRN"];
  filter?: Maybe<ProjectFilter>;
  pageCursor?: ProjectCursor;
};

export type QueryPropertiesArgs = {
  pageCursor: PmPageCursor;
  search?: Maybe<Scalars["String"]>;
};

export type QueryPropertiesReportArgs = {
  managingOrgId: Scalars["ID"];
  propertyIssue?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryPropertyArgs = {
  propertyId: Scalars["ID"];
};

export type QueryPropertyIssuesDrilldownReportArgs = {
  managingOrgId: Scalars["ID"];
  propertyIssue: Scalars["String"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryPropertyIssuesMissingFeesReportArgs = {
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
  propertyId?: Maybe<Array<Scalars["String"]>>;
};

export type QueryPropertyIssuesSummaryReportArgs = {
  managingOrgId?: Maybe<Scalars["ID"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
  excludeIssues?: Maybe<Array<Scalars["String"]>>;
};

export type QueryPropertyLiveReportArgs = {
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryReconciliationReportPeriodArgs = {
  id: Scalars["ID"];
};

export type QueryReconciliationReportPeriodByDateArgs = {
  input: ReportPeriodByDateInput;
};

export type QueryReconciliationReportPeriodsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<ReconciliationReportPeriodFilter>;
  sorting?: Maybe<Array<ReconciliationReportPeriodSort>>;
};

export type QueryRecurringFeeArgs = {
  id: Scalars["AiloRN"];
};

export type QueryRecurringFeeBlueprintArgs = {
  id: Scalars["AiloRN"];
};

export type QueryRecurringFeeBlueprintsArgs = {
  conditions: RecurringFeeBlueprintsQueryInput;
};

export type QueryRecurringFeeBlueprintsV2Args = {
  conditions?: Maybe<RecurringFeeBlueprintsQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
};

export type QueryRecurringFeesArgs = {
  conditions: RecurringFeesQueryInput;
};

export type QueryRecurringOwingArgs = {
  id: Scalars["ID"];
};

export type QueryRecurringOwingByReferenceArgs = {
  ref: Scalars["AiloRN"];
};

export type QueryRecurringOwingPreviewSummaryArgs = {
  owing: RecurringOwingCalculationInput;
};

export type QueryReiFormArgs = {
  id: Scalars["ID"];
};

export type QueryReiTokenArgs = {
  id: Scalars["ID"];
};

export type QueryRentArgs = {
  id: Scalars["AiloRN"];
};

export type QueryRentReviewPreviewArgs = {
  proposedPlan: RentReviewProposedPlanInput;
};

export type QueryRenterOnAppReportArgs = {
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryRolesArgs = {
  conditions?: Maybe<RolesQueryConditions>;
};

export type QueryRolesAtLegalEntityArgs = {
  legalEntity: Scalars["AiloRN"];
};

export type QuerySearchAgencyPropertiesArgs = {
  input: SearchAgencyPropertiesQueryInput;
};

export type QuerySearchContactsArgs = {
  organisationAilornIn?: Maybe<Array<Scalars["AiloRN"]>>;
  typeOf?: Maybe<Array<Scalars["String"]>>;
  legalEntityType?: Maybe<Array<Scalars["String"]>>;
  query?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<ContactSortParams>>;
  paginationParams?: Maybe<PaginationParamsWithPageNumber>;
};

export type QuerySearchTenanciesArgs = {
  input: SearchTenanciesQueryInput;
};

export type QuerySearchTrustAccountWalletsArgs = {
  input: SearchTrustAccountWalletsQueryInput;
};

export type QueryStatementArgs = {
  id: Scalars["ID"];
};

export type QueryStatementByAccountArgs = {
  statementByAccountInput: StatementByAccountInput;
};

export type QueryStatementProgressArgs = {
  id: Scalars["ID"];
};

export type QueryStatementProgressesArgs = {
  filter: StatementProgressFilter;
  cursor: StatementProgressCursor;
};

export type QueryStatementsArgs = {
  filter?: Maybe<GeneralStatementFilter>;
  paginationParams?: Maybe<PaginationParams>;
  sort?: Maybe<Array<StatementSort>>;
};

export type QuerySupplierArgs = {
  id: Scalars["ID"];
};

export type QuerySupplierByIdArgs = {
  supplierId: Scalars["ID"];
};

export type QuerySuppliersArgs = {
  cursor: PageCursor;
  conditions?: Maybe<SuppliersQueryInput>;
};

export type QueryTaxCategoryByIdArgs = {
  taxCategoryId: Scalars["String"];
};

export type QueryTaxSummaryStatementDataArgs = {
  statementRequest: TaxSummaryStatementInput;
};

export type QueryTenancyArgs = {
  tenancyId: Scalars["ID"];
};

export type QueryTenancyAgreementArgs = {
  id: Scalars["ID"];
};

export type QueryTenancyFirstRentPaymentReportArgs = {
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryTenancyLedgerInitialPaymentLineItemsArgs = {
  input?: Maybe<TenancyLedgerLineItemsInput>;
};

export type QueryTenancyLedgerLineItemsArgs = {
  input?: Maybe<TenancyLedgerLineItemsInput>;
};

export type QueryTenancyLedgerReportArgs = {
  managingOrgId: Scalars["ID"];
  tenancyId: Scalars["String"];
};

export type QueryThreadV2Args = {
  threadAilorn: Scalars["AiloRN"];
};

export type QueryTotalClearedRentReceivedInWalletsArgs = {
  ownerRef: Scalars["AiloRN"];
};

export type QueryTransactionDetailsByIdArgs = {
  businessTransactionId: Scalars["AiloRN"];
};

export type QueryTransactionReportArgs = {
  cursor: LedgerPageCursor;
  filter?: Maybe<Filter>;
};

export type QueryTransactionsArgs = {
  filter: ListTransactionsFilters;
  paginationParams?: PaginationParams;
  sort?: Array<ListTransactionsSortParams>;
};

export type QueryTransferSummaryStatementDataArgs = {
  input?: Maybe<TransferSummaryStatementInput>;
};

export type QueryTransferSummaryStatementRangeArgs = {
  input?: Maybe<TransferSummaryStatementRangeInput>;
};

export type QueryTrustAccountArgs = {
  id: Scalars["ID"];
};

export type QueryTrustAccountReconciliationAdjustmentsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TrustAccountReconciliationAdjustmentFilter>;
  sorting?: Maybe<Array<TrustAccountReconciliationAdjustmentSort>>;
};

export type QueryTrustAccountWalletArgs = {
  id: Scalars["ID"];
};

export type QueryTrustAccountWalletsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TrustAccountWalletFilter>;
  sorting?: Maybe<Array<TrustAccountWalletSort>>;
};

export type QueryTrustAccountsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TrustAccountFilter>;
  sorting?: Maybe<Array<TrustAccountSort>>;
};

export type QueryUnreadMessagesCountArgs = {
  input: UnreadMessagesCountInput;
};

export type QueryUpcomingBillsArgs = {
  toBePaidBy: Scalars["AiloRN"];
  cursor?: Maybe<BillPageCursor>;
};

export type QueryUserArgs = {
  id: Scalars["Int"];
};

export type QueryUsersArgs = {
  ids: Array<Scalars["Int"]>;
};

export type QueryWalletBalanceReportArgs = {
  filter?: Maybe<Filter>;
};

export type QueryWalletByIdArgs = {
  walletId: Scalars["ID"];
};

export type QueryWalletByWalletOwnerReferenceArgs = {
  walletOwnerReference: Scalars["AiloRN"];
};

export type QueryWalletEntriesWithStatusByOwnerRefArgs = {
  aggregated?: Maybe<Scalars["Boolean"]>;
  cursor?: Maybe<LedgerPageCursor>;
  ownerRef: Scalars["AiloRN"];
  relatingToManagementAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type QueryWalletOwnerLiabilityReportArgs = {
  filter?: Maybe<DateRangeFilter>;
};

export type QueryWalletStatementArgs = {
  input: WalletStatementInput;
};

export type Mutation = {
  __typename?: "Mutation";
  unlockReportPeriod: ReportPeriodLockChangeResult;
  createOneTrustAccountReconciliationAdjustment: TrustAccountReconciliationAdjustment;
  removeOneTrustAccountReconciliationAdjustment: TrustAccountReconciliationAdjustment;
  createOneTrustAccountStatementBalance: TrustAccountStatementBalance;
  upsertEftPaymentSettings: EftPaymentSettings;
  createOneTrustAccount: TrustAccount;
  updateOneTrustAccount: TrustAccount;
  createStatements: CreateStatementsResponse;
  createStatementsWithSubject: CreateStatementsResponse;
  createStatementsForAllManagements: Scalars["String"];
  retryCreateStatement?: Maybe<Statement>;
  addTrustAccount?: Maybe<TrustBankAccount>;
  disburseFundsToTrustAccount?: Maybe<DisburseFundsResponse>;
  addCentrepayDirective: AddCentrepayDirectiveOutput;
  deleteCentrepayDirective: Scalars["Boolean"];
  upsertCentrepayFeeOverride: CentrepayFeeSetting;
  registerPushToken?: Maybe<PushToken>;
  deregisterPushToken?: Maybe<PushToken>;
  createTenancy?: Maybe<Tenancy>;
  updateEndOfTenancy?: Maybe<Tenancy>;
  updateIngoingTenancy?: Maybe<Tenancy>;
  reviewLease?: Maybe<Tenancy>;
  voidTenancy: VoidTenancyPayload;
  alterTenancyStartDate?: Maybe<Tenancy>;
  createRents?: Maybe<CreateRentsPayload>;
  deleteRent?: Maybe<DeleteRentPayload>;
  /** @deprecated Use `createFeeBlueprint` instead. */
  createRecurringFeeBlueprint?: Maybe<RecurringFeeBlueprint>;
  createFeeBlueprint?: Maybe<FeeBlueprint>;
  findOrCreateFeeBlueprint?: Maybe<FeeBlueprint>;
  /** @deprecated Use `updateFeeBlueprint` instead. */
  updateRecurringFeeBlueprint?: Maybe<RecurringFeeBlueprint>;
  updateFeeBlueprint?: Maybe<FeeBlueprint>;
  clearManagementEnd?: Maybe<ClearManagementEndPayload>;
  updateManagementAgreement: UpdateManagementAgreementPayload;
  updateManagementFee: ManagementFeeSchedule;
  createFee?: Maybe<Fee>;
  updateFee?: Maybe<Fee>;
  createRecurringFee?: Maybe<RecurringFee>;
  createRecurringFees?: Maybe<Array<RecurringFee>>;
  /**
   * Cancels the recurring fee by setting its end date, to stop charging the fee.
   * Any outstanding balance still needs to be paid off.
   */
  cancelRecurringFee?: Maybe<RecurringFee>;
  updateRecurringFee?: Maybe<RecurringFee>;
  setupManagementAndTenancy?: Maybe<SetupResult>;
  createProperty?: Maybe<Property>;
  updateProperty?: Maybe<Property>;
  createRentCredit?: Maybe<RentCreditDetailsPayload>;
  createTenancyAgreement?: Maybe<CreateTenancyAgreementPayload>;
  updateTenancyAgreement?: Maybe<UpdateTenancyAgreementPayload>;
  /** @deprecated Use `createRentReview` instead. */
  reviewRent?: Maybe<Tenancy>;
  createRentReview?: Maybe<CreateRentReviewPayload>;
  editRentReview?: Maybe<EditRentReviewPayload>;
  attachFilesToTenancy?: Maybe<Tenancy>;
  attachFilesToManagement?: Maybe<Management>;
  deleteTenancyFile?: Maybe<DeleteTenancyFilePayload>;
  deleteManagementFile?: Maybe<DeleteManagementFilePayload>;
  findOrCreateManagementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  createManagementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  updateManagementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  createOrUpdateManagementNote?: Maybe<CreateOrUpdateManagementNotePayload>;
  endManagementV2: EndManagementV2Payload;
  createTenancyBond?: Maybe<TenancyBond>;
  updateTenancyBond?: Maybe<TenancyBond>;
  upsertTenancyBondClaims: Array<Maybe<TenancyBondClaim>>;
  removeBondClaim: TenancyBond;
  updateTenancyBondStatus: TenancyBond;
  transferTenancyDeposit?: Maybe<TenancyDeposit>;
  cancelTenancyDeposit?: Maybe<TenancyDeposit>;
  moveManagementsToTeam?: Maybe<Team>;
  createProject?: Maybe<Project>;
  updateProject?: Maybe<Project>;
  closeProject?: Maybe<Project>;
  reopenProject?: Maybe<Project>;
  /** Deprecated - use archiveProjects instead */
  archiveProject?: Maybe<Scalars["ID"]>;
  archiveProjects: ArchiveProjectsPayload;
  unarchiveProject?: Maybe<Project>;
  createAction?: Maybe<Action>;
  updateAction?: Maybe<Action>;
  reassignAction?: Maybe<Action>;
  updateActionDueDate?: Maybe<Action>;
  completeAction?: Maybe<Action>;
  uncompleteAction?: Maybe<Action>;
  addProjectFiles?: Maybe<Array<Maybe<ProjectFile>>>;
  removeProjectFiles?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  completeAssignTenancyAgreementAction?: Maybe<Action>;
  completeAssignRentAction?: Maybe<CompleteAssignRentActionPayload>;
  completeAssignTenancyAction?: Maybe<CompleteAssignTenancyActionPayload>;
  completeAssignNewManagementAction?: Maybe<CompleteAssignNewManagementActionPayload>;
  completeAssignFormAction?: Maybe<CompleteAssignFormActionPayload>;
  lockTrustAccount?: Maybe<TrustAccountLock>;
  payFromWallet?: Maybe<SagaStatusResponse>;
  receiptFunds?: Maybe<SagaStatusResponse>;
  walletTransfer?: Maybe<SagaStatusResponse>;
  createRecurringOwing?: Maybe<RecurringOwing>;
  updateRecurringOwing?: Maybe<RecurringOwing>;
  startMigrations?: Maybe<BulkActionMigrationTasksPayload>;
  approveMigratingManagements?: Maybe<BulkActionMigratingManagementsPayload>;
  unapproveMigratingManagements?: Maybe<BulkActionMigratingManagementsPayload>;
  excludeMigratingManagement: MigratingManagement;
  includeMigratingManagements?: Maybe<BulkActionMigratingManagementsPayload>;
  publishMigratingManagements?: Maybe<BulkActionMigratingManagementsPayload>;
  updateMigratingManagement: MigratingManagement;
  revokeMailboxAccess: Scalars["Boolean"];
  sendMail: Mail;
  getMailboxAuthorizationUrl?: Maybe<Scalars["String"]>;
  /** @deprecated Use `createPaymentMethod` instead */
  addBPay?: Maybe<BPay>;
  /** @deprecated Use `createPaymentMethod` instead */
  addBankAccount?: Maybe<BankAccount>;
  /** @deprecated Use `createPaymentMethod` instead */
  addCreditCard?: Maybe<CreditCard>;
  adjustLiabilityEntry?: Maybe<AdjustmentLiabilityEntry>;
  archiveLiability: LiabilityStatus;
  cancelAutoPay?: Maybe<ResponseStatus>;
  cancelAutoPayLiabilityStatus?: Maybe<AutoPayLiabilityStatus>;
  cancelAutoWithdrawPlan?: Maybe<AutoWithdrawPlan>;
  cancelLiabilityPaymentPlan?: Maybe<LiabilityPaymentPlan>;
  cleanOrphanedBusinessTransaction: BusinessTransactionStatus;
  cleanUnclearedBusinessTransaction: BusinessTransactionStatus;
  /**
   * Use for BILL HOP1 late reversal and HOP2 has already been SUCCESS and cleared. Details: http://ailo.atlassian.net/wiki/spaces/TS/pages/1622245700/How+to+do+all+Reversal+Refund+like+things
   * No real world payment transaction will be created, purely Ailo adjustment internally.
   */
  createAdjustmentForPayer: BusinessTransaction;
  createAutoWithdrawPlanV2?: Maybe<AutoWithdrawPlan>;
  createBankAccountBlacklist: BankAccountBlacklist;
  createLiabilityPaymentPlan?: Maybe<LiabilityPaymentPlan>;
  createOrUpdatePaymentMethodAiloFeeWaiver: Array<Maybe<PaymentMethodAiloFees>>;
  createOverdraftAllowance: OverdraftAllowance;
  createPaymentMethod?: Maybe<PaymentMethod>;
  createUpdateAutoPay?: Maybe<ResponseStatus>;
  creditFromAiloBank?: Maybe<ResponseStatus>;
  deleteBankAccountBlacklist: BankAccountBlacklist;
  /**
   * Graphql best practice require mutation return something.
   * This should always return true.
   * Exceptions may be thrown in error.
   */
  deletePaymentMethod: Scalars["Boolean"];
  emitLiabilityStateChangedEvent?: Maybe<Liability>;
  generateLiabilityEntries: GenerateLiabilityEntriesPayload;
  /**
   * This should only be used by admin or service-to-service communication.
   * This is used to prevent creating duplicate bank account.
   * This never creates once-off bank account.
   */
  getOrCreateWalletOwnerAndBankAccount?: Maybe<BankAccount>;
  partialRefundLiabilityPayment: Array<RefundOutput>;
  payLiability?: Maybe<ResponseStatus>;
  payLiabilityFromWallet?: Maybe<ResponseStatus>;
  /**
   * Use for RENT/FEE/BILL HOP1 REDUND. Details: https://ailo.atlassian.net/wiki/spaces/TS/pages/1622245700/How+to+do+all+Reversal+Refund+like+things
   * If the payment is coming from a payment method, it will credit the money back to the payment method, so real world payment transaction will be initiated
   * If the payment is coming from a wallet, it will credit the money back to the wallet.
   */
  refundLiabilityPayment: Array<RefundOutput>;
  reverseAdjustment?: Maybe<AdjustmentLiabilityEntry>;
  /**
   * Use for RENT late revsersal, make sure the management fee has been refund first before late reversal of RENT. Details: https://ailo.atlassian.net/wiki/spaces/TS/pages/1622245700/How+to+do+all+Reversal+Refund+like+things
   * Use for BILL Hop2 late reversal, make sure the param are bill HOP2 BT id, no overdraft allowance will be created against Ailo bank wallet.
   * Use for BILL Hop1 late reversal and HOP2 VOIDed or FAILed.  make sure the param are bill HOP1 BT id, no overdraft allowance will be created against supplier wallet
   * Use for wallet withdraw late reversal, no overdraft allowance will be created.
   * No real world payment transaction will be created, purely Ailo adjustment internally.
   */
  reverseBusinessTx: BusinessTransaction;
  transferToWallet?: Maybe<ResponseStatus>;
  updateAutoWithdrawPlanV2?: Maybe<AutoWithdrawPlan>;
  updateLiabilityPaymentPlan?: Maybe<LiabilityPaymentPlan>;
  upsertAutoPayLiabilityStatus?: Maybe<AutoPayLiabilityStatus>;
  withdrawFromWallet?: Maybe<ResponseStatus>;
  writeOff?: Maybe<ResponseStatus>;
  createOneKey: Key;
  updateOneKey: Key;
  deleteOneKey: KeyDeleteResponse;
  createInspection: CreateInspectionResult;
  updateInspection: UpdateInspectionResult;
  removeInspection: RemoveInspectionResult;
  createInspectionArea: CreateInspectionAreaResult;
  updateInspectionArea: UpdateInspectionAreaResult;
  removeInspectionArea: RemoveInspectionAreaResult;
  createInspectionAreaFile: CreateInspectionAreaFileResult;
  removeInspectionAreaFile: RemoveInspectionAreaFileResult;
  createInspectionFeature: CreateInspectionFeatureResult;
  updateInspectionFeature: UpdateInspectionFeatureResult;
  removeInspectionFeature: RemoveInspectionFeatureResult;
  createInspectionFeatureFile: CreateInspectionFeatureFileResult;
  removeInspectionFeatureFile: RemoveInspectionFeatureFileResult;
  createInspectionAppointments: CreateInspectionAppointmentsResult;
  updateInspectionAppointment: UpdateInspectionAppointmentResult;
  cancelInspectionAppointment: CancelInspectionAppointmentResult;
  setRoutineInspectionDueDate: SetRoutineInspectionDueDateResult;
  generateInspectionReport: GenerateInspectionReportResult;
  createBanana?: Maybe<Banana>;
  createReiToken: ReiToken;
  updateReiToken: ReiToken;
  removeReiToken: ReiToken;
  createReiFormFromUserTemplate: ReiForm;
  createAiloForm: CreateAiloFormResult;
  updateAiloForm: UpdateAiloFormResult;
  /**
   * Creates a new file entity attached to the current user,
   * and returns it together with an AWS S3 Upload URL to which the user can upload a file.
   */
  startFileUpload: FileWithUploadUrl;
  /**
   * Validate if the file has been uploaded.
   *
   * File needs to belong to the current user,
   * or if it's a m2m call, requires "file:claim" scope.
   */
  validateFile: ValidateFileResponse;
  createOtherContact: Contact;
  findOrCreateOtherContacts: Array<Contact>;
  syncLegalEntity: Array<Contact>;
  createChatV2?: Maybe<ChatV2>;
  createThreadV2: ThreadV2;
  sendMessageV2: MessageV2;
  acknowledgeThread?: Maybe<ThreadV2>;
  unacknowledgeThreadFromMessage?: Maybe<ThreadV2>;
  createBill?: Maybe<Bill>;
  archiveBill?: Maybe<Bill>;
  addBillAttachments?: Maybe<Bill>;
  createPaymentReference?: Maybe<PaymentReference>;
  findOrCreatePaymentReference?: Maybe<PaymentReference>;
  createSupplier?: Maybe<Supplier>;
  findOrCreateInternalSupplier?: Maybe<Supplier>;
  archiveSupplier?: Maybe<Supplier>;
  updateSupplier?: Maybe<Supplier>;
  createBillLiabilityState?: Maybe<BillLiabilityState>;
  noopAutopayment?: Maybe<Scalars["Boolean"]>;
  upsertCrn?: Maybe<UpsertCrnOutput>;
  deleteCrn?: Maybe<Scalars["Boolean"]>;
  sendEmailVerificationCode?: Maybe<SendEmailVerificationResponse>;
  verifyEmail?: Maybe<VerifyEmailResponse>;
  cancelEmailVerifications?: Maybe<Scalars["Boolean"]>;
  acceptTermsOfServices?: Maybe<Person>;
  updatePersonProfileDetails?: Maybe<Person>;
  updateCompanyProfileDetails?: Maybe<Company>;
  addMemberToOrganisation?: Maybe<OrganisationMembership>;
  removeMemberFromOrganisation?: Maybe<Scalars["Boolean"]>;
  addMemberToLegalEntity?: Maybe<LegalEntityMembership>;
  removeMemberFromLegalEntity?: Maybe<Scalars["Boolean"]>;
  completeOnboardingTask?: Maybe<UserOnboardingTask>;
  sendPhoneVerificationCode?: Maybe<SendPhoneVerificationCodeResponse>;
  verifyPhoneVerificationCode?: Maybe<VerifyPhoneVerificationCodeResponse>;
  atpEmptyStarterSchemaNoop?: Maybe<Scalars["Boolean"]>;
  _empty?: Maybe<Scalars["Boolean"]>;
};

export type MutationUnlockReportPeriodArgs = {
  input: UnlockReportPeriodInput;
};

export type MutationCreateOneTrustAccountReconciliationAdjustmentArgs = {
  input: CreateOneTrustAccountReconciliationAdjustmentInput;
};

export type MutationRemoveOneTrustAccountReconciliationAdjustmentArgs = {
  input: RemoveTrustAccountReconciliationAdjustmentInput;
};

export type MutationCreateOneTrustAccountStatementBalanceArgs = {
  input: CreateOneTrustAccountStatementBalanceInput;
};

export type MutationUpsertEftPaymentSettingsArgs = {
  input: EftPaymentSettingsInput;
};

export type MutationCreateOneTrustAccountArgs = {
  input: CreateOneTrustAccountInput;
};

export type MutationUpdateOneTrustAccountArgs = {
  input: UpdateOneTrustAccountInput;
};

export type MutationCreateStatementsArgs = {
  input: CreateStatementInput;
};

export type MutationCreateStatementsWithSubjectArgs = {
  input: CreateStatementWithSubjectInput;
};

export type MutationCreateStatementsForAllManagementsArgs = {
  input: CreateStatementsForAllManagementsInput;
};

export type MutationRetryCreateStatementArgs = {
  progressAiloRN: Scalars["AiloRN"];
};

export type MutationAddTrustAccountArgs = {
  input: AddTrustAccountInput;
};

export type MutationDisburseFundsToTrustAccountArgs = {
  input: DisburseToTrustAccountInput;
};

export type MutationAddCentrepayDirectiveArgs = {
  input: AddCentrepayDirectiveInput;
};

export type MutationDeleteCentrepayDirectiveArgs = {
  input: DeleteCentrepayDirectiveInput;
};

export type MutationUpsertCentrepayFeeOverrideArgs = {
  input: CentrepayFeeOverrideInput;
};

export type MutationRegisterPushTokenArgs = {
  token: Scalars["String"];
  projectId?: Maybe<Scalars["String"]>;
};

export type MutationDeregisterPushTokenArgs = {
  token: Scalars["String"];
};

export type MutationCreateTenancyArgs = {
  tenancyDetails: TenancySetupInput;
  managementId: Scalars["ID"];
  autoPublish?: Maybe<Scalars["Boolean"]>;
  doNotNotify?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateEndOfTenancyArgs = {
  tenancyId: Scalars["ID"];
  endDate?: Maybe<Scalars["Date"]>;
  reason?: Maybe<VacatingReason>;
  notes?: Maybe<Scalars["String"]>;
};

export type MutationUpdateIngoingTenancyArgs = {
  tenancyDetails: IngoingTenancyUpdateInput;
};

export type MutationReviewLeaseArgs = {
  tenancyId: Scalars["ID"];
  agreementDetails?: Maybe<LeaseRenewalDetails>;
  allowCurrentTenancyAgreementToLapse?: Maybe<Scalars["Boolean"]>;
};

export type MutationVoidTenancyArgs = {
  tenancyId: Scalars["ID"];
};

export type MutationAlterTenancyStartDateArgs = {
  tenancyId: Scalars["ID"];
  startDate: Scalars["String"];
};

export type MutationCreateRentsArgs = {
  input: CreateRentsInput;
};

export type MutationDeleteRentArgs = {
  input: DeleteRentInput;
};

export type MutationCreateRecurringFeeBlueprintArgs = {
  input: RecurringFeeBlueprintInput;
};

export type MutationCreateFeeBlueprintArgs = {
  input: CreateFeeBlueprintInput;
};

export type MutationFindOrCreateFeeBlueprintArgs = {
  input: FindOrCreateBlueprintInput;
};

export type MutationUpdateRecurringFeeBlueprintArgs = {
  input: UpdateRecurringFeeBlueprintInput;
};

export type MutationUpdateFeeBlueprintArgs = {
  input: UpdateFeeBlueprintInput;
};

export type MutationClearManagementEndArgs = {
  managementId: Scalars["ID"];
};

export type MutationUpdateManagementAgreementArgs = {
  input: UpdateManagementAgreementInput;
};

export type MutationUpdateManagementFeeArgs = {
  input: UpdateManagementFeeInput;
};

export type MutationCreateFeeArgs = {
  input: CreateFeeInput;
};

export type MutationUpdateFeeArgs = {
  input: UpdateFeeInput;
};

export type MutationCreateRecurringFeeArgs = {
  input: RecurringFeeInput;
};

export type MutationCreateRecurringFeesArgs = {
  input: Array<RecurringFeeInput>;
};

export type MutationCancelRecurringFeeArgs = {
  input: CancelRecurringFeeInput;
};

export type MutationUpdateRecurringFeeArgs = {
  input: UpdateRecurringFeeInput;
};

export type MutationSetupManagementAndTenancyArgs = {
  managementAndTenancyDetails: ManagementAndTenancyInput;
  autoPublish?: Maybe<Scalars["Boolean"]>;
  doNotNotify?: Maybe<Scalars["Boolean"]>;
};

export type MutationCreatePropertyArgs = {
  propertyDetails: PropertyInput;
};

export type MutationUpdatePropertyArgs = {
  updatePropertyInput: UpdatePropertyInput;
};

export type MutationCreateRentCreditArgs = {
  input: RentCreditDetailsInput;
};

export type MutationCreateTenancyAgreementArgs = {
  tenancyId: Scalars["ID"];
  agreementDetails: TenancyAgreementDetails;
};

export type MutationUpdateTenancyAgreementArgs = {
  input: UpdateTenancyAgreementInput;
};

export type MutationReviewRentArgs = {
  tenancyId: Scalars["ID"];
  rentDetails: RentReviewDetails;
};

export type MutationCreateRentReviewArgs = {
  tenancyId: Scalars["ID"];
  rentDetails: RentReviewDetails;
};

export type MutationEditRentReviewArgs = {
  input: EditRentReviewInput;
};

export type MutationAttachFilesToTenancyArgs = {
  tenancyId: Scalars["ID"];
  fileIds: Array<Scalars["AiloRN"]>;
};

export type MutationAttachFilesToManagementArgs = {
  managementId: Scalars["ID"];
  fileIds: Array<Scalars["AiloRN"]>;
  fileOwnerAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type MutationDeleteTenancyFileArgs = {
  input: DeleteTenancyFileInput;
};

export type MutationDeleteManagementFileArgs = {
  input: DeleteManagementFileInput;
};

export type MutationFindOrCreateManagementFeeBlueprintArgs = {
  input: FindOrCreateManagementFeeBlueprintInput;
};

export type MutationCreateManagementFeeBlueprintArgs = {
  input: CreateManagementFeeBlueprintInput;
};

export type MutationUpdateManagementFeeBlueprintArgs = {
  input: UpdateManagementFeeBlueprintInput;
};

export type MutationCreateOrUpdateManagementNoteArgs = {
  input: CreateOrUpdateManagementNoteInput;
};

export type MutationEndManagementV2Args = {
  input: EndManagementV2Input;
};

export type MutationCreateTenancyBondArgs = {
  bondDetails: CreateBondInput;
};

export type MutationUpdateTenancyBondArgs = {
  bondDetails: UpdateBondInput;
};

export type MutationUpsertTenancyBondClaimsArgs = {
  bond: UpsertTenancyBondClaims;
};

export type MutationRemoveBondClaimArgs = {
  bondAilorn: Scalars["AiloRN"];
};

export type MutationUpdateTenancyBondStatusArgs = {
  bondAilorn: Scalars["AiloRN"];
  status: TenancyBondStatus;
};

export type MutationTransferTenancyDepositArgs = {
  tenancyDepositId: Scalars["AiloRN"];
  transferAmount?: Maybe<MoneyInput>;
};

export type MutationCancelTenancyDepositArgs = {
  tenancyDepositId: Scalars["AiloRN"];
};

export type MutationMoveManagementsToTeamArgs = {
  managementIds: Array<Scalars["AiloRN"]>;
  teamId: Scalars["AiloRN"];
};

export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};

export type MutationCloseProjectArgs = {
  id: Scalars["ID"];
};

export type MutationReopenProjectArgs = {
  id: Scalars["ID"];
};

export type MutationArchiveProjectArgs = {
  id: Scalars["ID"];
};

export type MutationArchiveProjectsArgs = {
  input: ArchiveProjectsInput;
};

export type MutationUnarchiveProjectArgs = {
  id: Scalars["ID"];
};

export type MutationCreateActionArgs = {
  input: CreateActionInput;
};

export type MutationUpdateActionArgs = {
  input: UpdateActionInput;
};

export type MutationReassignActionArgs = {
  id: Scalars["ID"];
  assigneeAilorn: Scalars["AiloRN"];
};

export type MutationUpdateActionDueDateArgs = {
  id: Scalars["ID"];
  dueDate: Scalars["DateTime"];
};

export type MutationCompleteActionArgs = {
  id: Scalars["ID"];
};

export type MutationUncompleteActionArgs = {
  id: Scalars["ID"];
};

export type MutationAddProjectFilesArgs = {
  projectId: Scalars["ID"];
  fileAilorns: Array<Scalars["AiloRN"]>;
};

export type MutationRemoveProjectFilesArgs = {
  projectFileIds: Array<Scalars["ID"]>;
};

export type MutationCompleteAssignTenancyAgreementActionArgs = {
  id: Scalars["ID"];
  input: CompleteAssignTenancyAgreementActionInput;
};

export type MutationCompleteAssignRentActionArgs = {
  id: Scalars["ID"];
  input: CompleteAssignRentActionInput;
};

export type MutationCompleteAssignTenancyActionArgs = {
  input: CompleteAssignTenancyActionInput;
};

export type MutationCompleteAssignNewManagementActionArgs = {
  input: CompleteAssignNewManagementActionInput;
};

export type MutationCompleteAssignFormActionArgs = {
  input: CompleteAssignFormActionInput;
};

export type MutationLockTrustAccountArgs = {
  input: LockTrustAccountInput;
};

export type MutationPayFromWalletArgs = {
  payFromWalletInput: PayFromWalletInput;
};

export type MutationReceiptFundsArgs = {
  receiptFundsInput: ReceiptFundsInput;
};

export type MutationWalletTransferArgs = {
  walletTransferInput: WalletTransferInput;
};

export type MutationCreateRecurringOwingArgs = {
  owing: RecurringOwingInput;
};

export type MutationUpdateRecurringOwingArgs = {
  owing: RecurringOwingCalculationInput;
  owingId: Scalars["ID"];
  reason: Scalars["String"];
};

export type MutationStartMigrationsArgs = {
  organisationId: Scalars["AiloRN"];
};

export type MutationApproveMigratingManagementsArgs = {
  migratingManagementIds: Array<Scalars["ID"]>;
};

export type MutationUnapproveMigratingManagementsArgs = {
  migratingManagementIds: Array<Scalars["ID"]>;
};

export type MutationExcludeMigratingManagementArgs = {
  migratingManagementId: Scalars["ID"];
  exclusionReason: Scalars["String"];
  exclusionNotes?: Maybe<Scalars["String"]>;
};

export type MutationIncludeMigratingManagementsArgs = {
  migratingManagementIds: Array<Scalars["ID"]>;
};

export type MutationPublishMigratingManagementsArgs = {
  migratingManagementIds: Array<Scalars["ID"]>;
};

export type MutationUpdateMigratingManagementArgs = {
  input: UpdateMigratingManagementInput;
};

export type MutationRevokeMailboxAccessArgs = {
  input: RevokeMailboxAccessInput;
};

export type MutationSendMailArgs = {
  input: SendMailInput;
};

export type MutationGetMailboxAuthorizationUrlArgs = {
  input: GetMailboxAuthorizationUrlInput;
};

export type MutationAddBPayArgs = {
  details: BPayInput;
  owner: Scalars["AiloRN"];
};

export type MutationAddBankAccountArgs = {
  details: BankAccountInput;
  owner: Scalars["AiloRN"];
};

export type MutationAddCreditCardArgs = {
  details: CreditCardInput;
  owner: Scalars["AiloRN"];
};

export type MutationAdjustLiabilityEntryArgs = {
  adjustmentType: Scalars["String"];
  amount: MoneyInput;
  description: Scalars["String"];
  descriptionIsCustom?: Maybe<Scalars["Boolean"]>;
  effectiveAt: Scalars["DateTime"];
  idempotencyKey: Scalars["String"];
  liabilityId: Scalars["ID"];
};

export type MutationArchiveLiabilityArgs = {
  input: ArchiveLiabilityInput;
};

export type MutationCancelAutoPayArgs = {
  cancelAutoPayInput: CancelAutoPayInput;
};

export type MutationCancelAutoPayLiabilityStatusArgs = {
  cancelAutoPayLiabilityStatusInput: CancelAutoPayLiabilityStatusInput;
};

export type MutationCancelAutoWithdrawPlanArgs = {
  cancelAutoWithdrawInput: CancelAutoWithdrawPlanInput;
};

export type MutationCancelLiabilityPaymentPlanArgs = {
  cancelLiabilityPaymentPlanInput: CancelLiabilityPaymentPlanInput;
};

export type MutationCleanOrphanedBusinessTransactionArgs = {
  businessTransactionId: Scalars["ID"];
};

export type MutationCleanUnclearedBusinessTransactionArgs = {
  businessTransactionId: Scalars["ID"];
  description: Scalars["String"];
};

export type MutationCreateAdjustmentForPayerArgs = {
  businessTxId: Scalars["ID"];
  payerReference: Scalars["AiloRN"];
};

export type MutationCreateAutoWithdrawPlanV2Args = {
  createAutoWithdrawPlanInput: CreateAutoWithdrawPlanInputV2;
};

export type MutationCreateBankAccountBlacklistArgs = {
  blackListItem: BankAccountBlacklistInput;
};

export type MutationCreateLiabilityPaymentPlanArgs = {
  createLiabilityPaymentPlanInput: CreateLiabilityPaymentPlanInput;
};

export type MutationCreateOrUpdatePaymentMethodAiloFeeWaiverArgs = {
  feeWaiverInput: PaymentMethodAiloFeeWaiverInput;
};

export type MutationCreateOverdraftAllowanceArgs = {
  input: OverdraftAllowanceInput;
};

export type MutationCreatePaymentMethodArgs = {
  input?: Maybe<CreatePaymentMethodInput>;
};

export type MutationCreateUpdateAutoPayArgs = {
  createUpdateAutoPayInput: CreateUpdateAutoPayInput;
};

export type MutationCreditFromAiloBankArgs = {
  walletCreditInput: AdjustWalletBalanceInput;
};

export type MutationDeleteBankAccountBlacklistArgs = {
  id: Scalars["ID"];
};

export type MutationDeletePaymentMethodArgs = {
  disableAutoPlans?: Maybe<Scalars["Boolean"]>;
  paymentMethodId: Scalars["ID"];
};

export type MutationEmitLiabilityStateChangedEventArgs = {
  liabilityId: Scalars["ID"];
};

export type MutationGenerateLiabilityEntriesArgs = {
  input: GenerateLiabilityEntriesInput;
};

export type MutationGetOrCreateWalletOwnerAndBankAccountArgs = {
  input: GetOrCreateWalletOwnerAndBankAccountInput;
};

export type MutationPartialRefundLiabilityPaymentArgs = {
  amount: MoneyInput;
  businessTxId: Scalars["AiloRN"];
  cascade?: Maybe<Scalars["Boolean"]>;
  description: Scalars["String"];
  destinationWalletId?: Maybe<Scalars["String"]>;
};

export type MutationPayLiabilityArgs = {
  amount: MoneyInput;
  idempotentKey: Scalars["GeneratedKey"];
  liabilityId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
};

export type MutationPayLiabilityFromWalletArgs = {
  amount: MoneyInput;
  idempotentKey: Scalars["GeneratedKey"];
  liability: Scalars["AiloRN"];
  wallet: Scalars["AiloRN"];
};

export type MutationRefundLiabilityPaymentArgs = {
  businessTxId: Scalars["AiloRN"];
  cascade?: Maybe<Scalars["Boolean"]>;
  description: Scalars["String"];
};

export type MutationReverseAdjustmentArgs = {
  input: ReverseAdjustmentInput;
};

export type MutationReverseBusinessTxArgs = {
  businessTxId: Scalars["ID"];
};

export type MutationTransferToWalletArgs = {
  transferToWalletInput: TransferToWalletInput;
};

export type MutationUpdateAutoWithdrawPlanV2Args = {
  updateAutoWithdrawInput: UpdateAutoWithdrawPlanInputV2;
};

export type MutationUpdateLiabilityPaymentPlanArgs = {
  updateLiabilityPaymentPlanInput: UpdateLiabilityPaymentPlanInput;
};

export type MutationUpsertAutoPayLiabilityStatusArgs = {
  upsertAutoPayLiabilityStatusInput: UpsertAutoPayLiabilityStatusInput;
};

export type MutationWithdrawFromWalletArgs = {
  withdrawInput: WithdrawInput;
};

export type MutationWriteOffArgs = {
  walletCreditInput: AdjustWalletBalanceInput;
};

export type MutationCreateOneKeyArgs = {
  input: CreateOneKeyInput;
};

export type MutationUpdateOneKeyArgs = {
  input: UpdateOneKeyInput;
};

export type MutationDeleteOneKeyArgs = {
  input: DeleteOneKeyInput;
};

export type MutationCreateInspectionArgs = {
  createInspectionInput: CreateInspectionInput;
};

export type MutationUpdateInspectionArgs = {
  updateInspectionInput: UpdateInspectionInput;
};

export type MutationRemoveInspectionArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionAreaArgs = {
  createInspectionAreaInput: CreateInspectionAreaInput;
};

export type MutationUpdateInspectionAreaArgs = {
  updateInspectionAreaInput: UpdateInspectionAreaInput;
};

export type MutationRemoveInspectionAreaArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionAreaFileArgs = {
  createInspectionAreaFileInput: CreateInspectionAreaFileInput;
};

export type MutationRemoveInspectionAreaFileArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionFeatureArgs = {
  createInspectionFeatureInput: CreateInspectionFeatureInput;
};

export type MutationUpdateInspectionFeatureArgs = {
  updateInspectionFeatureInput: UpdateInspectionFeatureInput;
};

export type MutationRemoveInspectionFeatureArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionFeatureFileArgs = {
  createInspectionFeatureFileInput: CreateInspectionFeatureFileInput;
};

export type MutationRemoveInspectionFeatureFileArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionAppointmentsArgs = {
  input: Array<CreateInspectionAppointmentInput>;
  agencyAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type MutationUpdateInspectionAppointmentArgs = {
  input: UpdateInspectionAppointmentInput;
};

export type MutationCancelInspectionAppointmentArgs = {
  id: Scalars["ID"];
};

export type MutationSetRoutineInspectionDueDateArgs = {
  input: SetRoutineInspectionDueDateInput;
};

export type MutationGenerateInspectionReportArgs = {
  generateInspectionReportInput: GenerateInspectionReportInput;
};

export type MutationCreateBananaArgs = {
  input: CreateBananaInput;
};

export type MutationCreateReiTokenArgs = {
  createReiTokenInput: CreateReiTokenInput;
};

export type MutationUpdateReiTokenArgs = {
  updateReiTokenInput: UpdateReiTokenInput;
};

export type MutationRemoveReiTokenArgs = {
  removeReiTokenInput: RemoveReiTokenInput;
};

export type MutationCreateReiFormFromUserTemplateArgs = {
  createReiFormFromUserTemplateInput: CreateReiFormFromUserTemplateInput;
};

export type MutationCreateAiloFormArgs = {
  createAiloFormInput: CreateAiloFormInput;
};

export type MutationUpdateAiloFormArgs = {
  updateAiloFormInput: UpdateAiloFormInput;
};

export type MutationStartFileUploadArgs = {
  fileName: Scalars["String"];
  fileKind?: Maybe<FileKind>;
  ownerId?: Maybe<Scalars["AiloRN"]>;
};

export type MutationValidateFileArgs = {
  fileId: Scalars["AiloRN"];
  fileKind?: Maybe<FileKind>;
};

export type MutationCreateOtherContactArgs = {
  input: CreateOtherContactInput;
};

export type MutationFindOrCreateOtherContactsArgs = {
  input: FindOrCreateOtherContactsInput;
};

export type MutationSyncLegalEntityArgs = {
  input: SyncLegalEntityInput;
};

export type MutationCreateChatV2Args = {
  input: CreateChatInputV2;
};

export type MutationCreateThreadV2Args = {
  input: CreateThreadInputV2;
};

export type MutationSendMessageV2Args = {
  input: SendMessageInputV2;
};

export type MutationAcknowledgeThreadArgs = {
  input: AcknowledgeThreadInput;
};

export type MutationUnacknowledgeThreadFromMessageArgs = {
  input: UnacknowledgeThreadFromMessageInput;
};

export type MutationCreateBillArgs = {
  billDetails: BillInput;
};

export type MutationArchiveBillArgs = {
  billId: Scalars["ID"];
  reason?: Maybe<Scalars["String"]>;
};

export type MutationAddBillAttachmentsArgs = {
  billId: Scalars["AiloRN"];
  attachmentsFileIds: Array<Scalars["AiloRN"]>;
  payersLegalEntitiesIds?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type MutationCreatePaymentReferenceArgs = {
  paymentReferenceDetails: PaymentReferenceInput;
};

export type MutationFindOrCreatePaymentReferenceArgs = {
  paymentReferenceDetails: PaymentReferenceInput;
};

export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};

export type MutationFindOrCreateInternalSupplierArgs = {
  supplierDetails: InternalSupplierInput;
};

export type MutationArchiveSupplierArgs = {
  supplierId: Scalars["ID"];
};

export type MutationUpdateSupplierArgs = {
  input: UpdateSupplierInput;
};

export type MutationCreateBillLiabilityStateArgs = {
  billLiabilityStateDetails: BillLiabilityStateInput;
};

export type MutationUpsertCrnArgs = {
  input: UpsertCrnInput;
};

export type MutationDeleteCrnArgs = {
  input: DeleteCrnInput;
};

export type MutationSendEmailVerificationCodeArgs = {
  emailAddress: Scalars["String"];
};

export type MutationVerifyEmailArgs = {
  emailAddress: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type MutationUpdatePersonProfileDetailsArgs = {
  input: UpdatePersonProfileDetailsInput;
};

export type MutationUpdateCompanyProfileDetailsArgs = {
  input: UpdateCompanyProfileDetailsInput;
};

export type MutationAddMemberToOrganisationArgs = {
  input: AddMemberToOrganisationInput;
};

export type MutationRemoveMemberFromOrganisationArgs = {
  input: RemoveMemberFromOrganisationInput;
};

export type MutationAddMemberToLegalEntityArgs = {
  input: AddMemberToLegalEntityInput;
};

export type MutationRemoveMemberFromLegalEntityArgs = {
  input: RemoveMemberFromLegalEntityInput;
};

export type MutationCompleteOnboardingTaskArgs = {
  onboardingTaskId: OnboardingTaskId;
};

export type MutationSendPhoneVerificationCodeArgs = {
  phoneNumber: Scalars["String"];
};

export type MutationVerifyPhoneVerificationCodeArgs = {
  phoneNumber: Scalars["String"];
  code: Scalars["String"];
};

export enum AbaFileFormat {
  Balanced = "balanced",
  Unbalanced = "unbalanced"
}

export type AbrCompanyLookupResult = {
  __typename?: "AbrCompanyLookupResult";
  registeredEntityName: Scalars["String"];
  addressPostcode: Scalars["String"];
  addressState: Scalars["String"];
  abn: Scalars["String"];
  acn?: Maybe<Scalars["String"]>;
};

export type Account = {
  __typename?: "Account";
  accountOwner: AccountOwner;
  balance: Money;
  bill?: Maybe<Bill>;
  book: Book;
  id: Scalars["ID"];
  reference: Scalars["AiloRN"];
  subtype: AccountSubtype;
};

export type AccountOwner = {
  __typename?: "AccountOwner";
  availableBalance: Money;
  managingEntity?: Maybe<LegalEntityCompanion>;
  reference: Scalars["AiloRN"];
  totalBalance: Money;
};

export type AccountOwnerAvailableBalanceArgs = {
  book?: Book;
};

export type AccountOwnerTotalBalanceArgs = {
  book?: Book;
};

export enum AccountSortField {
  Book = "BOOK",
  Reference = "REFERENCE",
  Subtype = "SUBTYPE",
  Type = "TYPE"
}

export enum AccountSubtype {
  CashCleared = "CASH_CLEARED",
  CashClearing = "CASH_CLEARING",
  Contribution = "CONTRIBUTION",
  Disbursement = "DISBURSEMENT",
  Expense = "EXPENSE",
  Income = "INCOME",
  Payable = "PAYABLE",
  PayableCleared = "PAYABLE_CLEARED",
  PayableClearing = "PAYABLE_CLEARING",
  Receivable = "RECEIVABLE"
}

export type AccountTag = {
  __typename?: "AccountTag";
  accountOwnerReference: Scalars["AiloRN"];
  accountSubtype: AccountSubtype;
  book: Book;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  value: Scalars["String"];
};

export type AccountTagBalance = {
  __typename?: "AccountTagBalance";
  accountTag: AccountTag;
  balance?: Maybe<Money>;
  balanceCreatedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  isLatestEntry: Scalars["Boolean"];
  snowflake: Scalars["Long"];
};

export type AccountTagBalanceIsLatestEntryArgs = {
  asOf?: Maybe<Scalars["DateTime"]>;
};

export enum AccountType {
  Asset = "ASSET",
  Equity = "EQUITY",
  Liability = "LIABILITY"
}

export type AcknowledgeThreadInput = {
  threadAilorn: Scalars["AiloRN"];
  lastAcknowledgedAt: Scalars["DateTime"];
  lastAcknowledgedMessageAilorn: Scalars["AiloRN"];
};

export type Action = {
  __typename?: "Action";
  id: Scalars["ID"];
  name: Scalars["String"];
  description: Scalars["String"];
  type: ActionType;
  typeLabel: Scalars["String"];
  dueDate: Scalars["DateTime"];
  project: Project;
  assignee?: Maybe<Person>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<User>;
  modifiedBy?: Maybe<User>;
  doneAt?: Maybe<Scalars["DateTime"]>;
  status: ActionStatus;
  meta?: Maybe<ActionMeta>;
};

export type ActionCursor = {
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  cursor?: Maybe<Scalars["String"]>;
};

export type ActionInitiator = User | System;

export type ActionMeta =
  | AssignTenancyAgreementActionMeta
  | AssignRentActionMeta
  | AssignTenancyActionMeta
  | AssignNewManagementActionMeta
  | AssignFormActionMeta
  | RequestSignatureActionMeta
  | ManageDepositActionMeta
  | TrackDocusignActionMeta
  | TrackInspectionCompletedActionMeta
  | GenerateInspectionReportActionMeta
  | EndTenancyActionMeta
  | ConfirmInspectionScheduleActionMeta
  | AssignMessageActionMeta;

export enum ActionStatus {
  Active = "Active",
  Done = "Done"
}

export enum ActionType {
  Reminder = "Reminder",
  AssignTenancyAgreement = "AssignTenancyAgreement",
  AssignTenancy = "AssignTenancy",
  AssignNewManagement = "AssignNewManagement",
  AssignRent = "AssignRent",
  AssignForm = "AssignForm",
  RequestSignature = "RequestSignature",
  ManageDeposit = "ManageDeposit",
  TrackDocusign = "TrackDocusign",
  TrackInspectionCompleted = "TrackInspectionCompleted",
  GenerateInspectionReport = "GenerateInspectionReport",
  EndTenancy = "EndTenancy",
  ConfirmInspectionSchedule = "ConfirmInspectionSchedule",
  AssignMessage = "AssignMessage"
}

/**
 * type ActiveManagementCursor will eventually be deprecated,
 * consider to enhance/use ManagementCursor instead
 */
export type ActiveManagementCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
  sort?: Maybe<ManagementSortParams>;
};

export type AddCentrepayDirectiveInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  tenancy: Scalars["AiloRN"];
};

export type AddCentrepayDirectiveOutput = {
  __typename?: "AddCentrepayDirectiveOutput";
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  tenancy: Scalars["AiloRN"];
};

export type AddMemberToLegalEntityInput = {
  memberPersonId: Scalars["String"];
  legalEntityId: Scalars["String"];
  roleId: Scalars["String"];
};

export type AddMemberToOrganisationInput = {
  personId: Scalars["String"];
  organisationId: Scalars["String"];
  roleId: Scalars["String"];
};

export type Address = {
  __typename?: "Address";
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  postcode?: Maybe<Scalars["String"]>;
};

export type AddTrustAccountInput = {
  agency: Scalars["AiloRN"];
  accountName: Scalars["String"];
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
};

export type AdjustmentLiabilityEntry = LiabilityEntry & {
  __typename?: "AdjustmentLiabilityEntry";
  amount: Money;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<ActionInitiator>;
  description?: Maybe<Scalars["String"]>;
  descriptionIsCustom?: Maybe<Scalars["Boolean"]>;
  /** The date of the entry to be shown to consumers. This is the latest of createdAt and effectiveAt. */
  displayDate: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  entryType: SourceType;
  id: Scalars["ID"];
  liability: Liability;
  /** @deprecated Use `liability` */
  liabilityId: Scalars["ID"];
  reversedByAdjustment?: Maybe<AdjustmentLiabilityEntry>;
  reversesAdjustment?: Maybe<AdjustmentLiabilityEntry>;
  sourceId?: Maybe<Scalars["ID"]>;
  sourceType?: Maybe<SourceType>;
  /** @deprecated Irrelevant to adjustment liability entry */
  status?: Maybe<BusinessTxStatusEnum>;
};

export type AdjustWalletBalanceInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  userFacingDescription?: Maybe<Scalars["String"]>;
  walletId: Scalars["ID"];
};

export type AgencyFinancialPerformanceReport = {
  __typename?: "AgencyFinancialPerformanceReport";
  totalRevenue?: Maybe<CardMetric>;
  managementFeeRevenue?: Maybe<CardMetric>;
  overFeeRevenue?: Maybe<CardMetric>;
  propertiesUnderManagement?: Maybe<CardMetric>;
  averageManagementFeeAmount?: Maybe<CardMetric>;
  averageManagementFeePercent?: Maybe<CardMetric>;
};

export type AgencyIncomeByChartOfAccountData = {
  __typename?: "AgencyIncomeByChartOfAccountData";
  chartOfAccountName: Scalars["String"];
  incomeAmount: Scalars["String"];
};

export type AgencyIncomeByChartOfAccountHeaderFields = {
  __typename?: "AgencyIncomeByChartOfAccountHeaderFields";
  totalIncomeAmount: Scalars["String"];
};

export type AgencyIncomeByChartOfAccountReport = {
  __typename?: "AgencyIncomeByChartOfAccountReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<AgencyIncomeByChartOfAccountData>>>;
  headerFields: AgencyIncomeByChartOfAccountHeaderFields;
};

export type AgencyIncomeByMonthReport = {
  __typename?: "AgencyIncomeByMonthReport";
  labels?: Maybe<Array<Maybe<ReportSeriesTimeLabel>>>;
  series?: Maybe<Array<Maybe<ReportTimeSeries>>>;
};

export type AgencyIncomeByPropertyData = {
  __typename?: "AgencyIncomeByPropertyData";
  teamId?: Maybe<Scalars["String"]>;
  teamName?: Maybe<Scalars["String"]>;
  propertyId?: Maybe<Scalars["String"]>;
  managementId?: Maybe<Scalars["String"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  rentCollectedAmount?: Maybe<Scalars["String"]>;
  managementFeeAmount?: Maybe<Scalars["String"]>;
  managementFeePercent?: Maybe<Scalars["String"]>;
  otherFeeAmount?: Maybe<Scalars["String"]>;
  totalFeeAmount?: Maybe<Scalars["String"]>;
};

export type AgencyIncomeByPropertyHeaderFields = {
  __typename?: "AgencyIncomeByPropertyHeaderFields";
  total?: Maybe<AgencyIncomeByPropertyTotals>;
  average?: Maybe<AgencyIncomeByPropertyTotals>;
};

export type AgencyIncomeByPropertyReport = {
  __typename?: "AgencyIncomeByPropertyReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<AgencyIncomeByPropertyData>>>;
  headerFields: AgencyIncomeByPropertyHeaderFields;
};

export type AgencyIncomeByPropertyTotals = {
  __typename?: "AgencyIncomeByPropertyTotals";
  rentCollectedAmount?: Maybe<Scalars["String"]>;
  managementFeeAmount?: Maybe<Scalars["String"]>;
  managementFeePercent?: Maybe<Scalars["String"]>;
  otherFeeAmount?: Maybe<Scalars["String"]>;
  totalFeeAmount?: Maybe<Scalars["String"]>;
};

export type AgencyIncomeData = {
  __typename?: "AgencyIncomeData";
  incomeAmount?: Maybe<Scalars["Float"]>;
  rentAmount?: Maybe<Scalars["Float"]>;
  minDate?: Maybe<Scalars["String"]>;
  propertyId?: Maybe<Scalars["String"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  managementId?: Maybe<Scalars["String"]>;
  feeCode?: Maybe<Scalars["String"]>;
  feeName?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamName?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
  month?: Maybe<Scalars["String"]>;
  qtr?: Maybe<Scalars["String"]>;
  financialYearAU?: Maybe<Scalars["String"]>;
  financialQtrAU?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
};

export enum AgencyIncomeDimension {
  Fee = "Fee",
  Property = "Property",
  Team = "Team",
  Year = "Year",
  FinancialYearAu = "FinancialYearAU",
  FinancialQtrAu = "FinancialQtrAU",
  Month = "Month",
  Qtr = "Qtr"
}

export type AgencyIncomeDisbursementPeriodData = {
  __typename?: "AgencyIncomeDisbursementPeriodData";
  disbursementPeriodSk: Scalars["String"];
  disbursementDisplayName: Scalars["String"];
  openingBalance: Scalars["String"];
  closingBalance: Scalars["String"];
};

export type AgencyIncomeDisbursementPeriodReport = {
  __typename?: "AgencyIncomeDisbursementPeriodReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<AgencyIncomeDisbursementPeriodData>>>;
};

export type AgencyIncomeReport = {
  __typename?: "AgencyIncomeReport";
  type: Scalars["String"];
  rows?: Maybe<Array<Maybe<AgencyIncomeData>>>;
  lastUpdated?: Maybe<Scalars["String"]>;
};

export type AgencyPropertiesQueryInput = {
  paginationParams: PaginationParams;
};

export type AgencyProperty = {
  __typename?: "AgencyProperty";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  property: Property;
  agencyOrganisation: Organisation;
  /**
   * returns the first to exist of active, next or previous management
   * in that order.
   */
  mostRelevantManagement?: Maybe<Management>;
  managements: Array<Management>;
  address: Address;
};

export type AiloForm = Form & {
  __typename?: "AiloForm";
  id: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  organisation: Organisation;
  name: Scalars["String"];
  template: AiloFormTemplate;
  /** Number of answers associated with this page */
  respondedFields: Scalars["Int"];
  /** Number of fields associated with this page (Excludes the 'EMPTY' field type) */
  totalFields: Scalars["Int"];
  pages: Array<AiloFormPage>;
  ailorn: Scalars["AiloRN"];
};

export type AiloFormField = {
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
};

export type AiloFormFieldDate = AiloFormField & {
  __typename?: "AiloFormFieldDate";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
  placeholder?: Maybe<Scalars["String"]>;
  dateResponse?: Maybe<AiloFormResponseDate>;
};

export type AiloFormFieldEmpty = AiloFormField & {
  __typename?: "AiloFormFieldEmpty";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
};

export type AiloFormFieldInfo = {
  __typename?: "AiloFormFieldInfo";
  description: Scalars["Markdown"];
  icon?: Maybe<Scalars["Url"]>;
};

export type AiloFormFieldNumber = AiloFormField & {
  __typename?: "AiloFormFieldNumber";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
  unit?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  numberResponse?: Maybe<AiloFormResponseNumber>;
};

export type AiloFormFieldText = AiloFormField & {
  __typename?: "AiloFormFieldText";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
  placeholder?: Maybe<Scalars["String"]>;
  textResponse?: Maybe<AiloFormResponseText>;
};

export type AiloFormFieldYesNo = AiloFormField & {
  __typename?: "AiloFormFieldYesNo";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
  includeNA: Scalars["Boolean"];
  yesNoResponse?: Maybe<AiloFormResponseYesNo>;
};

export type AiloFormPage = {
  __typename?: "AiloFormPage";
  id: Scalars["ID"];
  index: Scalars["Int"];
  icon?: Maybe<Scalars["Url"]>;
  name: Scalars["String"];
  parentTemplate: AiloFormTemplate;
  parentForm: AiloForm;
  /** List of all fields flattened including children fields */
  allFields: Array<AiloFormField>;
  /** List of all top level fields, query children to get next depth of fields */
  topLevelFields: Array<AiloFormField>;
};

export type AiloFormResponse = {
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
};

export type AiloFormResponseDate = AiloFormResponse & {
  __typename?: "AiloFormResponseDate";
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
  date: Scalars["DateTime"];
};

export type AiloFormResponseNumber = AiloFormResponse & {
  __typename?: "AiloFormResponseNumber";
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
  number: Scalars["Float"];
};

export type AiloFormResponsePayloadInput = {
  DATE?: Maybe<Scalars["DateTime"]>;
  NUMBER?: Maybe<Scalars["Float"]>;
  TEXT?: Maybe<Scalars["String"]>;
  YES_NO?: Maybe<Scalars["String"]>;
};

export type AiloFormResponseText = AiloFormResponse & {
  __typename?: "AiloFormResponseText";
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
  text: Scalars["String"];
};

export type AiloFormResponseYesNo = AiloFormResponse & {
  __typename?: "AiloFormResponseYesNo";
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
  yesNoNa: AiloFormYesNoResponse;
};

export type AiloFormTemplate = {
  __typename?: "AiloFormTemplate";
  id: Scalars["ID"];
  type: AiloFormType;
  supportedState: AustralianState;
  version: Scalars["Int"];
  name: Scalars["String"];
  pages: Array<AiloFormTemplatePage>;
};

export type AiloFormTemplateField = {
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
};

export type AiloFormTemplateFieldDate = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldDate";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
  placeholder?: Maybe<Scalars["String"]>;
};

export type AiloFormTemplateFieldEmpty = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldEmpty";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
};

export type AiloFormTemplateFieldNumber = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldNumber";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
  unit?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
};

export type AiloFormTemplateFieldText = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldText";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
  placeholder?: Maybe<Scalars["String"]>;
};

export type AiloFormTemplateFieldYesNo = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldYesNo";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
  includeNA: Scalars["Boolean"];
};

export type AiloFormTemplatePage = {
  __typename?: "AiloFormTemplatePage";
  id: Scalars["ID"];
  index: Scalars["Int"];
  icon?: Maybe<Scalars["Url"]>;
  name: Scalars["String"];
  parentTemplate: AiloFormTemplate;
  /** List of all fields flattened including children fields */
  allFields: Array<AiloFormTemplateField>;
  /** List of all top level fields, query children to get next depth of fields */
  topLevelFields: Array<AiloFormTemplateField>;
};

export enum AiloFormType {
  IngoingInspection = "INGOING_INSPECTION"
}

export enum AiloFormYesNoResponse {
  Yes = "YES",
  No = "NO",
  Na = "NA"
}

export type AiloRnFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["AiloRN"]>;
  neq?: Maybe<Scalars["AiloRN"]>;
  gt?: Maybe<Scalars["AiloRN"]>;
  gte?: Maybe<Scalars["AiloRN"]>;
  lt?: Maybe<Scalars["AiloRN"]>;
  lte?: Maybe<Scalars["AiloRN"]>;
  like?: Maybe<Scalars["AiloRN"]>;
  notLike?: Maybe<Scalars["AiloRN"]>;
  iLike?: Maybe<Scalars["AiloRN"]>;
  notILike?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export enum AllowedOperations {
  None = "None",
  Create = "Create",
  Edit = "Edit"
}

export type AllTenanciesQueryFilterInput = {
  includeOffboarded?: Maybe<Scalars["Boolean"]>;
  includeVoided?: Maybe<Scalars["Boolean"]>;
  includeDrafts?: Maybe<Scalars["Boolean"]>;
};

export type AllTenanciesQueryInput = {
  paginationParams: PaginationParams;
  filters?: Maybe<AllTenanciesQueryFilterInput>;
};

export type AmountDueToDateInput = {
  dateTime?: Maybe<Scalars["DateTime"]>;
  liabilityId: Scalars["ID"];
};

export type ArchiveLiabilityInput = {
  archivedAt?: Maybe<Scalars["DateTime"]>;
  liabilityId: Scalars["ID"];
};

export type ArchiveProjectsInput = {
  ids: Array<Scalars["ID"]>;
};

export type ArchiveProjectsPayload = {
  __typename?: "ArchiveProjectsPayload";
  projects: Array<Project>;
};

export type Arrears = {
  __typename?: "Arrears";
  /** @deprecated Use `amountInArrears` instead */
  amount: TaxableAmount;
  /** Amount in arrears, value can be negative when overpaid  */
  amountInArrears: TaxableAmount;
  calculatedFromDate?: Maybe<Scalars["Date"]>;
  calculatedToDate?: Maybe<Scalars["Date"]>;
  /** @deprecated Use `calculatedFromDate` instead */
  date: Scalars["Date"];
  /** Number of days in arrears, value can be negative when overpaid  */
  daysInArrears: Scalars["Int"];
};

export type AssigneeAilornsComparison = {
  in?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type AssignFormActionMeta = {
  __typename?: "AssignFormActionMeta";
  form?: Maybe<Form>;
};

export type AssignMessageActionMeta = {
  __typename?: "AssignMessageActionMeta";
  messageTemplate?: Maybe<Scalars["String"]>;
  subjectTemplate?: Maybe<Scalars["String"]>;
};

export type AssignNewManagementActionMeta = {
  __typename?: "AssignNewManagementActionMeta";
  management?: Maybe<Management>;
};

export type AssignRentActionMeta = {
  __typename?: "AssignRentActionMeta";
  rent?: Maybe<Rent>;
};

export type AssignTenancyActionMeta = {
  __typename?: "AssignTenancyActionMeta";
  tenancy?: Maybe<Tenancy>;
};

export type AssignTenancyAgreementActionMeta = {
  __typename?: "AssignTenancyAgreementActionMeta";
  tenancyAgreement?: Maybe<TenancyAgreement>;
};

export enum AustralianState {
  Nsw = "NSW",
  Wa = "WA",
  Sa = "SA",
  Nt = "NT",
  Tas = "TAS",
  Act = "ACT",
  Qld = "QLD",
  Vic = "VIC"
}

export type AutoPayLiabilityStatus = {
  __typename?: "AutoPayLiabilityStatus";
  enabled: Scalars["Boolean"];
  maximumPaymentAmount?: Maybe<Money>;
  /**
   * If null, it means it'll be paid using Ailo Wallet.
   * TODO: Return `WalletPaymentMethod` instead.
   */
  payerId: Scalars["AiloRN"];
  paymentMethod?: Maybe<PaymentMethod>;
  /** @deprecated Use `AutoPayLiabilityStatus.paymentMethod.id` instead */
  paymentMethodId?: Maybe<Scalars["ID"]>;
};

export type AutoPaymentDetails = {
  __typename?: "AutoPaymentDetails";
  paymentMethod?: Maybe<PaymentMethod>;
};

export type AutoWithdrawPlan = {
  __typename?: "AutoWithdrawPlan";
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  details: AutoWithdrawPlanDetails;
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  owner: Scalars["AiloRN"];
  wallet: Wallet;
  walletId: Scalars["ID"];
};

export type AutoWithdrawPlanDetails = {
  __typename?: "AutoWithdrawPlanDetails";
  /** If `frequency` = Monthly, use `anniversaryDaysOfMonth` instead. */
  anniversary?: Maybe<Scalars["Int"]>;
  /**
   * Only used when `frequency` = Monthly. Allowed values: 1-28.
   * e.g. ['1', '15'] means a withdrawal occurs on the 1st and 15th of every month.
   */
  anniversaryDaysOfMonth?: Maybe<Array<Scalars["Int"]>>;
  autoWithdrawPlanId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  createdByV2?: Maybe<ActionInitiator>;
  endDate?: Maybe<Scalars["Date"]>;
  frequency: QuartzPlanFrequency;
  id: Scalars["ID"];
  /**
   * Only used when `frequency` = Monthly.
   * If `true`, withdrawal will happen on the last day of the month.
   * e.g. 30th June, 31st July, 28th Feb or 29th Feb depending on leap year.
   */
  isLastDayOfTheMonth?: Maybe<Scalars["Boolean"]>;
  nextFireTime: Scalars["DateTime"];
  /** @deprecated Use `paymentMethodDestinations` instead */
  paymentMethod: PaymentMethod;
  paymentMethodDestinations: Array<PaymentMethodDestination>;
  /** @deprecated Use `paymentMethodDestinations` instead */
  paymentMethodId: Scalars["ID"];
  setAsideAmount?: Maybe<Money>;
  startDate: Scalars["Date"];
  timezoneId: Scalars["String"];
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type Banana = {
  __typename?: "Banana";
  id: Scalars["ID"];
  weight: Weight;
};

export type BankAccount = PaymentMethod & {
  __typename?: "BankAccount";
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  ailoRN: Scalars["AiloRN"];
  bsb?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  topUpFee?: Maybe<TransactionFee>;
  wallet: Wallet;
};

export type BankAccountBlacklist = {
  __typename?: "BankAccountBlacklist";
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  ownerLegalEntity: Scalars["AiloRN"];
};

export type BankAccountBlacklistInput = {
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
  description: Scalars["String"];
  ownerLegalEntity: Scalars["AiloRN"];
};

export type BankAccountInput = {
  accountName: Scalars["String"];
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  hidden?: Maybe<Scalars["Boolean"]>;
  onceOff?: Maybe<Scalars["Boolean"]>;
};

export type BankReconciliationReport = {
  __typename?: "BankReconciliationReport";
  items?: Maybe<Array<Maybe<ReconciliationReportItem>>>;
};

export type BelongToTeamsArgInput = {
  teamIds?: Array<Scalars["AiloRN"]>;
  includeUnassigned?: Scalars["Boolean"];
};

/** @deprecated Use `PaginationParams` instead when possible. */
export type BidirectionalPageCursor = {
  /** If true, will return items before the cursor, rather than after it. */
  before?: Maybe<Scalars["Boolean"]>;
  cursor?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type BidirectionalPageInfo = {
  __typename?: "BidirectionalPageInfo";
  total: Scalars["Int"];
  hasNext: Scalars["Boolean"];
  nextCursor?: Maybe<Scalars["String"]>;
  hasPrevious: Scalars["Boolean"];
  previousCursor?: Maybe<Scalars["String"]>;
};

export type BidirectionalPaginatedManagements = {
  __typename?: "BidirectionalPaginatedManagements";
  pageInfo: BidirectionalPageInfo;
  items: Array<Management>;
};

export type BidirectionalPaginatedTenancies = {
  __typename?: "BidirectionalPaginatedTenancies";
  pageInfo: BidirectionalPageInfo;
  items: Array<Tenancy>;
};

export type Bill = {
  __typename?: "Bill";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  /** Never null (nullable only because of federation reasons). */
  organisation?: Maybe<Organisation>;
  paymentReference?: Maybe<PaymentReference>;
  /** @deprecated Use `Bill.payer.ailoRN` instead. */
  toBePaidBy: Scalars["AiloRN"];
  /** Never null (nullable only because of federation reasons). */
  payer?: Maybe<BillPayer>;
  /**
   * Never null (nullable only because of federation reasons).
   * @deprecated Use `Bill.payee` instead.
   */
  supplier?: Maybe<Supplier>;
  /** Never null (nullable only because of federation reasons). */
  payee?: Maybe<BillPayee>;
  description?: Maybe<Scalars["String"]>;
  /** @deprecated Use `Bill.dueDateV2` instead */
  dueDate: Scalars["Date"];
  dueDateV2: Scalars["LocalDate"];
  /** @deprecated Use `Bill.issueDateV2` instead */
  issueDate: Scalars["Date"];
  issueDateV2: Scalars["LocalDate"];
  timeZone: Scalars["TimeZone"];
  liabilityState?: Maybe<BillLiabilityState>;
  invoiceNumber?: Maybe<Scalars["String"]>;
  status: BillStatus;
  agencyStatus: BillAgencyStatus;
  taxCategory: TaxCategory;
  /**
   * When bill was created, was the tax automatically calculated by the UI?
   * (If null, it means it was created before the automatic tax calculation was available.)
   */
  taxAutoCalculated?: Maybe<Scalars["Boolean"]>;
  amount: Money;
  lineItems?: Maybe<PaginatedLineItems>;
  relatingToManagement: Scalars["AiloRN"];
  relatedToTeam?: Maybe<Scalars["AiloRN"]>;
  createdAt: Scalars["DateTime"];
  /** @deprecated Use `Bill.createdByV2.ailoRN` instead. */
  createdBy: Scalars["AiloRN"];
  /** If null it means the bill has been created by Ailo system. */
  createdByUser?: Maybe<User>;
  modifiedBy: Scalars["AiloRN"];
  liability?: Maybe<Liability>;
  organisationArchiveReason?: Maybe<Scalars["String"]>;
  /**
   * It represents the agency legal entity.
   * Never nullable (nullable only because of federation error policy).
   */
  managingEntityAilorn?: Maybe<Scalars["AiloRN"]>;
  /** It is only used to populate bill liability payment decription when debiting investor bank account, not consumer facing */
  propertyAddress?: Maybe<Scalars["String"]>;
  applyManagementFee: Scalars["Boolean"];
  /** If true the funds will not be automatically withdrawn by the supplier */
  internal: Scalars["Boolean"];
  attachments: Array<BillAttachment>;
  /** @deprecated use `Bill.organisation.ailoRN` instead. */
  relatingToOrganisation: Scalars["AiloRN"];
  management?: Maybe<Management>;
  archivableByOrganisation: Scalars["Boolean"];
};

export enum BillAgencyStatus {
  Cancelled = "Cancelled",
  Refunded = "Refunded",
  RefundFailed = "RefundFailed",
  AwaitingPayment = "AwaitingPayment",
  Overdue = "Overdue",
  Due = "Due",
  PaymentSent = "PaymentSent",
  Paid = "Paid"
}

export type BillAttachment = {
  __typename?: "BillAttachment";
  id: Scalars["ID"];
  /**
   * This should never be null, but because it comes from external service,
   * in a case of unexpected server error, it will be returned as null.
   * In such case, client should treat it as internal server error,
   * show an error indicator and report it to Sentry.
   */
  file?: Maybe<File>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
};

export type BillDefaultPageCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
  sort?: Maybe<Scalars["String"]>;
};

export type BillInput = {
  paymentReferenceId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  /** Tenancy / Management / Agency Legal Entity ID */
  toBePaidBy: Scalars["AiloRN"];
  /** Tenancy Tenants / Management Owners / Agency Legal Entity IDs */
  toBePaidByLegalEntities?: Maybe<Array<Scalars["AiloRN"]>>;
  /** @deprecated Use `payeeAiloRN` instead. */
  supplierId?: Maybe<Scalars["ID"]>;
  payeeAiloRN?: Maybe<Scalars["AiloRN"]>;
  /** @deprecated Use `dueDateV2` instead. */
  dueDate?: Maybe<Scalars["Date"]>;
  dueDateV2?: Maybe<Scalars["LocalDate"]>;
  invoiceNumber?: Maybe<Scalars["String"]>;
  status: BillStatus;
  taxCategoryId: Scalars["String"];
  /** @deprecated Use `issueDateV2` instead. */
  issueDate?: Maybe<Scalars["Date"]>;
  issueDateV2?: Maybe<Scalars["LocalDate"]>;
  timeZone?: Maybe<Scalars["TimeZone"]>;
  taxAutoCalculated?: Maybe<Scalars["Boolean"]>;
  lineItems: Array<LineItemInput>;
  attachmentsFileIds?: Maybe<Array<Scalars["AiloRN"]>>;
  relatingToManagement: Scalars["AiloRN"];
  relatingToOrganisation: Scalars["AiloRN"];
  managingEntityAilorn?: Maybe<Scalars["AiloRN"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  applyManagementFee?: Maybe<Scalars["Boolean"]>;
  internal?: Maybe<Scalars["Boolean"]>;
};

export type BillIssuesDrilldownData = {
  __typename?: "BillIssuesDrilldownData";
  approvalStatus?: Maybe<Scalars["String"]>;
  billId?: Maybe<Scalars["String"]>;
  billUnlikelyToBePaidReason?: Maybe<Scalars["String"]>;
  invoiceNumber?: Maybe<Scalars["String"]>;
  payer?: Maybe<Scalars["String"]>;
  paymentStatus?: Maybe<Scalars["String"]>;
  supplier?: Maybe<Scalars["String"]>;
};

export type BillIssuesDrilldownReport = {
  __typename?: "BillIssuesDrilldownReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<BillIssuesDrilldownData>>>;
};

export type BillIssuesSummaryReport = {
  __typename?: "BillIssuesSummaryReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<BillIssueSummaryData>>>;
};

export type BillIssueSummaryData = {
  __typename?: "BillIssueSummaryData";
  billIssue: Scalars["String"];
  billCount: Scalars["Int"];
  billCountPercent: Scalars["String"];
  drilldownFields?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type BillLiabilityState = {
  __typename?: "BillLiabilityState";
  /** @deprecated Use `BillLiabilityState.payByDateV2` instead */
  payByDate?: Maybe<Scalars["Date"]>;
  payByDateV2?: Maybe<Scalars["LocalDate"]>;
  paymentStatus?: Maybe<Scalars["String"]>;
  /** @deprecated Use `BillLiabilityState.paidAt` instead */
  paymentDate?: Maybe<Scalars["Date"]>;
  paidAt?: Maybe<Scalars["DateTime"]>;
  dueAmount?: Maybe<Money>;
};

export type BillLiabilityStateInput = {
  billId: Scalars["AiloRN"];
  paymentStatus: BillPaymentStatus;
};

export type BillPageCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
  sort?: Maybe<BillSortParams>;
};

export type BillPayee = Supplier | Management | LegalEntityCompanion;

export type BillPayer = Tenancy | Management | LegalEntityCompanion;

export enum BillPayerType {
  Tenancy = "Tenancy",
  Management = "Management",
  LegalEntity = "LegalEntity"
}

export enum BillPaymentStatus {
  Due = "DUE",
  Paid = "PAID",
  Pending = "PENDING",
  Processing = "PROCESSING",
  Failed = "FAILED"
}

export type BillsData = {
  __typename?: "BillsData";
  billId: Scalars["String"];
  propertyAddress?: Maybe<Scalars["String"]>;
  billDate?: Maybe<Scalars["String"]>;
  billType?: Maybe<Scalars["String"]>;
  payerEntityType: Scalars["String"];
  supplierName?: Maybe<Scalars["String"]>;
  billInvoiceNumber?: Maybe<Scalars["String"]>;
  billAmount?: Maybe<Scalars["String"]>;
  billCreatedAt: Scalars["String"];
  billPaymentStatus?: Maybe<Scalars["String"]>;
  billStatus?: Maybe<Scalars["String"]>;
  billDueDays?: Maybe<Scalars["Int"]>;
};

export enum BillSortField {
  CreatedAt = "CreatedAt",
  DueDate = "DueDate",
  PayByDate = "PayByDate",
  PaymentDate = "PaymentDate"
}

export type BillSortParams = {
  field: BillSortField;
  direction: SortDirection;
};

export type BillsReport = {
  __typename?: "BillsReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<BillsData>>>;
};

export enum BillStatus {
  Void = "VOID",
  Approved = "APPROVED",
  Refunded = "REFUNDED"
}

export type BondAuthority = {
  __typename?: "BondAuthority";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  agencyLegalEntityAilorn: Scalars["AiloRN"];
  userFacingName: Scalars["String"];
};

export type BondAuthorityAggregateGroupBy = {
  __typename?: "BondAuthorityAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
};

export type BondAuthorityCountAggregate = {
  __typename?: "BondAuthorityCountAggregate";
  id?: Maybe<Scalars["Int"]>;
};

export type BondAuthorityEdge = {
  __typename?: "BondAuthorityEdge";
  /** The node containing the BondAuthority */
  node: BondAuthority;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type BondAuthorityMaxAggregate = {
  __typename?: "BondAuthorityMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
};

export type BondAuthorityMinAggregate = {
  __typename?: "BondAuthorityMinAggregate";
  id?: Maybe<Scalars["ID"]>;
};

export enum BondDisbursalFailureReason {
  ReceivedLessFundsThanClaimed = "ReceivedLessFundsThanClaimed",
  ReceivedMoreFundsThanClaimed = "ReceivedMoreFundsThanClaimed",
  PaymentError = "PaymentError"
}

export type BondsData = {
  __typename?: "BondsData";
  bondId: Scalars["String"];
  bondReference?: Maybe<Scalars["String"]>;
  bondStatusDescription?: Maybe<Scalars["String"]>;
  bondStatus?: Maybe<Scalars["String"]>;
  renterNames?: Maybe<Scalars["String"]>;
  investorNames?: Maybe<Scalars["String"]>;
  dateClaimCompleted?: Maybe<Scalars["String"]>;
  dateFundsReceived?: Maybe<Scalars["String"]>;
  dateClaimCreated?: Maybe<Scalars["String"]>;
  agencyId?: Maybe<Scalars["String"]>;
  bondAmount?: Maybe<Scalars["String"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  managementId?: Maybe<Scalars["String"]>;
};

export type BondsReport = {
  __typename?: "BondsReport";
  type: Scalars["String"];
  columns: Array<ReportColumn>;
  rows: Array<BondsData>;
};

export enum Book {
  Accrual = "ACCRUAL",
  Cash = "CASH"
}

export type BooleanFieldComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
};

export type BPay = PaymentMethod & {
  __typename?: "BPay";
  ailoRN: Scalars["AiloRN"];
  billerCode: Scalars["String"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  topUpFee?: Maybe<TransactionFee>;
  wallet: Wallet;
};

export type BPayInput = {
  billerCode: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type BulkActionMigratingManagementsErrorPayload = {
  __typename?: "BulkActionMigratingManagementsErrorPayload";
  id: Scalars["ID"];
  failureReason: Scalars["String"];
};

export type BulkActionMigratingManagementsPayload = {
  __typename?: "BulkActionMigratingManagementsPayload";
  successes: Array<MigratingManagement>;
  errors: Array<BulkActionMigratingManagementsErrorPayload>;
};

export type BulkActionMigrationTasksErrorPayload = {
  __typename?: "BulkActionMigrationTasksErrorPayload";
  legalEntityId: Scalars["AiloRN"];
  failureReason: Scalars["String"];
};

export type BulkActionMigrationTasksPayload = {
  __typename?: "BulkActionMigrationTasksPayload";
  successes: Array<Scalars["AiloRN"]>;
  errors: Array<BulkActionMigrationTasksErrorPayload>;
};

export type BusinessTransaction = {
  __typename?: "BusinessTransaction";
  ailoRN: Scalars["AiloRN"];
  amount: Money;
  children?: Maybe<Array<Maybe<BusinessTransaction>>>;
  /** Returns date & time of clearedAt or null if it is not cleared yet. */
  clearedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars["String"]>;
  /**
   * Returns date of clearedAt if the business transaction is cleared, else it returns an estimate based on created date and today's date.
   * If the status of the business transaction is FAIL or CLEANED, null is returned.
   * Currently does not support business transactions without a liability due to lack of Zone data in Business Transaction.
   */
  estimatedClearDate?: Maybe<Scalars["Date"]>;
  /** Returns date & time of clearedAt (Can be before cleared) */
  expectedClearedAt?: Maybe<Scalars["DateTime"]>;
  /** Returns date of when latest business transaction status is created if it's status is FAIL or CLEANED and null otherwise. */
  failedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  idempotentKey: Scalars["String"];
  isReversal: Scalars["Boolean"];
  parent?: Maybe<BusinessTransaction>;
  payer?: Maybe<Scalars["AiloRN"]>;
  payerLegalEntity?: Maybe<LegalEntityCompanion>;
  paymentChannel?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentReferenceId?: Maybe<Scalars["String"]>;
  status?: Maybe<BusinessTxStatusEnum>;
  type: BusinessTxType;
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type BusinessTransactionStatus = {
  __typename?: "BusinessTransactionStatus";
  status: BusinessTxStatusEnum;
};

export enum BusinessTxStatusEnum {
  Cleaned = "CLEANED",
  Fail = "FAIL",
  Pending = "PENDING",
  Success = "SUCCESS"
}

export enum BusinessTxType {
  PaymentAuto = "PAYMENT_AUTO",
  PaymentManual = "PAYMENT_MANUAL",
  Refund = "REFUND",
  Reverse = "REVERSE",
  TopUpManual = "TOP_UP_MANUAL",
  VirtualAccountDebit = "VIRTUAL_ACCOUNT_DEBIT",
  WithdrawAuto = "WITHDRAW_AUTO",
  WithdrawManual = "WITHDRAW_MANUAL"
}

export type CancelAutoPayInput = {
  liabilityId: Scalars["ID"];
};

export type CancelAutoPayLiabilityStatusInput = {
  /**
   * Legal Entity ARN (Person/Company) if it is meant to pay liabilities using their "personal" wallet,
   * or Management folio ARN, if it's meant to pay liabilities using management folio wallet.
   */
  payerId?: Maybe<Scalars["AiloRN"]>;
  /** Current user legal entity ID. Will get deprecated soon, once we are able to get user person ARN from new authz token. */
  payerLegalEntity: Scalars["AiloRN"];
};

export type CancelAutoWithdrawPlanInput = {
  autoWithdrawPlanId: Scalars["ID"];
};

export type CancelInspectionAppointmentResult = {
  __typename?: "CancelInspectionAppointmentResult";
  inspectionAppointment: InspectionAppointment;
};

export type CancelLiabilityPaymentPlanInput = {
  liabilityPaymentPlanId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type CancelRecurringFeeInput = {
  id: Scalars["AiloRN"];
  /**
   * If not provided, fee will end with the current cycle. (Future cycles will not happen.)
   * In case the fee hasn't started yet, it'll end immediately. (It's end date will be set to the date before the fee start date.)
   *
   * Optionally, pass in the date on which the fee should end.
   * Such date needs to be an end date of a fee cycle.
   */
  endDate?: Maybe<Scalars["String"]>;
};

export type CancelVacateAbility = {
  __typename?: "CancelVacateAbility";
  ableToCancel: Scalars["Boolean"];
  notAbleToCancelReason?: Maybe<NotAbleToCancelReason>;
};

export type CardMetric = {
  __typename?: "CardMetric";
  label?: Maybe<Scalars["String"]>;
  thisPeriodValue?: Maybe<Scalars["String"]>;
  periodLabel?: Maybe<Scalars["String"]>;
  previousPeriodValue?: Maybe<Scalars["String"]>;
  changeValue?: Maybe<Scalars["String"]>;
  changeType?: Maybe<Scalars["String"]>;
};

export type CentrepayData = {
  __typename?: "CentrepayData";
  crnReference: Scalars["String"];
  renterNames?: Maybe<Scalars["String"]>;
  latestTransactionDate?: Maybe<Scalars["String"]>;
  latestTransactionAmount?: Maybe<Scalars["String"]>;
  agencyId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  managementId?: Maybe<Scalars["String"]>;
  tenancyStatus?: Maybe<Scalars["String"]>;
  crnStatus?: Maybe<Scalars["String"]>;
  crnDeletedDate?: Maybe<Scalars["String"]>;
};

export type CentrepayDirective = {
  __typename?: "CentrepayDirective";
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  tenancy: Scalars["AiloRN"];
};

export type CentrepayFeeOverrideInput = {
  escrowAccount: Scalars["AiloRN"];
  enabled: Scalars["Boolean"];
};

export type CentrepayFeeSetting = {
  __typename?: "CentrepayFeeSetting";
  ailoRN: Scalars["AiloRN"];
  enabled: Scalars["Boolean"];
};

export type CentrepayReport = {
  __typename?: "CentrepayReport";
  type: Scalars["String"];
  columns: Array<ReportColumn>;
  rows: Array<CentrepayData>;
};

export type Charge = {
  __typename?: "Charge";
  amount: TaxableAmount;
  date: Scalars["Date"];
};

export type ChargeCycle = {
  __typename?: "ChargeCycle";
  amount: Money;
  endDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  startDate: Scalars["Date"];
  taxAmount: Money;
};

export type ChargeSchedule = {
  __typename?: "ChargeSchedule";
  firstChargeDate: Scalars["Date"];
};

export type ChargeScheduleInput = {
  firstChargeDate: Scalars["Date"];
};

export type ChatV2 = {
  __typename?: "ChatV2";
  ailorn: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
  participants: Array<Contact>;
  threads?: Maybe<PaginatedThreadsV2>;
  mostRecentMessage: MessageSummaryV2;
  readonly: Scalars["Boolean"];
  unreadMessagesCount: Scalars["Int"];
};

export type ChatV2ThreadsArgs = {
  pagination?: Maybe<PaginationInputV2>;
};

export type ClaimedFilesCount = {
  __typename?: "ClaimedFilesCount";
  totalFiles: Scalars["Int"];
  validFiles: Scalars["Int"];
};

export type ClearManagementEndPayload = {
  __typename?: "ClearManagementEndPayload";
  management?: Maybe<Management>;
};

export type Company = LegalEntity & {
  __typename?: "Company";
  id: Scalars["ID"];
  ailoRN: Scalars["String"];
  eulaSignedAt?: Maybe<Scalars["DateTime"]>;
  organisationId: Scalars["ID"];
  organisation: Organisation;
  timezone: Scalars["TimeZone"];
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  registeredEntityId?: Maybe<Scalars["String"]>;
  registeredEntityName: Scalars["String"];
  eulaSignedBy?: Maybe<Person>;
  currentUserPermissions: Array<Scalars["String"]>;
  accountsEmail: Array<Scalars["String"]>;
  /** Legal Entity Memberships in which this legal entity is the group of which the other people are members. */
  legalEntityMembershipsAsLegalEntity: Array<LegalEntityMembership>;
  trustAccounts: CompanyTrustAccountsConnection;
  /** Statements with subject = Legal Entity Ailorn */
  statements?: Maybe<PaginatedStatements>;
  tenancies?: Maybe<PaginatedTenancies>;
  managements?: Maybe<PaginatedManagements>;
  tenancyBondVirtualAccounts: Array<TenancyBondAccount>;
  ownerships?: Maybe<PaginatedOwnerships>;
  tenantships?: Maybe<PaginatedTenantships>;
  /** this can go when payment gateway splits */
  allWalletsAvailableBalance: Money;
  allWalletsTotalBalance: Money;
  kycVerifiedAccordingToLedgerService?: Maybe<Scalars["Boolean"]>;
  paymentMethods?: Maybe<PaginatedPaymentMethods>;
  wallets: Array<Wallet>;
  contact?: Maybe<Contact>;
};

export type CompanyTrustAccountsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TrustAccountFilter>;
  sorting?: Maybe<Array<TrustAccountSort>>;
};

export type CompanyStatementsArgs = {
  filter: StatementFilter;
  cursor?: Maybe<StatementCursor>;
  sort?: Maybe<Array<StatementSort>>;
};

export type CompanyTenanciesArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type CompanyManagementsArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type CompanyOwnershipsArgs = {
  managingOrganisationId: Scalars["AiloRN"];
  pageCursor: PmPageCursor;
};

export type CompanyTenantshipsArgs = {
  managingOrganisationId: Scalars["AiloRN"];
  pageCursor: PmPageCursor;
};

export type CompanyPaymentMethodsArgs = {
  methodType?: Maybe<PaymentMethodType>;
};

export type CompanyContactArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type CompanyTrustAccountsConnection = {
  __typename?: "CompanyTrustAccountsConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<TrustAccountEdge>;
};

export type CompleteAssignFormActionInput = {
  id: Scalars["ID"];
  formAilorn: Scalars["AiloRN"];
};

export type CompleteAssignFormActionPayload = {
  __typename?: "CompleteAssignFormActionPayload";
  action?: Maybe<Action>;
};

export type CompleteAssignNewManagementActionInput = {
  id: Scalars["ID"];
  managementAilorn: Scalars["AiloRN"];
};

export type CompleteAssignNewManagementActionPayload = {
  __typename?: "CompleteAssignNewManagementActionPayload";
  action?: Maybe<Action>;
};

export type CompleteAssignRentActionInput = {
  rentAilorn: Scalars["AiloRN"];
};

export type CompleteAssignRentActionPayload = {
  __typename?: "CompleteAssignRentActionPayload";
  action?: Maybe<Action>;
};

export type CompleteAssignTenancyActionInput = {
  id: Scalars["ID"];
  tenancyAilorn: Scalars["AiloRN"];
  inspectionAppointmentAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type CompleteAssignTenancyActionPayload = {
  __typename?: "CompleteAssignTenancyActionPayload";
  action?: Maybe<Action>;
};

export type CompleteAssignTenancyAgreementActionInput = {
  tenancyId: Scalars["ID"];
  agreementDetails: TenancyAgreementDetails;
};

export type ConfirmInspectionScheduleActionMeta = {
  __typename?: "ConfirmInspectionScheduleActionMeta";
  tenancy?: Maybe<Tenancy>;
  inspectionAppointment?: Maybe<InspectionAppointment>;
};

export type ConsumerInvite = {
  __typename?: "ConsumerInvite";
  id: Scalars["ID"];
  metadata: Scalars["String"];
};

export type ConsumerSetupInput = {
  email?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  migratingCustomer?: Maybe<Scalars["Boolean"]>;
  legalMiddleName?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["Date"]>;
};

export type Contact = {
  __typename?: "Contact";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  types: Array<ContactType>;
  /**
   * The contact's preferred name (or first name if they haven't indicated a preferred name), followed by their last name
   * (if any), in a format ready for display. Does not include middle names.
   */
  displayName: Scalars["String"];
  /** The contact's preferred name, or their first name if they haven't indicated a preferred name */
  displayFirstName: Scalars["String"];
  status: ContactStatus;
  legalEntity?: Maybe<LegalEntity>;
  legalEntityType?: Maybe<ContactLegalEntityType>;
  user?: Maybe<User>;
  primaryEmail?: Maybe<Scalars["String"]>;
  photo?: Maybe<File>;
  /** The owner of this contact, e.g. a Person contact who owns a Company contact */
  owner?: Maybe<Contact>;
  /** Contacts that are owned by this contact. i.e. Company contacts that are owned by a Person contact */
  contactsOwned: Array<Contact>;
  /**
   * This is the confidential part of the contact that is accessible only for entities that have a read permission against the
   * requested contact's organisation. Other entities would only be able to see the "non-confidential" fields.
   * Null if a non-authorised user tries to query for this
   */
  confidential?: Maybe<ContactConfidential>;
  allAddresses: Array<ContactPropertyAddress>;
  investorAddresses: Array<ManagementPropertyAddress>;
  tenantAddresses: Array<TenancyPropertyAddress>;
  teams: Array<Maybe<Team>>;
  mailboxAccesses: Array<MailboxAccess>;
};

export type ContactConfidential = {
  __typename?: "ContactConfidential";
  organisation?: Maybe<Organisation>;
  primaryPhone?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["Date"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  onAppAt?: Maybe<Scalars["DateTime"]>;
  archivedAt?: Maybe<Scalars["DateTime"]>;
  contactRole?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  middleNames?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  modifiedAt: Scalars["DateTime"];
  modifiedBy: ActionInitiator;
};

export enum ContactLegalEntityType {
  Person = "Person",
  Company = "Company"
}

export type ContactOrEmail = {
  contactAilorn?: Maybe<Scalars["AiloRN"]>;
  email?: Maybe<Scalars["String"]>;
};

export type ContactPropertyAddress = {
  managementAilorn: Scalars["AiloRN"];
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  /** <unitStreetNumber> <streetName> */
  shortAddress: Scalars["String"];
};

export type ContactsByManagement = {
  __typename?: "ContactsByManagement";
  agencyContacts: Array<Contact>;
  investorContacts: Array<Contact>;
};

export type ContactsByTenancy = {
  __typename?: "ContactsByTenancy";
  agencyContacts: Array<Contact>;
  tenantContacts: Array<Contact>;
};

export type ContactsFilters = {
  legalEntityTypes?: Maybe<Array<ContactLegalEntityType>>;
  excludeContactAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type ContactSortParams = {
  field: Scalars["String"];
  direction: SortDirection;
};

export type ContactsQueryInput = {
  pagination: PaginationParams;
};

export enum ContactStatus {
  NotOnApp = "NotOnApp",
  OnApp = "OnApp",
  Archived = "Archived"
}

export enum ContactType {
  Investor = "Investor",
  Tenant = "Tenant",
  Supplier = "Supplier",
  Agent = "Agent",
  Other = "Other"
}

export type CreateActionInput = {
  name: Scalars["String"];
  description: Scalars["String"];
  projectId: Scalars["ID"];
  assigneeAilorn: Scalars["AiloRN"];
  type: ActionType;
  dueDate: Scalars["DateTime"];
};

export type CreateAiloFormCreateAiloFormResponseInput = {
  payload: AiloFormResponsePayloadInput;
  fieldId: Scalars["ID"];
};

export type CreateAiloFormInput = {
  templateId: Scalars["ID"];
  organisationAilorn: Scalars["AiloRN"];
  name: Scalars["String"];
  responses?: Maybe<Array<CreateAiloFormCreateAiloFormResponseInput>>;
};

export type CreateAiloFormResult = {
  __typename?: "CreateAiloFormResult";
  form: AiloForm;
};

export type CreateAutoWithdrawPlanInputV2 = {
  anniversary?: Maybe<Scalars["Int"]>;
  /**
   * Only used when `frequency` = Monthly. Allowed values: 1-28.
   * e.g. ['1', '15'] means a withdrawal occurs on the 1st and 15th of every month.
   */
  anniversaryDaysOfMonth?: Maybe<Array<Scalars["Int"]>>;
  endDate?: Maybe<Scalars["Date"]>;
  frequency: QuartzPlanFrequency;
  /**
   * Only used when `frequency` = Monthly.
   * If `true`, withdrawal will happen on the last day of the month.
   * e.g. 30th June, 31st July, 28th Feb or 29th Feb depending on leap year.
   */
  lastDayOfTheMonth?: Maybe<Scalars["Boolean"]>;
  payerLegalEntityId: Scalars["AiloRN"];
  paymentMethodDestinations: Array<PaymentMethodDestinationInput>;
  setAsideAmount?: Maybe<MoneyInput>;
  startDate: Scalars["Date"];
  userFacingDescription?: Maybe<Scalars["String"]>;
  walletId: Scalars["ID"];
};

export type CreateBananaInput = {
  bananaId: Scalars["ID"];
};

export type CreateBondInput = {
  tenancyAilorn: Scalars["AiloRN"];
  reference?: Maybe<Scalars["String"]>;
  amount: MoneyInput;
  bondAccountAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type CreateChatInputV2 = {
  participants: Array<ContactOrEmail>;
  initialThread: ThreadInputV2;
  organisationAilorn: Scalars["AiloRN"];
};

export type CreateFeeBlueprintInput = {
  name: Scalars["String"];
  type: FeeBlueprintType;
  taxCategoryId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  fixedAmount?: Maybe<MoneyInput>;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  frequency?: Maybe<FeeFrequency>;
  event?: Maybe<FeeEventType>;
  anniversaryDay?: Maybe<Scalars["Int"]>;
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  organisationId: Scalars["AiloRN"];
};

export type CreateFeeInput = {
  type: FeeType;
  feeBlueprintId?: Maybe<Scalars["String"]>;
  managementFeeBlueprintId?: Maybe<Scalars["String"]>;
  managementId: Scalars["String"];
  amount: MoneyInput;
  taxAmount?: Maybe<MoneyInput>;
  percentage?: Maybe<Scalars["Float"]>;
  baseAmount?: Maybe<MoneyInput>;
  baseAmountType?: Maybe<FeeBaseAmountType>;
  taxCategoryId: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type CreateInspectionAppointmentInput = {
  propertyAilorn?: Maybe<Scalars["AiloRN"]>;
  tenancyAilorn: Scalars["AiloRN"];
  type: InspectionType;
  inspectingAgentAilorn: Scalars["AiloRN"];
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type CreateInspectionAppointmentsResult = {
  __typename?: "CreateInspectionAppointmentsResult";
  inspectionAppointments: Array<InspectionAppointment>;
};

export type CreateInspectionAreaFileInput = {
  fileAilorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
  inspectionAreaId: Scalars["ID"];
};

export type CreateInspectionAreaFileResult = {
  __typename?: "CreateInspectionAreaFileResult";
  inspectionAreaFile: InspectionAreaFile;
};

export type CreateInspectionAreaInput = {
  index: Scalars["Int"];
  name: Scalars["String"];
  inspected: Scalars["Boolean"];
  notes?: Maybe<Scalars["String"]>;
  condition?: Maybe<InspectionAreaCondition>;
  inspectionId: Scalars["ID"];
  files?: Maybe<Array<CreateInspectionAreaInputFileInput>>;
  features?: Maybe<Array<CreateInspectionAreaInputFeatureInput>>;
};

export type CreateInspectionAreaInputFeatureInput = {
  name: Scalars["String"];
  index: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  isClean?: Maybe<Scalars["Boolean"]>;
  isUndamaged?: Maybe<Scalars["Boolean"]>;
  isWorking?: Maybe<Scalars["Boolean"]>;
  files?: Maybe<Array<CreateInspectionFeatureInputFileInput>>;
};

export type CreateInspectionAreaInputFileInput = {
  fileAilorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
};

export type CreateInspectionAreaResult = {
  __typename?: "CreateInspectionAreaResult";
  inspectionArea: InspectionArea;
};

export type CreateInspectionFeatureFileInput = {
  fileAilorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
  inspectionFeatureId: Scalars["ID"];
};

export type CreateInspectionFeatureFileResult = {
  __typename?: "CreateInspectionFeatureFileResult";
  inspectionFeatureFile: InspectionFeatureFile;
};

export type CreateInspectionFeatureInput = {
  name: Scalars["String"];
  index: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  isClean?: Maybe<Scalars["Boolean"]>;
  isUndamaged?: Maybe<Scalars["Boolean"]>;
  isWorking?: Maybe<Scalars["Boolean"]>;
  inspectionAreaId: Scalars["ID"];
  files?: Maybe<Array<CreateInspectionFeatureInputFileInput>>;
};

export type CreateInspectionFeatureInputFileInput = {
  fileAilorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
};

export type CreateInspectionFeatureResult = {
  __typename?: "CreateInspectionFeatureResult";
  inspectionFeature: InspectionFeature;
};

export type CreateInspectionInput = {
  inspectionAppointmentId: Scalars["String"];
  agencyAilorn?: Maybe<Scalars["AiloRN"]>;
  type: InspectionType;
  inspectingAgentAilorn: Scalars["AiloRN"];
  startedAt: Scalars["DateTime"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  areas?: Maybe<Array<CreateInspectionInputAreaInput>>;
  ailoFormAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type CreateInspectionInputAreaInput = {
  index: Scalars["Int"];
  name: Scalars["String"];
  inspected: Scalars["Boolean"];
  notes?: Maybe<Scalars["String"]>;
  condition?: Maybe<InspectionAreaCondition>;
  files?: Maybe<Array<CreateInspectionAreaInputFileInput>>;
  features?: Maybe<Array<CreateInspectionAreaInputFeatureInput>>;
};

export type CreateInspectionResult = {
  __typename?: "CreateInspectionResult";
  inspection: Inspection;
};

export type CreateLiabilityPaymentPlanInput = {
  amount: MoneyInput;
  anniversary: Scalars["Int"];
  endDate?: Maybe<Scalars["Date"]>;
  frequency: QuartzPlanFrequency;
  liabilityId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
  paymentMethodId: Scalars["ID"];
  startDate: Scalars["Date"];
};

export type CreateManagementFeeBlueprintInput = {
  managementId: Scalars["ID"];
  feeBlueprintId: Scalars["ID"];
  fixedAmount?: Maybe<MoneyInput>;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  description?: Maybe<Scalars["String"]>;
};

export type CreateOneKeyInput = {
  /** The record to create */
  key: KeyInput;
};

export type CreateOneTrustAccountInput = {
  /** The record to create */
  trustAccount: CreateTrustAccountInput;
};

export type CreateOneTrustAccountReconciliationAdjustmentInput = {
  /** The record to create */
  trustAccountReconciliationAdjustment: CreateTrustAccountReconciliationAdjustmentInput;
};

export type CreateOneTrustAccountStatementBalanceInput = {
  /** The record to create */
  trustAccountStatementBalance: CreateTrustAccountStatementBalanceInput;
};

export type CreateOrUpdateManagementNoteInput = {
  id?: Maybe<Scalars["ID"]>;
  managementId: Scalars["ID"];
  note: Scalars["String"];
};

export type CreateOrUpdateManagementNotePayload = {
  __typename?: "CreateOrUpdateManagementNotePayload";
  managementNote: ManagementNote;
};

export type CreateOtherContactInput = {
  organisationAilorn: Scalars["AiloRN"];
  primaryEmail: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
};

export type CreatePaymentMethodInput = {
  bankAccountInput?: Maybe<BankAccountInput>;
  bpayInput?: Maybe<BPayInput>;
  /** If true, create a `walletOwner` if it doesn't already exist before adding the provided payment method to it */
  createWalletOwner?: Maybe<Scalars["Boolean"]>;
  creditCardInput?: Maybe<CreditCardInputV2>;
  deleteExistingPaymentMethods?: Maybe<Scalars["Boolean"]>;
  /** ID reference from external system. Not used by ledger. */
  externalId?: Maybe<Scalars["String"]>;
  /** Organisation (e.g. agency) that is allowed to manage the provided wallet owner. Must be provided when the walletOwnerAiloRN is a supplier AiloRN. */
  managingOrganisationAiloRN?: Maybe<Scalars["AiloRN"]>;
  walletOwnerAiloRN: Scalars["AiloRN"];
};

export type CreateProjectAbility = {
  __typename?: "CreateProjectAbility";
  ableToCreate: Scalars["Boolean"];
  notAbleToCreateReason?: Maybe<Scalars["String"]>;
};

export type CreateProjectInput = {
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  organisationAilorn: Scalars["AiloRN"];
  assigneeAilorn: Scalars["AiloRN"];
  type: ProjectType;
  dueDate: Scalars["Date"];
  fileAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
  meta: Scalars["JSONObject"];
};

export type CreateReiFormFromUserTemplateInput = {
  /** template id of form to generate */
  reiUserTemplateId: Scalars["Int"];
  /** Form name for future reference */
  name: Scalars["String"];
  /** AiloRN of agency organisation */
  organisationAilorn: Scalars["AiloRN"];
  /** Tenancy Ailorn of the property */
  tenancyAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type CreateReiTokenInput = {
  /** REI Token */
  token: Scalars["String"];
  /** State the REI account was linked to */
  supportedAustralianState?: Maybe<AustralianState>;
  /** AiloRN of person the token is associated with */
  personAilorn: Scalars["AiloRN"];
  /** AiloRN of agency organisation */
  organisationAilorn: Scalars["AiloRN"];
};

export type CreateRentInput = {
  effectiveDate: Scalars["Date"];
  amountInCents: Scalars["String"];
  period: RentFrequency;
  category: RentCategory;
  setsChargeDate: Scalars["Boolean"];
};

export type CreateRentReviewPayload = {
  __typename?: "CreateRentReviewPayload";
  rent?: Maybe<Rent>;
  tenancy?: Maybe<Tenancy>;
  query: Query;
};

export type CreateRentsInput = {
  tenancyId: Scalars["ID"];
  rents: Array<CreateRentInput>;
};

export type CreateRentsPayload = {
  __typename?: "CreateRentsPayload";
  rents: Array<Rent>;
  query: Query;
};

export type CreateStatementInput = {
  statementOwnerAiloRNs: Array<Scalars["AiloRN"]>;
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
  type: StatementType;
  /** Statement created for is default to manual request */
  createdFor?: Maybe<StatementCreatedFor>;
  skipValidation?: Maybe<Scalars["Boolean"]>;
  skipManagementHasPaymentsCheck?: Maybe<Scalars["Boolean"]>;
  allowUnrecognisedTransactionLineItems?: Maybe<Scalars["Boolean"]>;
  linkFileToSubject?: Maybe<Scalars["Boolean"]>;
  sendEmail?: Maybe<Scalars["Boolean"]>;
  dryRunEmail?: Maybe<Scalars["String"]>;
  /** overrideStatementValues should be null unless overriding real data. To override the real data, provide a string containing a JSON of the exact statement values wanted for the statement */
  overrideStatementValues?: Maybe<Scalars["String"]>;
};

export type CreateStatementsForAllManagementsInput = {
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
  sendEmail?: Maybe<Scalars["Boolean"]>;
  type: StatementType;
  dryRunEmail?: Maybe<Scalars["String"]>;
  skipValidation?: Maybe<Scalars["Boolean"]>;
  skipManagementHasPaymentsCheck?: Maybe<Scalars["Boolean"]>;
  allowUnrecognisedTransactionLineItems?: Maybe<Scalars["Boolean"]>;
  linkFileToSubject?: Maybe<Scalars["Boolean"]>;
};

export type CreateStatementsResponse = {
  __typename?: "CreateStatementsResponse";
  statementProgresses: Array<StatementProgress>;
  numberOfStatements: Scalars["Int"];
};

export type CreateStatementWithSubjectInput = {
  statementSubjectAiloRNs: Array<Scalars["AiloRN"]>;
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
  type: StatementType;
  /** Statement created for is default to manual request */
  createdFor?: Maybe<StatementCreatedFor>;
  skipValidation?: Maybe<Scalars["Boolean"]>;
  skipManagementHasPaymentsCheck?: Maybe<Scalars["Boolean"]>;
  allowUnrecognisedTransactionLineItems?: Maybe<Scalars["Boolean"]>;
  linkFileToSubject?: Maybe<Scalars["Boolean"]>;
  sendEmail?: Maybe<Scalars["Boolean"]>;
  dryRunEmail?: Maybe<Scalars["String"]>;
  /** overrideStatementValues should be null unless overriding real data. To override the real data, provide a string containing a JSON of the exact statement values wanted for the statement */
  overrideStatementValues?: Maybe<Scalars["String"]>;
};

export type CreateSupplierInput = {
  organisationAiloRN: Scalars["AiloRN"];
  abn?: Maybe<Scalars["String"]>;
  registeredEntityName?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  address?: Maybe<SupplierAddressInput>;
  emailAddress?: Maybe<Scalars["String"]>;
};

export type CreateTenancyAgreementPayload = {
  __typename?: "CreateTenancyAgreementPayload";
  tenancyAgreement?: Maybe<TenancyAgreement>;
};

export type CreateTenancyBondClaim = {
  amount: MoneyInput;
  bondAiloRN: Scalars["AiloRN"];
  liabilityAiloRN: Scalars["AiloRN"];
  claimOrder: Scalars["Int"];
};

export type CreateTenantshipInput = {
  tenancyId: Scalars["ID"];
  tenantId: Scalars["ID"];
  startDate: Scalars["Date"];
  endDate?: Maybe<Scalars["Date"]>;
};

export type CreateThreadInputV2 = {
  thread: ThreadInputV2;
  chatAilorn: Scalars["AiloRN"];
};

export type CreateTrustAccountInput = {
  ownerLegalEntityAilorn: Scalars["AiloRN"];
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bsb?: Maybe<Scalars["String"]>;
};

export type CreateTrustAccountReconciliationAdjustmentInput = {
  amountInCents: Scalars["Long"];
  applyOn: Scalars["LocalDate"];
  details: Scalars["String"];
  reportPeriodId: Scalars["ID"];
};

export type CreateTrustAccountStatementBalanceInput = {
  amountInCents: Scalars["Long"];
  date: Scalars["LocalDate"];
  reportPeriodId: Scalars["ID"];
};

export type CreateUpdateAutoPayInput = {
  liabilityId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
};

export type CreditCard = PaymentMethod & {
  __typename?: "CreditCard";
  ailoRN: Scalars["AiloRN"];
  category?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  expiry?: Maybe<Scalars["Date"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  /** Masked credit card number, e.g. "512345XXXXXX2346". */
  number?: Maybe<Scalars["String"]>;
  topUpFee?: Maybe<TransactionFee>;
  /** E.g. "visa", "mastercard", "amex". */
  type?: Maybe<Scalars["String"]>;
  wallet: Wallet;
};

export type CreditCardInput = {
  description?: Maybe<Scalars["String"]>;
  rawCreditCardPayload: Scalars["String"];
};

export type CreditCardInputV2 = {
  category: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  expiry: Scalars["String"];
  number: Scalars["String"];
  token: Scalars["String"];
  type: Scalars["String"];
};

export type CumulativeCharge = {
  __typename?: "CumulativeCharge";
  amount: TaxableAmount;
  date: Scalars["Date"];
};

export type CursorPaging = {
  /** Paginate before opaque cursor */
  before?: Maybe<Scalars["ConnectionCursor"]>;
  /** Paginate after opaque cursor */
  after?: Maybe<Scalars["ConnectionCursor"]>;
  /** Paginate first */
  first?: Maybe<Scalars["Int"]>;
  /** Paginate last */
  last?: Maybe<Scalars["Int"]>;
};

export type DashboardFigure = {
  __typename?: "DashboardFigure";
  title: Scalars["String"];
  type: DashboardFigureType;
  data: DashboardFigureData;
};

export type DashboardFigureData =
  | DashboardFigurePeriodComparison
  | DashboardFigureTeamPeriodComparison;

export type DashboardFigurePeriodComparison = {
  __typename?: "DashboardFigurePeriodComparison";
  currentValue?: Maybe<Scalars["String"]>;
  previousValue?: Maybe<Scalars["String"]>;
  period?: Maybe<Scalars["String"]>;
};

export type DashboardFigureTeamPeriodComparison = {
  __typename?: "DashboardFigureTeamPeriodComparison";
  currentTotal: Scalars["Int"];
  teams: Array<TeamPeriodComparison>;
};

export enum DashboardFigureType {
  PeriodComparison = "PeriodComparison",
  TeamPeriodComparison = "TeamPeriodComparison"
}

export type DateFieldComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["DateTime"]>;
  neq?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
  between?: Maybe<DateFieldComparisonBetween>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars["DateTime"];
  upper: Scalars["DateTime"];
};

export type DateRange = {
  __typename?: "DateRange";
  startDate?: Maybe<Scalars["Date"]>;
  endDate?: Maybe<Scalars["Date"]>;
};

export enum DateRangeCurrentness {
  Past = "past",
  Current = "current",
  Future = "future"
}

export type DateRangeFilter = {
  afterDate?: Maybe<Scalars["Date"]>;
  beforeDate?: Maybe<Scalars["Date"]>;
};

export type DeleteCentrepayDirectiveInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  tenancy: Scalars["AiloRN"];
};

export type DeleteCrnInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
};

export type DeleteManagementFileInput = {
  fileAilorn: Scalars["AiloRN"];
  managementId: Scalars["ID"];
};

export type DeleteManagementFilePayload = {
  __typename?: "DeleteManagementFilePayload";
  file: File;
  query: Query;
};

export type DeleteOneKeyInput = {
  /** The id of the record to delete. */
  id: Scalars["ID"];
};

export type DeleteRentInput = {
  rentId: Scalars["ID"];
};

export type DeleteRentPayload = {
  __typename?: "DeleteRentPayload";
  rentId: Scalars["ID"];
  query: Query;
};

export type DeleteTenancyFileInput = {
  fileAilorn: Scalars["AiloRN"];
  tenancyId: Scalars["ID"];
};

export type DeleteTenancyFilePayload = {
  __typename?: "DeleteTenancyFilePayload";
  file: File;
  query: Query;
};

export type DeprecatedStatementData = {
  __typename?: "DeprecatedStatementData";
  lineItems?: Maybe<Array<StatementLineItem>>;
  netEarnings: Money;
  totalExpenses: Money;
  totalExpensesGST: Money;
  totalIncome: Money;
};

export type DisburseFundsResponse = {
  __typename?: "DisburseFundsResponse";
  success: Scalars["Boolean"];
};

export type DisburseToTrustAccountInput = {
  amount: MoneyInput;
  agency: Scalars["AiloRN"];
  escrowAccount: Scalars["AiloRN"];
  idempotencyKey: Scalars["String"];
  description: Scalars["String"];
};

export type EditableRentReviewPayload = {
  __typename?: "EditableRentReviewPayload";
  rent?: Maybe<Rent>;
  problem?: Maybe<EditableRentReviewProblem>;
};

export type EditableRentReviewProblem = ProblemInterface & {
  __typename?: "EditableRentReviewProblem";
  message: Scalars["String"];
};

export type EditRentReviewInput = {
  rentId: Scalars["ID"];
  effectiveDate: Scalars["Date"];
  amountInCents: Scalars["String"];
  period: RentFrequency;
  setsChargeDate: Scalars["Boolean"];
  scheduledRentReviewDate: Scalars["Date"];
};

export type EditRentReviewPayload = {
  __typename?: "EditRentReviewPayload";
  rent?: Maybe<Rent>;
  tenancy?: Maybe<Tenancy>;
  query: Query;
};

export type EftPaymentSettings = {
  __typename?: "EftPaymentSettings";
  /** Agency legal entity */
  agency: Scalars["AiloRN"];
  directEntryUserId?: Maybe<Scalars["String"]>;
  abaFileFormat: AbaFileFormat;
};

export type EftPaymentSettingsInput = {
  /** Agency legal entity */
  agency: Scalars["AiloRN"];
  directEntryUserId?: Maybe<Scalars["String"]>;
  abaFileFormat: AbaFileFormat;
};

export type EmbedableReportsParams = {
  field: ManagementSortField;
  direction: SortDirection;
};

export type EmbeddableReport = {
  __typename?: "EmbeddableReport";
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  reportThumbnail?: Maybe<Scalars["String"]>;
  reportType?: Maybe<Scalars["String"]>;
  reportUrl: Scalars["String"];
  thumbnailUrl?: Maybe<Scalars["String"]>;
};

export enum EmbeddableReportType {
  Agency = "Agency",
  Investor = "Investor",
  Principal = "Principal",
  Portfolio = "Portfolio"
}

export type EndManagementV2Input = {
  managementId: Scalars["ID"];
  endDate: Scalars["Date"];
  endReasonCode: ManagementEndReasonCode;
  endCauseCodes?: Maybe<Array<ManagementEndCauseCode>>;
  endNote: Scalars["String"];
};

export type EndManagementV2Payload = {
  __typename?: "EndManagementV2Payload";
  management: Management;
};

export type EndTenancyActionMeta = {
  __typename?: "EndTenancyActionMeta";
  tenancy?: Maybe<Tenancy>;
};

export type Entry = {
  __typename?: "Entry";
  account: Account;
  accountBalance?: Maybe<Money>;
  amount: Money;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  ledgerEvent: LedgerEvent;
  snowflake: Scalars["Long"];
  userFacingDescription: Scalars["String"];
};

export enum EntrySortField {
  CreatedAt = "CREATED_AT",
  Snowflake = "SNOWFLAKE"
}

export enum ExternalMailboxStatus {
  Active = "Active",
  Inactive = "Inactive"
}

export type ExternalMetadataInput = {
  externalSourceId: Scalars["Int"];
  externalFeeTemplateId: Scalars["ID"];
  triggerId?: Maybe<Scalars["ID"]>;
  triggerType?: Maybe<PMeFeeTriggerType>;
  name?: Maybe<Scalars["String"]>;
  chartAccountName?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Float"]>;
  valueType?: Maybe<PMeFeeValueType>;
};

export type Fee = {
  __typename?: "Fee";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  type: FeeType;
  management: Management;
  blueprint?: Maybe<FeeBlueprint>;
  managementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  /** Never null if type = BillManagementFee */
  appliedTo?: Maybe<Bill>;
  /**
   * Bill if type = BillManagementFee
   * Tenancy if type = EventBasedFee & blueprint.eventType = firstRentSettled
   */
  appliedToV2?: Maybe<FeeAppliedTo>;
  amount: Money;
  percentage?: Maybe<Scalars["Float"]>;
  baseAmount?: Maybe<Money>;
  baseAmountType?: Maybe<FeeBaseAmountType>;
  taxCategory: FeeTaxCategory;
  description?: Maybe<Scalars["String"]>;
  /** TODO META-381: Make this never null after backfilling status */
  status?: Maybe<FeeStatus>;
  createdAt: Scalars["DateTime"];
  /** Never null. */
  createdBy?: Maybe<ActionInitiator>;
  /** Never null. */
  modifiedBy?: Maybe<ActionInitiator>;
  archivedAt?: Maybe<Scalars["DateTime"]>;
  archivedBy?: Maybe<ActionInitiator>;
  archiveReason?: Maybe<Scalars["String"]>;
  taxAmount?: Maybe<Money>;
  liability?: Maybe<Liability>;
};

export type FeeAppliedTo = Bill | Tenancy;

export enum FeeBaseAmountType {
  Bill = "Bill",
  OneWeekRent = "OneWeekRent"
}

export type FeeBlueprint = {
  __typename?: "FeeBlueprint";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  type: FeeBlueprintType;
  name: Scalars["String"];
  taxCategory: FeeTaxCategory;
  event?: Maybe<FeeEvent>;
  /** @deprecated Use `description` on `Fee` or `RecurringFee` instead. */
  description?: Maybe<Scalars["String"]>;
  /** Present if `chargeType == FixedAmount`. Null otherwise. */
  fixedAmount?: Maybe<Money>;
  taxTreatment: TaxTreatment;
  /** Present if `chargeType == OneWeekRentPercentage`. Null otherwise. */
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  chargeType: FeeBlueprintChargeType;
  /** Present if `type == RecurringFee`. Null otherwise. */
  frequency?: Maybe<FeeFrequency>;
  /** Present if `type == RecurringFee`. Null otherwise. */
  anniversaryDay?: Maybe<Scalars["Int"]>;
  /** Present if `type == RecurringFee && frequency == "annually"`. Null otherwise. */
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  /** Always present. (Nullable only because it's a federated field.) */
  organisation?: Maybe<Organisation>;
  createdAt: Scalars["DateTime"];
  createdBy: ActionInitiator;
  modifiedBy: ActionInitiator;
  archived: Scalars["Boolean"];
  archivedAt?: Maybe<Scalars["DateTime"]>;
  archivedBy?: Maybe<ActionInitiator>;
  archiveReason?: Maybe<Scalars["String"]>;
  supported?: Maybe<Scalars["Boolean"]>;
};

export enum FeeBlueprintChargeType {
  FixedAmount = "FixedAmount",
  OneWeekRentPercentage = "OneWeekRentPercentage"
}

export type FeeBlueprintsQueryConditions = {
  organisationId?: Maybe<Scalars["ID"]>;
  type?: Maybe<FeeBlueprintType>;
  /** Exclude agency fee blueprints that are linked to non-archived management fee blueprints on the given management */
  notAppliedToManagementId?: Maybe<Scalars["ID"]>;
  archived?: Maybe<Scalars["Boolean"]>;
};

export enum FeeBlueprintType {
  RecurringFee = "RecurringFee",
  OneOffFee = "OneOffFee",
  EventBasedFee = "EventBasedFee"
}

export type FeeCatagory = {
  __typename?: "FeeCatagory";
  name: Scalars["String"];
};

export type FeeEvent = {
  __typename?: "FeeEvent";
  type: FeeEventType;
  shortDescription: Scalars["String"];
  longDescription: Scalars["String"];
  createdAt: Scalars["DateTime"];
};

export enum FeeEventType {
  FirstRentSettled = "firstRentSettled"
}

export enum FeeFrequency {
  Weekly = "weekly",
  Monthly = "monthly",
  Annually = "annually"
}

export type FeeOccurrence = {
  __typename?: "FeeOccurrence";
  date: Scalars["Date"];
  taxAmount: Money;
  taxInclusiveAmount: Money;
};

export enum FeeSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  /** Sorted in this order: Due, PaymentPending, Paid, RefundPending, Refunded, Cancelled */
  StatusProgressAsc = "STATUS_PROGRESS_ASC"
}

export type FeesQueryConditions = {
  type?: Maybe<Array<FeeType>>;
  managementId?: Maybe<Array<Scalars["ID"]>>;
  appliedToAiloRN?: Maybe<Array<Scalars["AiloRN"]>>;
  status?: Maybe<Array<FeeStatus>>;
};

export enum FeeStatus {
  Due = "Due",
  PaymentPending = "PaymentPending",
  Paid = "Paid",
  Cancelled = "Cancelled",
  RefundPending = "RefundPending",
  Refunded = "Refunded"
}

export type FeeTaxCategory = {
  __typename?: "FeeTaxCategory";
  id: Scalars["String"];
  name: Scalars["String"];
  createdAt: Scalars["DateTime"];
};

export enum FeeType {
  BillManagementFee = "BillManagementFee",
  OneOffFee = "OneOffFee",
  EventBasedFee = "EventBasedFee"
}

export type File = {
  __typename?: "File";
  id: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
  fileName: Scalars["String"];
  fileSize?: Maybe<Scalars["Int"]>;
  kind: FileKind;
  contentType?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  thumbnailUrl?: Maybe<Scalars["String"]>;
  thumbnailStatus: ThumbnailStatus;
};

export enum FileKind {
  AgencyTransferStatement = "AgencyTransferStatement",
  AuthZPersonPhoto = "AuthZ_PersonPhoto",
  BillBillAttachment = "Bill_BillAttachment",
  ChatMessageFilePart = "Chat_MessageFilePart",
  InspectionReport = "InspectionReport",
  StatementInvestorStatement = "Statement_InvestorStatement",
  ProjectFile = "ProjectFile",
  TenancyFile = "TenancyFile",
  ManagementFile = "ManagementFile",
  InspectionFile = "InspectionFile",
  Receipt = "Receipt",
  CentrepayReport = "CentrepayReport",
  Unknown = "unknown"
}

export type FileWithUploadUrl = {
  __typename?: "FileWithUploadUrl";
  file: File;
  uploadUrl: Scalars["String"];
};

export type Filter = {
  dateRange?: Maybe<DateRangeFilter>;
  search?: Maybe<Array<Maybe<SearchFilter>>>;
};

export type FindOrCreateBlueprintInput = {
  name: Scalars["String"];
  type: FeeBlueprintType;
  taxCategoryId: Scalars["ID"];
  taxTreatment: TaxTreatment;
  event?: Maybe<FeeEventType>;
  organisationAilorn: Scalars["AiloRN"];
  externalMetadata: ExternalMetadataInput;
  fixedAmount?: Maybe<MoneyInput>;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  frequency?: Maybe<FeeFrequency>;
  anniversaryDay?: Maybe<Scalars["Int"]>;
  anniversaryMonth?: Maybe<Scalars["Int"]>;
};

export type FindOrCreateManagementFeeBlueprintInput = {
  managementId: Scalars["ID"];
  feeBlueprintId: Scalars["ID"];
  fixedAmount?: Maybe<MoneyInput>;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  pmeData?: Maybe<ExternalMetadataInput>;
  description?: Maybe<Scalars["String"]>;
};

export type FindOrCreateOtherContactsInput = {
  organisationAilorn: Scalars["AiloRN"];
  emails: Array<Scalars["String"]>;
};

export type FirstAndLastEntries = {
  __typename?: "FirstAndLastEntries";
  firstEntry?: Maybe<Entry>;
  lastEntry?: Maybe<Entry>;
};

export type Form = {
  id: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  organisation: Organisation;
};

export type FundReceived = {
  __typename?: "FundReceived";
  amount: Money;
  receivedAt: Scalars["DateTime"];
};

export type GatewayCapturePaymentDetails = {
  __typename?: "GatewayCapturePaymentDetails";
  url?: Maybe<Scalars["URL"]>;
};

export type GeneralLedgerInteraction = {
  __typename?: "GeneralLedgerInteraction";
  reference: Scalars["AiloRN"];
};

export type GeneralProjectMeta = {
  __typename?: "GeneralProjectMeta";
  management?: Maybe<Management>;
};

export type GeneralStatementFilter = {
  subject?: Maybe<Scalars["AiloRN"]>;
  types?: Maybe<Array<StatementType>>;
  createdFor?: Maybe<Array<StatementCreatedFor>>;
};

export type GenerateInspectionReportActionMeta = {
  __typename?: "GenerateInspectionReportActionMeta";
  inspectionAppointment?: Maybe<InspectionAppointment>;
};

export type GenerateInspectionReportInput = {
  inspectionId: Scalars["String"];
};

export type GenerateInspectionReportResult = {
  __typename?: "GenerateInspectionReportResult";
  inspection: Inspection;
};

export type GenerateLiabilityEntriesInput = {
  liabilityIds: Array<Scalars["ID"]>;
};

export type GenerateLiabilityEntriesPayload = {
  __typename?: "GenerateLiabilityEntriesPayload";
  liabilityIds: Array<Scalars["ID"]>;
};

export type GetCentrepayersInput = {
  tenancy: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
};

export type GetCentrepayPaymentDirectivesFilter = {
  legalEntity?: Maybe<Scalars["AiloRN"]>;
  managingEntity?: Maybe<Scalars["AiloRN"]>;
  tenancy?: Maybe<Scalars["AiloRN"]>;
};

export type GetCrnInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
};

export type GetCrnOutput = {
  __typename?: "GetCrnOutput";
  crn: Scalars["String"];
};

export type GetLegalEntityByCrnInput = {
  managingEntity: Scalars["AiloRN"];
  crn: Scalars["String"];
};

export type GetLegalEntityByCrnOutput = {
  __typename?: "GetLegalEntityByCrnOutput";
  legalEntity: Scalars["AiloRN"];
};

export type GetMailboxAuthorizationUrlInput = {
  contactAilorn: Scalars["AiloRN"];
  provider: MailboxProvider;
};

export type GetOrCreateWalletOwnerAndBankAccountInput = {
  accountName: Scalars["String"];
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
  description: Scalars["String"];
  walletOwnerAiloRN: Scalars["AiloRN"];
};

export type GetTrustAccountInput = {
  agency: Scalars["AiloRN"];
};

export type GraphQlAilornFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["AiloRN"]>;
  neq?: Maybe<Scalars["AiloRN"]>;
  gt?: Maybe<Scalars["AiloRN"]>;
  gte?: Maybe<Scalars["AiloRN"]>;
  lt?: Maybe<Scalars["AiloRN"]>;
  lte?: Maybe<Scalars["AiloRN"]>;
  like?: Maybe<Scalars["AiloRN"]>;
  notLike?: Maybe<Scalars["AiloRN"]>;
  iLike?: Maybe<Scalars["AiloRN"]>;
  notILike?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type IdFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["ID"]>;
  neq?: Maybe<Scalars["ID"]>;
  gt?: Maybe<Scalars["ID"]>;
  gte?: Maybe<Scalars["ID"]>;
  lt?: Maybe<Scalars["ID"]>;
  lte?: Maybe<Scalars["ID"]>;
  like?: Maybe<Scalars["ID"]>;
  notLike?: Maybe<Scalars["ID"]>;
  iLike?: Maybe<Scalars["ID"]>;
  notILike?: Maybe<Scalars["ID"]>;
  in?: Maybe<Array<Scalars["ID"]>>;
  notIn?: Maybe<Array<Scalars["ID"]>>;
};

export type IgnoreMe = {
  __typename?: "IgnoreMe";
  company?: Maybe<Company>;
  paymentMethodCompanion?: Maybe<PaymentMethodCompanion>;
  person?: Maybe<Person>;
};

export type InAppReport = {
  __typename?: "InAppReport";
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  reportThumbnail?: Maybe<Scalars["String"]>;
  reportType?: Maybe<Scalars["String"]>;
  reportBackend?: Maybe<Scalars["String"]>;
  reportUrl: Scalars["String"];
  thumbnailUrl?: Maybe<Scalars["String"]>;
};

export type InAppReportParams = {
  direction: SortDirection;
};

export enum InAppReportType {
  Agency = "Agency",
  Investor = "Investor",
  Principal = "Principal",
  Portfolio = "Portfolio"
}

export type InboundMailDirectionDetails = {
  __typename?: "InboundMailDirectionDetails";
  direction: MailDirection;
};

export type IncomeAndExpenseByTaxCategoryData = {
  __typename?: "IncomeAndExpenseByTaxCategoryData";
  expenses: Array<IncomeAndExpenseByTaxCategoryDataEntry>;
  income: Array<IncomeAndExpenseByTaxCategoryDataEntry>;
};

export type IncomeAndExpenseByTaxCategoryDataEntry = {
  __typename?: "IncomeAndExpenseByTaxCategoryDataEntry";
  taxCategory: Scalars["String"];
  totalAmount: Money;
  totalTaxAmount: Money;
};

export type IncomeAndExpenseByTaxCategoryInput = {
  accountOwnerReference: Scalars["AiloRN"];
  /** Filter to include only entries which have created_at < createdBefore */
  createdBefore: Scalars["DateTime"];
  /** Filter to include only entries which have created_at >= createdFrom */
  createdFrom: Scalars["DateTime"];
};

export type IngoingTenancyUpdateInput = {
  tenancyId: Scalars["ID"];
  scheduledRentReviewDate?: Maybe<Scalars["Date"]>;
  tenancyAgreement: TenancyAgreementSetupInput;
  rents: Array<RentSetupInput>;
};

export type Inspection = {
  __typename?: "Inspection";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  type: InspectionType;
  startedAt: Scalars["DateTime"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  areas: Array<InspectionArea>;
  inspectionAppointment: InspectionAppointment;
  property: Property;
  agency: Organisation;
  inspectingAgent: Person;
  /** @deprecated Use areaFilesForThumbnail and featureFieldsForThumbnail instead */
  files: Array<InspectionAreaFile>;
  areaFilesForThumbnail: Array<InspectionAreaFile>;
  featureFilesForThumbnail: Array<InspectionFeatureFile>;
  ailoForm?: Maybe<AiloForm>;
  tenancy: Tenancy;
  reportGenerationState: ReportGenerationState;
  latestReportProgress?: Maybe<InspectionReportProgress>;
};

export type InspectionAreaFilesForThumbnailArgs = {
  limit?: Maybe<Scalars["Int"]>;
};

export type InspectionFeatureFilesForThumbnailArgs = {
  limit?: Maybe<Scalars["Int"]>;
};

export type InspectionAggregateGroupBy = {
  __typename?: "InspectionAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
};

export type InspectionAppointment = {
  __typename?: "InspectionAppointment";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  type: InspectionType;
  startTime?: Maybe<Scalars["DateTime"]>;
  inspection?: Maybe<Inspection>;
  property: Property;
  tenancy: Tenancy;
  agency: Organisation;
  inspectingAgent: Person;
  previousInspection?: Maybe<Inspection>;
  project?: Maybe<Project>;
};

export type InspectionAppointmentAggregateGroupBy = {
  __typename?: "InspectionAppointmentAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  propertyAilorn?: Maybe<Scalars["AiloRN"]>;
  tenancyAilorn?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type InspectionAppointmentConnection = {
  __typename?: "InspectionAppointmentConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<InspectionAppointmentEdge>;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type InspectionAppointmentCountAggregate = {
  __typename?: "InspectionAppointmentCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  propertyAilorn?: Maybe<Scalars["Int"]>;
  tenancyAilorn?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["Int"]>;
  inspectingAgentAilorn?: Maybe<Scalars["Int"]>;
  startTime?: Maybe<Scalars["Int"]>;
};

export type InspectionAppointmentEdge = {
  __typename?: "InspectionAppointmentEdge";
  /** The node containing the InspectionAppointment */
  node: InspectionAppointment;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type InspectionAppointmentFilter = {
  id?: Maybe<IdFilterComparison>;
  propertyAilorn?: Maybe<InspectionAppointmentPropertyAilornFilterComparison>;
  tenancyAilorn?: Maybe<InspectionAppointmentTenancyAilornFilterComparison>;
  type?: Maybe<InspectionAppointmentTypeFilterComparison>;
  inspectingAgentAilorn?: Maybe<InspectionAppointmentInspectingAgentAilornFilterComparison>;
  startTime?: Maybe<DateFieldComparison>;
  inspection?: Maybe<InspectionAppointmentFilterInspectionFilter>;
};

export type InspectionAppointmentFilterInspectionFilter = {
  id?: Maybe<IdFilterComparison>;
  type?: Maybe<InspectionTypeFilterComparison>;
  inspectingAgentAilorn?: Maybe<InspectionInspectingAgentAilornFilterComparison>;
  startedAt?: Maybe<DateFieldComparison>;
  completedAt?: Maybe<DateFieldComparison>;
};

export type InspectionAppointmentInspectingAgentAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type InspectionAppointmentMaxAggregate = {
  __typename?: "InspectionAppointmentMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  propertyAilorn?: Maybe<Scalars["AiloRN"]>;
  tenancyAilorn?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type InspectionAppointmentMinAggregate = {
  __typename?: "InspectionAppointmentMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  propertyAilorn?: Maybe<Scalars["AiloRN"]>;
  tenancyAilorn?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type InspectionAppointmentPropertyAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type InspectionAppointmentSort = {
  field: InspectionAppointmentSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum InspectionAppointmentSortFields {
  Id = "id",
  PropertyAilorn = "propertyAilorn",
  TenancyAilorn = "tenancyAilorn",
  Type = "type",
  InspectingAgentAilorn = "inspectingAgentAilorn",
  StartTime = "startTime"
}

export type InspectionAppointmentTenancyAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type InspectionAppointmentTypeFilterComparison = {
  eq?: Maybe<InspectionType>;
  in?: Maybe<Array<InspectionType>>;
  notIn?: Maybe<Array<InspectionType>>;
};

export type InspectionArea = {
  __typename?: "InspectionArea";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  index: Scalars["Int"];
  name: Scalars["String"];
  inspected: Scalars["Boolean"];
  notes?: Maybe<Scalars["String"]>;
  condition?: Maybe<InspectionAreaCondition>;
  inspection: Inspection;
  files: Array<InspectionAreaFile>;
  features: Array<InspectionFeature>;
};

export enum InspectionAreaCondition {
  Excellent = "Excellent",
  Good = "Good",
  Fair = "Fair",
  Poor = "Poor"
}

export type InspectionAreaFile = {
  __typename?: "InspectionAreaFile";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
  inspectionArea: InspectionArea;
  file: File;
};

export type InspectionConnection = {
  __typename?: "InspectionConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<InspectionEdge>;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type InspectionCountAggregate = {
  __typename?: "InspectionCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["Int"]>;
  inspectingAgentAilorn?: Maybe<Scalars["Int"]>;
  startedAt?: Maybe<Scalars["Int"]>;
  completedAt?: Maybe<Scalars["Int"]>;
};

export type InspectionEdge = {
  __typename?: "InspectionEdge";
  /** The node containing the Inspection */
  node: Inspection;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type InspectionFeature = {
  __typename?: "InspectionFeature";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  name: Scalars["String"];
  index: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  isClean?: Maybe<Scalars["Boolean"]>;
  isUndamaged?: Maybe<Scalars["Boolean"]>;
  isWorking?: Maybe<Scalars["Boolean"]>;
  inspectionArea: InspectionArea;
  files: Array<InspectionFeatureFile>;
};

export type InspectionFeatureFile = {
  __typename?: "InspectionFeatureFile";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
  inspectionFeature: InspectionFeature;
  file: File;
};

export type InspectionFilter = {
  id?: Maybe<IdFilterComparison>;
  type?: Maybe<InspectionTypeFilterComparison>;
  inspectingAgentAilorn?: Maybe<InspectionInspectingAgentAilornFilterComparison>;
  startedAt?: Maybe<DateFieldComparison>;
  completedAt?: Maybe<DateFieldComparison>;
};

export type InspectionInspectingAgentAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type InspectionMaxAggregate = {
  __typename?: "InspectionMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
};

export type InspectionMinAggregate = {
  __typename?: "InspectionMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
};

export type InspectionReportProgress = {
  __typename?: "InspectionReportProgress";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  fileAilorn?: Maybe<Scalars["AiloRN"]>;
  /** Report generation completion time */
  completedAt?: Maybe<Scalars["DateTime"]>;
  /** Report generation failure time */
  failedAt?: Maybe<Scalars["DateTime"]>;
  inspection: Inspection;
};

export type InspectionSort = {
  field: InspectionSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum InspectionSortFields {
  Id = "id",
  Type = "type",
  InspectingAgentAilorn = "inspectingAgentAilorn",
  StartedAt = "startedAt",
  CompletedAt = "completedAt"
}

export enum InspectionType {
  Ingoing = "Ingoing",
  Outgoing = "Outgoing",
  Routine = "Routine"
}

export type InspectionTypeFilterComparison = {
  eq?: Maybe<InspectionType>;
  in?: Maybe<Array<InspectionType>>;
  notIn?: Maybe<Array<InspectionType>>;
};

export type InternalSupplierInput = {
  name?: Maybe<Scalars["String"]>;
  registeredEntityName?: Maybe<Scalars["String"]>;
  organisationReference: Scalars["AiloRN"];
  internalReference: Scalars["AiloRN"];
};

export type IntFieldComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["Int"]>;
  neq?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
  between?: Maybe<IntFieldComparisonBetween>;
  notBetween?: Maybe<IntFieldComparisonBetween>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars["Int"];
  upper: Scalars["Int"];
};

export type InvestorOnAppData = {
  __typename?: "InvestorOnAppData";
  isPrimaryInvestor?: Maybe<Scalars["String"]>;
  legalEntityId?: Maybe<Scalars["String"]>;
  investorStatus?: Maybe<Scalars["String"]>;
  investorName?: Maybe<Scalars["String"]>;
  hasNotificationsEnabled?: Maybe<Scalars["String"]>;
  totalBillsDue?: Maybe<Scalars["String"]>;
  properties?: Maybe<Scalars["String"]>;
  onAppAt?: Maybe<Scalars["String"]>;
};

export type InvestorOnAppReport = {
  __typename?: "InvestorOnAppReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<InvestorOnAppData>>>;
};

export type Key = {
  __typename?: "Key";
  id: Scalars["ID"];
  code: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  property: Property;
  organisation: Organisation;
};

export type KeyAggregateGroupBy = {
  __typename?: "KeyAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  organisationAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type KeyCountAggregate = {
  __typename?: "KeyCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
  organisationAilorn?: Maybe<Scalars["Int"]>;
};

export type KeyDeleteResponse = {
  __typename?: "KeyDeleteResponse";
  id?: Maybe<Scalars["ID"]>;
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  property?: Maybe<Property>;
  organisation?: Maybe<Organisation>;
};

export type KeyEdge = {
  __typename?: "KeyEdge";
  /** The node containing the Key */
  node: Key;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type KeyFilter = {
  id?: Maybe<KeyIdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  organisationAilorn?: Maybe<KeyOrganisationAilornFilterComparison>;
};

export type KeyIdFilterComparison = {
  eq?: Maybe<Scalars["ID"]>;
};

export type KeyInput = {
  code: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  propertyAilorn: Scalars["AiloRN"];
  organisationAilorn: Scalars["AiloRN"];
};

export type KeyMaxAggregate = {
  __typename?: "KeyMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  organisationAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type KeyMinAggregate = {
  __typename?: "KeyMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  organisationAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type KeyOrganisationAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
};

export type KeySort = {
  field: KeySortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum KeySortFields {
  Id = "id",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  OrganisationAilorn = "organisationAilorn"
}

export type LeaseRenewalDetails = {
  id?: Maybe<Scalars["ID"]>;
  startDate: Scalars["Date"];
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
};

export type LeaseRenewalProjectMeta = {
  __typename?: "LeaseRenewalProjectMeta";
  management: Management;
  tenancy: Tenancy;
  tenancyAgreement?: Maybe<TenancyAgreement>;
  rent?: Maybe<Rent>;
};

/** @deprecated Use `BidirectionalPageInfo` instead when possible. */
export type LedgerBidirectionalPageInfo = {
  __typename?: "LedgerBidirectionalPageInfo";
  /** @deprecated Use `hasNext` instead. */
  hasMore: Scalars["Boolean"];
  hasNext: Scalars["Boolean"];
  hasPrevious: Scalars["Boolean"];
  nextCursor?: Maybe<Scalars["String"]>;
  previousCursor?: Maybe<Scalars["String"]>;
  total: Scalars["Int"];
};

export type LedgerEvent = {
  __typename?: "LedgerEvent";
  ailoRN: Scalars["AiloRN"];
  businessTransaction?: Maybe<BusinessTransaction>;
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  domainEventKey: Scalars["String"];
  entries?: Maybe<PaginatedEntries>;
  id: Scalars["ID"];
  interaction: GeneralLedgerInteraction;
  occurredAt: Scalars["DateTime"];
  owing?: Maybe<RecurringOwing>;
  paymentMethod?: Maybe<PaymentMethodCompanion>;
  reference: Scalars["AiloRN"];
  reversalDepth?: Maybe<Scalars["Int"]>;
  type: LedgerEventType;
};

export type LedgerEventEntriesArgs = {
  pagination?: PaginationParams;
};

export enum LedgerEventType {
  Cleared = "CLEARED",
  Failed = "FAILED",
  Initiated = "INITIATED",
  InitiatedAndCleared = "INITIATED_AND_CLEARED"
}

/** @deprecated Use `BidirectionalPageCursor` instead when possible. */
export type LedgerPageCursor = {
  cursor?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
  paginateBackward?: Maybe<Scalars["Boolean"]>;
  /** sort is not supported yet. */
  sort?: Maybe<Scalars["String"]>;
};

export type LegalEntity = {
  id: Scalars["ID"];
  ailoRN: Scalars["String"];
  eulaSignedAt?: Maybe<Scalars["DateTime"]>;
  organisationId: Scalars["ID"];
  currentUserPermissions: Array<Scalars["String"]>;
  timezone: Scalars["TimeZone"];
  emailAddress?: Maybe<Scalars["String"]>;
  /** Statements with subject = Legal Entity Ailorn */
  statements?: Maybe<PaginatedStatements>;
  tenancies?: Maybe<PaginatedTenancies>;
  managements?: Maybe<PaginatedManagements>;
  ownerships?: Maybe<PaginatedOwnerships>;
  tenantships?: Maybe<PaginatedTenantships>;
  contact?: Maybe<Contact>;
};

export type LegalEntityStatementsArgs = {
  filter: StatementFilter;
  cursor?: Maybe<StatementCursor>;
  sort?: Maybe<Array<StatementSort>>;
};

export type LegalEntityTenanciesArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type LegalEntityManagementsArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type LegalEntityOwnershipsArgs = {
  managingOrganisationId: Scalars["AiloRN"];
  pageCursor: PmPageCursor;
};

export type LegalEntityTenantshipsArgs = {
  managingOrganisationId: Scalars["AiloRN"];
  pageCursor: PmPageCursor;
};

export type LegalEntityContactArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type LegalEntityCompanion = {
  __typename?: "LegalEntityCompanion";
  id: Scalars["ID"];
  /** @deprecated Use `LegalEntityCompanion.ailoRNV2` instead. */
  ailoRN: Scalars["String"];
  ailoRNV2: Scalars["AiloRN"];
  eulaSignedAt?: Maybe<Scalars["DateTime"]>;
  organisation: Organisation;
  legalEntity: LegalEntity;
};

export type LegalEntityMembership = {
  __typename?: "LegalEntityMembership";
  member: Person;
  legalEntity: LegalEntity;
  role: LegalEntityRole;
};

export type LegalEntityPersonRole = Role & {
  __typename?: "LegalEntityPersonRole";
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
  legalEntity: LegalEntity;
  person: Person;
};

export type LegalEntityRole = Role & {
  __typename?: "LegalEntityRole";
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
};

export enum LegalEntityType {
  Company = "Company",
  Person = "Person"
}

export type Liability = {
  __typename?: "Liability";
  /**
   * Amount computed base on how many full cycle, how many Days into a partial cycle and how much are paid up to now for this liability.
   * e.g. Tenant occupied a $100/wk property for 2 days so far and paid $30.
   * The DailyRate of this property is $100/7 = $14.29 per day.
   * This field should be 2days * $14.29 - $30 = -$1.42 (i.e. Tenant overpaid $1.42 so far and not in arrears)
   */
  amountInArrears: Money;
  autoPaymentDetails?: Maybe<AutoPaymentDetails>;
  category: Scalars["String"];
  /**
   * A poorly named and designed timestamp representing a date compute base on whether payment for full cycles of liability has been made.
   * The time should always be 00am of a Date in liability's zone. This timestamp also require minus 1 sec treatment to be displayed as a Date that's accurate for the domain.
   * e.g. Tenant paid 1 full week Rent for this Mon-Sun, this field would be Monday 00am in `zone`. The actual Paid To Date should be Sunday.
   * @deprecated Use `paidToDate` instead
   */
  classicPaidTo?: Maybe<Scalars["DateTime"]>;
  consumerReference?: Maybe<Scalars["AiloRN"]>;
  /** The daily rent rate for the latest plan. */
  dailyRate: Money;
  /**
   * The number of days a tenant has stayed in a property and not paid anything for.
   * Eg. Tenant moves in 1/1/2021 and hasn't paid. Tenant is 1 day in arrears because they didn't pay for 1/1/2021.
   * If this tenant makes a payment for any amount, they will be 0 days in arrears, even if it doesn't cover a full day.
   */
  daysInArrears: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  /** the amount of whichever the not-fully-paid cycle (past, today or future). if no partial-paid period, this will be full amount of whichever cycle due-to-be-paid next. */
  dueAmount?: Maybe<Money>;
  /** the due date of whichever not-fully-paid cycle (past, today or future). if no partial-paid period, this will be full amount of whichever cycle due-to-be-paid next. */
  dueDate?: Maybe<Scalars["DateTime"]>;
  /**
   * Date compute base on how much is paid against this liability up til now.
   * Does not require minus 1 day or 1 sec treatment. The Date is in this Liability's `zone`.
   * e.g. Tenant paid $30 into this Mon-Sun (rent = $100/week). It is 2 days and a bit worth of Rent. This field should be this Tuesday (i.e paid for Monday and Tuesday inclusive)
   * e.g. Tenant paid nothing into this Mon-Sun, this field should be last Sunday.
   */
  effectivePaidToDate?: Maybe<Scalars["Date"]>;
  entries?: Maybe<PaginatedLiabilityEntries>;
  firstPaymentDate?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  lastPaymentDate?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Use `category` instead */
  liabilityType?: Maybe<Scalars["String"]>;
  linkedChildBusinessTransactions?: Maybe<Array<BusinessTransaction>>;
  management?: Maybe<Management>;
  /** the full amount of the next (as of today) rent cycle, regardless any rent payment */
  nextChargeAmount?: Maybe<Money>;
  /** the start date of the next (as of today) rent cycle, regardless any rent payment */
  nextChargeCreateDate?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Use `nextChargeDueDate`. they are the same, this one is poorly named. */
  nextChargeDate?: Maybe<Scalars["DateTime"]>;
  /** the due date of the next rent cycle, regardless any rent payment */
  nextChargeDueDate?: Maybe<Scalars["DateTime"]>;
  /** the due amount of the next (as of today) not-fully-paid rent cycle */
  nextDueAmount?: Maybe<Money>;
  /** the due date of the next (as of today) not-fully-paid rent cycle */
  nextDueDate?: Maybe<Scalars["DateTime"]>;
  /**
   * Poorly named field representing Amount computed base on how many full liability cycles owed and how much are paid up to now.
   * Produce wrong result for any Liability with Overlapping LiabilityPlans, as this field uses Liaiblity Entry sum.
   * @deprecated Use `overdueAmount` instead
   */
  outstandingBalance: Money;
  /**
   * Amount computed base on how many full liability cycles owed and how much are paid up to now.
   * e.g. Tenant occupied a $100/wk property for 2 days so far and paid $30. This field should be $100 - $30
   */
  overdueAmount: Money;
  /**
   * A poorly named and designed timestamp representing Effective Paid To Date, a Date compute base on how much is paid against this liability up til now.
   * The time should always be 00am of a Date in liability's zone. This timestamp also require minus 1 sec treatment to be displayed as a Date that's accurate for the domain.
   * e.g. Tenant paid $30 into this Mon-Sun (rent = $100/week). It is 2 days and a bit worth of Rent. This field will be 00am of Wed. The actual Effective Paid To Date should be Tuesday.
   * @deprecated Use `effectivePaidToDate` instead
   */
  paidTo?: Maybe<Scalars["DateTime"]>;
  /**
   * Date compute base on whether payment for full cycles of liability has been made.
   * Does not require minus 1 day or 1 sec treatment. The Date is in this Liability's `zone`.
   * e.g. Tenant paid 1 full week Rent for this Mon-Sun, this field should be this Sunday.
   * e.g. Tenant paid nothing for this Mon-Sun, this field should be last Sunday.
   */
  paidToDate?: Maybe<Scalars["Date"]>;
  paymentKey: Scalars["GeneratedKey"];
  paymentPlans?: Maybe<PaginatedLiabilityPaymentPlans>;
  providerReference?: Maybe<Scalars["AiloRN"]>;
  reference: Scalars["String"];
  referencedEntity?: Maybe<LiabilityReferencedEntity>;
  relatingToManagement?: Maybe<Scalars["AiloRN"]>;
  status?: Maybe<LiabilityStatus>;
  taxCategory: Scalars["String"];
  /**
   * If this liability has a defined termination date, what is the Amount owed from now to that termination day (incl. the termination day itself)
   * Null when there's no termination date known for the liability.
   * e.g. as of today, a Tenant owes nothing and even over paid $30. The property will be terminated in 1 month and 12 days.
   * This field should calculate how much will be owed between now and 1mth12days base on rent schedule, and minus $30 overpayment.
   */
  terminationAmountDue?: Maybe<Money>;
  /** The inclusive date of the liability's termination. The value is null if liability has not been terminated. */
  terminationDate?: Maybe<Scalars["Date"]>;
  timeZoneName?: Maybe<Scalars["ZoneId"]>;
  totalAmountPaid: Money;
  recurringFee?: Maybe<RecurringFee>;
  owing?: Maybe<RecurringOwing>;
};

export type LiabilityEntriesArgs = {
  cursor?: Maybe<BidirectionalPageCursor>;
  filterTypes?: Maybe<Array<SourceType>>;
  sort?: Maybe<LiabilityEntrySort>;
};

export type LiabilityPaymentPlansArgs = {
  enabled: Scalars["Boolean"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type LiabilityCalculation = {
  __typename?: "LiabilityCalculation";
  paidToDate: Scalars["Date"];
  timeZoneName: Scalars["ZoneId"];
};

export type LiabilityCalculationInput = {
  asOf: Scalars["Date"];
  liabilityId: Scalars["ID"];
};

export type LiabilityChangePreview = {
  __typename?: "LiabilityChangePreview";
  earliestStartDateTime: Scalars["DateTime"];
  effectivePaidToDate?: Maybe<Scalars["Date"]>;
  effectivePaidToDateWithProposedPlan?: Maybe<Scalars["Date"]>;
  mergedPlans: Array<Maybe<Plan>>;
  /** @deprecated Use `effectivePaidToDate` instead */
  paidToDate?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Use `effectivePaidToDateWithProposedPlan` instead */
  paidToDateWithProposedPlan?: Maybe<Scalars["DateTime"]>;
  requiredAdjustment: Money;
};

export type LiabilityEntry = {
  amount: Money;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<ActionInitiator>;
  /** The date of the entry to be shown to consumers. This is the latest of createdAt and effectiveAt. */
  displayDate: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  entryType: SourceType;
  id: Scalars["ID"];
  liability: Liability;
  /** @deprecated Use `liability` */
  liabilityId: Scalars["ID"];
  /** @deprecated Use status in PaymentLiabilityEntry businessTransaction */
  status?: Maybe<BusinessTxStatusEnum>;
};

export type LiabilityEntrySort = {
  field: LiabilityEntrySortField;
};

export enum LiabilityEntrySortField {
  DisplayDate = "DISPLAY_DATE",
  EffectiveAt = "EFFECTIVE_AT"
}

export type LiabilityPaymentPlan = {
  __typename?: "LiabilityPaymentPlan";
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  details: LiabilityPaymentPlanDetails;
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  liabilityId: Scalars["ID"];
  owner: Scalars["AiloRN"];
};

export type LiabilityPaymentPlanDetails = {
  __typename?: "LiabilityPaymentPlanDetails";
  amount: Money;
  anniversary: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  endDate?: Maybe<Scalars["Date"]>;
  frequency: QuartzPlanFrequency;
  id: Scalars["ID"];
  liabilityPaymentPlanId: Scalars["ID"];
  paymentMethod: PaymentMethod;
  paymentMethodId: Scalars["ID"];
  startDate: Scalars["Date"];
  timezoneId: Scalars["String"];
};

export enum LiabilityPlanFrequency {
  Daily = "DAILY",
  Fortnightly = "FORTNIGHTLY",
  Monthly = "MONTHLY",
  OnceOff = "ONCE_OFF",
  Weekly = "WEEKLY"
}

export type LiabilityPlanInput = {
  asOfDate?: Maybe<Scalars["DateTime"]>;
  liabilityId: Scalars["ID"];
  proposedPlans: Array<Maybe<ProposedPlan>>;
};

/**
 * There should be `RecurringFee` here as well,
 * but we temporarily do not support it.
 * (Should be done eventually with steps listed at https://github.com/ailohq/property-management-service/pull/241 )
 */
export type LiabilityReferencedEntity = Bill | Fee | Tenancy;

export type LiabilityReport = {
  __typename?: "LiabilityReport";
  reference: Scalars["String"];
  reportItems?: Maybe<Array<Maybe<LiabilityReportItem>>>;
};

export type LiabilityReportItem = {
  __typename?: "LiabilityReportItem";
  amount: Money;
  /** @deprecated Use `daysInArrearsV2` */
  daysInArrears: Scalars["Int"];
  daysInArrearsV2: Scalars["Int"];
  nextDueDate: Scalars["DateTime"];
  outstandingBalance: Money;
  /** @deprecated Use `paidToDateV2` */
  paidToDate: Scalars["DateTime"];
  paidToDateV2: Scalars["DateTime"];
  paymentDate: Scalars["DateTime"];
  /** @deprecated Use `rentArrearsV2` */
  rentArrears: Money;
  rentArrearsV2: Money;
};

export type LiabilityStatus = {
  __typename?: "LiabilityStatus";
  archivedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  id: Scalars["ID"];
  isArchived: Scalars["Boolean"];
  liabilityId: Scalars["ID"];
  payerKycCheckRequired: Scalars["Boolean"];
};

export type LineItem = {
  __typename?: "LineItem";
  id: Scalars["ID"];
  /** @deprecated Use `LineItem.id` instead */
  ailoRN: Scalars["AiloRN"];
  /** @deprecated Use `LineItem.taxInclusiveAmount` instead. */
  amount: Money;
  taxInclusiveAmount: Money;
  taxAmount: Money;
  description: Scalars["String"];
  isTax: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
};

export type LineItemInput = {
  /** Tax inclusive amount. */
  amount: MoneyInput;
  taxAmount?: Maybe<MoneyInput>;
  description: Scalars["String"];
  isTax: Scalars["Boolean"];
};

export type ListAccountsFilters = {
  accountOwnerReference: Scalars["AiloRN"];
  accountReferences?: Maybe<Array<Scalars["AiloRN"]>>;
  accountSubtypes?: Maybe<Array<AccountSubtype>>;
  accountTypes?: Maybe<Array<AccountType>>;
  books?: Maybe<Array<Book>>;
  /** Default: includeAccountsWithNonPositiveBalances = true */
  includeAccountsWithNonPositiveBalances?: Maybe<Scalars["Boolean"]>;
};

export type ListAccountsSortParams = {
  direction: SortDirection;
  field: AccountSortField;
};

export type ListAccountTagBalancesFilters = {
  accountOwnerReference: Scalars["AiloRN"];
  accountSubtypes: Array<AccountSubtype>;
  asOf?: Maybe<Scalars["DateTime"]>;
  book?: Maybe<Book>;
  tagValue?: Maybe<Scalars["String"]>;
};

export type ListEntriesFilters = {
  accountOwnerReference: Scalars["AiloRN"];
  /**
   * Filter to include only entries which have account's reference match NS Entities.
   * NS Entities don't have "ailo:" prefix. Usage example: [ "authz:legalentity", "bill:bill" ]
   */
  accountReferenceNSEntities?: Maybe<Array<Scalars["String"]>>;
  accountReferences?: Maybe<Array<Scalars["AiloRN"]>>;
  accountSubtypes?: Maybe<Array<AccountSubtype>>;
  accountTypes?: Maybe<Array<AccountType>>;
  books?: Maybe<Array<Book>>;
  /** Filter to include only entries which have created_at < createdBefore */
  createdBefore?: Maybe<Scalars["DateTime"]>;
  /** Filter to include only entries which have created_at >= createdFrom */
  createdFrom?: Maybe<Scalars["DateTime"]>;
  includeEmptyAccountBalance?: Maybe<Scalars["Boolean"]>;
};

export type ListEntriesSortParams = {
  direction: SortDirection;
  field: EntrySortField;
};

export type ListPurposesFilters = {
  payableBy?: Maybe<Scalars["AiloRN"]>;
  payableTo?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<Scalars["String"]>;
};

export type ListTransactionsFilters = {
  createdAfter?: Maybe<Scalars["DateTime"]>;
  createdBefore?: Maybe<Scalars["DateTime"]>;
  management?: Maybe<Scalars["AiloRN"]>;
  moneyHolder: Scalars["AiloRN"];
  type?: Maybe<Scalars["String"]>;
};

export type ListTransactionsSortParams = {
  direction: SortDirection;
  field: TransactionsSortField;
};

export type LocalDateFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["LocalDate"]>;
  neq?: Maybe<Scalars["LocalDate"]>;
  gt?: Maybe<Scalars["LocalDate"]>;
  gte?: Maybe<Scalars["LocalDate"]>;
  lt?: Maybe<Scalars["LocalDate"]>;
  lte?: Maybe<Scalars["LocalDate"]>;
  like?: Maybe<Scalars["LocalDate"]>;
  notLike?: Maybe<Scalars["LocalDate"]>;
  iLike?: Maybe<Scalars["LocalDate"]>;
  notILike?: Maybe<Scalars["LocalDate"]>;
  in?: Maybe<Array<Scalars["LocalDate"]>>;
  notIn?: Maybe<Array<Scalars["LocalDate"]>>;
};

export type LocalDateRangeCondition = {
  gt?: Maybe<Scalars["LocalDate"]>;
  gte?: Maybe<Scalars["LocalDate"]>;
  lt?: Maybe<Scalars["LocalDate"]>;
  lte?: Maybe<Scalars["LocalDate"]>;
};

export type LockTrustAccountInput = {
  lockedUpTo: Scalars["DateTime"];
  trustAccountAilorn: Scalars["AiloRN"];
};

export type LongFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["Long"]>;
  neq?: Maybe<Scalars["Long"]>;
  gt?: Maybe<Scalars["Long"]>;
  gte?: Maybe<Scalars["Long"]>;
  lt?: Maybe<Scalars["Long"]>;
  lte?: Maybe<Scalars["Long"]>;
  like?: Maybe<Scalars["Long"]>;
  notLike?: Maybe<Scalars["Long"]>;
  iLike?: Maybe<Scalars["Long"]>;
  notILike?: Maybe<Scalars["Long"]>;
  in?: Maybe<Array<Scalars["Long"]>>;
  notIn?: Maybe<Array<Scalars["Long"]>>;
};

export type Mail = {
  __typename?: "Mail";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  mailThreadAilorn: Scalars["AiloRN"];
  fromContact?: Maybe<Contact>;
  toContacts: Array<Maybe<Contact>>;
  attachmentFiles: Array<Maybe<File>>;
  externalId: Scalars["String"];
  externalSentAt: Scalars["DateTime"];
  fromEmailAddress: Scalars["String"];
  toEmailAddresses: Array<Scalars["String"]>;
  subject: Scalars["String"];
  htmlBody: Scalars["String"];
  plaintextBody: Scalars["String"];
  provider: MailboxProvider;
  directionDetails: MailDirectionDetails;
  externalClientUrl?: Maybe<Scalars["String"]>;
};

export type Mailbox = {
  __typename?: "Mailbox";
  emailAddress: Scalars["String"];
  externalStatus: ExternalMailboxStatus;
  externalStatusLastCheckedAt: Scalars["DateTime"];
  provider: MailboxProvider;
};

export type MailboxAccess = {
  __typename?: "MailboxAccess";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  contactWithAccess?: Maybe<Contact>;
  grantedAt: Scalars["DateTime"];
  mailbox: Mailbox;
};

export enum MailboxProvider {
  Gmail = "Gmail"
}

export enum MailDirection {
  Inbound = "Inbound",
  Outbound = "Outbound"
}

export type MailDirectionDetails =
  | InboundMailDirectionDetails
  | OutboundMailDirectionDetails;

export type ManageDepositActionMeta = {
  __typename?: "ManageDepositActionMeta";
  deposit?: Maybe<TenancyDeposit>;
};

export type Management = {
  __typename?: "Management";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  isDraft?: Maybe<Scalars["Boolean"]>;
  managingEntity?: Maybe<Company>;
  firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  owners?: Maybe<Array<Maybe<Ownership>>>;
  endDate?: Maybe<Scalars["Date"]>;
  createdAt: Scalars["DateTime"];
  migratedAt?: Maybe<Scalars["DateTime"]>;
  terminationReason?: Maybe<ManagementTerminationReason>;
  terminationNotes?: Maybe<Scalars["String"]>;
  endAbility: ManagementEndAbility;
  endReason?: Maybe<ManagementEndReason>;
  endNote?: Maybe<Scalars["String"]>;
  managementFolioAilorn: Scalars["AiloRN"];
  managementFolio: ManagementFolio;
  agencyProperty: AgencyProperty;
  /**
   * Tenancies with an end date before today's date,
   * Ordered by date range desc (the ones that have ended recently first).
   */
  formerTenancies?: Maybe<BidirectionalPaginatedTenancies>;
  /** Ordered by date range desc (the ones that have been voided recently first). */
  voidedTenancies?: Maybe<BidirectionalPaginatedTenancies>;
  /** Tenancy with a start date later than today */
  nextTenancy?: Maybe<Tenancy>;
  /** Tenancy with start date before or equal to today and closest to today's date */
  mostRecentTenancy?: Maybe<Tenancy>;
  /**
   * returns the first to exist of active, next or previous tenancy
   * in that order.
   */
  mostRelevantTenancy?: Maybe<Tenancy>;
  currentManagementAgreement?: Maybe<ManagementAgreement>;
  currentOrNextManagementAgreement?: Maybe<ManagementAgreement>;
  allManagementAgreements: Array<ManagementAgreement>;
  managementAgreements?: Maybe<PaginatedManagementAgreements>;
  currentOrNextManagementFeeSchedule?: Maybe<ManagementFeeSchedule>;
  fees?: Maybe<PaginatedFees>;
  recurringFees: Array<RecurringFee>;
  property: Property;
  files?: Maybe<PaginatedFiles>;
  note?: Maybe<ManagementNote>;
  startDate?: Maybe<Scalars["Date"]>;
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
  occupancyStatus?: Maybe<OccupancyStatus>;
  tenancies?: Maybe<PaginatedTenancies>;
  currentTenancy?: Maybe<Tenancy>;
  ownerships?: Maybe<PaginatedOwnerships>;
  team?: Maybe<Team>;
  /** Statements with subject = Mangement Ailorn */
  statements?: Maybe<PaginatedStatements>;
  projects?: Maybe<PaginatedProjects>;
  ownerContacts: Array<Contact>;
};

export type ManagementFormerTenanciesArgs = {
  pageCursor?: Maybe<TenancyCursor>;
};

export type ManagementVoidedTenanciesArgs = {
  pageCursor?: Maybe<TenancyCursor>;
};

export type ManagementFeesArgs = {
  filter?: Maybe<ManagementFeeFilter>;
  cursor?: Maybe<PageCursorWithoutSort>;
  sort?: Maybe<Array<FeeSort>>;
};

export type ManagementFilesArgs = {
  pageCursor?: PaginationParams;
};

export type ManagementOwnershipsArgs = {
  pageCursor?: PmPageCursor;
};

export type ManagementStatementsArgs = {
  filter: StatementFilter;
  cursor?: Maybe<StatementCursor>;
  sort?: Maybe<Array<StatementSort>>;
};

export type ManagementProjectsArgs = {
  pageCursor?: ProjectCursor;
};

export type ManagementAgreement = {
  __typename?: "ManagementAgreement";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  startDate?: Maybe<Scalars["String"]>;
  fixedTermEndDate?: Maybe<Scalars["String"]>;
  /** In ISO-8601 duration format */
  inspectionFrequency?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["Int"];
  modifiedBy: Scalars["Int"];
  managementFeeSchedules?: Maybe<PaginatedManagementFeeSchedules>;
};

export type ManagementAndTenancyInput = {
  propertyId?: Maybe<Scalars["ID"]>;
  property?: Maybe<PropertyInput>;
  managingEntityId: Scalars["ID"];
  management: ManagementSetupInput;
  tenancy?: Maybe<TenancySetupInput>;
};

export enum ManagementCannotBeEndedProblem {
  TenancyEndDateMissing = "TenancyEndDateMissing"
}

export type ManagementCursor = {
  cursor?: Maybe<Scalars["String"]>;
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  sort?: Maybe<ManagementSortParams>;
};

export type ManagementEndAbility = {
  __typename?: "ManagementEndAbility";
  canBeEnded: Scalars["Boolean"];
  problem?: Maybe<ManagementCannotBeEndedProblem>;
};

export type ManagementEndCause = {
  __typename?: "ManagementEndCause";
  code: ManagementEndCauseCode;
  label: Scalars["String"];
};

export enum ManagementEndCauseCode {
  Fees = "Fees",
  LevelOfService = "LevelOfService",
  Communication = "Communication",
  ChangingTeamMembers = "ChangingTeamMembers",
  Other = "Other"
}

export type ManagementEndReason = {
  __typename?: "ManagementEndReason";
  code: ManagementEndReasonCode;
  label: Scalars["String"];
  causes: Array<ManagementEndCause>;
};

export enum ManagementEndReasonCode {
  LostToCompetitor = "LostToCompetitor",
  LostToOwnerSelfManaging = "LostToOwnerSelfManaging",
  OwnerOrFamilyMovedIn = "OwnerOrFamilyMovedIn",
  ManagementTerminatedByOurAgency = "ManagementTerminatedByOurAgency",
  ManagementSoldToAnotherAgency = "ManagementSoldToAnotherAgency",
  PropertyRenovations = "PropertyRenovations",
  PropertySaleByOurAgency = "PropertySaleByOurAgency",
  PropertySaleByAnotherAgency = "PropertySaleByAnotherAgency",
  PropertySaleByOwner = "PropertySaleByOwner",
  Other = "Other",
  Offboarded = "Offboarded"
}

export type ManagementFeeBlueprint = {
  __typename?: "ManagementFeeBlueprint";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  feeBlueprint: FeeBlueprint;
  management: Management;
  fixedAmount?: Maybe<Money>;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  chargeType: FeeBlueprintChargeType;
  createdAt: Scalars["DateTime"];
  createdBy: ActionInitiator;
  modifiedAt?: Maybe<Scalars["DateTime"]>;
  modifiedBy?: Maybe<ActionInitiator>;
  archived: Scalars["Boolean"];
  archivedAt?: Maybe<Scalars["DateTime"]>;
  archivedBy?: Maybe<ActionInitiator>;
  description?: Maybe<Scalars["String"]>;
};

export type ManagementFeeBlueprintsQueryConditions = {
  managementId?: Maybe<Scalars["ID"]>;
  feeBlueprintId?: Maybe<Scalars["ID"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<FeeBlueprintType>;
};

export type ManagementFeeFilter = {
  type?: Maybe<Array<FeeType>>;
  appliedToAiloRN?: Maybe<Array<Scalars["AiloRN"]>>;
  status?: Maybe<Array<FeeStatus>>;
};

export type ManagementFeeSchedule = {
  __typename?: "ManagementFeeSchedule";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  /**
   * Percentage of rent that will deduce the fee amount.
   * In range 0 - 1.
   */
  percent: Scalars["Float"];
  startDate: Scalars["String"];
  management: Management;
  managementAgreement: ManagementAgreement;
};

export type ManagementFilterAddressSimilarityInput = {
  search: Scalars["String"];
  /**
   * Threshold from 0 to 1 for how similar returned results must be
   * 0: All results
   * 1: Extremely similar
   */
  threshold?: Scalars["Float"];
};

export type ManagementFilterParams = {
  name: ManagementFilterTypes;
  args?: Maybe<MgmtFilterArgInput>;
};

export enum ManagementFilterTypes {
  HasActiveTenancyInArrears = "HasActiveTenancyInArrears",
  HasActiveTenancyInArrearsFromOwing = "HasActiveTenancyInArrearsFromOwing",
  HasOverdueTenancy = "HasOverdueTenancy",
  HasOverdueTenancyFromOwing = "HasOverdueTenancyFromOwing",
  HasActiveTenancy = "HasActiveTenancy",
  HasTenancyUpForRentReviewOrLeaseRenewal = "HasTenancyUpForRentReviewOrLeaseRenewal",
  /**
   * enum BelongToTeams will eventually be deprecated,
   * use BelongToTeamsAndUnassigned instead
   */
  BelongToTeams = "BelongToTeams",
  BelongToTeamsAndUnassigned = "BelongToTeamsAndUnassigned",
  Active = "Active",
  DefaultPropertyList = "DefaultPropertyList",
  Terminated = "Terminated",
  HasOccupancyStatus = "HasOccupancyStatus",
  AddressSimilarity = "AddressSimilarity",
  ExcludeLost = "ExcludeLost"
}

export type ManagementFolio = {
  __typename?: "ManagementFolio";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  managingEntity?: Maybe<Company>;
  owners: Array<ManagementFolioOwnership>;
  /**
   * All managements including current and former ones that were previously in this folio before they were moved to a different folio.
   * The same management can appear multiple times in this list if it was moved out, back in and out again.
   */
  allManagements: PaginatedManagementFolioToManagements;
  /**
   * Managements that were previously in this folio before they were moved to a different folio.
   * The same management can appear multiple times in this list if it was moved out, back in and out again.
   * @deprecated Use allManagements instead
   */
  formerManagements: PaginatedManagementFolioToManagements;
  /**
   * Managements that are currently in this Management Folio.
   * @deprecated Use ManagementFolio.allManagements from common instead
   */
  managements: Array<Management>;
  wallet?: Maybe<Wallet>;
};

export type ManagementFolioAllManagementsArgs = {
  paginationParams?: PaginationParams;
  filter?: Maybe<ManagementFolioManagementFilter>;
};

export type ManagementFolioFormerManagementsArgs = {
  paginationParams?: PaginationParams;
};

export type ManagementFolioManagementFilter = {
  isCurrent?: Maybe<Scalars["Boolean"]>;
};

export type ManagementFolioOwnership = {
  __typename?: "ManagementFolioOwnership";
  managementFolio: ManagementFolio;
  owner?: Maybe<LegalEntity>;
  startDate: Scalars["LocalDate"];
  isPrimary: Scalars["Boolean"];
  consumerInvite?: Maybe<ConsumerInvite>;
};

export type ManagementFolioToManagement = {
  __typename?: "ManagementFolioToManagement";
  id: Scalars["ID"];
  management: Management;
  managementFolio: ManagementFolio;
  startDatetime: Scalars["DateTime"];
  endDatetime?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  modifiedAt: Scalars["DateTime"];
};

export type ManagementInput = {
  propertyId: Scalars["ID"];
  managingEntityId: Scalars["ID"];
};

export type ManagementNote = {
  __typename?: "ManagementNote";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  management: Management;
  note: Scalars["String"];
};

export type ManagementPropertyAddress = ContactPropertyAddress & {
  __typename?: "ManagementPropertyAddress";
  managementAilorn: Scalars["AiloRN"];
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  /** <unitStreetNumber> <streetName> */
  shortAddress: Scalars["String"];
  firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["Date"]>;
};

export type ManagementSetupInput = {
  startDate: Scalars["String"];
  fixedTermEndDate?: Maybe<Scalars["String"]>;
  /** In ISO-8601 duration format */
  inspectionFrequency?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  percent: Scalars["Float"];
  owners: Array<OwnershipSetupInput>;
};

export enum ManagementSortField {
  LiabilityPaidToDate = "LiabilityPaidToDate",
  TenancyOwingEffectivePaidToDate = "TenancyOwingEffectivePaidToDate",
  Address = "Address",
  LatestTenancyAgreementEndDate = "LatestTenancyAgreementEndDate",
  LatestTenancyAgreementStartDate = "LatestTenancyAgreementStartDate",
  ClassicPaidToDate = "ClassicPaidToDate",
  TenancyOwingClassicPaidToDate = "TenancyOwingClassicPaidToDate",
  OutstandingBalance = "OutstandingBalance",
  TenancyOwingOverdueAmount = "TenancyOwingOverdueAmount",
  TeamName = "TeamName",
  EndDate = "EndDate",
  CurrentTenancyScheduledRentReviewDate = "CurrentTenancyScheduledRentReviewDate",
  /** use with ManagementFilterTypes.AddressSimilarity */
  AddressSimilarity = "AddressSimilarity"
}

export type ManagementSortParams = {
  field: ManagementSortField;
  direction: SortDirection;
};

export enum ManagementTerminationReason {
  Lost = "Lost",
  OffBoarded = "OffBoarded"
}

/**
 * A valid message must contain either
 *  * Some non-whitespace text content; OR
 *  * 1 or more attached files.
 */
export type MessageInputV2 = {
  /** Text will be truncated to a length of 5000 characters. */
  text?: Maybe<Scalars["String"]>;
  /** A maximum number of 20 files is allowed. */
  fileAilorns: Array<Scalars["AiloRN"]>;
  sendEmailFromMailboxAccessAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type MessageSummaryV2 = {
  __typename?: "MessageSummaryV2";
  /**
   * The first portion of the message, truncated at 64 characters.
   * If the message contains only attachments, this will contain a text summary, e.g.
   * "sent 2 files".
   */
  snippet: Scalars["String"];
  attachmentCount: Scalars["Int"];
  sentAt: Scalars["DateTime"];
  sender: Contact;
};

export type MessageV2 = {
  __typename?: "MessageV2";
  ailorn: Scalars["AiloRN"];
  sender: Contact;
  sentAt: Scalars["DateTime"];
  text?: Maybe<Scalars["String"]>;
  files: Array<File>;
  mail?: Maybe<Mail>;
};

export type MgmtFilterArgInput = {
  BelongToTeams?: Maybe<Array<Scalars["AiloRN"]>>;
  BelongToTeamsAndUnassigned?: Maybe<BelongToTeamsArgInput>;
  HasOccupancyStatus?: Maybe<Array<OccupancyStatus>>;
  AddressSimilarity?: Maybe<ManagementFilterAddressSimilarityInput>;
};

export type MigratingManagement = {
  __typename?: "MigratingManagement";
  id: Scalars["ID"];
  organisation?: Maybe<Organisation>;
  legalEntity?: Maybe<LegalEntity>;
  trustManagementId: Scalars["String"];
  trustTenancyId?: Maybe<Scalars["String"]>;
  ailoManagement?: Maybe<Management>;
  ailoTenancy?: Maybe<Tenancy>;
  body: Scalars["JSON"];
  createdAt: Scalars["DateTime"];
  modifiedAt: Scalars["DateTime"];
  modifiedBy?: Maybe<ActionInitiator>;
  healthcheckRunAt?: Maybe<Scalars["DateTime"]>;
  portfolioName?: Maybe<Scalars["String"]>;
  status: MigratingManagementStatus;
  statusChangedAt: Scalars["DateTime"];
  statusChangedBy?: Maybe<ActionInitiator>;
  approvedBy?: Maybe<ActionInitiator>;
  approvedByDisplay?: Maybe<Scalars["String"]>;
  approvedAt?: Maybe<Scalars["DateTime"]>;
  migrationFailureReason?: Maybe<Scalars["String"]>;
  exclusionReason?: Maybe<Scalars["String"]>;
  exclusionNotes?: Maybe<Scalars["String"]>;
  hasVacatingTenancy: Scalars["Boolean"];
  migrateAsVacant: Scalars["Boolean"];
  errors?: Maybe<Scalars["JSON"]>;
  postApprovalTasks?: Maybe<Scalars["JSON"]>;
  batchRef?: Maybe<Scalars["String"]>;
  paidTo?: Maybe<Scalars["Date"]>;
  partialPaidCents?: Maybe<Scalars["String"]>;
  /** Simplified publish status for display to users, only relevant when status is Approved or InProgress */
  publishStatus?: Maybe<MigratingManagementPublishStatus>;
};

export enum MigratingManagementPublishStatus {
  Failed = "Failed",
  InProgress = "InProgress",
  Approved = "Approved"
}

export type MigratingManagementsFilters = {
  organisationId: Scalars["AiloRN"];
  addressSearch?: Maybe<Scalars["String"]>;
  status?: Maybe<Array<Scalars["String"]>>;
  portfolio?: Maybe<MigratingManagementsPortfolioFilter>;
  withErrors?: Maybe<Scalars["Boolean"]>;
};

export enum MigratingManagementSortField {
  Id = "Id",
  CreatedAt = "CreatedAt",
  ModifiedAt = "ModifiedAt",
  ApprovedAt = "ApprovedAt",
  Address = "Address",
  DataIssues = "DataIssues",
  Portfolio = "Portfolio",
  StatusChangedAt = "StatusChangedAt",
  PublishStatus = "PublishStatus",
  ExclusionReason = "ExclusionReason"
}

export type MigratingManagementSortParams = {
  field: MigratingManagementSortField;
  direction: SortDirection;
};

export type MigratingManagementsPortfolioFilter = {
  names: Array<Scalars["String"]>;
  includeUnassigned: Scalars["Boolean"];
};

export enum MigratingManagementStatus {
  Created = "Created",
  Ignored = "Ignored",
  Excluded = "Excluded",
  InProgress = "InProgress",
  Approved = "Approved",
  Imported = "Imported"
}

export enum MigrationDetailsRefreshStatus {
  Success = "Success",
  Failed = "Failed",
  InProgress = "InProgress"
}

export enum MigrationIssueKey {
  VacatingTenancy = "VacatingTenancy"
}

export enum MigrationTaskStatus {
  Ingesting = "Ingesting",
  Transforming = "Transforming",
  Success = "Success",
  Failed = "Failed"
}

export type Money = {
  __typename?: "Money";
  cents: Scalars["Long"];
};

export type MoneyInput = {
  cents: Scalars["Long"];
};

export type NewManagementProjectMeta = {
  __typename?: "NewManagementProjectMeta";
  management?: Maybe<Management>;
};

export type NewTenancyProjectMeta = {
  __typename?: "NewTenancyProjectMeta";
  management: Management;
  tenancy?: Maybe<Tenancy>;
};

export enum NotAbleToCancelReason {
  TenancyIsNotVacating = "TenancyIsNotVacating",
  TenancyEndDateIsInThePast = "TenancyEndDateIsInThePast",
  ManagementHasIngoingTenancy = "ManagementHasIngoingTenancy",
  AlreadyChargedForPeriodWithEndDate = "AlreadyChargedForPeriodWithEndDate",
  ManagementIsEnding = "ManagementIsEnding"
}

export type NotificationsData = {
  __typename?: "NotificationsData";
  legalEntityId?: Maybe<Scalars["String"]>;
  agencyId?: Maybe<Scalars["String"]>;
  tenancyId?: Maybe<Scalars["String"]>;
  personName?: Maybe<Scalars["String"]>;
  personId?: Maybe<Scalars["String"]>;
  personType?: Maybe<Scalars["String"]>;
  notificationChannel?: Maybe<Scalars["String"]>;
  notificationType?: Maybe<Scalars["String"]>;
  notificationDeliveryStatus?: Maybe<Scalars["String"]>;
  notificationTitle?: Maybe<Scalars["String"]>;
  sentAt?: Maybe<Scalars["String"]>;
  managementId?: Maybe<Scalars["String"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
};

export type NotificationsReport = {
  __typename?: "NotificationsReport";
  type: Scalars["String"];
  columns: Array<ReportColumn>;
  rows: Array<NotificationsData>;
};

export enum OccupancyStatus {
  Vacant = "Vacant",
  Occupied = "Occupied",
  Vacating = "Vacating"
}

export type OffsetPageInfo = {
  __typename?: "OffsetPageInfo";
  total: Scalars["Int"];
  hasMore: Scalars["Boolean"];
  nextOffset?: Maybe<Scalars["Int"]>;
};

export type OffsetPaginationInputV2 = {
  pageSize: Scalars["Int"];
  offset?: Maybe<Scalars["Int"]>;
};

export type OkResult = {
  __typename?: "OkResult";
  _ok: Scalars["Boolean"];
};

export type OnboardingTask = {
  __typename?: "OnboardingTask";
  id: OnboardingTaskId;
  description: Scalars["String"];
};

export enum OnboardingTaskId {
  CompleteAiloOverview = "CompleteAiloOverview",
  AddPaymentMethod = "AddPaymentMethod",
  VerifyPhoneNumber = "VerifyPhoneNumber",
  AcceptTermsOfService = "AcceptTermsOfService",
  AllowPushNotifications = "AllowPushNotifications",
  SetupAutoRentPayment = "SetupAutoRentPayment",
  GetToKnowAilo = "GetToKnowAilo",
  AcceptAgencyTermsOfService = "AcceptAgencyTermsOfService",
  PayTenancyDeposit = "PayTenancyDeposit"
}

export type Organisation = {
  __typename?: "Organisation";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  name: Scalars["String"];
  orgType: OrganisationType;
  createdAt: Scalars["DateTime"];
  modifiedAt?: Maybe<Scalars["DateTime"]>;
  members?: Maybe<PaginatedPeople>;
  legalEntities?: Maybe<Array<Maybe<LegalEntity>>>;
  memberships: Array<OrganisationMembership>;
  teams: Array<Team>;
  migrationDetails?: Maybe<OrganisationMigrationDetails>;
  hasActiveExternalTrustAccount: Scalars["Boolean"];
  migratingManagementPortfolioNames?: Maybe<OrganisationPortfolioNamesPayload>;
  availableFeatures: Array<PlatformFeature>;
  effectiveUserContact?: Maybe<Contact>;
  /** Returns only the external suppliers. */
  suppliers: PaginatedSuppliers;
  /** @deprecated Use `Organisation.suppliers` instead */
  externalSuppliers: PaginatedSuppliers;
};

export type OrganisationMembersArgs = {
  pageCursor?: Maybe<PageCursor>;
};

export type OrganisationTeamsArgs = {
  filters?: Array<TeamFilterParams>;
};

export type OrganisationMigratingManagementPortfolioNamesArgs = {
  status?: Maybe<MigratingManagementStatus>;
};

export type OrganisationSuppliersArgs = {
  cursor: BillDefaultPageCursor;
};

export type OrganisationExternalSuppliersArgs = {
  cursor: BillDefaultPageCursor;
};

export type OrganisationMembership = {
  __typename?: "OrganisationMembership";
  member: Person;
  organisation: Organisation;
  /** @deprecated Use `OrganisationMembership.member` instead. */
  person: Person;
  role: OrganisationRole;
};

export type OrganisationMigrationDetails = {
  __typename?: "OrganisationMigrationDetails";
  lastRefreshedAt?: Maybe<Scalars["DateTime"]>;
  refreshStatus?: Maybe<MigrationDetailsRefreshStatus>;
};

export type OrganisationPersonRole = Role & {
  __typename?: "OrganisationPersonRole";
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
  organisation: Organisation;
};

export type OrganisationPortfolioNamesPayload = {
  __typename?: "OrganisationPortfolioNamesPayload";
  hasUnassigned: Scalars["Boolean"];
  names: Array<Scalars["String"]>;
};

export type OrganisationRole = Role & {
  __typename?: "OrganisationRole";
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
};

export type OrganisationsFilter = {
  orgType?: Maybe<OrganisationType>;
};

export enum OrganisationType {
  Agency = "Agency",
  Generic = "Generic"
}

export enum OutboundMailDeliveryError {
  InvalidToAddress = "InvalidToAddress",
  MailboxFull = "MailboxFull",
  Unknown = "Unknown"
}

export enum OutboundMailDeliveryStatus {
  Sending = "Sending",
  Sent = "Sent",
  Error = "Error"
}

export type OutboundMailDirectionDetails = {
  __typename?: "OutboundMailDirectionDetails";
  direction: MailDirection;
  outboundDeliveryStatus: OutboundMailDeliveryStatus;
  outboundDeliveryStatusUpdatedAt: Scalars["DateTime"];
  outboundDeliveryError?: Maybe<OutboundMailDeliveryError>;
};

export type OverdraftAllowance = {
  __typename?: "OverdraftAllowance";
  amount: Money;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  endAt: Scalars["DateTime"];
  id: Scalars["ID"];
  startAt: Scalars["DateTime"];
  type: Scalars["String"];
  walletId: Scalars["ID"];
};

export type OverdraftAllowanceInput = {
  amount: MoneyInput;
  endAt: Scalars["DateTime"];
  startAt: Scalars["DateTime"];
  walletId: Scalars["ID"];
};

export type OwingProgress = {
  __typename?: "OwingProgress";
  /** arrears representing all not fully paid days up to and including today  */
  arrears: Arrears;
  /** the earliest not fully paid charge  */
  due?: Maybe<Charge>;
  /** cumulative charge representing total remaining amount to be paid  */
  endOfOwing?: Maybe<CumulativeCharge>;
  firstPaidAt?: Maybe<Scalars["DateTime"]>;
  lastPaidAt?: Maybe<Scalars["DateTime"]>;
  /** next (from today) charge regardless of whether it is paid or not  */
  nextCharge?: Maybe<Charge>;
  /** the next (from today) not fully paid charge  */
  nextDue?: Maybe<Charge>;
  /** cumulative charge representing all not fully paid cycles that are due up to today  */
  overdue: CumulativeCharge;
  paidTo: PaidTo;
  /** The total amount of money paid towards this owing. This does not include adjustments */
  totalPaid: Money;
};

export type OwingReferencedEntity = RecurringFee | Tenancy;

export type OwingSummary = {
  __typename?: "OwingSummary";
  chargeCycle: UnpersistedChargeCycle;
  chargeRates?: Maybe<Array<Maybe<Scalars["String"]>>>;
  firstChargeDate: Scalars["Date"];
  frequency: RecurringFrequency;
};

export type Ownership = {
  __typename?: "Ownership";
  managementId: Scalars["ID"];
  owner?: Maybe<LegalEntity>;
  startDate: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  sharesOwned: Scalars["Int"];
  sharesRatio: Scalars["Float"];
  /**
   * Total shares owned by all ownerships of the management
   * @deprecated Use `Management.sharesRatio` or `Management.sharesOwned` instead.
   */
  totalShares: Scalars["Int"];
  management: Management;
  ownerId: Scalars["ID"];
};

export type OwnershipSetupInput = {
  ownerId?: Maybe<Scalars["ID"]>;
  sharesOwned: Scalars["Int"];
  consumer?: Maybe<ConsumerSetupInput>;
};

export type PageCursor = {
  pageSize?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["String"]>;
};

export type PageCursorWithoutSort = {
  pageSize?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  total: Scalars["Int"];
  hasMore: Scalars["Boolean"];
  nextCursor?: Maybe<Scalars["String"]>;
};

export type PaginatedAccounts = {
  __typename?: "PaginatedAccounts";
  items: Array<Maybe<Account>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedAccountTagBalances = {
  __typename?: "PaginatedAccountTagBalances";
  items: Array<Maybe<AccountTagBalance>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedActions = {
  __typename?: "PaginatedActions";
  pageInfo: PageInfo;
  items: Array<Action>;
};

export type PaginatedAgencyProperties = {
  __typename?: "PaginatedAgencyProperties";
  pageInfo: BidirectionalPageInfo;
  items: Array<AgencyProperty>;
};

export type PaginatedAgencyPropertySearchItems = {
  __typename?: "PaginatedAgencyPropertySearchItems";
  pageInfo: BidirectionalPageInfo;
  items: Array<AgencyProperty>;
};

export type PaginatedAutoWithdrawPlans = {
  __typename?: "PaginatedAutoWithdrawPlans";
  items?: Maybe<Array<AutoWithdrawPlan>>;
  pageInfo: PageInfo;
};

export type PaginatedBills = {
  __typename?: "PaginatedBills";
  pageInfo: BidirectionalPageInfo;
  items: Array<Bill>;
};

export type PaginatedChargeCycles = {
  __typename?: "PaginatedChargeCycles";
  items: Array<Maybe<ChargeCycle>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedChatsInputV2 = {
  organisationAilorn: Scalars["AiloRN"];
  pagination: OffsetPaginationInputV2;
  /**
   * All chats returned must have at least one of the following
   * contacts as a participant.
   */
  mustIncludeOneOf?: Maybe<Array<ContactOrEmail>>;
  /**
   * All chats returned must have all of the following contacts
   * as participants.
   */
  mustIncludeAllOf?: Maybe<Array<ContactOrEmail>>;
  /**
   * All chats returned must have all of, and only, the following contacts
   * as participants.
   */
  mustIncludeExactly?: Maybe<Array<ContactOrEmail>>;
};

export type PaginatedChatsV2 = {
  __typename?: "PaginatedChatsV2";
  offsetPageInfo: OffsetPageInfo;
  items: Array<ChatV2>;
};

export type PaginatedContacts = {
  __typename?: "PaginatedContacts";
  pageInfo: BidirectionalPageInfo;
  items: Array<Contact>;
};

export type PaginatedContactSearchItems = {
  __typename?: "PaginatedContactSearchItems";
  pageInfo: BidirectionalPageInfo;
  items: Array<Contact>;
};

export type PaginatedEntries = {
  __typename?: "PaginatedEntries";
  items: Array<Maybe<Entry>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedFeeBlueprints = {
  __typename?: "PaginatedFeeBlueprints";
  pageInfo: PageInfo;
  items: Array<FeeBlueprint>;
};

export type PaginatedFees = {
  __typename?: "PaginatedFees";
  pageInfo: PageInfo;
  items: Array<Fee>;
};

export type PaginatedFiles = {
  __typename?: "PaginatedFiles";
  pageInfo: BidirectionalPageInfo;
  items: Array<Maybe<File>>;
};

export type PaginatedLiabilityEntries = {
  __typename?: "PaginatedLiabilityEntries";
  items: Array<LiabilityEntry>;
  pageInfo: LedgerBidirectionalPageInfo;
};

export type PaginatedLiabilityPaymentPlans = {
  __typename?: "PaginatedLiabilityPaymentPlans";
  items?: Maybe<Array<LiabilityPaymentPlan>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaginatedLineItems = {
  __typename?: "PaginatedLineItems";
  pageInfo: PageInfo;
  items: Array<LineItem>;
};

export type PaginatedManagementAgreements = {
  __typename?: "PaginatedManagementAgreements";
  pageInfo: PageInfo;
  items: Array<ManagementAgreement>;
};

export type PaginatedManagementFeeBlueprints = {
  __typename?: "PaginatedManagementFeeBlueprints";
  pageInfo: PageInfo;
  items: Array<ManagementFeeBlueprint>;
};

export type PaginatedManagementFeeSchedules = {
  __typename?: "PaginatedManagementFeeSchedules";
  pageInfo: PageInfo;
  items: Array<Maybe<ManagementFeeSchedule>>;
};

export type PaginatedManagementFolioOwnerships = {
  __typename?: "PaginatedManagementFolioOwnerships";
  pageInfo: PageInfo;
  items: Array<ManagementFolioOwnership>;
};

export type PaginatedManagementFolios = {
  __typename?: "PaginatedManagementFolios";
  pageInfo: PageInfo;
  items: Array<ManagementFolio>;
};

export type PaginatedManagementFolioToManagements = {
  __typename?: "PaginatedManagementFolioToManagements";
  pageInfo: PageInfo;
  items: Array<ManagementFolioToManagement>;
};

export type PaginatedManagements = {
  __typename?: "PaginatedManagements";
  pageInfo: PageInfo;
  items: Array<Management>;
};

export type PaginatedMessagesV2 = {
  __typename?: "PaginatedMessagesV2";
  pageInfo: PageInfo;
  items: Array<Maybe<MessageV2>>;
};

export type PaginatedMigratingManagements = {
  __typename?: "PaginatedMigratingManagements";
  pageInfo: BidirectionalPageInfo;
  items: Array<Maybe<MigratingManagement>>;
};

export type PaginatedOrganisations = {
  __typename?: "PaginatedOrganisations";
  pageInfo: PageInfo;
  items: Array<Maybe<Organisation>>;
};

export type PaginatedOwnerships = {
  __typename?: "PaginatedOwnerships";
  pageInfo: PageInfo;
  items: Array<Ownership>;
};

export type PaginatedPaymentMethods = {
  __typename?: "PaginatedPaymentMethods";
  items: Array<Maybe<PaymentMethod>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaginatedPaymentReferences = {
  __typename?: "PaginatedPaymentReferences";
  pageInfo: PageInfo;
  items: Array<Maybe<PaymentReference>>;
};

export type PaginatedPeople = {
  __typename?: "PaginatedPeople";
  pageInfo: PageInfo;
  items: Array<Person>;
};

export type PaginatedProjectFiles = {
  __typename?: "PaginatedProjectFiles";
  pageInfo: PageInfo;
  items: Array<ProjectFile>;
};

export type PaginatedProjects = {
  __typename?: "PaginatedProjects";
  pageInfo: BidirectionalPageInfo;
  items: Array<Project>;
};

export type PaginatedProperties = {
  __typename?: "PaginatedProperties";
  pageInfo: PageInfo;
  items: Array<Property>;
};

export type PaginatedReconciliationDetail = {
  __typename?: "PaginatedReconciliationDetail";
  items: Array<ReconciliationDetailReportItem>;
  pageInfo: LedgerBidirectionalPageInfo;
};

export type PaginatedRecurringFeeBlueprints = {
  __typename?: "PaginatedRecurringFeeBlueprints";
  pageInfo: PageInfo;
  items: Array<RecurringFeeBlueprint>;
};

export type PaginatedRecurringOwingEvents = {
  __typename?: "PaginatedRecurringOwingEvents";
  items: Array<Maybe<RecurringOwingEvent>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedRents = {
  __typename?: "PaginatedRents";
  pageInfo: PageInfo;
  items: Array<Rent>;
};

export type PaginatedStatementProgresses = {
  __typename?: "PaginatedStatementProgresses";
  pageInfo: BidirectionalPageInfo;
  items: Array<Maybe<StatementProgress>>;
};

export type PaginatedStatements = {
  __typename?: "PaginatedStatements";
  pageInfo: BidirectionalPageInfo;
  items: Array<Statement>;
};

export type PaginatedStatementTransactionLineItem = {
  __typename?: "PaginatedStatementTransactionLineItem";
  items: Array<StatementTransactionLineItem>;
  pageInfo: LedgerBidirectionalPageInfo;
};

export type PaginatedSuppliers = {
  __typename?: "PaginatedSuppliers";
  pageInfo: PageInfo;
  items: Array<Supplier>;
};

export type PaginatedTenancies = {
  __typename?: "PaginatedTenancies";
  pageInfo: PageInfo;
  items: Array<Tenancy>;
};

export type PaginatedTenancyAgreements = {
  __typename?: "PaginatedTenancyAgreements";
  pageInfo: PageInfo;
  items: Array<Maybe<TenancyAgreement>>;
};

export type PaginatedTenancySearchResultItems = {
  __typename?: "PaginatedTenancySearchResultItems";
  pageInfo: BidirectionalPageInfo;
  items: Array<TenancySearchResult>;
};

export type PaginatedTenantships = {
  __typename?: "PaginatedTenantships";
  pageInfo: PageInfo;
  items: Array<Tenantship>;
};

export type PaginatedThreadsV2 = {
  __typename?: "PaginatedThreadsV2";
  pageInfo: PageInfo;
  items: Array<Maybe<ThreadV2>>;
};

export type PaginatedTransactionReport = {
  __typename?: "PaginatedTransactionReport";
  items?: Maybe<Array<Maybe<TransactionReportItem>>>;
  pageInfo?: Maybe<LedgerBidirectionalPageInfo>;
};

export type PaginatedTransactions = {
  __typename?: "PaginatedTransactions";
  items: Array<Maybe<Transaction>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedTrustAccountWalletSearchItems = {
  __typename?: "PaginatedTrustAccountWalletSearchItems";
  pageInfo: BidirectionalPageInfo;
  items: Array<TrustAccountWallet>;
};

export type PaginatedUsers = {
  __typename?: "PaginatedUsers";
  pageInfo: PageInfo;
  items: Array<Maybe<User>>;
};

export type PaginatedWalletEntriesWithBtStatus = {
  __typename?: "PaginatedWalletEntriesWithBtStatus";
  items: Array<WalletEntry>;
  pageInfo: LedgerBidirectionalPageInfo;
};

export type PaginationInputV2 = {
  pageSize: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
};

export type PaginationParams = {
  cursor?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
  /** If true, will return items before the cursor, rather than after it. */
  before?: Maybe<Scalars["Boolean"]>;
};

export type PaginationParamsWithPageNumber = {
  cursor?: Maybe<Scalars["String"]>;
  /** Current page number (positive value) */
  page?: Maybe<Scalars["Int"]>;
  /** Number of items per page */
  pageSize?: Maybe<Scalars["Int"]>;
  /** If true, will return items before the cursor, rather than after it. */
  before?: Maybe<Scalars["Boolean"]>;
};

export type PaidTo = {
  __typename?: "PaidTo";
  classic: PaidToDate;
  effective: PaidToDate;
};

export type PaidToDate = {
  __typename?: "PaidToDate";
  date: Scalars["Date"];
  partPayment: Money;
};

export type PaidToDateDetails = {
  __typename?: "PaidToDateDetails";
  amountInArrearsBeforeEntry: Money;
  daysInArrearsBeforeEntry: Scalars["Int"];
  effectivePaidToDate: Scalars["Date"];
  effectivePaidToDateBeforeEntry: Scalars["Date"];
  effectivePaidToDateCredit: Money;
  paidToDate: Scalars["Date"];
  paidToDateBeforeEntry: Scalars["Date"];
  paidToDateCredit: Money;
};

export enum PaidUsingType {
  AiloWallet = "AILO_WALLET",
  EftIntoTrustAccount = "EFT_INTO_TRUST_ACCOUNT",
  PaymentMethod = "PAYMENT_METHOD"
}

export type PayerBillsSummary = {
  __typename?: "PayerBillsSummary";
  payer: Scalars["AiloRN"];
  /** Total due amount for all approved bills, excluding management fee bills. */
  outstandingBillsBalance: Money;
};

export type PayFromWalletInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  idempotencyKey: Scalars["String"];
  purposeId: Scalars["ID"];
  walletOwner: Scalars["AiloRN"];
};

export type PaymentAllocationInput = {
  amount: MoneyInput;
  description?: Maybe<Scalars["String"]>;
  purposeId: Scalars["ID"];
};

export type PaymentLiabilityEntry = LiabilityEntry & {
  __typename?: "PaymentLiabilityEntry";
  amount: Money;
  businessTransaction: BusinessTransaction;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<ActionInitiator>;
  /** The date of the entry to be shown to consumers. This is the latest of createdAt and effectiveAt. */
  displayDate: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  entryType: SourceType;
  id: Scalars["ID"];
  liability: Liability;
  /** @deprecated Use `liability` */
  liabilityId: Scalars["ID"];
  /** @deprecated Use status in PaymentLiabilityEntry businessTransaction */
  status?: Maybe<BusinessTxStatusEnum>;
};

export type PaymentMethod = {
  ailoRN: Scalars["AiloRN"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  topUpFee?: Maybe<TransactionFee>;
  wallet: Wallet;
};

export type PaymentMethodAiloFeeFilterEntryInput = {
  active: Scalars["Boolean"];
  endDate?: Maybe<Scalars["Date"]>;
  paymentMethodType: Scalars["String"];
  startDate?: Maybe<Scalars["Date"]>;
};

export type PaymentMethodAiloFees = {
  __typename?: "PaymentMethodAiloFees";
  appliesToId?: Maybe<Scalars["String"]>;
  feeBasisPoints?: Maybe<Scalars["Int"]>;
  feeFlatCents?: Maybe<Scalars["Int"]>;
  feeName?: Maybe<Scalars["String"]>;
  filterEntryEffectiveFromInclusive?: Maybe<Scalars["Date"]>;
  filterEntryEffectiveToInclusive?: Maybe<Scalars["Date"]>;
  filterName?: Maybe<Scalars["String"]>;
  filterNote?: Maybe<Scalars["String"]>;
  filterType?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  methodCategory?: Maybe<Scalars["String"]>;
  methodType: Scalars["String"];
};

export type PaymentMethodAiloFeeWaiverInput = {
  filterEntries?: Maybe<Array<Maybe<PaymentMethodAiloFeeFilterEntryInput>>>;
  notes?: Maybe<Scalars["String"]>;
  ownerLegalEntity: Scalars["AiloRN"];
};

export type PaymentMethodCompanion = {
  __typename?: "PaymentMethodCompanion";
  id: Scalars["AiloRN"];
  paymentMethod: PaymentMethod;
};

export type PaymentMethodDestination = {
  __typename?: "PaymentMethodDestination";
  paymentMethod: PaymentMethod;
  paymentMethodId: Scalars["ID"];
  share: Scalars["Int"];
};

export type PaymentMethodDestinationInput = {
  paymentMethodId: Scalars["ID"];
  share: Scalars["Int"];
};

export type PaymentMethodSearchOptions = {
  externalId?: Maybe<Scalars["String"]>;
  includeDeleted?: Maybe<Scalars["Boolean"]>;
  includeHidden?: Maybe<Scalars["Boolean"]>;
  includeOnceOff?: Maybe<Scalars["Boolean"]>;
  ownerAiloRN?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<PaymentMethodType>;
};

export enum PaymentMethodType {
  BPay = "BPay",
  BankAccount = "BankAccount",
  CreditCard = "CreditCard",
  VirtualAccount = "VirtualAccount"
}

export type PaymentReference = {
  __typename?: "PaymentReference";
  id: Scalars["ID"];
  supplier: Supplier;
  /**
   * Always present if supplier is external, otherwise empty.
   * @deprecated Use `PaymentReference.supplierPaymentMethodCompanion.ailoRN` instead.
   */
  supplierPaymentMethodReference?: Maybe<Scalars["AiloRN"]>;
  /**
   * Only present if it's an external supplier.
   * If it's an internal supplier, the bill will be paid to supplier's Ailo wallet.
   */
  supplierPaymentMethodCompanion?: Maybe<PaymentMethodCompanion>;
  crn?: Maybe<Scalars["String"]>;
  paymentDescription?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
};

export type PaymentReferenceInput = {
  supplierId: Scalars["ID"];
  /** Required if supplier is external. */
  supplierPaymentMethodReference?: Maybe<Scalars["AiloRN"]>;
  crn?: Maybe<Scalars["String"]>;
  paymentDescription?: Maybe<Scalars["String"]>;
};

export type PaymentReferencesQueryConditions = {
  /** @deprecated Use `PaymentReferencesQueryConditions.payeeId` instead */
  supplierId?: Maybe<Scalars["ID"]>;
  payeeId?: Maybe<Scalars["AiloRN"]>;
  billPayerId?: Maybe<Scalars["AiloRN"]>;
};

export type Permission = {
  __typename?: "Permission";
  id: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type Person = LegalEntity & {
  __typename?: "Person";
  id: Scalars["ID"];
  ailoRN: Scalars["String"];
  eulaSignedAt?: Maybe<Scalars["DateTime"]>;
  organisationId: Scalars["ID"];
  organisation: Organisation;
  timezone: Scalars["TimeZone"];
  emailAddress?: Maybe<Scalars["String"]>;
  /**
   * Future email address that is currently in pending verification status.
   * Will be set to null once verification succeeds or expires.
   */
  pendingEmailAddress?: Maybe<Scalars["String"]>;
  phoneNo?: Maybe<Scalars["String"]>;
  /** Equal to `preferredName` if given, otherwise `legalFirstName`. */
  firstName: Scalars["String"];
  legalFirstName: Scalars["String"];
  legalMiddleName?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["Date"]>;
  photo?: Maybe<File>;
  /**
   * Has KYC (Know Your Customer) verification check has been done for this person?
   * It is required for them to be eligible to transfer money out of Ailo platform.
   */
  kycVerified: Scalars["Boolean"];
  user?: Maybe<User>;
  occupation?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
  taxFileNumber?: Maybe<Scalars["String"]>;
  signUpCompleted?: Maybe<Scalars["Boolean"]>;
  currentUserPermissions: Array<Scalars["String"]>;
  organisationMemberships: Array<OrganisationMembership>;
  /** Legal Entity Memberships in which this person is a member. */
  legalEntityMembershipsAsMember: Array<LegalEntityMembership>;
  /** @deprecated Use `Person.organisationMemberships`. */
  organisationRoles: Array<OrganisationPersonRole>;
  /** @deprecated Use `Person.legalEntityMembershipsAsMember`. */
  legalEntityRoles: Array<LegalEntityPersonRole>;
  /** Legal Entity Memberships in which this legal entity is the group of which the other people are members. */
  legalEntityMembershipsAsLegalEntity: Array<LegalEntityMembership>;
  phoneNoVerified: Scalars["Boolean"];
  /** Statements with subject = Legal Entity Ailorn */
  statements?: Maybe<PaginatedStatements>;
  tenancies?: Maybe<PaginatedTenancies>;
  managements?: Maybe<PaginatedManagements>;
  ownerships?: Maybe<PaginatedOwnerships>;
  tenantships?: Maybe<PaginatedTenantships>;
  /** this can go when payment gateway splits */
  allWalletsAvailableBalance: Money;
  allWalletsTotalBalance: Money;
  kycVerifiedAccordingToLedgerService?: Maybe<Scalars["Boolean"]>;
  paymentMethods?: Maybe<PaginatedPaymentMethods>;
  wallets: Array<Wallet>;
  reiTokensForOrg: Array<ReiToken>;
  reiTemplatesForOrg: Array<ReiTemplate>;
  contact?: Maybe<Contact>;
};

export type PersonStatementsArgs = {
  filter: StatementFilter;
  cursor?: Maybe<StatementCursor>;
  sort?: Maybe<Array<StatementSort>>;
};

export type PersonTenanciesArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type PersonManagementsArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type PersonOwnershipsArgs = {
  managingOrganisationId: Scalars["AiloRN"];
  pageCursor: PmPageCursor;
};

export type PersonTenantshipsArgs = {
  managingOrganisationId: Scalars["AiloRN"];
  pageCursor: PmPageCursor;
};

export type PersonPaymentMethodsArgs = {
  methodType?: Maybe<PaymentMethodType>;
};

export type PersonReiTokensForOrgArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PersonReiTemplatesForOrgArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PersonContactArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type Plan = {
  __typename?: "Plan";
  amount: Money;
  /** @deprecated No longer needed. PM service also got rid of it. */
  endDate?: Maybe<Scalars["DateTime"]>;
  frequency: QuartzPlanFrequency;
  isExisting?: Maybe<Scalars["Boolean"]>;
  startDate: Scalars["DateTime"];
};

export type PlanBasedLiabilityEntry = LiabilityEntry & {
  __typename?: "PlanBasedLiabilityEntry";
  amount: Money;
  /** @deprecated Use `paidToDate` instead */
  classicPaidTo: Scalars["Date"];
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<ActionInitiator>;
  cycleEndDate: Scalars["Date"];
  cycleStartDate: Scalars["Date"];
  /** The date of the entry to be shown to consumers. This is the latest of createdAt and effectiveAt. */
  displayDate: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  entryType: SourceType;
  frequency?: Maybe<LiabilityPlanFrequency>;
  id: Scalars["ID"];
  liability: Liability;
  /** @deprecated Use `liability` */
  liabilityId: Scalars["ID"];
  /** The date the liability has been paid to after this entry took place. */
  paidToDate: Scalars["Date"];
  /** @deprecated Irrelevant to plan based liability entry */
  status?: Maybe<BusinessTxStatusEnum>;
};

export type PlatformFeature = {
  __typename?: "PlatformFeature";
  id: PlatformFeatureId;
  description?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
};

export enum PlatformFeatureId {
  AgencyAccess = "AgencyAccess",
  AgencyAppPropertyBills = "AgencyAppPropertyBills",
  AgencyAppPropertyFees = "AgencyAppPropertyFees",
  AgencyAppSupplierCreate = "AgencyAppSupplierCreate",
  AgencyAppViewConsumersMoneySettings = "AgencyAppViewConsumersMoneySettings",
  AgencyIncomeTotals = "AgencyIncomeTotals",
  AgencyInternalSuppliers = "AgencyInternalSuppliers",
  AgencyTransferStatement = "AgencyTransferStatement",
  AgencyWalletManualWithdrawal = "AgencyWalletManualWithdrawal",
  AllowFeatureOverride = "AllowFeatureOverride",
  AnalyticsDashboard = "AnalyticsDashboard",
  ApplyNewFee = "ApplyNewFee",
  ArchiveBillAsAgent = "ArchiveBillAsAgent",
  ArrearsListFromOwing = "ArrearsListFromOwing",
  AuthorizedHelpUrl = "AuthorizedHelpUrl",
  AutoPayBillsAsInvestor = "AutoPayBillsAsInvestor",
  AutoPayRent = "AutoPayRent",
  AutoWalletWithdraw = "AutoWalletWithdraw",
  AutoWithdrawPlan = "AutoWithdrawPlan",
  BillAttachments = "BillAttachments",
  BillManagementFee = "BillManagementFee",
  Bills = "Bills",
  BondsReport = "BondsReport",
  BypassRentLimit = "BypassRentLimit",
  CancelRecurringFee = "CancelRecurringFee",
  Centrepay = "Centrepay",
  Chat = "Chat",
  ChatDetailsWeb = "ChatDetailsWeb",
  ConsumerAccess = "ConsumerAccess",
  ConsumerAppKycCheck = "ConsumerAppKycCheck",
  ConsumerAppUserEmailChange = "ConsumerAppUserEmailChange",
  ConsumerProfileProperties = "ConsumerProfileProperties",
  ContactsTab = "ContactsTab",
  ContactsTabCustomers = "ContactsTabCustomers",
  ContactsTabOtherContacts = "ContactsTabOtherContacts",
  CreateAndViewPropertyKeys = "CreateAndViewPropertyKeys",
  CreateGeneralProject = "CreateGeneralProject",
  CreateLeaseRenewalProject = "CreateLeaseRenewalProject",
  CreateNewManagementProject = "CreateNewManagementProject",
  CreateNewTenancyProject = "CreateNewTenancyProject",
  CreateRoutineInspectionProject = "CreateRoutineInspectionProject",
  CreateVacatingTenancyProject = "CreateVacatingTenancyProject",
  CubejsReporting = "CubejsReporting",
  Customers = "Customers",
  DeletePropertyKeys = "DeletePropertyKeys",
  Deposits = "Deposits",
  Disbursements = "Disbursements",
  DisplayFormsTab = "DisplayFormsTab",
  EditApplyNewFee = "EditApplyNewFee",
  EditManagementEnd = "EditManagementEnd",
  EditManagementFee = "EditManagementFee",
  EditPropertyKeys = "EditPropertyKeys",
  EditRecurringFee = "EditRecurringFee",
  EditTenancyBondClaim = "EditTenancyBondClaim",
  EnableBillAutoPayLimit = "EnableBillAutoPayLimit",
  EndManagement = "EndManagement",
  EventBasedFee = "EventBasedFee",
  ExpensesCard = "ExpensesCard",
  FeeBlueprints = "FeeBlueprints",
  FeeFreeRent = "FeeFreeRent",
  FileUpload = "FileUpload",
  FilterBillByTeam = "FilterBillByTeam",
  ForceFileDownloadFlag = "ForceFileDownloadFlag",
  InAppReview = "InAppReview",
  InactiveChatEnhancements = "InactiveChatEnhancements",
  InvestorAccess = "InvestorAccess",
  InvestorFormerTenanciesCard = "InvestorFormerTenanciesCard",
  InvestorInternalSuppliers = "InvestorInternalSuppliers",
  InvestorPropertyWallet = "InvestorPropertyWallet",
  InvestorRentTab = "InvestorRentTab",
  LedgerRemake = "LedgerRemake",
  LiabilityPaymentPlan = "LiabilityPaymentPlan",
  LpmaAccess = "LpmaAccess",
  MailboxLinking = "MailboxLinking",
  ManageExpenseForm = "ManageExpenseForm",
  ManagementFeeBlueprint = "ManagementFeeBlueprint",
  ManagementPortfolios = "ManagementPortfolios",
  MetabaseReporting = "MetabaseReporting",
  MobileTeamChatsFilter = "MobileTeamChatsFilter",
  MoveManagementToFolio = "MoveManagementToFolio",
  NewConsumerAppNav = "NewConsumerAppNav",
  NewLostManagementsList = "NewLostManagementsList",
  NotificationPriming = "NotificationPriming",
  Onboarding = "Onboarding",
  OnboardingTabInPmApp = "OnboardingTabInPMApp",
  OnboardingTabPublishProperty = "OnboardingTabPublishProperty",
  OnboardingTabRefresh = "OnboardingTabRefresh",
  OneOffFeeBlueprints = "OneOffFeeBlueprints",
  OverdueListFromOwing = "OverdueListFromOwing",
  OwnershipStatementBillAndFeeDescription = "OwnershipStatementBillAndFeeDescription",
  OwnershipStatementInclBeforeGst = "OwnershipStatementInclBeforeGst",
  OwnershipStatementShowNextRentScheduleForNewProperties = "OwnershipStatementShowNextRentScheduleForNewProperties",
  PmJoinThread = "PmJoinThread",
  Projects = "Projects",
  Properties = "Properties",
  PropertyIssuesMissingFees = "PropertyIssuesMissingFees",
  PropertySearchFromSearchService = "PropertySearchFromSearchService",
  Receipting = "Receipting",
  RemoveManagementEnd = "RemoveManagementEnd",
  ReportingModalFilter = "ReportingModalFilter",
  ReportingTeamFilter = "ReportingTeamFilter",
  RevenueReport = "RevenueReport",
  SplitJointOwnershipStatement = "SplitJointOwnershipStatement",
  StatementsAddedToManagementFiles = "StatementsAddedToManagementFiles",
  TenancyBondClaim = "TenancyBondClaim",
  TenancyBonds = "TenancyBonds",
  TenancyDetailsDragAndDrop = "TenancyDetailsDragAndDrop",
  TenancyLedgerArrearsInHeader = "TenancyLedgerArrearsInHeader",
  TenancyLedgerAvoidOverlappingRentPeriod = "TenancyLedgerAvoidOverlappingRentPeriod",
  TenancyLedgerHeadersUseLedger = "TenancyLedgerHeadersUseLedger",
  TenancyLedgerLineItemsUseLedger = "TenancyLedgerLineItemsUseLedger",
  TenancyLedgerStatementDownload = "TenancyLedgerStatementDownload",
  TenantAccess = "TenantAccess",
  TransactionFees = "TransactionFees",
  TransferBillsDueWarning = "TransferBillsDueWarning",
  TransferDeposits = "TransferDeposits",
  TransferSummaryStatement = "TransferSummaryStatement",
  TruncateProjectList = "TruncateProjectList",
  TrustAccounting = "TrustAccounting",
  TrustReconciliation = "TrustReconciliation",
  ViewAgencyWallet = "ViewAgencyWallet",
  ViewAgencyWalletTransactions = "ViewAgencyWalletTransactions",
  ViewBillAsAgent = "ViewBillAsAgent",
  VoidIngoingTenancy = "VoidIngoingTenancy",
  WalletAccess = "WalletAccess",
  WalletStatementUserFacingDescription = "WalletStatementUserFacingDescription",
  WelcomeMessageParticipants = "WelcomeMessageParticipants",
  ZendeskWidget = "ZendeskWidget",
  ZendeskWidgetRestrictedForms = "ZendeskWidgetRestrictedForms"
}

export type PlatformPlan = {
  __typename?: "PlatformPlan";
  id: Scalars["String"];
  price: Money;
  product: PlatformProduct;
  features: Array<PlatformFeature>;
};

export type PlatformProduct = {
  __typename?: "PlatformProduct";
  id: Scalars["ID"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type PlatformSubscription = {
  __typename?: "PlatformSubscription";
  id: Scalars["ID"];
  plan: PlatformPlan;
  startsOn: Scalars["Date"];
  endsOn?: Maybe<Scalars["Date"]>;
  customer?: Maybe<Organisation>;
};

export enum PMeFeeTriggerType {
  FirstRentReceipt = "FirstRentReceipt",
  Manual = "Manual",
  FirstReceiptPerStatement = "FirstReceiptPerStatement",
  InvoiceReceipt = "InvoiceReceipt",
  InspectionOnRoutine = "InspectionOnRoutine",
  InspectionOnEntry = "InspectionOnEntry",
  InspectionOnExit = "InspectionOnExit",
  BillCreated = "BillCreated"
}

export enum PMeFeeValueType {
  Fixed = "Fixed",
  Percent = "Percent"
}

export type PmPageCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
  sort?: Maybe<Scalars["String"]>;
};

export type PreviewedRentSchedule = {
  __typename?: "PreviewedRentSchedule";
  amountInCents: Scalars["String"];
  period: RentFrequency;
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
  proRata: Scalars["Boolean"];
};

export type PreviewMultipleRentReviewsInput = {
  rentFrequency: QuartzPlanFrequency;
  rentReviewDetails: Array<RentReviewInput>;
};

export type ProblemInterface = {
  message: Scalars["String"];
};

export type Project = {
  __typename?: "Project";
  id: Scalars["ID"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  type: ProjectType;
  dueDate: Scalars["Date"];
  organisation?: Maybe<Organisation>;
  assignee: Person;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<User>;
  modifiedBy?: Maybe<User>;
  closedAt?: Maybe<Scalars["DateTime"]>;
  archivedAt?: Maybe<Scalars["DateTime"]>;
  status: ProjectStatus;
  reference: Scalars["String"];
  meta?: Maybe<ProjectMeta>;
  actions?: Maybe<PaginatedActions>;
  files?: Maybe<PaginatedProjectFiles>;
};

export type ProjectActionsArgs = {
  cursor?: ActionCursor;
};

export type ProjectFilesArgs = {
  cursor?: ProjectFileCursor;
};

export type ProjectCursor = {
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  cursor?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["String"]>;
};

export type ProjectFile = {
  __typename?: "ProjectFile";
  id: Scalars["ID"];
  file?: Maybe<File>;
  project: Project;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<User>;
};

export type ProjectFileCursor = {
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  cursor?: Maybe<Scalars["String"]>;
};

export type ProjectFilter = {
  status?: Maybe<StatusComparison>;
  assigneeAilorns?: Maybe<AssigneeAilornsComparison>;
  types?: Maybe<ProjectTypesComparison>;
};

export type ProjectMeta =
  | GeneralProjectMeta
  | LeaseRenewalProjectMeta
  | NewTenancyProjectMeta
  | NewManagementProjectMeta
  | VacatingTenancyProjectMeta
  | RoutineInspectionProjectMeta;

export enum ProjectStatus {
  Open = "Open",
  Closed = "Closed",
  Archived = "Archived"
}

export enum ProjectType {
  General = "General",
  LeaseRenewal = "LeaseRenewal",
  NewTenancy = "NewTenancy",
  NewManagement = "NewManagement",
  VacatingTenancy = "VacatingTenancy",
  RoutineInspection = "RoutineInspection"
}

export type ProjectTypesComparison = {
  in?: Maybe<Array<ProjectType>>;
};

export type PropertiesData = {
  __typename?: "PropertiesData";
  propertyId?: Maybe<Scalars["String"]>;
  managementId?: Maybe<Scalars["String"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  propertyOnAppAt?: Maybe<Scalars["String"]>;
  propertyPayingRentOnAiloSince?: Maybe<Scalars["String"]>;
  overdueRent?: Maybe<Scalars["String"]>;
  arrearsAmount?: Maybe<Scalars["String"]>;
  arrearsDays?: Maybe<Scalars["Int"]>;
  vacateDate?: Maybe<Scalars["String"]>;
  vacantDays?: Maybe<Scalars["Int"]>;
  totalWalletBalance?: Maybe<Scalars["String"]>;
  availableWalletBalance?: Maybe<Scalars["String"]>;
  lastRentPaymentInAilo?: Maybe<Scalars["String"]>;
  investorNames?: Maybe<Scalars["String"]>;
  renterNames?: Maybe<Scalars["String"]>;
  previousDisbursementDate?: Maybe<Scalars["String"]>;
  daysSincePreviousDisbursement?: Maybe<Scalars["Int"]>;
  leaseExpiryDate?: Maybe<Scalars["String"]>;
  daysSinceLeaseExpiry?: Maybe<Scalars["Int"]>;
  rentNextDueDate?: Maybe<Scalars["String"]>;
  tenancyPaidToDate?: Maybe<Scalars["String"]>;
  overdueDays?: Maybe<Scalars["Int"]>;
  investorInvitedAt?: Maybe<Scalars["String"]>;
  renterInvitedAt?: Maybe<Scalars["String"]>;
  scheduledRentReviewDate?: Maybe<Scalars["String"]>;
  rentAmount?: Maybe<Scalars["String"]>;
  rentPeriod?: Maybe<Scalars["String"]>;
};

export type PropertiesReport = {
  __typename?: "PropertiesReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<PropertiesData>>>;
};

export type Property = {
  __typename?: "Property";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  address: Address;
  /**
   * Returns all agency properties associated with this property.
   * Throws if requested without admin read permissions.
   */
  agencyProperties: Array<AgencyProperty>;
  keys: Array<Key>;
  inspections: Array<Inspection>;
  inspectionAppointments: Array<InspectionAppointment>;
};

export type PropertyKeysArgs = {
  filter?: Maybe<KeyFilter>;
  sorting?: Maybe<Array<KeySort>>;
};

export type PropertyInspectionsArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PropertyInspectionAppointmentsArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PropertyInput = {
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  postcode?: Maybe<Scalars["String"]>;
};

export type PropertyIssuesDrilldownData = {
  __typename?: "PropertyIssuesDrilldownData";
  propertyAddress?: Maybe<Scalars["String"]>;
  propertyOnAppAt?: Maybe<Scalars["String"]>;
  propertyPayingRentOnAiloSince?: Maybe<Scalars["String"]>;
  overdueRent?: Maybe<Scalars["String"]>;
  arrearsAmount?: Maybe<Scalars["String"]>;
  arrearsDays?: Maybe<Scalars["Int"]>;
  vacateDate?: Maybe<Scalars["String"]>;
};

export type PropertyIssuesDrilldownReport = {
  __typename?: "PropertyIssuesDrilldownReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<PropertyIssuesDrilldownData>>>;
};

export type PropertyIssuesHeaderFields = {
  __typename?: "PropertyIssuesHeaderFields";
  totalPropertyCount?: Maybe<Scalars["Int"]>;
  propertyWithIssueCount?: Maybe<Scalars["Int"]>;
  propertyWithIssuePercent?: Maybe<Scalars["String"]>;
};

export type PropertyIssuesMissingFeeData = {
  __typename?: "PropertyIssuesMissingFeeData";
  propertyId?: Maybe<Scalars["String"]>;
  managementId?: Maybe<Scalars["String"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  investorNames?: Maybe<Scalars["String"]>;
  feeType?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
  vacateDate?: Maybe<Scalars["String"]>;
};

export type PropertyIssuesMissingFeesReport = {
  __typename?: "PropertyIssuesMissingFeesReport";
  type: Scalars["String"];
  columns: Array<ReportColumn>;
  rows: Array<PropertyIssuesMissingFeeData>;
};

export type PropertyIssuesSummaryData = {
  __typename?: "PropertyIssuesSummaryData";
  propertyIssueId: Scalars["String"];
  propertyIssue: Scalars["String"];
  propertyCount: Scalars["Int"];
  propertyCountPercent: Scalars["String"];
  drilldownFields?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PropertyIssuesSummaryReport = {
  __typename?: "PropertyIssuesSummaryReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<PropertyIssuesSummaryData>>>;
  headerFields?: Maybe<PropertyIssuesHeaderFields>;
};

export type PropertyLiveData = {
  __typename?: "PropertyLiveData";
  teamId?: Maybe<Scalars["String"]>;
  teamName?: Maybe<Scalars["String"]>;
  propertyId?: Maybe<Scalars["String"]>;
  managementId?: Maybe<Scalars["String"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  investorNames?: Maybe<Scalars["String"]>;
  renterNames?: Maybe<Scalars["String"]>;
  propertyLiveAt?: Maybe<Scalars["String"]>;
};

export type PropertyLiveHeaderFields = {
  __typename?: "PropertyLiveHeaderFields";
  totalPropertyCount?: Maybe<Scalars["Int"]>;
  activePropertyCount?: Maybe<Scalars["Int"]>;
  pendingPropertyCount?: Maybe<Scalars["Int"]>;
  vacantPropertyCount?: Maybe<Scalars["Int"]>;
  activePropertyPercent?: Maybe<Scalars["String"]>;
};

export type PropertyLiveReport = {
  __typename?: "PropertyLiveReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<PropertyLiveData>>>;
  headerFields: PropertyLiveHeaderFields;
};

export type ProposedPlan = {
  amount: MoneyInput;
  desiredStartDate: Scalars["DateTime"];
  frequency: QuartzPlanFrequency;
};

export type ProposedRent = {
  amountInCents: Scalars["String"];
  period: RentFrequency;
  effectiveDate: Scalars["Date"];
  setsChargeDate: Scalars["Boolean"];
};

export type Purpose = {
  __typename?: "Purpose";
  id: Scalars["ID"];
  payable?: Maybe<PurposeReference>;
  payableBy: Scalars["AiloRN"];
  payableTo: Scalars["AiloRN"];
  reference: Scalars["AiloRN"];
  type: PurposeType;
};

export type PurposeReference = Bill | RecurringOwing | TrustAccountWallet;

export enum PurposeType {
  AccountTransfer = "ACCOUNT_TRANSFER",
  Bill = "BILL",
  Deposit = "DEPOSIT",
  Disbursement = "DISBURSEMENT",
  Fee = "FEE",
  NegativeWalletBalance = "NEGATIVE_WALLET_BALANCE",
  Rent = "RENT"
}

export type PushToken = {
  __typename?: "PushToken";
  id: Scalars["Int"];
  token: Scalars["String"];
  userArn: Scalars["AiloRN"];
  registeredAt: Scalars["DateTime"];
  projectId?: Maybe<Scalars["String"]>;
};

export enum QuartzPlanFrequency {
  Daily = "daily",
  Fortnightly = "fortnightly",
  Monthly = "monthly",
  Weekly = "weekly"
}

export type ReceiptFundsInput = {
  allocations: Array<PaymentAllocationInput>;
  idempotencyKey: Scalars["String"];
  paidByReference?: Maybe<Scalars["String"]>;
  paidUsing?: Maybe<PaidUsingType>;
  payerName?: Maybe<Scalars["String"]>;
  receiptDate: Scalars["Date"];
  relevantTrustAccountWallet: Scalars["AiloRN"];
  trustAccount: Scalars["AiloRN"];
};

export type ReconciliationDetailReportItem = {
  __typename?: "ReconciliationDetailReportItem";
  businessTransactionId: Scalars["AiloRN"];
  categoryId: Scalars["Int"];
  fees?: Maybe<Money>;
  id: Scalars["Int"];
  paymentTransactionId?: Maybe<Scalars["AiloRN"]>;
  paymentType?: Maybe<Scalars["String"]>;
  requested?: Maybe<Money>;
  total: Money;
  transactionType?: Maybe<Scalars["String"]>;
};

export type ReconciliationReportItem = {
  __typename?: "ReconciliationReportItem";
  count: Scalars["Long"];
  fees?: Maybe<Money>;
  id?: Maybe<Scalars["Int"]>;
  paymentType?: Maybe<Scalars["String"]>;
  requested?: Maybe<Money>;
  total: Money;
  transactionType?: Maybe<Scalars["String"]>;
};

export type ReconciliationReportPeriod = {
  __typename?: "ReconciliationReportPeriod";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  month: Scalars["Int"];
  year: Scalars["Int"];
  locked: Scalars["Boolean"];
  trustAccountAilorn: Scalars["AiloRN"];
  trustAccount: TrustAccount;
  legalEntity?: Maybe<Company>;
  lastAddedStatementBalance?: Maybe<TrustAccountStatementBalance>;
  canAlterLock: Scalars["Boolean"];
  adjustmentSumInCents: Scalars["Long"];
  isReconciling: Scalars["Boolean"];
};

export type ReconciliationReportPeriodConnection = {
  __typename?: "ReconciliationReportPeriodConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<ReconciliationReportPeriodEdge>;
};

export type ReconciliationReportPeriodEdge = {
  __typename?: "ReconciliationReportPeriodEdge";
  /** The node containing the ReconciliationReportPeriod */
  node: ReconciliationReportPeriod;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type ReconciliationReportPeriodFilter = {
  and?: Maybe<Array<ReconciliationReportPeriodFilter>>;
  or?: Maybe<Array<ReconciliationReportPeriodFilter>>;
  id?: Maybe<IdFilterComparison>;
  month?: Maybe<IntFieldComparison>;
  year?: Maybe<IntFieldComparison>;
  locked?: Maybe<BooleanFieldComparison>;
  trustAccountAilorn?: Maybe<GraphQlAilornFilterComparison>;
};

export type ReconciliationReportPeriodSort = {
  field: ReconciliationReportPeriodSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ReconciliationReportPeriodSortFields {
  Id = "id",
  Month = "month",
  Year = "year",
  Locked = "locked",
  TrustAccountAilorn = "trustAccountAilorn"
}

export type RecurringFee = {
  __typename?: "RecurringFee";
  /** @deprecated Use `idV2` or `ailoRN` instead. */
  id: Scalars["AiloRN"];
  idV2: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  name: Scalars["String"];
  /** Null only if the current user isn't authorized to access the management. (e.g. Tenant.) */
  managementAgreement?: Maybe<ManagementAgreement>;
  /** Null only if the current user isn't authorized to access the management. (e.g. Tenant.) */
  management?: Maybe<Management>;
  taxCategory: FeeTaxCategory;
  taxTreatment: TaxTreatment;
  frequency: FeeFrequency;
  startDate: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
  /** @deprecated Use `blueprintV2` instead. */
  blueprint: RecurringFeeBlueprint;
  blueprintV2: FeeBlueprint;
  schedules: Array<RecurringFeeSchedule>;
  /**
   * The most recent schedule that has already started (even if it has already ended due to fee being cancelled or management ending).
   * If none exist, return the schedule that will start next.
   */
  currentOrNextSchedule?: Maybe<RecurringFeeSchedule>;
  liability?: Maybe<Liability>;
  /** Whether local date is currently on or past the fee's final charge cycle */
  nextOccurrence?: Maybe<FeeOccurrence>;
  cancelled: Scalars["Boolean"];
  /**
   * True if the fee has been cancelled while it was still in the future,
   * or archived before it has been paid.
   *
   * An archived fee is archived forever;
   * and its' liabilities will also get archived.
   */
  archived: Scalars["Boolean"];
  currentness: DateRangeCurrentness;
  owing?: Maybe<RecurringOwing>;
};

export type RecurringFeeBlueprint = {
  __typename?: "RecurringFeeBlueprint";
  id: Scalars["AiloRN"];
  type: Scalars["String"];
  name: Scalars["String"];
  taxCategory: FeeTaxCategory;
  description?: Maybe<Scalars["String"]>;
  fixedAmount: Money;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  frequency: FeeFrequency;
  frequencyFormatted: Scalars["String"];
  anniversaryDay: Scalars["Int"];
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  /** @deprecated Use `organisation.id` instead. */
  createdByOrganisation: Scalars["AiloRN"];
  /** @deprecated Use `organisation.id` instead. */
  organisationId: Scalars["AiloRN"];
  /** Always present. (Nullable only because it's a federated field.) */
  organisation?: Maybe<Organisation>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
  archived: Scalars["Boolean"];
  archivedAt?: Maybe<Scalars["DateTime"]>;
  archivedBy?: Maybe<Scalars["AiloRN"]>;
  archiveReason?: Maybe<Scalars["String"]>;
};

export type RecurringFeeBlueprintInput = {
  name: Scalars["String"];
  taxCategoryId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  fixedAmount: MoneyInput;
  taxTreatment: TaxTreatment;
  frequency: FeeFrequency;
  anniversaryDay: Scalars["Int"];
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  organisationId: Scalars["AiloRN"];
};

export type RecurringFeeBlueprintsQueryConditions = {
  organisationId?: Maybe<Scalars["AiloRN"]>;
  archived?: Maybe<Scalars["Boolean"]>;
};

export type RecurringFeeBlueprintsQueryInput = {
  createdByOrganisation: Scalars["AiloRN"];
  archived?: Maybe<Scalars["Boolean"]>;
};

export enum RecurringFeeCurrentness {
  Past = "past",
  Current = "current",
  Future = "future"
}

export type RecurringFeeInput = {
  blueprintId: Scalars["AiloRN"];
  managementAgreementId: Scalars["AiloRN"];
  endDate?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  schedules: Array<RecurringFeeScheduleInput>;
};

export enum RecurringFeePaymentStatus {
  Overdue = "overdue",
  /**
   * Currently not overdue but may become overdue in the future
   * once the next occurrence starts
   */
  PaidForNow = "paidForNow",
  /**
   * Currently not overdue and fee has no more occurrences so it should
   * never become overdue again
   */
  PaidCompletely = "paidCompletely"
}

export type RecurringFeeSchedule = {
  __typename?: "RecurringFeeSchedule";
  id: Scalars["AiloRN"];
  feeId: Scalars["AiloRN"];
  taxAmount: Money;
  taxInclusiveAmount: Money;
  startDate: Scalars["String"];
  createdAt: Scalars["String"];
  createdBy: Scalars["AiloRN"];
};

export type RecurringFeeScheduleInput = {
  taxAmount: MoneyInput;
  taxInclusiveAmount: MoneyInput;
  startDate: Scalars["String"];
};

export type RecurringFeesQueryInput = {
  managementId?: Maybe<Scalars["AiloRN"]>;
  managementAgreementId?: Maybe<Scalars["AiloRN"]>;
  createdByOrganisation?: Maybe<Scalars["AiloRN"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  currentness?: Maybe<Array<RecurringFeeCurrentness>>;
  paymentStatus?: Maybe<Array<RecurringFeePaymentStatus>>;
};

export enum RecurringFrequency {
  Annually = "ANNUALLY",
  Fortnightly = "FORTNIGHTLY",
  Monthly = "MONTHLY",
  Onceoff = "ONCEOFF",
  Weekly = "WEEKLY"
}

export type RecurringOwing = {
  __typename?: "RecurringOwing";
  ailoRN: Scalars["AiloRN"];
  chargeCycles?: Maybe<PaginatedChargeCycles>;
  chargeSchedules: Array<ChargeSchedule>;
  id: Scalars["ID"];
  liability?: Maybe<Liability>;
  owedBy: Scalars["AiloRN"];
  owedTo: Scalars["AiloRN"];
  owingType: RecurringOwingType;
  progress: OwingProgress;
  ratePeriods: Array<RecurringOwingRatePeriod>;
  recurringOwingEvents?: Maybe<PaginatedRecurringOwingEvents>;
  reference: Scalars["AiloRN"];
  referencedEntity?: Maybe<OwingReferencedEntity>;
  taxCategory: Scalars["String"];
};

export type RecurringOwingChargeCyclesArgs = {
  pagination?: PaginationParams;
};

export type RecurringOwingRecurringOwingEventsArgs = {
  pagination?: PaginationParams;
};

export type RecurringOwingCalculationInput = {
  chargeSchedules: Array<ChargeScheduleInput>;
  ratePeriods: Array<RecurringOwingRatePeriodInput>;
};

export type RecurringOwingEvent = {
  __typename?: "RecurringOwingEvent";
  arrearsBeforeEntry?: Maybe<Arrears>;
  businessTransaction?: Maybe<BusinessTransaction>;
  causeReference: Scalars["AiloRN"];
  changeInOwedAmount: Money;
  changeInOwedTaxAmount: Money;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  effectiveAt: Scalars["DateTime"];
  emittedAt?: Maybe<Scalars["DateTime"]>;
  eventType: RecurringOwingEventType;
  id: Scalars["ID"];
  internalDescription: Scalars["String"];
  modifiedAt: Scalars["DateTime"];
  paidTo?: Maybe<PaidTo>;
  paidToBeforeEntry?: Maybe<PaidTo>;
  recurringOwing?: Maybe<RecurringOwing>;
  reversalOf?: Maybe<Scalars["AiloRN"]>;
};

export enum RecurringOwingEventType {
  Adjustment = "ADJUSTMENT",
  AdjustmentReversal = "ADJUSTMENT_REVERSAL",
  OwingChanged = "OWING_CHANGED",
  OwingDue = "OWING_DUE",
  Payment = "PAYMENT",
  PaymentFailure = "PAYMENT_FAILURE",
  PaymentReversal = "PAYMENT_REVERSAL",
  Refund = "REFUND",
  RefundFailure = "REFUND_FAILURE",
  RefundReversal = "REFUND_REVERSAL"
}

export type RecurringOwingInput = {
  chargeSchedules: Array<ChargeScheduleInput>;
  liabilityId?: Maybe<Scalars["AiloRN"]>;
  managingEntities?: Maybe<Array<Scalars["AiloRN"]>>;
  owedBy: Scalars["AiloRN"];
  owedTo: Scalars["AiloRN"];
  owingType: RecurringOwingType;
  ratePeriods: Array<RecurringOwingRatePeriodInput>;
  reference: Scalars["AiloRN"];
  relatedToManagement?: Maybe<Scalars["AiloRN"]>;
  taxCategory: Scalars["String"];
  timezone: Scalars["String"];
};

export type RecurringOwingRatePeriod = {
  __typename?: "RecurringOwingRatePeriod";
  amount: Money;
  endDate?: Maybe<Scalars["Date"]>;
  frequency: RecurringFrequency;
  startDate: Scalars["Date"];
  taxAmount: Money;
};

export type RecurringOwingRatePeriodInput = {
  amount: MoneyInput;
  endDate?: Maybe<Scalars["Date"]>;
  frequency: RecurringFrequency;
  startDate: Scalars["Date"];
  taxAmount?: Maybe<MoneyInput>;
};

export enum RecurringOwingType {
  Fee = "FEE",
  Rent = "RENT"
}

export type RefundOutput = {
  __typename?: "RefundOutput";
  businessTransaction: BusinessTransaction;
  businessTransactionStatus: BusinessTransactionStatus;
};

export type ReiForm = Form & {
  __typename?: "ReiForm";
  id: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  organisation: Organisation;
  supportedState: AustralianState;
  reiFormInfo: ReiFormInfo;
  viewUrl: Scalars["String"];
  downloadUrl: Scalars["String"];
  remoteSigningPDF: Scalars["Binary"];
  ailorn: Scalars["AiloRN"];
  remoteSigningStatus: ReiFormRemoteSigningStatus;
};

export enum ReiFormErrorCode {
  /** The form is not found in REI system, perhaps it has been deleted */
  ReiFormNotFound = "REI_FORM_NOT_FOUND",
  /** REI doesn't allow to get the form with that ID */
  ReiUnauthorised = "REI_UNAUTHORISED",
  /** The linked account and the form state don't match */
  ReiTokenFormStatesMismatch = "REI_TOKEN_FORM_STATES_MISMATCH"
}

export type ReiFormInfo = {
  __typename?: "ReiFormInfo";
  name: Scalars["String"];
  finalised: Scalars["Boolean"];
};

export type ReiFormRemoteSigner = {
  __typename?: "ReiFormRemoteSigner";
  name: Scalars["String"];
  /** Sign status */
  status: ReiFormRemoteSignerStatus;
  /** Date if signed */
  signed?: Maybe<Scalars["DateTime"]>;
};

export enum ReiFormRemoteSignerStatus {
  /** The form is waiting for the signer to sign */
  Pending = "PENDING",
  /** The signer has opened the form in Docusign */
  Delivered = "DELIVERED",
  /** The signer has declined to sign a form */
  Declined = "DECLINED",
  /** The signer has completed signing a form */
  Completed = "COMPLETED"
}

export type ReiFormRemoteSigningStatus = {
  __typename?: "ReiFormRemoteSigningStatus";
  status: SigningStatus;
  /** Date if sent */
  sent?: Maybe<Scalars["DateTime"]>;
  /** List of signers with names and signed dates if any */
  recipients: Array<ReiFormRemoteSigner>;
};

export type ReiTemplate = {
  __typename?: "ReiTemplate";
  id: Scalars["Int"];
  name: Scalars["String"];
  code: Scalars["String"];
};

export type ReiToken = {
  __typename?: "ReiToken";
  id: Scalars["ID"];
  token: Scalars["String"];
  email: Scalars["String"];
  supportedState: AustralianState;
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  person: Person;
  organisation: Organisation;
};

export type RelayPageInfo = {
  __typename?: "RelayPageInfo";
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars["Boolean"]>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars["ConnectionCursor"]>;
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars["ConnectionCursor"]>;
};

/** Sort Directions */
export enum RelaySortDirection {
  Asc = "ASC",
  Desc = "DESC"
}

export type RemoveInspectionAreaFileResult = {
  __typename?: "RemoveInspectionAreaFileResult";
  inspectionAreaFile: InspectionAreaFile;
};

export type RemoveInspectionAreaResult = {
  __typename?: "RemoveInspectionAreaResult";
  inspectionArea: InspectionArea;
};

export type RemoveInspectionFeatureFileResult = {
  __typename?: "RemoveInspectionFeatureFileResult";
  inspectionFeatureFile: InspectionFeatureFile;
};

export type RemoveInspectionFeatureResult = {
  __typename?: "RemoveInspectionFeatureResult";
  inspectionFeature: InspectionFeature;
};

export type RemoveInspectionResult = {
  __typename?: "RemoveInspectionResult";
  inspection: Inspection;
};

export type RemoveMemberFromLegalEntityInput = {
  memberPersonId: Scalars["String"];
  legalEntityId: Scalars["String"];
  roleId: Scalars["String"];
};

export type RemoveMemberFromOrganisationInput = {
  personId: Scalars["String"];
  organisationId: Scalars["String"];
  roleId: Scalars["String"];
};

export type RemoveReiTokenInput = {
  /** ID of the REI Token to be deleted */
  id: Scalars["String"];
  /** AiloRN of person the token is associated with */
  personAilorn: Scalars["AiloRN"];
  /** AiloRN of agency organisation */
  organisationAilorn: Scalars["AiloRN"];
};

export type RemoveTenantshipInput = {
  tenancyId: Scalars["ID"];
  tenantId: Scalars["ID"];
};

export type RemoveTrustAccountReconciliationAdjustmentInput = {
  id: Scalars["ID"];
  removedReason: Scalars["String"];
  removeFromReportPeriodId: Scalars["ID"];
};

export type Rent = {
  __typename?: "Rent";
  /** @deprecated Use rent.ailorn instead. This field will eventually be changed to type ID */
  id: Scalars["AiloRN"];
  ailorn: Scalars["AiloRN"];
  amountInCents: Scalars["String"];
  /** In cents with double-precision */
  dailyRate: Scalars["Float"];
  period: RentFrequency;
  effectiveDate: Scalars["Date"];
  rentIncreaseInCents: Scalars["String"];
  setsChargeDate: Scalars["Boolean"];
  category: RentCategory;
  previousRent?: Maybe<Rent>;
  tenancy?: Maybe<Tenancy>;
  rentSchedules: Array<RentSchedule>;
  proRataSchedulesForRentUpdate: Array<PreviewedRentSchedule>;
};

export type RentProRataSchedulesForRentUpdateArgs = {
  proposedRent: ProposedRent;
};

export enum RentCategory {
  Legacy = "Legacy",
  Support = "Support",
  Inherited = "Inherited",
  RentReview = "RentReview",
  LeaseRenewal = "LeaseRenewal",
  NewTenancy = "NewTenancy"
}

export type RentCreditDetailsInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  tenancyId: Scalars["ID"];
  effectiveDate: Scalars["Date"];
  idempotencyKey: Scalars["String"];
  notifyTenants?: Scalars["Boolean"];
  notifyInvestors?: Scalars["Boolean"];
};

export type RentCreditDetailsPayload = {
  __typename?: "RentCreditDetailsPayload";
  rentCredit?: Maybe<AdjustmentLiabilityEntry>;
  tenancy?: Maybe<Tenancy>;
  query: Query;
};

export type RenterOnAppData = {
  __typename?: "RenterOnAppData";
  propertyId?: Maybe<Scalars["String"]>;
  managementId?: Maybe<Scalars["String"]>;
  tenancyId?: Maybe<Scalars["String"]>;
  legalEntityId?: Maybe<Scalars["String"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  renterName?: Maybe<Scalars["String"]>;
  renterOnAppAt?: Maybe<Scalars["String"]>;
};

export type RenterOnAppReport = {
  __typename?: "RenterOnAppReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<RenterOnAppData>>>;
};

export enum RentFrequency {
  Daily = "daily",
  Weekly = "weekly",
  Fortnightly = "fortnightly",
  Monthly = "monthly"
}

export type RentPageCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
};

export type RentReviewDetails = {
  effectiveDate: Scalars["Date"];
  amountInCents: Scalars["String"];
  scheduledRentReviewDate: Scalars["Date"];
  period: RentFrequency;
};

export type RentReviewInput = {
  amount: MoneyInput;
  effectiveDate: Scalars["Date"];
};

export type RentReviewPlan = {
  __typename?: "RentReviewPlan";
  amount: Money;
  description?: Maybe<Scalars["String"]>;
  effectiveFrom: Scalars["Date"];
  proRata: Scalars["Boolean"];
};

export type RentReviewProposedPlanInput = {
  amount: MoneyInput;
  desiredStartFrom: Scalars["Date"];
  liabilityRef: Scalars["AiloRN"];
};

export type RentSchedule = {
  __typename?: "RentSchedule";
  id: Scalars["AiloRN"];
  amountInCents: Scalars["String"];
  period: RentFrequency;
  startDate: Scalars["Date"];
  proRata: Scalars["Boolean"];
};

export type RentSetupInput = {
  effectiveDate: Scalars["Date"];
  amountInCents: Scalars["String"];
  period: RentFrequency;
  category: RentCategory;
};

export enum RentSort {
  EffectiveDateAsc = "EFFECTIVE_DATE_ASC",
  EffectiveDateDesc = "EFFECTIVE_DATE_DESC"
}

export type ReportColumn = {
  __typename?: "ReportColumn";
  key: Scalars["String"];
  header: Scalars["String"];
};

export type ReportGenerationState = {
  __typename?: "ReportGenerationState";
  /** Returns true if an ongoing report progress does not exist */
  canGenerateReport: Scalars["Boolean"];
};

export type ReportPeriodByDateInput = {
  month: Scalars["Int"];
  year: Scalars["Int"];
  trustAccountAilorn: Scalars["AiloRN"];
};

export type ReportPeriodLockChangeResult = {
  __typename?: "ReportPeriodLockChangeResult";
  status: ReportPeriodLockChangeResultStatus;
  failureReason?: Maybe<Scalars["String"]>;
  report?: Maybe<ReconciliationReportPeriod>;
};

export enum ReportPeriodLockChangeResultStatus {
  Success = "SUCCESS",
  Failure = "FAILURE"
}

export type ReportSeriesTimeLabel = {
  __typename?: "ReportSeriesTimeLabel";
  raw?: Maybe<Scalars["String"]>;
  formatted?: Maybe<Scalars["String"]>;
};

export type ReportTimeSeries = {
  __typename?: "ReportTimeSeries";
  name?: Maybe<Scalars["String"]>;
  values?: Maybe<Array<Maybe<Scalars["Float"]>>>;
};

export type RequestSignatureActionMeta = {
  __typename?: "RequestSignatureActionMeta";
  form?: Maybe<Form>;
};

export type ResponseStatus = {
  __typename?: "ResponseStatus";
  businessTransaction?: Maybe<BusinessTransaction>;
  businessTransactionId?: Maybe<Scalars["AiloRN"]>;
  error?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
};

export type ReverseAdjustmentInput = {
  adjustmentId: Scalars["ID"];
  description: Scalars["String"];
};

export type RevokeMailboxAccessInput = {
  mailboxAccessAilorn: Scalars["AiloRN"];
};

export type Role = {
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
};

export type RolesQueryConditions = {
  type?: Maybe<RoleType>;
};

export enum RoleType {
  OrganisationRole = "OrganisationRole",
  LegalEntityRole = "LegalEntityRole"
}

export type RoutineInspectionProjectMeta = {
  __typename?: "RoutineInspectionProjectMeta";
  management: Management;
  tenancy: Tenancy;
  inspectionAppointment: InspectionAppointment;
};

export type SagaStatusResponse = {
  __typename?: "SagaStatusResponse";
  error?: Maybe<Scalars["String"]>;
  idempotencyKey: Scalars["String"];
  sagaId?: Maybe<Scalars["ID"]>;
  status: Scalars["String"];
};

export type SearchAgencyPropertiesQueryInput = {
  managingEntityOrgAilorn?: Maybe<Scalars["AiloRN"]>;
  query: Scalars["String"];
  /**
   * Cursor-based pagination in Elasticsearch can only support forward way based on "scroll_id" or "search_after", details: https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#scroll-search-results
   * Current cursor-based implementation is based on "scroll_id".
   * If page param (must be positive, i.e., >=1) is provided, then it will process query by using "from" (similar like offset based query in postgres), details:  https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#paginate-search-results
   * Note: By default, you cannot use from and size to page through more than 10,000 hits. This limit is a safeguard set by the index.max_result_window index setting. If you need to page through more than 10,000 hits, use the search_after parameter instead.
   */
  paginationParams?: Maybe<PaginationParamsWithPageNumber>;
};

export type SearchFilter = {
  key?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type SearchTenanciesQueryInput = {
  filters?: Maybe<TenanciesQueryFilterInput>;
  sort?: Maybe<Array<TenanciesSortParams>>;
  /**
   * Cursor-based pagination in Elasticsearch can only support forward way based on "scroll_id" or "search_after", details: https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#scroll-search-results
   * Current cursor-based implementation is based on "scroll_id".
   * If page param (must be positive, i.e., >=1) is provided, then it will process query by using "from" (similar like offset based query in postgres), details:  https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#paginate-search-results
   * Note: By default, you cannot use from and size to page through more than 10,000 hits. This limit is a safeguard set by the index.max_result_window index setting. If you need to page through more than 10,000 hits, use the search_after parameter instead.
   */
  paginationParams?: Maybe<PaginationParamsWithPageNumber>;
};

export type SearchTrustAccountWalletsQueryInput = {
  trustAccountOwnerLegalEntityAilorn?: Maybe<Scalars["AiloRN"]>;
  query?: Maybe<Scalars["String"]>;
  isTenancyWallet?: Maybe<Scalars["Boolean"]>;
  /**
   * Cursor-based pagination in Elasticsearch can only support forward way based on "scroll_id" or "search_after", details: https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#scroll-search-results
   * Current cursor-based implementation is based on "scroll_id".
   * If page param (must be positive, i.e., >=1) is provided, then it will process query by using "from" (similar like offset based query in postgres), details:  https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#paginate-search-results
   * Note: By default, you cannot use from and size to page through more than 10,000 hits. This limit is a safeguard set by the index.max_result_window index setting. If you need to page through more than 10,000 hits, use the search_after parameter instead.
   */
  paginationParams?: Maybe<PaginationParamsWithPageNumber>;
};

export enum SendEmailVerificationErrorCode {
  EmailAlreadyVerified = "EMAIL_ALREADY_VERIFIED",
  EmailUsedByAnotherPerson = "EMAIL_USED_BY_ANOTHER_PERSON"
}

export type SendEmailVerificationErrorResponse = {
  __typename?: "SendEmailVerificationErrorResponse";
  errorCode: SendEmailVerificationErrorCode;
};

export type SendEmailVerificationResponse =
  | SendEmailVerificationSuccessResponse
  | SendEmailVerificationErrorResponse;

export type SendEmailVerificationSuccessResponse = {
  __typename?: "SendEmailVerificationSuccessResponse";
  ok: Scalars["Boolean"];
};

export type SendMailInput = {
  sendFromMailboxAccessAilorn: Scalars["AiloRN"];
  toContactAilorns: Array<Scalars["AiloRN"]>;
  replyToMailThreadAilorn?: Maybe<Scalars["AiloRN"]>;
  subject: Scalars["String"];
  /** Plain text email body, will be formatted with generic styling before sending. */
  plaintextBody: Scalars["String"];
  attachmentFileAilorns: Array<Scalars["AiloRN"]>;
};

export type SendMessageInputV2 = {
  message: MessageInputV2;
  threadAilorn: Scalars["AiloRN"];
};

export type SendPhoneVerificationCodeResponse = {
  __typename?: "SendPhoneVerificationCodeResponse";
  ok: Scalars["Boolean"];
  error?: Maybe<SendPhoneVerificationCodeResponseError>;
};

export type SendPhoneVerificationCodeResponseError = {
  __typename?: "SendPhoneVerificationCodeResponseError";
  /**
   * Possible values:
   * - "internal-server-error"
   * - "phone-number-already-verified"
   * - "invalid-phone-number"
   * - "max-verification-check-attempts-reached"
   * - "max-send-attempts-reached"
   * - "sms-is-not-supported-by-landline-phone-number"
   * - "too-many-concurrent-requests-for-phone-number"
   */
  code: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
};

export type SetRoutineInspectionDueDateInput = {
  tenancyAilorn: Scalars["AiloRN"];
  dueDate: Scalars["Date"];
};

export type SetRoutineInspectionDueDateResult = {
  __typename?: "SetRoutineInspectionDueDateResult";
  tenancy: Tenancy;
};

export type SetupResult = {
  __typename?: "SetupResult";
  management?: Maybe<Management>;
  tenancy?: Maybe<Tenancy>;
};

export enum SigningStatus {
  /** The REI account is not linked with DocuSign yet */
  NotAvailable = "NOT_AVAILABLE",
  /** The form is waiting to be sent */
  Pending = "PENDING",
  /** The form has been sent to its signers */
  Sent = "SENT",
  /** All the signers have signed the form */
  Completed = "COMPLETED",
  /** One of the form signers has declined to sign */
  Declined = "DECLINED",
  /** REI returned an unknown status */
  Unknown = "UNKNOWN"
}

export enum SortDirection {
  Asc = "Asc",
  Desc = "Desc"
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = "NULLS_FIRST",
  NullsLast = "NULLS_LAST"
}

export enum SourceType {
  Adjustment = "ADJUSTMENT",
  Payment = "PAYMENT",
  Planbased = "PLANBASED"
}

export type Statement = {
  __typename?: "Statement";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  file?: Maybe<File>;
  billsZipFile?: Maybe<File>;
  statementSubject: Scalars["AiloRN"];
  subject?: Maybe<StatementSubject>;
  type: StatementType;
  rangeStartDate: Scalars["Date"];
  rangeEndDate: Scalars["Date"];
  createdFor: StatementCreatedFor;
  statementValues?: Maybe<Scalars["String"]>;
  statementProgress?: Maybe<StatementProgress>;
};

export type StatementAdjustmentLineItem = StatementLineItem2 & {
  __typename?: "StatementAdjustmentLineItem";
  adjustmentAmount: Money;
  clearedDate?: Maybe<Scalars["Date"]>;
  description?: Maybe<Scalars["String"]>;
  effectivePaidTo?: Maybe<Scalars["Date"]>;
  knowledgedDate: Scalars["Date"];
  liability?: Maybe<Liability>;
  liabilityCategory?: Maybe<Scalars["String"]>;
  management?: Maybe<Management>;
  sourceId: Scalars["ID"];
  sourceType: StatementLineItemSourceType;
  statementTransactionDate?: Maybe<Scalars["Date"]>;
  /** @deprecated Use `statementTransactionDate` */
  statementTransactionDateTime?: Maybe<Scalars["DateTime"]>;
  walletId: Scalars["ID"];
};

export type StatementByAccountInput = {
  account: Scalars["AiloRN"];
  from: Scalars["Date"];
  to: Scalars["Date"];
  zoneId: Scalars["ZoneId"];
};

export enum StatementCreatedFor {
  ScheduledJob = "ScheduledJob",
  ManualRequest = "ManualRequest",
  Disbursement = "Disbursement"
}

export type StatementCursor = {
  pageSize?: Maybe<Scalars["Int"]>;
  paginateBackward?: Maybe<Scalars["Boolean"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type StatementFilter = {
  statementTypes?: Maybe<Array<StatementType>>;
  createdFor?: Maybe<Array<StatementCreatedFor>>;
};

export type StatementLineItem = {
  __typename?: "StatementLineItem";
  account: Scalars["AiloRN"];
  amount: Money;
  businessTransactionType?: Maybe<Scalars["String"]>;
  category: StatementLineItemCategoryType;
  description?: Maybe<Scalars["String"]>;
  effectivePaidTo?: Maybe<Scalars["Date"]>;
  gst: Money;
  knowledgedDate: Scalars["Date"];
  liability: Liability;
  sourceType: StatementLineItemSourceType;
};

export type StatementLineItem2 = {
  clearedDate?: Maybe<Scalars["Date"]>;
  knowledgedDate: Scalars["Date"];
  liability?: Maybe<Liability>;
  liabilityCategory?: Maybe<Scalars["String"]>;
  management?: Maybe<Management>;
  sourceId: Scalars["ID"];
  sourceType: StatementLineItemSourceType;
  statementTransactionDate?: Maybe<Scalars["Date"]>;
  /** @deprecated Use `statementTransactionDate` */
  statementTransactionDateTime?: Maybe<Scalars["DateTime"]>;
  walletId: Scalars["ID"];
};

export enum StatementLineItemCategoryType {
  Expense = "EXPENSE",
  Income = "INCOME"
}

export enum StatementLineItemSourceType {
  AdjustmentEntry = "AdjustmentEntry",
  StatementEntry = "StatementEntry"
}

export type StatementProgress = {
  __typename?: "StatementProgress";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  statement?: Maybe<Statement>;
  statementSubject?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<StatementType>;
  rangeStartDate?: Maybe<Scalars["Date"]>;
  rangeEndDate?: Maybe<Scalars["Date"]>;
  createdFor?: Maybe<StatementCreatedFor>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  isSuccessful?: Maybe<Scalars["Boolean"]>;
  skipValidation?: Maybe<Scalars["Boolean"]>;
  skipManagementHasPaymentsCheck?: Maybe<Scalars["Boolean"]>;
  allowUnrecognisedTransactionLineItems?: Maybe<Scalars["Boolean"]>;
  linkFileToSubject?: Maybe<Scalars["Boolean"]>;
  dryRunEmail?: Maybe<Scalars["String"]>;
  sendEmail?: Maybe<Scalars["Boolean"]>;
  overrideStatementValues?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  attemptNo?: Maybe<Scalars["Int"]>;
  rangeStartBusinessTxId?: Maybe<Scalars["String"]>;
  rangeEndBusinessTxId?: Maybe<Scalars["String"]>;
  subject?: Maybe<StatementSubject>;
};

export type StatementProgressCursor = {
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  cursor?: Maybe<Scalars["String"]>;
};

export type StatementProgressFilter = {
  sendEmail?: Maybe<Scalars["Boolean"]>;
  statementSubject?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<StatementType>;
  types?: Maybe<Array<StatementType>>;
};

export enum StatementSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  RangeStartDateAsc = "RANGE_START_DATE_ASC",
  RangeStartDateDesc = "RANGE_START_DATE_DESC",
  RangeEndDateAsc = "RANGE_END_DATE_ASC",
  RangeEndDateDesc = "RANGE_END_DATE_DESC"
}

export type StatementSubject = LegalEntityCompanion | Management | Tenancy;

export type StatementTransactionLineItem = StatementLineItem2 & {
  __typename?: "StatementTransactionLineItem";
  btAmount: Money;
  btCreatedAt: Scalars["DateTime"];
  btStatusCreatedAt: Scalars["DateTime"];
  businessTransactionId: Scalars["ID"];
  businessTransactionStatus: Scalars["String"];
  businessTransactionType?: Maybe<Scalars["String"]>;
  clearedDate?: Maybe<Scalars["Date"]>;
  effectivePaidTo?: Maybe<Scalars["Date"]>;
  gst: Money;
  knowledgedDate: Scalars["Date"];
  liability?: Maybe<Liability>;
  liabilityCategory?: Maybe<Scalars["String"]>;
  management?: Maybe<Management>;
  parentBusinessTransactionId?: Maybe<Scalars["ID"]>;
  reversalBusinessTransactionType?: Maybe<Scalars["String"]>;
  reversalOfBusinessTransactionId?: Maybe<Scalars["ID"]>;
  serviceFeeAmount: Money;
  sourceId: Scalars["ID"];
  sourcePaymentMethodMaskedDetails?: Maybe<Scalars["String"]>;
  sourcePaymentMethodType?: Maybe<Scalars["String"]>;
  sourceType: StatementLineItemSourceType;
  sourceWalletId?: Maybe<Scalars["ID"]>;
  sourceWalletOwner?: Maybe<LegalEntityCompanion>;
  sourceWalletOwnerReference?: Maybe<Scalars["AiloRN"]>;
  sourceWalletType?: Maybe<StatementWalletType>;
  statementTransactionDate?: Maybe<Scalars["Date"]>;
  /** @deprecated Use `statementTransactionDate` */
  statementTransactionDateTime?: Maybe<Scalars["DateTime"]>;
  targetPaymentMethodMaskedDetails?: Maybe<Scalars["String"]>;
  targetPaymentMethodType?: Maybe<Scalars["String"]>;
  targetWalletId?: Maybe<Scalars["ID"]>;
  targetWalletOwner?: Maybe<LegalEntityCompanion>;
  targetWalletOwnerReference?: Maybe<Scalars["AiloRN"]>;
  targetWalletType?: Maybe<StatementWalletType>;
  taxCategory?: Maybe<Scalars["String"]>;
  userFacingDescription?: Maybe<Scalars["String"]>;
  walletAvailableBalance: Money;
  walletChangeAmount: Money;
  walletId: Scalars["ID"];
  walletOverdraftAllowance: Money;
  walletStatementRunningBalance?: Maybe<Money>;
  walletTotalBalance: Money;
};

export enum StatementType {
  AgencyTransfer = "AgencyTransfer",
  Management = "Management",
  TaxSummary = "TaxSummary",
  TenancyLedger = "TenancyLedger",
  TransferSummary = "TransferSummary"
}

export enum StatementWalletType {
  AiloBank = "AILO_BANK",
  AiloTrading = "AILO_TRADING",
  AiloWriteOff = "AILO_WRITE_OFF",
  ManagementFolio = "MANAGEMENT_FOLIO",
  PaymentMethod = "PAYMENT_METHOD",
  Personal = "PERSONAL",
  Tenancy = "TENANCY",
  Unknown = "UNKNOWN"
}

export type StatusComparison = {
  eq?: Maybe<ProjectStatus>;
};

export type Supplier = {
  __typename?: "Supplier";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  registeredEntityName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  abn?: Maybe<Scalars["String"]>;
  /**
   * String containing entire address. Will be removed in the future after migrating
   * this field to the new address fields.
   */
  businessAddress?: Maybe<Scalars["String"]>;
  address?: Maybe<SupplierAddress>;
  emailAddress?: Maybe<Scalars["String"]>;
  organisationReference: Scalars["AiloRN"];
  organisation?: Maybe<Organisation>;
  createdAt: Scalars["DateTime"];
  /**
   * If null it means the bill has been created by Ailo system.
   * @deprecated Use `Supplier.createdByV2` instead
   */
  createdBy?: Maybe<User>;
  createdByV2?: Maybe<ActionInitiator>;
  modifiedAt?: Maybe<Scalars["DateTime"]>;
  modifiedBy?: Maybe<ActionInitiator>;
  archivedAt?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Use `Supplier.archivedByV2` instead */
  archivedBy?: Maybe<Scalars["AiloRN"]>;
  archivedByV2?: Maybe<ActionInitiator>;
  /** @deprecated Use `Bill.payee` instead */
  internalReference?: Maybe<Scalars["AiloRN"]>;
  /** @deprecated Use `Bill.payee` instead */
  internalLegalEntity?: Maybe<LegalEntityCompanion>;
  paymentMethods: Array<Maybe<PaymentMethodCompanion>>;
  kycDataComplete: Scalars["Boolean"];
};

export type SupplierAddress = {
  __typename?: "SupplierAddress";
  streetName?: Maybe<Scalars["String"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type SupplierAddressInput = {
  streetName?: Maybe<Scalars["String"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type SupplierBankAccountInput = {
  description?: Maybe<Scalars["String"]>;
  bsb: Scalars["String"];
  accountNumber: Scalars["String"];
  accountName: Scalars["String"];
  onceOff?: Maybe<Scalars["Boolean"]>;
};

export type SupplierBPayInput = {
  description?: Maybe<Scalars["String"]>;
  billerCode: Scalars["String"];
};

export type SupplierCreditCardInputV2 = {
  description?: Maybe<Scalars["String"]>;
  token: Scalars["String"];
  type: Scalars["String"];
  category: Scalars["String"];
  number: Scalars["String"];
  expiry: Scalars["String"];
};

export type SupplierPaymentMethodInput = {
  creditCardInput?: Maybe<SupplierCreditCardInputV2>;
  bankAccountInput?: Maybe<SupplierBankAccountInput>;
  bpayInput?: Maybe<SupplierBPayInput>;
};

export type SuppliersQueryInput = {
  registeredEntityName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  registeredEntityNameLike?: Maybe<Scalars["String"]>;
  nameLike?: Maybe<Scalars["String"]>;
  abnLike?: Maybe<Scalars["String"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  kycDataComplete?: Maybe<Scalars["Boolean"]>;
  organisationReference?: Maybe<Scalars["AiloRN"]>;
};

export type SyncLegalEntityInput = {
  legalEntityAilorn: Scalars["AiloRN"];
};

export type System = {
  __typename?: "System";
  ailoRN: Scalars["AiloRN"];
};

export type TaxableAmount = {
  __typename?: "TaxableAmount";
  tax: Money;
  total: Money;
};

export type TaxCategory = {
  __typename?: "TaxCategory";
  id: Scalars["String"];
  name: Scalars["String"];
  createdAt: Scalars["DateTime"];
};

export type TaxSummaryEntry = {
  __typename?: "TaxSummaryEntry";
  taxCategory: Scalars["String"];
  totalAiloFees: Money;
  totalAmount: Money;
  totalGST: Money;
};

export type TaxSummaryStatementData = {
  __typename?: "TaxSummaryStatementData";
  expenseTransactions: Array<TaxSummaryTransactionEntry>;
  expenses: Array<TaxSummaryEntry>;
  income: Array<TaxSummaryEntry>;
  incomeTransactions: Array<TaxSummaryTransactionEntry>;
};

export type TaxSummaryStatementInput = {
  endDate: Scalars["Date"];
  startDate: Scalars["Date"];
  walletOwnerReference: Scalars["AiloRN"];
  zoneId: Scalars["ZoneId"];
};

export type TaxSummaryTransactionEntry = {
  __typename?: "TaxSummaryTransactionEntry";
  amount: Money;
  bill?: Maybe<Bill>;
  feeAmount: Money;
  gstAmount: Money;
  liabilityReference?: Maybe<Scalars["AiloRN"]>;
  taxCategory: Scalars["String"];
  transactionClearedAt?: Maybe<Scalars["DateTime"]>;
  transactionCreatedAt: Scalars["DateTime"];
};

export enum TaxTreatment {
  Inclusive = "inclusive",
  Exclusive = "exclusive",
  NoTax = "noTax"
}

export type Team = {
  __typename?: "Team";
  id: Scalars["AiloRN"];
  name: Scalars["String"];
  myTeam: Scalars["Boolean"];
  contacts: Array<Contact>;
};

export type TeamFilterInput = {
  ailornIn?: Maybe<Array<Scalars["AiloRN"]>>;
  includeUnassigned?: Maybe<Scalars["Boolean"]>;
};

export type TeamFilterParams = {
  name: TeamFilterTypes;
};

export enum TeamFilterTypes {
  HasManagements = "HasManagements"
}

export type TeamPeriodComparison = {
  __typename?: "TeamPeriodComparison";
  currentValue: Scalars["String"];
  previousValue?: Maybe<Scalars["String"]>;
  teamName: Scalars["String"];
  id: Scalars["String"];
  period?: Maybe<Scalars["String"]>;
};

export type TenanciesQueryFilterInput = {
  query?: Maybe<Scalars["String"]>;
  team?: Maybe<TeamFilterInput>;
  managingEntityOrgAilorn?: Maybe<Scalars["AiloRN"]>;
  excludeVoided?: Maybe<Scalars["Boolean"]>;
  excludeVacated?: Maybe<Scalars["Boolean"]>;
  excludeWithPendingRoutineAppointment?: Maybe<Scalars["Boolean"]>;
  excludeOffboarded?: Maybe<Scalars["Boolean"]>;
};

export enum TenanciesSortField {
  RoutineInspectionDueDate = "RoutineInspectionDueDate"
}

export type TenanciesSortParams = {
  field: TenanciesSortField;
  direction: SortDirection;
};

export type Tenancy = {
  __typename?: "Tenancy";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
  startDate?: Maybe<Scalars["Date"]>;
  endDate?: Maybe<Scalars["Date"]>;
  voidedAt?: Maybe<Scalars["Date"]>;
  vacatingReason?: Maybe<VacatingReason>;
  vacatingNotes?: Maybe<Scalars["String"]>;
  canCancelVacate?: Maybe<CancelVacateAbility>;
  isVoidable: VoidTenancyAbility;
  /** Date which the another rent review should be performed */
  scheduledRentReviewDate?: Maybe<Scalars["Date"]>;
  validVacateEndDateRange?: Maybe<DateRange>;
  /** @deprecated Use `leaseReviewAllowedOperation` instead */
  leaseReviewAllowed?: Maybe<Scalars["Boolean"]>;
  leaseReviewAllowedOperation?: Maybe<AllowedOperations>;
  agencyProperty: AgencyProperty;
  /**
   * returns the first to exist of currentRent, nextRent, mostRecentRent
   * in that order. Represents the rent users are most likely
   * interested in.
   */
  displayRent?: Maybe<Rent>;
  currentRent?: Maybe<Rent>;
  nextRent?: Maybe<Rent>;
  nextRentReview?: Maybe<Rent>;
  mostRecentRentReview?: Maybe<Rent>;
  rents: PaginatedRents;
  property: Property;
  currentRentSchedule?: Maybe<RentSchedule>;
  nextRentSchedule?: Maybe<RentSchedule>;
  proRataSchedulesForNewRent: Array<PreviewedRentSchedule>;
  tenancyAgreements?: Maybe<PaginatedTenancyAgreements>;
  mostRecentTenancyAgreement?: Maybe<TenancyAgreement>;
  nextTenancyAgreement?: Maybe<TenancyAgreement>;
  latestTenancyAgreement?: Maybe<TenancyAgreement>;
  rentReviewAllowed?: Maybe<Scalars["Boolean"]>;
  editableRentReview?: Maybe<EditableRentReviewPayload>;
  bond?: Maybe<TenancyBond>;
  files?: Maybe<PaginatedFiles>;
  /** Null only if the current user isn't authorized to access the management. (e.g. Tenant.) */
  management?: Maybe<Management>;
  liability?: Maybe<Liability>;
  liabilityReport?: Maybe<LiabilityReport>;
  isDraft?: Maybe<Scalars["Boolean"]>;
  payerBillsSummary?: Maybe<PayerBillsSummary>;
  deposit?: Maybe<TenancyDeposit>;
  tenantships?: Maybe<PaginatedTenantships>;
  rent?: Maybe<RecurringOwing>;
  inspections: Array<Inspection>;
  inspectionAppointments: Array<InspectionAppointment>;
  lastCompletedRoutineInspection?: Maybe<Inspection>;
  routineInspectionDueDate?: Maybe<Scalars["Date"]>;
  tenantContacts: Array<Contact>;
  billsAsPayer?: Maybe<PaginatedBills>;
  /** @deprecated Use `Tenancy.billsAsPayer` instead */
  upcomingBills?: Maybe<PaginatedBills>;
  /** @deprecated Use `Tenancy.billsAsPayer` instead */
  paidBills?: Maybe<PaginatedBills>;
};

export type TenancyRentsArgs = {
  cursor?: RentPageCursor;
  sort?: Maybe<Array<RentSort>>;
};

export type TenancyProRataSchedulesForNewRentArgs = {
  proposedRent: ProposedRent;
};

export type TenancyFilesArgs = {
  pageCursor?: PaginationParams;
};

export type TenancyTenantshipsArgs = {
  pageCursor?: PmPageCursor;
};

export type TenancyBillsAsPayerArgs = {
  id?: Maybe<Array<Scalars["AiloRN"]>>;
  organisationId?: Maybe<Array<Scalars["AiloRN"]>>;
  payeeId?: Maybe<Array<Scalars["AiloRN"]>>;
  taxCategoryId?: Maybe<Array<Scalars["String"]>>;
  taxCategoryIdNotIn?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Array<BillStatus>>;
  paid?: Maybe<Scalars["Boolean"]>;
  payByDate?: Maybe<LocalDateRangeCondition>;
  sort?: Maybe<BillSortParams>;
  cursor?: Maybe<PageCursorWithoutSort>;
  excludeBillsWithEmptyPaymentStatus?: Maybe<Scalars["Boolean"]>;
};

export type TenancyUpcomingBillsArgs = {
  cursor?: Maybe<BillPageCursor>;
};

export type TenancyPaidBillsArgs = {
  cursor?: Maybe<BillPageCursor>;
};

export type TenancyAgreement = {
  __typename?: "TenancyAgreement";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  startDate?: Maybe<Scalars["Date"]>;
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
  /**
   * Represents the date when the user made the decision to allow the current lease to lapse.
   * This is NOT the date which the tenancy is allowed to go into periodic from.
   */
  allowedToLapseAt?: Maybe<Scalars["DateTime"]>;
};

export type TenancyAgreementDetails = {
  startDate: Scalars["Date"];
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
};

export type TenancyAgreementSetupInput = {
  startDate: Scalars["Date"];
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
};

export type TenancyBond = {
  __typename?: "TenancyBond";
  id: Scalars["ID"];
  amount: Money;
  /** @deprecated use 'amount' */
  amountInCents?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["String"]>;
  status: TenancyBondStatus;
  failureReason?: Maybe<BondDisbursalFailureReason>;
  fundsReceived?: Maybe<Array<FundReceived>>;
  claims?: Maybe<Array<TenancyBondClaim>>;
  createdAt: Scalars["DateTime"];
  modifiedAt?: Maybe<Scalars["DateTime"]>;
};

export type TenancyBondAccount = {
  __typename?: "TenancyBondAccount";
  id: Scalars["ID"];
  paymentMethodCompanion?: Maybe<PaymentMethodCompanion>;
};

export type TenancyBondClaim = {
  __typename?: "TenancyBondClaim";
  id: Scalars["ID"];
  amount: Money;
  liabilityAiloRN: Scalars["AiloRN"];
  claimOrder: Scalars["Int"];
  claimStatus: TenancyBondClaimStatus;
};

export enum TenancyBondClaimStatus {
  Unpaid = "Unpaid",
  Pending = "Pending",
  Paid = "Paid"
}

export type TenancyBondSetupInput = {
  amountInCents: Scalars["String"];
  reference: Scalars["String"];
};

export enum TenancyBondStatus {
  Unclaimed = "Unclaimed",
  AwaitingFunds = "AwaitingFunds",
  FundsReceived = "FundsReceived",
  Disbursing = "Disbursing",
  ClaimSuccessful = "ClaimSuccessful",
  DisbursementFailed = "DisbursementFailed",
  BondResolved = "BondResolved"
}

export type TenancyCursor = {
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  cursor?: Maybe<Scalars["String"]>;
};

export type TenancyDeposit = {
  __typename?: "TenancyDeposit";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  amount: Money;
  releasedAt?: Maybe<Scalars["DateTime"]>;
  releasedAmount: Money;
  releasedBy?: Maybe<Scalars["AiloRN"]>;
  paidAt?: Maybe<Scalars["DateTime"]>;
  status: TenancyDepositStatus;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
  liability?: Maybe<Liability>;
};

export type TenancyDepositSetupInput = {
  amount: MoneyInput;
};

export enum TenancyDepositStatus {
  Void = "VOID",
  New = "NEW",
  Due = "DUE",
  Pending = "PENDING",
  Paid = "PAID",
  Released = "RELEASED",
  Failed = "FAILED"
}

export type TenancyFirstRentPaymentData = {
  __typename?: "TenancyFirstRentPaymentData";
  propertyId?: Maybe<Scalars["String"]>;
  managementId?: Maybe<Scalars["String"]>;
  tenancyId?: Maybe<Scalars["String"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  firstRentPaymentDate?: Maybe<Scalars["String"]>;
  renter?: Maybe<Scalars["String"]>;
  firstRentPaymentAmount?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamName?: Maybe<Scalars["String"]>;
};

export type TenancyFirstRentPaymentReport = {
  __typename?: "TenancyFirstRentPaymentReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<TenancyFirstRentPaymentData>>>;
};

export type TenancyInput = {
  managementId: Scalars["ID"];
};

export type TenancyLedgerData = {
  __typename?: "TenancyLedgerData";
  transactionDate: Scalars["String"];
  transactionType: Scalars["String"];
  transactionDescription: Scalars["String"];
  paidToDate?: Maybe<Scalars["String"]>;
  transactionAmount: Scalars["String"];
  arrearsAmount?: Maybe<Scalars["String"]>;
  arrearsDays?: Maybe<Scalars["Int"]>;
};

export type TenancyLedgerHeaderFields = {
  __typename?: "TenancyLedgerHeaderFields";
  agencyName?: Maybe<Scalars["String"]>;
  agencyAddress?: Maybe<Scalars["String"]>;
  agencyABN?: Maybe<Scalars["String"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  totalRentPaymentAmount?: Maybe<Scalars["String"]>;
  ledgerPeriodStart?: Maybe<Scalars["String"]>;
  ledgerPeriodEnd?: Maybe<Scalars["String"]>;
  ledgerDate?: Maybe<Scalars["String"]>;
};

export type TenancyLedgerLineItem = {
  __typename?: "TenancyLedgerLineItem";
  amount: Money;
  businessTransaction?: Maybe<BusinessTransaction>;
  displayDateTime: Scalars["DateTime"];
  isFailOrVoid: Scalars["Boolean"];
  liability: Liability;
  liabilityEntryDateTime: Scalars["DateTime"];
  paidToDateDetails?: Maybe<PaidToDateDetails>;
  type: TenancyLedgerLineItemType;
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type TenancyLedgerLineItemsInput = {
  tenancy: Scalars["AiloRN"];
};

export enum TenancyLedgerLineItemType {
  Adjustment = "ADJUSTMENT",
  Bill = "BILL",
  Rent = "RENT"
}

export type TenancyLedgerReport = {
  __typename?: "TenancyLedgerReport";
  type: Scalars["String"];
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  rows?: Maybe<Array<Maybe<TenancyLedgerData>>>;
  headerFields?: Maybe<TenancyLedgerHeaderFields>;
};

export type TenancyPropertyAddress = ContactPropertyAddress & {
  __typename?: "TenancyPropertyAddress";
  managementAilorn: Scalars["AiloRN"];
  managementEndDate?: Maybe<Scalars["Date"]>;
  tenancyAilorn: Scalars["AiloRN"];
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  /** <unitStreetNumber> <streetName> */
  shortAddress: Scalars["String"];
  startDate?: Maybe<Scalars["Date"]>;
  endDate?: Maybe<Scalars["Date"]>;
  voidedAt?: Maybe<Scalars["Date"]>;
};

export type TenancySearchResult = {
  __typename?: "TenancySearchResult";
  id: Scalars["ID"];
  tenancy: Tenancy;
};

export type TenancySetupInput = {
  startDate: Scalars["Date"];
  endDate?: Maybe<Scalars["Date"]>;
  scheduledRentReviewDate?: Maybe<Scalars["Date"]>;
  tenants: Array<TenantshipSetupInput>;
  tenancyAgreement: TenancyAgreementSetupInput;
  rents?: Maybe<Array<RentSetupInput>>;
  bond?: Maybe<TenancyBondSetupInput>;
  deposit?: Maybe<TenancyDepositSetupInput>;
  fileAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type Tenantship = {
  __typename?: "Tenantship";
  tenancyId: Scalars["ID"];
  tenantId: Scalars["ID"];
  tenant?: Maybe<LegalEntity>;
  startDate: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  tenancy: Tenancy;
};

export type TenantshipSetupInput = {
  tenantId?: Maybe<Scalars["ID"]>;
  consumer?: Maybe<ConsumerSetupInput>;
};

export enum ThreadDeliveryMechanism {
  Chat = "Chat",
  Email = "Email"
}

export type ThreadInputV2 = {
  message: MessageInputV2;
  /**
   * Subjects are validated to ensure that they contain at least some non-whitespace text.
   * If a value longer than 78 characters is provided, it will be truncated to have a length of 78.
   */
  subject: Scalars["String"];
  /**
   * If "Email" is selected as one of the delivery mechanisms, then
   * all messages sent in this thread (including the initial message)
   * must also have a "sendEmailFromMailboxAccessAilorn" set in MessageInputV2
   */
  deliveryMechanisms?: Maybe<Array<ThreadDeliveryMechanism>>;
  /**
   * Entities in Ailo that this thread is related to. Currently supported relations are:
   * - project:action
   * - project:project
   * - propertymanagement:management
   */
  relatedToAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type ThreadParticipant = {
  __typename?: "ThreadParticipant";
  contact: Contact;
  joinedAt: Scalars["DateTime"];
  isAdditionalParticipant: Scalars["Boolean"];
};

export type ThreadRelatedEntity = Action | Project | Management;

export type ThreadV2 = {
  __typename?: "ThreadV2";
  ailorn: Scalars["AiloRN"];
  subject: Scalars["String"];
  participants: Array<Contact>;
  threadParticipants: Array<ThreadParticipant>;
  deliveryMechanisms: Array<ThreadDeliveryMechanism>;
  firstMessage: MessageV2;
  mostRecentMessage: MessageV2;
  oldestUnreadMessage?: Maybe<MessageV2>;
  /** This does not include the first message in the thread. */
  replies: PaginatedMessagesV2;
  /** This does not count the first message in the thread. */
  numberOfReplies: Scalars["Int"];
  chat: ChatV2;
  relatedTo: Array<Maybe<ThreadRelatedEntity>>;
  unreadMessagesCount: Scalars["Int"];
};

export type ThreadV2RepliesArgs = {
  pagination: PaginationInputV2;
};

export enum ThumbnailStatus {
  Generating = "Generating",
  Generated = "Generated",
  None = "None"
}

export type TotalClearedRentReceived = {
  __typename?: "TotalClearedRentReceived";
  count: Scalars["Int"];
};

export type TrackDocusignActionMeta = {
  __typename?: "TrackDocusignActionMeta";
  form?: Maybe<Form>;
};

export type TrackInspectionCompletedActionMeta = {
  __typename?: "TrackInspectionCompletedActionMeta";
  inspectionAppointment?: Maybe<InspectionAppointment>;
};

export type Transaction = {
  __typename?: "Transaction";
  allocations: Array<Maybe<TransactionAllocation>>;
  auditNumber?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["AiloRN"]>;
  management?: Maybe<Management>;
  referenceNumber?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

export type TransactionAllocation = {
  __typename?: "TransactionAllocation";
  amount?: Maybe<Money>;
  description?: Maybe<Scalars["String"]>;
};

export type TransactionDetails = {
  __typename?: "TransactionDetails";
  createdAt: Scalars["DateTime"];
  feeAmount: Money;
  idempotentKey?: Maybe<Scalars["String"]>;
  liability?: Maybe<Liability>;
  message?: Maybe<Scalars["String"]>;
  payer?: Maybe<Scalars["AiloRN"]>;
  paymentChannel?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentReferenceId?: Maybe<Scalars["String"]>;
  paymentRequestDescription?: Maybe<Scalars["String"]>;
  requestedAmount: Money;
  transactionAmount: Money;
  transactionRef: Scalars["AiloRN"];
  transactionStatus: TransactionStatus;
  type?: Maybe<Scalars["String"]>;
};

export type TransactionFee = {
  __typename?: "TransactionFee";
  feeBps: Scalars["Int"];
  feeFlatCents: Scalars["Int"];
  waived?: Maybe<Scalars["Boolean"]>;
  waivedTo?: Maybe<Scalars["Date"]>;
};

export type TransactionReportItem = {
  __typename?: "TransactionReportItem";
  ailoFeeAmount?: Maybe<Money>;
  bankSettlementDate?: Maybe<Scalars["DateTime"]>;
  bankingChannel?: Maybe<Scalars["String"]>;
  billType?: Maybe<Scalars["String"]>;
  businessTransactionId: Scalars["AiloRN"];
  channel?: Maybe<Scalars["String"]>;
  clearedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  gatewayPaymentId?: Maybe<Scalars["String"]>;
  /** @deprecated Use `businessTransactionId` or `paymentTransactionId` instead */
  id?: Maybe<Scalars["AiloRN"]>;
  methodType?: Maybe<Scalars["String"]>;
  originalPaymentAmount?: Maybe<Money>;
  payeeId?: Maybe<Scalars["AiloRN"]>;
  payerId?: Maybe<Scalars["AiloRN"]>;
  paymentTransactionId?: Maybe<Scalars["AiloRN"]>;
  paymentTransactionType?: Maybe<Scalars["String"]>;
  paymentType?: Maybe<Scalars["String"]>;
  processOrSettlementDate?: Maybe<Scalars["DateTime"]>;
  product?: Maybe<Scalars["String"]>;
  responseCode?: Maybe<Scalars["String"]>;
  reversalType?: Maybe<Scalars["String"]>;
  reversedBusinessTransactionId?: Maybe<Scalars["AiloRN"]>;
  reversedPaymentTransaction?: Maybe<Scalars["AiloRN"]>;
  status?: Maybe<Scalars["String"]>;
  taxCategory?: Maybe<Scalars["String"]>;
  totalPaymentAmount?: Maybe<Money>;
  transactionType?: Maybe<Scalars["String"]>;
};

export enum TransactionsSortField {
  AuditNumber = "AUDIT_NUMBER"
}

export type TransactionStatus = {
  __typename?: "transactionStatus";
  clearedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  status: BusinessTxStatusEnum;
};

export type TransferSummaryStatementInput = {
  /** Transfer business tx id the statement data starts from exclusive */
  from?: Maybe<Scalars["ID"]>;
  /** Transfer business tx id the statement data is up to inclusive */
  to: Scalars["ID"];
  walletOwnerReference: Scalars["AiloRN"];
};

export type TransferSummaryStatementRange = {
  __typename?: "TransferSummaryStatementRange";
  fromBusinessTxId?: Maybe<Scalars["ID"]>;
  fromDateTime: Scalars["DateTime"];
  toBusinessTxId: Scalars["ID"];
  toDateTime: Scalars["DateTime"];
};

export type TransferSummaryStatementRangeInput = {
  /** Business transaction id of the disbursement. */
  businessTxId: Scalars["ID"];
  /** Wallet owner of wallet being disbursed from. */
  walletOwnerReference: Scalars["AiloRN"];
};

export type TransferToWalletInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  fromWalletId: Scalars["ID"];
  toWalletId: Scalars["ID"];
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type TrustAccount = {
  __typename?: "TrustAccount";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  ownerLegalEntityAilorn: Scalars["AiloRN"];
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bsb?: Maybe<Scalars["String"]>;
  ownerLegalEntity: Company;
};

export type TrustAccountAggregateGroupBy = {
  __typename?: "TrustAccountAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  ownerLegalEntityAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountConnection = {
  __typename?: "TrustAccountConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<TrustAccountEdge>;
};

export type TrustAccountCountAggregate = {
  __typename?: "TrustAccountCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  ownerLegalEntityAilorn?: Maybe<Scalars["Int"]>;
};

export type TrustAccountEdge = {
  __typename?: "TrustAccountEdge";
  /** The node containing the TrustAccount */
  node: TrustAccount;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type TrustAccountFilter = {
  and?: Maybe<Array<TrustAccountFilter>>;
  or?: Maybe<Array<TrustAccountFilter>>;
  id?: Maybe<IdFilterComparison>;
  ownerLegalEntityAilorn?: Maybe<AiloRnFilterComparison>;
};

export type TrustAccountLock = {
  __typename?: "TrustAccountLock";
  lockedUpTo: Scalars["DateTime"];
  status: TrustAccountLockStatus;
  trustAccountAilorn: Scalars["AiloRN"];
};

export enum TrustAccountLockStatus {
  Confirmed = "CONFIRMED",
  Unconfirmed = "UNCONFIRMED"
}

export type TrustAccountMaxAggregate = {
  __typename?: "TrustAccountMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  ownerLegalEntityAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountMinAggregate = {
  __typename?: "TrustAccountMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  ownerLegalEntityAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountReconciliationAdjustment = {
  __typename?: "TrustAccountReconciliationAdjustment";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  amountInCents: Scalars["Long"];
  applyOn: Scalars["LocalDate"];
  details: Scalars["String"];
  reportPeriodId: Scalars["ID"];
  removedReason?: Maybe<Scalars["String"]>;
  removedFrom?: Maybe<Scalars["LocalDate"]>;
  /** @deprecated Use removedFrom */
  removedAt?: Maybe<Scalars["DateTime"]>;
  reportPeriod: ReconciliationReportPeriod;
};

export type TrustAccountReconciliationAdjustmentAggregateGroupBy = {
  __typename?: "TrustAccountReconciliationAdjustmentAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  amountInCents?: Maybe<Scalars["Long"]>;
  applyOn?: Maybe<Scalars["LocalDate"]>;
  reportPeriodId?: Maybe<Scalars["ID"]>;
  removedFrom?: Maybe<Scalars["LocalDate"]>;
};

export type TrustAccountReconciliationAdjustmentAvgAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentAvgAggregate";
  amountInCents?: Maybe<Scalars["Float"]>;
};

export type TrustAccountReconciliationAdjustmentConnection = {
  __typename?: "TrustAccountReconciliationAdjustmentConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<TrustAccountReconciliationAdjustmentEdge>;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type TrustAccountReconciliationAdjustmentCountAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  amountInCents?: Maybe<Scalars["Int"]>;
  applyOn?: Maybe<Scalars["Int"]>;
  reportPeriodId?: Maybe<Scalars["Int"]>;
  removedFrom?: Maybe<Scalars["Int"]>;
};

export type TrustAccountReconciliationAdjustmentEdge = {
  __typename?: "TrustAccountReconciliationAdjustmentEdge";
  /** The node containing the TrustAccountReconciliationAdjustment */
  node: TrustAccountReconciliationAdjustment;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type TrustAccountReconciliationAdjustmentFilter = {
  and?: Maybe<Array<TrustAccountReconciliationAdjustmentFilter>>;
  or?: Maybe<Array<TrustAccountReconciliationAdjustmentFilter>>;
  id?: Maybe<IdFilterComparison>;
  amountInCents?: Maybe<LongFilterComparison>;
  applyOn?: Maybe<LocalDateFilterComparison>;
  reportPeriodId?: Maybe<IdFilterComparison>;
  removedFrom?: Maybe<LocalDateFilterComparison>;
  reportPeriod?: Maybe<TrustAccountReconciliationAdjustmentFilterReconciliationReportPeriodFilter>;
};

export type TrustAccountReconciliationAdjustmentFilterReconciliationReportPeriodFilter =
  {
    and?: Maybe<
      Array<TrustAccountReconciliationAdjustmentFilterReconciliationReportPeriodFilter>
    >;
    or?: Maybe<
      Array<TrustAccountReconciliationAdjustmentFilterReconciliationReportPeriodFilter>
    >;
    id?: Maybe<IdFilterComparison>;
    month?: Maybe<IntFieldComparison>;
    year?: Maybe<IntFieldComparison>;
    locked?: Maybe<BooleanFieldComparison>;
    trustAccountAilorn?: Maybe<GraphQlAilornFilterComparison>;
  };

export type TrustAccountReconciliationAdjustmentMaxAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  amountInCents?: Maybe<Scalars["Long"]>;
  applyOn?: Maybe<Scalars["LocalDate"]>;
  reportPeriodId?: Maybe<Scalars["ID"]>;
  removedFrom?: Maybe<Scalars["LocalDate"]>;
};

export type TrustAccountReconciliationAdjustmentMinAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  amountInCents?: Maybe<Scalars["Long"]>;
  applyOn?: Maybe<Scalars["LocalDate"]>;
  reportPeriodId?: Maybe<Scalars["ID"]>;
  removedFrom?: Maybe<Scalars["LocalDate"]>;
};

export type TrustAccountReconciliationAdjustmentSort = {
  field: TrustAccountReconciliationAdjustmentSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TrustAccountReconciliationAdjustmentSortFields {
  Id = "id",
  AmountInCents = "amountInCents",
  ApplyOn = "applyOn",
  ReportPeriodId = "reportPeriodId",
  RemovedFrom = "removedFrom"
}

export type TrustAccountReconciliationAdjustmentSumAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentSumAggregate";
  amountInCents?: Maybe<Scalars["Float"]>;
};

export type TrustAccountSort = {
  field: TrustAccountSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TrustAccountSortFields {
  Id = "id",
  OwnerLegalEntityAilorn = "ownerLegalEntityAilorn"
}

export type TrustAccountStatementBalance = {
  __typename?: "TrustAccountStatementBalance";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  amountInCents: Scalars["Long"];
  date: Scalars["LocalDate"];
  trustAccountAilorn: Scalars["ID"];
  relatedReportPeriods: Array<ReconciliationReportPeriod>;
};

export type TrustAccountStatementBalanceRelatedReportPeriodsArgs = {
  filter?: Maybe<ReconciliationReportPeriodFilter>;
  sorting?: Maybe<Array<ReconciliationReportPeriodSort>>;
};

export type TrustAccountWallet = {
  __typename?: "TrustAccountWallet";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  trustAccount: TrustAccount;
  trustAccountId: Scalars["ID"];
  entityReference: Scalars["AiloRN"];
  type: TrustAccountWalletType;
  code: Scalars["String"];
  entity: TrustAccountWalletEntity;
};

export type TrustAccountWalletAggregateGroupBy = {
  __typename?: "TrustAccountWalletAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  trustAccountId?: Maybe<Scalars["ID"]>;
  entityReference?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountWalletConnection = {
  __typename?: "TrustAccountWalletConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<TrustAccountWalletEdge>;
};

export type TrustAccountWalletCountAggregate = {
  __typename?: "TrustAccountWalletCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  trustAccountId?: Maybe<Scalars["Int"]>;
  entityReference?: Maybe<Scalars["Int"]>;
};

export type TrustAccountWalletEdge = {
  __typename?: "TrustAccountWalletEdge";
  /** The node containing the TrustAccountWallet */
  node: TrustAccountWallet;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type TrustAccountWalletEntity =
  | LegalEntityCompanion
  | ManagementFolio
  | Tenancy
  | Supplier
  | BondAuthority;

export type TrustAccountWalletFilter = {
  and?: Maybe<Array<TrustAccountWalletFilter>>;
  or?: Maybe<Array<TrustAccountWalletFilter>>;
  id?: Maybe<IdFilterComparison>;
  trustAccountId?: Maybe<IdFilterComparison>;
  entityReference?: Maybe<AiloRnFilterComparison>;
};

export type TrustAccountWalletMaxAggregate = {
  __typename?: "TrustAccountWalletMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  trustAccountId?: Maybe<Scalars["ID"]>;
  entityReference?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountWalletMinAggregate = {
  __typename?: "TrustAccountWalletMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  trustAccountId?: Maybe<Scalars["ID"]>;
  entityReference?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountWalletSort = {
  field: TrustAccountWalletSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TrustAccountWalletSortFields {
  Id = "id",
  TrustAccountId = "trustAccountId",
  EntityReference = "entityReference"
}

export enum TrustAccountWalletType {
  ManagementFolio = "MANAGEMENT_FOLIO",
  Tenancy = "TENANCY",
  Agency = "AGENCY",
  BondAuthority = "BOND_AUTHORITY",
  Supplier = "SUPPLIER",
  Investor = "INVESTOR",
  Renter = "RENTER"
}

export type TrustBankAccount = {
  __typename?: "TrustBankAccount";
  agency: Scalars["AiloRN"];
  paymentMethod: PaymentMethodCompanion;
  createdBy: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
};

export type TrustReceipt = {
  __typename?: "TrustReceipt";
  id: Scalars["ID"];
};

export type TrustReceiptAggregateGroupBy = {
  __typename?: "TrustReceiptAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
};

export type TrustReceiptCountAggregate = {
  __typename?: "TrustReceiptCountAggregate";
  id?: Maybe<Scalars["Int"]>;
};

export type TrustReceiptEdge = {
  __typename?: "TrustReceiptEdge";
  /** The node containing the TrustReceipt */
  node: TrustReceipt;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type TrustReceiptMaxAggregate = {
  __typename?: "TrustReceiptMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
};

export type TrustReceiptMinAggregate = {
  __typename?: "TrustReceiptMinAggregate";
  id?: Maybe<Scalars["ID"]>;
};

export type UnacknowledgeThreadFromMessageInput = {
  messageAilorn: Scalars["AiloRN"];
};

export type UnlockReportPeriodInput = {
  month: Scalars["Int"];
  year: Scalars["Int"];
  trustAccountAilorn: Scalars["AiloRN"];
};

export type UnpersistedChargeCycle = {
  __typename?: "UnpersistedChargeCycle";
  amount: Money;
  endDate?: Maybe<Scalars["Date"]>;
  startDate: Scalars["Date"];
  taxAmount: Money;
};

export type UnreadMessagesCountInput = {
  organisationAilorn: Scalars["AiloRN"];
};

export enum UnvoidableTenancyReason {
  TenancyIsNotIngoing = "TenancyIsNotIngoing",
  DepositHasBeenPaid = "DepositHasBeenPaid"
}

export type UpdateActionInput = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  assigneeAilorn?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<ActionType>;
  dueDate?: Maybe<Scalars["DateTime"]>;
  meta?: Maybe<Scalars["JSONObject"]>;
};

export type UpdateAiloFormInput = {
  name?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  updateResponses?: Maybe<Array<UpdateAiloFormResponseInput>>;
  /** creates or overwrites responses for specific fields */
  createResponses?: Maybe<Array<CreateAiloFormCreateAiloFormResponseInput>>;
  deleteResponseForFields?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateAiloFormResponseInput = {
  payload: AiloFormResponsePayloadInput;
  id: Scalars["ID"];
};

export type UpdateAiloFormResult = {
  __typename?: "UpdateAiloFormResult";
  form: AiloForm;
};

export type UpdateAutoWithdrawPlanInputV2 = {
  autoWithdrawPlanId: Scalars["ID"];
  paymentMethodDestinations: Array<PaymentMethodDestinationInput>;
  setAsideAmount?: Maybe<MoneyInput>;
};

export type UpdateBondInput = {
  tenancyAilorn: Scalars["AiloRN"];
  reference?: Maybe<Scalars["String"]>;
  amount?: Maybe<MoneyInput>;
  bondAccountAilorn?: Maybe<Scalars["AiloRN"]>;
  claimedOn?: Maybe<Scalars["Date"]>;
};

export type UpdateCompanyProfileDetailsInput = {
  id: Scalars["AiloRN"];
  registeredEntityId?: Maybe<Scalars["String"]>;
  registeredEntityName?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["TimeZone"]>;
  accountsEmail?: Maybe<Array<Scalars["String"]>>;
};

export type UpdateFeeBlueprintInput = {
  id: Scalars["ID"];
  archived?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  taxCategoryId?: Maybe<Scalars["ID"]>;
  description?: Maybe<Scalars["String"]>;
  event?: Maybe<FeeEventType>;
  fixedAmount?: Maybe<MoneyInput>;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  taxTreatment?: Maybe<TaxTreatment>;
  anniversaryDay?: Maybe<Scalars["Int"]>;
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  archiveReason?: Maybe<Scalars["String"]>;
};

export type UpdateFeeInput = {
  id: Scalars["String"];
  amount?: Maybe<MoneyInput>;
  taxAmount?: Maybe<MoneyInput>;
  percentage?: Maybe<Scalars["Float"]>;
  baseAmount?: Maybe<MoneyInput>;
  description?: Maybe<Scalars["String"]>;
  /** Must be either `true` or `null` */
  archived?: Maybe<Scalars["Boolean"]>;
  archiveReason?: Maybe<Scalars["String"]>;
};

export type UpdateInspectionAppointmentInput = {
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
};

export type UpdateInspectionAppointmentResult = {
  __typename?: "UpdateInspectionAppointmentResult";
  inspectionAppointment: InspectionAppointment;
};

export type UpdateInspectionAreaInput = {
  index?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  inspected?: Maybe<Scalars["Boolean"]>;
  notes?: Maybe<Scalars["String"]>;
  condition?: Maybe<InspectionAreaCondition>;
  id: Scalars["ID"];
  createFiles?: Maybe<Array<CreateInspectionAreaInputFileInput>>;
  removeFiles?: Maybe<Array<Scalars["ID"]>>;
  updateFeatures?: Maybe<Array<UpdateInspectionFeatureInput>>;
  createFeatures?: Maybe<Array<CreateInspectionAreaInputFeatureInput>>;
  removeFeatures?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateInspectionAreaResult = {
  __typename?: "UpdateInspectionAreaResult";
  inspectionArea: InspectionArea;
};

export type UpdateInspectionFeatureInput = {
  name?: Maybe<Scalars["String"]>;
  index?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  isClean?: Maybe<Scalars["Boolean"]>;
  isUndamaged?: Maybe<Scalars["Boolean"]>;
  isWorking?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  createFiles?: Maybe<Array<CreateInspectionFeatureInputFileInput>>;
  removeFiles?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateInspectionFeatureResult = {
  __typename?: "UpdateInspectionFeatureResult";
  inspectionFeature: InspectionFeature;
};

export type UpdateInspectionInput = {
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  ailoFormAilorn?: Maybe<Scalars["AiloRN"]>;
  id: Scalars["ID"];
  updateAreas?: Maybe<Array<UpdateInspectionAreaInput>>;
  createAreas?: Maybe<Array<CreateInspectionInputAreaInput>>;
  removeAreas?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateInspectionResult = {
  __typename?: "UpdateInspectionResult";
  inspection: Inspection;
};

export type UpdateKeyInput = {
  code: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type UpdateLiabilityPaymentPlanInput = {
  liabilityPaymentPlanId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
  paymentMethodId: Scalars["ID"];
};

export type UpdateManagementAgreementInput = {
  id: Scalars["ID"];
  startDate?: Maybe<Scalars["String"]>;
  fixedTermEndDate?: Maybe<Scalars["String"]>;
  /** In ISO-8601 duration format */
  inspectionFrequency?: Maybe<Scalars["String"]>;
};

export type UpdateManagementAgreementPayload = {
  __typename?: "UpdateManagementAgreementPayload";
  managementAgreement?: Maybe<ManagementAgreement>;
};

export type UpdateManagementFeeBlueprintInput = {
  id: Scalars["ID"];
  archived?: Maybe<Scalars["Boolean"]>;
  fixedAmount?: Maybe<MoneyInput>;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  taxTreatment?: Maybe<TaxTreatment>;
  description?: Maybe<Scalars["String"]>;
};

export type UpdateManagementFeeInput = {
  managementAgreementAiloRN: Scalars["AiloRN"];
  percent: Scalars["Float"];
};

export type UpdateMigratingManagementInput = {
  migratingManagementId: Scalars["ID"];
  migrateAsVacant?: Maybe<Scalars["Boolean"]>;
};

export type UpdateOneKeyInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateKeyInput;
};

export type UpdateOneTrustAccountInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateTrustAccountInput;
};

export type UpdatePersonProfileDetailsInput = {
  id: Scalars["AiloRN"];
  legalFirstName?: Maybe<Scalars["String"]>;
  legalMiddleName?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  photoFileId?: Maybe<Scalars["AiloRN"]>;
  birthDate?: Maybe<Scalars["Date"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type UpdateProjectInput = {
  id: Scalars["ID"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  assigneeAilorn: Scalars["AiloRN"];
  type: ProjectType;
  dueDate: Scalars["Date"];
  fileAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type UpdatePropertyInput = {
  id: Scalars["ID"];
  address: PropertyInput;
};

export type UpdateRecurringFeeBlueprintInput = {
  id: Scalars["AiloRN"];
  archived?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  taxCategoryId?: Maybe<Scalars["ID"]>;
  description?: Maybe<Scalars["String"]>;
  fixedAmount?: Maybe<MoneyInput>;
  taxTreatment?: Maybe<TaxTreatment>;
  anniversaryDay?: Maybe<Scalars["Int"]>;
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  archiveReason?: Maybe<Scalars["String"]>;
};

export type UpdateRecurringFeeInput = {
  id: Scalars["AiloRN"];
  /**
   * If provided, must contain at least one schedule.
   * Replaces any existing future schedules with the provided list.
   */
  futureSchedules?: Maybe<Array<RecurringFeeScheduleInput>>;
  description?: Maybe<Scalars["String"]>;
};

export type UpdateReiTokenInput = {
  /** REI Token */
  token?: Maybe<Scalars["String"]>;
  /** State the REI account was linked to */
  supportedAustralianState?: Maybe<AustralianState>;
  /** AiloRN of person the token is associated with */
  personAilorn?: Maybe<Scalars["AiloRN"]>;
  /** AiloRN of agency organisation */
  organisationAilorn?: Maybe<Scalars["AiloRN"]>;
  id: Scalars["ID"];
};

export type UpdateSupplierInput = {
  id: Scalars["ID"];
  abn?: Maybe<Scalars["String"]>;
  registeredEntityName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  address?: Maybe<SupplierAddressInput>;
  emailAddress?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<SupplierPaymentMethodInput>;
  replaceExistingDueBillsPaymentMethod?: Maybe<Scalars["Boolean"]>;
};

export type UpdateTenancyAgreementInput = {
  id: Scalars["ID"];
  startDate: Scalars["LocalDate"];
  /** If not provided, the mutation will remove the existing `fixedTermEndDate` */
  fixedTermEndDate?: Maybe<Scalars["LocalDate"]>;
};

export type UpdateTenancyAgreementPayload = {
  __typename?: "UpdateTenancyAgreementPayload";
  tenancyAgreement?: Maybe<TenancyAgreement>;
};

export type UpdateTrustAccountInput = {
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bsb?: Maybe<Scalars["String"]>;
};

export type UpsertAutoPayLiabilityStatusInput = {
  /** Maximum payable amount that auto pay will cover, default to be 40k and configurable via env var: ledger.auto-pay-liability.maximum-payment-amount */
  maximumPaymentAmount?: Maybe<MoneyInput>;
  /**
   * Legal Entity ARN (Person/Company) if it is meant to pay liabilities using their "personal" wallet,
   * or Management folio ARN, if it's meant to pay liabilities using management folio wallet.
   */
  payerId?: Maybe<Scalars["AiloRN"]>;
  /** Current user legal entity ID. Will get deprecated soon, once we are able to get user person ARN from new authz token. */
  payerLegalEntity: Scalars["AiloRN"];
  paymentMethodId?: Maybe<Scalars["ID"]>;
};

export type UpsertCrnInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  crn: Scalars["String"];
};

export type UpsertCrnOutput = {
  __typename?: "UpsertCrnOutput";
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  crn: Scalars["String"];
};

export type UpsertTenancyBondClaim = {
  id?: Maybe<Scalars["ID"]>;
  amount: MoneyInput;
  liabilityAiloRN: Scalars["AiloRN"];
  claimOrder: Scalars["Int"];
  claimStatus?: Maybe<TenancyBondClaimStatus>;
};

export type UpsertTenancyBondClaims = {
  bondId: Scalars["ID"];
  claims: Array<UpsertTenancyBondClaim>;
};

export type User = {
  __typename?: "User";
  id: Scalars["Int"];
  ailoRN: Scalars["AiloRN"];
  auth0Id?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  modifiedAt?: Maybe<Scalars["DateTime"]>;
  onboardedAt?: Maybe<Scalars["DateTime"]>;
  onboardingCompletedAt?: Maybe<Scalars["DateTime"]>;
  person: Person;
  organisations: Array<Organisation>;
  onboardingTasks: Array<UserOnboardingTask>;
  /** Returns true if the user has a role (via person) in any org with type "Agency". */
  isAgent?: Maybe<Scalars["Boolean"]>;
};

export type UserOrganisationsArgs = {
  filter?: Maybe<OrganisationsFilter>;
};

export type UserOnboardingTask = {
  __typename?: "UserOnboardingTask";
  /** @deprecated Use `onboardingTask.id` */
  onboardingTaskId: OnboardingTaskId;
  onboardingTask: OnboardingTask;
  completedAt: Scalars["DateTime"];
};

export enum VacatingReason {
  WithinTermsOfAgreement = "WithinTermsOfAgreement",
  LeaseBreak = "LeaseBreak",
  Termination = "Termination",
  LostManagement = "LostManagement",
  OffBoarded = "OffBoarded"
}

export type VacatingTenancyProjectMeta = {
  __typename?: "VacatingTenancyProjectMeta";
  management: Management;
  tenancy: Tenancy;
  inspectionAppointment?: Maybe<InspectionAppointment>;
};

export enum ValidateFileErrorCode {
  FileNotFound = "FileNotFound",
  FileNotUploaded = "FileNotUploaded",
  InvalidFileType = "InvalidFileType"
}

export type ValidateFileResponse = {
  __typename?: "ValidateFileResponse";
  ok: Scalars["Boolean"];
  /** Present if `ok === true`. */
  file?: Maybe<File>;
  /** Present if `ok === false`. */
  errorCode?: Maybe<ValidateFileErrorCode>;
};

export enum VerifyEmailErrorCode {
  VerificationCodeNotFound = "VERIFICATION_CODE_NOT_FOUND",
  VerificationCodeExpired = "VERIFICATION_CODE_EXPIRED",
  EmailUsedByAnotherPerson = "EMAIL_USED_BY_ANOTHER_PERSON"
}

export type VerifyEmailErrorResponse = {
  __typename?: "VerifyEmailErrorResponse";
  errorCode: VerifyEmailErrorCode;
};

export type VerifyEmailResponse =
  | VerifyEmailSuccessResponse
  | VerifyEmailErrorResponse;

export type VerifyEmailSuccessResponse = {
  __typename?: "VerifyEmailSuccessResponse";
  user: User;
};

export type VerifyPhoneVerificationCodeResponse = {
  __typename?: "VerifyPhoneVerificationCodeResponse";
  ok: Scalars["Boolean"];
  error?: Maybe<VerifyPhoneVerificationCodeResponseError>;
};

export type VerifyPhoneVerificationCodeResponseError = {
  __typename?: "VerifyPhoneVerificationCodeResponseError";
  /**
   * Possible values:
   * - "internal-server-error"
   * - "invalid-verification-code"
   * - "verification-not-found"
   * - "invalid-phone-number"
   * - "max-verification-check-attempts-reached"
   * - "max-send-attempts-reached"
   * - "sms-is-not-supported-by-landline-phone-number"
   * - "too-many-concurrent-requests-for-phone-number"
   */
  code: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
};

export type VirtualAccount = PaymentMethod & {
  __typename?: "VirtualAccount";
  accountNumber?: Maybe<Scalars["String"]>;
  ailoRN: Scalars["AiloRN"];
  bsb?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  reference?: Maybe<Scalars["String"]>;
  topUpFee?: Maybe<TransactionFee>;
  virtualAccountId?: Maybe<Scalars["String"]>;
  wallet: Wallet;
};

export type VoidTenancyAbility = {
  __typename?: "VoidTenancyAbility";
  voidable: Scalars["Boolean"];
  unvoidableReason?: Maybe<UnvoidableTenancyReason>;
};

export type VoidTenancyPayload = {
  __typename?: "VoidTenancyPayload";
  tenancy: Tenancy;
};

export type Wallet = {
  __typename?: "Wallet";
  autoPayLiabilityStatus?: Maybe<AutoPayLiabilityStatus>;
  autoWithdrawPlans?: Maybe<PaginatedAutoWithdrawPlans>;
  availableBalance: Money;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  idempotencyKey: Scalars["GeneratedKey"];
  name: Scalars["String"];
  owner: WalletOwner;
  totalBalance: Money;
};

export type WalletAutoWithdrawPlansArgs = {
  disableOwnerFilter?: Maybe<Scalars["Boolean"]>;
  enabled: Scalars["Boolean"];
  payerLegalEntityId?: Maybe<Scalars["AiloRN"]>;
};

export type WalletBalance = {
  __typename?: "WalletBalance";
  availableBalance: Money;
  clearedBalance: Money;
  overdraftAllowance: Money;
  totalBalance: Money;
};

export type WalletBalanceItem = {
  __typename?: "WalletBalanceItem";
  balance: Money;
  id: Scalars["AiloRN"];
  name: Scalars["String"];
  reference: Scalars["AiloRN"];
};

export type WalletBalanceReport = {
  __typename?: "WalletBalanceReport";
  items: Array<Maybe<WalletBalanceItem>>;
};

export type WalletEntry = {
  __typename?: "WalletEntry";
  amount: Money;
  businessTransaction: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  isReversal: Scalars["Boolean"];
  isTopUpFee: Scalars["Boolean"];
  liability?: Maybe<Liability>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: BusinessTxStatusEnum;
};

export type WalletOwner = {
  __typename?: "WalletOwner";
  /** How much money the wallet owner currently owes due to fees */
  dueFeeAmount: Money;
  id: Scalars["ID"];
  reference: Scalars["AiloRN"];
};

export type WalletOwnerLiabilityReport = {
  __typename?: "WalletOwnerLiabilityReport";
  items: Array<Maybe<WalletOwnerLiabilityReportItem>>;
};

export type WalletOwnerLiabilityReportItem = {
  __typename?: "WalletOwnerLiabilityReportItem";
  reference: Scalars["AiloRN"];
  totalBalance: Money;
};

export type WalletStatementData = {
  __typename?: "WalletStatementData";
  closingBalance: WalletBalance;
  lineItemsByStatementTransactionDate: Array<StatementLineItem2>;
  openingBalance: WalletBalance;
  taxSummary: TaxSummaryStatementData;
  transactionLineItemsByStatementTransactionDate?: Maybe<PaginatedStatementTransactionLineItem>;
};

export type WalletStatementDataTransactionLineItemsByStatementTransactionDateArgs =
  {
    paginationParams?: Maybe<PaginationParams>;
  };

export type WalletStatementInput = {
  from: Scalars["Date"];
  to: Scalars["Date"];
  walletOwnerReference: Scalars["AiloRN"];
  zoneId: Scalars["ZoneId"];
};

export type WalletTransferInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  destinationWalletOwner: Scalars["AiloRN"];
  idempotencyKey: Scalars["String"];
  sourceWalletOwner: Scalars["AiloRN"];
};

export type Weight = {
  __typename?: "Weight";
  kgs: Scalars["Long"];
};

export type WithdrawInput = {
  amount: MoneyInput;
  fromWalletId: Scalars["ID"];
  idempotencyKey: Scalars["GeneratedKey"];
  toPaymentMethodId: Scalars["ID"];
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  messagePostedV2?: Maybe<SubscriptionMessageV2>;
};

export type SubscriptionMessageV2 = {
  __typename?: "SubscriptionMessageV2";
  messageAilorn: Scalars["AiloRN"];
  threadAilorn: Scalars["AiloRN"];
  chatAilorn: Scalars["AiloRN"];
};

export type GetCurrentOrgReiTokensQueryVariables = Exact<{
  personAilorn: Scalars["AiloRN"];
  organisationAilorn: Scalars["AiloRN"];
}>;

export type GetCurrentOrgReiTokensQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | { __typename?: "Company"; id: string }
    | {
        __typename?: "Person";
        id: string;
        reiTokensForOrg: Array<
          { __typename?: "ReiToken" } & ReiTokenBaseFragment
        >;
      }
  >;
};

export type ReiTokenBaseFragment = {
  __typename?: "ReiToken";
  id: string;
  token: string;
  email: string;
  supportedState: AustralianState;
};

export type CompanyDisplayDetailsFragment = {
  __typename?: "Company";
  registeredEntityName: string;
};

export type GetLegalEntityDisplayDetailsQueryVariables = Exact<{
  ailoRN: Scalars["AiloRN"];
}>;

export type GetLegalEntityDisplayDetailsQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | ({ __typename: "Company"; id: string } & CompanyDisplayDetailsFragment)
    | ({ __typename: "Person"; id: string } & PersonDisplayDetailsFragment)
  >;
};

export type PersonDisplayDetailsFragment = {
  __typename?: "Person";
  photo?: Maybe<{
    __typename?: "File";
    id: string;
    url?: Maybe<string>;
    thumbnailUrl?: Maybe<string>;
  }>;
} & PersonNamesFragment;

export type LegalEntityCompanionCacheKeysFragmentFragment = {
  __typename?: "LegalEntityCompanion";
  id: string;
  ailoRNV2: string;
};

export type LegalEntityCompanionOrganisationFragmentFragment = {
  __typename?: "LegalEntityCompanion";
  organisation: {
    __typename?: "Organisation";
    id: string;
    ailoRN: string;
    name: string;
  };
} & LegalEntityCompanionCacheKeysFragmentFragment;

export type GetManagementCurrentOrNextFeeScheduleQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetManagementCurrentOrNextFeeScheduleQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    managementFeeSchedule?: Maybe<{
      __typename?: "ManagementFeeSchedule";
      id: string;
      percent: number;
    }>;
  }>;
};

export type GetPaymentReferenceForPayerAndSupplierQueryVariables = Exact<{
  payeeId: Scalars["AiloRN"];
  payerId: Scalars["AiloRN"];
}>;

export type GetPaymentReferenceForPayerAndSupplierQuery = {
  __typename?: "Query";
  paymentReferences?: Maybe<{
    __typename?: "PaginatedPaymentReferences";
    items: Array<
      Maybe<{
        __typename?: "PaymentReference";
        id: string;
        crn?: Maybe<string>;
      }>
    >;
  }>;
};

export type GetLastSupplierBillTaxAutoCalculatedQueryVariables = Exact<{
  payeeId: Scalars["AiloRN"];
}>;

export type GetLastSupplierBillTaxAutoCalculatedQuery = {
  __typename?: "Query";
  bills?: Maybe<{
    __typename?: "PaginatedBills";
    items: Array<{
      __typename?: "Bill";
      ailoRN: string;
      taxAutoCalculated?: Maybe<boolean>;
    }>;
  }>;
};

export type GetSuppliersQueryVariables = Exact<{
  id: Scalars["ID"];
  suppliersCursor: BillDefaultPageCursor;
}>;

export type GetSuppliersQuery = {
  __typename?: "Query";
  organisation?: Maybe<{
    __typename?: "Organisation";
    id: string;
    suppliers: {
      __typename?: "PaginatedSuppliers";
      pageInfo: {
        __typename?: "PageInfo";
        total: number;
        hasMore: boolean;
        nextCursor?: Maybe<string>;
      };
      items: Array<{
        __typename?: "Supplier";
        ailoRN: string;
        name?: Maybe<string>;
        abn?: Maybe<string>;
      }>;
    };
  }>;
};

export type GetLastSupplierBillTaxCategoryQueryVariables = Exact<{
  payeeId: Scalars["AiloRN"];
}>;

export type GetLastSupplierBillTaxCategoryQuery = {
  __typename?: "Query";
  bills?: Maybe<{
    __typename?: "PaginatedBills";
    items: Array<{
      __typename?: "Bill";
      ailoRN: string;
      taxCategory: { __typename?: "TaxCategory"; id: string };
    }>;
  }>;
};

export type GetTaxCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTaxCategoriesQuery = {
  __typename?: "Query";
  taxCategories: Array<{
    __typename?: "TaxCategory";
    id: string;
    name: string;
  }>;
};

export type CreateBillMutationVariables = Exact<{
  billDetails: BillInput;
}>;

export type CreateBillMutation = {
  __typename?: "Mutation";
  createBill?: Maybe<{ __typename?: "Bill"; ailoRN: string }>;
};

export type CreateBillLiabilityStateMutationVariables = Exact<{
  billLiabilityStateDetails: BillLiabilityStateInput;
}>;

export type CreateBillLiabilityStateMutation = {
  __typename?: "Mutation";
  createBillLiabilityState?: Maybe<{
    __typename?: "BillLiabilityState";
    paymentStatus?: Maybe<string>;
  }>;
};

export type FindOrCreateInternalSupplierMutationVariables = Exact<{
  supplierDetails: InternalSupplierInput;
}>;

export type FindOrCreateInternalSupplierMutation = {
  __typename?: "Mutation";
  findOrCreateInternalSupplier?: Maybe<{
    __typename?: "Supplier";
    ailoRN: string;
  }>;
};

export type FindOrCreatePaymentReferenceMutationVariables = Exact<{
  paymentReferenceDetails: PaymentReferenceInput;
}>;

export type FindOrCreatePaymentReferenceMutation = {
  __typename?: "Mutation";
  findOrCreatePaymentReference?: Maybe<{
    __typename?: "PaymentReference";
    id: string;
    supplierPaymentMethodReference?: Maybe<string>;
    crn?: Maybe<string>;
    paymentDescription?: Maybe<string>;
    supplier: { __typename?: "Supplier"; ailoRN: string };
  }>;
};

export type GetPaymentMethodByOwnerQueryVariables = Exact<{
  owner: Scalars["AiloRN"];
}>;

export type GetPaymentMethodByOwnerQuery = {
  __typename?: "Query";
  paymentMethodsByOwner?: Maybe<{
    __typename?: "PaginatedPaymentMethods";
    pageInfo?: Maybe<{ __typename?: "PageInfo"; total: number }>;
    items: Array<
      Maybe<
        | {
            __typename: "BankAccount";
            accountName?: Maybe<string>;
            accountNumber?: Maybe<string>;
            bsb?: Maybe<string>;
            id: string;
          }
        | { __typename: "BPay"; billerCode: string; id: string }
        | { __typename: "CreditCard"; id: string }
        | { __typename: "VirtualAccount"; id: string }
      >
    >;
  }>;
};

export type GetBillsCountQueryVariables = Exact<{
  organisationId: Scalars["AiloRN"];
  paid?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Array<BillStatus>>;
  dueDate?: Maybe<LocalDateRangeCondition>;
  invoiceNumber?: Maybe<Array<Scalars["String"]>>;
  payeeId?: Maybe<Array<Scalars["AiloRN"]>>;
}>;

export type GetBillsCountQuery = {
  __typename?: "Query";
  bills?: Maybe<{
    __typename?: "PaginatedBills";
    pageInfo: { __typename?: "BidirectionalPageInfo"; total: number };
  }>;
};

export type GetUpcomingAndPaidBillsQueryVariables = Exact<{
  pageCursor: PageCursorWithoutSort;
  payerId?: Maybe<Array<Scalars["AiloRN"]>>;
}>;

export type GetUpcomingAndPaidBillsQuery = {
  __typename?: "Query";
  upcomingBills?: Maybe<{
    __typename?: "PaginatedBills";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
    };
    items: Array<{
      __typename?: "Bill";
      ailoRN: string;
      status: BillStatus;
      agencyStatus: BillAgencyStatus;
      dueDateV2: string;
      amount: { __typename?: "Money"; cents: number };
      supplier?: Maybe<{
        __typename?: "Supplier";
        ailoRN: string;
        name?: Maybe<string>;
        internalReference?: Maybe<string>;
        internalLegalEntity?: Maybe<
          {
            __typename?: "LegalEntityCompanion";
          } & LegalEntityCompanionOrganisationFragmentFragment
        >;
      }>;
      liabilityState?: Maybe<{
        __typename?: "BillLiabilityState";
        payByDateV2?: Maybe<string>;
        paymentStatus?: Maybe<string>;
        paidAt?: Maybe<string>;
        dueAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
      }>;
    }>;
  }>;
  paidBills?: Maybe<{
    __typename?: "PaginatedBills";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
    };
    items: Array<{
      __typename?: "Bill";
      ailoRN: string;
      status: BillStatus;
      agencyStatus: BillAgencyStatus;
      dueDateV2: string;
      amount: { __typename?: "Money"; cents: number };
      supplier?: Maybe<{
        __typename?: "Supplier";
        ailoRN: string;
        name?: Maybe<string>;
        internalReference?: Maybe<string>;
        internalLegalEntity?: Maybe<
          {
            __typename?: "LegalEntityCompanion";
          } & LegalEntityCompanionOrganisationFragmentFragment
        >;
      }>;
      liabilityState?: Maybe<{
        __typename?: "BillLiabilityState";
        payByDateV2?: Maybe<string>;
        paymentStatus?: Maybe<string>;
        paidAt?: Maybe<string>;
        dueAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
      }>;
    }>;
  }>;
};

export type ArchiveBillMutationVariables = Exact<{
  billId: Scalars["ID"];
  reason?: Maybe<Scalars["String"]>;
}>;

export type ArchiveBillMutation = {
  __typename?: "Mutation";
  archiveBill?: Maybe<{
    __typename?: "Bill";
    ailoRN: string;
    status: BillStatus;
    agencyStatus: BillAgencyStatus;
    archivableByOrganisation: boolean;
    organisationArchiveReason?: Maybe<string>;
  }>;
};

export type GetBillArchiveFormDataQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type GetBillArchiveFormDataQuery = {
  __typename?: "Query";
  billById: {
    __typename?: "Bill";
    ailoRN: string;
    status: BillStatus;
    agencyStatus: BillAgencyStatus;
    archivableByOrganisation: boolean;
    organisationArchiveReason?: Maybe<string>;
    management?: Maybe<
      { __typename?: "Management"; id: string } & ManagementPropertyFragment
    >;
    supplier?: Maybe<{
      __typename?: "Supplier";
      ailoRN: string;
      name?: Maybe<string>;
      internalReference?: Maybe<string>;
      internalLegalEntity?: Maybe<
        {
          __typename?: "LegalEntityCompanion";
        } & LegalEntityCompanionOrganisationFragmentFragment
      >;
    }>;
    liabilityState?: Maybe<{
      __typename?: "BillLiabilityState";
      payByDateV2?: Maybe<string>;
      paymentStatus?: Maybe<string>;
    }>;
  };
};

export type GetBillHeaderDataQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type GetBillHeaderDataQuery = {
  __typename?: "Query";
  billById: {
    __typename?: "Bill";
    ailoRN: string;
    dueDateV2: string;
    status: BillStatus;
    agencyStatus: BillAgencyStatus;
    archivableByOrganisation: boolean;
    supplier?: Maybe<{
      __typename?: "Supplier";
      ailoRN: string;
      name?: Maybe<string>;
      internalReference?: Maybe<string>;
      internalLegalEntity?: Maybe<
        {
          __typename?: "LegalEntityCompanion";
        } & LegalEntityCompanionOrganisationFragmentFragment
      >;
    }>;
    liabilityState?: Maybe<{
      __typename?: "BillLiabilityState";
      payByDateV2?: Maybe<string>;
      paymentStatus?: Maybe<string>;
      paidAt?: Maybe<string>;
      dueAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
    }>;
    amount: { __typename?: "Money"; cents: number };
  };
};

export type GetBillManagementIdQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type GetBillManagementIdQuery = {
  __typename?: "Query";
  billById: {
    __typename?: "Bill";
    ailoRN: string;
    management?: Maybe<{ __typename?: "Management"; id: string }>;
  };
};

export type GetClaimTenancyBondDetailsQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
  paidBills?: Maybe<Scalars["Boolean"]>;
}>;

export type GetClaimTenancyBondDetailsQuery = {
  __typename?: "Query";
  tenancy?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      endDate?: Maybe<string>;
      management?: Maybe<{
        __typename?: "Management";
        id: string;
        managingEntity?: Maybe<{
          __typename?: "Company";
          id: string;
          tenancyBondVirtualAccounts: Array<{
            __typename?: "TenancyBondAccount";
            id: string;
            paymentMethodCompanion?: Maybe<{
              __typename?: "PaymentMethodCompanion";
              id: string;
              paymentMethod:
                | { __typename?: "BankAccount"; id: string }
                | { __typename?: "BPay"; id: string }
                | { __typename?: "CreditCard"; id: string }
                | {
                    __typename?: "VirtualAccount";
                    accountNumber?: Maybe<string>;
                    bsb?: Maybe<string>;
                    id: string;
                  };
            }>;
          }>;
        }>;
      }>;
      bond?: Maybe<{
        __typename?: "TenancyBond";
        id: string;
        reference?: Maybe<string>;
        status: TenancyBondStatus;
        failureReason?: Maybe<BondDisbursalFailureReason>;
        amount: { __typename?: "Money"; cents: number };
        fundsReceived?: Maybe<
          Array<{
            __typename?: "FundReceived";
            receivedAt: string;
            amount: { __typename?: "Money"; cents: number };
          }>
        >;
        claims?: Maybe<
          Array<{ __typename?: "TenancyBondClaim" } & TenancyBondClaimFragment>
        >;
      }>;
      liability?: Maybe<{
        __typename?: "Liability";
        reference: string;
        overdueAmount: { __typename?: "Money"; cents: number };
        terminationAmountDue?: Maybe<{ __typename?: "Money"; cents: number }>;
      }>;
      billsAsPayer?: Maybe<{
        __typename?: "PaginatedBills";
        items: Array<{
          __typename?: "Bill";
          ailoRN: string;
          dueDateV2: string;
          amount: { __typename?: "Money"; cents: number };
          payee?: Maybe<
            | { __typename?: "Supplier"; ailoRN: string; name?: Maybe<string> }
            | { __typename?: "Management"; ailoRN: string }
            | { __typename?: "LegalEntityCompanion"; ailoRN: string }
          >;
          taxCategory: { __typename?: "TaxCategory"; name: string };
        }>;
      }>;
      tenantships?: Maybe<{
        __typename?: "PaginatedTenantships";
        items: Array<{
          __typename?: "Tenantship";
          tenant?: Maybe<
            | ({ __typename?: "Company" } & LegalEntityName_Company_Fragment)
            | ({ __typename?: "Person" } & LegalEntityName_Person_Fragment)
          >;
        }>;
      }>;
    } & TenancyPropertyFragment
  >;
};

export type TenancyBondClaimFragment = {
  __typename?: "TenancyBondClaim";
  id: string;
  liabilityAiloRN: string;
  claimOrder: number;
  amount: { __typename?: "Money"; cents: number };
};

export type UpdateBondStatusMutationVariables = Exact<{
  bondAilorn: Scalars["AiloRN"];
  status: TenancyBondStatus;
}>;

export type UpdateBondStatusMutation = {
  __typename?: "Mutation";
  updateTenancyBondStatus: {
    __typename?: "TenancyBond";
    id: string;
    status: TenancyBondStatus;
  };
};

export type UpdateManagementFeeMutationVariables = Exact<{
  input: UpdateManagementFeeInput;
}>;

export type UpdateManagementFeeMutation = {
  __typename?: "Mutation";
  updateManagementFee: { __typename?: "ManagementFeeSchedule"; id: string };
};

export type UpdateOneOffFeeMutationVariables = Exact<{
  input: UpdateFeeInput;
}>;

export type UpdateOneOffFeeMutation = {
  __typename?: "Mutation";
  updateFee?: Maybe<{
    __typename?: "Fee";
    id: string;
    percentage?: Maybe<number>;
    description?: Maybe<string>;
    ailorn: string;
    amount: { __typename?: "Money"; cents: number };
  }>;
};

export type UpdateRecurringFeeMutationVariables = Exact<{
  input: UpdateRecurringFeeInput;
}>;

export type UpdateRecurringFeeMutation = {
  __typename?: "Mutation";
  updateRecurringFee?: Maybe<{
    __typename?: "RecurringFee";
    description?: Maybe<string>;
    id: string;
    ailorn: string;
  }>;
};

export type FeeFieldsFragment = {
  __typename?: "Fee";
  id: string;
  type: FeeType;
  percentage?: Maybe<number>;
  description?: Maybe<string>;
  status?: Maybe<FeeStatus>;
  createdAt: string;
  archivedAt?: Maybe<string>;
  ailorn: string;
  amount: { __typename?: "Money"; cents: number };
  taxAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
  blueprint?: Maybe<{
    __typename?: "FeeBlueprint";
    id: string;
    name: string;
    oneWeekRentPercentage?: Maybe<number>;
    taxTreatment: TaxTreatment;
    ailorn: string;
    fixedAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
  }>;
  managementFeeBlueprint?: Maybe<{
    __typename?: "ManagementFeeBlueprint";
    id: string;
    ailorn: string;
    oneWeekRentPercentage?: Maybe<number>;
    taxTreatment: TaxTreatment;
    feeBlueprint: {
      __typename?: "FeeBlueprint";
      id: string;
      name: string;
      ailorn: string;
    };
    fixedAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
  }>;
  management: {
    __typename?: "Management";
    id: string;
    ailorn: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      ailorn: string;
      currentRent?: Maybe<{
        __typename?: "Rent";
        id: string;
        dailyRate: number;
      }>;
    }>;
  } & ManagementPropertyFragment;
};

export type GetExpenseQueryVariables = Exact<{
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  isFee?: Maybe<Scalars["Boolean"]>;
  isBill?: Maybe<Scalars["Boolean"]>;
}>;

export type GetExpenseQuery = {
  __typename?: "Query";
  fee?: Maybe<{ __typename?: "Fee" } & FeeDetailsFragment>;
  billById: { __typename?: "Bill" } & BillDetailsFragment;
  fees?: Maybe<{
    __typename?: "PaginatedFees";
    items: Array<{
      __typename?: "Fee";
      id: string;
      percentage?: Maybe<number>;
      amount: { __typename?: "Money"; cents: number };
    }>;
  }>;
};

export type FeeDetailsFragment = {
  __typename?: "Fee";
  management: {
    __typename?: "Management";
    managingEntity?: Maybe<{
      __typename?: "Company";
      id: string;
      ailorn: string;
      organisation: {
        __typename?: "Organisation";
        id: string;
        name: string;
        ailorn: string;
      };
    }>;
    owners?: Maybe<
      Array<
        Maybe<{
          __typename?: "Ownership";
          owner?: Maybe<
            | {
                __typename: "Company";
                registeredEntityName: string;
                id: string;
              }
            | {
                __typename: "Person";
                firstName: string;
                lastName?: Maybe<string>;
                phoneNo?: Maybe<string>;
                emailAddress?: Maybe<string>;
                id: string;
              }
          >;
        }>
      >
    >;
  };
  liability?: Maybe<{
    __typename?: "Liability";
    id: string;
    lastPaymentDate?: Maybe<string>;
  }>;
  taxCategory: { __typename?: "FeeTaxCategory"; name: string };
} & FeeFieldsFragment;

export type BillDetailsFragment = {
  __typename?: "Bill";
  id: string;
  description?: Maybe<string>;
  archivableByOrganisation: boolean;
  agencyStatus: BillAgencyStatus;
  invoiceNumber?: Maybe<string>;
  createdAt: string;
  organisationArchiveReason?: Maybe<string>;
  applyManagementFee: boolean;
  ailorn: string;
  dueDate: string;
  issueDate: string;
  paymentReference?: Maybe<{
    __typename?: "PaymentReference";
    id: string;
    crn?: Maybe<string>;
    supplierPaymentMethodCompanion?: Maybe<{
      __typename?: "PaymentMethodCompanion";
      paymentMethod:
        | {
            __typename: "BankAccount";
            bsb?: Maybe<string>;
            accountNumber?: Maybe<string>;
            accountName?: Maybe<string>;
            id: string;
          }
        | { __typename: "BPay"; billerCode: string; id: string }
        | { __typename: "CreditCard"; id: string }
        | { __typename: "VirtualAccount"; id: string };
    }>;
  }>;
  amount: { __typename?: "Money"; cents: number };
  payee?: Maybe<
    | {
        __typename: "Supplier";
        id: string;
        name?: Maybe<string>;
        ailorn: string;
      }
    | {
        __typename: "Management";
        id: string;
        ailorn: string;
        managingEntity?: Maybe<{
          __typename?: "Company";
          id: string;
          registeredEntityName: string;
          ailorn: string;
        }>;
        owners?: Maybe<
          Array<
            Maybe<{
              __typename?: "Ownership";
              managementId: string;
              owner?: Maybe<
                | {
                    __typename: "Company";
                    id: string;
                    registeredEntityName: string;
                    ailorn: string;
                  }
                | {
                    __typename: "Person";
                    id: string;
                    firstName: string;
                    lastName?: Maybe<string>;
                    ailorn: string;
                  }
              >;
            }>
          >
        >;
      }
    | ({
        __typename: "LegalEntityCompanion";
      } & LegalEntityCompanionOrganisationFragmentFragment)
  >;
  payer?: Maybe<
    | {
        __typename: "Tenancy";
        tenantships?: Maybe<{
          __typename?: "PaginatedTenantships";
          items: Array<{
            __typename?: "Tenantship";
            tenant?: Maybe<
              | {
                  __typename: "Company";
                  registeredEntityName: string;
                  id: string;
                }
              | {
                  __typename: "Person";
                  firstName: string;
                  lastName?: Maybe<string>;
                  phoneNo?: Maybe<string>;
                  emailAddress?: Maybe<string>;
                  id: string;
                }
            >;
          }>;
        }>;
      }
    | {
        __typename: "Management";
        ownerships?: Maybe<{
          __typename?: "PaginatedOwnerships";
          items: Array<{
            __typename?: "Ownership";
            owner?: Maybe<
              | {
                  __typename: "Company";
                  registeredEntityName: string;
                  id: string;
                }
              | {
                  __typename: "Person";
                  firstName: string;
                  lastName?: Maybe<string>;
                  phoneNo?: Maybe<string>;
                  emailAddress?: Maybe<string>;
                  id: string;
                }
            >;
          }>;
        }>;
      }
    | { __typename: "LegalEntityCompanion" }
  >;
  attachments: Array<{
    __typename?: "BillAttachment";
    id: string;
    file?: Maybe<{ __typename?: "File" } & FileDetailsFragment>;
  }>;
  lineItems?: Maybe<{
    __typename?: "PaginatedLineItems";
    items: Array<{
      __typename?: "LineItem";
      id: string;
      description: string;
      isTax: boolean;
      ailorn: string;
      taxInclusiveAmount: { __typename?: "Money"; cents: number };
      taxAmount: { __typename?: "Money"; cents: number };
    }>;
  }>;
  liabilityState?: Maybe<{
    __typename?: "BillLiabilityState";
    paidAt?: Maybe<string>;
  }>;
  taxCategory: { __typename?: "TaxCategory"; name: string };
  management?: Maybe<
    {
      __typename?: "Management";
      id: string;
      currentOrNextManagementFeeSchedule?: Maybe<{
        __typename?: "ManagementFeeSchedule";
        id: string;
        percent: number;
      }>;
    } & ManagementPropertyFragment
  >;
};

export type GetFeeBlueprintsAndAppliedRecurringFeesQueryVariables = Exact<{
  agencyOrganisationId: Scalars["ID"];
  blueprintType?: Maybe<FeeBlueprintType>;
  managementId: Scalars["ID"];
  managementAilorn: Scalars["AiloRN"];
  cursor?: Maybe<PageCursorWithoutSort>;
}>;

export type GetFeeBlueprintsAndAppliedRecurringFeesQuery = {
  __typename?: "Query";
  blueprints?: Maybe<{
    __typename?: "PaginatedFeeBlueprints";
    items: Array<{
      __typename?: "FeeBlueprint";
      id: string;
      name: string;
      taxTreatment: TaxTreatment;
      frequency?: Maybe<FeeFrequency>;
      anniversaryDay?: Maybe<number>;
      anniversaryMonth?: Maybe<number>;
      type: FeeBlueprintType;
      ailorn: string;
      fixedAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
    }>;
  }>;
  recurringFees?: Maybe<
    Array<{
      __typename?: "RecurringFee";
      ailoRN: string;
      id: string;
      blueprint: { __typename?: "FeeBlueprint"; id: string; ailoRN: string };
    }>
  >;
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    ailorn: string;
    managingEntity?: Maybe<{
      __typename?: "Company";
      id: string;
      ailorn: string;
    }>;
    currentOrNextManagementAgreement?: Maybe<{
      __typename?: "ManagementAgreement";
      id: string;
      ailorn: string;
    }>;
  }>;
};

export type GetUpcomingAndPaidBillsAndFeesQueryVariables = Exact<{
  pageSize?: Maybe<Scalars["Int"]>;
  payerAilorn: Scalars["AiloRN"];
  payerId: Scalars["ID"];
  payerIsManagement: Scalars["Boolean"];
}>;

export type GetUpcomingAndPaidBillsAndFeesQuery = {
  __typename?: "Query";
  upcomingBills?: Maybe<{
    __typename?: "PaginatedBills";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
    };
    items: Array<{ __typename?: "Bill" } & UpcomingAndPaidBillFieldsFragment>;
  }>;
  paidBills?: Maybe<{
    __typename?: "PaginatedBills";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
    };
    items: Array<{ __typename?: "Bill" } & UpcomingAndPaidBillFieldsFragment>;
  }>;
  upcomingFees?: Maybe<{
    __typename?: "PaginatedFees";
    pageInfo: {
      __typename?: "PageInfo";
      hasMore: boolean;
      nextCursor?: Maybe<string>;
      total: number;
    };
    items: Array<{ __typename?: "Fee" } & UpcomingAndPaidFeeFieldsFragment>;
  }>;
  paidFees?: Maybe<{
    __typename?: "PaginatedFees";
    pageInfo: {
      __typename?: "PageInfo";
      hasMore: boolean;
      nextCursor?: Maybe<string>;
      total: number;
    };
    items: Array<{ __typename?: "Fee" } & UpcomingAndPaidFeeFieldsFragment>;
  }>;
};

export type UpcomingAndPaidBillFieldsFragment = {
  __typename?: "Bill";
  id: string;
  status: BillStatus;
  agencyStatus: BillAgencyStatus;
  dueDateV2: string;
  issueDateV2: string;
  ailorn: string;
  amount: { __typename?: "Money"; cents: number };
  supplier?: Maybe<{
    __typename?: "Supplier";
    ailoRN: string;
    name?: Maybe<string>;
    internalReference?: Maybe<string>;
    internalLegalEntity?: Maybe<
      {
        __typename?: "LegalEntityCompanion";
      } & LegalEntityCompanionOrganisationFragmentFragment
    >;
  }>;
  taxCategory: { __typename?: "TaxCategory"; id: string; name: string };
  liabilityState?: Maybe<{
    __typename?: "BillLiabilityState";
    payByDateV2?: Maybe<string>;
    paymentStatus?: Maybe<string>;
    paidAt?: Maybe<string>;
    dueAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
  }>;
};

export type UpcomingAndPaidFeeFieldsFragment = {
  __typename?: "Fee";
  taxCategory: { __typename?: "FeeTaxCategory"; id: string; name: string };
  management: {
    __typename?: "Management";
    managingEntity?: Maybe<{
      __typename?: "Company";
      id: string;
      ailoRN: string;
      organisation: {
        __typename?: "Organisation";
        id: string;
        ailoRN: string;
        name: string;
      };
    }>;
  };
} & FeeFieldsFragment;

export type ManagementFeeScheduleFieldsFragment = {
  __typename?: "ManagementFeeSchedule";
  id: string;
  percent: number;
  ailorn: string;
  managementAgreement: {
    __typename?: "ManagementAgreement";
    id: string;
    ailorn: string;
  };
  management: {
    __typename?: "Management";
    id: string;
    ailorn: string;
  } & ManagementPropertyFragment;
};

export type RecurringFeeFieldsFragment = {
  __typename?: "RecurringFee";
  name: string;
  startDate: string;
  frequency: FeeFrequency;
  taxTreatment: TaxTreatment;
  description?: Maybe<string>;
  id: string;
  ailorn: string;
  blueprint: {
    __typename?: "FeeBlueprint";
    id: string;
    name: string;
    taxTreatment: TaxTreatment;
    ailorn: string;
    fixedAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
  };
  nextOccurrence?: Maybe<{
    __typename?: "FeeOccurrence";
    date: string;
    taxInclusiveAmount: { __typename?: "Money"; cents: number };
  }>;
  currentOrNextSchedule?: Maybe<{
    __typename?: "RecurringFeeSchedule";
    id: string;
    startDate: string;
    taxInclusiveAmount: { __typename?: "Money"; cents: number };
  }>;
  management?: Maybe<
    {
      __typename?: "Management";
      id: string;
      ailorn: string;
    } & ManagementPropertyFragment
  >;
};

export type CreateFeeMutationVariables = Exact<{
  input: CreateFeeInput;
}>;

export type CreateFeeMutation = {
  __typename?: "Mutation";
  fee?: Maybe<{ __typename?: "Fee"; id: string }>;
};

export type CreateRecurringFeeMutationVariables = Exact<{
  input: RecurringFeeInput;
}>;

export type CreateRecurringFeeMutation = {
  __typename?: "Mutation";
  createRecurringFee?: Maybe<{
    __typename?: "RecurringFee";
    startDate: string;
    frequency: FeeFrequency;
    taxTreatment: TaxTreatment;
    id: string;
    ailorn: string;
    schedules: Array<{
      __typename?: "RecurringFeeSchedule";
      taxInclusiveAmount: { __typename?: "Money"; cents: number };
    }>;
    blueprint: {
      __typename?: "FeeBlueprint";
      id: string;
      name: string;
      ailorn: string;
    };
  }>;
};

export type CancelOneOffFeeMutationVariables = Exact<{
  input: UpdateFeeInput;
}>;

export type CancelOneOffFeeMutation = {
  __typename?: "Mutation";
  updateFee?: Maybe<{
    __typename?: "Fee";
    id: string;
    ailoRN: string;
    archivedAt?: Maybe<string>;
    status?: Maybe<FeeStatus>;
  }>;
};

export type CancelRecurringFeeMutationVariables = Exact<{
  input: CancelRecurringFeeInput;
}>;

export type CancelRecurringFeeMutation = {
  __typename?: "Mutation";
  cancelRecurringFee?: Maybe<{ __typename?: "RecurringFee"; id: string }>;
};

export type GetManagementRecurringFeesQueryVariables = Exact<{
  managementId: Scalars["ID"];
  managementAiloRN: Scalars["AiloRN"];
}>;

export type GetManagementRecurringFeesQuery = {
  __typename?: "Query";
  management?: Maybe<
    {
      __typename?: "Management";
      id: string;
      currentOrNextManagementFeeSchedule?: Maybe<
        {
          __typename?: "ManagementFeeSchedule";
        } & ManagementFeeScheduleFieldsFragment
      >;
      currentOrNextManagementAgreement?: Maybe<{
        __typename?: "ManagementAgreement";
        id: string;
      }>;
    } & ManagementPropertyFragment
  >;
  recurringFees?: Maybe<
    Array<
      {
        __typename?: "RecurringFee";
        liability?: Maybe<{
          __typename?: "Liability";
          reference: string;
          overdueAmount: { __typename?: "Money"; cents: number };
        }>;
      } & RecurringFeeFieldsFragment
    >
  >;
};

export type ArchiveManagementFeeBlueprintMutationVariables = Exact<{
  managementFeeBlueprintId: Scalars["ID"];
}>;

export type ArchiveManagementFeeBlueprintMutation = {
  __typename?: "Mutation";
  updateManagementFeeBlueprint?: Maybe<{
    __typename?: "ManagementFeeBlueprint";
    id: string;
    ailorn: string;
    taxTreatment: TaxTreatment;
    oneWeekRentPercentage?: Maybe<number>;
    fixedAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
    feeBlueprint: {
      __typename?: "FeeBlueprint";
      id: string;
      name: string;
      ailorn: string;
    };
  }>;
};

export type CreateManagementFeeBlueprintMutationVariables = Exact<{
  input: CreateManagementFeeBlueprintInput;
}>;

export type CreateManagementFeeBlueprintMutation = {
  __typename?: "Mutation";
  createManagementFeeBlueprint?: Maybe<{
    __typename?: "ManagementFeeBlueprint";
    id: string;
    ailorn: string;
    taxTreatment: TaxTreatment;
    oneWeekRentPercentage?: Maybe<number>;
    description?: Maybe<string>;
    fixedAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
    feeBlueprint: {
      __typename?: "FeeBlueprint";
      id: string;
      name: string;
      type: FeeBlueprintType;
      ailorn: string;
    };
  }>;
};

export type UpdateManagementFeeBlueprintMutationVariables = Exact<{
  input: UpdateManagementFeeBlueprintInput;
}>;

export type UpdateManagementFeeBlueprintMutation = {
  __typename?: "Mutation";
  updateManagementFeeBlueprint?: Maybe<{
    __typename?: "ManagementFeeBlueprint";
    id: string;
    ailorn: string;
    taxTreatment: TaxTreatment;
    oneWeekRentPercentage?: Maybe<number>;
    description?: Maybe<string>;
    fixedAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
    feeBlueprint: {
      __typename?: "FeeBlueprint";
      id: string;
      name: string;
      ailorn: string;
    };
  }>;
};

export type GetManagementFeeBlueprintsQueryVariables = Exact<{
  conditions?: Maybe<ManagementFeeBlueprintsQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
}>;

export type GetManagementFeeBlueprintsQuery = {
  __typename?: "Query";
  managementFeeBlueprints?: Maybe<{
    __typename?: "PaginatedManagementFeeBlueprints";
    items: Array<{
      __typename?: "ManagementFeeBlueprint";
      id: string;
      ailorn: string;
      oneWeekRentPercentage?: Maybe<number>;
      taxTreatment: TaxTreatment;
      description?: Maybe<string>;
      feeBlueprint: {
        __typename?: "FeeBlueprint";
        id: string;
        name: string;
        type: FeeBlueprintType;
        oneWeekRentPercentage?: Maybe<number>;
        taxTreatment: TaxTreatment;
        ailorn: string;
        fixedAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
        event?: Maybe<{
          __typename?: "FeeEvent";
          type: FeeEventType;
          shortDescription: string;
          longDescription: string;
        }>;
        taxCategory: {
          __typename?: "FeeTaxCategory";
          id: string;
          name: string;
        };
      };
      fixedAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
    }>;
  }>;
};

export type GetClaimedFilesCountQueryVariables = Exact<{
  claimerAilorn: Scalars["AiloRN"];
  fileKind?: Maybe<FileKind>;
}>;

export type GetClaimedFilesCountQuery = {
  __typename?: "Query";
  claimedFilesCount?: Maybe<{
    __typename?: "ClaimedFilesCount";
    totalFiles: number;
    validFiles: number;
  }>;
};

export type GenerateInspectionReportMutationVariables = Exact<{
  inspectionId: Scalars["String"];
}>;

export type GenerateInspectionReportMutation = {
  __typename?: "Mutation";
  generateInspectionReport: {
    __typename?: "GenerateInspectionReportResult";
    inspection: {
      __typename?: "Inspection";
      id: string;
    } & InspectionReportStateFragment &
      InspectionLatestReportProgressFragment;
  };
};

export type GetInspectionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetInspectionQuery = {
  __typename?: "Query";
  inspection: { __typename?: "Inspection" } & InspectionFragment;
};

export type GetInspectionReportStateQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetInspectionReportStateQuery = {
  __typename?: "Query";
  inspection: {
    __typename?: "Inspection";
    id: string;
  } & InspectionReportStateFragment &
    InspectionLatestReportProgressFragment;
};

export type GetPendingRoutineInspectionAppointmentsQueryVariables = Exact<{
  inspectingAgentAilorn: Scalars["AiloRN"];
  organisationAilorn: Scalars["AiloRN"];
}>;

export type GetPendingRoutineInspectionAppointmentsQuery = {
  __typename?: "Query";
  inspectionAppointmentConnection: {
    __typename?: "InspectionAppointmentConnection";
    edges: Array<{
      __typename?: "InspectionAppointmentEdge";
      node: {
        __typename?: "InspectionAppointment";
        property: { __typename?: "Property" } & PropertyKeysFragment;
      } & PendingInspectionAppointmentFragment;
    }>;
  };
};

export type InspectionFragment = {
  __typename?: "Inspection";
  id: string;
  ailorn: string;
  type: InspectionType;
  startedAt: string;
  completedAt?: Maybe<string>;
  inspectingAgent: { __typename?: "Person" } & PersonDisplayDetailsFragment;
  areas: Array<{ __typename?: "InspectionArea" } & InspectionAreaFragment>;
  areaFilesForThumbnail: Array<{
    __typename?: "InspectionAreaFile";
    id: string;
    file: { __typename?: "File"; id: string; thumbnailUrl?: Maybe<string> };
  }>;
  featureFilesForThumbnail: Array<{
    __typename?: "InspectionFeatureFile";
    id: string;
    file: { __typename?: "File"; id: string; thumbnailUrl?: Maybe<string> };
  }>;
} & InspectionReportStateFragment &
  InspectionLatestReportProgressFragment;

export type InspectionAppointmentFragment = {
  __typename?: "InspectionAppointment";
  id: string;
  type: InspectionType;
  startTime?: Maybe<string>;
  inspection?: Maybe<{ __typename?: "Inspection" } & InspectionFragment>;
  inspectingAgent: { __typename?: "Person" } & PersonDisplayDetailsFragment;
};

export type InspectionAreaFragment = {
  __typename?: "InspectionArea";
  id: string;
  name: string;
  inspected: boolean;
};

export type InspectionLatestReportProgressFragment = {
  __typename?: "Inspection";
  id: string;
  latestReportProgress?: Maybe<{
    __typename?: "InspectionReportProgress";
    id: string;
    completedAt?: Maybe<string>;
    failedAt?: Maybe<string>;
  }>;
};

export type InspectionReportStateFragment = {
  __typename?: "Inspection";
  id: string;
  reportGenerationState: {
    __typename?: "ReportGenerationState";
    canGenerateReport: boolean;
  };
};

export type PendingInspectionAppointmentFragment = {
  __typename?: "InspectionAppointment";
  id: string;
  startTime?: Maybe<string>;
  property: { __typename?: "Property" } & PropertyIdAndAddressFragment;
};

export type CreateTenancyLedgerStatementMutationVariables = Exact<{
  tenancyAiloRN: Scalars["AiloRN"];
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
}>;

export type CreateTenancyLedgerStatementMutation = {
  __typename?: "Mutation";
  createStatementsWithSubject: {
    __typename?: "CreateStatementsResponse";
    statementProgresses: Array<{
      __typename?: "StatementProgress";
      id: string;
    }>;
  };
};

export type GetTenancyStartAndEndDatesQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetTenancyStartAndEndDatesQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    startDate?: Maybe<string>;
    endDate?: Maybe<string>;
  }>;
};

export type GetStatementFileUrlQueryVariables = Exact<{
  statementProgressId: Scalars["ID"];
}>;

export type GetStatementFileUrlQuery = {
  __typename?: "Query";
  statementProgress?: Maybe<{
    __typename?: "StatementProgress";
    isSuccessful?: Maybe<boolean>;
    statement?: Maybe<{
      __typename?: "Statement";
      id: string;
      file?: Maybe<{ __typename?: "File"; id: string; url?: Maybe<string> }>;
    }>;
  }>;
};

export type GetManagementPortfolioTeamsQueryVariables = Exact<{
  managementPortfolioId: Scalars["ID"];
  managementsFilter?: Maybe<ManagementFolioManagementFilter>;
}>;

export type GetManagementPortfolioTeamsQuery = {
  __typename?: "Query";
  managementFolio?: Maybe<{
    __typename?: "ManagementFolio";
    allManagements: {
      __typename?: "PaginatedManagementFolioToManagements";
      items: Array<{
        __typename?: "ManagementFolioToManagement";
        management: {
          __typename?: "Management";
          team?: Maybe<{ __typename?: "Team"; id: string; name: string }>;
        };
      }>;
    };
  }>;
};

export type GetManagementPortfolioOwnersQueryVariables = Exact<{
  managementPortfolioId: Scalars["ID"];
  organisationAilorn: Scalars["AiloRN"];
}>;

export type GetManagementPortfolioOwnersQuery = {
  __typename?: "Query";
  managementFolio?: Maybe<{
    __typename?: "ManagementFolio";
    id: string;
    owners: Array<{
      __typename?: "ManagementFolioOwnership";
      isPrimary: boolean;
      owner?: Maybe<
        | ({
            __typename: "Company";
            registeredEntityId?: Maybe<string>;
            id: string;
            contact?: Maybe<{
              __typename?: "Contact";
              id: string;
              ailorn: string;
            }>;
          } & CompanyDisplayDetailsFragment)
        | ({
            __typename: "Person";
            phoneNo?: Maybe<string>;
            emailAddress?: Maybe<string>;
            id: string;
            user?: Maybe<{
              __typename?: "User";
              id: number;
              onboardingCompletedAt?: Maybe<string>;
            }>;
            contact?: Maybe<{
              __typename?: "Contact";
              id: string;
              ailorn: string;
            }>;
          } & PersonDisplayDetailsFragment)
      >;
    }>;
  }>;
};

export type GetManagementPortfolioPropertiesQueryVariables = Exact<{
  managementPortfolioId: Scalars["ID"];
  managementsFilter?: Maybe<ManagementFolioManagementFilter>;
}>;

export type GetManagementPortfolioPropertiesQuery = {
  __typename?: "Query";
  managementFolio?: Maybe<{
    __typename?: "ManagementFolio";
    allManagements: {
      __typename?: "PaginatedManagementFolioToManagements";
      items: Array<{
        __typename?: "ManagementFolioToManagement";
        management: {
          __typename?: "Management";
          occupancyStatus?: Maybe<OccupancyStatus>;
          mostRecentTenancy?: Maybe<{
            __typename?: "Tenancy";
            endDate?: Maybe<string>;
          }>;
        } & ManagementPropertyFragment;
      }>;
    };
  }>;
};

type PropertyOnboardingListActionInitiator_User_Fragment = {
  __typename?: "User";
  id: number;
  person: {
    __typename?: "Person";
    firstName: string;
    lastName?: Maybe<string>;
  };
};

type PropertyOnboardingListActionInitiator_System_Fragment = {
  __typename?: "System";
};

export type PropertyOnboardingListActionInitiatorFragment =
  | PropertyOnboardingListActionInitiator_User_Fragment
  | PropertyOnboardingListActionInitiator_System_Fragment;

export type ApprovedMigratingManagementFragment = {
  __typename?: "MigratingManagement";
  id: string;
  body: Record<string, any>;
  portfolioName?: Maybe<string>;
  statusChangedAt: string;
  approvedByDisplay?: Maybe<string>;
  approvedAt?: Maybe<string>;
  approvedBy?: Maybe<
    | ({
        __typename?: "User";
      } & PropertyOnboardingListActionInitiator_User_Fragment)
    | ({
        __typename?: "System";
      } & PropertyOnboardingListActionInitiator_System_Fragment)
  >;
};

export type GetPagedApprovedMigratingManagementsQueryVariables = Exact<{
  cursor?: Maybe<Scalars["String"]>;
  before: Scalars["Boolean"];
  pageSize: Scalars["Int"];
  sort: Array<MigratingManagementSortParams>;
  organisationId: Scalars["AiloRN"];
  status: Array<Scalars["String"]>;
}>;

export type GetPagedApprovedMigratingManagementsQuery = {
  __typename?: "Query";
  migratingManagements?: Maybe<{
    __typename?: "PaginatedMigratingManagements";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
      total: number;
    };
    items: Array<
      Maybe<
        {
          __typename?: "MigratingManagement";
        } & ApprovedMigratingManagementFragment
      >
    >;
  }>;
};

export type AwaitingApprovalMigratingManagementFragment = {
  __typename?: "MigratingManagement";
  id: string;
  body: Record<string, any>;
  portfolioName?: Maybe<string>;
  errors?: Maybe<Record<string, any>>;
};

export type GetPagedAwaitingApprovalMigratingManagementsQueryVariables = Exact<{
  cursor?: Maybe<Scalars["String"]>;
  before: Scalars["Boolean"];
  pageSize: Scalars["Int"];
  sort: Array<MigratingManagementSortParams>;
  organisationId: Scalars["AiloRN"];
  addressSearch?: Maybe<Scalars["String"]>;
  portfolio?: Maybe<MigratingManagementsPortfolioFilter>;
  withErrors?: Maybe<Scalars["Boolean"]>;
}>;

export type GetPagedAwaitingApprovalMigratingManagementsQuery = {
  __typename?: "Query";
  migratingManagements?: Maybe<{
    __typename?: "PaginatedMigratingManagements";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
      total: number;
    };
    items: Array<
      Maybe<
        {
          __typename?: "MigratingManagement";
        } & AwaitingApprovalMigratingManagementFragment
      >
    >;
  }>;
};

export type ExcludedMigratingManagementFragment = {
  __typename?: "MigratingManagement";
  id: string;
  body: Record<string, any>;
  portfolioName?: Maybe<string>;
  statusChangedAt: string;
  exclusionReason?: Maybe<string>;
  statusChangedBy?: Maybe<
    | ({
        __typename?: "User";
      } & PropertyOnboardingListActionInitiator_User_Fragment)
    | ({
        __typename?: "System";
      } & PropertyOnboardingListActionInitiator_System_Fragment)
  >;
};

export type GetPagedExcludedMigratingManagementsQueryVariables = Exact<{
  cursor?: Maybe<Scalars["String"]>;
  before: Scalars["Boolean"];
  pageSize: Scalars["Int"];
  sort: Array<MigratingManagementSortParams>;
  organisationId: Scalars["AiloRN"];
}>;

export type GetPagedExcludedMigratingManagementsQuery = {
  __typename?: "Query";
  migratingManagements?: Maybe<{
    __typename?: "PaginatedMigratingManagements";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
      total: number;
    };
    items: Array<
      Maybe<
        {
          __typename?: "MigratingManagement";
        } & ExcludedMigratingManagementFragment
      >
    >;
  }>;
};

export type LiveMigratingManagementFragment = {
  __typename?: "MigratingManagement";
  id: string;
  body: Record<string, any>;
  portfolioName?: Maybe<string>;
  statusChangedAt: string;
  approvedByDisplay?: Maybe<string>;
  approvedBy?: Maybe<
    | ({
        __typename?: "User";
      } & PropertyOnboardingListActionInitiator_User_Fragment)
    | ({
        __typename?: "System";
      } & PropertyOnboardingListActionInitiator_System_Fragment)
  >;
  ailoManagement?: Maybe<{ __typename?: "Management"; id: string }>;
};

export type GetPagedLiveMigratingManagementsQueryVariables = Exact<{
  cursor?: Maybe<Scalars["String"]>;
  before: Scalars["Boolean"];
  pageSize: Scalars["Int"];
  sort: Array<MigratingManagementSortParams>;
  organisationId: Scalars["AiloRN"];
}>;

export type GetPagedLiveMigratingManagementsQuery = {
  __typename?: "Query";
  migratingManagements?: Maybe<{
    __typename?: "PaginatedMigratingManagements";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
      total: number;
    };
    items: Array<
      Maybe<
        { __typename?: "MigratingManagement" } & LiveMigratingManagementFragment
      >
    >;
  }>;
};

export type ActionFragment = {
  __typename?: "Action";
  id: string;
  name: string;
  description: string;
  type: ActionType;
  typeLabel: string;
  status: ActionStatus;
  createdAt: string;
  dueDate: string;
  doneAt?: Maybe<string>;
};

export type ActionDetailsFragment = {
  __typename?: "Action";
  id: string;
  assignee?: Maybe<{
    __typename?: "Person";
    id: string;
    firstName: string;
    lastName?: Maybe<string>;
  }>;
  meta?: Maybe<
    | ({
        __typename?: "AssignTenancyAgreementActionMeta";
      } & AssignTenancyAgreementActionMetaFragment)
    | ({ __typename?: "AssignRentActionMeta" } & AssignRentActionMetaFragment)
    | ({
        __typename?: "AssignTenancyActionMeta";
      } & AssignTenancyActionMetaFragment)
    | ({
        __typename?: "AssignNewManagementActionMeta";
      } & AssignNewManagementActionMetaFragment)
    | ({ __typename?: "AssignFormActionMeta" } & AssignFormActionMetaFragment)
    | ({
        __typename?: "RequestSignatureActionMeta";
      } & RequestSignatureActionMetaFragment)
    | ({
        __typename?: "ManageDepositActionMeta";
      } & ManageDepositActionMetaFragment)
    | ({
        __typename?: "TrackDocusignActionMeta";
      } & TrackDocusignActionMetaFragment)
    | ({
        __typename?: "TrackInspectionCompletedActionMeta";
      } & TrackInspectionCompletedActionMetaFragment)
    | ({
        __typename?: "GenerateInspectionReportActionMeta";
      } & GenerateInspectionReportActionMetaFragment)
    | ({ __typename?: "EndTenancyActionMeta" } & EndTenancyActionMetaFragment)
    | ({
        __typename?: "ConfirmInspectionScheduleActionMeta";
      } & ConfirmInspectionScheduleActionMetaFragment)
    | ({
        __typename?: "AssignMessageActionMeta";
      } & AssignMessageActionMetaFragment)
  >;
  project: { __typename?: "Project" } & ProjectFragment & ProjectMetaFragment;
} & ActionFragment;

export type AssignFormActionMetaFragment = {
  __typename?: "AssignFormActionMeta";
  form?: Maybe<
    | { __typename?: "AiloForm"; id: string }
    | { __typename?: "ReiForm"; id: string }
  >;
};

export type AssignNewManagementActionMetaFragment = {
  __typename?: "AssignNewManagementActionMeta";
  management?: Maybe<{ __typename?: "Management"; id: string }>;
};

export type AssignRentActionMetaFragment = {
  __typename?: "AssignRentActionMeta";
  rent?: Maybe<
    {
      __typename?: "Rent";
      tenancy?: Maybe<{
        __typename?: "Tenancy";
        id: string;
        scheduledRentReviewDate?: Maybe<string>;
      }>;
    } & EditableRentFragment
  >;
};

export type AssignTenancyActionMetaFragment = {
  __typename?: "AssignTenancyActionMeta";
  tenancy?: Maybe<
    { __typename?: "Tenancy"; id: string } & TenancyVoidedAtFragment
  >;
};

export type AssignTenancyAgreementActionMetaFragment = {
  __typename?: "AssignTenancyAgreementActionMeta";
  tenancyAgreement?: Maybe<{
    __typename?: "TenancyAgreement";
    id: string;
    startDate?: Maybe<string>;
    fixedTermEndDate?: Maybe<string>;
  }>;
};

export type CompleteActionMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CompleteActionMutation = {
  __typename?: "Mutation";
  completeAction?: Maybe<{ __typename?: "Action" } & ActionDetailsFragment>;
};

export type ConfirmInspectionScheduleActionMetaFragment = {
  __typename?: "ConfirmInspectionScheduleActionMeta";
  tenancy?: Maybe<
    { __typename?: "Tenancy" } & TenancyInspectionScheduleFragment
  >;
  inspectionAppointment?: Maybe<
    { __typename?: "InspectionAppointment" } & InspectionAppointmentFragment
  >;
};

export type EndTenancyActionMetaFragment = {
  __typename?: "EndTenancyActionMeta";
  tenancy?: Maybe<{ __typename?: "Tenancy" } & EndTenancyDetailsFragment>;
};

export type EndTenancyDetailsFragment = {
  __typename?: "Tenancy";
  id: string;
  endDate?: Maybe<string>;
  vacatingReason?: Maybe<VacatingReason>;
  vacatingNotes?: Maybe<string>;
} & CanCancelVacateFragment;

export type GetReiFormRemoteSigningPdfQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetReiFormRemoteSigningPdfQuery = {
  __typename?: "Query";
  form:
    | { __typename?: "AiloForm"; id: string }
    | {
        __typename?: "ReiForm";
        remoteSigningPDF: string;
        id: string;
        reiFormInfo: { __typename?: "ReiFormInfo"; name: string };
      };
};

export type GenerateInspectionReportActionMetaFragment = {
  __typename?: "GenerateInspectionReportActionMeta";
  inspectionAppointment?: Maybe<
    { __typename?: "InspectionAppointment" } & InspectionAppointmentFragment
  >;
};

export type ManageDepositActionMetaFragment = {
  __typename?: "ManageDepositActionMeta";
  deposit?: Maybe<{ __typename?: "TenancyDeposit"; id: string }>;
};

export type AssignMessageActionMetaFragment = {
  __typename?: "AssignMessageActionMeta";
  subjectTemplate?: Maybe<string>;
  messageTemplate?: Maybe<string>;
};

export type RequestSignatureActionMetaFragment = {
  __typename?: "RequestSignatureActionMeta";
  form?: Maybe<
    | { __typename?: "AiloForm"; id: string }
    | { __typename?: "ReiForm"; id: string }
  >;
};

export type TrackDocusignActionMetaFragment = {
  __typename?: "TrackDocusignActionMeta";
  form?: Maybe<
    | { __typename?: "AiloForm"; id: string }
    | { __typename?: "ReiForm"; id: string }
  >;
};

export type TrackInspectionCompletedActionMetaFragment = {
  __typename?: "TrackInspectionCompletedActionMeta";
  inspectionAppointment?: Maybe<
    { __typename?: "InspectionAppointment" } & InspectionAppointmentFragment
  >;
};

export type UncompleteActionMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UncompleteActionMutation = {
  __typename?: "Mutation";
  uncompleteAction?: Maybe<{ __typename?: "Action" } & ActionDetailsFragment>;
};

export type AddProjectFilesMutationVariables = Exact<{
  projectId: Scalars["ID"];
  fileAilorns: Array<Scalars["AiloRN"]>;
}>;

export type AddProjectFilesMutation = {
  __typename?: "Mutation";
  addProjectFiles?: Maybe<
    Array<
      Maybe<{
        __typename?: "ProjectFile";
        id: string;
        file?: Maybe<{ __typename?: "File" } & FileDetailsFragment>;
      }>
    >
  >;
};

export type GetCurrentTenancyQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetCurrentTenancyQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    currentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      ailoRN: string;
      endDate?: Maybe<string>;
    }>;
  }>;
};

export type GetProjectActionsQueryVariables = Exact<{
  projectId: Scalars["ID"];
  cursor?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
}>;

export type GetProjectActionsQuery = {
  __typename?: "Query";
  project?: Maybe<{
    __typename?: "Project";
    id: string;
    actions?: Maybe<{
      __typename?: "PaginatedActions";
      pageInfo: {
        __typename?: "PageInfo";
        total: number;
        hasMore: boolean;
        nextCursor?: Maybe<string>;
      };
      items: Array<{ __typename?: "Action" } & ActionDetailsFragment>;
    }>;
  }>;
};

export type GetProjectDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetProjectDetailsQuery = {
  __typename?: "Query";
  project?: Maybe<{ __typename?: "Project" } & ProjectDetailsFragment>;
};

export type GetProjectsByOrganisationQueryVariables = Exact<{
  organisationAilorn: Scalars["AiloRN"];
  cursor?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
  sort?: Maybe<Scalars["String"]>;
  status?: Maybe<ProjectStatus>;
  paginateBackward?: Maybe<Scalars["Boolean"]>;
  assigneeAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
  projectTypes?: Maybe<Array<ProjectType>>;
}>;

export type GetProjectsByOrganisationQuery = {
  __typename?: "Query";
  projectsByOrganisation?: Maybe<{
    __typename?: "PaginatedProjects";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
    } & ProjectPageInfoFragment;
    items: Array<{ __typename?: "Project" } & ProjectListRowFragment>;
  }>;
};

export type ProjectActionsFragment = {
  __typename?: "Project";
  actions?: Maybe<{
    __typename?: "PaginatedActions";
    items: Array<{ __typename?: "Action" } & ActionDetailsFragment>;
    pageInfo: { __typename?: "PageInfo"; total: number };
  }>;
};

export type GeneralProjectMetaFragment = {
  __typename?: "GeneralProjectMeta";
  generalManagement?: Maybe<
    { __typename?: "Management" } & ProjectManagementFragment
  >;
};

export type LeaseRenewalProjectMetaFragment = {
  __typename?: "LeaseRenewalProjectMeta";
  leaseRenewalTenancy: {
    __typename?: "Tenancy";
    id: string;
    ailoRN: string;
    leaseReviewAllowedOperation?: Maybe<AllowedOperations>;
    rentReviewAllowed?: Maybe<boolean>;
    editableRentReview?: Maybe<{
      __typename?: "EditableRentReviewPayload";
      rent?: Maybe<{ __typename?: "Rent" } & EditableRentFragment>;
    }>;
  };
  leaseRenewalManagement: {
    __typename?: "Management";
  } & ProjectManagementFragment;
};

export type NewManagementProjectMetaFragment = {
  __typename?: "NewManagementProjectMeta";
  newManagement?: Maybe<
    { __typename?: "Management" } & ProjectManagementFragment
  >;
};

export type NewTenancyProjectMetaFragment = {
  __typename?: "NewTenancyProjectMeta";
  newTenancy?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      deposit?: Maybe<
        {
          __typename?: "TenancyDeposit";
          id: string;
        } & TenancyDepositDetailsFragment
      >;
    } & TenancyFragment
  >;
  newTenancyManagement: {
    __typename?: "Management";
  } & ProjectManagementFragment;
};

export type ProjectFragment = {
  __typename?: "Project";
  id: string;
  name: string;
  type: ProjectType;
  description?: Maybe<string>;
  dueDate: string;
  status: ProjectStatus;
  reference: string;
  createdAt: string;
} & ProjectAssigneeInfoFragment;

export type ProjectAssigneeInfoFragment = {
  __typename?: "Project";
  id: string;
  assignee: { __typename?: "Person" } & PersonNamesFragment &
    PersonDisplayDetailsFragment;
};

export type ProjectDetailsFragment = {
  __typename?: "Project";
} & ProjectFragment &
  ProjectFilesFragment &
  ProjectOrganisationFragment &
  ProjectActionsFragment &
  ProjectMetaFragment &
  ProjectAssigneeInfoFragment;

export type ProjectFileFragment = {
  __typename?: "ProjectFile";
  id: string;
  file?: Maybe<{
    __typename?: "File";
    id: string;
    url?: Maybe<string>;
    thumbnailUrl?: Maybe<string>;
    thumbnailStatus: ThumbnailStatus;
    contentType?: Maybe<string>;
    fileName: string;
    fileSize?: Maybe<number>;
  }>;
};

export type ProjectFilesFragment = {
  __typename?: "Project";
  files?: Maybe<{
    __typename?: "PaginatedProjectFiles";
    items: Array<{ __typename?: "ProjectFile" } & ProjectFileFragment>;
    pageInfo: { __typename?: "PageInfo"; total: number };
  }>;
};

export type ProjectListRowFragment = {
  __typename?: "Project";
  id: string;
  name: string;
  description?: Maybe<string>;
  type: ProjectType;
  dueDate: string;
  closedAt?: Maybe<string>;
  status: ProjectStatus;
  reference: string;
  createdAt: string;
  assignee: {
    __typename?: "Person";
    id: string;
  } & PersonDisplayDetailsFragment;
  actions?: Maybe<{
    __typename?: "PaginatedActions";
    items: Array<{ __typename?: "Action"; id: string; status: ActionStatus }>;
    pageInfo: { __typename?: "PageInfo"; total: number };
  }>;
} & ProjectWithAddressFragment;

export type ProjectManagementFragment = {
  __typename?: "Management";
  id: string;
  currentTenancy?: Maybe<
    { __typename?: "Tenancy" } & FormSidebarTenancyDetailsFragment
  >;
} & ManagementPropertyFragment &
  ProjectManagementOwnershipsFragment &
  ProjectMangementTenanciesFragment;

export type ProjectManagementOwnershipsFragment = {
  __typename?: "Management";
  ownerships?: Maybe<{
    __typename?: "PaginatedOwnerships";
    items: Array<{
      __typename?: "Ownership";
      owner?: Maybe<
        | { __typename?: "Company"; registeredEntityName: string; id: string }
        | {
            __typename?: "Person";
            firstName: string;
            lastName?: Maybe<string>;
            id: string;
          }
      >;
    }>;
  }>;
};

export type ProjectMangementTenanciesFragment = {
  __typename?: "Management";
  tenancies?: Maybe<{
    __typename?: "PaginatedTenancies";
    items: Array<{
      __typename?: "Tenancy";
      id: string;
      tenantships?: Maybe<{
        __typename?: "PaginatedTenantships";
        items: Array<{
          __typename?: "Tenantship";
          tenant?: Maybe<
            | {
                __typename?: "Company";
                registeredEntityName: string;
                id: string;
              }
            | {
                __typename?: "Person";
                firstName: string;
                lastName?: Maybe<string>;
                id: string;
              }
          >;
        }>;
      }>;
    }>;
  }>;
};

export type ProjectMetaFragment = {
  __typename?: "Project";
  id: string;
  meta?: Maybe<
    | ({ __typename?: "GeneralProjectMeta" } & GeneralProjectMetaFragment)
    | ({
        __typename?: "LeaseRenewalProjectMeta";
      } & LeaseRenewalProjectMetaFragment)
    | ({ __typename?: "NewTenancyProjectMeta" } & NewTenancyProjectMetaFragment)
    | ({
        __typename?: "NewManagementProjectMeta";
      } & NewManagementProjectMetaFragment)
    | ({
        __typename?: "VacatingTenancyProjectMeta";
      } & VacatingTenancyProjectMetaFragment)
    | ({
        __typename?: "RoutineInspectionProjectMeta";
      } & RoutineInspectionProjectMetaFragment)
  >;
};

export type ProjectOrganisationFragment = {
  __typename?: "Project";
  organisation?: Maybe<
    { __typename?: "Organisation" } & OrganisationMembersDetailsFragment
  >;
};

export type ProjectPageInfoFragment = {
  __typename?: "BidirectionalPageInfo";
  total: number;
  hasNext: boolean;
  hasPrevious: boolean;
  nextCursor?: Maybe<string>;
  previousCursor?: Maybe<string>;
};

export type ProjectWithAddressFragment = {
  __typename?: "Project";
  id: string;
  meta?: Maybe<
    | ({ __typename?: "GeneralProjectMeta" } & GeneralProjectAddressFragment)
    | ({
        __typename?: "LeaseRenewalProjectMeta";
      } & LeaseRenewalProjectAddressFragment)
    | ({
        __typename?: "NewTenancyProjectMeta";
      } & NewTenancyProjectAddressFragment)
    | ({
        __typename?: "NewManagementProjectMeta";
      } & NewManagementProjectAddressFragment)
    | ({
        __typename?: "VacatingTenancyProjectMeta";
      } & VacatingTenancyProjectAddressFragment)
    | ({
        __typename?: "RoutineInspectionProjectMeta";
      } & RoutineInspectionProjectAddressFragment)
  >;
} & ProjectFragment;

export type GeneralProjectAddressFragment = {
  __typename?: "GeneralProjectMeta";
  generalManagement?: Maybe<
    { __typename?: "Management" } & ProjectManagementWithAddressFragment
  >;
};

export type LeaseRenewalProjectAddressFragment = {
  __typename?: "LeaseRenewalProjectMeta";
  leaseRenewalManagement: {
    __typename?: "Management";
  } & ProjectManagementWithAddressFragment;
};

export type NewTenancyProjectAddressFragment = {
  __typename?: "NewTenancyProjectMeta";
  newTenancyManagement: {
    __typename?: "Management";
  } & ProjectManagementWithAddressFragment;
};

export type NewManagementProjectAddressFragment = {
  __typename?: "NewManagementProjectMeta";
  newManagement?: Maybe<
    { __typename?: "Management" } & ProjectManagementWithAddressFragment
  >;
};

export type VacatingTenancyProjectAddressFragment = {
  __typename?: "VacatingTenancyProjectMeta";
  vacatingTenancyManagement: {
    __typename?: "Management";
  } & ProjectManagementWithAddressFragment;
};

export type RoutineInspectionProjectAddressFragment = {
  __typename?: "RoutineInspectionProjectMeta";
  routineInspectionManagement: {
    __typename?: "Management";
  } & ProjectManagementWithAddressFragment;
};

export type ProjectManagementWithAddressFragment = {
  __typename?: "Management";
  id: string;
} & ManagementPropertyFragment;

export type RoutineInspectionProjectMetaFragment = {
  __typename?: "RoutineInspectionProjectMeta";
  routineInspectionManagement: {
    __typename?: "Management";
  } & ProjectManagementFragment;
  tenancy: { __typename?: "Tenancy"; id: string; ailoRN: string };
};

export type VacatingTenancyProjectMetaFragment = {
  __typename?: "VacatingTenancyProjectMeta";
  vacatingTenancyManagement: {
    __typename?: "Management";
  } & ProjectManagementFragment;
};

export type CancelTenancyDepositMutationVariables = Exact<{
  tenancyDepositAilorn: Scalars["AiloRN"];
}>;

export type CancelTenancyDepositMutation = {
  __typename?: "Mutation";
  cancelTenancyDeposit?: Maybe<{ __typename?: "TenancyDeposit"; id: string }>;
};

export type GetAllTenanciesDepositsQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetAllTenanciesDepositsQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    occupancyStatus?: Maybe<OccupancyStatus>;
    mostRecentTenancy?: Maybe<
      { __typename?: "Tenancy" } & TenancyWithTenancyDepositDetailsFragment
    >;
    nextTenancy?: Maybe<
      { __typename?: "Tenancy" } & TenancyWithTenancyDepositDetailsFragment
    >;
    formerTenancies?: Maybe<{
      __typename?: "BidirectionalPaginatedTenancies";
      items: Array<
        { __typename?: "Tenancy" } & TenancyWithTenancyDepositDetailsFragment
      >;
    }>;
  }>;
};

export type TenancyWithTenancyDepositDetailsFragment = {
  __typename?: "Tenancy";
  id: string;
  startDate?: Maybe<string>;
  deposit?: Maybe<
    { __typename?: "TenancyDeposit" } & TenancyDepositDetailsFragment
  >;
};

export type TenancyDepositDetailsFragment = {
  __typename?: "TenancyDeposit";
  id: string;
  ailoRN: string;
  status: TenancyDepositStatus;
  createdAt: string;
  paidAt?: Maybe<string>;
  amount: { __typename?: "Money"; cents: number };
  liability?: Maybe<{
    __typename?: "Liability";
    entries?: Maybe<{
      __typename?: "PaginatedLiabilityEntries";
      items: Array<
        | { __typename?: "AdjustmentLiabilityEntry" }
        | {
            __typename?: "PaymentLiabilityEntry";
            businessTransaction: {
              __typename?: "BusinessTransaction";
              expectedClearedAt?: Maybe<string>;
              clearedAt?: Maybe<string>;
            };
          }
        | { __typename?: "PlanBasedLiabilityEntry" }
      >;
    }>;
  }>;
};

export type GetTenancyAndTenancyDepositDetailsQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetTenancyAndTenancyDepositDetailsQuery = {
  __typename?: "Query";
  tenancy?: Maybe<
    { __typename?: "Tenancy" } & TenancyWithTenancyDepositDetailsFragment
  >;
};

export type TransferTenancyDepositMutationVariables = Exact<{
  tenancyDepositAilorn: Scalars["AiloRN"];
}>;

export type TransferTenancyDepositMutation = {
  __typename?: "Mutation";
  transferTenancyDeposit?: Maybe<{
    __typename?: "TenancyDeposit";
    id: string;
    status: TenancyDepositStatus;
  }>;
};

export type GetTenanciesForMgmtQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetTenanciesForMgmtQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    tenancies?: Maybe<{
      __typename?: "PaginatedTenancies";
      items: Array<{
        __typename?: "Tenancy";
        id: string;
        endDate?: Maybe<string>;
        startDate?: Maybe<string>;
        voidedAt?: Maybe<string>;
        tenantships?: Maybe<{
          __typename?: "PaginatedTenantships";
          pageInfo: { __typename?: "PageInfo"; total: number };
          items: Array<{
            __typename?: "Tenantship";
            tenant?: Maybe<
              | {
                  __typename: "Company";
                  registeredEntityName: string;
                  id: string;
                  ailoRN: string;
                }
              | {
                  __typename: "Person";
                  firstName: string;
                  lastName?: Maybe<string>;
                  id: string;
                  ailoRN: string;
                  photo?: Maybe<{
                    __typename?: "File";
                    id: string;
                    url?: Maybe<string>;
                    thumbnailUrl?: Maybe<string>;
                  }>;
                }
            >;
          }>;
        }>;
      }>;
    }>;
  }>;
};

export type ManagementEndDetailsFragment = {
  __typename?: "Management";
  id: string;
  endDate?: Maybe<string>;
  endNote?: Maybe<string>;
  endAbility: {
    __typename?: "ManagementEndAbility";
    canBeEnded: boolean;
    problem?: Maybe<ManagementCannotBeEndedProblem>;
  };
  endReason?: Maybe<{
    __typename?: "ManagementEndReason";
    code: ManagementEndReasonCode;
    label: string;
    causes: Array<{
      __typename?: "ManagementEndCause";
      code: ManagementEndCauseCode;
      label: string;
    }>;
  }>;
};

export type ManagementOwnershipsFragment = {
  __typename?: "Management";
  id: string;
  ownerships?: Maybe<{
    __typename?: "PaginatedOwnerships";
    items: Array<
      {
        __typename?: "Ownership";
        owner?: Maybe<
          | {
              __typename?: "Company";
              contact?: Maybe<{ __typename?: "Contact"; ailorn: string }>;
            }
          | {
              __typename?: "Person";
              contact?: Maybe<{ __typename?: "Contact"; ailorn: string }>;
            }
        >;
      } & OwnerFragment
    >;
  }>;
};

export type OwnerFragment = {
  __typename?: "Ownership";
  owner?: Maybe<
    | ({
        __typename: "Company";
        id: string;
        registeredEntityId?: Maybe<string>;
      } & CompanyDisplayDetailsFragment)
    | ({
        __typename: "Person";
        id: string;
        phoneNo?: Maybe<string>;
        emailAddress?: Maybe<string>;
        birthDate?: Maybe<string>;
        user?: Maybe<{
          __typename?: "User";
          id: number;
          onboardingCompletedAt?: Maybe<string>;
        }>;
      } & PersonDisplayDetailsFragment)
  >;
};

export type ProjectItemDetailsFragment = {
  __typename?: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  reference: string;
  type: ProjectType;
};

export type AgencyPropertyFragment = {
  __typename?: "AgencyProperty";
  id: string;
  mostRelevantManagement?: Maybe<{ __typename?: "Management"; id: string }>;
} & AgencyPropertyAddressFragment;

export type AgencyPropertyAddressFragment = {
  __typename?: "AgencyProperty";
  id: string;
  address: { __typename?: "Address" } & AddressFragment;
};

export type CanCancelVacateFragment = {
  __typename?: "Tenancy";
  id: string;
  canCancelVacate?: Maybe<{
    __typename?: "CancelVacateAbility";
    ableToCancel: boolean;
    notAbleToCancelReason?: Maybe<NotAbleToCancelReason>;
  }>;
};

export type ClearManagementEndMutationVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type ClearManagementEndMutation = {
  __typename?: "Mutation";
  clearManagementEnd?: Maybe<{
    __typename?: "ClearManagementEndPayload";
    management?: Maybe<{
      __typename?: "Management";
      id: string;
      endDate?: Maybe<string>;
      endAbility: {
        __typename?: "ManagementEndAbility";
        canBeEnded: boolean;
        problem?: Maybe<ManagementCannotBeEndedProblem>;
      };
      currentTenancy?: Maybe<
        { __typename?: "Tenancy" } & CanCancelVacateFragment
      >;
    }>;
  }>;
};

export type DeletePropertyKeyMutationVariables = Exact<{
  input: DeleteOneKeyInput;
  organisationAilorn: Scalars["AiloRN"];
}>;

export type DeletePropertyKeyMutation = {
  __typename?: "Mutation";
  deleteOneKey: {
    __typename?: "KeyDeleteResponse";
    id?: Maybe<string>;
    property?: Maybe<{ __typename?: "Property" } & PropertyKeysFragment>;
  };
};

export type DisplayRentFragment = {
  __typename?: "Tenancy";
  displayRent?: Maybe<{ __typename?: "Rent" } & RentFragment>;
};

export type EditableRentFragment = {
  __typename?: "Rent";
  previousRent?: Maybe<{ __typename?: "Rent" } & RentFragment>;
} & RentFragment;

export type EndingManagementDetailsFragment = {
  __typename?: "Management";
  id: string;
  startDate?: Maybe<string>;
  fixedTermEndDate?: Maybe<string>;
  property: { __typename?: "Property" } & PropertyIdAndAddressFragment;
  tenancies?: Maybe<{
    __typename?: "PaginatedTenancies";
    items: Array<{ __typename?: "Tenancy" } & TenancyFragment>;
  }>;
  owners?: Maybe<
    Array<
      Maybe<{
        __typename?: "Ownership";
        owner?: Maybe<
          | ({ __typename?: "Company" } & LegalEntityName_Company_Fragment)
          | ({ __typename?: "Person" } & LegalEntityName_Person_Fragment)
        >;
      }>
    >
  >;
};

export type FeeBlueprintFragment = {
  __typename?: "FeeBlueprint";
  id: string;
  ailoRN: string;
  name: string;
  type: FeeBlueprintType;
  chargeType: FeeBlueprintChargeType;
  taxTreatment: TaxTreatment;
  frequency?: Maybe<FeeFrequency>;
  anniversaryDay?: Maybe<number>;
  anniversaryMonth?: Maybe<number>;
  oneWeekRentPercentage?: Maybe<number>;
  archived: boolean;
  taxCategory: { __typename?: "FeeTaxCategory"; id: string; name: string };
  fixedAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
};

export type FormSidebarTenancyDetailsFragment = {
  __typename?: "Tenancy";
  id: string;
  displayRent?: Maybe<{
    __typename?: "Rent";
    id: string;
    amountInCents: string;
    period: RentFrequency;
  }>;
  liability?: Maybe<{
    __typename?: "Liability";
    effectivePaidToDate?: Maybe<string>;
  }>;
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    owners?: Maybe<
      Array<
        Maybe<{
          __typename?: "Ownership";
          owner?: Maybe<
            | ({ __typename?: "Company" } & LegalEntityName_Company_Fragment)
            | ({ __typename?: "Person" } & LegalEntityName_Person_Fragment)
          >;
        }>
      >
    >;
  }>;
  tenantships?: Maybe<{
    __typename?: "PaginatedTenantships";
    items: Array<{
      __typename?: "Tenantship";
      tenant?: Maybe<
        | ({ __typename?: "Company" } & LegalEntityName_Company_Fragment)
        | ({ __typename?: "Person" } & LegalEntityName_Person_Fragment)
      >;
    }>;
  }>;
} & TenancyPropertyFragment;

export type FormerOrVoidedTenancyFragment = {
  __typename?: "Tenancy";
  id: string;
  endDate?: Maybe<string>;
  voidedAt?: Maybe<string>;
  liability?: Maybe<{
    __typename?: "Liability";
    id: string;
    reference: string;
    terminationAmountDue?: Maybe<{ __typename?: "Money"; cents: number }>;
  }>;
  payerBillsSummary?: Maybe<{
    __typename?: "PayerBillsSummary";
    outstandingBillsBalance: { __typename?: "Money"; cents: number };
  }>;
  tenantships?: Maybe<{
    __typename?: "PaginatedTenantships";
    items: Array<{
      __typename?: "Tenantship";
      tenant?: Maybe<
        | {
            __typename: "Company";
            registeredEntityName: string;
            registeredEntityId?: Maybe<string>;
            id: string;
          }
        | {
            __typename: "Person";
            firstName: string;
            lastName?: Maybe<string>;
            phoneNo?: Maybe<string>;
            emailAddress?: Maybe<string>;
            id: string;
          }
      >;
    }>;
  }>;
};

export type GetAgencyAndManagementFeeBlueprintsQueryVariables = Exact<{
  agencyBlueprintConditions?: Maybe<FeeBlueprintsQueryConditions>;
  managementBlueprintConditions?: Maybe<ManagementFeeBlueprintsQueryConditions>;
  isAgencyBlueprint?: Maybe<Scalars["Boolean"]>;
  isManagementBlueprint?: Maybe<Scalars["Boolean"]>;
}>;

export type GetAgencyAndManagementFeeBlueprintsQuery = {
  __typename?: "Query";
  agencyBlueprints?: Maybe<{
    __typename?: "PaginatedFeeBlueprints";
    items: Array<{ __typename?: "FeeBlueprint" } & FeeBlueprintFragment>;
  }>;
  managementBlueprints?: Maybe<{
    __typename?: "PaginatedManagementFeeBlueprints";
    items: Array<{
      __typename?: "ManagementFeeBlueprint";
      id: string;
      ailorn: string;
      oneWeekRentPercentage?: Maybe<number>;
      chargeType: FeeBlueprintChargeType;
      taxTreatment: TaxTreatment;
      feeBlueprint: {
        __typename?: "FeeBlueprint";
        id: string;
        name: string;
        type: FeeBlueprintType;
        ailorn: string;
        taxCategory: {
          __typename?: "FeeTaxCategory";
          id: string;
          name: string;
        };
      };
      fixedAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
    }>;
  }>;
};

export type GetAgencyPropertyQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAgencyPropertyQuery = {
  __typename?: "Query";
  agencyProperty?: Maybe<
    { __typename?: "AgencyProperty" } & AgencyPropertyFragment
  >;
};

export type GetBasicPropertyDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetBasicPropertyDetailsQuery = {
  __typename?: "Query";
  management?: Maybe<
    {
      __typename?: "Management";
      id: string;
      endDate?: Maybe<string>;
    } & ManagementPropertyFragment
  >;
};

export type GetCentrepayersQueryVariables = Exact<{
  input: GetCentrepayersInput;
}>;

export type GetCentrepayersQuery = {
  __typename?: "Query";
  getCentrepayers: Array<{
    __typename?: "CentrepayDirective";
    legalEntity: string;
  }>;
};

export type GetFeeBlueprintsQueryVariables = Exact<{
  conditions?: Maybe<FeeBlueprintsQueryConditions>;
}>;

export type GetFeeBlueprintsQuery = {
  __typename?: "Query";
  blueprints?: Maybe<{
    __typename?: "PaginatedFeeBlueprints";
    items: Array<
      {
        __typename?: "FeeBlueprint";
        event?: Maybe<{
          __typename?: "FeeEvent";
          type: FeeEventType;
          shortDescription: string;
          longDescription: string;
        }>;
      } & FeeBlueprintFragment
    >;
  }>;
};

export type GetIngoingTenancyDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
  organisationAilorn: Scalars["AiloRN"];
}>;

export type GetIngoingTenancyDetailsQuery = {
  __typename?: "Query";
  tenancy?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      startDate?: Maybe<string>;
      bond?: Maybe<{
        __typename?: "TenancyBond";
        id: string;
        reference?: Maybe<string>;
        status: TenancyBondStatus;
        createdAt: string;
        amount: { __typename?: "Money"; cents: number };
      }>;
      rents: {
        __typename?: "PaginatedRents";
        pageInfo: { __typename?: "PageInfo"; total: number };
        items: Array<{
          __typename?: "Rent";
          id: string;
          effectiveDate: string;
        }>;
      };
      tenantships?: Maybe<{
        __typename?: "PaginatedTenantships";
        items: Array<{
          __typename?: "Tenantship";
          tenant?: Maybe<
            | {
                __typename: "Company";
                registeredEntityName: string;
                registeredEntityId?: Maybe<string>;
                id: string;
                contact?: Maybe<{ __typename?: "Contact"; ailorn: string }>;
              }
            | {
                __typename: "Person";
                firstName: string;
                lastName?: Maybe<string>;
                phoneNo?: Maybe<string>;
                emailAddress?: Maybe<string>;
                id: string;
                contact?: Maybe<{ __typename?: "Contact"; ailorn: string }>;
              }
          >;
        }>;
      }>;
    } & TenancyWithTenancyDepositDetailsFragment
  >;
};

export type GetManagementAgencyQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetManagementAgencyQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    managingEntity?: Maybe<{
      __typename?: "Company";
      id: string;
      ailoRN: string;
      organisation: { __typename?: "Organisation"; id: string; name: string };
    }>;
  }>;
};

export type GetManagementFeeQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetManagementFeeQuery = {
  __typename?: "Query";
  managementFeeSchedule: {
    __typename?: "ManagementFeeSchedule";
    id: string;
    ailoRN: string;
    percent: number;
    managementAgreement: {
      __typename?: "ManagementAgreement";
      id: string;
      ailoRN: string;
    };
  };
};

export type GetManagementFirstPublishedQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetManagementFirstPublishedQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    firstPublishedAt?: Maybe<string>;
  }>;
};

export type GetManagementNoteQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetManagementNoteQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    note?: Maybe<{ __typename?: "ManagementNote" } & ManagementNoteFragment>;
  }>;
};

export type GetManagementOwnersQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetManagementOwnersQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    endDate?: Maybe<string>;
    endAbility: {
      __typename?: "ManagementEndAbility";
      canBeEnded: boolean;
      problem?: Maybe<ManagementCannotBeEndedProblem>;
    };
    ownerships?: Maybe<{
      __typename?: "PaginatedOwnerships";
      pageInfo: { __typename?: "PageInfo"; total: number };
      items: Array<{
        __typename?: "Ownership";
        owner?: Maybe<
          | {
              __typename: "Company";
              registeredEntityName: string;
              id: string;
              ailoRN: string;
            }
          | {
              __typename: "Person";
              firstName: string;
              lastName?: Maybe<string>;
              phoneNo?: Maybe<string>;
              emailAddress?: Maybe<string>;
              id: string;
              ailoRN: string;
            }
        >;
      }>;
    }>;
  }>;
};

export type GetManagementProjectsQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetManagementProjectsQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    projects?: Maybe<{
      __typename?: "PaginatedProjects";
      items: Array<{ __typename?: "Project" } & ProjectItemDetailsFragment>;
    }>;
  }>;
};

export type GetManagementPropertyKeysQueryVariables = Exact<{
  id: Scalars["ID"];
  organisationAilorn: Scalars["AiloRN"];
}>;

export type GetManagementPropertyKeysQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    property: { __typename?: "Property" } & PropertyKeysFragment;
  }>;
};

export type GetMembersByOrganisationQueryVariables = Exact<{
  organisationId: Scalars["ID"];
}>;

export type GetMembersByOrganisationQuery = {
  __typename?: "Query";
  organisation?: Maybe<
    { __typename?: "Organisation" } & OrganisationMembersDetailsFragment
  >;
};

export type GetMostRecentTenancyIdQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetMostRecentTenancyIdQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{ __typename?: "Tenancy"; id: string }>;
  }>;
};

export type GetPersonNameQueryVariables = Exact<{
  id: Scalars["AiloRN"];
}>;

export type GetPersonNameQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | { __typename: "Company"; id: string }
    | {
        __typename: "Person";
        firstName: string;
        lastName?: Maybe<string>;
        id: string;
      }
  >;
};

export type GetPropertyAddressAndInvestorsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetPropertyAddressAndInvestorsQuery = {
  __typename?: "Query";
  management?: Maybe<
    {
      __typename?: "Management";
      id: string;
      owners?: Maybe<
        Array<
          Maybe<{
            __typename?: "Ownership";
            ownerId: string;
            owner?: Maybe<
              | ({ __typename?: "Company" } & LegalEntityName_Company_Fragment)
              | ({ __typename?: "Person" } & LegalEntityName_Person_Fragment)
            >;
          }>
        >
      >;
    } & ManagementPropertyFragment
  >;
};

export type GetPropertyAddressByManagementIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetPropertyAddressByManagementIdQuery = {
  __typename?: "Query";
  management?: Maybe<
    { __typename?: "Management"; id: string } & ManagementPropertyFragment
  >;
};

export type GetPropertyDetailsByManagementQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetPropertyDetailsByManagementQuery = {
  __typename?: "Query";
  management?: Maybe<
    {
      __typename?: "Management";
      id: string;
      managingEntity?: Maybe<{
        __typename?: "Company";
        id: string;
        ailoRN: string;
      }>;
      managementFolio: {
        __typename?: "ManagementFolio";
        id: string;
        ailorn: string;
      };
    } & ManagementPropertyFragment
  >;
};

export type GetPropertyKeyQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetPropertyKeyQuery = {
  __typename?: "Query";
  key?: Maybe<{ __typename?: "Key" } & KeyFragment>;
};

export type GetPropertyOccupancyHealthQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetPropertyOccupancyHealthQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    occupancyStatus?: Maybe<OccupancyStatus>;
    startDate?: Maybe<string>;
    mostRecentTenancy?: Maybe<
      {
        __typename?: "Tenancy";
        id: string;
        startDate?: Maybe<string>;
        endDate?: Maybe<string>;
        routineInspectionDueDate?: Maybe<string>;
        mostRecentTenancyAgreement?: Maybe<{
          __typename?: "TenancyAgreement";
          id: string;
          startDate?: Maybe<string>;
          fixedTermEndDate?: Maybe<string>;
          allowedToLapseAt?: Maybe<string>;
        }>;
        currentRentSchedule?: Maybe<{
          __typename?: "RentSchedule";
          id: string;
        }>;
      } & UpcomingTenancyAgreementFragment
    >;
    nextTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      startDate?: Maybe<string>;
    }>;
  }>;
};

export type GetPropertyRentHealthQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetPropertyRentHealthQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    occupancyStatus?: Maybe<OccupancyStatus>;
    mostRecentTenancy?: Maybe<
      {
        __typename?: "Tenancy";
        id: string;
        endDate?: Maybe<string>;
        liability?: Maybe<{
          __typename?: "Liability";
          id: string;
          reference: string;
          effectivePaidToDate?: Maybe<string>;
        }>;
        currentRentSchedule?: Maybe<{
          __typename?: "RentSchedule";
          id: string;
          amountInCents: string;
          period: RentFrequency;
        }>;
      } & UpcomingRentChangesFragment
    >;
  }>;
};

export type GetRecurringFeeQueryVariables = Exact<{
  id: Scalars["AiloRN"];
}>;

export type GetRecurringFeeQuery = {
  __typename?: "Query";
  recurringFee?: Maybe<{
    __typename?: "RecurringFee";
    name: string;
    startDate: string;
    frequency: FeeFrequency;
    description?: Maybe<string>;
    id: string;
    blueprint: {
      __typename?: "RecurringFeeBlueprint";
      id: string;
      fixedAmount: { __typename?: "Money"; cents: number };
    };
    nextOccurrence?: Maybe<{
      __typename?: "FeeOccurrence";
      date: string;
      taxInclusiveAmount: { __typename?: "Money"; cents: number };
    }>;
    currentOrNextSchedule?: Maybe<{
      __typename?: "RecurringFeeSchedule";
      id: string;
      startDate: string;
      taxInclusiveAmount: { __typename?: "Money"; cents: number };
    }>;
  }>;
};

export type GetStopVacatingDataQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetStopVacatingDataQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{ __typename?: "Tenancy" } & CanCancelVacateFragment>;
};

export type GetTeamsByOrganisationQueryVariables = Exact<{
  organisationId: Scalars["ID"];
  organisationAilorn: Scalars["AiloRN"];
}>;

export type GetTeamsByOrganisationQuery = {
  __typename?: "Query";
  organisation?: Maybe<{
    __typename?: "Organisation";
    id: string;
    teams: Array<{
      __typename?: "Team";
      id: string;
      name: string;
      myTeam: boolean;
    }>;
  }>;
  managementsForOrg?: Maybe<{
    __typename?: "BidirectionalPaginatedManagements";
    pageInfo: { __typename?: "BidirectionalPageInfo"; total: number };
  }>;
};

export type GetTeamsWithContactByOrganisationQueryVariables = Exact<{
  organisationId: Scalars["ID"];
}>;

export type GetTeamsWithContactByOrganisationQuery = {
  __typename?: "Query";
  organisation?: Maybe<{
    __typename?: "Organisation";
    id: string;
    teams: Array<{
      __typename?: "Team";
      id: string;
      name: string;
      myTeam: boolean;
      contacts: Array<{ __typename?: "Contact"; ailorn: string }>;
    }>;
  }>;
};

export type GetTenanciesQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetTenanciesQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    occupancyStatus?: Maybe<OccupancyStatus>;
    endDate?: Maybe<string>;
    managingEntity?: Maybe<{ __typename?: "Company"; ailoRN: string }>;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      endDate?: Maybe<string>;
      vacatingReason?: Maybe<VacatingReason>;
      vacatingNotes?: Maybe<string>;
      tenantships?: Maybe<{
        __typename?: "PaginatedTenantships";
        items: Array<{
          __typename?: "Tenantship";
          tenant?: Maybe<
            | { __typename: "Company"; id: string }
            | {
                __typename: "Person";
                firstName: string;
                lastName?: Maybe<string>;
                phoneNo?: Maybe<string>;
                emailAddress?: Maybe<string>;
                id: string;
              }
          >;
        }>;
      }>;
    }>;
    nextTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      tenantships?: Maybe<{
        __typename?: "PaginatedTenantships";
        items: Array<{
          __typename?: "Tenantship";
          tenant?: Maybe<
            | { __typename: "Company"; id: string }
            | {
                __typename: "Person";
                firstName: string;
                lastName?: Maybe<string>;
                phoneNo?: Maybe<string>;
                emailAddress?: Maybe<string>;
                id: string;
              }
          >;
        }>;
      }>;
    }>;
  }>;
};

export type GetTenancyOutstandingLiabilityQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetTenancyOutstandingLiabilityQuery = {
  __typename?: "Query";
  tenancy?: Maybe<
    { __typename?: "Tenancy"; id: string } & TenancyLiabilityFragment
  >;
};

type TenantInfo_Company_Fragment = {
  __typename: "Company";
  registeredEntityName: string;
  registeredEntityId?: Maybe<string>;
  id: string;
  contact?: Maybe<{ __typename?: "Contact"; ailorn: string }>;
};

type TenantInfo_Person_Fragment = {
  __typename: "Person";
  firstName: string;
  lastName?: Maybe<string>;
  phoneNo?: Maybe<string>;
  emailAddress?: Maybe<string>;
  id: string;
  photo?: Maybe<{
    __typename?: "File";
    id: string;
    url?: Maybe<string>;
    thumbnailUrl?: Maybe<string>;
  }>;
  user?: Maybe<{
    __typename?: "User";
    id: number;
    onboardingCompletedAt?: Maybe<string>;
  }>;
  contact?: Maybe<{ __typename?: "Contact"; ailorn: string }>;
};

export type TenantInfoFragment =
  | TenantInfo_Company_Fragment
  | TenantInfo_Person_Fragment;

export type TenancyBondFragment = {
  __typename?: "TenancyBond";
  id: string;
  reference?: Maybe<string>;
  status: TenancyBondStatus;
  failureReason?: Maybe<BondDisbursalFailureReason>;
  createdAt: string;
  modifiedAt?: Maybe<string>;
  amount: { __typename?: "Money"; cents: number };
};

export type GetTenantsAndOutstandingLiabilityQueryVariables = Exact<{
  id: Scalars["ID"];
  organisationAilorn: Scalars["AiloRN"];
}>;

export type GetTenantsAndOutstandingLiabilityQuery = {
  __typename?: "Query";
  tenancy?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      endDate?: Maybe<string>;
      voidedAt?: Maybe<string>;
      rentReviewAllowed?: Maybe<boolean>;
      leaseReviewAllowedOperation?: Maybe<AllowedOperations>;
      payerBillsSummary?: Maybe<{
        __typename?: "PayerBillsSummary";
        outstandingBillsBalance: { __typename?: "Money"; cents: number };
      }>;
      tenantships?: Maybe<{
        __typename?: "PaginatedTenantships";
        items: Array<{
          __typename?: "Tenantship";
          tenant?: Maybe<
            | ({ __typename?: "Company" } & TenantInfo_Company_Fragment)
            | ({ __typename?: "Person" } & TenantInfo_Person_Fragment)
          >;
        }>;
      }>;
      editableRentReview?: Maybe<{
        __typename?: "EditableRentReviewPayload";
        rent?: Maybe<{ __typename?: "Rent" } & EditableRentFragment>;
      }>;
      bond?: Maybe<{ __typename?: "TenancyBond" } & TenancyBondFragment>;
    } & CanCancelVacateFragment &
      TenancyLiabilityFragment &
      UpcomingTenancyAgreementFragment
  >;
};

export type KeyFragment = {
  __typename?: "Key";
  id: string;
  code: string;
  description?: Maybe<string>;
  property: { __typename?: "Property" } & PropertyIdAndAddressFragment;
};

type LegalEntityName_Company_Fragment = {
  __typename: "Company";
  registeredEntityName: string;
  id: string;
};

type LegalEntityName_Person_Fragment = {
  __typename: "Person";
  firstName: string;
  lastName?: Maybe<string>;
  id: string;
};

export type LegalEntityNameFragment =
  | LegalEntityName_Company_Fragment
  | LegalEntityName_Person_Fragment;

export type ManagementFragment = {
  __typename?: "Management";
  id: string;
  ailoRN: string;
  startDate?: Maybe<string>;
  endDate?: Maybe<string>;
};

export type ManagementAgreementFragment = {
  __typename?: "ManagementAgreement";
  id: string;
  startDate?: Maybe<string>;
  fixedTermEndDate?: Maybe<string>;
  inspectionFrequency?: Maybe<string>;
};

export type GetManagementEndDateQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetManagementEndDateQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    endDate?: Maybe<string>;
  }>;
};

export type ManagementEndReasonFragment = {
  __typename?: "ManagementEndReason";
  code: ManagementEndReasonCode;
  label: string;
  causes: Array<
    { __typename?: "ManagementEndCause" } & ManagementEndCauseFragment
  >;
};

export type ManagementFormerAndVoidedTenanciesFragment = {
  __typename?: "Management";
  id: string;
  formerTenancies?: Maybe<{
    __typename?: "BidirectionalPaginatedTenancies";
    items: Array<{ __typename?: "Tenancy" } & FormerOrVoidedTenancyFragment>;
  }>;
  voidedTenancies?: Maybe<{
    __typename?: "BidirectionalPaginatedTenancies";
    items: Array<{ __typename?: "Tenancy" } & FormerOrVoidedTenancyFragment>;
  }>;
};

export type ManagementEndCauseFragment = {
  __typename?: "ManagementEndCause";
  code: ManagementEndCauseCode;
  label: string;
};

export type ManagementNoteFragment = {
  __typename?: "ManagementNote";
  id: string;
  ailoRN: string;
  note: string;
  management: { __typename?: "Management"; id: string };
};

export type OrganisationMembersDetailsFragment = {
  __typename?: "Organisation";
  id: string;
  members?: Maybe<{
    __typename?: "PaginatedPeople";
    items: Array<{ __typename?: "Person" } & PersonNamesFragment>;
  }>;
};

export type PersonNamesFragment = {
  __typename: "Person";
  id: string;
  ailoRN: string;
  firstName: string;
  legalFirstName: string;
  legalMiddleName?: Maybe<string>;
  preferredName?: Maybe<string>;
  lastName?: Maybe<string>;
};

export type PropertyKeysFragment = {
  __typename?: "Property";
  id: string;
  keys: Array<{ __typename?: "Key" } & KeyFragment>;
};

export type RentFragment = {
  __typename?: "Rent";
  id: string;
  amountInCents: string;
  dailyRate: number;
  period: RentFrequency;
  effectiveDate: string;
};

export type SearchAgencyPropertiesQueryVariables = Exact<{
  search: Scalars["String"];
  orgAilorn: Scalars["AiloRN"];
  pageSize: Scalars["Int"];
}>;

export type SearchAgencyPropertiesQuery = {
  __typename?: "Query";
  searchAgencyProperties?: Maybe<{
    __typename?: "PaginatedAgencyPropertySearchItems";
    items: Array<
      { __typename?: "AgencyProperty" } & AgencyPropertyAddressFragment
    >;
  }>;
};

export type SearchManagementsQueryVariables = Exact<{
  search: Scalars["String"];
  orgAilorn: Scalars["AiloRN"];
}>;

export type SearchManagementsQuery = {
  __typename?: "Query";
  managementsForOrg?: Maybe<{
    __typename?: "BidirectionalPaginatedManagements";
    items: Array<
      { __typename?: "Management"; id: string } & ManagementPropertyFragment
    >;
  }>;
};

export type SearchTenanciesQueryVariables = Exact<{
  filters?: Maybe<TenanciesQueryFilterInput>;
  sort?: Maybe<Array<TenanciesSortParams>>;
  pageSize: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
}>;

export type SearchTenanciesQuery = {
  __typename?: "Query";
  searchTenancies?: Maybe<{
    __typename?: "PaginatedTenancySearchResultItems";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
    };
    items: Array<{
      __typename?: "TenancySearchResult";
      id: string;
      tenancy: { __typename?: "Tenancy" } & SearchTenanciesResultFragment;
    }>;
  }>;
};

export type SearchTenanciesResultFragment = {
  __typename?: "Tenancy";
  id: string;
  routineInspectionDueDate?: Maybe<string>;
  property: { __typename?: "Property" } & PropertyIdAndAddressFragment;
  lastCompletedRoutineInspection?: Maybe<{
    __typename?: "Inspection";
    id: string;
    completedAt?: Maybe<string>;
  }>;
};

export type TenancyFragment = {
  __typename?: "Tenancy";
  id: string;
  ailoRN: string;
  startDate?: Maybe<string>;
  endDate?: Maybe<string>;
  voidedAt?: Maybe<string>;
  property: { __typename?: "Property"; id: string; ailorn: string };
};

export type TenancyAgreementFragment = {
  __typename?: "TenancyAgreement";
  id: string;
  startDate?: Maybe<string>;
  fixedTermEndDate?: Maybe<string>;
  allowedToLapseAt?: Maybe<string>;
};

export type TenancyIsVoidableFragment = {
  __typename?: "Tenancy";
  id: string;
  isVoidable: {
    __typename?: "VoidTenancyAbility";
    voidable: boolean;
    unvoidableReason?: Maybe<UnvoidableTenancyReason>;
  };
};

export type TenancyLiabilityFragment = {
  __typename?: "Tenancy";
  id: string;
  liability?: Maybe<{
    __typename?: "Liability";
    id: string;
    reference: string;
    terminationAmountDue?: Maybe<{ __typename?: "Money"; cents: number }>;
  }>;
};

export type TenancyVoidedAtFragment = {
  __typename?: "Tenancy";
  id: string;
  voidedAt?: Maybe<string>;
};

export type NextRentFragment = {
  __typename?: "Tenancy";
  nextRentSchedule?: Maybe<{
    __typename?: "RentSchedule";
    id: string;
    amountInCents: string;
    period: RentFrequency;
    startDate: string;
  }>;
};

export type NextRentReviewFragment = {
  __typename?: "Tenancy";
  nextRentReview?: Maybe<{
    __typename?: "Rent";
    id: string;
    amountInCents: string;
    period: RentFrequency;
    effectiveDate: string;
  }>;
};

export type UpcomingRentChangesFragment = {
  __typename?: "Tenancy";
  scheduledRentReviewDate?: Maybe<string>;
} & NextRentFragment &
  NextRentReviewFragment;

export type UpcomingTenancyAgreementFragment = {
  __typename?: "Tenancy";
  nextTenancyAgreement?: Maybe<
    { __typename?: "TenancyAgreement" } & TenancyAgreementFragment
  >;
};

export type GetStatementsQueryVariables = Exact<{
  filter?: Maybe<GeneralStatementFilter>;
  cursor?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
  sort?: Maybe<Array<StatementSort>>;
}>;

export type GetStatementsQuery = {
  __typename?: "Query";
  statements?: Maybe<{
    __typename?: "PaginatedStatements";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      hasNext: boolean;
      hasPrevious: boolean;
      nextCursor?: Maybe<string>;
      previousCursor?: Maybe<string>;
      total: number;
    };
    items: Array<{
      __typename?: "Statement";
      id: string;
      type: StatementType;
      rangeStartDate: string;
      rangeEndDate: string;
      ailorn: string;
      file?: Maybe<{
        __typename?: "File";
        url?: Maybe<string>;
        kind: FileKind;
        contentType?: Maybe<string>;
        ailorn: string;
      }>;
      statementProgress?: Maybe<{
        __typename?: "StatementProgress";
        id: string;
        rangeStartBusinessTxId?: Maybe<string>;
        rangeEndBusinessTxId?: Maybe<string>;
      }>;
    }>;
  }>;
};

export type GetTransferStatementDataQueryVariables = Exact<{
  rangeStartBusinessTxId?: Maybe<Scalars["ID"]>;
  rangeEndBusinessTxId: Scalars["ID"];
  walletOwnerReference: Scalars["AiloRN"];
  paginationParams?: Maybe<PaginationParams>;
}>;

export type GetTransferStatementDataQuery = {
  __typename?: "Query";
  transferSummaryStatementData?: Maybe<{
    __typename?: "WalletStatementData";
    transactionLineItemsByStatementTransactionDate?: Maybe<{
      __typename?: "PaginatedStatementTransactionLineItem";
      pageInfo: {
        __typename?: "LedgerBidirectionalPageInfo";
        total: number;
        hasNext: boolean;
        nextCursor?: Maybe<string>;
      };
      items: Array<{
        __typename: "StatementTransactionLineItem";
        sourceId: string;
        clearedDate?: Maybe<string>;
        statementTransactionDateTime?: Maybe<string>;
        businessTransactionStatus: string;
        businessTransactionType?: Maybe<string>;
        management?: Maybe<{
          __typename?: "Management";
          id: string;
          team?: Maybe<{ __typename?: "Team"; id: string; name: string }>;
          property: {
            __typename?: "Property";
            id: string;
            address: { __typename?: "Address" } & AddressFragment;
          };
        }>;
        liability?: Maybe<{
          __typename?: "Liability";
          id: string;
          providerReference?: Maybe<string>;
          taxCategory: string;
        }>;
        gst: { __typename?: "Money"; cents: number };
        btAmount: { __typename?: "Money"; cents: number };
        walletChangeAmount: { __typename?: "Money"; cents: number };
      }>;
    }>;
  }>;
};

export type AbrCompanyLookupQueryVariables = Exact<{
  abnOrAcn: Scalars["String"];
}>;

export type AbrCompanyLookupQuery = {
  __typename?: "Query";
  abrCompanyLookup?: Maybe<{
    __typename: "AbrCompanyLookupResult";
    registeredEntityName: string;
    abn: string;
    acn?: Maybe<string>;
    addressState: string;
    addressPostcode: string;
  }>;
};

export type CreatePaymentMethodMutationVariables = Exact<{
  ownerAiloRN: Scalars["AiloRN"];
  managingOrganisationAiloRN: Scalars["AiloRN"];
  bankAccount?: Maybe<BankAccountInput>;
  bpay?: Maybe<BPayInput>;
  createWalletOwner?: Maybe<Scalars["Boolean"]>;
  deleteExistingPaymentMethods?: Maybe<Scalars["Boolean"]>;
}>;

export type CreatePaymentMethodMutation = {
  __typename?: "Mutation";
  createPaymentMethod?: Maybe<
    | { __typename?: "BankAccount"; id: string }
    | { __typename?: "BPay"; id: string }
    | { __typename?: "CreditCard"; id: string }
    | { __typename?: "VirtualAccount"; id: string }
  >;
};

export type CreateSupplierMutationVariables = Exact<{
  organisation: Scalars["AiloRN"];
  abn: Scalars["String"];
  name: Scalars["String"];
  registeredEntityName: Scalars["String"];
  address: SupplierAddressInput;
  emailAddress?: Maybe<Scalars["String"]>;
}>;

export type CreateSupplierMutation = {
  __typename?: "Mutation";
  createSupplier?: Maybe<{
    __typename?: "Supplier";
    id: string;
    ailoRN: string;
  }>;
};

export type GetAnalyticsDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetAnalyticsDataQuery = {
  __typename?: "Query";
  effectiveUser?: Maybe<{
    __typename?: "User";
    id: number;
    person: {
      __typename?: "Person";
      id: string;
      firstName: string;
      lastName?: Maybe<string>;
      emailAddress?: Maybe<string>;
    };
    organisations: Array<{ __typename?: "Organisation"; id: string }>;
  }>;
};

export type GetAvailableFeaturesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAvailableFeaturesQuery = {
  __typename?: "Query";
  effectiveUser?: Maybe<{
    __typename?: "User";
    id: number;
    organisations: Array<{
      __typename?: "Organisation";
      id: string;
      orgType: OrganisationType;
      name: string;
      availableFeatures: Array<{
        __typename?: "PlatformFeature";
        id: PlatformFeatureId;
      }>;
      legalEntities?: Maybe<
        Array<
          Maybe<
            | {
                __typename?: "Company";
                ailoRN: string;
                currentUserPermissions: Array<string>;
                timezone: string;
                registeredEntityName: string;
                id: string;
                trustAccounts: {
                  __typename?: "CompanyTrustAccountsConnection";
                  edges: Array<{
                    __typename?: "TrustAccountEdge";
                    node: {
                      __typename?: "TrustAccount";
                      id: string;
                      ailorn: string;
                    };
                  }>;
                };
              }
            | { __typename?: "Person"; id: string }
          >
        >
      >;
      effectiveUserContact?: Maybe<{ __typename?: "Contact"; ailorn: string }>;
    }>;
    person: {
      __typename?: "Person";
      id: string;
      ailoRN: string;
      firstName: string;
      lastName?: Maybe<string>;
      emailAddress?: Maybe<string>;
      eulaSignedAt?: Maybe<string>;
      photo?: Maybe<{
        __typename?: "File";
        id: string;
        url?: Maybe<string>;
        thumbnailUrl?: Maybe<string>;
      }>;
    };
  }>;
};

export type CreateActionMutationVariables = Exact<{
  input: CreateActionInput;
}>;

export type CreateActionMutation = {
  __typename?: "Mutation";
  createAction?: Maybe<{ __typename?: "Action" } & ActionDetailsFragment>;
};

export type GetActionUpdateDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetActionUpdateDetailsQuery = {
  __typename?: "Query";
  action?: Maybe<
    {
      __typename?: "Action";
      project: {
        __typename?: "Project";
        id: string;
        organisation?: Maybe<{
          __typename?: "Organisation";
          id: string;
          members?: Maybe<{
            __typename?: "PaginatedPeople";
            items: Array<
              {
                __typename?: "Person";
                photo?: Maybe<{
                  __typename?: "File";
                  id: string;
                  url?: Maybe<string>;
                  thumbnailUrl?: Maybe<string>;
                }>;
              } & PersonNamesFragment
            >;
          }>;
        }>;
      };
    } & ActionDetailsFragment
  >;
};

export type UpdateActionMutationVariables = Exact<{
  input: UpdateActionInput;
}>;

export type UpdateActionMutation = {
  __typename?: "Mutation";
  updateAction?: Maybe<{ __typename?: "Action" } & ActionDetailsFragment>;
};

export type GetActionAssigneesQueryVariables = Exact<{
  organisationId: Scalars["ID"];
}>;

export type GetActionAssigneesQuery = {
  __typename?: "Query";
  organisation?: Maybe<{
    __typename?: "Organisation";
    id: string;
    members?: Maybe<{
      __typename?: "PaginatedPeople";
      items: Array<{ __typename?: "Person" } & PersonDisplayDetailsFragment>;
    }>;
  }>;
};

export type PersonEmailInUseQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type PersonEmailInUseQuery = {
  __typename?: "Query";
  personEmailInUse: boolean;
};

export type GetManagementDetailsForEditQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetManagementDetailsForEditQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    startDate?: Maybe<string>;
    endDate?: Maybe<string>;
    scheduledRentReviewDate?: Maybe<string>;
    nextTenancyAgreement?: Maybe<{
      __typename?: "TenancyAgreement";
      id: string;
      startDate?: Maybe<string>;
      fixedTermEndDate?: Maybe<string>;
    }>;
    displayRent?: Maybe<{
      __typename?: "Rent";
      id: string;
      amountInCents: string;
      period: RentFrequency;
      effectiveDate: string;
    }>;
    management?: Maybe<{
      __typename?: "Management";
      id: string;
      mostRecentTenancy?: Maybe<{
        __typename?: "Tenancy";
        id: string;
        endDate?: Maybe<string>;
      }>;
    }>;
  }>;
};

export type CreateManagementMutationVariables = Exact<{
  managementDetails: ManagementAndTenancyInput;
}>;

export type CreateManagementMutation = {
  __typename?: "Mutation";
  setupManagementAndTenancy?: Maybe<{
    __typename?: "SetupResult";
    management?: Maybe<{ __typename?: "Management" } & ManagementFragment>;
  }>;
};

export type UpdateIngoingManagementMutationVariables = Exact<{
  tenancyDetails: IngoingTenancyUpdateInput;
}>;

export type UpdateIngoingManagementMutation = {
  __typename?: "Mutation";
  updateIngoingTenancy?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      scheduledRentReviewDate?: Maybe<string>;
      rents: {
        __typename?: "PaginatedRents";
        items: Array<{ __typename?: "Rent" } & RentFragment>;
      };
      nextTenancyAgreement?: Maybe<{
        __typename?: "TenancyAgreement";
        id: string;
        startDate?: Maybe<string>;
        fixedTermEndDate?: Maybe<string>;
      }>;
      nextRentSchedule?: Maybe<{
        __typename?: "RentSchedule";
        id: string;
        amountInCents: string;
        period: RentFrequency;
        startDate: string;
      }>;
    } & TenancyFragment
  >;
};

export type EndManagementV2MutationVariables = Exact<{
  input: EndManagementV2Input;
}>;

export type EndManagementV2Mutation = {
  __typename?: "Mutation";
  endManagementV2: {
    __typename?: "EndManagementV2Payload";
    management: {
      __typename?: "Management";
      currentTenancy?: Maybe<
        { __typename?: "Tenancy" } & CanCancelVacateFragment
      >;
    } & ManagementEndDetailsFragment;
  };
};

export type GetManagementEndReasonsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetManagementEndReasonsQuery = {
  __typename?: "Query";
  managementEndReasons: Array<
    { __typename?: "ManagementEndReason" } & ManagementEndReasonFragment
  >;
};

export type GetEndingManagementDetailsQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetEndingManagementDetailsQuery = {
  __typename?: "Query";
  management?: Maybe<
    { __typename?: "Management" } & EndingManagementDetailsFragment &
      ManagementEndDetailsFragment
  >;
};

export type GetDataForEndTenancyQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetDataForEndTenancyQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    endDate?: Maybe<string>;
    vacatingReason?: Maybe<VacatingReason>;
    vacatingNotes?: Maybe<string>;
    deposit?: Maybe<{
      __typename?: "TenancyDeposit";
      id: string;
      status: TenancyDepositStatus;
    }>;
    liability?: Maybe<{
      __typename?: "Liability";
      id: string;
      reference: string;
      effectivePaidToDate?: Maybe<string>;
    }>;
    validVacateEndDateRange?: Maybe<{
      __typename?: "DateRange";
      startDate?: Maybe<string>;
      endDate?: Maybe<string>;
    }>;
    mostRecentTenancyAgreement?: Maybe<{
      __typename?: "TenancyAgreement";
      id: string;
      startDate?: Maybe<string>;
      fixedTermEndDate?: Maybe<string>;
      allowedToLapseAt?: Maybe<string>;
    }>;
    management?: Maybe<{
      __typename?: "Management";
      nextTenancy?: Maybe<{
        __typename?: "Tenancy";
        id: string;
        startDate?: Maybe<string>;
      }>;
    }>;
    property: { __typename?: "Property" } & PropertyIdAndAddressFragment;
  }>;
};

export type UpdateManagementAgreementMutationVariables = Exact<{
  input: UpdateManagementAgreementInput;
}>;

export type UpdateManagementAgreementMutation = {
  __typename?: "Mutation";
  updateManagementAgreement: {
    __typename?: "UpdateManagementAgreementPayload";
    managementAgreement?: Maybe<
      { __typename?: "ManagementAgreement" } & ManagementAgreementFragment
    >;
  };
};

export type GetLeaseRenewalDataQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetLeaseRenewalDataQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    endDate?: Maybe<string>;
    rentReviewAllowed?: Maybe<boolean>;
    leaseReviewAllowedOperation?: Maybe<AllowedOperations>;
    mostRecentTenancyAgreement?: Maybe<{
      __typename?: "TenancyAgreement";
      id: string;
      startDate?: Maybe<string>;
      fixedTermEndDate?: Maybe<string>;
      allowedToLapseAt?: Maybe<string>;
    }>;
    nextTenancyAgreement?: Maybe<{
      __typename?: "TenancyAgreement";
      id: string;
      startDate?: Maybe<string>;
      fixedTermEndDate?: Maybe<string>;
      allowedToLapseAt?: Maybe<string>;
    }>;
  }>;
};

export type CompleteAssignTenancyAgreementActionMutationVariables = Exact<{
  id: Scalars["ID"];
  input: CompleteAssignTenancyAgreementActionInput;
}>;

export type CompleteAssignTenancyAgreementActionMutation = {
  __typename?: "Mutation";
  completeAssignTenancyAgreementAction?: Maybe<{
    __typename?: "Action";
    id: string;
    meta?: Maybe<
      | {
          __typename?: "AssignTenancyAgreementActionMeta";
          tenancyAgreement?: Maybe<{
            __typename?: "TenancyAgreement";
            id: string;
            startDate?: Maybe<string>;
            fixedTermEndDate?: Maybe<string>;
          }>;
        }
      | { __typename?: "AssignRentActionMeta" }
      | { __typename?: "AssignTenancyActionMeta" }
      | { __typename?: "AssignNewManagementActionMeta" }
      | { __typename?: "AssignFormActionMeta" }
      | { __typename?: "RequestSignatureActionMeta" }
      | { __typename?: "ManageDepositActionMeta" }
      | { __typename?: "TrackDocusignActionMeta" }
      | { __typename?: "TrackInspectionCompletedActionMeta" }
      | { __typename?: "GenerateInspectionReportActionMeta" }
      | { __typename?: "EndTenancyActionMeta" }
      | { __typename?: "ConfirmInspectionScheduleActionMeta" }
      | { __typename?: "AssignMessageActionMeta" }
    >;
  }>;
};

export type ReviewLeaseMutationVariables = Exact<{
  tenancyId: Scalars["ID"];
  agreementDetails?: Maybe<LeaseRenewalDetails>;
  allowCurrentTenancyAgreementToLapse?: Maybe<Scalars["Boolean"]>;
}>;

export type ReviewLeaseMutation = {
  __typename?: "Mutation";
  reviewLease?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      leaseReviewAllowed?: Maybe<boolean>;
      leaseReviewAllowedOperation?: Maybe<AllowedOperations>;
      rentReviewAllowed?: Maybe<boolean>;
      mostRecentTenancyAgreement?: Maybe<{
        __typename?: "TenancyAgreement";
        id: string;
        allowedToLapseAt?: Maybe<string>;
      }>;
    } & UpcomingTenancyAgreementFragment &
      UpcomingRentChangesFragment
  >;
};

export type UpdateTenancyAgreementMutationVariables = Exact<{
  input: UpdateTenancyAgreementInput;
}>;

export type UpdateTenancyAgreementMutation = {
  __typename?: "Mutation";
  updateTenancyAgreement?: Maybe<{
    __typename?: "UpdateTenancyAgreementPayload";
    tenancyAgreement?: Maybe<{
      __typename?: "TenancyAgreement";
      id: string;
      startDate?: Maybe<string>;
      fixedTermEndDate?: Maybe<string>;
    }>;
  }>;
};

export type CreateOrUpdateManagementNoteMutationVariables = Exact<{
  input: CreateOrUpdateManagementNoteInput;
}>;

export type CreateOrUpdateManagementNoteMutation = {
  __typename?: "Mutation";
  note?: Maybe<{
    __typename?: "CreateOrUpdateManagementNotePayload";
    managementNote: { __typename?: "ManagementNote" } & ManagementNoteFragment;
  }>;
};

export type GetManagementInfoQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetManagementInfoQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    currentTenancy?: Maybe<
      { __typename?: "Tenancy" } & FormSidebarTenancyDetailsFragment
    >;
  }>;
};

export type GetProjectFormSidebarDetailsQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetProjectFormSidebarDetailsQuery = {
  __typename?: "Query";
  project?: Maybe<
    { __typename?: "Project"; id: string } & ProjectDetailsFragment
  >;
};

export type CanCreateProjectOfTypeQueryVariables = Exact<{
  managementAilorn: Scalars["AiloRN"];
  type: ProjectType;
}>;

export type CanCreateProjectOfTypeQuery = {
  __typename?: "Query";
  canCreateProjectOfType?: Maybe<{
    __typename?: "CreateProjectAbility";
    ableToCreate: boolean;
    notAbleToCreateReason?: Maybe<string>;
  }>;
};

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;

export type CreateProjectMutation = {
  __typename?: "Mutation";
  createProject?: Maybe<{
    __typename?: "Project";
    id: string;
    type: ProjectType;
  }>;
};

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;

export type UpdateProjectMutation = {
  __typename?: "Mutation";
  updateProject?: Maybe<{ __typename?: "Project" } & ProjectDetailsFragment>;
};

export type ProRataPreviewFragment = {
  __typename?: "PreviewedRentSchedule";
  amountInCents: string;
  period: RentFrequency;
  startDate: string;
  endDate: string;
};

export type ProRataSchedulesForNewRentQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
  proposedRent: ProposedRent;
}>;

export type ProRataSchedulesForNewRentQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    proRataSchedulesForNewRent: Array<
      { __typename?: "PreviewedRentSchedule" } & ProRataPreviewFragment
    >;
  }>;
};

export type ProRataSchedulesForRentUpdateQueryVariables = Exact<{
  rentAilorn: Scalars["AiloRN"];
  proposedRent: ProposedRent;
}>;

export type ProRataSchedulesForRentUpdateQuery = {
  __typename?: "Query";
  rent?: Maybe<{
    __typename?: "Rent";
    id: string;
    proRataSchedulesForRentUpdate: Array<
      { __typename?: "PreviewedRentSchedule" } & ProRataPreviewFragment
    >;
  }>;
};

export type CreateRentReviewMutationVariables = Exact<{
  tenancyId: Scalars["ID"];
  rentDetails: RentReviewDetails;
}>;

export type CreateRentReviewMutation = {
  __typename?: "Mutation";
  createRentReview?: Maybe<{
    __typename?: "CreateRentReviewPayload";
    rent?: Maybe<{ __typename?: "Rent" } & RentFragment>;
    tenancy?: Maybe<
      {
        __typename?: "Tenancy";
        id: string;
        rentReviewAllowed?: Maybe<boolean>;
        editableRentReview?: Maybe<{
          __typename?: "EditableRentReviewPayload";
          rent?: Maybe<{ __typename?: "Rent" } & EditableRentFragment>;
        }>;
      } & UpcomingRentChangesFragment
    >;
  }>;
};

export type EditRentReviewMutationVariables = Exact<{
  input: EditRentReviewInput;
}>;

export type EditRentReviewMutation = {
  __typename?: "Mutation";
  editRentReview?: Maybe<{
    __typename?: "EditRentReviewPayload";
    rent?: Maybe<{ __typename?: "Rent" } & RentFragment>;
    tenancy?: Maybe<
      {
        __typename?: "Tenancy";
        id: string;
        rentReviewAllowed?: Maybe<boolean>;
        editableRentReview?: Maybe<{
          __typename?: "EditableRentReviewPayload";
          rent?: Maybe<{ __typename?: "Rent" } & EditableRentFragment>;
        }>;
      } & UpcomingRentChangesFragment
    >;
  }>;
};

export type GetRentReviewModalDataQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetRentReviewModalDataQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    endDate?: Maybe<string>;
    leaseReviewAllowedOperation?: Maybe<AllowedOperations>;
    scheduledRentReviewDate?: Maybe<string>;
    liability?: Maybe<{
      __typename?: "Liability";
      id: string;
      nextChargeCreateDate?: Maybe<string>;
    }>;
    mostRecentRentReview?: Maybe<
      { __typename?: "Rent"; rentIncreaseInCents: string } & RentFragment
    >;
    currentRent?: Maybe<{ __typename?: "Rent" } & RentFragment>;
    rents: {
      __typename?: "PaginatedRents";
      pageInfo: { __typename?: "PageInfo"; total: number };
      items: Array<{ __typename?: "Rent" } & RentFragment>;
    };
  }>;
};

export type SetRoutineInspectionDueDateMutationVariables = Exact<{
  input: SetRoutineInspectionDueDateInput;
}>;

export type SetRoutineInspectionDueDateMutation = {
  __typename?: "Mutation";
  setRoutineInspectionDueDate: {
    __typename?: "SetRoutineInspectionDueDateResult";
    tenancy: { __typename?: "Tenancy" } & TenancyInspectionScheduleFragment;
  };
};

export type GetNewTenancyFileQueryVariables = Exact<{
  id: Scalars["AiloRN"];
}>;

export type GetNewTenancyFileQuery = {
  __typename?: "Query";
  file?: Maybe<{
    __typename?: "File";
    id: string;
    fileName: string;
    fileSize?: Maybe<number>;
    url?: Maybe<string>;
    contentType?: Maybe<string>;
  }>;
};

export type GetMostRecentTenancyEndQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetMostRecentTenancyEndQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      endDate?: Maybe<string>;
    }>;
  }>;
};

export type GetTenancyDetailsForEditQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetTenancyDetailsForEditQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    startDate?: Maybe<string>;
    endDate?: Maybe<string>;
    scheduledRentReviewDate?: Maybe<string>;
    nextTenancyAgreement?: Maybe<{
      __typename?: "TenancyAgreement";
      id: string;
      startDate?: Maybe<string>;
      fixedTermEndDate?: Maybe<string>;
    }>;
    displayRent?: Maybe<{
      __typename?: "Rent";
      id: string;
      amountInCents: string;
      period: RentFrequency;
      effectiveDate: string;
    }>;
    management?: Maybe<{
      __typename?: "Management";
      id: string;
      mostRecentTenancy?: Maybe<{
        __typename?: "Tenancy";
        id: string;
        endDate?: Maybe<string>;
      }>;
    }>;
  }>;
};

export type CreateTenancyMutationVariables = Exact<{
  tenancyDetails: TenancySetupInput;
  managementId: Scalars["ID"];
}>;

export type CreateTenancyMutation = {
  __typename?: "Mutation";
  createTenancy?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      management?: Maybe<{
        __typename?: "Management";
        id: string;
        endAbility: {
          __typename?: "ManagementEndAbility";
          canBeEnded: boolean;
          problem?: Maybe<ManagementCannotBeEndedProblem>;
        };
        currentTenancy?: Maybe<
          { __typename?: "Tenancy" } & CanCancelVacateFragment
        >;
        mostRecentTenancy?: Maybe<{ __typename?: "Tenancy"; id: string }>;
        nextTenancy?: Maybe<{ __typename?: "Tenancy"; id: string }>;
      }>;
      tenantships?: Maybe<{
        __typename?: "PaginatedTenantships";
        pageInfo: { __typename?: "PageInfo"; total: number };
        items: Array<{
          __typename?: "Tenantship";
          tenant?: Maybe<
            | {
                __typename: "Company";
                registeredEntityName: string;
                id: string;
              }
            | {
                __typename: "Person";
                firstName: string;
                lastName?: Maybe<string>;
                id: string;
                photo?: Maybe<{
                  __typename?: "File";
                  id: string;
                  url?: Maybe<string>;
                  thumbnailUrl?: Maybe<string>;
                }>;
              }
          >;
        }>;
      }>;
    } & TenancyFragment
  >;
};

export type UpdateIngoingTenancyMutationVariables = Exact<{
  tenancyDetails: IngoingTenancyUpdateInput;
}>;

export type UpdateIngoingTenancyMutation = {
  __typename?: "Mutation";
  updateIngoingTenancy?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      scheduledRentReviewDate?: Maybe<string>;
      rents: {
        __typename?: "PaginatedRents";
        items: Array<{ __typename?: "Rent" } & RentFragment>;
      };
      nextTenancyAgreement?: Maybe<{
        __typename?: "TenancyAgreement";
        id: string;
        startDate?: Maybe<string>;
        fixedTermEndDate?: Maybe<string>;
      }>;
      nextRentSchedule?: Maybe<{
        __typename?: "RentSchedule";
        id: string;
        amountInCents: string;
        period: RentFrequency;
        startDate: string;
      }>;
    } & TenancyFragment
  >;
};

export type GetIngoingTenancyIsVoidableQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetIngoingTenancyIsVoidableQuery = {
  __typename?: "Query";
  tenancy?: Maybe<
    { __typename?: "Tenancy"; id: string } & TenancyIsVoidableFragment
  >;
};

export type VoidTenancyMutationVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type VoidTenancyMutation = {
  __typename?: "Mutation";
  voidTenancy: {
    __typename?: "VoidTenancyPayload";
    tenancy: {
      __typename?: "Tenancy";
      management?: Maybe<{
        __typename?: "Management";
        id: string;
        nextTenancy?: Maybe<{ __typename?: "Tenancy"; id: string }>;
        endAbility: {
          __typename?: "ManagementEndAbility";
          canBeEnded: boolean;
          problem?: Maybe<ManagementCannotBeEndedProblem>;
        };
        mostRecentTenancy?: Maybe<
          { __typename?: "Tenancy" } & CanCancelVacateFragment
        >;
        voidedTenancies?: Maybe<{
          __typename?: "BidirectionalPaginatedTenancies";
          items: Array<
            { __typename?: "Tenancy" } & FormerOrVoidedTenancyFragment
          >;
        }>;
      }>;
    } & TenancyVoidedAtFragment &
      TenancyIsVoidableFragment;
  };
};

export type GetPropertyDetailsForTenancyQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetPropertyDetailsForTenancyQuery = {
  __typename?: "Query";
  tenancy?: Maybe<
    { __typename?: "Tenancy" } & FormSidebarTenancyDetailsFragment
  >;
};

export type GetDashboardCardDetailsQueryVariables = Exact<{
  name: Scalars["String"];
  managingEntityId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
  includeUnassigned?: Maybe<Scalars["Boolean"]>;
}>;

export type GetDashboardCardDetailsQuery = {
  __typename?: "Query";
  dashboardFigureByName?: Maybe<{
    __typename?: "DashboardFigure";
    type: DashboardFigureType;
    data:
      | { __typename?: "DashboardFigurePeriodComparison" }
      | {
          __typename?: "DashboardFigureTeamPeriodComparison";
          currentTotal: number;
          teams: Array<{
            __typename?: "TeamPeriodComparison";
            currentValue: string;
            teamName: string;
            id: string;
          }>;
        };
  }>;
};

export type GetBillByIdQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type GetBillByIdQuery = {
  __typename?: "Query";
  billById: {
    __typename?: "Bill";
    ailoRN: string;
    dueDateV2: string;
    status: BillStatus;
    agencyStatus: BillAgencyStatus;
    supplier?: Maybe<{
      __typename?: "Supplier";
      ailoRN: string;
      name?: Maybe<string>;
      internalLegalEntity?: Maybe<
        {
          __typename?: "LegalEntityCompanion";
        } & LegalEntityCompanionOrganisationFragmentFragment
      >;
    }>;
    liabilityState?: Maybe<{
      __typename?: "BillLiabilityState";
      paymentStatus?: Maybe<string>;
      payByDateV2?: Maybe<string>;
    }>;
    payer?: Maybe<
      | ({ __typename?: "Tenancy" } & BillPayerNames_Tenancy_Fragment)
      | ({ __typename?: "Management" } & BillPayerNames_Management_Fragment)
      | ({
          __typename?: "LegalEntityCompanion";
        } & BillPayerNames_LegalEntityCompanion_Fragment)
    >;
    amount: { __typename?: "Money"; cents: number };
    management?: Maybe<
      { __typename?: "Management"; id: string } & ManagementPropertyFragment
    >;
  };
};

export type GetBillsQueryVariables = Exact<{
  organisationId: Scalars["AiloRN"];
  paid?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Array<BillStatus>>;
  dueDate?: Maybe<LocalDateRangeCondition>;
  sort?: Maybe<BillSortParams>;
  cursor?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
  payerType?: Maybe<Array<BillPayerType>>;
  team?: Maybe<Array<Scalars["AiloRN"]>>;
  includeUnassigned?: Maybe<Scalars["Boolean"]>;
}>;

export type GetBillsQuery = {
  __typename?: "Query";
  bills?: Maybe<{
    __typename?: "PaginatedBills";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
    };
    items: Array<{
      __typename?: "Bill";
      ailoRN: string;
      dueDateV2: string;
      status: BillStatus;
      agencyStatus: BillAgencyStatus;
      supplier?: Maybe<{
        __typename?: "Supplier";
        ailoRN: string;
        name?: Maybe<string>;
        internalLegalEntity?: Maybe<
          {
            __typename?: "LegalEntityCompanion";
          } & LegalEntityCompanionOrganisationFragmentFragment
        >;
      }>;
      liabilityState?: Maybe<{
        __typename?: "BillLiabilityState";
        paymentStatus?: Maybe<string>;
        payByDateV2?: Maybe<string>;
      }>;
      payer?: Maybe<
        | ({ __typename?: "Tenancy" } & BillPayerNames_Tenancy_Fragment)
        | ({ __typename?: "Management" } & BillPayerNames_Management_Fragment)
        | ({
            __typename?: "LegalEntityCompanion";
          } & BillPayerNames_LegalEntityCompanion_Fragment)
      >;
      amount: { __typename?: "Money"; cents: number };
      management?: Maybe<
        { __typename?: "Management"; id: string } & ManagementPropertyFragment
      >;
    }>;
  }>;
};

type BillPayerNames_Tenancy_Fragment = {
  __typename: "Tenancy";
  tenantships?: Maybe<{
    __typename?: "PaginatedTenantships";
    items: Array<{
      __typename?: "Tenantship";
      tenant?: Maybe<
        | { __typename: "Company"; registeredEntityName: string; id: string }
        | {
            __typename: "Person";
            firstName: string;
            lastName?: Maybe<string>;
            id: string;
          }
      >;
    }>;
  }>;
};

type BillPayerNames_Management_Fragment = {
  __typename: "Management";
  ownerships?: Maybe<{
    __typename?: "PaginatedOwnerships";
    items: Array<{
      __typename?: "Ownership";
      owner?: Maybe<
        | { __typename: "Company"; registeredEntityName: string; id: string }
        | {
            __typename: "Person";
            firstName: string;
            lastName?: Maybe<string>;
            id: string;
          }
      >;
    }>;
  }>;
};

type BillPayerNames_LegalEntityCompanion_Fragment = {
  __typename: "LegalEntityCompanion";
  id: string;
  ailoRNV2: string;
};

export type BillPayerNamesFragment =
  | BillPayerNames_Tenancy_Fragment
  | BillPayerNames_Management_Fragment
  | BillPayerNames_LegalEntityCompanion_Fragment;

export type GetPagedContactsQueryVariables = Exact<{
  query?: Maybe<Scalars["String"]>;
  typeOf?: Maybe<Array<Scalars["String"]>>;
  organisationAilornIn?: Maybe<Array<Scalars["AiloRN"]>>;
  sort?: Maybe<Array<ContactSortParams>>;
  paginationParams: PaginationParamsWithPageNumber;
}>;

export type GetPagedContactsQuery = {
  __typename?: "Query";
  searchContacts?: Maybe<{
    __typename?: "PaginatedContactSearchItems";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      hasPrevious: boolean;
    };
    items: Array<{
      __typename?: "Contact";
      id: string;
      ailorn: string;
      displayName: string;
      primaryEmail?: Maybe<string>;
      types: Array<ContactType>;
      user?: Maybe<{ __typename?: "User"; ailoRN: string }>;
      confidential?: Maybe<{
        __typename?: "ContactConfidential";
        primaryPhone?: Maybe<string>;
      }>;
      legalEntity?: Maybe<
        | {
            __typename: "Company";
            id: string;
            registeredEntityId?: Maybe<string>;
            registeredEntityName: string;
          }
        | {
            __typename: "Person";
            id: string;
            firstName: string;
            lastName?: Maybe<string>;
            phoneNo?: Maybe<string>;
          }
      >;
    }>;
  }>;
};

export type ApproveMigratingManagementMutationVariables = Exact<{
  migratingManagementId: Scalars["ID"];
}>;

export type ApproveMigratingManagementMutation = {
  __typename?: "Mutation";
  approveMigratingManagements?: Maybe<{
    __typename?: "BulkActionMigratingManagementsPayload";
    successes: Array<{ __typename?: "MigratingManagement"; id: string }>;
    errors: Array<{
      __typename?: "BulkActionMigratingManagementsErrorPayload";
      id: string;
    }>;
  }>;
};

export type ExcludeMigratingManagementMutationVariables = Exact<{
  migratingManagementId: Scalars["ID"];
  exclusionReason: Scalars["String"];
  exclusionNotes?: Maybe<Scalars["String"]>;
}>;

export type ExcludeMigratingManagementMutation = {
  __typename?: "Mutation";
  excludeMigratingManagement: {
    __typename?: "MigratingManagement";
    id: string;
  };
};

export type MigratingManagementSidebarFragment = {
  __typename?: "MigratingManagement";
  id: string;
  status: MigratingManagementStatus;
  publishStatus?: Maybe<MigratingManagementPublishStatus>;
  statusChangedAt: string;
  exclusionReason?: Maybe<string>;
  exclusionNotes?: Maybe<string>;
  body: Record<string, any>;
  errors?: Maybe<Record<string, any>>;
  hasVacatingTenancy: boolean;
  migrateAsVacant: boolean;
  statusChangedBy?: Maybe<
    | ({
        __typename?: "User";
      } & PropertyOnboardingListActionInitiator_User_Fragment)
    | ({
        __typename?: "System";
      } & PropertyOnboardingListActionInitiator_System_Fragment)
  >;
};

export type GetMigratingManagementSidebarQueryVariables = Exact<{
  migratingManagementId: Scalars["ID"];
}>;

export type GetMigratingManagementSidebarQuery = {
  __typename?: "Query";
  migratingManagement?: Maybe<
    { __typename?: "MigratingManagement" } & MigratingManagementSidebarFragment
  >;
};

export type IncludeMigratingManagementMutationVariables = Exact<{
  migratingManagementId: Scalars["ID"];
}>;

export type IncludeMigratingManagementMutation = {
  __typename?: "Mutation";
  includeMigratingManagements?: Maybe<{
    __typename?: "BulkActionMigratingManagementsPayload";
    successes: Array<{ __typename?: "MigratingManagement"; id: string }>;
    errors: Array<{
      __typename?: "BulkActionMigratingManagementsErrorPayload";
      id: string;
    }>;
  }>;
};

export type PublishMigratingManagementMutationVariables = Exact<{
  migratingManagementId: Scalars["ID"];
}>;

export type PublishMigratingManagementMutation = {
  __typename?: "Mutation";
  publishMigratingManagements?: Maybe<{
    __typename?: "BulkActionMigratingManagementsPayload";
    successes: Array<{
      __typename?: "MigratingManagement";
      id: string;
      body: Record<string, any>;
    }>;
    errors: Array<{
      __typename?: "BulkActionMigratingManagementsErrorPayload";
      id: string;
    }>;
  }>;
};

export type UnapproveMigratingManagementMutationVariables = Exact<{
  migratingManagementId: Scalars["ID"];
}>;

export type UnapproveMigratingManagementMutation = {
  __typename?: "Mutation";
  unapproveMigratingManagements?: Maybe<{
    __typename?: "BulkActionMigratingManagementsPayload";
    successes: Array<{ __typename?: "MigratingManagement"; id: string }>;
    errors: Array<{
      __typename?: "BulkActionMigratingManagementsErrorPayload";
      id: string;
    }>;
  }>;
};

export type UpdateMigratingManagementMutationVariables = Exact<{
  migratingManagementId: Scalars["ID"];
  migrateAsVacant?: Maybe<Scalars["Boolean"]>;
}>;

export type UpdateMigratingManagementMutation = {
  __typename?: "Mutation";
  updateMigratingManagement: {
    __typename?: "MigratingManagement";
    id: string;
    migrateAsVacant: boolean;
    errors?: Maybe<Record<string, any>>;
    healthcheckRunAt?: Maybe<string>;
  };
};

export type GetMigrationDetailsQueryVariables = Exact<{
  organisationId: Scalars["ID"];
}>;

export type GetMigrationDetailsQuery = {
  __typename?: "Query";
  organisation?: Maybe<{
    __typename?: "Organisation";
    id: string;
    hasActiveExternalTrustAccount: boolean;
    migrationDetails?: Maybe<{
      __typename?: "OrganisationMigrationDetails";
      lastRefreshedAt?: Maybe<string>;
      refreshStatus?: Maybe<MigrationDetailsRefreshStatus>;
    }>;
  }>;
};

export type StartMigrationsMutationVariables = Exact<{
  organisationId: Scalars["AiloRN"];
}>;

export type StartMigrationsMutation = {
  __typename?: "Mutation";
  startMigrations?: Maybe<{
    __typename?: "BulkActionMigrationTasksPayload";
    successes: Array<string>;
    errors: Array<{
      __typename?: "BulkActionMigrationTasksErrorPayload";
      legalEntityId: string;
    }>;
  }>;
};

export type GetMigratingManagementPublishStatusQueryVariables = Exact<{
  migratingManagementId: Scalars["ID"];
}>;

export type GetMigratingManagementPublishStatusQuery = {
  __typename?: "Query";
  migratingManagement?: Maybe<{
    __typename?: "MigratingManagement";
    id: string;
    publishStatus?: Maybe<MigratingManagementPublishStatus>;
  }>;
};

export type ApproveMigratingManagementsMutationVariables = Exact<{
  migratingManagementIds: Array<Scalars["ID"]>;
}>;

export type ApproveMigratingManagementsMutation = {
  __typename?: "Mutation";
  approveMigratingManagements?: Maybe<{
    __typename?: "BulkActionMigratingManagementsPayload";
    successes: Array<{ __typename?: "MigratingManagement"; id: string }>;
    errors: Array<{
      __typename?: "BulkActionMigratingManagementsErrorPayload";
      id: string;
    }>;
  }>;
};

export type GetMigratingManagementPortfolioNamesQueryVariables = Exact<{
  organisationId: Scalars["ID"];
  status?: Maybe<MigratingManagementStatus>;
}>;

export type GetMigratingManagementPortfolioNamesQuery = {
  __typename?: "Query";
  organisation?: Maybe<{
    __typename?: "Organisation";
    id: string;
    migratingManagementPortfolioNames?: Maybe<{
      __typename?: "OrganisationPortfolioNamesPayload";
      names: Array<string>;
      hasUnassigned: boolean;
    }>;
  }>;
};

export type IncludeMigratingManagementsMutationVariables = Exact<{
  migratingManagementIds: Array<Scalars["ID"]>;
}>;

export type IncludeMigratingManagementsMutation = {
  __typename?: "Mutation";
  includeMigratingManagements?: Maybe<{
    __typename?: "BulkActionMigratingManagementsPayload";
    successes: Array<{ __typename?: "MigratingManagement"; id: string }>;
    errors: Array<{
      __typename?: "BulkActionMigratingManagementsErrorPayload";
      id: string;
    }>;
  }>;
};

export type UnapproveMigratingManagementsMutationVariables = Exact<{
  migratingManagementIds: Array<Scalars["ID"]>;
}>;

export type UnapproveMigratingManagementsMutation = {
  __typename?: "Mutation";
  unapproveMigratingManagements?: Maybe<{
    __typename?: "BulkActionMigratingManagementsPayload";
    successes: Array<{ __typename?: "MigratingManagement"; id: string }>;
    errors: Array<{
      __typename?: "BulkActionMigratingManagementsErrorPayload";
      id: string;
    }>;
  }>;
};

export type RemoveProjectFilesMutationVariables = Exact<{
  projectFileIds: Array<Scalars["ID"]>;
}>;

export type RemoveProjectFilesMutation = {
  __typename?: "Mutation";
  removeProjectFiles?: Maybe<Array<Maybe<string>>>;
};

export type GetProjectFilesQueryVariables = Exact<{
  id: Scalars["ID"];
  cursor?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
}>;

export type GetProjectFilesQuery = {
  __typename?: "Query";
  project?: Maybe<{
    __typename?: "Project";
    id: string;
    files?: Maybe<{
      __typename?: "PaginatedProjectFiles";
      items: Array<{
        __typename?: "ProjectFile";
        id: string;
        file?: Maybe<{
          __typename?: "File";
          id: string;
          url?: Maybe<string>;
          thumbnailUrl?: Maybe<string>;
          thumbnailStatus: ThumbnailStatus;
          contentType?: Maybe<string>;
          fileName: string;
          fileSize?: Maybe<number>;
        }>;
      }>;
      pageInfo: {
        __typename?: "PageInfo";
        total: number;
        hasMore: boolean;
        nextCursor?: Maybe<string>;
      };
    }>;
  }>;
};

export type ArchiveProjectMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ArchiveProjectMutation = {
  __typename?: "Mutation";
  archiveProject?: Maybe<string>;
};

export type CloseProjectMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CloseProjectMutation = {
  __typename?: "Mutation";
  closeProject?: Maybe<
    { __typename?: "Project" } & ProjectFragment & ProjectActionsFragment
  >;
};

export type ReopenProjectMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ReopenProjectMutation = {
  __typename?: "Mutation";
  reopenProject?: Maybe<
    { __typename?: "Project" } & ProjectFragment & ProjectActionsFragment
  >;
};

export type GetProjectActionsCountQueryVariables = Exact<{
  projectId: Scalars["ID"];
}>;

export type GetProjectActionsCountQuery = {
  __typename?: "Query";
  project?: Maybe<{
    __typename?: "Project";
    id: string;
    actions?: Maybe<{
      __typename?: "PaginatedActions";
      pageInfo: { __typename?: "PageInfo"; total: number };
    }>;
  }>;
};

export type GetFormDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetFormDetailsQuery = {
  __typename?: "Query";
  form:
    | { __typename?: "AiloForm"; id: string }
    | {
        __typename?: "ReiForm";
        id: string;
        reiFormInfo: {
          __typename?: "ReiFormInfo";
          name: string;
          finalised: boolean;
        };
      };
};

export type GetFormUrlsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetFormUrlsQuery = {
  __typename?: "Query";
  form:
    | { __typename?: "AiloForm"; id: string }
    | {
        __typename?: "ReiForm";
        viewUrl: string;
        downloadUrl: string;
        id: string;
      };
};

export type CompleteAssignFormActionMutationVariables = Exact<{
  input: CompleteAssignFormActionInput;
}>;

export type CompleteAssignFormActionMutation = {
  __typename?: "Mutation";
  completeAssignFormAction?: Maybe<{
    __typename?: "CompleteAssignFormActionPayload";
    action?: Maybe<{ __typename?: "Action" } & ActionDetailsFragment>;
  }>;
};

export type CreateReiFormFromUserTemplateMutationVariables = Exact<{
  input: CreateReiFormFromUserTemplateInput;
}>;

export type CreateReiFormFromUserTemplateMutation = {
  __typename?: "Mutation";
  createReiFormFromUserTemplate: {
    __typename?: "ReiForm";
    id: string;
    ailorn: string;
  };
};

export type GetFormGenerationDataQueryVariables = Exact<{
  personAilorn: Scalars["AiloRN"];
  organisationAilorn: Scalars["AiloRN"];
  projectId: Scalars["ID"];
}>;

export type GetFormGenerationDataQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | { __typename?: "Company"; id: string }
    | {
        __typename?: "Person";
        id: string;
        reiTemplatesForOrg: Array<{
          __typename?: "ReiTemplate";
          id: number;
          name: string;
          code: string;
        }>;
      }
  >;
  project?: Maybe<{ __typename?: "Project"; id: string } & ProjectMetaFragment>;
};

export type GetManagementDetailsForActionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetManagementDetailsForActionQuery = {
  __typename?: "Query";
  management?: Maybe<
    {
      __typename?: "Management";
      id: string;
      managementAgreements?: Maybe<{
        __typename?: "PaginatedManagementAgreements";
        items: Array<{
          __typename?: "ManagementAgreement";
          id: string;
          startDate?: Maybe<string>;
          fixedTermEndDate?: Maybe<string>;
          managementFeeSchedules?: Maybe<{
            __typename?: "PaginatedManagementFeeSchedules";
            items: Array<
              Maybe<{
                __typename?: "ManagementFeeSchedule";
                id: string;
                percent: number;
              }>
            >;
          }>;
        }>;
      }>;
      ownerships?: Maybe<
        { __typename?: "PaginatedOwnerships" } & OwnershipItemsFragment
      >;
    } & ManagementFragment &
      ManagementPropertyFragment
  >;
};

export type OwnershipItemsFragment = {
  __typename?: "PaginatedOwnerships";
  items: Array<{ __typename?: "Ownership" } & OwnerWithSharesFragment>;
};

export type OwnerWithSharesFragment = {
  __typename?: "Ownership";
  sharesOwned: number;
  totalShares: number;
} & OwnerFragment;

export type CompleteAssignNewManagementActionMutationVariables = Exact<{
  input: CompleteAssignNewManagementActionInput;
}>;

export type CompleteAssignNewManagementActionMutation = {
  __typename?: "Mutation";
  completeAssignNewManagementAction?: Maybe<{
    __typename?: "CompleteAssignNewManagementActionPayload";
    action?: Maybe<{ __typename?: "Action" } & ActionDetailsFragment>;
  }>;
};

export type CompleteAssignRentActionMutationVariables = Exact<{
  id: Scalars["ID"];
  input: CompleteAssignRentActionInput;
}>;

export type CompleteAssignRentActionMutation = {
  __typename?: "Mutation";
  completeAssignRentAction?: Maybe<{
    __typename?: "CompleteAssignRentActionPayload";
    action?: Maybe<{ __typename?: "Action" } & ActionDetailsFragment>;
  }>;
};

export type GetTenancyDetailsForActionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetTenancyDetailsForActionQuery = {
  __typename?: "Query";
  tenancy?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      scheduledRentReviewDate?: Maybe<string>;
      rents: {
        __typename?: "PaginatedRents";
        pageInfo: { __typename?: "PageInfo"; total: number };
        items: Array<{
          __typename?: "Rent";
          period: RentFrequency;
          effectiveDate: string;
          amountInCents: string;
        }>;
      };
      tenancyAgreements?: Maybe<{
        __typename?: "PaginatedTenancyAgreements";
        items: Array<
          Maybe<{
            __typename?: "TenancyAgreement";
            id: string;
            startDate?: Maybe<string>;
            fixedTermEndDate?: Maybe<string>;
          }>
        >;
      }>;
      deposit?: Maybe<{
        __typename?: "TenancyDeposit";
        amount: { __typename?: "Money"; cents: number };
      }>;
      tenantships?: Maybe<
        { __typename?: "PaginatedTenantships" } & TenantShipItemsFragment
      >;
    } & TenancyFragment &
      TenancyIsVoidableFragment
  >;
};

export type TenantShipItemsFragment = {
  __typename?: "PaginatedTenantships";
  items: Array<{ __typename?: "Tenantship" } & TenantFragment>;
};

export type TenantFragment = {
  __typename?: "Tenantship";
  tenant?: Maybe<
    | { __typename: "Company"; id: string }
    | {
        __typename: "Person";
        legalFirstName: string;
        lastName?: Maybe<string>;
        preferredName?: Maybe<string>;
        legalMiddleName?: Maybe<string>;
        phoneNo?: Maybe<string>;
        emailAddress?: Maybe<string>;
        birthDate?: Maybe<string>;
        id: string;
        photo?: Maybe<{
          __typename?: "File";
          url?: Maybe<string>;
          thumbnailUrl?: Maybe<string>;
        }>;
      }
  >;
};

export type CompleteAssignTenancyActionMutationVariables = Exact<{
  input: CompleteAssignTenancyActionInput;
}>;

export type CompleteAssignTenancyActionMutation = {
  __typename?: "Mutation";
  completeAssignTenancyAction?: Maybe<{
    __typename?: "CompleteAssignTenancyActionPayload";
    action?: Maybe<{ __typename?: "Action" } & ActionDetailsFragment>;
  }>;
};

export type CreateInspectionAppointmentsMutationVariables = Exact<{
  agencyAilorn: Scalars["AiloRN"];
  input: Array<CreateInspectionAppointmentInput>;
}>;

export type CreateInspectionAppointmentsMutation = {
  __typename?: "Mutation";
  createInspectionAppointments: {
    __typename?: "CreateInspectionAppointmentsResult";
    inspectionAppointments: Array<
      {
        __typename?: "InspectionAppointment";
      } & InspectionAppointmentDetailsFragment
    >;
  };
};

export type GetManagementDetailsForAssignTenancyActionQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetManagementDetailsForAssignTenancyActionQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    endDate?: Maybe<string>;
    currentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      endDate?: Maybe<string>;
    }>;
    nextTenancy?: Maybe<{ __typename?: "Tenancy"; id: string }>;
  }>;
};

export type InspectionAppointmentDetailsFragment = {
  __typename?: "InspectionAppointment";
  id: string;
  ailorn: string;
};

export type GetReiFormRemoteSigningStatusQueryVariables = Exact<{
  formId: Scalars["ID"];
}>;

export type GetReiFormRemoteSigningStatusQuery = {
  __typename?: "Query";
  form:
    | { __typename?: "AiloForm"; id: string }
    | {
        __typename?: "ReiForm";
        id: string;
        remoteSigningStatus: {
          __typename?: "ReiFormRemoteSigningStatus";
        } & ReiFormRemoteSigningStatusFragment;
      };
};

export type ReiFormRemoteSigningStatusFragment = {
  __typename?: "ReiFormRemoteSigningStatus";
  status: SigningStatus;
  sent?: Maybe<string>;
  recipients: Array<
    { __typename?: "ReiFormRemoteSigner" } & ReiFormRemoteSignerFragment
  >;
};

export type ReiFormRemoteSignerFragment = {
  __typename?: "ReiFormRemoteSigner";
  name: string;
  status: ReiFormRemoteSignerStatus;
  signed?: Maybe<string>;
};

export type GetActionDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetActionDetailsQuery = {
  __typename?: "Query";
  action?: Maybe<{ __typename?: "Action" } & ActionDetailsFragment>;
};

export type ReassignActionMutationVariables = Exact<{
  id: Scalars["ID"];
  assigneeAilorn: Scalars["AiloRN"];
}>;

export type ReassignActionMutation = {
  __typename?: "Mutation";
  reassignAction?: Maybe<{ __typename?: "Action" } & ActionDetailsFragment>;
};

export type UpdateActionDueDateMutationVariables = Exact<{
  id: Scalars["ID"];
  dueDate: Scalars["DateTime"];
}>;

export type UpdateActionDueDateMutation = {
  __typename?: "Mutation";
  updateActionDueDate?: Maybe<
    { __typename?: "Action" } & ActionDetailsFragment
  >;
};

export type GetAddExpenseScreenDetailsQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetAddExpenseScreenDetailsQuery = {
  __typename?: "Query";
  management?: Maybe<
    {
      __typename?: "Management";
      id: string;
      ailoRN: string;
      currentOrNextManagementAgreement?: Maybe<{
        __typename?: "ManagementAgreement";
        id: string;
      }>;
      managingEntity?: Maybe<{
        __typename?: "Company";
        id: string;
        ailoRN: string;
      }>;
      owners?: Maybe<
        Array<
          Maybe<{
            __typename?: "Ownership";
            owner?: Maybe<
              | ({ __typename?: "Company" } & LegalEntityName_Company_Fragment)
              | ({ __typename?: "Person" } & LegalEntityName_Person_Fragment)
            >;
          }>
        >
      >;
      nextTenancy?: Maybe<{
        __typename?: "Tenancy";
        id: string;
        ailorn: string;
        rents: {
          __typename?: "PaginatedRents";
          items: Array<{
            __typename?: "Rent";
            dailyRate: number;
            ailorn: string;
          }>;
        };
      }>;
      mostRecentTenancy?: Maybe<{
        __typename?: "Tenancy";
        id: string;
        endDate?: Maybe<string>;
        ailorn: string;
        currentRent?: Maybe<{
          __typename?: "Rent";
          id: string;
          dailyRate: number;
        }>;
        rents: {
          __typename?: "PaginatedRents";
          items: Array<{
            __typename?: "Rent";
            dailyRate: number;
            ailorn: string;
          }>;
        };
        tenantships?: Maybe<{
          __typename?: "PaginatedTenantships";
          items: Array<{
            __typename?: "Tenantship";
            tenant?: Maybe<
              | ({ __typename?: "Company" } & LegalEntityName_Company_Fragment)
              | ({ __typename?: "Person" } & LegalEntityName_Person_Fragment)
            >;
          }>;
        }>;
      }>;
    } & ManagementPropertyFragment
  >;
};

export type GetOccupancyStatusAndTenancyDetailsQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetOccupancyStatusAndTenancyDetailsQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    occupancyStatus?: Maybe<OccupancyStatus>;
    startDate?: Maybe<string>;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      endDate?: Maybe<string>;
      mostRecentTenancyAgreement?: Maybe<{
        __typename?: "TenancyAgreement";
        id: string;
        fixedTermEndDate?: Maybe<string>;
      }>;
    }>;
    nextTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      startDate?: Maybe<string>;
    }>;
  }>;
};

export type GetAddressAndFirstPaymentDateQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetAddressAndFirstPaymentDateQuery = {
  __typename?: "Query";
  management?: Maybe<
    {
      __typename?: "Management";
      id: string;
      mostRecentTenancy?: Maybe<{
        __typename?: "Tenancy";
        id: string;
        liability?: Maybe<{
          __typename?: "Liability";
          reference: string;
          firstPaymentDate?: Maybe<string>;
        }>;
      }>;
    } & ManagementPropertyFragment
  >;
};

export type GetAgreementOfMostRecentTenancyQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetAgreementOfMostRecentTenancyQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      latestTenancyAgreement?: Maybe<
        { __typename?: "TenancyAgreement" } & TenancyAgreementFragment
      >;
    }>;
  }>;
};

export type GetArrearsAmountQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetArrearsAmountQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      liability?: Maybe<{
        __typename?: "Liability";
        reference: string;
        amountInArrears: { __typename?: "Money"; cents: number };
      }>;
    }>;
  }>;
};

export type GetArrearsAmountFromOwingQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetArrearsAmountFromOwingQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      rent?: Maybe<{
        __typename?: "RecurringOwing";
        id: string;
        progress: {
          __typename?: "OwingProgress";
          arrears: {
            __typename?: "Arrears";
            amount: {
              __typename?: "TaxableAmount";
              total: { __typename?: "Money"; cents: number };
            };
          };
        };
      }>;
    }>;
  }>;
};

export type GetBalanceOwedQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetBalanceOwedQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      liability?: Maybe<{
        __typename?: "Liability";
        reference: string;
        overdueAmount: { __typename?: "Money"; cents: number };
      }>;
    }>;
  }>;
};

export type GetBalanceOwedFromOwingQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetBalanceOwedFromOwingQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      rent?: Maybe<{
        __typename?: "RecurringOwing";
        id: string;
        progress: {
          __typename?: "OwingProgress";
          overdue: {
            __typename?: "CumulativeCharge";
            amount: {
              __typename?: "TaxableAmount";
              total: { __typename?: "Money"; cents: number };
            };
          };
        };
      }>;
    }>;
  }>;
};

export type GetDaysInArrearsQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetDaysInArrearsQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      liability?: Maybe<{
        __typename?: "Liability";
        reference: string;
        daysInArrears: number;
      }>;
    }>;
  }>;
};

export type GetDaysInArrearsFromOwingQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetDaysInArrearsFromOwingQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      rent?: Maybe<{
        __typename?: "RecurringOwing";
        id: string;
        progress: {
          __typename?: "OwingProgress";
          arrears: { __typename?: "Arrears"; date: string };
        };
      }>;
    }>;
  }>;
};

export type GetEndDateQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetEndDateQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    endDate?: Maybe<string>;
  }>;
};

export type GetEndReasonQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetEndReasonQuery = {
  __typename?: "Query";
  management?: Maybe<
    { __typename?: "Management"; id: string } & ManagementEndDetailsFragment
  >;
};

export type GetManagementRentQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetManagementRentQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<
      { __typename?: "Tenancy"; id: string } & DisplayRentFragment
    >;
  }>;
};

export type GetManagementTeamQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetManagementTeamQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    team?: Maybe<{ __typename?: "Team"; id: string; name: string }>;
  }>;
};

export type GetOccupancyStatusQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetOccupancyStatusQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    occupancyStatus?: Maybe<OccupancyStatus>;
    nextTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      startDate?: Maybe<string>;
    }>;
  }>;
};

export type GetOverdueDateQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetOverdueDateQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      liability?: Maybe<{
        __typename?: "Liability";
        reference: string;
        dueDate?: Maybe<string>;
        overdueAmount: { __typename?: "Money"; cents: number };
      }>;
    }>;
  }>;
};

export type GetOverdueDateFromOwingQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetOverdueDateFromOwingQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      rent?: Maybe<{
        __typename?: "RecurringOwing";
        id: string;
        progress: {
          __typename?: "OwingProgress";
          overdue: { __typename?: "CumulativeCharge"; date: string };
        };
      }>;
    }>;
  }>;
};

export type GetPaidToDateQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetPaidToDateQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      endDate?: Maybe<string>;
      liability?: Maybe<{
        __typename?: "Liability";
        reference: string;
        effectivePaidToDate?: Maybe<string>;
      }>;
    }>;
  }>;
};

export type GetPaidToDateFromOwingQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetPaidToDateFromOwingQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      endDate?: Maybe<string>;
      rent?: Maybe<{
        __typename?: "RecurringOwing";
        id: string;
        progress: {
          __typename?: "OwingProgress";
          paidTo: {
            __typename?: "PaidTo";
            effective: { __typename?: "PaidToDate"; date: string };
          };
        };
      }>;
    }>;
  }>;
};

export type GetScheduledRentReviewDateQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetScheduledRentReviewDateQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      scheduledRentReviewDate?: Maybe<string>;
    }>;
  }>;
};

export type GetPagedActiveManagementsQueryVariables = Exact<{
  organisationId: Scalars["AiloRN"];
  pageCursor: ManagementCursor;
  filters: Array<ManagementFilterParams>;
}>;

export type GetPagedActiveManagementsQuery = {
  __typename?: "Query";
  managementsForOrg?: Maybe<{
    __typename?: "BidirectionalPaginatedManagements";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
    };
    items: Array<
      {
        __typename?: "Management";
        id: string;
        occupancyStatus?: Maybe<OccupancyStatus>;
        startDate?: Maybe<string>;
        mostRecentTenancy?: Maybe<{
          __typename?: "Tenancy";
          id: string;
          endDate?: Maybe<string>;
          mostRecentTenancyAgreement?: Maybe<
            { __typename?: "TenancyAgreement" } & TenancyAgreementFragment
          >;
        }>;
        nextTenancy?: Maybe<{
          __typename?: "Tenancy";
          id: string;
          startDate?: Maybe<string>;
        }>;
        team?: Maybe<{ __typename?: "Team"; id: string; name: string }>;
      } & ManagementPropertyFragment
    >;
  }>;
};

export type GetPagedLostManagementsQueryVariables = Exact<{
  organisationId: Scalars["AiloRN"];
  pageCursor: ManagementCursor;
  filters: Array<ManagementFilterParams>;
}>;

export type GetPagedLostManagementsQuery = {
  __typename?: "Query";
  managementsForOrg?: Maybe<{
    __typename?: "BidirectionalPaginatedManagements";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
    };
    items: Array<
      { __typename?: "Management"; id: string } & ManagementEndDetailsFragment &
        ManagementPropertyFragment
    >;
  }>;
};

export type GetPagedManagementsDueForRenewalsQueryVariables = Exact<{
  organisationId: Scalars["AiloRN"];
  pageCursor: ManagementCursor;
  filters: Array<ManagementFilterParams>;
}>;

export type GetPagedManagementsDueForRenewalsQuery = {
  __typename?: "Query";
  managementsForOrg?: Maybe<{
    __typename?: "BidirectionalPaginatedManagements";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
    };
    items: Array<
      {
        __typename?: "Management";
        id: string;
        occupancyStatus?: Maybe<OccupancyStatus>;
        mostRecentTenancy?: Maybe<
          {
            __typename?: "Tenancy";
            id: string;
            scheduledRentReviewDate?: Maybe<string>;
            latestTenancyAgreement?: Maybe<
              { __typename?: "TenancyAgreement" } & TenancyAgreementFragment
            >;
          } & DisplayRentFragment
        >;
        nextTenancy?: Maybe<{
          __typename?: "Tenancy";
          id: string;
          startDate?: Maybe<string>;
        }>;
      } & ManagementPropertyFragment
    >;
  }>;
};

export type GetPagedManagementsWithTenancyInArrearsQueryVariables = Exact<{
  organisationId: Scalars["AiloRN"];
  pageCursor: ManagementCursor;
  filters: Array<ManagementFilterParams>;
}>;

export type GetPagedManagementsWithTenancyInArrearsQuery = {
  __typename?: "Query";
  managementsForOrg?: Maybe<{
    __typename?: "BidirectionalPaginatedManagements";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
    };
    items: Array<
      {
        __typename?: "Management";
        id: string;
        mostRecentTenancy?: Maybe<
          { __typename?: "Tenancy" } & TenancyInArrearsFragment
        >;
      } & ManagementPropertyFragment
    >;
  }>;
};

export type GetPagedManagementsWithTenancyInArrearsFromOwingQueryVariables =
  Exact<{
    organisationId: Scalars["AiloRN"];
    pageCursor: ManagementCursor;
    filters: Array<ManagementFilterParams>;
  }>;

export type GetPagedManagementsWithTenancyInArrearsFromOwingQuery = {
  __typename?: "Query";
  managementsForOrg?: Maybe<{
    __typename?: "BidirectionalPaginatedManagements";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
    };
    items: Array<
      {
        __typename?: "Management";
        id: string;
        mostRecentTenancy?: Maybe<
          { __typename?: "Tenancy" } & TenancyInArrearsFromOwingFragment
        >;
      } & ManagementPropertyFragment
    >;
  }>;
};

export type TenancyInArrearsFromOwingFragment = {
  __typename?: "Tenancy";
  id: string;
  rent?: Maybe<{
    __typename?: "RecurringOwing";
    id: string;
    progress: {
      __typename?: "OwingProgress";
      firstPaidAt?: Maybe<string>;
      arrears: {
        __typename?: "Arrears";
        date: string;
        amount: {
          __typename?: "TaxableAmount";
          total: { __typename?: "Money"; cents: number };
        };
      };
      paidTo: {
        __typename?: "PaidTo";
        effective: { __typename?: "PaidToDate"; date: string };
      };
    };
  }>;
} & DisplayRentFragment &
  TenancyPropertyFragment;

export type GetPagedOverdueManagementsQueryVariables = Exact<{
  organisationId: Scalars["AiloRN"];
  pageCursor: ManagementCursor;
  filters: Array<ManagementFilterParams>;
}>;

export type GetPagedOverdueManagementsQuery = {
  __typename?: "Query";
  managementsForOrg?: Maybe<{
    __typename?: "BidirectionalPaginatedManagements";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
    };
    items: Array<
      {
        __typename?: "Management";
        id: string;
        mostRecentTenancy?: Maybe<
          {
            __typename?: "Tenancy";
            id: string;
            endDate?: Maybe<string>;
            liability?: Maybe<{
              __typename?: "Liability";
              reference: string;
              effectivePaidToDate?: Maybe<string>;
              dueDate?: Maybe<string>;
              overdueAmount: { __typename?: "Money"; cents: number };
            }>;
          } & DisplayRentFragment
        >;
      } & ManagementPropertyFragment
    >;
  }>;
};

export type GetPagedOverdueManagementsFromOwingQueryVariables = Exact<{
  organisationId: Scalars["AiloRN"];
  pageCursor: ManagementCursor;
  filters: Array<ManagementFilterParams>;
}>;

export type GetPagedOverdueManagementsFromOwingQuery = {
  __typename?: "Query";
  managementsForOrg?: Maybe<{
    __typename?: "BidirectionalPaginatedManagements";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      hasPrevious: boolean;
      previousCursor?: Maybe<string>;
    };
    items: Array<
      {
        __typename?: "Management";
        id: string;
        mostRecentTenancy?: Maybe<
          {
            __typename?: "Tenancy";
            id: string;
            endDate?: Maybe<string>;
            rent?: Maybe<{
              __typename?: "RecurringOwing";
              id: string;
              progress: {
                __typename?: "OwingProgress";
                overdue: {
                  __typename?: "CumulativeCharge";
                  date: string;
                  amount: {
                    __typename?: "TaxableAmount";
                    total: { __typename?: "Money"; cents: number };
                  };
                };
                paidTo: {
                  __typename?: "PaidTo";
                  effective: { __typename?: "PaidToDate"; date: string };
                };
              };
            }>;
          } & DisplayRentFragment
        >;
      } & ManagementPropertyFragment
    >;
  }>;
};

export type TenancyInArrearsFragment = {
  __typename?: "Tenancy";
  id: string;
  liability?: Maybe<{
    __typename?: "Liability";
    reference: string;
    effectivePaidToDate?: Maybe<string>;
    firstPaymentDate?: Maybe<string>;
    daysInArrears: number;
    amountInArrears: { __typename?: "Money"; cents: number };
  }>;
} & DisplayRentFragment &
  TenancyPropertyFragment;

export type GetManagementsForOrgCountQueryVariables = Exact<{
  organisationId: Scalars["AiloRN"];
  pageCursor: ManagementCursor;
  filters: Array<ManagementFilterParams>;
}>;

export type GetManagementsForOrgCountQuery = {
  __typename?: "Query";
  managementsForOrg?: Maybe<{
    __typename?: "BidirectionalPaginatedManagements";
    pageInfo: { __typename?: "BidirectionalPageInfo"; total: number };
  }>;
};

export type CreateTenancyBondMutationVariables = Exact<{
  bondDetails: CreateBondInput;
}>;

export type CreateTenancyBondMutation = {
  __typename?: "Mutation";
  createTenancyBond?: Maybe<{
    __typename?: "TenancyBond";
    id: string;
    reference?: Maybe<string>;
    amount: { __typename?: "Money"; cents: number };
  }>;
};

export type UpdateTenancyBondMutationVariables = Exact<{
  bondDetails: UpdateBondInput;
}>;

export type UpdateTenancyBondMutation = {
  __typename?: "Mutation";
  updateTenancyBond?: Maybe<{
    __typename?: "TenancyBond";
    id: string;
    reference?: Maybe<string>;
    amount: { __typename?: "Money"; cents: number };
  }>;
};

export type GetAgencyTrustAccountsQueryVariables = Exact<{
  agency: Scalars["AiloRN"];
}>;

export type GetAgencyTrustAccountsQuery = {
  __typename?: "Query";
  getTrustAccounts: Array<{
    __typename?: "TrustBankAccount";
    agency: string;
    paymentMethod: {
      __typename?: "PaymentMethodCompanion";
      id: string;
      paymentMethod:
        | {
            __typename?: "BankAccount";
            accountNumber?: Maybe<string>;
            bsb?: Maybe<string>;
            id: string;
          }
        | { __typename?: "BPay"; id: string }
        | { __typename?: "CreditCard"; id: string }
        | { __typename?: "VirtualAccount"; id: string };
    };
  }>;
};

export type GetTenantsAndPropertyDetailsQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetTenantsAndPropertyDetailsQuery = {
  __typename?: "Query";
  tenancy?: Maybe<
    {
      __typename?: "Tenancy";
      management?: Maybe<{
        __typename?: "Management";
        id: string;
        managingEntity?: Maybe<{
          __typename?: "Company";
          id: string;
          tenancyBondVirtualAccounts: Array<{
            __typename?: "TenancyBondAccount";
            id: string;
            paymentMethodCompanion?: Maybe<{
              __typename?: "PaymentMethodCompanion";
              id: string;
              paymentMethod:
                | { __typename?: "BankAccount"; id: string }
                | { __typename?: "BPay"; id: string }
                | { __typename?: "CreditCard"; id: string }
                | {
                    __typename?: "VirtualAccount";
                    accountNumber?: Maybe<string>;
                    bsb?: Maybe<string>;
                    id: string;
                  };
            }>;
          }>;
        }>;
      }>;
      tenantships?: Maybe<{
        __typename?: "PaginatedTenantships";
        items: Array<{
          __typename?: "Tenantship";
          tenant?: Maybe<
            | { __typename?: "Company" }
            | {
                __typename?: "Person";
                firstName: string;
                lastName?: Maybe<string>;
              }
          >;
        }>;
      }>;
      bond?: Maybe<{
        __typename?: "TenancyBond";
        id: string;
        reference?: Maybe<string>;
        status: TenancyBondStatus;
        amount: { __typename?: "Money"; cents: number };
      }>;
    } & TenancyPropertyFragment
  >;
};

export type GetAmountDueAtDateQueryVariables = Exact<{
  liabilityId: Scalars["ID"];
  dateTime: Scalars["DateTime"];
}>;

export type GetAmountDueAtDateQuery = {
  __typename?: "Query";
  amountDueToDate?: Maybe<{ __typename?: "Money"; cents: number }>;
};

export type GetDataForEditEndTenancySidebarQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetDataForEditEndTenancySidebarQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    endDate?: Maybe<string>;
    vacatingReason?: Maybe<VacatingReason>;
    vacatingNotes?: Maybe<string>;
    deposit?: Maybe<{
      __typename?: "TenancyDeposit";
      id: string;
      status: TenancyDepositStatus;
    }>;
    liability?: Maybe<{
      __typename?: "Liability";
      id: string;
      reference: string;
      effectivePaidToDate?: Maybe<string>;
    }>;
    validVacateEndDateRange?: Maybe<{
      __typename?: "DateRange";
      startDate?: Maybe<string>;
      endDate?: Maybe<string>;
    }>;
    mostRecentTenancyAgreement?: Maybe<{
      __typename?: "TenancyAgreement";
      id: string;
      startDate?: Maybe<string>;
      fixedTermEndDate?: Maybe<string>;
      allowedToLapseAt?: Maybe<string>;
    }>;
  }>;
};

export type GetNextTenancyStartDateQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetNextTenancyStartDateQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    nextTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      startDate?: Maybe<string>;
    }>;
  }>;
};

export type UpdateEndOfTenancyMutationVariables = Exact<{
  id: Scalars["ID"];
  endDate?: Maybe<Scalars["Date"]>;
  reason?: Maybe<VacatingReason>;
  notes?: Maybe<Scalars["String"]>;
}>;

export type UpdateEndOfTenancyMutation = {
  __typename?: "Mutation";
  updateEndOfTenancy?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      endDate?: Maybe<string>;
      management?: Maybe<
        {
          __typename?: "Management";
          id: string;
          occupancyStatus?: Maybe<OccupancyStatus>;
        } & ManagementEndDetailsFragment &
          ManagementFormerAndVoidedTenanciesFragment
      >;
    } & CanCancelVacateFragment
  >;
};

export type GetDefaultWalletWithMoneyManagementSettingsForManagementQueryVariables =
  Exact<{
    walletOwnerReference: Scalars["AiloRN"];
  }>;

export type GetDefaultWalletWithMoneyManagementSettingsForManagementQuery = {
  __typename?: "Query";
  walletByWalletOwnerReference?: Maybe<{
    __typename?: "Wallet";
    id: string;
    name: string;
    availableBalance: { __typename?: "Money"; cents: number };
    totalBalance: { __typename?: "Money"; cents: number };
    autoWithdrawPlans?: Maybe<{
      __typename?: "PaginatedAutoWithdrawPlans";
      items?: Maybe<
        Array<{
          __typename?: "AutoWithdrawPlan";
          id: string;
          enabled: boolean;
          details: {
            __typename?: "AutoWithdrawPlanDetails";
            frequency: QuartzPlanFrequency;
            nextFireTime: string;
          };
        }>
      >;
    }>;
  }>;
  autoPayLiabilityStatuses?: Maybe<
    Array<
      Maybe<{
        __typename?: "AutoPayLiabilityStatus";
        payerId: string;
        enabled: boolean;
      }>
    >
  >;
};

export type GetTenancyByManagementQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetTenancyByManagementQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      endDate?: Maybe<string>;
    }>;
    nextTenancy?: Maybe<{ __typename?: "Tenancy"; id: string }>;
  }>;
};

export type GetLegalEntityDetailsQueryVariables = Exact<{
  ailoRN: Scalars["AiloRN"];
  organisationAiloRN: Scalars["AiloRN"];
}>;

export type GetLegalEntityDetailsQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | {
        __typename: "Company";
        registeredEntityName: string;
        registeredEntityId?: Maybe<string>;
        id: string;
        organisation: {
          __typename?: "Organisation";
          members?: Maybe<{
            __typename?: "PaginatedPeople";
            items: Array<
              {
                __typename: "Person";
                phoneNo?: Maybe<string>;
                emailAddress?: Maybe<string>;
                photo?: Maybe<{
                  __typename?: "File";
                  id: string;
                  url?: Maybe<string>;
                  thumbnailUrl?: Maybe<string>;
                }>;
                user?: Maybe<{
                  __typename?: "User";
                  id: number;
                  onboardingCompletedAt?: Maybe<string>;
                }>;
              } & PersonNamesFragment
            >;
          }>;
        };
        contact?: Maybe<{ __typename?: "Contact"; ailorn: string }>;
        managements?: Maybe<{
          __typename?: "PaginatedManagements";
          items: Array<
            {
              __typename?: "Management";
              id: string;
            } & ManagementPropertyFragment
          >;
        }>;
        ownerships?: Maybe<{
          __typename?: "PaginatedOwnerships";
          items: Array<{
            __typename?: "Ownership";
            sharesOwned: number;
            totalShares: number;
            management: {
              __typename?: "Management";
              id: string;
            } & ManagementPropertyFragment;
          }>;
        }>;
        tenancies?: Maybe<{
          __typename?: "PaginatedTenancies";
          items: Array<{
            __typename?: "Tenancy";
            id: string;
            management?: Maybe<
              {
                __typename?: "Management";
                id: string;
              } & ManagementPropertyFragment
            >;
            liability?: Maybe<{
              __typename?: "Liability";
              id: string;
              autoPaymentDetails?: Maybe<{
                __typename?: "AutoPaymentDetails";
                paymentMethod?: Maybe<
                  | ({
                      __typename?: "BankAccount";
                    } & PaymentMethodDetails_BankAccount_Fragment)
                  | ({
                      __typename?: "BPay";
                    } & PaymentMethodDetails_BPay_Fragment)
                  | ({
                      __typename?: "CreditCard";
                    } & PaymentMethodDetails_CreditCard_Fragment)
                  | ({
                      __typename?: "VirtualAccount";
                    } & PaymentMethodDetails_VirtualAccount_Fragment)
                >;
              }>;
            }>;
          }>;
        }>;
      }
    | {
        __typename: "Person";
        firstName: string;
        lastName?: Maybe<string>;
        phoneNo?: Maybe<string>;
        emailAddress?: Maybe<string>;
        id: string;
        photo?: Maybe<{
          __typename?: "File";
          id: string;
          url?: Maybe<string>;
          thumbnailUrl?: Maybe<string>;
        }>;
        user?: Maybe<{
          __typename?: "User";
          id: number;
          onboardingCompletedAt?: Maybe<string>;
        }>;
        contact?: Maybe<{ __typename?: "Contact"; ailorn: string }>;
        managements?: Maybe<{
          __typename?: "PaginatedManagements";
          items: Array<
            {
              __typename?: "Management";
              id: string;
            } & ManagementPropertyFragment
          >;
        }>;
        ownerships?: Maybe<{
          __typename?: "PaginatedOwnerships";
          items: Array<{
            __typename?: "Ownership";
            sharesOwned: number;
            totalShares: number;
            management: {
              __typename?: "Management";
              id: string;
            } & ManagementPropertyFragment;
          }>;
        }>;
        tenancies?: Maybe<{
          __typename?: "PaginatedTenancies";
          items: Array<{
            __typename?: "Tenancy";
            id: string;
            management?: Maybe<
              {
                __typename?: "Management";
                id: string;
              } & ManagementPropertyFragment
            >;
            liability?: Maybe<{
              __typename?: "Liability";
              id: string;
              autoPaymentDetails?: Maybe<{
                __typename?: "AutoPaymentDetails";
                paymentMethod?: Maybe<
                  | ({
                      __typename?: "BankAccount";
                    } & PaymentMethodDetails_BankAccount_Fragment)
                  | ({
                      __typename?: "BPay";
                    } & PaymentMethodDetails_BPay_Fragment)
                  | ({
                      __typename?: "CreditCard";
                    } & PaymentMethodDetails_CreditCard_Fragment)
                  | ({
                      __typename?: "VirtualAccount";
                    } & PaymentMethodDetails_VirtualAccount_Fragment)
                >;
              }>;
            }>;
          }>;
        }>;
      }
  >;
  liabilityPaymentPlans?: Maybe<{
    __typename?: "PaginatedLiabilityPaymentPlans";
    items?: Maybe<
      Array<{
        __typename?: "LiabilityPaymentPlan";
        id: string;
        enabled: boolean;
        liabilityId: string;
        owner: string;
        details: {
          __typename?: "LiabilityPaymentPlanDetails";
          frequency: QuartzPlanFrequency;
          anniversary: number;
          startDate: string;
          endDate?: Maybe<string>;
          amount: { __typename?: "Money"; cents: number };
          paymentMethod:
            | ({
                __typename?: "BankAccount";
              } & PaymentMethodDetails_BankAccount_Fragment)
            | ({ __typename?: "BPay" } & PaymentMethodDetails_BPay_Fragment)
            | ({
                __typename?: "CreditCard";
              } & PaymentMethodDetails_CreditCard_Fragment)
            | ({
                __typename?: "VirtualAccount";
              } & PaymentMethodDetails_VirtualAccount_Fragment);
        };
      }>
    >;
  }>;
  autoWithdrawPlans?: Maybe<{
    __typename?: "PaginatedAutoWithdrawPlans";
    items?: Maybe<
      Array<{
        __typename?: "AutoWithdrawPlan";
        id: string;
        details: {
          __typename?: "AutoWithdrawPlanDetails";
          frequency: QuartzPlanFrequency;
          anniversary?: Maybe<number>;
          startDate: string;
          endDate?: Maybe<string>;
          setAsideAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
        };
      }>
    >;
  }>;
  autoPayLiabilityStatuses?: Maybe<
    Array<
      Maybe<{
        __typename?: "AutoPayLiabilityStatus";
        enabled: boolean;
        maximumPaymentAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
        paymentMethod?: Maybe<
          | ({
              __typename?: "BankAccount";
            } & PaymentMethodDetails_BankAccount_Fragment)
          | ({ __typename?: "BPay" } & PaymentMethodDetails_BPay_Fragment)
          | ({
              __typename?: "CreditCard";
            } & PaymentMethodDetails_CreditCard_Fragment)
          | ({
              __typename?: "VirtualAccount";
            } & PaymentMethodDetails_VirtualAccount_Fragment)
        >;
      }>
    >
  >;
};

type PaymentMethodDetails_BankAccount_Fragment = {
  __typename: "BankAccount";
  id: string;
};

type PaymentMethodDetails_BPay_Fragment = { __typename: "BPay"; id: string };

type PaymentMethodDetails_CreditCard_Fragment = {
  __typename: "CreditCard";
  id: string;
};

type PaymentMethodDetails_VirtualAccount_Fragment = {
  __typename: "VirtualAccount";
  id: string;
};

export type PaymentMethodDetailsFragment =
  | PaymentMethodDetails_BankAccount_Fragment
  | PaymentMethodDetails_BPay_Fragment
  | PaymentMethodDetails_CreditCard_Fragment
  | PaymentMethodDetails_VirtualAccount_Fragment;

export type AttachFilesToManagementMutationVariables = Exact<{
  id: Scalars["ID"];
  fileIds: Array<Scalars["AiloRN"]>;
}>;

export type AttachFilesToManagementMutation = {
  __typename?: "Mutation";
  attachFilesToManagement?: Maybe<
    { __typename?: "Management"; id: string } & ManagementFilesFragment
  >;
};

export type GetManagementAndTeamsQueryVariables = Exact<{
  managementId: Scalars["ID"];
  organisationId: Scalars["ID"];
}>;

export type GetManagementAndTeamsQuery = {
  __typename?: "Query";
  management?: Maybe<
    {
      __typename?: "Management";
      id: string;
      startDate?: Maybe<string>;
      fixedTermEndDate?: Maybe<string>;
      team?: Maybe<{ __typename?: "Team"; id: string }>;
    } & ManagementPropertyFragment
  >;
  organisation?: Maybe<{
    __typename?: "Organisation";
    id: string;
    teams: Array<{
      __typename?: "Team";
      id: string;
      name: string;
      myTeam: boolean;
    }>;
  }>;
};

export type GetManagementDetailsAndTeamsQueryVariables = Exact<{
  managementId: Scalars["ID"];
  organisationId: Scalars["ID"];
}>;

export type GetManagementDetailsAndTeamsQuery = {
  __typename?: "Query";
  management?: Maybe<
    {
      __typename?: "Management";
      id: string;
      startDate?: Maybe<string>;
      endDate?: Maybe<string>;
      fixedTermEndDate?: Maybe<string>;
      endNote?: Maybe<string>;
      endReason?: Maybe<{
        __typename?: "ManagementEndReason";
        code: ManagementEndReasonCode;
        label: string;
        causes: Array<{
          __typename?: "ManagementEndCause";
          code: ManagementEndCauseCode;
          label: string;
        }>;
      }>;
      team?: Maybe<{ __typename?: "Team"; id: string }>;
    } & ManagementPropertyFragment &
      ManagementFilesFragment
  >;
  organisation?: Maybe<{
    __typename?: "Organisation";
    id: string;
    teams: Array<{
      __typename?: "Team";
      id: string;
      name: string;
      myTeam: boolean;
    }>;
  }>;
};

export type ManagementFilesFragment = {
  __typename?: "Management";
  files?: Maybe<{
    __typename?: "PaginatedFiles";
    pageInfo: { __typename?: "BidirectionalPageInfo"; total: number };
    items: Array<
      Maybe<{
        __typename?: "File";
        id: string;
        fileName: string;
        fileSize?: Maybe<number>;
        url?: Maybe<string>;
        contentType?: Maybe<string>;
      }>
    >;
  }>;
};

export type MoveManagementsToTeamMutationVariables = Exact<{
  managementId: Scalars["AiloRN"];
  teamId: Scalars["AiloRN"];
}>;

export type MoveManagementsToTeamMutation = {
  __typename?: "Mutation";
  moveManagementsToTeam?: Maybe<{ __typename?: "Team"; id: string }>;
};

export type CreatePropertyKeyMutationVariables = Exact<{
  input: CreateOneKeyInput;
  organisationAilorn: Scalars["AiloRN"];
}>;

export type CreatePropertyKeyMutation = {
  __typename?: "Mutation";
  createOneKey: {
    __typename?: "Key";
    property: { __typename?: "Property" } & PropertyKeysFragment;
  } & KeyFragment;
};

export type UpdatePropertyKeyMutationVariables = Exact<{
  input: UpdateOneKeyInput;
  organisationAilorn: Scalars["AiloRN"];
}>;

export type UpdatePropertyKeyMutation = {
  __typename?: "Mutation";
  updateOneKey: {
    __typename?: "Key";
    property: { __typename?: "Property" } & PropertyKeysFragment;
  } & KeyFragment;
};

export type GetAllTenanciesQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetAllTenanciesQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    tenancies?: Maybe<{
      __typename?: "PaginatedTenancies";
      items: Array<{
        __typename?: "Tenancy";
        id: string;
        bond?: Maybe<{
          __typename?: "TenancyBond";
          status: TenancyBondStatus;
          failureReason?: Maybe<BondDisbursalFailureReason>;
          fundsReceived?: Maybe<
            Array<{ __typename?: "FundReceived"; receivedAt: string }>
          >;
        }>;
      }>;
    }>;
  }>;
};

export type GetManagementAndTenancyForPropertyChatListQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetManagementAndTenancyForPropertyChatListQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    ownerContacts: Array<{
      __typename?: "Contact";
      id: string;
      ailorn: string;
    }>;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      tenantContacts: Array<{
        __typename?: "Contact";
        id: string;
        ailorn: string;
      }>;
    }>;
    nextTenancy?: Maybe<{
      __typename?: "Tenancy";
      tenantContacts: Array<{
        __typename?: "Contact";
        id: string;
        ailorn: string;
      }>;
    }>;
    team?: Maybe<{ __typename?: "Team"; id: string }>;
  }>;
};

export type GetFormerTenanciesUnpaidBillsQueryVariables = Exact<{
  organisationAilorn: Scalars["AiloRN"];
  managementAilorn: Scalars["AiloRN"];
  currentTenancyAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
  limit: Scalars["Int"];
}>;

export type GetFormerTenanciesUnpaidBillsQuery = {
  __typename?: "Query";
  bills?: Maybe<{
    __typename?: "PaginatedBills";
    pageInfo: { __typename?: "BidirectionalPageInfo"; total: number };
    items: Array<{
      __typename?: "Bill";
      ailoRN: string;
      payer?: Maybe<
        | { __typename: "Tenancy"; id: string }
        | { __typename: "Management" }
        | { __typename: "LegalEntityCompanion" }
      >;
    }>;
  }>;
};

export type GetManagementOwnerEndDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
  organisationAilorn: Scalars["AiloRN"];
}>;

export type GetManagementOwnerEndDetailsQuery = {
  __typename?: "Query";
  management?: Maybe<
    { __typename?: "Management"; id: string } & ManagementOwnershipsFragment &
      ManagementEndDetailsFragment
  >;
};

export type GetPropertyHealthQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetPropertyHealthQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    occupancyStatus?: Maybe<OccupancyStatus>;
    startDate?: Maybe<string>;
    mostRecentTenancy?: Maybe<
      {
        __typename?: "Tenancy";
        id: string;
        endDate?: Maybe<string>;
        startDate?: Maybe<string>;
        routineInspectionDueDate?: Maybe<string>;
        liability?: Maybe<{
          __typename?: "Liability";
          id: string;
          reference: string;
          paidTo?: Maybe<string>;
        }>;
        currentRentSchedule?: Maybe<{
          __typename?: "RentSchedule";
          id: string;
          amountInCents: string;
          period: RentFrequency;
        }>;
        mostRecentTenancyAgreement?: Maybe<{
          __typename?: "TenancyAgreement";
          id: string;
          startDate?: Maybe<string>;
          fixedTermEndDate?: Maybe<string>;
          allowedToLapseAt?: Maybe<string>;
        }>;
      } & UpcomingTenancyAgreementFragment &
        UpcomingRentChangesFragment
    >;
    nextTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      startDate?: Maybe<string>;
    }>;
  }>;
};

export type GetFormerAndVoidedTenanciesQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetFormerAndVoidedTenanciesQuery = {
  __typename?: "Query";
  management?: Maybe<
    { __typename?: "Management" } & ManagementFormerAndVoidedTenanciesFragment
  >;
};

export type GetPropertyWalletMoneyManagementSettingsQueryVariables = Exact<{
  walletOwnerReference: Scalars["AiloRN"];
}>;

export type GetPropertyWalletMoneyManagementSettingsQuery = {
  __typename?: "Query";
  walletByWalletOwnerReference?: Maybe<{
    __typename?: "Wallet";
    id: string;
    name: string;
    availableBalance: { __typename?: "Money"; cents: number };
    totalBalance: { __typename?: "Money"; cents: number };
    autoWithdrawPlans?: Maybe<{
      __typename?: "PaginatedAutoWithdrawPlans";
      items?: Maybe<
        Array<{
          __typename?: "AutoWithdrawPlan";
          id: string;
          enabled: boolean;
          owner: string;
          details: {
            __typename?: "AutoWithdrawPlanDetails";
            startDate: string;
            frequency: QuartzPlanFrequency;
            nextFireTime: string;
            anniversaryDaysOfMonth?: Maybe<Array<number>>;
            isLastDayOfTheMonth?: Maybe<boolean>;
            paymentMethodDestinations: Array<{
              __typename?: "PaymentMethodDestination";
              paymentMethodId: string;
              share: number;
              paymentMethod:
                | ({
                    __typename?: "BankAccount";
                    accountName?: Maybe<string>;
                    accountNumber?: Maybe<string>;
                  } & PaymentMethodDetails_BankAccount_Fragment)
                | ({ __typename?: "BPay" } & PaymentMethodDetails_BPay_Fragment)
                | ({
                    __typename?: "CreditCard";
                  } & PaymentMethodDetails_CreditCard_Fragment)
                | ({
                    __typename?: "VirtualAccount";
                  } & PaymentMethodDetails_VirtualAccount_Fragment);
            }>;
          };
        }>
      >;
    }>;
  }>;
  autoPayLiabilityStatuses?: Maybe<
    Array<
      Maybe<{
        __typename?: "AutoPayLiabilityStatus";
        payerId: string;
        enabled: boolean;
        maximumPaymentAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
      }>
    >
  >;
};

export type ReviewRentMutationVariables = Exact<{
  tenancyId: Scalars["ID"];
  rentDetails: RentReviewDetails;
}>;

export type ReviewRentMutation = {
  __typename?: "Mutation";
  reviewRent?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      leaseReviewAllowed?: Maybe<boolean>;
      rentReviewAllowed?: Maybe<boolean>;
      editableRentReview?: Maybe<{
        __typename?: "EditableRentReviewPayload";
        rent?: Maybe<{ __typename?: "Rent"; id: string }>;
      }>;
    } & UpcomingRentChangesFragment
  >;
};

export type GetRentReviewDataQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
  edit: Scalars["Boolean"];
}>;

export type GetRentReviewDataQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    endDate?: Maybe<string>;
    leaseReviewAllowedOperation?: Maybe<AllowedOperations>;
    scheduledRentReviewDate?: Maybe<string>;
    liability?: Maybe<{
      __typename?: "Liability";
      id: string;
      nextChargeCreateDate?: Maybe<string>;
    }>;
    mostRecentRentReview?: Maybe<{
      __typename?: "Rent";
      id: string;
      effectiveDate: string;
      rentIncreaseInCents: string;
    }>;
    currentRent?: Maybe<{
      __typename?: "Rent";
      id: string;
      amountInCents: string;
      period: RentFrequency;
    }>;
    rents: {
      __typename?: "PaginatedRents";
      pageInfo: { __typename?: "PageInfo"; total: number };
      items: Array<{
        __typename?: "Rent";
        id: string;
        amountInCents: string;
        period: RentFrequency;
      }>;
    };
    editableRentReview?: Maybe<{
      __typename?: "EditableRentReviewPayload";
      rent?: Maybe<{
        __typename?: "Rent";
        id: string;
        amountInCents: string;
        period: RentFrequency;
        effectiveDate: string;
        previousRent?: Maybe<{
          __typename?: "Rent";
          id: string;
          amountInCents: string;
        }>;
      }>;
    }>;
  }>;
};

export type AddCentrepayDirectiveMutationVariables = Exact<{
  input: AddCentrepayDirectiveInput;
}>;

export type AddCentrepayDirectiveMutation = {
  __typename?: "Mutation";
  addCentrepayDirective: {
    __typename?: "AddCentrepayDirectiveOutput";
    legalEntity: string;
    managingEntity: string;
    tenancy: string;
  };
};

export type DeleteCentrepayDirectiveMutationVariables = Exact<{
  input: DeleteCentrepayDirectiveInput;
}>;

export type DeleteCentrepayDirectiveMutation = {
  __typename?: "Mutation";
  deleteCentrepayDirective: boolean;
};

export type DeleteCrnMutationVariables = Exact<{
  input: DeleteCrnInput;
}>;

export type DeleteCrnMutation = {
  __typename?: "Mutation";
  deleteCrn?: Maybe<boolean>;
};

export type GetCrnQueryVariables = Exact<{
  input: GetCrnInput;
}>;

export type GetCrnQuery = {
  __typename?: "Query";
  getCrn?: Maybe<{ __typename?: "GetCrnOutput"; crn: string }>;
};

export type UpsertLegalEntitiesCrnMutationVariables = Exact<{
  input: UpsertCrnInput;
}>;

export type UpsertLegalEntitiesCrnMutation = {
  __typename?: "Mutation";
  upsertCrn?: Maybe<{
    __typename?: "UpsertCrnOutput";
    legalEntity: string;
    managingEntity: string;
    crn: string;
  }>;
};

export type InspectionSectionInfoFragment = {
  __typename?: "Tenancy";
  id: string;
  lastCompletedRoutineInspection?: Maybe<{
    __typename?: "Inspection";
    id: string;
    completedAt?: Maybe<string>;
  }>;
} & TenancyInspectionScheduleFragment;

export type AttachFilesToTenancyMutationVariables = Exact<{
  id: Scalars["ID"];
  fileIds: Array<Scalars["AiloRN"]>;
}>;

export type AttachFilesToTenancyMutation = {
  __typename?: "Mutation";
  attachFilesToTenancy?: Maybe<
    { __typename?: "Tenancy"; id: string } & TenancyFilesFragment
  >;
};

export type GetTenancyDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetTenancyDetailsQuery = {
  __typename?: "Query";
  tenancy?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      vacatingReason?: Maybe<VacatingReason>;
      vacatingNotes?: Maybe<string>;
      mostRecentTenancyAgreement?: Maybe<{
        __typename?: "TenancyAgreement";
        id: string;
        startDate?: Maybe<string>;
        fixedTermEndDate?: Maybe<string>;
        allowedToLapseAt?: Maybe<string>;
      }>;
      displayRent?: Maybe<{ __typename?: "Rent" } & RentFragment>;
      rents: {
        __typename?: "PaginatedRents";
        items: Array<{ __typename?: "Rent" } & RentFragment>;
      };
      liability?: Maybe<{
        __typename?: "Liability";
        id: string;
        effectivePaidToDate?: Maybe<string>;
      }>;
      tenantships?: Maybe<{
        __typename?: "PaginatedTenantships";
        items: Array<{
          __typename?: "Tenantship";
          tenant?: Maybe<
            | ({ __typename?: "Company" } & LegalEntityName_Company_Fragment)
            | ({ __typename?: "Person" } & LegalEntityName_Person_Fragment)
          >;
        }>;
      }>;
      management?: Maybe<{
        __typename?: "Management";
        managingEntity?: Maybe<{ __typename?: "Company"; ailoRN: string }>;
      }>;
    } & TenancyFragment &
      UpcomingTenancyAgreementFragment &
      UpcomingRentChangesFragment &
      TenancyFilesFragment &
      TenancyPropertyFragment &
      InspectionSectionInfoFragment
  >;
};

export type TenancyFilesFragment = {
  __typename?: "Tenancy";
  files?: Maybe<{
    __typename?: "PaginatedFiles";
    pageInfo: { __typename?: "BidirectionalPageInfo"; total: number };
    items: Array<
      Maybe<{
        __typename?: "File";
        id: string;
        fileName: string;
        fileSize?: Maybe<number>;
        url?: Maybe<string>;
        contentType?: Maybe<string>;
      }>
    >;
  }>;
};

export type TenancyInspectionScheduleFragment = {
  __typename?: "Tenancy";
  id: string;
  routineInspectionDueDate?: Maybe<string>;
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    currentOrNextManagementAgreement?: Maybe<{
      __typename?: "ManagementAgreement";
      id: string;
      inspectionFrequency?: Maybe<string>;
    }>;
  }>;
};

export type RemoveBondClaimMutationVariables = Exact<{
  bondAilorn: Scalars["AiloRN"];
}>;

export type RemoveBondClaimMutation = {
  __typename?: "Mutation";
  removeBondClaim: {
    __typename?: "TenancyBond";
    id: string;
    status: TenancyBondStatus;
    claims?: Maybe<
      Array<{
        __typename?: "TenancyBondClaim";
        id: string;
        amount: { __typename?: "Money"; cents: number };
      }>
    >;
  };
};

export type CreateRentCreditMutationVariables = Exact<{
  input: RentCreditDetailsInput;
}>;

export type CreateRentCreditMutation = {
  __typename?: "Mutation";
  createRentCredit?: Maybe<{
    __typename?: "RentCreditDetailsPayload";
    tenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      liability?: Maybe<{
        __typename?: "Liability";
        id: string;
        entries?: Maybe<{
          __typename?: "PaginatedLiabilityEntries";
          items: Array<
            | ({
                __typename?: "AdjustmentLiabilityEntry";
              } & AdjustmentLiabilityEntryFragment)
            | { __typename?: "PaymentLiabilityEntry" }
            | { __typename?: "PlanBasedLiabilityEntry" }
          >;
        }>;
      }>;
    }>;
  }>;
};

export type GetTenancyDetailsForCreateRentAdjustmentQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetTenancyDetailsForCreateRentAdjustmentQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    startDate?: Maybe<string>;
    management?: Maybe<{
      __typename?: "Management";
      id: string;
      managingEntity?: Maybe<{
        __typename?: "Company";
        id: string;
        timezone: string;
      }>;
    }>;
  }>;
};

export type GetAdjustmentDetailsQueryVariables = Exact<{
  liabilityEntryId: Scalars["ID"];
  tenancyId: Scalars["ID"];
}>;

export type GetAdjustmentDetailsQuery = {
  __typename?: "Query";
  liabilityEntry?: Maybe<
    | ({
        __typename?: "AdjustmentLiabilityEntry";
      } & AdjustmentLiabilityEntryFragment)
    | { __typename?: "PaymentLiabilityEntry" }
    | { __typename?: "PlanBasedLiabilityEntry" }
  >;
  tenancy?: Maybe<
    { __typename?: "Tenancy"; id: string } & TenancyPropertyFragment
  >;
};

export type AdjustmentLiabilityEntriesForRentAdjustmentsListQueryVariables =
  Exact<{
    tenancyId: Scalars["ID"];
  }>;

export type AdjustmentLiabilityEntriesForRentAdjustmentsListQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    liability?: Maybe<{
      __typename?: "Liability";
      id: string;
      reference: string;
      entries?: Maybe<{
        __typename?: "PaginatedLiabilityEntries";
        items: Array<
          | ({
              __typename?: "AdjustmentLiabilityEntry";
            } & AdjustmentLiabilityEntryFragment)
          | { __typename?: "PaymentLiabilityEntry" }
          | { __typename?: "PlanBasedLiabilityEntry" }
        >;
      }>;
    }>;
  }>;
};

export type AdjustmentLiabilityEntryFragment = {
  __typename?: "AdjustmentLiabilityEntry";
  id: string;
  createdAt: string;
  description?: Maybe<string>;
  effectiveAt: string;
  descriptionIsCustom?: Maybe<boolean>;
  createdBy?: Maybe<
    | { __typename?: "User"; ailoRN: string }
    | { __typename?: "System"; ailoRN: string }
  >;
  amount: { __typename?: "Money"; cents: number };
  reversedByAdjustment?: Maybe<{
    __typename?: "AdjustmentLiabilityEntry";
    id: string;
    effectiveAt: string;
    description?: Maybe<string>;
    descriptionIsCustom?: Maybe<boolean>;
  }>;
  reversesAdjustment?: Maybe<{
    __typename?: "AdjustmentLiabilityEntry";
    id: string;
  }>;
};

export type TenancyStartDateQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type TenancyStartDateQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    startDate?: Maybe<string>;
    management?: Maybe<{
      __typename?: "Management";
      id: string;
      managingEntity?: Maybe<{
        __typename?: "Company";
        id: string;
        timezone: string;
      }>;
    }>;
  }>;
};

export type ReverseRentAdjustmentMutationVariables = Exact<{
  input: ReverseAdjustmentInput;
}>;

export type ReverseRentAdjustmentMutation = {
  __typename?: "Mutation";
  reverseAdjustment?: Maybe<{
    __typename?: "AdjustmentLiabilityEntry";
    id: string;
    reversesAdjustment?: Maybe<{
      __typename?: "AdjustmentLiabilityEntry";
      id: string;
      createdAt: string;
      effectiveAt: string;
      description?: Maybe<string>;
      amount: { __typename?: "Money"; cents: number };
      reversedByAdjustment?: Maybe<{
        __typename?: "AdjustmentLiabilityEntry";
        id: string;
        effectiveAt: string;
        description?: Maybe<string>;
      }>;
    }>;
    liability: {
      __typename?: "Liability";
      id: string;
      reference: string;
      entries?: Maybe<{
        __typename?: "PaginatedLiabilityEntries";
        items: Array<
          | ({
              __typename?: "AdjustmentLiabilityEntry";
            } & AdjustmentLiabilityEntryFragment)
          | { __typename?: "PaymentLiabilityEntry" }
          | { __typename?: "PlanBasedLiabilityEntry" }
        >;
      }>;
    };
  }>;
};

export type UpsertTenancyBondClaimsMutationVariables = Exact<{
  bond: UpsertTenancyBondClaims;
}>;

export type UpsertTenancyBondClaimsMutation = {
  __typename?: "Mutation";
  upsertTenancyBondClaims: Array<
    Maybe<{ __typename?: "TenancyBondClaim" } & TenancyBondClaimFragment>
  >;
};

export type GetDisbursementPeriodDataQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
}>;

export type GetDisbursementPeriodDataQuery = {
  __typename?: "Query";
  agencyIncomeDisbursementPeriodReport?: Maybe<{
    __typename?: "AgencyIncomeDisbursementPeriodReport";
    rows?: Maybe<
      Array<
        Maybe<{
          __typename?: "AgencyIncomeDisbursementPeriodData";
          disbursementPeriodSk: string;
          disbursementDisplayName: string;
          openingBalance: string;
          closingBalance: string;
        }>
      >
    >;
    columns?: Maybe<
      Array<Maybe<{ __typename?: "ReportColumn"; header: string; key: string }>>
    >;
  }>;
};

export type GetBillIssueTableDataQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetBillIssueTableDataQuery = {
  __typename?: "Query";
  billIssuesSummaryReport?: Maybe<{
    __typename?: "BillIssuesSummaryReport";
    rows?: Maybe<
      Array<
        Maybe<{
          __typename?: "BillIssueSummaryData";
          billIssue: string;
          billCount: number;
          billCountPercent: string;
          drilldownFields?: Maybe<Array<Maybe<string>>>;
        }>
      >
    >;
    columns?: Maybe<
      Array<Maybe<{ __typename?: "ReportColumn"; header: string; key: string }>>
    >;
  }>;
};

export type GetBillsDueTableDataQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  billIssue?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetBillsDueTableDataQuery = {
  __typename?: "Query";
  billsDueReport?: Maybe<{
    __typename?: "BillsReport";
    rows?: Maybe<
      Array<
        Maybe<{
          __typename?: "BillsData";
          billId: string;
          propertyAddress?: Maybe<string>;
          billDate?: Maybe<string>;
          billType?: Maybe<string>;
          payerEntityType: string;
          supplierName?: Maybe<string>;
          billInvoiceNumber?: Maybe<string>;
          billAmount?: Maybe<string>;
          billCreatedAt: string;
          billPaymentStatus?: Maybe<string>;
          billDueDays?: Maybe<number>;
        }>
      >
    >;
    columns?: Maybe<
      Array<Maybe<{ __typename?: "ReportColumn"; header: string; key: string }>>
    >;
  }>;
};

export type GetBondsReportQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
  searchTerm?: Maybe<Scalars["String"]>;
}>;

export type GetBondsReportQuery = {
  __typename?: "Query";
  bondsReport?: Maybe<{
    __typename?: "BondsReport";
    type: string;
    columns: Array<{
      __typename?: "ReportColumn";
      key: string;
      header: string;
    }>;
    rows: Array<{
      __typename?: "BondsData";
      bondReference?: Maybe<string>;
      bondStatus?: Maybe<string>;
      bondStatusDescription?: Maybe<string>;
      bondAmount?: Maybe<string>;
      renterNames?: Maybe<string>;
      propertyAddress?: Maybe<string>;
      managementId?: Maybe<string>;
      dateClaimCreated?: Maybe<string>;
    }>;
  }>;
};

export type GetCentrepayReportQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
  searchTerm?: Maybe<Scalars["String"]>;
}>;

export type GetCentrepayReportQuery = {
  __typename?: "Query";
  centrepayReport?: Maybe<{
    __typename?: "CentrepayReport";
    type: string;
    rows: Array<{
      __typename?: "CentrepayData";
      crnReference: string;
      renterNames?: Maybe<string>;
      latestTransactionDate?: Maybe<string>;
      latestTransactionAmount?: Maybe<string>;
      agencyId?: Maybe<string>;
      managementId?: Maybe<string>;
      propertyAddress?: Maybe<string>;
      teamId?: Maybe<string>;
      crnStatus?: Maybe<string>;
      crnDeletedDate?: Maybe<string>;
      tenancyStatus?: Maybe<string>;
    }>;
  }>;
};

export type GetAgencyFinancialPerformanceReportQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetAgencyFinancialPerformanceReportQuery = {
  __typename?: "Query";
  agencyFinancialPerformanceReport?: Maybe<{
    __typename?: "AgencyFinancialPerformanceReport";
    totalRevenue?: Maybe<{
      __typename?: "CardMetric";
      label?: Maybe<string>;
      thisPeriodValue?: Maybe<string>;
      periodLabel?: Maybe<string>;
      previousPeriodValue?: Maybe<string>;
      changeValue?: Maybe<string>;
      changeType?: Maybe<string>;
    }>;
    managementFeeRevenue?: Maybe<{
      __typename?: "CardMetric";
      label?: Maybe<string>;
      thisPeriodValue?: Maybe<string>;
      periodLabel?: Maybe<string>;
      previousPeriodValue?: Maybe<string>;
      changeValue?: Maybe<string>;
      changeType?: Maybe<string>;
    }>;
    overFeeRevenue?: Maybe<{
      __typename?: "CardMetric";
      label?: Maybe<string>;
      thisPeriodValue?: Maybe<string>;
      periodLabel?: Maybe<string>;
      previousPeriodValue?: Maybe<string>;
      changeValue?: Maybe<string>;
      changeType?: Maybe<string>;
    }>;
    propertiesUnderManagement?: Maybe<{
      __typename?: "CardMetric";
      label?: Maybe<string>;
      thisPeriodValue?: Maybe<string>;
      periodLabel?: Maybe<string>;
      previousPeriodValue?: Maybe<string>;
      changeValue?: Maybe<string>;
      changeType?: Maybe<string>;
    }>;
    averageManagementFeeAmount?: Maybe<{
      __typename?: "CardMetric";
      label?: Maybe<string>;
      thisPeriodValue?: Maybe<string>;
      periodLabel?: Maybe<string>;
      previousPeriodValue?: Maybe<string>;
      changeValue?: Maybe<string>;
      changeType?: Maybe<string>;
    }>;
    averageManagementFeePercent?: Maybe<{
      __typename?: "CardMetric";
      label?: Maybe<string>;
      thisPeriodValue?: Maybe<string>;
      periodLabel?: Maybe<string>;
      previousPeriodValue?: Maybe<string>;
      changeValue?: Maybe<string>;
      changeType?: Maybe<string>;
    }>;
  }>;
};

export type GetAgencyIncomeByMonthReportQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetAgencyIncomeByMonthReportQuery = {
  __typename?: "Query";
  agencyIncomeByMonthReport?: Maybe<{
    __typename?: "AgencyIncomeByMonthReport";
    labels?: Maybe<
      Array<
        Maybe<{
          __typename?: "ReportSeriesTimeLabel";
          raw?: Maybe<string>;
          formatted?: Maybe<string>;
        }>
      >
    >;
    series?: Maybe<
      Array<
        Maybe<{
          __typename?: "ReportTimeSeries";
          name?: Maybe<string>;
          values?: Maybe<Array<Maybe<number>>>;
        }>
      >
    >;
  }>;
};

export type GetCurrentPropertyCountQueryVariables = Exact<{
  managingOrgId?: Maybe<Scalars["ID"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetCurrentPropertyCountQuery = {
  __typename?: "Query";
  propertyIssuesSummaryReport?: Maybe<{
    __typename?: "PropertyIssuesSummaryReport";
    headerFields?: Maybe<{
      __typename?: "PropertyIssuesHeaderFields";
      totalPropertyCount?: Maybe<number>;
    }>;
  }>;
};

export type GetAgencyIncomeByChartOfAccountReportDataQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  disbursementPeriodSk?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetAgencyIncomeByChartOfAccountReportDataQuery = {
  __typename?: "Query";
  agencyIncomeByChartOfAccountReport?: Maybe<{
    __typename?: "AgencyIncomeByChartOfAccountReport";
    headerFields: {
      __typename?: "AgencyIncomeByChartOfAccountHeaderFields";
      totalIncomeAmount: string;
    };
    rows?: Maybe<
      Array<
        Maybe<{
          __typename?: "AgencyIncomeByChartOfAccountData";
          chartOfAccountName: string;
          incomeAmount: string;
        }>
      >
    >;
    columns?: Maybe<
      Array<Maybe<{ __typename?: "ReportColumn"; header: string; key: string }>>
    >;
  }>;
};

export type AgencyIncomeByPropertyReportTotalFragment = {
  __typename?: "AgencyIncomeByPropertyTotals";
  rentCollectedAmount?: Maybe<string>;
  managementFeeAmount?: Maybe<string>;
  managementFeePercent?: Maybe<string>;
  otherFeeAmount?: Maybe<string>;
  totalFeeAmount?: Maybe<string>;
};

export type GetAgencyIncomeByPropertyReportDataQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  disbursementPeriodSk?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
  includeActiveProperties?: Maybe<Scalars["Boolean"]>;
}>;

export type GetAgencyIncomeByPropertyReportDataQuery = {
  __typename?: "Query";
  agencyIncomeByPropertyReport?: Maybe<{
    __typename?: "AgencyIncomeByPropertyReport";
    headerFields: {
      __typename?: "AgencyIncomeByPropertyHeaderFields";
      total?: Maybe<
        {
          __typename?: "AgencyIncomeByPropertyTotals";
        } & AgencyIncomeByPropertyReportTotalFragment
      >;
      average?: Maybe<
        {
          __typename?: "AgencyIncomeByPropertyTotals";
        } & AgencyIncomeByPropertyReportTotalFragment
      >;
    };
    rows?: Maybe<
      Array<
        Maybe<{
          __typename?: "AgencyIncomeByPropertyData";
          teamId?: Maybe<string>;
          teamName?: Maybe<string>;
          propertyId?: Maybe<string>;
          managementId?: Maybe<string>;
          propertyAddress?: Maybe<string>;
          rentCollectedAmount?: Maybe<string>;
          managementFeeAmount?: Maybe<string>;
          managementFeePercent?: Maybe<string>;
          otherFeeAmount?: Maybe<string>;
          totalFeeAmount?: Maybe<string>;
        }>
      >
    >;
    columns?: Maybe<
      Array<Maybe<{ __typename?: "ReportColumn"; header: string; key: string }>>
    >;
  }>;
};

export type GetPropertiesTableDataQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  propertyIssue?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetPropertiesTableDataQuery = {
  __typename?: "Query";
  propertiesReport?: Maybe<{
    __typename?: "PropertiesReport";
    rows?: Maybe<
      Array<
        Maybe<{
          __typename?: "PropertiesData";
          managementId?: Maybe<string>;
          propertyAddress?: Maybe<string>;
          propertyOnAppAt?: Maybe<string>;
          propertyPayingRentOnAiloSince?: Maybe<string>;
          overdueRent?: Maybe<string>;
          arrearsAmount?: Maybe<string>;
          arrearsDays?: Maybe<number>;
          vacateDate?: Maybe<string>;
          vacantDays?: Maybe<number>;
          totalWalletBalance?: Maybe<string>;
          availableWalletBalance?: Maybe<string>;
          lastRentPaymentInAilo?: Maybe<string>;
          investorNames?: Maybe<string>;
          renterNames?: Maybe<string>;
          previousDisbursementDate?: Maybe<string>;
          daysSincePreviousDisbursement?: Maybe<number>;
          leaseExpiryDate?: Maybe<string>;
          daysSinceLeaseExpiry?: Maybe<number>;
          rentNextDueDate?: Maybe<string>;
          tenancyPaidToDate?: Maybe<string>;
          overdueDays?: Maybe<number>;
          investorInvitedAt?: Maybe<string>;
          renterInvitedAt?: Maybe<string>;
          scheduledRentReviewDate?: Maybe<string>;
          rentAmount?: Maybe<string>;
          rentPeriod?: Maybe<string>;
        }>
      >
    >;
    columns?: Maybe<
      Array<Maybe<{ __typename?: "ReportColumn"; header: string; key: string }>>
    >;
  }>;
};

export type GetInvestorOnAppTableDataQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetInvestorOnAppTableDataQuery = {
  __typename?: "Query";
  investorOnAppReport?: Maybe<{
    __typename?: "InvestorOnAppReport";
    rows?: Maybe<
      Array<
        Maybe<{
          __typename?: "InvestorOnAppData";
          isPrimaryInvestor?: Maybe<string>;
          legalEntityId?: Maybe<string>;
          investorStatus?: Maybe<string>;
          investorName?: Maybe<string>;
          hasNotificationsEnabled?: Maybe<string>;
          totalBillsDue?: Maybe<string>;
          properties?: Maybe<string>;
          onAppAt?: Maybe<string>;
        }>
      >
    >;
    columns?: Maybe<
      Array<Maybe<{ __typename?: "ReportColumn"; header: string; key: string }>>
    >;
  }>;
};

export type GetNotificationsTableDataQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
  searchTerm?: Maybe<Scalars["String"]>;
}>;

export type GetNotificationsTableDataQuery = {
  __typename?: "Query";
  notificationsReport?: Maybe<{
    __typename?: "NotificationsReport";
    type: string;
    columns: Array<{
      __typename?: "ReportColumn";
      key: string;
      header: string;
    }>;
    rows: Array<{
      __typename?: "NotificationsData";
      agencyId?: Maybe<string>;
      legalEntityId?: Maybe<string>;
      tenancyId?: Maybe<string>;
      personName?: Maybe<string>;
      personId?: Maybe<string>;
      personType?: Maybe<string>;
      notificationChannel?: Maybe<string>;
      notificationType?: Maybe<string>;
      notificationTitle?: Maybe<string>;
      notificationDeliveryStatus?: Maybe<string>;
      sentAt?: Maybe<string>;
      propertyAddress?: Maybe<string>;
      managementId?: Maybe<string>;
      teamId?: Maybe<string>;
    }>;
  }>;
};

export type GetPropertyLiveTableDataQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetPropertyLiveTableDataQuery = {
  __typename?: "Query";
  propertyLiveReport?: Maybe<{
    __typename?: "PropertyLiveReport";
    rows?: Maybe<
      Array<
        Maybe<{
          __typename?: "PropertyLiveData";
          teamId?: Maybe<string>;
          teamName?: Maybe<string>;
          propertyId?: Maybe<string>;
          managementId?: Maybe<string>;
          propertyAddress?: Maybe<string>;
          investorNames?: Maybe<string>;
          renterNames?: Maybe<string>;
          propertyLiveAt?: Maybe<string>;
        }>
      >
    >;
    columns?: Maybe<
      Array<Maybe<{ __typename?: "ReportColumn"; header: string; key: string }>>
    >;
    headerFields: {
      __typename?: "PropertyLiveHeaderFields";
      totalPropertyCount?: Maybe<number>;
      activePropertyCount?: Maybe<number>;
      pendingPropertyCount?: Maybe<number>;
      vacantPropertyCount?: Maybe<number>;
      activePropertyPercent?: Maybe<string>;
    };
  }>;
};

export type GetRenterOnAppTableDataQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetRenterOnAppTableDataQuery = {
  __typename?: "Query";
  renterOnAppReport?: Maybe<{
    __typename?: "RenterOnAppReport";
    rows?: Maybe<
      Array<
        Maybe<{
          __typename?: "RenterOnAppData";
          propertyId?: Maybe<string>;
          managementId?: Maybe<string>;
          tenancyId?: Maybe<string>;
          legalEntityId?: Maybe<string>;
          propertyAddress?: Maybe<string>;
          renterName?: Maybe<string>;
          renterOnAppAt?: Maybe<string>;
        }>
      >
    >;
    columns?: Maybe<
      Array<Maybe<{ __typename?: "ReportColumn"; header: string; key: string }>>
    >;
  }>;
};

export type GetFirstRentPaymentsTableDataQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetFirstRentPaymentsTableDataQuery = {
  __typename?: "Query";
  tenancyFirstRentPaymentReport?: Maybe<{
    __typename?: "TenancyFirstRentPaymentReport";
    rows?: Maybe<
      Array<
        Maybe<{
          __typename?: "TenancyFirstRentPaymentData";
          propertyId?: Maybe<string>;
          managementId?: Maybe<string>;
          tenancyId?: Maybe<string>;
          propertyAddress?: Maybe<string>;
          firstRentPaymentDate?: Maybe<string>;
          renter?: Maybe<string>;
          firstRentPaymentAmount?: Maybe<string>;
          teamId?: Maybe<string>;
          teamName?: Maybe<string>;
        }>
      >
    >;
    columns?: Maybe<
      Array<Maybe<{ __typename?: "ReportColumn"; header: string; key: string }>>
    >;
  }>;
};

export type GetPropertyIssueDrilldownTableDataQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  issue: Scalars["String"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetPropertyIssueDrilldownTableDataQuery = {
  __typename?: "Query";
  propertyIssuesDrilldownReport?: Maybe<{
    __typename?: "PropertyIssuesDrilldownReport";
    rows?: Maybe<
      Array<
        Maybe<{
          __typename?: "PropertyIssuesDrilldownData";
          propertyAddress?: Maybe<string>;
          propertyOnAppAt?: Maybe<string>;
          propertyPayingRentOnAiloSince?: Maybe<string>;
          overdueRent?: Maybe<string>;
          arrearsAmount?: Maybe<string>;
          arrearsDays?: Maybe<number>;
          vacateDate?: Maybe<string>;
        }>
      >
    >;
    columns?: Maybe<
      Array<Maybe<{ __typename?: "ReportColumn"; header: string; key: string }>>
    >;
  }>;
};

export type PropertyIssuesMissingFeesReportQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type PropertyIssuesMissingFeesReportQuery = {
  __typename?: "Query";
  propertyIssuesMissingFeesReport?: Maybe<{
    __typename?: "PropertyIssuesMissingFeesReport";
    rows: Array<{
      __typename?: "PropertyIssuesMissingFeeData";
      managementId?: Maybe<string>;
      propertyAddress?: Maybe<string>;
      reason?: Maybe<string>;
      feeType?: Maybe<string>;
      investorNames?: Maybe<string>;
      vacateDate?: Maybe<string>;
    }>;
    columns: Array<{
      __typename?: "ReportColumn";
      header: string;
      key: string;
    }>;
  }>;
};

export type GetPropertyIssueTableDataQueryVariables = Exact<{
  managingOrgId?: Maybe<Scalars["ID"]>;
  teamId?: Maybe<Array<Scalars["ID"]>>;
}>;

export type GetPropertyIssueTableDataQuery = {
  __typename?: "Query";
  propertyIssuesSummaryReport?: Maybe<{
    __typename?: "PropertyIssuesSummaryReport";
    rows?: Maybe<
      Array<
        Maybe<{
          __typename?: "PropertyIssuesSummaryData";
          propertyIssueId: string;
          propertyIssue: string;
          propertyCount: number;
          propertyCountPercent: string;
          drilldownFields?: Maybe<Array<Maybe<string>>>;
        }>
      >
    >;
    columns?: Maybe<
      Array<Maybe<{ __typename?: "ReportColumn"; header: string; key: string }>>
    >;
    headerFields?: Maybe<{
      __typename?: "PropertyIssuesHeaderFields";
      totalPropertyCount?: Maybe<number>;
      propertyWithIssueCount?: Maybe<number>;
      propertyWithIssuePercent?: Maybe<string>;
    }>;
  }>;
};

export type AgencyIncomeReportQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  disbursementPeriodSk?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["LocalDate"]>;
  endDate?: Maybe<Scalars["LocalDate"]>;
  teamIds?: Maybe<Array<Scalars["ID"]>>;
  includeMonth?: Maybe<Scalars["Boolean"]>;
  includeFinancialYearAU?: Maybe<Scalars["Boolean"]>;
  includeQuarter?: Maybe<Scalars["Boolean"]>;
}>;

export type AgencyIncomeReportQuery = {
  __typename?: "Query";
  agencyIncomeReport?: Maybe<{
    __typename: "AgencyIncomeReport";
    lastUpdated?: Maybe<string>;
    rows?: Maybe<
      Array<
        Maybe<{
          __typename: "AgencyIncomeData";
          incomeAmount?: Maybe<number>;
          month?: Maybe<string>;
          financialYearAU?: Maybe<string>;
          feeName?: Maybe<string>;
          qtr?: Maybe<string>;
        }>
      >
    >;
  }>;
};

export type AgencyIncomeDateQueryVariables = Exact<{
  managingOrgId: Scalars["ID"];
  teamIds?: Maybe<Array<Scalars["ID"]>>;
}>;

export type AgencyIncomeDateQuery = {
  __typename?: "Query";
  agencyIncomeReport?: Maybe<{
    __typename?: "AgencyIncomeReport";
    rows?: Maybe<
      Array<Maybe<{ __typename?: "AgencyIncomeData"; minDate?: Maybe<string> }>>
    >;
  }>;
};

export type FindEftPaymentSettingsQueryVariables = Exact<{
  agency: Scalars["AiloRN"];
}>;

export type FindEftPaymentSettingsQuery = {
  __typename?: "Query";
  findEftPaymentSettings?: Maybe<{
    __typename?: "EftPaymentSettings";
    agency: string;
    abaFileFormat: AbaFileFormat;
    directEntryUserId?: Maybe<string>;
  }>;
};

export type UpsertEftPaymentSettingsMutationVariables = Exact<{
  input: EftPaymentSettingsInput;
}>;

export type UpsertEftPaymentSettingsMutation = {
  __typename?: "Mutation";
  upsertEftPaymentSettings: {
    __typename?: "EftPaymentSettings";
    agency: string;
    abaFileFormat: AbaFileFormat;
    directEntryUserId?: Maybe<string>;
  };
};

export type CreateFeeBlueprintMutationVariables = Exact<{
  input: CreateFeeBlueprintInput;
}>;

export type CreateFeeBlueprintMutation = {
  __typename?: "Mutation";
  blueprint?: Maybe<
    {
      __typename?: "FeeBlueprint";
      event?: Maybe<{ __typename?: "FeeEvent"; type: FeeEventType }>;
    } & FeeBlueprintFragment
  >;
};

export type GetFeeBlueprintQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetFeeBlueprintQuery = {
  __typename?: "Query";
  blueprint?: Maybe<
    {
      __typename?: "FeeBlueprint";
      event?: Maybe<{ __typename?: "FeeEvent"; type: FeeEventType }>;
    } & FeeBlueprintFragment
  >;
};

export type GetFeeEventsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFeeEventsQuery = {
  __typename?: "Query";
  feeEvents?: Maybe<
    Array<{
      __typename?: "FeeEvent";
      type: FeeEventType;
      shortDescription: string;
      longDescription: string;
    }>
  >;
};

export type GetFeeTaxCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFeeTaxCategoriesQuery = {
  __typename?: "Query";
  feeTaxCategories?: Maybe<
    Array<{ __typename?: "FeeTaxCategory"; id: string; name: string }>
  >;
};

export type UpdateFeeBlueprintMutationVariables = Exact<{
  input: UpdateFeeBlueprintInput;
}>;

export type UpdateFeeBlueprintMutation = {
  __typename?: "Mutation";
  blueprint?: Maybe<
    {
      __typename?: "FeeBlueprint";
      event?: Maybe<{ __typename?: "FeeEvent"; type: FeeEventType }>;
    } & FeeBlueprintFragment
  >;
};

export type CreateReiTokenMutationVariables = Exact<{
  createReiTokenInput: CreateReiTokenInput;
  organisationAilorn: Scalars["AiloRN"];
}>;

export type CreateReiTokenMutation = {
  __typename?: "Mutation";
  createReiToken: {
    __typename?: "ReiToken";
    person: {
      __typename?: "Person";
      id: string;
      reiTokensForOrg: Array<
        { __typename?: "ReiToken" } & ReiTokenBaseFragment
      >;
    };
  } & ReiTokenBaseFragment;
};

export type RemoveReiTokenMutationVariables = Exact<{
  removeReiTokenInput: RemoveReiTokenInput;
  organisationAilorn: Scalars["AiloRN"];
}>;

export type RemoveReiTokenMutation = {
  __typename?: "Mutation";
  removeReiToken: {
    __typename?: "ReiToken";
    person: {
      __typename?: "Person";
      id: string;
      reiTokensForOrg: Array<
        { __typename?: "ReiToken" } & ReiTokenBaseFragment
      >;
    };
  } & ReiTokenBaseFragment;
};

export type CreateTrustAccountAdjustmentMutationVariables = Exact<{
  input: CreateTrustAccountReconciliationAdjustmentInput;
}>;

export type CreateTrustAccountAdjustmentMutation = {
  __typename?: "Mutation";
  createOneTrustAccountReconciliationAdjustment: {
    __typename?: "TrustAccountReconciliationAdjustment";
    reportPeriod: {
      __typename?: "ReconciliationReportPeriod";
      id: string;
      adjustmentSumInCents: number;
    };
  } & TrustAccountReconciliationAdjustmentFragmentFragment;
};

export type ListTrustAccountAdjustmentsQueryVariables = Exact<{
  filter?: Maybe<TrustAccountReconciliationAdjustmentFilter>;
  paging?: Maybe<CursorPaging>;
}>;

export type ListTrustAccountAdjustmentsQuery = {
  __typename?: "Query";
  trustAccountReconciliationAdjustments: {
    __typename?: "TrustAccountReconciliationAdjustmentConnection";
    totalCount: number;
    pageInfo: { __typename?: "RelayPageInfo" } & RelayPageInfoFragmentFragment;
    edges: Array<{
      __typename?: "TrustAccountReconciliationAdjustmentEdge";
      cursor: any;
      node: {
        __typename?: "TrustAccountReconciliationAdjustment";
      } & TrustAccountReconciliationAdjustmentFragmentFragment;
    }>;
  };
};

export type TrustAccountReconciliationAdjustmentFragmentFragment = {
  __typename?: "TrustAccountReconciliationAdjustment";
  id: string;
  applyOn: string;
  details: string;
  amountInCents: number;
  removedReason?: Maybe<string>;
  removedFrom?: Maybe<string>;
};

export type RelayPageInfoFragmentFragment = {
  __typename?: "RelayPageInfo";
  endCursor?: Maybe<any>;
  hasNextPage?: Maybe<boolean>;
  hasPreviousPage?: Maybe<boolean>;
  startCursor?: Maybe<any>;
};

export type RemoveTrustAccountAdjustmentMutationVariables = Exact<{
  input: RemoveTrustAccountReconciliationAdjustmentInput;
}>;

export type RemoveTrustAccountAdjustmentMutation = {
  __typename?: "Mutation";
  removeOneTrustAccountReconciliationAdjustment: {
    __typename?: "TrustAccountReconciliationAdjustment";
    reportPeriod: {
      __typename?: "ReconciliationReportPeriod";
      id: string;
      adjustmentSumInCents: number;
    };
  } & TrustAccountReconciliationAdjustmentFragmentFragment;
};

export type LatestReconciliationReportPeriodQueryVariables = Exact<{
  trustAccountAilorn: Scalars["AiloRN"];
}>;

export type LatestReconciliationReportPeriodQuery = {
  __typename?: "Query";
  reconciliationReportPeriods: {
    __typename?: "ReconciliationReportPeriodConnection";
    edges: Array<{
      __typename?: "ReconciliationReportPeriodEdge";
      node: {
        __typename?: "ReconciliationReportPeriod";
      } & ReconciliationReportPeriodFragmentFragment;
    }>;
  };
};

export type ListAllReconciliationReportPeriodsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListAllReconciliationReportPeriodsQuery = {
  __typename?: "Query";
  reconciliationReportPeriods: {
    __typename?: "ReconciliationReportPeriodConnection";
    edges: Array<{
      __typename?: "ReconciliationReportPeriodEdge";
      node: {
        __typename?: "ReconciliationReportPeriod";
      } & ReconciliationReportPeriodFragmentFragment;
    }>;
  };
};

export type UpdateTrustAccountStatementBalanceMutationVariables = Exact<{
  reportPeriodId: Scalars["ID"];
  amountInCents: Scalars["Long"];
  date: Scalars["LocalDate"];
}>;

export type UpdateTrustAccountStatementBalanceMutation = {
  __typename?: "Mutation";
  createOneTrustAccountStatementBalance: {
    __typename?: "TrustAccountStatementBalance";
    relatedReportPeriods: Array<{
      __typename?: "ReconciliationReportPeriod";
      id: string;
      isReconciling: boolean;
      canAlterLock: boolean;
      lastAddedStatementBalance?: Maybe<{
        __typename?: "TrustAccountStatementBalance";
        id: string;
      }>;
    }>;
  } & TrustAccountStatementBalanceFragmentFragment;
};

export type LockTrustAccountMutationVariables = Exact<{
  trustAccountAilorn: Scalars["AiloRN"];
  lockedUpTo: Scalars["DateTime"];
}>;

export type LockTrustAccountMutation = {
  __typename?: "Mutation";
  lockTrustAccount?: Maybe<
    { __typename?: "TrustAccountLock" } & TrustAccountLockFragmentFragment
  >;
};

export type UnlockReconciliationPeriodMutationVariables = Exact<{
  trustAccountAilorn: Scalars["AiloRN"];
  month: Scalars["Int"];
  year: Scalars["Int"];
}>;

export type UnlockReconciliationPeriodMutation = {
  __typename?: "Mutation";
  unlockReportPeriod: {
    __typename?: "ReportPeriodLockChangeResult";
    failureReason?: Maybe<string>;
    report?: Maybe<
      {
        __typename?: "ReconciliationReportPeriod";
        canAlterLock: boolean;
      } & ReconciliationReportPeriodFragmentFragment
    >;
  };
};

export type ViewAccountTagBalancesQueryVariables = Exact<{
  trustAccountAilorn: Scalars["AiloRN"];
  book: Book;
  accountSubtypes: Array<AccountSubtype>;
  asOf?: Maybe<Scalars["DateTime"]>;
}>;

export type ViewAccountTagBalancesQuery = {
  __typename?: "Query";
  accountTagBalances?: Maybe<{
    __typename?: "PaginatedAccountTagBalances";
    items: Array<
      Maybe<{
        __typename?: "AccountTagBalance";
        balance?: Maybe<{ __typename?: "Money"; cents: number }>;
        accountTag: {
          __typename?: "AccountTag";
          accountSubtype: AccountSubtype;
          value: string;
        };
      }>
    >;
  }>;
};

export type ViewReconciliationReportPeriodQueryVariables = Exact<{
  trustAccountAilorn: Scalars["AiloRN"];
  year: Scalars["Int"];
  month: Scalars["Int"];
}>;

export type ViewReconciliationReportPeriodQuery = {
  __typename?: "Query";
  reconciliationReportPeriodByDate?: Maybe<
    {
      __typename?: "ReconciliationReportPeriod";
      canAlterLock: boolean;
    } & ReconciliationReportPeriodFragmentFragment
  >;
  getTrustAccountLock?: Maybe<
    { __typename?: "TrustAccountLock" } & TrustAccountLockFragmentFragment
  >;
};

export type ReconciliationReportPeriodFragmentFragment = {
  __typename?: "ReconciliationReportPeriod";
  id: string;
  month: number;
  year: number;
  locked: boolean;
  trustAccountAilorn: string;
  adjustmentSumInCents: number;
  isReconciling: boolean;
  lastAddedStatementBalance?: Maybe<
    {
      __typename?: "TrustAccountStatementBalance";
    } & TrustAccountStatementBalanceFragmentFragment
  >;
};

export type TrustAccountStatementBalanceFragmentFragment = {
  __typename?: "TrustAccountStatementBalance";
  id: string;
  amountInCents: number;
  date: string;
};

export type TrustAccountLockFragmentFragment = {
  __typename?: "TrustAccountLock";
  trustAccountAilorn: string;
  status: TrustAccountLockStatus;
  lockedUpTo: string;
};

export type GetAvailablePurposesQueryVariables = Exact<{
  filters: ListPurposesFilters;
}>;

export type GetAvailablePurposesQuery = {
  __typename?: "Query";
  payablePurposes?: Maybe<
    Array<{
      __typename?: "Purpose";
      id: string;
      reference: string;
      type: PurposeType;
      payable?: Maybe<
        | {
            __typename?: "Bill";
            id: string;
            ailoRN: string;
            description?: Maybe<string>;
            dueDateV2: string;
            amount: { __typename?: "Money"; cents: number };
          }
        | {
            __typename?: "RecurringOwing";
            id: string;
            reference: string;
            ailoRN: string;
            progress: {
              __typename?: "OwingProgress";
              due?: Maybe<{
                __typename?: "Charge";
                date: string;
                amount: {
                  __typename?: "TaxableAmount";
                  total: { __typename?: "Money"; cents: number };
                };
              }>;
              paidTo: {
                __typename?: "PaidTo";
                classic: {
                  __typename?: "PaidToDate";
                  date: string;
                  partPayment: { __typename?: "Money"; cents: number };
                };
                effective: {
                  __typename?: "PaidToDate";
                  date: string;
                  partPayment: { __typename?: "Money"; cents: number };
                };
              };
            };
          }
        | {
            __typename?: "TrustAccountWallet";
            id: string;
            ailorn: string;
            trustAccount: { __typename?: "TrustAccount"; id: string };
          }
      >;
    }>
  >;
};

export type TransactionsQueryVariables = Exact<{
  cursor?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
  before?: Maybe<Scalars["Boolean"]>;
  sort: Array<ListTransactionsSortParams>;
  moneyHolder: Scalars["AiloRN"];
  createdAfter?: Maybe<Scalars["DateTime"]>;
  createdBefore?: Maybe<Scalars["DateTime"]>;
}>;

export type TransactionsQuery = {
  __typename?: "Query";
  transactions?: Maybe<{
    __typename?: "PaginatedTransactions";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
      previousCursor?: Maybe<string>;
    };
    items: Array<
      Maybe<{
        __typename?: "Transaction";
        id?: Maybe<string>;
        auditNumber?: Maybe<number>;
        type?: Maybe<string>;
        referenceNumber?: Maybe<number>;
        createdAt?: Maybe<string>;
        description?: Maybe<string>;
        allocations: Array<
          Maybe<{
            __typename?: "TransactionAllocation";
            description?: Maybe<string>;
            amount?: Maybe<{ __typename?: "Money"; cents: number }>;
          }>
        >;
        management?: Maybe<
          { __typename?: "Management"; id: string } & ManagementPropertyFragment
        >;
      }>
    >;
  }>;
};

export type SearchTrustAccountWalletsQueryVariables = Exact<{
  input: SearchTrustAccountWalletsQueryInput;
  organisationAilorn: Scalars["AiloRN"];
}>;

export type SearchTrustAccountWalletsQuery = {
  __typename?: "Query";
  searchTrustAccountWallets?: Maybe<{
    __typename?: "PaginatedTrustAccountWalletSearchItems";
    pageInfo: { __typename?: "BidirectionalPageInfo"; total: number };
    items: Array<
      {
        __typename?: "TrustAccountWallet";
      } & TrustAccountWalletSearchResultFragment
    >;
  }>;
};

export type TrustAccountWalletSearchResultFragment = {
  __typename?: "TrustAccountWallet";
  id: string;
  ailorn: string;
  type: TrustAccountWalletType;
  entityReference: string;
  code: string;
  trustAccount: {
    __typename?: "TrustAccount";
    id: string;
    ownerLegalEntityAilorn: string;
  };
  entity:
    | {
        __typename?: "LegalEntityCompanion";
        ailoRNV2: string;
        legalEntity:
          | ({
              __typename?: "Company";
            } & LegalEntityContactName_Company_Fragment)
          | ({
              __typename?: "Person";
            } & LegalEntityContactName_Person_Fragment);
      }
    | {
        __typename?: "ManagementFolio";
        owners: Array<{
          __typename?: "ManagementFolioOwnership";
          owner?: Maybe<
            | ({
                __typename?: "Company";
              } & LegalEntityContactName_Company_Fragment)
            | ({
                __typename?: "Person";
              } & LegalEntityContactName_Person_Fragment)
          >;
        }>;
      }
    | {
        __typename?: "Tenancy";
        tenantContacts: Array<{
          __typename?: "Contact";
          ailorn: string;
          displayName: string;
        }>;
        property: { __typename?: "Property" } & PropertyFragment;
      }
    | { __typename?: "Supplier"; ailoRN: string; name?: Maybe<string> }
    | { __typename?: "BondAuthority"; ailorn: string; userFacingName: string };
};

type LegalEntityContactName_Company_Fragment = {
  __typename?: "Company";
  contact?: Maybe<{
    __typename?: "Contact";
    id: string;
    ailorn: string;
    displayName: string;
  }>;
};

type LegalEntityContactName_Person_Fragment = {
  __typename?: "Person";
  contact?: Maybe<{
    __typename?: "Contact";
    id: string;
    ailorn: string;
    displayName: string;
  }>;
};

export type LegalEntityContactNameFragment =
  | LegalEntityContactName_Company_Fragment
  | LegalEntityContactName_Person_Fragment;

export type PropertyFragment = {
  __typename?: "Property";
  id: string;
  ailorn: string;
  address: {
    __typename?: "Address";
    unitStreetNumber: string;
    streetName: string;
    suburb?: Maybe<string>;
    state?: Maybe<string>;
    country: string;
    postcode?: Maybe<string>;
  };
};

export type WalletEntriesForTransactionListQueryVariables = Exact<{
  ownerId: Scalars["AiloRN"];
  cursor?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
}>;

export type WalletEntriesForTransactionListQuery = {
  __typename?: "Query";
  walletEntriesWithStatusByOwnerRef?: Maybe<{
    __typename?: "PaginatedWalletEntriesWithBtStatus";
    pageInfo: {
      __typename?: "LedgerBidirectionalPageInfo";
      total: number;
      hasMore: boolean;
      nextCursor?: Maybe<string>;
    };
    items: Array<{
      __typename?: "WalletEntry";
      id: string;
      status: BusinessTxStatusEnum;
      isReversal: boolean;
      isTopUpFee: boolean;
      createdAt: string;
      amount: { __typename?: "Money"; cents: number };
      liability?: Maybe<{
        __typename?: "Liability";
        id: string;
        category: string;
        taxCategory: string;
        referencedEntity?: Maybe<
          | {
              __typename: "Bill";
              ailoRN: string;
              nullableManagement?: Maybe<
                {
                  __typename?: "Management";
                  id: string;
                } & ManagementPropertyFragment
              >;
            }
          | {
              __typename: "Fee";
              id: string;
              management: {
                __typename?: "Management";
                id: string;
              } & ManagementPropertyFragment;
            }
          | { __typename: "Tenancy" }
        >;
        recurringFee?: Maybe<{
          __typename?: "RecurringFee";
          name: string;
          id: string;
          management?: Maybe<
            {
              __typename?: "Management";
              id: string;
            } & ManagementPropertyFragment
          >;
        }>;
      }>;
      paymentMethod?: Maybe<
        | { __typename?: "BankAccount"; id: string }
        | { __typename?: "BPay"; id: string }
        | { __typename?: "CreditCard"; id: string }
        | { __typename?: "VirtualAccount"; id: string }
      >;
    }>;
  }>;
};

export type WithdrawFromWalletMutationVariables = Exact<{
  withdrawInput: WithdrawInput;
}>;

export type WithdrawFromWalletMutation = {
  __typename?: "Mutation";
  withdrawFromWallet?: Maybe<{
    __typename?: "ResponseStatus";
    status: string;
    businessTransaction?: Maybe<{
      __typename?: "BusinessTransaction";
      paymentChannel?: Maybe<string>;
    }>;
  }>;
};

export type GetAgencyBalancesAndPaymentMethodsQueryVariables = Exact<{
  organisationId: Scalars["ID"];
}>;

export type GetAgencyBalancesAndPaymentMethodsQuery = {
  __typename?: "Query";
  organisation?: Maybe<{
    __typename?: "Organisation";
    id: string;
    legalEntities?: Maybe<
      Array<
        Maybe<
          | {
              __typename?: "Company";
              currentUserPermissions: Array<string>;
              id: string;
              ailoRN: string;
              wallets: Array<{
                __typename?: "Wallet";
                id: string;
                idempotencyKey: string;
                availableBalance: { __typename?: "Money"; cents: number };
                totalBalance: { __typename?: "Money"; cents: number };
                autoWithdrawPlans?: Maybe<{
                  __typename?: "PaginatedAutoWithdrawPlans";
                  items?: Maybe<
                    Array<{
                      __typename?: "AutoWithdrawPlan";
                      id: string;
                      details: {
                        __typename?: "AutoWithdrawPlanDetails";
                        id: string;
                        startDate: string;
                        endDate?: Maybe<string>;
                        frequency: QuartzPlanFrequency;
                        anniversary?: Maybe<number>;
                        nextFireTime: string;
                      };
                    }>
                  >;
                }>;
              }>;
              paymentMethods?: Maybe<{
                __typename?: "PaginatedPaymentMethods";
                items: Array<
                  Maybe<
                    | {
                        __typename: "BankAccount";
                        bsb?: Maybe<string>;
                        accountName?: Maybe<string>;
                        accountNumber?: Maybe<string>;
                        id: string;
                        isOnceOff?: Maybe<boolean>;
                      }
                    | {
                        __typename: "BPay";
                        id: string;
                        isOnceOff?: Maybe<boolean>;
                      }
                    | {
                        __typename: "CreditCard";
                        expiry?: Maybe<string>;
                        number?: Maybe<string>;
                        type?: Maybe<string>;
                        id: string;
                        isOnceOff?: Maybe<boolean>;
                      }
                    | {
                        __typename: "VirtualAccount";
                        id: string;
                        isOnceOff?: Maybe<boolean>;
                      }
                  >
                >;
              }>;
            }
          | { __typename?: "Person"; id: string; ailoRN: string }
        >
      >
    >;
  }>;
};

export type RentLiabilityFragment = {
  __typename?: "Liability";
  id: string;
  category: string;
  firstPaymentDate?: Maybe<string>;
  nextDueDate?: Maybe<string>;
  paidToDate?: Maybe<string>;
  reference: string;
  taxCategory: string;
  terminationDate?: Maybe<string>;
  nextDueAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
  overdueAmount: { __typename?: "Money"; cents: number };
  entries?: Maybe<{
    __typename?: "PaginatedLiabilityEntries";
    pageInfo: {
      __typename?: "LedgerBidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
    };
    items: Array<
      | ({
          __typename?: "AdjustmentLiabilityEntry";
        } & RentLiabilityEntry_AdjustmentLiabilityEntry_Fragment)
      | ({
          __typename?: "PaymentLiabilityEntry";
        } & RentLiabilityEntry_PaymentLiabilityEntry_Fragment)
      | ({
          __typename?: "PlanBasedLiabilityEntry";
        } & RentLiabilityEntry_PlanBasedLiabilityEntry_Fragment)
    >;
  }>;
};

type RentLiabilityEntry_AdjustmentLiabilityEntry_Fragment = {
  __typename: "AdjustmentLiabilityEntry";
  descriptionIsCustom?: Maybe<boolean>;
  description?: Maybe<string>;
  id: string;
  createdAt: string;
  effectiveAt: string;
  entryType: SourceType;
  amount: { __typename?: "Money"; cents: number };
};

type RentLiabilityEntry_PaymentLiabilityEntry_Fragment = {
  __typename: "PaymentLiabilityEntry";
  id: string;
  createdAt: string;
  effectiveAt: string;
  entryType: SourceType;
  businessTransaction: {
    __typename?: "BusinessTransaction";
    id: string;
    type: BusinessTxType;
    payer?: Maybe<string>;
    status?: Maybe<BusinessTxStatusEnum>;
    estimatedClearDate?: Maybe<string>;
    clearedAt?: Maybe<string>;
    failedAt?: Maybe<string>;
    createdBy?: Maybe<{
      __typename?: "User";
      id: number;
      person: { __typename?: "Person"; id: string; firstName: string };
    }>;
    parent?: Maybe<{
      __typename?: "BusinessTransaction";
      id: string;
      createdAt: string;
    }>;
    children?: Maybe<
      Array<
        Maybe<{
          __typename?: "BusinessTransaction";
          id: string;
          type: BusinessTxType;
          status?: Maybe<BusinessTxStatusEnum>;
        }>
      >
    >;
  };
  amount: { __typename?: "Money"; cents: number };
};

type RentLiabilityEntry_PlanBasedLiabilityEntry_Fragment = {
  __typename: "PlanBasedLiabilityEntry";
  cycleStartDate: string;
  cycleEndDate: string;
  paidToDate: string;
  frequency?: Maybe<LiabilityPlanFrequency>;
  id: string;
  createdAt: string;
  effectiveAt: string;
  entryType: SourceType;
  amount: { __typename?: "Money"; cents: number };
};

export type RentLiabilityEntryFragment =
  | RentLiabilityEntry_AdjustmentLiabilityEntry_Fragment
  | RentLiabilityEntry_PaymentLiabilityEntry_Fragment
  | RentLiabilityEntry_PlanBasedLiabilityEntry_Fragment;

export type FileDetailsFragment = {
  __typename?: "File";
  id: string;
  fileName: string;
  fileSize?: Maybe<number>;
  contentType?: Maybe<string>;
  url?: Maybe<string>;
};

export type AddressFragment = {
  __typename?: "Address";
  unitStreetNumber: string;
  streetName: string;
  suburb?: Maybe<string>;
  state?: Maybe<string>;
  postcode?: Maybe<string>;
  country: string;
};

export type ManagementPropertyFragment = {
  __typename?: "Management";
  id: string;
  property: { __typename?: "Property" } & PropertyIdAndAddressFragment;
};

export type PropertyIdAndAddressFragment = {
  __typename?: "Property";
  id: string;
  address: { __typename?: "Address" } & AddressFragment;
};

export type TenancyPropertyFragment = {
  __typename?: "Tenancy";
  property: { __typename?: "Property" } & PropertyIdAndAddressFragment;
};

export const ReiTokenBaseFragmentDoc = gql`
  fragment ReiTokenBase on ReiToken {
    id
    token
    email
    supportedState
  }
`;
export const TenancyBondClaimFragmentDoc = gql`
  fragment TenancyBondClaim on TenancyBondClaim {
    id
    amount {
      cents
    }
    liabilityAiloRN
    claimOrder
  }
`;
export const AddressFragmentDoc = gql`
  fragment Address on Address {
    unitStreetNumber
    streetName
    suburb
    state
    postcode
    country
  }
`;
export const PropertyIdAndAddressFragmentDoc = gql`
  fragment PropertyIdAndAddress on Property {
    id
    address {
      ...Address
    }
  }
  ${AddressFragmentDoc}
`;
export const ManagementPropertyFragmentDoc = gql`
  fragment ManagementProperty on Management {
    id
    property {
      ...PropertyIdAndAddress
    }
  }
  ${PropertyIdAndAddressFragmentDoc}
`;
export const FeeFieldsFragmentDoc = gql`
  fragment feeFields on Fee {
    id
    ailorn: ailoRN
    type
    amount {
      cents
    }
    taxAmount {
      cents
    }
    percentage
    description
    blueprint {
      id
      ailorn: ailoRN
      name
      fixedAmount {
        cents
      }
      oneWeekRentPercentage
      taxTreatment
    }
    managementFeeBlueprint {
      id
      ailorn
      feeBlueprint {
        id
        ailorn: ailoRN
        name
      }
      fixedAmount {
        cents
      }
      oneWeekRentPercentage
      taxTreatment
    }
    status
    createdAt
    archivedAt
    management {
      id
      ailorn: ailoRN
      ...ManagementProperty
      mostRecentTenancy {
        id
        ailorn: ailoRN
        currentRent {
          id
          dailyRate
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
`;
export const FeeDetailsFragmentDoc = gql`
  fragment feeDetails on Fee {
    ...feeFields
    management {
      managingEntity {
        id
        ailorn: ailoRN
        organisation {
          id
          ailorn: ailoRN
          name
        }
      }
      owners {
        owner {
          __typename
          id
          ... on Person {
            firstName
            lastName
            phoneNo
            emailAddress
          }
          ... on Company {
            registeredEntityName
          }
        }
      }
    }
    liability {
      id
      lastPaymentDate
    }
    taxCategory {
      name
    }
  }
  ${FeeFieldsFragmentDoc}
`;
export const LegalEntityCompanionCacheKeysFragmentFragmentDoc = gql`
  fragment legalEntityCompanionCacheKeysFragment on LegalEntityCompanion {
    id
    ailoRNV2
  }
`;
export const LegalEntityCompanionOrganisationFragmentFragmentDoc = gql`
  fragment legalEntityCompanionOrganisationFragment on LegalEntityCompanion {
    ...legalEntityCompanionCacheKeysFragment
    organisation {
      id
      ailoRN
      name
    }
  }
  ${LegalEntityCompanionCacheKeysFragmentFragmentDoc}
`;
export const FileDetailsFragmentDoc = gql`
  fragment FileDetails on File {
    id
    fileName
    fileSize
    contentType
    url
  }
`;
export const BillDetailsFragmentDoc = gql`
  fragment billDetails on Bill {
    id
    ailorn: ailoRN
    description
    paymentReference {
      id
      crn
      supplierPaymentMethodCompanion {
        paymentMethod {
          __typename
          id
          ... on BPay {
            billerCode
          }
          ... on BankAccount {
            bsb
            accountNumber
            accountName
          }
        }
      }
    }
    amount {
      cents
    }
    archivableByOrganisation
    dueDate: dueDateV2
    agencyStatus
    payee {
      __typename
      ... on Supplier {
        id
        ailorn: ailoRN
        name
      }
      ... on Management {
        id
        ailorn: ailoRN
        managingEntity {
          id
          ailorn: ailoRN
          registeredEntityName
        }
        owners {
          managementId
          owner {
            __typename
            ... on Company {
              id
              ailorn: ailoRN
              registeredEntityName
            }
            ... on Person {
              id
              ailorn: ailoRN
              firstName
              lastName
            }
          }
        }
      }
      ... on LegalEntityCompanion {
        ...legalEntityCompanionOrganisationFragment
      }
    }
    payer {
      __typename
      ... on Tenancy {
        tenantships {
          items {
            tenant {
              __typename
              id
              ... on Person {
                firstName
                lastName
                phoneNo
                emailAddress
              }
              ... on Company {
                registeredEntityName
              }
            }
          }
        }
      }
      ... on Management {
        ownerships {
          items {
            owner {
              __typename
              id
              ... on Person {
                firstName
                lastName
                phoneNo
                emailAddress
              }
              ... on Company {
                registeredEntityName
              }
            }
          }
        }
      }
    }
    attachments {
      id
      file {
        ...FileDetails
      }
    }
    lineItems {
      items {
        ailorn: ailoRN
        id
        taxInclusiveAmount {
          cents
        }
        taxAmount {
          cents
        }
        description
        isTax
      }
    }
    invoiceNumber
    createdAt
    issueDate: issueDateV2
    liabilityState {
      paidAt
    }
    organisationArchiveReason
    taxCategory {
      name
    }
    applyManagementFee
    management {
      id
      currentOrNextManagementFeeSchedule {
        id
        percent
      }
      ...ManagementProperty
    }
  }
  ${LegalEntityCompanionOrganisationFragmentFragmentDoc}
  ${FileDetailsFragmentDoc}
  ${ManagementPropertyFragmentDoc}
`;
export const UpcomingAndPaidBillFieldsFragmentDoc = gql`
  fragment upcomingAndPaidBillFields on Bill {
    id
    ailorn: ailoRN
    amount {
      cents
    }
    status
    agencyStatus
    dueDateV2
    issueDateV2
    supplier {
      ailoRN
      name
      internalReference
      internalLegalEntity {
        ...legalEntityCompanionOrganisationFragment
      }
    }
    taxCategory {
      id
      name
    }
    liabilityState {
      payByDateV2
      paymentStatus
      paidAt
      dueAmount {
        cents
      }
    }
  }
  ${LegalEntityCompanionOrganisationFragmentFragmentDoc}
`;
export const UpcomingAndPaidFeeFieldsFragmentDoc = gql`
  fragment upcomingAndPaidFeeFields on Fee {
    ...feeFields
    taxCategory {
      id
      name
    }
    management {
      managingEntity {
        id
        ailoRN
        organisation {
          id
          ailoRN
          name
        }
      }
    }
  }
  ${FeeFieldsFragmentDoc}
`;
export const ManagementFeeScheduleFieldsFragmentDoc = gql`
  fragment managementFeeScheduleFields on ManagementFeeSchedule {
    id
    ailorn: ailoRN
    percent
    managementAgreement {
      id
      ailorn: ailoRN
    }
    management {
      id
      ailorn: ailoRN
      ...ManagementProperty
    }
  }
  ${ManagementPropertyFragmentDoc}
`;
export const RecurringFeeFieldsFragmentDoc = gql`
  fragment recurringFeeFields on RecurringFee {
    id: idV2
    ailorn: ailoRN
    name
    startDate
    frequency
    taxTreatment
    blueprint: blueprintV2 {
      id
      ailorn: ailoRN
      name
      fixedAmount {
        cents
      }
      taxTreatment
    }
    nextOccurrence {
      date
      taxInclusiveAmount {
        cents
      }
    }
    currentOrNextSchedule {
      id
      taxInclusiveAmount {
        cents
      }
      startDate
    }
    description
    management {
      id
      ailorn: ailoRN
      ...ManagementProperty
    }
  }
  ${ManagementPropertyFragmentDoc}
`;
export const PendingInspectionAppointmentFragmentDoc = gql`
  fragment PendingInspectionAppointment on InspectionAppointment {
    id
    startTime
    property {
      ...PropertyIdAndAddress
    }
  }
  ${PropertyIdAndAddressFragmentDoc}
`;
export const PropertyOnboardingListActionInitiatorFragmentDoc = gql`
  fragment PropertyOnboardingListActionInitiator on ActionInitiator {
    ... on User {
      id
      person {
        firstName
        lastName
      }
    }
  }
`;
export const ApprovedMigratingManagementFragmentDoc = gql`
  fragment ApprovedMigratingManagement on MigratingManagement {
    id
    body
    portfolioName
    statusChangedAt
    approvedBy {
      ...PropertyOnboardingListActionInitiator
    }
    approvedByDisplay
    approvedAt
  }
  ${PropertyOnboardingListActionInitiatorFragmentDoc}
`;
export const AwaitingApprovalMigratingManagementFragmentDoc = gql`
  fragment AwaitingApprovalMigratingManagement on MigratingManagement {
    id
    body
    portfolioName
    errors
  }
`;
export const ExcludedMigratingManagementFragmentDoc = gql`
  fragment ExcludedMigratingManagement on MigratingManagement {
    id
    body
    portfolioName
    statusChangedAt
    statusChangedBy {
      ...PropertyOnboardingListActionInitiator
    }
    exclusionReason
  }
  ${PropertyOnboardingListActionInitiatorFragmentDoc}
`;
export const LiveMigratingManagementFragmentDoc = gql`
  fragment LiveMigratingManagement on MigratingManagement {
    id
    body
    portfolioName
    statusChangedAt
    approvedBy {
      ...PropertyOnboardingListActionInitiator
    }
    approvedByDisplay
    ailoManagement {
      id
    }
  }
  ${PropertyOnboardingListActionInitiatorFragmentDoc}
`;
export const PersonNamesFragmentDoc = gql`
  fragment PersonNames on Person {
    id
    ailoRN
    __typename
    firstName
    legalFirstName
    legalMiddleName
    preferredName
    lastName
  }
`;
export const PersonDisplayDetailsFragmentDoc = gql`
  fragment PersonDisplayDetails on Person {
    photo {
      id
      url
      thumbnailUrl
    }
    ...PersonNames
  }
  ${PersonNamesFragmentDoc}
`;
export const ProjectAssigneeInfoFragmentDoc = gql`
  fragment ProjectAssigneeInfo on Project {
    id
    assignee {
      ...PersonNames
      ...PersonDisplayDetails
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonDisplayDetailsFragmentDoc}
`;
export const ProjectFragmentDoc = gql`
  fragment Project on Project {
    id
    name
    type
    description
    dueDate
    status
    reference
    createdAt
    ...ProjectAssigneeInfo
  }
  ${ProjectAssigneeInfoFragmentDoc}
`;
export const ProjectFileFragmentDoc = gql`
  fragment ProjectFile on ProjectFile {
    id
    file {
      id
      url
      thumbnailUrl
      thumbnailStatus
      contentType
      fileName
      fileSize
    }
  }
`;
export const ProjectFilesFragmentDoc = gql`
  fragment ProjectFiles on Project {
    files(cursor: { pageSize: 1000 }) {
      items {
        ...ProjectFile
      }
      pageInfo {
        total
      }
    }
  }
  ${ProjectFileFragmentDoc}
`;
export const OrganisationMembersDetailsFragmentDoc = gql`
  fragment OrganisationMembersDetails on Organisation {
    id
    members(pageCursor: { pageSize: 10000 }) {
      items {
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;
export const ProjectOrganisationFragmentDoc = gql`
  fragment ProjectOrganisation on Project {
    organisation {
      ...OrganisationMembersDetails
    }
  }
  ${OrganisationMembersDetailsFragmentDoc}
`;
export const ActionFragmentDoc = gql`
  fragment Action on Action {
    id
    name
    description
    type
    typeLabel
    status
    createdAt
    dueDate
    doneAt
  }
`;
export const AssignTenancyAgreementActionMetaFragmentDoc = gql`
  fragment AssignTenancyAgreementActionMeta on AssignTenancyAgreementActionMeta {
    tenancyAgreement {
      id
      startDate
      fixedTermEndDate
    }
  }
`;
export const RentFragmentDoc = gql`
  fragment Rent on Rent {
    id
    amountInCents
    dailyRate
    period
    effectiveDate
  }
`;
export const EditableRentFragmentDoc = gql`
  fragment EditableRent on Rent {
    ...Rent
    previousRent {
      ...Rent
    }
  }
  ${RentFragmentDoc}
`;
export const AssignRentActionMetaFragmentDoc = gql`
  fragment AssignRentActionMeta on AssignRentActionMeta {
    rent {
      ...EditableRent
      tenancy {
        id
        scheduledRentReviewDate
      }
    }
  }
  ${EditableRentFragmentDoc}
`;
export const TenancyVoidedAtFragmentDoc = gql`
  fragment TenancyVoidedAt on Tenancy {
    id
    voidedAt
  }
`;
export const AssignTenancyActionMetaFragmentDoc = gql`
  fragment AssignTenancyActionMeta on AssignTenancyActionMeta {
    tenancy {
      id
      ...TenancyVoidedAt
    }
  }
  ${TenancyVoidedAtFragmentDoc}
`;
export const AssignNewManagementActionMetaFragmentDoc = gql`
  fragment AssignNewManagementActionMeta on AssignNewManagementActionMeta {
    management {
      id
    }
  }
`;
export const AssignFormActionMetaFragmentDoc = gql`
  fragment AssignFormActionMeta on AssignFormActionMeta {
    form {
      id
    }
  }
`;
export const RequestSignatureActionMetaFragmentDoc = gql`
  fragment RequestSignatureActionMeta on RequestSignatureActionMeta {
    form {
      id
    }
  }
`;
export const TrackDocusignActionMetaFragmentDoc = gql`
  fragment TrackDocusignActionMeta on TrackDocusignActionMeta {
    form {
      id
    }
  }
`;
export const ManageDepositActionMetaFragmentDoc = gql`
  fragment ManageDepositActionMeta on ManageDepositActionMeta {
    deposit {
      id
    }
  }
`;
export const InspectionAreaFragmentDoc = gql`
  fragment InspectionArea on InspectionArea {
    id
    name
    inspected
  }
`;
export const InspectionReportStateFragmentDoc = gql`
  fragment InspectionReportState on Inspection {
    id
    reportGenerationState {
      canGenerateReport
    }
  }
`;
export const InspectionLatestReportProgressFragmentDoc = gql`
  fragment InspectionLatestReportProgress on Inspection {
    id
    latestReportProgress {
      id
      completedAt
      failedAt
    }
  }
`;
export const InspectionFragmentDoc = gql`
  fragment Inspection on Inspection {
    id
    ailorn
    type
    startedAt
    completedAt
    inspectingAgent {
      ...PersonDisplayDetails
    }
    areas {
      ...InspectionArea
    }
    areaFilesForThumbnail {
      id
      file {
        id
        thumbnailUrl
      }
    }
    featureFilesForThumbnail {
      id
      file {
        id
        thumbnailUrl
      }
    }
    ...InspectionReportState
    ...InspectionLatestReportProgress
  }
  ${PersonDisplayDetailsFragmentDoc}
  ${InspectionAreaFragmentDoc}
  ${InspectionReportStateFragmentDoc}
  ${InspectionLatestReportProgressFragmentDoc}
`;
export const InspectionAppointmentFragmentDoc = gql`
  fragment InspectionAppointment on InspectionAppointment {
    id
    type
    startTime
    inspection {
      ...Inspection
    }
    inspectingAgent {
      ...PersonDisplayDetails
    }
  }
  ${InspectionFragmentDoc}
  ${PersonDisplayDetailsFragmentDoc}
`;
export const GenerateInspectionReportActionMetaFragmentDoc = gql`
  fragment GenerateInspectionReportActionMeta on GenerateInspectionReportActionMeta {
    inspectionAppointment {
      ...InspectionAppointment
    }
  }
  ${InspectionAppointmentFragmentDoc}
`;
export const TrackInspectionCompletedActionMetaFragmentDoc = gql`
  fragment TrackInspectionCompletedActionMeta on TrackInspectionCompletedActionMeta {
    inspectionAppointment {
      ...InspectionAppointment
    }
  }
  ${InspectionAppointmentFragmentDoc}
`;
export const CanCancelVacateFragmentDoc = gql`
  fragment CanCancelVacate on Tenancy {
    id
    canCancelVacate {
      ableToCancel
      notAbleToCancelReason
    }
  }
`;
export const EndTenancyDetailsFragmentDoc = gql`
  fragment EndTenancyDetails on Tenancy {
    id
    endDate
    vacatingReason
    vacatingNotes
    ...CanCancelVacate
  }
  ${CanCancelVacateFragmentDoc}
`;
export const EndTenancyActionMetaFragmentDoc = gql`
  fragment EndTenancyActionMeta on EndTenancyActionMeta {
    tenancy {
      ...EndTenancyDetails
    }
  }
  ${EndTenancyDetailsFragmentDoc}
`;
export const TenancyInspectionScheduleFragmentDoc = gql`
  fragment TenancyInspectionSchedule on Tenancy {
    id
    routineInspectionDueDate
    management {
      id
      currentOrNextManagementAgreement {
        id
        inspectionFrequency
      }
    }
  }
`;
export const ConfirmInspectionScheduleActionMetaFragmentDoc = gql`
  fragment ConfirmInspectionScheduleActionMeta on ConfirmInspectionScheduleActionMeta {
    tenancy {
      ...TenancyInspectionSchedule
    }
    inspectionAppointment {
      ...InspectionAppointment
    }
  }
  ${TenancyInspectionScheduleFragmentDoc}
  ${InspectionAppointmentFragmentDoc}
`;
export const AssignMessageActionMetaFragmentDoc = gql`
  fragment AssignMessageActionMeta on AssignMessageActionMeta {
    subjectTemplate
    messageTemplate
  }
`;
export const LegalEntityNameFragmentDoc = gql`
  fragment legalEntityName on LegalEntity {
    id
    __typename
    ... on Person {
      firstName
      lastName
    }
    ... on Company {
      registeredEntityName
    }
  }
`;
export const TenancyPropertyFragmentDoc = gql`
  fragment TenancyProperty on Tenancy {
    property {
      ...PropertyIdAndAddress
    }
  }
  ${PropertyIdAndAddressFragmentDoc}
`;
export const FormSidebarTenancyDetailsFragmentDoc = gql`
  fragment FormSidebarTenancyDetails on Tenancy {
    id
    displayRent {
      id
      amountInCents
      period
    }
    liability {
      effectivePaidToDate
    }
    management {
      id
      owners {
        owner {
          ...legalEntityName
        }
      }
    }
    ...TenancyProperty
    tenantships {
      items {
        tenant {
          ...legalEntityName
        }
      }
    }
  }
  ${LegalEntityNameFragmentDoc}
  ${TenancyPropertyFragmentDoc}
`;
export const ProjectManagementOwnershipsFragmentDoc = gql`
  fragment ProjectManagementOwnerships on Management {
    ownerships(pageCursor: { pageSize: 1000 }) {
      items {
        owner {
          id
          ... on Company {
            registeredEntityName
          }
          ... on Person {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
export const ProjectMangementTenanciesFragmentDoc = gql`
  fragment ProjectMangementTenancies on Management {
    tenancies {
      items {
        id
        tenantships(pageCursor: { pageSize: 1000 }) {
          items {
            tenant {
              id
              ... on Company {
                registeredEntityName
              }
              ... on Person {
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;
export const ProjectManagementFragmentDoc = gql`
  fragment ProjectManagement on Management {
    id
    ...ManagementProperty
    currentTenancy {
      ...FormSidebarTenancyDetails
    }
    ...ProjectManagementOwnerships
    ...ProjectMangementTenancies
  }
  ${ManagementPropertyFragmentDoc}
  ${FormSidebarTenancyDetailsFragmentDoc}
  ${ProjectManagementOwnershipsFragmentDoc}
  ${ProjectMangementTenanciesFragmentDoc}
`;
export const LeaseRenewalProjectMetaFragmentDoc = gql`
  fragment LeaseRenewalProjectMeta on LeaseRenewalProjectMeta {
    leaseRenewalTenancy: tenancy {
      id
      ailoRN
      leaseReviewAllowedOperation
      rentReviewAllowed
      editableRentReview {
        rent {
          ...EditableRent
        }
      }
    }
    leaseRenewalManagement: management {
      ...ProjectManagement
    }
  }
  ${EditableRentFragmentDoc}
  ${ProjectManagementFragmentDoc}
`;
export const TenancyFragmentDoc = gql`
  fragment Tenancy on Tenancy {
    id
    ailoRN
    startDate
    endDate
    voidedAt
    property {
      id
      ailorn
    }
  }
`;
export const TenancyDepositDetailsFragmentDoc = gql`
  fragment TenancyDepositDetails on TenancyDeposit {
    id
    ailoRN
    status
    amount {
      cents
    }
    createdAt
    paidAt
    liability {
      entries(filterTypes: PAYMENT) {
        items {
          ... on PaymentLiabilityEntry {
            businessTransaction {
              expectedClearedAt
              clearedAt
            }
          }
        }
      }
    }
  }
`;
export const NewTenancyProjectMetaFragmentDoc = gql`
  fragment NewTenancyProjectMeta on NewTenancyProjectMeta {
    newTenancy: tenancy {
      id
      ...Tenancy
      deposit {
        id
        ...TenancyDepositDetails
      }
    }
    newTenancyManagement: management {
      ...ProjectManagement
    }
  }
  ${TenancyFragmentDoc}
  ${TenancyDepositDetailsFragmentDoc}
  ${ProjectManagementFragmentDoc}
`;
export const GeneralProjectMetaFragmentDoc = gql`
  fragment GeneralProjectMeta on GeneralProjectMeta {
    generalManagement: management {
      ...ProjectManagement
    }
  }
  ${ProjectManagementFragmentDoc}
`;
export const NewManagementProjectMetaFragmentDoc = gql`
  fragment NewManagementProjectMeta on NewManagementProjectMeta {
    newManagement: management {
      ...ProjectManagement
    }
  }
  ${ProjectManagementFragmentDoc}
`;
export const VacatingTenancyProjectMetaFragmentDoc = gql`
  fragment VacatingTenancyProjectMeta on VacatingTenancyProjectMeta {
    vacatingTenancyManagement: management {
      ...ProjectManagement
    }
  }
  ${ProjectManagementFragmentDoc}
`;
export const RoutineInspectionProjectMetaFragmentDoc = gql`
  fragment RoutineInspectionProjectMeta on RoutineInspectionProjectMeta {
    routineInspectionManagement: management {
      ...ProjectManagement
    }
    tenancy {
      id
      ailoRN
    }
  }
  ${ProjectManagementFragmentDoc}
`;
export const ProjectMetaFragmentDoc = gql`
  fragment ProjectMeta on Project {
    id
    meta {
      ...LeaseRenewalProjectMeta
      ...NewTenancyProjectMeta
      ...GeneralProjectMeta
      ...NewManagementProjectMeta
      ...VacatingTenancyProjectMeta
      ...RoutineInspectionProjectMeta
    }
  }
  ${LeaseRenewalProjectMetaFragmentDoc}
  ${NewTenancyProjectMetaFragmentDoc}
  ${GeneralProjectMetaFragmentDoc}
  ${NewManagementProjectMetaFragmentDoc}
  ${VacatingTenancyProjectMetaFragmentDoc}
  ${RoutineInspectionProjectMetaFragmentDoc}
`;
export const ActionDetailsFragmentDoc = gql`
  fragment ActionDetails on Action {
    id
    ...Action
    assignee {
      id
      firstName
      lastName
    }
    meta {
      ...AssignTenancyAgreementActionMeta
      ...AssignRentActionMeta
      ...AssignTenancyActionMeta
      ...AssignNewManagementActionMeta
      ...AssignFormActionMeta
      ...RequestSignatureActionMeta
      ...TrackDocusignActionMeta
      ...ManageDepositActionMeta
      ...GenerateInspectionReportActionMeta
      ...TrackInspectionCompletedActionMeta
      ...EndTenancyActionMeta
      ...ConfirmInspectionScheduleActionMeta
      ...AssignMessageActionMeta
    }
    project {
      ...Project
      ...ProjectMeta
    }
  }
  ${ActionFragmentDoc}
  ${AssignTenancyAgreementActionMetaFragmentDoc}
  ${AssignRentActionMetaFragmentDoc}
  ${AssignTenancyActionMetaFragmentDoc}
  ${AssignNewManagementActionMetaFragmentDoc}
  ${AssignFormActionMetaFragmentDoc}
  ${RequestSignatureActionMetaFragmentDoc}
  ${TrackDocusignActionMetaFragmentDoc}
  ${ManageDepositActionMetaFragmentDoc}
  ${GenerateInspectionReportActionMetaFragmentDoc}
  ${TrackInspectionCompletedActionMetaFragmentDoc}
  ${EndTenancyActionMetaFragmentDoc}
  ${ConfirmInspectionScheduleActionMetaFragmentDoc}
  ${AssignMessageActionMetaFragmentDoc}
  ${ProjectFragmentDoc}
  ${ProjectMetaFragmentDoc}
`;
export const ProjectActionsFragmentDoc = gql`
  fragment ProjectActions on Project {
    actions {
      items {
        ...ActionDetails
      }
      pageInfo {
        total
      }
    }
  }
  ${ActionDetailsFragmentDoc}
`;
export const ProjectDetailsFragmentDoc = gql`
  fragment ProjectDetails on Project {
    ...Project
    ...ProjectFiles
    ...ProjectOrganisation
    ...ProjectActions
    ...ProjectMeta
    ...ProjectAssigneeInfo
  }
  ${ProjectFragmentDoc}
  ${ProjectFilesFragmentDoc}
  ${ProjectOrganisationFragmentDoc}
  ${ProjectActionsFragmentDoc}
  ${ProjectMetaFragmentDoc}
  ${ProjectAssigneeInfoFragmentDoc}
`;
export const ProjectManagementWithAddressFragmentDoc = gql`
  fragment ProjectManagementWithAddress on Management {
    id
    ...ManagementProperty
  }
  ${ManagementPropertyFragmentDoc}
`;
export const LeaseRenewalProjectAddressFragmentDoc = gql`
  fragment LeaseRenewalProjectAddress on LeaseRenewalProjectMeta {
    leaseRenewalManagement: management {
      ...ProjectManagementWithAddress
    }
  }
  ${ProjectManagementWithAddressFragmentDoc}
`;
export const NewTenancyProjectAddressFragmentDoc = gql`
  fragment NewTenancyProjectAddress on NewTenancyProjectMeta {
    newTenancyManagement: management {
      ...ProjectManagementWithAddress
    }
  }
  ${ProjectManagementWithAddressFragmentDoc}
`;
export const GeneralProjectAddressFragmentDoc = gql`
  fragment GeneralProjectAddress on GeneralProjectMeta {
    generalManagement: management {
      ...ProjectManagementWithAddress
    }
  }
  ${ProjectManagementWithAddressFragmentDoc}
`;
export const NewManagementProjectAddressFragmentDoc = gql`
  fragment NewManagementProjectAddress on NewManagementProjectMeta {
    newManagement: management {
      ...ProjectManagementWithAddress
    }
  }
  ${ProjectManagementWithAddressFragmentDoc}
`;
export const VacatingTenancyProjectAddressFragmentDoc = gql`
  fragment VacatingTenancyProjectAddress on VacatingTenancyProjectMeta {
    vacatingTenancyManagement: management {
      ...ProjectManagementWithAddress
    }
  }
  ${ProjectManagementWithAddressFragmentDoc}
`;
export const RoutineInspectionProjectAddressFragmentDoc = gql`
  fragment RoutineInspectionProjectAddress on RoutineInspectionProjectMeta {
    routineInspectionManagement: management {
      ...ProjectManagementWithAddress
    }
  }
  ${ProjectManagementWithAddressFragmentDoc}
`;
export const ProjectWithAddressFragmentDoc = gql`
  fragment ProjectWithAddress on Project {
    id
    meta {
      ...LeaseRenewalProjectAddress
      ...NewTenancyProjectAddress
      ...GeneralProjectAddress
      ...NewManagementProjectAddress
      ...VacatingTenancyProjectAddress
      ...RoutineInspectionProjectAddress
    }
    ...Project
  }
  ${LeaseRenewalProjectAddressFragmentDoc}
  ${NewTenancyProjectAddressFragmentDoc}
  ${GeneralProjectAddressFragmentDoc}
  ${NewManagementProjectAddressFragmentDoc}
  ${VacatingTenancyProjectAddressFragmentDoc}
  ${RoutineInspectionProjectAddressFragmentDoc}
  ${ProjectFragmentDoc}
`;
export const ProjectListRowFragmentDoc = gql`
  fragment ProjectListRow on Project {
    id
    name
    description
    type
    dueDate
    assignee {
      id
      ...PersonDisplayDetails
    }
    closedAt
    status
    reference
    actions {
      items {
        id
        status
      }
      pageInfo {
        total
      }
    }
    createdAt
    ...ProjectWithAddress
  }
  ${PersonDisplayDetailsFragmentDoc}
  ${ProjectWithAddressFragmentDoc}
`;
export const ProjectPageInfoFragmentDoc = gql`
  fragment ProjectPageInfo on BidirectionalPageInfo {
    total
    hasNext
    hasPrevious
    nextCursor
    previousCursor
  }
`;
export const TenancyWithTenancyDepositDetailsFragmentDoc = gql`
  fragment TenancyWithTenancyDepositDetails on Tenancy {
    id
    startDate
    deposit {
      ...TenancyDepositDetails
    }
  }
  ${TenancyDepositDetailsFragmentDoc}
`;
export const ManagementEndDetailsFragmentDoc = gql`
  fragment ManagementEndDetails on Management {
    id
    endDate
    endAbility {
      canBeEnded
      problem
    }
    endReason {
      code
      label
      causes {
        code
        label
      }
    }
    endNote
  }
`;
export const CompanyDisplayDetailsFragmentDoc = gql`
  fragment CompanyDisplayDetails on Company {
    registeredEntityName
  }
`;
export const OwnerFragmentDoc = gql`
  fragment Owner on Ownership {
    owner {
      __typename
      id
      ...PersonDisplayDetails
      ...CompanyDisplayDetails
      ... on Person {
        id
        phoneNo
        emailAddress
        birthDate
        user {
          id
          onboardingCompletedAt
        }
      }
      ... on Company {
        id
        registeredEntityId
      }
    }
  }
  ${PersonDisplayDetailsFragmentDoc}
  ${CompanyDisplayDetailsFragmentDoc}
`;
export const ManagementOwnershipsFragmentDoc = gql`
  fragment ManagementOwnerships on Management {
    id
    ownerships {
      items {
        ...Owner
        owner {
          contact(organisationAilorn: $organisationAilorn) {
            ailorn
          }
        }
      }
    }
  }
  ${OwnerFragmentDoc}
`;
export const ProjectItemDetailsFragmentDoc = gql`
  fragment ProjectItemDetails on Project {
    id
    name
    status
    reference
    type
  }
`;
export const AgencyPropertyAddressFragmentDoc = gql`
  fragment AgencyPropertyAddress on AgencyProperty {
    id
    address {
      ...Address
    }
  }
  ${AddressFragmentDoc}
`;
export const AgencyPropertyFragmentDoc = gql`
  fragment AgencyProperty on AgencyProperty {
    id
    ...AgencyPropertyAddress
    mostRelevantManagement {
      id
    }
  }
  ${AgencyPropertyAddressFragmentDoc}
`;
export const EndingManagementDetailsFragmentDoc = gql`
  fragment EndingManagementDetails on Management {
    id
    startDate
    fixedTermEndDate
    property {
      ...PropertyIdAndAddress
    }
    tenancies {
      items {
        ...Tenancy
      }
    }
    owners {
      owner {
        ...legalEntityName
      }
    }
  }
  ${PropertyIdAndAddressFragmentDoc}
  ${TenancyFragmentDoc}
  ${LegalEntityNameFragmentDoc}
`;
export const FeeBlueprintFragmentDoc = gql`
  fragment feeBlueprint on FeeBlueprint {
    id
    ailoRN
    name
    taxCategory {
      id
      name
    }
    type
    chargeType
    taxTreatment
    frequency
    anniversaryDay
    anniversaryMonth
    fixedAmount {
      cents
    }
    oneWeekRentPercentage
    archived
  }
`;
export const TenantInfoFragmentDoc = gql`
  fragment tenantInfo on LegalEntity {
    __typename
    id
    ... on Person {
      firstName
      lastName
      photo {
        id
        url
        thumbnailUrl
      }
      phoneNo
      emailAddress
      user {
        id
        onboardingCompletedAt
      }
    }
    ... on Company {
      registeredEntityName
      registeredEntityId
    }
    contact(organisationAilorn: $organisationAilorn) {
      ailorn
    }
  }
`;
export const TenancyBondFragmentDoc = gql`
  fragment tenancyBond on TenancyBond {
    id
    amount {
      cents
    }
    reference
    status
    failureReason
    createdAt
    modifiedAt
  }
`;
export const ManagementFragmentDoc = gql`
  fragment Management on Management {
    id
    ailoRN
    startDate
    endDate
  }
`;
export const ManagementAgreementFragmentDoc = gql`
  fragment ManagementAgreement on ManagementAgreement {
    id
    startDate
    fixedTermEndDate
    inspectionFrequency
  }
`;
export const ManagementEndCauseFragmentDoc = gql`
  fragment ManagementEndCause on ManagementEndCause {
    code
    label
  }
`;
export const ManagementEndReasonFragmentDoc = gql`
  fragment ManagementEndReason on ManagementEndReason {
    code
    label
    causes {
      ...ManagementEndCause
    }
  }
  ${ManagementEndCauseFragmentDoc}
`;
export const FormerOrVoidedTenancyFragmentDoc = gql`
  fragment FormerOrVoidedTenancy on Tenancy {
    id
    endDate
    voidedAt
    liability {
      id
      reference
      terminationAmountDue {
        cents
      }
    }
    payerBillsSummary {
      outstandingBillsBalance {
        cents
      }
    }
    tenantships(pageCursor: { pageSize: 20 }) {
      items {
        tenant {
          __typename
          id
          ... on Person {
            firstName
            lastName
            phoneNo
            emailAddress
          }
          ... on Company {
            registeredEntityName
            registeredEntityId
          }
        }
      }
    }
  }
`;
export const ManagementFormerAndVoidedTenanciesFragmentDoc = gql`
  fragment ManagementFormerAndVoidedTenancies on Management {
    id
    formerTenancies(pageCursor: { pageSize: 20 }) {
      items {
        ...FormerOrVoidedTenancy
      }
    }
    voidedTenancies(pageCursor: { pageSize: 20 }) {
      items {
        ...FormerOrVoidedTenancy
      }
    }
  }
  ${FormerOrVoidedTenancyFragmentDoc}
`;
export const ManagementNoteFragmentDoc = gql`
  fragment managementNote on ManagementNote {
    id
    ailoRN
    management {
      id
    }
    note
  }
`;
export const KeyFragmentDoc = gql`
  fragment Key on Key {
    id
    code
    description
    property {
      ...PropertyIdAndAddress
    }
  }
  ${PropertyIdAndAddressFragmentDoc}
`;
export const PropertyKeysFragmentDoc = gql`
  fragment PropertyKeys on Property {
    id
    keys(filter: { organisationAilorn: { eq: $organisationAilorn } }) {
      ...Key
    }
  }
  ${KeyFragmentDoc}
`;
export const SearchTenanciesResultFragmentDoc = gql`
  fragment SearchTenanciesResult on Tenancy {
    id
    property {
      ...PropertyIdAndAddress
    }
    lastCompletedRoutineInspection {
      id
      completedAt
    }
    routineInspectionDueDate
  }
  ${PropertyIdAndAddressFragmentDoc}
`;
export const TenancyIsVoidableFragmentDoc = gql`
  fragment TenancyIsVoidable on Tenancy {
    id
    isVoidable {
      voidable
      unvoidableReason
    }
  }
`;
export const TenancyLiabilityFragmentDoc = gql`
  fragment TenancyLiability on Tenancy {
    id
    liability {
      id
      reference
      terminationAmountDue {
        cents
      }
    }
  }
`;
export const NextRentFragmentDoc = gql`
  fragment nextRent on Tenancy {
    nextRentSchedule {
      id
      amountInCents
      period
      startDate
    }
  }
`;
export const NextRentReviewFragmentDoc = gql`
  fragment nextRentReview on Tenancy {
    nextRentReview {
      id
      amountInCents
      period
      effectiveDate
    }
  }
`;
export const UpcomingRentChangesFragmentDoc = gql`
  fragment upcomingRentChanges on Tenancy {
    ...nextRent
    ...nextRentReview
    scheduledRentReviewDate
  }
  ${NextRentFragmentDoc}
  ${NextRentReviewFragmentDoc}
`;
export const TenancyAgreementFragmentDoc = gql`
  fragment TenancyAgreement on TenancyAgreement {
    id
    startDate
    fixedTermEndDate
    allowedToLapseAt
  }
`;
export const UpcomingTenancyAgreementFragmentDoc = gql`
  fragment upcomingTenancyAgreement on Tenancy {
    nextTenancyAgreement {
      ...TenancyAgreement
    }
  }
  ${TenancyAgreementFragmentDoc}
`;
export const ProRataPreviewFragmentDoc = gql`
  fragment proRataPreview on PreviewedRentSchedule {
    amountInCents
    period
    startDate
    endDate
  }
`;
export const BillPayerNamesFragmentDoc = gql`
  fragment billPayerNames on BillPayer {
    __typename
    ... on Tenancy {
      tenantships {
        items {
          tenant {
            __typename
            id
            ... on Person {
              firstName
              lastName
            }
            ... on Company {
              registeredEntityName
            }
          }
        }
      }
    }
    ... on Management {
      ownerships {
        items {
          owner {
            __typename
            id
            ... on Person {
              firstName
              lastName
            }
            ... on Company {
              registeredEntityName
            }
          }
        }
      }
    }
    ... on LegalEntityCompanion {
      id
      ailoRNV2
    }
  }
`;
export const MigratingManagementSidebarFragmentDoc = gql`
  fragment MigratingManagementSidebar on MigratingManagement {
    id
    status
    publishStatus
    statusChangedAt
    statusChangedBy {
      ...PropertyOnboardingListActionInitiator
    }
    exclusionReason
    exclusionNotes
    body
    errors
    hasVacatingTenancy
    migrateAsVacant
  }
  ${PropertyOnboardingListActionInitiatorFragmentDoc}
`;
export const OwnerWithSharesFragmentDoc = gql`
  fragment OwnerWithShares on Ownership {
    ...Owner
    sharesOwned
    totalShares
  }
  ${OwnerFragmentDoc}
`;
export const OwnershipItemsFragmentDoc = gql`
  fragment OwnershipItems on PaginatedOwnerships {
    items {
      ...OwnerWithShares
    }
  }
  ${OwnerWithSharesFragmentDoc}
`;
export const TenantFragmentDoc = gql`
  fragment Tenant on Tenantship {
    tenant {
      __typename
      id
      ... on Person {
        legalFirstName
        lastName
        preferredName
        legalMiddleName
        phoneNo
        emailAddress
        birthDate
        photo {
          url
          thumbnailUrl
        }
      }
    }
  }
`;
export const TenantShipItemsFragmentDoc = gql`
  fragment TenantShipItems on PaginatedTenantships {
    items {
      ...Tenant
    }
  }
  ${TenantFragmentDoc}
`;
export const InspectionAppointmentDetailsFragmentDoc = gql`
  fragment InspectionAppointmentDetails on InspectionAppointment {
    id
    ailorn
  }
`;
export const ReiFormRemoteSignerFragmentDoc = gql`
  fragment ReiFormRemoteSigner on ReiFormRemoteSigner {
    name
    status
    signed
  }
`;
export const ReiFormRemoteSigningStatusFragmentDoc = gql`
  fragment ReiFormRemoteSigningStatus on ReiFormRemoteSigningStatus {
    status
    sent
    recipients {
      ...ReiFormRemoteSigner
    }
  }
  ${ReiFormRemoteSignerFragmentDoc}
`;
export const DisplayRentFragmentDoc = gql`
  fragment DisplayRent on Tenancy {
    displayRent {
      ...Rent
    }
  }
  ${RentFragmentDoc}
`;
export const TenancyInArrearsFromOwingFragmentDoc = gql`
  fragment TenancyInArrearsFromOwing on Tenancy {
    id
    ...DisplayRent
    rent {
      id
      progress {
        arrears {
          amount {
            total {
              cents
            }
          }
          date
        }
        firstPaidAt
        paidTo {
          effective {
            date
          }
        }
      }
    }
    ...TenancyProperty
  }
  ${DisplayRentFragmentDoc}
  ${TenancyPropertyFragmentDoc}
`;
export const TenancyInArrearsFragmentDoc = gql`
  fragment TenancyInArrears on Tenancy {
    id
    ...DisplayRent
    liability {
      reference
      effectivePaidToDate
      firstPaymentDate
      amountInArrears {
        cents
      }
      daysInArrears
    }
    ...TenancyProperty
  }
  ${DisplayRentFragmentDoc}
  ${TenancyPropertyFragmentDoc}
`;
export const PaymentMethodDetailsFragmentDoc = gql`
  fragment PaymentMethodDetails on PaymentMethod {
    __typename
    id
  }
`;
export const ManagementFilesFragmentDoc = gql`
  fragment managementFiles on Management {
    files(pageCursor: { pageSize: 1000 }) {
      pageInfo {
        total
      }
      items {
        id
        fileName
        fileSize
        url
        contentType
      }
    }
  }
`;
export const InspectionSectionInfoFragmentDoc = gql`
  fragment InspectionSectionInfo on Tenancy {
    id
    lastCompletedRoutineInspection {
      id
      completedAt
    }
    ...TenancyInspectionSchedule
  }
  ${TenancyInspectionScheduleFragmentDoc}
`;
export const TenancyFilesFragmentDoc = gql`
  fragment tenancyFiles on Tenancy {
    files(pageCursor: { pageSize: 1000 }) {
      pageInfo {
        total
      }
      items {
        id
        fileName
        fileSize
        url
        contentType
      }
    }
  }
`;
export const AdjustmentLiabilityEntryFragmentDoc = gql`
  fragment adjustmentLiabilityEntry on AdjustmentLiabilityEntry {
    id
    createdBy {
      ... on User {
        ailoRN
      }
      ... on System {
        ailoRN
      }
    }
    amount {
      cents
    }
    createdAt
    description
    effectiveAt
    descriptionIsCustom
    reversedByAdjustment {
      id
      effectiveAt
      description
      descriptionIsCustom
    }
    reversesAdjustment {
      id
    }
  }
`;
export const AgencyIncomeByPropertyReportTotalFragmentDoc = gql`
  fragment AgencyIncomeByPropertyReportTotal on AgencyIncomeByPropertyTotals {
    rentCollectedAmount
    managementFeeAmount
    managementFeePercent
    otherFeeAmount
    totalFeeAmount
  }
`;
export const TrustAccountReconciliationAdjustmentFragmentFragmentDoc = gql`
  fragment TrustAccountReconciliationAdjustmentFragment on TrustAccountReconciliationAdjustment {
    id
    applyOn
    details
    amountInCents
    removedReason
    removedFrom
  }
`;
export const RelayPageInfoFragmentFragmentDoc = gql`
  fragment RelayPageInfoFragment on RelayPageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
export const TrustAccountStatementBalanceFragmentFragmentDoc = gql`
  fragment trustAccountStatementBalanceFragment on TrustAccountStatementBalance {
    id
    amountInCents
    date
  }
`;
export const ReconciliationReportPeriodFragmentFragmentDoc = gql`
  fragment reconciliationReportPeriodFragment on ReconciliationReportPeriod {
    id
    month
    year
    locked
    trustAccountAilorn
    lastAddedStatementBalance {
      ...trustAccountStatementBalanceFragment
    }
    adjustmentSumInCents
    isReconciling
  }
  ${TrustAccountStatementBalanceFragmentFragmentDoc}
`;
export const TrustAccountLockFragmentFragmentDoc = gql`
  fragment trustAccountLockFragment on TrustAccountLock {
    trustAccountAilorn
    status
    lockedUpTo
  }
`;
export const LegalEntityContactNameFragmentDoc = gql`
  fragment LegalEntityContactName on LegalEntity {
    contact(organisationAilorn: $organisationAilorn) {
      id
      ailorn
      displayName
    }
  }
`;
export const PropertyFragmentDoc = gql`
  fragment Property on Property {
    id
    ailorn
    address {
      unitStreetNumber
      streetName
      suburb
      state
      country
      postcode
    }
  }
`;
export const TrustAccountWalletSearchResultFragmentDoc = gql`
  fragment TrustAccountWalletSearchResult on TrustAccountWallet {
    id
    ailorn
    type
    entityReference
    trustAccount {
      id
      ownerLegalEntityAilorn
    }
    code
    entity {
      ... on LegalEntityCompanion {
        ailoRNV2
        legalEntity {
          ...LegalEntityContactName
        }
      }
      ... on ManagementFolio {
        owners {
          owner {
            ...LegalEntityContactName
          }
        }
      }
      ... on Tenancy {
        tenantContacts {
          ailorn
          displayName
        }
        property {
          ...Property
        }
      }
      ... on Supplier {
        ailoRN
        name
      }
      ... on BondAuthority {
        ailorn
        userFacingName
      }
    }
  }
  ${LegalEntityContactNameFragmentDoc}
  ${PropertyFragmentDoc}
`;
export const RentLiabilityEntryFragmentDoc = gql`
  fragment RentLiabilityEntry on LiabilityEntry {
    id
    __typename
    amount {
      cents
    }
    createdAt
    effectiveAt
    entryType
    ... on PlanBasedLiabilityEntry {
      cycleStartDate
      cycleEndDate
      paidToDate
      frequency
    }
    ... on AdjustmentLiabilityEntry {
      descriptionIsCustom
      description
    }
    ... on PaymentLiabilityEntry {
      businessTransaction {
        id
        createdBy {
          id
          person {
            id
            firstName
          }
        }
        type
        payer
        status
        parent {
          id
          createdAt
        }
        children {
          id
          type
          status
        }
        estimatedClearDate
        clearedAt
        failedAt
      }
    }
  }
`;
export const RentLiabilityFragmentDoc = gql`
  fragment RentLiability on Liability {
    id
    category
    firstPaymentDate
    nextDueAmount {
      cents
    }
    nextDueDate
    overdueAmount {
      cents
    }
    paidToDate
    reference
    taxCategory
    terminationDate
    entries(
      filterTypes: $filterTypes
      cursor: { cursor: $cursor, pageSize: $pageSize }
      sort: $sort
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
      }
      items {
        ...RentLiabilityEntry
      }
    }
  }
  ${RentLiabilityEntryFragmentDoc}
`;
export const GetCurrentOrgReiTokensDocument = gql`
  query getCurrentOrgReiTokens(
    $personAilorn: AiloRN!
    $organisationAilorn: AiloRN!
  ) {
    legalEntity(id: $personAilorn) {
      id
      ... on Person {
        reiTokensForOrg(organisationAilorn: $organisationAilorn) {
          ...ReiTokenBase
        }
      }
    }
  }
  ${ReiTokenBaseFragmentDoc}
`;

/**
 * __useGetCurrentOrgReiTokensQuery__
 *
 * To run a query within a React component, call `useGetCurrentOrgReiTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentOrgReiTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentOrgReiTokensQuery({
 *   variables: {
 *      personAilorn: // value for 'personAilorn'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useGetCurrentOrgReiTokensQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentOrgReiTokensQuery,
    GetCurrentOrgReiTokensQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentOrgReiTokensQuery,
    GetCurrentOrgReiTokensQueryVariables
  >(GetCurrentOrgReiTokensDocument, baseOptions);
}
export function useGetCurrentOrgReiTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentOrgReiTokensQuery,
    GetCurrentOrgReiTokensQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentOrgReiTokensQuery,
    GetCurrentOrgReiTokensQueryVariables
  >(GetCurrentOrgReiTokensDocument, baseOptions);
}
export type GetCurrentOrgReiTokensQueryHookResult = ReturnType<
  typeof useGetCurrentOrgReiTokensQuery
>;
export type GetCurrentOrgReiTokensLazyQueryHookResult = ReturnType<
  typeof useGetCurrentOrgReiTokensLazyQuery
>;
export type GetCurrentOrgReiTokensQueryResult = Apollo.QueryResult<
  GetCurrentOrgReiTokensQuery,
  GetCurrentOrgReiTokensQueryVariables
>;
export const GetLegalEntityDisplayDetailsDocument = gql`
  query getLegalEntityDisplayDetails($ailoRN: AiloRN!) {
    legalEntity(id: $ailoRN) {
      __typename
      id
      ...PersonDisplayDetails
      ...CompanyDisplayDetails
    }
  }
  ${PersonDisplayDetailsFragmentDoc}
  ${CompanyDisplayDetailsFragmentDoc}
`;

/**
 * __useGetLegalEntityDisplayDetailsQuery__
 *
 * To run a query within a React component, call `useGetLegalEntityDisplayDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLegalEntityDisplayDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLegalEntityDisplayDetailsQuery({
 *   variables: {
 *      ailoRN: // value for 'ailoRN'
 *   },
 * });
 */
export function useGetLegalEntityDisplayDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLegalEntityDisplayDetailsQuery,
    GetLegalEntityDisplayDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetLegalEntityDisplayDetailsQuery,
    GetLegalEntityDisplayDetailsQueryVariables
  >(GetLegalEntityDisplayDetailsDocument, baseOptions);
}
export function useGetLegalEntityDisplayDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLegalEntityDisplayDetailsQuery,
    GetLegalEntityDisplayDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetLegalEntityDisplayDetailsQuery,
    GetLegalEntityDisplayDetailsQueryVariables
  >(GetLegalEntityDisplayDetailsDocument, baseOptions);
}
export type GetLegalEntityDisplayDetailsQueryHookResult = ReturnType<
  typeof useGetLegalEntityDisplayDetailsQuery
>;
export type GetLegalEntityDisplayDetailsLazyQueryHookResult = ReturnType<
  typeof useGetLegalEntityDisplayDetailsLazyQuery
>;
export type GetLegalEntityDisplayDetailsQueryResult = Apollo.QueryResult<
  GetLegalEntityDisplayDetailsQuery,
  GetLegalEntityDisplayDetailsQueryVariables
>;
export const GetManagementCurrentOrNextFeeScheduleDocument = gql`
  query getManagementCurrentOrNextFeeSchedule($managementId: ID!) {
    management(managementId: $managementId) {
      id
      managementFeeSchedule: currentOrNextManagementFeeSchedule {
        id
        percent
      }
    }
  }
`;

/**
 * __useGetManagementCurrentOrNextFeeScheduleQuery__
 *
 * To run a query within a React component, call `useGetManagementCurrentOrNextFeeScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementCurrentOrNextFeeScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementCurrentOrNextFeeScheduleQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetManagementCurrentOrNextFeeScheduleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementCurrentOrNextFeeScheduleQuery,
    GetManagementCurrentOrNextFeeScheduleQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementCurrentOrNextFeeScheduleQuery,
    GetManagementCurrentOrNextFeeScheduleQueryVariables
  >(GetManagementCurrentOrNextFeeScheduleDocument, baseOptions);
}
export function useGetManagementCurrentOrNextFeeScheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementCurrentOrNextFeeScheduleQuery,
    GetManagementCurrentOrNextFeeScheduleQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementCurrentOrNextFeeScheduleQuery,
    GetManagementCurrentOrNextFeeScheduleQueryVariables
  >(GetManagementCurrentOrNextFeeScheduleDocument, baseOptions);
}
export type GetManagementCurrentOrNextFeeScheduleQueryHookResult = ReturnType<
  typeof useGetManagementCurrentOrNextFeeScheduleQuery
>;
export type GetManagementCurrentOrNextFeeScheduleLazyQueryHookResult =
  ReturnType<typeof useGetManagementCurrentOrNextFeeScheduleLazyQuery>;
export type GetManagementCurrentOrNextFeeScheduleQueryResult =
  Apollo.QueryResult<
    GetManagementCurrentOrNextFeeScheduleQuery,
    GetManagementCurrentOrNextFeeScheduleQueryVariables
  >;
export const GetPaymentReferenceForPayerAndSupplierDocument = gql`
  query getPaymentReferenceForPayerAndSupplier(
    $payeeId: AiloRN!
    $payerId: AiloRN!
  ) {
    paymentReferences(
      conditions: { payeeId: $payeeId, billPayerId: $payerId }
      cursor: { pageSize: 1 }
    ) {
      items {
        id
        crn
      }
    }
  }
`;

/**
 * __useGetPaymentReferenceForPayerAndSupplierQuery__
 *
 * To run a query within a React component, call `useGetPaymentReferenceForPayerAndSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentReferenceForPayerAndSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentReferenceForPayerAndSupplierQuery({
 *   variables: {
 *      payeeId: // value for 'payeeId'
 *      payerId: // value for 'payerId'
 *   },
 * });
 */
export function useGetPaymentReferenceForPayerAndSupplierQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentReferenceForPayerAndSupplierQuery,
    GetPaymentReferenceForPayerAndSupplierQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaymentReferenceForPayerAndSupplierQuery,
    GetPaymentReferenceForPayerAndSupplierQueryVariables
  >(GetPaymentReferenceForPayerAndSupplierDocument, baseOptions);
}
export function useGetPaymentReferenceForPayerAndSupplierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentReferenceForPayerAndSupplierQuery,
    GetPaymentReferenceForPayerAndSupplierQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaymentReferenceForPayerAndSupplierQuery,
    GetPaymentReferenceForPayerAndSupplierQueryVariables
  >(GetPaymentReferenceForPayerAndSupplierDocument, baseOptions);
}
export type GetPaymentReferenceForPayerAndSupplierQueryHookResult = ReturnType<
  typeof useGetPaymentReferenceForPayerAndSupplierQuery
>;
export type GetPaymentReferenceForPayerAndSupplierLazyQueryHookResult =
  ReturnType<typeof useGetPaymentReferenceForPayerAndSupplierLazyQuery>;
export type GetPaymentReferenceForPayerAndSupplierQueryResult =
  Apollo.QueryResult<
    GetPaymentReferenceForPayerAndSupplierQuery,
    GetPaymentReferenceForPayerAndSupplierQueryVariables
  >;
export const GetLastSupplierBillTaxAutoCalculatedDocument = gql`
  query getLastSupplierBillTaxAutoCalculated($payeeId: AiloRN!) {
    bills(
      taxCategoryIdNotIn: ["MANAGEMENT_FEES"]
      payeeId: [$payeeId]
      status: [APPROVED, REFUNDED]
      sort: { field: CreatedAt, direction: Desc }
      cursor: { pageSize: 1 }
    ) {
      items {
        ailoRN
        taxAutoCalculated
      }
    }
  }
`;

/**
 * __useGetLastSupplierBillTaxAutoCalculatedQuery__
 *
 * To run a query within a React component, call `useGetLastSupplierBillTaxAutoCalculatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastSupplierBillTaxAutoCalculatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastSupplierBillTaxAutoCalculatedQuery({
 *   variables: {
 *      payeeId: // value for 'payeeId'
 *   },
 * });
 */
export function useGetLastSupplierBillTaxAutoCalculatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLastSupplierBillTaxAutoCalculatedQuery,
    GetLastSupplierBillTaxAutoCalculatedQueryVariables
  >
) {
  return Apollo.useQuery<
    GetLastSupplierBillTaxAutoCalculatedQuery,
    GetLastSupplierBillTaxAutoCalculatedQueryVariables
  >(GetLastSupplierBillTaxAutoCalculatedDocument, baseOptions);
}
export function useGetLastSupplierBillTaxAutoCalculatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastSupplierBillTaxAutoCalculatedQuery,
    GetLastSupplierBillTaxAutoCalculatedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetLastSupplierBillTaxAutoCalculatedQuery,
    GetLastSupplierBillTaxAutoCalculatedQueryVariables
  >(GetLastSupplierBillTaxAutoCalculatedDocument, baseOptions);
}
export type GetLastSupplierBillTaxAutoCalculatedQueryHookResult = ReturnType<
  typeof useGetLastSupplierBillTaxAutoCalculatedQuery
>;
export type GetLastSupplierBillTaxAutoCalculatedLazyQueryHookResult =
  ReturnType<typeof useGetLastSupplierBillTaxAutoCalculatedLazyQuery>;
export type GetLastSupplierBillTaxAutoCalculatedQueryResult =
  Apollo.QueryResult<
    GetLastSupplierBillTaxAutoCalculatedQuery,
    GetLastSupplierBillTaxAutoCalculatedQueryVariables
  >;
export const GetSuppliersDocument = gql`
  query getSuppliers($id: ID!, $suppliersCursor: BillDefaultPageCursor!) {
    organisation(id: $id) {
      id
      suppliers(cursor: $suppliersCursor) {
        pageInfo {
          total
          hasMore
          nextCursor
        }
        items {
          ailoRN
          name
          abn
        }
      }
    }
  }
`;

/**
 * __useGetSuppliersQuery__
 *
 * To run a query within a React component, call `useGetSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuppliersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      suppliersCursor: // value for 'suppliersCursor'
 *   },
 * });
 */
export function useGetSuppliersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSuppliersQuery,
    GetSuppliersQueryVariables
  >
) {
  return Apollo.useQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(
    GetSuppliersDocument,
    baseOptions
  );
}
export function useGetSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSuppliersQuery,
    GetSuppliersQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(
    GetSuppliersDocument,
    baseOptions
  );
}
export type GetSuppliersQueryHookResult = ReturnType<
  typeof useGetSuppliersQuery
>;
export type GetSuppliersLazyQueryHookResult = ReturnType<
  typeof useGetSuppliersLazyQuery
>;
export type GetSuppliersQueryResult = Apollo.QueryResult<
  GetSuppliersQuery,
  GetSuppliersQueryVariables
>;
export const GetLastSupplierBillTaxCategoryDocument = gql`
  query getLastSupplierBillTaxCategory($payeeId: AiloRN!) {
    bills(
      taxCategoryIdNotIn: ["MANAGEMENT_FEES"]
      payeeId: [$payeeId]
      status: [APPROVED, REFUNDED]
      sort: { field: CreatedAt, direction: Desc }
      cursor: { pageSize: 1 }
    ) {
      items {
        ailoRN
        taxCategory {
          id
        }
      }
    }
  }
`;

/**
 * __useGetLastSupplierBillTaxCategoryQuery__
 *
 * To run a query within a React component, call `useGetLastSupplierBillTaxCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastSupplierBillTaxCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastSupplierBillTaxCategoryQuery({
 *   variables: {
 *      payeeId: // value for 'payeeId'
 *   },
 * });
 */
export function useGetLastSupplierBillTaxCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLastSupplierBillTaxCategoryQuery,
    GetLastSupplierBillTaxCategoryQueryVariables
  >
) {
  return Apollo.useQuery<
    GetLastSupplierBillTaxCategoryQuery,
    GetLastSupplierBillTaxCategoryQueryVariables
  >(GetLastSupplierBillTaxCategoryDocument, baseOptions);
}
export function useGetLastSupplierBillTaxCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastSupplierBillTaxCategoryQuery,
    GetLastSupplierBillTaxCategoryQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetLastSupplierBillTaxCategoryQuery,
    GetLastSupplierBillTaxCategoryQueryVariables
  >(GetLastSupplierBillTaxCategoryDocument, baseOptions);
}
export type GetLastSupplierBillTaxCategoryQueryHookResult = ReturnType<
  typeof useGetLastSupplierBillTaxCategoryQuery
>;
export type GetLastSupplierBillTaxCategoryLazyQueryHookResult = ReturnType<
  typeof useGetLastSupplierBillTaxCategoryLazyQuery
>;
export type GetLastSupplierBillTaxCategoryQueryResult = Apollo.QueryResult<
  GetLastSupplierBillTaxCategoryQuery,
  GetLastSupplierBillTaxCategoryQueryVariables
>;
export const GetTaxCategoriesDocument = gql`
  query getTaxCategories {
    taxCategories {
      id
      name
    }
  }
`;

/**
 * __useGetTaxCategoriesQuery__
 *
 * To run a query within a React component, call `useGetTaxCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTaxCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTaxCategoriesQuery,
    GetTaxCategoriesQueryVariables
  >
) {
  return Apollo.useQuery<GetTaxCategoriesQuery, GetTaxCategoriesQueryVariables>(
    GetTaxCategoriesDocument,
    baseOptions
  );
}
export function useGetTaxCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaxCategoriesQuery,
    GetTaxCategoriesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTaxCategoriesQuery,
    GetTaxCategoriesQueryVariables
  >(GetTaxCategoriesDocument, baseOptions);
}
export type GetTaxCategoriesQueryHookResult = ReturnType<
  typeof useGetTaxCategoriesQuery
>;
export type GetTaxCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetTaxCategoriesLazyQuery
>;
export type GetTaxCategoriesQueryResult = Apollo.QueryResult<
  GetTaxCategoriesQuery,
  GetTaxCategoriesQueryVariables
>;
export const CreateBillDocument = gql`
  mutation createBill($billDetails: BillInput!) {
    createBill(billDetails: $billDetails) {
      ailoRN
    }
  }
`;
export type CreateBillMutationFn = Apollo.MutationFunction<
  CreateBillMutation,
  CreateBillMutationVariables
>;

/**
 * __useCreateBillMutation__
 *
 * To run a mutation, you first call `useCreateBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillMutation, { data, loading, error }] = useCreateBillMutation({
 *   variables: {
 *      billDetails: // value for 'billDetails'
 *   },
 * });
 */
export function useCreateBillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBillMutation,
    CreateBillMutationVariables
  >
) {
  return Apollo.useMutation<CreateBillMutation, CreateBillMutationVariables>(
    CreateBillDocument,
    baseOptions
  );
}
export type CreateBillMutationHookResult = ReturnType<
  typeof useCreateBillMutation
>;
export type CreateBillMutationResult =
  Apollo.MutationResult<CreateBillMutation>;
export type CreateBillMutationOptions = Apollo.BaseMutationOptions<
  CreateBillMutation,
  CreateBillMutationVariables
>;
export const CreateBillLiabilityStateDocument = gql`
  mutation createBillLiabilityState(
    $billLiabilityStateDetails: BillLiabilityStateInput!
  ) {
    createBillLiabilityState(
      billLiabilityStateDetails: $billLiabilityStateDetails
    ) {
      paymentStatus
    }
  }
`;
export type CreateBillLiabilityStateMutationFn = Apollo.MutationFunction<
  CreateBillLiabilityStateMutation,
  CreateBillLiabilityStateMutationVariables
>;

/**
 * __useCreateBillLiabilityStateMutation__
 *
 * To run a mutation, you first call `useCreateBillLiabilityStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillLiabilityStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillLiabilityStateMutation, { data, loading, error }] = useCreateBillLiabilityStateMutation({
 *   variables: {
 *      billLiabilityStateDetails: // value for 'billLiabilityStateDetails'
 *   },
 * });
 */
export function useCreateBillLiabilityStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBillLiabilityStateMutation,
    CreateBillLiabilityStateMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateBillLiabilityStateMutation,
    CreateBillLiabilityStateMutationVariables
  >(CreateBillLiabilityStateDocument, baseOptions);
}
export type CreateBillLiabilityStateMutationHookResult = ReturnType<
  typeof useCreateBillLiabilityStateMutation
>;
export type CreateBillLiabilityStateMutationResult =
  Apollo.MutationResult<CreateBillLiabilityStateMutation>;
export type CreateBillLiabilityStateMutationOptions =
  Apollo.BaseMutationOptions<
    CreateBillLiabilityStateMutation,
    CreateBillLiabilityStateMutationVariables
  >;
export const FindOrCreateInternalSupplierDocument = gql`
  mutation findOrCreateInternalSupplier(
    $supplierDetails: InternalSupplierInput!
  ) {
    findOrCreateInternalSupplier(supplierDetails: $supplierDetails) {
      ailoRN
    }
  }
`;
export type FindOrCreateInternalSupplierMutationFn = Apollo.MutationFunction<
  FindOrCreateInternalSupplierMutation,
  FindOrCreateInternalSupplierMutationVariables
>;

/**
 * __useFindOrCreateInternalSupplierMutation__
 *
 * To run a mutation, you first call `useFindOrCreateInternalSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindOrCreateInternalSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findOrCreateInternalSupplierMutation, { data, loading, error }] = useFindOrCreateInternalSupplierMutation({
 *   variables: {
 *      supplierDetails: // value for 'supplierDetails'
 *   },
 * });
 */
export function useFindOrCreateInternalSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FindOrCreateInternalSupplierMutation,
    FindOrCreateInternalSupplierMutationVariables
  >
) {
  return Apollo.useMutation<
    FindOrCreateInternalSupplierMutation,
    FindOrCreateInternalSupplierMutationVariables
  >(FindOrCreateInternalSupplierDocument, baseOptions);
}
export type FindOrCreateInternalSupplierMutationHookResult = ReturnType<
  typeof useFindOrCreateInternalSupplierMutation
>;
export type FindOrCreateInternalSupplierMutationResult =
  Apollo.MutationResult<FindOrCreateInternalSupplierMutation>;
export type FindOrCreateInternalSupplierMutationOptions =
  Apollo.BaseMutationOptions<
    FindOrCreateInternalSupplierMutation,
    FindOrCreateInternalSupplierMutationVariables
  >;
export const FindOrCreatePaymentReferenceDocument = gql`
  mutation findOrCreatePaymentReference(
    $paymentReferenceDetails: PaymentReferenceInput!
  ) {
    findOrCreatePaymentReference(
      paymentReferenceDetails: $paymentReferenceDetails
    ) {
      id
      supplier {
        ailoRN
      }
      supplierPaymentMethodReference
      crn
      paymentDescription
    }
  }
`;
export type FindOrCreatePaymentReferenceMutationFn = Apollo.MutationFunction<
  FindOrCreatePaymentReferenceMutation,
  FindOrCreatePaymentReferenceMutationVariables
>;

/**
 * __useFindOrCreatePaymentReferenceMutation__
 *
 * To run a mutation, you first call `useFindOrCreatePaymentReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindOrCreatePaymentReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findOrCreatePaymentReferenceMutation, { data, loading, error }] = useFindOrCreatePaymentReferenceMutation({
 *   variables: {
 *      paymentReferenceDetails: // value for 'paymentReferenceDetails'
 *   },
 * });
 */
export function useFindOrCreatePaymentReferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FindOrCreatePaymentReferenceMutation,
    FindOrCreatePaymentReferenceMutationVariables
  >
) {
  return Apollo.useMutation<
    FindOrCreatePaymentReferenceMutation,
    FindOrCreatePaymentReferenceMutationVariables
  >(FindOrCreatePaymentReferenceDocument, baseOptions);
}
export type FindOrCreatePaymentReferenceMutationHookResult = ReturnType<
  typeof useFindOrCreatePaymentReferenceMutation
>;
export type FindOrCreatePaymentReferenceMutationResult =
  Apollo.MutationResult<FindOrCreatePaymentReferenceMutation>;
export type FindOrCreatePaymentReferenceMutationOptions =
  Apollo.BaseMutationOptions<
    FindOrCreatePaymentReferenceMutation,
    FindOrCreatePaymentReferenceMutationVariables
  >;
export const GetPaymentMethodByOwnerDocument = gql`
  query getPaymentMethodByOwner($owner: AiloRN!) {
    paymentMethodsByOwner(owner: $owner) {
      pageInfo {
        total
      }
      items {
        id
        __typename
        ... on BPay {
          billerCode
        }
        ... on BankAccount {
          accountName
          accountNumber
          bsb
        }
      }
    }
  }
`;

/**
 * __useGetPaymentMethodByOwnerQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodByOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodByOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodByOwnerQuery({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useGetPaymentMethodByOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentMethodByOwnerQuery,
    GetPaymentMethodByOwnerQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaymentMethodByOwnerQuery,
    GetPaymentMethodByOwnerQueryVariables
  >(GetPaymentMethodByOwnerDocument, baseOptions);
}
export function useGetPaymentMethodByOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentMethodByOwnerQuery,
    GetPaymentMethodByOwnerQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaymentMethodByOwnerQuery,
    GetPaymentMethodByOwnerQueryVariables
  >(GetPaymentMethodByOwnerDocument, baseOptions);
}
export type GetPaymentMethodByOwnerQueryHookResult = ReturnType<
  typeof useGetPaymentMethodByOwnerQuery
>;
export type GetPaymentMethodByOwnerLazyQueryHookResult = ReturnType<
  typeof useGetPaymentMethodByOwnerLazyQuery
>;
export type GetPaymentMethodByOwnerQueryResult = Apollo.QueryResult<
  GetPaymentMethodByOwnerQuery,
  GetPaymentMethodByOwnerQueryVariables
>;
export const GetBillsCountDocument = gql`
  query getBillsCount(
    $organisationId: AiloRN!
    $paid: Boolean
    $status: [BillStatus!]
    $dueDate: LocalDateRangeCondition
    $invoiceNumber: [String!]
    $payeeId: [AiloRN!]
  ) {
    bills(
      taxCategoryIdNotIn: ["MANAGEMENT_FEES"]
      organisationId: [$organisationId]
      paid: $paid
      status: $status
      dueDate: $dueDate
      invoiceNumber: $invoiceNumber
      payeeId: $payeeId
    ) {
      pageInfo {
        total
      }
    }
  }
`;

/**
 * __useGetBillsCountQuery__
 *
 * To run a query within a React component, call `useGetBillsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillsCountQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      paid: // value for 'paid'
 *      status: // value for 'status'
 *      dueDate: // value for 'dueDate'
 *      invoiceNumber: // value for 'invoiceNumber'
 *      payeeId: // value for 'payeeId'
 *   },
 * });
 */
export function useGetBillsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBillsCountQuery,
    GetBillsCountQueryVariables
  >
) {
  return Apollo.useQuery<GetBillsCountQuery, GetBillsCountQueryVariables>(
    GetBillsCountDocument,
    baseOptions
  );
}
export function useGetBillsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillsCountQuery,
    GetBillsCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetBillsCountQuery, GetBillsCountQueryVariables>(
    GetBillsCountDocument,
    baseOptions
  );
}
export type GetBillsCountQueryHookResult = ReturnType<
  typeof useGetBillsCountQuery
>;
export type GetBillsCountLazyQueryHookResult = ReturnType<
  typeof useGetBillsCountLazyQuery
>;
export type GetBillsCountQueryResult = Apollo.QueryResult<
  GetBillsCountQuery,
  GetBillsCountQueryVariables
>;
export const GetUpcomingAndPaidBillsDocument = gql`
  query getUpcomingAndPaidBills(
    $pageCursor: PageCursorWithoutSort!
    $payerId: [AiloRN!]
  ) {
    upcomingBills: bills(
      cursor: $pageCursor
      payerId: $payerId
      paid: false
      status: [APPROVED, REFUNDED]
      taxCategoryIdNotIn: ["MANAGEMENT_FEES"]
      sort: { field: PayByDate, direction: Asc }
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
        hasPrevious
        previousCursor
      }
      items {
        ailoRN
        amount {
          cents
        }
        status
        agencyStatus
        dueDateV2
        supplier {
          ailoRN
          name
          internalReference
          internalLegalEntity {
            ...legalEntityCompanionOrganisationFragment
          }
        }
        liabilityState {
          payByDateV2
          paymentStatus
          paidAt
          dueAmount {
            cents
          }
        }
      }
    }
    paidBills: bills(
      cursor: $pageCursor
      payerId: $payerId
      paid: true
      status: [APPROVED, REFUNDED]
      taxCategoryIdNotIn: ["MANAGEMENT_FEES"]
      sort: { field: PaymentDate, direction: Desc }
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
        hasPrevious
        previousCursor
      }
      items {
        ailoRN
        amount {
          cents
        }
        status
        agencyStatus
        dueDateV2
        supplier {
          ailoRN
          name
          internalReference
          internalLegalEntity {
            ...legalEntityCompanionOrganisationFragment
          }
        }
        liabilityState {
          payByDateV2
          paymentStatus
          paidAt
          dueAmount {
            cents
          }
        }
      }
    }
  }
  ${LegalEntityCompanionOrganisationFragmentFragmentDoc}
`;

/**
 * __useGetUpcomingAndPaidBillsQuery__
 *
 * To run a query within a React component, call `useGetUpcomingAndPaidBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingAndPaidBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingAndPaidBillsQuery({
 *   variables: {
 *      pageCursor: // value for 'pageCursor'
 *      payerId: // value for 'payerId'
 *   },
 * });
 */
export function useGetUpcomingAndPaidBillsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUpcomingAndPaidBillsQuery,
    GetUpcomingAndPaidBillsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetUpcomingAndPaidBillsQuery,
    GetUpcomingAndPaidBillsQueryVariables
  >(GetUpcomingAndPaidBillsDocument, baseOptions);
}
export function useGetUpcomingAndPaidBillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpcomingAndPaidBillsQuery,
    GetUpcomingAndPaidBillsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetUpcomingAndPaidBillsQuery,
    GetUpcomingAndPaidBillsQueryVariables
  >(GetUpcomingAndPaidBillsDocument, baseOptions);
}
export type GetUpcomingAndPaidBillsQueryHookResult = ReturnType<
  typeof useGetUpcomingAndPaidBillsQuery
>;
export type GetUpcomingAndPaidBillsLazyQueryHookResult = ReturnType<
  typeof useGetUpcomingAndPaidBillsLazyQuery
>;
export type GetUpcomingAndPaidBillsQueryResult = Apollo.QueryResult<
  GetUpcomingAndPaidBillsQuery,
  GetUpcomingAndPaidBillsQueryVariables
>;
export const ArchiveBillDocument = gql`
  mutation archiveBill($billId: ID!, $reason: String) {
    archiveBill(billId: $billId, reason: $reason) {
      ailoRN
      status
      agencyStatus
      archivableByOrganisation
      organisationArchiveReason
    }
  }
`;
export type ArchiveBillMutationFn = Apollo.MutationFunction<
  ArchiveBillMutation,
  ArchiveBillMutationVariables
>;

/**
 * __useArchiveBillMutation__
 *
 * To run a mutation, you first call `useArchiveBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveBillMutation, { data, loading, error }] = useArchiveBillMutation({
 *   variables: {
 *      billId: // value for 'billId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useArchiveBillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveBillMutation,
    ArchiveBillMutationVariables
  >
) {
  return Apollo.useMutation<ArchiveBillMutation, ArchiveBillMutationVariables>(
    ArchiveBillDocument,
    baseOptions
  );
}
export type ArchiveBillMutationHookResult = ReturnType<
  typeof useArchiveBillMutation
>;
export type ArchiveBillMutationResult =
  Apollo.MutationResult<ArchiveBillMutation>;
export type ArchiveBillMutationOptions = Apollo.BaseMutationOptions<
  ArchiveBillMutation,
  ArchiveBillMutationVariables
>;
export const GetBillArchiveFormDataDocument = gql`
  query getBillArchiveFormData($billId: ID!) {
    billById(billId: $billId) {
      ailoRN
      management {
        id
        ...ManagementProperty
      }
      supplier {
        ailoRN
        name
        internalReference
        internalLegalEntity {
          ...legalEntityCompanionOrganisationFragment
        }
      }
      status
      agencyStatus
      liabilityState {
        payByDateV2
        paymentStatus
      }
      archivableByOrganisation
      organisationArchiveReason
    }
  }
  ${ManagementPropertyFragmentDoc}
  ${LegalEntityCompanionOrganisationFragmentFragmentDoc}
`;

/**
 * __useGetBillArchiveFormDataQuery__
 *
 * To run a query within a React component, call `useGetBillArchiveFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillArchiveFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillArchiveFormDataQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useGetBillArchiveFormDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBillArchiveFormDataQuery,
    GetBillArchiveFormDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetBillArchiveFormDataQuery,
    GetBillArchiveFormDataQueryVariables
  >(GetBillArchiveFormDataDocument, baseOptions);
}
export function useGetBillArchiveFormDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillArchiveFormDataQuery,
    GetBillArchiveFormDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetBillArchiveFormDataQuery,
    GetBillArchiveFormDataQueryVariables
  >(GetBillArchiveFormDataDocument, baseOptions);
}
export type GetBillArchiveFormDataQueryHookResult = ReturnType<
  typeof useGetBillArchiveFormDataQuery
>;
export type GetBillArchiveFormDataLazyQueryHookResult = ReturnType<
  typeof useGetBillArchiveFormDataLazyQuery
>;
export type GetBillArchiveFormDataQueryResult = Apollo.QueryResult<
  GetBillArchiveFormDataQuery,
  GetBillArchiveFormDataQueryVariables
>;
export const GetBillHeaderDataDocument = gql`
  query getBillHeaderData($billId: ID!) {
    billById(billId: $billId) {
      ailoRN
      supplier {
        ailoRN
        name
        internalReference
        internalLegalEntity {
          ...legalEntityCompanionOrganisationFragment
        }
      }
      dueDateV2
      liabilityState {
        payByDateV2
        paymentStatus
        paidAt
        dueAmount {
          cents
        }
      }
      status
      agencyStatus
      amount {
        cents
      }
      archivableByOrganisation
    }
  }
  ${LegalEntityCompanionOrganisationFragmentFragmentDoc}
`;

/**
 * __useGetBillHeaderDataQuery__
 *
 * To run a query within a React component, call `useGetBillHeaderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillHeaderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillHeaderDataQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useGetBillHeaderDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBillHeaderDataQuery,
    GetBillHeaderDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetBillHeaderDataQuery,
    GetBillHeaderDataQueryVariables
  >(GetBillHeaderDataDocument, baseOptions);
}
export function useGetBillHeaderDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillHeaderDataQuery,
    GetBillHeaderDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetBillHeaderDataQuery,
    GetBillHeaderDataQueryVariables
  >(GetBillHeaderDataDocument, baseOptions);
}
export type GetBillHeaderDataQueryHookResult = ReturnType<
  typeof useGetBillHeaderDataQuery
>;
export type GetBillHeaderDataLazyQueryHookResult = ReturnType<
  typeof useGetBillHeaderDataLazyQuery
>;
export type GetBillHeaderDataQueryResult = Apollo.QueryResult<
  GetBillHeaderDataQuery,
  GetBillHeaderDataQueryVariables
>;
export const GetBillManagementIdDocument = gql`
  query getBillManagementId($billId: ID!) {
    billById(billId: $billId) {
      ailoRN
      management {
        id
      }
    }
  }
`;

/**
 * __useGetBillManagementIdQuery__
 *
 * To run a query within a React component, call `useGetBillManagementIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillManagementIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillManagementIdQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useGetBillManagementIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBillManagementIdQuery,
    GetBillManagementIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetBillManagementIdQuery,
    GetBillManagementIdQueryVariables
  >(GetBillManagementIdDocument, baseOptions);
}
export function useGetBillManagementIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillManagementIdQuery,
    GetBillManagementIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetBillManagementIdQuery,
    GetBillManagementIdQueryVariables
  >(GetBillManagementIdDocument, baseOptions);
}
export type GetBillManagementIdQueryHookResult = ReturnType<
  typeof useGetBillManagementIdQuery
>;
export type GetBillManagementIdLazyQueryHookResult = ReturnType<
  typeof useGetBillManagementIdLazyQuery
>;
export type GetBillManagementIdQueryResult = Apollo.QueryResult<
  GetBillManagementIdQuery,
  GetBillManagementIdQueryVariables
>;
export const GetClaimTenancyBondDetailsDocument = gql`
  query getClaimTenancyBondDetails($tenancyId: ID!, $paidBills: Boolean) {
    tenancy(tenancyId: $tenancyId) {
      id
      endDate
      management {
        id
        managingEntity {
          id
          tenancyBondVirtualAccounts {
            id
            paymentMethodCompanion {
              id
              paymentMethod {
                id
                ... on VirtualAccount {
                  accountNumber
                  bsb
                }
              }
            }
          }
        }
      }
      bond {
        id
        amount {
          cents
        }
        reference
        status
        failureReason
        fundsReceived {
          amount {
            cents
          }
          receivedAt
        }
        claims {
          ...TenancyBondClaim
        }
      }
      liability {
        reference
        overdueAmount {
          cents
        }
        terminationAmountDue {
          cents
        }
      }
      ...TenancyProperty
      billsAsPayer(
        paid: $paidBills
        cursor: { pageSize: 50 }
        sort: { field: DueDate, direction: Asc }
      ) {
        items {
          ailoRN
          dueDateV2
          amount {
            cents
          }
          payee {
            ... on Supplier {
              ailoRN
              name
            }
            ... on Management {
              ailoRN
            }
            ... on LegalEntityCompanion {
              ailoRN: ailoRNV2
            }
          }
          taxCategory {
            name
          }
        }
      }
      tenantships {
        items {
          tenant {
            ...legalEntityName
          }
        }
      }
    }
  }
  ${TenancyBondClaimFragmentDoc}
  ${TenancyPropertyFragmentDoc}
  ${LegalEntityNameFragmentDoc}
`;

/**
 * __useGetClaimTenancyBondDetailsQuery__
 *
 * To run a query within a React component, call `useGetClaimTenancyBondDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimTenancyBondDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimTenancyBondDetailsQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *      paidBills: // value for 'paidBills'
 *   },
 * });
 */
export function useGetClaimTenancyBondDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClaimTenancyBondDetailsQuery,
    GetClaimTenancyBondDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetClaimTenancyBondDetailsQuery,
    GetClaimTenancyBondDetailsQueryVariables
  >(GetClaimTenancyBondDetailsDocument, baseOptions);
}
export function useGetClaimTenancyBondDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClaimTenancyBondDetailsQuery,
    GetClaimTenancyBondDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetClaimTenancyBondDetailsQuery,
    GetClaimTenancyBondDetailsQueryVariables
  >(GetClaimTenancyBondDetailsDocument, baseOptions);
}
export type GetClaimTenancyBondDetailsQueryHookResult = ReturnType<
  typeof useGetClaimTenancyBondDetailsQuery
>;
export type GetClaimTenancyBondDetailsLazyQueryHookResult = ReturnType<
  typeof useGetClaimTenancyBondDetailsLazyQuery
>;
export type GetClaimTenancyBondDetailsQueryResult = Apollo.QueryResult<
  GetClaimTenancyBondDetailsQuery,
  GetClaimTenancyBondDetailsQueryVariables
>;
export const UpdateBondStatusDocument = gql`
  mutation updateBondStatus($bondAilorn: AiloRN!, $status: TenancyBondStatus!) {
    updateTenancyBondStatus(bondAilorn: $bondAilorn, status: $status) {
      id
      status
    }
  }
`;
export type UpdateBondStatusMutationFn = Apollo.MutationFunction<
  UpdateBondStatusMutation,
  UpdateBondStatusMutationVariables
>;

/**
 * __useUpdateBondStatusMutation__
 *
 * To run a mutation, you first call `useUpdateBondStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBondStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBondStatusMutation, { data, loading, error }] = useUpdateBondStatusMutation({
 *   variables: {
 *      bondAilorn: // value for 'bondAilorn'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateBondStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBondStatusMutation,
    UpdateBondStatusMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateBondStatusMutation,
    UpdateBondStatusMutationVariables
  >(UpdateBondStatusDocument, baseOptions);
}
export type UpdateBondStatusMutationHookResult = ReturnType<
  typeof useUpdateBondStatusMutation
>;
export type UpdateBondStatusMutationResult =
  Apollo.MutationResult<UpdateBondStatusMutation>;
export type UpdateBondStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateBondStatusMutation,
  UpdateBondStatusMutationVariables
>;
export const UpdateManagementFeeDocument = gql`
  mutation updateManagementFee($input: UpdateManagementFeeInput!) {
    updateManagementFee(input: $input) {
      id
    }
  }
`;
export type UpdateManagementFeeMutationFn = Apollo.MutationFunction<
  UpdateManagementFeeMutation,
  UpdateManagementFeeMutationVariables
>;

/**
 * __useUpdateManagementFeeMutation__
 *
 * To run a mutation, you first call `useUpdateManagementFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagementFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagementFeeMutation, { data, loading, error }] = useUpdateManagementFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManagementFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManagementFeeMutation,
    UpdateManagementFeeMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateManagementFeeMutation,
    UpdateManagementFeeMutationVariables
  >(UpdateManagementFeeDocument, baseOptions);
}
export type UpdateManagementFeeMutationHookResult = ReturnType<
  typeof useUpdateManagementFeeMutation
>;
export type UpdateManagementFeeMutationResult =
  Apollo.MutationResult<UpdateManagementFeeMutation>;
export type UpdateManagementFeeMutationOptions = Apollo.BaseMutationOptions<
  UpdateManagementFeeMutation,
  UpdateManagementFeeMutationVariables
>;
export const UpdateOneOffFeeDocument = gql`
  mutation updateOneOffFee($input: UpdateFeeInput!) {
    updateFee(input: $input) {
      id
      ailorn: ailoRN
      amount {
        cents
      }
      percentage
      description
    }
  }
`;
export type UpdateOneOffFeeMutationFn = Apollo.MutationFunction<
  UpdateOneOffFeeMutation,
  UpdateOneOffFeeMutationVariables
>;

/**
 * __useUpdateOneOffFeeMutation__
 *
 * To run a mutation, you first call `useUpdateOneOffFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOffFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneOffFeeMutation, { data, loading, error }] = useUpdateOneOffFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneOffFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneOffFeeMutation,
    UpdateOneOffFeeMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateOneOffFeeMutation,
    UpdateOneOffFeeMutationVariables
  >(UpdateOneOffFeeDocument, baseOptions);
}
export type UpdateOneOffFeeMutationHookResult = ReturnType<
  typeof useUpdateOneOffFeeMutation
>;
export type UpdateOneOffFeeMutationResult =
  Apollo.MutationResult<UpdateOneOffFeeMutation>;
export type UpdateOneOffFeeMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneOffFeeMutation,
  UpdateOneOffFeeMutationVariables
>;
export const UpdateRecurringFeeDocument = gql`
  mutation updateRecurringFee($input: UpdateRecurringFeeInput!) {
    updateRecurringFee(input: $input) {
      id: idV2
      ailorn: ailoRN
      description
    }
  }
`;
export type UpdateRecurringFeeMutationFn = Apollo.MutationFunction<
  UpdateRecurringFeeMutation,
  UpdateRecurringFeeMutationVariables
>;

/**
 * __useUpdateRecurringFeeMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringFeeMutation, { data, loading, error }] = useUpdateRecurringFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecurringFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecurringFeeMutation,
    UpdateRecurringFeeMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateRecurringFeeMutation,
    UpdateRecurringFeeMutationVariables
  >(UpdateRecurringFeeDocument, baseOptions);
}
export type UpdateRecurringFeeMutationHookResult = ReturnType<
  typeof useUpdateRecurringFeeMutation
>;
export type UpdateRecurringFeeMutationResult =
  Apollo.MutationResult<UpdateRecurringFeeMutation>;
export type UpdateRecurringFeeMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecurringFeeMutation,
  UpdateRecurringFeeMutationVariables
>;
export const GetExpenseDocument = gql`
  query getExpense(
    $id: ID!
    $ailorn: AiloRN!
    $isFee: Boolean = false
    $isBill: Boolean = false
  ) {
    fee(id: $id) @include(if: $isFee) {
      ...feeDetails
    }
    billById(billId: $id) @include(if: $isBill) {
      ...billDetails
    }
    fees(conditions: { type: [BillManagementFee], appliedToAiloRN: [$ailorn] })
      @include(if: $isBill) {
      items {
        id
        amount {
          cents
        }
        percentage
      }
    }
  }
  ${FeeDetailsFragmentDoc}
  ${BillDetailsFragmentDoc}
`;

/**
 * __useGetExpenseQuery__
 *
 * To run a query within a React component, call `useGetExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseQuery({
 *   variables: {
 *      id: // value for 'id'
 *      ailorn: // value for 'ailorn'
 *      isFee: // value for 'isFee'
 *      isBill: // value for 'isBill'
 *   },
 * });
 */
export function useGetExpenseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExpenseQuery,
    GetExpenseQueryVariables
  >
) {
  return Apollo.useQuery<GetExpenseQuery, GetExpenseQueryVariables>(
    GetExpenseDocument,
    baseOptions
  );
}
export function useGetExpenseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExpenseQuery,
    GetExpenseQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetExpenseQuery, GetExpenseQueryVariables>(
    GetExpenseDocument,
    baseOptions
  );
}
export type GetExpenseQueryHookResult = ReturnType<typeof useGetExpenseQuery>;
export type GetExpenseLazyQueryHookResult = ReturnType<
  typeof useGetExpenseLazyQuery
>;
export type GetExpenseQueryResult = Apollo.QueryResult<
  GetExpenseQuery,
  GetExpenseQueryVariables
>;
export const GetFeeBlueprintsAndAppliedRecurringFeesDocument = gql`
  query getFeeBlueprintsAndAppliedRecurringFees(
    $agencyOrganisationId: ID!
    $blueprintType: FeeBlueprintType
    $managementId: ID!
    $managementAilorn: AiloRN!
    $cursor: PageCursorWithoutSort
  ) {
    blueprints: feeBlueprints(
      cursor: $cursor
      conditions: {
        organisationId: $agencyOrganisationId
        type: $blueprintType
        archived: false
      }
    ) {
      items {
        id
        ailorn: ailoRN
        name
        fixedAmount {
          cents
        }
        taxTreatment
        frequency
        anniversaryDay
        anniversaryMonth
        type
      }
    }
    recurringFees(
      conditions: {
        managementId: $managementAilorn
        currentness: [current, future]
        archived: false
      }
    ) {
      id: idV2
      ailoRN
      blueprint: blueprintV2 {
        id
        ailoRN
      }
    }
    management(managementId: $managementId) {
      id
      ailorn: ailoRN
      managingEntity {
        id
        ailorn: ailoRN
      }
      currentOrNextManagementAgreement {
        id
        ailorn: ailoRN
      }
    }
  }
`;

/**
 * __useGetFeeBlueprintsAndAppliedRecurringFeesQuery__
 *
 * To run a query within a React component, call `useGetFeeBlueprintsAndAppliedRecurringFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeeBlueprintsAndAppliedRecurringFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeeBlueprintsAndAppliedRecurringFeesQuery({
 *   variables: {
 *      agencyOrganisationId: // value for 'agencyOrganisationId'
 *      blueprintType: // value for 'blueprintType'
 *      managementId: // value for 'managementId'
 *      managementAilorn: // value for 'managementAilorn'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetFeeBlueprintsAndAppliedRecurringFeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeeBlueprintsAndAppliedRecurringFeesQuery,
    GetFeeBlueprintsAndAppliedRecurringFeesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetFeeBlueprintsAndAppliedRecurringFeesQuery,
    GetFeeBlueprintsAndAppliedRecurringFeesQueryVariables
  >(GetFeeBlueprintsAndAppliedRecurringFeesDocument, baseOptions);
}
export function useGetFeeBlueprintsAndAppliedRecurringFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeeBlueprintsAndAppliedRecurringFeesQuery,
    GetFeeBlueprintsAndAppliedRecurringFeesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetFeeBlueprintsAndAppliedRecurringFeesQuery,
    GetFeeBlueprintsAndAppliedRecurringFeesQueryVariables
  >(GetFeeBlueprintsAndAppliedRecurringFeesDocument, baseOptions);
}
export type GetFeeBlueprintsAndAppliedRecurringFeesQueryHookResult = ReturnType<
  typeof useGetFeeBlueprintsAndAppliedRecurringFeesQuery
>;
export type GetFeeBlueprintsAndAppliedRecurringFeesLazyQueryHookResult =
  ReturnType<typeof useGetFeeBlueprintsAndAppliedRecurringFeesLazyQuery>;
export type GetFeeBlueprintsAndAppliedRecurringFeesQueryResult =
  Apollo.QueryResult<
    GetFeeBlueprintsAndAppliedRecurringFeesQuery,
    GetFeeBlueprintsAndAppliedRecurringFeesQueryVariables
  >;
export const GetUpcomingAndPaidBillsAndFeesDocument = gql`
  query getUpcomingAndPaidBillsAndFees(
    $pageSize: Int
    $payerAilorn: AiloRN!
    $payerId: ID!
    $payerIsManagement: Boolean!
  ) {
    upcomingBills: bills(
      cursor: { pageSize: $pageSize }
      payerId: [$payerAilorn]
      paid: false
      status: [APPROVED, REFUNDED]
      taxCategoryIdNotIn: ["MANAGEMENT_FEES"]
      sort: { field: DueDate, direction: Asc }
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
        hasPrevious
        previousCursor
      }
      items {
        ...upcomingAndPaidBillFields
      }
    }
    paidBills: bills(
      cursor: { pageSize: $pageSize }
      payerId: [$payerAilorn]
      paid: true
      status: [APPROVED, REFUNDED]
      taxCategoryIdNotIn: ["MANAGEMENT_FEES"]
      sort: { field: CreatedAt, direction: Desc }
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
        hasPrevious
        previousCursor
      }
      items {
        ...upcomingAndPaidBillFields
      }
    }
    upcomingFees: fees(
      cursor: { pageSize: $pageSize }
      conditions: { managementId: [$payerId], status: [Due] }
      sort: [CREATED_AT_ASC]
    ) @include(if: $payerIsManagement) {
      pageInfo {
        hasMore
        nextCursor
        total
      }
      items {
        ...upcomingAndPaidFeeFields
      }
    }
    paidFees: fees(
      cursor: { pageSize: $pageSize }
      conditions: {
        managementId: [$payerId]
        status: [PaymentPending, Paid, Cancelled, RefundPending, Refunded]
      }
      sort: [CREATED_AT_DESC]
    ) @include(if: $payerIsManagement) {
      pageInfo {
        hasMore
        nextCursor
        total
      }
      items {
        ...upcomingAndPaidFeeFields
      }
    }
  }
  ${UpcomingAndPaidBillFieldsFragmentDoc}
  ${UpcomingAndPaidFeeFieldsFragmentDoc}
`;

/**
 * __useGetUpcomingAndPaidBillsAndFeesQuery__
 *
 * To run a query within a React component, call `useGetUpcomingAndPaidBillsAndFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingAndPaidBillsAndFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingAndPaidBillsAndFeesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      payerAilorn: // value for 'payerAilorn'
 *      payerId: // value for 'payerId'
 *      payerIsManagement: // value for 'payerIsManagement'
 *   },
 * });
 */
export function useGetUpcomingAndPaidBillsAndFeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUpcomingAndPaidBillsAndFeesQuery,
    GetUpcomingAndPaidBillsAndFeesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetUpcomingAndPaidBillsAndFeesQuery,
    GetUpcomingAndPaidBillsAndFeesQueryVariables
  >(GetUpcomingAndPaidBillsAndFeesDocument, baseOptions);
}
export function useGetUpcomingAndPaidBillsAndFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpcomingAndPaidBillsAndFeesQuery,
    GetUpcomingAndPaidBillsAndFeesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetUpcomingAndPaidBillsAndFeesQuery,
    GetUpcomingAndPaidBillsAndFeesQueryVariables
  >(GetUpcomingAndPaidBillsAndFeesDocument, baseOptions);
}
export type GetUpcomingAndPaidBillsAndFeesQueryHookResult = ReturnType<
  typeof useGetUpcomingAndPaidBillsAndFeesQuery
>;
export type GetUpcomingAndPaidBillsAndFeesLazyQueryHookResult = ReturnType<
  typeof useGetUpcomingAndPaidBillsAndFeesLazyQuery
>;
export type GetUpcomingAndPaidBillsAndFeesQueryResult = Apollo.QueryResult<
  GetUpcomingAndPaidBillsAndFeesQuery,
  GetUpcomingAndPaidBillsAndFeesQueryVariables
>;
export const CreateFeeDocument = gql`
  mutation createFee($input: CreateFeeInput!) {
    fee: createFee(input: $input) {
      id
    }
  }
`;
export type CreateFeeMutationFn = Apollo.MutationFunction<
  CreateFeeMutation,
  CreateFeeMutationVariables
>;

/**
 * __useCreateFeeMutation__
 *
 * To run a mutation, you first call `useCreateFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeeMutation, { data, loading, error }] = useCreateFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFeeMutation,
    CreateFeeMutationVariables
  >
) {
  return Apollo.useMutation<CreateFeeMutation, CreateFeeMutationVariables>(
    CreateFeeDocument,
    baseOptions
  );
}
export type CreateFeeMutationHookResult = ReturnType<
  typeof useCreateFeeMutation
>;
export type CreateFeeMutationResult = Apollo.MutationResult<CreateFeeMutation>;
export type CreateFeeMutationOptions = Apollo.BaseMutationOptions<
  CreateFeeMutation,
  CreateFeeMutationVariables
>;
export const CreateRecurringFeeDocument = gql`
  mutation createRecurringFee($input: RecurringFeeInput!) {
    createRecurringFee(input: $input) {
      id: idV2
      ailorn: ailoRN
      startDate
      frequency
      taxTreatment
      schedules {
        taxInclusiveAmount {
          cents
        }
      }
      blueprint: blueprintV2 {
        id
        ailorn: ailoRN
        name
      }
    }
  }
`;
export type CreateRecurringFeeMutationFn = Apollo.MutationFunction<
  CreateRecurringFeeMutation,
  CreateRecurringFeeMutationVariables
>;

/**
 * __useCreateRecurringFeeMutation__
 *
 * To run a mutation, you first call `useCreateRecurringFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringFeeMutation, { data, loading, error }] = useCreateRecurringFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecurringFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecurringFeeMutation,
    CreateRecurringFeeMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateRecurringFeeMutation,
    CreateRecurringFeeMutationVariables
  >(CreateRecurringFeeDocument, baseOptions);
}
export type CreateRecurringFeeMutationHookResult = ReturnType<
  typeof useCreateRecurringFeeMutation
>;
export type CreateRecurringFeeMutationResult =
  Apollo.MutationResult<CreateRecurringFeeMutation>;
export type CreateRecurringFeeMutationOptions = Apollo.BaseMutationOptions<
  CreateRecurringFeeMutation,
  CreateRecurringFeeMutationVariables
>;
export const CancelOneOffFeeDocument = gql`
  mutation cancelOneOffFee($input: UpdateFeeInput!) {
    updateFee(input: $input) {
      id
      ailoRN
      archivedAt
      status
    }
  }
`;
export type CancelOneOffFeeMutationFn = Apollo.MutationFunction<
  CancelOneOffFeeMutation,
  CancelOneOffFeeMutationVariables
>;

/**
 * __useCancelOneOffFeeMutation__
 *
 * To run a mutation, you first call `useCancelOneOffFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOneOffFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOneOffFeeMutation, { data, loading, error }] = useCancelOneOffFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelOneOffFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelOneOffFeeMutation,
    CancelOneOffFeeMutationVariables
  >
) {
  return Apollo.useMutation<
    CancelOneOffFeeMutation,
    CancelOneOffFeeMutationVariables
  >(CancelOneOffFeeDocument, baseOptions);
}
export type CancelOneOffFeeMutationHookResult = ReturnType<
  typeof useCancelOneOffFeeMutation
>;
export type CancelOneOffFeeMutationResult =
  Apollo.MutationResult<CancelOneOffFeeMutation>;
export type CancelOneOffFeeMutationOptions = Apollo.BaseMutationOptions<
  CancelOneOffFeeMutation,
  CancelOneOffFeeMutationVariables
>;
export const CancelRecurringFeeDocument = gql`
  mutation cancelRecurringFee($input: CancelRecurringFeeInput!) {
    cancelRecurringFee(input: $input) {
      id: ailoRN
    }
  }
`;
export type CancelRecurringFeeMutationFn = Apollo.MutationFunction<
  CancelRecurringFeeMutation,
  CancelRecurringFeeMutationVariables
>;

/**
 * __useCancelRecurringFeeMutation__
 *
 * To run a mutation, you first call `useCancelRecurringFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRecurringFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRecurringFeeMutation, { data, loading, error }] = useCancelRecurringFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelRecurringFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelRecurringFeeMutation,
    CancelRecurringFeeMutationVariables
  >
) {
  return Apollo.useMutation<
    CancelRecurringFeeMutation,
    CancelRecurringFeeMutationVariables
  >(CancelRecurringFeeDocument, baseOptions);
}
export type CancelRecurringFeeMutationHookResult = ReturnType<
  typeof useCancelRecurringFeeMutation
>;
export type CancelRecurringFeeMutationResult =
  Apollo.MutationResult<CancelRecurringFeeMutation>;
export type CancelRecurringFeeMutationOptions = Apollo.BaseMutationOptions<
  CancelRecurringFeeMutation,
  CancelRecurringFeeMutationVariables
>;
export const GetManagementRecurringFeesDocument = gql`
  query getManagementRecurringFees(
    $managementId: ID!
    $managementAiloRN: AiloRN!
  ) {
    management(managementId: $managementId) {
      id
      currentOrNextManagementFeeSchedule {
        ...managementFeeScheduleFields
      }
      currentOrNextManagementAgreement {
        id
      }
      ...ManagementProperty
    }
    recurringFees(
      conditions: {
        managementId: $managementAiloRN
        paymentStatus: [overdue, paidForNow]
        archived: false
      }
    ) {
      ...recurringFeeFields
      liability {
        reference
        overdueAmount {
          cents
        }
      }
    }
  }
  ${ManagementFeeScheduleFieldsFragmentDoc}
  ${ManagementPropertyFragmentDoc}
  ${RecurringFeeFieldsFragmentDoc}
`;

/**
 * __useGetManagementRecurringFeesQuery__
 *
 * To run a query within a React component, call `useGetManagementRecurringFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementRecurringFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementRecurringFeesQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *      managementAiloRN: // value for 'managementAiloRN'
 *   },
 * });
 */
export function useGetManagementRecurringFeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementRecurringFeesQuery,
    GetManagementRecurringFeesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementRecurringFeesQuery,
    GetManagementRecurringFeesQueryVariables
  >(GetManagementRecurringFeesDocument, baseOptions);
}
export function useGetManagementRecurringFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementRecurringFeesQuery,
    GetManagementRecurringFeesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementRecurringFeesQuery,
    GetManagementRecurringFeesQueryVariables
  >(GetManagementRecurringFeesDocument, baseOptions);
}
export type GetManagementRecurringFeesQueryHookResult = ReturnType<
  typeof useGetManagementRecurringFeesQuery
>;
export type GetManagementRecurringFeesLazyQueryHookResult = ReturnType<
  typeof useGetManagementRecurringFeesLazyQuery
>;
export type GetManagementRecurringFeesQueryResult = Apollo.QueryResult<
  GetManagementRecurringFeesQuery,
  GetManagementRecurringFeesQueryVariables
>;
export const ArchiveManagementFeeBlueprintDocument = gql`
  mutation archiveManagementFeeBlueprint($managementFeeBlueprintId: ID!) {
    updateManagementFeeBlueprint(
      input: { id: $managementFeeBlueprintId, archived: true }
    ) {
      id
      ailorn
      fixedAmount {
        cents
      }
      taxTreatment
      oneWeekRentPercentage
      feeBlueprint {
        id
        ailorn: ailoRN
        name
      }
    }
  }
`;
export type ArchiveManagementFeeBlueprintMutationFn = Apollo.MutationFunction<
  ArchiveManagementFeeBlueprintMutation,
  ArchiveManagementFeeBlueprintMutationVariables
>;

/**
 * __useArchiveManagementFeeBlueprintMutation__
 *
 * To run a mutation, you first call `useArchiveManagementFeeBlueprintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveManagementFeeBlueprintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveManagementFeeBlueprintMutation, { data, loading, error }] = useArchiveManagementFeeBlueprintMutation({
 *   variables: {
 *      managementFeeBlueprintId: // value for 'managementFeeBlueprintId'
 *   },
 * });
 */
export function useArchiveManagementFeeBlueprintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveManagementFeeBlueprintMutation,
    ArchiveManagementFeeBlueprintMutationVariables
  >
) {
  return Apollo.useMutation<
    ArchiveManagementFeeBlueprintMutation,
    ArchiveManagementFeeBlueprintMutationVariables
  >(ArchiveManagementFeeBlueprintDocument, baseOptions);
}
export type ArchiveManagementFeeBlueprintMutationHookResult = ReturnType<
  typeof useArchiveManagementFeeBlueprintMutation
>;
export type ArchiveManagementFeeBlueprintMutationResult =
  Apollo.MutationResult<ArchiveManagementFeeBlueprintMutation>;
export type ArchiveManagementFeeBlueprintMutationOptions =
  Apollo.BaseMutationOptions<
    ArchiveManagementFeeBlueprintMutation,
    ArchiveManagementFeeBlueprintMutationVariables
  >;
export const CreateManagementFeeBlueprintDocument = gql`
  mutation createManagementFeeBlueprint(
    $input: CreateManagementFeeBlueprintInput!
  ) {
    createManagementFeeBlueprint(input: $input) {
      id
      ailorn
      fixedAmount {
        cents
      }
      taxTreatment
      oneWeekRentPercentage
      feeBlueprint {
        id
        ailorn: ailoRN
        name
        type
      }
      description
    }
  }
`;
export type CreateManagementFeeBlueprintMutationFn = Apollo.MutationFunction<
  CreateManagementFeeBlueprintMutation,
  CreateManagementFeeBlueprintMutationVariables
>;

/**
 * __useCreateManagementFeeBlueprintMutation__
 *
 * To run a mutation, you first call `useCreateManagementFeeBlueprintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagementFeeBlueprintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagementFeeBlueprintMutation, { data, loading, error }] = useCreateManagementFeeBlueprintMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManagementFeeBlueprintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManagementFeeBlueprintMutation,
    CreateManagementFeeBlueprintMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateManagementFeeBlueprintMutation,
    CreateManagementFeeBlueprintMutationVariables
  >(CreateManagementFeeBlueprintDocument, baseOptions);
}
export type CreateManagementFeeBlueprintMutationHookResult = ReturnType<
  typeof useCreateManagementFeeBlueprintMutation
>;
export type CreateManagementFeeBlueprintMutationResult =
  Apollo.MutationResult<CreateManagementFeeBlueprintMutation>;
export type CreateManagementFeeBlueprintMutationOptions =
  Apollo.BaseMutationOptions<
    CreateManagementFeeBlueprintMutation,
    CreateManagementFeeBlueprintMutationVariables
  >;
export const UpdateManagementFeeBlueprintDocument = gql`
  mutation updateManagementFeeBlueprint(
    $input: UpdateManagementFeeBlueprintInput!
  ) {
    updateManagementFeeBlueprint(input: $input) {
      id
      ailorn
      fixedAmount {
        cents
      }
      taxTreatment
      oneWeekRentPercentage
      feeBlueprint {
        id
        ailorn: ailoRN
        name
      }
      description
    }
  }
`;
export type UpdateManagementFeeBlueprintMutationFn = Apollo.MutationFunction<
  UpdateManagementFeeBlueprintMutation,
  UpdateManagementFeeBlueprintMutationVariables
>;

/**
 * __useUpdateManagementFeeBlueprintMutation__
 *
 * To run a mutation, you first call `useUpdateManagementFeeBlueprintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagementFeeBlueprintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagementFeeBlueprintMutation, { data, loading, error }] = useUpdateManagementFeeBlueprintMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManagementFeeBlueprintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManagementFeeBlueprintMutation,
    UpdateManagementFeeBlueprintMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateManagementFeeBlueprintMutation,
    UpdateManagementFeeBlueprintMutationVariables
  >(UpdateManagementFeeBlueprintDocument, baseOptions);
}
export type UpdateManagementFeeBlueprintMutationHookResult = ReturnType<
  typeof useUpdateManagementFeeBlueprintMutation
>;
export type UpdateManagementFeeBlueprintMutationResult =
  Apollo.MutationResult<UpdateManagementFeeBlueprintMutation>;
export type UpdateManagementFeeBlueprintMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateManagementFeeBlueprintMutation,
    UpdateManagementFeeBlueprintMutationVariables
  >;
export const GetManagementFeeBlueprintsDocument = gql`
  query getManagementFeeBlueprints(
    $conditions: ManagementFeeBlueprintsQueryConditions
    $cursor: PageCursorWithoutSort
  ) {
    managementFeeBlueprints(conditions: $conditions, cursor: $cursor) {
      items {
        id
        ailorn
        feeBlueprint {
          id
          ailorn: ailoRN
          name
          fixedAmount {
            cents
          }
          event {
            type
            shortDescription
            longDescription
          }
          type
          oneWeekRentPercentage
          taxCategory {
            id
            name
          }
          taxTreatment
        }
        fixedAmount {
          cents
        }
        oneWeekRentPercentage
        taxTreatment
        description
      }
    }
  }
`;

/**
 * __useGetManagementFeeBlueprintsQuery__
 *
 * To run a query within a React component, call `useGetManagementFeeBlueprintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementFeeBlueprintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementFeeBlueprintsQuery({
 *   variables: {
 *      conditions: // value for 'conditions'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetManagementFeeBlueprintsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManagementFeeBlueprintsQuery,
    GetManagementFeeBlueprintsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementFeeBlueprintsQuery,
    GetManagementFeeBlueprintsQueryVariables
  >(GetManagementFeeBlueprintsDocument, baseOptions);
}
export function useGetManagementFeeBlueprintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementFeeBlueprintsQuery,
    GetManagementFeeBlueprintsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementFeeBlueprintsQuery,
    GetManagementFeeBlueprintsQueryVariables
  >(GetManagementFeeBlueprintsDocument, baseOptions);
}
export type GetManagementFeeBlueprintsQueryHookResult = ReturnType<
  typeof useGetManagementFeeBlueprintsQuery
>;
export type GetManagementFeeBlueprintsLazyQueryHookResult = ReturnType<
  typeof useGetManagementFeeBlueprintsLazyQuery
>;
export type GetManagementFeeBlueprintsQueryResult = Apollo.QueryResult<
  GetManagementFeeBlueprintsQuery,
  GetManagementFeeBlueprintsQueryVariables
>;
export const GetClaimedFilesCountDocument = gql`
  query GetClaimedFilesCount($claimerAilorn: AiloRN!, $fileKind: FileKind) {
    claimedFilesCount(claimerAilorn: $claimerAilorn, fileKind: $fileKind) {
      totalFiles
      validFiles
    }
  }
`;

/**
 * __useGetClaimedFilesCountQuery__
 *
 * To run a query within a React component, call `useGetClaimedFilesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimedFilesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimedFilesCountQuery({
 *   variables: {
 *      claimerAilorn: // value for 'claimerAilorn'
 *      fileKind: // value for 'fileKind'
 *   },
 * });
 */
export function useGetClaimedFilesCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClaimedFilesCountQuery,
    GetClaimedFilesCountQueryVariables
  >
) {
  return Apollo.useQuery<
    GetClaimedFilesCountQuery,
    GetClaimedFilesCountQueryVariables
  >(GetClaimedFilesCountDocument, baseOptions);
}
export function useGetClaimedFilesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClaimedFilesCountQuery,
    GetClaimedFilesCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetClaimedFilesCountQuery,
    GetClaimedFilesCountQueryVariables
  >(GetClaimedFilesCountDocument, baseOptions);
}
export type GetClaimedFilesCountQueryHookResult = ReturnType<
  typeof useGetClaimedFilesCountQuery
>;
export type GetClaimedFilesCountLazyQueryHookResult = ReturnType<
  typeof useGetClaimedFilesCountLazyQuery
>;
export type GetClaimedFilesCountQueryResult = Apollo.QueryResult<
  GetClaimedFilesCountQuery,
  GetClaimedFilesCountQueryVariables
>;
export const GenerateInspectionReportDocument = gql`
  mutation GenerateInspectionReport($inspectionId: String!) {
    generateInspectionReport(
      generateInspectionReportInput: { inspectionId: $inspectionId }
    ) {
      inspection {
        id
        ...InspectionReportState
        ...InspectionLatestReportProgress
      }
    }
  }
  ${InspectionReportStateFragmentDoc}
  ${InspectionLatestReportProgressFragmentDoc}
`;
export type GenerateInspectionReportMutationFn = Apollo.MutationFunction<
  GenerateInspectionReportMutation,
  GenerateInspectionReportMutationVariables
>;

/**
 * __useGenerateInspectionReportMutation__
 *
 * To run a mutation, you first call `useGenerateInspectionReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateInspectionReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateInspectionReportMutation, { data, loading, error }] = useGenerateInspectionReportMutation({
 *   variables: {
 *      inspectionId: // value for 'inspectionId'
 *   },
 * });
 */
export function useGenerateInspectionReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateInspectionReportMutation,
    GenerateInspectionReportMutationVariables
  >
) {
  return Apollo.useMutation<
    GenerateInspectionReportMutation,
    GenerateInspectionReportMutationVariables
  >(GenerateInspectionReportDocument, baseOptions);
}
export type GenerateInspectionReportMutationHookResult = ReturnType<
  typeof useGenerateInspectionReportMutation
>;
export type GenerateInspectionReportMutationResult =
  Apollo.MutationResult<GenerateInspectionReportMutation>;
export type GenerateInspectionReportMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateInspectionReportMutation,
    GenerateInspectionReportMutationVariables
  >;
export const GetInspectionDocument = gql`
  query GetInspection($id: ID!) {
    inspection(id: $id) {
      ...Inspection
    }
  }
  ${InspectionFragmentDoc}
`;

/**
 * __useGetInspectionQuery__
 *
 * To run a query within a React component, call `useGetInspectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInspectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInspectionQuery,
    GetInspectionQueryVariables
  >
) {
  return Apollo.useQuery<GetInspectionQuery, GetInspectionQueryVariables>(
    GetInspectionDocument,
    baseOptions
  );
}
export function useGetInspectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInspectionQuery,
    GetInspectionQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetInspectionQuery, GetInspectionQueryVariables>(
    GetInspectionDocument,
    baseOptions
  );
}
export type GetInspectionQueryHookResult = ReturnType<
  typeof useGetInspectionQuery
>;
export type GetInspectionLazyQueryHookResult = ReturnType<
  typeof useGetInspectionLazyQuery
>;
export type GetInspectionQueryResult = Apollo.QueryResult<
  GetInspectionQuery,
  GetInspectionQueryVariables
>;
export const GetInspectionReportStateDocument = gql`
  query getInspectionReportState($id: ID!) {
    inspection(id: $id) {
      id
      ...InspectionReportState
      ...InspectionLatestReportProgress
    }
  }
  ${InspectionReportStateFragmentDoc}
  ${InspectionLatestReportProgressFragmentDoc}
`;

/**
 * __useGetInspectionReportStateQuery__
 *
 * To run a query within a React component, call `useGetInspectionReportStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspectionReportStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspectionReportStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInspectionReportStateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInspectionReportStateQuery,
    GetInspectionReportStateQueryVariables
  >
) {
  return Apollo.useQuery<
    GetInspectionReportStateQuery,
    GetInspectionReportStateQueryVariables
  >(GetInspectionReportStateDocument, baseOptions);
}
export function useGetInspectionReportStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInspectionReportStateQuery,
    GetInspectionReportStateQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetInspectionReportStateQuery,
    GetInspectionReportStateQueryVariables
  >(GetInspectionReportStateDocument, baseOptions);
}
export type GetInspectionReportStateQueryHookResult = ReturnType<
  typeof useGetInspectionReportStateQuery
>;
export type GetInspectionReportStateLazyQueryHookResult = ReturnType<
  typeof useGetInspectionReportStateLazyQuery
>;
export type GetInspectionReportStateQueryResult = Apollo.QueryResult<
  GetInspectionReportStateQuery,
  GetInspectionReportStateQueryVariables
>;
export const GetPendingRoutineInspectionAppointmentsDocument = gql`
  query getPendingRoutineInspectionAppointments(
    $inspectingAgentAilorn: AiloRN!
    $organisationAilorn: AiloRN!
  ) {
    inspectionAppointmentConnection(
      filter: {
        inspectingAgentAilorn: { eq: $inspectingAgentAilorn }
        inspection: { id: { is: null } }
        startTime: { isNot: null }
      }
      paging: { first: 50 }
      sorting: [{ field: startTime, direction: DESC }]
    ) {
      edges {
        node {
          ...PendingInspectionAppointment
          property {
            ...PropertyKeys
          }
        }
      }
    }
  }
  ${PendingInspectionAppointmentFragmentDoc}
  ${PropertyKeysFragmentDoc}
`;

/**
 * __useGetPendingRoutineInspectionAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetPendingRoutineInspectionAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingRoutineInspectionAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingRoutineInspectionAppointmentsQuery({
 *   variables: {
 *      inspectingAgentAilorn: // value for 'inspectingAgentAilorn'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useGetPendingRoutineInspectionAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPendingRoutineInspectionAppointmentsQuery,
    GetPendingRoutineInspectionAppointmentsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPendingRoutineInspectionAppointmentsQuery,
    GetPendingRoutineInspectionAppointmentsQueryVariables
  >(GetPendingRoutineInspectionAppointmentsDocument, baseOptions);
}
export function useGetPendingRoutineInspectionAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPendingRoutineInspectionAppointmentsQuery,
    GetPendingRoutineInspectionAppointmentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPendingRoutineInspectionAppointmentsQuery,
    GetPendingRoutineInspectionAppointmentsQueryVariables
  >(GetPendingRoutineInspectionAppointmentsDocument, baseOptions);
}
export type GetPendingRoutineInspectionAppointmentsQueryHookResult = ReturnType<
  typeof useGetPendingRoutineInspectionAppointmentsQuery
>;
export type GetPendingRoutineInspectionAppointmentsLazyQueryHookResult =
  ReturnType<typeof useGetPendingRoutineInspectionAppointmentsLazyQuery>;
export type GetPendingRoutineInspectionAppointmentsQueryResult =
  Apollo.QueryResult<
    GetPendingRoutineInspectionAppointmentsQuery,
    GetPendingRoutineInspectionAppointmentsQueryVariables
  >;
export const CreateTenancyLedgerStatementDocument = gql`
  mutation createTenancyLedgerStatement(
    $tenancyAiloRN: AiloRN!
    $startDate: Date!
    $endDate: Date!
  ) {
    createStatementsWithSubject(
      input: {
        type: TenancyLedger
        startDate: $startDate
        endDate: $endDate
        sendEmail: false
        statementSubjectAiloRNs: [$tenancyAiloRN]
        skipManagementHasPaymentsCheck: true
        createdFor: ManualRequest
      }
    ) {
      statementProgresses {
        id
      }
    }
  }
`;
export type CreateTenancyLedgerStatementMutationFn = Apollo.MutationFunction<
  CreateTenancyLedgerStatementMutation,
  CreateTenancyLedgerStatementMutationVariables
>;

/**
 * __useCreateTenancyLedgerStatementMutation__
 *
 * To run a mutation, you first call `useCreateTenancyLedgerStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenancyLedgerStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenancyLedgerStatementMutation, { data, loading, error }] = useCreateTenancyLedgerStatementMutation({
 *   variables: {
 *      tenancyAiloRN: // value for 'tenancyAiloRN'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCreateTenancyLedgerStatementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTenancyLedgerStatementMutation,
    CreateTenancyLedgerStatementMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateTenancyLedgerStatementMutation,
    CreateTenancyLedgerStatementMutationVariables
  >(CreateTenancyLedgerStatementDocument, baseOptions);
}
export type CreateTenancyLedgerStatementMutationHookResult = ReturnType<
  typeof useCreateTenancyLedgerStatementMutation
>;
export type CreateTenancyLedgerStatementMutationResult =
  Apollo.MutationResult<CreateTenancyLedgerStatementMutation>;
export type CreateTenancyLedgerStatementMutationOptions =
  Apollo.BaseMutationOptions<
    CreateTenancyLedgerStatementMutation,
    CreateTenancyLedgerStatementMutationVariables
  >;
export const GetTenancyStartAndEndDatesDocument = gql`
  query getTenancyStartAndEndDates($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      startDate
      endDate
    }
  }
`;

/**
 * __useGetTenancyStartAndEndDatesQuery__
 *
 * To run a query within a React component, call `useGetTenancyStartAndEndDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenancyStartAndEndDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenancyStartAndEndDatesQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetTenancyStartAndEndDatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenancyStartAndEndDatesQuery,
    GetTenancyStartAndEndDatesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenancyStartAndEndDatesQuery,
    GetTenancyStartAndEndDatesQueryVariables
  >(GetTenancyStartAndEndDatesDocument, baseOptions);
}
export function useGetTenancyStartAndEndDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenancyStartAndEndDatesQuery,
    GetTenancyStartAndEndDatesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenancyStartAndEndDatesQuery,
    GetTenancyStartAndEndDatesQueryVariables
  >(GetTenancyStartAndEndDatesDocument, baseOptions);
}
export type GetTenancyStartAndEndDatesQueryHookResult = ReturnType<
  typeof useGetTenancyStartAndEndDatesQuery
>;
export type GetTenancyStartAndEndDatesLazyQueryHookResult = ReturnType<
  typeof useGetTenancyStartAndEndDatesLazyQuery
>;
export type GetTenancyStartAndEndDatesQueryResult = Apollo.QueryResult<
  GetTenancyStartAndEndDatesQuery,
  GetTenancyStartAndEndDatesQueryVariables
>;
export const GetStatementFileUrlDocument = gql`
  query getStatementFileUrl($statementProgressId: ID!) {
    statementProgress(id: $statementProgressId) {
      isSuccessful
      statement {
        id
        file {
          id
          url
        }
      }
    }
  }
`;

/**
 * __useGetStatementFileUrlQuery__
 *
 * To run a query within a React component, call `useGetStatementFileUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatementFileUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatementFileUrlQuery({
 *   variables: {
 *      statementProgressId: // value for 'statementProgressId'
 *   },
 * });
 */
export function useGetStatementFileUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStatementFileUrlQuery,
    GetStatementFileUrlQueryVariables
  >
) {
  return Apollo.useQuery<
    GetStatementFileUrlQuery,
    GetStatementFileUrlQueryVariables
  >(GetStatementFileUrlDocument, baseOptions);
}
export function useGetStatementFileUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStatementFileUrlQuery,
    GetStatementFileUrlQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetStatementFileUrlQuery,
    GetStatementFileUrlQueryVariables
  >(GetStatementFileUrlDocument, baseOptions);
}
export type GetStatementFileUrlQueryHookResult = ReturnType<
  typeof useGetStatementFileUrlQuery
>;
export type GetStatementFileUrlLazyQueryHookResult = ReturnType<
  typeof useGetStatementFileUrlLazyQuery
>;
export type GetStatementFileUrlQueryResult = Apollo.QueryResult<
  GetStatementFileUrlQuery,
  GetStatementFileUrlQueryVariables
>;
export const GetManagementPortfolioTeamsDocument = gql`
  query getManagementPortfolioTeams(
    $managementPortfolioId: ID!
    $managementsFilter: ManagementFolioManagementFilter
  ) {
    managementFolio(id: $managementPortfolioId) {
      allManagements(filter: $managementsFilter) {
        items {
          management {
            team {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetManagementPortfolioTeamsQuery__
 *
 * To run a query within a React component, call `useGetManagementPortfolioTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementPortfolioTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementPortfolioTeamsQuery({
 *   variables: {
 *      managementPortfolioId: // value for 'managementPortfolioId'
 *      managementsFilter: // value for 'managementsFilter'
 *   },
 * });
 */
export function useGetManagementPortfolioTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementPortfolioTeamsQuery,
    GetManagementPortfolioTeamsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementPortfolioTeamsQuery,
    GetManagementPortfolioTeamsQueryVariables
  >(GetManagementPortfolioTeamsDocument, baseOptions);
}
export function useGetManagementPortfolioTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementPortfolioTeamsQuery,
    GetManagementPortfolioTeamsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementPortfolioTeamsQuery,
    GetManagementPortfolioTeamsQueryVariables
  >(GetManagementPortfolioTeamsDocument, baseOptions);
}
export type GetManagementPortfolioTeamsQueryHookResult = ReturnType<
  typeof useGetManagementPortfolioTeamsQuery
>;
export type GetManagementPortfolioTeamsLazyQueryHookResult = ReturnType<
  typeof useGetManagementPortfolioTeamsLazyQuery
>;
export type GetManagementPortfolioTeamsQueryResult = Apollo.QueryResult<
  GetManagementPortfolioTeamsQuery,
  GetManagementPortfolioTeamsQueryVariables
>;
export const GetManagementPortfolioOwnersDocument = gql`
  query getManagementPortfolioOwners(
    $managementPortfolioId: ID!
    $organisationAilorn: AiloRN!
  ) {
    managementFolio(id: $managementPortfolioId) {
      id
      owners {
        isPrimary
        owner {
          __typename
          id
          contact(organisationAilorn: $organisationAilorn) {
            id
            ailorn
          }
          ...PersonDisplayDetails
          ...CompanyDisplayDetails
          ... on Person {
            phoneNo
            emailAddress
            user {
              id
              onboardingCompletedAt
            }
          }
          ... on Company {
            registeredEntityId
          }
        }
      }
    }
  }
  ${PersonDisplayDetailsFragmentDoc}
  ${CompanyDisplayDetailsFragmentDoc}
`;

/**
 * __useGetManagementPortfolioOwnersQuery__
 *
 * To run a query within a React component, call `useGetManagementPortfolioOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementPortfolioOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementPortfolioOwnersQuery({
 *   variables: {
 *      managementPortfolioId: // value for 'managementPortfolioId'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useGetManagementPortfolioOwnersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementPortfolioOwnersQuery,
    GetManagementPortfolioOwnersQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementPortfolioOwnersQuery,
    GetManagementPortfolioOwnersQueryVariables
  >(GetManagementPortfolioOwnersDocument, baseOptions);
}
export function useGetManagementPortfolioOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementPortfolioOwnersQuery,
    GetManagementPortfolioOwnersQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementPortfolioOwnersQuery,
    GetManagementPortfolioOwnersQueryVariables
  >(GetManagementPortfolioOwnersDocument, baseOptions);
}
export type GetManagementPortfolioOwnersQueryHookResult = ReturnType<
  typeof useGetManagementPortfolioOwnersQuery
>;
export type GetManagementPortfolioOwnersLazyQueryHookResult = ReturnType<
  typeof useGetManagementPortfolioOwnersLazyQuery
>;
export type GetManagementPortfolioOwnersQueryResult = Apollo.QueryResult<
  GetManagementPortfolioOwnersQuery,
  GetManagementPortfolioOwnersQueryVariables
>;
export const GetManagementPortfolioPropertiesDocument = gql`
  query getManagementPortfolioProperties(
    $managementPortfolioId: ID!
    $managementsFilter: ManagementFolioManagementFilter
  ) {
    managementFolio(id: $managementPortfolioId) {
      allManagements(filter: $managementsFilter) {
        items {
          management {
            ...ManagementProperty
            occupancyStatus
            mostRecentTenancy {
              endDate
            }
          }
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useGetManagementPortfolioPropertiesQuery__
 *
 * To run a query within a React component, call `useGetManagementPortfolioPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementPortfolioPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementPortfolioPropertiesQuery({
 *   variables: {
 *      managementPortfolioId: // value for 'managementPortfolioId'
 *      managementsFilter: // value for 'managementsFilter'
 *   },
 * });
 */
export function useGetManagementPortfolioPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementPortfolioPropertiesQuery,
    GetManagementPortfolioPropertiesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementPortfolioPropertiesQuery,
    GetManagementPortfolioPropertiesQueryVariables
  >(GetManagementPortfolioPropertiesDocument, baseOptions);
}
export function useGetManagementPortfolioPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementPortfolioPropertiesQuery,
    GetManagementPortfolioPropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementPortfolioPropertiesQuery,
    GetManagementPortfolioPropertiesQueryVariables
  >(GetManagementPortfolioPropertiesDocument, baseOptions);
}
export type GetManagementPortfolioPropertiesQueryHookResult = ReturnType<
  typeof useGetManagementPortfolioPropertiesQuery
>;
export type GetManagementPortfolioPropertiesLazyQueryHookResult = ReturnType<
  typeof useGetManagementPortfolioPropertiesLazyQuery
>;
export type GetManagementPortfolioPropertiesQueryResult = Apollo.QueryResult<
  GetManagementPortfolioPropertiesQuery,
  GetManagementPortfolioPropertiesQueryVariables
>;
export const GetPagedApprovedMigratingManagementsDocument = gql`
  query getPagedApprovedMigratingManagements(
    $cursor: String
    $before: Boolean!
    $pageSize: Int!
    $sort: [MigratingManagementSortParams!]!
    $organisationId: AiloRN!
    $status: [String!]!
  ) {
    migratingManagements(
      pagination: { pageSize: $pageSize, cursor: $cursor, before: $before }
      filters: { organisationId: $organisationId, status: $status }
      sort: $sort
    ) {
      pageInfo {
        hasNext
        nextCursor
        hasPrevious
        previousCursor
        total
      }
      items {
        ...ApprovedMigratingManagement
      }
    }
  }
  ${ApprovedMigratingManagementFragmentDoc}
`;

/**
 * __useGetPagedApprovedMigratingManagementsQuery__
 *
 * To run a query within a React component, call `useGetPagedApprovedMigratingManagementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedApprovedMigratingManagementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedApprovedMigratingManagementsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      before: // value for 'before'
 *      pageSize: // value for 'pageSize'
 *      sort: // value for 'sort'
 *      organisationId: // value for 'organisationId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetPagedApprovedMigratingManagementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagedApprovedMigratingManagementsQuery,
    GetPagedApprovedMigratingManagementsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPagedApprovedMigratingManagementsQuery,
    GetPagedApprovedMigratingManagementsQueryVariables
  >(GetPagedApprovedMigratingManagementsDocument, baseOptions);
}
export function useGetPagedApprovedMigratingManagementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagedApprovedMigratingManagementsQuery,
    GetPagedApprovedMigratingManagementsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPagedApprovedMigratingManagementsQuery,
    GetPagedApprovedMigratingManagementsQueryVariables
  >(GetPagedApprovedMigratingManagementsDocument, baseOptions);
}
export type GetPagedApprovedMigratingManagementsQueryHookResult = ReturnType<
  typeof useGetPagedApprovedMigratingManagementsQuery
>;
export type GetPagedApprovedMigratingManagementsLazyQueryHookResult =
  ReturnType<typeof useGetPagedApprovedMigratingManagementsLazyQuery>;
export type GetPagedApprovedMigratingManagementsQueryResult =
  Apollo.QueryResult<
    GetPagedApprovedMigratingManagementsQuery,
    GetPagedApprovedMigratingManagementsQueryVariables
  >;
export const GetPagedAwaitingApprovalMigratingManagementsDocument = gql`
  query getPagedAwaitingApprovalMigratingManagements(
    $cursor: String
    $before: Boolean!
    $pageSize: Int!
    $sort: [MigratingManagementSortParams!]!
    $organisationId: AiloRN!
    $addressSearch: String
    $portfolio: MigratingManagementsPortfolioFilter
    $withErrors: Boolean
  ) {
    migratingManagements(
      pagination: { pageSize: $pageSize, cursor: $cursor, before: $before }
      filters: {
        organisationId: $organisationId
        status: "IMPORTED"
        addressSearch: $addressSearch
        portfolio: $portfolio
        withErrors: $withErrors
      }
      sort: $sort
    ) {
      pageInfo {
        hasNext
        nextCursor
        hasPrevious
        previousCursor
        total
      }
      items {
        ...AwaitingApprovalMigratingManagement
      }
    }
  }
  ${AwaitingApprovalMigratingManagementFragmentDoc}
`;

/**
 * __useGetPagedAwaitingApprovalMigratingManagementsQuery__
 *
 * To run a query within a React component, call `useGetPagedAwaitingApprovalMigratingManagementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedAwaitingApprovalMigratingManagementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedAwaitingApprovalMigratingManagementsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      before: // value for 'before'
 *      pageSize: // value for 'pageSize'
 *      sort: // value for 'sort'
 *      organisationId: // value for 'organisationId'
 *      addressSearch: // value for 'addressSearch'
 *      portfolio: // value for 'portfolio'
 *      withErrors: // value for 'withErrors'
 *   },
 * });
 */
export function useGetPagedAwaitingApprovalMigratingManagementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagedAwaitingApprovalMigratingManagementsQuery,
    GetPagedAwaitingApprovalMigratingManagementsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPagedAwaitingApprovalMigratingManagementsQuery,
    GetPagedAwaitingApprovalMigratingManagementsQueryVariables
  >(GetPagedAwaitingApprovalMigratingManagementsDocument, baseOptions);
}
export function useGetPagedAwaitingApprovalMigratingManagementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagedAwaitingApprovalMigratingManagementsQuery,
    GetPagedAwaitingApprovalMigratingManagementsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPagedAwaitingApprovalMigratingManagementsQuery,
    GetPagedAwaitingApprovalMigratingManagementsQueryVariables
  >(GetPagedAwaitingApprovalMigratingManagementsDocument, baseOptions);
}
export type GetPagedAwaitingApprovalMigratingManagementsQueryHookResult =
  ReturnType<typeof useGetPagedAwaitingApprovalMigratingManagementsQuery>;
export type GetPagedAwaitingApprovalMigratingManagementsLazyQueryHookResult =
  ReturnType<typeof useGetPagedAwaitingApprovalMigratingManagementsLazyQuery>;
export type GetPagedAwaitingApprovalMigratingManagementsQueryResult =
  Apollo.QueryResult<
    GetPagedAwaitingApprovalMigratingManagementsQuery,
    GetPagedAwaitingApprovalMigratingManagementsQueryVariables
  >;
export const GetPagedExcludedMigratingManagementsDocument = gql`
  query getPagedExcludedMigratingManagements(
    $cursor: String
    $before: Boolean!
    $pageSize: Int!
    $sort: [MigratingManagementSortParams!]!
    $organisationId: AiloRN!
  ) {
    migratingManagements(
      pagination: { pageSize: $pageSize, cursor: $cursor, before: $before }
      filters: { organisationId: $organisationId, status: "EXCLUDED" }
      sort: $sort
    ) {
      pageInfo {
        hasNext
        nextCursor
        hasPrevious
        previousCursor
        total
      }
      items {
        ...ExcludedMigratingManagement
      }
    }
  }
  ${ExcludedMigratingManagementFragmentDoc}
`;

/**
 * __useGetPagedExcludedMigratingManagementsQuery__
 *
 * To run a query within a React component, call `useGetPagedExcludedMigratingManagementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedExcludedMigratingManagementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedExcludedMigratingManagementsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      before: // value for 'before'
 *      pageSize: // value for 'pageSize'
 *      sort: // value for 'sort'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetPagedExcludedMigratingManagementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagedExcludedMigratingManagementsQuery,
    GetPagedExcludedMigratingManagementsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPagedExcludedMigratingManagementsQuery,
    GetPagedExcludedMigratingManagementsQueryVariables
  >(GetPagedExcludedMigratingManagementsDocument, baseOptions);
}
export function useGetPagedExcludedMigratingManagementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagedExcludedMigratingManagementsQuery,
    GetPagedExcludedMigratingManagementsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPagedExcludedMigratingManagementsQuery,
    GetPagedExcludedMigratingManagementsQueryVariables
  >(GetPagedExcludedMigratingManagementsDocument, baseOptions);
}
export type GetPagedExcludedMigratingManagementsQueryHookResult = ReturnType<
  typeof useGetPagedExcludedMigratingManagementsQuery
>;
export type GetPagedExcludedMigratingManagementsLazyQueryHookResult =
  ReturnType<typeof useGetPagedExcludedMigratingManagementsLazyQuery>;
export type GetPagedExcludedMigratingManagementsQueryResult =
  Apollo.QueryResult<
    GetPagedExcludedMigratingManagementsQuery,
    GetPagedExcludedMigratingManagementsQueryVariables
  >;
export const GetPagedLiveMigratingManagementsDocument = gql`
  query getPagedLiveMigratingManagements(
    $cursor: String
    $before: Boolean!
    $pageSize: Int!
    $sort: [MigratingManagementSortParams!]!
    $organisationId: AiloRN!
  ) {
    migratingManagements(
      pagination: { pageSize: $pageSize, cursor: $cursor, before: $before }
      filters: { organisationId: $organisationId, status: "CREATED" }
      sort: $sort
    ) {
      pageInfo {
        hasNext
        nextCursor
        hasPrevious
        previousCursor
        total
      }
      items {
        ...LiveMigratingManagement
      }
    }
  }
  ${LiveMigratingManagementFragmentDoc}
`;

/**
 * __useGetPagedLiveMigratingManagementsQuery__
 *
 * To run a query within a React component, call `useGetPagedLiveMigratingManagementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedLiveMigratingManagementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedLiveMigratingManagementsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      before: // value for 'before'
 *      pageSize: // value for 'pageSize'
 *      sort: // value for 'sort'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetPagedLiveMigratingManagementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagedLiveMigratingManagementsQuery,
    GetPagedLiveMigratingManagementsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPagedLiveMigratingManagementsQuery,
    GetPagedLiveMigratingManagementsQueryVariables
  >(GetPagedLiveMigratingManagementsDocument, baseOptions);
}
export function useGetPagedLiveMigratingManagementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagedLiveMigratingManagementsQuery,
    GetPagedLiveMigratingManagementsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPagedLiveMigratingManagementsQuery,
    GetPagedLiveMigratingManagementsQueryVariables
  >(GetPagedLiveMigratingManagementsDocument, baseOptions);
}
export type GetPagedLiveMigratingManagementsQueryHookResult = ReturnType<
  typeof useGetPagedLiveMigratingManagementsQuery
>;
export type GetPagedLiveMigratingManagementsLazyQueryHookResult = ReturnType<
  typeof useGetPagedLiveMigratingManagementsLazyQuery
>;
export type GetPagedLiveMigratingManagementsQueryResult = Apollo.QueryResult<
  GetPagedLiveMigratingManagementsQuery,
  GetPagedLiveMigratingManagementsQueryVariables
>;
export const CompleteActionDocument = gql`
  mutation completeAction($id: ID!) {
    completeAction(id: $id) {
      ...ActionDetails
    }
  }
  ${ActionDetailsFragmentDoc}
`;
export type CompleteActionMutationFn = Apollo.MutationFunction<
  CompleteActionMutation,
  CompleteActionMutationVariables
>;

/**
 * __useCompleteActionMutation__
 *
 * To run a mutation, you first call `useCompleteActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeActionMutation, { data, loading, error }] = useCompleteActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteActionMutation,
    CompleteActionMutationVariables
  >
) {
  return Apollo.useMutation<
    CompleteActionMutation,
    CompleteActionMutationVariables
  >(CompleteActionDocument, baseOptions);
}
export type CompleteActionMutationHookResult = ReturnType<
  typeof useCompleteActionMutation
>;
export type CompleteActionMutationResult =
  Apollo.MutationResult<CompleteActionMutation>;
export type CompleteActionMutationOptions = Apollo.BaseMutationOptions<
  CompleteActionMutation,
  CompleteActionMutationVariables
>;
export const GetReiFormRemoteSigningPdfDocument = gql`
  query getReiFormRemoteSigningPDF($id: ID!) {
    form(id: $id) {
      id
      ... on ReiForm {
        reiFormInfo {
          name
        }
        remoteSigningPDF
      }
    }
  }
`;

/**
 * __useGetReiFormRemoteSigningPdfQuery__
 *
 * To run a query within a React component, call `useGetReiFormRemoteSigningPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReiFormRemoteSigningPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReiFormRemoteSigningPdfQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReiFormRemoteSigningPdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReiFormRemoteSigningPdfQuery,
    GetReiFormRemoteSigningPdfQueryVariables
  >
) {
  return Apollo.useQuery<
    GetReiFormRemoteSigningPdfQuery,
    GetReiFormRemoteSigningPdfQueryVariables
  >(GetReiFormRemoteSigningPdfDocument, baseOptions);
}
export function useGetReiFormRemoteSigningPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReiFormRemoteSigningPdfQuery,
    GetReiFormRemoteSigningPdfQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetReiFormRemoteSigningPdfQuery,
    GetReiFormRemoteSigningPdfQueryVariables
  >(GetReiFormRemoteSigningPdfDocument, baseOptions);
}
export type GetReiFormRemoteSigningPdfQueryHookResult = ReturnType<
  typeof useGetReiFormRemoteSigningPdfQuery
>;
export type GetReiFormRemoteSigningPdfLazyQueryHookResult = ReturnType<
  typeof useGetReiFormRemoteSigningPdfLazyQuery
>;
export type GetReiFormRemoteSigningPdfQueryResult = Apollo.QueryResult<
  GetReiFormRemoteSigningPdfQuery,
  GetReiFormRemoteSigningPdfQueryVariables
>;
export const UncompleteActionDocument = gql`
  mutation uncompleteAction($id: ID!) {
    uncompleteAction(id: $id) {
      ...ActionDetails
    }
  }
  ${ActionDetailsFragmentDoc}
`;
export type UncompleteActionMutationFn = Apollo.MutationFunction<
  UncompleteActionMutation,
  UncompleteActionMutationVariables
>;

/**
 * __useUncompleteActionMutation__
 *
 * To run a mutation, you first call `useUncompleteActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUncompleteActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uncompleteActionMutation, { data, loading, error }] = useUncompleteActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUncompleteActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UncompleteActionMutation,
    UncompleteActionMutationVariables
  >
) {
  return Apollo.useMutation<
    UncompleteActionMutation,
    UncompleteActionMutationVariables
  >(UncompleteActionDocument, baseOptions);
}
export type UncompleteActionMutationHookResult = ReturnType<
  typeof useUncompleteActionMutation
>;
export type UncompleteActionMutationResult =
  Apollo.MutationResult<UncompleteActionMutation>;
export type UncompleteActionMutationOptions = Apollo.BaseMutationOptions<
  UncompleteActionMutation,
  UncompleteActionMutationVariables
>;
export const AddProjectFilesDocument = gql`
  mutation addProjectFiles($projectId: ID!, $fileAilorns: [AiloRN!]!) {
    addProjectFiles(projectId: $projectId, fileAilorns: $fileAilorns) {
      id
      file {
        ...FileDetails
      }
    }
  }
  ${FileDetailsFragmentDoc}
`;
export type AddProjectFilesMutationFn = Apollo.MutationFunction<
  AddProjectFilesMutation,
  AddProjectFilesMutationVariables
>;

/**
 * __useAddProjectFilesMutation__
 *
 * To run a mutation, you first call `useAddProjectFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectFilesMutation, { data, loading, error }] = useAddProjectFilesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      fileAilorns: // value for 'fileAilorns'
 *   },
 * });
 */
export function useAddProjectFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProjectFilesMutation,
    AddProjectFilesMutationVariables
  >
) {
  return Apollo.useMutation<
    AddProjectFilesMutation,
    AddProjectFilesMutationVariables
  >(AddProjectFilesDocument, baseOptions);
}
export type AddProjectFilesMutationHookResult = ReturnType<
  typeof useAddProjectFilesMutation
>;
export type AddProjectFilesMutationResult =
  Apollo.MutationResult<AddProjectFilesMutation>;
export type AddProjectFilesMutationOptions = Apollo.BaseMutationOptions<
  AddProjectFilesMutation,
  AddProjectFilesMutationVariables
>;
export const GetCurrentTenancyDocument = gql`
  query getCurrentTenancy($managementId: ID!) {
    management(managementId: $managementId) {
      id
      currentTenancy {
        id
        ailoRN
        endDate
      }
    }
  }
`;

/**
 * __useGetCurrentTenancyQuery__
 *
 * To run a query within a React component, call `useGetCurrentTenancyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentTenancyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentTenancyQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetCurrentTenancyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentTenancyQuery,
    GetCurrentTenancyQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentTenancyQuery,
    GetCurrentTenancyQueryVariables
  >(GetCurrentTenancyDocument, baseOptions);
}
export function useGetCurrentTenancyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentTenancyQuery,
    GetCurrentTenancyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentTenancyQuery,
    GetCurrentTenancyQueryVariables
  >(GetCurrentTenancyDocument, baseOptions);
}
export type GetCurrentTenancyQueryHookResult = ReturnType<
  typeof useGetCurrentTenancyQuery
>;
export type GetCurrentTenancyLazyQueryHookResult = ReturnType<
  typeof useGetCurrentTenancyLazyQuery
>;
export type GetCurrentTenancyQueryResult = Apollo.QueryResult<
  GetCurrentTenancyQuery,
  GetCurrentTenancyQueryVariables
>;
export const GetProjectActionsDocument = gql`
  query getProjectActions($projectId: ID!, $cursor: String, $pageSize: Int!) {
    project(id: $projectId) {
      id
      actions(cursor: { cursor: $cursor, pageSize: $pageSize }) {
        pageInfo {
          total
          hasMore
          nextCursor
        }
        items {
          ...ActionDetails
        }
      }
    }
  }
  ${ActionDetailsFragmentDoc}
`;

/**
 * __useGetProjectActionsQuery__
 *
 * To run a query within a React component, call `useGetProjectActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectActionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      cursor: // value for 'cursor'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetProjectActionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectActionsQuery,
    GetProjectActionsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetProjectActionsQuery,
    GetProjectActionsQueryVariables
  >(GetProjectActionsDocument, baseOptions);
}
export function useGetProjectActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectActionsQuery,
    GetProjectActionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetProjectActionsQuery,
    GetProjectActionsQueryVariables
  >(GetProjectActionsDocument, baseOptions);
}
export type GetProjectActionsQueryHookResult = ReturnType<
  typeof useGetProjectActionsQuery
>;
export type GetProjectActionsLazyQueryHookResult = ReturnType<
  typeof useGetProjectActionsLazyQuery
>;
export type GetProjectActionsQueryResult = Apollo.QueryResult<
  GetProjectActionsQuery,
  GetProjectActionsQueryVariables
>;
export const GetProjectDetailsDocument = gql`
  query getProjectDetails($id: ID!) {
    project(id: $id) {
      ...ProjectDetails
    }
  }
  ${ProjectDetailsFragmentDoc}
`;

/**
 * __useGetProjectDetailsQuery__
 *
 * To run a query within a React component, call `useGetProjectDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectDetailsQuery,
    GetProjectDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetProjectDetailsQuery,
    GetProjectDetailsQueryVariables
  >(GetProjectDetailsDocument, baseOptions);
}
export function useGetProjectDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectDetailsQuery,
    GetProjectDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetProjectDetailsQuery,
    GetProjectDetailsQueryVariables
  >(GetProjectDetailsDocument, baseOptions);
}
export type GetProjectDetailsQueryHookResult = ReturnType<
  typeof useGetProjectDetailsQuery
>;
export type GetProjectDetailsLazyQueryHookResult = ReturnType<
  typeof useGetProjectDetailsLazyQuery
>;
export type GetProjectDetailsQueryResult = Apollo.QueryResult<
  GetProjectDetailsQuery,
  GetProjectDetailsQueryVariables
>;
export const GetProjectsByOrganisationDocument = gql`
  query getProjectsByOrganisation(
    $organisationAilorn: AiloRN!
    $cursor: String
    $pageSize: Int!
    $sort: String
    $status: ProjectStatus
    $paginateBackward: Boolean
    $assigneeAilorns: [AiloRN!]
    $projectTypes: [ProjectType!]
  ) {
    projectsByOrganisation(
      organisationAilorn: $organisationAilorn
      filter: {
        status: { eq: $status }
        assigneeAilorns: { in: $assigneeAilorns }
        types: { in: $projectTypes }
      }
      pageCursor: {
        cursor: $cursor
        pageSize: $pageSize
        sort: $sort
        paginateBackward: $paginateBackward
      }
    ) {
      pageInfo {
        ...ProjectPageInfo
      }
      items {
        ...ProjectListRow
      }
    }
  }
  ${ProjectPageInfoFragmentDoc}
  ${ProjectListRowFragmentDoc}
`;

/**
 * __useGetProjectsByOrganisationQuery__
 *
 * To run a query within a React component, call `useGetProjectsByOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsByOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsByOrganisationQuery({
 *   variables: {
 *      organisationAilorn: // value for 'organisationAilorn'
 *      cursor: // value for 'cursor'
 *      pageSize: // value for 'pageSize'
 *      sort: // value for 'sort'
 *      status: // value for 'status'
 *      paginateBackward: // value for 'paginateBackward'
 *      assigneeAilorns: // value for 'assigneeAilorns'
 *      projectTypes: // value for 'projectTypes'
 *   },
 * });
 */
export function useGetProjectsByOrganisationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectsByOrganisationQuery,
    GetProjectsByOrganisationQueryVariables
  >
) {
  return Apollo.useQuery<
    GetProjectsByOrganisationQuery,
    GetProjectsByOrganisationQueryVariables
  >(GetProjectsByOrganisationDocument, baseOptions);
}
export function useGetProjectsByOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsByOrganisationQuery,
    GetProjectsByOrganisationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetProjectsByOrganisationQuery,
    GetProjectsByOrganisationQueryVariables
  >(GetProjectsByOrganisationDocument, baseOptions);
}
export type GetProjectsByOrganisationQueryHookResult = ReturnType<
  typeof useGetProjectsByOrganisationQuery
>;
export type GetProjectsByOrganisationLazyQueryHookResult = ReturnType<
  typeof useGetProjectsByOrganisationLazyQuery
>;
export type GetProjectsByOrganisationQueryResult = Apollo.QueryResult<
  GetProjectsByOrganisationQuery,
  GetProjectsByOrganisationQueryVariables
>;
export const CancelTenancyDepositDocument = gql`
  mutation cancelTenancyDeposit($tenancyDepositAilorn: AiloRN!) {
    cancelTenancyDeposit(tenancyDepositId: $tenancyDepositAilorn) {
      id
    }
  }
`;
export type CancelTenancyDepositMutationFn = Apollo.MutationFunction<
  CancelTenancyDepositMutation,
  CancelTenancyDepositMutationVariables
>;

/**
 * __useCancelTenancyDepositMutation__
 *
 * To run a mutation, you first call `useCancelTenancyDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTenancyDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTenancyDepositMutation, { data, loading, error }] = useCancelTenancyDepositMutation({
 *   variables: {
 *      tenancyDepositAilorn: // value for 'tenancyDepositAilorn'
 *   },
 * });
 */
export function useCancelTenancyDepositMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelTenancyDepositMutation,
    CancelTenancyDepositMutationVariables
  >
) {
  return Apollo.useMutation<
    CancelTenancyDepositMutation,
    CancelTenancyDepositMutationVariables
  >(CancelTenancyDepositDocument, baseOptions);
}
export type CancelTenancyDepositMutationHookResult = ReturnType<
  typeof useCancelTenancyDepositMutation
>;
export type CancelTenancyDepositMutationResult =
  Apollo.MutationResult<CancelTenancyDepositMutation>;
export type CancelTenancyDepositMutationOptions = Apollo.BaseMutationOptions<
  CancelTenancyDepositMutation,
  CancelTenancyDepositMutationVariables
>;
export const GetAllTenanciesDepositsDocument = gql`
  query getAllTenanciesDeposits($managementId: ID!) {
    management(managementId: $managementId) {
      id
      occupancyStatus
      mostRecentTenancy {
        ...TenancyWithTenancyDepositDetails
      }
      nextTenancy {
        ...TenancyWithTenancyDepositDetails
      }
      formerTenancies {
        items {
          ...TenancyWithTenancyDepositDetails
        }
      }
    }
  }
  ${TenancyWithTenancyDepositDetailsFragmentDoc}
`;

/**
 * __useGetAllTenanciesDepositsQuery__
 *
 * To run a query within a React component, call `useGetAllTenanciesDepositsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTenanciesDepositsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTenanciesDepositsQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetAllTenanciesDepositsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllTenanciesDepositsQuery,
    GetAllTenanciesDepositsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAllTenanciesDepositsQuery,
    GetAllTenanciesDepositsQueryVariables
  >(GetAllTenanciesDepositsDocument, baseOptions);
}
export function useGetAllTenanciesDepositsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTenanciesDepositsQuery,
    GetAllTenanciesDepositsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAllTenanciesDepositsQuery,
    GetAllTenanciesDepositsQueryVariables
  >(GetAllTenanciesDepositsDocument, baseOptions);
}
export type GetAllTenanciesDepositsQueryHookResult = ReturnType<
  typeof useGetAllTenanciesDepositsQuery
>;
export type GetAllTenanciesDepositsLazyQueryHookResult = ReturnType<
  typeof useGetAllTenanciesDepositsLazyQuery
>;
export type GetAllTenanciesDepositsQueryResult = Apollo.QueryResult<
  GetAllTenanciesDepositsQuery,
  GetAllTenanciesDepositsQueryVariables
>;
export const GetTenancyAndTenancyDepositDetailsDocument = gql`
  query getTenancyAndTenancyDepositDetails($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      ...TenancyWithTenancyDepositDetails
    }
  }
  ${TenancyWithTenancyDepositDetailsFragmentDoc}
`;

/**
 * __useGetTenancyAndTenancyDepositDetailsQuery__
 *
 * To run a query within a React component, call `useGetTenancyAndTenancyDepositDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenancyAndTenancyDepositDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenancyAndTenancyDepositDetailsQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetTenancyAndTenancyDepositDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenancyAndTenancyDepositDetailsQuery,
    GetTenancyAndTenancyDepositDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenancyAndTenancyDepositDetailsQuery,
    GetTenancyAndTenancyDepositDetailsQueryVariables
  >(GetTenancyAndTenancyDepositDetailsDocument, baseOptions);
}
export function useGetTenancyAndTenancyDepositDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenancyAndTenancyDepositDetailsQuery,
    GetTenancyAndTenancyDepositDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenancyAndTenancyDepositDetailsQuery,
    GetTenancyAndTenancyDepositDetailsQueryVariables
  >(GetTenancyAndTenancyDepositDetailsDocument, baseOptions);
}
export type GetTenancyAndTenancyDepositDetailsQueryHookResult = ReturnType<
  typeof useGetTenancyAndTenancyDepositDetailsQuery
>;
export type GetTenancyAndTenancyDepositDetailsLazyQueryHookResult = ReturnType<
  typeof useGetTenancyAndTenancyDepositDetailsLazyQuery
>;
export type GetTenancyAndTenancyDepositDetailsQueryResult = Apollo.QueryResult<
  GetTenancyAndTenancyDepositDetailsQuery,
  GetTenancyAndTenancyDepositDetailsQueryVariables
>;
export const TransferTenancyDepositDocument = gql`
  mutation transferTenancyDeposit($tenancyDepositAilorn: AiloRN!) {
    transferTenancyDeposit(tenancyDepositId: $tenancyDepositAilorn) {
      id
      status
    }
  }
`;
export type TransferTenancyDepositMutationFn = Apollo.MutationFunction<
  TransferTenancyDepositMutation,
  TransferTenancyDepositMutationVariables
>;

/**
 * __useTransferTenancyDepositMutation__
 *
 * To run a mutation, you first call `useTransferTenancyDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferTenancyDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferTenancyDepositMutation, { data, loading, error }] = useTransferTenancyDepositMutation({
 *   variables: {
 *      tenancyDepositAilorn: // value for 'tenancyDepositAilorn'
 *   },
 * });
 */
export function useTransferTenancyDepositMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransferTenancyDepositMutation,
    TransferTenancyDepositMutationVariables
  >
) {
  return Apollo.useMutation<
    TransferTenancyDepositMutation,
    TransferTenancyDepositMutationVariables
  >(TransferTenancyDepositDocument, baseOptions);
}
export type TransferTenancyDepositMutationHookResult = ReturnType<
  typeof useTransferTenancyDepositMutation
>;
export type TransferTenancyDepositMutationResult =
  Apollo.MutationResult<TransferTenancyDepositMutation>;
export type TransferTenancyDepositMutationOptions = Apollo.BaseMutationOptions<
  TransferTenancyDepositMutation,
  TransferTenancyDepositMutationVariables
>;
export const GetTenanciesForMgmtDocument = gql`
  query getTenanciesForMgmt($managementId: ID!) {
    management(managementId: $managementId) {
      id
      tenancies {
        items {
          id
          endDate
          startDate
          voidedAt
          tenantships(pageCursor: { pageSize: 3 }) {
            pageInfo {
              total
            }
            items {
              tenant {
                __typename
                id
                ailoRN
                ... on Person {
                  firstName
                  lastName
                  photo {
                    id
                    url
                    thumbnailUrl
                  }
                }
                ... on Company {
                  registeredEntityName
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetTenanciesForMgmtQuery__
 *
 * To run a query within a React component, call `useGetTenanciesForMgmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenanciesForMgmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenanciesForMgmtQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetTenanciesForMgmtQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenanciesForMgmtQuery,
    GetTenanciesForMgmtQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenanciesForMgmtQuery,
    GetTenanciesForMgmtQueryVariables
  >(GetTenanciesForMgmtDocument, baseOptions);
}
export function useGetTenanciesForMgmtLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenanciesForMgmtQuery,
    GetTenanciesForMgmtQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenanciesForMgmtQuery,
    GetTenanciesForMgmtQueryVariables
  >(GetTenanciesForMgmtDocument, baseOptions);
}
export type GetTenanciesForMgmtQueryHookResult = ReturnType<
  typeof useGetTenanciesForMgmtQuery
>;
export type GetTenanciesForMgmtLazyQueryHookResult = ReturnType<
  typeof useGetTenanciesForMgmtLazyQuery
>;
export type GetTenanciesForMgmtQueryResult = Apollo.QueryResult<
  GetTenanciesForMgmtQuery,
  GetTenanciesForMgmtQueryVariables
>;
export const ClearManagementEndDocument = gql`
  mutation clearManagementEnd($managementId: ID!) {
    clearManagementEnd(managementId: $managementId) {
      management {
        id
        endDate
        endAbility {
          canBeEnded
          problem
        }
        currentTenancy {
          ...CanCancelVacate
        }
      }
    }
  }
  ${CanCancelVacateFragmentDoc}
`;
export type ClearManagementEndMutationFn = Apollo.MutationFunction<
  ClearManagementEndMutation,
  ClearManagementEndMutationVariables
>;

/**
 * __useClearManagementEndMutation__
 *
 * To run a mutation, you first call `useClearManagementEndMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearManagementEndMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearManagementEndMutation, { data, loading, error }] = useClearManagementEndMutation({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useClearManagementEndMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearManagementEndMutation,
    ClearManagementEndMutationVariables
  >
) {
  return Apollo.useMutation<
    ClearManagementEndMutation,
    ClearManagementEndMutationVariables
  >(ClearManagementEndDocument, baseOptions);
}
export type ClearManagementEndMutationHookResult = ReturnType<
  typeof useClearManagementEndMutation
>;
export type ClearManagementEndMutationResult =
  Apollo.MutationResult<ClearManagementEndMutation>;
export type ClearManagementEndMutationOptions = Apollo.BaseMutationOptions<
  ClearManagementEndMutation,
  ClearManagementEndMutationVariables
>;
export const DeletePropertyKeyDocument = gql`
  mutation deletePropertyKey(
    $input: DeleteOneKeyInput!
    $organisationAilorn: AiloRN!
  ) {
    deleteOneKey(input: $input) {
      id
      property {
        ...PropertyKeys
      }
    }
  }
  ${PropertyKeysFragmentDoc}
`;
export type DeletePropertyKeyMutationFn = Apollo.MutationFunction<
  DeletePropertyKeyMutation,
  DeletePropertyKeyMutationVariables
>;

/**
 * __useDeletePropertyKeyMutation__
 *
 * To run a mutation, you first call `useDeletePropertyKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePropertyKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePropertyKeyMutation, { data, loading, error }] = useDeletePropertyKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useDeletePropertyKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePropertyKeyMutation,
    DeletePropertyKeyMutationVariables
  >
) {
  return Apollo.useMutation<
    DeletePropertyKeyMutation,
    DeletePropertyKeyMutationVariables
  >(DeletePropertyKeyDocument, baseOptions);
}
export type DeletePropertyKeyMutationHookResult = ReturnType<
  typeof useDeletePropertyKeyMutation
>;
export type DeletePropertyKeyMutationResult =
  Apollo.MutationResult<DeletePropertyKeyMutation>;
export type DeletePropertyKeyMutationOptions = Apollo.BaseMutationOptions<
  DeletePropertyKeyMutation,
  DeletePropertyKeyMutationVariables
>;
export const GetAgencyAndManagementFeeBlueprintsDocument = gql`
  query getAgencyAndManagementFeeBlueprints(
    $agencyBlueprintConditions: FeeBlueprintsQueryConditions
    $managementBlueprintConditions: ManagementFeeBlueprintsQueryConditions
    $isAgencyBlueprint: Boolean = false
    $isManagementBlueprint: Boolean = false
  ) {
    agencyBlueprints: feeBlueprints(conditions: $agencyBlueprintConditions)
      @include(if: $isAgencyBlueprint) {
      items {
        ...feeBlueprint
      }
    }
    managementBlueprints: managementFeeBlueprints(
      conditions: $managementBlueprintConditions
    ) @include(if: $isManagementBlueprint) {
      items {
        id
        ailorn
        feeBlueprint {
          id
          ailorn: ailoRN
          name
          taxCategory {
            id
            name
          }
          type
        }
        fixedAmount {
          cents
        }
        oneWeekRentPercentage
        chargeType
        taxTreatment
      }
    }
  }
  ${FeeBlueprintFragmentDoc}
`;

/**
 * __useGetAgencyAndManagementFeeBlueprintsQuery__
 *
 * To run a query within a React component, call `useGetAgencyAndManagementFeeBlueprintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyAndManagementFeeBlueprintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyAndManagementFeeBlueprintsQuery({
 *   variables: {
 *      agencyBlueprintConditions: // value for 'agencyBlueprintConditions'
 *      managementBlueprintConditions: // value for 'managementBlueprintConditions'
 *      isAgencyBlueprint: // value for 'isAgencyBlueprint'
 *      isManagementBlueprint: // value for 'isManagementBlueprint'
 *   },
 * });
 */
export function useGetAgencyAndManagementFeeBlueprintsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAgencyAndManagementFeeBlueprintsQuery,
    GetAgencyAndManagementFeeBlueprintsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAgencyAndManagementFeeBlueprintsQuery,
    GetAgencyAndManagementFeeBlueprintsQueryVariables
  >(GetAgencyAndManagementFeeBlueprintsDocument, baseOptions);
}
export function useGetAgencyAndManagementFeeBlueprintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgencyAndManagementFeeBlueprintsQuery,
    GetAgencyAndManagementFeeBlueprintsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAgencyAndManagementFeeBlueprintsQuery,
    GetAgencyAndManagementFeeBlueprintsQueryVariables
  >(GetAgencyAndManagementFeeBlueprintsDocument, baseOptions);
}
export type GetAgencyAndManagementFeeBlueprintsQueryHookResult = ReturnType<
  typeof useGetAgencyAndManagementFeeBlueprintsQuery
>;
export type GetAgencyAndManagementFeeBlueprintsLazyQueryHookResult = ReturnType<
  typeof useGetAgencyAndManagementFeeBlueprintsLazyQuery
>;
export type GetAgencyAndManagementFeeBlueprintsQueryResult = Apollo.QueryResult<
  GetAgencyAndManagementFeeBlueprintsQuery,
  GetAgencyAndManagementFeeBlueprintsQueryVariables
>;
export const GetAgencyPropertyDocument = gql`
  query getAgencyProperty($id: ID!) {
    agencyProperty(id: $id) {
      ...AgencyProperty
    }
  }
  ${AgencyPropertyFragmentDoc}
`;

/**
 * __useGetAgencyPropertyQuery__
 *
 * To run a query within a React component, call `useGetAgencyPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyPropertyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAgencyPropertyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgencyPropertyQuery,
    GetAgencyPropertyQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAgencyPropertyQuery,
    GetAgencyPropertyQueryVariables
  >(GetAgencyPropertyDocument, baseOptions);
}
export function useGetAgencyPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgencyPropertyQuery,
    GetAgencyPropertyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAgencyPropertyQuery,
    GetAgencyPropertyQueryVariables
  >(GetAgencyPropertyDocument, baseOptions);
}
export type GetAgencyPropertyQueryHookResult = ReturnType<
  typeof useGetAgencyPropertyQuery
>;
export type GetAgencyPropertyLazyQueryHookResult = ReturnType<
  typeof useGetAgencyPropertyLazyQuery
>;
export type GetAgencyPropertyQueryResult = Apollo.QueryResult<
  GetAgencyPropertyQuery,
  GetAgencyPropertyQueryVariables
>;
export const GetBasicPropertyDetailsDocument = gql`
  query getBasicPropertyDetails($id: ID!) {
    management(managementId: $id) {
      id
      endDate
      ...ManagementProperty
    }
  }
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useGetBasicPropertyDetailsQuery__
 *
 * To run a query within a React component, call `useGetBasicPropertyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicPropertyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicPropertyDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBasicPropertyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBasicPropertyDetailsQuery,
    GetBasicPropertyDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetBasicPropertyDetailsQuery,
    GetBasicPropertyDetailsQueryVariables
  >(GetBasicPropertyDetailsDocument, baseOptions);
}
export function useGetBasicPropertyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBasicPropertyDetailsQuery,
    GetBasicPropertyDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetBasicPropertyDetailsQuery,
    GetBasicPropertyDetailsQueryVariables
  >(GetBasicPropertyDetailsDocument, baseOptions);
}
export type GetBasicPropertyDetailsQueryHookResult = ReturnType<
  typeof useGetBasicPropertyDetailsQuery
>;
export type GetBasicPropertyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetBasicPropertyDetailsLazyQuery
>;
export type GetBasicPropertyDetailsQueryResult = Apollo.QueryResult<
  GetBasicPropertyDetailsQuery,
  GetBasicPropertyDetailsQueryVariables
>;
export const GetCentrepayersDocument = gql`
  query getCentrepayers($input: GetCentrepayersInput!) {
    getCentrepayers(input: $input) {
      legalEntity
    }
  }
`;

/**
 * __useGetCentrepayersQuery__
 *
 * To run a query within a React component, call `useGetCentrepayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCentrepayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCentrepayersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCentrepayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCentrepayersQuery,
    GetCentrepayersQueryVariables
  >
) {
  return Apollo.useQuery<GetCentrepayersQuery, GetCentrepayersQueryVariables>(
    GetCentrepayersDocument,
    baseOptions
  );
}
export function useGetCentrepayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCentrepayersQuery,
    GetCentrepayersQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCentrepayersQuery,
    GetCentrepayersQueryVariables
  >(GetCentrepayersDocument, baseOptions);
}
export type GetCentrepayersQueryHookResult = ReturnType<
  typeof useGetCentrepayersQuery
>;
export type GetCentrepayersLazyQueryHookResult = ReturnType<
  typeof useGetCentrepayersLazyQuery
>;
export type GetCentrepayersQueryResult = Apollo.QueryResult<
  GetCentrepayersQuery,
  GetCentrepayersQueryVariables
>;
export const GetFeeBlueprintsDocument = gql`
  query getFeeBlueprints($conditions: FeeBlueprintsQueryConditions) {
    blueprints: feeBlueprints(conditions: $conditions) {
      items {
        ...feeBlueprint
        event {
          type
          shortDescription
          longDescription
        }
      }
    }
  }
  ${FeeBlueprintFragmentDoc}
`;

/**
 * __useGetFeeBlueprintsQuery__
 *
 * To run a query within a React component, call `useGetFeeBlueprintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeeBlueprintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeeBlueprintsQuery({
 *   variables: {
 *      conditions: // value for 'conditions'
 *   },
 * });
 */
export function useGetFeeBlueprintsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeeBlueprintsQuery,
    GetFeeBlueprintsQueryVariables
  >
) {
  return Apollo.useQuery<GetFeeBlueprintsQuery, GetFeeBlueprintsQueryVariables>(
    GetFeeBlueprintsDocument,
    baseOptions
  );
}
export function useGetFeeBlueprintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeeBlueprintsQuery,
    GetFeeBlueprintsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetFeeBlueprintsQuery,
    GetFeeBlueprintsQueryVariables
  >(GetFeeBlueprintsDocument, baseOptions);
}
export type GetFeeBlueprintsQueryHookResult = ReturnType<
  typeof useGetFeeBlueprintsQuery
>;
export type GetFeeBlueprintsLazyQueryHookResult = ReturnType<
  typeof useGetFeeBlueprintsLazyQuery
>;
export type GetFeeBlueprintsQueryResult = Apollo.QueryResult<
  GetFeeBlueprintsQuery,
  GetFeeBlueprintsQueryVariables
>;
export const GetIngoingTenancyDetailsDocument = gql`
  query getIngoingTenancyDetails($id: ID!, $organisationAilorn: AiloRN!) {
    tenancy(tenancyId: $id) {
      id
      startDate
      bond {
        id
        amount {
          cents
        }
        reference
        status
        createdAt
      }
      ...TenancyWithTenancyDepositDetails
      rents(cursor: { pageSize: 1 }) {
        pageInfo {
          total
        }
        items {
          id
          effectiveDate
        }
      }
      tenantships {
        items {
          tenant {
            __typename
            id
            ... on Person {
              firstName
              lastName
              phoneNo
              emailAddress
            }
            ... on Company {
              registeredEntityName
              registeredEntityId
            }
            contact(organisationAilorn: $organisationAilorn) {
              ailorn
            }
          }
        }
      }
    }
  }
  ${TenancyWithTenancyDepositDetailsFragmentDoc}
`;

/**
 * __useGetIngoingTenancyDetailsQuery__
 *
 * To run a query within a React component, call `useGetIngoingTenancyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIngoingTenancyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIngoingTenancyDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useGetIngoingTenancyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIngoingTenancyDetailsQuery,
    GetIngoingTenancyDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetIngoingTenancyDetailsQuery,
    GetIngoingTenancyDetailsQueryVariables
  >(GetIngoingTenancyDetailsDocument, baseOptions);
}
export function useGetIngoingTenancyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIngoingTenancyDetailsQuery,
    GetIngoingTenancyDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetIngoingTenancyDetailsQuery,
    GetIngoingTenancyDetailsQueryVariables
  >(GetIngoingTenancyDetailsDocument, baseOptions);
}
export type GetIngoingTenancyDetailsQueryHookResult = ReturnType<
  typeof useGetIngoingTenancyDetailsQuery
>;
export type GetIngoingTenancyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetIngoingTenancyDetailsLazyQuery
>;
export type GetIngoingTenancyDetailsQueryResult = Apollo.QueryResult<
  GetIngoingTenancyDetailsQuery,
  GetIngoingTenancyDetailsQueryVariables
>;
export const GetManagementAgencyDocument = gql`
  query getManagementAgency($id: ID!) {
    management(managementId: $id) {
      id
      managingEntity {
        id
        ailoRN
        organisation {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetManagementAgencyQuery__
 *
 * To run a query within a React component, call `useGetManagementAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementAgencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManagementAgencyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementAgencyQuery,
    GetManagementAgencyQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementAgencyQuery,
    GetManagementAgencyQueryVariables
  >(GetManagementAgencyDocument, baseOptions);
}
export function useGetManagementAgencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementAgencyQuery,
    GetManagementAgencyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementAgencyQuery,
    GetManagementAgencyQueryVariables
  >(GetManagementAgencyDocument, baseOptions);
}
export type GetManagementAgencyQueryHookResult = ReturnType<
  typeof useGetManagementAgencyQuery
>;
export type GetManagementAgencyLazyQueryHookResult = ReturnType<
  typeof useGetManagementAgencyLazyQuery
>;
export type GetManagementAgencyQueryResult = Apollo.QueryResult<
  GetManagementAgencyQuery,
  GetManagementAgencyQueryVariables
>;
export const GetManagementFeeDocument = gql`
  query getManagementFee($id: ID!) {
    managementFeeSchedule(id: $id) {
      id
      ailoRN
      percent
      managementAgreement {
        id
        ailoRN
      }
    }
  }
`;

/**
 * __useGetManagementFeeQuery__
 *
 * To run a query within a React component, call `useGetManagementFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementFeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManagementFeeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementFeeQuery,
    GetManagementFeeQueryVariables
  >
) {
  return Apollo.useQuery<GetManagementFeeQuery, GetManagementFeeQueryVariables>(
    GetManagementFeeDocument,
    baseOptions
  );
}
export function useGetManagementFeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementFeeQuery,
    GetManagementFeeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementFeeQuery,
    GetManagementFeeQueryVariables
  >(GetManagementFeeDocument, baseOptions);
}
export type GetManagementFeeQueryHookResult = ReturnType<
  typeof useGetManagementFeeQuery
>;
export type GetManagementFeeLazyQueryHookResult = ReturnType<
  typeof useGetManagementFeeLazyQuery
>;
export type GetManagementFeeQueryResult = Apollo.QueryResult<
  GetManagementFeeQuery,
  GetManagementFeeQueryVariables
>;
export const GetManagementFirstPublishedDocument = gql`
  query getManagementFirstPublished($id: ID!) {
    management(managementId: $id) {
      id
      firstPublishedAt
    }
  }
`;

/**
 * __useGetManagementFirstPublishedQuery__
 *
 * To run a query within a React component, call `useGetManagementFirstPublishedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementFirstPublishedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementFirstPublishedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManagementFirstPublishedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementFirstPublishedQuery,
    GetManagementFirstPublishedQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementFirstPublishedQuery,
    GetManagementFirstPublishedQueryVariables
  >(GetManagementFirstPublishedDocument, baseOptions);
}
export function useGetManagementFirstPublishedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementFirstPublishedQuery,
    GetManagementFirstPublishedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementFirstPublishedQuery,
    GetManagementFirstPublishedQueryVariables
  >(GetManagementFirstPublishedDocument, baseOptions);
}
export type GetManagementFirstPublishedQueryHookResult = ReturnType<
  typeof useGetManagementFirstPublishedQuery
>;
export type GetManagementFirstPublishedLazyQueryHookResult = ReturnType<
  typeof useGetManagementFirstPublishedLazyQuery
>;
export type GetManagementFirstPublishedQueryResult = Apollo.QueryResult<
  GetManagementFirstPublishedQuery,
  GetManagementFirstPublishedQueryVariables
>;
export const GetManagementNoteDocument = gql`
  query getManagementNote($managementId: ID!) {
    management(managementId: $managementId) {
      id
      note {
        ...managementNote
      }
    }
  }
  ${ManagementNoteFragmentDoc}
`;

/**
 * __useGetManagementNoteQuery__
 *
 * To run a query within a React component, call `useGetManagementNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementNoteQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetManagementNoteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementNoteQuery,
    GetManagementNoteQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementNoteQuery,
    GetManagementNoteQueryVariables
  >(GetManagementNoteDocument, baseOptions);
}
export function useGetManagementNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementNoteQuery,
    GetManagementNoteQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementNoteQuery,
    GetManagementNoteQueryVariables
  >(GetManagementNoteDocument, baseOptions);
}
export type GetManagementNoteQueryHookResult = ReturnType<
  typeof useGetManagementNoteQuery
>;
export type GetManagementNoteLazyQueryHookResult = ReturnType<
  typeof useGetManagementNoteLazyQuery
>;
export type GetManagementNoteQueryResult = Apollo.QueryResult<
  GetManagementNoteQuery,
  GetManagementNoteQueryVariables
>;
export const GetManagementOwnersDocument = gql`
  query getManagementOwners($id: ID!) {
    management(managementId: $id) {
      id
      endDate
      endAbility {
        canBeEnded
        problem
      }
      ownerships {
        pageInfo {
          total
        }
        items {
          owner {
            __typename
            id
            ailoRN
            ... on Person {
              firstName
              lastName
              phoneNo
              emailAddress
            }
            ... on Company {
              registeredEntityName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetManagementOwnersQuery__
 *
 * To run a query within a React component, call `useGetManagementOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementOwnersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManagementOwnersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementOwnersQuery,
    GetManagementOwnersQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementOwnersQuery,
    GetManagementOwnersQueryVariables
  >(GetManagementOwnersDocument, baseOptions);
}
export function useGetManagementOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementOwnersQuery,
    GetManagementOwnersQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementOwnersQuery,
    GetManagementOwnersQueryVariables
  >(GetManagementOwnersDocument, baseOptions);
}
export type GetManagementOwnersQueryHookResult = ReturnType<
  typeof useGetManagementOwnersQuery
>;
export type GetManagementOwnersLazyQueryHookResult = ReturnType<
  typeof useGetManagementOwnersLazyQuery
>;
export type GetManagementOwnersQueryResult = Apollo.QueryResult<
  GetManagementOwnersQuery,
  GetManagementOwnersQueryVariables
>;
export const GetManagementProjectsDocument = gql`
  query getManagementProjects($managementId: ID!) {
    management(managementId: $managementId) {
      id
      projects(pageCursor: { pageSize: 2000 }) {
        items {
          ...ProjectItemDetails
        }
      }
    }
  }
  ${ProjectItemDetailsFragmentDoc}
`;

/**
 * __useGetManagementProjectsQuery__
 *
 * To run a query within a React component, call `useGetManagementProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementProjectsQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetManagementProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementProjectsQuery,
    GetManagementProjectsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementProjectsQuery,
    GetManagementProjectsQueryVariables
  >(GetManagementProjectsDocument, baseOptions);
}
export function useGetManagementProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementProjectsQuery,
    GetManagementProjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementProjectsQuery,
    GetManagementProjectsQueryVariables
  >(GetManagementProjectsDocument, baseOptions);
}
export type GetManagementProjectsQueryHookResult = ReturnType<
  typeof useGetManagementProjectsQuery
>;
export type GetManagementProjectsLazyQueryHookResult = ReturnType<
  typeof useGetManagementProjectsLazyQuery
>;
export type GetManagementProjectsQueryResult = Apollo.QueryResult<
  GetManagementProjectsQuery,
  GetManagementProjectsQueryVariables
>;
export const GetManagementPropertyKeysDocument = gql`
  query getManagementPropertyKeys($id: ID!, $organisationAilorn: AiloRN!) {
    management(managementId: $id) {
      id
      property {
        ...PropertyKeys
      }
    }
  }
  ${PropertyKeysFragmentDoc}
`;

/**
 * __useGetManagementPropertyKeysQuery__
 *
 * To run a query within a React component, call `useGetManagementPropertyKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementPropertyKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementPropertyKeysQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useGetManagementPropertyKeysQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementPropertyKeysQuery,
    GetManagementPropertyKeysQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementPropertyKeysQuery,
    GetManagementPropertyKeysQueryVariables
  >(GetManagementPropertyKeysDocument, baseOptions);
}
export function useGetManagementPropertyKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementPropertyKeysQuery,
    GetManagementPropertyKeysQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementPropertyKeysQuery,
    GetManagementPropertyKeysQueryVariables
  >(GetManagementPropertyKeysDocument, baseOptions);
}
export type GetManagementPropertyKeysQueryHookResult = ReturnType<
  typeof useGetManagementPropertyKeysQuery
>;
export type GetManagementPropertyKeysLazyQueryHookResult = ReturnType<
  typeof useGetManagementPropertyKeysLazyQuery
>;
export type GetManagementPropertyKeysQueryResult = Apollo.QueryResult<
  GetManagementPropertyKeysQuery,
  GetManagementPropertyKeysQueryVariables
>;
export const GetMembersByOrganisationDocument = gql`
  query getMembersByOrganisation($organisationId: ID!) {
    organisation(id: $organisationId) {
      ...OrganisationMembersDetails
    }
  }
  ${OrganisationMembersDetailsFragmentDoc}
`;

/**
 * __useGetMembersByOrganisationQuery__
 *
 * To run a query within a React component, call `useGetMembersByOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersByOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersByOrganisationQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetMembersByOrganisationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMembersByOrganisationQuery,
    GetMembersByOrganisationQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMembersByOrganisationQuery,
    GetMembersByOrganisationQueryVariables
  >(GetMembersByOrganisationDocument, baseOptions);
}
export function useGetMembersByOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMembersByOrganisationQuery,
    GetMembersByOrganisationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMembersByOrganisationQuery,
    GetMembersByOrganisationQueryVariables
  >(GetMembersByOrganisationDocument, baseOptions);
}
export type GetMembersByOrganisationQueryHookResult = ReturnType<
  typeof useGetMembersByOrganisationQuery
>;
export type GetMembersByOrganisationLazyQueryHookResult = ReturnType<
  typeof useGetMembersByOrganisationLazyQuery
>;
export type GetMembersByOrganisationQueryResult = Apollo.QueryResult<
  GetMembersByOrganisationQuery,
  GetMembersByOrganisationQueryVariables
>;
export const GetMostRecentTenancyIdDocument = gql`
  query getMostRecentTenancyId($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
      }
    }
  }
`;

/**
 * __useGetMostRecentTenancyIdQuery__
 *
 * To run a query within a React component, call `useGetMostRecentTenancyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentTenancyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentTenancyIdQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetMostRecentTenancyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMostRecentTenancyIdQuery,
    GetMostRecentTenancyIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMostRecentTenancyIdQuery,
    GetMostRecentTenancyIdQueryVariables
  >(GetMostRecentTenancyIdDocument, baseOptions);
}
export function useGetMostRecentTenancyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMostRecentTenancyIdQuery,
    GetMostRecentTenancyIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMostRecentTenancyIdQuery,
    GetMostRecentTenancyIdQueryVariables
  >(GetMostRecentTenancyIdDocument, baseOptions);
}
export type GetMostRecentTenancyIdQueryHookResult = ReturnType<
  typeof useGetMostRecentTenancyIdQuery
>;
export type GetMostRecentTenancyIdLazyQueryHookResult = ReturnType<
  typeof useGetMostRecentTenancyIdLazyQuery
>;
export type GetMostRecentTenancyIdQueryResult = Apollo.QueryResult<
  GetMostRecentTenancyIdQuery,
  GetMostRecentTenancyIdQueryVariables
>;
export const GetPersonNameDocument = gql`
  query getPersonName($id: AiloRN!) {
    legalEntity(id: $id) {
      __typename
      id
      ... on Person {
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetPersonNameQuery__
 *
 * To run a query within a React component, call `useGetPersonNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonNameQuery,
    GetPersonNameQueryVariables
  >
) {
  return Apollo.useQuery<GetPersonNameQuery, GetPersonNameQueryVariables>(
    GetPersonNameDocument,
    baseOptions
  );
}
export function useGetPersonNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonNameQuery,
    GetPersonNameQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetPersonNameQuery, GetPersonNameQueryVariables>(
    GetPersonNameDocument,
    baseOptions
  );
}
export type GetPersonNameQueryHookResult = ReturnType<
  typeof useGetPersonNameQuery
>;
export type GetPersonNameLazyQueryHookResult = ReturnType<
  typeof useGetPersonNameLazyQuery
>;
export type GetPersonNameQueryResult = Apollo.QueryResult<
  GetPersonNameQuery,
  GetPersonNameQueryVariables
>;
export const GetPropertyAddressAndInvestorsDocument = gql`
  query getPropertyAddressAndInvestors($id: ID!) {
    management(managementId: $id) {
      id
      ...ManagementProperty
      owners {
        ownerId
        owner {
          ...legalEntityName
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
  ${LegalEntityNameFragmentDoc}
`;

/**
 * __useGetPropertyAddressAndInvestorsQuery__
 *
 * To run a query within a React component, call `useGetPropertyAddressAndInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyAddressAndInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyAddressAndInvestorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyAddressAndInvestorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyAddressAndInvestorsQuery,
    GetPropertyAddressAndInvestorsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertyAddressAndInvestorsQuery,
    GetPropertyAddressAndInvestorsQueryVariables
  >(GetPropertyAddressAndInvestorsDocument, baseOptions);
}
export function useGetPropertyAddressAndInvestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyAddressAndInvestorsQuery,
    GetPropertyAddressAndInvestorsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertyAddressAndInvestorsQuery,
    GetPropertyAddressAndInvestorsQueryVariables
  >(GetPropertyAddressAndInvestorsDocument, baseOptions);
}
export type GetPropertyAddressAndInvestorsQueryHookResult = ReturnType<
  typeof useGetPropertyAddressAndInvestorsQuery
>;
export type GetPropertyAddressAndInvestorsLazyQueryHookResult = ReturnType<
  typeof useGetPropertyAddressAndInvestorsLazyQuery
>;
export type GetPropertyAddressAndInvestorsQueryResult = Apollo.QueryResult<
  GetPropertyAddressAndInvestorsQuery,
  GetPropertyAddressAndInvestorsQueryVariables
>;
export const GetPropertyAddressByManagementIdDocument = gql`
  query getPropertyAddressByManagementId($id: ID!) {
    management(managementId: $id) {
      id
      ...ManagementProperty
    }
  }
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useGetPropertyAddressByManagementIdQuery__
 *
 * To run a query within a React component, call `useGetPropertyAddressByManagementIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyAddressByManagementIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyAddressByManagementIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyAddressByManagementIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyAddressByManagementIdQuery,
    GetPropertyAddressByManagementIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertyAddressByManagementIdQuery,
    GetPropertyAddressByManagementIdQueryVariables
  >(GetPropertyAddressByManagementIdDocument, baseOptions);
}
export function useGetPropertyAddressByManagementIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyAddressByManagementIdQuery,
    GetPropertyAddressByManagementIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertyAddressByManagementIdQuery,
    GetPropertyAddressByManagementIdQueryVariables
  >(GetPropertyAddressByManagementIdDocument, baseOptions);
}
export type GetPropertyAddressByManagementIdQueryHookResult = ReturnType<
  typeof useGetPropertyAddressByManagementIdQuery
>;
export type GetPropertyAddressByManagementIdLazyQueryHookResult = ReturnType<
  typeof useGetPropertyAddressByManagementIdLazyQuery
>;
export type GetPropertyAddressByManagementIdQueryResult = Apollo.QueryResult<
  GetPropertyAddressByManagementIdQuery,
  GetPropertyAddressByManagementIdQueryVariables
>;
export const GetPropertyDetailsByManagementDocument = gql`
  query getPropertyDetailsByManagement($id: ID!) {
    management(managementId: $id) {
      id
      managingEntity {
        id
        ailoRN
      }
      managementFolio {
        id
        ailorn
      }
      ...ManagementProperty
    }
  }
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useGetPropertyDetailsByManagementQuery__
 *
 * To run a query within a React component, call `useGetPropertyDetailsByManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyDetailsByManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyDetailsByManagementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyDetailsByManagementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyDetailsByManagementQuery,
    GetPropertyDetailsByManagementQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertyDetailsByManagementQuery,
    GetPropertyDetailsByManagementQueryVariables
  >(GetPropertyDetailsByManagementDocument, baseOptions);
}
export function useGetPropertyDetailsByManagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyDetailsByManagementQuery,
    GetPropertyDetailsByManagementQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertyDetailsByManagementQuery,
    GetPropertyDetailsByManagementQueryVariables
  >(GetPropertyDetailsByManagementDocument, baseOptions);
}
export type GetPropertyDetailsByManagementQueryHookResult = ReturnType<
  typeof useGetPropertyDetailsByManagementQuery
>;
export type GetPropertyDetailsByManagementLazyQueryHookResult = ReturnType<
  typeof useGetPropertyDetailsByManagementLazyQuery
>;
export type GetPropertyDetailsByManagementQueryResult = Apollo.QueryResult<
  GetPropertyDetailsByManagementQuery,
  GetPropertyDetailsByManagementQueryVariables
>;
export const GetPropertyKeyDocument = gql`
  query getPropertyKey($id: ID!) {
    key(id: $id) {
      ...Key
    }
  }
  ${KeyFragmentDoc}
`;

/**
 * __useGetPropertyKeyQuery__
 *
 * To run a query within a React component, call `useGetPropertyKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyKeyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyKeyQuery,
    GetPropertyKeyQueryVariables
  >
) {
  return Apollo.useQuery<GetPropertyKeyQuery, GetPropertyKeyQueryVariables>(
    GetPropertyKeyDocument,
    baseOptions
  );
}
export function useGetPropertyKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyKeyQuery,
    GetPropertyKeyQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetPropertyKeyQuery, GetPropertyKeyQueryVariables>(
    GetPropertyKeyDocument,
    baseOptions
  );
}
export type GetPropertyKeyQueryHookResult = ReturnType<
  typeof useGetPropertyKeyQuery
>;
export type GetPropertyKeyLazyQueryHookResult = ReturnType<
  typeof useGetPropertyKeyLazyQuery
>;
export type GetPropertyKeyQueryResult = Apollo.QueryResult<
  GetPropertyKeyQuery,
  GetPropertyKeyQueryVariables
>;
export const GetPropertyOccupancyHealthDocument = gql`
  query getPropertyOccupancyHealth($managementId: ID!) {
    management(managementId: $managementId) {
      id
      occupancyStatus
      startDate
      mostRecentTenancy {
        id
        startDate
        endDate
        mostRecentTenancyAgreement {
          id
          startDate
          fixedTermEndDate
          allowedToLapseAt
        }
        routineInspectionDueDate
        ...upcomingTenancyAgreement
        currentRentSchedule {
          id
        }
      }
      nextTenancy {
        id
        startDate
      }
    }
  }
  ${UpcomingTenancyAgreementFragmentDoc}
`;

/**
 * __useGetPropertyOccupancyHealthQuery__
 *
 * To run a query within a React component, call `useGetPropertyOccupancyHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyOccupancyHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyOccupancyHealthQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetPropertyOccupancyHealthQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyOccupancyHealthQuery,
    GetPropertyOccupancyHealthQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertyOccupancyHealthQuery,
    GetPropertyOccupancyHealthQueryVariables
  >(GetPropertyOccupancyHealthDocument, baseOptions);
}
export function useGetPropertyOccupancyHealthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyOccupancyHealthQuery,
    GetPropertyOccupancyHealthQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertyOccupancyHealthQuery,
    GetPropertyOccupancyHealthQueryVariables
  >(GetPropertyOccupancyHealthDocument, baseOptions);
}
export type GetPropertyOccupancyHealthQueryHookResult = ReturnType<
  typeof useGetPropertyOccupancyHealthQuery
>;
export type GetPropertyOccupancyHealthLazyQueryHookResult = ReturnType<
  typeof useGetPropertyOccupancyHealthLazyQuery
>;
export type GetPropertyOccupancyHealthQueryResult = Apollo.QueryResult<
  GetPropertyOccupancyHealthQuery,
  GetPropertyOccupancyHealthQueryVariables
>;
export const GetPropertyRentHealthDocument = gql`
  query getPropertyRentHealth($managementId: ID!) {
    management(managementId: $managementId) {
      id
      occupancyStatus
      mostRecentTenancy {
        id
        liability {
          id
          reference
          effectivePaidToDate
        }
        endDate
        currentRentSchedule {
          id
          amountInCents
          period
        }
        ...upcomingRentChanges
      }
    }
  }
  ${UpcomingRentChangesFragmentDoc}
`;

/**
 * __useGetPropertyRentHealthQuery__
 *
 * To run a query within a React component, call `useGetPropertyRentHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyRentHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyRentHealthQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetPropertyRentHealthQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyRentHealthQuery,
    GetPropertyRentHealthQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertyRentHealthQuery,
    GetPropertyRentHealthQueryVariables
  >(GetPropertyRentHealthDocument, baseOptions);
}
export function useGetPropertyRentHealthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyRentHealthQuery,
    GetPropertyRentHealthQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertyRentHealthQuery,
    GetPropertyRentHealthQueryVariables
  >(GetPropertyRentHealthDocument, baseOptions);
}
export type GetPropertyRentHealthQueryHookResult = ReturnType<
  typeof useGetPropertyRentHealthQuery
>;
export type GetPropertyRentHealthLazyQueryHookResult = ReturnType<
  typeof useGetPropertyRentHealthLazyQuery
>;
export type GetPropertyRentHealthQueryResult = Apollo.QueryResult<
  GetPropertyRentHealthQuery,
  GetPropertyRentHealthQueryVariables
>;
export const GetRecurringFeeDocument = gql`
  query getRecurringFee($id: AiloRN!) {
    recurringFee(id: $id) {
      id: ailoRN
      name
      startDate
      frequency
      blueprint {
        id
        fixedAmount {
          cents
        }
      }
      nextOccurrence {
        date
        taxInclusiveAmount {
          cents
        }
      }
      currentOrNextSchedule {
        id
        taxInclusiveAmount {
          cents
        }
        startDate
      }
      description
    }
  }
`;

/**
 * __useGetRecurringFeeQuery__
 *
 * To run a query within a React component, call `useGetRecurringFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringFeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecurringFeeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRecurringFeeQuery,
    GetRecurringFeeQueryVariables
  >
) {
  return Apollo.useQuery<GetRecurringFeeQuery, GetRecurringFeeQueryVariables>(
    GetRecurringFeeDocument,
    baseOptions
  );
}
export function useGetRecurringFeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecurringFeeQuery,
    GetRecurringFeeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetRecurringFeeQuery,
    GetRecurringFeeQueryVariables
  >(GetRecurringFeeDocument, baseOptions);
}
export type GetRecurringFeeQueryHookResult = ReturnType<
  typeof useGetRecurringFeeQuery
>;
export type GetRecurringFeeLazyQueryHookResult = ReturnType<
  typeof useGetRecurringFeeLazyQuery
>;
export type GetRecurringFeeQueryResult = Apollo.QueryResult<
  GetRecurringFeeQuery,
  GetRecurringFeeQueryVariables
>;
export const GetStopVacatingDataDocument = gql`
  query getStopVacatingData($id: ID!) {
    tenancy(tenancyId: $id) {
      ...CanCancelVacate
    }
  }
  ${CanCancelVacateFragmentDoc}
`;

/**
 * __useGetStopVacatingDataQuery__
 *
 * To run a query within a React component, call `useGetStopVacatingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStopVacatingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStopVacatingDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStopVacatingDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStopVacatingDataQuery,
    GetStopVacatingDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetStopVacatingDataQuery,
    GetStopVacatingDataQueryVariables
  >(GetStopVacatingDataDocument, baseOptions);
}
export function useGetStopVacatingDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStopVacatingDataQuery,
    GetStopVacatingDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetStopVacatingDataQuery,
    GetStopVacatingDataQueryVariables
  >(GetStopVacatingDataDocument, baseOptions);
}
export type GetStopVacatingDataQueryHookResult = ReturnType<
  typeof useGetStopVacatingDataQuery
>;
export type GetStopVacatingDataLazyQueryHookResult = ReturnType<
  typeof useGetStopVacatingDataLazyQuery
>;
export type GetStopVacatingDataQueryResult = Apollo.QueryResult<
  GetStopVacatingDataQuery,
  GetStopVacatingDataQueryVariables
>;
export const GetTeamsByOrganisationDocument = gql`
  query getTeamsByOrganisation(
    $organisationId: ID!
    $organisationAilorn: AiloRN!
  ) {
    organisation(id: $organisationId) {
      id
      teams {
        id
        name
        myTeam
      }
    }
    managementsForOrg(
      organisationId: $organisationAilorn
      pageCursor: { pageSize: 0 }
      filters: [
        { name: Active }
        {
          name: BelongToTeamsAndUnassigned
          args: {
            BelongToTeamsAndUnassigned: { teamIds: [], includeUnassigned: true }
          }
        }
      ]
    ) {
      pageInfo {
        total
      }
    }
  }
`;

/**
 * __useGetTeamsByOrganisationQuery__
 *
 * To run a query within a React component, call `useGetTeamsByOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsByOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsByOrganisationQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useGetTeamsByOrganisationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamsByOrganisationQuery,
    GetTeamsByOrganisationQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTeamsByOrganisationQuery,
    GetTeamsByOrganisationQueryVariables
  >(GetTeamsByOrganisationDocument, baseOptions);
}
export function useGetTeamsByOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamsByOrganisationQuery,
    GetTeamsByOrganisationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTeamsByOrganisationQuery,
    GetTeamsByOrganisationQueryVariables
  >(GetTeamsByOrganisationDocument, baseOptions);
}
export type GetTeamsByOrganisationQueryHookResult = ReturnType<
  typeof useGetTeamsByOrganisationQuery
>;
export type GetTeamsByOrganisationLazyQueryHookResult = ReturnType<
  typeof useGetTeamsByOrganisationLazyQuery
>;
export type GetTeamsByOrganisationQueryResult = Apollo.QueryResult<
  GetTeamsByOrganisationQuery,
  GetTeamsByOrganisationQueryVariables
>;
export const GetTeamsWithContactByOrganisationDocument = gql`
  query getTeamsWithContactByOrganisation($organisationId: ID!) {
    organisation(id: $organisationId) {
      id
      teams {
        id
        name
        myTeam
        contacts {
          ailorn
        }
      }
    }
  }
`;

/**
 * __useGetTeamsWithContactByOrganisationQuery__
 *
 * To run a query within a React component, call `useGetTeamsWithContactByOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsWithContactByOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsWithContactByOrganisationQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetTeamsWithContactByOrganisationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamsWithContactByOrganisationQuery,
    GetTeamsWithContactByOrganisationQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTeamsWithContactByOrganisationQuery,
    GetTeamsWithContactByOrganisationQueryVariables
  >(GetTeamsWithContactByOrganisationDocument, baseOptions);
}
export function useGetTeamsWithContactByOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamsWithContactByOrganisationQuery,
    GetTeamsWithContactByOrganisationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTeamsWithContactByOrganisationQuery,
    GetTeamsWithContactByOrganisationQueryVariables
  >(GetTeamsWithContactByOrganisationDocument, baseOptions);
}
export type GetTeamsWithContactByOrganisationQueryHookResult = ReturnType<
  typeof useGetTeamsWithContactByOrganisationQuery
>;
export type GetTeamsWithContactByOrganisationLazyQueryHookResult = ReturnType<
  typeof useGetTeamsWithContactByOrganisationLazyQuery
>;
export type GetTeamsWithContactByOrganisationQueryResult = Apollo.QueryResult<
  GetTeamsWithContactByOrganisationQuery,
  GetTeamsWithContactByOrganisationQueryVariables
>;
export const GetTenanciesDocument = gql`
  query getTenancies($managementId: ID!) {
    management(managementId: $managementId) {
      id
      occupancyStatus
      endDate
      managingEntity {
        ailoRN
      }
      mostRecentTenancy {
        id
        endDate
        vacatingReason
        vacatingNotes
        tenantships {
          items {
            tenant {
              __typename
              id
              ... on Person {
                firstName
                lastName
                phoneNo
                emailAddress
              }
            }
          }
        }
      }
      nextTenancy {
        id
        tenantships {
          items {
            tenant {
              __typename
              id
              ... on Person {
                firstName
                lastName
                phoneNo
                emailAddress
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetTenanciesQuery__
 *
 * To run a query within a React component, call `useGetTenanciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenanciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenanciesQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetTenanciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenanciesQuery,
    GetTenanciesQueryVariables
  >
) {
  return Apollo.useQuery<GetTenanciesQuery, GetTenanciesQueryVariables>(
    GetTenanciesDocument,
    baseOptions
  );
}
export function useGetTenanciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenanciesQuery,
    GetTenanciesQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetTenanciesQuery, GetTenanciesQueryVariables>(
    GetTenanciesDocument,
    baseOptions
  );
}
export type GetTenanciesQueryHookResult = ReturnType<
  typeof useGetTenanciesQuery
>;
export type GetTenanciesLazyQueryHookResult = ReturnType<
  typeof useGetTenanciesLazyQuery
>;
export type GetTenanciesQueryResult = Apollo.QueryResult<
  GetTenanciesQuery,
  GetTenanciesQueryVariables
>;
export const GetTenancyOutstandingLiabilityDocument = gql`
  query getTenancyOutstandingLiability($id: ID!) {
    tenancy(tenancyId: $id) {
      id
      ...TenancyLiability
    }
  }
  ${TenancyLiabilityFragmentDoc}
`;

/**
 * __useGetTenancyOutstandingLiabilityQuery__
 *
 * To run a query within a React component, call `useGetTenancyOutstandingLiabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenancyOutstandingLiabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenancyOutstandingLiabilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenancyOutstandingLiabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenancyOutstandingLiabilityQuery,
    GetTenancyOutstandingLiabilityQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenancyOutstandingLiabilityQuery,
    GetTenancyOutstandingLiabilityQueryVariables
  >(GetTenancyOutstandingLiabilityDocument, baseOptions);
}
export function useGetTenancyOutstandingLiabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenancyOutstandingLiabilityQuery,
    GetTenancyOutstandingLiabilityQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenancyOutstandingLiabilityQuery,
    GetTenancyOutstandingLiabilityQueryVariables
  >(GetTenancyOutstandingLiabilityDocument, baseOptions);
}
export type GetTenancyOutstandingLiabilityQueryHookResult = ReturnType<
  typeof useGetTenancyOutstandingLiabilityQuery
>;
export type GetTenancyOutstandingLiabilityLazyQueryHookResult = ReturnType<
  typeof useGetTenancyOutstandingLiabilityLazyQuery
>;
export type GetTenancyOutstandingLiabilityQueryResult = Apollo.QueryResult<
  GetTenancyOutstandingLiabilityQuery,
  GetTenancyOutstandingLiabilityQueryVariables
>;
export const GetTenantsAndOutstandingLiabilityDocument = gql`
  query getTenantsAndOutstandingLiability(
    $id: ID!
    $organisationAilorn: AiloRN!
  ) {
    tenancy(tenancyId: $id) {
      id
      endDate
      voidedAt
      ...CanCancelVacate
      ...TenancyLiability
      payerBillsSummary {
        outstandingBillsBalance {
          cents
        }
      }
      tenantships {
        items {
          tenant {
            ...tenantInfo
          }
        }
      }
      ...upcomingTenancyAgreement
      rentReviewAllowed
      editableRentReview {
        rent {
          ...EditableRent
        }
      }
      leaseReviewAllowedOperation
      bond {
        ...tenancyBond
      }
    }
  }
  ${CanCancelVacateFragmentDoc}
  ${TenancyLiabilityFragmentDoc}
  ${TenantInfoFragmentDoc}
  ${UpcomingTenancyAgreementFragmentDoc}
  ${EditableRentFragmentDoc}
  ${TenancyBondFragmentDoc}
`;

/**
 * __useGetTenantsAndOutstandingLiabilityQuery__
 *
 * To run a query within a React component, call `useGetTenantsAndOutstandingLiabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsAndOutstandingLiabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsAndOutstandingLiabilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useGetTenantsAndOutstandingLiabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenantsAndOutstandingLiabilityQuery,
    GetTenantsAndOutstandingLiabilityQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenantsAndOutstandingLiabilityQuery,
    GetTenantsAndOutstandingLiabilityQueryVariables
  >(GetTenantsAndOutstandingLiabilityDocument, baseOptions);
}
export function useGetTenantsAndOutstandingLiabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantsAndOutstandingLiabilityQuery,
    GetTenantsAndOutstandingLiabilityQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenantsAndOutstandingLiabilityQuery,
    GetTenantsAndOutstandingLiabilityQueryVariables
  >(GetTenantsAndOutstandingLiabilityDocument, baseOptions);
}
export type GetTenantsAndOutstandingLiabilityQueryHookResult = ReturnType<
  typeof useGetTenantsAndOutstandingLiabilityQuery
>;
export type GetTenantsAndOutstandingLiabilityLazyQueryHookResult = ReturnType<
  typeof useGetTenantsAndOutstandingLiabilityLazyQuery
>;
export type GetTenantsAndOutstandingLiabilityQueryResult = Apollo.QueryResult<
  GetTenantsAndOutstandingLiabilityQuery,
  GetTenantsAndOutstandingLiabilityQueryVariables
>;
export const GetManagementEndDateDocument = gql`
  query getManagementEndDate($managementId: ID!) {
    management(managementId: $managementId) {
      id
      endDate
    }
  }
`;

/**
 * __useGetManagementEndDateQuery__
 *
 * To run a query within a React component, call `useGetManagementEndDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementEndDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementEndDateQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetManagementEndDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementEndDateQuery,
    GetManagementEndDateQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementEndDateQuery,
    GetManagementEndDateQueryVariables
  >(GetManagementEndDateDocument, baseOptions);
}
export function useGetManagementEndDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementEndDateQuery,
    GetManagementEndDateQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementEndDateQuery,
    GetManagementEndDateQueryVariables
  >(GetManagementEndDateDocument, baseOptions);
}
export type GetManagementEndDateQueryHookResult = ReturnType<
  typeof useGetManagementEndDateQuery
>;
export type GetManagementEndDateLazyQueryHookResult = ReturnType<
  typeof useGetManagementEndDateLazyQuery
>;
export type GetManagementEndDateQueryResult = Apollo.QueryResult<
  GetManagementEndDateQuery,
  GetManagementEndDateQueryVariables
>;
export const SearchAgencyPropertiesDocument = gql`
  query searchAgencyProperties(
    $search: String!
    $orgAilorn: AiloRN!
    $pageSize: Int!
  ) {
    searchAgencyProperties(
      input: {
        query: $search
        managingEntityOrgAilorn: $orgAilorn
        paginationParams: { pageSize: $pageSize }
      }
    ) {
      items {
        ...AgencyPropertyAddress
      }
    }
  }
  ${AgencyPropertyAddressFragmentDoc}
`;

/**
 * __useSearchAgencyPropertiesQuery__
 *
 * To run a query within a React component, call `useSearchAgencyPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAgencyPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAgencyPropertiesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      orgAilorn: // value for 'orgAilorn'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useSearchAgencyPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAgencyPropertiesQuery,
    SearchAgencyPropertiesQueryVariables
  >
) {
  return Apollo.useQuery<
    SearchAgencyPropertiesQuery,
    SearchAgencyPropertiesQueryVariables
  >(SearchAgencyPropertiesDocument, baseOptions);
}
export function useSearchAgencyPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAgencyPropertiesQuery,
    SearchAgencyPropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SearchAgencyPropertiesQuery,
    SearchAgencyPropertiesQueryVariables
  >(SearchAgencyPropertiesDocument, baseOptions);
}
export type SearchAgencyPropertiesQueryHookResult = ReturnType<
  typeof useSearchAgencyPropertiesQuery
>;
export type SearchAgencyPropertiesLazyQueryHookResult = ReturnType<
  typeof useSearchAgencyPropertiesLazyQuery
>;
export type SearchAgencyPropertiesQueryResult = Apollo.QueryResult<
  SearchAgencyPropertiesQuery,
  SearchAgencyPropertiesQueryVariables
>;
export const SearchManagementsDocument = gql`
  query searchManagements($search: String!, $orgAilorn: AiloRN!) {
    managementsForOrg(
      organisationId: $orgAilorn
      pageCursor: {
        pageSize: 10
        sort: { field: AddressSimilarity, direction: Desc }
      }
      filters: [
        {
          name: AddressSimilarity
          args: { AddressSimilarity: { search: $search, threshold: 0.2 } }
        }
        { name: DefaultPropertyList }
      ]
    ) {
      items {
        id
        ...ManagementProperty
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useSearchManagementsQuery__
 *
 * To run a query within a React component, call `useSearchManagementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchManagementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchManagementsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      orgAilorn: // value for 'orgAilorn'
 *   },
 * });
 */
export function useSearchManagementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchManagementsQuery,
    SearchManagementsQueryVariables
  >
) {
  return Apollo.useQuery<
    SearchManagementsQuery,
    SearchManagementsQueryVariables
  >(SearchManagementsDocument, baseOptions);
}
export function useSearchManagementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchManagementsQuery,
    SearchManagementsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SearchManagementsQuery,
    SearchManagementsQueryVariables
  >(SearchManagementsDocument, baseOptions);
}
export type SearchManagementsQueryHookResult = ReturnType<
  typeof useSearchManagementsQuery
>;
export type SearchManagementsLazyQueryHookResult = ReturnType<
  typeof useSearchManagementsLazyQuery
>;
export type SearchManagementsQueryResult = Apollo.QueryResult<
  SearchManagementsQuery,
  SearchManagementsQueryVariables
>;
export const SearchTenanciesDocument = gql`
  query searchTenancies(
    $filters: TenanciesQueryFilterInput
    $sort: [TenanciesSortParams!]
    $pageSize: Int!
    $cursor: String
  ) {
    searchTenancies(
      input: {
        filters: $filters
        sort: $sort
        paginationParams: { cursor: $cursor, pageSize: $pageSize }
      }
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
      }
      items {
        id
        tenancy {
          ...SearchTenanciesResult
        }
      }
    }
  }
  ${SearchTenanciesResultFragmentDoc}
`;

/**
 * __useSearchTenanciesQuery__
 *
 * To run a query within a React component, call `useSearchTenanciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTenanciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTenanciesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSearchTenanciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchTenanciesQuery,
    SearchTenanciesQueryVariables
  >
) {
  return Apollo.useQuery<SearchTenanciesQuery, SearchTenanciesQueryVariables>(
    SearchTenanciesDocument,
    baseOptions
  );
}
export function useSearchTenanciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchTenanciesQuery,
    SearchTenanciesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SearchTenanciesQuery,
    SearchTenanciesQueryVariables
  >(SearchTenanciesDocument, baseOptions);
}
export type SearchTenanciesQueryHookResult = ReturnType<
  typeof useSearchTenanciesQuery
>;
export type SearchTenanciesLazyQueryHookResult = ReturnType<
  typeof useSearchTenanciesLazyQuery
>;
export type SearchTenanciesQueryResult = Apollo.QueryResult<
  SearchTenanciesQuery,
  SearchTenanciesQueryVariables
>;
export const GetStatementsDocument = gql`
  query getStatements(
    $filter: GeneralStatementFilter
    $cursor: String
    $pageSize: Int!
    $sort: [StatementSort!]
  ) {
    statements(
      filter: $filter
      paginationParams: { cursor: $cursor, pageSize: $pageSize }
      sort: $sort
    ) {
      pageInfo {
        hasNext
        hasPrevious
        nextCursor
        previousCursor
        total
      }
      items {
        id
        ailorn: ailoRN
        file {
          ailorn: id
          url
          kind
          contentType
        }
        type
        rangeStartDate
        rangeEndDate
        statementProgress {
          id
          rangeStartBusinessTxId
          rangeEndBusinessTxId
        }
      }
    }
  }
`;

/**
 * __useGetStatementsQuery__
 *
 * To run a query within a React component, call `useGetStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatementsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      pageSize: // value for 'pageSize'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetStatementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStatementsQuery,
    GetStatementsQueryVariables
  >
) {
  return Apollo.useQuery<GetStatementsQuery, GetStatementsQueryVariables>(
    GetStatementsDocument,
    baseOptions
  );
}
export function useGetStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStatementsQuery,
    GetStatementsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetStatementsQuery, GetStatementsQueryVariables>(
    GetStatementsDocument,
    baseOptions
  );
}
export type GetStatementsQueryHookResult = ReturnType<
  typeof useGetStatementsQuery
>;
export type GetStatementsLazyQueryHookResult = ReturnType<
  typeof useGetStatementsLazyQuery
>;
export type GetStatementsQueryResult = Apollo.QueryResult<
  GetStatementsQuery,
  GetStatementsQueryVariables
>;
export const GetTransferStatementDataDocument = gql`
  query getTransferStatementData(
    $rangeStartBusinessTxId: ID
    $rangeEndBusinessTxId: ID!
    $walletOwnerReference: AiloRN!
    $paginationParams: PaginationParams
  ) {
    transferSummaryStatementData(
      input: {
        from: $rangeStartBusinessTxId
        to: $rangeEndBusinessTxId
        walletOwnerReference: $walletOwnerReference
      }
    ) {
      transactionLineItemsByStatementTransactionDate(
        paginationParams: $paginationParams
      ) {
        pageInfo {
          total
          hasNext
          nextCursor
        }
        items {
          __typename
          sourceId
          clearedDate
          statementTransactionDateTime
          management {
            id
            team {
              id
              name
            }
            property {
              id
              address {
                ...Address
              }
            }
          }
          liability {
            id
            providerReference
            taxCategory
          }
          businessTransactionStatus
          gst {
            cents
          }
          btAmount {
            cents
          }
          walletChangeAmount {
            cents
          }
          businessTransactionType
        }
      }
    }
  }
  ${AddressFragmentDoc}
`;

/**
 * __useGetTransferStatementDataQuery__
 *
 * To run a query within a React component, call `useGetTransferStatementDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferStatementDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferStatementDataQuery({
 *   variables: {
 *      rangeStartBusinessTxId: // value for 'rangeStartBusinessTxId'
 *      rangeEndBusinessTxId: // value for 'rangeEndBusinessTxId'
 *      walletOwnerReference: // value for 'walletOwnerReference'
 *      paginationParams: // value for 'paginationParams'
 *   },
 * });
 */
export function useGetTransferStatementDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTransferStatementDataQuery,
    GetTransferStatementDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTransferStatementDataQuery,
    GetTransferStatementDataQueryVariables
  >(GetTransferStatementDataDocument, baseOptions);
}
export function useGetTransferStatementDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransferStatementDataQuery,
    GetTransferStatementDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTransferStatementDataQuery,
    GetTransferStatementDataQueryVariables
  >(GetTransferStatementDataDocument, baseOptions);
}
export type GetTransferStatementDataQueryHookResult = ReturnType<
  typeof useGetTransferStatementDataQuery
>;
export type GetTransferStatementDataLazyQueryHookResult = ReturnType<
  typeof useGetTransferStatementDataLazyQuery
>;
export type GetTransferStatementDataQueryResult = Apollo.QueryResult<
  GetTransferStatementDataQuery,
  GetTransferStatementDataQueryVariables
>;
export const AbrCompanyLookupDocument = gql`
  query abrCompanyLookup($abnOrAcn: String!) {
    abrCompanyLookup(abnOrAcn: $abnOrAcn) {
      registeredEntityName
      abn
      acn
      addressState
      addressPostcode
      __typename
    }
  }
`;

/**
 * __useAbrCompanyLookupQuery__
 *
 * To run a query within a React component, call `useAbrCompanyLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAbrCompanyLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAbrCompanyLookupQuery({
 *   variables: {
 *      abnOrAcn: // value for 'abnOrAcn'
 *   },
 * });
 */
export function useAbrCompanyLookupQuery(
  baseOptions: Apollo.QueryHookOptions<
    AbrCompanyLookupQuery,
    AbrCompanyLookupQueryVariables
  >
) {
  return Apollo.useQuery<AbrCompanyLookupQuery, AbrCompanyLookupQueryVariables>(
    AbrCompanyLookupDocument,
    baseOptions
  );
}
export function useAbrCompanyLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AbrCompanyLookupQuery,
    AbrCompanyLookupQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    AbrCompanyLookupQuery,
    AbrCompanyLookupQueryVariables
  >(AbrCompanyLookupDocument, baseOptions);
}
export type AbrCompanyLookupQueryHookResult = ReturnType<
  typeof useAbrCompanyLookupQuery
>;
export type AbrCompanyLookupLazyQueryHookResult = ReturnType<
  typeof useAbrCompanyLookupLazyQuery
>;
export type AbrCompanyLookupQueryResult = Apollo.QueryResult<
  AbrCompanyLookupQuery,
  AbrCompanyLookupQueryVariables
>;
export const CreatePaymentMethodDocument = gql`
  mutation createPaymentMethod(
    $ownerAiloRN: AiloRN!
    $managingOrganisationAiloRN: AiloRN!
    $bankAccount: BankAccountInput
    $bpay: BPayInput
    $createWalletOwner: Boolean
    $deleteExistingPaymentMethods: Boolean
  ) {
    createPaymentMethod(
      input: {
        managingOrganisationAiloRN: $managingOrganisationAiloRN
        walletOwnerAiloRN: $ownerAiloRN
        createWalletOwner: $createWalletOwner
        deleteExistingPaymentMethods: $deleteExistingPaymentMethods
        bankAccountInput: $bankAccount
        bpayInput: $bpay
      }
    ) {
      id
    }
  }
`;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      ownerAiloRN: // value for 'ownerAiloRN'
 *      managingOrganisationAiloRN: // value for 'managingOrganisationAiloRN'
 *      bankAccount: // value for 'bankAccount'
 *      bpay: // value for 'bpay'
 *      createWalletOwner: // value for 'createWalletOwner'
 *      deleteExistingPaymentMethods: // value for 'deleteExistingPaymentMethods'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentMethodMutation,
    CreatePaymentMethodMutationVariables
  >
) {
  return Apollo.useMutation<
    CreatePaymentMethodMutation,
    CreatePaymentMethodMutationVariables
  >(CreatePaymentMethodDocument, baseOptions);
}
export type CreatePaymentMethodMutationHookResult = ReturnType<
  typeof useCreatePaymentMethodMutation
>;
export type CreatePaymentMethodMutationResult =
  Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;
export const CreateSupplierDocument = gql`
  mutation createSupplier(
    $organisation: AiloRN!
    $abn: String!
    $name: String!
    $registeredEntityName: String!
    $address: SupplierAddressInput!
    $emailAddress: String
  ) {
    createSupplier(
      input: {
        organisationAiloRN: $organisation
        abn: $abn
        name: $name
        registeredEntityName: $registeredEntityName
        address: $address
        emailAddress: $emailAddress
      }
    ) {
      id
      ailoRN
    }
  }
`;
export type CreateSupplierMutationFn = Apollo.MutationFunction<
  CreateSupplierMutation,
  CreateSupplierMutationVariables
>;

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      organisation: // value for 'organisation'
 *      abn: // value for 'abn'
 *      name: // value for 'name'
 *      registeredEntityName: // value for 'registeredEntityName'
 *      address: // value for 'address'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useCreateSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSupplierMutation,
    CreateSupplierMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateSupplierMutation,
    CreateSupplierMutationVariables
  >(CreateSupplierDocument, baseOptions);
}
export type CreateSupplierMutationHookResult = ReturnType<
  typeof useCreateSupplierMutation
>;
export type CreateSupplierMutationResult =
  Apollo.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = Apollo.BaseMutationOptions<
  CreateSupplierMutation,
  CreateSupplierMutationVariables
>;
export const GetAnalyticsDataDocument = gql`
  query getAnalyticsData {
    effectiveUser {
      id
      person {
        id
        firstName
        lastName
        emailAddress
      }
      organisations(filter: { orgType: Agency }) {
        id
      }
    }
  }
`;

/**
 * __useGetAnalyticsDataQuery__
 *
 * To run a query within a React component, call `useGetAnalyticsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnalyticsDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAnalyticsDataQuery,
    GetAnalyticsDataQueryVariables
  >
) {
  return Apollo.useQuery<GetAnalyticsDataQuery, GetAnalyticsDataQueryVariables>(
    GetAnalyticsDataDocument,
    baseOptions
  );
}
export function useGetAnalyticsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnalyticsDataQuery,
    GetAnalyticsDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAnalyticsDataQuery,
    GetAnalyticsDataQueryVariables
  >(GetAnalyticsDataDocument, baseOptions);
}
export type GetAnalyticsDataQueryHookResult = ReturnType<
  typeof useGetAnalyticsDataQuery
>;
export type GetAnalyticsDataLazyQueryHookResult = ReturnType<
  typeof useGetAnalyticsDataLazyQuery
>;
export type GetAnalyticsDataQueryResult = Apollo.QueryResult<
  GetAnalyticsDataQuery,
  GetAnalyticsDataQueryVariables
>;
export const GetAvailableFeaturesDocument = gql`
  query getAvailableFeatures {
    effectiveUser {
      id
      organisations {
        id
        orgType
        name
        availableFeatures {
          id
        }
        legalEntities {
          id
          ... on Company {
            ailoRN
            currentUserPermissions
            timezone
            registeredEntityName
            trustAccounts {
              edges {
                node {
                  id
                  ailorn
                }
              }
            }
          }
        }
        effectiveUserContact {
          ailorn
        }
      }
      person {
        id
        ailoRN
        firstName
        lastName
        emailAddress
        photo {
          id
          url
          thumbnailUrl
        }
        eulaSignedAt
      }
    }
  }
`;

/**
 * __useGetAvailableFeaturesQuery__
 *
 * To run a query within a React component, call `useGetAvailableFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableFeaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAvailableFeaturesQuery,
    GetAvailableFeaturesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAvailableFeaturesQuery,
    GetAvailableFeaturesQueryVariables
  >(GetAvailableFeaturesDocument, baseOptions);
}
export function useGetAvailableFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableFeaturesQuery,
    GetAvailableFeaturesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAvailableFeaturesQuery,
    GetAvailableFeaturesQueryVariables
  >(GetAvailableFeaturesDocument, baseOptions);
}
export type GetAvailableFeaturesQueryHookResult = ReturnType<
  typeof useGetAvailableFeaturesQuery
>;
export type GetAvailableFeaturesLazyQueryHookResult = ReturnType<
  typeof useGetAvailableFeaturesLazyQuery
>;
export type GetAvailableFeaturesQueryResult = Apollo.QueryResult<
  GetAvailableFeaturesQuery,
  GetAvailableFeaturesQueryVariables
>;
export const CreateActionDocument = gql`
  mutation createAction($input: CreateActionInput!) {
    createAction(input: $input) {
      ...ActionDetails
    }
  }
  ${ActionDetailsFragmentDoc}
`;
export type CreateActionMutationFn = Apollo.MutationFunction<
  CreateActionMutation,
  CreateActionMutationVariables
>;

/**
 * __useCreateActionMutation__
 *
 * To run a mutation, you first call `useCreateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActionMutation, { data, loading, error }] = useCreateActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateActionMutation,
    CreateActionMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateActionMutation,
    CreateActionMutationVariables
  >(CreateActionDocument, baseOptions);
}
export type CreateActionMutationHookResult = ReturnType<
  typeof useCreateActionMutation
>;
export type CreateActionMutationResult =
  Apollo.MutationResult<CreateActionMutation>;
export type CreateActionMutationOptions = Apollo.BaseMutationOptions<
  CreateActionMutation,
  CreateActionMutationVariables
>;
export const GetActionUpdateDetailsDocument = gql`
  query getActionUpdateDetails($id: ID!) {
    action(id: $id) {
      ...ActionDetails
      project {
        id
        organisation {
          id
          members(pageCursor: { pageSize: 1000 }) {
            items {
              ...PersonNames
              photo {
                id
                url
                thumbnailUrl
              }
            }
          }
        }
      }
    }
  }
  ${ActionDetailsFragmentDoc}
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetActionUpdateDetailsQuery__
 *
 * To run a query within a React component, call `useGetActionUpdateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionUpdateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionUpdateDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActionUpdateDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActionUpdateDetailsQuery,
    GetActionUpdateDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetActionUpdateDetailsQuery,
    GetActionUpdateDetailsQueryVariables
  >(GetActionUpdateDetailsDocument, baseOptions);
}
export function useGetActionUpdateDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActionUpdateDetailsQuery,
    GetActionUpdateDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetActionUpdateDetailsQuery,
    GetActionUpdateDetailsQueryVariables
  >(GetActionUpdateDetailsDocument, baseOptions);
}
export type GetActionUpdateDetailsQueryHookResult = ReturnType<
  typeof useGetActionUpdateDetailsQuery
>;
export type GetActionUpdateDetailsLazyQueryHookResult = ReturnType<
  typeof useGetActionUpdateDetailsLazyQuery
>;
export type GetActionUpdateDetailsQueryResult = Apollo.QueryResult<
  GetActionUpdateDetailsQuery,
  GetActionUpdateDetailsQueryVariables
>;
export const UpdateActionDocument = gql`
  mutation updateAction($input: UpdateActionInput!) {
    updateAction(input: $input) {
      ...ActionDetails
    }
  }
  ${ActionDetailsFragmentDoc}
`;
export type UpdateActionMutationFn = Apollo.MutationFunction<
  UpdateActionMutation,
  UpdateActionMutationVariables
>;

/**
 * __useUpdateActionMutation__
 *
 * To run a mutation, you first call `useUpdateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionMutation, { data, loading, error }] = useUpdateActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateActionMutation,
    UpdateActionMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateActionMutation,
    UpdateActionMutationVariables
  >(UpdateActionDocument, baseOptions);
}
export type UpdateActionMutationHookResult = ReturnType<
  typeof useUpdateActionMutation
>;
export type UpdateActionMutationResult =
  Apollo.MutationResult<UpdateActionMutation>;
export type UpdateActionMutationOptions = Apollo.BaseMutationOptions<
  UpdateActionMutation,
  UpdateActionMutationVariables
>;
export const GetActionAssigneesDocument = gql`
  query getActionAssignees($organisationId: ID!) {
    organisation(id: $organisationId) {
      id
      members(pageCursor: { pageSize: 1000 }) {
        items {
          ...PersonDisplayDetails
        }
      }
    }
  }
  ${PersonDisplayDetailsFragmentDoc}
`;

/**
 * __useGetActionAssigneesQuery__
 *
 * To run a query within a React component, call `useGetActionAssigneesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionAssigneesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionAssigneesQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetActionAssigneesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActionAssigneesQuery,
    GetActionAssigneesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetActionAssigneesQuery,
    GetActionAssigneesQueryVariables
  >(GetActionAssigneesDocument, baseOptions);
}
export function useGetActionAssigneesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActionAssigneesQuery,
    GetActionAssigneesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetActionAssigneesQuery,
    GetActionAssigneesQueryVariables
  >(GetActionAssigneesDocument, baseOptions);
}
export type GetActionAssigneesQueryHookResult = ReturnType<
  typeof useGetActionAssigneesQuery
>;
export type GetActionAssigneesLazyQueryHookResult = ReturnType<
  typeof useGetActionAssigneesLazyQuery
>;
export type GetActionAssigneesQueryResult = Apollo.QueryResult<
  GetActionAssigneesQuery,
  GetActionAssigneesQueryVariables
>;
export const PersonEmailInUseDocument = gql`
  query personEmailInUse($email: String!) {
    personEmailInUse(email: $email)
  }
`;

/**
 * __usePersonEmailInUseQuery__
 *
 * To run a query within a React component, call `usePersonEmailInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonEmailInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonEmailInUseQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePersonEmailInUseQuery(
  baseOptions: Apollo.QueryHookOptions<
    PersonEmailInUseQuery,
    PersonEmailInUseQueryVariables
  >
) {
  return Apollo.useQuery<PersonEmailInUseQuery, PersonEmailInUseQueryVariables>(
    PersonEmailInUseDocument,
    baseOptions
  );
}
export function usePersonEmailInUseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersonEmailInUseQuery,
    PersonEmailInUseQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    PersonEmailInUseQuery,
    PersonEmailInUseQueryVariables
  >(PersonEmailInUseDocument, baseOptions);
}
export type PersonEmailInUseQueryHookResult = ReturnType<
  typeof usePersonEmailInUseQuery
>;
export type PersonEmailInUseLazyQueryHookResult = ReturnType<
  typeof usePersonEmailInUseLazyQuery
>;
export type PersonEmailInUseQueryResult = Apollo.QueryResult<
  PersonEmailInUseQuery,
  PersonEmailInUseQueryVariables
>;
export const GetManagementDetailsForEditDocument = gql`
  query getManagementDetailsForEdit($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      startDate
      endDate
      scheduledRentReviewDate
      nextTenancyAgreement {
        id
        startDate
        fixedTermEndDate
      }
      displayRent {
        id
        amountInCents
        period
        effectiveDate
      }
      management {
        id
        mostRecentTenancy {
          id
          endDate
        }
      }
    }
  }
`;

/**
 * __useGetManagementDetailsForEditQuery__
 *
 * To run a query within a React component, call `useGetManagementDetailsForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementDetailsForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementDetailsForEditQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetManagementDetailsForEditQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementDetailsForEditQuery,
    GetManagementDetailsForEditQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementDetailsForEditQuery,
    GetManagementDetailsForEditQueryVariables
  >(GetManagementDetailsForEditDocument, baseOptions);
}
export function useGetManagementDetailsForEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementDetailsForEditQuery,
    GetManagementDetailsForEditQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementDetailsForEditQuery,
    GetManagementDetailsForEditQueryVariables
  >(GetManagementDetailsForEditDocument, baseOptions);
}
export type GetManagementDetailsForEditQueryHookResult = ReturnType<
  typeof useGetManagementDetailsForEditQuery
>;
export type GetManagementDetailsForEditLazyQueryHookResult = ReturnType<
  typeof useGetManagementDetailsForEditLazyQuery
>;
export type GetManagementDetailsForEditQueryResult = Apollo.QueryResult<
  GetManagementDetailsForEditQuery,
  GetManagementDetailsForEditQueryVariables
>;
export const CreateManagementDocument = gql`
  mutation createManagement($managementDetails: ManagementAndTenancyInput!) {
    setupManagementAndTenancy(
      managementAndTenancyDetails: $managementDetails
      autoPublish: true
      doNotNotify: false
    ) {
      management {
        ...Management
      }
    }
  }
  ${ManagementFragmentDoc}
`;
export type CreateManagementMutationFn = Apollo.MutationFunction<
  CreateManagementMutation,
  CreateManagementMutationVariables
>;

/**
 * __useCreateManagementMutation__
 *
 * To run a mutation, you first call `useCreateManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagementMutation, { data, loading, error }] = useCreateManagementMutation({
 *   variables: {
 *      managementDetails: // value for 'managementDetails'
 *   },
 * });
 */
export function useCreateManagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManagementMutation,
    CreateManagementMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateManagementMutation,
    CreateManagementMutationVariables
  >(CreateManagementDocument, baseOptions);
}
export type CreateManagementMutationHookResult = ReturnType<
  typeof useCreateManagementMutation
>;
export type CreateManagementMutationResult =
  Apollo.MutationResult<CreateManagementMutation>;
export type CreateManagementMutationOptions = Apollo.BaseMutationOptions<
  CreateManagementMutation,
  CreateManagementMutationVariables
>;
export const UpdateIngoingManagementDocument = gql`
  mutation updateIngoingManagement(
    $tenancyDetails: IngoingTenancyUpdateInput!
  ) {
    updateIngoingTenancy(tenancyDetails: $tenancyDetails) {
      id
      ...Tenancy
      rents(cursor: { pageSize: 1 }) {
        items {
          ...Rent
        }
      }
      scheduledRentReviewDate
      nextTenancyAgreement {
        id
        startDate
        fixedTermEndDate
      }
      nextRentSchedule {
        id
        amountInCents
        period
        startDate
      }
    }
  }
  ${TenancyFragmentDoc}
  ${RentFragmentDoc}
`;
export type UpdateIngoingManagementMutationFn = Apollo.MutationFunction<
  UpdateIngoingManagementMutation,
  UpdateIngoingManagementMutationVariables
>;

/**
 * __useUpdateIngoingManagementMutation__
 *
 * To run a mutation, you first call `useUpdateIngoingManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIngoingManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIngoingManagementMutation, { data, loading, error }] = useUpdateIngoingManagementMutation({
 *   variables: {
 *      tenancyDetails: // value for 'tenancyDetails'
 *   },
 * });
 */
export function useUpdateIngoingManagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIngoingManagementMutation,
    UpdateIngoingManagementMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateIngoingManagementMutation,
    UpdateIngoingManagementMutationVariables
  >(UpdateIngoingManagementDocument, baseOptions);
}
export type UpdateIngoingManagementMutationHookResult = ReturnType<
  typeof useUpdateIngoingManagementMutation
>;
export type UpdateIngoingManagementMutationResult =
  Apollo.MutationResult<UpdateIngoingManagementMutation>;
export type UpdateIngoingManagementMutationOptions = Apollo.BaseMutationOptions<
  UpdateIngoingManagementMutation,
  UpdateIngoingManagementMutationVariables
>;
export const EndManagementV2Document = gql`
  mutation EndManagementV2($input: EndManagementV2Input!) {
    endManagementV2(input: $input) {
      management {
        ...ManagementEndDetails
        currentTenancy {
          ...CanCancelVacate
        }
      }
    }
  }
  ${ManagementEndDetailsFragmentDoc}
  ${CanCancelVacateFragmentDoc}
`;
export type EndManagementV2MutationFn = Apollo.MutationFunction<
  EndManagementV2Mutation,
  EndManagementV2MutationVariables
>;

/**
 * __useEndManagementV2Mutation__
 *
 * To run a mutation, you first call `useEndManagementV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndManagementV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endManagementV2Mutation, { data, loading, error }] = useEndManagementV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndManagementV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndManagementV2Mutation,
    EndManagementV2MutationVariables
  >
) {
  return Apollo.useMutation<
    EndManagementV2Mutation,
    EndManagementV2MutationVariables
  >(EndManagementV2Document, baseOptions);
}
export type EndManagementV2MutationHookResult = ReturnType<
  typeof useEndManagementV2Mutation
>;
export type EndManagementV2MutationResult =
  Apollo.MutationResult<EndManagementV2Mutation>;
export type EndManagementV2MutationOptions = Apollo.BaseMutationOptions<
  EndManagementV2Mutation,
  EndManagementV2MutationVariables
>;
export const GetManagementEndReasonsDocument = gql`
  query getManagementEndReasons {
    managementEndReasons {
      ...ManagementEndReason
    }
  }
  ${ManagementEndReasonFragmentDoc}
`;

/**
 * __useGetManagementEndReasonsQuery__
 *
 * To run a query within a React component, call `useGetManagementEndReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementEndReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementEndReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagementEndReasonsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManagementEndReasonsQuery,
    GetManagementEndReasonsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementEndReasonsQuery,
    GetManagementEndReasonsQueryVariables
  >(GetManagementEndReasonsDocument, baseOptions);
}
export function useGetManagementEndReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementEndReasonsQuery,
    GetManagementEndReasonsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementEndReasonsQuery,
    GetManagementEndReasonsQueryVariables
  >(GetManagementEndReasonsDocument, baseOptions);
}
export type GetManagementEndReasonsQueryHookResult = ReturnType<
  typeof useGetManagementEndReasonsQuery
>;
export type GetManagementEndReasonsLazyQueryHookResult = ReturnType<
  typeof useGetManagementEndReasonsLazyQuery
>;
export type GetManagementEndReasonsQueryResult = Apollo.QueryResult<
  GetManagementEndReasonsQuery,
  GetManagementEndReasonsQueryVariables
>;
export const GetEndingManagementDetailsDocument = gql`
  query getEndingManagementDetails($managementId: ID!) {
    management(managementId: $managementId) {
      ...EndingManagementDetails
      ...ManagementEndDetails
    }
  }
  ${EndingManagementDetailsFragmentDoc}
  ${ManagementEndDetailsFragmentDoc}
`;

/**
 * __useGetEndingManagementDetailsQuery__
 *
 * To run a query within a React component, call `useGetEndingManagementDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndingManagementDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndingManagementDetailsQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetEndingManagementDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEndingManagementDetailsQuery,
    GetEndingManagementDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetEndingManagementDetailsQuery,
    GetEndingManagementDetailsQueryVariables
  >(GetEndingManagementDetailsDocument, baseOptions);
}
export function useGetEndingManagementDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEndingManagementDetailsQuery,
    GetEndingManagementDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetEndingManagementDetailsQuery,
    GetEndingManagementDetailsQueryVariables
  >(GetEndingManagementDetailsDocument, baseOptions);
}
export type GetEndingManagementDetailsQueryHookResult = ReturnType<
  typeof useGetEndingManagementDetailsQuery
>;
export type GetEndingManagementDetailsLazyQueryHookResult = ReturnType<
  typeof useGetEndingManagementDetailsLazyQuery
>;
export type GetEndingManagementDetailsQueryResult = Apollo.QueryResult<
  GetEndingManagementDetailsQuery,
  GetEndingManagementDetailsQueryVariables
>;
export const GetDataForEndTenancyDocument = gql`
  query getDataForEndTenancy($id: ID!) {
    tenancy(tenancyId: $id) {
      id
      deposit {
        id
        status
      }
      liability {
        id
        reference
        effectivePaidToDate
      }
      endDate
      validVacateEndDateRange {
        startDate
        endDate
      }
      vacatingReason
      vacatingNotes
      mostRecentTenancyAgreement {
        id
        startDate
        fixedTermEndDate
        allowedToLapseAt
      }
      management {
        nextTenancy {
          id
          startDate
        }
      }
      property {
        ...PropertyIdAndAddress
      }
    }
  }
  ${PropertyIdAndAddressFragmentDoc}
`;

/**
 * __useGetDataForEndTenancyQuery__
 *
 * To run a query within a React component, call `useGetDataForEndTenancyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForEndTenancyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForEndTenancyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataForEndTenancyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDataForEndTenancyQuery,
    GetDataForEndTenancyQueryVariables
  >
) {
  return Apollo.useQuery<
    GetDataForEndTenancyQuery,
    GetDataForEndTenancyQueryVariables
  >(GetDataForEndTenancyDocument, baseOptions);
}
export function useGetDataForEndTenancyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataForEndTenancyQuery,
    GetDataForEndTenancyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetDataForEndTenancyQuery,
    GetDataForEndTenancyQueryVariables
  >(GetDataForEndTenancyDocument, baseOptions);
}
export type GetDataForEndTenancyQueryHookResult = ReturnType<
  typeof useGetDataForEndTenancyQuery
>;
export type GetDataForEndTenancyLazyQueryHookResult = ReturnType<
  typeof useGetDataForEndTenancyLazyQuery
>;
export type GetDataForEndTenancyQueryResult = Apollo.QueryResult<
  GetDataForEndTenancyQuery,
  GetDataForEndTenancyQueryVariables
>;
export const UpdateManagementAgreementDocument = gql`
  mutation updateManagementAgreement($input: UpdateManagementAgreementInput!) {
    updateManagementAgreement(input: $input) {
      managementAgreement {
        ...ManagementAgreement
      }
    }
  }
  ${ManagementAgreementFragmentDoc}
`;
export type UpdateManagementAgreementMutationFn = Apollo.MutationFunction<
  UpdateManagementAgreementMutation,
  UpdateManagementAgreementMutationVariables
>;

/**
 * __useUpdateManagementAgreementMutation__
 *
 * To run a mutation, you first call `useUpdateManagementAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagementAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagementAgreementMutation, { data, loading, error }] = useUpdateManagementAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManagementAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManagementAgreementMutation,
    UpdateManagementAgreementMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateManagementAgreementMutation,
    UpdateManagementAgreementMutationVariables
  >(UpdateManagementAgreementDocument, baseOptions);
}
export type UpdateManagementAgreementMutationHookResult = ReturnType<
  typeof useUpdateManagementAgreementMutation
>;
export type UpdateManagementAgreementMutationResult =
  Apollo.MutationResult<UpdateManagementAgreementMutation>;
export type UpdateManagementAgreementMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateManagementAgreementMutation,
    UpdateManagementAgreementMutationVariables
  >;
export const GetLeaseRenewalDataDocument = gql`
  query getLeaseRenewalData($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      endDate
      mostRecentTenancyAgreement {
        id
        startDate
        fixedTermEndDate
        allowedToLapseAt
      }
      rentReviewAllowed
      nextTenancyAgreement {
        id
        startDate
        fixedTermEndDate
        allowedToLapseAt
      }
      leaseReviewAllowedOperation
    }
  }
`;

/**
 * __useGetLeaseRenewalDataQuery__
 *
 * To run a query within a React component, call `useGetLeaseRenewalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseRenewalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseRenewalDataQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetLeaseRenewalDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeaseRenewalDataQuery,
    GetLeaseRenewalDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetLeaseRenewalDataQuery,
    GetLeaseRenewalDataQueryVariables
  >(GetLeaseRenewalDataDocument, baseOptions);
}
export function useGetLeaseRenewalDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeaseRenewalDataQuery,
    GetLeaseRenewalDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetLeaseRenewalDataQuery,
    GetLeaseRenewalDataQueryVariables
  >(GetLeaseRenewalDataDocument, baseOptions);
}
export type GetLeaseRenewalDataQueryHookResult = ReturnType<
  typeof useGetLeaseRenewalDataQuery
>;
export type GetLeaseRenewalDataLazyQueryHookResult = ReturnType<
  typeof useGetLeaseRenewalDataLazyQuery
>;
export type GetLeaseRenewalDataQueryResult = Apollo.QueryResult<
  GetLeaseRenewalDataQuery,
  GetLeaseRenewalDataQueryVariables
>;
export const CompleteAssignTenancyAgreementActionDocument = gql`
  mutation completeAssignTenancyAgreementAction(
    $id: ID!
    $input: CompleteAssignTenancyAgreementActionInput!
  ) {
    completeAssignTenancyAgreementAction(id: $id, input: $input) {
      id
      meta {
        ... on AssignTenancyAgreementActionMeta {
          tenancyAgreement {
            id
            startDate
            fixedTermEndDate
          }
        }
      }
    }
  }
`;
export type CompleteAssignTenancyAgreementActionMutationFn =
  Apollo.MutationFunction<
    CompleteAssignTenancyAgreementActionMutation,
    CompleteAssignTenancyAgreementActionMutationVariables
  >;

/**
 * __useCompleteAssignTenancyAgreementActionMutation__
 *
 * To run a mutation, you first call `useCompleteAssignTenancyAgreementActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAssignTenancyAgreementActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAssignTenancyAgreementActionMutation, { data, loading, error }] = useCompleteAssignTenancyAgreementActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteAssignTenancyAgreementActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteAssignTenancyAgreementActionMutation,
    CompleteAssignTenancyAgreementActionMutationVariables
  >
) {
  return Apollo.useMutation<
    CompleteAssignTenancyAgreementActionMutation,
    CompleteAssignTenancyAgreementActionMutationVariables
  >(CompleteAssignTenancyAgreementActionDocument, baseOptions);
}
export type CompleteAssignTenancyAgreementActionMutationHookResult = ReturnType<
  typeof useCompleteAssignTenancyAgreementActionMutation
>;
export type CompleteAssignTenancyAgreementActionMutationResult =
  Apollo.MutationResult<CompleteAssignTenancyAgreementActionMutation>;
export type CompleteAssignTenancyAgreementActionMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteAssignTenancyAgreementActionMutation,
    CompleteAssignTenancyAgreementActionMutationVariables
  >;
export const ReviewLeaseDocument = gql`
  mutation reviewLease(
    $tenancyId: ID!
    $agreementDetails: LeaseRenewalDetails
    $allowCurrentTenancyAgreementToLapse: Boolean
  ) {
    reviewLease(
      tenancyId: $tenancyId
      agreementDetails: $agreementDetails
      allowCurrentTenancyAgreementToLapse: $allowCurrentTenancyAgreementToLapse
    ) {
      id
      mostRecentTenancyAgreement {
        id
        allowedToLapseAt
      }
      ...upcomingTenancyAgreement
      ...upcomingRentChanges
      leaseReviewAllowed
      leaseReviewAllowedOperation
      rentReviewAllowed
    }
  }
  ${UpcomingTenancyAgreementFragmentDoc}
  ${UpcomingRentChangesFragmentDoc}
`;
export type ReviewLeaseMutationFn = Apollo.MutationFunction<
  ReviewLeaseMutation,
  ReviewLeaseMutationVariables
>;

/**
 * __useReviewLeaseMutation__
 *
 * To run a mutation, you first call `useReviewLeaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewLeaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewLeaseMutation, { data, loading, error }] = useReviewLeaseMutation({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *      agreementDetails: // value for 'agreementDetails'
 *      allowCurrentTenancyAgreementToLapse: // value for 'allowCurrentTenancyAgreementToLapse'
 *   },
 * });
 */
export function useReviewLeaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReviewLeaseMutation,
    ReviewLeaseMutationVariables
  >
) {
  return Apollo.useMutation<ReviewLeaseMutation, ReviewLeaseMutationVariables>(
    ReviewLeaseDocument,
    baseOptions
  );
}
export type ReviewLeaseMutationHookResult = ReturnType<
  typeof useReviewLeaseMutation
>;
export type ReviewLeaseMutationResult =
  Apollo.MutationResult<ReviewLeaseMutation>;
export type ReviewLeaseMutationOptions = Apollo.BaseMutationOptions<
  ReviewLeaseMutation,
  ReviewLeaseMutationVariables
>;
export const UpdateTenancyAgreementDocument = gql`
  mutation updateTenancyAgreement($input: UpdateTenancyAgreementInput!) {
    updateTenancyAgreement(input: $input) {
      tenancyAgreement {
        id
        startDate
        fixedTermEndDate
      }
    }
  }
`;
export type UpdateTenancyAgreementMutationFn = Apollo.MutationFunction<
  UpdateTenancyAgreementMutation,
  UpdateTenancyAgreementMutationVariables
>;

/**
 * __useUpdateTenancyAgreementMutation__
 *
 * To run a mutation, you first call `useUpdateTenancyAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenancyAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenancyAgreementMutation, { data, loading, error }] = useUpdateTenancyAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenancyAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTenancyAgreementMutation,
    UpdateTenancyAgreementMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateTenancyAgreementMutation,
    UpdateTenancyAgreementMutationVariables
  >(UpdateTenancyAgreementDocument, baseOptions);
}
export type UpdateTenancyAgreementMutationHookResult = ReturnType<
  typeof useUpdateTenancyAgreementMutation
>;
export type UpdateTenancyAgreementMutationResult =
  Apollo.MutationResult<UpdateTenancyAgreementMutation>;
export type UpdateTenancyAgreementMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenancyAgreementMutation,
  UpdateTenancyAgreementMutationVariables
>;
export const CreateOrUpdateManagementNoteDocument = gql`
  mutation createOrUpdateManagementNote(
    $input: CreateOrUpdateManagementNoteInput!
  ) {
    note: createOrUpdateManagementNote(input: $input) {
      managementNote {
        ...managementNote
      }
    }
  }
  ${ManagementNoteFragmentDoc}
`;
export type CreateOrUpdateManagementNoteMutationFn = Apollo.MutationFunction<
  CreateOrUpdateManagementNoteMutation,
  CreateOrUpdateManagementNoteMutationVariables
>;

/**
 * __useCreateOrUpdateManagementNoteMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateManagementNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateManagementNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateManagementNoteMutation, { data, loading, error }] = useCreateOrUpdateManagementNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateManagementNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateManagementNoteMutation,
    CreateOrUpdateManagementNoteMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateOrUpdateManagementNoteMutation,
    CreateOrUpdateManagementNoteMutationVariables
  >(CreateOrUpdateManagementNoteDocument, baseOptions);
}
export type CreateOrUpdateManagementNoteMutationHookResult = ReturnType<
  typeof useCreateOrUpdateManagementNoteMutation
>;
export type CreateOrUpdateManagementNoteMutationResult =
  Apollo.MutationResult<CreateOrUpdateManagementNoteMutation>;
export type CreateOrUpdateManagementNoteMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrUpdateManagementNoteMutation,
    CreateOrUpdateManagementNoteMutationVariables
  >;
export const GetManagementInfoDocument = gql`
  query getManagementInfo($managementId: ID!) {
    management(managementId: $managementId) {
      id
      currentTenancy {
        ...FormSidebarTenancyDetails
      }
    }
  }
  ${FormSidebarTenancyDetailsFragmentDoc}
`;

/**
 * __useGetManagementInfoQuery__
 *
 * To run a query within a React component, call `useGetManagementInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementInfoQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetManagementInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementInfoQuery,
    GetManagementInfoQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementInfoQuery,
    GetManagementInfoQueryVariables
  >(GetManagementInfoDocument, baseOptions);
}
export function useGetManagementInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementInfoQuery,
    GetManagementInfoQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementInfoQuery,
    GetManagementInfoQueryVariables
  >(GetManagementInfoDocument, baseOptions);
}
export type GetManagementInfoQueryHookResult = ReturnType<
  typeof useGetManagementInfoQuery
>;
export type GetManagementInfoLazyQueryHookResult = ReturnType<
  typeof useGetManagementInfoLazyQuery
>;
export type GetManagementInfoQueryResult = Apollo.QueryResult<
  GetManagementInfoQuery,
  GetManagementInfoQueryVariables
>;
export const GetProjectFormSidebarDetailsDocument = gql`
  query getProjectFormSidebarDetails($projectId: ID!) {
    project(id: $projectId) {
      id
      ...ProjectDetails
    }
  }
  ${ProjectDetailsFragmentDoc}
`;

/**
 * __useGetProjectFormSidebarDetailsQuery__
 *
 * To run a query within a React component, call `useGetProjectFormSidebarDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectFormSidebarDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectFormSidebarDetailsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectFormSidebarDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectFormSidebarDetailsQuery,
    GetProjectFormSidebarDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetProjectFormSidebarDetailsQuery,
    GetProjectFormSidebarDetailsQueryVariables
  >(GetProjectFormSidebarDetailsDocument, baseOptions);
}
export function useGetProjectFormSidebarDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectFormSidebarDetailsQuery,
    GetProjectFormSidebarDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetProjectFormSidebarDetailsQuery,
    GetProjectFormSidebarDetailsQueryVariables
  >(GetProjectFormSidebarDetailsDocument, baseOptions);
}
export type GetProjectFormSidebarDetailsQueryHookResult = ReturnType<
  typeof useGetProjectFormSidebarDetailsQuery
>;
export type GetProjectFormSidebarDetailsLazyQueryHookResult = ReturnType<
  typeof useGetProjectFormSidebarDetailsLazyQuery
>;
export type GetProjectFormSidebarDetailsQueryResult = Apollo.QueryResult<
  GetProjectFormSidebarDetailsQuery,
  GetProjectFormSidebarDetailsQueryVariables
>;
export const CanCreateProjectOfTypeDocument = gql`
  query CanCreateProjectOfType(
    $managementAilorn: AiloRN!
    $type: ProjectType!
  ) {
    canCreateProjectOfType(managementAilorn: $managementAilorn, type: $type) {
      ableToCreate
      notAbleToCreateReason
    }
  }
`;

/**
 * __useCanCreateProjectOfTypeQuery__
 *
 * To run a query within a React component, call `useCanCreateProjectOfTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanCreateProjectOfTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanCreateProjectOfTypeQuery({
 *   variables: {
 *      managementAilorn: // value for 'managementAilorn'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCanCreateProjectOfTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    CanCreateProjectOfTypeQuery,
    CanCreateProjectOfTypeQueryVariables
  >
) {
  return Apollo.useQuery<
    CanCreateProjectOfTypeQuery,
    CanCreateProjectOfTypeQueryVariables
  >(CanCreateProjectOfTypeDocument, baseOptions);
}
export function useCanCreateProjectOfTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CanCreateProjectOfTypeQuery,
    CanCreateProjectOfTypeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CanCreateProjectOfTypeQuery,
    CanCreateProjectOfTypeQueryVariables
  >(CanCreateProjectOfTypeDocument, baseOptions);
}
export type CanCreateProjectOfTypeQueryHookResult = ReturnType<
  typeof useCanCreateProjectOfTypeQuery
>;
export type CanCreateProjectOfTypeLazyQueryHookResult = ReturnType<
  typeof useCanCreateProjectOfTypeLazyQuery
>;
export type CanCreateProjectOfTypeQueryResult = Apollo.QueryResult<
  CanCreateProjectOfTypeQuery,
  CanCreateProjectOfTypeQueryVariables
>;
export const CreateProjectDocument = gql`
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      type
    }
  }
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
  >(CreateProjectDocument, baseOptions);
}
export type CreateProjectMutationHookResult = ReturnType<
  typeof useCreateProjectMutation
>;
export type CreateProjectMutationResult =
  Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation updateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      ...ProjectDetails
    }
  }
  ${ProjectDetailsFragmentDoc}
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >(UpdateProjectDocument, baseOptions);
}
export type UpdateProjectMutationHookResult = ReturnType<
  typeof useUpdateProjectMutation
>;
export type UpdateProjectMutationResult =
  Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const ProRataSchedulesForNewRentDocument = gql`
  query proRataSchedulesForNewRent(
    $tenancyId: ID!
    $proposedRent: ProposedRent!
  ) {
    tenancy(tenancyId: $tenancyId) {
      id
      proRataSchedulesForNewRent(proposedRent: $proposedRent) {
        ...proRataPreview
      }
    }
  }
  ${ProRataPreviewFragmentDoc}
`;

/**
 * __useProRataSchedulesForNewRentQuery__
 *
 * To run a query within a React component, call `useProRataSchedulesForNewRentQuery` and pass it any options that fit your needs.
 * When your component renders, `useProRataSchedulesForNewRentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProRataSchedulesForNewRentQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *      proposedRent: // value for 'proposedRent'
 *   },
 * });
 */
export function useProRataSchedulesForNewRentQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProRataSchedulesForNewRentQuery,
    ProRataSchedulesForNewRentQueryVariables
  >
) {
  return Apollo.useQuery<
    ProRataSchedulesForNewRentQuery,
    ProRataSchedulesForNewRentQueryVariables
  >(ProRataSchedulesForNewRentDocument, baseOptions);
}
export function useProRataSchedulesForNewRentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProRataSchedulesForNewRentQuery,
    ProRataSchedulesForNewRentQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ProRataSchedulesForNewRentQuery,
    ProRataSchedulesForNewRentQueryVariables
  >(ProRataSchedulesForNewRentDocument, baseOptions);
}
export type ProRataSchedulesForNewRentQueryHookResult = ReturnType<
  typeof useProRataSchedulesForNewRentQuery
>;
export type ProRataSchedulesForNewRentLazyQueryHookResult = ReturnType<
  typeof useProRataSchedulesForNewRentLazyQuery
>;
export type ProRataSchedulesForNewRentQueryResult = Apollo.QueryResult<
  ProRataSchedulesForNewRentQuery,
  ProRataSchedulesForNewRentQueryVariables
>;
export const ProRataSchedulesForRentUpdateDocument = gql`
  query proRataSchedulesForRentUpdate(
    $rentAilorn: AiloRN!
    $proposedRent: ProposedRent!
  ) {
    rent(id: $rentAilorn) {
      id
      proRataSchedulesForRentUpdate(proposedRent: $proposedRent) {
        ...proRataPreview
      }
    }
  }
  ${ProRataPreviewFragmentDoc}
`;

/**
 * __useProRataSchedulesForRentUpdateQuery__
 *
 * To run a query within a React component, call `useProRataSchedulesForRentUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useProRataSchedulesForRentUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProRataSchedulesForRentUpdateQuery({
 *   variables: {
 *      rentAilorn: // value for 'rentAilorn'
 *      proposedRent: // value for 'proposedRent'
 *   },
 * });
 */
export function useProRataSchedulesForRentUpdateQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProRataSchedulesForRentUpdateQuery,
    ProRataSchedulesForRentUpdateQueryVariables
  >
) {
  return Apollo.useQuery<
    ProRataSchedulesForRentUpdateQuery,
    ProRataSchedulesForRentUpdateQueryVariables
  >(ProRataSchedulesForRentUpdateDocument, baseOptions);
}
export function useProRataSchedulesForRentUpdateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProRataSchedulesForRentUpdateQuery,
    ProRataSchedulesForRentUpdateQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ProRataSchedulesForRentUpdateQuery,
    ProRataSchedulesForRentUpdateQueryVariables
  >(ProRataSchedulesForRentUpdateDocument, baseOptions);
}
export type ProRataSchedulesForRentUpdateQueryHookResult = ReturnType<
  typeof useProRataSchedulesForRentUpdateQuery
>;
export type ProRataSchedulesForRentUpdateLazyQueryHookResult = ReturnType<
  typeof useProRataSchedulesForRentUpdateLazyQuery
>;
export type ProRataSchedulesForRentUpdateQueryResult = Apollo.QueryResult<
  ProRataSchedulesForRentUpdateQuery,
  ProRataSchedulesForRentUpdateQueryVariables
>;
export const CreateRentReviewDocument = gql`
  mutation createRentReview($tenancyId: ID!, $rentDetails: RentReviewDetails!) {
    createRentReview(tenancyId: $tenancyId, rentDetails: $rentDetails) {
      rent {
        ...Rent
      }
      tenancy {
        id
        ...upcomingRentChanges
        rentReviewAllowed
        editableRentReview {
          rent {
            ...EditableRent
          }
        }
      }
    }
  }
  ${RentFragmentDoc}
  ${UpcomingRentChangesFragmentDoc}
  ${EditableRentFragmentDoc}
`;
export type CreateRentReviewMutationFn = Apollo.MutationFunction<
  CreateRentReviewMutation,
  CreateRentReviewMutationVariables
>;

/**
 * __useCreateRentReviewMutation__
 *
 * To run a mutation, you first call `useCreateRentReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRentReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRentReviewMutation, { data, loading, error }] = useCreateRentReviewMutation({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *      rentDetails: // value for 'rentDetails'
 *   },
 * });
 */
export function useCreateRentReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRentReviewMutation,
    CreateRentReviewMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateRentReviewMutation,
    CreateRentReviewMutationVariables
  >(CreateRentReviewDocument, baseOptions);
}
export type CreateRentReviewMutationHookResult = ReturnType<
  typeof useCreateRentReviewMutation
>;
export type CreateRentReviewMutationResult =
  Apollo.MutationResult<CreateRentReviewMutation>;
export type CreateRentReviewMutationOptions = Apollo.BaseMutationOptions<
  CreateRentReviewMutation,
  CreateRentReviewMutationVariables
>;
export const EditRentReviewDocument = gql`
  mutation editRentReview($input: EditRentReviewInput!) {
    editRentReview(input: $input) {
      rent {
        ...Rent
      }
      tenancy {
        id
        ...upcomingRentChanges
        rentReviewAllowed
        editableRentReview {
          rent {
            ...EditableRent
          }
        }
      }
    }
  }
  ${RentFragmentDoc}
  ${UpcomingRentChangesFragmentDoc}
  ${EditableRentFragmentDoc}
`;
export type EditRentReviewMutationFn = Apollo.MutationFunction<
  EditRentReviewMutation,
  EditRentReviewMutationVariables
>;

/**
 * __useEditRentReviewMutation__
 *
 * To run a mutation, you first call `useEditRentReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRentReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRentReviewMutation, { data, loading, error }] = useEditRentReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRentReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditRentReviewMutation,
    EditRentReviewMutationVariables
  >
) {
  return Apollo.useMutation<
    EditRentReviewMutation,
    EditRentReviewMutationVariables
  >(EditRentReviewDocument, baseOptions);
}
export type EditRentReviewMutationHookResult = ReturnType<
  typeof useEditRentReviewMutation
>;
export type EditRentReviewMutationResult =
  Apollo.MutationResult<EditRentReviewMutation>;
export type EditRentReviewMutationOptions = Apollo.BaseMutationOptions<
  EditRentReviewMutation,
  EditRentReviewMutationVariables
>;
export const GetRentReviewModalDataDocument = gql`
  query getRentReviewModalData($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      endDate
      liability {
        id
        nextChargeCreateDate
      }
      mostRecentRentReview {
        ...Rent
        rentIncreaseInCents
      }
      leaseReviewAllowedOperation
      currentRent {
        ...Rent
      }
      rents(cursor: { pageSize: 1 }) {
        pageInfo {
          total
        }
        items {
          ...Rent
        }
      }
      scheduledRentReviewDate
    }
  }
  ${RentFragmentDoc}
`;

/**
 * __useGetRentReviewModalDataQuery__
 *
 * To run a query within a React component, call `useGetRentReviewModalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRentReviewModalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRentReviewModalDataQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetRentReviewModalDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRentReviewModalDataQuery,
    GetRentReviewModalDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetRentReviewModalDataQuery,
    GetRentReviewModalDataQueryVariables
  >(GetRentReviewModalDataDocument, baseOptions);
}
export function useGetRentReviewModalDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRentReviewModalDataQuery,
    GetRentReviewModalDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetRentReviewModalDataQuery,
    GetRentReviewModalDataQueryVariables
  >(GetRentReviewModalDataDocument, baseOptions);
}
export type GetRentReviewModalDataQueryHookResult = ReturnType<
  typeof useGetRentReviewModalDataQuery
>;
export type GetRentReviewModalDataLazyQueryHookResult = ReturnType<
  typeof useGetRentReviewModalDataLazyQuery
>;
export type GetRentReviewModalDataQueryResult = Apollo.QueryResult<
  GetRentReviewModalDataQuery,
  GetRentReviewModalDataQueryVariables
>;
export const SetRoutineInspectionDueDateDocument = gql`
  mutation setRoutineInspectionDueDate(
    $input: SetRoutineInspectionDueDateInput!
  ) {
    setRoutineInspectionDueDate(input: $input) {
      tenancy {
        ...TenancyInspectionSchedule
      }
    }
  }
  ${TenancyInspectionScheduleFragmentDoc}
`;
export type SetRoutineInspectionDueDateMutationFn = Apollo.MutationFunction<
  SetRoutineInspectionDueDateMutation,
  SetRoutineInspectionDueDateMutationVariables
>;

/**
 * __useSetRoutineInspectionDueDateMutation__
 *
 * To run a mutation, you first call `useSetRoutineInspectionDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRoutineInspectionDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRoutineInspectionDueDateMutation, { data, loading, error }] = useSetRoutineInspectionDueDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetRoutineInspectionDueDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetRoutineInspectionDueDateMutation,
    SetRoutineInspectionDueDateMutationVariables
  >
) {
  return Apollo.useMutation<
    SetRoutineInspectionDueDateMutation,
    SetRoutineInspectionDueDateMutationVariables
  >(SetRoutineInspectionDueDateDocument, baseOptions);
}
export type SetRoutineInspectionDueDateMutationHookResult = ReturnType<
  typeof useSetRoutineInspectionDueDateMutation
>;
export type SetRoutineInspectionDueDateMutationResult =
  Apollo.MutationResult<SetRoutineInspectionDueDateMutation>;
export type SetRoutineInspectionDueDateMutationOptions =
  Apollo.BaseMutationOptions<
    SetRoutineInspectionDueDateMutation,
    SetRoutineInspectionDueDateMutationVariables
  >;
export const GetNewTenancyFileDocument = gql`
  query getNewTenancyFile($id: AiloRN!) {
    file(id: $id) {
      id
      fileName
      fileSize
      url
      contentType
    }
  }
`;

/**
 * __useGetNewTenancyFileQuery__
 *
 * To run a query within a React component, call `useGetNewTenancyFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewTenancyFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewTenancyFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNewTenancyFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNewTenancyFileQuery,
    GetNewTenancyFileQueryVariables
  >
) {
  return Apollo.useQuery<
    GetNewTenancyFileQuery,
    GetNewTenancyFileQueryVariables
  >(GetNewTenancyFileDocument, baseOptions);
}
export function useGetNewTenancyFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNewTenancyFileQuery,
    GetNewTenancyFileQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetNewTenancyFileQuery,
    GetNewTenancyFileQueryVariables
  >(GetNewTenancyFileDocument, baseOptions);
}
export type GetNewTenancyFileQueryHookResult = ReturnType<
  typeof useGetNewTenancyFileQuery
>;
export type GetNewTenancyFileLazyQueryHookResult = ReturnType<
  typeof useGetNewTenancyFileLazyQuery
>;
export type GetNewTenancyFileQueryResult = Apollo.QueryResult<
  GetNewTenancyFileQuery,
  GetNewTenancyFileQueryVariables
>;
export const GetMostRecentTenancyEndDocument = gql`
  query getMostRecentTenancyEnd($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        endDate
      }
    }
  }
`;

/**
 * __useGetMostRecentTenancyEndQuery__
 *
 * To run a query within a React component, call `useGetMostRecentTenancyEndQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentTenancyEndQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentTenancyEndQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetMostRecentTenancyEndQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMostRecentTenancyEndQuery,
    GetMostRecentTenancyEndQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMostRecentTenancyEndQuery,
    GetMostRecentTenancyEndQueryVariables
  >(GetMostRecentTenancyEndDocument, baseOptions);
}
export function useGetMostRecentTenancyEndLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMostRecentTenancyEndQuery,
    GetMostRecentTenancyEndQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMostRecentTenancyEndQuery,
    GetMostRecentTenancyEndQueryVariables
  >(GetMostRecentTenancyEndDocument, baseOptions);
}
export type GetMostRecentTenancyEndQueryHookResult = ReturnType<
  typeof useGetMostRecentTenancyEndQuery
>;
export type GetMostRecentTenancyEndLazyQueryHookResult = ReturnType<
  typeof useGetMostRecentTenancyEndLazyQuery
>;
export type GetMostRecentTenancyEndQueryResult = Apollo.QueryResult<
  GetMostRecentTenancyEndQuery,
  GetMostRecentTenancyEndQueryVariables
>;
export const GetTenancyDetailsForEditDocument = gql`
  query getTenancyDetailsForEdit($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      startDate
      endDate
      scheduledRentReviewDate
      nextTenancyAgreement {
        id
        startDate
        fixedTermEndDate
      }
      displayRent {
        id
        amountInCents
        period
        effectiveDate
      }
      management {
        id
        mostRecentTenancy {
          id
          endDate
        }
      }
    }
  }
`;

/**
 * __useGetTenancyDetailsForEditQuery__
 *
 * To run a query within a React component, call `useGetTenancyDetailsForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenancyDetailsForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenancyDetailsForEditQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetTenancyDetailsForEditQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenancyDetailsForEditQuery,
    GetTenancyDetailsForEditQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenancyDetailsForEditQuery,
    GetTenancyDetailsForEditQueryVariables
  >(GetTenancyDetailsForEditDocument, baseOptions);
}
export function useGetTenancyDetailsForEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenancyDetailsForEditQuery,
    GetTenancyDetailsForEditQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenancyDetailsForEditQuery,
    GetTenancyDetailsForEditQueryVariables
  >(GetTenancyDetailsForEditDocument, baseOptions);
}
export type GetTenancyDetailsForEditQueryHookResult = ReturnType<
  typeof useGetTenancyDetailsForEditQuery
>;
export type GetTenancyDetailsForEditLazyQueryHookResult = ReturnType<
  typeof useGetTenancyDetailsForEditLazyQuery
>;
export type GetTenancyDetailsForEditQueryResult = Apollo.QueryResult<
  GetTenancyDetailsForEditQuery,
  GetTenancyDetailsForEditQueryVariables
>;
export const CreateTenancyDocument = gql`
  mutation createTenancy(
    $tenancyDetails: TenancySetupInput!
    $managementId: ID!
  ) {
    createTenancy(
      tenancyDetails: $tenancyDetails
      managementId: $managementId
      autoPublish: true
      doNotNotify: false
    ) {
      id
      ...Tenancy
      management {
        id
        endAbility {
          canBeEnded
          problem
        }
        currentTenancy {
          ...CanCancelVacate
        }
        mostRecentTenancy {
          id
        }
        nextTenancy {
          id
        }
      }
      tenantships(pageCursor: { pageSize: 3 }) {
        pageInfo {
          total
        }
        items {
          tenant {
            __typename
            id
            ... on Person {
              firstName
              lastName
              photo {
                id
                url
                thumbnailUrl
              }
            }
            ... on Company {
              registeredEntityName
            }
          }
        }
      }
    }
  }
  ${TenancyFragmentDoc}
  ${CanCancelVacateFragmentDoc}
`;
export type CreateTenancyMutationFn = Apollo.MutationFunction<
  CreateTenancyMutation,
  CreateTenancyMutationVariables
>;

/**
 * __useCreateTenancyMutation__
 *
 * To run a mutation, you first call `useCreateTenancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenancyMutation, { data, loading, error }] = useCreateTenancyMutation({
 *   variables: {
 *      tenancyDetails: // value for 'tenancyDetails'
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useCreateTenancyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTenancyMutation,
    CreateTenancyMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateTenancyMutation,
    CreateTenancyMutationVariables
  >(CreateTenancyDocument, baseOptions);
}
export type CreateTenancyMutationHookResult = ReturnType<
  typeof useCreateTenancyMutation
>;
export type CreateTenancyMutationResult =
  Apollo.MutationResult<CreateTenancyMutation>;
export type CreateTenancyMutationOptions = Apollo.BaseMutationOptions<
  CreateTenancyMutation,
  CreateTenancyMutationVariables
>;
export const UpdateIngoingTenancyDocument = gql`
  mutation updateIngoingTenancy($tenancyDetails: IngoingTenancyUpdateInput!) {
    updateIngoingTenancy(tenancyDetails: $tenancyDetails) {
      id
      ...Tenancy
      rents(cursor: { pageSize: 1 }) {
        items {
          ...Rent
        }
      }
      scheduledRentReviewDate
      nextTenancyAgreement {
        id
        startDate
        fixedTermEndDate
      }
      nextRentSchedule {
        id
        amountInCents
        period
        startDate
      }
    }
  }
  ${TenancyFragmentDoc}
  ${RentFragmentDoc}
`;
export type UpdateIngoingTenancyMutationFn = Apollo.MutationFunction<
  UpdateIngoingTenancyMutation,
  UpdateIngoingTenancyMutationVariables
>;

/**
 * __useUpdateIngoingTenancyMutation__
 *
 * To run a mutation, you first call `useUpdateIngoingTenancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIngoingTenancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIngoingTenancyMutation, { data, loading, error }] = useUpdateIngoingTenancyMutation({
 *   variables: {
 *      tenancyDetails: // value for 'tenancyDetails'
 *   },
 * });
 */
export function useUpdateIngoingTenancyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIngoingTenancyMutation,
    UpdateIngoingTenancyMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateIngoingTenancyMutation,
    UpdateIngoingTenancyMutationVariables
  >(UpdateIngoingTenancyDocument, baseOptions);
}
export type UpdateIngoingTenancyMutationHookResult = ReturnType<
  typeof useUpdateIngoingTenancyMutation
>;
export type UpdateIngoingTenancyMutationResult =
  Apollo.MutationResult<UpdateIngoingTenancyMutation>;
export type UpdateIngoingTenancyMutationOptions = Apollo.BaseMutationOptions<
  UpdateIngoingTenancyMutation,
  UpdateIngoingTenancyMutationVariables
>;
export const GetIngoingTenancyIsVoidableDocument = gql`
  query getIngoingTenancyIsVoidable($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      ...TenancyIsVoidable
    }
  }
  ${TenancyIsVoidableFragmentDoc}
`;

/**
 * __useGetIngoingTenancyIsVoidableQuery__
 *
 * To run a query within a React component, call `useGetIngoingTenancyIsVoidableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIngoingTenancyIsVoidableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIngoingTenancyIsVoidableQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetIngoingTenancyIsVoidableQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIngoingTenancyIsVoidableQuery,
    GetIngoingTenancyIsVoidableQueryVariables
  >
) {
  return Apollo.useQuery<
    GetIngoingTenancyIsVoidableQuery,
    GetIngoingTenancyIsVoidableQueryVariables
  >(GetIngoingTenancyIsVoidableDocument, baseOptions);
}
export function useGetIngoingTenancyIsVoidableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIngoingTenancyIsVoidableQuery,
    GetIngoingTenancyIsVoidableQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetIngoingTenancyIsVoidableQuery,
    GetIngoingTenancyIsVoidableQueryVariables
  >(GetIngoingTenancyIsVoidableDocument, baseOptions);
}
export type GetIngoingTenancyIsVoidableQueryHookResult = ReturnType<
  typeof useGetIngoingTenancyIsVoidableQuery
>;
export type GetIngoingTenancyIsVoidableLazyQueryHookResult = ReturnType<
  typeof useGetIngoingTenancyIsVoidableLazyQuery
>;
export type GetIngoingTenancyIsVoidableQueryResult = Apollo.QueryResult<
  GetIngoingTenancyIsVoidableQuery,
  GetIngoingTenancyIsVoidableQueryVariables
>;
export const VoidTenancyDocument = gql`
  mutation voidTenancy($tenancyId: ID!) {
    voidTenancy(tenancyId: $tenancyId) {
      tenancy {
        ...TenancyVoidedAt
        ...TenancyIsVoidable
        management {
          id
          nextTenancy {
            id
          }
          endAbility {
            canBeEnded
            problem
          }
          mostRecentTenancy {
            ...CanCancelVacate
          }
          voidedTenancies(pageCursor: { pageSize: 20 }) {
            items {
              ...FormerOrVoidedTenancy
            }
          }
        }
      }
    }
  }
  ${TenancyVoidedAtFragmentDoc}
  ${TenancyIsVoidableFragmentDoc}
  ${CanCancelVacateFragmentDoc}
  ${FormerOrVoidedTenancyFragmentDoc}
`;
export type VoidTenancyMutationFn = Apollo.MutationFunction<
  VoidTenancyMutation,
  VoidTenancyMutationVariables
>;

/**
 * __useVoidTenancyMutation__
 *
 * To run a mutation, you first call `useVoidTenancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidTenancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidTenancyMutation, { data, loading, error }] = useVoidTenancyMutation({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useVoidTenancyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VoidTenancyMutation,
    VoidTenancyMutationVariables
  >
) {
  return Apollo.useMutation<VoidTenancyMutation, VoidTenancyMutationVariables>(
    VoidTenancyDocument,
    baseOptions
  );
}
export type VoidTenancyMutationHookResult = ReturnType<
  typeof useVoidTenancyMutation
>;
export type VoidTenancyMutationResult =
  Apollo.MutationResult<VoidTenancyMutation>;
export type VoidTenancyMutationOptions = Apollo.BaseMutationOptions<
  VoidTenancyMutation,
  VoidTenancyMutationVariables
>;
export const GetPropertyDetailsForTenancyDocument = gql`
  query getPropertyDetailsForTenancy($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      ...FormSidebarTenancyDetails
    }
  }
  ${FormSidebarTenancyDetailsFragmentDoc}
`;

/**
 * __useGetPropertyDetailsForTenancyQuery__
 *
 * To run a query within a React component, call `useGetPropertyDetailsForTenancyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyDetailsForTenancyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyDetailsForTenancyQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetPropertyDetailsForTenancyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyDetailsForTenancyQuery,
    GetPropertyDetailsForTenancyQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertyDetailsForTenancyQuery,
    GetPropertyDetailsForTenancyQueryVariables
  >(GetPropertyDetailsForTenancyDocument, baseOptions);
}
export function useGetPropertyDetailsForTenancyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyDetailsForTenancyQuery,
    GetPropertyDetailsForTenancyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertyDetailsForTenancyQuery,
    GetPropertyDetailsForTenancyQueryVariables
  >(GetPropertyDetailsForTenancyDocument, baseOptions);
}
export type GetPropertyDetailsForTenancyQueryHookResult = ReturnType<
  typeof useGetPropertyDetailsForTenancyQuery
>;
export type GetPropertyDetailsForTenancyLazyQueryHookResult = ReturnType<
  typeof useGetPropertyDetailsForTenancyLazyQuery
>;
export type GetPropertyDetailsForTenancyQueryResult = Apollo.QueryResult<
  GetPropertyDetailsForTenancyQuery,
  GetPropertyDetailsForTenancyQueryVariables
>;
export const GetDashboardCardDetailsDocument = gql`
  query getDashboardCardDetails(
    $name: String!
    $managingEntityId: ID!
    $teamId: [ID!]
    $includeUnassigned: Boolean
  ) {
    dashboardFigureByName(
      dashboardFigureName: $name
      agencyId: $managingEntityId
      teams: $teamId
      includeUnassigned: $includeUnassigned
    ) {
      type
      data {
        ... on DashboardFigureTeamPeriodComparison {
          currentTotal
          teams {
            currentValue
            teamName
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetDashboardCardDetailsQuery__
 *
 * To run a query within a React component, call `useGetDashboardCardDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardCardDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardCardDetailsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      managingEntityId: // value for 'managingEntityId'
 *      teamId: // value for 'teamId'
 *      includeUnassigned: // value for 'includeUnassigned'
 *   },
 * });
 */
export function useGetDashboardCardDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDashboardCardDetailsQuery,
    GetDashboardCardDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetDashboardCardDetailsQuery,
    GetDashboardCardDetailsQueryVariables
  >(GetDashboardCardDetailsDocument, baseOptions);
}
export function useGetDashboardCardDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardCardDetailsQuery,
    GetDashboardCardDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetDashboardCardDetailsQuery,
    GetDashboardCardDetailsQueryVariables
  >(GetDashboardCardDetailsDocument, baseOptions);
}
export type GetDashboardCardDetailsQueryHookResult = ReturnType<
  typeof useGetDashboardCardDetailsQuery
>;
export type GetDashboardCardDetailsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardCardDetailsLazyQuery
>;
export type GetDashboardCardDetailsQueryResult = Apollo.QueryResult<
  GetDashboardCardDetailsQuery,
  GetDashboardCardDetailsQueryVariables
>;
export const GetBillByIdDocument = gql`
  query getBillById($billId: ID!) {
    billById(billId: $billId) {
      ailoRN
      dueDateV2
      supplier {
        ailoRN
        name
        internalLegalEntity {
          ...legalEntityCompanionOrganisationFragment
        }
      }
      liabilityState {
        paymentStatus
        payByDateV2
      }
      status
      agencyStatus
      payer {
        ...billPayerNames
      }
      amount {
        cents
      }
      management {
        id
        ...ManagementProperty
      }
    }
  }
  ${LegalEntityCompanionOrganisationFragmentFragmentDoc}
  ${BillPayerNamesFragmentDoc}
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useGetBillByIdQuery__
 *
 * To run a query within a React component, call `useGetBillByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillByIdQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useGetBillByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBillByIdQuery,
    GetBillByIdQueryVariables
  >
) {
  return Apollo.useQuery<GetBillByIdQuery, GetBillByIdQueryVariables>(
    GetBillByIdDocument,
    baseOptions
  );
}
export function useGetBillByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillByIdQuery,
    GetBillByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetBillByIdQuery, GetBillByIdQueryVariables>(
    GetBillByIdDocument,
    baseOptions
  );
}
export type GetBillByIdQueryHookResult = ReturnType<typeof useGetBillByIdQuery>;
export type GetBillByIdLazyQueryHookResult = ReturnType<
  typeof useGetBillByIdLazyQuery
>;
export type GetBillByIdQueryResult = Apollo.QueryResult<
  GetBillByIdQuery,
  GetBillByIdQueryVariables
>;
export const GetBillsDocument = gql`
  query getBills(
    $organisationId: AiloRN!
    $paid: Boolean
    $status: [BillStatus!]
    $dueDate: LocalDateRangeCondition
    $sort: BillSortParams
    $cursor: String
    $pageSize: Int!
    $payerType: [BillPayerType!]
    $team: [AiloRN!]
    $includeUnassigned: Boolean
  ) {
    bills(
      taxCategoryIdNotIn: ["MANAGEMENT_FEES"]
      organisationId: [$organisationId]
      paid: $paid
      payerType: $payerType
      status: $status
      dueDate: $dueDate
      sort: $sort
      cursor: { cursor: $cursor, pageSize: $pageSize }
      team: $team
      includeUnassigned: $includeUnassigned
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
      }
      items {
        ailoRN
        dueDateV2
        supplier {
          ailoRN
          name
          internalLegalEntity {
            ...legalEntityCompanionOrganisationFragment
          }
        }
        liabilityState {
          paymentStatus
          payByDateV2
        }
        status
        agencyStatus
        payer {
          ...billPayerNames
        }
        amount {
          cents
        }
        management {
          id
          ...ManagementProperty
        }
      }
    }
  }
  ${LegalEntityCompanionOrganisationFragmentFragmentDoc}
  ${BillPayerNamesFragmentDoc}
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useGetBillsQuery__
 *
 * To run a query within a React component, call `useGetBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      paid: // value for 'paid'
 *      status: // value for 'status'
 *      dueDate: // value for 'dueDate'
 *      sort: // value for 'sort'
 *      cursor: // value for 'cursor'
 *      pageSize: // value for 'pageSize'
 *      payerType: // value for 'payerType'
 *      team: // value for 'team'
 *      includeUnassigned: // value for 'includeUnassigned'
 *   },
 * });
 */
export function useGetBillsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBillsQuery, GetBillsQueryVariables>
) {
  return Apollo.useQuery<GetBillsQuery, GetBillsQueryVariables>(
    GetBillsDocument,
    baseOptions
  );
}
export function useGetBillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillsQuery,
    GetBillsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetBillsQuery, GetBillsQueryVariables>(
    GetBillsDocument,
    baseOptions
  );
}
export type GetBillsQueryHookResult = ReturnType<typeof useGetBillsQuery>;
export type GetBillsLazyQueryHookResult = ReturnType<
  typeof useGetBillsLazyQuery
>;
export type GetBillsQueryResult = Apollo.QueryResult<
  GetBillsQuery,
  GetBillsQueryVariables
>;
export const GetPagedContactsDocument = gql`
  query getPagedContacts(
    $query: String
    $typeOf: [String!]
    $organisationAilornIn: [AiloRN!]
    $sort: [ContactSortParams!]
    $paginationParams: PaginationParamsWithPageNumber!
  ) {
    searchContacts(
      query: $query
      typeOf: $typeOf
      organisationAilornIn: $organisationAilornIn
      sort: $sort
      paginationParams: $paginationParams
    ) {
      pageInfo {
        total
        hasNext
        hasPrevious
      }
      items {
        id
        ailorn
        user {
          ailoRN
        }
        confidential {
          primaryPhone
        }
        displayName
        primaryEmail
        types
        legalEntity {
          ... on Person {
            id
            firstName
            lastName
            __typename
            phoneNo
          }
          ... on Company {
            id
            registeredEntityId
            registeredEntityName
            __typename
          }
        }
      }
    }
  }
`;

/**
 * __useGetPagedContactsQuery__
 *
 * To run a query within a React component, call `useGetPagedContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedContactsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      typeOf: // value for 'typeOf'
 *      organisationAilornIn: // value for 'organisationAilornIn'
 *      sort: // value for 'sort'
 *      paginationParams: // value for 'paginationParams'
 *   },
 * });
 */
export function useGetPagedContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagedContactsQuery,
    GetPagedContactsQueryVariables
  >
) {
  return Apollo.useQuery<GetPagedContactsQuery, GetPagedContactsQueryVariables>(
    GetPagedContactsDocument,
    baseOptions
  );
}
export function useGetPagedContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagedContactsQuery,
    GetPagedContactsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPagedContactsQuery,
    GetPagedContactsQueryVariables
  >(GetPagedContactsDocument, baseOptions);
}
export type GetPagedContactsQueryHookResult = ReturnType<
  typeof useGetPagedContactsQuery
>;
export type GetPagedContactsLazyQueryHookResult = ReturnType<
  typeof useGetPagedContactsLazyQuery
>;
export type GetPagedContactsQueryResult = Apollo.QueryResult<
  GetPagedContactsQuery,
  GetPagedContactsQueryVariables
>;
export const ApproveMigratingManagementDocument = gql`
  mutation approveMigratingManagement($migratingManagementId: ID!) {
    approveMigratingManagements(
      migratingManagementIds: [$migratingManagementId]
    ) {
      successes {
        id
      }
      errors {
        id
      }
    }
  }
`;
export type ApproveMigratingManagementMutationFn = Apollo.MutationFunction<
  ApproveMigratingManagementMutation,
  ApproveMigratingManagementMutationVariables
>;

/**
 * __useApproveMigratingManagementMutation__
 *
 * To run a mutation, you first call `useApproveMigratingManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveMigratingManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveMigratingManagementMutation, { data, loading, error }] = useApproveMigratingManagementMutation({
 *   variables: {
 *      migratingManagementId: // value for 'migratingManagementId'
 *   },
 * });
 */
export function useApproveMigratingManagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveMigratingManagementMutation,
    ApproveMigratingManagementMutationVariables
  >
) {
  return Apollo.useMutation<
    ApproveMigratingManagementMutation,
    ApproveMigratingManagementMutationVariables
  >(ApproveMigratingManagementDocument, baseOptions);
}
export type ApproveMigratingManagementMutationHookResult = ReturnType<
  typeof useApproveMigratingManagementMutation
>;
export type ApproveMigratingManagementMutationResult =
  Apollo.MutationResult<ApproveMigratingManagementMutation>;
export type ApproveMigratingManagementMutationOptions =
  Apollo.BaseMutationOptions<
    ApproveMigratingManagementMutation,
    ApproveMigratingManagementMutationVariables
  >;
export const ExcludeMigratingManagementDocument = gql`
  mutation excludeMigratingManagement(
    $migratingManagementId: ID!
    $exclusionReason: String!
    $exclusionNotes: String
  ) {
    excludeMigratingManagement(
      migratingManagementId: $migratingManagementId
      exclusionReason: $exclusionReason
      exclusionNotes: $exclusionNotes
    ) {
      id
    }
  }
`;
export type ExcludeMigratingManagementMutationFn = Apollo.MutationFunction<
  ExcludeMigratingManagementMutation,
  ExcludeMigratingManagementMutationVariables
>;

/**
 * __useExcludeMigratingManagementMutation__
 *
 * To run a mutation, you first call `useExcludeMigratingManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcludeMigratingManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excludeMigratingManagementMutation, { data, loading, error }] = useExcludeMigratingManagementMutation({
 *   variables: {
 *      migratingManagementId: // value for 'migratingManagementId'
 *      exclusionReason: // value for 'exclusionReason'
 *      exclusionNotes: // value for 'exclusionNotes'
 *   },
 * });
 */
export function useExcludeMigratingManagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExcludeMigratingManagementMutation,
    ExcludeMigratingManagementMutationVariables
  >
) {
  return Apollo.useMutation<
    ExcludeMigratingManagementMutation,
    ExcludeMigratingManagementMutationVariables
  >(ExcludeMigratingManagementDocument, baseOptions);
}
export type ExcludeMigratingManagementMutationHookResult = ReturnType<
  typeof useExcludeMigratingManagementMutation
>;
export type ExcludeMigratingManagementMutationResult =
  Apollo.MutationResult<ExcludeMigratingManagementMutation>;
export type ExcludeMigratingManagementMutationOptions =
  Apollo.BaseMutationOptions<
    ExcludeMigratingManagementMutation,
    ExcludeMigratingManagementMutationVariables
  >;
export const GetMigratingManagementSidebarDocument = gql`
  query getMigratingManagementSidebar($migratingManagementId: ID!) {
    migratingManagement(id: $migratingManagementId) {
      ...MigratingManagementSidebar
    }
  }
  ${MigratingManagementSidebarFragmentDoc}
`;

/**
 * __useGetMigratingManagementSidebarQuery__
 *
 * To run a query within a React component, call `useGetMigratingManagementSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMigratingManagementSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMigratingManagementSidebarQuery({
 *   variables: {
 *      migratingManagementId: // value for 'migratingManagementId'
 *   },
 * });
 */
export function useGetMigratingManagementSidebarQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMigratingManagementSidebarQuery,
    GetMigratingManagementSidebarQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMigratingManagementSidebarQuery,
    GetMigratingManagementSidebarQueryVariables
  >(GetMigratingManagementSidebarDocument, baseOptions);
}
export function useGetMigratingManagementSidebarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMigratingManagementSidebarQuery,
    GetMigratingManagementSidebarQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMigratingManagementSidebarQuery,
    GetMigratingManagementSidebarQueryVariables
  >(GetMigratingManagementSidebarDocument, baseOptions);
}
export type GetMigratingManagementSidebarQueryHookResult = ReturnType<
  typeof useGetMigratingManagementSidebarQuery
>;
export type GetMigratingManagementSidebarLazyQueryHookResult = ReturnType<
  typeof useGetMigratingManagementSidebarLazyQuery
>;
export type GetMigratingManagementSidebarQueryResult = Apollo.QueryResult<
  GetMigratingManagementSidebarQuery,
  GetMigratingManagementSidebarQueryVariables
>;
export const IncludeMigratingManagementDocument = gql`
  mutation includeMigratingManagement($migratingManagementId: ID!) {
    includeMigratingManagements(
      migratingManagementIds: [$migratingManagementId]
    ) {
      successes {
        id
      }
      errors {
        id
      }
    }
  }
`;
export type IncludeMigratingManagementMutationFn = Apollo.MutationFunction<
  IncludeMigratingManagementMutation,
  IncludeMigratingManagementMutationVariables
>;

/**
 * __useIncludeMigratingManagementMutation__
 *
 * To run a mutation, you first call `useIncludeMigratingManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncludeMigratingManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [includeMigratingManagementMutation, { data, loading, error }] = useIncludeMigratingManagementMutation({
 *   variables: {
 *      migratingManagementId: // value for 'migratingManagementId'
 *   },
 * });
 */
export function useIncludeMigratingManagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncludeMigratingManagementMutation,
    IncludeMigratingManagementMutationVariables
  >
) {
  return Apollo.useMutation<
    IncludeMigratingManagementMutation,
    IncludeMigratingManagementMutationVariables
  >(IncludeMigratingManagementDocument, baseOptions);
}
export type IncludeMigratingManagementMutationHookResult = ReturnType<
  typeof useIncludeMigratingManagementMutation
>;
export type IncludeMigratingManagementMutationResult =
  Apollo.MutationResult<IncludeMigratingManagementMutation>;
export type IncludeMigratingManagementMutationOptions =
  Apollo.BaseMutationOptions<
    IncludeMigratingManagementMutation,
    IncludeMigratingManagementMutationVariables
  >;
export const PublishMigratingManagementDocument = gql`
  mutation publishMigratingManagement($migratingManagementId: ID!) {
    publishMigratingManagements(
      migratingManagementIds: [$migratingManagementId]
    ) {
      successes {
        id
        body
      }
      errors {
        id
      }
    }
  }
`;
export type PublishMigratingManagementMutationFn = Apollo.MutationFunction<
  PublishMigratingManagementMutation,
  PublishMigratingManagementMutationVariables
>;

/**
 * __usePublishMigratingManagementMutation__
 *
 * To run a mutation, you first call `usePublishMigratingManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishMigratingManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishMigratingManagementMutation, { data, loading, error }] = usePublishMigratingManagementMutation({
 *   variables: {
 *      migratingManagementId: // value for 'migratingManagementId'
 *   },
 * });
 */
export function usePublishMigratingManagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishMigratingManagementMutation,
    PublishMigratingManagementMutationVariables
  >
) {
  return Apollo.useMutation<
    PublishMigratingManagementMutation,
    PublishMigratingManagementMutationVariables
  >(PublishMigratingManagementDocument, baseOptions);
}
export type PublishMigratingManagementMutationHookResult = ReturnType<
  typeof usePublishMigratingManagementMutation
>;
export type PublishMigratingManagementMutationResult =
  Apollo.MutationResult<PublishMigratingManagementMutation>;
export type PublishMigratingManagementMutationOptions =
  Apollo.BaseMutationOptions<
    PublishMigratingManagementMutation,
    PublishMigratingManagementMutationVariables
  >;
export const UnapproveMigratingManagementDocument = gql`
  mutation unapproveMigratingManagement($migratingManagementId: ID!) {
    unapproveMigratingManagements(
      migratingManagementIds: [$migratingManagementId]
    ) {
      successes {
        id
      }
      errors {
        id
      }
    }
  }
`;
export type UnapproveMigratingManagementMutationFn = Apollo.MutationFunction<
  UnapproveMigratingManagementMutation,
  UnapproveMigratingManagementMutationVariables
>;

/**
 * __useUnapproveMigratingManagementMutation__
 *
 * To run a mutation, you first call `useUnapproveMigratingManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnapproveMigratingManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unapproveMigratingManagementMutation, { data, loading, error }] = useUnapproveMigratingManagementMutation({
 *   variables: {
 *      migratingManagementId: // value for 'migratingManagementId'
 *   },
 * });
 */
export function useUnapproveMigratingManagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnapproveMigratingManagementMutation,
    UnapproveMigratingManagementMutationVariables
  >
) {
  return Apollo.useMutation<
    UnapproveMigratingManagementMutation,
    UnapproveMigratingManagementMutationVariables
  >(UnapproveMigratingManagementDocument, baseOptions);
}
export type UnapproveMigratingManagementMutationHookResult = ReturnType<
  typeof useUnapproveMigratingManagementMutation
>;
export type UnapproveMigratingManagementMutationResult =
  Apollo.MutationResult<UnapproveMigratingManagementMutation>;
export type UnapproveMigratingManagementMutationOptions =
  Apollo.BaseMutationOptions<
    UnapproveMigratingManagementMutation,
    UnapproveMigratingManagementMutationVariables
  >;
export const UpdateMigratingManagementDocument = gql`
  mutation updateMigratingManagement(
    $migratingManagementId: ID!
    $migrateAsVacant: Boolean
  ) {
    updateMigratingManagement(
      input: {
        migrateAsVacant: $migrateAsVacant
        migratingManagementId: $migratingManagementId
      }
    ) {
      id
      migrateAsVacant
      errors
      healthcheckRunAt
    }
  }
`;
export type UpdateMigratingManagementMutationFn = Apollo.MutationFunction<
  UpdateMigratingManagementMutation,
  UpdateMigratingManagementMutationVariables
>;

/**
 * __useUpdateMigratingManagementMutation__
 *
 * To run a mutation, you first call `useUpdateMigratingManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMigratingManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMigratingManagementMutation, { data, loading, error }] = useUpdateMigratingManagementMutation({
 *   variables: {
 *      migratingManagementId: // value for 'migratingManagementId'
 *      migrateAsVacant: // value for 'migrateAsVacant'
 *   },
 * });
 */
export function useUpdateMigratingManagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMigratingManagementMutation,
    UpdateMigratingManagementMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateMigratingManagementMutation,
    UpdateMigratingManagementMutationVariables
  >(UpdateMigratingManagementDocument, baseOptions);
}
export type UpdateMigratingManagementMutationHookResult = ReturnType<
  typeof useUpdateMigratingManagementMutation
>;
export type UpdateMigratingManagementMutationResult =
  Apollo.MutationResult<UpdateMigratingManagementMutation>;
export type UpdateMigratingManagementMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMigratingManagementMutation,
    UpdateMigratingManagementMutationVariables
  >;
export const GetMigrationDetailsDocument = gql`
  query getMigrationDetails($organisationId: ID!) {
    organisation(id: $organisationId) {
      id
      hasActiveExternalTrustAccount
      migrationDetails {
        lastRefreshedAt
        refreshStatus
      }
    }
  }
`;

/**
 * __useGetMigrationDetailsQuery__
 *
 * To run a query within a React component, call `useGetMigrationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMigrationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMigrationDetailsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetMigrationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMigrationDetailsQuery,
    GetMigrationDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMigrationDetailsQuery,
    GetMigrationDetailsQueryVariables
  >(GetMigrationDetailsDocument, baseOptions);
}
export function useGetMigrationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMigrationDetailsQuery,
    GetMigrationDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMigrationDetailsQuery,
    GetMigrationDetailsQueryVariables
  >(GetMigrationDetailsDocument, baseOptions);
}
export type GetMigrationDetailsQueryHookResult = ReturnType<
  typeof useGetMigrationDetailsQuery
>;
export type GetMigrationDetailsLazyQueryHookResult = ReturnType<
  typeof useGetMigrationDetailsLazyQuery
>;
export type GetMigrationDetailsQueryResult = Apollo.QueryResult<
  GetMigrationDetailsQuery,
  GetMigrationDetailsQueryVariables
>;
export const StartMigrationsDocument = gql`
  mutation startMigrations($organisationId: AiloRN!) {
    startMigrations(organisationId: $organisationId) {
      successes
      errors {
        legalEntityId
      }
    }
  }
`;
export type StartMigrationsMutationFn = Apollo.MutationFunction<
  StartMigrationsMutation,
  StartMigrationsMutationVariables
>;

/**
 * __useStartMigrationsMutation__
 *
 * To run a mutation, you first call `useStartMigrationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMigrationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMigrationsMutation, { data, loading, error }] = useStartMigrationsMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useStartMigrationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartMigrationsMutation,
    StartMigrationsMutationVariables
  >
) {
  return Apollo.useMutation<
    StartMigrationsMutation,
    StartMigrationsMutationVariables
  >(StartMigrationsDocument, baseOptions);
}
export type StartMigrationsMutationHookResult = ReturnType<
  typeof useStartMigrationsMutation
>;
export type StartMigrationsMutationResult =
  Apollo.MutationResult<StartMigrationsMutation>;
export type StartMigrationsMutationOptions = Apollo.BaseMutationOptions<
  StartMigrationsMutation,
  StartMigrationsMutationVariables
>;
export const GetMigratingManagementPublishStatusDocument = gql`
  query getMigratingManagementPublishStatus($migratingManagementId: ID!) {
    migratingManagement(id: $migratingManagementId) {
      id
      publishStatus
    }
  }
`;

/**
 * __useGetMigratingManagementPublishStatusQuery__
 *
 * To run a query within a React component, call `useGetMigratingManagementPublishStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMigratingManagementPublishStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMigratingManagementPublishStatusQuery({
 *   variables: {
 *      migratingManagementId: // value for 'migratingManagementId'
 *   },
 * });
 */
export function useGetMigratingManagementPublishStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMigratingManagementPublishStatusQuery,
    GetMigratingManagementPublishStatusQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMigratingManagementPublishStatusQuery,
    GetMigratingManagementPublishStatusQueryVariables
  >(GetMigratingManagementPublishStatusDocument, baseOptions);
}
export function useGetMigratingManagementPublishStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMigratingManagementPublishStatusQuery,
    GetMigratingManagementPublishStatusQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMigratingManagementPublishStatusQuery,
    GetMigratingManagementPublishStatusQueryVariables
  >(GetMigratingManagementPublishStatusDocument, baseOptions);
}
export type GetMigratingManagementPublishStatusQueryHookResult = ReturnType<
  typeof useGetMigratingManagementPublishStatusQuery
>;
export type GetMigratingManagementPublishStatusLazyQueryHookResult = ReturnType<
  typeof useGetMigratingManagementPublishStatusLazyQuery
>;
export type GetMigratingManagementPublishStatusQueryResult = Apollo.QueryResult<
  GetMigratingManagementPublishStatusQuery,
  GetMigratingManagementPublishStatusQueryVariables
>;
export const ApproveMigratingManagementsDocument = gql`
  mutation approveMigratingManagements($migratingManagementIds: [ID!]!) {
    approveMigratingManagements(
      migratingManagementIds: $migratingManagementIds
    ) {
      successes {
        id
      }
      errors {
        id
      }
    }
  }
`;
export type ApproveMigratingManagementsMutationFn = Apollo.MutationFunction<
  ApproveMigratingManagementsMutation,
  ApproveMigratingManagementsMutationVariables
>;

/**
 * __useApproveMigratingManagementsMutation__
 *
 * To run a mutation, you first call `useApproveMigratingManagementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveMigratingManagementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveMigratingManagementsMutation, { data, loading, error }] = useApproveMigratingManagementsMutation({
 *   variables: {
 *      migratingManagementIds: // value for 'migratingManagementIds'
 *   },
 * });
 */
export function useApproveMigratingManagementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveMigratingManagementsMutation,
    ApproveMigratingManagementsMutationVariables
  >
) {
  return Apollo.useMutation<
    ApproveMigratingManagementsMutation,
    ApproveMigratingManagementsMutationVariables
  >(ApproveMigratingManagementsDocument, baseOptions);
}
export type ApproveMigratingManagementsMutationHookResult = ReturnType<
  typeof useApproveMigratingManagementsMutation
>;
export type ApproveMigratingManagementsMutationResult =
  Apollo.MutationResult<ApproveMigratingManagementsMutation>;
export type ApproveMigratingManagementsMutationOptions =
  Apollo.BaseMutationOptions<
    ApproveMigratingManagementsMutation,
    ApproveMigratingManagementsMutationVariables
  >;
export const GetMigratingManagementPortfolioNamesDocument = gql`
  query getMigratingManagementPortfolioNames(
    $organisationId: ID!
    $status: MigratingManagementStatus
  ) {
    organisation(id: $organisationId) {
      id
      migratingManagementPortfolioNames(status: $status) {
        names
        hasUnassigned
      }
    }
  }
`;

/**
 * __useGetMigratingManagementPortfolioNamesQuery__
 *
 * To run a query within a React component, call `useGetMigratingManagementPortfolioNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMigratingManagementPortfolioNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMigratingManagementPortfolioNamesQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetMigratingManagementPortfolioNamesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMigratingManagementPortfolioNamesQuery,
    GetMigratingManagementPortfolioNamesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMigratingManagementPortfolioNamesQuery,
    GetMigratingManagementPortfolioNamesQueryVariables
  >(GetMigratingManagementPortfolioNamesDocument, baseOptions);
}
export function useGetMigratingManagementPortfolioNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMigratingManagementPortfolioNamesQuery,
    GetMigratingManagementPortfolioNamesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMigratingManagementPortfolioNamesQuery,
    GetMigratingManagementPortfolioNamesQueryVariables
  >(GetMigratingManagementPortfolioNamesDocument, baseOptions);
}
export type GetMigratingManagementPortfolioNamesQueryHookResult = ReturnType<
  typeof useGetMigratingManagementPortfolioNamesQuery
>;
export type GetMigratingManagementPortfolioNamesLazyQueryHookResult =
  ReturnType<typeof useGetMigratingManagementPortfolioNamesLazyQuery>;
export type GetMigratingManagementPortfolioNamesQueryResult =
  Apollo.QueryResult<
    GetMigratingManagementPortfolioNamesQuery,
    GetMigratingManagementPortfolioNamesQueryVariables
  >;
export const IncludeMigratingManagementsDocument = gql`
  mutation includeMigratingManagements($migratingManagementIds: [ID!]!) {
    includeMigratingManagements(
      migratingManagementIds: $migratingManagementIds
    ) {
      successes {
        id
      }
      errors {
        id
      }
    }
  }
`;
export type IncludeMigratingManagementsMutationFn = Apollo.MutationFunction<
  IncludeMigratingManagementsMutation,
  IncludeMigratingManagementsMutationVariables
>;

/**
 * __useIncludeMigratingManagementsMutation__
 *
 * To run a mutation, you first call `useIncludeMigratingManagementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncludeMigratingManagementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [includeMigratingManagementsMutation, { data, loading, error }] = useIncludeMigratingManagementsMutation({
 *   variables: {
 *      migratingManagementIds: // value for 'migratingManagementIds'
 *   },
 * });
 */
export function useIncludeMigratingManagementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncludeMigratingManagementsMutation,
    IncludeMigratingManagementsMutationVariables
  >
) {
  return Apollo.useMutation<
    IncludeMigratingManagementsMutation,
    IncludeMigratingManagementsMutationVariables
  >(IncludeMigratingManagementsDocument, baseOptions);
}
export type IncludeMigratingManagementsMutationHookResult = ReturnType<
  typeof useIncludeMigratingManagementsMutation
>;
export type IncludeMigratingManagementsMutationResult =
  Apollo.MutationResult<IncludeMigratingManagementsMutation>;
export type IncludeMigratingManagementsMutationOptions =
  Apollo.BaseMutationOptions<
    IncludeMigratingManagementsMutation,
    IncludeMigratingManagementsMutationVariables
  >;
export const UnapproveMigratingManagementsDocument = gql`
  mutation unapproveMigratingManagements($migratingManagementIds: [ID!]!) {
    unapproveMigratingManagements(
      migratingManagementIds: $migratingManagementIds
    ) {
      successes {
        id
      }
      errors {
        id
      }
    }
  }
`;
export type UnapproveMigratingManagementsMutationFn = Apollo.MutationFunction<
  UnapproveMigratingManagementsMutation,
  UnapproveMigratingManagementsMutationVariables
>;

/**
 * __useUnapproveMigratingManagementsMutation__
 *
 * To run a mutation, you first call `useUnapproveMigratingManagementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnapproveMigratingManagementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unapproveMigratingManagementsMutation, { data, loading, error }] = useUnapproveMigratingManagementsMutation({
 *   variables: {
 *      migratingManagementIds: // value for 'migratingManagementIds'
 *   },
 * });
 */
export function useUnapproveMigratingManagementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnapproveMigratingManagementsMutation,
    UnapproveMigratingManagementsMutationVariables
  >
) {
  return Apollo.useMutation<
    UnapproveMigratingManagementsMutation,
    UnapproveMigratingManagementsMutationVariables
  >(UnapproveMigratingManagementsDocument, baseOptions);
}
export type UnapproveMigratingManagementsMutationHookResult = ReturnType<
  typeof useUnapproveMigratingManagementsMutation
>;
export type UnapproveMigratingManagementsMutationResult =
  Apollo.MutationResult<UnapproveMigratingManagementsMutation>;
export type UnapproveMigratingManagementsMutationOptions =
  Apollo.BaseMutationOptions<
    UnapproveMigratingManagementsMutation,
    UnapproveMigratingManagementsMutationVariables
  >;
export const RemoveProjectFilesDocument = gql`
  mutation removeProjectFiles($projectFileIds: [ID!]!) {
    removeProjectFiles(projectFileIds: $projectFileIds)
  }
`;
export type RemoveProjectFilesMutationFn = Apollo.MutationFunction<
  RemoveProjectFilesMutation,
  RemoveProjectFilesMutationVariables
>;

/**
 * __useRemoveProjectFilesMutation__
 *
 * To run a mutation, you first call `useRemoveProjectFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectFilesMutation, { data, loading, error }] = useRemoveProjectFilesMutation({
 *   variables: {
 *      projectFileIds: // value for 'projectFileIds'
 *   },
 * });
 */
export function useRemoveProjectFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveProjectFilesMutation,
    RemoveProjectFilesMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveProjectFilesMutation,
    RemoveProjectFilesMutationVariables
  >(RemoveProjectFilesDocument, baseOptions);
}
export type RemoveProjectFilesMutationHookResult = ReturnType<
  typeof useRemoveProjectFilesMutation
>;
export type RemoveProjectFilesMutationResult =
  Apollo.MutationResult<RemoveProjectFilesMutation>;
export type RemoveProjectFilesMutationOptions = Apollo.BaseMutationOptions<
  RemoveProjectFilesMutation,
  RemoveProjectFilesMutationVariables
>;
export const GetProjectFilesDocument = gql`
  query getProjectFiles($id: ID!, $cursor: String, $pageSize: Int!) {
    project(id: $id) {
      id
      files(cursor: { cursor: $cursor, pageSize: $pageSize }) {
        items {
          id
          file {
            id
            url
            thumbnailUrl
            thumbnailStatus
            contentType
            fileName
            fileSize
          }
        }
        pageInfo {
          total
          hasMore
          nextCursor
        }
      }
    }
  }
`;

/**
 * __useGetProjectFilesQuery__
 *
 * To run a query within a React component, call `useGetProjectFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectFilesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cursor: // value for 'cursor'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetProjectFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectFilesQuery,
    GetProjectFilesQueryVariables
  >
) {
  return Apollo.useQuery<GetProjectFilesQuery, GetProjectFilesQueryVariables>(
    GetProjectFilesDocument,
    baseOptions
  );
}
export function useGetProjectFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectFilesQuery,
    GetProjectFilesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetProjectFilesQuery,
    GetProjectFilesQueryVariables
  >(GetProjectFilesDocument, baseOptions);
}
export type GetProjectFilesQueryHookResult = ReturnType<
  typeof useGetProjectFilesQuery
>;
export type GetProjectFilesLazyQueryHookResult = ReturnType<
  typeof useGetProjectFilesLazyQuery
>;
export type GetProjectFilesQueryResult = Apollo.QueryResult<
  GetProjectFilesQuery,
  GetProjectFilesQueryVariables
>;
export const ArchiveProjectDocument = gql`
  mutation archiveProject($id: ID!) {
    archiveProject(id: $id)
  }
`;
export type ArchiveProjectMutationFn = Apollo.MutationFunction<
  ArchiveProjectMutation,
  ArchiveProjectMutationVariables
>;

/**
 * __useArchiveProjectMutation__
 *
 * To run a mutation, you first call `useArchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProjectMutation, { data, loading, error }] = useArchiveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveProjectMutation,
    ArchiveProjectMutationVariables
  >
) {
  return Apollo.useMutation<
    ArchiveProjectMutation,
    ArchiveProjectMutationVariables
  >(ArchiveProjectDocument, baseOptions);
}
export type ArchiveProjectMutationHookResult = ReturnType<
  typeof useArchiveProjectMutation
>;
export type ArchiveProjectMutationResult =
  Apollo.MutationResult<ArchiveProjectMutation>;
export type ArchiveProjectMutationOptions = Apollo.BaseMutationOptions<
  ArchiveProjectMutation,
  ArchiveProjectMutationVariables
>;
export const CloseProjectDocument = gql`
  mutation closeProject($id: ID!) {
    closeProject(id: $id) {
      ...Project
      ...ProjectActions
    }
  }
  ${ProjectFragmentDoc}
  ${ProjectActionsFragmentDoc}
`;
export type CloseProjectMutationFn = Apollo.MutationFunction<
  CloseProjectMutation,
  CloseProjectMutationVariables
>;

/**
 * __useCloseProjectMutation__
 *
 * To run a mutation, you first call `useCloseProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeProjectMutation, { data, loading, error }] = useCloseProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseProjectMutation,
    CloseProjectMutationVariables
  >
) {
  return Apollo.useMutation<
    CloseProjectMutation,
    CloseProjectMutationVariables
  >(CloseProjectDocument, baseOptions);
}
export type CloseProjectMutationHookResult = ReturnType<
  typeof useCloseProjectMutation
>;
export type CloseProjectMutationResult =
  Apollo.MutationResult<CloseProjectMutation>;
export type CloseProjectMutationOptions = Apollo.BaseMutationOptions<
  CloseProjectMutation,
  CloseProjectMutationVariables
>;
export const ReopenProjectDocument = gql`
  mutation reopenProject($id: ID!) {
    reopenProject(id: $id) {
      ...Project
      ...ProjectActions
    }
  }
  ${ProjectFragmentDoc}
  ${ProjectActionsFragmentDoc}
`;
export type ReopenProjectMutationFn = Apollo.MutationFunction<
  ReopenProjectMutation,
  ReopenProjectMutationVariables
>;

/**
 * __useReopenProjectMutation__
 *
 * To run a mutation, you first call `useReopenProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenProjectMutation, { data, loading, error }] = useReopenProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReopenProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReopenProjectMutation,
    ReopenProjectMutationVariables
  >
) {
  return Apollo.useMutation<
    ReopenProjectMutation,
    ReopenProjectMutationVariables
  >(ReopenProjectDocument, baseOptions);
}
export type ReopenProjectMutationHookResult = ReturnType<
  typeof useReopenProjectMutation
>;
export type ReopenProjectMutationResult =
  Apollo.MutationResult<ReopenProjectMutation>;
export type ReopenProjectMutationOptions = Apollo.BaseMutationOptions<
  ReopenProjectMutation,
  ReopenProjectMutationVariables
>;
export const GetProjectActionsCountDocument = gql`
  query getProjectActionsCount($projectId: ID!) {
    project(id: $projectId) {
      id
      actions(cursor: { pageSize: 0 }) {
        pageInfo {
          total
        }
      }
    }
  }
`;

/**
 * __useGetProjectActionsCountQuery__
 *
 * To run a query within a React component, call `useGetProjectActionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectActionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectActionsCountQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectActionsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectActionsCountQuery,
    GetProjectActionsCountQueryVariables
  >
) {
  return Apollo.useQuery<
    GetProjectActionsCountQuery,
    GetProjectActionsCountQueryVariables
  >(GetProjectActionsCountDocument, baseOptions);
}
export function useGetProjectActionsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectActionsCountQuery,
    GetProjectActionsCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetProjectActionsCountQuery,
    GetProjectActionsCountQueryVariables
  >(GetProjectActionsCountDocument, baseOptions);
}
export type GetProjectActionsCountQueryHookResult = ReturnType<
  typeof useGetProjectActionsCountQuery
>;
export type GetProjectActionsCountLazyQueryHookResult = ReturnType<
  typeof useGetProjectActionsCountLazyQuery
>;
export type GetProjectActionsCountQueryResult = Apollo.QueryResult<
  GetProjectActionsCountQuery,
  GetProjectActionsCountQueryVariables
>;
export const GetFormDetailsDocument = gql`
  query getFormDetails($id: ID!) {
    form(id: $id) {
      id
      ... on ReiForm {
        reiFormInfo {
          name
          finalised
        }
      }
    }
  }
`;

/**
 * __useGetFormDetailsQuery__
 *
 * To run a query within a React component, call `useGetFormDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormDetailsQuery,
    GetFormDetailsQueryVariables
  >
) {
  return Apollo.useQuery<GetFormDetailsQuery, GetFormDetailsQueryVariables>(
    GetFormDetailsDocument,
    baseOptions
  );
}
export function useGetFormDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormDetailsQuery,
    GetFormDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetFormDetailsQuery, GetFormDetailsQueryVariables>(
    GetFormDetailsDocument,
    baseOptions
  );
}
export type GetFormDetailsQueryHookResult = ReturnType<
  typeof useGetFormDetailsQuery
>;
export type GetFormDetailsLazyQueryHookResult = ReturnType<
  typeof useGetFormDetailsLazyQuery
>;
export type GetFormDetailsQueryResult = Apollo.QueryResult<
  GetFormDetailsQuery,
  GetFormDetailsQueryVariables
>;
export const GetFormUrlsDocument = gql`
  query getFormUrls($id: ID!) {
    form(id: $id) {
      id
      ... on ReiForm {
        viewUrl
        downloadUrl
      }
    }
  }
`;

/**
 * __useGetFormUrlsQuery__
 *
 * To run a query within a React component, call `useGetFormUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormUrlsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormUrlsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormUrlsQuery,
    GetFormUrlsQueryVariables
  >
) {
  return Apollo.useQuery<GetFormUrlsQuery, GetFormUrlsQueryVariables>(
    GetFormUrlsDocument,
    baseOptions
  );
}
export function useGetFormUrlsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormUrlsQuery,
    GetFormUrlsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetFormUrlsQuery, GetFormUrlsQueryVariables>(
    GetFormUrlsDocument,
    baseOptions
  );
}
export type GetFormUrlsQueryHookResult = ReturnType<typeof useGetFormUrlsQuery>;
export type GetFormUrlsLazyQueryHookResult = ReturnType<
  typeof useGetFormUrlsLazyQuery
>;
export type GetFormUrlsQueryResult = Apollo.QueryResult<
  GetFormUrlsQuery,
  GetFormUrlsQueryVariables
>;
export const CompleteAssignFormActionDocument = gql`
  mutation completeAssignFormAction($input: CompleteAssignFormActionInput!) {
    completeAssignFormAction(input: $input) {
      action {
        ...ActionDetails
      }
    }
  }
  ${ActionDetailsFragmentDoc}
`;
export type CompleteAssignFormActionMutationFn = Apollo.MutationFunction<
  CompleteAssignFormActionMutation,
  CompleteAssignFormActionMutationVariables
>;

/**
 * __useCompleteAssignFormActionMutation__
 *
 * To run a mutation, you first call `useCompleteAssignFormActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAssignFormActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAssignFormActionMutation, { data, loading, error }] = useCompleteAssignFormActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteAssignFormActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteAssignFormActionMutation,
    CompleteAssignFormActionMutationVariables
  >
) {
  return Apollo.useMutation<
    CompleteAssignFormActionMutation,
    CompleteAssignFormActionMutationVariables
  >(CompleteAssignFormActionDocument, baseOptions);
}
export type CompleteAssignFormActionMutationHookResult = ReturnType<
  typeof useCompleteAssignFormActionMutation
>;
export type CompleteAssignFormActionMutationResult =
  Apollo.MutationResult<CompleteAssignFormActionMutation>;
export type CompleteAssignFormActionMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteAssignFormActionMutation,
    CompleteAssignFormActionMutationVariables
  >;
export const CreateReiFormFromUserTemplateDocument = gql`
  mutation CreateReiFormFromUserTemplate(
    $input: CreateReiFormFromUserTemplateInput!
  ) {
    createReiFormFromUserTemplate(createReiFormFromUserTemplateInput: $input) {
      id
      ailorn
    }
  }
`;
export type CreateReiFormFromUserTemplateMutationFn = Apollo.MutationFunction<
  CreateReiFormFromUserTemplateMutation,
  CreateReiFormFromUserTemplateMutationVariables
>;

/**
 * __useCreateReiFormFromUserTemplateMutation__
 *
 * To run a mutation, you first call `useCreateReiFormFromUserTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReiFormFromUserTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReiFormFromUserTemplateMutation, { data, loading, error }] = useCreateReiFormFromUserTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReiFormFromUserTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReiFormFromUserTemplateMutation,
    CreateReiFormFromUserTemplateMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateReiFormFromUserTemplateMutation,
    CreateReiFormFromUserTemplateMutationVariables
  >(CreateReiFormFromUserTemplateDocument, baseOptions);
}
export type CreateReiFormFromUserTemplateMutationHookResult = ReturnType<
  typeof useCreateReiFormFromUserTemplateMutation
>;
export type CreateReiFormFromUserTemplateMutationResult =
  Apollo.MutationResult<CreateReiFormFromUserTemplateMutation>;
export type CreateReiFormFromUserTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    CreateReiFormFromUserTemplateMutation,
    CreateReiFormFromUserTemplateMutationVariables
  >;
export const GetFormGenerationDataDocument = gql`
  query getFormGenerationData(
    $personAilorn: AiloRN!
    $organisationAilorn: AiloRN!
    $projectId: ID!
  ) {
    legalEntity(id: $personAilorn) {
      id
      ... on Person {
        reiTemplatesForOrg(organisationAilorn: $organisationAilorn) {
          id
          name
          code
        }
      }
    }
    project(id: $projectId) {
      id
      ...ProjectMeta
    }
  }
  ${ProjectMetaFragmentDoc}
`;

/**
 * __useGetFormGenerationDataQuery__
 *
 * To run a query within a React component, call `useGetFormGenerationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormGenerationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormGenerationDataQuery({
 *   variables: {
 *      personAilorn: // value for 'personAilorn'
 *      organisationAilorn: // value for 'organisationAilorn'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetFormGenerationDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormGenerationDataQuery,
    GetFormGenerationDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetFormGenerationDataQuery,
    GetFormGenerationDataQueryVariables
  >(GetFormGenerationDataDocument, baseOptions);
}
export function useGetFormGenerationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormGenerationDataQuery,
    GetFormGenerationDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetFormGenerationDataQuery,
    GetFormGenerationDataQueryVariables
  >(GetFormGenerationDataDocument, baseOptions);
}
export type GetFormGenerationDataQueryHookResult = ReturnType<
  typeof useGetFormGenerationDataQuery
>;
export type GetFormGenerationDataLazyQueryHookResult = ReturnType<
  typeof useGetFormGenerationDataLazyQuery
>;
export type GetFormGenerationDataQueryResult = Apollo.QueryResult<
  GetFormGenerationDataQuery,
  GetFormGenerationDataQueryVariables
>;
export const GetManagementDetailsForActionDocument = gql`
  query getManagementDetailsForAction($id: ID!) {
    management(managementId: $id) {
      id
      ...Management
      ...ManagementProperty
      managementAgreements {
        items {
          id
          startDate
          fixedTermEndDate
          managementFeeSchedules {
            items {
              id
              percent
            }
          }
        }
      }
      ownerships {
        ...OwnershipItems
      }
    }
  }
  ${ManagementFragmentDoc}
  ${ManagementPropertyFragmentDoc}
  ${OwnershipItemsFragmentDoc}
`;

/**
 * __useGetManagementDetailsForActionQuery__
 *
 * To run a query within a React component, call `useGetManagementDetailsForActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementDetailsForActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementDetailsForActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManagementDetailsForActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementDetailsForActionQuery,
    GetManagementDetailsForActionQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementDetailsForActionQuery,
    GetManagementDetailsForActionQueryVariables
  >(GetManagementDetailsForActionDocument, baseOptions);
}
export function useGetManagementDetailsForActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementDetailsForActionQuery,
    GetManagementDetailsForActionQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementDetailsForActionQuery,
    GetManagementDetailsForActionQueryVariables
  >(GetManagementDetailsForActionDocument, baseOptions);
}
export type GetManagementDetailsForActionQueryHookResult = ReturnType<
  typeof useGetManagementDetailsForActionQuery
>;
export type GetManagementDetailsForActionLazyQueryHookResult = ReturnType<
  typeof useGetManagementDetailsForActionLazyQuery
>;
export type GetManagementDetailsForActionQueryResult = Apollo.QueryResult<
  GetManagementDetailsForActionQuery,
  GetManagementDetailsForActionQueryVariables
>;
export const CompleteAssignNewManagementActionDocument = gql`
  mutation completeAssignNewManagementAction(
    $input: CompleteAssignNewManagementActionInput!
  ) {
    completeAssignNewManagementAction(input: $input) {
      action {
        ...ActionDetails
      }
    }
  }
  ${ActionDetailsFragmentDoc}
`;
export type CompleteAssignNewManagementActionMutationFn =
  Apollo.MutationFunction<
    CompleteAssignNewManagementActionMutation,
    CompleteAssignNewManagementActionMutationVariables
  >;

/**
 * __useCompleteAssignNewManagementActionMutation__
 *
 * To run a mutation, you first call `useCompleteAssignNewManagementActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAssignNewManagementActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAssignNewManagementActionMutation, { data, loading, error }] = useCompleteAssignNewManagementActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteAssignNewManagementActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteAssignNewManagementActionMutation,
    CompleteAssignNewManagementActionMutationVariables
  >
) {
  return Apollo.useMutation<
    CompleteAssignNewManagementActionMutation,
    CompleteAssignNewManagementActionMutationVariables
  >(CompleteAssignNewManagementActionDocument, baseOptions);
}
export type CompleteAssignNewManagementActionMutationHookResult = ReturnType<
  typeof useCompleteAssignNewManagementActionMutation
>;
export type CompleteAssignNewManagementActionMutationResult =
  Apollo.MutationResult<CompleteAssignNewManagementActionMutation>;
export type CompleteAssignNewManagementActionMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteAssignNewManagementActionMutation,
    CompleteAssignNewManagementActionMutationVariables
  >;
export const CompleteAssignRentActionDocument = gql`
  mutation completeAssignRentAction(
    $id: ID!
    $input: CompleteAssignRentActionInput!
  ) {
    completeAssignRentAction(id: $id, input: $input) {
      action {
        ...ActionDetails
      }
    }
  }
  ${ActionDetailsFragmentDoc}
`;
export type CompleteAssignRentActionMutationFn = Apollo.MutationFunction<
  CompleteAssignRentActionMutation,
  CompleteAssignRentActionMutationVariables
>;

/**
 * __useCompleteAssignRentActionMutation__
 *
 * To run a mutation, you first call `useCompleteAssignRentActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAssignRentActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAssignRentActionMutation, { data, loading, error }] = useCompleteAssignRentActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteAssignRentActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteAssignRentActionMutation,
    CompleteAssignRentActionMutationVariables
  >
) {
  return Apollo.useMutation<
    CompleteAssignRentActionMutation,
    CompleteAssignRentActionMutationVariables
  >(CompleteAssignRentActionDocument, baseOptions);
}
export type CompleteAssignRentActionMutationHookResult = ReturnType<
  typeof useCompleteAssignRentActionMutation
>;
export type CompleteAssignRentActionMutationResult =
  Apollo.MutationResult<CompleteAssignRentActionMutation>;
export type CompleteAssignRentActionMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteAssignRentActionMutation,
    CompleteAssignRentActionMutationVariables
  >;
export const GetTenancyDetailsForActionDocument = gql`
  query getTenancyDetailsForAction($id: ID!) {
    tenancy(tenancyId: $id) {
      id
      ...Tenancy
      ...TenancyIsVoidable
      scheduledRentReviewDate
      rents(cursor: { pageSize: 1 }) {
        pageInfo {
          total
        }
        items {
          period
          effectiveDate
          amountInCents
        }
      }
      tenancyAgreements {
        items {
          id
          startDate
          fixedTermEndDate
        }
      }
      deposit {
        amount {
          cents
        }
      }
      tenantships {
        ...TenantShipItems
      }
    }
  }
  ${TenancyFragmentDoc}
  ${TenancyIsVoidableFragmentDoc}
  ${TenantShipItemsFragmentDoc}
`;

/**
 * __useGetTenancyDetailsForActionQuery__
 *
 * To run a query within a React component, call `useGetTenancyDetailsForActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenancyDetailsForActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenancyDetailsForActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenancyDetailsForActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenancyDetailsForActionQuery,
    GetTenancyDetailsForActionQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenancyDetailsForActionQuery,
    GetTenancyDetailsForActionQueryVariables
  >(GetTenancyDetailsForActionDocument, baseOptions);
}
export function useGetTenancyDetailsForActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenancyDetailsForActionQuery,
    GetTenancyDetailsForActionQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenancyDetailsForActionQuery,
    GetTenancyDetailsForActionQueryVariables
  >(GetTenancyDetailsForActionDocument, baseOptions);
}
export type GetTenancyDetailsForActionQueryHookResult = ReturnType<
  typeof useGetTenancyDetailsForActionQuery
>;
export type GetTenancyDetailsForActionLazyQueryHookResult = ReturnType<
  typeof useGetTenancyDetailsForActionLazyQuery
>;
export type GetTenancyDetailsForActionQueryResult = Apollo.QueryResult<
  GetTenancyDetailsForActionQuery,
  GetTenancyDetailsForActionQueryVariables
>;
export const CompleteAssignTenancyActionDocument = gql`
  mutation completeAssignTenancyAction(
    $input: CompleteAssignTenancyActionInput!
  ) {
    completeAssignTenancyAction(input: $input) {
      action {
        ...ActionDetails
      }
    }
  }
  ${ActionDetailsFragmentDoc}
`;
export type CompleteAssignTenancyActionMutationFn = Apollo.MutationFunction<
  CompleteAssignTenancyActionMutation,
  CompleteAssignTenancyActionMutationVariables
>;

/**
 * __useCompleteAssignTenancyActionMutation__
 *
 * To run a mutation, you first call `useCompleteAssignTenancyActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAssignTenancyActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAssignTenancyActionMutation, { data, loading, error }] = useCompleteAssignTenancyActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteAssignTenancyActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteAssignTenancyActionMutation,
    CompleteAssignTenancyActionMutationVariables
  >
) {
  return Apollo.useMutation<
    CompleteAssignTenancyActionMutation,
    CompleteAssignTenancyActionMutationVariables
  >(CompleteAssignTenancyActionDocument, baseOptions);
}
export type CompleteAssignTenancyActionMutationHookResult = ReturnType<
  typeof useCompleteAssignTenancyActionMutation
>;
export type CompleteAssignTenancyActionMutationResult =
  Apollo.MutationResult<CompleteAssignTenancyActionMutation>;
export type CompleteAssignTenancyActionMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteAssignTenancyActionMutation,
    CompleteAssignTenancyActionMutationVariables
  >;
export const CreateInspectionAppointmentsDocument = gql`
  mutation createInspectionAppointments(
    $agencyAilorn: AiloRN!
    $input: [CreateInspectionAppointmentInput!]!
  ) {
    createInspectionAppointments(agencyAilorn: $agencyAilorn, input: $input) {
      inspectionAppointments {
        ...InspectionAppointmentDetails
      }
    }
  }
  ${InspectionAppointmentDetailsFragmentDoc}
`;
export type CreateInspectionAppointmentsMutationFn = Apollo.MutationFunction<
  CreateInspectionAppointmentsMutation,
  CreateInspectionAppointmentsMutationVariables
>;

/**
 * __useCreateInspectionAppointmentsMutation__
 *
 * To run a mutation, you first call `useCreateInspectionAppointmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInspectionAppointmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInspectionAppointmentsMutation, { data, loading, error }] = useCreateInspectionAppointmentsMutation({
 *   variables: {
 *      agencyAilorn: // value for 'agencyAilorn'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInspectionAppointmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInspectionAppointmentsMutation,
    CreateInspectionAppointmentsMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateInspectionAppointmentsMutation,
    CreateInspectionAppointmentsMutationVariables
  >(CreateInspectionAppointmentsDocument, baseOptions);
}
export type CreateInspectionAppointmentsMutationHookResult = ReturnType<
  typeof useCreateInspectionAppointmentsMutation
>;
export type CreateInspectionAppointmentsMutationResult =
  Apollo.MutationResult<CreateInspectionAppointmentsMutation>;
export type CreateInspectionAppointmentsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInspectionAppointmentsMutation,
    CreateInspectionAppointmentsMutationVariables
  >;
export const GetManagementDetailsForAssignTenancyActionDocument = gql`
  query getManagementDetailsForAssignTenancyAction($managementId: ID!) {
    management(managementId: $managementId) {
      id
      endDate
      currentTenancy {
        id
        endDate
      }
      nextTenancy {
        id
      }
    }
  }
`;

/**
 * __useGetManagementDetailsForAssignTenancyActionQuery__
 *
 * To run a query within a React component, call `useGetManagementDetailsForAssignTenancyActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementDetailsForAssignTenancyActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementDetailsForAssignTenancyActionQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetManagementDetailsForAssignTenancyActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementDetailsForAssignTenancyActionQuery,
    GetManagementDetailsForAssignTenancyActionQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementDetailsForAssignTenancyActionQuery,
    GetManagementDetailsForAssignTenancyActionQueryVariables
  >(GetManagementDetailsForAssignTenancyActionDocument, baseOptions);
}
export function useGetManagementDetailsForAssignTenancyActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementDetailsForAssignTenancyActionQuery,
    GetManagementDetailsForAssignTenancyActionQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementDetailsForAssignTenancyActionQuery,
    GetManagementDetailsForAssignTenancyActionQueryVariables
  >(GetManagementDetailsForAssignTenancyActionDocument, baseOptions);
}
export type GetManagementDetailsForAssignTenancyActionQueryHookResult =
  ReturnType<typeof useGetManagementDetailsForAssignTenancyActionQuery>;
export type GetManagementDetailsForAssignTenancyActionLazyQueryHookResult =
  ReturnType<typeof useGetManagementDetailsForAssignTenancyActionLazyQuery>;
export type GetManagementDetailsForAssignTenancyActionQueryResult =
  Apollo.QueryResult<
    GetManagementDetailsForAssignTenancyActionQuery,
    GetManagementDetailsForAssignTenancyActionQueryVariables
  >;
export const GetReiFormRemoteSigningStatusDocument = gql`
  query GetReiFormRemoteSigningStatus($formId: ID!) {
    form(id: $formId) {
      id
      ... on ReiForm {
        remoteSigningStatus {
          ...ReiFormRemoteSigningStatus
        }
      }
    }
  }
  ${ReiFormRemoteSigningStatusFragmentDoc}
`;

/**
 * __useGetReiFormRemoteSigningStatusQuery__
 *
 * To run a query within a React component, call `useGetReiFormRemoteSigningStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReiFormRemoteSigningStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReiFormRemoteSigningStatusQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useGetReiFormRemoteSigningStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReiFormRemoteSigningStatusQuery,
    GetReiFormRemoteSigningStatusQueryVariables
  >
) {
  return Apollo.useQuery<
    GetReiFormRemoteSigningStatusQuery,
    GetReiFormRemoteSigningStatusQueryVariables
  >(GetReiFormRemoteSigningStatusDocument, baseOptions);
}
export function useGetReiFormRemoteSigningStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReiFormRemoteSigningStatusQuery,
    GetReiFormRemoteSigningStatusQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetReiFormRemoteSigningStatusQuery,
    GetReiFormRemoteSigningStatusQueryVariables
  >(GetReiFormRemoteSigningStatusDocument, baseOptions);
}
export type GetReiFormRemoteSigningStatusQueryHookResult = ReturnType<
  typeof useGetReiFormRemoteSigningStatusQuery
>;
export type GetReiFormRemoteSigningStatusLazyQueryHookResult = ReturnType<
  typeof useGetReiFormRemoteSigningStatusLazyQuery
>;
export type GetReiFormRemoteSigningStatusQueryResult = Apollo.QueryResult<
  GetReiFormRemoteSigningStatusQuery,
  GetReiFormRemoteSigningStatusQueryVariables
>;
export const GetActionDetailsDocument = gql`
  query getActionDetails($id: ID!) {
    action(id: $id) {
      ...ActionDetails
    }
  }
  ${ActionDetailsFragmentDoc}
`;

/**
 * __useGetActionDetailsQuery__
 *
 * To run a query within a React component, call `useGetActionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActionDetailsQuery,
    GetActionDetailsQueryVariables
  >
) {
  return Apollo.useQuery<GetActionDetailsQuery, GetActionDetailsQueryVariables>(
    GetActionDetailsDocument,
    baseOptions
  );
}
export function useGetActionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActionDetailsQuery,
    GetActionDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetActionDetailsQuery,
    GetActionDetailsQueryVariables
  >(GetActionDetailsDocument, baseOptions);
}
export type GetActionDetailsQueryHookResult = ReturnType<
  typeof useGetActionDetailsQuery
>;
export type GetActionDetailsLazyQueryHookResult = ReturnType<
  typeof useGetActionDetailsLazyQuery
>;
export type GetActionDetailsQueryResult = Apollo.QueryResult<
  GetActionDetailsQuery,
  GetActionDetailsQueryVariables
>;
export const ReassignActionDocument = gql`
  mutation reassignAction($id: ID!, $assigneeAilorn: AiloRN!) {
    reassignAction(id: $id, assigneeAilorn: $assigneeAilorn) {
      ...ActionDetails
    }
  }
  ${ActionDetailsFragmentDoc}
`;
export type ReassignActionMutationFn = Apollo.MutationFunction<
  ReassignActionMutation,
  ReassignActionMutationVariables
>;

/**
 * __useReassignActionMutation__
 *
 * To run a mutation, you first call `useReassignActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReassignActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reassignActionMutation, { data, loading, error }] = useReassignActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      assigneeAilorn: // value for 'assigneeAilorn'
 *   },
 * });
 */
export function useReassignActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReassignActionMutation,
    ReassignActionMutationVariables
  >
) {
  return Apollo.useMutation<
    ReassignActionMutation,
    ReassignActionMutationVariables
  >(ReassignActionDocument, baseOptions);
}
export type ReassignActionMutationHookResult = ReturnType<
  typeof useReassignActionMutation
>;
export type ReassignActionMutationResult =
  Apollo.MutationResult<ReassignActionMutation>;
export type ReassignActionMutationOptions = Apollo.BaseMutationOptions<
  ReassignActionMutation,
  ReassignActionMutationVariables
>;
export const UpdateActionDueDateDocument = gql`
  mutation updateActionDueDate($id: ID!, $dueDate: DateTime!) {
    updateActionDueDate(id: $id, dueDate: $dueDate) {
      ...ActionDetails
    }
  }
  ${ActionDetailsFragmentDoc}
`;
export type UpdateActionDueDateMutationFn = Apollo.MutationFunction<
  UpdateActionDueDateMutation,
  UpdateActionDueDateMutationVariables
>;

/**
 * __useUpdateActionDueDateMutation__
 *
 * To run a mutation, you first call `useUpdateActionDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionDueDateMutation, { data, loading, error }] = useUpdateActionDueDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dueDate: // value for 'dueDate'
 *   },
 * });
 */
export function useUpdateActionDueDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateActionDueDateMutation,
    UpdateActionDueDateMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateActionDueDateMutation,
    UpdateActionDueDateMutationVariables
  >(UpdateActionDueDateDocument, baseOptions);
}
export type UpdateActionDueDateMutationHookResult = ReturnType<
  typeof useUpdateActionDueDateMutation
>;
export type UpdateActionDueDateMutationResult =
  Apollo.MutationResult<UpdateActionDueDateMutation>;
export type UpdateActionDueDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateActionDueDateMutation,
  UpdateActionDueDateMutationVariables
>;
export const GetAddExpenseScreenDetailsDocument = gql`
  query getAddExpenseScreenDetails($managementId: ID!) {
    management(managementId: $managementId) {
      id
      ailoRN
      currentOrNextManagementAgreement {
        id
      }
      managingEntity {
        id
        ailoRN
      }
      ...ManagementProperty
      owners {
        owner {
          ...legalEntityName
        }
      }
      nextTenancy {
        id
        ailorn: ailoRN
        rents(cursor: { pageSize: 1 }) {
          items {
            ailorn: id
            dailyRate
          }
        }
      }
      mostRecentTenancy {
        id
        ailorn: ailoRN
        endDate
        currentRent {
          id
          dailyRate
        }
        rents(cursor: { pageSize: 1 }, sort: [EFFECTIVE_DATE_DESC]) {
          items {
            ailorn: id
            dailyRate
          }
        }
        tenantships {
          items {
            tenant {
              ...legalEntityName
            }
          }
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
  ${LegalEntityNameFragmentDoc}
`;

/**
 * __useGetAddExpenseScreenDetailsQuery__
 *
 * To run a query within a React component, call `useGetAddExpenseScreenDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddExpenseScreenDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddExpenseScreenDetailsQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetAddExpenseScreenDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAddExpenseScreenDetailsQuery,
    GetAddExpenseScreenDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAddExpenseScreenDetailsQuery,
    GetAddExpenseScreenDetailsQueryVariables
  >(GetAddExpenseScreenDetailsDocument, baseOptions);
}
export function useGetAddExpenseScreenDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddExpenseScreenDetailsQuery,
    GetAddExpenseScreenDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAddExpenseScreenDetailsQuery,
    GetAddExpenseScreenDetailsQueryVariables
  >(GetAddExpenseScreenDetailsDocument, baseOptions);
}
export type GetAddExpenseScreenDetailsQueryHookResult = ReturnType<
  typeof useGetAddExpenseScreenDetailsQuery
>;
export type GetAddExpenseScreenDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAddExpenseScreenDetailsLazyQuery
>;
export type GetAddExpenseScreenDetailsQueryResult = Apollo.QueryResult<
  GetAddExpenseScreenDetailsQuery,
  GetAddExpenseScreenDetailsQueryVariables
>;
export const GetOccupancyStatusAndTenancyDetailsDocument = gql`
  query getOccupancyStatusAndTenancyDetails($managementId: ID!) {
    management(managementId: $managementId) {
      id
      occupancyStatus
      startDate
      mostRecentTenancy {
        id
        endDate
        mostRecentTenancyAgreement {
          id
          fixedTermEndDate
        }
      }
      nextTenancy {
        id
        startDate
      }
    }
  }
`;

/**
 * __useGetOccupancyStatusAndTenancyDetailsQuery__
 *
 * To run a query within a React component, call `useGetOccupancyStatusAndTenancyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOccupancyStatusAndTenancyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOccupancyStatusAndTenancyDetailsQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetOccupancyStatusAndTenancyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOccupancyStatusAndTenancyDetailsQuery,
    GetOccupancyStatusAndTenancyDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetOccupancyStatusAndTenancyDetailsQuery,
    GetOccupancyStatusAndTenancyDetailsQueryVariables
  >(GetOccupancyStatusAndTenancyDetailsDocument, baseOptions);
}
export function useGetOccupancyStatusAndTenancyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOccupancyStatusAndTenancyDetailsQuery,
    GetOccupancyStatusAndTenancyDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetOccupancyStatusAndTenancyDetailsQuery,
    GetOccupancyStatusAndTenancyDetailsQueryVariables
  >(GetOccupancyStatusAndTenancyDetailsDocument, baseOptions);
}
export type GetOccupancyStatusAndTenancyDetailsQueryHookResult = ReturnType<
  typeof useGetOccupancyStatusAndTenancyDetailsQuery
>;
export type GetOccupancyStatusAndTenancyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetOccupancyStatusAndTenancyDetailsLazyQuery
>;
export type GetOccupancyStatusAndTenancyDetailsQueryResult = Apollo.QueryResult<
  GetOccupancyStatusAndTenancyDetailsQuery,
  GetOccupancyStatusAndTenancyDetailsQueryVariables
>;
export const GetAddressAndFirstPaymentDateDocument = gql`
  query getAddressAndFirstPaymentDate($managementId: ID!) {
    management(managementId: $managementId) {
      id
      ...ManagementProperty
      mostRecentTenancy {
        id
        liability {
          reference
          firstPaymentDate
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useGetAddressAndFirstPaymentDateQuery__
 *
 * To run a query within a React component, call `useGetAddressAndFirstPaymentDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressAndFirstPaymentDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressAndFirstPaymentDateQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetAddressAndFirstPaymentDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAddressAndFirstPaymentDateQuery,
    GetAddressAndFirstPaymentDateQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAddressAndFirstPaymentDateQuery,
    GetAddressAndFirstPaymentDateQueryVariables
  >(GetAddressAndFirstPaymentDateDocument, baseOptions);
}
export function useGetAddressAndFirstPaymentDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddressAndFirstPaymentDateQuery,
    GetAddressAndFirstPaymentDateQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAddressAndFirstPaymentDateQuery,
    GetAddressAndFirstPaymentDateQueryVariables
  >(GetAddressAndFirstPaymentDateDocument, baseOptions);
}
export type GetAddressAndFirstPaymentDateQueryHookResult = ReturnType<
  typeof useGetAddressAndFirstPaymentDateQuery
>;
export type GetAddressAndFirstPaymentDateLazyQueryHookResult = ReturnType<
  typeof useGetAddressAndFirstPaymentDateLazyQuery
>;
export type GetAddressAndFirstPaymentDateQueryResult = Apollo.QueryResult<
  GetAddressAndFirstPaymentDateQuery,
  GetAddressAndFirstPaymentDateQueryVariables
>;
export const GetAgreementOfMostRecentTenancyDocument = gql`
  query getAgreementOfMostRecentTenancy($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        latestTenancyAgreement {
          ...TenancyAgreement
        }
      }
    }
  }
  ${TenancyAgreementFragmentDoc}
`;

/**
 * __useGetAgreementOfMostRecentTenancyQuery__
 *
 * To run a query within a React component, call `useGetAgreementOfMostRecentTenancyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementOfMostRecentTenancyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementOfMostRecentTenancyQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetAgreementOfMostRecentTenancyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgreementOfMostRecentTenancyQuery,
    GetAgreementOfMostRecentTenancyQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAgreementOfMostRecentTenancyQuery,
    GetAgreementOfMostRecentTenancyQueryVariables
  >(GetAgreementOfMostRecentTenancyDocument, baseOptions);
}
export function useGetAgreementOfMostRecentTenancyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgreementOfMostRecentTenancyQuery,
    GetAgreementOfMostRecentTenancyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAgreementOfMostRecentTenancyQuery,
    GetAgreementOfMostRecentTenancyQueryVariables
  >(GetAgreementOfMostRecentTenancyDocument, baseOptions);
}
export type GetAgreementOfMostRecentTenancyQueryHookResult = ReturnType<
  typeof useGetAgreementOfMostRecentTenancyQuery
>;
export type GetAgreementOfMostRecentTenancyLazyQueryHookResult = ReturnType<
  typeof useGetAgreementOfMostRecentTenancyLazyQuery
>;
export type GetAgreementOfMostRecentTenancyQueryResult = Apollo.QueryResult<
  GetAgreementOfMostRecentTenancyQuery,
  GetAgreementOfMostRecentTenancyQueryVariables
>;
export const GetArrearsAmountDocument = gql`
  query getArrearsAmount($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        liability {
          reference
          amountInArrears {
            cents
          }
        }
      }
    }
  }
`;

/**
 * __useGetArrearsAmountQuery__
 *
 * To run a query within a React component, call `useGetArrearsAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArrearsAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArrearsAmountQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetArrearsAmountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetArrearsAmountQuery,
    GetArrearsAmountQueryVariables
  >
) {
  return Apollo.useQuery<GetArrearsAmountQuery, GetArrearsAmountQueryVariables>(
    GetArrearsAmountDocument,
    baseOptions
  );
}
export function useGetArrearsAmountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArrearsAmountQuery,
    GetArrearsAmountQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetArrearsAmountQuery,
    GetArrearsAmountQueryVariables
  >(GetArrearsAmountDocument, baseOptions);
}
export type GetArrearsAmountQueryHookResult = ReturnType<
  typeof useGetArrearsAmountQuery
>;
export type GetArrearsAmountLazyQueryHookResult = ReturnType<
  typeof useGetArrearsAmountLazyQuery
>;
export type GetArrearsAmountQueryResult = Apollo.QueryResult<
  GetArrearsAmountQuery,
  GetArrearsAmountQueryVariables
>;
export const GetArrearsAmountFromOwingDocument = gql`
  query getArrearsAmountFromOwing($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        rent {
          id
          progress {
            arrears {
              amount {
                total {
                  cents
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetArrearsAmountFromOwingQuery__
 *
 * To run a query within a React component, call `useGetArrearsAmountFromOwingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArrearsAmountFromOwingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArrearsAmountFromOwingQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetArrearsAmountFromOwingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetArrearsAmountFromOwingQuery,
    GetArrearsAmountFromOwingQueryVariables
  >
) {
  return Apollo.useQuery<
    GetArrearsAmountFromOwingQuery,
    GetArrearsAmountFromOwingQueryVariables
  >(GetArrearsAmountFromOwingDocument, baseOptions);
}
export function useGetArrearsAmountFromOwingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArrearsAmountFromOwingQuery,
    GetArrearsAmountFromOwingQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetArrearsAmountFromOwingQuery,
    GetArrearsAmountFromOwingQueryVariables
  >(GetArrearsAmountFromOwingDocument, baseOptions);
}
export type GetArrearsAmountFromOwingQueryHookResult = ReturnType<
  typeof useGetArrearsAmountFromOwingQuery
>;
export type GetArrearsAmountFromOwingLazyQueryHookResult = ReturnType<
  typeof useGetArrearsAmountFromOwingLazyQuery
>;
export type GetArrearsAmountFromOwingQueryResult = Apollo.QueryResult<
  GetArrearsAmountFromOwingQuery,
  GetArrearsAmountFromOwingQueryVariables
>;
export const GetBalanceOwedDocument = gql`
  query getBalanceOwed($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        liability {
          reference
          overdueAmount {
            cents
          }
        }
      }
    }
  }
`;

/**
 * __useGetBalanceOwedQuery__
 *
 * To run a query within a React component, call `useGetBalanceOwedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBalanceOwedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBalanceOwedQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetBalanceOwedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBalanceOwedQuery,
    GetBalanceOwedQueryVariables
  >
) {
  return Apollo.useQuery<GetBalanceOwedQuery, GetBalanceOwedQueryVariables>(
    GetBalanceOwedDocument,
    baseOptions
  );
}
export function useGetBalanceOwedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBalanceOwedQuery,
    GetBalanceOwedQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetBalanceOwedQuery, GetBalanceOwedQueryVariables>(
    GetBalanceOwedDocument,
    baseOptions
  );
}
export type GetBalanceOwedQueryHookResult = ReturnType<
  typeof useGetBalanceOwedQuery
>;
export type GetBalanceOwedLazyQueryHookResult = ReturnType<
  typeof useGetBalanceOwedLazyQuery
>;
export type GetBalanceOwedQueryResult = Apollo.QueryResult<
  GetBalanceOwedQuery,
  GetBalanceOwedQueryVariables
>;
export const GetBalanceOwedFromOwingDocument = gql`
  query getBalanceOwedFromOwing($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        rent {
          id
          progress {
            overdue {
              amount {
                total {
                  cents
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetBalanceOwedFromOwingQuery__
 *
 * To run a query within a React component, call `useGetBalanceOwedFromOwingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBalanceOwedFromOwingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBalanceOwedFromOwingQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetBalanceOwedFromOwingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBalanceOwedFromOwingQuery,
    GetBalanceOwedFromOwingQueryVariables
  >
) {
  return Apollo.useQuery<
    GetBalanceOwedFromOwingQuery,
    GetBalanceOwedFromOwingQueryVariables
  >(GetBalanceOwedFromOwingDocument, baseOptions);
}
export function useGetBalanceOwedFromOwingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBalanceOwedFromOwingQuery,
    GetBalanceOwedFromOwingQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetBalanceOwedFromOwingQuery,
    GetBalanceOwedFromOwingQueryVariables
  >(GetBalanceOwedFromOwingDocument, baseOptions);
}
export type GetBalanceOwedFromOwingQueryHookResult = ReturnType<
  typeof useGetBalanceOwedFromOwingQuery
>;
export type GetBalanceOwedFromOwingLazyQueryHookResult = ReturnType<
  typeof useGetBalanceOwedFromOwingLazyQuery
>;
export type GetBalanceOwedFromOwingQueryResult = Apollo.QueryResult<
  GetBalanceOwedFromOwingQuery,
  GetBalanceOwedFromOwingQueryVariables
>;
export const GetDaysInArrearsDocument = gql`
  query getDaysInArrears($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        liability {
          reference
          daysInArrears
        }
      }
    }
  }
`;

/**
 * __useGetDaysInArrearsQuery__
 *
 * To run a query within a React component, call `useGetDaysInArrearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDaysInArrearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDaysInArrearsQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetDaysInArrearsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDaysInArrearsQuery,
    GetDaysInArrearsQueryVariables
  >
) {
  return Apollo.useQuery<GetDaysInArrearsQuery, GetDaysInArrearsQueryVariables>(
    GetDaysInArrearsDocument,
    baseOptions
  );
}
export function useGetDaysInArrearsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDaysInArrearsQuery,
    GetDaysInArrearsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetDaysInArrearsQuery,
    GetDaysInArrearsQueryVariables
  >(GetDaysInArrearsDocument, baseOptions);
}
export type GetDaysInArrearsQueryHookResult = ReturnType<
  typeof useGetDaysInArrearsQuery
>;
export type GetDaysInArrearsLazyQueryHookResult = ReturnType<
  typeof useGetDaysInArrearsLazyQuery
>;
export type GetDaysInArrearsQueryResult = Apollo.QueryResult<
  GetDaysInArrearsQuery,
  GetDaysInArrearsQueryVariables
>;
export const GetDaysInArrearsFromOwingDocument = gql`
  query getDaysInArrearsFromOwing($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        rent {
          id
          progress {
            arrears {
              date
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetDaysInArrearsFromOwingQuery__
 *
 * To run a query within a React component, call `useGetDaysInArrearsFromOwingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDaysInArrearsFromOwingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDaysInArrearsFromOwingQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetDaysInArrearsFromOwingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDaysInArrearsFromOwingQuery,
    GetDaysInArrearsFromOwingQueryVariables
  >
) {
  return Apollo.useQuery<
    GetDaysInArrearsFromOwingQuery,
    GetDaysInArrearsFromOwingQueryVariables
  >(GetDaysInArrearsFromOwingDocument, baseOptions);
}
export function useGetDaysInArrearsFromOwingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDaysInArrearsFromOwingQuery,
    GetDaysInArrearsFromOwingQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetDaysInArrearsFromOwingQuery,
    GetDaysInArrearsFromOwingQueryVariables
  >(GetDaysInArrearsFromOwingDocument, baseOptions);
}
export type GetDaysInArrearsFromOwingQueryHookResult = ReturnType<
  typeof useGetDaysInArrearsFromOwingQuery
>;
export type GetDaysInArrearsFromOwingLazyQueryHookResult = ReturnType<
  typeof useGetDaysInArrearsFromOwingLazyQuery
>;
export type GetDaysInArrearsFromOwingQueryResult = Apollo.QueryResult<
  GetDaysInArrearsFromOwingQuery,
  GetDaysInArrearsFromOwingQueryVariables
>;
export const GetEndDateDocument = gql`
  query getEndDate($managementId: ID!) {
    management(managementId: $managementId) {
      id
      endDate
    }
  }
`;

/**
 * __useGetEndDateQuery__
 *
 * To run a query within a React component, call `useGetEndDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndDateQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetEndDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEndDateQuery,
    GetEndDateQueryVariables
  >
) {
  return Apollo.useQuery<GetEndDateQuery, GetEndDateQueryVariables>(
    GetEndDateDocument,
    baseOptions
  );
}
export function useGetEndDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEndDateQuery,
    GetEndDateQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetEndDateQuery, GetEndDateQueryVariables>(
    GetEndDateDocument,
    baseOptions
  );
}
export type GetEndDateQueryHookResult = ReturnType<typeof useGetEndDateQuery>;
export type GetEndDateLazyQueryHookResult = ReturnType<
  typeof useGetEndDateLazyQuery
>;
export type GetEndDateQueryResult = Apollo.QueryResult<
  GetEndDateQuery,
  GetEndDateQueryVariables
>;
export const GetEndReasonDocument = gql`
  query getEndReason($managementId: ID!) {
    management(managementId: $managementId) {
      id
      ...ManagementEndDetails
    }
  }
  ${ManagementEndDetailsFragmentDoc}
`;

/**
 * __useGetEndReasonQuery__
 *
 * To run a query within a React component, call `useGetEndReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndReasonQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetEndReasonQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEndReasonQuery,
    GetEndReasonQueryVariables
  >
) {
  return Apollo.useQuery<GetEndReasonQuery, GetEndReasonQueryVariables>(
    GetEndReasonDocument,
    baseOptions
  );
}
export function useGetEndReasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEndReasonQuery,
    GetEndReasonQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetEndReasonQuery, GetEndReasonQueryVariables>(
    GetEndReasonDocument,
    baseOptions
  );
}
export type GetEndReasonQueryHookResult = ReturnType<
  typeof useGetEndReasonQuery
>;
export type GetEndReasonLazyQueryHookResult = ReturnType<
  typeof useGetEndReasonLazyQuery
>;
export type GetEndReasonQueryResult = Apollo.QueryResult<
  GetEndReasonQuery,
  GetEndReasonQueryVariables
>;
export const GetManagementRentDocument = gql`
  query getManagementRent($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        ...DisplayRent
      }
    }
  }
  ${DisplayRentFragmentDoc}
`;

/**
 * __useGetManagementRentQuery__
 *
 * To run a query within a React component, call `useGetManagementRentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementRentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementRentQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetManagementRentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementRentQuery,
    GetManagementRentQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementRentQuery,
    GetManagementRentQueryVariables
  >(GetManagementRentDocument, baseOptions);
}
export function useGetManagementRentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementRentQuery,
    GetManagementRentQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementRentQuery,
    GetManagementRentQueryVariables
  >(GetManagementRentDocument, baseOptions);
}
export type GetManagementRentQueryHookResult = ReturnType<
  typeof useGetManagementRentQuery
>;
export type GetManagementRentLazyQueryHookResult = ReturnType<
  typeof useGetManagementRentLazyQuery
>;
export type GetManagementRentQueryResult = Apollo.QueryResult<
  GetManagementRentQuery,
  GetManagementRentQueryVariables
>;
export const GetManagementTeamDocument = gql`
  query getManagementTeam($managementId: ID!) {
    management(managementId: $managementId) {
      id
      team {
        id
        name
      }
    }
  }
`;

/**
 * __useGetManagementTeamQuery__
 *
 * To run a query within a React component, call `useGetManagementTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementTeamQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetManagementTeamQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementTeamQuery,
    GetManagementTeamQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementTeamQuery,
    GetManagementTeamQueryVariables
  >(GetManagementTeamDocument, baseOptions);
}
export function useGetManagementTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementTeamQuery,
    GetManagementTeamQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementTeamQuery,
    GetManagementTeamQueryVariables
  >(GetManagementTeamDocument, baseOptions);
}
export type GetManagementTeamQueryHookResult = ReturnType<
  typeof useGetManagementTeamQuery
>;
export type GetManagementTeamLazyQueryHookResult = ReturnType<
  typeof useGetManagementTeamLazyQuery
>;
export type GetManagementTeamQueryResult = Apollo.QueryResult<
  GetManagementTeamQuery,
  GetManagementTeamQueryVariables
>;
export const GetOccupancyStatusDocument = gql`
  query getOccupancyStatus($managementId: ID!) {
    management(managementId: $managementId) {
      id
      occupancyStatus
      nextTenancy {
        id
        startDate
      }
    }
  }
`;

/**
 * __useGetOccupancyStatusQuery__
 *
 * To run a query within a React component, call `useGetOccupancyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOccupancyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOccupancyStatusQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetOccupancyStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOccupancyStatusQuery,
    GetOccupancyStatusQueryVariables
  >
) {
  return Apollo.useQuery<
    GetOccupancyStatusQuery,
    GetOccupancyStatusQueryVariables
  >(GetOccupancyStatusDocument, baseOptions);
}
export function useGetOccupancyStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOccupancyStatusQuery,
    GetOccupancyStatusQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetOccupancyStatusQuery,
    GetOccupancyStatusQueryVariables
  >(GetOccupancyStatusDocument, baseOptions);
}
export type GetOccupancyStatusQueryHookResult = ReturnType<
  typeof useGetOccupancyStatusQuery
>;
export type GetOccupancyStatusLazyQueryHookResult = ReturnType<
  typeof useGetOccupancyStatusLazyQuery
>;
export type GetOccupancyStatusQueryResult = Apollo.QueryResult<
  GetOccupancyStatusQuery,
  GetOccupancyStatusQueryVariables
>;
export const GetOverdueDateDocument = gql`
  query getOverdueDate($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        liability {
          reference
          dueDate
          overdueAmount {
            cents
          }
        }
      }
    }
  }
`;

/**
 * __useGetOverdueDateQuery__
 *
 * To run a query within a React component, call `useGetOverdueDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverdueDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverdueDateQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetOverdueDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOverdueDateQuery,
    GetOverdueDateQueryVariables
  >
) {
  return Apollo.useQuery<GetOverdueDateQuery, GetOverdueDateQueryVariables>(
    GetOverdueDateDocument,
    baseOptions
  );
}
export function useGetOverdueDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOverdueDateQuery,
    GetOverdueDateQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetOverdueDateQuery, GetOverdueDateQueryVariables>(
    GetOverdueDateDocument,
    baseOptions
  );
}
export type GetOverdueDateQueryHookResult = ReturnType<
  typeof useGetOverdueDateQuery
>;
export type GetOverdueDateLazyQueryHookResult = ReturnType<
  typeof useGetOverdueDateLazyQuery
>;
export type GetOverdueDateQueryResult = Apollo.QueryResult<
  GetOverdueDateQuery,
  GetOverdueDateQueryVariables
>;
export const GetOverdueDateFromOwingDocument = gql`
  query getOverdueDateFromOwing($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        rent {
          id
          progress {
            overdue {
              date
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetOverdueDateFromOwingQuery__
 *
 * To run a query within a React component, call `useGetOverdueDateFromOwingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverdueDateFromOwingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverdueDateFromOwingQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetOverdueDateFromOwingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOverdueDateFromOwingQuery,
    GetOverdueDateFromOwingQueryVariables
  >
) {
  return Apollo.useQuery<
    GetOverdueDateFromOwingQuery,
    GetOverdueDateFromOwingQueryVariables
  >(GetOverdueDateFromOwingDocument, baseOptions);
}
export function useGetOverdueDateFromOwingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOverdueDateFromOwingQuery,
    GetOverdueDateFromOwingQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetOverdueDateFromOwingQuery,
    GetOverdueDateFromOwingQueryVariables
  >(GetOverdueDateFromOwingDocument, baseOptions);
}
export type GetOverdueDateFromOwingQueryHookResult = ReturnType<
  typeof useGetOverdueDateFromOwingQuery
>;
export type GetOverdueDateFromOwingLazyQueryHookResult = ReturnType<
  typeof useGetOverdueDateFromOwingLazyQuery
>;
export type GetOverdueDateFromOwingQueryResult = Apollo.QueryResult<
  GetOverdueDateFromOwingQuery,
  GetOverdueDateFromOwingQueryVariables
>;
export const GetPaidToDateDocument = gql`
  query getPaidToDate($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        endDate
        liability {
          reference
          effectivePaidToDate
        }
      }
    }
  }
`;

/**
 * __useGetPaidToDateQuery__
 *
 * To run a query within a React component, call `useGetPaidToDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaidToDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaidToDateQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetPaidToDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaidToDateQuery,
    GetPaidToDateQueryVariables
  >
) {
  return Apollo.useQuery<GetPaidToDateQuery, GetPaidToDateQueryVariables>(
    GetPaidToDateDocument,
    baseOptions
  );
}
export function useGetPaidToDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaidToDateQuery,
    GetPaidToDateQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetPaidToDateQuery, GetPaidToDateQueryVariables>(
    GetPaidToDateDocument,
    baseOptions
  );
}
export type GetPaidToDateQueryHookResult = ReturnType<
  typeof useGetPaidToDateQuery
>;
export type GetPaidToDateLazyQueryHookResult = ReturnType<
  typeof useGetPaidToDateLazyQuery
>;
export type GetPaidToDateQueryResult = Apollo.QueryResult<
  GetPaidToDateQuery,
  GetPaidToDateQueryVariables
>;
export const GetPaidToDateFromOwingDocument = gql`
  query getPaidToDateFromOwing($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        endDate
        rent {
          id
          progress {
            paidTo {
              effective {
                date
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetPaidToDateFromOwingQuery__
 *
 * To run a query within a React component, call `useGetPaidToDateFromOwingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaidToDateFromOwingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaidToDateFromOwingQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetPaidToDateFromOwingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaidToDateFromOwingQuery,
    GetPaidToDateFromOwingQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaidToDateFromOwingQuery,
    GetPaidToDateFromOwingQueryVariables
  >(GetPaidToDateFromOwingDocument, baseOptions);
}
export function useGetPaidToDateFromOwingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaidToDateFromOwingQuery,
    GetPaidToDateFromOwingQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaidToDateFromOwingQuery,
    GetPaidToDateFromOwingQueryVariables
  >(GetPaidToDateFromOwingDocument, baseOptions);
}
export type GetPaidToDateFromOwingQueryHookResult = ReturnType<
  typeof useGetPaidToDateFromOwingQuery
>;
export type GetPaidToDateFromOwingLazyQueryHookResult = ReturnType<
  typeof useGetPaidToDateFromOwingLazyQuery
>;
export type GetPaidToDateFromOwingQueryResult = Apollo.QueryResult<
  GetPaidToDateFromOwingQuery,
  GetPaidToDateFromOwingQueryVariables
>;
export const GetScheduledRentReviewDateDocument = gql`
  query getScheduledRentReviewDate($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        scheduledRentReviewDate
      }
    }
  }
`;

/**
 * __useGetScheduledRentReviewDateQuery__
 *
 * To run a query within a React component, call `useGetScheduledRentReviewDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledRentReviewDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledRentReviewDateQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetScheduledRentReviewDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScheduledRentReviewDateQuery,
    GetScheduledRentReviewDateQueryVariables
  >
) {
  return Apollo.useQuery<
    GetScheduledRentReviewDateQuery,
    GetScheduledRentReviewDateQueryVariables
  >(GetScheduledRentReviewDateDocument, baseOptions);
}
export function useGetScheduledRentReviewDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScheduledRentReviewDateQuery,
    GetScheduledRentReviewDateQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetScheduledRentReviewDateQuery,
    GetScheduledRentReviewDateQueryVariables
  >(GetScheduledRentReviewDateDocument, baseOptions);
}
export type GetScheduledRentReviewDateQueryHookResult = ReturnType<
  typeof useGetScheduledRentReviewDateQuery
>;
export type GetScheduledRentReviewDateLazyQueryHookResult = ReturnType<
  typeof useGetScheduledRentReviewDateLazyQuery
>;
export type GetScheduledRentReviewDateQueryResult = Apollo.QueryResult<
  GetScheduledRentReviewDateQuery,
  GetScheduledRentReviewDateQueryVariables
>;
export const GetPagedActiveManagementsDocument = gql`
  query getPagedActiveManagements(
    $organisationId: AiloRN!
    $pageCursor: ManagementCursor!
    $filters: [ManagementFilterParams!]!
  ) {
    managementsForOrg(
      organisationId: $organisationId
      pageCursor: $pageCursor
      filters: $filters
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
        hasPrevious
        previousCursor
      }
      items {
        id
        ...ManagementProperty
        occupancyStatus
        startDate
        mostRecentTenancy {
          id
          endDate
          mostRecentTenancyAgreement {
            ...TenancyAgreement
          }
        }
        nextTenancy {
          id
          startDate
        }
        team {
          id
          name
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
  ${TenancyAgreementFragmentDoc}
`;

/**
 * __useGetPagedActiveManagementsQuery__
 *
 * To run a query within a React component, call `useGetPagedActiveManagementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedActiveManagementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedActiveManagementsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      pageCursor: // value for 'pageCursor'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPagedActiveManagementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagedActiveManagementsQuery,
    GetPagedActiveManagementsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPagedActiveManagementsQuery,
    GetPagedActiveManagementsQueryVariables
  >(GetPagedActiveManagementsDocument, baseOptions);
}
export function useGetPagedActiveManagementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagedActiveManagementsQuery,
    GetPagedActiveManagementsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPagedActiveManagementsQuery,
    GetPagedActiveManagementsQueryVariables
  >(GetPagedActiveManagementsDocument, baseOptions);
}
export type GetPagedActiveManagementsQueryHookResult = ReturnType<
  typeof useGetPagedActiveManagementsQuery
>;
export type GetPagedActiveManagementsLazyQueryHookResult = ReturnType<
  typeof useGetPagedActiveManagementsLazyQuery
>;
export type GetPagedActiveManagementsQueryResult = Apollo.QueryResult<
  GetPagedActiveManagementsQuery,
  GetPagedActiveManagementsQueryVariables
>;
export const GetPagedLostManagementsDocument = gql`
  query getPagedLostManagements(
    $organisationId: AiloRN!
    $pageCursor: ManagementCursor!
    $filters: [ManagementFilterParams!]!
  ) {
    managementsForOrg(
      organisationId: $organisationId
      pageCursor: $pageCursor
      filters: $filters
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
        hasPrevious
        previousCursor
      }
      items {
        id
        ...ManagementEndDetails
        ...ManagementProperty
      }
    }
  }
  ${ManagementEndDetailsFragmentDoc}
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useGetPagedLostManagementsQuery__
 *
 * To run a query within a React component, call `useGetPagedLostManagementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedLostManagementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedLostManagementsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      pageCursor: // value for 'pageCursor'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPagedLostManagementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagedLostManagementsQuery,
    GetPagedLostManagementsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPagedLostManagementsQuery,
    GetPagedLostManagementsQueryVariables
  >(GetPagedLostManagementsDocument, baseOptions);
}
export function useGetPagedLostManagementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagedLostManagementsQuery,
    GetPagedLostManagementsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPagedLostManagementsQuery,
    GetPagedLostManagementsQueryVariables
  >(GetPagedLostManagementsDocument, baseOptions);
}
export type GetPagedLostManagementsQueryHookResult = ReturnType<
  typeof useGetPagedLostManagementsQuery
>;
export type GetPagedLostManagementsLazyQueryHookResult = ReturnType<
  typeof useGetPagedLostManagementsLazyQuery
>;
export type GetPagedLostManagementsQueryResult = Apollo.QueryResult<
  GetPagedLostManagementsQuery,
  GetPagedLostManagementsQueryVariables
>;
export const GetPagedManagementsDueForRenewalsDocument = gql`
  query getPagedManagementsDueForRenewals(
    $organisationId: AiloRN!
    $pageCursor: ManagementCursor!
    $filters: [ManagementFilterParams!]!
  ) {
    managementsForOrg(
      organisationId: $organisationId
      pageCursor: $pageCursor
      filters: $filters
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
        hasPrevious
        previousCursor
      }
      items {
        id
        ...ManagementProperty
        occupancyStatus
        mostRecentTenancy {
          id
          scheduledRentReviewDate
          ...DisplayRent
          latestTenancyAgreement {
            ...TenancyAgreement
          }
        }
        nextTenancy {
          id
          startDate
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
  ${DisplayRentFragmentDoc}
  ${TenancyAgreementFragmentDoc}
`;

/**
 * __useGetPagedManagementsDueForRenewalsQuery__
 *
 * To run a query within a React component, call `useGetPagedManagementsDueForRenewalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedManagementsDueForRenewalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedManagementsDueForRenewalsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      pageCursor: // value for 'pageCursor'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPagedManagementsDueForRenewalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagedManagementsDueForRenewalsQuery,
    GetPagedManagementsDueForRenewalsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPagedManagementsDueForRenewalsQuery,
    GetPagedManagementsDueForRenewalsQueryVariables
  >(GetPagedManagementsDueForRenewalsDocument, baseOptions);
}
export function useGetPagedManagementsDueForRenewalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagedManagementsDueForRenewalsQuery,
    GetPagedManagementsDueForRenewalsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPagedManagementsDueForRenewalsQuery,
    GetPagedManagementsDueForRenewalsQueryVariables
  >(GetPagedManagementsDueForRenewalsDocument, baseOptions);
}
export type GetPagedManagementsDueForRenewalsQueryHookResult = ReturnType<
  typeof useGetPagedManagementsDueForRenewalsQuery
>;
export type GetPagedManagementsDueForRenewalsLazyQueryHookResult = ReturnType<
  typeof useGetPagedManagementsDueForRenewalsLazyQuery
>;
export type GetPagedManagementsDueForRenewalsQueryResult = Apollo.QueryResult<
  GetPagedManagementsDueForRenewalsQuery,
  GetPagedManagementsDueForRenewalsQueryVariables
>;
export const GetPagedManagementsWithTenancyInArrearsDocument = gql`
  query getPagedManagementsWithTenancyInArrears(
    $organisationId: AiloRN!
    $pageCursor: ManagementCursor!
    $filters: [ManagementFilterParams!]!
  ) {
    managementsForOrg(
      organisationId: $organisationId
      pageCursor: $pageCursor
      filters: $filters
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
        hasPrevious
        previousCursor
      }
      items {
        id
        ...ManagementProperty
        mostRecentTenancy {
          ...TenancyInArrears
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
  ${TenancyInArrearsFragmentDoc}
`;

/**
 * __useGetPagedManagementsWithTenancyInArrearsQuery__
 *
 * To run a query within a React component, call `useGetPagedManagementsWithTenancyInArrearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedManagementsWithTenancyInArrearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedManagementsWithTenancyInArrearsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      pageCursor: // value for 'pageCursor'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPagedManagementsWithTenancyInArrearsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagedManagementsWithTenancyInArrearsQuery,
    GetPagedManagementsWithTenancyInArrearsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPagedManagementsWithTenancyInArrearsQuery,
    GetPagedManagementsWithTenancyInArrearsQueryVariables
  >(GetPagedManagementsWithTenancyInArrearsDocument, baseOptions);
}
export function useGetPagedManagementsWithTenancyInArrearsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagedManagementsWithTenancyInArrearsQuery,
    GetPagedManagementsWithTenancyInArrearsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPagedManagementsWithTenancyInArrearsQuery,
    GetPagedManagementsWithTenancyInArrearsQueryVariables
  >(GetPagedManagementsWithTenancyInArrearsDocument, baseOptions);
}
export type GetPagedManagementsWithTenancyInArrearsQueryHookResult = ReturnType<
  typeof useGetPagedManagementsWithTenancyInArrearsQuery
>;
export type GetPagedManagementsWithTenancyInArrearsLazyQueryHookResult =
  ReturnType<typeof useGetPagedManagementsWithTenancyInArrearsLazyQuery>;
export type GetPagedManagementsWithTenancyInArrearsQueryResult =
  Apollo.QueryResult<
    GetPagedManagementsWithTenancyInArrearsQuery,
    GetPagedManagementsWithTenancyInArrearsQueryVariables
  >;
export const GetPagedManagementsWithTenancyInArrearsFromOwingDocument = gql`
  query getPagedManagementsWithTenancyInArrearsFromOwing(
    $organisationId: AiloRN!
    $pageCursor: ManagementCursor!
    $filters: [ManagementFilterParams!]!
  ) {
    managementsForOrg(
      organisationId: $organisationId
      pageCursor: $pageCursor
      filters: $filters
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
        hasPrevious
        previousCursor
      }
      items {
        id
        ...ManagementProperty
        mostRecentTenancy {
          ...TenancyInArrearsFromOwing
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
  ${TenancyInArrearsFromOwingFragmentDoc}
`;

/**
 * __useGetPagedManagementsWithTenancyInArrearsFromOwingQuery__
 *
 * To run a query within a React component, call `useGetPagedManagementsWithTenancyInArrearsFromOwingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedManagementsWithTenancyInArrearsFromOwingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedManagementsWithTenancyInArrearsFromOwingQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      pageCursor: // value for 'pageCursor'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPagedManagementsWithTenancyInArrearsFromOwingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagedManagementsWithTenancyInArrearsFromOwingQuery,
    GetPagedManagementsWithTenancyInArrearsFromOwingQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPagedManagementsWithTenancyInArrearsFromOwingQuery,
    GetPagedManagementsWithTenancyInArrearsFromOwingQueryVariables
  >(GetPagedManagementsWithTenancyInArrearsFromOwingDocument, baseOptions);
}
export function useGetPagedManagementsWithTenancyInArrearsFromOwingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagedManagementsWithTenancyInArrearsFromOwingQuery,
    GetPagedManagementsWithTenancyInArrearsFromOwingQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPagedManagementsWithTenancyInArrearsFromOwingQuery,
    GetPagedManagementsWithTenancyInArrearsFromOwingQueryVariables
  >(GetPagedManagementsWithTenancyInArrearsFromOwingDocument, baseOptions);
}
export type GetPagedManagementsWithTenancyInArrearsFromOwingQueryHookResult =
  ReturnType<typeof useGetPagedManagementsWithTenancyInArrearsFromOwingQuery>;
export type GetPagedManagementsWithTenancyInArrearsFromOwingLazyQueryHookResult =
  ReturnType<
    typeof useGetPagedManagementsWithTenancyInArrearsFromOwingLazyQuery
  >;
export type GetPagedManagementsWithTenancyInArrearsFromOwingQueryResult =
  Apollo.QueryResult<
    GetPagedManagementsWithTenancyInArrearsFromOwingQuery,
    GetPagedManagementsWithTenancyInArrearsFromOwingQueryVariables
  >;
export const GetPagedOverdueManagementsDocument = gql`
  query getPagedOverdueManagements(
    $organisationId: AiloRN!
    $pageCursor: ManagementCursor!
    $filters: [ManagementFilterParams!]!
  ) {
    managementsForOrg(
      organisationId: $organisationId
      pageCursor: $pageCursor
      filters: $filters
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
        hasPrevious
        previousCursor
      }
      items {
        id
        ...ManagementProperty
        mostRecentTenancy {
          id
          endDate
          ...DisplayRent
          liability {
            reference
            effectivePaidToDate
            dueDate
            overdueAmount {
              cents
            }
          }
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
  ${DisplayRentFragmentDoc}
`;

/**
 * __useGetPagedOverdueManagementsQuery__
 *
 * To run a query within a React component, call `useGetPagedOverdueManagementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedOverdueManagementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedOverdueManagementsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      pageCursor: // value for 'pageCursor'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPagedOverdueManagementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagedOverdueManagementsQuery,
    GetPagedOverdueManagementsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPagedOverdueManagementsQuery,
    GetPagedOverdueManagementsQueryVariables
  >(GetPagedOverdueManagementsDocument, baseOptions);
}
export function useGetPagedOverdueManagementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagedOverdueManagementsQuery,
    GetPagedOverdueManagementsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPagedOverdueManagementsQuery,
    GetPagedOverdueManagementsQueryVariables
  >(GetPagedOverdueManagementsDocument, baseOptions);
}
export type GetPagedOverdueManagementsQueryHookResult = ReturnType<
  typeof useGetPagedOverdueManagementsQuery
>;
export type GetPagedOverdueManagementsLazyQueryHookResult = ReturnType<
  typeof useGetPagedOverdueManagementsLazyQuery
>;
export type GetPagedOverdueManagementsQueryResult = Apollo.QueryResult<
  GetPagedOverdueManagementsQuery,
  GetPagedOverdueManagementsQueryVariables
>;
export const GetPagedOverdueManagementsFromOwingDocument = gql`
  query getPagedOverdueManagementsFromOwing(
    $organisationId: AiloRN!
    $pageCursor: ManagementCursor!
    $filters: [ManagementFilterParams!]!
  ) {
    managementsForOrg(
      organisationId: $organisationId
      pageCursor: $pageCursor
      filters: $filters
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
        hasPrevious
        previousCursor
      }
      items {
        id
        ...ManagementProperty
        mostRecentTenancy {
          id
          endDate
          ...DisplayRent
          rent {
            id
            progress {
              overdue {
                amount {
                  total {
                    cents
                  }
                }
                date
              }
              paidTo {
                effective {
                  date
                }
              }
            }
          }
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
  ${DisplayRentFragmentDoc}
`;

/**
 * __useGetPagedOverdueManagementsFromOwingQuery__
 *
 * To run a query within a React component, call `useGetPagedOverdueManagementsFromOwingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedOverdueManagementsFromOwingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedOverdueManagementsFromOwingQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      pageCursor: // value for 'pageCursor'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPagedOverdueManagementsFromOwingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagedOverdueManagementsFromOwingQuery,
    GetPagedOverdueManagementsFromOwingQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPagedOverdueManagementsFromOwingQuery,
    GetPagedOverdueManagementsFromOwingQueryVariables
  >(GetPagedOverdueManagementsFromOwingDocument, baseOptions);
}
export function useGetPagedOverdueManagementsFromOwingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagedOverdueManagementsFromOwingQuery,
    GetPagedOverdueManagementsFromOwingQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPagedOverdueManagementsFromOwingQuery,
    GetPagedOverdueManagementsFromOwingQueryVariables
  >(GetPagedOverdueManagementsFromOwingDocument, baseOptions);
}
export type GetPagedOverdueManagementsFromOwingQueryHookResult = ReturnType<
  typeof useGetPagedOverdueManagementsFromOwingQuery
>;
export type GetPagedOverdueManagementsFromOwingLazyQueryHookResult = ReturnType<
  typeof useGetPagedOverdueManagementsFromOwingLazyQuery
>;
export type GetPagedOverdueManagementsFromOwingQueryResult = Apollo.QueryResult<
  GetPagedOverdueManagementsFromOwingQuery,
  GetPagedOverdueManagementsFromOwingQueryVariables
>;
export const GetManagementsForOrgCountDocument = gql`
  query getManagementsForOrgCount(
    $organisationId: AiloRN!
    $pageCursor: ManagementCursor!
    $filters: [ManagementFilterParams!]!
  ) {
    managementsForOrg(
      organisationId: $organisationId
      pageCursor: $pageCursor
      filters: $filters
    ) {
      pageInfo {
        total
      }
    }
  }
`;

/**
 * __useGetManagementsForOrgCountQuery__
 *
 * To run a query within a React component, call `useGetManagementsForOrgCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementsForOrgCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementsForOrgCountQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      pageCursor: // value for 'pageCursor'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetManagementsForOrgCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementsForOrgCountQuery,
    GetManagementsForOrgCountQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementsForOrgCountQuery,
    GetManagementsForOrgCountQueryVariables
  >(GetManagementsForOrgCountDocument, baseOptions);
}
export function useGetManagementsForOrgCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementsForOrgCountQuery,
    GetManagementsForOrgCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementsForOrgCountQuery,
    GetManagementsForOrgCountQueryVariables
  >(GetManagementsForOrgCountDocument, baseOptions);
}
export type GetManagementsForOrgCountQueryHookResult = ReturnType<
  typeof useGetManagementsForOrgCountQuery
>;
export type GetManagementsForOrgCountLazyQueryHookResult = ReturnType<
  typeof useGetManagementsForOrgCountLazyQuery
>;
export type GetManagementsForOrgCountQueryResult = Apollo.QueryResult<
  GetManagementsForOrgCountQuery,
  GetManagementsForOrgCountQueryVariables
>;
export const CreateTenancyBondDocument = gql`
  mutation createTenancyBond($bondDetails: CreateBondInput!) {
    createTenancyBond(bondDetails: $bondDetails) {
      id
      amount {
        cents
      }
      reference
    }
  }
`;
export type CreateTenancyBondMutationFn = Apollo.MutationFunction<
  CreateTenancyBondMutation,
  CreateTenancyBondMutationVariables
>;

/**
 * __useCreateTenancyBondMutation__
 *
 * To run a mutation, you first call `useCreateTenancyBondMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenancyBondMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenancyBondMutation, { data, loading, error }] = useCreateTenancyBondMutation({
 *   variables: {
 *      bondDetails: // value for 'bondDetails'
 *   },
 * });
 */
export function useCreateTenancyBondMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTenancyBondMutation,
    CreateTenancyBondMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateTenancyBondMutation,
    CreateTenancyBondMutationVariables
  >(CreateTenancyBondDocument, baseOptions);
}
export type CreateTenancyBondMutationHookResult = ReturnType<
  typeof useCreateTenancyBondMutation
>;
export type CreateTenancyBondMutationResult =
  Apollo.MutationResult<CreateTenancyBondMutation>;
export type CreateTenancyBondMutationOptions = Apollo.BaseMutationOptions<
  CreateTenancyBondMutation,
  CreateTenancyBondMutationVariables
>;
export const UpdateTenancyBondDocument = gql`
  mutation updateTenancyBond($bondDetails: UpdateBondInput!) {
    updateTenancyBond(bondDetails: $bondDetails) {
      id
      amount {
        cents
      }
      reference
    }
  }
`;
export type UpdateTenancyBondMutationFn = Apollo.MutationFunction<
  UpdateTenancyBondMutation,
  UpdateTenancyBondMutationVariables
>;

/**
 * __useUpdateTenancyBondMutation__
 *
 * To run a mutation, you first call `useUpdateTenancyBondMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenancyBondMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenancyBondMutation, { data, loading, error }] = useUpdateTenancyBondMutation({
 *   variables: {
 *      bondDetails: // value for 'bondDetails'
 *   },
 * });
 */
export function useUpdateTenancyBondMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTenancyBondMutation,
    UpdateTenancyBondMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateTenancyBondMutation,
    UpdateTenancyBondMutationVariables
  >(UpdateTenancyBondDocument, baseOptions);
}
export type UpdateTenancyBondMutationHookResult = ReturnType<
  typeof useUpdateTenancyBondMutation
>;
export type UpdateTenancyBondMutationResult =
  Apollo.MutationResult<UpdateTenancyBondMutation>;
export type UpdateTenancyBondMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenancyBondMutation,
  UpdateTenancyBondMutationVariables
>;
export const GetAgencyTrustAccountsDocument = gql`
  query getAgencyTrustAccounts($agency: AiloRN!) {
    getTrustAccounts(input: { agency: $agency }) {
      agency
      paymentMethod {
        id
        paymentMethod {
          id
          ... on BankAccount {
            accountNumber
            bsb
          }
        }
      }
    }
  }
`;

/**
 * __useGetAgencyTrustAccountsQuery__
 *
 * To run a query within a React component, call `useGetAgencyTrustAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyTrustAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyTrustAccountsQuery({
 *   variables: {
 *      agency: // value for 'agency'
 *   },
 * });
 */
export function useGetAgencyTrustAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgencyTrustAccountsQuery,
    GetAgencyTrustAccountsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAgencyTrustAccountsQuery,
    GetAgencyTrustAccountsQueryVariables
  >(GetAgencyTrustAccountsDocument, baseOptions);
}
export function useGetAgencyTrustAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgencyTrustAccountsQuery,
    GetAgencyTrustAccountsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAgencyTrustAccountsQuery,
    GetAgencyTrustAccountsQueryVariables
  >(GetAgencyTrustAccountsDocument, baseOptions);
}
export type GetAgencyTrustAccountsQueryHookResult = ReturnType<
  typeof useGetAgencyTrustAccountsQuery
>;
export type GetAgencyTrustAccountsLazyQueryHookResult = ReturnType<
  typeof useGetAgencyTrustAccountsLazyQuery
>;
export type GetAgencyTrustAccountsQueryResult = Apollo.QueryResult<
  GetAgencyTrustAccountsQuery,
  GetAgencyTrustAccountsQueryVariables
>;
export const GetTenantsAndPropertyDetailsDocument = gql`
  query getTenantsAndPropertyDetails($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      management {
        id
        managingEntity {
          id
          tenancyBondVirtualAccounts {
            id
            paymentMethodCompanion {
              id
              paymentMethod {
                id
                ... on VirtualAccount {
                  accountNumber
                  bsb
                }
              }
            }
          }
        }
      }
      tenantships {
        items {
          tenant {
            ... on Person {
              firstName
              lastName
            }
          }
        }
      }
      ...TenancyProperty
      bond {
        id
        amount {
          cents
        }
        reference
        status
      }
    }
  }
  ${TenancyPropertyFragmentDoc}
`;

/**
 * __useGetTenantsAndPropertyDetailsQuery__
 *
 * To run a query within a React component, call `useGetTenantsAndPropertyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsAndPropertyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsAndPropertyDetailsQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetTenantsAndPropertyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenantsAndPropertyDetailsQuery,
    GetTenantsAndPropertyDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenantsAndPropertyDetailsQuery,
    GetTenantsAndPropertyDetailsQueryVariables
  >(GetTenantsAndPropertyDetailsDocument, baseOptions);
}
export function useGetTenantsAndPropertyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantsAndPropertyDetailsQuery,
    GetTenantsAndPropertyDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenantsAndPropertyDetailsQuery,
    GetTenantsAndPropertyDetailsQueryVariables
  >(GetTenantsAndPropertyDetailsDocument, baseOptions);
}
export type GetTenantsAndPropertyDetailsQueryHookResult = ReturnType<
  typeof useGetTenantsAndPropertyDetailsQuery
>;
export type GetTenantsAndPropertyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetTenantsAndPropertyDetailsLazyQuery
>;
export type GetTenantsAndPropertyDetailsQueryResult = Apollo.QueryResult<
  GetTenantsAndPropertyDetailsQuery,
  GetTenantsAndPropertyDetailsQueryVariables
>;
export const GetAmountDueAtDateDocument = gql`
  query getAmountDueAtDate($liabilityId: ID!, $dateTime: DateTime!) {
    amountDueToDate(
      amountDueToDateInput: { liabilityId: $liabilityId, dateTime: $dateTime }
    ) {
      cents
    }
  }
`;

/**
 * __useGetAmountDueAtDateQuery__
 *
 * To run a query within a React component, call `useGetAmountDueAtDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAmountDueAtDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAmountDueAtDateQuery({
 *   variables: {
 *      liabilityId: // value for 'liabilityId'
 *      dateTime: // value for 'dateTime'
 *   },
 * });
 */
export function useGetAmountDueAtDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAmountDueAtDateQuery,
    GetAmountDueAtDateQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAmountDueAtDateQuery,
    GetAmountDueAtDateQueryVariables
  >(GetAmountDueAtDateDocument, baseOptions);
}
export function useGetAmountDueAtDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAmountDueAtDateQuery,
    GetAmountDueAtDateQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAmountDueAtDateQuery,
    GetAmountDueAtDateQueryVariables
  >(GetAmountDueAtDateDocument, baseOptions);
}
export type GetAmountDueAtDateQueryHookResult = ReturnType<
  typeof useGetAmountDueAtDateQuery
>;
export type GetAmountDueAtDateLazyQueryHookResult = ReturnType<
  typeof useGetAmountDueAtDateLazyQuery
>;
export type GetAmountDueAtDateQueryResult = Apollo.QueryResult<
  GetAmountDueAtDateQuery,
  GetAmountDueAtDateQueryVariables
>;
export const GetDataForEditEndTenancySidebarDocument = gql`
  query getDataForEditEndTenancySidebar($id: ID!) {
    tenancy(tenancyId: $id) {
      id
      deposit {
        id
        status
      }
      liability {
        id
        reference
        effectivePaidToDate
      }
      endDate
      validVacateEndDateRange {
        startDate
        endDate
      }
      vacatingReason
      vacatingNotes
      mostRecentTenancyAgreement {
        id
        startDate
        fixedTermEndDate
        allowedToLapseAt
      }
    }
  }
`;

/**
 * __useGetDataForEditEndTenancySidebarQuery__
 *
 * To run a query within a React component, call `useGetDataForEditEndTenancySidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataForEditEndTenancySidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataForEditEndTenancySidebarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDataForEditEndTenancySidebarQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDataForEditEndTenancySidebarQuery,
    GetDataForEditEndTenancySidebarQueryVariables
  >
) {
  return Apollo.useQuery<
    GetDataForEditEndTenancySidebarQuery,
    GetDataForEditEndTenancySidebarQueryVariables
  >(GetDataForEditEndTenancySidebarDocument, baseOptions);
}
export function useGetDataForEditEndTenancySidebarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDataForEditEndTenancySidebarQuery,
    GetDataForEditEndTenancySidebarQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetDataForEditEndTenancySidebarQuery,
    GetDataForEditEndTenancySidebarQueryVariables
  >(GetDataForEditEndTenancySidebarDocument, baseOptions);
}
export type GetDataForEditEndTenancySidebarQueryHookResult = ReturnType<
  typeof useGetDataForEditEndTenancySidebarQuery
>;
export type GetDataForEditEndTenancySidebarLazyQueryHookResult = ReturnType<
  typeof useGetDataForEditEndTenancySidebarLazyQuery
>;
export type GetDataForEditEndTenancySidebarQueryResult = Apollo.QueryResult<
  GetDataForEditEndTenancySidebarQuery,
  GetDataForEditEndTenancySidebarQueryVariables
>;
export const GetNextTenancyStartDateDocument = gql`
  query getNextTenancyStartDate($managementId: ID!) {
    management(managementId: $managementId) {
      nextTenancy {
        id
        startDate
      }
    }
  }
`;

/**
 * __useGetNextTenancyStartDateQuery__
 *
 * To run a query within a React component, call `useGetNextTenancyStartDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextTenancyStartDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextTenancyStartDateQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetNextTenancyStartDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNextTenancyStartDateQuery,
    GetNextTenancyStartDateQueryVariables
  >
) {
  return Apollo.useQuery<
    GetNextTenancyStartDateQuery,
    GetNextTenancyStartDateQueryVariables
  >(GetNextTenancyStartDateDocument, baseOptions);
}
export function useGetNextTenancyStartDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNextTenancyStartDateQuery,
    GetNextTenancyStartDateQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetNextTenancyStartDateQuery,
    GetNextTenancyStartDateQueryVariables
  >(GetNextTenancyStartDateDocument, baseOptions);
}
export type GetNextTenancyStartDateQueryHookResult = ReturnType<
  typeof useGetNextTenancyStartDateQuery
>;
export type GetNextTenancyStartDateLazyQueryHookResult = ReturnType<
  typeof useGetNextTenancyStartDateLazyQuery
>;
export type GetNextTenancyStartDateQueryResult = Apollo.QueryResult<
  GetNextTenancyStartDateQuery,
  GetNextTenancyStartDateQueryVariables
>;
export const UpdateEndOfTenancyDocument = gql`
  mutation updateEndOfTenancy(
    $id: ID!
    $endDate: Date
    $reason: VacatingReason
    $notes: String
  ) {
    updateEndOfTenancy(
      tenancyId: $id
      endDate: $endDate
      reason: $reason
      notes: $notes
    ) {
      id
      endDate
      ...CanCancelVacate
      management {
        id
        occupancyStatus
        ...ManagementEndDetails
        ...ManagementFormerAndVoidedTenancies
      }
    }
  }
  ${CanCancelVacateFragmentDoc}
  ${ManagementEndDetailsFragmentDoc}
  ${ManagementFormerAndVoidedTenanciesFragmentDoc}
`;
export type UpdateEndOfTenancyMutationFn = Apollo.MutationFunction<
  UpdateEndOfTenancyMutation,
  UpdateEndOfTenancyMutationVariables
>;

/**
 * __useUpdateEndOfTenancyMutation__
 *
 * To run a mutation, you first call `useUpdateEndOfTenancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEndOfTenancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEndOfTenancyMutation, { data, loading, error }] = useUpdateEndOfTenancyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      endDate: // value for 'endDate'
 *      reason: // value for 'reason'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateEndOfTenancyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEndOfTenancyMutation,
    UpdateEndOfTenancyMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateEndOfTenancyMutation,
    UpdateEndOfTenancyMutationVariables
  >(UpdateEndOfTenancyDocument, baseOptions);
}
export type UpdateEndOfTenancyMutationHookResult = ReturnType<
  typeof useUpdateEndOfTenancyMutation
>;
export type UpdateEndOfTenancyMutationResult =
  Apollo.MutationResult<UpdateEndOfTenancyMutation>;
export type UpdateEndOfTenancyMutationOptions = Apollo.BaseMutationOptions<
  UpdateEndOfTenancyMutation,
  UpdateEndOfTenancyMutationVariables
>;
export const GetDefaultWalletWithMoneyManagementSettingsForManagementDocument = gql`
  query getDefaultWalletWithMoneyManagementSettingsForManagement(
    $walletOwnerReference: AiloRN!
  ) {
    walletByWalletOwnerReference(walletOwnerReference: $walletOwnerReference) {
      id
      name
      availableBalance {
        cents
      }
      totalBalance {
        cents
      }
      autoWithdrawPlans(disableOwnerFilter: true, enabled: true) {
        items {
          id
          enabled
          details {
            frequency
            nextFireTime
          }
        }
      }
    }
    autoPayLiabilityStatuses(payerLegalEntityId: $walletOwnerReference) {
      payerId
      enabled
    }
  }
`;

/**
 * __useGetDefaultWalletWithMoneyManagementSettingsForManagementQuery__
 *
 * To run a query within a React component, call `useGetDefaultWalletWithMoneyManagementSettingsForManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultWalletWithMoneyManagementSettingsForManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultWalletWithMoneyManagementSettingsForManagementQuery({
 *   variables: {
 *      walletOwnerReference: // value for 'walletOwnerReference'
 *   },
 * });
 */
export function useGetDefaultWalletWithMoneyManagementSettingsForManagementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDefaultWalletWithMoneyManagementSettingsForManagementQuery,
    GetDefaultWalletWithMoneyManagementSettingsForManagementQueryVariables
  >
) {
  return Apollo.useQuery<
    GetDefaultWalletWithMoneyManagementSettingsForManagementQuery,
    GetDefaultWalletWithMoneyManagementSettingsForManagementQueryVariables
  >(
    GetDefaultWalletWithMoneyManagementSettingsForManagementDocument,
    baseOptions
  );
}
export function useGetDefaultWalletWithMoneyManagementSettingsForManagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDefaultWalletWithMoneyManagementSettingsForManagementQuery,
    GetDefaultWalletWithMoneyManagementSettingsForManagementQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetDefaultWalletWithMoneyManagementSettingsForManagementQuery,
    GetDefaultWalletWithMoneyManagementSettingsForManagementQueryVariables
  >(
    GetDefaultWalletWithMoneyManagementSettingsForManagementDocument,
    baseOptions
  );
}
export type GetDefaultWalletWithMoneyManagementSettingsForManagementQueryHookResult =
  ReturnType<
    typeof useGetDefaultWalletWithMoneyManagementSettingsForManagementQuery
  >;
export type GetDefaultWalletWithMoneyManagementSettingsForManagementLazyQueryHookResult =
  ReturnType<
    typeof useGetDefaultWalletWithMoneyManagementSettingsForManagementLazyQuery
  >;
export type GetDefaultWalletWithMoneyManagementSettingsForManagementQueryResult =
  Apollo.QueryResult<
    GetDefaultWalletWithMoneyManagementSettingsForManagementQuery,
    GetDefaultWalletWithMoneyManagementSettingsForManagementQueryVariables
  >;
export const GetTenancyByManagementDocument = gql`
  query getTenancyByManagement($id: ID!) {
    management(managementId: $id) {
      id
      mostRecentTenancy {
        id
        endDate
      }
      nextTenancy {
        id
      }
    }
  }
`;

/**
 * __useGetTenancyByManagementQuery__
 *
 * To run a query within a React component, call `useGetTenancyByManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenancyByManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenancyByManagementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenancyByManagementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenancyByManagementQuery,
    GetTenancyByManagementQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenancyByManagementQuery,
    GetTenancyByManagementQueryVariables
  >(GetTenancyByManagementDocument, baseOptions);
}
export function useGetTenancyByManagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenancyByManagementQuery,
    GetTenancyByManagementQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenancyByManagementQuery,
    GetTenancyByManagementQueryVariables
  >(GetTenancyByManagementDocument, baseOptions);
}
export type GetTenancyByManagementQueryHookResult = ReturnType<
  typeof useGetTenancyByManagementQuery
>;
export type GetTenancyByManagementLazyQueryHookResult = ReturnType<
  typeof useGetTenancyByManagementLazyQuery
>;
export type GetTenancyByManagementQueryResult = Apollo.QueryResult<
  GetTenancyByManagementQuery,
  GetTenancyByManagementQueryVariables
>;
export const GetLegalEntityDetailsDocument = gql`
  query getLegalEntityDetails($ailoRN: AiloRN!, $organisationAiloRN: AiloRN!) {
    legalEntity(id: $ailoRN) {
      __typename
      id
      ... on Person {
        firstName
        lastName
        photo {
          id
          url
          thumbnailUrl
        }
        phoneNo
        emailAddress
        user {
          id
          onboardingCompletedAt
        }
      }
      ... on Company {
        registeredEntityName
        registeredEntityId
        organisation {
          members(pageCursor: { pageSize: 50 }) {
            items {
              __typename
              ...PersonNames
              photo {
                id
                url
                thumbnailUrl
              }
              phoneNo
              emailAddress
              user {
                id
                onboardingCompletedAt
              }
            }
          }
        }
      }
      contact(organisationAilorn: $organisationAiloRN) {
        ailorn
      }
      managements(
        managingOrganisationId: $organisationAiloRN
        pageCursor: { pageSize: 50 }
      ) {
        items {
          id
          ...ManagementProperty
        }
      }
      ownerships(
        managingOrganisationId: $organisationAiloRN
        pageCursor: { pageSize: 50 }
      ) {
        items {
          sharesOwned
          totalShares
          management {
            id
            ...ManagementProperty
          }
        }
      }
      tenancies(
        managingOrganisationId: $organisationAiloRN
        pageCursor: { pageSize: 50 }
      ) {
        items {
          id
          management {
            id
            ...ManagementProperty
          }
          liability {
            id
            autoPaymentDetails {
              paymentMethod {
                ...PaymentMethodDetails
              }
            }
          }
        }
      }
    }
    liabilityPaymentPlans(enabled: true, payerLegalEntityId: $ailoRN) {
      items {
        id
        enabled
        liabilityId
        owner
        details {
          frequency
          anniversary
          startDate
          endDate
          amount {
            cents
          }
          paymentMethod {
            ...PaymentMethodDetails
          }
        }
      }
    }
    autoWithdrawPlans(
      enabled: true
      payerLegalEntityId: $ailoRN
      disableOwnerFilter: true
    ) {
      items {
        id
        details {
          frequency
          anniversary
          startDate
          endDate
          setAsideAmount {
            cents
          }
        }
      }
    }
    autoPayLiabilityStatuses(payerLegalEntityId: $ailoRN) {
      enabled
      maximumPaymentAmount {
        cents
      }
      paymentMethod {
        ...PaymentMethodDetails
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${ManagementPropertyFragmentDoc}
  ${PaymentMethodDetailsFragmentDoc}
`;

/**
 * __useGetLegalEntityDetailsQuery__
 *
 * To run a query within a React component, call `useGetLegalEntityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLegalEntityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLegalEntityDetailsQuery({
 *   variables: {
 *      ailoRN: // value for 'ailoRN'
 *      organisationAiloRN: // value for 'organisationAiloRN'
 *   },
 * });
 */
export function useGetLegalEntityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLegalEntityDetailsQuery,
    GetLegalEntityDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetLegalEntityDetailsQuery,
    GetLegalEntityDetailsQueryVariables
  >(GetLegalEntityDetailsDocument, baseOptions);
}
export function useGetLegalEntityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLegalEntityDetailsQuery,
    GetLegalEntityDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetLegalEntityDetailsQuery,
    GetLegalEntityDetailsQueryVariables
  >(GetLegalEntityDetailsDocument, baseOptions);
}
export type GetLegalEntityDetailsQueryHookResult = ReturnType<
  typeof useGetLegalEntityDetailsQuery
>;
export type GetLegalEntityDetailsLazyQueryHookResult = ReturnType<
  typeof useGetLegalEntityDetailsLazyQuery
>;
export type GetLegalEntityDetailsQueryResult = Apollo.QueryResult<
  GetLegalEntityDetailsQuery,
  GetLegalEntityDetailsQueryVariables
>;
export const AttachFilesToManagementDocument = gql`
  mutation attachFilesToManagement($id: ID!, $fileIds: [AiloRN!]!) {
    attachFilesToManagement(managementId: $id, fileIds: $fileIds) {
      id
      ...managementFiles
    }
  }
  ${ManagementFilesFragmentDoc}
`;
export type AttachFilesToManagementMutationFn = Apollo.MutationFunction<
  AttachFilesToManagementMutation,
  AttachFilesToManagementMutationVariables
>;

/**
 * __useAttachFilesToManagementMutation__
 *
 * To run a mutation, you first call `useAttachFilesToManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachFilesToManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachFilesToManagementMutation, { data, loading, error }] = useAttachFilesToManagementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileIds: // value for 'fileIds'
 *   },
 * });
 */
export function useAttachFilesToManagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachFilesToManagementMutation,
    AttachFilesToManagementMutationVariables
  >
) {
  return Apollo.useMutation<
    AttachFilesToManagementMutation,
    AttachFilesToManagementMutationVariables
  >(AttachFilesToManagementDocument, baseOptions);
}
export type AttachFilesToManagementMutationHookResult = ReturnType<
  typeof useAttachFilesToManagementMutation
>;
export type AttachFilesToManagementMutationResult =
  Apollo.MutationResult<AttachFilesToManagementMutation>;
export type AttachFilesToManagementMutationOptions = Apollo.BaseMutationOptions<
  AttachFilesToManagementMutation,
  AttachFilesToManagementMutationVariables
>;
export const GetManagementAndTeamsDocument = gql`
  query getManagementAndTeams($managementId: ID!, $organisationId: ID!) {
    management(managementId: $managementId) {
      id
      startDate
      fixedTermEndDate
      team {
        id
      }
      ...ManagementProperty
    }
    organisation(id: $organisationId) {
      id
      teams {
        id
        name
        myTeam
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useGetManagementAndTeamsQuery__
 *
 * To run a query within a React component, call `useGetManagementAndTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementAndTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementAndTeamsQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetManagementAndTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementAndTeamsQuery,
    GetManagementAndTeamsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementAndTeamsQuery,
    GetManagementAndTeamsQueryVariables
  >(GetManagementAndTeamsDocument, baseOptions);
}
export function useGetManagementAndTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementAndTeamsQuery,
    GetManagementAndTeamsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementAndTeamsQuery,
    GetManagementAndTeamsQueryVariables
  >(GetManagementAndTeamsDocument, baseOptions);
}
export type GetManagementAndTeamsQueryHookResult = ReturnType<
  typeof useGetManagementAndTeamsQuery
>;
export type GetManagementAndTeamsLazyQueryHookResult = ReturnType<
  typeof useGetManagementAndTeamsLazyQuery
>;
export type GetManagementAndTeamsQueryResult = Apollo.QueryResult<
  GetManagementAndTeamsQuery,
  GetManagementAndTeamsQueryVariables
>;
export const GetManagementDetailsAndTeamsDocument = gql`
  query getManagementDetailsAndTeams($managementId: ID!, $organisationId: ID!) {
    management(managementId: $managementId) {
      id
      startDate
      endDate
      fixedTermEndDate
      endReason {
        code
        label
        causes {
          code
          label
        }
      }
      endNote
      team {
        id
      }
      ...ManagementProperty
      ...managementFiles
    }
    organisation(id: $organisationId) {
      id
      teams {
        id
        name
        myTeam
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
  ${ManagementFilesFragmentDoc}
`;

/**
 * __useGetManagementDetailsAndTeamsQuery__
 *
 * To run a query within a React component, call `useGetManagementDetailsAndTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementDetailsAndTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementDetailsAndTeamsQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetManagementDetailsAndTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementDetailsAndTeamsQuery,
    GetManagementDetailsAndTeamsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementDetailsAndTeamsQuery,
    GetManagementDetailsAndTeamsQueryVariables
  >(GetManagementDetailsAndTeamsDocument, baseOptions);
}
export function useGetManagementDetailsAndTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementDetailsAndTeamsQuery,
    GetManagementDetailsAndTeamsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementDetailsAndTeamsQuery,
    GetManagementDetailsAndTeamsQueryVariables
  >(GetManagementDetailsAndTeamsDocument, baseOptions);
}
export type GetManagementDetailsAndTeamsQueryHookResult = ReturnType<
  typeof useGetManagementDetailsAndTeamsQuery
>;
export type GetManagementDetailsAndTeamsLazyQueryHookResult = ReturnType<
  typeof useGetManagementDetailsAndTeamsLazyQuery
>;
export type GetManagementDetailsAndTeamsQueryResult = Apollo.QueryResult<
  GetManagementDetailsAndTeamsQuery,
  GetManagementDetailsAndTeamsQueryVariables
>;
export const MoveManagementsToTeamDocument = gql`
  mutation moveManagementsToTeam($managementId: AiloRN!, $teamId: AiloRN!) {
    moveManagementsToTeam(managementIds: [$managementId], teamId: $teamId) {
      id
    }
  }
`;
export type MoveManagementsToTeamMutationFn = Apollo.MutationFunction<
  MoveManagementsToTeamMutation,
  MoveManagementsToTeamMutationVariables
>;

/**
 * __useMoveManagementsToTeamMutation__
 *
 * To run a mutation, you first call `useMoveManagementsToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveManagementsToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveManagementsToTeamMutation, { data, loading, error }] = useMoveManagementsToTeamMutation({
 *   variables: {
 *      managementId: // value for 'managementId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useMoveManagementsToTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveManagementsToTeamMutation,
    MoveManagementsToTeamMutationVariables
  >
) {
  return Apollo.useMutation<
    MoveManagementsToTeamMutation,
    MoveManagementsToTeamMutationVariables
  >(MoveManagementsToTeamDocument, baseOptions);
}
export type MoveManagementsToTeamMutationHookResult = ReturnType<
  typeof useMoveManagementsToTeamMutation
>;
export type MoveManagementsToTeamMutationResult =
  Apollo.MutationResult<MoveManagementsToTeamMutation>;
export type MoveManagementsToTeamMutationOptions = Apollo.BaseMutationOptions<
  MoveManagementsToTeamMutation,
  MoveManagementsToTeamMutationVariables
>;
export const CreatePropertyKeyDocument = gql`
  mutation createPropertyKey(
    $input: CreateOneKeyInput!
    $organisationAilorn: AiloRN!
  ) {
    createOneKey(input: $input) {
      ...Key
      property {
        ...PropertyKeys
      }
    }
  }
  ${KeyFragmentDoc}
  ${PropertyKeysFragmentDoc}
`;
export type CreatePropertyKeyMutationFn = Apollo.MutationFunction<
  CreatePropertyKeyMutation,
  CreatePropertyKeyMutationVariables
>;

/**
 * __useCreatePropertyKeyMutation__
 *
 * To run a mutation, you first call `useCreatePropertyKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropertyKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyKeyMutation, { data, loading, error }] = useCreatePropertyKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useCreatePropertyKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePropertyKeyMutation,
    CreatePropertyKeyMutationVariables
  >
) {
  return Apollo.useMutation<
    CreatePropertyKeyMutation,
    CreatePropertyKeyMutationVariables
  >(CreatePropertyKeyDocument, baseOptions);
}
export type CreatePropertyKeyMutationHookResult = ReturnType<
  typeof useCreatePropertyKeyMutation
>;
export type CreatePropertyKeyMutationResult =
  Apollo.MutationResult<CreatePropertyKeyMutation>;
export type CreatePropertyKeyMutationOptions = Apollo.BaseMutationOptions<
  CreatePropertyKeyMutation,
  CreatePropertyKeyMutationVariables
>;
export const UpdatePropertyKeyDocument = gql`
  mutation updatePropertyKey(
    $input: UpdateOneKeyInput!
    $organisationAilorn: AiloRN!
  ) {
    updateOneKey(input: $input) {
      ...Key
      property {
        ...PropertyKeys
      }
    }
  }
  ${KeyFragmentDoc}
  ${PropertyKeysFragmentDoc}
`;
export type UpdatePropertyKeyMutationFn = Apollo.MutationFunction<
  UpdatePropertyKeyMutation,
  UpdatePropertyKeyMutationVariables
>;

/**
 * __useUpdatePropertyKeyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyKeyMutation, { data, loading, error }] = useUpdatePropertyKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useUpdatePropertyKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePropertyKeyMutation,
    UpdatePropertyKeyMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePropertyKeyMutation,
    UpdatePropertyKeyMutationVariables
  >(UpdatePropertyKeyDocument, baseOptions);
}
export type UpdatePropertyKeyMutationHookResult = ReturnType<
  typeof useUpdatePropertyKeyMutation
>;
export type UpdatePropertyKeyMutationResult =
  Apollo.MutationResult<UpdatePropertyKeyMutation>;
export type UpdatePropertyKeyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyKeyMutation,
  UpdatePropertyKeyMutationVariables
>;
export const GetAllTenanciesDocument = gql`
  query getAllTenancies($managementId: ID!) {
    management(managementId: $managementId) {
      id
      tenancies {
        items {
          id
          bond {
            status
            failureReason
            fundsReceived {
              receivedAt
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllTenanciesQuery__
 *
 * To run a query within a React component, call `useGetAllTenanciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTenanciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTenanciesQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetAllTenanciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllTenanciesQuery,
    GetAllTenanciesQueryVariables
  >
) {
  return Apollo.useQuery<GetAllTenanciesQuery, GetAllTenanciesQueryVariables>(
    GetAllTenanciesDocument,
    baseOptions
  );
}
export function useGetAllTenanciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTenanciesQuery,
    GetAllTenanciesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAllTenanciesQuery,
    GetAllTenanciesQueryVariables
  >(GetAllTenanciesDocument, baseOptions);
}
export type GetAllTenanciesQueryHookResult = ReturnType<
  typeof useGetAllTenanciesQuery
>;
export type GetAllTenanciesLazyQueryHookResult = ReturnType<
  typeof useGetAllTenanciesLazyQuery
>;
export type GetAllTenanciesQueryResult = Apollo.QueryResult<
  GetAllTenanciesQuery,
  GetAllTenanciesQueryVariables
>;
export const GetManagementAndTenancyForPropertyChatListDocument = gql`
  query getManagementAndTenancyForPropertyChatList($managementId: ID!) {
    management(managementId: $managementId) {
      id
      ownerContacts {
        id
        ailorn
      }
      mostRecentTenancy {
        tenantContacts {
          id
          ailorn
        }
      }
      nextTenancy {
        tenantContacts {
          id
          ailorn
        }
      }
      team {
        id
      }
    }
  }
`;

/**
 * __useGetManagementAndTenancyForPropertyChatListQuery__
 *
 * To run a query within a React component, call `useGetManagementAndTenancyForPropertyChatListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementAndTenancyForPropertyChatListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementAndTenancyForPropertyChatListQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetManagementAndTenancyForPropertyChatListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementAndTenancyForPropertyChatListQuery,
    GetManagementAndTenancyForPropertyChatListQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementAndTenancyForPropertyChatListQuery,
    GetManagementAndTenancyForPropertyChatListQueryVariables
  >(GetManagementAndTenancyForPropertyChatListDocument, baseOptions);
}
export function useGetManagementAndTenancyForPropertyChatListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementAndTenancyForPropertyChatListQuery,
    GetManagementAndTenancyForPropertyChatListQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementAndTenancyForPropertyChatListQuery,
    GetManagementAndTenancyForPropertyChatListQueryVariables
  >(GetManagementAndTenancyForPropertyChatListDocument, baseOptions);
}
export type GetManagementAndTenancyForPropertyChatListQueryHookResult =
  ReturnType<typeof useGetManagementAndTenancyForPropertyChatListQuery>;
export type GetManagementAndTenancyForPropertyChatListLazyQueryHookResult =
  ReturnType<typeof useGetManagementAndTenancyForPropertyChatListLazyQuery>;
export type GetManagementAndTenancyForPropertyChatListQueryResult =
  Apollo.QueryResult<
    GetManagementAndTenancyForPropertyChatListQuery,
    GetManagementAndTenancyForPropertyChatListQueryVariables
  >;
export const GetFormerTenanciesUnpaidBillsDocument = gql`
  query getFormerTenanciesUnpaidBills(
    $organisationAilorn: AiloRN!
    $managementAilorn: AiloRN!
    $currentTenancyAilorns: [AiloRN!]
    $limit: Int!
  ) {
    bills(
      organisationId: [$organisationAilorn]
      management: [$managementAilorn]
      payerIdNotIn: $currentTenancyAilorns
      payerType: [Tenancy]
      paid: false
      status: [APPROVED, REFUNDED]
      taxCategoryIdNotIn: ["MANAGEMENT_FEES"]
      cursor: { pageSize: $limit }
    ) {
      pageInfo {
        total
      }
      items {
        ailoRN
        payer {
          __typename
          ... on Tenancy {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetFormerTenanciesUnpaidBillsQuery__
 *
 * To run a query within a React component, call `useGetFormerTenanciesUnpaidBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormerTenanciesUnpaidBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormerTenanciesUnpaidBillsQuery({
 *   variables: {
 *      organisationAilorn: // value for 'organisationAilorn'
 *      managementAilorn: // value for 'managementAilorn'
 *      currentTenancyAilorns: // value for 'currentTenancyAilorns'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetFormerTenanciesUnpaidBillsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormerTenanciesUnpaidBillsQuery,
    GetFormerTenanciesUnpaidBillsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetFormerTenanciesUnpaidBillsQuery,
    GetFormerTenanciesUnpaidBillsQueryVariables
  >(GetFormerTenanciesUnpaidBillsDocument, baseOptions);
}
export function useGetFormerTenanciesUnpaidBillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormerTenanciesUnpaidBillsQuery,
    GetFormerTenanciesUnpaidBillsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetFormerTenanciesUnpaidBillsQuery,
    GetFormerTenanciesUnpaidBillsQueryVariables
  >(GetFormerTenanciesUnpaidBillsDocument, baseOptions);
}
export type GetFormerTenanciesUnpaidBillsQueryHookResult = ReturnType<
  typeof useGetFormerTenanciesUnpaidBillsQuery
>;
export type GetFormerTenanciesUnpaidBillsLazyQueryHookResult = ReturnType<
  typeof useGetFormerTenanciesUnpaidBillsLazyQuery
>;
export type GetFormerTenanciesUnpaidBillsQueryResult = Apollo.QueryResult<
  GetFormerTenanciesUnpaidBillsQuery,
  GetFormerTenanciesUnpaidBillsQueryVariables
>;
export const GetManagementOwnerEndDetailsDocument = gql`
  query getManagementOwnerEndDetails($id: ID!, $organisationAilorn: AiloRN!) {
    management(managementId: $id) {
      id
      ...ManagementOwnerships
      ...ManagementEndDetails
    }
  }
  ${ManagementOwnershipsFragmentDoc}
  ${ManagementEndDetailsFragmentDoc}
`;

/**
 * __useGetManagementOwnerEndDetailsQuery__
 *
 * To run a query within a React component, call `useGetManagementOwnerEndDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementOwnerEndDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementOwnerEndDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useGetManagementOwnerEndDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementOwnerEndDetailsQuery,
    GetManagementOwnerEndDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementOwnerEndDetailsQuery,
    GetManagementOwnerEndDetailsQueryVariables
  >(GetManagementOwnerEndDetailsDocument, baseOptions);
}
export function useGetManagementOwnerEndDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementOwnerEndDetailsQuery,
    GetManagementOwnerEndDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementOwnerEndDetailsQuery,
    GetManagementOwnerEndDetailsQueryVariables
  >(GetManagementOwnerEndDetailsDocument, baseOptions);
}
export type GetManagementOwnerEndDetailsQueryHookResult = ReturnType<
  typeof useGetManagementOwnerEndDetailsQuery
>;
export type GetManagementOwnerEndDetailsLazyQueryHookResult = ReturnType<
  typeof useGetManagementOwnerEndDetailsLazyQuery
>;
export type GetManagementOwnerEndDetailsQueryResult = Apollo.QueryResult<
  GetManagementOwnerEndDetailsQuery,
  GetManagementOwnerEndDetailsQueryVariables
>;
export const GetPropertyHealthDocument = gql`
  query getPropertyHealth($managementId: ID!) {
    management(managementId: $managementId) {
      id
      occupancyStatus
      startDate
      mostRecentTenancy {
        id
        liability {
          id
          reference
          paidTo
        }
        endDate
        currentRentSchedule {
          id
          amountInCents
          period
        }
        startDate
        mostRecentTenancyAgreement {
          id
          startDate
          fixedTermEndDate
          allowedToLapseAt
        }
        routineInspectionDueDate
        ...upcomingTenancyAgreement
        ...upcomingRentChanges
      }
      nextTenancy {
        id
        startDate
      }
    }
  }
  ${UpcomingTenancyAgreementFragmentDoc}
  ${UpcomingRentChangesFragmentDoc}
`;

/**
 * __useGetPropertyHealthQuery__
 *
 * To run a query within a React component, call `useGetPropertyHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyHealthQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetPropertyHealthQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyHealthQuery,
    GetPropertyHealthQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertyHealthQuery,
    GetPropertyHealthQueryVariables
  >(GetPropertyHealthDocument, baseOptions);
}
export function useGetPropertyHealthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyHealthQuery,
    GetPropertyHealthQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertyHealthQuery,
    GetPropertyHealthQueryVariables
  >(GetPropertyHealthDocument, baseOptions);
}
export type GetPropertyHealthQueryHookResult = ReturnType<
  typeof useGetPropertyHealthQuery
>;
export type GetPropertyHealthLazyQueryHookResult = ReturnType<
  typeof useGetPropertyHealthLazyQuery
>;
export type GetPropertyHealthQueryResult = Apollo.QueryResult<
  GetPropertyHealthQuery,
  GetPropertyHealthQueryVariables
>;
export const GetFormerAndVoidedTenanciesDocument = gql`
  query getFormerAndVoidedTenancies($managementId: ID!) {
    management(managementId: $managementId) {
      ...ManagementFormerAndVoidedTenancies
    }
  }
  ${ManagementFormerAndVoidedTenanciesFragmentDoc}
`;

/**
 * __useGetFormerAndVoidedTenanciesQuery__
 *
 * To run a query within a React component, call `useGetFormerAndVoidedTenanciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormerAndVoidedTenanciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormerAndVoidedTenanciesQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetFormerAndVoidedTenanciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormerAndVoidedTenanciesQuery,
    GetFormerAndVoidedTenanciesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetFormerAndVoidedTenanciesQuery,
    GetFormerAndVoidedTenanciesQueryVariables
  >(GetFormerAndVoidedTenanciesDocument, baseOptions);
}
export function useGetFormerAndVoidedTenanciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormerAndVoidedTenanciesQuery,
    GetFormerAndVoidedTenanciesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetFormerAndVoidedTenanciesQuery,
    GetFormerAndVoidedTenanciesQueryVariables
  >(GetFormerAndVoidedTenanciesDocument, baseOptions);
}
export type GetFormerAndVoidedTenanciesQueryHookResult = ReturnType<
  typeof useGetFormerAndVoidedTenanciesQuery
>;
export type GetFormerAndVoidedTenanciesLazyQueryHookResult = ReturnType<
  typeof useGetFormerAndVoidedTenanciesLazyQuery
>;
export type GetFormerAndVoidedTenanciesQueryResult = Apollo.QueryResult<
  GetFormerAndVoidedTenanciesQuery,
  GetFormerAndVoidedTenanciesQueryVariables
>;
export const GetPropertyWalletMoneyManagementSettingsDocument = gql`
  query getPropertyWalletMoneyManagementSettings(
    $walletOwnerReference: AiloRN!
  ) {
    walletByWalletOwnerReference(walletOwnerReference: $walletOwnerReference) {
      id
      name
      availableBalance {
        cents
      }
      totalBalance {
        cents
      }
      autoWithdrawPlans(disableOwnerFilter: true, enabled: true) {
        items {
          id
          enabled
          owner
          details {
            startDate
            frequency
            nextFireTime
            paymentMethodDestinations {
              paymentMethodId
              share
              paymentMethod {
                ...PaymentMethodDetails
                ... on BankAccount {
                  accountName
                  accountNumber
                }
              }
            }
            anniversaryDaysOfMonth
            isLastDayOfTheMonth
          }
        }
      }
    }
    autoPayLiabilityStatuses(payerLegalEntityId: $walletOwnerReference) {
      payerId
      enabled
      maximumPaymentAmount {
        cents
      }
    }
  }
  ${PaymentMethodDetailsFragmentDoc}
`;

/**
 * __useGetPropertyWalletMoneyManagementSettingsQuery__
 *
 * To run a query within a React component, call `useGetPropertyWalletMoneyManagementSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyWalletMoneyManagementSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyWalletMoneyManagementSettingsQuery({
 *   variables: {
 *      walletOwnerReference: // value for 'walletOwnerReference'
 *   },
 * });
 */
export function useGetPropertyWalletMoneyManagementSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyWalletMoneyManagementSettingsQuery,
    GetPropertyWalletMoneyManagementSettingsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertyWalletMoneyManagementSettingsQuery,
    GetPropertyWalletMoneyManagementSettingsQueryVariables
  >(GetPropertyWalletMoneyManagementSettingsDocument, baseOptions);
}
export function useGetPropertyWalletMoneyManagementSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyWalletMoneyManagementSettingsQuery,
    GetPropertyWalletMoneyManagementSettingsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertyWalletMoneyManagementSettingsQuery,
    GetPropertyWalletMoneyManagementSettingsQueryVariables
  >(GetPropertyWalletMoneyManagementSettingsDocument, baseOptions);
}
export type GetPropertyWalletMoneyManagementSettingsQueryHookResult =
  ReturnType<typeof useGetPropertyWalletMoneyManagementSettingsQuery>;
export type GetPropertyWalletMoneyManagementSettingsLazyQueryHookResult =
  ReturnType<typeof useGetPropertyWalletMoneyManagementSettingsLazyQuery>;
export type GetPropertyWalletMoneyManagementSettingsQueryResult =
  Apollo.QueryResult<
    GetPropertyWalletMoneyManagementSettingsQuery,
    GetPropertyWalletMoneyManagementSettingsQueryVariables
  >;
export const ReviewRentDocument = gql`
  mutation reviewRent($tenancyId: ID!, $rentDetails: RentReviewDetails!) {
    reviewRent(tenancyId: $tenancyId, rentDetails: $rentDetails) {
      id
      ...upcomingRentChanges
      leaseReviewAllowed
      rentReviewAllowed
      editableRentReview {
        rent {
          id
        }
      }
    }
  }
  ${UpcomingRentChangesFragmentDoc}
`;
export type ReviewRentMutationFn = Apollo.MutationFunction<
  ReviewRentMutation,
  ReviewRentMutationVariables
>;

/**
 * __useReviewRentMutation__
 *
 * To run a mutation, you first call `useReviewRentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewRentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewRentMutation, { data, loading, error }] = useReviewRentMutation({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *      rentDetails: // value for 'rentDetails'
 *   },
 * });
 */
export function useReviewRentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReviewRentMutation,
    ReviewRentMutationVariables
  >
) {
  return Apollo.useMutation<ReviewRentMutation, ReviewRentMutationVariables>(
    ReviewRentDocument,
    baseOptions
  );
}
export type ReviewRentMutationHookResult = ReturnType<
  typeof useReviewRentMutation
>;
export type ReviewRentMutationResult =
  Apollo.MutationResult<ReviewRentMutation>;
export type ReviewRentMutationOptions = Apollo.BaseMutationOptions<
  ReviewRentMutation,
  ReviewRentMutationVariables
>;
export const GetRentReviewDataDocument = gql`
  query getRentReviewData($tenancyId: ID!, $edit: Boolean!) {
    tenancy(tenancyId: $tenancyId) {
      id
      endDate
      liability {
        id
        nextChargeCreateDate
      }
      mostRecentRentReview {
        id
        effectiveDate
        rentIncreaseInCents
      }
      leaseReviewAllowedOperation
      currentRent {
        id
        amountInCents
        period
      }
      rents(cursor: { pageSize: 1 }) {
        pageInfo {
          total
        }
        items {
          id
          amountInCents
          period
        }
      }
      editableRentReview @include(if: $edit) {
        rent {
          id
          amountInCents
          period
          effectiveDate
          previousRent {
            id
            amountInCents
          }
        }
      }
      scheduledRentReviewDate @include(if: $edit)
    }
  }
`;

/**
 * __useGetRentReviewDataQuery__
 *
 * To run a query within a React component, call `useGetRentReviewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRentReviewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRentReviewDataQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *      edit: // value for 'edit'
 *   },
 * });
 */
export function useGetRentReviewDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRentReviewDataQuery,
    GetRentReviewDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetRentReviewDataQuery,
    GetRentReviewDataQueryVariables
  >(GetRentReviewDataDocument, baseOptions);
}
export function useGetRentReviewDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRentReviewDataQuery,
    GetRentReviewDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetRentReviewDataQuery,
    GetRentReviewDataQueryVariables
  >(GetRentReviewDataDocument, baseOptions);
}
export type GetRentReviewDataQueryHookResult = ReturnType<
  typeof useGetRentReviewDataQuery
>;
export type GetRentReviewDataLazyQueryHookResult = ReturnType<
  typeof useGetRentReviewDataLazyQuery
>;
export type GetRentReviewDataQueryResult = Apollo.QueryResult<
  GetRentReviewDataQuery,
  GetRentReviewDataQueryVariables
>;
export const AddCentrepayDirectiveDocument = gql`
  mutation addCentrepayDirective($input: AddCentrepayDirectiveInput!) {
    addCentrepayDirective(input: $input) {
      legalEntity
      managingEntity
      tenancy
    }
  }
`;
export type AddCentrepayDirectiveMutationFn = Apollo.MutationFunction<
  AddCentrepayDirectiveMutation,
  AddCentrepayDirectiveMutationVariables
>;

/**
 * __useAddCentrepayDirectiveMutation__
 *
 * To run a mutation, you first call `useAddCentrepayDirectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCentrepayDirectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCentrepayDirectiveMutation, { data, loading, error }] = useAddCentrepayDirectiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCentrepayDirectiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCentrepayDirectiveMutation,
    AddCentrepayDirectiveMutationVariables
  >
) {
  return Apollo.useMutation<
    AddCentrepayDirectiveMutation,
    AddCentrepayDirectiveMutationVariables
  >(AddCentrepayDirectiveDocument, baseOptions);
}
export type AddCentrepayDirectiveMutationHookResult = ReturnType<
  typeof useAddCentrepayDirectiveMutation
>;
export type AddCentrepayDirectiveMutationResult =
  Apollo.MutationResult<AddCentrepayDirectiveMutation>;
export type AddCentrepayDirectiveMutationOptions = Apollo.BaseMutationOptions<
  AddCentrepayDirectiveMutation,
  AddCentrepayDirectiveMutationVariables
>;
export const DeleteCentrepayDirectiveDocument = gql`
  mutation deleteCentrepayDirective($input: DeleteCentrepayDirectiveInput!) {
    deleteCentrepayDirective(input: $input)
  }
`;
export type DeleteCentrepayDirectiveMutationFn = Apollo.MutationFunction<
  DeleteCentrepayDirectiveMutation,
  DeleteCentrepayDirectiveMutationVariables
>;

/**
 * __useDeleteCentrepayDirectiveMutation__
 *
 * To run a mutation, you first call `useDeleteCentrepayDirectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCentrepayDirectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCentrepayDirectiveMutation, { data, loading, error }] = useDeleteCentrepayDirectiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCentrepayDirectiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCentrepayDirectiveMutation,
    DeleteCentrepayDirectiveMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteCentrepayDirectiveMutation,
    DeleteCentrepayDirectiveMutationVariables
  >(DeleteCentrepayDirectiveDocument, baseOptions);
}
export type DeleteCentrepayDirectiveMutationHookResult = ReturnType<
  typeof useDeleteCentrepayDirectiveMutation
>;
export type DeleteCentrepayDirectiveMutationResult =
  Apollo.MutationResult<DeleteCentrepayDirectiveMutation>;
export type DeleteCentrepayDirectiveMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCentrepayDirectiveMutation,
    DeleteCentrepayDirectiveMutationVariables
  >;
export const DeleteCrnDocument = gql`
  mutation deleteCrn($input: DeleteCrnInput!) {
    deleteCrn(input: $input)
  }
`;
export type DeleteCrnMutationFn = Apollo.MutationFunction<
  DeleteCrnMutation,
  DeleteCrnMutationVariables
>;

/**
 * __useDeleteCrnMutation__
 *
 * To run a mutation, you first call `useDeleteCrnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCrnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCrnMutation, { data, loading, error }] = useDeleteCrnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCrnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCrnMutation,
    DeleteCrnMutationVariables
  >
) {
  return Apollo.useMutation<DeleteCrnMutation, DeleteCrnMutationVariables>(
    DeleteCrnDocument,
    baseOptions
  );
}
export type DeleteCrnMutationHookResult = ReturnType<
  typeof useDeleteCrnMutation
>;
export type DeleteCrnMutationResult = Apollo.MutationResult<DeleteCrnMutation>;
export type DeleteCrnMutationOptions = Apollo.BaseMutationOptions<
  DeleteCrnMutation,
  DeleteCrnMutationVariables
>;
export const GetCrnDocument = gql`
  query getCrn($input: GetCrnInput!) {
    getCrn(input: $input) {
      crn
    }
  }
`;

/**
 * __useGetCrnQuery__
 *
 * To run a query within a React component, call `useGetCrnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrnQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCrnQuery(
  baseOptions: Apollo.QueryHookOptions<GetCrnQuery, GetCrnQueryVariables>
) {
  return Apollo.useQuery<GetCrnQuery, GetCrnQueryVariables>(
    GetCrnDocument,
    baseOptions
  );
}
export function useGetCrnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCrnQuery, GetCrnQueryVariables>
) {
  return Apollo.useLazyQuery<GetCrnQuery, GetCrnQueryVariables>(
    GetCrnDocument,
    baseOptions
  );
}
export type GetCrnQueryHookResult = ReturnType<typeof useGetCrnQuery>;
export type GetCrnLazyQueryHookResult = ReturnType<typeof useGetCrnLazyQuery>;
export type GetCrnQueryResult = Apollo.QueryResult<
  GetCrnQuery,
  GetCrnQueryVariables
>;
export const UpsertLegalEntitiesCrnDocument = gql`
  mutation upsertLegalEntitiesCrn($input: UpsertCrnInput!) {
    upsertCrn(input: $input) {
      legalEntity
      managingEntity
      crn
    }
  }
`;
export type UpsertLegalEntitiesCrnMutationFn = Apollo.MutationFunction<
  UpsertLegalEntitiesCrnMutation,
  UpsertLegalEntitiesCrnMutationVariables
>;

/**
 * __useUpsertLegalEntitiesCrnMutation__
 *
 * To run a mutation, you first call `useUpsertLegalEntitiesCrnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLegalEntitiesCrnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLegalEntitiesCrnMutation, { data, loading, error }] = useUpsertLegalEntitiesCrnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLegalEntitiesCrnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertLegalEntitiesCrnMutation,
    UpsertLegalEntitiesCrnMutationVariables
  >
) {
  return Apollo.useMutation<
    UpsertLegalEntitiesCrnMutation,
    UpsertLegalEntitiesCrnMutationVariables
  >(UpsertLegalEntitiesCrnDocument, baseOptions);
}
export type UpsertLegalEntitiesCrnMutationHookResult = ReturnType<
  typeof useUpsertLegalEntitiesCrnMutation
>;
export type UpsertLegalEntitiesCrnMutationResult =
  Apollo.MutationResult<UpsertLegalEntitiesCrnMutation>;
export type UpsertLegalEntitiesCrnMutationOptions = Apollo.BaseMutationOptions<
  UpsertLegalEntitiesCrnMutation,
  UpsertLegalEntitiesCrnMutationVariables
>;
export const AttachFilesToTenancyDocument = gql`
  mutation attachFilesToTenancy($id: ID!, $fileIds: [AiloRN!]!) {
    attachFilesToTenancy(tenancyId: $id, fileIds: $fileIds) {
      id
      ...tenancyFiles
    }
  }
  ${TenancyFilesFragmentDoc}
`;
export type AttachFilesToTenancyMutationFn = Apollo.MutationFunction<
  AttachFilesToTenancyMutation,
  AttachFilesToTenancyMutationVariables
>;

/**
 * __useAttachFilesToTenancyMutation__
 *
 * To run a mutation, you first call `useAttachFilesToTenancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachFilesToTenancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachFilesToTenancyMutation, { data, loading, error }] = useAttachFilesToTenancyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileIds: // value for 'fileIds'
 *   },
 * });
 */
export function useAttachFilesToTenancyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachFilesToTenancyMutation,
    AttachFilesToTenancyMutationVariables
  >
) {
  return Apollo.useMutation<
    AttachFilesToTenancyMutation,
    AttachFilesToTenancyMutationVariables
  >(AttachFilesToTenancyDocument, baseOptions);
}
export type AttachFilesToTenancyMutationHookResult = ReturnType<
  typeof useAttachFilesToTenancyMutation
>;
export type AttachFilesToTenancyMutationResult =
  Apollo.MutationResult<AttachFilesToTenancyMutation>;
export type AttachFilesToTenancyMutationOptions = Apollo.BaseMutationOptions<
  AttachFilesToTenancyMutation,
  AttachFilesToTenancyMutationVariables
>;
export const GetTenancyDetailsDocument = gql`
  query getTenancyDetails($id: ID!) {
    tenancy(tenancyId: $id) {
      id
      ...Tenancy
      vacatingReason
      vacatingNotes
      mostRecentTenancyAgreement {
        id
        startDate
        fixedTermEndDate
        allowedToLapseAt
      }
      displayRent {
        ...Rent
      }
      rents(cursor: { pageSize: 1000 }) {
        items {
          ...Rent
        }
      }
      liability {
        id
        effectivePaidToDate
      }
      tenantships {
        items {
          tenant {
            ...legalEntityName
          }
        }
      }
      management {
        managingEntity {
          ailoRN
        }
      }
      ...upcomingTenancyAgreement
      ...upcomingRentChanges
      ...tenancyFiles
      ...TenancyProperty
      ...InspectionSectionInfo
    }
  }
  ${TenancyFragmentDoc}
  ${RentFragmentDoc}
  ${LegalEntityNameFragmentDoc}
  ${UpcomingTenancyAgreementFragmentDoc}
  ${UpcomingRentChangesFragmentDoc}
  ${TenancyFilesFragmentDoc}
  ${TenancyPropertyFragmentDoc}
  ${InspectionSectionInfoFragmentDoc}
`;

/**
 * __useGetTenancyDetailsQuery__
 *
 * To run a query within a React component, call `useGetTenancyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenancyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenancyDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenancyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenancyDetailsQuery,
    GetTenancyDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenancyDetailsQuery,
    GetTenancyDetailsQueryVariables
  >(GetTenancyDetailsDocument, baseOptions);
}
export function useGetTenancyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenancyDetailsQuery,
    GetTenancyDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenancyDetailsQuery,
    GetTenancyDetailsQueryVariables
  >(GetTenancyDetailsDocument, baseOptions);
}
export type GetTenancyDetailsQueryHookResult = ReturnType<
  typeof useGetTenancyDetailsQuery
>;
export type GetTenancyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetTenancyDetailsLazyQuery
>;
export type GetTenancyDetailsQueryResult = Apollo.QueryResult<
  GetTenancyDetailsQuery,
  GetTenancyDetailsQueryVariables
>;
export const RemoveBondClaimDocument = gql`
  mutation removeBondClaim($bondAilorn: AiloRN!) {
    removeBondClaim(bondAilorn: $bondAilorn) {
      id
      status
      claims {
        id
        amount {
          cents
        }
      }
    }
  }
`;
export type RemoveBondClaimMutationFn = Apollo.MutationFunction<
  RemoveBondClaimMutation,
  RemoveBondClaimMutationVariables
>;

/**
 * __useRemoveBondClaimMutation__
 *
 * To run a mutation, you first call `useRemoveBondClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBondClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBondClaimMutation, { data, loading, error }] = useRemoveBondClaimMutation({
 *   variables: {
 *      bondAilorn: // value for 'bondAilorn'
 *   },
 * });
 */
export function useRemoveBondClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveBondClaimMutation,
    RemoveBondClaimMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveBondClaimMutation,
    RemoveBondClaimMutationVariables
  >(RemoveBondClaimDocument, baseOptions);
}
export type RemoveBondClaimMutationHookResult = ReturnType<
  typeof useRemoveBondClaimMutation
>;
export type RemoveBondClaimMutationResult =
  Apollo.MutationResult<RemoveBondClaimMutation>;
export type RemoveBondClaimMutationOptions = Apollo.BaseMutationOptions<
  RemoveBondClaimMutation,
  RemoveBondClaimMutationVariables
>;
export const CreateRentCreditDocument = gql`
  mutation createRentCredit($input: RentCreditDetailsInput!) {
    createRentCredit(input: $input) {
      tenancy {
        id
        liability {
          id
          entries(filterTypes: ADJUSTMENT) {
            items {
              ...adjustmentLiabilityEntry
            }
          }
        }
      }
    }
  }
  ${AdjustmentLiabilityEntryFragmentDoc}
`;
export type CreateRentCreditMutationFn = Apollo.MutationFunction<
  CreateRentCreditMutation,
  CreateRentCreditMutationVariables
>;

/**
 * __useCreateRentCreditMutation__
 *
 * To run a mutation, you first call `useCreateRentCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRentCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRentCreditMutation, { data, loading, error }] = useCreateRentCreditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRentCreditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRentCreditMutation,
    CreateRentCreditMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateRentCreditMutation,
    CreateRentCreditMutationVariables
  >(CreateRentCreditDocument, baseOptions);
}
export type CreateRentCreditMutationHookResult = ReturnType<
  typeof useCreateRentCreditMutation
>;
export type CreateRentCreditMutationResult =
  Apollo.MutationResult<CreateRentCreditMutation>;
export type CreateRentCreditMutationOptions = Apollo.BaseMutationOptions<
  CreateRentCreditMutation,
  CreateRentCreditMutationVariables
>;
export const GetTenancyDetailsForCreateRentAdjustmentDocument = gql`
  query getTenancyDetailsForCreateRentAdjustment($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      startDate
      management {
        id
        managingEntity {
          id
          timezone
        }
      }
    }
  }
`;

/**
 * __useGetTenancyDetailsForCreateRentAdjustmentQuery__
 *
 * To run a query within a React component, call `useGetTenancyDetailsForCreateRentAdjustmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenancyDetailsForCreateRentAdjustmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenancyDetailsForCreateRentAdjustmentQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetTenancyDetailsForCreateRentAdjustmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenancyDetailsForCreateRentAdjustmentQuery,
    GetTenancyDetailsForCreateRentAdjustmentQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenancyDetailsForCreateRentAdjustmentQuery,
    GetTenancyDetailsForCreateRentAdjustmentQueryVariables
  >(GetTenancyDetailsForCreateRentAdjustmentDocument, baseOptions);
}
export function useGetTenancyDetailsForCreateRentAdjustmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenancyDetailsForCreateRentAdjustmentQuery,
    GetTenancyDetailsForCreateRentAdjustmentQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenancyDetailsForCreateRentAdjustmentQuery,
    GetTenancyDetailsForCreateRentAdjustmentQueryVariables
  >(GetTenancyDetailsForCreateRentAdjustmentDocument, baseOptions);
}
export type GetTenancyDetailsForCreateRentAdjustmentQueryHookResult =
  ReturnType<typeof useGetTenancyDetailsForCreateRentAdjustmentQuery>;
export type GetTenancyDetailsForCreateRentAdjustmentLazyQueryHookResult =
  ReturnType<typeof useGetTenancyDetailsForCreateRentAdjustmentLazyQuery>;
export type GetTenancyDetailsForCreateRentAdjustmentQueryResult =
  Apollo.QueryResult<
    GetTenancyDetailsForCreateRentAdjustmentQuery,
    GetTenancyDetailsForCreateRentAdjustmentQueryVariables
  >;
export const GetAdjustmentDetailsDocument = gql`
  query getAdjustmentDetails($liabilityEntryId: ID!, $tenancyId: ID!) {
    liabilityEntry(liabilityEntryId: $liabilityEntryId) {
      ... on AdjustmentLiabilityEntry {
        ...adjustmentLiabilityEntry
      }
    }
    tenancy(tenancyId: $tenancyId) {
      id
      ...TenancyProperty
    }
  }
  ${AdjustmentLiabilityEntryFragmentDoc}
  ${TenancyPropertyFragmentDoc}
`;

/**
 * __useGetAdjustmentDetailsQuery__
 *
 * To run a query within a React component, call `useGetAdjustmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdjustmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdjustmentDetailsQuery({
 *   variables: {
 *      liabilityEntryId: // value for 'liabilityEntryId'
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetAdjustmentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdjustmentDetailsQuery,
    GetAdjustmentDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAdjustmentDetailsQuery,
    GetAdjustmentDetailsQueryVariables
  >(GetAdjustmentDetailsDocument, baseOptions);
}
export function useGetAdjustmentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdjustmentDetailsQuery,
    GetAdjustmentDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAdjustmentDetailsQuery,
    GetAdjustmentDetailsQueryVariables
  >(GetAdjustmentDetailsDocument, baseOptions);
}
export type GetAdjustmentDetailsQueryHookResult = ReturnType<
  typeof useGetAdjustmentDetailsQuery
>;
export type GetAdjustmentDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAdjustmentDetailsLazyQuery
>;
export type GetAdjustmentDetailsQueryResult = Apollo.QueryResult<
  GetAdjustmentDetailsQuery,
  GetAdjustmentDetailsQueryVariables
>;
export const AdjustmentLiabilityEntriesForRentAdjustmentsListDocument = gql`
  query AdjustmentLiabilityEntriesForRentAdjustmentsList($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      liability {
        id
        reference
        entries(filterTypes: ADJUSTMENT) {
          items {
            ...adjustmentLiabilityEntry
          }
        }
      }
    }
  }
  ${AdjustmentLiabilityEntryFragmentDoc}
`;

/**
 * __useAdjustmentLiabilityEntriesForRentAdjustmentsListQuery__
 *
 * To run a query within a React component, call `useAdjustmentLiabilityEntriesForRentAdjustmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdjustmentLiabilityEntriesForRentAdjustmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdjustmentLiabilityEntriesForRentAdjustmentsListQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useAdjustmentLiabilityEntriesForRentAdjustmentsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdjustmentLiabilityEntriesForRentAdjustmentsListQuery,
    AdjustmentLiabilityEntriesForRentAdjustmentsListQueryVariables
  >
) {
  return Apollo.useQuery<
    AdjustmentLiabilityEntriesForRentAdjustmentsListQuery,
    AdjustmentLiabilityEntriesForRentAdjustmentsListQueryVariables
  >(AdjustmentLiabilityEntriesForRentAdjustmentsListDocument, baseOptions);
}
export function useAdjustmentLiabilityEntriesForRentAdjustmentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdjustmentLiabilityEntriesForRentAdjustmentsListQuery,
    AdjustmentLiabilityEntriesForRentAdjustmentsListQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    AdjustmentLiabilityEntriesForRentAdjustmentsListQuery,
    AdjustmentLiabilityEntriesForRentAdjustmentsListQueryVariables
  >(AdjustmentLiabilityEntriesForRentAdjustmentsListDocument, baseOptions);
}
export type AdjustmentLiabilityEntriesForRentAdjustmentsListQueryHookResult =
  ReturnType<typeof useAdjustmentLiabilityEntriesForRentAdjustmentsListQuery>;
export type AdjustmentLiabilityEntriesForRentAdjustmentsListLazyQueryHookResult =
  ReturnType<
    typeof useAdjustmentLiabilityEntriesForRentAdjustmentsListLazyQuery
  >;
export type AdjustmentLiabilityEntriesForRentAdjustmentsListQueryResult =
  Apollo.QueryResult<
    AdjustmentLiabilityEntriesForRentAdjustmentsListQuery,
    AdjustmentLiabilityEntriesForRentAdjustmentsListQueryVariables
  >;
export const TenancyStartDateDocument = gql`
  query TenancyStartDate($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      startDate
      management {
        id
        managingEntity {
          id
          timezone
        }
      }
    }
  }
`;

/**
 * __useTenancyStartDateQuery__
 *
 * To run a query within a React component, call `useTenancyStartDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenancyStartDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenancyStartDateQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useTenancyStartDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    TenancyStartDateQuery,
    TenancyStartDateQueryVariables
  >
) {
  return Apollo.useQuery<TenancyStartDateQuery, TenancyStartDateQueryVariables>(
    TenancyStartDateDocument,
    baseOptions
  );
}
export function useTenancyStartDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TenancyStartDateQuery,
    TenancyStartDateQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    TenancyStartDateQuery,
    TenancyStartDateQueryVariables
  >(TenancyStartDateDocument, baseOptions);
}
export type TenancyStartDateQueryHookResult = ReturnType<
  typeof useTenancyStartDateQuery
>;
export type TenancyStartDateLazyQueryHookResult = ReturnType<
  typeof useTenancyStartDateLazyQuery
>;
export type TenancyStartDateQueryResult = Apollo.QueryResult<
  TenancyStartDateQuery,
  TenancyStartDateQueryVariables
>;
export const ReverseRentAdjustmentDocument = gql`
  mutation reverseRentAdjustment($input: ReverseAdjustmentInput!) {
    reverseAdjustment(input: $input) {
      id
      reversesAdjustment {
        id
        amount {
          cents
        }
        createdAt
        effectiveAt
        description
        reversedByAdjustment {
          id
          effectiveAt
          description
        }
      }
      liability {
        id
        reference
        entries(filterTypes: ADJUSTMENT) {
          items {
            ...adjustmentLiabilityEntry
          }
        }
      }
    }
  }
  ${AdjustmentLiabilityEntryFragmentDoc}
`;
export type ReverseRentAdjustmentMutationFn = Apollo.MutationFunction<
  ReverseRentAdjustmentMutation,
  ReverseRentAdjustmentMutationVariables
>;

/**
 * __useReverseRentAdjustmentMutation__
 *
 * To run a mutation, you first call `useReverseRentAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReverseRentAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reverseRentAdjustmentMutation, { data, loading, error }] = useReverseRentAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReverseRentAdjustmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReverseRentAdjustmentMutation,
    ReverseRentAdjustmentMutationVariables
  >
) {
  return Apollo.useMutation<
    ReverseRentAdjustmentMutation,
    ReverseRentAdjustmentMutationVariables
  >(ReverseRentAdjustmentDocument, baseOptions);
}
export type ReverseRentAdjustmentMutationHookResult = ReturnType<
  typeof useReverseRentAdjustmentMutation
>;
export type ReverseRentAdjustmentMutationResult =
  Apollo.MutationResult<ReverseRentAdjustmentMutation>;
export type ReverseRentAdjustmentMutationOptions = Apollo.BaseMutationOptions<
  ReverseRentAdjustmentMutation,
  ReverseRentAdjustmentMutationVariables
>;
export const UpsertTenancyBondClaimsDocument = gql`
  mutation upsertTenancyBondClaims($bond: UpsertTenancyBondClaims!) {
    upsertTenancyBondClaims(bond: $bond) {
      ...TenancyBondClaim
    }
  }
  ${TenancyBondClaimFragmentDoc}
`;
export type UpsertTenancyBondClaimsMutationFn = Apollo.MutationFunction<
  UpsertTenancyBondClaimsMutation,
  UpsertTenancyBondClaimsMutationVariables
>;

/**
 * __useUpsertTenancyBondClaimsMutation__
 *
 * To run a mutation, you first call `useUpsertTenancyBondClaimsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTenancyBondClaimsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTenancyBondClaimsMutation, { data, loading, error }] = useUpsertTenancyBondClaimsMutation({
 *   variables: {
 *      bond: // value for 'bond'
 *   },
 * });
 */
export function useUpsertTenancyBondClaimsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertTenancyBondClaimsMutation,
    UpsertTenancyBondClaimsMutationVariables
  >
) {
  return Apollo.useMutation<
    UpsertTenancyBondClaimsMutation,
    UpsertTenancyBondClaimsMutationVariables
  >(UpsertTenancyBondClaimsDocument, baseOptions);
}
export type UpsertTenancyBondClaimsMutationHookResult = ReturnType<
  typeof useUpsertTenancyBondClaimsMutation
>;
export type UpsertTenancyBondClaimsMutationResult =
  Apollo.MutationResult<UpsertTenancyBondClaimsMutation>;
export type UpsertTenancyBondClaimsMutationOptions = Apollo.BaseMutationOptions<
  UpsertTenancyBondClaimsMutation,
  UpsertTenancyBondClaimsMutationVariables
>;
export const GetDisbursementPeriodDataDocument = gql`
  query getDisbursementPeriodData($managingOrgId: ID!) {
    agencyIncomeDisbursementPeriodReport(managingOrgId: $managingOrgId) {
      rows {
        disbursementPeriodSk
        disbursementDisplayName
        openingBalance
        closingBalance
      }
      columns {
        header
        key
      }
    }
  }
`;

/**
 * __useGetDisbursementPeriodDataQuery__
 *
 * To run a query within a React component, call `useGetDisbursementPeriodDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisbursementPeriodDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisbursementPeriodDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *   },
 * });
 */
export function useGetDisbursementPeriodDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDisbursementPeriodDataQuery,
    GetDisbursementPeriodDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetDisbursementPeriodDataQuery,
    GetDisbursementPeriodDataQueryVariables
  >(GetDisbursementPeriodDataDocument, baseOptions);
}
export function useGetDisbursementPeriodDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDisbursementPeriodDataQuery,
    GetDisbursementPeriodDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetDisbursementPeriodDataQuery,
    GetDisbursementPeriodDataQueryVariables
  >(GetDisbursementPeriodDataDocument, baseOptions);
}
export type GetDisbursementPeriodDataQueryHookResult = ReturnType<
  typeof useGetDisbursementPeriodDataQuery
>;
export type GetDisbursementPeriodDataLazyQueryHookResult = ReturnType<
  typeof useGetDisbursementPeriodDataLazyQuery
>;
export type GetDisbursementPeriodDataQueryResult = Apollo.QueryResult<
  GetDisbursementPeriodDataQuery,
  GetDisbursementPeriodDataQueryVariables
>;
export const GetBillIssueTableDataDocument = gql`
  query getBillIssueTableData($managingOrgId: ID!, $teamId: [ID!]) {
    billIssuesSummaryReport(managingOrgId: $managingOrgId, teamId: $teamId) {
      rows {
        billIssue
        billCount
        billCountPercent
        drilldownFields
      }
      columns {
        header
        key
      }
    }
  }
`;

/**
 * __useGetBillIssueTableDataQuery__
 *
 * To run a query within a React component, call `useGetBillIssueTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillIssueTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillIssueTableDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetBillIssueTableDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBillIssueTableDataQuery,
    GetBillIssueTableDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetBillIssueTableDataQuery,
    GetBillIssueTableDataQueryVariables
  >(GetBillIssueTableDataDocument, baseOptions);
}
export function useGetBillIssueTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillIssueTableDataQuery,
    GetBillIssueTableDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetBillIssueTableDataQuery,
    GetBillIssueTableDataQueryVariables
  >(GetBillIssueTableDataDocument, baseOptions);
}
export type GetBillIssueTableDataQueryHookResult = ReturnType<
  typeof useGetBillIssueTableDataQuery
>;
export type GetBillIssueTableDataLazyQueryHookResult = ReturnType<
  typeof useGetBillIssueTableDataLazyQuery
>;
export type GetBillIssueTableDataQueryResult = Apollo.QueryResult<
  GetBillIssueTableDataQuery,
  GetBillIssueTableDataQueryVariables
>;
export const GetBillsDueTableDataDocument = gql`
  query getBillsDueTableData(
    $managingOrgId: ID!
    $billIssue: String
    $teamId: [ID!]
  ) {
    billsDueReport(
      managingOrgId: $managingOrgId
      billIssue: $billIssue
      teamId: $teamId
    ) {
      rows {
        billId
        propertyAddress
        billDate
        billType
        payerEntityType
        supplierName
        billInvoiceNumber
        billAmount
        billCreatedAt
        billPaymentStatus
        billDueDays
      }
      columns {
        header
        key
      }
    }
  }
`;

/**
 * __useGetBillsDueTableDataQuery__
 *
 * To run a query within a React component, call `useGetBillsDueTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillsDueTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillsDueTableDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      billIssue: // value for 'billIssue'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetBillsDueTableDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBillsDueTableDataQuery,
    GetBillsDueTableDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetBillsDueTableDataQuery,
    GetBillsDueTableDataQueryVariables
  >(GetBillsDueTableDataDocument, baseOptions);
}
export function useGetBillsDueTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillsDueTableDataQuery,
    GetBillsDueTableDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetBillsDueTableDataQuery,
    GetBillsDueTableDataQueryVariables
  >(GetBillsDueTableDataDocument, baseOptions);
}
export type GetBillsDueTableDataQueryHookResult = ReturnType<
  typeof useGetBillsDueTableDataQuery
>;
export type GetBillsDueTableDataLazyQueryHookResult = ReturnType<
  typeof useGetBillsDueTableDataLazyQuery
>;
export type GetBillsDueTableDataQueryResult = Apollo.QueryResult<
  GetBillsDueTableDataQuery,
  GetBillsDueTableDataQueryVariables
>;
export const GetBondsReportDocument = gql`
  query getBondsReport(
    $managingOrgId: ID!
    $teamId: [ID!]
    $searchTerm: String
  ) {
    bondsReport(
      managingOrgId: $managingOrgId
      teamId: $teamId
      searchTerm: $searchTerm
    ) {
      type
      columns {
        key
        header
      }
      rows {
        bondReference
        bondStatus
        bondStatusDescription
        bondAmount
        renterNames
        propertyAddress
        managementId
        dateClaimCreated
      }
    }
  }
`;

/**
 * __useGetBondsReportQuery__
 *
 * To run a query within a React component, call `useGetBondsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBondsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBondsReportQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetBondsReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBondsReportQuery,
    GetBondsReportQueryVariables
  >
) {
  return Apollo.useQuery<GetBondsReportQuery, GetBondsReportQueryVariables>(
    GetBondsReportDocument,
    baseOptions
  );
}
export function useGetBondsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBondsReportQuery,
    GetBondsReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetBondsReportQuery, GetBondsReportQueryVariables>(
    GetBondsReportDocument,
    baseOptions
  );
}
export type GetBondsReportQueryHookResult = ReturnType<
  typeof useGetBondsReportQuery
>;
export type GetBondsReportLazyQueryHookResult = ReturnType<
  typeof useGetBondsReportLazyQuery
>;
export type GetBondsReportQueryResult = Apollo.QueryResult<
  GetBondsReportQuery,
  GetBondsReportQueryVariables
>;
export const GetCentrepayReportDocument = gql`
  query getCentrepayReport(
    $managingOrgId: ID!
    $teamId: [ID!]
    $searchTerm: String
  ) {
    centrepayReport(
      managingOrgId: $managingOrgId
      teamId: $teamId
      searchTerm: $searchTerm
    ) {
      type
      rows {
        crnReference
        renterNames
        latestTransactionDate
        latestTransactionAmount
        agencyId
        managementId
        propertyAddress
        teamId
        crnStatus
        crnDeletedDate
        tenancyStatus
      }
    }
  }
`;

/**
 * __useGetCentrepayReportQuery__
 *
 * To run a query within a React component, call `useGetCentrepayReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCentrepayReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCentrepayReportQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetCentrepayReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCentrepayReportQuery,
    GetCentrepayReportQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCentrepayReportQuery,
    GetCentrepayReportQueryVariables
  >(GetCentrepayReportDocument, baseOptions);
}
export function useGetCentrepayReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCentrepayReportQuery,
    GetCentrepayReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCentrepayReportQuery,
    GetCentrepayReportQueryVariables
  >(GetCentrepayReportDocument, baseOptions);
}
export type GetCentrepayReportQueryHookResult = ReturnType<
  typeof useGetCentrepayReportQuery
>;
export type GetCentrepayReportLazyQueryHookResult = ReturnType<
  typeof useGetCentrepayReportLazyQuery
>;
export type GetCentrepayReportQueryResult = Apollo.QueryResult<
  GetCentrepayReportQuery,
  GetCentrepayReportQueryVariables
>;
export const GetAgencyFinancialPerformanceReportDocument = gql`
  query getAgencyFinancialPerformanceReport(
    $managingOrgId: ID!
    $teamId: [ID!]
  ) {
    agencyFinancialPerformanceReport(
      managingOrgId: $managingOrgId
      teamId: $teamId
    ) {
      totalRevenue {
        label
        thisPeriodValue
        periodLabel
        previousPeriodValue
        changeValue
        changeType
      }
      managementFeeRevenue {
        label
        thisPeriodValue
        periodLabel
        previousPeriodValue
        changeValue
        changeType
      }
      overFeeRevenue {
        label
        thisPeriodValue
        periodLabel
        previousPeriodValue
        changeValue
        changeType
      }
      propertiesUnderManagement {
        label
        thisPeriodValue
        periodLabel
        previousPeriodValue
        changeValue
        changeType
      }
      averageManagementFeeAmount {
        label
        thisPeriodValue
        periodLabel
        previousPeriodValue
        changeValue
        changeType
      }
      averageManagementFeePercent {
        label
        thisPeriodValue
        periodLabel
        previousPeriodValue
        changeValue
        changeType
      }
    }
  }
`;

/**
 * __useGetAgencyFinancialPerformanceReportQuery__
 *
 * To run a query within a React component, call `useGetAgencyFinancialPerformanceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyFinancialPerformanceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyFinancialPerformanceReportQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetAgencyFinancialPerformanceReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgencyFinancialPerformanceReportQuery,
    GetAgencyFinancialPerformanceReportQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAgencyFinancialPerformanceReportQuery,
    GetAgencyFinancialPerformanceReportQueryVariables
  >(GetAgencyFinancialPerformanceReportDocument, baseOptions);
}
export function useGetAgencyFinancialPerformanceReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgencyFinancialPerformanceReportQuery,
    GetAgencyFinancialPerformanceReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAgencyFinancialPerformanceReportQuery,
    GetAgencyFinancialPerformanceReportQueryVariables
  >(GetAgencyFinancialPerformanceReportDocument, baseOptions);
}
export type GetAgencyFinancialPerformanceReportQueryHookResult = ReturnType<
  typeof useGetAgencyFinancialPerformanceReportQuery
>;
export type GetAgencyFinancialPerformanceReportLazyQueryHookResult = ReturnType<
  typeof useGetAgencyFinancialPerformanceReportLazyQuery
>;
export type GetAgencyFinancialPerformanceReportQueryResult = Apollo.QueryResult<
  GetAgencyFinancialPerformanceReportQuery,
  GetAgencyFinancialPerformanceReportQueryVariables
>;
export const GetAgencyIncomeByMonthReportDocument = gql`
  query getAgencyIncomeByMonthReport($managingOrgId: ID!, $teamId: [ID!]) {
    agencyIncomeByMonthReport(managingOrgId: $managingOrgId, teamId: $teamId) {
      labels {
        raw
        formatted
      }
      series {
        name
        values
      }
    }
  }
`;

/**
 * __useGetAgencyIncomeByMonthReportQuery__
 *
 * To run a query within a React component, call `useGetAgencyIncomeByMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyIncomeByMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyIncomeByMonthReportQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetAgencyIncomeByMonthReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgencyIncomeByMonthReportQuery,
    GetAgencyIncomeByMonthReportQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAgencyIncomeByMonthReportQuery,
    GetAgencyIncomeByMonthReportQueryVariables
  >(GetAgencyIncomeByMonthReportDocument, baseOptions);
}
export function useGetAgencyIncomeByMonthReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgencyIncomeByMonthReportQuery,
    GetAgencyIncomeByMonthReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAgencyIncomeByMonthReportQuery,
    GetAgencyIncomeByMonthReportQueryVariables
  >(GetAgencyIncomeByMonthReportDocument, baseOptions);
}
export type GetAgencyIncomeByMonthReportQueryHookResult = ReturnType<
  typeof useGetAgencyIncomeByMonthReportQuery
>;
export type GetAgencyIncomeByMonthReportLazyQueryHookResult = ReturnType<
  typeof useGetAgencyIncomeByMonthReportLazyQuery
>;
export type GetAgencyIncomeByMonthReportQueryResult = Apollo.QueryResult<
  GetAgencyIncomeByMonthReportQuery,
  GetAgencyIncomeByMonthReportQueryVariables
>;
export const GetCurrentPropertyCountDocument = gql`
  query getCurrentPropertyCount($managingOrgId: ID, $teamId: [ID!]) {
    propertyIssuesSummaryReport(
      managingOrgId: $managingOrgId
      teamId: $teamId
    ) {
      headerFields {
        totalPropertyCount
      }
    }
  }
`;

/**
 * __useGetCurrentPropertyCountQuery__
 *
 * To run a query within a React component, call `useGetCurrentPropertyCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentPropertyCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentPropertyCountQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetCurrentPropertyCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentPropertyCountQuery,
    GetCurrentPropertyCountQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentPropertyCountQuery,
    GetCurrentPropertyCountQueryVariables
  >(GetCurrentPropertyCountDocument, baseOptions);
}
export function useGetCurrentPropertyCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentPropertyCountQuery,
    GetCurrentPropertyCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentPropertyCountQuery,
    GetCurrentPropertyCountQueryVariables
  >(GetCurrentPropertyCountDocument, baseOptions);
}
export type GetCurrentPropertyCountQueryHookResult = ReturnType<
  typeof useGetCurrentPropertyCountQuery
>;
export type GetCurrentPropertyCountLazyQueryHookResult = ReturnType<
  typeof useGetCurrentPropertyCountLazyQuery
>;
export type GetCurrentPropertyCountQueryResult = Apollo.QueryResult<
  GetCurrentPropertyCountQuery,
  GetCurrentPropertyCountQueryVariables
>;
export const GetAgencyIncomeByChartOfAccountReportDataDocument = gql`
  query getAgencyIncomeByChartOfAccountReportData(
    $managingOrgId: ID!
    $disbursementPeriodSk: String
    $startDate: String
    $endDate: String
    $teamId: [ID!]
  ) {
    agencyIncomeByChartOfAccountReport(
      managingOrgId: $managingOrgId
      disbursementPeriodSk: $disbursementPeriodSk
      startDate: $startDate
      endDate: $endDate
      teamId: $teamId
    ) {
      headerFields {
        totalIncomeAmount
      }
      rows {
        chartOfAccountName
        incomeAmount
      }
      columns {
        header
        key
      }
    }
  }
`;

/**
 * __useGetAgencyIncomeByChartOfAccountReportDataQuery__
 *
 * To run a query within a React component, call `useGetAgencyIncomeByChartOfAccountReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyIncomeByChartOfAccountReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyIncomeByChartOfAccountReportDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      disbursementPeriodSk: // value for 'disbursementPeriodSk'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetAgencyIncomeByChartOfAccountReportDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgencyIncomeByChartOfAccountReportDataQuery,
    GetAgencyIncomeByChartOfAccountReportDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAgencyIncomeByChartOfAccountReportDataQuery,
    GetAgencyIncomeByChartOfAccountReportDataQueryVariables
  >(GetAgencyIncomeByChartOfAccountReportDataDocument, baseOptions);
}
export function useGetAgencyIncomeByChartOfAccountReportDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgencyIncomeByChartOfAccountReportDataQuery,
    GetAgencyIncomeByChartOfAccountReportDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAgencyIncomeByChartOfAccountReportDataQuery,
    GetAgencyIncomeByChartOfAccountReportDataQueryVariables
  >(GetAgencyIncomeByChartOfAccountReportDataDocument, baseOptions);
}
export type GetAgencyIncomeByChartOfAccountReportDataQueryHookResult =
  ReturnType<typeof useGetAgencyIncomeByChartOfAccountReportDataQuery>;
export type GetAgencyIncomeByChartOfAccountReportDataLazyQueryHookResult =
  ReturnType<typeof useGetAgencyIncomeByChartOfAccountReportDataLazyQuery>;
export type GetAgencyIncomeByChartOfAccountReportDataQueryResult =
  Apollo.QueryResult<
    GetAgencyIncomeByChartOfAccountReportDataQuery,
    GetAgencyIncomeByChartOfAccountReportDataQueryVariables
  >;
export const GetAgencyIncomeByPropertyReportDataDocument = gql`
  query getAgencyIncomeByPropertyReportData(
    $managingOrgId: ID!
    $disbursementPeriodSk: String
    $startDate: String
    $endDate: String
    $teamId: [ID!]
    $includeActiveProperties: Boolean
  ) {
    agencyIncomeByPropertyReport(
      managingOrgId: $managingOrgId
      disbursementPeriodSk: $disbursementPeriodSk
      startDate: $startDate
      endDate: $endDate
      teamId: $teamId
      includeActiveProperties: $includeActiveProperties
    ) {
      headerFields {
        total {
          ...AgencyIncomeByPropertyReportTotal
        }
        average {
          ...AgencyIncomeByPropertyReportTotal
        }
      }
      rows {
        teamId
        teamName
        propertyId
        managementId
        propertyAddress
        rentCollectedAmount
        managementFeeAmount
        managementFeePercent
        otherFeeAmount
        totalFeeAmount
      }
      columns {
        header
        key
      }
    }
  }
  ${AgencyIncomeByPropertyReportTotalFragmentDoc}
`;

/**
 * __useGetAgencyIncomeByPropertyReportDataQuery__
 *
 * To run a query within a React component, call `useGetAgencyIncomeByPropertyReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyIncomeByPropertyReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyIncomeByPropertyReportDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      disbursementPeriodSk: // value for 'disbursementPeriodSk'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      teamId: // value for 'teamId'
 *      includeActiveProperties: // value for 'includeActiveProperties'
 *   },
 * });
 */
export function useGetAgencyIncomeByPropertyReportDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgencyIncomeByPropertyReportDataQuery,
    GetAgencyIncomeByPropertyReportDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAgencyIncomeByPropertyReportDataQuery,
    GetAgencyIncomeByPropertyReportDataQueryVariables
  >(GetAgencyIncomeByPropertyReportDataDocument, baseOptions);
}
export function useGetAgencyIncomeByPropertyReportDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgencyIncomeByPropertyReportDataQuery,
    GetAgencyIncomeByPropertyReportDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAgencyIncomeByPropertyReportDataQuery,
    GetAgencyIncomeByPropertyReportDataQueryVariables
  >(GetAgencyIncomeByPropertyReportDataDocument, baseOptions);
}
export type GetAgencyIncomeByPropertyReportDataQueryHookResult = ReturnType<
  typeof useGetAgencyIncomeByPropertyReportDataQuery
>;
export type GetAgencyIncomeByPropertyReportDataLazyQueryHookResult = ReturnType<
  typeof useGetAgencyIncomeByPropertyReportDataLazyQuery
>;
export type GetAgencyIncomeByPropertyReportDataQueryResult = Apollo.QueryResult<
  GetAgencyIncomeByPropertyReportDataQuery,
  GetAgencyIncomeByPropertyReportDataQueryVariables
>;
export const GetPropertiesTableDataDocument = gql`
  query getPropertiesTableData(
    $managingOrgId: ID!
    $propertyIssue: String
    $teamId: [ID!]
  ) {
    propertiesReport(
      managingOrgId: $managingOrgId
      propertyIssue: $propertyIssue
      teamId: $teamId
    ) {
      rows {
        managementId
        propertyAddress
        propertyOnAppAt
        propertyPayingRentOnAiloSince
        overdueRent
        arrearsAmount
        arrearsDays
        vacateDate
        vacantDays
        totalWalletBalance
        availableWalletBalance
        lastRentPaymentInAilo
        investorNames
        renterNames
        previousDisbursementDate
        daysSincePreviousDisbursement
        leaseExpiryDate
        daysSinceLeaseExpiry
        rentNextDueDate
        tenancyPaidToDate
        overdueDays
        investorInvitedAt
        renterInvitedAt
        scheduledRentReviewDate
        rentAmount
        rentPeriod
      }
      columns {
        header
        key
      }
    }
  }
`;

/**
 * __useGetPropertiesTableDataQuery__
 *
 * To run a query within a React component, call `useGetPropertiesTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertiesTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertiesTableDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      propertyIssue: // value for 'propertyIssue'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetPropertiesTableDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertiesTableDataQuery,
    GetPropertiesTableDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertiesTableDataQuery,
    GetPropertiesTableDataQueryVariables
  >(GetPropertiesTableDataDocument, baseOptions);
}
export function useGetPropertiesTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertiesTableDataQuery,
    GetPropertiesTableDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertiesTableDataQuery,
    GetPropertiesTableDataQueryVariables
  >(GetPropertiesTableDataDocument, baseOptions);
}
export type GetPropertiesTableDataQueryHookResult = ReturnType<
  typeof useGetPropertiesTableDataQuery
>;
export type GetPropertiesTableDataLazyQueryHookResult = ReturnType<
  typeof useGetPropertiesTableDataLazyQuery
>;
export type GetPropertiesTableDataQueryResult = Apollo.QueryResult<
  GetPropertiesTableDataQuery,
  GetPropertiesTableDataQueryVariables
>;
export const GetInvestorOnAppTableDataDocument = gql`
  query getInvestorOnAppTableData($managingOrgId: ID!, $teamId: [ID!]) {
    investorOnAppReport(managingOrgId: $managingOrgId, teamId: $teamId) {
      rows {
        isPrimaryInvestor
        legalEntityId
        investorStatus
        investorName
        hasNotificationsEnabled
        totalBillsDue
        properties
        onAppAt
      }
      columns {
        header
        key
      }
    }
  }
`;

/**
 * __useGetInvestorOnAppTableDataQuery__
 *
 * To run a query within a React component, call `useGetInvestorOnAppTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorOnAppTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvestorOnAppTableDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetInvestorOnAppTableDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvestorOnAppTableDataQuery,
    GetInvestorOnAppTableDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetInvestorOnAppTableDataQuery,
    GetInvestorOnAppTableDataQueryVariables
  >(GetInvestorOnAppTableDataDocument, baseOptions);
}
export function useGetInvestorOnAppTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvestorOnAppTableDataQuery,
    GetInvestorOnAppTableDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetInvestorOnAppTableDataQuery,
    GetInvestorOnAppTableDataQueryVariables
  >(GetInvestorOnAppTableDataDocument, baseOptions);
}
export type GetInvestorOnAppTableDataQueryHookResult = ReturnType<
  typeof useGetInvestorOnAppTableDataQuery
>;
export type GetInvestorOnAppTableDataLazyQueryHookResult = ReturnType<
  typeof useGetInvestorOnAppTableDataLazyQuery
>;
export type GetInvestorOnAppTableDataQueryResult = Apollo.QueryResult<
  GetInvestorOnAppTableDataQuery,
  GetInvestorOnAppTableDataQueryVariables
>;
export const GetNotificationsTableDataDocument = gql`
  query getNotificationsTableData(
    $managingOrgId: ID!
    $teamId: [ID!]
    $searchTerm: String
  ) {
    notificationsReport(
      managingOrgId: $managingOrgId
      teamId: $teamId
      searchTerm: $searchTerm
    ) {
      type
      columns {
        key
        header
      }
      rows {
        agencyId
        legalEntityId
        agencyId
        tenancyId
        personName
        personId
        personType
        notificationChannel
        notificationType
        notificationTitle
        notificationDeliveryStatus
        sentAt
        propertyAddress
        managementId
        teamId
      }
    }
  }
`;

/**
 * __useGetNotificationsTableDataQuery__
 *
 * To run a query within a React component, call `useGetNotificationsTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsTableDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetNotificationsTableDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationsTableDataQuery,
    GetNotificationsTableDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetNotificationsTableDataQuery,
    GetNotificationsTableDataQueryVariables
  >(GetNotificationsTableDataDocument, baseOptions);
}
export function useGetNotificationsTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationsTableDataQuery,
    GetNotificationsTableDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetNotificationsTableDataQuery,
    GetNotificationsTableDataQueryVariables
  >(GetNotificationsTableDataDocument, baseOptions);
}
export type GetNotificationsTableDataQueryHookResult = ReturnType<
  typeof useGetNotificationsTableDataQuery
>;
export type GetNotificationsTableDataLazyQueryHookResult = ReturnType<
  typeof useGetNotificationsTableDataLazyQuery
>;
export type GetNotificationsTableDataQueryResult = Apollo.QueryResult<
  GetNotificationsTableDataQuery,
  GetNotificationsTableDataQueryVariables
>;
export const GetPropertyLiveTableDataDocument = gql`
  query getPropertyLiveTableData($managingOrgId: ID!, $teamId: [ID!]) {
    propertyLiveReport(managingOrgId: $managingOrgId, teamId: $teamId) {
      rows {
        teamId
        teamName
        propertyId
        managementId
        propertyAddress
        investorNames
        renterNames
        propertyLiveAt
      }
      columns {
        header
        key
      }
      headerFields {
        totalPropertyCount
        activePropertyCount
        pendingPropertyCount
        vacantPropertyCount
        activePropertyPercent
      }
    }
  }
`;

/**
 * __useGetPropertyLiveTableDataQuery__
 *
 * To run a query within a React component, call `useGetPropertyLiveTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyLiveTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyLiveTableDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetPropertyLiveTableDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyLiveTableDataQuery,
    GetPropertyLiveTableDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertyLiveTableDataQuery,
    GetPropertyLiveTableDataQueryVariables
  >(GetPropertyLiveTableDataDocument, baseOptions);
}
export function useGetPropertyLiveTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyLiveTableDataQuery,
    GetPropertyLiveTableDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertyLiveTableDataQuery,
    GetPropertyLiveTableDataQueryVariables
  >(GetPropertyLiveTableDataDocument, baseOptions);
}
export type GetPropertyLiveTableDataQueryHookResult = ReturnType<
  typeof useGetPropertyLiveTableDataQuery
>;
export type GetPropertyLiveTableDataLazyQueryHookResult = ReturnType<
  typeof useGetPropertyLiveTableDataLazyQuery
>;
export type GetPropertyLiveTableDataQueryResult = Apollo.QueryResult<
  GetPropertyLiveTableDataQuery,
  GetPropertyLiveTableDataQueryVariables
>;
export const GetRenterOnAppTableDataDocument = gql`
  query getRenterOnAppTableData($managingOrgId: ID!, $teamId: [ID!]) {
    renterOnAppReport(managingOrgId: $managingOrgId, teamId: $teamId) {
      rows {
        propertyId
        managementId
        tenancyId
        legalEntityId
        propertyAddress
        renterName
        renterOnAppAt
      }
      columns {
        header
        key
      }
    }
  }
`;

/**
 * __useGetRenterOnAppTableDataQuery__
 *
 * To run a query within a React component, call `useGetRenterOnAppTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRenterOnAppTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRenterOnAppTableDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetRenterOnAppTableDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRenterOnAppTableDataQuery,
    GetRenterOnAppTableDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetRenterOnAppTableDataQuery,
    GetRenterOnAppTableDataQueryVariables
  >(GetRenterOnAppTableDataDocument, baseOptions);
}
export function useGetRenterOnAppTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRenterOnAppTableDataQuery,
    GetRenterOnAppTableDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetRenterOnAppTableDataQuery,
    GetRenterOnAppTableDataQueryVariables
  >(GetRenterOnAppTableDataDocument, baseOptions);
}
export type GetRenterOnAppTableDataQueryHookResult = ReturnType<
  typeof useGetRenterOnAppTableDataQuery
>;
export type GetRenterOnAppTableDataLazyQueryHookResult = ReturnType<
  typeof useGetRenterOnAppTableDataLazyQuery
>;
export type GetRenterOnAppTableDataQueryResult = Apollo.QueryResult<
  GetRenterOnAppTableDataQuery,
  GetRenterOnAppTableDataQueryVariables
>;
export const GetFirstRentPaymentsTableDataDocument = gql`
  query getFirstRentPaymentsTableData($managingOrgId: ID!, $teamId: [ID!]) {
    tenancyFirstRentPaymentReport(
      managingOrgId: $managingOrgId
      teamId: $teamId
    ) {
      rows {
        propertyId
        managementId
        tenancyId
        propertyAddress
        firstRentPaymentDate
        renter
        firstRentPaymentAmount
        teamId
        teamName
      }
      columns {
        header
        key
      }
    }
  }
`;

/**
 * __useGetFirstRentPaymentsTableDataQuery__
 *
 * To run a query within a React component, call `useGetFirstRentPaymentsTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstRentPaymentsTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstRentPaymentsTableDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetFirstRentPaymentsTableDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFirstRentPaymentsTableDataQuery,
    GetFirstRentPaymentsTableDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetFirstRentPaymentsTableDataQuery,
    GetFirstRentPaymentsTableDataQueryVariables
  >(GetFirstRentPaymentsTableDataDocument, baseOptions);
}
export function useGetFirstRentPaymentsTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFirstRentPaymentsTableDataQuery,
    GetFirstRentPaymentsTableDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetFirstRentPaymentsTableDataQuery,
    GetFirstRentPaymentsTableDataQueryVariables
  >(GetFirstRentPaymentsTableDataDocument, baseOptions);
}
export type GetFirstRentPaymentsTableDataQueryHookResult = ReturnType<
  typeof useGetFirstRentPaymentsTableDataQuery
>;
export type GetFirstRentPaymentsTableDataLazyQueryHookResult = ReturnType<
  typeof useGetFirstRentPaymentsTableDataLazyQuery
>;
export type GetFirstRentPaymentsTableDataQueryResult = Apollo.QueryResult<
  GetFirstRentPaymentsTableDataQuery,
  GetFirstRentPaymentsTableDataQueryVariables
>;
export const GetPropertyIssueDrilldownTableDataDocument = gql`
  query getPropertyIssueDrilldownTableData(
    $managingOrgId: ID!
    $issue: String!
    $teamId: [ID!]
  ) {
    propertyIssuesDrilldownReport(
      managingOrgId: $managingOrgId
      propertyIssue: $issue
      teamId: $teamId
    ) {
      rows {
        propertyAddress
        propertyOnAppAt
        propertyPayingRentOnAiloSince
        overdueRent
        arrearsAmount
        arrearsDays
        vacateDate
      }
      columns {
        header
        key
      }
    }
  }
`;

/**
 * __useGetPropertyIssueDrilldownTableDataQuery__
 *
 * To run a query within a React component, call `useGetPropertyIssueDrilldownTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyIssueDrilldownTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyIssueDrilldownTableDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      issue: // value for 'issue'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetPropertyIssueDrilldownTableDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyIssueDrilldownTableDataQuery,
    GetPropertyIssueDrilldownTableDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertyIssueDrilldownTableDataQuery,
    GetPropertyIssueDrilldownTableDataQueryVariables
  >(GetPropertyIssueDrilldownTableDataDocument, baseOptions);
}
export function useGetPropertyIssueDrilldownTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyIssueDrilldownTableDataQuery,
    GetPropertyIssueDrilldownTableDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertyIssueDrilldownTableDataQuery,
    GetPropertyIssueDrilldownTableDataQueryVariables
  >(GetPropertyIssueDrilldownTableDataDocument, baseOptions);
}
export type GetPropertyIssueDrilldownTableDataQueryHookResult = ReturnType<
  typeof useGetPropertyIssueDrilldownTableDataQuery
>;
export type GetPropertyIssueDrilldownTableDataLazyQueryHookResult = ReturnType<
  typeof useGetPropertyIssueDrilldownTableDataLazyQuery
>;
export type GetPropertyIssueDrilldownTableDataQueryResult = Apollo.QueryResult<
  GetPropertyIssueDrilldownTableDataQuery,
  GetPropertyIssueDrilldownTableDataQueryVariables
>;
export const PropertyIssuesMissingFeesReportDocument = gql`
  query propertyIssuesMissingFeesReport($managingOrgId: ID!, $teamId: [ID!]) {
    propertyIssuesMissingFeesReport(
      managingOrgId: $managingOrgId
      teamId: $teamId
    ) {
      rows {
        managementId
        propertyAddress
        reason
        feeType
        investorNames
        vacateDate
      }
      columns {
        header
        key
      }
    }
  }
`;

/**
 * __usePropertyIssuesMissingFeesReportQuery__
 *
 * To run a query within a React component, call `usePropertyIssuesMissingFeesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyIssuesMissingFeesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyIssuesMissingFeesReportQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function usePropertyIssuesMissingFeesReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertyIssuesMissingFeesReportQuery,
    PropertyIssuesMissingFeesReportQueryVariables
  >
) {
  return Apollo.useQuery<
    PropertyIssuesMissingFeesReportQuery,
    PropertyIssuesMissingFeesReportQueryVariables
  >(PropertyIssuesMissingFeesReportDocument, baseOptions);
}
export function usePropertyIssuesMissingFeesReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertyIssuesMissingFeesReportQuery,
    PropertyIssuesMissingFeesReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    PropertyIssuesMissingFeesReportQuery,
    PropertyIssuesMissingFeesReportQueryVariables
  >(PropertyIssuesMissingFeesReportDocument, baseOptions);
}
export type PropertyIssuesMissingFeesReportQueryHookResult = ReturnType<
  typeof usePropertyIssuesMissingFeesReportQuery
>;
export type PropertyIssuesMissingFeesReportLazyQueryHookResult = ReturnType<
  typeof usePropertyIssuesMissingFeesReportLazyQuery
>;
export type PropertyIssuesMissingFeesReportQueryResult = Apollo.QueryResult<
  PropertyIssuesMissingFeesReportQuery,
  PropertyIssuesMissingFeesReportQueryVariables
>;
export const GetPropertyIssueTableDataDocument = gql`
  query getPropertyIssueTableData($managingOrgId: ID, $teamId: [ID!]) {
    propertyIssuesSummaryReport(
      managingOrgId: $managingOrgId
      teamId: $teamId
    ) {
      rows {
        propertyIssueId
        propertyIssue
        propertyCount
        propertyCountPercent
        drilldownFields
      }
      columns {
        header
        key
      }
      headerFields {
        totalPropertyCount
        propertyWithIssueCount
        propertyWithIssuePercent
      }
    }
  }
`;

/**
 * __useGetPropertyIssueTableDataQuery__
 *
 * To run a query within a React component, call `useGetPropertyIssueTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyIssueTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyIssueTableDataQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetPropertyIssueTableDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPropertyIssueTableDataQuery,
    GetPropertyIssueTableDataQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertyIssueTableDataQuery,
    GetPropertyIssueTableDataQueryVariables
  >(GetPropertyIssueTableDataDocument, baseOptions);
}
export function useGetPropertyIssueTableDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyIssueTableDataQuery,
    GetPropertyIssueTableDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertyIssueTableDataQuery,
    GetPropertyIssueTableDataQueryVariables
  >(GetPropertyIssueTableDataDocument, baseOptions);
}
export type GetPropertyIssueTableDataQueryHookResult = ReturnType<
  typeof useGetPropertyIssueTableDataQuery
>;
export type GetPropertyIssueTableDataLazyQueryHookResult = ReturnType<
  typeof useGetPropertyIssueTableDataLazyQuery
>;
export type GetPropertyIssueTableDataQueryResult = Apollo.QueryResult<
  GetPropertyIssueTableDataQuery,
  GetPropertyIssueTableDataQueryVariables
>;
export const AgencyIncomeReportDocument = gql`
  query agencyIncomeReport(
    $managingOrgId: ID!
    $disbursementPeriodSk: String
    $startDate: LocalDate
    $endDate: LocalDate
    $teamIds: [ID!]
    $includeMonth: Boolean = false
    $includeFinancialYearAU: Boolean = false
    $includeQuarter: Boolean = false
  ) {
    agencyIncomeReport(
      managingOrgId: $managingOrgId
      disbursementPeriodSk: $disbursementPeriodSk
      startDate: $startDate
      endDate: $endDate
      teamIds: $teamIds
    ) {
      rows {
        incomeAmount
        month @include(if: $includeMonth)
        financialYearAU @include(if: $includeFinancialYearAU)
        feeName
        qtr @include(if: $includeQuarter)
        __typename
      }
      lastUpdated
      __typename
    }
  }
`;

/**
 * __useAgencyIncomeReportQuery__
 *
 * To run a query within a React component, call `useAgencyIncomeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyIncomeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyIncomeReportQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      disbursementPeriodSk: // value for 'disbursementPeriodSk'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      teamIds: // value for 'teamIds'
 *      includeMonth: // value for 'includeMonth'
 *      includeFinancialYearAU: // value for 'includeFinancialYearAU'
 *      includeQuarter: // value for 'includeQuarter'
 *   },
 * });
 */
export function useAgencyIncomeReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyIncomeReportQuery,
    AgencyIncomeReportQueryVariables
  >
) {
  return Apollo.useQuery<
    AgencyIncomeReportQuery,
    AgencyIncomeReportQueryVariables
  >(AgencyIncomeReportDocument, baseOptions);
}
export function useAgencyIncomeReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyIncomeReportQuery,
    AgencyIncomeReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    AgencyIncomeReportQuery,
    AgencyIncomeReportQueryVariables
  >(AgencyIncomeReportDocument, baseOptions);
}
export type AgencyIncomeReportQueryHookResult = ReturnType<
  typeof useAgencyIncomeReportQuery
>;
export type AgencyIncomeReportLazyQueryHookResult = ReturnType<
  typeof useAgencyIncomeReportLazyQuery
>;
export type AgencyIncomeReportQueryResult = Apollo.QueryResult<
  AgencyIncomeReportQuery,
  AgencyIncomeReportQueryVariables
>;
export const AgencyIncomeDateDocument = gql`
  query agencyIncomeDate($managingOrgId: ID!, $teamIds: [ID!]) {
    agencyIncomeReport(managingOrgId: $managingOrgId, teamIds: $teamIds) {
      rows {
        minDate
      }
    }
  }
`;

/**
 * __useAgencyIncomeDateQuery__
 *
 * To run a query within a React component, call `useAgencyIncomeDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyIncomeDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyIncomeDateQuery({
 *   variables: {
 *      managingOrgId: // value for 'managingOrgId'
 *      teamIds: // value for 'teamIds'
 *   },
 * });
 */
export function useAgencyIncomeDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyIncomeDateQuery,
    AgencyIncomeDateQueryVariables
  >
) {
  return Apollo.useQuery<AgencyIncomeDateQuery, AgencyIncomeDateQueryVariables>(
    AgencyIncomeDateDocument,
    baseOptions
  );
}
export function useAgencyIncomeDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyIncomeDateQuery,
    AgencyIncomeDateQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    AgencyIncomeDateQuery,
    AgencyIncomeDateQueryVariables
  >(AgencyIncomeDateDocument, baseOptions);
}
export type AgencyIncomeDateQueryHookResult = ReturnType<
  typeof useAgencyIncomeDateQuery
>;
export type AgencyIncomeDateLazyQueryHookResult = ReturnType<
  typeof useAgencyIncomeDateLazyQuery
>;
export type AgencyIncomeDateQueryResult = Apollo.QueryResult<
  AgencyIncomeDateQuery,
  AgencyIncomeDateQueryVariables
>;
export const FindEftPaymentSettingsDocument = gql`
  query findEftPaymentSettings($agency: AiloRN!) {
    findEftPaymentSettings(agency: $agency) {
      agency
      abaFileFormat
      directEntryUserId
    }
  }
`;

/**
 * __useFindEftPaymentSettingsQuery__
 *
 * To run a query within a React component, call `useFindEftPaymentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEftPaymentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEftPaymentSettingsQuery({
 *   variables: {
 *      agency: // value for 'agency'
 *   },
 * });
 */
export function useFindEftPaymentSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindEftPaymentSettingsQuery,
    FindEftPaymentSettingsQueryVariables
  >
) {
  return Apollo.useQuery<
    FindEftPaymentSettingsQuery,
    FindEftPaymentSettingsQueryVariables
  >(FindEftPaymentSettingsDocument, baseOptions);
}
export function useFindEftPaymentSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindEftPaymentSettingsQuery,
    FindEftPaymentSettingsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FindEftPaymentSettingsQuery,
    FindEftPaymentSettingsQueryVariables
  >(FindEftPaymentSettingsDocument, baseOptions);
}
export type FindEftPaymentSettingsQueryHookResult = ReturnType<
  typeof useFindEftPaymentSettingsQuery
>;
export type FindEftPaymentSettingsLazyQueryHookResult = ReturnType<
  typeof useFindEftPaymentSettingsLazyQuery
>;
export type FindEftPaymentSettingsQueryResult = Apollo.QueryResult<
  FindEftPaymentSettingsQuery,
  FindEftPaymentSettingsQueryVariables
>;
export const UpsertEftPaymentSettingsDocument = gql`
  mutation upsertEftPaymentSettings($input: EftPaymentSettingsInput!) {
    upsertEftPaymentSettings(input: $input) {
      agency
      abaFileFormat
      directEntryUserId
    }
  }
`;
export type UpsertEftPaymentSettingsMutationFn = Apollo.MutationFunction<
  UpsertEftPaymentSettingsMutation,
  UpsertEftPaymentSettingsMutationVariables
>;

/**
 * __useUpsertEftPaymentSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertEftPaymentSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEftPaymentSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEftPaymentSettingsMutation, { data, loading, error }] = useUpsertEftPaymentSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertEftPaymentSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertEftPaymentSettingsMutation,
    UpsertEftPaymentSettingsMutationVariables
  >
) {
  return Apollo.useMutation<
    UpsertEftPaymentSettingsMutation,
    UpsertEftPaymentSettingsMutationVariables
  >(UpsertEftPaymentSettingsDocument, baseOptions);
}
export type UpsertEftPaymentSettingsMutationHookResult = ReturnType<
  typeof useUpsertEftPaymentSettingsMutation
>;
export type UpsertEftPaymentSettingsMutationResult =
  Apollo.MutationResult<UpsertEftPaymentSettingsMutation>;
export type UpsertEftPaymentSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertEftPaymentSettingsMutation,
    UpsertEftPaymentSettingsMutationVariables
  >;
export const CreateFeeBlueprintDocument = gql`
  mutation createFeeBlueprint($input: CreateFeeBlueprintInput!) {
    blueprint: createFeeBlueprint(input: $input) {
      ...feeBlueprint
      event {
        type
      }
    }
  }
  ${FeeBlueprintFragmentDoc}
`;
export type CreateFeeBlueprintMutationFn = Apollo.MutationFunction<
  CreateFeeBlueprintMutation,
  CreateFeeBlueprintMutationVariables
>;

/**
 * __useCreateFeeBlueprintMutation__
 *
 * To run a mutation, you first call `useCreateFeeBlueprintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeeBlueprintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeeBlueprintMutation, { data, loading, error }] = useCreateFeeBlueprintMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeeBlueprintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFeeBlueprintMutation,
    CreateFeeBlueprintMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateFeeBlueprintMutation,
    CreateFeeBlueprintMutationVariables
  >(CreateFeeBlueprintDocument, baseOptions);
}
export type CreateFeeBlueprintMutationHookResult = ReturnType<
  typeof useCreateFeeBlueprintMutation
>;
export type CreateFeeBlueprintMutationResult =
  Apollo.MutationResult<CreateFeeBlueprintMutation>;
export type CreateFeeBlueprintMutationOptions = Apollo.BaseMutationOptions<
  CreateFeeBlueprintMutation,
  CreateFeeBlueprintMutationVariables
>;
export const GetFeeBlueprintDocument = gql`
  query getFeeBlueprint($id: ID!) {
    blueprint: feeBlueprint(id: $id) {
      ...feeBlueprint
      event {
        type
      }
    }
  }
  ${FeeBlueprintFragmentDoc}
`;

/**
 * __useGetFeeBlueprintQuery__
 *
 * To run a query within a React component, call `useGetFeeBlueprintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeeBlueprintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeeBlueprintQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeeBlueprintQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeeBlueprintQuery,
    GetFeeBlueprintQueryVariables
  >
) {
  return Apollo.useQuery<GetFeeBlueprintQuery, GetFeeBlueprintQueryVariables>(
    GetFeeBlueprintDocument,
    baseOptions
  );
}
export function useGetFeeBlueprintLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeeBlueprintQuery,
    GetFeeBlueprintQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetFeeBlueprintQuery,
    GetFeeBlueprintQueryVariables
  >(GetFeeBlueprintDocument, baseOptions);
}
export type GetFeeBlueprintQueryHookResult = ReturnType<
  typeof useGetFeeBlueprintQuery
>;
export type GetFeeBlueprintLazyQueryHookResult = ReturnType<
  typeof useGetFeeBlueprintLazyQuery
>;
export type GetFeeBlueprintQueryResult = Apollo.QueryResult<
  GetFeeBlueprintQuery,
  GetFeeBlueprintQueryVariables
>;
export const GetFeeEventsDocument = gql`
  query getFeeEvents {
    feeEvents {
      type
      shortDescription
      longDescription
    }
  }
`;

/**
 * __useGetFeeEventsQuery__
 *
 * To run a query within a React component, call `useGetFeeEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeeEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeeEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeeEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeeEventsQuery,
    GetFeeEventsQueryVariables
  >
) {
  return Apollo.useQuery<GetFeeEventsQuery, GetFeeEventsQueryVariables>(
    GetFeeEventsDocument,
    baseOptions
  );
}
export function useGetFeeEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeeEventsQuery,
    GetFeeEventsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetFeeEventsQuery, GetFeeEventsQueryVariables>(
    GetFeeEventsDocument,
    baseOptions
  );
}
export type GetFeeEventsQueryHookResult = ReturnType<
  typeof useGetFeeEventsQuery
>;
export type GetFeeEventsLazyQueryHookResult = ReturnType<
  typeof useGetFeeEventsLazyQuery
>;
export type GetFeeEventsQueryResult = Apollo.QueryResult<
  GetFeeEventsQuery,
  GetFeeEventsQueryVariables
>;
export const GetFeeTaxCategoriesDocument = gql`
  query getFeeTaxCategories {
    feeTaxCategories {
      id
      name
    }
  }
`;

/**
 * __useGetFeeTaxCategoriesQuery__
 *
 * To run a query within a React component, call `useGetFeeTaxCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeeTaxCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeeTaxCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeeTaxCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeeTaxCategoriesQuery,
    GetFeeTaxCategoriesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetFeeTaxCategoriesQuery,
    GetFeeTaxCategoriesQueryVariables
  >(GetFeeTaxCategoriesDocument, baseOptions);
}
export function useGetFeeTaxCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeeTaxCategoriesQuery,
    GetFeeTaxCategoriesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetFeeTaxCategoriesQuery,
    GetFeeTaxCategoriesQueryVariables
  >(GetFeeTaxCategoriesDocument, baseOptions);
}
export type GetFeeTaxCategoriesQueryHookResult = ReturnType<
  typeof useGetFeeTaxCategoriesQuery
>;
export type GetFeeTaxCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetFeeTaxCategoriesLazyQuery
>;
export type GetFeeTaxCategoriesQueryResult = Apollo.QueryResult<
  GetFeeTaxCategoriesQuery,
  GetFeeTaxCategoriesQueryVariables
>;
export const UpdateFeeBlueprintDocument = gql`
  mutation updateFeeBlueprint($input: UpdateFeeBlueprintInput!) {
    blueprint: updateFeeBlueprint(input: $input) {
      ...feeBlueprint
      event {
        type
      }
    }
  }
  ${FeeBlueprintFragmentDoc}
`;
export type UpdateFeeBlueprintMutationFn = Apollo.MutationFunction<
  UpdateFeeBlueprintMutation,
  UpdateFeeBlueprintMutationVariables
>;

/**
 * __useUpdateFeeBlueprintMutation__
 *
 * To run a mutation, you first call `useUpdateFeeBlueprintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeeBlueprintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeeBlueprintMutation, { data, loading, error }] = useUpdateFeeBlueprintMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFeeBlueprintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFeeBlueprintMutation,
    UpdateFeeBlueprintMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateFeeBlueprintMutation,
    UpdateFeeBlueprintMutationVariables
  >(UpdateFeeBlueprintDocument, baseOptions);
}
export type UpdateFeeBlueprintMutationHookResult = ReturnType<
  typeof useUpdateFeeBlueprintMutation
>;
export type UpdateFeeBlueprintMutationResult =
  Apollo.MutationResult<UpdateFeeBlueprintMutation>;
export type UpdateFeeBlueprintMutationOptions = Apollo.BaseMutationOptions<
  UpdateFeeBlueprintMutation,
  UpdateFeeBlueprintMutationVariables
>;
export const CreateReiTokenDocument = gql`
  mutation createReiToken(
    $createReiTokenInput: CreateReiTokenInput!
    $organisationAilorn: AiloRN!
  ) {
    createReiToken(createReiTokenInput: $createReiTokenInput) {
      ...ReiTokenBase
      person {
        id
        reiTokensForOrg(organisationAilorn: $organisationAilorn) {
          ...ReiTokenBase
        }
      }
    }
  }
  ${ReiTokenBaseFragmentDoc}
`;
export type CreateReiTokenMutationFn = Apollo.MutationFunction<
  CreateReiTokenMutation,
  CreateReiTokenMutationVariables
>;

/**
 * __useCreateReiTokenMutation__
 *
 * To run a mutation, you first call `useCreateReiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReiTokenMutation, { data, loading, error }] = useCreateReiTokenMutation({
 *   variables: {
 *      createReiTokenInput: // value for 'createReiTokenInput'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useCreateReiTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReiTokenMutation,
    CreateReiTokenMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateReiTokenMutation,
    CreateReiTokenMutationVariables
  >(CreateReiTokenDocument, baseOptions);
}
export type CreateReiTokenMutationHookResult = ReturnType<
  typeof useCreateReiTokenMutation
>;
export type CreateReiTokenMutationResult =
  Apollo.MutationResult<CreateReiTokenMutation>;
export type CreateReiTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateReiTokenMutation,
  CreateReiTokenMutationVariables
>;
export const RemoveReiTokenDocument = gql`
  mutation removeReiToken(
    $removeReiTokenInput: RemoveReiTokenInput!
    $organisationAilorn: AiloRN!
  ) {
    removeReiToken(removeReiTokenInput: $removeReiTokenInput) {
      ...ReiTokenBase
      person {
        id
        reiTokensForOrg(organisationAilorn: $organisationAilorn) {
          ...ReiTokenBase
        }
      }
    }
  }
  ${ReiTokenBaseFragmentDoc}
`;
export type RemoveReiTokenMutationFn = Apollo.MutationFunction<
  RemoveReiTokenMutation,
  RemoveReiTokenMutationVariables
>;

/**
 * __useRemoveReiTokenMutation__
 *
 * To run a mutation, you first call `useRemoveReiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReiTokenMutation, { data, loading, error }] = useRemoveReiTokenMutation({
 *   variables: {
 *      removeReiTokenInput: // value for 'removeReiTokenInput'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useRemoveReiTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveReiTokenMutation,
    RemoveReiTokenMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveReiTokenMutation,
    RemoveReiTokenMutationVariables
  >(RemoveReiTokenDocument, baseOptions);
}
export type RemoveReiTokenMutationHookResult = ReturnType<
  typeof useRemoveReiTokenMutation
>;
export type RemoveReiTokenMutationResult =
  Apollo.MutationResult<RemoveReiTokenMutation>;
export type RemoveReiTokenMutationOptions = Apollo.BaseMutationOptions<
  RemoveReiTokenMutation,
  RemoveReiTokenMutationVariables
>;
export const CreateTrustAccountAdjustmentDocument = gql`
  mutation createTrustAccountAdjustment(
    $input: CreateTrustAccountReconciliationAdjustmentInput!
  ) {
    createOneTrustAccountReconciliationAdjustment(
      input: { trustAccountReconciliationAdjustment: $input }
    ) {
      ...TrustAccountReconciliationAdjustmentFragment
      reportPeriod {
        id
        adjustmentSumInCents
      }
    }
  }
  ${TrustAccountReconciliationAdjustmentFragmentFragmentDoc}
`;
export type CreateTrustAccountAdjustmentMutationFn = Apollo.MutationFunction<
  CreateTrustAccountAdjustmentMutation,
  CreateTrustAccountAdjustmentMutationVariables
>;

/**
 * __useCreateTrustAccountAdjustmentMutation__
 *
 * To run a mutation, you first call `useCreateTrustAccountAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrustAccountAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrustAccountAdjustmentMutation, { data, loading, error }] = useCreateTrustAccountAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTrustAccountAdjustmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTrustAccountAdjustmentMutation,
    CreateTrustAccountAdjustmentMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateTrustAccountAdjustmentMutation,
    CreateTrustAccountAdjustmentMutationVariables
  >(CreateTrustAccountAdjustmentDocument, baseOptions);
}
export type CreateTrustAccountAdjustmentMutationHookResult = ReturnType<
  typeof useCreateTrustAccountAdjustmentMutation
>;
export type CreateTrustAccountAdjustmentMutationResult =
  Apollo.MutationResult<CreateTrustAccountAdjustmentMutation>;
export type CreateTrustAccountAdjustmentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateTrustAccountAdjustmentMutation,
    CreateTrustAccountAdjustmentMutationVariables
  >;
export const ListTrustAccountAdjustmentsDocument = gql`
  query listTrustAccountAdjustments(
    $filter: TrustAccountReconciliationAdjustmentFilter
    $paging: CursorPaging
  ) {
    trustAccountReconciliationAdjustments(filter: $filter, paging: $paging) {
      totalCount
      pageInfo {
        ...RelayPageInfoFragment
      }
      edges {
        cursor
        node {
          ...TrustAccountReconciliationAdjustmentFragment
        }
      }
    }
  }
  ${RelayPageInfoFragmentFragmentDoc}
  ${TrustAccountReconciliationAdjustmentFragmentFragmentDoc}
`;

/**
 * __useListTrustAccountAdjustmentsQuery__
 *
 * To run a query within a React component, call `useListTrustAccountAdjustmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTrustAccountAdjustmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTrustAccountAdjustmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useListTrustAccountAdjustmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListTrustAccountAdjustmentsQuery,
    ListTrustAccountAdjustmentsQueryVariables
  >
) {
  return Apollo.useQuery<
    ListTrustAccountAdjustmentsQuery,
    ListTrustAccountAdjustmentsQueryVariables
  >(ListTrustAccountAdjustmentsDocument, baseOptions);
}
export function useListTrustAccountAdjustmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListTrustAccountAdjustmentsQuery,
    ListTrustAccountAdjustmentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListTrustAccountAdjustmentsQuery,
    ListTrustAccountAdjustmentsQueryVariables
  >(ListTrustAccountAdjustmentsDocument, baseOptions);
}
export type ListTrustAccountAdjustmentsQueryHookResult = ReturnType<
  typeof useListTrustAccountAdjustmentsQuery
>;
export type ListTrustAccountAdjustmentsLazyQueryHookResult = ReturnType<
  typeof useListTrustAccountAdjustmentsLazyQuery
>;
export type ListTrustAccountAdjustmentsQueryResult = Apollo.QueryResult<
  ListTrustAccountAdjustmentsQuery,
  ListTrustAccountAdjustmentsQueryVariables
>;
export const RemoveTrustAccountAdjustmentDocument = gql`
  mutation removeTrustAccountAdjustment(
    $input: RemoveTrustAccountReconciliationAdjustmentInput!
  ) {
    removeOneTrustAccountReconciliationAdjustment(input: $input) {
      ...TrustAccountReconciliationAdjustmentFragment
      reportPeriod {
        id
        adjustmentSumInCents
      }
    }
  }
  ${TrustAccountReconciliationAdjustmentFragmentFragmentDoc}
`;
export type RemoveTrustAccountAdjustmentMutationFn = Apollo.MutationFunction<
  RemoveTrustAccountAdjustmentMutation,
  RemoveTrustAccountAdjustmentMutationVariables
>;

/**
 * __useRemoveTrustAccountAdjustmentMutation__
 *
 * To run a mutation, you first call `useRemoveTrustAccountAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTrustAccountAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTrustAccountAdjustmentMutation, { data, loading, error }] = useRemoveTrustAccountAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTrustAccountAdjustmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTrustAccountAdjustmentMutation,
    RemoveTrustAccountAdjustmentMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveTrustAccountAdjustmentMutation,
    RemoveTrustAccountAdjustmentMutationVariables
  >(RemoveTrustAccountAdjustmentDocument, baseOptions);
}
export type RemoveTrustAccountAdjustmentMutationHookResult = ReturnType<
  typeof useRemoveTrustAccountAdjustmentMutation
>;
export type RemoveTrustAccountAdjustmentMutationResult =
  Apollo.MutationResult<RemoveTrustAccountAdjustmentMutation>;
export type RemoveTrustAccountAdjustmentMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveTrustAccountAdjustmentMutation,
    RemoveTrustAccountAdjustmentMutationVariables
  >;
export const LatestReconciliationReportPeriodDocument = gql`
  query latestReconciliationReportPeriod($trustAccountAilorn: AiloRN!) {
    reconciliationReportPeriods(
      filter: { trustAccountAilorn: { eq: $trustAccountAilorn } }
      sorting: [
        { field: year, direction: DESC }
        { field: month, direction: DESC }
      ]
      paging: { first: 1 }
    ) {
      edges {
        node {
          ...reconciliationReportPeriodFragment
        }
      }
    }
  }
  ${ReconciliationReportPeriodFragmentFragmentDoc}
`;

/**
 * __useLatestReconciliationReportPeriodQuery__
 *
 * To run a query within a React component, call `useLatestReconciliationReportPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestReconciliationReportPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestReconciliationReportPeriodQuery({
 *   variables: {
 *      trustAccountAilorn: // value for 'trustAccountAilorn'
 *   },
 * });
 */
export function useLatestReconciliationReportPeriodQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestReconciliationReportPeriodQuery,
    LatestReconciliationReportPeriodQueryVariables
  >
) {
  return Apollo.useQuery<
    LatestReconciliationReportPeriodQuery,
    LatestReconciliationReportPeriodQueryVariables
  >(LatestReconciliationReportPeriodDocument, baseOptions);
}
export function useLatestReconciliationReportPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestReconciliationReportPeriodQuery,
    LatestReconciliationReportPeriodQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    LatestReconciliationReportPeriodQuery,
    LatestReconciliationReportPeriodQueryVariables
  >(LatestReconciliationReportPeriodDocument, baseOptions);
}
export type LatestReconciliationReportPeriodQueryHookResult = ReturnType<
  typeof useLatestReconciliationReportPeriodQuery
>;
export type LatestReconciliationReportPeriodLazyQueryHookResult = ReturnType<
  typeof useLatestReconciliationReportPeriodLazyQuery
>;
export type LatestReconciliationReportPeriodQueryResult = Apollo.QueryResult<
  LatestReconciliationReportPeriodQuery,
  LatestReconciliationReportPeriodQueryVariables
>;
export const ListAllReconciliationReportPeriodsDocument = gql`
  query listAllReconciliationReportPeriods {
    reconciliationReportPeriods {
      edges {
        node {
          ...reconciliationReportPeriodFragment
        }
      }
    }
  }
  ${ReconciliationReportPeriodFragmentFragmentDoc}
`;

/**
 * __useListAllReconciliationReportPeriodsQuery__
 *
 * To run a query within a React component, call `useListAllReconciliationReportPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllReconciliationReportPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllReconciliationReportPeriodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllReconciliationReportPeriodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAllReconciliationReportPeriodsQuery,
    ListAllReconciliationReportPeriodsQueryVariables
  >
) {
  return Apollo.useQuery<
    ListAllReconciliationReportPeriodsQuery,
    ListAllReconciliationReportPeriodsQueryVariables
  >(ListAllReconciliationReportPeriodsDocument, baseOptions);
}
export function useListAllReconciliationReportPeriodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAllReconciliationReportPeriodsQuery,
    ListAllReconciliationReportPeriodsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListAllReconciliationReportPeriodsQuery,
    ListAllReconciliationReportPeriodsQueryVariables
  >(ListAllReconciliationReportPeriodsDocument, baseOptions);
}
export type ListAllReconciliationReportPeriodsQueryHookResult = ReturnType<
  typeof useListAllReconciliationReportPeriodsQuery
>;
export type ListAllReconciliationReportPeriodsLazyQueryHookResult = ReturnType<
  typeof useListAllReconciliationReportPeriodsLazyQuery
>;
export type ListAllReconciliationReportPeriodsQueryResult = Apollo.QueryResult<
  ListAllReconciliationReportPeriodsQuery,
  ListAllReconciliationReportPeriodsQueryVariables
>;
export const UpdateTrustAccountStatementBalanceDocument = gql`
  mutation updateTrustAccountStatementBalance(
    $reportPeriodId: ID!
    $amountInCents: Long!
    $date: LocalDate!
  ) {
    createOneTrustAccountStatementBalance(
      input: {
        trustAccountStatementBalance: {
          reportPeriodId: $reportPeriodId
          amountInCents: $amountInCents
          date: $date
        }
      }
    ) {
      ...trustAccountStatementBalanceFragment
      relatedReportPeriods {
        id
        lastAddedStatementBalance {
          id
        }
        isReconciling
        canAlterLock
      }
    }
  }
  ${TrustAccountStatementBalanceFragmentFragmentDoc}
`;
export type UpdateTrustAccountStatementBalanceMutationFn =
  Apollo.MutationFunction<
    UpdateTrustAccountStatementBalanceMutation,
    UpdateTrustAccountStatementBalanceMutationVariables
  >;

/**
 * __useUpdateTrustAccountStatementBalanceMutation__
 *
 * To run a mutation, you first call `useUpdateTrustAccountStatementBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrustAccountStatementBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrustAccountStatementBalanceMutation, { data, loading, error }] = useUpdateTrustAccountStatementBalanceMutation({
 *   variables: {
 *      reportPeriodId: // value for 'reportPeriodId'
 *      amountInCents: // value for 'amountInCents'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdateTrustAccountStatementBalanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTrustAccountStatementBalanceMutation,
    UpdateTrustAccountStatementBalanceMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateTrustAccountStatementBalanceMutation,
    UpdateTrustAccountStatementBalanceMutationVariables
  >(UpdateTrustAccountStatementBalanceDocument, baseOptions);
}
export type UpdateTrustAccountStatementBalanceMutationHookResult = ReturnType<
  typeof useUpdateTrustAccountStatementBalanceMutation
>;
export type UpdateTrustAccountStatementBalanceMutationResult =
  Apollo.MutationResult<UpdateTrustAccountStatementBalanceMutation>;
export type UpdateTrustAccountStatementBalanceMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTrustAccountStatementBalanceMutation,
    UpdateTrustAccountStatementBalanceMutationVariables
  >;
export const LockTrustAccountDocument = gql`
  mutation lockTrustAccount(
    $trustAccountAilorn: AiloRN!
    $lockedUpTo: DateTime!
  ) {
    lockTrustAccount(
      input: {
        lockedUpTo: $lockedUpTo
        trustAccountAilorn: $trustAccountAilorn
      }
    ) {
      ...trustAccountLockFragment
    }
  }
  ${TrustAccountLockFragmentFragmentDoc}
`;
export type LockTrustAccountMutationFn = Apollo.MutationFunction<
  LockTrustAccountMutation,
  LockTrustAccountMutationVariables
>;

/**
 * __useLockTrustAccountMutation__
 *
 * To run a mutation, you first call `useLockTrustAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockTrustAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockTrustAccountMutation, { data, loading, error }] = useLockTrustAccountMutation({
 *   variables: {
 *      trustAccountAilorn: // value for 'trustAccountAilorn'
 *      lockedUpTo: // value for 'lockedUpTo'
 *   },
 * });
 */
export function useLockTrustAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LockTrustAccountMutation,
    LockTrustAccountMutationVariables
  >
) {
  return Apollo.useMutation<
    LockTrustAccountMutation,
    LockTrustAccountMutationVariables
  >(LockTrustAccountDocument, baseOptions);
}
export type LockTrustAccountMutationHookResult = ReturnType<
  typeof useLockTrustAccountMutation
>;
export type LockTrustAccountMutationResult =
  Apollo.MutationResult<LockTrustAccountMutation>;
export type LockTrustAccountMutationOptions = Apollo.BaseMutationOptions<
  LockTrustAccountMutation,
  LockTrustAccountMutationVariables
>;
export const UnlockReconciliationPeriodDocument = gql`
  mutation unlockReconciliationPeriod(
    $trustAccountAilorn: AiloRN!
    $month: Int!
    $year: Int!
  ) {
    unlockReportPeriod(
      input: {
        trustAccountAilorn: $trustAccountAilorn
        month: $month
        year: $year
      }
    ) {
      report {
        ...reconciliationReportPeriodFragment
        canAlterLock
      }
      failureReason
    }
  }
  ${ReconciliationReportPeriodFragmentFragmentDoc}
`;
export type UnlockReconciliationPeriodMutationFn = Apollo.MutationFunction<
  UnlockReconciliationPeriodMutation,
  UnlockReconciliationPeriodMutationVariables
>;

/**
 * __useUnlockReconciliationPeriodMutation__
 *
 * To run a mutation, you first call `useUnlockReconciliationPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockReconciliationPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockReconciliationPeriodMutation, { data, loading, error }] = useUnlockReconciliationPeriodMutation({
 *   variables: {
 *      trustAccountAilorn: // value for 'trustAccountAilorn'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useUnlockReconciliationPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlockReconciliationPeriodMutation,
    UnlockReconciliationPeriodMutationVariables
  >
) {
  return Apollo.useMutation<
    UnlockReconciliationPeriodMutation,
    UnlockReconciliationPeriodMutationVariables
  >(UnlockReconciliationPeriodDocument, baseOptions);
}
export type UnlockReconciliationPeriodMutationHookResult = ReturnType<
  typeof useUnlockReconciliationPeriodMutation
>;
export type UnlockReconciliationPeriodMutationResult =
  Apollo.MutationResult<UnlockReconciliationPeriodMutation>;
export type UnlockReconciliationPeriodMutationOptions =
  Apollo.BaseMutationOptions<
    UnlockReconciliationPeriodMutation,
    UnlockReconciliationPeriodMutationVariables
  >;
export const ViewAccountTagBalancesDocument = gql`
  query viewAccountTagBalances(
    $trustAccountAilorn: AiloRN!
    $book: Book!
    $accountSubtypes: [AccountSubtype!]!
    $asOf: DateTime
  ) {
    accountTagBalances(
      paginationParams: { pageSize: 100 }
      filter: {
        accountOwnerReference: $trustAccountAilorn
        accountSubtypes: $accountSubtypes
        book: $book
        asOf: $asOf
      }
    ) {
      items {
        balance {
          cents
        }
        accountTag {
          accountSubtype
          value
        }
      }
    }
  }
`;

/**
 * __useViewAccountTagBalancesQuery__
 *
 * To run a query within a React component, call `useViewAccountTagBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewAccountTagBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewAccountTagBalancesQuery({
 *   variables: {
 *      trustAccountAilorn: // value for 'trustAccountAilorn'
 *      book: // value for 'book'
 *      accountSubtypes: // value for 'accountSubtypes'
 *      asOf: // value for 'asOf'
 *   },
 * });
 */
export function useViewAccountTagBalancesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewAccountTagBalancesQuery,
    ViewAccountTagBalancesQueryVariables
  >
) {
  return Apollo.useQuery<
    ViewAccountTagBalancesQuery,
    ViewAccountTagBalancesQueryVariables
  >(ViewAccountTagBalancesDocument, baseOptions);
}
export function useViewAccountTagBalancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewAccountTagBalancesQuery,
    ViewAccountTagBalancesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ViewAccountTagBalancesQuery,
    ViewAccountTagBalancesQueryVariables
  >(ViewAccountTagBalancesDocument, baseOptions);
}
export type ViewAccountTagBalancesQueryHookResult = ReturnType<
  typeof useViewAccountTagBalancesQuery
>;
export type ViewAccountTagBalancesLazyQueryHookResult = ReturnType<
  typeof useViewAccountTagBalancesLazyQuery
>;
export type ViewAccountTagBalancesQueryResult = Apollo.QueryResult<
  ViewAccountTagBalancesQuery,
  ViewAccountTagBalancesQueryVariables
>;
export const ViewReconciliationReportPeriodDocument = gql`
  query viewReconciliationReportPeriod(
    $trustAccountAilorn: AiloRN!
    $year: Int!
    $month: Int!
  ) {
    reconciliationReportPeriodByDate(
      input: {
        trustAccountAilorn: $trustAccountAilorn
        year: $year
        month: $month
      }
    ) {
      ...reconciliationReportPeriodFragment
      canAlterLock
    }
    getTrustAccountLock(trustAccountAilorn: $trustAccountAilorn) {
      ...trustAccountLockFragment
    }
  }
  ${ReconciliationReportPeriodFragmentFragmentDoc}
  ${TrustAccountLockFragmentFragmentDoc}
`;

/**
 * __useViewReconciliationReportPeriodQuery__
 *
 * To run a query within a React component, call `useViewReconciliationReportPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewReconciliationReportPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewReconciliationReportPeriodQuery({
 *   variables: {
 *      trustAccountAilorn: // value for 'trustAccountAilorn'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useViewReconciliationReportPeriodQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewReconciliationReportPeriodQuery,
    ViewReconciliationReportPeriodQueryVariables
  >
) {
  return Apollo.useQuery<
    ViewReconciliationReportPeriodQuery,
    ViewReconciliationReportPeriodQueryVariables
  >(ViewReconciliationReportPeriodDocument, baseOptions);
}
export function useViewReconciliationReportPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewReconciliationReportPeriodQuery,
    ViewReconciliationReportPeriodQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ViewReconciliationReportPeriodQuery,
    ViewReconciliationReportPeriodQueryVariables
  >(ViewReconciliationReportPeriodDocument, baseOptions);
}
export type ViewReconciliationReportPeriodQueryHookResult = ReturnType<
  typeof useViewReconciliationReportPeriodQuery
>;
export type ViewReconciliationReportPeriodLazyQueryHookResult = ReturnType<
  typeof useViewReconciliationReportPeriodLazyQuery
>;
export type ViewReconciliationReportPeriodQueryResult = Apollo.QueryResult<
  ViewReconciliationReportPeriodQuery,
  ViewReconciliationReportPeriodQueryVariables
>;
export const GetAvailablePurposesDocument = gql`
  query getAvailablePurposes($filters: ListPurposesFilters!) {
    payablePurposes(filters: $filters) {
      id
      reference
      type
      payable {
        ... on RecurringOwing {
          id
          reference
          ailoRN
          progress {
            due {
              amount {
                total {
                  cents
                }
              }
              date
            }
            paidTo {
              classic {
                date
                partPayment {
                  cents
                }
              }
              effective {
                date
                partPayment {
                  cents
                }
              }
            }
          }
        }
        ... on Bill {
          id
          ailoRN
          description
          amount {
            cents
          }
          dueDateV2
        }
        ... on TrustAccountWallet {
          id
          ailorn
          trustAccount {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetAvailablePurposesQuery__
 *
 * To run a query within a React component, call `useGetAvailablePurposesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailablePurposesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailablePurposesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAvailablePurposesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailablePurposesQuery,
    GetAvailablePurposesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAvailablePurposesQuery,
    GetAvailablePurposesQueryVariables
  >(GetAvailablePurposesDocument, baseOptions);
}
export function useGetAvailablePurposesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailablePurposesQuery,
    GetAvailablePurposesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAvailablePurposesQuery,
    GetAvailablePurposesQueryVariables
  >(GetAvailablePurposesDocument, baseOptions);
}
export type GetAvailablePurposesQueryHookResult = ReturnType<
  typeof useGetAvailablePurposesQuery
>;
export type GetAvailablePurposesLazyQueryHookResult = ReturnType<
  typeof useGetAvailablePurposesLazyQuery
>;
export type GetAvailablePurposesQueryResult = Apollo.QueryResult<
  GetAvailablePurposesQuery,
  GetAvailablePurposesQueryVariables
>;
export const TransactionsDocument = gql`
  query transactions(
    $cursor: String
    $pageSize: Int!
    $before: Boolean
    $sort: [ListTransactionsSortParams!]!
    $moneyHolder: AiloRN!
    $createdAfter: DateTime
    $createdBefore: DateTime
  ) {
    transactions(
      paginationParams: {
        pageSize: $pageSize
        cursor: $cursor
        before: $before
      }
      filter: {
        moneyHolder: $moneyHolder
        createdAfter: $createdAfter
        createdBefore: $createdBefore
      }
      sort: $sort
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
        previousCursor
      }
      items {
        id
        auditNumber
        type
        referenceNumber
        createdAt
        description
        allocations {
          amount {
            cents
          }
          description
        }
        management {
          id
          ...ManagementProperty
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      pageSize: // value for 'pageSize'
 *      before: // value for 'before'
 *      sort: // value for 'sort'
 *      moneyHolder: // value for 'moneyHolder'
 *      createdAfter: // value for 'createdAfter'
 *      createdBefore: // value for 'createdBefore'
 *   },
 * });
 */
export function useTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TransactionsQuery,
    TransactionsQueryVariables
  >
) {
  return Apollo.useQuery<TransactionsQuery, TransactionsQueryVariables>(
    TransactionsDocument,
    baseOptions
  );
}
export function useTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransactionsQuery,
    TransactionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<TransactionsQuery, TransactionsQueryVariables>(
    TransactionsDocument,
    baseOptions
  );
}
export type TransactionsQueryHookResult = ReturnType<
  typeof useTransactionsQuery
>;
export type TransactionsLazyQueryHookResult = ReturnType<
  typeof useTransactionsLazyQuery
>;
export type TransactionsQueryResult = Apollo.QueryResult<
  TransactionsQuery,
  TransactionsQueryVariables
>;
export const SearchTrustAccountWalletsDocument = gql`
  query searchTrustAccountWallets(
    $input: SearchTrustAccountWalletsQueryInput!
    $organisationAilorn: AiloRN!
  ) {
    searchTrustAccountWallets(input: $input) {
      pageInfo {
        total
      }
      items {
        ...TrustAccountWalletSearchResult
      }
    }
  }
  ${TrustAccountWalletSearchResultFragmentDoc}
`;

/**
 * __useSearchTrustAccountWalletsQuery__
 *
 * To run a query within a React component, call `useSearchTrustAccountWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTrustAccountWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTrustAccountWalletsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      organisationAilorn: // value for 'organisationAilorn'
 *   },
 * });
 */
export function useSearchTrustAccountWalletsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchTrustAccountWalletsQuery,
    SearchTrustAccountWalletsQueryVariables
  >
) {
  return Apollo.useQuery<
    SearchTrustAccountWalletsQuery,
    SearchTrustAccountWalletsQueryVariables
  >(SearchTrustAccountWalletsDocument, baseOptions);
}
export function useSearchTrustAccountWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchTrustAccountWalletsQuery,
    SearchTrustAccountWalletsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SearchTrustAccountWalletsQuery,
    SearchTrustAccountWalletsQueryVariables
  >(SearchTrustAccountWalletsDocument, baseOptions);
}
export type SearchTrustAccountWalletsQueryHookResult = ReturnType<
  typeof useSearchTrustAccountWalletsQuery
>;
export type SearchTrustAccountWalletsLazyQueryHookResult = ReturnType<
  typeof useSearchTrustAccountWalletsLazyQuery
>;
export type SearchTrustAccountWalletsQueryResult = Apollo.QueryResult<
  SearchTrustAccountWalletsQuery,
  SearchTrustAccountWalletsQueryVariables
>;
export const WalletEntriesForTransactionListDocument = gql`
  query WalletEntriesForTransactionList(
    $ownerId: AiloRN!
    $cursor: String
    $pageSize: Int!
  ) {
    walletEntriesWithStatusByOwnerRef(
      ownerRef: $ownerId
      cursor: { cursor: $cursor, pageSize: $pageSize }
    ) {
      pageInfo {
        total
        hasMore
        nextCursor
      }
      items {
        id
        amount {
          cents
        }
        status
        isReversal
        isTopUpFee
        createdAt
        liability {
          id
          referencedEntity {
            __typename
            ... on Bill {
              ailoRN
              nullableManagement: management {
                id
                ...ManagementProperty
              }
            }
            ... on Fee {
              id
              management {
                id
                ...ManagementProperty
              }
            }
          }
          category
          taxCategory
          recurringFee {
            id: ailoRN
            name
            management {
              id
              ...ManagementProperty
            }
          }
        }
        paymentMethod {
          id
        }
      }
    }
  }
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useWalletEntriesForTransactionListQuery__
 *
 * To run a query within a React component, call `useWalletEntriesForTransactionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletEntriesForTransactionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletEntriesForTransactionListQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      cursor: // value for 'cursor'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useWalletEntriesForTransactionListQuery(
  baseOptions: Apollo.QueryHookOptions<
    WalletEntriesForTransactionListQuery,
    WalletEntriesForTransactionListQueryVariables
  >
) {
  return Apollo.useQuery<
    WalletEntriesForTransactionListQuery,
    WalletEntriesForTransactionListQueryVariables
  >(WalletEntriesForTransactionListDocument, baseOptions);
}
export function useWalletEntriesForTransactionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WalletEntriesForTransactionListQuery,
    WalletEntriesForTransactionListQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    WalletEntriesForTransactionListQuery,
    WalletEntriesForTransactionListQueryVariables
  >(WalletEntriesForTransactionListDocument, baseOptions);
}
export type WalletEntriesForTransactionListQueryHookResult = ReturnType<
  typeof useWalletEntriesForTransactionListQuery
>;
export type WalletEntriesForTransactionListLazyQueryHookResult = ReturnType<
  typeof useWalletEntriesForTransactionListLazyQuery
>;
export type WalletEntriesForTransactionListQueryResult = Apollo.QueryResult<
  WalletEntriesForTransactionListQuery,
  WalletEntriesForTransactionListQueryVariables
>;
export const WithdrawFromWalletDocument = gql`
  mutation withdrawFromWallet($withdrawInput: WithdrawInput!) {
    withdrawFromWallet(withdrawInput: $withdrawInput) {
      status
      businessTransaction {
        paymentChannel
      }
    }
  }
`;
export type WithdrawFromWalletMutationFn = Apollo.MutationFunction<
  WithdrawFromWalletMutation,
  WithdrawFromWalletMutationVariables
>;

/**
 * __useWithdrawFromWalletMutation__
 *
 * To run a mutation, you first call `useWithdrawFromWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawFromWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawFromWalletMutation, { data, loading, error }] = useWithdrawFromWalletMutation({
 *   variables: {
 *      withdrawInput: // value for 'withdrawInput'
 *   },
 * });
 */
export function useWithdrawFromWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawFromWalletMutation,
    WithdrawFromWalletMutationVariables
  >
) {
  return Apollo.useMutation<
    WithdrawFromWalletMutation,
    WithdrawFromWalletMutationVariables
  >(WithdrawFromWalletDocument, baseOptions);
}
export type WithdrawFromWalletMutationHookResult = ReturnType<
  typeof useWithdrawFromWalletMutation
>;
export type WithdrawFromWalletMutationResult =
  Apollo.MutationResult<WithdrawFromWalletMutation>;
export type WithdrawFromWalletMutationOptions = Apollo.BaseMutationOptions<
  WithdrawFromWalletMutation,
  WithdrawFromWalletMutationVariables
>;
export const GetAgencyBalancesAndPaymentMethodsDocument = gql`
  query getAgencyBalancesAndPaymentMethods($organisationId: ID!) {
    organisation(id: $organisationId) {
      id
      legalEntities {
        id
        ailoRN
        ... on Company {
          currentUserPermissions
          wallets {
            id
            availableBalance {
              cents
            }
            totalBalance {
              cents
            }
            idempotencyKey
            autoWithdrawPlans(enabled: true) {
              items {
                id
                details {
                  id
                  startDate
                  endDate
                  frequency
                  anniversary
                  nextFireTime
                }
              }
            }
          }
          paymentMethods {
            items {
              __typename
              id
              isOnceOff
              ... on BankAccount {
                bsb
                accountName
                accountNumber
              }
              ... on CreditCard {
                expiry
                number
                type
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAgencyBalancesAndPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetAgencyBalancesAndPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgencyBalancesAndPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgencyBalancesAndPaymentMethodsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetAgencyBalancesAndPaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgencyBalancesAndPaymentMethodsQuery,
    GetAgencyBalancesAndPaymentMethodsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAgencyBalancesAndPaymentMethodsQuery,
    GetAgencyBalancesAndPaymentMethodsQueryVariables
  >(GetAgencyBalancesAndPaymentMethodsDocument, baseOptions);
}
export function useGetAgencyBalancesAndPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgencyBalancesAndPaymentMethodsQuery,
    GetAgencyBalancesAndPaymentMethodsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAgencyBalancesAndPaymentMethodsQuery,
    GetAgencyBalancesAndPaymentMethodsQueryVariables
  >(GetAgencyBalancesAndPaymentMethodsDocument, baseOptions);
}
export type GetAgencyBalancesAndPaymentMethodsQueryHookResult = ReturnType<
  typeof useGetAgencyBalancesAndPaymentMethodsQuery
>;
export type GetAgencyBalancesAndPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetAgencyBalancesAndPaymentMethodsLazyQuery
>;
export type GetAgencyBalancesAndPaymentMethodsQueryResult = Apollo.QueryResult<
  GetAgencyBalancesAndPaymentMethodsQuery,
  GetAgencyBalancesAndPaymentMethodsQueryVariables
>;
