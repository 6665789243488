import { GridSortOrder } from "@ailo/ui";
import { useAnalytics } from "local/common";
import { useCallback, useState } from "react";
import { PropertyOnboardingListRequestFromBeginning } from "../types";

export interface PropertyOnboardingListReorder {
  onSortOrderChange: (nextState: GridSortOrder) => void;
  sortOrder: GridSortOrder[];
}

export function usePropertyOnboardingListReorder(
  initialSortState: GridSortOrder[],
  requestFromBeginning: PropertyOnboardingListRequestFromBeginning,
  getAnalyticsInfo: (sortOrder: GridSortOrder) => {
    eventName: string;
    orderBy: string;
  }
): PropertyOnboardingListReorder {
  const analytics = useAnalytics();

  const [sortOrder, setSortOrder] = useState(initialSortState);

  const trackReorderingAnalytics = useCallback(
    (sortOrder: GridSortOrder) => {
      const analyticsInfo = getAnalyticsInfo(sortOrder);
      analytics.trackPropertyOnboardingListReordered(analyticsInfo.eventName, {
        orderBy: analyticsInfo.orderBy
      });
    },
    [analytics, getAnalyticsInfo]
  );

  const onSortOrderChange = useCallback(
    (sortOrder: GridSortOrder): void => {
      trackReorderingAnalytics(sortOrder);

      const newSortOrder = [
        sortOrder,
        ...initialSortState.filter((s) => s.columnKey !== sortOrder.columnKey)
      ];

      setSortOrder(newSortOrder);
      requestFromBeginning({ sortOrder: newSortOrder });
    },
    [requestFromBeginning, trackReorderingAnalytics, initialSortState]
  );

  return {
    onSortOrderChange,
    sortOrder
  };
}
