import { AiloSentry } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import {
  ManagementEndDetailsFragment,
  useEndManagementV2Mutation
} from "local/graphql";
import { throttle } from "lodash";
import { useFormContext } from "react-hook-form";
import { EndManagementFormData } from "./EndManagementFormData";

interface Props {
  onSuccess: (management: ManagementEndDetailsFragment) => void;
  onError: () => void;
}

export function useSubmit({
  onSuccess,
  onError
}: Props): () => Promise<void> | void {
  const { handleSubmit } = useFormContext<EndManagementFormData>();

  const [endManagementV2] = useEndManagementV2Mutation();

  return throttle(
    handleSubmit(
      async ({
        managementId,
        managementEndDate,
        managementEndReason,
        managementLostReason,
        managementEndNote
      }: EndManagementFormData) => {
        try {
          const result = await endManagementV2({
            variables: {
              input: {
                managementId,
                endDate: managementEndDate,
                endReasonCode: managementEndReason,
                endCauseCodes: managementLostReason || [],
                endNote: managementEndNote
              }
            }
          });

          if (result.errors) {
            throw new ApolloError({
              errorMessage: "endManagementV2 mutation failed with error(s)",
              graphQLErrors: result.errors
            });
          }
          const managementResult = result.data?.endManagementV2.management;
          if (!managementResult) {
            throw new Error(
              "endManagementV2 mutation failed: returned empty management"
            );
          }
          await onSuccess(managementResult);
        } catch (error: unknown) {
          AiloSentry.captureException(error);
          onError();
          return;
        }
      }
    ),
    500
  );
}
