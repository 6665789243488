import { useCurrentAgencyOrg, notEmpty } from "local/common";
import React from "react";
import { useGetBillIssueTableDataQuery } from "local/graphql";
import { useReportQuery, useReportTeamContext } from "../..";
import { ReportPage } from "../../components";
import { omit } from "lodash";
import { billIssueRowLink } from "../../components/ReportPage/utils/tableNavigation";
import { floatForSort } from "local/tabs";
import { reports } from "../../reportsDetails";

export function BillIssuesTable(): React.ReactElement {
  const { id } = useCurrentAgencyOrg();
  const { teamId } = useReportTeamContext();

  const queryResult = useReportQuery(useGetBillIssueTableDataQuery, {
    managingOrgId: id,
    teamId
  });
  const reportData = queryResult.data?.billIssuesSummaryReport;
  const rows = reportData?.rows?.filter(notEmpty).map((r) => ({
    ...omit(r, "drilldownFields"),
    drilldownFields: r.drilldownFields?.filter(notEmpty)
  }));
  const columns = reportData?.columns?.filter(notEmpty);

  return (
    <ReportPage
      title={reports.bill_issues.reportHeading}
      data={{
        ...queryResult,
        rows,
        columns
      }}
      columnsConfig={{
        billIssue: { sortable: false },
        billCount: { horizontalAlign: "right" },
        billCountPercent: {
          horizontalAlign: "right",
          sortValue: (r) => floatForSort(r.billCountPercent)
        }
      }}
      rowLink={billIssueRowLink}
    />
  );
}
