import React from "react";
import { Colors, SFC, Text } from "@ailo/primitives";
import { GridRow, HeaderCellContainer, TextCellContent } from "@ailo/ui";
import { ColWidths } from "../../BillGrid/BillGridRows/components";

export enum BillListColumnName {
  dueDate = "DUE DATE",
  property = "PROPERTY",
  supplier = "SUPPLIER",
  payer = "PAYER",
  status = "STATUS",
  total = "TOTAL (INC. GST)"
}

export const GridHeaderDisplay: SFC<{
  gridColWidths: ColWidths;
}> = ({ style, gridColWidths }) => {
  return (
    <GridRow style={style} colWidths={gridColWidths}>
      {[
        BillListColumnName.dueDate,
        BillListColumnName.property,
        BillListColumnName.supplier,
        BillListColumnName.payer,
        BillListColumnName.status,
        BillListColumnName.total
      ].map((label, index) => {
        return (
          <HeaderCellContainer key={index} style={{ flexShrink: 1 }}>
            <TextCellContent
              TextComponent={Text.Subheading}
              color={
                index === 0
                  ? Colors.TEXT.DARK.PRIMARY
                  : Colors.TEXT.DARK.SECONDARY
              }
            >
              {label}
            </TextCellContent>
          </HeaderCellContainer>
        );
      })}
    </GridRow>
  );
};
