import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Button } from "@ailo/ui";
import { SFC } from "@ailo/primitives";
import { useNavigation, Screens } from "local/common";

const AddBillButton: SFC = ({ style }) => {
  const navigation = useNavigation();
  const onPress = (): void => {
    navigation.navigate(Screens.BillsTab, {
      billId: "new"
    });
  };

  return (
    <Container>
      <Button.Primary testID={"AddBillButton"} style={style} onPress={onPress}>
        {"Add Bill"}
      </Button.Primary>
    </Container>
  );
};

const Container = styled(View)`
  min-width: 40px;
`;

export { AddBillButton };
