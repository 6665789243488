import React, { ReactElement } from "react";
import { SplitScreenInfoPanel } from "local/common";
import { PropertyDetailsUI } from "local/modals/components/PropertyDetails";
import { useGetManagementInfoQuery } from "local/graphql";

export function ProjectInfo({
  managementId
}: {
  managementId: string;
}): ReactElement | null {
  const { data, loading, error } = useGetManagementInfoQuery({
    variables: { managementId },
    fetchPolicy: "cache-and-network"
  });

  if (!data && loading) {
    return <SplitScreenInfoPanel.Loading />;
  }

  const tenancy = data?.management?.currentTenancy;

  if (!tenancy || error) {
    return null;
  }

  return <PropertyDetailsUI tenancy={tenancy} />;
}
