import { createStackNavigator } from "@react-navigation/stack";
import { AppStackNavigatorParamList, Screens } from "local/common";
import React from "react";
import { AppNavigator } from "./AppNavigator";

const Stack = createStackNavigator<AppStackNavigatorParamList>();

export function AppStackNavigator(): React.ReactElement {
  // FYI This is wrapped with a Stack Navigator
  // to allow for a back button in drawer screens.
  //
  // This is because it's impossible to have a back button
  // just with the `Drawer.Navigator` (mounted by `AppNavigator`).
  // ( https://github.com/react-navigation/react-navigation/issues/4793 )
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name={Screens.AppNavigator} component={AppNavigator} />
    </Stack.Navigator>
  );
}
