import React, { FC } from "react";
import { WalletOwnerTransactionsList } from "@ailo/domains";
import { SFC } from "@ailo/primitives";
import { AiloRN, services } from "@ailo/ailorn";
import { TitleHeader } from "./components";

interface Props {
  managementId: string;
  emptyView: SFC;
}

export const ManagementLedgerDetails: FC<Props> = ({
  managementId,
  emptyView
}) => {
  const managementLedgerOwnerRef = AiloRN.of(
    services.PropertyManagement.management,
    managementId
  ).toString();

  return (
    <>
      <TitleHeader>{"Management"}</TitleHeader>
      <WalletOwnerTransactionsList
        ownerRef={managementLedgerOwnerRef}
        emptyView={emptyView}
      />
    </>
  );
};
