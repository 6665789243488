import { Colors, isReactText, opacify, Text } from "@ailo/primitives";
import { Separator } from "@ailo/ui";
import React, { ReactElement, ReactNode } from "react";
import { Platform, StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";

export interface PropertyContentCardProps {
  title: ReactNode;
  titleRight?: ReactNode;
  hasTitleSeparator?: boolean;
  style?: StyleProp<ViewStyle>;
  children: ReactNode;
}

export const PropertyContentCard = React.memo(function PropertyContentCard({
  title,
  titleRight,
  hasTitleSeparator = true,
  style,
  children
}: PropertyContentCardProps): ReactElement {
  const formattedTitle = isReactText(title) ? (
    <Text.BodyL weight={"medium"}>{title}</Text.BodyL>
  ) : (
    title
  );

  return (
    <OuterContainer style={style}>
      <InnerContainer>
        <ContentContainer>
          <TitleContainer>
            {formattedTitle}
            {titleRight}
          </TitleContainer>
          {hasTitleSeparator && <StyledSeparator />}
          <ChildrenContainer>{children}</ChildrenContainer>
        </ContentContainer>
      </InnerContainer>
    </OuterContainer>
  );
});

export const PropertyContentCardLoading = React.memo(
  function PropertyContentCardLoading({
    titleWidth,
    titleRight,
    style,
    hasTitleSeparator = true,
    children
  }: Omit<PropertyContentCardProps, "title"> & {
    titleWidth: number;
  }): ReactElement {
    return (
      <OuterContainer style={style}>
        <InnerContainer>
          <ContentContainer>
            <TitleContainer>
              <Text.BodyL.Loading width={titleWidth} />
              {titleRight}
            </TitleContainer>
            {hasTitleSeparator && <StyledSeparator />}
            <ChildrenContainer>{children}</ChildrenContainer>
          </ContentContainer>
        </InnerContainer>
      </OuterContainer>
    );
  }
);

const OuterContainer = styled(View)`
  background: ${Colors.WHITE};
  box-shadow: 0 1px 4px ${opacify(Colors.SPACE_BLACK, 0.2)};
  ${Platform.OS === "android" ? "elevation: 4;" : ""}
  border-radius: 4px;
`;

const InnerContainer = styled(View)`
  overflow: hidden;
  border-radius: 4px;
  flex-direction: row;
  background-color: ${Colors.WHITE};
  padding-top: 20px;
`;

const ContentContainer = styled(View)`
  flex-direction: column;
  flex-grow: 1;
  flex: auto;
`;

const TitleContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
`;

const ChildrenContainer = styled(View)`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
`;

const StyledSeparator = styled(Separator)`
  margin-left: 0;
  margin-top: 8px;
  margin-bottom: 16px;
`;
