import React from "react";
import { Colors, TeamIcon } from "@ailo/primitives";
import { IconContainer } from "./IconContainer";

export function AssignedTeamListItemIcon(): React.ReactElement {
  return (
    <IconContainer>
      <TeamIcon color={Colors.TEXT.DARK.SECONDARY} height={16} width={16} />
    </IconContainer>
  );
}
