import { ErrorModal } from "@ailo/ui";
import { getManagementIfExists } from "local/domain/project";
import {
  ActionDetailsFragment,
  useGetProjectDetailsQuery
} from "local/graphql";
import React, { useCallback, useState } from "react";
import { BaseSplitScreenModalForm } from "../BaseSplitScreenModalForm";
import { ProjectInfo } from "../ProjectModalForm/ProjectInfo";
import { CreateOrUpdateActionForm } from "./ActionForm/CreateOrUpdateActionForm";

interface Props {
  action?: ActionDetailsFragment;
  projectId: string;
  onDismiss: () => void;
}
export function ActionModalForm({
  action,
  projectId,
  onDismiss
}: Props): React.ReactElement {
  const [submitError, setSubmitError] = useState<boolean>(false);
  const renderFormComponent = useCallback(
    (onFormSubmitting: (isSubmitting: boolean) => void): React.ReactElement => (
      <CreateOrUpdateActionForm
        action={action}
        projectId={projectId}
        onFormSubmitting={(isSubmitting) => onFormSubmitting(isSubmitting)}
        onDismiss={onDismiss}
        setSubmitError={setSubmitError}
      />
    ),
    [action, onDismiss, projectId]
  );

  const { data } = useGetProjectDetailsQuery({
    variables: {
      id: projectId
    }
  });
  const management = data?.project
    ? getManagementIfExists(data.project)
    : undefined;

  return (
    <>
      <BaseSplitScreenModalForm
        title={!action ? "Create action" : "Edit action"}
        renderFormComponent={renderFormComponent}
        renderRightComponent={() =>
          management ? <ProjectInfo managementId={management.id} /> : <></>
        }
        onDismiss={onDismiss}
      />
      <ErrorModal
        visible={submitError}
        onDismiss={() => setSubmitError(false)}
      />
    </>
  );
}
