import { AiloRN } from "@ailo/ailorn";
import { useGetDefaultWalletForManagementQuery } from "@ailo/domains";
import { Colors, Text } from "@ailo/primitives";
import {
  didQueryNotLoadYet,
  didQuerySucceed,
  useOnFocus
} from "@ailo/services";
import { Money } from "@ailo/ui";
import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";

interface PropertyWalletBalanceProps {
  managementAilorn: AiloRN;
  style?: StyleProp<ViewStyle>;
}

export function PropertyWalletBalance({
  managementAilorn,
  style
}: PropertyWalletBalanceProps): ReactElement {
  const walletResult = useGetDefaultWalletForManagementQuery({
    variables: {
      walletOwnerReference: managementAilorn.toString()
    }
  });

  const { refetch } = walletResult;
  useOnFocus(refetch);

  const availableBalanceText = walletResult.data?.walletByWalletOwnerReference
    ?.availableBalance
    ? Money.from(
        walletResult.data.walletByWalletOwnerReference.availableBalance
      ).format()
    : undefined;

  if (
    didQueryNotLoadYet(walletResult) ||
    !didQuerySucceed(walletResult) ||
    !availableBalanceText
  ) {
    return <PropertyWalletBalance.Loading style={style} />;
  }

  return (
    <Container style={style}>
      <View style={{ flexDirection: "row" }}>
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
          {"Property Wallet Balance "}
        </Text.BodyS>
        <Text.BodyS>{availableBalanceText}</Text.BodyS>
      </View>
    </Container>
  );
}

PropertyWalletBalance.Loading = function Loading({
  style
}: Pick<PropertyWalletBalanceProps, "style">) {
  return (
    <Container style={style}>
      <Text.Loading variant={"BodyS"} width={120} />
    </Container>
  );
};

const Container = styled(View)`
  flex-direction: row-reverse;
  margin-right: 24px;
`;
