import {
  ProjectManagementWithAddressFragment,
  ProjectType,
  ProjectWithAddressFragment
} from "local/graphql";
import { isProjectOfTypeWithAddressFromMeta } from "local/domain/project/project";

export function getManagementIfExists(
  project: ProjectWithAddressFragment
): ProjectManagementWithAddressFragment | undefined {
  if (isProjectOfTypeWithAddressFromMeta(project, ProjectType.General)) {
    return project.meta.generalManagement || undefined;
  }

  if (isProjectOfTypeWithAddressFromMeta(project, ProjectType.LeaseRenewal)) {
    return project.meta.leaseRenewalManagement;
  }

  if (isProjectOfTypeWithAddressFromMeta(project, ProjectType.NewTenancy)) {
    return project.meta.newTenancyManagement;
  }

  if (isProjectOfTypeWithAddressFromMeta(project, ProjectType.NewManagement)) {
    return project.meta.newManagement || undefined;
  }

  if (
    isProjectOfTypeWithAddressFromMeta(project, ProjectType.VacatingTenancy)
  ) {
    return project.meta.vacatingTenancyManagement || undefined;
  }

  if (
    isProjectOfTypeWithAddressFromMeta(project, ProjectType.RoutineInspection)
  ) {
    return project.meta.routineInspectionManagement || undefined;
  }
}
