import { PropertyOnboardingListActionInitiatorFragment } from "local/graphql";
import { isPresent } from "ts-is-present";

export function getActionInitiatorDisplayName(
  actionInitiator?: PropertyOnboardingListActionInitiatorFragment | null
): string {
  if (!actionInitiator || actionInitiator.__typename === "System") {
    return "Ailo Support";
  }

  if (actionInitiator.__typename === "User") {
    return [actionInitiator.person.firstName, actionInitiator.person.lastName]
      .filter(isPresent)
      .join(" ");
  }

  return "";
}
