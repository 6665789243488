import { createMultiModalSelectFilter, ModalFilter } from "@ailo/ui";
import { useGetFeeTaxCategoriesQuery } from "local/graphql";
import { capitalize } from "lodash";
import { isPresent } from "ts-is-present";

export const useFeeBlueprintsFilter = (): ModalFilter => {
  const { data } = useGetFeeTaxCategoriesQuery();

  const feeTaxCategories =
    data?.feeTaxCategories?.map(({ name }) => name).filter(isPresent) ?? [];

  return createMultiModalSelectFilter({
    props: {
      title: "Fee category",
      filterKey: "feeCategories",
      options: feeTaxCategories.map((value) => ({
        label: capitalize(value.toLowerCase()),
        value: value.toLowerCase()
      }))
    },
    button: { pluralName: "fee categories" },
    atLeastOneSelected: true,
    errorMessage: "At least one fee category must be selected"
  });
};
