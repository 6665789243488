import { throttle } from "lodash";
import { useFormContext } from "react-hook-form";
import { useCompleteAssignTenancyAgreementAction } from "./useCompleteAssignTenancyAgreementAction";
import { useReviewLease } from "./useReviewLease";
import { LeaseRenewalFormData } from "./LeaseRenewalFormData";
import { useUpdateTenancyAgreement } from "./useUpdateTenancyAgreement";
import { Action } from "local/domain/project";
import { ActionType } from "local/graphql";

interface Props {
  action?: Action<ActionType.AssignTenancyAgreement>;
  onSuccess: () => void;
  onError: () => void;
}

export function useSubmit({
  action,
  onSuccess,
  onError
}: Props): () => Promise<void> | void {
  const { handleSubmit } = useFormContext<LeaseRenewalFormData>();
  const [reviewLease] = useReviewLease({
    onCompleted: onSuccess,
    onError
  });
  const [completeTenancyAgreementAction] =
    useCompleteAssignTenancyAgreementAction({
      onCompleted: onSuccess,
      onError
    });
  const [updateTenancyAgreement] = useUpdateTenancyAgreement({
    onCompleted: onSuccess,
    onError
  });
  return throttle(
    handleSubmit(
      async ({
        tenancyId,
        agreementStartDate,
        agreementFixedTermEndDate,
        leaseType,
        agreementId
      }: LeaseRenewalFormData) => {
        const tenancyAgreementDetails = {
          tenancyId,
          startDate: agreementStartDate,
          fixedTermEndDate: agreementFixedTermEndDate
        };

        if (!action) {
          return reviewLease({
            ...tenancyAgreementDetails,
            agreementId,
            leaseType
          });
        }

        const tenancyAgreement = action.meta?.tenancyAgreement;

        if (tenancyAgreement) {
          return updateTenancyAgreement({
            agreementId: tenancyAgreement.id,
            startDate: agreementStartDate,
            fixedTermEndDate: agreementFixedTermEndDate
          });
        }

        return completeTenancyAgreementAction({
          ...tenancyAgreementDetails,
          actionId: action.id
        });
      }
    ),
    500
  );
}
