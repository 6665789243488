import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { View } from "react-native";
import { SFC } from "@ailo/primitives";
import { TextInputFormField, validateMaxLength } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";

const name = "billDescription" as const;
const MAX_LENGTH = 60 as const;

const BillDescriptionInput: SFC = ({ style }) => {
  const { control, errors, trigger, setValue } =
    useFormContext<AddBillFormData>();

  return (
    <View style={style}>
      <Controller
        control={control}
        name={name}
        defaultValue={""}
        render={({ value, onChange, onBlur, ref }) => (
          <TextInputFormField
            {...{ value, onBlur, inputRef: ref }}
            softCharacterLimit={{
              limit: MAX_LENGTH,
              enforcementLevel: "error"
            }}
            label={"Enter a description for the payer (optional)"}
            placeholder={"Description"}
            autoCompleteType={"off"}
            autoCorrect={false}
            testID={"BillDescriptionInputFormField"}
            error={errors[name]?.message}
            onFocus={() => {
              setValue("fieldUnderInvoiceNumberWasFocused", true);
            }}
            onChange={(text): void => {
              onChange(text);
              trigger(name);
            }}
          />
        )}
        rules={{
          required: false,
          validate: validateMaxLength(MAX_LENGTH)
        }}
      />
    </View>
  );
};

export { BillDescriptionInput };
