import React from "react";
import { CustomersListRow } from "../useCustomersListQuery";
import { CellContainer, TextCell, TextCellContent } from "@ailo/ui";

function RoleCell({
  row: { legalEntity }
}: {
  row: CustomersListRow;
}): React.ReactElement {
  const individual = legalEntity?.__typename === "Person";
  const company = legalEntity?.__typename === "Company";
  return (
    <CellContainer>
      {individual && <TextCellContent>{"Individual"}</TextCellContent>}
      {company && <TextCellContent>{"Company"}</TextCellContent>}
    </CellContainer>
  );
}

RoleCell.Loading = TextCell.Loading;

export { RoleCell };
