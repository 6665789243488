import {
  Grid,
  GridColumnOptions,
  GridSortOrder,
  MultilineTextCell,
  Table
} from "@ailo/ui";
import { Colors, opacify } from "@ailo/primitives";

import React, { useState } from "react";

import { View } from "react-native";
import { Row } from "local/common";
import { ReportHeader } from "../ReportHeader";
import styled from "styled-components/native";
import { ReportDownloadButton } from "../ReportDownloadButton";

export interface ReportTableColumn {
  header: string;
  key: string;
}

export type ReportCellValue =
  | string
  | number
  | boolean
  | undefined
  | string[]
  | null;

export interface ReportTableRow {
  [key: string]: string | number | boolean | undefined | string[] | null;
}

interface ReportTableProps {
  reportTitle: string;
  columns?: ReportTableColumn[];
  rows?: ReportTableRow[];
  rowContainer?: any;
  sortableColumns?: String[];
  headerComponents?: React.ReactElement;
  filterComponents?: React.ReactElement;
  downloadDisabled?: boolean;
}

export const TableWrapper = styled(View)`
  height: 100%;
  max-height: 542px;
`;

export function FunctionalReportTable({
  headerComponents,
  reportTitle,
  rows,
  columns,
  sortableColumns,
  rowContainer,
  filterComponents,
  downloadDisabled
}: ReportTableProps): React.FunctionComponentElement<ReportTableProps> {
  const [sortOrder, setSortOrder] = useState<GridSortOrder>();

  function renderCell(
    columnKey: string,
    reportTableRow: ReportTableRow
  ): React.ReactElement {
    if (columnKey in reportTableRow) {
      return <MultilineTextCell>{reportTableRow[columnKey]}</MultilineTextCell>;
    } else {
      return <></>;
    }
  }

  if (!rows) {
    return <Table.Loading />;
  }

  function onSortOrderChange(sortOrder: GridSortOrder): void {
    rows!.sort((a: { [x: string]: any }, b: { [x: string]: any }) => {
      const sortOrderDirection = sortOrder.direction === "ASC" ? 1 : -1;

      let rowComparison: number;
      if (
        typeof a[sortOrder.columnKey] === "undefined" &&
        typeof b[sortOrder.columnKey] === "undefined"
      ) {
        rowComparison = 0;
      } else if (typeof a[sortOrder.columnKey] === "undefined") {
        rowComparison = 1;
      } else if (typeof b[sortOrder.columnKey] === "undefined") {
        rowComparison = -1;
      } else {
        rowComparison =
          a[sortOrder.columnKey]! > b[sortOrder.columnKey]! ? 1 : -1;
      }
      return sortOrderDirection * rowComparison;
    });
    setSortOrder(sortOrder);
  }

  const gridRows: ReportTableRow[] & { key: number }[] = rows!.map(
    (row, index) => {
      return {
        key: index,
        ...row
      };
    }
  );

  if (!columns) {
    return <Table.Loading />;
  }

  const gridColumns: GridColumnOptions<{
    key: number;
  }>[] = columns.map((column) => {
    return {
      key: column.key,
      name: column.header,
      sortable: sortableColumns ? sortableColumns.includes(column.key) : false,
      renderCell({ row }): React.ReactElement {
        return renderCell(column.key, row);
      }
    };
  });

  return (
    <TableWrapper>
      <View
        style={{
          marginTop: -1,
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 10,
          paddingLeft: 0
        }}
      >
        <Row style={{ paddingBottom: 32, alignItems: "center" }}>
          <ReportHeader reportTitle={reportTitle} />
          {!downloadDisabled && (
            <ReportDownloadButton
              fileName={reportTitle + ".csv"}
              rows={rows}
              columns={columns}
            />
          )}
        </Row>
        {headerComponents}
      </View>

      <View
        style={{
          height: "100%",
          borderRadius: 4
        }}
      >
        {filterComponents && (
          <View
            style={{
              backgroundColor: Colors.WHITE,
              paddingHorizontal: 16,
              paddingVertical: 16
            }}
          >
            {filterComponents}
          </View>
        )}
        <View
          style={{
            height: "100vh",
            maxHeight: "-webkit-fill-available",
            borderRadius: 4
          }}
        >
          <Grid
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              borderRadius: 4
            }}
            bodyStyle={{
              shadowColor: opacify(Colors.BLACK, 0.2),
              shadowOffset: { width: 1, height: 1 },
              shadowRadius: 4
            }}
            columns={gridColumns}
            rows={gridRows}
            onRowPress={(): void => {}}
            sortOrder={sortOrder}
            onSortOrderChange={onSortOrderChange}
            renderRowContainer={rowContainer}
          />
        </View>
      </View>
    </TableWrapper>
  );
}
