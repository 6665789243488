import { View } from "react-native";
import React, { ReactElement } from "react";

export function ClaimSteps({
  steps
}: {
  steps: React.ReactNode[];
}): ReactElement {
  return <View style={{ flexGrow: 1 }}>{steps}</View>;
}
