import { useGlobalModal } from "@ailo/ui";
import React, { useCallback } from "react";
import { Screens, useNavigation } from "local/common";
import { ProjectModalForm } from "local/modals/ProjectModalForm/ProjectModalForm";

export function useShowCreateProjectModal(): () => void {
  const modal = useGlobalModal();
  const { navigate } = useNavigation<Screens.ProjectList>();

  return useCallback(() => {
    modal.show(
      <ProjectModalForm
        onDismiss={modal.hide}
        onSuccess={(projectId: string) => {
          navigate(Screens.Project, {
            id: projectId,
            previousScreen: Screens.ProjectList
          });
        }}
      />
    );
  }, [modal, navigate]);
}
