import React, { ReactElement } from "react";
import {
  ManagementPortfolioContentCard,
  ManagementPortfolioContentProps
} from "../ManagementPortfolioScreen";
import { ManagementPortfolioPropertiesListItem } from "./ManagementPortfolioPropertiesListItem";
import { useGetManagementPortfolioProperties } from "./useGetManagementPortfolioProperties";

/**
 * Metapod TODO:
 * - navigate to property page on item click
 * - add loading and error states
 * - handle no properties edge case
 */
export function ManagementPortfolioPropertiesCard({
  portfolioAilorn
}: ManagementPortfolioContentProps): ReactElement | null {
  const { data, loading, error } =
    useGetManagementPortfolioProperties(portfolioAilorn);

  if (loading) {
    return null;
  }
  if (error || !data) {
    return null;
  }

  return (
    <ManagementPortfolioContentCard title={"Properties"}>
      {data.map((management, index) => (
        <ManagementPortfolioPropertiesListItem
          key={management.id}
          management={management}
          isLastItem={index === data.length - 1}
        />
      ))}
    </ManagementPortfolioContentCard>
  );
}
