import { OwnerDetails } from "../../useCreateManagement";

export function getTotalShares(
  ownerArray?: { details: OwnerDetails }[]
): number {
  if (!ownerArray) return 0;
  return ownerArray.reduce((previous, current) => {
    return previous + current.details.sharesOwned;
  }, 0);
}
