import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { useCallback } from "react";
import { MutationResult } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { isPresent } from "ts-is-present";
import {
  UpdateMigratingManagementMutation,
  UpdateMigratingManagementMutationVariables,
  useUpdateMigratingManagementMutation,
  GetPagedAwaitingApprovalMigratingManagementsDocument,
  GetMigratingManagementSidebarDocument
} from "local/graphql";

interface UpdateMigratingManagementProps {
  migratingManagementId: string;
  onSuccess: (result: UpdateMigratingManagementMutation) => void;
  onError: () => void;
}

type UpdateMigratingManagementVariables = Omit<
  UpdateMigratingManagementMutationVariables,
  "migratingManagementId"
>;
type UpdateMigratingManagement = [
  (variables: UpdateMigratingManagementVariables) => void,
  MutationResult<UpdateMigratingManagementMutation>
];

const refreshOperationNames = [
  getOperationName(GetPagedAwaitingApprovalMigratingManagementsDocument)
];

export function useUpdateMigratingManagement({
  migratingManagementId,
  onSuccess,
  onError
}: UpdateMigratingManagementProps): UpdateMigratingManagement {
  const [updateMigratingManagementMutation, mutationResult] =
    useUpdateMigratingManagementMutation({
      variables: { migratingManagementId },
      refetchQueries: [
        {
          query: GetMigratingManagementSidebarDocument,
          variables: {
            migratingManagementId
          }
        },
        ...refreshOperationNames.filter(isPresent)
      ]
    });

  const updateMigratingManagement = useCallback(
    async (variables: UpdateMigratingManagementVariables) => {
      try {
        const result = await updateMigratingManagementMutation({
          variables: {
            ...variables,
            migratingManagementId
          }
        });
        throwIfMutationFailed(result, {
          dataKey: "updateMigratingManagement"
        });

        if (!result.data) {
          onError();
          return;
        }

        onSuccess(result.data);
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
      }
    },
    [
      updateMigratingManagementMutation,
      onSuccess,
      onError,
      migratingManagementId
    ]
  );

  return [updateMigratingManagement, mutationResult];
}
