import {
  AiloSentry,
  MutationResultDataMissingError,
  useAnalytics
} from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { useCallback } from "react";
import { NewTenancyFormData } from "../TenancyForms";
import { getRentFrequencyInputForMutation } from "../TenancyForms/getRentFrequenceInputForMutation";
import {
  CreateTenancyMutation,
  useCreateTenancyMutation,
  TenancyFragment,
  RentFrequency,
  RentCategory
} from "local/graphql";

export interface TenantDetails {
  firstName: string;
  middleName?: string;
  lastName: string;
  preferredName?: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: string;
  isExistingUser?: boolean;
}

type CreateTenancyInput = NewTenancyFormData & {
  agreementStartDate: string;
  rentStartDate: string;
  rentFrequency: RentFrequency;
};

export function useCreateTenancy({
  onCompleted,
  onError
}: {
  onCompleted?: (tenancy: TenancyFragment) => void;
  onError?: (error?: unknown) => void;
}): [
  ({
    formData,
    managementId
  }: {
    formData: CreateTenancyInput;
    managementId: string;
  }) => Promise<void>,
  MutationResult<CreateTenancyMutation>
] {
  const analytics = useAnalytics();
  const [createTenancy, result] = useCreateTenancyMutation();

  const newTenancy = useCallback(
    async ({
      formData: {
        agreementStartDate,
        agreementEndDate,
        files,
        rentAmount,
        tenants,
        rentFrequency,
        rentReviewDate: scheduledRentReviewDate,
        depositAmount,
        rentStartDate
      },
      managementId
    }: {
      formData: CreateTenancyInput;
      managementId: string;
    }): Promise<void> => {
      try {
        const rentAmountInCents = rentAmount?.cents.toString() ?? "0";

        const result = await createTenancy({
          variables: {
            tenancyDetails: {
              fileAilorns: files?.map((file) => file.id!),
              startDate: agreementStartDate,
              tenancyAgreement: {
                startDate: agreementStartDate,
                fixedTermEndDate: agreementEndDate
              },
              rents: [
                {
                  amountInCents: rentAmountInCents,
                  category: RentCategory.NewTenancy,
                  effectiveDate: rentStartDate,
                  period: getRentFrequencyInputForMutation(rentFrequency)
                }
              ],
              tenants: [
                ...(tenants?.map(({ details }) => ({
                  consumer: {
                    firstName: details.firstName,
                    lastName: details.lastName,
                    email: details.email,
                    migratingCustomer: false,
                    phone: details.phoneNumber,
                    preferredName: details.preferredName,
                    legalMiddleName: details.middleName,
                    birthDate: details.dateOfBirth
                  }
                })) ?? [])
              ],
              scheduledRentReviewDate,
              deposit: depositAmount
                ? { amount: { cents: depositAmount.cents } }
                : undefined
            },
            managementId
          }
        });

        const tenancy = result.data?.createTenancy;
        if (!tenancy) {
          throw new MutationResultDataMissingError("createTenancy", result);
        }
        await onCompleted?.(tenancy);
      } catch (error) {
        AiloSentry.captureException(error);
        return onError?.(error);
      }

      const hasExistingAiloUsers =
        tenants?.some((tenant) => tenant.details.isExistingUser) ?? false;

      analytics.trackNewTenancyCreated({ hasExistingAiloUsers });
    },
    [createTenancy, onCompleted, onError, analytics]
  );

  return [newTenancy, result];
}
