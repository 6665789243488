import React from "react";
import { PropertyChatList } from "./PropertyChatList";
import { PropertyScreenCardErrorBoundary } from "local/domain/propertyManagement";
import { useOnFocus } from "@ailo/services";
import { StyleProp, ViewStyle } from "react-native";
import { useGetDataForPropertyChatList } from "./useGetDataForPropertyChatList";
import { useCurrentAgencyOrg } from "local/common";
import { ChatContextProvider, ChatListContextProvider } from "@ailo/domains";
import { AiloRN } from "@ailo/ailorn";
import {
  PropertyContentCard,
  PropertyContentCardLoading
} from "../PropertyContentCard";
import styled from "styled-components/native";

interface Props {
  managementId: string;
  style?: StyleProp<ViewStyle>;
}

function ChatListCard({ style, managementId }: Props): React.ReactElement {
  const { data, loading, refetch, error } =
    useGetDataForPropertyChatList(managementId);

  const currentAgencyOrg = useCurrentAgencyOrg();

  useOnFocus(refetch);

  if (loading && !data)
    return (
      <PropertyContentCardLoading style={style} titleWidth={20}>
        <PropertyChatList.Loading />
      </PropertyContentCardLoading>
    );

  if (error || !data) {
    throw error;
  }

  const chatFilter = {
    mustIncludeOneOf: data.contactAilorns.map((contactAilorn) => ({
      contactAilorn: contactAilorn.toString()
    }))
  };

  return (
    <StyledPropertyContentCard style={style} title={"Chat"}>
      <ChatListContextProvider
        organisationAilorn={AiloRN.from(currentAgencyOrg.ailoRN, {
          expected: "authz:organisation"
        })}
        filters={chatFilter}
        agencyName={currentAgencyOrg.name}
      >
        <ChatContextProvider
          organisationAilorn={AiloRN.from(currentAgencyOrg.ailoRN, {
            expected: "authz:organisation"
          })}
          agencyName={currentAgencyOrg.name}
        >
          <PropertyChatList managementTeamId={data.teamId} />
        </ChatContextProvider>
      </ChatListContextProvider>
    </StyledPropertyContentCard>
  );
}

function ChatListCardWithErrorBoundary({
  style,
  managementId
}: Props): React.ReactElement {
  return (
    <PropertyScreenCardErrorBoundary style={style} cardTitle={"Chat"}>
      <ChatListCard style={style} managementId={managementId} />
    </PropertyScreenCardErrorBoundary>
  );
}

const StyledPropertyContentCard = styled(PropertyContentCard)`
  padding-bottom: 16px;
`;

export { ChatListCardWithErrorBoundary as ChatListCard };
