export enum DisbursementListView {
  ALL_DISBURSALS = "To be disbursed",
  INVESTOR_DISBURSALS = "Investors",
  SUPPLIER_DISBURSALS = "Suppliers",
  HISTORY = "History"
}

export interface DisbursementsTabParamList {
  tab?: DisbursementListView;
}
