import React from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import { DescriptionList, Separator, SidebarContent, Money } from "@ailo/ui";
import { ClaimTenancyData, ClaimSummary } from "local/domain/bond";

import { TenancyInfo } from "./TenancyInfo";
import { BondInfo } from "./BondInfo";
import { ClaimInfo } from "./ClaimInfo";
import { BondAuthorityAlert } from "./BondAuthorityAlert";
import { TenancyBondStatus } from "local/graphql";

type Props = {
  data?: ClaimTenancyData;
  claimSummary?: ClaimSummary;
  loading?: boolean;
};

export function BondClaimDetails({
  data,
  claimSummary,
  loading
}: Props): React.ReactElement | null {
  if (loading || !data || !claimSummary) {
    return <BondClaimDetails.Loading />;
  }

  const { tenancyEndDate, property, tenants, bond, bills } = data;
  const { amountRentClaimed, amountReleaseToRenters } = claimSummary;

  const showAlert = showBondAuthorityAlert(
    bond.status,
    claimSummary.amountRequested
  );

  return (
    <StyledContainer>
      <TenancyInfo
        tenancyEndDate={tenancyEndDate}
        property={property}
        tenants={tenants}
      />
      <SectionSeparator />
      <BondInfo bond={bond} />
      <SectionSeparator />
      <ClaimInfo
        amountRentClaimed={amountRentClaimed}
        amountReleaseToRenters={amountReleaseToRenters}
        bills={bills}
        bond={bond}
      />

      {showAlert && <BondAuthorityAlert state={property.address.state} />}
    </StyledContainer>
  );
}

function showBondAuthorityAlert(
  bondStatus?: TenancyBondStatus,
  amountRequested?: Money
): boolean {
  return (
    bondStatus === TenancyBondStatus.AwaitingFunds ||
    (bondStatus === TenancyBondStatus.ClaimSuccessful &&
      amountRequested?.cents === 0)
  );
}

BondClaimDetails.Loading = function Loading() {
  return (
    <StyledContainer>
      <DescriptionList.Loading size={"small"} />
    </StyledContainer>
  );
};

const StyledContainer = styled(View)`
  padding: 0 32px;
  margin-top: 32px;
`;

const SectionSeparator = styled(Separator)`
  margin-top: 40px;
  margin-bottom: 32px;
  margin-left: 0;
  margin-right: -${SidebarContent.paddingHorizontal}px;
`;
