import React from "react";
import { ConfirmModal, LocalDate } from "@ailo/ui";
import { Text } from "@ailo/primitives";
import {
  EndingManagementDetailsFragment,
  ManagementEndDetailsFragment
} from "local/graphql";
import { formatAddress } from "@ailo/domain-helpers";
import { isFormerManagement } from "local/tabs/properties/components/PropertyScreen/components/PropertyContent/components/utils";

export function ConfirmEndManagementModal({
  management,
  managementNewEndDate,
  onCancel,
  onConfirm,
  visible
}: {
  management: EndingManagementDetailsFragment & ManagementEndDetailsFragment;
  managementNewEndDate?: LocalDate;
  onCancel: () => void;
  onConfirm: () => void;
  visible: boolean;
}): React.ReactElement | null {
  const formattedAddress = formatAddress(management.property.address, {
    format: "street"
  });
  if (!managementNewEndDate || !formattedAddress) {
    return null;
  }
  const managementHasEnded = isFormerManagement(management.endDate);
  const managementWilBeRestarted =
    managementHasEnded && managementNewEndDate.isAfter(LocalDate.today());
  return (
    <ConfirmModal
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={"End management"}
      confirmLabel={"End management"}
      destructive
      visible={visible}
    >
      {managementWilBeRestarted ? (
        <ManagementRestartContent
          formattedAddress={formattedAddress}
          managementEndDate={managementNewEndDate}
        />
      ) : (
        <ManagementEndContent
          formattedAddress={formattedAddress}
          managementEndDate={managementNewEndDate}
        />
      )}
    </ConfirmModal>
  );
}

type ConfirmContentProps = {
  formattedAddress: string;
  managementEndDate: LocalDate;
};

function ManagementRestartContent({
  managementEndDate,
  formattedAddress
}: ConfirmContentProps): React.ReactElement {
  return (
    <Text.BodyM>
      {`The management for ${formattedAddress} will restart today and end on `}
      <Text weight={"medium"}>{managementEndDate.format("D MMMM YYYY")}</Text>
      {
        ". Any recurring fee schedules may start again. You can change the end date or end reasons at any time."
      }
    </Text.BodyM>
  );
}

function ManagementEndContent({
  managementEndDate,
  formattedAddress
}: ConfirmContentProps): React.ReactElement {
  return (
    <Text.BodyM>
      {`The management for ${formattedAddress} will end on `}
      <Text weight={"medium"}>{managementEndDate.format("D MMMM YYYY")}</Text>
      {"."}
    </Text.BodyM>
  );
}
