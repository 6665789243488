import { useCurrentAgencyOrg } from "local/common";
import {
  ManagementFilterTypes,
  ManagementSortField,
  SortDirection,
  useGetPagedManagementsWithTenancyInArrearsFromOwingLazyQuery
} from "local/graphql";
import { useCallback } from "react";
import { PROPERTY_LIST_PAGE_SIZE, RequestCursor } from "./usePaginationRequest";
import { PropertyListQueryData } from "../BasePropertyList";
import { useGetPropertyListQueryReturn } from "./useGetPropertyListQueryReturn";
import { GridSortOrder } from "@ailo/ui";
import { ArrearsPropertyListColumns } from "../ArrearsPropertyList";
import { getTeamsManagementFilterParams } from "./getTeamsManagementFilterParams";
import { PropertyListSelectedTeams } from "./usePropertyListSelectedTeams";

export function useArrearsPropertyListFromOwingQuery(
  initialSortOrder: GridSortOrder
): PropertyListQueryData {
  const currentOrg = useCurrentAgencyOrg();

  const [request, { data, loading, called }] =
    useGetPagedManagementsWithTenancyInArrearsFromOwingLazyQuery({
      fetchPolicy: "cache-and-network"
    });

  const sendRequest = useCallback(
    (
      selectedTeams: PropertyListSelectedTeams,
      cursor: RequestCursor = {
        cursor: null,
        pageSize: PROPERTY_LIST_PAGE_SIZE,
        paginateBackward: false,
        sort: arrearsFromOwingSortStateToApiParam(initialSortOrder)
      }
    ) => {
      request({
        variables: {
          organisationId: currentOrg.ailoRN,
          filters: [
            { name: ManagementFilterTypes.HasActiveTenancyInArrearsFromOwing },
            ...getTeamsManagementFilterParams(selectedTeams)
          ],
          pageCursor: cursor
        }
      });
    },
    [currentOrg.ailoRN, request, initialSortOrder]
  );

  const pageInfo = data?.managementsForOrg?.pageInfo;
  const managements = data?.managementsForOrg?.items;

  return useGetPropertyListQueryReturn({
    sendRequest,
    pageInfo,
    managements,
    loading: loading && !data,
    called,
    sortStateToApiParam: arrearsFromOwingSortStateToApiParam
  });
}

export function arrearsFromOwingSortStateToApiParam({
  columnKey,
  direction
}: GridSortOrder): { field: ManagementSortField; direction: SortDirection } {
  return {
    field: getApiSortField(columnKey),
    direction: direction === "ASC" ? SortDirection.Asc : SortDirection.Desc
  };
}

function getApiSortField(columnKey: string): ManagementSortField {
  if (columnKey === ArrearsPropertyListColumns.Properties)
    return ManagementSortField.Address;

  if (columnKey === ArrearsPropertyListColumns.PaidTo)
    return ManagementSortField.TenancyOwingEffectivePaidToDate;

  throw new Error(`Cannot sort overdue property list by column ${columnKey}`);
}
