import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Colors, opacify, Text } from "@ailo/primitives";

interface Props {
  children: string;
  style?: StyleProp<ViewStyle>;
}

function PropertyScreenCardListSubHeader({
  children,
  style
}: Props): ReactElement {
  return (
    <View style={style}>
      <Text.Subheading color={opacify(Colors.TEXT.DARK.SECONDARY, 0.6)}>
        {children}
      </Text.Subheading>
    </View>
  );
}

function Loading({ style }: { style?: StyleProp<ViewStyle> }): ReactElement {
  return (
    <View style={style}>
      <Text.BodyS.Loading width={80} />
    </View>
  );
}

PropertyScreenCardListSubHeader.Loading = Loading;

export { PropertyScreenCardListSubHeader };
