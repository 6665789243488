import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { NewManagementFormData } from "../../NewManagementFormData";
import { getTotalShares } from "../OwnerDetailsStep/getTotalShares";
import { BaseReviewDetailsSection } from "./BaseReviewDetailsSection";
import { ReviewOwnerProfileCard } from "./ReviewOwnerProfileCard";

interface Props {
  onEditPress?(): void;
}

export function OwnerDetailsSection({ onEditPress }: Props): ReactElement {
  const { getValues } = useFormContext<NewManagementFormData>();
  const owners = getValues().owners;
  const totalShares = getTotalShares(owners);
  return (
    <BaseReviewDetailsSection title={"Owners"} onEditPress={onEditPress}>
      {!!owners &&
        owners.map(({ details }, index) => {
          return (
            <ReviewOwnerProfileCard
              key={`New-Owner-Review-Card-${index}-${details.firstName}`}
              person={details}
              totalShares={totalShares}
              style={{
                marginTop: 12,
                marginBottom: index === owners.length - 1 ? 0 : 12
              }}
            />
          );
        })}
    </BaseReviewDetailsSection>
  );
}
