import React, { ReactElement } from "react";
import { Badge } from "@ailo/ui";
import { useOnFocus } from "@ailo/services";
import { useGetDisbursements } from "local/tabs/trustaccounting";

interface Props {
  active?: boolean;
}

export function DisbursementsCountBadge({
  active = true
}: Props): ReactElement | null {
  const { data, loading, error, refetch } = useGetDisbursements({});

  useOnFocus(refetch);

  const pageTotal = data?.pageInfo.total;

  if (loading) {
    return <Badge.Loading width={23} style={{ marginLeft: 8 }} />;
  }

  if (!data || error) {
    return null;
  }

  return (
    <Badge type={active ? "ailo" : "outline"} style={{ marginLeft: 8 }}>
      {pageTotal}
    </Badge>
  );
}
