import { Bill } from "local/domain/bond";
import React, { ReactElement } from "react";
import { Badge, BorderedBox } from "@ailo/ui";
import { Row } from "local/common";
import { View } from "react-native";
import { Text } from "@ailo/primitives";
import { SecondaryText as CategoryText } from "../../../../SecondaryText";
import { FormControlledCheckbox } from "./FormControlledCheckbox";
import { FormControlledAmount } from "./FormControlledAmount";

export type BillInputProps = {
  bill: Bill;
  index: number;
};

export function BillClaimInput({ bill, index }: BillInputProps): ReactElement {
  return (
    <BorderedBox style={{ borderRadius: 4, padding: 18, marginTop: 12 }}>
      <Row style={{ width: "100%" }}>
        <Row
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
          }}
        >
          <FormControlledCheckbox bill={bill} index={index} />
          <View>
            <Text.BodyM weight={"medium"}>{bill.payeeName}</Text.BodyM>
            <CategoryText>{bill.category}</CategoryText>
          </View>
        </Row>
        <View style={{ alignItems: "flex-end" }}>
          <FormControlledAmount bill={bill} index={index} />

          {bill.overdue ? (
            <Badge type={"critical"} testID={"BillStatusBadge"}>
              {"Overdue"}
            </Badge>
          ) : (
            <Badge type={"attention"} testID={"BillStatusBadge"}>
              {"Due"}
            </Badge>
          )}
        </View>
      </Row>
    </BorderedBox>
  );
}
