import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { useCallback } from "react";
import { MutationResult } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { isPresent } from "ts-is-present";
import {
  GetPagedApprovedMigratingManagementsDocument,
  GetMigratingManagementSidebarDocument,
  PublishMigratingManagementMutation,
  usePublishMigratingManagementMutation
} from "local/graphql";
import { formatAddress } from "@ailo/domain-helpers";

interface PublishMigratingManagementsProps {
  migratingManagementId: string;
  onSuccess: (result: PublishMigratingManagementMutation) => void;
  onError: () => void;
}

type PublishMigratingManagements = [
  () => void,
  MutationResult<PublishMigratingManagementMutation>
];

const refreshOperationNames = [
  getOperationName(GetPagedApprovedMigratingManagementsDocument)
];

export function usePublishMigratingManagement({
  migratingManagementId,
  onSuccess,
  onError
}: PublishMigratingManagementsProps): PublishMigratingManagements {
  const analytics = useAnalytics();

  const [publishMigratingManagementMutation, mutationResult] =
    usePublishMigratingManagementMutation({
      variables: { migratingManagementId },
      refetchQueries: [
        {
          query: GetMigratingManagementSidebarDocument,
          variables: {
            migratingManagementId
          }
        },
        ...refreshOperationNames.filter(isPresent)
      ]
    });

  const publishMigratingManagement = useCallback(async () => {
    try {
      const result = await publishMigratingManagementMutation();
      throwIfMutationFailed(result, {
        dataKey: "publishMigratingManagements"
      });

      if (!result.data) {
        onError();
        return;
      }

      const successes =
        result.data.publishMigratingManagements?.successes ?? [];
      if (successes.length !== 0) {
        const address = successes[0].body.management?.property;
        if (address) {
          analytics.track("Migrating Property Set Live", {
            property_address: formatAddress(address, {
              format: "street, suburb"
            })
          });
        }
      }

      onSuccess(result.data);
    } catch (error) {
      AiloSentry.captureException(error);
      onError();
    }
  }, [publishMigratingManagementMutation, onSuccess, onError, analytics]);

  return [publishMigratingManagement, mutationResult];
}
