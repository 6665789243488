import React, { useMemo } from "react";
import {
  LostPropertyList,
  DefaultPropertyList,
  RenewalsPropertyList,
  OverduePropertyList,
  ArrearsPropertyList,
  PropertyListHeader,
  ManagementCountBadgeRenderFunction,
  ArrearsPropertyListFromOwing,
  OverduePropertyListFromOwing
} from "./components";
import { ScreenComponent, useAnalytics } from "@ailo/services";
import {
  BadgeQueryParams,
  PropertyListView,
  Screens,
  useNavigation,
  useRoute
} from "local/common";
import { ManagementFilterTypes, PlatformFeatureId } from "local/graphql";
import { useSelectedTeamAnalytics } from "local/domain/propertyManagement";
import { useHasFeature } from "@ailo/domains";

const PropertyListScreen = (): React.ReactElement | null => {
  const { tab } = useRoute<Screens.PropertyList>().params!;
  const navigation = useNavigation<Screens.PropertyList>();
  const hasArrearsFromOwingFeature = useHasFeature(
    PlatformFeatureId.ArrearsListFromOwing
  );
  const hasOverdueFromOwingFeature = useHasFeature(
    PlatformFeatureId.OverdueListFromOwing
  );

  const propertyTabData: PropertyTabData[] = useMemo(
    () =>
      [
        {
          label: PropertyListView.PROPERTIES,
          listComponent: DefaultPropertyList
        },
        {
          label: PropertyListView.ARREARS,
          listComponent: hasArrearsFromOwingFeature
            ? ArrearsPropertyListFromOwing
            : ArrearsPropertyList,
          badge: ManagementCountBadgeRenderFunction(
            hasArrearsFromOwingFeature
              ? ManagementFilterTypes.HasActiveTenancyInArrearsFromOwing
              : ManagementFilterTypes.HasActiveTenancyInArrears
          )
        },
        {
          label: PropertyListView.OVERDUE,
          listComponent: hasOverdueFromOwingFeature
            ? OverduePropertyListFromOwing
            : OverduePropertyList,
          badge: ManagementCountBadgeRenderFunction(
            hasOverdueFromOwingFeature
              ? ManagementFilterTypes.HasOverdueTenancyFromOwing
              : ManagementFilterTypes.HasOverdueTenancy
          )
        },
        {
          label: PropertyListView.RENEWALS_AND_REVIEWS,
          listComponent: RenewalsPropertyList
        },
        {
          label: PropertyListView.LOST,
          listComponent: LostPropertyList
        }
      ].map((tab, index) => ({ ...tab, index })),
    [hasArrearsFromOwingFeature, hasOverdueFromOwingFeature]
  );

  const tabData = propertyTabData.find((t) =>
    t.label.toLowerCase().includes(tab.toLowerCase())
  );

  const analyticsTrackPayload = {
    tab: tabData?.label,
    ...useSelectedTeamAnalytics()
  };
  const analytics = useAnalytics();

  if (!tabData) {
    navigation.setParams({ tab: PropertyListView.PROPERTIES });
    return null;
  }

  return (
    <ScreenComponent
      analyticsScreenName={Screens.PropertyList}
      analyticsTrackPayload={analyticsTrackPayload}
    >
      <tabData.listComponent
        tabNavigation={
          <PropertyListHeader
            tabs={propertyTabData}
            selectedTabIndex={tabData.index}
            onNavigate={(newTab) =>
              analytics.trackScreenVisited(Screens.PropertyList, {
                ...analyticsTrackPayload,
                tab: newTab
              })
            }
          />
        }
      />
    </ScreenComponent>
  );
};

interface PropertyTabData {
  index: number;
  label: PropertyListView;
  listComponent: React.ComponentType<{ tabNavigation: React.ReactElement }>;
  badge?: React.ComponentType<{
    active?: boolean;
    params?: BadgeQueryParams;
  }>;
}

export { PropertyListScreen };
