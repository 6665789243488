import { useEnvironment } from "@ailo/services";
import { useCallback } from "react";

export function useReiUrlGenerator(
  callbackOrigin: string
): (state: string) => string | undefined {
  const { REI_INTEGRATION_URL, REI_CALLBACK_PATH, REI_CLIENT_ID } =
    useEnvironment();
  const redirectUrl = callbackOrigin + REI_CALLBACK_PATH;
  return useCallback(
    (state: string) => {
      if (!REI_INTEGRATION_URL || !REI_CLIENT_ID) return;

      let url = REI_INTEGRATION_URL;
      url += "?response_type=token";
      url += "&client_id=" + encodeURIComponent(REI_CLIENT_ID);
      url += "&state=" + encodeURIComponent(state);
      url += "&redirect_uri=" + encodeURIComponent(redirectUrl);
      return url;
    },
    [REI_CLIENT_ID, REI_INTEGRATION_URL, redirectUrl]
  );
}
