import React, { useState } from "react";
import {
  PlatformFeatureId,
  useAgencyIncomeDateQuery,
  useGetAgencyIncomeByChartOfAccountReportDataQuery,
  useGetFeeTaxCategoriesQuery
} from "local/graphql";
import {
  disbursementPeriodToDate,
  parseFormattedToFloat,
  safeMap,
  useReportQuery,
  useReportTeamContext
} from "../..";
import { ReportPage } from "../../components";
import { notEmpty } from "local/common";
import { LinePortionCard } from "../../components/LinePortionCard";
import { useGlobal } from "reactn";
import {
  formatPercentage,
  LocalDate,
  TimePeriods,
  useFilterModal
} from "@ailo/ui";
import { head, round, sortBy, sumBy } from "lodash";
import { reports } from "../../reportsDetails";
import {
  parseStartAndEndDate,
  ReportTimeFilterCustomPeriod
} from "../../components/TimePeriodFilterCustomPeriod";
import { useTeamFilter } from "local/tabs";

export function IncomeByChartOfAccountData({
  disbursementPeriodSk,
  disbursementPeriodLabel,
  startDate,
  endDate,
  filterComponents
}: {
  disbursementPeriodSk?: string;
  disbursementPeriodLabel?: string;
  startDate?: string;
  endDate?: string;
  filterComponents: React.ReactElement;
}): React.ReactElement {
  const [availableFeatures] = useGlobal("availableFeatures");
  const totalsFeature = availableFeatures.includes(
    PlatformFeatureId.AgencyIncomeTotals
  );
  const [filterState, onSubmit] = useState<{
    teamIds: string[];
    startDate: string;
    endDate: string;
    timePeriod: TimePeriods;
  }>({
    startDate: LocalDate.today().setDayOfMonth(1).toString(),
    endDate: LocalDate.today().toString(),
    teamIds: [],
    timePeriod: "month"
  });

  const enableFilterModal = availableFeatures.includes(
    PlatformFeatureId.ReportingModalFilter
  );

  const { managingOrgId, teamId } = useReportTeamContext();

  const { startDate: filterStateStartDate, endDate: filterStateEndDate } =
    parseStartAndEndDate(filterState);
  const variables = enableFilterModal
    ? {
        ...filterState,
        startDate: filterStateStartDate,
        endDate: filterStateEndDate,
        managingOrgId,
        disbursementPeriodSk,
        teamId: filterState.teamIds
      }
    : {
        managingOrgId,
        disbursementPeriodSk,
        startDate,
        endDate,
        teamId
      };
  const queryResult = useReportQuery(
    useGetAgencyIncomeByChartOfAccountReportDataQuery,
    variables
  );
  const feeTypeResult = useGetFeeTaxCategoriesQuery();
  const feeTypeNames = sortBy(
    feeTypeResult.data?.feeTaxCategories ?? [],
    "name"
  ).map((c) => c.name);

  const reportData = queryResult.data?.agencyIncomeByChartOfAccountReport;
  const { disbursementPeriodStartDate, disbursementPeriodEndDate } =
    disbursementPeriodToDate(disbursementPeriodLabel);
  const rawRows = reportData?.rows?.filter(notEmpty).map((r) => ({
    chartOfAccountName: r.chartOfAccountName,
    incomeAmount: safeMap(r.incomeAmount, parseFormattedToFloat) ?? 0
  }));
  const totalAmount = sumBy(rawRows ?? [], (r) => r.incomeAmount ?? 0);
  const rows = rawRows
    ?.concat(
      (totalsFeature
        ? feeTypeNames.filter(
            (f) => !rawRows.find((r) => r.chartOfAccountName == f)
          )
        : []
      ).map((chartOfAccountName) => ({ chartOfAccountName, incomeAmount: 0 }))
    )
    .map((r) => ({
      ...r,
      pct: safeMap(r.incomeAmount, (v) =>
        totalAmount == 0 ? 0 : v / totalAmount
      )
    }));

  const mgmtFeePct = rows?.find(
    (r) => r?.chartOfAccountName == "Management Fees"
  )?.pct;
  const otherFeePct = totalAmount == 0 ? 0 : 1 - (mgmtFeePct ?? 0);
  const portionData = rows
    ? [
        {
          name: "Management fees",
          value: mgmtFeePct ?? 0
        },
        {
          name: "Other fees",
          value: otherFeePct ?? 0
        }
      ]
    : undefined;

  parseStartAndEndDate(filterState);
  const minDateQuery = useAgencyIncomeDateQuery({
    variables: { managingOrgId }
  });
  const earliestTransaction =
    head(minDateQuery?.data?.agencyIncomeReport?.rows)?.minDate ?? "";

  const loading =
    queryResult.loading || feeTypeResult.loading || minDateQuery.loading;
  const modalFilters = [
    ReportTimeFilterCustomPeriod({
      startDate: filterStateStartDate,
      endDate: filterStateEndDate,
      timePeriod: filterState.timePeriod,
      earliestTransaction
    }),
    useTeamFilter()
  ];
  const { filterButtons } = useFilterModal({
    modalFilters,
    onSubmit,
    filterState,
    loading
  });

  return (
    <>
      {!enableFilterModal && filterComponents}
      <ReportPage
        title={reports.income_by_chart_of_account.reportHeading}
        filterModalButtons={enableFilterModal ? filterButtons : undefined}
        data={{
          ...queryResult,
          loading,
          rows,
          totals: totalsFeature
            ? [
                {
                  incomeAmount: totalAmount,
                  pct: sumBy(rows ?? [], (r) => r.incomeAmount) > 0 ? 1 : null, // when there is no income at all, show nothing for pct
                  totalTitle: "Total"
                }
              ]
            : undefined
        }}
        columnsConfig={{
          chartOfAccountName: { header: "Fee type" },
          incomeAmount: { header: "Amount", type: "currency" },
          pct: { header: "Percentage", type: "pct" }
        }}
        startDate={
          enableFilterModal
            ? filterStateStartDate
            : startDate ?? disbursementPeriodStartDate
        }
        endDate={
          enableFilterModal
            ? filterStateEndDate
            : endDate ?? disbursementPeriodEndDate
        }
        componentAboveTable={
          totalsFeature && !enableFilterModal ? (
            <LinePortionCard
              title={"Source of income"}
              loading={queryResult.loading}
              data={portionData}
              format={(v) =>
                formatPercentage(round(v, 3), { withSymbol: true })
              }
            />
          ) : undefined
        }
        defaultSort={{ columnKey: "incomeAmount", direction: "DESC" }}
        fileNameIncludeDate
        showHeader
      />
    </>
  );
}
