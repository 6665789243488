import {
  KeyedLiveMigratingManagementFragment,
  PropertyOnboardingListColumn
} from "../../types";
import {
  AddressCell,
  ApprovedByCell,
  LiveOnCell
} from "../../PropertyOnboardingListItemCell";
import { LivePropertyOnboardingListColumnNames } from "./useLivePropertyOnboardingList";

export type LivePropertyOnboardingListColumns =
  PropertyOnboardingListColumn<KeyedLiveMigratingManagementFragment>[];

export function useLivePropertyOnboardingListColumns(): LivePropertyOnboardingListColumns {
  return [
    {
      width: 3,
      renderCell: AddressCell,
      renderLoadingCell: AddressCell.Loading,
      name: LivePropertyOnboardingListColumnNames.Address,
      sortable: true,
      caretPosition: "right"
    },
    {
      width: 1,
      renderCell: ApprovedByCell,
      renderLoadingCell: ApprovedByCell.Loading,
      name: LivePropertyOnboardingListColumnNames.ApprovedBy,
      sortable: false,
      caretPosition: "right"
    },
    {
      width: 1,
      renderCell: LiveOnCell,
      renderLoadingCell: LiveOnCell.Loading,
      name: LivePropertyOnboardingListColumnNames.LiveOn,
      sortable: true,
      caretPosition: "right"
    }
  ];
}
