import { GridSortOrder } from "@ailo/ui";
import React from "react";
import { BasePropertyList, BasePropertyListProps } from "./BasePropertyList";
import { ComeBackSoonMessage } from "./ComeBackSoonMessage";
import {
  AddressCell,
  AgreementEndDateCell,
  AgreementStartDateCell,
  RentCell,
  ScheduledRentReviewDateCell
} from "./PropertyItemCell";
import { usePropertyListReorder, useRenewalsPropertyListQuery } from "./hooks";
import { lowerCase, startCase } from "lodash";
import { createCellRenderer } from "./createCellRenderer";

function RenewalsPropertyList({
  tabNavigation
}: {
  tabNavigation: React.ReactElement;
}): React.ReactElement {
  const queryData = useRenewalsPropertyListQuery(initialSortOrder);

  const { sortOrder, onSortOrderChange } = usePropertyListReorder(
    initialSortOrder,
    queryData.requestFromBeginning,
    analyticsInfo
  );

  return (
    <BasePropertyList
      tabNavigation={tabNavigation}
      queryData={queryData}
      errorMessage={"There was a problem loading Renewals & Reviews"}
      emptyMessage={"No properties with lease renewals or rent reviews to show"}
      secondaryEmptyMessage={<ComeBackSoonMessage />}
      sortOrder={sortOrder}
      columns={columns}
      onSortOrderChange={onSortOrderChange}
    />
  );
}
export enum RenewalPropertyListColumns {
  Properties = "Properties",
  Rent = "Rent",
  RentReview = "Rent Review",
  TenancyAgmtStart = "Agrmt. start",
  TenancyAgmtEnd = "Agrmt. end"
}

const columns: BasePropertyListProps["columns"] = [
  {
    width: 3,
    renderCell: createCellRenderer(AddressCell),
    renderLoadingCell: AddressCell.Loading,
    name: RenewalPropertyListColumns.Properties,
    sortable: false
  },
  {
    width: 1,
    renderCell: createCellRenderer(RentCell),
    renderLoadingCell: RentCell.Loading,
    name: RenewalPropertyListColumns.Rent,
    sortable: false,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(ScheduledRentReviewDateCell),
    renderLoadingCell: ScheduledRentReviewDateCell.Loading,
    name: RenewalPropertyListColumns.RentReview,
    sortable: true,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(AgreementStartDateCell),
    renderLoadingCell: AgreementStartDateCell.Loading,
    name: RenewalPropertyListColumns.TenancyAgmtStart,
    sortable: true,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(AgreementEndDateCell),
    renderLoadingCell: AgreementEndDateCell.Loading,
    name: RenewalPropertyListColumns.TenancyAgmtEnd,
    sortable: true,
    horizontalAlign: "right",
    verticalAlign: "middle"
  }
];

const initialSortOrder: GridSortOrder = {
  columnKey: RenewalPropertyListColumns.TenancyAgmtEnd,
  direction: "ASC"
};

function analyticsColName(columnKey: string): string {
  if (columnKey === RenewalPropertyListColumns.TenancyAgmtStart)
    return "Tenancy Agreement Start Date";
  if (columnKey === RenewalPropertyListColumns.TenancyAgmtEnd)
    return "Tenancy Agreement End Date";
  if (columnKey === RenewalPropertyListColumns.RentReview) return "Rent Review";
  throw new Error(`Unexpected column to return analytics: ${columnKey}`);
}

function analyticsInfo(sortOrder: GridSortOrder): {
  eventName: string;
  orderBy: string;
} {
  return {
    eventName: "Renewals & Reviews Property List Reordered",
    orderBy: `${analyticsColName(sortOrder.columnKey)} ${startCase(
      lowerCase(sortOrder.direction)
    )}`
  };
}

export { RenewalsPropertyList };
