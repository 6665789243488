import { BadgeType } from "@ailo/ui";

export type BillAgencyStatus =
  | "Cancelled"
  | "Refunded"
  | "RefundFailed"
  | "AwaitingPayment"
  | "Overdue"
  | "Due"
  | "PaymentSent"
  | "Paid";

/**
 * @deprecated use `badgeTypesByBillDisplayStatusV22` instead
 */
const badgeTypesByBillDisplayStatus: {
  [key in BillAgencyStatus]: BadgeType;
} = {
  AwaitingPayment: "default",
  Due: "critical",
  PaymentSent: "attention",
  Paid: "success",
  Cancelled: "default",
  Overdue: "critical",
  Refunded: "default",
  RefundFailed: "critical"
};

/**
 * @deprecated use `getExpenseStatusBadgeType` instead
 */
export function getBillDisplayStatusBadgeType(
  agencyStatus: BillAgencyStatus
): BadgeType {
  return badgeTypesByBillDisplayStatus[agencyStatus];
}
