import { ContactCardSetup } from "@ailo/domains";
import { Colors } from "@ailo/primitives";
import { useNavigation } from "local/common";
import React, { useEffect } from "react";
import { View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { BaseDrawerNavigator } from "./BaseDrawerNavigator";
import { DrawerHeader } from "./DrawerHeader";
import { ScreenHeader } from "./ScreenHeader";
import { useGetAvailableDrawers } from "./useGetAvailableDrawers";
import { ZendeskWidget } from "./ZendeskWidget";
import { SelectedTeamContextProvider } from "local/domain/propertyManagement";

export const AppNavigator: React.FC = () => {
  const navigation = useNavigation();
  const drawers = useGetAvailableDrawers();

  useEffect(() => {
    navigation.setOptions({ header: () => null });
  }, [navigation]);

  return (
    <SelectedTeamContextProvider>
      <ContactCardSetup>
        <HeaderContainer>
          <DrawerHeader style={drawerStyle} />
          <ScreenHeader />
        </HeaderContainer>
        <BaseDrawerNavigator
          drawerHeader={<View style={{ height: 24 }} />}
          navData={drawers}
          drawerStyle={drawerStyle}
          drawerType={"permanent"}
          initialRouteName={drawers[0]?.name}
        />
        <ZendeskWidget />
      </ContactCardSetup>
    </SelectedTeamContextProvider>
  );
};

const drawerStyle: ViewStyle = {
  width: 200,
  backgroundColor: Colors.CHARCOAL,
  borderRightWidth: 0
};

const HeaderContainer = styled(View)`
  flex-direction: row;
`;
