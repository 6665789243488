import { ClaimTenancyData } from "local/domain/bond";
import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ClaimBondFormData } from "../../../ClaimBondFormData";
import { Money, MoneyInput } from "@ailo/ui";

export function FormControlledRentClaimInput({
  data
}: {
  data: ClaimTenancyData;
}): ReactElement {
  const { errors, control, register, unregister, setValue } =
    useFormContext<ClaimBondFormData>();

  useEffect(() => {
    const reference = `rent.reference`;
    register(reference);
    setValue(reference, data.rent.reference);

    return () => {
      unregister(reference);
    };
  }, [data.rent.reference, register, unregister, setValue]);

  const name = "rent.amount";

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={data.rent.amountClaimed}
      rules={{
        validate: (value: Money) =>
          valueIsNotGreaterThanOutstandingRent(value, data) ||
          `You cannot claim more than the outstanding rent`
      }}
      render={({ onChange, onBlur, value, ref }): React.ReactElement => (
        <MoneyInput
          value={value}
          onBlur={onBlur}
          inputRef={ref}
          onChange={onChange}
          style={{ paddingLeft: "6px" }}
          label={"Rent to be paid from bond"}
          error={errors?.rent?.amount?.message}
        />
      )}
    />
  );
}

function valueIsNotGreaterThanOutstandingRent(
  amount: Money,
  data: ClaimTenancyData
): boolean {
  return !amount || amount.cents <= data.rent.outstandingAmount.cents;
}
