import { DateTimeWithTimeZone, DateInputFormField } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RelativeTimeInDays } from "local/common";
import { LeaseRenewalFormData } from "../LeaseRenewalFormData";

interface Props {
  currentAgreementStartDate?: string;
  tenancyEndDate?: string;
}

export function AgreementStartDateInput({
  currentAgreementStartDate,
  tenancyEndDate
}: Props): ReactElement {
  const { control, errors } = useFormContext<LeaseRenewalFormData>();

  const dayAfterCurrentAgreementStartedOrTomorrow = currentAgreementStartDate
    ? DateTimeWithTimeZone.from(currentAgreementStartDate)
        .add(1, "day")
        .toLocalDateString()
    : DateTimeWithTimeZone.now().add(1, "day").toLocalDateString();

  const dayBeforeTenancyEndDate = tenancyEndDate
    ? DateTimeWithTimeZone.from(tenancyEndDate)
        .subtract(1, "day")
        .toLocalDateString()
    : undefined;

  return (
    <Controller
      name={"agreementStartDate"}
      render={({ value, onChange, onBlur }): ReactElement => {
        return (
          <>
            <DateInputFormField
              webPopoverHorizontalAlign={"start"}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              label={"Start date"}
              placeholder={"Start date"}
              autoCorrect={false}
              autoCompleteType={"off"}
              minDate={dayAfterCurrentAgreementStartedOrTomorrow}
              maxDate={dayBeforeTenancyEndDate}
              error={errors.agreementStartDate?.message}
            />
            <RelativeTimeInDays style={{ marginTop: 4 }} date={value} />
          </>
        );
      }}
      defaultValue={null}
      control={control}
      rules={{
        required: "Please select a start date"
      }}
    />
  );
}
