import { AiloRN, services } from "@ailo/ailorn";
import { getNextRecurrence } from "@ailo/domains";
import {
  didQueryNotLoadYet,
  didQuerySucceed,
  useOnFocus
} from "@ailo/services";
import { Money, MoneyInterface } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import {
  FeeBlueprintType,
  FeeFrequency,
  TaxTreatment,
  useGetFeeBlueprintsAndAppliedRecurringFeesQuery
} from "local/graphql";
import moment from "moment";
import { useMemo } from "react";

interface Props {
  managementId: string;
}

export type RecurringFeeBlueprintOption = {
  label: string;
  /** blueprint ailorn string */
  value: string;
  price: MoneyInterface;
  taxTreatment: TaxTreatment;
  priceIncludingTax: MoneyInterface;
  frequency: FeeFrequency;
  anniversaryDay: number;
  anniversaryMonth?: number;
  startDate: string;
  type: FeeBlueprintType;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useGetRecurringFeeOptions({ managementId }: Props) {
  const { id: organisationId } = useCurrentAgencyOrg();

  const result = useGetFeeBlueprintsAndAppliedRecurringFeesQuery({
    variables: {
      agencyOrganisationId: organisationId,
      blueprintType: FeeBlueprintType.RecurringFee,
      managementId,
      managementAilorn: AiloRN.of(
        services.PropertyManagement.management,
        managementId
      ).toString()
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network"
  });

  useOnFocus(result.refetch);

  const options: RecurringFeeBlueprintOption[] = useMemo(
    () =>
      result.data?.blueprints?.items
        .filter(
          (blueprint) =>
            !result.data?.recurringFees?.some(
              (fee) => fee.blueprint.id === blueprint.id
            )
        )
        .map((blueprint) => {
          if (!blueprint.frequency) {
            throw new Error("Recurring Fee Blueprint must have a frequency");
          }
          if (!blueprint.anniversaryDay) {
            throw new Error(
              "Recurring Fee Blueprint must have an anniversaryDay"
            );
          }
          if (!blueprint.fixedAmount) {
            throw new Error("Recurring Fee Blueprint must have a fixedAmount");
          }

          return {
            label: blueprint.name,
            value: blueprint.ailorn,
            price: blueprint.fixedAmount,
            priceIncludingTax:
              blueprint.taxTreatment === TaxTreatment.Exclusive
                ? Money.from(blueprint.fixedAmount).multiply(1.1)
                : Money.from(blueprint.fixedAmount),
            taxTreatment: blueprint.taxTreatment,
            frequency: blueprint.frequency,
            anniversaryDay: blueprint.anniversaryDay,
            anniversaryMonth: blueprint.anniversaryMonth ?? undefined,
            startDate: getNextRecurrence({
              asOf: moment.tz("Australia/Sydney"), // TODO: Don't hard code timezones - should use agency timezone
              frequency: blueprint.frequency,
              anniversaryDay: blueprint.anniversaryDay,
              anniversaryMonth: blueprint.anniversaryMonth
            }),
            type: blueprint.type
          };
        }) ?? [],
    [result]
  );

  const management = result.data?.management;
  const managementAgreementAilornString =
    management?.currentOrNextManagementAgreement?.ailorn;
  const managingEntityAilornString = management?.managingEntity?.ailorn;

  return {
    loading: didQueryNotLoadYet(result),
    error: !didQueryNotLoadYet(result) && !didQuerySucceed(result),
    refetch: result.refetch,
    options,
    managementAgreementAilorn: managementAgreementAilornString
      ? AiloRN.fromString(managementAgreementAilornString)
      : undefined,
    managingEntityAilorn: managingEntityAilornString
      ? AiloRN.fromString(managingEntityAilornString)
      : undefined
  };
}
