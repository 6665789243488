import { SelectOption } from "@ailo/ui";
import { PersonNamesFragment, ProjectType } from "local/graphql";

export const ProjectFormFields = {
  management: "management",
  type: "type",
  name: "name",
  description: "description",
  assignee: "assignee",
  dueDate: "dueDate",
  files: "files",
  meta: "meta"
} as const;

export interface ProjectFormData {
  [ProjectFormFields.management]?: SelectOption | null;
  [ProjectFormFields.type]?: ProjectType;
  [ProjectFormFields.name]?: string;
  [ProjectFormFields.description]?: string;
  [ProjectFormFields.assignee]?: PersonNamesFragment | null;
  [ProjectFormFields.dueDate]?: string;
  [ProjectFormFields.files]?: Array<{
    localKey: string | number;
    id: string;
    key: string;
    fileName: string;
    fileUri: string;
    fileSize: number;
  }>;
  [ProjectFormFields.meta]?: Record<string, unknown>;
}
