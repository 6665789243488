import { useCurrentUser } from "@ailo/domains";
import { MultiSelectButton } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import { useGetMembersByOrganisationQuery } from "local/graphql";
import React, { useCallback, useMemo } from "react";
import { isPresent } from "ts-is-present";
import { formatPersonName } from "@ailo/domain-helpers";

type FilterProps = {
  onChange: (selectedValues: string[]) => void;
  values: string[];
};

export function AssigneesFilter({
  onChange,
  values
}: FilterProps): React.ReactElement {
  const { person: currentUserPerson } = useCurrentUser();

  const { id: organisationId } = useCurrentAgencyOrg();

  const { data, loading, refetch, error } = useGetMembersByOrganisationQuery({
    variables: {
      organisationId
    }
  });

  const currentUserOption = useMemo(
    () => ({
      label: formatPersonName(currentUserPerson),
      value: currentUserPerson.ailoRN.toString()
    }),
    [currentUserPerson]
  );

  const otherUserOptions = useMemo(
    () =>
      data?.organisation?.members?.items
        ?.map((person) => ({
          label: formatPersonName(person),
          value: person.ailoRN.toString()
        }))
        .filter((option) => option.value !== currentUserOption.value)
        .sort((userA, userB) => (userA.label <= userB.label ? -1 : 1)) || [],
    [currentUserOption.value, data?.organisation?.members?.items]
  );

  const optionGroups = useMemo(
    () => [
      {
        label: "",
        options: [currentUserOption]
      },
      {
        label: "",
        options: otherUserOptions
      }
    ],
    [currentUserOption, otherUserOptions]
  );

  const selectedOptions = useMemo(
    () =>
      values
        .map((value) => {
          return [...otherUserOptions, currentUserOption]?.find(
            (option) => value === option.value
          );
        })
        .filter(isPresent),
    [otherUserOptions, currentUserOption, values]
  );

  const onChangeOption = useCallback(
    (values: { label: string; value: string }[]) => {
      onChange(values?.map((v) => v.value));
    },
    [onChange]
  );

  if (loading && !data) {
    return <MultiSelectButton.Loading entityType={"Assignees"} />;
  }

  if (!optionGroups || error) {
    return (
      <MultiSelectButton.Error entityType={"Assignees"} onReload={refetch} />
    );
  }

  return (
    <MultiSelectButton
      entityType={"Assignees"}
      optionGroups={optionGroups}
      value={selectedOptions}
      onChange={onChangeOption}
    />
  );
}
