import React, { ReactElement, useCallback } from "react";
import { InfiniteScrollList } from "@ailo/ui";
import { ActionDetailsFragment } from "local/graphql";
import { ActionCard } from "./ActionCard";
import { ActionStatusContextProvider } from "local/tabs/projects/actions/ActionStatusContextProvider";

interface Props {
  projectId: string;
  actions: ActionDetailsFragment[];
  onLoadMore?: () => void;
  loadingMore: boolean;
  bounces?: boolean;
}

export function ActionsList({
  projectId,
  actions,
  onLoadMore,
  loadingMore,
  bounces = true
}: Props): React.ReactElement {
  const renderItem = useCallback(
    ({ item }: { item: ActionDetailsFragment }): ReactElement => {
      return (
        <ActionStatusContextProvider
          key={item.id}
          projectId={projectId}
          action={item}
        >
          <ActionCard action={item} projectId={projectId} />
        </ActionStatusContextProvider>
      );
    },
    [projectId]
  );

  return (
    <InfiniteScrollList
      style={{ flex: 1, padding: 4 }}
      data={actions}
      renderItem={renderItem}
      keyExtractor={(item): string => item.id}
      onEndReachedThreshold={0.3}
      bounces={bounces}
      loadingMore={loadingMore}
      onLoadMore={onLoadMore}
      contentContainerStyle={{
        paddingBottom: 48,
        paddingTop: 28,
        paddingRight: 56
      }}
    />
  );
}
