import { Text } from "@ailo/primitives";
import { withScreenComponent } from "@ailo/services";
import { PageContent } from "local/common";
import React from "react";
import { PopularReportsSection } from "./components/PopularReports";
import { ReportColumns } from "./components/ReportOverview";

export const ReportListScreen = withScreenComponent(
  (): React.ReactElement | null => (
    <PageContent>
      <Text.DisplayM style={{ marginBottom: 40 }}>{"Reporting"}</Text.DisplayM>
      <PopularReportsSection />
      <ReportColumns />
    </PageContent>
  )
);
