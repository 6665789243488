import React from "react";
import { View } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";
import { SFC } from "@ailo/primitives";
import { LabelledTextInput } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";

interface Props {
  accountName: string;
}

const AccountNameInput: SFC<Props> = ({ accountName, style }) => {
  const { control } = useFormContext<AddBillFormData>();

  return (
    <View style={style}>
      <Controller
        as={LabelledTextInput}
        name={"payment.accountName"}
        label={"Account Name"}
        placeholder={"Account Name"}
        defaultValue={accountName}
        editable={false}
        control={control}
        onChange={([selected]: any): string => {
          return selected;
        }}
        rules={{
          validate: (accountName): boolean | string =>
            !isEmpty(accountName) ||
            "Bank account payment method missing account name"
        }}
        testID={"AccountNameInputFormField"}
      />
    </View>
  );
};

export { AccountNameInput };
