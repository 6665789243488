import { Colors, opacify } from "@ailo/primitives";
import {
  AiloSentry,
  didQueryNotLoadYet,
  didQuerySucceed,
  useAnalytics,
  useOnFocus
} from "@ailo/services";
import {
  AlertScreen,
  ErrorAlertScreen,
  SidebarContent,
  useAlertContext,
  useToastContext
} from "@ailo/ui";
import {
  FeeBlueprintType,
  GetManagementFeeBlueprintsDocument,
  GetManagementFeeBlueprintsQuery,
  useArchiveManagementFeeBlueprintMutation,
  useGetManagementFeeBlueprintsQuery
} from "local/graphql";
import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { AddButton } from "../AddButton";
import { AgencyFeeBlueprintQuery } from "../ManagementFeeBlueprintModals";
import { ManagementFeeBlueprintsListItem } from "../ManagementFeeBlueprintListItem";

interface Props {
  managementId: string;
  setShowFooter: (show: boolean) => void;
  onAddBlueprintPress: () => void;
  onEdit: (
    blueprint: NonNullable<
      GetManagementFeeBlueprintsQuery["managementFeeBlueprints"]
    >["items"][number]
  ) => void;
  agencyBlueprintsQuery: Omit<AgencyFeeBlueprintQuery, "options">;
}

export function ManualManagementFeeBlueprintsList({
  managementId,
  setShowFooter,
  onAddBlueprintPress,
  onEdit,
  agencyBlueprintsQuery
}: Props): ReactElement {
  const { confirm } = useAlertContext();

  const getManagementFeeBlueprintsQueryVariables = useMemo(
    () => ({
      conditions: {
        managementId,
        archived: false,
        type: FeeBlueprintType.OneOffFee
      }
    }),
    [managementId]
  );

  const [archive] = useArchiveManagementFeeBlueprintMutation({
    refetchQueries: [
      {
        query: GetManagementFeeBlueprintsDocument,
        variables: getManagementFeeBlueprintsQueryVariables
      }
    ]
  });

  const analytics = useAnalytics();
  const toast = useToastContext();

  const onRemove = useCallback(
    async (managementFeeBlueprintId: string) => {
      const response = await archive({
        variables: { managementFeeBlueprintId }
      });
      if (response.data?.updateManagementFeeBlueprint) {
        toast.show({
          type: "success",
          message: `${response.data.updateManagementFeeBlueprint.feeBlueprint.name.trim()} template removed`
        });
        analytics.track("Fee Blueprint Removed from Property Schedule", {
          feeBlueprintId: response.data?.updateManagementFeeBlueprint.id,
          managementId
        });
        agencyBlueprintsQuery.refetch();
      } else {
        response.errors && AiloSentry.captureException(response.errors);
        toast.show({ type: "error", message: "Could not remove fee template" });
      }
    },
    [agencyBlueprintsQuery, analytics, archive, managementId, toast]
  );

  const blueprintsResult = useGetManagementFeeBlueprintsQuery({
    variables: getManagementFeeBlueprintsQueryVariables
  });

  const { refetch, data } = blueprintsResult;

  useOnFocus(refetch);

  const { managementFeeBlueprints } = data ?? {};
  const blueprints = managementFeeBlueprints?.items;

  useEffect(() => {
    setShowFooter(!!blueprints?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blueprints?.length]);

  if (didQueryNotLoadYet(blueprintsResult)) {
    return <ManualManagementFeeBlueprintsList.Loading />;
  }

  if (!didQuerySucceed(blueprintsResult)) {
    return (
      <ErrorAlertScreen
        title={"There was a problem loading manual fee schedules"}
        onRetry={blueprintsResult.refetch}
      />
    );
  }

  if (!blueprints?.length) {
    return (
      <ManualManagementFeeBlueprintsList.Empty
        managementId={managementId}
        onAddBlueprintPress={onAddBlueprintPress}
        agencyBlueprintsQuery={agencyBlueprintsQuery}
      />
    );
  }

  return (
    <Container>
      {blueprints.map((blueprint, index) => (
        <ManagementFeeBlueprintsListItem
          key={index}
          edit={() => {
            onEdit(blueprint);
          }}
          remove={async () => {
            await confirm({
              title: `Remove ${blueprint.feeBlueprint.name
                .trim()
                .toLowerCase()} template`,
              message:
                "Any fees that have already been charged from this template will not be affected. To cancel an existing fee, navigate to the expenses section.",
              confirmLabel: "Remove",
              destructive: true,
              onConfirm: () => void onRemove(blueprint.id)
            });
          }}
          managementFeeBlueprint={blueprint}
        />
      ))}
    </Container>
  );
}

ManualManagementFeeBlueprintsList.Loading = function Loading({
  itemsLimit = 3,
  style
}: {
  itemsLimit?: number;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  const listItems = [];
  for (let i = 0; i < itemsLimit; i++) {
    const color = opacify(
      Colors.CHARCOAL,
      0.75 * ((itemsLimit - i) / itemsLimit)
    );
    listItems.push(
      <ManagementFeeBlueprintsListItem.Loading
        key={i}
        color={color}
        style={{ borderType: "none" }}
      />
    );
  }

  return (
    <Container style={style}>
      <View>{listItems}</View>
    </Container>
  );
};

ManualManagementFeeBlueprintsList.Empty = function Empty({
  onAddBlueprintPress,
  agencyBlueprintsQuery
}: Pick<
  Props,
  "managementId" | "onAddBlueprintPress" | "agencyBlueprintsQuery"
>): ReactElement {
  return agencyBlueprintsQuery.loaded && agencyBlueprintsQuery.error ? (
    <ErrorAlertScreen
      title={"There was a problem loading agency fee schedules"}
      onRetry={agencyBlueprintsQuery.refetch}
    />
  ) : (
    <AlertScreen
      type={"plant-and-house-and-document"}
      description={`No manual fee templates have been\nadded to this property yet`}
      inlineButton={
        !agencyBlueprintsQuery.loaded ? (
          <AddButton.Loading variant={"solid"} style={{ marginTop: 16 }} />
        ) : (
          <AddButton
            label={"Add a manual fee template"}
            onPress={onAddBlueprintPress}
            variant={"solid"}
            style={{ marginTop: 16 }}
          />
        )
      }
      style={{ marginBottom: -SidebarContent.paddingBottom }}
    />
  );
};

const Container = styled(View)`
  padding-left: 16px;
`;
