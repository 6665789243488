import { PageCenteredColumn, PageContent } from "local/common";
import React, { ReactElement, useRef } from "react";
import { ScrollView } from "react-native";
import styled from "styled-components/native";
import { ManagementPortfolioOwnersCard } from "../ManagementPortfolioOwnersCard";
import { ManagementPortfolioHeader } from "../ManagementPortfolioHeader";
import { ManagementPortfolioContentProps } from "./ManagementPortfolioContentProps";
import { ManagementPortfolioPropertiesCard } from "../ManagementPortfolioPropertiesCard/ManagementPortfolioPropertiesCard";

export function ManagementPortfolioContent({
  portfolioAilorn
}: ManagementPortfolioContentProps): ReactElement {
  const scrollViewRef = useRef<ScrollView>(null);

  return (
    <PageContent scrollViewRef={scrollViewRef}>
      <StyledPageCenteredColumn>
        <ManagementPortfolioHeader portfolioAilorn={portfolioAilorn} />
        <ManagementPortfolioOwnersCard portfolioAilorn={portfolioAilorn} />
        <ManagementPortfolioPropertiesCard portfolioAilorn={portfolioAilorn} />
      </StyledPageCenteredColumn>
    </PageContent>
  );
}

const StyledPageCenteredColumn = styled(PageCenteredColumn)`
  max-width: 640;
`;
