import { AiloRN } from "@ailo/ailorn";
import { LocalDate } from "@ailo/date";
import { useCurrentUser } from "@ailo/domains";
import {
  InspectionDay,
  InspectionDayQueryReturn,
  useGetInspectionDays
} from "local/domain/inspection";
import React, { createContext, useContext, useState, useMemo } from "react";

type InspectionPlannerContextValue = {
  selectedInspectionDate: LocalDate | null;
  setSelectedInspectionDate: (newDate: LocalDate | null) => void;
  inspectionDayQuery: InspectionDayQueryReturn;
  selectedInspectionDay: InspectionDay | null;
  assignee: AiloRN;
  setAssignee: (assignee: AiloRN) => void;
};

const InspectionPlannerContext = createContext<InspectionPlannerContextValue>({
  selectedInspectionDate: null,
  setSelectedInspectionDate: (): void => {},
  inspectionDayQuery: { data: [], loading: true, error: false },
  selectedInspectionDay: null,
  assignee: AiloRN.of("authz:legalentity", "InspectionPlannerInitialValue"),
  setAssignee: (): void => {}
});

export function useInspectionPlanner(): InspectionPlannerContextValue {
  return useContext(InspectionPlannerContext);
}

export function InspectionPlannerContextProvider({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const {
    person: { ailoRN: personAilorn }
  } = useCurrentUser();
  const [selectedInspectionDate, setSelectedInspectionDate] =
    useState<LocalDate | null>(null);
  const [assignee, setAssignee] = useState<AiloRN>(AiloRN.from(personAilorn));
  const inspectionDayQuery = useGetInspectionDays(assignee);

  const selectedInspectionDay = useMemo(() => {
    if (!selectedInspectionDate) {
      return null;
    }

    return (
      inspectionDayQuery.data.find((inspectionDay) =>
        inspectionDay.date.isSame(selectedInspectionDate)
      ) || null
    );
  }, [selectedInspectionDate, inspectionDayQuery]);

  return (
    <InspectionPlannerContext.Provider
      value={{
        selectedInspectionDate,
        setSelectedInspectionDate,
        inspectionDayQuery,
        selectedInspectionDay,
        assignee,
        setAssignee
      }}
    >
      {children}
    </InspectionPlannerContext.Provider>
  );
}
