import { ErrorBoundary } from "@ailo/services";
import React, { ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { ErrorCard } from "./ErrorCard";

export function PropertyScreenCardErrorBoundary({
  cardTitle,
  style,
  children
}: {
  cardTitle: string;
  style?: StyleProp<ViewStyle>;
  children?: ReactNode;
}): React.ReactElement {
  return (
    <ErrorBoundary
      fallbackComponent={({ retry }): React.ReactElement => (
        <ErrorCard onRetry={retry} cardTitle={cardTitle} style={style} />
      )}
    >
      {children}
    </ErrorBoundary>
  );
}
