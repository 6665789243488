import React from "react";
import {
  BriefcaseAvatar,
  CellContainer,
  PersonAvatar,
  TextCellContent
} from "@ailo/ui";
import { CustomersListRow } from "../useCustomersListQuery";
import { Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";

function NameCell({ row }: { row: CustomersListRow }): React.ReactElement {
  const { legalEntity } = row;

  if (!legalEntity) return <></>;

  const displayName =
    legalEntity?.__typename === "Company"
      ? legalEntity?.registeredEntityName
      : `${legalEntity?.firstName} ${legalEntity?.lastName}`;

  return (
    <StyledCellContainer>
      <IconContainer>
        {legalEntity?.__typename === "Person" ? (
          <PersonAvatar size={40} person={legalEntity} />
        ) : (
          <BriefcaseAvatar size={40} iconSize={28} />
        )}
      </IconContainer>
      <TextCellContent weight={"medium"} TextComponent={Text.BodyM}>
        {displayName}
      </TextCellContent>
    </StyledCellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <StyledCellContainer>
      <IconContainer>
        <PersonAvatar.Loading size={40} />
      </IconContainer>
      <TextCellContent.AdjustableLoading style={{ flex: 1 }} />
    </StyledCellContainer>
  );
}

NameCell.Loading = Loading;

const IconContainer = styled(View)`
  width: 40px;
  height: 40px;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
`;

const StyledCellContainer = styled(CellContainer)`
  flex-direction: row;
  align-items: center;
`;

export { NameCell };
