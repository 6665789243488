import React from "react";
import { View, Image } from "react-native";
import styled from "styled-components/native";
import { Text, Colors, LeftChevronIcon, AiloHouses } from "@ailo/primitives";
import { Button, DotDivider } from "@ailo/ui";
import { useAuth, useEnvironment, withScreenComponent } from "@ailo/services";

export const RecommendAiloInsightScreen = withScreenComponent(
  (): React.ReactElement => {
    const { logout } = useAuth();
    const envVars = useEnvironment();

    return (
      <>
        <BackButton
          leftIcon={LeftChevronIcon}
          onPress={(): void => {
            logout();
          }}
        >
          {"Back to Login"}
        </BackButton>
        <Container>
          <Text.DisplayS
            color={Colors.TEXT.DARK.PRIMARY}
            style={{ textAlign: "center" }}
          >
            {"Oops! Are you looking\nfor Ailo Insights?"}
          </Text.DisplayS>

          <Text.BodyM style={{ marginTop: 8, textAlign: "center" }}>
            {
              "You've logged into the Ailo app for managing\nrenters and property investors."
            }
          </Text.BodyM>

          <Button.Primary style={{ marginTop: 24 }} href={envVars.INSIGHT_URL}>
            {"Open Ailo Insights"}
          </Button.Primary>

          <LinkContainer>
            <Button.Text href={envVars.AILO_SUPPORT_EMAIL}>
              <Text.BodyL weight={"medium"}>{"Enquire"}</Text.BodyL>
            </Button.Text>

            <DotDivider color={Colors.AILO_RED} />

            <Button.Text href={envVars.AILO_INFO_URL}>
              <Text.BodyL weight={"medium"}>
                {"Learn about new Ailo"}
              </Text.BodyL>
            </Button.Text>
          </LinkContainer>
        </Container>

        <FooterImage source={AiloHouses} />
      </>
    );
  }
);

const Container = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const LinkContainer = styled(View)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 28px;
`;

const BackButton = styled(Button.Text)`
  margin-top: 42px;
  margin-left: 53px;
  align-self: flex-start;
`;

const FooterImage = styled(Image)`
  align-self: center;
  width: 460px;
  height: 150px;
`;
