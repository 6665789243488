import { useNavigation, Screens } from "local/common";
import { useCallback } from "react";

export function useNavigateToAddExpense(): ({
  managementId
}: {
  managementId: string;
}) => void {
  const navigation = useNavigation();

  return useCallback(
    ({ managementId }): void => {
      navigation.navigate(Screens.PropertiesTab, {
        screen: Screens.AddExpense,
        params: {
          managementId
        }
      });
    },
    [navigation]
  );
}
