import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import {
  ReiFormRemoteSigningStatus,
  useGetReiFormRemoteSigningStatusQuery
} from "local/graphql";

export function useGetReiFormSigningStatus(
  formId: string
): MappedQueryResult<ReiFormRemoteSigningStatus | undefined> {
  return useMappedQueryResult(
    useGetReiFormRemoteSigningStatusQuery({
      variables: { formId },
      fetchPolicy: "network-only"
    }),
    (data) => {
      if (data == null) {
        return undefined;
      }
      if (data.form.__typename !== "ReiForm") {
        throw new ApolloError({
          errorMessage: "Form is not of type ReiForm"
        });
      }
      return data.form.remoteSigningStatus;
    }
  );
}
