import { AiloRN } from "@ailo/ailorn";
import { LoadingSynchroniser } from "@ailo/primitives";
import { didQuerySucceed, useOnFocus, usePaginatedQuery } from "@ailo/services";
import { Card, MultiSelectButton, PaginationNavigation } from "@ailo/ui";
import {
  PageContent,
  Screens,
  useCurrentAgencyOrg,
  useRoute
} from "local/common";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useState
} from "react";
import { View, ViewStyle } from "react-native";
import { useDebouncedCallback } from "use-debounce";
import { BillGridRows, EmptyBillGrid } from "./BillGrid";
import { BillGridHeader } from "./BillGridHeader";
import { useGetBillsQuery } from "local/graphql";
import { getBillsQueryTabVariables } from "./getBillsQueryTabVariables";
import { ScreenHeader } from "./ScreenHeader";
import { TabId } from "./tabs";
import { useTabCounts } from "./useTabCounts";

import { BillPayerType } from "@ailo/domains";
import { ColWidths } from "./BillGrid/BillGridRows/components";
import styled from "styled-components/native";
import { values } from "lodash";
import { useSelectedTeams } from "local/domain/propertyManagement";
import { useNavigation } from "@react-navigation/native";

const gridColWidths: ColWidths = [1, 3, 3, 3, 1, 1];

export interface BillListScreenContentRefAttributes {
  refetch(): void;
  resetPage(): void;
}

interface Props {
  tab: TabId;
  onBillRowPress?(billId: string): void;
  onTabChange?: React.ComponentProps<typeof ScreenHeader>["onTabChange"];
  payerTypes: UsePayerTypes;
}

const payerTypeOptions = {
  label: "",
  options: values(BillPayerType)
    .filter((p) => p !== BillPayerType.LegalEntity)
    .map((p) => ({ label: p, value: p }))
};

export interface UsePayerTypes {
  selected: BillPayerType[];
  setSelected: (selected: BillPayerType[]) => void;
  selectedOptions: {
    label: BillPayerType;
    value: BillPayerType;
  }[];
}

export const usePayerTypes = (
  onSelected: (selected: BillPayerType[]) => void
): UsePayerTypes => {
  const [selected, setSelectedState] = useState<BillPayerType[]>([]);
  const selectedOptions = payerTypeOptions.options.filter((p) =>
    selected?.includes(p.value)
  );
  const setSelected = (selected: BillPayerType[]): void => {
    setSelectedState(selected);
    onSelected?.(selected);
  };
  return { selected, setSelected, selectedOptions };
};

export const BillListScreenContent = React.forwardRef<
  BillListScreenContentRefAttributes,
  Props
>(function BillListScreenContent(
  { tab, onBillRowPress, onTabChange, payerTypes },
  ref
) {
  const { propertyListSelectedTeamIds, propertyListUnassignedSelected } =
    useSelectedTeams();
  const currentOrganisation = useCurrentAgencyOrg();
  const { params } = useRoute<Screens.BillsTab>();
  const { setParams } = useNavigation();
  const initialBillPayer = params?.payerType ? params.payerType : null;
  useOnFocus(
    () => {
      if (initialBillPayer != null) {
        payerTypes.setSelected([initialBillPayer]);
        setParams({ payerType: "" });
      }
    },
    { fireOncePerFocus: true }
  );

  const result = usePaginatedQuery(useGetBillsQuery, "bills", {
    variables: {
      ...getBillsQueryTabVariables(tab),
      organisationId: currentOrganisation.ailoRN,
      payerType: payerTypes.selected ?? [],
      team:
        propertyListSelectedTeamIds.length === 0
          ? null
          : propertyListSelectedTeamIds,
      includeUnassigned: propertyListUnassignedSelected,
      pageSize: 25
    },
    fetchPolicy: "cache-and-network"
  });
  const tabCounts = useTabCounts();

  useLayoutEffect(() => {
    if (result.currentPage !== 1) {
      result.goToPage(1);
    }
    // Intentionally reset pagination when query-impacting changes are made
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, payerTypes.selected, propertyListSelectedTeamIds]);

  const screenHeader = (
    <>
      <ScreenHeader
        tab={tab}
        tabCounts={tabCounts.data}
        tabCountsLoading={tabCounts.loading && !tabCounts.data}
        onTabChange={onTabChange}
      />
      <FilterContainer>
        <MultiSelectButton
          optionGroups={[payerTypeOptions]}
          entityType={"Payer types"}
          value={payerTypes.selectedOptions}
          onChange={(values) =>
            payerTypes.setSelected(values.map((v) => v.value))
          }
        />
      </FilterContainer>
    </>
  );

  useImperativeHandle(
    ref,
    () => ({
      refetch() {
        result.refetch();
        tabCounts.refetch();
      },
      resetPage() {
        result.goToPage(1);
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result.refetch, tabCounts.refetch, result.goToPage]
  );

  useOnFocus(tabCounts.refetch, { skipInitialFocus: true });

  const { callback: debouncedResultRefetch } = useDebouncedCallback(
    useCallback(() => {
      result.refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result.refetch]),
    30
  );
  useOnFocus(debouncedResultRefetch);
  useEffect(debouncedResultRefetch, [tab, debouncedResultRefetch]);

  if (result.loading) {
    return (
      <PageContent stickyHeaderIndices={[1]}>
        {screenHeader}
        <BillGridHeader.Loading
          gridColWidths={gridColWidths}
          style={centerized}
        />
        <Card style={gridContentContainerStyle}>
          <BillGridRows.Loading gridColWidths={gridColWidths} />
        </Card>
      </PageContent>
    );
  }

  if (!didQuerySucceed(result) || !result.data.bills) {
    throw result.error ?? new TypeError(`result.data.bills is empty`);
  }

  if (result.data.bills.pageInfo.total === 0)
    return (
      <PageContent>
        {screenHeader}
        <EmptyBillGrid
          style={{
            marginTop: -80,
            marginLeft: 0,
            marginRight: 0
          }}
        />
      </PageContent>
    );

  return (
    <LoadingSynchroniser>
      <PageContent stickyHeaderIndices={[1]}>
        {screenHeader}

        <BillGridHeader
          gridColWidths={gridColWidths}
          numBills={result.data.bills.pageInfo.total}
          style={centerized}
        />

        <Card style={gridContentContainerStyle} testID={"bill-grid"}>
          <BillGridRows
            billIds={result.data.bills.items.map(
              (bill) => AiloRN.fromString(bill.ailoRN).internalId
            )}
            gridColWidths={gridColWidths}
            onBillRowPress={onBillRowPress}
          />
        </Card>

        <PaginationNavigation
          style={{ marginTop: 20, justifyContent: "center" }}
          currentPage={result.currentPage}
          pagesCount={result.pagesCount}
          hasNextPage={result.hasNextPage}
          hasPreviousPage={result.hasPreviousPage}
          onGoToPreviousPage={result.goToPreviousPage}
          onGoToNextPage={result.goToNextPage}
        />
      </PageContent>
    </LoadingSynchroniser>
  );
});

const FilterContainer = styled(View)`
  padding: 0px 20px 16px 20px;
  z-index: 10;
  flex-direction: row;
`;

const centerized: ViewStyle = {
  alignSelf: "center",
  width: "100%"
};

// we use the rounded/shadow border to fill around the frozen headers and filters. This needs to be adjusted relative to the height of elements "inside" the white box
const gridContentContainerStyle: ViewStyle = {
  margin: 0,
  marginTop: -120,
  paddingTop: 120,
  ...centerized
};
