import React, { useCallback } from "react";
import { ActionDetailsFragment } from "local/graphql";
import { useToastContext } from "@ailo/ui";
import { ActionForm } from "../ActionForm";
import { useUpdateActionAnalytics } from "./useUpdateActionAnalytics";
import { useUpdateAction } from "./useUpdateAction";
import { useGetAssignees } from "../../useGetAssignees";
import { useCurrentAgencyOrg } from "local/common";
import { useSynchronisedLoad } from "@ailo/primitives";
import { ActionFormData } from "../ActionFormData";
import { AiloRN, services } from "@ailo/ailorn";

interface Props {
  action: ActionDetailsFragment;
  projectId: string;
  onFormSubmitting: (isSubmitting: boolean) => void;
  onDismiss: () => void;
  setSubmitError: (hasError: boolean) => void;
}

export function UpdateActionForm({
  action,
  projectId,
  onFormSubmitting,
  onDismiss,
  setSubmitError
}: Props): React.ReactElement {
  const { id: organisationId } = useCurrentAgencyOrg();
  const { assignees, loading: assigneesLoading } = useGetAssignees({
    organisationId
  });

  const loading = useSynchronisedLoad(assigneesLoading);
  const toast = useToastContext();

  const { onFormOpened, onValueUpdated, onSubmitSuccess } =
    useUpdateActionAnalytics(projectId);

  const [updateAction] = useUpdateAction({
    onSuccess: ({ action, initialValues, formValues }) => {
      toast.show({
        type: "success",
        message: "Action updated"
      });

      onDismiss();
      onSubmitSuccess(action, initialValues, formValues);
    },
    onError: () => {
      setSubmitError(true);
    }
  });

  const submit = useCallback(
    async (data: ActionFormData): Promise<void> => {
      const { name, description, assignee, dueDate, type } = data;

      if (!assignee) {
        throw new Error("Assignee cannot be empty");
      }

      await updateAction(
        {
          projectId,
          id: action.id,
          name,
          description,
          assignee: AiloRN.of(services.AuthZ.legalEntity, assignee.id),
          dueDate,
          type
        },
        getFormInitialValues(action),
        data
      );
    },
    [updateAction, projectId, action]
  );

  return (
    <ActionForm
      type={"update"}
      onSubmit={submit}
      onCancel={onDismiss}
      loading={loading}
      assignees={assignees}
      style={{
        maxWidth: 680,
        width: "100%",
        flex: 1,
        alignSelf: "flex-end"
      }}
      initialValue={getFormInitialValues(action)}
      onFocus={onFormOpened}
      onValueUpdated={onValueUpdated}
      onFormSubmitting={onFormSubmitting}
    />
  );
}

function getFormInitialValues(action: ActionDetailsFragment): ActionFormData {
  return {
    assignee: action.assignee,
    type: action.type,
    name: action.name,
    description: action.description,
    dueDate: action.dueDate
  };
}
