import React, { ReactElement, useCallback } from "react";
import { useForm } from "react-hook-form";
import { StyleProp, ViewStyle } from "react-native";
import { ManagementDetailsStep } from "./AddOrEditManagementFormSteps";
import { MultiStepForm } from "local/common";
import { ManagementFragment } from "local/graphql";
import { NewManagementFormData } from "./NewManagementFormData";
import { useUpdateIngoingManagement } from "./useUpdateIngoingManagement";

export interface EditManagementFormData
  extends Pick<
    NewManagementFormData,
    "agreementStartDate" | "agreementEndDate"
  > {}

interface Props {
  managementId: string;
  defaultValues: EditManagementFormData;
  onSuccess?(management: ManagementFragment): void;
  onError?(): void;
  onCancel?(): void;
  style?: StyleProp<ViewStyle>;
}

export function EditManagementFormWithData({
  managementId,
  defaultValues,
  onSuccess,
  onError,
  onCancel,
  style
}: Props): ReactElement {
  const form = useForm<EditManagementFormData>({
    defaultValues,
    mode: "onChange"
  });
  const { reset, formState, handleSubmit } = form;
  const { isSubmitting, isDirty } = formState;

  const submit = handleSubmit(
    useSubmit({
      managementId,
      onCompleted: async (management: ManagementFragment) => {
        await onSuccess?.(management);
        reset();
      },
      onError
    })
  );

  const cancel = useCallback(() => {
    reset();
    onCancel?.();
  }, [onCancel, reset]);

  const renderStepManagementDetails = useCallback(
    (visible: boolean): React.ReactElement => (
      <ManagementDetailsStep
        key={"New-Management-Form-Step-ManagementDetails"}
        display={visible}
      />
    ),
    []
  );

  const steps = [
    {
      stepId: "ManagementDetails",
      render: renderStepManagementDetails
    }
  ];

  return (
    <MultiStepForm
      style={style}
      title={"Edit Management"}
      submitButtonText={"Update Management"}
      stepValue={1}
      onChange={() => submit()}
      onCancel={cancel}
      isDirty={isDirty}
      isSubmitting={isSubmitting}
      steps={steps.map((step) => step.render)}
    />
  );
}

function useSubmit({
  managementId,
  onCompleted,
  onError
}: {
  managementId: string;
  onCompleted?: (management: ManagementFragment) => void;
  onError?: () => void;
}): (data: EditManagementFormData) => Promise<void> {
  const [editManagement] = useUpdateIngoingManagement({
    onCompleted,
    onError
  });

  return useCallback(
    async (data: EditManagementFormData) => {
      const { agreementStartDate } = data;
      if (agreementStartDate == null) {
        throw new TypeError(
          "Agreement start date of a new management cannot be null or undefined"
        );
      }

      return editManagement({
        managementId,
        formData: { ...data, agreementStartDate }
      });
    },
    [editManagement, managementId]
  );
}
