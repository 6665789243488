import { AiloRN, services } from "@ailo/ailorn";
import { Colors, opacify } from "@ailo/primitives";
import { ScreenComponent, useAnalytics, useOnFocus } from "@ailo/services";
import { ContentWithSidebar } from "@ailo/ui";
import {
  PageCenteredColumn,
  PageContent,
  Screens,
  useRoute
} from "local/common";
import { useNavigateToRentAdjustments } from "local/tabs";
import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import {
  LedgerSidebarContent,
  LegalEntitySidebarContent,
  ProjectsListSidebar,
  PropertyContent,
  TenancySidebarContent
} from "./components";
import { ManagementSidebarContent } from "./components/ManagementSidebarContent/ManagementSidebarContent";
import {
  PropertyContextProvider,
  PropertyScreenSidebarContextProvider
} from "./context";
import { getSidebarContent } from "./getSidebarContent";
import { SidebarForm } from "./SidebarForm";
import { SidebarInfo } from "./SidebarInfo";

export const PropertyScreen: FC = () => {
  const analytics = useAnalytics();
  const navigateToRentAdjustments = useNavigateToRentAdjustments();

  const managementId = useRoute<Screens.Property>().params?.managementId;

  const currentMgmtId = useRef("");

  const [sidebarInfo, setSidebarInfoState] = useState<SidebarInfo>();
  const [sidebarForm, setSidebarFormState] = useState<SidebarForm>();

  const screenRef = useRef<ScrollView>(null);

  const [expenseListSidebarActive, setExpenseListSidebarActive] =
    useState(false);

  const clearSidebarForm = useCallback(() => {
    const analyticsMessage =
      sidebarForm?.type === "vacate" ? "Closed End of Tenancy" : "";
    analyticsMessage && analytics.track(analyticsMessage);
    setSidebarFormState(undefined);
    setExpenseListSidebarActive(false);
  }, [setSidebarFormState, sidebarForm, analytics]);

  const clearSidebarInfo = useCallback(() => {
    setSidebarInfoState(undefined);
  }, [setSidebarInfoState]);

  const setSidebarForm = useCallback(
    (form?: SidebarForm) => {
      clearSidebarInfo();
      setSidebarFormState(form);
    },
    [clearSidebarInfo]
  );

  const setSidebarInfo = useCallback(
    (info?: SidebarInfo) => {
      clearSidebarForm();
      setSidebarInfoState(info);
    },
    [clearSidebarForm]
  );

  useOnFocus(() => {
    if (currentMgmtId.current === managementId) return;

    currentMgmtId.current = managementId || "";
    screenRef.current?.scrollTo({ animated: false });

    if (sidebarForm) clearSidebarForm();
    if (sidebarInfo) clearSidebarInfo();
  });

  const onLedgerDetailEntryPress = useCallback(
    (liabilityEntryId: string): void =>
      setSidebarForm({
        id: liabilityEntryId,
        type: "liabilityEntryView"
      }),
    [setSidebarForm]
  );

  const onMoneyManagementSettingsPress = useCallback((): void => {
    if (managementId) {
      setSidebarForm({
        id: managementId,
        type: "moneyManagementSettings"
      });
    }
  }, [managementId, setSidebarForm]);

  const onOwnerOrTenantPress = (
    id: string,
    entityType: "Person" | "Company"
  ): void => {
    clearSidebarForm();
    setSidebarInfo({
      type: "legalEntity",
      id,
      entityType
    });
  };

  const sidebarInfoComponent = useMemo((): React.ReactElement => {
    if (!sidebarInfo)
      return managementId ? (
        <LedgerSidebarContent
          managementId={managementId}
          onMoneyManagementSettingsPress={onMoneyManagementSettingsPress}
          onLedgerDetailEntryPress={onLedgerDetailEntryPress}
        />
      ) : (
        <></>
      );

    if (sidebarInfo.type === "tenancy") {
      return (
        <TenancySidebarContent
          onDismiss={clearSidebarInfo}
          tenancyDetails={sidebarInfo}
        />
      );
    }

    if (sidebarInfo.type === "management") {
      return (
        <ManagementSidebarContent
          onDismiss={clearSidebarInfo}
          managementDetails={sidebarInfo}
        />
      );
    }

    if (sidebarInfo.type === "legalEntity") {
      return (
        <LegalEntitySidebarContent
          onDismiss={clearSidebarInfo}
          legalEntityDetails={sidebarInfo}
        />
      );
    }

    if (sidebarInfo.type === "projectList" && managementId) {
      return (
        <ProjectsListSidebar
          managementId={managementId}
          onClose={clearSidebarInfo}
        />
      );
    }

    return managementId ? (
      <LedgerSidebarContent
        managementId={managementId}
        onMoneyManagementSettingsPress={onMoneyManagementSettingsPress}
        onLedgerDetailEntryPress={onLedgerDetailEntryPress}
      />
    ) : (
      <></>
    );
  }, [
    sidebarInfo,
    managementId,
    onMoneyManagementSettingsPress,
    onLedgerDetailEntryPress,
    clearSidebarInfo
  ]);

  const viewOnlySidebarTypes: Array<SidebarForm["type"]> = ["expenseView"];

  const noBackdropSidebarTypes: Array<SidebarForm["type"]> = [
    "liabilityEntryView",
    "moneyManagementSettings",
    "expensesList",
    "viewBondClaim",
    "editBondReference",
    "managementFeeBlueprints",
    "viewKeys",
    "projectsList"
  ];

  if (!managementId) {
    return null;
  }

  return (
    <PropertyContextProvider managementId={managementId}>
      <PropertyScreenSidebarContextProvider setSidebarForm={setSidebarForm}>
        <ContentWithSidebar
          key={managementId}
          open={!!sidebarForm}
          dismissOnBackdropPress={
            sidebarForm?.type &&
            viewOnlySidebarTypes.includes(sidebarForm?.type)
          }
          hasBackdrop={
            sidebarForm?.type &&
            !noBackdropSidebarTypes.includes(sidebarForm?.type)
          }
          sidebarContent={getSidebarContent(
            managementId,
            clearSidebarForm,
            setSidebarFormState,
            sidebarForm,
            expenseListSidebarActive,
            onOwnerOrTenantPress
          )}
          onDismiss={clearSidebarForm}
        >
          <ScreenContainer
            analyticsTrackPayload={{
              managementId: AiloRN.of(
                services.PropertyManagement.management,
                managementId
              ).toString()
            }}
          >
            <PageContent scrollViewRef={screenRef}>
              <PageCenteredColumn style={{ maxWidth: 560 }}>
                <PropertyContent
                  managementId={managementId}
                  onTenancyDetailsPress={(id, title): void =>
                    setSidebarInfo({ type: "tenancy", id, title })
                  }
                  onManagementDetailsPress={(id): void =>
                    setSidebarInfo({
                      type: "management",
                      id
                    })
                  }
                  onVacatePress={(id): void => {
                    analytics.track("Clicked End Tenancy");
                    setSidebarForm({ id, type: "vacate" });
                  }}
                  onEditEndTenancyPress={(id): void =>
                    setSidebarForm({ id, type: "editEndTenancy" })
                  }
                  onOwnerOrTenantPress={onOwnerOrTenantPress}
                  onExpensePress={(ailorn): void =>
                    setSidebarForm({
                      id: ailorn.toString(),
                      type: "expenseView"
                    })
                  }
                  projectLink={({ id }: { id: string }) => ({
                    to: `/projects/${id}`
                  })}
                  onRentAdjustmentsPress={(tenancyId): void => {
                    navigateToRentAdjustments({ managementId, tenancyId });
                  }}
                  onViewMoreExpensesPress={({ tabId, tenancyId }): void => {
                    setSidebarForm({
                      id: "",
                      type: "expensesList",
                      initialSelectedTab: tabId,
                      initialTenancyId: tenancyId
                    });
                    setExpenseListSidebarActive(true);
                  }}
                  onViewMoreProjectPress={(): void => {
                    setSidebarInfo({
                      id: "",
                      type: "projectList"
                    });
                  }}
                  onAddBondDetailsPress={(tenancyId: string): void => {
                    setSidebarForm({ id: tenancyId, type: "addBondDetails" });
                  }}
                  onEditBondDetailsPress={(tenancyId: string): void => {
                    setSidebarForm({ id: tenancyId, type: "editBondDetails" });
                  }}
                  onViewBondClaimPress={(bondId: string): void => {
                    setSidebarForm({ id: bondId, type: "viewBondClaim" });
                  }}
                  onEditBondReferencePress={(tenancyId: string): void => {
                    setSidebarForm({
                      id: tenancyId,
                      type: "editBondReference"
                    });
                  }}
                  onViewManagementFeeBlueprintsPress={(
                    managementId: string
                  ): void =>
                    setSidebarForm({
                      id: managementId,
                      type: "managementFeeBlueprints"
                    })
                  }
                  onViewKeysPress={(): void =>
                    setSidebarForm({
                      id: managementId,
                      type: "viewKeys"
                    })
                  }
                />
              </PageCenteredColumn>
            </PageContent>
            <Container>{sidebarInfoComponent}</Container>
          </ScreenContainer>
        </ContentWithSidebar>
      </PropertyScreenSidebarContextProvider>
    </PropertyContextProvider>
  );
};

const ScreenContainer = styled(ScreenComponent)`
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
`;

const Container = styled(View)`
  max-width: 480px;
  flex: 1;
  background-color: ${Colors.WHITE};
  box-shadow: 0 1px 4px ${opacify(Colors.SPACE_BLACK, 0.2)};
`;
