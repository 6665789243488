import React from "react";
import { Colors, Text } from "@ailo/primitives";
import { TeamDropdownMenu } from "./TeamDropdownMenu";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";

const StyledItem = styled(View)`
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

interface Props {
  allTeams: { id: string; name: string; myTeam: boolean }[];
  assignedTeamId?: string;
  onTeamChange?: (teamDetails: { teamId: string; teamName: string }) => void;
  style?: StyleProp<ViewStyle>;
}

export function TeamDropdownRow({
  allTeams,
  assignedTeamId,
  onTeamChange,
  style
}: Props): React.ReactElement {
  return (
    <StyledItem style={style}>
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{"Team"}</Text.BodyS>
      <TeamDropdownMenu
        allTeams={allTeams}
        assignedTeamId={assignedTeamId}
        onTeamChange={onTeamChange}
      />
    </StyledItem>
  );
}

TeamDropdownRow.Error = function Error({
  onReload,
  style
}: {
  onReload?: () => void;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <StyledItem style={style}>
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{"Team"}</Text.BodyS>
      <TeamDropdownMenu.Error onReload={onReload} />
    </StyledItem>
  );
};

TeamDropdownRow.Loading = function Loading({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <StyledItem style={style}>
      <Text.BodyS.Loading width={120} />
      <TeamDropdownMenu.Loading />
    </StyledItem>
  );
};
