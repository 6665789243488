import React, { FC, useCallback, useState } from "react";
import styled from "styled-components/native";
import { Colors, opacify, Text } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { SwitcherHeaderOption } from "../SwitcherHeader";

interface Props {
  option: SwitcherHeaderOption | string;
  onPress?: () => void;
  selected: boolean;
}

const SwitcherOption: FC<Props> = ({ option, onPress, selected }) => {
  const [hovered, setHovered] = useState(false);

  const onHoverStart = useCallback(() => {
    setHovered(true);
  }, [setHovered]);

  const onHoverEnd = useCallback(() => {
    setHovered(false);
  }, [setHovered]);

  const onPressCallback = useCallback(() => {
    onPress?.();
  }, [onPress]);

  return (
    <StyledButton
      onPress={onPressCallback}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      active={selected}
      hovered={hovered}
      contentStyle={{ alignItems: "center" }}
    >
      <Text.BodyM
        weight={"medium"}
        style={{
          color: selected ? Colors.AILO_RED : Colors.TEXT.DARK.SECONDARY
        }}
      >
        {typeof option === "string" ? option : option.label}
      </Text.BodyM>
      {typeof option !== "string" && option.badge ? (
        <option.badge active={selected} params={option.params} />
      ) : null}
    </StyledButton>
  );
};

interface ButtonProps {
  active: boolean;
  hovered: boolean;
}

const StyledButton = styled(Button.Text)<ButtonProps>`
  border-radius: 0;
  border-width: 0;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 7px;
  margin-right: 20px;
  border-bottom-width: 3px;
  border-color: ${({ active, hovered }: ButtonProps): string =>
    active
      ? Colors.AILO_RED
      : hovered
      ? opacify(Colors.SPACE_BLACK, 0.1)
      : "transparent"};
  align-items: center;
`;

export { SwitcherOption };
