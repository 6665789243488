import { useEnvironment } from "@ailo/services";
import {
  BaseModalProps,
  Separator,
  TextInputFormField,
  validateMaxLength
} from "@ailo/ui";
import { FormFooter } from "local/common";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { StyleSheet, View } from "react-native";

export type UpsertKeyFormData = {
  code: string;
  description?: string | null;
};

type UpsertKeyFormContentProps = {
  initialValues?: UpsertKeyFormData;
  onSubmit: (formData: UpsertKeyFormData) => void;
  onDismiss: () => void;
  isSubmitting: boolean;
  submitText: string;
};

const styles = StyleSheet.create({
  formFooter: {
    marginTop: 16,
    paddingBottom: 20
  },
  keyNumberField: { marginBottom: 24 },
  descriptionField: { marginBottom: 60 }
});

export function PropertyKeysFormContent({
  initialValues,
  isSubmitting,
  onSubmit,
  onDismiss,
  submitText
}: React.PropsWithChildren<UpsertKeyFormContentProps>): React.ReactElement<BaseModalProps> {
  const { PROPERTY_KEY_CODE_MAX_LENGTH, PROPERTY_KEY_DESCRIPTION_MAX_LENGTH } =
    useEnvironment();

  const { control, handleSubmit, errors, reset } = useForm<UpsertKeyFormData>({
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      code: initialValues?.code || "",
      description: initialValues?.description || ""
    }
  });

  const onSubmitForm = handleSubmit(async (data) => {
    await onSubmit(data);
    onDismiss();
    reset();
  });

  return (
    <>
      <Controller
        control={control}
        name={"code"}
        render={({ value, onChange, onBlur }) => (
          <TextInputFormField
            {...{ value, onChange, onBlur }}
            label={"Key number"}
            style={styles.keyNumberField}
            error={errors.code?.message}
            testID={"PropertyKeyCode"}
            softCharacterLimit={{
              limit: PROPERTY_KEY_CODE_MAX_LENGTH,
              enforcementLevel: "error"
            }}
          />
        )}
        rules={{
          validate: {
            maxLength: validateMaxLength(PROPERTY_KEY_CODE_MAX_LENGTH)
          },
          required: "Please provide a key number"
        }}
        accessibilityLabel={`property-key-number`}
      />

      <Controller
        control={control}
        name={"description"}
        render={({ value, onChange, onBlur }) => (
          <TextInputFormField
            {...{ value, onChange, onBlur }}
            label={"Description (optional)"}
            style={styles.descriptionField}
            error={errors.description?.message}
            testID={"PropertyKeyDescription"}
            softCharacterLimit={{
              limit: PROPERTY_KEY_DESCRIPTION_MAX_LENGTH,
              enforcementLevel: "error"
            }}
          />
        )}
        rules={{
          validate: {
            maxLength: validateMaxLength(PROPERTY_KEY_DESCRIPTION_MAX_LENGTH)
          }
        }}
        accessibilityLabel={`property-key-description`}
      />
      <FormSeparator />
      <FormFooter
        type={"modal"}
        onSubmit={onSubmitForm}
        isSubmitting={isSubmitting}
        submitVisible={true}
        onCancel={() => {
          onDismiss();
          reset();
        }}
        submitText={submitText}
        canGoBack={false}
        style={styles.formFooter}
        onGoBack={onDismiss}
        submitButtonDisabled={isSubmitting}
        cancelButtonDisabled={isSubmitting}
      />
    </>
  );
}

PropertyKeysFormContent.Loading = PropertyKeysFormContentLoading;

export function PropertyKeysFormContentLoading({
  submitText,
  onCancel
}: {
  submitText: string;
  onCancel: () => void;
}): React.ReactElement {
  return (
    <>
      <TextInputFormField.Loading style={styles.keyNumberField} />
      <View style={{ paddingTop: 20 }} />
      <TextInputFormField.Loading style={styles.descriptionField} />
      <FormSeparator />

      <FormFooter
        type={"modal"}
        isSubmitting={false}
        submitText={submitText}
        submitVisible={true}
        onCancel={onCancel}
        onSubmit={() => {}}
        submitButtonDisabled={true}
        canGoBack={false}
        onGoBack={() => {}}
        style={styles.formFooter}
      />
    </>
  );
}

function FormSeparator(): React.ReactElement {
  return <Separator style={{ marginHorizontal: -20 }} />;
}
