import { Tooltip } from "@ailo/ui";
import React from "react";

export function OptionalTooltip({
  children,
  tooltipContent
}: {
  children: React.ReactElement;
  tooltipContent?: string;
}): React.ReactElement {
  return tooltipContent ? (
    <Tooltip tooltipContent={tooltipContent}>{children}</Tooltip>
  ) : (
    children
  );
}
