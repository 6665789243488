import { FilesContextProvider, FileKind } from "@ailo/domains";
import { useMemoRef } from "@ailo/primitives";
import { ContentWithSidebar } from "@ailo/ui";
import { useIsFocused } from "@react-navigation/native";
import { ArchiveBillSidebarContent } from "local/domain/bill";
import {
  RouteProp,
  AppDrawerNavigatorParamList,
  Screens,
  useNavigation,
  useRoute
} from "local/common";
import React, { useCallback, useRef } from "react";
import { AddBillSidebarContent } from "./AddBillSidebarContent";
import { BillListRefAttributes, BillListScreen } from "./BillListScreen";
import { ExpenseDetailView } from "local/domain/expense";
import { AiloRN, services } from "@ailo/ailorn";

function useSidebarContent({
  route,
  onBillCreate,
  onBillArchive
}: {
  route: RouteProp<AppDrawerNavigatorParamList, Screens.BillsTab>;
  onBillCreate?(): void;
  onBillArchive?(): void;
}): React.ReactElement | null {
  const navigation = useNavigation();
  const { tab, billId, billSubview } = route.params ?? {};

  const onClose = useCallback(() => {
    navigation.navigate(Screens.BillsTab, {
      tab
    });
  }, [navigation, tab]);

  const onArchiveBillPress = useCallback(() => {
    navigation.navigate(Screens.BillsTab, {
      tab,
      billId,
      billSubview: "cancel"
    });
  }, [navigation, tab, billId]);

  const goBackToBill = useCallback(
    (billId: string) => {
      navigation.navigate(Screens.BillsTab, {
        billId,
        tab
      });
    },
    [navigation, tab]
  );

  if (billId === "new") {
    return <AddBillSidebarContent onBillCreate={onBillCreate} />;
  }

  if (billId) {
    if (billSubview === "cancel") {
      return (
        <ArchiveBillSidebarContent
          billId={billId}
          onArchiveSuccess={onBillArchive}
          onGoBack={goBackToBill}
          onClose={onClose}
        />
      );
    }
    return (
      <ExpenseDetailView
        ailorn={AiloRN.of(services.Bill.bill, billId)}
        onCancelExpensePress={onArchiveBillPress}
        onClose={onClose}
      />
    );
  }

  return null;
}

export function BillsTabScreen(): React.ReactElement {
  const navigation = useNavigation();
  const route = useRoute<Screens.BillsTab>();
  const isFocusedRef = useMemoRef(useIsFocused());

  const billListRef = useRef<BillListRefAttributes>(null);
  const refetchBills = useCallback(() => {
    if (isFocusedRef.current) {
      billListRef.current?.refetch();
      billListRef.current?.resetPage();
    }
  }, [isFocusedRef]);

  const refetchBillsWithDelay = useCallback(() => {
    const refetchBillList = (): void => {
      if (
        isFocusedRef.current &&
        [undefined, "upcoming", "overdue"].includes(route.params?.tab)
      ) {
        billListRef.current?.refetch();
      }
    };

    // Bill will be visible only after Bill Service -> Ledger Service -> Bill Service
    // events are handled. We need to wait. And it needs to work with 100% guarantee,
    // as e2e tests rely on it. Thus, let's refetch it after 4s (should work in ~90% of cases),
    // and again after 12s (should be ok in the other cases).
    setTimeout(refetchBillList, 4000);
    setTimeout(refetchBillList, 12000);
  }, [isFocusedRef, route.params]);

  const sidebarContent = useSidebarContent({
    route,
    onBillCreate: refetchBillsWithDelay,
    onBillArchive: refetchBills
  });

  return (
    <FilesContextProvider kind={FileKind.BillBillAttachment}>
      <ContentWithSidebar
        open={!!sidebarContent}
        sidebarContent={sidebarContent}
        onDismiss={(): void =>
          navigation.navigate(Screens.BillsTab, {
            tab: route.params?.tab
          })
        }
      >
        <BillListScreen billListRef={billListRef} />
      </ContentWithSidebar>
    </FilesContextProvider>
  );
}
