import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { SFC, Colors, Text } from "@ailo/primitives";

interface Props {
  icon: React.FC;
  title: string;
}

const SectionHeader: SFC<Props> = ({ style, icon: Icon, title }) => {
  return (
    <Container style={style}>
      <Icon />
      <Text.BodyM
        weight={"medium"}
        color={Colors.TEXT.DARK.PRIMARY}
        style={{ marginLeft: 6 }}
      >
        {title}
      </Text.BodyM>
    </Container>
  );
};

const Container = styled(View)`
  flex-direction: row;
`;

export { SectionHeader };
