import { DateTimeWithTimeZone, Alert } from "@ailo/ui";
import React, { ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";

interface Props {
  style?: StyleProp<ViewStyle>;
  agreementEndDate: string;
}

export function AllowCurrentLeaseToBePeriodicInfo({
  style,
  agreementEndDate
}: Props): ReactElement {
  const date = DateTimeWithTimeZone.fromLocalDate(agreementEndDate);
  const formattedDate = date.format("D MMM YYYY");
  return (
    <Alert
      type={"info"}
      message={`Lease ${
        date.isAfter(DateTimeWithTimeZone.now()) ? "will" : "has"
      } automatically become periodic on the agreement end date (${formattedDate})`}
      style={style}
    />
  );
}
