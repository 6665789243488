import { useHasFeature } from "@ailo/domains";
import { PlusIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { PlatformFeatureId } from "local/graphql";
import React from "react";
import { useNavigateToAddExpense } from "./useNavigateToAddExpense";

export function AddExpenseButton({
  managementId,
  variant = "card",
  onPress
}: {
  managementId: string;
  variant?: "card" | "sidebar";
  onPress?: () => void;
}): React.ReactElement | null {
  const canManageExpenses = useHasFeature(PlatformFeatureId.ManageExpenseForm);
  const navigateToAddExpense = useNavigateToAddExpense();

  if (!canManageExpenses) return null;

  return variant === "card" ? (
    <Button.Text
      leftIcon={PlusIcon}
      style={{ flexDirection: "row", borderWidth: 0 }}
      onPress={onPress ?? (() => navigateToAddExpense({ managementId }))}
    >
      {"Add"}
    </Button.Text>
  ) : (
    <Button.Secondary
      type={"small"}
      style={{ height: 32 }}
      onPress={onPress ?? (() => navigateToAddExpense({ managementId }))}
    >
      {"Add"}
    </Button.Secondary>
  );
}
