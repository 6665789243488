import React, { ReactElement } from "react";
import { ListItem } from "@ailo/ui";

export function ActionRequiredListItem({
  header,
  subHeader
}: {
  header: string;
  subHeader: string;
}): ReactElement {
  return (
    <ListItem
      header={header}
      subHeader={subHeader}
      style={{
        height: 72,
        borderType: "around",
        borderRadius: 8,
        alignItems: "center"
      }}
    />
  );
}
