import React from "react";
import { Colors, Text, LeftChevronIcon, Link } from "@ailo/primitives";
import { ViewStyle } from "react-native";
import styled from "styled-components/native";

interface Props {
  children?: React.ReactNode;
  style?: ViewStyle;
  onPress: () => void;
}

export function BackButton({
  style,
  children,
  onPress
}: Props): React.ReactElement {
  return (
    <StyledLink onPress={onPress} style={style}>
      <LeftChevronIcon />
      <PageTitle
        color={Colors.AILO_RED}
        weight={"medium"}
        ellipsizeMode={"tail"}
        numberOfLines={1}
      >
        {children}
      </PageTitle>
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  text-decoration-line: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 0;
  flex: 1;
`;

const PageTitle = styled(Text.BodyM)`
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 12px;
`;
