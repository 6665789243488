import React, { ReactElement } from "react";
import {
  PayableBalanceCard,
  PayableBalanceCardLoading
} from "./PayableBalanceCard";
import { useLedgerBalancesData } from "../useLedgerBalancesData";

interface Props {
  asOfDate: string;
  isReconciling: boolean;
}

export function LedgerBalancesComponent({
  asOfDate,
  isReconciling
}: Props): ReactElement {
  const data = useLedgerBalancesData({ asOfDate });

  if (data.loading) {
    return <PayableBalanceCardLoading />;
  }

  return (
    <PayableBalanceCard
      asOf={asOfDate}
      isReconciling={isReconciling}
      items={data.payableBalances}
    />
  );
}

export function LedgerBalancesComponentLoading(): ReactElement {
  return <PayableBalanceCardLoading />;
}
