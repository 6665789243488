import React, { ReactElement } from "react";
import { Colors, Text } from "@ailo/primitives";
import { BondAuthorityCard } from "./BondAuthorityCard";
import {
  BankAccountDetails,
  BankAccountDetailsBox,
  BondAuthority
} from "local/domain/bond";
import { ClaimBondFormConfiguration, usePropertyIsInStates } from "local/tabs";

import { AustralianState } from "local/common";

type BondAuthorityProps = {
  bondAuthority: BondAuthority;
  bondAccount: BankAccountDetails;
  formConfiguration: ClaimBondFormConfiguration;
  reference?: string;
  state?: string;
  enableSubmitButton: () => void;
};

export function BondAuthoritySection({
  bondAuthority,
  bondAccount,
  formConfiguration,
  enableSubmitButton,
  reference
}: BondAuthorityProps): ReactElement {
  const propertyIsInWaOrSa = usePropertyIsInStates([
    AustralianState.WA,
    AustralianState.SA
  ]);

  if (propertyIsInWaOrSa) {
    return (
      <>
        <Text.BodyL weight={"medium"} style={{ marginBottom: 20 }}>
          {"What to do next"}
        </Text.BodyL>
        <Text.BodyM weight={"medium"} style={{ marginBottom: 12 }}>
          {"Step 1"}
        </Text.BodyM>
        <Text.BodyM
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ marginBottom: 16 }}
        >
          {formConfiguration.confirmClaimStep.bondAuthorityActionDescription}
        </Text.BodyM>
        <BondAuthorityCard
          bondAuthority={bondAuthority}
          enableSubmitButton={enableSubmitButton}
        />
        <Text.BodyM weight={"medium"} style={{ marginTop: 32 }}>
          {"Step 2"}
        </Text.BodyM>
        <Text.BodyM
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ marginTop: 12, marginBottom: 12 }}
        >
          {"The funds "}
          <Text.BodyM weight={"medium"}>{"‘requested by agency’ "}</Text.BodyM>
          {
            "will be transferred to your trust account. Once funds have been received to the trust account, "
          }
          <Text.BodyM weight={"medium"}>
            {"please transfer to the Ailo bond account: "}
          </Text.BodyM>
          {}
        </Text.BodyM>
        <BankAccountDetailsBox
          headerContent={
            <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
              {"Requested by agency"}
            </Text.BodyS>
          }
          reference={reference}
          bankAccount={bondAccount}
          style={{ width: 327, marginTop: 12, marginBottom: 32 }}
        />
      </>
    );
  }

  return (
    <>
      <Text.BodyL weight={"medium"} style={{ marginBottom: 20 }}>
        {"What to do next"}
      </Text.BodyL>
      <Text.BodyM
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginBottom: 16 }}
      >
        {formConfiguration.confirmClaimStep.bondAuthorityActionDescription}
      </Text.BodyM>
      <BondAuthorityCard
        bondAuthority={bondAuthority}
        enableSubmitButton={enableSubmitButton}
      />
    </>
  );
}
