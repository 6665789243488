import { Colors, Text } from "@ailo/primitives";
import { Alert, Button, Modal, Spinner } from "@ailo/ui";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { useEffect } from "reactn";
import styled from "styled-components/native";
import { UseTimerOutput } from "../useTimer";

interface Props {
  toggleModal: () => void;
  resetModal: () => void;
  onDownload: () => void;
  loading: boolean;
  timerObject: UseTimerOutput;
}

export function DownloadModalWithTimeout({
  toggleModal,
  resetModal,
  onDownload,
  loading,

  timerObject
}: Props): ReactElement {
  const { timer, resetTimer, isTimeout, downloadTimeOutInMs } = timerObject;

  useEffect(() => {
    if (timer && !loading) {
      onDownload();
      resetTimer();
      toggleModal();
    }
  }, [timer, resetTimer, toggleModal, loading, onDownload]);

  if (isTimeout) {
    return (
      <Modal visible={true}>
        <ModalElement style={{ height: "auto" }}>
          <View style={{ flexDirection: "row" }}>
            <Text.DisplayS>{"An error has occurred"}</Text.DisplayS>
          </View>
          <Text.BodyM style={{ marginBottom: 32 }}>
            <Alert type={"warning"} style={{ marginTop: 20, width: "100%" }}>
              {"There was a problem generating the ledger."}
              <br />
              {"Please try again."}
            </Alert>
          </Text.BodyM>
          <TimeoutActionsContainer>
            <Button.Secondary style={{ maxWidth: 80 }} onPress={toggleModal}>
              {"Close"}
            </Button.Secondary>
            <Button.Primary
              style={{ maxWidth: 80, marginLeft: "auto" }}
              onPress={resetModal}
            >
              {"Retry"}
            </Button.Primary>
          </TimeoutActionsContainer>
        </ModalElement>
      </Modal>
    );
  }

  return (
    <Modal visible={true}>
      <ModalElement>
        <View style={{ flexDirection: "row" }}>
          <Spinner size={24} style={{ marginRight: 12, marginBottom: 20 }} />
          <Text.DisplayS>{"Please Wait..."}</Text.DisplayS>
        </View>
        <Text.BodyM style={{ marginBottom: 32 }}>
          {"It may take up to "}
          {(downloadTimeOutInMs / 1000).toFixed(0)}
          {
            "  seconds to generate. Please don’t close this window. The file will automatically download when ready."
          }
        </Text.BodyM>
        <Button.Secondary style={{ maxWidth: 80 }} onPress={toggleModal}>
          {"Cancel"}
        </Button.Secondary>
      </ModalElement>
    </Modal>
  );
}

const ModalElement = styled(View)`
  background-color: ${Colors.WHITE};
  width: 480px;
  height: 200px;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  padding: 20px;
`;

const TimeoutActionsContainer = styled(View)`
  display: flex;
  flex-direction: row;
`;
