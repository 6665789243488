import React from "react";
import { Badge, LocalDate } from "@ailo/ui";
import {
  getRentAdjustmentStatus,
  RentAdjustmentStatus
} from "./getRentAdjustmentStatus";

interface Props {
  effectiveAt: LocalDate;
  reversed: boolean;
}

export function RentAdjustmentStatusBadge({
  effectiveAt,
  reversed
}: Props): React.ReactElement | null {
  const status = getRentAdjustmentStatus({ effectiveAt, reversed });

  switch (status) {
    case RentAdjustmentStatus.REVERSED:
      return (
        <Badge type={"critical"} testID={"RentAdjustmentStatusBadge"}>
          {status}
        </Badge>
      );
    case RentAdjustmentStatus.PENDING:
      return (
        <Badge type={"attention"} testID={"RentAdjustmentStatusBadge"}>
          {"Pending"}
        </Badge>
      );
    default:
      return null;
  }
}
