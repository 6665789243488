import React, { ReactElement } from "react";
import { BaseActionActivity } from "../BaseActionActivity";
import { ErrorAlertScreen, useGlobalModal } from "@ailo/ui";
import { AiloSentry } from "@ailo/services";
import { TenancyAgreementDescriptionList } from "./TenancyAgreementDescriptionList";
import { LeaseRenewalModalForm } from "local/modals/LeaseRenewalModalForm";
import { ActionType, AllowedOperations, ProjectType } from "local/graphql";
import { RefinedActionActivityProps } from "../ActionActivityProps";
import { EditSectionHeader } from "../EditSectionHeader";
import { isProjectOfTypeWithMeta } from "local/domain/project/project";

export function AssignTenancyAgreementActionActivity({
  action
}: RefinedActionActivityProps<ActionType.AssignTenancyAgreement>): ReactElement {
  const modal = useGlobalModal();
  const project = action.project;

  if (!isProjectOfTypeWithMeta(project, ProjectType.LeaseRenewal)) {
    AiloSentry.captureException(
      new Error("Project is not of Lease Renewal type")
    );
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the action information."}
      />
    );
  }

  const projectTenancy = project.meta.leaseRenewalTenancy;
  const { id: tenancyId, leaseReviewAllowedOperation } = projectTenancy;
  const tenancyAgreement = action.meta.tenancyAgreement;

  if (tenancyAgreement) {
    const { startDate, fixedTermEndDate } = tenancyAgreement;

    return (
      <BaseActionActivity>
        <EditSectionHeader
          style={{ marginBottom: 4 }}
          title={"Lease renewal"}
          onPress={() => {
            modal.show(
              <LeaseRenewalModalForm
                action={action}
                tenancyId={tenancyId}
                agreement={tenancyAgreement}
                onDismiss={modal.hide}
                onSuccess={() => {
                  modal.hide();
                }}
              />
            );
          }}
        />
        <TenancyAgreementDescriptionList
          startDate={startDate}
          fixedTermEndDate={fixedTermEndDate}
        />
      </BaseActionActivity>
    );
  }
  const isCreateLeaseRenewalAllowed =
    leaseReviewAllowedOperation === AllowedOperations.Create;

  return (
    <BaseActionActivity
      button={{
        label: "Enter lease renewal",
        onPress: () => {
          modal.show(
            <LeaseRenewalModalForm
              action={action}
              tenancyId={tenancyId}
              onDismiss={modal.hide}
              onSuccess={() => {
                modal.hide();
              }}
            />
          );
        },
        disabled: !isCreateLeaseRenewalAllowed,
        tooltipText: !isCreateLeaseRenewalAllowed
          ? "Upcoming renewal already exists"
          : undefined
      }}
    />
  );
}
