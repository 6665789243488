import React, { useState } from "react";
import {
  ErrorCard,
  Grid,
  GridHeadRow,
  GridSortOrder,
  PaginationNavigation
} from "@ailo/ui";
import { View } from "react-native";
import styled from "styled-components/native";
import { columns, loadingColumns } from "./gridConfig";
import {
  TransactionListFilters,
  useGetTransactions
} from "./useGetTransactions";
import { EmptyGridCard } from "local/tabs/trustaccounting/EmptyGridCard";
import { isPresent } from "ts-is-present";
import { LocalDate } from "@ailo/date";
import { useOnFocus } from "@ailo/services";
import { LoadingSynchroniser } from "@ailo/primitives";
import { useCurrentAgencyOrg } from "local/common";
import { TransactionsFilterBar } from "./TransactionsFilterBar";

type InnerTransactionsListProps = {
  moneyHolder: string;
};

const TransactionsListGrid: React.FC = (props) => {
  const moneyHolder =
    useCurrentAgencyOrg().legalEntities[0].defaultTrustAccount?.ailorn;
  if (!moneyHolder) {
    return (
      <Grid.Placeholder>
        <ErrorCard
          message={"There is no trust account to display"}
          onReload={() => {}}
        />
      </Grid.Placeholder>
    );
  }
  return (
    <TransactionsListGridForMoneyHolder moneyHolder={moneyHolder} {...props} />
  );
};

const TransactionsListGridForMoneyHolder: React.FC<
  InnerTransactionsListProps
> = ({ moneyHolder }) => {
  const defaultCategory = "this-month";
  const [filters, setFilters] = useState<TransactionListFilters>({
    category: defaultCategory,
    moneyHolder
  });

  const [sortOrder, setSortOrder] = useState<GridSortOrder>({
    columnKey: "auditNumber",
    direction: "DESC"
  });

  const result = useGetTransactions({
    filters,
    sortOrder
  });
  const { data, loading, error } = result;

  useOnFocus(result.refetch);
  const filterBar = (
    <TransactionsFilterBar filters={filters} setFilters={setFilters} />
  );

  const gridHeaderRow = (
    <View>
      {filterBar}
      <GridHeadRow
        columns={columns}
        sortOrder={sortOrder}
        onSortOrderChange={setSortOrder}
      />
    </View>
  );

  if (loading) {
    return (
      <LoadingSynchroniser>
        <Grid
          bodyStyle={{ marginLeft: 60, marginRight: 60 }}
          columns={loadingColumns}
          scrollView={true}
          headerOverride={gridHeaderRow}
          renderRowContainer={({ row, style, children }) => (
            <View style={[style, { opacity: row.opacity }]}>{children}</View>
          )}
          rows={[
            { key: 1, opacity: 0.75 },
            { key: 2, opacity: 0.5 },
            { key: 3, opacity: 0.25 },
            { key: 4, opacity: 0 }
          ]}
        />
      </LoadingSynchroniser>
    );
  }

  if (error) {
    return (
      <Grid.Placeholder>
        {filterBar}
        <ErrorCard
          message={"There's a problem loading transactions"}
          onReload={() => {}}
        />
      </Grid.Placeholder>
    );
  }
  if (data?.transactions?.items.length === 0) {
    return (
      <Grid.Placeholder>
        {filterBar}
        <EmptyGridCard
          primaryText={"Transactions"}
          secondaryText={"There are no transactions for this period"}
        />
      </Grid.Placeholder>
    );
  }

  return (
    <LoadingSynchroniser>
      <Grid
        rows={
          data?.transactions?.items.filter(isPresent).map((t) => ({
            id: t.id!!,
            key: t.id!!,
            type: t.type!!,
            allocations: t.allocations.filter(isPresent),
            createdAt: LocalDate.parse(t.createdAt!!)!!,
            description: t.description!!,
            referenceNumber: t.referenceNumber!!,
            auditNumber: t.auditNumber!!,
            managementId: t.management?.id || undefined,
            propertyAddress: t.management?.property?.address
          })) ?? []
        }
        scrollView={true}
        columns={columns}
        headerOverride={gridHeaderRow}
        footerCaption={
          <StyledContainer>
            <GridCell />
            <PaginationNavigation
              {...result}
              style={{ justifyContent: "center" }}
              currentPage={result.currentPage}
              pagesCount={result.pagesCount}
              hasNextPage={result.hasNextPage}
              hasPreviousPage={result.hasPreviousPage}
              onGoToPreviousPage={result.goToPreviousPage}
              onGoToNextPage={result.goToNextPage}
            />
            <GridCell />
          </StyledContainer>
        }
      />
    </LoadingSynchroniser>
  );
};

const GridCell = styled(View)`
  flex-direction: row;
`;
const StyledContainer = styled(View)`
  padding: 16px;
`;

export { TransactionsListGrid };
