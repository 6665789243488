import { Colors, opacify } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { CreateRentCreditButton } from "./CreateRentCreditButton";
import { FullPageHeader, Screens, useRoute } from "local/common";
import React, { ReactElement } from "react";
import { ScrollView, StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { RentAdjustmentsList } from "./RentAdjustmentsList";

interface Props {
  tenancyId: string;
  onClose(): void;
  style?: StyleProp<ViewStyle>;
}

const ListSectionContainer = styled(View)`
  margin-bottom: 20px;
`;

export function RentAdjustments({
  tenancyId,
  onClose,
  style
}: Props): ReactElement {
  const { managementId } = useRoute<Screens.RentAdjustments>().params!;

  return (
    <View style={style}>
      <ScrollView>
        <FullPageHeader
          header={"Rent Adjustments"}
          subheader={
            "Rent credits can be applied to grant renters periods of free rent, moving paid-to dates forward, or reimbursement for out-of-pocket expenses."
          }
          style={{ paddingHorizontal: 16, marginBottom: 24 }}
        />
        <ButtonContainer>
          <CreateRentCreditButton
            managementId={managementId}
            tenancyId={tenancyId}
          />
        </ButtonContainer>
        <ListSectionContainer>
          <RentAdjustmentsList tenancyId={tenancyId} />
        </ListSectionContainer>
      </ScrollView>
      <ActionsContainer>
        <Button.Primary
          style={{
            marginTop: 16,
            marginRight: 60,
            marginBottom: 24,
            marginLeft: "auto"
          }}
          testID={"CloseButton"}
          onPress={onClose}
        >
          {"Close"}
        </Button.Primary>
      </ActionsContainer>
    </View>
  );
}

const ActionsContainer = styled(View)`
  height: 80px;
  border-top-width: 1px;
  border-top-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;

const ButtonContainer = styled(View)`
  flex-direction: row;
  margin-left: 16px;
  margin-bottom: 38px;
`;
