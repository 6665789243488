import { Text, useIsMountedRef } from "@ailo/primitives";
import { AiloSentry } from "@ailo/services";
import { ConfirmModal, useToastContext } from "@ailo/ui";
import { Fee } from "local/domain/expense";
import React, { ReactElement, useCallback, useRef } from "react";
import {
  CancelOneOffFeeDetails,
  useCancelOneOffFee
} from "./useCancelOneOffFee";

export type CancelOneOffFeeConfirmModalData = CancelOneOffFeeDetails & {
  feeName: string;
};

interface Props {
  data?: CancelOneOffFeeConfirmModalData;
  onDismiss?: () => void;
}

export function onCancelFeePress({
  fee,
  setModalData
}: {
  fee: Fee;
  setModalData: (data: CancelOneOffFeeConfirmModalData) => void;
}): (() => void) | undefined {
  if (fee.blueprint) {
    return () =>
      fee.blueprint &&
      setModalData({
        feeId: fee.ailorn.internalId,
        feeName: fee.blueprint.name,
        amount: fee.totalAmount,
        oneWeekRentPercentage: fee.percentage,
        blueprintId: fee.blueprint.id,
        blueprintAmount: fee.blueprint.amount,
        blueprintOneWeekRentPercentage: fee.blueprint.oneWeekRentPercentage
      });
  }
}

export function CancelOneOffFeeConfirmModal({
  data,
  onDismiss
}: Props): ReactElement {
  const toast = useToastContext();
  const feeNameRef = useRef("");
  const isMountedRef = useIsMountedRef();
  const [cancelOneOffFee, submitting] = useCancelOneOffFee();

  const submit = useCallback(async () => {
    if (!data) return;

    try {
      await cancelOneOffFee({
        feeId: data.feeId,
        amount: data.amount,
        oneWeekRentPercentage: data.oneWeekRentPercentage,
        blueprintId: data.blueprintId,
        blueprintAmount: data.blueprintAmount,
        blueprintOneWeekRentPercentage: data.blueprintOneWeekRentPercentage
      });
      toast.show({
        type: "success",
        message: "Fee removed"
      });
    } catch (error) {
      AiloSentry.captureException(error);
      toast.show({
        type: "error",
        message: "Unable to remove fee. Please try again."
      });
    }
    if (isMountedRef) onDismiss?.();
  }, [data, onDismiss, cancelOneOffFee, toast, isMountedRef]);

  if (data) {
    feeNameRef.current = data.feeName;
  }

  return (
    <ConfirmModal
      visible={!!data}
      title={`Cancel ${feeNameRef.current}?`}
      onConfirm={submit}
      onCancel={onDismiss}
      destructive={true}
      confirmLabel={"Yes"}
      cancelLabel={"No"}
      loading={submitting}
    >
      <Text.BodyM weight={"book"}>
        {"Payment will no longer be required once it has been cancelled"}
      </Text.BodyM>
    </ConfirmModal>
  );
}
