import React, { ReactElement } from "react";
import { Text, Colors, opacify } from "@ailo/primitives";
import { formatProjectType, CellContainer } from "@ailo/ui";
import { ProjectListRow } from "../ProjectList";

export function ProjectCell({
  row: { name, type }
}: {
  row: ProjectListRow;
  rowIndex: number;
}): ReactElement {
  return (
    <CellContainer>
      <Text.BodyM numberOfLines={1} weight={"medium"}>
        {formatProjectType(type)}
      </Text.BodyM>

      <Text.BodyS numberOfLines={1} color={opacify(Colors.SPACE_BLACK, 0.6)}>
        {name}
      </Text.BodyS>
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <Text.BodyM.Loading width={120} />
      <Text.BodyS.Loading width={80} />
    </CellContainer>
  );
}

ProjectCell.Loading = Loading;
