import React, { ReactElement } from "react";
import { PrimaryText } from "local/tabs/properties/components/TenancyBondScreen/components/PrimaryText";

export function ClaimStepTransferFromTrust(): ReactElement {
  return (
    <PrimaryText weight={"medium"} style={{ marginTop: 68 }}>
      {"Transfer the bond from your trust account to Ailo"}
    </PrimaryText>
  );
}
