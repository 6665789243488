import React from "react";
import styled from "styled-components/native";
import { AFC, Colors, opacify } from "@ailo/primitives";
import { ScreenComponent } from "@ailo/services";

const EmptyChatScreen: AFC = () => {
  return <Container />;
};

const Container = styled(ScreenComponent)`
  flex: 1;
  border-left-style: solid;
  border-left-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  background-color: ${Colors.WHITE};
`;
export { EmptyChatScreen };
