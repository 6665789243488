import { PeopleAndPropertyIcon } from "@ailo/primitives";
import { DropdownMenuOption } from "@ailo/ui";
import React from "react";

export function getSetEndTenancyOption({
  onSelect
}: {
  onSelect: () => void;
}): DropdownMenuOption {
  return {
    label: "End tenancy",
    icon: <PeopleAndPropertyIcon />,
    onSelect
  };
}
