import { Colors, Text } from "@ailo/primitives";
import { Badge, BriefcaseAvatar, Button, PersonAvatar } from "@ailo/ui";
import React from "react";
import { View } from "react-native";
import {
  PropertyBadges,
  useHasFeature,
  useSharedPressableLinkTo
} from "@ailo/domains";
import styled from "styled-components/native";
import {
  BaseProfileCard,
  PersonVerificationIcon,
  ProfileCardContactDetails,
  ProfileCardStyle
} from "local/domain/authz";
import { GetLegalEntityDetailsQuery, PlatformFeatureId } from "local/graphql";
import { AiloRN } from "@ailo/ailorn";

interface Property {
  address: string;
  path: string;
}

interface Props {
  legalEntity: NonNullable<GetLegalEntityDetailsQuery["legalEntity"]>;
  tenantProperties?: Property[];
  investorProperties?: Property[];
  style?: ProfileCardStyle;
}

export function PropertySidebarProfileCard({
  legalEntity,
  tenantProperties: tenantProperties = [],
  investorProperties = [],
  style
}: Props): React.ReactElement {
  const hasChatFeature = useHasFeature(PlatformFeatureId.Chat);
  const displayName =
    legalEntity.__typename === "Person"
      ? `${legalEntity.firstName} ${legalEntity.lastName}`
      : legalEntity.registeredEntityName;
  const hasTenantProperties = tenantProperties.length > 0;
  const hasInvestorProperties = investorProperties.length > 0;

  const linkProps = useSharedPressableLinkTo("startNewChat", {
    contactAilorns: legalEntity.contact
      ? [
          AiloRN.from(legalEntity.contact.ailorn, {
            expected: "contact:contact"
          })
        ]
      : []
  });

  return (
    <BaseProfileCard
      isTenant={hasTenantProperties}
      isInvestor={hasInvestorProperties}
      style={style}
      colorGradientContent={
        <>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View style={{ marginTop: -20 }}>
              {legalEntity.__typename === "Person" ? (
                <PersonAvatar size={64} person={legalEntity} />
              ) : (
                <BriefcaseAvatar size={64} iconSize={28} />
              )}
            </View>
            <IconContainer>
              {legalEntity.__typename === "Person" && (
                <PersonVerificationIcon
                  verified={!!legalEntity.user?.onboardingCompletedAt}
                />
              )}
            </IconContainer>
          </View>
          <Text.DisplayM style={{ marginBottom: 8 }}>
            {displayName}
          </Text.DisplayM>
          <View style={{ marginBottom: 4 }}>
            {hasInvestorProperties && (
              <StyledPropertyBadges
                showInvestor={true}
                properties={investorProperties}
              />
            )}
            {hasTenantProperties && (
              <StyledPropertyBadges
                showTenant={true}
                properties={tenantProperties}
              />
            )}
          </View>
          {hasChatFeature && legalEntity.contact && (
            <View style={{ marginTop: 12, marginBottom: 4 }}>
              <StyledChatButton {...linkProps}>
                <Text.BodyM weight={"medium"}>{"Chat"}</Text.BodyM>
              </StyledChatButton>
            </View>
          )}
        </>
      }
    >
      <ProfileCardContactDetails
        phoneNo={
          legalEntity.__typename === "Person" ? legalEntity.phoneNo : undefined
        }
        emailAddress={
          legalEntity.__typename === "Person"
            ? legalEntity.emailAddress
            : undefined
        }
        registeredEntityId={
          legalEntity.__typename === "Company"
            ? legalEntity.registeredEntityId
            : undefined
        }
      />
    </BaseProfileCard>
  );
}

const IconContainer = styled(View)`
  flex-direction: row;
  justify-content: flex-end;
  height: 24px;
  margin-bottom: 20px;
  margin-top: 16px;
`;

const StyledPropertyBadges = styled(PropertyBadges).attrs({
  badgeStyle: { marginBottom: 4 }
})`
  margin-bottom: 4px;
  flex-wrap: wrap;
`;

const StyledChatButton = styled(Button).attrs({
  variant: "secondary",
  borderColor: {
    default: Colors.GRAYSCALE.OUTLINE,
    disabled: Colors.GRAYSCALE.OUTLINE
  }
})`
  padding: 6px;
  min-height: 32px;
  height: 32px;
  width: 66px;
`;

PropertySidebarProfileCard.Loading = Loading;

function Loading({
  entityType,
  style
}: {
  entityType: "Person" | "Company";
  style?: ProfileCardStyle;
}): React.ReactElement {
  return (
    <BaseProfileCard.Loading
      style={style}
      colorGradientContent={
        <>
          <LoadingAvatarWrapper>
            {entityType === "Person" ? (
              <PersonAvatar.Loading size={64} />
            ) : (
              <BriefcaseAvatar.Loading size={64} />
            )}
          </LoadingAvatarWrapper>
          <Text.DisplayM.Loading width={120} style={{ marginBottom: 8 }} />
          <View style={{ flexDirection: "row", paddingBottom: 4 }}>
            <Badge.Loading width={80} style={{ marginRight: 4 }} />
            <Badge.Loading width={80} />
          </View>
        </>
      }
    >
      <ProfileCardContactDetails.Loading isCompany={entityType === "Company"} />
    </BaseProfileCard.Loading>
  );
}

const LoadingAvatarWrapper = styled(View)`
  background-color: ${Colors.WHITE};
  border-radius: 32px;
  margin-top: -20px;
  margin-bottom: 18px;
  width: 64px;
`;
