import { SFC, Text } from "@ailo/primitives";
import { useOnFocus } from "@ailo/services";
import { setVerifiedOnLegalEntity } from "local/domain/authz";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components/native";
import { useGetTenantsAndOutstandingLiability } from "./useGetTenantsAndOutstandingLiability";
import { ManageMenu } from "./ManageMenu";
import { MovingOutBanner } from "./MovingOutBanner";
import { useNavigateToClaimTenancyBond } from "local/tabs";
import { LeaseRenewalModalForm } from "local/modals/LeaseRenewalModalForm";
import { useGlobalModal } from "@ailo/ui";
import { BaseTenancyCard, isBondInClaimProcess } from "../BaseTenancyCard";
import { createBondMenuOptions } from "../BaseTenancyCard/createBondMenuOptions";
import { RentReviewModalForm } from "local/modals/RentReviewModalForm";
import {
  AllowedOperations,
  OccupancyStatus,
  RentFragment,
  TenancyAgreementFragment
} from "local/graphql";
import { CancelVacateModal } from "./CancelVacateModal";
import { AiloRN } from "@ailo/ailorn";
import { isPresent } from "ts-is-present";

interface Props {
  managementId: string;
  tenancyId: string;
  leaseRenewalAllowedOperation?: AllowedOperations;
  occupancyStatus?: OccupancyStatus | null;
  onDetailsPress: (tenancyId: string, title: string) => void;
  onVacatePress: (tenancyId: string) => void;
  onEditEndTenancyPress: (tenancyId: string) => void;
  onTenantPress?: (id: string, entityType: "Person" | "Company") => void;
  onRentAdjustmentsPress: (tenancyId: string) => void;
  onAddBondDetailsPress: (tenancyId: string) => void;
  onEditBondDetailsPress: (tenancyId: string) => void;
  onViewBondClaimPress: (tenancyId: string) => void;
  onEditBondReferencePress: (tenancyId: string) => void;
}

export const ActiveTenancyCard: SFC<Props> = ({
  style,
  managementId,
  tenancyId,
  occupancyStatus,
  onDetailsPress,
  onVacatePress,
  onEditEndTenancyPress,
  onTenantPress,
  onRentAdjustmentsPress,
  onAddBondDetailsPress,
  onEditBondDetailsPress,
  onViewBondClaimPress,
  onEditBondReferencePress
}) => {
  const { data, loading, error, refetch } =
    useGetTenantsAndOutstandingLiability(tenancyId);

  const modal = useGlobalModal();

  const navigateToClaimBond = useNavigateToClaimTenancyBond({
    managementId,
    tenancyId
  });

  const onLeaseRenewalSuccess = (
    rentReviewChecked: boolean,
    agreementStartDate: string
  ): void => {
    if (rentReviewChecked) {
      modal.show(
        <RentReviewModalForm
          tenancyId={tenancyId}
          prefill={{ effectiveDate: agreementStartDate }}
        />
      );
    } else {
      modal.hide();
    }
  };

  const leaseRenewalModal = (
    nextTenancyAgreement?: TenancyAgreementFragment
  ): void => {
    modal.show(
      <LeaseRenewalModalForm
        tenancyId={tenancyId}
        onDismiss={modal.hide}
        onSuccess={onLeaseRenewalSuccess}
        agreement={nextTenancyAgreement}
      />
    );
  };

  const rentReviewModal = (editableRent?: RentFragment): void => {
    modal.show(
      <RentReviewModalForm tenancyId={tenancyId} editableRent={editableRent} />
    );
  };

  const [
    confirmRemoveEndDateModalVisible,
    setConfirmRemoveEndDateModalVisible
  ] = useState(false);

  const cardTitle = "Active Tenancy";

  useOnFocus(refetch);

  const tenantContactAilorns = useMemo(
    () =>
      (data?.tenants ?? [])
        .map(({ contact }) =>
          contact
            ? AiloRN.from(contact.ailorn, { expected: "contact:contact" })
            : null
        )
        .filter(isPresent),
    [data?.tenants]
  );

  const openDetailsSidebar = useCallback(
    (): void => onDetailsPress(tenancyId, cardTitle),
    [tenancyId, cardTitle, onDetailsPress]
  );

  if (loading && !data) {
    return (
      <BaseTenancyCard.Loading
        style={style}
        headerRightComponentLoading={<Text.BodyM.Loading width={80} />}
      />
    );
  }

  if (error || !data) {
    throw error ?? new Error("Could not load data");
  }

  const bondMenuOptions = createBondMenuOptions({
    bond: data?.bond,
    onAddBondDetailsPress,
    onEditBondDetailsPress,
    tenancyId,
    navigateToClaimBond,
    onViewBondClaimPress,
    onEditBondReferencePress
  });

  return (
    <BaseTenancyCard
      managementId={managementId}
      title={cardTitle}
      style={style}
      tenants={data.tenants.map(setVerifiedOnLegalEntity)}
      tenantContactAilorns={tenantContactAilorns}
      displayBondStatus={isBondInClaimProcess(data.bond)}
      headerRightComponent={
        <ManageMenu
          rentReview={{
            canCreate: data.rentReviewAllowed,
            editableRent: data.editableRentReview,
            onPress: ({ editableRent, canCreate }) => {
              if (!editableRent && !canCreate) {
                return;
              }
              rentReviewModal(editableRent);
            }
          }}
          leaseRenewal={{
            allowedOperation: data.leaseReviewAllowedOperation,
            nextTenancyAgreement: data.nextTenancyAgreement,
            onPress: ({ nextTenancyAgreement }): void =>
              leaseRenewalModal(nextTenancyAgreement)
          }}
          endTenancy={{
            isVacating:
              occupancyStatus === OccupancyStatus.Vacating && !!data.endDate,
            onPress: ({ isVacating }) => {
              if (isVacating) onEditEndTenancyPress(tenancyId);
              else onVacatePress(tenancyId);
            }
          }}
          stopVacating={{
            tenancyId,
            onPress: () => {
              setConfirmRemoveEndDateModalVisible(true);
            }
          }}
          inspections={{
            onPress: openDetailsSidebar
          }}
          rentAdjustments={{
            onPress: () => onRentAdjustmentsPress(tenancyId)
          }}
          bondMenuOptions={bondMenuOptions}
          centrepayPayments={{
            onPress: openDetailsSidebar
          }}
        />
      }
      onDetailsPress={openDetailsSidebar}
      onTenantPress={onTenantPress}
      bondMenuOptions={bondMenuOptions}
    >
      {data.endDate && (
        <StyledMovingOutBanner
          endDate={data.endDate}
          outstandingBill={data.outstandingBillsBalance}
          outstandingRent={data.terminationAmountDue}
        />
      )}

      <CancelVacateModal
        tenancyId={tenancyId}
        canCancelVacate={data.canCancelVacate}
        tenancyEndDate={data.endDate}
        visible={confirmRemoveEndDateModalVisible}
        onDismiss={() => setConfirmRemoveEndDateModalVisible(false)}
      />
    </BaseTenancyCard>
  );
};

const StyledMovingOutBanner = styled(MovingOutBanner)`
  margin: 0 16px 16px;
`;
