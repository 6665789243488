import { PropertyListView, Screens, useNavigation } from "local/common";
import { BillPayerType, OccupancyStatus } from "local/graphql";
import _ from "lodash";
import React from "react";
import { View } from "react-native";
import { useState, useMemo } from "reactn";
import styled from "styled-components/native";
import { DashboardCard } from "./dashboardCard";

const DashboardCardListWrapper = styled(View)<{ columnNumber: 1 | 2 | 3 }>`
  display: grid;
  gap: 12px 12px;
  grid-template-columns: repeat(${({ columnNumber }) => columnNumber}, 1fr);
`;

const DashboardCardColumnWrapper = styled(View)`
  display: flex;
  flex-direction: column;
  gap: 12px 12px;
  flex-wrap: wrap;
`;

export enum DashboardFigureName {
  TenancyArrears = "Tenancies in rental arrears",
  LeaseRenewals = "Lease renewals due within 90 days",
  PUM = "Properties under management",
  VacantProperties = "Vacant properties",
  OverdueTenancyBills = "Properties with overdue tenancy bills",
  OverdueManagementBills = "Properties with overdue management bills"
}

function DashboardCardList(): React.ReactElement {
  const [columnNumber, setColumnNumber] = useState<1 | 2 | 3>(2);
  const { navigate } = useNavigation();

  function columnNumberCallback(wrapperWidth: number | null): void {
    if (wrapperWidth == null) {
      return;
    }

    if (wrapperWidth > 1280) {
      setColumnNumber(3);
    } else if (wrapperWidth < 1280 && wrapperWidth > 664) {
      return setColumnNumber(2);
    } else {
      return setColumnNumber(1);
    }
  }

  // David and Han say this should be moved in to a procedual function for readablity
  const TwoDArrayOfCards = useMemo(() => {
    const propertyTabOnPress = (): void =>
      navigate(Screens.PropertiesTab, {
        screen: Screens.PropertyList,
        params: { tab: PropertyListView.PROPERTIES, statuses: [] }
      });
    const overdueBillsOnPressTenancy = (): void =>
      navigate(Screens.BillsTab, {
        tab: "overdue",
        payerType: BillPayerType.Tenancy
      });
    const overdueBillsOnPressManagement = (): void =>
      navigate(Screens.BillsTab, {
        tab: "overdue",
        payerType: BillPayerType.Management
      });
    const vacantOnPress = (): void =>
      navigate(Screens.PropertiesTab, {
        screen: Screens.PropertyList,
        params: {
          tab: PropertyListView.PROPERTIES,
          statuses: [OccupancyStatus.Vacant]
        }
      });
    const tenancyArrearsOnPress = (): void =>
      navigate(Screens.PropertiesTab, {
        screen: Screens.PropertyList,
        params: { tab: PropertyListView.ARREARS }
      });
    const renewalsOnPress = (): void =>
      navigate(Screens.PropertiesTab, {
        screen: Screens.PropertyList,
        params: {
          tab: PropertyListView.RENEWALS_AND_REVIEWS
        }
      });
    return [
      {
        title: DashboardFigureName.TenancyArrears,
        viewButtonOnPress: tenancyArrearsOnPress
      },
      {
        title: DashboardFigureName.LeaseRenewals,
        viewButtonOnPress: renewalsOnPress
      },
      {
        title: DashboardFigureName.OverdueTenancyBills,
        viewButtonOnPress: overdueBillsOnPressTenancy
      },
      {
        title: DashboardFigureName.OverdueManagementBills,
        viewButtonOnPress: overdueBillsOnPressManagement
      },
      {
        title: DashboardFigureName.VacantProperties,
        viewButtonOnPress: vacantOnPress
      },
      {
        title: DashboardFigureName.PUM,
        viewButtonOnPress: propertyTabOnPress
      }
    ].reduce<Array<React.ReactNodeArray>>(
      (cardColumn, cardProps, index) =>
        cardColumn.map((arrayElements, arrayIndex) => {
          return index % columnNumber == arrayIndex % columnNumber
            ? [
                ...arrayElements,
                <DashboardCard key={cardProps.title} {...cardProps} />
              ]
            : arrayElements;
        }),
      new Array(columnNumber).fill([])
    );
  }, [columnNumber, navigate]);

  const columns = useMemo(
    () =>
      TwoDArrayOfCards.map((cardColumn, index) => {
        return (
          <DashboardCardColumnWrapper key={index + "card Array"}>
            {cardColumn}
          </DashboardCardColumnWrapper>
        );
      }),
    [TwoDArrayOfCards]
  );

  return (
    <DashboardCardListWrapper
      onLayout={({ nativeEvent }) => {
        columnNumberCallback(nativeEvent?.layout.width);
      }}
      columnNumber={columnNumber}
    >
      {columns}
    </DashboardCardListWrapper>
  );
}

export { DashboardCardList };
