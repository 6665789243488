import { AiloRN } from "@ailo/ailorn";
import { AustralianState, getState } from "local/common";
import {
  GetPropertyDetailsByManagementQuery,
  useGetPropertyDetailsByManagementQuery
} from "local/graphql";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect
} from "react";

export interface PropertyAddress {
  unitStreetNumber: string;
  streetName: string;
  suburb?: string | null;
  state?: string | null;
  country: string;
  postcode?: string | null;
}

interface PropertyData {
  managementId: string;
  portfolioAilorn: AiloRN<"propertymanagement:managementfolio">;
  property: {
    id: string;
    address: PropertyAddress;
  };
  managingEntity?: {
    id: string;
    ailorn: AiloRN;
  };
}

const PropertyContext = createContext<PropertyData | undefined>(undefined);

function PropertyContextProvider({
  managementId,
  children
}: PropsWithChildren<{
  managementId?: string;
}>): React.ReactElement {
  const { data, refetch } = useGetPropertyDetailsByManagementQuery({
    variables: { id: managementId! },
    skip: !managementId
  });

  useEffect(() => {
    refetch();
  }, [managementId, refetch]);

  return (
    <PropertyContext.Provider value={parsePropertyDetails(data?.management)}>
      {children}
    </PropertyContext.Provider>
  );
}

function parsePropertyDetails(
  details: GetPropertyDetailsByManagementQuery["management"]
): PropertyData | undefined {
  if (!details) {
    return undefined;
  }
  return {
    managementId: details.id,
    portfolioAilorn: AiloRN.from(details.managementFolio.ailorn, {
      expected: "propertymanagement:managementfolio"
    }),
    managingEntity: details.managingEntity
      ? {
          id: details.managingEntity?.id,
          ailorn: AiloRN.from(details.managingEntity?.ailoRN)
        }
      : undefined,
    property: {
      id: details.property.id,
      address: details.property.address
    }
  };
}

function useCurrentPropertyAddress(): PropertyAddress | undefined {
  return useContext(PropertyContext)?.property.address;
}

function useAgencyLegalEntityOfCurrentProperty(): AiloRN | undefined {
  return useContext(PropertyContext)?.managingEntity?.ailorn;
}

function usePropertyIsInStates(states: AustralianState[]): boolean {
  const state = getState(useContext(PropertyContext)?.property.address.state);
  return state ? states.includes(state) : false;
}

function useCurrentPropertyPortfolioAilorn():
  | AiloRN<"propertymanagement:managementfolio">
  | undefined {
  return useContext(PropertyContext)?.portfolioAilorn;
}

export {
  useAgencyLegalEntityOfCurrentProperty,
  useCurrentPropertyAddress,
  useCurrentPropertyPortfolioAilorn,
  usePropertyIsInStates,
  PropertyContext,
  PropertyData,
  PropertyContextProvider
};
