import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { useCallback } from "react";
import { MutationResult } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { isPresent } from "ts-is-present";
import {
  GetPagedApprovedMigratingManagementsDocument,
  GetPagedAwaitingApprovalMigratingManagementsDocument,
  ApproveMigratingManagementMutation,
  GetMigratingManagementSidebarDocument,
  useApproveMigratingManagementMutation
} from "local/graphql";
import { AiloRN, services } from "@ailo/ailorn";

interface ApproveMigratingManagementsProps {
  migratingManagementId: string;
  onSuccess: (result: ApproveMigratingManagementMutation) => void;
  onError: () => void;
}

type ApproveMigratingManagements = [
  () => void,
  MutationResult<ApproveMigratingManagementMutation>
];

const refreshOperationNames = [
  getOperationName(GetPagedAwaitingApprovalMigratingManagementsDocument),
  getOperationName(GetPagedApprovedMigratingManagementsDocument)
];

export function useApproveMigratingManagement({
  migratingManagementId,
  onSuccess,
  onError
}: ApproveMigratingManagementsProps): ApproveMigratingManagements {
  const analytics = useAnalytics();

  const [approveMigratingManagementMutation, mutationResult] =
    useApproveMigratingManagementMutation({
      variables: { migratingManagementId },
      refetchQueries: [
        {
          query: GetMigratingManagementSidebarDocument,
          variables: {
            migratingManagementId
          }
        },
        ...refreshOperationNames.filter(isPresent)
      ]
    });

  const approveMigratingManagement = useCallback(async () => {
    try {
      analytics.track("Migrating Property Approved", {
        migrating_management_id: AiloRN.of(
          services.Migration.migratingManagement,
          migratingManagementId
        ).toString()
      });

      const result = await approveMigratingManagementMutation();
      throwIfMutationFailed(result, { dataKey: "approveMigratingManagements" });

      if (!result.data) {
        onError();
        return;
      }

      onSuccess(result.data);
    } catch (error) {
      AiloSentry.captureException(error);
      onError();
    }
  }, [
    approveMigratingManagementMutation,
    onSuccess,
    onError,
    analytics,
    migratingManagementId
  ]);

  return [approveMigratingManagement, mutationResult];
}
