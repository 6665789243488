import { SearchBox } from "@ailo/ui";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { FilterComponentProps } from "../types";

export interface StringFilterProps extends FilterComponentProps {
  placeholder?: string;
  control: Control;
}

export const StringFilter = ({
  control,
  name,
  placeholder = "",
  defaultValue
}: StringFilterProps): React.ReactElement<StringFilterProps> => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ value, onChange }) => (
      <SearchBox
        value={value}
        key={name}
        placeholder={placeholder}
        onClear={() => onChange("")}
        onInputFinish={(e) => onChange(e)}
        onInput={(e) => {
          if (e == "") onChange("");
        }}
        controlType={"full-width"}
      />
    )}
  />
);
