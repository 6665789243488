import React, { ReactElement } from "react";
import { MigratingManagementSidebarFragment } from "local/graphql";
import { Colors, Text } from "@ailo/primitives";
import { View } from "react-native";
import { isPresent } from "ts-is-present";

interface Props {
  migratingManagement: MigratingManagementSidebarFragment;
}

function MigratingManagementAddress({
  migratingManagement
}: Props): ReactElement | null {
  const address = migratingManagement.body.management?.property;
  if (!address) return null;

  const streetAddress = [address.unitStreetNumber, address.streetName]
    .filter(isPresent)
    .join(" ");
  const suburbDetails = [address.suburb, address.state, address.postcode]
    .filter(isPresent)
    .join(" ");

  return (
    <View>
      <Text.DisplayS>{streetAddress}</Text.DisplayS>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
        {suburbDetails}
      </Text.BodyM>
    </View>
  );
}

function Loading(): ReactElement {
  return (
    <View>
      <Text.DisplayS.Loading width={160} />
      <Text.BodyM.Loading width={120} />
    </View>
  );
}

MigratingManagementAddress.Loading = Loading;

export { MigratingManagementAddress };
