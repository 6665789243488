import { ErrorSubtractedIcon, SuccessSubtractedIcon } from "@ailo/primitives";
import React, { ReactElement } from "react";
import { Badge, BadgeCellLoading, BadgeType, CellContainer } from "@ailo/ui";
import { AwaitingApprovalMigratingManagementFragment } from "local/graphql";

function StatusCell({
  row: { errors }
}: {
  row: AwaitingApprovalMigratingManagementFragment;
  rowIndex: number;
}): ReactElement {
  const errorsArray = Array.isArray(errors) ? errors : [];
  const isHealthy = errorsArray.length === 0;

  const badgeText = isHealthy ? "Healthy" : "Data Issues";
  const badgeType: BadgeType = isHealthy ? "success" : "critical";
  const LeftComponent = isHealthy ? SuccessSubtractedIcon : ErrorSubtractedIcon;

  return (
    <CellContainer>
      <Badge
        type={badgeType}
        leftComponent={
          <LeftComponent width={12} height={12} style={{ marginVertical: 2 }} />
        }
      >
        {badgeText}
      </Badge>
    </CellContainer>
  );
}

StatusCell.Loading = BadgeCellLoading;

export { StatusCell };
