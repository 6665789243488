import React, { useMemo } from "react";
import { View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { formatDate } from "@ailo/services";
import { Colors, Text } from "@ailo/primitives";
import { DescriptionList, formatProjectType, Separator } from "@ailo/ui";
import { ProjectBadges, getManagementIfExists } from "local/domain/project";
import { ProjectDetailsFragment } from "local/graphql";
import { AssigneeField } from "./AssigneeField";
import { PropertyAddressBadge } from "./PropertyAddressBadge";
import { FailedToLoad } from "./FailedToLoad";
import { isPresent } from "ts-is-present";
import { ProjectFilesDragAndDrop } from "./ProjectFilesDragAndDrop";

interface ProjectDetailsProps {
  project: ProjectDetailsFragment;
  style: ViewStyle;
  openFileTab: () => void;
}

export function ProjectDetails({
  project,
  style
}: ProjectDetailsProps): React.ReactElement {
  const { description, dueDate, assignee, type } = project;
  const management = getManagementIfExists(project);

  const items = [
    management
      ? {
          label: "Property",
          description: <PropertyAddressBadge management={management} />
        }
      : undefined,
    {
      label: "Project",
      description: <ProjectBadges project={project} />
    },
    {
      label: "Type",
      description: formatProjectType(type)
    },
    {
      label: "Description",
      description: description || "—"
    },
    {
      label: "Due date",
      description: formatDate(dueDate)
    },
    {
      label: "Assignee",
      description: assignee ? (
        <AssigneeField assignee={assignee} />
      ) : (
        <FailedToLoad />
      )
    }
  ].filter(isPresent);

  const renderLabel = (label: string): React.ReactNode => (
    <Text.BodyS
      style={{ width: 80 }}
      weight={"book"}
      color={Colors.TEXT.DARK.SECONDARY}
    >
      {label}
    </Text.BodyS>
  );

  const renderDescription = (
    description: string | React.ReactNode
  ): React.ReactNode =>
    typeof description !== "string" ? (
      description
    ) : (
      <Text.BodyS weight={"book"}>{description}</Text.BodyS>
    );

  const projectFilesDragAndDrop = useMemo(() => {
    return <ProjectFilesDragAndDrop project={project} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.files]);

  return (
    <View style={[style]}>
      <Title>{project.name}</Title>
      <DescriptionList
        size={"small"}
        items={items.map(({ label, description }) => ({
          label: renderLabel(label),
          description: renderDescription(description)
        }))}
        itemStyle={{ marginTop: 12 }}
      />
      <SectionSeparator />
      {projectFilesDragAndDrop}
    </View>
  );
}

ProjectDetails.Loading = function ProjectDetailsLoading({
  style
}: {
  style?: ViewStyle;
}): React.ReactElement {
  return (
    <View style={style}>
      <Text.DisplayM.Loading
        width={240}
        height={28}
        style={{ marginBottom: 12 }}
      />
      <LoadingDescriptionRow>
        <LoadingDescriptionLabel>
          <Text.BodyS.Loading width={59} />
        </LoadingDescriptionLabel>
        <ProjectBadges.Loading />
      </LoadingDescriptionRow>
      <LoadingDescriptionRow>
        <LoadingDescriptionLabel>
          <Text.BodyS.Loading width={63} />
        </LoadingDescriptionLabel>
        <Text.BodyS.Loading width={60} />
      </LoadingDescriptionRow>
      <LoadingDescriptionRow>
        <LoadingDescriptionLabel>
          <Text.BodyS.Loading width={63} />
        </LoadingDescriptionLabel>
        <Text.BodyS.Loading width={60} />
      </LoadingDescriptionRow>
      <LoadingDescriptionRow>
        <LoadingDescriptionLabel>
          <Text.BodyS.Loading width={63} />
        </LoadingDescriptionLabel>
        <Text.BodyS.Loading width={120} />
      </LoadingDescriptionRow>
      <LoadingDescriptionRow>
        <LoadingDescriptionLabel>
          <Text.BodyS.Loading width={67} />
        </LoadingDescriptionLabel>
        <LoadingDescriptionColumn>
          <Text.BodyS.Loading width={264} />
          <Text.BodyS.Loading width={264} />
          <Text.BodyS.Loading width={120} />
        </LoadingDescriptionColumn>
      </LoadingDescriptionRow>
      <LoadingDescriptionRow>
        <LoadingDescriptionLabel>
          <Text.BodyS.Loading width={72} />
        </LoadingDescriptionLabel>
        <AssigneeField.Loading />
      </LoadingDescriptionRow>
    </View>
  );
};

const Title = styled(Text.DisplayM)`
  margin-bottom: 14px;
`;

const SectionSeparator = styled(Separator)`
  margin: 40px -60px 32px 0px;
  margin-bottom: 32px;
`;

const LoadingDescriptionRow = styled(View)`
  flex-direction: row;
  margin-top: 12px;
`;

const LoadingDescriptionColumn = styled(View)`
  flex-direction: column;
`;

const LoadingDescriptionLabel = styled(View)`
  width: 72px;
  margin-right: 24px;
`;
