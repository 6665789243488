import React, { ReactElement } from "react";
import { PersonAvatar, Tooltip, CellContainer } from "@ailo/ui";
import { ProjectListRow } from "../ProjectList";

export function AssigneeCell({
  row: { person }
}: {
  row: ProjectListRow;
  rowIndex: number;
}): ReactElement {
  return (
    <CellContainer>
      <Tooltip
        tooltipContent={[person.firstName, person?.lastName]
          .filter(Boolean)
          .join(" ")}
        style={{ alignSelf: "flex-start" }}
      >
        <PersonAvatar person={person} size={32} />
      </Tooltip>
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <PersonAvatar.Loading size={32} />
    </CellContainer>
  );
}

AssigneeCell.Loading = Loading;
