import { AiloSentry } from "@ailo/services";
import {
  ManagementNoteFragment,
  useCreateOrUpdateManagementNoteMutation
} from "local/graphql";
import { throttle } from "lodash";
import { useManagementNoteForm } from "./ManagementNoteFormContext";
import { ManagementNoteFields } from "./ManagementNoteFormData";

interface Props {
  onSuccess: (note?: ManagementNoteFragment) => void;
  onError: () => void;
}

export function useSubmit({
  onSuccess,
  onError
}: Props): () => Promise<void> | void {
  const { handleSubmit } = useManagementNoteForm();
  const [createOrUpdateManagementNoteMutation] =
    useCreateOrUpdateManagementNoteMutation();

  return throttle(
    handleSubmit(async (input: ManagementNoteFields) => {
      try {
        const result = await createOrUpdateManagementNoteMutation({
          variables: {
            input: {
              ...input,
              note: input.note ?? ""
            }
          }
        });

        if (result.errors) {
          AiloSentry.captureException(result.errors);
          onError();
          return;
        }
        const noteResult = result.data?.note?.managementNote;
        await onSuccess(noteResult);
      } catch (error: unknown) {
        AiloSentry.captureException(error);
        onError();
        return;
      }
    }),
    500
  );
}
