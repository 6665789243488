import { AFC, Colors, opacify, Text } from "@ailo/primitives";
import { CellContainer, LocalDate } from "@ailo/ui";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";

interface StickProps {
  hidden?: boolean;
}

interface LoadingProps {
  isFirstRow: boolean;
  isLastRow: boolean;
}

interface Props {
  date: LocalDate;
  isFirstRow: boolean;
  isLastRow: boolean;
}

interface Statics {
  Loading: React.FC<LoadingProps>;
}

const DueDate: AFC<Props> & Statics = ({ date, isFirstRow, isLastRow }) => {
  return (
    <CellContainer>
      <Stick hidden={isFirstRow} />
      <TextContainer>
        <Text.BodyS weight={"medium"} color={Colors.TEXT.DARK.SECONDARY}>
          {date.format("D")}
        </Text.BodyS>
      </TextContainer>
      <TextContainer>
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
          {date.format("MMM")}
        </Text.BodyS>
      </TextContainer>
      <Stick hidden={isLastRow} />
    </CellContainer>
  );
};

const Stick = styled(View)<StickProps>`
  background-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  visibility: ${({ hidden }): string => (hidden ? "hidden" : "visible")};
  width: 2px;
  height: 16px;
  margin-left: 19px;
`;

const TextContainer = styled(View)`
  width: 40px;
  height: 20px;
  align-items: center;
`;

const Loading: AFC<LoadingProps> = ({ isFirstRow, isLastRow }) => {
  return (
    <CellContainer>
      <Stick hidden={isFirstRow} />
      <TextContainer>
        <Text.BodyM.Loading width={20} />
      </TextContainer>
      <TextContainer>
        <Text.BodyS.Loading width={40} />
      </TextContainer>
      <Stick hidden={isLastRow} />
    </CellContainer>
  );
};

DueDate.Loading = Loading;

export { DueDate };
