import React, { useCallback } from "react";
import { EndManagementForm } from "./EndManagementForm";
import { BaseSplitScreenModalForm } from "../BaseSplitScreenModalForm";
import { ManagementDetails } from "./ManagementDetails";
import { useGlobalModal } from "@ailo/ui";

interface Props {
  managementId: string;
  onDismiss?: () => void;
  onSuccess?: () => void;
}

export function EndManagementModalForm({
  managementId,
  onDismiss,
  onSuccess
}: Props): React.ReactElement {
  const globalModal = useGlobalModal();

  const dismissModal = useCallback(() => {
    globalModal.hide();
    onDismiss?.();
  }, [onDismiss, globalModal]);

  const successCallback = useCallback(() => {
    globalModal.hide();
    onSuccess?.();
  }, [onSuccess, globalModal]);

  const renderFormComponent = useCallback(
    (onFormSubmitting: (isSubmitting: boolean) => void): React.ReactElement => (
      <EndManagementForm
        managementId={managementId}
        onDismiss={dismissModal}
        onSuccess={successCallback}
        onFormSubmitting={(isSubmitting) => onFormSubmitting(isSubmitting)}
      />
    ),
    [managementId, dismissModal, successCallback]
  );

  return (
    <BaseSplitScreenModalForm
      title={"End management"}
      renderFormComponent={renderFormComponent}
      renderRightComponent={() => (
        <ManagementDetails managementId={managementId} />
      )}
      onDismiss={dismissModal}
    />
  );
}
