import React, { useEffect } from "react";
import { FormField, SelectInput } from "@ailo/ui";
import { Controller, useFormContext } from "react-hook-form";
import { EndManagementFormData } from "../EndManagementFormData";
import { ManagementEndReasonFragment } from "local/graphql";
import { ManagementEndReason, ManagementEndReasonCode } from "@ailo/domains";

export function ManagementEndReasonInput({
  initialValue,
  endReasons
}: {
  initialValue?: ManagementEndReasonCode | null;
  endReasons: ManagementEndReasonFragment[];
}): React.ReactElement {
  const { control, errors, setValue } = useFormContext<EndManagementFormData>();
  const formError = errors.managementEndReason as { message?: string };

  useEffect(() => {
    if (initialValue) {
      setValue("managementEndReason", initialValue);
    }
  }, [setValue, initialValue, endReasons]);

  const endReasonsWithoutOffboarded = endReasons.filter(isAgencyEndReason);

  return (
    <Controller
      name={"managementEndReason"}
      render={({ value, onChange, onBlur }): React.ReactElement => {
        return (
          <FormField label={"Management end reason"} error={formError?.message}>
            <SelectInput
              {...{ onBlur }}
              placeholder={"Select reason..."}
              value={value}
              options={endReasonsWithoutOffboarded.map((reason) => ({
                value: reason.code,
                label: reason.label
              }))}
              onChange={(option) => {
                onChange(
                  endReasonsWithoutOffboarded.find(
                    (reason) => reason.code === option?.value
                  )?.code
                );
              }}
              useTextInputHeight
              controlShouldRenderValue={!!value}
              testID={"ManagementEndReasonInputFormField"}
              hasError={!!formError?.message}
              isSearchable={false}
            />
          </FormField>
        );
      }}
      defaultValue={
        initialValue
          ? {
              label: getReasonLabel(endReasons, initialValue),
              value: initialValue
            }
          : null
      }
      control={control}
      rules={{
        required: "Please select a reason"
      }}
    />
  );
}

function isAgencyEndReason({ code }: ManagementEndReason): boolean {
  return code !== ManagementEndReasonCode.Offboarded;
}

function getReasonLabel(
  endReasons: ManagementEndReasonFragment[],
  value: ManagementEndReasonCode
): string {
  const endReason = endReasons.find((reason) => reason.code === value);
  if (!endReason) {
    throw new Error(`value "${value}" not found`);
  }
  return endReason.label;
}
