import _ from "lodash";
import { formatAddress } from "@ailo/domain-helpers";
import { useMountedState } from "@ailo/primitives";
import {
  MappedQueryResult,
  useEnvironment,
  useMappedQueryResult
} from "@ailo/services";
import { GridSortOrder } from "@ailo/ui";
import { ApolloError } from "@apollo/client";
import { useCurrentAgencyOrg } from "local/common";
import {
  ProjectPageInfoFragment,
  ProjectStatus,
  SortDirection,
  useGetProjectsByOrganisationQuery
} from "local/graphql";
import { ProjectListRow } from "local/tabs/projects/ProjectListScreen/components";
import { isPresent } from "ts-is-present";
import { useEffect } from "react";
import { FilterStateType } from "./filters";
import { getManagementIfExists } from "local/domain/project";

export type ProjectsByOrganisation = {
  projects: ProjectListRow[];
  pageInfo: ProjectPageInfoFragment;
};

export interface ProjectListQueryData {
  data: MappedQueryResult<ProjectsByOrganisation> & {
    currentPage: number;
    pagesCount: number;
  };
  requestNewPage: ({ paginateBackward, cursor }: RequestCursor) => void;
  requestNextPage: () => void;
  requestPreviousPage: () => void;
}
export interface RequestCursor {
  paginateBackward: boolean;
  cursor?: string | null;
}

export function useGetProjectRows(params?: {
  status?: ProjectStatus;
  sortOrder?: GridSortOrder;
  pageSize?: number;
  filterVariables?: FilterStateType;
}): ProjectListQueryData {
  const { PROJECT_LIST_PAGE_SIZE } = useEnvironment();

  const [currentPage, setCurrentPageNo] = useMountedState<number>(1);
  const { ailoRN: organisationAilorn } = useCurrentAgencyOrg();

  const {
    status,
    sortOrder = {
      columnKey: "dueDate",
      direction: "ASC"
    },
    pageSize = PROJECT_LIST_PAGE_SIZE,
    filterVariables
  } = params || {};

  useEffect(() => {
    setCurrentPageNo(1);
  }, [status, setCurrentPageNo, filterVariables, sortOrder]);

  const sort = generateSortValue({
    sortBy: sortOrder.columnKey,
    direction: sortOrder.direction
  });

  const result = useMappedQueryResult(
    useGetProjectsByOrganisationQuery({
      variables: {
        organisationAilorn,
        sort,
        pageSize,
        status,
        cursor: null,
        paginateBackward: false,
        ...filterVariables
      },
      notifyOnNetworkStatusChange: true
    }),
    (data) => {
      if (!data.projectsByOrganisation) {
        throw new ApolloError({
          errorMessage: "Failed to query projects by organisation"
        });
      }

      return {
        projects: data.projectsByOrganisation.items
          .filter(isPresent)
          .map((project): ProjectListRow => {
            const management = getManagementIfExists(project);
            const address = management?.property.address;
            const formattedAddress = address
              ? formatAddress(address, {
                  format: "street, suburb"
                })
              : undefined;

            if (!project.assignee) {
              throw new ApolloError({
                errorMessage: "Project has no valid assignee"
              });
            }

            return {
              ...project,
              projectId: project.id,
              reference: formattedAddress ?? "-",
              type: project.type,
              key: project.reference,
              person: project.assignee,
              actions: project.actions
            };
          }),
        pageInfo: data.projectsByOrganisation.pageInfo
      };
    }
  );

  const requestNewPage = ({
    paginateBackward,
    cursor
  }: RequestCursor): void => {
    const newPage = !cursor
      ? 1
      : paginateBackward
      ? currentPage - 1
      : currentPage + 1;

    setCurrentPageNo(newPage);

    const variables =
      newPage === 1
        ? { cursor: null, paginateBackward: false }
        : { cursor, paginateBackward };

    result.fetchMore({
      variables,
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        return fetchMoreResult;
      }
    });
  };

  function requestPreviousPage(): void {
    if (!result.data) return;
    requestNewPage({
      paginateBackward: true,
      cursor: result.data.pageInfo.previousCursor
    });
  }

  function requestNextPage(): void {
    if (!result.data) return;
    requestNewPage({
      paginateBackward: false,
      cursor: result.data.pageInfo.nextCursor
    });
  }

  return {
    data: {
      ...result,
      currentPage,
      pagesCount:
        result.data?.pageInfo.total &&
        result.data?.pageInfo.total > PROJECT_LIST_PAGE_SIZE
          ? Math.ceil(result.data?.pageInfo.total / PROJECT_LIST_PAGE_SIZE)
          : 1
    },
    requestNewPage,
    requestNextPage,
    requestPreviousPage
  };
}

function generateSortValue({
  sortBy,
  direction
}: {
  sortBy: string;
  direction: string;
}): string {
  return `${
    direction.toLowerCase() === SortDirection.Asc.toLowerCase() ? "+" : "-"
  }${_.camelCase(sortBy)}`;
}
