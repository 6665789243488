import { useGlobalModal } from "@ailo/ui";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import {
  BaseModal,
  ModalCloseButton,
  ModalHeader,
  ModalTitle
} from "./components";
import { InspectionAppointmentList } from "./InspectionAppointmentList";
import { InspectionDayDisplay } from "./InspectionDayDisplay";
import { InspectionPlannerContextProvider } from "./InspectionPlannerContext";
import { UnscheduledTenanciesList } from "./UnscheduledTenanciesList";

export function InspectionPlanner(): React.ReactElement {
  const modal = useGlobalModal();

  return (
    <InspectionPlannerContextProvider>
      <BaseModal>
        <ModalHeader>
          <ModalTitle>{"Inspections planner"}</ModalTitle>
          <ModalCloseButton
            onPress={() => {
              modal.hide();
            }}
          />
        </ModalHeader>
        <Container>
          <InspectionAppointmentList />
          <UnscheduledTenanciesList />
          <InspectionDayDisplay />
        </Container>
      </BaseModal>
    </InspectionPlannerContextProvider>
  );
}

const Container = styled(View)`
  flex: 1;
  flex-direction: row;
`;
