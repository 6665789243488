import { Colors, Text } from "@ailo/primitives";
import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { View } from "react-native";

interface Props {
  text: string;
  subtext?: string;
}

export function HealthIssue({ text, subtext }: Props): ReactElement {
  return (
    <Container>
      <Text.BodyS>{text}</Text.BodyS>
      {subtext ? (
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{subtext}</Text.BodyS>
      ) : null}
    </Container>
  );
}

const Container = styled(View)`
  padding: 16px;
  border-top-style: solid;
  border-top-color: ${Colors.GRAYSCALE.OUTLINE};
  border-top-width: 1px;
`;
