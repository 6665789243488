import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect
} from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  UseFormMethods
} from "react-hook-form";
import {
  ManagementNoteFields,
  ManagementNoteFormData
} from "./ManagementNoteFormData";

const ManagementNoteFormContext = createContext<
  ManagementNoteFormData | undefined
>(undefined);

export function useManagementNoteForm(): UseFormMethods<ManagementNoteFields> & {
  data: ManagementNoteFormData;
} {
  const formData = useContext(ManagementNoteFormContext);
  const formMethods = useFormContext<ManagementNoteFields>();

  if (!formData)
    throw new Error(
      `useManagementNoteForm must be called from within ManagementNoteFormProvider`
    );

  return { ...formMethods, data: formData };
}

export function ManagementNoteFormProvider({
  data,
  children
}: PropsWithChildren<{
  data: ManagementNoteFormData;
}>): ReactElement {
  const formMethods = useForm<ManagementNoteFields>({
    mode: "onChange",
    shouldFocusError: true
  });

  const { setValue, register } = formMethods;

  register({
    name: ManagementNoteFields.managementId,
    value: data.managementId
  });

  if (data.id) {
    register({
      name: ManagementNoteFields.id,
      value: data.id
    });
  }

  useEffect(() => {
    setValue(ManagementNoteFields.note, data.note || "");
  }, [data.note, setValue]);

  return (
    <FormProvider {...formMethods}>
      <ManagementNoteFormContext.Provider value={data}>
        {children}
      </ManagementNoteFormContext.Provider>
    </FormProvider>
  );
}
