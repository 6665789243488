import { WalletCard } from "@ailo/domains";
import {
  didQueryNotLoadYet,
  useActionEventContext,
  useOnFocus
} from "@ailo/services";
import { ContentWithSidebar } from "@ailo/ui";
import { NetworkStatus } from "@apollo/client";
import {
  PageCenteredColumn,
  PageContent,
  Screens,
  useCurrentAgencyOrg,
  useNavigation,
  useRoute
} from "local/common";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { ScrollView, View } from "react-native";
import { LegalEntitySwitcher } from "./LegalEntitySwitcher";
import { TabsAndContent } from "./TabsAndContent";
import { TransferFundsSidebarContent } from "./TransferFundsSidebarContent";
import { useWalletQuery } from "./useWalletQuery";

export const WalletTabScreenContent: FC = () => {
  const navigation = useNavigation();
  const route = useRoute<Screens.WalletTab>();
  const currentAgencyOrg = useCurrentAgencyOrg();

  const scrollViewRef = useRef<ScrollView>(null);

  const transferFundsSidebarOpen = !!route.params?.transfer;
  const legalEntityId =
    route.params?.legalEntityId ||
    currentAgencyOrg.defaultWalletLegalEntity?.id;
  const tabLabel = route.params?.tab;

  const openTransferFundsSidebar = useCallback(() => {
    navigation.navigate(Screens.WalletTab, {
      tab: tabLabel,
      transfer: "1",
      legalEntityId
    });
  }, [navigation, tabLabel, legalEntityId]);

  const closeTransferFundsSidebar = useCallback(() => {
    navigation.navigate(Screens.WalletTab, { tab: tabLabel, legalEntityId });
  }, [navigation, tabLabel, legalEntityId]);

  const setLegalEntityId = useCallback(
    (id) => {
      if (id != legalEntityId)
        navigation.navigate(Screens.WalletTab, {
          tab: tabLabel,
          legalEntityId: id
        });
    },
    [navigation, tabLabel, legalEntityId]
  );

  const showLegalEntitySwitcher = currentAgencyOrg.legalEntities.length > 1;

  const walletResult = useWalletQuery(legalEntityId, {
    notifyOnNetworkStatusChange: true
  });

  useOnFocus(walletResult.refetch);

  const {
    canWithdraw = false,
    wallet,
    bankAccountPaymentMethod
  } = walletResult.data ?? {};
  const hasFundsToWithdraw = (wallet?.availableBalance.cents ?? 0) > 0;

  const walletCard = didQueryNotLoadYet(walletResult) ? (
    <WalletCard.Loading
      agency
      transferButtonVisible={canWithdraw}
      style={{ margin: 0 }}
    />
  ) : (
    <WalletCard
      agency
      availableBalance={wallet?.availableBalance}
      totalBalance={wallet?.totalBalance}
      transferButtonVisible={canWithdraw}
      transferDisabled={transferFundsSidebarOpen || !hasFundsToWithdraw}
      nextTransferDate={wallet?.nextAutomaticWithdrawalDate}
      onTransfer={openTransferFundsSidebar}
      onRetry={walletResult.refetch}
      style={{ margin: 0 }}
    />
  );

  useEffect(() => {
    if (
      walletResult.data &&
      !walletResult.data.canWithdraw &&
      transferFundsSidebarOpen
    ) {
      closeTransferFundsSidebar();
    }
  }, [closeTransferFundsSidebar, transferFundsSidebarOpen, walletResult.data]);

  const eventContext = useActionEventContext();

  const sidebarContent = wallet ? (
    <TransferFundsSidebarContent
      wallet={wallet}
      bankAccount={bankAccountPaymentMethod}
      balanceLoading={
        walletResult.loading ||
        walletResult.networkStatus === NetworkStatus.refetch
      }
      onClose={closeTransferFundsSidebar}
      onTransferSuccess={() => {
        walletResult.refetch();
        eventContext.emit({ type: "AgencyFundsDisbursed" });
      }}
      onReload={walletResult.refetch}
    />
  ) : undefined;

  return (
    <ContentWithSidebar
      position={"static"}
      open={transferFundsSidebarOpen}
      sidebarContent={sidebarContent}
    >
      <PageContent
        contentContainerStyle={{ paddingTop: 16 }}
        scrollViewRef={scrollViewRef}
      >
        {showLegalEntitySwitcher && (
          <View style={{ alignItems: "flex-end" }}>
            <LegalEntitySwitcher
              legalEntityId={legalEntityId}
              onChange={setLegalEntityId}
            />
          </View>
        )}

        <PageCenteredColumn
          style={{ marginTop: showLegalEntitySwitcher ? 24 : 16 }}
        >
          {walletCard}
        </PageCenteredColumn>

        <TabsAndContent scrollViewRef={scrollViewRef} />
      </PageContent>
    </ContentWithSidebar>
  );
};
