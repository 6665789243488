import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { Colors, opacify } from "@ailo/primitives";
import {
  Button,
  ErrorModal,
  SidebarStickyBottom,
  SidebarTitle,
  SpinnerOverlay,
  useToastContext
} from "@ailo/ui";
import { Screens, useNavigation } from "local/common";
import {
  ApplyManagementFeeCheckboxInput,
  FilesInput,
  LineItemsInput,
  PayeeInput,
  PayerInput,
  useAddBillForm
} from "local/domain/bill/AddBillForm";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import {
  CreateSupplierModal,
  CreateSupplierPaymentMethodModal
} from "local/domain/supplier";
import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { BillDetailsInput, CreateBillButton, PaymentTypeInput } from "./form";
import { PropertyInput } from "./form/PropertyInput";

const MODAL_FIXED_HEIGHT = 600; // we need to force the supplier creation and payment method modals to be the same height.

export const AddBillForm = ({
  onBillCreate
}: {
  onBillCreate?(): void;
}): React.ReactElement => {
  const toast = useToastContext();
  const navigation = useNavigation();
  const attachmentsEnabled = useHasFeature(PlatformFeatureId.BillAttachments);

  const { register, unregister, setValue, watch, handleSubmit } =
    useFormContext<AddBillFormData>();
  const selectedSupplier = watch("supplierReference");
  const supplierName = selectedSupplier?.label;
  const supplierReference = selectedSupplier?.value;

  useEffect(() => {
    register({ name: "fieldUnderInvoiceNumberWasFocused" });
    return (): void => {
      unregister("fieldUnderInvoiceNumberWasFocused");
    };
  }, [register, unregister]);

  const { submit, sending, formError, dismissFormError } = useAddBillForm({
    onSubmitSuccess: () => {
      onBillCreate?.();
      toast.show({
        type: "success",
        message: "Bill created successfully"
      });
      navigation.navigate(Screens.BillsTab);
    }
  });

  const [createSupplierModalOpen, toggleCreateSupplierModal] = useState(false);
  const [
    createSupplierPaymentMethodModalOpen,
    toggleCreateSupplierPaymentMethodModal
  ] = useState(false);

  const scrollViewRef = useRef<ScrollView>(null);

  return (
    <Container testID={"AddBillForm"}>
      <ScrollContainer ref={scrollViewRef}>
        <SidebarTitle title={"Add New Bill"} />
        <FormContainer>
          <PropertyInput />
          <PayerInput
            label={"Who's paying this bill?"}
            noOptionMessage={
              "Search for a property to see who is able to pay this bill"
            }
            style={{ marginTop: 24 }}
          />
          <PayeeInput
            style={{ marginTop: 24 }}
            onCreateSupplierClick={() => toggleCreateSupplierModal(true)}
          />
          <ApplyManagementFeeCheckboxInput style={{ marginTop: 28 }} />
          <Separator style={{ marginTop: 40 }} />
          <PaymentTypeInput
            style={{ marginTop: 32 }}
            onCreatePaymentMethodClick={() =>
              toggleCreateSupplierPaymentMethodModal(true)
            }
          />
          <Separator style={{ marginTop: 40 }} />
          <BillDetailsInput style={{ marginTop: 32 }} />
          <Separator style={{ marginTop: 40 }} />
          <LineItemsInput style={{ marginTop: 32 }} />
          {attachmentsEnabled && (
            <>
              <Separator style={{ marginTop: 40 }} />
              <FilesInput
                style={{ marginTop: 32 }}
                onAttached={() => {
                  scrollViewRef.current?.scrollToEnd({ animated: true });
                }}
              />
            </>
          )}
        </FormContainer>
      </ScrollContainer>

      <SidebarStickyBottom>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Button.Secondary
            onPress={(): void => navigation.navigate(Screens.BillsTab)}
          >
            {"Cancel"}
          </Button.Secondary>
          <CreateBillButton onPress={handleSubmit(submit)} />
        </View>
      </SidebarStickyBottom>

      {sending && <SpinnerOverlay />}

      {createSupplierModalOpen ? (
        <CreateSupplierModal
          height={MODAL_FIXED_HEIGHT}
          onCancel={() => toggleCreateSupplierModal(false)}
          onSubmit={(data) => {
            setValue("supplierReference", {
              label: data.name,
              value: data.ailoRN
            });
            toggleCreateSupplierModal(false);
            setTimeout(() => toggleCreateSupplierPaymentMethodModal(true), 100);
          }}
        />
      ) : null}

      {createSupplierPaymentMethodModalOpen &&
      supplierReference &&
      supplierName ? (
        <CreateSupplierPaymentMethodModal
          height={MODAL_FIXED_HEIGHT}
          supplier={{
            ailoRN: supplierReference,
            name: supplierName
          }}
          onCancel={() => toggleCreateSupplierPaymentMethodModal(false)}
          onSubmit={() => {
            toggleCreateSupplierPaymentMethodModal(false);
          }}
        />
      ) : null}

      {formError && (
        <ErrorModal
          title={formError.title}
          description={formError.message}
          onDismiss={dismissFormError}
        />
      )}
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
`;

const ScrollContainer = styled(ScrollView)`
  flex: 1;
  background-color: ${Colors.WHITE};
`;

const FormContainer = styled(View)`
  padding-horizontal: 32px;
  padding-bottom: 32px;
`;

const Separator = styled(View)`
  margin-right: -32px;
  height: 1px;
  background-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;
