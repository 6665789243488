import React from "react";
import { DropdownMenuOption } from "@ailo/ui";
import { Options } from "./ManageMenu";
import { EditIcon, RentReviewIcon } from "@ailo/primitives";
import { EditableRentFragment } from "local/graphql";

export function getRentReviewOption(
  rentReview: Options<{
    editableRent?: EditableRentFragment;
    canCreate: boolean;
  }>
): DropdownMenuOption {
  const disabled = !(rentReview.canCreate || rentReview.editableRent);

  const review = {
    ...rentReview,
    editableRent: rentReview.canCreate ? undefined : rentReview.editableRent
  };

  const onSelect = (): void => rentReview.onPress(review);

  return rentReview.canCreate
    ? getCreateRentReviewOption({ onSelect, disabled })
    : getEditRentReviewOption({ onSelect, disabled });
}

function getCreateRentReviewOption({
  onSelect,
  disabled
}: {
  disabled: boolean;
  onSelect: () => void;
}): DropdownMenuOption {
  return {
    label: "Rent review",
    icon: <RentReviewIcon />,
    onSelect,
    disabled
  };
}

function getEditRentReviewOption({
  onSelect,
  disabled
}: {
  disabled: boolean;
  onSelect: () => void;
}): DropdownMenuOption {
  return {
    label: "Edit rent review",
    icon: <EditIcon />,
    onSelect,
    disabled
  };
}
