import React, { ReactElement, useCallback, useMemo } from "react";
import { useCurrentAgencyOrg } from "local/common";
import { AiloRN, services } from "@ailo/ailorn";
import { Colors, Skeleton, Text } from "@ailo/primitives";
import {
  Button,
  DropdownMenu,
  DropdownMenuToggleProps,
  PersonAvatar
} from "@ailo/ui";
import { ActionDetailsFragment } from "local/graphql";
import { useGetAssignees } from "local/modals/ActionModalForm/useGetAssignees";
import { sortPeopleAlphabeticallyByName } from "local/domain/utils/sortPeopleAlphabeticallyByName";
import { formatPersonName } from "@ailo/domain-helpers";

type Assignee = ActionDetailsFragment["assignee"];

export function AssigneeSelector({
  assignee,
  onReassignAction
}: {
  assignee: Assignee;
  onReassignAction: (assigneeAilorn: string) => void;
}): ReactElement | null {
  const { id: organisationId } = useCurrentAgencyOrg();
  const { assignees, loading: loadingAssignees } = useGetAssignees({
    organisationId
  });

  const assigneeOptions = useMemo(() => {
    if (loadingAssignees) {
      return [];
    }

    return sortPeopleAlphabeticallyByName(assignees).map((assignee) => ({
      label: (
        <Text.BodyL weight={"book"}>{formatPersonName(assignee)}</Text.BodyL>
      ),
      icon: <PersonAvatar person={assignee} size={24} />,
      onSelect: () =>
        onReassignAction(
          AiloRN.of(services.AuthZ.legalEntity, assignee.id).toString()
        )
    }));
  }, [assignees, loadingAssignees, onReassignAction]);

  const renderDropdownButton = useCallback(
    ({ toggle, open }: DropdownMenuToggleProps) => {
      const assigneeName = assignee ? formatPersonName(assignee) : "Unassigned";

      return (
        <Button.Secondary
          type={"small"}
          active={open}
          onPress={toggle}
          contentColor={Colors.TEXT.DARK.PRIMARY}
        >
          <Text.BodyS weight={"medium"} style={{ fontWeight: "500" }}>
            <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
              {"Assigned"}
            </Text.BodyS>{" "}
            {assigneeName}
          </Text.BodyS>
        </Button.Secondary>
      );
    },
    [assignee]
  );

  if (loadingAssignees) {
    return (
      <DropdownMenu.Loading
        options={[{ icon: true }, { icon: true }, { icon: true }]}
        renderToggle={renderDropdownButton}
        itemSize={"small"}
      />
    );
  }

  return (
    <DropdownMenu
      options={assigneeOptions}
      renderToggle={renderDropdownButton}
      itemSize={"small"}
    />
  );
}

AssigneeSelector.Loading = function AssigneeSelectorLoading(): ReactElement {
  return <Skeleton style={{ width: 120, height: 32, borderRadius: 16 }} />;
};
