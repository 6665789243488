import React from "react";
import { Colors, PropertyIcon } from "@ailo/primitives";
import { formatPropertyAddressName } from "@ailo/domains";
import { SearchBoxOption } from "@ailo/ui";
import { ActivePropertiesQuery } from "local/domain/propertyManagement";

export function formatPropertyToSearchBoxOption(
  property: ActivePropertiesQuery["properties"][0]
): SearchBoxOption {
  return {
    leftComponent: <PropertyIcon color={Colors.SMOKE} />,
    label: `${formatPropertyAddressName(property, { withSuburb: true })}`,
    size: "small",
    value: property.managementId
  };
}
