import { useAnalytics } from "@ailo/services";
import { FormFieldGroup, FormFieldRow } from "@ailo/ui";
import React, { ReactElement, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components/native";
import {
  NewManagementFormData,
  NewManagementFormFields
} from "../../NewManagementFormData";
import { AddressInput } from "./AddressInput";
import { AgreementEndDateInput } from "./AgreementEndDateInput";
import { ManagementFeePercentInput } from "./ManagementFeePercentInput";
import { StartDateInput } from "./StartDateInput";
import { tryParseToLocalDate, validateIsAfterDate } from "./utils";
import { Text } from "@ailo/primitives";
import { LocalDate } from "@ailo/date";

export function ManagementDetailsStep({
  display,
  previousManagementEndDate
}: {
  display: boolean;
  previousManagementEndDate?: string;
}): ReactElement {
  const { watch, trigger } = useFormContext<NewManagementFormData>();

  const analytics = useAnalytics();
  useEffect(() => {
    if (display === true) {
      analytics.trackScreenVisited("New Management — Agreement Details");
    }
  }, [display, analytics]);

  const agreementStartDate = tryParseToLocalDate(
    watch(NewManagementFormFields.agreementStartDate)
  );
  const agreementEndDate = watch(NewManagementFormFields.agreementEndDate);
  const minStartDate = previousManagementEndDate
    ? LocalDate.from(previousManagementEndDate).add(1, "day")
    : undefined;

  const agreementEndMinDate = useMemo((): LocalDate | undefined => {
    if (!agreementStartDate && !minStartDate) return undefined;
    if (!agreementStartDate) return minStartDate?.add(1, "day");
    if (!minStartDate) return agreementStartDate.add(1, "day");
    if (agreementStartDate.isSameOrAfter(minStartDate))
      return agreementStartDate.add(1, "day");
    return minStartDate?.add(1, "day");
  }, [minStartDate, agreementStartDate]);

  useEffect(() => {
    if (agreementEndDate) trigger(NewManagementFormFields.agreementEndDate);
  }, [agreementStartDate, trigger, agreementEndDate]);

  const validateIsAfterPreviousManagementEnd = useMemo(
    () =>
      validateIsAfterDate(
        "Select a date after the current management ends",
        minStartDate
      ),
    [minStartDate]
  );

  return (
    <FormFieldGroup
      label={"Address"}
      style={{ display: display ? undefined : "none" }}
    >
      <AddressInput />
      <Text.BodyM style={{ marginBottom: 20 }} weight={"medium"}>
        {"Agreement details"}
      </Text.BodyM>
      <StyledFormFieldRow>
        <StartDateInput
          validate={validateIsAfterPreviousManagementEnd}
          minStartDate={minStartDate}
          agreementEndDate={agreementEndDate}
        />
        <AgreementEndDateInput
          validate={{
            afterAgreementStart: validateIsAfterDate(
              "End date cannot be before start date",
              agreementStartDate
            ),
            afterPreviousManagementEnd: validateIsAfterPreviousManagementEnd
          }}
          minEndDate={agreementEndMinDate}
        />
      </StyledFormFieldRow>
      <StyledFormFieldRow cols={2}>
        <ManagementFeePercentInput />
      </StyledFormFieldRow>
    </FormFieldGroup>
  );
}

const StyledFormFieldRow = styled(FormFieldRow)`
  margin-bottom: 24px;
`;
