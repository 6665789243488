import React, { ReactElement, useCallback } from "react";
import { ConfirmModal, useToastContext } from "@ailo/ui";
import { LeaseRenewalDetails } from "./LeaseRenewalDetails";
import { useFormContext } from "react-hook-form";
import {
  LeaseRenewalFormData,
  useSubmit
} from "local/modals/LeaseRenewalModalForm/LeaseRenewalForm";
import { usePropertyScreenSidebar } from "local/tabs";
import { useAnalytics } from "@ailo/services";

export function LeaseRenewalConfirmModal({
  visible,
  onSuccess,
  onCancel,
  onError
}: {
  visible: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  onError: () => void;
}): ReactElement {
  const Toast = useToastContext();
  const analytics = useAnalytics();
  const { watch } = useFormContext<LeaseRenewalFormData>();

  const tenancyId = watch("tenancyId");
  const rentReviewChecked = watch("rentReviewChecked");
  const agreementStartDate = watch("agreementStartDate");

  const propertyScreenSidebarContext = usePropertyScreenSidebar();

  const submitSuccess = useCallback(() => {
    Toast.show({
      type: "success",
      message: "Lease renewal updated"
    });
    onSuccess();
    analytics.track("Lease Renewal Completed", {
      rentReviewCheckbox: rentReviewChecked
    });
    if (rentReviewChecked) {
      propertyScreenSidebarContext.setSidebarForm({
        type: "rentReview",
        id: tenancyId,
        mode: "create",
        prefill: { effectiveDate: agreementStartDate }
      });
    }
  }, [
    Toast,
    agreementStartDate,
    analytics,
    onSuccess,
    propertyScreenSidebarContext,
    rentReviewChecked,
    tenancyId
  ]);

  const submit = useSubmit({
    onSuccess: submitSuccess,
    onError
  });

  return (
    <ConfirmModal
      visible={visible}
      title={"Confirm Details"}
      confirmLabel={rentReviewChecked ? "Confirm & Add Rent Review" : "Confirm"}
      onConfirm={submit}
      onCancel={onCancel}
    >
      <LeaseRenewalDetails />
    </ConfirmModal>
  );
}
