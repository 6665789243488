import React from "react";
import {
  LegalEntityListItem,
  LegalEntityListItemProps
} from "./LegalEntityListItem";

export interface LegalEntityListProps {
  legalEntities: ({ id: string } & LegalEntityListItemProps["legalEntity"])[];
  hideLastItemBorder?: boolean;
  onItemPress?: (id: string, entityType: "Person" | "Company") => void;
}

export function LegalEntityList({
  legalEntities,
  hideLastItemBorder = true,
  onItemPress
}: LegalEntityListProps): React.ReactElement {
  return (
    <>
      {legalEntities.map((legalEntity, index) => (
        <LegalEntityListItem
          legalEntity={legalEntity}
          key={legalEntity.id}
          onPress={getOnPress(legalEntity)}
          style={{
            borderType:
              hideLastItemBorder && index === legalEntities.length - 1
                ? "none"
                : undefined
          }}
        />
      ))}
    </>
  );

  function getOnPress(
    legalEntity: LegalEntityListProps["legalEntities"][0]
  ): (() => void) | undefined {
    if (legalEntity.__typename === "Person") {
      return onItemPress
        ? (): void => onItemPress(legalEntity.id, legalEntity.__typename)
        : undefined;
    }

    return onItemPress
      ? (): void => onItemPress(legalEntity.id, legalEntity.__typename)
      : undefined;
  }
}
