import { Colors } from "@ailo/primitives";
import { Badge, LocalDate } from "@ailo/ui";
import { ActionDetailsFragment } from "local/graphql";
import React, { ReactNode } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { ActionCardFooterAvatar } from "./ActionCardFooterAvatar";
import { ActionCardFooterCalendar } from "./ActionCardFooterCalendar";

export function ActionCardFooter({
  action,
  additionalStatus
}: {
  action: ActionDetailsFragment;
  additionalStatus?: ReactNode;
}): React.ReactElement {
  const dueDateColor = LocalDate.from(action.dueDate).isBefore(
    LocalDate.from(LocalDate.today())
  )
    ? Colors.TEXT.RED
    : Colors.TEXT.DARK.SECONDARY;

  return (
    <Container>
      <LeftContainer>
        <Badge style={{ width: "fit-content" }}>{action.typeLabel}</Badge>
        {additionalStatus}
        <ActionCardFooterCalendar
          dueDate={action.dueDate}
          color={dueDateColor}
          style={{ marginLeft: 16 }}
        />
      </LeftContainer>
      <ActionCardFooterAvatar assignee={action.assignee} />
    </Container>
  );
}

const Container = styled(View)`
  flex-direction: row;
`;
const LeftContainer = styled(View)`
  flex-direction: row;
  align-items: flex-end;
`;
