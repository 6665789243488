import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";

export function usePropertySearchAnalytics(): { searchVersion: "2.0" | "1.0" } {
  const hasFeaturePropertySearchFromSearchService = useHasFeature(
    PlatformFeatureId.PropertySearchFromSearchService
  );

  return {
    searchVersion: hasFeaturePropertySearchFromSearchService ? "2.0" : "1.0"
  };
}
