import { Text } from "@ailo/primitives";
import {
  FormFieldRow,
  Money,
  MoneyInterface,
  PercentInputFormField
} from "@ailo/ui";
import Big from "big.js";
import { CreateOneOffFeeFormData } from "local/domain/expense";
import { FeeBlueprintChargeType, TaxTreatment } from "local/graphql";
import React, { ReactElement, useCallback, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ChargeTypeInput } from "./ChargeTypeInput";
import { PriceInput } from "./PriceInput";

interface Props {
  weeklyRent?: Money;
}

const PERCENT_DECIMAL_PLACES = 4;

export function RentBasedFeeInputs({ weeklyRent }: Props): ReactElement {
  const { control, errors, watch, setValue } =
    useFormContext<CreateOneOffFeeFormData>();
  const feeBlueprint = watch("feeBlueprint");
  const chargeType = watch("chargeType");
  const totalAmount = watch("totalAmount");
  const totalAmountMoney = totalAmount ? Money.from(totalAmount) : undefined;

  const {
    oneWeekRentPercentageIncludingTax: initialPercentageIncludingTax,
    taxTreatment
  } = feeBlueprint;

  const rentBasedFee =
    chargeType === FeeBlueprintChargeType.OneWeekRentPercentage;

  const onChangePrice = (price: MoneyInterface | null): void => {
    const oneWeekRentPercentage =
      weeklyRent && price
        ? new Big(price.cents)
            .div(weeklyRent.cents)
            .round(PERCENT_DECIMAL_PLACES)
            .toNumber()
        : undefined;
    setValue("oneWeekRentPercentage", oneWeekRentPercentage);
  };

  const onChangeOneWeekRentPercentage = useCallback(
    (percentage?: number): void => {
      if (!weeklyRent) return;
      const price = Money.fromCents(
        new Big(weeklyRent.cents).mul(percentage ?? 0).toNumber()
      );
      setValue("totalAmount", price);
    },
    [setValue, weeklyRent]
  );

  useEffect(() => {
    setValue("oneWeekRentPercentage", initialPercentageIncludingTax);
    onChangeOneWeekRentPercentage(initialPercentageIncludingTax);
  }, [setValue, initialPercentageIncludingTax, onChangeOneWeekRentPercentage]);

  return (
    <>
      <ChargeTypeInput weeklyRent={weeklyRent} style={{ marginTop: 24 }} />

      <FormFieldRow
        cols={2}
        style={{
          marginTop: 24,
          display: rentBasedFee
            ? // Hide input but still render it so this input's value can still be
              // changed whenever `oneWeekRentPercentage` changes
              "none"
            : undefined
        }}
      >
        <PriceInput onChange={onChangePrice} />
      </FormFieldRow>

      <FormFieldRow
        cols={2}
        style={{ display: !rentBasedFee ? "none" : undefined, marginTop: 24 }}
      >
        <Controller
          control={control}
          name={"oneWeekRentPercentage"}
          defaultValue={0}
          render={({ value, onChange, onBlur }) => (
            <PercentInputFormField
              {...{ value, onBlur }}
              onChange={(value) => {
                onChange(value);
                onChangeOneWeekRentPercentage(value);
              }}
              label={"Price (incl. GST)"}
              error={errors.oneWeekRentPercentage?.message}
              format={"decimal"}
              decimalPlaces={PERCENT_DECIMAL_PLACES}
              endAdornment={<Text.BodyL>{"weeks of rent"}</Text.BodyL>}
              helperText={
                totalAmountMoney
                  ? taxTreatment === TaxTreatment.NoTax
                    ? `Total: ${totalAmountMoney.format()} (no GST)`
                    : `Total: ${totalAmountMoney.format()} (including ${totalAmountMoney
                        .divide(11)
                        .format()} GST)`
                  : undefined
              }
            />
          )}
          rules={{
            validate: (price): boolean | string =>
              rentBasedFee && (price === undefined || price === null)
                ? "Price is required."
                : true
          }}
        />
      </FormFieldRow>
    </>
  );
}
