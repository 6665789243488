import { AiloSentry, useApolloClient, useUserStorage } from "@ailo/services";
import { useCallback, useEffect } from "react";
import { useMountedState } from "@ailo/primitives";
import {
  GetTeamsByOrganisationDocument,
  GetTeamsByOrganisationQuery
} from "local/graphql";
import { useCurrentAgencyOrg } from "local/common";
import { sortBy } from "lodash";
import { ApolloQueryResult } from "@apollo/client";
import { ChatTeamId, DEFAULT_CHAT_TEAM_ID } from "@ailo/domains";
import { AiloRN } from "@ailo/ailorn";

const PROPERTY_LIST_SELECTED_TEAMS = "propertyListSelectedTeam";
const PROPERTY_LIST_UNASSIGNED_SELECTED = "propertyListUnassignedSelected";

const CHAT_SELECTED_TEAM = "chatSelectedTeam";

export function useStoredSelectedTeams(): {
  propertyListUnassignedSelected: boolean;
  updatePropertyListUnassignedSelected: (selected: boolean) => void;
  propertyListSelectedTeamIds: string[];
  updatePropertyListTeamIds: (teamIds: string[]) => void;
  chatSelectedTeamId: ChatTeamId;
  updateChatTeamId: (teamId: ChatTeamId) => void;
  initialized: boolean;
} {
  const userStorage = useUserStorage();
  const [propertyListUnassignedSelected, setPropertyListUnassignedSelected] =
    useMountedState<boolean>(false);
  const [propertyListSelectedTeamIds, setPropertyListSelectedTeamIds] =
    useMountedState<string[]>([]);
  const [chatSelectedTeamId, setChatSelectedTeamId] =
    useMountedState<ChatTeamId>(DEFAULT_CHAT_TEAM_ID);
  const [initialized, setInitialized] = useMountedState(false);
  const client = useApolloClient();
  const agencyOrg = useCurrentAgencyOrg();

  const updatePropertyListTeamIds = useCallback(
    (teamIds: string[]): void => {
      userStorage.setItem(PROPERTY_LIST_SELECTED_TEAMS, teamIds);
      setPropertyListSelectedTeamIds(teamIds);
    },
    [setPropertyListSelectedTeamIds, userStorage]
  );

  const updatePropertyListUnassignedSelected = useCallback(
    (selected: boolean): void => {
      userStorage.setItem(PROPERTY_LIST_UNASSIGNED_SELECTED, selected);
      setPropertyListUnassignedSelected(selected);
    },
    [setPropertyListUnassignedSelected, userStorage]
  );

  const updateChatTeamId = useCallback(
    (team: ChatTeamId): void => {
      userStorage.setItem(CHAT_SELECTED_TEAM, team);
      setChatSelectedTeamId(team);
    },
    [setChatSelectedTeamId, userStorage]
  );

  const initializePropertyListTeams = useCallback(
    async (
      organisationTeamsQuery: Promise<
        ApolloQueryResult<GetTeamsByOrganisationQuery>
      >
    ): Promise<void> => {
      try {
        const storedPropertyListTeams = await userStorage.getItem<
          (string | { id: string })[]
        >(PROPERTY_LIST_SELECTED_TEAMS);
        const { data } = await organisationTeamsQuery;

        if (storedPropertyListTeams == null) {
          const teamsToStoreIntoCache = (data.organisation?.teams ?? [])
            .filter((team) => team.myTeam)
            .map((team) => ({ id: team.id, name: team.name }));
          updatePropertyListTeamIds(teamsToStoreIntoCache.map(({ id }) => id));
        } else {
          setPropertyListSelectedTeamIds(
            storedPropertyListTeams.map((item) =>
              typeof item === "string" ? item : item.id
            )
          );
        }

        const storedUnassignedSelectedFlag = await userStorage.getItem<boolean>(
          PROPERTY_LIST_UNASSIGNED_SELECTED
        );

        setPropertyListUnassignedSelected(
          !!(
            storedUnassignedSelectedFlag &&
            data.managementsForOrg?.pageInfo.total
          )
        );
      } catch (error) {
        AiloSentry.captureException(error);
        setPropertyListSelectedTeamIds([]);
      }
    },
    [
      setPropertyListSelectedTeamIds,
      updatePropertyListTeamIds,
      setPropertyListUnassignedSelected,
      userStorage
    ]
  );

  const initializeChatTeam = useCallback(
    async (
      organisationTeamsQuery: Promise<
        ApolloQueryResult<GetTeamsByOrganisationQuery>
      >
    ): Promise<void> => {
      try {
        const storedChatTeam = await userStorage.getItem<
          string | { id: string }
        >(CHAT_SELECTED_TEAM);

        if (storedChatTeam == null) {
          const { data } = await organisationTeamsQuery;
          const teamToStoreIntoCache = sortBy(data.organisation?.teams ?? [], [
            (team): number => (team.myTeam ? 0 : 1),
            "name"
          ])[0];
          updateChatTeamId(teamToStoreIntoCache?.id ?? DEFAULT_CHAT_TEAM_ID);
        } else {
          setChatSelectedTeamId(
            typeof storedChatTeam === "string"
              ? storedChatTeam
              : storedChatTeam.id
          );
        }
      } catch (error) {
        AiloSentry.captureException(error);
        setChatSelectedTeamId(DEFAULT_CHAT_TEAM_ID);
      }
    },
    [setChatSelectedTeamId, updateChatTeamId, userStorage]
  );

  const initializeSelectedTeams = useCallback(async (): Promise<void> => {
    const allTeamsInOrgPromise = client.query<GetTeamsByOrganisationQuery>({
      variables: {
        organisationId: agencyOrg.id,
        organisationAilorn: AiloRN.of(
          "authz:organisation",
          agencyOrg.id
        ).toString()
      },
      query: GetTeamsByOrganisationDocument
    });
    await initializePropertyListTeams(allTeamsInOrgPromise);
    await initializeChatTeam(allTeamsInOrgPromise);

    setInitialized(true);
  }, [
    agencyOrg.id,
    client,
    initializeChatTeam,
    initializePropertyListTeams,
    setInitialized
  ]);

  useEffect(() => {
    void initializeSelectedTeams();
  }, [initializeSelectedTeams]);

  return {
    propertyListUnassignedSelected,
    updatePropertyListUnassignedSelected,
    propertyListSelectedTeamIds,
    updatePropertyListTeamIds,
    chatSelectedTeamId,
    updateChatTeamId,
    initialized
  };
}
