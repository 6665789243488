import React, { ReactElement, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { StyleProp, ViewStyle } from "react-native";
import {
  ManagementDetailsStep,
  OwnerDetailsStep,
  ReviewManagementDetailsStep,
  getTotalShares
} from "./AddOrEditManagementFormSteps";
import { MultiStepForm } from "local/common";
import { isPresent } from "ts-is-present";
import { ManagementFragment } from "local/graphql";
import { useCreateManagement } from "./useCreateManagement";
import {
  AddressFieldsList,
  NewManagementFormData,
  NewManagementFormFields
} from "./NewManagementFormData";

interface Props {
  onManagementCreated?(management: ManagementFragment): void;
  onError?(): void;
  onCancel?(): void;
  style?: StyleProp<ViewStyle>;
}

type StepIds = "ManagementDetails" | "Owners" | "Review";

type Step = {
  stepId: StepIds;
  render: (visible: boolean) => React.ReactElement;
};

const step1Fields = [
  NewManagementFormFields.agreementStartDate,
  NewManagementFormFields.agreementEndDate,
  NewManagementFormFields.managementFeePercent,
  ...AddressFieldsList.map(
    (addressField) => `${NewManagementFormFields.address}.${addressField}`
  )
];

export function NewManagementForm({
  onManagementCreated,
  onError,
  onCancel,
  style
}: Props): ReactElement {
  const [currentStepNumber, setCurrentStep] = useState<number>(1);
  const form = useFormContext<NewManagementFormData>();

  const {
    reset,
    trigger,
    watch,
    formState,
    handleSubmit,
    getValues,
    setError
  } = form;
  const { isSubmitting, isDirty } = formState;

  const ownerArray = watch("owners");
  const totalShares = getTotalShares(getValues().owners);

  const submit = handleSubmit(
    useSubmit({
      onCompleted: async (management: ManagementFragment) => {
        await onManagementCreated?.(management);
        reset();
        setCurrentStep(1);
      },
      onError
    })
  );

  const cancel = useCallback(() => {
    reset();
    onCancel?.();
    setCurrentStep(1);
  }, [onCancel, reset]);

  const renderStepManagementDetails = useCallback(
    (visible: boolean): React.ReactElement => (
      <ManagementDetailsStep
        key={"New-Management-Form-Step-ManagementDetails"}
        display={visible}
      />
    ),
    []
  );

  const renderStepOwners = useCallback(
    (visible: boolean): React.ReactElement => (
      <OwnerDetailsStep
        key={"New-Management-Form-Step-Owners"}
        display={visible}
      />
    ),
    []
  );

  const renderStepReview = useCallback(
    (visible: boolean): React.ReactElement => (
      <ReviewManagementDetailsStep
        key={"New-Management-Form-Step-Review"}
        display={visible}
        onEditAgreementPress={(): void => setCurrentStep(1)}
        onEditOwnersPress={(): void => setCurrentStep(2)}
      />
    ),
    []
  );

  const steps = [
    {
      stepId: "ManagementDetails",
      render: renderStepManagementDetails
    },
    {
      stepId: "Owners",
      render: renderStepOwners
    },
    {
      stepId: "Review",
      render: renderStepReview
    }
  ].filter(isPresent) as Step[];

  const onNextButtonPress = useCallback(
    async (newStepNumber: number) => {
      if (newStepNumber < currentStepNumber) {
        setCurrentStep(newStepNumber);
        return;
      }
      const currentStepId = steps[currentStepNumber - 1].stepId;

      switch (currentStepId) {
        case "ManagementDetails": {
          const step1Valid = await trigger(step1Fields);
          if (step1Valid) {
            setCurrentStep(newStepNumber);
          }
          break;
        }
        case "Owners": {
          if (!ownerArray || ownerArray.length === 0) {
            setError("ownersError", {
              type: "required",
              message: "Please add at least one owner for a new management"
            });
          } else if (totalShares === 0) {
            setError("ownersError", {
              type: "required",
              message: "Please add at least one owner with shares"
            });
          } else {
            setCurrentStep(newStepNumber);
          }
          break;
        }
        case "Review": {
          submit();
          break;
        }
      }
    },
    [
      currentStepNumber,
      steps,
      trigger,
      ownerArray,
      totalShares,
      submit,
      setError
    ]
  );

  return (
    <>
      <MultiStepForm
        style={style}
        title={"Create New Management"}
        submitButtonText={"Create Management"}
        stepValue={currentStepNumber}
        onChange={onNextButtonPress}
        onCancel={cancel}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        steps={steps.map((step) => step.render)}
        headerBackButtonDisabled
        hideCancelButton
        showFooterBackButton
      />
    </>
  );
}

function useSubmit({
  onCompleted,
  onError
}: {
  onCompleted?: (management: ManagementFragment) => void;
  onError?: () => void;
}): (data: NewManagementFormData) => Promise<void> {
  const [addNewManagement] = useCreateManagement({
    onCompleted,
    onError
  });

  return useCallback(
    async (data: NewManagementFormData) => {
      const { agreementStartDate, managementFeePercent, owners, address } =
        data;
      if (
        agreementStartDate == null ||
        managementFeePercent == null ||
        owners == null ||
        address == null
      ) {
        throw new TypeError("Required fields for new management missing");
      }

      const { unitStreetNumber, streetName, suburb, country, postcode, state } =
        address;
      if (
        unitStreetNumber == null ||
        streetName == null ||
        suburb == null ||
        country == null ||
        postcode == null ||
        state == null
      ) {
        throw new TypeError("Required fields for address missing");
      }

      return addNewManagement({
        formData: {
          ...data,
          agreementStartDate,
          managementFeePercent,
          owners,
          address: {
            unitStreetNumber,
            streetName,
            suburb,
            country,
            postcode,
            state
          }
        }
      });
    },
    [addNewManagement]
  );
}
