import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Colors, opacify, useSynchronisedLoad } from "@ailo/primitives";
import {
  didQuerySucceed,
  useInfinitelyPaginatedQuery,
  useOnFocus
} from "@ailo/services";
import { ErrorAlertScreen, ErrorModal } from "@ailo/ui";
import { ProjectFilesHeader } from "./ProjectFilesHeader";
import { ProjectFilesList } from "./ProjectFilesList";
import { useGetFilesQuery } from "./useGetFiles";
import { getFiles } from "../ProjectDetails/ProjectFiles";
import { useProjectTabFilesState } from "./useProjectTabFilesState";

interface Props {
  projectId: string;
  onDismiss: () => void;
}

export function ProjectFilesSidebar({
  projectId,
  onDismiss
}: Props): ReactElement {
  const { loading, loadingMore, hasMore, data, refetch, fetchMore, error } =
    useInfinitelyPaginatedQuery(useGetFilesQuery, (data) => data.files!, {
      variables: {
        id: projectId,
        pageSize: 12
      },
      fetchPolicy: "cache-and-network"
    });
  useOnFocus(refetch);

  const { files, addFile, removeFile, uploadError, setUploadError } =
    useProjectTabFilesState(projectId);

  const projectFiles = getFiles(data?.files);

  const isLoading = useSynchronisedLoad(loading && !data);

  if (isLoading) {
    return <ProjectFilesSidebar.Loading onDismiss={onDismiss} />;
  }

  if (!didQuerySucceed({ data, error })) {
    return (
      <ProjectFilesSidebar.Error onDismiss={onDismiss} onRetry={refetch} />
    );
  }

  return (
    <Container>
      <ProjectFilesHeader addFile={addFile} onDismiss={onDismiss} />
      <ProjectFilesList
        projectId={projectId}
        files={projectFiles}
        processingFiles={files}
        onRemoveFile={removeFile}
        loadingMore={loadingMore}
        onLoadMore={hasMore ? fetchMore : undefined}
      />
      {uploadError && (
        <ErrorModal
          title={uploadError.title}
          description={uploadError.message}
          onDismiss={() => setUploadError(undefined)}
        />
      )}
    </Container>
  );
}

ProjectFilesSidebar.Loading = function ActionSidebarLoading({
  onDismiss
}: {
  onDismiss: () => void;
}): ReactElement {
  return (
    <Container>
      <ProjectFilesHeader.Loading onDismiss={onDismiss} />
      <ProjectFilesList.Loading />
    </Container>
  );
};

ProjectFilesSidebar.Error = function ActionSidebarError({
  onDismiss,
  onRetry
}: {
  onDismiss: () => void;
  onRetry: () => void;
}): ReactElement {
  return (
    <Container>
      <ProjectFilesHeader.Loading onDismiss={onDismiss} />
      <ErrorContainer>
        <ErrorAlertScreen
          variant={"sidebar"}
          title={"There was a problem loading\nthe Project Files"}
          onRetry={onRetry}
        />
      </ErrorContainer>
    </Container>
  );
};

const Container = styled(View)`
  height: 100%;
  padding-top: 32px;
  box-shadow: 0px 2px 12px ${opacify(Colors.BLACK, 0.2)};
`;

const ErrorContainer = styled(View)`
  width: 100%
  height: 100%;
  align-items: center;
  justify-content: center;
`;
