import { useCallback, useState } from "react";
import { ProjectType } from "local/graphql";

export type FilterStateType = {
  assigneeAilorns?: string[] | null;
  projectTypes?: ProjectType[] | null;
};

export type UpdateProjectFilterStateCallback = (
  mergeState: FilterStateType
) => void;

export function useProjectListFilterState(): {
  filterState: FilterStateType;
  updateFilterState: UpdateProjectFilterStateCallback;
} {
  const [filterState, setFilterState] = useState<FilterStateType>({
    assigneeAilorns: null,
    projectTypes: null
  });

  const updateFilterState = useCallback<UpdateProjectFilterStateCallback>(
    (mergeState) => {
      setFilterState({ ...filterState, ...mergeState });
    },
    [filterState, setFilterState]
  );

  return { filterState, updateFilterState };
}
