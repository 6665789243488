import React from "react";
import { Colors, Skeleton } from "@ailo/primitives";
import {
  CellContainer,
  DateTimeWithTimeZone,
  Money,
  TextCell,
  TextCellContent
} from "@ailo/ui";
import { useGetOverdueDateQuery } from "local/graphql";
import { ErrorCell } from "./ErrorCell";
import { BasePropertyListRow } from "../BasePropertyListRow";

function OverdueDateCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetOverdueDateQuery({
    variables: { managementId }
  });

  if (loading && !data) return <Loading />;

  const liability = data?.management?.mostRecentTenancy?.liability;
  if (!liability)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const overdueAmount = Money.fromCents(liability?.overdueAmount?.cents || 1);
  const dueDate = liability.dueDate || null;

  if (dueDate == null)
    return <TextCell color={Colors.TEXT.DARK.SECONDARY}>{"—"}</TextCell>;

  const ailoDueDate =
    DateTimeWithTimeZone.fromDateSubtractingAMillisecond(dueDate);

  const shouldDisplayNumberOfDays =
    overdueAmount.isPositive && ailoDueDate.isOverdue();

  if (!shouldDisplayNumberOfDays) {
    return <TextCell>{ailoDueDate.format("DD MMM YYYY")}</TextCell>;
  }

  const overdueDays = ailoDueDate.daysBeforeToday();

  return (
    <CellContainer>
      <TextCellContent>{ailoDueDate.format("DD MMM YYYY")}</TextCellContent>
      <TextCellContent color={Colors.TEXT.DARK.SECONDARY}>
        {`${overdueDays} ${overdueDays > 1 ? "days" : "day"}`}
      </TextCellContent>
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <Skeleton
        style={{
          width: 70,
          height: 12
        }}
      />
      <Skeleton
        style={{
          width: 60,
          height: 12,
          marginTop: 8
        }}
      />
    </CellContainer>
  );
}

OverdueDateCell.Loading = Loading;

export { OverdueDateCell };
