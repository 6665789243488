import { ErrorAlertScreen } from "@ailo/ui";
import _ from "lodash";
import React, { ReactElement, ReactNode } from "react";
import { View } from "react-native";

export function ManagementOrTenancySidebarError({
  onRetry,
  children,
  type
}: {
  onRetry: () => void;
  children?: ReactNode;
  type: "manangement" | "tenancy";
}): ReactElement {
  return (
    <View style={{ flex: 1 }}>
      {children}
      <View style={{ flex: 1, justifyContent: "center" }}>
        <ErrorAlertScreen
          variant={"sidebar"}
          title={`There was a problem loading\n${_.upperFirst(type)} details`}
          onRetry={onRetry}
        />
      </View>
    </View>
  );
}
