import React, { useMemo } from "react";
import styled from "styled-components/native";
import { Card } from "@ailo/ui";
import { HealthIssue } from "./HealthIssue";
import { isValidHealthIssue, MigrationHealthIssue } from "./isValidHealthIssue";
import { HealthIssuesCountHeader } from "./HealthIssuesCountHeader";

interface Props {
  issues?: Record<string, any>;
}

export function HealthIssuesCard({ issues = {} }: Props): React.ReactElement {
  const filteredHealthIssues = useMemo(() => {
    return Object.values(issues).reduce<MigrationHealthIssue[]>(
      (acc, healthIssue) => {
        return isValidHealthIssue(healthIssue) ? [...acc, healthIssue] : acc;
      },
      []
    );
  }, [issues]);

  return (
    <StyledCard borderRadius={8}>
      <HealthIssuesCountHeader issues={issues} />
      {filteredHealthIssues.map((issue, i) => (
        <HealthIssue
          key={i}
          text={issue.description ?? issue.field ?? ""}
          subtext={issue.value}
        />
      ))}
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  margin: 0;
`;
