import { Money, Separator } from "@ailo/ui";
import { ClaimTenancyData } from "local/domain/bond";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { Footer } from "../Footer";
import { ClaimActionsList } from "./ClaimActionsList";
import { ClaimStatus } from "./ClaimStatus";

interface Props {
  amountClaimed: Money;
  data: ClaimTenancyData;
  onClose: () => void;
}

function ClaimDetails({ amountClaimed, data, onClose }: Props): ReactElement {
  return (
    <View
      style={{
        flex: 1,
        alignItems: "flex-end",
        flexDirection: "column",
        alignSelf: "flex-end"
      }}
    >
      <View
        style={{
          flex: 1,
          width: 620
        }}
      >
        <ClaimStatus amountClaimed={amountClaimed} />
        <Separator style={{ marginLeft: 0, width: "100%" }} />
        <ClaimActionsList data={data} amountClaimed={amountClaimed} />
        <Footer onPress={onClose} />
      </View>
    </View>
  );
}

export { ClaimDetails };
