import React, { ReactElement } from "react";
import { ErrorAlertScreen } from "@ailo/ui";
import { ActionActivityDepositSummaryCardBase } from "./ActionActivityDepositSummaryCardBase";
import { useGetTenancyDepositData } from "local/domain/propertyManagement";

type Props = {
  tenancyId: string;
  managementId: string;
};

export const ActionActivityDepositSummaryCard = ({
  tenancyId,
  managementId
}: Partial<Props>): ReactElement | null => {
  if (!tenancyId || !managementId) {
    return <ActionActivityDepositSummaryCardBase />;
  }

  return (
    <ActionActivityDepositSummaryCardWithData
      tenancyId={tenancyId}
      managementId={managementId}
    />
  );
};

const ActionActivityDepositSummaryCardWithData = ({
  tenancyId,
  managementId
}: Props): ReactElement | null => {
  const { data, error, loading, refetch } = useGetTenancyDepositData(tenancyId);

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <ErrorAlertScreen
        variant={"medium"}
        title={"There was a problem loading\nTenancy initial payment"}
        onRetry={refetch}
        style={{ paddingVertical: 40 }}
      />
    );
  }

  return (
    <ActionActivityDepositSummaryCardBase
      depositData={data}
      managementId={managementId}
    />
  );
};
