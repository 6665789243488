import { Colors, Text } from "@ailo/primitives";
import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

interface Props {
  loading: boolean;
  error?: Error;
  subject?: string;
  numberOfReplies?: number;
}

export function ChatSidebarTitle({
  loading,
  error,
  subject,
  numberOfReplies
}: Props): ReactElement {
  if (loading) {
    return (
      <Container>
        <Text.Loading variant={"BodyM"} width={100} />
        <Text.Loading variant={"BodyS"} />
      </Container>
    );
  }

  if (error) {
    return <Container />;
  }

  return (
    <Container>
      <Text.BodyM numberOfLines={1} weight={"medium"}>
        {subject}
      </Text.BodyM>
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
        {(numberOfReplies ?? 0) + 1}{" "}
        {(numberOfReplies ?? 0) + 1 === 1 ? "message" : "messages"}
      </Text.BodyS>
    </Container>
  );
}

const Container = styled(View)`
  flex-direction: column;
  flex: 1;
`;
