import React, { ReactElement } from "react";
import { useGetEndingManagementDetailsQuery } from "local/graphql";
import { SplitScreenInfoPanel } from "local/common";
import { ManagementDetailsUI } from "./ManagementDetailsUI";

export function ManagementDetails({
  managementId: managementId
}: {
  managementId: string;
}): ReactElement | null {
  const { data, loading, error } = useGetEndingManagementDetailsQuery({
    variables: { managementId },
    fetchPolicy: "cache-and-network"
  });

  if (!data && loading) {
    return <SplitScreenInfoPanel.Loading />;
  }

  const management = data?.management;

  if (!management || error) {
    return null;
  }

  return <ManagementDetailsUI management={management} />;
}
