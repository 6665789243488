import { Colors, opacify } from "@ailo/primitives";
import { Separator } from "@ailo/ui";
import React from "react";
import { View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { CardColorGradient } from "./CardColorGradient";

export interface ProfileCardProps {
  isTenant?: boolean;
  isInvestor?: boolean;
  colorGradientContent?: React.ReactNode;
  children?: React.ReactNode;
  style?: ProfileCardStyle;
}

export interface ProfileCardStyle
  extends Pick<
    ViewStyle,
    | "margin"
    | "marginVertical"
    | "marginHorizontal"
    | "marginLeft"
    | "marginRight"
    | "marginTop"
    | "marginBottom"
    | "paddingLeft"
  > {}

export function BaseProfileCard({
  isTenant,
  isInvestor,
  colorGradientContent,
  children,
  style
}: ProfileCardProps): React.ReactElement {
  return (
    <Container style={style}>
      <CardColorGradient
        isTenant={isTenant}
        isInvestor={isInvestor}
        style={{ paddingBottom: 12, paddingRight: 16 }}
      >
        {colorGradientContent}
      </CardColorGradient>
      <Separator style={{ marginLeft: 0, marginBottom: 20 }} />
      {children}
    </Container>
  );
}

const Container = styled(View)`
  margin-top: 20px;
  padding-bottom: 16px;
  padding-left: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 4px ${opacify(Colors.SPACE_BLACK, 0.2)};
`;

BaseProfileCard.Loading = Loading;

function Loading({
  style,
  colorGradientContent,
  children
}: {
  style?: ProfileCardStyle;
  colorGradientContent?: React.ReactNode;
  children?: React.ReactNode;
}): React.ReactElement {
  return (
    <Container style={style}>
      <CardColorGradient style={{ paddingBottom: 20 }}>
        {colorGradientContent}
      </CardColorGradient>
      <Separator style={{ marginLeft: 0, marginBottom: 16 }} />
      {children}
    </Container>
  );
}
