import React, { PropsWithChildren, ReactElement, useEffect } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  UseFormMethods
} from "react-hook-form";
import { createContext, useContext } from "reactn";
import {
  RentReviewFields,
  RentReviewFormData,
  RentReviewPrefill
} from "./RentReviewFormData";

const RentReviewFormContext = createContext<RentReviewFormData | undefined>(
  undefined
);

export function useRentReviewForm(): UseFormMethods<RentReviewFields> & {
  data: RentReviewFormData;
} {
  const formData = useContext(RentReviewFormContext);
  const formMethods = useFormContext<RentReviewFields>();

  if (!formData)
    throw new Error(
      `useRentReviewForm must be called from within RentReviewFormProvider`
    );

  return { ...formMethods, data: formData };
}

export function RentReviewFormProvider({
  data,
  prefill = {},
  children
}: PropsWithChildren<{
  data: RentReviewFormData;
  prefill?: RentReviewPrefill;
}>): ReactElement {
  const formMethods = useForm<RentReviewFields>({
    mode: "onChange",
    shouldFocusError: true
  });

  const { setValue, register } = formMethods;

  register({
    name: RentReviewFields.period,
    value: prefill.period
  });

  useEffect(() => {
    if (prefill.effectiveDate)
      setValue(RentReviewFields.effectiveDate, prefill.effectiveDate, {
        shouldValidate: true
      });
    if (prefill.scheduledRentReviewDate)
      setValue(
        RentReviewFields.scheduledRentReviewDate,
        prefill.scheduledRentReviewDate,
        {
          shouldValidate: true
        }
      );
    if (prefill.amount)
      setValue(RentReviewFields.amount, prefill.amount, {
        shouldValidate: true
      });
  }, [
    prefill.amount,
    prefill.effectiveDate,
    prefill.period,
    prefill.scheduledRentReviewDate,
    setValue
  ]);

  return (
    <FormProvider {...formMethods}>
      <RentReviewFormContext.Provider value={data}>
        {children}
      </RentReviewFormContext.Provider>
    </FormProvider>
  );
}
