import { Colors, opacify, SFC, Text } from "@ailo/primitives";
import { getLabelForTenancyVacatingReason } from "local/domain/propertyManagement";
import { VacatingReason } from "local/graphql";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { isPresent } from "ts-is-present";
import { Section } from "./Section";
import { LocalDate } from "@ailo/date";

interface Props {
  endDate: LocalDate;
  vacatingReasons?: VacatingReason;
  vacatingNotes?: string;
}

export const EndOfTenancySection: SFC<Props> = ({
  style,
  endDate,
  vacatingReasons,
  vacatingNotes
}) => {
  const descriptionListItems = [
    {
      label: "End date",
      description: endDate.format("D MMM YYYY")
    },
    vacatingReasons
      ? {
          label: "Type",
          description: getLabelForTenancyVacatingReason(vacatingReasons)
        }
      : undefined
  ].filter(isPresent);

  return (
    <>
      <Section
        style={style}
        title={"End of Tenancy"}
        descriptionListItems={descriptionListItems}
      />
      {vacatingNotes && (
        <View style={{ marginTop: 8 }}>
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{"Note"}</Text.BodyS>
          <VacatingNotesContainer>
            <Text.BodyS>{vacatingNotes}</Text.BodyS>
          </VacatingNotesContainer>
        </View>
      )}
    </>
  );
};

const VacatingNotesContainer = styled(View)`
  padding: 15px 12px;
  border-radius: 4px;
  border: 1px solid ${opacify(Colors.SPACE_BLACK, 0.2)};
  background-color: ${Colors.CLOUD};
  margin-top: 12px;
`;
