import React, { ComponentProps, ReactElement } from "react";
import { isPresent } from "ts-is-present";
import { CardProps } from "@ailo/ui";
import { didQueryNotLoadYet, didQuerySucceed } from "@ailo/services";
import { ErrorCard } from "local/domain/propertyManagement";
import { EmptyCardContent } from "../EmptyCardContent";
import {
  PlatformFeatureId,
  useGetManagementProjectsQuery
} from "local/graphql";
import { ViewAllButton } from "local/common";
import {
  ProjectListItem,
  useHandleCreateProjectButton
} from "local/domain/project";
import { ProjectList } from "local/domain/project/ProjectList";
import { useHasFeature } from "@ailo/domains";
import {
  ProjectsPropertyContentCard,
  ProjectsPropertyContentCardLoading
} from "./ProjectsPropertyContentCard";

interface ProjectsCardProps {
  style?: CardProps["style"];
  managementId: string;
  itemsLimit?: number;
  onProjectPress: ComponentProps<typeof ProjectListItem>["onPress"];
  onViewMoreProjectPress: () => void;
}

export function ProjectsCard({
  onProjectPress,
  onViewMoreProjectPress,
  managementId,
  itemsLimit = 3,
  style
}: ProjectsCardProps): ReactElement | null {
  const hasTruncateProjectsListFeature = useHasFeature(
    PlatformFeatureId.TruncateProjectList
  );
  const { onCreateProjectPress } = useHandleCreateProjectButton(managementId);

  const projectsResult = useGetManagementProjectsQuery({
    variables: { managementId }
  });

  if (didQueryNotLoadYet(projectsResult)) {
    return (
      <ProjectsPropertyContentCardLoading>
        <ProjectList.Loading itemsLimit={itemsLimit} />
      </ProjectsPropertyContentCardLoading>
    );
  }

  if (!didQuerySucceed(projectsResult)) {
    return (
      <ErrorCard
        cardTitle={"Projects"}
        onRetry={projectsResult.refetch}
        style={style}
      />
    );
  }

  const projectItems =
    projectsResult.data.management?.projects?.items?.filter(isPresent) ?? [];

  if (projectItems.length === 0) {
    return (
      <ProjectsPropertyContentCard>
        <EmptyCardContent
          text={"Start a new project for this property"}
          buttonText={"Create project"}
          onButtonPress={onCreateProjectPress}
        />
      </ProjectsPropertyContentCard>
    );
  }

  return (
    <ProjectsPropertyContentCard onCreateProjectPress={onCreateProjectPress}>
      <ProjectList
        itemsLimit={hasTruncateProjectsListFeature ? itemsLimit : undefined}
        projectItems={projectItems}
        onProjectPress={onProjectPress}
      />

      {hasTruncateProjectsListFeature &&
        !!itemsLimit &&
        projectItems.length > itemsLimit && (
          <ViewAllButton
            onPress={onViewMoreProjectPress}
            style={{ marginHorizontal: 16, marginVertical: 16 }}
          />
        )}
    </ProjectsPropertyContentCard>
  );
}
