import styled from "styled-components/native";
import { View } from "react-native";

export const MainFormContainer = styled(View)`
  width: 100%;
  height: 500px;
  padding-left: 32px;
  padding-top: 32px;
  padding-right: 32px;
`;
