import { AvatarContainer, Badge, Card, TickButton, Tooltip } from "@ailo/ui";
import { useLinkProps } from "@react-navigation/native";
import { ActionDetailsFragment, ActionStatus } from "local/graphql";
import { useActionStatusContext } from "local/tabs/projects/actions/ActionStatusContext";
import React from "react";

type Props = {
  action: ActionDetailsFragment;
  projectId: string;
  hasTitleSeparator?: boolean;
  titleRight?: React.ReactNode;
  children: React.ReactNode;
};
export function BaseActionCard({
  action,
  projectId,
  hasTitleSeparator,
  titleRight,
  children
}: Props): React.ReactElement {
  const linkProps = useLinkProps({
    to: `/projects/${projectId}/?actionId=${action.id}`
  });
  const { handleActionStatusChange } = useActionStatusContext();
  const isDone = action.status === ActionStatus.Done;

  return (
    <Card
      variant={"list-card"}
      title={action.name}
      titleRight={titleRight}
      hasTitleSeparator={hasTitleSeparator}
      borderRadius={8}
      icon={
        <Tooltip tooltipContent={isDone ? "Mark as active" : "Mark as done"}>
          <TickButton
            iconSize={"small"}
            done={isDone}
            onPress={handleActionStatusChange}
          />
        </Tooltip>
      }
      style={{ marginBottom: 12, maxWidth: CARD_MAX_WIDTH }}
      {...linkProps}
    >
      {children}
    </Card>
  );
}

BaseActionCard.Loading = function BaseActionCardLoading(): React.ReactElement {
  return (
    <Card.Loading
      variant={"list-card"}
      titleWidth={120}
      style={{ marginBottom: 12, maxWidth: CARD_MAX_WIDTH }}
      icon={<AvatarContainer.Loading size={20} />}
    >
      <Badge.Loading width={60} />
    </Card.Loading>
  );
};

const CARD_MAX_WIDTH = 508;
