import React, { ReactElement, useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";
import { PersonDisplayDetailsFragment } from "local/graphql";
import { ActionFormBody, ActionFormBodyType } from "./ActionFormBody";
import { MultiStepForm } from "local/common";
import { ActionFormContext } from "./ActionFormContext";
import { ActionFormData } from "./ActionFormData";

interface Props {
  type: ActionFormBodyType;
  initialValue?: ActionFormData;
  style?: StyleProp<ViewStyle>;
  onSubmit(data: ActionFormData): void;
  onCancel(): void;
  loading: boolean;
  assignees: PersonDisplayDetailsFragment[];
  onValueUpdated: (
    fieldName: string,
    isValid: boolean,
    formValues: ActionFormData
  ) => void;
  onFocus: () => void;
  onFormSubmitting: (isSubmitting: boolean) => void;
}

export function ActionForm({ ...props }: Props): React.ReactElement {
  return (
    <ActionFormContext initialValue={props.initialValue}>
      <ActionMultiStepForm {...props} />
      {";"}
    </ActionFormContext>
  );
}

function ActionMultiStepForm({
  type,
  style,
  onSubmit,
  onCancel,
  loading,
  assignees,
  onValueUpdated,
  onFocus,
  onFormSubmitting
}: Props): ReactElement {
  const { formState, handleSubmit, getValues } =
    useFormContext<ActionFormData>();

  const submit = handleSubmit(onSubmit);
  const cancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const { isSubmitting, isDirty, errors } = formState;

  useEffect(() => {
    onFocus();
  }, [onFocus]);

  useEffect(() => {
    onFormSubmitting(isSubmitting);
  }, [isSubmitting, onFormSubmitting]);

  const onFieldUpdated = useCallback(
    (fieldName: keyof ActionFormData) => {
      onValueUpdated(fieldName, !errors[fieldName], getValues());
    },
    [errors, getValues, onValueUpdated]
  );

  const renderStep = useCallback(
    (visible: boolean): React.ReactElement => (
      <View
        style={{ display: visible ? undefined : "none" }}
        key={"CreateActionFormStep"}
      >
        {loading ? (
          <ActionFormBody.Loading type={type} />
        ) : (
          <ActionFormBody
            type={type}
            key={"Action-Form-Step"}
            assignees={assignees}
            onFieldUpdated={onFieldUpdated}
          />
        )}
      </View>
    ),
    [assignees, loading, onFieldUpdated, type]
  );

  return (
    <MultiStepForm
      style={style}
      title={type === "create" ? "Create action" : "Edit action"}
      submitButtonText={type === "create" ? "Create action" : "Save changes"}
      submitButtonVisible={true}
      stepValue={1}
      onChange={() => null}
      onSubmit={submit}
      onCancel={cancel}
      isDirty={isDirty}
      submitButtonDisabled={!isDirty}
      steps={[renderStep]}
    />
  );
}
