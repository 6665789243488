import React from "react";
import { Platform, View } from "react-native";
import { Colors, ProjectImage, Text } from "@ailo/primitives";
import { useEnvironment } from "@ailo/services";

export function EmptyState(): React.ReactElement {
  const { PROJECTS_LIST_HELP_URL } = useEnvironment();

  return (
    <View
      style={{
        padding: 32,
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <ProjectImage />
      <Text.BodyL weight={"medium"} style={{ marginTop: 20, marginBottom: 8 }}>
        {"Open Projects"}
      </Text.BodyL>
      <Text.BodyS
        style={{ color: Colors.TEXT.DARK.SECONDARY, marginBottom: 16 }}
      >
        {"You have no open projects to manage"}
      </Text.BodyS>
      <Text.BodyM
        onPress={() =>
          Platform.OS === "web" && window.open(PROJECTS_LIST_HELP_URL, "_blank")
        }
        color={Colors.AILO_RED}
        weight={"medium"}
      >
        {"Learn more about projects"}
      </Text.BodyM>
    </View>
  );
}
