import React, { useMemo } from "react";
import { SelectInput } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";

export const LegalEntitySwitcher: React.FC<{
  onChange: (legalEntityId: string) => void;
  legalEntityId?: string;
}> = ({ onChange, legalEntityId }) => {
  const currentAgencyOrg = useCurrentAgencyOrg();
  const legalEntities = currentAgencyOrg.legalEntities;

  const sortedLegalEntityDropDownOptions = useMemo(
    () =>
      legalEntities
        .map((legalEntity) => ({
          value: legalEntity.id,
          label: legalEntity.registeredEntityName || "Unnamed Company",
          disabled: !legalEntity.currentUserPermissions.includes(
            "legal_entity:financial:view"
          )
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [legalEntities]
  );

  return (
    <SelectInput
      options={sortedLegalEntityDropDownOptions}
      controlStyle={{ width: 286, height: 52 }}
      value={sortedLegalEntityDropDownOptions.find(
        (option): boolean => option.value === legalEntityId
      )}
      onChange={(option): void => {
        option && onChange(option.value);
      }}
      isSearchable={false}
    />
  );
};
