import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import {
  GetLeaseRenewalDataQueryVariables,
  useGetLeaseRenewalDataQuery,
  AllowedOperations
} from "local/graphql";

export interface LeaseRenewalData {
  currentAgreementStartDate?: string;
  currentAgreementFixedTermEndDate?: string;
  currentAgreementIsAllowedToLapse: boolean;
  nextTenancyAgreement?: {
    id: string;
    startDate: string;
    fixedTermEndDate?: string;
    allowedToLapseAt: boolean | null;
  };
  tenancyEndDate?: string;
  rentReviewAllowed: boolean;
  leaseReviewAllowedOperation: AllowedOperations;
}

export function useGetLeaseRenewalData(
  tenancyId: string
): MappedQueryResult<LeaseRenewalData, GetLeaseRenewalDataQueryVariables> {
  const result = useGetLeaseRenewalDataQuery({
    variables: { tenancyId }
  });

  return useMappedQueryResult(result, (data) => {
    const tenancy = data?.tenancy;

    if (!tenancy) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} not found`
      });
    }

    const {
      endDate,
      mostRecentTenancyAgreement,
      nextTenancyAgreement,
      rentReviewAllowed,
      leaseReviewAllowedOperation
    } = tenancy;

    if (!mostRecentTenancyAgreement) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} does not have mostRecentTenancyAgreement`
      });
    }

    if (rentReviewAllowed == null) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} does not have rentReviewAllowed`
      });
    }

    if (
      nextTenancyAgreement &&
      (!nextTenancyAgreement.id || !nextTenancyAgreement.startDate)
    ) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} does not have expected next tenancy agreement data`
      });
    }

    if (!leaseReviewAllowedOperation) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} does not have a lease review allowed operation`
      });
    }

    return {
      currentAgreementStartDate:
        mostRecentTenancyAgreement.startDate ?? undefined,
      currentAgreementFixedTermEndDate:
        mostRecentTenancyAgreement.fixedTermEndDate ?? undefined,
      currentAgreementIsAllowedToLapse:
        !!mostRecentTenancyAgreement.allowedToLapseAt,
      tenancyEndDate: endDate ?? undefined,
      rentReviewAllowed,
      nextTenancyAgreement: nextTenancyAgreement
        ? {
            id: nextTenancyAgreement.id,
            startDate: nextTenancyAgreement.startDate!,
            fixedTermEndDate:
              nextTenancyAgreement.fixedTermEndDate ?? undefined,
            allowedToLapseAt: !!nextTenancyAgreement.allowedToLapseAt
          }
        : undefined,
      leaseReviewAllowedOperation
    };
  });
}
