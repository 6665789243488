import { DropdownMenuOption } from "@ailo/ui";
import { getEditEndTenancyOption } from "local/domain/propertyManagement";
import { getSetEndTenancyOption } from "./getSetEndTenancyOption";
import { Options } from "./ManageMenu";

export function getEndTenancyOption(
  endTenancy: Options<{ isVacating: boolean }>
): DropdownMenuOption {
  const canEditEndTenancy = endTenancy.isVacating;

  const onSelectEndTenancy = (): void => endTenancy.onPress(endTenancy);
  return canEditEndTenancy
    ? getEditEndTenancyOption({ onSelect: onSelectEndTenancy })
    : getSetEndTenancyOption({ onSelect: onSelectEndTenancy });
}
