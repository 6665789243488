import { LocalDate } from "@ailo/date";
import { DescriptionList } from "@ailo/ui";
import React, { ReactElement } from "react";

type Props = {
  startDate?: string | null;
  fixedTermEndDate?: string | null;
};
export const TenancyAgreementDescriptionList = ({
  startDate,
  fixedTermEndDate
}: Props): ReactElement => {
  const agreementStartDate = startDate ? new LocalDate(startDate) : undefined;
  const agreementEndDate = fixedTermEndDate
    ? new LocalDate(fixedTermEndDate)
    : undefined;

  const descriptionListItems = [
    {
      label: "Lease type",
      descriptionBottom: agreementEndDate ? "Fixed term" : "Periodic",
      columnIndex: 0
    },
    {
      label: "Agreement end",
      descriptionBottom: agreementEndDate
        ? agreementEndDate.format("DD MMM YYYY")
        : "N/A",
      columnIndex: 0
    },
    {
      label: "Agreement start",
      descriptionBottom: agreementStartDate
        ? agreementStartDate.format("DD MMM YYYY")
        : "N/A",
      columnIndex: 1
    }
  ];

  return (
    <DescriptionList
      items={descriptionListItems}
      rowStyle={{ marginTop: 16 }}
      columns={2}
      size={"small"}
    />
  );
};
