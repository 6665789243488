import { FilesState, RentFrequency } from "@ailo/domains";
import { useAnalytics } from "@ailo/services";
import {
  DateInputFormField,
  FormField,
  FormFieldGroup,
  FormFieldRow,
  LocalDate,
  MoneyInput,
  RadioButtonGroup,
  Separator
} from "@ailo/ui";
import { FileAttachmentsGroup } from "local/common";
import { FileKind } from "local/graphql";
import { startCase, toLower } from "lodash";
import React, { ReactElement, useEffect, useMemo } from "react";
import { Controller, UseFormMethods } from "react-hook-form";
import styled from "styled-components/native";
import { LeaseTypes, NewTenancyFormData } from "../../TenancyForms";
import { StartDateInput } from "./StartDateInput";

export function LeaseAgreementDetailsStep({
  form,
  setFilesState,
  display,
  previousTenancyEndDate,
  allowFileUploads
}: {
  form: UseFormMethods<NewTenancyFormData>;
  setFilesState?: (fs: FilesState) => void;
  display: boolean;
  previousTenancyEndDate?: string;
  allowFileUploads: boolean;
}): ReactElement {
  const analytics = useAnalytics();
  useEffect(() => {
    if (display === true) {
      analytics.trackScreenVisited("New Tenancy — Lease Details");
    }
  }, [display, analytics]);

  const leaseType = form.watch("leaseType");
  const agreementStartDate = tryParseToLocalDate(
    form.watch("agreementStartDate")
  );
  const agreementEndDate = form.watch("agreementEndDate");
  const rentStartDate = form.watch("rentStartDate");
  const rentReviewDate = form.watch("rentReviewDate");
  const minStartDate = previousTenancyEndDate
    ? LocalDate.from(previousTenancyEndDate).add(1, "day")
    : undefined;

  const agreementEndAndRentReviewMinDate = useMemo(():
    | LocalDate
    | undefined => {
    if (!agreementStartDate && !minStartDate) return undefined;
    if (!agreementStartDate) return minStartDate?.add(1, "day");
    if (!minStartDate) return agreementStartDate.add(1, "day");
    if (agreementStartDate.isSameOrAfter(minStartDate))
      return agreementStartDate.add(1, "day");
    return minStartDate?.add(1, "day");
  }, [minStartDate, agreementStartDate]);

  useEffect(() => {
    if (agreementEndDate) form.trigger("agreementEndDate");
    if (rentReviewDate) form.trigger("rentReviewDate");
  }, [agreementStartDate, form, agreementEndDate, rentReviewDate]);

  const validateIsAfterPreviousTenancyEnd = useMemo(
    () =>
      validateIsAfterDate(
        "Select a date after the current tenancy ends",
        minStartDate
      ),
    [minStartDate]
  );

  return (
    <FormFieldGroup
      label={"Lease Agreement"}
      style={{ display: display ? undefined : "none" }}
    >
      <StyledFormFieldRow>
        <Controller
          control={form.control}
          name={"leaseType"}
          defaultValue={"Fixed Term"}
          render={({ value, onChange, onBlur }) => (
            <FormField
              label={"Lease Type"}
              error={form.errors.leaseType?.message}
            >
              <RadioButtonGroup
                {...{ value, onChange, onBlur }}
                variant={"button-solid-group"}
                options={[LeaseTypes.FixedTerm, LeaseTypes.Periodic].map(
                  (value) => ({
                    value,
                    label: value
                  })
                )}
                onChange={(selectedValue) => {
                  onChange(selectedValue);
                }}
              />
            </FormField>
          )}
          rules={{
            required: "Lease type is required"
          }}
        />
      </StyledFormFieldRow>
      <StyledFormFieldRow>
        <StartDateInput
          form={form}
          validate={validateIsAfterPreviousTenancyEnd}
          minStartDate={minStartDate}
          agreementEndDate={agreementEndDate}
          rentReviewDate={rentReviewDate}
        />
        {leaseType === LeaseTypes.FixedTerm && (
          <Controller
            control={form.control}
            render={({ value, onBlur, onChange }): React.ReactElement => (
              <DateInputFormField
                webPopoverHorizontalAlign={"start"}
                onBlur={onBlur}
                onChange={(date): void => {
                  onChange(date ?? null);
                }}
                minDate={agreementEndAndRentReviewMinDate?.toString()}
                placeholder={"End date"}
                autoCorrect={false}
                autoCompleteType={"off"}
                allowAnyTypedDate={true}
                value={value}
                label={"Agreement End"}
                error={form.errors.agreementEndDate?.message}
              />
            )}
            name={"agreementEndDate"}
            defaultValue={null}
            rules={{
              required: "Please select an end date",
              validate: {
                afterAgreementStart: validateIsAfterDate(
                  "End date cannot be before start date",
                  agreementStartDate
                ),
                afterPreviousTenancyEnd: validateIsAfterPreviousTenancyEnd
              }
            }}
          />
        )}
      </StyledFormFieldRow>
      <StyledFormFieldRow cols={2}>
        <Controller
          control={form.control}
          render={({ value, onBlur, onChange }): React.ReactElement => (
            <DateInputFormField
              webPopoverHorizontalAlign={"start"}
              onBlur={onBlur}
              onChange={(date): void => {
                onChange(date ?? null);
              }}
              minDate={agreementStartDate?.toString()}
              placeholder={"Start date"}
              autoCorrect={false}
              autoCompleteType={"off"}
              allowAnyTypedDate={true}
              value={value}
              label={"Rent Start Date"}
              helperText={"Paid to date + 1 day"}
              error={form.errors.rentStartDate?.message}
            />
          )}
          name={"rentStartDate"}
          defaultValue={rentStartDate}
          rules={{
            required: "Please select a start date",
            validate: {
              afterAgreementStart: validateIsAfterDate(
                "Rent start date cannot be before agreement start date",
                agreementStartDate
              ),
              beforeAgreementEnd: validateIsBeforeDate(
                "Rent start date cannot be after agreement end date",
                agreementEndDate
              )
            }
          }}
        />
        <Controller
          control={form.control}
          render={({ value, onBlur, onChange }): React.ReactElement => (
            <DateInputFormField
              webPopoverHorizontalAlign={"start"}
              onBlur={onBlur}
              onChange={(date): void => {
                onChange(date ?? null);
              }}
              minDate={agreementEndAndRentReviewMinDate?.toString()}
              placeholder={"Review date"}
              autoCorrect={false}
              autoCompleteType={"off"}
              allowAnyTypedDate={true}
              value={value}
              label={"Schedule Rent Review"}
              error={form.errors.rentReviewDate?.message}
            />
          )}
          name={"rentReviewDate"}
          defaultValue={null}
          rules={{
            validate: {
              afterAgreementStart: validateIsAfterDate(
                "Review date cannot be before start date",
                agreementStartDate
              ),
              afterPreviousTenancyEnd: validateIsAfterPreviousTenancyEnd
            }
          }}
        />
      </StyledFormFieldRow>
      <StyledFormFieldRow>
        <Controller
          control={form.control}
          name={"rentFrequency"}
          defaultValue={null}
          render={({ value, onChange, onBlur }) => (
            <FormField
              label={"Rent Frequency"}
              error={form.errors.rentFrequency?.message}
            >
              <RadioButtonGroup
                {...{ value, onChange, onBlur }}
                variant={"button-solid-group"}
                options={[
                  RentFrequency.Weekly,
                  RentFrequency.Fortnightly,
                  RentFrequency.Monthly
                ].map((value) => ({
                  value,
                  label: startCase(toLower(value))
                }))}
                onChange={(selectedValue) => {
                  onChange(selectedValue);
                }}
                hasError={!!form.errors.rentFrequency?.message}
              />
            </FormField>
          )}
          rules={{
            required: "Please select a rent frequency"
          }}
        />
      </StyledFormFieldRow>
      <StyledFormFieldRow>
        <Controller
          control={form.control}
          name={"rentAmount"}
          defaultValue={null}
          render={({ value, onChange, onBlur }) => (
            <FormField label={"Rent Amount"}>
              <MoneyInput
                {...{ value, onChange, onBlur }}
                error={form.errors.rentAmount?.message}
                withTrailingZeros={true}
                allowNull={true}
              />
            </FormField>
          )}
          rules={{
            required: "Please enter a rent amount"
          }}
        />
        <></>
      </StyledFormFieldRow>
      {allowFileUploads && setFilesState && (
        <>
          <SectionSeparator />
          <FileAttachmentsGroup
            kind={FileKind.TenancyFile}
            form={form}
            setFilesState={setFilesState}
          />
        </>
      )}
    </FormFieldGroup>
  );
}

const StyledFormFieldRow = styled(FormFieldRow)`
  margin-bottom: 24px;
`;

const SectionSeparator = styled(Separator)`
  margin: 16px -60px 32px 0;
`;

function validateIsAfterDate(
  errorMessage: string,
  date?: LocalDate | string | null
): (value: string) => string | undefined {
  return (value: string) => {
    if (!value || !date) return;

    const valueIsBeforeDate = LocalDate.fromString(value).isBefore(
      LocalDate.from(date)
    );

    return valueIsBeforeDate ? errorMessage : undefined;
  };
}

function validateIsBeforeDate(
  errorMessage: string,
  date?: LocalDate | string | null
): (value: string) => string | undefined {
  return (value: string) => {
    if (!value || !date) return;

    const valueIsAfterDate = LocalDate.fromString(value).isAfter(
      LocalDate.from(date)
    );

    return valueIsAfterDate ? errorMessage : undefined;
  };
}

function tryParseToLocalDate(
  date: LocalDate | string | undefined | null
): LocalDate | undefined {
  if (date == null) return undefined;
  return LocalDate.from(date);
}
