import React, { ReactElement, useCallback, useMemo } from "react";
import { ErrorCard, Grid, GridRenderRowContainerProps } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import { View } from "react-native";
import { useListAllReconciliationReportPeriodsQuery } from "local/graphql";
import styled from "styled-components/native";
import { columns, loadingColumns, loadingRows } from "./gridConfig";
import { PressableReconciliationLink } from "../components";
import { EmptyCard } from "./EmptyCard";

export function ReportPeriodList(): ReactElement {
  const { loading, data, refetch } =
    useListAllReconciliationReportPeriodsQuery();
  const headerCaption = (
    <HeaderContainer>
      <Text.DisplayS>{"Past reconciliations"}</Text.DisplayS>
    </HeaderContainer>
  );
  const renderRowContainer = useCallback(
    (props: RowContainerProps) => (
      <PressableReconciliationLink {...props.row} {...props} />
    ),
    []
  );
  const rows = useMemo(() => {
    return data?.reconciliationReportPeriods.edges.map(({ node }) => ({
      ...node,
      key: node.id
    }));
  }, [data?.reconciliationReportPeriods.edges]);

  if (loading && !rows) {
    return (
      <Grid
        headerCaption={headerCaption}
        columns={loadingColumns}
        rows={loadingRows}
        rowStyle={{ borderWidth: 0 }}
      />
    );
  }

  if (!rows || rows.length === 0) {
    return (
      <Grid.Placeholder headerCaption={headerCaption}>
        {!rows ? (
          <ErrorCard
            message={"There’s a problem loading past reconciliations"}
            onReload={refetch}
          />
        ) : (
          <EmptyCard
            message={"Past reconciliations"}
            secondaryMessage={"You have no past reconciliations"}
          />
        )}
      </Grid.Placeholder>
    );
  }

  return (
    <Grid
      headerCaption={headerCaption}
      columns={columns}
      rows={rows}
      renderRowContainer={renderRowContainer}
    />
  );
}

type RowContainerProps = GridRenderRowContainerProps<{
  key: string;
  year: number;
  month: number;
}>;

const HeaderContainer = styled(View)`
  display: flex;
  justify-content: flex-end;
  background-color: ${Colors.WHITE};
  padding: 16px;
  box-shadow: 0 1px 4px rgba(28, 30, 38, 0.2);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;
