import { View } from "react-native";
import { Badge, CellContainer, TextCellContent } from "@ailo/ui";
import React, { FC } from "react";
import { OptionalTooltip } from "local/common";

export const RenderDualValueCell: FC<{
  mainValue?: string | null;
  BadgeValue?: string | null;
  dualCellType?: string | null;
  tooltipText?: string | undefined;
}> = ({ mainValue, BadgeValue, dualCellType, tooltipText }) => {
  const mainCellText = <TextCellContent>{mainValue}</TextCellContent>;

  if (dualCellType === "crn" && BadgeValue === "Active") {
    return <CellContainer>{mainCellText}</CellContainer>;
  }

  return (
    <CellContainer style={{ flexDirection: "row" }}>
      {mainCellText}
      <OptionalTooltip tooltipContent={tooltipText}>
        <View>
          <Badge
            style={{ alignSelf: "flex-start", marginLeft: 8, marginTop: 2 }}
          >
            {BadgeValue}
          </Badge>
        </View>
      </OptionalTooltip>
    </CellContainer>
  );
};
