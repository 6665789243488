import React, { ReactElement } from "react";
import { Button, StickyBottom } from "@ailo/ui";

export function Footer({ onPress }: { onPress: () => void }): ReactElement {
  return (
    <StickyBottom
      style={{
        paddingVertical: 20,
        paddingLeft: 0,
        paddingRight: 60,
        width: "100%",
        marginTop: "auto"
      }}
    >
      <Button.Primary
        style={{
          marginLeft: "auto"
        }}
        testID={"Close button"}
        onPress={onPress}
      >
        {"Close"}
      </Button.Primary>
    </StickyBottom>
  );
}
