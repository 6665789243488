import { SFC } from "@ailo/primitives";
import { useOnFocus } from "@ailo/services";
import { PropertyScreenCardErrorBoundary } from "local/domain/propertyManagement";
import { useGetTenanciesQuery } from "local/graphql";
import moment from "moment";
import React from "react";
import { View } from "react-native";
import { ActiveTenancyCard } from "./ActiveTenancyCard";
import { AddNewTenancyCard } from "./AddNewTenancyCard";
import { BaseTenancyCard } from "./BaseTenancyCard";
import { IngoingTenancyCard } from "./IngoingTenancyCard";

interface Props {
  managementId: string;
  onVacatePress: (tenancyId: string) => void;
  onEditEndTenancyPress: (tenancyId: string) => void;
  onTenancyDetailsPress: (tenancyId: string, title: string) => void;
  onTenantPress?: (id: string, entityType: "Person" | "Company") => void;
  onRentAdjustmentsPress: (tenancyId: string) => void;
  onAddBondDetailsPress: (tenancyId: string) => void;
  onEditBondDetailsPress: (tenancyId: string) => void;
  onViewBondClaimPress: (bondId: string) => void;
  onEditBondReferencePress: (tenancyId: string) => void;
}

export const TenancyCards: SFC<Props> = ({
  style,
  managementId,
  onVacatePress,
  onEditEndTenancyPress,
  onTenancyDetailsPress,
  onTenantPress,
  onRentAdjustmentsPress,
  onAddBondDetailsPress,
  onEditBondDetailsPress,
  onViewBondClaimPress,
  onEditBondReferencePress
}) => {
  const { data, loading, refetch } = useGetTenanciesQuery({
    variables: { managementId }
  });

  useOnFocus(refetch);

  if (loading) {
    return <BaseTenancyCard.Loading style={style} />;
  }

  const management = data?.management;

  if (!management)
    throw new Error(
      `Management "${managementId}" not found when requesting tenancies`
    );

  const activeTenancyId = getActiveTenancyId({
    mostRecentTenancyId: management.mostRecentTenancy?.id,
    mostRecentTenancyEndDate: management.mostRecentTenancy?.endDate
  });

  const ingoingTenancyId = management.nextTenancy?.id;

  const shouldShowAddNewTenancyCard =
    (!activeTenancyId || management.mostRecentTenancy?.endDate) &&
    !ingoingTenancyId &&
    !management.endDate;

  const occupancyStatus = data?.management?.occupancyStatus;

  return (
    <View style={style}>
      {shouldShowAddNewTenancyCard && (
        <AddNewTenancyCard
          managementId={managementId}
          style={{ marginBottom: 16 }}
        />
      )}
      {ingoingTenancyId &&
        withErrorBoundary({
          tenancyCard: (
            <IngoingTenancyCard
              managementId={managementId}
              tenancyId={ingoingTenancyId}
              onDetailsPress={onTenancyDetailsPress}
              onTenantPress={onTenantPress}
              onRentAdjustmentsPress={onRentAdjustmentsPress}
              onAddBondDetailsPress={onAddBondDetailsPress}
              onEditBondDetailsPress={onEditBondDetailsPress}
              onViewBondClaimPress={onViewBondClaimPress}
              onEditBondReferencePress={onEditBondReferencePress}
              style={{ marginBottom: 16 }}
            />
          ),
          cardTitle: "Ingoing tenancy"
        })}
      {activeTenancyId &&
        withErrorBoundary({
          tenancyCard: (
            <ActiveTenancyCard
              managementId={managementId}
              tenancyId={activeTenancyId}
              occupancyStatus={occupancyStatus}
              onVacatePress={onVacatePress}
              onEditEndTenancyPress={onEditEndTenancyPress}
              onDetailsPress={onTenancyDetailsPress}
              onTenantPress={onTenantPress}
              onRentAdjustmentsPress={onRentAdjustmentsPress}
              onAddBondDetailsPress={onAddBondDetailsPress}
              onEditBondDetailsPress={onEditBondDetailsPress}
              onViewBondClaimPress={onViewBondClaimPress}
              onEditBondReferencePress={onEditBondReferencePress}
              style={{ marginBottom: 16 }}
            />
          ),
          cardTitle: "Active tenancy"
        })}
    </View>
  );
};

function getActiveTenancyId(params: {
  mostRecentTenancyId?: string;
  mostRecentTenancyEndDate?: string | null;
}): string | null {
  if (!params.mostRecentTenancyId) return null;

  if (
    !params.mostRecentTenancyEndDate ||
    moment(params.mostRecentTenancyEndDate).isSameOrAfter(
      moment().startOf("day")
    )
  )
    return params.mostRecentTenancyId;

  return null;
}

function withErrorBoundary({
  tenancyCard,
  cardTitle,
  key
}: {
  tenancyCard: React.ReactElement;
  cardTitle: string;
  key?: string;
}): React.ReactElement {
  return (
    <PropertyScreenCardErrorBoundary
      cardTitle={cardTitle}
      key={key}
      style={{ marginBottom: 16 }}
    >
      {tenancyCard}
    </PropertyScreenCardErrorBoundary>
  );
}
