import { useHasFeature } from "@ailo/domains";
import { Colors, Text } from "@ailo/primitives";
import { useAnalytics, useEnvironment } from "@ailo/services";
import { Card, Separator } from "@ailo/ui";
import {
  PageContent,
  Screens,
  useAgencyDefaultWalletLegalEntityPermissions,
  useNavigation,
  useRoute
} from "local/common";
import { PlatformFeatureId } from "local/graphql";
import React, {
  ReactElement,
  useCallback,
  useMemo,
  useState,
  useEffect
} from "react";
import { TouchableOpacity, View } from "react-native";
import styled from "styled-components/native";
import { FeesTab } from "./FeesTab";
import { SettingsTitle } from "./SettingsTitle";
import { FormConnectionsTab } from "./FormConnectionsTab/FormConnectionsTab";
import { BankingDetailsFormTab } from "local/tabs/settings/SettingsTabScreenContent/BankingDetailsFormTab/BankingDetailsFormTab";

type SettingsTab = {
  id: "fees" | "emails" | "forms" | "banking";
  label: string;
  content: React.ReactNode;
  enabled: boolean;
};

type SettingsSection = {
  id: "agency" | "connections" | "trustAccounting";
  label: string;
  tabs: SettingsTab[];
};

export function SettingsTabScreenContent(): ReactElement {
  const [height, setHeight] = useState(0);
  const navigation = useNavigation();
  const analytics = useAnalytics();

  const hasFeesFeature = useHasFeature(PlatformFeatureId.FeeBlueprints);
  const hasTrustAccountingFeature = useHasFeature(
    PlatformFeatureId.TrustAccounting
  );
  const hasDisplayFormsTabFeature = useHasFeature(
    PlatformFeatureId.DisplayFormsTab
  );
  const { REI_CLIENT_ID, REI_INTEGRATION_URL } = useEnvironment();
  const permissions = useAgencyDefaultWalletLegalEntityPermissions();
  const hasManageLegalEntityFinancialPermission = permissions.includes(
    "legal_entity:financial:manage"
  );

  const sections = useMemo<SettingsSection[]>(() => {
    return [
      {
        id: "agency" as const,
        label: "Agency",
        tabs: [
          {
            id: "fees" as const,
            label: "Agency fee templates",
            content: <FeesTab />,
            enabled: hasFeesFeature
          }
        ].filter((tab) => tab.enabled)
      },
      {
        id: "connections" as const,
        label: "Connections",
        tabs: [
          {
            id: "forms" as const,
            label: "Forms",
            content: <FormConnectionsTab />,
            enabled: Boolean(
              hasDisplayFormsTabFeature && REI_CLIENT_ID && REI_INTEGRATION_URL
            )
          }
        ].filter((tab) => tab.enabled)
      },
      {
        id: "trustAccounting" as const,
        label: "Trust accounting",
        tabs: [
          {
            id: "banking" as const,
            label: "Banking",
            content: <BankingDetailsFormTab />,
            enabled:
              hasTrustAccountingFeature &&
              hasManageLegalEntityFinancialPermission
          }
        ].filter((tab) => tab.enabled)
      }
    ].filter((section) => section.tabs.length > 0);
  }, [
    hasDisplayFormsTabFeature,
    hasTrustAccountingFeature,
    hasFeesFeature,
    REI_CLIENT_ID,
    REI_INTEGRATION_URL,
    hasManageLegalEntityFinancialPermission
  ]);

  const { params } = useRoute<Screens.SettingsTab>();
  const selectedTab =
    sections
      .map((section) => section.tabs)
      .flat()
      .find((tab) => tab.id === params?.tab) || sections[0]?.tabs[0];

  useEffect(() => {
    analytics.trackScreenVisited(`Settings — ${selectedTab.label}`);
  }, [analytics, selectedTab.label]);

  const trackHeight = useCallback(
    (event) => {
      const { height: newHeight } = event.nativeEvent.layout;
      if (height !== newHeight) setHeight(newHeight);
    },
    [height]
  );

  return (
    <PageContent
      maxWidth={960}
      style={{ minWidth: 890 }}
      testID={"SettingsTabScreen"}
      contentContainerStyle={{ paddingBottom: 0 }}
    >
      <SettingsTitle />
      <Card
        style={{
          marginHorizontal: 0,
          marginBottom: 0,
          marginTop: 24
        }}
        innerStyle={{
          overflow: "visible"
        }}
        attached={["bottom"]}
      >
        <View style={{ flexDirection: "row" }}>
          <VerticalTabNavigation testID={"VerticalTabNavigation"}>
            {sections.map((section, index) => (
              <React.Fragment key={section.id}>
                {index > 0 ? <VerticalTabNavigationSectionSeparator /> : null}
                <VerticalTabNavigationSection>
                  <VerticalTabNavigationSectionHeader>
                    {section.label}
                  </VerticalTabNavigationSectionHeader>
                  {section.tabs.map((tab) => (
                    <VerticalTabNavigationItem
                      key={tab.id}
                      testID={"VerticalTabNavigationItem"}
                      focused={tab.id === selectedTab.id}
                      onPress={(): void =>
                        navigation.navigate(Screens.SettingsTab, {
                          tab: tab.id
                        })
                      }
                    >
                      <Text.BodyM weight={"medium"}>{tab.label}</Text.BodyM>
                    </VerticalTabNavigationItem>
                  ))}
                </VerticalTabNavigationSection>
              </React.Fragment>
            ))}
          </VerticalTabNavigation>
          <TabContentContainer onLayout={trackHeight}>
            {selectedTab.content}
          </TabContentContainer>
        </View>
      </Card>
    </PageContent>
  );
}

const VerticalTabNavigation = styled(View)`
  width: 240px;
  padding: 8px;
  position: sticky;
  top: 0;
  align-self: flex-start;
`;

const VerticalTabNavigationSection = styled(View)`
  padding-top: 8px;
`;

const VerticalTabNavigationSectionHeader = styled(Text.Subheading)`
  margin: 0 0 12px 16px;
  color: ${Colors.TEXT.DARK.SECONDARY};
`;

const VerticalTabNavigationItem = styled(TouchableOpacity)<{
  focused: boolean;
}>`
  padding: 10px 16px;
  margin: 0 0 4px;
  border-radius: 4px;
  background-color: ${({ focused }): string =>
    focused ? Colors.CLOUD : "transparent"};
`;

const VerticalTabNavigationSectionSeparator = styled(Separator)`
  margin: 16px -8px 4px 16px;
`;

const DISTANCE_TO_TOP_OF_PAGE = 144;

const TabContentContainer = styled(View)`
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: -1px;
  border-left-width: 1px;
  border-style: solid;
  border-color: ${Colors.GRAYSCALE.OUTLINE};
  padding-bottom: 24px;
  min-height: calc(100vh - ${DISTANCE_TO_TOP_OF_PAGE}px);
`;
