import { ParamListBase } from "@react-navigation/native";
import { Screens } from "local/common";
import moment from "moment";

export interface ReconciliationsStackNavigatorParamList extends ParamListBase {
  [Screens.TrustReconciliationStackNavigator]: {};
  [Screens.TrustReconciliationList]: {};
  [Screens.TrustReconciliationView]: {
    monthYear: MonthYearObject;
  };
  [Screens.TrustReconciliationAdjustmentList]: {
    monthYear: MonthYearObject;
  };
}

export interface MonthYearObject {
  month: number;
  year: number;
}

export class MonthYear {
  static parse(monthYear: string): MonthYearObject {
    const [year, month] = monthYear.split("-");
    return {
      year: parseInt(year),
      month: parseInt(month)
    };
  }

  static stringify(monthYear: MonthYearObject): string {
    const month = monthYear.month.toString().padStart(2, "0");
    return `${monthYear.year}-${month}`;
  }

  static toEndOfMonthISOString(
    monthYear: MonthYearObject,
    timeZone: string
  ): string {
    return moment
      .tz({ year: monthYear.year, month: monthYear.month - 1 }, timeZone)
      .add(1, "month")
      .subtract(1, "millisecond")
      .toISOString();
  }
}
