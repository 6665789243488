import React, { ReactElement, useCallback, useState } from "react";
import { EditTenancyForm, NewTenancyForm } from "./TenancyForms";
import { useToastContext, ErrorModal } from "@ailo/ui";
import { TenancyFragment } from "local/graphql";

interface Props {
  managementId: string;
  tenancyId?: string;
  onDismiss: () => void;
  onSuccess: (tenancy: TenancyFragment) => void;
}

export function AddOrEditTenancyScreen({
  managementId,
  tenancyId,
  onDismiss,
  onSuccess
}: Props): ReactElement {
  const toast = useToastContext();
  const [showErrorModal, setShowErrorModal] = useState(false);

  const success = useCallback(
    async (tenancy: TenancyFragment) => {
      toast.show({
        type: "success",
        message: tenancyId ? "Tenancy Updated" : "New tenancy created"
      });
      await onSuccess(tenancy);
    },
    [toast, tenancyId, onSuccess]
  );

  const onError = useCallback(() => {
    setShowErrorModal(true);
  }, []);

  return (
    <>
      {tenancyId ? (
        <EditTenancyForm
          tenancyId={tenancyId}
          onSuccess={success}
          onError={onError}
          onCancel={onDismiss}
          style={{
            maxWidth: 680,
            width: "100%",
            flex: 1,
            alignSelf: "flex-end"
          }}
        />
      ) : (
        <NewTenancyForm
          managementId={managementId}
          onTenancyCreated={success}
          onError={onError}
          onCancel={onDismiss}
          style={{
            maxWidth: 680,
            width: "100%",
            flex: 1,
            alignSelf: "flex-end"
          }}
        />
      )}
      <ErrorModal
        visible={showErrorModal}
        onDismiss={() => setShowErrorModal(false)}
      />
    </>
  );
}
