import { Text, LeftChevronIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";

export function TabContent({
  title,
  style,
  testID,
  rightButtons,
  onGoBack,
  children
}: {
  title?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  rightButtons?: React.ReactNode;
  onGoBack?(): void;
  children?: React.ReactNode;
}): React.ReactElement {
  return (
    <View
      style={[
        {
          paddingTop: TabContent.padding,
          paddingHorizontal: TabContent.padding
        },
        style
      ]}
      testID={testID}
    >
      {(onGoBack || rightButtons) && (
        <View style={{ flexDirection: "row" }}>
          {onGoBack && (
            <View style={{ marginBottom: 20 }}>
              <Button.Secondary square type={"small"} onPress={onGoBack}>
                <LeftChevronIcon alignCenter />
              </Button.Secondary>
            </View>
          )}
          {rightButtons && (
            <View style={{ marginLeft: "auto" }}>{rightButtons}</View>
          )}
        </View>
      )}
      {title && <Text.DisplayS>{title}</Text.DisplayS>}
      {children}
    </View>
  );
}

TabContent.padding = 32;
