import React, { ReactElement, useCallback } from "react";
import { Link, Text } from "@ailo/primitives";
import { useEnvironment } from "@ailo/services";

export function DefaultRequestSignatureActionDescription(): ReactElement {
  const { DOCUSIGN_URL } = useEnvironment();
  const openDocuSign = useCallback(() => {
    window.open(DOCUSIGN_URL, "_blank");
  }, [DOCUSIGN_URL]);
  return (
    <Text.BodyL weight={"book"}>
      {"Send the agreement for electronic signature via "}
      <Link
        variant={"primary"}
        onPress={openDocuSign}
        style={{
          textDecorationLine: "underline"
        }}
      >
        {"DocuSign"}
      </Link>{" "}
      {"or download a copy to send via email or post."}
    </Text.BodyL>
  );
}
