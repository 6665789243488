import React from "react";
import { PropertyScreenCardListSubHeader } from "local/domain/propertyManagement";
import { AddBond } from "./AddBond";
import { BondDetails } from "./BondDetails";
import { TenancyBond } from "local/tabs";
import { LocalDate } from "@ailo/date";
import { Colors, Text } from "@ailo/primitives";
import { Separator } from "@ailo/ui";
import { AustralianState } from "local/common";
import { usePropertyIsInStates } from "local/tabs/properties/components/PropertyScreen/context/PropertyContext";
import { TrustAccountDetailsBox } from "local/domain/bond/TrustAccountDetailsBox";

export function TenancyBondsSection({
  bond,
  showStatus,
  onPressAdd,
  onPressEdit
}: {
  managementId: string;
  bond?: TenancyBond;
  showStatus?: boolean;
  onPressAdd?: () => void;
  onPressEdit?: () => void;
}): React.ReactElement {
  const propertyInNtSaOrWa = usePropertyIsInStates([
    AustralianState.NT,
    AustralianState.WA,
    AustralianState.SA
  ]);

  const lastUpdated = bond?.modifiedAt;
  const sevenDaysAgo = LocalDate.today().subtract(7, "days");
  const bondModifiedWithinLast7Days = lastUpdated
    ? sevenDaysAgo.isBefore(lastUpdated)
    : false;

  const showTaDetails =
    propertyInNtSaOrWa &&
    bondModifiedWithinLast7Days &&
    ["Unclaimed", "AwaitingFunds", "DisbursementFailed"].includes(
      bond?.status || ""
    );

  return (
    <>
      <PropertyScreenCardListSubHeader
        style={{ marginTop: 20, marginHorizontal: 16 }}
      >
        {"Bonds"}
      </PropertyScreenCardListSubHeader>
      {bond ? (
        <>
          <BondDetails
            bond={bond}
            showStatus={showStatus}
            onPressEdit={onPressEdit}
          />
          {showTaDetails && (
            <TrustAccountDetailsBox
              reference={bond?.reference}
              style={{ marginBottom: 16, marginHorizontal: 16 }}
              headerContent={TrustAccountDetailsHeader}
            />
          )}
        </>
      ) : (
        <AddBond onPress={onPressAdd} />
      )}
    </>
  );
}

const TrustAccountDetailsHeader = (
  <>
    <Text.BodyM weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
      {
        "Please provide your trust account details as listed below to your renters."
      }
    </Text.BodyM>
    <Separator
      style={{
        width: "auto",
        marginVertical: 16,
        marginRight: -18,
        marginLeft: 0
      }}
    />
  </>
);
