import { CommonActions } from "@react-navigation/native";
import { Screens } from "local/common";

export function getNavigateToPropertyAction(
  managementId: string
): CommonActions.Action {
  return CommonActions.navigate(Screens.PropertiesTab, {
    screen: Screens.Property,
    params: {
      managementId,
      vacateTenancyId: undefined,
      rentReviewTenancyId: undefined,
      tenancyDetails: undefined
    }
  });
}
