import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BankingDetailsFormData } from "./BankingDetailsFormData";
import { TextInputFormField } from "@ailo/ui";

const MAX_LENGTH = 6 as const;

export function DirectEntryUserIdFormInput(): React.ReactElement {
  const { control, errors } = useFormContext<BankingDetailsFormData>();
  return (
    <Controller
      control={control}
      name={"directEntryUserId"}
      render={({ value, onChange, onBlur }) => {
        return (
          <TextInputFormField
            {...{ value, onBlur }}
            label={"Direct entry user ID"}
            error={errors.directEntryUserId?.message}
            softCharacterLimit={{
              limit: MAX_LENGTH,
              enforcementLevel: "enforced",
              hideLimit: true
            }}
            digitsOnly={true}
            onChangeText={(text) => {
              onChange(text.slice(0, 6));
            }}
          />
        );
      }}
      rules={{
        validate: (value: string) =>
          value.length === 0 ||
          value.length === 6 ||
          "Direct entry user ID must be exactly 6 digits"
      }}
    />
  );
}
