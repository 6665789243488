import React from "react";
import { SFC, opacify, Colors, MovingInTruckImage } from "@ailo/primitives";
import styled from "styled-components/native";
import { TenancyBanner } from "local/domain/propertyManagement";

interface Props {
  startDate: string;
}

export const MovingInBanner: SFC<Props> = ({ style, startDate }) => {
  return (
    <TenancyBanner
      style={[style, { backgroundColor: opacify(Colors.STATUS.SUCCESS, 0.2) }]}
      dateColumn={{ dateColumnTitle: "Start date", dateValue: startDate }}
      renderBannerImage={(): React.ReactNode => <StyledImage />}
    />
  );
};

const StyledImage = styled(MovingInTruckImage).attrs({
  width: 96,
  height: 48
})`
  position: absolute;
  bottom: 0;
  right: 12px;
`;
