import { Money, useToastContext } from "@ailo/ui";
import { GetManagementFeeBlueprintsQuery, TaxTreatment } from "local/graphql";
import React, { ReactElement, useCallback } from "react";
import { isPresent } from "ts-is-present";
import {
  createAgencyBlueprintOptions,
  ManagementFeeBlueprintModalForm,
  ManagementFeeBlueprintModalFormData
} from "../ManagementFeeBlueprintModalForm";
import { useEditManagementFeeBlueprint } from "./useEditManagementFeeBlueprint";

export interface EditManagementFeeBlueprintModalFormControllerData {
  managementId?: string;
  managementFeeBlueprint?: NonNullable<
    GetManagementFeeBlueprintsQuery["managementFeeBlueprints"]
  >["items"][number];
}

export interface EditManagementFeeBlueprintModalFormControllerProps
  extends EditManagementFeeBlueprintModalFormControllerData {
  onCancel: () => void;
  onSuccess: () => void;
  showEditBlueprintModal: boolean;
}

export function EditManagementFeeBlueprintModalFormController({
  managementId,
  onCancel,
  onSuccess,
  showEditBlueprintModal,
  managementFeeBlueprint
}: EditManagementFeeBlueprintModalFormControllerProps): ReactElement | null {
  const [editManagementFeeBlueprintMutation, loading] =
    useEditManagementFeeBlueprint();

  const toast = useToastContext();

  const submit = useCallback(
    async (data: ManagementFeeBlueprintModalFormData): Promise<void> => {
      if (loading || !managementId || !managementFeeBlueprint) return;
      const fixedAmount = data.fixedAmount
        ? { cents: data.fixedAmount.cents }
        : null;
      const oneWeekRentPercentage = data.oneWeekRentPercentage ?? null;
      if ((fixedAmount === null) === (oneWeekRentPercentage === null))
        throw Error(
          "Exactly one of fixed amount and one week percentage should be defined"
        );

      editManagementFeeBlueprintMutation({
        managementId,
        updateInput: {
          id: managementFeeBlueprint.id,
          fixedAmount,
          oneWeekRentPercentage,
          taxTreatment: data.includesGst
            ? TaxTreatment.Inclusive
            : TaxTreatment.Exclusive,
          description: data.description
        }
      }).then((result) => {
        if (result) {
          onSuccess();
          toast.show({
            type: "success",
            message: `${managementFeeBlueprint.feeBlueprint.name} updated`
          });
        }
      });
    },
    [
      editManagementFeeBlueprintMutation,
      loading,
      managementFeeBlueprint,
      managementId,
      onSuccess,
      toast
    ]
  );

  if (!managementFeeBlueprint) return null;

  const initialValues = initialValuesFromBlueprint(managementFeeBlueprint);

  return (
    <ManagementFeeBlueprintModalForm
      mode={"edit"}
      title={`Edit ${initialValues?.feeBlueprint.label.trim().toLowerCase()}`}
      initialValues={initialValues}
      agencyFeeBlueprints={[initialValues?.feeBlueprint].filter(isPresent)}
      onSubmit={submit}
      submitting={loading}
      onCancel={onCancel}
      visible={showEditBlueprintModal}
    />
  );
}

function initialValuesFromBlueprint(
  blueprint: NonNullable<
    GetManagementFeeBlueprintsQuery["managementFeeBlueprints"]
  >["items"][number]
): ManagementFeeBlueprintModalFormData {
  const {
    fixedAmount,
    oneWeekRentPercentage,
    taxTreatment,
    feeBlueprint,
    description
  } = blueprint;
  return {
    fixedAmount: fixedAmount ? Money.from(fixedAmount) : undefined,
    oneWeekRentPercentage: oneWeekRentPercentage ?? undefined,
    feeBlueprint: createAgencyBlueprintOptions([feeBlueprint])[0],
    includesGst: taxTreatment === TaxTreatment.Inclusive,
    description: description ?? undefined
  };
}
