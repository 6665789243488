import React, { ReactElement } from "react";
import { DepositData } from "local/domain/propertyManagement";
import { Text } from "@ailo/primitives";
import { DepositStatusBadge } from "./DepositStatusBadge";
import { DepositSummaryDescription } from "./DepositSummaryDescription";
import { Badge } from "@ailo/ui";
import styled from "styled-components/native";
import { StyleProp, View, ViewStyle } from "react-native";
import { DepositSummaryManageMenu } from "./DepositSummaryManageMenu";
import { PropertyContentCard } from "local/tabs/properties/components/PropertyScreen";

export interface DepositSummaryContentsProps {
  deposit: DepositData;
  managementId: string;
  isFormerTenancy?: boolean;
  showManageMenu?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const DepositSummaryContents = ({
  deposit,
  managementId,
  isFormerTenancy,
  showManageMenu,
  style
}: DepositSummaryContentsProps): ReactElement => {
  const { displayStatus, amount } = deposit;

  const title = (
    <Title>
      <Text.BodyL weight={"medium"}>{"Initial Payment"}</Text.BodyL>
      <StyledDepositStatusBadge status={displayStatus} />
      {isFormerTenancy && <StyledBadge>{"Former Tenancy"}</StyledBadge>}
    </Title>
  );

  const titleRight = (
    <TitleRight>
      <Text.BodyL weight={"book"}>{amount.format()}</Text.BodyL>
      {showManageMenu && (
        <DepositSummaryManageMenu
          deposit={deposit}
          managementId={managementId}
          isFormerTenancy={isFormerTenancy}
        />
      )}
    </TitleRight>
  );

  return (
    <PropertyContentCard style={style} title={title} titleRight={titleRight}>
      <DepositSummaryDescription
        deposit={deposit}
        isFormerTenancy={isFormerTenancy}
      />
    </PropertyContentCard>
  );
};

const Title = styled(View)`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const TitleRight = styled(View)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const StyledDepositStatusBadge = styled(DepositStatusBadge)`
  margin-left: 10px;
`;

const StyledBadge = styled(Badge)`
  margin-left: 4px;
`;
