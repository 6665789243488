import styled from "styled-components/native";
import { View } from "react-native";
import React from "react";
import { Text, Colors, Skeleton } from "@ailo/primitives";
import moment from "moment";
import { TeamsFilter } from "local/domain/propertyManagement";

const FilterBarWrapper = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 14px;
  background-color: ${Colors.WHITE};
  align-items: center;
  box-shadow: 0px 1px 4px rgba(28, 30, 38, 0.2);
  border-radius: 4px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  align-items: center;
  min-height: 48px;
`;

const BarElementBorderLine = ({
  children
}: {
  children: React.ReactNodeArray;
}): React.ReactElement => {
  const elements = children.filter((element) => element);
  const { length } = elements;

  return (
    <>
      {elements.map((element, index) => {
        return (
          <View
            key={index}
            style={{
              borderStyle: "solid",
              borderColor:
                index === length - 1
                  ? Colors.TRANSPARENT
                  : Colors.GRAYSCALE.OUTLINE,
              borderRightWidth: 1,
              marginRight: 16,
              paddingRight: 16,
              height: 32,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {element}
          </View>
        );
      })}
    </>
  );
};

const BarTitle = (): React.ReactElement => (
  <Text.BodyS style={{ marginRight: 12, minWidth: "max-content" }}>
    {"View data for:"}
  </Text.BodyS>
);

function FilterBar({
  onTeamChange
}: {
  onTeamChange?: (teams: string[]) => void;
}): React.ReactElement {
  const getCurrentTime = moment().format("h:mma");

  return (
    <FilterBarWrapper>
      <BarTitle />
      <BarElementBorderLine>
        <TeamsFilter
          buttonHeight={32}
          onChange={onTeamChange}
          horizontalAlign={"left"}
        />
        <Text.BodyS>
          {"Updated "}
          {getCurrentTime}
        </Text.BodyS>
        {/* <Text.BodyS>
        //TODO: where snapshot info goes once implemented Shows change since 31
        Jan 2021
      </Text.BodyS> */}
      </BarElementBorderLine>
    </FilterBarWrapper>
  );
}
FilterBar.Loading = function FilterBarLoading() {
  return (
    <FilterBarWrapper>
      <BarTitle />
      <BarElementBorderLine>
        <Skeleton style={{ height: 32, width: 156, borderRadius: 16 }} />
        <Skeleton style={{ height: 20, width: 100 }} />
      </BarElementBorderLine>
    </FilterBarWrapper>
  );
};

export { FilterBar as DashboardFilterBar };
