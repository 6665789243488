import React, { Dispatch, SetStateAction, useMemo } from "react";
import { Colors, Text } from "@ailo/primitives";
import { SelectedRowsHeader } from "./SelectedRowsHeader";
import { UnscheduledTenanciesListRow } from "../useGetUnscheduledTenanciesRows";
import { Button } from "@ailo/ui";
import styled from "styled-components/native";
import { View } from "react-native";

export function useGetSelectedRowsMenu({
  rows,
  selectedRowIds
}: {
  rows: UnscheduledTenanciesListRow[];
  selectedRowIds: string[];
  setSelectedRowIds: Dispatch<SetStateAction<string[]>>;
}): React.ReactElement | null {
  const headerOverride = useMemo(() => {
    if (rows.length === 0 || selectedRowIds.length === 0) {
      return null;
    }
    return (
      <SelectedRowsHeader>
        <Column>
          <Text.BodyM
            weight={"medium"}
            style={{
              color: Colors.WHITE
            }}
          >{`${selectedRowIds.length} selected`}</Text.BodyM>
        </Column>
        <Column
          style={{
            justifyContent: "flex-end"
          }}
        >
          <MenuButton>{"Map"}</MenuButton>
          <MenuButton>{"Change due date"}</MenuButton>
          <MenuButton>{"Schedule"}</MenuButton>
        </Column>
      </SelectedRowsHeader>
    );
  }, [rows.length, selectedRowIds.length]);

  return headerOverride;
}

const Column = styled(View)`
  flex-direction: row;
  flex: 1;
  gap: 12px;
`;

const MenuButton = styled(Button).attrs({
  variant: "secondary",
  contentColor: Colors.OCEAN
})``;
