import React from "react";
import { AFC, SFC } from "@ailo/primitives";
import { useOnFocus } from "@ailo/services";
import {
  formatPropertyAddressName,
  useGetManagementAddressQuery,
  PropertyCell
} from "@ailo/domains";
import { AddressData } from "local/common";

interface Props {
  managementId: string;
}

interface Statics {
  Loading: SFC;
}

const Address: AFC<Props> & Statics = ({ managementId }) => {
  const { data, loading, refetch } = useGetManagementAddressQuery({
    variables: { managementId }
  });

  useOnFocus(refetch);

  if (managementId == null) return null;
  if (loading && !data) {
    return <PropertyCell.Loading />;
  }
  const address = data?.management?.property?.address;
  if (!address) throw Error("Cannot find address");

  return (
    <PropertyCell
      style={{ maxWidth: 272 }}
      streetAndStreetNumber={getStreetAndStreetNumber(address)}
      suburb={address.suburb}
    />
  );
};

const getStreetAndStreetNumber = ({
  unitStreetNumber,
  streetName
}: AddressData): string => {
  const formattedAddress = formatPropertyAddressName({
    address: { unitStreetNumber, streetName }
  });
  if (!formattedAddress) throw Error("Cannot find address");
  return formattedAddress;
};

Address.Loading = PropertyCell.Loading;

export { Address };
