import { useCallback, useMemo } from "react";
import { useAnalytics, uuid } from "@ailo/services";
import { ProjectFormStep } from "./ProjectForm";
import { ProjectType } from "local/graphql";
import { startCase } from "lodash";
import { ProjectFormData } from "./ProjectForm/ProjectFormData";

interface Project {
  id: string;
  type: ProjectType;
}

interface UseCreateProjectAnalytics {
  onStepStarted: (formStep: ProjectFormStep) => void;
  onValueUpdated: (
    fieldName: string,
    isValid: boolean,
    formValues: ProjectFormData
  ) => void;
  onCreateSubmitSuccess: (
    project: Project,
    formValues: ProjectFormData
  ) => void;
  onUpdateSubmitSuccess: (
    project: { id: String },
    initialValues: ProjectFormData,
    formValues: ProjectFormData
  ) => void;
}

export function useFormProjectAnalytics(
  formName: string
): UseCreateProjectAnalytics {
  const formSessionId = useMemo(() => uuid(), []);
  const analytics = useAnalytics();

  const onStepStarted = useCallback(
    (formStep: ProjectFormStep) => {
      analytics.trackScreenVisited(formStep, {
        form_session_id: formSessionId
      });
    },
    [analytics, formSessionId]
  );

  const onValueUpdated = useCallback(
    (fieldName: string, isValid, formValues: ProjectFormData) => {
      analytics.track("Form Step Completed", {
        form_name: formName,
        form_session_id: formSessionId,
        step_name: startCase(fieldName),
        value_is_valid: isValid ? "Yes" : "No",
        steps_completed_this_far: formValuesToStepsCompleted(formValues)
      });
    },
    [analytics, formName, formSessionId]
  );

  const onCreateSubmitSuccess = useCallback(
    (project: Project, formValues: ProjectFormData) => {
      analytics.track("Project Created", {
        project_id: project.id,
        project_type: startCase(project.type),
        form_session_id: formSessionId,
        steps_completed: formValuesToStepsCompleted(formValues)
      });
    },
    [analytics, formSessionId]
  );

  const onUpdateSubmitSuccess = useCallback(
    (
      project: { id: String },
      initialValues: ProjectFormData,
      formValues: ProjectFormData
    ) => {
      analytics.track("Project Updated", {
        project_id: project.id,
        fields_updated: getUpdatedFields(initialValues, formValues)
      });
    },
    [analytics]
  );

  return {
    onStepStarted,
    onValueUpdated,
    onCreateSubmitSuccess,
    onUpdateSubmitSuccess
  };
}

function formValuesToStepsCompleted(formValues: ProjectFormData): string[] {
  return Object.keys(formValues)
    .filter((key) => !!formValues[key as keyof ProjectFormData])
    .map(startCase);
}

function getUpdatedFields(
  initialValues: ProjectFormData,
  submittedValues: ProjectFormData
): string[] {
  return Object.keys(submittedValues)
    .filter(
      (key) =>
        initialValues[key as keyof ProjectFormData] ===
        submittedValues[key as keyof ProjectFormData]
    )
    .map(startCase);
}
