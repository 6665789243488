import { Text } from "@ailo/primitives";
import { ErrorAlertScreen } from "@ailo/ui";
import {
  AgreementCreatedNoTokenView,
  getReiFormError,
  LinkReiAccountSuggestion
} from "local/domain/project";
import { useGetReiTokens } from "local/domain/project/useGetReiTokens";
import {
  ActionType,
  ReiFormErrorCode,
  ReiTokenBaseFragment,
  SigningStatus
} from "local/graphql";
import React, { ReactElement, useEffect } from "react";
import { RefinedActionActivityProps } from "../ActionActivityProps";
import { ReminderActionActivity } from "../ReminderActionActivity";
import { useCompleteActionIfSigned } from "./useCompleteActionIfSigned";
import { useGetFormStatuses } from "./useGetFormStatuses";
import { FormInfoWithSignButtonView, NoFormFinalisedView } from "./views";

export type RequestSignatureActionActivityProps =
  RefinedActionActivityProps<ActionType.RequestSignature>;

export function RequestSignatureActionActivity({
  action,
  projectId
}: RequestSignatureActionActivityProps): ReactElement {
  const { data: reiTokens, loading, error, refetch } = useGetReiTokens();

  if (loading) {
    return <Text.BodyS.Loading width={200} />;
  }
  if (error) {
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the form information."}
        onRetry={refetch}
      />
    );
  }

  const formId = action.meta.form?.id;
  if (formId) {
    if (!reiTokens?.length) {
      return <AgreementCreatedNoTokenView />;
    }

    return (
      <ViewWithFormId
        formId={formId}
        action={action}
        projectId={projectId}
        reiTokens={reiTokens}
      />
    );
  }

  return (
    <ViewWithoutFormId
      action={action}
      projectId={projectId}
      reiTokens={reiTokens}
    />
  );
}

function ViewWithoutFormId({
  action,
  projectId,
  reiTokens
}: RequestSignatureActionActivityProps & {
  reiTokens: ReiTokenBaseFragment[] | undefined;
}): ReactElement {
  if (!reiTokens?.length) {
    return (
      <ReminderActionActivity action={action} projectId={projectId}>
        <LinkReiAccountSuggestion style={{ marginTop: 32 }} />
      </ReminderActionActivity>
    );
  }

  return <NoFormFinalisedView />;
}

function ViewWithFormId({
  action,
  projectId,
  formId,
  reiTokens
}: RequestSignatureActionActivityProps & {
  formId: string;
  reiTokens: ReiTokenBaseFragment[];
}): ReactElement {
  const { data, loading, error, refetch, stopPolling, startPolling } =
    useGetFormStatuses(formId);
  const {
    reiForm: { remoteSigningStatus, reiFormInfo }
  } = data;

  useEffect(() => {
    startPolling(5000);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, remoteSigningStatus]);

  useCompleteActionIfSigned({
    projectId,
    actionId: action.id,
    signingStatus: data.reiForm.remoteSigningStatus?.status
  });

  const reiFormNotFoundError = getReiFormError(
    ReiFormErrorCode.ReiFormNotFound,
    error
  );

  if (loading) {
    return <Text.BodyS.Loading width={200} />;
  }

  if (
    (error || !reiFormInfo || !remoteSigningStatus) &&
    !reiFormNotFoundError
  ) {
    stopPolling();
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the form information."}
        onRetry={refetch}
      />
    );
  }

  if (reiFormNotFoundError || reiFormInfo?.finalised !== true) {
    return <NoFormFinalisedView formDeleted={reiFormNotFoundError != null} />;
  }

  if (remoteSigningStatus?.status === SigningStatus.NotAvailable) {
    return <AgreementCreatedNoTokenView />;
  }

  return (
    <FormInfoWithSignButtonView
      reiForm={{
        formId,
        reiFormInfo
      }}
      reiTokensLength={(reiTokens ?? []).length}
      remoteSigningStatusSent={remoteSigningStatus?.sent}
    />
  );
}
