import { useCurrentAgencyOrg } from "local/common";
import {
  ManagementFilterTypes,
  ManagementSortField,
  OccupancyStatus,
  SortDirection,
  useGetPagedManagementsDueForRenewalsLazyQuery
} from "local/graphql";
import { useCallback } from "react";
import { PROPERTY_LIST_PAGE_SIZE, RequestCursor } from "./usePaginationRequest";
import { PropertyListQueryData } from "../BasePropertyList";
import { useGetPropertyListQueryReturn } from "./useGetPropertyListQueryReturn";
import { GridSortOrder } from "@ailo/ui";
import { RenewalPropertyListColumns } from "../RenewalsPropertyList";
import { getTeamsManagementFilterParams } from "./getTeamsManagementFilterParams";
import { PropertyListSelectedTeams } from "./usePropertyListSelectedTeams";
import { getOccupancyStatusFilterParams } from "./getOccupancyStatusFilterParams";

export function useRenewalsPropertyListQuery(
  initialSortOrder: GridSortOrder
): PropertyListQueryData {
  const currentOrg = useCurrentAgencyOrg();

  const [request, { data, loading, called }] =
    useGetPagedManagementsDueForRenewalsLazyQuery({
      fetchPolicy: "cache-and-network"
    });

  const sendRequest = useCallback(
    (
      selectedTeams: PropertyListSelectedTeams,
      cursor: RequestCursor = {
        cursor: null,
        pageSize: PROPERTY_LIST_PAGE_SIZE,
        paginateBackward: false,
        sort: sortStateToApiParam(initialSortOrder)
      }
    ) => {
      request({
        variables: {
          organisationId: currentOrg.ailoRN,
          filters: [
            { name: ManagementFilterTypes.DefaultPropertyList },
            {
              name: ManagementFilterTypes.HasTenancyUpForRentReviewOrLeaseRenewal
            },
            ...getTeamsManagementFilterParams(selectedTeams),
            ...getOccupancyStatusFilterParams([OccupancyStatus.Occupied])
          ],
          pageCursor: cursor
        }
      });
    },
    [currentOrg.ailoRN, initialSortOrder, request]
  );

  const pageInfo = data?.managementsForOrg?.pageInfo;
  const managements = data?.managementsForOrg?.items;

  return useGetPropertyListQueryReturn({
    sendRequest,
    pageInfo,
    managements,
    loading: loading && !data,
    called,
    sortStateToApiParam
  });
}

function sortStateToApiParam(sortOrder: GridSortOrder): {
  field: ManagementSortField;
  direction: SortDirection;
} {
  const directionForApi =
    sortOrder.direction === "DESC" ? SortDirection.Desc : SortDirection.Asc;

  return {
    field: getApiSortField(sortOrder.columnKey),
    direction: directionForApi
  };
}

function getApiSortField(columnKey: string): ManagementSortField {
  if (columnKey === RenewalPropertyListColumns.RentReview)
    return ManagementSortField.CurrentTenancyScheduledRentReviewDate;

  if (columnKey === RenewalPropertyListColumns.TenancyAgmtStart)
    return ManagementSortField.LatestTenancyAgreementStartDate;

  if (columnKey === RenewalPropertyListColumns.TenancyAgmtEnd)
    return ManagementSortField.LatestTenancyAgreementEndDate;

  throw new Error(`Cannot sort renewal list by column ${columnKey}`);
}
