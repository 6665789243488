import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { useCallback } from "react";
import { MutationResult } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { isPresent } from "ts-is-present";
import {
  GetPagedApprovedMigratingManagementsDocument,
  GetPagedAwaitingApprovalMigratingManagementsDocument,
  GetMigratingManagementSidebarDocument,
  UnapproveMigratingManagementMutation,
  useUnapproveMigratingManagementMutation
} from "local/graphql";
import { AiloRN, services } from "@ailo/ailorn";

interface UnapproveMigratingManagementsProps {
  migratingManagementId: string;
  onSuccess: (result: UnapproveMigratingManagementMutation) => void;
  onError: () => void;
}

type UnapproveMigratingManagements = [
  () => void,
  MutationResult<UnapproveMigratingManagementMutation>
];

const refreshOperationNames = [
  getOperationName(GetPagedAwaitingApprovalMigratingManagementsDocument),
  getOperationName(GetPagedApprovedMigratingManagementsDocument)
];

export function useUnapproveMigratingManagement({
  migratingManagementId,
  onSuccess,
  onError
}: UnapproveMigratingManagementsProps): UnapproveMigratingManagements {
  const analytics = useAnalytics();

  const [unapproveMigratingManagementMutation, mutationResult] =
    useUnapproveMigratingManagementMutation({
      variables: { migratingManagementId },
      refetchQueries: [
        {
          query: GetMigratingManagementSidebarDocument,
          variables: {
            migratingManagementId
          }
        },
        ...refreshOperationNames.filter(isPresent)
      ]
    });

  const unapproveMigratingManagement = useCallback(async () => {
    try {
      analytics.track("Migrating Property Unapproved", {
        migrating_management_id: AiloRN.of(
          services.Migration.migratingManagement,
          migratingManagementId
        ).toString()
      });

      const result = await unapproveMigratingManagementMutation();
      throwIfMutationFailed(result, {
        dataKey: "unapproveMigratingManagements"
      });

      if (!result.data) {
        onError();
        return;
      }

      onSuccess(result.data);
    } catch (error) {
      AiloSentry.captureException(error);
      onError();
    }
  }, [
    unapproveMigratingManagementMutation,
    onSuccess,
    onError,
    analytics,
    migratingManagementId
  ]);

  return [unapproveMigratingManagement, mutationResult];
}
