import { formatLegalEntityName } from "@ailo/domain-helpers";
import { PersonNamesFragment } from "local/graphql";

export function sortPeopleAlphabeticallyByName(
  people: PersonNamesFragment[]
): PersonNamesFragment[] {
  return [...people].sort((personA, personB) =>
    formatLegalEntityName(personA).localeCompare(formatLegalEntityName(personB))
  );
}
