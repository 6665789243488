import {
  MigratingManagementSidebarFragment,
  useGetMigratingManagementSidebarQuery
} from "local/graphql";
import { ApolloError } from "@apollo/client";

interface Params {
  migratingManagementId: string;
}

interface GetMigratingManagementSidebar {
  migratingManagement?: MigratingManagementSidebarFragment;
  loading: boolean;
  error?: ApolloError;
  refetch: () => void;
}

export function useGetMigratingManagementSidebar({
  migratingManagementId
}: Params): GetMigratingManagementSidebar {
  const { data, loading, error, refetch } =
    useGetMigratingManagementSidebarQuery({
      variables: { migratingManagementId },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-and-network"
    });

  const migratingManagement = data?.migratingManagement ?? undefined;

  return {
    migratingManagement,
    loading,
    error,
    refetch
  };
}
