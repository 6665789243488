import {
  AiloSentry,
  formatDate,
  MappedQueryResult,
  useMappedQueryResult
} from "@ailo/services";
import { MaybeFileDetails, fragmentToFileDetails } from "@ailo/domains";
import { ApolloError } from "@apollo/client";
import {
  GetManagementDetailsAndTeamsQuery,
  GetManagementDetailsAndTeamsQueryVariables,
  ManagementEndReason,
  useGetManagementDetailsAndTeamsQuery
} from "local/graphql";

import { useCurrentAgencyOrg } from "local/common";

const maximumNumberOfDisplayedFile = 1000;

export function useGetManagementDetailsAndTeams({
  managementId
}: {
  managementId: string;
}): MappedQueryResult<ReturnType, GetManagementDetailsAndTeamsQueryVariables> {
  const { id: organisationId } = useCurrentAgencyOrg();
  const result = useGetManagementDetailsAndTeamsQuery({
    variables: {
      managementId,
      organisationId
    }
  });

  return useMappedQueryResult(result, (data) => {
    const management = data?.management;

    if (!management)
      throw new ApolloError({
        errorMessage: `Cannot get management for ${managementId}`
      });

    if (!data?.management && !data?.organisation)
      throw new ApolloError({
        errorMessage: `[Property Management Summary] Management "${managementId}" and Organisation "${organisationId}" not found`
      });

    if (management.files == null) {
      management.files = { items: [null], pageInfo: { total: 1 } };
    }

    if (management.files.pageInfo.total > maximumNumberOfDisplayedFile) {
      AiloSentry.captureMessage(
        `Management ${managementId} has ${management.files.pageInfo.total} files which ` +
          `is more than the maximum number of ${maximumNumberOfDisplayedFile} displayed files.`
      );
    }

    return {
      managementData: parseManagementData({
        managementId,
        management: data.management
      }),
      teamData: parseTeamData({
        assignedTeamId: data.management?.team?.id,
        organisationId,
        organisation: data.organisation,
        managementId
      }),
      files: management.files.items.map(fragmentToFileDetails)
    };
  });
}

function parseManagementData({
  management,
  managementId
}: {
  managementId: string;
  management: GetManagementDetailsAndTeamsQuery["management"];
}): ManagementData {
  const propertyAddress = management?.property?.address;

  if (!propertyAddress)
    throw new ApolloError({
      errorMessage: `Cannot get address of Management "${managementId}"`
    });
  const startDate = management?.startDate
    ? formatDate(management.startDate)
    : "N/A";
  const fixedTermEndDate = management?.fixedTermEndDate
    ? formatDate(management.fixedTermEndDate)
    : management?.endDate
    ? formatDate(management.endDate)
    : "N/A";

  const { endDate, endReason, endNote } = management;

  return {
    managementId: managementId,
    startDate,
    fixedTermEndDate,
    unitStreetNumber: propertyAddress.unitStreetNumber,
    streetName: propertyAddress.streetName,
    endDate,
    endReason,
    endNote
  };
}

function parseTeamData({
  assignedTeamId,
  managementId,
  organisationId,
  organisation
}: {
  assignedTeamId?: string;
  managementId: string;
  organisationId: string;
  organisation: GetManagementDetailsAndTeamsQuery["organisation"];
}): TeamData {
  const allTeams = organisation?.teams || [];

  const assignedTeamIdIsValid =
    assignedTeamId == null ||
    allTeams.map((team) => team.id).includes(assignedTeamId);

  if (!assignedTeamIdIsValid) {
    throw new ApolloError({
      errorMessage:
        `[Property Management Summary] Logic error: Management "${managementId}" belongs to` +
        ` Team "${assignedTeamId}" but this team is not found in Organisation "${organisationId}"`
    });
  }

  return {
    allTeams,
    assignedTeamId,
    error: !organisation
  };
}

export type ManagementData = {
  managementId: string;
  startDate: string | null;
  fixedTermEndDate: string | null;
  unitStreetNumber: string;
  streetName: string;
  endDate?: string | null;
  endReason?: ManagementEndReason | null;
  endNote?: string | null;
};

export type TeamData = {
  allTeams: { id: string; name: string; myTeam: boolean }[];
  assignedTeamId?: string;
  error: boolean;
};

type ReturnType = {
  teamData: TeamData;
  managementData: ManagementData;
  files: MaybeFileDetails[];
};
