import { LocalDate } from "@ailo/ui";

export enum RentAdjustmentStatus {
  DEFAULT = "Default",
  PENDING = "Pending",
  REVERSED = "Reversed"
}

export function getRentAdjustmentStatus({
  effectiveAt,
  reversed
}: {
  effectiveAt: LocalDate;
  reversed: boolean;
}): RentAdjustmentStatus {
  return reversed
    ? RentAdjustmentStatus.REVERSED
    : effectiveAt.isAfter(LocalDate.today())
    ? RentAdjustmentStatus.PENDING
    : RentAdjustmentStatus.DEFAULT;
}
