import {
  ApprovedPropertyOnboardingListQuery,
  BasePropertyOnboardingListContent,
  PropertyOnboardingListLoadingContent,
  PropertyOnboardingListReorder,
  useApprovedPropertyOnboardingListQuery,
  useBasePropertyOnboardingListContent,
  usePropertyOnboardingListLoadingContent,
  usePropertyOnboardingListReorder
} from "../../hooks";
import { GridSortOrder } from "@ailo/ui";
import { lowerCase, startCase } from "lodash";
import {
  ApprovedPropertyOnboardingListSelect,
  useApprovedPropertyOnboardingListSelect
} from "./useApprovedPropertyOnboardingListSelect";
import {
  ApprovedPropertyOnboardingListColumns,
  useApprovedPropertyOnboardingListColumns
} from "./useApprovedPropertyOnboardingListColumns";
import {
  ApprovedPropertyOnboardingListContent,
  useApprovedPropertyOnboardingListContent
} from "./useApprovedPropertyOnboardingListContent";
import { ReactElement } from "react";
import { KeyedApprovedMigratingManagementFragment } from "../../types";

export enum ApprovedPropertyOnboardingListColumnNames {
  Address = "Address",
  Portfolio = "Portfolio",
  ApprovedBy = "Approved by",
  ApprovedOn = "Approved on",
  PublishStatus = "Status"
}

const SORT_ORDER: GridSortOrder[] = [
  {
    columnKey: ApprovedPropertyOnboardingListColumnNames.ApprovedOn,
    direction: "DESC"
  },
  {
    columnKey: ApprovedPropertyOnboardingListColumnNames.Address,
    direction: "ASC"
  },
  {
    columnKey: ApprovedPropertyOnboardingListColumnNames.Portfolio,
    direction: "ASC"
  },
  {
    columnKey: ApprovedPropertyOnboardingListColumnNames.PublishStatus,
    direction: "ASC"
  }
];

function getAnalyticsInfo(sortOrder: GridSortOrder): {
  eventName: string;
  orderBy: string;
} {
  return {
    eventName: "Approved Property Onboarding List Reordered",
    orderBy: `${sortOrder.columnKey} ${startCase(
      lowerCase(sortOrder.direction)
    )}`
  };
}

interface Params {
  tabNavigation: ReactElement;
}

interface ApprovedPropertyOnboardingListData {
  queryData: ApprovedPropertyOnboardingListQuery;
  reorder: PropertyOnboardingListReorder;
  select: ApprovedPropertyOnboardingListSelect;
  columns: ApprovedPropertyOnboardingListColumns;
  content: ApprovedPropertyOnboardingListContent;
  baseContent: BasePropertyOnboardingListContent<KeyedApprovedMigratingManagementFragment>;
  loadingContent: PropertyOnboardingListLoadingContent;
}

export function useApprovedPropertyOnboardingList({
  tabNavigation
}: Params): ApprovedPropertyOnboardingListData {
  const queryData = useApprovedPropertyOnboardingListQuery({
    initialSortOrder: SORT_ORDER
  });

  const reorder = usePropertyOnboardingListReorder(
    SORT_ORDER,
    queryData.requestFromBeginning,
    getAnalyticsInfo
  );

  const select = useApprovedPropertyOnboardingListSelect({
    migratingManagements: queryData.data.migratingManagements
  });

  const columns = useApprovedPropertyOnboardingListColumns({
    select,
    refetchList: queryData.refetch
  });

  const content = useApprovedPropertyOnboardingListContent({
    tabNavigation,
    queryData,
    select
  });

  const baseContent = useBasePropertyOnboardingListContent({
    queryData,
    headerCaptionContent: content.headerCaptionContent,
    columns,
    sortOrder: reorder.sortOrder,
    onSortOrderChange: reorder.onSortOrderChange,
    isRowSelected: select.isRowSelected
  });

  const loadingContent = usePropertyOnboardingListLoadingContent({ columns });

  return {
    queryData,
    reorder,
    select,
    columns,
    content,
    baseContent,
    loadingContent
  };
}
