import React, { useEffect } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { DateInputFormField, FormFieldRow, LocalDate } from "@ailo/ui";
import { Controller } from "react-hook-form";
import { RentReviewFields } from "../RentReviewFormData";
import { useRentReviewForm } from "../RentReviewFormContext";

function ScheduledRentReviewDateInput({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  const { control, errors, watch, setValue } = useRentReviewForm();
  const effectiveDate = watch(RentReviewFields.effectiveDate);

  useEffect(() => {
    if (effectiveDate) {
      setValue(
        RentReviewFields.scheduledRentReviewDate,
        LocalDate.from(effectiveDate).add(1, "year").toString(),
        { shouldValidate: true }
      );
    }
  }, [effectiveDate, setValue]);

  return (
    <View style={style} accessible={true}>
      <FormFieldRow>
        <Controller
          name={RentReviewFields.scheduledRentReviewDate}
          render={({ value, onChange, onBlur }): React.ReactElement => (
            <DateInputFormField
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              label={"Schedule next rent review"}
              placeholder={"Next rent review"}
              autoCorrect={false}
              autoCompleteType={"off"}
              minDate={effectiveDate ?? undefined}
              error={errors.scheduledRentReviewDate?.message}
            />
          )}
          defaultValue={null}
          control={control}
          rules={{
            required: "Please select the next rent review date"
          }}
        />
        <></>
      </FormFieldRow>
    </View>
  );
}

export { ScheduledRentReviewDateInput };
