import { didQueryNotLoadYet, throwIfQueryFailed } from "@ailo/services";
import {
  PersonDisplayDetailsFragment,
  useGetActionAssigneesQuery
} from "local/graphql";

export function useGetAssignees({
  organisationId
}: {
  organisationId: string;
}): {
  assignees: PersonDisplayDetailsFragment[];
  loading: boolean;
  refetch: () => void;
} {
  const reportersResult = useGetActionAssigneesQuery({
    variables: {
      organisationId
    }
  });
  throwIfQueryFailed(reportersResult, { dataKey: "organisation" });

  const propertyManagers =
    reportersResult.data?.organisation?.members?.items || [];

  return {
    assignees: propertyManagers,
    loading: didQueryNotLoadYet(reportersResult),
    refetch: reportersResult.refetch
  };
}
