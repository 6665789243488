import { useState, useCallback, useEffect, useRef } from "react";
import { usePopupPosition } from "@ailo/ui";
import { useCurrentUser } from "@ailo/domains";
import { useCurrentAgencyOrg } from "local/common";
import { CreateReiTokenInput, AustralianState } from "local/graphql";
import { useEnvironment, uuid } from "@ailo/services";
import { useReiUrlGenerator } from "./useReiUrl";

type returns = {
  isOpen: boolean;
  show: () => void;
  close: () => void;
  cancel: () => void;
  setConnectModalVisible: (visible: boolean) => void;
};

type Args = {
  onFormCallback?: (data: CreateReiTokenInput) => void;
  onFormCallbackError?: () => void;
};

export function useConnectReiFormsPopup({
  onFormCallback,
  onFormCallbackError
}: Args): returns {
  const [popup, setPopup] = useState<Window | null>(null);
  const { person: currentPerson } = useCurrentUser();
  const currentAgency = useCurrentAgencyOrg();
  const [connectModalVisible, setConnectModalVisible] = useState(false);
  const popupPosition = usePopupPosition(475, 645);
  const { REI_CALLBACK_ORIGIN = window.location.origin } = useEnvironment();
  const stateRef = useRef(uuid());
  const urlGenerator = useReiUrlGenerator(REI_CALLBACK_ORIGIN);

  const show = useCallback(async () => {
    stateRef.current = uuid();
    const url = urlGenerator(stateRef.current);

    if (!url) return;
    const authWindow = window.open(url, "REIForms", `${popupPosition}`);
    setPopup(authWindow);
    setConnectModalVisible(true);
  }, [popupPosition, urlGenerator]);

  const hide = useCallback(() => {
    if (popup && !popup.closed) {
      popup.close();
    }
    setPopup(null);
  }, [popup]);

  const close = useCallback(() => {
    hide();
    setConnectModalVisible(false);
  }, [hide]);

  const cancel = useCallback(() => {
    hide();
    setConnectModalVisible(false);
  }, [hide]);

  useEffect(() => {
    if (!popup) {
      return;
    }

    const interval = setInterval(() => {
      if (popup.closed) {
        cancel();
      }
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, [popup, cancel]);

  useEffect(() => {
    if (!popup) {
      return;
    }

    const messageHandler = (event: MessageEvent): void => {
      if (event.origin === REI_CALLBACK_ORIGIN && event.source === popup) {
        const search = new URLSearchParams(event.data.slice(1));
        const token = search.get("access_token");
        const supportedState = getSupportedStateFromParam(search.get("api"));
        const state = search.get("state");
        const error = search.get("error");

        if (!token || !supportedState || stateRef.current !== state || error) {
          onFormCallbackError?.();
          hide();
          return;
        }

        const data: CreateReiTokenInput = {
          token,
          supportedAustralianState: supportedState,
          personAilorn: currentPerson.ailoRN,
          organisationAilorn: currentAgency.ailoRN
        };

        onFormCallback?.(data);
        hide();
      }
    };

    window.addEventListener("message", messageHandler);

    return () => window.removeEventListener("message", messageHandler);
  }, [
    popup,
    hide,
    currentPerson.ailoRN,
    currentAgency.ailoRN,
    onFormCallback,
    onFormCallbackError,
    REI_CALLBACK_ORIGIN
  ]);

  return {
    isOpen: connectModalVisible,
    show,
    close,
    cancel,
    setConnectModalVisible
  };
}

function getSupportedStateFromParam(
  state?: string | null
): AustralianState | null {
  if (!state) {
    return null;
  }
  const upperCaseState = state.toUpperCase();
  if (!isValidState(upperCaseState)) {
    return null;
  }
  return upperCaseState;
}

function isValidState(state: string): state is AustralianState {
  return Object.values<string>(AustralianState).includes(state);
}
