import { LeftChevronIcon, Text } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";

interface Props {
  style?: StyleProp<ViewStyle>;
  canGoBack: boolean;
  onGoBack?: () => void;
  title: string;
  rightComponent?: React.ReactElement;
}

export function FullPageFormHeader({
  style,
  canGoBack,
  onGoBack,
  title,
  rightComponent
}: Props): ReactElement {
  return (
    <Container style={style}>
      <View style={{ flexDirection: "row" }}>
        {canGoBack && (
          <Button.Secondary
            square
            type={"small"}
            onPress={onGoBack}
            style={{ marginRight: 16, height: 32, width: 32 }}
            testID={"BackButton"}
          >
            <LeftChevronIcon alignCenter />
          </Button.Secondary>
        )}
        <Text.DisplayM weight={"medium"}>{title}</Text.DisplayM>
      </View>
      {rightComponent}
    </Container>
  );
}

const Container = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 32px;
`;
