import React from "react";
import { PropertyKeysFormContent } from "../PropertyKeyFormContent";
import { useCreatePropertyKey } from "./useCreatePropertyKey";

type Props = {
  onDismiss: () => void;
};

export function AddKeyForm({ onDismiss }: Props): React.ReactElement<Props> {
  const { onSubmit, loading } = useCreatePropertyKey();

  return (
    <PropertyKeysFormContent
      onSubmit={onSubmit}
      onDismiss={onDismiss}
      isSubmitting={loading}
      submitText={"Add keys"}
    />
  );
}
