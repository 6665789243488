import { AiloRN } from "@ailo/ailorn";
import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import {
  CreateReiFormFromUserTemplateInput,
  useCreateReiFormFromUserTemplateMutation
} from "local/graphql";
import { useCallback } from "react";

export function useCreateReiFormFromUserTemplate({
  onSuccess
}: {
  onSuccess?: (formAilorn: AiloRN) => void;
}): [
  createReiFormFromUserTemplate: (
    input: CreateReiFormFromUserTemplateInput
  ) => Promise<void>,
  isGenerating: boolean
] {
  const toast = useToastContext();

  const [createReiFormFromUserTemplateMutation, { loading: isGenerating }] =
    useCreateReiFormFromUserTemplateMutation();

  const createReiFormFromUserTemplate = useCallback(
    async ({
      reiUserTemplateId,
      name,
      organisationAilorn,
      tenancyAilorn
    }: CreateReiFormFromUserTemplateInput): Promise<void> => {
      if (isGenerating) return;

      try {
        const result = await createReiFormFromUserTemplateMutation({
          variables: {
            input: {
              name,
              organisationAilorn,
              tenancyAilorn,
              reiUserTemplateId
            }
          }
        });
        throwIfMutationFailed(result, {
          dataKey: "createReiFormFromUserTemplate"
        });

        const formAilorn = result?.data?.createReiFormFromUserTemplate?.ailorn;

        if (!formAilorn) throw new Error("Form could not be generated");

        await onSuccess?.(AiloRN.from(formAilorn));

        toast.show({
          type: "success",
          message: `Form has been generated`
        });
      } catch (error) {
        AiloSentry.captureException(error);
        toast.showFormSubmitError();
      }
    },
    [createReiFormFromUserTemplateMutation, isGenerating, toast, onSuccess]
  );

  return [createReiFormFromUserTemplate, isGenerating];
}
