import { Colors, Pressable, RightChevronIcon, Text } from "@ailo/primitives";
import { useLinkProps } from "@react-navigation/native";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { Report } from "../../VisualizationScreen/reportsDetails";
import {
  BackgroundColor,
  ReportSectionwWrapper,
  getBackgroundColor
} from "./ReportComponentWrappers";

export function ReportCard({
  isLastRow,
  report
}: {
  isLastRow: boolean;
  report: Report;
}): ReactElement {
  const { reportHeading, reportDescription, name } = report;
  const linkProps = useLinkProps({ to: "/reports/v2/" + name });
  return (
    <Pressable {...linkProps}>
      {({ hovered, pressed }) => (
        <BackgroundColor
          backgroundColor={getBackgroundColor({ hovered, pressed })}
        >
          <ReportSectionwWrapper isLastRow={isLastRow}>
            <View style={{ marginRight: "auto", flex: 1 }}>
              <Text.BodyM weight={"medium"}>{reportHeading}</Text.BodyM>
              <Text.BodyS
                color={Colors.TEXT.DARK.SECONDARY}
                numberOfLines={2}
                ellipsizeMode={"tail"}
              >
                {reportDescription}
              </Text.BodyS>
            </View>
            <RightChevronIcon
              style={{
                justifyContent: "flex-start",
                paddingRight: 16
              }}
            />
          </ReportSectionwWrapper>
        </BackgroundColor>
      )}
    </Pressable>
  );
}
