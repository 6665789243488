import { FilesStateFile, useFilesState } from "@ailo/domains";
import { FileKind } from "local/graphql";
import { useCallback, useState } from "react";
import { useProjectAnalytics } from "local/domain/project";

export function useProjectTabFilesState(projectId: string): {
  addFile: () => void;
  files: FilesStateFile[];
  removeFile: (file: FilesStateFile) => void;
  uploadError:
    | {
        title: string;
        message: string;
      }
    | undefined;
  setUploadError: (
    value:
      | {
          title: string;
          message: string;
        }
      | undefined
  ) => void;
} {
  const { onFileUploadStarted, onFileUploadFailed } =
    useProjectAnalytics(projectId);

  const onAddFile = useCallback(
    (file: FilesStateFile) => {
      onFileUploadStarted(file);
    },
    [onFileUploadStarted]
  );

  const [uploadError, setUploadError] = useState<{
    title: string;
    message: string;
  }>();

  const { addFile, files, removeFile } = useFilesState({
    kind: FileKind.ProjectFile,
    async claimFile(file): Promise<void> {
      onAddFile(file);
    },
    onAddFileError(error, file) {
      setUploadError(error);
      onFileUploadFailed(file);
    }
  });

  return { addFile, files, removeFile, uploadError, setUploadError };
}
