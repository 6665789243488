import {
  LoadingSynchroniser,
  useMountedState,
  useSynchronisedLoad
} from "@ailo/primitives";
import { ErrorModal, useToastContext } from "@ailo/ui";
import { MultiStepForm } from "local/common";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useFormContext } from "react-hook-form";
import { LeaseRenewalFormData } from "./LeaseRenewalFormData";
import { LeaseRenewalFormContext } from "./LeaseRenewalFormContext";
import { useSubmit } from "./useSubmit";
import { CreateLeaseRenewalStep, ReviewLeaseRenewalStep } from "./Steps";
import { Action } from "local/domain/project";
import { TenancyAgreementFragment, ActionType } from "local/graphql";
import { useAnalytics } from "@ailo/services";

type StepIds = "Create" | "Review";

type Step = {
  stepId: StepIds;
  render: (visible: boolean) => ReactElement;
};

interface Props {
  action?: Action<ActionType.AssignTenancyAgreement>;
  tenancyId: string;
  agreement?: TenancyAgreementFragment;
  prefillStartDate?: string;
  onDismiss: () => void;
  onSuccess: (rentReviewChecked: boolean, agreementStartDate: string) => void;
  onFormSubmitting: (isSubmitting: boolean) => void;
}

export function LeaseRenewalForm({ ...props }: Props): ReactElement {
  return (
    <LoadingSynchroniser>
      <LeaseRenewalFormContext>
        <LeaseRenewalMultistepForm {...props} />
      </LeaseRenewalFormContext>
    </LoadingSynchroniser>
  );
}

export function LeaseRenewalMultistepForm({
  action,
  tenancyId,
  agreement,
  onDismiss,
  onSuccess,
  prefillStartDate,
  onFormSubmitting
}: Props): ReactElement {
  const Toast = useToastContext();
  const [currentStepNumber, setCurrentStepNumber] = useState<number>(1);
  const [showErrorModal, setShowErrorModal] = useMountedState(false);
  const { formState, trigger, watch } = useFormContext<LeaseRenewalFormData>();
  const { isDirty, isSubmitting } = formState;

  const rentReviewChecked = watch("rentReviewChecked");
  const agreementStartDate = watch("agreementStartDate");

  const isLoading = useSynchronisedLoad(false);

  const analytics = useAnalytics();

  useEffect(() => {
    onFormSubmitting(isSubmitting);
  }, [onFormSubmitting, isSubmitting]);

  const renderStepCreate = useCallback(
    (visible: boolean): ReactElement => (
      <CreateLeaseRenewalStep
        action={action}
        display={visible}
        tenancyId={tenancyId}
        agreement={agreement}
        key={"CreateLeaseRenewalStep"}
        prefillStartDate={prefillStartDate}
      />
    ),

    [action, tenancyId, agreement, prefillStartDate]
  );

  const renderStepReview = useCallback(
    (visible: boolean): ReactElement => (
      <ReviewLeaseRenewalStep
        action={action}
        display={visible}
        key={"ReviewLeaseRenewalStep"}
        setCurrentStepNumber={setCurrentStepNumber}
      />
    ),
    [action]
  );

  const steps: Step[] = useMemo(
    () => [
      {
        stepId: "Create",
        render: renderStepCreate
      },
      {
        stepId: "Review",
        render: renderStepReview
      }
    ],
    [renderStepCreate, renderStepReview]
  );

  const onNextButtonPress = useCallback(
    async (newStepNumber: number) => {
      if (newStepNumber < currentStepNumber) {
        setCurrentStepNumber(newStepNumber);
        return;
      }
      const currentStepId = steps[currentStepNumber - 1].stepId;

      if (currentStepId === "Create") {
        const isValid = await trigger();
        if (isValid) {
          setCurrentStepNumber(newStepNumber);
        }
      }
    },
    [currentStepNumber, steps, trigger]
  );

  const submitSuccess = useCallback(() => {
    Toast.show({
      type: "success",
      message: "Lease renewal updated"
    });
    onSuccess(rentReviewChecked, agreementStartDate);
    analytics.track("Lease Renewal Completed", {
      rentReviewCheckbox: rentReviewChecked
    });
  }, [Toast, agreementStartDate, onSuccess, rentReviewChecked, analytics]);

  const submit = useSubmit({
    action,
    onSuccess: submitSuccess,
    onError: () => {
      setShowErrorModal(true);
    }
  });
  return (
    <>
      <ErrorModal
        visible={showErrorModal}
        onDismiss={(): void => setShowErrorModal(false)}
      />
      <MultiStepForm
        style={{
          maxWidth: 680,
          width: "100%",
          flex: 1,
          alignSelf: "flex-end"
        }}
        title={"Lease renewal"}
        onSubmit={submit}
        submitButtonText={"Save"}
        stepValue={currentStepNumber}
        onChange={onNextButtonPress}
        onCancel={onDismiss}
        isDirty={isDirty}
        steps={steps.map((step) => step.render)}
        headerBackButtonDisabled
        hideCancelButton
        showFooterBackButton
        submitButtonDisabled={isLoading}
      />
    </>
  );
}
