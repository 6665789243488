import moment from "moment";
import React, { FC } from "react";
import {
  EmptyTransactionsList,
  ManagementLedgerDetails,
  TenancyManagementLedgerDetails
} from "./components";
import { useGetTenancyByManagementQuery } from "local/graphql";

interface Props {
  managementId: string;
  onLedgerDetailEntryPress: (liabilityEntryId: string) => void;
  onMoneyManagementSettingsPress: () => void;
}

export const LedgerDetails: FC<Props> = ({
  managementId,
  onLedgerDetailEntryPress,
  onMoneyManagementSettingsPress
}) => {
  const { data, loading } = useGetTenancyByManagementQuery({
    variables: { id: managementId }
  });

  if (loading && !data) {
    return <Loading />;
  }

  if (!data?.management) {
    throw new Error(
      `[Property Ledger Details] Management "${managementId}" not found`
    );
  }

  const initialTenancyId = getInitialTenancyId(
    data.management.mostRecentTenancy?.id,
    data.management.mostRecentTenancy?.endDate,
    data.management.nextTenancy?.id
  );

  return (
    <>
      {initialTenancyId ? (
        <TenancyManagementLedgerDetails
          initialTenancyId={initialTenancyId}
          managementId={managementId}
          onMoneyManagementSettingsPress={onMoneyManagementSettingsPress}
          onLedgerDetailEntryPress={onLedgerDetailEntryPress}
        />
      ) : (
        <ManagementLedgerDetails
          managementId={managementId}
          emptyView={EmptyTransactionsList}
        />
      )}
    </>
  );
};

function getInitialTenancyId(
  mostRecentId?: string,
  mostRecentTenancyEndDate?: string | null,
  nextId?: string
): string | undefined {
  if (mostRecentId) {
    if (!mostRecentTenancyEndDate) return mostRecentId;

    if (moment().isSameOrBefore(moment(mostRecentTenancyEndDate), "day"))
      return mostRecentId;

    if (nextId) return nextId;

    return mostRecentId;
  }

  if (nextId) return nextId;

  return undefined;
}

const Loading: FC = () => <TenancyManagementLedgerDetails.Loading />;
