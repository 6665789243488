import { useNavigation, Screens } from "local/common";

export function useOpenAddBillSidebar(): () => void {
  const navigation = useNavigation();

  return () => {
    navigation.navigate(Screens.BillsTab, {
      billId: "new"
    });
  };
}
