import { Colors, Text } from "@ailo/primitives";
import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";

interface Props {
  style?: StyleProp<ViewStyle>;
  header: string;
  subheader?: string;
}

export function FullPageHeader({
  style,
  header,
  subheader
}: Props): ReactElement {
  return (
    <Container style={style}>
      <View style={{ alignItems: "flex-start", flex: 1 }}>
        <Text.DisplayM color={Colors.TEXT.DARK.PRIMARY}>{header}</Text.DisplayM>
        {subheader && (
          <Text.BodyM
            style={{ paddingTop: 12 }}
            color={Colors.TEXT.DARK.SECONDARY}
          >
            {subheader}
          </Text.BodyM>
        )}
      </View>
    </Container>
  );
}

const Container = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 32px;
`;
