import React, { useMemo } from "react";
import {
  BadgeQueryParams,
  Screens,
  useNavigation,
  useRoute
} from "local/common";
import { withScreenComponent } from "@ailo/services";
import { useHasFeature } from "@ailo/domains";
import { ContactsTabScreenHeader } from "./ContactsTabScreenHeader";
import { CustomersList } from "./CustomersList";
import { ContactsTabView } from "../../common/navigation/screenParams/ContactsTabStackNavigatorParamList";
import { CustomersListBadge } from "./CustomersList/CustomersListBadge";
import { PlatformFeatureId } from "local/graphql";

interface ContactsTabData {
  label: ContactsTabView;
  listComponent: React.ComponentType<{
    tabNavigation: (queryString?: string) => React.ReactElement;
  }>;
  badge?: React.ComponentType<{
    active?: boolean;
    params?: BadgeQueryParams;
  }>;
}

const ContactsTabScreen = withScreenComponent(
  { analytics: false },
  (): React.ReactElement | null => {
    const navigation = useNavigation<Screens.ContactsTab>();
    const route = useRoute<Screens.ContactsTab>();
    const initialTab = route.params?.tab ?? ContactsTabView.CUSTOMERS;

    const hasCustomersFeature = useHasFeature(
      PlatformFeatureId.ContactsTabCustomers
    );

    const contactsTabData = useMemo(() => {
      const tabs: ContactsTabData[] = [];

      if (hasCustomersFeature) {
        tabs.push({
          label: ContactsTabView.CUSTOMERS,
          listComponent: CustomersList,
          badge: CustomersListBadge
        });
      }

      return tabs;
    }, [hasCustomersFeature]);

    const currentIndex = contactsTabData.findIndex((data) =>
      data.label.toLowerCase().includes(initialTab.toLowerCase())
    );

    if (currentIndex < 0) {
      navigation.setParams({ tab: ContactsTabView.CUSTOMERS });
      return null;
    }

    const { listComponent: ListComponent } = contactsTabData[currentIndex];

    return (
      <ListComponent
        tabNavigation={(queryString?: string) => (
          <ContactsTabScreenHeader
            tabs={contactsTabData}
            queryString={queryString}
            selectedTabIndex={currentIndex}
          />
        )}
      />
    );
  }
);

export { ContactsTabScreen };
