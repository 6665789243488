import React, { ReactElement } from "react";
import { ListItem, ListItemStyle } from "@ailo/ui";
import { RightChevronIcon } from "@ailo/primitives";
import { ProjectStatus } from "local/graphql";
import { ProjectBadges } from "local/domain/project";
import {
  PressableNavigationProps,
  usePressableNavigation
} from "@ailo/services";
import { ProjectIconCard } from "./ProjectIconCard";

interface Project {
  id: string;
  name: string;
  status: ProjectStatus;
  reference: string;
}

interface Props extends PressableNavigationProps<Project> {
  project: Project;
  style?: ListItemStyle;
}

export function ProjectListItem({
  project,
  style,
  onPress,
  link
}: Props): ReactElement {
  const pressableProps = usePressableNavigation({
    link,
    data: project,
    onPress
  });

  return (
    <ListItem
      header={project.name}
      subHeader={<ProjectBadges project={project} />}
      leftComponent={<ProjectIconCard />}
      rightComponent={<RightChevronIcon width={20} height={20} />}
      style={style}
      {...pressableProps}
    />
  );
}

ProjectListItem.Loading = function ProjectListItemLoading() {
  return (
    <ListItem.Loading
      leftComponent={true}
      subHeader={true}
      style={{ borderType: "none" }}
    />
  );
};
