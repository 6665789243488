import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { Card } from "@ailo/ui";
import { Colors, Text, VirtualizedScrollView } from "@ailo/primitives";

interface Props {
  headerCaption: (showGridHeader: boolean) => React.ReactElement;
  styledHeader: React.ReactElement;
}

const PropertyOnboardingListEmptyFilterResults = ({
  headerCaption,
  styledHeader
}: Props): ReactElement => (
  <StyledScrollViewContainer stickyHeaderIndices={[1]}>
    {headerCaption(true)}
    {styledHeader}
    <Body>
      <StyledBodyL weight={"medium"}>
        {"We couldn’t find anything matching your search"}
      </StyledBodyL>
      <StyledBodyS color={Colors.TEXT.DARK.SECONDARY}>
        {"Please try a different search term"}
      </StyledBodyS>
    </Body>
  </StyledScrollViewContainer>
);

const StyledScrollViewContainer = styled(VirtualizedScrollView)`
  flex: 1;
  padding-top: 0;
  padding-right: 60px;
  padding-left: 60px;
  margin-top: -1px;
  width: 100%;
  background-color: ${Colors.CLOUD};
`;

const Body = styled(Card)`
  justify-content: center;
  align-items: center;
  height: 360px;
  margin: -46px 0 0 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding-top: 46px;
  box-shadow: 0px 1px 4px rgba(28, 30, 38, 0.2);
`;

const StyledBodyL = styled(Text.BodyL)`
  margin-bottom: 8px;
  text-align: center;
  margin-top: 16px;
`;

const StyledBodyS = styled(Text.BodyS)`
  text-align: center;
`;

export { PropertyOnboardingListEmptyFilterResults };
