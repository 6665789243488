import { getExpenseStatusBadgeType, getExpenseStatusText } from "@ailo/domains";
import { didQueryNotLoadYet, didQuerySucceed } from "@ailo/services";
import { Money } from "@ailo/ui";
import React, { useCallback } from "react";
import { isPresent } from "ts-is-present";
import { BillDataFetchError } from "../BillDataFetchError";
import { BillHeaderUI } from "./BillHeaderUI";
import { useGetBillHeaderDataQuery } from "local/graphql";

interface BillHeaderProps {
  billId: string;
  title?: string;
  showArchiveButton?: boolean;
  fetchPolicy?: "cache-first" | "cache-and-network";
  onGoBack?(): void;
  onClose?(): void;
  onArchiveBillPress?(billId: string): void;
}

export function BillHeader({
  billId,
  title,
  showArchiveButton = false,
  fetchPolicy = "cache-and-network",
  onGoBack,
  onClose,
  onArchiveBillPress
}: BillHeaderProps): React.ReactElement | null {
  const result = useGetBillHeaderDataQuery({
    variables: {
      billId
    },
    fetchPolicy
  });

  const onPress = useCallback(() => {
    onArchiveBillPress?.(billId);
  }, [onArchiveBillPress, billId]);

  if (didQueryNotLoadYet(result)) {
    return <BillHeader.Loading />;
  }

  if (!didQuerySucceed(result)) {
    if (result.error) {
      throw new BillDataFetchError(result.error.message, result.error);
    }
    throw new BillDataFetchError("useGetBillHeaderDataQuery() failed to load");
  }

  const billData = result.data.billById;
  if (!billData) {
    throw new TypeError(`billData is undefined`);
  }

  const payeeName =
    billData.supplier?.internalLegalEntity?.organisation?.name ||
    billData.supplier?.name;
  if (!payeeName) {
    throw new TypeError(`payeeName is undefined`);
  }

  const statusBadge = {
    testID: "Status",
    type: getExpenseStatusBadgeType(billData.agencyStatus),
    text: getExpenseStatusText(billData.agencyStatus)
  };
  const badges = [statusBadge].filter(isPresent);

  const archiveButton =
    showArchiveButton && billData.archivableByOrganisation
      ? {
          label: "Cancel Bill",
          onPress: onPress
        }
      : undefined;
  const rightButtons = [archiveButton].filter(isPresent);

  return (
    <BillHeaderUI
      title={title}
      payeeName={payeeName}
      totalAmount={Money.fromCents(result.data.billById.amount.cents)}
      badges={badges}
      rightButtons={rightButtons}
      onGoBack={onGoBack}
      onClose={onClose}
    />
  );
}

BillHeader.Loading = BillHeaderUI.Loading;
