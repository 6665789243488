import { useToastContext } from "@ailo/ui";
import { useGenerateInspectionReportMutation } from "local/graphql";
import { useCallback } from "react";

export function useGenerateInspectionReport(): [(id: string) => void, boolean] {
  const toast = useToastContext();
  const [generateInspectionReportMutation, { loading: isGenerating }] =
    useGenerateInspectionReportMutation();

  const mutationCallback = useCallback(
    async (inspectionId: string) => {
      if (isGenerating) {
        return;
      }
      const result = await generateInspectionReportMutation({
        variables: {
          inspectionId
        }
      });

      if (result.errors?.length && !result.data) {
        toast.show({
          type: "error",
          message: "Failed to generate report. Please try again."
        });
      } else {
        toast.show({
          type: "success",
          message: "Preparing the report."
        });
      }
    },
    [generateInspectionReportMutation, toast, isGenerating]
  );

  return [mutationCallback, isGenerating];
}
