import React, { ReactElement } from "react";
import { Text } from "@ailo/primitives";
import { CellContainer } from "@ailo/ui";
import { ProjectListRow } from "../ProjectList";

export function PropertyCell({
  row: { reference }
}: {
  row: ProjectListRow;
  rowIndex: number;
}): ReactElement {
  return (
    <CellContainer>
      <Text.BodyS>{reference}</Text.BodyS>
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <Text.BodyS.Loading width={80} />
    </CellContainer>
  );
}

PropertyCell.Loading = Loading;
