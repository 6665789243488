import React from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import {
  opacify,
  CloseIcon,
  LeftArrow,
  Text,
  Colors,
  HouseAndDocumentImage
} from "@ailo/primitives";
import { Button, Money, MoneyInterface, Badge, BadgeType } from "@ailo/ui";

export interface BillHeaderUIProps {
  title?: string;
  payeeName: string;
  totalAmount: MoneyInterface;
  badges?: Array<{
    type?: BadgeType;
    text: string;
    testID?: string;
  }>;
  rightButtons?: Array<{
    label: string;
    onPress?(): void;
  }>;
  onGoBack?(): void;
  onClose?(): void;
}

const StyledContainer = styled(View)`
  background-color: ${opacify(Colors.LIGHT_BLUE, 0.1)};
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
`;

const StyledTitleRow = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const StyledTitleButtons = styled(View)`
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledTextAndImageRow = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledLeft = styled(View)`
  padding-bottom: 20px;
`;

const StyledBadgesRow = styled(View)`
  margin-top: 8px;
  flex-direction: row;
`;

export function BillHeaderUI({
  title,
  payeeName,
  totalAmount,
  badges = [],
  rightButtons = [],
  onGoBack,
  onClose
}: BillHeaderUIProps): React.ReactElement {
  return (
    <StyledContainer testID={"bill-header"}>
      <StyledTitleRow>
        {onGoBack && (
          <StyledTitleButtons>
            {onGoBack && (
              <Button.Secondary
                type={"small"}
                square
                onPress={onGoBack}
                style={{ marginRight: 16 }}
              >
                <LeftArrow width={20} height={20} />
              </Button.Secondary>
            )}
          </StyledTitleButtons>
        )}

        {title && (
          <Text.DisplayS
            weight={"medium"}
            color={Colors.TEXT.DARK.PRIMARY}
            style={{ marginRight: "auto" }}
          >
            {title}
          </Text.DisplayS>
        )}

        {(rightButtons.length > 0 || onClose) && (
          <StyledTitleButtons style={{ marginLeft: "auto" }}>
            {rightButtons.map((button, index) => (
              <Button.Secondary
                type={"small"}
                key={index}
                onPress={button.onPress}
                style={{ marginLeft: index === 0 ? 0 : 8 }}
              >
                {button.label}
              </Button.Secondary>
            ))}
            {onClose && (
              <Button.Secondary
                type={"small"}
                square
                onPress={onClose}
                style={{ marginLeft: 8 }}
              >
                <CloseIcon width={20} height={20} />
              </Button.Secondary>
            )}
          </StyledTitleButtons>
        )}
      </StyledTitleRow>

      <StyledTextAndImageRow>
        <StyledLeft>
          <Text.BodyM
            style={{ marginTop: 20, color: opacify(Colors.SPACE_BLACK, 0.6) }}
          >
            {payeeName}
          </Text.BodyM>
          <Text.DisplayL style={{ marginTop: 12 }} testID={"total-amount-text"}>
            {Money.from(totalAmount).format()}
          </Text.DisplayL>

          {badges.length > 0 && (
            <StyledBadgesRow>
              {badges.map((badge, index) => (
                <Badge
                  key={index}
                  type={badge.type}
                  style={{ marginLeft: index === 0 ? 0 : 4 }}
                  testID={badge.testID}
                >
                  {badge.text}
                </Badge>
              ))}
            </StyledBadgesRow>
          )}
        </StyledLeft>

        <HouseAndDocumentImage width={128} height={80} />
      </StyledTextAndImageRow>
    </StyledContainer>
  );
}

BillHeaderUI.Loading = function BillHeaderUILoading({
  onGoBack,
  title,
  onClose
}: Pick<
  BillHeaderUIProps,
  "title" | "onGoBack" | "onClose"
>): React.ReactElement {
  return (
    <StyledContainer>
      <StyledTitleRow>
        {onGoBack && (
          <StyledTitleButtons>
            {onGoBack && (
              <Button.Secondary
                type={"small"}
                square
                onPress={onGoBack}
                style={{ marginRight: 16 }}
              >
                <LeftArrow width={20} height={20} />
              </Button.Secondary>
            )}
          </StyledTitleButtons>
        )}

        {title && (
          <Text.DisplayS
            weight={"medium"}
            color={Colors.TEXT.DARK.PRIMARY}
            style={{ marginRight: "auto" }}
          >
            {title}
          </Text.DisplayS>
        )}

        {onClose && (
          <StyledTitleButtons style={{ marginLeft: "auto" }}>
            <Button.Loading
              type={"small"}
              style={{ marginRight: 8, width: 110 }}
            />
            {onClose && (
              <Button.Secondary
                type={"small"}
                square
                onPress={onClose}
                style={{ marginLeft: 8 }}
              >
                <CloseIcon width={20} height={20} />
              </Button.Secondary>
            )}
          </StyledTitleButtons>
        )}
      </StyledTitleRow>

      <StyledTextAndImageRow>
        <StyledLeft>
          <Text.BodyM.Loading style={{ marginTop: 20, width: 200 }} />
          <Text.DisplayL.Loading style={{ marginTop: 12, width: 120 }} />

          <StyledBadgesRow>
            <Badge.Loading width={80} />
          </StyledBadgesRow>
        </StyledLeft>
      </StyledTextAndImageRow>
    </StyledContainer>
  );
};
