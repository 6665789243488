import { AFC } from "@ailo/primitives";
import React from "react";
import { TextCell } from "@ailo/ui";

interface Props {
  name: string;
}

interface Statics {
  Loading: React.FC;
}

const Payer: AFC<Props> & Statics = ({ name }) => {
  return <TextCell>{name}</TextCell>;
};

Payer.Loading = TextCell.Loading;

export { Payer };
