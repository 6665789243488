import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { useCallback } from "react";

import {
  ReverseRentAdjustmentMutation,
  useReverseRentAdjustmentMutation
} from "local/graphql";

import { ReverseRentAdjustmentFormData } from "./ReverseRentAdjustmentModal";

export function useReverseRentAdjustment({
  onCompleted,
  onError
}: {
  onCompleted?: () => void;
  onError?: (error?: unknown) => void;
}): [
  ({
    formData,
    adjustmentId
  }: {
    formData: ReverseRentAdjustmentFormData;
    adjustmentId: string;
  }) => Promise<void>,
  MutationResult<ReverseRentAdjustmentMutation>
] {
  const analytics = useAnalytics();
  const [reverseAdjustment, result] = useReverseRentAdjustmentMutation();

  const reversal = useCallback(
    async ({
      formData: { description },
      adjustmentId
    }: {
      formData: ReverseRentAdjustmentFormData;
      adjustmentId: string;
    }): Promise<void> => {
      try {
        const result = await reverseAdjustment({
          variables: {
            input: {
              description: description,
              adjustmentId
            }
          }
        });

        throwIfMutationFailed(result, { dataKey: "reverseAdjustment" });
      } catch (error) {
        AiloSentry.captureException(error);
        return onError?.(error);
      }
      analytics.trackRentCreditReversed();
      onCompleted?.();
    },
    [reverseAdjustment, onCompleted, onError, analytics]
  );

  return [reversal, result];
}
