import React, { ReactElement } from "react";
import { Colors, TeamIcon, Text } from "@ailo/primitives";
import {
  ManagementPortfolioContentCard,
  ManagementPortfolioContentProps
} from "../ManagementPortfolioScreen";
import { useGetManagementPortfolioTeams } from "./useGetManagementPortfolioTeams";
import styled from "styled-components/native";
import { View } from "react-native";
import { useManagementPortfolioTeamsSummary } from "./useManagementPortfolioTeamsSummary";

export function ManagementPortfolioHeader({
  portfolioAilorn
}: ManagementPortfolioContentProps): ReactElement {
  const { data, loading, error, refetch } =
    useGetManagementPortfolioTeams(portfolioAilorn);
  const teamsSummary = useManagementPortfolioTeamsSummary(data);

  if (loading) {
    return (
      <>
        <Text.DisplayM.Loading width={120} />
        <Text.BodyM.Loading width={120} />
      </>
    );
  }
  if (error || !data) {
    return (
      <ManagementPortfolioContentCard.ErrorContent
        entityDisplayType={"Teams"}
        onRetry={() => {
          refetch();
        }}
      />
    );
  }
  return (
    <>
      <Text.DisplayM weight={"medium"}>
        {`Portfolio ${portfolioAilorn.internalId}`}
      </Text.DisplayM>
      <StyledContainer>
        <StyledTeamIcon color={Colors.TEXT.DARK.SECONDARY} />
        <Text.DisplayS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
          {teamsSummary}
        </Text.DisplayS>
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 24px;
`;

const StyledTeamIcon = styled(TeamIcon)`
  margin-right: 8px;
`;
