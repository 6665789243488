import React, { FC } from "react";
import { AlertScreen, Money } from "@ailo/ui";
import { BankAccountMethod } from "@ailo/domains";
import { useEnvironment } from "@ailo/services";
import { Colors, Text } from "@ailo/primitives";
import { Platform } from "react-native";

interface Props {
  bankAccount?: BankAccountMethod;
  amount: number;
  channel?: string;
}

const TransferSuccess: FC<Props> = ({ bankAccount, amount, channel }) => {
  const displayAmount = Money.fromCents(amount).format();

  const isNppTransfer = channel && channel.toUpperCase() === "NPP";
  const clearanceMessage = isNppTransfer
    ? " Transfers are typically available within minutes, but may take 1-2 business days. "
    : " Please allow 1 - 2 business days for funds to be accessible.";

  const body = `${displayAmount} has successfully been transferred from your wallet to ${
    bankAccount?.accountNumber ? "the" : "your"
  } bank account${
    bankAccount?.accountNumber ? " " + bankAccount.accountNumber : ""
  }. ${clearanceMessage}`;

  const description = (
    <>
      {body}
      {isNppTransfer ? <LearnMoreLink /> : undefined}
    </>
  );

  return (
    <AlertScreen
      title={"Funds Transferred"}
      type={"house-and-document"}
      description={description}
    />
  );
};

const LearnMoreLink: FC = () => {
  const { WALLET_TRANSFER_TIMING_HELP_URL } = useEnvironment();

  return (
    <Text.BodyS
      onPress={() =>
        Platform.OS === "web" &&
        window.open(WALLET_TRANSFER_TIMING_HELP_URL, "_blank")
      }
      color={Colors.AILO_RED}
      weight={"medium"}
      style={{ textDecorationLine: "underline" }}
    >
      {"Learn more."}
    </Text.BodyS>
  );
};

export { TransferSuccess };
