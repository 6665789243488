import React from "react";
import { TouchableOpacity } from "react-native";
import { SFC, Colors, ClearIcon, opacify } from "@ailo/primitives";

interface Props {
  onPress: () => void;
}

const LineItemClearButton: SFC<Props> = ({ onPress, style }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[{ cursor: "pointer" } as {}, style]}
      testID={"LineItemClearButton"}
    >
      <ClearIcon color={opacify(Colors.SPACE_BLACK, 0.6)} />
    </TouchableOpacity>
  );
};

export { LineItemClearButton };
