import { ProjectDetailsFragment } from "local/graphql";
import React from "react";
import { CreateProjectForm } from "./CreateProjectForm/CreateProjectForm";
import { ProjectFormData } from "./ProjectFormData";
import { UpdateProjectForm } from "./UpdateProjectForm/UpdateProjectForm";

interface Props {
  project?: ProjectDetailsFragment;
  prefillValues?: ProjectFormData;
  onDismiss: () => void;
  onSuccess?: (projectId: string) => void;
  onFormSubmitting: (isSubmitting: boolean) => void;
  setSubmitError: (hasError: boolean) => void;
}
export function CreateOrUpdateProjectForm({
  project,
  onDismiss,
  onSuccess,
  onFormSubmitting,
  setSubmitError
}: Props): React.ReactElement {
  if (project) {
    return (
      <UpdateProjectForm
        project={project}
        onFormSubmitting={onFormSubmitting}
        onDismiss={onDismiss}
        setSubmitError={setSubmitError}
        onSuccess={onSuccess}
      />
    );
  }

  return (
    <CreateProjectForm
      onFormSubmitting={onFormSubmitting}
      onDismiss={onDismiss}
      setSubmitError={setSubmitError}
      onSuccess={onSuccess}
    />
  );
}
