import { LocalDate } from "@ailo/date";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { isPresent } from "ts-is-present";
import { Section } from "./Section";

interface Props {
  voidedAt?: LocalDate;
  startDate?: LocalDate;
  agreementStartDate?: LocalDate;
  agreementEndDate?: LocalDate;
  agreementAllowedToLapse: boolean;
  nextAgreementStartDate?: LocalDate;
  style?: StyleProp<ViewStyle>;
}

export function TenancyAgreementSection({
  style,
  voidedAt,
  startDate,
  agreementStartDate,
  agreementEndDate,
  nextAgreementStartDate
}: Props): React.ReactElement {
  const periodicSince =
    agreementEndDate && agreementEndDate.isBefore(LocalDate.today())
      ? agreementEndDate.add(1, "day")
      : undefined;

  const items = [
    voidedAt && {
      label: "Tenancy voided",
      description: voidedAt.format("D MMM YYYY")
    },
    startDate && {
      label: startDate.isAfter(LocalDate.today())
        ? "Tenancy starting"
        : "Occupied since",
      description: startDate.format("D MMM YYYY")
    },
    agreementStartDate && {
      label: agreementEndDate ? "Agreement start" : "Periodic agreement since",
      description: agreementStartDate.format("D MMM YYYY")
    },
    agreementEndDate && {
      label: "Agreement expiry",
      description: agreementEndDate.format("D MMM YYYY")
    },
    periodicSince && {
      label: "Periodic since",
      description: periodicSince.format("D MMM YYYY")
    },
    nextAgreementStartDate && {
      label: "New agreement start",
      description: nextAgreementStartDate.format("D MMM YYYY")
    }
  ];

  return (
    <Section
      style={style}
      title={"Details"}
      descriptionListItems={items.filter(isPresent)}
    />
  );
}
