import { FilterBar, RadioButtonFilterInput } from "@ailo/ui";
import React, { Dispatch, ReactElement, SetStateAction, useMemo } from "react";
import { AdjustmentListFilter } from "./types";

const valueToLabelOptionDefinitions: Record<AdjustmentListFilter, string> = {
  current: "Current",
  all: "All adjustments"
};

export function AdjustmentsFilterBar({
  filter,
  setFilter
}: {
  filter: AdjustmentListFilter;
  setFilter: Dispatch<SetStateAction<AdjustmentListFilter>>;
}): ReactElement {
  const filterOptions = useMemo(() => {
    return (
      Object.keys(valueToLabelOptionDefinitions) as AdjustmentListFilter[]
    ).map((val: AdjustmentListFilter) => ({
      label: valueToLabelOptionDefinitions[val],
      value: val,
      onSelect: () => setFilter(val)
    }));
  }, [setFilter]);

  return (
    <FilterBar>
      <RadioButtonFilterInput
        options={filterOptions}
        defaultValue={valueToLabelOptionDefinitions[filter]}
        dropdownMenuAlign={"start"}
      />
    </FilterBar>
  );
}
