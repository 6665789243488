import { useCurrentAgencyOrg } from "local/common";
import React from "react";

import { useReportQuery, useReportTeamContext } from "../..";

import { usePropertyIssuesMissingFeesReportQuery } from "local/graphql";
import { ReportPage } from "../../components";
import { FilterComponentType } from "../../components/ReportPage/FilterFactory";
import { reports } from "../../reportsDetails";
import { propertyRowLink } from "../../components/ReportPage/utils/tableNavigation";

export function PropertyIssuesMissingFeesTable(): React.ReactElement {
  const { id } = useCurrentAgencyOrg();
  const { teamId } = useReportTeamContext();
  const queryResult = useReportQuery(usePropertyIssuesMissingFeesReportQuery, {
    managingOrgId: id,
    teamId
  });
  const reportData = {
    ...queryResult,
    rows: queryResult.data?.propertyIssuesMissingFeesReport?.rows?.map((r) => ({
      key: `${r.managementId}|${r.feeType}|${r.reason}`,
      ...r
    })),
    columns: queryResult.data?.propertyIssuesMissingFeesReport?.columns
  };
  type Row = NonNullable<typeof reportData["rows"]>[number];

  return (
    <ReportPage<Row>
      title={reports.property_issues_missing_fee.reportHeading}
      description={
        "The following properties are not being charged one or more types of annual and/or monthly recurring fees"
      }
      data={reportData}
      filterConfig={[
        {
          name: "search",
          columns: ["propertyAddress", "investorNames"],
          type: FilterComponentType.Search,
          defaultValue: "",
          placeholder: "Search properties"
        },
        {
          name: "teamFilter",
          type: FilterComponentType.Team,
          defaultValue: "",
          columns: []
        },
        {
          name: "Frequency",
          namePlural: "Frequencies",
          columns: ["feeType"],
          defaultValue: [],
          menuWidth: 300,
          type: FilterComponentType.MultiSelect
        },
        {
          name: "Reason",
          namePlural: "Reasons",
          columns: ["reason"],
          defaultValue: [],
          type: FilterComponentType.MultiSelect
        }
      ]}
      columnsConfig={{
        propertyAddress: {
          width: 2
        },
        reason: {
          maxWidth: 168
        },
        feeType: {
          header: "Frequency not being charged"
        }
      }}
      rowLink={propertyRowLink}
    />
  );
}
