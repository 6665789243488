import { useLinkProps, CommonActions } from "@react-navigation/native";
import { Screens } from "local/common";
import { Maybe } from "local/graphql";
import { GridRow, ReportTableRow } from "../ReportTable";

interface HasManagement {
  managementId?: Maybe<string>;
}

export type NavigateCfg = Parameters<typeof useLinkProps>[0];

export const propertyRowLink = <TRow extends ReportTableRow & HasManagement>(
  row: GridRow<TRow>
): NavigateCfg | null =>
  row.managementId
    ? {
        to: "/reports",
        action: CommonActions.navigate(Screens.AppNavigator, {
          screen: Screens.PropertiesTab,
          params: {
            screen: Screens.Property,
            params: {
              managementId: row.managementId,
              vacateTenancyId: undefined,
              rentReviewTenancyId: undefined,
              tenancyDetails: undefined
            }
          }
        })
      }
    : null;

export const billIssueRowLink = <
  TRow extends ReportTableRow & {
    billIssue: string;
    drilldownFields?: string[];
  }
>(
  row: GridRow<TRow>
): NavigateCfg => ({
  to: "/reports",
  action: CommonActions.navigate({
    name: Screens.ReportVisulisation,
    key: row.billIssue,
    params: {
      reportName: "bills_due",
      billIssue: row.billIssue,
      billColumns: row.drilldownFields?.join(",")
    }
  })
});

export const billRowLink = <TRow extends { billId: string }>(
  row: GridRow<TRow>
): NavigateCfg => ({
  to: "/reports",
  action: CommonActions.navigate(Screens.BillsTab, {
    tab: "upcoming",
    billId: row.billId
  })
});
