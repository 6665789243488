import React, { ReactElement } from "react";
import { ActionType } from "local/graphql";
import { ReminderActionActivity } from "./ReminderActionActivity";
import { ActionActivityProps } from "./ActionActivityProps";
import { AssignFormActionActivity } from "./AssignFormActionActivity";
import { AssignTenancyAgreementActionActivity } from "./LeaseRenewalActionActivity";
import { isActionOfTypeWithMeta } from "local/domain/project";
import { AssignRentActionActivity } from "./AssignRentActionActivity";
import { AssignTenancyActionActivity } from "./AssignTenancyActionActivity";
import { RequestSignatureActionActivity } from "./RequestSignatureActionActivity";
import { ManageDepositActionActivity } from "./ManageDepositActionActivity";
import { TrackDocusignActionActivity } from "./TrackDocusignActionActivity";
import { AssignNewManagementActionActivity } from "./AssignNewManagementActionActivity";
import { TrackInspectionCompletedActionActivity } from "./TrackInspectionCompletedActionActivity";
import { GenerateInspectionReportActionActivity } from "./GenerateInspectionReportActionActivity";
import { EndTenancyActionActivity } from "./EndTenancyActionActivity";
import { ConfirmInspectionScheduleActionActivity } from "./ConfirmInspectionScheduleActionActivity";
import { AssignMessageActionActivity } from "./AssignMessageActionActivity";

export function ActionActivity(props: ActionActivityProps): ReactElement {
  const { action, projectId } = props;
  if (isActionOfTypeWithMeta(action, ActionType.AssignForm)) {
    return <AssignFormActionActivity action={action} projectId={projectId} />;
  }

  if (isActionOfTypeWithMeta(action, ActionType.AssignTenancyAgreement)) {
    return (
      <AssignTenancyAgreementActionActivity
        action={action}
        projectId={projectId}
      />
    );
  }

  if (isActionOfTypeWithMeta(action, ActionType.AssignRent)) {
    return <AssignRentActionActivity action={action} projectId={projectId} />;
  }

  if (isActionOfTypeWithMeta(action, ActionType.AssignTenancy)) {
    return (
      <AssignTenancyActionActivity action={action} projectId={projectId} />
    );
  }

  if (isActionOfTypeWithMeta(action, ActionType.AssignNewManagement)) {
    return (
      <AssignNewManagementActionActivity
        action={action}
        projectId={projectId}
      />
    );
  }

  if (isActionOfTypeWithMeta(action, ActionType.RequestSignature)) {
    return (
      <RequestSignatureActionActivity action={action} projectId={projectId} />
    );
  }

  if (isActionOfTypeWithMeta(action, ActionType.ManageDeposit)) {
    return (
      <ManageDepositActionActivity action={action} projectId={projectId} />
    );
  }

  if (isActionOfTypeWithMeta(action, ActionType.TrackDocusign)) {
    return (
      <TrackDocusignActionActivity action={action} projectId={projectId} />
    );
  }

  if (isActionOfTypeWithMeta(action, ActionType.TrackInspectionCompleted)) {
    return (
      <TrackInspectionCompletedActionActivity
        action={action}
        projectId={projectId}
      />
    );
  }

  if (isActionOfTypeWithMeta(action, ActionType.GenerateInspectionReport)) {
    return (
      <GenerateInspectionReportActionActivity
        action={action}
        projectId={projectId}
      />
    );
  }

  if (isActionOfTypeWithMeta(action, ActionType.EndTenancy)) {
    return <EndTenancyActionActivity action={action} projectId={projectId} />;
  }

  if (isActionOfTypeWithMeta(action, ActionType.ConfirmInspectionSchedule)) {
    return (
      <ConfirmInspectionScheduleActionActivity
        action={action}
        projectId={projectId}
      />
    );
  }

  if (isActionOfTypeWithMeta(action, ActionType.AssignMessage)) {
    return (
      <AssignMessageActionActivity action={action} projectId={projectId} />
    );
  }

  return <ReminderActionActivity action={action} projectId={projectId} />;
}
