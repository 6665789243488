import { Colors } from "@ailo/primitives";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";

export function FilterHeaderWithTabNavigation({
  tabNavigation,
  filterElements
}: {
  tabNavigation: React.ReactElement;
  filterElements?: React.ReactElement[];
}): React.ReactElement {
  if (!filterElements || filterElements.length === 0) {
    return tabNavigation;
  }

  const filtersComponent = (
    <PropertyListFiltersContainer>
      {filterElements.map((element, index) =>
        index > 0 ? <View style={{ marginLeft: 12 }}>{element}</View> : element
      )}
    </PropertyListFiltersContainer>
  );

  return (
    <React.Fragment key={"project-list-header-caption"}>
      {tabNavigation}
      {filtersComponent}
    </React.Fragment>
  );
}

const PropertyListFiltersContainer = styled(View)`
  display: flex;
  justify-content: flex-end;
  background-color: ${Colors.WHITE};
  padding: 16px 16px 0 16px;
  box-shadow: 0px 1px 4px rgba(28, 30, 38, 0.2);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;
