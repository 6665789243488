import { formatPhoneNumber } from "@ailo/domains";
import React from "react";
import { CustomersListRow } from "../CustomersList/useCustomersListQuery";
import { TextCell } from "@ailo/ui";

function PhoneNumberCell({
  row: { phoneNo }
}: {
  row: CustomersListRow;
}): React.ReactElement {
  return (
    <TextCell>
      {phoneNo && phoneNo.trim().length != 0
        ? formatPhoneNumber(phoneNo)
        : "Not supplied"}
    </TextCell>
  );
}

PhoneNumberCell.Loading = TextCell.Loading;

export { PhoneNumberCell };
