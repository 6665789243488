import { ReiFormRemoteSignerFragment } from "local/graphql";
import React, { ReactElement } from "react";
import { Text } from "@ailo/primitives";
import { LocalDate } from "@ailo/date";
import {
  DocusignSignatureTrackingList,
  ReiFormButtons,
  ReiFormDownloadSignedButton
} from "local/domain/project";
import { ActionActivityContainer } from "../..";
export function SigningRequestView({
  tenants,
  sentDate,
  formId
}: {
  tenants: ReiFormRemoteSignerFragment[];
  sentDate?: string | null;
  formId: string;
}): ReactElement {
  return (
    <ActionActivityContainer>
      {sentDate && (
        <Text.BodyL weight={"medium"} style={{ marginBottom: 20 }}>
          {"Sent via DocuSign on "}
          {LocalDate.from(sentDate, {
            keepTimeZone: false
          }).format("DD MMM")}
        </Text.BodyL>
      )}
      <DocusignSignatureTrackingList tenants={tenants} listItemSpacing={12} />
      <ReiFormButtons style={{ marginTop: 12 }}>
        <ReiFormDownloadSignedButton formId={formId} />
      </ReiFormButtons>
    </ActionActivityContainer>
  );
}
