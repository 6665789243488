import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";

export function RowSpaceBetween({
  left,
  right,
  style
}: {
  left?: React.ReactElement;
  right?: React.ReactElement;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <View
      style={[{ flexDirection: "row", justifyContent: "space-between" }, style]}
    >
      {left ? left : <View />}
      {right}
    </View>
  );
}
