import { Timer } from "../../../../common/useTimer";
import { useEffect } from "reactn";
import { useGetStatementFileUrlLazyQuery } from "local/graphql";
import { useState } from "react";

function useStatementFileUrl(
  timer: Timer,
  statementProgressId: string
): {
  url: string;
} {
  const [getStatementFileUrl, statementFileUrl] =
    useGetStatementFileUrlLazyQuery({
      pollInterval: 500
    });
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (!timer || !statementProgressId) {
      return;
    }

    getStatementFileUrl({
      variables: {
        statementProgressId: statementProgressId
      }
    });
  }, [timer, statementProgressId, getStatementFileUrl]);

  useEffect(() => {
    if (!timer || !statementFileUrl.data) {
      return;
    }
    if (statementFileUrl.data?.statementProgress?.statement?.file?.url) {
      setUrl(statementFileUrl.data?.statementProgress?.statement?.file?.url);
    }
  }, [timer, statementFileUrl, statementFileUrl.data]);

  // Reset state
  useEffect(() => {
    if (timer) {
      return;
    }

    if (statementFileUrl.stopPolling) {
      statementFileUrl.stopPolling();
    }
    setUrl("");
  }, [timer, statementFileUrl]);

  return { url };
}

export { useStatementFileUrl };
