import React, { ReactElement, ReactNode } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { LeaseRenewalFormData } from "./LeaseRenewalFormData";

export function LeaseRenewalFormContext({
  children
}: {
  children?: ReactNode;
}): ReactElement {
  const formMethods = useForm<LeaseRenewalFormData>({
    mode: "onChange",
    shouldFocusError: true
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
}
