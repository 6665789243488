import { Colors, opacify, Text } from "@ailo/primitives";
import {
  DateInputFormField,
  formatProjectType,
  FormFieldGroup,
  FormFieldRow,
  Separator,
  TextInputFormField,
  validateMaxLength
} from "@ailo/ui";
import { FileAttachmentsGroup } from "local/common";
import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { View } from "react-native";
import styled from "styled-components/native";
import { AssigneeFormField } from "./AssigneeFormField";
import { ProjectFormData, ProjectFormFields } from "./ProjectFormData";

export function ProjectSecondStep({
  onFieldUpdated,
  onFocus
}: {
  onFieldUpdated(fieldName: keyof ProjectFormData): void;
  onFocus: () => void;
}): ReactElement {
  const form = useFormContext<ProjectFormData>();
  const { errors, control, watch } = form;
  const projectType = watch(ProjectFormFields.type);

  useEffect(() => {
    onFocus();
  }, [onFocus]);

  return (
    <FormFieldGroup
      label={projectType ? formatProjectType(projectType) : "Project"}
    >
      <FormFieldRow style={{ marginBottom: 24 }}>
        <Controller
          control={control}
          name={ProjectFormFields.name}
          defaultValue={null}
          render={({ value, onChange, onBlur }) => (
            <TextInputFormField
              {...{ value, onChange, onBlur }}
              label={"Name"}
              error={errors.name?.message}
              onBlur={() => onFieldUpdated("name")}
              testID={"ProjectNameInputFormField"}
              softCharacterLimit={{
                limit: MAX_NAME_LENGTH,
                enforcementLevel: "error"
              }}
            />
          )}
          rules={{
            required: "Please enter a project name",
            validate: {
              maxLength: validateMaxLength(MAX_NAME_LENGTH)
            }
          }}
        />
      </FormFieldRow>
      <FormFieldRow>
        <Controller
          control={control}
          name={ProjectFormFields.description}
          defaultValue={null}
          render={({ value, onChange, onBlur }) => (
            <TextInputFormField
              {...{ value, onChange, onBlur }}
              label={"Description (optional)"}
              multiline
              numberOfLines={3}
              style={{ marginBottom: 20 }}
              error={errors.description?.message}
              onBlur={() => onFieldUpdated("description")}
              testID={"ProjectDescriptionInputFormField"}
            />
          )}
          rules={{
            validate: {
              maxLength: validateMaxLength(2048)
            }
          }}
        />
      </FormFieldRow>
      <FormFieldRow style={{ marginBottom: 20, width: "50%" }}>
        <Controller
          control={control}
          render={({ value, onBlur, onChange }) => (
            <DateInputFormField
              webPopoverHorizontalAlign={"start"}
              onBlur={onBlur}
              onChange={(date): void => {
                onChange(date ?? null);
                onFieldUpdated("dueDate");
              }}
              placeholder={"Select a date"}
              autoCorrect={false}
              autoCompleteType={"off"}
              value={value}
              label={"Due date"}
              error={errors.dueDate?.message}
              testID={"ProjectDueDateInputFormField"}
            />
          )}
          name={ProjectFormFields.dueDate}
          defaultValue={null}
          rules={{
            required: "Please select a due date"
          }}
        />
      </FormFieldRow>

      <FormFieldRow style={{ marginBottom: 8 }}>
        <Controller
          control={form.control}
          name={ProjectFormFields.assignee}
          defaultValue={null}
          render={({ value, onChange, onBlur }) => (
            <AssigneeFormField
              label={"Assignee"}
              error={errors.assignee?.message}
              onBlur={onBlur}
              onChange={(value) => {
                onChange(value ?? null);
                onFieldUpdated("assignee");
              }}
              value={value}
            />
          )}
          rules={{
            required: "Please select a team member for the project"
          }}
        />
      </FormFieldRow>
    </FormFieldGroup>
  );
}

const Loading = (): ReactElement => (
  <>
    <Text.BodyS.Loading width={60} style={{ marginBottom: 20 }} />
    <TextInputFormField.Loading labelWidth={60} style={{ marginBottom: 20 }} />
    <TextInputFormField.Loading
      labelWidth={120}
      multiline
      numberOfLines={3}
      style={{ marginBottom: 20 }}
    />
    <View style={{ width: "50%" }}>
      <TextInputFormField.Loading labelWidth={60} />
    </View>
    <SectionSeparator
      style={{
        marginBottom: 32,
        marginTop: 40,
        borderColor: opacify(Colors.CHARCOAL, 0.075)
      }}
    />
    <Text.BodyS.Loading width={60} style={{ marginBottom: 8 }} />
    <TextInputFormField.Loading labelWidth={60} style={{ marginBottom: 20 }} />
    <SectionSeparator
      style={{
        marginBottom: 32,
        marginTop: 40,
        borderColor: opacify(Colors.CHARCOAL, 0.075)
      }}
    />
    <FileAttachmentsGroup.Loading style={{ marginBottom: 20 }} />
  </>
);

ProjectSecondStep.Loading = Loading;

export const SectionSeparator = styled(Separator)`
  margin: 32px -60px 12px 0;
`;

const MAX_NAME_LENGTH = 60;
