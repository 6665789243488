import { LocalDate } from "@ailo/date";
import { CalendarIcon, Text } from "@ailo/primitives";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";

export function ActionCardFooterCalendar({
  color,
  dueDate,
  style
}: {
  color: string;
  dueDate: string;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <Container style={style}>
      <CalendarIcon
        style={{ marginRight: 8 }}
        width={16}
        height={16}
        color={color}
      />
      <Text.BodyS color={color} lineHeight={16}>
        {LocalDate.from(dueDate).format("DD MMM")}
      </Text.BodyS>
    </Container>
  );
}

const Container = styled(View)`
  flex-direction: row;
`;
