import React from "react";
import { LocalDate } from "@ailo/date";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { Colors, Skeleton, Text } from "@ailo/primitives";

interface Props {
  date: LocalDate;
}

export function DayOfMonth({ date }: Props): React.ReactElement {
  return (
    <Container>
      <Text.BodyL weight={"medium"}>{date.dayOfMonth}</Text.BodyL>
      <Text.BodyXS weight={"medium"} color={Colors.TEXT.DARK.SECONDARY}>
        {date.format("MMM")}
      </Text.BodyXS>
    </Container>
  );
}

DayOfMonth.Loading = function Loading({
  color,
  style
}: {
  color?: string;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return <LoadingContainer style={[style, { backgroundColor: color }]} />;
};

const baseStyles = `
  border-radius: 12px;
  width: 48px;
  height: 48px;
`;

const Container = styled(View)`
  ${baseStyles}
  box-shadow: 0px 1px 4px ${Colors.GRAYSCALE.DIVIDERS};
  padding: 4px;
  align-items: center;
`;

const LoadingContainer = styled(Skeleton)`
  ${baseStyles}
`;
