import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Text, Colors, HouseAndDocumentImage, SFC } from "@ailo/primitives";

const EmptyRentTransactionsList: SFC = ({ style }) => (
  <Container style={style}>
    <StyledImage />
    <StyledBodyL weight={"medium"} color={Colors.TEXT.DARK.PRIMARY}>
      {"No Rent Payments Yet"}
    </StyledBodyL>
    <StyledBodyS color={Colors.TEXT.DARK.SECONDARY}>
      {"Rent payments will appear here automatically."}
    </StyledBodyS>
  </Container>
);

const Container = styled(View)`
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const StyledBodyL = styled(Text.BodyL)`
  margin-top: 20px;
  text-align: center;
`;

const StyledBodyS = styled(Text.BodyS)`
  margin-top: 8px;
  text-align: center;
`;

const StyledImage = styled(HouseAndDocumentImage).attrs({
  width: 128,
  height: 80
})`
  align-self: center;
`;

export { EmptyRentTransactionsList };
