import { ClaimTenancyData, getBondAuthorityData } from "local/domain/bond";
import { Money } from "@ailo/ui";
import React, { ReactElement, ReactNode } from "react";
import { View } from "react-native";
import { ClaimTimeline } from "./ClaimTimeline";
import { ClaimSteps } from "./ClaimSteps";
import { AustralianState, getState } from "local/common";
import { usePropertyIsInStates } from "local/tabs";
import { ClaimStepTransferFromTrust } from "./ClaimStepTransferFromTrust";
import { ClaimStepSubmittedToBondAuthority } from "./ClaimStepSubmittedToBondAuthority";
import { ClaimStepRecordedClaim } from "./ClaimStepRecordedClaim";
import { ClaimStepAwaitingFunds } from "./ClaimStepAwaitingFunds";

export function ClaimActionsList({
  data,
  amountClaimed
}: {
  data: ClaimTenancyData;
  amountClaimed: Money;
}): ReactElement {
  const steps = useNextStepsForState({ data, amountClaimed });

  return (
    <View
      style={{
        marginTop: 12,
        flexDirection: "row",
        justifyContent: "flex-start"
      }}
    >
      <ClaimTimeline amountClaimed={amountClaimed} steps={steps.length} />
      <ClaimSteps steps={steps} />
    </View>
  );
}

function useNextStepsForState({
  data,
  amountClaimed
}: {
  data: ClaimTenancyData;
  amountClaimed: Money;
}): ReactNode[] {
  const requireSubmitToBondAuthority = !usePropertyIsInStates([
    AustralianState.NT
  ]);
  const requireTransferFromTrust = usePropertyIsInStates([
    AustralianState.WA,
    AustralianState.SA
  ]);

  const bondAmount = data.bond.amount;
  const bondRef = data.bond.reference;
  const state = getState(data.property.address.state);
  const bondAuthorityName = getBondAuthorityData(state)?.name;

  const steps = [ClaimStepRecordedClaim({ amountClaimed, bondAmount })];

  if (requireSubmitToBondAuthority) {
    steps.push(
      ClaimStepSubmittedToBondAuthority({ bondRef, bondAuthorityName })
    );
    steps.push(ClaimStepAwaitingFunds({ amountClaimed, bondAuthorityName }));

    if (requireTransferFromTrust) {
      steps.push(ClaimStepTransferFromTrust());
    }
  }

  return steps;
}
