import React, { useMemo, useState } from "react";
import { Colors, Pressable, Text, useHover } from "@ailo/primitives";

export function AddReferenceLink({
  onPress
}: {
  onPress?: () => void;
}): React.ReactElement {
  const [ref, isHovered] = useHover();
  const [isPressed, setIsPressed] = useState(false);

  const bkgColour = useMemo((): string => {
    if (isPressed) return Colors.INTERACTION.AILO_RED.PRESSED;
    if (isHovered) return Colors.INTERACTION.AILO_RED.HOVER;
    return Colors.AILO_RED;
  }, [isHovered, isPressed]);

  return (
    <Pressable
      onPressIn={(): void => setIsPressed(true)}
      onPressOut={(): void => setIsPressed(false)}
      ref={ref}
    >
      <Text.BodyS
        color={bkgColour}
        style={{ textDecorationLine: "underline" }}
        onPress={onPress}
      >
        {"Add bond reference"}
      </Text.BodyS>
    </Pressable>
  );
}
