/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useCallback } from "react";
import styled from "styled-components/native";
import { View, ViewStyle } from "react-native";
import { FormProvider, useForm } from "react-hook-form";
import { Text, Colors, opacify, PlantAndBarGraph } from "@ailo/primitives";
import { ErrorAlertScreen } from "@ailo/ui";
import { FilterConfig, FilterFactory } from "./FilterFactory";
import { fileNameForDownload, ReportHeading } from "./ReportHeading";
import {
  ColumnsCfg,
  GridRow,
  ReportTableColumn,
  ReportTableDefaultSort,
  ReportTableRow,
  SimpleReportTable,
  TotalRow
} from "./ReportTable";
import { ReportTitle } from "./ReportTitle";
import {
  DownloadButtonProps,
  ReportDownloadButton
} from "../ReportDownloadButton";
import { NavigateCfg } from "./utils/tableNavigation";
import { entries } from "lodash";
import { cellFormattedValue } from "./ReportTable/Cell";
import { DateTimeWithTimeZone } from "@ailo/date";

export interface TableData<TRow> {
  rows?: (ReportTableRow & TRow)[] | null;
  lastUpdated?: DateTimeWithTimeZone | null;
  columns?: ReportTableColumn[] | null;
  error?: Error;
  loading?: boolean;
  totals?: TotalRow<TRow>[];
  refetch?: () => void;
}

export interface ReportPageProps<TRow> {
  title: string;
  /**
   * the report data and loading/error state.
   * columns are optional, will fall back to columnsConfig if not provided
   */
  data: TableData<TRow>;
  /**
   * column display configuration, typically defined statically by the report control
   */
  columnsConfig?: ColumnsCfg<TRow>;
  filterName?: string;
  componentAboveHeader?: ReactElement;
  componentAboveTable?: ReactElement;
  componentBelowFilter?: ReactElement;
  filterConfig?: FilterConfig<TRow>[];
  startDate?: string;
  endDate?: string;
  fileNameIncludeDate?: boolean;
  fileName?: string;
  description?: string;
  defaultSort?: ReportTableDefaultSort<TRow>;
  /**
   * shows a header bar above the table with a date range and download button. Otherwise the download button is displayed with the filters
   */
  showHeader?: boolean;
  showDownload?: boolean;
  rowLink?: (row: GridRow<TRow>) => NavigateCfg | null;
  filterModalButtons?: ReactElement;
}

const ReportWrapper = ({
  style,
  children
}: {
  style?: ViewStyle;
  children: React.ReactNode;
}): ReactElement => (
  <View
    style={{
      backgroundColor: Colors.WHITE,
      shadowColor: opacify(Colors.BLACK, 0.2),
      shadowOffset: { width: 0, height: 1 },
      shadowRadius: 4,
      borderRadius: 4,
      ...style
    }}
  >
    {children}
  </View>
);

const PageWrapper = styled(View)`
  flex-grow: 1;
`;

const NoDataSection = (
  <View
    style={{
      height: "360",
      width: "100%",
      alignItems: "center",
      marginTop: 67,
      marginBottom: 142
    }}
  >
    <PlantAndBarGraph />
    <Text.BodyXS style={{ marginTop: 20 }} color={Colors.TEXT.DARK.SECONDARY}>
      {"No data available"}
    </Text.BodyXS>
  </View>
);

interface ErrorSectionProps {
  refetch?: () => void;
}
const ErrorSection = function ({ refetch }: ErrorSectionProps): ReactElement {
  return (
    <ErrorAlertScreen
      variant={"medium"}
      title={"There was a problem loading data"}
      style={{ paddingVertical: 40 }}
      onRetry={refetch}
    />
  );
};

export function reportPageDerivedState<TRow extends ReportTableRow>(
  props: ReportPageProps<TRow>
): {
  download: DownloadButtonProps<TRow>;
  columns: ReportTableColumn[];
} {
  const {
    data,
    columnsConfig,
    filterConfig,
    fileName,
    title,
    fileNameIncludeDate,
    startDate,
    endDate
  } = props;

  const columns =
    data.columns ??
    entries(columnsConfig ?? {})
      .filter(([, c]) => c !== undefined)
      .map(([key, c]) => ({
        key,
        header: c?.header ?? key
      }));

  // format the cell values like in a cell for csv download
  const downloadRows = data?.rows?.map(
    (r) =>
      Object.fromEntries(
        columns.map(({ key }) => {
          const cfg = columnsConfig?.[key];
          const value = r[key];
          return [key, cfg ? cellFormattedValue(cfg, value) : value];
        })
      ) as TRow
  );

  const download = {
    ...data,
    columns,
    rows: downloadRows,
    filterConfig: filterConfig,
    fileName:
      fileName ??
      fileNameForDownload({
        title,
        fileNameIncludeDate,
        startDate,
        endDate
      })
  };

  return { columns, download };
}

export function ReportPage<TRow extends ReportTableRow>(
  props: ReportPageProps<TRow>
): ReactElement {
  const {
    title,
    data,
    columnsConfig,
    filterName,
    filterConfig,
    componentAboveHeader,
    componentAboveTable,
    componentBelowFilter,
    startDate,
    endDate,
    description,
    defaultSort,
    showHeader,
    rowLink,
    filterModalButtons,
    showDownload = true
  } = props;

  const methods = useForm();
  const { loading } = data;

  const { download, columns } = reportPageDerivedState<TRow>(props);

  const GetReportSection = useCallback((): ReactElement => {
    if (data?.error) return <ErrorSection refetch={data?.refetch} />;
    if (loading || !data?.rows) return <SimpleReportTable.Loading />;
    if (data?.rows?.length == 0) return NoDataSection;
    return (
      <SimpleReportTable<TRow>
        rows={data.rows}
        columns={columns}
        columnsConfig={columnsConfig}
        filterName={filterName}
        filterConfig={filterConfig}
        defaultSort={defaultSort}
        rowLink={rowLink}
        totals={data.totals}
      />
    );
  }, [data]);

  return (
    <FormProvider {...methods}>
      <PageWrapper>
        <ReportTitle
          title={title}
          style={{ marginBottom: description ? 16 : 32 }}
          lastUpdated={data?.lastUpdated}
        />
        {description ? (
          <Text.BodyM
            style={{ marginBottom: 40 }}
            color={Colors.TEXT.DARK.SECONDARY}
          >
            {description}
          </Text.BodyM>
        ) : null}
        {componentAboveHeader}
        {showHeader && (
          <ReportHeading<TRow>
            startDate={startDate}
            endDate={endDate}
            tableDownload={download}
          />
        )}
        {componentAboveTable}
        <ReportWrapper>
          {filterModalButtons && (
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                paddingHorizontal: 12,
                paddingVertical: 16,
                borderBottomWidth: 2,
                borderColor: Colors.GRAYSCALE.OUTLINE,
                overflow: "hidden"
              }}
            >
              <FilterFactory
                filters={filterConfig}
                rows={data.rows}
                loading={data.loading}
                style={{ padding: 0, marginRight: 12 }}
                appendComponents={
                  !showHeader && showDownload
                    ? [<ReportDownloadButton {...download} key={"download"} />]
                    : []
                }
              />
              {filterModalButtons}
            </View>
          )}
          {!filterModalButtons && (
            <FilterFactory
              filters={filterConfig}
              rows={data.rows}
              loading={data.loading}
              style={thickBottomBorderStyle}
              appendComponents={
                !showHeader && showDownload
                  ? [<ReportDownloadButton {...download} key={"download"} />]
                  : []
              }
            />
          )}
          {componentBelowFilter && (
            <View style={thickBottomBorderStyle}>{componentBelowFilter}</View>
          )}
          {GetReportSection()}
        </ReportWrapper>
      </PageWrapper>
    </FormProvider>
  );
}

const thickBottomBorderStyle = {
  borderBottomColor: opacify(Colors.GRAYSCALE.OUTLINE, 0.1),
  borderBottomWidth: 2
};
