import React, { ReactElement } from "react";
import { PrimaryText } from "local/tabs/properties/components/TenancyBondScreen/components/PrimaryText";
import { Money, Separator } from "@ailo/ui";

export function ClaimStepAwaitingFunds({
  amountClaimed,
  bondAuthorityName
}: {
  amountClaimed: Money;
  bondAuthorityName?: string;
}): ReactElement {
  return (
    <>
      <Separator style={{ marginLeft: 0 }} />
      <PrimaryText style={{ marginTop: 30 }} weight={"medium"}>
        {amountClaimed.isZero
          ? `${bondAuthorityName} will release the bond to renters`
          : `Awaiting funds from ${bondAuthorityName}`}
      </PrimaryText>
    </>
  );
}
