import { useMemo } from "react";
import { usePaginationRequest } from "./usePaginationRequest";
import {
  GridSortStateToApiParam,
  PropertyOnboardingListPageInfo,
  PropertyOnboardingListQueryData,
  PropertyOnboardingListRequestExtraVariables,
  PropertyOnboardingListSendRequest
} from "../types";

interface GetPropertyOnboardingListQueryReturnInputs<
  TMigratingManagement,
  TExtraVariables extends PropertyOnboardingListRequestExtraVariables
> {
  sendRequest: PropertyOnboardingListSendRequest<TExtraVariables>;
  pageInfo?: Omit<PropertyOnboardingListPageInfo, "pageNo">;
  migratingManagements?: TMigratingManagement[];
  loading: boolean;
  called: boolean;
  gridSortStateToApiParam?: GridSortStateToApiParam;
  refetch?: () => void;
}

export function useGetPropertyOnboardingListQueryReturn<
  TMigratingManagement extends { id: string },
  TExtraVariables extends PropertyOnboardingListRequestExtraVariables
>({
  sendRequest,
  pageInfo,
  migratingManagements,
  loading,
  called,
  gridSortStateToApiParam,
  refetch
}: GetPropertyOnboardingListQueryReturnInputs<
  TMigratingManagement,
  TExtraVariables
>): PropertyOnboardingListQueryData<
  TMigratingManagement & { key: string },
  TExtraVariables
> {
  const currentCursor = useMemo(
    () => ({
      nextPage: pageInfo?.nextCursor || null,
      previousPage: pageInfo?.previousCursor || null
    }),
    [pageInfo]
  );

  const {
    lastRequestParams,
    lastRequestExtraVariables,
    requestNewPage,
    requestFromBeginning,
    currentPage
  } = usePaginationRequest(currentCursor, sendRequest, gridSortStateToApiParam);

  return {
    loading: loading || !called,
    data: useMemo(() => {
      if (!migratingManagements || !pageInfo) {
        return {
          error: called,
          migratingManagements: [],
          pageInfo: { hasNext: false, hasPrevious: false, total: 0, pageNo: 0 }
        };
      }

      return {
        error: false,
        migratingManagements: migratingManagements.map((mgmt) => ({
          key: mgmt.id,
          ...mgmt
        })),
        pageInfo: { ...pageInfo, pageNo: currentPage }
      };
    }, [called, currentPage, migratingManagements, pageInfo]),
    requestNewPage,
    requestFromBeginning,
    lastRequestParams,
    lastRequestExtraVariables,
    refetch: refetch ?? (() => {})
  };
}
