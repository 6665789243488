import React, { ReactElement } from "react";
import { ScreenComponent } from "@ailo/services";
import { PropertyOnboardingList } from "./PropertyOnboardingList";

export function PropertyOnboardingListScreen(): ReactElement {
  return (
    <ScreenComponent>
      <PropertyOnboardingList />
    </ScreenComponent>
  );
}
