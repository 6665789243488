import { EditIcon } from "@ailo/primitives";
import { DropdownMenuOption } from "@ailo/ui";
import React from "react";

export function getEditEndTenancyOption({
  onSelect
}: {
  onSelect: () => void;
}): DropdownMenuOption {
  return {
    label: "Edit end tenancy",
    icon: <EditIcon />,
    onSelect
  };
}
