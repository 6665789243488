import { Text } from "@ailo/primitives";
import { BoxWithShadow, Money, MoneyInterface, Separator } from "@ailo/ui";
import { Row } from "local/common";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";

export function GstBox({
  includesGst,
  fixedAmount: _fixedAmount,
  style
}: {
  includesGst: boolean | null | undefined;
  fixedAmount: MoneyInterface | null | undefined;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  const fixedAmount = _fixedAmount ? Money.from(_fixedAmount) : _fixedAmount;
  return (
    <BoxWithShadow
      style={[
        {
          maxHeight: undefined,
          maxWidth: undefined,
          padding: 16,
          marginTop: 24
        },
        style
      ]}
    >
      <Row style={{ justifyContent: "space-between", width: "100%" }}>
        <Text.BodyS weight={"book"}>
          {includesGst ? "GST included" : "GST"}
        </Text.BodyS>
        <Text>
          {(fixedAmount ?? Money.zero()).divide(includesGst ? 11 : 10).format()}
        </Text>
      </Row>
      {!includesGst && (
        <View style={{ width: "100%" }}>
          <Separator
            style={{
              marginVertical: 16,
              marginRight: -16,
              marginLeft: 0
            }}
          />
          <Row style={{ justifyContent: "space-between", width: "100%" }}>
            <Text.DisplayS>{"Total Price"}</Text.DisplayS>
            <Text.DisplayS>
              {(fixedAmount ?? Money.zero()).multiply(1.1).format()}
            </Text.DisplayS>
          </Row>
        </View>
      )}
    </BoxWithShadow>
  );
}
