import React, { ReactElement } from "react";
import { View } from "react-native";
import { TextInputFormField } from "@ailo/ui";
import { LeftAlignedText } from "local/tabs";
import { AllocationFieldInputProps } from "./types";
import { capitalize } from "lodash";

export function AllocationDescriptionInput({
  allocationPurpose,
  onChange,
  error,
  showTitle = false,
  ...rest
}: AllocationFieldInputProps): ReactElement {
  return (
    <View>
      {showTitle && (
        <LeftAlignedText
          style={{
            paddingBottom: 4
          }}
        >
          {capitalize(allocationPurpose.name)}
        </LeftAlignedText>
      )}
      {(allocationPurpose.name === "Deposit" && (
        <TextInputFormField
          {...rest}
          placeholder={"Description"}
          autoCompleteType={"off"}
          autoCorrect={false}
          minHeight={36}
          inputStyle={{
            height: 36,
            fontSize: 13,
            fontWeight: "normal",
            lineHeight: 20
          }}
          error={error}
          onChange={(text): void => {
            onChange(text);
          }}
        />
      )) || <LeftAlignedText>{allocationPurpose.description}</LeftAlignedText>}
    </View>
  );
}
