import React, { ReactElement } from "react";
import { Text } from "@ailo/primitives";
import { CellContainer } from "./CellContainer";
import { UnscheduledTenanciesListRow } from "../useGetUnscheduledTenanciesRows";
import { FormattedAddressCell } from "local/domain/propertyManagement";

export function PropertyCell({
  row: { propertyAddress }
}: {
  row: UnscheduledTenanciesListRow;
  rowIndex: number;
}): ReactElement {
  return (
    <CellContainer>
      <FormattedAddressCell address={propertyAddress} />
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <Text.BodyM.Loading width={80} />
      <Text.BodyS.Loading width={40} />
    </CellContainer>
  );
}

PropertyCell.Loading = Loading;
