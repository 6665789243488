import React from "react";
import { Colors, Text } from "@ailo/primitives";
import { Button, CellContainer, TextCellContent } from "@ailo/ui";
import { GestureResponderEvent, StyleProp, ViewStyle } from "react-native";

export function ErrorCell({
  onPress,
  style
}: {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <CellContainer style={style}>
      <TextCellContent color={Colors.TEXT.DARK.SECONDARY}>
        {"Failed to load"}
      </TextCellContent>
      {onPress != null && (
        <Button.Text
          onPress={(event: GestureResponderEvent): void => {
            event.stopPropagation();
            event.preventDefault();
            onPress();
          }}
        >
          <Text.BodyS weight={"medium"}>{"Reload"}</Text.BodyS>
        </Button.Text>
      )}
    </CellContainer>
  );
}
