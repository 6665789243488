import { DateTimeWithTimeZone } from "@ailo/ui";
import {
  BillSortField,
  BillStatus,
  SortDirection,
  GetBillsQueryVariables
} from "local/graphql";
import { TabId } from "./tabs";

export function getBillsQueryTabVariables(
  tab: TabId
): Omit<GetBillsQueryVariables, "organisationId" | "cursor" | "pageSize"> {
  if (tab === "upcoming") {
    return {
      status: [BillStatus.Approved, BillStatus.Refunded],
      paid: false,
      dueDate: {
        gte: DateTimeWithTimeZone.now().toLocalDateString()
      },
      sort: { field: BillSortField.DueDate, direction: SortDirection.Asc }
    };
  }

  if (tab === "overdue") {
    return {
      status: [BillStatus.Approved, BillStatus.Refunded],
      paid: false,
      dueDate: {
        lt: DateTimeWithTimeZone.now().toLocalDateString()
      },
      sort: { field: BillSortField.DueDate, direction: SortDirection.Asc }
    };
  }

  if (tab === "paid") {
    return {
      status: [BillStatus.Approved, BillStatus.Refunded],
      paid: true,
      sort: { field: BillSortField.DueDate, direction: SortDirection.Desc }
    };
  }

  if (tab === "cancelled") {
    return {
      status: [BillStatus.Void],
      sort: { field: BillSortField.DueDate, direction: SortDirection.Desc }
    };
  }

  throw new TypeError(`Unknown tab: ${tab}`);
}
