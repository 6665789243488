import { useGlobalModal } from "@ailo/ui";
import { ManagementFragment } from "local/graphql";
import { AddOrEditManagementModalForm } from "local/modals/AddOrEditManagementModalForm";
import React, { ReactElement } from "react";
import { BaseActionActivity } from "../../BaseActionActivity";

export function CreateManagementView({
  onCreateSuccess
}: {
  onCreateSuccess: (management: ManagementFragment) => void;
}): ReactElement {
  const modal = useGlobalModal();

  return (
    <BaseActionActivity
      button={{
        label: "Set up new management",
        onPress: () => {
          modal.show(
            <AddOrEditManagementModalForm
              onDismiss={modal.hide}
              onSuccess={onCreateSuccess}
            />
          );
        }
      }}
      description={"Enter the details for the new management"}
    />
  );
}
