import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { useCallback } from "react";
import {
  ArchiveProjectMutation,
  useArchiveProjectMutation,
  GetManagementProjectsDocument,
  ProjectDetailsFragment
} from "local/graphql";
import { getManagementIfExists } from "local/domain/project";

export function useArchiveProject({
  onCompleted,
  onError
}: {
  onCompleted: ({ project }: { project: ProjectDetailsFragment }) => void;
  onError: () => void;
}): [
  (project: ProjectDetailsFragment) => void,
  MutationResult<ArchiveProjectMutation>
] {
  const [archiveProjectMutation, result] = useArchiveProjectMutation();

  const archiveProject = useCallback(
    async (project: ProjectDetailsFragment): Promise<void> => {
      let result: ExecutionResult<ArchiveProjectMutation>;

      const management = getManagementIfExists(project);
      try {
        result = await archiveProjectMutation({
          variables: {
            id: project.id
          },
          refetchQueries: management
            ? [
                {
                  query: GetManagementProjectsDocument,
                  variables: { managementId: management.id }
                }
              ]
            : []
        });
        throwIfMutationFailed(result, { dataKey: "archiveProject" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }

      onCompleted({ project });
    },
    [archiveProjectMutation, onCompleted, onError]
  );

  return [archiveProject, result];
}
