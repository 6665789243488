import { ExtendElementProps } from "@ailo/primitives";
import {
  Alert,
  BaseModal,
  TextInputFormField,
  useToastContext
} from "@ailo/ui";
import { useRemoveTrustAccountAdjustmentMutation } from "local/graphql";
import React, { ReactElement, useCallback, useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { ModalFooter, PleaseWaitTitle } from "../components";
import { clearTrustAccountReconciliationAdjustmentsCache } from "../graphql/clearTrustAccountReconciliationAdjustmentsCache";

type RemoveAdjustmentModalProps = ExtendElementProps<
  {
    adjustmentId: string;
    removeFromReportPeriodId: string;
    onCancelPress?: () => void;
    onRemovePress?: () => void;
  },
  typeof BaseModal
>;

export function RemoveAdjustmentModal({
  adjustmentId,
  removeFromReportPeriodId,
  children,
  onCancelPress,
  onRemovePress,
  ...rest
}: RemoveAdjustmentModalProps): ReactElement {
  const toast = useToastContext();
  const [removedReason, setRemovedReason] = useState("");
  const [removeAdjustment, { loading, error }] =
    useRemoveTrustAccountAdjustmentMutation();

  const onActionPress = useCallback(async () => {
    const { errors } = await removeAdjustment({
      variables: {
        input: { id: adjustmentId, removedReason, removeFromReportPeriodId }
      },
      refetchQueries: ["listTrustAccountAdjustments"],
      update: clearTrustAccountReconciliationAdjustmentsCache
    });
    if (!errors) {
      onRemovePress?.();
      toast.show({ type: "success", message: "Adjustment has been removed" });
    }
  }, [
    adjustmentId,
    removeFromReportPeriodId,
    onRemovePress,
    removeAdjustment,
    removedReason,
    toast
  ]);

  const footer = (
    <ModalFooter
      cancel={"Cancel"}
      onCancelPress={onCancelPress}
      action={loading ? undefined : "Remove"}
      onActionPress={removedReason ? onActionPress : undefined}
      actionButtonVariant={"destructive"}
    />
  );
  return (
    <BaseModal
      title={loading ? <PleaseWaitTitle /> : "Remove adjustment"}
      footer={footer}
      showFooterBorder
      {...rest}
    >
      {error && <ErrorAlert />}
      <FormContainer>
        <TextInputFormField
          label={"Why are you removing?"}
          value={removedReason}
          onChangeText={setRemovedReason}
        />
      </FormContainer>
    </BaseModal>
  );
}

const FormContainer = styled(View)`
  margin-top: 12px;
  margin-bottom: 44px;
`;

const ErrorAlert = styled(Alert).attrs({
  type: "warning",
  children: "There was a problem removing the adjustment.\nPlease try again."
})`
  margin-top: 12px;
  margin-bottom: 4px;
`;
