import { Row as TSourceRow } from "local/common";
import { LineChart, Money, TimePeriods } from "@ailo/ui";
import {
  AgencyIncomeReportQuery,
  useAgencyIncomeReportQuery,
  useAgencyIncomeDateQuery
} from "local/graphql";
import { capitalize, head, orderBy } from "lodash";
import React, { useState } from "react";
import {
  safeMap,
  useReportQuery,
  useReportTeamContext,
  useFeeBlueprintsFilter,
  useReportModalFilter,
  useTeamFilter
} from "../..";

import moment from "moment";
import { View } from "react-native";
import { reports } from "../../reportsDetails";
import {
  ReportPage,
  reportPageDerivedState,
  ReportPageProps,
  TrendValue
} from "../../components";
import { ReportDownloadButton } from "../../components/ReportDownloadButton";

import { RevenueReportTimeFilter } from "./RevenueTimePeriodFilter";
import { isPresent } from "ts-is-present";
import { DateTimeWithTimeZone, LocalDate } from "@ailo/date";
import { calculateRowColumns, RevenueReportRow } from "./getRowColumns";

/** for testing purposes until we hook up filters */
type TSourceRow = Omit<
  NonNullable<
    NonNullable<
      NonNullable<AgencyIncomeReportQuery["agencyIncomeReport"]>["rows"]
    >[0]
  >,
  "__typename"
>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useRevenueTable() {
  const [filter, setFilter] = useState<
    Partial<{
      teamIds: string[];
      feeCategories: string[];
      startDate: string;
      endDate: string;
      timePeriod: TimePeriods;
    }>
  >();
  const startDate = filter?.startDate
    ? LocalDate.from(filter?.startDate)
    : LocalDate.today().subtract(13, "month");
  const endDate = filter?.endDate
    ? LocalDate.from(filter.endDate)
    : LocalDate.today();

  const teamIds = filter?.teamIds ?? [];
  const feeCategories = filter?.feeCategories ?? [];
  const periodType = filter?.timePeriod ?? "month";

  const { managingOrgId } = useReportTeamContext();
  const queryFilter = {
    feeCategories,
    startDate: startDate.subtract(1, "year").toString(),
    endDate: endDate.toString(),
    managingOrgId,
    teamIds
  };

  const queryResult = useReportQuery(useAgencyIncomeReportQuery, {
    includeMonth: periodType === "month",
    includeQuarter: periodType === "quarter",
    includeFinancialYearAU: periodType == "financial year",
    ...queryFilter
  });

  const minDateQuery = useAgencyIncomeDateQuery({
    variables: { managingOrgId }
  });
  const earliestTransaction =
    head(minDateQuery?.data?.agencyIncomeReport?.rows)?.minDate ?? "";

  function getRows(): {
    rows: RevenueReportRow[] | undefined;
    dateExported: LocalDate | undefined;
    lastUpdated?: DateTimeWithTimeZone | null;
  } {
    const reportData = queryResult.data?.agencyIncomeReport;

    const rawRows: TSourceRow[] | undefined =
      reportData?.rows?.filter(isPresent);

    if (!queryFilter.startDate || !queryFilter.endDate || !rawRows)
      return { rows: undefined, dateExported: undefined };

    return {
      rows: calculateRowColumns(rawRows, {
        feeCategories,
        startDate,
        endDate,
        periodType
      }),
      dateExported: LocalDate.today(),
      lastUpdated: safeMap(reportData?.lastUpdated, (u) =>
        DateTimeWithTimeZone.from(moment.utc(u).local())
      )
    };
  }

  const feeBlueprintFilter = useFeeBlueprintsFilter();
  const teamFilter = useTeamFilter();
  const modalFilters = [
    RevenueReportTimeFilter({
      timePeriod: "month",
      earliestTransaction,
      startDate: startDate.toString(),
      endDate: endDate.toString()
    }),
    feeBlueprintFilter,
    teamFilter
  ];

  const { filterButtons } = useReportModalFilter({
    onSubmit: setFilter,
    filterState: {
      ...filter
    },
    modalFilters,
    loading: queryResult.loading
  });
  const { rows, dateExported, lastUpdated } = getRows();

  const lessThan12MonthsDisplayed = startDate.diff(endDate, "month") > 12;

  const reportPage: ReportPageProps<RevenueReportRow> = {
    title: reports.revenue.reportHeading,
    data: { ...queryResult, rows, lastUpdated },
    filterModalButtons: filterButtons,
    columnsConfig: {
      rowDate: {
        header: capitalize(periodType),
        formatValue: ({ period }) => period?.longFormat() ?? ""
      },
      incomeAmount: { type: "currency", header: "Total revenue (incl. GST)" },
      periodIncomeDelta: {
        type: "currency",
        header: `Change from last ${periodType.toLowerCase()}`,
        // eslint-disable-next-line react/display-name
        renderCell: ({ row }) => (
          <TrendValue
            dollars={row.periodIncomeDelta}
            changePct={row.periodIncomeDeltaPct}
          />
        )
      },
      periodIncomeDeltaPct: {
        type: "pct",
        header: `Change from last ${periodType.toLowerCase()} (%)`,
        downloadOnly: true
      },
      priorYearIncomeDelta:
        periodType == "financial year" || lessThan12MonthsDisplayed
          ? undefined
          : {
              type: "currency",
              header: `Change from last year`,
              // eslint-disable-next-line react/display-name
              renderCell: ({ row }) => (
                <TrendValue
                  dollars={row.priorYearIncomeDelta}
                  changePct={row.priorYearIncomeDeltaPct}
                />
              )
            },
      priorYearIncomeDeltaPct: {
        type: "pct",
        header: `Change from last year (%)`,
        downloadOnly: true
      },
      feeName: {
        header: "Fee categories included in total",
        downloadOnly: true
      }
    },
    defaultSort: { columnKey: "rowDate", direction: "DESC" },
    componentBelowFilter: (
      <View
        style={{
          marginTop: 48,
          marginBottom: 48,
          marginLeft: 18,
          marginRight: 18
        }}
      >
        <LineChart
          data={orderBy(rows ?? [], "period", "asc")}
          loading={queryResult.loading}
          accessors={{
            x: (r) => r.period.shortFormat(),
            y: (r) => r.incomeAmount,
            yAxisLabel: (v) =>
              Money.fromDollars(v).format({ withTrailingZeros: false }),
            yTipLabel: (v) =>
              Money.fromDollars(v).format({ withTrailingZeros: true }),
            lineProps: (r) => ({
              strokeDasharray: r.periodInProgress ? "8" : undefined
            })
          }}
          height={300}
        />
      </View>
    ),
    fileName: `RevenueOverTime_export_${dateExported?.format("YYYY-MM-DD")}`,
    showDownload: false
  };

  const { download } = reportPageDerivedState(reportPage);
  return {
    reportPage: [ReportPage, reportPage],
    topComponents: [ReportDownloadButton, { ...download, secondaryStyle: true }]
  };
}
