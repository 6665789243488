import React, { ReactElement } from "react";
import { Link, Text } from "@ailo/primitives";
import { Screens, useNavigation } from "local/common";

export function NoReiTokenDescription(): ReactElement {
  const navigation = useNavigation<Screens.SettingsTab>();
  const navigateToFormSettings = (): void => {
    navigation.navigate(Screens.AppNavigator, {
      screen: Screens.SettingsTab,
      params: {
        tab: "forms"
      }
    });
  };
  return (
    <Text.BodyL weight={"book"} style={{ marginBottom: 24 }}>
      {"An agreement has been created, connect your "}
      <Link variant={"primary"} onPress={navigateToFormSettings}>
        {"forms account"}
      </Link>{" "}
      {"to view, edit, send or download."}
    </Text.BodyL>
  );
}
