import { Text, IconProps, Colors, Pressable, useHover } from "@ailo/primitives";
import React, { ReactElement } from "react";
import { Animated, View } from "react-native";
import styled from "styled-components/native";
import {
  AppDrawerNavigatorParamList,
  Screens,
  useNavigation
} from "local/common";
import { NavigationDrawerItem } from "../NavigationDrawerItem";
import { DrawerElementData } from "../BaseDrawerNavigator";

export interface NavigationDrawerProps extends DrawerElementData {
  label: string;
  onDrawerItemPressed?: () => void;
  screenName?: keyof AppDrawerNavigatorParamList;
}

export interface NavigationDrawerGroupProps {
  icon: React.FC<IconProps>;
  groupName: string;
  groupScreenDefault: Screens;
  childDrawers: NavigationDrawerProps[];
}

export function NavigationDrawerGroup({
  icon,
  groupName,
  groupScreenDefault,
  childDrawers
}: NavigationDrawerGroupProps): ReactElement {
  const navigation = useNavigation();
  const currentScreenName = useGetCurrentScreenName();
  const [hoverRef, isHovered] = useHover();

  const focused = childDrawers.some((draw) => {
    return draw.name === currentScreenName;
  });

  const color = focused || isHovered ? Colors.WHITE : Colors.SMOKE;

  return (
    <View
      ref={hoverRef}
      style={{
        flexDirection: "column"
      }}
    >
      <Pressable
        onPress={() => {
          navigation.navigate(groupScreenDefault);
        }}
        style={({ pressed }) => ({
          borderRadius: 4,
          flexDirection: "row",
          alignItems: "center",
          marginHorizontal: 8,
          paddingLeft: 18,
          height: 44,
          backgroundColor: pressed ? Colors.SPACE_BLACK : Colors.TRANSPARENT
        })}
      >
        {React.createElement(icon, {
          style: { marginRight: 12, width: 20, height: 20 },
          color
        })}
        <Text.BodyM color={color} weight={"medium"}>
          {groupName}
        </Text.BodyM>
      </Pressable>
      <ChildWrapper focused={focused}>
        {childDrawers.map((drawer, arrayIndex) => {
          return (
            <NavigationDrawerItem
              style={{
                paddingHorizontal: 12,
                height: 36
              }}
              key={arrayIndex}
              focused={drawer.name === currentScreenName}
              {...drawer}
            />
          );
        })}
      </ChildWrapper>
    </View>
  );
}

const ChildWrapper = styled(Animated.View)<{ focused: boolean }>`
  display: ${({ focused }) => (focused ? "flex" : "none")};
  padding-left: 2px;
  margin-bottom: 12px;
  margin-left: 34px;
  border-left-color: ${Colors.PEBBLE};
  border-left-width: 1px;
  gap: 4px;
`;

function useGetCurrentScreenName(): string | undefined {
  const navigation = useNavigation();
  const state = navigation.getState().routes[0].state;

  const currentRoute = state?.index;
  const routes = state?.routes;

  return currentRoute && routes && currentRoute < routes.length
    ? routes[currentRoute].name
    : undefined;
}
