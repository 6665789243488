import React, { ReactElement, useCallback, useMemo } from "react";
import { MultiSelectButton } from "@ailo/ui";
import {
  AwaitingApprovalPropertyOnboardingListStatusFilterData,
  AwaitingApprovalPropertyOnboardingListStatusFilterSelection
} from "./hooks";
import styled from "styled-components/native";
import { View } from "react-native";

interface DataIssueOption {
  label: string;
  value: string;
  withErrors: AwaitingApprovalPropertyOnboardingListStatusFilterSelection;
}

const HEALTHY_OPTION: DataIssueOption = {
  label: "Healthy",
  value: "healthy",
  withErrors: "healthy"
};
const DATA_ISSUES_OPTION: DataIssueOption = {
  label: "Data Issues",
  value: "issues",
  withErrors: "data-issues"
};
const OPTIONS: DataIssueOption[] = [HEALTHY_OPTION, DATA_ISSUES_OPTION];

interface Props {
  statusFilterData: AwaitingApprovalPropertyOnboardingListStatusFilterData;
}

export function AwaitingApprovalPropertyOnboardingListStatusFilter({
  statusFilterData: { withErrors, setWithErrors }
}: Props): ReactElement {
  const selectedOptions = useMemo(() => {
    if (withErrors === "all") return [HEALTHY_OPTION, DATA_ISSUES_OPTION];
    if (withErrors === "healthy") return [HEALTHY_OPTION];
    if (withErrors === "data-issues") return [DATA_ISSUES_OPTION];
    return [];
  }, [withErrors]);

  const onChange = useCallback(
    (options: DataIssueOption[]) => {
      if (options.length === 0) {
        setWithErrors(undefined);
        return;
      }

      if (options.length === OPTIONS.length) {
        setWithErrors("all");
        return;
      }

      const selectedOption = options[0];
      if (!selectedOption) return;
      setWithErrors(selectedOption.withErrors);
    },
    [setWithErrors]
  );

  return (
    <FilterContainer>
      <MultiSelectButton
        optionGroups={[{ label: "", options: OPTIONS }]}
        entityType={"Statuses"}
        value={selectedOptions}
        onChange={onChange}
        horizontalAlign={"right"}
      />
    </FilterContainer>
  );
}

const FilterContainer = styled(View)`
  margin-left: 12px;
`;
