import { Colors, Pressable, Text } from "@ailo/primitives";
import { useSelectedTeams } from "local/domain/propertyManagement";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
export interface TeamMetricProps {
  teamName: string;
  teamId: string;
  value: string;
  changeValue?: string;
  dashboardCardNavigation: () => void;
}

const BackgroundColor = styled(View)<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
const BackgroundWrapper = styled(View)`
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 16px;
  padding-right: 16px;
  border-color: ${Colors.GRAYSCALE.OUTLINE};
  border-style: solid;
  border-top-width: 1px;
`;

const getBackgroundColor = ({
  hovered,
  pressed
}: {
  hovered?: boolean;
  pressed?: boolean;
}): string => {
  if (pressed) {
    return Colors.INTERACTION.WHITE.PRESSED;
  }
  if (hovered) {
    return Colors.INTERACTION.WHITE.HOVER;
  } else {
    return Colors.TRANSPARENT;
  }
};

export function TeamMetric({
  teamName,
  value,
  teamId,
  dashboardCardNavigation
}: TeamMetricProps): React.ReactElement {
  const { updatePropertyListTeamIds, updatePropertyListUnassignedSelected } =
    useSelectedTeams();

  return (
    <Pressable
      onPress={() => {
        updatePropertyListTeamIds(teamId ? [teamId] : []);
        updatePropertyListUnassignedSelected(teamName === "Unassigned");
        dashboardCardNavigation();
      }}
    >
      {({ hovered, pressed }) => (
        <BackgroundColor
          backgroundColor={getBackgroundColor({ hovered, pressed })}
        >
          <BackgroundWrapper>
            <Text.BodyM
              color={Colors.TEXT.DARK.SECONDARY}
              weight={"book"}
              style={{ marginRight: "auto" }}
            >
              {teamName}
            </Text.BodyM>
            <Text.BodyM weight={"medium"}>{value}</Text.BodyM>
          </BackgroundWrapper>
        </BackgroundColor>
      )}
    </Pressable>
  );
}
