import { SFC } from "@ailo/primitives";
import { useOnFocus } from "@ailo/services";
import { setVerifiedOnLegalEntity } from "local/domain/authz";
import React, { useState, useCallback } from "react";
import styled from "styled-components/native";
import { ManageMenu } from "./ManageMenu";
import { MovingInBanner } from "./MovingInBanner";
import { LocalDate } from "@ailo/date";
import { parseBond } from "../parseBond";
import { useGetIngoingTenancyDetailsQuery } from "local/graphql";
import { useNavigateToClaimTenancyBond } from "local/tabs";
import { getTenantList } from "../../utils";
import { BaseTenancyCard, isBondInClaimProcess } from "../BaseTenancyCard";
import { createBondMenuOptions } from "../BaseTenancyCard/createBondMenuOptions";
import { useGlobalModal } from "@ailo/ui";
import { TenancyModalForm } from "local/modals/TenancyModalForm";
import { VoidIngoingTenancyModal } from "local/modals/VoidTenancyConfirmModal";
import { AiloRN } from "@ailo/ailorn";
import { isPresent } from "ts-is-present";
import { useCurrentAgencyOrg } from "local/common";

interface Props {
  tenancyId: string;
  managementId: string;
  onDetailsPress: (tenancyId: string, title: string) => void;
  onTenantPress?: (id: string, entityType: "Person" | "Company") => void;
  onRentAdjustmentsPress: (tenancyId: string) => void;
  onAddBondDetailsPress: (tenancyId: string) => void;
  onEditBondDetailsPress: (tenancyId: string) => void;
  onViewBondClaimPress: (bondId: string) => void;
  onEditBondReferencePress: (tenancyId: string) => void;
}

const CARD_TITLE = "Ingoing Tenancy";

export const IngoingTenancyCard: SFC<Props> = ({
  style,
  tenancyId,
  managementId,
  onDetailsPress,
  onTenantPress,
  onRentAdjustmentsPress,
  onAddBondDetailsPress,
  onEditBondDetailsPress,
  onViewBondClaimPress,
  onEditBondReferencePress
}) => {
  const { ailoRN: currentAgencyOrgAilornString } = useCurrentAgencyOrg();
  const modal = useGlobalModal();
  const { data, loading, refetch } = useGetIngoingTenancyDetailsQuery({
    variables: {
      id: tenancyId,
      organisationAilorn: currentAgencyOrgAilornString
    }
  });

  const [voidTenancyModalVisible, setVoidTenancyModalVisible] = useState(false);
  const openVoidIngoingModal = useCallback(() => {
    setVoidTenancyModalVisible(true);
  }, [setVoidTenancyModalVisible]);

  const navigateToClaimBond = useNavigateToClaimTenancyBond({
    managementId,
    tenancyId
  });

  useOnFocus(refetch);

  if (loading && !data) {
    return <BaseTenancyCard.Loading style={style} />;
  }

  const ingoingTenancy = data?.tenancy;

  if (!ingoingTenancy)
    throw new Error(`[Ingoing Tenancy] "${tenancyId}" not found`);

  const tenants = getTenantList(
    ingoingTenancy.id,
    ingoingTenancy.tenantships?.items
  );

  const tenantContactAilorns = tenants
    .map(({ contact }) =>
      contact
        ? AiloRN.from(contact.ailorn, { expected: "contact:contact" })
        : null
    )
    .filter(isPresent);

  const canEditTenancy =
    ingoingTenancy.rents.pageInfo.total === 1 &&
    ingoingTenancy.startDate &&
    new LocalDate(ingoingTenancy.startDate).isAfter(LocalDate.today());

  const bond = parseBond(ingoingTenancy);
  const bondMenuOptions = createBondMenuOptions({
    bond,
    onAddBondDetailsPress,
    onEditBondDetailsPress,
    tenancyId,
    navigateToClaimBond,
    onViewBondClaimPress,
    onEditBondReferencePress
  });

  const handleEditPress = (): void => {
    modal.show(
      <TenancyModalForm
        managementId={managementId}
        tenancyId={tenancyId}
        onDismiss={modal.hide}
        onSuccess={modal.hide}
      />
    );
  };
  return (
    <>
      <BaseTenancyCard
        title={CARD_TITLE}
        style={style}
        tenants={tenants.map(setVerifiedOnLegalEntity)}
        managementId={managementId}
        tenantContactAilorns={tenantContactAilorns}
        onDetailsPress={(): void => onDetailsPress(tenancyId, CARD_TITLE)}
        onTenantPress={onTenantPress}
        bondMenuOptions={bondMenuOptions}
        displayBondStatus={isBondInClaimProcess(bond)}
        headerRightComponent={
          <ManageMenu
            tenancyId={tenancyId}
            onEditPress={canEditTenancy ? handleEditPress : undefined}
            onRentAdjustmentsPress={(): void =>
              onRentAdjustmentsPress(tenancyId)
            }
            bondMenuOptions={bondMenuOptions}
            onVoidPress={openVoidIngoingModal}
          />
        }
      >
        {ingoingTenancy.startDate && (
          <StyledMovingInBanner startDate={ingoingTenancy.startDate} />
        )}
      </BaseTenancyCard>

      <VoidIngoingTenancyModal
        tenancyId={tenancyId}
        tenancyStartDate={
          ingoingTenancy.startDate
            ? LocalDate.from(ingoingTenancy.startDate)
            : undefined
        }
        isVisible={voidTenancyModalVisible}
        setIsVisible={setVoidTenancyModalVisible}
      />
    </>
  );
};

const StyledMovingInBanner = styled(MovingInBanner)`
  margin: 0 16px 16px;
`;
