import React, { useCallback, useMemo, useState } from "react";
import { Button, Grid, GridSortOrder } from "@ailo/ui";
import { useUnscheduledTenancyListFilters } from "../useUnscheduledTenancyListFilters";
import { Calendar, Colors, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";
import {
  UnscheduledTenanciesListRow,
  useGetUnscheduledTenanciesRows
} from "../useGetUnscheduledTenanciesRows";
import {
  UnscheduledTenanciesColumns,
  useGetTenanciesGridColumns
} from "./useGetTenanciesGridColumns";
import { useGetSelectedRowsMenu } from "./useGetSelectedRowsMenu";

export interface UnscheduledTenanciesListLoadingRow {
  key: string;
}

const initialSortOrder: GridSortOrder = {
  columnKey: UnscheduledTenanciesColumns.InspectionDue,
  direction: "ASC"
};

export function TenanciesGrid(): React.ReactElement {
  const { filterElements, filterState } = useUnscheduledTenancyListFilters();

  const [sortOrder, setSortOrder] = useState<GridSortOrder>(initialSortOrder);

  const {
    data: rows,
    loading,
    hasMore,
    loadingMore,
    fetchMore
  } = useGetUnscheduledTenanciesRows({
    filterState,
    sortOrder
  });

  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const columns = useGetTenanciesGridColumns({
    selectedRowIds: selectedRowIds,
    setSelectedRowIds: setSelectedRowIds
  });

  const onRowPress = useCallback(
    ({ key }: UnscheduledTenanciesListRow) => {
      if (selectedRowIds.find((row) => row === key)) {
        setSelectedRowIds(selectedRowIds.filter((row) => row != key));
      } else {
        setSelectedRowIds([...selectedRowIds, key]);
      }
    },
    [selectedRowIds]
  );

  const selectedRowsMenu = useGetSelectedRowsMenu({
    rows,
    selectedRowIds,
    setSelectedRowIds
  });

  const headerCaption = useMemo(() => {
    const filtersComponent = (
      <FiltersContainer>
        {filterElements.map((element, index) => (
          <View
            style={{ marginLeft: index > 0 ? 12 : 0 }}
            key={`filter-element-${index}`}
          >
            {element}
          </View>
        ))}
      </FiltersContainer>
    );

    return (
      <React.Fragment key={"project-list-header-caption"}>
        {filtersComponent}
        {selectedRowsMenu}
      </React.Fragment>
    );
  }, [filterElements, selectedRowsMenu]);

  if (loading) {
    return <></>;
  }

  if (rows.length === 0) {
    return (
      <EmptyContainer
        style={{
          borderTopWidth: 0
        }}
      >
        <Calendar style={{ marginBottom: 20 }} />
        <Text.BodyL>{"All properties scheduled"}</Text.BodyL>
      </EmptyContainer>
    );
  }

  return (
    <Grid
      headerCaption={headerCaption}
      footerCaption={
        hasMore ? (
          <FooterContainer>
            <Button.Secondary
              onPress={fetchMore}
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 16,
                paddingRight: 16
              }}
              loading={loadingMore}
            >
              {"Load more"}
            </Button.Secondary>
          </FooterContainer>
        ) : (
          <></>
        )
      }
      onRowPress={onRowPress}
      columns={columns}
      rows={rows}
      sortOrder={sortOrder}
      onSortOrderChange={setSortOrder}
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: Colors.WHITE,
        borderRadius: 4,
        borderWidth: 0.5,
        borderColor: Colors.GRAYSCALE.OUTLINE
      }}
      rowStyle={{
        padding: 16
      }}
      rowHeadStyle={{
        paddingTop: 20,
        paddingBottom: 8,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        paddingLeft: 16,
        paddingRight: 16
      }}
      rowHeadCellStyle={{
        paddingLeft: 16,
        paddingRight: 16
      }}
    />
  );
}

const FiltersContainer = styled(View)`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.WHITE};
  padding: 16px;
  box-shadow: 0px 1px 4px ${Colors.GRAYSCALE.OUTLINE};
`;

const FooterContainer = styled(View)`
  padding: 16px;
  justify-content: center;
  align-items: center;
`;

const EmptyContainer = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-bottom: 32px;
`;
