import { formatAddress } from "@ailo/domain-helpers";
import { LocalDate, Separator } from "@ailo/ui";
import { SplitScreenInfoPanel } from "local/common";
import { InvestorChips } from "local/domain/propertyManagement";
import { EndingManagementDetailsFragment } from "local/graphql";
import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { isPresent } from "ts-is-present";
import { getLastEndedTenancyEndDate } from "../getLastEndedTenancy";

export function ManagementDetailsUI({
  management
}: {
  management: EndingManagementDetailsFragment;
}): ReactElement {
  const { startDate, property, fixedTermEndDate, tenancies, owners } =
    management;

  let lastTenancyEndDateFormatted: string;
  const notVoidedTenancies = (tenancies?.items || []).filter(
    (tenancy) => !tenancy.voidedAt
  );
  if (notVoidedTenancies.length > 0) {
    const lastTenancyEndDate = getLastEndedTenancyEndDate(notVoidedTenancies);
    if (!lastTenancyEndDate) {
      throw new Error("Management has an unended tenancy");
    }
    lastTenancyEndDateFormatted = lastTenancyEndDate.format("D MMM YYYY");
  } else {
    lastTenancyEndDateFormatted = "N/A";
  }

  const rows = [
    [
      {
        label: "Property",
        content:
          formatAddress(property.address, {
            format: "street suburb state"
          }) || null
      }
    ],
    [
      {
        label: "Managed since",
        content: startDate
          ? new LocalDate(startDate).format("D MMM YYYY")
          : "N/A"
      },
      {
        label: "Mgt. agreement expiry",
        content: fixedTermEndDate
          ? new LocalDate(fixedTermEndDate).format("D MMM YYYY")
          : "N/A"
      }
    ],
    [
      {
        label: "Last tenancy end date",
        content: lastTenancyEndDateFormatted
      }
    ],
    [
      {
        content: <StyledSeparator />
      }
    ],
    [
      {
        label: "Investors",
        content: owners ? (
          <InvestorChips
            owners={owners
              .map((ownership) => ownership?.owner)
              .filter(isPresent)}
          />
        ) : null
      }
    ]
  ];

  return <SplitScreenInfoPanel rows={rows} />;
}

const StyledSeparator = styled(Separator)`
  margin-top: 2px;
  margin-bottom: 4px;
  margin-left: 0;
  width: 308px;
`;
