import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { useRentReviewForm } from "../../RentReviewFormContext";
import { RentReviewFields } from "../../RentReviewFormData";
import {
  OutOfCycleInfoForCreate,
  OutOfCycleInfoForUpdate
} from "./OutOfCycleInfo";

export function PreviewPlansSection({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  const { watch, errors, data } = useRentReviewForm();

  const amount = watch(RentReviewFields.amount);
  const effectiveDate = watch(RentReviewFields.effectiveDate);
  const period = watch(RentReviewFields.period);

  if (errors.amount || errors.effectiveDate || !amount || !effectiveDate)
    return <></>;

  const proposedRent = {
    amountInCents: amount.cents.toString(),
    effectiveDate,
    period
  };

  if (data.mode === "edit") {
    return (
      <OutOfCycleInfoForUpdate
        rentAilorn={data.rentAilorn}
        proposedRent={proposedRent}
        style={style}
      />
    );
  }

  return (
    <OutOfCycleInfoForCreate
      tenancyId={data.tenancyId}
      proposedRent={proposedRent}
      style={style}
    />
  );
}
