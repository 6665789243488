import { ActionDetailsFragment } from "local/graphql";
import React from "react";
import { ActionFormData } from "./ActionFormData";
import { CreateActionForm } from "./CreateActionForm/CreateActionForm";
import { UpdateActionForm } from "./UpdateActionForm/UpdateActionForm";

interface Props {
  action?: ActionDetailsFragment;
  projectId: string;
  prefillValues?: ActionFormData;
  onDismiss: () => void;
  onFormSubmitting: (isSubmitting: boolean) => void;
  setSubmitError: (hasError: boolean) => void;
}
export function CreateOrUpdateActionForm({
  action,
  projectId,
  onDismiss,
  onFormSubmitting,
  setSubmitError
}: Props): React.ReactElement {
  if (action) {
    return (
      <UpdateActionForm
        action={action}
        projectId={projectId}
        onFormSubmitting={onFormSubmitting}
        onDismiss={onDismiss}
        setSubmitError={setSubmitError}
      />
    );
  }

  return (
    <CreateActionForm
      projectId={projectId}
      onFormSubmitting={onFormSubmitting}
      onDismiss={onDismiss}
      setSubmitError={setSubmitError}
    />
  );
}
