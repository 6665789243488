import { isProjectOfTypeWithMeta } from "local/domain/project/project";
import { ActionType, ProjectType } from "local/graphql";
import React, { ReactElement } from "react";
import { RefinedActionActivityProps } from "../ActionActivityProps";
import { ActionActivityDepositSummaryCard } from "./ActionActivityDepositSummaryCard";

export const ManageDepositActionActivity = ({
  action
}: RefinedActionActivityProps<ActionType.ManageDeposit>): ReactElement => {
  const { project } = action;

  if (!isProjectOfTypeWithMeta(project, ProjectType.NewTenancy)) {
    throw new Error("Project is not type of NewTenancy");
  }

  return (
    <ActionActivityDepositSummaryCard
      tenancyId={project.meta.newTenancy?.id}
      managementId={project.meta.newTenancyManagement.id}
    />
  );
};
