import { Colors, opacify } from "@ailo/primitives";
import React from "react";
import { LinearGradient } from "expo-linear-gradient";
import { StyleProp, ViewStyle } from "react-native";

interface Props {
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  isTenant?: boolean;
  isInvestor?: boolean;
}

export function CardColorGradient({
  isTenant,
  isInvestor,
  children,
  style
}: Props): React.ReactElement {
  return (
    <LinearGradient
      colors={getGradientColors({ isTenant, isInvestor })}
      start={[0, 0]}
      end={[1, 0]}
      style={style}
    >
      {children}
    </LinearGradient>
  );
}

function getGradientColors({
  isTenant,
  isInvestor
}: {
  isTenant?: boolean;
  isInvestor?: boolean;
}): string[] {
  if (isTenant && isInvestor) {
    return [
      opacify(Colors.STATUS.WARNING, 0),
      opacify(Colors.STATUS.WARNING, 0.05),
      opacify(Colors.TENANT_BLUE, 0.05)
    ];
  }

  if (isTenant) {
    return [opacify(Colors.TENANT_BLUE, 0), opacify(Colors.TENANT_BLUE, 0.05)];
  }

  if (isInvestor) {
    return [
      opacify(Colors.STATUS.WARNING, 0),
      opacify(Colors.STATUS.WARNING, 0.05)
    ];
  }

  return [opacify(Colors.CHARCOAL, 0), opacify(Colors.CHARCOAL, 0.075)];
}
