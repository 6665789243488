import { Colors } from "@ailo/primitives";
import { LocalDate } from "@ailo/date";

export function getScheduledRentReviewColor(
  scheduledRentReviewDate: LocalDate
): string {
  const diff = scheduledRentReviewDate.diff(LocalDate.today());

  if (diff < 60) return Colors.TEXT.RED;
  if (diff >= 60 && diff <= 90) return Colors.TEXT.ORANGE;
  return Colors.TEXT.DARK.PRIMARY;
}
