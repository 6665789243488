import { FullscreenFileDropUploader, useHasFeature } from "@ailo/domains";
import { ScreenComponent, useAnalytics } from "@ailo/services";
import { Screens, useNavigation, useRoute } from "local/common";
import { PlatformFeatureId } from "local/graphql";
import React, { useCallback } from "react";
import {
  BillListScreenContent,
  BillListScreenContentRefAttributes,
  usePayerTypes
} from "./BillListScreenContent";
import { TabId, tabs } from "./tabs";
import { useOpenAddBillSidebar } from "./useOpenAddBillSidebar";

export type { BillListScreenContentRefAttributes as BillListRefAttributes };

const screenName = "Bills";
const tabLabel = (id: TabId): string | undefined =>
  tabs.find((t) => t.id == id)?.label;

export const BillListScreen = ({
  billListRef
}: {
  billListRef?: React.RefObject<BillListScreenContentRefAttributes>;
}): React.ReactElement => {
  const navigation = useNavigation();
  const route = useRoute<Screens.BillsTab>();
  const analytics = useAnalytics();
  const trackScreenVisited = (payload: {
    filteredByPayerType?: string[];
    tab?: string;
  }): void => analytics.trackScreenVisited(screenName, payload);

  const payerTypes = usePayerTypes((selected) =>
    trackScreenVisited({
      ...analyticsPayload,
      filteredByPayerType: selected
    })
  );

  const tab = route.params?.tab ?? tabs[0].id;
  const onTabChange = (tabId: TabId): void => {
    navigation.navigate(Screens.BillsTab, {
      ...route.params,
      tab: tabId
    });
    trackScreenVisited({
      ...analyticsPayload,
      tab: tabLabel(tabId)
    });
  };

  const analyticsPayload = {
    filteredByPayerType: payerTypes?.selected,
    tab: tabLabel(tab)
  };

  const viewBillEnabled = useHasFeature(PlatformFeatureId.ViewBillAsAgent);
  const viewBill = useCallback(
    (billId: string) => {
      navigation.navigate(Screens.BillsTab, {
        tab,
        billId
      });
    },
    [navigation, tab]
  );
  const onBillRowPress = viewBillEnabled ? viewBill : undefined;

  const openAddBillSidebar = useOpenAddBillSidebar();

  return (
    <ScreenComponent
      analyticsScreenName={screenName}
      analyticsTrackPayload={analyticsPayload}
    >
      <FullscreenFileDropUploader
        prompt={"Create a new bill"}
        onDrop={openAddBillSidebar}
      >
        <BillListScreenContent
          ref={billListRef}
          tab={tab}
          onBillRowPress={onBillRowPress}
          onTabChange={onTabChange}
          payerTypes={payerTypes}
        />
      </FullscreenFileDropUploader>
    </ScreenComponent>
  );
};
