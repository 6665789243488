import { usePressableNavigation } from "@ailo/services";
import {
  EmptyGrid,
  ErrorCard,
  Grid,
  gridBodyMarginTop,
  GridColumnOptions,
  GridRenderRowContainerProps,
  GridSortOrder,
  PaginationNavigation
} from "@ailo/ui";
import { StackActions } from "@react-navigation/native";
import { Screens } from "local/common";
import React, { useCallback } from "react";
import { Pressable, View, Image } from "react-native";
import styled from "styled-components/native";
import {
  BasePropertyListLoadingRow,
  BasePropertyListRow
} from "./BasePropertyListRow";
import { FilterHeaderWithTabNavigation } from "./filters/FilterHeaderWithTabNavigation";
import { PROPERTY_LIST_PAGE_SIZE } from "./hooks/usePaginationRequest";
import { getPaginationData } from "./utils";
import { House } from "@ailo/primitives";
import { EmptyFilteredPropertyList } from "./EmptyFilteredPropertyList";
import { usePropertyListSelectedTeams } from "./hooks/usePropertyListSelectedTeams";

export interface PropertyListQueryData {
  data: {
    pageInfo: {
      total: number;
      pageNo: number;
      hasNext: boolean;
      hasPrevious: boolean;
    };
    error: boolean;
    managements: BasePropertyListRow[];
  };
  loading: boolean;
  requestNewPage: (
    sortOrder: GridSortOrder,
    forward: boolean,
    pageNo: number
  ) => void;
  requestFromBeginning: (sortOrder: GridSortOrder) => void;
}

export interface BasePropertyListProps {
  tabNavigation: React.ReactElement;
  queryData: PropertyListQueryData;
  errorMessage: string;
  emptyMessage: string;
  secondaryEmptyMessage: React.ReactElement | string;
  sortOrder: GridSortOrder;
  columns: Array<
    GridColumnOptions<BasePropertyListRow> & {
      renderLoadingCell: GridColumnOptions<BasePropertyListLoadingRow>["renderCell"];
    }
  >;
  onSortOrderChange?: (sortOrder: GridSortOrder) => void;
  footerButton?: React.ReactElement;
  filterElements?: React.ReactElement[];
  isAnyFilterActive?: boolean;
}

function RowContainer({
  row,
  style,
  children
}: GridRenderRowContainerProps<{
  key: string;
  managementId: string;
}>): React.ReactElement {
  const linkProps = usePressableNavigation({
    link: {
      to: `/properties/${row.managementId}`,
      action: StackActions.push(Screens.Property, {
        managementId: row.managementId
      })
    }
  });

  return (
    <Pressable {...linkProps} style={style}>
      {children}
    </Pressable>
  );
}

function LoadingRowContainer({
  style,
  children
}: GridRenderRowContainerProps<{ key: string }>): React.ReactElement {
  return <View style={[style, { borderBottomWidth: 0 }]}>{children}</View>;
}

const BasePropertyList: React.FC<BasePropertyListProps> = ({
  tabNavigation,
  queryData,
  errorMessage,
  emptyMessage,
  secondaryEmptyMessage,
  columns,
  sortOrder,
  onSortOrderChange,
  footerButton,
  filterElements,
  isAnyFilterActive
}) => {
  const { teamIds, unassignedSelected } = usePropertyListSelectedTeams();
  const { data, loading, requestFromBeginning, requestNewPage } = queryData;
  const bodyStyle = filterElements?.length ? FilteredListStyle : undefined;
  const renderEmptyFilteredList = useCallback(
    () => (
      <EmptyFilteredPropertyList
        style={
          bodyStyle || {
            marginTop: -gridBodyMarginTop,
            paddingTop: gridBodyMarginTop
          }
        }
      />
    ),
    [bodyStyle]
  );

  if (loading)
    return (
      <Grid
        testID={"base-property-grid"}
        headerCaption={
          <FilterHeaderWithTabNavigation
            tabNavigation={tabNavigation}
            filterElements={filterElements}
          />
        }
        rows={[{ key: "1" }, { key: "2" }, { key: "3" }]}
        columns={columns.map((col) => ({
          ...col,
          renderCell: col.renderLoadingCell
        }))}
        sortOrder={sortOrder}
        renderRowContainer={LoadingRowContainer}
        bodyStyle={bodyStyle}
      />
    );

  if (data.error)
    return (
      <Grid.Placeholder headerCaption={tabNavigation}>
        <ErrorCard
          message={errorMessage}
          onReload={(): void => requestFromBeginning(sortOrder)}
        />
      </Grid.Placeholder>
    );

  if (
    data.pageInfo.total === 0 &&
    (isAnyFilterActive || teamIds.length > 0 || unassignedSelected)
  )
    return (
      <Grid
        headerCaption={
          <FilterHeaderWithTabNavigation
            tabNavigation={tabNavigation}
            filterElements={filterElements}
          />
        }
        columns={columns}
        gridBodyComponent={renderEmptyFilteredList}
        rows={[]}
      />
    );

  if (data.pageInfo.total === 0)
    return (
      <Grid.Placeholder headerCaption={tabNavigation}>
        <EmptyGrid
          message={emptyMessage}
          secondaryMessage={secondaryEmptyMessage}
          bannerImage={
            <Image
              source={House}
              style={{ width: 106, height: 80, alignSelf: "center" }}
            />
          }
        />
      </Grid.Placeholder>
    );

  const pagination = getPaginationData(data.pageInfo, PROPERTY_LIST_PAGE_SIZE);

  return (
    <Grid
      headerCaption={
        <FilterHeaderWithTabNavigation
          tabNavigation={tabNavigation}
          filterElements={filterElements}
        />
      }
      footerCaption={
        <StyledContainer>
          <GridCell />
          <PaginationNavigation
            {...pagination}
            style={{ justifyContent: "center" }}
            onGoToPreviousPage={(): void => {
              requestNewPage(sortOrder, false, pagination.currentPage);
            }}
            onGoToNextPage={(): void => {
              requestNewPage(sortOrder, true, pagination.currentPage);
            }}
          />
          <GridCell style={{ justifyContent: "flex-end" }}>
            {footerButton}
          </GridCell>
        </StyledContainer>
      }
      bodyStyle={bodyStyle}
      columns={columns}
      rows={data.managements}
      sortOrder={sortOrder}
      renderRowContainer={RowContainer}
      onRowPress={(): void => {}}
      onSortOrderChange={onSortOrderChange}
    />
  );
};

const StyledContainer = styled(View)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 24px;
  margin-bottom: 32px;
  height: 40px;
  align-items: center;
`;

const GridCell = styled(View)`
  flex-direction: row;
`;

const FilteredListStyle = {
  marginTop: -47,
  paddingTop: 47
};

export { BasePropertyList };
