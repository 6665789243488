import React, { ReactElement, useCallback } from "react";
import {
  ActionType,
  InspectionAppointmentDetailsFragment,
  InspectionType,
  ProjectType,
  TenancyFragment
} from "local/graphql";
import { RefinedActionActivityProps } from "../ActionActivityProps";
import { ErrorAlertScreen, useGlobalModal } from "@ailo/ui";
import { isProjectOfTypeWithMeta } from "local/domain/project/project";
import { AiloSentry } from "@ailo/services";
import { useCompleteAssignTenancyAction } from "./useCompleteAssignTenancyAction";
import { DisplayTenancyDetails } from "./DisplayTenancyDetails";
import { CreateTenancyView } from "./CreateTenancyView";
import { useCreateInspectionAppointments } from "./useCreateInspectionAppointments";
import { useFindActionOfTypeInProject } from "./useFindActionOfTypeInProject";
import { useCurrentAgencyOrg } from "local/common";
import { Text } from "@ailo/primitives";

export function AssignTenancyActionActivity({
  action,
  projectId
}: RefinedActionActivityProps<ActionType.AssignTenancy>): ReactElement {
  const modal = useGlobalModal();
  const { ailoRN: agencyAilorn } = useCurrentAgencyOrg();
  const { completeAssignTenancyAction } = useCompleteAssignTenancyAction();
  const { createInspectionAppointments } = useCreateInspectionAppointments();

  const {
    loading,
    error,
    action: trackInspectionAction
  } = useFindActionOfTypeInProject({
    projectId,
    actionType: ActionType.TrackInspectionCompleted
  });

  const onCreateSuccess = useCallback(
    async (tenancy: TenancyFragment) => {
      let inspectionAppointments:
        | InspectionAppointmentDetailsFragment[]
        | undefined;
      if (trackInspectionAction) {
        inspectionAppointments = await createInspectionAppointments({
          agencyAilorn: agencyAilorn.toString(),
          input: [
            {
              inspectingAgentAilorn: action.project.assignee.ailoRN.toString(),
              propertyAilorn: tenancy.property.ailorn.toString(),
              tenancyAilorn: tenancy.ailoRN.toString(),
              type: InspectionType.Ingoing
            }
          ]
        });
      }
      await completeAssignTenancyAction({
        tenancy,
        actionId: action.id,
        inspectionAppointmentAilorn: inspectionAppointments?.[0].ailorn
      });

      modal.hide();
    },
    [
      trackInspectionAction,
      completeAssignTenancyAction,
      action.id,
      action.project.assignee.ailoRN,
      modal,
      createInspectionAppointments,
      agencyAilorn
    ]
  );

  const project = action.project;

  if (loading) {
    return <Text.Loading variant={"BodyM"} width={100} />;
  }

  if (!isProjectOfTypeWithMeta(project, ProjectType.NewTenancy) || error) {
    AiloSentry.captureException(
      new Error(`Project is not of ${ProjectType.NewTenancy} type`)
    );
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the action information."}
      />
    );
  }
  const managementId = project.meta.newTenancyManagement.id;
  const tenancyId = action.meta.tenancy?.id;

  if (tenancyId) {
    return (
      <DisplayTenancyDetails
        tenancyId={tenancyId}
        managementId={managementId}
      />
    );
  }

  return (
    <CreateTenancyView
      managementId={managementId}
      onCreateSuccess={onCreateSuccess}
    />
  );
}
