import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { useCallback } from "react";
import {
  RemoveReiTokenMutation,
  useRemoveReiTokenMutation,
  RemoveReiTokenInput
} from "local/graphql";

export function useRemoveReiToken({
  onCompleted,
  onError
}: {
  onCompleted?: () => void;
  onError?: () => void;
} = {}): [
  (removeReiTokenInput: RemoveReiTokenInput) => void,
  MutationResult<RemoveReiTokenMutation>
] {
  const [removeReiTokenMutation, result] = useRemoveReiTokenMutation();
  const analytics = useAnalytics();

  const removeReiToken = useCallback(
    async (removeReiTokenInput: RemoveReiTokenInput): Promise<void> => {
      try {
        const result = await removeReiTokenMutation({
          variables: {
            removeReiTokenInput,
            organisationAilorn: removeReiTokenInput.organisationAilorn
          }
        });

        throwIfMutationFailed(result, { dataKey: "removeReiToken" });
        onCompleted?.();

        analytics.track("REI Account Removed");
      } catch (error) {
        AiloSentry.captureException(error);
        onError?.();
      }
    },
    [removeReiTokenMutation, onCompleted, analytics, onError]
  );

  return [removeReiToken, result];
}
