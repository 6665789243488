import React from "react";
import { SFC, useSynchronisedLoad } from "@ailo/primitives";
import {
  RentHealthBody,
  RentHealthHeader,
  SectionContainer
} from "./components";
import { useGetPropertyRentHealthQuery, OccupancyStatus } from "local/graphql";

interface Props {
  managementId: string;
}

export const RentHealthSection: SFC<Props> = ({ style, managementId }) => {
  const { data, loading } = useGetPropertyRentHealthQuery({
    variables: { managementId }
  });

  const isLoading = useSynchronisedLoad(loading && !data);

  if (isLoading) return null;

  const management = data?.management;

  if (!management)
    throw new Error(
      `[Property Rent Health] Management "${managementId}" not found`
    );

  const { mostRecentTenancy, occupancyStatus } = management;

  if (!occupancyStatus)
    throw new Error(
      `[Property Rent Health] Management "${managementId}" has no occupancy status`
    );

  const currentRentSchedule = mostRecentTenancy?.currentRentSchedule;
  const nextRentSchedule = mostRecentTenancy?.nextRentSchedule;
  const nextRentReview = mostRecentTenancy?.nextRentReview;
  const scheduledRentReviewDate = mostRecentTenancy?.scheduledRentReviewDate;
  const paidToDate = mostRecentTenancy?.liability?.effectivePaidToDate;

  const isGeneratingIncome = Boolean(
    occupancyStatus !== OccupancyStatus.Vacant &&
      (currentRentSchedule || nextRentSchedule)
  );

  return (
    <SectionContainer style={style}>
      <RentHealthHeader
        style={{ marginBottom: 12 }}
        paidToDate={paidToDate}
        isGeneratingIncome={isGeneratingIncome}
      />
      <RentHealthBody
        paidToDate={paidToDate}
        endDate={mostRecentTenancy?.endDate}
        currentRent={currentRentSchedule}
        nextRentSchedule={nextRentSchedule}
        nextRentReview={nextRentReview}
        scheduledRentReviewDate={scheduledRentReviewDate}
        isVacant={occupancyStatus === OccupancyStatus.Vacant}
      />
    </SectionContainer>
  );
};
