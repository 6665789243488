import React, { ReactElement } from "react";
import { BaseModal, Button } from "@ailo/ui";
import { Text } from "@ailo/primitives";
import { Row } from "local/common";

export function DeleteCentrepayModal({
  visible,
  onPress,
  onDismiss,
  renterName
}: {
  visible: boolean;
  onPress: () => Promise<void>;
  onDismiss: () => void;
  renterName: string;
}): ReactElement {
  return (
    <BaseModal
      title={"Remove renter’s Centrepay from tenancy"}
      visible={visible}
    >
      <Text.BodyS>
        {`This will stop any funds coming from Centrepay to pay rent for this tenancy on behalf of ${renterName}. Are you sure you want to remove this?`}
      </Text.BodyS>
      <Row style={{ marginTop: 32 }}>
        <Button onPress={onDismiss} variant={"secondary"}>
          {"Cancel"}
        </Button>
        <Button onPress={onPress} variant={"destructive"}>
          {"Remove"}
        </Button>
      </Row>
    </BaseModal>
  );
}
