import React, { ReactElement } from "react";
import { DefaultDisbursementsList } from "local/tabs/trustaccounting";

function AllDisbursementsList({
  tabNavigation
}: {
  tabNavigation: ReactElement;
}): ReactElement {
  return <DefaultDisbursementsList tabNavigation={tabNavigation} />;
}

export { AllDisbursementsList };
