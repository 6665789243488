import { LocalDate } from "@ailo/date";
import { formatRentPaymentSchedule } from "@ailo/domains";
import { Money } from "@ailo/ui";
import { hasPresentKey } from "ts-is-present";
import { TenancyInArrearsFromOwingFragment } from "local/graphql";
import { getStreetAndStreetNumber } from "../PropertyItemCell/utils";

export const columnHeaders: string[] = [
  "Address",
  "Rent amount",
  "Property paying in Ailo since",
  "Arrears amount",
  "Paid to date",
  "Days in arrears"
];

export function getArrearsReportFromOwingCsvData(
  tenanciesInArrears: TenancyInArrearsFromOwingFragment[]
): string[][] {
  const csvData = tenanciesInArrears
    .filter(hasPresentKey("displayRent"))
    .filter(hasPresentKey("rent"))
    .map((tenancy) => {
      const address = `${getStreetAndStreetNumber(tenancy.property.address)}, ${
        tenancy.property.address.suburb
      }`;

      const rentAmount = formatRentPaymentSchedule(tenancy.displayRent);

      const rentOwing = tenancy.rent;

      const firstPaymentDate = rentOwing.progress.firstPaidAt
        ? LocalDate.fromString(rentOwing.progress.firstPaidAt).format(
            "DD MMM YYYY"
          )
        : "";

      const arrears = rentOwing.progress.arrears;

      const arrearsAmount = Money.fromCents(
        arrears.amount.total.cents
      ).format();

      const paidTo = LocalDate.fromString(
        rentOwing.progress.paidTo.effective.date
      ).format("DD MMM YYYY");

      //TODO: query from owing instead of manually calculating
      const daysInArrears = LocalDate.today()
        .diff(new LocalDate(arrears.date), "days")
        .toString();

      return [
        address,
        rentAmount,
        firstPaymentDate,
        arrearsAmount,
        paidTo,
        daysInArrears
      ];
    });

  csvData.unshift(columnHeaders);
  return csvData;
}
