import { Controller, UseFormMethods } from "react-hook-form";
import React, { ReactElement } from "react";
import { RadioButtonGroup } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import { AddCentrepayerFormData } from "./CentrepaySetupModal";
import { TenantCentrepayInfo } from "../TenantCentrepayInfo";

export function RenterSelectionInput({
  form,
  tenants,
  renderTenantSelection
}: {
  form: UseFormMethods<AddCentrepayerFormData>;
  tenants: TenantCentrepayInfo[];
  renderTenantSelection: boolean;
}): React.ReactElement {
  const render = ({
    value,
    onChange
  }: {
    value: string;
    onChange: () => void;
  }): ReactElement => (
    <>
      {renderTenantSelection && (
        <>
          <Text.BodyS
            color={Colors.TEXT.DARK.SECONDARY}
            style={{ marginTop: 24, marginBottom: 10 }}
          >
            {"Select renter"}
          </Text.BodyS>
          <RadioButtonGroup
            value={value}
            onChange={onChange}
            options={tenants.map((tenant) => ({
              value: tenant.legalEntity.toString(),
              label: tenant.name,
              disabled: tenant?.centrepaySetUp
            }))}
          />
        </>
      )}
    </>
  );
  return (
    <>
      <Controller
        control={form.control}
        name={"renter"}
        render={render}
        rules={{
          required: "Select a renter to continue"
        }}
      />
      {form.errors["renter"]?.message && (
        <Text.BodyS color={Colors.STATUS.ERROR} style={{ marginTop: 10 }}>
          {form.errors["renter"]?.message}
        </Text.BodyS>
      )}
    </>
  );
}
