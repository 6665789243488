import { BPayTypeName, useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { Alert, FormFieldGroup, FormFieldRow, Separator } from "@ailo/ui";
import {
  FilesInput,
  BillDescriptionInput,
  CustomerReferenceInput,
  DueDateInput,
  InvoiceNumberInput,
  IssueDateInput,
  LineItemsInput,
  TaxCategoryInput
} from "local/domain/bill/AddBillForm";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import React, { ReactElement, RefObject } from "react";
import { useFormContext } from "react-hook-form";
import { ScrollView } from "react-native";

export function BillInputs({
  scrollViewRef
}: {
  scrollViewRef: RefObject<ScrollView>;
}): ReactElement {
  const form = useFormContext<AddBillFormData>();
  const invoiceNumberWarning = form.watch("invoiceNumberWarning");
  const paymentType = form.watch("payment.type");
  const attachmentsEnabled = useHasFeature(PlatformFeatureId.BillAttachments);

  return (
    <>
      <FormFieldGroup label={"Bill Details"} style={{ marginTop: 32 }}>
        <CustomerReferenceInput
          style={{
            marginBottom: 24,
            width: "50%",
            paddingRight: 8
          }}
          shouldRender={paymentType === BPayTypeName}
        />
        <FormFieldRow>
          <InvoiceNumberInput />
          <TaxCategoryInput />
        </FormFieldRow>
        {invoiceNumberWarning && (
          <Alert type={"warning"} style={{ marginTop: 24 }}>
            {invoiceNumberWarning}
          </Alert>
        )}
        <FormFieldRow style={{ marginTop: 24 }}>
          <IssueDateInput />
          <DueDateInput />
        </FormFieldRow>
        <BillDescriptionInput style={{ marginTop: 24 }} />
      </FormFieldGroup>
      <Separator style={{ marginLeft: 0, marginTop: 40 }} />
      <LineItemsInput style={{ marginTop: 32 }} />
      {attachmentsEnabled && (
        <>
          <Separator style={{ marginLeft: 0, marginTop: 40 }} />
          <FilesInput
            label={"Files"}
            style={{ marginTop: 32 }}
            onAttached={() => {
              scrollViewRef.current?.scrollToEnd({ animated: true });
            }}
          />
        </>
      )}
    </>
  );
}
