import { Row } from "local/common";

import { Text } from "@ailo/primitives";

import { DisbursementPeriodFilter } from "../../components/DisbursementPeriod";
import { DateRangeFilter } from "../../components/DateRangeFilter";
import { IncomeByChartOfAccountData } from "./IncomeByChartOfAccountData";
import React, { useState } from "react";
import { LocalDate } from "@ailo/date";

export function IncomeByChartOfAccountTable(): React.ReactElement {
  const [disbursementPeriodSelection, setDisbursementPeriodSelection] =
    useState<{
      value: string;
      label: string;
    }>();
  const [dateRangeSelection, setDateRangeSelection] = useState<{
    startDate?: string;
    endDate?: string;
  }>({
    startDate: LocalDate.today().setDayOfMonth(1).toString(),
    endDate: LocalDate.today().toString()
  });
  function onDisbursementPeriodChange(selection?: {
    value: string;
    label: string;
  }): void {
    setDisbursementPeriodSelection(selection);
    // clear the date range
    setDateRangeSelection({});
  }
  function onDateRangeChange({
    startDate,
    endDate
  }: {
    startDate?: string;
    endDate?: string;
  }): void {
    setDateRangeSelection({
      startDate,
      endDate
    });
    // clear the disbursement period
    setDisbursementPeriodSelection(undefined);
  }
  const incomeByPropertyDataKey = [
    "incomeByChartOfAccount",
    "disbursementDateSk",
    disbursementPeriodSelection?.value,
    "start",
    dateRangeSelection?.startDate,
    "end",
    dateRangeSelection?.endDate
  ].join("_");

  return (
    <IncomeByChartOfAccountData
      key={incomeByPropertyDataKey}
      disbursementPeriodSk={disbursementPeriodSelection?.value}
      disbursementPeriodLabel={disbursementPeriodSelection?.label}
      startDate={dateRangeSelection?.startDate}
      endDate={dateRangeSelection?.endDate}
      filterComponents={
        <Row
          style={{
            alignItems: "flex-end",
            justifyContent: "flex-start",
            paddingBottom: "50px",
            flexWrap: "wrap"
          }}
        >
          <DateRangeFilter
            dateRangeSelection={dateRangeSelection}
            onChange={onDateRangeChange}
          />
          <div
            style={{
              alignItems: "center",
              marginBottom: "12px",
              marginTop: "12px",
              paddingLeft: "50px",
              paddingRight: "50px"
            }}
          >
            <Text.BodyM>{"or"}</Text.BodyM>
          </div>
          <DisbursementPeriodFilter
            selectedDisbursementPeriod={disbursementPeriodSelection}
            onChange={onDisbursementPeriodChange}
          />
        </Row>
      }
    />
  );
}
