import { Screens, useRoute } from "local/common";
import React, { ReactElement } from "react";
import { CreateFeeTab } from "./CreateFeeTab";
import { FeeDetailsTab } from "./FeeDetailsTab";
import { FeeListTab } from "./FeeListTab";

export function FeesTab(): ReactElement {
  const { params } = useRoute<Screens.SettingsTab>();

  if (params?.id === "new") {
    return <CreateFeeTab />;
  }

  if (params?.id) {
    return <FeeDetailsTab />;
  }

  return <FeeListTab />;
}
