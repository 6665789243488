import { useRoute as useReactRoute } from "@react-navigation/native";
import { NavigatorsFlatParamList } from "./screenParams";
import { Screens } from "./Screens";

export type Route<T extends Screens> = {
  key: string;
  name: T;
  params?: NavigatorsFlatParamList[T];
};

export function useRoute<T extends Screens>(): Route<T> {
  return useReactRoute();
}
