import { LocalDate } from "@ailo/date";
import { DatePeriod } from "../periods";
import { RawRow } from "./getRowColumns";

type Row = RawRow & {
  incomeAmount: number;
  rowDate: LocalDate;
  period: DatePeriod;
};
export function calculateIncomeOfFeeCatagoriesInPeriod(rows: Row[]): Row[] {
  return rows.reduce<Row[]>((prev, row) => {
    const lastValue = prev[prev.length - 1];
    if (!lastValue?.rowDate.isSame(row.rowDate)) {
      return [...prev, row];
    }

    prev.pop();
    return [
      ...prev,
      {
        ...row,
        incomeAmount: (row.incomeAmount ?? 0) + (lastValue?.incomeAmount ?? 0),
        feeName: `${row.feeName}|${lastValue.feeName}`
      }
    ];
  }, []);
}
