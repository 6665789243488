import { Colors } from "@ailo/primitives";
import React, { ReactElement, ReactNode } from "react";

type Props = {
  url: string;
  children?: ReactNode;
};

export const ExternalLink = ({ url, children }: Props): ReactElement => {
  return (
    <a
      href={url}
      target={"_blank"}
      rel={"noreferrer"}
      style={{
        color: Colors.AILO_RED
      }}
    >
      {children}
    </a>
  );
};
