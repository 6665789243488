import { useHasFeature } from "@ailo/domains";
import { useOnFocus } from "@ailo/services";
import { PersonListItem, setVerifiedOnLegalEntity } from "local/domain/authz";
import { LegalEntityList } from "local/domain/authz/LegalEntityList";
import { PropertyScreenCardListSubHeader } from "local/domain/propertyManagement";
import {
  PlatformFeatureId,
  useGetManagementOwnerEndDetailsQuery
} from "local/graphql";
import React, { useMemo } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { isPresent } from "ts-is-present";
import { ListItemAction } from "../common/ListItemAction";
import { isFormerManagement } from "../utils";
import {
  PropertyContentCard,
  PropertyContentCardLoading
} from "../PropertyContentCard";
import styled from "styled-components/native";
import { Separator } from "@ailo/ui";
import { AiloRN } from "@ailo/ailorn";
import { useCurrentAgencyOrg } from "local/common";
import { ManagementCardTitleRight } from "local/tabs/properties/components/PropertyScreen/components/PropertyContent/components/ManagementCardWithBoundary/ManagementCardTitleRight";

interface Props {
  managementId: string;
  onDetailsPress: () => void;
  onOwnerPress?: (id: string, entityType: "Person" | "Company") => void;
  onViewManagementFeeBlueprintsPress: () => void;
  style?: StyleProp<ViewStyle>;
}

export function ManagementCard({
  managementId,
  style,
  onDetailsPress,
  onOwnerPress,
  onViewManagementFeeBlueprintsPress
}: Props): React.ReactElement | null {
  const hasManagementFeeBlueprintFeature = useHasFeature(
    PlatformFeatureId.ManagementFeeBlueprint
  );
  const { ailoRN: currentAgencyOrgAilornString } = useCurrentAgencyOrg();

  const { data, loading, refetch } = useGetManagementOwnerEndDetailsQuery({
    variables: {
      id: managementId,
      organisationAilorn: currentAgencyOrgAilornString
    }
  });
  useOnFocus(refetch);

  const management = data?.management;

  const owners = useMemo(
    () =>
      (management?.ownerships?.items || [])
        .map((ownership) => ownership?.owner)
        .filter(isPresent),
    [management?.ownerships?.items]
  );

  const participantContactAilorns = useMemo(
    () =>
      owners
        .map(({ contact }) =>
          contact
            ? AiloRN.from(contact.ailorn, { expected: "contact:contact" })
            : null
        )
        .filter(isPresent),
    [owners]
  );

  const title = useMemo(
    () =>
      isFormerManagement(management?.endDate)
        ? "Former management"
        : "Management",
    [management?.endDate]
  );

  if (loading && !data) {
    return <ManagementCard.Loading />;
  }

  if (!management)
    throw new Error(
      `[Property Owners List] Management "${managementId}" not found`
    );

  if (!owners || owners.length === 0) {
    return null;
  }

  return (
    <PropertyContentCard
      style={style}
      title={title}
      titleRight={
        <ManagementCardTitleRight
          management={management}
          participantContactAilorns={participantContactAilorns}
        />
      }
    >
      <PropertyScreenCardListSubHeader
        style={{
          marginTop: 4,
          marginHorizontal: 16,
          marginBottom: 16
        }}
      >
        {"Contacts"}
      </PropertyScreenCardListSubHeader>
      <LegalEntityList
        legalEntities={owners.map(setVerifiedOnLegalEntity)}
        hideLastItemBorder={true}
        onItemPress={onOwnerPress}
      />
      <StyledSeparator />
      <PropertyScreenCardListSubHeader
        style={{
          marginTop: 20,
          marginHorizontal: 16
        }}
      >
        {"Management"}
      </PropertyScreenCardListSubHeader>
      <ListItemAction
        header={"Management details"}
        onPress={onDetailsPress}
        style={{
          marginBottom: hasManagementFeeBlueprintFeature ? 0 : 16
        }}
      />
      {hasManagementFeeBlueprintFeature && (
        <ListItemAction
          header={"Fee schedule"}
          onPress={onViewManagementFeeBlueprintsPress}
          style={{ marginTop: 12 }}
        />
      )}
    </PropertyContentCard>
  );
}

function Loading({
  style,
  headerRightComponentLoading
}: {
  style?: StyleProp<ViewStyle>;
  headerRightComponentLoading?: React.ReactElement;
}): React.ReactElement {
  return (
    <PropertyContentCardLoading
      style={style}
      titleWidth={28}
      titleRight={headerRightComponentLoading}
    >
      <PersonListItem.Loading style={{ borderType: "none" }} />
      <PersonListItem.Loading style={{ borderType: "none" }} />
    </PropertyContentCardLoading>
  );
}

ManagementCard.Loading = Loading;

const StyledSeparator = styled(Separator)`
  margin-left: 0;
  margin-top: 16px;
`;
