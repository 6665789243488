import React, { FC } from "react";
import { ErrorAlertScreen } from "@ailo/ui";

interface Props {
  onReload: () => void;
}

const TransferError: FC<Props> = ({ onReload }) => {
  return (
    <ErrorAlertScreen
      title={"There was a problem processing your transaction"}
      onRetry={onReload}
    />
  );
};

export { TransferError };
