import { Calendar, Text, Colors } from "@ailo/primitives";
import { getInspectionMonths } from "local/domain/inspection";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { InspectionMonthListItem } from "./InspectionMonthListItem";
import { useInspectionPlanner } from "../InspectionPlannerContext";

export function InspectionMonthList(): React.ReactElement {
  const { inspectionDayQuery } = useInspectionPlanner();
  const { data, loading } = inspectionDayQuery;

  if (loading) {
    return <InspectionMonthListItem.Loading />;
  }

  if (data.length === 0) {
    return (
      <EmptyContainer>
        <Calendar style={{ marginBottom: 20 }} />
        <Text.BodyL>{"No inspections scheduled"}</Text.BodyL>
      </EmptyContainer>
    );
  }

  return (
    <>
      {getInspectionMonths(data).map((inspectionMonth) => (
        <InspectionMonthListItem
          inspectionMonth={inspectionMonth}
          key={`InspectionMonth starting ${inspectionMonth.firstDayOfMonth.format()}`}
        />
      ))}
    </>
  );
}

const EmptyContainer = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${Colors.GRAYSCALE.OUTLINE};
  padding-bottom: 60px;
`;
