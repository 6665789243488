import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import { DepositData, parseDepositData } from "local/domain/propertyManagement";
import { useGetTenancyAndTenancyDepositDetailsQuery } from "local/graphql";

export function useGetTenancyDepositData(
  tenancyId: string
): MappedQueryResult<DepositData | undefined> {
  return useMappedQueryResult(
    useGetTenancyAndTenancyDepositDetailsQuery({
      variables: { tenancyId }
    }),
    (data) => {
      const tenancy = data?.tenancy;

      if (!tenancy || !tenancy.startDate) {
        throw new ApolloError({
          errorMessage: "Expected tenancy data is not defined"
        });
      }

      return parseDepositData(tenancy);
    }
  );
}
