import React, { ReactElement, useCallback, useMemo } from "react";
import { MultiSelectButton } from "@ailo/ui";
import {
  AwaitingApprovalPropertyOnboardingListPortfolioFilterData,
  PortfolioFilter
} from "./hooks";
import styled from "styled-components/native";
import { View } from "react-native";
import { useGetMigratingManagementPortfolioNames } from "../hooks";
import { MigratingManagementStatus } from "local/graphql";

const UNASSIGNED_OPTION: PortfolioFilterOption = {
  label: "Unassigned",
  value: "unassigned",
  isUnassignedOption: true
};

interface PortfolioFilterOption {
  label: string;
  value: string;
  isUnassignedOption?: boolean;
}

interface Props {
  portfolioFilterData: AwaitingApprovalPropertyOnboardingListPortfolioFilterData;
}

export function AwaitingApprovalPropertyOnboardingListPortfolioFilter({
  portfolioFilterData: { portfolioFilter, setPortfolioFilter }
}: Props): ReactElement | null {
  const { portfolioNames, hasUnassigned, loading, error, refetch } =
    useGetMigratingManagementPortfolioNames({
      status: MigratingManagementStatus.Imported
    });

  const metaOptions = useMemo<PortfolioFilterOption[]>(() => {
    return hasUnassigned ? [UNASSIGNED_OPTION] : [];
  }, [hasUnassigned]);

  const portfolioOptions = useMemo<PortfolioFilterOption[]>(() => {
    return portfolioNames.map((p) => ({ label: p, value: p }));
  }, [portfolioNames]);

  const options = useMemo(() => {
    return [metaOptions, portfolioOptions].reduce((acc, options) => {
      if (options.length === 0) return acc;
      return [...acc, ...options];
    }, [] as PortfolioFilterOption[]);
  }, [metaOptions, portfolioOptions]);

  const optionGroups = useMemo(() => {
    return [{ label: "", options }];
  }, [options]);

  const selectedMetaOptions = useMemo(() => {
    return portfolioFilter?.includeUnassigned &&
      metaOptions.includes(UNASSIGNED_OPTION)
      ? [UNASSIGNED_OPTION]
      : [];
  }, [metaOptions, portfolioFilter?.includeUnassigned]);

  const selectedPortfolioOptions = useMemo(() => {
    return portfolioOptions.filter(
      (o) =>
        !o.isUnassignedOption &&
        portfolioFilter?.selectedPortfolioNames.includes(o.value)
    );
  }, [portfolioOptions, portfolioFilter?.selectedPortfolioNames]);

  const selectedOptions = useMemo(() => {
    return [...selectedMetaOptions, ...selectedPortfolioOptions];
  }, [selectedMetaOptions, selectedPortfolioOptions]);

  const setSelectedOptions = useCallback(
    (options: PortfolioFilterOption[]) => {
      if (options.length === 0) {
        setPortfolioFilter(undefined);
        return;
      }

      const newPortfolioFilter = options.reduce(
        (acc, o) => {
          if (o.isUnassignedOption) return { ...acc, includeUnassigned: true };
          return {
            ...acc,
            selectedPortfolioNames: [...acc.selectedPortfolioNames, o.value]
          };
        },
        {
          selectedPortfolioNames: [],
          includeUnassigned: false
        } as PortfolioFilter
      );

      setPortfolioFilter(newPortfolioFilter);
    },
    [setPortfolioFilter]
  );

  if (loading) {
    return (
      <FilterContainer>
        <MultiSelectButton.Loading
          entityType={"Portfolios"}
          horizontalAlign={"right"}
        />
      </FilterContainer>
    );
  }

  if (error) {
    return (
      <FilterContainer>
        <MultiSelectButton.Error
          entityType={"Portfolios"}
          horizontalAlign={"right"}
          onReload={refetch}
        />
      </FilterContainer>
    );
  }

  if (portfolioOptions.length === 0) return null;

  return (
    <FilterContainer>
      <MultiSelectButton
        optionGroups={optionGroups}
        entityType={"Portfolios"}
        value={selectedOptions}
        onChange={setSelectedOptions}
        horizontalAlign={"right"}
      />
    </FilterContainer>
  );
}

const FilterContainer = styled(View)`
  margin-left: 12px;
`;
