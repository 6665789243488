import React from "react";
import {
  ScrollView,
  ScrollViewProps,
  StyleProp,
  StyleSheet,
  ViewStyle
} from "react-native";

export function PageContent({
  stickyHeaderIndices,
  scrollViewRef,
  maxWidth,
  style,
  contentContainerStyle,
  testID = "PageContent",
  children
}: {
  scrollViewRef?: React.RefObject<ScrollView>;
  maxWidth?: 960;
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  testID?: string;
  children?: React.ReactNode;
} & Pick<ScrollViewProps, "stickyHeaderIndices">): React.ReactElement {
  return (
    <ScrollView
      ref={scrollViewRef}
      stickyHeaderIndices={stickyHeaderIndices}
      style={[styles.container, style]}
      contentContainerStyle={[
        styles.contentContainer,
        maxWidth
          ? {
              maxWidth: maxWidth + 2 * 60
            }
          : undefined,
        contentContainerStyle
      ]}
      testID={testID}
    >
      {children}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  contentContainer: {
    paddingTop: 32,
    paddingBottom: 60,
    paddingHorizontal: 60,
    alignSelf: "center",
    width: "100%",
    flexGrow: 1
  }
});
