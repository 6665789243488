import { useToastContext } from "@ailo/ui";
import { useCompleteAction, useUncompleteAction } from "local/domain/project";
import { ActionDetailsFragment, ActionStatus } from "local/graphql";
import React, { ReactElement, useMemo } from "react";
import {
  ActionStatusContext,
  ActionStatusContextValue
} from "./ActionStatusContext";

interface Props {
  projectId: string;
  action: ActionDetailsFragment;
  children: React.ReactNode;
}

export function ActionStatusContextProvider({
  projectId,
  action,
  children
}: Props): ReactElement {
  const toast = useToastContext();
  const [completeAction] = useCompleteAction({
    projectId,
    onCompleted: () => {
      toast.show({
        type: "success",
        message: "Action done"
      });
    },
    onError: () => {
      toast.show({
        type: "error",
        message: "Unable to mark as done. Please try again."
      });
    }
  });

  const [uncompleteAction] = useUncompleteAction({
    projectId,
    onCompleted: () => {
      toast.show({
        type: "success",
        message: "Action incomplete"
      });
    },
    onError: () => {
      toast.show({
        type: "error",
        message: "Unable to mark as active. Please try again."
      });
    }
  });
  const contextValue = useMemo<ActionStatusContextValue>(() => {
    const handleActionStatusChange: ActionStatusContextValue["handleActionStatusChange"] =
      function () {
        action.status === ActionStatus.Active
          ? completeAction(action.id)
          : uncompleteAction(action.id);
      };

    return { handleActionStatusChange };
  }, [action.id, action.status, completeAction, uncompleteAction]);

  return (
    <ActionStatusContext.Provider value={contextValue}>
      {children}
    </ActionStatusContext.Provider>
  );
}
