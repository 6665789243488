import {
  BankAccountTypeName,
  BPayTypeName,
  isSupplierInternal
} from "@ailo/domains";
import { useActionEventContext } from "@ailo/services";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import {
  PaymentMethodType,
  useGetPaymentMethodByOwnerLazyQuery
} from "local/graphql";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

export enum PaymentFormState {
  NO_SELECTION,
  LOADING,
  INTERNAL,
  BPAY,
  BANK_ACCOUNT,
  NO_PAYMENT_METHOD
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useGetSupplierPaymentMethod({
  supplierReference
}: {
  supplierReference?: string;
}) {
  const { register, setValue, watch } = useFormContext<AddBillFormData>();
  const [isInternalSupplier, setIsInternalSupplier] = useState(false);

  const [
    getPaymentMethodByOwner,
    { data: paymentMethodsData, loading: paymentMethodsLoading }
  ] = useGetPaymentMethodByOwnerLazyQuery({
    fetchPolicy: "network-only"
  });

  const actionEventContext = useActionEventContext();
  useEffect(() => {
    return actionEventContext.subscribe((event) => {
      if (
        event.type === "PaymentMethodCreated" &&
        !isEmpty(supplierReference) &&
        supplierReference
      ) {
        getPaymentMethodByOwner({
          variables: {
            owner: supplierReference
          }
        });
      }
    });
  }, [actionEventContext, getPaymentMethodByOwner, supplierReference]);

  useEffect(() => {
    setIsInternalSupplier(isSupplierInternal(supplierReference));
    if (!isEmpty(supplierReference) && supplierReference) {
      getPaymentMethodByOwner({
        variables: {
          owner: supplierReference
        }
      });
    }
  }, [supplierReference, getPaymentMethodByOwner, setIsInternalSupplier]);

  const payment = watch("payment");

  useEffect(() => {
    register(
      { name: "payment.type" },
      {
        required: !isInternalSupplier ? "Payment type missing" : false,
        validate: (paymentType): boolean | string =>
          isInternalSupplier ||
          paymentType === BPayTypeName ||
          paymentType === BankAccountTypeName ||
          "Payment type missing"
      }
    );
  }, [register, isInternalSupplier, payment, supplierReference]);

  const paymentMethods = paymentMethodsData?.paymentMethodsByOwner?.items;
  const paymentMethod = paymentMethods?.[0];
  const paymentMethodType = paymentMethod?.__typename;

  useEffect(() => {
    if (!paymentMethodsLoading && paymentMethod?.id != null) {
      setValue("payment.id", paymentMethod.id, { shouldValidate: true });
    }
  }, [paymentMethodsLoading, paymentMethod, paymentMethodType, setValue]);

  const paymentFormState = paymentFormSwitcher({
    supplierReference,
    paymentMethodsLoading,
    isInternalSupplier,
    paymentMethodType: paymentMethodType as PaymentMethodType
  });

  return {
    paymentFormState,
    paymentMethod
  };
}

function paymentFormSwitcher({
  supplierReference,
  paymentMethodsLoading,
  isInternalSupplier,
  paymentMethodType
}: {
  supplierReference?: string;
  paymentMethodsLoading?: boolean;
  isInternalSupplier?: boolean;
  paymentMethodType?: PaymentMethodType;
}): PaymentFormState {
  if (isEmpty(supplierReference) || !supplierReference)
    return PaymentFormState.NO_SELECTION;
  if (paymentMethodsLoading) return PaymentFormState.LOADING;
  if (isInternalSupplier) return PaymentFormState.INTERNAL;
  if (paymentMethodType === BPayTypeName) return PaymentFormState.BPAY;
  if (paymentMethodType === BankAccountTypeName)
    return PaymentFormState.BANK_ACCOUNT;
  else return PaymentFormState.NO_PAYMENT_METHOD;
}
