import { useAnalytics } from "@ailo/services";
import { useCallback } from "react";

export function usePropertyKeyUpdatedAnalytics(keyId: string): {
  trackPropertyKeyUpdated: () => void;
} {
  const analytics = useAnalytics();

  const trackPropertyKeyUpdated = useCallback(() => {
    analytics.track("Property Key Updated", {
      keyId
    });
  }, [analytics, keyId]);

  return {
    trackPropertyKeyUpdated
  };
}
