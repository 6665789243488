import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import {
  TenancyFragment,
  useCompleteAssignTenancyActionMutation
} from "local/graphql";
import { useCallback } from "react";

export interface CompleteAssignTenancyActionInput {
  tenancy: TenancyFragment;
  actionId: string;
  inspectionAppointmentAilorn?: string;
}

export function useCompleteAssignTenancyAction(): {
  completeAssignTenancyAction: (
    input: CompleteAssignTenancyActionInput
  ) => Promise<void>;
} {
  const toast = useToastContext();

  const [completeAssignTenancyActionMutation] =
    useCompleteAssignTenancyActionMutation();

  const completeAssignTenancyAction = useCallback(
    async ({
      tenancy,
      actionId,
      inspectionAppointmentAilorn
    }: CompleteAssignTenancyActionInput): Promise<void> => {
      try {
        const result = await completeAssignTenancyActionMutation({
          variables: {
            input: {
              id: actionId,
              tenancyAilorn: tenancy.ailoRN.toString(),
              inspectionAppointmentAilorn
            }
          }
        });

        throwIfMutationFailed(result, {
          dataKey: "completeAssignTenancyAction"
        });
      } catch (error) {
        AiloSentry.captureException(error);
        toast.showFormSubmitError();
      }
    },
    [completeAssignTenancyActionMutation, toast]
  );

  return { completeAssignTenancyAction };
}
