import React from "react";
import { SFC, Text } from "@ailo/primitives";
import styled from "styled-components/native";

export const TitleHeader: SFC = ({ style, children }) => {
  return <StyledText style={style}>{children}</StyledText>;
};

const StyledText = styled(Text.DisplayS)`
  padding: 36px 24px 24px;
`;
