import { useApolloClient } from "@apollo/client";
import {
  GetReiFormRemoteSigningPdfDocument,
  GetReiFormRemoteSigningPdfQuery
} from "local/graphql";

export function useGetReiFormRemoteSigningPdf(
  id: string
): () => Promise<GetReiFormRemoteSigningPdfQuery["form"]> {
  const { query } = useApolloClient();

  return async () => {
    const { data } = await query<GetReiFormRemoteSigningPdfQuery>({
      query: GetReiFormRemoteSigningPdfDocument,
      variables: {
        id
      },
      fetchPolicy: "network-only"
    });

    if (!data?.form) {
      throw new Error("Could not download form");
    }

    const { form } = data;

    return form;
  };
}
