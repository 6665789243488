import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { useCallback } from "react";
import { useVoidTenancyMutation, VoidTenancyMutation } from "local/graphql";

export function useVoidTenancy({
  tenancyId,
  onCompleted,
  onError
}: {
  tenancyId: string;
  onCompleted?: () => void;
  onError?: () => void;
}): [() => void, MutationResult<VoidTenancyMutation>] {
  const [voidTenancyMutation, result] = useVoidTenancyMutation();

  const voidTenancy = useCallback(async (): Promise<void> => {
    let result: ExecutionResult<VoidTenancyMutation>;

    try {
      result = await voidTenancyMutation({
        variables: {
          tenancyId
        }
      });
      throwIfMutationFailed(result, { dataKey: "voidTenancy" });
    } catch (error) {
      AiloSentry.captureException(error);
      onError?.();
      return;
    }

    onCompleted?.();
  }, [onCompleted, voidTenancyMutation, tenancyId, onError]);

  return [voidTenancy, result];
}
