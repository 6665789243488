import React, { ReactElement, useCallback, useMemo } from "react";
import { PropertyOnboardingListScreen } from "./PropertyOnboardingScreen";
import { ContentWithSidebar } from "@ailo/ui";
import { Screens, useNavigation, useRoute } from "local/common";
import { MigratingManagementSidebarContentContainer } from "./MigratingManagementSidebarContent";

export function OnboardingTabScreen(): ReactElement {
  const navigation = useNavigation();
  const route = useRoute<Screens.OnboardingTab>();
  const migratingManagementId = route.params?.migratingManagementId;

  const closeSidebar = useCallback((): void => {
    navigation.navigate(Screens.OnboardingTab, {
      tab: route.params?.tab
    });
  }, [navigation, route.params?.tab]);

  const sidebarContent = useMemo(() => {
    return migratingManagementId ? (
      <MigratingManagementSidebarContentContainer
        migratingManagementId={migratingManagementId}
        closeSidebar={closeSidebar}
      />
    ) : null;
  }, [migratingManagementId, closeSidebar]);

  return (
    <ContentWithSidebar
      open={Boolean(sidebarContent)}
      sidebarContent={sidebarContent}
      onDismiss={closeSidebar}
      animateOnMount={false}
    >
      <PropertyOnboardingListScreen />
    </ContentWithSidebar>
  );
}
