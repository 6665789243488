import { SidebarContent } from "@ailo/ui";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { CreateProjectButton } from "./CreateProjectButton";

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  onCreateProjectPress?: () => void;
  style?: StyleProp<ViewStyle>;
};

export function ProjectsListSidebarContainer({
  children,
  onClose,
  onCreateProjectPress,
  style
}: Props): React.ReactElement {
  return (
    <SidebarContent
      title={"Projects"}
      titleRightButtons={
        onCreateProjectPress ? (
          <CreateProjectButton onPress={onCreateProjectPress} />
        ) : undefined
      }
      onClose={onClose}
      style={[{ paddingHorizontal: 0 }, style]}
      titleStyle={{ marginHorizontal: 32, marginBottom: 32 }}
    >
      {children}
    </SidebarContent>
  );
}
