import { ConfirmModal, FormField, SelectInput } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller, useForm } from "react-hook-form";
import { DateDuration } from "@ailo/date";
import { useSetInspectionFrequency } from "./useSetInspectionFrequency";
import { View } from "react-native";
import { ManagementAgreementFragment } from "local/graphql";

interface FormData {
  inspectionFrequency: string;
}

interface Props {
  managementAgreementId: string;
  onCancel(): void;
  onInspectionFrequencyUpdated?(
    managementAgreement: ManagementAgreementFragment
  ): void;
  defaultValues?: FormData;
}

export function InspectionFrequencyModalForm({
  managementAgreementId,
  onCancel,
  onInspectionFrequencyUpdated,
  defaultValues
}: Props): ReactElement {
  const form = useForm<FormData>({
    defaultValues,
    mode: "onBlur"
  });
  const [setInspectionFrequency] = useSetInspectionFrequency({
    onCompleted: onInspectionFrequencyUpdated
  });

  const submit = form.handleSubmit((data: FormData) => {
    setInspectionFrequency({ ...data, managementAgreementId });
  });

  return (
    <ConfirmModal
      title={"Set inspection frequency"}
      onConfirm={submit}
      onCancel={onCancel}
      showFooterBorder={true}
      borderRadius={12}
    >
      <View style={{ paddingBottom: 44 }}>
        <Controller
          control={form.control}
          name={"inspectionFrequency"}
          render={({ value, onChange, onBlur }) => {
            return (
              <FormField label={"Routine inspection frequency"}>
                <SelectInput
                  {...{ value, onBlur }}
                  options={monthOptions}
                  onChange={(option) => {
                    onChange(option.value);
                  }}
                  useTextInputHeight
                />
              </FormField>
            );
          }}
        />
      </View>
    </ConfirmModal>
  );
}

const monthOptions = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((months) => {
  const duration = DateDuration.from({ months });

  return {
    label: `Every ${duration.format()}`,
    value: duration.toISOString()
  };
});
