import { ParamListBase } from "@react-navigation/native";
import { Screens } from "local/common";

export type WalletTabLabel = "Transfer summaries" | "Transactions";

export interface WalletTabStackNavigatorParamList extends ParamListBase {
  [Screens.WalletTab]: {
    transfer?: "1";
    legalEntityId?: string;
    tab?: WalletTabLabel;
  };
}
