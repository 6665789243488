import { LocalDate } from "@ailo/date";

export type TenancyWithEndDate = {
  endDate?: string | null;
};

export function sortTenancyByEndDateDesc<T extends TenancyWithEndDate>(
  tenancies: T[],
  nullFirst: boolean = true
): T[] {
  return [...tenancies].sort((a, b) => {
    if (!a.endDate || !b.endDate) return nullFirst ? -1 : 1;

    return LocalDate.from(a.endDate).isAfter(LocalDate.from(b.endDate))
      ? -1
      : 1;
  });
}
