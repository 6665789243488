import { GridSortOrder, Money } from "@ailo/ui";
import { LocalDate } from "@ailo/date";

type DisbursementCategory = "investor" | "supplier";

function useGetDisbursements({
  filter,
  sortOrder = { columnKey: "names", direction: "DESC" }
}: {
  filter?: DisbursementCategory;
  sortOrder?: GridSortOrder;
}): {
  data: {
    disbursements: DisbursementRow[];
    pageInfo: {
      total: number;
    };
  };
  loading: boolean;
  error: boolean;
  refetch: () => void;
} {
  const disbursements: DisbursementRow[] = [
    {
      key: "row1",
      id: "1",
      payouts: Money.fromDollars(300),
      withheld: Money.zero(),
      nextDisbursement: LocalDate.today(),
      dueDate: LocalDate.today().add(14, "days"),
      schedule: "fortnightly",
      folioId: "OWN12345",
      category: "investor",
      names: ["John Smith", "Jane Smith"]
    },
    {
      key: "row2",
      id: "2",
      payouts: Money.fromDollars(200),
      withheld: Money.fromDollars(50),
      nextDisbursement: LocalDate.tomorrow(),
      dueDate: LocalDate.today(),
      schedule: "fortnightly",
      folioId: "SUPP55555",
      category: "supplier",
      names: ["ACE Electrical"]
    },
    {
      key: "row3",
      id: "3",
      payouts: Money.zero(),
      withheld: Money.zero(),
      nextDisbursement: LocalDate.today().add(7, "days"),
      dueDate: LocalDate.today().add(14, "days"),
      schedule: "monthly",
      folioId: "OWN87654",
      category: "investor",
      names: ["Greg Poole"]
    }
  ];

  const refetch = (): void => {
    return;
  };

  if (sortOrder.columnKey === "names") {
    if (sortOrder.direction === "ASC") {
      disbursements.sort((a, b) =>
        a.names.join().localeCompare(b.names.join())
      );
    } else {
      disbursements.sort((a, b) =>
        b.names.join().localeCompare(a.names.join())
      );
    }
  }

  const filteredDisbursements = filter
    ? disbursements.filter((disbursement) => disbursement.category === filter)
    : disbursements;

  return {
    data: {
      disbursements: filteredDisbursements,
      pageInfo: {
        total: filteredDisbursements.length
      }
    },
    loading: false,
    error: false,
    refetch
  };
}

interface DisbursementRow {
  id: string;
  key: string;
  payouts: Money;
  withheld: Money;
  dueDate: LocalDate;
  nextDisbursement: LocalDate;
  folioId: string;
  schedule: string;
  category: DisbursementCategory;
  names: string[];
}

export { DisbursementRow, useGetDisbursements, DisbursementCategory };
