import { QueryResult } from "@apollo/client";
import {
  GetTransferStatementDataQuery,
  GetTransferStatementDataQueryVariables,
  useGetTransferStatementDataQuery
} from "local/graphql";
import { useEffect } from "react";

interface UseGetTransferStatementDataProps {
  statementQueryVariables: Pick<
    GetTransferStatementDataQueryVariables,
    "rangeEndBusinessTxId" | "rangeStartBusinessTxId" | "walletOwnerReference"
  >;
}

// Kept relatively small to avoid fetching too much data in one go from the ledger service
const PAGE_SIZE = 100;

export function useGetTransferStatementData({
  statementQueryVariables
}: UseGetTransferStatementDataProps): {
  data: QueryResult<GetTransferStatementDataQuery>["data"];
  loading: boolean;
} {
  const { data, fetchMore } = useGetTransferStatementDataQuery({
    variables: {
      ...statementQueryVariables,
      paginationParams: { pageSize: PAGE_SIZE }
    },
    fetchPolicy: "cache-first"
  });

  const page =
    data?.transferSummaryStatementData
      ?.transactionLineItemsByStatementTransactionDate;

  useEffect(() => {
    if (!page || !page?.pageInfo.hasNext || !fetchMore) {
      return;
    }

    fetchMore({
      variables: {
        ...statementQueryVariables,
        paginationParams: {
          pageSize: PAGE_SIZE,
          cursor: page?.pageInfo.nextCursor
        }
      }
    });
  }, [page, fetchMore, statementQueryVariables]);

  return {
    data,
    loading: (page?.pageInfo.hasNext ?? true) === true
  };
}
