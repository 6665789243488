import React from "react";
import { Colors, opacify, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";
import { BaseActionActivity } from "../../BaseActionActivity";
import { Alert, Badge, Separator } from "@ailo/ui";
import { ClaimedFilesCount, InspectionFragment } from "local/graphql";
import { ButtonWithTooltipProps } from "../../ButtonWithTooltip";
import { InspectionThumbnails } from "./InspectionThumbnails";
import { InspectionDisplayNameFromType } from "./inspectionDisplayNameFromType";

export function InspectionViewUI({
  inspection,
  inspectionFilesCount,
  description,
  button,
  footer
}: {
  inspection: InspectionFragment;
  inspectionFilesCount: ClaimedFilesCount;
  description: string | React.ReactElement;
  button: ButtonWithTooltipProps | React.ReactNode;
  footer: string | React.ReactNode;
}): React.ReactElement {
  const inspectionReportName = InspectionDisplayNameFromType[inspection.type];
  const { validFiles, totalFiles } = inspectionFilesCount;
  const { latestReportProgress } = inspection;
  return (
    <BaseActionActivity
      description={description}
      button={button}
      footer={footer}
    >
      {latestReportProgress?.failedAt && (
        <Alert type={"warning"} style={{ marginBottom: 16 }}>
          {"Something went wrong. Please try again."}
        </Alert>
      )}
      <InspectionCard>
        <InspectionCardContentContainer
          style={{
            justifyContent: "space-between"
          }}
        >
          <Text.BodyS weight={"medium"}>{inspectionReportName}</Text.BodyS>
          {validFiles === totalFiles ? (
            <Badge type={"success"}>{"Sync complete"}</Badge>
          ) : (
            <Badge type={"attention"}>{"Waiting for sync"}</Badge>
          )}
        </InspectionCardContentContainer>
        <Separator
          style={{
            margin: 0
          }}
        />
        <InspectionCardContentContainer>
          <InspectionThumbnails inspection={inspection} />
          <Text.BodyS
            style={{
              marginLeft: 16
            }}
            color={Colors.TEXT.DARK.SECONDARY}
          >
            {`${inspection.areas.length} ${
              inspection.areas.length > 1 ? "areas" : "area"
            }  ·  ${totalFiles} ${totalFiles > 1 ? "photos" : "photo"}`}
          </Text.BodyS>
        </InspectionCardContentContainer>
      </InspectionCard>
    </BaseActionActivity>
  );
}

const InspectionCard = styled(View)`
  border-radius: 8px;
  border: 1px solid ${opacify(Colors.SPACE_BLACK, 0.1)};
  padding-left: 16px;
  margin-bottom: 24px;
`;

const InspectionCardContentContainer = styled(View)`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
