import { useGetReiFormStatus } from "local/domain/project/useGetReiFormStatus";
import { useGetReiFormSigningStatus } from "local/domain/project/useGetReiFormSigningStatus";
import {
  ReiFormInfo,
  ReiFormRemoteSigningStatus,
  ReiTokenBaseFragment
} from "local/graphql";
import { GraphQLError } from "graphql";
import { ApolloError } from "@apollo/client";

type ReiFormStatuses = {
  data: {
    reiTokens?: ReiTokenBaseFragment[];
    reiForm: {
      reiFormInfo?: ReiFormInfo;
      remoteSigningStatus?: ReiFormRemoteSigningStatus;
    };
  };
  loading: boolean;
  error?: ApolloError;
  refetch: () => void;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
  startPollingReiFormInfo: (pollInterval: number) => void;
  stopPollingReiFormInfo: () => void;
};

export function useGetFormStatuses(formId: string): ReiFormStatuses {
  const {
    data: reiFormInfo,
    error: errorGettingReiFormInfo,
    refetch: refetchReiFormInfo,
    loading: loadingReiFormInfo,
    startPolling: startPollingReiFormInfo,
    stopPolling: stopPollingReiFormInfo
  } = useGetReiFormStatus(formId);

  const {
    data: remoteSigningStatus,
    error: errorGettingRemoteSigningStatus,
    loading: loadingRemoteSigningStatus,
    refetch: refetchRemoteSigningStatus,
    startPolling: pollRemoteSigningStatus,
    stopPolling: stopPollingRemoteSigningStatus
  } = useGetReiFormSigningStatus(formId);

  return {
    data: {
      reiForm: {
        reiFormInfo,
        remoteSigningStatus
      }
    },
    loading: loadingReiFormInfo || loadingRemoteSigningStatus,
    startPolling: pollRemoteSigningStatus,
    stopPolling: stopPollingRemoteSigningStatus,
    startPollingReiFormInfo,
    stopPollingReiFormInfo,
    error: (() => {
      let graphQLErrors: GraphQLError[] = [];
      if (errorGettingReiFormInfo || !reiFormInfo) {
        graphQLErrors = graphQLErrors.concat(
          errorGettingReiFormInfo?.graphQLErrors ?? [
            new GraphQLError("ReiFormInfo is empty")
          ]
        );
      }
      if (errorGettingRemoteSigningStatus || !remoteSigningStatus) {
        graphQLErrors = graphQLErrors.concat(
          errorGettingReiFormInfo?.graphQLErrors ?? [
            new GraphQLError("RemoteSigningStatus is empty")
          ]
        );
      }
      if (graphQLErrors.length > 0) {
        return new ApolloError({
          graphQLErrors
        });
      }
    })(),
    refetch: () => {
      if (errorGettingReiFormInfo || !reiFormInfo) {
        refetchReiFormInfo();
      }
      if (errorGettingRemoteSigningStatus || !remoteSigningStatus) {
        refetchRemoteSigningStatus();
      }
    }
  };
}
