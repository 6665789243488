import { OwnershipListItemProps } from "./InvestorOwnershipsSection/OwnershipListItem";

export function getPropertyBadgeData(
  managements?: {
    id: string;
    property: {
      id: string;
      address: {
        unitStreetNumber: string;
        streetName: string;
      };
    };
  }[]
): {
  address: string;
  path: string;
}[] {
  if (!managements) return [];

  return managements.map((management) => ({
    address:
      management.property.address.unitStreetNumber +
      " " +
      management.property.address.streetName,
    path: `/properties/${management.id}`
  }));
}

export function getOwnershipData(
  ownerships?: {
    sharesOwned: number;
    totalShares: number;
    management: {
      id: string;
      property: {
        id: string;
        address: {
          unitStreetNumber: string;
          streetName: string;
          suburb?: string | null;
        };
      };
    };
  }[]
): OwnershipListItemProps["ownership"][] {
  if (!ownerships) return [];

  return ownerships.map((ownership) => ({
    sharesPercentage: (
      Math.round((ownership.sharesOwned / ownership.totalShares) * 10000) / 100
    ).toString(),
    address: {
      unitStreetNumber: ownership.management.property.address.unitStreetNumber,
      streetName: ownership.management.property.address.streetName,
      suburb: ownership.management.property.address.suburb ?? ""
    },
    path: `/properties/${ownership.management.id}`
  }));
}
