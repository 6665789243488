import { LocalDate } from "@ailo/date";
import { NetworkStatus, QueryHookOptions, QueryResult } from "@apollo/client";
import moment from "moment";

export interface ReportTeamContextOption {
  excludeUnassignedFromTeamId?: boolean;
}

/** parses a formatted date values for sort. Null values are epoch */
export const dateForSort = (value?: string | null): Date =>
  LocalDate.parse(value)?.toDate() ?? new Date(0);

/** parses a formatted float value for sorting. Null values are -infinity */
export const floatForSort = (value?: string | null): number =>
  safeMap(value, parseFormattedToFloat) ?? Number.NEGATIVE_INFINITY;

export const parseFormattedToFloat = (value: string): number =>
  Number.parseFloat(value.replace(/[^0-9.-]/g, ""));

export const safeMap = <T, R>(
  value: T,
  map: (value: NonNullable<T>) => R
): R | null => (value == null ? null : map(value as NonNullable<T>));

export type PageQueryResult<TData, TVariables> = Omit<
  QueryResult<TData, TVariables>,
  "refetch" | "networkStatus"
> & {
  refetch: () => void;
};

/**
 * a light wrapper for apollo queries to make it easier to use with the ReportPage
 */
export const useReportQuery = <TData, TVariables>(
  query: (
    options: QueryHookOptions<TData, TVariables>
  ) => QueryResult<TData, TVariables>,
  variables: TVariables
): PageQueryResult<TData, TVariables> => {
  const res = query({ variables, notifyOnNetworkStatusChange: true });
  return {
    ...res,
    loading: res.loading ?? res.networkStatus == NetworkStatus.refetch,
    refetch: () => res.refetch(variables)
  };
};

/**
 *
 * @param disbursementPeriod the formatted label for a disbursement
 * @returns start/end for disbursements in YYYY-MM-DD format
 */
export function disbursementPeriodToDate(
  disbursementPeriod: string | undefined
): {
  disbursementPeriodEndDate?: string;
  disbursementPeriodStartDate?: string;
} {
  if (!disbursementPeriod) {
    return {
      disbursementPeriodStartDate: undefined,
      disbursementPeriodEndDate: undefined
    };
  }

  const reg = /\d\d\s\w{3}\s\d{4}|Now/g;
  const matches = disbursementPeriod.match(reg);
  const start = matches?.[0];
  const end = matches?.[1];

  const localDateFromDisbursement = (date?: string): string | undefined =>
    (date
      ? date == "Now"
        ? LocalDate.today()
        : LocalDate.fromMoment(moment(date, "DD MMM YYYY"))
      : undefined
    )?.format("YYYY-MM-DD");

  return {
    disbursementPeriodStartDate: localDateFromDisbursement(start),
    disbursementPeriodEndDate: localDateFromDisbursement(end)
  };
}
