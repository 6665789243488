import { Alert } from "@ailo/ui";
import React, { ReactElement } from "react";

export function OwnerNoEmailWarning(): ReactElement {
  return (
    <Alert
      type={"warning"}
      message={
        "Without an email address this user will not be invited to use Ailo. Add an email to enable this owner to log in, view transactions and chat."
      }
    />
  );
}
