import { useMemo } from "react";
import { RequestCursor, usePaginationRequest } from "./usePaginationRequest";
import { PropertyListQueryData } from "../BasePropertyList";
import { GridSortOrder } from "@ailo/ui";
import { ManagementSortField, SortDirection } from "local/graphql";
import { PropertyListFilterStateType } from "../filters/usePropertyListFilterState";
import { PropertyListSelectedTeams } from "./usePropertyListSelectedTeams";

// TODO: Consider rename and/or replace this hook. Not sure if we need this inter-mediate hook.
// Looks like it's only there to safe re-peating code without managing any concrete states
export function useGetPropertyListQueryReturn({
  sendRequest,
  pageInfo,
  managements,
  loading,
  called,
  sortStateToApiParam,
  filterVariables
}: {
  sendRequest: (
    selectedTeams: PropertyListSelectedTeams,
    cursor?: RequestCursor
  ) => void;
  pageInfo?: {
    nextCursor?: string | null;
    hasNext: boolean;
    previousCursor?: string | null;
    hasPrevious: boolean;
    total: number;
  };
  managements?: {
    id: string;
  }[];
  loading: boolean;
  called: boolean;
  sortStateToApiParam?: (s: GridSortOrder) => {
    field: ManagementSortField;
    direction: SortDirection;
  };
  filterVariables?: PropertyListFilterStateType;
}): PropertyListQueryData {
  const currentCursor = useMemo(
    () => ({
      nextPage: pageInfo?.nextCursor || null,
      previousPage: pageInfo?.previousCursor || null
    }),
    [pageInfo]
  );

  const { requestNewPage, requestFromBeginning, currentPage } =
    usePaginationRequest(
      currentCursor,
      sendRequest,
      sortStateToApiParam,
      filterVariables
    );

  return {
    loading: loading || !called,
    data: useMemo(() => {
      if (managements && pageInfo) {
        return {
          error: false,
          managements: managements.map((mgmt) => ({
            key: mgmt.id,
            managementId: mgmt.id
          })),
          pageInfo: { ...pageInfo, pageNo: currentPage }
        };
      }

      return {
        error: called,
        managements: [],
        pageInfo: { hasNext: false, hasPrevious: false, total: 0, pageNo: 0 }
      };
    }, [called, currentPage, managements, pageInfo]),
    requestNewPage,
    requestFromBeginning
  };
}
