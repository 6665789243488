import React from "react";
import { View } from "react-native";
import { CloseIcon, DownloadIcon, Link } from "@ailo/primitives";
import styled from "styled-components/native";

interface Props {
  onDownloadPress?: () => void;
  onClosePress: () => void;
}

export const ActionButtonBar: React.FC<Props> = ({
  onClosePress,
  onDownloadPress
}) => {
  return (
    <Container>
      <Link onPress={onClosePress} style={{ marginLeft: 24 }}>
        <CloseIcon />
      </Link>
      {onDownloadPress && (
        <Link onPress={onDownloadPress} style={{ marginRight: 24 }}>
          <DownloadIcon />
        </Link>
      )}
    </Container>
  );
};

const Container = styled(View)`
  height: 48px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
