import { ClaimTenancyData } from "local/domain/bond";
import React, { ReactElement } from "react";
import { BillClaimInputList } from "./BillClaimInputList";
import { Separator } from "@ailo/ui";
import { RentClaimInput } from "./RentClaimInput";
import { ClaimedMoreThanBondError } from "./ClaimedMoreThanBondError";
import { Step } from "local/common";
import { ClaimBondFormConfiguration } from "local/tabs";

export function ClaimLiabilitiesStep({
  data,
  formConfiguration,
  visible
}: {
  data: ClaimTenancyData;
  formConfiguration: ClaimBondFormConfiguration;
  visible: boolean;
}): ReactElement {
  return (
    <Step visible={visible}>
      {formConfiguration.claimLiabilitiesStep.infoHeader}
      <ClaimedMoreThanBondError data={data} />

      <BillClaimInputList
        data={data}
        style={{ paddingBottom: 32, marginBottom: 12 }}
      />

      <Separator
        style={{ marginLeft: 0, marginRight: -60, marginBottom: 32 }}
      />
      <RentClaimInput data={data} />
    </Step>
  );
}
