import React, { ReactElement, useCallback } from "react";
import { ConfirmModal, useToastContext } from "@ailo/ui";
import { Text } from "@ailo/primitives";
import { useCancelDeposit } from "../useCancelDeposit";

export function CancelDepositConfirmationModal({
  tenancyDepositAilorn,
  managementId,
  visible,
  onSuccess,
  onCancel,
  onError
}: {
  tenancyDepositAilorn: string;
  managementId: string;
  visible: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  onError: () => void;
}): ReactElement {
  const Toast = useToastContext();

  const submitSuccess = useCallback(() => {
    Toast.show({
      type: "success",
      message: "Initial payment removed"
    });
    onSuccess();
  }, [onSuccess, Toast]);

  const [cancelDeposit] = useCancelDeposit({
    onSuccess: submitSuccess,
    onError
  });

  const submit = useCallback(
    (): void =>
      cancelDeposit({
        tenancyDepositAilorn,
        managementId
      }),
    [cancelDeposit, tenancyDepositAilorn, managementId]
  );

  return (
    <ConfirmModal
      visible={visible}
      title={"Remove initial payment request?"}
      onConfirm={submit}
      onCancel={onCancel}
      confirmLabel={"Remove"}
      destructive
    >
      <Text.BodyM weight={"book"}>
        {
          "This initial payment request will be cancelled. The invited renters won’t be able to make the payment via Ailo."
        }
      </Text.BodyM>
    </ConfirmModal>
  );
}
