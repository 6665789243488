import { BankAccountMethod, BPayMethod, useHasFeature } from "@ailo/domains";
import { SFC, Text } from "@ailo/primitives";
import { Alert, Button, FormField, NoInputFormField } from "@ailo/ui";
import {
  PaymentFormState,
  useGetSupplierPaymentMethod
} from "local/domain/bill/AddBillForm";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import { PlatformFeatureId } from "local/graphql";
import React, { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { View } from "react-native";
import { BankAccountInput, BPayInput, WalletInput } from "./components";
type PaymentTypeInputProps = {
  onCreatePaymentMethodClick: () => void;
};

const PaymentTypeInput: SFC<PaymentTypeInputProps> = ({
  style,
  onCreatePaymentMethodClick
}) => {
  const { watch } = useFormContext<AddBillFormData>();
  const hasCreateSupplierFeature = useHasFeature(
    PlatformFeatureId.AgencyAppSupplierCreate
  );

  const selectedSupplier = watch("supplierReference");
  const supplierReference = selectedSupplier?.value;

  const { paymentFormState, paymentMethod } = useGetSupplierPaymentMethod({
    supplierReference
  });
  const isInternalSupplier = paymentFormState === PaymentFormState.INTERNAL;

  const ref = useRef<View>(null);

  return (
    <Controller
      name={"payment.id"}
      defaultValue={null}
      rules={{
        required: !isInternalSupplier ? "Payment method required" : false,
        validate: () =>
          ![
            PaymentFormState.LOADING,
            PaymentFormState.NO_SELECTION,
            PaymentFormState.NO_PAYMENT_METHOD
          ].includes(paymentFormState) || "Payment method required"
      }}
      onFocus={() => ref.current?.focus()}
      render={() => (
        <View
          style={style}
          accessible={true}
          testID={"PaymentTypeFormGroup"}
          ref={ref}
        >
          <Text.BodyL weight={"medium"} style={{ marginBottom: 32 }}>
            {isInternalSupplier ? "Paid To" : "Supplier Payment Method"}
          </Text.BodyL>
          {paymentFormState === PaymentFormState.NO_SELECTION ? (
            <NoInputFormField
              message={"Select a supplier to see their payment method"}
            />
          ) : paymentFormState === PaymentFormState.LOADING ? (
            <NoInputFormField loading={true} />
          ) : paymentFormState === PaymentFormState.NO_PAYMENT_METHOD ? (
            <FormField>
              <Alert
                type={"error"}
                message={"There is no payment method for this supplier"}
              />
              {hasCreateSupplierFeature && (
                <Button.Secondary
                  onPress={() => onCreatePaymentMethodClick()}
                  style={{ marginTop: 24 }}
                >
                  {"Add Payment Method"}
                </Button.Secondary>
              )}
            </FormField>
          ) : paymentFormState === PaymentFormState.INTERNAL ? (
            <WalletInput supplierReference={supplierReference!} />
          ) : (
            paymentFormState === PaymentFormState.BANK_ACCOUNT && (
              <BankAccountInput
                bankAccountMethod={paymentMethod as BankAccountMethod}
              />
            )
          )}
          <BPayInput
            bpayMethod={paymentMethod as BPayMethod}
            shouldRender={paymentFormState === PaymentFormState.BPAY}
          />
        </View>
      )}
    />
  );
};

export { PaymentTypeInput };
