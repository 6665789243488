import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { Money } from "@ailo/ui";
import {
  GetAllTenanciesDepositsQuery,
  GetAllTenanciesDepositsQueryVariables,
  useGetAllTenanciesDepositsQuery,
  OccupancyStatus
} from "local/graphql";
import { notEmpty } from "local/common";
import {
  DepositStatus,
  mapDepositStatus
} from "local/domain/propertyManagement";

export type DepositData = {
  createdAt: string;
  tenancyDepositAilorn: string;
  status: string;
  displayStatus: DepositStatus;
  amount: Money;
  tenancyStartDate: string;
  paidAt?: string;
  expectedClearedAt?: string;
  clearedAt?: string;
};

type GqlTenancy = NonNullable<
  GetAllTenanciesDepositsQuery["management"]
>["nextTenancy"];

type ReturnType = {
  ingoingTenancyDeposit?: DepositData;
  currentTenancyDeposit?: DepositData;
  formerTenancyDeposits: DepositData[];
};

function isActiveTenancy(occupanyStatus?: OccupancyStatus | null): boolean {
  if (!occupanyStatus) return false;
  return [OccupancyStatus.Occupied, OccupancyStatus.Vacating].includes(
    occupanyStatus
  );
}

export function useGetAllTenanciesDeposits(
  managementId: string
): MappedQueryResult<ReturnType, GetAllTenanciesDepositsQueryVariables> {
  const result = useGetAllTenanciesDepositsQuery({
    variables: { managementId }
  });

  return useMappedQueryResult(result, (data) => {
    return {
      ingoingTenancyDeposit: parseDepositData(data.management?.nextTenancy),
      currentTenancyDeposit: isActiveTenancy(data.management?.occupancyStatus)
        ? parseDepositData(data.management?.mostRecentTenancy)
        : undefined,
      formerTenancyDeposits: parseFormerTenancyDeposits(data.management)
    };
  });
}

export function parseDepositData(
  tenancy?: GqlTenancy
): DepositData | undefined {
  if (!tenancy?.deposit) return undefined;
  const deposit = tenancy.deposit;
  const paymentEntry = getFirstPaymentEntry(deposit.liability?.entries?.items);

  return {
    createdAt: deposit.createdAt,
    tenancyDepositAilorn: deposit.ailoRN,
    status: deposit.status,
    displayStatus: mapDepositStatus(deposit.status),
    amount: Money.fromCents(deposit.amount.cents),
    tenancyStartDate: tenancy.startDate!,
    paidAt: deposit.paidAt || undefined,
    expectedClearedAt:
      paymentEntry?.businessTransaction.expectedClearedAt || undefined,
    clearedAt: paymentEntry?.businessTransaction.clearedAt || undefined
  };
}

function parseFormerTenancyDeposits(
  management?: GetAllTenanciesDepositsQuery["management"]
): DepositData[] {
  const formerTenancies = management?.formerTenancies?.items || [];

  return formerTenancies
    .map((tenancy) => {
      return parseDepositData(tenancy);
    })
    .filter(notEmpty);
}

export function getFirstPaymentEntry(
  paymentEntryItems?: any
): PaymentLiabilityEntryItem | undefined {
  return ((paymentEntryItems || []) as PaymentLiabilityEntryItem[]).find(
    () => true
  );
}

type PaymentLiabilityEntryItem = {
  businessTransaction: {
    expectedClearedAt: string;
    clearedAt: string;
  };
};
