import React from "react";
import { GetManagementAgencyQuery } from "local/graphql";
import { Badge } from "@ailo/ui";

export function createManagementOrganisationSelectOption(
  queryData?: GetManagementAgencyQuery
): {
  value: string;
  label: string;
  RightComponent?: React.ReactNode;
} | null {
  const managementId = queryData?.management?.id;
  if (!managementId) return null;

  const agencyName = queryData?.management?.managingEntity?.organisation?.name;
  const agencyAiloRN = queryData?.management?.managingEntity?.ailoRN;
  if (!agencyName || !agencyAiloRN) return null;

  return {
    label: agencyName,
    value: agencyAiloRN,
    RightComponent: <Badge>{"Agency"}</Badge>
  };
}
