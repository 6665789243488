import { AiloSentry } from "@ailo/services";
import {
  InspectionAppointmentDetailsFragment,
  InspectionType,
  useCreateInspectionAppointmentsMutation
} from "local/graphql";
import { useCallback } from "react";

export interface CreateInspectionAppointmentInput {
  agencyAilorn: string;
  input: {
    propertyAilorn: string;
    tenancyAilorn: string;
    inspectingAgentAilorn: string;
    type: InspectionType;
  }[];
}

export function useCreateInspectionAppointments(): {
  createInspectionAppointments: (
    input: CreateInspectionAppointmentInput
  ) => Promise<InspectionAppointmentDetailsFragment[] | undefined>;
} {
  const [createInspectionAppointmentsMutation] =
    useCreateInspectionAppointmentsMutation();

  const createInspectionAppointments = useCallback(
    async ({
      agencyAilorn,
      input
    }: CreateInspectionAppointmentInput): Promise<
      InspectionAppointmentDetailsFragment[] | undefined
    > => {
      const result = await createInspectionAppointmentsMutation({
        variables: {
          agencyAilorn,
          input
        }
      });
      const inspectionAppointments =
        result.data?.createInspectionAppointments.inspectionAppointments;
      if (!inspectionAppointments) {
        AiloSentry.captureException("Failed to create inspection appointments");
      }
      return inspectionAppointments;
    },
    [createInspectionAppointmentsMutation]
  );
  return {
    createInspectionAppointments
  };
}
