import React, { useCallback } from "react";
import { BaseSplitScreenModalForm } from "../BaseSplitScreenModalForm";
import { AddOrEditTenancyScreen } from "./AddOrEditTenancyScreen";
import { PropertyAddress } from "../components/PropertyAddress";
import { TenancyFragment } from "local/graphql";

interface Props {
  managementId: string;
  tenancyId?: string;
  onDismiss: () => void;
  onSuccess: (tenancy: TenancyFragment) => void;
}

export function TenancyModalForm({
  managementId,
  tenancyId,
  onDismiss,
  onSuccess
}: Props): React.ReactElement {
  const renderFormComponent = useCallback(
    (): React.ReactElement => (
      <AddOrEditTenancyScreen
        tenancyId={tenancyId}
        managementId={managementId}
        onDismiss={onDismiss}
        onSuccess={onSuccess}
      />
    ),
    [tenancyId, managementId, onDismiss, onSuccess]
  );

  return (
    <BaseSplitScreenModalForm
      title={tenancyId ? "Edit ingoing tenancy" : "Set up the new tenancy"}
      renderFormComponent={renderFormComponent}
      renderRightComponent={() => (
        <PropertyAddress managementId={managementId} />
      )}
      onDismiss={onDismiss}
    />
  );
}
