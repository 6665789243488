import { VacatingReason } from "local/graphql";

export const tenancyVacatingReasons = [
  {
    value: VacatingReason.WithinTermsOfAgreement,
    label: "Within terms of agreement"
  },
  { value: VacatingReason.LeaseBreak, label: "Lease break" },
  { value: VacatingReason.Termination, label: "Termination" },
  { value: VacatingReason.LostManagement, label: "Management ending" }
];

export function getLabelForTenancyVacatingReason(
  inputReason: VacatingReason
): string {
  const valueLabelPair = tenancyVacatingReasons.find(
    (reason) => reason.value === inputReason
  );
  return valueLabelPair ? valueLabelPair.label : "";
}
