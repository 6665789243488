import React, { ReactElement } from "react";
import { Colors, opacify, Text } from "@ailo/primitives";
import { FormFieldGroup, FormFieldRow, Separator } from "@ailo/ui";
import styled from "styled-components/native";
import { View } from "react-native";
import {
  ActionAssigneeField,
  ActionDescriptionField,
  ActionDueDateField,
  ActionNameField,
  ActionTypeField
} from "./fields";
import { ActionFormData } from "./ActionFormData";
import { PersonDisplayDetailsFragment } from "local/graphql";
import { BaseFormSeparator } from "local/modals/ui/BaseFormSeparator";

export type ActionFormBodyType = "create" | "update";

export function ActionFormBody({
  type,
  assignees,
  onFieldUpdated
}: {
  type: ActionFormBodyType;
  assignees: PersonDisplayDetailsFragment[];
  onFieldUpdated(fieldName: keyof ActionFormData): void;
}): ReactElement {
  return (
    <FormFieldGroup label={"Action type"}>
      {type === "create" && (
        <>
          <FormFieldRow>
            <ActionTypeField onFieldUpdated={onFieldUpdated} />
          </FormFieldRow>
          <BaseFormSeparator style={{ marginTop: 32, marginBottom: 32 }} />
        </>
      )}
      <FormFieldRow style={{ marginBottom: 24 }}>
        <ActionNameField onFieldUpdated={onFieldUpdated} />
      </FormFieldRow>
      <FormFieldRow style={{ marginBottom: 24 }}>
        <ActionDescriptionField onFieldUpdated={onFieldUpdated} />
      </FormFieldRow>
      <FormFieldRow style={{ width: "50%", marginBottom: 24 }}>
        <ActionDueDateField onFieldUpdated={onFieldUpdated} />
      </FormFieldRow>
      <FormFieldRow>
        <ActionAssigneeField
          label={"Assignee"}
          onFieldUpdated={onFieldUpdated}
          assignees={assignees}
        />
      </FormFieldRow>
    </FormFieldGroup>
  );
}

const Loading = ({ type }: { type: ActionFormBodyType }): ReactElement => (
  <>
    <Text.BodyS.Loading width={60} style={{ marginBottom: 20 }} />
    {type === "create" && (
      <>
        <ActionTypeField.Loading />
        <SectionSeparator
          style={{
            marginBottom: 36,
            marginTop: 32,
            borderColor: opacify(Colors.CHARCOAL, 0.075)
          }}
        />
      </>
    )}
    <ActionNameField.Loading />
    <ActionDescriptionField.Loading />
    <SectionSeparator
      style={{
        marginBottom: 32,
        marginTop: 40,
        borderColor: opacify(Colors.CHARCOAL, 0.075)
      }}
    />
    <Text.BodyS.Loading width={100} style={{ marginBottom: 16 }} />
    <View style={{ width: "50%" }}>
      <ActionDueDateField.Loading />
    </View>
    <SectionSeparator
      style={{
        marginBottom: 32,
        marginTop: 40,
        borderColor: opacify(Colors.CHARCOAL, 0.075)
      }}
    />
    <Text.BodyS.Loading width={100} style={{ marginBottom: 16 }} />
    <ActionAssigneeField.Loading />
  </>
);

ActionFormBody.Loading = Loading;

const SectionSeparator = styled(Separator)`
  margin: 32px -60px 12px 0;
`;
