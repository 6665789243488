import { GetClaimTenancyBondDetailsQuery } from "local/graphql";
import { AiloRN } from "@ailo/ailorn";
import { Money } from "@ailo/ui";
import { isRentLiability } from "./isRentLiability";
import { ClaimTenancyData } from "local/domain/bond";
import { GqlClaim } from "./types";

export function parseRent(
  data: GetClaimTenancyBondDetailsQuery
): ClaimTenancyData["rent"] {
  const liability = data?.tenancy?.liability!;
  const overdueAmount =
    liability.terminationAmountDue?.cents || liability.overdueAmount.cents;
  const outstandingAmount =
    overdueAmount && overdueAmount > 0
      ? Money.fromCents(overdueAmount)
      : Money.zero();
  const rentClaim = findRentClaim(data?.tenancy?.bond?.claims);

  return {
    reference: AiloRN.fromString(liability.reference),
    outstandingAmount,
    amountClaimed: rentClaim ? Money.from(rentClaim.amount) : Money.zero()
  };
}

export function findRentClaim(
  claims?: GqlClaim[] | null
): GqlClaim | undefined {
  const rentClaims = claims?.filter((claim) =>
    claim?.liabilityAiloRN ? isRentLiability(claim) : false
  );
  return rentClaims && rentClaims.length > 0 ? rentClaims[0] : undefined;
}
