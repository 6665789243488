import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { QueryHookOptions, ApolloError } from "@apollo/client";

import {
  GetAvailableFeaturesQuery,
  GetAvailableFeaturesQueryVariables,
  useGetAvailableFeaturesQuery
} from "local/graphql";

type SetupData = GetAvailableFeaturesQuery & {
  effectiveUser: NonNullable<GetAvailableFeaturesQuery["effectiveUser"]> & {
    person: NonNullable<
      GetAvailableFeaturesQuery["effectiveUser"]
    >["person"] & {
      firstName: NonNullable<
        NonNullable<
          GetAvailableFeaturesQuery["effectiveUser"]
        >["person"]["firstName"]
      >;
    };
  };
};

export function useGetSetupData(
  opts: QueryHookOptions<
    GetAvailableFeaturesQuery,
    GetAvailableFeaturesQueryVariables
  >
): MappedQueryResult<SetupData, GetAvailableFeaturesQueryVariables> {
  const result = useGetAvailableFeaturesQuery(opts);
  return useMappedQueryResult(result, (data) => {
    if (!data.effectiveUser?.person) {
      throw new ApolloError({
        errorMessage: `effectiveUser.person is missing in GetSetupDataQuery`
      });
    }
    return data as SetupData;
  });
}
