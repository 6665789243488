import React, { ReactElement, useCallback } from "react";
import { Screens, useRoute } from "local/common";
import { EditBondClaimForm } from "./components";

import { BaseClaimTenancyBondScreen } from "./BaseClaimTenancyBondScreen";
import { ClaimTenancyData } from "local/domain/bond";
import { PropertyContextProvider } from "../PropertyScreen";

function EditTenancyBondClaimScreen(): ReactElement {
  const { tenancyId, managementId } =
    useRoute<Screens.EditTenancyBondClaim>().params!;

  const formConstructor = useCallback(
    (data: ClaimTenancyData, onClose: () => void) => {
      return <EditBondClaimForm data={data} onClose={onClose} />;
    },
    []
  );

  return (
    <PropertyContextProvider managementId={managementId}>
      <BaseClaimTenancyBondScreen
        tenancyId={tenancyId}
        managementId={managementId}
        formConstructor={formConstructor}
        title={"Edit bond claim"}
      />
    </PropertyContextProvider>
  );
}

export { EditTenancyBondClaimScreen };
