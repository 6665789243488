import { Colors, TeamIcon } from "@ailo/primitives";
import { MultiSelectButton } from "@ailo/ui";
import React, { useCallback } from "react";
import { useSelectedTeams } from "./context";
import {
  useGetDataForTeamsFilter,
  Option,
  UnassignedOption
} from "./useGetDataForTeamsFilter";

export function TeamsFilter({
  buttonHeight = 40,
  horizontalAlign = "right",
  onChange
}: {
  buttonHeight?: number;
  horizontalAlign?: "left" | "right";
  onChange?: (teams: string[]) => void;
}): React.ReactElement | null {
  const {
    propertyListUnassignedSelected,
    updatePropertyListUnassignedSelected,
    propertyListSelectedTeamIds,
    updatePropertyListTeamIds
  } = useSelectedTeams();

  const { optionGroups, selectedOptions, loading, error, refetch } =
    useGetDataForTeamsFilter(
      propertyListSelectedTeamIds,
      propertyListUnassignedSelected
    );

  const onSelectedTeamChange = useCallback(
    (options: Option[]) => {
      const teamOptions = options.filter(
        ({ value }) => value !== UnassignedOption.value
      );
      const teamIds = teamOptions.map(({ value }) => value);
      updatePropertyListTeamIds(teamIds);

      const unassignedSelected = options.some(
        ({ value }) => value === UnassignedOption.value
      );
      updatePropertyListUnassignedSelected(unassignedSelected);
      onChange?.(teamIds);
    },
    [onChange, updatePropertyListTeamIds, updatePropertyListUnassignedSelected]
  );

  if (loading)
    return (
      <MultiSelectButton.Loading
        entityType={"Teams"}
        horizontalAlign={horizontalAlign}
        buttonHeight={buttonHeight}
      />
    );

  if (error)
    return (
      <MultiSelectButton.Error
        entityType={"Teams"}
        horizontalAlign={horizontalAlign}
        onReload={refetch}
      />
    );

  if (optionGroups.length === 0) return null;

  return (
    <MultiSelectButton
      optionGroups={optionGroups}
      leftIcon={
        <TeamIcon color={Colors.TEXT.DARK.SECONDARY} height={20} width={20} />
      }
      entityType={"Teams"}
      value={selectedOptions}
      onChange={onSelectedTeamChange}
      horizontalAlign={horizontalAlign}
      buttonHeight={buttonHeight}
    />
  );
}
