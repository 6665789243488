import React, { ReactElement } from "react";
import { FormFooterProps } from "./FormFooterProps";
import { FullPageFormFooter } from "./FullPageFormFooter";
import { ModalFormFooter } from "./ModalFormFooter";

interface Props {
  type: "full-page" | "modal";
}

export function FormFooter({
  type,
  ...formFooterProps
}: Props & FormFooterProps): ReactElement {
  if (type === "full-page") return <FullPageFormFooter {...formFooterProps} />;

  return <ModalFormFooter {...formFooterProps} />;
}
