import { AiloRN, services } from "@ailo/ailorn";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { ScreenComponent, useAnalytics } from "@ailo/services";
import { SidebarContent, TabNavigation } from "@ailo/ui";
import { AddExpenseButton, ExpensesSectionList } from "local/domain/expense";
import { AutomaticFeesList } from "local/domain/fee";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { ExpensesListSidebarErrorBoundaryFallback } from "./ExpensesListSidebarErrorBoundaryFallback";
import { PropertyWalletBalance } from "./PropertyWalletBalance";
import { TenancyTabContent } from "./TenancyTabContent";

const tabs = [
  { key: "tenancy" as const, label: "Tenancy" },
  { key: "management" as const, label: "Management" },
  { key: "recurring" as const, label: "Recurring" }
];

interface ExpensesListSidebarProps {
  managementId: string;
  onAddExpensePress?: () => void;
  onExpensePress?: (ailorn: AiloRN) => void;
  initialSelectedTab?: "tenancy" | "management" | "recurring";
  initialTenancyId?: string;
  onClose?: () => void;
  style?: StyleProp<ViewStyle>;
}

export function ExpensesListSidebarContent({
  initialSelectedTab,
  initialTenancyId,
  managementId,
  onExpensePress,
  onClose,
  style
}: ExpensesListSidebarProps): ReactElement {
  const [selectedTab, setSelectedTab] = useState(
    tabs.find((tab) => tab.key === initialSelectedTab) || tabs[1]
  );
  const onTabChange = useCallback(
    (tab): void => setSelectedTab(tab),
    [setSelectedTab]
  );

  const hasManagementFeeBlueprintFeature = useHasFeature(
    PlatformFeatureId.ManagementFeeBlueprint
  );

  const managementAilorn = AiloRN.of(
    services.PropertyManagement.management,
    managementId
  );
  const managementAilornString = managementAilorn.toString();

  const analytics = useAnalytics();
  useEffect(() => {
    if (selectedTab.key === "tenancy") return;

    const screenName =
      selectedTab.key === "management"
        ? "Management Expenses"
        : "Management Recurring Expenses";
    const analyticsPayload = {
      managementId: managementAilornString
    };
    analytics.trackScreenVisited(screenName, analyticsPayload);
  }, [analytics, selectedTab, managementAilornString]);

  const tabContent =
    selectedTab.key === "tenancy" ? (
      <TenancyTabContent
        initialTenancyId={initialTenancyId}
        managementAilorn={managementAilorn}
        onExpensePress={onExpensePress}
      />
    ) : selectedTab.key === "management" ? (
      <>
        <PropertyWalletBalance
          managementAilorn={managementAilorn}
          style={{ marginTop: 16 }}
        />
        <ExpensesSectionList
          payerAilorn={managementAilorn}
          onExpensePress={onExpensePress}
          style={{ marginTop: 16 }}
        />
      </>
    ) : (
      <AutomaticFeesList
        managementId={managementId}
        itemsLimit={1000}
        variant={"sidebar"}
      />
    );

  return (
    <SidebarContent
      title={"Expenses"}
      titleRightButtons={
        <AddExpenseButton managementId={managementId} variant={"sidebar"} />
      }
      onClose={onClose}
      style={[{ paddingHorizontal: 0 }, style]}
      titleStyle={{ marginHorizontal: 32, marginBottom: 32 }}
    >
      <TabNavigation
        options={tabs.filter((tab) =>
          hasManagementFeeBlueprintFeature ? tab.key !== "recurring" : true
        )}
        selectedOptionKey={selectedTab.key}
        onChange={onTabChange}
        style={{ marginLeft: 32 }}
      />
      {tabContent}
    </SidebarContent>
  );
}

export function ExpensesListSidebar(
  props: ExpensesListSidebarProps
): ReactElement {
  return (
    <ScreenComponent
      name={"Property Expenses List"}
      analytics={false}
      errorBoundaryOptions={{
        fallbackComponent: ExpensesListSidebarErrorBoundaryFallback(
          props.onClose
        )
      }}
    >
      <ExpensesListSidebarContent {...props} />
    </ScreenComponent>
  );
}
