import React from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import { Text, Colors } from "@ailo/primitives";
import { Address, ProjectStatus, ProjectType } from "local/graphql";
import { formatAddress } from "@ailo/domain-helpers";
import { ProjectStatusBadge } from "./ProjectStatusBadge";
import { formatProjectType } from "@ailo/ui";

export interface ProjectInfoUIProps {
  address?: Address;
  type: ProjectType;
  status?: ProjectStatus;
  reference?: string;
  description?: string | null;
}

interface Column {
  label: string;
  description: string | React.ReactNode | null;
}

export function ProjectInfoUI({
  address,
  type,
  status,
  reference,
  description: projectDescription
}: ProjectInfoUIProps): React.ReactElement {
  const rows = [
    [
      {
        label: "Property",
        description: address ? formatAddress(address) : null
      }
    ],
    [
      {
        label: "Type",
        description: formatProjectType(type)
      }
    ],
    [
      {
        label: "Status",
        description: status ? <ProjectStatusBadge status={status} /> : null
      },
      {
        label: "Reference",
        description: reference
      }
    ],
    [{ label: "Description", description: projectDescription }]
  ].map((columns: Column[]) =>
    columns.filter(({ description }) => !!description)
  );

  return (
    <Container>
      {rows.map((columns, i) => (
        <Row key={i}>
          {columns.map(({ label, description }) => (
            <Column key={label}>
              <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
                {label}
              </Text.BodyS>
              {typeof description !== "string" ? (
                description
              ) : (
                <Text.BodyS weight={"book"}>{description}</Text.BodyS>
              )}
            </Column>
          ))}
        </Row>
      ))}
    </Container>
  );
}

const Column = styled(View)`
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
`;

const Row = styled(View)`
  flex-direction: row;
  margin-bottom: 12px;
`;

const Container = styled(Column)`
  max-width: 308px;
  margin: 80px 60px 0 32px;
  align-items: stretch;
`;

ProjectInfoUI.Loading = function ProjectInfoUILoading({
  fullSize
}: {
  fullSize?: boolean;
}): React.ReactElement {
  return (
    <Container>
      <Text.BodyS.Loading width={60} />
      <Text.BodyS.Loading width={120} style={{ marginBottom: 12 }} />
      <Text.BodyS.Loading width={60} />
      <Text.BodyS.Loading width={120} style={{ marginBottom: 12 }} />
      {fullSize && (
        <>
          <Text.BodyS.Loading width={60} />
          <Text.BodyS.Loading width={120} style={{ marginBottom: 12 }} />
          <Text.BodyS.Loading width={307} />
          <Text.BodyS.Loading width={307} />
          <Text.BodyS.Loading width={154} />
        </>
      )}
    </Container>
  );
};
