import { Table, NumberTimeSeries } from "@ailo/ui";
import { Row, useCurrentAgencyOrg } from "local/common";

import { ReportHeader } from "../../components/ReportHeader";

import { ReportMetricCard } from "../../components/ReportMetricCard";

import { View } from "react-native";

import React from "react";
import {
  useGetAgencyFinancialPerformanceReportQuery,
  useGetAgencyIncomeByMonthReportQuery,
  useGetCurrentPropertyCountQuery
} from "local/graphql";
import { useReportTeamContext } from "../..";
import { reports } from "../../reportsDetails";

function getFinancialPerformanceSeries(
  series: { name: string; values: number[] }[]
): {
  managementFees: number[];
  otherFees: number[];
} {
  const managementFees = new Array(series[0]?.values.length).fill(0);
  const otherFees = new Array(series[0]?.values.length).fill(0);
  series.forEach((s) => {
    s.values.forEach((v, i) => {
      if (s.name === "Management Fees") {
        managementFees[i] += v ?? 0;
      } else {
        otherFees[i] += v ?? 0;
      }
    });
  });
  return {
    managementFees,
    otherFees
  };
}

export function FinancialPerformanceDashboard(): React.ReactElement {
  const { id } = useCurrentAgencyOrg();
  const { teamId } = useReportTeamContext();
  // construct variables
  const variables = { managingOrgId: id, teamId };
  // get data
  const metricQueryResponse = useGetAgencyFinancialPerformanceReportQuery({
    variables,
    onError: () => metricQueryResponse.refetch()
  });
  const timeseriesQueryResponse = useGetAgencyIncomeByMonthReportQuery({
    variables,
    onError: () => timeseriesQueryResponse.refetch()
  });

  const currentPropertyCount = useGetCurrentPropertyCountQuery({ variables });
  const reportData = metricQueryResponse.data?.agencyFinancialPerformanceReport;
  const timeseriesData =
    timeseriesQueryResponse.data?.agencyIncomeByMonthReport;

  if (
    !currentPropertyCount.data?.propertyIssuesSummaryReport?.headerFields
      ?.totalPropertyCount
  ) {
    metricQueryResponse.refetch();
    return <Table.Loading />;
  }
  if (!reportData) {
    metricQueryResponse.refetch();
    return <Table.Loading />;
  }
  if (!timeseriesData) {
    timeseriesQueryResponse.refetch();
    return <Table.Loading />;
  }
  const labels: string[] = (timeseriesData.labels ?? []).map((l) => {
    return l?.formatted ?? "";
  });
  const financialPerformanceSeries = getFinancialPerformanceSeries(
    timeseriesData?.series as { name: string; values: number[] }[]
  );
  const series = [
    {
      name: "Management Fees",
      color: "#6E8FCB",
      values: financialPerformanceSeries.managementFees
    },
    {
      name: "Other Fees",
      color: "#B36ECB",
      values: financialPerformanceSeries.otherFees
    }
  ];

  return (
    <>
      <ReportHeader reportTitle={reports.financial_performance.reportHeading} />
      <Row style={{ justifyContent: "space-between", marginTop: 44 }}>
        <View style={{ flex: 1 }}>
          <ReportMetricCard
            value={reportData.totalRevenue!.thisPeriodValue}
            label={reportData.totalRevenue!.label!}
            previousValue={
              reportData.totalRevenue!.previousPeriodValue ?? undefined
            }
            periodLabel={reportData.totalRevenue!.periodLabel ?? undefined}
            changeValue={reportData.totalRevenue!.changeValue ?? undefined}
            changeType={
              reportData.propertiesUnderManagement!.changeType === "positive"
                ? "positive"
                : "negative"
            }
          />
        </View>
      </Row>
      <Row>
        <View style={{ flex: 1 }}>
          <ReportMetricCard
            value={reportData.managementFeeRevenue!.thisPeriodValue}
            label={reportData.managementFeeRevenue!.label!}
            previousValue={
              reportData.managementFeeRevenue!.previousPeriodValue ?? undefined
            }
            periodLabel={
              reportData.managementFeeRevenue!.periodLabel ?? undefined
            }
            changeValue={
              reportData.managementFeeRevenue!.changeValue ?? undefined
            }
            changeType={
              reportData.managementFeeRevenue!.changeType === "positive"
                ? "positive"
                : "negative"
            }
          />
        </View>
        <View style={{ flex: 1 }}>
          <ReportMetricCard
            value={reportData.averageManagementFeePercent!.thisPeriodValue}
            label={reportData.averageManagementFeePercent!.label!}
            previousValue={
              reportData.averageManagementFeePercent!.previousPeriodValue ??
              undefined
            }
            periodLabel={
              reportData.averageManagementFeePercent!.periodLabel ?? undefined
            }
            changeValue={
              reportData.averageManagementFeePercent!.changeValue ?? undefined
            }
            changeType={
              reportData.averageManagementFeePercent!.changeType === "positive"
                ? "positive"
                : "negative"
            }
          />
        </View>
      </Row>
      <Row>
        <View style={{ flex: 1 }}>
          <ReportMetricCard
            value={reportData.overFeeRevenue!.thisPeriodValue}
            label={reportData.overFeeRevenue!.label!}
            previousValue={
              reportData.overFeeRevenue!.previousPeriodValue ?? undefined
            }
            periodLabel={reportData.overFeeRevenue!.periodLabel ?? undefined}
            changeValue={reportData.overFeeRevenue!.changeValue ?? undefined}
            changeType={
              reportData.overFeeRevenue!.changeType === "positive"
                ? "positive"
                : "negative"
            }
          />
        </View>
        <View style={{ flex: 1 }}>
          <ReportMetricCard
            value={reportData.averageManagementFeeAmount!.thisPeriodValue}
            label={reportData.averageManagementFeeAmount!.label!}
            previousValue={
              reportData.averageManagementFeeAmount!.previousPeriodValue ??
              undefined
            }
            periodLabel={
              reportData.averageManagementFeeAmount!.periodLabel ?? undefined
            }
            changeValue={
              reportData.averageManagementFeeAmount!.changeValue ?? undefined
            }
            changeType={
              reportData.averageManagementFeeAmount!.changeType === "positive"
                ? "positive"
                : "negative"
            }
          />
        </View>
      </Row>
      <NumberTimeSeries
        title={"Total Revenue by Management Fees, Other Fees"}
        subtitle={"Monthly"}
        labels={labels}
        series={series}
      />
    </>
  );
}
