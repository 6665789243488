import React from "react";
import { View } from "react-native";

import { SFC } from "@ailo/primitives";
import { SelectOption } from "@ailo/ui";

import { SearchProperty } from "local/domain/propertyManagement/components/SearchProperty";
import { useAnalytics } from "@ailo/services";
import { usePropertySearchAnalytics } from "local/domain/propertyManagement/hooks/usePropertySearchAnalytics";

const PropertyInput: SFC = ({ style }) => {
  const analytics = useAnalytics();
  const { searchVersion } = usePropertySearchAnalytics();

  const onOptionSelect = (
    option: SelectOption | undefined,
    searchString: string
  ): void => {
    analytics.track("Create Bill Property Input Search Result Clicked", {
      searchResultCategory: "Property",
      searchResultClickedString: option?.label,
      searchString,
      searchStringLength: searchString.length,
      searchVersion
    });
  };

  const onEmptyOptionsShow = (searchString: string): void => {
    analytics.track("Create Bill Property Input Search No Match Found", {
      searchString,
      searchStringLength: searchString.length,
      searchVersion
    });
  };

  const onFocus = (): void => {
    analytics.track("Create Bill Property Input Search Opened", {
      searchVersion
    });
  };

  return (
    <View style={style}>
      <SearchProperty
        label={"Property"}
        onFocus={onFocus}
        onEmptyOptionsShow={onEmptyOptionsShow}
        onOptionSelect={onOptionSelect}
        name={"managementId"}
      />
    </View>
  );
};

export { PropertyInput };
