import { Screens, useNavigation } from "local/common";
import { useCallback } from "react";

export function useNavigateToClaimTenancyBond({
  managementId,
  tenancyId
}: {
  managementId: string;
  tenancyId: string;
}): () => void {
  const navigation = useNavigation();

  return useCallback((): void => {
    navigation.navigate(Screens.AppNavigator, {
      screen: Screens.PropertiesTab,
      params: {
        screen: Screens.ClaimTenancyBond,
        params: { managementId, tenancyId }
      }
    });
  }, [navigation, managementId, tenancyId]);
}
