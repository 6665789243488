import { CloseIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";

export function ModalCloseButton({
  onPress,
  style
}: {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <Button.Secondary
      style={[
        {
          borderWidth: 0,
          borderRadius: 0,
          width: 72
        },
        style
      ]}
      type={"medium"}
      square
      onPress={onPress}
    >
      <CloseIcon height={18} width={18} />
    </Button.Secondary>
  );
}
