import {
  SwitcherHeaderOption,
  PropertyListView,
  Screens,
  TabNavigation,
  useNavigation
} from "local/common";
import { TeamsFilter } from "local/domain/propertyManagement";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";

interface Props {
  tabs: (SwitcherHeaderOption | PropertyListView)[];
  selectedTabIndex: number;
  onNavigate?: (tab: string) => void;
}

export function PropertyListHeader({
  tabs,
  selectedTabIndex,
  onNavigate
}: Props): React.ReactElement {
  const navigation = useNavigation<Screens.PropertyList>();

  return (
    <Container>
      <StyledTabNavigation
        options={tabs}
        selected={selectedTabIndex}
        navigation={navigation}
        onNavigate={onNavigate}
      />
      <TeamsFilter />
    </Container>
  );
}

const Container = styled(View)`
  flex-direction: row;
  margin-bottom: 16px;
  margin-top: 32px;
  align-items: center;
`;

const StyledTabNavigation = styled(TabNavigation)`
  flex: 1;
  margin-right: 12px;
`;
