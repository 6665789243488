import { Colors, opacify, Text } from "@ailo/primitives";
import { FileList } from "@ailo/ui";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { translateFile } from "@ailo/domains";
import { ExpenseMergedType } from "../../graphql/useGetExpense";

type ExpenseAttachmentsProps = {
  expense?: Pick<ExpenseMergedType, "attachments" | "expenseType">;
  loading?: boolean;
};

export function ExpenseAttachments({
  expense,
  loading
}: ExpenseAttachmentsProps): React.ReactElement | null {
  if (!expense?.attachments) return null;

  if (loading) {
    return (
      <StyledContainer>
        <Text.BodyM.Loading width={100} />
      </StyledContainer>
    );
  }

  const files = expense.attachments.map((a) => translateFile(a.file));

  if (files.length === 0) {
    return null;
  }

  return (
    <StyledContainer>
      <Text.BodyM
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginBottom: 16 }}
      >
        {"Attachments"}
      </Text.BodyM>
      <FileList files={files} openingMethod={"newTab"} />
    </StyledContainer>
  );
}

const StyledContainer = styled(View)`
  margin-top: 40px;
  border-top-width: 1px;
  border-top-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  margin-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
`;
