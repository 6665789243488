import { useCurrentAgencyOrg } from "local/common";
import {
  UnapproveMigratingManagementsMutation,
  useUnapproveMigratingManagementsMutation,
  GetPagedApprovedMigratingManagementsDocument
} from "local/graphql";
import { PROPERTY_ONBOARDING_LIST_PAGE_SIZE } from "./usePaginationRequest";
import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { useCallback } from "react";
import { MutationResult } from "@apollo/client";
import { PropertyOnboardingListRequestParams } from "../types";

interface UnapproveMigratingManagementsProps {
  selectedMigratingManagementIds: string[];
  onSuccess: (result: UnapproveMigratingManagementsMutation) => void;
  onError: () => void;
  lastRequestParams: PropertyOnboardingListRequestParams | null;
}

type UnapproveMigratingManagements = [
  () => void,
  MutationResult<UnapproveMigratingManagementsMutation>
];

export function useUnapproveMigratingManagements({
  selectedMigratingManagementIds,
  onSuccess,
  onError,
  lastRequestParams
}: UnapproveMigratingManagementsProps): UnapproveMigratingManagements {
  const analytics = useAnalytics();
  const currentOrg = useCurrentAgencyOrg();

  const [unapproveMigratingManagementsMutation, mutationResult] =
    useUnapproveMigratingManagementsMutation({
      variables: { migratingManagementIds: selectedMigratingManagementIds },
      refetchQueries: [
        {
          query: GetPagedApprovedMigratingManagementsDocument,
          variables: {
            cursor: lastRequestParams?.cursor ?? null,
            before: lastRequestParams?.before ?? null,
            pageSize: PROPERTY_ONBOARDING_LIST_PAGE_SIZE,
            organisationId: currentOrg.ailoRN,
            sort: lastRequestParams?.sort ?? []
          }
        }
      ]
    });

  const unapproveMigratingManagements = useCallback(async () => {
    try {
      analytics.track("Multiple Migrating Properties Unapproved");

      const result = await unapproveMigratingManagementsMutation();
      throwIfMutationFailed(result, {
        dataKey: "unapproveMigratingManagements"
      });
      if (!result.data) {
        onError();
        return;
      }

      onSuccess(result.data);
    } catch (error) {
      AiloSentry.captureException(error);
      onError();
    }
  }, [unapproveMigratingManagementsMutation, onSuccess, onError, analytics]);

  return [unapproveMigratingManagements, mutationResult];
}
