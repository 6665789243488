import React, { ReactElement } from "react";
import {
  ErrorBoundaryDefaultRenderFallback,
  ErrorBoundaryRenderFallbackProps
} from "@ailo/services";
import { ErrorAlertScreen } from "@ailo/ui";
import { BillDataFetchError } from "./BillDataFetchError";

export function ViewBillErrorBoundaryFallback(
  onClose?: () => void
): (props: ErrorBoundaryRenderFallbackProps) => ReactElement {
  return function Fallback({
    error,
    retry
  }: ErrorBoundaryRenderFallbackProps): ReactElement {
    if (error instanceof BillDataFetchError) {
      return (
        <ErrorAlertScreen
          variant={"sidebar"}
          title={"There was a problem loading Bill details"}
          onRetry={retry}
          onClose={onClose}
        />
      );
    }

    return (
      <ErrorBoundaryDefaultRenderFallback {...{ error, retry, onClose }} />
    );
  };
}
