import React from "react";
import { InspectionSectionInfoFragment } from "local/graphql";
import { View } from "react-native";
import { Text } from "@ailo/primitives";
import { SectionHeader } from "../../../common";
import { InspectionScheduleManageMenu } from "local/domain/inspection";
import { InspectionSectionDescriptionList } from "./InspectionSectionDescriptionList";

interface Props {
  inspectionSectionInfo: InspectionSectionInfoFragment;
}

export function InspectionSection({
  inspectionSectionInfo
}: Props): React.ReactElement {
  return (
    <View>
      <SectionHeader>
        <Text.BodyL weight={"medium"} style={{ marginBottom: 16 }}>
          {"Inspections"}
        </Text.BodyL>
        <InspectionScheduleManageMenu
          inspectionSchedule={inspectionSectionInfo}
        />
      </SectionHeader>
      <InspectionSectionDescriptionList
        inspectionSectionInfo={inspectionSectionInfo}
      />
    </View>
  );
}
