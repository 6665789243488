import { FormField, RadioButtonGroup } from "@ailo/ui";
import { ActionType } from "local/graphql";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ActionFormData } from "../ActionFormData";

export function ActionTypeField({
  onFieldUpdated
}: {
  onFieldUpdated(fieldName: keyof ActionFormData): void;
}): ReactElement {
  const { control, errors } = useFormContext<ActionFormData>();

  return (
    <Controller
      control={control}
      name={"type"}
      defaultValue={ActionType.Reminder}
      render={({ value, onChange, onBlur }) => (
        <FormField error={errors.type?.message}>
          <RadioButtonGroup
            {...{ value, onBlur }}
            style={{ flexWrap: "wrap" }}
            variant={"button-solid-multiline"}
            optional={true}
            options={[
              {
                value: ActionType.Reminder,
                label: "Reminder"
              },
              {
                value: ActionType.AssignForm,
                label: "Form"
              }
            ]}
            onChange={(selectedValue) => {
              onFieldUpdated("type");
              onChange(selectedValue);
            }}
          />
        </FormField>
      )}
      rules={{
        required: "Please select an action type"
      }}
    />
  );
}

ActionTypeField.Loading = function Loading() {
  return (
    <RadioButtonGroup.Loading
      options={[...Array(1)].map(() => ({
        buttonWidth: 120
      }))}
      variant={"button-solid-multiline"}
    />
  );
};
