import { KeyFragment } from "local/graphql";
import React, { useCallback, useState } from "react";

type EditModalConfigType = {
  mode: "edit" | "delete";
  title: string;
  selectedKey: KeyFragment;
};

type CreateModalConfigType = {
  mode: "create";
  title: string;
};

type ModalConfigState = CreateModalConfigType | EditModalConfigType;

type PropertyManagementInfoType = {
  propertyAilornString: string;
  organisationAilornString: string;
  managementAilornString: string;
};

export type PropertyKeyModalContextData = {
  openKeyModal: (modalConfig: ModalConfigState) => void;
  closeKeyModal: () => void;
  modalConfig?: ModalConfigState;
} & PropertyManagementInfoType;

export const PropertyKeyModalContext = React.createContext<
  PropertyKeyModalContextData | undefined
>(undefined);

export function PropertyKeyModalContextProvider({
  children,
  propertyAilornString,
  organisationAilornString,
  managementAilornString
}: React.PropsWithChildren<PropertyManagementInfoType>): React.ReactElement {
  const [modalConfig, setModalConfig] = useState<ModalConfigState>();

  const closeKeyModal = useCallback(() => {
    setModalConfig(undefined);
  }, []);

  return (
    <PropertyKeyModalContext.Provider
      value={{
        modalConfig,
        openKeyModal: setModalConfig,
        closeKeyModal,
        propertyAilornString,
        organisationAilornString,
        managementAilornString
      }}
    >
      {children}
    </PropertyKeyModalContext.Provider>
  );
}
