import React, { ReactNode, useEffect, useRef } from "react";
import { View } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { Colors, SFC } from "@ailo/primitives";
import { useClearPreviousDataOnVariablesChange } from "@ailo/services";
import {
  FormField,
  LabelledSelectInput,
  SelectInput,
  SelectInputError,
  SelectInputLoading
} from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import { ApolloError } from "@apollo/client";
import {
  useGetTaxCategoriesQuery,
  useGetLastSupplierBillTaxCategoryQuery
} from "local/graphql";

const name = "taxCategoryId";

const TaxCategoryInputFormField: SFC = ({ style }) => {
  const { taxCategoryOptions, loading, error } = useTaxCategoryData();

  const { control, formState, watch, setValue, errors } =
    useFormContext<AddBillFormData>();
  const dirty = !!formState.dirtyFields.taxCategoryId;
  const payeeId = watch("supplierReference")?.value;
  const lastSupplierBillResult = useClearPreviousDataOnVariablesChange(
    useGetLastSupplierBillTaxCategoryQuery,
    !payeeId || dirty
      ? { skip: true }
      : {
          variables: { payeeId },
          fetchPolicy: "cache-and-network" as const,
          nextFetchPolicy: "cache-first" as const
        }
  );
  const defaultValue =
    lastSupplierBillResult.data?.bills?.items[0]?.taxCategory.id ?? null;

  useEffect(() => {
    if (!dirty) {
      setValue("taxCategoryId", defaultValue);
    }
  }, [dirty, defaultValue, setValue]);

  const inputRef = useRef<any>(null);

  if (loading) return <Loading style={style} />;
  if (error) return <Error style={style} />;

  return (
    <View style={style}>
      <Controller
        render={({ value, onChange }): React.ReactElement => (
          <FormField
            label={"Category"}
            testID={"TaxCategoryInputFormField"}
            error={errors.taxCategoryId?.message}
          >
            <SelectInput
              value={value}
              inputRef={inputRef}
              onValueChange={onChange}
              placeholder={"Select Category..."}
              options={taxCategoryOptions}
              useTextInputHeight
            />
          </FormField>
        )}
        onFocus={() => inputRef.current.focus()}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: "Select Category"
        }}
      />
    </View>
  );
};

const Loading: SFC = ({ style }) => {
  return (
    <CategorySelectIntermediateInput
      style={style}
      label={"Category"}
      component={<SelectInputLoading />}
    />
  );
};

const Error: SFC = ({ style }) => {
  return (
    <CategorySelectIntermediateInput
      style={style}
      label={"Category"}
      component={<SelectInputError />}
    />
  );
};

interface IntermediateSelectProps {
  component: ReactNode;
  label: string;
}

const CategorySelectIntermediateInput: SFC<IntermediateSelectProps> = ({
  style,
  component,
  label
}) => {
  return (
    <View style={style}>
      <LabelledSelectInput.Placeholder
        component={component}
        label={label}
        placeholder={"Select category..."}
        controlStyle={{ height: 52 }}
        optionStyle={{ backgroundColor: Colors.WHITE }}
      />
    </View>
  );
};

interface TaxCategoryInputData {
  loading: boolean;
  error: ApolloError | undefined;
  taxCategoryOptions: { label: string; value: string }[];
}

function useTaxCategoryData(): TaxCategoryInputData {
  const { data, loading, error } = useGetTaxCategoriesQuery();

  const taxCategoryOptions = (data?.taxCategories || [])
    .map((item) => ({ label: item.name, value: item.id }))
    .filter((item) => !["RENT", "MANAGEMENT_FEES"].includes(item.value))
    .sort((a, b) => a.label.localeCompare(b.label));

  return {
    error,
    loading: !data && loading,
    taxCategoryOptions
  };
}

export { TaxCategoryInputFormField as TaxCategoryInput };
