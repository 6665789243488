import React from "react";
import { View } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { SFC } from "@ailo/primitives";
import { DateInputFormField } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";

export const DueDateInput: SFC = ({ style }) => {
  const { control, setValue, watch, errors } =
    useFormContext<AddBillFormData>();
  const issueDate = watch("issueDate");

  return (
    <View style={style}>
      <Controller
        render={({ value, onBlur, onChange, ref }): React.ReactElement => (
          <DateInputFormField
            inputRef={ref}
            error={errors.dueDate?.message}
            webPopoverHorizontalAlign={"end"}
            onBlur={onBlur}
            onChange={(date): void => onChange(date ?? null)}
            onFocus={() => {
              setValue("fieldUnderInvoiceNumberWasFocused", true);
            }}
            label={"Due Date"}
            placeholder={"Due Date"}
            autoCorrect={false}
            autoCompleteType={"off"}
            value={value}
            minDate={issueDate ?? undefined}
            testID={"DueDateInputFormField"}
          />
        )}
        name={"dueDate"}
        defaultValue={null}
        control={control}
        rules={{
          required: "Enter due date"
        }}
      />
    </View>
  );
};
