import { GetTenantsAndPropertyDetailsQuery, Person } from "local/graphql";
import { Money } from "@ailo/ui";
import { TenancyBond } from "local/tabs";
import { BondAccount } from "local/domain/bond";

type TenancyDetailsData = {
  tenants: { firstName: string; lastName: string }[];
  propertyAddress: {
    streetName: string;
    unitStreetNumber: string;
    suburb?: string;
    postCode?: string;
    state?: string;
  };
  bond?: TenancyBond;
  bondAccount: BondAccount;
};

function parseTenancyDetailsData(
  data?: GetTenantsAndPropertyDetailsQuery
): TenancyDetailsData | undefined {
  if (!data?.tenancy?.tenantships?.items || !data?.tenancy?.property) {
    return undefined;
  }
  const tenants = data.tenancy.tenantships.items.map((tenant) => ({
    firstName: (tenant.tenant as Person).firstName,
    lastName: (tenant.tenant as Person).lastName || ""
  }));

  const address = data.tenancy.property.address;
  const bond = data.tenancy.bond;

  return {
    tenants,
    propertyAddress: {
      ...address,
      suburb: address.suburb || undefined,
      postCode: address.postcode || undefined,
      state: address.state || undefined
    },
    bond: bond
      ? {
          id: bond.id,
          amount: Money.from(bond.amount),
          reference: bond.reference || undefined,
          status: bond.status
        }
      : undefined,
    bondAccount: parseBondAccount(data)
  };
}

function parseBondAccount(
  data: GetTenantsAndPropertyDetailsQuery
): BondAccount {
  const accounts =
    data?.tenancy?.management?.managingEntity?.tenancyBondVirtualAccounts;
  if (accounts?.length !== 1) {
    throw new Error(
      "There must be exactly one bond account for a managing entity"
    );
  }
  const account = accounts[0].paymentMethodCompanion?.paymentMethod;
  if (account?.__typename !== "VirtualAccount") {
    throw new Error("Bond accounts must be virtual accounts");
  }
  if (!account?.accountNumber || !account?.bsb) {
    throw new Error("Bond accounts must have a valid account number and bsb");
  }
  return {
    accountNumber: account.accountNumber,
    bsb: account.bsb
  };
}

export { TenancyDetailsData, parseTenancyDetailsData };
