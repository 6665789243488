import { Alert, formatMoney } from "@ailo/ui";
import React, { ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";

export function AmountDueAtEndDateWarningMessage({
  amountDueAtEndDate,
  style
}: {
  amountDueAtEndDate?: number;
  style?: StyleProp<ViewStyle>;
}): ReactElement | null {
  const rentOverPaid = amountDueAtEndDate && amountDueAtEndDate < 0;
  if (!rentOverPaid) {
    return null;
  }
  return (
    <Alert
      style={style}
      type={"warning"}
      message={`A total of ${formatMoney(
        { cents: -1 * amountDueAtEndDate },
        { withSign: true }
      )} in rent has been overpaid based on the end of tenancy date`}
    />
  );
}
