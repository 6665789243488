import { AiloRN } from "@ailo/ailorn";
import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { LocalDate, Money } from "@ailo/ui";
import { ApolloError } from "@apollo/client";
import {
  GetRentReviewModalDataQueryVariables,
  useGetRentReviewModalDataQuery,
  EditableRentFragment,
  AllowedOperations
} from "local/graphql";
import { RentReviewFormData, RentReviewPrefill } from "./RentReviewFormData";

type Result = RentReviewFormData & { prefill: RentReviewPrefill };

export function useGetRentReviewData({
  editableRent,
  tenancyId
}: {
  editableRent?: EditableRentFragment;
  tenancyId: string;
}): MappedQueryResult<Result, GetRentReviewModalDataQueryVariables> {
  const result = useGetRentReviewModalDataQuery({
    variables: { tenancyId }
  });

  return useMappedQueryResult(result, (data): Result => {
    const tenancy = data?.tenancy;

    if (!tenancy) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} not found`
      });
    }

    const {
      liability,
      currentRent,
      endDate,
      mostRecentRentReview,
      leaseReviewAllowedOperation,
      scheduledRentReviewDate,
      rents
    } = tenancy;

    if (!liability) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} does not have liability`
      });
    }

    if (!liability.nextChargeCreateDate) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} does not have liability.nextChargeCreateDate`
      });
    }

    if (!currentRent && rents.pageInfo.total !== 1 && !editableRent) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} does not have currentRent and does not have exactly 1 future rent`
      });
    }

    if (!leaseReviewAllowedOperation) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} does not have leaseReviewAllowedOperation`
      });
    }

    const lastRentReview = mostRecentRentReview
      ? {
          effectiveDate: mostRecentRentReview.effectiveDate,
          rentIncreaseAmount: Money.fromCents(
            mostRecentRentReview.rentIncreaseInCents
          )
        }
      : undefined;

    const baseDetails = {
      tenancyId,
      tenancyEndDate: endDate ?? undefined,
      nextChargeCreateDate: currentRent
        ? liability.nextChargeCreateDate
        : LocalDate.fromString(liability.nextChargeCreateDate)
            .add(1, "day")
            .toString(),
      lastRentReview,
      createLeaseReviewAllowed:
        leaseReviewAllowedOperation === AllowedOperations.Create
    };

    if (editableRent) {
      const { amountInCents, ...details } = editableRent;

      const editPrefill = {
        ...details,
        amount: Money.fromCents(amountInCents),
        scheduledRentReviewDate: scheduledRentReviewDate ?? undefined
      };

      if (!editableRent.previousRent) {
        throw new ApolloError({
          errorMessage: `Attempting to edit rent ${editableRent.id} which does not have a previous rent`
        });
      }

      return {
        mode: "edit",
        rentAilorn: AiloRN.fromString(editableRent.id),
        prefill: editPrefill,
        previousRentAmount: Money.fromCents(
          editableRent.previousRent.amountInCents
        ),
        ...baseDetails
      };
    }

    const baseRent = currentRent ?? rents.items[0];

    const previousRentAmount = Money.fromCents(baseRent.amountInCents);

    const createPrefill = {
      amount: previousRentAmount,
      period: baseRent.period
    };

    return {
      mode: "create",
      prefill: createPrefill,
      previousRentAmount: Money.fromCents(baseRent.amountInCents),
      ...baseDetails
    };
  });
}
