import React from "react";
import { AiloRN } from "@ailo/ailorn";
import { useGetLegalEntityDisplayDetailsQuery } from "local/graphql";
import { Badge, LegalEntityAvatar } from "@ailo/ui";
import { didQueryNotLoadYet } from "@ailo/services";
import { Colors, Pressable, Text } from "@ailo/primitives";
import { View } from "react-native";

const SIZE = 16;

export function LegalEntityBadge({
  legalEntityAiloRN,
  onPress
}: {
  legalEntityAiloRN: AiloRN;
  onPress?: (id: string, entityType: "Person" | "Company") => void;
}): React.ReactElement | null {
  const result = useGetLegalEntityDisplayDetailsQuery({
    variables: {
      ailoRN: legalEntityAiloRN.toString()
    }
  });

  if (didQueryNotLoadYet(result)) {
    return (
      <BadgeBase leftComponent={<LegalEntityAvatar.Loading size={SIZE} />}>
        <View style={{ width: 80 }} />
      </BadgeBase>
    );
  }

  const legalEntity = result?.data?.legalEntity;

  if (!legalEntity) {
    return (
      <BadgeBase
        leftComponent={<LegalEntityAvatar.Loading size={SIZE} />}
        text={"Could not load"}
      />
    );
  }

  const name =
    (legalEntity.__typename === "Person"
      ? [legalEntity.firstName, legalEntity.lastName]
      : legalEntity.__typename === "Company"
      ? [legalEntity.registeredEntityName]
      : []
    )
      .filter(Boolean)
      .join(" ") || "Unknown";

  return (
    <Pressable
      onPress={
        onPress
          ? () => onPress(legalEntity.id, legalEntity.__typename)
          : undefined
      }
    >
      <BadgeBase
        leftComponent={
          <LegalEntityAvatar size={SIZE} legalEntity={legalEntity} />
        }
        text={name}
      />
    </Pressable>
  );
}

function BadgeBase({
  leftComponent,
  text,
  children
}: {
  leftComponent: React.ReactElement;
  text?: string;
  children?: React.ReactNode;
}): React.ReactElement {
  return (
    <Badge
      style={{ height: SIZE, borderRadius: 100, paddingLeft: 0 }}
      leftComponent={leftComponent}
    >
      {text ? (
        <Text.BodyS color={Colors.TEXT.DARK.PRIMARY}>{text}</Text.BodyS>
      ) : (
        children
      )}
    </Badge>
  );
}
