import { RentFrequency } from "@ailo/domains";
import { Badge, LocalDate, Money } from "@ailo/ui";
import { capitaliseFirstLetter } from "local/domain/utils";
import React, { ReactElement } from "react";
import { Text, Colors } from "@ailo/primitives";
import { InputDetailsSection } from "../../InputDetailsSection";
import { ManageMenu } from "./ManageMenu";

interface Props {
  tenancyId: string;
  voidedAt?: string | null;
  agreementStartDate?: string | null;
  agreementEndDate?: string | null;
  rentReviewDate?: string | null;
  rentFrequency: RentFrequency;
  rentAmount?: string;
  rentStartDate?: string;
  handleEditPress: () => void;
  handleVoidPress: () => void;
  editTenancyAbility: {
    canEdit: boolean;
    cannotEditTooltip?: string;
  };
}

export function AgreementDetailsSection({
  tenancyId,
  voidedAt,
  agreementStartDate,
  agreementEndDate,
  rentReviewDate,
  rentFrequency,
  rentAmount,
  rentStartDate,
  handleEditPress,
  handleVoidPress,
  editTenancyAbility
}: Props): ReactElement {
  const descriptionListData = [
    {
      label: "Lease type",
      description: agreementEndDate ? "Fixed term" : "Periodic"
    },
    {
      label: "Agreement start",
      description: agreementStartDate
        ? new LocalDate(agreementStartDate).format("DD MMM YYYY")
        : "N/A"
    },
    {
      label: "Agreement end",
      description: agreementEndDate
        ? new LocalDate(agreementEndDate).format("DD MMM YYYY")
        : "N/A"
    },
    {
      label: "Rent start date",
      description: rentStartDate
        ? new LocalDate(rentStartDate).format("DD MMM YYYY")
        : "N/A"
    },
    {
      label: "Rent review date",
      description: rentReviewDate
        ? new LocalDate(rentReviewDate).format("DD MMM YYYY")
        : "N/A"
    },
    {
      label: "Rent frequency",
      description: capitaliseFirstLetter(rentFrequency)
    },
    {
      label: "Rent amount",
      description: rentAmount ? Money.fromCents(rentAmount).format() : "N/A"
    }
  ];

  return (
    <InputDetailsSection
      title={
        <Text.BodyL color={Colors.TEXT.DARK.PRIMARY} weight={"medium"}>
          {"New Tenancy"}
          {!!voidedAt && (
            <Badge type={"critical"} style={{ marginLeft: 10 }}>
              {"Voided"}
            </Badge>
          )}
        </Text.BodyL>
      }
      headerRightColumn={
        <ManageMenu
          tenancyId={tenancyId}
          onEditPress={editTenancyAbility.canEdit ? handleEditPress : undefined}
          disabledEditTooltip={editTenancyAbility.cannotEditTooltip}
          onVoidPress={!voidedAt ? handleVoidPress : undefined}
        />
      }
      descriptionListData={descriptionListData}
    />
  );
}
