import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ClaimBondFormData } from "../../ClaimBondFormData";
import { Alert } from "@ailo/ui";
import { ClaimTenancyData } from "local/domain/bond";
import { useTotalClaimedValid } from "./useTotalClaimedValid";

export function ClaimedMoreThanBondError({
  data
}: {
  data: ClaimTenancyData;
}): ReactElement {
  const { control, errors } = useFormContext<ClaimBondFormData>();

  const totalClaimedValid = useTotalClaimedValid(data);

  return (
    <Controller
      name={"total"}
      control={control}
      defaultValue={null}
      rules={{
        validate: () => {
          return totalClaimedValid() || "invalid";
        }
      }}
      render={(): ReactElement => {
        const hasError = errors?.total?.message;
        return (
          <Alert
            type={"error"}
            style={{
              display: hasError ? undefined : "none",
              marginBottom: 32
            }}
          >
            {
              "The total amount for bills and/or outstanding rent cannot be more than the total bond amount. Please review your claim."
            }
          </Alert>
        );
      }}
    />
  );
}
