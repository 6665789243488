import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import {
  GetManagementNoteQueryVariables,
  ManagementNoteFragment,
  useGetManagementNoteQuery
} from "local/graphql";

export function useGetManagementNotes(
  managementId: string
): MappedQueryResult<
  ManagementNoteFragment | undefined,
  GetManagementNoteQueryVariables
> {
  const result = useGetManagementNoteQuery({
    variables: { managementId }
  });

  return useMappedQueryResult(result, (data) => {
    if (!data?.management) {
      throw new ApolloError({
        errorMessage: `[Management notes] Management "${managementId}" not found`
      });
    }

    return data.management?.note || undefined;
  });
}
