import React from "react";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";
import { TenanciesGrid } from "./TenanciesGrid";
import { useInspectionPlanner } from "../InspectionPlannerContext";

export function UnscheduledTenanciesList(): React.ReactElement | null {
  const { selectedInspectionDay } = useInspectionPlanner();
  if (selectedInspectionDay) {
    return null;
  }

  return (
    <View
      style={{
        paddingLeft: 60,
        paddingRight: 60,
        paddingTop: 32,
        flex: 1,
        backgroundColor: Colors.WHITE,
        overflow: "scroll"
      }}
    >
      <View style={{ marginBottom: 32 }}>
        <Text.BodyL weight={"medium"}>{"Unscheduled"}</Text.BodyL>
      </View>

      <TenanciesGrid />
    </View>
  );
}
