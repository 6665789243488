import { ParamListBase } from "@react-navigation/native";
import { ReportName } from "local/tabs/reports/VisualizationScreen/reportsDetails";
import { Screens } from "../Screens";

export enum ReportTypes {
  METABASE = "Metabase",
  NATIVE = "Custom"
}

export interface ReportsTabStackNavigatorParamList extends ParamListBase {
  [Screens.ReportEmbed]: {
    metabaseEmbedToken?: string;
  };
  [Screens.ReportVisulisation]: {
    reportName: ReportName;
  };
}
