import { useUnlockReconciliationPeriodMutation } from "local/graphql";
import React, { memo, ReactElement, useCallback } from "react";
import {
  ConfiguredModalProps,
  LockUnlockBaseModal
} from "./LockUnlockBaseModalComponent";

function UnlockModalComponent(props: ConfiguredModalProps): ReactElement {
  const [unlockMutation, { loading, error, data }] =
    useUnlockReconciliationPeriodMutation({
      variables: {
        trustAccountAilorn: props.period.trustAccountAilorn,
        month: props.period.month,
        year: props.period.year
      }
    });
  const onButtonPress = useCallback(() => {
    unlockMutation();
  }, [unlockMutation]);

  const errorReason =
    data?.unlockReportPeriod.failureReason ??
    (error ? "Please try again" : undefined);

  const errorText = errorReason
    ? `There was a problem unlocking this month. ${errorReason}`
    : undefined;

  return (
    <LockUnlockBaseModal
      loading={loading}
      errorText={errorText}
      actionText={"Unlock"}
      onActionPress={onButtonPress}
      {...props}
    >
      {
        "Unlocking this month will allow changes to be made to the cash book and bank statement balance. Make sure you reconcile and lock the month again to generate updated reports."
      }
    </LockUnlockBaseModal>
  );
}

export const UnlockModal = memo(UnlockModalComponent);
