import { Column } from "@ailo/primitives";
import styled from "styled-components/native";

export const ScreenContainer = styled(Column)`
  flex: 1;
  margin: 32px 60px;
`;

export const LeftColumn = styled(Column)`
  flex: 1;
  align-items: flex-start;
`;
export const RightColumn = styled(Column)`
  flex: 1;
  align-items: flex-end;
`;
