import { ParamListBase } from "@react-navigation/native";
import { Screens } from "local/common";

export enum ContactsTabView {
  CUSTOMERS = "Customers",
  SUPPLIERS = "Suppliers",
  OTHER_CONTACTS = "Other Contacts"
}

export type ContactType = "Person" | "Company" | "Other";

export interface ContactsTabStackNavigatorParamList extends ParamListBase {
  [Screens.ContactsTab]: {
    tab: ContactsTabView;
    contactId?: string;
    contactType?: ContactType;
  };
}
