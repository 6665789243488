import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ClaimBondFormData } from "../../../ClaimBondFormData";
import { Text } from "@ailo/primitives";
import { BillInputProps } from "./BillClaimInput";

export function FormControlledAmount({
  bill,
  index
}: BillInputProps): ReactElement {
  const { control } = useFormContext<ClaimBondFormData>();
  const amountFieldName = `bills.${index}.amount`;

  return (
    <Controller
      name={amountFieldName}
      control={control}
      defaultValue={bill.amount}
      render={({ value }): React.ReactElement => (
        <Text weight={"medium"} style={{ marginBottom: 2 }}>
          {value.format()}
        </Text>
      )}
    />
  );
}
