import { useToastContext } from "@ailo/ui";
import React, { ReactElement, useCallback } from "react";
import { ProjectDetailsFragment } from "local/graphql";
import { useFormProjectAnalytics } from "../../useFormProjectAnalytics";
import { useUpdateProject } from "../../useUpdateProject";
import { ProjectFormData } from "../ProjectFormData";
import { ProjectForm } from "..";

interface Props {
  project: ProjectDetailsFragment;
  onFormSubmitting: (isSubmitting: boolean) => void;
  onDismiss: () => void;
  onSuccess?: (projectId: string) => void;
  setSubmitError: (value: boolean) => void;
}

export function UpdateProjectForm({
  project,
  onFormSubmitting,
  onDismiss,
  onSuccess,
  setSubmitError
}: Props): ReactElement {
  const toast = useToastContext();
  const { onValueUpdated, onStepStarted, onUpdateSubmitSuccess } =
    useFormProjectAnalytics("Update Project");

  const [updateProject] = useUpdateProject({
    onCompleted: ({ project, initialValues, formValues }) => {
      onUpdateSubmitSuccess(project, initialValues, formValues);
      toast.show({
        type: "success",
        message: "Project updated"
      });
      onSuccess?.(project.id);
      onDismiss();
    },
    onError: () => {
      setSubmitError(true);
    }
  });

  const submit = useCallback(
    async (data: ProjectFormData): Promise<void> => {
      const { name, description, dueDate, type, files, assignee } = data;

      if (!name) {
        throw new Error("Project name cannot be blank");
      }
      if (!dueDate) {
        throw new Error("Due date cannot be blank");
      }
      if (!type) {
        throw new Error("Project type cannot be blank");
      }

      if (!assignee?.ailoRN) {
        throw new Error("Project assignee cannot be blank");
      }

      await updateProject(
        {
          id: project.id,
          name,
          description,
          dueDate,
          type,
          fileAilorns: files?.map((file) => file.id) ?? [],
          assigneeAilorn: assignee.ailoRN.toString()
        },
        getFormInitialValues(project),
        data
      );
    },
    [updateProject, project]
  );

  const cancel = useCallback((): void => {
    onDismiss();
  }, [onDismiss]);

  return (
    <ProjectForm
      type={"update"}
      onSubmit={submit}
      onCancel={cancel}
      style={{
        maxWidth: 680,
        width: "100%",
        flex: 1,
        alignSelf: "flex-end"
      }}
      initialValue={getFormInitialValues(project)}
      onValueUpdated={onValueUpdated}
      onStepStarted={onStepStarted}
      onFormSubmitting={onFormSubmitting}
    />
  );
}

export function getFormInitialValues(
  project: ProjectDetailsFragment
): ProjectFormData {
  return {
    type: project.type,
    name: project.name,
    description: project.description || undefined,
    dueDate: project.dueDate,
    files: project.files?.items.map((file) => ({
      id: file.file?.id!,
      localKey: file.id,
      fileName: file.file?.fileName || "",
      fileSize: file.file?.fileSize || 0,
      fileUri: "",
      key: file.id
    })),
    assignee: project.assignee
  };
}
