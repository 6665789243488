import React from "react";
import { Text } from "@ailo/primitives";
import { PageContent, Screens, useCurrentAgencyOrg } from "local/common";
import { ScreenComponent, useAnalytics } from "@ailo/services";
import { DashboardTitle } from "./DashboardTitle";
import { DashboardFilterBar } from "./DashboardFilterBar";
import { useGetTeamsByOrganisationQuery } from "local/graphql";
import { DashboardCardList } from "./DashboardCardList";
import { useSelectedTeamAnalytics } from "local/domain/propertyManagement";
import { AiloRN } from "@ailo/ailorn";

export const moreThanOneTeamContext = React.createContext<boolean | undefined>(
  undefined
);

function useGetAgencyTeamCount(): { data: number | null; loading: boolean } {
  const { id } = useCurrentAgencyOrg();
  const { data, loading } = useGetTeamsByOrganisationQuery({
    variables: {
      organisationId: id,
      organisationAilorn: AiloRN.of("authz:organisation", id).toString()
    }
  });

  return { loading, data: data?.organisation?.teams?.length ?? null };
}

export function DashboardScreen(): React.ReactElement {
  return (
    <ScreenComponent analyticsTrackPayload={useSelectedTeamAnalytics()}>
      <Dashboard />
    </ScreenComponent>
  );
}

function Dashboard(): React.ReactElement {
  const { data: teamCount, loading } = useGetAgencyTeamCount();
  const moreThanOneTeam = teamCount ? teamCount > 1 : undefined;
  const analytics = useAnalytics();

  if (loading) {
    return (
      <moreThanOneTeamContext.Provider value={moreThanOneTeam}>
        <Dashboard.Loading />
      </moreThanOneTeamContext.Provider>
    );
  }

  return (
    <PageContent>
      <moreThanOneTeamContext.Provider value={moreThanOneTeam}>
        <DashboardTitle />
        <DashboardFilterBar
          onTeamChange={(teams) =>
            analytics.trackScreenVisited(Screens.AnalyticsDashboardTab, {
              filteredByTeamId: teams
            })
          }
        />
        <DashboardCardList />
      </moreThanOneTeamContext.Provider>
    </PageContent>
  );
}

function DashboardLoading(): React.ReactElement {
  return (
    <PageContent>
      <Text.DisplayM weight={"medium"} style={{ marginBottom: 40 }}>
        {"Dashboard"}
      </Text.DisplayM>
      <DashboardFilterBar.Loading />
      <DashboardCardList />
    </PageContent>
  );
}

Dashboard.Loading = DashboardLoading;
