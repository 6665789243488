import React, { useState } from "react";
import { CancelFormIcon, DownCaretIcon, RefreshIcon } from "@ailo/primitives";
import {
  Button,
  DropdownMenu,
  DropdownMenuOption,
  DropdownMenuToggleProps,
  LocalDate
} from "@ailo/ui";
import { PlatformFeatureId, ManagementEndDetailsFragment } from "local/graphql";
import { isPresent } from "ts-is-present";
import { useEditManagementEndOption } from "./useEditManagementEndOption";
import { StopEndingManagementConfirmModal } from "./StopEndingManagementConfirmModal";
import { useEndManagementOption } from "./useEndManagementOption";
import { isFormerManagement } from "../../utils";
import { useHasFeature } from "@ailo/domains";
import styled from "styled-components/native";

export function ManageMenu({
  managementEndDetails
}: {
  managementEndDetails: ManagementEndDetailsFragment;
}): React.ReactElement | null {
  const showConfirmModalHook = useState(false);

  const hasRemoveManagementEndFeature = useHasFeature(
    PlatformFeatureId.RemoveManagementEnd
  );

  const endManagementOption = useEndManagementOption(managementEndDetails);

  const editEndManagementEndOption =
    useEditManagementEndOption(managementEndDetails);

  const stopEndingOrRestartManagementOption =
    getStopEndingOrRestartManagementOption({
      endDate: managementEndDetails.endDate,
      featureFlag: hasRemoveManagementEndFeature,
      onSelect: () => showConfirmModalHook[1](true)
    });

  const options = [
    endManagementOption,
    editEndManagementEndOption,
    stopEndingOrRestartManagementOption
  ].filter(isPresent);

  if (!options.length) return null;

  return (
    <>
      <DropdownMenu renderToggle={renderDropdownButton} options={options} />

      <StopEndingManagementConfirmModal
        managementEndDetails={managementEndDetails}
        showConfirmModalHook={showConfirmModalHook}
      />
    </>
  );
}

function renderDropdownButton({
  toggle,
  open
}: DropdownMenuToggleProps): React.ReactElement {
  return (
    <StyledTextButton onPress={toggle} rightIcon={DownCaretIcon} active={open}>
      {"Manage"}
    </StyledTextButton>
  );
}

const StyledTextButton = styled(Button.Text)`
  border-width: 0;
`;

function getStopEndingOrRestartManagementOption({
  endDate,
  featureFlag,
  onSelect
}: {
  endDate: ManagementEndDetailsFragment["endDate"];
  featureFlag: boolean;
  onSelect: () => void;
}): DropdownMenuOption | null {
  if (!featureFlag || !endDate) {
    return null;
  }

  const hasManagementEnded = isFormerManagement(endDate);
  const isManagementEndedAfter4Weeks = LocalDate.from(endDate).isBefore(
    LocalDate.today().subtract(4, "weeks")
  );

  const isRestartManagementDisabled =
    hasManagementEnded && isManagementEndedAfter4Weeks;

  return {
    label: hasManagementEnded ? "Restart management" : "Stop ending management",
    icon: hasManagementEnded ? <RefreshIcon /> : <CancelFormIcon />,
    disabled: isRestartManagementDisabled,
    tooltip: isRestartManagementDisabled
      ? "Contact Support to restart this former management"
      : undefined,
    onSelect
  };
}
