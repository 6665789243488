import {
  useNavigation as useReactNavigation,
  NavigationProp,
  ParamListBase
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { NavigatorsFlatParamList } from "./screenParams";
import { Screens } from "./Screens";
import { useMemo } from "react";

/**
 * Changes the behaviour of `navigate()` so that it replaces the current route params,
 * instead of merging them.
 *
 * https://github.com/react-navigation/react-navigation/issues/6674#issuecomment-665006548
 */

interface CustomHelpers {
  removeParams: (params: object) => void;
}

function useNavigationWithNavigateReplacingParams<
  T extends NavigationProp<ParamListBase> & CustomHelpers
>(): T {
  const navigation = useReactNavigation<T>();

  return useMemo((): T => {
    function getEmptyParams(): object {
      const state = navigation.dangerouslyGetState();
      const keys: string[] = Array.prototype.concat(
        ...state.routes.map((route) =>
          Object.keys((route as any)?.params || {})
        )
      );
      return keys.reduce((acc, k) => ({ ...acc, [k]: undefined }), {});
    }

    const navigate: typeof navigation.navigate = (...args: any) => {
      if (typeof args[0] === "string") {
        return navigation.navigate(args[0], {
          ...getEmptyParams(),
          ...args[1]
        });
      }

      if (typeof args[0] === "object") {
        return navigation.navigate({
          ...args[0],
          params: {
            ...getEmptyParams(),
            ...args[0].params
          }
        });
      }

      return navigation.navigate(...args);
    };

    const removeParams = (params: string[]): void => {
      navigation.setParams(
        params.reduce((acc, key) => ({ ...acc, [key]: undefined }), {})
      );
    };
    return {
      ...navigation,
      removeParams,
      navigate
    };
  }, [navigation]);
}

export type Navigation<T extends Screens = Screens> = StackNavigationProp<
  NavigatorsFlatParamList,
  T
> &
  CustomHelpers;

export function useNavigation<T extends Screens>(): Navigation<T> {
  return useNavigationWithNavigateReplacingParams();
}
