import { AiloSentry, MutationResultDataMissingError } from "@ailo/services";
import { throttle } from "lodash";
import { useRentReviewForm } from "./RentReviewFormContext";
import { RentReviewFields } from "./RentReviewFormData";
import {
  useCreateRentReviewMutation,
  useEditRentReviewMutation,
  RentFragment
} from "local/graphql";

interface Props {
  onSuccess: (createdOrEditedRent: RentFragment) => void;
  onError: () => void;
}

export function useSubmit({
  onSuccess,
  onError
}: Props): () => Promise<void> | void {
  const { handleSubmit, data } = useRentReviewForm();
  const [createRentReview] = useCreateRentReviewMutation();
  const [editRentReview] = useEditRentReviewMutation();

  return throttle(
    handleSubmit(async ({ amount, ...formDetails }: RentReviewFields) => {
      const rentDetails = {
        ...formDetails,
        amountInCents: amount.cents.toString()
      };

      try {
        if (data.mode === "edit") {
          const result = await editRentReview({
            variables: {
              input: {
                rentId: data.rentAilorn.internalId,
                setsChargeDate: false,
                ...rentDetails
              }
            }
          });

          const editedRent = result.data?.editRentReview?.rent;

          if (!editedRent) {
            throw new MutationResultDataMissingError(
              "editRentReview.rent",
              result
            );
          }

          await onSuccess(editedRent);

          return;
        }

        const result = await createRentReview({
          variables: {
            tenancyId: data.tenancyId,
            rentDetails
          }
        });

        const createdRent = result.data?.createRentReview?.rent;

        if (!createdRent) {
          throw new MutationResultDataMissingError(
            "createRentReview.rent",
            result
          );
        }

        await onSuccess(createdRent);
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }
    }),
    500
  );
}
