import { useMemo } from "react";
import { BadgeType } from "@ailo/ui";
import { PortfolioPropertyOccupancy } from "./useGetManagementPortfolioProperties";
import { OccupancyStatus } from "local/graphql";
import { formatDate } from "@ailo/services";

export interface ManagementPortfolioPropertyOccupancySummary {
  type: BadgeType;
  status: string;
}

export function useGetManagementPortfolioPropertyOccupancySummary(
  occupancy: PortfolioPropertyOccupancy
): ManagementPortfolioPropertyOccupancySummary | null {
  return useMemo(() => {
    const occupancyStatus = occupancy.occupancyStatus;
    const mostRecentTenancyEndDate = occupancy.mostRecentTenancy?.endDate;
    if (
      !occupancyStatus ||
      occupancyStatus === OccupancyStatus.Occupied ||
      !mostRecentTenancyEndDate
    ) {
      return null;
    }
    return {
      type:
        occupancyStatus === OccupancyStatus.Vacating ? "attention" : "default",
      status: `${occupancyStatus}  ·  ${formatDate(mostRecentTenancyEndDate)}`
    };
  }, [occupancy]);
}
