import { AiloRN, services } from "@ailo/ailorn";
import { useMemo } from "react";
import { useGetBillManagementIdQuery } from "local/graphql";

export interface BillAnalyticsPayload {
  billId: string;
  managementId?: string;
}

export function useBillScreenAnalyticsPayload({
  billId
}: {
  billId?: string;
}): BillAnalyticsPayload | undefined {
  const result = useGetBillManagementIdQuery({
    skip: !billId,
    variables: billId ? { billId } : undefined
  });

  const managementId = result.data?.billById?.management?.id;

  const analyticsPayload = useMemo(() => {
    if (result.loading || !billId) {
      return undefined;
    }

    const billAiloRN = AiloRN.of(services.Bill.bill, billId).toString();
    const managementAiloRN = managementId
      ? AiloRN.of(
          services.PropertyManagement.management,
          managementId
        ).toString()
      : undefined;

    return {
      billId: billAiloRN,
      managementId: managementAiloRN
    };
  }, [billId, managementId, result.loading]);

  return analyticsPayload;
}
