import {
  ErrorAlertScreen,
  LocalDate,
  Money,
  Separator,
  useToastContext
} from "@ailo/ui";
import React, { useCallback, useState } from "react";
import { RentAdjustmentSidebarHeader } from "./RentAdjustmentSidebarHeader";
import { RentAdjustmentSidebarDetails } from "./RentAdjustmentSidebarDetails";
import { useGetAdjustmentDetailsQuery } from "local/graphql";
import { AdjustmentEntryDetails } from "./AdjustmentEntryDetails";
import { ReverseRentAdjustmentModal } from "../ReverseRentAdjustmentModal";
import { adjustmentTypeFromAmount } from "./adjustmentTypeFromAmount";

interface Props {
  adjustmentId: string;
  tenancyId: string;
  onDismiss: () => void;
}

export function RentAdjustmentSidebar({
  adjustmentId,
  tenancyId,
  onDismiss
}: Props): React.ReactElement {
  const [showReversalModal, setShowReversalModal] = useState(false);
  const toast = useToastContext();
  const { data, loading, error, refetch } = useGetAdjustmentDetailsQuery({
    variables: {
      liabilityEntryId: adjustmentId,
      tenancyId: tenancyId
    }
  });

  const onSuccess = useCallback(() => {
    toast.show({
      type: "success",
      message: "Rent credit reversed"
    });
    setShowReversalModal(false);
  }, [toast]);

  if (loading) {
    return <RentAdjustmentSidebarLoading onDismiss={onDismiss} />;
  }

  if (
    error ||
    data?.liabilityEntry?.__typename !== "AdjustmentLiabilityEntry"
  ) {
    return (
      <ErrorAlertScreen
        style={{ minHeight: 300 }}
        title={`There was a problem loading
        Rent Adjustment Details`}
        onRetry={refetch}
      />
    );
  }

  const { liabilityEntry, tenancy } = data;

  const adjustmentDetails: AdjustmentEntryDetails = {
    amount: Money.from(liabilityEntry.amount),
    createdAt: LocalDate.from(liabilityEntry.createdAt, {
      keepTimeZone: false
    }),
    effectiveAt: LocalDate.from(liabilityEntry.effectiveAt, {
      keepTimeZone: false
    }),
    description:
      liabilityEntry.descriptionIsCustom && liabilityEntry.description
        ? liabilityEntry.description
        : undefined,
    reversed: !!liabilityEntry.reversedByAdjustment
  };

  const canBeReversed =
    !liabilityEntry.reversedByAdjustment &&
    !liabilityEntry.reversesAdjustment &&
    adjustmentTypeFromAmount(adjustmentDetails.amount) === "Credit" &&
    liabilityEntry.createdBy?.__typename === "User";

  const { reversedByAdjustment } = liabilityEntry;
  return (
    <>
      <RentAdjustmentSidebarHeader
        adjustmentDetails={adjustmentDetails}
        address={tenancy?.property.address}
        onClose={onDismiss}
        onReverseButtonPress={
          canBeReversed
            ? (): void => {
                setShowReversalModal(true);
              }
            : undefined
        }
      />
      <Separator style={{ marginLeft: 0 }} />

      <RentAdjustmentSidebarDetails
        adjustmentDetails={adjustmentDetails}
        reversalDetails={
          reversedByAdjustment
            ? {
                effectiveAt: LocalDate.from(reversedByAdjustment.effectiveAt, {
                  keepTimeZone: false
                }),
                description:
                  reversedByAdjustment.descriptionIsCustom &&
                  reversedByAdjustment.description
                    ? reversedByAdjustment.description
                    : undefined
              }
            : undefined
        }
      />

      {showReversalModal && (
        <ReverseRentAdjustmentModal
          adjustmentId={adjustmentId}
          amount={adjustmentDetails.amount}
          onClose={() => setShowReversalModal(false)}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}

function RentAdjustmentSidebarLoading({
  onDismiss
}: {
  onDismiss(): void;
}): React.ReactElement {
  return (
    <>
      <RentAdjustmentSidebarHeader.Loading onClose={onDismiss} />
      <Separator style={{ marginLeft: 0 }} />
      <RentAdjustmentSidebarDetails.Loading />
    </>
  );
}
