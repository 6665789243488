import React, { ReactElement, useMemo } from "react";
import { ExtendElementProps } from "@ailo/primitives";
import { useCreateTrustAccountAdjustmentMutation } from "local/graphql";
import { Alert, BaseModal, LocalDate, Money, useToastContext } from "@ailo/ui";
import { ModalFooter, PleaseWaitTitle } from "../components";
import { AddAdjustmentForm, useAddAdjustmentForm } from "./AddAdjustmentForm";
import { ReconciliationPeriod } from "../ReconciliationViewScreen/types";
import styled from "styled-components/native";
import { View } from "react-native";
import { clearTrustAccountReconciliationAdjustmentsCache } from "../graphql/clearTrustAccountReconciliationAdjustmentsCache";

type AddAdjustmentModalProps = ExtendElementProps<
  {
    period: ReconciliationPeriod;
    onCancelPress?: () => void;
    onAddPress?: () => void;
  },
  typeof BaseModal
>;

export function AddAdjustmentModal({
  period,
  children,
  onCancelPress,
  onAddPress,
  ...rest
}: AddAdjustmentModalProps): ReactElement {
  const toast = useToastContext();
  const [createAdjustment, { loading, error }] =
    useCreateTrustAccountAdjustmentMutation({
      refetchQueries: ["listTrustAccountAdjustments"],
      update: clearTrustAccountReconciliationAdjustmentsCache
    });
  const formMethods = useAddAdjustmentForm();
  const onActionPress = useMemo(
    () =>
      formMethods.handleSubmit(async ({ amount, applyOn, details }) => {
        const result = await createAdjustment({
          variables: {
            input: {
              reportPeriodId: period.id,
              amountInCents: Money.fromDollars(amount).cents,
              applyOn: LocalDate.from(applyOn).toString(),
              details
            }
          }
        });

        if (!result.errors) {
          onAddPress?.();
          toast.show({
            type: "success",
            message: "Adjustment has been added"
          });
        }
      }),
    [createAdjustment, formMethods, onAddPress, period.id, toast]
  );
  const footer = (
    <ModalFooter
      cancel={"Cancel"}
      onCancelPress={onCancelPress}
      action={loading ? undefined : "Add"}
      onActionPress={onActionPress}
    />
  );
  return (
    <BaseModal
      title={loading ? <PleaseWaitTitle /> : "Add adjustment"}
      footer={footer}
      showFooterBorder
      {...rest}
    >
      {error && <ErrorAlert />}
      <FormContainer>
        <AddAdjustmentForm {...formMethods} period={period} />
      </FormContainer>
    </BaseModal>
  );
}

const FormContainer = styled(View)`
  margin-bottom: 40px;
`;

const ErrorAlert = styled(Alert).attrs({
  type: "warning",
  children: "There was a problem adding the adjustment.\nPlease try again."
})`
  margin-top: 12px;
  margin-bottom: 4px;
`;
