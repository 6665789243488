import { ClaimTenancyData } from "local/domain/bond";
import React, { ReactElement } from "react";
import { SecondaryText } from "../SecondaryText";
import { Row } from "local/common";
import { TenantChips } from "local/domain/propertyManagement";
import { isPresent } from "ts-is-present";

export function TenantsDetails({
  data
}: {
  data: ClaimTenancyData;
}): ReactElement {
  return (
    <>
      <SecondaryText style={{ marginBottom: 4 }}>{"Renters"}</SecondaryText>
      <Row
        style={{ justifyContent: "flex-start", flexWrap: "wrap", width: "70%" }}
      >
        <TenantChips tenants={data.tenants.filter(isPresent)} />
      </Row>
    </>
  );
}
