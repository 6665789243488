import React from "react";
import {
  formatRentPaymentSchedule,
  RentScheduleDomainType
} from "@ailo/domains";
import { SFC } from "@ailo/primitives";
import { formatDateDefaultToNA } from "@ailo/services";
import { DescriptionList } from "@ailo/ui";
import { formatPaidToDate } from "./formatPaidToDate";
import { isPresent } from "ts-is-present";

interface Props {
  rentSchedule: RentScheduleDomainType;
  paidToDate?: string | null;
}

export const NextRentDetails: SFC<Props> = ({
  style,
  rentSchedule,
  paidToDate
}) => {
  return (
    <DescriptionList
      style={style}
      size={"small"}
      items={[
        {
          label: "Next rent amount",
          description: formatRentPaymentSchedule(rentSchedule)
        },
        {
          label: "Next rent date",
          description: formatDateDefaultToNA(rentSchedule.startDate)
        },
        paidToDate
          ? {
              label: "Paid to date",
              description: formatPaidToDate(paidToDate)
            }
          : null
      ].filter(isPresent)}
      testID={"next-rent-details-section"}
    />
  );
};
