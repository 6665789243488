import { useGenerateHelpUrl, useHasFeature } from "@ailo/domains";
import {
  Colors,
  HelpIcon,
  LogoutIcon,
  Text,
  ExternalLinkIcon,
  SettingsIcon
} from "@ailo/primitives";
import { useAuth } from "@ailo/services";
import {
  DropdownMenu,
  DropdownMenuOption,
  DropdownMenuToggleProps
} from "@ailo/ui";
import { Screens, useNavigation } from "local/common";
import React from "react";
import { Platform, View } from "react-native";
import { CurrentUserDetailsButton } from "./CurrentUserDetailsButton";
import { PlatformFeatureId } from "local/graphql";

export function ProfileMenu(): React.ReactElement | null {
  const { logout } = useAuth();
  const generateHelpUrl = useGenerateHelpUrl();
  const navigation = useNavigation();

  const options: DropdownMenuOption[] = [];

  const hasSettingsTabScreen = useHasFeature(PlatformFeatureId.FeeBlueprints);
  if (hasSettingsTabScreen)
    options.push({
      label: "Settings",
      icon: <SettingsIcon />,
      onSelect: (): void => {
        navigation.navigate(Screens.SettingsTab, {
          tab: "fees",
          id: undefined
        });
      }
    });

  options.push({
    label: (
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text.BodyM>{"Help"}</Text.BodyM>
        <ExternalLinkIcon height={20} width={20} color={Colors.SMOKE} />
      </View>
    ),
    icon: <HelpIcon />,
    onSelect: async (): Promise<void> => {
      Platform.OS === "web" && window.open(await generateHelpUrl(), "_blank");
    }
  });

  options.push({
    label: "Logout",
    icon: <LogoutIcon />,
    onSelect: (): void => {
      logout();
    }
  });

  return <DropdownMenu renderToggle={renderDropdownButton} options={options} />;
}

function renderDropdownButton({
  toggle,
  open
}: DropdownMenuToggleProps): React.ReactElement {
  return <CurrentUserDetailsButton onPress={toggle} active={open} />;
}
