import { Column, Row } from "@ailo/primitives";
import React, { memo, ReactElement } from "react";
import styled from "styled-components/native";
import { LeftColumn, RightColumn, ScreenContainer } from "../components";

type LayoutProps = {
  title: ReactElement;
  breadcrumbs: ReactElement;
  balancedBadge: ReactElement;
  lockButton: ReactElement;
  bankAccountCard: ReactElement;
  ledgerBalancesComponent: ReactElement;
};

function PageLayoutComponent(props: LayoutProps): ReactElement {
  return (
    <ScreenContainer>
      <HeaderContainer>
        <LeftColumn>
          {props.breadcrumbs}
          <TitleContainer>{props.title}</TitleContainer>
          {props.balancedBadge}
        </LeftColumn>
        <RightColumn>{props.lockButton}</RightColumn>
      </HeaderContainer>
      <Column>{props.bankAccountCard}</Column>
      <LedgerBalancesColumn>
        {props.ledgerBalancesComponent}
      </LedgerBalancesColumn>
    </ScreenContainer>
  );
}

export const PageLayout = memo(PageLayoutComponent);

const HeaderContainer = styled(Row)`
  margin-bottom: 40px;
`;

const TitleContainer = styled(Row)`
  margin: 8px 0;
`;

const LedgerBalancesColumn = styled(Column)`
  padding-top: 32px;
  padding-bottom: 32px;
`;
