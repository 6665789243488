import React from "react";
import { useGlobalModal } from "@ailo/ui";
import { EmptyCardContent } from "../EmptyCardContent";
import { StyleProp, ViewStyle } from "react-native";
import { TenancyModalForm } from "local/modals/TenancyModalForm";
import { PropertyContentCard } from "local/tabs";

interface Props {
  managementId: string;
  style?: StyleProp<ViewStyle>;
}

export function AddNewTenancyCard({
  managementId,
  style
}: Props): React.ReactElement {
  const modal = useGlobalModal();

  const handleButtonPress = (): void => {
    modal.show(
      <TenancyModalForm
        managementId={managementId}
        onDismiss={modal.hide}
        onSuccess={modal.hide}
      />
    );
  };
  return (
    <PropertyContentCard title={"Tenancy"} style={style}>
      <EmptyCardContent
        text={"Add a new tenancy to this property"}
        buttonText={"Create New Tenancy"}
        onButtonPress={handleButtonPress}
      />
    </PropertyContentCard>
  );
}
