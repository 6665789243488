import { DropdownMenuOption, useToastContext } from "@ailo/ui";
import { useProjectAnalytics } from "local/domain/project";
import { ProjectFile } from "./getFiles";
import { useRemoveFiles } from "./useRemoveFiles";

export function useRemoveFileOption({
  projectId,
  file,
  icon,
  onDelete
}: {
  projectId: string;
  file: ProjectFile;
  icon: React.ReactElement;
  onDelete: (fileId: string) => void;
}): DropdownMenuOption {
  const toast = useToastContext();

  const { onFileRemoved } = useProjectAnalytics(projectId);

  const [onDeletePressed] = useRemoveFiles({
    onError: () => {
      toast.show({
        type: "error",
        message: "Unable to delete file. Please try again."
      });
    },
    onCompleted: () => {
      onDelete(file.id);
      toast.show({
        type: "success",
        message: "File deleted"
      });
      onFileRemoved(file);
    }
  });

  return {
    label: "Delete file",
    icon,
    onSelect: (): void => onDeletePressed(projectId, [file.projectFileId])
  };
}
