import React, { ReactElement } from "react";
import { UseFormMethods } from "react-hook-form";
import { NewTenancyFormData } from "../NewTenancyForm";
import styled from "styled-components/native";
import { BaseReviewDetailsSection } from "./BaseReviewDetailsSection";
import { DetailsField } from "./DetailsField";
import { Money } from "@ailo/ui";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";

interface Props {
  form: UseFormMethods<NewTenancyFormData>;
  onEditPress?(): void;
}

export function DepositDetailsSection({
  form,
  onEditPress
}: Props): ReactElement {
  const formData = form.getValues();
  return (
    <BaseReviewDetailsSection
      title={"Initial Payment"}
      onEditPress={onEditPress}
    >
      {formData.depositEnabled ? (
        <StyledDetailsField
          label={"Amount"}
          value={
            formData.depositAmount
              ? Money.from(formData.depositAmount).format()
              : undefined
          }
        />
      ) : (
        <View>
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
            {"Do not collect"}
          </Text.BodyS>
        </View>
      )}
    </BaseReviewDetailsSection>
  );
}

const StyledDetailsField = styled(DetailsField)`
  margin-bottom: 8px;
`;
