import { GridSortOrder } from "@ailo/ui";
import { ProjectStatus } from "local/graphql";
import React from "react";
import {
  AssigneeCell,
  DueDateCell,
  ProgressCell,
  ProjectCell,
  PropertyCell
} from "../Cells";
import { GridProjectList, ProjectListProps } from "./GridProjectList";
import { useGetProjectRows } from "./useGetProjectRows";
import { useProjectFilters } from "./useProjectFilters";
import { useProjectListSortState } from "./useProjectListReorder";

function DefaultProjectList({
  tabNavigation,
  status
}: {
  tabNavigation: React.ReactElement;
  status: ProjectStatus;
}): React.ReactElement {
  const { filterElements, isAnyFilterActive, filterState } =
    useProjectFilters();

  const [sortOrder, setSortOrder] = useProjectListSortState(initialSortOrder);

  const queryData = useGetProjectRows({
    status,
    filterVariables: filterState,
    sortOrder
  });

  const emptyMessage = isAnyFilterActive
    ? "We couldn't find any matches"
    : `${status} projects`;

  const secondaryEmptyMessage = isAnyFilterActive
    ? "Please try a different search term or filter"
    : `You have no ${status?.toLowerCase()} projects to manage`;

  return (
    <GridProjectList
      filterElements={filterElements}
      isAnyFilterActive={isAnyFilterActive}
      queryData={queryData}
      tabNavigation={tabNavigation}
      errorMessage={`There was a problem loading ${status?.toLowerCase()} projects`}
      emptyMessage={emptyMessage}
      secondaryEmptyMessage={secondaryEmptyMessage}
      columns={columns}
      sortOrder={sortOrder}
      onSortOrderChange={setSortOrder}
    />
  );
}

enum ProjectListColumns {
  Project = "Project",
  Property = "Property",
  Assignee = "Assignee",
  Progress = "Progress",
  DueDate = "Due date"
}

const initialSortOrder: GridSortOrder = {
  columnKey: ProjectListColumns.DueDate,
  direction: "ASC"
};

const columns: ProjectListProps["columns"] = [
  {
    width: 2,
    renderCell: ProjectCell,
    renderLoadingCell: ProjectCell.Loading,
    name: ProjectListColumns.Project
  },
  {
    width: 2,
    renderCell: PropertyCell,
    renderLoadingCell: PropertyCell.Loading,
    name: ProjectListColumns.Property,
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: AssigneeCell,
    renderLoadingCell: AssigneeCell.Loading,
    name: ProjectListColumns.Assignee,
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: ProgressCell,
    renderLoadingCell: ProgressCell.Loading,
    name: ProjectListColumns.Progress,
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: DueDateCell,
    renderLoadingCell: DueDateCell.Loading,
    name: ProjectListColumns.DueDate,
    verticalAlign: "middle",
    sortable: true,
    caretPosition: "right"
  }
];

export { DefaultProjectList };
