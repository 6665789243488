import { AiloRN } from "@ailo/ailorn";
import { RecurringDate } from "@ailo/date";
import { formatAddress } from "@ailo/domain-helpers";
import { LocalDate, Money } from "@ailo/ui";
import {
  FeeFrequency,
  ManagementFeeScheduleFieldsFragment,
  RecurringFeeFieldsFragment,
  TaxTreatment
} from "local/graphql";
import { Fee } from "../graphql/useGetExpense";
import { EditExpenseModalFormData } from "./types";

export type EditManagementFeeScheduleDetail = {
  type: "ManagementFeeSchedule";
  ailorn: AiloRN;
  name: string;
  percentage: number;
  managementAilorn: AiloRN;
  managementAgreementAilorn: AiloRN;
  propertyAddress?: string;
  chargeDescription: string;
};

export type EditRecurringFeeDetail = {
  type: "RecurringFee";
  ailorn: AiloRN;
  name: string;
  managementAilorn: AiloRN;
  totalAmount: Money;
  description?: string;
  frequency: FeeFrequency;
  displayFrequency: string;
  nextOccurrence?: {
    date: string;
    totalAmount: Money;
  };
  blueprint: {
    ailorn: AiloRN;
    amount: Money;
    amountIncludingTax: Money;
  };
  propertyAddress?: string;
  chargeDescription?: string;
};

export type EditOneOffFeeDetail = {
  type: "OneOffFee";
  ailorn: AiloRN;
  name: string;
  managementAilorn: AiloRN;
  totalAmount: Money;
  percentage?: number;
  description?: string;
  blueprint: {
    ailorn: AiloRN;
    amount?: Money;
    amountIncludingTax?: Money;
    oneWeekRentPercentage?: number;
    oneWeekRentPercentageIncludingTax?: number;
  };
  currentWeeklyRent?: Money;
  propertyAddress?: string;
  chargeDescription?: string;
};

export type EditExpenseDetail =
  | EditManagementFeeScheduleDetail
  | EditRecurringFeeDetail
  | EditOneOffFeeDetail;

export function buildEditManagementFeeScheduleDetail(
  schedule: ManagementFeeScheduleFieldsFragment
): EditManagementFeeScheduleDetail {
  const address = schedule.management.property.address;
  const propertyAddress = address
    ? formatAddress(address, {
        format: "street, suburb"
      })
    : undefined;

  return {
    type: "ManagementFeeSchedule",
    ailorn: AiloRN.fromString(schedule.ailorn),
    name: "Management fee",
    percentage: schedule.percent,
    managementAilorn: AiloRN.fromString(schedule.management.ailorn),
    managementAgreementAilorn: AiloRN.fromString(
      schedule.managementAgreement.ailorn
    ),
    propertyAddress,
    chargeDescription: "This fee is issued when rent is received"
  };
}

export function buildEditRecurringFeeDetail(
  fee: RecurringFeeFieldsFragment
): EditRecurringFeeDetail {
  const address = fee.management?.property.address;
  const propertyAddress = address
    ? formatAddress(address, {
        format: "street, suburb"
      })
    : undefined;
  if (!fee.management?.ailorn)
    throw new Error("Insufficient access to management");

  if (!fee.blueprint.fixedAmount)
    throw new Error("Recurring fee must have a fixedAmount");

  const nextOccurrenceDate = fee.nextOccurrence
    ? LocalDate.from(fee.nextOccurrence?.date)
    : undefined;

  const recurringDate = new RecurringDate({
    frequency: fee.frequency,
    startDate: fee.startDate
  });

  return {
    type: "RecurringFee",
    ailorn: AiloRN.fromString(fee.ailorn),
    name: fee.blueprint.name,
    totalAmount: Money.from(
      fee.nextOccurrence?.taxInclusiveAmount ||
        fee.currentOrNextSchedule?.taxInclusiveAmount || { cents: 0 }
    ),
    description: fee.description ?? undefined,
    frequency: fee.frequency,
    displayFrequency: recurringDate.format({ type: "/FFFF" }),
    managementAilorn: AiloRN.fromString(fee.management.ailorn),
    blueprint: {
      ailorn: AiloRN.fromString(fee.blueprint.ailorn),
      amount: Money.from(fee.blueprint.fixedAmount),
      amountIncludingTax: Money.from(fee.blueprint.fixedAmount).multiply(
        fee.blueprint.taxTreatment === TaxTreatment.Exclusive ? 1.1 : 1
      )
    },
    nextOccurrence: fee.nextOccurrence
      ? {
          date: fee.nextOccurrence.date,
          totalAmount: Money.from(fee.nextOccurrence.taxInclusiveAmount)
        }
      : undefined,
    propertyAddress,
    chargeDescription: nextOccurrenceDate
      ? `This fee is issued ${recurringDate.format({
          type: "F-ly on D"
        })}. The next issue date is ${nextOccurrenceDate.format(
          "MMMM D, YYYY"
        )}`
      : undefined
  };
}

export function buildEditOneOffFeeDetail(fee: Fee): EditOneOffFeeDetail {
  if (!fee.blueprint) {
    throw new TypeError(
      "One Off Fee needs to be associated with a fee blueprint. Make sure this isn't a bill management fee."
    );
  }

  return {
    type: "OneOffFee",
    ailorn: fee.ailorn,
    name: fee.blueprint.name,
    totalAmount: fee.totalAmount,
    percentage: fee.percentage,
    description: fee.description,
    managementAilorn: fee.managementAilorn,
    blueprint: {
      ailorn: fee.blueprint.ailorn,
      amount: fee.blueprint.amount,
      amountIncludingTax: fee.blueprint.amountIncludingTax,
      oneWeekRentPercentageIncludingTax:
        fee.blueprint.oneWeekRentPercentageIncludingTax
    },
    currentWeeklyRent: fee.currentWeeklyRent,
    propertyAddress: fee.propertyNameWithSuburb,
    chargeDescription:
      "This fee will be paid automatically when funds are available"
  };
}

export function buildEditExpenseDetail(
  expense: EditExpenseModalFormData
): EditExpenseDetail {
  return expense.expenseType === "ManagementFeeSchedule"
    ? buildEditManagementFeeScheduleDetail(expense)
    : expense.expenseType === "RecurringFee"
    ? buildEditRecurringFeeDetail(expense)
    : buildEditOneOffFeeDetail(expense);
}
