import { View } from "react-native";
import { Text } from "@ailo/primitives";
import React, { ReactElement } from "react";

export function DisbursementsHeader(): ReactElement {
  return (
    <View style={{ marginTop: 32, marginBottom: 40 }}>
      <Text.DisplayM style={{ marginBottom: 16 }}>
        {"Disbursements"}
      </Text.DisplayM>
    </View>
  );
}
