import React from "react";
import { ApolloLink } from "@apollo/client";
import { ApolloSetup, ApolloSetupProps } from "@ailo/services";

interface AgencyApolloSetupProps extends Omit<ApolloSetupProps, "links"> {}

export function AgencyApolloSetup(
  props: AgencyApolloSetupProps
): React.ReactElement {
  return <ApolloSetup links={links} {...props} />;
}

const links: ApolloLink[] = [];
