import { DownCaretIcon } from "@ailo/primitives";
import {
  Button,
  DropdownMenu,
  DropdownMenuOptionGroup,
  DropdownMenuToggleProps
} from "@ailo/ui";
import React from "react";
import { isPresent } from "ts-is-present";
import { BondMenuOptions, useGetBondOptionsGroup } from "../BaseTenancyCard";
import { getEditIngoingTenancyOption } from "./getEditIngoingTenancyOption";
import { useGetRentAdjustmentsOption } from "../manageMenuOptions";
import { useGetVoidIngoingTenancyOption } from "local/modals/VoidTenancyConfirmModal";
import styled from "styled-components/native";

export function ManageMenu({
  tenancyId,
  onEditPress,
  onRentAdjustmentsPress,
  onVoidPress,
  bondMenuOptions
}: {
  tenancyId: string;
  onEditPress?: () => void;
  onVoidPress?: () => void;
  onRentAdjustmentsPress?: () => void;
  bondMenuOptions: BondMenuOptions;
}): React.ReactElement | null {
  const editIngoingTenancyOption = getEditIngoingTenancyOption(onEditPress);

  const rentAdjustmentsOption = useGetRentAdjustmentsOption({
    onSelect: (): void => onRentAdjustmentsPress?.(),
    disabled: !onRentAdjustmentsPress
  });

  const voidIngoingTenancyOption = useGetVoidIngoingTenancyOption(
    tenancyId,
    onVoidPress
  );

  const tenancyOptionsGroup: DropdownMenuOptionGroup = {
    options: [
      rentAdjustmentsOption,
      editIngoingTenancyOption,
      voidIngoingTenancyOption
    ].filter(isPresent),
    type: "group"
  };

  const bondOptionsGroup = useGetBondOptionsGroup(bondMenuOptions);

  const options = [tenancyOptionsGroup, bondOptionsGroup].filter(isPresent);

  return <DropdownMenu renderToggle={renderDropdownButton} options={options} />;
}

function renderDropdownButton({
  toggle,
  open
}: DropdownMenuToggleProps): React.ReactElement {
  return (
    <StyledTextButton onPress={toggle} rightIcon={DownCaretIcon} active={open}>
      {"Manage"}
    </StyledTextButton>
  );
}

const StyledTextButton = styled(Button.Text)`
  border-width: 0;
`;
