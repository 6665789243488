import { useCurrentAgencyOrg } from "local/common";
import {
  useGetMigratingManagementPortfolioNamesQuery,
  MigratingManagementStatus
} from "local/graphql";
import { ApolloError } from "@apollo/client";
import { useMemo } from "react";

interface Params {
  status?: MigratingManagementStatus | null;
}

interface GetMigratingManagementPortfolioNames {
  portfolioNames: string[];
  hasUnassigned: boolean;
  loading: boolean;
  error?: ApolloError;
  refetch: () => void;
}

export function useGetMigratingManagementPortfolioNames({
  status
}: Params): GetMigratingManagementPortfolioNames {
  const { id: organisationId } = useCurrentAgencyOrg();

  const { data, loading, error, refetch } =
    useGetMigratingManagementPortfolioNamesQuery({
      variables: { organisationId, status }
    });

  const portfolioNames = useMemo(() => {
    const unsortedPortfolioNames =
      data?.organisation?.migratingManagementPortfolioNames?.names ?? [];
    return unsortedPortfolioNames.slice().sort((a, b) => a.localeCompare(b));
  }, [data?.organisation?.migratingManagementPortfolioNames?.names]);

  const hasUnassigned =
    data?.organisation?.migratingManagementPortfolioNames?.hasUnassigned ??
    false;

  return {
    portfolioNames,
    hasUnassigned,
    loading,
    error,
    refetch
  };
}
