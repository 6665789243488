import React, { ReactElement } from "react";
import { DateTimeWithTimeZone, TextCell } from "@ailo/ui";
import { ApprovedMigratingManagementFragment } from "local/graphql";

function ApprovedOnCell({
  row: { approvedAt }
}: {
  row: ApprovedMigratingManagementFragment;
  rowIndex: number;
}): ReactElement | null {
  if (!approvedAt) return null;
  const ailoApprovedAt = DateTimeWithTimeZone.from(approvedAt);

  return <TextCell>{ailoApprovedAt.format("D MMM YYYY")}</TextCell>;
}

ApprovedOnCell.Loading = TextCell.Loading;

export { ApprovedOnCell };
