import { DateRange, LocalDate, RecurringDate } from "@ailo/date";
import { formatAddress, formatPaymentMethodName } from "@ailo/domain-helpers";
import { Colors, Text } from "@ailo/primitives";
import { DescriptionList, Money } from "@ailo/ui";
import React from "react";
import { GetLegalEntityDetailsQuery } from "local/graphql";
import { SidebarSection } from "./SidebarSection";

export function TenantMoneySettingsSection({
  data
}: {
  data: GetLegalEntityDetailsQuery;
}): React.ReactElement | null {
  if (
    !data.legalEntity?.tenancies ||
    data.legalEntity.tenancies.items.length === 0
  ) {
    return null;
  }

  return (
    <SidebarSection title={"Renter Settings"}>
      {data.legalEntity!.tenancies?.items.map((tenancy, tenancyIndex) => {
        const liabilityPaymentPlans = (
          data.liabilityPaymentPlans?.items ?? []
        ).filter(
          (plan) =>
            tenancy.liability?.id && plan.liabilityId === tenancy.liability?.id
        );
        const autopayText = liabilityPaymentPlans.length
          ? "Rent paid using payment schedules"
          : tenancy.liability?.autoPaymentDetails
          ? [
              "Rent paid when due",
              tenancy.liability.autoPaymentDetails.paymentMethod &&
                formatPaymentMethodName(
                  tenancy.liability.autoPaymentDetails.paymentMethod,
                  {
                    type: "short-name"
                  }
                )
            ]
              .filter(Boolean)
              .join("\n")
          : "Switched off";

        const paymentSchedulesText = !liabilityPaymentPlans.length
          ? "Switched off"
          : liabilityPaymentPlans
              .filter((plan) => plan.liabilityId === tenancy.liability?.id)
              .map((plan) => {
                const dateRange = new DateRange({
                  start: LocalDate.from(plan.details.startDate),
                  end: plan.details.endDate
                    ? LocalDate.from(plan.details.endDate)
                    : undefined
                });
                const recurringDate = new RecurringDate({
                  frequency: plan.details.frequency,
                  startDate: plan.details.startDate
                });
                return [
                  `${Money.from(plan.details.amount)} ${recurringDate.format({
                    type: "/FF"
                  })}`,
                  `Next payment ${
                    recurringDate
                      .getNextOccurrenceInDateRange(dateRange)
                      ?.format("DD MMM") ?? "-"
                  }`,
                  dateRange.end && `End date: ${dateRange.end?.format()}`,
                  plan.details.paymentMethod &&
                    formatPaymentMethodName(plan.details.paymentMethod, {
                      type: "short-name"
                    })
                ]
                  .filter(Boolean)
                  .join("\n");
              })
              .join("\n\n");

        return (
          <React.Fragment key={tenancy.id}>
            {data.legalEntity!.tenancies!.items.length > 0 && (
              <Text.BodyS
                weight={"medium"}
                color={Colors.CHARCOAL}
                style={{
                  marginTop: tenancyIndex > 0 ? 24 : 0,
                  marginBottom: 8
                }}
              >
                {formatAddress(tenancy.management!.property.address)}
              </Text.BodyS>
            )}
            <DescriptionList
              size={"small"}
              items={[
                {
                  label: "Autopay",
                  description: autopayText
                },
                {
                  label: "Payment schedules",
                  description: paymentSchedulesText
                }
              ]}
            />
          </React.Fragment>
        );
      })}
    </SidebarSection>
  );
}
