import { useGetPropertyKey } from "local/domain/propertyManagement/hooks/useGetPropertyKey";
import { KeyFragment } from "local/graphql";
import React from "react";
import { PropertyKeysFormContent } from "../PropertyKeyFormContent";
import { useUpdatePropertyKey } from "./useUpdatePropertyKey";

type UpdateKeyFormProps = {
  editKey: KeyFragment;
  onDismiss: () => void;
};

const FORM_SUBMIT_TEXT = "Update keys";

export function UpdateKeyForm({
  editKey,
  onDismiss
}: UpdateKeyFormProps): React.ReactElement<UpdateKeyFormProps> | null {
  const { id: editKeyId } = editKey;

  const { updatePropertyKey, loading: updatePropertyKeyLoading } =
    useUpdatePropertyKey(editKeyId);

  const {
    data: keyToEdit,
    loading: getPropertyKeyQueryLoading,
    error: getPropertyKeyQueryError
  } = useGetPropertyKey(editKeyId, "network-only");

  if (getPropertyKeyQueryLoading) {
    return (
      <PropertyKeysFormContent.Loading
        onCancel={onDismiss}
        submitText={FORM_SUBMIT_TEXT}
      />
    );
  }

  if (getPropertyKeyQueryError || !keyToEdit) {
    throw new Error(getPropertyKeyQueryError?.message);
  }

  const { code, description } = keyToEdit;

  return (
    <PropertyKeysFormContent
      onSubmit={updatePropertyKey}
      onDismiss={onDismiss}
      initialValues={{ code, description }}
      isSubmitting={updatePropertyKeyLoading}
      submitText={FORM_SUBMIT_TEXT}
    />
  );
}
