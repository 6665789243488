import { formatAddress } from "@ailo/domain-helpers";
import { Text } from "@ailo/primitives";
import { ConfirmModal } from "@ailo/ui";
import { KeyFragment } from "local/graphql";
import React, { useCallback } from "react";

import { useDeletePropertyKey } from "./useDeletePropertyKey";

export function DeleteKeyModal({
  selectedKey: { id, code, property },
  onDismiss,
  title,
  visible
}: {
  selectedKey: KeyFragment;
  onDismiss: () => void;
  title: string;
  visible: boolean;
}): React.ReactElement {
  const { deletePropertyKey, loading } = useDeletePropertyKey(id, code);

  const formattedAddress = formatAddress(property.address, {
    format: "street, suburb"
  });

  const onConfirmDelete = useCallback(async () => {
    await deletePropertyKey(id);
    onDismiss();
  }, [deletePropertyKey, id, onDismiss]);

  return (
    <ConfirmModal
      visible={visible}
      title={title}
      onConfirm={onConfirmDelete}
      onCancel={onDismiss}
      destructive={true}
      confirmLabel={"Remove key"}
      loading={loading}
    >
      <Text.BodyM>{`The key and description will be removed from ${formattedAddress}.`}</Text.BodyM>
    </ConfirmModal>
  );
}
