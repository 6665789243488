import { FilterComponentType, FilterConfig } from "../FilterFactory";

export type FilterMatchMode = "exact" | "search";

export interface FilterInputParams {
  columnsToFilter: string[];
  valuesToMatch: string[];
  /** The type of matching to do when filtering string row values */
  mode?: FilterMatchMode;
}

export function getFilterInputParams<Row>(
  filterConfigs: FilterConfig<Row>[],
  filterInputs: { [x: string]: string | { label: string; value: string }[] }
): FilterInputParams[] {
  return filterConfigs
    .filter(
      (filterConfig) =>
        !isInputEqualToDefaultValue(
          filterInputs[filterConfig.name],
          filterConfig.defaultValue
        )
    )
    .map((filterConfig) => ({
      columnsToFilter: filterConfig.columns,
      valuesToMatch: convertFilterInputToStringArray(
        filterInputs[filterConfig.name]
      ),
      mode:
        (filterConfig.type ?? FilterComponentType.Search) ==
        FilterComponentType.Search
          ? "search"
          : "exact"
    }));
}

function convertFilterInputToStringArray(
  filter: string | { label: string; value: string }[]
): string[] {
  switch (typeof filter) {
    case "object":
      return filter.map((option) => option.label);
    case "string":
      return [filter];
    default:
      return [];
  }
}

const isInputEqualToDefaultValue = (
  filter: string | object[],
  defaultValue: string | object[]
): boolean => {
  switch (typeof filter) {
    case "object":
      return (
        filter.length === defaultValue.length &&
        filter.every((val, index) => val === defaultValue[index])
      );
    case "string":
      return filter === defaultValue;
    default:
      return true;
  }
};
