import React, { useCallback, useEffect } from "react";
import { ProjectListFilterType } from "../types";
import { UpdateProjectFilterStateCallback } from "../useProjectListFilterState";
import { AssigneesFilter } from "./AssigneesFilter";

export function useAssigneesFilter({
  isFilterDisabled = false,
  assigneeAilorns,
  updateFilterState
}: {
  isFilterDisabled?: boolean;
  assigneeAilorns?: string[];
  updateFilterState: UpdateProjectFilterStateCallback;
}): ProjectListFilterType {
  useEffect(() => {
    if (isFilterDisabled && assigneeAilorns?.length) {
      updateFilterState({ assigneeAilorns: null });
    }
  }, [assigneeAilorns, isFilterDisabled, updateFilterState]);

  const onChangeFilter = useCallback(
    (values: string[]) => {
      updateFilterState({ assigneeAilorns: values.sort() });
    },
    [updateFilterState]
  );

  return {
    isFilterActive: !!assigneeAilorns?.length,
    component: isFilterDisabled ? null : (
      <AssigneesFilter
        key={"ProjectList-AssigneesFilter"}
        onChange={onChangeFilter}
        values={assigneeAilorns || []}
      />
    )
  };
}
