import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { BaseReviewDetailsSection } from "./BaseReviewDetailsSection";
import { DescriptionList } from "@ailo/ui";
import { getDateValue } from "./getDateValue";
import { NewManagementFormData } from "../../NewManagementFormData";
import { getAddressValue } from "./getAddressValue";
import { BaseFormSeparator } from "local/modals/ui/BaseFormSeparator";

interface Props {
  onEditPress?(): void;
}

export function ManagementDetailsSection({ onEditPress }: Props): ReactElement {
  const form = useFormContext<NewManagementFormData>();
  const formData = form.getValues();

  return (
    <>
      <BaseReviewDetailsSection title={"Address"} onEditPress={onEditPress}>
        <DescriptionList
          size={"small"}
          items={[
            {
              label: "Address",
              descriptionBottom: getAddressValue(formData.address)
            }
          ]}
          rowStyle={{
            marginTop: 16
          }}
        />
      </BaseReviewDetailsSection>
      <BaseFormSeparator />
      <BaseReviewDetailsSection
        title={"Agreement details"}
        onEditPress={onEditPress}
      >
        <DescriptionList
          size={"small"}
          columns={2}
          items={[
            {
              label: "Agreement start",
              descriptionBottom: getDateValue(formData.agreementStartDate),
              columnIndex: 0
            },
            {
              label: "Agreement end",
              descriptionBottom: formData.agreementEndDate
                ? getDateValue(formData.agreementEndDate)
                : "N/A",
              columnIndex: 1
            },
            {
              label: "Management fee %",
              descriptionBottom: formData.managementFeePercent
                ? `${formData.managementFeePercent}%`
                : "N/A",
              columnIndex: 0
            }
          ]}
          rowStyle={{
            marginTop: 16
          }}
          style={{ marginBottom: 0 }}
        />
      </BaseReviewDetailsSection>
    </>
  );
}
