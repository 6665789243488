import { ReconciliationReportPeriodFragmentFragment } from "local/graphql";
import { monthNumberToName } from "../utils";
import { LocalDate } from "@ailo/date";
import { Money } from "@ailo/money";
import { ReportPeriodFragment } from "./types";

export function parseReportPeriodFragment(
  reportPeriod: ReconciliationReportPeriodFragmentFragment
): ReportPeriodFragment {
  const periodStart = LocalDate.fromObject({
    year: reportPeriod.year,
    month: reportPeriod.month,
    day: 1
  });
  return {
    ...reportPeriod,
    month: reportPeriod.month,
    year: reportPeriod.year,
    monthName: monthNumberToName(reportPeriod.month),
    isReconciling: reportPeriod.isReconciling,
    periodStart,
    periodEnd: periodStart.add(1, "month").subtract(1, "day"),
    adjustmentSum: Money.fromCents(reportPeriod.adjustmentSumInCents),
    bankBalance: reportPeriod.lastAddedStatementBalance
      ? {
          amount: Money.fromCents(
            reportPeriod.lastAddedStatementBalance.amountInCents
          ),
          date: LocalDate.fromString(
            reportPeriod.lastAddedStatementBalance.date
          )
        }
      : undefined
  };
}
