import React, { useCallback } from "react";
import {
  KeyedAwaitingApprovalMigratingManagementFragment,
  PropertyOnboardingListColumn
} from "../../types";
import {
  AddressCell,
  CheckboxCell,
  CheckboxHeaderCell,
  DataIssuesCell,
  HealthCheckedCheckboxCell,
  PortfolioCell,
  StatusCell
} from "../../PropertyOnboardingListItemCell";
import { AwaitingApprovalPropertyOnboardingListSelect } from "./useAwaitingApprovalPropertyOnboardingListSelect";
import { AwaitingApprovalPropertyOnboardingListColumnNames } from "./useAwaitingApprovalPropertyOnboardingList";

interface Params {
  select: AwaitingApprovalPropertyOnboardingListSelect;
}

export type AwaitingApprovalPropertyOnboardingListColumns =
  PropertyOnboardingListColumn<KeyedAwaitingApprovalMigratingManagementFragment>[];

export function useAwaitingApprovalPropertyOnboardingListColumns({
  select: {
    selectableMigratingManagementIds,
    setSelectedMigratingManagementIds,
    validSelectedMigratingManagementIds
  }
}: Params): AwaitingApprovalPropertyOnboardingListColumns {
  const AwaitingApprovalCheckboxHeaderCell = useCallback(() => {
    return (
      <CheckboxHeaderCell
        disabled={selectableMigratingManagementIds.length === 0}
        value={
          selectableMigratingManagementIds.length !== 0 &&
          selectableMigratingManagementIds.length ===
            validSelectedMigratingManagementIds.length
        }
        onChange={(selected) => {
          setSelectedMigratingManagementIds(
            selected ? selectableMigratingManagementIds : []
          );
        }}
      />
    );
  }, [
    setSelectedMigratingManagementIds,
    selectableMigratingManagementIds,
    validSelectedMigratingManagementIds.length
  ]);

  const AwaitingApprovalCheckboxCell = useCallback(
    ({
      row,
      rowIndex
    }: {
      row: KeyedAwaitingApprovalMigratingManagementFragment;
      rowIndex: number;
    }) => {
      return (
        <HealthCheckedCheckboxCell
          row={row}
          rowIndex={rowIndex}
          selectedMigratingManagementIds={validSelectedMigratingManagementIds}
          setSelectedMigratingManagementIds={setSelectedMigratingManagementIds}
        />
      );
    },
    [setSelectedMigratingManagementIds, validSelectedMigratingManagementIds]
  );

  return [
    {
      maxWidth: "36px",
      renderHeaderCell: AwaitingApprovalCheckboxHeaderCell,
      renderCell: AwaitingApprovalCheckboxCell,
      renderLoadingCell: CheckboxCell.Loading,
      name: "checkbox",
      sortable: false
    },
    {
      width: 6,
      renderCell: AddressCell,
      renderLoadingCell: AddressCell.Loading,
      name: AwaitingApprovalPropertyOnboardingListColumnNames.Address,
      sortable: true,
      caretPosition: "right"
    },
    {
      width: 6,
      renderCell: PortfolioCell,
      renderLoadingCell: PortfolioCell.Loading,
      name: AwaitingApprovalPropertyOnboardingListColumnNames.Portfolio,
      sortable: true,
      caretPosition: "right"
    },
    {
      width: 2,
      renderCell: DataIssuesCell,
      renderLoadingCell: DataIssuesCell.Loading,
      name: AwaitingApprovalPropertyOnboardingListColumnNames.DataIssues,
      horizontalAlign: "right",
      sortable: true,
      caretPosition: "left"
    },
    {
      width: 4,
      renderCell: StatusCell,
      renderLoadingCell: StatusCell.Loading,
      name: AwaitingApprovalPropertyOnboardingListColumnNames.Status,
      horizontalAlign: "right",
      sortable: true,
      caretPosition: "left"
    }
  ];
}
