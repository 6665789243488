import React, { ReactElement } from "react";
import { AwaitingApprovalMigratingManagementFragment } from "local/graphql";
import { TextCell } from "@ailo/ui";

function DataIssuesCell({
  row: { errors }
}: {
  row: AwaitingApprovalMigratingManagementFragment;
  rowIndex: number;
}): ReactElement {
  const errorsArray = Array.isArray(errors) ? errors : [];

  return <TextCell>{errorsArray.length}</TextCell>;
}

function Loading(): React.ReactElement {
  return <TextCell.AdjustableLoading width={40} />;
}

DataIssuesCell.Loading = Loading;

export { DataIssuesCell };
