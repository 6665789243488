import React from "react";
import { View } from "react-native";
import { openURL } from "expo-linking";
import { useEnvironment } from "@ailo/services";
import { Colors, Link } from "@ailo/primitives";
import { Alert, ListItemHeader } from "@ailo/ui";
import { BankAccountMethod, PaymentMethodListItem } from "@ailo/domains";

interface Props {
  bankAccount: BankAccountMethod | undefined;
}

export function BankAccountDisplay({ bankAccount }: Props): React.ReactElement {
  const envVars = useEnvironment();

  return (
    <View>
      <ListItemHeader
        text={"Transfer to"}
        style={{ backgroundColor: Colors.CLOUD, marginTop: 32 }}
      />
      {bankAccount && (
        <PaymentMethodListItem
          paymentMethodId={bankAccount.id}
          style={{ paddingHorizontal: 32 }}
        />
      )}
      {bankAccount ? (
        <Alert
          type={"info"}
          style={{ marginHorizontal: 32, marginVertical: 24 }}
        >
          {"If you'd like to change your bank account, please contact "}
          <Link
            onPress={(): void => {
              openURL(envVars.AILO_SUPPORT_EMAIL);
            }}
          >
            {"Ailo Support"}
          </Link>
        </Alert>
      ) : (
        <Alert
          type={"warning"}
          style={{ marginHorizontal: 32, marginVertical: 24 }}
          message={
            "No bank account found. Contact Ailo support to add a bank account"
          }
        />
      )}
    </View>
  );
}
