import React, { ReactElement } from "react";
import { BondDetailsFormContext } from "../common/BondDetailsFormContext";
import { AddBondDetailsSidebarContent } from "./AddBondDetailsSidebarContent";
import { BondDetailsProps } from "../common/BondDetailsProps";

export function AddBondDetailsSidebar(props: BondDetailsProps): ReactElement {
  return (
    <BondDetailsFormContext>
      <AddBondDetailsSidebarContent {...props} />
    </BondDetailsFormContext>
  );
}
