import { useCallback, useMemo, useState } from "react";
import {
  SearchTenanciesResultFragment,
  TenanciesQueryFilterInput,
  TenanciesSortParams,
  useSearchTenanciesQuery
} from "local/graphql";
import { ApolloError } from "@apollo/client";

type SearchTenanciesResult = {
  pageInfo: {
    total: number;
    hasNext: boolean;
    nextCursor?: string | null;
  };
  items: SearchTenanciesResultFragment[];
};

type SearchTenanciesProps = {
  filters?: TenanciesQueryFilterInput;
  sort?: TenanciesSortParams[];
  pageSize?: number;
  cursor?: string;
};

export type SearchTenanciesReturn = {
  data?: SearchTenanciesResult;
  hasMore: boolean;
  fetchMore: () => void;
  loading: boolean;
  loadingMore: boolean;
  error?: ApolloError;
  refetch: () => void;
};

export function useSearchTenancies({
  filters,
  sort,
  pageSize = 50,
  cursor
}: SearchTenanciesProps): SearchTenanciesReturn {
  const {
    fetchMore: originalFetchMore,
    data,
    ...rest
  } = useSearchTenanciesQuery({
    variables: {
      filters,
      sort,
      pageSize,
      cursor
    }
  });

  const hasMore = useMemo(() => {
    return (
      data?.searchTenancies?.pageInfo.hasNext === true &&
      data?.searchTenancies?.pageInfo.nextCursor != null
    );
  }, [data]);

  const [loadingMore, setLoadingMore] = useState(false);

  const fetchMore = useCallback(async () => {
    if (
      !data ||
      !data.searchTenancies?.pageInfo.hasNext ||
      !data.searchTenancies?.pageInfo.nextCursor
    ) {
      return;
    }
    setLoadingMore(true);
    await originalFetchMore({
      variables: {
        cursor: data.searchTenancies.pageInfo.nextCursor
      },
      updateQuery: ({ searchTenancies }, { fetchMoreResult }) => {
        return {
          searchTenancies: {
            pageInfo: fetchMoreResult?.searchTenancies?.pageInfo || {
              total: searchTenancies?.pageInfo.total || 0,
              hasNext: false
            },
            items: [
              ...(searchTenancies ? searchTenancies.items : []),
              ...(fetchMoreResult?.searchTenancies
                ? fetchMoreResult.searchTenancies.items
                : [])
            ]
          }
        };
      }
    });
    setLoadingMore(false);
  }, [data, originalFetchMore]);

  return {
    data:
      data && data.searchTenancies
        ? {
            pageInfo: data.searchTenancies.pageInfo,
            items: data.searchTenancies.items.map((item) => item.tenancy)
          }
        : undefined,
    loadingMore,
    hasMore,
    fetchMore,
    ...rest
  };
}
