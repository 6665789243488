import { LocalDate } from "@ailo/date";
import { formatRentPaymentSchedule } from "@ailo/domains";
import { Money } from "@ailo/ui";
import { hasPresentKey } from "ts-is-present";
import { TenancyInArrearsFragment } from "local/graphql";
import { getStreetAndStreetNumber } from "../PropertyItemCell/utils";

export const columnHeaders: string[] = [
  "Address",
  "Rent amount",
  "Property paying in Ailo since",
  "Arrears amount",
  "Paid to date",
  "Days in arrears"
];

export function getArrearsReportCsvData(
  tenanciesInArrears: TenancyInArrearsFragment[]
): string[][] {
  const csvData = tenanciesInArrears
    .filter(hasPresentKey("displayRent"))
    .filter(hasPresentKey("liability"))
    .map((tenancy) => {
      const address = `${getStreetAndStreetNumber(tenancy.property.address)}, ${
        tenancy.property.address.suburb
      }`;

      const rentAmount = formatRentPaymentSchedule(tenancy.displayRent);

      const liability = tenancy.liability;

      const firstPaymentDate = liability.firstPaymentDate
        ? LocalDate.fromString(liability.firstPaymentDate).format("DD MMM YYYY")
        : "";

      const arrearsAmount = Money.fromCents(
        liability.amountInArrears.cents
      ).format();

      const paidTo = liability.effectivePaidToDate
        ? LocalDate.fromString(liability.effectivePaidToDate).format(
            "DD MMM YYYY"
          )
        : "";

      const daysInArrears = liability.daysInArrears.toString();

      return [
        address,
        rentAmount,
        firstPaymentDate,
        arrearsAmount,
        paidTo,
        daysInArrears
      ];
    });

  csvData.unshift(columnHeaders);
  return csvData;
}
