import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { useCallback } from "react";
import { MutationResult } from "@apollo/client";
import {
  GetAllTenanciesDepositsDocument,
  TransferTenancyDepositMutation,
  useTransferTenancyDepositMutation
} from "local/graphql";
import { ExecutionResult } from "graphql";

interface DepositDetails {
  tenancyDepositAilorn: string;
  managementId: string;
}

export function useTransferDeposit({
  onSuccess,
  onError
}: {
  onSuccess: () => void;
  onError: () => void;
}): [
  (details: DepositDetails) => void,
  MutationResult<TransferTenancyDepositMutation>
] {
  const [transferDepositMutation, result] = useTransferTenancyDepositMutation();

  const transferDeposit = useCallback(
    async ({ tenancyDepositAilorn, managementId }: DepositDetails) => {
      let result: ExecutionResult<TransferTenancyDepositMutation>;

      try {
        result = await transferDepositMutation({
          variables: {
            tenancyDepositAilorn
          },
          refetchQueries: [
            {
              query: GetAllTenanciesDepositsDocument,
              variables: { managementId }
            }
          ]
        });

        throwIfMutationFailed(result, { dataKey: "transferTenancyDeposit" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }

      onSuccess();
    },
    [transferDepositMutation, onSuccess, onError]
  );

  return [transferDeposit, result];
}
