import React, { ReactElement } from "react";
import { isEmpty } from "lodash";
import {
  ManagementPortfolioOwner,
  ManagementPortfolioOwnersListItem
} from "./ManagementPortfolioOwnersListItem";

interface Props {
  owners: ManagementPortfolioOwner[];
}

export function ManagementPortfolioOwnersList({
  owners
}: Props): ReactElement | null {
  if (isEmpty(owners)) {
    return null;
  }

  return (
    <>
      {owners.map((owner, index) => (
        <ManagementPortfolioOwnersListItem
          key={owner.id}
          owner={owner}
          isLastItem={index === owners.length - 1}
        />
      ))}
    </>
  );
}
