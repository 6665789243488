import { SFC, Text } from "@ailo/primitives";
import { useClearPreviousDataOnVariablesChange } from "@ailo/services";
import { CheckboxInput, FormFieldRow, Money, MoneyInput } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { View } from "react-native";
import styled from "styled-components/native";
import { useGetLastSupplierBillTaxAutoCalculatedQuery } from "local/graphql";

const zeroAmount = { cents: 0 };

const TaxAmountInputRow: SFC = ({ style }) => {
  const { control, setValue, watch, formState, getValues } =
    useFormContext<AddBillFormData>();
  const taxAutoCalculated = watch("taxAutoCalculated");

  const lineItems = watch("lineItems");
  const lineItemsTotalAmount = (lineItems ?? []).reduce(
    (acc, lineItem) => acc.add(lineItem.amount ?? Money.zero()),
    Money.zero()
  );
  const autoCalculatedTaxAmountCents = lineItemsTotalAmount.divide(11).cents;

  const payeeId = watch("supplierReference")?.value;
  const taxAutoCalculatedDirty = formState.dirtyFields.taxAutoCalculated;
  const taxAmountDirty = formState.dirtyFields.taxAmount;
  const dirty = taxAutoCalculatedDirty || taxAmountDirty;

  const lastSupplierBillResult = useClearPreviousDataOnVariablesChange(
    useGetLastSupplierBillTaxAutoCalculatedQuery,
    !payeeId || dirty
      ? { skip: true }
      : {
          variables: { payeeId },
          fetchPolicy: "cache-and-network" as const,
          nextFetchPolicy: "cache-first" as const
        }
  );

  const taxAutoCalculatedDefaultValue =
    lastSupplierBillResult.data?.bills?.items[0]?.taxAutoCalculated ?? true;
  useEffect(() => {
    if (
      !dirty &&
      getValues("taxAutoCalculated") !== taxAutoCalculatedDefaultValue
    ) {
      setValue("taxAutoCalculated", taxAutoCalculatedDefaultValue);
    }
  }, [dirty, taxAutoCalculatedDefaultValue, setValue, getValues]);

  useEffect(() => {
    setValue(
      "taxAmount",
      taxAutoCalculated ?? taxAutoCalculatedDefaultValue
        ? Money.fromCents(autoCalculatedTaxAmountCents)
        : Money.zero()
    );
  }, [
    autoCalculatedTaxAmountCents,
    dirty,
    setValue,
    taxAutoCalculated,
    taxAutoCalculatedDefaultValue
  ]);

  return (
    <View style={style}>
      <Controller
        name={"taxAutoCalculated"}
        defaultValue={taxAutoCalculatedDefaultValue}
        control={control}
        render={({ onChange, value }) => (
          <CheckboxInput
            label={"GST included"}
            value={value}
            onChange={onChange}
            onFocus={() => {
              setValue("fieldUnderInvoiceNumberWasFocused", true);
            }}
          />
        )}
      />

      <FormFieldRow style={{ marginTop: 16 }}>
        <LabelContainer>
          <Text.BodyL>{"GST"}</Text.BodyL>
        </LabelContainer>
        <Controller
          name={`taxAmount`}
          defaultValue={zeroAmount}
          control={control}
          render={({ onChange, onBlur, value }): React.ReactElement => (
            <MoneyInput
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              testID={"LineItemTaxAmountInput"}
              disabled={taxAutoCalculated}
              withTrailingZeros
              onFocus={(): void => {
                setValue("fieldUnderInvoiceNumberWasFocused", true);
              }}
            />
          )}
          rules={{
            required: "GST amount is required"
          }}
        />
      </FormFieldRow>
    </View>
  );
};

const LabelContainer = styled(View)`
  justify-content: center;
  height: 52px;
`;

export { TaxAmountInputRow };
