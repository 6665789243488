import { CheckBoxIcon, Colors, Text } from "@ailo/primitives";
import React, { ReactElement, useCallback, useEffect } from "react";
import styled from "styled-components/native";
import { Pressable, View } from "react-native";
import { useUpdateMigratingManagement } from "./hooks";
import { useToastContext } from "@ailo/ui";
import { MigratingManagementIssueData } from "./KeyedHealthIssuesCard";

interface Props {
  migratingManagementIssueData: MigratingManagementIssueData;
  setLoading: (loading: boolean) => void;
}

export function VacatingTenancyHealthIssue({
  migratingManagementIssueData,
  setLoading
}: Props): ReactElement {
  const { migratingManagementId, migrateAsVacant } =
    migratingManagementIssueData;
  const toast = useToastContext();

  const onError = useCallback(() => {
    toast.show({
      type: "error",
      message: "Unable to update property. Please try again"
    });
  }, [toast]);

  const onSuccess = useCallback(() => {
    toast.show({
      type: "success",
      message: "Property updated"
    });
  }, [toast]);

  const [updateMigratingManagement, { loading }] = useUpdateMigratingManagement(
    {
      migratingManagementId,
      onSuccess,
      onError
    }
  );

  useEffect(() => {
    setLoading(loading);

    return () => {
      setLoading(false);
    };
  }, [setLoading, loading]);

  const onCheckBoxPress = useCallback(() => {
    updateMigratingManagement({ migrateAsVacant: !migrateAsVacant });
  }, [updateMigratingManagement, migrateAsVacant]);

  return (
    <Container>
      <Text.BodyS>{"There is a vacating tenancy"}</Text.BodyS>
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
        {"You cannot onboard a property with a move out date"}
      </Text.BodyS>

      <CheckBoxPressable onPress={onCheckBoxPress} disabled={loading}>
        <ScaledCheckBoxIcon checked={migrateAsVacant} height={20} width={20} />
        <CheckBoxLabel>
          {
            "Allow Ailo to automatically remove the vacating tenancy and onboard this property as vacant ready for new renters"
          }
        </CheckBoxLabel>
      </CheckBoxPressable>
    </Container>
  );
}

const Container = styled(View)`
  padding: 16px;
  border-top-style: solid;
  border-top-color: ${Colors.GRAYSCALE.OUTLINE};
  border-top-width: 1px;
`;

const CheckBoxPressable = styled(Pressable)`
  flex-direction: row;
  margin-top: 11px;
`;

const ScaledCheckBoxIcon = styled(CheckBoxIcon)`
  min-width: 20px;
  min-height: 20px;
`;

const CheckBoxLabel = styled(Text.BodyS)`
  display: flex;
  align-items: center;
  margin-left: 8px;
  text-align-vertical: center;
`;
