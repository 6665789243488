import React from "react";
import { Pressable, View } from "react-native";
import styled from "styled-components/native";
import { Colors, DownloadIcon, Text } from "@ailo/primitives";
import { MoneyInterface, Tooltip } from "@ailo/ui";
import { computeRentBalance, RentBalance, useHasFeature } from "@ailo/domains";
import { useState } from "reactn";
import { TenancyLedgerDownloadModal } from "./TenancyLedgerDownloadModal";
import { useAnalytics } from "local/common";
import { AiloRN, services } from "@ailo/ailorn";
import { PlatformFeatureId } from "local/graphql";

interface Props {
  overdueAmount: MoneyInterface | undefined;
  nextDueDate: string | undefined | null;
  nextDueAmount: MoneyInterface | undefined | null;
  tenancyId: string;
  tenancyLedgerDownloadDisabled: boolean;
}

export function RentBalanceHeader({
  overdueAmount,
  nextDueDate,
  nextDueAmount,
  tenancyId,
  tenancyLedgerDownloadDisabled = true
}: Props): React.ReactElement {
  const { label, amount, color } =
    computeRentBalance({
      nextDueDate,
      overdueAmount,
      nextDueAmount
    }) || {};
  const analytics = useAnalytics();

  const hasTenancyLedgerDownloadFeatureToggle = useHasFeature(
    PlatformFeatureId.TenancyLedgerStatementDownload
  );
  const [modalActive, setModalActive] = useState(false);
  const toggleModal = (): void => {
    setModalActive(!modalActive);
  };
  const tenancyLedgerDownloadButtonColor = tenancyLedgerDownloadDisabled
    ? Colors.TEXT.DARK.SECONDARY
    : Colors.AILO_RED;
  return (
    <Container>
      {label && color && amount && (
        <RentBalanceContainer>
          <RentBalance
            rentBalanceLabel={label}
            rentBalanceColor={color}
            rentBalance={amount}
          />
        </RentBalanceContainer>
      )}
      {hasTenancyLedgerDownloadFeatureToggle && (
        <>
          {modalActive && (
            <TenancyLedgerDownloadModal
              toggleModal={toggleModal}
              tenancyId={tenancyId}
            />
          )}

          <Tooltip
            tooltipContent={
              !tenancyLedgerDownloadDisabled
                ? "Tenancy ledger PDF"
                : "Tenancy ledger unavailable"
            }
          >
            <Pressable
              onPress={() => {
                toggleModal();
                analytics.track(`Tenancy Ledger Downloaded`, {
                  tenancy_id: AiloRN.of(
                    services.PropertyManagement.tenancy,
                    tenancyId
                  )
                });
              }}
              disabled={tenancyLedgerDownloadDisabled}
            >
              <TenancyLedgerDownloadButton>
                <DownloadIcon
                  style={{ marginRight: 4 }}
                  width={20}
                  color={tenancyLedgerDownloadButtonColor}
                />
                <Text.BodyM color={tenancyLedgerDownloadButtonColor}>
                  {"Download"}
                </Text.BodyM>
              </TenancyLedgerDownloadButton>
            </Pressable>
          </Tooltip>
        </>
      )}
    </Container>
  );
}

const Container = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 24px 16px 24px;
`;

const RentBalanceContainer = styled(View)`
  justify-content: space-between;
  background-color: ${Colors.WHITE};
`;

const TenancyLedgerDownloadButton = styled(View)`
  flex-direction: row;
  align-items: center;
`;
