import React, { ReactElement } from "react";
import { View } from "react-native";
import { PrimaryText } from "local/tabs/properties/components/TenancyBondScreen/components/PrimaryText";
import { Colors, Text } from "@ailo/primitives";
import { Separator } from "@ailo/ui";

export function ClaimStepSubmittedToBondAuthority({
  bondRef,
  bondAuthorityName
}: {
  bondRef?: string;
  bondAuthorityName?: string;
}): ReactElement {
  return (
    <>
      <Separator style={{ marginLeft: 0 }} />
      <View style={{ paddingVertical: 20 }}>
        <PrimaryText weight={"medium"}>
          {`You’ve submitted the claim to ${bondAuthorityName}`}
        </PrimaryText>
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
          {"Bond reference: " + bondRef}
        </Text.BodyS>
      </View>
    </>
  );
}
