import React, { ReactElement } from "react";
import { Text } from "@ailo/primitives";
import { RefinedActionActivityProps } from "../../ActionActivityProps";
import {
  ActionType,
  ProjectType,
  useGetProjectActionsQuery
} from "local/graphql";
import { NoInspectionAppointmentViewUI } from "./NoInspectionAppointmentViewUI";

export function NoInspectionAppointmentView({
  action,
  projectId
}: RefinedActionActivityProps<
  | ActionType.GenerateInspectionReport
  | ActionType.TrackInspectionCompleted
  | ActionType.ConfirmInspectionSchedule
>): ReactElement {
  const { data, loading } = useGetProjectActionsQuery({
    variables: {
      projectId,
      pageSize: 10
    }
  });

  if (loading && !data) {
    return <Text.Loading variant={"BodyM"} width={100} />;
  }

  let targetActionId: string | undefined;
  let targetActionName: string | undefined;

  if (action.project.type === ProjectType.NewTenancy) {
    const assignTenancyAction = data?.project?.actions?.items.find(
      (action) => action.type === ActionType.AssignTenancy
    );
    targetActionId = assignTenancyAction?.id;
    targetActionName = "set up the new tenancy";
  } else if (action.project.type === ProjectType.VacatingTenancy) {
    const endTenancyAction = data?.project?.actions?.items.find(
      (action) => action.type === ActionType.EndTenancy
    );
    targetActionId = endTenancyAction?.id;
    targetActionName = "end the tenancy";
  }

  return (
    <NoInspectionAppointmentViewUI
      targetActionId={targetActionId}
      targetActionName={targetActionName || ""}
    />
  );
}
