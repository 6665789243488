import { Colors, Text } from "@ailo/primitives";
import { LocalDate, Money } from "@ailo/ui";
import { CreateRecurringFeeFormData } from "local/domain/expense";
import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { View, ViewStyle } from "react-native";

type Props = {
  nextChargeDate?: LocalDate;
  style?: ViewStyle;
};
export function RecurringFeeChargeDescription({
  nextChargeDate,
  style
}: Props): ReactElement | null {
  const { watch } = useFormContext<CreateRecurringFeeFormData>();

  const price = watch("totalAmount");
  const startDate = watch("startDate");
  const frequency = watch("feeBlueprint")?.frequency;
  if (!price || !startDate) return null;
  const priceText = Money.from(price).format();
  return (
    <View style={style}>
      {nextChargeDate ? (
        <Text.DisplayS>
          {`${priceText} (incl. GST) charged on ${nextChargeDate.format(
            "Do [of] MMMM YYYY"
          )}`}
        </Text.DisplayS>
      ) : (
        <>
          <Text.DisplayS>
            {`${priceText} (incl. GST) charged ${frequency}`}
          </Text.DisplayS>
          <Text.BodyM
            color={Colors.TEXT.DARK.SECONDARY}
            style={{ marginBottom: 4 }}
          >
            {`Beginning on ${LocalDate.from(startDate).format(
              "Do [of] MMMM YYYY"
            )}`}
          </Text.BodyM>
        </>
      )}
    </View>
  );
}

RecurringFeeChargeDescription.Loading = function Loading(): ReactElement {
  return (
    <>
      <Text.BodyL.Loading width={100} style={{ marginBottom: 20 }} />
      <Text.DisplayS.Loading width={280} />
    </>
  );
};
