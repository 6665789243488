import { AiloSentry } from "@ailo/services";
import React, { ReactElement } from "react";
import { hasPresentKey } from "ts-is-present";
import { BaseActionActivity } from "../../BaseActionActivity";
import { EditSectionHeader } from "../../EditSectionHeader";
import { OwnerWithSharesFragment } from "local/graphql";
import { ReviewOwnerProfileCard } from "local/modals/AddOrEditManagementModalForm/AddOrEditManagementFormSteps/ReviewManagementDetailsStep/ReviewOwnerProfileCard";

export function OwnerDetailsSection({
  owners
}: {
  owners: OwnerWithSharesFragment[];
}): ReactElement {
  return (
    <BaseActionActivity>
      <EditSectionHeader
        title={"Owners"}
        displayEditButton={false}
        style={{ marginBottom: 20 }}
      />
      {owners
        .filter(hasPresentKey("owner"))
        .map(({ owner, sharesOwned, totalShares }, index) => {
          if (owner.__typename === "Company") {
            AiloSentry.captureException(
              new Error(
                `Company as renters is not enabled yet. Error occured for owner id: ${owner.id}`
              )
            );
            return <></>;
          }
          return (
            <ReviewOwnerProfileCard
              key={`Owner-Review-Card-${owner.id}`}
              person={{
                dateOfBirth: owner.birthDate,
                email: owner.emailAddress,
                phoneNumber: owner.phoneNo,
                middleName: owner.legalMiddleName,
                sharesOwned: sharesOwned,
                ...owner
              }}
              totalShares={totalShares}
              showNoEmailWarning={false}
              style={{
                marginTop: 12,
                marginBottom: index === owners.length - 1 ? 0 : 12
              }}
            />
          );
        })}
    </BaseActionActivity>
  );
}
