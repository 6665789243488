import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import { isPresent } from "ts-is-present";
import { getTenantList } from "../../utils";
import { parseBond } from "../parseBond";
import { TenancyBond } from "local/tabs";
import {
  AllowedOperations,
  CancelVacateAbility,
  GetTenantsAndOutstandingLiabilityQueryVariables,
  RentFragment,
  TenancyAgreementFragment,
  TenantInfoFragment,
  useGetTenantsAndOutstandingLiabilityQuery
} from "local/graphql";
import { useCurrentAgencyOrg } from "local/common";

type TenantsAndOutstandingLiability = {
  tenants: TenantInfoFragment[];
  tenancyId: string;
  outstandingBillsBalance?: number;
  terminationAmountDue?: number;
  endDate?: string;
  rentReviewAllowed: boolean;
  editableRentReview?: RentFragment;
  nextTenancyAgreement?: TenancyAgreementFragment;
  leaseReviewAllowedOperation: AllowedOperations;
  bond?: TenancyBond;
  canCancelVacate?: CancelVacateAbility;
};

export function useGetTenantsAndOutstandingLiability(
  tenancyId: string
): MappedQueryResult<
  TenantsAndOutstandingLiability,
  GetTenantsAndOutstandingLiabilityQueryVariables
> {
  const { ailoRN: currentAgencyOrgAilornString } = useCurrentAgencyOrg();

  const result = useGetTenantsAndOutstandingLiabilityQuery({
    variables: {
      id: tenancyId,
      organisationAilorn: currentAgencyOrgAilornString
    }
  });

  return useMappedQueryResult(
    result,
    (data): TenantsAndOutstandingLiability => {
      const tenancy = data?.tenancy;

      if (!tenancy) {
        throw new ApolloError({
          errorMessage: `Tenancy ID="${tenancyId}" not found`
        });
      }

      if (!isPresent(tenancy.leaseReviewAllowedOperation)) {
        throw new ApolloError({
          errorMessage: `Tenancy ID="${tenancyId}" leaseReviewAllowedOperation is undefined`
        });
      }

      if (!isPresent(tenancy.rentReviewAllowed)) {
        throw new ApolloError({
          errorMessage: `Tenancy ID="${tenancyId}" rentReviewAllowed is undefined`
        });
      }

      const tenants = getTenantList(tenancy.id, tenancy.tenantships?.items);

      return {
        tenants,
        endDate: tenancy.endDate || undefined,
        tenancyId,
        outstandingBillsBalance:
          tenancy.payerBillsSummary?.outstandingBillsBalance.cents,
        terminationAmountDue: tenancy.liability?.terminationAmountDue?.cents,
        rentReviewAllowed: tenancy.rentReviewAllowed,
        editableRentReview: tenancy.editableRentReview?.rent ?? undefined,
        nextTenancyAgreement: tenancy.nextTenancyAgreement ?? undefined,
        leaseReviewAllowedOperation: tenancy.leaseReviewAllowedOperation,
        bond: parseBond(tenancy),
        canCancelVacate: tenancy.canCancelVacate ?? undefined
      };
    }
  );
}
