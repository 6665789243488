import { FormField, SelectInputWithSearch, SelectOption } from "@ailo/ui";
import { isEmpty } from "lodash";
import React, { ReactElement, useMemo, useRef } from "react";
import { Controller } from "react-hook-form";
import { usePropertyOrAgencyPropertySearch } from "../../hooks/usePropertyOrAgencyPropertySearch";

interface Props {
  propertyAddress?: string;
  managementId?: string;
  setSelectedManagementId?: (managementId: string) => void;
  label?: string;
  onOptionSelect?: (
    option: SelectOption | undefined,
    searchString: string
  ) => void;
  onEmptyOptionsShow?: (searchString: string) => void;
  onFocus?: () => void;
  name: string;
}

export function SearchProperty({
  managementId,
  propertyAddress,
  label,
  onOptionSelect,
  onEmptyOptionsShow,
  onFocus,
  name
}: Props): ReactElement {
  const {
    searchHandler: searchProperties,
    error,
    options,
    transformToSelectedManagementOption
  } = usePropertyOrAgencyPropertySearch();

  const propertyOptions = useMemo(
    () => options?.map(({ label, value }) => ({ label, value })),
    [options]
  );

  const inputRef = useRef<any>(null);
  return (
    <Controller
      render={({ value, onChange }): React.ReactElement => {
        return (
          <FormField label={label}>
            <SelectInputWithSearch
              {...{ value }}
              onChange={async (option?: SelectOption) => {
                const selectedOption =
                  await transformToSelectedManagementOption(option);

                onChange(selectedOption);
              }}
              inputRef={inputRef}
              options={propertyOptions}
              onInputFinish={searchProperties}
              isError={!!error}
              placeholder={"Search properties..."}
              onFocus={(): void => {
                onFocus?.();
              }}
              onOptionSelect={(
                option: SelectOption | undefined,
                searchString: string
              ): void => {
                onOptionSelect?.(option, searchString);
              }}
              onEmptyOptionsShow={(searchString): void => {
                onEmptyOptionsShow?.(searchString);
              }}
              isClearable
            />
          </FormField>
        );
      }}
      name={name}
      onFocus={() => {
        inputRef?.current?.focus();
      }}
      defaultValue={
        propertyAddress
          ? {
              value: managementId,
              label: propertyAddress
            }
          : undefined
      }
      rules={{
        validate: (option): boolean | string =>
          (!isEmpty(option?.label) && !isEmpty(option?.value)) ||
          "Select property"
      }}
    />
  );
}
