import React from "react";
import {
  SidebarCard,
  DropdownMenuToggleProps,
  Button,
  DropdownMenu
} from "@ailo/ui";
import { MoreIcon, EditIcon, DeleteIcon } from "@ailo/primitives";
import { KeyFragment } from "local/graphql";
import { hasPresentKey } from "ts-is-present";

function renderDropdownButton({
  open,
  toggle
}: DropdownMenuToggleProps): React.ReactElement {
  return (
    <Button.Secondary
      type={"extra-small"}
      square
      active={open}
      onPress={toggle}
    >
      <MoreIcon width={16} height={16} />
    </Button.Secondary>
  );
}

export function KeyCard({
  propertyKey: { id, code, description },
  onEdit,
  onDelete
}: {
  propertyKey: KeyFragment;
  onEdit?: () => void;
  onDelete?: () => void;
}): React.ReactElement {
  const dropdownOptions = [
    {
      label: "Edit",
      icon: <EditIcon />,
      onSelect: onEdit
    },
    {
      label: "Remove",
      icon: <DeleteIcon />,
      onSelect: onDelete
    }
  ].filter(hasPresentKey("onSelect"));

  const isDropdownEnabled = dropdownOptions.length > 0;

  return (
    <SidebarCard
      title={code}
      titleRight={
        isDropdownEnabled ? (
          <DropdownMenu
            renderToggle={renderDropdownButton}
            options={dropdownOptions}
          />
        ) : undefined
      }
      descriptionListItems={[
        {
          label: "Description",
          description: description || "—",
          numberOfLines: 0
        }
      ]}
      key={id}
    />
  );
}
