import { ApolloError } from "@apollo/client";
import { useCurrentAgencyOrg } from "local/common";
import {
  AgencyPropertiesQuery,
  useMappedSearchAgencyPropertiesLazyQuery
} from "local/domain/propertyManagement";
import { useCallback } from "react";

type SearchPropertyCallback = {
  searchAgencyProperties: (queryString: string) => void;
  data?: AgencyPropertiesQuery;
  error?: ApolloError;
};
export function useSearchAgencyPropertiesCallback(): SearchPropertyCallback {
  const currentOrg = useCurrentAgencyOrg();

  const [query, { data, error }] = useMappedSearchAgencyPropertiesLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  const searchAgencyProperties = useCallback(
    (queryString: string): void => {
      query({
        variables: {
          search: queryString,
          orgAilorn: currentOrg.ailoRN,
          pageSize: 10
        }
      });
    },
    [currentOrg.ailoRN, query]
  );

  return {
    searchAgencyProperties,
    data,
    error
  };
}
