import React, { ReactElement } from "react";
import {
  ChatSearchBar,
  ChatSearchBarEmptyOption,
  ChatSearchBarProps
} from "@ailo/domains";
import { ChatSearchBarWebDropDownItem } from "./ChatSearchBarWebDropDownItem";

type Props = Omit<
  ChatSearchBarProps,
  | "controlType"
  | "height"
  | "menuStyle"
  | "groupHeadingStyle"
  | "optionItemStyle"
  | "controlStyle"
  | "menuPortalTargetId"
  | "NoOptionComponent"
>;

export const ChatSearchBarWeb = React.memo(function ChatSearchBarWeb(
  props: Props
): ReactElement {
  return (
    <ChatSearchBar
      {...props}
      controlType={"full-width-borderless"}
      height={52}
      menuStyle={MENU_STYLE}
      groupHeadingStyle={GROUP_HEADING_STYLE}
      optionItemStyle={OPTION_ITEM_STYLE}
      controlStyle={CONTROL_STYLE}
      menuPortalTargetId={null}
      NoOptionComponent={ChatSearchBarEmptyOption}
      OptionLabelComponent={ChatSearchBarWebDropDownItem}
    />
  );
});

const MENU_STYLE = {
  marginLeft: 53,
  marginTop: -4,
  marginRight: 24,
  paddingTop: -4
} as const;

const GROUP_HEADING_STYLE = {
  marginTop: 0,
  marginBottom: 0
} as const;

const OPTION_ITEM_STYLE = {
  alignItems: "flex-start",
  paddingBottom: 16,
  paddingTop: 16
} as const;

const CONTROL_STYLE = {
  paddingRight: 24
} as const;
