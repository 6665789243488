import React from "react";
import { useGetEndReasonQuery } from "local/graphql";
import { BasePropertyListRow } from "../BasePropertyListRow";
import { ErrorCell } from "./ErrorCell";
import { TextCell } from "@ailo/ui";

export function EndReasonCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement | null {
  const { data, loading } = useGetEndReasonQuery({
    variables: { managementId }
  });

  if (loading && !data) {
    return <Loading />;
  }

  const management = data?.management;
  if (!management) {
    return <EndReasonCell.Error />;
  }

  const endReason = management.endReason;

  if (!endReason) {
    return <TextCell>{"N/A"}</TextCell>;
  }

  const endReasonText = endReason.label;

  return <TextCell>{endReasonText}</TextCell>;
}

function Loading(): React.ReactElement {
  return <TextCell.Loading />;
}

function Error(): React.ReactElement {
  return <ErrorCell style={{ alignItems: "flex-start", height: undefined }} />;
}

EndReasonCell.Loading = Loading;
EndReasonCell.Error = Error;
