import { useCallback, useMemo } from "react";
import { useAnalytics, uuid } from "@ailo/services";
import { ActionDetailsFragment } from "local/graphql";
import { startCase } from "lodash";
import { AiloRN, services } from "@ailo/ailorn";
import { ActionFormData } from "../ActionFormData";

interface UseCreateActionAnalytics {
  onFormOpened: () => void;
  onValueUpdated: (
    fieldName: string,
    isValid: boolean,
    formValues: ActionFormData
  ) => void;
  onSubmitSuccess: (
    action: ActionDetailsFragment,
    formValues: ActionFormData
  ) => void;
}

export function useCreateActionAnalytics(
  projectId: string
): UseCreateActionAnalytics {
  const formSessionId = useMemo(() => uuid(), []);
  const analytics = useAnalytics();
  const projectAiloRN = AiloRN.of(
    services.Project.project,
    projectId
  ).toString();

  const onFormOpened = useCallback(() => {
    analytics.trackScreenVisited("Create Project Action", {
      form_session_id: formSessionId,
      project_id: projectAiloRN
    });
  }, [analytics, formSessionId, projectAiloRN]);

  const onValueUpdated = useCallback(
    (fieldName: string, isValid, formValues: ActionFormData) => {
      analytics.track("Form Step Completed", {
        form_name: "Create Project Action",
        form_session_id: formSessionId,
        step_name: startCase(fieldName),
        value_is_valid: isValid ? "Yes" : "No",
        steps_completed_this_far: formValuesToStepsCompleted(formValues),
        project_id: projectAiloRN
      });
    },
    [analytics, formSessionId, projectAiloRN]
  );

  const onSubmitSuccess = useCallback(
    (action: ActionDetailsFragment, formValues: ActionFormData) => {
      analytics.track("Project Action Created", {
        project_id: projectAiloRN,
        action_id: action.id,
        ...(action.type ? { action_type: startCase(action.type) } : {}),
        form_session_id: formSessionId,
        steps_completed: formValuesToStepsCompleted(formValues)
      });
    },
    [analytics, formSessionId, projectAiloRN]
  );

  return {
    onFormOpened,
    onValueUpdated,
    onSubmitSuccess
  };
}

function formValuesToStepsCompleted(formValues: ActionFormData): string[] {
  return Object.keys(formValues)
    .filter((key) => !!formValues[key as keyof ActionFormData])
    .map(startCase);
}
