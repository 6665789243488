import { useEffect } from "reactn";
import { Timer } from "../../../../common/useTimer";
import { useState } from "react";
import { LocalDate } from "@ailo/date";
import {
  useGetTenancyStartAndEndDatesLazyQuery,
  GetTenancyStartAndEndDatesQuery
} from "local/graphql";

type UseQueryDates =
  | {
      startDate: LocalDate;
      endDate: LocalDate;
    }
  | undefined;

function useQueryDates(
  timer: Timer,
  tenancyId: string
): {
  queryDates: UseQueryDates;
} {
  const [queryDates, setQueryDates] = useState<UseQueryDates>(undefined);

  const [getTenancyStartAndEndDates, tenancyStartAndEndDates] =
    useGetTenancyStartAndEndDatesLazyQuery({
      fetchPolicy: "no-cache"
    });

  useEffect(() => {
    if (!timer || !tenancyId) {
      return;
    }

    getTenancyStartAndEndDates({
      variables: { tenancyId }
    });
  }, [timer, tenancyId, getTenancyStartAndEndDates]);

  useEffect(() => {
    if (!timer || !tenancyStartAndEndDates.data || queryDates) {
      return;
    }
    setQueryDates(getTenancyLedgerQueryDates(tenancyStartAndEndDates.data));
  }, [timer, tenancyStartAndEndDates, queryDates]);

  // Reset state
  useEffect(() => {
    if (timer) {
      return;
    }
    tenancyStartAndEndDates.client?.stop();
    setQueryDates(undefined);
  }, [timer, tenancyStartAndEndDates]);

  return {
    queryDates
  };
}

function getTenancyLedgerQueryDates(
  tenancyStartAndEndDatesData: GetTenancyStartAndEndDatesQuery
): { startDate: LocalDate; endDate: LocalDate } {
  const today = LocalDate.today();

  const tenancyStartDate = tenancyStartAndEndDatesData.tenancy?.startDate
    ? LocalDate.from(tenancyStartAndEndDatesData.tenancy?.startDate)
    : undefined;

  const tenancyEndDate = tenancyStartAndEndDatesData.tenancy?.endDate
    ? LocalDate.from(tenancyStartAndEndDatesData.tenancy?.endDate)
    : undefined;

  const startDate =
    !tenancyStartDate || today.isBefore(tenancyStartDate)
      ? today
      : tenancyStartDate;

  const endDate =
    tenancyEndDate && tenancyEndDate.isBefore(today) ? tenancyEndDate : today;

  return { startDate, endDate };
}

export { useQueryDates, UseQueryDates };
