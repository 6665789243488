import React, { ReactElement, useCallback } from "react";
import { InfiniteScrollList } from "@ailo/ui";
import { FilePreview } from "../ProjectDetails/ProjectFiles/FilePreview";
import { FilesStateFile, useOpenFile } from "@ailo/domains";
import { useProjectAnalytics } from "local/domain/project";
import { ProjectFile } from "../ProjectDetails/ProjectFiles";
import { FileUploading } from "../ProjectDetails/ProjectFiles/FileUploading";
import { Colors, opacify, Text } from "@ailo/primitives";
import { View } from "react-native";
import styled from "styled-components/native";

interface Props {
  projectId: string;
  files: ProjectFile[];
  processingFiles: FilesStateFile[];
  onRemoveFile: (file: FilesStateFile) => void;
  onLoadMore?: () => void;
  loadingMore: boolean;
}

export function ProjectFilesList({
  projectId,
  files,
  processingFiles,
  onRemoveFile,
  onLoadMore,
  loadingMore
}: Props): React.ReactElement {
  const { onFileOpened, onFileDownloaded } = useProjectAnalytics(projectId);

  const { openFile, DownloadModal } = useOpenFile(files, {
    onOpen: onFileOpened,
    onDownload: onFileDownloaded
  });

  const renderItem = useCallback(
    ({ item }): ReactElement => {
      if ((item as ProjectFile).projectFileId !== undefined) {
        return (
          <FilePreview
            key={"tab" + item.id}
            projectId={projectId}
            file={item}
            onPress={openFile}
            onDelete={() => onRemoveFile(item)}
            type={"medium-details"}
          />
        );
      } else {
        return (
          <FileUploading file={item} key={item.key} width={133} height={160} />
        );
      }
    },
    [onRemoveFile, openFile, projectId]
  );

  return (
    <>
      <DownloadModal />
      <InfiniteScrollList
        data={[
          ...processingFiles
            .reverse()
            .filter((newFile) => !files.some((file) => file.id === newFile.id)),
          ...files
        ]}
        renderItem={renderItem}
        keyExtractor={(item): string => getFileKey(item)}
        onEndReachedThreshold={0.3}
        bounces={true}
        loadingMore={loadingMore}
        numColumns={3}
        onLoadMore={onLoadMore}
        contentContainerStyle={{
          paddingBottom: 60,
          paddingTop: 24,
          paddingRight: 32,
          paddingLeft: 32
        }}
      />
    </>
  );
}

function getFileKey(file: ProjectFile | FilesStateFile): string {
  if ((file as ProjectFile).projectFileId !== undefined) {
    return (file as ProjectFile).id;
  }
  return (file as FilesStateFile).key.toString();
}

ProjectFilesList.Loading = function ProjectFilesHeaderLoading(): ReactElement {
  return (
    <Container>
      <FileContainer>
        <Text.BodyS.Loading
          width={60}
          height={12}
          style={{ paddingBottom: 8 }}
        />
        <Text.BodyS.Loading width={40} height={12} />
      </FileContainer>
      <FileContainer>
        <Text.BodyS.Loading
          width={60}
          height={12}
          style={{ paddingBottom: 8 }}
        />
        <Text.BodyS.Loading width={40} height={12} />
      </FileContainer>
      <FileContainer>
        <Text.BodyS.Loading
          width={60}
          height={12}
          style={{ paddingBottom: 8 }}
        />
        <Text.BodyS.Loading width={40} height={12} />
      </FileContainer>
    </Container>
  );
};

const Container = styled(View)`
  padding-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: row;
  margin-right: -8px;
`;

const FileContainer = styled(View)`
  padding: 15px;
  width: 133px;
  height: 160px;
  border: solid 1px ${opacify(Colors.SPACE_BLACK, 0.1)};
  border-radius: 5px;
  background-color: white;
  margin-right: 8px;
  align-content: flex-end;
  justify-content: flex-end;
`;
