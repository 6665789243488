import React, { ReactElement } from "react";
import {
  ErrorBoundaryDefaultRenderFallback,
  ErrorBoundaryRenderFallbackProps
} from "@ailo/services";
import { ErrorAlertScreen } from "@ailo/ui";
import { ExpenseDataFetchError } from "../../graphql";

export function ExpenseDetailViewErrorBoundaryFallback(
  onClose?: () => void
): (props: ErrorBoundaryRenderFallbackProps) => ReactElement {
  return function Fallback({
    error,
    retry
  }: ErrorBoundaryRenderFallbackProps): ReactElement {
    if (error instanceof ExpenseDataFetchError) {
      return (
        <ErrorAlertScreen
          variant={"sidebar"}
          title={"There was a problem loading Expense details"}
          onRetry={retry}
          onClose={onClose}
        />
      );
    }

    return (
      <ErrorBoundaryDefaultRenderFallback {...{ error, retry, onClose }} />
    );
  };
}
