import { Button, GridSortOrder, LocalDate, Tooltip } from "@ailo/ui";
import React from "react";
import { CSVLink } from "react-csv";
import { Colors, DownloadIcon, Text } from "@ailo/primitives";
import { useCurrentAgencyOrg } from "local/common";
import {
  ManagementFilterTypes,
  useGetPagedManagementsWithTenancyInArrearsQuery
} from "local/graphql";
import { usePropertyListSelectedTeams } from "../hooks/usePropertyListSelectedTeams";
import { arrearsSortStateToApiParam } from "../hooks";
import { View } from "react-native";
import { getArrearsReportCsvData } from "./getArrearsReportCsvData";
import { isPresent } from "ts-is-present";
import { getTeamsManagementFilterParams } from "../hooks/getTeamsManagementFilterParams";

const MAX_EXPECTED_MANAGEMENTS_IN_ARREARS_IN_ONE_AGENCY = 2000;

export function ArrearsReportDownloadButton({
  sortOrder
}: {
  sortOrder: GridSortOrder;
}): React.ReactElement {
  const currentOrg = useCurrentAgencyOrg();
  const selectedTeams = usePropertyListSelectedTeams();

  const { data, loading, error } =
    useGetPagedManagementsWithTenancyInArrearsQuery({
      variables: {
        organisationId: currentOrg.ailoRN,
        filters: [
          { name: ManagementFilterTypes.HasActiveTenancyInArrears },
          ...getTeamsManagementFilterParams(selectedTeams)
        ],
        pageCursor: {
          cursor: null,
          pageSize: MAX_EXPECTED_MANAGEMENTS_IN_ARREARS_IN_ONE_AGENCY,
          paginateBackward: false,
          sort: arrearsSortStateToApiParam(sortOrder)
        }
      }
    });

  if (loading) {
    return <Disabled tooltipText={"Loading data"} />;
  }

  const tenanciesInArrears = data?.managementsForOrg?.items
    .map((management) => management.mostRecentTenancy)
    .filter(isPresent);

  if (error || !tenanciesInArrears) {
    return (
      <Disabled tooltipText={"Failed to load data. Please try again later."} />
    );
  }

  return (
    <CSVLink
      style={{ textDecoration: "none" }}
      filename={`Arrears Report ${LocalDate.today().format("YYYY-MM-DD")}`}
      data={getArrearsReportCsvData(tenanciesInArrears)}
      target={"_blank"}
    >
      <Button
        variant={"text"}
        contentStyle={{ alignItems: "center" }}
        leftIcon={DownloadIcon}
      >
        {"Download CSV"}
      </Button>
    </CSVLink>
  );
}

function Disabled({
  tooltipText
}: {
  tooltipText: string;
}): React.ReactElement {
  return (
    <Tooltip tooltipContent={tooltipText}>
      <View style={{ flexDirection: "row" }}>
        <DownloadIcon
          color={Colors.TEXT.DARK.PLACEHOLDER}
          style={{ marginRight: 4 }}
          height={20}
          width={20}
        />
        <Text.BodyM weight={"medium"} color={Colors.TEXT.DARK.PLACEHOLDER}>
          {"Download CSV"}
        </Text.BodyM>
      </View>
    </Tooltip>
  );
}
