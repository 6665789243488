import { formatRentPaymentSchedule } from "@ailo/domains";
import { Colors, Text } from "@ailo/primitives";
import { formatDate } from "@ailo/services";
import { DescriptionList, Separator } from "@ailo/ui";
import { EditSectionHeader } from "local/tabs/projects/actions/ActionSidebar/ActionActivity/EditSectionHeader";
import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { View } from "react-native";
import { useRentReviewForm } from "../RentReviewFormContext";
import { RentReviewFields } from "../RentReviewFormData";

export function ReviewRentReviewStep({
  display,
  setCurrentStepNumber
}: {
  display: boolean;
  setCurrentStepNumber: Dispatch<SetStateAction<number>>;
}): ReactElement {
  const { watch } = useRentReviewForm();
  const amount = watch(RentReviewFields.amount);
  const period = watch(RentReviewFields.period);
  const scheduledRentReviewDate = watch(
    RentReviewFields.scheduledRentReviewDate
  );
  const effectiveDate = watch(RentReviewFields.effectiveDate);

  return (
    <View style={{ display: display ? undefined : "none" }}>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
        {"Please confirm the details are correct"}
      </Text.BodyM>
      <Separator
        style={{
          marginTop: 32,
          marginBottom: 32,
          marginLeft: 0,
          marginRight: -60
        }}
      />
      <EditSectionHeader
        title={"Rent review"}
        onPress={() => setCurrentStepNumber(1)}
        style={{ marginBottom: 20 }}
      />
      <DescriptionList
        size={"small"}
        columns={2}
        items={[
          {
            label: "Rent amount",
            descriptionBottom:
              amount && period
                ? formatRentPaymentSchedule({
                    amountInCents: amount.cents,
                    period
                  })
                : "N/A",
            columnIndex: 0
          },
          {
            label: "Effective date",
            descriptionBottom: effectiveDate
              ? formatDate(effectiveDate)
              : "N/A",
            columnIndex: 1
          }
        ]}
      />
      <DescriptionList
        size={"small"}
        columns={1}
        items={[
          {
            label: "Next rent review",
            descriptionBottom: scheduledRentReviewDate
              ? formatDate(scheduledRentReviewDate)
              : "N/A"
          }
        ]}
        style={{ marginTop: 16 }}
      />
    </View>
  );
}
