import React, { ReactElement } from "react";
import { Button, Modal } from "@ailo/ui";
import styled from "styled-components/native";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";
import { AiloRN, nsEntities } from "@ailo/ailorn";
import { useRemoveBondClaimMutation } from "local/graphql";
import { ClaimTenancyData, useBondAnalytics } from "local/domain/bond";
import { getState } from "local/common";

interface Props {
  toggleModal: () => void;
  visible: boolean;
  data: ClaimTenancyData;
  closeSidebar: () => void;
}

export function RemoveBondClaimModal({
  toggleModal,
  visible,
  data,
  closeSidebar
}: Props): ReactElement {
  const [removeClaimMutation] = useRemoveBondClaimMutation();
  const { onBondClaimRemoved } = useBondAnalytics();

  const bondAilorn = AiloRN.of(
    nsEntities.propertymanagement.bond,
    data.bond.id
  );

  const onPressRemove = async (): Promise<void> => {
    {
      await removeClaimMutation({
        variables: { bondAilorn: bondAilorn.toString() }
      });

      const state = getState(data?.property.address.state);

      onBondClaimRemoved({
        bondId: data.bond.id,
        managementId: data.managementId,
        state
      });

      toggleModal();
      closeSidebar();
    }
  };

  return (
    <Modal visible={visible} onBackgroundTouch={toggleModal}>
      <ModalElement>
        <View style={{ marginBottom: 19 }}>
          <Text.DisplayS style={{ marginBottom: 5 }}>
            {"Remove claim in Ailo"}
          </Text.DisplayS>
          <Text.BodyM>
            {
              "Are you sure you want to remove the bond claim in Ailo? This action does not directly affect any claims with the bond authority."
            }
          </Text.BodyM>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Button.Secondary onPress={toggleModal}>{"Cancel"}</Button.Secondary>
          <Button.Destructive onPress={onPressRemove}>
            {"Remove claim"}
          </Button.Destructive>
        </View>
      </ModalElement>
    </Modal>
  );
}

const ModalElement = styled(View)`
  background-color: ${Colors.WHITE};
  width: 480px;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  padding: 20px;
`;
