import React, { ReactElement } from "react";
import { DepositSummaryDescription } from "./DepositSummaryDescription";
import { PropertyContentCard } from "local/tabs/properties/components/PropertyScreen";
import {
  DepositSummaryContents,
  DepositSummaryContentsProps
} from "./DepositSummaryContents";

type Props = Partial<DepositSummaryContentsProps>;

export const DepositSummary = ({
  deposit,
  managementId,
  style,
  ...props
}: Props): ReactElement => {
  if (!deposit || !managementId) {
    return (
      <PropertyContentCard style={style} title={"No initial payment requested"}>
        <DepositSummaryDescription />
      </PropertyContentCard>
    );
  }

  return (
    <DepositSummaryContents
      deposit={deposit}
      managementId={managementId}
      style={style}
      {...props}
    />
  );
};
