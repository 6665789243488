import { RightChevronIcon, Text } from "@ailo/primitives";
import { Money } from "@ailo/ui";
import React, { ReactElement } from "react";
import { View } from "react-native";

interface Props {
  amount: Money;
  hasRightChevronIcon?: boolean;
  statusBadge?: ReactElement;
}

export function AmountAndStatus({
  amount,
  hasRightChevronIcon = false,
  statusBadge
}: Props): ReactElement {
  return (
    <View style={{ flexDirection: "row" }}>
      <View
        style={{
          alignItems: "flex-end",
          justifyContent: "space-between"
        }}
      >
        <Text.BodyM
          style={{ textAlign: "right", marginBottom: 4 }}
          weight={"medium"}
        >
          {amount.format()}
        </Text.BodyM>
        {statusBadge}
      </View>
      {hasRightChevronIcon && <RightChevronIcon height={20} width={20} />}
    </View>
  );
}
