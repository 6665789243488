import React from "react";
import { useOnFocus } from "@ailo/services";
import { SFC } from "@ailo/primitives";
import { FormerTenancyBanner } from "./FormerTenancyBanner";
import styled from "styled-components/native";
import { setVerifiedOnLegalEntity } from "local/domain/authz";
import { getTenantList } from "../../../utils";
import { ManageMenu } from "./ManageMenu";
import { parseBond } from "../../parseBond";
import { useNavigateToClaimTenancyBond } from "local/tabs";
import { useGetTenantsAndOutstandingLiabilityQuery } from "local/graphql";
import { BaseTenancyCard } from "../../BaseTenancyCard";
import { createBondMenuOptions } from "../../BaseTenancyCard/createBondMenuOptions";
import { useCurrentAgencyOrg } from "local/common";
import { AiloRN } from "@ailo/ailorn";
import { isPresent } from "ts-is-present";

interface Props {
  managementId: string;
  tenancyId: string;
  onDetailsPress: (tenancyId: string, title: string) => void;
  onRentAdjustmentsPress: (tenancyId: string) => void;
  onTenantPress?: (id: string, entityType: "Person" | "Company") => void;
  onAddBondDetailsPress: (tenancyId: string) => void;
  onEditBondDetailsPress: (tenancyId: string) => void;
  onViewBondClaimPress: (tenancyId: string) => void;
  onEditBondReferencePress: (tenancyId: string) => void;
}

export const FormerTenancyCard: SFC<Props> = ({
  style,
  managementId,
  tenancyId,
  onDetailsPress,
  onRentAdjustmentsPress,
  onTenantPress,
  onAddBondDetailsPress,
  onEditBondDetailsPress,
  onViewBondClaimPress,
  onEditBondReferencePress
}) => {
  const { ailoRN: currentAgencyOrgAilornString } = useCurrentAgencyOrg();

  const { data, loading, refetch } = useGetTenantsAndOutstandingLiabilityQuery({
    variables: {
      id: tenancyId,
      organisationAilorn: currentAgencyOrgAilornString
    }
  });

  const navigateToClaimBond = useNavigateToClaimTenancyBond({
    managementId,
    tenancyId
  });

  useOnFocus(refetch);

  if (loading && !data) return null;

  const tenancy = data?.tenancy;

  if (!tenancy || (!tenancy.endDate && !tenancy.voidedAt))
    throw new Error("cannot get former tenancy");

  const cardTitle = tenancy.endDate ? "Former tenancy" : "Voided tenancy";

  const tenants = getTenantList(tenancy.id, tenancy.tenantships?.items);

  const tenantContactAilorns = tenants
    .map(({ contact }) =>
      contact
        ? AiloRN.from(contact.ailorn, { expected: "contact:contact" })
        : null
    )
    .filter(isPresent);

  const bond = parseBond(tenancy);
  const bondMenuOptions = createBondMenuOptions({
    bond,
    onAddBondDetailsPress,
    onEditBondDetailsPress,
    tenancyId,
    navigateToClaimBond,
    onViewBondClaimPress,
    onEditBondReferencePress
  });

  return (
    <BaseTenancyCard
      managementId={managementId}
      title={cardTitle}
      style={style}
      tenants={tenants.map(setVerifiedOnLegalEntity)}
      tenantContactAilorns={tenantContactAilorns}
      headerRightComponent={
        <ManageMenu
          onRentAdjustmentsPress={(): void => onRentAdjustmentsPress(tenancyId)}
          bondMenuOptions={bondMenuOptions}
          isFormerTenancy
        />
      }
      onDetailsPress={(): void => onDetailsPress(tenancyId, cardTitle)}
      onTenantPress={onTenantPress}
      bondMenuOptions={bondMenuOptions}
      displayBondStatus={true}
    >
      <StyledFormerTenancyBanner
        endDate={tenancy.endDate}
        voidedAt={tenancy.voidedAt}
        outstandingBill={
          tenancy.payerBillsSummary?.outstandingBillsBalance.cents
        }
        outstandingRent={tenancy.liability?.terminationAmountDue?.cents}
      />
    </BaseTenancyCard>
  );
};

const StyledFormerTenancyBanner = styled(FormerTenancyBanner)`
  margin: 0 16px 16px;
`;
