import {
  formatLegalEntityName,
  formatNameInitials
} from "@ailo/domain-helpers";
import { Chip, InitialsAvatar } from "@ailo/ui";
import { LegalEntityNameFragment } from "local/graphql";
import { isEmpty } from "lodash";
import React, { ReactElement } from "react";
import { ChipsContainer } from "./ChipContainer";

export function InvestorChips({
  owners
}: {
  owners?: LegalEntityNameFragment[];
}): ReactElement | null {
  if (!owners || isEmpty(owners)) return null;

  return (
    <ChipsContainer>
      {owners.map((investor) => {
        const label = formatLegalEntityName(investor);
        const initials = formatNameInitials(investor) || "";

        return (
          <Chip
            key={investor.id}
            label={label}
            renderAvatar={(size) => (
              <InitialsAvatar initials={initials || ""} size={size} />
            )}
            style={{
              marginTop: 4,
              marginRight: 8
            }}
          />
        );
      })}
    </ChipsContainer>
  );
}
