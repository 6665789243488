import React, { ReactElement, useEffect } from "react";
import type { ComponentType } from "react";
import { withScreenComponent } from "@ailo/services";
import { Screens, useNavigation, useRoute } from "local/common";
import {
  ProjectListHeader,
  DefaultProjectList,
  ProjectListTabNavigationBadge
} from "./components";
import { ProjectStatus } from "local/graphql";
import { useShowCreateProjectModal } from "./components/ProjectList/useShowCreateProjectModal";

interface ProjectTabData {
  label: "Open" | "Closed";
  status: ProjectStatus;
  badge?: ComponentType<{
    active?: boolean;
  }>;
}

const ProjectListScreen = withScreenComponent((): ReactElement | null => {
  const { tab = "open", create } = useRoute<Screens.ProjectList>().params!;

  const { setParams } = useNavigation<Screens.ProjectList>();

  const showCreateProjectModal = useShowCreateProjectModal();

  useEffect(() => {
    if (create) {
      setParams({ create: false });
      showCreateProjectModal();
    }
  }, [create, setParams, showCreateProjectModal]);

  const currentIndex = PROJECT_TAB_DATA.findIndex(
    ({ label }) => label.toLowerCase() === tab.toLowerCase()
  );

  const { status } = PROJECT_TAB_DATA[currentIndex];

  return (
    <DefaultProjectList
      status={status}
      tabNavigation={
        <ProjectListHeader
          tabs={PROJECT_TAB_DATA}
          selectedTabIndex={currentIndex}
        />
      }
    />
  );
});

const PROJECT_TAB_DATA: ProjectTabData[] = [
  {
    label: "Open",
    status: ProjectStatus.Open,
    badge: ProjectListTabNavigationBadge
  },
  {
    label: "Closed",
    status: ProjectStatus.Closed
  }
];

export { ProjectListScreen };
