import React from "react";
import { Alert } from "@ailo/ui";
import { useManagementPublishedRecently } from "local/domain/propertyManagement";
import { SFC } from "@ailo/primitives";

interface Props {
  managementId: string;
}

const OffAppPaymentInfoBox: SFC<Props> = ({ managementId, style }) => {
  const { publishedRecently, loading } =
    useManagementPublishedRecently(managementId);

  if (loading || !publishedRecently) {
    return null;
  }

  return (
    <Alert
      type={"info"}
      message={
        "During migration, if a renter makes a payment outside of Ailo it may take up to 7 days for this information to pass through the banks and be visible here."
      }
      style={style}
    />
  );
};

export { OffAppPaymentInfoBox };
