export enum AustralianState {
  NSW = "NSW",
  ACT = "ACT",
  VIC = "VIC",
  SA = "SA",
  WA = "WA",
  NT = "NT",
  QLD = "QLD",
  TAS = "TAS"
}

function createStateRegex(state: AustralianState): RegExp {
  return RegExp(
    `^${state
      .split("")
      .map((char) => `[${char}]`)
      .join(".*")}.*`
  );
}

const StateRegexes = Object.values(AustralianState).map((state) => ({
  state,
  regex: createStateRegex(state)
}));

export function getState(
  state: string | undefined | null
): AustralianState | undefined {
  if (!state) {
    return undefined;
  }
  for (const stateRegex of StateRegexes) {
    if (stateRegex.regex.test(state.toUpperCase())) {
      return stateRegex.state;
    }
  }
  return undefined;
}
