import { LocalDate } from "@ailo/date";

export function isValidDateSelection({
  startDate,
  endDate
}: {
  startDate?: string;
  endDate?: string;
}): boolean {
  const start = LocalDate.parse(startDate);
  const end = LocalDate.parse(endDate);

  if (start === undefined || end === undefined) return false;

  return start.isSameOrBefore(end);
}
