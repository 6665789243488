import { LocalDate } from "@ailo/date";
import { formatRentPaymentSchedule, RentDomainType } from "@ailo/domains";
import { SFC } from "@ailo/primitives";
import { formatDateDefaultToNA } from "@ailo/services";
import { DescriptionList } from "@ailo/ui";
import { getScheduledRentReviewColor } from "local/domain/propertyManagement";
import React, { ComponentProps } from "react";

interface Props {
  rentReview?: RentDomainType | null;
  scheduledRentReviewDate?: string | null;
}

export const NextRentReviewDetails: SFC<Props> = ({
  style,
  rentReview,
  scheduledRentReviewDate
}) => {
  let items: ComponentProps<typeof DescriptionList>["items"] = [];

  if (rentReview) {
    items = [
      ...items,
      {
        label: "Rent review amount",
        description: formatRentPaymentSchedule(rentReview)
      },
      {
        label: "Effective date",
        description: formatDateDefaultToNA(rentReview.effectiveDate)
      }
    ];
  }

  if (scheduledRentReviewDate) {
    items = [
      ...items,
      {
        label: "Scheduled review",
        description: formatDateDefaultToNA(scheduledRentReviewDate),
        descriptionColor: getScheduledRentReviewColor(
          LocalDate.fromString(scheduledRentReviewDate)
        )
      }
    ];
  }

  return (
    <DescriptionList
      style={style}
      size={"small"}
      items={items}
      testID={"rent-review-details-section"}
    />
  );
};
