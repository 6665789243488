import React, { ReactElement, useMemo } from "react";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";
import { RefreshButton } from "./RefreshButton";
import { MigrationDetailsRefreshStatus } from "local/graphql";
import { Moment } from "moment";
import { RefreshInProgressButton } from "./RefreshInProgressButton";
import styled from "styled-components/native";

interface Props {
  error: boolean;
  refreshStatus?: MigrationDetailsRefreshStatus;
  lastRefreshedAt?: Moment;
}

function RefreshButtonAndInfo({
  error,
  lastRefreshedAt,
  refreshStatus
}: Props): ReactElement {
  const refreshFailed = refreshStatus === MigrationDetailsRefreshStatus.Failed;
  const refreshInProgress =
    refreshStatus === MigrationDetailsRefreshStatus.InProgress;

  const Button = useMemo(() => {
    if (refreshFailed) return <RefreshButton.Retry />;
    if (refreshInProgress) return <RefreshInProgressButton />;
    return <RefreshButton />;
  }, [refreshFailed, refreshInProgress]);

  const lastRefreshedText = useMemo(() => {
    if (error) return "Last refreshed failed to load";
    if (!lastRefreshedAt) return null;
    return `Last refreshed ${lastRefreshedAt.fromNow()}`;
  }, [error, lastRefreshedAt]);

  const refreshText = lastRefreshedText
    ? `${lastRefreshedText} · Updates may take up to 2 hours`
    : "Updates may take up to 2 hours";

  return (
    <Container>
      <ButtonRow>{Button}</ButtonRow>
      <Text.BodyXXS color={Colors.TEXT.DARK.SECONDARY}>
        {refreshText}
      </Text.BodyXXS>
    </Container>
  );
}

function Loading(): ReactElement {
  return (
    <Container>
      <ButtonRow>
        <RefreshButton.Loading />
      </ButtonRow>
      <Text.BodyXXS.Loading width={120} />
    </Container>
  );
}

RefreshButtonAndInfo.Loading = Loading;

export { RefreshButtonAndInfo };

const Container = styled(View)`
  align-items: flex-end;
`;

const ButtonRow = styled(View)`
  flex-direction: row;
  margin-bottom: 12px;
`;
