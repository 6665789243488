import { Text } from "@ailo/primitives";
import { ListItem, ListItemDate, ListItemStyle } from "@ailo/ui";
import { BillListItem } from "local/domain/bill";
import { FeeListItem } from "local/domain/fee";
import React, { ReactElement } from "react";
import { Expense } from "../graphql";

interface ExpensesListItemProps {
  expense: Expense;
  onExpensePress: () => void;
  style?: ListItemStyle;
}

export function ExpensesListItem({
  expense,
  onExpensePress,
  style
}: ExpensesListItemProps): ReactElement | null {
  return expense.__typename === "Bill" ? (
    <BillListItem bill={expense} onPress={onExpensePress} style={style} />
  ) : expense.__typename === "Fee" ? (
    <FeeListItem fee={expense} onPress={onExpensePress} style={style} />
  ) : null;
}

ExpensesListItem.Loading = function Loading({
  color,
  style
}: {
  color?: string;
  style?: ListItemStyle;
}) {
  return (
    <ListItem
      style={{
        verticalAlignCenter: "top",
        verticalAlignRight: "top",
        ...style
      }}
      header={<Text.BodyM.Loading width={120} color={color} />}
      subHeader={<Text.BodyS.Loading width={80} color={color} />}
      leftComponent={<ListItemDate.Loading showSticks={false} color={color} />}
    />
  );
};
