import { Card } from "@ailo/ui";
import React from "react";
import { SidebarSection } from "../SidebarSection";
import { OwnershipListItem, OwnershipListItemProps } from "./OwnershipListItem";

interface Props {
  ownerships: OwnershipListItemProps["ownership"][];
}

export function InvestorOwnershipsSection({
  ownerships
}: Props): React.ReactElement {
  return (
    <SidebarSection title={"Properties"}>
      <Card style={{ margin: 0 }}>
        {ownerships.map((ownership, index) => {
          return (
            <OwnershipListItem
              ownership={ownership}
              isLastElement={index === ownerships.length - 1}
              key={index}
            />
          );
        })}
      </Card>
    </SidebarSection>
  );
}
