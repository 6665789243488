import React, { ReactElement } from "react";
import { Button } from "@ailo/ui";
import { EditIcon } from "@ailo/primitives";

export function EditButton({
  onEditPress
}: {
  onEditPress: () => void;
}): ReactElement {
  return (
    <Button.Text
      leftIcon={EditIcon}
      style={{ flexDirection: "row" }}
      onPress={onEditPress}
    >
      {"Edit"}
    </Button.Text>
  );
}
