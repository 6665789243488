import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { SFC, Colors, Text } from "@ailo/primitives";

interface Props {
  labelText: string;
  fieldText?: string;
}

const SectionRow: SFC<Props> = ({ style, labelText, fieldText }) => {
  return (
    <Container style={style}>
      <InfoLabel>{labelText}</InfoLabel>
      {fieldText ? <InfoField>{fieldText}</InfoField> : null}
    </Container>
  );
};

const Container = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const InfoLabel = styled(Text.BodyS).attrs({
  numberOfLines: 1
})`
  flex: 1;
  color: ${Colors.TEXT.DARK.SECONDARY};
`;

const InfoField = styled(Text.BodyS).attrs({
  numberOfLines: 1
})`
  color: ${Colors.TEXT.DARK.PRIMARY};
  text-align: right;
  margin-left: 8px;
`;

export { SectionRow };
