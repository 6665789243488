import React from "react";
import { Colors } from "@ailo/primitives";
import { DateTimeWithTimeZone, TextCell } from "@ailo/ui";
import { useGetPaidToDateQuery } from "local/graphql";
import { hasLeaseEnded } from "./utils";
import { ErrorCell } from "./ErrorCell";
import { BasePropertyListRow } from "../BasePropertyListRow";

export function PaidToDateCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetPaidToDateQuery({
    variables: { managementId }
  });

  if (loading && !data) return <TextCell.Loading />;

  const tenancy = data?.management?.mostRecentTenancy;
  if (!tenancy)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const paidToDate = tenancy.liability?.effectivePaidToDate;
  if (paidToDate == null || hasLeaseEnded(tenancy.endDate))
    return <TextCell color={Colors.TEXT.DARK.SECONDARY}>{"—"}</TextCell>;

  const ailoPaidToDate = DateTimeWithTimeZone.fromLocalDate(paidToDate);

  return <TextCell>{ailoPaidToDate.format("DD MMM YYYY")}</TextCell>;
}

PaidToDateCell.Loading = TextCell.Loading;
