import { useHasFeature } from "@ailo/domains";
import { Colors, Link, Text } from "@ailo/primitives";
import { FormField, NoInputFormField, SelectInput } from "@ailo/ui";
import { Screens, useNavigation } from "local/common";
import {
  AddExpenseFormData,
  OneOffFeeBlueprintOption,
  useGetOneOffFeeOptions
} from "local/domain/expense";
import { PlatformFeatureId } from "local/graphql";
import { isEmpty } from "lodash";
import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";

interface Props {
  managementId: string;
  onViewFeeSchedulePress: () => void;
  toggleFieldsInIntermediateState(
    field: keyof AddExpenseFormData,
    inIntermediateState: boolean
  ): void;
  style?: StyleProp<ViewStyle>;
}

const name = "feeBlueprint";
const label = "Select a fee template";

export function OneOffFeeBlueprintInput({
  managementId,
  onViewFeeSchedulePress,
  toggleFieldsInIntermediateState,
  style
}: Props): ReactElement {
  const { control, errors } = useFormContext<{
    feeBlueprint: OneOffFeeBlueprintOption & { message: string };
  }>();
  const hasManagementFeeBlueprintFeature = useHasFeature(
    PlatformFeatureId.ManagementFeeBlueprint
  );

  const { loading, options } = useGetOneOffFeeOptions({ managementId });
  const noOptions = isEmpty(options);
  const inIntermediateState = loading || noOptions;

  useEffect(() => {
    toggleFieldsInIntermediateState(name, inIntermediateState);
    return () => {
      toggleFieldsInIntermediateState(name, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inIntermediateState]);

  const navigation = useNavigation();

  const navigateToSettingsScreen = (): void => {
    navigation.navigate(Screens.SettingsTab);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ value, onChange, ref }): ReactElement => {
        return (
          <View style={style} ref={ref} key={`${inIntermediateState}`}>
            {loading ? (
              <NoInputFormField loading={true} label={label} />
            ) : noOptions ? (
              <NoInputFormField
                message={
                  hasManagementFeeBlueprintFeature ? (
                    <Text.BodyS style={{ color: Colors.TEXT.DARK.SECONDARY }}>
                      {"No fee templates available for this property. "}
                      <Link
                        onPress={onViewFeeSchedulePress}
                        variant={"primary"}
                      >
                        {" Open the fee schedule "}
                      </Link>
                      {"to add one."}
                    </Text.BodyS>
                  ) : (
                    <Text.BodyS style={{ color: Colors.TEXT.DARK.SECONDARY }}>
                      {
                        "No fee templates available. Administrators can add fee templates from the"
                      }
                      <Link
                        onPress={navigateToSettingsScreen}
                        variant={"primary"}
                      >
                        {" Settings "}
                      </Link>
                      {"page."}
                    </Text.BodyS>
                  )
                }
                label={label}
              />
            ) : (
              <FormField
                label={label}
                testID={"FeeBlueprintInputFormField"}
                error={errors[name]?.message}
              >
                <SelectInput
                  inputRef={ref}
                  value={
                    value &&
                    (options.find((opt) => opt.value === value.value) ?? value)
                  }
                  onChange={onChange}
                  placeholder={"Select a fee template"}
                  options={options}
                  useTextInputHeight
                />
              </FormField>
            )}
          </View>
        );
      }}
      rules={{
        validate: (option): boolean | string =>
          (!isEmpty(option?.label) && !isEmpty(option?.value)) ||
          "Select fee template"
      }}
    />
  );
}
