import { useMountedState } from "@ailo/primitives";
import { useOnFocus } from "@ailo/services";
import { GridSortOrder } from "@ailo/ui";
import { useCallback, useEffect, useRef } from "react";
import {
  GridSortStateToApiParam,
  PropertyOnboardingListRequestExtraVariables,
  PropertyOnboardingListRequestFromBeginning,
  PropertyOnboardingListRequestNewPage,
  PropertyOnboardingListRequestParams,
  PropertyOnboardingListSendRequest
} from "../types";

interface PaginationState<
  TExtraVariables extends PropertyOnboardingListRequestExtraVariables
> {
  currentPage: number;
  requestNewPage: PropertyOnboardingListRequestNewPage<TExtraVariables>;
  requestFromBeginning: PropertyOnboardingListRequestFromBeginning<TExtraVariables>;
  lastRequestParams: PropertyOnboardingListRequestParams | null;
  lastRequestExtraVariables: TExtraVariables | null;
}

interface BidirectionalCursor {
  nextPage: string | null;
  previousPage: string | null;
}

export const MIN_NO_CHARS_TO_START_SEARCH = 3;

export const PROPERTY_ONBOARDING_LIST_PAGE_SIZE = 50;

export function usePaginationRequest<
  TExtraVariables extends PropertyOnboardingListRequestExtraVariables
>(
  latestCursor: BidirectionalCursor,
  sendRequest: PropertyOnboardingListSendRequest<TExtraVariables>,
  gridSortStateToApiParam?: GridSortStateToApiParam
): PaginationState<TExtraVariables> {
  const lastRequestParams = useRef<PropertyOnboardingListRequestParams | null>(
    null
  );
  const lastRequestExtraVariables = useRef<TExtraVariables | null>(null);
  const currentCursor = useRef<BidirectionalCursor>(latestCursor);
  const [currentPage, setCurrentPageNo] = useMountedState<number>(1);

  useEffect(() => {
    currentCursor.current = { nextPage: null, previousPage: null };

    if (lastRequestParams.current) {
      lastRequestParams.current = {
        sort: lastRequestParams.current.sort,
        cursor: null,
        before: false
      };
    }

    setCurrentPageNo(1);
  }, [setCurrentPageNo]);

  useEffect(() => {
    currentCursor.current = latestCursor;
  }, [latestCursor]);

  const onFocus = useCallback((): void => {
    sendRequest({
      requestParams: lastRequestParams.current ?? undefined,
      extraVariables: lastRequestExtraVariables.current ?? undefined
    });
  }, [sendRequest]);

  useOnFocus(onFocus);

  const runRequest = useCallback(
    ({
      sortOrder,
      forward,
      cursor,
      extraVariables
    }: {
      sortOrder?: GridSortOrder[];
      forward: boolean;
      cursor: string | null;
      extraVariables?: TExtraVariables;
    }): void => {
      const isFirstPage = cursor === null;
      const paginateBackward = isFirstPage ? false : !forward;

      const givenSortOrder = sortOrder
        ? gridSortStateToApiParam?.(sortOrder)
        : undefined;
      const sort = givenSortOrder ?? lastRequestParams.current?.sort;

      lastRequestParams.current = {
        cursor,
        sort,
        before: paginateBackward
      };

      lastRequestExtraVariables.current =
        extraVariables ?? lastRequestExtraVariables.current;

      sendRequest({
        requestParams: lastRequestParams.current ?? undefined,
        extraVariables: lastRequestExtraVariables.current ?? undefined
      });

      if (isFirstPage) setCurrentPageNo(1);
      else if (paginateBackward) setCurrentPageNo(currentPage - 1);
      else setCurrentPageNo(currentPage + 1);
    },
    [currentPage, sendRequest, setCurrentPageNo, gridSortStateToApiParam]
  );

  const requestNewPage = useCallback(
    ({
      sortOrder,
      forward,
      pageNo,
      extraVariables
    }: {
      sortOrder: GridSortOrder[];
      forward: boolean;
      pageNo: number;
      extraVariables?: TExtraVariables;
    }): void => {
      runRequest({
        sortOrder,
        forward,
        cursor: getCursorToQuery(forward, pageNo, currentCursor.current),
        extraVariables
      });
    },
    [runRequest]
  );

  const requestFromBeginning = useCallback(
    ({
      sortOrder,
      extraVariables
    }: {
      sortOrder?: GridSortOrder[];
      extraVariables?: TExtraVariables;
    }): void => {
      runRequest({
        sortOrder,
        extraVariables,
        forward: true,
        cursor: null
      });
    },
    [runRequest]
  );

  return {
    lastRequestParams: lastRequestParams.current,
    lastRequestExtraVariables: lastRequestExtraVariables.current,
    requestNewPage,
    requestFromBeginning,
    currentPage
  };
}

const getCursorToQuery = (
  forward: boolean,
  currPageNo: number,
  cursor: BidirectionalCursor
): string | null => {
  if (forward) return cursor.nextPage || null;

  if (currPageNo > 2) return cursor.previousPage || null;

  return null;
};
