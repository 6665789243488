import { ConfirmModal, LocalDate } from "@ailo/ui";
import { Text } from "@ailo/primitives";
import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { isFormerManagement } from "../../utils";
import { useStopEndingManagement } from "./useStopEndingManagement";
import { ManagementEndDetailsFragment } from "local/graphql";

export function StopEndingManagementConfirmModal({
  showConfirmModalHook,
  managementEndDetails
}: {
  showConfirmModalHook: [boolean, Dispatch<SetStateAction<boolean>>];
  managementEndDetails: ManagementEndDetailsFragment;
}): ReactElement | null {
  const [showConfirmModal, setShowConfirmModal] = showConfirmModalHook;

  const stopEndingManagement = useStopEndingManagement({
    managementEndDetails,
    onSuccess: () => {
      setShowConfirmModal(false);
    }
  });

  const { endDate: managementEndDate } = managementEndDetails;

  if (!stopEndingManagement || !managementEndDate) return null;

  const hasManagementEnded = isFormerManagement(managementEndDate);

  const label = hasManagementEnded
    ? "Restart management"
    : "Stop ending management";

  return (
    <ConfirmModal
      visible={showConfirmModal}
      title={label}
      onConfirm={stopEndingManagement?.mutation}
      onCancel={() => setShowConfirmModal(false)}
      confirmLabel={label}
      loading={stopEndingManagement?.loading}
      destructive
    >
      <Text.BodyM weight={"book"}>
        <Text.BodyM weight={"book"}>
          {"This action will remove the management end date "}
        </Text.BodyM>
        <Text.BodyM weight={"medium"}>
          {LocalDate.from(managementEndDate).format("D MMMM YYYY")}
        </Text.BodyM>
        <Text.BodyM weight={"book"}>
          {hasManagementEnded
            ? " and the management will no longer be ended. Any recurring fee schedules will start again."
            : " and the management will no longer be ending."}
        </Text.BodyM>
      </Text.BodyM>
    </ConfirmModal>
  );
}
