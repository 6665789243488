import React, { ReactElement } from "react";
import { Colors, SearchIcon, Text } from "@ailo/primitives";
import { ErrorAlertScreen, SelectInput, SelectInputLoading } from "@ailo/ui";
import { BaseActionActivity } from "../../BaseActionActivity";
import { assignFormActivityDescription } from "../assignFormActivityDescription";
import { ReiTemplateOption } from "./types";
import { ReiFormDeletedAlert } from "local/domain/project";

interface Props {
  onGenerateFormPress(): void;
  generateDisabled: boolean;
  templateOptions: ReiTemplateOption[];
  selectedTemplate?: ReiTemplateOption;
  onChange: (newTemplate?: ReiTemplateOption) => void;
  displayDeletedFormAlert?: boolean;
}

function SearchSelectLabel(): React.ReactElement {
  return (
    <SearchIcon
      color={Colors.TEXT.DARK.SECONDARY}
      style={{ marginLeft: 10, marginRight: 6, height: 20, width: 20 }}
    />
  );
}

export function SelectTemplateViewUI({
  onGenerateFormPress,
  generateDisabled,
  templateOptions,
  selectedTemplate,
  onChange,
  displayDeletedFormAlert = false
}: Props): ReactElement {
  return (
    <BaseActionActivity
      description={assignFormActivityDescription}
      button={{
        label: "Generate form",
        onPress: onGenerateFormPress,
        disabled: generateDisabled
      }}
    >
      {displayDeletedFormAlert && <ReiFormDeletedAlert />}
      <SelectTemplateText />
      <SelectInput
        controlStyle={{ marginBottom: 24 }}
        options={templateOptions}
        value={selectedTemplate}
        onChange={onChange}
        useTextInputHeight
        placeholder={"Search templates..."}
        label={<SearchSelectLabel />}
      />
    </BaseActionActivity>
  );
}

SelectTemplateViewUI.Error = function SelectTemplateViewUIError({
  onRetry
}: {
  onRetry(): void;
}): React.ReactElement {
  return (
    <ErrorAlertScreen
      title={"There was a problem fetching the form information."}
      onRetry={onRetry}
    />
  );
};

SelectTemplateViewUI.Loading =
  function SelectTemplateViewUILoading(): React.ReactElement {
    return (
      <BaseActionActivity
        description={assignFormActivityDescription}
        button={{
          label: "Generate form",
          disabled: true
        }}
      >
        <SelectTemplateText />
        <SelectInputLoading
          style={{ height: 52, borderRadius: 4, marginBottom: 24 }}
        />
      </BaseActionActivity>
    );
  };

function SelectTemplateText(): React.ReactElement {
  return (
    <Text.BodyS
      style={{
        marginBottom: 8,
        color: Colors.TEXT.DARK.SECONDARY
      }}
    >
      {"Select template"}
    </Text.BodyS>
  );
}
