import React from "react";
import { Screens, useRoute } from "local/common";
import { Badge } from "@ailo/ui";
import { useOnFocus } from "@ailo/services";
import { useGetProjectActionsCountQuery } from "local/graphql";

interface Props {
  active?: boolean;
}

export function ActionsCountBadge({
  active = true
}: Props): React.ReactElement {
  const { id } = useRoute<Screens.Project>().params!;
  const { data, loading, refetch } = useGetProjectActionsCountQuery({
    variables: {
      projectId: id!
    }
  });
  useOnFocus(refetch);

  const pageTotal = data?.project?.actions?.pageInfo?.total;

  if (loading) {
    return <Badge.Loading width={27} style={{ marginLeft: 8 }} />;
  }

  return (
    <Badge type={active ? "ailo" : "outline"} style={{ marginLeft: 8 }}>
      {pageTotal}
    </Badge>
  );
}
