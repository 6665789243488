import React, { useCallback } from "react";
import { View, ViewStyle } from "react-native";
import { Text } from "@ailo/primitives";
import { formatAddress, useGlobalModal } from "@ailo/ui";
import { useNavigateToProperty } from "local/domain/propertyManagement";
import { BackButton } from "./BackButton";
import styled from "styled-components/native";
import { ProjectMenu } from "./ProjectMenu";
import { ProjectDetailsFragment } from "local/graphql";
import { ProjectEditButton } from "./ProjectEditButton";
import { ProjectModalForm } from "local/modals/ProjectModalForm/ProjectModalForm";
import { Screens, useRoute } from "local/common";
import { useNavigation } from "@react-navigation/native";
import { getManagementIfExists } from "local/domain/project";

interface Props {
  style?: ViewStyle;
  project: ProjectDetailsFragment;
}

export function ProjectScreenHeader({
  style,
  project
}: Props): React.ReactElement | null {
  const modal = useGlobalModal();
  const navigateToProperty = useNavigateToProperty();

  const navigation = useNavigation();
  const { previousScreen = Screens.Property } =
    useRoute<Screens.Project>().params!;
  const management = getManagementIfExists(project);
  const shouldGoBackToPropertyScreen =
    previousScreen === Screens.Property && management;

  const onBackButtonPress = useCallback(() => {
    if (shouldGoBackToPropertyScreen) {
      navigateToProperty(management.id);
    } else {
      navigation.navigate(Screens.ProjectList, {});
    }
  }, [
    navigateToProperty,
    navigation,
    management,
    shouldGoBackToPropertyScreen
  ]);

  return (
    <HeaderContainer style={style}>
      <BackButton onPress={onBackButtonPress}>
        {shouldGoBackToPropertyScreen
          ? formatAddress(management.property.address, {
              format: "street"
            })
          : "Projects"}
      </BackButton>
      <ProjectOptionsContainer>
        <ProjectEditButton
          onPress={() => {
            modal.show(
              <ProjectModalForm
                managementId={management?.id}
                onDismiss={modal.hide}
                project={project}
              />
            );
          }}
        />
        <ProjectMenu project={project} />
      </ProjectOptionsContainer>
    </HeaderContainer>
  );
}

ProjectScreenHeader.Loading = function ProjectScreenHeaderLoading({
  style
}: {
  style?: ViewStyle;
}): React.ReactElement {
  return (
    <View style={style}>
      <Text.BodyS.Loading width={120} />
    </View>
  );
};

const HeaderContainer = styled(View)`
  height: 32px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const ProjectOptionsContainer = styled(View)`
  flex-direction: row;
  justify-content: flex-end;
`;
