import { useNavigation, Screens } from "local/common";
import { useCallback } from "react";

export function useNavigateToRentAdjustments(): ({
  managementId,
  tenancyId
}: {
  managementId: string;
  tenancyId: string;
}) => void {
  const navigation = useNavigation();

  return useCallback(
    ({ managementId, tenancyId }): void => {
      navigation.navigate(Screens.PropertiesTab, {
        screen: Screens.RentAdjustments,
        params: {
          managementId,
          tenancyId
        }
      });
    },
    [navigation]
  );
}
