import React from "react";
import { View } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";
import { SFC } from "@ailo/primitives";
import { LabelledTextInput } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";

interface Props {
  accountNumber: string;
}

const AccountNumberInput: SFC<Props> = ({ accountNumber, style }) => {
  const { control } = useFormContext<AddBillFormData>();

  return (
    <View style={style}>
      <Controller
        as={LabelledTextInput}
        name={"payment.accountNumber"}
        label={"Account Number"}
        placeholder={"Account Number"}
        defaultValue={accountNumber}
        editable={false}
        control={control}
        onChange={([selected]: any): string => {
          return selected;
        }}
        rules={{
          validate: (accountNumber): boolean | string =>
            !isEmpty(accountNumber) ||
            "Bank account payment method missing account number"
        }}
        testID={"AccountNumberInputFormField"}
      />
    </View>
  );
};

export { AccountNumberInput };
