import { mapUploadedFileToFile, translateFile } from "@ailo/domains";
import { DeleteIcon } from "@ailo/primitives";
import { useToastContext, UploadedFile } from "@ailo/ui";
import {
  useProjectAnalytics,
  useAddProjectFile,
  AddProjectFileType,
  ProjectFileType
} from "local/domain/project";
import { Dispatch, useCallback } from "react";
import { useRemoveFiles } from "./ProjectFiles/useRemoveFiles";

export type ProjectFileStateType = {
  projectFilesState: ProjectFileType[];
  setProjectFilesState: Dispatch<React.SetStateAction<ProjectFileType[]>>;
};

type ProjectFileDragAndDrop = {
  addProjectFile: ({
    projectId,
    ailorn,
    file
  }: AddProjectFileType) => Promise<void>;
  getSecondaryActionsForFile?: (
    file: UploadedFile
  ) => UploadedFile["secondaryAction"];
  projectFiles: ProjectFileType[];
  onFileOpened: (file: UploadedFile) => void;
  onFileDownloaded: (file: UploadedFile) => void;
};

export function useProjectFileDragAndDrop({
  projectId,
  projectFilesState,
  setProjectFilesState
}: {
  projectId: string;
  projectFilesState: ProjectFileStateType["projectFilesState"];
  setProjectFilesState: ProjectFileStateType["setProjectFilesState"];
}): ProjectFileDragAndDrop {
  const toast = useToastContext();

  const {
    onFileOpened,
    onFileDownloaded,
    onFileUploadStarted,
    onFileRemoved,
    onFileUploaded,
    onFileUploadFailed
  } = useProjectAnalytics(projectId);

  const [addProjectFile] = useAddProjectFile({
    onStart: ({ file }: ProjectFileType) => {
      return onFileUploadStarted({
        fileSize: file.size,
        fileName: file.name
      });
    },
    onCompleted: (fileDetails) => {
      onFileUploaded(fileDetails);
      setProjectFilesState((prev) => [
        ...prev,
        {
          fileId: fileDetails.id,
          file: translateFile(fileDetails.file)
        }
      ]);
    },
    onError: (fileDetails) => onFileUploadFailed(fileDetails)
  });

  const [onDeletePressed] = useRemoveFiles({
    onError: () => {
      toast.show({
        type: "error",
        message: "Unable to delete file. Please try again."
      });
    },
    onCompleted: () => {
      toast.show({
        type: "success",
        message: "File deleted"
      });
    }
  });

  const deleteFile =
    (uploadedFile: UploadedFile) =>
    (otherFiles: UploadedFile[]): UploadedFile[] => {
      let projectFile: ProjectFileType | undefined;
      setProjectFilesState((prev) => {
        projectFile = prev.find(({ file }) =>
          file.ailorn?.equals(uploadedFile.ailorn)
        );
        return prev;
      });

      if (projectFile) {
        onDeletePressed(projectId, [projectFile.fileId]);
        onFileRemoved(mapUploadedFileToFile(projectFile.file));
        const currentFiles = otherFiles.filter(
          ({ ailorn }) => !ailorn?.equals(uploadedFile.ailorn)
        );
        setProjectFilesState((prev) =>
          prev.filter(({ fileId }) => fileId !== projectFile?.fileId)
        );

        return currentFiles;
      }

      return otherFiles;
    };

  const getSecondaryActionsForFile = (
    file: UploadedFile
  ): UploadedFile["secondaryAction"] => {
    return {
      type: "menu",
      getOptions: (otherFiles) => {
        return [
          {
            Icon: DeleteIcon,
            label: "Delete file",
            onSelect: () => deleteFile(file)(otherFiles)
          }
        ];
      }
    };
  };

  const handleOnOpen = useCallback(
    (file: UploadedFile) => {
      onFileOpened(mapUploadedFileToFile(file));
    },
    [onFileOpened]
  );

  const handleOnDownload = useCallback(
    (file: UploadedFile) => {
      onFileDownloaded(mapUploadedFileToFile(file));
    },
    [onFileDownloaded]
  );

  return {
    projectFiles: projectFilesState,
    addProjectFile,
    getSecondaryActionsForFile,
    onFileOpened: handleOnOpen,
    onFileDownloaded: handleOnDownload
  };
}
