import React from "react";
import { View } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";
import { SFC } from "@ailo/primitives";
import { LabelledTextInput } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";

interface Props {
  bsb: string;
}

const BSBInput: SFC<Props> = ({ bsb, style }) => {
  const { control } = useFormContext<AddBillFormData>();

  return (
    <View style={style}>
      <Controller
        as={LabelledTextInput}
        name={"payment.bsb"}
        label={"BSB"}
        placeholder={"BSB"}
        defaultValue={bsb}
        editable={false}
        control={control}
        onChange={([selected]: any): string => {
          return selected;
        }}
        rules={{
          validate: (bsb): boolean | string =>
            !isEmpty(bsb) || "Bank account payment method missing bsb"
        }}
        testID={"BsbInputFormField"}
      />
    </View>
  );
};

export { BSBInput };
