import {
  Button,
  Grid,
  GridColumnOptions,
  GridRenderRowContainerProps,
  GridSortOrder,
  GridStyle
} from "@ailo/ui";
import { Colors } from "@ailo/primitives";
import React, { useMemo, useState } from "react";
import { FilterConfig } from "../FilterFactory";
import { useFilter } from "../utils";
import { BadgeCell, ReportTextCell } from "./Cell";
import { RowContainer } from "./RowContainer";
import { SimpleReportTableLoading } from "./SimpleReportTableLoading";
import {
  ColumnCfg,
  ColumnDisplayType,
  ColumnsCfg,
  ReportCellValue,
  ReportTableColumn,
  ReportTableDefaultSort,
  ReportTableRow
} from ".";
import { NavigateCfg } from "../utils/tableNavigation";
import { orderBy } from "lodash";
import { StyleProp, ViewStyle } from "react-native";

export type TotalRow<TRow> = Partial<TRow> & { totalTitle: string };

export type GridRow<TRow> = Partial<TRow> & {
  key: string;
  rowType: "row" | "total";
};

interface ReportTableProps<TRow extends ReportTableRow> {
  /**
   * Column listing from the reporting service. Display configuration is configured on columnCfg on the client.
   */
  columns: ReportTableColumn[];
  columnsConfig?: ColumnsCfg<TRow>;
  rows: TRow[];
  filterName?: string;
  filterConfig?: FilterConfig<TRow>[];
  defaultSort?: ReportTableDefaultSort<TRow>;
  rowLink?: (row: GridRow<TRow>) => NavigateCfg | null;
  totals?: TotalRow<TRow>[];
}

const displayDefaults: Record<
  ColumnDisplayType,
  Pick<ColumnCfg<undefined>, "horizontalAlign">
> = {
  currency: { horizontalAlign: "right" },
  pct: { horizontalAlign: "right" },
  default: { horizontalAlign: "left" },
  count: { horizontalAlign: "right" }
};

export function SimpleReportTable<TRow extends ReportTableRow>({
  rows,
  columns,
  columnsConfig,
  filterConfig,
  defaultSort,
  rowLink,
  totals
}: ReportTableProps<TRow>): React.FunctionComponentElement<
  ReportTableProps<TRow>
> {
  const [sortOrder, setSortOrder] = useState(defaultSort);
  const [showRows, setShowRows] = useState(50);

  const filter = useFilter(filterConfig);
  const onSortOrderChange = (sortOrder: GridSortOrder): void =>
    setSortOrder(sortOrder as ReportTableDefaultSort<TRow>);
  // const navigation = useNavigation();

  const filteredRows = useMemo(
    () =>
      rows.filter(filter)?.map((row, i) => ({
        key: `row_${i}`,
        rowType: "row",
        ...row
      })),
    [filter, rows]
  );

  const { gridRows, showMore } = useMemo(() => {
    let sortedFilteredRows = filteredRows;
    if (sortOrder) {
      const sortCol = columnsConfig?.[sortOrder.columnKey];
      const sortValue = (row: TRow): ReportCellValue =>
        sortCol?.sortValue ? sortCol?.sortValue(row) : row[sortOrder.columnKey];
      sortedFilteredRows = orderBy(
        filteredRows,
        [sortValue],
        [sortOrder.direction == "ASC" ? "asc" : "desc"]
      );
    }

    const limitedRows = sortedFilteredRows.slice(
      0,
      showRows
    ) as GridRow<TRow>[];

    const displayRows =
      totals && columns?.length
        ? totals
            .map((t, i) => {
              const { totalTitle, ...totalRow } = t;
              return {
                ...totalRow,
                [columns[0].key]: totalTitle,
                key: `total_${i}`,
                rowType: "total"
              } as any as GridRow<TRow>;
            })
            .concat(limitedRows)
        : limitedRows;

    return {
      showMore: filteredRows.length > showRows,
      gridRows: displayRows
    };
  }, [filteredRows, sortOrder, showRows, totals, columns, columnsConfig]);

  const gridColumns: GridColumnOptions<GridRow<TRow>>[] = columns
    .map((column) => ({
      columnConfig: columnsConfig?.[column.key] ?? ({} as ColumnCfg<TRow>),
      column
    }))
    .filter(({ columnConfig }) => !columnConfig.downloadOnly)
    .map(({ column, columnConfig }) => {
      const columnCfgDefaults = displayDefaults[columnConfig.type ?? "default"];
      const { horizontalAlign, maxWidth, sortable, width, renderCell } =
        Object.assign(
          { horizontalAlign: "left", sortable: true, width: 1 },
          columnCfgDefaults,
          columnConfig
        );

      return {
        key: column.key,
        name: columnConfig.header ?? column.header,
        maxWidth,
        sortable,
        width,
        verticalAlign: "middle",
        horizontalAlign,
        renderCell: renderCell
          ? renderCell
          : ({ row }) => {
              return columnConfig.badge ? (
                <BadgeCell
                  columnKey={column.key}
                  reportTableRow={row}
                  badgeMapping={columnConfig.badge.valueColorMapping}
                />
              ) : (
                <ReportTextCell
                  columnKey={column.key}
                  reportTableRow={row}
                  alignItems={horizontalAlign}
                  cfg={columnConfig}
                />
              );
            }
      };
    });

  return (
    <Grid
      flat
      style={
        {
          paddingLeft: 0,
          paddingRight: 0,
          borderRadius: 4,
          backgroundColor: Colors.WHITE,
          marginTop: 0,
          /* override some styling on the grid container. Keep default flex and overflow so that it grows with content and doesn't scroll - this allows use of position: sticky */
          flex: "unset",
          overflow: "unset"
        } as unknown as StyleProp<GridStyle>
      }
      bodyStyle={{
        borderRadius: 4,
        overflow: "hidden"
      }}
      columns={gridColumns}
      rows={gridRows}
      sortOrder={sortOrder}
      onSortOrderChange={onSortOrderChange}
      renderRowContainer={(props) => {
        const lastRow = props.rowIndex == gridRows.length - 1;
        return (
          <ReportTableRowContainer
            {...props}
            lastRow={lastRow}
            rowLink={rowLink}
          />
        );
      }}
      rowHeadStyle={
        { position: "sticky", top: 0 } as any as StyleProp<ViewStyle>
      }
      footerCaption={
        showMore ? (
          <Button.Text
            onPress={() => {
              setShowRows(showRows + 100);
            }}
            style={{ margin: 16 }}
          >
            {"Load more"}
          </Button.Text>
        ) : (
          <></>
        )
      }
    />
  );
}

SimpleReportTable.Loading = SimpleReportTableLoading;

type ReportTableRowContainerProps<TRow extends ReportTableRow> =
  GridRenderRowContainerProps<GridRow<TRow>> & {
    rowLink?: (row: GridRow<TRow>) => NavigateCfg | null;
    lastRow?: boolean;
  };

export function ReportTableRowContainer<TRow extends ReportTableRow>(
  props: ReportTableRowContainerProps<TRow>
): React.FunctionComponentElement<ReportTableRowContainerProps<TRow>> {
  const { rowLink, lastRow, ...passProps } = props;

  return (
    <RowContainer<GridRow<TRow>>
      {...passProps}
      style={[
        props.style,
        props.row.rowType == "total" ? { backgroundColor: Colors.CLOUD } : null,
        lastRow ? { borderBottomWidth: 0 } : null
      ]}
      link={props.row.rowType == "total" ? null : rowLink?.(props.row)}
    />
  );
}
