import React, { ReactElement, useEffect } from "react";
import { ActionDetailsFragment, FileKind } from "local/graphql";
import { BaseActionCard } from "../BaseActionCard";
import { ActionCardFooter } from "../shared/ActionCardFooter";
import { Badge } from "@ailo/ui";
import { useGetClaimedFilesCount } from "local/domain/file";
import styled from "styled-components/native";

type Props = {
  action: ActionDetailsFragment;
  projectId: string;
  inspectionAilorn: string;
};

export function GenerateInspectionReportActionCardBase({
  action,
  projectId,
  inspectionAilorn
}: Props): ReactElement {
  const {
    data: inspectionFilesCount,
    startPolling,
    stopPolling
  } = useGetClaimedFilesCount(inspectionAilorn, FileKind.InspectionFile);

  useEffect(() => {
    if (inspectionFilesCount) {
      const { totalFiles, validFiles } = inspectionFilesCount;
      if (validFiles !== totalFiles) {
        startPolling(5000);
      } else {
        stopPolling();
      }
    }
  }, [inspectionFilesCount, startPolling, stopPolling]);

  let additionalStatus: React.ReactNode | undefined;

  if (inspectionFilesCount) {
    const { totalFiles, validFiles } = inspectionFilesCount;
    additionalStatus =
      validFiles === totalFiles ? (
        <BadgeWithMarginLeft type={"success"}>
          {"Sync complete"}
        </BadgeWithMarginLeft>
      ) : (
        <BadgeWithMarginLeft type={"attention"}>
          {"Waiting for sync"}
        </BadgeWithMarginLeft>
      );
  }

  return (
    <BaseActionCard action={action} projectId={projectId}>
      <ActionCardFooter action={action} additionalStatus={additionalStatus} />
    </BaseActionCard>
  );
}

const BadgeWithMarginLeft = styled(Badge)`
  margin-left: 4px;
`;
