import React from "react";
import { Colors, Skeleton } from "@ailo/primitives";
import {
  Badge,
  CellContainer,
  DateTimeWithTimeZone,
  TextCell,
  TextCellContent
} from "@ailo/ui";
import { useGetScheduledRentReviewDateQuery } from "local/graphql";
import { ErrorCell } from "./ErrorCell";
import { BasePropertyListRow } from "../BasePropertyListRow";

function ScheduledRentReviewDateCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetScheduledRentReviewDateQuery({
    variables: { managementId }
  });

  if (loading && !data) {
    return <Loading />;
  }

  const tenancy = data?.management?.mostRecentTenancy;
  if (!tenancy)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const scheduledRentReviewDate = tenancy.scheduledRentReviewDate;

  if (!scheduledRentReviewDate) {
    return <TextCell color={Colors.TEXT.DARK.SECONDARY}>{"—"}</TextCell>;
  }

  const ailoScheduledRentReviewDate = DateTimeWithTimeZone.fromLocalDate(
    scheduledRentReviewDate
  );

  const daysToRentReview = ailoScheduledRentReviewDate.daysAfterToday();

  return (
    <CellContainer>
      <Badge style={{ marginTop: 2 }}>
        {`${daysToRentReview} ${daysToRentReview > 1 ? "days" : "day"}`}
      </Badge>
      <TextCellContent
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginTop: 2 }}
      >
        {ailoScheduledRentReviewDate.format("DD MMM YYYY")}
      </TextCellContent>
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <Badge.Loading
        style={{
          width: 60,
          marginTop: 2
        }}
      />
      <Skeleton
        style={{
          width: 70,
          height: 12,
          marginTop: 8
        }}
      />
    </CellContainer>
  );
}

ScheduledRentReviewDateCell.Loading = Loading;

export { ScheduledRentReviewDateCell };
