import React, { FC } from "react";
import { useOnFocus } from "@ailo/services";
import { useGetOccupancyStatusAndTenancyDetailsQuery } from "local/graphql";
import { getOccupancyStatusInfo, getOccupancyBadgeType } from "./utils";
import { Skeleton, Colors } from "@ailo/primitives";
import { OccupancyStatusDisplay } from "./components";
import { Badge, CellContainer } from "@ailo/ui";

interface Props {
  managementId: string;
}

interface Statics {
  Loading: typeof Loading;
}

const OccupancyStatusLogic: FC<Props> & Statics = ({ managementId }) => {
  const { data, loading, refetch } =
    useGetOccupancyStatusAndTenancyDetailsQuery({
      variables: { managementId }
    });

  useOnFocus(refetch);

  if (loading && !data) {
    return <Loading />;
  }

  const management = data?.management;

  if (!management) {
    throw new Error(
      `[OccupancyStatus] Management "${managementId}" is undefined`
    );
  }

  const occupancyStatus = management.occupancyStatus;
  if (!occupancyStatus) {
    throw new Error(
      `[OccupancyStatus] Occupancy status for Management "${managementId}" is undefined`
    );
  }

  const mostRecentTenancy = management?.mostRecentTenancy;
  const agreement = mostRecentTenancy?.mostRecentTenancyAgreement;
  const nextTenancyStartDate = management.nextTenancy?.startDate;

  const statusExtraInfo = getOccupancyStatusInfo({
    managementId,
    occupancyStatus,
    tenancyEndDate: mostRecentTenancy?.endDate,
    fixedTermAgreementEndDate: agreement?.fixedTermEndDate,
    managementStartDate: management.startDate,
    newTenancyStartDate: nextTenancyStartDate
  });

  const badgeType = getOccupancyBadgeType(
    occupancyStatus,
    nextTenancyStartDate
  );

  return (
    <OccupancyStatusDisplay
      status={occupancyStatus}
      statusExtraInfo={statusExtraInfo}
      badgeType={badgeType}
    />
  );
};

const Loading = (): React.ReactElement => (
  <CellContainer>
    <Badge.Loading
      style={{
        width: 80
      }}
    />
    <Skeleton
      style={{
        width: 120,
        height: 12,
        backgroundColor: Colors.CHARCOAL,
        marginTop: 8
      }}
    />
  </CellContainer>
);

OccupancyStatusLogic.Loading = Loading;

export { OccupancyStatusLogic };
