import React, { ReactElement } from "react";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { OptionLabelProps } from "@ailo/ui";
import { ChatSearchBarOptionData, OnAppIndicator } from "@ailo/domains";

export const ChatSearchBarWebDropDownItem = React.memo(
  function ChatSearchBarWebDropDownItem({
    option,
    style
  }: OptionLabelProps<ChatSearchBarOptionData>): ReactElement {
    const Icon = option.leftComponent ?? null;

    return (
      <OuterContainer style={style}>
        {Icon}
        <InnerContainer>
          <TitleContainer>
            <Text.BodyM numberOfLines={1}>{option.label}</Text.BodyM>
            {option.data && (
              <StyledOnAppIndicator contact={option.data.contact} />
            )}
          </TitleContainer>
          {Boolean(option.description) && (
            <Text.BodyS
              numberOfLines={1}
              weight={"book"}
              color={Colors.TEXT.DARK.SECONDARY}
            >
              {option.description}
            </Text.BodyS>
          )}
          {option.footer && <FooterContainer>{option.footer}</FooterContainer>}
        </InnerContainer>
      </OuterContainer>
    );
  }
);

const OuterContainer = styled(View)`
  flex: 1;
  flex-direction: row;
  padding: 12px;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
`;

const InnerContainer = styled(View)`
  padding-left: 16px;
  flex-direction: column;
  flex: 1;
`;

const TitleContainer = styled(View)`
  display: flex;
  flex-direction: row;
`;

const FooterContainer = styled(View)`
  margin-top: 4px;
`;

const StyledOnAppIndicator = styled(OnAppIndicator).attrs({ size: 20 })`
  margin-left: 8px;
`;
