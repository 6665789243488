import React, { ReactElement } from "react";
import {
  AllocationsInput,
  CreateReceiptFormData,
  LeftAlignedText,
  LeftAlignedTextLoading,
  RightAlignedText,
  RightAlignedTextLoading,
  AllocationAmountInput,
  AllocationDescriptionInput
} from "local/tabs";
import { View } from "react-native";

import { Money } from "@ailo/money";

import { Controller, useFormContext } from "react-hook-form";
import {
  AllocationsTableRow,
  HeaderCellContainer
} from "../AllocationsInput/AllocationsTableRow";
import styled from "styled-components/native";
import { Colors, Text } from "@ailo/primitives";

const WalletReceiptAllocationsTableRow = styled(AllocationsTableRow).attrs(
  () => ({
    columns: [{ flex: 1 }, { flex: 5 }, { flex: 2 }]
  })
)``;

export function WalletReceiptAllocationsInput(): ReactElement {
  const { control, errors } = useFormContext<CreateReceiptFormData>();
  return (
    <AllocationsInput
      emptyTitle={"Please select a wallet to show allocations"}
      type={"DEPOSIT"}
      walletFilter={"payableTo"}
      headerRow={
        <WalletReceiptAllocationsTableRow cellContainer={HeaderCellContainer}>
          <LeftAlignedText color={Colors.TEXT.DARK.SECONDARY}>
            {"Allocation"}
          </LeftAlignedText>
          <LeftAlignedText color={Colors.TEXT.DARK.SECONDARY}>
            {"Details"}
          </LeftAlignedText>
          <RightAlignedText color={Colors.TEXT.DARK.SECONDARY}>
            {"Amount"}
          </RightAlignedText>
        </WalletReceiptAllocationsTableRow>
      }
      loadingRow={
        <WalletReceiptAllocationsTableRow>
          <LeftAlignedTextLoading />
          <LeftAlignedTextLoading />
          <RightAlignedTextLoading />
        </WalletReceiptAllocationsTableRow>
      }
      renderRow={(item, i) => {
        return (
          <WalletReceiptAllocationsTableRow key={i}>
            <View>
              <Controller
                name={`allocations[${i}].included`}
                defaultValue={true}
                control={control}
                render={() => <View />}
              />
              <Text.BodyS>{item.name}</Text.BodyS>
            </View>

            <View style={{ flex: 1 }}>
              <Controller
                name={`allocations[${i}].purposeId`}
                control={control}
                defaultValue={item.purposeId}
                render={() => <View />}
              />

              <Controller
                name={`allocations[${i}].description`}
                control={control}
                defaultValue={item.description}
                render={(props) => (
                  <AllocationDescriptionInput
                    {...props}
                    allocationPurpose={item}
                    error={errors.allocations?.[i]?.description?.message}
                  />
                )}
              />
            </View>
            <Controller
              name={`allocations[${i}].amount`}
              control={control}
              defaultValue={item.amountDue || null}
              render={(props): React.ReactElement => (
                <AllocationAmountInput
                  allocationPurpose={item}
                  {...props}
                  error={errors.allocations?.[i]?.amount?.cents?.message}
                />
              )}
              rules={{
                required: "Enter amount",
                validate: (amount: Money) =>
                  amount.cents >= 0 || "Enter non-negative amount"
              }}
            />
          </WalletReceiptAllocationsTableRow>
        );
      }}
    />
  );
}
