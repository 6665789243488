import React from "react";
import { EmptyGrid } from "@ailo/ui";
import { StyleProp, ViewStyle } from "react-native";

export function EmptyFilteredPropertyList({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <EmptyGrid
      message={"We couldn’t find any matches"}
      secondaryMessage={"Please try a different search term or filter"}
      style={style}
    />
  );
}
