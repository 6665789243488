import { AiloRN, services } from "@ailo/ailorn";
import { DateRange, LocalDate, LocalDateFormat } from "@ailo/date";
import { formatLegalEntityName, formatNames } from "@ailo/domain-helpers";
import { Badge } from "@ailo/ui";
import { Tenancy, TenancyType, Tenant } from "local/domain/propertyManagement";
import React from "react";
import { PayerSelectOption } from "../AddBillFormData";

export function createManagementTenantsSelectOption(
  tenancy?: Tenancy
): PayerSelectOption | undefined {
  if (!tenancy) {
    return undefined;
  }
  const tenancyId = tenancy.id;
  const tenants: Tenant[] = tenancy.tenants;

  if (tenants.length === 0) {
    return undefined;
  }

  const dateRange = new DateRange({
    start: new LocalDate(tenancy.startDate),
    end: tenancy.endDate ? new LocalDate(tenancy.endDate) : undefined
  });

  const description =
    tenancy.type === TenancyType.ACTIVE
      ? `Active Tenancy`
      : tenancy.type === TenancyType.INGOING
      ? `Ingoing Tenancy`
      : `Former Tenancy (${dateRange.format({
          dateFormat: "MMM YYYY" as LocalDateFormat
        })})`;

  const badgeText = "Renter";

  return {
    type: "tenancy",
    label: formatNames(tenants.map((tenant) => formatLegalEntityName(tenant))),
    description,
    value: AiloRN.of(services.PropertyManagement.tenancy, tenancyId).toString(),
    tenantsAilorns: tenants.map((t) => t.ailoRN),
    RightComponent: <Badge type={"tenant"}>{badgeText}</Badge>
  };
}
