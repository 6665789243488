import { createStackNavigator } from "@react-navigation/stack";
import { ProjectTabStackNavigatorParamList, Screens } from "local/common";
import React, { ReactElement } from "react";
import { ProjectListScreen } from "./ProjectListScreen/ProjectListScreen";
import { ProjectsScreen } from "./ProjectScreen";

const Stack = createStackNavigator<ProjectTabStackNavigatorParamList>();

export function ProjectsTabScreen(): ReactElement {
  return (
    <Stack.Navigator
      initialRouteName={Screens.ProjectList}
      screenOptions={{ headerShown: false, gestureEnabled: false }}
    >
      <Stack.Screen
        name={Screens.ProjectList}
        options={{ title: "Project List" }}
        component={ProjectListScreen}
        initialParams={{ tab: "open" }}
      />
      <Stack.Screen
        name={Screens.Project}
        component={ProjectsScreen}
        options={{ title: "Projects" }}
      />
    </Stack.Navigator>
  );
}
