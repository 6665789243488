import {
  didQueryNotLoadYet,
  didQuerySucceed,
  ScreenComponent
} from "@ailo/services";
import { ErrorAlertScreen } from "@ailo/ui";
import {
  ProjectListItem,
  useHandleCreateProjectButton
} from "local/domain/project";
import { ProjectList } from "local/domain/project/ProjectList";
import { useGetManagementProjectsQuery } from "local/graphql";
import React, { ComponentProps, ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { isPresent } from "ts-is-present";
import { EmptyState } from "./EmptyState";
import { ProjectsListSidebarContainer } from "./ProjectsListSidebarContainer";
import { ProjectsListSidebarErrorBoundaryFallback } from "./ProjectsListSidebarErrorBoundaryFallback";

type Props = {
  managementId: string;
  style?: StyleProp<ViewStyle>;
  onProjectPress?: ComponentProps<typeof ProjectListItem>["onPress"];
  onClose: () => void;
};

export function ProjectsListSidebar(props: Props): ReactElement {
  return (
    <ScreenComponent
      name={"Projects"}
      analytics={false}
      errorBoundaryOptions={{
        fallbackComponent: ProjectsListSidebarErrorBoundaryFallback(
          props.onClose
        )
      }}
    >
      <ProjectsListSidebarContent {...props} />
    </ScreenComponent>
  );
}

export function ProjectsListSidebarContent({
  managementId,
  onProjectPress,
  onClose,
  style
}: Props): ReactElement {
  const projectsResult = useGetManagementProjectsQuery({
    variables: { managementId }
  });
  const { onCreateProjectPress } = useHandleCreateProjectButton(managementId);

  if (didQueryNotLoadYet(projectsResult)) {
    return (
      <ProjectsListSidebarContainer
        onClose={onClose}
        onCreateProjectPress={onCreateProjectPress}
        style={style}
      >
        <ProjectList.Loading itemsLimit={3} />
      </ProjectsListSidebarContainer>
    );
  }
  if (!didQuerySucceed(projectsResult)) {
    return (
      <ProjectsListSidebarContainer onClose={onClose} style={style}>
        <ErrorAlertScreen
          title={"There was a problem loading projects"}
          onRetry={() => projectsResult.refetch()}
        />
      </ProjectsListSidebarContainer>
    );
  }

  const projectItems =
    projectsResult.data.management?.projects?.items.filter(isPresent) ?? [];

  if (projectItems.length === 0) {
    return (
      <ProjectsListSidebarContainer
        onCreateProjectPress={onCreateProjectPress}
        onClose={onClose}
        style={style}
      >
        <EmptyState />
      </ProjectsListSidebarContainer>
    );
  }
  return (
    <ProjectsListSidebarContainer
      onCreateProjectPress={onCreateProjectPress}
      onClose={onClose}
      style={style}
    >
      <ProjectList
        projectItems={projectItems}
        onProjectPress={onProjectPress}
      />
    </ProjectsListSidebarContainer>
  );
}
