import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import {
  RentFragment,
  useCompleteAssignRentActionMutation
} from "local/graphql";
import { useCallback } from "react";

export interface CompleteAssignRentActionInput {
  rent: RentFragment;
  actionId: string;
}

export function useCompleteAssignRentAction(): {
  completeAssignRentAction: (
    input: CompleteAssignRentActionInput
  ) => Promise<void>;
} {
  const toast = useToastContext();

  const [completeAssignRentActionMutation] =
    useCompleteAssignRentActionMutation();

  const completeAssignRentAction = useCallback(
    async ({
      rent,
      actionId
    }: CompleteAssignRentActionInput): Promise<void> => {
      try {
        const result = await completeAssignRentActionMutation({
          variables: {
            id: actionId,
            input: {
              rentAilorn: rent.id
            }
          }
        });
        throwIfMutationFailed(result, {
          dataKey: "completeAssignRentAction"
        });
      } catch (error) {
        AiloSentry.captureException(error);
        toast.showFormSubmitError();
      }
    },
    [completeAssignRentActionMutation, toast]
  );

  return { completeAssignRentAction };
}
