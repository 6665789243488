import React, { ReactNode } from "react";
import { StyleProp, TextStyle } from "react-native";
import { Text } from "@ailo/primitives";

export function ActionCardContent({
  description,
  style
}: {
  description: ReactNode;
  style?: StyleProp<TextStyle>;
}): React.ReactElement {
  return (
    <Text.BodyS weight={"book"} style={style}>
      {description}
    </Text.BodyS>
  );
}
