import { useHasFeature } from "@ailo/domains";
import { formatDateDefaultToNA, formatDateOrDefault } from "@ailo/services";
import { DescriptionList } from "@ailo/ui";
import { PlatformFeatureId } from "local/graphql";
import React, { FC } from "react";
import { getAgreementDatesDescriptionListItems } from "./getAgreementDatesDescriptionListItems";
import { SuccessIcon } from "./icons";
import { SectionHeader } from "./sections";

interface Props {
  startDate?: string | null;
  agreementStartDate?: string | null;
  fixedTermAgreementEndDate?: string | null;
  agreementAllowedToLapse: boolean;
  nextAgreementStartDate?: string | null;
  routineInspectionDueDate?: string | null;
}

export const OccupiedDetails: FC<Props> = ({
  startDate,
  agreementStartDate,
  fixedTermAgreementEndDate,
  agreementAllowedToLapse,
  nextAgreementStartDate,
  routineInspectionDueDate
}) => {
  const hasRoutineInspectionFeature = useHasFeature(
    PlatformFeatureId.CreateRoutineInspectionProject
  );

  return (
    <>
      <SectionHeader
        icon={SuccessIcon}
        title={"Occupied"}
        style={{ marginBottom: 12 }}
      />
      <DescriptionList
        size={"small"}
        items={[
          {
            label: "Occupied since",
            description: formatDateDefaultToNA(startDate)
          },
          ...getAgreementDatesDescriptionListItems({
            agreementStartDate,
            fixedTermAgreementEndDate,
            agreementAllowedToLapse,
            nextAgreementStartDate
          }),
          ...(hasRoutineInspectionFeature
            ? [
                {
                  label: "Next inspection due",
                  description: formatDateOrDefault(
                    "No due date set",
                    routineInspectionDueDate
                  )
                }
              ]
            : [])
        ]}
        testID={"occupancy-details-section"}
      />
    </>
  );
};
