import { DownCaretIcon } from "@ailo/primitives";
import {
  Button,
  DropdownMenu,
  DropdownMenuOptionGroup,
  DropdownMenuToggleProps
} from "@ailo/ui";
import { useGetVoidIngoingTenancyOption } from "local/modals/VoidTenancyConfirmModal";
import React from "react";
import { isPresent } from "ts-is-present";
import { getEditIngoingTenancyOption } from "./getEditIngoingTenancyOption";

export function ManageMenu({
  tenancyId,
  onEditPress,
  disabledEditTooltip,
  onVoidPress
}: {
  tenancyId: string;
  onEditPress?: () => void;
  disabledEditTooltip?: string;
  onVoidPress?: () => void;
}): React.ReactElement {
  const editIngoingTenancyOption = getEditIngoingTenancyOption(
    onEditPress,
    disabledEditTooltip
  );

  const voidIngoingTenancyOption = useGetVoidIngoingTenancyOption(
    tenancyId,
    onVoidPress
  );

  const tenancyOptionsGroup: DropdownMenuOptionGroup = {
    options: [editIngoingTenancyOption, voidIngoingTenancyOption].filter(
      isPresent
    ),
    type: "group"
  };

  const options = [tenancyOptionsGroup];

  return <DropdownMenu renderToggle={renderDropdownButton} options={options} />;
}

function renderDropdownButton({
  toggle,
  open
}: DropdownMenuToggleProps): React.ReactElement {
  return (
    <Button.Text onPress={toggle} rightIcon={DownCaretIcon} active={open}>
      {"Manage"}
    </Button.Text>
  );
}
