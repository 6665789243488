import * as Apollo from "@apollo/client";
import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import {
  GetProjectActionsQuery,
  GetProjectActionsQueryVariables,
  useGetProjectActionsQuery
} from "local/graphql";

type GetActionsQuery = {
  actions: NonNullable<GetProjectActionsQuery["project"]>["actions"];
};
type GetActionsQueryVariables = GetProjectActionsQueryVariables;

export const useGetActionsQuery = (
  baseOptions: Omit<
    Apollo.QueryHookOptions<GetActionsQuery, GetActionsQueryVariables>,
    "onCompleted"
  >
): MappedQueryResult<GetActionsQuery, GetActionsQueryVariables> => {
  const result = useGetProjectActionsQuery(baseOptions);

  const modifiedQuery = useMappedQueryResult(result, (data) => {
    return {
      actions: data.project?.actions
    };
  });

  return modifiedQuery;
};
