import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AFC } from "@ailo/primitives";
import { ClaimBondFormData } from "./ClaimBondFormData";

const ClaimBondFormContext: AFC = ({ children }) => {
  const formMethods = useForm<ClaimBondFormData>({
    shouldFocusError: true,
    mode: "onChange",
    defaultValues: {
      rent: {},
      bills: [],
      total: null
    },
    reValidateMode: "onChange"
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
};

export { ClaimBondFormContext };
