import React from "react";
import { ListItem } from "@ailo/ui";
import { Colors } from "@ailo/primitives";
import { AssignedTeamListItemIcon } from "./AssignedTeamListItemIcon";
import { ChatTeam } from "@ailo/domains";

export function TeamListItem({
  team,
  selectedTeamId,
  onSelect
}: {
  team: ChatTeam;
  selectedTeamId?: string;
  onSelect: (teamId: string) => void;
}): React.ReactElement {
  return (
    <ListItem
      header={team.name}
      leftComponent={<AssignedTeamListItemIcon />}
      style={{
        borderType: "none",
        height: 40,
        paddingVertical: 0,
        marginBottom: 4,
        borderRadius: 4,
        ...(team.ailorn.internalId === selectedTeamId
          ? { backgroundColor: Colors.INTERACTION.WHITE.PRESSED }
          : {})
      }}
      onPress={() => onSelect(team.ailorn.internalId)}
    />
  );
}
