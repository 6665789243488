import { AiloRN, services } from "@ailo/ailorn";
import { LocalDate } from "@ailo/date";
import {
  formatAddress,
  formatLegalEntityName,
  formatNames
} from "@ailo/domain-helpers";
import { BillPayerType } from "@ailo/domains";
import { useOnFocus } from "@ailo/services";
import { Money } from "@ailo/ui";
import { WatchQueryFetchPolicy } from "@apollo/client";
import Big from "big.js";
import { billPayerNames } from "local/domain/bill";
import {
  BillDetailsFragment,
  FeeDetailsFragment,
  GetExpenseQuery,
  GetExpenseQueryVariables,
  TaxTreatment,
  useGetExpenseQuery
} from "local/graphql";
import { useCallback } from "react";
import { isPresent } from "ts-is-present";
import { ExpenseDataFetchError } from "./ExpenseDataFetchError";

export enum ExpenseType {
  BILL = "Bill",
  FEE = "Fee"
}

type PaymentReference = NonNullable<BillDetailsFragment["paymentReference"]>;

type PaymentMethod = NonNullable<
  PaymentReference["supplierPaymentMethodCompanion"]
>["paymentMethod"];

type Payer = {
  entityType?: BillPayerType;
  name?: string;
};
type Status =
  | BillDetailsFragment["agencyStatus"]
  | NonNullable<FeeDetailsFragment["status"]>;

export type Bill = {
  ailorn: AiloRN;
  expenseType: ExpenseType.BILL;
  payeeName: string;
  status: Status;
  dueDate: LocalDate;
  archivableByOrganisation: boolean;
  totalAmount: Money;
  propertyName?: string;
  taxCategory: string;
  invoiceNumber?: string;
  description?: string;
  paymentMethod?: PaymentMethod;
  paymentReference?: PaymentReference;
  payers: Payer[];
  issueDate: LocalDate;
  createdAt: LocalDate;
  paidOn?: LocalDate;
  organisationArchiveReason?: string;
  lineItems: BillDetailsFragment["lineItems"];
  attachments: BillDetailsFragment["attachments"];
  applyManagementFee: BillDetailsFragment["applyManagementFee"];
  management: BillDetailsFragment["management"];
  fees: GetExpenseQuery["fees"];
};

export type Fee = {
  ailorn: AiloRN;
  expenseType: ExpenseType.FEE;
  payeeName?: string;
  status?: Status;
  totalAmount: Money;
  label?: string;
  propertyName?: string;
  propertyNameWithSuburb?: string;
  taxCategory?: string;
  payers: Payer[];
  description?: string;
  taxAmount?: Money;
  issueDate?: LocalDate;
  paidOn?: LocalDate;
  toBePaid?: string;
  percentage?: number;
  blueprint?: {
    id: string;
    ailorn: AiloRN;
    name: string;
    amount?: Money;
    amountIncludingTax?: Money;
    oneWeekRentPercentage?: number;
    oneWeekRentPercentageIncludingTax?: number;
  };
  managementId: string;
  managementAilorn: AiloRN;
  currentWeeklyRent?: Money;
};

export type ExpenseUnionType = Bill | Fee;

export type ExpenseMergedType = Partial<
  Omit<Bill, "expenseType"> &
    Omit<Fee, "expenseType"> & { expenseType: ExpenseType }
>;

export function useGetExpense({
  ailorn,
  fetchPolicy
}: {
  ailorn?: AiloRN;
  fetchPolicy?: WatchQueryFetchPolicy;
}): {
  loading: boolean;
  data?: ExpenseUnionType;
} {
  const variables = constructQueryVariables(ailorn);

  const { data, loading, refetch } = useGetExpenseQuery({
    skip: !ailorn,
    variables,
    fetchPolicy: fetchPolicy || "cache-and-network"
  });

  const _refetch = useCallback(() => {
    if (ailorn) refetch();
  }, [ailorn, refetch]);
  useOnFocus(_refetch);

  if (!loading && !data) throw new ExpenseDataFetchError("Cannot get expense");

  const expense = constructExpenseFromRawApiData(data);

  return {
    loading: loading && !data,
    data: expense
  };
}

function constructExpenseFromRawApiData(
  data?: GetExpenseQuery
): ExpenseUnionType | undefined {
  if (!data) return;
  if (data.billById?.ailorn) {
    const expenseType = ExpenseType.BILL;
    const bill = data.billById;
    const ailorn = AiloRN.fromString(bill.ailorn);
    const payeeName = getPayeeName(bill.payee);
    const status = bill.agencyStatus;
    const dueDate = LocalDate.from(bill.dueDate);
    const archivableByOrganisation = bill.archivableByOrganisation ?? false;
    const totalAmount = new Money(bill.amount?.cents ?? 0);
    const propertyName = bill.management
      ? formatAddress(bill.management.property.address, {
          format: "street"
        })
      : undefined;
    const taxCategory = bill.taxCategory.name;
    const invoiceNumber = bill.invoiceNumber ?? undefined;
    const description = bill.description ?? undefined;
    const paymentReference = bill.paymentReference ?? undefined;
    const paymentMethod =
      paymentReference?.supplierPaymentMethodCompanion?.paymentMethod;
    const payers = billPayerNames(bill.payer);

    const issueDate = LocalDate.from(bill.issueDate);
    const createdAt = LocalDate.from(bill.createdAt);
    const paidOn = bill.liabilityState?.paidAt
      ? LocalDate.from(bill.liabilityState?.paidAt)
      : undefined;
    const organisationArchiveReason =
      bill.organisationArchiveReason ?? undefined;
    const lineItems = bill.lineItems;
    const attachments = bill.attachments;
    const applyManagementFee = bill.applyManagementFee;
    const management = bill.management;
    const fees = data.fees;

    return {
      expenseType,
      ailorn,
      payeeName,
      status,
      dueDate,
      archivableByOrganisation,
      totalAmount,
      propertyName,
      taxCategory,
      invoiceNumber,
      description,
      paymentMethod,
      paymentReference,
      payers,
      issueDate,
      createdAt,
      paidOn,
      organisationArchiveReason,
      lineItems,
      attachments,
      applyManagementFee,
      management,
      fees
    };
  }
  if (data.fee?.ailorn) {
    const fee = data.fee;
    const description = fee.description ?? undefined;
    const totalAmount = Money.fromCents(fee.amount.cents);
    const label =
      fee.managementFeeBlueprint?.feeBlueprint.name ?? fee.blueprint?.name;
    const propertyName = fee.management
      ? formatAddress(fee.management.property.address, {
          format: "street"
        })
      : undefined;
    const propertyNameWithSuburb = fee.management
      ? formatAddress(fee.management.property.address, {
          format: "street, suburb"
        })
      : undefined;
    const status = fee.status ?? undefined;
    const taxCategory = fee.taxCategory.name;
    const expenseType = ExpenseType.FEE;
    const ailorn = AiloRN.fromString(fee.ailorn);

    const payers: Payer[] = fee.management.owners
      ? fee.management.owners
          .map((ownership) => ownership?.owner)
          .filter(isPresent)
          .map((owner) => ({
            entityType: BillPayerType.Management,
            name: formatLegalEntityName(owner)
          }))
      : [];

    const taxAmount = Money.fromCents(fee.taxAmount?.cents || 0);
    const issueDate = LocalDate.from(fee.createdAt);
    const paidOn =
      status === "Paid" && fee.liability?.lastPaymentDate
        ? LocalDate.from(fee.liability?.lastPaymentDate)
        : undefined;
    const toBePaid = status === "Due" ? "When funds are available" : undefined;
    const payeeName = fee.management.managingEntity?.organisation.name;
    const percentage = fee.percentage ?? undefined;

    const managementOrAgencyBlueprint =
      fee.managementFeeBlueprint ?? fee.blueprint;

    const blueprint = managementOrAgencyBlueprint
      ? {
          id: managementOrAgencyBlueprint.id,
          ailorn: AiloRN.fromString(managementOrAgencyBlueprint.ailorn),
          name:
            fee.blueprint?.name ??
            fee.managementFeeBlueprint?.feeBlueprint.name ??
            "",
          amount: managementOrAgencyBlueprint.fixedAmount?.cents
            ? Money.from(managementOrAgencyBlueprint.fixedAmount)
            : undefined,
          amountIncludingTax: managementOrAgencyBlueprint.fixedAmount
            ? Money.from(managementOrAgencyBlueprint.fixedAmount).multiply(
                managementOrAgencyBlueprint.taxTreatment ===
                  TaxTreatment.Exclusive
                  ? 1.1
                  : 1
              )
            : undefined,
          oneWeekRentPercentage:
            managementOrAgencyBlueprint.oneWeekRentPercentage ?? undefined,
          oneWeekRentPercentageIncludingTax:
            managementOrAgencyBlueprint.oneWeekRentPercentage != undefined
              ? new Big(managementOrAgencyBlueprint.oneWeekRentPercentage)
                  .mul(
                    managementOrAgencyBlueprint.taxTreatment ===
                      TaxTreatment.Exclusive
                      ? 1.1
                      : 1
                  )
                  .round(4)
                  .toNumber()
              : undefined
        }
      : undefined;
    const managementId = fee.management.id;
    const managementAilorn = AiloRN.fromString(fee.management.ailorn);

    const dailyRate = fee.management.mostRecentTenancy?.currentRent?.dailyRate;
    const currentWeeklyRent =
      dailyRate != undefined ? Money.fromCents(dailyRate * 7) : undefined;

    return {
      description,
      payeeName,
      totalAmount,
      label,
      propertyName,
      propertyNameWithSuburb,
      status,
      taxCategory,
      expenseType,
      ailorn,
      payers,
      taxAmount,
      issueDate,
      paidOn,
      toBePaid,
      percentage,
      blueprint,
      managementId,
      managementAilorn,
      currentWeeklyRent
    };
  }
}

function getPayeeName(payee: GetExpenseQuery["billById"]["payee"]): string {
  return payee?.__typename === "Supplier"
    ? payee.name ?? ""
    : payee?.__typename === "LegalEntityCompanion"
    ? payee.organisation.name
    : payee?.__typename === "Management"
    ? formatNames(
        (payee.owners ?? [])
          .filter(isPresent)
          .map((ownership) => ownership.owner)
          .filter(isPresent)
          .map((owner) => formatLegalEntityName(owner))
      )
    : "";
}

function constructQueryVariables(
  ailorn?: AiloRN
): GetExpenseQueryVariables | undefined {
  if (!ailorn) return undefined;
  switch (getExpenseType(ailorn)) {
    case ExpenseType.BILL:
      return {
        id: ailorn.internalId,
        ailorn: ailorn.toString(),
        isBill: true,
        isFee: false
      };
    case ExpenseType.FEE:
      return {
        id: ailorn.internalId,
        ailorn: ailorn.toString(),
        isBill: false,
        isFee: true
      };
  }
}

function getExpenseType(expenseId?: AiloRN): ExpenseType | undefined {
  return expenseId?.isA(services.Bill.bill)
    ? ExpenseType.BILL
    : expenseId?.isA(services.PropertyManagement.fee)
    ? ExpenseType.FEE
    : undefined;
}
