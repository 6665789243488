import { MigrationIssueKey } from "local/graphql";

export interface MigrationHealthIssue {
  key?: MigrationIssueKey;
  field?: string;
  value?: string;
  description: string;
}

export function isValidHealthIssue(
  healthIssue: any
): healthIssue is MigrationHealthIssue {
  return !(
    typeof healthIssue !== "object" ||
    (healthIssue.field && typeof healthIssue.field !== "string") ||
    (healthIssue.value && typeof healthIssue.value !== "string") ||
    (healthIssue.description && typeof healthIssue.description !== "string") ||
    (healthIssue.key &&
      !Object.values(MigrationIssueKey).includes(healthIssue.key))
  );
}
