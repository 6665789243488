import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Colors } from "@ailo/primitives";
import { AddBillButton } from "./AddBillButton";
import { TabNavigation } from "@ailo/ui";
import { TabId, tabs, tabsWithCounters } from "../tabs";
import { TabCounts } from "../useTabCounts";
import { TeamsFilter } from "local/domain/propertyManagement/components/TeamsFilter";
import { Screens, useAnalytics } from "local/common";

export function ScreenHeader({
  tab,
  tabCounts,
  tabCountsLoading,
  onTabChange
}: {
  tab: TabId;
  tabCounts?: TabCounts;
  tabCountsLoading?: boolean;
  onTabChange?(tab: TabId): void;
}): React.ReactElement {
  const analytics = useAnalytics();
  return (
    <Container>
      <TabNavigation
        options={tabs.map((tab) => ({
          key: tab.id,
          label: tab.label,
          badge: tabsWithCounters.includes(tab.id)
            ? tabCountsLoading
              ? "loading"
              : tabCounts?.[tab.id] || undefined
            : undefined
        }))}
        selectedOptionKey={tab}
        onChange={(option) => onTabChange?.(option.key)}
        style={{
          flex: 1,
          marginRight: 12
        }}
      />
      <View style={{ marginHorizontal: 12 }}>
        <TeamsFilter
          onChange={(teams) =>
            analytics.trackScreenVisited(Screens.AnalyticsDashboardTab, {
              filteredByTeamId: teams
            })
          }
        />
      </View>
      <AddBillButton />
    </Container>
  );
}

const Container = styled(View)`
  margin-bottom: 32px;
  flex-direction: row;
  background-color: ${Colors.CLOUD};
`;
