import "array-flat-polyfill";
import { ContactCardSetup, MaintenanceSetup } from "@ailo/domains";
import { Colors, CurrentAppContextProvider } from "@ailo/primitives";
import {
  ActionEventContextProvider,
  AuthSetup,
  EnvironmentSetup,
  ErrorSetup,
  NavigationSetup,
  staticEnvironment,
  useAuth,
  UserStorageContextProvider,
  withErrorBoundary
} from "@ailo/services";
import {
  DateTimeWithTimeZone,
  UIProvider,
  GlobalModal,
  FileCarouselSetup
} from "@ailo/ui";
import Constants from "expo-constants";
import { linking } from "local/common";
import React, { useEffect } from "react";
import { StatusBar } from "react-native";
import { AgencyApolloSetup } from "local/graphql";
import possibleTypeData from "../graphql/fragmentTypes.generated";
import { AnalyticsSetup } from "./AnalyticsSetup";
import { AppDataSetup } from "./AppDataSetup";
import { LoginNavigator } from "./LoginNavigator";
import { CallbackHandler } from "./AppNavigator/CallbackHandler";
import { ImageCarouselSetup } from "./AppNavigator/ImageCarouselSetup";
import { AgencyWebSharedNavigationSetup } from "./AgencyWebSharedNavigationSetup";

const navigationTheme = {
  colors: { background: Colors.CLOUD }
};

function UserStorageProvider({
  children
}: {
  children: React.ReactElement;
}): JSX.Element {
  const { user } = useAuth();
  return (
    <UserStorageContextProvider userId={user?.effectiveId?.toString()}>
      {children}
    </UserStorageContextProvider>
  );
}

const { SENTRY_ENABLE, SENTRY_DSN, SENTRY_TRACES_SAMPLE_RATE, GATEWAY_TRACE } =
  staticEnvironment.releaseEnvironment;
const { codeVersion, buildTimestamp, buildVersion } =
  staticEnvironment.buildInfo;

const allowLoggingPii = !staticEnvironment.isProd;

const errorConfig = {
  sentryEnabled: !!(!__DEV__ || SENTRY_ENABLE),
  sentryDsn: SENTRY_DSN,
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE ?? __DEV__ ? 1.0 : 0.01,
  allowLoggingPii
};
const apolloConfig = {
  allowLoggingPii,
  forceTracing: !!GATEWAY_TRACE,
  possibleTypes: possibleTypeData.possibleTypes,
  clientName: "ailo-agency-app",
  clientVersion: codeVersion
};

const App: React.FunctionComponent = () => {
  useEffect(() => {
    StatusBar.setBarStyle("light-content");
    logAppVersion();
  }, []);

  return (
    <CurrentAppContextProvider appPackageId={"agency-app"}>
      <UIProvider>
        <ActionEventContextProvider>
          <ErrorSetup {...errorConfig}>
            <EnvironmentSetup>
              <MaintenanceSetup>
                <CallbackHandler>
                  <AuthSetup>
                    <UserStorageProvider>
                      <AgencyApolloSetup {...apolloConfig}>
                        <AnalyticsSetup>
                          <AppDataSetup>
                            <NavigationSetup
                              theme={navigationTheme}
                              linking={linking}
                            >
                              <AgencyWebSharedNavigationSetup>
                                <ContactCardSetup>
                                  <FileCarouselSetup>
                                    <ImageCarouselSetup>
                                      <GlobalModal>
                                        <LoginNavigator />
                                      </GlobalModal>
                                    </ImageCarouselSetup>
                                  </FileCarouselSetup>
                                </ContactCardSetup>
                              </AgencyWebSharedNavigationSetup>
                            </NavigationSetup>
                          </AppDataSetup>
                        </AnalyticsSetup>
                      </AgencyApolloSetup>
                    </UserStorageProvider>
                  </AuthSetup>
                </CallbackHandler>
              </MaintenanceSetup>
            </EnvironmentSetup>
          </ErrorSetup>
        </ActionEventContextProvider>
      </UIProvider>
    </CurrentAppContextProvider>
  );
};

const logAppVersion = (): void => {
  // eslint-disable-next-line no-console
  console.log(
    `%c${Constants.manifest?.name}: ` +
      [
        `Version ${Constants.manifest?.version}`,
        `Build ${buildVersion}` +
          (buildTimestamp
            ? ` (${DateTimeWithTimeZone.fromTimestampString(
                buildTimestamp
              ).toISOString()})`
            : ""),
        codeVersion && `Code ${codeVersion.substr(0, 8)}`
      ]
        .filter(Boolean)
        .join(" / "),
    `color: ${Colors.AILO_RED}; font-size: medium`
  );
};

const AppWithErrorBoundary = withErrorBoundary(App);

export { AppWithErrorBoundary as App };
