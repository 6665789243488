import { useToastContext } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import React, { ReactElement, useCallback } from "react";
import { useGetCurrentTenancyQuery } from "local/graphql";
import { useFormProjectAnalytics } from "../../useFormProjectAnalytics";
import { useCreateProject } from "../../useCreateProject";
import { ProjectForm } from "..";
import { ProjectFormData, ProjectFormFields } from "../ProjectFormData";
import { useFormContext } from "react-hook-form";

interface Props {
  onFormSubmitting: (isSubmitting: boolean) => void;
  onDismiss: () => void;
  onSuccess?: (projectId: string) => void;
  setSubmitError: (value: boolean) => void;
}

export function CreateProjectForm({
  onFormSubmitting,
  onDismiss,
  onSuccess,
  setSubmitError
}: Props): ReactElement {
  const { watch } = useFormContext<ProjectFormData>();
  const management = watch(ProjectFormFields.management);

  const { id: organisationId } = useCurrentAgencyOrg();
  const toast = useToastContext();
  const { onStepStarted, onValueUpdated, onCreateSubmitSuccess } =
    useFormProjectAnalytics("Create Project");

  const { data: currentTenancyData, loading: currentTenancyLoading } =
    useGetCurrentTenancyQuery({
      variables: management?.value
        ? { managementId: management.value }
        : undefined,
      skip: !management?.value
    });

  const [createProject] = useCreateProject({
    onCompleted: ({ project, formValues }) => {
      onCreateSubmitSuccess(project, formValues);
      toast.show({
        type: "success",
        message: "Project created"
      });
      onSuccess?.(project.id);
      onDismiss();
    },
    onError: () => {
      setSubmitError(true);
    }
  });

  const submit = useCallback(
    async (data: ProjectFormData): Promise<void> => {
      const { name, description, dueDate, type, files, meta, assignee } = data;
      if (!name) {
        throw new Error("Name cannot be blank");
      }
      if (!dueDate) {
        throw new Error("Description cannot be blank");
      }
      if (!type) {
        throw new Error("Project type cannot be blank");
      }
      if (!dueDate) {
        throw new Error("Project due date cannot be blank");
      }

      if (!assignee?.ailoRN) {
        throw new Error("Assignee cannot be blank");
      }

      if (!management?.value) {
        throw new Error("Property cannot be blank");
      }

      await createProject(
        {
          managementId: management.value,
          organisationId,
          name,
          description,
          dueDate,
          type,
          fileAilorns: files?.map((file) => file.id!) ?? [],
          meta,
          assigneeAilorn: assignee.ailoRN.toString()
        },
        data
      );
    },
    [createProject, management?.value, organisationId]
  );

  const cancel = useCallback((): void => {
    onDismiss();
  }, [onDismiss]);

  return (
    <ProjectForm
      type={"create"}
      onSubmit={submit}
      onCancel={cancel}
      currentTenancy={
        currentTenancyData?.management?.currentTenancy ?? undefined
      }
      currentTenancyLoading={currentTenancyLoading}
      style={{
        maxWidth: 680,
        width: "100%",
        flex: 1,
        alignSelf: "flex-end"
      }}
      onFormSubmitting={onFormSubmitting}
      onValueUpdated={onValueUpdated}
      onStepStarted={onStepStarted}
    />
  );
}
