import { Table } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import {
  FunctionalReportTable,
  ReportTableColumn,
  ReportTableRow
} from "../../../components/ReportTable";

import { ReportMetricCard } from "../../../components/ReportMetricCard";

import React from "react";
import { useGetPropertyLiveTableDataQuery } from "local/graphql";
import { View } from "react-native";
import { useReportTeamContext } from "../../..";
import { reports } from "../../../reportsDetails";

// need to exclude dates and dollar amounts because of string formatting
const sortableColumns: string[] = [
  "propertyAddress",
  "investorNames",
  "renterNames",
  "teamName"
];

export function PropertyLiveTable(): React.ReactElement {
  const { id } = useCurrentAgencyOrg();
  const { teamId } = useReportTeamContext();
  // construct variables
  const variables = { managingOrgId: id, teamId };
  // get data
  const visualisationData = useGetPropertyLiveTableDataQuery({
    variables,
    onError: () => visualisationData.refetch(),
    onCompleted: (data) =>
      data.propertyLiveReport === null ? visualisationData.refetch() : null
  });
  const reportData = visualisationData.data?.propertyLiveReport;
  if (!reportData?.rows) return <Table.Loading />;

  if (reportData.rows.length < 1) return <Table.Loading />;

  const rows = reportData.rows.map((r) => {
    return r as ReportTableRow;
  }) as [ReportTableRow];
  const columns = reportData.columns as [ReportTableColumn];
  // get header fields
  const activeProperties = reportData.headerFields.activePropertyCount ?? 0;
  const activePercent = reportData.headerFields.activePropertyPercent ?? "0%";
  const pendingProperties = reportData.headerFields.pendingPropertyCount ?? 0;
  const vacantProperties = reportData.headerFields.vacantPropertyCount ?? 0;
  return (
    <div>
      <FunctionalReportTable
        reportTitle={reports.property_activation.reportHeading}
        columns={columns}
        rows={rows}
        sortableColumns={sortableColumns}
        headerComponents={
          <View style={{ flexWrap: "wrap", flex: 4, flexDirection: "row" }}>
            <ReportMetricCard
              value={activeProperties}
              label={"Activated Properties"}
            />
            <ReportMetricCard value={activePercent} label={"Activated"} />
            <ReportMetricCard
              value={pendingProperties}
              label={"Pending Properties"}
            />
            <ReportMetricCard
              value={vacantProperties}
              label={"Vacant Properties"}
            />
          </View>
        }
      />
    </div>
  );
}
