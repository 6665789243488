import { AiloRN, services } from "@ailo/ailorn";
import { useDownloadFile } from "@ailo/domains";
import { DownloadModalWithTimeout, useAnalytics } from "local/common";
import React, { ReactElement } from "react";
import { useState } from "reactn";
import { useQueryDates } from "./useQueryDates";
import { useStatementCreation } from "./useStatementCreation";
import { useStatementFileUrl } from "./useStatementFileUrl";
import { useTenancyTimer } from "./useTenancyTimer";

export function TenancyLedgerDownloadModal({
  toggleModal,
  tenancyId
}: {
  toggleModal: () => void;
  tenancyId: string;
}): ReactElement {
  const downloadTimeOutInMs = 30000;
  const [key, setKey] = useState(0);
  const resetModal = (): void => {
    setKey(key + 1);
  };

  return (
    <TenancyLedgerDownloadModalWithTimeout
      key={key.toString()}
      toggleModal={toggleModal}
      resetModal={resetModal}
      tenancyId={tenancyId}
      downloadTimeOutInMs={downloadTimeOutInMs}
    />
  );
}

function TenancyLedgerDownloadModalWithTimeout({
  toggleModal,
  resetModal,
  tenancyId,
  downloadTimeOutInMs
}: {
  toggleModal: () => void;
  resetModal: () => void;
  tenancyId: string;
  downloadTimeOutInMs: number;
}): ReactElement {
  const analytics = useAnalytics();
  const { downloadFile } = useDownloadFile();

  // Get timer
  const timerObject = useTenancyTimer(
    tenancyId,
    downloadTimeOutInMs,
    analytics
  );

  const { timer } = timerObject;

  // Get Tenancy's start/end dates
  const { queryDates } = useQueryDates(timer, tenancyId);

  // Create statement
  const { statementProgressId } = useStatementCreation(
    timer,
    tenancyId,
    queryDates
  );

  // Get fileUrl & download
  const { url } = useStatementFileUrl(timer, statementProgressId);

  const trackDownload = (): void =>
    analytics.track(`Tenancy Ledger Downloaded`, {
      tenancy_id: AiloRN.of(services.PropertyManagement.tenancy, tenancyId)
    });

  return (
    <DownloadModalWithTimeout
      toggleModal={toggleModal}
      resetModal={resetModal}
      timerObject={timerObject}
      onDownload={() => {
        downloadFile(url);
        trackDownload();
      }}
      loading={!url}
    />
  );
}
