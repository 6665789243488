import { SidebarSections } from "@ailo/ui";
import React from "react";
import {
  TenancyAgreementSection,
  EndOfTenancySection,
  RentSection,
  TenancyAttachments,
  CentrepaySection,
  InspectionSection
} from "./components";
import { TenancyDetailsData } from "./useGetTenancyDetails";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";

type Props = {
  tenancyId: string;
} & TenancyDetailsData;

export function TenancyDetailsDisplay({
  tenancyId,
  startDate,
  endDate,
  voidedAt,
  agreementStartDate,
  agreementEndDate,
  agreementAllowedToLapse,
  nextAgreementStartDate,
  displayRent,
  nextRentSchedule,
  futureRents,
  scheduledRentReviewDate,
  paidToDate,
  files,
  vacatingReason,
  vacatingNotes,
  managingEntity,
  tenants,
  property,
  inspectionSection
}: Props): React.ReactElement {
  const hasCentrepayFeature = useHasFeature(PlatformFeatureId.Centrepay);
  const hasRoutineInspectionFeature = useHasFeature(
    PlatformFeatureId.CreateRoutineInspectionProject
  );

  return (
    <SidebarSections>
      <TenancyAgreementSection
        voidedAt={voidedAt}
        startDate={startDate}
        agreementStartDate={agreementStartDate}
        agreementEndDate={agreementEndDate}
        agreementAllowedToLapse={agreementAllowedToLapse}
        nextAgreementStartDate={nextAgreementStartDate}
      />
      {endDate && (
        <EndOfTenancySection
          endDate={endDate}
          vacatingReasons={vacatingReason}
          vacatingNotes={vacatingNotes}
        />
      )}
      {managingEntity && hasCentrepayFeature && (
        <CentrepaySection
          managingEntity={managingEntity}
          tenancyId={tenancyId}
          property={property}
          tenants={tenants}
          endDate={endDate}
        />
      )}
      <RentSection
        futureRents={futureRents}
        paidToDate={paidToDate}
        scheduledRentReviewDate={scheduledRentReviewDate}
        displayRent={displayRent}
        nextRentSchedule={nextRentSchedule}
      />
      {hasRoutineInspectionFeature && (
        <InspectionSection inspectionSectionInfo={inspectionSection} />
      )}
      {files && <TenancyAttachments tenancyId={tenancyId} files={files} />}
    </SidebarSections>
  );
}
