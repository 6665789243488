import { Alert } from "@ailo/ui";
import React, { ReactElement } from "react";

export function TenantNoEmailWarning(): ReactElement {
  return (
    <Alert
      type={"warning"}
      message={
        "Without an email address this user will not be invited to use Ailo. Add an email to enable this renter to log in, pay rent and chat."
      }
    />
  );
}
