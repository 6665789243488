import {
  Button,
  createModalFilterItem,
  ModalFilter,
  TimePeriodRadioButtons,
  TimePeriods,
  TimePeriodInput,
  DateInputFormField,
  FormFieldRow
} from "@ailo/ui";
import React, { ReactElement } from "react";
import { LocalDate } from "@ailo/date";
import { Controller, useFormContext } from "react-hook-form";
import { isValidDateSelection } from "../reports/RevenueTable/isValidDateSelection";
import { getPeriodConstructor } from "../reports/RevenueTable/periods";
import { MonthPeriod } from "../reports/RevenueTable/periods/MonthPeriod";

interface Defaults {
  startDate: string;
  endDate: string;
  timePeriod: TimePeriods;
  earliestTransaction: string;
}

function CustomPeriod({
  earliestTransaction
}: {
  earliestTransaction: string;
}): ReactElement {
  const { control } = useFormContext();
  const today = LocalDate.today().toString();
  return (
    <FormFieldRow>
      <Controller
        control={control}
        name={"startDate"}
        defaultValue={today}
        render={({ value, onChange }) => (
          <DateInputFormField
            style={{ width: "100%", flex: 1 }}
            onChange={(date) => {
              onChange(date);
            }}
            labelRight={
              <Button.Text
                onPress={() => {
                  onChange(earliestTransaction);
                }}
              >
                {"Earliest transaction"}
              </Button.Text>
            }
            label={"From date"}
            placeholder={"From date"}
            autoCorrect={false}
            autoCompleteType={"off"}
            value={value}
            maxDate={today}
          />
        )}
      />
      <Controller
        control={control}
        name={"endDate"}
        render={({ value, onChange }) => (
          <DateInputFormField
            style={{ width: "100%", flex: 1 }}
            onChange={(date) => {
              onChange(date);
            }}
            label={"To date"}
            labelRight={
              <Button.Text
                onPress={() => {
                  onChange(today);
                }}
              >
                {"Present"}
              </Button.Text>
            }
            placeholder={"To date"}
            autoCorrect={false}
            autoCompleteType={"off"}
            value={value}
            maxDate={today}
          />
        )}
      />
    </FormFieldRow>
  );
}

export function FilterComponent(defaults: Defaults): ReactElement {
  const { watch } = useFormContext();
  const { timePeriod } = watch();
  const minDate =
    LocalDate.parse(defaults.earliestTransaction) ??
    LocalDate.today().subtract(1, "year");

  return (
    <>
      <TimePeriodRadioButtons
        timePeriod={timePeriod ?? defaults.timePeriod}
        options={[
          { label: "Month", value: "month" },
          { label: "Quarter", value: "quarter" },
          {
            label: "Financial year",
            value: "financial year"
          },
          {
            label: "Custom period",
            value: "customPeriod"
          }
        ]}
      />
      {timePeriod === "customPeriod" ? (
        <CustomPeriod earliestTransaction={defaults.earliestTransaction} />
      ) : (
        <TimePeriodInput
          timePeriod={timePeriod ?? defaults.timePeriod}
          inputName={"startDate"}
          formRowLabel={`Select which ${timePeriod} to view income for`}
          autoPopulateButtonLabel={"Present"}
          autoPopulateDate={LocalDate.today()}
          minDate={minDate}
          defaultValue={
            LocalDate.from(defaults.startDate).isSameOrAfter(minDate)
              ? defaults.startDate
              : minDate.toString()
          }
        />
      )}
    </>
  );
}

export function parseStartAndEndDate({
  startDate,
  endDate,
  timePeriod
}: {
  startDate: string;
  endDate: string;
  timePeriod: TimePeriods | "customPeriod";
}): {
  startDate: string;
  endDate: string;
} {
  if (timePeriod === "customPeriod") {
    return { startDate, endDate };
  }
  const today = LocalDate.today();

  const period = getPeriodConstructor(timePeriod)(startDate);
  return {
    startDate: period?.startDate.toString() ?? LocalDate.today().toString(),
    endDate:
      period != undefined && today.isSameOrAfter(period.endDate)
        ? period.endDate.toString()
        : today.toString()
  };
}

export function ReportTimeFilterCustomPeriod(defaults: Defaults): ModalFilter {
  return {
    FilterComponent: createModalFilterItem(<FilterComponent {...defaults} />, {
      title: "Time period"
    }),
    errorConfig: {
      errorMessage: "You need to enter a start date and a end date",
      errorEvaluationFunction: (filterInput) => {
        if (filterInput?.timePeriod === "customPeriod") {
          return isValidDateSelection({ ...filterInput });
        }
        return true;
      }
    },
    title: "Time period",
    filterKey: "timePeriod",
    // eslint-disable-next-line react/display-name
    ButtonComponent: ({ onPress, index, filterState }) => {
      const startDate =
        typeof filterState?.startDate === "string"
          ? LocalDate.from(filterState.startDate)
          : LocalDate.today();

      const period =
        typeof filterState?.timePeriod === "string"
          ? getPeriodConstructor(filterState?.timePeriod)(startDate)
          : new MonthPeriod(startDate);

      if (filterState?.timePeriod === "customPeriod") {
        const endDate =
          typeof filterState?.endDate === "string"
            ? LocalDate.from(filterState.endDate)
            : LocalDate.today();

        return (
          <Button.Filter
            key={index}
            onPress={onPress}
            style={{ marginRight: 12 }}
          >{`${startDate.format("DD MMM")} ‘${startDate.format(
            "YY"
          )} — ${endDate.format("DD MMM")} ‘${endDate.format(
            "YY"
          )}`}</Button.Filter>
        );
      }

      return (
        <Button.Filter
          key={index}
          onPress={onPress}
          style={{ marginRight: 12 }}
        >
          {period?.shortFormat()}
        </Button.Filter>
      );
    }
  };
}
