import React, { ReactElement } from "react";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";
import { AmountInput } from "./components/AmountInput";
import { ReferenceInput } from "./components/ReferenceInput";
import { TenancyDetails } from "./components/TenancyDetails";
import { TenancyDetailsData } from "./parseTenancyDetailsData";
import { Alert, Separator } from "@ailo/ui";
import { isBondInClaimProcess } from "local/domain/bond/isBondInClaimProcess";
import { AustralianState, getState } from "local/common";
import { useFormContext } from "react-hook-form";
import { BondDetailsFormData } from "./BondDetailsFormData";
import { usePropertyIsInStates } from "../../../context/PropertyContext";
import { TrustAccountDetailsBox } from "local/domain/bond/TrustAccountDetailsBox";

export function BondDetailsForm({
  tenancyDetails
}: {
  tenancyDetails?: TenancyDetailsData;
}): ReactElement {
  const { watch } = useFormContext<BondDetailsFormData>();

  const propertyIsInNtWaOrSa = usePropertyIsInStates([
    AustralianState.NT,
    AustralianState.WA,
    AustralianState.SA
  ]);

  const bondInClaimProcess = isBondInClaimProcess(tenancyDetails?.bond);

  const state = getState(tenancyDetails?.propertyAddress.state);

  const propertyIsInNorthernTerritory = state === "NT";

  const notInNtOrBondAlreadyExists =
    !propertyIsInNorthernTerritory || !!tenancyDetails?.bond;
  return (
    <View>
      {bondInClaimProcess && (
        <Alert type={"warning"} style={{ marginBottom: 40 }}>
          {
            "There is an existing claim for this bond awaiting funds from the bond authority. Editing the bond reference may affect the claim."
          }
        </Alert>
      )}
      {tenancyDetails && <TenancyDetails data={tenancyDetails} />}
      {notInNtOrBondAlreadyExists && (
        <>
          <BondReferenceHeader />
          <ReferenceInput
            defaultValue={tenancyDetails?.bond?.reference}
            disabled={propertyIsInNorthernTerritory}
          />
        </>
      )}

      {!bondInClaimProcess && (
        <>
          <LodgedAmountHeader />
          <AmountInput defaultValue={tenancyDetails?.bond?.amount} />
        </>
      )}
      {propertyIsInNtWaOrSa &&
        !tenancyDetails?.bond &&
        !!tenancyDetails?.bondAccount && (
          <>
            <Separator
              style={{ marginTop: 40, marginBottom: 32, marginLeft: 0 }}
            />
            <Text.BodyM weight={"medium"}>{"What to do next"}</Text.BodyM>
            <Text.BodyM
              style={{ marginVertical: 20 }}
              color={Colors.TEXT.DARK.SECONDARY}
            >
              {"Provide your renter with your trust account details"}
            </Text.BodyM>
            <TrustAccountDetailsBox
              style={{ marginBottom: 40 }}
              reference={watch("reference")}
            />
          </>
        )}
    </View>
  );
}

function BondReferenceHeader(): ReactElement {
  return (
    <Text.BodyS
      style={{ marginTop: 24, marginBottom: 8 }}
      color={Colors.TEXT.DARK.SECONDARY}
    >
      {"Bond reference"}
    </Text.BodyS>
  );
}

function LodgedAmountHeader(): ReactElement {
  return (
    <Text.BodyS
      style={{ marginTop: 24, marginBottom: 8 }}
      color={Colors.TEXT.DARK.SECONDARY}
    >
      {"Bond amount"}
    </Text.BodyS>
  );
}
