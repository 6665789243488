import { Colors, Text } from "@ailo/primitives";
import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

interface Props {
  label: string;
  value?: string | null;
  style?: StyleProp<ViewStyle>;
}

export function DetailsField({ label, value, style }: Props): ReactElement {
  return (
    <View
      style={[
        style,
        {
          flexDirection: "row",
          justifyContent: "space-between"
        }
      ]}
    >
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{label}</Text.BodyS>
      <Text.BodyS>{value ?? "—"}</Text.BodyS>
    </View>
  );
}
