import React from "react";
import { Image } from "react-native";
import styled from "styled-components/native";
import { Colors, opacify, House, SFC } from "@ailo/primitives";
import { TenancyBanner } from "local/domain/propertyManagement";

type Props = {
  endDate?: string | null;
  voidedAt?: string | null;
  outstandingRent?: number;
  outstandingBill?: number;
};

export const FormerTenancyBanner: SFC<Props> = ({
  endDate,
  voidedAt,
  outstandingBill,
  outstandingRent,
  style
}) => {
  let dateColumn;
  if (endDate) {
    dateColumn = { dateColumnTitle: "Tenancy ended", dateValue: endDate };
  } else if (voidedAt) {
    dateColumn = { dateColumnTitle: "Ingoing voided", dateValue: voidedAt };
  } else {
    throw new Error("Former tenancy must have either end date or void at");
  }
  return (
    <TenancyBanner
      style={[style, { backgroundColor: opacify(Colors.LIGHT_BLUE, 0.2) }]}
      dateColumn={dateColumn}
      moneyColumn={{
        moneyColumnTitle: "Balance owed",
        billBalanceCents: outstandingBill,
        rentBalanceCents: outstandingRent
      }}
      renderBannerImage={(): React.ReactNode => <StyledImage source={House} />}
    />
  );
};

const StyledImage = styled(Image)`
  width: 64px;
  height: 48px;
  position: absolute;
  bottom: 0;
  right: 28px;
`;
