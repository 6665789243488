import { Table } from "@ailo/ui";
import {
  FunctionalReportTable,
  ReportTableColumn,
  ReportTableRow
} from "../../../components/ReportTable";

import React, { useMemo } from "react";
import { useGetRenterOnAppTableDataQuery } from "local/graphql";
import { useReportTeamContext } from "../../..";
import { reports } from "../../../reportsDetails";

// need to exclude dates and dollar amounts because of string formatting
const sortableColumns: string[] = ["propertyAddress", "renterName"];

export function RenterOnAppTable(): React.ReactElement {
  // construct variables
  const variables = useReportTeamContext();
  // get data
  const visualisationData = useGetRenterOnAppTableDataQuery({
    variables,
    onError: () => visualisationData.refetch()
  });

  const reportData = visualisationData.data?.renterOnAppReport;
  const dataRows = reportData?.rows;

  const rows: [ReportTableRow] = useMemo(
    () =>
      dataRows?.map((r) => {
        return r as ReportTableRow;
      }) as [ReportTableRow],
    [dataRows]
  );

  if (!reportData) return <Table.Loading />;
  if (!rows) return <Table.Loading />;

  if (rows.length < 1) return <Table.Loading />;

  const columns = reportData.columns as [ReportTableColumn];
  return (
    <FunctionalReportTable
      reportTitle={reports.renter_on_app.reportHeading}
      rows={rows}
      columns={columns}
      sortableColumns={sortableColumns}
    />
  );
}
