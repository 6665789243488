import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import { Colors } from "@ailo/primitives";
import { AwaitingApprovalPropertyOnboardingListFilterData } from "./hooks";
import { AwaitingApprovalPropertyOnboardingListAddressSearchBox } from "./AwaitingApprovalPropertyOnboardingListAddressSearchBox";
import { AwaitingApprovalPropertyOnboardingListPortfolioFilter } from "./AwaitingApprovalPropertyOnboardingListPortfolioFilter";
import { AwaitingApprovalPropertyOnboardingListStatusFilter } from "./AwaitingApprovalPropertyOnboardingListStatusFilter";

interface Props {
  filters: AwaitingApprovalPropertyOnboardingListFilterData;
}

export function AwaitingApprovalPropertyOnboardingListFilters({
  filters
}: Props): ReactElement {
  return (
    <HeaderContainer>
      <FilterContainer>
        <AwaitingApprovalPropertyOnboardingListAddressSearchBox
          addressSearchData={filters.addressSearchData}
        />
        <AwaitingApprovalPropertyOnboardingListPortfolioFilter
          portfolioFilterData={filters.portfolioFilterData}
        />
        <AwaitingApprovalPropertyOnboardingListStatusFilter
          statusFilterData={filters.statusFilterData}
        />
      </FilterContainer>
    </HeaderContainer>
  );
}

const FilterContainer = styled(View)`
  padding: 16px 16px 8px;
  min-height: 64px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background-color: ${Colors.WHITE};
  box-shadow: 0px 1px 4px rgba(28, 30, 38, 0.2);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

const HeaderContainer = styled(View)`
  width: 100%;
  background-color: ${Colors.CLOUD};
`;
