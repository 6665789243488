import React, { useCallback, useEffect } from "react";
import { View } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { SFC } from "@ailo/primitives";
import { useApolloClient } from "@ailo/services";
import {
  TextInputFormField,
  useFormFieldWarning,
  validateMaxLength
} from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import {
  GetBillsCountDocument,
  GetBillsCountQuery,
  GetBillsCountQueryVariables,
  BillStatus
} from "local/graphql";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";

const name = "invoiceNumber";
const MAX_LENGTH = 255;

const InvoiceNumberInput: SFC = ({ style }) => {
  const client = useApolloClient();
  const currentOrg = useCurrentAgencyOrg();
  const {
    control,
    formState,
    watch,
    getValues,
    register,
    unregister,
    setValue,
    errors
  } = useFormContext<AddBillFormData>();

  const { warning, triggerWarning } = useFormFieldWarning<
    AddBillFormData,
    typeof name,
    string | undefined
  >({
    name,
    validate: useCallback(
      async (value) => {
        const supplierReference = getValues("supplierReference")?.value;
        if (!value?.trim())
          return "Consider including an invoice number if provided.\nThis makes it easier to keep track of bills.";
        if (!supplierReference) return undefined;
        const result = await client.query<
          GetBillsCountQuery,
          GetBillsCountQueryVariables
        >({
          query: GetBillsCountDocument,
          variables: {
            organisationId: currentOrg.ailoRN,
            payeeId: [supplierReference],
            invoiceNumber: [value],
            status: [BillStatus.Approved, BillStatus.Refunded]
          },
          fetchPolicy: "network-only"
        });
        if (result.data?.bills?.pageInfo.total) {
          return "Warning: Invoice number already exists in Ailo.\nYou may be duplicating a bill.";
        }
        return undefined;
      },
      [client, currentOrg.ailoRN, getValues]
    ),
    mode: "onChangeAfterWarningDebounced"
  });

  const invoiceNumber = watch("invoiceNumber");
  const supplierReference = watch("supplierReference")?.value;
  const areFieldsBelowFocused = watch("fieldUnderInvoiceNumberWasFocused");

  useEffect(() => {
    if (
      areFieldsBelowFocused ||
      (supplierReference && formState.dirtyFields.invoiceNumber)
    ) {
      triggerWarning();
    }
  }, [
    areFieldsBelowFocused,
    triggerWarning,
    supplierReference,
    formState.dirtyFields.invoiceNumber
  ]);

  useEffect(() => {
    register({ name: "invoiceNumberWarning" });
    return (): void => {
      unregister("invoiceNumberWarning");
    };
  }, [register, unregister]);

  useEffect(() => {
    setValue("invoiceNumberWarning", warning);
  }, [warning, setValue]);

  return (
    <View style={style}>
      <Controller
        control={control}
        name={name}
        defaultValue={""}
        render={({ value, onChange, onBlur, ref }) => {
          return (
            <TextInputFormField
              {...{ value, onChange }}
              inputRef={ref}
              error={errors[name]?.message}
              label={"Invoice Number"}
              placeholder={"Invoice Number"}
              autoCompleteType={"off"}
              autoCorrect={false}
              warning={!!warning}
              testID={"InvoiceNumberInputFormField"}
              onBlur={() => {
                setValue("invoiceNumber", invoiceNumber?.trim());
                onBlur();
                triggerWarning();
              }}
            />
          );
        }}
        rules={{
          required: false,
          validate: validateMaxLength(MAX_LENGTH)
        }}
      />
    </View>
  );
};

export { InvoiceNumberInput };
