import {
  BillsIcon,
  Colors,
  opacify,
  PropertyIcon,
  Text,
  TransferIcon
} from "@ailo/primitives";
import {
  Badge,
  CellContainer,
  DateTimeWithTimeZone,
  GridHeadRow,
  GridRow,
  GridRowProps,
  Money,
  TextCell,
  TextCellContent
} from "@ailo/ui";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { Transaction } from "./useTransactionsQuery";

const columns: NonNullable<GridRowProps["columns"]> = [
  {
    name: "Type",
    verticalAlign: "middle",
    width: 30.5
  },
  {
    key: "createdAt",
    name: "Date",
    verticalAlign: "middle",
    width: 13
  },
  {
    name: "Property",
    verticalAlign: "middle",
    width: 30.5
  },
  {
    name: "Status",
    verticalAlign: "middle",
    width: 13
  },
  {
    name: "Total (GST incl)",
    verticalAlign: "middle",
    width: 13
  }
];

const StyledTypeCell = styled(CellContainer)`
  flex-direction: row;
  align-items: center;
`;

const StyledTypeIcon = styled(View)`
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid ${opacify(Colors.SPACE_BLACK, 0.1)};
  margin-right: 16px;
`;

const typeIconProps = {
  color: opacify(Colors.SPACE_BLACK, 0.6),
  width: 16,
  height: 16
};

export function TransactionListTableRow({
  style,
  transaction
}: {
  style?: StyleProp<ViewStyle>;
  transaction: Transaction;
}): React.ReactElement {
  const TypeIcon =
    transaction.category.type === "property"
      ? PropertyIcon
      : transaction.propertyName
      ? BillsIcon
      : TransferIcon;

  return (
    <GridRow style={style} columns={columns}>
      <StyledTypeCell>
        <StyledTypeIcon>
          <TypeIcon {...typeIconProps} />
        </StyledTypeIcon>
        <TextCellContent weight={"medium"}>
          {transaction.category.name}
        </TextCellContent>
      </StyledTypeCell>

      <TextCell>
        {DateTimeWithTimeZone.from(transaction.createdAt).format("DD MMM YY")}
      </TextCell>

      <TextCell>{transaction.propertyName || "—"}</TextCell>

      <CellContainer>
        <Badge type={transaction.status.type}>{transaction.status.name}</Badge>
      </CellContainer>

      <TextCell>{Money.fromCents(transaction.amount.cents).format()}</TextCell>
    </GridRow>
  );
}

TransactionListTableRow.Header = function TransactionListTableRowHeader({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <GridHeadRow
      style={style}
      columns={columns}
      sortOrder={{ columnKey: "createdAt", direction: "DESC" }}
    />
  );
};

TransactionListTableRow.Loading = function TransactionListTableRowLoading({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <GridRow style={style} columns={columns}>
      <StyledTypeCell>
        <StyledTypeIcon>
          <PropertyIcon {...typeIconProps} />
        </StyledTypeIcon>
        <Text.BodyS.Loading width={120} />
      </StyledTypeCell>
      <TextCell.AdjustableLoading width={60} />
      <TextCell.AdjustableLoading width={100} />
      <TextCell.AdjustableLoading width={80} />
      <TextCell.AdjustableLoading width={40} />
    </GridRow>
  );
};
