import { useHasFeature } from "@ailo/domains";
import {
  didQueryNotLoadYet,
  throwIfQueryFailed,
  useOnFocus
} from "@ailo/services";
import {
  Button,
  FormField,
  FormFieldRow,
  MoneyInterface,
  SelectInput,
  SpinnerOverlay,
  TextInputFormField
} from "@ailo/ui";
import { Screens, useNavigation } from "local/common";
import {
  FeeBlueprintChargeType,
  FeeBlueprintType,
  FeeEventType,
  FeeFrequency,
  PlatformFeatureId,
  useGetFeeTaxCategoriesQuery
} from "local/graphql";
import React, { ReactElement } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";
import { TabContentStickyBottom } from "../../common";
import { ChargeDetails } from "./ChargeDetails";
import { taxCategoryDefaultValues } from "./taxCategoryDefaultValues";

const RECURRING_FEE_TAX_CATEGORIES = Object.keys(
  taxCategoryDefaultValues
).filter(
  (taxCategoryId) =>
    taxCategoryDefaultValues[taxCategoryId].type === "RecurringFee"
);

export interface ManageFeeBlueprintFormData {
  name: string | null;
  type: FeeBlueprintType | null;
  taxCategoryId: string | null;
  chargeType: FeeBlueprintChargeType | null;
  frequency: FeeFrequency | null;
  chargeDateDayOfWeek: number | null;
  chargeDateDayOfMonth: number | null;
  chargeDateMonth: number | null;
  price: MoneyInterface | null;
  includesGst: boolean;
  oneWeekRentPercentage: number | null;
  event: FeeEventType | null;
}

export function ManageFeeBlueprintForm({
  mode,
  initialValue,
  loading: loadingProp = false,
  style,
  onSubmit
}: {
  mode: "create" | "update";
  initialValue?: ManageFeeBlueprintFormData;
  loading?: boolean;
  style?: StyleProp<ViewStyle>;
  onSubmit(data: ManageFeeBlueprintFormData): void;
}): ReactElement {
  const navigation = useNavigation<Screens.SettingsTab>();
  const canManageOneOffFeeBlueprints = useHasFeature(
    PlatformFeatureId.OneOffFeeBlueprints
  );

  const form = useForm<ManageFeeBlueprintFormData>({
    defaultValues: initialValue
  });
  const taxCategoryId = form.watch("taxCategoryId");

  const categoriesResult = useGetFeeTaxCategoriesQuery();
  throwIfQueryFailed(categoriesResult, { dataKey: "feeTaxCategories" });
  useOnFocus(categoriesResult.refetch);

  const cancel = (): void =>
    navigation.navigate(Screens.SettingsTab, {
      tab: "fees"
    });

  const submit = form.handleSubmit(onSubmit);
  const loading = loadingProp || didQueryNotLoadYet(categoriesResult);

  return (
    <FormProvider {...form}>
      <View
        style={[
          {
            position: "static" as any
          },
          style
        ]}
      >
        {loading ? (
          <FormFieldRow style={{ marginBottom: 24 }}>
            <TextInputFormField.Loading labelWidth={60} />
            <TextInputFormField.Loading labelWidth={60} />
          </FormFieldRow>
        ) : (
          <FormFieldRow style={{ marginBottom: 24 }}>
            <Controller
              control={form.control}
              name={"taxCategoryId"}
              defaultValue={null}
              render={({ value, onChange, onBlur }) => (
                <FormField
                  label={"Category"}
                  error={form.errors.taxCategoryId?.message}
                >
                  <SelectInput
                    {...{ value, onBlur }}
                    placeholder={"Select"}
                    options={categoriesResult
                      .data!.feeTaxCategories!.filter((taxCategory) => {
                        return (
                          canManageOneOffFeeBlueprints ||
                          RECURRING_FEE_TAX_CATEGORIES.includes(taxCategory.id)
                        );
                      })
                      .map((taxCategory) => ({
                        label: taxCategory.name,
                        value: taxCategory.id
                      }))}
                    onChange={(option) => {
                      onChange(option.value);
                    }}
                    useTextInputHeight
                  />
                </FormField>
              )}
              rules={{
                required: "Category is required."
              }}
            />
            <Controller
              control={form.control}
              name={"name"}
              defaultValue={null}
              render={({ value, onChange, onBlur }) => (
                <TextInputFormField
                  {...{ value, onChange, onBlur }}
                  label={"Fee name"}
                  error={form.errors.name?.message}
                  placeholder={"Fee name"}
                />
              )}
              rules={{
                required: "Fee name is required."
              }}
            />
          </FormFieldRow>
        )}

        {taxCategoryId && <ChargeDetails mode={mode} />}

        <TabContentStickyBottom>
          <View style={{ flexDirection: "row" }}>
            <Button.Secondary
              disabled={form.formState.isSubmitting}
              onPress={cancel}
            >
              {"Cancel"}
            </Button.Secondary>
            <Button.Primary
              disabled={form.formState.isSubmitting || !form.formState.isDirty}
              style={{ marginLeft: "auto" }}
              testID={"SubmitButton"}
              onPress={submit}
            >
              {mode === "create" ? "Create fee template" : "Save"}
            </Button.Primary>
          </View>
        </TabContentStickyBottom>

        <SpinnerOverlay
          visible={form.formState.isSubmitting}
          spinnerVerticalAlign={{ top: 250 }}
        />
      </View>
    </FormProvider>
  );
}
