import { useHasFeature } from "@ailo/domains";
import { KeysIcon, Text, Colors } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { PlatformFeatureId } from "local/graphql";
import React, { useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { ViewKeysModal } from "./ViewKeysModal";

export function KeysButton({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement | null {
  const hasKeyFeature = useHasFeature(
    PlatformFeatureId.CreateAndViewPropertyKeys
  );
  const [showModal, setShowModal] = useState(false);

  if (!hasKeyFeature) {
    return null;
  }

  return (
    <>
      <Button
        variant={"secondary"}
        style={style}
        type={"small"}
        onPress={() => {
          setShowModal(true);
        }}
      >
        <KeysIcon height={20} width={20} style={{ marginRight: 6 }} />
        <Text.BodyM weight={"medium"} color={Colors.AILO_RED}>
          {"Keys"}
        </Text.BodyM>
      </Button>
      <ViewKeysModal
        visible={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </>
  );
}
