import styled from "styled-components/native";
import { View } from "react-native";
import { Colors, opacify } from "@ailo/primitives";

export const IconContainer = styled(View)`
  background-color: ${Colors.WHITE};
  margin-right: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 4px ${opacify(Colors.SPACE_BLACK, 0.2)};
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
`;
