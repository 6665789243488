import { Colors } from "@ailo/primitives";
import { View } from "react-native";
import styled from "styled-components/native";

export const Divider = styled(View)`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: -36px;
  border-top-width: 1px;
  border-top-color: ${Colors.GRAYSCALE.OUTLINE};
`;
