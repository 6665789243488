import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Colors, HouseAndDocumentImage, SFC, Text } from "@ailo/primitives";

const EmptyTransactionsList: SFC = ({ style }) => (
  <Container style={style}>
    <HouseAndDocumentImage width={128} height={80} style={{ marginTop: 32 }} />
    <Text.BodyL style={{ marginTop: 20 }} weight={"medium"}>
      {"No Transactions Yet"}
    </Text.BodyL>
    <Text.BodyS style={{ marginTop: 8 }} color={Colors.TEXT.DARK.SECONDARY}>
      {"Transaction history will appear here"}
    </Text.BodyS>
  </Container>
);

const Container = styled(View)`
  height: 204px;
  align-items: center;
`;

export { EmptyTransactionsList };
