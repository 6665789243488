import React, { useCallback } from "react";
import { LeaseRenewalForm } from "./LeaseRenewalForm";
import { PropertyDetails } from "../components/PropertyDetails";
import { BaseSplitScreenModalForm } from "../BaseSplitScreenModalForm";
import { Action } from "local/domain/project";
import { TenancyAgreementFragment, ActionType } from "local/graphql";

interface Props {
  action?: Action<ActionType.AssignTenancyAgreement>;
  tenancyId: string;
  agreement?: TenancyAgreementFragment;
  prefillStartDate?: string;
  onDismiss: () => void;
  onSuccess: (rentReviewChecked: boolean, agreementStartDate: string) => void;
}

export function LeaseRenewalModalForm({
  action,
  tenancyId,
  agreement,
  prefillStartDate,
  onDismiss,
  onSuccess
}: Props): React.ReactElement {
  const renderFormComponent = useCallback(
    (onFormSubmitting: (isSubmitting: boolean) => void): React.ReactElement => (
      <LeaseRenewalForm
        action={action}
        agreement={agreement}
        tenancyId={tenancyId}
        onDismiss={onDismiss}
        onSuccess={onSuccess}
        prefillStartDate={prefillStartDate}
        onFormSubmitting={(isSubmitting) => onFormSubmitting(isSubmitting)}
      />
    ),
    [action, agreement, tenancyId, onDismiss, onSuccess, prefillStartDate]
  );

  return (
    <BaseSplitScreenModalForm
      title={"Lease renewal"}
      renderFormComponent={renderFormComponent}
      renderRightComponent={() => <PropertyDetails tenancyId={tenancyId} />}
      onDismiss={onDismiss}
    />
  );
}
