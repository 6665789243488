import { TenancyBond } from "local/tabs";
import { useToastContext } from "@ailo/ui";
import { TenancyBondStatus, useUpdateBondStatusMutation } from "local/graphql";
import { useCallback } from "react";
import { AiloRN, nsEntities } from "@ailo/ailorn";

export function useUpdateBondStatus(
  bond: TenancyBond | undefined,
  status: TenancyBondStatus,
  successMessage: string
): () => void {
  const toast = useToastContext();
  const [updateBondStatusMutation] = useUpdateBondStatusMutation();
  return useCallback(async () => {
    if (!bond) {
      return;
    }

    const update = await updateBondStatusMutation({
      variables: {
        bondAilorn: AiloRN.of(
          nsEntities.propertymanagement.bond,
          bond.id
        ).toString(),
        status
      }
    });
    if (update.errors) {
      toast.show({
        type: "error",
        message: "Unable to update bond status. Please try again."
      });
    } else {
      toast.show({
        type: "success",
        message: successMessage
      });
    }
  }, [toast, updateBondStatusMutation, bond, status, successMessage]);
}
