import React, { ReactElement } from "react";
import { getActionInitiatorDisplayName } from "../utils";
import {
  ApprovedMigratingManagementFragment,
  LiveMigratingManagementFragment
} from "local/graphql";
import { TextCell } from "@ailo/ui";

function ApprovedByCell({
  row: { approvedBy, approvedByDisplay }
}: {
  row: ApprovedMigratingManagementFragment | LiveMigratingManagementFragment;
  rowIndex: number;
}): ReactElement {
  return (
    <TextCell>
      {approvedByDisplay ?? getActionInitiatorDisplayName(approvedBy)}
    </TextCell>
  );
}

ApprovedByCell.Loading = TextCell.Loading;

export { ApprovedByCell };
