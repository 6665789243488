import React, { FC, useState, useCallback, useEffect } from "react";
import { ScrollView } from "react-native";
import { ScreenComponent, didMutationSucceed } from "@ailo/services";
import {
  Button,
  SidebarTitle,
  SidebarStickyBottom,
  SpinnerOverlay
} from "@ailo/ui";
import {
  BalanceDisplay,
  BankAccountMethod,
  WithdrawStatus
} from "@ailo/domains";
import { useAnalytics } from "local/common";
import { BankAccountDisplay } from "./BankAccountDisplay";
import { TransferError } from "./TransferError";
import { TransferSuccess } from "./TransferSuccess";
import { useWithdrawFromWalletMutation } from "local/graphql";

interface Props {
  onClose: () => void;
  wallet: {
    id: string;
    availableBalance: { cents: number };
    idempotencyKey: string;
  };
  bankAccount?: BankAccountMethod;
  onTransferSuccess: () => void;
  onReload: () => void;
  balanceLoading: boolean;
}

const TransferFundsSidebarContent: FC<Props> = ({
  onClose,
  wallet,
  bankAccount,
  onTransferSuccess,
  onReload,
  balanceLoading
}) => {
  const analytics = useAnalytics();

  const [transferAmount, setTransferAmount] = useState<number>(0);
  const [error, setError] = useState(false);
  const [
    overrideToKeepShowingLoadingSpinner,
    setOverrideToKeepShowingLoadingSpinner
  ] = useState(false);

  const [withdrawFromWalletMutation, result] = useWithdrawFromWalletMutation();
  const showLoadingOverlay =
    overrideToKeepShowingLoadingSpinner ||
    (result.called && balanceLoading) ||
    result.loading;
  const transferSuccess =
    !balanceLoading &&
    didMutationSucceed(result) &&
    [WithdrawStatus.Pending, WithdrawStatus.Success].some(
      (successfulStatus) =>
        successfulStatus == result.data?.withdrawFromWallet?.status
    );
  const channel =
    result.data?.withdrawFromWallet?.businessTransaction?.paymentChannel ||
    undefined;

  const hasFundsToWithdraw = wallet.availableBalance.cents > 0;
  useEffect(() => {
    if (!transferSuccess && !hasFundsToWithdraw) {
      onClose();
    }
    // Intentionally ignore `onClose` dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFundsToWithdraw, transferSuccess]);

  useEffect(() => {
    if (balanceLoading) setOverrideToKeepShowingLoadingSpinner(false);
  }, [balanceLoading]);

  const onTransfer = useCallback(() => {
    if (!bankAccount) throw new Error("Bank account not found");
    setOverrideToKeepShowingLoadingSpinner(true);
    setTransferAmount(wallet.availableBalance.cents);
    withdrawFromWalletMutation({
      variables: {
        withdrawInput: {
          amount: { cents: wallet.availableBalance.cents },
          fromWalletId: wallet.id,
          idempotencyKey: wallet.idempotencyKey,
          toPaymentMethodId: bankAccount.id
        }
      }
    }).then((result) => {
      switch (result.data?.withdrawFromWallet?.status) {
        case WithdrawStatus.Pending:
        case WithdrawStatus.Success:
          onTransferSuccess();
          analytics.trackFundsWithdrawn();
          break;
        default:
          setError(true);
          setOverrideToKeepShowingLoadingSpinner(false);
          analytics.trackFundsWithdrawalFailed({
            errorCode: status || "NO_STATUS_RETURNED"
          });
          break;
      }
    });
  }, [
    bankAccount,
    wallet,
    setTransferAmount,
    setError,
    withdrawFromWalletMutation,
    onTransferSuccess,
    analytics
  ]);

  const reload = useCallback(async () => {
    setError(false);
    onReload();
  }, [setError, onReload]);

  const bottomButtons = !error && (
    <SidebarStickyBottom
      style={{
        flexDirection: "row-reverse",
        justifyContent: "space-between"
      }}
    >
      {!transferSuccess && (
        <Button.Primary
          disabled={
            !bankAccount?.accountNumber || wallet.availableBalance.cents <= 0
          }
          onPress={onTransfer}
        >
          {"Transfer"}
        </Button.Primary>
      )}
      <Button.Secondary onPress={onClose}>
        {transferSuccess ? "Close" : "Cancel"}
      </Button.Secondary>
    </SidebarStickyBottom>
  );

  return (
    <ScreenComponent analytics={false}>
      <SidebarTitle title={"Transfer Funds"} closeButton onClose={onClose} />
      {error ? (
        <TransferError onReload={reload} />
      ) : transferSuccess ? (
        <TransferSuccess
          bankAccount={bankAccount}
          amount={transferAmount}
          channel={channel}
        />
      ) : (
        <ScrollView>
          <BalanceDisplay
            amount={wallet.availableBalance}
            label={"Amount"}
            style={{ marginHorizontal: 32 }}
          />
          <BankAccountDisplay bankAccount={bankAccount} />
        </ScrollView>
      )}
      {bottomButtons}
      {showLoadingOverlay && <SpinnerOverlay />}
    </ScreenComponent>
  );
};

export { TransferFundsSidebarContent };
