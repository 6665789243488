import React, { ReactElement } from "react";
import { Colors, Text } from "@ailo/primitives";
import { LocalDate } from "@ailo/date";
import { CellContainer } from "./CellContainer";
import { UnscheduledTenanciesListRow } from "../useGetUnscheduledTenanciesRows";

export function DueDateCell({
  row: { dueDate }
}: {
  row: UnscheduledTenanciesListRow;
  rowIndex: number;
}): ReactElement {
  const dueDateLocalDate = LocalDate.parse(dueDate);
  if (!dueDateLocalDate) {
    return (
      <CellContainer>
        <Text.BodyS color={Colors.ALERT_ORANGE}>{"No due date"}</Text.BodyS>
      </CellContainer>
    );
  }
  const missedDueDate = dueDateLocalDate.isBefore(LocalDate.today());
  return (
    <CellContainer>
      <Text.BodyS
        color={missedDueDate ? Colors.ALERT_ORANGE : Colors.TEXT.DARK.PRIMARY}
      >
        {dueDateLocalDate.format("D MMM YYYY")}
      </Text.BodyS>
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <Text.BodyS.Loading width={80} />
    </CellContainer>
  );
}

DueDateCell.Loading = Loading;
