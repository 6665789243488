import { PaginationProps } from "@ailo/ui";

export function getPaginationData(
  input: {
    total: number;
    hasNext: boolean;
    hasPrevious: boolean;
    pageNo: number;
  },
  maxRowsPerPage: number
): PaginationProps {
  const { pageNo, total } = input;
  return {
    currentPage: pageNo,
    pagesCount: Math.ceil(total / maxRowsPerPage)
  };
}
