import React, { ReactElement } from "react";
import { BondDetailsFormContext } from "../common/BondDetailsFormContext";
import { EditBondDetailsSidebarContent } from "./EditBondDetailsSidebarContent";
import { BondDetailsProps } from "../common/BondDetailsProps";

export function EditBondDetailsSidebar(props: BondDetailsProps): ReactElement {
  return (
    <BondDetailsFormContext>
      <EditBondDetailsSidebarContent
        title={"Edit bond details"}
        onSuccessMessage={"Bond details updated"}
        onFailureMessage={"Unable to update details. Please try again"}
        {...props}
      />
    </BondDetailsFormContext>
  );
}
