import { ChatNavigationParams, DEFAULT_CHAT_TEAM_ID } from "@ailo/domains";
import { Link } from "@ailo/services";
import { CommonActions } from "@react-navigation/native";
import { Screens } from "local/common";
import { generateCommsTabUrl } from "./generateCommsTabUrl";

export function generateLinkToCommsTab({
  teamId = DEFAULT_CHAT_TEAM_ID,
  chatId,
  threadId
}: Partial<ChatNavigationParams>): Link {
  const to = generateCommsTabUrl({ teamId, chatId, threadId });

  if (!chatId) {
    return {
      to,
      action: CommonActions.navigate(Screens.CommsTab, {
        teamId,
        screen: Screens.EmptyChat
      })
    };
  }

  return {
    to,
    action: CommonActions.navigate(Screens.CommsTab, {
      teamId,
      screen: Screens.ChatThread,
      params: {
        chatId,
        threadId
      }
    })
  };
}
