import { ParamListBase } from "@react-navigation/native";
import { Screens } from "../Screens";
import { ChatTeamId } from "@ailo/domains";

export interface CommsTabStackNavigatorParamList extends ParamListBase {
  [Screens.CommsTab]: {
    teamId: ChatTeamId;
  };
  [Screens.ChatThread]: {
    chatId: string;
    threadId?: string;
  };
  [Screens.NewChatMessage]: {
    chatId?: string;
    initialContactIds?: string | string[];
  };
}
