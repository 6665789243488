import styled from "styled-components/native";
import React, { ReactElement } from "react";
import { Colors, DefaultProps } from "@ailo/primitives";
import { StyleProp, View, ViewStyle } from "react-native";
import { TableRow } from "@ailo/ui";

export function AllocationsTableRow({
  columns,
  children,
  cellContainer = CellContainer
}: DefaultProps<{
  columns?: ViewStyle[];
  cellContainer?: React.ComponentType<{
    children?: React.ReactNode;
    style?: StyleProp<ViewStyle>;
  }>;
}>): ReactElement {
  return (
    <RowContainer columns={columns} cellContainer={cellContainer}>
      {children}
    </RowContainer>
  );
}

export const RowContainer = styled(TableRow)`
  flex-direction: row;
  border-bottom-width: 1px;
  padding-left: 0;
  padding-right: 0;
  border-bottom-color: ${Colors.GRAYSCALE.DIVIDERS};
`;

export const FooterRowContainer = styled(RowContainer)`
  border-bottom-width: 0;
`;

export const HeaderCellContainer = styled(View)`
  padding-top: 20px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  align-self: center;
`;

export const CellContainer = styled(View)`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  align-self: center;
`;
