import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BankingDetailsFormData } from "./BankingDetailsFormData";
import { FormField, SelectInput } from "@ailo/ui";

export function AbaFileFormatInput(): React.ReactElement {
  const { control } = useFormContext<BankingDetailsFormData>();
  return (
    <Controller
      control={control}
      name={"abaFileFormat"}
      render={({ value, onChange }) => {
        return (
          <FormField label={"Format of .aba file"}>
            <SelectInput
              value={value}
              onChange={(change) => {
                onChange(change?.value);
              }}
              useTextInputHeight={true}
              options={[
                { value: "balanced", label: "Balanced" },
                { value: "unbalanced", label: "Unbalanced" }
              ]}
            />
          </FormField>
        );
      }}
    />
  );
}
