import { SidebarContent, SidebarTitle } from "@ailo/ui";
import React from "react";
import { ViewStyle } from "react-native";
import {
  ManagementOrTenancySidebarError,
  ManagementOrTenancySidebarLoading
} from "../common";
import { isFormerManagement } from "../PropertyContent/components/utils";
import { ManagementSidebarDetails } from "./ManagementSidebarDetails";
import { useGetManagementDetailsAndTeams } from "./useGetManagementDetailsAndTeams";

interface Props {
  onDismiss: () => void;
  managementDetails: {
    id: string;
  };
}

export function ManagementSidebarContent({
  onDismiss,
  managementDetails
}: Props): React.ReactElement {
  const { data, loading, refetch } = useGetManagementDetailsAndTeams({
    managementId: managementDetails.id
  });

  if (loading && !data) {
    return (
      <ManagementOrTenancySidebarLoading>
        <ManagementDetailsHeader
          style={{ marginHorizontal: 0, marginTop: 0, marginBottom: 0 }}
          onDismiss={onDismiss}
        />
      </ManagementOrTenancySidebarLoading>
    );
  }

  if (!data) {
    return (
      <ManagementOrTenancySidebarError
        type={"manangement"}
        onRetry={(): void => {
          refetch();
        }}
      >
        <ManagementDetailsHeader onDismiss={onDismiss} />
      </ManagementOrTenancySidebarError>
    );
  }

  const { managementData, teamData, files } = data;

  return (
    <SidebarContent
      title={
        isFormerManagement(managementData.endDate)
          ? "Former management"
          : "Management details"
      }
      closeButton
      onClose={onDismiss}
    >
      <ManagementSidebarDetails
        managementData={managementData}
        teamData={teamData}
        files={files}
      />
    </SidebarContent>
  );
}

function ManagementDetailsHeader({
  style,
  onDismiss
}: {
  style?: ViewStyle;
  onDismiss: () => void;
}): React.ReactElement {
  return (
    <SidebarTitle
      title={"Management details"}
      closeButton
      onClose={onDismiss}
      style={style}
    />
  );
}
