import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";
import { Button } from "@ailo/ui";

interface Props {
  text: string;
  buttonText: string;
  onButtonPress?: () => void;
}

export function EmptyCardContent({
  text,
  buttonText,
  onButtonPress: onButtonPress
}: Props): ReactElement {
  return (
    <Container>
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{text}</Text.BodyS>
      {onButtonPress && (
        <CreateButton onPress={onButtonPress}>{buttonText}</CreateButton>
      )}
    </Container>
  );
}

const Container = styled(View)`
  align-items: center;
  padding: 18px 16px 34px 0;
  margin-left: 16px;
`;

const CreateButton = styled(Button)`
  margin-top: 16px;
`;
