import { SFC } from "@ailo/primitives";
import React from "react";
import { View } from "react-native";
import { LineItemAmountInput } from "./LineItemAmountInput";
import { LineItemClearButton } from "./LineItemClearButton";
import { LineItemDescriptionInput } from "./LineItemDescriptionInput";

interface Props {
  index: number;
  totalNumLineItems: number;
  clearButtonOnPress: () => void;
}

const LineItemInput: SFC<Props> = ({
  index,
  totalNumLineItems,
  clearButtonOnPress,
  style,
  ...rest
}) => {
  return (
    <View
      {...rest}
      testID={"LineItemFormGroup"}
      style={{ flexDirection: "row", marginTop: 14 }}
    >
      <LineItemDescriptionInput
        index={index}
        style={{
          // Widths are specified as percentages and calculated below
          // because otherwise, `TextInputFormField` doesn't respect
          // the parent container's width for some reason
          width: "50%",
          paddingRight: 8
        }}
      />
      <View
        style={{
          flexDirection: "row",
          alignItems: "flex-start",
          width: "50%",
          paddingLeft: 8
        }}
      >
        <LineItemAmountInput
          index={index}
          style={{
            width: totalNumLineItems > 1 ? "calc(100% - 32px)" : "100%"
          }}
        />
        {totalNumLineItems > 1 && (
          <LineItemClearButton
            onPress={clearButtonOnPress}
            style={{ marginTop: 14, paddingLeft: 8 }}
          />
        )}
      </View>
    </View>
  );
};

export { LineItemInput };
