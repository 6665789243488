import React, { ReactElement } from "react";
import { BondDetailsForm } from "../common/BondDetailsForm";
import {
  Button,
  SidebarContent,
  SpinnerOverlay,
  useToastContext
} from "@ailo/ui";
import { useFormContext } from "react-hook-form";
import { BondDetailsFormData } from "../common/BondDetailsFormData";
import { getState, Row } from "local/common";
import { parseTenancyDetailsData } from "../common/parseTenancyDetailsData";
import { useGetTenantsAndPropertyDetailsQuery } from "local/graphql";
import { useCreateTenancyBond } from "./useCreateTenancyBond";
import { BondDetailsProps } from "../common/BondDetailsProps";
import { useBondAnalytics } from "local/domain/bond";

export function AddBondDetailsSidebarContent({
  tenancyId,
  managementId,
  onClose
}: BondDetailsProps): ReactElement {
  const { data, loading } = useGetTenantsAndPropertyDetailsQuery({
    variables: { tenancyId }
  });

  const { onBondCreated } = useBondAnalytics();
  const toasts = useToastContext();

  const { createTenancyBond } = useCreateTenancyBond({
    onCompleted: (result) => {
      const createdBond = result?.data?.createTenancyBond;
      const state = getState(data?.tenancy?.property.address.state);

      onBondCreated({
        bondId: createdBond?.id,
        managementId,
        state
      });

      toasts.show({ type: "success", message: "Bond details added" });
      onClose();
    },
    onError: () => {
      toasts.show({ type: "error", message: "Failed to add bond details" });
      onClose();
    }
  });
  const { handleSubmit } = useFormContext<BondDetailsFormData>();

  if (loading) {
    return <SpinnerOverlay />;
  }

  const footerRow = (
    <Row>
      <Button.Secondary onPress={onClose}>{"Cancel"}</Button.Secondary>
      <Button.Primary
        onPress={handleSubmit(async (data) => {
          await createTenancyBond(managementId, tenancyId, data);
        })}
      >
        {"Add details"}
      </Button.Primary>
    </Row>
  );
  return (
    <SidebarContent
      title={"Add bond details"}
      footer={footerRow}
      footerStyle={{ borderTopWidth: 1 }}
    >
      <BondDetailsForm tenancyDetails={parseTenancyDetailsData(data)} />
    </SidebarContent>
  );
}
