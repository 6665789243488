import { BillLineItemsCard, Money } from "@ailo/ui";
import React from "react";
import { ExpenseMergedType, ExpenseType } from "../../graphql/useGetExpense";

interface ExpenseLineItemsProps {
  expense?: ExpenseMergedType;
  loading: boolean;
}

export function ExpenseLineItems({
  expense,
  loading
}: ExpenseLineItemsProps): React.ReactElement | null {
  if (!expense) return null;

  if (loading) {
    return (
      <BillLineItemsCard.Loading
        size={"small"}
        lineItemsWidths={[[120, 60]]}
        taxItemsWidths={[[25, 60]]}
        totalAmountWidths={[45, 80]}
        style={{ marginTop: 32, marginHorizontal: 32, marginBottom: 0 }}
      />
    );
  }

  const lineItems = expense.lineItems?.items;
  const nonTaxLineItems = lineItems?.filter((item) => !item.isTax);
  const includedTaxAmount =
    nonTaxLineItems
      ?.map((li) => li.taxAmount)
      .reduce((a, b) => a.add(b), Money.zero()) ||
    expense.taxAmount ||
    Money.fromCents(0);
  const taxItems = lineItems?.filter((item) => item.isTax);

  const displayLineItems =
    nonTaxLineItems?.map((item) => ({
      key: item.id,
      name: item.description,
      taxInclusiveAmount: item.taxInclusiveAmount
    })) || [];

  const feeLineItem = !!(
    expense.expenseType === ExpenseType.FEE &&
    expense.label &&
    expense.totalAmount
  ) && {
    key: "fee",
    name: expense.label,
    taxInclusiveAmount: { cents: expense.totalAmount.cents }
  };

  if (feeLineItem) {
    displayLineItems?.push(feeLineItem);
  }

  return (
    <BillLineItemsCard
      size={"small"}
      style={{ marginTop: 32, marginHorizontal: 32, marginBottom: 0 }}
      lineItems={displayLineItems}
      taxItems={taxItems?.map((item) => ({
        key: item.id,
        name: item.description,
        taxInclusiveAmount: item.taxInclusiveAmount
      }))}
      includedTaxAmount={includedTaxAmount}
      totalAmount={expense.totalAmount!}
    />
  );
}
