import React, { ReactElement } from "react";
import styled from "styled-components/native";
import {
  Colors,
  DefaultProps,
  ExtendElementProps,
  isReactText
} from "@ailo/primitives";
import { Money } from "@ailo/money";
import { BalanceTableRow, DescriptionText, MoneyText } from "./components";

type BalanceRowProps = ExtendElementProps<
  DefaultProps<{
    header?: boolean;
    amount?: Money | ReactElement;
    icon?: ReactElement;
  }>,
  typeof BalanceTableRow
>;

function InternalBalanceRow({
  header = false,
  style,
  children,
  amount = Money.zero(),
  icon,
  ...rest
}: BalanceRowProps): ReactElement {
  const description = isReactText(children) ? (
    <DescriptionText weight={header ? "medium" : "book"}>
      {children}
    </DescriptionText>
  ) : (
    children
  );
  const money =
    amount instanceof Money ? (
      <MoneyText weight={header ? "medium" : "book"}>
        {amount.format()}
      </MoneyText>
    ) : (
      amount
    );
  return (
    <BalanceTableRow style={style} {...rest}>
      {description}
      {money}
      {icon}
    </BalanceTableRow>
  );
}

const columns = [{ flex: 1 }, { width: 150 }, { width: 52 }];
export const BalanceRow = styled(InternalBalanceRow).attrs((props) => ({
  columns: props.icon ? columns : columns.slice(0, columns.length - 1)
}))``;

export const BalanceHeaderRow = styled(BalanceRow).attrs({ header: true })`
  background-color: ${Colors.CLOUD};
`;
