import {
  BasePropertyOnboardingListContent,
  LivePropertyOnboardingListQuery,
  PropertyOnboardingListLoadingContent,
  PropertyOnboardingListReorder,
  useBasePropertyOnboardingListContent,
  useLivePropertyOnboardingListQuery,
  usePropertyOnboardingListLoadingContent,
  usePropertyOnboardingListReorder
} from "../../hooks";
import { GridSortOrder } from "@ailo/ui";
import { lowerCase, startCase } from "lodash";
import {
  LivePropertyOnboardingListColumns,
  useLivePropertyOnboardingListColumns
} from "./useLivePropertyOnboardingListColumns";
import {
  LivePropertyOnboardingListContent,
  useLivePropertyOnboardingListContent
} from "./useLivePropertyOnboardingListContent";
import { ReactElement } from "react";
import { KeyedLiveMigratingManagementFragment } from "../../types";

export enum LivePropertyOnboardingListColumnNames {
  Address = "Address",
  ApprovedBy = "Approved by",
  LiveOn = "Live on"
}

const SORT_ORDER: GridSortOrder[] = [
  {
    columnKey: LivePropertyOnboardingListColumnNames.LiveOn,
    direction: "DESC"
  },
  {
    columnKey: LivePropertyOnboardingListColumnNames.Address,
    direction: "ASC"
  }
];

function getAnalyticsInfo(sortOrder: GridSortOrder): {
  eventName: string;
  orderBy: string;
} {
  return {
    eventName: "Live Property Onboarding List Reordered",
    orderBy: `${sortOrder.columnKey} ${startCase(
      lowerCase(sortOrder.direction)
    )}`
  };
}

interface Params {
  tabNavigation: ReactElement;
}

interface LivePropertyOnboardingListData {
  queryData: LivePropertyOnboardingListQuery;
  reorder: PropertyOnboardingListReorder;
  columns: LivePropertyOnboardingListColumns;
  content: LivePropertyOnboardingListContent;
  baseContent: BasePropertyOnboardingListContent<KeyedLiveMigratingManagementFragment>;
  loadingContent: PropertyOnboardingListLoadingContent;
}

export function useLivePropertyOnboardingList({
  tabNavigation
}: Params): LivePropertyOnboardingListData {
  const queryData = useLivePropertyOnboardingListQuery({
    initialSortOrder: SORT_ORDER
  });

  const reorder = usePropertyOnboardingListReorder(
    SORT_ORDER,
    queryData.requestFromBeginning,
    getAnalyticsInfo
  );

  const columns = useLivePropertyOnboardingListColumns();

  const content = useLivePropertyOnboardingListContent({
    tabNavigation
  });

  const baseContent = useBasePropertyOnboardingListContent({
    queryData,
    headerCaptionContent: content.headerCaptionContent,
    columns,
    sortOrder: reorder.sortOrder,
    onSortOrderChange: reorder.onSortOrderChange,
    rowClickAction: content.rowClickAction
  });

  const loadingContent = usePropertyOnboardingListLoadingContent({ columns });

  return {
    queryData,
    reorder,
    columns,
    content,
    baseContent,
    loadingContent
  };
}
