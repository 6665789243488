import React, { ReactElement } from "react";
import { ViewStyle } from "react-native";
import { Badge, BadgeType } from "@ailo/ui";
import { SuccessSubtractedIcon } from "@ailo/primitives";
import { ProjectStatus } from "local/graphql";

interface ProjectStatusBadgeProps {
  status: ProjectStatus;
  style?: ViewStyle;
}

export function ProjectStatusBadge({
  status,
  style
}: ProjectStatusBadgeProps): ReactElement {
  return (
    <Badge
      type={statusBadgeTypes[status]}
      leftComponent={
        status === ProjectStatus.Closed && (
          <SuccessSubtractedIcon
            width={12}
            height={12}
            style={{ marginVertical: 2 }}
          />
        )
      }
      style={style}
    >
      {status}
    </Badge>
  );
}

ProjectStatusBadge.Loading = function ProjectStatusBadgeLoading({
  style
}: {
  style?: ViewStyle;
}): React.ReactElement {
  return <Badge.Loading style={style} />;
};

const statusBadgeTypes: Record<ProjectStatus, BadgeType> = {
  [ProjectStatus.Open]: "attention",
  [ProjectStatus.Closed]: "success",
  [ProjectStatus.Archived]: "success"
};
