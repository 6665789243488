import React, { useState, useCallback } from "react";
import { Modal } from "@ailo/ui";
import { ImageCarouselContext, ImageCarouselOptions } from "@ailo/domains";
import { ImageCarouselScreen } from "./ImageCarouselScreen";
import { ErrorBoundary } from "@ailo/services";
import { View } from "react-native";
import { Colors } from "@ailo/primitives";

const defaultOptions: ImageCarouselOptions = {
  imageUrls: [],
  initialImageIndex: 0,
  showDownloadButton: true
};

type Props = React.PropsWithChildren<{}>;
/**
 * @deprecated Use "FileCarouselSetup" instead
 */
export function ImageCarouselSetup({ children }: Props): React.ReactElement {
  const [{ imageUrls, initialImageIndex, showDownloadButton }, setImageData] =
    useState<ImageCarouselOptions>(defaultOptions);
  const dismiss = useCallback((): void => {
    setImageData(defaultOptions);
  }, []);

  const visible = imageUrls.length > 0;
  return (
    <>
      <ImageCarouselContext.Provider value={setImageData}>
        {children}
      </ImageCarouselContext.Provider>
      <Modal
        visible={visible}
        onBackgroundTouch={dismiss}
        contentStyle={{
          flex: 1,
          width: "100%",
          height: "100%"
        }}
      >
        {visible && (
          <View
            style={{
              backgroundColor: Colors.WHITE,
              borderRadius: 8,
              height: "100%"
            }}
          >
            <ErrorBoundary onClose={dismiss}>
              <ImageCarouselScreen
                onClosePress={dismiss}
                imageUrls={imageUrls}
                initialImageIndex={initialImageIndex}
                showDownloadButton={showDownloadButton}
              />
            </ErrorBoundary>
          </View>
        )}
      </Modal>
    </>
  );
}
