import React, { ReactElement } from "react";
import { View } from "react-native";
import {
  KeyedHealthIssuesCard,
  MigratingManagementIssueData
} from "./components/";

interface Props {
  issues?: Record<string, any>;
  migratingManagementIssueData: MigratingManagementIssueData;
  setLoading: (loading: boolean) => void;
}

export function DataIssuesMigratingManagementBody({
  issues,
  migratingManagementIssueData,
  setLoading
}: Props): ReactElement {
  return (
    <View>
      <View style={{ marginTop: 32, marginBottom: 60 }}>
        <KeyedHealthIssuesCard
          issues={issues}
          migratingManagementIssueData={migratingManagementIssueData}
          setLoading={setLoading}
        />
      </View>
    </View>
  );
}
