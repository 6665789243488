import { Colors, Text } from "@ailo/primitives";
import { BankAccountDetails, BankAccountDetailsBox } from "local/domain/bond";
import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

function NTSteps({
  bondAccount,
  reference
}: {
  bondAccount: BankAccountDetails;
  reference?: string;
}): ReactElement {
  return (
    <>
      <Text.BodyL weight={"medium"} style={{ marginBottom: 20 }}>
        {"What to do next"}
      </Text.BodyL>
      <Text.BodyM weight={"medium"} style={{ marginBottom: 20 }}>
        {"Step 1"}
      </Text.BodyM>
      <Text.BodyM
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginBottom: 16 }}
      >
        {"Transfer funds requested by Agency to the Ailo virtual account. \n"}
        {"IMPORTANT: Please ensure you use the provided bond reference"}
      </Text.BodyM>
      <BankAccountDetailsBox
        headerContent={
          <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
            {"Requested by agency"}
          </Text.BodyS>
        }
        reference={reference}
        bankAccount={bondAccount}
        style={{ width: 327, marginTop: 12, marginBottom: 32 }}
      />
      <Text.BodyM weight={"medium"} style={{ marginBottom: 20 }}>
        {"Step 2"}
      </Text.BodyM>
      <Text.BodyM
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginBottom: 16 }}
      >
        {"Find out bank account details of renters and transfer "}
      </Text.BodyM>
      <BorderedBox
        style={{
          borderRadius: 4,
          padding: 18,
          marginTop: 12,
          width: 327
        }}
      >
        <Text.BodyS
          weight={"book"}
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ marginBottom: 20 }}
        >
          {"Release to renters"}
        </Text.BodyS>
        <Text.BodyM weight={"medium"} style={{ marginBottom: 20 }}>
          {"Transfer balance to renter's chosen bank account"}
        </Text.BodyM>
      </BorderedBox>
    </>
  );
}

export const BorderedBox = styled(View)`
  border: 1px solid ${Colors.GRAYSCALE.OUTLINE};
  border-radius: 12px;
`;

export { NTSteps };
