import { ErrorAlertScreen, TextInputFormField } from "@ailo/ui";
import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import { RentReviewSection } from "../RentReviewSection";

export function CreateRentReviewStep({
  display
}: {
  display: boolean;
}): ReactElement {
  return (
    <FormContainer style={{ display: display ? undefined : "none" }}>
      <RentReviewSection />
    </FormContainer>
  );
}

CreateRentReviewStep.Loading = function Loading(): ReactElement {
  return (
    <>
      <View style={{ flexDirection: "row" }}>
        <TextInputFormField.Loading style={{ flex: 1, marginRight: 12 }} />
        <TextInputFormField.Loading style={{ flex: 1 }} />
      </View>
      <View style={{ marginTop: 20, width: "50%" }}>
        <TextInputFormField.Loading style={{ flex: 1 }} />
      </View>
    </>
  );
};

CreateRentReviewStep.Error = function Error({
  onRetry
}: {
  onRetry: () => void;
}): React.ReactElement {
  return (
    <FormContainer style={{ justifyContent: "center" }}>
      <ErrorAlertScreen
        variant={"medium"}
        title={"There was a problem loading\nLease Renewal"}
        onRetry={onRetry}
      />
    </FormContainer>
  );
};

const FormContainer = styled(View)`
  padding: 0 32px 60px;
  flex: 1;
`;
