import React, { ReactElement } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  View,
  ViewProps
} from "react-native";
import styled from "styled-components/native";

import { Colors, opacify } from "@ailo/primitives";
import { useHeaderHeight } from "@react-navigation/stack";

function ChatScreenContainer({
  style,
  children,
  onLayout
}: Pick<ViewProps, "style" | "children" | "onLayout">): ReactElement {
  const headerHeight = useHeaderHeight();

  return (
    <StyledSafeAreaView style={style}>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : undefined}
        keyboardVerticalOffset={headerHeight}
        style={{ flex: 1 }}
      >
        <View onLayout={onLayout} style={{ flex: 1 }}>
          {children}
        </View>
      </KeyboardAvoidingView>
    </StyledSafeAreaView>
  );
}

const StyledSafeAreaView = styled(SafeAreaView)`
  flex: 1;
  border-left-style: solid;
  border-left-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  background-color: ${Colors.WHITE};
`;

export { ChatScreenContainer };
