import { withScreenComponent } from "@ailo/services";
import { PageContent, Screens, useNavigation, useRoute } from "local/common";
import { TeamsFilter } from "local/domain/propertyManagement";
import React, { ReactElement, useCallback } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { ReportName, reports } from "./reportsDetails";
import {
  BillIssuesTable,
  PropertyIssuesTable,
  PropertiesTable,
  BillsTable,
  CentrepayTable,
  IncomeByPropertyTable,
  IncomeByChartOfAccountTable,
  TenancyFirstRentPaymentsTable,
  RenterOnAppTable,
  PropertyLiveTable,
  NotificationsTable,
  InvestorOnAppTable,
  FinancialPerformanceDashboard,
  BondsTable,
  PropertyIssuesMissingFeesTable,
  useRevenueTable
} from "./reports";
import { useReportsBreadCrumb } from "./useReportsBreadcrumb";
import { useGlobal } from "reactn";
import { PlatformFeatureId } from "local/graphql";

/**
 * A tuple a component, and the properties to render it with to pass to it
 */
export type HookComponent<TProps = any> = [
  (props: TProps) => ReactElement,
  TProps
];

/**
 * A page which needs to render itself and the top level components (e.g. download button)
 */
export interface ReportPageState<TPage = any, TComponents = any> {
  reportPage: HookComponent<TPage>;
  topComponents: HookComponent<TComponents>;
}

type PageHook = {
  useReportPage: () => ReportPageState;
};
type ReportComponent = (() => ReactElement) | PageHook;

export function isPageComponents(
  component: ReportComponent
): component is PageHook {
  return (component as PageHook).useReportPage !== undefined;
}

const reportComponents: Record<ReportName, ReportComponent> = {
  property_issues: PropertyIssuesTable,
  property_issues_missing_fee: PropertyIssuesMissingFeesTable,
  bill_issues: BillIssuesTable,
  properties: PropertiesTable,
  bills_due: BillsTable,
  income_by_property: IncomeByPropertyTable,
  income_by_chart_of_account: IncomeByChartOfAccountTable,
  first_rent_payments: TenancyFirstRentPaymentsTable,
  renter_on_app: RenterOnAppTable,
  property_live: PropertyLiveTable,
  property_activation: PropertyLiveTable,
  investors: InvestorOnAppTable,
  property_notifications: NotificationsTable,
  financial_performance: FinancialPerformanceDashboard,
  bonds: BondsTable,
  centrepay: CentrepayTable,
  revenue: { useReportPage: useRevenueTable as () => ReportPageState }
};

export const VisualizationScreen = withScreenComponent(
  (): ReactElement | null => {
    const navigation = useNavigation<Screens.ReportVisulisation>();
    const route = useRoute<Screens.ReportVisulisation>();

    const BackButton = useReportsBreadCrumb();

    const navToReportList = useCallback((): void => {
      navigation.navigate(Screens.ReportList);
    }, [navigation]);

    const report = reports[route.params!.reportName];
    const Report = reportComponents[route.params!.reportName];

    if (!report || !Report) {
      navToReportList();
      return <></>;
    }

    const [availableFeatures] = useGlobal("availableFeatures");
    const hasOwnTeamFilter =
      typeof report.hasOwnTeamFilter === "string"
        ? availableFeatures.includes(PlatformFeatureId.ReportingModalFilter)
        : report.hasOwnTeamFilter;

    return (
      <PageContent>
        {isPageComponents(Report) ? (
          <PageComponentsView
            useReportPage={Report.useReportPage}
            BackButton={BackButton}
          />
        ) : (
          <>
            <HeaderRow>
              <View style={{ alignItems: "flex-start" }}>
                <BackButton />
              </View>
              {!hasOwnTeamFilter && <TeamsFilter />}
            </HeaderRow>
            <Report />
          </>
        )}
      </PageContent>
    );
  }
);

const PageComponentsView = ({
  useReportPage: useReportPage,
  BackButton
}: PageHook & { BackButton: () => ReactElement }): ReactElement => {
  const {
    reportPage: [Page, pageProps],
    topComponents: [TopComponents, componentProps]
  } = useReportPage();
  return (
    <>
      <HeaderRow>
        <View style={{ alignItems: "flex-start" }}>
          <BackButton />
        </View>
        <TopComponents {...componentProps} />
      </HeaderRow>
      <Page {...pageProps} />
    </>
  );
};

const HeaderRow = styled(View)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
`;
