import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { View } from "react-native";
import { DeleteIcon, SuccessSubtractedIcon, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import {
  Badge,
  Button,
  CellContainer,
  DropdownMenu,
  DropdownMenuOption,
  DropdownMenuOptionGroup,
  Grid,
  TextCell,
  TextCellContent,
  useToastContext
} from "@ailo/ui";
import { TabContent } from "../common";
import { ProviderIconCard } from "../common/ProviderIconCard";
import { ConnectFormsButton } from "./ConnectFormsButton";
import { useCreateReiToken } from "./useCreateReiToken";
import { useCurrentUser } from "@ailo/domains";
import { OptionalTooltip, useCurrentAgencyOrg } from "local/common";
import { getStateData } from "./utils";
import { ConfirmRemoveAccountModal } from "./ConfirmRemoveAccountModal";
import { MoreButton } from "./MoreButton";
import { useRemoveReiToken } from "./useRemoveReiToken";
import { useGetCurrentOrgReiTokensQuery } from "local/graphql";

export function FormList(): ReactElement {
  const [confirmModalVisible, setConfirmModalVisible] =
    useState<boolean>(false);
  const [errors, setErrors] = useState<string>();

  const {
    person: { ailoRN: personAilorn }
  } = useCurrentUser();
  const { ailoRN: organisationAilorn } = useCurrentAgencyOrg();

  const { data, loading } = useGetCurrentOrgReiTokensQuery({
    variables: {
      personAilorn,
      organisationAilorn
    }
  });

  const unknownError = "Something went wrong. Please try again";
  const [createNewReiToken, createdResult] = useCreateReiToken({
    onError: () => {
      setErrors(unknownError);
    }
  });

  const onFormCallbackError = useCallback(() => {
    return setErrors(unknownError);
  }, []);

  const toast = useToastContext();
  const [removeReiToken] = useRemoveReiToken({
    onCompleted: () => {
      toast.show({
        type: "success",
        message: "Forms account removed"
      });
    },
    onError: () => {
      toast.show({
        type: "error",
        message: unknownError
      });
    }
  });

  const reiTokensForOrg =
    data?.legalEntity?.__typename === "Person"
      ? data.legalEntity.reiTokensForOrg
      : undefined;

  const rows = useMemo(() => {
    return (
      reiTokensForOrg?.map((row) => {
        const stateData = getStateData(row.supportedState);
        return {
          key: row.id,
          account: {
            form_type: stateData.formType,
            email: row.email
          },
          state: stateData.state
        };
      }) ?? []
    );
  }, [reiTokensForOrg]);

  const [hasReiToken, setHasReiToken] = useState(false);
  useEffect(() => {
    setHasReiToken(!!reiTokensForOrg?.length);
  }, [reiTokensForOrg]);

  if (!data && loading) {
    return <FormList.Loading />;
  }

  const options: (DropdownMenuOption | DropdownMenuOptionGroup)[] = [
    {
      label: "Remove account",
      icon: <DeleteIcon />,
      onSelect: () => setConfirmModalVisible(true)
    }
  ];

  return (
    <>
      <Actions>
        <OptionalTooltip
          tooltipContent={hasReiToken ? "Already connected" : ""}
        >
          <ConnectFormsButton
            connectedEmail={createdResult.data?.createReiToken.email}
            onFormCallback={createNewReiToken}
            onFormCallbackError={onFormCallbackError}
            errors={errors}
            onError={setErrors}
            disabled={hasReiToken}
          >
            {"Connect account"}
          </ConnectFormsButton>
        </OptionalTooltip>
      </Actions>
      {rows.length > 0 ? (
        <View style={{ marginHorizontal: -TabContent.padding }}>
          <Grid
            scrollView={false}
            flat
            rows={rows}
            columns={[
              {
                ...columnsBase.account,
                renderCell({ row }) {
                  return (
                    <AccountColumn>
                      <ProviderIconCard
                        provider={row.account.form_type}
                        size={"medium"}
                      />
                      <AccountDetails>
                        <TextCellContent
                          weight={"medium"}
                          TextComponent={Text.BodyM}
                        >
                          {row.account.form_type}
                        </TextCellContent>
                        <TextCellContent>{row.account.email}</TextCellContent>
                      </AccountDetails>
                    </AccountColumn>
                  );
                }
              },
              {
                ...columnsBase.state,
                renderCell({ row }) {
                  return <TextCell>{row.state}</TextCell>;
                }
              },
              {
                ...columnsBase.status,
                renderCell() {
                  return (
                    <CellContainer>
                      <Badge
                        type={"success"}
                        leftComponent={
                          <SuccessSubtractedIcon
                            width={12}
                            height={12}
                            style={{ marginVertical: 2 }}
                          />
                        }
                        style={{ alignItems: "center", width: 93 }}
                      >
                        {"Connected"}
                      </Badge>
                    </CellContainer>
                  );
                }
              },
              {
                ...columnsBase.more,
                renderCell() {
                  return (
                    <CellContainer>
                      <DropdownMenu
                        options={options}
                        renderToggle={({ toggle, open }) => (
                          <MoreButton toggle={toggle} open={open} />
                        )}
                        itemSize={"small"}
                      />
                    </CellContainer>
                  );
                }
              },
              {
                ...columnsBase.hiddenModal,
                renderCell({ row }) {
                  return (
                    <ConfirmRemoveAccountModal
                      visible={confirmModalVisible}
                      onCancel={() => setConfirmModalVisible(false)}
                      onConfirm={() => {
                        removeReiToken({
                          id: row.key,
                          personAilorn,
                          organisationAilorn
                        });
                        setConfirmModalVisible(false);
                      }}
                    />
                  );
                }
              }
            ]}
            rowStyle={{ paddingHorizontal: 16 }}
            rowHeadStyle={{ paddingHorizontal: 16 }}
          />
        </View>
      ) : null}
    </>
  );
}

FormList.Loading = function Loading() {
  return (
    <>
      <Actions>
        <ConnectButton disabled>{"Connect account"}</ConnectButton>
      </Actions>
      <View style={{ marginHorizontal: -TabContent.padding }}>
        <Grid.Loading
          scrollView={false}
          flat
          rows={[
            { key: 1, opacity: 0.75 },
            { key: 2, opacity: 0.5 },
            { key: 3, opacity: 0.25 }
          ]}
          renderRowContainer={({ row, style, children }) => (
            <View style={[style, { opacity: row.opacity }]}>{children}</View>
          )}
          rowStyle={{ paddingHorizontal: 16 }}
          columns={[
            {
              ...columnsBase.account,
              renderCell() {
                return (
                  <AccountColumn>
                    <ProviderIconCard.Loading size={"medium"} />
                    <AccountDetails>
                      <Text.BodyM.Loading width={120} />
                      <TextCellContent.Loading />
                    </AccountDetails>
                  </AccountColumn>
                );
              }
            },
            {
              ...columnsBase.state,
              renderCell() {
                return <TextCell.AdjustableLoading style={{ width: 120 }} />;
              }
            },
            {
              ...columnsBase.status,
              renderCell() {
                return null;
              }
            },
            {
              ...columnsBase.more,
              renderCell() {
                return null;
              }
            }
          ]}
        />
      </View>
    </>
  );
};

const columnsBase = {
  account: {
    key: "account",
    name: "Account"
  },
  state: {
    key: "state",
    name: "State or territory",
    maxWidth: 160
  },
  status: {
    key: "status",
    maxWidth: 140
  },
  more: {
    key: "more",
    maxWidth: 64,
    horizontalAlign: "right" as const
  },
  hiddenModal: {
    key: "hiddenModal",
    width: 0
  }
};

const Actions = styled(View)`
  margin-bottom: 40px;
  align-items: flex-start;
`;

const AccountColumn = styled(CellContainer)`
  flex-direction: row;
`;

const AccountDetails = styled(View)`
  flex-direction: column;
  margin-left: 16px;
  flex-shrink: 1;
`;

const ConnectButton = styled(Button.Primary)`
  margin-top: 24px;
`;
