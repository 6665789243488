import { useMountedState } from "@ailo/primitives";
import { ErrorAlertScreen, ErrorModal, useToastContext } from "@ailo/ui";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { RentReviewPrefill } from "./RentReviewFormData";
import { useGetRentReviewData } from "./useGetRentReviewData";
import { merge } from "lodash";
import { RentReviewFormProvider } from "./RentReviewFormContext";
import { useFormContext } from "react-hook-form";
import { RentReviewFields } from "src/tabs/properties/components/PropertyScreen/components/RentReviewSidebar/components";
import { useSubmit } from "./useSubmit";
import { MultiStepForm } from "local/common";
import { CreateRentReviewStep, ReviewRentReviewStep } from "./Steps";
import { EditableRentFragment, RentFragment } from "local/graphql";
import { useAnalytics } from "@ailo/services";

type StepIds = "Create" | "Review";

type Step = {
  stepId: StepIds;
  render: (visible: boolean) => ReactElement;
};

export interface RentReviewFormProps {
  editableRent?: EditableRentFragment;
  tenancyId: string;
  onDismiss: () => void;
  onSuccess?: (createdOrEditedRent: RentFragment) => void;
  prefill?: RentReviewPrefill;
  onFormSubmitting: (isSubmitting: boolean) => void;
}

export function RentReviewForm(props: RentReviewFormProps): ReactElement {
  const { editableRent, tenancyId, prefill } = props;
  const { data, loading, refetch } = useGetRentReviewData({
    tenancyId,
    editableRent
  });

  if (loading && !data) {
    return <RentReviewMultistepForm.Loading />;
  }

  if (!data) {
    return <RentReviewMultistepForm.Error onRetry={refetch} />;
  }

  return (
    <RentReviewFormProvider
      prefill={merge({ tenancyId }, data.prefill, prefill)}
      data={data}
    >
      <RentReviewMultistepForm {...props} />
    </RentReviewFormProvider>
  );
}

export function RentReviewMultistepForm({
  onDismiss,
  onSuccess,
  onFormSubmitting
}: RentReviewFormProps): ReactElement {
  const Toast = useToastContext();
  const [currentStepNumber, setCurrentStepNumber] = useState<number>(1);
  const [showErrorModal, setShowErrorModal] = useMountedState(false);
  const { formState, trigger } = useFormContext<RentReviewFields>();
  const { isDirty, isSubmitting } = formState;
  const analytics = useAnalytics();

  useEffect(() => {
    onFormSubmitting(isSubmitting);
  }, [onFormSubmitting, isSubmitting]);

  const renderStepCreate = useCallback(
    (visible: boolean): ReactElement => (
      <CreateRentReviewStep display={visible} key={"CreateRentReviewStep"} />
    ),
    []
  );

  const renderStepReview = useCallback(
    (visible: boolean): ReactElement => (
      <ReviewRentReviewStep
        display={visible}
        key={"ReviewRentReviewStep"}
        setCurrentStepNumber={setCurrentStepNumber}
      />
    ),
    []
  );

  const steps: Step[] = useMemo(
    () => [
      {
        stepId: "Create",
        render: renderStepCreate
      },
      {
        stepId: "Review",
        render: renderStepReview
      }
    ],
    [renderStepCreate, renderStepReview]
  );

  const onNextButtonPress = useCallback(
    async (newStepNumber: number) => {
      if (newStepNumber < currentStepNumber) {
        setCurrentStepNumber(newStepNumber);
        return;
      }
      const currentStepId = steps[currentStepNumber - 1].stepId;

      if (currentStepId === "Create") {
        const isValid = await trigger();
        if (isValid) {
          setCurrentStepNumber(newStepNumber);
        }
      }
    },
    [currentStepNumber, steps, trigger]
  );

  const submitSuccess = useCallback(
    async (createdOrEditedRent: RentFragment) => {
      await onSuccess?.(createdOrEditedRent);
      Toast.show({
        type: "success",
        message: "Rent review completed"
      });
      analytics.track("Rent Review Completed");
    },
    [Toast, onSuccess, analytics]
  );

  const submit = useSubmit({
    onSuccess: submitSuccess,
    onError: () => {
      setShowErrorModal(true);
    }
  });
  return (
    <>
      <ErrorModal
        visible={showErrorModal}
        onDismiss={(): void => setShowErrorModal(false)}
      />
      <MultiStepForm
        style={multistepFormStyle}
        title={"Rent review"}
        onSubmit={submit}
        submitButtonText={"Save"}
        stepValue={currentStepNumber}
        onChange={onNextButtonPress}
        onCancel={onDismiss}
        isDirty={isDirty}
        steps={steps.map((step) => step.render)}
        headerBackButtonDisabled
        hideCancelButton
        showFooterBackButton
      />
    </>
  );
}

RentReviewMultistepForm.Loading = function Loading(): ReactElement {
  return (
    <MultiStepForm.Loading
      title={"Rent review"}
      loadingComponent={
        <CreateRentReviewStep.Loading key={"CreateRentReviewStep.Loading"} />
      }
      stepCount={2}
      style={multistepFormStyle}
    />
  );
};

const multistepFormStyle: StyleProp<ViewStyle> = {
  maxWidth: 680,
  width: "100%",
  flex: 1,
  alignSelf: "flex-end"
};

RentReviewMultistepForm.Error = function Error({
  onRetry
}: {
  onRetry: () => void;
}): ReactElement {
  return (
    <FormContainer>
      <ErrorAlertScreen
        variant={"sidebar"}
        title={"There was a problem loading\nRent Review"}
        onRetry={onRetry}
      />
    </FormContainer>
  );
};

const FormContainer = styled(View)`
  padding: 0 32px 60px;
  max-width: 680;
  width: 100%;
  flex: 1;
  align-self: flex-end;
`;
