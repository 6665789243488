import React from "react";
import { Colors } from "@ailo/primitives";
import { OccupancyStatus } from "local/graphql";
import { Badge, BadgeType, CellContainer, TextCellContent } from "@ailo/ui";

const OccupancyStatusDisplay: React.FC<{
  status: OccupancyStatus;
  statusExtraInfo: string;
  badgeType: BadgeType;
}> = ({ status, statusExtraInfo, badgeType }) => (
  <CellContainer style={{ justifyContent: "center" }}>
    <Badge type={badgeType} style={{ alignSelf: "flex-start", marginTop: 2 }}>
      {status}
    </Badge>
    {statusExtraInfo.length > 0 ? (
      <TextCellContent
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginTop: 2 }}
      >
        {statusExtraInfo}
      </TextCellContent>
    ) : null}
  </CellContainer>
);

export { OccupancyStatusDisplay };
