import React, { memo, ReactElement, useCallback } from "react";
import {
  useLockTrustAccountMutation,
  TrustAccountLockStatus,
  Maybe
} from "local/graphql";
import moment from "moment";
import { ReconciliationPeriod } from "../types";
import {
  LockUnlockBaseModal,
  ConfiguredModalProps
} from "./LockUnlockBaseModalComponent";

function LockModalComponent(props: ConfiguredModalProps): ReactElement {
  const lockedUpTo = lockUpToFromPeriod(props.period);
  const variables = {
    trustAccountAilorn: props.period.trustAccountAilorn,
    lockedUpTo
  };

  const [lockMutation, { loading, error, data }] = useLockTrustAccountMutation({
    variables
  });

  const isWaiting = loading || isUnconfirmed(data);

  const onButtonPress = useCallback(() => {
    lockMutation();
  }, [lockMutation]);

  const errorText = error
    ? "There was a problem locking this month. Please try again."
    : undefined;

  return (
    <LockUnlockBaseModal
      loading={isWaiting}
      errorText={errorText}
      actionText={"Lock"}
      onActionPress={onButtonPress}
      {...props}
    >
      {
        "Locking this month will block changes to be made to the cash book and bank statement balance. It is still possible to unlock the month if necessary."
      }
    </LockUnlockBaseModal>
  );
}

type LockData = Maybe<{
  lockTrustAccount?: Maybe<{ status?: TrustAccountLockStatus }>;
}>;

function isUnconfirmed(data?: LockData): boolean {
  return data?.lockTrustAccount?.status === TrustAccountLockStatus.Unconfirmed;
}

function lockUpToFromPeriod(
  period: Pick<ReconciliationPeriod, "year" | "month" | "timezone">
): string {
  return moment
    .tz({ year: period.year, month: period.month - 1 }, period.timezone)
    .add(1, "month")
    .subtract(1, "millisecond")
    .toISOString();
}

export const LockModal = memo(LockModalComponent);
