import { useCallback } from "react";
import { Screens, useNavigation, useRoute } from "local/common";
import { useTotalAmountToClaim } from "../useTotalAmountToClaim";
import { ClaimTenancyData } from "local/domain/bond";

export function useNavigateToSuccessScreen({
  data,
  onClose
}: {
  data: ClaimTenancyData;
  onClose: () => void;
}): () => void {
  const navigation = useNavigation();
  const amountClaimed = useTotalAmountToClaim();

  const { tenancyId, managementId } =
    useRoute<Screens.ClaimTenancyBond>().params!;

  return useCallback((): void => {
    navigation.navigate(Screens.AppNavigator, {
      screen: Screens.PropertiesTab,
      params: {
        screen: Screens.ClaimTenancyBondSuccess,
        params: {
          data,
          amountClaimed,
          onClose,
          tenancyId,
          managementId
        }
      }
    });
  }, [amountClaimed, data, navigation, onClose, managementId, tenancyId]);
}
