import { isPresent } from "ts-is-present";

export function getTenantList<T = unknown>(
  tenancyId: string,
  tenantships?: Array<{ tenant?: T | null } | null> | null
): T[] {
  if (!tenantships || tenantships.length === 0)
    throw new Error(`Cannot get tenantships for tenancy ${tenancyId}`);

  const tenants = tenantships.map((item) => item?.tenant).filter(isPresent);

  if (tenantships.length !== tenants.length)
    throw new Error(`Fail to get some tenant data in tenancy ${tenancyId}`);

  return tenants;
}
