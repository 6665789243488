import { ViewBondClaimProps } from "../ViewBondClaimProps";
import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { ScrollView } from "react-native";
import { BondClaimHeader } from "./BondClaimHeader";
import {
  useGetClaimTenancyBondDetails,
  calculateClaimSummary
} from "local/domain/bond";
import { useOnFocus } from "@ailo/services";
import { BondClaimDetails } from "./BondClaimDetails";
import { useNavigateToEditTenancyBondClaim } from "local/tabs";

export function ViewBondClaimSideBarContent({
  managementId,
  tenancyId,
  onClose
}: ViewBondClaimProps): ReactElement {
  const navigateToEditBondClaim = useNavigateToEditTenancyBondClaim({
    managementId,
    tenancyId
  });

  const { data, loading, refetch } = useGetClaimTenancyBondDetails({
    tenancyId,
    includePaidBills: true
  });

  useOnFocus(refetch);

  const claimSummary = data
    ? calculateClaimSummary({
        bond: data.bond,
        rent: data.rent,
        bills: data.bills
      })
    : undefined;

  return (
    <StyledContainer>
      <BondClaimHeader
        onEdit={navigateToEditBondClaim}
        data={data}
        requestedAmount={claimSummary?.amountRequested}
        loading={loading}
        onClose={onClose}
      />
      <BondClaimDetails
        data={data}
        claimSummary={claimSummary}
        loading={loading}
      />
    </StyledContainer>
  );
}

const StyledContainer = styled(ScrollView)`
  padding-bottom: 60px;
`;
