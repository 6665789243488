import React from "react";
import { AddKeyForm } from "../forms/AddKeyForm/AddKeyForm";
import { UpdateKeyForm } from "../forms/UpdateKeyForm";
import { UpsertKeyModal } from "./UpsertKeyModal";
import { PropertyKeyModalContextProvider } from "../PropertyKeyModalContext";
import { useKeyModalContext } from "../PropertyKeyModalContext/useKeyModalContext";
import { DeleteKeyModal } from "./DeleteKeyModal";

type Props = React.PropsWithChildren<{
  propertyAilornString: string;
  organisationAilornString: string;
  managementAilornString: string;
}>;

export function PropertyKeyModal({
  propertyAilornString,
  organisationAilornString,
  managementAilornString,
  children
}: Props): React.ReactElement {
  return (
    <PropertyKeyModalContextProvider
      propertyAilornString={propertyAilornString}
      organisationAilornString={organisationAilornString}
      managementAilornString={managementAilornString}
    >
      {children}
      <Modal />
    </PropertyKeyModalContextProvider>
  );
}

function Modal(): React.ReactElement | null {
  const modalCtx = useKeyModalContext();
  const { modalConfig, closeKeyModal } = modalCtx;

  if (!modalConfig) {
    return null;
  }

  const { title } = modalConfig;

  switch (modalConfig.mode) {
    case "delete":
      return (
        <DeleteKeyModal
          selectedKey={modalConfig.selectedKey}
          onDismiss={closeKeyModal}
          title={title}
          visible={true}
        />
      );

    case "edit":
      return (
        <UpsertKeyModal visible={true} title={title}>
          <UpdateKeyForm
            editKey={modalConfig.selectedKey}
            onDismiss={closeKeyModal}
          />
        </UpsertKeyModal>
      );
    case "create":
      return (
        <UpsertKeyModal visible={true} title={title}>
          <AddKeyForm onDismiss={closeKeyModal} />
        </UpsertKeyModal>
      );
    default:
      return null;
  }
}
