import React, { ReactElement } from "react";
import { useGetMigratingManagementSidebar } from "./hooks";
import { MigratingManagementSidebarContent } from "./MigratingManagementSidebarContent";
import { MigratingManagementSidebarError } from "./MigratingManagementSidebarError";

interface Props {
  migratingManagementId: string;
  closeSidebar: () => void;
}

export function MigratingManagementSidebarContentContainer({
  migratingManagementId,
  closeSidebar
}: Props): ReactElement {
  const { migratingManagement, loading, error, refetch } =
    useGetMigratingManagementSidebar({ migratingManagementId });

  if (loading && !migratingManagement) {
    return (
      <MigratingManagementSidebarContent.Loading closeSidebar={closeSidebar} />
    );
  }

  if (error || !migratingManagement) {
    return (
      <MigratingManagementSidebarError
        closeSidebar={closeSidebar}
        refetch={refetch}
      />
    );
  }

  return (
    <MigratingManagementSidebarContent
      migratingManagement={migratingManagement}
      closeSidebar={closeSidebar}
      refetch={refetch}
    />
  );
}
