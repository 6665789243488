import { AiloRN, services } from "@ailo/ailorn";
import { useHasFeature } from "@ailo/domains";
import { SidebarSections, SidebarContent } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import React, { useCallback } from "react";
import { hasPresentKey } from "ts-is-present";
import { getOwnershipData, getPropertyBadgeData } from "./getDisplayData";
import {
  PlatformFeatureId,
  useGetLegalEntityDetailsQuery
} from "local/graphql";
import { InvestorOwnershipsSection } from "./InvestorOwnershipsSection";
import { LegalEntitySidebarContentError } from "./LegalEntitySidebarContentError";
import { CompanyMembersSection } from "./CompanyMembersSection";
import { PropertySidebarProfileCard } from "./PropertySidebarProfileCard";
import { setVerifiedOnLegalEntity } from "local/domain/authz";
import { useAnalytics, useOnFocus } from "@ailo/services";
import { TenantMoneySettingsSection } from "./TenantMoneySettingsSection";

interface Props {
  onDismiss: () => void;
  legalEntityDetails: {
    id: string;
    entityType: "Person" | "Company";
  };
}

export function LegalEntitySidebarContent({
  onDismiss,
  legalEntityDetails
}: Props): React.ReactElement {
  const analytics = useAnalytics();
  const currentAgencyOrg = useCurrentAgencyOrg();

  const onFocus = useCallback((): void => {
    analytics.trackScreenVisited("Customer", {});
  }, [analytics]);
  useOnFocus(onFocus);

  const hasConsumerProfilePropertiesFeature = useHasFeature(
    PlatformFeatureId.ConsumerProfileProperties
  );
  const hasViewConsumersMoneySettingsFeature = useHasFeature(
    PlatformFeatureId.AgencyAppViewConsumersMoneySettings
  );

  const { data, loading, refetch } = useGetLegalEntityDetailsQuery({
    variables: {
      ailoRN: AiloRN.of(
        services.AuthZ.legalEntity,
        legalEntityDetails.id
      ).toString(),
      organisationAiloRN: currentAgencyOrg.ailoRN
    }
  });

  if (loading && !data) {
    return (
      <Loading
        onDismiss={onDismiss}
        entityType={legalEntityDetails.entityType}
      />
    );
  }

  if (!data || !data.legalEntity)
    return (
      <LegalEntitySidebarContentError
        onRetry={(): void => {
          refetch();
        }}
        onClose={onDismiss}
      />
    );

  const ownerships = getOwnershipData(data.legalEntity.ownerships?.items);

  const investorProperties = getPropertyBadgeData(
    data.legalEntity.managements?.items
  );

  const managementsOfTenancies = data.legalEntity.tenancies?.items
    .filter(hasPresentKey("management"))
    .map((tenancy) => tenancy.management);
  const tenantProperties = getPropertyBadgeData(managementsOfTenancies);

  const showOwnershipsSection =
    ownerships.length > 0 && hasConsumerProfilePropertiesFeature;

  return (
    <SidebarContent
      closeButton
      onClose={onDismiss}
      titleStyle={{ marginBottom: 0 }}
    >
      <SidebarSections>
        <PropertySidebarProfileCard
          legalEntity={data.legalEntity}
          tenantProperties={tenantProperties}
          investorProperties={investorProperties}
        />
        {data.legalEntity.__typename === "Company" &&
          data.legalEntity.organisation.members && (
            <CompanyMembersSection
              members={data.legalEntity.organisation.members?.items.map(
                setVerifiedOnLegalEntity
              )}
            />
          )}
        {hasViewConsumersMoneySettingsFeature &&
          !!data.legalEntity.tenancies?.items.length && (
            <TenantMoneySettingsSection data={data} />
          )}
        {showOwnershipsSection && (
          <InvestorOwnershipsSection ownerships={ownerships} />
        )}
      </SidebarSections>
    </SidebarContent>
  );
}

function Loading({
  onDismiss,
  entityType
}: {
  onDismiss: () => void;
  entityType: "Person" | "Company";
}): React.ReactElement {
  return (
    <SidebarContent
      closeButton
      onClose={onDismiss}
      titleStyle={{ marginBottom: 0 }}
    >
      <SidebarSections>
        <PropertySidebarProfileCard.Loading entityType={entityType} />
      </SidebarSections>
    </SidebarContent>
  );
}
