import React from "react";
import { ActionDetailsFragment } from "local/graphql";
import { BaseActionCard } from "../BaseActionCard";
import { ActionCardFooter } from "../shared/ActionCardFooter";
import { View } from "react-native";

export type GenericActionCardProps = {
  projectId: string;
  action: ActionDetailsFragment;
  children?: React.ReactNode;
  hasTitleSeparator?: boolean;
};

export function GenericActionCard({
  projectId,
  action,
  children,
  hasTitleSeparator = false
}: GenericActionCardProps): React.ReactElement {
  return (
    <BaseActionCard
      action={action}
      projectId={projectId}
      hasTitleSeparator={hasTitleSeparator}
    >
      {children && <View style={{ marginRight: 8 }}>{children}</View>}
      <ActionCardFooter action={action} />
    </BaseActionCard>
  );
}
