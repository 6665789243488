import { Money, TextCell } from "@ailo/ui";
import React, { ReactElement } from "react";
import {
  ColumnCfg,
  ColumnDisplayType,
  GridRow,
  ReportCellValue,
  ReportTableRow
} from "..";

interface TextCellProps<TRow> {
  columnKey: string;
  reportTableRow: GridRow<TRow>;
  alignItems: string;
  cfg?: ColumnCfg<TRow>;
}

export const cellFormattedValue = <TRow,>(
  cfg: ColumnCfg<TRow> | undefined,
  value: ReportCellValue | null | undefined,
  nullFormattedValue: string = ""
): string => {
  const format = displayFormat[cfg?.type ?? "default"];
  return value == null ? nullFormattedValue : format(value);
};

const displayFormat: Record<ColumnDisplayType, (s: ReportCellValue) => string> =
  {
    currency: (v) =>
      typeof v == "number" ? Money.fromDollars(v).format() : String(v),
    pct: (v) => (typeof v == "number" ? `${(v * 100).toFixed(1)}%` : String(v)),
    default: (v) => String(v),
    count: (v) => String(v)
  };

export function ReportTextCell<TRow extends ReportTableRow>({
  columnKey,
  reportTableRow,
  cfg
}: TextCellProps<TRow>): ReactElement {
  const value = cfg?.formatValue
    ? cfg.formatValue(reportTableRow)
    : reportTableRow[columnKey];

  const formattedValue = cellFormattedValue(
    cfg,
    value,
    reportTableRow.rowType == "total" ? "" : "-"
  );

  return <TextCell>{formattedValue}</TextCell>;
}
