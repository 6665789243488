import {
  AutoPayIcon,
  Colors,
  DeleteIcon,
  EditIcon,
  Text
} from "@ailo/primitives";
import { Button, DropdownMenu, ListItem, ListItemStyle } from "@ailo/ui";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { isPresent } from "ts-is-present";
import { AmountAndTax } from "./ManualManagementFeeBlueprintsList/AmountAndTax";
import { GetManagementFeeBlueprintsQuery } from "local/graphql";
import { upperFirst } from "lodash";

interface ManagementFeeBlueprintsListItemProps {
  edit?: () => void;
  remove?: () => void;
  managementFeeBlueprint: NonNullable<
    GetManagementFeeBlueprintsQuery["managementFeeBlueprints"]
  >["items"][number];
  style?: ListItemStyle;
}

export function ManagementFeeBlueprintsListItem({
  managementFeeBlueprint,
  edit,
  remove,
  style
}: ManagementFeeBlueprintsListItemProps): ReactElement | null {
  const editBlueprint = edit && {
    label: "Edit",
    icon: <EditIcon />,
    onSelect: edit
  };

  const removeBlueprint = remove && {
    label: "Remove",
    icon: <DeleteIcon />,
    onSelect: remove
  };

  const options = [editBlueprint, removeBlueprint].filter(isPresent);

  return (
    <ListItem
      testID={undefined}
      header={managementFeeBlueprint.feeBlueprint.name}
      subHeader={
        managementFeeBlueprint.feeBlueprint.event?.shortDescription ? (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <AutoPayIcon
              width={16}
              height={16}
              color={Colors.TEXT.DARK.SECONDARY}
            />
            <Text.BodyS
              color={Colors.TEXT.DARK.SECONDARY}
              style={{ marginLeft: 6 }}
            >
              {upperFirst(
                managementFeeBlueprint.feeBlueprint.event.shortDescription
              )}
            </Text.BodyS>
          </View>
        ) : undefined
      }
      rightComponent={
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <AmountAndTax managementFeeBlueprint={managementFeeBlueprint} />
          {!!options.length && (
            <DropdownMenu
              options={options}
              renderToggle={({ toggle, open }) => (
                <Button.More
                  variant={"small"}
                  onPress={toggle}
                  active={open}
                  style={{ marginLeft: 12 }}
                />
              )}
            />
          )}
        </View>
      }
      style={{
        verticalAlignCenter: "top",
        verticalAlignRight: "top",
        paddingRight: 32,
        ...style
      }}
    />
  );
}

ManagementFeeBlueprintsListItem.Loading = function Loading({
  color,
  style
}: {
  color?: string;
  style?: ListItemStyle;
}) {
  return (
    <ListItem
      style={{
        verticalAlignCenter: "top",
        verticalAlignRight: "top",
        ...style
      }}
      header={<Text.BodyM.Loading width={120} color={color} />}
      subHeader={<Text.BodyS.Loading width={80} color={color} />}
    />
  );
};
