import { useHasFeature } from "@ailo/domains";
import { useGetRecurringFeeOptions } from "local/domain/expense";
import { PlatformFeatureId } from "local/graphql";
import React from "react";
import { AddButton } from "../AddButton";

export function AutomaticFeeBlueprintFooter({
  onAddAutomaticFeePress,
  automaticFeeBlueprintsQuery
}: {
  onAddAutomaticFeePress: () => void;
  automaticFeeBlueprintsQuery: Pick<
    ReturnType<typeof useGetRecurringFeeOptions>,
    "loading" | "error" | "refetch"
  >;
}): React.ReactElement {
  const canManageEventBasedFees = useHasFeature(
    PlatformFeatureId.EventBasedFee
  );

  const addRecurringFeeButton = automaticFeeBlueprintsQuery.loading ? (
    <AddButton.Loading />
  ) : automaticFeeBlueprintsQuery.error ? (
    <AddButton.Error
      message={"There was a problem loading agency automatic fee templates"}
      onRetry={() => void automaticFeeBlueprintsQuery.refetch}
    />
  ) : (
    <AddButton
      label={`Add a recurring${
        canManageEventBasedFees ? " or event-based " : " "
      }fee`}
      onPress={onAddAutomaticFeePress}
    />
  );

  return <>{addRecurringFeeButton}</>;
}
