import React, { ReactElement } from "react";
import { Colors, DefaultProps, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { BalanceCardContainer } from "../ReconciliationViewScreen/BalanceCard/components";
import { useLatestReportPeriodData } from "./useLatestReportPeriodData";
import {
  BadgeLoading,
  BalancedBadge,
  UnBalancedBadge
} from "../ReconciliationViewScreen/Badge";
import { monthNumberToName } from "../utils";
import { View } from "react-native";
import { Money } from "@ailo/money";
import { ReconciliationTextButton } from "../components";
import { Button, ErrorCard } from "@ailo/ui";
import { EmptyCard } from "./EmptyCard";

export function ReportPeriodCard({ style }: DefaultProps): ReactElement | null {
  const data = useLatestReportPeriodData();

  if (data.loading) {
    return <ReportPeriodCardLoading style={style} />;
  }

  if (!data.loading && data.error) {
    return (
      <ErrorCard
        style={style}
        message={"There's a problem loading current reconciliation"}
        onReload={data.refetch}
      />
    );
  }

  if (data.reportPeriod == undefined) {
    return (
      <EmptyCard
        style={style}
        message={"Current reconciliation"}
        secondaryMessage={"You have no current reconciliation"}
      />
    );
  }

  const period = data.reportPeriod;

  const monthName = monthNumberToName(period.month);
  const periodDisplayName = `${monthName} ${period.year}`;

  const { amount, date } = period.bankBalance ?? { amount: Money.zero() };
  const description = date
    ? `Bank statement balance as at ${date.format("DD MMMM YYYY")}`
    : "No bank statement balance provided";

  const Container = period?.isReconciling ? ContainerSuccess : ContainerWarning;

  return (
    <Container style={style}>
      <TopHalf>
        <TitleContainer>
          <Text.DisplayM>{`${periodDisplayName} (Current)`}</Text.DisplayM>
          <ReconciliationTextButton year={period.year} month={period.month} />
        </TitleContainer>
        {data?.reportPeriod.isReconciling ? (
          <BalancedBadge />
        ) : (
          <UnBalancedBadge />
        )}
      </TopHalf>
      <BottomHalf>
        <DescriptionText>{description}</DescriptionText>
        <Text.DisplayL>{amount.format()}</Text.DisplayL>
      </BottomHalf>
    </Container>
  );
}

function ReportPeriodCardLoading({ style }: DefaultProps): ReactElement | null {
  return (
    <BaseContainer style={style}>
      <TopHalf>
        <TitleContainer>
          <Text.DisplayM.Loading width={240} />
          <Button.Loading type={"text"} />
        </TitleContainer>
        <BadgeLoading width={200} />
      </TopHalf>
      <BottomHalf>
        <DescriptionTextLoading width={300} />
        <Text.DisplayL.Loading width={160} />
      </BottomHalf>
    </BaseContainer>
  );
}

const DescriptionText = styled(Text.BodyS).attrs({
  color: Colors.TEXT.DARK.SECONDARY
})`
  padding-bottom: 8px;
`;

const DescriptionTextLoading = DescriptionText.withComponent(
  Text.BodyS.Loading
);

const TitleContainer = styled(View)`
  flex-direction: row;
  margin-bottom: 8px;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
`;

const BaseContainer = styled(BalanceCardContainer)`
  border-bottom-width: 4px;
  padding: 16px 0 12px 16px;
  flex-direction: column;
  border-bottom-color: ${Colors.WHITE};
`;

const ContainerSuccess = styled(BaseContainer)`
  border-bottom-color: ${Colors.STATUS.SUCCESS};
`;

const ContainerWarning = styled(BaseContainer)`
  border-bottom-color: ${Colors.STATUS.WARNING};
`;

const TopHalf = styled(View)`
  align-items: flex-start;
  padding-bottom: 16px;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.GRAYSCALE.OUTLINE};
`;

const BottomHalf = styled(View)`
  padding-top: 12px;
`;
