import React, { ReactElement, useCallback } from "react";
import { ActionType, ProjectType, ManagementFragment } from "local/graphql";
import { RefinedActionActivityProps } from "../ActionActivityProps";
import { ErrorAlertScreen, useGlobalModal } from "@ailo/ui";
import { isProjectOfTypeWithMeta } from "local/domain/project/project";
import { AiloSentry } from "@ailo/services";
import { useCompleteAssignNewManagementAction } from "./useCompleteAssignNewManagementAction";
import { DisplayManagementDetails } from "./DisplayManagementDetails";
import { CreateManagementView } from "./CreateManagementView";

export function AssignNewManagementActionActivity({
  action
}: RefinedActionActivityProps<ActionType.AssignNewManagement>): ReactElement {
  const modal = useGlobalModal();
  const { completeAssignNewManagementAction } =
    useCompleteAssignNewManagementAction();

  const onCreateSuccess = useCallback(
    async (management: ManagementFragment) => {
      await completeAssignNewManagementAction({
        management,
        actionId: action.id
      });
      modal.hide();
    },
    [completeAssignNewManagementAction, action.id, modal]
  );

  const project = action.project;

  if (!isProjectOfTypeWithMeta(project, ProjectType.NewManagement)) {
    AiloSentry.captureException(
      new Error(`Project is not of ${ProjectType.NewManagement} type`)
    );
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the action information."}
      />
    );
  }
  const managementId = action.meta.management?.id;

  if (managementId) {
    return <DisplayManagementDetails managementId={managementId} />;
  }

  return <CreateManagementView onCreateSuccess={onCreateSuccess} />;
}
