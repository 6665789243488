import {
  useGetDataForEndTenancyQuery,
  VacatingReason,
  TenancyDepositStatus,
  PropertyIdAndAddressFragment
} from "local/graphql";
import { ApolloError } from "@apollo/client";
import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { MAX_DATE, MIN_DATE, DateRange } from "@ailo/primitives";
import moment from "moment";
import { AgreementDateType, getTenancyAgreementDateInfo } from "@ailo/domains";

export interface EndTenancyData {
  liabilityId: string;
  effectivePaidToDate?: moment.Moment;
  tenancyAgreementDateInfo?: string;
  nextTenancyDateInfo?: string;
  tenancyEndDate?: moment.Moment;
  vacatingNotes?: string | null;
  vacatingReason?: VacatingReason | null;
  validEndDateRange: DateRange;
  showDepositFundsWarning: boolean;
  property: PropertyIdAndAddressFragment;
}

export function useGetDataForEndTenancy(
  tenancyId: string
): MappedQueryResult<EndTenancyData> {
  const result = useGetDataForEndTenancyQuery({
    variables: { id: tenancyId }
  });

  return useMappedQueryResult(result, (data) => {
    const tenancy = data?.tenancy;

    if (!tenancy) {
      throw new ApolloError({
        errorMessage: `Tenancy ID="${tenancyId}" not found`
      });
    }

    const {
      liability,
      endDate,
      mostRecentTenancyAgreement,
      vacatingNotes,
      vacatingReason,
      validVacateEndDateRange,
      deposit,
      management,
      property
    } = tenancy;

    if (!liability) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} does not have liability`
      });
    }

    if (!validVacateEndDateRange) {
      throw new ApolloError({
        errorMessage: `Tenancy ${tenancyId} does not have valid end date range`
      });
    }

    return {
      liabilityId: liability.id,
      effectivePaidToDate: getMomentOrUndefined(liability.effectivePaidToDate),
      tenancyAgreementDateInfo: getTenancyAgreementDateInfoText(
        mostRecentTenancyAgreement
      ),
      nextTenancyDateInfo: getNextTenancyDateInfoText(
        management?.nextTenancy?.startDate
      ),
      tenancyEndDate: getMomentOrUndefined(endDate),
      vacatingNotes,
      vacatingReason,
      validEndDateRange: {
        startDate:
          getMomentOrUndefined(validVacateEndDateRange?.startDate) ?? MIN_DATE,
        endDate:
          getMomentOrUndefined(management?.nextTenancy?.startDate)?.subtract(
            1,
            "day"
          ) ?? MAX_DATE
      },
      showDepositFundsWarning: deposit?.status
        ? [TenancyDepositStatus.Pending, TenancyDepositStatus.Paid].includes(
            deposit.status
          )
        : false,
      property
    };
  });
}

function getMomentOrUndefined(
  date?: Date | string | null
): moment.Moment | undefined {
  return date ? moment(date) : undefined;
}

function getTenancyAgreementDateInfoText(
  mostRecentTenancyAgreement?: {
    startDate?: string | null;
    fixedTermEndDate?: string | null;
    allowedToLapseAt?: string | null;
  } | null
): string | undefined {
  if (!mostRecentTenancyAgreement?.startDate) return;

  const { dateType, date } = getTenancyAgreementDateInfo({
    agreementStartDate: mostRecentTenancyAgreement.startDate,
    fixedTermAgreementEndDate: mostRecentTenancyAgreement.fixedTermEndDate,
    agreementAllowedToLapse: !!mostRecentTenancyAgreement.allowedToLapseAt
  });

  return (
    (dateType === AgreementDateType.PeriodicAgreementSince
      ? "Periodic agreement since "
      : dateType === AgreementDateType.PeriodicSince
      ? "Periodic since "
      : dateType === AgreementDateType.AgreementExpired
      ? "Tenancy agreement expired "
      : "Tenancy agreement expires ") + date
  );
}

function getNextTenancyDateInfoText(
  nextTenancyStartDate: string | null | undefined
): any {
  const date = getMomentOrUndefined(nextTenancyStartDate);

  if (!date) return undefined;

  return `A new tenancy starts ${date.format("DD MMM YYYY")}`;
}
