import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { useCallback } from "react";
import { Action, useActionAnalytics } from "local/domain/project";
import {
  ReassignActionMutation,
  useReassignActionMutation
} from "local/graphql";

export function useReassignAction({
  projectId,
  onCompleted,
  onError
}: {
  projectId: string;
  onCompleted?: ({ action }: { action: Action }) => void;
  onError: () => void;
}): [
  (id: string, assigneeAilorn: string) => void,
  MutationResult<ReassignActionMutation>
] {
  const { onActionReassign } = useActionAnalytics(projectId);
  const [reassignActionMutation, result] = useReassignActionMutation();

  const reassignAction = useCallback(
    async (id: string, assigneeAilorn: string): Promise<void> => {
      let result: ExecutionResult<ReassignActionMutation>;

      try {
        result = await reassignActionMutation({
          variables: { id, assigneeAilorn }
        });
        throwIfMutationFailed(result, { dataKey: "reassignAction" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }

      const reassignedAction = result.data!.reassignAction!;

      onActionReassign(reassignedAction);
      onCompleted?.({ action: reassignedAction });
    },
    [onActionReassign, reassignActionMutation, onCompleted, onError]
  );

  return [reassignAction, result];
}
