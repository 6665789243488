import React, { ReactElement } from "react";
import { FileListItem } from "@ailo/ui";
import {
  FileDetails,
  FilesState,
  FilesStateFile,
  fragmentToFileDetails,
  NewFile
} from "@ailo/domains";
import { useGetNewTenancyFileQuery } from "local/graphql";

interface Props {
  file: FilesStateFile;
  filesState?: FilesState;
  isLastItem: boolean;
  onPress: (file: FileDetails) => void;
}

export function NewTenancyFileListItem({
  file,
  filesState,
  isLastItem = false,
  onPress
}: Props): ReactElement {
  return file.id ? (
    <OpenableFile
      file={{ ...file, id: file.id }}
      isLastItem={isLastItem}
      onPress={onPress}
    />
  ) : (
    <NewFile
      file={file}
      marginBottom={isLastItem ? 0 : 12}
      onFileRemove={filesState?.removeFile}
      onFileUploadRetry={filesState?.retryFile}
    />
  );
}

interface OpenableFileProps {
  file: Omit<FilesStateFile, "id"> & { id: string };
  isLastItem: boolean;
  onPress: (file: FileDetails) => void;
}

function OpenableFile({
  file,
  isLastItem = false,
  onPress
}: OpenableFileProps): ReactElement {
  const { data, loading } = useGetNewTenancyFileQuery({
    variables: { id: file.id }
  });

  const fileDetails = fragmentToFileDetails(data?.file);

  if (loading || !fileDetails) {
    return (
      <NewFile file={{ ...file, loading }} marginBottom={isLastItem ? 0 : 12} />
    );
  }

  return (
    <FileListItem
      fileName={fileDetails.fileName}
      fileSize={fileDetails.fileSize || undefined}
      fileUrl={fileDetails.url || undefined}
      onPress={() => onPress(fileDetails)}
      style={{
        borderType: "around",
        borderRadius: 8,
        marginBottom: isLastItem ? 0 : 12
      }}
    />
  );
}
