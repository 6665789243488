import { Screens, useNavigation, useRoute } from "local/common";
import { useCallback } from "react";
import {
  ChatTeamId,
  useGetValidSelectedTeam,
  UseGetValidSelectedTeamParams
} from "@ailo/domains";

type Params = Pick<
  UseGetValidSelectedTeamParams,
  "teams" | "currentUserContactAilorn"
>;

export function useGetCommsTabSelectedTeam(
  params: Params
): ReturnType<typeof useGetValidSelectedTeam> {
  const navigation = useNavigation<Screens.CommsTab>();
  const { teamId } = useRoute<Screens.CommsTab>().params! || {};
  const onNavigationTeamIdParamChanged = useCallback(
    (newTeamId: ChatTeamId) => {
      navigation.setParams({ teamId: newTeamId });
    },
    [navigation]
  );

  return useGetValidSelectedTeam({
    ...params,
    navigationTeamIdParam: teamId,
    onNavigationTeamIdParamChanged
  });
}
