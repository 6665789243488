import { Text } from "@ailo/primitives";

import { DisbursementPeriodFilter } from "../../components/DisbursementPeriod";
import { DateRangeFilter } from "../../components/DateRangeFilter";
import { IncomeByPropertyData } from "./IncomeByPropertyData";
import React, { useState } from "react";
import { View } from "react-native";
import { LocalDate } from "@ailo/date";

export function IncomeByPropertyTable(): React.ReactElement {
  const [disbursementPeriodSelection, setDisbursementPeriodSelection] =
    useState<{
      value: string;
      label: string;
    }>();
  const [dateRangeSelection, setDateRangeSelection] = useState<{
    startDate?: string;
    endDate?: string;
  }>({
    startDate: LocalDate.today().setDayOfMonth(1).toString(),
    endDate: LocalDate.today().toString()
  });
  function onDisbursementPeriodChange(selection?: {
    value: string;
    label: string;
  }): void {
    setDisbursementPeriodSelection(selection);
    // clear the date range
    setDateRangeSelection({});
  }
  function onDateRangeChange({
    startDate,
    endDate
  }: {
    startDate?: string;
    endDate?: string;
  }): void {
    setDateRangeSelection({
      startDate,
      endDate
    });
    // clear the disbursement period
    setDisbursementPeriodSelection(undefined);
  }
  const incomeByPropertyDataKey = [
    "incomeByProperty",
    "disbursementDateSk",
    disbursementPeriodSelection?.value,
    "start",
    dateRangeSelection?.startDate,
    "end",
    dateRangeSelection?.endDate
  ].join("_");

  const FilterComponents = (): React.ReactElement => {
    return (
      <View
        style={{
          alignItems: "flex-end",
          justifyContent: "flex-start",
          flexDirection: "row",
          paddingBottom: "32px",
          flexWrap: "wrap"
        }}
      >
        <DateRangeFilter
          dateRangeSelection={dateRangeSelection}
          onChange={onDateRangeChange}
        />
        <View
          style={{
            alignItems: "center",
            marginBottom: "12px",
            marginTop: "12px",
            paddingLeft: "50px",
            paddingRight: "50px"
          }}
        >
          <Text.BodyM>{"or"}</Text.BodyM>
        </View>
        <DisbursementPeriodFilter
          selectedDisbursementPeriod={disbursementPeriodSelection}
          onChange={onDisbursementPeriodChange}
        />
      </View>
    );
  };
  return (
    <IncomeByPropertyData
      key={incomeByPropertyDataKey}
      disbursementPeriodSk={disbursementPeriodSelection?.value}
      disbursementPeriodLabel={disbursementPeriodSelection?.label}
      startDate={dateRangeSelection?.startDate}
      endDate={dateRangeSelection?.endDate}
      filterComponent={<FilterComponents />}
    />
  );
}
