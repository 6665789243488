import React, { FC } from "react";
import {
  OccupancyStatusLogic,
  OccupancyStatusErrorBoundary
} from "./components";
import { BasePropertyListRow } from "../../BasePropertyListRow";

interface Props {
  row: BasePropertyListRow;
}

interface Statics {
  Loading: typeof OccupancyStatusLogic.Loading;
}

const OccupancyStatusCell: FC<Props> & Statics = ({
  row: { managementId }
}) => (
  <OccupancyStatusErrorBoundary>
    <OccupancyStatusLogic managementId={managementId} />
  </OccupancyStatusErrorBoundary>
);

OccupancyStatusCell.Loading = OccupancyStatusLogic.Loading;

export { OccupancyStatusCell };
