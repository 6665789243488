import { ConfirmModal } from "@ailo/ui";
import React from "react";
import { Colors, Text, LargeInfoIcon } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";

export function FormerTenancyConfirmCrnSetupModal({
  visible,
  onDismiss,
  onConfirm
}: {
  visible: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}): React.ReactElement {
  return (
    <ConfirmModal
      onConfirm={onConfirm}
      onCancel={onDismiss}
      showFooterBorder={true}
      confirmLabel={"Continue"}
      cancelLabel={"Cancel"}
      visible={visible}
      headerAndContentContainerStyle={{
        paddingVertical: 60
      }}
    >
      <Container>
        <LargeInfoIcon />
        <Text.BodyL
          color={Colors.TEXT.DARK.PRIMARY}
          style={{ marginTop: 16, textAlign: "center" }}
        >
          {"Editing a CRN on a former tenancy"}
        </Text.BodyL>
        <Text.BodyM
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ marginTop: 8, textAlign: "center" }}
        >
          {
            "By editing a CRN on a former tenancy, payments received from Centrepay will pay any outstanding rent. If there is no outstanding rent, funds will be transferred to your trust account"
          }
        </Text.BodyM>
      </Container>
    </ConfirmModal>
  );
}

const Container = styled(View)`
  justify-content: center;
  align-items: center;
`;
