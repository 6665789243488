import { validateBpayCrn } from "@ailo/domains";
import { SFC } from "@ailo/primitives";
import { useClearPreviousDataOnVariablesChange } from "@ailo/services";
import { Alert, TextInputFormField, useFormFieldWarning } from "@ailo/ui";
import React, { useEffect, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { View } from "react-native";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import { useGetPaymentReferenceForPayerAndSupplierQuery } from "local/graphql";

const name = "payment.customerReference";

const CustomerReferenceInput: SFC<{ shouldRender?: boolean }> = ({
  style,
  shouldRender
}) => {
  const {
    control,
    errors,
    setValue,
    register,
    unregister,
    watch,
    getValues,
    formState
  } = useFormContext<AddBillFormData & { [name]?: string }>();

  const { dirtyFields } = formState;

  const { warning, triggerWarning } = useFormFieldWarning<
    AddBillFormData,
    typeof name,
    string | undefined
  >({
    name,
    validate(value) {
      if (value && /^[0-9]{2,20}$/.test(value) && !validateBpayCrn(value)) {
        return "This customer reference looks incorrect. Please check you’ve entered it exactly as it appears on the bill. If it is correct, please ignore this warning.";
      }
      return undefined;
    }
  });

  const payerId = watch("payerId")?.value;
  const payeeId = watch("supplierReference")?.value;
  const getCrn = useCallback(
    (): string | undefined => getValues(name),
    [getValues]
  );
  const prevPaymentReference = useClearPreviousDataOnVariablesChange(
    useGetPaymentReferenceForPayerAndSupplierQuery,
    !payerId || !payeeId
      ? { skip: true }
      : {
          variables: { payerId, payeeId },
          fetchPolicy: "cache-and-network" as const,
          nextFetchPolicy: "cache-first" as const
        }
  );

  const defaultValue =
    prevPaymentReference.data?.paymentReferences?.items[0]?.crn ?? "";

  useEffect(() => {
    if (!dirtyFields.payment?.customerReference || !getCrn()) {
      setValue(name, defaultValue);
      delete dirtyFields.payment?.customerReference;
    }
  }, [defaultValue, setValue, dirtyFields, getCrn]);

  useEffect(() => {
    register({ name: "payment.customerReferenceWarningCurrentlyShown" });
    register({ name: "payment.customerReferenceWarningEverShown" });
    return (): void => {
      unregister("payment.customerReferenceWarningCurrentlyShown");
      unregister("payment.customerReferenceWarningEverShown");
    };
  }, [register, unregister]);

  const hasWarning = !!warning;
  useEffect(() => {
    setValue("payment.customerReferenceWarningCurrentlyShown", hasWarning);
    if (hasWarning) {
      setValue("payment.customerReferenceWarningEverShown", true);
    }
  }, [setValue, hasWarning]);

  return (
    <View style={shouldRender ? style : undefined}>
      <Controller
        {...{ name, control, defaultValue }}
        render={({ onChange, onBlur, value, ref }): React.ReactElement => (
          <>
            {shouldRender && (
              <TextInputFormField
                {...{ value, onChangeText: onChange, inputRef: ref }}
                testID={"CustomerReferenceInputFormField"}
                digitsOnly
                onBlur={(): void => {
                  onBlur();
                  triggerWarning();
                }}
                label={"Customer Reference"}
                placeholder={"Customer Reference"}
                autoCorrect={false}
                autoCompleteType={"off"}
                warning={!!warning}
                error={errors.payment?.customerReference?.message}
                softCharacterLimit={{
                  limit: 20,
                  enforcementLevel: "error"
                }}
              />
            )}
          </>
        )}
        rules={{
          required: shouldRender && "Enter customer reference",
          pattern: shouldRender
            ? {
                value: /^[0-9]{2,20}$/,
                message: "Customer reference must contain 2 to 20 digits"
              }
            : undefined
        }}
      />
      {shouldRender && warning && (
        <Alert type={"warning"} style={{ marginTop: 12 }}>
          {warning}
        </Alert>
      )}
    </View>
  );
};

export { CustomerReferenceInput };
