import { BaseModal, Button, PropertySummaryCard, Separator } from "@ailo/ui";
import React, { useEffect, useMemo } from "react";
import { AiloRN } from "@ailo/ailorn";
import { TenantCentrepayInfo } from "../TenantCentrepayInfo";
import { useForm } from "react-hook-form";
import { CrnInput } from "./CrnInput";
import { RenterSelectionInput } from "./RenterSelectionInput";
import { Address } from "@ailo/domains";
import { formatAddress } from "@ailo/domain-helpers";
import { useSubmitCentrepaySetupForm } from "./useSubmitCentrepaySetupForm";
import { Row } from "local/common";

export function CentrepaySetupModal({
  managingEntity,
  tenants,
  tenancy,
  property,
  visible,
  onSuccess,
  onFailure,
  onDismiss,
  title,
  buttonLabel,
  createCentrepayDirective
}: {
  managingEntity: AiloRN;
  tenants: TenantCentrepayInfo[];
  tenancy: AiloRN;
  property?: {
    address?: Address;
  };
  visible: boolean;
  onSuccess: () => void;
  onFailure: () => void;
  onDismiss: () => void;
  title: string;
  buttonLabel: string;
  createCentrepayDirective: boolean;
}): React.ReactElement {
  const singleTenant = tenants.length === 1 ? tenants[0] : undefined;
  const defaultValues = useMemo(() => {
    return {
      crn: singleTenant?.crn || "",
      renter: singleTenant?.legalEntity.toString() || ""
    };
  }, [singleTenant]);
  const form = useForm<AddCentrepayerFormData>({
    defaultValues,
    mode: "onBlur"
  });
  const { reset, setError } = form;
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);
  const submit = useSubmitCentrepaySetupForm({
    managingEntity,
    tenancy,
    onSuccess: async () => {
      await onSuccess();
      form.reset();
    },
    onFailure,
    createCentrepayDirective,
    setError
  });

  return (
    <BaseModal
      title={title}
      showFooterBorder={false}
      visible={visible}
      headerAndContentContainerStyle={{ padding: 20 }}
    >
      {property?.address && (
        <PropertySummaryCard
          address={formatAddress(property.address, {
            format: "street, suburb, state, postcode"
          })}
          tenantName={singleTenant?.name}
        />
      )}

      <RenterSelectionInput
        form={form}
        tenants={tenants}
        renderTenantSelection={!singleTenant}
      />
      <CrnInput form={form} />

      <Separator style={{ marginLeft: -20, marginRight: -20 }} />
      <Row style={{ marginTop: 16 }}>
        <Button onPress={onDismiss} variant={"secondary"}>
          {"Cancel"}
        </Button>

        <Button onPress={form.handleSubmit(submit)}>{buttonLabel}</Button>
      </Row>
    </BaseModal>
  );
}

export interface AddCentrepayerFormData {
  crn?: string;
  renter?: string;
}
