import { useCurrentAgencyOrg } from "local/common";
import {
  GetMigrationDetailsDocument,
  StartMigrationsMutation,
  useStartMigrationsMutation
} from "local/graphql";
import { MutationResult } from "@apollo/client";
import { useCallback } from "react";
import { AiloRN, services } from "@ailo/ailorn";
import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";

interface StartMigrationsProps {
  onSuccess?: (result: StartMigrationsMutation) => void;
  onError?: () => void;
}

type StartMigrations = [() => void, MutationResult<StartMigrationsMutation>];

export function useStartMigrations({
  onSuccess,
  onError
}: StartMigrationsProps | undefined = {}): StartMigrations {
  const analytics = useAnalytics();
  const { id: organisationId } = useCurrentAgencyOrg();

  const [startMigrationsMutation, mutationResult] = useStartMigrationsMutation({
    variables: {
      organisationId: AiloRN.of(
        services.AuthZ.organisation,
        organisationId
      ).toString()
    },
    refetchQueries: [
      {
        query: GetMigrationDetailsDocument,
        variables: {
          organisationId
        }
      }
    ]
  });

  const startMigrations = useCallback(async () => {
    try {
      analytics.track("Migration Data Refresh Initiated");

      const result = await startMigrationsMutation();
      throwIfMutationFailed(result, { dataKey: "startMigrations" });

      if (!result.data) {
        if (onError) onError();
        return;
      }

      if (onSuccess) onSuccess(result.data);
    } catch (error) {
      AiloSentry.captureException(error);
      if (onError) onError();
    }
  }, [startMigrationsMutation, onSuccess, onError, analytics]);

  return [startMigrations, mutationResult];
}
