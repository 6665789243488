import { useContext, createContext } from "react";

export interface ActionStatusContextValue {
  handleActionStatusChange: () => void;
}
export const ActionStatusContext = createContext<ActionStatusContextValue>({
  handleActionStatusChange() {
    throw new TypeError(
      "ActionStatusContext is not implemented. Have you forgotten to mount ActionStatusContextProvider?"
    );
  }
});

export function useActionStatusContext(): ActionStatusContextValue {
  return useContext(ActionStatusContext);
}
