import { Screens, useNavigation } from "local/common";
import { useCallback } from "react";

/**
 * To be used only when inside Screens.NewChatMessage
 */
export function useSelectFoundChat(): (chatId: string | undefined) => void {
  const navigation = useNavigation<Screens.NewChatMessage>();

  return useCallback(
    (chatId: string | undefined) => {
      navigation.setParams({ chatId });
    },
    [navigation]
  );
}
