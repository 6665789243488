import { Colors, Link, Text as AiloText } from "@ailo/primitives";
import React, {
  ReactElement,
  useRef,
  useState,
  useEffect,
  useCallback
} from "react";

import { StyleProp, Text, TextStyle, View } from "react-native";

const DEFAULT_READ_MORE_TEXT = "Read more";

export function ReadMore({
  numberOfLines,
  note,
  readMoreText = DEFAULT_READ_MORE_TEXT,
  readLessText
}: {
  numberOfLines: number;
  note: string;
  readMoreText?: string;
  readLessText?: string;
}): ReactElement {
  const fullTextRef = useRef<Text>(null);
  const trimmedTextRef = useRef<Text>(null);

  const [fullTextHeight, setFullTextHeight] = useState<number>(-1);
  const [trimmedTextHeight, setTrimmedTextHeight] = useState<number>(-1);
  const [shouldClampText, setShouldClampText] = useState(false);

  const doMeasure = useCallback(() => {
    fullTextRef.current?.measure((x, y, width, height) => {
      setFullTextHeight(height);
    });
    trimmedTextRef.current?.measure((x, y, width, height) => {
      setTrimmedTextHeight(height);
    });
  }, [fullTextRef, trimmedTextRef]);

  useEffect(() => {
    doMeasure();
    requestAnimationFrame(() => {
      setShouldClampText(fullTextHeight > trimmedTextHeight);
    });
  }, [trimmedTextHeight, fullTextHeight, note, doMeasure]);

  const isTextHeightDifferent = fullTextHeight !== trimmedTextHeight;

  return (
    <View
      style={{
        position: "relative",
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16
      }}
    >
      <Text
        ref={trimmedTextRef}
        numberOfLines={numberOfLines}
        style={[
          {
            position: shouldClampText ? "relative" : "absolute",
            opacity: shouldClampText ? 1 : 0
          },
          !shouldClampText ? { zIndex: -999 } : {}
        ]}
      >
        <AiloText.BodyS weight={"book"}>{note}</AiloText.BodyS>
        {isTextHeightDifferent && (
          <ToggleReadMoreOrLessButton
            type={"more"}
            style={{
              position: "absolute",
              bottom: "2px",
              right: 0,
              paddingLeft: "5px"
            }}
            text={readMoreText}
            callback={setShouldClampText}
          />
        )}
      </Text>
      <Text
        ref={fullTextRef}
        style={[
          {
            position: shouldClampText ? "absolute" : "relative",
            opacity: shouldClampText ? 0 : 1
          },
          shouldClampText ? { zIndex: -999 } : {}
        ]}
      >
        <AiloText.BodyS weight={"book"}>{note}</AiloText.BodyS>
      </Text>
      {!shouldClampText && isTextHeightDifferent && (
        <ToggleReadMoreOrLessButton
          type={"less"}
          style={{ alignSelf: "flex-end" }}
          text={readLessText}
          callback={setShouldClampText}
        />
      )}
    </View>
  );
}

function ToggleReadMoreOrLessButton({
  type,
  text,
  callback,
  style
}: {
  type: "more" | "less";
  text?: string;
  style?: StyleProp<TextStyle>;
  callback: (b: boolean) => void;
}): React.ReactElement {
  return text ? (
    <Link
      variant={"primary"}
      style={[style || {}, { backgroundColor: Colors.WHITE }]}
      onPress={() => {
        callback(type !== "more");
      }}
    >
      {text}
    </Link>
  ) : (
    <></>
  );
}
