import React, { useCallback } from "react";
import { EditIcon } from "@ailo/primitives";
import { DropdownMenuOption, useGlobalModal } from "@ailo/ui";
import { ManagementEndDetailsFragment, PlatformFeatureId } from "local/graphql";
import { useHasFeature } from "@ailo/domains";
import { EndManagementModalForm } from "local/modals/EndManagementModalForm";
import { isFormerManagement } from "../../utils";

export function useEditManagementEndOption(
  managementEndDetails: ManagementEndDetailsFragment
): DropdownMenuOption | null {
  const hasEditManagementEndFeature = useHasFeature(
    PlatformFeatureId.EditManagementEnd
  );

  const { id, endDate } = managementEndDetails;

  const globalModal = useGlobalModal();
  const openEditManagementEndModal = useCallback(() => {
    globalModal.show(<EndManagementModalForm managementId={id} />);
  }, [globalModal, id]);

  if (!endDate || !hasEditManagementEndFeature) return null;

  const label = isFormerManagement(endDate)
    ? "Edit ended management"
    : "Edit ending management";

  return {
    label: label,
    icon: <EditIcon />,
    onSelect: openEditManagementEndModal
  };
}
