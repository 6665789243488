import { AiloRN, nsEntities } from "@ailo/ailorn";
import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import {
  CreateProjectMutation,
  GetManagementProjectsDocument,
  ProjectType,
  useCreateProjectMutation
} from "local/graphql";
import { useCallback } from "react";
import { ProjectFormData } from "../ProjectForm/ProjectFormData";

interface ProjectDetails {
  managementId?: string;
  organisationId: string;
  name: string;
  description?: string | null;
  dueDate: string;
  type: ProjectType;
  fileAilorns: string[];
  meta?: Record<string, unknown>;
  assigneeAilorn: string;
}

interface Project {
  id: string;
  type: ProjectType;
}

export function useCreateProject({
  onCompleted,
  onError
}: {
  onCompleted: ({
    project,
    formValues
  }: {
    project: Project;
    formValues: ProjectFormData;
  }) => void;
  onError: () => void;
}): [
  (details: ProjectDetails, formValues: ProjectFormData) => void,
  MutationResult<CreateProjectMutation>
] {
  const [createProjectMutation, result] = useCreateProjectMutation();

  const createProject = useCallback(
    async (
      {
        managementId,
        organisationId,
        meta,
        ...otherProjectDetails
      }: ProjectDetails,
      formValues: ProjectFormData
    ): Promise<void> => {
      const mutationInput = {
        ...otherProjectDetails,
        organisationAilorn: AiloRN.of(
          nsEntities.authz.organisation,
          organisationId
        ).toString(),
        meta: {
          managementAilorn: managementId
            ? AiloRN.of(
                nsEntities.propertymanagement.management,
                managementId
              ).toString()
            : undefined,
          ...meta
        }
      };

      let result: ExecutionResult<CreateProjectMutation>;

      try {
        result = await createProjectMutation({
          variables: { input: mutationInput },
          refetchQueries: [
            {
              query: GetManagementProjectsDocument,
              variables: { managementId }
            }
          ]
        });
        throwIfMutationFailed(result, { dataKey: "createProject" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }

      onCompleted({ project: result.data!.createProject!, formValues });
    },
    [createProjectMutation, onCompleted, onError]
  );

  return [createProject, result];
}
