import { Money } from "@ailo/ui";
import React, { ReactElement } from "react";
import { LinearGradient } from "expo-linear-gradient";
import { Colors, Text } from "@ailo/primitives";
import { ClaimStatusBadge } from "./ClaimStatusBadge";

interface Props {
  amountClaimed: Money;
}

export function ClaimStatus({ amountClaimed }: Props): ReactElement {
  return (
    <LinearGradient
      colors={["rgba(110, 143, 203, 0)", "rgba(110, 143, 203, 0.1)"]}
      start={[0, 0]}
      end={[1, 0]}
      style={{ width: "100%" }}
    >
      <Text.DisplayM style={{ marginTop: 32 }}>
        {"Claim recorded in Ailo"}
      </Text.DisplayM>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY} style={{ marginTop: 40 }}>
        {"Requested by agency"}
      </Text.BodyM>
      <Text.DisplayL color={Colors.TEXT.DARK.PRIMARY} weight={"medium"}>
        {amountClaimed.format()}
      </Text.DisplayL>
      <ClaimStatusBadge amountClaimed={amountClaimed} />
    </LinearGradient>
  );
}
