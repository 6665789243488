import { GridSortOrder } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import {
  MigratingManagementSortField,
  SortDirection,
  useGetPagedLiveMigratingManagementsLazyQuery
} from "local/graphql";
import { useCallback } from "react";
import { useGetPropertyOnboardingListQueryReturn } from "./useGetPropertyOnboardingListQueryReturn";
import { PROPERTY_ONBOARDING_LIST_PAGE_SIZE } from "./usePaginationRequest";
import { isPresent } from "ts-is-present";
import {
  KeyedLiveMigratingManagementFragment,
  LiveMigratingManagementExtraVariables,
  PropertyOnboardingListQueryData,
  PropertyOnboardingListRequestParams
} from "../types";
import { LivePropertyOnboardingListColumnNames } from "../Live/hooks";

export type LivePropertyOnboardingListQuery = PropertyOnboardingListQueryData<
  KeyedLiveMigratingManagementFragment,
  LiveMigratingManagementExtraVariables
>;

export function useLivePropertyOnboardingListQuery({
  initialSortOrder
}: {
  initialSortOrder: GridSortOrder[];
}): LivePropertyOnboardingListQuery {
  const currentOrg = useCurrentAgencyOrg();

  const [request, { data, loading, called, refetch }] =
    useGetPagedLiveMigratingManagementsLazyQuery({
      fetchPolicy: "cache-and-network"
    });

  const sendRequest = useCallback(
    ({
      requestParams
    }: {
      requestParams?: PropertyOnboardingListRequestParams;
    }) => {
      const {
        before = false,
        cursor = null,
        sort = gridSortStateToApiParam(initialSortOrder)
      } = requestParams ?? {};

      request({
        variables: {
          cursor,
          before,
          pageSize: PROPERTY_ONBOARDING_LIST_PAGE_SIZE,
          organisationId: currentOrg.ailoRN,
          sort
        }
      });
    },
    [initialSortOrder, request, currentOrg.ailoRN]
  );

  const pageInfo = data?.migratingManagements?.pageInfo;
  const migratingManagements =
    data?.migratingManagements?.items.filter(isPresent);

  return useGetPropertyOnboardingListQueryReturn({
    sendRequest,
    pageInfo,
    migratingManagements,
    loading: loading && !data,
    called,
    gridSortStateToApiParam,
    refetch
  });
}

const gridSortStateToApiParam = (
  gridSortOrders: GridSortOrder[]
): {
  field: MigratingManagementSortField;
  direction: SortDirection;
}[] => {
  return gridSortOrders.map(({ direction, columnKey }) => {
    const directionForApi =
      direction === "DESC" ? SortDirection.Desc : SortDirection.Asc;

    return { field: getApiSortField(columnKey), direction: directionForApi };
  });
};

function getApiSortField(columnKey: string): MigratingManagementSortField {
  if (columnKey === LivePropertyOnboardingListColumnNames.Address)
    return MigratingManagementSortField.Address;

  if (columnKey === LivePropertyOnboardingListColumnNames.LiveOn)
    return MigratingManagementSortField.StatusChangedAt;

  throw new Error(
    `Cannot sort awaiting approval property list by column ${columnKey}`
  );
}
