import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { Card } from "@ailo/ui";
import { ProjectIcon, Colors, opacify } from "@ailo/primitives";

export function ProjectIconCard(): ReactElement {
  return (
    <IconContainer
      style={{
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <ProjectIcon
        color={opacify(Colors.SPACE_BLACK, 0.6)}
        width={20}
        height={20}
      />
    </IconContainer>
  );
}

const IconContainer = styled(Card)`
  width: 40px;
  height: 40px;
  margin: 0;
  margin-right: 16px;
`;
