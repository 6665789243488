import { useCallback } from "react";
import { startCase } from "lodash";
import { useAnalytics } from "@ailo/services";
import { AiloRN, services } from "@ailo/ailorn";
import { ActionDetailsFragment } from "local/graphql";

interface UseActionAnalytics {
  onViewAction: (actionId: string) => void;
  onActionReassign: (action: ActionDetailsFragment) => void;
  onActionStatusChange: (action: ActionDetailsFragment) => void;
  onActionDueDateChange: (
    action: ActionDetailsFragment,
    previousDueDate: string
  ) => void;
}

export function useActionAnalytics(projectId: string): UseActionAnalytics {
  const analytics = useAnalytics();

  const onViewAction = useCallback(
    (actionId: string) => {
      analytics.trackScreenVisited("Project Action", {
        project_id: AiloRN.of(services.Project.project, projectId).toString(),
        action_id: AiloRN.of(services.Project.action, actionId).toString()
      });
    },
    [analytics, projectId]
  );

  const onActionReassign = useCallback(
    (action: ActionDetailsFragment) => {
      analytics.track("Project Action Reassigned", {
        project_id: AiloRN.of(services.Project.project, projectId).toString(),
        action_id: action.id,
        action_created_at: action.createdAt,
        action_type: action.type ? startCase(action.type) : undefined,
        assignee_id: action.assignee
          ? AiloRN.of(services.AuthZ.legalEntity, action.assignee.id).toString()
          : undefined
      });
    },
    [analytics, projectId]
  );

  const onActionStatusChange = useCallback(
    (action: ActionDetailsFragment) => {
      analytics.track("Project Action Status Changed", {
        project_id: AiloRN.of(services.Project.project, projectId).toString(),
        action_id: action.id,
        status: action.status,
        action_created_at: action.createdAt,
        action_type: action.type ? startCase(action.type) : undefined,
        assignee_id: action.assignee
          ? AiloRN.of(services.AuthZ.legalEntity, action.assignee.id).toString()
          : undefined
      });
    },
    [analytics, projectId]
  );

  const onActionDueDateChange = useCallback(
    (action: ActionDetailsFragment, previousDueDate: string) => {
      analytics.track("Project Action Due Date Updated", {
        project_id: AiloRN.of(services.Project.project, projectId).toString(),
        action_id: action.id,
        action_created_at: action.createdAt,
        action_type: action.type ? startCase(action.type) : undefined,
        from_date: action.dueDate,
        to_date: previousDueDate
      });
    },
    [analytics, projectId]
  );

  return {
    onViewAction,
    onActionReassign,
    onActionStatusChange,
    onActionDueDateChange
  };
}
