import React from "react";
import {
  DocusignSignatureTrackingList,
  getReiFormError
} from "local/domain/project";
import { useGetReiFormSigningStatus } from "local/domain/project/useGetReiFormSigningStatus";
import { ActionType, ReiFormErrorCode, SigningStatus } from "local/graphql";
import { RefinedActionActivityProps } from "local/tabs/projects/actions/ActionSidebar/ActionActivity/ActionActivityProps";
import { GenericActionCard } from "../GenericActionCard";
import { View } from "react-native";

export function ViewWithReiToken({
  action,
  projectId,
  formId
}: RefinedActionActivityProps<ActionType.TrackDocusign> & {
  formId: string;
}): React.ReactElement {
  const { data, error } = useGetReiFormSigningStatus(formId);

  const reiFormNotFoundError = getReiFormError(
    ReiFormErrorCode.ReiFormNotFound,
    error
  );

  const formAvailable =
    data?.status !== SigningStatus.NotAvailable && !reiFormNotFoundError;

  return (
    <GenericActionCard
      projectId={projectId}
      action={action}
      hasTitleSeparator={formAvailable && !!data?.recipients.length}
    >
      {formAvailable && !!data?.recipients.length && (
        <View style={{ marginBottom: 12 }}>
          <DocusignSignatureTrackingList
            tenants={data.recipients}
            listItemSpacing={8}
          />
        </View>
      )}
    </GenericActionCard>
  );
}
