import React from "react";
import { ActionType } from "local/graphql";
import { RefinedActionActivityProps } from "local/tabs/projects/actions";
import { Badge, LocalDate } from "@ailo/ui";
import { BaseActionCard } from "../BaseActionCard";
import { ActionCardContent } from "../shared/ActionCardContent";
import { ActionCardFooter } from "../shared/ActionCardFooter";
import { Colors } from "@ailo/primitives";

export type AssignTenancyActionCardProps =
  RefinedActionActivityProps<ActionType.AssignTenancy>;

export function AssignTenancyActionCard({
  projectId,
  action
}: AssignTenancyActionCardProps): React.ReactElement {
  const voidedAt = action.meta.tenancy?.voidedAt;
  const voidedAtLocalDate = voidedAt
    ? new LocalDate(voidedAt).format("D MMMM YYYY")
    : undefined;
  return (
    <BaseActionCard action={action} projectId={projectId}>
      {voidedAtLocalDate && (
        <ActionCardContent
          description={`Tenancy voided ${voidedAtLocalDate}`}
          style={{ color: Colors.TEXT.DARK.SECONDARY, marginBottom: 20 }}
        />
      )}
      <ActionCardFooter
        action={action}
        additionalStatus={
          voidedAtLocalDate && (
            <Badge type={"critical"} style={{ marginLeft: 4 }}>
              {"Voided"}
            </Badge>
          )
        }
      />
    </BaseActionCard>
  );
}
