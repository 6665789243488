import { useCurrentUser } from "@ailo/domains";
import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import {
  PersonNamesFragment,
  useGetMembersByOrganisationQuery
} from "local/graphql";
import { isPresent } from "ts-is-present";

export function useCurrentOrgMembers(
  organisationId: string
): MappedQueryResult<{
  currentUserMember: PersonNamesFragment | undefined;
  nonCurrentUserMembers: PersonNamesFragment[];
  allMembers: PersonNamesFragment[];
}> {
  const result = useGetMembersByOrganisationQuery({
    variables: {
      organisationId
    }
  });

  const { person: currentPerson } = useCurrentUser();

  return useMappedQueryResult(result, (data) => {
    const members = data?.organisation?.members?.items;

    if (!Array.isArray(members)) {
      throw new ApolloError({
        errorMessage: `Could not fetch members for organisation ID: ${organisationId}`
      });
    }

    const validMembers = members.filter(isPresent);

    const currentUserMember = validMembers.find(
      (member) => member.ailoRN === currentPerson.ailoRN
    );

    const nonCurrentUserMembers = members.filter(
      (member) => member.ailoRN !== currentUserMember?.ailoRN
    );

    return {
      currentUserMember,
      nonCurrentUserMembers,
      allMembers: validMembers
    };
  });
}
