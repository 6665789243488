import { Screens, useNavigation } from "local/common";
import { useCallback } from "react";
import { ChatTeamId } from "@ailo/domains";

/**
 * To be used only when inside Screens.CommsTab
 */
export function useSelectTeam(): (teamId: ChatTeamId) => void {
  const navigation = useNavigation<Screens.CommsTab>();

  return useCallback(
    (teamId: ChatTeamId) => {
      navigation.setParams({ teamId });
    },
    [navigation]
  );
}
