import { Text } from "@ailo/primitives";
import { staticEnvironment } from "@ailo/services";
import React, { useEffect } from "react";

export function ReiFormsCallback(): React.ReactElement {
  useEffect(() => {
    const opener: Window | null = window.opener;
    if (!opener) return;
    const origin =
      staticEnvironment.releaseChannel === "dev" ? "*" : window.location.origin;
    opener.postMessage(window.location.hash, origin);
  }, []);

  return <Text.BodyL>{"Processing..."}</Text.BodyL>;
}
