import { Column, Row } from "@ailo/primitives";
import styled from "styled-components/native";

export const HeaderRow = styled(Row)`
  margin: 20px 60px 42px;
`;

export const LeftColumn = styled(Column)`
  flex: 1;
  align-items: flex-start;
`;
export const RightColumn = styled(Column)`
  flex: 1;
  align-items: flex-end;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;
