import React, { ReactElement } from "react";
import styled from "styled-components/native";
import {
  Colors,
  opacify,
  SuccessSubtractedIcon,
  WarningSubtractedIcon
} from "@ailo/primitives";
import { Money } from "@ailo/money";
import { DescriptionText } from "./components";
import { View } from "react-native";
import { BalanceHeaderRow } from "./BalanceRow";

type BalanceTotalRowVariantProps = {
  amount?: Money | ReactElement;
  children: string;
  icon?: ReactElement;
};

export function BalanceTotalRowSuccess({
  children,
  ...rest
}: BalanceTotalRowVariantProps): ReactElement {
  return (
    <BalanceTotalRowSuccessContainer {...rest}>
      <BalanceTotalRowVariantChildrenContainer>
        <SuccessSubtractedIcon width={20} height={20} />
        <BalanceTotalRowVariantDescription>
          {children}
        </BalanceTotalRowVariantDescription>
      </BalanceTotalRowVariantChildrenContainer>
    </BalanceTotalRowSuccessContainer>
  );
}

export function BalanceTotalRowWarning({
  children,
  ...rest
}: BalanceTotalRowVariantProps): ReactElement {
  return (
    <BalanceTotalRowWarningContainer {...rest}>
      <BalanceTotalRowVariantChildrenContainer>
        <WarningSubtractedIcon width={20} height={20} />
        <BalanceTotalRowVariantDescription>
          {children}
        </BalanceTotalRowVariantDescription>
      </BalanceTotalRowVariantChildrenContainer>
    </BalanceTotalRowWarningContainer>
  );
}

const BalanceTotalRowVariantChildrenContainer = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const BalanceTotalRowVariantDescription = styled(DescriptionText).attrs({
  weight: "medium"
})`
  margin-left: 8px;
`;

export const BalanceTotalRowSuccessContainer = styled(BalanceHeaderRow)`
  background-color: ${opacify(Colors.STATUS.SUCCESS, 0.1)};
  border-bottom-width: 4px;
  border-bottom-color: ${Colors.STATUS.SUCCESS};
`;

export const BalanceTotalRowWarningContainer = styled(BalanceHeaderRow)`
  background-color: ${opacify(Colors.STATUS.WARNING, 0.1)};
  border-bottom-width: 4px;
  border-bottom-color: ${Colors.STATUS.WARNING};
`;
