import React, { ReactElement, useMemo } from "react";
import { GridColumnOptions, GridRenderRowContainerProps } from "@ailo/ui";
import { View } from "react-native";
import {
  KeyedMigratingManagement,
  PropertyOnboardingListColumn
} from "../types";

const LOADING_ROWS = [
  { key: "1" },
  { key: "2" },
  { key: "3" },
  { key: "4" },
  { key: "5" },
  { key: "6" }
];

function LoadingRowContainer({
  style,
  rowIndex,
  children
}: GridRenderRowContainerProps<{ key: string }>): React.ReactElement {
  const opacity = Math.max(0, 1 - rowIndex / 3);
  return (
    <View style={[style, { borderBottomWidth: 0, opacity }]}>{children}</View>
  );
}

interface Params<TMigratingManagement extends KeyedMigratingManagement> {
  columns: PropertyOnboardingListColumn<TMigratingManagement>[];
}

export interface PropertyOnboardingListLoadingContent {
  loadingRows: { key: string }[];
  renderLoadingRowContainer: (
    props: GridRenderRowContainerProps<{ key: string }>
  ) => ReactElement;
  loadingColumns: GridColumnOptions<{ key: string }>[];
}

export function usePropertyOnboardingListLoadingContent<
  TMigratingManagement extends KeyedMigratingManagement
>({
  columns
}: Params<TMigratingManagement>): PropertyOnboardingListLoadingContent {
  const loadingColumns = useMemo(
    () =>
      columns.map((col) => ({
        ...col,
        renderCell: col.renderLoadingCell
      })),
    [columns]
  );

  return {
    loadingRows: LOADING_ROWS,
    renderLoadingRowContainer: LoadingRowContainer,
    loadingColumns
  };
}
