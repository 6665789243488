import {
  DisbursementListView,
  Screens,
  SwitcherHeaderOption,
  TabNavigation,
  useNavigation
} from "local/common";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";

interface Props {
  tabs: (SwitcherHeaderOption | DisbursementListView)[];
  selectedTabIndex: number;
}

export function DisbursementsListTabs({
  tabs,
  selectedTabIndex
}: Props): React.ReactElement {
  const navigation = useNavigation<Screens.DisbursementsList>();
  return (
    <Container>
      <StyledTabNavigation
        options={tabs}
        selected={selectedTabIndex}
        navigation={navigation}
      />
    </Container>
  );
}

const Container = styled(View)`
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
`;

const StyledTabNavigation = styled(TabNavigation)`
  flex: 1;
`;
