import React, { ReactElement, useCallback } from "react";
import { Button, useToastContext } from "@ailo/ui";
import { useIncludeMigratingManagement } from "../../hooks";
import { IncludeMigratingManagementMutation } from "local/graphql";

interface Props {
  migratingManagementId: string;
  closeSidebar: () => void;
}

export function ExcludedMigratingManagementFooter({
  migratingManagementId,
  closeSidebar
}: Props): ReactElement {
  const toast = useToastContext();

  const onError = useCallback(() => {
    toast.show({
      type: "error",
      message: "Unable to mark property as included. Please try again"
    });
  }, [toast]);

  const onSuccess = useCallback(
    (data: IncludeMigratingManagementMutation) => {
      const successes = data.includeMigratingManagements?.successes ?? [];
      if (successes.length === 0) {
        toast.show({
          type: "error",
          message: "Unable to mark property as included. Please try again"
        });
        return;
      }

      closeSidebar();
      toast.show({
        type: "success",
        message: "Property marked as included"
      });
    },
    [toast, closeSidebar]
  );

  const [includeMigratingManagement, { loading }] =
    useIncludeMigratingManagement({
      migratingManagementId,
      onSuccess,
      onError
    });

  return (
    <Button.Primary
      onPress={() => includeMigratingManagement()}
      loading={loading}
    >
      {"Mark as Included"}
    </Button.Primary>
  );
}
