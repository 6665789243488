import React, { FC } from "react";
import { LedgerDetails } from "./components";
import { LedgerDetailsErrorBoundary } from "local/domain/ledger";

interface Props {
  managementId: string;
  onLedgerDetailEntryPress: (liabilityEntryId: string) => void;
  onMoneyManagementSettingsPress: () => void;
}

export const LedgerSidebarContent: FC<Props> = ({
  managementId,
  onLedgerDetailEntryPress,
  onMoneyManagementSettingsPress
}) => {
  return (
    <LedgerDetailsErrorBoundary sectionName={"Tenancy &amp; Management"}>
      <LedgerDetails
        managementId={managementId}
        onLedgerDetailEntryPress={onLedgerDetailEntryPress}
        onMoneyManagementSettingsPress={onMoneyManagementSettingsPress}
      />
    </LedgerDetailsErrorBoundary>
  );
};
