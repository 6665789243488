import React, { ReactElement } from "react";
import { DefaultDisbursementsList } from "local/tabs/trustaccounting";

function SupplierDisbursementsList({
  tabNavigation
}: {
  tabNavigation: ReactElement;
}): ReactElement {
  return (
    <DefaultDisbursementsList
      category={"supplier"}
      tabNavigation={tabNavigation}
    />
  );
}

export { SupplierDisbursementsList };
