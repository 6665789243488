import { ErrorBoundaryRenderFallbackProps } from "@ailo/services";
import { ErrorAlertScreen } from "@ailo/ui";
import React, { ReactElement } from "react";

export function ProjectsListSidebarErrorBoundaryFallback(
  onClose?: () => void
): (props: ErrorBoundaryRenderFallbackProps) => ReactElement {
  return function Fallback({
    retry
  }: ErrorBoundaryRenderFallbackProps): ReactElement {
    return (
      <ErrorAlertScreen
        variant={"sidebar"}
        title={"There was a problem loading projects"}
        onRetry={retry}
        onClose={onClose}
      />
    );
  };
}
