import { Colors } from "@ailo/primitives";
import React from "react";
import { Pressable, View } from "react-native";
import styled from "styled-components/native";

const Overlay = styled(View)`
  flex: 1;
  height: 100%;
  background-color: ${Colors.WHITE};
  opacity: 0.5;
`;

const StyledPressable = styled(Pressable)`
  flex: 1;
  cursor: ${(props): string => (props.onPress ? "pointer" : "default")};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

export function ChatScreenOverlay({
  onPress
}: {
  onPress?: () => void;
}): React.ReactElement {
  return (
    <StyledPressable onPress={onPress}>
      <Overlay />
    </StyledPressable>
  );
}
