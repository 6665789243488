import { GridSortOrder } from "@ailo/ui";
import React from "react";
import {
  AddressCellWithNoPaymentWarning,
  BalanceOwedFromOwingCell,
  RentCell,
  PaidToDateFromOwingCell,
  OverdueDateFromOwingCell
} from "./PropertyItemCell";
import { usePropertyListReorder, useOverduePropertyListQuery } from "./hooks";
import { ComeBackSoonMessage } from "./ComeBackSoonMessage";
import { lowerCase, startCase } from "lodash";
import { BasePropertyList, BasePropertyListProps } from "./BasePropertyList";
import { createCellRenderer } from "./createCellRenderer";

export function OverduePropertyListFromOwing({
  tabNavigation
}: {
  tabNavigation: React.ReactElement;
}): React.ReactElement {
  const queryData = useOverduePropertyListQuery(initialSortOrder);
  const { sortOrder, onSortOrderChange } = usePropertyListReorder(
    initialSortOrder,
    queryData.requestFromBeginning,
    analyticsInfo
  );

  return (
    <BasePropertyList
      tabNavigation={tabNavigation}
      queryData={queryData}
      errorMessage={"There was a problem loading Overdue"}
      emptyMessage={"No overdue properties to show"}
      secondaryEmptyMessage={<ComeBackSoonMessage />}
      sortOrder={sortOrder}
      columns={columns}
      onSortOrderChange={onSortOrderChange}
    />
  );
}

export enum OverduePropertyListFromOwingColumns {
  Properties = "Properties",
  Rent = "Rent",
  BalanceOwed = "Balance owed",
  DueDate = "Due date",
  PaidTo = "Paid to"
}

const columns: BasePropertyListProps["columns"] = [
  {
    width: 3,
    renderCell: createCellRenderer(AddressCellWithNoPaymentWarning),
    renderLoadingCell: AddressCellWithNoPaymentWarning.Loading,
    name: OverduePropertyListFromOwingColumns.Properties,
    sortable: true
  },
  {
    width: 1,
    renderCell: createCellRenderer(RentCell),
    renderLoadingCell: RentCell.Loading,
    name: OverduePropertyListFromOwingColumns.Rent,
    sortable: false,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(BalanceOwedFromOwingCell),
    renderLoadingCell: BalanceOwedFromOwingCell.Loading,
    name: OverduePropertyListFromOwingColumns.BalanceOwed,
    sortable: true,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(OverdueDateFromOwingCell),
    renderLoadingCell: OverdueDateFromOwingCell.Loading,
    name: OverduePropertyListFromOwingColumns.DueDate,
    sortable: true,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(PaidToDateFromOwingCell),
    renderLoadingCell: PaidToDateFromOwingCell.Loading,
    name: OverduePropertyListFromOwingColumns.PaidTo,
    sortable: true,
    horizontalAlign: "right",
    verticalAlign: "middle"
  }
];

const initialSortOrder: GridSortOrder = {
  columnKey: OverduePropertyListFromOwingColumns.PaidTo,
  direction: "ASC"
};

function analyticsColName(columnKey: string): string {
  if (columnKey === OverduePropertyListFromOwingColumns.PaidTo)
    return "Arrears";
  if (columnKey === OverduePropertyListFromOwingColumns.Properties)
    return "Address";
  if (columnKey === OverduePropertyListFromOwingColumns.BalanceOwed)
    return "Balance Owed";
  if (columnKey === OverduePropertyListFromOwingColumns.DueDate)
    return "Due Date";
  throw new Error(`Unexpected column to return analytics: ${columnKey}`);
}

function analyticsInfo(sortOrder: GridSortOrder): {
  eventName: string;
  orderBy: string;
} {
  return {
    eventName: "Overdue Property List Reordered",
    orderBy: `${analyticsColName(sortOrder.columnKey)} ${startCase(
      lowerCase(sortOrder.direction)
    )}`
  };
}
