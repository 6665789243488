import React, { ReactElement } from "react";
import { Button } from "@ailo/ui";
import { ExcludePropertyButton } from "./ExcludeProperty";
import styled from "styled-components/native";
import { View } from "react-native";

interface Props {
  migratingManagementId: string;
  closeSidebar: () => void;
}

export function DataIssuesMigratingManagementFooter({
  migratingManagementId,
  closeSidebar
}: Props): ReactElement {
  return (
    <ButtonContainer>
      <ExcludePropertyButton
        migratingManagementId={migratingManagementId}
        closeSidebar={closeSidebar}
      />

      <StyledPrimaryButton disabled={true}>
        {"Approve Property"}
      </StyledPrimaryButton>
    </ButtonContainer>
  );
}

const ButtonContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const StyledPrimaryButton = styled(Button.Primary)`
  flex: 1;
  margin-left: 12px;
`;
