import React from "react";
import { Controller } from "react-hook-form";
import { Colors, Text } from "@ailo/primitives";
import { Money, MoneyInput } from "@ailo/ui";
import { formatRentPaymentSchedule } from "@ailo/domains";
import { RentReviewFields } from "../RentReviewFormData";
import { useRentReviewForm } from "../RentReviewFormContext";
import { RentFrequency } from "local/graphql";

function RentReviewAmountInput(): React.ReactElement {
  const { control, watch, data } = useRentReviewForm();
  const currentRentPeriod = watch(RentReviewFields.period);

  return (
    <Controller
      control={control}
      name={RentReviewFields.amount}
      defaultValue={new Money(0)}
      render={({ onChange, onBlur, value }): React.ReactElement => {
        const enteredRent = value ?? undefined;
        const rentChangeText = getRentChangeText(
          currentRentPeriod,
          data.previousRentAmount,
          enteredRent
        );

        return (
          <>
            <MoneyInput
              {...{ value, onChange, onBlur }}
              label={"Rent amount"}
            />
            {!!rentChangeText && (
              <Text.BodyXS
                color={Colors.TEXT.DARK.SECONDARY}
                style={{ marginTop: 4 }}
              >
                {rentChangeText}
              </Text.BodyXS>
            )}
          </>
        );
      }}
      rules={{
        required: "Rent review amount is required"
      }}
      accessibilityLabel={`rent-review-amount-input`}
    />
  );
}

function getRentChangeText(
  rentPeriod: RentFrequency,
  previousRent: Money,
  newRent?: Money
): string | undefined {
  if (previousRent.isZero || !newRent) return undefined;

  const rentDifference = previousRent.subtract(newRent);
  if (rentDifference.isZero) return;

  const formattedRentDifference = formatRentPaymentSchedule({
    amountInCents: rentDifference.abs().cents.toString(),
    period: rentPeriod
  });

  if (rentDifference.isPositive) {
    return `Increased ${formattedRentDifference}`;
  }

  return `Decreased ${formattedRentDifference}`;
}

export { RentReviewAmountInput };
