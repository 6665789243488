import React, { ReactElement } from "react";
import { View } from "react-native";
import { Text } from "@ailo/primitives";
import { ErrorModal } from "@ailo/ui";
import { FilesStateFile, MaybeFileDetails, NewFilesList } from "@ailo/domains";
import { AttachmentsFileList } from "./AttachmentsFileList";
import { SectionHeader } from "local/tabs/properties/components/PropertyScreen/components/common/SectionHeader";
import { AddElementButton } from "local/tabs/properties/components/PropertyScreen/components/common/AddElementButton";

interface Props {
  files: MaybeFileDetails[];
  newFiles: FilesStateFile[];
  onRemoveFile: (file: FilesStateFile) => void;
  onRetryFile: (file: FilesStateFile) => void;
  onUploadPress: () => void;
  onDismiss: () => void;
  emptyFileListMsg: string;
  error?: { title: string; description: string };
}

export function Attachments({
  files,
  newFiles,
  onRemoveFile,
  onRetryFile,
  onUploadPress,
  onDismiss,
  emptyFileListMsg,
  error
}: Props): ReactElement {
  return (
    <View>
      <SectionHeader>
        <Text.BodyL weight={"medium"}>{"Files"}</Text.BodyL>
        <AddElementButton onPress={onUploadPress} text={"Upload File"} />
      </SectionHeader>
      <NewFilesList
        files={[...newFiles].reverse()}
        onFileRemove={onRemoveFile}
        onFileUploadRetry={onRetryFile}
      />
      <AttachmentsFileList
        files={files}
        showEmpty={newFiles.length === 0}
        emptyFileListMsg={emptyFileListMsg}
      />
      {error && (
        <ErrorModal
          title={error.title}
          description={error.description}
          onDismiss={onDismiss}
        />
      )}
    </View>
  );
}
