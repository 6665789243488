import React, { ReactElement } from "react";
import { SearchBox } from "@ailo/ui";

interface Props {
  searchQuery?: string;
  onChangeFilter: (query?: string) => void;
  minInputLengthForInteraction?: number;
}

export function PropertyAddressFilter({
  searchQuery,
  onChangeFilter,
  minInputLengthForInteraction
}: Props): ReactElement {
  return (
    <SearchBox
      onClear={onChangeFilter}
      onRetry={onChangeFilter}
      onInputFinish={onChangeFilter}
      initialQueryString={searchQuery}
      inputDebounceDelay={500}
      placeholder={"Search properties"}
      isMulti={false}
      controlType={"compact"}
      minInputLengthForInteraction={minInputLengthForInteraction}
    />
  );
}
