import { DefaultProps, Text } from "@ailo/primitives";
import { withScreenComponent } from "@ailo/services";
import {
  Button,
  ModalVisibility,
  OptionsDropdown,
  useModalVisibility
} from "@ailo/ui";
import React, { ReactElement } from "react";
import { HeaderRow, LeftColumn, RightColumn } from "./components";
import { TenancyReceiptModal } from "./TenancyReceiptModal";
import { WalletReceiptModal } from "./WalletReceiptModal";

import { TransactionsListGrid } from "./TransactionsListGrid";

export const TransactionsScreen = withScreenComponent(
  (): ReactElement | null => {
    const tenancyReceiptModalVisibility = useModalVisibility();
    const walletReceiptModalVisibility = useModalVisibility();

    return (
      <>
        <HeaderRow>
          <LeftColumn style={{ padding: 14, paddingLeft: 0 }}>
            <Text.DisplayM>{"Transactions"}</Text.DisplayM>
          </LeftColumn>
          <RightColumn>
            <NewTenancyReceiptButton
              onPress={tenancyReceiptModalVisibility.show}
            />
            <MoreActionsDropdown
              walletReceiptModalVisibility={walletReceiptModalVisibility}
            />
          </RightColumn>
        </HeaderRow>
        <TransactionsListGrid />
        <TenancyReceiptModal visibility={tenancyReceiptModalVisibility} />
        <WalletReceiptModal visibility={walletReceiptModalVisibility} />
      </>
    );
  }
);

function NewTenancyReceiptButton({
  style,
  onPress
}: DefaultProps<{ onPress: (x: any) => void }>): ReactElement {
  return (
    <Button.Primary
      testID={"CreateTenancyReceipt"}
      style={style}
      onPress={onPress}
    >
      {"New tenancy receipt"}
    </Button.Primary>
  );
}

function MoreActionsDropdown({
  walletReceiptModalVisibility
}: {
  walletReceiptModalVisibility: ModalVisibility<any>;
}): ReactElement {
  return (
    <OptionsDropdown
      title={"More"}
      options={[
        {
          label: "Create wallet receipt",
          onSelect() {
            walletReceiptModalVisibility.show(null);
          }
        }
      ]}
      style={{ marginLeft: 12 }}
    />
  );
}
