import { AiloRN } from "@ailo/ailorn";
import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { useCallback } from "react";
import {
  UpdateActionMutation,
  useUpdateActionMutation,
  GetProjectActionsDocument,
  ActionType,
  ActionDetailsFragment
} from "local/graphql";
import { ActionFormData } from "../ActionFormData";

export interface ActionDetails {
  id: string;
  projectId: string;
  name: string;
  description: string;
  assignee: AiloRN;
  dueDate: string;
  type: ActionType;
}

export function useUpdateAction({
  onSuccess,
  onError
}: {
  onSuccess: ({
    action,
    initialValues,
    formValues
  }: {
    action: ActionDetailsFragment;
    initialValues: ActionFormData;
    formValues: ActionFormData;
  }) => void;
  onError: () => void;
}): [
  (
    details: ActionDetails,
    initialValues: ActionFormData,
    formValues: ActionFormData
  ) => void,
  MutationResult<UpdateActionMutation>
] {
  const [updateActionMutation, result] = useUpdateActionMutation();

  const updateAction = useCallback(
    async (
      { id, projectId, assignee, ...otherActionDetails }: ActionDetails,
      initialValues,
      formValues: ActionFormData
    ): Promise<void> => {
      const mutationInput = {
        ...otherActionDetails,
        id,
        assigneeAilorn: assignee.toString()
      };

      let result: ExecutionResult<UpdateActionMutation>;

      try {
        result = await updateActionMutation({
          variables: { input: mutationInput },
          refetchQueries: [
            {
              query: GetProjectActionsDocument,
              variables: {
                projectId: projectId,
                pageSize: 10
              }
            }
          ]
        });
        throwIfMutationFailed(result, { dataKey: "updateAction" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }

      const actionType = result.data!.updateAction!.type;
      if (!actionType) {
        throw new Error("Action was created with no type.");
      }

      onSuccess({
        action: { ...result.data!.updateAction!, type: actionType },
        initialValues,
        formValues
      });
    },
    [updateActionMutation, onSuccess, onError]
  );

  return [updateAction, result];
}
