import React, { ReactElement } from "react";
import { ExcludedMigratingManagementFragment } from "local/graphql";
import { TextCell } from "@ailo/ui";

function ExclusionReasonCell({
  row: { exclusionReason }
}: {
  row: ExcludedMigratingManagementFragment;
  rowIndex: number;
}): ReactElement {
  return <TextCell>{exclusionReason}</TextCell>;
}

ExclusionReasonCell.Loading = TextCell.Loading;

export { ExclusionReasonCell };
