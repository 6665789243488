import React, { ReactElement, useEffect } from "react";
import { Skeleton } from "@ailo/primitives";
import { ErrorAlertScreen } from "@ailo/ui";
import { BaseActionActivity } from "../../BaseActionActivity";
import { assignFormActivityDescription } from "../assignFormActivityDescription";
import { FormListItem } from "local/domain/project";
import { ActionType, ReiFormErrorCode } from "local/graphql";
import {
  getReiFormError,
  ReiFormButtons,
  ReiFormDownloadWithUrlButton
} from "local/domain/project/action";
import { SelectTemplateView } from "../SelectTemplateView";
import { RefinedActionActivityProps } from "../../ActionActivityProps";
import { useGetReiFormStatus } from "local/domain/project/useGetReiFormStatus";
import { ReiFormViewButton } from "local/domain/project/action/formAction/ReiFormButtons/ReiFormViewButton";

interface Props {
  formId: string;
}

export function GeneratedFormView({
  formId,
  action,
  projectId
}: Props & RefinedActionActivityProps<ActionType.AssignForm>): ReactElement {
  const {
    data: reiFormInfo,
    loading,
    error,
    refetch,
    startPolling,
    stopPolling
  } = useGetReiFormStatus(formId);

  const reiFormFinalised = reiFormInfo?.finalised;

  useEffect(() => {
    if (reiFormFinalised !== true) {
      startPolling(5000);
    }

    if (reiFormFinalised === true) {
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, reiFormFinalised]);

  const reiFormNotFoundError = getReiFormError(
    ReiFormErrorCode.ReiFormNotFound,
    error
  );

  if (reiFormNotFoundError) {
    return (
      <SelectTemplateView
        action={action}
        projectId={projectId}
        displayDeletedFormAlert={true}
      />
    );
  }

  if (error) {
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the form information."}
        onRetry={refetch}
      />
    );
  }

  return (
    <BaseActionActivity description={assignFormActivityDescription}>
      {loading && <Skeleton style={{ borderRadius: 8, height: 72 }} />}
      {reiFormInfo && (
        <FormListItem
          formName={reiFormInfo.name}
          formStatus={reiFormInfo.finalised ? "Finalised" : "Draft"}
        />
      )}
      <ReiFormButtons style={{ marginTop: 24 }}>
        <ReiFormViewButton formId={formId} disabled={loading}>
          {"View"}
        </ReiFormViewButton>
        <ReiFormDownloadWithUrlButton formId={formId}>
          {"Download a copy"}
        </ReiFormDownloadWithUrlButton>
      </ReiFormButtons>
    </BaseActionActivity>
  );
}
