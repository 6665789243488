import React from "react";
import {
  formatRentPaymentSchedule,
  RentScheduleDomainType,
  RentAmountAndFrequency,
  hasSameRent,
  RentDomainType
} from "@ailo/domains";
import { formatDateDefaultToNA } from "@ailo/services";
import { formatPaidToDate } from "./formatPaidToDate";
import { DescriptionList } from "@ailo/ui";
import { NextRentDetails } from "./NextRentDetails";
import { NextRentReviewDetails } from "./NextRentReviewDetails";

interface Props {
  paidToDate?: string | null;
  endDate?: string | null;
  currentRent?: RentAmountAndFrequency | null;
  nextRentSchedule?: RentScheduleDomainType | null;
  nextRentReview?: RentDomainType | null;
  scheduledRentReviewDate?: string | null;
  isVacant: boolean;
}

function RentHealthBody({
  paidToDate,
  endDate,
  currentRent,
  nextRentSchedule,
  nextRentReview,
  scheduledRentReviewDate,
  isVacant
}: Props): React.ReactElement {
  if (!currentRent) {
    return (
      <RentHealthBodyWithoutCurrentRent
        nextRentSchedule={nextRentSchedule}
        paidToDate={paidToDate}
      />
    );
  }

  if (isVacant) return <NoGeneratingIncome />;

  const nextRentReviewToShow = hasSameRent(currentRent, nextRentReview)
    ? null
    : nextRentReview;

  return (
    <>
      <DescriptionList
        size={"small"}
        testID={"rent-details-section"}
        items={[
          {
            label: "Rent amount",
            description: formatRentPaymentSchedule(currentRent)
          },
          {
            label: "Paid to date",
            description: formatPaidToDate(paidToDate)
          },
          ...(endDate
            ? [
                {
                  label: "Rent end date",
                  description: formatDateDefaultToNA(endDate)
                }
              ]
            : [])
        ]}
      />

      {nextRentSchedule && !hasSameRent(currentRent, nextRentSchedule) ? (
        <NextRentDetails
          style={{ marginTop: 16 }}
          rentSchedule={nextRentSchedule}
        />
      ) : null}

      {nextRentReviewToShow || scheduledRentReviewDate ? (
        <NextRentReviewDetails
          style={{ marginTop: 16 }}
          rentReview={nextRentReviewToShow}
          scheduledRentReviewDate={scheduledRentReviewDate}
        />
      ) : null}
    </>
  );
}

function RentHealthBodyWithoutCurrentRent({
  nextRentSchedule,
  paidToDate
}: {
  nextRentSchedule?: RentScheduleDomainType | null;
  paidToDate?: string | null;
}): React.ReactElement {
  if (!nextRentSchedule) return <NoGeneratingIncome />;

  return (
    <NextRentDetails rentSchedule={nextRentSchedule} paidToDate={paidToDate} />
  );
}

function NoGeneratingIncome(): React.ReactElement {
  return (
    <DescriptionList
      size={"small"}
      items={[
        {
          label: "Non-income generating"
        }
      ]}
    />
  );
}

export { RentHealthBody };
