import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback
} from "react";
import { BasePropertyOnboardingListHeader } from "./BasePropertyOnboardingListHeader";
import { useToastContext } from "@ailo/ui";
import { capitalize } from "lodash";
import { PropertyOnboardingListRequestParams } from "../types";
import { IncludeMigratingManagementsMutation } from "local/graphql";
import { useIncludeMigratingManagements } from "../hooks/useIncludeMigratingManagements";

interface Props {
  selectedMigratingManagementIds: string[];
  setSelectedMigratingManagementIds: Dispatch<SetStateAction<string[]>>;
  lastRequestParams: PropertyOnboardingListRequestParams | null;
  fullySelected: boolean;
  setFailedProperties: Dispatch<SetStateAction<number>>;
  refetch: () => void;
}

export function ExcludedPropertyOnboardingListHeader({
  selectedMigratingManagementIds,
  setSelectedMigratingManagementIds,
  lastRequestParams,
  fullySelected,
  setFailedProperties,
  refetch
}: Props): ReactElement {
  const toast = useToastContext();

  const propertyPlurality =
    selectedMigratingManagementIds.length === 1 ? "property" : "properties";

  const onIncludeError = useCallback(() => {
    toast.show({
      type: "error",
      message: `Unable to include ${selectedMigratingManagementIds.length} ${propertyPlurality}. Please try again`
    });
    setSelectedMigratingManagementIds([]);
  }, [
    selectedMigratingManagementIds.length,
    setSelectedMigratingManagementIds,
    toast,
    propertyPlurality
  ]);

  const onIncludeSuccess = useCallback(
    (data: IncludeMigratingManagementsMutation) => {
      setSelectedMigratingManagementIds([]);

      const successes = data.includeMigratingManagements?.successes ?? [];
      if (successes.length !== 0) {
        toast.show({
          type: "success",
          message: `${successes.length} ${
            successes.length === 1 ? "property" : "properties"
          } included`
        });
      }

      const errors = data.includeMigratingManagements?.errors ?? [];
      setFailedProperties(errors.length);

      refetch();
    },
    [setSelectedMigratingManagementIds, toast, setFailedProperties, refetch]
  );

  const [includeMigratingManagements] = useIncludeMigratingManagements({
    lastRequestParams,
    selectedMigratingManagementIds,
    onError: onIncludeError,
    onSuccess: onIncludeSuccess
  });

  return (
    <BasePropertyOnboardingListHeader
      onUnselect={() => setSelectedMigratingManagementIds([])}
      fullySelected={fullySelected}
      label={`${selectedMigratingManagementIds.length} selected`}
      action={{
        label: `Include ${capitalize(propertyPlurality)}`,
        onConfirm: includeMigratingManagements,
        modalTitle: `Include ${capitalize(propertyPlurality)}`,
        modalBody: `${selectedMigratingManagementIds.length} ${propertyPlurality} will be moved to the awaiting approval list.`
      }}
    />
  );
}
