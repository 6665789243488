import { Controller, UseFormMethods } from "react-hook-form";
import { NewTenancyFormData } from "../NewTenancyForm";
import React, { ReactElement, useEffect } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import {
  Alert,
  CollapsibleSection,
  FormField,
  Money,
  MoneyInput
} from "@ailo/ui";
export function DepositDetailsStep({
  form,
  display
}: {
  form: UseFormMethods<NewTenancyFormData>;
  display: boolean;
}): ReactElement {
  return (
    <View style={{ display: display ? undefined : "none" }}>
      <CollapsibleSection
        title={"Collect an initial payment to finalise tenancy"}
        defaultEnabled={true}
      >
        <DepositDetailsSectionContent style={{ marginTop: 24 }} form={form} />
      </CollapsibleSection>
    </View>
  );
}

function DepositDetailsSectionContent({
  style,
  form
}: {
  style?: StyleProp<ViewStyle>;
  form: UseFormMethods<NewTenancyFormData>;
}): ReactElement {
  const { unregister, register, watch } = form;

  useEffect(() => {
    register({ name: "depositEnabled", value: true });

    return (): void => {
      unregister(["depositEnabled"]);
    };
  }, [register, unregister]);

  const depositEnabled = watch("depositEnabled");

  return (
    <View style={style}>
      <Controller
        control={form.control}
        name={"depositAmount"}
        defaultValue={null}
        render={({ value, onChange, onBlur }) => (
          <FormField label={"Payment amount"} style={{ marginBottom: 20 }}>
            <MoneyInput
              {...{ value, onChange, onBlur }}
              error={form.errors.depositAmount?.message}
              withTrailingZeros={true}
            />
          </FormField>
        )}
        rules={{
          required: "Amount must not be empty",
          validate: (amount): boolean | string =>
            depositEnabled && !!amount && amount.cents <= 0
              ? `Amount must be greater than ${Money.zero().format()}.`
              : true
        }}
      />
      <Alert
        type={"info"}
        message={
          "Renters will be emailed with instructions on how to download the Ailo app and make their initial payment once the tenancy has been created."
        }
      />
    </View>
  );
}
