import { Text, Colors } from "@ailo/primitives";
import React from "react";

export function ReportHeader({
  reportTitle
}: {
  reportTitle: string;
}): React.ReactElement {
  return (
    <Text.DisplayM color={Colors.TEXT.DARK.PRIMARY}>
      {reportTitle}
    </Text.DisplayM>
  );
}
