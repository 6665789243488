import {
  MigratingManagementSidebarFragment,
  MigratingManagementPublishStatus,
  MigratingManagementStatus
} from "local/graphql";
import React from "react";
import {
  ApprovedMigratingManagementBody,
  ApprovedMigratingManagementFooter,
  DataIssuesMigratingManagementBody,
  DataIssuesMigratingManagementFooter,
  FailedMigratingManagementBody,
  HealthyMigratingManagementBody,
  HealthyMigratingManagementFooter,
  InProgressMigratingManagementBody,
  InProgressMigratingManagementFooter
} from "./innerContent";
import { ExcludedMigratingManagementBody } from "./innerContent/body/ExcludedMigratingManagementBody";
import { ExcludedMigratingManagementFooter } from "./innerContent/footer/ExcludedMigratingManagementFooter";

interface MigratingManagementSidebarInnerContent {
  body: React.ReactNode;
  footer: React.ReactNode;
}

interface Params {
  migratingManagement: MigratingManagementSidebarFragment;
  closeSidebar: () => void;
  setLoading: (loading: boolean) => void;
}

export function getMigratingManagementSidebarInnerContent(
  params: Params
): MigratingManagementSidebarInnerContent | null {
  const { migratingManagement } = params;

  if (migratingManagement.status === MigratingManagementStatus.Imported) {
    return getImportedSidebarInnerContent(params);
  }

  if (migratingManagement.status === MigratingManagementStatus.Excluded) {
    return getExcludedSidebarInnerContent(params);
  }

  if (migratingManagement.status === MigratingManagementStatus.Approved) {
    return getApprovedSidebarInnerContent(params);
  }

  if (migratingManagement.status === MigratingManagementStatus.InProgress) {
    return getInProgressSidebarInnerContent();
  }

  return null;
}

function getImportedSidebarInnerContent({
  migratingManagement,
  closeSidebar,
  setLoading
}: Params): MigratingManagementSidebarInnerContent | null {
  const canApprove = migratingManagement.errors?.length === 0;
  const showDataIssues = !canApprove || migratingManagement.hasVacatingTenancy;

  const body = showDataIssues ? (
    <DataIssuesMigratingManagementBody
      setLoading={setLoading}
      issues={migratingManagement.errors ?? undefined}
      migratingManagementIssueData={{
        migratingManagementId: migratingManagement.id,
        migrateAsVacant: migratingManagement.migrateAsVacant,
        hasVacatingTenancy: migratingManagement.hasVacatingTenancy
      }}
    />
  ) : (
    <HealthyMigratingManagementBody />
  );

  const footer = canApprove ? (
    <HealthyMigratingManagementFooter
      migratingManagementId={migratingManagement.id}
      closeSidebar={closeSidebar}
    />
  ) : (
    <DataIssuesMigratingManagementFooter
      migratingManagementId={migratingManagement.id}
      closeSidebar={closeSidebar}
    />
  );

  return { body, footer };
}

function getExcludedSidebarInnerContent({
  migratingManagement,
  closeSidebar
}: Params): MigratingManagementSidebarInnerContent {
  return {
    body: (
      <ExcludedMigratingManagementBody
        statusChangedAt={migratingManagement.statusChangedAt}
        statusChangedBy={migratingManagement.statusChangedBy}
        exclusionReason={migratingManagement.exclusionReason}
        exclusionNotes={migratingManagement.exclusionNotes}
        errors={migratingManagement.errors}
      />
    ),
    footer: (
      <ExcludedMigratingManagementFooter
        migratingManagementId={migratingManagement.id}
        closeSidebar={closeSidebar}
      />
    )
  };
}

function getApprovedSidebarInnerContent({
  migratingManagement,
  closeSidebar
}: Params): MigratingManagementSidebarInnerContent {
  if (
    migratingManagement.publishStatus ===
    MigratingManagementPublishStatus.Failed
  ) {
    return {
      body: <FailedMigratingManagementBody />,
      footer: (
        <ApprovedMigratingManagementFooter
          publishButtonText={"Retry Set Property Live"}
          migratingManagementId={migratingManagement.id}
          closeSidebar={closeSidebar}
        />
      )
    };
  }

  return {
    body: <ApprovedMigratingManagementBody />,
    footer: (
      <ApprovedMigratingManagementFooter
        publishButtonText={"Set Property Live"}
        migratingManagementId={migratingManagement.id}
        closeSidebar={closeSidebar}
      />
    )
  };
}

function getInProgressSidebarInnerContent(): MigratingManagementSidebarInnerContent {
  return {
    body: <InProgressMigratingManagementBody />,
    footer: <InProgressMigratingManagementFooter />
  };
}
