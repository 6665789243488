import React, { ReactElement } from "react";
import { View } from "react-native";

export function Step({
  visible,
  children
}: {
  visible: boolean;
  children?: React.ReactNode;
}): ReactElement {
  return (
    <View style={{ display: visible ? undefined : "none" }}>{children}</View>
  );
}
