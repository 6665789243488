import { useToastContext } from "@ailo/ui";
import { useCompleteAction } from "local/domain/project";
import { ActionStatus, SigningStatus } from "local/graphql";
import { useEffect, useCallback, useState } from "react";
import { RefinedActionActivityProps } from "../ActionActivityProps";

export function usePollingCallback({
  action,
  projectId,
  remoteSigningStatus,
  startPolling,
  stopPolling,
  statusToCheck
}: RefinedActionActivityProps & {
  remoteSigningStatus?: SigningStatus;
  startPolling: (interval: number) => void;
  stopPolling: () => void;
  statusToCheck?: SigningStatus;
}): () => void {
  const completeAction = useGetCompleteAction(projectId);
  const [isActionCompleted, setIsActionCompleted] = useState(false);
  useEffect(() => {
    if (remoteSigningStatus === statusToCheck) {
      stopPolling();

      if (!isActionCompleted && action.status === ActionStatus.Active) {
        completeAction(action.id);
        setIsActionCompleted(true);
      }
    }
  }, [
    action.status,
    action.id,
    completeAction,
    stopPolling,
    remoteSigningStatus,
    statusToCheck,
    isActionCompleted
  ]);

  return useCallback(() => {
    if (action.status === ActionStatus.Done) {
      return;
    }
    startPolling(5000);
  }, [startPolling, action]);
}

export function useGetCompleteAction(
  projectId: string
): (actionId: string) => void {
  const toast = useToastContext();

  const [completeAction] = useCompleteAction({
    projectId,
    onError: () => {
      toast.show({
        type: "error",
        message: "Cannot update the status of this action"
      });
    }
  });
  return completeAction;
}
