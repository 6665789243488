import React, { ReactElement } from "react";
import {
  ApprovedMigratingManagementFragment,
  AwaitingApprovalMigratingManagementFragment,
  ExcludedMigratingManagementFragment
} from "local/graphql";
import { TextCell } from "@ailo/ui";

function PortfolioCell({
  row: { portfolioName }
}: {
  row:
    | AwaitingApprovalMigratingManagementFragment
    | ExcludedMigratingManagementFragment
    | ApprovedMigratingManagementFragment;
  rowIndex: number;
}): ReactElement {
  return <TextCell>{portfolioName}</TextCell>;
}

PortfolioCell.Loading = TextCell.Loading;

export { PortfolioCell };
