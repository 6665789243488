import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { AwaitingApprovalPropertyOnboardingListHeader } from "../../PropertyOnboardingListHeader";
import { AwaitingApprovalPropertyOnboardingListFilterData } from "./useAwaitingApprovalPropertyOnboardingListFilters";
import { AwaitingApprovalPropertyOnboardingListSelect } from "./useAwaitingApprovalPropertyOnboardingListSelect";
import { AwaitingApprovalPropertyOnboardingListQuery } from "../../hooks";
import { PropertyOnboardingListFailedPropertyActionModal } from "../../PropertyOnboardingListFailedPropertyActionModal";
import { AwaitingApprovalPropertyOnboardingListFilters } from "../AwaitingApprovalPropertyOnboardingListFilters";

interface Params {
  tabNavigation: ReactElement;
  queryData: AwaitingApprovalPropertyOnboardingListQuery;
  filters: AwaitingApprovalPropertyOnboardingListFilterData;
  select: AwaitingApprovalPropertyOnboardingListSelect;
}

export interface AwaitingApprovalPropertyOnboardingListContent {
  headerCaptionContent: (showGridHeader: boolean) => ReactElement;
  headerOverride: ReactElement | null;
  failedPropertiesModal: ReactElement | null;
}

export function useAwaitingApprovalPropertyOnboardingListContent({
  tabNavigation,
  queryData: { refetch, lastRequestParams },
  filters,
  select: {
    validSelectedMigratingManagementIds,
    setSelectedMigratingManagementIds,
    selectableMigratingManagementIds
  }
}: Params): AwaitingApprovalPropertyOnboardingListContent {
  const [failedProperties, setFailedProperties] = useState(0);

  const headerCaptionContent = useCallback(
    (showGridHeader?: boolean): React.ReactElement => {
      return (
        <>
          {tabNavigation}
          {showGridHeader ? (
            <AwaitingApprovalPropertyOnboardingListFilters filters={filters} />
          ) : null}
        </>
      );
    },
    [tabNavigation, filters]
  );

  const headerOverride = useMemo(() => {
    return validSelectedMigratingManagementIds.length !== 0 ? (
      <AwaitingApprovalPropertyOnboardingListHeader
        lastRequestParams={lastRequestParams}
        selectedMigratingManagementIds={validSelectedMigratingManagementIds}
        setSelectedMigratingManagementIds={setSelectedMigratingManagementIds}
        fullySelected={
          validSelectedMigratingManagementIds.length ===
          selectableMigratingManagementIds.length
        }
        setFailedProperties={setFailedProperties}
        refetch={refetch}
      />
    ) : null;
  }, [
    lastRequestParams,
    refetch,
    validSelectedMigratingManagementIds,
    selectableMigratingManagementIds.length,
    setSelectedMigratingManagementIds
  ]);

  const failedPropertiesModal = useMemo(() => {
    return failedProperties ? (
      <PropertyOnboardingListFailedPropertyActionModal
        onClose={() => setFailedProperties(0)}
        failedProperties={failedProperties}
      />
    ) : null;
  }, [failedProperties]);

  return {
    headerCaptionContent,
    headerOverride,
    failedPropertiesModal
  };
}
