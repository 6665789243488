import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Text } from "@ailo/primitives";
import { Spinner } from "@ailo/ui";

const LoadingSpinner = styled(Spinner).attrs({ size: 24 })`
  margin-right: 12px;
`;

export const PleaseWaitTitle = styled(View).attrs(({ children }) => ({
  children: (
    <>
      <LoadingSpinner />
      <Text.DisplayS>{children ?? "Please wait..."}</Text.DisplayS>
    </>
  )
}))`
  flex-direction: row;
  align-items: flex-start;
`;
