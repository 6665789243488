import { TenancyBond } from "local/tabs";
import { TenancyBondStatus } from "local/graphql";

export function canEditClaim(
  hasEditBondClaimFeature: boolean,
  bond: TenancyBond
): boolean {
  return (
    hasEditBondClaimFeature &&
    bond.status !== TenancyBondStatus.ClaimSuccessful &&
    bond.status !== TenancyBondStatus.BondResolved
  );
}
