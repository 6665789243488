import React, { ReactElement } from "react";
import { ClaimTenancyData } from "local/domain/bond";
import { View } from "react-native";
import { PropertyAndLiabilityDetails } from "./PropertyAndLiabilityDetails";
import { Separator } from "@ailo/ui";
import { TenantsDetails } from "./TenantsDetails";
import { Skeleton } from "@ailo/primitives";
import styled from "styled-components/native";

type Props = {
  data?: ClaimTenancyData;
};

export function TenancyDetails({ data }: Props): ReactElement {
  return (
    <View style={{ marginLeft: 32, marginTop: 80 }}>
      <PropertyAndLiabilityDetails data={data!} />
      <Separator style={{ marginLeft: 0, marginTop: 20, marginBottom: 20 }} />
      <TenantsDetails data={data!} />
    </View>
  );
}

function Loading(): ReactElement {
  return (
    <View style={{ marginLeft: 32, marginTop: 64 }}>
      <Bone style={{ marginBottom: 12 }} />
      <LongBone />
      <Bone style={{ marginBottom: 12, marginTop: 24 }} />
      <LongBone />
    </View>
  );
}

TenancyDetails.Loading = Loading;

const Bone = styled(Skeleton)`
  width: 60px;
  height: 12px;
  border-radius: 4px;
`;

const LongBone = styled(Bone)`
  width: 120px;
`;
