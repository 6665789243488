import React, { ReactElement } from "react";
import { Badge } from "@ailo/ui";
import { PortfolioPropertyOccupancy } from "./useGetManagementPortfolioProperties";
import { useGetManagementPortfolioPropertyOccupancySummary } from "./useGetManagementPortfolioPropertyOccupancySummary";

interface Props {
  occupancy: PortfolioPropertyOccupancy;
}

export function ManagementPortfolioPropertyOccupancyBadge({
  occupancy
}: Props): ReactElement | null {
  const summary = useGetManagementPortfolioPropertyOccupancySummary(occupancy);
  if (!summary) return null;
  return <Badge type={summary.type}>{summary.status}</Badge>;
}
