import React, { memo, ReactElement, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ViewStyle } from "react-native";
import { RenderData } from "./ReconciliationDateControl";
import styled from "styled-components/native";
import { Money, AutoFormattedTextInputFormField } from "@ailo/ui";
import { Text } from "@ailo/primitives";

interface ReconciliationDateControlProps {
  style?: ViewStyle;
  name: string;
  label: string;
}

function MoneyControlComponent({
  style,
  label,
  name
}: ReconciliationDateControlProps): ReactElement {
  const { errors } = useFormContext();
  const errorMessage = errors[name]?.message;
  const render = useCallback(
    ({ onChange, ...data }: RenderData<string>) => (
      <MoneyFormField
        style={style}
        label={label}
        error={errorMessage}
        onChangeText={onChange}
        {...data}
      />
    ),
    [errorMessage, label, style]
  );
  return (
    <Controller name={name} render={render} rules={rules} defaultValue={""} />
  );
}

const rules = {
  required: `You need to enter an amount`,
  validate: (value: string) =>
    Number.isNaN(Money.fromDollars(value).cents) ? `Amount is no valid` : true
};

const MoneyFormField = styled(AutoFormattedTextInputFormField).attrs(
  (props) => ({
    autoCorrect: false,
    autoCompleteType: "off",
    keyboardType: "decimal-pad",
    returnKeyType: "done",
    placeholder: "0.00",
    startAdornment: <Text.BodyL>{"$"}</Text.BodyL>,
    startAdornmentWidth: 9,
    formatValidator: /^-?[0-9]*\.?[0-9]{0,2}$/,
    ...props
  })
)``;

export const MoneyControl = memo(MoneyControlComponent);
