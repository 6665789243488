import { ScreenComponent } from "@ailo/services";
import React, { ReactElement } from "react";
import { LeaseRenewalForm } from "./components";
import { LeaseRenewalFormContext } from "local/modals/LeaseRenewalModalForm/LeaseRenewalForm";

interface Props {
  tenancyId: string;
  prefillStartDate?: string;
  onDismiss: () => void;
}

export function LeaseRenewalSidebar({
  tenancyId,
  prefillStartDate,
  onDismiss
}: Props): ReactElement {
  return (
    <ScreenComponent name={"Tenancy Review"}>
      <LeaseRenewalFormContext>
        <LeaseRenewalForm
          tenancyId={tenancyId}
          prefillStartDate={prefillStartDate}
          onDismiss={onDismiss}
        />
      </LeaseRenewalFormContext>
    </ScreenComponent>
  );
}
