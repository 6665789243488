import { useIsMountedRef } from "@ailo/primitives";
import { useState, useCallback } from "react";

export function useReiFormUrlButton({
  getFormUrl,
  onGetFormUrlComplete,
  onError
}: {
  getFormUrl: () => Promise<string>;
  onGetFormUrlComplete: (url: string) => void;
  onError: () => void;
}): { loading: boolean; onPressReiFormButton: () => void } {
  const isMountedRef = useIsMountedRef();
  const [loading, setLoadingState] = useState(false);

  const onPressReiFormButton = useCallback(async () => {
    try {
      setLoadingState(true);
      const url = await getFormUrl();
      if (!isMountedRef.current) {
        return;
      }
      setLoadingState(false);
      onGetFormUrlComplete(url);
    } catch (_error) {
      onError();
    }
  }, [getFormUrl, isMountedRef, onGetFormUrlComplete, onError]);

  return { loading, onPressReiFormButton };
}
