import { CardContentPlaceholder } from "@ailo/ui";
import React, { ReactElement } from "react";
import styled from "styled-components/native";

export function BillClaimEmpty(): ReactElement {
  return <StyledContainer description={"There are no outstanding bills"} />;
}

const StyledContainer = styled(CardContentPlaceholder)`
  height: 52px;
  border-radius: 4;
`;
