import React from "react";
import { BadgeCellLoading, CellContainer } from "@ailo/ui";
import { View } from "react-native";
import styled from "styled-components/native";
import { CustomersListRow } from "../useCustomersListQuery";
import { ContactType } from "local/graphql";
import { PropertyBadges } from "@ailo/domains";

function TypeCell({
  row: { type }
}: {
  row: CustomersListRow;
}): React.ReactElement {
  const isInvestor = type.includes(ContactType.Investor);
  const isTenant = type.includes(ContactType.Tenant);

  return (
    <CellContainer
      style={{ flex: 1, alignItems: "flex-start", justifyContent: "center" }}
    >
      {isInvestor && (
        <StyledPropertyBadges showInvestor={true} properties={[]} />
      )}
      {isInvestor && isTenant && (
        <View style={{ marginTop: 2, marginBottom: 2 }} />
      )}
      {isTenant && <StyledPropertyBadges showTenant={true} properties={[]} />}
    </CellContainer>
  );
}

const StyledPropertyBadges = styled(PropertyBadges)`
  flex-wrap: wrap;
`;

TypeCell.Loading = BadgeCellLoading;

export { TypeCell };
