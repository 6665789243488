import React from "react";
import styled from "styled-components/native";
import { SFC, opacify, Colors, MovingOutTruckImage } from "@ailo/primitives";
import { TenancyBanner } from "local/domain/propertyManagement";

interface Props {
  endDate: string;
  outstandingRent?: number;
  outstandingBill?: number;
}

export const MovingOutBanner: SFC<Props> = ({
  endDate,
  outstandingBill,
  outstandingRent,
  style
}) => {
  return (
    <TenancyBanner
      style={[style, { backgroundColor: opacify(Colors.STATUS.WARNING, 0.2) }]}
      dateColumn={{ dateColumnTitle: "End date", dateValue: endDate }}
      moneyColumn={{
        moneyColumnTitle: "Current balance to vacate",
        billBalanceCents: outstandingBill,
        rentBalanceCents: outstandingRent
      }}
      renderBannerImage={(): React.ReactNode => <StyledImage />}
    />
  );
};

const StyledImage = styled(MovingOutTruckImage).attrs({
  width: 96,
  height: 48
})`
  position: absolute;
  bottom: 0;
  right: 12px;
`;
