import React, { ReactElement } from "react";
import { UseFormMethods } from "react-hook-form";
import { NewTenancyFormData } from "../NewTenancyForm";
import { BaseReviewDetailsSection } from "./BaseReviewDetailsSection";
import { ReviewTenantProfileCard } from "./ReviewTenantProfileCard";

interface Props {
  form: UseFormMethods<NewTenancyFormData>;
  onEditPress?(): void;
}

export function TenantDetailsSection({
  form,
  onEditPress
}: Props): ReactElement {
  const tenants = form.getValues().tenants;
  return (
    <BaseReviewDetailsSection title={"Renters"} onEditPress={onEditPress}>
      {!!tenants &&
        tenants.map(({ details }, index) => {
          return (
            <ReviewTenantProfileCard
              key={`New-Tenant-Review-Card-${index}-${details.firstName}`}
              person={details}
              style={{
                marginTop: 12,
                marginBottom: index === tenants.length - 1 ? 0 : 12
              }}
            />
          );
        })}
    </BaseReviewDetailsSection>
  );
}
