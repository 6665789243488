import { TenancyInspectionScheduleFragment } from "local/graphql";
import { DateDuration } from "@ailo/date";
import { formatDateOrDefault } from "@ailo/services";
import { DescriptionListItem } from "@ailo/ui";
import { DEFAULT_INSPECTION_FREQUENCY } from "local/domain/inspection";

export function getInspectionScheduleDescriptionListItems(
  inspectionSchedule: TenancyInspectionScheduleFragment
): DescriptionListItem[] {
  const agreedInspectionFrequency =
    inspectionSchedule.management?.currentOrNextManagementAgreement
      ?.inspectionFrequency;
  const inspectionFrequencyDisplay = agreedInspectionFrequency
    ? `Every ${DateDuration.from(agreedInspectionFrequency).format()}`
    : `Every ${DEFAULT_INSPECTION_FREQUENCY.format()} (Ailo default)`;

  return [
    {
      label: "Next inspection due date",
      description: formatDateOrDefault(
        "No due date set",
        inspectionSchedule.routineInspectionDueDate
      )
    },
    {
      label: "Inspection frequency",
      description: inspectionFrequencyDisplay
    }
  ];
}
