import { Colors } from "@ailo/primitives";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";

export function ModalHeader({
  children,
  style
}: {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return <Container style={style}>{children}</Container>;
}

const Container = styled(View)`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${Colors.GRAYSCALE.OUTLINE};
  flex-direction: row;
  width: 100%;
`;
