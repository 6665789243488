import { DropdownMenuOption } from "@ailo/ui";
import { DeleteIcon, RentCreditIcon } from "@ailo/primitives";
import React from "react";
import { DepositData, DepositStatus } from "local/domain/propertyManagement";

type Props = {
  onCancelClick?: () => void;
  onTransferClick?: () => void;
  deposit: DepositData;
  isFormerTenancy?: boolean;
};

export function getMenuOptions({
  onCancelClick,
  onTransferClick,
  deposit,
  isFormerTenancy
}: Props): DropdownMenuOption[] {
  let options: DropdownMenuOption[] = [];

  if (isCancellable(deposit)) {
    options = options.concat(createCancelOption(onCancelClick));
  }

  if (isTransferable(deposit)) {
    options = options.concat(
      createTransferOption(onTransferClick, isFormerTenancy)
    );
  }

  return options;
}

function isCancellable(depositDetails: DepositData): boolean {
  return [DepositStatus.Unpaid, DepositStatus.Failed].includes(
    depositDetails.displayStatus
  );
}

function isTransferable(depositDetails: DepositData): boolean {
  return [DepositStatus.Cleared].includes(depositDetails.displayStatus);
}

function createCancelOption(onCancelClicked?: () => void): DropdownMenuOption {
  return {
    label: "Remove payment request",
    icon: <DeleteIcon />,
    onSelect: (): void => onCancelClicked?.(),
    disabled: !onCancelClicked
  };
}

function createTransferOption(
  onTransferClicked?: () => void,
  isFormerTenancy?: boolean
): DropdownMenuOption {
  return {
    label: "Transfer to rent",
    icon: <RentCreditIcon />,
    onSelect: (): void => onTransferClicked?.(),
    disabled: !onTransferClicked || isFormerTenancy
  };
}
