import { AiloRN } from "@ailo/ailorn";
import {
  didQueryFail,
  logApolloResultFailed,
  useOnFocus
} from "@ailo/services";
import { PaginationNavigation } from "@ailo/ui";
import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { TransactionListTable } from "./TransactionListTable";
import { useTransactionsQuery } from "./useTransactionsQuery";

const ListSectionContainer = styled(View)`
  min-width: 500px;
`;

export function TransactionList({
  walletOwnerAilorn,
  tabNavigation,
  onPageChange
}: {
  walletOwnerAilorn?: AiloRN<string>; // TODO: To support multiple wallets per legal entity, this should be replaced by wallet id
  tabNavigation: ReactElement;
  onPageChange?(): void;
}): ReactElement {
  const { result, currentPage, pagesCount, goToPreviousPage, goToNextPage } =
    useTransactionsQuery(walletOwnerAilorn);
  useOnFocus(result.refetch);

  if (result.loading) {
    return (
      <ListSectionContainer>
        {tabNavigation}
        <TransactionListTable.Loading />
      </ListSectionContainer>
    );
  }

  const paginationNav =
    pagesCount && pagesCount > 1 ? (
      <PaginationNavigation
        style={{ marginTop: 20, justifyContent: "center" }}
        currentPage={currentPage}
        pagesCount={pagesCount}
        onGoToPreviousPage={
          goToPreviousPage &&
          ((): void => {
            goToPreviousPage();
            onPageChange?.();
          })
        }
        onGoToNextPage={
          goToNextPage &&
          ((): void => {
            goToNextPage();
            onPageChange?.();
          })
        }
      />
    ) : undefined;

  if (didQueryFail(result) || !result.data) {
    logApolloResultFailed(result, {
      operationId: "TransactionList.result"
    });
    return (
      <ListSectionContainer>
        {tabNavigation}
        <TransactionListTable.Error onRetry={result.refetch} />
        {paginationNav}
      </ListSectionContainer>
    );
  }

  return (
    <ListSectionContainer>
      {tabNavigation}
      <View>
        <TransactionListTable transactions={result.data.transactions} />
      </View>
      {paginationNav}
    </ListSectionContainer>
  );
}
