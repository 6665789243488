import { AiloRN, services } from "@ailo/ailorn";
import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { useCallback } from "react";
import { BondDetailsFormData } from "../common/BondDetailsFormData";
import {
  CreateTenancyBondMutation,
  useCreateTenancyBondMutation,
  GetIngoingTenancyDetailsDocument,
  GetTenantsAndOutstandingLiabilityDocument
} from "local/graphql";
import { useCurrentAgencyOrg } from "local/common";

export function useCreateTenancyBond({
  onCompleted,
  onError
}: {
  onCompleted: (result: ExecutionResult<CreateTenancyBondMutation>) => void;
  onError: () => void;
}): {
  createTenancyBond: (
    managementId: string,
    tenancyId: string,
    formValues: BondDetailsFormData
  ) => void;
  result: MutationResult<CreateTenancyBondMutation>;
} {
  const { ailoRN: currentAgencyOrgAilornString } = useCurrentAgencyOrg();
  const [createTenancyBondMutation, result] = useCreateTenancyBondMutation();

  const createTenancyBond = useCallback(
    async (
      managementId,
      tenancyId,
      { reference, amount }: BondDetailsFormData
    ): Promise<void> => {
      let result: ExecutionResult<CreateTenancyBondMutation>;
      try {
        result = await createTenancyBondMutation({
          variables: {
            bondDetails: {
              tenancyAilorn: AiloRN.of(
                services.PropertyManagement.tenancy,
                tenancyId
              ).toString(),
              amount: {
                cents: amount?.cents!
              },
              reference: reference!
            }
          },
          refetchQueries: [
            {
              query: GetTenantsAndOutstandingLiabilityDocument,
              variables: {
                id: tenancyId,
                organisationAilorn: currentAgencyOrgAilornString
              }
            },
            {
              query: GetIngoingTenancyDetailsDocument,
              variables: {
                id: tenancyId,
                organisationAilorn: currentAgencyOrgAilornString
              }
            }
          ]
        });
        throwIfMutationFailed(result, { dataKey: "createTenancyBond" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }

      onCompleted(result);
    },
    [
      createTenancyBondMutation,
      currentAgencyOrgAilornString,
      onCompleted,
      onError
    ]
  );

  return { createTenancyBond, result };
}
