import React, { ReactElement, useCallback, useState } from "react";
import { ScrollView, StyleProp, View, ViewStyle } from "react-native";
import {
  BaseModal,
  ConfirmModal,
  ErrorModal,
  useUnsavedChangesWarningEffect
} from "@ailo/ui";
import { Text, Colors } from "@ailo/primitives";
import { FullPageFormHeader } from "./FullPageFormHeader";
import { FormFooter } from "./FormFooter";
import { noop } from "lodash";

type StepId = number;

type MultiStepFormType = "default" | "modal";

interface Props {
  cancelButtonDisabled?: boolean;
  isDirty: boolean;
  isSubmitting?: boolean;
  steps: ((visible: boolean) => React.ReactElement)[];
  stepValue: number;
  style?: StyleProp<ViewStyle>;
  submitButtonDisabled?: boolean;
  submitButtonText?: string;
  submitButtonVisible?: boolean;
  title: string;
  type?: MultiStepFormType;
  onCancel: () => void;
  onChange: (newStep: number) => void;
  onSubmit?: () => void;
  dismissSubmitError?(): void;
  showSubmitError?: boolean;
  nextButtonTexts?: Record<StepId, string>;
  headerBackButtonDisabled?: boolean;
  hideCancelButton?: boolean;
  showFooterBackButton?: boolean;
}

export function MultiStepForm({
  cancelButtonDisabled = false,
  isDirty,
  isSubmitting = false,
  steps,
  stepValue,
  style,
  submitButtonDisabled = false,
  submitButtonText,
  submitButtonVisible = true,
  title,
  type = "default",
  onCancel,
  onChange,
  onSubmit,
  dismissSubmitError,
  showSubmitError,
  nextButtonTexts,
  headerBackButtonDisabled = false,
  hideCancelButton = false,
  showFooterBackButton = false
}: Props): ReactElement {
  const [modalVisible, setModalVisible] = useState(false);

  const cancel = useCallback(() => {
    onCancel();
    setModalVisible(false);
  }, [onCancel]);

  const onCancelPress = useCallback(() => {
    if (!isDirty) {
      cancel();
    } else {
      setModalVisible(true);
    }
  }, [cancel, isDirty]);

  useUnsavedChangesWarningEffect(isDirty);

  return (
    <FormWrapper type={type} title={title} style={style}>
      {type === "default" ? (
        <ScrollView
          style={{ flex: 1, paddingBottom: 60, paddingHorizontal: 60 }}
        >
          <FullPageFormHeader
            canGoBack={headerBackButtonDisabled ? false : stepValue !== 1}
            onGoBack={(): void => {
              onChange(stepValue - 1);
            }}
            title={title}
            rightComponent={
              steps.length > 1 ? (
                <Text.BodyXS
                  color={Colors.TEXT.DARK.SECONDARY}
                >{`Step ${stepValue} / ${steps.length}`}</Text.BodyXS>
              ) : undefined
            }
          />
          {steps.map(
            (step, index): React.ReactElement => step(stepValue === index + 1)
          )}
        </ScrollView>
      ) : (
        steps.map(
          (step, index): React.ReactElement => step(stepValue === index + 1)
        )
      )}

      <FormFooter
        type={type === "modal" ? "modal" : "full-page"}
        isSubmitting={isSubmitting}
        cancelButtonDisabled={cancelButtonDisabled}
        submitButtonDisabled={submitButtonDisabled}
        onCancel={onCancelPress}
        onSubmit={() => {
          onSubmit && stepValue === steps.length
            ? onSubmit()
            : onChange(stepValue + 1);
        }}
        submitVisible={submitButtonVisible}
        submitText={
          stepValue === steps.length
            ? submitButtonText ?? "Submit"
            : nextButtonTexts?.[stepValue] || "Next"
        }
        canGoBack={stepValue !== 1}
        onGoBack={(): void => {
          onChange(stepValue - 1);
        }}
        hideCancelButton={hideCancelButton}
        showFooterBackButton={showFooterBackButton && stepValue > 1}
      />
      <ErrorModal
        visible={!!showSubmitError}
        onDismiss={dismissSubmitError ? dismissSubmitError : () => {}}
      />
      <ConfirmModal
        visible={modalVisible}
        title={"Leave page?"}
        confirmLabel={"Leave"}
        cancelLabel={"Continue Editing"}
        onConfirm={cancel}
        onCancel={() => setModalVisible(false)}
      >
        <Text.BodyM weight={"book"}>
          {
            "You haven’t completed this form. Are you sure you want to leave without submitting?"
          }
        </Text.BodyM>
      </ConfirmModal>
    </FormWrapper>
  );
}

function FormWrapper({
  type,
  title,
  children,
  style
}: {
  type: Props["type"];
  title: string;
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  if (type === "modal") {
    return (
      <BaseModal title={title} visible>
        <View style={style}>{children}</View>
      </BaseModal>
    );
  }

  return <View style={style}>{children}</View>;
}

MultiStepForm.Loading = function Loading({
  title,
  style,
  stepCount,
  loadingComponent
}: {
  title: string;
  style?: StyleProp<ViewStyle>;
  stepCount: number;
  loadingComponent: ReactElement;
}): ReactElement {
  const steps = [() => loadingComponent];
  for (let i = 1; i < stepCount; i++) {
    steps.push(() => <React.Fragment key={`DummyView-${i}`} />);
  }

  return (
    <MultiStepForm
      style={style}
      title={title}
      stepValue={1}
      onChange={noop}
      onCancel={noop}
      isDirty={false}
      steps={steps}
      headerBackButtonDisabled
      hideCancelButton
      showFooterBackButton
      submitButtonDisabled={true}
    />
  );
};
