import { Card } from "@ailo/ui";
import styled from "styled-components/native";

export const CardWrapper = styled(Card)`
  width: inherit;
  padding-top: 16px;
  padding-bottom: 0;
  margin: 0;
  height: fit-content;
  flex-basis: auto;
`;

export const CardErrorWrapper = styled(CardWrapper)`
  padding-top: 50px;
  padding-bottom: 46px;
  padding-left: 32px;
  padding-right: 32px;

  justify-content: center;
  text-align: center;
`;
