import { AiloRN } from "@ailo/ailorn";
import { useCurrentAgencyOrg } from "./useCurrentAgencyOrg";
import { useAgencyDefaultWalletLegalEntity } from "./useAgencyDefaultWalletLegalEntity";

export function useLegalEntityPermissions(legalEntity: AiloRN): string[] {
  const agencyOrg = useCurrentAgencyOrg();
  return agencyOrg.legalEntities
    .filter(({ ailorn }) => legalEntity.toString() === ailorn)
    .flatMap((company) => company.currentUserPermissions);
}

export function useAgencyDefaultWalletLegalEntityPermissions(): string[] {
  return useLegalEntityPermissions(useAgencyDefaultWalletLegalEntity());
}
