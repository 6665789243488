import { useApolloClient } from "@apollo/client";
import { GetFormUrlsDocument, GetFormUrlsQuery } from "local/graphql";

export function useGetFormUrl(
  id: string,
  urlType: "view" | "download"
): () => Promise<string> {
  const { query } = useApolloClient();

  return async () => {
    const { data } = await query<GetFormUrlsQuery>({
      query: GetFormUrlsDocument,
      variables: {
        id
      },
      fetchPolicy: "network-only"
    });

    const { form } = data;
    if (form.__typename !== "ReiForm") {
      throw new Error("The form is not an REI form");
    }

    switch (urlType) {
      case "view":
        return form.viewUrl;
      case "download":
        return form.downloadUrl;
    }
  };
}
