import { formatDate } from "@ailo/services";
import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { View } from "react-native";
import { AllowCurrentLeaseToBePeriodicInfo } from "local/modals/LeaseRenewalModalForm/LeaseRenewalForm/LeaseRenewalSection/AllowCurrentLeaseToBePeriodicInfo";
import {
  LeaseType,
  LeaseRenewalFormData
} from "local/modals/LeaseRenewalModalForm/LeaseRenewalForm";
import { ModalRow, ModalTextWithHeader } from "@ailo/ui";

export function LeaseRenewalDetails(): ReactElement {
  const { watch } = useFormContext<LeaseRenewalFormData>();

  const leaseType = watch("leaseType");
  const startDate = watch("agreementStartDate");
  const endDate = watch("agreementFixedTermEndDate");
  const currentAgreementFixedTermEndDate = watch(
    "currentAgreementFixedTermEndDate"
  );

  const headerText =
    leaseType === LeaseType.AllowCurrentToBePeriodic
      ? "Automatic periodic lease"
      : leaseType;

  return (
    <View>
      <ModalRow>
        <ModalTextWithHeader header={"Lease type"} text={headerText} />
      </ModalRow>
      {leaseType === LeaseType.AllowCurrentToBePeriodic &&
      !!currentAgreementFixedTermEndDate ? (
        <AllowCurrentLeaseToBePeriodicInfo
          style={{ marginTop: 24 }}
          agreementEndDate={currentAgreementFixedTermEndDate}
        />
      ) : (
        <ModalRow style={{ marginTop: 16 }}>
          {startDate && (
            <ModalTextWithHeader
              header={"Start date"}
              text={formatDate(startDate)}
            />
          )}
          {endDate && (
            <ModalTextWithHeader
              header={"End date"}
              text={formatDate(endDate)}
              style={{ marginLeft: 12 }}
            />
          )}
        </ModalRow>
      )}
    </View>
  );
}
