import { ScreenComponent } from "@ailo/services";
import React from "react";
import { AddBillForm, AddBillFormContext } from "./AddBillForm";

export function AddBillSidebarContent({
  onBillCreate
}: {
  onBillCreate?(): void;
}): React.ReactElement {
  return (
    <ScreenComponent name={"Add Bill"}>
      <AddBillFormContext>
        <AddBillForm onBillCreate={onBillCreate} />
      </AddBillFormContext>
    </ScreenComponent>
  );
}
