import { Colors, Link, Text } from "@ailo/primitives";
import { RecoverableError } from "@ailo/services";
import { FormField, NoInputFormField, SelectInput } from "@ailo/ui";
import { Screens, useNavigation, useRoute } from "local/common";
import {
  AddExpenseFormData,
  useGetRecurringFeeOptions
} from "local/domain/expense";
import { isEmpty } from "lodash";
import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";

interface Props {
  style?: StyleProp<ViewStyle>;
  toggleFieldsInIntermediateState(
    field: keyof AddExpenseFormData,
    inIntermediateState: boolean
  ): void;
}

const name = "feeBlueprint";
const label = "Select a fee template";

export function RecurringFeeBlueprintInput({
  style,
  toggleFieldsInIntermediateState
}: Props): ReactElement {
  const { managementId } = useRoute<Screens.AddExpense>().params!;
  const { control, errors } = useFormContext();

  const {
    loading,
    error: queryError,
    options,
    refetch
  } = useGetRecurringFeeOptions({
    managementId
  });

  if (queryError) {
    throw new RecoverableError(
      "Error fetching recurring fee blueprints",
      refetch
    );
  }

  const noOptions = isEmpty(options);

  const inIntermediateState = loading || noOptions;

  useEffect(() => {
    toggleFieldsInIntermediateState(name, inIntermediateState);
    return () => {
      toggleFieldsInIntermediateState(name, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inIntermediateState]);

  const navigation = useNavigation();

  const navigateToToSViewScreen = (): void => {
    navigation.navigate(Screens.SettingsTab);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ value, onChange, ref }): React.ReactElement => {
        return (
          <View style={style} ref={ref}>
            {loading ? (
              <NoInputFormField loading={true} label={label} />
            ) : noOptions ? (
              <NoInputFormField
                message={
                  <Text.BodyS style={{ color: Colors.TEXT.DARK.SECONDARY }}>
                    {
                      "No fee templates available. Administrators can add fee templates from the"
                    }
                    <Link onPress={navigateToToSViewScreen} variant={"primary"}>
                      {" Settings "}
                    </Link>
                    {"page."}
                  </Text.BodyS>
                }
                label={label}
              />
            ) : (
              <FormField
                label={label}
                testID={"FeeBlueprintInputFormField"}
                error={errors[name]?.message}
              >
                <SelectInput
                  inputRef={ref}
                  value={
                    value &&
                    (options.find((opt) => opt.value === value.value) ?? value)
                  }
                  onChange={onChange}
                  placeholder={"Select a fee template"}
                  options={options}
                  useTextInputHeight
                />
              </FormField>
            )}
          </View>
        );
      }}
      rules={{
        validate: (option): boolean | string =>
          (!isEmpty(option?.label) && !isEmpty(option?.value)) ||
          "Select fee template"
      }}
    />
  );
}
