import { LocalDate } from "@ailo/date";
import { AnimatedTick, Colors, Text } from "@ailo/primitives";
import { ConfirmModal, Money } from "@ailo/ui";
import {
  ManagementFeeBlueprint,
  RecurringFee,
  TaxTreatment
} from "local/graphql";
import LottieView from "lottie-react-native";
import React, { ReactElement, useEffect, useRef } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

export type FeeSuccessModalData = Pick<
  ManagementFeeBlueprint,
  "fixedAmount" | "oneWeekRentPercentage" | "taxTreatment"
> & {
  feeBlueprint: Pick<ManagementFeeBlueprint["feeBlueprint"], "name" | "type">;
  recurring?: Pick<RecurringFee, "frequency" | "startDate">;
};

interface FeeSuccessModalProps {
  fee?: FeeSuccessModalData;
  onDismiss: () => void;
  onAddAnother?: () => void;
  visible: boolean;
}

export function FeeSuccessModal({
  fee,
  onDismiss,
  onAddAnother,
  visible
}: FeeSuccessModalProps): ReactElement {
  const animatedTickRef = useRef<LottieView>(null);

  useEffect(() => {
    if (visible && animatedTickRef.current) {
      animatedTickRef.current.reset();
      animatedTickRef.current.play();
    }
  }, [visible]);

  const {
    fixedAmount,
    feeBlueprint,
    oneWeekRentPercentage,
    taxTreatment,
    recurring
  } = fee ?? {};

  const taxText =
    taxTreatment === TaxTreatment.Inclusive
      ? "(incl. GST)"
      : taxTreatment === TaxTreatment.Exclusive
      ? "(excl. GST)"
      : "";

  let amountText =
    oneWeekRentPercentage != undefined
      ? `${oneWeekRentPercentage} weeks of rent`
      : fixedAmount
      ? Money.from(fixedAmount).format()
      : "";

  amountText = `${amountText} ${taxText}`;

  if (recurring) {
    amountText = `${amountText} charged ${
      recurring.frequency
    }\nstarting ${LocalDate.from(recurring.startDate).format("D MMMM YYYY")}`;
  }

  return (
    <ConfirmModal
      onConfirm={onDismiss}
      onCancel={onAddAnother}
      showFooterBorder={true}
      confirmLabel={"Finish"}
      cancelLabel={"Add another fee template"}
      visible={visible && !!fee}
      headerAndContentContainerStyle={{
        paddingVertical: 60
      }}
    >
      <Container>
        <AnimatedTick ref={animatedTickRef} style={{ width: 96, height: 96 }} />
        <Text.BodyL
          weight={"medium"}
          style={{ marginTop: 16 }}
        >{`${feeBlueprint?.name} added`}</Text.BodyL>
        <Text.BodyS
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ marginTop: 8, textAlign: "center" }}
        >
          {amountText}
        </Text.BodyS>
      </Container>
    </ConfirmModal>
  );
}

const Container = styled(View)`
  justify-content: center;
  align-items: center;
`;
