import { AiloRN, services } from "@ailo/ailorn";
import {
  didQueryNotLoadYet,
  didQuerySucceed,
  useOnFocus
} from "@ailo/services";
import { ErrorAlertScreen, Separator } from "@ailo/ui";
import { ExpensesList } from "local/domain/expense";
import {
  TenancySwitcher,
  useGetManagementTenancies
} from "local/domain/propertyManagement";
import { useGetMostRecentTenancyIdQuery } from "local/graphql";
import React, { ReactElement, useEffect, useState } from "react";
import { UnpaidExpensesForFormerTenanciesAlert } from "./UnpaidExpensesForFormerTenanciesAlert";

interface TenancyTabContentProps {
  managementId: string;
  itemsLimit?: number;
  onExpensePress?: (ailorn: AiloRN) => void;
  onViewMoreExpensesPress?: (tenancyId: string) => void;
}

export function TenancyTabContent({
  managementId,
  onExpensePress,
  onViewMoreExpensesPress
}: TenancyTabContentProps): ReactElement {
  const mostRecentTenancyResult = useGetMostRecentTenancyIdQuery({
    variables: { managementId }
  });
  const tenanciesResult = useGetManagementTenancies(managementId);
  const [tenancyId, setTenancyId] = useState<string>();

  const mostRecentTenancyId =
    mostRecentTenancyResult.data?.management?.mostRecentTenancy?.id;

  useEffect(() => {
    if (mostRecentTenancyId) {
      setTenancyId(mostRecentTenancyId);
    }
  }, [mostRecentTenancyId]);

  useOnFocus(mostRecentTenancyResult.refetch, { skipInitialFocus: true });

  if (
    didQueryNotLoadYet(mostRecentTenancyResult) ||
    tenanciesResult.loading ||
    !tenanciesResult.data ||
    !tenancyId
  ) {
    return (
      <>
        <TenancySwitcher.Loading style={{ marginTop: 16 }} />
        <ExpensesList.Loading itemsLimit={3} />
      </>
    );
  }

  if (!didQuerySucceed(mostRecentTenancyResult)) {
    return (
      <ErrorAlertScreen
        title={"There was a problem loading Expenses"}
        onRetry={() => mostRecentTenancyResult.refetch()}
      />
    );
  }

  if (tenanciesResult.data?.length === 0) {
    return <ExpensesList.Empty />;
  }

  return (
    <>
      {mostRecentTenancyId && tenancyId === mostRecentTenancyId && (
        <UnpaidExpensesForFormerTenanciesAlert
          management={{
            id: managementId,
            mostRecentTenancy: { id: mostRecentTenancyId }
          }}
          style={{
            marginTop: 16,
            marginHorizontal: 16
          }}
        />
      )}

      <TenancySwitcher
        value={tenancyId}
        managementId={managementId}
        tenancies={tenanciesResult.data}
        style={{ marginTop: 16, marginBottom: 0 }}
        onChange={setTenancyId}
      />

      <Separator style={{ marginLeft: 16, marginTop: 16 }} />

      <ExpensesList
        payerAilorn={AiloRN.of(services.PropertyManagement.tenancy, tenancyId)}
        onExpensePress={onExpensePress}
        onViewMoreExpensesPress={() => onViewMoreExpensesPress?.(tenancyId)}
        itemsLimit={2}
      />
    </>
  );
}
