import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from "react";
import { KeyedExcludedMigratingManagementFragment } from "../../types";

interface Params {
  migratingManagements: KeyedExcludedMigratingManagementFragment[];
}

export interface ExcludedPropertyOnboardingListSelect {
  selectedMigratingManagementIds: string[];
  setSelectedMigratingManagementIds: Dispatch<SetStateAction<string[]>>;
  selectableMigratingManagementIds: string[];
  isRowSelected?: (row: KeyedExcludedMigratingManagementFragment) => boolean;
}

export function useExcludedPropertyOnboardingListSelect({
  migratingManagements
}: Params): ExcludedPropertyOnboardingListSelect {
  const [selectedMigratingManagementIds, setSelectedMigratingManagementIds] =
    useState<string[]>([]);

  const selectableMigratingManagementIds = useMemo(() => {
    return migratingManagements.map((mm) => mm.id);
  }, [migratingManagements]);

  const isRowSelected = useCallback(
    (row: KeyedExcludedMigratingManagementFragment) =>
      selectedMigratingManagementIds.includes(row.id),
    [selectedMigratingManagementIds]
  );

  return {
    selectedMigratingManagementIds,
    setSelectedMigratingManagementIds,
    selectableMigratingManagementIds,
    isRowSelected
  };
}
