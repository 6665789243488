import { AiloRN, services } from "@ailo/ailorn";
import { AiloSentry, ensureMutationSucceeds } from "@ailo/services";
import { useCurrentAgencyOrg } from "local/common";
import { useCallback } from "react";
import {
  GetManagementAndTeamsDocument,
  useMoveManagementsToTeamMutation
} from "local/graphql";

export interface TeamManagementDetails {
  teamId: string;
  teamName: string;
  managementId: string;
}

export function useMoveManagementToTeam({
  onSuccess,
  onFail
}: {
  onSuccess: (details: TeamManagementDetails) => void;
  onFail: () => void;
}): {
  moveManagementToTeam: (details: TeamManagementDetails) => void;
  loading: boolean;
} {
  const { id: organisationId } = useCurrentAgencyOrg();

  const [moveManagementToTeamMutation, { loading }] =
    useMoveManagementsToTeamMutation();

  const moveManagementToTeam = useCallback(
    async ({
      teamId,
      teamName,
      managementId
    }: TeamManagementDetails): Promise<void> => {
      try {
        await ensureMutationSucceeds(
          moveManagementToTeamMutation({
            variables: {
              managementId: AiloRN.of(
                services.PropertyManagement.management,
                managementId
              ).toString(),
              teamId
            },
            refetchQueries: [
              {
                query: GetManagementAndTeamsDocument,
                variables: { managementId, organisationId }
              }
            ],
            awaitRefetchQueries: true
          }),
          { dataKey: "moveManagementsToTeam" }
        );
      } catch (error) {
        AiloSentry.captureException(error);
        onFail();

        return;
      }
      onSuccess({ teamId, teamName, managementId });
    },
    [moveManagementToTeamMutation, onFail, onSuccess, organisationId]
  );

  return {
    moveManagementToTeam: useCallback(
      (details: TeamManagementDetails): void => {
        moveManagementToTeam(details);
      },
      [moveManagementToTeam]
    ),
    loading
  };
}
