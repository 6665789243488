import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { Colors, Text } from "@ailo/primitives";
import {
  ConfirmModal,
  RadioButtonGroup,
  Separator,
  TextInput,
  useToastContext
} from "@ailo/ui";
import { useExcludeMigratingManagement } from "../../../hooks";
import styled from "styled-components/native";
import { PropertyExclusionReasons } from "./PropertyExclusionReasons";

interface Props {
  migratingManagementId: string;
  closeSidebar: () => void;
  modalVisible: boolean;
  closeModal: () => void;
}

export function ExcludePropertyModal({
  migratingManagementId,
  closeSidebar,
  modalVisible,
  closeModal
}: Props): ReactElement {
  const toast = useToastContext();
  const [exclusionReason, setExclusionReason] = useState<string | undefined>();
  const [exclusionNotes, setExclusionNotes] = useState<string>("");

  const exclusionReasonOptions = useMemo(() => {
    return PropertyExclusionReasons.map(({ value, label }) => ({
      value,
      label: label ?? value
    }));
  }, []);

  const onExclusionReasonChange = useCallback((newExclusionReason?: string) => {
    setExclusionReason(newExclusionReason);
  }, []);

  const onExclusionReasonNotes = useCallback((newNotes: string) => {
    setExclusionNotes(newNotes);
  }, []);

  const onError = useCallback(() => {
    toast.show({
      type: "error",
      message: "Unable to exclude property. Please try again"
    });
  }, [toast]);

  const onSuccess = useCallback(() => {
    closeSidebar();
    setExclusionReason(undefined);
    setExclusionNotes("");
    toast.show({
      type: "success",
      message: "Property marked as excluded"
    });
  }, [toast, closeSidebar]);

  const [excludeMigratingManagement, { loading }] =
    useExcludeMigratingManagement({
      migratingManagementId,
      onSuccess,
      onError
    });

  const onModalConfirm = useCallback((): void => {
    if (!exclusionReason) return;
    excludeMigratingManagement({ exclusionReason, exclusionNotes });
    closeModal();
  }, [excludeMigratingManagement, closeModal, exclusionReason, exclusionNotes]);

  return (
    <ConfirmModal
      visible={modalVisible}
      title={"Exclude Property"}
      onConfirm={onModalConfirm}
      onCancel={closeModal}
      showFooterBorder={true}
      confirmLabel={"Exclude Property"}
      disabled={!exclusionReason}
      loading={loading}
    >
      <DescriptionText>
        {
          "If a property isn’t quite ready for Ailo today, just exclude them. They can always be added in the future."
        }
      </DescriptionText>

      <ModalSeparator />

      <ExclusionReasonLabel color={Colors.TEXT.DARK.SECONDARY}>
        {"Exclude reason"}
      </ExclusionReasonLabel>
      <RadioButtonGroup
        value={exclusionReason}
        options={exclusionReasonOptions}
        onChange={onExclusionReasonChange}
        style={{ marginBottom: 26 }}
      />

      <NotesLabel color={Colors.TEXT.DARK.SECONDARY}>{"Notes"}</NotesLabel>
      <TextInput
        value={exclusionNotes}
        onChangeText={onExclusionReasonNotes}
        placeholder={"Add a note"}
        style={{ marginBottom: 60 }}
        multiline={true}
        autoExpandVertically={true}
      />
    </ConfirmModal>
  );
}

const DescriptionText = styled(Text.BodyM)`
  margin-bottom: 40px;
`;

const ModalSeparator = styled(Separator)`
  margin-left: 0px;
  margin-bottom: 32px;
`;

const ExclusionReasonLabel = styled(Text.BodyS)`
  margin-bottom: 10px;
`;

const NotesLabel = styled(Text.BodyS)`
  margin-bottom: 12px;
`;
