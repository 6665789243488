import { ClaimTenancyData } from "local/domain/bond";
import { Money } from "@ailo/ui";

export function calculateTotalAmountOfBillsDue(data: ClaimTenancyData): Money {
  return Money.fromCents(
    data.bills
      .map((bill) => bill.amount.cents)
      .reduce((accumulator, currValue) => accumulator + currValue, 0)
  );
}
