import { useEffect, useState } from "react";
import { useGenerateHelpUrl } from "@ailo/domains";
import { Platform } from "react-native";

export function useRedirectToHelpIfNeeded({
  isAuthenticated
}: {
  isAuthenticated: boolean;
}): boolean {
  const [redirected, setRedirected] = useState(false);
  const generateHelpUrl = useGenerateHelpUrl();

  useEffect((): void => {
    if (!isAuthenticated) {
      return;
    }

    if (Platform.OS !== "web") {
      return;
    }

    if (window.location.pathname === "/go/help") {
      const params = new URLSearchParams(window.location.search);
      const returnTo = params.get("return_to") || undefined;

      generateHelpUrl({ returnTo }).then((url) => {
        // eslint-disable-next-line no-console
        console.log("Redirect to", url);
        window.location.assign(url);
      });

      setRedirected(true);
    }
  }, [generateHelpUrl, isAuthenticated]);

  return redirected;
}
