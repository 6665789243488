import { useContext } from "react";
import { PropertyKeyModalContext, PropertyKeyModalContextData } from ".";

export function useKeyModalContext(): PropertyKeyModalContextData {
  const propertyKeyModalContext = useContext(PropertyKeyModalContext);

  if (!propertyKeyModalContext) {
    throw new Error("Missing PropertyKeyModalContext");
  }

  return propertyKeyModalContext;
}
