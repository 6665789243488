import { Colors } from "@ailo/primitives";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { TextCell } from "@ailo/ui";

export function ErrorCell({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <TextCell style={style} color={Colors.TEXT.DARK.SECONDARY}>
      {"Failed to load"}
    </TextCell>
  );
}
