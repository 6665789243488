import React, { ReactElement } from "react";
import { View } from "react-native";
import {
  AllocationAmountInput,
  AllocationDescriptionInput,
  AllocationsInput,
  CreateReceiptFormData,
  LeftAlignedText,
  LeftAlignedTextLoading,
  RightAlignedText,
  RightAlignedTextLoading
} from "local/tabs";
import styled from "styled-components/native";
import {
  AllocationsTableRow,
  CellContainer,
  HeaderCellContainer
} from "../AllocationsInput/AllocationsTableRow";
import { Controller, useFormContext } from "react-hook-form";
import { CheckboxInput } from "@ailo/ui";
import { Money } from "@ailo/money";
import { LocalDate } from "@ailo/date";
import { Colors } from "@ailo/primitives";

const TenancyReceiptAllocationsTableRow = styled(AllocationsTableRow).attrs(
  () => ({
    columns: [{ flex: 1 }, { flex: 5 }, { flex: 2 }, { flex: 2 }, { flex: 2 }]
  })
)``;

const TenancyReceiptAllocationsTableHeaderRow = styled(
  AllocationsTableRow
).attrs(() => ({
  columns: [{ flex: 1 }, { flex: 5 }, { flex: 2 }, { flex: 2 }, { flex: 2 }]
}))``;

export function TenancyReceiptAllocationsInput(): ReactElement {
  const { control, errors, setValue } = useFormContext<CreateReceiptFormData>();
  return (
    <AllocationsInput
      emptyTitle={"Please select a tenancy to show allocations"}
      walletFilter={"payableBy"}
      loadingRow={
        <TenancyReceiptAllocationsTableRow cellContainer={CellContainer}>
          <CheckboxInput.Loading style={{ alignSelf: "center" }} />
          <LeftAlignedTextLoading />
          <LeftAlignedTextLoading />
          <RightAlignedTextLoading />
          <RightAlignedTextLoading />
        </TenancyReceiptAllocationsTableRow>
      }
      headerRow={
        <TenancyReceiptAllocationsTableHeaderRow
          cellContainer={HeaderCellContainer}
        >
          <LeftAlignedText color={Colors.TEXT.DARK.SECONDARY}>
            {""}
          </LeftAlignedText>
          <LeftAlignedText color={Colors.TEXT.DARK.SECONDARY}>
            {"Details"}
          </LeftAlignedText>
          <LeftAlignedText color={Colors.TEXT.DARK.SECONDARY}>
            {"Due"}
          </LeftAlignedText>
          <RightAlignedText color={Colors.TEXT.DARK.SECONDARY}>
            {"Amount Due"}
          </RightAlignedText>
          <RightAlignedText color={Colors.TEXT.DARK.SECONDARY}>
            {"Amount"}
          </RightAlignedText>
        </TenancyReceiptAllocationsTableHeaderRow>
      }
      renderRow={(item, i) => {
        return (
          <TenancyReceiptAllocationsTableRow
            cellContainer={CellContainer}
            key={i}
          >
            <Controller
              name={`allocations[${i}].included`}
              defaultValue={false}
              control={control}
              render={({ onChange, value }) => (
                <CheckboxInput
                  label={undefined}
                  value={value}
                  onChange={onChange}
                  style={{ alignSelf: "center" }}
                  onFocus={() => {
                    setValue(`allocations[${i}].included`, true);
                  }}
                />
              )}
            />

            <View style={{ flex: 1 }}>
              <Controller
                name={`allocations[${i}].purposeId`}
                control={control}
                defaultValue={item.purposeId}
                render={() => <View />}
              />

              <Controller
                name={`allocations[${i}].description`}
                control={control}
                defaultValue={item.description}
                render={(props) => (
                  <AllocationDescriptionInput
                    {...props}
                    showTitle={true}
                    allocationPurpose={item}
                    error={errors.allocations?.[i]?.description?.message}
                  />
                )}
              />
            </View>
            <LeftAlignedText>
              {item.dueDate &&
                LocalDate.from(item.dueDate).format("DD MMM YYYY")}
            </LeftAlignedText>
            <RightAlignedText weight={"medium"}>
              {item.amountDue?.format()}
            </RightAlignedText>
            <Controller
              name={`allocations[${i}].amount`}
              control={control}
              defaultValue={item.amountDue || null}
              render={(props): React.ReactElement => (
                <AllocationAmountInput
                  allocationPurpose={item}
                  {...props}
                  error={errors.allocations?.[i]?.amount?.cents?.message}
                />
              )}
              rules={{
                required: "Enter amount",
                validate: (amount: Money) =>
                  amount.cents >= 0 || "Enter non-negative amount"
              }}
            />
          </TenancyReceiptAllocationsTableRow>
        );
      }}
    />
  );
}
