import { ScreenComponent } from "@ailo/services";
import React, { ComponentProps, ReactElement } from "react";
import { RentReviewForm } from "./components";

export function RentReviewSidebar(
  props: ComponentProps<typeof RentReviewForm>
): ReactElement {
  return (
    <ScreenComponent name={"Rent Review"}>
      <RentReviewForm {...props} />
    </ScreenComponent>
  );
}
