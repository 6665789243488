import React, { ReactElement } from "react";
import { Text } from "@ailo/primitives";
import {
  DescriptionList,
  DescriptionListItem,
  DynamicDescriptionList,
  Separator
} from "@ailo/ui";
import { ManagementData, TeamData } from "./useGetManagementDetailsAndTeams";
import { ManagementTeamSelector } from "./ManagementTeamSelector";
import { StyleProp, ViewStyle } from "react-native";
import { formatDate } from "@ailo/services";

interface Props {
  managementData: ManagementData;
  teamData: TeamData;
}

export function ManagementSummary({
  managementData,
  teamData
}: Props): React.ReactElement {
  const { allTeams, assignedTeamId } = teamData;
  const {
    startDate,
    fixedTermEndDate,
    unitStreetNumber,
    streetName,
    endDate,
    endReason,
    endNote
  } = managementData;

  const endedManagementData = endDate
    ? [
        {
          label: "Management end date",
          description: formatDate(endDate)
        },
        { label: "Management end reason", description: endReason?.label },
        {
          label: "Cause of loss",
          description: endReason?.causes.map(({ label }) => label).join(", ")
        },
        {
          label: "Management end note",
          description: endNote,
          fullWidth: true
        }
      ]
    : [];

  const data = [
    { label: "Managed since", description: startDate },
    { label: "Mgt. agreement expiry", description: fixedTermEndDate },
    ...endedManagementData
  ];
  return (
    <>
      <Text.BodyL weight={"medium"} style={{ marginBottom: 16 }}>
        {"Details"}
      </Text.BodyL>

      {allTeams.length > 0 && (
        <ManagementTeamSelector
          allTeams={allTeams}
          assignedTeamId={assignedTeamId}
          managementId={managementData.managementId}
          managementName={[unitStreetNumber, streetName].join(" ")}
          style={{ marginBottom: 8 }}
        />
      )}

      <Separator
        style={{
          marginTop: 32,
          marginLeft: 0,
          marginRight: -32,
          marginBottom: 32
        }}
      />

      <ManagementSummaryDescriptionList data={data} />
    </>
  );
}

type DescriptionListType =
  | DynamicDescriptionList["data"]
  | DescriptionListItem[];

function ManagementSummaryDescriptionList({
  data
}: {
  data: DescriptionListType;
}): ReactElement {
  return isStatic(data) ? (
    <DescriptionList
      size={"small"}
      items={data}
      testID={"management-details-section"}
      style={{
        paddingBottom: "16px"
      }}
    />
  ) : (
    <DynamicDescriptionList
      data={data}
      cell={ManagementEndDetails}
      cellStyle={
        {
          width: "50%", // 2 columns
          paddingBottom: "16px",
          paddingRight: "16px",
          gap: 4
        } as StyleProp<ViewStyle>
      }
    />
  );
}

function ManagementEndDetails(props: {
  label: string;
  description: string | null;
  style?: StyleProp<ViewStyle>;
}): ReactElement {
  return (
    <DynamicDescriptionList.Item direction={"column"} style={props.style}>
      <DynamicDescriptionList.Label label={props.label} />
      <DynamicDescriptionList.Description description={props.description} />
    </DynamicDescriptionList.Item>
  );
}

const isStatic = (data: DescriptionListType): data is DescriptionListItem[] => {
  const staticDescriptionListItem = (data as DescriptionListItem[])[0];
  return staticDescriptionListItem.descriptionBottom !== undefined;
};
