import { PersonAvatarProps } from "@ailo/ui";
import React from "react";
import { View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { LegalEntityBadge } from "./LegalEntityBadge";

interface Props {
  assignee: {
    id: string;
    __typename: "Person";
  } & PersonAvatarProps["person"];
  style?: ViewStyle;
}
export function AssigneeField({
  assignee,
  style
}: Props): React.ReactElement | null {
  return (
    <Container style={style}>
      <LegalEntityBadge legalEntity={assignee} style={{ marginBottom: 2 }} />
    </Container>
  );
}

AssigneeField.Loading = function AssigneeFieldLoading(): React.ReactElement {
  return (
    <View style={{ flexDirection: "column" }}>
      <LegalEntityBadge.Loading style={{ marginBottom: 2 }} />
    </View>
  );
};

const Container = styled(View)`
  flex-direction: column;
`;
