import React, { ReactElement, useCallback } from "react";
import { ConfirmModal, useGlobalModal, useToastContext } from "@ailo/ui";
import { RentReviewDetails } from "./RentReviewDetails";
import { useSubmit } from "./useSubmit";
import { RentReviewFields } from "../RentReviewFormData";
import { useAnalytics } from "@ailo/services";
import { useRentReviewForm } from "../RentReviewFormContext";
import { LeaseRenewalModalForm } from "local/modals/LeaseRenewalModalForm";

export function RentReviewConfirmModal({
  visible,
  onSuccess,
  onCancel,
  onError
}: {
  visible: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  onError: () => void;
}): ReactElement {
  const Toast = useToastContext();
  const analytics = useAnalytics();
  const modal = useGlobalModal();
  const { watch, data } = useRentReviewForm();

  const leaseRenewalChecked = watch(RentReviewFields.leaseRenewalChecked);
  const rentReviewEffectiveDate = watch(RentReviewFields.effectiveDate);

  const submitSuccess = useCallback(() => {
    Toast.show({
      type: "success",
      message: "Rent review updated"
    });
    onSuccess();
    analytics.track("Rent Review Completed", {
      leaseRenewalCheckbox: leaseRenewalChecked
    });
    if (leaseRenewalChecked) {
      modal.show(
        <LeaseRenewalModalForm
          tenancyId={data.tenancyId}
          onDismiss={modal.hide}
          onSuccess={modal.hide}
          prefillStartDate={rentReviewEffectiveDate}
        />
      );
    }
  }, [
    Toast,
    analytics,
    data.tenancyId,
    leaseRenewalChecked,
    onSuccess,
    rentReviewEffectiveDate,
    modal
  ]);

  const submit = useSubmit({
    onSuccess: submitSuccess,
    onError
  });

  return (
    <ConfirmModal
      visible={visible}
      title={"Confirm Details"}
      confirmLabel={
        leaseRenewalChecked ? "Confirm & Add Lease Renewal" : "Confirm"
      }
      onConfirm={submit}
      onCancel={onCancel}
    >
      <RentReviewDetails />
    </ConfirmModal>
  );
}
