import { AiloRN } from "@ailo/ailorn";
import {
  BankAccountMethod,
  findBankAccountPaymentMethod,
  useHasFeature,
  getNextAutomaticWithdrawalDate
} from "@ailo/domains";
import { hasPresentKey, hasValueAtKey, isPresent } from "ts-is-present";
import { useMappedQueryResult } from "@ailo/services";
import { QueryHookOptions, ApolloError } from "@apollo/client";
import { useCurrentAgencyOrg } from "local/common";
import {
  PlatformFeatureId,
  useGetAgencyBalancesAndPaymentMethodsQuery
} from "local/graphql";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useWalletQuery(
  legalEntityId?: string,
  options?: Omit<QueryHookOptions<any, {}>, "variables">
) {
  const currentAgencyOrg = useCurrentAgencyOrg();
  const result = useGetAgencyBalancesAndPaymentMethodsQuery({
    ...options,
    variables: {
      organisationId: AiloRN.fromString(currentAgencyOrg.ailoRN).internalId
    }
  });

  const hasAgencyManualWithdrawalFeature = useHasFeature(
    PlatformFeatureId.AgencyWalletManualWithdrawal
  );

  return useMappedQueryResult(
    result,
    (data, [hasAgencyManualWithdrawalFeature]) => {
      if (!data.organisation) {
        throw new ApolloError({
          errorMessage: "organisation() GQL query returned null"
        });
      }

      const legalEntity = data.organisation.legalEntities
        ?.filter(isPresent)
        .filter(hasPresentKey("__typename"))
        .filter(hasValueAtKey("__typename", "Company" as "Company"))
        .find((le) => le?.id === legalEntityId);

      const canWithdraw =
        hasAgencyManualWithdrawalFeature &&
        legalEntity?.currentUserPermissions.includes(
          "legal_entity:financial:manage"
        );

      // TODO: Allow multiple wallets per legal entity
      const graphqlWallet = legalEntity?.wallets?.[0];
      const wallet = graphqlWallet
        ? {
            nextAutomaticWithdrawalDate: getNextAutomaticWithdrawalDate(
              graphqlWallet?.autoWithdrawPlans?.items
            ),
            ...graphqlWallet
          }
        : undefined;

      const paymentMethods = legalEntity?.paymentMethods?.items?.filter(
        (paymentMethod): paymentMethod is BankAccountMethod =>
          paymentMethod?.__typename === "BankAccount"
      );

      // TODO: Allow choice of payment method?
      const bankAccountPaymentMethod =
        findBankAccountPaymentMethod(paymentMethods);

      return {
        canWithdraw,
        wallet,
        bankAccountPaymentMethod
      };
    },
    [hasAgencyManualWithdrawalFeature]
  );
}
