import React from "react";
import { FormFieldRow } from "@ailo/ui";
import { TrustAccountWalletInput } from "../TrustAccountWalletInput";
import { WalletSearchMode } from "local/tabs";
import { MainFormContainer } from "../../components";

export function TenancyReceiptMainForm(): React.ReactElement {
  return (
    <MainFormContainer>
      <FormFieldRow>
        <TrustAccountWalletInput
          label={"Tenancy"}
          mode={WalletSearchMode.TENANCY}
        />
      </FormFieldRow>
    </MainFormContainer>
  );
}
