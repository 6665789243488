import React, { ReactElement } from "react";
import { Text } from "@ailo/primitives";
import { CloseButton } from "local/common";
import styled from "styled-components/native";
import { View } from "react-native";
import { Button } from "@ailo/ui";

interface Props {
  addFile?: () => void;
  onDismiss: () => void;
}

export function ProjectFilesHeader({
  addFile,
  onDismiss
}: Props): ReactElement {
  return (
    <Container>
      <Text.DisplayS>{"All files"}</Text.DisplayS>
      <RightContent>
        <Button.Primary type={"small"} onPress={addFile} disabled={!addFile}>
          {"Add new file"}
        </Button.Primary>
        <CloseButton onDismiss={onDismiss} style={{ marginLeft: 8 }} />
      </RightContent>
    </Container>
  );
}

ProjectFilesHeader.Loading = function ProjectFilesHeaderLoading({
  onDismiss
}: {
  onDismiss: () => void;
}): ReactElement {
  return <ProjectFilesHeader onDismiss={onDismiss} />;
};

const Container = styled(View)`
  padding: 0 32px 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RightContent = styled(View)`
  flex-direction: row;
  justify-content: flex-end;
`;
