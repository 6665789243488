import { TenancyDepositStatus } from "local/graphql";

enum DepositStatus {
  Unpaid = "Unpaid",
  Pending = "Pending",
  Failed = "Failed",
  Cleared = "Cleared",
  Released = "Released",
  Cancelled = "Cancelled"
}

function mapDepositStatus(status: string): DepositStatus {
  switch (status) {
    case TenancyDepositStatus.Due:
    case TenancyDepositStatus.New:
      return DepositStatus.Unpaid;
    case TenancyDepositStatus.Pending:
      return DepositStatus.Pending;
    case TenancyDepositStatus.Failed:
      return DepositStatus.Failed;
    case TenancyDepositStatus.Paid:
      return DepositStatus.Cleared;
    case TenancyDepositStatus.Released:
      return DepositStatus.Released;
    case TenancyDepositStatus.Void:
      return DepositStatus.Cancelled;
    default:
      return DepositStatus.Unpaid;
  }
}

export { mapDepositStatus, DepositStatus };
