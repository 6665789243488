import {
  GetMigratingManagementPublishStatusQueryResult,
  useGetMigratingManagementPublishStatusQuery,
  MigratingManagementPublishStatus
} from "local/graphql";
import { useEffect } from "react";

const POLL_INTERVAL_MS = 5e3; // 5 seconds

interface Params {
  migratingManagementId: string;
  refetchList: () => void;
}

export function useGetMigratingManagementPublishStatus({
  migratingManagementId,
  refetchList
}: Params): GetMigratingManagementPublishStatusQueryResult {
  const queryResult = useGetMigratingManagementPublishStatusQuery({
    variables: { migratingManagementId },
    pollInterval: POLL_INTERVAL_MS
  });

  useEffect(() => {
    if (!queryResult.data?.migratingManagement) return;
    const { migratingManagement } = queryResult.data;

    if (!migratingManagement.publishStatus) {
      refetchList();
    }
  }, [queryResult.data, refetchList]);

  useEffect(() => {
    if (!queryResult.data?.migratingManagement) return;
    const { migratingManagement } = queryResult.data;

    if (
      migratingManagement.publishStatus !==
      MigratingManagementPublishStatus.InProgress
    ) {
      queryResult.stopPolling();
    }
  }, [queryResult]);

  return queryResult;
}
