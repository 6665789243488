import { AiloRN } from "@ailo/ailorn";
import { withScreenComponent } from "@ailo/services";
import { Screens, useRoute } from "local/common";
import React from "react";
import { ManagementPortfolioContent } from "./ManagementPortfolioContent";

/**
 * Root component for management portfolio details screen.
 * You land on it when navigating to `Screens.Portfolio`.
 */
export const ManagementPortfolioScreen = withScreenComponent(() => {
  const route = useRoute<Screens.Portfolio>();
  const portfolioId = route.params?.portfolioId;
  if (!portfolioId) {
    return null;
  }
  const portfolioAilorn = AiloRN.of(
    "propertymanagement:managementfolio",
    portfolioId
  );
  return <ManagementPortfolioContent portfolioAilorn={portfolioAilorn} />;
});
