import React, { ReactElement } from "react";
import { ReiFormInfo } from "@ailo/domains";
import {
  DefaultRequestSignatureActionDescription,
  FormListItem,
  ReiFormButtons,
  ReiFormDownloadButton
} from "local/domain/project";
import { BaseActionActivity } from "../../BaseActionActivity";
import { ReiFormViewButton } from "local/domain/project/action/formAction/ReiFormButtons/ReiFormViewButton";

type RequestSignatureActionViewProps = {
  reiTokensLength: number;
  reiForm: {
    formId: string;
    reiFormInfo: ReiFormInfo;
  };
  remoteSigningStatusSent?: string | null;
};

export function FormInfoWithSignButtonView({
  reiForm: { formId, reiFormInfo },
  remoteSigningStatusSent
}: RequestSignatureActionViewProps): ReactElement {
  return (
    <BaseActionActivity
      description={<DefaultRequestSignatureActionDescription />}
    >
      <FormListItem
        formName={reiFormInfo.name ?? ""}
        formStatus={"Finalised"}
      />
      <ReiFormButtons style={{ marginTop: 24 }}>
        <ReiFormViewButton formId={formId}>
          {"Send via DocuSign"}
        </ReiFormViewButton>
        <ReiFormDownloadButton
          formId={formId}
          disabled={false}
          remoteSigningStatusSent={remoteSigningStatusSent}
        />
      </ReiFormButtons>
    </BaseActionActivity>
  );
}
