import { Timer } from "../../../../common/useTimer";
import { useEffect } from "reactn";
import { AiloRN, services } from "@ailo/ailorn";
import { useCreateTenancyLedgerStatementMutation } from "local/graphql";
import { useState } from "react";
import { UseQueryDates } from "./useQueryDates";

function useStatementCreation(
  timer: Timer,
  tenancyId: string,
  queryDates: UseQueryDates
): {
  statementProgressId: string;
} {
  const [createStatement, statementProgress] =
    useCreateTenancyLedgerStatementMutation({
      fetchPolicy: "no-cache"
    });
  const [statementProgressId, setStatementProgressId] = useState("");

  useEffect(() => {
    if (!timer || !tenancyId || !queryDates) {
      return;
    }

    createStatement({
      variables: {
        tenancyAiloRN: AiloRN.of(
          services.PropertyManagement.tenancy,
          tenancyId
        ).toString(),
        startDate: queryDates.startDate.toString(),
        endDate: queryDates.endDate.toString()
      }
    });
  }, [timer, tenancyId, queryDates, createStatement]);

  useEffect(() => {
    if (!timer || !statementProgress.data) {
      return;
    }

    setStatementProgressId(
      statementProgress.data?.createStatementsWithSubject.statementProgresses[0]
        .id
    );
  }, [timer, statementProgress]);

  // Reset state
  useEffect(() => {
    if (timer) {
      return;
    }
    statementProgress.client.stop();
    setStatementProgressId("");
  }, [timer, statementProgress]);

  return {
    statementProgressId
  };
}

export { useStatementCreation };
