import { ClaimTenancyData } from "local/domain/bond";
import { DescriptionList } from "@ailo/ui";
import React from "react";

type Props = Pick<ClaimTenancyData, "bond">;

export function BondInfo({ bond }: Props): React.ReactElement {
  const items = [
    {
      label: "Bond reference",
      description: bond.reference
    },
    {
      label: "Bond amount",
      description: bond.amount.format()
    }
  ];

  return (
    <DescriptionList
      size={"small"}
      items={items}
      testID={"ClaimDetailsBondItems"}
    />
  );
}
