import React, { useCallback } from "react";
import {
  KeyedExcludedMigratingManagementFragment,
  PropertyOnboardingListColumn
} from "../../types";
import {
  AddressCell,
  CheckboxCell,
  CheckboxHeaderCell,
  ExcludedByCell,
  ExcludedOnCell,
  ExclusionReasonCell,
  PortfolioCell
} from "../../PropertyOnboardingListItemCell";
import { ExcludedPropertyOnboardingListColumnNames } from "./useExcludedPropertyOnboardingList";
import { ExcludedPropertyOnboardingListSelect } from "./useExcludedPropertyOnboardingListSelect";

interface Params {
  select: ExcludedPropertyOnboardingListSelect;
}

export type ExcludedPropertyOnboardingListColumns =
  PropertyOnboardingListColumn<KeyedExcludedMigratingManagementFragment>[];

export function useExcludedPropertyOnboardingListColumns({
  select: {
    selectedMigratingManagementIds,
    selectableMigratingManagementIds,
    setSelectedMigratingManagementIds
  }
}: Params): ExcludedPropertyOnboardingListColumns {
  const ExcludedCheckboxHeaderCell = useCallback(() => {
    return (
      <CheckboxHeaderCell
        disabled={selectableMigratingManagementIds.length === 0}
        value={
          selectableMigratingManagementIds.length !== 0 &&
          selectableMigratingManagementIds.length ===
            selectedMigratingManagementIds.length
        }
        onChange={(selected) => {
          setSelectedMigratingManagementIds(
            selected ? selectableMigratingManagementIds : []
          );
        }}
      />
    );
  }, [
    setSelectedMigratingManagementIds,
    selectableMigratingManagementIds,
    selectedMigratingManagementIds.length
  ]);

  const ExcludedCheckboxCell = useCallback(
    ({
      row,
      rowIndex
    }: {
      row: KeyedExcludedMigratingManagementFragment;
      rowIndex: number;
    }) => {
      return (
        <CheckboxCell
          row={row}
          rowIndex={rowIndex}
          selectedMigratingManagementIds={selectedMigratingManagementIds}
          setSelectedMigratingManagementIds={setSelectedMigratingManagementIds}
        />
      );
    },
    [setSelectedMigratingManagementIds, selectedMigratingManagementIds]
  );

  return [
    {
      maxWidth: "32px",
      renderHeaderCell: ExcludedCheckboxHeaderCell,
      renderCell: ExcludedCheckboxCell,
      renderLoadingCell: CheckboxCell.Loading,
      name: "checkbox",
      sortable: false
    },
    {
      width: 2,
      renderCell: AddressCell,
      renderLoadingCell: AddressCell.Loading,
      name: ExcludedPropertyOnboardingListColumnNames.Address,
      sortable: true,
      caretPosition: "right"
    },
    {
      width: 1,
      renderCell: PortfolioCell,
      renderLoadingCell: PortfolioCell.Loading,
      name: ExcludedPropertyOnboardingListColumnNames.Portfolio,
      sortable: true,
      caretPosition: "right"
    },
    {
      width: 1,
      renderCell: ExcludedByCell,
      renderLoadingCell: ExcludedByCell.Loading,
      name: ExcludedPropertyOnboardingListColumnNames.ExcludedBy,
      sortable: false,
      caretPosition: "right"
    },
    {
      width: 1,
      renderCell: ExcludedOnCell,
      renderLoadingCell: ExcludedOnCell.Loading,
      name: ExcludedPropertyOnboardingListColumnNames.ExcludedOn,
      sortable: true,
      caretPosition: "right"
    },
    {
      width: 1,
      renderCell: ExclusionReasonCell,
      renderLoadingCell: ExclusionReasonCell.Loading,
      name: ExcludedPropertyOnboardingListColumnNames.ExclusionReason,
      sortable: true,
      caretPosition: "right"
    }
  ];
}
