import { LocalDate } from "@ailo/date";
import { Text, Colors, Skeleton } from "@ailo/primitives";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import {
  DownloadButtonProps,
  ReportDownloadButton
} from "../ReportDownloadButton";
import { ReportTableRow } from "./ReportTable/types";

const HeadingWrapper = styled(View)`
  display: grid;
  grid-template-columns: 1fr 1fr;

  background-color: ${Colors.WHITE};
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 4px rgba(28, 30, 38, 0.2);
  border-radius: 4px;
  margin-bottom: 16px;
  min-height: 48px;
  padding-right: 16px;
`;

/**
 * formats the given date
 */
const formatHeadingDate = (
  date: string | undefined,
  formatToday: boolean = false
): string | undefined => {
  if (date === undefined) return LocalDate.today().format("D MMM YYYY");
  if (formatToday && LocalDate.from(date).isSame(LocalDate.today()))
    return "today";

  return LocalDate.from(date).format("D MMM YYYY");
};

/**
 * returns a human readable date range (eg. 1 Jan 2021 to Today)
 */
const formatRange = (
  start?: string,
  end?: string,
  formatToday: boolean = false
): string => {
  return `${formatHeadingDate(start)} to ${formatHeadingDate(
    end,
    formatToday
  )}`;
};

export const fileNameForDownload = ({
  fileNameIncludeDate,
  title,
  startDate,
  endDate
}: {
  startDate?: string;
  endDate?: string;
  title: string;
  fileNameIncludeDate?: boolean;
}): string =>
  fileNameIncludeDate
    ? `${title} - ${formatRange(startDate, endDate, false)}`
    : title;

export type ReportHeadingProps<TRow extends ReportTableRow = ReportTableRow> = {
  startDate?: string;
  endDate?: string;
  noData?: boolean;
  tableDownload?: DownloadButtonProps<TRow>;
};

export const ReportHeading = <TRow extends ReportTableRow>({
  startDate,
  endDate,
  tableDownload
}: ReportHeadingProps<TRow>): React.ReactElement => {
  const formattedDatePeriod = formatRange(startDate, endDate, true);

  return (
    <HeadingWrapper>
      <Text.BodyS
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ paddingLeft: 19 }}
      >
        {formattedDatePeriod}
      </Text.BodyS>
      {tableDownload && <ReportDownloadButton {...tableDownload} />}
    </HeadingWrapper>
  );
};

ReportHeading.Loading = function ReportHeadingLoading() {
  return (
    <HeadingWrapper style={{ paddingHorizontal: 16, alignItems: "center" }}>
      <Skeleton style={{ height: 12, width: 186 }} />
      <Skeleton style={{ height: 20, width: 120, marginLeft: "auto" }} />
    </HeadingWrapper>
  );
};
