import { formatLegalEntityName } from "@ailo/domain-helpers";
import { Colors, opacify, Skeleton, Text } from "@ailo/primitives";
import { LegalEntityAvatar, PersonAvatarProps } from "@ailo/ui";
import React from "react";
import { View, ViewStyle } from "react-native";
import styled from "styled-components/native";

interface Props {
  legalEntity: LegalEntity;
  style?: ViewStyle;
}

type LegalEntity = { id: string } & (
  | ({
      __typename: "Person";
    } & PersonAvatarProps["person"])
  | {
      __typename: "Company";
      registeredEntityName: string;
    }
);

export function LegalEntityBadge({
  legalEntity,
  style
}: Props): React.ReactElement | null {
  return (
    <Container style={style}>
      <Badge>
        <LegalEntityAvatar size={16} legalEntity={legalEntity} />
        <LegalEntityName>{formatLegalEntityName(legalEntity)}</LegalEntityName>
      </Badge>
    </Container>
  );
}

LegalEntityBadge.Loading = function LegalEntityBadgeLoading({
  style
}: {
  style?: ViewStyle;
}): React.ReactElement {
  return (
    <View style={style}>
      <Skeleton style={{ borderRadius: 8, width: 80, height: 16 }} />
    </View>
  );
};

const Container = styled(View)`
  align-items: flex-start;
`;

const Badge = styled(View)`
  flex-direction: row;
  background-color: ${opacify(Colors.CHARCOAL, 0.075)};
  border-radius: 8px;
  align-items: center;
`;

const LegalEntityName = styled(Text.BodyXS)`
  margin: 0 8px;
  line-height: 16px;
`;
