import {
  SearchTrustAccountWalletsQuery,
  TrustAccountWalletSearchResultFragment,
  TrustAccountWalletType,
  useSearchTrustAccountWalletsLazyQuery
} from "local/graphql";
import { ApolloError } from "@apollo/client";
import { WalletSearchMode } from "local/tabs";
import { useCurrentAgencyOrg } from "local/common";
import React, { useCallback, useMemo } from "react";
import { Badge, BadgeType, SearchBoxOption } from "@ailo/ui";
import { formatAddress } from "@ailo/domain-helpers";

type SearchTrustAccountWalletsCallback = {
  searchWallets: (queryString: string) => void;
  data?: SearchTrustAccountWalletsQuery;
  error?: ApolloError;
};

const PAGE_SIZE = 10;

function useGetSearchTrustAccountWalletsCallback(
  mode: WalletSearchMode
): SearchTrustAccountWalletsCallback {
  const currentOrg = useCurrentAgencyOrg();
  const currentLegalEntity = currentOrg.legalEntities[0];
  const [
    searchWallets,
    { data: searchWalletsData, error: searchWalletsError }
  ] = useSearchTrustAccountWalletsLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  const searchCallback = useCallback(
    (queryString: string): void => {
      searchWallets({
        variables: {
          organisationAilorn: currentOrg.ailoRN,
          input: {
            isTenancyWallet: mode === WalletSearchMode.TENANCY,
            trustAccountOwnerLegalEntityAilorn: currentLegalEntity.ailorn,
            query: queryString,
            paginationParams: {
              pageSize: PAGE_SIZE
            }
          }
        }
      });
    },
    [currentOrg.ailoRN, currentLegalEntity, searchWallets, mode]
  );

  return {
    searchWallets: searchCallback,
    data: searchWalletsData,
    error: searchWalletsError
  };
}

export function useTrustAccountWalletsSearch(mode: WalletSearchMode): {
  searchWallets: (queryString: string) => void;
  options?: SearchBoxOption[];
  error?: ApolloError;
  data?: SearchTrustAccountWalletsQuery;
} {
  const { searchWallets, data, error } =
    useGetSearchTrustAccountWalletsCallback(mode);

  const walletOptions = useMemo(
    () =>
      data?.searchTrustAccountWallets?.items
        ?.map(formatWalletToSearchBoxOption)
        .filter(isValidOption) || [],
    [data]
  );

  return useMemo(
    () => ({
      searchWallets,
      error,
      options: walletOptions,
      data
    }),
    [walletOptions, data, error, searchWallets]
  );
}

function isValidOption(option?: { value?: string; label?: string }): boolean {
  return !!(option?.label?.trim() && option?.value?.trim());
}

function matchType(type: TrustAccountWalletType): BadgeType {
  switch (type) {
    case TrustAccountWalletType.Agency:
    case TrustAccountWalletType.BondAuthority:
      return "agency_wallet";
    case TrustAccountWalletType.Investor:
    case TrustAccountWalletType.ManagementFolio:
      return "investor_wallet";
    case TrustAccountWalletType.Renter:
    case TrustAccountWalletType.Tenancy:
      return "tenant_wallet";
    case TrustAccountWalletType.Supplier:
      return "supplier_wallet";
    default:
      return "default";
  }
}

function mapEntityToDisplayName(
  entity: TrustAccountWalletSearchResultFragment["entity"]
): string {
  if (entity.__typename === "ManagementFolio" && entity.owners) {
    return entity.owners
      ?.map((entity) => {
        return entity.owner?.contact?.displayName;
      })
      .join(", ");
  } else if (entity.__typename === "Tenancy" && entity.tenantContacts) {
    return entity.tenantContacts
      .map((contact) => {
        return contact.displayName;
      })
      .join(", ");
  } else if (entity.__typename === "Supplier") {
    return entity.name!!;
  } else if (entity.__typename === "BondAuthority") {
    return entity.userFacingName;
  } else if (entity.__typename === "LegalEntityCompanion") {
    return entity.legalEntity.contact?.displayName!!;
  } else return "this item cannot be displayed";
}

function mapDescription(
  entity: TrustAccountWalletSearchResultFragment["entity"]
): string | undefined {
  if (entity.__typename === "Tenancy") {
    return formatAddress(entity.property.address, {
      format: "street, suburb"
    });
  }
}

function formatWalletToSearchBoxOption(
  wallet: TrustAccountWalletSearchResultFragment
): SearchBoxOption & {
  RightComponent: React.ReactNode;
} {
  return {
    label: mapEntityToDisplayName(wallet.entity),
    value: wallet.entityReference,
    size: "full",
    description: mapDescription(wallet.entity),
    RightComponent: (
      <Badge type={matchType(wallet.type)}>{wallet.code}</Badge>
    ) as React.ReactNode
  };
}
