import {
  ActionType,
  ActionFragment,
  AssignFormActionMetaFragment,
  AssignRentActionMetaFragment,
  AssignTenancyActionMetaFragment,
  AssignTenancyAgreementActionMetaFragment,
  RequestSignatureActionMetaFragment,
  TrackDocusignActionMetaFragment,
  ManageDepositActionMetaFragment,
  AssignNewManagementActionMetaFragment,
  GenerateInspectionReportActionMetaFragment,
  TrackInspectionCompletedActionMetaFragment,
  EndTenancyActionMetaFragment,
  ConfirmInspectionScheduleActionMetaFragment,
  AssignMessageActionMetaFragment
} from "local/graphql";

export type Action<Type extends ActionType = ActionType> = ActionFragment & {
  type: Type;
  meta?: ActionMeta[Type] | null;
};

export type ActionMeta = { [key in ActionType]: {} } & {
  [ActionType.Reminder]: {};
  [ActionType.AssignTenancyAgreement]: AssignTenancyAgreementActionMetaFragment;
  [ActionType.AssignNewManagement]: AssignNewManagementActionMetaFragment;
  [ActionType.AssignRent]: AssignRentActionMetaFragment;
  [ActionType.AssignTenancy]: AssignTenancyActionMetaFragment;
  [ActionType.AssignForm]: AssignFormActionMetaFragment;
  [ActionType.RequestSignature]: RequestSignatureActionMetaFragment;
  [ActionType.TrackDocusign]: TrackDocusignActionMetaFragment;
  [ActionType.ManageDeposit]: ManageDepositActionMetaFragment;
  [ActionType.GenerateInspectionReport]: GenerateInspectionReportActionMetaFragment;
  [ActionType.TrackInspectionCompleted]: TrackInspectionCompletedActionMetaFragment;
  [ActionType.EndTenancy]: EndTenancyActionMetaFragment;
  [ActionType.ConfirmInspectionSchedule]: ConfirmInspectionScheduleActionMetaFragment;
  [ActionType.AssignMessage]: AssignMessageActionMetaFragment;
};

export function isActionOfTypeWithMeta<Type extends ActionType>(
  action: Action,
  type: Type
): action is Action<Type> & { meta: ActionMeta[Type] } {
  return action.type === type && !!action.meta;
}
