import { Colors, Text } from "@ailo/primitives";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { ScheduledInspectionsAssigneeFilter } from "./ScheduledInspectionsAssigneeFilter";
import { InspectionMonthList } from "./InspectionMonthList";

export function InspectionAppointmentList(): React.ReactElement {
  return (
    <Container>
      <View style={{ padding: 32 }}>
        <Text.BodyL style={{ marginBottom: 32 }} weight={"medium"}>
          {"Schedule"}
        </Text.BodyL>
        <ScheduledInspectionsAssigneeFilter />
      </View>
      <InspectionMonthList />
    </Container>
  );
}

const Container = styled(View)`
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: ${Colors.GRAYSCALE.OUTLINE};
  width: 360px;
  overflow: auto;
`;
