import { useCallback, useMemo } from "react";
import { useAnalytics, uuid } from "@ailo/services";
import { ActionDetailsFragment } from "local/graphql";
import { startCase } from "lodash";
import { ActionFormData } from "../ActionFormData";

interface UseUpdateActionAnalytics {
  onFormOpened: () => void;
  onValueUpdated: (
    fieldName: string,
    isValid: boolean,
    formValues: ActionFormData
  ) => void;
  onSubmitSuccess: (
    action: ActionDetailsFragment,
    initialValues: ActionFormData,
    formValues: ActionFormData
  ) => void;
}

export function useUpdateActionAnalytics(
  projectId: string
): UseUpdateActionAnalytics {
  const formSessionId = useMemo(() => uuid(), []);
  const analytics = useAnalytics();

  const onFormOpened = useCallback(() => {
    analytics.trackScreenVisited("Update Project Action", {
      form_session_id: formSessionId,
      project_id: projectId
    });
  }, [analytics, formSessionId, projectId]);

  const onValueUpdated = useCallback(
    (fieldName: string, isValid, formValues: ActionFormData) => {
      analytics.track("Form Step Completed", {
        form_name: "Update Project Action",
        form_session_id: formSessionId,
        step_name: startCase(fieldName),
        value_is_valid: isValid ? "Yes" : "No",
        steps_completed_this_far: formValuesToStepsCompleted(formValues),
        project_id: projectId
      });
    },
    [analytics, formSessionId, projectId]
  );

  const onSubmitSuccess = useCallback(
    (
      action: ActionDetailsFragment,
      initialValues: ActionFormData,
      formValues: ActionFormData
    ) => {
      analytics.track("Project Action Updated", {
        project_id: projectId,
        action_id: action.id,
        fields_updated: getUpdatedFields(initialValues, formValues)
      });
    },
    [analytics, projectId]
  );

  return {
    onFormOpened,
    onValueUpdated,
    onSubmitSuccess
  };
}

function formValuesToStepsCompleted(formValues: ActionFormData): string[] {
  return Object.keys(formValues)
    .filter((key) => !!formValues[key as keyof ActionFormData])
    .map(startCase);
}

function getUpdatedFields(
  initialValues: ActionFormData,
  submittedValues: ActionFormData
): string[] {
  return Object.keys(submittedValues)
    .filter(
      (key) =>
        initialValues[key as keyof ActionFormData] ===
        submittedValues[key as keyof ActionFormData]
    )
    .map(startCase);
}
