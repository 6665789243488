import React, { ReactElement, useCallback, useState } from "react";
import styled from "styled-components/native";
import { Button, ConfirmModal, useToastContext } from "@ailo/ui";
import { PublishMigratingManagementMutation } from "local/graphql";
import { usePublishMigratingManagement } from "../../hooks";
import { Text } from "@ailo/primitives";

interface Props {
  migratingManagementId: string;
  closeSidebar: () => void;
  buttonText: string;
}

export function PublishPropertyButton({
  migratingManagementId,
  closeSidebar,
  buttonText
}: Props): ReactElement {
  const [modalVisible, setModalVisible] = useState(false);
  const toast = useToastContext();

  const onError = useCallback(() => {
    toast.show({
      type: "error",
      message: "Unable to set property live. Please try again"
    });
  }, [toast]);

  const onSuccess = useCallback(
    (data: PublishMigratingManagementMutation) => {
      const successes = data.publishMigratingManagements?.successes ?? [];
      if (successes.length === 0) {
        toast.show({
          type: "error",
          message: "Unable to set property live. Please try again"
        });
        return;
      }

      closeSidebar();
      toast.show({
        type: "success",
        message: "Property will be set live"
      });
    },
    [toast, closeSidebar]
  );

  const [publishMigratingManagement, { loading }] =
    usePublishMigratingManagement({
      migratingManagementId,
      onSuccess,
      onError
    });

  const onModalConfirm = useCallback((): void => {
    publishMigratingManagement();
    setModalVisible(false);
  }, [publishMigratingManagement]);

  return (
    <>
      <StyledPrimaryButton
        onPress={() => setModalVisible(true)}
        loading={loading}
      >
        {buttonText}
      </StyledPrimaryButton>

      <ConfirmModal
        visible={modalVisible}
        title={"Set Property Live"}
        onConfirm={onModalConfirm}
        onCancel={() => setModalVisible(false)}
      >
        <Text.BodyM>
          {
            "1 property will be set live and the renters and investors will be automatically invited to Ailo."
          }
        </Text.BodyM>
      </ConfirmModal>
    </>
  );
}

const StyledPrimaryButton = styled(Button.Primary)`
  flex: 1;
`;
