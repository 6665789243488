import React, { useCallback, useRef } from "react";
import { Text } from "@ailo/primitives";
import { ConfirmModal, useToastContext } from "@ailo/ui";
import { useAnalytics } from "local/common";
import { AiloRN, services } from "@ailo/ailorn";
import {
  TeamManagementDetails,
  useMoveManagementToTeam
} from "./useMoveManagementToTeam";

interface ModalData {
  managementId: string;
  managementName: string;
  teamId: string;
  teamName: string;
}

interface Props {
  data?: ModalData;
  onDismiss?: () => void;
}

export function MoveManagementToTeamConfirmModal({
  data,
  onDismiss
}: Props): React.ReactElement {
  const analytics = useAnalytics();
  const toast = useToastContext();
  const messageRef = useRef("");

  const onSuccessMoveManagementToTeam = useCallback(
    ({ teamId, teamName, managementId }: TeamManagementDetails): void => {
      toast.show({
        type: "success",
        message: `Property moved to ${teamName}`
      });
      analytics.trackPropertyMoveToTeam({
        managementId: AiloRN.of(
          services.PropertyManagement.management,
          managementId
        ).toString(),
        teamId
      });
      onDismiss?.();
    },
    [analytics, onDismiss, toast]
  );

  const onFailMoveManagementToTeam = useCallback((): void => {
    toast.show({
      type: "error",
      message: "Unable to move property. Please try again."
    });
    onDismiss?.();
  }, [onDismiss, toast]);

  const { moveManagementToTeam, loading } = useMoveManagementToTeam({
    onSuccess: onSuccessMoveManagementToTeam,
    onFail: onFailMoveManagementToTeam
  });

  const submitRequest = useCallback(() => {
    if (loading) return;

    if (!data) return;

    moveManagementToTeam(data);
  }, [data, moveManagementToTeam, loading]);

  if (data) {
    messageRef.current = `${data.managementName} will be moved to ${data.teamName}.`;
  }

  return (
    <ConfirmModal
      visible={!!data}
      title={"Move teams"}
      onConfirm={submitRequest}
      onCancel={onDismiss}
    >
      <Text.BodyM weight={"book"}>{messageRef.current}</Text.BodyM>
    </ConfirmModal>
  );
}
