import { AiloRN } from "@ailo/ailorn";
import { Colors, opacify } from "@ailo/primitives";
import { useActionEventContext, useOnFocus } from "@ailo/services";
import { AlertScreen, ErrorAlertScreen, SectionHeader } from "@ailo/ui";
import React, { ReactElement, useCallback, useEffect } from "react";
import {
  SectionList,
  SectionListRenderItem,
  StyleProp,
  View,
  ViewStyle
} from "react-native";
import {
  EmptyPaidExpensesSection,
  EmptyUpcomingExpensesSection
} from "./EmptySections";
import { ExpensesListItem } from "./ExpensesList/ExpensesListItem";
import { Expense, useGetUpcomingAndPaidBillsAndFees } from "./graphql";

interface ExpensesSectionListProps {
  payerAilorn: AiloRN;
  itemsLimit?: number;
  onExpensePress?: (ailorn: AiloRN) => void;
  style?: StyleProp<ViewStyle>;
}

export function ExpensesSectionList({
  payerAilorn,
  itemsLimit = 1000,
  onExpensePress,
  style
}: ExpensesSectionListProps): ReactElement {
  const { upcomingExpenses, paidExpenses, loading, refetch, error } =
    useGetUpcomingAndPaidBillsAndFees({
      payerAilorn,
      pageSize: itemsLimit
    });

  useOnFocus(refetch);

  const actionEventContext = useActionEventContext();
  useEffect(() => {
    return actionEventContext.subscribe((event) => {
      if (event.type === "BillCreated") {
        // Bill will be visible only after Bill Service -> Ledger Service -> Bill Service
        // events are handled. We need to wait. And it needs to work with 100% guarantee
        // for e2e tests (if we ever write it). Thus, let's refetch it after 4s (should work in ~90% of cases),
        // and again after 12s (should be ok in the other cases).
        setTimeout(refetch, 4000);
        setTimeout(refetch, 12000);
      }
    });
  }, [actionEventContext, refetch]);

  const renderItem: SectionListRenderItem<
    typeof sections[0]["data"][0],
    typeof sections[0]
  > = useCallback(
    ({ item, section, index }): ReactElement => {
      if ("placeholder" in item) return item.placeholder;
      return (
        <ExpensesListItem
          expense={item}
          onExpensePress={() =>
            onExpensePress?.(AiloRN.fromString(item.ailorn))
          }
          style={{
            borderType: index === section.data.length - 1 ? "none" : undefined
          }}
        />
      );
    },
    [onExpensePress]
  );

  if (loading) {
    return <ExpensesSectionList.Loading />;
  }

  if (error) {
    return (
      <ErrorAlertScreen
        title={"There was a problem loading Expenses"}
        onRetry={refetch}
        variant={"sidebar"}
      />
    );
  }

  const expenses = [...upcomingExpenses, ...paidExpenses];

  if (!expenses.length) {
    return <ExpensesSectionList.Empty payerAilorn={payerAilorn} />;
  }

  // SectionList doesn't support an api to render something if sections[].data is empty
  // so insert a placeholder into sections[].data as a workaround
  // https://github.com/facebook/react-native/issues/14721#issuecomment-340820024
  const sections: Array<{
    title: string;
    titleRight: string;
    type: "upcoming" | "paid";
    data: Array<Expense | { placeholder: ReactElement }>;
  }> = [
    {
      title: "Outstanding",
      titleRight: "By Payment Priority",
      type: "upcoming",
      data: upcomingExpenses.length
        ? upcomingExpenses
        : [{ placeholder: EmptyUpcomingExpensesSection }]
    },
    {
      title: "Settled",
      titleRight: "By Issue Date",
      type: "paid",
      data: paidExpenses.length
        ? paidExpenses
        : [{ placeholder: EmptyPaidExpensesSection }]
    }
  ];

  return (
    <SectionList
      style={style}
      sections={sections}
      renderItem={renderItem}
      renderSectionHeader={({
        section: { title, titleRight }
      }): ReactElement => {
        return <SectionHeader title={title} titleRight={titleRight} />;
      }}
      stickySectionHeadersEnabled={true}
      keyExtractor={(item): string =>
        "placeholder" in item ? "placeholder" : item.ailorn
      }
    />
  );
}

ExpensesSectionList.Loading = function Loading({
  itemsLimit = 3,
  style
}: {
  itemsLimit?: number;
  style?: StyleProp<ViewStyle>;
}): ReactElement {
  const listItems = [];
  for (let i = 0; i < itemsLimit; i++) {
    const color = opacify(
      Colors.CHARCOAL,
      0.75 * ((itemsLimit - i) / itemsLimit)
    );
    listItems.push(
      <ExpensesListItem.Loading
        key={i}
        color={color}
        style={{ borderType: "none" }}
      />
    );
  }

  return <View style={style}>{listItems}</View>;
};

ExpensesSectionList.Empty = function Empty({
  payerAilorn
}: Pick<ExpensesSectionListProps, "payerAilorn">): ReactElement {
  return (
    <AlertScreen
      type={"plant-and-house-and-document"}
      description={`No expenses have been added yet for this ${payerAilorn.entity}`}
    />
  );
};
