import { useCurrentUser } from "@ailo/domains";
import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import { useCurrentAgencyOrg } from "local/common";
import {
  ReiTokenBaseFragment,
  useGetCurrentOrgReiTokensQuery
} from "local/graphql";

export function useGetReiTokens(): MappedQueryResult<ReiTokenBaseFragment[]> {
  const {
    person: { ailoRN: personAilorn }
  } = useCurrentUser();
  const { ailoRN: organisationAilorn } = useCurrentAgencyOrg();
  return useMappedQueryResult(
    useGetCurrentOrgReiTokensQuery({
      variables: {
        personAilorn,
        organisationAilorn
      },
      fetchPolicy: "cache-and-network"
    }),
    (data) => {
      if (data.legalEntity?.__typename !== "Person") {
        throw new ApolloError({
          errorMessage: "LegalEntity is not of type Person"
        });
      }
      const reiTokensForOrg = data.legalEntity.reiTokensForOrg;
      return reiTokensForOrg ? reiTokensForOrg : [];
    }
  );
}
