import React, { ReactElement, PropsWithChildren } from "react";
import { ActionActivityProps } from "./ActionActivityProps";
import { ActionStatus } from "local/graphql";
import { BaseActionActivity } from "./BaseActionActivity";
import { useActionStatusContext } from "../../ActionStatusContext";

export function ReminderActionActivity({
  action,
  children
}: PropsWithChildren<ActionActivityProps>): ReactElement {
  const { handleActionStatusChange } = useActionStatusContext();

  if (action.status === ActionStatus.Done) {
    return (
      <BaseActionActivity
        description={"Have you completed this action?"}
        button={{
          label: "Mark as incomplete",
          onPress: handleActionStatusChange,
          buttonVariant: "secondary"
        }}
        footer={children}
      />
    );
  }

  return (
    <BaseActionActivity
      description={"Have you completed this action?"}
      button={{
        label: "Mark as done",
        onPress: handleActionStatusChange
      }}
      footer={children}
    />
  );
}
