import { GridSortOrder } from "@ailo/ui";
import { lowerCase, startCase } from "lodash";
import React from "react";
import { BasePropertyList, BasePropertyListProps } from "./BasePropertyList";
import { createCellRenderer } from "./createCellRenderer";
import { usePropertyListFilters } from "./filters";
import { useDefaultPropertyListQuery, usePropertyListReorder } from "./hooks";
import { AddressCell, OccupancyStatusCell, TeamCell } from "./PropertyItemCell";

function DefaultPropertyList({
  tabNavigation
}: {
  tabNavigation: React.ReactElement;
}): React.ReactElement {
  const { filterElements, filterState, isAnyFilterActive } =
    usePropertyListFilters();
  const queryData = useDefaultPropertyListQuery(initialSortOrder, filterState);

  const columns: BasePropertyListProps["columns"] = [
    {
      width: 38,
      renderCell: addressCellRenderer,
      renderLoadingCell: AddressCell.Loading,
      name: PropertyListColumns.Properties,
      sortable: true
    },
    {
      width: 38,
      renderCell: occupancyStatusCellRenderer,
      renderLoadingCell: OccupancyStatusCell.Loading,
      name: PropertyListColumns.Status,
      sortable: false
    },
    {
      width: 20,
      renderCell: teamCellRenderer,
      renderLoadingCell: TeamCell.Loading,
      name: PropertyListColumns.Teams,
      verticalAlign: "middle",
      caretPosition: "right",
      sortable: true
    }
  ];

  const { sortOrder, onSortOrderChange } = usePropertyListReorder(
    initialSortOrder,
    queryData.requestFromBeginning,
    analyticsInfo
  );

  return (
    <BasePropertyList
      tabNavigation={tabNavigation}
      filterElements={filterElements}
      isAnyFilterActive={isAnyFilterActive}
      queryData={queryData}
      errorMessage={"There was a problem loading Properties"}
      emptyMessage={"No properties"}
      secondaryEmptyMessage={
        "A list of all your properties will be shown here."
      }
      sortOrder={sortOrder}
      columns={columns}
      onSortOrderChange={onSortOrderChange}
    />
  );
}

const addressCellRenderer = createCellRenderer(AddressCell);
const occupancyStatusCellRenderer = createCellRenderer(OccupancyStatusCell);
const teamCellRenderer = createCellRenderer(TeamCell);

export enum PropertyListColumns {
  Properties = "Properties",
  Status = "Status",
  Teams = "Team",
  Arrears = "Paid to"
}

const initialSortOrder: GridSortOrder = {
  columnKey: PropertyListColumns.Properties,
  direction: "ASC"
};

function analyticsColName(columnKey: string): string {
  if (columnKey === PropertyListColumns.Arrears) return "Arrears";
  if (columnKey === PropertyListColumns.Properties) return "Address";
  if (columnKey === PropertyListColumns.Teams) return "Team Name";
  throw new Error(`Unexpected column to return analytics: ${columnKey}`);
}

function analyticsInfo(sortOrder: GridSortOrder): {
  eventName: string;
  orderBy: string;
} {
  return {
    eventName: "Property List Reordered",
    orderBy: `${analyticsColName(sortOrder.columnKey)} ${startCase(
      lowerCase(sortOrder.direction)
    )}`
  };
}

export { DefaultPropertyList };
