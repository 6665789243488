import { Text } from "@ailo/primitives";
import { CheckboxInput, CollapsibleSection, FormFieldRow } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";
import { useRentReviewForm } from "../RentReviewFormContext";
import { RentReviewFields } from "../RentReviewFormData";
import { EffectiveDateInput } from "./EffectiveDateInput";
import { LastRentReviewInfoAlert } from "./LastRentReviewInfoAlert";
import { PreviewPlansSection } from "./PreviewPlansSection";
import { RentReviewAmountInput } from "./RentReviewAmountInput";
import { ScheduledRentReviewDateInput } from "./ScheduledRentReviewDateInput";

interface Props {
  style?: StyleProp<ViewStyle>;
}

export function RentReviewSection({ style }: Props): ReactElement {
  const { control, data } = useRentReviewForm();

  return (
    <View style={style}>
      {data.lastRentReview && (
        <LastRentReviewInfoAlert
          lastRentReview={data.lastRentReview}
          style={{ marginBottom: 20 }}
        />
      )}
      <View>
        <FormFieldRow>
          <RentReviewAmountInput />
          <EffectiveDateInput />
        </FormFieldRow>
        <PreviewPlansSection style={{ marginTop: 16 }} />
        <ScheduledRentReviewDateInput style={{ marginTop: 20 }} />
      </View>
      <Text.BodyL weight={"medium"} style={{ marginTop: 32 }}>
        {"Lease Renewal"}
      </Text.BodyL>
      <Controller
        name={RentReviewFields.leaseRenewalChecked}
        control={control}
        defaultValue={false}
        render={({ value, onChange }) => (
          <CheckboxInput
            disabled={!data.createLeaseReviewAllowed}
            label={"Add a lease renewal after completing the rent review"}
            style={{ marginTop: 26 }}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </View>
  );
}

RentReviewSection.Loading = function Loading(): ReactElement {
  return <CollapsibleSection.Loading titleWidth={90} />;
};
