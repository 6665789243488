import { useSynchronisedLoad } from "@ailo/primitives";
import {
  useGetPropertyOccupancyHealthQuery,
  OccupancyStatus
} from "local/graphql";
import React, { FC } from "react";
import { OccupiedDetails, VacantDetails, VacatingDetails } from "./components";

interface Props {
  managementId: string;
}

export const OccupancyHealthSectionBody: FC<Props> = ({ managementId }) => {
  const { data, loading } = useGetPropertyOccupancyHealthQuery({
    variables: { managementId }
  });

  const isLoading = useSynchronisedLoad(loading && !data);

  if (isLoading) return null;

  const management = data?.management;

  if (!management)
    throw new Error(
      `[Property Occupancy Health] Management "${managementId}" not found`
    );

  const { mostRecentTenancy, occupancyStatus } = management;

  if (!occupancyStatus)
    throw new Error(
      `[Property Occupancy Health] Management "${managementId}" has no occupancy status`
    );

  const tenancyEndDate = mostRecentTenancy?.endDate;
  const mostRecentTenancyAgreement =
    mostRecentTenancy?.mostRecentTenancyAgreement;
  const agreementStartDate = mostRecentTenancyAgreement?.startDate;
  const fixedTermAgreementEndDate =
    mostRecentTenancyAgreement?.fixedTermEndDate;
  const agreementAllowedToLapse =
    !!mostRecentTenancyAgreement?.allowedToLapseAt;
  const nextAgreementStartDate =
    mostRecentTenancy?.nextTenancyAgreement?.startDate;
  const newTenancyStartDate = management.nextTenancy?.startDate;
  const routineInspectionDueDate = mostRecentTenancy?.routineInspectionDueDate;

  switch (occupancyStatus) {
    case OccupancyStatus.Vacant:
      return (
        <VacantDetails
          managementStartDate={management.startDate}
          tenancyEndDate={tenancyEndDate}
          newTenancyStartDate={newTenancyStartDate}
          agreementStartDate={agreementStartDate}
          fixedTermAgreementEndDate={fixedTermAgreementEndDate}
          agreementAllowedToLapse={agreementAllowedToLapse}
          nextAgreementStartDate={nextAgreementStartDate}
          isGeneratingIncome={!!mostRecentTenancy?.currentRentSchedule}
        />
      );
    case OccupancyStatus.Vacating:
      return (
        <VacatingDetails
          tenancyEndDate={tenancyEndDate}
          agreementStartDate={agreementStartDate}
          fixedTermAgreementEndDate={fixedTermAgreementEndDate}
          agreementAllowedToLapse={agreementAllowedToLapse}
          nextAgreementStartDate={nextAgreementStartDate}
          newTenancyStartDate={newTenancyStartDate}
        />
      );
    case OccupancyStatus.Occupied:
      return (
        <OccupiedDetails
          startDate={mostRecentTenancy?.startDate}
          agreementStartDate={agreementStartDate}
          fixedTermAgreementEndDate={fixedTermAgreementEndDate}
          agreementAllowedToLapse={agreementAllowedToLapse}
          nextAgreementStartDate={nextAgreementStartDate}
          routineInspectionDueDate={routineInspectionDueDate}
        />
      );
    default:
      throw new Error(
        `[Property Occupancy Health] Unknown occupancy status "${occupancyStatus}" in Management "${managementId}"`
      );
  }
};
