import { Colors, Link, Text } from "@ailo/primitives";
import { Screens, useNavigation } from "local/common";
import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

export function LinkReiAccountSuggestion({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): ReactElement {
  const navigation = useNavigation<Screens.SettingsTab>();
  const navigateToFormSettings = (): void => {
    navigation.navigate(Screens.AppNavigator, {
      screen: Screens.SettingsTab,
      params: {
        tab: "forms"
      }
    });
  };

  return (
    <View style={style}>
      <Text.BodyS weight={"medium"} color={Colors.TEXT.DARK.SECONDARY}>
        {
          "Did you know you can seamlessly send forms and agreements to customers by  "
        }
        <Link variant={"primary"} onPress={navigateToFormSettings}>
          {"connecting a forms account"}
        </Link>
        {
          " to Ailo. Accounts with DocuSign can request remote digital signing (availability and selection of forms depends on legislation in your state)."
        }
      </Text.BodyS>
    </View>
  );
}
