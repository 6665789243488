import { DateTimeWithTimeZone } from "@ailo/ui";
import { AnalyticsService, FunctionArguments } from "local/common";
import { useState } from "react";
import { useEffect, useCallback } from "reactn";

// Timer is a key to know download process is happening/stopped or timeout
// On CLOSE/RESET case, Timer will be reset
type Timer = ReturnType<typeof setTimeout> | undefined;
export type TrackInput = FunctionArguments<AnalyticsService["track"]>;

interface UseTimerOutput {
  timer: Timer;
  resetTimer: () => void;
  isTimeout: boolean;
  downloadTimeOutInMs: number;
}

function useTimer(
  startTimer: boolean,
  downloadTimeOutInMs: number,
  onTimeOut?: (startTime: DateTimeWithTimeZone) => void
): UseTimerOutput {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const [isTimeout, setIsTimeout] = useState(false);

  const resetTimer = useCallback(() => {
    setTimer(undefined);
    setIsTimeout(false);
  }, []);

  useEffect(() => {
    if (!startTimer) return;

    const startTime = DateTimeWithTimeZone.now();
    const currentTimer = setTimeout(() => {
      setIsTimeout(true);
      setTimer(undefined);
      onTimeOut?.(startTime);
    }, downloadTimeOutInMs);

    setTimer(currentTimer);

    return () => {
      if (currentTimer) {
        clearTimeout(currentTimer);
      }
      resetTimer();
    };
  }, [downloadTimeOutInMs, resetTimer, startTimer, onTimeOut]);

  return {
    timer,
    resetTimer,
    isTimeout,
    downloadTimeOutInMs
  };
}

export { useTimer, Timer, UseTimerOutput };
