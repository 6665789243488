import { AiloRN, services } from "@ailo/ailorn";
import { useHasFeature } from "@ailo/domains";
import { RecoverableError, ScreenComponent } from "@ailo/services";
import { Button, SidebarCard, SidebarContent } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import { useGetManagementPropertyKeys } from "local/domain/propertyManagement/hooks/useGetManagementPropertyKeys";
import { PlatformFeatureId, KeyFragment } from "local/graphql";
import React, { ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { EmptyKeysList } from "./EmptyKeysList";
import { KeyCard } from "./KeyCard";
import { PropertyKeyModal } from "./PropertyKeyModal";
import { useKeyModalContext } from "./PropertyKeyModalContext";
import { ViewKeysSidebarErrorBoundaryFallback } from "./ViewKeysSidebarErrorBoundaryFallback";

interface ViewKeysSidebarProps {
  managementId: string;
  onClose(): void;
  style?: StyleProp<ViewStyle>;
}

export function ViewKeysSidebar({
  managementId,
  onClose
}: ViewKeysSidebarProps): ReactElement {
  return (
    <ScreenComponent
      name={"Management Property Keys"}
      analytics={false}
      errorBoundaryOptions={{
        fallbackComponent: ViewKeysSidebarErrorBoundaryFallback(onClose)
      }}
    >
      <KeysSidebardContent managementId={managementId} onClose={onClose} />
    </ScreenComponent>
  );
}

function KeysSidebardContent({
  managementId,
  onClose
}: ViewKeysSidebarProps): ReactElement {
  const { ailoRN: organisationAilornString } = useCurrentAgencyOrg();
  const { data, loading, error, refetch } = useGetManagementPropertyKeys(
    managementId,
    organisationAilornString
  );

  if (loading && !data) {
    return (
      <SidebarContent
        title={"Keys"}
        titleRightButtons={<AddKeysButtonLoading />}
        onClose={onClose}
      >
        <SidebarCard.Loading />
      </SidebarContent>
    );
  }

  if (error || !data) {
    throw new RecoverableError("There was a problem loading Keys", refetch);
  }

  const { propertyAilorn: propertyAilornString, keys } = data;

  return (
    <PropertyKeyModal
      propertyAilornString={propertyAilornString}
      organisationAilornString={organisationAilornString}
      managementAilornString={AiloRN.of(
        services.PropertyManagement.management,
        managementId
      ).toString()}
    >
      <SidebarContent
        title={"Keys"}
        titleRightButtons={<AddKeysButton />}
        onClose={onClose}
      >
        <KeysSidebarContentItems keys={keys} />
      </SidebarContent>
    </PropertyKeyModal>
  );
}

function KeysSidebarContentItems({
  keys
}: {
  keys: KeyFragment[];
}): ReactElement {
  const isEditPropertyKeysEnabled = useHasFeature(
    PlatformFeatureId.EditPropertyKeys
  );

  const isDeletePropertyKeysEnabled = useHasFeature(
    PlatformFeatureId.DeletePropertyKeys
  );

  const { openKeyModal } = useKeyModalContext();

  if (!keys.length) {
    return <EmptyKeysList />;
  }

  return (
    <>
      {keys.map((key) => (
        <KeyCard
          propertyKey={key}
          key={key.id}
          onEdit={
            isEditPropertyKeysEnabled
              ? () =>
                  openKeyModal({
                    mode: "edit",
                    selectedKey: key,
                    title: "Edit keys"
                  })
              : undefined
          }
          onDelete={
            isDeletePropertyKeysEnabled
              ? () =>
                  openKeyModal({
                    mode: "delete",
                    selectedKey: key,
                    title: `Remove key ${key.code}?`
                  })
              : undefined
          }
        />
      ))}
    </>
  );
}

function AddKeysButtonLoading(): React.ReactElement {
  return (
    <Button.Loading type={"small"} style={{ height: 32 }}>
      {"Add keys"}
    </Button.Loading>
  );
}

function AddKeysButton(): React.ReactElement {
  const { openKeyModal } = useKeyModalContext();

  return (
    <Button.Secondary
      type={"small"}
      style={{ height: 32 }}
      onPress={() => openKeyModal({ mode: "create", title: "Add keys" })}
    >
      {"Add keys"}
    </Button.Secondary>
  );
}
