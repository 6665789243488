import React, { ComponentProps } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Text } from "@ailo/primitives";
import { DescriptionList } from "@ailo/ui";

interface Props {
  title: string;
  descriptionListItems: ComponentProps<typeof DescriptionList>["items"];
  style?: StyleProp<ViewStyle>;
}

export function Section({
  style,
  title,
  descriptionListItems
}: Props): React.ReactElement {
  return (
    <View style={style}>
      <Text.BodyL weight={"medium"} style={{ marginBottom: 16 }}>
        {title}
      </Text.BodyL>
      <DescriptionList
        size={"small"}
        items={descriptionListItems}
        testID={"fees-details-section"}
      />
    </View>
  );
}
