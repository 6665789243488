import React, { ReactElement } from "react";
import { ActionType } from "local/graphql";
import { GenerateInspectionReportActionCardBase } from "./GenerateInspectionReportActionCardBase";
import { RefinedActionActivityProps } from "local/tabs/projects/actions";
import { GenericActionCard } from "../GenericActionCard";

type Props = RefinedActionActivityProps<ActionType.GenerateInspectionReport> & {
  tenancyId?: string;
};

export function GenerateInspectionReportActionCard({
  projectId,
  action
}: Props): ReactElement {
  const { inspectionAppointment } = action.meta;

  if (!inspectionAppointment || !inspectionAppointment.inspection) {
    return <GenericActionCard projectId={projectId} action={action} />;
  }

  const { inspection } = inspectionAppointment;

  return (
    <GenerateInspectionReportActionCardBase
      action={action}
      projectId={projectId}
      inspectionAilorn={inspection.ailorn}
    />
  );
}
