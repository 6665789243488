import { Colors } from "@ailo/primitives";
import { View } from "react-native";
import styled from "styled-components/native";

export const AllReportsWrapper = styled(View)<{ narrowWidth?: boolean }>`
  margin: 0px;
  gap: 12px;
`;

export const ReportSectionWrapper = styled(View)`
  display: grid;
  gap: 12px 12px;
  grid-template-columns: repeat(2, 1fr);
`;

export const BackgroundColor = styled(View)<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const ReportSectionwWrapper = styled(View)<{ isLastRow: boolean }>`
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  flex-direction: row;
  border-color: ${Colors.GRAYSCALE.OUTLINE};
  border-style: solid;
  justify-content: flex-start;
  border-bottom-width: ${({ isLastRow }): number => (isLastRow ? 0 : 1)}px;
`;

export const getBackgroundColor = ({
  hovered,
  pressed
}: {
  hovered?: boolean;
  pressed?: boolean;
}): string => {
  if (pressed) {
    return Colors.INTERACTION.WHITE.PRESSED;
  }
  if (hovered) {
    return Colors.INTERACTION.WHITE.HOVER;
  } else {
    return Colors.TRANSPARENT;
  }
};

export const PopularReportWrapper = styled(View)`
  padding-top: 20px;
  padding-bottom: 40px;
  margin-top: 0px;
  margin: 0px;
  gap: 0px 12px;
  flex: 1;
  flex-direction: row;
  min-height: fit-content;
`;
