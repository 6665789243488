import { useCallback, useState } from "react";
import { OccupancyStatus } from "local/graphql";

export type PropertyListFilterStateType = {
  occupancyStatuses?: OccupancyStatus[] | null;
};

export type UpdatePropertyFilterStateCallback = (
  mergeState: PropertyListFilterStateType
) => void;

export function usePropertyListFilterState(): {
  filterState: PropertyListFilterStateType;
  updateFilterState: UpdatePropertyFilterStateCallback;
} {
  const [filterState, setFilterState] = useState<PropertyListFilterStateType>({
    occupancyStatuses: null
  });

  const updateFilterState = useCallback<UpdatePropertyFilterStateCallback>(
    (mergeState) => {
      setFilterState({ ...filterState, ...mergeState });
    },
    [filterState, setFilterState]
  );

  return { filterState, updateFilterState };
}
