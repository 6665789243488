import { DownCaretIcon } from "@ailo/primitives";
import {
  Button,
  DropdownMenu,
  DropdownMenuOptionGroup,
  DropdownMenuToggleProps,
  useGlobalModal
} from "@ailo/ui";
import { EndTenancyDetailsFragment } from "local/graphql";
import { EndTenancyModalForm } from "local/modals/EndTenancyModalForm";

import {
  useGetStopVacatingOption,
  getEditEndTenancyOption
} from "local/domain/propertyManagement";

import React, { useState } from "react";
import { isPresent } from "ts-is-present";
import { CancelVacateModal } from "./CancelVacateModal";
import { LocalDate } from "@ailo/date";

export function ManageMenu({
  tenancy,
  onCancelVacate
}: {
  tenancy: EndTenancyDetailsFragment;
  onCancelVacate: () => void;
}): React.ReactElement {
  const [
    confirmRemoveEndDateModalVisible,
    setConfirmRemoveEndDateModalVisible
  ] = useState(false);

  const modal = useGlobalModal();

  const canEditEndTenancy =
    tenancy.endDate &&
    new LocalDate(tenancy.endDate).isSameOrAfter(LocalDate.today());

  const editEndTenancyOption = canEditEndTenancy
    ? getEditEndTenancyOption({
        onSelect: () => {
          modal.show(
            <EndTenancyModalForm
              tenancyId={tenancy.id}
              onDismiss={modal.hide}
              onSuccess={modal.hide}
            />
          );
        }
      })
    : null;

  const stopVacatingOption = useGetStopVacatingOption({
    onPress: () => {
      setConfirmRemoveEndDateModalVisible(true);
    },
    tenancyId: tenancy.id
  });

  const onCancel = (): void => {
    setConfirmRemoveEndDateModalVisible(false);
  };

  const onConfirm = (): void => {
    onCancelVacate?.();
    setConfirmRemoveEndDateModalVisible(false);
  };

  const tenancyOptionsGroup: DropdownMenuOptionGroup = {
    options: [editEndTenancyOption, stopVacatingOption].filter(isPresent),
    type: "group"
  };

  return (
    <>
      <DropdownMenu
        renderToggle={renderDropdownButton}
        options={[tenancyOptionsGroup]}
      />
      {tenancy.endDate && (
        <CancelVacateModal
          tenancy={tenancy}
          visible={confirmRemoveEndDateModalVisible}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
}

function renderDropdownButton({
  toggle,
  open
}: DropdownMenuToggleProps): React.ReactElement {
  return (
    <Button.Text onPress={toggle} rightIcon={DownCaretIcon} active={open}>
      {"Manage"}
    </Button.Text>
  );
}
