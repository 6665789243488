import { formatDateDefaultToNA } from "@ailo/services";
import { DescriptionList } from "@ailo/ui";
import React, { FC } from "react";
import { getAgreementDatesDescriptionListItems } from "./getAgreementDatesDescriptionListItems";
import { ErrorIcon, WarningIcon } from "./icons";
import { SectionHeader } from "./sections";

interface Props {
  managementStartDate?: string | null;
  tenancyEndDate?: string | null;
  newTenancyStartDate?: string | null;
  agreementStartDate?: string | null;
  fixedTermAgreementEndDate?: string | null;
  agreementAllowedToLapse: boolean;
  nextAgreementStartDate?: string | null;
  isGeneratingIncome: boolean;
}

export const VacantDetails: FC<Props> = ({
  managementStartDate,
  tenancyEndDate,
  newTenancyStartDate,
  agreementStartDate,
  fixedTermAgreementEndDate,
  agreementAllowedToLapse,
  nextAgreementStartDate,
  isGeneratingIncome
}) => {
  const vacantSinceDate = tenancyEndDate || managementStartDate;
  const HeaderIcon = newTenancyStartDate ? WarningIcon : ErrorIcon;
  return (
    <>
      <SectionHeader
        icon={HeaderIcon}
        title={"Vacant"}
        style={{ marginBottom: 12 }}
      />
      <DescriptionList
        size={"small"}
        items={[
          {
            label: "Vacant since",
            description: formatDateDefaultToNA(vacantSinceDate)
          },
          ...(isGeneratingIncome
            ? getAgreementDatesDescriptionListItems({
                agreementStartDate,
                fixedTermAgreementEndDate,
                agreementAllowedToLapse,
                nextAgreementStartDate
              })
            : []),
          ...(newTenancyStartDate
            ? [
                {
                  label: "New tenancy start",
                  description: formatDateDefaultToNA(newTenancyStartDate)
                }
              ]
            : [])
        ]}
        testID={"vacancy-details-section"}
      />
    </>
  );
};
