import React, { ReactElement } from "react";
import { Button } from "@ailo/ui";
import { PlusIcon } from "@ailo/primitives";

export function AddElementButton({
  onPress,
  text,
  disabled
}: {
  onPress: () => void;
  text: string;
  disabled?: boolean;
}): ReactElement {
  return (
    <Button.Text
      leftIcon={PlusIcon}
      style={{ flexDirection: "row" }}
      onPress={onPress}
      disabled={disabled}
    >
      {text}
    </Button.Text>
  );
}
