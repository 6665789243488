import React from "react";
import { WarningSubtractedIcon } from "@ailo/primitives";
import { PropertyWithIconCell } from "@ailo/domains";
import { Tooltip } from "@ailo/ui";
import { getStreetAndStreetNumber } from "./utils";
import { ErrorCell } from "./ErrorCell";
import { AddressFragment } from "local/graphql";

export function AddressCellUI({
  address,
  showNoPaymentWarning = false
}: {
  address: AddressFragment;
  showNoPaymentWarning?: boolean;
}): React.ReactElement {
  const RightIcon = showNoPaymentWarning ? (
    <Tooltip tooltipContent={"Renters have not yet paid rent on Ailo"}>
      <WarningSubtractedIcon width={20} height={20} />
    </Tooltip>
  ) : undefined;

  return (
    <PropertyWithIconCell
      streetAndStreetNumber={getStreetAndStreetNumber(address)}
      suburb={address.suburb || ""}
      rightIcon={RightIcon}
    />
  );
}

AddressCellUI.Loading = PropertyWithIconCell.Loading;

function Error({ onReload }: { onReload(): void }): React.ReactElement {
  return <ErrorCell onPress={onReload} style={{ alignItems: "flex-start" }} />;
}

AddressCellUI.Error = Error;
