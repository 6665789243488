import React from "react";
import { Card } from "@ailo/ui";
import {
  LegalEntityList,
  LegalEntityListProps
} from "local/domain/authz/LegalEntityList";
import { SidebarSection } from "./SidebarSection";

interface Props {
  members: LegalEntityListProps["legalEntities"];
  onPersonPress?: (personId: string) => void;
}

export function CompanyMembersSection({
  members,
  onPersonPress
}: Props): React.ReactElement {
  return (
    <SidebarSection title={"People"}>
      <Card style={{ margin: 0 }}>
        <LegalEntityList legalEntities={members} onItemPress={onPersonPress} />
      </Card>
    </SidebarSection>
  );
}
