import { View } from "react-native";
import { Skeleton } from "@ailo/primitives";
import React, { ReactElement } from "react";

export function CentrepaySectionLoading(): ReactElement {
  return (
    <View>
      <Skeleton style={{ width: "100%", height: 32 }} />
      <Skeleton style={{ width: "60%", height: 16, marginTop: 16 }} />
    </View>
  );
}
