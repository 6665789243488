import { AiloRN } from "@ailo/ailorn";
import {
  AttachmentsInput,
  FilesContextProvider,
  translateFile,
  useFilesContext
} from "@ailo/domains";
import { UploadedFile } from "@ailo/ui";
import { ProjectFileType } from "local/domain/project";
import { FileKind, ProjectDetailsFragment } from "local/graphql";
import React, { useEffect, useMemo, useState } from "react";
import { useProjectFileDragAndDrop } from "./useProjectFileDragAndDrop";

type Props = {
  project: ProjectDetailsFragment;
};
export function ProjectFilesDragAndDrop({
  project
}: Props): React.ReactElement {
  const [projectFilesState, setProjectFilesState] = useState<ProjectFileType[]>(
    []
  );

  useEffect(() => {
    setProjectFilesState(
      project?.files?.items.map(({ id, file }) => ({
        fileId: id,
        file: translateFile(file)
      })) || []
    );
  }, [project, setProjectFilesState]);

  const {
    addProjectFile,
    getSecondaryActionsForFile,
    onFileOpened,
    onFileDownloaded
  } = useProjectFileDragAndDrop({
    projectId: project.id,
    projectFilesState,
    setProjectFilesState
  });

  return (
    <FilesContextProvider
      kind={FileKind.ProjectFile}
      getSecondaryActionsForFile={getSecondaryActionsForFile}
      initialFiles={project.files?.items.map(({ file }) => translateFile(file))}
      claim={async (ailorn: AiloRN<"file:file">) => {
        const file = projectFilesState.find(({ file }) =>
          file.ailorn?.equals(ailorn)
        );
        await addProjectFile({ projectId: project.id, ailorn, file });
      }}
      newFileOrder={"DESC"}
    >
      <ProjectAttachmentsInput
        onFileDownloaded={onFileDownloaded}
        onFileOpened={onFileOpened}
        project={project}
      />
    </FilesContextProvider>
  );
}

function ProjectAttachmentsInput({
  onFileOpened,
  onFileDownloaded,
  project
}: Props & {
  onFileOpened: (file: UploadedFile) => void;
  onFileDownloaded: (file: UploadedFile) => void;
}): React.ReactElement {
  const { setFiles } = useFilesContext();

  const projectFiles = useMemo(() => {
    return project?.files?.items.map(({ file }) => translateFile(file)) || [];
  }, [project?.files?.items]);

  useEffect(() => {
    setFiles(projectFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectFiles]);

  return (
    <AttachmentsInput
      onFileOpened={onFileOpened}
      onFileDownloaded={onFileDownloaded}
    />
  );
}
