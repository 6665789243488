import React, { ReactElement } from "react";
import { DateInputFormField } from "@ailo/ui";
import { Controller, useFormContext, Validate } from "react-hook-form";
import {
  NewManagementFormData,
  NewManagementFormFields
} from "../../NewManagementFormData";
import { LocalDate } from "@ailo/date";

interface StartDateInputProps {
  validate: Validate;
  minStartDate?: LocalDate;
  agreementEndDate?: string | null;
}

export function StartDateInput({
  minStartDate,
  agreementEndDate,
  validate
}: StartDateInputProps): ReactElement {
  const { control, setValue, errors } = useFormContext<NewManagementFormData>();
  return (
    <Controller
      control={control}
      render={({ value, onBlur, onChange }): React.ReactElement => {
        return (
          <DateInputFormField
            webPopoverHorizontalAlign={"start"}
            onBlur={onBlur}
            onChange={(date): void => {
              onChange(date ?? undefined);

              const defaultEndDate = date
                ? LocalDate.from(date)
                    .add(1, "year")
                    .subtract(1, "day")
                    .toString()
                : undefined;

              if (!agreementEndDate)
                setValue(
                  NewManagementFormFields.agreementEndDate,
                  defaultEndDate
                );
            }}
            placeholder={"Start date"}
            autoCorrect={false}
            autoCompleteType={"off"}
            allowAnyTypedDate={true}
            value={value}
            minDate={minStartDate?.toString()}
            label={"Agreement Start"}
            error={errors.agreementStartDate?.message}
          />
        );
      }}
      name={NewManagementFormFields.agreementStartDate}
      defaultValue={null}
      rules={{
        required: "Please select a start date",
        validate
      }}
    />
  );
}
