import { fileIsLoaded } from "@ailo/domains";
import { UseFormMethods } from "react-hook-form";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";

export function isFormValid(form: UseFormMethods<AddBillFormData>): boolean {
  const formData = form.watch();

  return filesAreValid(formData);
}

function filesAreValid(formData: AddBillFormData): boolean {
  return formData.attachmentsFiles?.every(fileIsLoaded) ?? false;
}
