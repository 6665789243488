import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { Colors, Keys, Text } from "@ailo/primitives";

function EmptyKeysList({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): ReactElement {
  return (
    <Container style={style}>
      <Keys width={49} height={80} style={{ marginTop: 32 }} />
      <Text.BodyL style={{ marginTop: 20 }} weight={"medium"}>
        {"No keys"}
      </Text.BodyL>
      <Text.BodyS style={{ marginTop: 8 }} color={Colors.TEXT.DARK.SECONDARY}>
        {"Add keys and security details"}
      </Text.BodyS>
    </Container>
  );
}

const Container = styled(View)`
  height: 660px;
  align-items: center;
  justify-content: center;
`;

export { EmptyKeysList };
