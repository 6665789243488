import {
  useGetManagementPortfolioOwnersQuery,
  GetManagementPortfolioOwnersQuery
} from "local/graphql";
import { useCurrentAgencyOrg } from "local/common";
import { AiloRN } from "@ailo/ailorn";
import { ApolloQueryResult } from "@apollo/client";

/**
 * Fetch management portfolio details with `portfolioAilorn` and current organisation.
 */
export function useGetManagementPortfolioOwners(
  portfolioAilorn: AiloRN<"propertymanagement:managementfolio">
): {
  data: GetManagementPortfolioOwnersQuery | undefined;
  loading: boolean;
  error?: Error | undefined;
  refetch: () => Promise<ApolloQueryResult<GetManagementPortfolioOwnersQuery>>;
} {
  const { ailoRN: currentAgencyOrgAilorn } = useCurrentAgencyOrg();
  return useGetManagementPortfolioOwnersQuery({
    variables: {
      managementPortfolioId: portfolioAilorn.internalId,
      organisationAilorn: currentAgencyOrgAilorn
    }
  });
}
