import React from "react";
import { Gap } from "./Gap";
import { Skeleton, Text } from "@ailo/primitives";
import { Row } from "local/common";
import { View } from "react-native";
import { Separator } from "@ailo/ui";
import { SaveButton } from "./SaveButton";
import { StickyBottom } from "./StickyBottom";

export function Loading(): React.ReactElement {
  return (
    <>
      <Gap height={40} />
      <Text.BodyL.Loading />
      <Gap height={28} />
      <Text.BodyL.Loading />
      <Gap height={12} />
      <Skeleton style={{ height: 52 }} />
      <Gap height={28} />

      <Row>
        <View style={{ width: "46%" }}>
          <Text.BodyM.Loading />
          <Gap height={12} />
          <Skeleton style={{ height: 52 }} />
        </View>
        <View style={{ width: "46%" }}>
          <Text.BodyM.Loading />
          <Gap height={12} />
          <Skeleton style={{ height: 52 }} />
        </View>
      </Row>

      <StickyBottom>
        <Separator style={{ marginLeft: -32, marginRight: -32 }} />
        <Gap height={16} />
        <Row>
          <SaveButton.Loading />
        </Row>
      </StickyBottom>
    </>
  );
}
