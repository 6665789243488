import {
  ActionDetailsFragment,
  ActionType,
  useGetProjectActionsQuery
} from "local/graphql";

export interface GetActionFromProjectInput {
  projectId: string;
  type: ActionType;
}

export function useFindActionOfTypeInProject({
  projectId,
  actionType
}: {
  projectId: string;
  actionType: ActionType;
}): {
  loading: boolean;
  error: boolean;
  action?: ActionDetailsFragment;
} {
  const { data, loading, error } = useGetProjectActionsQuery({
    variables: {
      projectId,
      pageSize: 50
    }
  });

  const action = data?.project?.actions?.items.find(
    (action) => action.type === ActionType[actionType]
  );

  return {
    loading,
    error: !!error,
    action
  };
}
