import { GridSortOrder } from "@ailo/ui";
import React from "react";
import {
  AddressCellWithNoPaymentWarning,
  ArrearsAmountCell,
  PaidToDateCell,
  RentCell,
  DaysInArrearsCell
} from "../PropertyItemCell";
import { usePropertyListReorder, useArrearsPropertyListQuery } from "../hooks";
import { ComeBackSoonMessage } from "../ComeBackSoonMessage";
import { lowerCase, startCase } from "lodash";
import { BasePropertyList, BasePropertyListProps } from "../BasePropertyList";
import { createCellRenderer } from "../createCellRenderer";
import { ArrearsReportDownloadButton } from "./ArrearsReportDownloadButton";

export function ArrearsPropertyList({
  tabNavigation
}: {
  tabNavigation: React.ReactElement;
}): React.ReactElement {
  const queryData = useArrearsPropertyListQuery(initialSortOrder);
  const { sortOrder, onSortOrderChange } = usePropertyListReorder(
    initialSortOrder,
    queryData.requestFromBeginning,
    analyticsInfo
  );

  return (
    <BasePropertyList
      tabNavigation={tabNavigation}
      queryData={queryData}
      errorMessage={"There was a problem loading Arrears"}
      emptyMessage={"No properties in arrears to show"}
      secondaryEmptyMessage={<ComeBackSoonMessage />}
      sortOrder={sortOrder}
      columns={columns}
      onSortOrderChange={onSortOrderChange}
      footerButton={<ArrearsReportDownloadButton sortOrder={sortOrder} />}
    />
  );
}

export enum ArrearsPropertyListColumns {
  Properties = "Properties",
  Rent = "Rent",
  ArrearsAmount = "Arrears amt.",
  PaidTo = "Paid to",
  ArrearsDays = "Arrears days"
}

const columns: BasePropertyListProps["columns"] = [
  {
    width: 3,
    renderCell: createCellRenderer(AddressCellWithNoPaymentWarning),
    renderLoadingCell: AddressCellWithNoPaymentWarning.Loading,
    name: ArrearsPropertyListColumns.Properties,
    sortable: true
  },
  {
    width: 1,
    renderCell: createCellRenderer(RentCell),
    renderLoadingCell: RentCell.Loading,
    name: ArrearsPropertyListColumns.Rent,
    sortable: false,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(ArrearsAmountCell),
    renderLoadingCell: ArrearsAmountCell.Loading,
    name: ArrearsPropertyListColumns.ArrearsAmount,
    sortable: false,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(PaidToDateCell),
    renderLoadingCell: PaidToDateCell.Loading,
    name: ArrearsPropertyListColumns.PaidTo,
    sortable: true,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(DaysInArrearsCell),
    renderLoadingCell: DaysInArrearsCell.Loading,
    name: ArrearsPropertyListColumns.ArrearsDays,
    sortable: false,
    horizontalAlign: "right",
    verticalAlign: "middle"
  }
];

const initialSortOrder: GridSortOrder = {
  columnKey: ArrearsPropertyListColumns.PaidTo,
  direction: "ASC"
};

function analyticsColName(columnKey: string): string {
  if (columnKey === ArrearsPropertyListColumns.PaidTo) return "Arrears";
  if (columnKey === ArrearsPropertyListColumns.Properties) return "Address";
  throw new Error(`Unexpected column to return analytics: ${columnKey}`);
}

function analyticsInfo(sortOrder: GridSortOrder): {
  eventName: string;
  orderBy: string;
} {
  return {
    eventName: "Arrears Property List Reordered",
    orderBy: `${analyticsColName(sortOrder.columnKey)} ${startCase(
      lowerCase(sortOrder.direction)
    )}`
  };
}
