import React, { ReactElement } from "react";
import { MigrationIssueKey } from "local/graphql";
import { VacatingTenancyHealthIssue } from "./VacatingTenancyHealthIssue";
import { HealthIssue } from "./HealthIssue";
import { MigratingManagementIssueData } from "./KeyedHealthIssuesCard";

interface Props {
  migratingManagementIssueData: MigratingManagementIssueData;
  issueKey?: MigrationIssueKey;
  text: string;
  subtext?: string;
  setLoading: (loading: boolean) => void;
}

export function KeyedHealthIssue({
  migratingManagementIssueData,
  issueKey,
  text,
  subtext,
  setLoading
}: Props): ReactElement {
  if (issueKey === MigrationIssueKey.VacatingTenancy) {
    return (
      <VacatingTenancyHealthIssue
        migratingManagementIssueData={migratingManagementIssueData}
        setLoading={setLoading}
      />
    );
  }

  return <HealthIssue text={text} subtext={subtext} />;
}
