import { formatPhoneNumber } from "@ailo/domains";
import { Text } from "@ailo/primitives";
import { PersonAvatarProps, PersonAvatar, Separator } from "@ailo/ui";
import React from "react";
import {
  BaseProfileCard,
  ProfileCardContactDetails,
  ProfileCardStyle
} from "local/domain/authz";
import { View } from "react-native";
import { getDateValue } from "./getDateValue";
import { DetailsField } from "./DetailsField";
import styled from "styled-components/native";
import { OwnerNoEmailWarning } from "../OwnerNoEmailWarning";

interface Props {
  person: PersonAvatarProps["person"] & {
    preferredName?: string | null;
    middleName?: string | null;
    dateOfBirth?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    sharesOwned: number;
  };
  totalShares: number;
  showNoEmailWarning?: boolean;
  style?: ProfileCardStyle;
}

export function ReviewOwnerProfileCard({
  person,
  totalShares,
  showNoEmailWarning = true,
  style
}: Props): React.ReactElement {
  const { firstName, preferredName, lastName, sharesOwned } = person;
  const displayName = `${preferredName ?? firstName} ${lastName}`;
  const { phoneNumber, email, middleName, dateOfBirth } = person;

  return (
    <BaseProfileCard
      isInvestor={true}
      style={{ paddingLeft: 16, ...style }}
      colorGradientContent={
        <>
          <PersonAvatar
            size={40}
            person={{ firstName: preferredName ?? firstName, lastName }}
            style={{ marginTop: -8, marginBottom: 12 }}
          />
          <Text.BodyM weight={"medium"}>{displayName}</Text.BodyM>
        </>
      }
    >
      <ProfileCardContactDetails
        phoneNo={phoneNumber && formatPhoneNumber(phoneNumber)}
        emailAddress={email}
      />
      <StyledSeparator style={{ marginLeft: 0 }} />
      <View style={{ paddingRight: 16 }}>
        <StyledDetailsField label={"First name"} value={firstName} />
        <StyledDetailsField label={"Preferred name"} value={preferredName} />
        <StyledDetailsField label={"Middle name"} value={middleName} />
        <StyledDetailsField label={"Last name"} value={lastName} />
        <StyledDetailsField
          label={"Date of birth"}
          value={getDateValue(dateOfBirth)}
        />
        <DetailsField
          label={"Shares owned"}
          value={`${sharesOwned} out of ${totalShares}`}
        />
      </View>
      {!email && showNoEmailWarning && (
        <>
          <StyledSeparator style={{ marginLeft: -16 }} />
          <View style={{ paddingRight: 16 }}>
            <OwnerNoEmailWarning />
          </View>
        </>
      )}
    </BaseProfileCard>
  );
}

const StyledDetailsField = styled(DetailsField)`
  margin-bottom: 8px;
`;

const StyledSeparator = styled(Separator)`
  margin-top: 16px;
  margin-bottom: 16px;
`;
