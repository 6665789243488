import { MoneyInterface, useToastContext } from "@ailo/ui";
import { MultiStepForm } from "local/common";
import moment from "moment-timezone";
import React, {
  ReactElement,
  useCallback,
  useMemo,
  useState,
  useEffect
} from "react";
import { useForm } from "react-hook-form";
import { StyleProp, ViewStyle } from "react-native";
import { useThrottledCallback } from "use-debounce";
import { useNavigateToRentAdjustments } from "../../RentAdjustmentListScreen";
import { useGetTenancyDetailsForCreateRentAdjustmentQuery } from "local/graphql";
import { CreateRentAdjustmentStep } from "./steps/CreateRentAdjustmentStep";
import { ReviewRentAdjustmentStep } from "./steps/ReviewRentAdjustmentStep";
import { useCreateRentCredit } from "./useCreateRentCredit";

interface Props {
  tenancyId: string;
  managementId: string;
  idempotencyKey: string;
  style?: StyleProp<ViewStyle>;
}

type StepIds = "Create" | "Review";

type Step = {
  stepId: StepIds;
  render: (visible: boolean) => ReactElement;
};

export interface CreateRentAdjustmentFormData {
  amount: MoneyInterface | null;
  description: string | null;
  effectiveDate: string;
  idempotencyKey: string;
  notifyTenants: boolean;
  notifyInvestors: boolean;
}

export function CreateRentAdjustmentForm({
  tenancyId,
  managementId,
  idempotencyKey,
  style
}: Props): ReactElement {
  const toast = useToastContext();
  const navigateToRentAdjustments = useNavigateToRentAdjustments();
  const [currentStepNumber, setCurrentStepNumber] = useState<number>(1);

  const { data } = useGetTenancyDetailsForCreateRentAdjustmentQuery({
    variables: { tenancyId }
  });

  const tenancyStartDate = data?.tenancy?.startDate;
  const tenancyTimezone =
    data?.tenancy?.management?.managingEntity?.timezone || "Australia/Sydney";

  const tenancyCurrentDate = moment.tz(tenancyTimezone).format("YYYY-MM-DD");

  const form = useForm<CreateRentAdjustmentFormData>({
    defaultValues: {
      amount: null,
      description: "",
      effectiveDate: tenancyCurrentDate,
      idempotencyKey,
      notifyInvestors: true,
      notifyTenants: true
    },
    mode: "onChange"
  });

  const { formState, handleSubmit, reset, trigger, setValue } = form;

  const {
    dirtyFields: { effectiveDate: isEffectiveDateDirty },
    isDirty,
    isSubmitting
  } = formState;

  useEffect(() => {
    if (isEffectiveDateDirty) return;
    setValue("effectiveDate", tenancyCurrentDate);
  }, [isEffectiveDateDirty, setValue, tenancyCurrentDate]);

  const handleOnCancel = useCallback(() => {
    reset();
    setCurrentStepNumber(1);
    navigateToRentAdjustments({ managementId, tenancyId });
  }, [managementId, navigateToRentAdjustments, reset, tenancyId]);

  const [createRentCredit] = useCreateRentCredit({
    onCompleted: () => {
      toast.show({
        type: "success",
        message: "Rent credit issued"
      });
      reset();
      setCurrentStepNumber(1);
      navigateToRentAdjustments({ managementId, tenancyId });
    },
    onError: () => {
      toast.show({
        type: "error",
        message: "Unable to add rent credit. Please try again."
      });
    }
  });

  const { callback: onSubmitThrottledCallback } = useThrottledCallback(
    (rentCreditDetails: {
      amount: { cents: number };
      description: string;
      effectiveDate: string;
      idempotencyKey: string;
      notifyInvestors: boolean;
      notifyTenants: boolean;
    }): void => {
      const {
        amount: { cents },
        description,
        effectiveDate,
        notifyInvestors,
        notifyTenants
      } = rentCreditDetails;

      createRentCredit(
        {
          tenancyId,
          amount: { cents },
          description,
          effectiveDate,
          idempotencyKey,
          notifyInvestors,
          notifyTenants
        },
        managementId,
        tenancyCurrentDate
      );
    },
    10000,
    { trailing: false }
  );

  const renderStepCreate = useCallback(
    (visible: boolean): ReactElement => (
      <CreateRentAdjustmentStep
        display={visible}
        form={form}
        tenancyStartDate={tenancyStartDate}
        tenancyCurrentDate={tenancyCurrentDate}
        key={"CreateRentAdjustmentStep"}
      />
    ),
    [form, tenancyCurrentDate, tenancyStartDate]
  );

  const renderStepReview = useCallback(
    (visible: boolean): ReactElement => (
      <ReviewRentAdjustmentStep
        display={visible}
        form={form}
        key={"ReviewRentAdjustmentStep"}
        setCurrentStepNumber={setCurrentStepNumber}
      />
    ),
    [form]
  );

  const steps: Step[] = useMemo(
    () => [
      {
        stepId: "Create",
        render: renderStepCreate
      },
      {
        stepId: "Review",
        render: renderStepReview
      }
    ],
    [renderStepCreate, renderStepReview]
  );

  const onNextButtonPress = useCallback(
    async (newStepNumber: number) => {
      if (newStepNumber < currentStepNumber) {
        setCurrentStepNumber(newStepNumber);
        return;
      }
      const currentStepId = steps[currentStepNumber - 1].stepId;

      if (currentStepId === "Create") {
        const isValid = await trigger();
        if (isValid) {
          setCurrentStepNumber(newStepNumber);
        }
      }
    },
    [currentStepNumber, steps, trigger]
  );

  return (
    <MultiStepForm
      cancelButtonDisabled={isSubmitting}
      isDirty={isDirty}
      isSubmitting={isSubmitting}
      steps={steps.map((step) => step.render)}
      stepValue={currentStepNumber}
      style={style}
      submitButtonText={"Create Rent Credit"}
      title={"Create Rent Credit"}
      onCancel={handleOnCancel}
      onChange={onNextButtonPress}
      onSubmit={handleSubmit(onSubmitThrottledCallback)}
    />
  );
}
