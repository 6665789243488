import { Money } from "@ailo/money";
import { useToastContext } from "@ailo/ui";
import { useUpdateTrustAccountStatementBalanceMutation } from "local/graphql";
import { useCallback } from "react";

interface Params {
  onUpdateSuccess: () => void;
  periodId: string;
  date: string | undefined;
  amount: Money | undefined;
}

interface Return {
  updateBalance: () => void;
  submitting: boolean;
  lastUpdateFailed: boolean;
}

export function useUpdateBankBalance({
  onUpdateSuccess,
  periodId,
  amount,
  date
}: Params): Return {
  const toast = useToastContext();
  const [updateBalanceMutation, { error, loading }] =
    useUpdateTrustAccountStatementBalanceMutation();

  const updateBalance = useCallback(() => {
    if (!date || !amount) return;

    updateBalanceMutation({
      variables: {
        reportPeriodId: periodId,
        amountInCents: amount.cents,
        date
      }
    }).then(({ data }) => {
      if (data) {
        toast.show({
          type: "success",
          message: "Bank statement balance has been updated"
        });
        onUpdateSuccess();
      }
    });
  }, [amount, date, onUpdateSuccess, periodId, toast, updateBalanceMutation]);

  return { updateBalance, lastUpdateFailed: !!error, submitting: loading };
}
