import { BaseModal } from "@ailo/ui";
import React from "react";

export function UpsertKeyModal({
  visible,
  title,
  children
}: React.PropsWithChildren<{
  visible: boolean;
  title: string;
}>): React.ReactElement {
  return (
    <BaseModal
      headerAndContentContainerStyle={{ paddingBottom: 0 }}
      title={title}
      visible={visible}
    >
      {children}
    </BaseModal>
  );
}
