import { ClaimTenancyData } from "local/domain/bond";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { formatAddress } from "@ailo/domain-helpers";
import { Row } from "local/common";
import styled from "styled-components/native";
import { SecondaryText } from "../SecondaryText";
import { calculateTotalAmountOfBillsDue } from "./calculateTotalAmountOfBillsDue";
import { PrimaryText } from "../PrimaryText";

export function PropertyAndLiabilityDetails({
  data
}: {
  data: ClaimTenancyData;
}): ReactElement {
  return (
    <>
      <TextSection>
        <SecondaryText>{"Property"}</SecondaryText>
        <PrimaryText>
          {formatAddress(data.property.address, { format: "street, suburb" })}
        </PrimaryText>
      </TextSection>
      <Row style={{ justifyContent: "space-between", marginRight: 16 }}>
        <View style={{ marginRight: 32 }}>
          <TextSection>
            <SecondaryText>{"Bond amount"}</SecondaryText>
            <PrimaryText>{data.bond.amount.format()}</PrimaryText>
            <View style={{ marginTop: 16 }}>
              <SecondaryText>{"Total outstanding bills"}</SecondaryText>
              <PrimaryText>
                {calculateTotalAmountOfBillsDue(data).format()}
              </PrimaryText>
            </View>
          </TextSection>
        </View>
        <View>
          <TextSection>
            <SecondaryText>{"Bond reference"}</SecondaryText>
            <PrimaryText>{data.bond.reference}</PrimaryText>
          </TextSection>
          <View>
            <SecondaryText>{"Total outstanding rent"}</SecondaryText>
            <PrimaryText>{data.rent.outstandingAmount.format()}</PrimaryText>
          </View>
        </View>
      </Row>
    </>
  );
}

const TextSection = styled(View)`
  padding-bottom: 16px;
`;
