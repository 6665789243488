import React, { ReactElement, useEffect } from "react";
import { MultiStepForm } from "local/common";
import { ClaimTenancyData } from "local/domain/bond";
import { useFormContext } from "react-hook-form";
import { ClaimBondFormData } from "./ClaimBondFormData";
import { useFormSteps } from "./useFormSteps";
import { CLAIM_LIABILITIES_STEP } from "./useFormSteps/stepNames";
import { ClaimBondFormConfiguration } from "local/tabs";

type Props = {
  data: ClaimTenancyData;
  formConfiguration: ClaimBondFormConfiguration;
  onClose(): void;
};

export function BaseClaimBondForm({
  data,
  formConfiguration,
  onClose
}: Props): ReactElement {
  const { reset } = useFormContext<ClaimBondFormData>();
  const { onStepChange, steps, currentStep, goToStep, submitButtonDisabled } =
    useFormSteps({
      data,
      onClose,
      formConfiguration
    });

  useEffect(() => {
    reset();
  }, [data, reset]);

  return (
    <MultiStepForm
      isDirty={false}
      onCancel={() => {
        reset();
        goToStep(CLAIM_LIABILITIES_STEP);
        onClose();
      }}
      stepValue={currentStep}
      style={{
        maxWidth: 680,
        width: "100%",
        flex: 1,
        alignSelf: "flex-end"
      }}
      steps={steps}
      title={formConfiguration.title}
      onChange={onStepChange}
      submitButtonDisabled={submitButtonDisabled}
      submitButtonText={formConfiguration.submitButtonText}
      nextButtonTexts={{
        1: formConfiguration.claimLiabilitiesStep.nextButtonLabel
      }}
    />
  );
}
