import { Text } from "@ailo/primitives";
import { ErrorAlertScreen, LabelledTextInput } from "@ailo/ui";
import React, { ReactElement, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { View } from "react-native";
import styled from "styled-components/native";
import { EndManagementFormData } from "../EndManagementFormData";
import { EndManagementSection } from "../EndManagementSection";
import {
  EndingManagementDetailsFragment,
  ManagementEndDetailsFragment,
  ManagementEndReasonFragment
} from "local/graphql";
import { getLastEndedTenancyEndDate } from "../../getLastEndedTenancy";
import { LocalDate } from "@ailo/date";

interface Props {
  tenancyEndDate?: LocalDate;
  management: EndingManagementDetailsFragment & ManagementEndDetailsFragment;
  endReasons: ManagementEndReasonFragment[];
}

export function EndingManagementStep({
  management,
  display,
  endReasons
}: {
  management: EndingManagementDetailsFragment & ManagementEndDetailsFragment;
  display: boolean;
  endReasons: ManagementEndReasonFragment[];
}): ReactElement {
  return (
    <View style={{ display: display ? undefined : "none" }}>
      <EndingManagementFormContent
        management={management}
        endReasons={endReasons}
      />
    </View>
  );
}

function EndingManagementFormContent({
  management,
  endReasons
}: {
  management: EndingManagementDetailsFragment & ManagementEndDetailsFragment;
  endReasons: ManagementEndReasonFragment[];
}): ReactElement {
  if (!management?.tenancies?.items) {
    throw new Error("Management tenancies is undefined");
  }

  if (!management.startDate) {
    throw new Error("Management start date is empty");
  }

  let lastTenancyEndDate: LocalDate | undefined;
  const notVoidedTenancies = (management?.tenancies?.items || []).filter(
    (tenancy) => !tenancy.voidedAt
  );
  if (notVoidedTenancies.length > 0) {
    lastTenancyEndDate = getLastEndedTenancyEndDate(notVoidedTenancies);
    if (!lastTenancyEndDate) {
      throw new Error("Management has an unending tenancy");
    }
  }

  return (
    <EndManagementSectionFormWithData
      tenancyEndDate={lastTenancyEndDate}
      management={management}
      endReasons={endReasons}
    />
  );
}

function EndManagementSectionFormWithData({
  tenancyEndDate,
  management,
  endReasons
}: Props): ReactElement {
  const { register } = useFormContext<EndManagementFormData>();
  useEffect(() => {
    register({ name: "managementId", value: management.id });
  }, [register, management]);

  return (
    <Container>
      <EndManagementSection
        management={management}
        tenancyEndDate={tenancyEndDate}
        endReasons={endReasons}
      />
    </Container>
  );
}

EndingManagementStep.Loading = function Loading(): ReactElement {
  return (
    <View>
      <Text.BodyS.Loading width={120} style={{ marginTop: 34 }} />
      <LabelledTextInput.Loading style={{ flex: 1, marginTop: 20 }} />
      <LabelledTextInput.Loading style={{ flex: 1, marginTop: 20 }} />
      <LabelledTextInput.Loading style={{ flex: 1, marginTop: 20 }} />
    </View>
  );
};

EndingManagementStep.Error = function Error({
  onRetry
}: {
  onRetry: () => void;
}): React.ReactElement {
  return (
    <Container style={{ justifyContent: "center" }}>
      <ErrorAlertScreen
        variant={"medium"}
        title={"There was a problem loading\nEnding management"}
        onRetry={onRetry}
      />
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
`;
