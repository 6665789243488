import { Text, Colors } from "@ailo/primitives";
import React from "react";
import { TextStyle, View } from "react-native";
import { DateTimeWithTimeZone } from "@ailo/date";

export function ReportTitle({
  title,
  style,
  lastUpdated
}: {
  title: string;
  style?: TextStyle;
  lastUpdated?: DateTimeWithTimeZone | null;
}): React.ReactElement {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
        ...style
      }}
    >
      <Text.DisplayM color={Colors.TEXT.DARK.PRIMARY}>{title}</Text.DisplayM>
      {lastUpdated && (
        <Text.BodyXS color={Colors.TEXT.DARK.SECONDARY}>
          {`Data last updated: ${lastUpdated.friendlyFormat("datetime")}`}
        </Text.BodyXS>
      )}
    </View>
  );
}
