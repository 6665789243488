import {
  AwaitingApprovalPropertyOnboardingListQuery,
  BasePropertyOnboardingListContent,
  PropertyOnboardingListLoadingContent,
  PropertyOnboardingListReorder,
  useAwaitingApprovalPropertyOnboardingListQuery,
  useBasePropertyOnboardingListContent,
  usePropertyOnboardingListLoadingContent,
  usePropertyOnboardingListReorder
} from "../../hooks";
import {
  AwaitingApprovalPropertyOnboardingListFilterData,
  useAwaitingApprovalPropertyOnboardingListFilters
} from "./useAwaitingApprovalPropertyOnboardingListFilters";
import { GridSortOrder } from "@ailo/ui";
import { lowerCase, startCase } from "lodash";
import {
  AwaitingApprovalPropertyOnboardingListSelect,
  useAwaitingApprovalPropertyOnboardingListSelect
} from "./useAwaitingApprovalPropertyOnboardingListSelect";
import {
  AwaitingApprovalPropertyOnboardingListColumns,
  useAwaitingApprovalPropertyOnboardingListColumns
} from "./useAwaitingApprovalPropertyOnboardingListColumns";
import {
  AwaitingApprovalPropertyOnboardingListContent,
  useAwaitingApprovalPropertyOnboardingListContent
} from "./useAwaitingApprovalPropertyOnboardingListContent";
import { ReactElement } from "react";
import { KeyedAwaitingApprovalMigratingManagementFragment } from "../../types";

export enum AwaitingApprovalPropertyOnboardingListColumnNames {
  Address = "Address",
  Portfolio = "Portfolio",
  DataIssues = "Data issues",
  Status = "Status"
}

const SORT_ORDER: GridSortOrder[] = [
  {
    columnKey: AwaitingApprovalPropertyOnboardingListColumnNames.DataIssues,
    direction: "ASC"
  },
  {
    columnKey: AwaitingApprovalPropertyOnboardingListColumnNames.Address,
    direction: "ASC"
  },
  {
    columnKey: AwaitingApprovalPropertyOnboardingListColumnNames.Portfolio,
    direction: "ASC"
  }
];

function getAnalyticsInfo(sortOrder: GridSortOrder): {
  eventName: string;
  orderBy: string;
} {
  return {
    eventName: "Awaiting Approval Property Onboarding List Reordered",
    orderBy: `${sortOrder.columnKey} ${startCase(
      lowerCase(sortOrder.direction)
    )}`
  };
}

interface Params {
  tabNavigation: ReactElement;
}

interface AwaitingApprovalPropertyOnboardingListData {
  queryData: AwaitingApprovalPropertyOnboardingListQuery;
  reorder: PropertyOnboardingListReorder;
  filters: AwaitingApprovalPropertyOnboardingListFilterData;
  select: AwaitingApprovalPropertyOnboardingListSelect;
  columns: AwaitingApprovalPropertyOnboardingListColumns;
  content: AwaitingApprovalPropertyOnboardingListContent;
  baseContent: BasePropertyOnboardingListContent<KeyedAwaitingApprovalMigratingManagementFragment>;
  loadingContent: PropertyOnboardingListLoadingContent;
}

export function useAwaitingApprovalPropertyOnboardingList({
  tabNavigation
}: Params): AwaitingApprovalPropertyOnboardingListData {
  const queryData = useAwaitingApprovalPropertyOnboardingListQuery({
    initialSortOrder: SORT_ORDER
  });

  const reorder = usePropertyOnboardingListReorder(
    SORT_ORDER,
    queryData.requestFromBeginning,
    getAnalyticsInfo
  );

  const filters = useAwaitingApprovalPropertyOnboardingListFilters({
    requestFromBeginning: queryData.requestFromBeginning
  });

  const select = useAwaitingApprovalPropertyOnboardingListSelect({
    migratingManagements: queryData.data.migratingManagements
  });

  const columns = useAwaitingApprovalPropertyOnboardingListColumns({ select });

  const content = useAwaitingApprovalPropertyOnboardingListContent({
    tabNavigation,
    queryData,
    filters,
    select
  });

  const baseContent = useBasePropertyOnboardingListContent({
    queryData,
    headerCaptionContent: content.headerCaptionContent,
    columns,
    sortOrder: reorder.sortOrder,
    onSortOrderChange: reorder.onSortOrderChange,
    isRowSelected: select.isRowSelected
  });

  const loadingContent = usePropertyOnboardingListLoadingContent({ columns });

  return {
    queryData,
    reorder,
    filters,
    select,
    columns,
    content,
    baseContent,
    loadingContent
  };
}
