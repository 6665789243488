import { Money } from "@ailo/ui";
import {
  FeeBlueprintFragment,
  FeeBlueprintType,
  FeeEvent,
  Maybe,
  TaxTreatment
} from "local/graphql";

export interface AgencyFeeBlueprintOption {
  value: string;
  label: string;
  fixedAmount?: Money;
  oneWeekRentPercentage?: number;
  event?: Omit<FeeEvent, "createdAt">;
  includesGst: boolean;
  type: FeeBlueprintType;
}

export function createAgencyBlueprintOptions(
  agencyFeeBlueprints?: (Pick<
    FeeBlueprintFragment,
    | "oneWeekRentPercentage"
    | "taxTreatment"
    | "fixedAmount"
    | "id"
    | "name"
    | "type"
  > & { event?: Maybe<Omit<FeeEvent, "createdAt">> })[]
): AgencyFeeBlueprintOption[] {
  return (agencyFeeBlueprints ?? []).map((blueprint) => ({
    oneWeekRentPercentage: blueprint.oneWeekRentPercentage ?? undefined,
    includesGst: blueprint.taxTreatment === TaxTreatment.Inclusive,
    fixedAmount: blueprint.fixedAmount
      ? Money.from(blueprint.fixedAmount)
      : undefined,
    label: blueprint.name,
    value: blueprint.id,
    type: blueprint.type,
    event: blueprint.event ?? undefined
  }));
}
