import { Colors, RentCreditIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { useNavigateToAddExpense } from "local/domain/expense/AddNewExpenseButton/useNavigateToAddExpense";
import React, { ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";

export interface ChargeAFeeButtonProps {
  managementId: string;
  style?: StyleProp<ViewStyle>;
}

export function ChargeAFeeButton({
  managementId,
  style
}: ChargeAFeeButtonProps): ReactElement {
  const navigateToAddExpense = useNavigateToAddExpense();
  return (
    <Button.Text
      onPress={() => navigateToAddExpense({ managementId })}
      leftIcon={() => (
        <RentCreditIcon
          color={Colors.AILO_RED}
          width={24}
          height={24}
          style={{ marginRight: 14 }}
        />
      )}
      backgroundColor={{
        default: Colors.WHITE,
        hovered: Colors.INTERACTION.WHITE.HOVER,
        pressed: Colors.INTERACTION.WHITE.PRESSED,
        active: Colors.INTERACTION.WHITE.PRESSED,
        disabled: Colors.CLOUD
      }}
      style={[
        {
          justifyContent: "flex-start",
          borderRadius: 4,
          paddingHorizontal: 14,
          paddingVertical: 8
        },
        style
      ]}
      contentStyle={{ alignItems: "center" }}
    >
      {"Charge a fee"}
    </Button.Text>
  );
}
