import React, { ReactElement } from "react";
import { Colors, Text } from "@ailo/primitives";

export function FailedToLoad(): ReactElement {
  return (
    <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
      {"Failed to load"}
    </Text.BodyS>
  );
}
