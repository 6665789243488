import React, { ReactElement } from "react";
import { DateTimeWithTimeZone, TextCell } from "@ailo/ui";
import { ExcludedMigratingManagementFragment } from "local/graphql";

function ExcludedOnCell({
  row: { statusChangedAt }
}: {
  row: ExcludedMigratingManagementFragment;
  rowIndex: number;
}): ReactElement | null {
  const ailoExcludedAt = DateTimeWithTimeZone.from(statusChangedAt);

  return <TextCell>{ailoExcludedAt.format("D MMM YYYY")}</TextCell>;
}

ExcludedOnCell.Loading = TextCell.Loading;

export { ExcludedOnCell };
