import { DownCaretIcon } from "@ailo/primitives";
import {
  Button,
  DropdownMenu,
  DropdownMenuOptionGroup,
  DropdownMenuToggleProps
} from "@ailo/ui";
import React from "react";
import { isPresent } from "ts-is-present";
import { BondMenuOptions, useGetBondOptionsGroup } from "../../BaseTenancyCard";
import { getLeaseRenewalOption } from "./getLeaseRenewalOption";
import { getEndTenancyOption } from "./getEndTenancyOption";
import { getRentReviewOption } from "./getRentReviewOption";
import { useGetRentAdjustmentsOption } from "../../manageMenuOptions";
import {
  AllowedOperations,
  RentFragment,
  TenancyAgreementFragment
} from "local/graphql";
import { useGetCentrepayOptionsGroup } from "./useGetCentrepayOptionsGroup";
import {
  StopVacatingOptionProps,
  useGetStopVacatingOption
} from "local/domain/propertyManagement";
import styled from "styled-components/native";
import { useGetInspectionsOption } from "./useGetInspectionsOption";

export type Options<T extends {} = {}> = {
  onPress: (options: T) => void;
} & T;

interface ManageMenuProps {
  leaseRenewal: Options<{
    allowedOperation: AllowedOperations;
    nextTenancyAgreement?: TenancyAgreementFragment;
  }>;
  rentReview: Options<{
    editableRent?: RentFragment;
    canCreate: boolean;
  }>;
  endTenancy: Options<{ isVacating: boolean }>;
  inspections: Options;
  rentAdjustments: Options;
  bondMenuOptions: BondMenuOptions;
  stopVacating: StopVacatingOptionProps;
  centrepayPayments?: Options;
}

export function ManageMenu({
  leaseRenewal,
  rentReview,
  endTenancy,
  rentAdjustments,
  bondMenuOptions,
  stopVacating,
  centrepayPayments,
  inspections
}: ManageMenuProps): React.ReactElement | null {
  const endTenancyOption = getEndTenancyOption(endTenancy);
  const leaseRenewalOption = getLeaseRenewalOption(leaseRenewal);
  const rentReviewOption = getRentReviewOption(rentReview);
  const stopVacatingOption = useGetStopVacatingOption(stopVacating);
  const inspectionsOption = useGetInspectionsOption(inspections);

  const rentAdjustmentsOption = useGetRentAdjustmentsOption({
    onSelect: (): void => rentAdjustments.onPress(rentAdjustments)
  });

  const tenancyOptionsGroup: DropdownMenuOptionGroup = {
    options: [
      leaseRenewalOption,
      rentReviewOption,
      rentAdjustmentsOption,
      endTenancyOption,
      stopVacatingOption,
      inspectionsOption
    ].filter(isPresent),
    type: "group"
  };

  const bondOptionsGroup = useGetBondOptionsGroup(bondMenuOptions);

  const centrepayOptionsGroup = useGetCentrepayOptionsGroup(centrepayPayments);

  const options = [
    tenancyOptionsGroup,
    centrepayOptionsGroup,
    bondOptionsGroup
  ].filter(isPresent);

  return <DropdownMenu renderToggle={renderDropdownButton} options={options} />;
}

function renderDropdownButton({
  toggle,
  open
}: DropdownMenuToggleProps): React.ReactElement {
  return (
    <StyledTextButton onPress={toggle} rightIcon={DownCaretIcon} active={open}>
      {"Manage"}
    </StyledTextButton>
  );
}

const StyledTextButton = styled(Button.Text)`
  border-width: 0;
`;
