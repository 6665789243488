import React, { useEffect } from "react";
import { View } from "react-native";
import { useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";
import styled from "styled-components/native";
import { Colors, opacify, SFC, Text } from "@ailo/primitives";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";

interface Props {
  billerCode: string;
  shouldRender?: boolean;
}

const BillerCodeInput: SFC<Props> = ({ billerCode, shouldRender, style }) => {
  const { setValue, register, unregister } = useFormContext<AddBillFormData>();

  useEffect(() => {
    register(
      { name: "payment.billerCode" },
      {
        validate: (billerCode): boolean | string =>
          !shouldRender ||
          !isEmpty(billerCode) ||
          "Bpay payment method missing biller code"
      }
    );
    setValue("payment.billerCode", billerCode, { shouldValidate: true });

    return (): void => unregister("payment.billerCode");
  }, [register, unregister, setValue, shouldRender, billerCode]);

  return shouldRender ? (
    <View style={style} testID={"BillerCodeInputFormField"}>
      <Label weight={"medium"} color={Colors.TEXT.DARK.SECONDARY}>
        {"BPAY"}
      </Label>
      <Container style={{ width: 416, height: 52 }}>
        <Text.BodyL>{billerCode}</Text.BodyL>
      </Container>
    </View>
  ) : null;
};

const Label = styled(Text.BodyS)`
  margin-bottom: 8px;
`;

const Container = styled(View)`
  background-color: ${Colors.CLOUD};
  border: 1px solid ${opacify(Colors.SPACE_BLACK, 0.2)};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 12px;
`;

export { BillerCodeInput };
