import React from "react";
import { Controller } from "react-hook-form";
import { DateInputFormField, LocalDate } from "@ailo/ui";
import { RentReviewFields } from "../RentReviewFormData";
import { RelativeTimeInDays } from "local/common";
import { useRentReviewForm } from "../RentReviewFormContext";

function EffectiveDateInput(): React.ReactElement {
  const { control, errors, data } = useRentReviewForm();

  const minEffectiveDate = LocalDate.from(data.nextChargeCreateDate);
  const maxEffectiveDate = data.tenancyEndDate
    ? LocalDate.from(data.tenancyEndDate)
    : undefined;

  return (
    <Controller
      name={RentReviewFields.effectiveDate}
      render={({ value, onChange, onBlur }): React.ReactElement => (
        <>
          <DateInputFormField
            allowAnyTypedDate
            webPopoverHorizontalAlign={"end"}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            label={"Effective date"}
            placeholder={"Effective date"}
            autoCorrect={false}
            autoCompleteType={"off"}
            minDate={minEffectiveDate.toString()}
            maxDate={maxEffectiveDate?.toString()}
            error={errors.effectiveDate?.message}
          />
          <RelativeTimeInDays style={{ marginTop: 4 }} date={value} />
        </>
      )}
      defaultValue={null}
      control={control}
      rules={{
        required: "Please select an effective date",
        validate: (date) => {
          const localDate = new LocalDate(date);
          if (localDate.isBefore(minEffectiveDate))
            return "Effective date not available";
          if (maxEffectiveDate && localDate.isAfter(maxEffectiveDate))
            return "Effective date not available";
        }
      }}
    />
  );
}

export { EffectiveDateInput };
