import React, { ReactElement } from "react";
import {
  DepositData,
  DepositSummary,
  PropertyScreenCardErrorBoundary
} from "local/domain/propertyManagement";
import { StyleProp, ViewStyle } from "react-native";

interface Props {
  style?: StyleProp<ViewStyle>;
  managementId?: string;
  deposit?: DepositData;
  isFormerTenancy?: boolean;
  showManageMenu?: boolean;
}

export const DepositSummaryCard = ({
  style,
  deposit,
  managementId,
  isFormerTenancy,
  showManageMenu = false
}: Props): ReactElement => {
  return (
    <PropertyScreenCardErrorBoundary
      style={style}
      cardTitle={"Initial Payment"}
    >
      <DepositSummary
        style={style}
        deposit={deposit}
        managementId={managementId}
        isFormerTenancy={isFormerTenancy}
        showManageMenu={showManageMenu}
      />
    </PropertyScreenCardErrorBoundary>
  );
};
