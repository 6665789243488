import React from "react";
import { Alert } from "@ailo/ui";
import { useGetManagementEndDateQuery } from "local/graphql";
import { LocalDate } from "@ailo/date";
import { StyleProp, ViewStyle } from "react-native";

export function ManagementEndDate({
  managementId,
  style
}: {
  managementId: string;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement | null {
  const { data } = useGetManagementEndDateQuery({
    variables: { managementId }
  });

  const managementEndDate = data?.management?.endDate;
  if (!managementEndDate) {
    return null;
  }

  const message = generateEndDateMessage({
    endDate: new LocalDate(managementEndDate)
  });

  return <Alert message={message} type={"warning"} style={style} />;
}

function generateEndDateMessage({ endDate }: { endDate: LocalDate }): string {
  const formattedDate = endDate.format("DD MMM YYYY");
  return endDate.isSameOrBefore(LocalDate.today())
    ? `Management ended on ${formattedDate}`
    : `Management ending on ${formattedDate}`;
}
