import { useNavigation } from "local/common";
import { useCallback } from "react";
import { getNavigateToPropertyAction } from "./getNavigateToPropertyAction";

export function useNavigateToProperty(): (managementId: string) => void {
  const navigation = useNavigation();

  return useCallback(
    (managementId: string): void => {
      navigation.dispatch(getNavigateToPropertyAction(managementId));
    },
    [navigation]
  );
}
