import React, { ReactElement } from "react";
import { Button } from "@ailo/ui";

export function CreateProjectButton({
  onPress
}: {
  onPress?: () => void;
}): ReactElement {
  return (
    <Button.Secondary type={"small"} style={{ height: 32 }} onPress={onPress}>
      {"Create"}
    </Button.Secondary>
  );
}
