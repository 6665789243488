import React from "react";
import styled from "styled-components/native";
import { Card } from "@ailo/ui";
import { Colors, House, SFC, Text } from "@ailo/primitives";
import { Image } from "react-native";

const PropertyOnboardingListEmpty: SFC<{
  message: string;
  secondaryMessage: React.ReactElement | string;
}> = ({ style, message, secondaryMessage }) => (
  <Container style={style}>
    <StyledImage source={House} />
    <StyledBodyL weight={"medium"}>{message}</StyledBodyL>
    {typeof secondaryMessage === "string" ? (
      <StyledBodyS color={Colors.TEXT.DARK.SECONDARY}>
        {secondaryMessage}
      </StyledBodyS>
    ) : (
      secondaryMessage
    )}
  </Container>
);

const Container = styled(Card)`
  justify-content: center;
  align-items: center;
  height: 360px;
  margin: 0px;
`;

const StyledBodyL = styled(Text.BodyL)`
  margin-bottom: 8px;
  text-align: center;
  margin-top: 16px;
`;

const StyledBodyS = styled(Text.BodyS)`
  text-align: center;
`;

const StyledImage = styled(Image)`
  width: 106px;
  height: 80px;
  align-self: center;
`;

export { PropertyOnboardingListEmpty };
