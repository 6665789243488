export const tabs = [
  {
    id: "upcoming" as const,
    label: "Upcoming"
  },
  {
    id: "overdue" as const,
    label: "Overdue"
  },
  {
    id: "paid" as const,
    label: "Paid"
  },
  {
    id: "cancelled" as const,
    label: "Cancelled"
  }
];

export type TabId = typeof tabs[0]["id"];

export const tabsWithCounters: TabId[] = ["upcoming", "overdue"];
