import React from "react";
import { Button } from "@ailo/ui";
import { View } from "react-native";

interface SidebarBottomProps {
  onCancel: () => void;
  onSave: () => void;
  disableSave: boolean;
}

export function SidebarBottom({
  onCancel,
  onSave,
  disableSave
}: SidebarBottomProps): React.ReactElement {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between"
      }}
    >
      <Button.Secondary onPress={onCancel}>{"Cancel"}</Button.Secondary>
      <Button.Primary onPress={onSave} disabled={disableSave}>
        {"Save"}
      </Button.Primary>
    </View>
  );
}
