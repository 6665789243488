import React, { useMemo } from "react";
import { GridSortOrder } from "@ailo/ui";
import {
  AddressCell,
  EndDateCell,
  EndReasonCell,
  TeamCell
} from "./PropertyItemCell";
import { usePropertyListReorder, useLostPropertyListQuery } from "./hooks";
import { lowerCase, startCase } from "lodash";
import { BasePropertyList, BasePropertyListProps } from "./BasePropertyList";
import { createCellRenderer } from "./createCellRenderer";
import { Colors, Text } from "@ailo/primitives";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";

function LostPropertyList({
  tabNavigation
}: {
  tabNavigation: React.ReactElement;
}): React.ReactElement {
  const queryData = useLostPropertyListQuery(initialSortOrder);
  const { sortOrder, onSortOrderChange } = usePropertyListReorder(
    initialSortOrder,
    queryData.requestFromBeginning,
    analyticsInfo
  );

  const isNewLostManagementsListEnabled = useHasFeature(
    PlatformFeatureId.NewLostManagementsList
  );

  const columns: BasePropertyListProps["columns"] = useMemo(() => {
    const teamColumn = {
      width: 2,
      renderCell: createCellRenderer(TeamCell),
      renderLoadingCell: TeamCell.Loading,
      name: LostPropertyListColumns.Team
    };
    const lostReasonColumn = {
      width: 4,
      renderCell: createCellRenderer(EndReasonCell),
      renderLoadingCell: EndReasonCell.Loading,
      name: LostPropertyListColumns.EndReason
    };
    return [
      {
        width: 5,
        renderCell: createCellRenderer(AddressCell),
        renderLoadingCell: AddressCell.Loading,
        name: LostPropertyListColumns.Properties,
        sortable: true
      },
      {
        verticalAlign: "middle",
        sortable: false,
        ...(isNewLostManagementsListEnabled ? lostReasonColumn : teamColumn)
      },
      {
        width: 2,
        renderCell: createCellRenderer(EndDateCell),
        renderLoadingCell: EndDateCell.Loading,
        name: LostPropertyListColumns.EndDate,
        sortable: true,
        caretPosition: "right",
        horizontalAlign: "left",
        verticalAlign: "middle"
      }
    ];
  }, [isNewLostManagementsListEnabled]);

  return (
    <BasePropertyList
      tabNavigation={tabNavigation}
      queryData={queryData}
      errorMessage={"There was a problem loading Lost"}
      emptyMessage={"Management ending"}
      secondaryEmptyMessage={
        <Text.BodyS
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ textAlign: "center" }}
        >
          {"Managements that have ended will appear here"}
        </Text.BodyS>
      }
      sortOrder={sortOrder}
      columns={columns}
      onSortOrderChange={onSortOrderChange}
    />
  );
}

export enum LostPropertyListColumns {
  Properties = "Properties",
  EndDate = "End date",
  Team = "Team",
  EndReason = "End reason"
}

const initialSortOrder: GridSortOrder = {
  columnKey: LostPropertyListColumns.EndDate,
  direction: "DESC"
};

function analyticsColName(columnKey: string): string {
  if (columnKey === LostPropertyListColumns.EndDate) return "End Date";
  if (columnKey === LostPropertyListColumns.Properties) return "Address";
  throw new Error(`Unexpected column to return analytics: ${columnKey}`);
}

function analyticsInfo(sortOrder: GridSortOrder): {
  eventName: string;
  orderBy: string;
} {
  return {
    eventName: "Lost Property List Reordered",
    orderBy: `${analyticsColName(sortOrder.columnKey)} ${startCase(
      lowerCase(sortOrder.direction)
    )}`
  };
}

export { LostPropertyList };
