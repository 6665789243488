import { useMountedState } from "@ailo/primitives";
import { useOnFocus } from "@ailo/services";
import { GridSortOrder } from "@ailo/ui";
import {
  PropertyListSelectedTeams,
  usePropertyListSelectedTeams
} from "./usePropertyListSelectedTeams";
import { ManagementSortField, SortDirection } from "local/graphql";
import { useCallback, useRef, useEffect } from "react";
import { PropertyListFilterStateType } from "../filters/usePropertyListFilterState";

interface PaginationState {
  currentPage: number;
  requestNewPage: (
    sortOrder: GridSortOrder,
    forward: boolean,
    pageNo: number
  ) => void;
  requestFromBeginning: (sortOrder: GridSortOrder) => void;
  filterVariables?: PropertyListFilterStateType;
}

export interface RequestCursor {
  cursor: string | null;
  pageSize: number;
  paginateBackward: boolean;
  sort?: {
    field: ManagementSortField;
    direction: SortDirection;
  };
}

interface BidirectionalCursor {
  nextPage: string | null;
  previousPage: string | null;
}

export const PROPERTY_LIST_PAGE_SIZE = 25;

export function usePaginationRequest(
  latestCursor: BidirectionalCursor,
  sendRequest: (
    selectedTeams: PropertyListSelectedTeams,
    cursor?: RequestCursor
  ) => void,
  sortStateToApiParam?: (s: GridSortOrder) => {
    field: ManagementSortField;
    direction: SortDirection;
  },
  filterVariables?: PropertyListFilterStateType
): PaginationState {
  /**
   * Payload cursor that was USED in the previous request
   * This is only used as input for re-requesting on screen refocus
   */
  const lastRequestCursor = useRef<RequestCursor | null>(null);

  /**
   * Cursor strings TO PERFORM the next request
   * This is essentially the output pageInfo from the previous request
   */
  const currentCursor = useRef<BidirectionalCursor>(latestCursor);

  const [currentPage, setCurrentPageNo] = useMountedState<number>(1);

  const selectedTeams = usePropertyListSelectedTeams();

  useEffect(() => {
    currentCursor.current = { nextPage: null, previousPage: null };
    if (lastRequestCursor.current) {
      lastRequestCursor.current = {
        sort: lastRequestCursor.current.sort,
        cursor: null,
        pageSize: PROPERTY_LIST_PAGE_SIZE,
        paginateBackward: false
      };
    }
    setCurrentPageNo(1);
  }, [selectedTeams, setCurrentPageNo, filterVariables]);

  useEffect(() => {
    currentCursor.current = latestCursor;
  }, [latestCursor]);

  const onFocus = useCallback((): void => {
    if (lastRequestCursor.current) {
      sendRequest(selectedTeams, lastRequestCursor.current);
      return;
    }

    sendRequest(selectedTeams);
  }, [sendRequest, selectedTeams]);

  useOnFocus(onFocus);

  const _request = useCallback(
    (
      sortOrder: GridSortOrder,
      forward: boolean,
      cursor: string | null
    ): void => {
      const isFirstPage = cursor === null;
      const paginateBackward = isFirstPage ? false : !forward;

      lastRequestCursor.current = {
        cursor,
        pageSize: PROPERTY_LIST_PAGE_SIZE,
        paginateBackward,
        sort: sortStateToApiParam?.(sortOrder)
      };

      sendRequest(selectedTeams, lastRequestCursor.current);

      if (isFirstPage) setCurrentPageNo(1);
      else if (paginateBackward) setCurrentPageNo(currentPage - 1);
      else setCurrentPageNo(currentPage + 1);
    },
    [
      currentPage,
      sendRequest,
      setCurrentPageNo,
      sortStateToApiParam,
      selectedTeams
    ]
  );

  const requestNewPage = useCallback(
    (sortOrder: GridSortOrder, forward: boolean, pageNo: number): void => {
      _request(
        sortOrder,
        forward,
        getCursorToQuery(forward, pageNo, currentCursor.current)
      );
    },
    [_request]
  );

  const requestFromBeginning = useCallback(
    (sortOrder: GridSortOrder): void => {
      _request(sortOrder, true, null);
    },
    [_request]
  );

  return {
    requestNewPage,
    requestFromBeginning,
    currentPage
  };
}

const getCursorToQuery = (
  forward: boolean,
  currPageNo: number,
  cursor: BidirectionalCursor
): string | null => {
  if (forward) return cursor.nextPage || null;

  if (currPageNo > 2) return cursor.previousPage || null;

  return null;
};
