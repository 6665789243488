import { DeleteIcon } from "@ailo/primitives";
import {
  Button,
  DropdownMenu,
  DropdownMenuOption,
  DropdownMenuToggleProps
} from "@ailo/ui";
import React from "react";
import { useInspectionPlanner } from "../../InspectionPlannerContext";

export function MoreButton(): React.ReactElement | null {
  const { selectedInspectionDay } = useInspectionPlanner();

  if (!selectedInspectionDay) {
    return null;
  }

  const options: DropdownMenuOption[] = [
    {
      label: "Cancel inspection day",
      onSelect: () => {
        //Archive all projects for the selected day here
      },
      icon: <DeleteIcon />
    }
  ];

  function renderDropdownButton({
    toggle,
    open
  }: DropdownMenuToggleProps): React.ReactElement {
    return (
      <Button variant={"more"} type={"small"} onPress={toggle} active={open} />
    );
  }

  return <DropdownMenu renderToggle={renderDropdownButton} options={options} />;
}
