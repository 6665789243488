import React, { ReactElement } from "react";
import { Colors, HouseWithLoading, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";

export function InProgressMigratingManagementBody(): ReactElement {
  return (
    <Container>
      <StyledHouseWithLoading />
      <StyledBodyL weight={"medium"}>{"In Progress"}</StyledBodyL>
      <StyledBodyS color={Colors.TEXT.DARK.SECONDARY}>
        {
          "Property will be set live and the renters and investors will be automatically invited to Ailo."
        }
      </StyledBodyS>
    </Container>
  );
}

const Container = styled(View)`
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  flex-grow: 1;
`;

const StyledHouseWithLoading = styled(HouseWithLoading)`
  width: 149px;
  height: 96px;
  align-self: center;
`;

const StyledBodyL = styled(Text.BodyL)`
  text-align: center;
  margin-top: 20px;
`;

const StyledBodyS = styled(Text.BodyS)`
  text-align: center;
  max-width: 280px;
  margin-top: 8px;
  margin-bottom: 12px;
`;
