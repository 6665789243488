import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { useOnFocus } from "@ailo/services";
import {
  SFC,
  Colors,
  LoadingSynchroniser,
  opacify,
  useSynchronisedLoad
} from "@ailo/primitives";
import { Card } from "@ailo/ui";
import { PropertyScreenCardErrorBoundary } from "local/domain/propertyManagement";
import {
  OccupancyHealthSection,
  PropertyHealthCardLoading,
  RentHealthSection
} from "./components";
import { useGetPropertyHealthQuery } from "local/graphql";

interface Props {
  managementId: string;
}

export const PropertyHealthCard: SFC<Props> = ({ style, managementId }) => {
  const { data, loading, refetch } = useGetPropertyHealthQuery({
    variables: { managementId }
  });

  useOnFocus(refetch);

  const isLoading = useSynchronisedLoad(loading && !data);

  if (isLoading) {
    return <PropertyHealthCardLoading style={style} />;
  }

  return (
    <PropertyScreenCardErrorBoundary
      style={style}
      cardTitle={"Property Health"}
    >
      <LoadingSynchroniser>
        <StyledCard style={style}>
          <Row>
            <StyledRentHealthSection managementId={managementId} />
            <StyledOccupancyHealthSection managementId={managementId} />
          </Row>
        </StyledCard>
      </LoadingSynchroniser>
    </PropertyScreenCardErrorBoundary>
  );
};

const StyledCard = styled(Card)`
  margin: 0;
  padding: 0;
`;

const Row = styled(View)`
  flex-direction: row;
`;

const StyledRentHealthSection = styled(RentHealthSection)`
  flex: 1;
`;

const StyledOccupancyHealthSection = styled(OccupancyHealthSection)`
  flex: 1;
  border-left-width: 1px;
  border-left-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;
