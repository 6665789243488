import React, { useContext } from "react";
import { LoadingSplashPage } from "@ailo/ui";
import { useStoredSelectedTeams } from "./useStoredSelectedTeams";
import { ChatTeamId, DEFAULT_CHAT_TEAM_ID } from "@ailo/domains";

export interface SelectedTeamsData {
  propertyListUnassignedSelected: boolean;
  updatePropertyListUnassignedSelected: (selected: boolean) => void;
  propertyListSelectedTeamIds: string[];
  updatePropertyListTeamIds: (teamIds: string[]) => void;
  chatSelectedTeamId: ChatTeamId;
  updateChatTeamId: (teamId: ChatTeamId) => void;
}

const SelectedTeamContext = React.createContext<SelectedTeamsData>({
  propertyListUnassignedSelected: false,
  updatePropertyListUnassignedSelected: (): void => {},
  propertyListSelectedTeamIds: [],
  updatePropertyListTeamIds: (): void => {},
  chatSelectedTeamId: DEFAULT_CHAT_TEAM_ID,
  updateChatTeamId: (): void => {}
});

function SelectedTeamContextProvider({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const {
    propertyListUnassignedSelected,
    updatePropertyListUnassignedSelected,
    propertyListSelectedTeamIds,
    updatePropertyListTeamIds,
    chatSelectedTeamId,
    updateChatTeamId,
    initialized
  } = useStoredSelectedTeams();

  if (!initialized) {
    return <LoadingSplashPage />;
  }

  return (
    <SelectedTeamContext.Provider
      value={{
        propertyListUnassignedSelected,
        updatePropertyListUnassignedSelected,
        propertyListSelectedTeamIds,
        updatePropertyListTeamIds,
        chatSelectedTeamId,
        updateChatTeamId
      }}
    >
      {children}
    </SelectedTeamContext.Provider>
  );
}

function useSelectedTeams(): SelectedTeamsData {
  return useContext(SelectedTeamContext);
}

function useSelectedTeamAnalytics(): { filteredByTeamId: string[] } {
  const teams = useSelectedTeams();
  return { filteredByTeamId: teams?.propertyListSelectedTeamIds };
}

export {
  SelectedTeamContextProvider,
  useSelectedTeams,
  useSelectedTeamAnalytics
};
