import { ParamListBase } from "@react-navigation/native";
import { Money } from "@ailo/ui";
import { Screens } from "../Screens";
import { ClaimTenancyData } from "local/domain/bond";
import { OccupancyStatus } from "local/graphql";

export enum PropertyListView {
  PROPERTIES = "Properties",
  ARREARS = "Arrears",
  OVERDUE = "Overdue",
  RENEWALS_AND_REVIEWS = "Renewals & Reviews",
  LOST = "Former managements"
}

export interface PropertiesTabStackNavigatorParamList extends ParamListBase {
  [Screens.AddExpense]: {
    managementId: string;
  };
  [Screens.Property]: {
    managementId: string;
  };
  [Screens.PropertyList]: {
    tab: PropertyListView;
    statuses?: OccupancyStatus[];
  };
  [Screens.CreateRentAdjustment]: {
    managementId: string;
    tenancyId: string;
  };
  [Screens.RentAdjustments]: {
    managementId: string;
    tenancyId: string;
    adjustmentId?: string;
  };
  [Screens.ClaimTenancyBond]: {
    managementId: string;
    tenancyId: string;
  };
  [Screens.ClaimTenancyBondSuccess]: {
    managementId: string;
    tenancyId: string;
    amountClaimed: Money;
    data: ClaimTenancyData;
    onClose: () => void;
  };
  [Screens.EditTenancyBondClaim]: {
    managementId: string;
    tenancyId: string;
  };
}
