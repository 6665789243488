import { Colors, Text } from "@ailo/primitives";
import {
  FormFieldRow,
  Money,
  TextInputFormField,
  validateMaxLength
} from "@ailo/ui";
import { Controller, UseFormMethods } from "react-hook-form";
import React from "react";
import { Platform, View } from "react-native";
import { ReverseRentAdjustmentFormData } from "../ReverseRentAdjustmentModal";
import { useEnvironment } from "@ailo/services";
import { adjustmentTypeFromAmount } from "../../RentAdjustmentSidebar/adjustmentTypeFromAmount";

interface AddReversalDescriptionStepProps {
  amount: Money;
  display: boolean;
  form: UseFormMethods<ReverseRentAdjustmentFormData>;
}
export function AddReversalDescriptionStep({
  amount,
  display,
  form
}: AddReversalDescriptionStepProps): React.ReactElement {
  const { RENT_CREDIT_IN_THE_PAST_HELP_URL } = useEnvironment();
  const originalAdjustmentType = adjustmentTypeFromAmount(amount).toLowerCase();
  const reversalType = originalAdjustmentType === "credit" ? "debit" : "credit";

  return (
    <View style={display ? {} : { display: "none" }}>
      <Text.BodyM>
        {"A "}
        {reversalType}
        {" for the amount "}
        <Text.BodyM weight={"medium"}>{amount.abs().format()}</Text.BodyM>
        {" will appear in the renter/investor app and statements."}
      </Text.BodyM>

      <Text.BodyM
        color={Colors.AILO_RED}
        weight={"medium"}
        style={{ marginBottom: 40, marginTop: 12 }}
        onPress={() =>
          Platform.OS === "web" &&
          window.open(RENT_CREDIT_IN_THE_PAST_HELP_URL, "_blank")
        }
      >
        {"Learn more"}
      </Text.BodyM>

      <FormFieldRow>
        <Controller
          control={form.control}
          name={"description"}
          render={({ value, onChange, onBlur }) => (
            <TextInputFormField
              {...{ value, onChange, onBlur }}
              error={form.errors["description"]?.message}
              helperText={
                "Important: your description will be visible to both renters and investors"
              }
              label={"Description"}
              multiline
              numberOfLines={3}
              placeholder={`Reason for reversing rent ${originalAdjustmentType}`}
              softCharacterLimit={{
                enforcementLevel: "error",
                limit: 160
              }}
              testID={"ReverseRentAdjustmentDescriptionInputFormField"}
            />
          )}
          rules={{
            required: "Please enter a description",
            validate: { maxLength: validateMaxLength(160) }
          }}
        />
      </FormFieldRow>
    </View>
  );
}
