import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import { useCallback } from "react";
import {
  UpdateManagementFeeBlueprintMutation,
  useUpdateManagementFeeBlueprintMutation,
  UpdateManagementFeeBlueprintInput,
  GetManagementFeeBlueprintsDocument
} from "local/graphql";

export function useEditManagementFeeBlueprint(): [
  editManagementFeeBlueprint: (input: {
    updateInput: UpdateManagementFeeBlueprintInput;
    managementId: string;
  }) => Promise<
    UpdateManagementFeeBlueprintMutation["updateManagementFeeBlueprint"]
  >,
  loading: boolean
] {
  const analytics = useAnalytics();
  const toast = useToastContext();

  const [updateManagementFeeBlueprintMutation, { loading }] =
    useUpdateManagementFeeBlueprintMutation();

  const editManagementFeeBlueprint = useCallback(
    async ({
      updateInput,
      managementId
    }: {
      updateInput: UpdateManagementFeeBlueprintInput;
      managementId: string;
    }) => {
      if (loading) return;

      try {
        const result = await updateManagementFeeBlueprintMutation({
          variables: {
            input: updateInput
          },
          refetchQueries: [
            {
              query: GetManagementFeeBlueprintsDocument,
              variables: {
                conditions: {
                  managementId,
                  archived: false
                }
              }
            }
          ]
        });
        throwIfMutationFailed(result, {
          dataKey: "updateManagementFeeBlueprint"
        });

        analytics.track("Fee Blueprint Edited in Property Schedule", {
          feeBlueprintId: updateInput.id,
          managementId
        });

        return result.data?.updateManagementFeeBlueprint;
      } catch (error) {
        AiloSentry.captureException(error);
        toast.showFormSubmitError();
      }
    },
    [loading, updateManagementFeeBlueprintMutation, analytics, toast]
  );

  return [editManagementFeeBlueprint, loading];
}
