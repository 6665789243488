import {
  formatOrdinalInteger,
  FormField,
  FormFieldRow,
  RadioButtonGroup,
  SelectInput
} from "@ailo/ui";
import { capitalize } from "lodash";
import moment from "moment";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ManageFeeBlueprintFormData } from "./ManageFeeBlueprintForm";

export function FrequencyDetails({
  mode
}: {
  mode: "create" | "update";
}): ReactElement {
  const form = useFormContext<ManageFeeBlueprintFormData>();
  const frequency = form.watch("frequency");

  return (
    <>
      <Controller
        control={form.control}
        name={"frequency"}
        defaultValue={null}
        render={({ value, onChange, onBlur }) => (
          <FormField
            label={"How often should this fee be charged?"}
            error={form.errors.frequency?.message}
            style={{ marginBottom: 24 }}
          >
            <RadioButtonGroup
              {...{ value, onChange, onBlur }}
              variant={"button-solid-group"}
              options={["weekly", "monthly", "annually"].map((value) => ({
                value,
                label: capitalize(value)
              }))}
              disabled={mode === "update"}
            />
          </FormField>
        )}
        rules={{
          required: "Frequency is required."
        }}
      />

      {frequency === "weekly" && (
        <FormFieldRow cols={2} style={{ marginBottom: 24 }}>
          <Controller
            control={form.control}
            name={"chargeDateDayOfWeek"}
            defaultValue={null}
            render={({ value, onChange }) => (
              <FormField
                label={"Day of the week"}
                error={form.errors.chargeDateDayOfWeek?.message}
              >
                <SelectInput
                  value={String(value)}
                  placeholder={"Select"}
                  options={[...Array(7).keys()].map((i) => ({
                    value: String(i + 1),
                    label: moment.weekdays((i + 1) % 7)
                  }))}
                  onChange={(option) => onChange(Number(option?.value))}
                />
              </FormField>
            )}
            rules={{
              required: "Default Charge Date is required."
            }}
          />
        </FormFieldRow>
      )}

      {frequency === "annually" && (
        <FormFieldRow cols={2} style={{ marginBottom: 24 }}>
          <Controller
            control={form.control}
            name={"chargeDateMonth"}
            defaultValue={null}
            render={({ value, onChange }) => (
              <FormField
                label={"Month"}
                error={form.errors.chargeDateMonth?.message}
              >
                <SelectInput
                  value={String(value)}
                  placeholder={"Select"}
                  options={[...Array(12).keys()].map((i) => ({
                    value: String(i + 1),
                    label: moment.months(i)
                  }))}
                  onChange={(option) => onChange(Number(option?.value))}
                />
              </FormField>
            )}
            rules={{
              required: "Default Charge Month is required."
            }}
          />
        </FormFieldRow>
      )}

      {(frequency === "monthly" || frequency === "annually") && (
        <FormFieldRow cols={2} style={{ marginBottom: 24 }}>
          <Controller
            control={form.control}
            name={"chargeDateDayOfMonth"}
            defaultValue={null}
            render={({ value, onChange }) => (
              <FormField
                label={"Day of the month"}
                error={form.errors.chargeDateDayOfMonth?.message}
              >
                <SelectInput
                  value={String(value)}
                  placeholder={"Select"}
                  options={[...Array(28).keys()].map((i) => ({
                    value: String(i + 1),
                    label: formatOrdinalInteger(i + 1)
                  }))}
                  onChange={(option) => onChange(Number(option?.value))}
                />
              </FormField>
            )}
            rules={{
              required: "Default Charge Date is required."
            }}
          />
        </FormFieldRow>
      )}
    </>
  );
}
