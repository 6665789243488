import React, { ReactNode, useCallback } from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import { Colors, opacify, SFC, Skeleton } from "@ailo/primitives";
import { SwitcherOption } from "./components";

interface Statics {
  Loading: typeof Loading;
}

export interface BadgeQueryParams {
  queryString?: string;
}

export interface SwitcherHeaderOption {
  label: string;
  badge?: React.ComponentType<{
    active?: boolean;
    params?: BadgeQueryParams;
  }>;
  params?: BadgeQueryParams;
}

interface Props {
  options: (SwitcherHeaderOption | string)[];
  onPress?: (i: number) => void;
  selected?: number;
}

/**
 * @deprecated Use `TabNavigation` from `@ailo/ui` instead.
 */
const SwitcherHeader: SFC<Props> & Statics = ({
  style,
  options,
  onPress,
  selected = 0
}) => {
  const onOptionPress = useCallback(
    (i: number) => {
      if (i === selected) return;
      onPress && onPress(i);
    },
    [onPress, selected]
  );

  return (
    <OuterContainer style={style}>
      <InnerContainer>
        {options.map(
          (option, i): ReactNode => (
            <SwitcherOption
              key={i}
              option={option}
              selected={selected === i}
              onPress={(): void => onOptionPress(i)}
            />
          )
        )}
      </InnerContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled(View)`
  flex-direction: row;
  padding: 32px 24px 24px;
`;

const InnerContainer = styled(View)`
  flex-direction: row;
  flex: 1;
  border-bottom-width: 1px;
  border-bottom-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;

const Loading: SFC = ({ style }) => {
  return (
    <OuterContainer style={style}>
      <Skeleton style={{ width: 432, height: 40, borderRadius: 20 }} />
    </OuterContainer>
  );
};

SwitcherHeader.Loading = Loading;

export { SwitcherHeader };
