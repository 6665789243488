import React from "react";
import { View, ViewStyle, StyleProp } from "react-native";
import { RadioButtonGroup, TextInputFormField } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import { VacatingReason } from "local/graphql";

interface Props {
  style?: StyleProp<ViewStyle>;
  allVacatingReasons: { value: VacatingReason; label: string }[];
  vacatingReason: VacatingReason;
  vacatingNotes: string;
  onVacatingNoteChanged: (notes: string) => void;
  onVacatingReasonChanged: (reason?: VacatingReason) => void;
}

export function VacateReasonAndNotes({
  style,
  onVacatingReasonChanged,
  onVacatingNoteChanged,
  allVacatingReasons,
  vacatingReason,
  vacatingNotes
}: Props): React.ReactElement {
  return (
    <View style={style}>
      <RadioButtonGroup
        options={allVacatingReasons}
        onChange={onVacatingReasonChanged}
        value={vacatingReason}
        style={{ marginBottom: 32 }}
      />
      <TextInputFormField
        label={"Note"}
        numberOfLines={4}
        multiline
        placeholder={"Add a note..."}
        onChangeText={onVacatingNoteChanged}
        autoExpandVertically
        maxHeightToAutoExpand={300}
        value={vacatingNotes}
      />
      <Text.BodyXS
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginTop: 4, marginLeft: 12 }}
      >
        {"Private note only visible within your agency"}
      </Text.BodyXS>
    </View>
  );
}
