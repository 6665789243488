import {
  ChatFileContext,
  ChatScreenHeader,
  useChatContext,
  useThreadContext,
  useWatchChatNavigationParams
} from "@ailo/domains";
import { LoadingSynchroniser } from "@ailo/primitives";
import { ContentWithSidebar } from "@ailo/ui";
import { ScreenComponent } from "@ailo/services";
import { useLayout } from "@react-native-community/hooks";
import React, { ReactElement, useMemo } from "react";
import { ChatScreenContainer } from "./ChatScreenContainer";
import { AgencyThreadList } from "./AgencyThreadList";
import { ChatSidebar } from "./ChatSidebar";
import { useSetChatTitle } from "./useSetChatTitle";
import { Screens, useRoute } from "local/common";
import { useCloseThread, useOpenThread } from "local/tabs/comms/navigation";

export function ChatMessageScreen(): ReactElement {
  const { onLayout } = useLayout();
  const { chatId, threadId } = useRoute<Screens.ChatThread>().params || {};
  useWatchChatNavigationParams({ params: { chatId, threadId } });

  const openThread = useOpenThread();
  const closeThread = useCloseThread();

  const {
    selectedChatAilorn,
    selectedChat: { error, chat },
    drafts
  } = useChatContext();
  const { selectedThreadAilorn } = useThreadContext();

  useSetChatTitle();

  const analyticsTrackPayload = useMemo(
    () => ({ chatId: selectedChatAilorn?.internalId }),
    [selectedChatAilorn]
  );

  return (
    <ScreenComponent analyticsTrackPayload={analyticsTrackPayload}>
      <ContentWithSidebar
        open={selectedThreadAilorn !== undefined}
        sidebarContent={<ChatSidebar />}
        position={"static"}
        dismissOnBackdropPress={false}
        hasBackdrop={false}
        hasShadow={false}
        animateOnMount={false}
        onCloseEnd={closeThread}
      >
        <LoadingSynchroniser>
          <ChatScreenContainer onLayout={onLayout}>
            <ChatFileContext
              drafts={drafts}
              messageParent={selectedChatAilorn}
              dropDisabled={chat?.readonly}
            >
              {!error ? <ChatScreenHeader /> : null}

              <AgencyThreadList openThread={openThread} />
            </ChatFileContext>
          </ChatScreenContainer>
        </LoadingSynchroniser>
      </ContentWithSidebar>
    </ScreenComponent>
  );
}
