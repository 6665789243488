import React, { ReactElement } from "react";
import { View } from "react-native";
import { SecondaryText } from "../../../SecondaryText";
import { PrimaryText } from "../../../PrimaryText";
import { useTotalAmountToClaim } from "../../useTotalAmountToClaim";
import { ClaimTenancyData } from "local/domain/bond";
import { Money } from "@ailo/ui";
import styled from "styled-components/native";

export function ClaimedAndReturnedBondAmounts({
  data
}: {
  data: ClaimTenancyData;
}): ReactElement {
  const totalAmountToClaim = useTotalAmountToClaim();
  const bondAmountReturned = Money.fromCents(
    data.bond.amount.cents - totalAmountToClaim.cents
  );
  return (
    <Row style={{ marginTop: 20 }}>
      <Column style={{ marginRight: 12 }}>
        <SecondaryText style={{ marginBottom: 4 }}>
          {"Requested by agency"}
        </SecondaryText>
        <PrimaryText>{totalAmountToClaim.format()}</PrimaryText>
      </Column>

      <Column>
        <SecondaryText style={{ marginBottom: 4 }}>
          {"Release to renters"}
        </SecondaryText>
        <PrimaryText>{bondAmountReturned.format()}</PrimaryText>
      </Column>
    </Row>
  );
}

const Row = styled(View)`
  flex-direction: row;
`;

const Column = styled(View)`
  flex: 1;
`;
