import moment from "moment";
import { useState, useCallback } from "react";
import { useMountedState } from "@ailo/primitives";
import {
  GetTenanciesDocument,
  GetPropertyRentHealthDocument,
  GetPropertyOccupancyHealthDocument,
  VacatingReason,
  useUpdateEndOfTenancyMutation,
  GetTenancyOutstandingLiabilityDocument,
  GetTenancyOutstandingLiabilityQuery
} from "local/graphql";
import { ApolloClient, ApolloError, useApolloClient } from "@apollo/client";
import { useToastContext } from "@ailo/ui";
import { useAnalytics } from "@ailo/services";
import { GetRentHistoryForTenancyDocument } from "@ailo/domains";

export function useSubmit({
  isEdit,
  tenancyId,
  managementId,
  tenancyEndDate,
  vacatingReason,
  vacatingNotes,
  onSubmitSuccess
}: {
  isEdit: boolean;
  tenancyId: string;
  managementId: string;
  tenancyEndDate?: moment.Moment;
  vacatingReason: VacatingReason;
  vacatingNotes: string;
  onSubmitSuccess: () => void;
}): {
  submitting: boolean;
  onSubmit: () => Promise<void>;
  confirmModal: {
    visible: boolean;
    open: () => void;
    close: () => void;
  };
  errorModal: {
    visible: boolean;
    close: () => void;
  };
} {
  const analytics = useAnalytics();
  const toast = useToastContext();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useMountedState(false);

  const { submit: sendUpdateEndOfTenancyReq, submitting } =
    useUpdateEndOfTenancy(tenancyId, managementId);

  const doUpdateEndOfTenancy = useCallback(
    async (
      endDate: moment.Moment
    ): Promise<{
      success: boolean;
    }> => {
      const result = await sendUpdateEndOfTenancyReq(
        endDate,
        vacatingReason,
        vacatingNotes
      );

      if (result.success) {
        toast.show({
          type: "success",
          message: "The tenancy has been updated"
        });
      }

      return result;
    },
    [sendUpdateEndOfTenancyReq, vacatingReason, vacatingNotes, toast]
  );

  const onSubmit = useCallback(async (): Promise<void> => {
    analytics.track(isEdit ? "End Tenancy Modified" : "End Tenancy Confirmed");

    if (!tenancyEndDate) return;

    setConfirmModalVisible(false);

    const { success } = await doUpdateEndOfTenancy(tenancyEndDate);

    if (success) {
      onSubmitSuccess();
    } else {
      setErrorModalVisible(true);
    }
  }, [
    analytics,
    isEdit,
    tenancyEndDate,
    doUpdateEndOfTenancy,
    onSubmitSuccess,
    setErrorModalVisible
  ]);

  return {
    submitting,
    onSubmit,
    confirmModal: {
      visible: confirmModalVisible,
      open: (): void => {
        setConfirmModalVisible(true);
      },
      close: (): void => {
        setConfirmModalVisible(false);
      }
    },
    errorModal: {
      visible: errorModalVisible,
      close: (): void => {
        setErrorModalVisible(false);
      }
    }
  };
}

function useUpdateEndOfTenancy(
  tenancyId: string,
  managementId: string
): {
  submit: (
    date: moment.Moment,
    reason: VacatingReason,
    notes: string
  ) => Promise<{ success: boolean }>;
  submitting: boolean;
} {
  const [updateEndOfTenancy, { loading: submitting }] =
    useUpdateEndOfTenancyMutation();
  const apolloClient = useApolloClient();

  const submit = async (
    date: moment.Moment,
    reason: VacatingReason,
    notes?: string
  ): Promise<{ success: boolean }> => {
    try {
      const { errors } = await updateEndOfTenancy({
        variables: {
          id: tenancyId,
          endDate: date.format("YYYY-MM-DD"),
          reason,
          notes
        },
        refetchQueries: [
          { query: GetTenanciesDocument, variables: { managementId } },
          { query: GetPropertyRentHealthDocument, variables: { managementId } },
          {
            query: GetPropertyOccupancyHealthDocument,
            variables: { managementId }
          }
        ]
      });

      if (!errors) {
        refreshRentHistory(apolloClient, tenancyId);
        refreshOutstandingLiability(apolloClient, tenancyId);
      }

      return { success: !errors };
    } catch (e) {
      if (e instanceof ApolloError) return { success: false };
      throw e;
    }
  };
  return {
    submitting,
    submit
  };
}

function refreshRentHistory(
  apolloClient: ApolloClient<object>,
  tenancyId: string
): void {
  for (let i = 1; i <= 5; i++) {
    setTimeout(() => {
      apolloClient.query({
        query: GetRentHistoryForTenancyDocument,
        variables: { tenancyId, pageSize: 1000 },
        fetchPolicy: "network-only"
      });
    }, 1000 * i);
  }
}

function refreshOutstandingLiability(
  apolloClient: ApolloClient<object>,
  tenancyId: string
): void {
  const liabilityInterval = setInterval(async () => {
    const liability =
      await apolloClient.query<GetTenancyOutstandingLiabilityQuery>({
        query: GetTenancyOutstandingLiabilityDocument,
        variables: { id: tenancyId },
        fetchPolicy: "network-only"
      });
    if (liability.data.tenancy?.liability?.terminationAmountDue != null) {
      clearInterval(liabilityInterval);
    }
  }, 1000);
}
