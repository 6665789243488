import React from "react";
import {
  CustomersListQueryData,
  CustomersListRow,
  useCustomersListQuery
} from "./useCustomersListQuery";
import {
  EmptyGrid,
  ErrorCard,
  Grid,
  GridColumnOptions,
  PaginationNavigation
} from "@ailo/ui";
import {
  CONTACTS_LIST_PAGE_SIZE,
  ContactsListLoadingRow
} from "../usePaginationRequest";
import { NameCell, RoleCell, TypeCell } from "./CustomerItemCell";
import { AiloRN } from "@ailo/ailorn";
import { useAnalytics } from "local/common";
import { EmailAddressCell, PhoneNumberCell } from "../ContactItemCell";
import { Customers } from "@ailo/primitives";
import { Image } from "react-native";

export enum CustomersListColumns {
  Name = "Name",
  Type = "Type",
  EmailAddress = "Email Address",
  PhoneNumber = "Phone Number",
  Role = "Role"
}

export interface CustomersListProps {
  tabNavigation: React.ReactElement;
  queryData: CustomersListQueryData;
  errorMessage: string;
  emptyMessage: string;
  secondaryEmptyMessage: React.ReactElement | string;
  columns: Array<
    GridColumnOptions<CustomersListRow> & {
      renderLoadingCell: GridColumnOptions<ContactsListLoadingRow>["renderCell"];
    }
  >;
  onSortOrderChange?: () => void;
}

const columns: CustomersListProps["columns"] = [
  {
    width: 2,
    renderCell: NameCell,
    renderLoadingCell: NameCell.Loading,
    name: CustomersListColumns.Name,
    sortable: false
  },
  {
    width: 1,
    renderCell: TypeCell,
    renderLoadingCell: TypeCell.Loading,
    name: CustomersListColumns.Type,
    sortable: false
  },
  {
    width: 2,
    renderCell: EmailAddressCell,
    renderLoadingCell: EmailAddressCell.Loading,
    name: CustomersListColumns.EmailAddress,
    sortable: false
  },
  {
    width: 1,
    renderCell: PhoneNumberCell,
    renderLoadingCell: PhoneNumberCell.Loading,
    name: CustomersListColumns.PhoneNumber,
    sortable: false
  },
  {
    width: 1,
    renderCell: RoleCell,
    renderLoadingCell: RoleCell.Loading,
    name: CustomersListColumns.Role,
    sortable: false
  }
];

function CustomersListContent({
  queryString,
  onCustomerPress,
  header
}: {
  queryString: string;
  onCustomerPress: (id: string, entityType?: "Person" | "Company") => void;
  header: (showSearchBar?: boolean) => React.ReactElement;
}): React.ReactElement {
  const analytics = useAnalytics();

  const queryData = useCustomersListQuery(queryString);
  const { data, loading, requestFromBeginning, requestNewPage } = queryData;

  const pagination = {
    currentPage: data.pageInfo.pageNo,
    pagesCount: Math.ceil(data.pageInfo.total / CONTACTS_LIST_PAGE_SIZE)
  };

  if (loading)
    return (
      <Grid
        headerCaption={header(true)}
        testID={"base-customers-grid"}
        style={{ marginTop: 0 }}
        rows={[{ key: "1" }, { key: "2" }, { key: "3" }]}
        columns={columns.map((col) => ({
          ...col,
          renderCell: col.renderLoadingCell
        }))}
        rowStyle={{
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0
        }}
        bodyStyle={{
          marginTop: -38,
          paddingTop: 38
        }}
      />
    );

  if (data.error)
    return (
      <Grid.Placeholder headerCaption={header(false)} style={{ marginTop: 0 }}>
        <ErrorCard
          message={"There was a problem loading Customers"}
          onReload={(): void => requestFromBeginning()}
          style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
        />
      </Grid.Placeholder>
    );

  if (data.pageInfo.total === 0) {
    if (queryString) {
      analytics.trackContactsSearchNoResults({
        contactType: "Customer",
        searchString: queryString
      });
      return (
        <Grid.Placeholder headerCaption={header(true)} style={{ marginTop: 0 }}>
          <EmptyGrid
            message={"We couldn't find anything matching your search"}
            secondaryMessage={"Please try a different search term"}
            style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
          />
        </Grid.Placeholder>
      );
    }
    return (
      <Grid.Placeholder headerCaption={header(false)} style={{ marginTop: 0 }}>
        <EmptyGrid
          message={"No Customers"}
          secondaryMessage={"A list of all your customers will be shown here"}
          bannerImage={
            <Image
              source={Customers}
              style={{ width: 106, height: 106, alignSelf: "center" }}
            />
          }
          style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
        />
      </Grid.Placeholder>
    );
  }

  return (
    <Grid
      headerCaption={header(true)}
      footerCaption={
        <PaginationNavigation
          {...pagination}
          style={{
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            marginTop: 24,
            marginBottom: 32,
            justifyContent: "center"
          }}
          onGoToPreviousPage={(): void => {
            requestNewPage(pagination.currentPage - 1);
          }}
          onGoToNextPage={(): void => {
            requestNewPage(pagination.currentPage + 1);
          }}
        />
      }
      columns={columns}
      rows={data.customers}
      rowStyle={{
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0
      }}
      style={{ marginTop: 0 }}
      bodyStyle={{
        marginTop: -38,
        paddingTop: 38
      }}
      onRowPress={(rowData) =>
        onCustomerPress(
          AiloRN.fromString(rowData.id).internalId,
          rowData.legalEntity?.__typename
        )
      }
    />
  );
}

export { CustomersListContent };
