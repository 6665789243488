import {
  FormInputValues,
  ModalFilter,
  useFilterModal,
  useFilterModalArgs,
  UseFilterReturnValue
} from "@ailo/ui";
import { Screens, useAnalytics, useRoute } from "local/common";
import { camelCase, get, keyBy } from "lodash";
import { reports } from "../reportsDetails";

export interface ReportModalFilter extends ModalFilter {
  stateForAnalytics?: (
    filterState: string | boolean | string[]
  ) => string | string[];
}

const isReportModalFilter = (f: ModalFilter): f is ReportModalFilter =>
  (f as ReportModalFilter).stateForAnalytics !== undefined;

/**
 * Wraps useFilterModal by adding common reporting functionality - e.g. analytics for filters
 */
export function useReportModalFilter(
  args: useFilterModalArgs
): UseFilterReturnValue {
  const analytics = useAnalytics();
  const route = useRoute<Screens.ReportVisulisation>();
  const report = reports[route.params!.reportName];
  const track = (name: string, options?: Record<string, {}>): void =>
    analytics.track(name, { reportTitle: report.reportHeading, ...options });

  const filtersByKey = keyBy(args.modalFilters, (f) => f.filterKey);
  const getFilter = (key: string): ModalFilter | null => get(filtersByKey, key);

  const onSubmit = (values: FormInputValues): void => {
    args.onSubmit(values);

    const valuesForAnalytics = Object.fromEntries(
      Object.entries(values).map(([k, v]) => {
        const modal = getFilter(k);
        return [
          camelCase(`filter_${k}`),
          modal && isReportModalFilter(modal) && modal.stateForAnalytics
            ? modal.stateForAnalytics(v)
            : v
        ];
      })
    );
    track("Report Filter Applied", valuesForAnalytics);
  };

  const filterModal = useFilterModal({ ...args, onSubmit });
  return filterModal;
}
