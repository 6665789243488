import { AiloRN } from "@ailo/ailorn";
import { getPriceChangeDecription } from "@ailo/domains";
import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { MoneyInterface, useToastContext } from "@ailo/ui";
import { useCallback } from "react";
import { useUpdateOneOffFeeMutation } from "local/graphql";

export interface UpdateOneOffFeeDetails {
  ailorn: AiloRN;
  totalAmount: MoneyInterface;
  taxAmount: MoneyInterface;
  baseAmount?: MoneyInterface;
  percentage?: number;
  description: string | null;
  blueprintId: string;
  blueprintAmount?: MoneyInterface;
  blueprintPercent?: number;
}

export function useUpdateOneOffFee(): [
  updateOneOffFee: (input: UpdateOneOffFeeDetails) => Promise<void>,
  loading: boolean
] {
  const analytics = useAnalytics();
  const toast = useToastContext();

  const [updateOneOffFeeMutation, { loading }] = useUpdateOneOffFeeMutation();

  const updateOneOffFee = useCallback(
    async ({
      ailorn,
      totalAmount,
      taxAmount,
      baseAmount,
      percentage,
      description,
      blueprintId,
      blueprintAmount,
      blueprintPercent
    }: UpdateOneOffFeeDetails) => {
      if (loading) return;

      try {
        const result = await updateOneOffFeeMutation({
          variables: {
            input: {
              id: ailorn.internalId,
              amount: { cents: totalAmount.cents },
              taxAmount: { cents: taxAmount.cents },
              baseAmount: baseAmount ? { cents: baseAmount.cents } : null,
              percentage: percentage ?? null,
              description
            }
          }
        });
        throwIfMutationFailed(result, { dataKey: "updateFee" });

        toast.show({
          type: "success",
          message: "Fee has been updated"
        });

        const totalAmountState =
          totalAmount && blueprintAmount
            ? {
                current: totalAmount,
                original: blueprintAmount
              }
            : percentage !== undefined && blueprintPercent !== undefined
            ? {
                current: percentage,
                original: blueprintPercent
              }
            : undefined;

        analytics.track("Fee Edited on Property", {
          feeFrequency: "one-off",
          feeBlueprintId: blueprintId,
          feePrice: totalAmountState
            ? getPriceChangeDecription(totalAmountState)
            : "Unknown"
        });
      } catch (error) {
        AiloSentry.captureException(error);
        toast.showFormSubmitError();
      }
    },
    [loading, updateOneOffFeeMutation, toast, analytics]
  );

  return [updateOneOffFee, loading];
}
