import { useCallback, useMemo } from "react";
import { useCurrentAgencyOrg } from "local/common";
import {
  CONTACTS_LIST_PAGE_SIZE,
  RequestPaginationParams,
  usePaginationRequest
} from "../usePaginationRequest";
import {
  ContactType,
  SortDirection,
  useGetPagedContactsLazyQuery
} from "local/graphql";
import { PersonAvatarProps } from "@ailo/ui";
import { isPresent } from "ts-is-present";

export const CONTACT_TYPE_INVESTOR = "Investor";
export const CONTACT_TYPE_TENANT = "Tenant";

export interface CustomersListRow {
  id: string;
  legalEntity?: {
    firstName?: string | null;
    lastName?: string | null;
    emailAddress?: string | null;
    phoneNo?: string | null;
  } & (
    | {
        __typename: "Company";
        registeredEntityId?: string | null;
        registeredEntityName: string;
      }
    | (PersonAvatarProps["person"] & {
        __typename: "Person";
      })
  );
  type: ContactType[];
  email?: string | null;
  phoneNo?: string | null;
  key: string;
}

export interface CustomersListQueryData {
  data: {
    pageInfo: {
      total: number;
      pageNo: number;
      hasNext: boolean;
      hasPrevious: boolean;
    };
    error: boolean;
    customers: CustomersListRow[];
  };
  loading: boolean;
  requestNewPage: (pageNo: number) => void;
  requestFromBeginning: () => void;
}

export function useCustomersListQuery(
  queryString: string = ""
): CustomersListQueryData {
  const currentOrg = useCurrentAgencyOrg();
  const [request, { data, loading, called }] = useGetPagedContactsLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const sendRequest = useCallback(
    (
      paginationParams: RequestPaginationParams = {
        page: 1,
        pageSize: CONTACTS_LIST_PAGE_SIZE
      }
    ) => {
      request({
        variables: {
          query: queryString,
          typeOf: [CONTACT_TYPE_INVESTOR, CONTACT_TYPE_TENANT],
          organisationAilornIn: [currentOrg.ailoRN],
          sort: [{ field: "lastName", direction: SortDirection.Asc }],
          paginationParams
        }
      });
    },
    [currentOrg.ailoRN, queryString, request]
  );

  const pageInfo = data?.searchContacts?.pageInfo;
  const customers = data?.searchContacts?.items;

  const { requestNewPage, requestFromBeginning, currentPage } =
    usePaginationRequest(sendRequest);
  return {
    loading: (loading && !data) || !called,
    data: useMemo(() => {
      if (customers && pageInfo) {
        return {
          error: false,
          customers: customers.filter(isPresent).map((c) => ({
            key: c.id,
            id: c.id,
            type: c.types,
            email: c.primaryEmail,
            phoneNo: c.confidential?.primaryPhone,
            legalEntity: c.legalEntity ?? undefined
          })),
          pageInfo: {
            ...pageInfo,
            pageNo: currentPage
          }
        };
      }

      return {
        error: called,
        customers: [],
        pageInfo: { hasNext: false, hasPrevious: false, total: 0, pageNo: 0 }
      };
    }, [called, currentPage, customers, pageInfo]),
    requestNewPage,
    requestFromBeginning
  };
}
