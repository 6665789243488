import {
  LoadingSynchroniser,
  useMountedState,
  useSynchronisedLoad
} from "@ailo/primitives";
import { ErrorModal, LocalDate, useToastContext } from "@ailo/ui";
import { MultiStepForm } from "local/common";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useFormContext } from "react-hook-form";
import { EndManagementFormData } from "./EndManagementFormData";
import { useSubmit } from "./useSubmit";
import { EndingManagementStep, ReviewEndingManagementStep } from "./Steps";
import { EndManagementFormContext } from "./EndManagementFormContext";
import {
  ManagementEndDetailsFragment,
  useGetEndingManagementDetailsQuery,
  useGetManagementEndReasonsQuery
} from "local/graphql";
import styled from "styled-components/native";
import { View } from "react-native";
import { ErrorBoundary } from "@ailo/services";
import { AiloRN, nsEntities } from "@ailo/ailorn";
import { ConfirmEndManagementModal } from "./ConfirmEndManagementModal";
import { isFormerManagement } from "local/tabs/properties/components/PropertyScreen/components/PropertyContent/components/utils";

type StepIds = "Create" | "Review";

type Step = {
  stepId: StepIds;
  render: (visible: boolean) => ReactElement;
};

interface Props {
  managementId: string;
  onDismiss: () => void;
  onSuccess: () => void;
  onFormSubmitting: (isSubmitting: boolean) => void;
}

export function EndManagementForm({ ...props }: Props): ReactElement {
  return (
    <LoadingSynchroniser>
      <EndManagementFormContext>
        <EndManagementMultistepForm {...props} />
      </EndManagementFormContext>
    </LoadingSynchroniser>
  );
}

export function EndManagementMultistepForm({
  managementId,
  onDismiss,
  onSuccess,
  onFormSubmitting
}: Props): ReactElement {
  const Toast = useToastContext();
  const [currentStepNumber, setCurrentStepNumber] = useState<number>(1);
  const [showErrorModal, setShowErrorModal] = useMountedState(false);
  const { formState, trigger, reset, watch } =
    useFormContext<EndManagementFormData>();
  const { isDirty, isSubmitting } = formState;

  const { data, loading, error, refetch } = useGetEndingManagementDetailsQuery({
    variables: { managementId },
    fetchPolicy: "cache-and-network"
  });

  const {
    data: endReasonsData,
    loading: endReasonsLoading,
    error: endReasonsError,
    refetch: refetchEndReasons
  } = useGetManagementEndReasonsQuery();

  const isLoading = useSynchronisedLoad(loading);

  useEffect(() => {
    onFormSubmitting(isSubmitting);
  }, [onFormSubmitting, isSubmitting]);

  const renderStepEnding = useCallback(
    (visible: boolean): ReactElement =>
      data?.management && endReasonsData?.managementEndReasons ? (
        <EndingManagementStep
          key={"EndingManagementStep"}
          management={data?.management}
          endReasons={endReasonsData?.managementEndReasons}
          display={visible}
        />
      ) : (
        <></>
      ),
    [data?.management, endReasonsData?.managementEndReasons]
  );

  const renderStepReview = useCallback(
    (visible: boolean): ReactElement =>
      endReasonsData?.managementEndReasons ? (
        <ReviewEndingManagementStep
          display={visible}
          key={"ReviewEndingManagementStep"}
          setCurrentStepNumber={setCurrentStepNumber}
          endReasons={endReasonsData?.managementEndReasons}
        />
      ) : (
        <></>
      ),
    [endReasonsData?.managementEndReasons]
  );

  const steps: Step[] = useMemo(
    () => [
      {
        stepId: "Create",
        render: renderStepEnding
      },
      {
        stepId: "Review",
        render: renderStepReview
      }
    ],
    [renderStepEnding, renderStepReview]
  );

  const onNextButtonPress = useCallback(
    async (newStepNumber: number) => {
      if (newStepNumber < currentStepNumber) {
        setCurrentStepNumber(newStepNumber);
        return;
      }
      const currentStepId = steps[currentStepNumber - 1].stepId;

      if (currentStepId === "Create") {
        const isValid = await trigger();
        if (isValid) {
          setCurrentStepNumber(newStepNumber);
        }
      }
    },
    [currentStepNumber, steps, trigger]
  );

  const managementEndDate = watch("managementEndDate");
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const isUpdating = data?.management?.endDate != null;
  const submitSuccess = useCallback(
    (updatedManagement: ManagementEndDetailsFragment) => {
      const formattedEndDate = updatedManagement.endDate
        ? new LocalDate(updatedManagement.endDate).format("DD MMM YYYY")
        : "";
      Toast.show({
        type: "success",
        message: isFormerManagement(updatedManagement.endDate)
          ? `Management ended ${formattedEndDate}`
          : `Management ending ${formattedEndDate}`
      });
      onSuccess?.();
      analytics.track(
        !isUpdating ? "Management Ended" : "Ending Management Updated",
        {
          management_id: AiloRN.of(
            nsEntities.propertymanagement.management,
            updatedManagement.id
          ).toString(),
          end_date: updatedManagement.endDate,
          end_reason: updatedManagement.endReason?.code,
          loss_cause: updatedManagement.endReason?.causes.map(
            (cause) => cause.code
          ),
          description: updatedManagement.endNote
        }
      );
    },
    [Toast, isUpdating, onSuccess]
  );

  const submit = useSubmit({
    onSuccess: submitSuccess,
    onError: () => {
      setConfirmModalVisible(false);
      setShowErrorModal(true);
    }
  });

  if ((loading && !data) || (endReasonsLoading && !endReasonsData)) {
    return (
      <Container>
        <EndingManagementStep.Loading />
      </Container>
    );
  }

  if (error || !data?.management?.property.address) {
    return (
      <Container>
        <EndingManagementStep.Error onRetry={refetch} />
      </Container>
    );
  }

  if (endReasonsError || !endReasonsData?.managementEndReasons) {
    return (
      <Container>
        <EndingManagementStep.Error onRetry={refetchEndReasons} />
      </Container>
    );
  }

  const { management } = data;
  return (
    <>
      <ErrorModal
        visible={showErrorModal}
        onDismiss={(): void => setShowErrorModal(false)}
      />

      <ErrorBoundary>
        <MultiStepForm
          style={{
            maxWidth: 680,
            width: "100%",
            flex: 1,
            alignSelf: "flex-end"
          }}
          title={"Ending a management"}
          onSubmit={() => {
            setConfirmModalVisible(true);
          }}
          submitButtonText={"Save"}
          stepValue={currentStepNumber}
          onChange={onNextButtonPress}
          onCancel={() => {
            reset();
            onDismiss();
          }}
          isDirty={isDirty}
          steps={steps.map((step) => step.render)}
          headerBackButtonDisabled
          hideCancelButton
          showFooterBackButton
          submitButtonDisabled={isLoading}
        />
      </ErrorBoundary>

      <ConfirmEndManagementModal
        onCancel={() => setConfirmModalVisible(false)}
        onConfirm={submit}
        visible={confirmModalVisible}
        management={management}
        managementNewEndDate={
          managementEndDate ? LocalDate.from(managementEndDate) : undefined
        }
      />
    </>
  );
}

const Container = styled(View)`
  max-width: 680px;
  width: 100%;
  flex: 1;
  align-self: flex-end;
  padding-right: 60px;
  padding-left: 60px;
`;
