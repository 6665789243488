import { formatPhoneNumber } from "@ailo/domains";
import { AFC, Colors, Text } from "@ailo/primitives";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";

interface Props {
  phoneNo?: string | null;
  emailAddress?: string | null;
}

const PhoneNumberEmailDisplay: AFC<Props> = ({ phoneNo, emailAddress }) => {
  return (
    <Container>
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{emailAddress}</Text.BodyS>
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
        {phoneNo && formatPhoneNumber(phoneNo)}
      </Text.BodyS>
    </Container>
  );
};

const Container = styled(View)`
  flex-direction: column;
`;

export { PhoneNumberEmailDisplay };
