import React, { ReactElement } from "react";
import { Screens, SplitScreenContainer, useRoute } from "local/common";
import { TenancyDetails } from "../../tenancyDetails";
import { ClaimDetails } from "./claimDetails";
import { PropertyContextProvider } from "local/tabs/properties/components/PropertyScreen";

function ClaimSuccessScreen(): ReactElement {
  const { amountClaimed, data, onClose } =
    useRoute<Screens.ClaimTenancyBondSuccess>().params!;

  return (
    <PropertyContextProvider managementId={data.managementId}>
      <SplitScreenContainer
        leftSectionSize={"medium"}
        left={
          <ClaimDetails
            amountClaimed={amountClaimed}
            data={data}
            onClose={onClose}
          />
        }
        right={<TenancyDetails data={data} />}
      />
    </PropertyContextProvider>
  );
}

export { ClaimSuccessScreen };
