import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from "react";
import { KeyedAwaitingApprovalMigratingManagementFragment } from "../../types";

interface Params {
  migratingManagements: KeyedAwaitingApprovalMigratingManagementFragment[];
}

export interface AwaitingApprovalPropertyOnboardingListSelect {
  setSelectedMigratingManagementIds: Dispatch<SetStateAction<string[]>>;
  selectableMigratingManagementIds: string[];
  validSelectedMigratingManagementIds: string[];
  isRowSelected?: (
    row: KeyedAwaitingApprovalMigratingManagementFragment
  ) => boolean;
}

export function useAwaitingApprovalPropertyOnboardingListSelect({
  migratingManagements
}: Params): AwaitingApprovalPropertyOnboardingListSelect {
  const [selectedMigratingManagementIds, setSelectedMigratingManagementIds] =
    useState<string[]>([]);

  const selectableMigratingManagementIds = useMemo(() => {
    return migratingManagements.reduce((acc, mm) => {
      const errorsArray = Array.isArray(mm.errors) ? mm.errors : [];
      return errorsArray.length ? acc : [...acc, mm.id];
    }, [] as string[]);
  }, [migratingManagements]);

  const validSelectedMigratingManagementIds = useMemo(
    () =>
      selectedMigratingManagementIds.filter((id) =>
        selectableMigratingManagementIds.includes(id)
      ),
    [selectedMigratingManagementIds, selectableMigratingManagementIds]
  );

  const isRowSelected = useCallback(
    (row: KeyedAwaitingApprovalMigratingManagementFragment) =>
      validSelectedMigratingManagementIds.includes(row.id),
    [validSelectedMigratingManagementIds]
  );

  return {
    setSelectedMigratingManagementIds,
    selectableMigratingManagementIds,
    validSelectedMigratingManagementIds,
    isRowSelected
  };
}
