import { RentFrequency } from "local/graphql";
import { RentFrequency as GraphQLRentFrequency } from "@ailo/domains";

export function getRentFrequencyInputForMutation(
  freq: RentFrequency
): GraphQLRentFrequency {
  switch (freq) {
    case RentFrequency.Daily:
      return GraphQLRentFrequency.Daily;
    case RentFrequency.Weekly:
      return GraphQLRentFrequency.Weekly;
    case RentFrequency.Fortnightly:
      return GraphQLRentFrequency.Fortnightly;
    case RentFrequency.Monthly:
      return GraphQLRentFrequency.Monthly;
    default:
      throw new Error(`Unsupported rent frequency: ${freq}`);
  }
}
