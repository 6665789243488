import { ListItem } from "@ailo/ui";
import React from "react";
import { IconContainer } from "./IconContainer";
import { Colors } from "@ailo/primitives";
import { useMemo } from "reactn";
import { getTeamIcon, getSpecialTeamName, SpecialTeamId } from "@ailo/domains";

interface Props {
  teamId: SpecialTeamId;
  selectedTeamId?: string;
  onSelect: (teamId: SpecialTeamId) => void;
}

export function SpecialTeamListItem({
  teamId,
  selectedTeamId,
  onSelect
}: Props): React.ReactElement {
  const name = useMemo(() => getSpecialTeamName(teamId), [teamId]);

  const Icon = getTeamIcon(teamId);

  return (
    <ListItem
      header={name}
      leftComponent={
        <IconContainer>
          <Icon height={14} width={14} color={Colors.TEXT.DARK.SECONDARY} />
        </IconContainer>
      }
      style={{
        borderType: "none",
        height: 40,
        paddingVertical: 0,
        marginBottom: 4,
        borderRadius: 4,
        ...(teamId === selectedTeamId
          ? { backgroundColor: Colors.INTERACTION.WHITE.PRESSED }
          : {})
      }}
      onPress={() => onSelect(teamId)}
    />
  );
}
