import React, { ReactElement } from "react";
import { View } from "react-native";
import { Colors, OnboardingIcon, Text } from "@ailo/primitives";
import { PropertyOnboardingRefresh } from "./PropertyOnboardingRefresh";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";

interface Props {
  refetchList: () => void;
}

export function PropertyOnboardingHeader({ refetchList }: Props): ReactElement {
  const hasRefreshFeature = useHasFeature(
    PlatformFeatureId.OnboardingTabRefresh
  );

  return (
    <View style={{ marginTop: 32, marginBottom: 40 }}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 12
            }}
          >
            <OnboardingIcon
              height={32}
              width={32}
              color={Colors.TEXT.DARK.SECONDARY}
              style={{ marginRight: 12 }}
            />
            <Text.DisplayM>{"Property Onboarding"}</Text.DisplayM>
          </View>
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
            {
              "Review the health of your properties before onboarding them to Ailo"
            }
          </Text.BodyS>
        </View>
        {hasRefreshFeature ? (
          <PropertyOnboardingRefresh refetchList={refetchList} />
        ) : null}
      </View>
    </View>
  );
}
