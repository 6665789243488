import { LocalDate } from "@ailo/date";
import { FormerOrVoidedTenancyFragment } from "local/graphql";

export function sortTenanciesByEndDateOrVoidedAt(
  tenancies: FormerOrVoidedTenancyFragment[]
): FormerOrVoidedTenancyFragment[] {
  return [...tenancies].sort((a, b) => {
    return getSortDate(a).isSameOrBefore(getSortDate(b)) ? 1 : -1;
  });
}

function getSortDate(tenancy: FormerOrVoidedTenancyFragment): LocalDate {
  const date = tenancy.endDate || tenancy.voidedAt;
  if (!date) {
    throw new Error("Tenancy must have either end date or voided at");
  }
  return LocalDate.from(date);
}
