import {
  formatRentPaymentSchedule,
  hasSameRent,
  RentAmountAndFrequency,
  RentDomainType,
  RentScheduleDomainType
} from "@ailo/domains";
import { formatDateDefaultToNA } from "@ailo/services";
import { getScheduledRentReviewColor } from "local/domain/propertyManagement";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { isPresent } from "ts-is-present";
import { Section } from "./Section";
import { LocalDate } from "@ailo/date";

interface Props {
  futureRents: RentDomainType[];
  paidToDate?: LocalDate;
  scheduledRentReviewDate?: LocalDate;
  displayRent?: RentAmountAndFrequency;
  nextRentSchedule?: RentScheduleDomainType;
  style?: StyleProp<ViewStyle>;
}

export function RentSection({
  futureRents,
  paidToDate,
  scheduledRentReviewDate,
  displayRent,
  nextRentSchedule,
  style
}: Props): React.ReactElement {
  const futureRentItems = futureRents.map((rent) =>
    getRentItem(rent, "Rent review amount")
  );

  const items = [
    displayRent && {
      label: "Rent amount",
      description: formatRentPaymentSchedule(displayRent)
    },
    paidToDate && {
      label: "Paid to date",
      description: paidToDate.format("D MMM YYYY")
    },
    getNextRentItem(displayRent, nextRentSchedule),
    ...futureRentItems,
    scheduledRentReviewDate && {
      label: "Scheduled review",
      description: scheduledRentReviewDate.format("D MMM YYYY"),
      descriptionColor: getScheduledRentReviewColor(scheduledRentReviewDate)
    }
  ];

  return (
    <Section
      style={style}
      title={"Rent"}
      descriptionListItems={items.filter(isPresent)}
    />
  );
}

function getNextRentItem(
  displayRent?: RentAmountAndFrequency,
  nextRentSchedule?: RentScheduleDomainType
): { label: string; description: string } | undefined {
  if (!nextRentSchedule || hasSameRent(displayRent, nextRentSchedule)) {
    return undefined;
  }

  return getRentItem(
    { ...nextRentSchedule, effectiveDate: nextRentSchedule.startDate },
    "Next rent amount"
  );
}

function getRentItem(
  rent: RentDomainType,
  label: string
): { label: string; description: string } {
  return {
    label,
    description: `${formatRentPaymentSchedule(
      rent
    )} \n Starting ${formatDateDefaultToNA(rent.effectiveDate)}`
  };
}
