import { FormField, SelectInput } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  EndTenancyFormData,
  EndTenancyFormFields
} from "../EndTenancyFormData";
import { tenancyVacatingReasons } from "local/domain/propertyManagement";
import { VacatingReason } from "local/graphql";

export function EndTenancyReasonInput({
  reason
}: {
  reason?: VacatingReason | null;
}): ReactElement {
  const { control, errors } = useFormContext<EndTenancyFormData>();

  return (
    <Controller
      name={EndTenancyFormFields.reason}
      render={({ value, onChange, onBlur, ref }): ReactElement => {
        return (
          <FormField label={"Tenancy End Reason"}>
            <SelectInput
              inputRef={ref}
              {...{ onBlur }}
              placeholder={"Select reason..."}
              value={value}
              options={tenancyVacatingReasons}
              onChange={(option) => {
                onChange(option?.value);
              }}
              hasError={!!errors.reason?.message}
              valueContainerStyle={{ height: 52 }}
            />
          </FormField>
        );
      }}
      defaultValue={reason}
      control={control}
      rules={{
        required: "Please select a reason"
      }}
    />
  );
}
