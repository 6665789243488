import { Colors, opacify, SFC, Text } from "@ailo/primitives";
import { defaultMaxMoney, FormField, FormFieldRow, Money } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { View } from "react-native";
import styled from "styled-components/native";

const LineItemTotal: SFC = ({ style }) => {
  const { watch, control, errors, setValue } =
    useFormContext<AddBillFormData>();
  const lineItems = watch("lineItems");
  const taxAmount = watch("taxAmount");
  const taxAutoCalculated = watch("taxAutoCalculated");

  const lineItemsTotalAmount = (lineItems ?? []).reduce(
    (total, item) => total.add(item.amount ?? Money.zero()),
    Money.zero()
  );
  const totalAmount = taxAutoCalculated
    ? lineItemsTotalAmount
    : lineItemsTotalAmount.add(taxAmount ?? Money.zero());

  useEffect(
    () => setValue("totalAmount", totalAmount.cents, { shouldValidate: true }),
    [totalAmount.cents, setValue]
  );

  return (
    <Controller
      name={"totalAmount"}
      control={control}
      defaultValue={totalAmount.cents}
      rules={{
        validate: (totalAmount: number) =>
          totalAmount < 0
            ? `Total can't be less than ${Money.zero().format()}.`
            : totalAmount > defaultMaxMoney.cents
            ? `Total can't be more than ${defaultMaxMoney.format()}.`
            : true
      }}
      render={() => {
        return (
          <FormFieldRow style={style}>
            <LabelContainer>
              <Text.BodyL weight={"medium"}>{"Total"}</Text.BodyL>
            </LabelContainer>
            <FormField error={errors.totalAmount?.message}>
              <TotalContainer>
                <StyledText>
                  {totalAmount.format?.({ symbol: false })}
                </StyledText>
                <FloatingText>{"$"}</FloatingText>
              </TotalContainer>
            </FormField>
          </FormFieldRow>
        );
      }}
    />
  );
};

const TotalContainer = styled(View)`
  background-color: ${Colors.CLOUD};
  border: 1px solid ${opacify(Colors.SPACE_BLACK, 0.2)};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 12px;
  justify-content: center;
`;

const StyledText = styled(Text.BodyL)`
  padding-left: 14px;
`;
const FloatingText = styled(Text.BodyL)`
  position: absolute;
  left: 12px;
`;

const LabelContainer = styled(View)`
  justify-content: center;
  height: 52px;
`;

export { LineItemTotal };
