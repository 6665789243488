import { FormField, NoInputFormField, SelectInput } from "@ailo/ui";
import {
  sortTenancies,
  useGetManagementTenancies
} from "local/domain/propertyManagement";
import { useGetManagementOwnersQuery } from "local/graphql";
import { isEmpty } from "lodash";
import React, { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";
import { isPresent } from "ts-is-present";
import { AddBillFormData, PayerSelectOption } from "../AddBillFormData";
import { createManagementOwnersSelectOption } from "../utils/createManagementOwnersSelectOption";
import { createManagementTenantsSelectOption } from "../utils/createManagementTenantsSelectOption";

const name = "payerId";

type PayerInputProps = {
  managementId?: string;
  label?: string;
  noOptionMessage?: string;
  style?: StyleProp<ViewStyle>;
};

export function PayerInput({
  managementId: managementIdProp,
  label = "Who's paying?",
  noOptionMessage = "No investors or tenants found for this property",
  style
}: PayerInputProps): React.ReactElement {
  const { control, watch, setValue, errors } = useFormContext<
    AddBillFormData & { [name]: any }
  >();
  const managementId = managementIdProp || watch("managementId")?.value;

  const ownersResult = useGetManagementOwnersQuery({
    variables: managementId ? { id: managementId } : undefined,
    skip: !managementId
  });
  if (ownersResult.error) {
    throw ownersResult.error;
  }
  const managementOption = createManagementOwnersSelectOption(
    ownersResult.data
  );

  const tenanciesResult = useGetManagementTenancies(managementId);

  const nonVoidedTenancyOptions = sortTenancies(tenanciesResult.data)
    .filter((tenancy) => !tenancy.voidedAt)
    .map(createManagementTenantsSelectOption)
    .filter(isPresent);

  const loading = ownersResult.loading || tenanciesResult.loading;

  const options: PayerSelectOption[] =
    !loading && managementOption && nonVoidedTenancyOptions
      ? [managementOption, ...nonVoidedTenancyOptions]
      : [];

  const defaultValue = options[0] || null;

  useEffect(() => {
    if (!loading && defaultValue) {
      setValue(
        "payerId",
        {
          ...defaultValue,
          // react-hook-form doesn't like not primitive values
          // - it goes in an infinite call stack trace if given react nodes
          // - so let's nullify them before setting the value
          ...({ RightComponent: undefined } as any)
        },
        { shouldValidate: true }
      );
    }
  }, [loading, managementId]); // eslint-disable-line react-hooks/exhaustive-deps

  const inputRef = useRef<any>(null);

  return (
    <Controller
      render={({ value, onChange }): React.ReactElement => {
        return (
          <View style={style}>
            {loading ? (
              <NoInputFormField loading={true} label={label} />
            ) : isEmpty(options) ? (
              <NoInputFormField message={noOptionMessage} label={label} />
            ) : (
              <FormField
                label={label}
                testID={"PayerInputFormField"}
                error={errors[name]?.message}
              >
                <SelectInput
                  inputRef={inputRef}
                  value={
                    value &&
                    (options.find((opt) => opt.value === value.value) ?? value)
                  }
                  onChange={(v): void =>
                    onChange({
                      ...v,
                      // react-hook-form doesn't like not primitive values
                      // - it goes in an infinite call stack trace if given react nodes
                      // - so let's nullify them before setting the value
                      RightComponent: undefined
                    })
                  }
                  placeholder={"Select who's paying"}
                  options={options}
                  useTextInputHeight
                />
              </FormField>
            )}
          </View>
        );
      }}
      name={name}
      control={control}
      defaultValue={defaultValue}
      onFocus={() => inputRef.current?.focus()}
      rules={{
        validate: (option): boolean | string =>
          (!isEmpty(option?.label) && !isEmpty(option?.value)) ||
          "Select who's paying"
      }}
    />
  );
}
