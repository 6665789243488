import { AiloRN } from "@ailo/ailorn";
import { hasValueAtKey } from "@ailo/primitives";
import { AgencyOrganisation } from "local/common";
import {
  OrganisationType,
  PlatformFeatureId,
  GetAvailableFeaturesQuery
} from "local/graphql";
import { getDefaultWalletLegalEntity } from "./getDefaultWalletLegalEntity";

type UserPayload = NonNullable<GetAvailableFeaturesQuery["effectiveUser"]>;
type OrgPayload = NonNullable<UserPayload["organisations"][number]>;
type CompanyPayload = NonNullable<OrgPayload["legalEntities"]>[number] & {
  __typename: "Company";
};
type Company = AgencyOrganisation["legalEntities"][number];
type TrustAccount = Company["trustAccounts"][number];

export function getDefaultAgencyOrg(
  organisations: OrgPayload[]
): AgencyOrganisation | undefined {
  const agencyOrgs: AgencyOrganisation[] = organisations
    .filter(hasValueAtKey("orgType", OrganisationType.Agency))
    .map(formatAgency);

  return findDefaultOrg(agencyOrgs);
}

function formatAgency(payload: OrgPayload): AgencyOrganisation {
  const legalEntities = (payload.legalEntities ?? [])
    .filter(hasValueAtKey("__typename", "Company" as const))
    .map(formatCompany);

  const ailorn = AiloRN.of("authz:organisation", payload.id);

  return {
    ...payload,
    availableFeatures: payload.availableFeatures.map((feature) => feature.id),
    ailoRN: ailorn.toString(),
    ailorn,
    legalEntities,
    defaultWalletLegalEntity: getDefaultWalletLegalEntity(legalEntities)
  };
}

function formatCompany(company: CompanyPayload): Company {
  const formattedCompany = {
    ...company,
    ailorn: company.ailoRN,
    trustAccounts: company.trustAccounts.edges.map((ta) => ({
      ...ta.node
    }))
  };

  return {
    ...formattedCompany,
    defaultTrustAccount: findDefaultTrustAccount(formattedCompany.trustAccounts)
  };
}

function findDefaultOrg(
  organisations: AgencyOrganisation[]
): AgencyOrganisation | undefined {
  const orgWithAgencyAccess = organisations.find((org) => {
    return org.availableFeatures.includes(PlatformFeatureId.AgencyAccess);
  });

  return orgWithAgencyAccess ?? organisations[0];
}

function findDefaultTrustAccount(
  trustAccounts: TrustAccount[]
): TrustAccount | undefined {
  return [...trustAccounts].sort((a, b) => a.id.localeCompare(b.id))[0];
}
