import React, { ReactElement } from "react";
import { Grid } from "@ailo/ui";
import { useAwaitingApprovalPropertyOnboardingList } from "./hooks";
import { PropertyOnboardingListError } from "../PropertyOnboardingListError";
import { PropertyOnboardingListEmpty } from "../PropertyOnboardingListEmpty";
import { PropertyOnboardingListEmptyFilterResults } from "../PropertyOnboardingListEmptyFilterResults";

const BODY_STYLE = {
  // Both of these margin props are required to override Grid
  margin: "-46px 0 0 0",
  marginTop: -46,
  paddingTop: 46
};

export function AwaitingApprovalPropertyOnboardingList({
  tabNavigation
}: {
  tabNavigation: ReactElement;
}): ReactElement {
  const {
    filters,
    queryData,
    reorder,
    columns,
    content,
    baseContent,
    loadingContent
  } = useAwaitingApprovalPropertyOnboardingList({ tabNavigation });

  const { data, loading, requestFromBeginning } = queryData;
  const { sortOrder, onSortOrderChange } = reorder;
  const { headerOverride, failedPropertiesModal } = content;
  const {
    headerCaption,
    styledHeader,
    footerCaption,
    onRowPress,
    renderRowContainer
  } = baseContent;
  const { loadingRows, loadingColumns, renderLoadingRowContainer } =
    loadingContent;

  if (loading) {
    return (
      <Grid
        headerCaption={headerCaption(true)}
        headerOverride={styledHeader}
        rows={loadingRows}
        columns={loadingColumns}
        sortOrder={sortOrder[0]}
        renderRowContainer={renderLoadingRowContainer}
        bodyStyle={BODY_STYLE}
      />
    );
  }

  if (data.error) {
    return (
      <Grid.Placeholder headerCaption={headerCaption(false)}>
        <PropertyOnboardingListError
          message={"There was a problem loading Awaiting Approval"}
          onReload={(): void => requestFromBeginning({ sortOrder })}
        />
      </Grid.Placeholder>
    );
  }

  if (data.pageInfo.total === 0) {
    if (filters.hasActiveFilters) {
      return (
        <PropertyOnboardingListEmptyFilterResults
          headerCaption={headerCaption}
          styledHeader={styledHeader}
        />
      );
    }

    return (
      <Grid.Placeholder headerCaption={headerCaption(false)}>
        <PropertyOnboardingListEmpty
          message={"Awaiting Approval List"}
          secondaryMessage={
            "All migrating properties will appear here for\n" +
            "you to review and receive data issues"
          }
        />
      </Grid.Placeholder>
    );
  }

  return (
    <>
      <Grid
        headerCaption={headerCaption(true)}
        headerOverride={headerOverride ?? styledHeader}
        renderRowContainer={renderRowContainer}
        onRowPress={onRowPress}
        footerCaption={footerCaption}
        columns={columns}
        rows={data.migratingManagements}
        sortOrder={sortOrder[0]}
        onSortOrderChange={onSortOrderChange}
        bodyStyle={BODY_STYLE}
      />

      {failedPropertiesModal}
    </>
  );
}
