import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import {
  GetProjectDetailsDocument,
  ProjectDetailsFragment,
  ProjectType,
  UpdateProjectMutation,
  useUpdateProjectMutation
} from "local/graphql";
import { useCallback } from "react";
import { ProjectFormData } from "../ProjectForm/ProjectFormData";

interface ProjectDetails {
  id: string;
  name: string;
  description?: string | null;
  dueDate: string;
  type: ProjectType;
  fileAilorns: string[];
  assigneeAilorn: string;
}

export function useUpdateProject({
  onCompleted,
  onError
}: {
  onCompleted: ({
    project,
    initialValues,
    formValues
  }: {
    project: ProjectDetailsFragment;
    initialValues: ProjectFormData;
    formValues: ProjectFormData;
  }) => void;
  onError: () => void;
}): [
  (
    project: ProjectDetails,
    initialValues: ProjectFormData,
    formValues: ProjectFormData
  ) => void,
  MutationResult<UpdateProjectMutation>
] {
  const [updateProjectMutation, result] = useUpdateProjectMutation();

  const updateProject = useCallback(
    async (
      projectDetails: ProjectDetails,
      initialValues: ProjectFormData,
      formValues: ProjectFormData
    ): Promise<void> => {
      let result: ExecutionResult<UpdateProjectMutation>;

      try {
        result = await updateProjectMutation({
          variables: { input: projectDetails },
          refetchQueries: [
            {
              query: GetProjectDetailsDocument,
              variables: { id: projectDetails.id, filesPageSize: 8 }
            }
          ]
        });
        throwIfMutationFailed(result, { dataKey: "updateProject" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }

      const updatedProject = result.data?.updateProject;
      if (!updatedProject) {
        throw new Error("Failed to update project");
      }

      onCompleted({
        project: updatedProject,
        initialValues,
        formValues
      });
    },
    [onCompleted, updateProjectMutation, onError]
  );

  return [updateProject, result];
}
