import { formatPersonName } from "@ailo/domain-helpers";
import { Colors, DownCaretIcon, Text } from "@ailo/primitives";
import { Button, PersonAvatar } from "@ailo/ui";
import { PersonNamesFragment } from "local/graphql";
import React from "react";

export function AssigneeDropdownButton({
  person,
  active,
  onPress
}: {
  person: PersonNamesFragment;
  active: boolean;
  onPress: () => void;
}): React.ReactElement {
  return (
    <Button.Secondary
      active={active}
      onPress={onPress}
      style={{
        paddingHorizontal: 8,
        paddingVertical: 8
      }}
      contentStyle={{
        alignItems: "center"
      }}
    >
      <PersonAvatar person={person} size={24} style={{ marginRight: 8 }} />
      <Text.BodyM weight={"medium"}>{formatPersonName(person)}</Text.BodyM>
      <DownCaretIcon
        width={20}
        height={20}
        style={{ marginHorizontal: 4 }}
        color={Colors.TEXT.DARK.PRIMARY}
      />
    </Button.Secondary>
  );
}
