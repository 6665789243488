import { TextInputFormField, validateMaxLength } from "@ailo/ui";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { EndManagementFormData } from "../EndManagementFormData";

export function ManagementEndNoteInput({
  initialValue
}: {
  initialValue?: string | null;
}): React.ReactElement {
  const { control, errors } = useFormContext<EndManagementFormData>();

  return (
    <Controller
      control={control}
      name={"managementEndNote"}
      defaultValue={initialValue}
      render={({ value, onChange, onBlur }) => (
        <TextInputFormField
          {...{ value, onChange, onBlur }}
          label={"Management end note (provide a short description)"}
          style={{ marginBottom: 20 }}
          error={errors.managementEndNote?.message}
          testID={"ManagementEndNoteInputFormField"}
          softCharacterLimit={{
            limit: 140,
            enforcementLevel: "error"
          }}
        />
      )}
      rules={{
        validate: {
          maxLength: validateMaxLength(140)
        },
        required: "Please enter a short end note"
      }}
    />
  );
}
