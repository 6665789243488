import { DropdownMenuOptionGroup } from "@ailo/ui";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { CentrelinkIcon } from "@ailo/primitives";
import React from "react";
import { Options } from "./ManageMenu";

export function useGetCentrepayOptionsGroup(
  centrepayPayments: Options<{}> | undefined
): DropdownMenuOptionGroup | undefined {
  const hasCentrepayFeature = useHasFeature(PlatformFeatureId.Centrepay);

  const centrepayOption =
    centrepayPayments && hasCentrepayFeature
      ? {
          label: "Manage Centrepay",
          icon: <CentrelinkIcon />,
          onSelect: () => centrepayPayments.onPress(centrepayPayments),
          disabled: false
        }
      : undefined;

  return centrepayOption
    ? {
        options: [centrepayOption],
        type: "group"
      }
    : undefined;
}
