import { isSupplierInternal, useHasFeature } from "@ailo/domains";
import { Colors, SFC } from "@ailo/primitives";
import { AiloSentry, useActionEventContext, useOnFocus } from "@ailo/services";
import {
  Badge,
  FormField,
  LabelledSelectInput,
  SelectInput,
  SelectInputError,
  SelectInputLinkMenuFooter,
  SelectInputLoading,
  SelectInputProps,
  SelectOption
} from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import {
  useGetManagementAgencyQuery,
  useGetManagementOwnersQuery,
  PlatformFeatureId,
  useGetSuppliersQuery
} from "local/graphql";
import { isEmpty } from "lodash";
import React, { FC, useEffect, useRef, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { View } from "react-native";
import { createManagementOrganisationSelectOption } from "../utils/createManagementOrganisationSelectOption";
import { createManagementOwnersSelectOption } from "../utils/createManagementOwnersSelectOption";
import { AddBillFormData } from "../AddBillFormData";

const name = "supplierReference";

type PayeeRenderInputProps = Omit<SelectInputProps<SelectOption>, "label"> & {
  label: string;
  testID: string;
  placeholder: string;
  error?: string;
} & { errorState?: boolean; loading?: boolean };

const PayeeRenderInput: FC<PayeeRenderInputProps> = ({
  label,
  testID,
  errorState,
  loading,
  placeholder,
  error,
  ...rest
}) => {
  if (errorState)
    return (
      <LabelledSelectInput.Placeholder
        component={<SelectInputError />}
        placeholder={placeholder}
        label={label}
        controlStyle={{ height: 52 }}
        optionStyle={{ backgroundColor: Colors.WHITE }}
      />
    );

  if (loading)
    return (
      <LabelledSelectInput.Placeholder
        component={<SelectInputLoading />}
        placeholder={placeholder}
        label={label}
        controlStyle={{ height: 52 }}
        optionStyle={{ backgroundColor: Colors.WHITE }}
      />
    );

  return (
    <FormField label={label} testID={testID} error={error}>
      <SelectInput {...rest} placeholder={placeholder} />
    </FormField>
  );
};

type PayeeInputProps = {
  managementId?: string;
  defaultToAgency?: boolean;
  onCreateSupplierClick: () => void;
};

const PayeeInput: SFC<PayeeInputProps> = ({
  managementId: managementIdProp,
  onCreateSupplierClick,
  defaultToAgency = false,
  style
}) => {
  const currentAgencyOrg = useCurrentAgencyOrg();
  const MAX_SUPPLIERS = 1000;

  const hasInvestorInternalSupplierAccess = useHasFeature(
    PlatformFeatureId.InvestorInternalSuppliers
  );
  const hasAgencyInternalSupplierAccess = useHasFeature(
    PlatformFeatureId.AgencyInternalSuppliers
  );
  const hasCreateSupplierFeature = useHasFeature(
    PlatformFeatureId.AgencyAppSupplierCreate
  );

  const { data, loading, error, refetch } = useGetSuppliersQuery({
    variables: {
      suppliersCursor: {
        pageSize: MAX_SUPPLIERS,
        paginateBackward: false
      },
      id: currentAgencyOrg.id
    }
  });
  const actionEventContext = useActionEventContext();
  useEffect(() => {
    return actionEventContext.subscribe((event) => {
      if (event.type === "SupplierCreated") {
        refetch();
      }
    });
  }, [actionEventContext, refetch]);

  useOnFocus(refetch);

  const { control, watch, setValue, getValues, register, unregister, errors } =
    useFormContext<AddBillFormData>();

  const selectedProperty = watch("managementId");
  const managementId = managementIdProp || selectedProperty?.value;
  const payerId = watch("payerId")?.value;

  const ownersResult = useGetManagementOwnersQuery({
    variables: managementId ? { id: managementId } : undefined,
    skip: !managementId || !hasInvestorInternalSupplierAccess
  });
  if (ownersResult.error) {
    throw ownersResult.error;
  }

  const agencyResult = useGetManagementAgencyQuery({
    variables: managementId ? { id: managementId } : undefined,
    skip: !managementId || !hasAgencyInternalSupplierAccess
  });
  if (agencyResult.error) {
    throw agencyResult.error;
  }

  useEffect(() => {
    register({ name: "managingEntityAilorn" });
    return (): void => {
      unregister("managingEntityAilorn");
    };
  }, [register, unregister]);

  useEffect(() => {
    const managingEntityAilorn =
      agencyResult?.data?.management?.managingEntity?.ailoRN;
    if (managingEntityAilorn) {
      setValue("managingEntityAilorn", managingEntityAilorn);
    }
  }, [agencyResult, setValue]);

  // Clear supplier field if otherwise investor would be both supplier and payer
  useEffect(() => {
    const supplierReference = getValues("supplierReference")?.value;
    if (payerId === supplierReference) {
      setValue("supplierReference", null);
    }
  }, [payerId, setValue, getValues]);

  // Clear internal suppliers if management changes because old supplier will not
  // be relevant to new management
  useEffect(() => {
    const supplierReference = getValues("supplierReference")?.value;
    if (isSupplierInternal(supplierReference)) {
      setValue("supplierReference", null);
    }
  }, [managementId, setValue, getValues]);

  const inputRef = useRef<any>(null);

  const totalSuppliers = data?.organisation?.suppliers?.pageInfo?.total;

  if (totalSuppliers != null && totalSuppliers > MAX_SUPPLIERS) {
    AiloSentry.captureException(
      new Error(
        `Total suppliers (i.e. ${totalSuppliers}) is greater than the suppliers able to be selected in Add Bill Form (i.e. ${MAX_SUPPLIERS})`
      )
    );
  }

  const externalSupplierOptions =
    data?.organisation?.suppliers?.items
      .map((item) => ({
        label: item?.name,
        value: item.ailoRN,
        RightComponent: (<Badge type={"supplier"} />) as React.ReactNode
      }))
      .filter(
        (
          option
        ): option is SelectOption & {
          label: string;
          RightComponent: React.ReactNode;
        } => !!option.label
      ) || [];

  const agencyOption = createManagementOrganisationSelectOption(
    agencyResult.data
  );
  const internalSupplierOptions = [
    createManagementOwnersSelectOption(ownersResult.data),
    agencyOption
  ]
    .filter((x): x is SelectOption => !!x)
    .filter((supplier) => supplier?.value !== payerId); // payer should not also be supplier
  const supplierOptions = [
    ...internalSupplierOptions,
    ...externalSupplierOptions
  ];

  const afterRenderFocus = useCallback(
    (ref: React.RefObject<View>) => () => {
      setTimeout(() => ref.current?.focus(), 0);
    },
    []
  );

  useEffect(() => {
    if (defaultToAgency && agencyOption) {
      setValue("supplierReference", {
        ...agencyOption,
        // react-hook-form doesn't like not primitive values
        // - it goes in an infinite call stack trace if given react nodes
        // - so let's nullify them before setting the value
        RightComponent: undefined
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyOption?.value, defaultToAgency, setValue]);

  return (
    <View style={style}>
      <Controller
        name={name}
        onFocus={afterRenderFocus(inputRef)}
        render={({ value, onChange }): React.ReactElement => (
          <PayeeRenderInput
            errorState={!!error}
            error={errors[name]?.message}
            loading={!data && loading}
            label={"Who's getting paid?"}
            testID={"PayeeInputFormField"}
            inputRef={inputRef}
            value={
              value &&
              (supplierOptions.find((opt) => opt.value === value.value) ??
                value)
            }
            renderMenuFooter={
              hasCreateSupplierFeature
                ? ({ onMenuClose }) => (
                    <SelectInputLinkMenuFooter
                      label={"Add new supplier"}
                      onPress={() => {
                        onCreateSupplierClick();
                        onMenuClose();
                      }}
                    />
                  )
                : undefined
            }
            onChange={(v): void => {
              onChange({
                ...v,

                // react-hook-form doesn't like not primitive values
                // - it goes in an infinite call stack trace if given react nodes
                // - so let's nullify them before setting the value
                RightComponent: undefined
              });
            }}
            placeholder={"Select who's getting paid..."}
            options={supplierOptions}
            useTextInputHeight
          />
        )}
        control={control}
        defaultValue={null}
        rules={{
          validate: (option): boolean | string =>
            (!isEmpty(option?.label) && !isEmpty(option?.value)) ||
            "Select who's getting paid"
        }}
      />
    </View>
  );
};

export { PayeeInput };
