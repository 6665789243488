import React, { ReactElement, useMemo } from "react";
import { Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";
import {
  DateTimeWithTimeZone,
  DescriptionList,
  DescriptionListItem,
  Separator
} from "@ailo/ui";
import { MigratingManagementSidebarFragment } from "local/graphql";
import { getActionInitiatorDisplayName } from "../../../PropertyOnboardingScreen/PropertyOnboardingList/utils";
import { ExclusionNotes, HealthIssuesCard } from "./components";

type Params = Pick<
  MigratingManagementSidebarFragment,
  | "statusChangedBy"
  | "statusChangedAt"
  | "exclusionReason"
  | "exclusionNotes"
  | "errors"
>;

export function ExcludedMigratingManagementBody({
  statusChangedBy,
  statusChangedAt,
  exclusionReason,
  exclusionNotes,
  errors
}: Params): ReactElement {
  const descriptionListItems = useMemo(() => {
    const items = [] as DescriptionListItem[];

    if (statusChangedBy) {
      items.push({
        label: "Completed by",
        description: getActionInitiatorDisplayName(statusChangedBy)
      });
    }

    const ailoStatusChangedAt = DateTimeWithTimeZone.from(statusChangedAt);
    items.push({
      label: "Date",
      description: ailoStatusChangedAt.format("D MMM YYYY")
    });

    items.push({
      label: "Reason",
      description: exclusionReason
    });

    return items;
  }, [statusChangedBy, statusChangedAt, exclusionReason]);

  return (
    <Container>
      <StyledBodyL weight={"medium"}>{"Excluded"}</StyledBodyL>
      <DescriptionList
        items={descriptionListItems}
        size={"small"}
        style={{ marginBottom: 24 }}
      />

      {exclusionNotes ? (
        <ExclusionNotes exclusionNotes={exclusionNotes} />
      ) : null}

      {errors?.length > 0 ? (
        <>
          <StyledSeparator />
          <HealthIssuesCard issues={errors ?? undefined} />
        </>
      ) : null}
    </Container>
  );
}

const Container = styled(View)`
  padding-top: 32px;
  padding-bottom: 60px;
`;

const StyledBodyL = styled(Text.BodyL)`
  margin-bottom: 20px;
`;

const StyledSeparator = styled(Separator)`
  margin-left: 0px;
  margin-top: 40px;
  margin-bottom: 32px;
`;
