import { SFC, Text } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { View } from "react-native";
import { LineItemInput } from "./LineItemInput";
import { LineItemTotal } from "./LineItemTotal";
import { TaxAmountInputRow } from "./TaxAmountInputRow";

interface Props {
  inputDisabled?: boolean;
  accessibilityLabel?: string;
}

const LineItemsInput: SFC<Props> = ({ style }) => {
  const { control } = useFormContext<AddBillFormData>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "lineItems"
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({ name: "lineItems" }, false);
    }
  }, [fields, append]);

  return (
    <View style={style} accessible={true} testID={"LineItemsFormGroup"}>
      <Text.BodyL weight={"medium"} style={{ marginBottom: 8 }}>
        {"Line Items"}
      </Text.BodyL>

      {fields.map((item, index) => (
        <LineItemInput
          key={item.id}
          totalNumLineItems={fields.length}
          clearButtonOnPress={(): void => remove(index)}
          index={index}
          style={{ marginTop: 16 }}
        />
      ))}

      <Button.Secondary
        onPress={(): void => append({ name: "lineItems" })}
        style={{ marginTop: 16 }}
        testID={"AddLineItemButton"}
      >
        {"Add line item"}
      </Button.Secondary>

      <TaxAmountInputRow style={{ marginTop: 24 }} />
      <LineItemTotal style={{ marginTop: 24 }} />
    </View>
  );
};

export { LineItemsInput };
