import { Money } from "@ailo/ui";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ConfirmReverseRentAdjustmentStep,
  AddReversalDescriptionStep
} from "./steps";
import { MultiStepForm } from "local/common";
import { adjustmentTypeFromAmount } from "../RentAdjustmentSidebar/adjustmentTypeFromAmount";
import { useReverseRentAdjustment } from "./useReverseRentAdjustment";

type StepId = "AddDescription" | "Confirm";

interface Step {
  stepId: StepId;
  render: (visible: boolean) => React.ReactElement;
  modalTitle: string;
}

export interface ReverseRentAdjustmentFormData {
  description: string;
}

interface Props {
  adjustmentId: string;
  amount: Money;
  onClose(): void;
  onSuccess(): void;
}

export function ReverseRentAdjustmentModal({
  adjustmentId,
  amount,
  onClose,
  onSuccess
}: Props): React.ReactElement {
  const [currentStepNumber, setCurrentStepNumber] = useState<number>(1);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const form = useForm<ReverseRentAdjustmentFormData>({
    defaultValues: {
      description: ""
    },
    mode: "onChange"
  });

  const { formState, trigger, handleSubmit } = form;
  const { isDirty } = formState;

  const renderAddDescriptionStep = useCallback(
    (visible: boolean) => (
      <AddReversalDescriptionStep
        key={"add-reversal-description-step"}
        amount={amount}
        display={visible}
        form={form}
      />
    ),
    [form, amount]
  );
  const renderConfirmStep = useCallback(
    (visible: boolean) => (
      <ConfirmReverseRentAdjustmentStep
        key={"confirm-reverse-rent-adjustment-step"}
        amount={amount}
        display={visible}
        form={form}
      />
    ),
    [form, amount]
  );

  const adjustmentType = adjustmentTypeFromAmount(amount).toLowerCase();
  const reverseSteps: Step[] = useMemo(
    () => [
      {
        stepId: "AddDescription",
        modalTitle: `Reverse rent ${adjustmentType}`,
        render: renderAddDescriptionStep
      },
      {
        stepId: "Confirm",
        modalTitle: `Confirm reversal of rent ${adjustmentType}`,
        render: renderConfirmStep
      }
    ],
    [renderAddDescriptionStep, renderConfirmStep, adjustmentType]
  );

  const onNextButtonPress = useCallback(
    async (newStepNumber: number) => {
      if (newStepNumber < currentStepNumber) {
        setCurrentStepNumber(newStepNumber);
        return;
      }
      const currentStepId = reverseSteps[currentStepNumber - 1].stepId;
      if (currentStepId === "AddDescription") {
        const isValid = await trigger();
        if (isValid) {
          setCurrentStepNumber(newStepNumber);
        }
      }
    },
    [currentStepNumber, reverseSteps, trigger]
  );

  const [reverseAdjustment] = useReverseRentAdjustment({
    onCompleted: onSuccess,
    onError: () => setShowErrorModal(true)
  });

  const submit = useCallback(
    async (formData: ReverseRentAdjustmentFormData) => {
      return reverseAdjustment({
        adjustmentId,
        formData
      });
    },
    [reverseAdjustment, adjustmentId]
  );

  return (
    <MultiStepForm
      title={reverseSteps[currentStepNumber - 1].modalTitle}
      type={"modal"}
      cancelButtonDisabled={false}
      isDirty={isDirty}
      isSubmitting={false}
      steps={reverseSteps.map((step) => step.render)}
      stepValue={currentStepNumber}
      submitButtonText={"Confirm"}
      onCancel={onClose}
      onChange={onNextButtonPress}
      onSubmit={handleSubmit(submit)}
      showSubmitError={showErrorModal}
      dismissSubmitError={() => {
        setShowErrorModal(false);
      }}
    />
  );
}
