import React, { useCallback } from "react";
import styled from "styled-components/native";
import { EmptyContent, ListItemStyle } from "@ailo/ui";
import {
  Chat,
  ChatList,
  ChatListItem,
  useChatAnalytics,
  useChatListContext
} from "@ailo/domains";
import { Link, useOnFocus } from "@ailo/services";
import { generateLinkToCommsTab } from "local/tabs/comms/ChatScreen";

interface Props {
  managementTeamId: string;
}

function PropertyChatList({ managementTeamId }: Props): React.ReactElement {
  const {
    chatList: { chats, loading, refetch }
  } = useChatListContext();
  const chatAnalytics = useChatAnalytics();

  const generateLink = useCallback(
    (chat: Chat): Link =>
      generateLinkToCommsTab({
        teamId: managementTeamId,
        chatId: chat.ailorn.internalId
      }),
    [managementTeamId]
  );

  const trackChatOpened = useCallback(
    (chat: Chat): void => {
      chatAnalytics.trackChatOpened({
        chatAilorn: chat.ailorn,
        from: "Property Screen"
      });
    },
    [chatAnalytics]
  );

  useOnFocus(refetch, {
    skipInitialFocus: true,
    fireOncePerFocus: true
  });

  if (loading || !chats) return <Loading />;

  if (chats.length === 0)
    return <StyledEmptyContent message={"No chats found"} />;

  return (
    <>
      {chats.map((chat) => (
        <ChatListItem
          key={`Chat-${chat.ailorn}`}
          link={generateLink}
          chat={chat}
          onPress={trackChatOpened}
          style={CHAT_LIST_ITEM_STYLE}
        />
      ))}
    </>
  );
}

const CHAT_LIST_ITEM_STYLE: ListItemStyle = {
  paddingLeft: 16,
  paddingRight: 16
};

function Loading(): React.ReactElement {
  return <ChatList.Loading numberOfSkeletons={2} />;
}

PropertyChatList.Loading = Loading;

const StyledEmptyContent = styled(EmptyContent)`
  margin-left: 16px;
  margin-right: 16px;
`;

export { PropertyChatList };
