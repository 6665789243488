import { AllocationPurpose } from "local/tabs";
import {
  GetAvailablePurposesQuery,
  OwingProgress,
  PaidToDate,
  PurposeType,
  useGetAvailablePurposesQuery
} from "local/graphql";
import { Money } from "@ailo/money";
import { LocalDate } from "@ailo/date";
import { capitalize } from "lodash";

export function useGetAvailablePurposesData({
  payableBy,
  payableTo,
  type
}: {
  payableBy?: string;
  payableTo?: string;
  type?: string;
}): {
  loading: boolean;
  data: AllocationPurpose[] | undefined;
  error: string | undefined;
  refetch: () => unknown;
} {
  const { data, loading, error, refetch } = useGetAvailablePurposesQuery({
    variables: {
      filters: {
        type,
        payableTo,
        payableBy
      }
    }
  });

  if (loading) {
    return { loading, data: undefined, error: undefined, refetch };
  }

  if (error) {
    return { error: error.message, data: undefined, loading: false, refetch };
  }

  return {
    loading: false,
    data: purposesToAllocation(data?.payablePurposes),
    error: undefined,
    refetch
  };
}

function formatPaidToDate(paidTo: PaidToDate): string {
  const classicP2D = LocalDate.from(paidTo.date).format("DD/MM/YYYY");
  const classicPartPayment =
    paidTo.partPayment && paidTo.partPayment.cents != 0
      ? Money.from(paidTo.partPayment).format()
      : null;
  return [classicP2D, classicPartPayment].filter(Boolean).join(" + ");
}

function purposesToAllocation(
  purposes: GetAvailablePurposesQuery["payablePurposes"]
): AllocationPurpose[] {
  return (purposes || []).map((p) => {
    const basePurpose = {
      purposeId: p.id,
      included: false,
      name: capitalize(p.type),
      description: null
    };

    function rentDescription(progress: Pick<OwingProgress, "paidTo">): string {
      const classic = formatPaidToDate(progress?.paidTo.classic);
      const effective = formatPaidToDate(progress?.paidTo.effective);

      return `Currently paid to ${classic}\nEffective paid to ${effective}`;
    }

    if (
      p.type === PurposeType.Rent &&
      p.payable?.__typename === "RecurringOwing"
    ) {
      return {
        ...basePurpose,
        amountDue: Money.fromCents(
          p.payable.progress?.due?.amount?.total?.cents || 0
        ),
        dueDate: p.payable?.progress?.due?.date,
        description: rentDescription(p.payable.progress)
      };
    } else if (
      p.type === PurposeType.Bill &&
      p.payable?.__typename === "Bill"
    ) {
      return {
        ...basePurpose,
        amountDue: Money.from(p.payable?.amount),
        dueDate: p.payable?.dueDateV2,
        description: p.payable?.description?.toString() || null
      };
    }

    return basePurpose;
  });
}
