import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { NewChatMessageScreen } from "./NewChatMessageScreen";
import { Screens, useNavigation, useRoute } from "local/common";
import { AiloRN } from "@ailo/ailorn";
import { useGetContacts, useOnNavigationCompleted } from "@ailo/domains";
import { Colors } from "@ailo/primitives";
import { ScreenComponent } from "@ailo/services";
import { useLayout } from "@react-native-community/hooks";
import { ViewProps } from "react-native";
import { ChatScreenContainer } from "local/tabs/comms/ChatScreen";
import styled from "styled-components/native";

export function NewChatMessageScreenContainer(): ReactElement {
  const { onLayout } = useLayout();
  const navigation = useNavigation<Screens.NewChatMessage>();
  const { initialContactIds } = useRoute<Screens.NewChatMessage>().params || {};
  const urlInitialContactAilorns = useMemo(
    () => parseUrlContactAilorns(initialContactIds),
    [initialContactIds]
  );

  const [urlContactAilorns, setUrlContactAilorns] = useState(
    urlInitialContactAilorns
  );

  useEffect(() => {
    if (!initialContactIds) return;
    setUrlContactAilorns(parseUrlContactAilorns(initialContactIds));
  }, [initialContactIds]);

  useOnNavigationCompleted(
    useCallback(() => {
      navigation.setParams({ initialContactIds: undefined });
    }, [navigation])
  );

  const { contacts, loading } = useGetContacts(urlContactAilorns);
  const urlSelectedContacts = useMemo(() => contacts ?? [], [contacts]);

  if (loading) {
    return <Loading onLayout={onLayout} />;
  }

  return (
    <NewChatMessageScreen externallySelectedContacts={urlSelectedContacts} />
  );
}

function parseUrlContactAilorns(
  urlContactIds: string | string[] | undefined
): AiloRN<"contact:contact">[] {
  if (!urlContactIds) return [];

  const urlContactIdsArray =
    typeof urlContactIds === "string"
      ? urlContactIds.split(",")
      : urlContactIds;

  return urlContactIdsArray.map((contactId) =>
    AiloRN.of("contact:contact", contactId)
  );
}

function Loading({
  onLayout
}: {
  onLayout: ViewProps["onLayout"];
}): ReactElement {
  return (
    <StyledScreenComponent>
      <ChatScreenContainer onLayout={onLayout} />
    </StyledScreenComponent>
  );
}

const StyledScreenComponent = styled(ScreenComponent)`
  background-color: ${Colors.CLOUD};
`;
