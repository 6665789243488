import { AiloRN } from "@ailo/ailorn";
import {
  ActionEvent,
  ActionEventListener,
  didQuerySucceed,
  useActionEventContext,
  useOnFocus,
  usePaginatedQuery
} from "@ailo/services";
import { Card, ErrorAlertScreen, PaginationNavigation } from "@ailo/ui";
import {
  StatementCreatedFor,
  StatementSort,
  StatementType,
  useGetStatementsQuery
} from "local/graphql";
import React, { ReactElement, useCallback } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { TransferStatementsList } from "./TransferStatementsList";

export interface TransferStatementsTabContentProps {
  tabNavigation: ReactElement;
  onPageChange?: () => void;
  style?: StyleProp<ViewStyle>;
  legalEntityAilorn: AiloRN<"authz:legalentity">;
}

export function TransferStatementsTabContent({
  tabNavigation,
  onPageChange,
  style,
  legalEntityAilorn
}: TransferStatementsTabContentProps): ReactElement {
  const result = usePaginatedQuery(useGetStatementsQuery, "statements", {
    variables: {
      filter: {
        subject: legalEntityAilorn?.toString(),
        types: [StatementType.AgencyTransfer],
        createdFor: [StatementCreatedFor.Disbursement]
      },
      pageSize: 30,
      sort: [StatementSort.RangeEndDateDesc, StatementSort.CreatedAtDesc]
    }
  });

  const eventContext = useActionEventContext();

  const onDisbursementTriggered: ActionEventListener = useCallback(
    ({ type }: ActionEvent): void => {
      if (type === "AgencyFundsDisbursed") {
        // We want to try to notice when the statement service asynchronously
        // crates a statement for the disbursement that was just triggered.
        [5000, 10000, 30000].forEach((delay) =>
          setTimeout(result.refetch, delay)
        );
      }
    },
    [result.refetch]
  );

  eventContext.subscribe(onDisbursementTriggered);

  useOnFocus(result.refetch);

  const {
    data,
    currentPage,
    pagesCount,
    goToPreviousPage,
    goToNextPage,
    loading
  } = result;

  const paginationNav =
    pagesCount && pagesCount > 1 ? (
      <PaginationNavigation
        style={{ marginTop: 20, justifyContent: "center" }}
        currentPage={currentPage}
        pagesCount={pagesCount}
        onGoToPreviousPage={
          goToPreviousPage &&
          ((): void => {
            goToPreviousPage();
            onPageChange?.();
          })
        }
        onGoToNextPage={
          goToNextPage &&
          ((): void => {
            goToNextPage();
            onPageChange?.();
          })
        }
      />
    ) : undefined;

  if (loading) {
    return (
      <Container style={style}>
        {tabNavigation}
        <StyledCard>
          <TransferStatementsList.Loading />
        </StyledCard>
        {paginationNav}
      </Container>
    );
  }

  if (!didQuerySucceed(result)) {
    return (
      <Container style={style}>
        {tabNavigation}
        <StyledCard>
          <ErrorAlertScreen
            title={"There was a problem loading transfer statements"}
            onRetry={() => result.refetch()}
          />
        </StyledCard>
      </Container>
    );
  }

  const statements = data?.statements?.items ?? [];

  return (
    <Container style={style}>
      {tabNavigation}
      <StyledCard>
        <TransferStatementsList
          statements={statements}
          subject={legalEntityAilorn}
        />
      </StyledCard>
      {paginationNav}
    </Container>
  );
}

const Container = styled(View)`
  min-width: 500px;
`;

const StyledCard = styled(Card)`
  margin: 0;
`;
