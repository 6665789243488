import { useHasFeature } from "@ailo/domains";
import { SelectOption } from "@ailo/ui";
import { useApolloClient } from "@apollo/client";
import { PlatformFeatureId } from "local/graphql";
import { useCallback } from "react";
import { getAgencyPropertyMostRelevantManagementId } from "../graphql/getAgencyPropertyMostRelevantManagementId";

export function useGetAssociatedManagementOption(): (
  selectedOption?: SelectOption
) => Promise<SelectOption | undefined> {
  const apolloClient = useApolloClient();
  const hasFeaturePropertySearchFromSearchService = useHasFeature(
    PlatformFeatureId.PropertySearchFromSearchService
  );

  const transformToSelectedManagementOption = useCallback(
    async (
      selectedOption?: SelectOption
    ): Promise<SelectOption | undefined> => {
      if (!selectedOption?.label || !selectedOption?.value) {
        return undefined;
      }

      const managementId = await (hasFeaturePropertySearchFromSearchService
        ? getAgencyPropertyMostRelevantManagementId(
            apolloClient,
            selectedOption.value
          )
        : selectedOption.value);

      return {
        ...selectedOption,
        label: selectedOption.label,
        value: managementId
      };
    },
    [apolloClient, hasFeaturePropertySearchFromSearchService]
  );

  return transformToSelectedManagementOption;
}
