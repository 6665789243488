import { useCurrentAgencyOrg } from "local/common";
import { useGetDisbursementPeriodDataQuery } from "local/graphql";

export type DisbursementPeriod = {
  disbursementPeriodSk: string;
  disbursementDisplayName: string;
  openingBalance: string;
  closingBalance: string;
};

export function DisbursementPeriods(): DisbursementPeriod[] {
  const { id } = useCurrentAgencyOrg();
  const variables = { managingOrgId: id };
  // get data
  const visualisationData = useGetDisbursementPeriodDataQuery({ variables });
  const disbursementPeriods =
    visualisationData.data?.agencyIncomeDisbursementPeriodReport?.rows ?? [];
  return disbursementPeriods as DisbursementPeriod[];
}
