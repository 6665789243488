import { DropdownMenuOption } from "@ailo/ui";
import { AllowedOperations, TenancyAgreementFragment } from "local/graphql";
import { Options } from "./ManageMenu";
import { getEditLeaseRenewalOption } from "./getEditLeaseRenewalOption";
import { getCreateLeaseRenewalOption } from "./getCreateLeaseRenewalOption";

export function getLeaseRenewalOption(
  leaseRenewal: Options<{
    allowedOperation: AllowedOperations;
    nextTenancyAgreement?: TenancyAgreementFragment;
  }>
): DropdownMenuOption {
  const canEditLeaseRenewal =
    leaseRenewal.allowedOperation === AllowedOperations.Edit;

  const editLeaseRenewalDisabled =
    leaseRenewal.allowedOperation === AllowedOperations.None;

  const onSelectLeaseRenewal = (): void => leaseRenewal.onPress(leaseRenewal);

  return canEditLeaseRenewal
    ? getEditLeaseRenewalOption({
        onSelect: onSelectLeaseRenewal,
        disabled: editLeaseRenewalDisabled
      })
    : getCreateLeaseRenewalOption({
        onSelect: onSelectLeaseRenewal,
        disabled: editLeaseRenewalDisabled
      });
}
