import { SFC, Text } from "@ailo/primitives";
import { Alert, FormFieldRow } from "@ailo/ui";
import {
  BillDescriptionInput,
  DueDateInput,
  InvoiceNumberInput,
  IssueDateInput,
  TaxCategoryInput
} from "local/domain/bill/AddBillForm";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import React from "react";
import { useFormContext } from "react-hook-form";
import { View } from "react-native";

const BillDetailsInput: SFC<{}> = ({ style }) => {
  const { watch } = useFormContext<AddBillFormData>();
  const invoiceNumberWarning = watch("invoiceNumberWarning");

  return (
    <View style={style} accessible={true}>
      <Text.BodyL weight={"medium"}>{"Bill Details"}</Text.BodyL>

      <TaxCategoryInput style={{ marginTop: 24 }} />
      <InvoiceNumberInput style={{ marginTop: 24 }} />
      {invoiceNumberWarning && (
        <Alert type={"warning"} style={{ marginTop: 12 }}>
          {invoiceNumberWarning}
        </Alert>
      )}

      <FormFieldRow style={{ marginTop: 24 }}>
        <IssueDateInput />
        <DueDateInput />
      </FormFieldRow>

      <BillDescriptionInput style={{ marginTop: 24 }} />
    </View>
  );
};

export { BillDetailsInput };
