import {
  AiloSentry,
  MappedQueryResult,
  useMappedQueryResult
} from "@ailo/services";
import {
  MaybeFileDetails,
  fragmentToFileDetails,
  RentScheduleDomainType,
  Address
} from "@ailo/domains";
import {
  useGetTenancyDetailsQuery,
  GetTenancyDetailsQuery,
  GetTenancyDetailsQueryVariables,
  VacatingReason,
  RentFragment,
  InspectionSectionInfoFragment
} from "local/graphql";
import { QueryHookOptions, ApolloError } from "@apollo/client";
import { LocalDate } from "@ailo/date";
import { AiloRN, nsEntities } from "@ailo/ailorn";
import { formatLegalEntityName } from "@ailo/domain-helpers";

export interface TenancyDetailsData {
  startDate?: LocalDate;
  endDate?: LocalDate;
  voidedAt?: LocalDate;
  agreementStartDate?: LocalDate;
  agreementEndDate?: LocalDate;
  agreementAllowedToLapse: boolean;
  nextAgreementStartDate?: LocalDate;
  displayRent?: RentFragment;
  nextRentSchedule?: RentScheduleDomainType;
  futureRents: RentFragment[];
  scheduledRentReviewDate?: LocalDate;
  paidToDate?: LocalDate;
  files: MaybeFileDetails[];
  vacatingReason?: VacatingReason;
  vacatingNotes?: string;
  property?: {
    address?: Address;
  };
  tenants: { legalEntity: AiloRN; name: string }[];
  managingEntity?: AiloRN;
  inspectionSection: InspectionSectionInfoFragment;
}

const maximumNumberOfDisplayedFile = 1000;

export function useGetTenancyDetails(
  opts: QueryHookOptions<
    GetTenancyDetailsQuery,
    GetTenancyDetailsQueryVariables
  >
): MappedQueryResult<TenancyDetailsData, GetTenancyDetailsQueryVariables> {
  const result = useGetTenancyDetailsQuery(opts);

  return useMappedQueryResult(result, (data) => {
    const tenancy = data?.tenancy;
    if (!tenancy)
      throw new ApolloError({
        errorMessage: `Cannot get tenancy for ${opts.variables?.id}`
      });

    if (tenancy.files == null) {
      tenancy.files = { items: [null], pageInfo: { total: 1 } };
    }

    if (tenancy.files.pageInfo.total > maximumNumberOfDisplayedFile) {
      AiloSentry.captureMessage(
        `Tenancy ${opts.variables?.id} has ${tenancy.files.pageInfo.total} files which ` +
          `is more than the maximum number of ${maximumNumberOfDisplayedFile} displayed files.`
      );
    }
    return {
      startDate: maybeLocalDate(tenancy.startDate),
      endDate: maybeLocalDate(tenancy.endDate),
      voidedAt: maybeLocalDate(tenancy.voidedAt),
      agreementStartDate: maybeLocalDate(
        tenancy.mostRecentTenancyAgreement?.startDate
      ),
      agreementEndDate: maybeLocalDate(
        tenancy.mostRecentTenancyAgreement?.fixedTermEndDate
      ),
      agreementAllowedToLapse:
        !!tenancy.mostRecentTenancyAgreement?.allowedToLapseAt,
      nextAgreementStartDate: maybeLocalDate(
        tenancy.nextTenancyAgreement?.startDate
      ),
      displayRent: maybe(tenancy.displayRent),
      nextRentSchedule: maybe(tenancy.nextRentSchedule),
      futureRents: tenancy.rents.items.filter((rent) =>
        tenancy.displayRent
          ? new LocalDate(rent.effectiveDate).isAfter(
              new LocalDate(tenancy.displayRent.effectiveDate)
            )
          : true
      ),
      scheduledRentReviewDate: maybeLocalDate(tenancy.scheduledRentReviewDate),
      paidToDate: maybeLocalDate(tenancy.liability?.effectivePaidToDate),
      files: tenancy.files.items.map(fragmentToFileDetails),
      vacatingReason: tenancy.vacatingReason ?? undefined,
      vacatingNotes: tenancy.vacatingNotes ?? undefined,
      property: tenancy.property,
      tenants: (tenancy.tenantships?.items || []).map((tenantship) => ({
        legalEntity: AiloRN.of(
          nsEntities.authz.legalentity,
          tenantship.tenant!.id
        ),
        name: formatLegalEntityName(tenantship.tenant!)
      })),
      managingEntity: tenancy.management?.managingEntity?.ailoRN
        ? AiloRN.from(tenancy.management?.managingEntity?.ailoRN)
        : undefined,
      inspectionSection: tenancy
    };
  });
}

function maybe<T>(input?: T | null): T | undefined {
  return input ?? undefined;
}

function maybeLocalDate(dateString?: string | null): LocalDate | undefined {
  return dateString ? LocalDate.from(dateString) : undefined;
}
