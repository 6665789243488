import { Screens, useNavigation } from "local/common";
import { useCallbackRef } from "@ailo/primitives";

/**
 * To be used only when inside Screens.ChatThread
 */
export function useCloseThread(): () => void {
  const navigation = useNavigation<Screens.ChatThread>();

  return useCallbackRef(() => {
    navigation.setParams({ threadId: undefined });
  }, [navigation]);
}
