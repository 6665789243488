import { ActionType } from "local/graphql";
import React, { ReactElement } from "react";
import { RefinedActionActivityProps } from "../ActionActivityProps";
import { NoTenancyEndDateView } from "./NoTenancyEndDateView";
import { EndTenancyDetailsView } from "./EndTenancyDetailsView";
import { ErrorAlertScreen } from "@ailo/ui";

export function EndTenancyActionActivity({
  projectId,
  action
}: RefinedActionActivityProps<ActionType.EndTenancy>): ReactElement {
  const { tenancy } = action.meta;

  if (!tenancy) {
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the action information."}
      />
    );
  }

  if (!tenancy.endDate) {
    return (
      <NoTenancyEndDateView
        tenancyId={tenancy.id}
        projectId={projectId}
        actionId={action.id}
      />
    );
  }

  return (
    <EndTenancyDetailsView
      actionId={action.id}
      projectId={projectId}
      tenancy={tenancy}
    />
  );
}
