import { formatPhoneNumber } from "@ailo/domains";
import {
  ABNIcon,
  Colors,
  EmailIcon,
  MobilePhoneIcon,
  Text
} from "@ailo/primitives";
import React from "react";
import { View } from "react-native";
import { formatABN } from "../formatABN";

interface Props {
  phoneNo?: string | null;
  emailAddress?: string | null;
  registeredEntityId?: string | null;
}

export function ProfileCardContactDetails({
  phoneNo,
  emailAddress,
  registeredEntityId
}: Props): React.ReactElement {
  return (
    <>
      <View style={{ flexDirection: "row", marginBottom: 12 }}>
        <EmailIcon
          width={20}
          height={20}
          style={{ marginRight: 12 }}
          color={Colors.TEXT.DARK.SECONDARY}
        />
        <Text.BodyM numberOfLines={3}>
          {emailAddress ?? "Not supplied"}
        </Text.BodyM>
      </View>
      <View
        style={{
          flexDirection: "row",
          marginBottom: registeredEntityId ? 12 : 0
        }}
      >
        <MobilePhoneIcon
          width={20}
          height={20}
          style={{ marginRight: 12 }}
          color={Colors.TEXT.DARK.SECONDARY}
        />
        <Text.BodyM numberOfLines={1}>
          {phoneNo ? formatPhoneNumber(phoneNo) : "Not supplied"}
        </Text.BodyM>
      </View>
      {registeredEntityId && (
        <View style={{ flexDirection: "row" }}>
          <ABNIcon
            width={20}
            height={20}
            style={{ marginRight: 12 }}
            color={Colors.TEXT.DARK.SECONDARY}
          />
          <Text.BodyS numberOfLines={3}>
            {formatABN(registeredEntityId)}
          </Text.BodyS>
        </View>
      )}
    </>
  );
}

ProfileCardContactDetails.Loading = Loading;

function Loading({ isCompany }: { isCompany?: boolean }): React.ReactElement {
  return (
    <>
      <Text.BodyS.Loading width={120} style={{ marginBottom: 8 }} />
      {isCompany && (
        <Text.BodyS.Loading width={120} style={{ marginBottom: 8 }} />
      )}
      <Text.BodyS.Loading width={120} />
    </>
  );
}
