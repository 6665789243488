import React from "react";
import { useFormContext } from "react-hook-form";
import { Button } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import { isFormValid } from "./utils";

interface Props {
  onPress: () => void;
}

const SubmitButton: React.FC<Props> = ({ onPress }) => {
  const form = useFormContext<AddBillFormData>();
  const buttonDisabled = !isFormValid(form);

  return (
    <Button.Primary
      onPress={onPress}
      disabled={buttonDisabled}
      testID={"SubmitButton"}
    >
      {"Add Bill"}
    </Button.Primary>
  );
};

export { SubmitButton as CreateBillButton };
