import moment from "moment";
import { OccupancyStatus } from "local/graphql";
import { formatDate } from "@ailo/services";
import { BadgeType } from "@ailo/ui";

interface Params {
  managementId: string;
  occupancyStatus: OccupancyStatus;
  tenancyEndDate?: string | null;
  fixedTermAgreementEndDate?: string | null;
  managementStartDate?: string | null;
  newTenancyStartDate?: string | null;
}

const getOccupancyStatusInfo = ({
  managementId,
  occupancyStatus,
  tenancyEndDate,
  fixedTermAgreementEndDate,
  managementStartDate,
  newTenancyStartDate
}: Params): string => {
  switch (occupancyStatus) {
    case OccupancyStatus.Occupied: {
      if (
        fixedTermAgreementEndDate &&
        moment(fixedTermAgreementEndDate).isSameOrAfter(moment(), "day")
      )
        return `Agreement expiry ${formatDate(fixedTermAgreementEndDate)}`;
      return "Periodic agreement";
    }

    case OccupancyStatus.Vacating: {
      if (newTenancyStartDate) {
        return `New tenancy starting ${formatDate(newTenancyStartDate)}`;
      }

      if (!tenancyEndDate)
        throw new Error(
          `End date is not defined for vacating management id: "${managementId}"`
        );
      return formatDate(tenancyEndDate);
    }

    case OccupancyStatus.Vacant: {
      if (newTenancyStartDate) {
        return `New tenancy starting ${formatDate(newTenancyStartDate)}`;
      }
      const vacantSinceDate = tenancyEndDate || managementStartDate;
      if (!vacantSinceDate)
        throw new Error(
          `Vacant since date is not defined for vacant management id: ${managementId}`
        );
      return `Since ${formatDate(vacantSinceDate)}`;
    }
  }
};

const getOccupancyBadgeType = (
  status: OccupancyStatus,
  newTenancyStartDate?: string | null
): BadgeType => {
  switch (status) {
    case OccupancyStatus.Occupied:
      return "success";
    case OccupancyStatus.Vacant:
      return newTenancyStartDate ? "attention" : "critical";
    case OccupancyStatus.Vacating:
      return "attention";
  }
};

export { getOccupancyStatusInfo, getOccupancyBadgeType };
