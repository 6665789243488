import { useMemo } from "react";
import { isPresent } from "ts-is-present";
import { useOccupancyStatusFilter } from "./OccupancyStatusFilter";
import { usePropertyListFilterState } from "./usePropertyListFilterState";

type ProjectFiltersReturn = {
  filterElements: React.ReactElement[];
  isAnyFilterActive: boolean;
} & Pick<ReturnType<typeof usePropertyListFilterState>, "filterState">;

export function usePropertyListFilters(options?: {
  occupancyStatusesDisabled?: boolean;
}): ProjectFiltersReturn {
  const { occupancyStatusesDisabled = false } = options || {};

  const { filterState, updateFilterState } = usePropertyListFilterState();

  const occupancyStatusesFilter = useOccupancyStatusFilter({
    occupancyStatuses: filterState.occupancyStatuses || [],
    updateFilterState,
    isFilterDisabled: occupancyStatusesDisabled
  });

  const filters = useMemo(
    () => [occupancyStatusesFilter],
    [occupancyStatusesFilter]
  );

  const filterElements = useMemo(
    () => filters.map((filter) => filter.component).filter(isPresent),
    [filters]
  );

  return {
    filterElements,
    isAnyFilterActive: filters.some((filter) => filter.isFilterActive),
    filterState
  };
}
