import { GridSortOrder } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import {
  ManagementFilterTypes,
  ManagementSortField,
  SortDirection,
  useGetPagedActiveManagementsLazyQuery
} from "local/graphql";
import { useCallback } from "react";
import { PropertyListQueryData } from "../BasePropertyList";
import { PropertyListColumns } from "../DefaultPropertyList";
import { PropertyListFilterStateType } from "../filters/usePropertyListFilterState";
import { getOccupancyStatusFilterParams } from "./getOccupancyStatusFilterParams";
import { getTeamsManagementFilterParams } from "./getTeamsManagementFilterParams";
import { useGetPropertyListQueryReturn } from "./useGetPropertyListQueryReturn";
import { PROPERTY_LIST_PAGE_SIZE, RequestCursor } from "./usePaginationRequest";
import { PropertyListSelectedTeams } from "./usePropertyListSelectedTeams";

export function useDefaultPropertyListQuery(
  initialSortOrder: GridSortOrder,
  filterVariables: PropertyListFilterStateType
): PropertyListQueryData {
  const currentOrg = useCurrentAgencyOrg();
  const { occupancyStatuses } = filterVariables;

  const [request, { data, loading, called }] =
    useGetPagedActiveManagementsLazyQuery({
      fetchPolicy: "cache-and-network"
    });

  const sendRequest = useCallback(
    (
      selectedTeams: PropertyListSelectedTeams,
      cursor: RequestCursor = {
        cursor: null,
        pageSize: PROPERTY_LIST_PAGE_SIZE,
        paginateBackward: false,
        sort: gridSortStateToApiParam(initialSortOrder)
      }
    ) => {
      request({
        variables: {
          organisationId: currentOrg.ailoRN,
          filters: [
            { name: ManagementFilterTypes.DefaultPropertyList },
            ...getTeamsManagementFilterParams(selectedTeams),
            ...getOccupancyStatusFilterParams(occupancyStatuses)
          ],
          pageCursor: cursor
        }
      });
    },
    [initialSortOrder, request, currentOrg.ailoRN, occupancyStatuses]
  );

  const pageInfo = data?.managementsForOrg?.pageInfo;
  const managements = data?.managementsForOrg?.items;

  return useGetPropertyListQueryReturn({
    sendRequest,
    pageInfo,
    managements,
    loading: loading && !data,
    called,
    sortStateToApiParam: gridSortStateToApiParam,
    filterVariables
  });
}

const gridSortStateToApiParam = ({
  columnKey,
  direction
}: GridSortOrder): {
  field: ManagementSortField;
  direction: SortDirection;
} => {
  const directionForApi =
    columnKey === PropertyListColumns.Arrears
      ? direction === "DESC"
        ? SortDirection.Asc
        : SortDirection.Desc
      : direction === "DESC"
      ? SortDirection.Desc
      : SortDirection.Asc;

  return { field: getApiSortField(columnKey), direction: directionForApi };
};

function getApiSortField(columnKey: string): ManagementSortField {
  if (columnKey === PropertyListColumns.Properties)
    return ManagementSortField.Address;

  if (columnKey === PropertyListColumns.Teams)
    return ManagementSortField.TeamName;

  if (columnKey === PropertyListColumns.Arrears)
    return ManagementSortField.LiabilityPaidToDate;

  throw new Error(`Cannot sort default property list by column ${columnKey}`);
}
