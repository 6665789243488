import React, { ReactElement } from "react";
import { withScreenComponent } from "@ailo/services";
import { Text } from "@ailo/primitives";
import { ReportPeriodList } from "./ReportPeriodList";
import { View } from "react-native";
import { ReportPeriodCard } from "./ReportPeriodCard";

export const ReconciliationListScreen = withScreenComponent(
  (): ReactElement | null => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          paddingVertical: "32px"
        }}
      >
        <Text.DisplayM style={{ marginBottom: 40, marginHorizontal: 60 }}>
          {"Reconciliations"}
        </Text.DisplayM>
        <ReportPeriodCard style={{ marginBottom: 16, marginHorizontal: 60 }} />
        <ReportPeriodList />
      </View>
    );
  }
);
