import { AiloSentry } from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import { useUpdatePropertyKeyMutation } from "local/graphql";
import { useCallback } from "react";
import { useKeyModalContext } from "../../PropertyKeyModalContext";
import { UpsertKeyFormData } from "../PropertyKeyFormContent";
import { usePropertyKeyUpdatedAnalytics } from "./usePropertyKeyUpdatedAnalytics";

export function useUpdatePropertyKey(keyId: string): {
  updatePropertyKey: (formData: UpsertKeyFormData) => void;
  loading: boolean;
} {
  const toast = useToastContext();

  const { organisationAilornString } = useKeyModalContext();

  const { trackPropertyKeyUpdated } = usePropertyKeyUpdatedAnalytics(keyId);

  const [updatePropertyKeyMutation, { loading }] = useUpdatePropertyKeyMutation(
    {
      onCompleted: () => {
        trackPropertyKeyUpdated();
        toast.show({ message: "Keys updated", type: "success" });
      },
      onError: (error) => {
        toast.show({
          message: "Unable to update keys. Please try again.",
          type: "error"
        });

        AiloSentry.captureException(error);

        throw new Error(error.message);
      }
    }
  );

  const updatePropertyKey = useCallback(
    async (formData: UpsertKeyFormData) => {
      await updatePropertyKeyMutation({
        variables: {
          input: {
            id: keyId,
            update: {
              ...formData
            }
          },
          organisationAilorn: organisationAilornString
        }
      });
    },
    [keyId, updatePropertyKeyMutation, organisationAilornString]
  );

  return { updatePropertyKey, loading };
}
