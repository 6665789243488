import { ApolloClient } from "@apollo/client";
import {
  GetAgencyPropertyDocument,
  GetAgencyPropertyQuery,
  GetAgencyPropertyQueryVariables
} from "local/graphql";

export async function getAgencyPropertyMostRelevantManagementId(
  client: ApolloClient<Object>,
  agencyPropertyId: string
): Promise<string> {
  const { data: agencyPropertyData, error } = await client.query<
    GetAgencyPropertyQuery,
    GetAgencyPropertyQueryVariables
  >({
    query: GetAgencyPropertyDocument,
    variables: {
      id: agencyPropertyId
    }
  });

  if (!agencyPropertyData?.agencyProperty || error) {
    throw new Error(`Could not fetch agency property ID: ${agencyPropertyId}`);
  }

  if (!agencyPropertyData.agencyProperty.mostRelevantManagement?.id) {
    throw new Error(
      `No relevant management could be fetched for id ${agencyPropertyId}`
    );
  }

  return agencyPropertyData.agencyProperty.mostRelevantManagement.id;
}
