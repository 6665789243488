import React, { ReactElement, ReactChild } from "react";
import { AvatarContainer, ListItem } from "@ailo/ui";
import { RightChevronIcon, Text } from "@ailo/primitives";
import { ManagementPortfolioListItemCenterContent } from "./ManagementPortfolioListItemCenterContent";
import { View } from "react-native";
import styled from "styled-components/native";

interface Props {
  leftContent?: ReactElement;
  rightContent?: ReactElement;
  isLastItem: boolean;
  onPress?: () => void;
  children: ReactChild[] | ReactChild | boolean;
}

export interface ManagementPortfolioListItemLoadingProps {
  leftIconShape?: "square" | "rounded-square" | "circle";
  isLastItem: boolean;
}

export function ManagementPortfolioListItem({
  leftContent,
  rightContent,
  isLastItem,
  onPress,
  children
}: Props): ReactElement | null {
  if (!children) return null;
  return (
    <ListItem
      leftComponent={leftContent}
      header={
        <ManagementPortfolioListItemCenterContent>
          {children}
        </ManagementPortfolioListItemCenterContent>
      }
      rightComponent={
        <StyledManagementPortfolioListItemRightContainer>
          {rightContent}
          {onPress && <StyledRightChevronIcon height={20} width={20} />}
        </StyledManagementPortfolioListItemRightContainer>
      }
      style={{
        borderType: isLastItem ? "none" : "under-center-and-right"
      }}
      onPress={onPress}
    />
  );
}

const StyledManagementPortfolioListItemRightContainer = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const StyledRightChevronIcon = styled(RightChevronIcon)`
  margin-left: 8px;
`;

ManagementPortfolioListItem.Loading =
  function ManagementPortfolioListItemLoading({
    leftIconShape,
    isLastItem
  }: ManagementPortfolioListItemLoadingProps): ReactElement {
    return (
      <ListItem
        header={<Text.BodyM.Loading width={120} />}
        leftComponent={
          leftIconShape && (
            <StyledAvatarLoading size={40} shape={leftIconShape} />
          )
        }
        style={{
          borderType: isLastItem ? "none" : "under-center-and-right"
        }}
      />
    );
  };

const StyledAvatarLoading = styled(AvatarContainer.Loading)`
  margin-right: 16px;
`;
