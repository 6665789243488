import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { BankingDetailsFormData } from "./BankingDetailsFormData";
import { Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { Button, useToastContext } from "@ailo/ui";
import {
  AbaFileFormat,
  useUpsertEftPaymentSettingsMutation
} from "local/graphql";
import { useAgencyDefaultWalletLegalEntity } from "local/common";

export function SaveButton({
  onSubmit,
  onError,
  refetchData
}: {
  onSubmit: () => void;
  onError: () => void;
  refetchData: () => void;
}): React.ReactElement {
  const agency = useAgencyDefaultWalletLegalEntity();
  const [upsertEftPaymentSettings] = useUpsertEftPaymentSettingsMutation();
  const { handleSubmit } = useFormContext<BankingDetailsFormData>();
  const toasts = useToastContext();
  const submit = useCallback(async () => {
    await handleSubmit(async (data: BankingDetailsFormData) => {
      onSubmit();
      const { errors } = await upsertEftPaymentSettings({
        variables: {
          input: {
            directEntryUserId: data.directEntryUserId || null,
            abaFileFormat: data.abaFileFormat as AbaFileFormat,
            agency: agency.toString()
          }
        }
      });

      if (errors) {
        onError();
      } else {
        toasts.show({ type: "success", message: "Banking details updated" });
        await refetchData();
      }
    })();
  }, [
    upsertEftPaymentSettings,
    handleSubmit,
    agency,
    toasts,
    onError,
    onSubmit,
    refetchData
  ]);

  return (
    <StyledSaveButton onPress={submit}>
      <Text.BodyM>{"Save changes"}</Text.BodyM>
    </StyledSaveButton>
  );
}

function Loading(): React.ReactElement {
  return (
    <StyledSaveButton disabled={true}>
      <Text.BodyM>{"Save changes"}</Text.BodyM>
    </StyledSaveButton>
  );
}

SaveButton.Loading = Loading;

const StyledSaveButton = styled(Button.Primary)`
  margin-left: auto;
`;
