import { ApolloCache } from "@apollo/client";

export function clearTrustAccountReconciliationAdjustmentsCache<T>(
  cache: ApolloCache<T>
): void {
  cache.modify({
    fields: {
      trustAccountReconciliationAdjustments(fieldValue, details) {
        return details.DELETE;
      }
    }
  });
}
