import { InspectionFragment } from "local/graphql";
import React, { ReactElement } from "react";
import { NoInspectionView } from "../CommonInspectionActionViews";
import { InspectionCompletedView } from "./InspectionCompletedView";

export function InspectionAppointmentExists({
  projectId,
  inspection
}: {
  projectId: string;
  inspection?: InspectionFragment | null;
}): ReactElement {
  const inspectionCompleted = inspection?.completedAt;

  if (inspectionCompleted) {
    return <InspectionCompletedView projectId={projectId} />;
  }

  return <NoInspectionView />;
}
