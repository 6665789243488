import React, { ReactElement } from "react";
import { View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { ProjectStatus } from "local/graphql";
import { ProjectStatusBadge } from "./ProjectStatusBadge";
import { ProjectReferenceBadge } from "./ProjectReferenceBadge";

interface ProjectsBadgesProps {
  project: {
    status: ProjectStatus;
    reference: string;
  };
  style?: ViewStyle;
}

export function ProjectBadges({
  project,
  style
}: ProjectsBadgesProps): ReactElement {
  return (
    <BadgeContainer style={style}>
      <ProjectStatusBadge status={project.status} style={{ marginRight: 4 }} />
      <ProjectReferenceBadge reference={project.reference} />
    </BadgeContainer>
  );
}

ProjectBadges.Loading = function ProjectStatusBadgeLoading({
  style
}: {
  style?: ViewStyle;
}): React.ReactElement {
  return (
    <BadgeContainer style={style}>
      <ProjectStatusBadge.Loading />
    </BadgeContainer>
  );
};

const BadgeContainer = styled(View)`
  height: 20px;
  flex-direction: row;
  align-items: center;
`;
