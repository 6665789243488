import React, { memo, ReactElement, useCallback } from "react";
import { DateInputFormField } from "@ailo/ui";
import { LocalDate } from "@ailo/date";
import { Controller, useFormContext } from "react-hook-form";
import { ViewStyle } from "react-native";
import { ReconciliationPeriod } from "../ReconciliationViewScreen/types";

interface ReconciliationDateControlProps {
  period: ReconciliationPeriod;
  style?: ViewStyle;
  name: string;
  label: string;
}

function ReconciliationDateControlComponent({
  period,
  style,
  label,
  name
}: ReconciliationDateControlProps): ReactElement {
  const { errors } = useFormContext();
  const errorMessage = errors[name]?.message;
  const render = useCallback(
    ({ onChange, ...data }: RenderData<string>) => (
      <DateInputFormField
        style={style}
        label={label}
        error={errorMessage}
        minDate={period.periodStart.toString()}
        maxDate={period.periodEnd.toString()}
        onChange={(value) => onChange(value ?? "")}
        {...data}
      />
    ),
    [errorMessage, label, period.periodEnd, period.periodStart, style]
  );
  return (
    <Controller name={name} render={render} rules={rules} defaultValue={""} />
  );
}

const rules = {
  required: "You need to enter a date",
  validate: (value: string) => {
    try {
      LocalDate.fromString(value);
      return true;
    } catch {
      return "Date is not valid";
    }
  }
};

export const ReconciliationDateControl = memo(
  ReconciliationDateControlComponent
);

export interface RenderData<Value> {
  onChange: (value: Value) => void;
  onBlur: () => void;
  value: Value;
  ref: React.MutableRefObject<unknown>;
}
