import { Colors, Text, loadingTrio } from "@ailo/primitives";
import { InspectionMonth } from "local/domain/inspection";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { InspectionDayListItem } from "./InspectionDayListItem";

interface Props {
  inspectionMonth: InspectionMonth;
}

export function InspectionMonthListItem({
  inspectionMonth
}: Props): React.ReactElement {
  return (
    <Container>
      <Text.Subheading
        style={{ marginLeft: 12, marginBottom: 8 }}
        color={Colors.TEXT.DARK.SECONDARY}
      >
        {inspectionMonth.firstDayOfMonth.format("MMMM")}
      </Text.Subheading>
      {inspectionMonth.inspectionDays.map((inspectionDay) => (
        <SpacedDayListItem
          inspectionDay={inspectionDay}
          key={`InspectionDay ${inspectionDay.date.format()}`}
        />
      ))}
    </Container>
  );
}

const SpacedDayListItem = styled(InspectionDayListItem)`
  margin-top: 8px;
`;

InspectionMonthListItem.Loading = function Loading() {
  return (
    <Container>
      <Text.Subheading.Loading
        style={{ marginLeft: 12, marginBottom: 8, marginTop: 4 }}
      />
      {loadingTrio(SpacedLoadingDayListItem)}
    </Container>
  );
};

const SpacedLoadingDayListItem = styled(InspectionDayListItem.Loading)`
  margin-top: 8px;
`;

const Container = styled(View)`
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 32px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${Colors.GRAYSCALE.OUTLINE};
`;
