import { ErrorBoundary } from "@ailo/services";
import React, { ReactElement } from "react";
import { TabContentErrorBoundaryFallback } from "./TabContentErrorBoundaryFallback";
import {
  TransferStatementsTabContent,
  TransferStatementsTabContentProps
} from "./TransferStatementsTabContent";

export function TransferStatementsTab(
  props: TransferStatementsTabContentProps
): ReactElement {
  return (
    <ErrorBoundary
      fallbackComponent={TabContentErrorBoundaryFallback(props.tabNavigation)}
    >
      <TransferStatementsTabContent {...props} />
    </ErrorBoundary>
  );
}
