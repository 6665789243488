import { ParamListBase } from "@react-navigation/native";
import { LiteralUnion } from "type-fest";
import { Screens } from "../Screens";

export enum ProjectSideView {
  ACTIONS = "Actions"
}

export type ProjectListTab = LiteralUnion<"open" | "closed", string>;

export interface ProjectTabStackNavigatorParamList extends ParamListBase {
  [Screens.ProjectList]: {
    tab?: ProjectListTab;
    create?: boolean;
  };
  [Screens.Project]: {
    id?: string;
    option?: "new" | "edit";
    managementId?: string;
    tab?: ProjectSideView;
    actionId?: string;
    previousScreen?: Screens.ProjectList | Screens.Property;
  };
  [Screens.Action]: {
    projectId: string;
    id: "new" | string;
  };
}
