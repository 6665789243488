import { throwIfMutationFailed, AiloSentry } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { useCallback } from "react";
import {
  RemoveProjectFilesMutation,
  useRemoveProjectFilesMutation,
  GetProjectDetailsDocument
} from "local/graphql";

export function useRemoveFiles({
  onCompleted,
  onError
}: {
  onCompleted: ({
    projectFileIds
  }: {
    projectFileIds: (string | null)[];
  }) => void;
  onError: () => void;
}): [
  (projectId: string, projectFileIds: string[]) => void,
  MutationResult<RemoveProjectFilesMutation>
] {
  const [removeProjectFilesMutation, result] = useRemoveProjectFilesMutation();

  const removeProjectFiles = useCallback(
    async (projectId: string, projectFileIds: string[]): Promise<void> => {
      let result: ExecutionResult<RemoveProjectFilesMutation>;

      try {
        result = await removeProjectFilesMutation({
          variables: { projectFileIds },
          refetchQueries: [
            {
              query: GetProjectDetailsDocument,
              variables: { id: projectId, filesPageSize: 8 }
            }
          ]
        });
        throwIfMutationFailed(result, { dataKey: "removeProjectFiles" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }

      onCompleted({ projectFileIds: result.data!.removeProjectFiles! });
    },
    [removeProjectFilesMutation, onCompleted, onError]
  );

  return [removeProjectFiles, result];
}
