import React, { ReactElement } from "react";
import { ProgressBar, CellContainer } from "@ailo/ui";
import { ProjectListRow } from "../ProjectList";
import { ActionStatus, ProjectStatus } from "local/graphql";

export function ProgressCell({
  row: { actions, status }
}: {
  row: ProjectListRow;
  rowIndex: number;
}): ReactElement {
  const completed = actions?.items.filter(
    (action) => action.status === ActionStatus.Done
  ).length;
  const maxCompleted = actions?.pageInfo.total;
  const finished = status === ProjectStatus.Closed;

  return (
    <CellContainer>
      <ProgressBar
        label={true}
        completed={completed || 0}
        maxCompleted={maxCompleted || 0}
        finished={finished}
      />
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <ProgressBar.Loading />
    </CellContainer>
  );
}

ProgressCell.Loading = Loading;
