import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { Screens } from "local/common";
import { ReconciliationListScreen } from "../ReconciliationListScreen";
import { ReconciliationViewScreen } from "../ReconciliationViewScreen";
import { ReconciliationAdjustmentListScreen } from "../ReconciliationAdjustmentListScreen";

const Stack = createStackNavigator();

export function ReconciliationStackNavigator(): React.ReactElement {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name={Screens.TrustReconciliationList}
        component={ReconciliationListScreen}
      />
      <Stack.Screen
        name={Screens.TrustReconciliationView}
        component={ReconciliationViewScreen}
      />
      <Stack.Screen
        name={Screens.TrustReconciliationAdjustmentList}
        component={ReconciliationAdjustmentListScreen}
      />
    </Stack.Navigator>
  );
}
