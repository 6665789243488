import { AiloRN } from "@ailo/ailorn";
import { PlatformFeatureId } from "local/graphql";
import React, { useContext } from "react";

interface TrustAccount {
  id: string;
  ailorn: string;
}

interface Company {
  id: string;
  ailorn: string;
  registeredEntityName: string;
  currentUserPermissions: string[];
  trustAccounts: TrustAccount[];
  defaultTrustAccount?: TrustAccount;
  timezone: string;
}

export interface AgencyOrganisation {
  id: string;
  ailoRN: string;
  ailorn: AiloRN<"authz:organisation">;
  name: string;
  legalEntities: Company[];
  availableFeatures: PlatformFeatureId[];
  defaultWalletLegalEntity?: Company;
}

const currentAgencyContext = React.createContext<AgencyOrganisation | null>(
  null
);

export const CurrentAgencyProvider =
  currentAgencyContext.Provider as React.Provider<AgencyOrganisation>;

export function useCurrentAgencyOrg(): AgencyOrganisation {
  const currentAgency = useContext(currentAgencyContext);
  if (currentAgency == null) {
    throw new Error("useCurrentAgencyOrg used before agency data is loaded");
  }
  return currentAgency;
}
