import React from "react";
import { useDownloadFile } from "@ailo/domains";
import { Button, ButtonProps } from "@ailo/ui";
import { useGetFormUrl } from "./useGetFormUrl";
import { useReiFormUrlButton } from "./useReiFormUrlButton";
import { useReiFormDownloadButtonError } from "./useReiFormDownloadButtonError";

export function ReiFormDownloadWithUrlButton({
  formId,
  children,
  ...props
}: ButtonProps & { formId: string }): React.ReactElement {
  const { downloadFile } = useDownloadFile();
  const getFormUrl = useGetFormUrl(formId, "download");

  const onError = useReiFormDownloadButtonError();

  const { loading, onPressReiFormButton } = useReiFormUrlButton({
    getFormUrl,
    onGetFormUrlComplete: downloadFile,
    onError
  });

  return (
    <Button {...props} loading={loading} onPress={onPressReiFormButton}>
      {children}
    </Button>
  );
}
