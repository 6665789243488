import React, { ReactElement } from "react";
import { Text } from "@ailo/primitives";
import { CellContainer } from "./CellContainer";
import { UnscheduledTenanciesListRow } from "../useGetUnscheduledTenanciesRows";
import { formatDateOrDefault } from "@ailo/services";

export function LastInspectionCell({
  row: { lastInspectionDate }
}: {
  row: UnscheduledTenanciesListRow;
  rowIndex: number;
}): ReactElement {
  return (
    <CellContainer>
      <Text.BodyS>{formatDateOrDefault("—", lastInspectionDate)}</Text.BodyS>
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <Text.BodyS.Loading width={80} />
    </CellContainer>
  );
}

LastInspectionCell.Loading = Loading;
