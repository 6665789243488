import { useCurrentUser } from "@ailo/domains";
import { Text } from "@ailo/primitives";
import React, { ReactElement } from "react";

export const DashboardTitle = (): ReactElement => {
  const { firstName } = useCurrentUser().person;

  return (
    <Text.DisplayM weight={"medium"} style={{ marginBottom: 40 }}>
      {"Hello, "}
      {firstName} {"👋"}
    </Text.DisplayM>
  );
};
