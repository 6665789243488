import React, { ReactElement } from "react";
import { useNavigateToEditTenancyBondClaim } from "local/tabs";
import { Alert, AlertLinkFooter } from "@ailo/ui";
import { StyleProp, ViewStyle } from "react-native";

export function BondErrorAlertContent({
  managementId,
  tenancyId,
  style
}: {
  managementId: string;
  tenancyId: string;
  style?: StyleProp<ViewStyle>;
}): ReactElement {
  const navigateToEditBondClaim = useNavigateToEditTenancyBondClaim({
    managementId,
    tenancyId
  });

  return (
    <Alert
      style={style}
      type={"error"}
      message={
        "A bond claim for this property does not match the funds received."
      }
      footer={
        <AlertLinkFooter
          label={"Edit claim in Ailo"}
          onPress={navigateToEditBondClaim}
        />
      }
    />
  );
}
