import { Colors, Text } from "@ailo/primitives";
import React from "react";
import { View } from "react-native";
import { Button } from "@ailo/ui";

function ProcessDisbursementsActionHeader({
  selectedDisbursementIds
}: {
  selectedDisbursementIds: string[];
}): React.ReactElement {
  const numberSelected = selectedDisbursementIds.length;
  const pluralSuffix = numberSelected > 1 ? "s" : "";
  const buttonText = "Process disbursement" + pluralSuffix;

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: Colors.OCEAN,
        height: "48px",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
      }}
    >
      <Text.BodyM style={{ marginLeft: "16px" }} color={Colors.WHITE}>
        {`${numberSelected} selected`}
      </Text.BodyM>
      <Button
        variant={"secondary"}
        type={"small"}
        style={{ marginRight: "16px" }}
        backgroundColor={Colors.WHITE}
        contentColor={Colors.OCEAN}
      >
        <Text.BodyM weight={"medium"}>{buttonText}</Text.BodyM>
      </Button>
    </View>
  );
}

export { ProcessDisbursementsActionHeader };
