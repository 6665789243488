import { useOnFocus } from "@ailo/services";
import { Button } from "@ailo/ui";
import { useLinkProps } from "@react-navigation/native";
import moment from "moment-timezone";
import React, { ReactElement } from "react";
import { useTenancyStartDateQuery } from "local/graphql";

interface Props {
  managementId: string;
  tenancyId: string;
}

export function CreateRentCreditButton({
  managementId,
  tenancyId
}: Props): ReactElement | null {
  const linkProps = useLinkProps({
    to: `/properties/${managementId}/tenancies/${tenancyId}/create-rent-adjustment`
  });

  const { data, loading, error, refetch } = useTenancyStartDateQuery({
    variables: { tenancyId }
  });

  useOnFocus(refetch);

  const tenancyStartDate = data?.tenancy?.startDate;
  const tenancyTimezone =
    data?.tenancy?.management?.managingEntity?.timezone || "Australia/Sydney";

  const isIngoingTenancy = moment
    .tz(tenancyTimezone)
    .isBefore(moment(tenancyStartDate), "day");

  return (
    <Button.Primary
      loading={!data && loading}
      disabled={isIngoingTenancy || !!error}
      {...linkProps}
    >
      {"Create Rent Credit"}
    </Button.Primary>
  );
}
