import { StyleProp, ViewStyle } from "react-native";
import { SuccessIcon, MoreIcon, EditIcon } from "@ailo/primitives";
import {
  Button,
  DropdownMenu,
  DropdownMenuToggleProps,
  useGlobalModal
} from "@ailo/ui";
import React, { useCallback } from "react";
import { isPresent } from "ts-is-present";
import { ActionDetailsFragment, ActionStatus } from "local/graphql";
import { View } from "react-native-animatable";
import { ActionModalForm } from "local/modals/ActionModalForm/ActionModalForm";

export function ActionMenu({
  onChangeActionStatusPress,
  action,
  projectId,
  style
}: {
  onChangeActionStatusPress: () => void;
  action: ActionDetailsFragment;
  projectId: string;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement | null {
  const modal = useGlobalModal();
  const cancel = useCallback(() => {
    modal.hide();
  }, [modal]);

  const onEditAction = useCallback((): void => {
    modal.show(
      <ActionModalForm
        action={action}
        projectId={projectId}
        onDismiss={cancel}
      />
    );
  }, [action, projectId, modal, cancel]);

  const changeStatusAction = {
    label:
      action.status === ActionStatus.Active ? "Mark as done" : "Mark as active",
    icon: <SuccessIcon />,
    onSelect: (): void => onChangeActionStatusPress()
  };
  const editAction = {
    label: "Edit action",
    icon: <EditIcon />,
    onSelect: (): void => onEditAction()
  };

  const options = [editAction, changeStatusAction].filter(isPresent);
  if (options.length === 0) return null;

  return (
    <View style={style}>
      <DropdownMenu renderToggle={renderDropdownButton} options={options} />
    </View>
  );
}

function renderDropdownButton({
  open,
  toggle
}: DropdownMenuToggleProps): React.ReactElement {
  return (
    <Button.Secondary type={"small"} square active={open} onPress={toggle}>
      <MoreIcon width={20} height={20} />
    </Button.Secondary>
  );
}

ActionMenu.Loading = function ActionMenuLoading({
  style
}: {
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <View style={style}>
      <Button.Loading type={"small"} square />
    </View>
  );
};
