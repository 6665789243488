import { DeleteIcon, MoreIcon, ProjectIcon } from "@ailo/primitives";
import {
  Button,
  DropdownMenu,
  DropdownMenuOption,
  DropdownMenuToggleProps
} from "@ailo/ui";
import { PendingInspectionAppointmentFragment } from "local/graphql";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";

export function AppointmentTableRowMoreButton({
  style
}: {
  appointment: PendingInspectionAppointmentFragment;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement | null {
  const options: DropdownMenuOption[] = [
    {
      label: "View project",
      onSelect: () => {
        //Link to project here
      },
      icon: <ProjectIcon />
    },
    {
      label: "Cancel inspection",
      onSelect: () => {
        //Archive project for this appointment here
      },
      icon: <DeleteIcon />
    }
  ];

  function renderDropdownButton({
    toggle,
    open
  }: DropdownMenuToggleProps): React.ReactElement {
    return (
      <Button variant={"text"} onPress={toggle} style={style} active={open}>
        <MoreIcon />
      </Button>
    );
  }

  return <DropdownMenu renderToggle={renderDropdownButton} options={options} />;
}
