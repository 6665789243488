import { useCurrentAgencyOrg } from "local/common";
import { ApolloError } from "@apollo/client";
import {
  MigrationDetailsRefreshStatus,
  useGetMigrationDetailsQuery
} from "local/graphql";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";

interface GetMigrationTasksStatus {
  hasActiveExternalTrustAccount?: boolean;
  lastRefreshedAt?: Moment;
  refreshStatus?: MigrationDetailsRefreshStatus;
  loading: boolean;
  error?: ApolloError;
}

const POLL_INTERVAL_MS = 3e5; // 5 minutes

interface Params {
  refetchList: () => void;
}

export function useGetMigrationDetails({
  refetchList
}: Params): GetMigrationTasksStatus {
  const { id: organisationId } = useCurrentAgencyOrg();
  const [previousStatus, setPreviousStatus] = useState<
    MigrationDetailsRefreshStatus | undefined
  >();

  const { data, loading, error } = useGetMigrationDetailsQuery({
    variables: { organisationId },
    pollInterval: POLL_INTERVAL_MS
  });

  const hasActiveExternalTrustAccount =
    data?.organisation?.hasActiveExternalTrustAccount;
  const migrationDetails = data?.organisation?.migrationDetails;
  const lastRefreshedAt = migrationDetails?.lastRefreshedAt
    ? moment(migrationDetails?.lastRefreshedAt)
    : undefined;
  const refreshStatus = migrationDetails?.refreshStatus ?? undefined;

  useEffect(() => {
    if (refreshStatus === previousStatus) return;

    if (refreshStatus === MigrationDetailsRefreshStatus.Success) {
      refetchList();
    }

    setPreviousStatus(refreshStatus);
  }, [refreshStatus, previousStatus, refetchList]);

  return {
    hasActiveExternalTrustAccount,
    lastRefreshedAt,
    refreshStatus,
    loading,
    error
  };
}
