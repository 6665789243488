import { GridSortOrder } from "@ailo/ui";
import React from "react";
import {
  AddressCellWithNoPaymentWarning,
  RentCell,
  BalanceOwedCell,
  OverdueDateCell,
  PaidToDateCell
} from "./PropertyItemCell";
import { usePropertyListReorder, useOverduePropertyListQuery } from "./hooks";
import { ComeBackSoonMessage } from "./ComeBackSoonMessage";
import { lowerCase, startCase } from "lodash";
import { BasePropertyList, BasePropertyListProps } from "./BasePropertyList";
import { createCellRenderer } from "./createCellRenderer";

function OverduePropertyList({
  tabNavigation
}: {
  tabNavigation: React.ReactElement;
}): React.ReactElement {
  const queryData = useOverduePropertyListQuery(initialSortOrder);
  const { sortOrder, onSortOrderChange } = usePropertyListReorder(
    initialSortOrder,
    queryData.requestFromBeginning,
    analyticsInfo
  );

  return (
    <BasePropertyList
      tabNavigation={tabNavigation}
      queryData={queryData}
      errorMessage={"There was a problem loading Overdue"}
      emptyMessage={"No overdue properties to show"}
      secondaryEmptyMessage={<ComeBackSoonMessage />}
      sortOrder={sortOrder}
      columns={columns}
      onSortOrderChange={onSortOrderChange}
    />
  );
}

export enum OverduePropertyListColumns {
  Properties = "Properties",
  Rent = "Rent",
  BalanceOwed = "Balance owed",
  DueDate = "Due date",
  PaidTo = "Paid to"
}

const columns: BasePropertyListProps["columns"] = [
  {
    width: 3,
    renderCell: createCellRenderer(AddressCellWithNoPaymentWarning),
    renderLoadingCell: AddressCellWithNoPaymentWarning.Loading,
    name: OverduePropertyListColumns.Properties,
    sortable: true
  },
  {
    width: 1,
    renderCell: createCellRenderer(RentCell),
    renderLoadingCell: RentCell.Loading,
    name: OverduePropertyListColumns.Rent,
    sortable: false,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(BalanceOwedCell),
    renderLoadingCell: BalanceOwedCell.Loading,
    name: OverduePropertyListColumns.BalanceOwed,
    sortable: true,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(OverdueDateCell),
    renderLoadingCell: OverdueDateCell.Loading,
    name: OverduePropertyListColumns.DueDate,
    sortable: true,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(PaidToDateCell),
    renderLoadingCell: PaidToDateCell.Loading,
    name: OverduePropertyListColumns.PaidTo,
    sortable: true,
    horizontalAlign: "right",
    verticalAlign: "middle"
  }
];

const initialSortOrder: GridSortOrder = {
  columnKey: OverduePropertyListColumns.PaidTo,
  direction: "ASC"
};

function analyticsColName(columnKey: string): string {
  if (columnKey === OverduePropertyListColumns.PaidTo) return "Arrears";
  if (columnKey === OverduePropertyListColumns.Properties) return "Address";
  if (columnKey === OverduePropertyListColumns.BalanceOwed)
    return "Balance Owed";
  if (columnKey === OverduePropertyListColumns.DueDate) return "Due Date";
  throw new Error(`Unexpected column to return analytics: ${columnKey}`);
}

function analyticsInfo(sortOrder: GridSortOrder): {
  eventName: string;
  orderBy: string;
} {
  return {
    eventName: "Overdue Property List Reordered",
    orderBy: `${analyticsColName(sortOrder.columnKey)} ${startCase(
      lowerCase(sortOrder.direction)
    )}`
  };
}

export { OverduePropertyList };
