import React from "react";
import { Badge } from "@ailo/ui";
import { useCustomersListQuery } from "./useCustomersListQuery";
import { BadgeQueryParams } from "local/common";

interface Props {
  active?: boolean;
  params?: BadgeQueryParams;
}

export function CustomersListBadge({
  active = true,
  params = {}
}: Props): React.ReactElement {
  const queryData = useCustomersListQuery(params?.queryString ?? "");
  const { data, loading } = queryData;

  const pageTotal = data.pageInfo?.total;

  if (loading) {
    return <Badge.Loading width={27} style={{ marginLeft: 8 }} />;
  }

  return (
    <Badge type={active ? "ailo" : "outline"} style={{ marginLeft: 8 }}>
      {pageTotal}
    </Badge>
  );
}
