import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { useCallback } from "react";
import { MutationResult } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { isPresent } from "ts-is-present";
import {
  GetPagedAwaitingApprovalMigratingManagementsDocument,
  GetPagedExcludedMigratingManagementsDocument,
  ExcludeMigratingManagementMutation,
  ExcludeMigratingManagementMutationVariables,
  GetMigratingManagementSidebarDocument,
  useExcludeMigratingManagementMutation
} from "local/graphql";
import { AiloRN, services } from "@ailo/ailorn";

interface ExcludeMigratingManagementsProps {
  migratingManagementId: string;
  onSuccess: (result: ExcludeMigratingManagementMutation) => void;
  onError: () => void;
}

type ExcludeMigratingManagementMutationInputVariables = Omit<
  ExcludeMigratingManagementMutationVariables,
  "migratingManagementId"
>;

type ExcludeMigratingManagements = [
  (params: ExcludeMigratingManagementMutationInputVariables) => void,
  MutationResult<ExcludeMigratingManagementMutation>
];

const refreshOperationNames = [
  getOperationName(GetPagedAwaitingApprovalMigratingManagementsDocument),
  getOperationName(GetPagedExcludedMigratingManagementsDocument)
];

export function useExcludeMigratingManagement({
  migratingManagementId,
  onSuccess,
  onError
}: ExcludeMigratingManagementsProps): ExcludeMigratingManagements {
  const analytics = useAnalytics();

  const [excludeMigratingManagementMutation, mutationResult] =
    useExcludeMigratingManagementMutation({
      refetchQueries: [
        {
          query: GetMigratingManagementSidebarDocument,
          variables: {
            migratingManagementId
          }
        },
        ...refreshOperationNames.filter(isPresent)
      ]
    });

  const excludeMigratingManagement = useCallback(
    async ({
      exclusionReason,
      exclusionNotes
    }: ExcludeMigratingManagementMutationInputVariables) => {
      try {
        analytics.track("Migrating Property Excluded", {
          migrating_management_id: AiloRN.of(
            services.Migration.migratingManagement,
            migratingManagementId
          ).toString(),
          reason: exclusionReason
        });

        const result = await excludeMigratingManagementMutation({
          variables: { migratingManagementId, exclusionReason, exclusionNotes }
        });
        throwIfMutationFailed(result, {
          dataKey: "excludeMigratingManagement"
        });

        if (!result.data) {
          onError();
          return;
        }

        onSuccess(result.data);
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
      }
    },
    [
      excludeMigratingManagementMutation,
      onSuccess,
      onError,
      analytics,
      migratingManagementId
    ]
  );

  return [excludeMigratingManagement, mutationResult];
}
