import React, { ReactElement } from "react";

import { Badge, BadgeType } from "@ailo/ui";
import { DepositStatus } from "../mapDepositStatus";
import { StyleProp, ViewStyle } from "react-native";

type Props = {
  status: DepositStatus;
  style?: StyleProp<ViewStyle>;
};
const DepositStatusBadge = ({ status, style }: Props): ReactElement => {
  return (
    <Badge type={mapStatusToBadgeType(status)} style={style}>
      {status}
    </Badge>
  );
};

function mapStatusToBadgeType(status: DepositStatus): BadgeType {
  switch (status) {
    case DepositStatus.Cleared:
    case DepositStatus.Released:
      return "success";
    case DepositStatus.Unpaid:
    case DepositStatus.Pending:
      return "attention";
    case DepositStatus.Failed:
    case DepositStatus.Cancelled:
      return "critical";
    default:
      return "default";
  }
}

export { DepositStatusBadge, mapStatusToBadgeType };
