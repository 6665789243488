import React from "react";
import { View } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";
import { SFC } from "@ailo/primitives";
import { TextInputFormField } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
interface Props {
  index: number;
}

const LineItemDescriptionInput: SFC<Props> = ({ index, style }) => {
  const { control, setValue, errors } = useFormContext<AddBillFormData>();
  const name = `lineItems[${index}].description`;

  return (
    <View style={style}>
      <Controller
        name={name}
        control={control}
        defaultValue={""}
        render={({ value, onChange, onBlur, ref }) => {
          return (
            <TextInputFormField
              {...{ value, onBlur, inputRef: ref, onChange }}
              error={errors.lineItems?.[index]?.description?.message}
              placeholder={"Line Item"}
              testID={"LineItemDescriptionInput"}
              onFocus={() => {
                setValue("fieldUnderInvoiceNumberWasFocused", true);
              }}
            />
          );
        }}
        rules={{
          validate: (description): boolean | string =>
            !isEmpty(description) || `Enter line item description`
        }}
      />
    </View>
  );
};

export { LineItemDescriptionInput };
