import React from "react";
import { Row } from "local/common";
import { DateInputFormField } from "@ailo/ui";

export function DateRangeFilter({
  dateRangeSelection,
  onChange
}: {
  dateRangeSelection?: {
    startDate?: string;
    endDate?: string;
  };
  onChange: (item: any) => void;
}): React.ReactElement {
  return (
    <Row
      style={{
        justifyContent: "flex-end"
      }}
    >
      <DateInputFormField
        webPopoverHorizontalAlign={"end"}
        onChange={(date): void =>
          onChange({ startDate: date, endDate: dateRangeSelection?.endDate })
        }
        label={"From Date"}
        placeholder={"From Date"}
        autoCorrect={false}
        autoCompleteType={"off"}
        value={dateRangeSelection?.startDate}
        testID={"DateRangeFilterStartDate"}
        maxDate={dateRangeSelection?.endDate}
      />
      <p style={{ padding: 6 }} />
      <DateInputFormField
        webPopoverHorizontalAlign={"end"}
        onChange={(date): void =>
          onChange({ endDate: date, startDate: dateRangeSelection?.startDate })
        }
        label={"To Date"}
        placeholder={"To Date"}
        autoCorrect={false}
        autoCompleteType={"off"}
        value={dateRangeSelection?.endDate}
        testID={"DateRangeFilterToDate"}
        minDate={dateRangeSelection?.startDate}
      />
    </Row>
  );
}
