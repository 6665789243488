import React from "react";
import { Colors, PropertyIcon } from "@ailo/primitives";
import { SearchBoxOption } from "@ailo/ui";
import { AgencyPropertiesQuery } from "local/domain/propertyManagement";
import { formatAddress } from "@ailo/domain-helpers";

export function formatAgencyPropertyToSearchBoxOption(
  agencyProperty: AgencyPropertiesQuery["agencyProperties"][0]
): SearchBoxOption {
  return {
    leftComponent: <PropertyIcon color={Colors.SMOKE} />,
    label: `${formatAddress(agencyProperty.address, {
      format: "street, suburb"
    })}`,
    size: "small",
    value: agencyProperty.id
  };
}
