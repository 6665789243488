import React, { ReactElement } from "react";
import { TenancyDepositStatus } from "local/graphql";
import { uuid } from "@ailo/services";
import {
  DepositData,
  DepositSummaryCard,
  useGetAllTenanciesDeposits
} from "local/domain/propertyManagement";
import { StyleProp, ViewStyle } from "react-native";

interface Props {
  style?: StyleProp<ViewStyle>;
  managementId: string;
}

function filterForDisplay(formerTenancyDeposits: DepositData[]): DepositData[] {
  return formerTenancyDeposits.filter((deposit) => {
    return [
      TenancyDepositStatus.Pending.toString(),
      TenancyDepositStatus.Paid.toString()
    ].includes(deposit.status);
  });
}

function displayDeposit(deposit: DepositData): boolean {
  return ![
    TenancyDepositStatus.Released.toString(),
    TenancyDepositStatus.Void.toString()
  ].includes(deposit.status);
}

export const DepositSummaryList = ({
  style,
  managementId
}: Props): ReactElement => {
  const { data, loading, error } = useGetAllTenanciesDeposits(managementId);

  if (loading || !data) {
    return <></>;
  }

  if (error) {
    // pass to error boundary
    throw new Error(error?.message || "Failed to load deposit summary list.");
  }

  const {
    ingoingTenancyDeposit,
    currentTenancyDeposit,
    formerTenancyDeposits
  } = data;

  return (
    <>
      {ingoingTenancyDeposit && displayDeposit(ingoingTenancyDeposit) && (
        <DepositSummaryCard
          style={style}
          managementId={managementId}
          deposit={ingoingTenancyDeposit}
          showManageMenu
        />
      )}
      {currentTenancyDeposit && displayDeposit(currentTenancyDeposit) && (
        <DepositSummaryCard
          style={style}
          managementId={managementId}
          deposit={currentTenancyDeposit}
          showManageMenu
        />
      )}
      {filterForDisplay(formerTenancyDeposits).map((formerTenancyDeposit) => (
        <DepositSummaryCard
          style={style}
          managementId={managementId}
          deposit={formerTenancyDeposit}
          isFormerTenancy={true}
          key={uuid().toString()}
          showManageMenu
        />
      ))}
    </>
  );
};
