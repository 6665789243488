import { useChatContext } from "@ailo/domains";
import { useEffect } from "react";
import { useNavigation } from "local/common";

export function useSetChatTitle(): void {
  const navigation = useNavigation();

  const {
    selectedChat: { chat }
  } = useChatContext();

  useEffect(() => {
    if (chat) {
      navigation.setOptions({
        title: `Chat - ${chat.titleString}`
      });
    }
  }, [chat, navigation]);
}
