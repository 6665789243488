import { timezone as deviceTimezone } from "expo-localization";
import { useCurrentAgencyOrg } from "./useCurrentAgencyOrg";

export function useTimezone(): string {
  const { timezone } = useCurrentAgencyOrg().defaultWalletLegalEntity ?? {
    timezone: deviceTimezone
  };

  return timezone;
}
