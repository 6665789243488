import React from "react";
import styled from "styled-components/native";
import { StyleProp, View, ViewStyle } from "react-native";
import { Colors, Text } from "@ailo/primitives";

interface Props {
  title: string | React.ReactNode;
  style?: StyleProp<ViewStyle>;
  rightColumn?: React.ReactNode;
}

export function ActionActivitySectionHeader({
  title,
  style,
  rightColumn
}: Props): React.ReactElement {
  return (
    <SectionHeader style={style}>
      {typeof title === "string" ? (
        <Text.BodyL color={Colors.TEXT.DARK.PRIMARY} weight={"medium"}>
          {title}
        </Text.BodyL>
      ) : (
        title
      )}
      {rightColumn}
    </SectionHeader>
  );
}

const SectionHeader = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;
