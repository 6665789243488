import React, { ReactElement, useEffect } from "react";
import { ErrorAlertScreen } from "@ailo/ui";
import { Text } from "@ailo/primitives";
import { ReiFormErrorCode, SigningStatus } from "local/graphql";
import { useGetFormStatuses } from "../../RequestSignatureActionActivity/useGetFormStatuses";
import { SigningRequestView } from "../SigningRequestView";
import { usePollingCallback } from "../../RequestSignatureActionActivity/usePollingCallback";
import { RefinedActionActivityProps } from "../../ActionActivityProps";
import { ReminderActionActivity } from "../../ReminderActionActivity";
import { NoSigningRequestView } from "./NoSigningRequestView";
import {
  getReiFormError,
  LinkReiAccountSuggestion
} from "local/domain/project";

export function DisplayDocusignActionDetails({
  action,
  formId,
  projectId
}: RefinedActionActivityProps & {
  formId: string;
}): ReactElement {
  const { data, loading, error, stopPolling, startPolling } =
    useGetFormStatuses(formId);
  const {
    reiForm: { remoteSigningStatus, reiFormInfo }
  } = data;

  const polling = usePollingCallback({
    action,
    projectId,
    startPolling,
    stopPolling,
    remoteSigningStatus: remoteSigningStatus?.status,
    statusToCheck: SigningStatus.Completed
  });

  const hasBeenSentForSigning = !!remoteSigningStatus?.recipients.length;

  useEffect(() => {
    if (hasBeenSentForSigning) {
      polling();
    }
    return () => stopPolling();
  }, [hasBeenSentForSigning, polling, stopPolling]);

  const reiFormNotFoundError = getReiFormError(
    ReiFormErrorCode.ReiFormNotFound,
    error
  );

  if (loading) {
    return <Text.BodyS.Loading width={200} />;
  }

  if (reiFormNotFoundError) {
    stopPolling();
    return <NoSigningRequestView formDeleted />;
  }

  if (error || !data) {
    stopPolling();
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the information."}
      />
    );
  }

  if (remoteSigningStatus?.status === SigningStatus.NotAvailable) {
    return (
      <ReminderActionActivity action={action} projectId={projectId}>
        <LinkReiAccountSuggestion style={{ marginTop: 32 }} />
      </ReminderActionActivity>
    );
  }

  const finalised = reiFormInfo && reiFormInfo.finalised;

  if (!remoteSigningStatus?.recipients.length || !finalised) {
    return <NoSigningRequestView />;
  }

  return (
    <SigningRequestView
      tenants={remoteSigningStatus.recipients}
      sentDate={remoteSigningStatus?.sent}
      formId={formId}
    />
  );
}
