import { Colors } from "@ailo/primitives";
import { SidebarContent, TabNavigation } from "@ailo/ui";
import {
  EditExpenseModalFormController,
  EditExpenseModalFormData,
  FeeSuccessModal,
  FeeSuccessModalData,
  useGetRecurringFeeOptions
} from "local/domain/expense";

import { AutomaticFeesList } from "local/domain/fee";
import React, { ReactElement, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import {
  AddManagementFeeBlueprintModalFormController,
  EditManagementFeeBlueprintModalFormController,
  useManagementFeeBlueprintModals
} from "../ManagementFeeBlueprintModals";
import { ManualManagementFeeBlueprintsList } from "../ManualManagementFeeBlueprintsList";
import {
  FeeBlueprintType,
  GetManagementFeeBlueprintsQuery,
  PlatformFeatureId
} from "local/graphql";
import { ManagementFeeBlueprintFooter } from "./ManagementFeeBlueprintFooter";
import { ManagementFeeBlueprintsSidebarSubtitle } from "./ManagementFeeBlueprintsSidebarSubtitle";
import { AutomaticFeeBlueprintFooter } from "./AutomaticFeeBlueprintFooter";
import { useHasFeature } from "@ailo/domains";
import { AddAutomaticFeeModalFormController } from "local/domain/expense/AddAutomaticFeeModalFormController";

interface ManagementFeeBlueprintsSidebarContentProps {
  managementId: string;
  showChargeAFeeButton?: boolean;
  onClose(): void;
  style?: StyleProp<ViewStyle>;
}

export function ManagementFeeBlueprintsSidebarContent({
  managementId,
  showChargeAFeeButton = true,
  onClose,
  style
}: ManagementFeeBlueprintsSidebarContentProps): ReactElement {
  const canManageEventBasedFees = useHasFeature(
    PlatformFeatureId.EventBasedFee
  );

  const tabs = [
    { key: "managementFeeBlueprint" as const, label: "Manual" },
    {
      key: "automatedFee" as const,
      label: canManageEventBasedFees ? "Automated" : "Recurring"
    }
  ];

  const [selectedTab, setSelectedTab] = useState<typeof tabs[number]>(tabs[0]);
  const [
    showManagementFeeBlueprintFooter,
    setShowManagementFeeBlueprintFooter
  ] = useState(false);

  const {
    modalState: {
      showAddBlueprintModal,
      setShowAddBlueprintModal,
      showEditBlueprintModal,
      setShowEditBlueprintModal,
      blueprintToEdit,
      setBlueprintToEdit
    },
    agencyOneOffBlueprints,
    agencyEventBasedBlueprints
  } = useManagementFeeBlueprintModals({
    managementId
  });

  // Recurring fee add/edit modals
  const [recurringFeeToEdit, setRecurringFeeToEdit] = useState<
    EditExpenseModalFormData | undefined
  >();
  const [showAddAutomaticFeeModal, setShowAddAutomaticFeeModal] =
    useState(false);
  const recurringFeeOptions = useGetRecurringFeeOptions({
    managementId
  });

  // Success modal
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalData, setSuccessModalData] =
    useState<FeeSuccessModalData>();

  const { managementAgreementAilorn, managingEntityAilorn } =
    recurringFeeOptions;

  const onTabChange = (tab: typeof tabs[number]): void => setSelectedTab(tab);

  const tabContent =
    selectedTab.key === "managementFeeBlueprint" ? (
      <ManualManagementFeeBlueprintsList
        managementId={managementId}
        setShowFooter={setShowManagementFeeBlueprintFooter}
        onAddBlueprintPress={() => setShowAddBlueprintModal(true)}
        onEdit={(
          managementFeeBlueprint: NonNullable<
            GetManagementFeeBlueprintsQuery["managementFeeBlueprints"]
          >["items"][number]
        ) => {
          setShowEditBlueprintModal(true);
          setBlueprintToEdit(managementFeeBlueprint);
        }}
        agencyBlueprintsQuery={agencyOneOffBlueprints}
      />
    ) : selectedTab.key === "automatedFee" ? (
      <AutomaticFeesList
        variant={"sidebar"}
        managementId={managementId}
        itemsLimit={1000}
        onEditRecurringFee={(fee) => setRecurringFeeToEdit(fee)}
        onEditEventBasedFeeBlueprint={(
          managementFeeBlueprint: NonNullable<
            GetManagementFeeBlueprintsQuery["managementFeeBlueprints"]
          >["items"][number]
        ) => {
          setShowEditBlueprintModal(true);
          setBlueprintToEdit(managementFeeBlueprint);
        }}
        style={{ paddingLeft: 16 }}
      />
    ) : null;

  return (
    <SidebarContent
      title={"Fee schedule"}
      subtitle={
        <ManagementFeeBlueprintsSidebarSubtitle managementId={managementId} />
      }
      onClose={onClose}
      style={[{ paddingHorizontal: 0, backgroundColor: Colors.WHITE }, style]}
      titleStyle={{ marginHorizontal: 32, marginBottom: 32 }}
      footer={
        selectedTab.key === "managementFeeBlueprint" ? (
          showManagementFeeBlueprintFooter ? (
            <ManagementFeeBlueprintFooter
              managementId={managementId}
              onAddBlueprintPress={() => setShowAddBlueprintModal(true)}
              agencyBlueprintsQuery={agencyOneOffBlueprints}
              showChargeAFeeButton={showChargeAFeeButton}
            />
          ) : null
        ) : (
          <AutomaticFeeBlueprintFooter
            onAddAutomaticFeePress={() => setShowAddAutomaticFeeModal(true)}
            automaticFeeBlueprintsQuery={recurringFeeOptions}
          />
        )
      }
      footerStyle={{ paddingHorizontal: 20 }}
    >
      <TabNavigation
        options={tabs}
        selectedOptionKey={selectedTab.key}
        onChange={onTabChange}
        style={{ marginLeft: 32 }}
      />
      {tabContent}

      <AddManagementFeeBlueprintModalFormController
        managementId={managementId}
        agencyFeeBlueprints={agencyOneOffBlueprints.options}
        showAddBlueprintModal={showAddBlueprintModal}
        onSuccess={(blueprint) => {
          setShowAddBlueprintModal(false);
          setShowSuccessModal(true);
          setSuccessModalData(blueprint);
          agencyOneOffBlueprints.refetch();
        }}
        onCancel={() => setShowAddBlueprintModal(false)}
      />
      <EditManagementFeeBlueprintModalFormController
        managementId={managementId}
        managementFeeBlueprint={blueprintToEdit}
        showEditBlueprintModal={showEditBlueprintModal}
        onSuccess={() => setShowEditBlueprintModal(false)}
        onCancel={() => setShowEditBlueprintModal(false)}
      />

      <EditExpenseModalFormController
        expense={recurringFeeToEdit}
        onDismiss={() => setRecurringFeeToEdit(undefined)}
      />
      {managementAgreementAilorn && managingEntityAilorn && (
        <AddAutomaticFeeModalFormController
          managementId={managementId}
          managementAgreementAilorn={managementAgreementAilorn}
          managingEntityAilorn={managingEntityAilorn}
          recurringFeeOptions={[
            ...recurringFeeOptions.options,
            ...(canManageEventBasedFees
              ? agencyEventBasedBlueprints.options
              : [])
          ]}
          visible={showAddAutomaticFeeModal}
          onRecurringFeeSuccess={(fee) => {
            setShowAddAutomaticFeeModal(false);
            setSuccessModalData({
              fixedAmount: fee.schedules[0].taxInclusiveAmount,
              taxTreatment: fee.taxTreatment,
              feeBlueprint: {
                name: fee.blueprint.name,
                type: FeeBlueprintType.RecurringFee
              },
              recurring: {
                frequency: fee.frequency,
                startDate: fee.startDate
              }
            });
            setShowSuccessModal(true);
          }}
          onEventBasedFeeSuccess={(fee) => {
            setShowAddAutomaticFeeModal(false);
            setSuccessModalData({
              fixedAmount: fee.fixedAmount,
              oneWeekRentPercentage: fee.oneWeekRentPercentage,
              taxTreatment: fee.taxTreatment,
              feeBlueprint: {
                name: fee.feeBlueprint.name,
                type: FeeBlueprintType.EventBasedFee
              }
            });
            setShowSuccessModal(true);
          }}
          onDismiss={() => setShowAddAutomaticFeeModal(false)}
        />
      )}

      <FeeSuccessModal
        fee={successModalData}
        onDismiss={() => setShowSuccessModal(false)}
        onAddAnother={
          successModalData?.feeBlueprint.type === FeeBlueprintType.OneOffFee
            ? () => {
                setShowAddBlueprintModal(true);
                setShowSuccessModal(false);
              }
            : undefined
        }
        visible={showSuccessModal}
      />
    </SidebarContent>
  );
}
