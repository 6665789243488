import { FormField, Money, RadioButtonGroup } from "@ailo/ui";
import { CreateOneOffFeeFormData } from "local/domain/expense";
import { FeeBlueprintChargeType } from "local/graphql";
import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ViewStyle } from "react-native";

interface Props {
  weeklyRent?: Money;
  style?: ViewStyle;
}

const options = [
  {
    value: FeeBlueprintChargeType.FixedAmount,
    label: "Flat fee"
  },
  {
    value: FeeBlueprintChargeType.OneWeekRentPercentage,
    label: "Based on rent"
  }
];

export function ChargeTypeInput({ weeklyRent, style }: Props): ReactElement {
  const { control, watch, errors, setValue } =
    useFormContext<CreateOneOffFeeFormData>();
  const feeBlueprint = watch("feeBlueprint");

  const defaultChargeType = weeklyRent
    ? options.find((option) => option.value === feeBlueprint.chargeType)
        ?.value ?? options[0].value
    : options[0].value;

  useEffect(() => {
    // `defaultValue` passed to the `Controller` don't get seen by `watch` so use `setValue` instead
    // https://github.com/react-hook-form/react-hook-form/issues/1943#issue-643902427
    setValue("chargeType", defaultChargeType);
  }, [defaultChargeType, setValue]);

  return (
    <Controller
      control={control}
      name={"chargeType"}
      defaultValue={null}
      render={({ value, onChange, onBlur }) => (
        <FormField
          label={"How is this fee charged?"}
          helperText={
            !weeklyRent ? "Rent amount has not been defined" : undefined
          }
          error={errors.chargeType?.message}
          style={style}
        >
          <RadioButtonGroup
            {...{ value, onChange, onBlur }}
            variant={"button-solid-group"}
            options={options}
            disabled={!weeklyRent}
          />
        </FormField>
      )}
    />
  );
}
