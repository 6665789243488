import React, { useCallback } from "react";
import { BaseSplitScreenModalForm } from "../BaseSplitScreenModalForm";
import { AddOrEditManagementForm } from "./AddOrEditManagementForm";
import { ManagementFragment } from "local/graphql";
import { AddOrEditManagementFormContext } from "./AddOrEditManagementFormContext";

interface Props {
  managementId?: string;
  onDismiss: () => void;
  onSuccess: (management: ManagementFragment) => void;
}

export function AddOrEditManagementModalForm({
  managementId,
  onDismiss,
  onSuccess
}: Props): React.ReactElement {
  const renderFormComponent = useCallback(
    (): React.ReactElement => (
      <AddOrEditManagementForm
        managementId={managementId}
        onDismiss={onDismiss}
        onSuccess={onSuccess}
      />
    ),
    [managementId, onDismiss, onSuccess]
  );

  return (
    <AddOrEditManagementFormContext>
      <BaseSplitScreenModalForm
        title={
          managementId ? "Edit ingoing management" : "Set up the new management"
        }
        renderFormComponent={renderFormComponent}
        onDismiss={onDismiss}
      />
    </AddOrEditManagementFormContext>
  );
}
