import { AiloRN, services } from "@ailo/ailorn";
import { formatNames, formatLegalEntityName } from "@ailo/domain-helpers";
import { Badge } from "@ailo/ui";
import { GetManagementOwnersQuery } from "local/graphql";
import React from "react";
import { isPresent } from "ts-is-present";
import { PayerSelectOption } from "../AddBillFormData";

export function createManagementOwnersSelectOption(
  queryData?: GetManagementOwnersQuery
): PayerSelectOption | undefined {
  const managementId = queryData?.management?.id;
  if (!managementId) return undefined;
  const owners = (queryData?.management?.ownerships?.items || [])
    .map((ownership) => ownership?.owner)
    .filter(isPresent);
  if (owners.length === 0) return undefined;

  return {
    type: "management",
    label: formatNames(owners.map((owner) => formatLegalEntityName(owner))),
    value: AiloRN.of(
      services.PropertyManagement.management,
      managementId
    ).toString(),
    investorsAilorns: owners.map((o) => o.ailoRN),
    RightComponent: <Badge type={"investor"} />
  };
}
