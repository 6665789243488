import { AiloRN, services } from "@ailo/ailorn";
import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import {
  GetManagementRecurringFeesDocument,
  useUpdateManagementFeeMutation
} from "local/graphql";
import { useCallback } from "react";

export interface UpdateManagementFeeInput {
  managementId: string;
  managementAgreementAiloRN: string;
  percentage: number;
}

export function useUpdateManagementFee(): [
  updateManagementFee: (input: UpdateManagementFeeInput) => Promise<void>,
  loading: boolean
] {
  const analytics = useAnalytics();
  const toast = useToastContext();

  const [updateManagementFeeMutation, { loading }] =
    useUpdateManagementFeeMutation();

  const updateManagementFee = useCallback(
    async ({
      managementId,
      managementAgreementAiloRN,
      percentage
    }: UpdateManagementFeeInput) => {
      if (loading) return;
      try {
        const result = await updateManagementFeeMutation({
          variables: {
            input: {
              managementAgreementAiloRN,
              percent: percentage
            }
          },
          refetchQueries: [
            {
              query: GetManagementRecurringFeesDocument,
              variables: {
                managementId,
                managementAiloRN: AiloRN.of(
                  services.PropertyManagement.management,
                  managementId
                ).toString()
              }
            }
          ],
          awaitRefetchQueries: true
        });
        throwIfMutationFailed(result, { dataKey: "updateManagementFee" });

        toast.show({
          type: "success",
          message: "Fee has been updated"
        });

        analytics.track("Fee Edited on Property", {
          feeFrequency: "On each rent payment",
          percent: percentage
        });
      } catch (error) {
        AiloSentry.captureException(error);
        toast.showFormSubmitError();
      }
    },
    [updateManagementFeeMutation, toast, loading, analytics]
  );

  return [updateManagementFee, loading];
}
