import React from "react";
import { BasePropertyListRow } from "../BasePropertyListRow";
import { useGetAddressAndFirstPaymentDateQuery } from "local/graphql";
import { AddressCellUI } from "./AddressCellUI";

export function AddressCellWithNoPaymentWarning({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetAddressAndFirstPaymentDateQuery({
    variables: { managementId }
  });

  if (loading && !data) {
    return <AddressCellUI.Loading />;
  }

  const address = data?.management?.property?.address;
  const liability = data?.management?.mostRecentTenancy?.liability;

  if (!address || !liability)
    return (
      <AddressCellUI.Error
        onReload={(): void => {
          refetch();
        }}
      />
    );

  return (
    <AddressCellUI
      address={address}
      showNoPaymentWarning={!liability.firstPaymentDate}
    />
  );
}

AddressCellWithNoPaymentWarning.Loading = AddressCellUI.Loading;
