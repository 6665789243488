import { Alert } from "@ailo/ui";
import React, { ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";

export function DepositFundsWarningMessage({
  showDepositFundsWarning,
  style
}: {
  showDepositFundsWarning?: boolean;
  style?: StyleProp<ViewStyle>;
}): ReactElement | null {
  if (!showDepositFundsWarning) {
    return null;
  }
  return (
    <Alert
      style={style}
      type={"warning"}
      message={
        "Please contact Ailo support to assist you transferring or refunding the Initial Payment related to this tenancy."
      }
    />
  );
}
