import React, { ReactElement } from "react";
import { View } from "react-native";
import { Circle, Stick, Money } from "@ailo/ui";
import { Colors } from "@ailo/primitives";
import styled from "styled-components/native";

export function ClaimTimeline({
  amountClaimed,
  steps = 3
}: {
  amountClaimed: Money;
  steps?: number;
}): ReactElement {
  const nothingClaimed = amountClaimed.isZero;
  return (
    <TimelineContainer>
      <View style={{ width: 8, alignItems: "center" }}>
        <Stick
          colors={["rgba(45, 113, 214, 0)", Colors.OCEAN]}
          style={{ height: 42 }}
        />
        <Circle color={Colors.OCEAN} />
        {steps > 1 && (
          <>
            <Stick
              color={Colors.OCEAN}
              style={{ height: nothingClaimed ? 108 : 102 }}
            />
            <Circle large={!nothingClaimed} color={Colors.OCEAN} />
            <Stick
              color={nothingClaimed ? Colors.OCEAN : "rgba(28, 30, 38, 0.2)"}
              style={{ height: 58 }}
            />
            <Circle
              large={nothingClaimed}
              color={nothingClaimed ? Colors.OCEAN : "rgba(28, 30, 38, 0.2)"}
            />
            {steps === 4 && (
              <>
                <Stick
                  color={
                    nothingClaimed ? Colors.OCEAN : "rgba(28, 30, 38, 0.2)"
                  }
                  style={{ height: 72 }}
                />
                <Circle
                  large={nothingClaimed}
                  color={
                    nothingClaimed ? Colors.OCEAN : "rgba(28, 30, 38, 0.2)"
                  }
                />
              </>
            )}
          </>
        )}
      </View>
    </TimelineContainer>
  );
}

const TimelineContainer = styled(View)`
  padding-left: 19px;
  padding-right: 37px;
  align-items: center;
  background-color: ${Colors.TRANSPARENT};
`;
