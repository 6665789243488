import { Card, AlertScreen, ErrorAlertScreen, GridRow } from "@ailo/ui";
import React from "react";
import { Transaction } from "./useTransactionsQuery";
import { TransactionListTableRow } from "./TransactionListTableRow";
import styled from "styled-components/native";

const StyledCard = styled(Card)`
  margin: 0;
`;

export function TransactionListTable({
  transactions
}: {
  transactions: Transaction[];
}): React.ReactElement {
  if (transactions.length === 0) {
    return (
      <StyledCard>
        <AlertScreen
          style={{ minHeight: 300 }}
          type={"wallet"}
          title={"No Transactions"}
          description={"No transactions have occured yet in your Ailo Wallet."}
        />
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <TransactionListTableRow.Header />
      {transactions.map((transaction) => (
        <TransactionListTableRow
          key={transaction.id}
          transaction={transaction}
        />
      ))}
    </StyledCard>
  );
}

TransactionListTable.Loading =
  function TransactionListTableLoading(): React.ReactElement {
    return (
      <StyledCard>
        <TransactionListTableRow.Header />
        <TransactionListTableRow.Loading />
        <TransactionListTableRow.Loading style={{ opacity: 0.6 }} />
        <TransactionListTableRow.Loading style={{ opacity: 0.3 }} />
        <StyledGridRowEmpty />
      </StyledCard>
    );
  };

TransactionListTable.Error = function TransactionListTableError({
  onRetry
}: {
  onRetry?(): void;
}): React.ReactElement {
  return (
    <StyledCard>
      <ErrorAlertScreen
        style={{ minHeight: 300 }}
        title={"There was a problem loading Transactions"}
        onRetry={onRetry}
      />
    </StyledCard>
  );
};

const StyledGridRowEmpty = styled(GridRow)`
  height: 1584px;
`;
