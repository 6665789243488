import React from "react";
import { View, ViewStyle } from "react-native";
import {
  SwitcherHeader,
  Screens,
  NavigatorsFlatParamList,
  SwitcherHeaderOption
} from "local/common";
import { StackNavigationProp } from "@react-navigation/stack";

interface Props {
  style?: ViewStyle;
  selected: number;
  options: (SwitcherHeaderOption | string)[];
  navigation: StackNavigationProp<NavigatorsFlatParamList, Screens>;
  onNavigate?: (tab: string) => void;
}

export function TabNavigation({
  style,
  selected,
  options,
  navigation,
  onNavigate
}: Props): React.ReactElement {
  return (
    <View style={style}>
      <SwitcherHeader
        style={{ padding: 0 }}
        options={options}
        selected={selected}
        onPress={(i): void => {
          const option = options[i];
          const newTab = typeof option === "string" ? option : option.label;
          navigation.setParams({ tab: newTab });
          onNavigate?.(newTab);
        }}
      />
    </View>
  );
}
