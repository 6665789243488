import { LocalDate } from "@ailo/date";

export function validateIsAfterDate(
  errorMessage: string,
  date?: LocalDate | string | null
): (value: string) => string | undefined {
  return (value: string) => {
    if (!value || !date) return;

    const valueIsBeforeDate = LocalDate.fromString(value).isBefore(
      LocalDate.from(date)
    );

    return valueIsBeforeDate ? errorMessage : undefined;
  };
}
