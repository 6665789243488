import { useMemo } from "react";
import { isPresent } from "ts-is-present";
import {
  FilterStateType,
  usePropertyAddressFilter,
  useTeamsFilter,
  useUnscheduledTenanciesFilterState
} from "./filters";

type ProjectFiltersReturn = {
  filterElements: React.ReactElement[];
  isAnyFilterActive: boolean;
  filterState: FilterStateType;
};

export function useUnscheduledTenancyListFilters(): ProjectFiltersReturn {
  const { filterState, updateFilterState } =
    useUnscheduledTenanciesFilterState();

  const propertyAddressFilter = usePropertyAddressFilter({
    searchQuery: filterState.propertyAddress,
    updateFilterState
  });

  const teamsFilter = useTeamsFilter();

  const filters = useMemo(
    () => [propertyAddressFilter, teamsFilter],
    [propertyAddressFilter, teamsFilter]
  );

  const filterElements = useMemo(
    () => filters.map((filter) => filter.component).filter(isPresent),
    [filters]
  );

  return {
    filterElements,
    isAnyFilterActive: filters.some((filter) => filter.isFilterActive),
    filterState
  };
}
