import React, { ReactElement } from "react";
import styled from "styled-components/native";
import {
  FormsLiveIcon,
  RealworksIcon,
  ReiFormsLiveIcon,
  Skeleton
} from "@ailo/primitives";
import { Card } from "@ailo/ui";
import { FormType } from "../FormConnectionsTab/types";

type IconSize = "medium" | "large";

const sizes = {
  medium: {
    card: 40,
    icon: 24
  },
  large: {
    card: 80,
    icon: 48
  }
};

const iconsStorage = {
  [FormType.FORMS_LIVE]({ size }: { size: IconSize }) {
    return <FormsLiveIcon width={sizes[size].icon} height={sizes[size].icon} />;
  },
  [FormType.REALWORKS]({ size }: { size: IconSize }) {
    return <RealworksIcon width={sizes[size].icon} height={sizes[size].icon} />;
  },
  [FormType.REI_FORMS_LIVE]({ size }: { size: IconSize }) {
    return (
      <ReiFormsLiveIcon width={sizes[size].icon} height={sizes[size].icon} />
    );
  }
};

export function ProviderIconCard({
  provider,
  size
}: {
  provider: FormType;
  size: IconSize;
}): ReactElement {
  const Icon = iconsStorage[provider];
  return (
    <IconCard size={size}>
      <Icon size={size} />
    </IconCard>
  );
}

ProviderIconCard.Loading = function ProviderIconCardLoading({
  size
}: {
  size: IconSize;
}) {
  return (
    <Skeleton style={{ width: sizes[size].card, height: sizes[size].card }} />
  );
};

const IconCard = styled(Card)<{ size: IconSize }>`
  margin: 0;
  width: ${({ size }) => `${sizes[size].card}px`};
  height: ${({ size }) => `${sizes[size].card}px`};
  align-items: center;
  justify-content: center;
`;
