import { Screens, useNavigation } from "local/common";
import { AiloRN } from "@ailo/ailorn";
import { OpenThread } from "@ailo/domains";
import { useCallbackRef } from "@ailo/primitives";

/**
 * To be used only when inside Screens.ChatThread
 *
 * For more absolute use cases:
 * @see useNavigateToChatThreadScreen
 * @see generateLinkToCommsTab
 */
export function useOpenThread(): OpenThread {
  const navigation = useNavigation<Screens.ChatThread>();

  return useCallbackRef(
    (threadAilorn: AiloRN<"chat:thread">) => {
      navigation.setParams({ threadId: threadAilorn.internalId });
    },
    [navigation]
  );
}
