import React, { ReactElement, useCallback } from "react";
import { Screens, useRoute } from "local/common";
import { ClaimBondForm } from "./components";

import { BaseClaimTenancyBondScreen } from "./BaseClaimTenancyBondScreen";
import { ClaimTenancyData } from "local/domain/bond";
import { PropertyContextProvider } from "local/tabs";

function ClaimTenancyBondScreen(): ReactElement {
  const { tenancyId, managementId } =
    useRoute<Screens.ClaimTenancyBond>().params!;

  const formConstructor = useCallback(
    (data: ClaimTenancyData, onClose: () => void) => {
      return <ClaimBondForm data={data} onClose={onClose} />;
    },
    []
  );
  return (
    <PropertyContextProvider managementId={managementId}>
      <BaseClaimTenancyBondScreen
        tenancyId={tenancyId}
        managementId={managementId}
        formConstructor={formConstructor}
        title={"Claim bond"}
      />
    </PropertyContextProvider>
  );
}

export { ClaimTenancyBondScreen };
