import { AiloRNString } from "@ailo/ailorn";
import {
  AnalyticsService as BaseAnalyticsService,
  useAnalytics as useBaseAnalytics,
  AnalyticsContext as BaseAnalyticsContext
} from "@ailo/services";

export interface AnalyticsContext extends BaseAnalyticsContext {
  organisation_id: string[];
}

export const useAnalytics = useBaseAnalytics as () => AnalyticsService;

export class AnalyticsService extends BaseAnalyticsService<AnalyticsContext> {
  public trackBillCreated(payload: {
    billId: string;
    payableBy: string;
    managementId: string;
    crnValid?: boolean;
    crnInvalidWarningShown?: boolean;
  }): void {
    this.track("Bill Created", payload);
  }

  public trackFundsWithdrawn(): void {
    this.track("Funds Withdrawn");
  }

  public trackFundsWithdrawalFailed(payload: { errorCode: string }): void {
    this.track("Funds Withdrawal Failed", payload);
  }

  public trackPropertyListReordered(
    eventName: string,
    payload: { orderBy: string }
  ): void {
    this.track(eventName, payload);
  }

  public trackPropertyOnboardingListReordered(
    eventName: string,
    payload: { orderBy: string }
  ): void {
    this.track(eventName, payload);
  }

  public trackPropertyMoveToTeam(payload: {
    managementId: string;
    teamId: string;
  }): void {
    this.track("Property Moved to Team", payload);
  }

  public trackContactsSearchFocus(contactType: string): void {
    this.track(`${contactType} Search Opened`);
  }

  public trackContactsSearchNoResults(payload: {
    contactType: string;
    searchString: string;
  }): void {
    this.track(`${payload.contactType} Search No Match Found`, {
      ...payload,
      searchStringLength: payload.searchString.length
    });
  }

  public trackGlobalSearchFocus(payload: { searchVersion: string }): void {
    this.track("Global Search Opened", payload);
  }

  public trackGlobalSearchItemSelected(payload: {
    searchString: string;
    searchResultClickedString: string;
    searchResultCategory: string;
    searchVersion: string;
  }): void {
    this.track("Global Search Result Clicked", {
      ...payload,
      searchStringLength: payload.searchString.length
    });
  }

  public trackGlobalSearchNoResults(payload: {
    searchString: string;
    searchVersion: string;
  }): void {
    this.track("Global Search No Match Found", {
      ...payload,
      searchStringLength: payload.searchString.length
    });
  }

  public trackDashboardNavigate(payload: {
    cardTitle: string;
    teamIds?: AiloRNString[];
  }): void {
    this.track("Dashboard Navigate", payload);
  }
}
