/**
 * Redirect a route passed from e.g. https://ailo.new/*
 *
 * These are routed on the backend to https://ailo.pm/new/*. At this point,
 * the remaining URL routes have already been stripped away, so this provides
 * just a simple map of shorthand routes to the "new" endpoints for participanting
 * domains.
 *
 * e.g.
 *    https://ailo.new/bill  ->  https://ailo.pm/new/bill  ->  https://ailo.pm/bills/new
 *                                                              ^
 *                                                              +- This function
 *
 * For more information, see: https://ailo.atlassian.net/wiki/spaces/TS/pages/2878832770/https+ailo.new+redirection
 *
 * @param redirectTo
 * @returns
 */
export function getRedirectURLPath(redirectTo: string): string {
  switch (redirectTo) {
    case "": // Empty state (e.g. https://ailo.new) should create a new bill by default
    case "bill":
    case "bills":
      return "bills/new";
    case "project":
    case "projects":
      return "projects?create=1";
    default:
      // Invalid URLs shouldn't be handled, just go to the home screen instead
      return "";
  }
}
