import { useEnvironment } from "@ailo/services";
import React from "react";
import { ReiFormsCallback } from "./ReiFormsCallback";

export function CallbackHandler({
  children
}: {
  children?: React.ReactNode;
}): React.ReactElement | null {
  const { REI_CALLBACK_PATH } = useEnvironment();
  if (window.location.pathname === REI_CALLBACK_PATH) {
    return <ReiFormsCallback />;
  }
  return <>{children}</>;
}
