import React, { ReactElement, useCallback } from "react";
import { ConfirmModal, ErrorModal, LocalDate, useToastContext } from "@ailo/ui";
import { Text } from "@ailo/primitives";
import {
  CancelVacateAbility,
  EndTenancyDetailsFragment,
  NotAbleToCancelReason
} from "local/graphql";
import { useRemoveTenancyEndDate } from "local/domain/propertyManagement";
import { AiloRN, nsEntities } from "@ailo/ailorn";
import { useAnalytics } from "@ailo/services";

export function CancelVacateModal({
  tenancy,
  visible,
  onConfirm,
  onCancel
}: {
  tenancy: EndTenancyDetailsFragment;
  visible: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
}): ReactElement {
  const { id: tenancyId, endDate, canCancelVacate } = tenancy;

  const toast = useToastContext();
  const analytics = useAnalytics();
  const onTenancyUpdated = useCallback(() => {
    analytics.track("Vacating Tenancy Cancelled", {
      tenancy_id: AiloRN.of(
        nsEntities.propertymanagement.tenancy,
        tenancyId
      ).toString()
    });
  }, [tenancyId, analytics]);

  const [removeTenancyEndDate] = useRemoveTenancyEndDate({
    tenancyId,
    onCompleted: () => {
      onTenancyUpdated();
      toast.show({
        type: "success",
        message: "Tenancy no longer vacating"
      });
    },
    onError: () => {
      toast.show({
        type: "error",
        message: "Error stopping vacating tenancy"
      });
    }
  });
  const onConfirmPress = useCallback(() => {
    removeTenancyEndDate();
    onConfirm?.();
  }, [removeTenancyEndDate, onConfirm]);

  if (canCancelVacate?.ableToCancel === true && endDate) {
    const formattedTenancyEndDate =
      LocalDate.from(endDate).format("D MMMM YYYY");
    return (
      <ConfirmModal
        onCancel={onCancel}
        onConfirm={onConfirmPress}
        title={"Stop vacating tenancy"}
        confirmLabel={"Stop vacating tenancy"}
        destructive
        visible={visible}
      >
        <Text.BodyM>
          {"This action will remove the tenancy end date "}
          <Text weight={"medium"}>{formattedTenancyEndDate}</Text>
          {" and the tenancy will no longer be vacating"}
        </Text.BodyM>
      </ConfirmModal>
    );
  }

  const { title, description } = actionNotAllowedMessage(canCancelVacate);

  return (
    <ErrorModal
      visible={visible}
      onDismiss={onCancel}
      title={title}
      description={description}
    />
  );
}

function actionNotAllowedMessage(
  canCancelVacate?: CancelVacateAbility | null
): {
  title: string;
  description: string;
} {
  if (
    canCancelVacate?.notAbleToCancelReason ===
    NotAbleToCancelReason.AlreadyChargedForPeriodWithEndDate
  ) {
    return {
      title: "You are unable to stop vacating this tenancy",
      description:
        "The tenants have already been charged for this period. Please reach out to Support for assistance."
    };
  }
  if (
    canCancelVacate?.notAbleToCancelReason ===
    NotAbleToCancelReason.ManagementHasIngoingTenancy
  ) {
    return {
      title: "The ingoing tenancy must end first",
      description: "Please contact Support to end the ingoing tenancy."
    };
  }
  return {
    title: "An unexpected error has occurred",
    description:
      "If the error persists, please contact Support for assistance. "
  };
}
