import { AgencyOrganisation } from "local/common";

type Company = NonNullable<AgencyOrganisation["defaultWalletLegalEntity"]>;

/**
 * Returns the first legal entity where the user has highest permission level
 * that lets him access legal entity's wallet.
 *
 * We call it "default", because in the future user will be able to pick the legal entity from a dropdown.
 */
export function getDefaultWalletLegalEntity(
  companies: Company[]
): Company | undefined {
  const sortedCompanies = [...companies].sort((a, b): number => {
    return a.registeredEntityName.localeCompare(b.registeredEntityName);
  });

  const legalEntity =
    findWithPermission(sortedCompanies, FINANCIAL_MANAGE_PERMISSION) ??
    findWithPermission(sortedCompanies, FINANCIAL_VIEW_PERMISSION);

  return legalEntity;
}

export const FINANCIAL_MANAGE_PERMISSION = "legal_entity:financial:manage";
export const FINANCIAL_VIEW_PERMISSION = "legal_entity:financial:view";

function findWithPermission(
  companies: Company[],
  permission: string
): Company | undefined {
  return companies.find(({ currentUserPermissions }) =>
    currentUserPermissions.includes(permission)
  );
}
