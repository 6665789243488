import { PaginationProps } from "@ailo/ui";
import { PropertyOnboardingListPageInfo } from "../types";

export function getPaginationData(
  input: PropertyOnboardingListPageInfo,
  maxRowsPerPage: number
): PaginationProps {
  const { pageNo, total } = input;
  return {
    currentPage: pageNo,
    pagesCount: Math.ceil(total / maxRowsPerPage)
  };
}
