import React, { ReactElement } from "react";
import { useCurrentAgencyOrg } from "local/common";
import { Badge } from "@ailo/ui";
import { useOnFocus } from "@ailo/services";
import {
  ProjectStatus,
  useGetProjectsByOrganisationQuery
} from "local/graphql";

interface Props {
  active?: boolean;
}

export function ProjectListTabNavigationBadge({
  active = true
}: Props): ReactElement | null {
  const organisationAilorn = useCurrentAgencyOrg().ailoRN;
  const { data, loading, error, refetch } = useGetProjectsByOrganisationQuery({
    variables: {
      organisationAilorn,
      pageSize: 0,
      status: ProjectStatus.Open
    }
  });

  useOnFocus(refetch);

  const pageTotal = data?.projectsByOrganisation?.pageInfo.total;

  if (loading && !data) {
    return <Badge.Loading width={23} style={{ marginLeft: 8 }} />;
  }

  if (!data || error) {
    return null;
  }

  return (
    <Badge type={active ? "ailo" : "outline"} style={{ marginLeft: 8 }}>
      {pageTotal}
    </Badge>
  );
}
