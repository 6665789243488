import { notEmpty, useCurrentAgencyOrg } from "local/common";
import React from "react";

import {
  dateForSort,
  floatForSort,
  useReportQuery,
  useReportTeamContext
} from "../..";
import { useGetBondsReportQuery } from "local/graphql";
import { ReportPage } from "../../components";
import { FilterComponentType } from "../../components/ReportPage/FilterFactory";
import { propertyRowLink } from "../../components/ReportPage/utils/tableNavigation";
import { reports } from "../../reportsDetails";

export function BondsTable(): React.ReactElement {
  const { id } = useCurrentAgencyOrg();
  const { teamId } = useReportTeamContext();
  // get data
  const queryResult = useReportQuery(useGetBondsReportQuery, {
    managingOrgId: id,
    teamId
  });
  const reportData = queryResult.data?.bondsReport;
  const rows = reportData?.rows.filter(notEmpty);
  const columns = reportData?.columns;

  return (
    <ReportPage
      title={reports.bonds.reportHeading}
      data={{
        ...queryResult,
        rows,
        columns
      }}
      columnsConfig={{
        propertyAddress: {
          width: 2
        },
        bondStatusDescription: {
          badge: {
            valueColorMapping: [
              {
                cellValue: "Claim required",
                badgeType: "attention"
              },
              {
                cellValue: "Bond resolved",
                badgeType: "success"
              },
              {
                cellValue: "Awaiting Funds",
                badgeType: "default",
                text: "Awaiting funds"
              },
              {
                cellValue: "Claim mismatch",
                badgeType: "critical"
              },
              {
                cellValue: "Allocation pending",
                badgeType: "default"
              },
              {
                cellValue: "Claim completed",
                badgeType: "success"
              }
            ]
          }
        },
        bondAmount: {
          horizontalAlign: "right",
          sortValue: (r) => floatForSort(r.bondAmount)
        },
        dateClaimCreated: {
          sortValue: (r) => dateForSort(r.dateClaimCreated)
        }
      }}
      filterConfig={[
        {
          name: "search",
          columns: ["propertyAddress", "bondReference", "renterNames"],
          type: FilterComponentType.Search,
          defaultValue: "",
          placeholder: "Search properties or bond reference"
        },
        {
          name: "Status",
          namePlural: "Statuses",
          columns: ["bondStatusDescription"],
          defaultValue: [],
          type: FilterComponentType.MultiSelect
        }
      ]}
      defaultSort={{ columnKey: "propertyAddress", direction: "DESC" }}
      rowLink={propertyRowLink}
    />
  );
}
