import { AiloSentry } from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import { ApolloError } from "@apollo/client";
import { useCreatePropertyKeyMutation } from "local/graphql";
import { useCallback } from "react";
import { useKeyModalContext } from "../../PropertyKeyModalContext";
import { UpsertKeyFormData } from "../PropertyKeyFormContent";
import { usePropertyKeyAddedAnalytics } from "./usePropertyKeyAddedAnalytics";

export function useCreatePropertyKey(): {
  onSubmit: (formData: UpsertKeyFormData) => void;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const toast = useToastContext();

  const {
    propertyAilornString,
    managementAilornString,
    organisationAilornString
  } = useKeyModalContext();

  const { trackPropertyKeyAdded } = usePropertyKeyAddedAnalytics(
    propertyAilornString,
    managementAilornString
  );

  const [createPropertyKeyMutation, { loading, error }] =
    useCreatePropertyKeyMutation({
      onCompleted: (data) => {
        trackPropertyKeyAdded(data?.createOneKey);

        toast.show({
          message: "Keys added",
          type: "success"
        });
      },
      onError: (error) => {
        toast.show({
          message: "Unable to add keys. Please try again.",
          type: "error"
        });

        AiloSentry.captureException(error);

        throw new Error(error.message);
      }
    });

  const onSubmit = useCallback(
    async (formData: UpsertKeyFormData) => {
      await createPropertyKeyMutation({
        variables: {
          input: {
            key: {
              ...formData,
              organisationAilorn: organisationAilornString,
              propertyAilorn: propertyAilornString
            }
          },
          organisationAilorn: organisationAilornString
        }
      });
    },
    [createPropertyKeyMutation, organisationAilornString, propertyAilornString]
  );

  return { onSubmit, loading, error };
}
