import React from "react";
import { Colors, Text, isReactText, opacify } from "@ailo/primitives";
import { TableRow } from "@ailo/ui";
import { View } from "react-native";
import styled from "styled-components/native";

export const CellContainer = styled(View)`
  padding: 16px;
`;

export const RowContainer = styled(TableRow)`
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.GRAYSCALE.OUTLINE};
`;

export const BalanceTableRow = styled(RowContainer).attrs({
  cellContainer: CellContainer
})``;

export const BalanceTitleRow = styled(RowContainer).attrs(({ children }) => ({
  children: isReactText(children) ? (
    <Text.DisplayS>{children}</Text.DisplayS>
  ) : (
    children
  )
}))`
  padding: 16px;
`;

export const BalanceCardContainer = styled(View)`
  flex-direction: column;
  background: ${Colors.WHITE};
  box-shadow: 0 1px 4px ${opacify(Colors.SPACE_BLACK, 0.2)};
  border-radius: 4px;
  overflow: hidden;
`;

export const DescriptionText = styled(Text.BodyM)`
  text-align: left;
`;

export const MoneyText = styled(Text.BodyM)`
  text-align: right;
`;
