import React, { ReactElement } from "react";
import { PlusIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";

export function CreateProjectButton({
  onPress
}: {
  onPress?: () => void;
}): ReactElement {
  return (
    <Button.Text
      leftIcon={PlusIcon}
      style={{ flexDirection: "row", borderWidth: 0 }}
      onPress={onPress}
    >
      {"Create"}
    </Button.Text>
  );
}
