import React from "react";
import { useSynchronisedLoad } from "@ailo/primitives";
import { BillGridRow, ColWidths } from "./components";

interface GridRowListProps {
  gridColWidths: ColWidths;
  billIds: string[];
  onBillRowPress?(billId: string): void;
}

interface Statics {
  Loading: typeof Loading;
}

const BillGridRows: React.FC<GridRowListProps> & Statics = ({
  gridColWidths,
  billIds,
  onBillRowPress
}) => {
  if (useSynchronisedLoad(false)) {
    return <Loading gridColWidths={gridColWidths} />;
  }

  return (
    <>
      {billIds.map((billId, index) => (
        <BillGridRow
          gridColWidths={gridColWidths}
          billId={billId}
          index={index}
          key={index}
          isLastRow={index === billIds.length - 1}
          onRowPress={
            onBillRowPress ? (): void => onBillRowPress(billId) : undefined
          }
        />
      ))}
    </>
  );
};

const Loading: React.FC<{
  gridColWidths: ColWidths;
}> = ({ gridColWidths }) => {
  return <BillGridRow.Loading gridColWidths={gridColWidths} />;
};

BillGridRows.Loading = Loading;

export { BillGridRows };
