import React, { memo, ReactElement, useCallback } from "react";
import { TenancyReceiptMainForm } from "./TenancyReceiptMainForm";
import { ReceiptBaseModal } from "../../components";
import { CreateReceiptFormData } from "local/tabs/trustaccounting/transactions";
import { useForm, FormProvider } from "react-hook-form";
import { ModalVisibility } from "@ailo/ui";
import { TenancyReceiptAllocationsInput } from "local/tabs/trustaccounting/transactions/TransactionsScreen/TenancyReceiptModal/TenancyReceiptAllocationsInput";

function TenancyReceiptModalComponent(props: {
  visibility: ModalVisibility<void>;
}): ReactElement {
  const defaultValues = {};
  const form = useForm<CreateReceiptFormData>({
    defaultValues,
    mode: "onBlur"
  });
  const renderAllocations = useCallback(
    (): React.ReactElement => <TenancyReceiptAllocationsInput />,
    []
  );

  return (
    <FormProvider {...form}>
      <ReceiptBaseModal
        {...props}
        title={"New Tenancy Receipt"}
        renderAllocationsComponent={renderAllocations}
        onActionPress={() => {}}
      >
        <TenancyReceiptMainForm />
      </ReceiptBaseModal>
    </FormProvider>
  );
}

export const TenancyReceiptModal = memo(TenancyReceiptModalComponent);
