import {
  AiloSentry,
  MutationResultDataMissingError,
  useAnalytics
} from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { useCallback } from "react";
import {
  CreateManagementMutation,
  useCreateManagementMutation,
  ManagementFragment
} from "local/graphql";
import {
  AddressFormData,
  NewManagementFormData
} from "../NewManagementFormData";
import { SetRequired } from "type-fest";
import { useCurrentAgencyOrg } from "local/common";

export interface OwnerDetails {
  firstName: string;
  middleName?: string;
  lastName: string;
  preferredName?: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: string;
  isExistingUser?: boolean;
  sharesOwned: number;
}

type CompleteAddress = SetRequired<
  AddressFormData,
  | "unitStreetNumber"
  | "streetName"
  | "suburb"
  | "state"
  | "country"
  | "postcode"
>;

type CreateManagementInput = SetRequired<
  NewManagementFormData,
  "agreementStartDate" | "managementFeePercent" | "owners"
> & { address: CompleteAddress };

export function useCreateManagement({
  onCompleted,
  onError
}: {
  onCompleted?: (management: ManagementFragment) => void;
  onError?: (error?: unknown) => void;
}): [
  ({ formData }: { formData: CreateManagementInput }) => Promise<void>,
  MutationResult<CreateManagementMutation>
] {
  const analytics = useAnalytics();
  const [createManagement, result] = useCreateManagementMutation();
  const managingEntityId = useCurrentAgencyOrg().legalEntities[0].id;

  const newManagement = useCallback(
    async ({
      formData: {
        agreementStartDate,
        agreementEndDate,
        managementFeePercent,
        owners,
        address
      }
    }: {
      formData: CreateManagementInput;
    }): Promise<void> => {
      try {
        const result = await createManagement({
          variables: {
            managementDetails: {
              property: address,
              management: {
                startDate: agreementStartDate,
                fixedTermEndDate: agreementEndDate,
                percent: managementFeePercent,
                owners: owners.map((owner) => {
                  const {
                    email,
                    firstName,
                    lastName,
                    phoneNumber,
                    middleName,
                    preferredName,
                    dateOfBirth
                  } = owner.details;
                  return {
                    sharesOwned: owner.details.sharesOwned,
                    consumer: {
                      email,
                      firstName,
                      lastName,
                      phone: phoneNumber,
                      legalMiddleName: middleName,
                      preferredName,
                      birthDate: dateOfBirth
                    }
                  };
                })
              },
              managingEntityId
            }
          }
        });

        const management = result.data?.setupManagementAndTenancy?.management;
        if (!management) {
          throw new MutationResultDataMissingError(
            "setupManagementAndTenancy",
            result
          );
        }

        await onCompleted?.(management);
      } catch (error) {
        AiloSentry.captureException(error);
        return onError?.(error);
      }

      const hasExistingAiloUsers =
        owners?.some((owner) => owner.details.isExistingUser) ?? false;

      analytics.trackNewManagementCreated({ hasExistingAiloUsers });
    },
    [onError, analytics, createManagement, managingEntityId, onCompleted]
  );

  return [newManagement, result];
}
