import { useState } from "react";

export function useGetManagementNoteModalFormStates(): {
  modalStates: {
    visible: boolean;
    setVisible: (value: boolean) => void;
  };
} {
  const [managementNoteFormModalVisible, setManagementNoteFormModalVisible] =
    useState(false);
  return {
    modalStates: {
      visible: managementNoteFormModalVisible,
      setVisible: (value: boolean) => {
        setManagementNoteFormModalVisible(value);
      }
    }
  };
}
