import { Bill, ClaimTenancyData } from "local/domain/bond";
import { Money } from "@ailo/ui";
import { TenancyBondStatus } from "local/graphql";

type ClaimSummaryInput = Pick<ClaimTenancyData, "bond" | "rent" | "bills">;

export type ClaimSummary = {
  amountRentClaimed: Money;
  amountBillsClaimed: Money;
  amountRequested?: Money;
  amountReleaseToRenters: Money;
};

export function calculateClaimSummary({
  bond,
  rent,
  bills
}: ClaimSummaryInput): ClaimSummary {
  const amountRentClaimed = rent.amountClaimed;
  const amountBillsClaimed = getClaimedBillsTotal(bills);
  const amountRequested =
    bond.status !== TenancyBondStatus.BondResolved
      ? amountBillsClaimed.add(amountRentClaimed)
      : undefined;
  const amountReleaseToRenters =
    amountRequested && bond.amount.cents >= amountRequested.cents
      ? amountRequested?.subtract(bond.amount)
      : Money.zero();

  return {
    amountRentClaimed,
    amountBillsClaimed,
    amountRequested,
    amountReleaseToRenters
  };
}

function getClaimedBillsTotal(bills: Bill[]): Money {
  return bills
    .filter((bill) => bill.isSelected)
    .reduce(
      (amount, bill) => amount.add(bill.amount ?? Money.zero()),
      Money.zero()
    );
}
