import React, { ReactElement } from "react";
import {
  BalanceDisplay,
  TotalBalanceDisplay,
  useHasFeature
} from "@ailo/domains";
import { didQueryNotLoadYet, useOnFocus } from "@ailo/services";
import { AiloRN, services } from "@ailo/ailorn";
import { View } from "react-native";
import styled from "styled-components/native";
import { Card, EmptyContent } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import {
  useGetDefaultWalletWithMoneyManagementSettingsForManagementQuery,
  PlatformFeatureId
} from "local/graphql";
import { LocalDate } from "@ailo/date";
import { MoneySettingButton, MoneySettingListItem } from "./components";
import { OpenCurrentManagementPortfolioButton } from "./components/OpenCurrentManagementPortfolioButton";

interface Props {
  managementId: string;
  onMoneyManagementSettingsPress: () => void;
}

export function ManagementWalletCard({
  managementId,
  onMoneyManagementSettingsPress
}: Props): ReactElement | null {
  const hasManagementPortfoliosFeature = useHasFeature(
    PlatformFeatureId.ManagementPortfolios
  );

  const reference = AiloRN.of(
    services.PropertyManagement.management,
    managementId
  ).toString();

  const result =
    useGetDefaultWalletWithMoneyManagementSettingsForManagementQuery({
      variables: {
        walletOwnerReference: reference
      }
    });
  const { data, refetch } = result;
  useOnFocus(refetch);

  const loading = didQueryNotLoadYet(result);
  const availableBalance = data?.walletByWalletOwnerReference?.availableBalance;
  const totalBalance = data?.walletByWalletOwnerReference?.totalBalance;

  if (loading) {
    return <Loading />;
  }

  if (!availableBalance || !totalBalance) {
    return (
      <CardContainer>
        <EmptyContent message={"Sorry, this content could not be loaded"} />
      </CardContainer>
    );
  }

  const autoBillPaymentEnabled = !!data?.autoPayLiabilityStatuses?.find(
    (s) => s?.enabled
  );

  const enabledAutoWithdrawPlan =
    data?.walletByWalletOwnerReference?.autoWithdrawPlans?.items?.find(
      (p) => p?.enabled
    );

  return (
    <View>
      <View
        style={{
          paddingBottom: 20,
          flex: 1,
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "space-between"
        }}
      >
        <Text.BodyL weight={"medium"}>{"Property Wallet"}</Text.BodyL>
        {hasManagementPortfoliosFeature && (
          <OpenCurrentManagementPortfolioButton />
        )}
      </View>

      <CardContainer contentStyle={{ flexDirection: "row" }}>
        <AccountBalanceContainer>
          <BalanceDisplay
            amount={availableBalance}
            label={"Available Balance"}
          />
          <TotalBalanceDisplay
            amount={totalBalance}
            style={{ marginTop: 16 }}
          />
        </AccountBalanceContainer>
        <MoneySettingsContainer>
          <MoneySettingListItem
            title={"Auto Bill Payments"}
            enabled={autoBillPaymentEnabled}
          />
          <MoneySettingListItem
            title={"Auto Transfer"}
            subHeader={[
              enabledAutoWithdrawPlan?.details?.frequency,
              enabledAutoWithdrawPlan?.details?.nextFireTime
                ? "Next " +
                  LocalDate.from(
                    enabledAutoWithdrawPlan?.details?.nextFireTime
                  ).format("D MMM")
                : null
            ]
              .filter(Boolean)
              .join(" · ")}
            enabled={!!enabledAutoWithdrawPlan}
          />
          <MoneySettingButton onPress={onMoneyManagementSettingsPress} />
        </MoneySettingsContainer>
      </CardContainer>
    </View>
  );
}

function Loading(): ReactElement | null {
  return (
    <View>
      <View style={{ paddingBottom: 20 }}>
        <Text.BodyL.Loading />
      </View>
      <CardContainer contentStyle={{ flexDirection: "row" }}>
        <AccountBalanceContainer>
          <BalanceDisplay.Loading />
          <TotalBalanceDisplay.Loading />
        </AccountBalanceContainer>
        <MoneySettingsContainer>
          <MoneySettingListItem.Loading headerWidth={120} />
          <MoneySettingListItem.Loading headerWidth={80} />
          <MoneySettingButton.Loading />
        </MoneySettingsContainer>
      </CardContainer>
    </View>
  );
}

ManagementWalletCard.Loading = Loading;

const CardContainer = styled(Card)`
  margin: 0;
  padding: 0;
  box-shadow: none;
  border: 1px solid ${Colors.GRAYSCALE.OUTLINE};
`;

const AccountBalanceContainer = styled(View)`
  flex: 1;
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  border-right-width: 1px;
  border-right-color: ${Colors.GRAYSCALE.OUTLINE};
  justify-content: space-between;
`;

const MoneySettingsContainer = styled(View)`
  flex: 1;
`;
