import React, { ReactElement, useMemo } from "react";
import { HealthIssuesHeader } from "./HealthIssuesHeader";

interface Props {
  issues?: Record<string, any>;
}

export function HealthIssuesCountHeader({ issues = {} }: Props): ReactElement {
  const issuesCount = Object.keys(issues).length;
  const issuesHeaderTitle = useMemo(() => {
    if (issuesCount === 0) return "No issues found";
    if (issuesCount === 1) return "1 issue found";
    return `${issuesCount} issues found`;
  }, [issuesCount]);

  return <HealthIssuesHeader title={issuesHeaderTitle} />;
}
