import { orderBy } from "lodash";
import { useMemo } from "react";
import { isPresent } from "ts-is-present";
import { ChatTeam, isSpecialTeam, SpecialTeamId } from "@ailo/domains";

interface TeamGroup {
  label: string;
  teams: ChatTeam[];
}

export function useTeamGroups(teams: ChatTeam[]): TeamGroup[] {
  return useMemo(() => {
    return [
      getMyTeamGroup(teams),
      getUnassignedGroup(teams),
      getOtherTeamGroup(teams)
    ]
      .filter(isPresent)
      .filter((group) => group && group.teams.length > 0);
  }, [teams]);
}

function getMyTeamGroup(allTeams: ChatTeam[]): TeamGroup {
  return {
    label: "My Teams",
    teams: orderBy(
      allTeams.filter((team) => team.myTeam),
      "name"
    )
  };
}

function getOtherTeamGroup(allTeams: ChatTeam[]): TeamGroup {
  return {
    label: "Teams",
    teams: orderBy(
      allTeams.filter(
        (team) => !team.myTeam && !isSpecialTeam(team.ailorn.internalId)
      ),
      "name"
    )
  };
}

function getUnassignedGroup(allTeams: ChatTeam[]): TeamGroup | null {
  const unassignedTeam = allTeams.find(
    (team) => team.ailorn.internalId === SpecialTeamId.UnassignedChats
  );

  if (unassignedTeam) {
    return { label: "Other", teams: [unassignedTeam] };
  }

  return null;
}
