import { Bill, ClaimTenancyData } from "local/domain/bond";
import { DescriptionList, Money } from "@ailo/ui";
import React from "react";
import { Text } from "@ailo/primitives";
import { TenancyBondStatus } from "local/graphql";
import { TenancyBond } from "local/tabs";

type Props = Pick<ClaimTenancyData, "bills"> & {
  amountRentClaimed: Money;
  amountReleaseToRenters: Money;
  bond: TenancyBond;
};

export function ClaimInfo({
  amountRentClaimed,
  amountReleaseToRenters,
  bills,
  bond
}: Props): React.ReactElement {
  const billDescription = getBillsDescription(bills);
  const displayClaimInfo = bond.status !== TenancyBondStatus.BondResolved;

  const items = [
    {
      label: "Bills to be paid from bond",
      description: (
        <Text style={{ textAlign: "right" }}>
          {displayClaimInfo ? billDescription : "–"}
        </Text>
      )
    },
    {
      label: "Rent to be paid from bond",
      description: displayClaimInfo ? amountRentClaimed.format() : "–"
    },
    {
      label: "Release to renters",
      description: displayClaimInfo ? amountReleaseToRenters.format() : "–"
    }
  ];

  return (
    <DescriptionList
      size={"small"}
      items={items}
      testID={"ClaimDetailsClaimItems"}
    />
  );
}

function getBillsDescription(bills: Bill[]): string {
  const description = bills
    .filter((bill) => bill.isSelected)
    .map((bill) => `${bill.category} · ${bill.amount.format()}`)
    .join("\n");

  return description.length > 0 ? description : Money.zero().format();
}
