import React, { ReactElement, useCallback } from "react";
import { BaseSplitScreenModalForm } from "../BaseSplitScreenModalForm";
import { PropertyDetails } from "../components/PropertyDetails";

import { EndTenancyForm } from "./EndTenancyForm";

interface Props {
  tenancyId: string;
  onDismiss: () => void;
  onSuccess: () => void;
}

export function EndTenancyModalForm({
  tenancyId,
  onDismiss,
  onSuccess
}: Props): ReactElement {
  const renderFormComponent = useCallback(
    (): ReactElement => (
      <EndTenancyForm
        tenancyId={tenancyId}
        onDismiss={onDismiss}
        onSuccess={onSuccess}
      />
    ),
    [onDismiss, onSuccess, tenancyId]
  );

  return (
    <BaseSplitScreenModalForm
      title={"End tenancy"}
      renderFormComponent={renderFormComponent}
      renderRightComponent={() => <PropertyDetails tenancyId={tenancyId} />}
      onDismiss={onDismiss}
    />
  );
}
