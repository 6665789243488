import { GridRenderRowContainerProps, WhitePressable } from "@ailo/ui";
import { useLinkProps } from "@react-navigation/native";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { GridRow, ReportTableRow } from ".";
import { NavigateCfg } from "../utils/tableNavigation";

export function RowContainer<TRow extends ReportTableRow>({
  style,
  children,
  link
}: GridRenderRowContainerProps<GridRow<TRow>> & {
  link?: NavigateCfg | null;
}): ReactElement {
  const linkProps = useLinkProps(link ?? { to: "" });
  return link ? (
    <WhitePressable
      {...{ ...linkProps, onClick: linkProps.onPress }}
      style={style}
    >
      {children}
    </WhitePressable>
  ) : (
    <View {...{ children, style }} />
  );
}
