import React, { useCallback, useEffect } from "react";
import { StatusIcon, TextInputFormField } from "@ailo/ui";
import { Maybe } from "graphql/jsutils/Maybe";
import {
  AbrCompanyLookupResult,
  useAbrCompanyLookupLazyQuery
} from "local/graphql";
import { Controller, UseFormMethods } from "react-hook-form";
import { View } from "react-native";
import { validEmailRegex } from "@ailo/domains";

type CreateSupplierFormProps = {
  form: UseFormMethods<CreateSupplierFormData>;
  onFormFieldBlur: (
    fieldName: string,
    isValid: boolean | undefined,
    value: string,
    formValues: CreateSupplierFormData
  ) => void;
  toggleSubmissionPossible: (enableSubmitButton: boolean) => void;
  height: number;
};

type CreateSupplierFormData = {
  abn: string;
  name: string;
  registeredEntityName: string;
  emailAddress: string;
  addressState: string;
  addressPostcode: string;
};

type CreateSupplierFormField = keyof CreateSupplierFormData;

const AUTO_POPULATABLE_FIELDS_AND_DATA_KEYS: {
  field: CreateSupplierFormField;
  dataKey: keyof AbrCompanyLookupResult;
}[] = [
  { field: "name", dataKey: "registeredEntityName" },
  { field: "registeredEntityName", dataKey: "registeredEntityName" },
  { field: "addressState", dataKey: "addressState" },
  { field: "addressPostcode", dataKey: "addressPostcode" }
];

const CreateSupplierForm = ({
  form,
  onFormFieldBlur,
  toggleSubmissionPossible,
  height
}: CreateSupplierFormProps): JSX.Element => {
  const {
    register,
    unregister,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState
  } = form;

  useEffect(
    function registerOnMount(): () => void {
      const newFieldNames: CreateSupplierFormField[] = [
        "addressState",
        "addressPostcode"
      ];
      newFieldNames.forEach((name) => register({ name }));

      return function unregisterOnUnmount(): void {
        newFieldNames.forEach((name) => unregister(name));
      };
    },
    [register, unregister]
  );

  const setFormValuesFromLookupData = useCallback(
    function setFormValuesFromLookupData(
      data?: Maybe<AbrCompanyLookupResult>
    ): void {
      AUTO_POPULATABLE_FIELDS_AND_DATA_KEYS.filter(
        ({ field }) => !formState.dirtyFields[field] || !getValues(field)
      ).forEach(({ field, dataKey }) => {
        setValue(field, data?.[dataKey] || "", { shouldDirty: false });
        delete formState.dirtyFields[field];
      });
    },
    [formState.dirtyFields, getValues, setValue]
  );

  const [lookupAbn, abnResult] = useAbrCompanyLookupLazyQuery({
    fetchPolicy: "network-only"
  });
  const abnResultSuccessful = !!abnResult.data?.abrCompanyLookup?.abn;

  const abn = form.watch("abn");
  useEffect(() => {
    setFormValuesFromLookupData();
    clearErrors();
    if (abnIsValid(abn)) {
      lookupAbn({ variables: { abnOrAcn: abn } });
    }
  }, [abn, clearErrors, lookupAbn, setFormValuesFromLookupData]);

  useEffect(() => {
    if (!abnResult.data) {
      return;
    }

    if (!abnResult.data.abrCompanyLookup?.abn) {
      setError("abn", {
        message:
          "Sorry, we can't find a company with this ABN. Verify the number is correct.",
        type: "validate",
        shouldFocus: true
      });
      return;
    }

    setFormValuesFromLookupData(abnResult.data.abrCompanyLookup);
  }, [abnResult.data, setError, setFormValuesFromLookupData]);

  useEffect(() => {
    toggleSubmissionPossible(abnIsValid(abn) && abnResultSuccessful);
  }, [abn, abnResultSuccessful, toggleSubmissionPossible]);

  return (
    <View style={{ height }}>
      <Controller
        control={form.control}
        name={"abn"}
        defaultValue={""}
        rules={{
          required: "Supplier's ABN is required.",
          validate: abnValidationRule
        }}
        render={({ value, onChange, onBlur }) => (
          <TextInputFormField
            style={{ marginTop: 16 }}
            value={value}
            label={"Supplier ABN"}
            error={form.errors.abn?.message}
            placeholder={"Supplier ABN"}
            digitsOnly
            onChangeText={onChange}
            onBlur={() => {
              form.trigger("abn");
              onFormFieldBlur(
                "Supplier ABN",
                abnIsValid(abn) &&
                  (abnResult.loading ? undefined : abnResultSuccessful),
                getValues().abn,
                getValues()
              );
              onBlur();
            }}
            endAdornment={
              abnIsValid(abn) && (
                <StatusIcon
                  loading={abnResult.loading}
                  error={!abnResultSuccessful}
                />
              )
            }
          />
        )}
      />
      <Controller
        control={form.control}
        name={"registeredEntityName"}
        defaultValue={""}
        render={({ value, onChange, onBlur }) => (
          <TextInputFormField
            style={{ marginTop: 24 }}
            disabled
            {...{ value, onChange, onBlur }}
            label={"Registered Entity Name"}
            error={form.errors.registeredEntityName?.message}
          />
        )}
      />
      <Controller
        control={form.control}
        name={"name"}
        defaultValue={""}
        render={({ value, onChange, onBlur }) => (
          <TextInputFormField
            style={{ marginTop: 24 }}
            {...{ value, onChange, onBlur }}
            label={"Supplier Name"}
            onBlur={() =>
              onFormFieldBlur(
                "Supplier Name",
                !!getValues().name,
                getValues().name,
                getValues()
              )
            }
            error={form.errors.name?.message}
            placeholder={"Supplier Name"}
            helperText={
              "This is the name that you use to identify the supplier in Ailo. This name does not have to match Entity, Business, nor Trading name."
            }
          />
        )}
        rules={{
          required: "Supplier Name is required."
        }}
      />
      <Controller
        control={form.control}
        name={"emailAddress"}
        defaultValue={""}
        rules={{
          pattern: {
            value: validEmailRegex,
            message:
              "Please enter a valid email address, or leave this field blank"
          }
        }}
        render={({ value, onChange, onBlur }) => (
          <TextInputFormField
            style={{ marginTop: 24 }}
            {...{ value, onChange, onBlur }}
            label={"Email for payment receipts (remittance)"}
            onBlur={() =>
              onFormFieldBlur(
                "Email for payment receipts (remittance)",
                validEmailRegex.test(getValues().emailAddress) ||
                  !getValues().emailAddress,
                getValues().emailAddress,
                getValues()
              )
            }
            error={form.errors.emailAddress?.message}
            placeholder={"Email"}
          />
        )}
      />
    </View>
  );
};

function abnIsValid(abn?: string): boolean {
  return abn?.length === 11;
}

const abnValidationRule = (value: string): true | string =>
  abnIsValid(value) || "ABN must be exactly 11 digits";

export { CreateSupplierForm, CreateSupplierFormField, CreateSupplierFormData };
