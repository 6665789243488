import { AiloVerifiedIcon, IconProps } from "@ailo/primitives";
import React from "react";
import { Tooltip } from "@ailo/ui";

export function VerifiedPersonIcon(props: IconProps): React.ReactElement {
  return (
    <Tooltip tooltipContent={"On app"}>
      <AiloVerifiedIcon {...props} />
    </Tooltip>
  );
}
