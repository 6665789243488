import { AgreementDateType, getTenancyAgreementDateInfo } from "@ailo/domains";
import { formatDateDefaultToNA } from "@ailo/services";
import { DescriptionListItem } from "@ailo/ui";

export function getAgreementDatesDescriptionListItems({
  agreementStartDate,
  fixedTermAgreementEndDate,
  agreementAllowedToLapse,
  nextAgreementStartDate
}: {
  agreementStartDate?: string | null;
  fixedTermAgreementEndDate?: string | null;
  agreementAllowedToLapse: boolean;
  nextAgreementStartDate?: string | null;
}): DescriptionListItem[] {
  return [
    ...(agreementStartDate
      ? [
          getCurrentAgreementInfo({
            agreementStartDate,
            fixedTermAgreementEndDate,
            agreementAllowedToLapse
          })
        ]
      : []),
    ...(nextAgreementStartDate
      ? [
          {
            label: "New agrmt. start",
            description: formatDateDefaultToNA(nextAgreementStartDate)
          }
        ]
      : [])
  ];
}

function getCurrentAgreementInfo({
  agreementStartDate,
  fixedTermAgreementEndDate,
  agreementAllowedToLapse
}: {
  agreementStartDate: string;
  fixedTermAgreementEndDate?: string | null;
  agreementAllowedToLapse: boolean;
  nextAgreementStartDate?: string | null;
}): DescriptionListItem {
  const { dateType, date } = getTenancyAgreementDateInfo({
    agreementStartDate,
    fixedTermAgreementEndDate,
    agreementAllowedToLapse
  });
  return {
    label:
      dateType === AgreementDateType.PeriodicAgreementSince
        ? "Periodic agrmt. since"
        : dateType === AgreementDateType.PeriodicSince
        ? "Periodic since"
        : "Agreement expiry",
    description: date
  };
}
