import React from "react";
import { SFC } from "@ailo/primitives";
import { SectionContainer } from "./components";
import { OccupancyHealthSectionBody } from "./OccupancyHealthSectionBody";

interface Props {
  managementId: string;
}

export const OccupancyHealthSection: SFC<Props> = ({ style, managementId }) => {
  return (
    <SectionContainer style={style}>
      <OccupancyHealthSectionBody managementId={managementId} />
    </SectionContainer>
  );
};
