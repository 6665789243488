import React from "react";
import styled from "styled-components/native";
import { Button, Card } from "@ailo/ui";
import { Colors, SFC, Text, WarningSubtractedIcon } from "@ailo/primitives";
import { View } from "react-native";

const PropertyOnboardingListError: SFC<{
  message: string;
  onReload: () => void;
}> = ({ style, message, onReload }) => (
  <Container style={style}>
    <View style={{ alignItems: "center" }}>
      <WarningSubtractedIcon
        width={40}
        height={40}
        color={Colors.STATUS.WARNING}
      />
      <StyledBodyL weight={"medium"}>{message}</StyledBodyL>
      <View style={{ flexDirection: "row" }}>
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{"Try "}</Text.BodyS>
        <Button.Text onPress={onReload}>
          <Text.BodyS color={Colors.AILO_RED} weight={"medium"}>
            {"reloading"}
          </Text.BodyS>
        </Button.Text>
      </View>
    </View>
  </Container>
);

const Container = styled(Card)`
  justify-content: center;
  align-items: center;
  height: 360px;
  margin: 0px;
`;

const StyledBodyL = styled(Text.BodyL)`
  margin-bottom: 8px;
  text-align: center;
  margin-top: 16px;
`;

export { PropertyOnboardingListError };
