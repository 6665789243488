import { FeeSuccessModalData } from "local/domain/expense";
import { TaxTreatment } from "local/graphql";
import React, { ReactElement, useCallback } from "react";
import {
  ManagementFeeBlueprintModalForm,
  ManagementFeeBlueprintModalFormData,
  ManagementFeeBlueprintModalFormProps
} from "../ManagementFeeBlueprintModalForm";
import { useCreateManagementFeeBlueprint } from "./useCreateManagementFeeBlueprint";

export interface AddManagementFeeBlueprintModalFormControllerData {
  managementId?: string;
  agencyFeeBlueprints: ManagementFeeBlueprintModalFormProps["agencyFeeBlueprints"];
}

export interface AddManagementFeeBlueprintModalFormControllerProps
  extends AddManagementFeeBlueprintModalFormControllerData {
  onCancel: () => void;
  showAddBlueprintModal: boolean;
  onSuccess: (blueprint: FeeSuccessModalData) => void;
}

export function AddManagementFeeBlueprintModalFormController({
  managementId,
  agencyFeeBlueprints,
  onCancel,
  onSuccess,
  showAddBlueprintModal
}: AddManagementFeeBlueprintModalFormControllerProps): ReactElement {
  const [createManagementFeeBlueprintMutation, loading] =
    useCreateManagementFeeBlueprint();

  const submit = useCallback(
    async (data: ManagementFeeBlueprintModalFormData): Promise<void> => {
      if (loading || !managementId) return;

      const managementFeeBlueprint = await createManagementFeeBlueprintMutation(
        {
          managementId,
          feeBlueprintId: data.feeBlueprint.value,
          fixedAmount: data.fixedAmount
            ? { cents: data.fixedAmount.cents }
            : undefined,
          oneWeekRentPercentage: data.oneWeekRentPercentage,
          taxTreatment: data.includesGst
            ? TaxTreatment.Inclusive
            : TaxTreatment.Exclusive
        }
      );

      if (managementFeeBlueprint) {
        onSuccess(managementFeeBlueprint);
      }
    },
    [createManagementFeeBlueprintMutation, loading, managementId, onSuccess]
  );

  return (
    <ManagementFeeBlueprintModalForm
      mode={"add"}
      title={"Add a manual fee template"}
      agencyFeeBlueprints={agencyFeeBlueprints}
      onSubmit={submit}
      submitting={loading}
      onCancel={onCancel}
      visible={showAddBlueprintModal}
    />
  );
}
