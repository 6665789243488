import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { SFC } from "@ailo/primitives";
import React from "react";
import { View } from "react-native";
import {
  BackButton,
  BasicPropertyDetails,
  ChatListCard,
  DepositSummaryList,
  ExpensesCard,
  ManagementCardWithErrorBoundary,
  OffAppPaymentInfoBox,
  ProjectsCard,
  PropertyHealthCard,
  TenancyCards
} from "./components";
import { FormerTenancyCards } from "./components/TenancyCards/FormerTenancyCards";
import { PropertyContentProps } from "./PropertyContentProps";
import { BondErrorAlert } from "./components/BondErrorAlert";
import { ManagementNotesCard } from "./components/ManagementNotesCard";
import { ManagementEndDate } from "./components/ManagementEndDateCard/ManagementEndDateCard";

export const PropertyContent: SFC<PropertyContentProps> = ({
  managementId,
  style,
  onVacatePress,
  onEditEndTenancyPress,
  onTenancyDetailsPress,
  onOwnerOrTenantPress,
  onExpensePress,
  onProjectPress,
  onRentAdjustmentsPress,
  onViewMoreExpensesPress,
  onViewMoreProjectPress,
  onAddBondDetailsPress,
  onEditBondDetailsPress,
  onViewBondClaimPress,
  onManagementDetailsPress,
  onEditBondReferencePress,
  onViewManagementFeeBlueprintsPress: onViewManagementBlueprintsPress,
  onViewKeysPress
}) => {
  const showExpenses = useHasFeature(PlatformFeatureId.ExpensesCard);
  const showProjects = useHasFeature(PlatformFeatureId.Projects);
  const showDeposits = useHasFeature(PlatformFeatureId.Deposits);
  const showChat = useHasFeature(PlatformFeatureId.Chat);

  return (
    <View style={style}>
      <BackButton style={{ alignSelf: "flex-start" }} />
      <View style={{ marginTop: 20 }}>
        <ManagementEndDate
          managementId={managementId}
          style={{ marginBottom: 20 }}
        />
        <BondErrorAlert
          managementId={managementId}
          style={{ marginBottom: 20 }}
        />
        <OffAppPaymentInfoBox
          managementId={managementId}
          style={{ marginBottom: 20 }}
        />
      </View>
      <BasicPropertyDetails
        managementId={managementId}
        style={{ marginBottom: 24 }}
        onViewKeysPress={onViewKeysPress}
      />
      <ManagementNotesCard
        managementId={managementId}
        style={{ marginTop: 16 }}
      />
      <PropertyHealthCard
        managementId={managementId}
        style={{ marginTop: 16 }}
      />
      {showDeposits && (
        <DepositSummaryList
          managementId={managementId}
          style={{ marginTop: 16 }}
        />
      )}
      {showProjects && (
        <ProjectsCard
          managementId={managementId}
          onProjectPress={onProjectPress}
          style={{ marginTop: 16 }}
          onViewMoreProjectPress={onViewMoreProjectPress}
        />
      )}
      {showExpenses && (
        <ExpensesCard
          managementId={managementId}
          onExpensePress={onExpensePress}
          onViewMoreExpensesPress={onViewMoreExpensesPress}
          style={{ marginTop: 16 }}
        />
      )}
      {showChat && (
        <ChatListCard managementId={managementId} style={{ marginTop: 16 }} />
      )}
      <TenancyCards
        onVacatePress={onVacatePress}
        onEditEndTenancyPress={onEditEndTenancyPress}
        onTenancyDetailsPress={onTenancyDetailsPress}
        onTenantPress={onOwnerOrTenantPress}
        onRentAdjustmentsPress={onRentAdjustmentsPress}
        onAddBondDetailsPress={onAddBondDetailsPress}
        onEditBondDetailsPress={onEditBondDetailsPress}
        onViewBondClaimPress={onViewBondClaimPress}
        onEditBondReferencePress={onEditBondReferencePress}
        managementId={managementId}
        style={{ marginTop: 16 }}
      />
      <ManagementCardWithErrorBoundary
        managementId={managementId}
        onOwnerPress={onOwnerOrTenantPress}
        onManagementDetailsPress={onManagementDetailsPress}
        onViewManagementFeeBlueprintsPress={onViewManagementBlueprintsPress}
      />
      <FormerTenancyCards
        onRentAdjustmentsPress={onRentAdjustmentsPress}
        onTenancyDetailsPress={onTenancyDetailsPress}
        onTenantPress={onOwnerOrTenantPress}
        managementId={managementId}
        onAddBondDetailsPress={onAddBondDetailsPress}
        onEditBondDetailsPress={onEditBondDetailsPress}
        onViewBondClaimPress={onViewBondClaimPress}
        onEditBondReferencePress={onEditBondReferencePress}
      />
    </View>
  );
};
