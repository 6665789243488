import {
  AnalyticsIcon,
  BillsAgencyIcon,
  ChatNavIcon,
  DashboardIcon,
  IconProps,
  OnboardingIcon,
  PeopleIcon,
  ProjectIcon,
  PropertyIcon,
  TrustAccountingIcon,
  WalletIcon
} from "@ailo/primitives";
import {
  AppDrawerNavigatorParamList,
  Screens,
  useCurrentAgencyOrg,
  useNavigation
} from "local/common";
import { PlatformFeatureId } from "local/graphql";
import {
  BillsTabScreen,
  CommsTabScreen,
  ContactsTabScreen,
  DashboardTab,
  DisbursementsTabScreen,
  OnboardingTabScreen,
  ProjectsTabScreen,
  PropertiesTabScreen,
  ReconciliationStackNavigator,
  ReportsTabScreen,
  SettingsTabScreen,
  TransactionsScreen,
  VisualizationScreen,
  WalletTabScreen
} from "local/tabs";
import React from "react";
import { useGlobal } from "reactn";
import { DrawerElementData, DrawerItemRenderProp } from "./BaseDrawerNavigator";
import { NavigationDrawerItem } from "./NavigationDrawerItem";
import { NavigationChatUnreadCount } from "@ailo/domains";
import { AiloRN } from "@ailo/ailorn";
import {
  createGroupNavigationDrawer,
  NavigationDrawerProps
} from "./NavigationDrawerGroup";
import { isPresent } from "ts-is-present";
import { ManagementPortfolioScreen } from "local/domain/managementPortfolios";

export function useGetAvailableDrawers(): DrawerElementData[] {
  const navigation = useNavigation();
  const [features] = useGlobal("availableFeatures");

  const availableDrawers: DrawerElementData[] = [];

  const currentAgencyOrg = useCurrentAgencyOrg();

  if (features.includes(PlatformFeatureId.AnalyticsDashboard)) {
    availableDrawers.push({
      name: Screens.AnalyticsDashboardTab,
      title: "Dashboard",
      renderDrawerItem: createDrawerItemRenderProp({
        label: "Dashboard",
        icon: DashboardIcon,
        screenName: Screens.AnalyticsDashboardTab
      }),
      component: DashboardTab
    });
  }

  if (features.includes(PlatformFeatureId.Chat)) {
    availableDrawers.push({
      name: Screens.CommsTab,
      title: "Chat",
      renderDrawerItem: createDrawerItemRenderProp({
        label: "Chat",
        screenName: Screens.CommsTab,
        icon: ChatNavIcon,
        rightComponent: (
          <NavigationChatUnreadCount
            organisationAilorn={AiloRN.from(currentAgencyOrg.ailoRN, {
              expected: "authz:organisation"
            })}
          />
        )
      }),
      component: CommsTabScreen
    });
  }

  if (features.includes(PlatformFeatureId.Projects)) {
    availableDrawers.push({
      name: Screens.ProjectsTab,
      title: "Projects",
      renderDrawerItem: createDrawerItemRenderProp({
        label: "Projects",
        screenName: Screens.ProjectsTab,
        icon: ProjectIcon,
        onDrawerItemPressed: () => {
          navigation.navigate(Screens.ProjectsTab, {
            screen: Screens.ProjectList
          });
        }
      }),
      component: ProjectsTabScreen
    });
  }

  if (features.includes(PlatformFeatureId.Properties)) {
    availableDrawers.push({
      name: Screens.PropertiesTab,
      title: "Properties",
      renderDrawerItem: createDrawerItemRenderProp({
        label: "Properties",
        screenName: Screens.PropertiesTab,
        icon: PropertyIcon,
        onDrawerItemPressed: () => {
          const state = navigation.getState().routes[0].state;
          if (
            state?.index &&
            state?.routes[state.index].name === Screens.PropertiesTab
          ) {
            navigation.navigate(Screens.PropertiesTab, {
              screen: Screens.PropertyList
            });
          } else {
            navigation.navigate(Screens.AppNavigator, {
              screen: Screens.PropertiesTab
            });
          }
        }
      }),
      component: PropertiesTabScreen
    });
  }

  if (features.includes(PlatformFeatureId.ContactsTabCustomers)) {
    availableDrawers.push({
      name: Screens.ContactsTab,
      title: "Contacts",
      renderDrawerItem: createDrawerItemRenderProp({
        label: "Contacts",
        screenName: Screens.ContactsTab,
        icon: PeopleIcon
      }),
      component: ContactsTabScreen
    });
  }

  if (features.includes(PlatformFeatureId.Bills)) {
    availableDrawers.push({
      name: Screens.BillsTab,
      title: "Bills",
      renderDrawerItem: createDrawerItemRenderProp({
        label: "Bills",
        screenName: Screens.BillsTab,
        icon: BillsAgencyIcon
      }),
      component: BillsTabScreen
    });
  }

  if (features.includes(PlatformFeatureId.ViewAgencyWallet)) {
    availableDrawers.push({
      name: Screens.WalletTab,
      title: "Wallet",
      renderDrawerItem: createDrawerItemRenderProp({
        label: "Wallet",
        screenName: Screens.WalletTab,
        icon: WalletIcon
      }),
      component: WalletTabScreen
    });
  }

  if (features.includes(PlatformFeatureId.FeeBlueprints)) {
    availableDrawers.push({
      name: Screens.SettingsTab,
      title: "Settings",
      component: SettingsTabScreen
    });
  }

  if (features.includes(PlatformFeatureId.ManagementPortfolios)) {
    availableDrawers.push({
      name: Screens.Portfolio,
      component: ManagementPortfolioScreen
    });
  }

  if (features.includes(PlatformFeatureId.MetabaseReporting)) {
    availableDrawers.push({
      name: Screens.ReportsTab,
      title: "Reports",
      renderDrawerItem: createDrawerItemRenderProp({
        label: "Reporting",
        icon: AnalyticsIcon,
        screenName: Screens.ReportsTab,
        onDrawerItemPressed: () => {
          const state = navigation.getState().routes[0].state;
          if (
            state?.index &&
            state?.routes[state.index].name === Screens.ReportsTab
          ) {
            navigation.navigate(Screens.ReportsTab, {
              screen: Screens.ReportList
            });
          } else {
            navigation.navigate(Screens.AppNavigator, {
              screen: Screens.ReportsTab
            });
          }
        }
      }),
      component: ReportsTabScreen
    });
  }

  if (features.includes(PlatformFeatureId.CubejsReporting)) {
    availableDrawers.push({
      name: Screens.ReportVisulisation,
      title: "Reports",
      component: VisualizationScreen
    });
  }

  if (features.includes(PlatformFeatureId.TrustAccounting)) {
    const childDrawers = getTrustAccountingDrawers(features);

    if (childDrawers.length > 0) {
      availableDrawers.push(
        ...createGroupNavigationDrawer({
          icon: TrustAccountingIcon,
          groupName: "Trust accounting",
          groupScreenDefault: childDrawers[0].screenName as Screens,
          childDrawers
        })
      );
    }
  }

  if (features.includes(PlatformFeatureId.OnboardingTabInPmApp)) {
    availableDrawers.push({
      name: Screens.OnboardingTab,
      title: "Onboarding",
      renderDrawerItem: createDrawerItemRenderProp({
        label: "Onboarding",
        screenName: Screens.OnboardingTab,
        icon: OnboardingIcon
      }),
      component: OnboardingTabScreen
    });
  }

  return availableDrawers;
}

const createDrawerItemRenderProp = ({
  label,
  screenName,
  icon,
  rightComponent,
  onDrawerItemPressed
}: {
  label: string;
  screenName: keyof AppDrawerNavigatorParamList;
  icon?: React.FC<IconProps>;
  rightComponent?: React.ReactElement | null;
  onDrawerItemPressed?: () => void;
  childDraw?: boolean;
}): DrawerItemRenderProp => {
  return function renderDrawerItem({
    focused
  }: {
    focused: boolean;
  }): React.ReactElement {
    return (
      <NavigationDrawerItem
        label={label}
        icon={icon}
        screenName={screenName}
        focused={focused}
        rightComponent={rightComponent}
        onDrawerItemPressed={onDrawerItemPressed}
      />
    );
  };
};

function getTrustAccountingDrawers(
  features: string[]
): NavigationDrawerProps[] {
  return [
    features.includes(PlatformFeatureId.Disbursements)
      ? {
          label: "Disbursements",
          title: "Disbursements",
          component: DisbursementsTabScreen,
          name: Screens.DisbursementsList,
          screenName: Screens.DisbursementsList
        }
      : undefined,
    features.includes(PlatformFeatureId.TrustReconciliation)
      ? {
          label: "Reconciliations",
          title: "Reconciliations",
          component: ReconciliationStackNavigator,
          name: Screens.TrustReconciliationStackNavigator,
          screenName: Screens.TrustReconciliationStackNavigator
        }
      : undefined,
    features.includes(PlatformFeatureId.Receipting)
      ? {
          label: "Transactions",
          title: "Transactions",
          component: TransactionsScreen,
          name: Screens.TransactionsList,
          screenName: Screens.TransactionsList
        }
      : undefined
  ].filter(isPresent);
}
