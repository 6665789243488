import React from "react";
import { CustomersListRow } from "../CustomersList/useCustomersListQuery";
import { TextCell } from "@ailo/ui";

function EmailAddressCell({
  row: { email }
}: {
  row: CustomersListRow;
}): React.ReactElement {
  return <TextCell>{email ? email : "Not supplied"}</TextCell>;
}

EmailAddressCell.Loading = TextCell.Loading;

export { EmailAddressCell };
