import React, { ReactElement, useState } from "react";
import styled from "styled-components/native";
import {
  Alert,
  BaseModal,
  Button,
  DateInputFormField,
  MoneyInput
} from "@ailo/ui";
import { Money } from "@ailo/money";
import { ReconciliationPeriod } from "../types";
import { useUpdateBankBalance } from "./useUpdateBankBalance";
import { PleaseWaitTitle } from "../../components";

interface Props {
  period: ReconciliationPeriod;
  onCancel: () => void;
  onUpdate: () => void;
}

export function EditBankBalanceModal({
  period,
  onCancel,
  onUpdate
}: Props): ReactElement {
  const [date, setDate] = useState(period.bankBalance?.date?.toString());
  const [amount, setAmount] = useState(period.bankBalance?.amount);
  const { updateBalance, submitting, lastUpdateFailed } = useUpdateBankBalance({
    onUpdateSuccess: onUpdate,
    date,
    amount,
    periodId: period.id
  });

  const footer = (
    <>
      <Button.Secondary onPress={onCancel}>{"Cancel"}</Button.Secondary>
      {!submitting && (
        <Button.Primary disabled={submitting} onPress={updateBalance}>
          {"Update"}
        </Button.Primary>
      )}
    </>
  );

  const title = submitting ? (
    <PleaseWaitTitle />
  ) : (
    "Update bank statement balance"
  );

  return (
    <BaseModal title={title} footer={footer} showFooterBorder>
      {lastUpdateFailed && <UpdateErrorAlert />}
      <AmountInput
        disabled={submitting}
        value={amount}
        onChange={(newAmount) =>
          setAmount(newAmount ? Money.from(newAmount) : undefined)
        }
      />
      <StatementDateInput
        editable={!submitting}
        minDate={period.periodStart.toString()}
        maxDate={period.periodEnd.toString()}
        value={date}
        onChange={setDate}
        webPopoverHorizontalAlign={"start"}
        webPopoverContainerStyle={{ marginTop: "-54px" }}
      />
    </BaseModal>
  );
}

const UpdateErrorAlert = styled(Alert).attrs({
  type: "warning",
  children: "There was a problem updating the balance.\nPlease try again."
})`
  margin-top: 12px;
  margin-bottom: 4px;
`;

const AmountInput = styled(MoneyInput).attrs({
  label: "Amount",
  allowNull: true
})`
  margin-top: 12px;
`;

const StatementDateInput = styled(DateInputFormField).attrs({
  label: "Statement date"
})`
  margin-top: 24px;
  margin-bottom: 44px;
`;
