import React from "react";
import { Money, TextCell } from "@ailo/ui";
import { useGetBalanceOwedFromOwingQuery } from "local/graphql";
import { ErrorCell } from "./ErrorCell";
import { BasePropertyListRow } from "../BasePropertyListRow";

export function BalanceOwedFromOwingCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetBalanceOwedFromOwingQuery({
    variables: { managementId }
  });

  if (loading && !data) return <Loading />;

  const rentOwing = data?.management?.mostRecentTenancy?.rent;
  if (!rentOwing)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const overdueAmount = rentOwing.progress.overdue.amount.total.cents;

  return <TextCell>{Money.fromCents(overdueAmount).format()}</TextCell>;
}

function Loading(): React.ReactElement {
  return <TextCell.Loading />;
}

BalanceOwedFromOwingCell.Loading = Loading;
