import { formatRecurringDateFrequency } from "@ailo/date";
import { useHasFeature } from "@ailo/domains";
import { Text } from "@ailo/primitives";
import {
  Alert,
  CheckboxInput,
  feeMaxMoney,
  FormField,
  FormFieldRow,
  Money,
  MoneyInput,
  PercentInputFormField,
  RadioButtonGroup
} from "@ailo/ui";
import { GstBox } from "local/domain/expense";
import {
  FeeBlueprintChargeType,
  FeeBlueprintType,
  PlatformFeatureId
} from "local/graphql";
import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { isPresent } from "ts-is-present";
import { FeeEventField } from "./FeeEventField";
import { FrequencyDetails } from "./FrequencyDetails";
import { ManageFeeBlueprintFormData } from "./ManageFeeBlueprintForm";
import { taxCategoryDefaultValues } from "./taxCategoryDefaultValues";

export function ChargeDetails({
  mode
}: {
  mode: "create" | "update";
}): ReactElement {
  const canManageOneOffFeeBlueprints = useHasFeature(
    PlatformFeatureId.OneOffFeeBlueprints
  );
  const canManageEventBasedFees = useHasFeature(
    PlatformFeatureId.EventBasedFee
  );

  const form = useFormContext<ManageFeeBlueprintFormData>();
  const { getValues, setValue } = form;
  const { dirtyFields } = form.formState;

  const frequency = form.watch("frequency");
  const type = form.watch("type");
  const chargeType = form.watch("chargeType");
  const taxCategoryId = form.watch("taxCategoryId");
  const oneWeekRentPercentage = form.watch("oneWeekRentPercentage");
  const includesGst = form.watch("includesGst");
  const price = form.watch("price");

  useEffect(() => {
    if (
      mode !== "create" ||
      !taxCategoryId ||
      !(taxCategoryId in taxCategoryDefaultValues)
    )
      return;
    const defaultValues = taxCategoryDefaultValues[taxCategoryId];
    if (
      defaultValues.type === FeeBlueprintType.EventBasedFee &&
      !canManageEventBasedFees
    ) {
      defaultValues.type = FeeBlueprintType.OneOffFee;
      delete defaultValues.event;
    }
    (Object.keys(defaultValues) as Array<keyof typeof defaultValues>).forEach(
      (field) => {
        if (!dirtyFields[field] || !getValues(field)) {
          setValue(field, defaultValues[field] ?? null, { shouldDirty: false });
          delete dirtyFields[field];
        }
      }
    );
  }, [
    dirtyFields,
    getValues,
    setValue,
    taxCategoryId,
    mode,
    canManageEventBasedFees
  ]);

  return (
    <>
      <Controller
        control={form.control}
        name={"type"}
        defaultValue={null}
        render={({ value, onChange, onBlur }) => (
          <FormField
            label={"Select type of fee"}
            error={form.errors.chargeType?.message}
            style={{
              marginBottom: 24,
              display: canManageOneOffFeeBlueprints ? undefined : "none"
            }}
          >
            <RadioButtonGroup
              {...{ value, onChange, onBlur }}
              variant={"button-solid-group"}
              options={[
                {
                  value: FeeBlueprintType.OneOffFee,
                  label: "Manual fee"
                },
                {
                  value: FeeBlueprintType.RecurringFee,
                  label: "Recurring fee"
                },
                canManageEventBasedFees
                  ? {
                      value: FeeBlueprintType.EventBasedFee,
                      label: "Event-based fee"
                    }
                  : null
              ].filter(isPresent)}
              disabled={mode === "update"}
            />
          </FormField>
        )}
        rules={{
          required: "Fee type is required."
        }}
      />

      {type === FeeBlueprintType.RecurringFee && (
        <FrequencyDetails mode={mode} />
      )}

      {type === FeeBlueprintType.EventBasedFee && <FeeEventField mode={mode} />}

      <Controller
        control={form.control}
        name={"chargeType"}
        defaultValue={null}
        render={({ value, onChange, onBlur }) => (
          <FormField
            label={"How is this fee charged?"}
            error={form.errors.frequency?.message}
            style={{
              marginBottom: 24,
              display:
                type === FeeBlueprintType.RecurringFee ? "none" : undefined
            }}
          >
            <RadioButtonGroup
              {...{ value, onChange, onBlur }}
              variant={"button-solid-group"}
              options={[
                {
                  value: FeeBlueprintChargeType.FixedAmount,
                  label: "Flat fee"
                },
                {
                  value: FeeBlueprintChargeType.OneWeekRentPercentage,
                  label: "Based on rent"
                }
              ]}
              disabled={mode === "update"}
            />
          </FormField>
        )}
        rules={{
          required: "Charge type is required."
        }}
      />

      {(type === FeeBlueprintType.RecurringFee ||
        chargeType === FeeBlueprintChargeType.FixedAmount) && (
        <FormFieldRow cols={2}>
          <Controller
            control={form.control}
            name={"price"}
            defaultValue={{ cents: 0 }}
            render={({ value, onChange, onBlur }) => (
              <FormField
                label={"Default Price"}
                error={form.errors.price?.message}
              >
                <MoneyInput
                  {...{ value, onChange, onBlur }}
                  endAdornment={
                    frequency && formatRecurringDateFrequency(frequency)
                  }
                  endAdornmentWidth={28}
                  withTrailingZeros
                />
              </FormField>
            )}
            rules={{
              required: "Default Price is required.",
              validate: (price): boolean | string =>
                price.cents < 0
                  ? `Amount can't be less than ${Money.zero().format()}.`
                  : price.cents > feeMaxMoney.cents
                  ? `Amount can't be more than ${feeMaxMoney.format()}.`
                  : true
            }}
          />
        </FormFieldRow>
      )}

      {type !== FeeBlueprintType.RecurringFee &&
        chargeType === FeeBlueprintChargeType.OneWeekRentPercentage && (
          <FormFieldRow cols={2}>
            <Controller
              control={form.control}
              name={"oneWeekRentPercentage"}
              render={({ value, onChange, onBlur }) => (
                <PercentInputFormField
                  {...{ value, onChange, onBlur }}
                  label={"Price"}
                  error={form.errors.oneWeekRentPercentage?.message}
                  format={"decimal"}
                  decimalPlaces={4}
                  endAdornment={<Text.BodyL>{"weeks of rent"}</Text.BodyL>}
                />
              )}
              rules={{
                required: "Weeks of rent is required.",
                validate: (price): boolean | string =>
                  price.cents < 0 ? `Weeks of rent can't be less than 0` : true
              }}
            />
          </FormFieldRow>
        )}

      <Controller
        name={"includesGst"}
        control={form.control}
        render={({ onChange, value }) => (
          <CheckboxInput
            testID={"IncludeGstCheckboxInput"}
            label={"Includes GST"}
            style={{ marginTop: 26 }}
            value={value}
            onChange={onChange}
          />
        )}
      />

      {(type === FeeBlueprintType.RecurringFee ||
        chargeType === FeeBlueprintChargeType.FixedAmount) && (
        <GstBox
          {...{
            includesGst,
            fixedAmount: price ? Money.from(price) : Money.zero()
          }}
          style={{ marginTop: 26 }}
        />
      )}

      {type !== FeeBlueprintType.RecurringFee &&
        chargeType === FeeBlueprintChargeType.OneWeekRentPercentage && (
          <Alert
            type={"info"}
            message={
              oneWeekRentPercentage == null
                ? "Enter a value to see an estimated amount"
                : `If the weekly rent amount is ${Money.fromDollars(
                    300
                  ).format()}, the total price including GST will be ${Money.fromDollars(
                    300
                  )
                    .multiply(oneWeekRentPercentage)
                    .multiply(includesGst ? 1 : 1.1)
                    .format()}`
            }
            style={{ marginTop: 26 }}
          />
        )}
    </>
  );
}
