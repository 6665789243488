import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import {
  ClaimedFilesCount,
  FileKind,
  useGetClaimedFilesCountQuery
} from "local/graphql";

export function useGetClaimedFilesCount(
  claimerAilorn: string,
  fileKind?: FileKind
): MappedQueryResult<ClaimedFilesCount> {
  return useMappedQueryResult(
    useGetClaimedFilesCountQuery({
      variables: { claimerAilorn, fileKind },
      fetchPolicy: "network-only"
    }),
    (data) => {
      if (!data.claimedFilesCount) {
        throw new ApolloError({
          errorMessage: `Could not get claimed files count for claimer ${claimerAilorn}`
        });
      }

      return data.claimedFilesCount;
    }
  );
}
