import React, { ReactElement, useCallback, useState } from "react";
import {
  useFilesState,
  FilesStateFile,
  ClaimFileError,
  FilesStateUserFriendlyError,
  MaybeFileDetails
} from "@ailo/domains";
import { useAttachFilesToTenancyMutation, FileKind } from "local/graphql";
import { GraphQLError } from "graphql";
import { ApolloError } from "@apollo/client";
import { Attachments } from "../../common";

type NewFileData = FilesStateFile;

interface Props {
  tenancyId: string;
  files: MaybeFileDetails[];
}

export function TenancyAttachments({ tenancyId, files }: Props): ReactElement {
  const [error, setError] = useState<{
    title: string;
    description: string;
  }>();

  const [attachFilesToTenancyMutation] = useAttachFilesToTenancyMutation();

  const claimFile = useCallback(
    async (file: NewFileData): Promise<void> => {
      try {
        const { errors } = await attachFilesToTenancyMutation({
          variables: {
            id: tenancyId,
            fileIds: [file.id!]
          }
        });
        if (errors) throw errors[0];
      } catch (e) {
        if (e instanceof GraphQLError || e instanceof ApolloError) {
          throw new ClaimFileError(e.message);
        }
        throw e;
      }
    },
    [attachFilesToTenancyMutation, tenancyId]
  );

  const showError = useCallback((error: FilesStateUserFriendlyError): void => {
    setError({
      title: error.title,
      description: error.message
    });
  }, []);

  const {
    files: newFiles,
    addFile,
    removeFile,
    retryFile
  } = useFilesState({
    kind: FileKind.TenancyFile,
    keepFilesWithError: true,
    claimFile,
    onAddFileError: showError
  });

  return (
    <Attachments
      files={files}
      newFiles={newFiles}
      onRemoveFile={removeFile}
      onRetryFile={retryFile}
      onUploadPress={addFile}
      onDismiss={(): void => setError(undefined)}
      error={error}
      emptyFileListMsg={"Upload files for this tenancy"}
    />
  );
}
