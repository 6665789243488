import { useCallback, useState } from "react";

export function useErrorSavingDetails(): {
  errorSavingDetails: boolean;
  setError: () => void;
  clearError: () => void;
} {
  const [errorSavingDetails, setErrorSavingDetails] = useState(false);
  const setError = useCallback(() => {
    setErrorSavingDetails(true);
  }, [setErrorSavingDetails]);
  const clearError = useCallback(() => {
    setErrorSavingDetails(false);
  }, [setErrorSavingDetails]);
  return { errorSavingDetails, setError, clearError };
}
