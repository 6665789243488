import React, { ReactElement } from "react";
import { Badge, Money } from "@ailo/ui";

export function ClaimStatusBadge({
  amountClaimed
}: {
  amountClaimed: Money;
}): ReactElement {
  const badgeType = amountClaimed.isZero ? "success" : "default";
  const badgeText = amountClaimed.isZero ? "Bond released" : "Awaiting funds";
  return (
    <Badge
      type={badgeType}
      style={{
        marginTop: 14,
        paddingHorizontal: 4,
        marginBottom: 18,
        width: "fit-content"
      }}
    >
      {badgeText}
    </Badge>
  );
}
