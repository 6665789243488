import React from "react";
import { AFC, Text } from "@ailo/primitives";
import { CellContainer, Money, TextCell } from "@ailo/ui";

interface Props {
  amount: Money;
  testID?: string;
}

interface Statics {
  Loading: React.FC;
}

const Total: AFC<Props> & Statics = ({ amount, testID }) => {
  return (
    <CellContainer>
      <Text.BodyS numberOfLines={1} testID={testID}>
        {amount.format()}
      </Text.BodyS>
    </CellContainer>
  );
};

Total.Loading = TextCell.Loading;

export { Total };
