import React from "react";
import { FormFieldRow } from "@ailo/ui";
import { TrustAccountWalletInput } from "../TrustAccountWalletInput";
import { WalletSearchMode } from "local/tabs";
import { MainFormContainer } from "../../components";

export function WalletReceiptMainForm(): React.ReactElement {
  return (
    <MainFormContainer>
      <FormFieldRow>
        <TrustAccountWalletInput
          label={"To wallet"}
          mode={WalletSearchMode.NON_TENANCY}
        />
      </FormFieldRow>
    </MainFormContainer>
  );
}
