import { Text } from "@ailo/primitives";
import { SearchProperty } from "local/domain/propertyManagement";
import React, { ReactElement, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { ProjectFormData, ProjectFormFields } from "./ProjectFormData";

export function ProjectSearchProperty(): ReactElement {
  const { watch, reset } = useFormContext<ProjectFormData>();

  const management = watch(ProjectFormFields.management);

  const onPropertySelected = useCallback(
    (selectedProperty) => {
      reset({
        management: selectedProperty || undefined
      });
    },
    [reset]
  );
  return (
    <>
      <Text.BodyL style={{ marginBottom: 20 }}>
        {"Choose a property"}
      </Text.BodyL>
      <SearchProperty
        managementId={management?.value}
        propertyAddress={management?.label}
        name={ProjectFormFields.management}
        onOptionSelect={onPropertySelected}
      />
    </>
  );
}
