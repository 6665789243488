import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import { useCallback } from "react";
import {
  CreateManagementFeeBlueprintMutation,
  useCreateManagementFeeBlueprintMutation,
  GetManagementFeeBlueprintsDocument,
  CreateManagementFeeBlueprintInput,
  FeeBlueprintType
} from "local/graphql";

export function useCreateManagementFeeBlueprint(): [
  createManagementFeeBlueprint: (
    input: CreateManagementFeeBlueprintInput
  ) => Promise<
    CreateManagementFeeBlueprintMutation["createManagementFeeBlueprint"]
  >,
  loading: boolean
] {
  const analytics = useAnalytics();
  const toast = useToastContext();

  const [createManagementFeeBlueprintMutation, { loading }] =
    useCreateManagementFeeBlueprintMutation();

  const createManagementFeeBlueprint = useCallback(
    async ({
      managementId,
      feeBlueprintId,
      fixedAmount,
      taxTreatment,
      oneWeekRentPercentage,
      description
    }: CreateManagementFeeBlueprintInput) => {
      if (loading) return;

      try {
        const result = await createManagementFeeBlueprintMutation({
          variables: {
            input: {
              managementId,
              feeBlueprintId,
              fixedAmount,
              taxTreatment,
              oneWeekRentPercentage,
              description
            }
          },
          refetchQueries: [
            {
              query: GetManagementFeeBlueprintsDocument,
              variables: {
                conditions: {
                  managementId,
                  archived: false
                }
              }
            },
            {
              query: GetManagementFeeBlueprintsDocument,
              variables: {
                conditions: {
                  managementId,
                  archived: false,
                  type: FeeBlueprintType.EventBasedFee
                }
              }
            },
            {
              query: GetManagementFeeBlueprintsDocument,
              variables: {
                conditions: {
                  managementId,
                  archived: false,
                  type: FeeBlueprintType.OneOffFee
                }
              }
            }
          ]
        });
        throwIfMutationFailed(result, {
          dataKey: "createManagementFeeBlueprint"
        });

        analytics.track("Fee Blueprint Added to Property Schedule", {
          feeBlueprintId,
          managementId
        });

        return result.data?.createManagementFeeBlueprint;
      } catch (error) {
        AiloSentry.captureException(error);
        toast.showFormSubmitError();
      }
    },
    [loading, createManagementFeeBlueprintMutation, toast, analytics]
  );

  return [createManagementFeeBlueprint, loading];
}
