import moment, { Moment } from "moment";
import { useState, useCallback, useEffect } from "react";

import { useGetAmountDueAtDateLazyQuery } from "local/graphql";

export function useTenancyEndDateChange({
  liabilityId,
  oldTenancyEndDate
}: { liabilityId?: string; oldTenancyEndDate?: moment.Moment } = {}): {
  amountDueAtEndDate?: number;
  tenancyEndDate?: moment.Moment;
  onTenancyEndDateChange: (endDate: moment.Moment | undefined) => void;
} {
  const { requestForAmountDueAtDate, amount: amountDueAtEndDate } =
    useGetAmountDue();

  const [tenancyEndDate, setTenancyEndDate] = useState<Moment>();

  useEffect(() => {
    setTenancyEndDate(oldTenancyEndDate);
  }, [oldTenancyEndDate]);

  const onTenancyEndDateChange = useCallback(
    (endDate: moment.Moment | undefined): void => {
      setTenancyEndDate(endDate ? moment(endDate) : undefined);
      if (endDate) {
        requestForAmountDueAtDate({
          date: endDate,
          liabilityId: liabilityId
        });
      }
    },
    [liabilityId, requestForAmountDueAtDate]
  );

  return {
    amountDueAtEndDate,
    tenancyEndDate,
    onTenancyEndDateChange
  };
}

function useGetAmountDue(): {
  requestForAmountDueAtDate: (arg: {
    date?: moment.Moment;
    liabilityId?: string;
  }) => void;
  amount?: number;
} {
  const [getAmountDueAtDateLazyQuery, { data: amountDueAtDateData }] =
    useGetAmountDueAtDateLazyQuery();

  return {
    amount: amountDueAtDateData?.amountDueToDate?.cents,
    requestForAmountDueAtDate: useCallback(
      ({ date, liabilityId }): void => {
        getAmountDueAtDateLazyQuery({
          variables: {
            dateTime: date!.add(1, "day").toISOString(),
            liabilityId: liabilityId!
          }
        });
      },
      [getAmountDueAtDateLazyQuery]
    )
  };
}
