import React, { ReactElement } from "react";
import {
  EndTenancyFormData,
  EndTenancyFormFields
} from "../EndTenancyFormData";
import { Controller, useFormContext } from "react-hook-form";
import { DateInputFormField } from "@ailo/ui";

export function RentLiabilityEndDateInput(): ReactElement {
  const { watch } = useFormContext<EndTenancyFormData>();
  const endDate = watch(EndTenancyFormFields.endDate);
  return (
    <Controller
      name={EndTenancyFormFields.rentLiabilityEndDate}
      render={({ onChange }): ReactElement => {
        return (
          <DateInputFormField
            webPopoverHorizontalAlign={"start"}
            value={endDate}
            onChange={onChange}
            label={"Rent liability end date"}
            placeholder={"Liability end date"}
            autoCorrect={false}
            autoCompleteType={"off"}
            editable={false}
          />
        );
      }}
    />
  );
}
