import React, { ReactElement } from "react";
import { Colors, Skeleton, Text } from "@ailo/primitives";
import { CopyToClipboardButton } from "@ailo/ui";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";

interface BankAccountDetails {
  accountNumber: string;
  bsb: string;
}

interface BankAccountDetailsBoxParams {
  bankAccount: BankAccountDetails;
  reference?: string;
  showReference?: boolean;
  style?: StyleProp<ViewStyle>;
  headerContent?: React.ReactNode;
}

function BankAccountDetailsBox({
  reference,
  bankAccount,
  showReference = true,
  style,
  headerContent
}: BankAccountDetailsBoxParams): ReactElement {
  return (
    <BorderedBox
      style={[
        {
          borderRadius: 4,
          padding: 18
        },
        style
      ]}
    >
      {headerContent}
      {showReference && (
        <>
          <Row>
            <View style={{ marginRight: 7 }}>
              <Text.BodyM weight={"medium"}>{"Bond reference:"}</Text.BodyM>
            </View>
            <CopyToClipboardButton value={reference ? reference : "N/A"} />
          </Row>
        </>
      )}
      <Row>
        <View style={{ marginRight: 7 }}>
          <Text.BodyM weight={"medium"}>{"BSB:"}</Text.BodyM>
        </View>
        <CopyToClipboardButton value={bankAccount.bsb} />
      </Row>
      <Row>
        <View style={{ marginRight: 7 }}>
          <Text.BodyM weight={"medium"}>{"Account:"}</Text.BodyM>
        </View>
        <CopyToClipboardButton value={bankAccount.accountNumber} />
      </Row>
    </BorderedBox>
  );
}

function Loading({
  showReference = true,
  style,
  headerContent
}: {
  showReference?: boolean;
  style?: StyleProp<ViewStyle>;
  headerContent?: React.ReactNode;
}): ReactElement {
  return (
    <BorderedBox
      style={[
        {
          borderRadius: 4,
          padding: 18
        },
        style
      ]}
    >
      {headerContent}
      {showReference && (
        <>
          <Row>
            <View style={{ marginRight: 7 }}>
              <Text.BodyM weight={"medium"}>{"Bond reference:"}</Text.BodyM>
            </View>
            <Skeleton style={{ height: 12, width: 56 }} />
          </Row>
        </>
      )}
      <Row>
        <View style={{ marginRight: 7 }}>
          <Text.BodyM weight={"medium"}>{"BSB:"}</Text.BodyM>
        </View>
        <Skeleton style={{ height: 12, width: 56 }} />
      </Row>
      <Row>
        <View style={{ marginRight: 7 }}>
          <Text.BodyM weight={"medium"}>{"Account:"}</Text.BodyM>
        </View>
        <Skeleton style={{ height: 12, width: 74 }} />
      </Row>
    </BorderedBox>
  );
}

const Row = styled(View)`
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

export const BorderedBox = styled(View)`
  border: 1px solid ${Colors.GRAYSCALE.OUTLINE};
  border-radius: 12px;
`;

BankAccountDetailsBox.Loading = Loading;

export {
  BankAccountDetails,
  BankAccountDetailsBox,
  BankAccountDetailsBoxParams
};
