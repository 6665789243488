import { AiloRN } from "@ailo/ailorn";
import { useCurrentAgencyOrg } from "./useCurrentAgencyOrg";

export function useAgencyDefaultWalletLegalEntity(): AiloRN<"authz:legalentity"> {
  const agencyOrganisation = useCurrentAgencyOrg();

  const agencyDefaultWalletStringAilorn = AiloRN.from(
    agencyOrganisation.defaultWalletLegalEntity?.ailorn
  );
  if (
    !agencyDefaultWalletStringAilorn ||
    !agencyDefaultWalletStringAilorn.isA("authz:legalentity")
  ) {
    throw new Error("Agency default wallet AiloRN should be a legal entity");
  }
  return AiloRN.of(
    "authz:legalentity",
    agencyDefaultWalletStringAilorn.internalId
  );
}
