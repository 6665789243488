import { AiloRN } from "@ailo/ailorn";
import { LocalDate, RecurringDate } from "@ailo/date";
import { maskNumber } from "@ailo/domains";
import { Colors, Text } from "@ailo/primitives";
import { didQueryNotLoadYet } from "@ailo/services";
import {
  Alert,
  Badge,
  formatMonthlyFrequency,
  ListItem,
  OnOffBadge
} from "@ailo/ui";
import Big from "big.js";
import { LegalEntityBadge } from "local/domain/authz";
import {
  GetPropertyWalletMoneyManagementSettingsQueryHookResult,
  QuartzPlanFrequency
} from "local/graphql";
import React from "react";
import { View } from "react-native";
import { isPresent } from "ts-is-present";
import { RowSpaceBetween } from "./RowSpaceBetween";

export function AutoWithdrawSettingsDisplay({
  result,
  onLegalEntityPress
}: {
  result: GetPropertyWalletMoneyManagementSettingsQueryHookResult;
  onLegalEntityPress?: (id: string, entityType: "Person" | "Company") => void;
}): React.ReactElement | null {
  if (didQueryNotLoadYet(result)) {
    return <AutoWithdrawSettingsDisplayLoading />;
  }

  const { data } = result;
  const enabledAutoWithdrawPlan =
    data?.walletByWalletOwnerReference?.autoWithdrawPlans?.items?.find(
      (p) => p?.enabled
    );

  if (!enabledAutoWithdrawPlan) {
    return (
      <ListItem
        style={{
          borderType: "none",
          paddingLeft: 0,
          paddingRight: 0
        }}
        header={<Text.BodyL weight={"medium"}>{"Auto Transfer"}</Text.BodyL>}
        rightComponent={<OnOffBadge enabled={false} />}
      />
    );
  }

  const paymentMethodDestinations =
    enabledAutoWithdrawPlan?.details?.paymentMethodDestinations;

  const totalShares = paymentMethodDestinations?.reduce(
    (total, { share }) => total + share,
    0
  );

  const formattedFrequency =
    enabledAutoWithdrawPlan.details.frequency === QuartzPlanFrequency.Monthly
      ? formatMonthlyFrequency(
          enabledAutoWithdrawPlan.details.anniversaryDaysOfMonth ?? [],
          enabledAutoWithdrawPlan.details.isLastDayOfTheMonth ?? false
        )
      : new RecurringDate({
          frequency: enabledAutoWithdrawPlan.details.frequency,
          startDate: enabledAutoWithdrawPlan.details.startDate
        }).format({ type: "F-ly on D" });

  return (
    <ListItem
      style={{
        borderType: "none",
        paddingLeft: 0,
        paddingRight: 0
      }}
      header={<Text.BodyL weight={"medium"}>{"Auto Transfer"}</Text.BodyL>}
      rightComponent={<OnOffBadge enabled={!!enabledAutoWithdrawPlan} />}
    >
      <RowSpaceBetween
        style={{ paddingBottom: 8 }}
        left={
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{"To"}</Text.BodyS>
        }
        right={
          enabledAutoWithdrawPlan?.owner ? (
            <LegalEntityBadge
              onPress={onLegalEntityPress}
              legalEntityAiloRN={AiloRN.from(enabledAutoWithdrawPlan?.owner)}
            />
          ) : (
            <Text.BodyS color={Colors.TEXT.DARK.PRIMARY}>
              {"Unknown"}
            </Text.BodyS>
          )
        }
      />

      <RowSpaceBetween
        style={{ paddingBottom: 8 }}
        left={
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
            {"Destination account"}
          </Text.BodyS>
        }
        right={
          <View>
            {paymentMethodDestinations
              .map(
                ({ paymentMethod, share }) =>
                  paymentMethod.__typename === "BankAccount" &&
                  share && (
                    <Text.BodyS
                      key={paymentMethod.id}
                      style={{ marginRight: 0, marginLeft: "auto" }}
                      color={Colors.TEXT.DARK.PRIMARY}
                    >
                      {`${paymentMethod.accountName} ${maskNumber(
                        paymentMethod.accountNumber
                      )} · ${Big(share)
                        .div(totalShares)
                        .mul(100)
                        .round(2)
                        .toNumber()}%`}
                    </Text.BodyS>
                  )
              )
              .filter(isPresent)}
          </View>
        }
      />

      <RowSpaceBetween
        style={{ paddingBottom: 8 }}
        left={
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
            {"Frequency"}
          </Text.BodyS>
        }
        right={
          <Text.BodyS color={Colors.TEXT.DARK.PRIMARY}>
            {formattedFrequency}
          </Text.BodyS>
        }
      />

      <RowSpaceBetween
        left={
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
            {"Next transfer"}
          </Text.BodyS>
        }
        right={
          <Text.BodyS
            style={{ textTransform: "capitalize" }}
            color={Colors.TEXT.DARK.PRIMARY}
          >
            {LocalDate.from(
              enabledAutoWithdrawPlan?.details?.nextFireTime
            ).format("D MMMM YYYY")}
          </Text.BodyS>
        }
      />

      <Alert type={"info"} style={{ marginTop: 16 }}>
        {"Auto transfer happens after fees and bills are paid"}
      </Alert>
    </ListItem>
  );
}

function AutoWithdrawSettingsDisplayLoading(): React.ReactElement {
  return (
    <ListItem
      style={{
        borderType: "none",
        paddingLeft: 0,
        paddingRight: 0
      }}
      header={<Text.BodyL.Loading />}
      rightComponent={<Badge.Loading />}
    >
      <RowSpaceBetween
        style={{ paddingBottom: 8 }}
        left={<Text.BodyS.Loading width={20} />}
        right={<Text.BodyS.Loading width={80} />}
      />
      <RowSpaceBetween
        style={{ paddingBottom: 8 }}
        left={<Text.BodyS.Loading width={120} />}
        right={<Text.BodyS.Loading width={60} />}
      />
      <RowSpaceBetween
        left={<Text.BodyS.Loading width={80} />}
        right={<Text.BodyS.Loading width={120} />}
      />
      <RowSpaceBetween right={<Text.BodyS.Loading width={160} />} />
    </ListItem>
  );
}

AutoWithdrawSettingsDisplay.Loading = AutoWithdrawSettingsDisplayLoading;
