import { useToastContext } from "@ailo/ui";
import { useCompleteAction } from "local/domain/project";

export function useGetCompleteAction(
  projectId: string
): (actionId: string) => void {
  const toast = useToastContext();

  const [completeAction] = useCompleteAction({
    projectId,
    onError: () => {
      toast.show({
        type: "error",
        message: "Cannot update the status of this action"
      });
    }
  });
  return completeAction;
}
