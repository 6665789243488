import React, { ReactElement, useCallback } from "react";
import { Button, useToastContext } from "@ailo/ui";
import { useUnapproveMigratingManagement } from "../../hooks";
import {
  PlatformFeatureId,
  UnapproveMigratingManagementMutation
} from "local/graphql";
import { useHasFeature } from "@ailo/domains";
import styled from "styled-components/native";
import { View } from "react-native";
import { PublishPropertyButton } from "./PublishPropertyButton";

interface Props {
  migratingManagementId: string;
  closeSidebar: () => void;
  publishButtonText: string;
}

export function ApprovedMigratingManagementFooter({
  migratingManagementId,
  closeSidebar,
  publishButtonText
}: Props): ReactElement {
  const hasPublishPropertyFeature = useHasFeature(
    PlatformFeatureId.OnboardingTabPublishProperty
  );
  const toast = useToastContext();

  const onError = useCallback(() => {
    toast.show({
      type: "error",
      message: "Unable to remove property from approved. Please try again"
    });
  }, [toast]);

  const onSuccess = useCallback(
    (data: UnapproveMigratingManagementMutation) => {
      const successes = data.unapproveMigratingManagements?.successes ?? [];
      if (successes.length === 0) {
        toast.show({
          type: "error",
          message: "Unable to remove property from approved. Please try again"
        });
        return;
      }

      closeSidebar();
      toast.show({
        type: "success",
        message: "Property removed from approved"
      });
    },
    [toast, closeSidebar]
  );

  const [unapproveMigratingManagement, { loading }] =
    useUnapproveMigratingManagement({
      migratingManagementId,
      onSuccess,
      onError
    });

  const UnapproveButton = hasPublishPropertyFeature
    ? StyledSecondaryButton
    : StyledPrimaryButton;

  return (
    <ButtonContainer>
      <UnapproveButton
        hasPublishPropertyFeature={hasPublishPropertyFeature}
        onPress={() => unapproveMigratingManagement()}
        loading={loading}
      >
        {"Unapprove Property"}
      </UnapproveButton>

      {hasPublishPropertyFeature ? (
        <PublishPropertyButton
          migratingManagementId={migratingManagementId}
          closeSidebar={closeSidebar}
          buttonText={publishButtonText}
        />
      ) : null}
    </ButtonContainer>
  );
}

const ButtonContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const StyledPrimaryButton = styled(Button.Primary)<{
  hasPublishPropertyFeature: boolean;
}>`
  flex: 1;
  margin-right: ${({ hasPublishPropertyFeature }): string =>
    hasPublishPropertyFeature ? "12px" : "0"};
`;

const StyledSecondaryButton = styled(Button.Secondary)<{
  hasPublishPropertyFeature: boolean;
}>`
  flex: 1;
  margin-right: ${({ hasPublishPropertyFeature }): string =>
    hasPublishPropertyFeature ? "12px" : "0"};
`;
