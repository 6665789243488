import { AiloRN, services } from "@ailo/ailorn";
import { Colors, opacify } from "@ailo/primitives";
import {
  AiloSentry,
  didQueryNotLoadYet,
  didQuerySucceed,
  useOnFocus
} from "@ailo/services";
import {
  AlertScreen,
  ErrorAlertScreen,
  Money,
  useAlertContext,
  useToastContext
} from "@ailo/ui";
import {
  EditExpenseModalFormController,
  EditExpenseModalFormData
} from "local/domain/expense";
import React, { ReactElement, useCallback, useState } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { hasPresentKey } from "ts-is-present";
import {
  CancelRecurringFeeConfirmModal,
  CancelRecurringFeeConfirmModalData
} from "../CancelRecurringFeeConfirmModal";
import {
  FeeBlueprintType,
  GetManagementFeeBlueprintsDocument,
  GetManagementFeeBlueprintsQuery,
  PlatformFeatureId,
  useArchiveManagementFeeBlueprintMutation,
  useGetManagementFeeBlueprintsQuery,
  useGetManagementRecurringFeesQuery
} from "local/graphql";
import { ManagementFeeListItem } from "./ManagementFeeListItem";
import { AutomaticFeeListItem } from "./AutomaticFeeListItem";
import { useAnalytics, ViewAllButton } from "local/common";
import { useHasFeature } from "@ailo/domains";
import { ManagementFeeBlueprintsListItem } from "../managementFeeBlueprint";

interface AutomaticFeeListProps {
  managementId: string;
  itemsLimit?: number;
  onViewMoreExpensesPress?: () => void;
  /**
   * TODO Once ManagementFeeBlueprint feature toggle is enabled everywhere,
   * `onEdit` can become mandatory and the edit modal in this component
   * can be removed.
   */
  onEditRecurringFee?: (fee: EditExpenseModalFormData) => void;
  /**
   * `medium` by default.
   */
  onEditEventBasedFeeBlueprint?: (
    blueprint: NonNullable<
      GetManagementFeeBlueprintsQuery["managementFeeBlueprints"]
    >["items"][number]
  ) => void;
  variant?: "medium" | "sidebar";
  style?: StyleProp<ViewStyle>;
}

export function AutomaticFeesList({
  managementId,
  itemsLimit = 3,
  onViewMoreExpensesPress,
  onEditRecurringFee,
  onEditEventBasedFeeBlueprint,
  variant = "medium",
  style
}: AutomaticFeeListProps): ReactElement {
  const canManageEventBasedFees = useHasFeature(
    PlatformFeatureId.EventBasedFee
  );

  const { confirm } = useAlertContext();

  const [modalData, setModalData] =
    useState<CancelRecurringFeeConfirmModalData>();
  const onModalDismiss = (): void => setModalData(undefined);

  const [editExpenseModalData, setEditExpenseModalData] = useState<
    EditExpenseModalFormData | undefined
  >(undefined);
  const closeEditExpenseModal = (): void => setEditExpenseModalData(undefined);

  const [archive] = useArchiveManagementFeeBlueprintMutation({
    refetchQueries: [
      {
        query: GetManagementFeeBlueprintsDocument,
        variables: {
          conditions: {
            managementId,
            archived: false,
            type: FeeBlueprintType.EventBasedFee
          }
        }
      }
    ]
  });

  const analytics = useAnalytics();
  const toast = useToastContext();

  const recurringFeesResult = useGetManagementRecurringFeesQuery({
    variables: {
      managementId,
      managementAiloRN: AiloRN.of(
        services.PropertyManagement.management,
        managementId
      ).toString()
    }
  });

  const eventBasedFeesResult = useGetManagementFeeBlueprintsQuery({
    skip: !canManageEventBasedFees,
    variables: {
      conditions: {
        managementId,
        archived: false,
        type: FeeBlueprintType.EventBasedFee
      }
    }
  });

  const { refetch: refetchRecurringFees } = recurringFeesResult;
  const { refetch: refetchEventBasedFees } = eventBasedFeesResult;

  const refetch = useCallback(() => {
    refetchRecurringFees();
    refetchEventBasedFees();
  }, [refetchEventBasedFees, refetchRecurringFees]);

  useOnFocus(refetch);

  const onRemoveEventBasedFeeBlueprint = useCallback(
    async (managementFeeBlueprintId: string) => {
      const response = await archive({
        variables: { managementFeeBlueprintId }
      });
      if (response.data?.updateManagementFeeBlueprint) {
        toast.show({
          type: "success",
          message: `${response.data.updateManagementFeeBlueprint.feeBlueprint.name.trim()} template removed`
        });
        analytics.track("Fee Blueprint Removed from Property Schedule", {
          feeBlueprintId: response.data?.updateManagementFeeBlueprint.id,
          managementId
        });
        refetchEventBasedFees();
      } else {
        response.errors && AiloSentry.captureException(response.errors);
        toast.show({
          type: "error",
          message: "Could not remove event based fee trigger"
        });
      }
    },
    [analytics, archive, managementId, refetchEventBasedFees, toast]
  );

  if (
    didQueryNotLoadYet(recurringFeesResult) ||
    (canManageEventBasedFees && didQueryNotLoadYet(eventBasedFeesResult))
  ) {
    return <AutomaticFeesList.Loading style={style} />;
  }

  if (
    !didQuerySucceed(recurringFeesResult) ||
    (canManageEventBasedFees && !didQuerySucceed(eventBasedFeesResult))
  ) {
    return (
      <ErrorAlertScreen
        title={"There was a problem loading Expenses"}
        onRetry={refetch}
        variant={variant}
      />
    );
  }

  const managementFee =
    recurringFeesResult.data.management?.currentOrNextManagementFeeSchedule;
  const recurringFees = (recurringFeesResult.data.recurringFees ?? [])
    .filter(hasPresentKey("currentOrNextSchedule"))
    .slice(0, itemsLimit - (managementFee ? 1 : 0));
  const eventBasedManagementFeeBlueprints = canManageEventBasedFees
    ? eventBasedFeesResult.data?.managementFeeBlueprints?.items
    : undefined;

  if (
    !managementFee &&
    !recurringFees.length &&
    !eventBasedManagementFeeBlueprints?.length
  ) {
    return (
      <AlertScreen
        type={"plant-and-house-and-document"}
        description={"No recurring expenses have been added yet"}
      />
    );
  }

  return (
    <Container style={style}>
      <View>
        {managementFee && (
          <ManagementFeeListItem
            key={"management-fee"}
            fee={managementFee}
            onEditPress={
              onEditRecurringFee
                ? () =>
                    onEditRecurringFee({
                      ...managementFee,
                      expenseType: "ManagementFeeSchedule"
                    })
                : () =>
                    setEditExpenseModalData({
                      ...managementFee,
                      expenseType: "ManagementFeeSchedule"
                    })
            }
          />
        )}

        {eventBasedManagementFeeBlueprints?.map((blueprint, index) => (
          <ManagementFeeBlueprintsListItem
            key={index}
            edit={() => {
              onEditEventBasedFeeBlueprint?.(blueprint);
            }}
            remove={async () => {
              await confirm({
                title: `Remove ${blueprint.feeBlueprint.name
                  .trim()
                  .toLowerCase()} event based fee trigger`,
                message:
                  "Any fees that have already been charged from this event based fee trigger will not be affected. To cancel an existing fee, navigate to the expenses section.",
                confirmLabel: "Remove",
                destructive: true,
                onConfirm: () =>
                  void onRemoveEventBasedFeeBlueprint(blueprint.id)
              });
            }}
            managementFeeBlueprint={blueprint}
          />
        ))}

        {recurringFees.map((fee) => (
          <AutomaticFeeListItem
            key={fee.ailorn}
            fee={fee}
            onEditPress={
              onEditRecurringFee
                ? () =>
                    onEditRecurringFee({
                      ...fee,
                      expenseType: "RecurringFee"
                    })
                : () =>
                    setEditExpenseModalData({
                      ...fee,
                      expenseType: "RecurringFee"
                    })
            }
            onCancelPress={
              fee.nextOccurrence
                ? (): void => {
                    setModalData({
                      feeAiloRN: fee.ailorn,
                      feeName: fee.blueprint.name,
                      managementId,
                      amount: fee.currentOrNextSchedule.taxInclusiveAmount,
                      blueprintId: fee.blueprint.id,
                      blueprintAmount:
                        fee.blueprint.fixedAmount ?? Money.zero(),
                      frequency: fee.frequency
                    });
                  }
                : undefined
            }
          />
        ))}
      </View>
      {onViewMoreExpensesPress && (
        <ViewAllButton
          onPress={onViewMoreExpensesPress}
          style={{ marginHorizontal: 16, marginTop: 16 }}
        />
      )}
      <CancelRecurringFeeConfirmModal
        data={modalData}
        onDismiss={onModalDismiss}
      />
      <EditExpenseModalFormController
        expense={editExpenseModalData}
        onDismiss={closeEditExpenseModal}
      />
    </Container>
  );
}

AutomaticFeesList.Loading = function Loading({
  itemsLimit = 3,
  style
}: {
  itemsLimit?: number;
  style?: StyleProp<ViewStyle>;
}): ReactElement {
  const listItems = [];
  for (let i = 0; i < itemsLimit; i++) {
    const color = opacify(
      Colors.CHARCOAL,
      0.75 * ((itemsLimit - i) / itemsLimit)
    );
    listItems.push(
      <AutomaticFeeListItem.Loading
        key={i}
        color={color}
        style={{ borderType: "none" }}
      />
    );
  }

  return (
    <Container style={style}>
      <View>{listItems}</View>
    </Container>
  );
};

const Container = styled(View)`
  flex-grow: 1;
  justify-content: space-between;
`;
