import { View } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { CreateReceiptFormData } from "../../types";
import { FormField, SelectInputWithSearch, SelectOption } from "@ailo/ui";
import React, { useRef } from "react";

import { WalletSearchMode } from "local/tabs";
import { useTrustAccountWalletsSearch } from "./useTrustAccountWalletSearch";

interface TrustAccountWalletInputProps {
  onOptionSelect?: (
    option: SelectOption | undefined,
    searchString: string
  ) => void;
  onEmptyOptionsShow?: (searchString: string) => void;
  onFocus?: () => void;
  label: string;
  mode: WalletSearchMode;
}

export function TrustAccountWalletInput({
  onOptionSelect,
  onEmptyOptionsShow,
  onFocus,
  label,
  mode
}: TrustAccountWalletInputProps): React.ReactElement {
  const { control } = useFormContext<CreateReceiptFormData>();
  const { searchWallets, error, options } = useTrustAccountWalletsSearch(mode);

  const inputRef = useRef<any>(null);
  return (
    <View>
      <Controller
        control={control}
        name={"targetWallet"}
        defaultValue={""}
        render={({ value, onChange, onBlur, ref }) => (
          <FormField {...{ value, onBlur, inputRef: ref }} label={label}>
            <SelectInputWithSearch
              {...{ value }}
              onChange={async (option?: SelectOption) => {
                onChange({
                  ...option,
                  // react-hook-form doesn't like not primitive values
                  // - it goes in an infinite call stack trace if given react nodes
                  // - so let's nullify them before setting the value
                  RightComponent: undefined
                });
              }}
              inputRef={inputRef}
              options={options}
              onInputFinish={searchWallets}
              isError={!!error}
              placeholder={"Search wallets..."}
              onFocus={(): void => {
                onFocus?.();
              }}
              onOptionSelect={(
                option: SelectOption | undefined,
                searchString: string
              ): void => {
                onOptionSelect?.(option, searchString);
              }}
              onEmptyOptionsShow={(searchString): void => {
                onEmptyOptionsShow?.(searchString);
              }}
              isClearable
              optionStyle={
                mode === WalletSearchMode.TENANCY
                  ? {
                      height: 60
                    }
                  : undefined
              }
              value={
                value &&
                ((options || []).find((opt) => opt.value === value.value) ??
                  value)
              }
            />
          </FormField>
        )}
        rules={{
          required: true
        }}
      />
    </View>
  );
}
