import { GetAgencyTrustAccountsQuery } from "local/graphql";
import { BankAccountDetails } from "./BankAccountDetailsBox";

function parseTrustBankAccounts(
  data?: GetAgencyTrustAccountsQuery
): BankAccountDetails | undefined {
  const accounts = data?.getTrustAccounts;

  if (accounts === undefined || accounts.length === 0) {
    return undefined;
  }
  const account = accounts[0].paymentMethod?.paymentMethod;
  if (account?.__typename !== "BankAccount") {
    return undefined;
  }
  if (!account?.accountNumber || !account?.bsb) {
    return undefined;
  }
  return {
    accountNumber: account.accountNumber,
    bsb: account.bsb
  };
}

export { parseTrustBankAccounts };
