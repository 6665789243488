import React, { FunctionComponent } from "react";
import {
  ActionCellLoading,
  BadgeCellLoading,
  CellContainer,
  LocalDate,
  Money,
  TextCell
} from "@ailo/ui";
import { RightChevronIcon } from "@ailo/primitives";
import { View } from "react-native";
import { ReconciliationReportPeriodFragmentFragment } from "local/graphql";
import { LockedStatusBadge } from "../components";
import { monthNumberToName } from "../utils";

function BankStatementBalanceCell({
  row
}: {
  row: ReconciliationReportPeriodFragmentFragment;
}): React.ReactElement {
  let balanceText = "—";
  if (row.lastAddedStatementBalance?.amountInCents != null) {
    balanceText = Money.fromCents(
      row.lastAddedStatementBalance.amountInCents
    ).format();
  }
  return <TextCell>{balanceText}</TextCell>;
}

function BankStatementDateCell({
  row
}: {
  row: ReconciliationReportPeriodFragmentFragment;
}): React.ReactElement {
  let dateText = "—";
  if (row.lastAddedStatementBalance?.date) {
    dateText = LocalDate.from(row.lastAddedStatementBalance.date).format(
      "DD MMM YYYY"
    );
  }
  return <TextCell>{dateText}</TextCell>;
}

function Status({
  row
}: {
  row: ReconciliationReportPeriodFragmentFragment;
}): React.ReactElement {
  return (
    <CellContainer>
      <LockedStatusBadge
        style={{ alignSelf: "flex-start" }}
        locked={row.locked}
      />
    </CellContainer>
  );
}

function Month({
  row
}: {
  row: ReconciliationReportPeriodFragmentFragment;
}): React.ReactElement {
  return (
    <TextCell>
      {monthNumberToName(row.month)} {row.year}
    </TextCell>
  );
}

function RightArrow(): React.ReactElement {
  return (
    <CellContainer>
      <RightChevronIcon width={20} height={20} />
    </CellContainer>
  );
}

export const columns = [
  { name: "Month", renderCell: Month, loading: TextCell.Loading },
  {
    name: "Bank statement balance",
    renderCell: BankStatementBalanceCell,
    loading: TextCell.Loading
  },
  {
    name: "Bank statement date",
    renderCell: BankStatementDateCell,
    loading: TextCell.Loading
  },
  {
    name: "Status",
    renderCell: Status,
    loading: BadgeCellLoading,
    maxWidth: 184
  },
  {
    key: "Arrow",
    renderCell: RightArrow,
    loading: ActionCellLoading,
    maxWidth: 52,
    horizontalAlign: "right" as const
  }
];

export const loadingColumns = columns.map((column) => {
  const Loading: FunctionComponent = column.loading;
  const renderCell = ({
    row: { opacity }
  }: {
    row: { opacity: number };
  }): React.ReactElement => (
    <View style={{ opacity }}>
      <Loading />
    </View>
  );
  return { ...column, renderCell };
});

export const loadingRows = [...Array(5)].map((_, index) => ({
  key: index,
  opacity: Math.max(0.75 - index * 0.25, 0)
}));
