import { SFC } from "@ailo/primitives";
import { formatDateDefaultToNA } from "@ailo/services";
import { DescriptionList } from "@ailo/ui";
import React from "react";
import { getAgreementDatesDescriptionListItems } from "./getAgreementDatesDescriptionListItems";
import { WarningIcon } from "./icons";
import { SectionHeader } from "./sections";

interface Props {
  tenancyEndDate?: string | null;
  agreementStartDate?: string | null;
  fixedTermAgreementEndDate?: string | null;
  agreementAllowedToLapse: boolean;
  nextAgreementStartDate?: string | null;
  newTenancyStartDate?: string | null;
}

export const VacatingDetails: SFC<Props> = ({
  tenancyEndDate,
  agreementStartDate,
  fixedTermAgreementEndDate,
  agreementAllowedToLapse,
  nextAgreementStartDate,
  newTenancyStartDate
}) => {
  return (
    <>
      <SectionHeader
        icon={WarningIcon}
        title={"Vacating"}
        style={{ marginBottom: 12 }}
      />
      <DescriptionList
        size={"small"}
        items={[
          {
            label: "Tenancy ending",
            description: formatDateDefaultToNA(tenancyEndDate)
          },
          ...getAgreementDatesDescriptionListItems({
            agreementStartDate,
            fixedTermAgreementEndDate,
            agreementAllowedToLapse,
            nextAgreementStartDate
          }),
          ...(newTenancyStartDate
            ? [
                {
                  label: "New tenancy start",
                  description: formatDateDefaultToNA(newTenancyStartDate)
                }
              ]
            : [])
        ]}
      />
    </>
  );
};
