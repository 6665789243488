import React, { useCallback } from "react";
import { Button, ButtonProps, openUrl, useToastContext } from "@ailo/ui";
import { useGetFormUrl } from "./useGetFormUrl";
import { useReiFormUrlButton } from "./useReiFormUrlButton";

export function ReiFormViewButton({
  formId,
  disabled,
  children,
  ...props
}: ButtonProps & { formId: string }): React.ReactElement {
  const getFormUrl = useGetFormUrl(formId, "view");

  const toast = useToastContext();

  const onError = useCallback(() => {
    toast.show({
      type: "error",
      message: "Unable to view form. Please try again."
    });
  }, [toast]);

  const { loading, onPressReiFormButton } = useReiFormUrlButton({
    getFormUrl,
    onGetFormUrlComplete: openUrl,
    onError
  });

  return (
    <Button {...props} loading={loading} onPress={onPressReiFormButton}>
      {children}
    </Button>
  );
}
