import { useCurrentAgencyOrg } from "local/common";
import React from "react";
import { dateForSort, floatForSort, useReportTeamContext } from "../..";
import { useGetCentrepayReportQuery } from "local/graphql";
import { ReportPage } from "../../components";
import { FilterComponentType } from "../../components/ReportPage/FilterFactory";
import { propertyRowLink } from "../../components/ReportPage/utils/tableNavigation";
import { reports } from "../../reportsDetails";
import { useReportQuery } from "local/tabs";
import { RenderDualValueCell } from "../../components/RenderDualValueCell";

export function CentrepayTable(): React.ReactElement {
  const { id } = useCurrentAgencyOrg();
  const { teamId } = useReportTeamContext();
  // get data
  const queryResult = useReportQuery(useGetCentrepayReportQuery, {
    managingOrgId: id,
    teamId
  });
  const rows = queryResult.data?.centrepayReport?.rows;
  return (
    <ReportPage
      title={reports.centrepay.reportHeading}
      data={{
        ...queryResult,
        rows
      }}
      columnsConfig={{
        renterNames: { header: "Renter(s)" },
        propertyAddress: { header: "Property address" },
        crnReference: {
          header: "CRN",
          renderCell({ row }) {
            return (
              <RenderDualValueCell
                mainValue={row.crnReference}
                BadgeValue={row.crnStatus}
                dualCellType={"crn"}
                tooltipText={"Deleted " + row.crnDeletedDate}
              />
            );
          }
        },
        tenancyStatus: {
          header: "Tenancy status",
          badge: {
            valueColorMapping: [
              {
                cellValue: "Former",
                badgeType: "default"
              },
              {
                cellValue: "Active",
                badgeType: "success"
              },
              {
                cellValue: "Ingoing",
                badgeType: "attention"
              },
              {
                cellValue: "Unknown",
                badgeType: "critical"
              }
            ]
          }
        },
        latestTransactionDate: {
          header: "Latest payment date",
          sortValue: (r) => dateForSort(r.latestTransactionDate)
        },
        latestTransactionAmount: {
          header: "Latest payment amount",
          horizontalAlign: "right",
          sortValue: (r) => floatForSort(r.latestTransactionAmount)
        }
      }}
      filterConfig={[
        {
          name: "search",
          columns: ["crnReference", "renterNames"],
          type: FilterComponentType.Search,
          defaultValue: "",
          placeholder: "Search properties or CRN"
        },
        {
          type: FilterComponentType.Team,
          defaultValue: "",
          columns: [],
          name: "teamFilter"
        }
      ]}
      defaultSort={{ columnKey: "latestTransactionDate", direction: "DESC" }}
      rowLink={propertyRowLink}
    />
  );
}
