import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { AFC } from "@ailo/primitives";
import { Badge } from "@ailo/ui";
import { StatusBadge } from "./StatusBadge";

interface Statics {
  Loading: React.FC;
}

/**
 * @deprecated Use `ExpenseStatusBadge` instead
 */
const Status: AFC<React.ComponentProps<typeof StatusBadge>> & Statics = ({
  style,
  ...rest
}) => {
  return (
    <Container style={style}>
      <StatusBadge {...rest} />
    </Container>
  );
};

const Container = styled(View)`
  flex-direction: row;
`;

const Loading: AFC = () => {
  return (
    <Container>
      <Badge.Loading width={80} />
    </Container>
  );
};

Status.Loading = Loading;

export { Status };
