import { Alert, Button } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useFieldArray, UseFormMethods } from "react-hook-form";
import { NewTenancyFormData } from "../NewTenancyForm";
import { TenantEntry } from "./TenantEntry";
import { AddOrEditTenantModalForm } from "./AddOrEditTenantModalForm";
import { TenantDetails } from "../../useCreateTenancy";
import { View } from "react-native";
import { useAnalytics } from "@ailo/services";

enum FormModes {
  Add = "Add",
  Edit = "Edit"
}

export function TenantDetailsStep({
  form,
  display
}: {
  form: UseFormMethods<NewTenancyFormData>;
  display: boolean;
}): ReactElement {
  const analytics = useAnalytics();
  useEffect(() => {
    if (display === true) {
      analytics.trackScreenVisited("New Tenancy — Renters");
    }
  }, [display, analytics]);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState<
    | {
        formMode: FormModes.Add;
      }
    | { formMode: FormModes.Edit; editTenantIndex: number }
    | null
  >(null);

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "tenants"
  });

  const addTenant = useCallback(
    (tenantDetails: TenantDetails): void => {
      append({
        name: "tenants",
        details: tenantDetails
      });
      setModalVisible(false);
      form.clearErrors("tenants");
    },
    [append, form]
  );

  const editTenant = useCallback(
    (tenantDetails: TenantDetails, index: number): void => {
      form.setValue(`tenants[${index}].details`, tenantDetails);
      setModalVisible(false);
    },
    [form]
  );

  return (
    <View style={{ display: display ? undefined : "none" }}>
      <Text.BodyL weight={"medium"} style={{ marginBottom: 8 }}>
        {"Renters"}
      </Text.BodyL>
      <Text.BodyM
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginBottom: 24 }}
      >
        {
          "Please add all the renters connected to this new tenancy, ensuring you include at least one renter with an email address to access Ailo."
        }
      </Text.BodyM>
      {form.errors.tenants?.message && (
        <Alert
          style={{ marginBottom: 24 }}
          type={"error"}
          message={form.errors.tenants?.message}
        />
      )}
      {fields.map((item, index) => {
        return (
          <TenantEntry
            key={item.id}
            initialTenantDetails={item.details}
            index={index}
            form={form}
            deleteButtonOnPress={(): void => remove(index)}
            editButtonOnPress={(): void => {
              setModalVisible(true);
              setModalMode({
                formMode: FormModes.Edit,
                editTenantIndex: index
              });
            }}
            style={{ marginBottom: 12 }}
          />
        );
      })}
      <Button.Secondary
        onPress={(): void => {
          setModalVisible(true);
          setModalMode({ formMode: FormModes.Add });
        }}
        style={fields.length > 0 ? { marginTop: 4 } : {}}
      >
        {"Add Renter"}
      </Button.Secondary>
      {modalVisible && modalMode && (
        <AddOrEditTenantModalForm
          otherTenantEmails={fields
            .map((tenant) => tenant.details.email)
            .filter((email, index) => {
              if (
                modalMode.formMode === FormModes.Edit &&
                modalMode.editTenantIndex === index
              )
                return false;
              return !!email;
            })}
          onSubmit={(tenantDetails: TenantDetails): void => {
            if (modalMode.formMode === FormModes.Add) {
              addTenant(tenantDetails);
            } else {
              editTenant(tenantDetails, modalMode.editTenantIndex);
            }
          }}
          onCancel={(): void => setModalVisible(false)}
          confirmText={
            modalMode.formMode === FormModes.Add ? "Add Renter" : "Edit Renter"
          }
          defaultValues={
            modalMode.formMode === FormModes.Edit
              ? form.getValues(`tenants[${modalMode.editTenantIndex}].details`)
              : undefined
          }
        />
      )}
    </View>
  );
}
