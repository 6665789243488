import { Text, useSynchronisedLoad } from "@ailo/primitives";
import {
  ErrorAlertScreen,
  LabelledTextInput,
  RadioButtonGroup
} from "@ailo/ui";
import React, { ReactElement, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { View } from "react-native";
import styled from "styled-components/native";

import {
  LeaseRenewalData,
  useGetLeaseRenewalData
} from "../useGetLeaseRenewalData";

import { LeaseType, LeaseRenewalFormData } from "../LeaseRenewalFormData";
import { LeaseRenewalSection } from "../LeaseRenewalSection";
import { Action } from "local/domain/project";
import { TenancyAgreementFragment, ActionType } from "local/graphql";
interface Props {
  action?: Action<ActionType.AssignTenancyAgreement>;
  tenancyId: string;
  agreement?: TenancyAgreementFragment;
  prefillStartDate?: string;
}

const Container = styled(View)`
  flex: 1;
`;

const FormContainer = styled(View)`
  padding: 0 32px 60px;
`;

function CreateLeaseRenewalFormWithData({
  action,
  currentAgreementFixedTermEndDate,
  currentAgreementIsAllowedToLapse,
  currentAgreementStartDate,
  nextTenancyAgreement,
  prefillStartDate,
  rentReviewAllowed,
  tenancyEndDate,
  tenancyId,
  agreement
}: Props & LeaseRenewalData): ReactElement {
  const { formState, register, setValue } =
    useFormContext<LeaseRenewalFormData>();
  useEffect(() => {
    register({ name: "tenancyId", value: tenancyId });
    register({
      name: "currentAgreementFixedTermEndDate",
      value: currentAgreementFixedTermEndDate
    });

    if (!formState.isDirty && agreement) {
      const isPeriodic = !agreement?.fixedTermEndDate;

      if (isPeriodic) {
        setValue("leaseType", LeaseType.Periodic);
      }

      register({
        name: "agreementId",
        value: agreement.id
      });

      if (agreement.startDate) {
        setValue("agreementStartDate", agreement.startDate);
      }

      if (agreement.fixedTermEndDate) {
        setValue("agreementFixedTermEndDate", agreement.fixedTermEndDate, {
          shouldDirty: true
        });
      }
    }
  }, [
    currentAgreementStartDate,
    currentAgreementFixedTermEndDate,
    tenancyId,
    register,
    formState.isDirty,
    nextTenancyAgreement,
    setValue,
    agreement
  ]);

  useEffect(() => {
    if (!formState.isDirty && prefillStartDate) {
      setValue("agreementStartDate", prefillStartDate);
    }
  }, [formState.isDirty, prefillStartDate, setValue]);

  return (
    <Container>
      <FormContainer>
        <LeaseRenewalSection
          action={action}
          currentAgreementFixedTermEndDate={currentAgreementFixedTermEndDate}
          currentAgreementIsAllowedToLapse={currentAgreementIsAllowedToLapse}
          currentAgreementStartDate={currentAgreementStartDate}
          nextTenancyAgreement={nextTenancyAgreement}
          rentReviewAllowed={rentReviewAllowed}
          tenancyEndDate={tenancyEndDate}
        />
      </FormContainer>
    </Container>
  );
}

CreateLeaseRenewalStep.Loading = function Loading(): ReactElement {
  return (
    <View>
      <RadioButtonGroup.Loading
        style={{ marginTop: 26 }}
        options={[{ textWidth: 120 }, { textWidth: 150 }, { textWidth: 290 }]}
      />
      <Text.BodyS.Loading width={120} style={{ marginTop: 34 }} />
      <View
        style={{
          marginTop: 20,
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <LabelledTextInput.Loading style={{ flex: 1, marginRight: 16 }} />
        <LabelledTextInput.Loading style={{ flex: 1 }} />
      </View>
    </View>
  );
};

CreateLeaseRenewalStep.Error = function Error({
  onRetry
}: {
  onRetry: () => void;
}): React.ReactElement {
  return (
    <Container>
      <FormContainer style={{ flex: 1, justifyContent: "center" }}>
        <ErrorAlertScreen
          variant={"medium"}
          title={"There was a problem loading\nLease Renewal"}
          onRetry={onRetry}
        />
      </FormContainer>
    </Container>
  );
};

function CreateLeaseRenewalFormContent({
  action,
  tenancyId,
  agreement,
  prefillStartDate
}: {
  action?: Action<ActionType.AssignTenancyAgreement>;
  tenancyId: string;
  agreement?: TenancyAgreementFragment;
  prefillStartDate?: string;
}): ReactElement {
  const { data, loading, refetch } = useGetLeaseRenewalData(tenancyId);
  const isLoading = useSynchronisedLoad(loading && !data);

  if (isLoading) return <CreateLeaseRenewalStep.Loading />;

  if (!data) {
    return <CreateLeaseRenewalStep.Error onRetry={refetch} />;
  }
  return (
    <CreateLeaseRenewalFormWithData
      action={action}
      tenancyId={tenancyId}
      agreement={agreement}
      prefillStartDate={prefillStartDate}
      {...data}
    />
  );
}

export function CreateLeaseRenewalStep({
  action,
  tenancyId,
  agreement,
  display,
  prefillStartDate
}: {
  action?: Action<ActionType.AssignTenancyAgreement>;
  tenancyId: string;
  agreement?: TenancyAgreementFragment;
  display: boolean;
  prefillStartDate?: string;
}): ReactElement {
  return (
    <View style={{ display: display ? undefined : "none" }}>
      <CreateLeaseRenewalFormContent
        action={action}
        tenancyId={tenancyId}
        agreement={agreement}
        prefillStartDate={prefillStartDate}
      />
    </View>
  );
}
