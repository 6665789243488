import { formatPercentage, LocalDate } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Text, Colors } from "@ailo/primitives";
import { InputDetailsSection } from "../../InputDetailsSection";

interface Props {
  agreementStartDate?: string | null;
  agreementEndDate?: string | null;
  managementFeePercent?: number | null;
}

export function AgreementDetailsSection({
  agreementStartDate,
  agreementEndDate,
  managementFeePercent
}: Props): ReactElement {
  const descriptionListData = [
    {
      label: "Agreement start",
      description: agreementStartDate
        ? new LocalDate(agreementStartDate).format("DD MMM YYYY")
        : "N/A"
    },
    {
      label: "Agreement end",
      description: agreementEndDate
        ? new LocalDate(agreementEndDate).format("DD MMM YYYY")
        : "N/A"
    },
    {
      label: "Management fee percent",
      description: managementFeePercent
        ? formatPercentage(managementFeePercent)
        : "N/A"
    }
  ];

  return (
    <InputDetailsSection
      title={
        <Text.BodyL color={Colors.TEXT.DARK.PRIMARY} weight={"medium"}>
          {"Agreement details"}
        </Text.BodyL>
      }
      descriptionListData={descriptionListData}
    />
  );
}
