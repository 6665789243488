import { useCurrentAgencyOrg } from "local/common";
import {
  IncludeMigratingManagementsMutation,
  useIncludeMigratingManagementsMutation,
  GetPagedExcludedMigratingManagementsDocument
} from "local/graphql";
import { PROPERTY_ONBOARDING_LIST_PAGE_SIZE } from "./usePaginationRequest";
import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { useCallback } from "react";
import { MutationResult } from "@apollo/client";
import { PropertyOnboardingListRequestParams } from "../types";

interface IncludeMigratingManagementsProps {
  selectedMigratingManagementIds: string[];
  onSuccess: (result: IncludeMigratingManagementsMutation) => void;
  onError: () => void;
  lastRequestParams: PropertyOnboardingListRequestParams | null;
}

type IncludeMigratingManagements = [
  () => void,
  MutationResult<IncludeMigratingManagementsMutation>
];

export function useIncludeMigratingManagements({
  selectedMigratingManagementIds,
  onSuccess,
  onError,
  lastRequestParams
}: IncludeMigratingManagementsProps): IncludeMigratingManagements {
  const analytics = useAnalytics();
  const currentOrg = useCurrentAgencyOrg();

  const [includeMigratingManagementsMutation, mutationResult] =
    useIncludeMigratingManagementsMutation({
      variables: { migratingManagementIds: selectedMigratingManagementIds },
      refetchQueries: [
        {
          query: GetPagedExcludedMigratingManagementsDocument,
          variables: {
            cursor: lastRequestParams?.cursor ?? null,
            before: lastRequestParams?.before ?? false,
            pageSize: PROPERTY_ONBOARDING_LIST_PAGE_SIZE,
            organisationId: currentOrg.ailoRN,
            sort: lastRequestParams?.sort ?? []
          }
        }
      ]
    });

  const includeMigratingManagements = useCallback(async () => {
    try {
      analytics.track("Multiple Migrating Properties Included");

      const result = await includeMigratingManagementsMutation();
      throwIfMutationFailed(result, { dataKey: "includeMigratingManagements" });

      if (!result.data) {
        onError();
        return;
      }

      onSuccess(result.data);
    } catch (error) {
      AiloSentry.captureException(error);
      onError();
    }
  }, [includeMigratingManagementsMutation, onSuccess, onError, analytics]);

  return [includeMigratingManagements, mutationResult];
}
