import moment from "moment";
import { LoadingSynchroniser } from "@ailo/primitives";
import { ErrorModal } from "@ailo/ui";
import { MultiStepForm } from "local/common";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { isPresent } from "ts-is-present";
import { EndTenancyFormContext } from "./EndTenancyFormContext";
import { EndTenancyFormData, EndTenancyFormFields } from "./EndTenancyFormData";
import { EndTenancyStep } from "./EndTenancyStep";
import { ReviewEndTenancyStep } from "./ReviewEndTenancyStep/ReviewEndTenancyStep";
import { useEndTenancy } from "./useEndTenancy";
import { useGetDataForEndTenancy } from "./useGetDataForEndTenancy";
import { useGetAmountDue } from "./useGetAmountDue";
import { EndTenancyConfirmModal } from "./EndTenancyConfirmModal";

interface Props {
  tenancyId: string;
  onDismiss: () => void;
  onSuccess: () => void;
}

type StepIds = "Create" | "Review";

type Step = {
  stepId: StepIds;
  render: (visible: boolean) => React.ReactElement;
};

export function EndTenancyForm({ ...props }: Props): ReactElement {
  return (
    <LoadingSynchroniser>
      <EndTenancyFormContext>
        <EndTenancyFormMultistepForm {...props} />
      </EndTenancyFormContext>
    </LoadingSynchroniser>
  );
}

export function EndTenancyFormMultistepForm({
  tenancyId,
  onDismiss,
  onSuccess
}: Props): ReactElement {
  const {
    data: endTenancyData,
    loading,
    error,
    refetch
  } = useGetDataForEndTenancy(tenancyId);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentStepNumber, setCurrentStepNumber] = useState<number>(1);
  const { requestForAmountDueAtDate, amount: amountDueAtEndDate } =
    useGetAmountDue();
  const form = useFormContext<EndTenancyFormData>();

  const { formState, trigger, handleSubmit, register, watch } = form;
  const tenancyEndDate = watch(EndTenancyFormFields.endDate);

  useEffect(() => {
    if (endTenancyData?.liabilityId) {
      requestForAmountDueAtDate({
        date: moment(tenancyEndDate),
        liabilityId: endTenancyData.liabilityId
      });
    }
  }, [tenancyEndDate, endTenancyData?.liabilityId, requestForAmountDueAtDate]);
  useEffect(() => {
    register({ name: "tenancyId", value: tenancyId });
  }, [tenancyId, register]);

  const { isSubmitting, isDirty } = formState;

  const submit = handleSubmit(
    useSubmit({
      onCompleted: async () => {
        await onSuccess();
      },
      onError: () => {
        setShowErrorModal(true);
      }
    })
  );

  const renderStepCreate = useCallback(
    (visible: boolean): ReactElement => (
      <EndTenancyStep display={visible} endTenancyData={endTenancyData} />
    ),
    [endTenancyData]
  );

  const renderStepReview = useCallback(
    (visible: boolean): ReactElement => (
      <ReviewEndTenancyStep
        display={visible}
        onEditPress={(): void => setCurrentStepNumber(1)}
        endTenancyData={endTenancyData}
        amountDueAtEndDate={amountDueAtEndDate}
      />
    ),
    [endTenancyData, amountDueAtEndDate]
  );

  const steps = [
    {
      stepId: "Create",
      render: renderStepCreate
    },
    {
      stepId: "Review",
      render: renderStepReview
    }
  ].filter(isPresent) as Step[];

  const onNextButtonPress = useCallback(
    async (newStepNumber: number) => {
      if (newStepNumber < currentStepNumber) {
        setCurrentStepNumber(newStepNumber);
        return;
      }
      const currentStepId = steps[currentStepNumber - 1].stepId;

      if (currentStepId === "Create") {
        const isValid = await trigger();
        if (isValid) {
          setCurrentStepNumber(newStepNumber);
        }
      }
    },
    [currentStepNumber, steps, trigger]
  );
  if (loading) {
    return <EndTenancyStep.Loading />;
  }

  if (error || !endTenancyData) {
    return <EndTenancyStep.Error onRetry={refetch} />;
  }

  return (
    <>
      <ErrorModal
        visible={showErrorModal}
        onDismiss={() => setShowErrorModal(false)}
      />
      <MultiStepForm
        style={{
          maxWidth: 680,
          width: "100%",
          flex: 1,
          alignSelf: "flex-end"
        }}
        title={"End the tenancy"}
        submitButtonText={"Save"}
        stepValue={currentStepNumber}
        onChange={onNextButtonPress}
        onCancel={onDismiss}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        steps={steps.map((step) => step.render)}
        headerBackButtonDisabled
        hideCancelButton
        onSubmit={() => {
          setShowConfirmModal(true);
        }}
      />
      <EndTenancyConfirmModal
        visible={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={submit}
        loading={isSubmitting}
        property={endTenancyData.property}
        showDepositFundsWarning={endTenancyData.showDepositFundsWarning}
        amountDueAtEndDate={amountDueAtEndDate}
      />
    </>
  );
}

function useSubmit({
  onCompleted,
  onError
}: {
  onCompleted?: () => void;
  onError?: () => void;
}): (data: EndTenancyFormData) => Promise<void> {
  const [endTenancy] = useEndTenancy({
    onCompleted,
    onError
  });

  return useCallback(
    async (data: EndTenancyFormData) => {
      return endTenancy({
        formData: {
          ...data
        }
      });
    },
    [endTenancy]
  );
}
