import moment from "moment";

export function groupStatementsByMonth<
  TStatement extends {
    rangeEndDate: string;
  }
>(statements: TStatement[]): { title: string; data: TStatement[] }[] {
  return statements
    .reduce((groups: TStatement[][], statement: TStatement) => {
      const lastGroup = groups[groups.length - 1];

      if (
        groups.length === 0 ||
        !monthAndYearMatches(lastGroup[0].rangeEndDate, statement.rangeEndDate)
      ) {
        groups.push([]);
      }

      groups[groups.length - 1].push(statement);

      return groups;
    }, [])
    .map((group) => ({
      title: formatDate(group[0].rangeEndDate),
      data: group
    }));
}

function monthAndYearMatches(dateA: string, dateB: string): boolean {
  return (
    moment(dateA).isSame(dateB, "month") && moment(dateA).isSame(dateB, "year")
  );
}

function formatDate(date: string): string {
  const format = moment().isSame(date, "year") ? "MMMM" : "MMMM YYYY";
  return moment(date).format(format);
}
