import { Button, StickyBottom } from "@ailo/ui";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { FormFooterProps } from "./FormFooterProps";

export function FullPageFormFooter({
  cancelButtonDisabled,
  isSubmitting,
  style,
  submitButtonDisabled,
  submitText,
  submitVisible,
  onCancel,
  onSubmit,
  hideCancelButton,
  showFooterBackButton,
  onGoBack
}: FormFooterProps): ReactElement {
  return (
    <View
      style={[
        {
          position: "sticky" as any,
          bottom: 0
        },
        style
      ]}
    >
      <StickyBottom
        style={{
          paddingVertical: 20,
          paddingLeft: 0,
          paddingRight: 60,
          marginLeft: 60
        }}
      >
        <View style={{ flexDirection: "row" }}>
          {!hideCancelButton && (
            <Button.Secondary
              disabled={isSubmitting || cancelButtonDisabled}
              onPress={onCancel}
              testID={"CancelButton"}
            >
              {"Cancel"}
            </Button.Secondary>
          )}
          {showFooterBackButton && (
            <Button.Secondary onPress={onGoBack} testID={"BackButton"}>
              {"Back"}
            </Button.Secondary>
          )}
          {submitVisible && (
            <Button.Primary
              style={{ marginLeft: "auto" }}
              testID={"SubmitButton"}
              disabled={submitButtonDisabled}
              loading={isSubmitting}
              onPress={onSubmit}
            >
              {submitText}
            </Button.Primary>
          )}
        </View>
      </StickyBottom>
    </View>
  );
}
