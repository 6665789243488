import { LocalDate } from "@ailo/date";
import { Factory } from "rosie";
import { uuid } from "@ailo/services";
import { RentFrequency, RentFragment } from "local/graphql";

const rentFactory = new Factory<RentFragment>()
  .attr("id", uuid)
  .attr("amountInCents", uuid)
  .attr("dailyRate", 12345)
  .attr("period", RentFrequency.Weekly)
  .attr("effectiveDate", () => LocalDate.today().toString());

export { rentFactory };
