import { notEmpty, useCurrentAgencyOrg } from "local/common";
import React, { useState } from "react";
import {
  PlatformFeatureId,
  useAgencyIncomeDateQuery,
  useGetAgencyIncomeByPropertyReportDataQuery
} from "local/graphql";
import {
  disbursementPeriodToDate,
  floatForSort,
  useReportQuery,
  useReportTeamContext
} from "../..";
import { ReportPage } from "../../components";
import { TotalRow } from "../../components/ReportPage/ReportTable";
import { FilterComponentType } from "../../components/ReportPage/FilterFactory";
import { propertyRowLink } from "../../components/ReportPage/utils/tableNavigation";
import { useGlobal } from "reactn";
import { reports } from "../../reportsDetails";
import { LocalDate, TimePeriods, useFilterModal } from "@ailo/ui";
import { useTeamFilter } from "local/tabs";
import {
  parseStartAndEndDate,
  ReportTimeFilterCustomPeriod
} from "../../components/TimePeriodFilterCustomPeriod";
import { head } from "lodash";

export function IncomeByPropertyData({
  disbursementPeriodSk,
  disbursementPeriodLabel,
  startDate,
  endDate,
  filterComponent
}: {
  disbursementPeriodSk?: string;
  disbursementPeriodLabel?: string;
  startDate?: string;
  endDate?: string;
  filterComponent: React.ReactElement;
}): React.ReactElement {
  // if a disbursementPeriod or a date range is not provided no cigar
  const { id } = useCurrentAgencyOrg();
  const { teamId } = useReportTeamContext();
  const [availableFeatures] = useGlobal("availableFeatures");
  const hasTotalsFeature = availableFeatures.includes(
    PlatformFeatureId.AgencyIncomeTotals
  );

  const enableFilterModal = availableFeatures.includes(
    PlatformFeatureId.ReportingModalFilter
  );

  const [filterState, onSubmit] = useState<{
    teamIds: string[];
    startDate: string;
    endDate: string;
    timePeriod: TimePeriods;
  }>({
    startDate: LocalDate.today().setDayOfMonth(1).toString(),
    endDate: LocalDate.today().toString(),
    teamIds: [],
    timePeriod: "month"
  });

  const { startDate: filterStateStartDate, endDate: filterStateEndDate } =
    parseStartAndEndDate(filterState);
  const variables = enableFilterModal
    ? {
        ...filterState,
        startDate: filterStateStartDate,
        endDate: filterStateEndDate,
        managingOrgId: id,
        disbursementPeriodSk,
        teamId: filterState.teamIds,
        includeActiveProperties: hasTotalsFeature
      }
    : {
        managingOrgId: id,
        disbursementPeriodSk,
        startDate,
        endDate,
        teamId,
        includeActiveProperties: hasTotalsFeature
      };
  const { disbursementPeriodStartDate, disbursementPeriodEndDate } =
    disbursementPeriodToDate(disbursementPeriodLabel);

  const queryResult = useReportQuery(
    useGetAgencyIncomeByPropertyReportDataQuery,
    variables
  );

  const minDateQuery = useAgencyIncomeDateQuery({
    variables: { managingOrgId: id }
  });
  const earliestTransaction =
    head(minDateQuery?.data?.agencyIncomeReport?.rows)?.minDate ?? "";

  const reportData = queryResult.data?.agencyIncomeByPropertyReport;
  const rows = reportData?.rows?.filter(notEmpty);
  type Row = NonNullable<typeof rows>[0];
  const header = reportData?.headerFields;
  const totals = hasTotalsFeature
    ? ([
        { ...header?.average, totalTitle: "Average per property" },
        { ...header?.total, totalTitle: "Total income" }
      ] as TotalRow<Row>[])
    : undefined;
  const loading = queryResult.loading || minDateQuery.loading;
  const modalFilters = [
    ReportTimeFilterCustomPeriod({
      startDate: filterStateStartDate,
      endDate: filterStateEndDate,
      timePeriod: filterState.timePeriod,
      earliestTransaction
    }),
    useTeamFilter()
  ];
  const { filterButtons } = useFilterModal({
    modalFilters,
    onSubmit,
    filterState,
    loading
  });

  return (
    <ReportPage
      title={reports.income_by_property.reportHeading}
      data={{
        ...queryResult,
        rows,
        totals,
        loading
      }}
      filterModalButtons={enableFilterModal ? filterButtons : undefined}
      startDate={
        enableFilterModal
          ? filterStateStartDate
          : startDate ?? disbursementPeriodStartDate
      }
      endDate={
        enableFilterModal
          ? filterStateEndDate
          : endDate ?? disbursementPeriodEndDate
      }
      showHeader
      fileNameIncludeDate
      columnsConfig={{
        propertyAddress: {
          header: "Property",
          width: 2
        },
        teamName: {
          header: "Team"
        },
        rentCollectedAmount: {
          header: "Rent collected",
          sortValue: (r) => floatForSort(r.rentCollectedAmount),
          horizontalAlign: "right"
        },
        managementFeePercent: {
          header: "Avg Mgmt Fee",
          sortValue: (r) => floatForSort(r.managementFeePercent),
          horizontalAlign: "right"
        },
        managementFeeAmount: {
          header: "Mgmt fees",
          sortValue: (r) => floatForSort(r.managementFeeAmount),
          horizontalAlign: "right"
        },
        otherFeeAmount: {
          header: "Other fees",
          sortValue: (r) => floatForSort(r.otherFeeAmount),
          horizontalAlign: "right"
        },
        totalFeeAmount: {
          header: "All fees",
          sortValue: (r) => floatForSort(r.totalFeeAmount),
          horizontalAlign: "right"
        }
      }}
      defaultSort={{ columnKey: "propertyAddress", direction: "ASC" }}
      componentAboveHeader={!enableFilterModal ? filterComponent : undefined}
      filterConfig={[
        {
          name: "search",
          columns: ["propertyAddress"],
          type: FilterComponentType.Search,
          defaultValue: "",
          placeholder: "Search properties"
        }
      ]}
      rowLink={propertyRowLink}
    />
  );
}
