import { useOnFocus } from "@ailo/services";
import React from "react";
import {
  useAdjustmentLiabilityEntriesForRentAdjustmentsListQuery,
  AdjustmentLiabilityEntry
} from "local/graphql";
import { RentAdjustmentsListTable } from "./RentAdjustmentsListTable";

export function RentAdjustmentsList({
  tenancyId
}: {
  tenancyId: string;
}): React.ReactElement {
  const { data, loading, error, refetch } =
    useAdjustmentLiabilityEntriesForRentAdjustmentsListQuery({
      variables: { tenancyId }
    });

  useOnFocus(refetch);

  if (!data && loading) {
    return <RentAdjustmentsListTable.Loading />;
  }

  if (error) {
    return <RentAdjustmentsListTable.Error onRetry={refetch} />;
  }

  const rentAdjustments = data?.tenancy?.liability?.entries
    ?.items as AdjustmentLiabilityEntry[];

  return <RentAdjustmentsListTable rentAdjustments={rentAdjustments} />;
}
