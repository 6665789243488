import React from "react";
import { ClaimBondFormConfiguration } from "local/tabs";
import { BondAuthority, ClaimTenancyData } from "local/domain/bond";
import { BondAuthoritySection } from "./BondAuthoritySection";
import { AustralianState } from "local/common";
import { NTSteps } from "./NTSteps/NTSteps";
import { usePropertyIsInStates } from "local/tabs/properties/components/PropertyScreen";
import { Separator } from "../ConfirmClaimStep";

interface Props {
  data: ClaimTenancyData;
  bondAuthority?: BondAuthority;
  formConfiguration: ClaimBondFormConfiguration;
  enableSubmitButton: () => void;
}

function ConfirmClaimActions({
  data,
  bondAuthority,
  formConfiguration,
  enableSubmitButton
}: Props): React.ReactElement {
  return (
    <>
      <Separator />
      {!!bondAuthority && (
        <>
          <BondAuthoritySection
            reference={data.bond.reference}
            bondAccount={data.bondAccount}
            bondAuthority={bondAuthority}
            enableSubmitButton={enableSubmitButton}
            formConfiguration={formConfiguration}
          />
        </>
      )}
      {usePropertyIsInStates([AustralianState.NT]) && (
        <NTSteps
          bondAccount={data.bondAccount}
          reference={data.bond.reference}
        />
      )}
    </>
  );
}

export { ConfirmClaimActions };
