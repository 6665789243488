import { ExpenseStatusBadge } from "@ailo/domains";
import {
  ListItem,
  ListItemDate,
  ListItemStyle,
  LocalDate,
  Money
} from "@ailo/ui";
import { AmountAndStatus } from "local/domain/expense";
import { UpcomingAndPaidFeeFieldsFragment } from "local/graphql";
import React, { ReactElement } from "react";

interface Props {
  fee: UpcomingAndPaidFeeFieldsFragment;
  onPress?: () => void;
  style?: ListItemStyle;
}

export function FeeListItem({ fee, onPress, style }: Props): ReactElement {
  const issueDate = LocalDate.from(fee.createdAt, {
    keepTimeZone: false
  }).toString();

  return (
    <ListItem
      testID={undefined}
      header={fee.management.managingEntity?.organisation.name ?? ""}
      subHeader={
        fee.managementFeeBlueprint?.feeBlueprint?.name ??
        fee.blueprint?.name ??
        ""
      }
      leftComponent={<ListItemDate date={issueDate} showSticks={false} />}
      rightComponent={
        <AmountAndStatus
          amount={Money.from(fee.amount)}
          hasRightChevronIcon
          statusBadge={
            fee.status ? (
              <ExpenseStatusBadge
                status={fee.status}
                dueHint={"When funds are available"}
              />
            ) : undefined
          }
        />
      }
      onPress={() => onPress?.()}
      style={style}
    />
  );
}
