import { StyleProp, View, ViewStyle } from "react-native";
import React from "react";

interface StyledViewProps {
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export function Row({ children, style }: StyledViewProps): React.ReactElement {
  const styleApplied: StyleProp<ViewStyle> = {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    ...(style as object)
  };

  return <View style={styleApplied}>{children}</View>;
}
