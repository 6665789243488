import React, { useEffect, useState } from "react";
import { ConfirmModal, useToastContext } from "@ailo/ui";
import { useActionEventContext } from "@ailo/services";
import {
  CreateSupplierForm,
  CreateSupplierFormData
} from "./CreateSupplierForm";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  CreateSupplierMutationVariables,
  useCreateSupplierMutation
} from "local/graphql";
import { useCurrentAgencyOrg } from "local/common";
import { useCreateSupplierAnalytics } from "./useCreateSupplierAnalytics";

type CreateSupplierModalProps = {
  onSubmit: (supplier: { ailoRN: string; name: string }) => void;
  onCancel?: () => void;
  height: number;
};

export const CreateSupplierModal = ({
  onSubmit,
  onCancel,
  height
}: CreateSupplierModalProps): any => {
  const form = useForm<CreateSupplierFormData>();
  const analytics = useCreateSupplierAnalytics();
  const [enableFormSubmission, setEnableFormSubmission] = useState(false);
  useEffect(() => {
    analytics.trackFormOpened();
  }, [analytics]);
  const organisation = useCurrentAgencyOrg();
  const [createSupplier] = useCreateSupplierMutation();
  const toasts = useToastContext();
  const actionEvents = useActionEventContext();
  const handleSubmit: SubmitHandler<CreateSupplierFormData> = async (data) => {
    const result = await createSupplier({
      variables: createSupplierMutationVariables(data, organisation.ailoRN)
    });

    if (!result.data?.createSupplier?.ailoRN) {
      toasts.show({
        type: "error",
        message: "Couldn't create supplier at this time, try again"
      });
      return;
    }

    analytics.trackSubmitSuccess(
      { ailoRN: result.data.createSupplier.ailoRN },
      form.getValues()
    );

    onSubmit({
      ailoRN: result.data.createSupplier.ailoRN,
      name: data.name
    });

    toasts.show({
      type: "success",
      message: "Supplier created"
    });

    actionEvents.emit({ type: "SupplierCreated" });
  };

  return (
    <ConfirmModal
      title={"Add Supplier"}
      onCancel={onCancel}
      onConfirm={form.handleSubmit(handleSubmit)}
      disabled={!enableFormSubmission}
      confirmLabel={"Add Supplier"}
      showFooterBorder
    >
      <CreateSupplierForm
        height={height}
        form={form}
        onFormFieldBlur={analytics.trackValueUpdated}
        toggleSubmissionPossible={setEnableFormSubmission}
      />
    </ConfirmModal>
  );
};

const createSupplierMutationVariables = (
  data: CreateSupplierFormData,
  organisation: string
): CreateSupplierMutationVariables => {
  const { addressPostcode, addressState, abn, ...rest } = data;
  return {
    abn: abn.replace(/[^\d]/g, ""),
    address: { postcode: addressPostcode, state: addressState },
    organisation,
    ...rest
  };
};
