import { Screens, useNavigation } from "local/common";
import { NavigateToChatParams } from "@ailo/domains";
import { useCallbackRef } from "@ailo/primitives";

/**
 * To be used only when inside Screens.CommsTab
 *
 * For absolute use cases outside of the comms tab:
 * @see generateLinkToCommsTab
 */
export function useNavigateToChatThreadScreen(): (
  params: NavigateToChatParams
) => void {
  const navigation = useNavigation<Screens.CommsTab>();

  return useCallbackRef(
    ({ chatId, threadId }: NavigateToChatParams) => {
      navigation.navigate(Screens.ChatThread, {
        chatId,
        threadId
      });
    },
    [navigation]
  );
}
