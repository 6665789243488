import { ActionType } from "local/graphql";
import React, { ReactElement } from "react";

import { RefinedActionActivityProps } from "../ActionActivityProps";
import { DisplayDocusignActionDetails } from "./DisplayDocusignActionDetails";
import { Text } from "@ailo/primitives";
import { ErrorAlertScreen } from "@ailo/ui";
import { useGetReiTokens } from "local/domain/project/useGetReiTokens";
import { NoSigningRequestView } from "./DisplayDocusignActionDetails/NoSigningRequestView";
import { AgreementCreatedNoTokenView } from "local/domain/project";

export function TrackDocusignActionActivity({
  action
}: RefinedActionActivityProps<ActionType.TrackDocusign>): ReactElement {
  const { data: reiTokens, loading, error, refetch } = useGetReiTokens();

  if (loading) {
    return <Text.BodyS.Loading width={200} />;
  }
  if (error) {
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the form information."}
        onRetry={refetch}
      />
    );
  }
  const projectId = action.project.id;
  const formId = action.meta.form?.id;

  if (!formId) {
    return <NoSigningRequestView />;
  }

  if (!reiTokens?.length) {
    return <AgreementCreatedNoTokenView />;
  }

  return (
    <DisplayDocusignActionDetails
      projectId={projectId}
      formId={formId}
      action={action}
    />
  );
}
