import React, { useEffect } from "react";
import { ConfirmModal, useToastContext } from "@ailo/ui";
import {
  CreatePaymentMethodForm,
  CreatePaymentMethodFormData,
  PaymentMethod
} from "./CreatePaymentMethodForm";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  CreatePaymentMethodMutationVariables,
  useCreatePaymentMethodMutation
} from "local/graphql";
import { useCurrentAgencyOrg } from "local/common";
import { useCreatePaymentMethodAnalytics } from "./useCreatePaymentMethodAnalytics";
import { useActionEventContext } from "@ailo/services";

type CreateSupplierPaymentMethodModalProps = {
  supplier: { name: string; ailoRN: string };
  onSubmit: () => void;
  onCancel?: () => void;
  height: number;
};

export const CreateSupplierPaymentMethodModal = ({
  supplier,
  onSubmit,
  onCancel,
  height
}: CreateSupplierPaymentMethodModalProps): any => {
  const form = useForm<CreatePaymentMethodFormData>();
  const analytics = useCreatePaymentMethodAnalytics(supplier);
  const managingOrganisationAiloRN = useCurrentAgencyOrg().ailoRN;
  const [createPaymentMethod] = useCreatePaymentMethodMutation();
  const toasts = useToastContext();
  const actionEvents = useActionEventContext();
  const handleSubmit: SubmitHandler<CreatePaymentMethodFormData> = async (
    data
  ) => {
    const variables = createPaymentMethodMutationVariables(
      data,
      supplier,
      managingOrganisationAiloRN || ""
    );
    const result = await createPaymentMethod({
      variables
    });

    if (!result.data?.createPaymentMethod?.id) {
      toasts.show({
        type: "error",
        message: "Couldn't create payment method at this time, try again"
      });
      return;
    }

    analytics.trackSubmitSuccess(form.getValues());
    onSubmit();
    toasts.show({
      type: "success",
      message: "Payment method created"
    });
    actionEvents.emit({ type: "PaymentMethodCreated" });
  };

  useEffect(() => {
    analytics.trackFormOpened();
  }, [analytics]);

  return (
    <ConfirmModal
      title={"Add Payment Method"}
      confirmLabel={"Add Payment Method"}
      onCancel={onCancel}
      onConfirm={form.handleSubmit(handleSubmit)}
      showFooterBorder
    >
      <CreatePaymentMethodForm
        height={height}
        form={form}
        onFormFieldBlur={analytics.trackValueUpdated}
      />
    </ConfirmModal>
  );
};

const createPaymentMethodMutationVariables = (
  data: CreatePaymentMethodFormData,
  supplier: { ailoRN: string; name: string },
  managingOrganisationAiloRN: string
): CreatePaymentMethodMutationVariables => {
  const { accountNumber, bsb, billerCode, paymentMethod } = data;
  const bankAccount =
    paymentMethod === PaymentMethod.BankAccount
      ? {
          accountNumber,
          bsb,
          accountName: supplier.name
        }
      : null;
  const bpay = paymentMethod === PaymentMethod.BPay ? { billerCode } : null;
  return {
    ownerAiloRN: supplier.ailoRN,
    managingOrganisationAiloRN,
    createWalletOwner: true,
    deleteExistingPaymentMethods: false,
    bankAccount,
    bpay
  };
};
