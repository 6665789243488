import { Text } from "@ailo/primitives";
import { DescriptionList, Money, Separator } from "@ailo/ui";
import React from "react";
import { UseFormMethods } from "react-hook-form";
import { View } from "react-native";
import { ReverseRentAdjustmentFormData } from "../ReverseRentAdjustmentModal";

interface ConfirmReverseRentAdjustmentStepProps {
  amount: Money;
  display: boolean;
  form: UseFormMethods<ReverseRentAdjustmentFormData>;
}

export function ConfirmReverseRentAdjustmentStep({
  amount,
  display,
  form
}: ConfirmReverseRentAdjustmentStepProps): React.ReactElement {
  const description = form.watch("description");
  const items = [
    {
      label: "Amount",
      descriptionBottom: amount.abs().format()
    },

    {
      label: "Description",
      descriptionBottom: description
    }
  ];

  return (
    <View style={display ? {} : { display: "none" }}>
      <Text.BodyS>{"Please confirm the details are correct"}</Text.BodyS>

      <Separator
        style={{
          marginTop: 32,
          marginBottom: 8,
          marginLeft: 0,
          marginRight: -20
        }}
      />

      <DescriptionList
        size={"small"}
        items={items}
        itemStyle={{ marginTop: 16 }}
      />
    </View>
  );
}
