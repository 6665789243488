import { ActionType } from "local/graphql";
import React, { ReactElement } from "react";
import { RefinedActionActivityProps } from "../ActionActivityProps";
import { InspectionView } from "./InspectionView/InspectionView";
import { NoInspectionView } from "../CommonInspectionActionViews/NoInspectionView";
import { NoInspectionAppointmentView } from "../CommonInspectionActionViews/NoInspectionAppointmentView";

export function GenerateInspectionReportActionActivity({
  projectId,
  action
}: RefinedActionActivityProps<ActionType.GenerateInspectionReport>): ReactElement {
  const inspectionAppointment = action.meta?.inspectionAppointment;
  if (!inspectionAppointment) {
    return (
      <NoInspectionAppointmentView action={action} projectId={projectId} />
    );
  }

  const { inspection } = inspectionAppointment;
  if (!inspection) {
    return <NoInspectionView />;
  }

  return (
    <InspectionView
      actionId={action.id}
      projectId={projectId}
      inspection={inspection}
    />
  );
}
