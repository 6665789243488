import { useMountedState } from "@ailo/primitives";
import {
  Button,
  ErrorAlertScreen,
  ErrorModal,
  SidebarStickyBottom,
  SidebarTitle,
  SpinnerOverlay
} from "@ailo/ui";
import React, { ReactElement, useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { LeaseRenewalConfirmModal } from "./LeaseRenewalConfirmModal";
import {
  LeaseRenewalFormData,
  LeaseType,
  LeaseRenewalData,
  useGetLeaseRenewalData,
  LeaseRenewalSection
} from "local/modals/LeaseRenewalModalForm/LeaseRenewalForm";
import { AllowedOperations } from "local/graphql";
import { useAnalytics } from "@ailo/services";

interface Props {
  tenancyId: string;
  prefillStartDate?: string;
  onDismiss: () => void;
}

export function LeaseRenewalForm({
  tenancyId,
  prefillStartDate,
  onDismiss
}: Props): ReactElement {
  const { data, loading, refetch } = useGetLeaseRenewalData(tenancyId);

  if (loading) return <LeaseRenewalFormLoading onDismiss={onDismiss} />;

  if (!data)
    return <LeaseRenewalFormError onDismiss={onDismiss} onRetry={refetch} />;

  return (
    <LeaseRenewalFormWithData
      tenancyId={tenancyId}
      prefillStartDate={prefillStartDate}
      onDismiss={onDismiss}
      {...data}
    />
  );
}

function LeaseRenewalFormWithData({
  currentAgreementFixedTermEndDate,
  currentAgreementIsAllowedToLapse,
  currentAgreementStartDate,
  leaseReviewAllowedOperation,
  nextTenancyAgreement,
  prefillStartDate,
  rentReviewAllowed,
  tenancyEndDate,
  tenancyId,
  onDismiss
}: Props & LeaseRenewalData): ReactElement {
  const analytics = useAnalytics();
  const [showConfirmModal, setShowConfirmModal] = useMountedState(false);
  const [showErrorModal, setShowErrorModal] = useMountedState(false);

  const { formState, trigger, register, setValue } =
    useFormContext<LeaseRenewalFormData>();

  useEffect(() => {
    analytics.trackScreenVisited("Lease Renewal");
  }, [analytics]);

  useEffect(() => {
    register({ name: "tenancyId", value: tenancyId });
    register({
      name: "currentAgreementFixedTermEndDate",
      value: currentAgreementFixedTermEndDate
    });

    if (!formState.isDirty && nextTenancyAgreement) {
      const isPeriodic = !nextTenancyAgreement?.fixedTermEndDate;

      if (isPeriodic) {
        setValue("leaseType", LeaseType.Periodic);
      }

      register({ name: "agreementId", value: nextTenancyAgreement.id });

      setValue("agreementStartDate", nextTenancyAgreement.startDate);

      if (nextTenancyAgreement.fixedTermEndDate) {
        setValue(
          "agreementFixedTermEndDate",
          nextTenancyAgreement.fixedTermEndDate,
          {
            shouldDirty: true
          }
        );
      }
    }
  }, [
    currentAgreementStartDate,
    currentAgreementFixedTermEndDate,
    tenancyId,
    register,
    formState.isDirty,
    nextTenancyAgreement,
    setValue
  ]);

  useEffect(() => {
    if (!formState.isDirty && prefillStartDate) {
      setValue("agreementStartDate", prefillStartDate);
    }
  }, [formState.isDirty, prefillStartDate, setValue]);

  const handleSubmit = useCallback(() => {
    if (!formState.isValid) {
      trigger();
    } else {
      setShowConfirmModal(true);
    }
  }, [trigger, formState.isValid, setShowConfirmModal]);

  return (
    <Container>
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        <SidebarTitle
          title={
            leaseReviewAllowedOperation === AllowedOperations.Edit
              ? "Edit Lease Renewal"
              : "Lease Renewal"
          }
          closeButton
          onClose={onDismiss}
        />
        <FormContainer>
          <LeaseRenewalSection
            currentAgreementFixedTermEndDate={currentAgreementFixedTermEndDate}
            currentAgreementIsAllowedToLapse={currentAgreementIsAllowedToLapse}
            currentAgreementStartDate={currentAgreementStartDate}
            nextTenancyAgreement={nextTenancyAgreement}
            rentReviewAllowed={rentReviewAllowed}
            tenancyEndDate={tenancyEndDate}
          />
        </FormContainer>
      </ScrollView>

      <SidebarStickyBottom>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Button.Secondary onPress={onDismiss}>{"Cancel"}</Button.Secondary>
          <Button.Primary
            onPress={handleSubmit}
            disabled={formState.isSubmitting}
          >
            {"Confirm Details"}
          </Button.Primary>
        </View>
      </SidebarStickyBottom>

      <LeaseRenewalConfirmModal
        visible={showConfirmModal && !formState.isSubmitting}
        onSuccess={(): void => {
          setShowConfirmModal(false);
          onDismiss();
        }}
        onError={(): void => {
          setShowErrorModal(true);
          setShowConfirmModal(false);
        }}
        onCancel={(): void => setShowConfirmModal(false)}
      />

      <ErrorModal
        visible={showErrorModal}
        onDismiss={(): void => setShowErrorModal(false)}
      />

      <SpinnerOverlay visible={formState.isSubmitting} />
    </Container>
  );
}

function LeaseRenewalFormLoading({
  onDismiss
}: {
  onDismiss: () => void;
}): ReactElement {
  return (
    <Container>
      <SidebarTitle title={"Lease Renewal"} closeButton onClose={onDismiss} />
      <FormContainer>
        <LeaseRenewalSection.Loading />
      </FormContainer>
    </Container>
  );
}

function LeaseRenewalFormError({
  onDismiss,
  onRetry
}: {
  onDismiss: () => void;
  onRetry: () => void;
}): ReactElement {
  return (
    <Container>
      <SidebarTitle title={"Lease Renewal"} closeButton onClose={onDismiss} />
      <FormContainer style={{ flex: 1, justifyContent: "center" }}>
        <ErrorAlertScreen
          variant={"sidebar"}
          title={"There was a problem loading\nLease Renewal"}
          onRetry={onRetry}
        />
      </FormContainer>
    </Container>
  );
}

const Container = styled(View)`
  flex: 1;
`;

const FormContainer = styled(View)`
  padding: 0 32px 60px;
`;
