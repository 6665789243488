import { ScreenComponent } from "@ailo/services";
import React, { ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { ManagementFeeBlueprintsSidebarContent } from "./ManagementFeeBlueprintsSidebarContent";
import { ManagementFeeBlueprintsSidebarErrorBoundaryFallback } from "./ManagementFeeBlueprintsSidebarErrorBoundaryFallback";

interface ManagementFeeBlueprintsSidebarProps {
  managementId: string;
  showChargeAFeeButton?: boolean;
  onClose(): void;
  style?: StyleProp<ViewStyle>;
}

export function ManagementFeeBlueprintsSidebar(
  props: ManagementFeeBlueprintsSidebarProps
): ReactElement {
  return (
    <ScreenComponent
      name={"Management Fee Schedules"}
      analytics={false}
      errorBoundaryOptions={{
        fallbackComponent: ManagementFeeBlueprintsSidebarErrorBoundaryFallback(
          props.onClose
        )
      }}
    >
      <ManagementFeeBlueprintsSidebarContent {...props} />
    </ScreenComponent>
  );
}
