import React, { ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";
import {
  useGetManagementDetailsForEditQuery,
  ManagementFragment
} from "local/graphql";
import { EditManagementFormWithData } from "./EditManagementFormWithData";
import { uuid } from "@ailo/services";

interface Props {
  managementId: string;
  onSuccess?(management: ManagementFragment): void;
  onError?(): void;
  onCancel?(): void;
  style?: StyleProp<ViewStyle>;
}

export function EditManagementForm({
  onSuccess,
  onError,
  onCancel,
  managementId,
  style
}: Props): ReactElement {
  const { data, loading } = useGetManagementDetailsForEditQuery({
    variables: { tenancyId: uuid() },
    fetchPolicy: "network-only"
  });

  const management = data?.tenancy;
  const agreement = management?.nextTenancyAgreement;

  if (loading) return <></>;

  return (
    <EditManagementFormWithData
      managementId={managementId}
      defaultValues={{
        agreementStartDate: agreement?.startDate || undefined,
        agreementEndDate: agreement?.fixedTermEndDate || undefined
      }}
      onSuccess={onSuccess}
      onError={onError}
      onCancel={onCancel}
      style={style}
    />
  );
}
