import { useCallback, useMemo, useState } from "react";
import { MIN_NO_CHARS_TO_START_SEARCH } from "../../hooks";
import {
  AwaitingApprovalMigratingManagementExtraVariables,
  PropertyOnboardingListRequestFromBeginning
} from "../../types";

export interface AwaitingApprovalPropertyOnboardingListAddressSearchData {
  addressSearch: string;
  updateAddressSearch: (currentSearchTerm: string) => void;
  clearAddressSearch: () => void;
}

export interface PortfolioFilter {
  selectedPortfolioNames: string[];
  includeUnassigned: boolean;
}

export interface AwaitingApprovalPropertyOnboardingListPortfolioFilterData {
  portfolioFilter?: PortfolioFilter;
  setPortfolioFilter: (portfolioFilter?: PortfolioFilter) => void;
}

export type AwaitingApprovalPropertyOnboardingListStatusFilterSelection =
  | "healthy"
  | "data-issues"
  | "all";

export interface AwaitingApprovalPropertyOnboardingListStatusFilterData {
  withErrors?: AwaitingApprovalPropertyOnboardingListStatusFilterSelection;
  setWithErrors: (
    withErrors?: AwaitingApprovalPropertyOnboardingListStatusFilterSelection
  ) => void;
}

export interface AwaitingApprovalPropertyOnboardingListFilterData {
  hasActiveFilters: boolean;
  addressSearchData: AwaitingApprovalPropertyOnboardingListAddressSearchData;
  portfolioFilterData: AwaitingApprovalPropertyOnboardingListPortfolioFilterData;
  statusFilterData: AwaitingApprovalPropertyOnboardingListStatusFilterData;
}

interface Params {
  requestFromBeginning: PropertyOnboardingListRequestFromBeginning<AwaitingApprovalMigratingManagementExtraVariables>;
}

const getPortfolioExtraVariable = (
  portfolioFilter: PortfolioFilter | undefined
): AwaitingApprovalMigratingManagementExtraVariables["portfolio"] => {
  return portfolioFilter
    ? {
        includeUnassigned: portfolioFilter?.includeUnassigned,
        names: portfolioFilter?.selectedPortfolioNames
      }
    : undefined;
};

const getWithErrorsExtraVariable = (
  withErrors:
    | AwaitingApprovalPropertyOnboardingListStatusFilterSelection
    | undefined
): AwaitingApprovalMigratingManagementExtraVariables["withErrors"] => {
  if (withErrors === "healthy") return false;
  if (withErrors === "data-issues") return true;
  return undefined;
};

export function useAwaitingApprovalPropertyOnboardingListFilters({
  requestFromBeginning
}: Params): AwaitingApprovalPropertyOnboardingListFilterData {
  const [addressSearch, setAddressSearch] = useState<string>("");
  const [withErrors, setWithErrorsState] = useState<
    AwaitingApprovalPropertyOnboardingListStatusFilterSelection | undefined
  >(undefined);
  const [portfolioFilter, setPortfolioFilterState] = useState<
    PortfolioFilter | undefined
  >(undefined);

  const updateAddressSearch = useCallback(
    (currentSearchTerm: string): void => {
      const shouldUpdate =
        currentSearchTerm.length >= MIN_NO_CHARS_TO_START_SEARCH ||
        currentSearchTerm.length == 0;
      if (!shouldUpdate) return;

      setAddressSearch(currentSearchTerm);
      requestFromBeginning({
        extraVariables: {
          addressSearch: currentSearchTerm,
          withErrors: getWithErrorsExtraVariable(withErrors),
          portfolio: getPortfolioExtraVariable(portfolioFilter)
        }
      });
    },
    [requestFromBeginning, withErrors, portfolioFilter]
  );

  const clearAddressSearch = useCallback((): void => {
    updateAddressSearch("");
  }, [updateAddressSearch]);

  const setWithErrors = useCallback(
    (
      newWithErrors:
        | AwaitingApprovalPropertyOnboardingListStatusFilterSelection
        | undefined
    ): void => {
      setWithErrorsState(newWithErrors);
      requestFromBeginning({
        extraVariables: {
          addressSearch,
          withErrors: getWithErrorsExtraVariable(newWithErrors),
          portfolio: getPortfolioExtraVariable(portfolioFilter)
        }
      });
    },
    [requestFromBeginning, addressSearch, portfolioFilter]
  );

  const setPortfolioFilter = useCallback(
    (newPortfolioFilter: PortfolioFilter | undefined): void => {
      setPortfolioFilterState(newPortfolioFilter);
      requestFromBeginning({
        extraVariables: {
          addressSearch,
          withErrors: getWithErrorsExtraVariable(withErrors),
          portfolio: getPortfolioExtraVariable(newPortfolioFilter)
        }
      });
    },
    [requestFromBeginning, addressSearch, withErrors]
  );

  const hasActiveFilters = useMemo(() => {
    return (
      addressSearch !== "" ||
      portfolioFilter !== undefined ||
      withErrors !== undefined
    );
  }, [addressSearch, portfolioFilter, withErrors]);

  return {
    hasActiveFilters,
    addressSearchData: {
      addressSearch,
      updateAddressSearch,
      clearAddressSearch
    },
    portfolioFilterData: {
      portfolioFilter,
      setPortfolioFilter
    },
    statusFilterData: {
      withErrors,
      setWithErrors
    }
  };
}
