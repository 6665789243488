import React, { ReactElement, useState, useEffect } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Colors, Text } from "@ailo/primitives";
import { ConfirmModal, Spinner } from "@ailo/ui";
import { ProviderIconCard } from "../common/ProviderIconCard";
import { FormType } from "./types";

type Props = {
  emailAddress?: string;
  onClose: () => void;
  onCancel: () => void;
  visible: boolean;
  errors?: string;
  onError: (error: string | undefined) => void;
};

export function ConnectionFormsStatusModal({
  emailAddress,
  onClose,
  onCancel,
  visible,
  errors,
  onError
}: Props): ReactElement {
  const [displayEmailAddress, setDisplayEmailAddress] = useState<string>();
  const connected = !!displayEmailAddress;

  useEffect(() => {
    if (emailAddress) {
      setDisplayEmailAddress(emailAddress);
    }
  }, [emailAddress]);

  useEffect(() => {
    if (visible) {
      setDisplayEmailAddress(undefined);
      onError(undefined);
    }
  }, [onError, visible]);

  return (
    <ConfirmModal
      title={
        <Header>
          <ProviderIcons>
            {Object.values(FormType).map((form) => (
              <ProviderIconCard key={form} provider={form} size={"large"} />
            ))}
          </ProviderIcons>
          <Title weight={"medium"}>
            {connected
              ? "Forms Connected"
              : errors
              ? "Failed to connect forms"
              : "Connecting to REI Forms Live..."}
          </Title>
        </Header>
      }
      compactTitle={connected || !!errors}
      confirmLabel={connected || errors ? "Close" : "Cancel"}
      cancelable={false}
      showFooterBorder={true}
      onConfirm={connected ? onClose : onCancel}
      visible={visible}
    >
      {connected ? (
        <Message weight={"book"}>
          {"You can now generate and send forms using "}
          <EmailAddress weight={"medium"}>{displayEmailAddress}</EmailAddress>
          {" in Ailo."}
        </Message>
      ) : errors ? (
        <Message weight={"book"}>{errors}</Message>
      ) : (
        <Spinner
          size={24}
          style={{ marginTop: 4, marginBottom: 16, marginHorizontal: "auto" }}
        />
      )}
    </ConfirmModal>
  );
}

const Header = styled(View)`
  margin-top: 12px;
  width: 100%;
  align-items: center;
`;

const Title = styled(Text.BodyL)`
  margin-top: 20px;
  text-align: center;
  color: ${Colors.TEXT.DARK.PRIMARY};
`;

const Message = styled(Text.BodyS)`
  margin-left: 80px;
  margin-right: 80px;
  margin-bottom: 16px;
  color: ${Colors.TEXT.DARK.SECONDARY};
  text-align: center;
`;

const EmailAddress = styled(Text.BodyS)`
  font-weight: 500;
  color: ${Colors.TEXT.DARK.SECONDARY};
`;

const ProviderIcons = styled(View)`
  flex-direction: row;
  width: 60%;
  justify-content: space-between;
`;
