import { useMemo } from "react";
import { AiloRN } from "@ailo/ailorn";
import { ComposeMessageData, RelatedChatAilorns } from "@ailo/domains";
import { ActionType } from "local/graphql";
import { getManagementIfExists } from "local/domain/project";
import { RefinedAction } from "../ActionActivityProps";
import { useCurrentAgencyOrg } from "local/common";

export function useMessageData(
  action: RefinedAction<ActionType.AssignMessage>
): ComposeMessageData {
  const organisationAilorn = useCurrentAgencyOrg().ailorn;

  return useMemo(() => {
    const { messageTemplate, subjectTemplate } = action.meta;
    const projectMeta = action.project.meta ?? {};
    const tenancyAilorn =
      "tenancy" in projectMeta && projectMeta.tenancy?.ailoRN;
    const managementId = getManagementIfExists(action.project)?.id;

    const relatedToAilorns: RelatedChatAilorns[] = [
      AiloRN.of("project:action", action.id),
      AiloRN.of("project:project", action.project.id)
    ];
    if (managementId) {
      relatedToAilorns.push(
        AiloRN.of("propertymanagement:management", managementId)
      );
    }
    return {
      defaultMessage: messageTemplate ?? undefined,
      defaultSubject: subjectTemplate ?? undefined,
      recipientGroup: tenancyAilorn ? AiloRN.from(tenancyAilorn) : undefined,
      organisationAilorn,
      relatedToAilorns: relatedToAilorns
    };
  }, [action, organisationAilorn]);
}
