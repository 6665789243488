import { formatDate } from "@ailo/services";
import { Alert, Money } from "@ailo/ui";
import moment from "moment";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";

export function LastRentReviewInfoAlert({
  lastRentReview,
  style
}: {
  lastRentReview: {
    effectiveDate: string;
    rentIncreaseAmount: Money;
  };
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <Alert
      type={"info"}
      message={`Last review ${formatDate(
        moment(lastRentReview.effectiveDate)
      )}, ${getRentChangeText(lastRentReview.rentIncreaseAmount)}`}
      style={style}
    />
  );
}

function getRentChangeText(rentIncrease: Money): string {
  if (rentIncrease.isZero) {
    return "rent was unchanged";
  }

  const formattedRentIncrease = rentIncrease.abs().format();

  if (rentIncrease.isNegative) {
    return `rent decreased ${formattedRentIncrease}`;
  }

  return `rent increased ${formattedRentIncrease}`;
}
