import { AustralianState } from "local/graphql";
import { FormType } from "./types";

export const getStateData = (
  supportedState: string
): { state: string; formType: FormType } => {
  switch (supportedState.toUpperCase()) {
    case AustralianState.Nsw:
      return {
        state: "New South Wales",
        formType: FormType.REI_FORMS_LIVE
      };
    case AustralianState.Wa:
      return {
        state: "Western Australia",
        formType: FormType.REI_FORMS_LIVE
      };
    case AustralianState.Sa:
      return {
        state: "South Australia",
        formType: FormType.REI_FORMS_LIVE
      };
    case AustralianState.Nt:
      return {
        state: "Northern Territory",
        formType: FormType.REI_FORMS_LIVE
      };
    case AustralianState.Tas:
      return {
        state: "Tasmania",
        formType: FormType.REI_FORMS_LIVE
      };
    case AustralianState.Act:
      return {
        state: "Australian Capital Territory",
        formType: FormType.REI_FORMS_LIVE
      };
    case AustralianState.Qld:
      return {
        state: "Queensland",
        formType: FormType.REALWORKS
      };
    case AustralianState.Vic:
      return {
        state: "Victoria",
        formType: FormType.FORMS_LIVE
      };
    default:
      throw new Error(`Invalid state ${supportedState}`);
  }
};
