import { Text } from "@ailo/primitives";
import { ConfirmModal } from "@ailo/ui";
import React from "react";

export const ConfirmRemoveAccountModal = ({
  visible,
  onCancel,
  onConfirm
}: {
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}): React.ReactElement => (
  <ConfirmModal
    title={"Remove account"}
    visible={visible}
    onCancel={onCancel}
    onConfirm={onConfirm}
    destructive
  >
    <Text.BodyS>
      {"You will no longer have access to this forms account in Ailo"}
    </Text.BodyS>
  </ConfirmModal>
);
