import { DownCaretIcon } from "@ailo/primitives";
import {
  Button,
  DropdownMenu,
  DropdownMenuOption,
  DropdownMenuToggleProps
} from "@ailo/ui";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import styled from "styled-components/native";

type Props = {
  options: DropdownMenuOption[];
  style?: StyleProp<ViewStyle>;
};
export function ManageMenu({ options, style }: Props): React.ReactElement {
  return (
    <DropdownMenu
      renderToggle={renderDropdownButton}
      options={options}
      style={style}
    />
  );
}

function renderDropdownButton({
  toggle,
  open
}: DropdownMenuToggleProps): React.ReactElement {
  return (
    <StyledTextButton onPress={toggle} rightIcon={DownCaretIcon} active={open}>
      {"Manage"}
    </StyledTextButton>
  );
}

const StyledTextButton = styled(Button.Text)`
  border-width: 0;
`;
