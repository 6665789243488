import { isActionOfTypeWithMeta } from "local/domain/project";
import { ActionDetailsFragment, ActionType } from "local/graphql";
import React from "react";
import { AssignTenancyActionCard } from "./AssignTenancyActionCard";
import { GenerateInspectionReportActionCard } from "./GenerateInspectionReportActionCard";
import { GenericActionCard } from "./GenericActionCard";
import { ManageDepositActionCard } from "./ManageDepositActionCard";
import { TrackDocusignActionCard } from "./TrackDocusignActionCard/TrackDocusignActionCard";

type Props = {
  projectId: string;
  action: ActionDetailsFragment;
};

export function ActionCard({ action, projectId }: Props): React.ReactElement {
  if (isActionOfTypeWithMeta(action, ActionType.ManageDeposit)) {
    return <ManageDepositActionCard projectId={projectId} action={action} />;
  }

  if (isActionOfTypeWithMeta(action, ActionType.TrackDocusign)) {
    return <TrackDocusignActionCard projectId={projectId} action={action} />;
  }

  if (isActionOfTypeWithMeta(action, ActionType.AssignTenancy)) {
    return <AssignTenancyActionCard projectId={projectId} action={action} />;
  }

  if (isActionOfTypeWithMeta(action, ActionType.GenerateInspectionReport)) {
    return (
      <GenerateInspectionReportActionCard
        projectId={projectId}
        action={action}
      />
    );
  }

  return <GenericActionCard projectId={projectId} action={action} />;
}
