import { Text } from "@ailo/primitives";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { useInspectionPlanner } from "../../InspectionPlannerContext";
import { BackButton } from "./BackButton";
import { KeysButton } from "./KeysButton";
import { MoreButton } from "./MoreButton";

export function InspectionDayDisplayHeader({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement | null {
  const { selectedInspectionDay } = useInspectionPlanner();

  if (!selectedInspectionDay) {
    return null;
  }

  return (
    <Container style={style}>
      <Container>
        <BackButton style={{ marginRight: 16 }} />
        <Text.DisplayM>
          {selectedInspectionDay.date.format("dddd, D MMMM")}
        </Text.DisplayM>
      </Container>
      <Container>
        <KeysButton style={{ marginRight: 12 }} />
        <MoreButton />
      </Container>
    </Container>
  );
}

const Container = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;
