import { ButtonProps } from "@ailo/ui";
import React from "react";
import { ReiFormDownloadWithUrlButton } from "./ReiFormDownloadWithUrlButton";
import { ReiFormDownloadSignedButton } from "./ReiFormDownloadSignedButton";

const DOWNLOAD_TEXT = "Download a copy";

export function ReiFormDownloadButton({
  children,
  formId,
  remoteSigningStatusSent,
  style,
  ...props
}: React.PropsWithChildren<
  {
    formId: string;
    remoteSigningStatusSent?: string | null;
  } & ButtonProps
>): React.ReactElement {
  if (remoteSigningStatusSent) {
    return <ReiFormDownloadSignedButton formId={formId} />;
  }

  return (
    <ReiFormDownloadWithUrlButton {...props} formId={formId}>
      {DOWNLOAD_TEXT}
    </ReiFormDownloadWithUrlButton>
  );
}
