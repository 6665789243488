import React from "react";
import { FormField, RadioButtonGroup, TextInputFormField } from "@ailo/ui";
import { Controller, UseFormMethods } from "react-hook-form";
import { View } from "react-native";

export enum PaymentMethod {
  BPay,
  BankAccount
}

export type CreatePaymentMethodFormData = {
  paymentMethod: PaymentMethod;
  accountName: string;
  accountNumber: string;
  bsb: string;
  billerCode: string;
};

type CreatePaymentMethodFormProps = {
  form: UseFormMethods<CreatePaymentMethodFormData>;
  onFormFieldBlur: (
    fieldName: string,
    isValid: boolean | undefined,
    value: string,
    formValues: CreatePaymentMethodFormData
  ) => void;
  height: number;
};

const CreatePaymentMethodForm = ({
  form,
  onFormFieldBlur,
  height
}: CreatePaymentMethodFormProps): React.ReactElement => {
  const paymentMethodType = form.watch("paymentMethod");

  return (
    <View style={{ height }}>
      <Controller
        control={form.control}
        name={"paymentMethod"}
        defaultValue={PaymentMethod.BankAccount}
        render={({ value, onChange, onBlur }) => (
          <FormField label={"Payment Method"} style={{ marginTop: 24 }}>
            <RadioButtonGroup
              {...{ value, onChange, onBlur }}
              options={[
                { label: "Bank Account", value: PaymentMethod.BankAccount },
                { label: "BPAY", value: PaymentMethod.BPay }
              ]}
            />
          </FormField>
        )}
        onBlur={() =>
          onFormFieldBlur(
            "Payment Method",
            true,
            form.getValues().paymentMethod === PaymentMethod.BankAccount
              ? "Bank Account"
              : form.getValues().paymentMethod === PaymentMethod.BPay
              ? "BPAY"
              : "",
            form.getValues()
          )
        }
      />
      {paymentMethodType === PaymentMethod.BPay ? (
        <>
          <Controller
            control={form.control}
            name={"billerCode"}
            defaultValue={""}
            render={({ value, onChange, onBlur }) => (
              <TextInputFormField
                style={{ marginTop: 24 }}
                {...{ value, onBlur }}
                label={"Biller Code"}
                error={form.errors.billerCode?.message}
                placeholder={"Biller Code"}
                onChangeText={onChange}
                digitsOnly
                onBlur={() =>
                  onFormFieldBlur(
                    "Biller Code",
                    numberValidationRule(
                      "Biller Code",
                      4,
                      6
                    )(form.getValues().billerCode) === true || false,
                    form.getValues().billerCode,
                    form.getValues()
                  )
                }
              />
            )}
            rules={{
              required: `Biller Code is required.`,
              validate: numberValidationRule("Biller Code", 4, 6)
            }}
          />
        </>
      ) : (
        <>
          <Controller
            control={form.control}
            name={"bsb"}
            defaultValue={""}
            render={({ value, onChange, onBlur }) => (
              <TextInputFormField
                style={{ marginTop: 24 }}
                {...{ value, onBlur }}
                label={"BSB"}
                error={form.errors.bsb?.message}
                onChangeText={onChange}
                placeholder={"BSB"}
                digitsOnly
                onBlur={() =>
                  onFormFieldBlur(
                    "BSB",
                    numberValidationRule("BSB", 6)(form.getValues().bsb) ===
                      true || false,
                    form.getValues().bsb,
                    form.getValues()
                  )
                }
              />
            )}
            rules={{
              required: "BSB is required.",
              validate: numberValidationRule("BSB", 6)
            }}
          />
          <Controller
            control={form.control}
            name={"accountNumber"}
            defaultValue={""}
            render={({ value, onChange, onBlur }) => (
              <TextInputFormField
                style={{ marginTop: 24 }}
                {...{ value, onBlur }}
                label={"Account Number"}
                error={form.errors.accountNumber?.message}
                placeholder={"Account Number"}
                onChangeText={onChange}
                digitsOnly
                onBlur={() =>
                  onFormFieldBlur(
                    "Account Number",
                    numberValidationRule(
                      "Account Number",
                      6,
                      9
                    )(form.getValues().accountNumber) === true || false,
                    form.getValues().accountNumber,
                    form.getValues()
                  )
                }
              />
            )}
            rules={{
              required: `Account Number is required.`,
              validate: (x: string) =>
                x.length < 6 && x.length >= 4
                  ? `Account numbers should be at least six digits. If you are sure this account number is correct then use ${transformAccountNumber(
                      x
                    )}`
                  : numberValidationRule("Account Number", 6, 9)(x)
            }}
          />
        </>
      )}
    </View>
  );
};

function numberIsValid(number: string, min: number, max?: number): boolean {
  return max === undefined
    ? number.length === min
    : number.length >= min && number.length <= max;
}

/**
 * @returns true if @param number is a permitted length, and false otherwise.
 * @param max when not provided is assumed to equal min.
 *
 */
const numberValidationRule =
  (name: string, min: number, max?: number) =>
  (value: string): boolean | string =>
    numberIsValid(value, min, max) ||
    `${name} must be ${
      max === undefined
        ? `exactly ${min} digits`
        : `between ${min} and ${max} digits`
    }`;

const transformAccountNumber = (x: string): string => {
  let number = x;
  while (number.length < 6) {
    number = "0" + number;
  }
  return number;
};

export { CreatePaymentMethodForm };
