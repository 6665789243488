import { useHasFeature } from "@ailo/domains";
import { DeleteIcon, EditIcon } from "@ailo/primitives";
import { Button, DropdownMenu } from "@ailo/ui";
import React, { ReactElement } from "react";
import { isPresent } from "ts-is-present";
import { PlatformFeatureId } from "local/graphql";

interface FeeItemMenuProps {
  onEditFeePress?: () => void;
  onCancelFeePress?: () => void;
}

export function FeeItemMenu({
  onEditFeePress,
  onCancelFeePress
}: FeeItemMenuProps): ReactElement | null {
  const canEditFee = useHasFeature(PlatformFeatureId.EditRecurringFee);
  const canCancelFee = useHasFeature(PlatformFeatureId.CancelRecurringFee);

  const editFee =
    canEditFee && onEditFeePress
      ? {
          label: "Edit fee",
          icon: <EditIcon />,
          onSelect: (): void => onEditFeePress?.()
        }
      : undefined;
  const cancelFee =
    canCancelFee && onCancelFeePress
      ? {
          label: "Remove fee",
          icon: <DeleteIcon />,
          onSelect: (): void => onCancelFeePress?.()
        }
      : undefined;

  const options = [editFee, cancelFee].filter(isPresent);

  if (options.length === 0) return null;

  return (
    <DropdownMenu
      renderToggle={({ toggle, open }) => (
        <Button.More onPress={toggle} active={open} variant={"small"} />
      )}
      options={options}
    />
  );
}
