import { LocalDate } from "@ailo/date";
import { PendingInspectionAppointmentFragment } from "local/graphql";
import { groupBy } from "lodash";
import moment from "moment";
import { hasPresentKey } from "ts-is-present";

export type InspectionDay = {
  date: LocalDate;
  appointments: PendingInspectionAppointmentFragment[];
};

export type InspectionMonth = {
  firstDayOfMonth: LocalDate;
  inspectionDays: InspectionDay[];
};

export function getInspectionDays(
  appointments: PendingInspectionAppointmentFragment[],
  timezone: string
): InspectionDay[] {
  const appointmentsInAgencyTimezone = appointments
    .filter(hasPresentKey("startTime"))
    .sort((appointmentA, appointmentB) =>
      moment(appointmentA.startTime).diff(appointmentB.startTime)
    )
    .map((appointment) => ({
      ...appointment,
      startTime: moment.utc(appointment.startTime).tz(timezone).format()
    }));

  const inspectionDaysDictionary = groupBy(
    appointmentsInAgencyTimezone,
    (inspectionAppointment) =>
      LocalDate.fromString(inspectionAppointment.startTime).toString()
  );

  const inspectionDaysArray: InspectionDay[] = [];
  for (const [key, value] of Object.entries(inspectionDaysDictionary)) {
    inspectionDaysArray.push({
      date: LocalDate.fromString(key),
      appointments: value
    });
  }

  return inspectionDaysArray;
}

export function getInspectionMonths(
  inspectionDays: InspectionDay[]
): InspectionMonth[] {
  const inspectionMonthsDictionary = groupBy(inspectionDays, (inspectionDay) =>
    inspectionDay.date.setDayOfMonth(1).toString()
  );

  const inspectionMonthsArray: InspectionMonth[] = [];
  for (const [key, value] of Object.entries(inspectionMonthsDictionary)) {
    inspectionMonthsArray.push({
      firstDayOfMonth: LocalDate.fromString(key),
      inspectionDays: value
    });
  }

  return inspectionMonthsArray.sort((monthA, monthB) =>
    LocalDate.compare(monthA.firstDayOfMonth, monthB.firstDayOfMonth)
  );
}
