import { Row } from "@ailo/primitives";
import { ErrorCard, Grid, useModalVisibility } from "@ailo/ui";
import React, { memo, ReactElement, useState } from "react";
import styled from "styled-components/native";
import { EmptyGridCard } from "../../EmptyGridCard";
import { ReconciliationPeriod } from "../ReconciliationViewScreen/types";
import { AdjustmentsFilterBar } from "./AdjustmentsFilterBar";
import { columns, loadingColumns, loadingRows } from "./gridConfig";
import { RelayPaginationNavigation } from "./RelayPaginationNavigation";
import { RemoveAdjustmentModal } from "./RemoveAdjustmentModal";
import { AdjustmentListFilter } from "./types";
import { useAdjustmentGridData } from "./useAdjustmentGirdData";

interface AdjustmentListProps {
  header: ReactElement;
  period: ReconciliationPeriod;
}

const PAGE_SIZE = 20;
type ID = string;

function AdjustmentListComponent({
  header,
  period
}: AdjustmentListProps): ReactElement {
  const [filter, setFilter] = useState<AdjustmentListFilter>("current");
  const modalVisibility = useModalVisibility<ID>();
  const gridData = useAdjustmentGridData({
    filter,
    period,
    pageSize: PAGE_SIZE,
    onDeletePress: modalVisibility.show
  });

  const headerCaption = (
    <>
      {header}
      <AdjustmentsFilterBar filter={filter} setFilter={setFilter} />
    </>
  );

  if (gridData.loading) {
    return (
      <Grid
        headerCaption={headerCaption}
        columns={loadingColumns}
        rows={loadingRows}
        rowStyle={{ borderWidth: 0 }}
      />
    );
  }

  if (gridData.error) {
    return (
      <Grid.Placeholder headerCaption={headerCaption}>
        <ErrorCard
          message={"There’s a problem loading adjustments"}
          onReload={gridData.refetch}
        />
      </Grid.Placeholder>
    );
  }

  if (gridData.rows.length === 0) {
    return (
      <Grid.Placeholder headerCaption={headerCaption}>
        <EmptyGridCard
          primaryText={"Adjustments"}
          secondaryText={"You have no adjustments"}
        />
      </Grid.Placeholder>
    );
  }

  return (
    <>
      {modalVisibility.isVisible && (
        <RemoveAdjustmentModal
          adjustmentId={modalVisibility.data}
          removeFromReportPeriodId={period.id}
          onCancelPress={modalVisibility.hide}
          onRemovePress={modalVisibility.hide}
        />
      )}
      <Grid
        columns={columns}
        rows={gridData.rows}
        headerCaption={headerCaption}
        footerCaption={
          <FooterContainer>
            <RelayPaginationNavigation
              pageSize={PAGE_SIZE}
              totalCount={gridData.total}
              pageInfo={gridData.pageInfo}
              fetchMore={gridData.fetchMore}
            />
          </FooterContainer>
        }
      />
    </>
  );
}

const FooterContainer = styled(Row)`
  align-items: center;
  justify-content: center;
  margin: 24px 0 32px 0;
`;

export const AdjustmentList = memo(AdjustmentListComponent);
