import React, { useEffect } from "react";
import { View } from "react-native";
import { SFC } from "@ailo/primitives";
import { BankAccountMethod, BankAccountTypeName } from "@ailo/domains";
import { AccountNameInput, AccountNumberInput, BSBInput } from "./components";
import { useFormContext } from "react-hook-form";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";

interface Props {
  bankAccountMethod: BankAccountMethod;
}

const BankAccountInput: SFC<Props> = ({ bankAccountMethod, style }) => {
  const { setValue } = useFormContext<AddBillFormData>();

  useEffect(() => {
    setValue("payment.type", BankAccountTypeName, { shouldValidate: true });
  }, [setValue]);

  if (
    bankAccountMethod.accountName == null ||
    bankAccountMethod.bsb == null ||
    bankAccountMethod.accountNumber == null
  )
    throw new Error(
      `Supplier has bank account but missing data ${JSON.stringify(
        bankAccountMethod
      )}`
    );

  return (
    <View style={style} testID={"BankAccountFormGroup"}>
      <AccountNameInput accountName={bankAccountMethod.accountName} />
      <View style={{ flexDirection: "row", marginTop: 24 }}>
        <BSBInput bsb={bankAccountMethod.bsb} style={{ width: 150 }} />
        <AccountNumberInput
          accountNumber={bankAccountMethod.accountNumber}
          style={{ marginLeft: 16, width: 250 }}
        />
      </View>
    </View>
  );
};

export { BankAccountInput };
