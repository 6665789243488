import { Money } from "@ailo/money";

export function sumMoney(...amounts: (Money | undefined)[]): Money {
  return (
    amounts.reduce(
      (prev, curr) => (prev ?? Money.zero()).add(curr ?? Money.zero()),
      Money.zero()
    ) ?? Money.zero()
  );
}
