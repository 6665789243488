import {
  useGetManagementPortfolioTeamsQuery,
  GetManagementPortfolioTeamsQuery
} from "local/graphql";
import { AiloRN } from "@ailo/ailorn";
import { ApolloQueryResult } from "@apollo/client";
import { isPresent } from "ts-is-present";

export interface Team {
  ailorn: AiloRN<"propertymanagement:team">;
  name: string;
}

export function useGetManagementPortfolioTeams(
  portfolioAilorn: AiloRN<"propertymanagement:managementfolio">
): {
  data: Team[] | undefined;
  loading: boolean;
  error?: Error | undefined;
  refetch: () => Promise<ApolloQueryResult<GetManagementPortfolioTeamsQuery>>;
} {
  const { data, ...rest } = useGetManagementPortfolioTeamsQuery({
    variables: {
      managementPortfolioId: portfolioAilorn.internalId,
      managementsFilter: {
        isCurrent: true
      }
    }
  });
  const teams = getTeamsFromManagementPortfolioTeamsQuery(data);
  return { data: teams, ...rest };
}

export function getTeamsFromManagementPortfolioTeamsQuery(
  portfolio?: GetManagementPortfolioTeamsQuery
): Team[] | undefined {
  return portfolio?.managementFolio?.allManagements.items
    .map((portfolioToManagement) => {
      const team = portfolioToManagement.management.team;
      if (!team || !team.name) return null;
      const ailorn = AiloRN.from(team?.id, {
        expected: "propertymanagement:team"
      });
      return { ailorn, name: team.name };
    })
    .filter(isPresent);
}
