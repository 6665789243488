import React, { ReactElement, ReactNode } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { EndManagementFormData } from "./EndManagementFormData";

export function EndManagementFormContext({
  children
}: {
  children: ReactNode;
}): ReactElement {
  const formMethods = useForm<EndManagementFormData>({
    mode: "onChange",
    shouldFocusError: true
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
}
