import { useTrustAccountAilorn } from "local/common";
import { useLatestReconciliationReportPeriodQuery } from "local/graphql";
import { useMemo } from "react";
import { parseReportPeriodFragment } from "../graphql/parseReportPeriodFragment";
import { ReportPeriodFragment } from "../graphql/types";

type LatestPeriodReturn =
  | { loading: true }
  | {
      loading: false;
      reportPeriod: ReportPeriodFragment | undefined;
      error: boolean;
      refetch: () => unknown;
    };

export function useLatestReportPeriodData(): LatestPeriodReturn {
  const trustAccountAilorn = useTrustAccountAilorn();
  const variables = trustAccountAilorn ? { trustAccountAilorn } : undefined;

  const { loading, data, refetch } = useLatestReconciliationReportPeriodQuery({
    variables: variables,
    skip: !variables
  });
  const edges = data?.reconciliationReportPeriods.edges;
  const reportPeriod = (edges ?? [])[0]?.node;

  const parsedData = useMemo(() => {
    return reportPeriod ? parseReportPeriodFragment(reportPeriod) : undefined;
  }, [reportPeriod]);

  if (!edges && loading) return { loading: true };
  return { loading: false, reportPeriod: parsedData, error: !edges, refetch };
}
