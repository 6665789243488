import { Colors, Text } from "@ailo/primitives";
import {
  CheckboxInput,
  FormFieldRow,
  LabelledTextInput,
  RadioButtonGroup
} from "@ailo/ui";
import { Action } from "local/domain/project";
import { ActionType } from "local/graphql";
import React, { ReactElement, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";
import { LeaseType, LeaseRenewalFormData } from "../LeaseRenewalFormData";
import { AgreementFixedTermEndDateInput } from "./AgreementFixedTermEndDateInput";
import { AgreementStartDateInput } from "./AgreementStartDateInput";
import { AllowCurrentLeaseToBePeriodicInfo } from "./AllowCurrentLeaseToBePeriodicInfo";

const DEFAULT_LEASE_TYPE = LeaseType.FixedTerm;

interface Props {
  action?: Action<ActionType.AssignTenancyAgreement>;
  style?: StyleProp<ViewStyle>;
  tenancyEndDate?: string;
  currentAgreementStartDate?: string;
  currentAgreementFixedTermEndDate?: string;
  currentAgreementIsAllowedToLapse: boolean;
  rentReviewAllowed: boolean;
  nextTenancyAgreement?: {
    id: string;
    startDate: string;
    fixedTermEndDate?: string;
    allowedToLapseAt: boolean | null;
  };
}

export function LeaseRenewalSection({
  action,
  currentAgreementFixedTermEndDate,
  currentAgreementIsAllowedToLapse,
  currentAgreementStartDate,
  nextTenancyAgreement,
  rentReviewAllowed,
  tenancyEndDate
}: Props): ReactElement {
  return (
    <LeaseRenewalSectionContent
      action={action}
      tenancyEndDate={tenancyEndDate}
      currentAgreementStartDate={currentAgreementStartDate}
      currentAgreementFixedTermEndDate={currentAgreementFixedTermEndDate}
      currentAgreementIsAllowedToLapse={currentAgreementIsAllowedToLapse}
      rentReviewAllowed={rentReviewAllowed}
      nextTenancyAgreement={nextTenancyAgreement}
    />
  );
}

function LeaseRenewalSectionContent({
  action,
  style,
  tenancyEndDate,
  currentAgreementStartDate,
  currentAgreementFixedTermEndDate,
  currentAgreementIsAllowedToLapse,
  rentReviewAllowed,
  nextTenancyAgreement
}: Props): ReactElement {
  const { control } = useFormContext<LeaseRenewalFormData>();
  const leaseTypes = useMemo(
    () => [
      {
        value: LeaseType.FixedTerm,
        label: LeaseType.FixedTerm
      },
      {
        value: LeaseType.Periodic,
        label: LeaseType.Periodic
      },
      ...(!action
        ? [
            {
              value: LeaseType.AllowCurrentToBePeriodic,
              label: LeaseType.AllowCurrentToBePeriodic,
              disabled:
                !currentAgreementFixedTermEndDate ||
                currentAgreementIsAllowedToLapse ||
                !!nextTenancyAgreement
            }
          ]
        : [])
    ],
    [
      action,
      currentAgreementFixedTermEndDate,
      currentAgreementIsAllowedToLapse,
      nextTenancyAgreement
    ]
  );

  return (
    <View>
      <Controller
        control={control}
        name={"leaseType"}
        defaultValue={DEFAULT_LEASE_TYPE}
        render={({ onChange, value }) => (
          <View style={style}>
            <Text.BodyS
              color={Colors.TEXT.DARK.PLACEHOLDER}
              style={{ paddingBottom: 10 }}
            >
              {"Lease Type"}
            </Text.BodyS>
            <RadioButtonGroup
              options={leaseTypes}
              onChange={onChange}
              value={value}
              style={{ marginBottom: 32 }}
            />
            <Text.BodyL weight={"medium"}>{value}</Text.BodyL>
            {value === LeaseType.AllowCurrentToBePeriodic &&
            !!currentAgreementFixedTermEndDate ? (
              <AllowCurrentLeaseToBePeriodicInfo
                style={{ flex: 1, marginTop: 24 }}
                agreementEndDate={currentAgreementFixedTermEndDate}
              />
            ) : (
              <FormFieldRow style={{ marginTop: 20 }}>
                <AgreementStartDateInput
                  currentAgreementStartDate={currentAgreementStartDate}
                  tenancyEndDate={tenancyEndDate}
                />
                {value === LeaseType.FixedTerm && (
                  <AgreementFixedTermEndDateInput
                    tenancyEndDate={tenancyEndDate}
                  />
                )}
              </FormFieldRow>
            )}
          </View>
        )}
      />
      {!action && (
        <>
          <Text.BodyL weight={"medium"} style={{ marginTop: 32 }}>
            {"Rent Review"}
          </Text.BodyL>
          <Controller
            name={"rentReviewChecked"}
            control={control}
            defaultValue={false}
            render={({ value, onChange }) => (
              <CheckboxInput
                disabled={!rentReviewAllowed}
                label={"Add a rent review after completing the lease"}
                style={{ marginTop: 26 }}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </>
      )}
    </View>
  );
}

// TODO [MG-463] maybe clean this up?
LeaseRenewalSection.Loading = function Loading(): ReactElement {
  return (
    <View>
      <RadioButtonGroup.Loading
        style={{ marginTop: 26 }}
        options={[{ textWidth: 120 }, { textWidth: 150 }, { textWidth: 290 }]}
      />
      <Text.BodyS.Loading width={120} style={{ marginTop: 34 }} />
      <View
        style={{
          marginTop: 20,
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <LabelledTextInput.Loading style={{ flex: 1, marginRight: 16 }} />
        <LabelledTextInput.Loading style={{ flex: 1 }} />
      </View>
    </View>
  );
};
