import { IconProps } from "@ailo/primitives";
import React from "react";
import { UnverifiedPersonIcon } from "./UnverifiedPersonIcon";
import { VerifiedPersonIcon } from "./VerifiedPersonIcon";

interface Props extends IconProps {
  verified?: boolean;
}

export function PersonVerificationIcon({
  verified,
  ...iconProps
}: Props): React.ReactElement {
  return verified ? (
    <VerifiedPersonIcon {...iconProps} />
  ) : (
    <UnverifiedPersonIcon {...iconProps} />
  );
}
