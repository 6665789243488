import React, { useCallback } from "react";
import { PaymentHistoryIcon } from "@ailo/primitives";
import { DropdownMenuOption, useGlobalModal } from "@ailo/ui";
import {
  ManagementCannotBeEndedProblem,
  ManagementEndDetailsFragment,
  PlatformFeatureId
} from "local/graphql";
import { useHasFeature } from "@ailo/domains";
import { EndManagementModalForm } from "local/modals/EndManagementModalForm";

export function useEndManagementOption(
  managementEndDetails: ManagementEndDetailsFragment
): DropdownMenuOption | null {
  const hasEndManagementFeature = useHasFeature(
    PlatformFeatureId.EndManagement
  );

  const { id, endAbility } = managementEndDetails;

  const globalModal = useGlobalModal();
  const openEndManagementModal = useCallback(() => {
    globalModal.show(<EndManagementModalForm managementId={id} />);
  }, [globalModal, id]);

  if (managementEndDetails.endDate || !hasEndManagementFeature) return null;

  const tooltip =
    endAbility.problem === ManagementCannotBeEndedProblem.TenancyEndDateMissing
      ? "Tenancy must be ending or voided to end this management"
      : undefined;

  return {
    label: "End management",
    icon: <PaymentHistoryIcon />,
    onSelect: openEndManagementModal,
    disabled: !endAbility.canBeEnded,
    tooltip
  };
}
