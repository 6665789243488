import { DropdownMenuOption } from "@ailo/ui";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { EntranceIcon } from "@ailo/primitives";
import React from "react";
import { Options } from "./ManageMenu";

export function useGetInspectionsOption(
  inspections: Options<{}>
): DropdownMenuOption | undefined {
  const hasRoutineInspectionFeature = useHasFeature(
    PlatformFeatureId.CreateRoutineInspectionProject
  );

  return inspections && hasRoutineInspectionFeature
    ? {
        label: "Inspections",
        icon: <EntranceIcon />,
        onSelect: () => inspections.onPress(inspections),
        disabled: false
      }
    : undefined;
}
