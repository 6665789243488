import React, { ReactElement, useCallback } from "react";
import { EndTenancyDetailsFragment } from "local/graphql";
import { BaseActionActivity } from "../../BaseActionActivity";
import { ActionActivitySectionHeader } from "../../ActionActivitySectionHeader";

import { ManageMenu } from "./ManageMenu";
import { useUncompleteAction } from "local/domain/project";
import { EndTenancyDescriptionList } from "./EndTenancyDescriptionList";
interface Props {
  projectId: string;
  actionId: string;
  tenancy: EndTenancyDetailsFragment;
}

export function EndTenancyDetailsView({
  projectId,
  actionId,
  tenancy
}: Props): ReactElement {
  const [uncompleteAction] = useUncompleteAction({
    projectId
  });

  const onCancelVacate = useCallback(async () => {
    await uncompleteAction(actionId);
  }, [actionId, uncompleteAction]);

  return (
    <BaseActionActivity>
      <ActionActivitySectionHeader
        style={{ marginBottom: 20 }}
        title={"End tenancy"}
        rightColumn={
          <ManageMenu tenancy={tenancy} onCancelVacate={onCancelVacate} />
        }
      />
      <EndTenancyDescriptionList tenancy={tenancy} />
    </BaseActionActivity>
  );
}
