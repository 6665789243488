import React from "react";
import { EditButton } from "@ailo/ui";
import { StyleProp, ViewStyle } from "react-native";
import { ActionActivitySectionHeader } from "./ActionActivitySectionHeader";

interface Props {
  title: string;
  onPress?: () => void;
  editButtonDisabledTooltipText?: string;
  style?: StyleProp<ViewStyle>;
  displayEditButton?: boolean;
}

export function EditSectionHeader({
  title,
  onPress,
  style,
  editButtonDisabledTooltipText,
  displayEditButton = true
}: Props): React.ReactElement {
  return (
    <ActionActivitySectionHeader
      style={style}
      title={title}
      rightColumn={
        displayEditButton &&
        (onPress && !editButtonDisabledTooltipText ? (
          <EditButton onPress={onPress} />
        ) : (
          <EditButton.Disabled tooltipText={editButtonDisabledTooltipText} />
        ))
      }
    />
  );
}
