import { LocalDate } from "@ailo/date";
import { RawRow, RevenueReportRow } from "./getRowColumns";

export function isFeeNameInFeeCategoriesFilter(feeCategories?: string[]) {
  return (row: RawRow): boolean =>
    feeCategories === undefined ||
    feeCategories.length === 0 ||
    row.feeName === "" ||
    !!(row?.feeName && feeCategories?.includes(row.feeName.toLowerCase()));
}

export function timeOutsideOfFilter(startDate: LocalDate, endDate: LocalDate) {
  return (row: RevenueReportRow): boolean =>
    row.period.endDate.isSameOrAfter(startDate) &&
    row.period.startDate.isSameOrBefore(endDate ?? LocalDate.today());
}
