import { ProjectType } from "local/graphql";
import React, { useCallback, useEffect } from "react";
import { ProjectListFilterType } from "../types";
import { UpdateProjectFilterStateCallback } from "../useProjectListFilterState";
import { ProjectTypesFilter } from "./ProjectTypesFilter";

export function useProjectTypesFilter({
  isFilterDisabled = false,
  projectTypes,
  updateFilterState
}: {
  isFilterDisabled?: boolean;
  projectTypes?: string[];
  updateFilterState: UpdateProjectFilterStateCallback;
}): ProjectListFilterType {
  useEffect(() => {
    if (isFilterDisabled && projectTypes?.length) {
      updateFilterState({ projectTypes: null });
    }
  }, [isFilterDisabled, projectTypes, updateFilterState]);

  const onChangeFilter = useCallback(
    (values: ProjectType[]) => {
      updateFilterState({ projectTypes: values.sort() });
    },
    [updateFilterState]
  );

  return {
    isFilterActive: !!projectTypes?.length,
    component: isFilterDisabled ? null : (
      <ProjectTypesFilter
        key={"ProjectList-ProjectTypesFilter"}
        onChange={onChangeFilter}
        values={projectTypes || []}
      />
    )
  };
}
