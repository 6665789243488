import React, { ReactElement } from "react";
import { BaseActionActivity } from "../BaseActionActivity";
import { Link, Text } from "@ailo/primitives";
import { ActionType, useGetProjectActionsQuery } from "local/graphql";
import { usePressableNavigation } from "@ailo/services";
import { StackActions } from "@react-navigation/native";
import { Screens } from "local/common";

export function InspectionCompletedView({
  projectId
}: {
  projectId: string;
}): ReactElement {
  const { data, loading } = useGetProjectActionsQuery({
    variables: {
      projectId,
      pageSize: 10
    }
  });

  if (loading && !data) {
    return <Text.Loading variant={"BodyM"} width={100} />;
  }

  const generateInspectionReportAction = data?.project?.actions?.items.find(
    (action) => action.type === ActionType.GenerateInspectionReport
  );

  return (
    <InspectionCompletedViewUI
      projectId={projectId}
      generateInspectionReportActionId={generateInspectionReportAction?.id}
    />
  );
}

function InspectionCompletedViewUI({
  projectId,
  generateInspectionReportActionId
}: {
  projectId: string;
  generateInspectionReportActionId?: string;
}): React.ReactElement {
  const linkProps = usePressableNavigation({
    link: {
      to: `/projects/${projectId}?actionId=${generateInspectionReportActionId}`,
      action: StackActions.push(Screens.Project, {
        id: projectId,
        actionId: generateInspectionReportActionId
      })
    }
  });
  return (
    <BaseActionActivity>
      <Text.BodyM style={{ marginBottom: 20 }}>
        {"Inspection complete!"}
      </Text.BodyM>
      <Text.BodyM>
        {"You can create a PDF of the report from the "}
        <Link variant={"primary"} {...linkProps}>
          {"finalise report"}
        </Link>
        {" action."}
      </Text.BodyM>
    </BaseActionActivity>
  );
}
