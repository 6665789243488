import { Colors, Text } from "@ailo/primitives";
import { BaseFormSeparator } from "local/modals/ui/BaseFormSeparator";
import React, { ReactElement } from "react";
import { View } from "react-native";
import { EndTenancyData } from "../useGetDataForEndTenancy";
import { EndTenancyDetailsSection } from "./EndTenancyDetailsSection";

export function ReviewEndTenancyStep({
  display,
  onEditPress,
  endTenancyData,
  amountDueAtEndDate
}: {
  display: boolean;
  onEditPress(): void;
  endTenancyData?: EndTenancyData;
  amountDueAtEndDate?: number;
}): ReactElement {
  return (
    <View style={{ display: display ? undefined : "none" }}>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
        {"Please confirm the details are correct"}
      </Text.BodyM>
      <BaseFormSeparator />
      <EndTenancyDetailsSection
        onEditPress={onEditPress}
        endTenancyData={endTenancyData}
        amountDueAtEndDate={amountDueAtEndDate}
      />
    </View>
  );
}
