import { Colors, opacify } from "@ailo/primitives";
import { Modal } from "@ailo/ui";
import { useInspectionPlanner } from "local/modals/InspectionPlanner/InspectionPlannerContext";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { ModalHeader, ModalTitle, ModalCloseButton } from "../../../components";

export function ViewKeysModal({
  visible,
  onClose
}: {
  visible: boolean;
  onClose(): void;
}): React.ReactElement | null {
  const { selectedInspectionDay } = useInspectionPlanner();

  if (!selectedInspectionDay) {
    return null;
  }

  return (
    <Modal visible={visible}>
      <ContentWrapper>
        <ModalHeader>
          <ModalTitle>{"Keys"}</ModalTitle>
          <ModalCloseButton onPress={onClose} />
        </ModalHeader>
      </ContentWrapper>
    </Modal>
  );
}

const ContentWrapper = styled(View)`
  width: 640px;
  background-color: ${Colors.WHITE};
  border-radius: 12px;
  box-shadow: 0 1px 20px ${opacify(Colors.BLACK, 0.4)};
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
`;
