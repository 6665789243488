import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback
} from "react";
import { BasePropertyOnboardingListHeader } from "./BasePropertyOnboardingListHeader";
import { useUnapproveMigratingManagements } from "../hooks";
import { useToastContext } from "@ailo/ui";
import { capitalize } from "lodash";
import { PropertyOnboardingListRequestParams } from "../types";
import { UnapproveMigratingManagementsMutation } from "local/graphql";

interface Props {
  selectedMigratingManagementIds: string[];
  setSelectedMigratingManagementIds: Dispatch<SetStateAction<string[]>>;
  lastRequestParams: PropertyOnboardingListRequestParams | null;
  fullySelected: boolean;
  setFailedProperties: Dispatch<SetStateAction<number>>;
  refetch: () => void;
}

export function ApprovedPropertyOnboardingListHeader({
  selectedMigratingManagementIds,
  setSelectedMigratingManagementIds,
  lastRequestParams,
  fullySelected,
  setFailedProperties,
  refetch
}: Props): ReactElement {
  const toast = useToastContext();

  const propertyPlurality =
    selectedMigratingManagementIds.length === 1 ? "property" : "properties";

  const onUnapproveError = useCallback(() => {
    toast.show({
      type: "error",
      message: `Unable to remove ${selectedMigratingManagementIds.length} ${propertyPlurality} from approved. Please try again`
    });
    setSelectedMigratingManagementIds([]);
  }, [
    selectedMigratingManagementIds.length,
    setSelectedMigratingManagementIds,
    toast,
    propertyPlurality
  ]);

  const onUnapproveSuccess = useCallback(
    (data: UnapproveMigratingManagementsMutation) => {
      setSelectedMigratingManagementIds([]);

      const successes = data.unapproveMigratingManagements?.successes ?? [];
      if (successes.length !== 0) {
        toast.show({
          type: "success",
          message: `${successes.length} ${
            successes.length === 1 ? "property" : "properties"
          } removed from approved`
        });
      }

      const errors = data.unapproveMigratingManagements?.errors ?? [];
      setFailedProperties(errors.length);

      refetch();
    },
    [setSelectedMigratingManagementIds, toast, setFailedProperties, refetch]
  );

  const [unapproveMigratingManagements] = useUnapproveMigratingManagements({
    lastRequestParams,
    selectedMigratingManagementIds,
    onError: onUnapproveError,
    onSuccess: onUnapproveSuccess
  });

  return (
    <BasePropertyOnboardingListHeader
      onUnselect={() => setSelectedMigratingManagementIds([])}
      fullySelected={fullySelected}
      label={`${selectedMigratingManagementIds.length} selected`}
      action={{
        label: `Unapprove ${capitalize(propertyPlurality)}`,
        onConfirm: unapproveMigratingManagements,
        modalTitle: `Unapprove ${capitalize(propertyPlurality)}`,
        modalBody: `${selectedMigratingManagementIds.length} ${propertyPlurality} will be moved to the awaiting approval list.`
      }}
    />
  );
}
