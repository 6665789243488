import React from "react";
import { AddButton } from "../AddButton";
import { AgencyFeeBlueprintQuery } from "../ManagementFeeBlueprintModals";
import { ChargeAFeeButton } from "./ChargeAFeeButton";

export function ManagementFeeBlueprintFooter({
  managementId,
  showChargeAFeeButton,
  onAddBlueprintPress,
  agencyBlueprintsQuery
}: {
  managementId: string;
  showChargeAFeeButton: boolean;
  onAddBlueprintPress: () => void;
  agencyBlueprintsQuery: Omit<AgencyFeeBlueprintQuery, "options">;
}): React.ReactElement {
  const addManagementFeeBlueprintButton = !agencyBlueprintsQuery.loaded ? (
    <AddButton.Loading />
  ) : agencyBlueprintsQuery.error ? (
    <AddButton.Error
      message={"There was a problem loading agency fee templates"}
      onRetry={() => void agencyBlueprintsQuery.refetch}
    />
  ) : (
    <AddButton
      label={"Add a manual fee template"}
      onPress={onAddBlueprintPress}
    />
  );

  return (
    <>
      {showChargeAFeeButton && (
        <ChargeAFeeButton
          managementId={managementId}
          style={{ marginBottom: 4 }}
        />
      )}
      {addManagementFeeBlueprintButton}
    </>
  );
}
