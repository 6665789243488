import { ScreenComponent } from "@ailo/services";
import React, { ReactElement } from "react";
import { SettingsTabScreenContent } from "./SettingsTabScreenContent";

export function SettingsTabScreen(): ReactElement {
  return (
    <ScreenComponent analytics={false}>
      <SettingsTabScreenContent />
    </ScreenComponent>
  );
}
