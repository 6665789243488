import { AFC } from "@ailo/primitives";
import React from "react";
import { TextCell } from "@ailo/ui";

interface Props {
  name: string;
}

interface Statics {
  Loading: React.FC;
}

const Supplier: AFC<Props> & Statics = ({ name }) => {
  return <TextCell>{name}</TextCell>;
};

Supplier.Loading = TextCell.Loading;

export { Supplier };
