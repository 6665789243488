import React, { ReactElement } from "react";
import { BondDetailsForm } from "../common/BondDetailsForm";
import {
  Button,
  SidebarContent,
  SpinnerOverlay,
  useToastContext
} from "@ailo/ui";
import { useFormContext } from "react-hook-form";
import { BondDetailsFormData } from "../common/BondDetailsFormData";
import { getState, Row } from "local/common";
import { parseTenancyDetailsData } from "../common/parseTenancyDetailsData";
import { useGetTenantsAndPropertyDetailsQuery } from "local/graphql";
import { BondDetailsProps } from "../common/BondDetailsProps";
import { useUpdateTenancyBond } from "./useUpdateTenancyBond";
import { useBondAnalytics } from "local/domain/bond";

export function EditBondDetailsSidebarContent({
  tenancyId,
  managementId,
  onClose,
  title = "",
  onSuccessMessage = "",
  onFailureMessage = ""
}: BondDetailsProps): ReactElement {
  const { data, loading } = useGetTenantsAndPropertyDetailsQuery({
    variables: { tenancyId }
  });
  const { onBondUpdated } = useBondAnalytics();
  const toasts = useToastContext();

  const { updateTenancyBond } = useUpdateTenancyBond({
    onCompleted: (result) => {
      if (data?.tenancy?.bond) {
        const { amount: originalAmount, reference: originalReference } =
          data?.tenancy?.bond;

        const updatedBond = result?.data?.updateTenancyBond;
        const state = getState(data?.tenancy?.property.address.state);

        onBondUpdated({
          bondId: updatedBond?.id,
          managementId,
          state,
          original: {
            amount: originalAmount,
            reference: originalReference || undefined
          },
          updated: {
            amount: updatedBond?.amount || { cents: 0 },
            reference: updatedBond?.reference || undefined
          }
        });
      }

      toasts.show({ type: "success", message: onSuccessMessage });
      onClose();
    },
    onError: () => {
      toasts.show({
        type: "error",
        message: onFailureMessage
      });
      onClose();
    }
  });
  const { handleSubmit } = useFormContext<BondDetailsFormData>();

  if (loading) {
    return <SpinnerOverlay />;
  }

  const footerRow = (
    <Row>
      <Button.Secondary onPress={onClose}>{"Cancel"}</Button.Secondary>
      <Button.Primary
        onPress={handleSubmit(async (data) => {
          await updateTenancyBond(managementId, tenancyId, data);
        })}
      >
        {"Update"}
      </Button.Primary>
    </Row>
  );
  return (
    <SidebarContent
      title={title}
      footer={footerRow}
      footerStyle={{ borderTopWidth: 1 }}
    >
      <BondDetailsForm tenancyDetails={parseTenancyDetailsData(data)} />
    </SidebarContent>
  );
}
