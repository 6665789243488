import { Colors, Text } from "@ailo/primitives";
import { Money } from "@ailo/ui";
import { ManagementFeeBlueprint, TaxTreatment } from "local/graphql";
import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

interface AmountAndTaxProps {
  managementFeeBlueprint: Pick<
    ManagementFeeBlueprint,
    "fixedAmount" | "oneWeekRentPercentage" | "taxTreatment"
  >;
}

export function AmountAndTax({
  managementFeeBlueprint
}: AmountAndTaxProps): ReactElement {
  const { fixedAmount, oneWeekRentPercentage, taxTreatment } =
    managementFeeBlueprint;

  const amountText =
    fixedAmount != undefined
      ? Money.fromCents(fixedAmount?.cents).format()
      : oneWeekRentPercentage != undefined
      ? `${oneWeekRentPercentage} weeks of rent`
      : undefined;

  const taxText =
    taxTreatment === TaxTreatment.Inclusive ? "incl. GST" : "excl. GST";

  return (
    <AmountAndTaxContainer>
      <Text.BodyM style={{ textAlign: "right" }} weight={"medium"}>
        {amountText}
      </Text.BodyM>
      {taxTreatment !== TaxTreatment.NoTax && (
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{taxText}</Text.BodyS>
      )}
    </AmountAndTaxContainer>
  );
}

const AmountAndTaxContainer = styled(View)`
  align-items: flex-end;
  justify-content: space-between;
`;
