import React, { useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import {
  CheckBoxOnIcon,
  CheckBoxPartialIcon,
  Colors,
  opacify,
  Pressable,
  Text
} from "@ailo/primitives";
import { Button, ConfirmModal } from "@ailo/ui";

interface Props {
  onUnselect: () => void;
  fullySelected: boolean;
  label: string;
  action: {
    label: string;
    onConfirm: () => void;
    modalTitle: string;
    modalBody: string;
  };
}

export function BasePropertyOnboardingListHeader({
  onUnselect,
  fullySelected,
  label,
  action
}: Props): React.ReactElement {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = (): void => setModalVisible(true);
  const onConfirm = (): void => {
    action.onConfirm();
    setModalVisible(false);
  };
  const onCancel = (): void => {
    setModalVisible(false);
  };

  const CheckBoxIcon = fullySelected ? CheckBoxOnIcon : CheckBoxPartialIcon;

  return (
    <Container>
      <LeftContainer>
        <Pressable onPress={onUnselect}>
          <CheckBoxIcon color={Colors.WHITE} height={20} width={20} />
        </Pressable>
        <Label>
          <Text.BodyS
            numberOfLines={1}
            weight={"medium"}
            color={Colors.TEXT.LIGHT.PRIMARY}
          >
            {label}
          </Text.BodyS>
        </Label>
      </LeftContainer>

      <RightContainer>
        <Button.Secondary
          type={"small"}
          onPress={showModal}
          contentColor={Colors.OCEAN}
        >
          {action.label}
        </Button.Secondary>

        <ConfirmModal
          visible={modalVisible}
          title={action.modalTitle}
          onConfirm={onConfirm}
          onCancel={onCancel}
        >
          <Text.BodyM>{action.modalBody}</Text.BodyM>
        </ConfirmModal>
      </RightContainer>
    </Container>
  );
}

const Container = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
  align-items: center;
  background-color: ${Colors.OCEAN};
  border-bottom-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`;

const LeftContainer = styled(View)`
  flex-direction: row;
  justify-self: flex-start;
  align-items: center;
  padding-top: 4px;
`;

const Label = styled(View)`
  padding-left: 12px;
`;

const RightContainer = styled(View)`
  justify-self: flex-end;
  margin-bottom: 8px;
`;
