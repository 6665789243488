import { VacatingReason } from "local/graphql";

export const EndTenancyFormFields = {
  tenancyId: "tenancyId",
  endDate: "endDate",
  reason: "reason",
  notes: "notes",
  rentLiabilityEndDate: "rentLiabilityEndDate"
} as const;

export interface EndTenancyFormData {
  [EndTenancyFormFields.tenancyId]: string;
  [EndTenancyFormFields.endDate]: string;
  [EndTenancyFormFields.reason]: VacatingReason;
  [EndTenancyFormFields.notes]: string;
}
