import React, { ReactElement, useEffect } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import {
  Colors,
  Text,
  opacify,
  PropertyIcon,
  useSynchronisedLoad
} from "@ailo/primitives";
import styled from "styled-components/native";
import { useNavigation } from "local/common";
import { isPresent } from "ts-is-present";
import { useGetBasicPropertyDetailsQuery } from "local/graphql";
import { PropertyNotesButton } from "./PropertyNotesButton";
import { PropertyKeysButton } from "./PropertyKeys/PropertyKeysButton";

interface Props {
  managementId: string;
  style?: StyleProp<ViewStyle>;
  onViewKeysPress: () => void;
}

export function BasicPropertyDetails({
  managementId,
  onViewKeysPress,
  style
}: Props): ReactElement {
  const navigation = useNavigation();
  const { data, loading } = useGetBasicPropertyDetailsQuery({
    variables: { id: managementId }
  });

  const isLoading = useSynchronisedLoad(loading && !data);
  const management = data?.management;
  const address = management?.property?.address;
  const addressTitle = [address?.unitStreetNumber, address?.streetName]
    .filter(isPresent)
    .join(" ");
  const addressSubHeader = [address?.suburb, address?.state, address?.postcode]
    .filter(isPresent)
    .join(" ");

  useEffect(() => {
    if (address) {
      navigation.setOptions({
        title: [addressTitle, addressSubHeader].filter(isPresent).join(" ")
      });
    }
  }, [address, addressSubHeader, addressTitle, navigation]);

  if (isLoading) {
    return <Loading style={style} />;
  }

  return (
    <DetailsWrapper style={style} testID={"BasicPropertyDetails"}>
      <PropertyImageContainer>
        <PropertyIcon
          color={opacify(Colors.SPACE_BLACK, 0.5)}
          width={36}
          height={36}
        />
      </PropertyImageContainer>
      <View style={{ paddingLeft: 20, alignItems: "flex-start", flex: 1 }}>
        <Text.DisplayM color={Colors.TEXT.DARK.PRIMARY}>
          {addressTitle}
        </Text.DisplayM>
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
          {addressSubHeader}
        </Text.BodyS>
        <View
          style={{
            marginTop: 12,
            alignItems: "flex-start",
            flex: 1,
            flexDirection: "row"
          }}
        >
          <PropertyNotesButton managementId={managementId} />
          <PropertyKeysButton onViewKeysPress={onViewKeysPress} />
        </View>
      </View>
    </DetailsWrapper>
  );
}

function Loading({ style }: { style: StyleProp<ViewStyle> }): ReactElement {
  return (
    <DetailsWrapper style={style}>
      <PropertyImageContainer />
      <View style={{ paddingLeft: 20 }}>
        <Text.DisplayM.Loading style={{ paddingTop: 8 }} width={160} />
        <Text.BodyS.Loading style={{ paddingTop: 8 }} width={120} />
      </View>
    </DetailsWrapper>
  );
}

const DetailsWrapper = styled(View)`
  flex-direction: row;
`;

const PropertyImageContainer = styled(View)`
  width: 88px;
  height: 88px;
  border-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.WHITE};
  box-shadow: 0px 1px 4px ${opacify(Colors.SPACE_BLACK, 0.2)};
`;
