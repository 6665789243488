import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { ApprovedPropertyOnboardingListHeader } from "../../PropertyOnboardingListHeader";
import { ApprovedPropertyOnboardingListSelect } from "./useApprovedPropertyOnboardingListSelect";
import { ApprovedPropertyOnboardingListQuery } from "../../hooks";
import { PropertyOnboardingListFailedPropertyActionModal } from "../../PropertyOnboardingListFailedPropertyActionModal";

interface Params {
  tabNavigation: ReactElement;
  queryData: ApprovedPropertyOnboardingListQuery;
  select: ApprovedPropertyOnboardingListSelect;
}

export interface ApprovedPropertyOnboardingListContent {
  headerCaptionContent: (showGridHeader: boolean) => ReactElement;
  headerOverride: ReactElement | null;
  failedPropertiesModal: ReactElement | null;
}

export function useApprovedPropertyOnboardingListContent({
  tabNavigation,
  queryData: { refetch, lastRequestParams },
  select: {
    selectedMigratingManagementIds,
    setSelectedMigratingManagementIds,
    selectableMigratingManagementIds
  }
}: Params): ApprovedPropertyOnboardingListContent {
  const [failedProperties, setFailedProperties] = useState(0);

  const headerCaptionContent = useCallback((): React.ReactElement => {
    return tabNavigation;
  }, [tabNavigation]);

  const headerOverride = useMemo(() => {
    return selectedMigratingManagementIds.length !== 0 ? (
      <ApprovedPropertyOnboardingListHeader
        lastRequestParams={lastRequestParams}
        selectedMigratingManagementIds={selectedMigratingManagementIds}
        setSelectedMigratingManagementIds={setSelectedMigratingManagementIds}
        fullySelected={
          selectedMigratingManagementIds.length ===
          selectableMigratingManagementIds.length
        }
        setFailedProperties={setFailedProperties}
        refetch={refetch}
      />
    ) : null;
  }, [
    lastRequestParams,
    refetch,
    selectedMigratingManagementIds,
    selectableMigratingManagementIds.length,
    setSelectedMigratingManagementIds
  ]);

  const failedPropertiesModal = useMemo(() => {
    return failedProperties ? (
      <PropertyOnboardingListFailedPropertyActionModal
        onClose={() => setFailedProperties(0)}
        failedProperties={failedProperties}
      />
    ) : null;
  }, [failedProperties]);

  return {
    headerCaptionContent,
    headerOverride,
    failedPropertiesModal
  };
}
