import { getPriceChangeDecription } from "@ailo/domains";
import { throwIfMutationFailed, useAnalytics } from "@ailo/services";
import { MoneyInterface } from "@ailo/ui";
import { useCallback } from "react";
import { useCancelOneOffFeeMutation } from "local/graphql";

export interface CancelOneOffFeeDetails {
  feeId: string;
  amount: MoneyInterface;
  oneWeekRentPercentage?: number;
  blueprintId: string;
  blueprintAmount?: MoneyInterface;
  blueprintOneWeekRentPercentage?: number;
}

export function useCancelOneOffFee(): [
  cancelOneOffFee: (input: CancelOneOffFeeDetails) => Promise<void>,
  loading: boolean
] {
  const analytics = useAnalytics();

  const [cancelOneOffFeeMutation, { loading: submitting }] =
    useCancelOneOffFeeMutation();

  const cancelOneOffFee = useCallback(
    async ({
      feeId,
      amount,
      oneWeekRentPercentage,
      blueprintId,
      blueprintAmount,
      blueprintOneWeekRentPercentage
    }: CancelOneOffFeeDetails) => {
      if (submitting) return;

      const result = await cancelOneOffFeeMutation({
        variables: {
          input: { id: feeId, archived: true }
        }
      });
      throwIfMutationFailed(result, { dataKey: "updateFee" });

      const priceState =
        amount && blueprintAmount
          ? {
              current: amount,
              original: blueprintAmount
            }
          : oneWeekRentPercentage != undefined && blueprintOneWeekRentPercentage
          ? {
              current: oneWeekRentPercentage,
              original: blueprintOneWeekRentPercentage
            }
          : undefined;

      analytics.track("Fee Cancelled on Property", {
        feeFrequency: "one-off",
        feeBlueprintId: blueprintId,
        feePrice: priceState ? getPriceChangeDecription(priceState) : "Unknown"
      });
    },
    [cancelOneOffFeeMutation, submitting, analytics]
  );

  return [cancelOneOffFee, submitting];
}
