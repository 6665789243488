import { AiloRN } from "@ailo/ailorn";
import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import { useCallback } from "react";
import { useCompleteAssignFormActionMutation } from "local/graphql";

export interface CompleteAssignFormActionInput {
  formAilorn: AiloRN;
  actionId: string;
}

export function useCompleteAssignFormAction(): {
  completeAssignFormAction: (
    input: CompleteAssignFormActionInput
  ) => Promise<void>;
  loading: boolean;
} {
  const toast = useToastContext();

  const [completeAssignFormActionMutation, { loading }] =
    useCompleteAssignFormActionMutation();

  const completeAssignFormAction = useCallback(
    async ({
      formAilorn,
      actionId
    }: CompleteAssignFormActionInput): Promise<void> => {
      try {
        const result = await completeAssignFormActionMutation({
          variables: {
            input: {
              id: actionId,
              formAilorn: formAilorn.toString()
            }
          }
        });
        throwIfMutationFailed(result, {
          dataKey: "completeAssignFormAction"
        });
      } catch (error) {
        AiloSentry.captureException(error);
        toast.showFormSubmitError();
      }
    },
    [completeAssignFormActionMutation, toast]
  );

  return { completeAssignFormAction, loading };
}
