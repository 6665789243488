import { TenancyBond } from "local/tabs";
import { BondMenuOptions } from "./index";

export function createBondMenuOptions({
  bond,
  onEditBondDetailsPress,
  onAddBondDetailsPress,
  tenancyId,
  navigateToClaimBond,
  onViewBondClaimPress,
  onEditBondReferencePress,
  isFormerTenancy
}: {
  bond?: TenancyBond;
  onEditBondDetailsPress: (tenancyId: string) => void;
  onAddBondDetailsPress: (tenancyId: string) => void;
  tenancyId: string;
  navigateToClaimBond: () => void;
  onViewBondClaimPress: (tenancyId: string) => void;
  onEditBondReferencePress: (tenancyId: string) => void;
  isFormerTenancy?: boolean;
}): BondMenuOptions {
  return {
    editBondDetails: () => onEditBondDetailsPress(tenancyId),
    addBondDetails: () => onAddBondDetailsPress(tenancyId),
    navigateToClaimBond,
    viewBondClaim: () => onViewBondClaimPress(tenancyId),
    editBondReference: () => onEditBondReferencePress(tenancyId),
    bond,
    isFormerTenancy
  };
}
