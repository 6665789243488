import React, { ReactElement } from "react";
import { DateInputFormField, LocalDate } from "@ailo/ui";
import { Controller, UseFormMethods, Validate } from "react-hook-form";
import { NewTenancyFormData } from "../NewTenancyForm";

interface StartDateInputProps {
  form: UseFormMethods<NewTenancyFormData>;
  validate: Validate;
  minStartDate?: LocalDate;
  agreementEndDate?: string | null;
  rentReviewDate?: string | null;
}

export function StartDateInput({
  form,
  minStartDate,
  agreementEndDate,
  rentReviewDate,
  validate
}: StartDateInputProps): ReactElement {
  return (
    <Controller
      control={form.control}
      render={({ value, onBlur, onChange }): React.ReactElement => {
        return (
          <DateInputFormField
            webPopoverHorizontalAlign={"start"}
            onBlur={onBlur}
            onChange={(date): void => {
              onChange(date ?? null);

              const defaultEndDate = date
                ? LocalDate.from(date)
                    .add(1, "year")
                    .subtract(1, "day")
                    .toString()
                : null;

              if (!agreementEndDate)
                form.setValue("agreementEndDate", defaultEndDate);
              if (!rentReviewDate)
                form.setValue("rentReviewDate", defaultEndDate);
            }}
            placeholder={"Start date"}
            autoCorrect={false}
            autoCompleteType={"off"}
            allowAnyTypedDate={true}
            value={value}
            minDate={minStartDate?.toString()}
            label={"Agreement Start"}
            error={form.errors.agreementStartDate?.message}
          />
        );
      }}
      name={"agreementStartDate"}
      defaultValue={null}
      rules={{
        required: "Please select a start date",
        validate
      }}
    />
  );
}
