import { useCallback } from "react";
import { DropdownMenuOption, useToastContext } from "@ailo/ui";
import { useNavigateToProperty } from "local/domain/propertyManagement";
import { useProjectAnalytics } from "local/domain/project";
import { useArchiveProject } from "./useArchiveProject";
import { ProjectDetailsFragment } from "local/graphql";
import { Screens, useNavigation } from "local/common";

export function useArchiveProjectOption({
  projectId,
  managementId,
  onArchiveProject,
  icon
}: {
  projectId: string;
  managementId?: string;
  onArchiveProject: (action: boolean) => void;
  icon: React.ReactElement;
}): [DropdownMenuOption | null, (project: ProjectDetailsFragment) => void] {
  const toast = useToastContext();
  const navigation = useNavigation();
  const navigateToProperty = useNavigateToProperty();
  const { onProjectArchived } = useProjectAnalytics(projectId);

  const [archiveProject] = useArchiveProject({
    onError: () => {
      toast.show({
        type: "error",
        message: "Unable to archive Project. Please try again."
      });
    },
    onCompleted: ({ project }) => {
      toast.show({
        type: "success",
        message: "Project archived."
      });
      onProjectArchived(project);
      if (managementId) {
        navigateToProperty(managementId);
      } else {
        navigation.navigate(Screens.ProjectList, {});
      }
    }
  });

  const onArchiveProjectPress = useCallback(() => {
    onArchiveProject(true);
  }, [onArchiveProject]);

  const option = {
    label: "Archive Project",
    icon,
    onSelect: (): void => onArchiveProjectPress()
  };
  return [option, archiveProject];
}
