import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { getEffectiveDateStatus } from "local/tabs";
import { useCallback } from "react";
import {
  CreateRentCreditMutation,
  CreateRentCreditMutationVariables,
  useCreateRentCreditMutation
} from "local/graphql";

export function useCreateRentCredit({
  onCompleted,
  onError
}: {
  onCompleted?: () => void;
  onError?: () => void;
} = {}): [
  (
    input: CreateRentCreditMutationVariables["input"],
    managementId: string,
    tenancyCurrentDate: string
  ) => void,
  MutationResult<CreateRentCreditMutation>
] {
  const analytics = useAnalytics();
  const [createRentCreditMutation, result] = useCreateRentCreditMutation();

  const createRentCredit = useCallback(
    async (
      input: CreateRentCreditMutationVariables["input"],
      managementId: string,
      tenancyCurrentDate: string
    ): Promise<void> => {
      try {
        const result = await createRentCreditMutation({
          variables: {
            input
          }
        });
        throwIfMutationFailed(result, { dataKey: "createRentCredit" });
        onCompleted?.();

        const {
          effectiveDate,
          tenancyId,
          amount,
          notifyInvestors,
          notifyTenants
        } = input;

        analytics.track("Rent Credit Applied", {
          effectiveDate: getEffectiveDateStatus({
            date: effectiveDate,
            tenancyCurrentDate
          }),
          tenancyId,
          managementId,
          amount,
          notifyInvestors,
          notifyTenants
        });
      } catch (error) {
        AiloSentry.captureException(error);
        onError?.();
      }
    },
    [analytics, createRentCreditMutation, onCompleted, onError]
  );

  return [createRentCredit, result];
}
