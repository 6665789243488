import React from "react";
import { usePressableNavigation } from "@ailo/services";
import { ManagementPropertyFragment } from "local/graphql";
import { View } from "react-native";
import styled from "styled-components/native";
import { Badge } from "@ailo/ui";
import { Pressable, Text } from "@ailo/primitives";
import { formatAddress } from "@ailo/domain-helpers";

export function PropertyAddressBadge({
  management
}: {
  management: ManagementPropertyFragment;
}): React.ReactElement {
  const linkProps = usePressableNavigation({
    link: {
      to: `/properties/${management.id}`
    }
  });
  const propertyAddress = formatAddress(management.property.address, {
    format: "street"
  });
  return (
    <BadgeContainer>
      <Badge>
        <Pressable {...linkProps}>
          <Text.BodyXS>{propertyAddress}</Text.BodyXS>
        </Pressable>
      </Badge>
    </BadgeContainer>
  );
}

const BadgeContainer = styled(View)`
  height: 16px;
  flex-direction: row;
  align-items: center;
`;
