import { useHasFeature } from "@ailo/domains";
import {
  didQueryNotLoadYet,
  throwIfQueryFailed,
  useOnFocus
} from "@ailo/services";
import { Money, MoneyInterface } from "@ailo/ui";
import Big from "big.js";
import { useCurrentAgencyOrg } from "local/common";
import {
  FeeBlueprintChargeType,
  FeeBlueprintType,
  PlatformFeatureId,
  TaxTreatment,
  useGetAgencyAndManagementFeeBlueprintsQuery
} from "local/graphql";
import { useMemo } from "react";

interface Props {
  managementId: string;
}

export type OneOffFeeBlueprintOption = {
  label: string;
  /** blueprint ailorn string */
  value: string;
  price?: MoneyInterface;
  priceIncludingTax?: MoneyInterface;
  oneWeekRentPercentage?: number;
  oneWeekRentPercentageIncludingTax?: number;
  taxTreatment: TaxTreatment;
  type: FeeBlueprintType;
  taxCategoryId: string;
  chargeType: FeeBlueprintChargeType;
};

export function useGetOneOffFeeOptions({ managementId }: Props): {
  loading: boolean;
  options: OneOffFeeBlueprintOption[];
} {
  const { id: organisationId } = useCurrentAgencyOrg();

  const hasManagementFeeBlueprintFeature = useHasFeature(
    PlatformFeatureId.ManagementFeeBlueprint
  );

  const blueprintsResult = useGetAgencyAndManagementFeeBlueprintsQuery({
    variables: {
      agencyBlueprintConditions: {
        type: FeeBlueprintType.OneOffFee,
        organisationId,
        archived: false
      },
      managementBlueprintConditions: {
        type: FeeBlueprintType.OneOffFee,
        managementId,
        archived: false
      },
      isAgencyBlueprint: !hasManagementFeeBlueprintFeature,
      isManagementBlueprint: hasManagementFeeBlueprintFeature
    }
  });
  throwIfQueryFailed(blueprintsResult);
  useOnFocus(blueprintsResult.refetch);

  const options = useMemo(() => {
    const { agencyBlueprints, managementBlueprints } =
      blueprintsResult.data ?? {};

    if (agencyBlueprints) {
      return agencyBlueprints.items.map((blueprint) => ({
        label: blueprint.name,
        value: blueprint.ailoRN,
        price: blueprint.fixedAmount ?? undefined,
        oneWeekRentPercentage: blueprint.oneWeekRentPercentage ?? undefined,
        taxTreatment: blueprint.taxTreatment,
        priceIncludingTax: blueprint.fixedAmount
          ? Money.from(blueprint.fixedAmount).multiply(
              blueprint.taxTreatment === TaxTreatment.Exclusive ? 1.1 : 1
            )
          : undefined,
        oneWeekRentPercentageIncludingTax:
          blueprint.oneWeekRentPercentage != undefined
            ? new Big(blueprint.oneWeekRentPercentage)
                .mul(
                  blueprint.taxTreatment === TaxTreatment.Exclusive ? 1.1 : 1
                )
                .round(4)
                .toNumber()
            : undefined,
        taxCategoryId: blueprint.taxCategory.id,
        type: blueprint.type,
        chargeType: blueprint.chargeType
      }));
    }

    if (managementBlueprints) {
      return managementBlueprints.items.map((blueprint) => ({
        label: blueprint.feeBlueprint.name,
        value: blueprint.ailorn,
        price: blueprint.fixedAmount ?? undefined,
        priceIncludingTax: blueprint.fixedAmount
          ? Money.from(blueprint.fixedAmount).multiply(
              blueprint.taxTreatment === TaxTreatment.Exclusive ? 1.1 : 1
            )
          : undefined,
        oneWeekRentPercentage: blueprint.oneWeekRentPercentage ?? undefined,
        oneWeekRentPercentageIncludingTax:
          blueprint.oneWeekRentPercentage != undefined
            ? new Big(blueprint.oneWeekRentPercentage)
                .mul(
                  blueprint.taxTreatment === TaxTreatment.Exclusive ? 1.1 : 1
                )
                .round(4)
                .toNumber()
            : undefined,
        taxTreatment: blueprint.taxTreatment,
        taxCategoryId: blueprint.feeBlueprint.taxCategory.id,
        type: blueprint.feeBlueprint.type,
        chargeType: blueprint.chargeType
      }));
    }

    // Return empty array if query is still loading
    return [];
  }, [blueprintsResult]);

  return { loading: didQueryNotLoadYet(blueprintsResult), options };
}
