import { AiloRN, services } from "@ailo/ailorn";
import { ApolloError } from "@apollo/client";
import moment from "moment-timezone";
import { useCallback } from "react";
import {
  UpcomingAndPaidBillFieldsFragment,
  UpcomingAndPaidFeeFieldsFragment,
  useGetUpcomingAndPaidBillsAndFeesQuery
} from "local/graphql";

export type Expense =
  | UpcomingAndPaidBillFieldsFragment
  | UpcomingAndPaidFeeFieldsFragment;

type Props = {
  payerAilorn: AiloRN;
  pageSize?: number;
};

function getIssueDate(expense: Expense): string | null | undefined {
  return expense.__typename === "Bill"
    ? expense.issueDateV2
    : expense.__typename === "Fee"
    ? expense.createdAt
    : undefined;
}

export function useGetUpcomingAndPaidBillsAndFees({
  payerAilorn,
  pageSize = 3
}: Props): {
  upcomingExpenses: Expense[];
  paidExpenses: Expense[];
  hasNext: boolean;
  loading: boolean;
  refetch: () => Promise<void>;
  error?: ApolloError;
} {
  const { data, error, loading, refetch } =
    useGetUpcomingAndPaidBillsAndFeesQuery({
      variables: {
        payerAilorn: payerAilorn.toString(),
        payerId: payerAilorn.internalId,
        payerIsManagement:
          payerAilorn.nsEntity === services.PropertyManagement.management,
        pageSize
      }
    });
  const { upcomingBills, upcomingFees, paidBills, paidFees } = data ?? {};

  const upcomingExpenses = [
    ...(upcomingFees?.items ?? []),
    ...(upcomingBills?.items ?? [])
  ].slice(0, pageSize);

  const paidExpenses = [...(paidBills?.items ?? []), ...(paidFees?.items ?? [])]
    .sort((a, b) => {
      const dateA = getIssueDate(a);
      const dateB = getIssueDate(b);
      if (!dateA && !dateB) return 0;
      if (!dateA) return 1;
      if (!dateB) return -1;
      return moment(dateB).diff(dateA);
    })
    .slice(0, pageSize - upcomingExpenses.length);

  return {
    upcomingExpenses,
    paidExpenses,
    hasNext:
      (upcomingBills?.pageInfo?.total ?? 0) +
        (paidBills?.pageInfo?.total ?? 0) +
        (upcomingFees?.pageInfo?.total ?? 0) +
        (paidFees?.pageInfo?.total ?? 0) >
      pageSize,
    loading,
    refetch: useCallback(async (): Promise<void> => {
      await refetch();
    }, [refetch]),
    error
  };
}
