import { MoreIcon } from "@ailo/primitives";
import { Button, DropdownMenuToggleProps } from "@ailo/ui";
import React from "react";

export function MoreButton({
  toggle,
  open
}: DropdownMenuToggleProps): React.ReactElement {
  return (
    <Button.Secondary
      square
      type={"small"}
      style={{ alignSelf: "center" }}
      onPress={toggle}
      active={open}
    >
      <MoreIcon width={20} height={20} />
    </Button.Secondary>
  );
}
