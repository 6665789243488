import { OpenChat, useChatNavigationContext } from "@ailo/domains";
import { Screens, useNavigation } from "local/common";
import { AiloRN } from "@ailo/ailorn";
import { useCallbackRef } from "@ailo/primitives";

/**
 * To be used only when inside Screens.CommsTab
 *
 * For absolute use cases outside of the comms tab:
 * @see generateLinkToCommsTab
 */
export function useOpenChat(): OpenChat {
  const navigation = useNavigation();

  const {
    navigationParams: { threadId }
  } = useChatNavigationContext();

  return useCallbackRef(
    (chatAilorn: AiloRN<"chat:chat">) => {
      navigation.push(Screens.ChatThread, {
        chatId: chatAilorn.internalId,
        threadId
      });
    },
    [navigation, threadId]
  );
}
