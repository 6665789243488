import styled from "styled-components/native";
import { LockedPadlockIcon, UnlockedPadlockIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";

const SmallLockedPadlock = styled(LockedPadlockIcon).attrs({
  width: 20,
  height: 20
})``;

const SmallUnlockedPadlock =
  SmallLockedPadlock.withComponent(UnlockedPadlockIcon);

export const LockButton = styled(Button.Secondary).attrs({
  type: "small",
  leftIcon: SmallLockedPadlock,
  children: "Lock month"
})``;

export const UnlockButton = styled(Button.Secondary).attrs({
  type: "small",
  leftIcon: SmallUnlockedPadlock,
  children: "Unlock month"
})``;
