import React, { ReactElement } from "react";
import { Button, Tooltip } from "@ailo/ui";
import { EditIcon } from "@ailo/primitives";

export function EditButton({
  setShowEditModal
}: {
  setShowEditModal: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void;
}): ReactElement {
  return (
    <Tooltip tooltipContent={"Edit Centrepay"}>
      <Button.Secondary
        type={"small"}
        square
        onPress={() => {
          setShowEditModal(true);
        }}
      >
        <EditIcon height={20} width={20} />
      </Button.Secondary>
    </Tooltip>
  );
}
