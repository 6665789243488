import React, { ReactElement } from "react";
import { SearchBox } from "@ailo/ui";
import { AwaitingApprovalPropertyOnboardingListAddressSearchData } from "./hooks";
import styled from "styled-components/native";
import { View } from "react-native";

interface Props {
  addressSearchData: AwaitingApprovalPropertyOnboardingListAddressSearchData;
}

export function AwaitingApprovalPropertyOnboardingListAddressSearchBox({
  addressSearchData
}: Props): ReactElement {
  return (
    <SearchBoxContainer>
      <SearchBox
        onClear={addressSearchData.clearAddressSearch}
        onRetry={addressSearchData.updateAddressSearch}
        onInputFinish={addressSearchData.updateAddressSearch}
        initialQueryString={addressSearchData.addressSearch}
        inputDebounceDelay={500}
        placeholder={"Search properties"}
        isMulti={false}
        controlType={"full-width"}
      />
    </SearchBoxContainer>
  );
}

const SearchBoxContainer = styled(View)`
  flex-grow: 1;
`;
