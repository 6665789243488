import React, { ReactElement } from "react";
import { DateTimeWithTimeZone, TextCell } from "@ailo/ui";
import { LiveMigratingManagementFragment } from "local/graphql";

function LiveOnCell({
  row: { statusChangedAt }
}: {
  row: LiveMigratingManagementFragment;
  rowIndex: number;
}): ReactElement {
  const ailoStatusChangedAt = DateTimeWithTimeZone.from(statusChangedAt);

  return <TextCell>{ailoStatusChangedAt.format("D MMM YYYY")}</TextCell>;
}

LiveOnCell.Loading = TextCell.Loading;

export { LiveOnCell };
