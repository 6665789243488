import { Screens, useNavigation } from "local/common";
import { useCallbackRef } from "@ailo/primitives";

/**
 * To be used only when inside Screens.CommsTab
 *
 * For absolute use cases outside of the comms tab:
 * @see generateLinkToCommsTab
 */
export function useNavigateToEmptyChat(): () => void {
  const navigation = useNavigation<Screens.CommsTab>();

  return useCallbackRef(() => {
    navigation.navigate(Screens.EmptyChat);
  }, [navigation]);
}
