import React, { useCallback } from "react";
import { Text, Link } from "@ailo/primitives";
import { Screens, useNavigation } from "local/common";
import { BaseActionActivity } from "../../BaseActionActivity";

export function NoInspectionAppointmentViewUI({
  targetActionId,
  targetActionName
}: {
  targetActionId?: string;
  targetActionName: string;
}): React.ReactElement {
  const navigation = useNavigation<Screens.Project>();

  const onPress = useCallback(async () => {
    await navigation.setParams({ actionId: undefined });
    setTimeout(() => {
      navigation.setParams({ actionId: targetActionId });
    }, 300);
  }, [navigation, targetActionId]);

  return (
    <BaseActionActivity>
      <Text.BodyM>
        {"Please"}{" "}
        <Link variant={"primary"} onPress={onPress}>
          {targetActionName}
        </Link>{" "}
        {"to begin."}
      </Text.BodyM>
    </BaseActionActivity>
  );
}
