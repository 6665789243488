import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import {
  useGetIngoingTenancyIsVoidableQuery,
  VoidTenancyAbility
} from "local/graphql";

export function useGetIngoingTenancyIsVoidable(
  tenancyId: string
): MappedQueryResult<VoidTenancyAbility> {
  return useMappedQueryResult(
    useGetIngoingTenancyIsVoidableQuery({
      variables: { tenancyId }
    }),
    (data) => {
      const tenancy = data?.tenancy;

      if (!tenancy || !tenancy.isVoidable) {
        throw new ApolloError({
          errorMessage: "Expected tenancy data is not defined"
        });
      }

      return tenancy.isVoidable;
    }
  );
}
