import React from "react";
import { ActionType } from "local/graphql";
import { RefinedActionActivityProps } from "src/tabs/projects/actions/ActionSidebar/ActionActivity/ActionActivityProps";
import { useGetReiTokens } from "local/domain/project/useGetReiTokens";
import { ViewWithoutReiToken } from "./ViewWithoutReiToken";
import { ViewWithReiToken } from "./ViewWithReiToken";
import { GenericActionCard } from "../GenericActionCard";

export function TrackDocusignActionCard({
  action,
  projectId
}: RefinedActionActivityProps<ActionType.TrackDocusign>): React.ReactElement {
  const formId = action.meta.form?.id;
  const { data: reiTokens, loading } = useGetReiTokens();

  if (!formId || loading) {
    return <GenericActionCard projectId={projectId} action={action} />;
  }

  if (!reiTokens?.length) {
    return <ViewWithoutReiToken projectId={projectId} action={action} />;
  }
  return (
    <ViewWithReiToken formId={formId} projectId={projectId} action={action} />
  );
}
