import { Alert } from "@ailo/ui";
import React, { useEffect } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { useDebouncedCallback } from "use-debounce/lib";
import { usePersonEmailInUseLazyQuery } from "local/graphql";

export function ExistingPersonInfoBox({
  onChange,
  email,
  value,
  style
}: {
  onChange(value: boolean | undefined): void;
  email?: string;
  value?: boolean;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  const [personEmailInUse, { data }] = usePersonEmailInUseLazyQuery();

  const { callback } = useDebouncedCallback((email: string) => {
    personEmailInUse({ variables: { email } });
  }, 300);

  useEffect(() => {
    if (email) {
      callback(email);
    } else {
      onChange(undefined);
    }
  }, [email, callback, onChange]);

  useEffect(() => {
    if (data?.personEmailInUse != null) {
      onChange(data.personEmailInUse);
    } else {
      onChange(undefined);
    }
  }, [data, onChange]);

  if (!value) {
    return <></>;
  }

  return (
    <Alert
      style={style}
      type={"info"}
      message={
        "An account with this email already exists on Ailo. Once the tenancy is created, their contact details will be automatically synced from their Ailo profile."
      }
    />
  );
}

export function DuplicateEmailError({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <Alert
      style={style}
      type={"error"}
      message={
        "Sorry, each owner must have a unique email as Ailo accounts cannot be shared. Ask for a unique email from your owner or remove this email to continue."
      }
    />
  );
}
