import { DateTimeWithTimeZone, DateInputFormField } from "@ailo/ui";
import moment from "moment";
import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LeaseRenewalFormData } from "../LeaseRenewalFormData";

interface Props {
  tenancyEndDate?: string;
}

export function AgreementFixedTermEndDateInput({
  tenancyEndDate
}: Props): ReactElement {
  const { control, errors, watch, formState, getValues, setValue, trigger } =
    useFormContext<LeaseRenewalFormData>();
  const agreementStartDate = watch("agreementStartDate");

  const inputName = "agreementFixedTermEndDate";

  useEffect(() => {
    if (
      agreementStartDate &&
      !formState.dirtyFields.agreementFixedTermEndDate
    ) {
      const fixedTermEndDateDefault = DateTimeWithTimeZone.fromLocalDate(
        agreementStartDate
      )
        .add(1, "year")
        .subtract(1, "day")
        .toLocalDateString();

      const fixedTermEndDate =
        tenancyEndDate &&
        moment(tenancyEndDate).isBefore(fixedTermEndDateDefault, "day")
          ? tenancyEndDate
          : fixedTermEndDateDefault;

      setValue(inputName, fixedTermEndDate, {
        shouldValidate: true
      });
    }
  }, [
    agreementStartDate,
    formState.dirtyFields.agreementFixedTermEndDate,
    getValues,
    setValue,
    tenancyEndDate,
    trigger
  ]);

  const minDate = DateTimeWithTimeZone.now().add(2, "day").toLocalDateString();
  const maxDate = tenancyEndDate
    ? DateTimeWithTimeZone.from(tenancyEndDate).toLocalDateString()
    : undefined;

  return (
    <Controller
      name={inputName}
      render={({ value, onChange, onBlur }): ReactElement => (
        <DateInputFormField
          webPopoverHorizontalAlign={"end"}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          label={"End date"}
          placeholder={"End date"}
          autoCorrect={false}
          autoCompleteType={"off"}
          minDate={minDate}
          maxDate={maxDate}
          error={errors.agreementFixedTermEndDate?.message}
        />
      )}
      defaultValue={null}
      control={control}
      rules={{
        required: "Please select an end date",
        validate: (value): boolean | string =>
          (agreementStartDate
            ? moment(value).isAfter(agreementStartDate, "day")
            : moment(value).isSameOrAfter(minDate, "day")) ||
          "Must be later than start date"
      }}
    />
  );
}
