import React, { ComponentType, ReactElement, useEffect } from "react";
import {
  OnboardingListView,
  Screens,
  useNavigation,
  useRoute
} from "local/common";
import { PropertyOnboardingListTabs } from "./PropertyOnboardingListTabs";
import { AwaitingApprovalPropertyOnboardingList } from "./AwaitingApproval";
import { ApprovedPropertyOnboardingList } from "./Approved";
import { LivePropertyOnboardingList } from "./Live";
import { ExcludedPropertyOnboardingList } from "./Excluded";

interface PropertyOnboardingTabData {
  label: OnboardingListView;
  ListComponent: ComponentType<{ tabNavigation: ReactElement }>;
}

const PROPERTY_TAB_DATA: PropertyOnboardingTabData[] = [
  {
    label: OnboardingListView.AWAITING_APPROVAL,
    ListComponent: AwaitingApprovalPropertyOnboardingList
  },
  {
    label: OnboardingListView.EXCLUDED,
    ListComponent: ExcludedPropertyOnboardingList
  },
  {
    label: OnboardingListView.APPROVED,
    ListComponent: ApprovedPropertyOnboardingList
  },
  {
    label: OnboardingListView.LIVE,
    ListComponent: LivePropertyOnboardingList
  }
];

export function PropertyOnboardingList(): ReactElement | null {
  const { params } = useRoute<Screens.OnboardingTab>();
  const navigation = useNavigation<Screens.OnboardingTab>();

  const currentIndex = PROPERTY_TAB_DATA.findIndex((data) =>
    data.label.toLowerCase().includes(params?.tab?.toLowerCase() ?? "")
  );

  useEffect(() => {
    if (currentIndex < 0) {
      navigation.setParams({ tab: OnboardingListView.AWAITING_APPROVAL });
    }
  }, [currentIndex, navigation]);

  if (currentIndex < 0) {
    return null;
  }

  const { ListComponent } = PROPERTY_TAB_DATA[currentIndex];

  return (
    <ListComponent
      tabNavigation={
        <PropertyOnboardingListTabs
          tabs={PROPERTY_TAB_DATA}
          selectedTabIndex={currentIndex}
        />
      }
    />
  );
}
