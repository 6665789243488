import {
  ErrorBoundaryDefaultRenderFallback,
  ErrorBoundaryRenderFallbackProps
} from "@ailo/services";
import React, { ReactElement } from "react";

export function TabContentErrorBoundaryFallback(
  tabNavigation: ReactElement
): (props: ErrorBoundaryRenderFallbackProps) => ReactElement {
  return function Fallback({
    error,
    retry
  }: ErrorBoundaryRenderFallbackProps): ReactElement {
    return (
      <>
        {tabNavigation}
        <ErrorBoundaryDefaultRenderFallback {...{ error, retry }} />
      </>
    );
  };
}
