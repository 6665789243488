import { BondIcon, DocumentCheckmarkIcon, EditIcon } from "@ailo/primitives";
import { DropdownMenuOption, DropdownMenuOptionGroup } from "@ailo/ui";
import React from "react";
import { TenancyBond } from "local/tabs";
import { useHasFeature } from "@ailo/domains";
import { isPresent } from "ts-is-present";
import { PlatformFeatureId, TenancyBondStatus } from "local/graphql";
import { useUpdateBondStatus } from "local/domain/bond";

export type BondMenuOptions = {
  editBondDetails: () => void;
  addBondDetails: () => void;
  navigateToClaimBond: () => void;
  viewBondClaim: () => void;
  editBondReference: () => void;
  bond?: TenancyBond;
  isFormerTenancy?: boolean;
};

export function useGetBondOptionsGroup({
  editBondDetails,
  navigateToClaimBond,
  viewBondClaim,
  editBondReference,
  bond,
  isFormerTenancy
}: BondMenuOptions): DropdownMenuOptionGroup | undefined {
  const setBondResolved = useUpdateBondStatus(
    bond,
    TenancyBondStatus.BondResolved,
    "Bond marked as resolved"
  );
  const hasTenancyBondsFeature = useHasFeature(PlatformFeatureId.TenancyBonds);
  const hasClaimBondFeature = useHasFeature(PlatformFeatureId.TenancyBondClaim);
  const claimRequired = bond?.status === TenancyBondStatus.Unclaimed;
  const bondIsResolved = bond?.status === TenancyBondStatus.BondResolved;
  const enableClaimBond = hasClaimBondFeature && claimRequired;
  const enableViewClaim = hasClaimBondFeature && !claimRequired;
  const enableEditBondReference =
    bond &&
    [
      TenancyBondStatus.AwaitingFunds,
      TenancyBondStatus.DisbursementFailed
    ].includes(bond.status);
  const enableSetBondResolved = claimRequired && isFormerTenancy;

  const options = [
    claimRequired
      ? getEditBondDetailsOption({ onSelect: editBondDetails })
      : undefined,
    enableClaimBond
      ? getNavigateToClaimBondOption({
          onSelect: navigateToClaimBond,
          bondReference: bond?.reference
        })
      : undefined,
    enableSetBondResolved
      ? getMarkBondResolvedOption({
          onSelect: setBondResolved
        })
      : undefined,
    enableViewClaim
      ? getViewBondClaimOption({ onSelect: viewBondClaim, bondIsResolved })
      : undefined,
    enableEditBondReference
      ? getEditBondReferenceOption({ onSelect: editBondReference })
      : undefined
  ].filter(isPresent);

  return hasTenancyBondsFeature && !!bond && options.length > 0
    ? {
        options,
        type: "group"
      }
    : undefined;
}

function getEditBondDetailsOption({
  onSelect
}: {
  onSelect: () => void;
}): DropdownMenuOption {
  return {
    label: "Edit bond details",
    icon: <EditIcon />,
    onSelect
  };
}

function getMarkBondResolvedOption({
  onSelect
}: {
  onSelect: () => void;
}): DropdownMenuOption {
  return {
    label: "Mark bond as resolved",
    icon: <DocumentCheckmarkIcon />,
    onSelect
  };
}

function getEditBondReferenceOption({
  onSelect
}: {
  onSelect: () => void;
}): DropdownMenuOption {
  return {
    label: "Edit bond reference",
    icon: <EditIcon />,
    onSelect
  };
}

function getViewBondClaimOption({
  onSelect,
  bondIsResolved
}: {
  onSelect: () => void;
  bondIsResolved: boolean;
}): DropdownMenuOption {
  return {
    label: bondIsResolved ? "View bond details" : "View bond claim",
    icon: <BondIcon />,
    onSelect
  };
}

function getNavigateToClaimBondOption({
  onSelect,
  bondReference
}: {
  onSelect: () => void;
  bondReference?: string;
}): DropdownMenuOption {
  const hasNoBondRef = !bondReference;
  return {
    label: "Claim bond",
    icon: <BondIcon />,
    onSelect: onSelect,
    tooltip: hasNoBondRef ? "Bond reference required" : undefined,
    disabled: hasNoBondRef
  };
}
