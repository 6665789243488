import React, { useRef, useEffect } from "react";
import { CheckboxInput, FormField } from "@ailo/ui";
import { Controller, useFormContext } from "react-hook-form";
import { EndManagementFormData } from "../EndManagementFormData";
import {
  ManagementEndCauseCode,
  ManagementEndReasonFragment
} from "local/graphql";
import { InputContainer } from "./EndManagementSection";

export function ManagementLostReasonInput({
  initialValue,
  endReasons
}: {
  initialValue?: ManagementEndCauseCode[];
  endReasons: ManagementEndReasonFragment[];
}): React.ReactElement | null {
  const { control, errors, watch } = useFormContext<EndManagementFormData>();

  const selectedValues = useRef<ManagementEndCauseCode[] | undefined>(
    undefined
  );
  const endReason = watch("managementEndReason");

  useEffect(() => {
    if (selectedValues.current === undefined) {
      selectedValues.current = initialValue ? [...initialValue] : [];
    }
  }, [initialValue]);

  const validationError = errors.managementLostReason as { message?: string };

  const potentialEndCauses =
    endReasons.find((reason) => reason.code === endReason)?.causes || [];

  if (potentialEndCauses.length < 1) {
    return null;
  }

  return (
    <InputContainer>
      <Controller
        name={"managementLostReason"}
        render={({ onChange, onBlur }): React.ReactElement => {
          return (
            <FormField
              label={"Cause of loss (select one or more)"}
              error={validationError?.message}
            >
              {potentialEndCauses.map((reason, index): React.ReactElement => {
                return (
                  <CheckboxInput
                    key={`reason-${reason.code}`}
                    style={{
                      marginTop: index > 0 ? 12 : 0
                    }}
                    value={
                      selectedValues.current?.find(
                        (code) => code === reason.code
                      ) != null
                    }
                    label={reason.label}
                    {...{ onBlur }}
                    onChange={(value) => {
                      if (value) {
                        selectedValues.current?.push(reason.code);
                      } else {
                        selectedValues.current?.splice(
                          selectedValues.current?.indexOf(reason.code),
                          1
                        );
                      }
                      onChange(selectedValues.current);
                    }}
                  />
                );
              })}
            </FormField>
          );
        }}
        defaultValue={initialValue}
        control={control}
        rules={{
          validate: {
            arrayMinLength: (array?: any[]) => {
              if (array?.length) {
                return true;
              } else return "Please select one or more options";
            }
          }
        }}
      />
    </InputContainer>
  );
}
