import React, { ReactElement, useMemo, useState } from "react";
import { MigratingManagementSidebarFragment } from "local/graphql";
import { BaseMigratingManagementSidebarContent } from "./BaseMigratingManagementSidebarContent";
import { getMigratingManagementSidebarInnerContent } from "./getMigratingManagementSidebarInnerContent";
import { MigratingManagementSidebarError } from "./MigratingManagementSidebarError";

interface Props {
  migratingManagement: MigratingManagementSidebarFragment;
  closeSidebar: () => void;
  refetch: () => void;
}

function MigratingManagementSidebarContent({
  migratingManagement,
  closeSidebar,
  refetch
}: Props): ReactElement {
  const [loading, setLoading] = useState(false);

  const innerContent = useMemo(
    () =>
      getMigratingManagementSidebarInnerContent({
        migratingManagement,
        closeSidebar,
        setLoading
      }),
    [migratingManagement, closeSidebar, setLoading]
  );

  if (!innerContent) {
    return (
      <MigratingManagementSidebarError
        closeSidebar={closeSidebar}
        refetch={refetch}
      />
    );
  }

  return (
    <BaseMigratingManagementSidebarContent
      migratingManagement={migratingManagement}
      closeSidebar={closeSidebar}
      footer={innerContent.footer}
      loading={loading}
    >
      {innerContent.body}
    </BaseMigratingManagementSidebarContent>
  );
}

interface LoadingProps {
  closeSidebar: () => void;
}

function Loading({ closeSidebar }: LoadingProps): ReactElement {
  return (
    <BaseMigratingManagementSidebarContent.Loading
      closeSidebar={closeSidebar}
    />
  );
}

MigratingManagementSidebarContent.Loading = Loading;

export { MigratingManagementSidebarContent };
