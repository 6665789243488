import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AFC } from "@ailo/primitives";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";

const AddBillFormContext: AFC = ({ children }) => {
  const formMethods = useForm<AddBillFormData>({
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
};

export { AddBillFormContext };
