import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { Card } from "@ailo/ui";
import { View } from "react-native";
import { BillsIcon, Colors, Text } from "@ailo/primitives";

interface Params {
  exclusionNotes: string;
}

export function ExclusionNotes({ exclusionNotes }: Params): ReactElement {
  return (
    <StyledCard borderRadius={8}>
      <HeaderContents>
        <BillsIcon color={Colors.TEXT.DARK.SECONDARY} width={20} height={20} />
        <Text.BodyM weight={"medium"}>{"Notes"}</Text.BodyM>
      </HeaderContents>

      <BodyContents>
        <Text.BodyS>{exclusionNotes}</Text.BodyS>
      </BodyContents>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  margin: 0;
`;

const HeaderContents = styled(View)`
  padding: 16px;
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  gap: 16px;
`;

const BodyContents = styled(View)`
  padding: 16px;
  border-top-style: solid;
  border-top-color: ${Colors.GRAYSCALE.OUTLINE};
  border-top-width: 1px;
`;
