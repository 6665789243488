import {
  GetClaimTenancyBondDetailsQuery,
  LegalEntityNameFragment
} from "local/graphql";
import { Money } from "@ailo/ui";
import { extractFundsReceived } from "./extractFundsReceived";
import { parseBills } from "./parseBills";
import { parseRent } from "./parseRent";
import { ClaimTenancyData } from "local/domain/bond";
import { isPresent } from "ts-is-present";
import { TenancyBond } from "local/tabs";
import { BondAccount } from "local/domain/bond/types";

export function parseBondDetails(
  data: GetClaimTenancyBondDetailsQuery
): ClaimTenancyData | undefined {
  if (!data?.tenancy?.bond?.amount || !data.tenancy.liability?.overdueAmount) {
    return undefined;
  }
  return {
    tenancyId: data.tenancy.id,
    tenancyEndDate: data.tenancy.endDate || undefined,
    managementId: data.tenancy.management?.id,
    bills: parseBills(data),
    rent: parseRent(data),
    property: data.tenancy.property,
    tenants: parseTenants(data),
    bond: parseBond(data),
    bondAccount: parseBondAccount(data)
  };
}

function parseTenants(
  data: GetClaimTenancyBondDetailsQuery
): LegalEntityNameFragment[] {
  return (
    data.tenancy?.tenantships?.items
      .map((tenancy) => tenancy.tenant)
      .filter(isPresent) || []
  );
}

function parseBond(data: GetClaimTenancyBondDetailsQuery): TenancyBond {
  const bond = data?.tenancy?.bond!;
  return {
    id: bond.id,
    amount: Money.from(bond.amount),
    reference: bond.reference || undefined,
    status: bond.status,
    failureReason: bond.failureReason || undefined,
    fundsReceived: extractFundsReceived(bond)
  };
}

function parseBondAccount(data: GetClaimTenancyBondDetailsQuery): BondAccount {
  const accounts =
    data?.tenancy?.management?.managingEntity?.tenancyBondVirtualAccounts;
  if (accounts?.length !== 1) {
    throw new Error(
      "There must be exactly one bond account for a managing entity"
    );
  }
  const account = accounts[0].paymentMethodCompanion?.paymentMethod;
  if (account?.__typename !== "VirtualAccount") {
    throw new Error("Bond accounts must be virtual accounts");
  }
  if (!account?.accountNumber || !account?.bsb) {
    throw new Error("Bond accounts must have a valid account number and bsb");
  }
  return {
    accountNumber: account.accountNumber,
    bsb: account.bsb
  };
}
