import { useSelectedTeams } from "local/domain/propertyManagement";
import { useMemo } from "react";

export type PropertyListSelectedTeams = {
  teamIds: string[];
  unassignedSelected: boolean;
};

export function usePropertyListSelectedTeams(): PropertyListSelectedTeams {
  const { propertyListSelectedTeamIds, propertyListUnassignedSelected } =
    useSelectedTeams();

  return useMemo(
    () => ({
      teamIds: propertyListSelectedTeamIds,
      unassignedSelected: propertyListUnassignedSelected
    }),
    [propertyListSelectedTeamIds, propertyListUnassignedSelected]
  );
}
