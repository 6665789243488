import { Colors, Text } from "@ailo/primitives";
import { InspectionDay } from "local/domain/inspection";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";

interface Props {
  inspectionDay: InspectionDay;
}

export function InspectionDayListItemSubheader({
  inspectionDay
}: Props): React.ReactElement {
  const { appointments } = inspectionDay;
  const numProperties = appointments.length;
  const subheadingText = `${numProperties} ${
    numProperties === 1 ? "property" : "properties"
  }`;

  return (
    <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
      {subheadingText}
    </Text.BodyS>
  );
}

InspectionDayListItemSubheader.Loading = function Loading({
  color,
  style
}: {
  color?: string;
  style?: StyleProp<ViewStyle>;
}) {
  return <Text.BodyS.Loading width={80} color={color} style={style} />;
};
