import { GridSortOrder } from "@ailo/ui";
import { useCallback, useState } from "react";

interface GridHeaderState {
  changeSortOrder: (nextState: GridSortOrder) => void;
  sortOrder: GridSortOrder;
}

export function useProjectListSortState(
  initialSortState: GridSortOrder
): [GridSortOrder, (sortOrder: GridSortOrder) => void] {
  const [sortOrder, setSortOrder] = useState(initialSortState);

  return [sortOrder, setSortOrder];
}

export function useProjectListReorder({
  onSortOrderChanged,
  sortOrder,
  setSortOrder
}: {
  onSortOrderChanged: () => void;
  sortOrder: GridSortOrder;
  setSortOrder: (sortOrder: GridSortOrder) => void;
}): GridHeaderState {
  const changeSortOrder = useCallback(
    (sortOrder: GridSortOrder): void => {
      setSortOrder(sortOrder);
      onSortOrderChanged();
    },
    [onSortOrderChanged, setSortOrder]
  );

  return {
    changeSortOrder,
    sortOrder
  };
}
