import { useCurrentAgencyOrg } from "local/common";
import { useGetTeamsWithContactByOrganisationQuery } from "local/graphql";
import { AiloRN } from "@ailo/ailorn";
import { useMemo } from "react";
import { ChatTeam } from "@ailo/domains";

interface ReturnType {
  teams?: ChatTeam[];
  loading: boolean;
  error: boolean;
  refetch: () => void;
}

export function useGetTeamList(): ReturnType {
  const currentAgencyOrg = useCurrentAgencyOrg();
  const { data, loading, error, refetch } =
    useGetTeamsWithContactByOrganisationQuery({
      variables: {
        organisationId: currentAgencyOrg.id
      }
    });

  const teams = useMemo(
    () =>
      data?.organisation?.teams.map((t) => ({
        ailorn: AiloRN.from(t.id, { expected: "propertymanagement:team" }),
        myTeam: t.myTeam,
        name: t.name,
        contactAilorns: t.contacts.map((c) =>
          AiloRN.from(c.ailorn, { expected: "contact:contact" })
        )
      })),
    [data]
  );

  return {
    teams,
    loading,
    error: !!error,
    refetch
  };
}
