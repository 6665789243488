import React, { ReactElement, useCallback } from "react";
import {
  Colors,
  ExternalLinkIcon,
  Pressable,
  Text,
  useHover
} from "@ailo/primitives";
import {
  BorderedBox,
  BoxWithShadow,
  CenterContent,
  Separator,
  useOpenUrlTab
} from "@ailo/ui";
import { View } from "react-native";
import { PrimaryText } from "../../../../PrimaryText";
import { SecondaryText } from "../../../../SecondaryText";
import { Row } from "local/common";
import { BondAuthority } from "local/domain/bond";

type BondAuthorityProps = {
  bondAuthority: BondAuthority;
  enableSubmitButton: () => void;
};

export function BondAuthorityCard({
  bondAuthority,
  enableSubmitButton
}: BondAuthorityProps): ReactElement {
  const [ref, isHovered] = useHover();

  const openBondAuthorityPortal = useOpenUrlTab(bondAuthority.url);

  const onPress = useCallback(() => {
    openBondAuthorityPortal();
    enableSubmitButton();
  }, [openBondAuthorityPortal, enableSubmitButton]);

  return (
    <Pressable onPress={onPress} style={{ width: "fit-content" }}>
      <BorderedBox
        style={{
          paddingTop: 16,
          paddingLeft: 16,
          paddingBottom: 12,
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          backgroundColor: isHovered ? Colors.CLOUD : Colors.WHITE,
          width: 400
        }}
        ref={ref}
      >
        <BoxWithShadow
          style={{
            height: 40,
            width: 40,
            backgroundColor: bondAuthority.color
          }}
        >
          <CenterContent>{bondAuthority.logo}</CenterContent>
        </BoxWithShadow>
        <View style={{ width: 326, marginLeft: 16 }}>
          <View>
            <PrimaryText weight={"medium"}>{bondAuthority.name}</PrimaryText>
            <SecondaryText>{bondAuthority.displayUrl}</SecondaryText>
          </View>
          <Separator
            style={{ marginLeft: 0, marginTop: 16, marginBottom: 12 }}
          />
          <Row style={{ paddingRight: 16 }}>
            <Text.BodyM weight={"medium"} color={Colors.AILO_RED}>
              {"Log in"}
            </Text.BodyM>
            <ExternalLinkIcon />
          </Row>
        </View>
      </BorderedBox>
    </Pressable>
  );
}
