import React from "react";
import { Badge, ListItem, Money, OnOffBadge } from "@ailo/ui";
import { didQueryNotLoadYet } from "@ailo/services";
import { GetPropertyWalletMoneyManagementSettingsQueryHookResult } from "local/graphql";
import { Colors, Text } from "@ailo/primitives";
import { RowSpaceBetween } from "./RowSpaceBetween";

export function AutoBillPaymentSettingsDisplay({
  result
}: {
  result: GetPropertyWalletMoneyManagementSettingsQueryHookResult;
}): React.ReactElement {
  if (didQueryNotLoadYet(result)) {
    return <AutoBillPaymentSettingsDisplayLoading />;
  }

  const { data } = result;
  const enabledAutoBillPayment = data?.autoPayLiabilityStatuses?.find(
    (s) => s?.enabled
  );

  if (!enabledAutoBillPayment) {
    return (
      <ListItem
        style={{
          borderType: "none",
          paddingLeft: 0,
          paddingRight: 0
        }}
        header={
          <Text.BodyL weight={"medium"}>{"Auto Bill Payments"}</Text.BodyL>
        }
        rightComponent={<OnOffBadge enabled={false} />}
      />
    );
  }

  return (
    <ListItem
      style={{
        borderType: "none",
        paddingLeft: 0,
        paddingRight: 0
      }}
      header={<Text.BodyL weight={"medium"}>{"Auto Bill Payments"}</Text.BodyL>}
      rightComponent={<OnOffBadge enabled={!!enabledAutoBillPayment} />}
    >
      <RowSpaceBetween
        style={{ paddingBottom: 8 }}
        left={
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
            {"Pay when"}
          </Text.BodyS>
        }
        right={
          <Text.BodyS color={Colors.TEXT.DARK.PRIMARY}>
            {"Funds are available"}
          </Text.BodyS>
        }
      />
      <RowSpaceBetween
        style={{ paddingBottom: 8 }}
        left={
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
            {"Payment method"}
          </Text.BodyS>
        }
        right={
          <Text.BodyS color={Colors.TEXT.DARK.PRIMARY}>
            {"Property Wallet"}
          </Text.BodyS>
        }
      />
      <RowSpaceBetween
        style={{ paddingBottom: 8 }}
        left={
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
            {"Bill limit"}
          </Text.BodyS>
        }
        right={
          <Text.BodyS color={Colors.TEXT.DARK.PRIMARY}>
            {"Up to "}
            {Money.fromCents(
              enabledAutoBillPayment?.maximumPaymentAmount?.cents || 200000
            ).toString()}
          </Text.BodyS>
        }
      />
      <RowSpaceBetween
        left={
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
            {"Bill payments range"}
          </Text.BodyS>
        }
        right={
          <Text.BodyS color={Colors.TEXT.DARK.PRIMARY}>
            {"60 days in future"}
          </Text.BodyS>
        }
      />
    </ListItem>
  );
}

function AutoBillPaymentSettingsDisplayLoading(): React.ReactElement {
  return (
    <ListItem
      style={{
        borderType: "none",
        paddingLeft: 0,
        paddingRight: 0
      }}
      header={<Text.BodyL.Loading />}
      rightComponent={<Badge.Loading />}
    >
      <RowSpaceBetween
        style={{ paddingBottom: 8 }}
        left={<Text.BodyS.Loading width={60} />}
        right={<Text.BodyS.Loading width={120} />}
      />
      <RowSpaceBetween
        style={{ paddingBottom: 8 }}
        left={<Text.BodyS.Loading width={100} />}
        right={<Text.BodyS.Loading width={100} />}
      />
      <RowSpaceBetween
        style={{ paddingBottom: 8 }}
        left={<Text.BodyS.Loading width={60} />}
        right={<Text.BodyS.Loading width={100} />}
      />
      <RowSpaceBetween
        left={<Text.BodyS.Loading width={120} />}
        right={<Text.BodyS.Loading width={100} />}
      />
    </ListItem>
  );
}

AutoBillPaymentSettingsDisplay.Loading = AutoBillPaymentSettingsDisplayLoading;
