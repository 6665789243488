import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { Column, Row, Text } from "@ailo/primitives";
import { Button, useModalVisibility } from "@ailo/ui";
import { withScreenComponent, Breadcrumbs } from "@ailo/services";
import {
  AdjustmentsCrumb,
  ReconciliationsLinkCrumb,
  ReconciliationLinkCrumb,
  LeftColumn,
  RightColumn
} from "../components";
import { useRoute, Screens, MonthYearObject } from "local/common";
import { AddAdjustmentModal } from "./AddAdjustmentModal";
import { useReconciliationViewScreenData } from "../ReconciliationViewScreen/useReconciliationViewScreenData";
import { AdjustmentList } from "./AdjustmentList";

export const ReconciliationAdjustmentListScreen = withScreenComponent(
  (): ReactElement => {
    const reportMonthYear =
      useRoute<Screens.TrustReconciliationAdjustmentList>().params?.monthYear;
    const modalControl = useModalVisibility();
    const periodData = useReconciliationViewScreenData(reportMonthYear);

    if (!reportMonthYear || periodData.loading) return <></>;

    const header = (
      <>
        {modalControl.isVisible && (
          <AddAdjustmentModal
            period={periodData}
            visible={modalControl.isVisible}
            onCancelPress={modalControl.hide}
            onAddPress={modalControl.hide}
          />
        )}
        <HeaderRow>
          <LeftColumn>
            <Title {...reportMonthYear} />
          </LeftColumn>
          <RightColumn>
            <AddAdjustmentButton
              disabled={periodData.locked}
              onPress={modalControl.show}
            />
          </RightColumn>
        </HeaderRow>
      </>
    );

    return (
      <ScreenContainer>
        <AdjustmentList header={header} period={periodData} />
      </ScreenContainer>
    );
  }
);

const HeaderRow = styled(Row)`
  margin: 32px 0 40px;
`;

const ScreenContainer = styled(Column)`
  flex: 1;
`;

const AddAdjustmentButton = styled(Button.Primary).attrs({
  type: "small",
  children: "Add adjustment"
})``;

function Title({ month, year }: MonthYearObject): ReactElement {
  return (
    <>
      <Breadcrumbs>
        <ReconciliationsLinkCrumb />
        <ReconciliationLinkCrumb month={month} year={year} />
        <AdjustmentsCrumb />
      </Breadcrumbs>
      <TitleText>{`Adjustments`}</TitleText>
    </>
  );
}

const TitleText = styled(Text.DisplayM)`
  margin-top: 8px;
`;
