import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import { DEFAULT_CHAT_TEAM_ID } from "@ailo/domains";
import {
  GetManagementAndTenancyForPropertyChatListQueryVariables,
  useGetManagementAndTenancyForPropertyChatListQuery
} from "local/graphql";
import { uniq } from "lodash";
import { AiloRN } from "@ailo/ailorn";

interface PropertyChatListData {
  contactAilorns: AiloRN<"contact:contact">[];
  teamId: string;
}

export function useGetDataForPropertyChatList(
  managementId: string
): MappedQueryResult<
  PropertyChatListData,
  GetManagementAndTenancyForPropertyChatListQueryVariables
> {
  const result = useGetManagementAndTenancyForPropertyChatListQuery({
    variables: { managementId }
  });

  return useMappedQueryResult(result, (data) => {
    if (!data?.management) {
      throw new ApolloError({
        errorMessage: `[Property Related Chats] Management "${managementId}" not found`
      });
    }

    const mostRecentTenancyContactAilorns =
      data.management.mostRecentTenancy?.tenantContacts.map(
        (contact) => contact.ailorn
      );

    const nextTenancyContactAilorns =
      data.management.nextTenancy?.tenantContacts.map(
        (contact) => contact.ailorn
      );

    const ownerContactAilorns = data.management.ownerContacts?.map(
      (contact) => contact.ailorn
    );

    return {
      contactAilorns: uniq([
        ...(mostRecentTenancyContactAilorns || []),
        ...(nextTenancyContactAilorns || []),
        ...ownerContactAilorns
      ]).map((contactAilorn) =>
        AiloRN.from(contactAilorn, { expected: "contact:contact" })
      ),
      teamId: data.management.team
        ? AiloRN.from(data.management.team.id, {
            expected: "propertymanagement:team"
          }).internalId
        : DEFAULT_CHAT_TEAM_ID
    };
  });
}
