import React, { ReactElement, ReactNode } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { LocalDate } from "@ailo/date";
import {
  WaitIcon,
  Text,
  Colors,
  ErrorCrossIcon,
  ViewDetailsIcon,
  SuccessSubtractedFillIcon
} from "@ailo/primitives";
import {
  ReiFormRemoteSignerFragment,
  ReiFormRemoteSignerStatus
} from "local/graphql";

export function DocusignSignatureTrackingList({
  tenants,
  listItemSpacing
}: {
  tenants: ReiFormRemoteSignerFragment[];
  listItemSpacing: number;
}): ReactElement {
  return (
    <>
      {tenants.map((tenant) => {
        return (
          <TenantSignDetails
            tenant={tenant}
            key={tenant.name}
            style={{ marginBottom: listItemSpacing }}
          />
        );
      })}
    </>
  );
}

function TenantSignDetails({
  tenant,
  style
}: {
  tenant: ReiFormRemoteSignerFragment;
  style?: StyleProp<ViewStyle>;
}): ReactElement {
  const signatureText = `Signature from ${tenant.name}`;
  if (tenant.status === ReiFormRemoteSignerStatus.Declined) {
    return (
      <SignatureView
        icon={
          <ErrorCrossIcon width={20} height={20} color={Colors.ALERT_ORANGE} />
        }
        signedOn={"Declined"}
        signatureText={signatureText}
        style={style}
      />
    );
  }

  if (tenant.status === ReiFormRemoteSignerStatus.Delivered) {
    return (
      <SignatureView
        icon={
          <ViewDetailsIcon
            width={20}
            height={20}
            color={Colors.TEXT.DARK.SECONDARY}
          />
        }
        signedOn={"Viewed"}
        signatureText={signatureText}
        style={style}
      />
    );
  }
  if (tenant.status === ReiFormRemoteSignerStatus.Completed && tenant.signed) {
    return (
      <SignatureView
        icon={<SuccessSubtractedFillIcon width={20} height={20} />}
        signedOn={`Signed ${LocalDate.from(tenant.signed, {
          keepTimeZone: false
        }).format("DD MMM")}`}
        signatureText={signatureText}
        style={style}
      />
    );
  }

  return (
    <SignatureView
      icon={<WaitIcon width={20} height={20} />}
      signedOn={"Not Signed"}
      signatureText={signatureText}
      style={style}
    />
  );
}

function SignatureView({
  icon,
  signedOn,
  signatureText,
  style
}: {
  icon: ReactNode;
  signedOn: string;
  signatureText: string;
  style?: StyleProp<ViewStyle>;
}): ReactElement {
  return (
    <View
      style={[
        {
          justifyContent: "space-between",
          flexDirection: "row"
        },
        style
      ]}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {icon}

        <Text.BodyXS
          style={{
            marginLeft: 8
          }}
          color={Colors.TEXT.DARK.SECONDARY}
        >
          {signatureText}
        </Text.BodyXS>
      </View>

      <Text.BodyXS>{signedOn}</Text.BodyXS>
    </View>
  );
}
