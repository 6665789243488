import { Colors, RightChevronIcon, TenancyIcon } from "@ailo/primitives";
import { BoxWithShadow, ListItem, ListItemStyle } from "@ailo/ui";
import React from "react";
import { StyleProp, StyleSheet } from "react-native";

type Props = {
  header: string;
  icon?: React.ReactElement;
  onPress: () => void;
  style?: StyleProp<ListItemStyle>;
};
const defaultIcon = <TenancyIcon width={20} height={20} color={Colors.OCEAN} />;

export function ListItemAction({
  header,
  icon = defaultIcon,
  onPress,
  style
}: Props): React.ReactElement {
  return (
    <ListItem
      header={header}
      leftComponent={
        <BoxWithShadow style={{ marginRight: 16 }}>{icon}</BoxWithShadow>
      }
      style={StyleSheet.flatten([
        {
          borderType: "around",
          borderRadius: 8,
          marginLeft: 16,
          marginRight: 16,
          marginTop: 16,
          marginBottom: 16,
          alignItems: "center"
        },
        style
      ])}
      onPress={onPress}
      rightComponent={<RightChevronIcon width={20} height={20} />}
    />
  );
}
