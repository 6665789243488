import React, { ReactElement } from "react";
import { Text } from "@ailo/primitives";
import { LocalDate } from "@ailo/date";
import { CellContainer } from "@ailo/ui";
import { ProjectListRow } from "../ProjectList";

export function DueDateCell({
  row: { dueDate }
}: {
  row: ProjectListRow;
  rowIndex: number;
}): ReactElement {
  return (
    <CellContainer>
      <Text.BodyS>{LocalDate.parse(dueDate)?.format("D MMM")}</Text.BodyS>
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <Text.BodyS.Loading width={80} />
    </CellContainer>
  );
}

DueDateCell.Loading = Loading;
