import { Colors, opacify, SFC } from "@ailo/primitives";
import { useOnFocus } from "@ailo/services";
import { Separator } from "@ailo/ui";
import {
  ErrorCard,
  PropertyScreenCardErrorBoundary
} from "local/domain/propertyManagement";
import { isEmpty } from "lodash";
import React from "react";
import { View } from "react-native";
import { BaseTenancyCard } from "../BaseTenancyCard";
import { FormerTenancyCard } from "./FormerTenancyCard";
import { useGetFormerAndVoidedTenancies } from "./useGetFormerAndVoidedTenancies";

interface Props {
  managementId: string;
  onRentAdjustmentsPress: (tenancyId: string) => void;
  onTenancyDetailsPress: (tenancyId: string, title: string) => void;
  onTenantPress?: (id: string, entityType: "Person" | "Company") => void;
  onAddBondDetailsPress: (tenancyId: string) => void;
  onEditBondDetailsPress: (tenancyId: string) => void;
  onViewBondClaimPress: (tenancyId: string) => void;
  onEditBondReferencePress: (tenancyId: string) => void;
}

export const FormerTenancyCards: SFC<Props> = ({
  managementId,
  onRentAdjustmentsPress,
  onTenancyDetailsPress,
  onTenantPress,
  onAddBondDetailsPress,
  onEditBondDetailsPress,
  onViewBondClaimPress,
  onEditBondReferencePress
}) => {
  const {
    data: tenancies,
    error,
    loading,
    refetch
  } = useGetFormerAndVoidedTenancies(managementId);

  useOnFocus(refetch);

  if (loading && !tenancies) {
    return <BaseTenancyCard.Loading style={{ marginTop: 16 }} />;
  }

  if (error || !tenancies) {
    return <BaseTenancyCard.Error onRetry={refetch} />;
  }

  if (!tenancies || isEmpty(tenancies)) return null;

  return (
    <>
      <Separator
        style={{
          marginLeft: 0,
          marginBottom: 40,
          marginTop: 40,
          borderColor: opacify(Colors.CHARCOAL, 0.075)
        }}
      />
      <View>
        {tenancies.map((formerTenancy, index) =>
          formerTenancy?.id ? (
            withErrorBoundary({
              tenancyCard: (
                <FormerTenancyCard
                  style={{ marginBottom: 16 }}
                  managementId={managementId}
                  tenancyId={formerTenancy.id}
                  onDetailsPress={onTenancyDetailsPress}
                  onRentAdjustmentsPress={onRentAdjustmentsPress}
                  onTenantPress={onTenantPress}
                  onAddBondDetailsPress={onAddBondDetailsPress}
                  onEditBondDetailsPress={onEditBondDetailsPress}
                  onViewBondClaimPress={onViewBondClaimPress}
                  onEditBondReferencePress={onEditBondReferencePress}
                />
              ),
              cardTitle: "Former tenancy",
              key: `Former-Tenancy-${formerTenancy.id}`
            })
          ) : (
            <ErrorCard
              cardTitle={"Former tenancy details"}
              style={{ marginBottom: 16 }}
              key={`Former-Tenancy-Error-${index}`}
            />
          )
        )}
      </View>
    </>
  );
};

function withErrorBoundary({
  tenancyCard,
  cardTitle,
  key
}: {
  tenancyCard: React.ReactElement;
  cardTitle: string;
  key?: string;
}): React.ReactElement {
  return (
    <PropertyScreenCardErrorBoundary
      cardTitle={cardTitle}
      key={key}
      style={{ marginBottom: 16 }}
    >
      {tenancyCard}
    </PropertyScreenCardErrorBoundary>
  );
}
