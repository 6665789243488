export enum LeaseType {
  FixedTerm = "Fixed term lease",
  Periodic = "Periodic lease signed",
  AllowCurrentToBePeriodic = "Allow current lease to become periodic"
}

export interface LeaseRenewalFormData {
  agreementFixedTermEndDate?: string;
  agreementId?: string;
  agreementStartDate: string;
  currentAgreementFixedTermEndDate?: string;
  leaseType: LeaseType;
  rentReviewChecked: boolean;
  tenancyId: string;
}
