import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import {
  GetCentrepayersQueryVariables,
  useGetCentrepayersQuery
} from "local/graphql";
import { AiloRN } from "@ailo/ailorn";

export function useGetCentrepayers({
  managingEntity,
  tenancy
}: {
  managingEntity: AiloRN;
  tenancy: AiloRN;
}): MappedQueryResult<
  { tenantLegalEntity: AiloRN }[],
  GetCentrepayersQueryVariables
> {
  const result = useGetCentrepayersQuery({
    variables: {
      input: {
        managingEntity: managingEntity.toString(),
        tenancy: tenancy.toString()
      }
    }
  });

  return useMappedQueryResult(result, (data) => {
    return (data.getCentrepayers || []).map((centrepayer) => ({
      tenantLegalEntity: AiloRN.from(centrepayer.legalEntity)
    }));
  });
}
