import {
  BadgeQueryParams,
  ProjectSideView,
  Screens,
  useRoute
} from "local/common";
import React, { ReactElement, useMemo } from "react";
import { ActionsCountBadge, ActionsTab } from "./Actions";
import { ActionListHeader } from "./ActionListHeader";

interface ProjectTabData {
  label: ProjectSideView;
  listComponent: React.ComponentType<{ tabNavigation: React.ReactElement }>;
  badge?: React.ComponentType<{
    active?: boolean;
    params?: BadgeQueryParams;
  }>;
}

export function ProjectSideTabs(): ReactElement | null {
  const { tab } = useRoute<Screens.Project>().params!;
  const projectTabData = useMemo(() => {
    const tabs: ProjectTabData[] = [
      {
        label: ProjectSideView.ACTIONS,
        listComponent: ActionsTab,
        badge: ActionsCountBadge
      }
    ];
    return tabs;
  }, []);

  const currentIndex = projectTabData.findIndex((data) =>
    data.label.includes(
      (tab && tab.toString()) || ProjectSideView.ACTIONS.toString()
    )
  );
  const { listComponent: List } = projectTabData[currentIndex];

  return (
    <List
      tabNavigation={
        <ActionListHeader
          tabs={projectTabData}
          selectedTabIndex={currentIndex}
        />
      }
    />
  );
}
