import { Button, ButtonVariant, Tooltip } from "@ailo/ui";
import React, { ReactElement, ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";

export type ButtonWithTooltipProps = {
  onPress?(): void;
  label: string | ReactNode;
  disabled?: boolean;
  tooltipText?: string;
  buttonVariant?: ButtonVariant;
  style?: StyleProp<ViewStyle>;
};

export const ButtonWithTooltip = ({
  label,
  onPress,
  disabled,
  tooltipText,
  buttonVariant = "primary",
  style
}: ButtonWithTooltipProps): ReactElement => {
  if (tooltipText) {
    return (
      <Tooltip tooltipContent={tooltipText} style={{ alignSelf: "baseline" }}>
        <Button
          onPress={onPress}
          disabled={disabled}
          variant={buttonVariant ?? "primary"}
          style={style}
        >
          {label}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button
      onPress={onPress}
      disabled={disabled}
      variant={buttonVariant ?? "primary"}
      style={[{ alignSelf: "baseline" }, style]}
    >
      {label}
    </Button>
  );
};

export function isButtonWithTooltipProps(
  button: ButtonWithTooltipProps | React.ReactNode
): button is ButtonWithTooltipProps {
  if (button && typeof button === "object" && "label" in button) {
    return true;
  }
  return false;
}
