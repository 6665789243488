import { GetClaimTenancyBondDetailsQuery } from "local/graphql";
import { AiloRN } from "@ailo/ailorn";
import { Money } from "@ailo/ui";
import { LocalDate } from "@ailo/date";
import { isRentLiability } from "./isRentLiability";
import { Bill } from "local/domain/bond";
import { GqlClaim } from "./types";

type GqlBill = NonNullable<
  NonNullable<
    NonNullable<GetClaimTenancyBondDetailsQuery["tenancy"]>["billsAsPayer"]
  >["items"]
>[number];

export function parseBills(data: GetClaimTenancyBondDetailsQuery): Bill[] {
  const claimedBills = findBillClaims(data.tenancy?.bond?.claims);

  return (
    data.tenancy?.billsAsPayer?.items.map((billData) => {
      return {
        ailoRN: AiloRN.fromString(billData.ailoRN),
        payeeName: getPayeeName(billData),
        category: billData.taxCategory.name,
        amount: Money.from(billData.amount),
        overdue: LocalDate.from(billData.dueDateV2).isBefore(LocalDate.today()),
        isSelected: isClaimedBill(claimedBills, billData.ailoRN)
      };
    }) || []
  );
}

function getPayeeName(billData: GqlBill): string | undefined {
  if (billData.payee?.__typename === "Supplier") {
    return billData.payee.name || undefined;
  }
  return "Bill to the Owner";
}

function isClaimedBill(claimedBills: GqlClaim[], billAiloRN: String): boolean {
  return (
    claimedBills.filter(
      (claimedBill) => claimedBill.liabilityAiloRN == billAiloRN
    ).length > 0
  );
}

export function findBillClaims(claims?: GqlClaim[] | null): GqlClaim[] {
  return (
    claims?.filter((claim) =>
      claim?.liabilityAiloRN ? !isRentLiability(claim) : false
    ) || []
  );
}
