import { AiloSentry } from "@ailo/services";
import { ReviewTenantProfileCard } from "local/modals/TenancyModalForm/TenancyForms/ReviewTenancyDetailsStep/ReviewTenantProfileCard";
import React, { ReactElement } from "react";
import { hasPresentKey } from "ts-is-present";
import { BaseActionActivity } from "../../BaseActionActivity";
import { EditSectionHeader } from "../../EditSectionHeader";
import { TenantFragment } from "local/graphql";

export function TenantDetailsSection({
  tenants
}: {
  tenants: TenantFragment[];
}): ReactElement {
  return (
    <BaseActionActivity>
      <EditSectionHeader
        title={"Renters"}
        displayEditButton={false}
        style={{ marginBottom: 20 }}
      />
      {tenants.filter(hasPresentKey("tenant")).map(({ tenant }, index) => {
        if (tenant.__typename === "Company") {
          AiloSentry.captureException(
            new Error(
              `Company as renters is not enabled yet. Error occured for tenant id: ${tenant.id}`
            )
          );
          return <></>;
        }
        return (
          <ReviewTenantProfileCard
            key={`Tenant-Review-Card-${tenant.id}`}
            person={{
              dateOfBirth: tenant.birthDate,
              email: tenant.emailAddress,
              phoneNumber: tenant.phoneNo,
              middleName: tenant.legalMiddleName,
              firstName: tenant.legalFirstName,
              ...tenant
            }}
            style={{
              marginTop: 12,
              marginBottom: index === tenants.length - 1 ? 0 : 12
            }}
          />
        );
      })}
    </BaseActionActivity>
  );
}
