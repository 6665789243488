import { useAnalytics, uuid } from "@ailo/services";
import { startCase } from "lodash";
import { useMemo } from "react";
import { CreatePaymentMethodFormData } from "./CreatePaymentMethodForm";

type Supplier = {
  name: string;
  ailoRN: string;
};

type FormData = CreatePaymentMethodFormData;

interface UseCreatePaymentMethodAnalytics {
  trackFormOpened: () => void;
  trackValueUpdated: (
    fieldName: string,
    isValid: boolean | undefined,
    value: string,
    formValues: FormData
  ) => void;
  trackSubmitSuccess: (formValues: FormData) => void;
}

const formName = "Add Payment Method";

export function useCreatePaymentMethodAnalytics(
  supplier: Supplier
): UseCreatePaymentMethodAnalytics {
  const formSessionId = useMemo(() => uuid(), []);
  const analytics = useAnalytics();

  return useMemo(() => {
    function trackFormOpened(): void {
      analytics.trackScreenVisited("Add Payment Method", {
        form_name: formName,
        form_session_id: formSessionId,
        supplier_id: supplier.ailoRN
      });
    }

    function trackValueUpdated(
      fieldName: string,
      isValid: boolean | undefined,
      value: string,
      formValues: FormData
    ): void {
      analytics.track("Form Step Completed", {
        form_name: formName,
        form_session_id: formSessionId,
        supplier_id: supplier.ailoRN,
        step_name: startCase(fieldName),
        value: censorValue(value),
        value_is_valid:
          isValid === undefined ? "Pending" : isValid ? "Yes" : "No",
        steps_completed_this_far: formValuesToStepsCompleted(formValues)
      });
    }

    function trackSubmitSuccess(formValues: FormData): void {
      analytics.track("Payment Method Added", {
        form_name: formName,
        form_session_id: formSessionId,
        supplier_id: supplier.ailoRN,
        steps_completed: formValuesToStepsCompleted(formValues)
      });
    }

    return {
      trackFormOpened,
      trackValueUpdated,
      trackSubmitSuccess
    };
  }, [analytics, supplier, formSessionId]);
}

function formValuesToStepsCompleted(formValues: FormData): string[] {
  return Object.keys(formValues)
    .filter(
      (key) => !!formValues[key as keyof FormData] || key === "paymentMethod"
    )
    .map(startCase);
}

function censorValue(text: string): string {
  return text.length > 1 ? text[0] + text.slice(1).replace(/./g, "*") : text;
}
