import React, { ReactElement, useCallback, useState } from "react";
import styled from "styled-components/native";
import { Button } from "@ailo/ui";
import { ExcludePropertyModal } from "./ExcludePropertyModal";

interface Props {
  migratingManagementId: string;
  closeSidebar: () => void;
}

export function ExcludePropertyButton({
  migratingManagementId,
  closeSidebar
}: Props): ReactElement {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback((): void => {
    setModalVisible(false);
  }, []);

  return (
    <>
      <StyledSecondaryButton onPress={() => setModalVisible(true)}>
        {"Exclude Property"}
      </StyledSecondaryButton>

      <ExcludePropertyModal
        migratingManagementId={migratingManagementId}
        closeSidebar={closeSidebar}
        modalVisible={modalVisible}
        closeModal={closeModal}
      />
    </>
  );
}

const StyledSecondaryButton = styled(Button.Secondary)`
  flex: 1;
`;
