import { AiloIcon, Colors, opacify, Text } from "@ailo/primitives";
import React, { useState } from "react";
import { CloseButton, SplitContainer } from "local/common";
import styled from "styled-components/native";
import { Platform, View } from "react-native";
import { SpinnerOverlay } from "@ailo/ui";

interface Props {
  title: string;
  renderFormComponent: (
    onFormSubmitting: (isSubmitting: boolean) => void
  ) => React.ReactElement;
  renderRightComponent?: () => React.ReactElement;
  onDismiss: () => void;
}

export function BaseSplitScreenModalForm({
  title,
  renderFormComponent,
  renderRightComponent,
  onDismiss
}: Props): React.ReactElement {
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  return (
    <SplitScreenModalFormWrapper>
      <HeaderContainer>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <AiloIcon
            style={{
              marginRight: 24
            }}
          />
          <Text.BodyM weight={"medium"}>{title}</Text.BodyM>
        </View>
        <CloseButton onDismiss={onDismiss} />
      </HeaderContainer>
      <SplitContainer
        left={renderFormComponent((isSubmitting: boolean) =>
          setSpinnerVisible(isSubmitting)
        )}
        leftSectionSize={"large"}
        right={
          <View style={{ backgroundColor: Colors.CLOUD, flex: 1 }}>
            {renderRightComponent && renderRightComponent()}
          </View>
        }
      />
      {spinnerVisible && <SpinnerOverlay visible={spinnerVisible} />}
    </SplitScreenModalFormWrapper>
  );
}

const HeaderContainer = styled(View)`
  max-height: 56px;
  flex-direction: row;
  flex: 1;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  align-items: center;
  justify-content: space-between;
`;

const SplitScreenModalFormWrapper = styled(View)`
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: ${Colors.WHITE};
  ${Platform.OS === "web" ? `cursor: auto;` + `user-select: text;` : null}
`;
