import { AiloRN } from "@ailo/ailorn";
import { Link } from "@ailo/services";
import { getNavigateToPropertyAction } from "./getNavigateToPropertyAction";

export function generateLinkToProperty({
  managementAilorn
}: {
  managementAilorn: AiloRN<"propertymanagement:management">;
}): Link {
  return {
    to: `/properties/${managementAilorn.internalId}/`,
    action: getNavigateToPropertyAction(managementAilorn.internalId)
  };
}
