import React, { ReactElement } from "react";
import { Grid } from "@ailo/ui";
import { useApprovedPropertyOnboardingList } from "./hooks";
import { PropertyOnboardingListError } from "../PropertyOnboardingListError";
import { PropertyOnboardingListEmpty } from "../PropertyOnboardingListEmpty";

const BODY_STYLE = {
  // Both of these margin props are required to override Grid
  margin: "-48px 0 0 0",
  marginTop: -48,
  paddingTop: 48
};

export function ApprovedPropertyOnboardingList({
  tabNavigation
}: {
  tabNavigation: ReactElement;
}): ReactElement {
  const { queryData, reorder, columns, content, baseContent, loadingContent } =
    useApprovedPropertyOnboardingList({ tabNavigation });

  const { data, loading, requestFromBeginning } = queryData;
  const { sortOrder, onSortOrderChange } = reorder;
  const { headerOverride, failedPropertiesModal } = content;
  const {
    headerCaption,
    styledHeader,
    footerCaption,
    onRowPress,
    renderRowContainer
  } = baseContent;
  const { loadingRows, loadingColumns, renderLoadingRowContainer } =
    loadingContent;

  if (loading) {
    return (
      <Grid
        headerCaption={headerCaption(true)}
        headerOverride={styledHeader}
        rows={loadingRows}
        columns={loadingColumns}
        sortOrder={sortOrder[0]}
        renderRowContainer={renderLoadingRowContainer}
        bodyStyle={BODY_STYLE}
      />
    );
  }

  if (data.error) {
    return (
      <Grid.Placeholder headerCaption={headerCaption(false)}>
        <PropertyOnboardingListError
          message={"There was a problem loading Approved Properties"}
          onReload={(): void => requestFromBeginning({ sortOrder })}
        />
      </Grid.Placeholder>
    );
  }

  if (data.pageInfo.total === 0) {
    return (
      <Grid.Placeholder headerCaption={headerCaption(false)}>
        <PropertyOnboardingListEmpty
          message={"Approved List"}
          secondaryMessage={
            "When you mark properties as approved, you’ll\n" +
            "see them here before they go live"
          }
        />
      </Grid.Placeholder>
    );
  }

  return (
    <>
      <Grid
        headerCaption={headerCaption(true)}
        headerOverride={headerOverride ?? styledHeader}
        renderRowContainer={renderRowContainer}
        onRowPress={onRowPress}
        footerCaption={footerCaption}
        columns={columns}
        rows={data.migratingManagements}
        sortOrder={sortOrder[0]}
        onSortOrderChange={onSortOrderChange}
        bodyStyle={BODY_STYLE}
      />

      {failedPropertiesModal}
    </>
  );
}
