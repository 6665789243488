import { LocalDate } from "@ailo/date";
import { DatePeriod, Digit } from "./DatePeriod";

type QuarterPeriodInput = `20${Digit}${Digit}-Q${"1" | "2" | "3" | "4"}`;

export class QuarterPeriod extends DatePeriod {
  public from(input: QuarterPeriodInput | LocalDate): QuarterPeriod {
    if (input === null || input === undefined)
      throw Error(`QuarterPeriod can't be initialized by null or undefined`);
    return new QuarterPeriod(input);
  }

  public get startDate(): LocalDate {
    return this.date
      .setDayOfMonth(1)
      .setMonthOfYear(this.date.calanderQuarter * 3 - 2);
  }

  public get endDate(): LocalDate {
    return this.startDate.add(3, "months").subtract(1, "day");
  }

  public shortFormat(): string {
    return `Q${this.date.calanderQuarter} ‘${this.date.format("YY")}`;
  }

  public longFormat(): string {
    return `Q${this.date.calanderQuarter} ${this.date.format("YYYY")}`;
  }

  public parse(input: QuarterPeriodInput | null | undefined): LocalDate {
    if (input === null || input === undefined)
      throw Error(`QuarterPeriod can't be initialized by null or undefined`);

    const parsedValue = LocalDate.parse(input);
    if (parsedValue !== undefined) return parsedValue;

    const year = Number(input?.substr(0, 4));
    const month = 1 + (Number(input?.split("Q")[1]) - 1) * 3;
    return LocalDate.from({ year, month, day: 1 });
  }
}
