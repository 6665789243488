import { AiloRN, services } from "@ailo/ailorn";
import { DateTimeWithTimeZone } from "@ailo/ui";
import { AnalyticsService, useTimer, UseTimerOutput } from "local/common";
import { useCallback } from "react";

function useTenancyTimer(
  tenancyId: string,
  downloadTimeOutInMs: number,
  analytics: AnalyticsService
): UseTimerOutput {
  const onTimeOut = useCallback(
    (startTime: DateTimeWithTimeZone): void => {
      analytics.track(`Tenancy Ledger Generation Failed`, {
        tenancy_id: AiloRN.of(services.PropertyManagement.tenancy, tenancyId),
        request_made_at: startTime?.toISOString(),
        timed_out_at: DateTimeWithTimeZone.now().toISOString()
      });
    },
    [analytics, tenancyId]
  );

  return useTimer(!!tenancyId, downloadTimeOutInMs, onTimeOut);
}

export { useTenancyTimer };
