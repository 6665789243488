import { Colors, Text } from "@ailo/primitives";
import { formatDate } from "@ailo/services";
import { DescriptionList } from "@ailo/ui";
import { Action } from "local/domain/project";
import { ActionType } from "local/graphql";
import { BaseFormSeparator } from "local/modals/ui/BaseFormSeparator";
import { EditSectionHeader } from "local/tabs/projects/actions/ActionSidebar/ActionActivity/EditSectionHeader";
import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { useFormContext } from "react-hook-form";
import { View } from "react-native";
import { LeaseRenewalFormData, LeaseType } from "../LeaseRenewalFormData";
import { AllowCurrentLeaseToBePeriodicInfo } from "../LeaseRenewalSection/AllowCurrentLeaseToBePeriodicInfo";

export function ReviewLeaseRenewalStep({
  action,
  display,
  setCurrentStepNumber
}: {
  action?: Action<ActionType.AssignTenancyAgreement>;
  display: boolean;
  setCurrentStepNumber: Dispatch<SetStateAction<number>>;
}): ReactElement {
  const { watch } = useFormContext<LeaseRenewalFormData>();
  const leaseType = watch("leaseType");
  const startDate = watch("agreementStartDate");
  const endDate = watch("agreementFixedTermEndDate");
  const currentAgreementFixedTermEndDate = watch(
    "currentAgreementFixedTermEndDate"
  );
  const rentReviewChecked = watch("rentReviewChecked");

  return (
    <View style={{ display: display ? undefined : "none" }}>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
        {"Please confirm the details are correct"}
      </Text.BodyM>
      <BaseFormSeparator style={{ marginTop: 32 }} />
      <EditSectionHeader
        title={"Lease renewal"}
        onPress={() => setCurrentStepNumber(1)}
        style={{ marginBottom: 20 }}
      />
      {leaseType === LeaseType.AllowCurrentToBePeriodic &&
      !!currentAgreementFixedTermEndDate ? (
        <AllowCurrentLeaseToBePeriodicInfo
          style={{ marginTop: 24 }}
          agreementEndDate={currentAgreementFixedTermEndDate}
        />
      ) : (
        <>
          <DescriptionList
            size={"small"}
            columns={2}
            items={[
              {
                label: "Start date",
                descriptionBottom: startDate ? formatDate(startDate) : "N/A",
                columnIndex: 0
              },
              {
                label: "End date",
                descriptionBottom: endDate ? formatDate(endDate) : "N/A",
                columnIndex: 1
              }
            ]}
          />
        </>
      )}
      {!action && (
        <DescriptionList
          size={"small"}
          items={[
            {
              label: "Add rent review",
              descriptionBottom: rentReviewChecked ? "Yes" : "No",
              columnIndex: 0
            }
          ]}
          style={{ marginTop: 16 }}
        />
      )}
    </View>
  );
}
