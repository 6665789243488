import React from "react";
import { Colors, Skeleton } from "@ailo/primitives";
import { useGetOverdueDateFromOwingQuery } from "local/graphql";
import { ErrorCell } from "./ErrorCell";
import { BasePropertyListRow } from "../BasePropertyListRow";
import { LocalDate } from "@ailo/date";
import { CellContainer, TextCellContent } from "@ailo/ui";

export function OverdueDateFromOwingCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetOverdueDateFromOwingQuery({
    variables: { managementId }
  });

  if (loading && !data) return <Loading />;

  const rentOwing = data?.management?.mostRecentTenancy?.rent;
  if (!rentOwing)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const dueDate = new LocalDate(rentOwing.progress.overdue.date);
  const overdueDays = LocalDate.today().diff(dueDate, "days");

  return (
    <CellContainer>
      <TextCellContent>{dueDate.format("DD MMM YYYY")}</TextCellContent>
      <TextCellContent color={Colors.TEXT.DARK.SECONDARY}>
        {`${overdueDays} ${overdueDays > 1 ? "days" : "day"}`}
      </TextCellContent>
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <Skeleton
        style={{
          width: 70,
          height: 12
        }}
      />
      <Skeleton
        style={{
          width: 60,
          height: 12,
          marginTop: 8
        }}
      />
    </CellContainer>
  );
}

OverdueDateFromOwingCell.Loading = Loading;
