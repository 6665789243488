import { AiloSentry, useAnalytics } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { useCallback } from "react";
import {
  UpdateIngoingManagementMutation,
  useUpdateIngoingManagementMutation,
  ManagementFragment
} from "local/graphql";
import { EditManagementFormData } from "../EditManagementFormWithData";

type EditManagementInput = EditManagementFormData & {
  agreementStartDate: string;
};

export function useUpdateIngoingManagement({
  onError
}: {
  onCompleted?: (management: ManagementFragment) => void;
  onError?: (error?: unknown) => void;
}): [
  ({
    formData,
    managementId
  }: {
    formData: EditManagementInput;
    managementId: string;
  }) => Promise<void>,
  MutationResult<UpdateIngoingManagementMutation>
] {
  const analytics = useAnalytics();
  const [_updateIngoingManagement, result] =
    useUpdateIngoingManagementMutation();

  const editManagement = useCallback(async (): Promise<void> => {
    try {
      //call update mutation here
      // await onCompleted?.(management);
    } catch (error) {
      AiloSentry.captureException(error);
      return onError?.(error);
    }

    analytics.trackIngoingManagementUpdated();
  }, [onError, analytics]);

  return [editManagement, result];
}
