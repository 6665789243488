import React, { ReactElement, ReactNode } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ProjectFormData } from "./ProjectFormData";

export function ProjectFormContext({
  initialValue,
  children
}: {
  initialValue?: ProjectFormData;
  children?: ReactNode;
}): ReactElement {
  const formMethods = useForm<ProjectFormData>({
    mode: "onChange",
    shouldFocusError: true,
    ...(initialValue && { defaultValues: initialValue })
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
}
