import {
  ActionRequiredListItem,
  DefaultRequestSignatureActionDescription,
  ReiFormDeletedAlert
} from "local/domain/project";
import React, { ReactElement } from "react";
import { BaseActionActivity } from "../../BaseActionActivity";

export function NoSigningRequestView({
  formDeleted
}: {
  formDeleted?: boolean;
}): ReactElement {
  return (
    <BaseActionActivity
      description={<DefaultRequestSignatureActionDescription />}
    >
      {formDeleted && <ReiFormDeletedAlert />}
      <ActionRequiredListItem
        header={"No signing request yet"}
        subHeader={"Please send the form and add signature tracking"}
      />
    </BaseActionActivity>
  );
}
