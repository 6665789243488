import React from "react";
import { AFC } from "@ailo/primitives";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import { View } from "react-native";

interface Props {
  drawerItems: React.ReactElement[];
  header?: React.ReactElement;
  footer?: React.ReactElement;
}

const DrawerContent: AFC<Props> = ({
  drawerItems,
  header,
  footer,
  ...rest
}) => {
  return (
    <DrawerContentScrollView
      contentContainerStyle={{
        flex: 1,
        justifyContent: "space-between",
        paddingTop: 0
      }}
      {...rest}
    >
      <View>
        {header}
        {drawerItems.map((drawerItem, index) => (
          <React.Fragment key={index}>{drawerItem}</React.Fragment>
        ))}
      </View>
      {footer}
    </DrawerContentScrollView>
  );
};

export { DrawerContent };
