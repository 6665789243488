import {
  Button,
  createModalFilterItem,
  getTimePeriodString,
  LocalDate,
  ModalFilter,
  TimePeriodRadioButtons,
  TimePeriods,
  TimePeriodInput
} from "@ailo/ui";
import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { View } from "react-native";
import { isValidDateSelection } from "./isValidDateSelection";

interface Defaults {
  startDate: string;
  endDate: string;
  timePeriod: TimePeriods;
  earliestTransaction: string;
}

export function RevenueTimeFilterComponent(defaults: Defaults): ReactElement {
  const { watch } = useFormContext();
  const { timePeriod } = watch();
  const minDate =
    LocalDate.parse(defaults.earliestTransaction) ??
    LocalDate.today().subtract(1, "year");
  const earliestTransaction =
    LocalDate.parse(defaults.earliestTransaction) ?? LocalDate.today();

  const startDate = (
    <TimePeriodInput
      timePeriod={timePeriod ?? defaults.timePeriod}
      inputName={"startDate"}
      formRowLabel={"Start date"}
      autoPopulateButtonLabel={"Earliest transaction"}
      autoPopulateDate={earliestTransaction}
      minDate={minDate}
      defaultValue={
        LocalDate.from(defaults.startDate).isSameOrAfter(minDate)
          ? defaults.startDate
          : minDate.toString()
      }
    />
  );
  const endDate = (
    <TimePeriodInput
      timePeriod={timePeriod ?? defaults.timePeriod}
      inputName={"endDate"}
      formRowLabel={"End date"}
      autoPopulateButtonLabel={"Present"}
      autoPopulateDate={LocalDate.today()}
      minDate={minDate}
      defaultValue={defaults.endDate}
      setDateAsPeriodEnd
    />
  );

  return (
    <>
      <TimePeriodRadioButtons
        timePeriod={timePeriod ?? defaults.timePeriod}
        options={[
          { label: "Month", value: "month" },
          { label: "Quarter", value: "quarter" },
          {
            label: "Financial year",
            value: "financial year"
          }
        ]}
      />
      <View style={{ marginBottom: 20 }}>{startDate}</View>
      {endDate}
    </>
  );
}

export function RevenueReportTimeFilter(defaults: Defaults): ModalFilter {
  return {
    FilterComponent: createModalFilterItem(
      <RevenueTimeFilterComponent {...defaults} />,
      {
        title: "Time period"
      }
    ),
    errorConfig: {
      errorEvaluationFunction: isValidDateSelection,
      errorMessage: "End date must be after start date"
    },
    title: "Time period",
    filterKey: "timePeiod",
    // eslint-disable-next-line react/display-name
    ButtonComponent: ({ onPress, index, filterState }) => {
      return (
        <Button.Filter
          key={index}
          onPress={onPress}
          style={{ marginRight: 12 }}
        >
          {getTimePeriodString({ ...defaults, ...filterState })}
        </Button.Filter>
      );
    }
  };
}
