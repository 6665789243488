import { LoadingSynchroniser } from "@ailo/primitives";
import {
  LedgerDetailsErrorBoundary,
  TenancyTransactionsList
} from "local/domain/ledger";
import {
  TenancySwitcher,
  useGetManagementTenancies
} from "local/domain/propertyManagement";
import React, { useState } from "react";
import { EmptyRentTransactionsList } from "./components";

interface Props {
  managementId: string;
  initialTenancyId: string;
  onLedgerDetailEntryPress: (liabilityEntryId: string) => void;
}

export const SwitchableTenancyTransactionList: React.FC<Props> = ({
  managementId,
  initialTenancyId,
  onLedgerDetailEntryPress
}) => {
  const [tenancyIdState, setTenancyId] = useState(initialTenancyId);
  const tenanciesResult = useGetManagementTenancies(managementId);
  const tenancyId = tenancyIdState ?? initialTenancyId;

  return (
    <LoadingSynchroniser>
      {tenanciesResult.loading ? (
        <TenancySwitcher.Loading />
      ) : (
        <TenancySwitcher
          value={tenancyId}
          tenancies={tenanciesResult.data!}
          managementId={managementId}
          onChange={setTenancyId}
        />
      )}
      <LedgerDetailsErrorBoundary sectionName={"Tenancy"}>
        <TenancyTransactionsList
          tenancyId={tenancyId}
          emptyView={EmptyRentTransactionsList}
          onLedgerDetailEntryPress={onLedgerDetailEntryPress}
        />
      </LedgerDetailsErrorBoundary>
    </LoadingSynchroniser>
  );
};
