import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { useGetInspectionReportStateQuery } from "local/graphql";

export function useGetInspectionCanGenerateReport(
  id: string
): MappedQueryResult<boolean | undefined> {
  return useMappedQueryResult(
    useGetInspectionReportStateQuery({
      variables: { id },
      fetchPolicy: "network-only"
    }),
    (data) => {
      if (data == null) {
        return false;
      }

      return data.inspection.reportGenerationState.canGenerateReport;
    }
  );
}
