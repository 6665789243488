import { useOnFocus } from "@ailo/services";
import { SidebarContent, SidebarTitle } from "@ailo/ui";
import React from "react";
import { ViewStyle } from "react-native";
import {
  ManagementOrTenancySidebarError,
  ManagementOrTenancySidebarLoading
} from "../common";
import { TenancyDetailsDisplay } from "./TenancyDetailsDisplay";
import { useGetTenancyDetails } from "./useGetTenancyDetails";

interface Props {
  onDismiss: () => void;
  tenancyDetails: {
    id: string;
    title: string;
  };
}

export function TenancySidebarContent({
  onDismiss,
  tenancyDetails
}: Props): React.ReactElement {
  const { data, loading, refetch } = useGetTenancyDetails({
    variables: { id: tenancyDetails.id }
  });

  useOnFocus(refetch);

  const { title } = tenancyDetails;
  if (loading && !data) {
    return (
      <ManagementOrTenancySidebarLoading>
        <TenancyDetailsHeader
          title={title}
          onDismiss={onDismiss}
          style={{ marginHorizontal: 0, marginTop: 0, marginBottom: 0 }}
        />
      </ManagementOrTenancySidebarLoading>
    );
  }

  if (!data)
    return (
      <ManagementOrTenancySidebarError
        type={"tenancy"}
        onRetry={(): void => {
          refetch();
        }}
      >
        <TenancyDetailsHeader title={title} onDismiss={onDismiss} />
      </ManagementOrTenancySidebarError>
    );

  return (
    <SidebarContent
      title={tenancyDetails.title}
      closeButton
      onClose={onDismiss}
    >
      <TenancyDetailsDisplay tenancyId={tenancyDetails.id} {...data} />
    </SidebarContent>
  );
}

function TenancyDetailsHeader({
  title,
  style,
  onDismiss
}: {
  title: string;
  style?: ViewStyle;
  onDismiss: () => void;
}): React.ReactElement {
  return (
    <SidebarTitle title={title} closeButton onClose={onDismiss} style={style} />
  );
}
