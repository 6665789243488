import {
  AiloSentry,
  MutationResultDataMissingError,
  useAnalytics
} from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { useCallback } from "react";
import {
  useSetRoutineInspectionDueDateMutation,
  TenancyInspectionScheduleFragment,
  SetRoutineInspectionDueDateMutation
} from "local/graphql";
import { useToastContext } from "@ailo/ui";
import { AiloRN } from "@ailo/ailorn";

export type SetRoutineInspectionDueDateInput = {
  tenancyId: string;
  dueDate: string;
};

export function useSetRoutineInspectionDueDate({
  onCompleted,
  onError
}: {
  onCompleted?: (
    tenancyInspectionSchedule: TenancyInspectionScheduleFragment
  ) => void;
  onError?: (error?: unknown) => void;
}): [
  ({ tenancyId, dueDate }: SetRoutineInspectionDueDateInput) => Promise<void>,
  MutationResult<SetRoutineInspectionDueDateMutation>
] {
  const analytics = useAnalytics();
  const toast = useToastContext();
  const [mutate, result] = useSetRoutineInspectionDueDateMutation();

  const setRoutineInspectionDueDate = useCallback(
    async ({
      tenancyId,
      dueDate
    }: SetRoutineInspectionDueDateInput): Promise<void> => {
      try {
        const result = await mutate({
          variables: {
            input: {
              tenancyAilorn: AiloRN.of(
                "propertymanagement:tenancy",
                tenancyId
              ).toString(),
              dueDate
            }
          }
        });

        const tenancy = result.data?.setRoutineInspectionDueDate.tenancy;
        if (!tenancy) {
          throw new MutationResultDataMissingError(
            "setRoutineInspectionDueDate",
            result
          );
        }
        await onCompleted?.(tenancy);
        toast.show({
          type: "success",
          message: "Inspection due date updated"
        });
      } catch (error) {
        AiloSentry.captureException(error);
        toast.show({
          type: "error",
          message: "Error updating inspection due date"
        });
        return onError?.(error);
      }

      analytics.trackRoutineInspectionDueDateUpdated();
    },
    [mutate, onCompleted, onError, analytics, toast]
  );

  return [setRoutineInspectionDueDate, result];
}
