import React, { ReactElement } from "react";
import { GridSortOrder, PaginationNavigation } from "@ailo/ui";
import { getPaginationData } from "./utils";
import { PROPERTY_ONBOARDING_LIST_PAGE_SIZE } from "./hooks";
import {
  PropertyOnboardingListPageInfo,
  PropertyOnboardingListRequestExtraVariables,
  PropertyOnboardingListRequestNewPage
} from "./types";

interface Props<
  TExtraVariables extends PropertyOnboardingListRequestExtraVariables
> {
  pageInfo: PropertyOnboardingListPageInfo;
  requestNewPage: PropertyOnboardingListRequestNewPage<TExtraVariables>;
  sortOrder: GridSortOrder[];
}

export function PropertyOnboardingListPaginationNavigation<
  TExtraVariables extends PropertyOnboardingListRequestExtraVariables
>({
  pageInfo,
  requestNewPage,
  sortOrder
}: Props<TExtraVariables>): ReactElement {
  const pagination = getPaginationData(
    pageInfo,
    PROPERTY_ONBOARDING_LIST_PAGE_SIZE
  );

  return (
    <PaginationNavigation
      {...pagination}
      style={{ marginTop: 20, marginBottom: 32, justifyContent: "center" }}
      onGoToPreviousPage={(): void => {
        requestNewPage({
          sortOrder,
          forward: false,
          pageNo: pagination.currentPage
        });
      }}
      onGoToNextPage={(): void => {
        requestNewPage({
          sortOrder,
          forward: true,
          pageNo: pagination.currentPage
        });
      }}
    />
  );
}
