export const ManagementNoteFields = {
  id: "id",
  managementId: "managementId",
  note: "note"
} as const;

export interface ManagementNoteFields {
  [ManagementNoteFields.id]: string;
  [ManagementNoteFields.managementId]: string;
  [ManagementNoteFields.note]: string;
}

export type ManagementNoteMode = "create" | "edit";

export type ManagementNoteFormData = {
  mode: "create" | "edit";
  id?: string;
  managementId: string;
  note?: string;
};
