import { Badge, CellContainer } from "@ailo/ui";
import React from "react";
import { ColumnBadgeOptions, ReportTableRow } from "..";

interface BadgeCellProps {
  columnKey: string;
  reportTableRow: ReportTableRow;
  badgeMapping: ColumnBadgeOptions["valueColorMapping"];
}

export function BadgeCell({
  columnKey,
  reportTableRow,
  badgeMapping
}: BadgeCellProps): React.ReactElement {
  if (columnKey in reportTableRow) {
    const badgeConfig = badgeMapping.find(
      (map) => map.cellValue == reportTableRow[columnKey]
    );
    return (
      <CellContainer>
        <Badge
          style={{
            width: "fit-content",
            marginRight: 16
          }}
          type={badgeConfig?.badgeType || "default"}
        >
          {badgeConfig?.text || reportTableRow[columnKey]}
        </Badge>
      </CellContainer>
    );
  }

  return <></>;
}
