import { useCurrentAgencyOrg } from "local/common";
import { AiloRN } from "@ailo/ailorn";
import { useSelectedTeams } from "local/domain/propertyManagement";
import { Md5 } from "ts-md5/dist/md5";
import { ReportTeamContextOption } from "./utils";

export function useReportTeamContext({
  excludeUnassignedFromTeamId
}: ReportTeamContextOption = {}): {
  managingOrgId: string;
  teamId?: string[];
  includeUnassigned: boolean;
} {
  const { id: managingOrgId } = useCurrentAgencyOrg();
  const {
    propertyListSelectedTeamIds: selectedTeamIds,
    propertyListUnassignedSelected: includeUnassigned
  } = useSelectedTeams();

  const teamId = selectedTeamIds
    .map((id) => AiloRN.from(id).internalId.toString())
    .concat(
      includeUnassigned && !excludeUnassignedFromTeamId
        ? [unassignedTeamId(managingOrgId)]
        : []
    );

  return {
    managingOrgId,
    teamId,
    includeUnassigned
  };
}

export const unassignedTeamId = (managingOrgId: string): string =>
  Md5.hashStr(`${managingOrgId}-unallocated`);
