import {
  formatLegalEntityName,
  formatNameInitials
} from "@ailo/domain-helpers";
import { Chip, InitialsAvatar } from "@ailo/ui";
import { LegalEntityNameFragment } from "local/graphql";
import { isEmpty } from "lodash";
import React, { ReactElement } from "react";
import { ChipsContainer } from "./ChipContainer";

export function TenantChips({
  tenants
}: {
  tenants: LegalEntityNameFragment[];
}): ReactElement | null {
  if (isEmpty(tenants)) return null;

  return (
    <ChipsContainer>
      {tenants.map((renter) => (
        <Chip
          key={renter.id}
          label={formatLegalEntityName(renter)}
          renderAvatar={(size) => (
            <InitialsAvatar
              initials={formatNameInitials(renter) || ""}
              size={size}
            />
          )}
          style={{
            marginTop: 4,
            marginRight: 8
          }}
        />
      ))}
    </ChipsContainer>
  );
}
