import { FeeBlueprintType } from "@ailo/domains";
import { Text } from "@ailo/primitives";
import {
  Alert,
  CheckboxInput,
  ConfirmModal,
  DateInputFormField,
  ErrorAlertScreen,
  feeMaxMoney,
  FormField,
  Money,
  MoneyInput,
  MoneyInterface,
  PercentInput,
  PercentInputFormField,
  SelectInput,
  Separator,
  TextInputFormField,
  validateMaxLength
} from "@ailo/ui";
import { FeeFrequency } from "local/graphql";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { ReactElement, useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { View } from "react-native";
import styled from "styled-components/native";
import { AgencyFeeBlueprintOption } from "../fee/managementFeeBlueprint/ManagementFeeBlueprintModalForm";
import { RecurringFeeBlueprintOption } from "./graphql";
import { GstBox } from "./GstBox";
import { ResetButton } from "./ResetButton";

export interface ExpenseFormData {
  totalAmount?: MoneyInterface;
  percentage?: number;
  description?: string | null;
  feeBlueprint?: RecurringFeeBlueprintOption | AgencyFeeBlueprintOption;
  startDate?: string | null;
  fixedAmount?: MoneyInterface;
  oneWeekRentPercentage?: number;
  includesGst?: boolean;
}

export type CreateEventBasedFeeFormData = {
  feeBlueprint?: AgencyFeeBlueprintOption;
  blueprintId?: string;
  fixedAmount?: MoneyInterface;
  oneWeekRentPercentage?: number;
  includesGst?: boolean;
};

interface EditExpenseModalFormProps {
  expenseType:
    | "RecurringFee"
    | "EventBasedFee"
    | "OneOffFee"
    | "ManagementFeeSchedule";
  /**
   * `add` only supported for RecurringFee
   */
  mode: "edit" | "add";
  title: string;
  initialValues?: ExpenseFormData;
  defaultValues?: ExpenseFormData;
  frequency?: string;
  hasNextOccurrence?: boolean;
  chargeDescription?: string;
  feeBlueprints?: (RecurringFeeBlueprintOption | AgencyFeeBlueprintOption)[];
  onSubmit: (value: ExpenseFormData) => Promise<void> | void;
  submitting: boolean;
  onCancel: () => void;
  visible: boolean;
}

export const MAX_DESCRIPTION_LENGTH = 60;

export function ExpenseModalForm({
  expenseType: expenseTypeProp,
  mode,
  title,
  initialValues,
  defaultValues,
  frequency: frequencyProp,
  hasNextOccurrence,
  chargeDescription: chargeDescriptionProp,
  feeBlueprints = [],
  onSubmit,
  submitting,
  onCancel,
  visible
}: EditExpenseModalFormProps): ReactElement {
  const [expenseType, setExpenseType] = useState(expenseTypeProp);

  const form = useForm<ExpenseFormData>({
    defaultValues: initialValues
  });

  const { setValue, watch, errors } = form;

  const submit = form.handleSubmit(onSubmit);

  React.useEffect(() => {
    if (visible) {
      setValue("feeBlueprint", initialValues?.feeBlueprint);
      setValue("totalAmount", initialValues?.totalAmount);
      setValue("percentage", initialValues?.percentage);
      setValue("description", initialValues?.description);
      setValue("startDate", initialValues?.startDate);
      setValue("fixedAmount", initialValues?.fixedAmount);
      setValue("oneWeekRentPercentage", initialValues?.oneWeekRentPercentage);
      setValue("includesGst", initialValues?.includesGst);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const totalAmount = watch("totalAmount");
  const feeBlueprint = watch("feeBlueprint");
  const fixedAmount = watch("fixedAmount");
  const oneWeekRentPercentage = watch("oneWeekRentPercentage");
  const includesGst = watch("includesGst");

  const event = getEventBasedFeeValue(feeBlueprint, "event");
  const chargeDescription =
    chargeDescriptionProp ||
    (event ? `The fee is charged ${event.longDescription}` : undefined);

  const defaultTotalAmount =
    defaultValues?.totalAmount ??
    getRecurringFeeValue(feeBlueprint, "priceIncludingTax") ??
    Money.zero();

  const frequency =
    frequencyProp ?? getRecurringFeeValue(feeBlueprint, "frequency");

  const totalAmountFieldDisabled =
    mode === "edit" && expenseType === "RecurringFee" && !hasNextOccurrence;

  const feeBlueprintNotSelected =
    mode === "add" && expenseType === "RecurringFee" && !feeBlueprint;

  const resetPrice = useCallback((): void => {
    setValue(
      "fixedAmount",
      getEventBasedFeeValue(feeBlueprint, "fixedAmount"),
      {
        shouldValidate: true
      }
    );
    setValue(
      "oneWeekRentPercentage",
      getEventBasedFeeValue(feeBlueprint, "oneWeekRentPercentage"),
      {
        shouldValidate: true
      }
    );
    setValue("totalAmount", defaultTotalAmount, {
      shouldValidate: true
    });
  }, [defaultTotalAmount, feeBlueprint, setValue]);

  const resetPriceButton = (
    <ResetButton
      visible={
        feeBlueprint?.type === "EventBasedFee"
          ? fixedAmount?.cents !==
              getEventBasedFeeValue(feeBlueprint, "fixedAmount")?.cents ||
            oneWeekRentPercentage !=
              getEventBasedFeeValue(feeBlueprint, "oneWeekRentPercentage")
          : !totalAmountFieldDisabled &&
            totalAmount?.cents !== defaultTotalAmount.cents
      }
      onPress={() => resetPrice()}
    />
  );

  const setBlueprintDefaults = (
    blueprint: RecurringFeeBlueprintOption | AgencyFeeBlueprintOption
  ): void => {
    setValue(
      "totalAmount",
      getRecurringFeeValue(blueprint, "priceIncludingTax"),
      {
        shouldValidate: blueprint.type === "RecurringFee"
      }
    );
    setValue("startDate", getRecurringFeeValue(blueprint, "startDate"), {
      shouldValidate: blueprint.type === "RecurringFee"
    });
    setValue("fixedAmount", getEventBasedFeeValue(blueprint, "fixedAmount"), {
      shouldValidate: blueprint.type === "EventBasedFee"
    });
    setValue(
      "oneWeekRentPercentage",
      getEventBasedFeeValue(blueprint, "oneWeekRentPercentage"),
      {
        shouldValidate: blueprint.type === "EventBasedFee"
      }
    );
  };

  const validDate = (date: string): boolean => {
    if (frequency === FeeFrequency.Weekly) return true;
    if (expenseType !== "RecurringFee") return true;
    return moment(date).date() <= 28;
  };

  const minDate = moment().subtract(2, "months").format("YYYY-MM-DD");
  const maxDate = moment().add(1, "years").format("YYYY-MM-DD");

  const isCancelledRecurringFee =
    mode === "edit" && expenseType === "RecurringFee" && !hasNextOccurrence;

  return (
    <ConfirmModal
      title={title}
      onConfirm={submit}
      onCancel={onCancel}
      showFooterBorder={true}
      confirmLabel={mode === "add" ? "Add" : "Update"}
      borderRadius={12}
      loading={submitting}
      loadingButtonWidth={mode === "add" ? 62.61 : 84.11}
      visible={visible}
      headerAndContentContainerStyle={{ paddingBottom: 60 }}
    >
      <>
        {isCancelledRecurringFee && (
          <Alert type={"warning"}>
            {
              "This fee has already been cancelled so no future charges will be applied. To continue charging this fee, please add it as a new expense."
            }
          </Alert>
        )}

        {mode === "add" &&
          ["RecurringFee", "EventBasedFee"].includes(expenseType) && (
            <Controller
              name={"feeBlueprint"}
              control={form.control}
              defaultValue={null}
              render={({ value, onChange, ref }): ReactElement => {
                return (
                  <InputContainer
                    ref={ref}
                    style={{
                      marginTop: isCancelledRecurringFee ? 24 : 0
                    }}
                  >
                    <FormField
                      label={"Fee template"}
                      testID={"FeeBlueprintInputFormField"}
                      error={
                        (errors.feeBlueprint as { message: string } | undefined)
                          ?.message
                      }
                    >
                      <SelectInput
                        inputRef={ref}
                        value={
                          value &&
                          (feeBlueprints?.find(
                            (opt) => opt.value === value.value
                          ) ??
                            value)
                        }
                        onChange={(value) => {
                          onChange(value);
                          setExpenseType(value.type);
                          setBlueprintDefaults(value);
                        }}
                        placeholder={"Select fee template"}
                        options={feeBlueprints}
                        useTextInputHeight
                      />
                    </FormField>
                  </InputContainer>
                );
              }}
              rules={{
                validate: (option): boolean | string =>
                  (!isEmpty(option?.label) && !isEmpty(option?.value)) ||
                  "Select fee template"
              }}
            />
          )}

        {["RecurringFee", "OneOffFee"].includes(expenseType) && (
          <Controller
            control={form.control}
            name={"totalAmount"}
            defaultValue={defaultTotalAmount}
            render={({ value, onChange, onBlur, ref }) => (
              <InputContainer
                ref={ref}
                style={{
                  display: feeBlueprintNotSelected ? "none" : undefined
                }}
              >
                <FormField
                  label={"Price (incl. GST)"}
                  helperText={
                    value
                      ? `GST Included (${Money.from(value)
                          .divide(11)
                          .format()})`
                      : undefined
                  }
                  labelRight={resetPriceButton}
                  error={(form.errors.totalAmount as any)?.message}
                >
                  <MoneyInput
                    {...{
                      value,
                      onChange,
                      onBlur,
                      disabled: totalAmountFieldDisabled
                    }}
                    endAdornment={
                      frequency ? (
                        <Text.BodyL>{frequency}</Text.BodyL>
                      ) : undefined
                    }
                    withTrailingZeros
                  />
                </FormField>
              </InputContainer>
            )}
            rules={{
              required: "Price is required.",
              validate: (totalAmount): boolean | string =>
                totalAmount.cents < 0
                  ? `Amount can't be less than ${Money.zero().format()}.`
                  : totalAmount.cents > feeMaxMoney.cents
                  ? `Amount can't be more than ${feeMaxMoney.format()}.`
                  : true
            }}
          />
        )}

        {expenseType === "ManagementFeeSchedule" && (
          <Controller
            control={form.control}
            name={"percentage"}
            render={({ value, onChange, onBlur, ref }) => (
              <InputContainer ref={ref}>
                <PercentInputFormField
                  {...{ value, onChange, onBlur }}
                  label={"Price (incl. GST)"}
                  error={(form.errors.percentage as any)?.message}
                  endAdornment={<Text.BodyL>{"% of rent"}</Text.BodyL>}
                />
              </InputContainer>
            )}
            rules={{
              required: "Rate is required.",
              validate: (percentage): boolean | string =>
                percentage < 0
                  ? `Rate can't be less than 0%.`
                  : percentage > 1
                  ? `Rate can't be more than 100%.`
                  : true
            }}
          />
        )}

        {mode === "add" && expenseType === "RecurringFee" && (
          <Controller
            control={form.control}
            name={"startDate"}
            defaultValue={null}
            render={({ value, onChange, onBlur }) => (
              <InputContainer
                style={{
                  display:
                    feeBlueprint?.type === FeeBlueprintType.RecurringFee
                      ? undefined
                      : "none"
                }}
              >
                <DateInputFormField
                  {...{ value, onChange, onBlur }}
                  label={"Start Date"}
                  placeholder={"Start Date"}
                  autoCorrect={false}
                  autoCompleteType={"off"}
                  minDate={minDate}
                  maxDate={maxDate}
                  webDateEnabled={validDate}
                  error={errors.startDate?.message}
                  testID={"StartDateInputFormField"}
                />
              </InputContainer>
            )}
            rules={{
              required:
                feeBlueprint?.type === FeeBlueprintType.RecurringFee
                  ? "Enter start date"
                  : false
            }}
          />
        )}

        {["EventBasedFee"].includes(expenseType) && (
          <>
            <Controller
              control={form.control}
              defaultValue={
                initialValues?.fixedAmount ??
                getEventBasedFeeValue(feeBlueprint, "fixedAmount") ??
                null
              }
              name={"fixedAmount"}
              render={({ value, onChange, onBlur, ref }) => (
                <InputContainer
                  ref={ref}
                  style={{
                    display: !getEventBasedFeeValue(feeBlueprint, "fixedAmount")
                      ? "none"
                      : undefined
                  }}
                >
                  <FormField
                    label={"Price"}
                    labelRight={resetPriceButton}
                    error={
                      (errors.fixedAmount as { message?: string } | undefined)
                        ?.message
                    }
                  >
                    <MoneyInput
                      {...{
                        value,

                        onBlur
                      }}
                      onChange={(money) =>
                        onChange(money ? Money.from(money) : null)
                      }
                      allowNull
                      withTrailingZeros
                    />
                  </FormField>
                </InputContainer>
              )}
              rules={{
                required:
                  getEventBasedFeeValue(feeBlueprint, "fixedAmount") !=
                  undefined
                    ? "Price is required."
                    : false,
                validate: (fixedAmount: Money | null): boolean | string =>
                  fixedAmount != undefined
                    ? fixedAmount.cents < 0
                      ? `Price can't be less than ${Money.zero().format()}.`
                      : fixedAmount.cents > feeMaxMoney.cents
                      ? `Price can't be more than ${feeMaxMoney.format()}.`
                      : true
                    : true
              }}
            />
            <Controller
              control={form.control}
              defaultValue={
                initialValues?.oneWeekRentPercentage ??
                getEventBasedFeeValue(feeBlueprint, "oneWeekRentPercentage") ??
                null
              }
              name={"oneWeekRentPercentage"}
              render={({ value, onChange, onBlur, ref }) => (
                <InputContainer
                  ref={ref}
                  style={{
                    display:
                      getEventBasedFeeValue(
                        feeBlueprint,
                        "oneWeekRentPercentage"
                      ) == undefined
                        ? "none"
                        : undefined
                  }}
                >
                  <FormField
                    label={"Price"}
                    labelRight={resetPriceButton}
                    error={errors.oneWeekRentPercentage?.message}
                  >
                    <PercentInput
                      {...{
                        value,
                        onChange,
                        onBlur
                      }}
                      format={"decimal"}
                      decimalPlaces={4}
                      endAdornment={"weeks of rent"}
                    />
                  </FormField>
                </InputContainer>
              )}
              rules={{
                required:
                  getEventBasedFeeValue(
                    feeBlueprint,
                    "oneWeekRentPercentage"
                  ) != undefined
                    ? "Price is required."
                    : false,
                validate: (
                  oneWeekRentPercentage: number | null
                ): boolean | string =>
                  oneWeekRentPercentage != undefined
                    ? oneWeekRentPercentage < 0
                      ? "Price can't be less than 0%."
                      : true
                    : true
              }}
            />
            <Controller
              name={"includesGst"}
              defaultValue={
                initialValues?.includesGst ??
                getEventBasedFeeValue(feeBlueprint, "includesGst") ??
                true
              }
              control={form.control}
              render={({ onChange, value }) => (
                <CheckboxInput
                  testID={"IncludeGstCheckboxInput"}
                  label={"Includes GST"}
                  style={{
                    marginTop: 16,
                    display: !feeBlueprint ? "none" : undefined
                  }}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {getEventBasedFeeValue(feeBlueprint, "fixedAmount") && (
              <GstBox {...{ includesGst, fixedAmount }} />
            )}
          </>
        )}

        {chargeDescription && (
          <Alert type={"info"} style={{ marginTop: 16 }}>
            {chargeDescription}
          </Alert>
        )}

        {["RecurringFee"].includes(expenseType) && <ModalSeparator />}

        {["RecurringFee", "OneOffFee", "EventBasedFee"].includes(
          expenseType
        ) && (
          <Controller
            control={form.control}
            name={"description"}
            defaultValue={null}
            render={({ value, onChange, onBlur, ref }) => (
              <InputContainer
                ref={ref}
                style={{
                  marginTop: expenseType === "RecurringFee" ? 0 : 24
                }}
              >
                <TextInputFormField
                  {...{ value, onChange, onBlur }}
                  label={"Enter a description for the payer (optional)"}
                  error={form.errors.description?.message}
                  placeholder={"Description"}
                  softCharacterLimit={{
                    limit: MAX_DESCRIPTION_LENGTH,
                    enforcementLevel: "error"
                  }}
                  style={{
                    display: feeBlueprintNotSelected ? "none" : undefined
                  }}
                />
              </InputContainer>
            )}
            rules={{
              validate: validateMaxLength(MAX_DESCRIPTION_LENGTH)
            }}
          />
        )}
      </>
    </ConfirmModal>
  );
}

ExpenseModalForm.Error = function Error({
  title,
  onCancel,
  retry,
  visible
}: Pick<EditExpenseModalFormProps, "title" | "onCancel" | "visible"> & {
  retry: () => void;
}) {
  return (
    <ConfirmModal
      title={title}
      onCancel={onCancel}
      showFooterBorder={true}
      confirmLabel={"Update Fee"}
      borderRadius={12}
      visible={visible}
      disabled
    >
      <ErrorAlertScreen
        variant={"sidebar"}
        title={"There was a problem loading the expense"}
        onRetry={retry}
        style={{ marginVertical: 49 }}
      />
    </ConfirmModal>
  );
};

function getRecurringFeeValue<
  T extends RecurringFeeBlueprintOption | Record<string, any>,
  K extends keyof RecurringFeeBlueprintOption
>(
  feeBlueprint: T | undefined,
  key: K
): RecurringFeeBlueprintOption[K] | undefined {
  return (
    (feeBlueprint && key in feeBlueprint && feeBlueprint[key]) || undefined
  );
}

function getEventBasedFeeValue<
  T extends AgencyFeeBlueprintOption | Record<string, any>,
  K extends keyof AgencyFeeBlueprintOption
>(
  feeBlueprint: T | undefined,
  key: K
): AgencyFeeBlueprintOption[K] | undefined {
  return (
    (feeBlueprint && key in feeBlueprint && feeBlueprint[key]) || undefined
  );
}

const ModalSeparator = styled(Separator)`
  margin-top: 40;
  margin-bottom: 32;
  margin-left: 0;
  margin-right: -20;
`;

const InputContainer = styled(View)`
  margin-top: 24;
`;
