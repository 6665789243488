import { CompanyListItemProps } from "./CompanyListItem";
import { LegalEntityListProps } from "./LegalEntityList";
import { PersonListItemProps } from "./PersonListItem";

export type GQLPersonOrCompany = { id: string } & (
  | ({
      __typename: "Person";
      user?: {
        onboardingCompletedAt?: string | null;
      } | null;
    } & Omit<PersonListItemProps["person"], "verified">)
  | ({
      __typename: "Company";
    } & CompanyListItemProps["company"])
);

export function setVerifiedOnLegalEntity(
  entity: GQLPersonOrCompany
): LegalEntityListProps["legalEntities"][0] {
  return entity.__typename === "Company"
    ? entity
    : { ...entity, verified: !!entity.user?.onboardingCompletedAt };
}
