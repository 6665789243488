import { AiloRN } from "@ailo/ailorn";
import { isPresent } from "ts-is-present";
import { PlatformFeatureId, Maybe } from "local/graphql";
import React, { ReactElement, useMemo } from "react";
import { MessageContactsIconButton } from "local/tabs/properties/components/PropertyScreen/components/PropertyContent/components/PropertyContentCard";
import { useHasFeature } from "@ailo/domains";
import { isEmpty } from "lodash";

interface Props {
  owners: {
    contact?: Maybe<{ ailorn: string }>;
  }[];
}

/**
 * Starts a chat with all owners. Each owner objects needs to have a contact ailorn.
 */
export function ManagementPortfolioOwnersChatButton({
  owners
}: Props): ReactElement | null {
  const hasChatFeature = useHasFeature(PlatformFeatureId.Chat);

  const ownersContactAilorns = useMemo(
    () =>
      owners
        .map(({ contact }) =>
          contact
            ? AiloRN.from(contact.ailorn, { expected: "contact:contact" })
            : null
        )
        .filter(isPresent),
    [owners]
  );

  if (!hasChatFeature || isEmpty(ownersContactAilorns)) {
    return null;
  }

  return (
    <MessageContactsIconButton
      participantContactAilorns={ownersContactAilorns}
    />
  );
}
