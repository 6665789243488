import { CloseIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

interface CloseButtonProps {
  onDismiss: () => void;
  style?: StyleProp<ViewStyle>;
}

export function CloseButton({
  onDismiss,
  style
}: CloseButtonProps): ReactElement {
  return (
    <View style={style}>
      <Button.Secondary type={"small"} square onPress={onDismiss}>
        <CloseIcon height={20} width={20} />
      </Button.Secondary>
    </View>
  );
}
