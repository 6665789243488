import React from "react";
import styled from "styled-components/native";
import { useCurrentUser } from "@ailo/domains";
import { Colors, Text } from "@ailo/primitives";
import { useCurrentAgencyOrg } from "local/common";
import { Button, PersonAvatar } from "@ailo/ui";
import { View } from "react-native";
import { formatPersonName } from "@ailo/domain-helpers";

interface Props {
  onPress: () => void;
  active?: boolean;
  maxTextWidth?: number;
}

export function CurrentUserDetailsButton({
  onPress,
  active,
  maxTextWidth = 240
}: Props): React.ReactElement {
  const { person } = useCurrentUser();
  const { name: organisationName } = useCurrentAgencyOrg();
  const fullName = formatPersonName(person);

  return (
    <Button
      variant={"secondary"}
      type={"medium"}
      style={{
        paddingRight: 4,
        paddingLeft: 24,
        paddingVertical: 0,
        height: 40
      }}
      onPress={onPress}
      active={active}
    >
      <LeftContainer>
        <Text.BodyM
          weight={"medium"}
          numberOfLines={1}
          style={{ maxWidth: maxTextWidth, lineHeight: 16 }}
        >
          {fullName}
        </Text.BodyM>
        <Text.BodyXS
          numberOfLines={1}
          style={{ maxWidth: maxTextWidth, lineHeight: 16 }}
          color={Colors.TEXT.DARK.SECONDARY}
        >
          {organisationName}
        </Text.BodyXS>
      </LeftContainer>
      <PersonAvatar
        size={32}
        person={person}
        initialsColor={Colors.TEXT.LIGHT.PRIMARY}
      />
    </Button>
  );
}

const LeftContainer = styled(View)`
  margin-right: 8px;
  align-items: flex-end;
`;
