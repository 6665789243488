import React, { ReactElement } from "react";
import { CloseIcon } from "@ailo/primitives";
import { View } from "react-native";
import { Button } from "@ailo/ui";
import styled from "styled-components/native";
import { MigratingManagementAddress } from "./MigratingManagementAddress";
import { MigratingManagementSidebarFragment } from "local/graphql";

interface Props {
  migratingManagement: MigratingManagementSidebarFragment;
  closeSidebar: () => void;
}

interface LoadingProps {
  closeSidebar: () => void;
}

export function MigratingManagementSidebarHeader({
  migratingManagement,
  closeSidebar
}: Props): ReactElement {
  return (
    <HeaderContainer>
      <MigratingManagementAddress migratingManagement={migratingManagement} />
      <Button.Secondary type={"small"} square onPress={closeSidebar}>
        <CloseIcon width={20} height={20} />
      </Button.Secondary>
    </HeaderContainer>
  );
}

function Loading({ closeSidebar }: LoadingProps): ReactElement {
  return (
    <HeaderContainer>
      <MigratingManagementAddress.Loading />
      <Button.Secondary type={"small"} square onPress={closeSidebar}>
        <CloseIcon width={20} height={20} />
      </Button.Secondary>
    </HeaderContainer>
  );
}

function Error({ closeSidebar }: LoadingProps): ReactElement {
  return (
    <HeaderContainer>
      <View />
      <Button.Secondary type={"small"} square onPress={closeSidebar}>
        <CloseIcon width={20} height={20} />
      </Button.Secondary>
    </HeaderContainer>
  );
}

MigratingManagementSidebarHeader.Loading = Loading;
MigratingManagementSidebarHeader.Error = Error;

const HeaderContainer = styled(View)`
  display: grid;
  grid-template-columns: auto min-content;
  align-items: start;
`;
