import { ClaimTenancyData } from "local/domain/bond";
import { useCallback, useState } from "react";
import { StepRenderFunction } from "./StepRenderFunction";
import { createSteps } from "./createSteps";
import { useOnStepChange } from "./useOnStepChange";
import { CLAIM_LIABILITIES_STEP, Step } from "./stepNames";
import { useGoToStep } from "./useGoToStep";
import { ClaimBondFormConfiguration } from "../ClaimBondFormConfiguration";

type ReturnValues = {
  steps: StepRenderFunction[];
  onStepChange: (newStep: Step) => void;
  currentStep: Step;
  goToStep: (step: Step) => void;
  submitButtonDisabled: boolean;
};

export function useFormSteps({
  data,
  formConfiguration
}: {
  data: ClaimTenancyData;
  onClose: () => void;
  formConfiguration: ClaimBondFormConfiguration;
}): ReturnValues {
  const [currentStep, setCurrentStep] = useState<Step>(CLAIM_LIABILITIES_STEP);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const goToStep = useGoToStep(setSubmitButtonDisabled, setCurrentStep);

  const onEditPress = useCallback(() => {
    goToStep(CLAIM_LIABILITIES_STEP);
  }, [goToStep]);

  const enableSubmitButton = useCallback(() => {
    setSubmitButtonDisabled(false);
  }, [setSubmitButtonDisabled]);

  const onStepChange = useOnStepChange(
    data,
    currentStep,
    goToStep,
    setSubmitButtonDisabled,
    formConfiguration
  );

  return {
    steps: createSteps(
      data,
      formConfiguration,
      onEditPress,
      enableSubmitButton
    ),
    currentStep,
    goToStep,
    onStepChange,
    submitButtonDisabled
  };
}
