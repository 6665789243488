import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { useCallback } from "react";
import {
  UpdateTenancyAgreementMutation,
  useUpdateTenancyAgreementMutation
} from "local/graphql";

export function useUpdateTenancyAgreement({
  onCompleted,
  onError
}: {
  onCompleted: () => void;
  onError: () => void;
}): [
  ({
    agreementId,
    startDate,
    fixedTermEndDate
  }: {
    agreementId: string;
    startDate: string;
    fixedTermEndDate?: string | null;
  }) => Promise<void>,
  MutationResult<UpdateTenancyAgreementMutation>
] {
  const [updateTenancyAgreementMutation, result] =
    useUpdateTenancyAgreementMutation();

  const updateTenancyAgreement = useCallback(
    async ({ agreementId, startDate, fixedTermEndDate }): Promise<void> => {
      const mutationInput = {
        id: agreementId,
        startDate,
        fixedTermEndDate: fixedTermEndDate || null
      };

      try {
        const result = await updateTenancyAgreementMutation({
          variables: {
            input: mutationInput
          }
        });
        throwIfMutationFailed(result, {
          dataKey: "updateTenancyAgreement"
        });
        onCompleted?.();
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
      }
    },
    [onCompleted, onError, updateTenancyAgreementMutation]
  );

  return [updateTenancyAgreement, result];
}
