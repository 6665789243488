import { AiloRN } from "@ailo/ailorn";
import { DocumentOutline, DownloadIcon, Text } from "@ailo/primitives";
import { useAnalytics } from "@ailo/services";
import {
  Button,
  DropdownMenu,
  ListItem,
  ListItemStyle,
  LocalDate
} from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import React, { ReactElement, useCallback, useState } from "react";
import { Platform } from "react-native";
import { CsvDownloadModal } from "./CsvDownloadModal";
import { Statement } from "./TransferStatementsList";

interface StatementListItemProps {
  statement: Statement;
  subject: AiloRN;
  style?: ListItemStyle;
}

export function StatementListItem({
  statement,
  subject,
  style
}: StatementListItemProps): ReactElement {
  const analytics = useAnalytics();
  const currentOrg = useCurrentAgencyOrg();

  const [modalActive, setModalActive] = useState(false);
  const toggleModal = (): void => {
    setModalActive(!modalActive);
  };

  const onPdfDownload = useCallback((): void => {
    Platform.OS === "web" &&
      statement.file?.url &&
      window.open(statement.file.url, "_blank");

    statement.file &&
      analytics.track("File Downloaded", {
        fileType: statement.file.contentType,
        category: "Agency Transfer Summary",
        fileId: statement.file.ailorn,
        organisationId: currentOrg.ailoRN.toString()
      });
  }, [analytics, currentOrg.ailoRN, statement.file]);

  const onCsvDownload = useCallback((): void => {
    setModalActive(true);
  }, []);

  return (
    <>
      <ListItem
        testID={"StatementListItem"}
        header={LocalDate.from(statement.rangeEndDate).format("D MMMM YYYY")}
        rightComponent={
          <DropdownMenu
            menuWidth={350}
            options={[
              {
                icon: <DocumentOutline />,
                onSelect: onPdfDownload,
                label: "Download transfer summary (.PDF)"
              },
              {
                icon: <DocumentOutline />,
                onSelect: onCsvDownload,
                label: "Download transaction list (.CSV)",
                disabled: !statement.statementProgress?.rangeEndBusinessTxId
              }
            ]}
            renderToggle={({ toggle, open }) => (
              <Button
                variant={"text"}
                leftIcon={DownloadIcon}
                onPress={toggle}
                active={open}
                style={{ marginLeft: 12 }}
              >
                {"Download"}
              </Button>
            )}
          />
        }
        style={style}
      />
      {modalActive && (
        <CsvDownloadModal
          toggleModal={toggleModal}
          rangeEndDate={statement.rangeEndDate}
          statementQueryVariables={{
            rangeStartBusinessTxId:
              statement.statementProgress?.rangeStartBusinessTxId ?? undefined,
            rangeEndBusinessTxId:
              statement.statementProgress?.rangeEndBusinessTxId ?? "",
            walletOwnerReference: subject.toString()
          }}
        />
      )}
    </>
  );
}

StatementListItem.Loading = function Loading({
  color,
  style
}: {
  color?: string;
  style?: ListItemStyle;
}): ReactElement {
  return (
    <ListItem
      header={<Text.BodyM.Loading width={120} color={color} />}
      style={style}
    />
  );
};
