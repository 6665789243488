import { EditIcon, RightChevronIcon } from "@ailo/primitives";
import { usePressableNavigation } from "@ailo/services";
import {
  IconButton,
  Tooltip,
  useModalVisibility,
  WhitePressable
} from "@ailo/ui";
import { sumMoney } from "local/tabs/trustaccounting/reconciliation/utils";
import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { adjustmentsLinkTo } from "../components";
import {
  BalanceCard,
  BalanceCardLoading,
  BalanceHeaderRow,
  BalanceRow,
  BalanceTotalRowSuccess,
  BalanceTotalRowWarning
} from "./BalanceCard";
import { EditBankBalanceModal } from "./EditBankBalanceModal";
import { ReconciliationPeriod } from "./types";

interface BankBalanceCardProps {
  period: ReconciliationPeriod;
}

const BANK_BALANCE_CARD_TITLE = "Bank Account";

export function BankBalanceCard({
  period
}: BankBalanceCardProps): ReactElement {
  const { amount, date } = period.bankBalance ?? {};
  const description = date
    ? `Bank statement balance as at ${date.format("DD MMMM YYYY")}`
    : "No bank statement balance provided";

  const modalVisibility = useModalVisibility();
  const pressableAdjustmentProps = usePressableNavigation({
    link: { to: adjustmentsLinkTo(period) }
  });

  const BalanceTotalRow = period.isReconciling
    ? BalanceTotalRowSuccess
    : BalanceTotalRowWarning;

  const totalBalance = sumMoney(amount, period.adjustmentSum);

  return (
    <>
      {modalVisibility.isVisible && (
        <EditBankBalanceModal
          period={period}
          onUpdate={modalVisibility.hide}
          onCancel={modalVisibility.hide}
        />
      )}
      <BalanceCard title={BANK_BALANCE_CARD_TITLE}>
        <BalanceHeaderRow
          amount={amount}
          icon={
            !period.locked ? (
              <Tooltip tooltipContent={"Edit balance"}>
                <IconButton
                  Icon={EditIcon}
                  onPress={modalVisibility.show}
                  disabled={period.locked}
                />
              </Tooltip>
            ) : undefined
          }
        >
          {description}
        </BalanceHeaderRow>
        {period.locked ? (
          <AdjustmentsRow adjustmentSum={period.adjustmentSum} />
        ) : (
          <WhitePressable {...pressableAdjustmentProps}>
            <AdjustmentsRow
              adjustmentSum={period.adjustmentSum}
              icon={<ClickableIcon />}
            />
          </WhitePressable>
        )}
        <BalanceTotalRow
          amount={totalBalance}
          icon={period.locked ? undefined : <></>}
        >
          {"Reconciled bank statement balance"}
        </BalanceTotalRow>
      </BalanceCard>
    </>
  );
}

function AdjustmentsRow({
  adjustmentSum,
  icon
}: {
  adjustmentSum: ReconciliationPeriod["adjustmentSum"];
  icon?: ReactElement;
}): ReactElement {
  return (
    <BalanceRow amount={adjustmentSum} icon={icon}>
      {"Adjustments"}
    </BalanceRow>
  );
}

const ClickableIcon = styled(RightChevronIcon).attrs({
  alignCenter: true,
  width: 20,
  height: 20
})``;

export const BankBalanceCardLoading = styled(BalanceCardLoading).attrs({
  title: BANK_BALANCE_CARD_TITLE,
  rowsToLoad: 3
})``;
