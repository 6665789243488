import { useCurrentAgencyOrg } from "local/common";
import {
  ManagementFilterTypes,
  ManagementSortField,
  SortDirection,
  useGetPagedLostManagementsLazyQuery
} from "local/graphql";
import { useCallback } from "react";
import { PROPERTY_LIST_PAGE_SIZE, RequestCursor } from "./usePaginationRequest";
import { PropertyListQueryData } from "../BasePropertyList";
import { useGetPropertyListQueryReturn } from "./useGetPropertyListQueryReturn";
import { GridSortOrder } from "@ailo/ui";
import { LostPropertyListColumns } from "../LostPropertyList";
import { getTeamsManagementFilterParams } from "./getTeamsManagementFilterParams";
import { PropertyListSelectedTeams } from "./usePropertyListSelectedTeams";

export function useLostPropertyListQuery(
  initialSortOrder: GridSortOrder
): PropertyListQueryData {
  const currentOrg = useCurrentAgencyOrg();

  const [request, { data, loading, called }] =
    useGetPagedLostManagementsLazyQuery({
      fetchPolicy: "cache-and-network"
    });

  const sendRequest = useCallback(
    (
      selectedTeams: PropertyListSelectedTeams,
      cursor: RequestCursor = {
        cursor: null,
        pageSize: PROPERTY_LIST_PAGE_SIZE,
        paginateBackward: false,
        sort: sortStateToApiParam(initialSortOrder)
      }
    ) => {
      request({
        variables: {
          organisationId: currentOrg.ailoRN,
          filters: [
            { name: ManagementFilterTypes.Terminated },
            ...getTeamsManagementFilterParams(selectedTeams)
          ],
          pageCursor: cursor
        }
      });
    },
    [currentOrg.ailoRN, request, initialSortOrder]
  );

  const pageInfo = data?.managementsForOrg?.pageInfo;
  const managements = data?.managementsForOrg?.items;

  return useGetPropertyListQueryReturn({
    sendRequest,
    pageInfo,
    managements,
    loading: loading && !data,
    called,
    sortStateToApiParam
  });
}

function sortStateToApiParam({ columnKey, direction }: GridSortOrder): {
  field: ManagementSortField;
  direction: SortDirection;
} {
  const directionForApi =
    direction === "ASC" ? SortDirection.Asc : SortDirection.Desc;

  return {
    field: getApiSortField(columnKey),
    direction: directionForApi
  };
}

function getApiSortField(columnKey: string): ManagementSortField {
  if (columnKey === LostPropertyListColumns.Properties)
    return ManagementSortField.Address;

  if (columnKey === LostPropertyListColumns.EndDate)
    return ManagementSortField.EndDate;

  throw new Error(`Cannot sort Lost property list by column ${columnKey}`);
}
