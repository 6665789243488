import { Colors, LeftChevronIcon, Text } from "@ailo/primitives";
import { useOnFocus, withScreenComponent } from "@ailo/services";
import { WebView } from "@ailo/ui";
import { Screens, useNavigation, useRoute } from "local/common";
import React, { useCallback } from "react";
import { Pressable, View } from "react-native";

interface JWTPayload {
  exp: number;
  iat: number;
  resources: object;
  params: object;
}

export const ReportScreen = withScreenComponent(
  (): React.ReactElement | null => {
    const navigation = useNavigation<Screens.ReportEmbed>();
    const route = useRoute<Screens.ReportEmbed>();

    const navToReportList = useCallback((): void => {
      navigation.navigate(Screens.ReportList);
    }, [navigation]);

    const { metabaseEmbedToken } = route.params!;
    const token = decodeURIComponent(metabaseEmbedToken || "");
    //prevent encode/decode issues
    route.params!.metabaseEmbedToken = token;

    localStorage.setItem("report_id", token);

    const POLLING_INTERVALS = 1000;

    useOnFocus(() => {
      const getJWTPayload = (token: string): JWTPayload =>
        JSON.parse(atob(token.split(".")[1]));

      const isValidToken = (): boolean => {
        const { exp } = getJWTPayload(token);

        return Date.now() < exp * 1000;
      };
      const interval = setInterval(() => {
        !isValidToken() ? navToReportList() : null;
      }, POLLING_INTERVALS);
      return () => {
        clearInterval(interval);
      };
    });

    return (
      <View
        style={{ marginVertical: 40, marginHorizontal: 60, height: "100%" }}
      >
        <Pressable onPress={navToReportList}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 16
            }}
          >
            <LeftChevronIcon />
            <Text.DisplayS color={Colors.AILO_RED}>{"Reporting"}</Text.DisplayS>
          </View>
        </Pressable>
        <WebView uri={"https://reporting.ailo.io/embed/dashboard/" + token} />
      </View>
    );
  }
);
