import { GridSortOrder } from "@ailo/ui";
import React from "react";
import {
  AddressCellWithNoPaymentWarning,
  DaysInArrearsFromOwingCell,
  PaidToDateFromOwingCell,
  RentCell
} from "../PropertyItemCell";
import {
  useArrearsPropertyListFromOwingQuery,
  usePropertyListReorder
} from "../hooks";
import { ComeBackSoonMessage } from "../ComeBackSoonMessage";
import { lowerCase, startCase } from "lodash";
import { BasePropertyList, BasePropertyListProps } from "../BasePropertyList";
import { createCellRenderer } from "../createCellRenderer";
import { ArrearsReportFromOwingDownloadButton } from "./ArrearsReportFromOwingDownloadButton";
import { ArrearsAmountFromOwingCell } from "../PropertyItemCell/ArrearsAmountFromOwingCell";

export function ArrearsPropertyListFromOwing({
  tabNavigation
}: {
  tabNavigation: React.ReactElement;
}): React.ReactElement {
  const queryData = useArrearsPropertyListFromOwingQuery(initialSortOrder);
  const { sortOrder, onSortOrderChange } = usePropertyListReorder(
    initialSortOrder,
    queryData.requestFromBeginning,
    analyticsInfo
  );

  return (
    <BasePropertyList
      tabNavigation={tabNavigation}
      queryData={queryData}
      errorMessage={"There was a problem loading Arrears"}
      emptyMessage={"No properties in arrears to show"}
      secondaryEmptyMessage={<ComeBackSoonMessage />}
      sortOrder={sortOrder}
      columns={columns}
      onSortOrderChange={onSortOrderChange}
      footerButton={
        <ArrearsReportFromOwingDownloadButton sortOrder={sortOrder} />
      }
    />
  );
}

export enum ArrearsPropertyListFromOwingColumns {
  Properties = "Properties",
  Rent = "Rent",
  ArrearsAmount = "Arrears amt.",
  PaidTo = "Paid to",
  ArrearsDays = "Arrears days"
}

const columns: BasePropertyListProps["columns"] = [
  {
    width: 3,
    renderCell: createCellRenderer(AddressCellWithNoPaymentWarning),
    renderLoadingCell: AddressCellWithNoPaymentWarning.Loading,
    name: ArrearsPropertyListFromOwingColumns.Properties,
    sortable: true
  },
  {
    width: 1,
    renderCell: createCellRenderer(RentCell),
    renderLoadingCell: RentCell.Loading,
    name: ArrearsPropertyListFromOwingColumns.Rent,
    sortable: false,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(ArrearsAmountFromOwingCell),
    renderLoadingCell: ArrearsAmountFromOwingCell.Loading,
    name: ArrearsPropertyListFromOwingColumns.ArrearsAmount,
    sortable: false,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(PaidToDateFromOwingCell),
    renderLoadingCell: PaidToDateFromOwingCell.Loading,
    name: ArrearsPropertyListFromOwingColumns.PaidTo,
    sortable: true,
    horizontalAlign: "right",
    verticalAlign: "middle"
  },
  {
    width: 1,
    renderCell: createCellRenderer(DaysInArrearsFromOwingCell),
    renderLoadingCell: DaysInArrearsFromOwingCell.Loading,
    name: ArrearsPropertyListFromOwingColumns.ArrearsDays,
    sortable: false,
    horizontalAlign: "right",
    verticalAlign: "middle"
  }
];

const initialSortOrder: GridSortOrder = {
  columnKey: ArrearsPropertyListFromOwingColumns.PaidTo,
  direction: "ASC"
};

function analyticsColName(columnKey: string): string {
  if (columnKey === ArrearsPropertyListFromOwingColumns.PaidTo)
    return "Arrears";
  if (columnKey === ArrearsPropertyListFromOwingColumns.Properties)
    return "Address";
  throw new Error(`Unexpected column to return analytics: ${columnKey}`);
}

function analyticsInfo(sortOrder: GridSortOrder): {
  eventName: string;
  orderBy: string;
} {
  return {
    eventName: "Arrears Property List Reordered",
    orderBy: `${analyticsColName(sortOrder.columnKey)} ${startCase(
      lowerCase(sortOrder.direction)
    )}`
  };
}
