import { RecoverableError } from "@ailo/services";
import {
  MonthYearObject,
  useCurrentAgencyOrg,
  useTimezone
} from "local/common";
import {
  TrustAccountLockStatus,
  useViewReconciliationReportPeriodQuery
} from "local/graphql";
import { useEffect, useMemo } from "react";
import { ReconciliationPeriod } from "./types";
import { parseReportPeriodFragment } from "../graphql/parseReportPeriodFragment";

export function useReconciliationViewScreenData(
  reportMonthYear?: MonthYearObject
): { loading: true } | ({ loading: false } & ReconciliationPeriod) {
  const timezone = useTimezone();
  const { defaultTrustAccount } =
    useCurrentAgencyOrg().defaultWalletLegalEntity ?? {};
  const { ailorn: trustAccountAilorn } = defaultTrustAccount ?? {};

  const variables =
    reportMonthYear && trustAccountAilorn
      ? { ...reportMonthYear, trustAccountAilorn }
      : undefined;

  const skip = !variables;

  const { data, loading, refetch, startPolling, stopPolling } =
    useViewReconciliationReportPeriodQuery({
      variables,
      skip
    });

  const reportPeriod = data?.reconciliationReportPeriodByDate;
  const isLocking =
    data?.getTrustAccountLock?.status === TrustAccountLockStatus.Unconfirmed;

  useEffect(() => {
    if (skip || !isLocking) {
      stopPolling();
    } else {
      startPolling(1000);
    }
  }, [isLocking, skip, startPolling, stopPolling]);

  const parsedData = useMemo<ReconciliationPeriod | undefined>(() => {
    if (!reportPeriod) return undefined;

    const fragmentData = parseReportPeriodFragment(reportPeriod);

    return {
      ...reportPeriod,
      ...fragmentData,
      timezone: timezone,
      monthNumber: reportPeriod.month,
      canAlterLock: reportPeriod.canAlterLock && !isLocking
    };
  }, [reportPeriod, timezone, isLocking]);

  if (skip) {
    return { loading: true };
  }

  if (!parsedData && loading) {
    return { loading: true };
  }

  if (!parsedData)
    throw new RecoverableError("Could not load reconciliation", refetch);

  return {
    loading: false,
    ...parsedData
  };
}
