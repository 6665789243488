import { DateInputFormField, TextInputFormField } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ActionFormData } from "../ActionFormData";

export function ActionDueDateField({
  onFieldUpdated
}: {
  onFieldUpdated(fieldName: keyof ActionFormData): void;
}): ReactElement {
  const { control, errors } = useFormContext<ActionFormData>();

  return (
    <Controller
      control={control}
      render={({ value, onBlur, onChange }) => (
        <DateInputFormField
          webPopoverHorizontalAlign={"start"}
          onBlur={onBlur}
          placeholder={"Select a date"}
          autoCorrect={false}
          autoCompleteType={"off"}
          value={value}
          label={"Due Date"}
          error={errors.dueDate?.message}
          onChange={(date): void => {
            onChange(date ?? null);
            onFieldUpdated("dueDate");
          }}
        />
      )}
      name={"dueDate"}
      defaultValue={null}
      rules={{
        required: "Please select a due date"
      }}
    />
  );
}

ActionDueDateField.Loading = function Loading() {
  return <TextInputFormField.Loading labelWidth={60} />;
};
