import { Button } from "@ailo/ui";
import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { FormFooterProps } from "./FormFooterProps";

export function ModalFormFooter({
  cancelButtonDisabled,
  isSubmitting,
  submitButtonDisabled,
  submitText,
  submitVisible,
  onCancel,
  onSubmit,
  canGoBack,
  onGoBack,
  style
}: FormFooterProps): ReactElement {
  return (
    <StyledButtonsContainer style={style}>
      <Button.Secondary
        disabled={isSubmitting || cancelButtonDisabled}
        onPress={onCancel}
        testID={"CancelButton"}
      >
        {"Cancel"}
      </Button.Secondary>

      <StyledRightButtonsContainer>
        {canGoBack && (
          <Button.Secondary type={"medium"} onPress={onGoBack}>
            {"Back"}
          </Button.Secondary>
        )}

        {submitVisible && (
          <Button.Primary
            style={{ marginLeft: 12 }}
            testID={"SubmitButton"}
            disabled={submitButtonDisabled}
            loading={isSubmitting}
            onPress={onSubmit}
          >
            {submitText}
          </Button.Primary>
        )}
      </StyledRightButtonsContainer>
    </StyledButtonsContainer>
  );
}

const StyledButtonsContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
`;

const StyledRightButtonsContainer = styled(View)`
  flex-direction: row;
`;
