import { FeeBlueprintType, FeeEventType } from "local/graphql";

export const taxCategoryDefaultValues: {
  [key: string]: {
    name: string;
    type: FeeBlueprintType;
    chargeType: "FixedAmount" | "OneWeekRentPercentage";
    event?: FeeEventType;
  };
} = {
  ADMINISTRATION_FEES: {
    name: "Administration Fee",
    type: FeeBlueprintType.RecurringFee,
    chargeType: "FixedAmount"
  },
  ADVERTISING_FEES: {
    name: "Advertising Fee",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount",
    event: FeeEventType.FirstRentSettled
  },
  ADVERTISING_SIGN: {
    name: "Advertising Sign",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  ENTRY_INSPECTION_FEES: {
    name: "Entry Inspection Fee",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  EXIT_INSPECTION_FEES: {
    name: "Exit Inspection Fee",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  PHOTOGRAPHY_FEES: {
    name: "Photography Fee",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  ANNUAL_FEES: {
    name: "Annual Fee",
    type: FeeBlueprintType.RecurringFee,
    chargeType: "FixedAmount"
  },
  INSPECTION_FEES: {
    name: "Inspection Fee",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  LEASE_RENEWAL_FEES: {
    name: "Lease Renewal Fee",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  LEASE_PREPARATION_FEES: {
    name: "Lease Preparation Fee",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  LEASE_BREAK_FEES: {
    name: "Lease Break Fee",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  LETTING_FEES: {
    name: "Letting Fee",
    type: FeeBlueprintType.EventBasedFee,
    chargeType: "OneWeekRentPercentage",
    event: FeeEventType.FirstRentSettled
  },
  MANAGEMENT_FEES: {
    name: "Management Fee",
    type: FeeBlueprintType.RecurringFee,
    chargeType: "FixedAmount"
  },
  SALES_ADVERTISING_FEES: {
    name: "Sales Advertising Fee",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  TRIBUNAL_FEES: {
    name: "Tribunal Fee",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  RENT_COLLECTION_FEES: {
    name: "Rent Collection Fee",
    type: FeeBlueprintType.RecurringFee,
    chargeType: "FixedAmount"
  },
  INVOICE_FEES: {
    name: "Invoice Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  PROJECT_MANAGEMENT_FEES: {
    name: "Project Management Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  INSURANCE_CLAIM_FEES: {
    name: "Insurance Claim Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  },
  TITLE_SEARCH_FEES: {
    name: "Title Search Fees",
    type: FeeBlueprintType.OneOffFee,
    chargeType: "FixedAmount"
  }
};
