import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { SFC, Colors, Text } from "@ailo/primitives";
import { formatDate } from "@ailo/services";
import { Money } from "@ailo/ui";

interface Props {
  dateColumn: {
    dateColumnTitle: string;
    dateValue: string;
  };
  moneyColumn?: {
    moneyColumnTitle: string;
    rentBalanceCents?: number;
    billBalanceCents?: number;
  };
  renderBannerImage: () => React.ReactNode;
}

export const TenancyBanner: SFC<Props> = ({
  style,
  dateColumn: { dateColumnTitle, dateValue },
  moneyColumn,
  renderBannerImage
}) => {
  return (
    <Row style={style}>
      {renderBannerImage()}
      <Column style={{ width: 140 }}>
        <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
          {dateColumnTitle}
        </Text.BodyS>
        <Text.BodyS style={{ marginTop: 4 }} weight={"book"}>
          {formatDate(dateValue)}
        </Text.BodyS>
      </Column>
      {moneyColumn ? (
        <Column style={{ paddingLeft: 12 }}>
          <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
            {moneyColumn.moneyColumnTitle}
          </Text.BodyS>
          <OutstandingBalanceText
            category={"Rent"}
            valueInCents={moneyColumn.rentBalanceCents}
          />
          <OutstandingBalanceText
            category={"Bills"}
            valueInCents={moneyColumn.billBalanceCents}
          />
        </Column>
      ) : null}
    </Row>
  );
};

const OutstandingBalanceText: React.FC<{
  category: string;
  valueInCents?: number;
}> = ({ category, valueInCents }) => {
  if (valueInCents == null) {
    return (
      <View style={{ flexDirection: "row", marginTop: 4 }}>
        <Text.BodyS weight={"book"}>{`${category}: `}</Text.BodyS>
        <Text.BodyS.Loading width={60} />
      </View>
    );
  }

  const amount = Money.fromCents(valueInCents);

  if (amount.isNegative) {
    const value = amount.abs().format();
    return (
      <View style={{ flexDirection: "row" }}>
        <Text.BodyS style={{ marginTop: 4 }} weight={"book"}>
          {`${category}: `}
        </Text.BodyS>
        <Text.BodyS
          color={Colors.STATUS.SUCCESS}
          style={{ marginTop: 4 }}
          weight={"book"}
        >
          {`${value} credit`}
        </Text.BodyS>
      </View>
    );
  }

  return (
    <Text.BodyS style={{ marginTop: 4 }} weight={"book"}>
      {`${category}: ${amount.format()}`}
    </Text.BodyS>
  );
};

const Row = styled(View)`
  padding: 12px 16px;
  border-radius: 8px;
  flex-direction: row;
`;

const Column = styled(View)`
  flex-direction: column;
`;
