import { ClaimTenancyData } from "local/domain/bond";
import React, { ReactElement } from "react";
import { MoneyInput } from "@ailo/ui";
import { Text } from "@ailo/primitives";
import { View } from "react-native";
import { FormControlledRentClaimInput } from "./FormControlledRentClaimInput";

export function RentClaimInput({
  data,
  style
}: {
  data: ClaimTenancyData;
  style?: any;
}): ReactElement {
  return (
    <View style={style}>
      <Text.BodyL weight={"medium"}>{"Settle rent"}</Text.BodyL>
      <View style={{ flexDirection: "row", marginTop: 14 }}>
        <View style={{ flex: 1 }}>
          <MoneyInput
            style={{ paddingRight: "6px" }}
            label={"Outstanding rent"}
            disabled
            value={data.rent.outstandingAmount}
          />
        </View>

        <View style={{ flex: 1 }}>
          <FormControlledRentClaimInput data={data} />
        </View>
      </View>
    </View>
  );
}
