import { Colors } from "@ailo/primitives";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { useInspectionPlanner } from "../../InspectionPlannerContext";
import { AppointmentTableHeader } from "./AppointmentTableHeader";
import { AppointmentTableRow } from "./AppointmentTableRow";

export function AppointmentTable({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement | null {
  const { selectedInspectionDay } = useInspectionPlanner();

  if (!selectedInspectionDay) {
    return null;
  }

  return (
    <Container style={style}>
      <AppointmentTableHeader />
      {selectedInspectionDay.appointments.map((appointment, index) => (
        <AppointmentTableRow
          appointment={appointment}
          position={index + 1}
          key={`AppointmentTableRow-${index}`}
        />
      ))}
    </Container>
  );
}

const Container = styled(View)`
  border-width: 1px;
  border-style: solid;
  border-color: ${Colors.GRAYSCALE.OUTLINE};
  border-radius: 8px;
`;
