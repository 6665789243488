import { Text } from "@ailo/primitives";
import { ConfirmModal } from "@ailo/ui";
import React, { ReactElement } from "react";

interface Props {
  onClose: () => void;
  failedProperties: number;
}

export function PropertyOnboardingListFailedPropertyActionModal({
  onClose,
  failedProperties
}: Props): ReactElement {
  const failedPropertiesPlurality =
    failedProperties === 1 ? "property" : "properties";

  return (
    <ConfirmModal
      title={"Something went wrong"}
      cancelable={false}
      confirmLabel={"OK"}
      onConfirm={onClose}
    >
      <Text.BodyM>
        {"We were unable to complete this action for"}
        <Text.BodyM weight={"medium"}>
          {` ${failedProperties} ${failedPropertiesPlurality}. `}
        </Text.BodyM>
        {"Ensure you have permission to manage these properties and try again.\n\n" +
          "Please contact Ailo support if the problem persists."}
      </Text.BodyM>
    </ConfirmModal>
  );
}
