import React, { ReactElement, useCallback } from "react";
import { Button, useToastContext } from "@ailo/ui";
import { useApproveMigratingManagement } from "../../hooks";
import { ApproveMigratingManagementMutation } from "local/graphql";
import styled from "styled-components/native";
import { View } from "react-native";
import { ExcludePropertyButton } from "./ExcludeProperty";

interface Props {
  migratingManagementId: string;
  closeSidebar: () => void;
}

export function HealthyMigratingManagementFooter({
  migratingManagementId,
  closeSidebar
}: Props): ReactElement {
  const toast = useToastContext();

  const onError = useCallback(() => {
    toast.show({
      type: "error",
      message: "Unable to approve property. Please try again"
    });
  }, [toast]);

  const onSuccess = useCallback(
    (data: ApproveMigratingManagementMutation) => {
      const successes = data.approveMigratingManagements?.successes ?? [];
      if (successes.length === 0) {
        toast.show({
          type: "error",
          message: "Unable to approve property. Please try again"
        });
        return;
      }

      closeSidebar();
      toast.show({
        type: "success",
        message: "Property marked as approved"
      });
    },
    [toast, closeSidebar]
  );

  const [approveMigratingManagement, { loading }] =
    useApproveMigratingManagement({
      migratingManagementId,
      onSuccess,
      onError
    });

  return (
    <ButtonContainer>
      <ExcludePropertyButton
        migratingManagementId={migratingManagementId}
        closeSidebar={closeSidebar}
      />

      <StyledPrimaryButton
        onPress={() => approveMigratingManagement()}
        loading={loading}
      >
        {"Approve Property"}
      </StyledPrimaryButton>
    </ButtonContainer>
  );
}

const ButtonContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const StyledPrimaryButton = styled(Button.Primary)`
  flex: 1;
  margin-left: 12px;
`;
