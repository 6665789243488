import { AiloRN } from "@ailo/ailorn";
import { formatAddress } from "@ailo/domain-helpers";
import { getTaxCategoryName } from "@ailo/domains";
import {
  MockedQueryResult,
  mockQueryResult,
  useMappedQueryResult,
  usePaginatedQuery
} from "@ailo/services";
import { ApolloError, FetchPolicy } from "@apollo/client";
import { useWalletEntriesForTransactionListQuery } from "local/graphql";
import { capitalize } from "lodash";
import { useMemo, useRef } from "react";
import {
  getWalletEntryTransactionStatus,
  TransactionStatus
} from "./getWalletEntryTransactionStatus";

export interface Transaction {
  id: string;
  category: { type: "default" | "property"; name: string };
  createdAt: string;
  propertyName?: string;
  status: TransactionStatus;
  amount: {
    cents: number;
  };
}

export interface TransactionsQuery {
  transactions: Transaction[];
}

type TransactionsQueryResult = MockedQueryResult<
  TransactionsQuery | undefined,
  {}
>;

const pageSize = 20;

export function useTransactionsQuery(
  walletOwnerAilorn: AiloRN | undefined,
  options?: {
    fetchPolicy?: FetchPolicy;
    notifyOnNetworkStatusChange?: boolean;
    skip?: boolean;
  }
): {
  result: TransactionsQueryResult;
  currentPage: number;
  pagesCount?: number;
  goToPreviousPage?(): void;
  goToNextPage?(): void;
} {
  const walletResult = usePaginatedQuery(
    useWalletEntriesForTransactionListQuery,
    "walletEntriesWithStatusByOwnerRef",
    {
      variables: walletOwnerAilorn
        ? {
            ownerId: walletOwnerAilorn.toString(),
            pageSize
          }
        : undefined,
      skip: options?.skip || !walletOwnerAilorn,
      ...options
    }
  );

  const mappedResult = useMappedQueryResult(
    walletResult,
    (walletEntriesData) => {
      if (!walletEntriesData.walletEntriesWithStatusByOwnerRef) {
        throw new ApolloError({
          errorMessage: "walletEntriesWithStatusByOwnerRef query returned null"
        });
      }

      return {
        transactions:
          walletEntriesData.walletEntriesWithStatusByOwnerRef.items.map(
            (walletEntry) => {
              const liabilityReference =
                walletEntry?.liability?.referencedEntity;
              const management =
                liabilityReference && "management" in liabilityReference
                  ? liabilityReference.management
                  : liabilityReference &&
                    "nullableManagement" in liabilityReference
                  ? liabilityReference.nullableManagement
                  : walletEntry.liability?.recurringFee?.management;
              const propertyName = management?.property?.address
                ? formatAddress(management.property.address, {
                    format: "street"
                  })
                : undefined;

              return {
                id: walletEntry.id,
                category: {
                  type:
                    walletEntry.liability?.category === "FEE" ||
                    (walletEntry.liability?.taxCategory &&
                      ["RENT", "MANAGEMENT_FEES"].includes(
                        walletEntry.liability?.taxCategory
                      ))
                      ? ("property" as "property")
                      : ("default" as "default"),
                  name: walletEntry.liability?.recurringFee
                    ? walletEntry.liability?.recurringFee.name
                    : walletEntry.liability?.taxCategory
                    ? getTaxCategoryName(walletEntry.liability?.taxCategory)
                    : walletEntry.liability?.category
                    ? capitalize(walletEntry.liability.category)
                    : walletEntry.amount.cents > 0
                    ? "Funds Deposited"
                    : "Funds Transferred"
                },
                createdAt: walletEntry.createdAt,
                propertyName,
                status: getWalletEntryTransactionStatus(walletEntry),
                amount: walletEntry.amount
              };
            }
          )
      };
    }
  );

  /**
   * Memoized data of the current page or the last page, whichever is fully loaded.
   * (If the current page hasn't loaded fully yet, then keep showing the last page.)
   */
  const lastFullyLoadedDataRef = useRef<TransactionsQuery | undefined>(
    mappedResult.data
  );
  if (!mappedResult.loading) {
    lastFullyLoadedDataRef.current = mappedResult.data;
  }
  const lastFullyLoadedData = lastFullyLoadedDataRef.current;

  const finalResult = useMemo<TransactionsQueryResult>(() => {
    if (!walletOwnerAilorn) {
      return mockQueryResult({
        data: {
          transactions: []
        }
      });
    }
    return { ...mappedResult, data: lastFullyLoadedData };
  }, [mappedResult, walletOwnerAilorn, lastFullyLoadedData]);

  return {
    result: finalResult,
    currentPage: walletResult.currentPage,
    pagesCount: walletResult.pagesCount,
    goToPreviousPage: walletResult.goToPreviousPage,
    goToNextPage: walletResult.goToNextPage
  };
}
