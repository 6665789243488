import { useCallback } from "react";
import { FileDetails, FilesStateFile } from "@ailo/domains";
import { useAnalytics } from "@ailo/services";
import { ProjectDetailsFragment } from "local/graphql";
import { startCase } from "lodash";

export type ProjectFileAnalytics = Pick<
  FilesStateFile,
  "fileName" | "fileSize"
>;

interface UseProjectAnalytics {
  onProjectStatusChanged: (project: ProjectDetailsFragment) => void;
  onProjectArchived: (project: ProjectDetailsFragment) => void;
  onFileOpened: (file: FileDetails) => void;
  onFileDownloaded: (file: FileDetails) => void;
  onFileUploadStarted: (file: ProjectFileAnalytics) => void;
  onFileUploaded: (file: { fileSize?: number; contentType?: string }) => void;
  onFileUploadFailed: (file?: ProjectFileAnalytics) => void;
  onFileRemoved: (file: {
    id: string;
    fileName: string;
    contentType: string;
  }) => void;
}

export function useProjectAnalytics(projectId: string): UseProjectAnalytics {
  const analytics = useAnalytics();

  const onProjectStatusChanged = useCallback(
    (project: ProjectDetailsFragment) => {
      analytics.track("Project Status Changed", {
        project_id: project.id,
        project_created_at: project.createdAt,
        project_status: project.status,
        project_type: startCase(project.type),
        total_actions: project.actions?.pageInfo?.total || 0
      });
    },
    [analytics]
  );

  const onProjectArchived = useCallback(
    (project: ProjectDetailsFragment) => {
      analytics.track("Project Archived", {
        project_id: project.id,
        project_status: project.status,
        project_created_at: project.createdAt,
        project_type: startCase(project.type)
      });
    },
    [analytics]
  );

  const onFileOpened = useCallback(
    (file: FileDetails) => {
      analytics.trackScreenVisited("Project File", {
        project_id: projectId,
        file_id: file.id,
        file_type: file.contentType
      });
    },
    [analytics, projectId]
  );

  const onFileDownloaded = useCallback(
    (file: FileDetails) => {
      analytics.track("Project File Downloaded", {
        project_id: projectId,
        file_id: file.id,
        file_type: file.contentType
      });
    },
    [analytics, projectId]
  );

  const onFileUploadStarted = useCallback(
    (file: ProjectFileAnalytics) => {
      analytics.track("Project File Upload Started", {
        project_id: projectId,
        file_size: file.fileSize,
        file_type: file.fileName.split(".").pop()
      });
    },
    [analytics, projectId]
  );

  const onFileUploaded = useCallback(
    (file: { fileSize?: number; contentType?: string }) => {
      analytics.track("Project File Upload Successful", {
        project_id: projectId,
        file_size: file.fileSize,
        file_type: file.contentType
      });
    },
    [analytics, projectId]
  );

  const onFileRemoved = useCallback(
    (file: { id: string; fileName: string; contentType: string }) => {
      analytics.track("Project File Deleted", {
        project_id: projectId,
        file_id: file.id,
        file_name: file.fileName,
        file_type: file.contentType
      });
    },
    [analytics, projectId]
  );

  const onFileUploadFailed = useCallback(
    (file?: ProjectFileAnalytics) => {
      file
        ? analytics.track("Project File Upload Failed", {
            project_id: projectId,
            file_size: file.fileSize,
            file_type: file.fileName.split(".").pop()
          })
        : analytics.track("Project File Upload Failed", {
            project_id: projectId
          });
    },
    [analytics, projectId]
  );

  return {
    onProjectStatusChanged,
    onProjectArchived,
    onFileOpened,
    onFileDownloaded,
    onFileUploadStarted,
    onFileUploaded,
    onFileUploadFailed,
    onFileRemoved
  };
}
