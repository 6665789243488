import React, { ReactElement, useState } from "react";
import { View } from "react-native";
import {
  AddElementButton,
  SectionHeader
} from "local/tabs/properties/components/PropertyScreen/components/common";
import { Text } from "@ailo/primitives";
import { CentrepaySetupModal } from "./CentrepaySetupModal";
import { AiloRN, nsEntities } from "@ailo/ailorn";
import { useGetCentrepayers } from "local/domain/propertyManagement/hooks/useGetCentrepayers";
import { CentrepayRentersList } from "./CentrepayRentersList";
import { Address } from "@ailo/domains";
import { useToastContext } from "@ailo/ui";
import { getTenantsCentrepayStatus } from "./getTenantsCentrepayStatus";
import { CentrepaySectionLoading } from "./CentrepaySectionLoading";
import { LocalDate } from "@ailo/date";
import { FormerTenancyConfirmCrnSetupModal } from "local/tabs/properties/components/PropertyScreen/components/TenancySidebarContent/components/CentrepaySection/CentrepaySetupModal/FormerTenancyConfirmCrnSetupModal";

export function CentrepaySection({
  managingEntity,
  tenancyId,
  property,
  tenants,
  endDate
}: {
  managingEntity: AiloRN;
  tenancyId: string;
  property?: {
    address?: Address;
  };
  tenants: { legalEntity: AiloRN; name: string }[];
  endDate?: LocalDate;
}): ReactElement | null {
  const [showCentrepayModal, setShowCentrepayModal] = useState(false);
  const [
    showFormerTenancyConfirmCrnSetupModal,
    setShowFormerTenancyConfirmCrnSetupModal
  ] = useState(false);

  const tenancy = AiloRN.of(nsEntities.propertymanagement.tenancy, tenancyId);
  const toast = useToastContext();
  const {
    data: centrepayRenters,
    loading,
    refetch
  } = useGetCentrepayers({
    managingEntity,
    tenancy
  });
  const isFormerTenancy = endDate && endDate.isBefore(LocalDate.today());
  if (loading && !centrepayRenters) {
    return <CentrepaySectionLoading />;
  }
  return (
    <View>
      <SectionHeader>
        <Text.BodyL weight={"medium"}>{"Renters using Centrepay"}</Text.BodyL>
        <AddElementButton
          onPress={() => {
            if (isFormerTenancy) {
              setShowFormerTenancyConfirmCrnSetupModal(true);
            } else {
              setShowCentrepayModal(true);
            }
          }}
          text={"Add"}
          disabled={allTenantsSetupWithCentrepay(centrepayRenters, tenants)}
        />
      </SectionHeader>

      <CentrepayRentersList
        managingEntity={managingEntity}
        tenancy={tenancy}
        renters={centrepayRenters}
        onDelete={async () => {
          await refetch();
          toast.show({
            type: "success",
            message: "Centrepay has been removed"
          });
        }}
        property={property}
        onEdit={refetch}
      />

      <CentrepaySetupModal
        managingEntity={managingEntity}
        tenants={getTenantsCentrepayStatus(centrepayRenters, tenants)}
        tenancy={tenancy}
        property={property}
        visible={showCentrepayModal}
        onDismiss={() => {
          setShowCentrepayModal(false);
        }}
        onSuccess={async () => {
          setShowCentrepayModal(false);
          toast.show({ type: "success", message: "Centrepay has been set up" });
          await refetch();
        }}
        onFailure={async () => {
          setShowCentrepayModal(false);
          toast.show({ type: "error", message: "Centrepay setup failed" });
          await refetch();
        }}
        title={"Set up tenancy's Centrepay"}
        buttonLabel={"Add Centrepay"}
        createCentrepayDirective={true}
      />
      <FormerTenancyConfirmCrnSetupModal
        visible={showFormerTenancyConfirmCrnSetupModal}
        onDismiss={() => {
          setShowFormerTenancyConfirmCrnSetupModal(false);
        }}
        onConfirm={() => {
          setShowFormerTenancyConfirmCrnSetupModal(false);
          setShowCentrepayModal(true);
        }}
      />
    </View>
  );
}

function allTenantsSetupWithCentrepay(
  centrepayRenters: unknown[] | undefined,
  tenants: unknown[]
): boolean {
  return (centrepayRenters || []).length >= tenants.length;
}
