import { Button, formatProjectType } from "@ailo/ui";
import { Colors, opacify, Text, useSynchronisedLoad } from "@ailo/primitives";
import React, { ReactElement, useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { PlatformFeatureId, ProjectType, Tenancy } from "local/graphql";
import { OptionalTooltip } from "local/common";
import { useHasFeature } from "@ailo/domains";
import { ProjectFormData, ProjectFormFields } from "./ProjectFormData";
import { useCanCreateProject } from "../useCanCreateProject";
import { isPresent } from "ts-is-present";
import { View } from "react-native";
import styled from "styled-components/native";

export function ProjectFirstStep({
  currentTenancy,
  currentTenancyLoading,
  onFocus,
  onFieldUpdated,
  onTypeSelected,
  managementId
}: {
  currentTenancy?: Pick<Tenancy, "id" | "ailoRN" | "endDate">;
  currentTenancyLoading?: boolean;
  onFocus(): void;
  onFieldUpdated(fieldName: keyof ProjectFormData): void;
  onTypeSelected: (type?: ProjectType) => void;
  managementId: string;
}): ReactElement {
  useEffect(() => {
    onFocus();
  }, [onFocus]);

  const { setValue } = useFormContext<ProjectFormData>();
  const hasCreateNewTenancyProjectFeature = useHasFeature(
    PlatformFeatureId.CreateNewTenancyProject
  );
  const hasCreateGeneralProjectFeature = useHasFeature(
    PlatformFeatureId.CreateGeneralProject
  );
  const hasCreateNewManagementProjectFeature = useHasFeature(
    PlatformFeatureId.CreateNewManagementProject
  );

  const hasCreateVacatingTenancyProjectFeature = useHasFeature(
    PlatformFeatureId.CreateVacatingTenancyProject
  );

  const setProjectType = useCallback(
    (projectType: ProjectType) => {
      setValue(ProjectFormFields.type, projectType);
      onFieldUpdated(ProjectFormFields.type);
      onTypeSelected(projectType);
      if (
        projectType === ProjectType.LeaseRenewal ||
        projectType === ProjectType.VacatingTenancy
      ) {
        setValue("meta", { tenancyAilorn: currentTenancy?.ailoRN });
      }
    },
    [currentTenancy?.ailoRN, onFieldUpdated, onTypeSelected, setValue]
  );

  const renderProjectTypeButton = useCallback(
    ({
      type,
      disabled,
      tooltipContent,
      loading
    }: {
      type: ProjectType;
      disabled?: boolean;
      tooltipContent?: string;
      loading?: boolean;
    }) => (
      <OptionalTooltip tooltipContent={tooltipContent}>
        <Button.Secondary
          onPress={() => setProjectType(type)}
          style={{
            borderRadius: 4,
            borderColor: opacify(Colors.TEXT.DARK.PRIMARY, 0.2)
          }}
          contentColor={Colors.TEXT.DARK.PRIMARY}
          testID={`TypeInputFormButton${type}`}
          disabled={disabled}
          loading={loading}
        >
          <Text.BodyS weight={"book"}>{formatProjectType(type)}</Text.BodyS>
        </Button.Secondary>
      </OptionalTooltip>
    ),
    [setProjectType]
  );

  const {
    canCreateNewProject: canCreateNewTenancyProject,
    reason: newTenancyTooltip,
    loading: newTenancyButtonLoading
  } = useCanCreateProject(managementId, ProjectType.NewTenancy);

  const {
    canCreateNewProject: canCreateVacatingTenancyProject,
    reason: vacatingTenancyTooltip,
    loading: vacatingTenancyButtonLoading
  } = useCanCreateProject(managementId, ProjectType.VacatingTenancy);

  const loading = useSynchronisedLoad(
    newTenancyButtonLoading === true && currentTenancyLoading === true
  );

  const leaseRenewalProjectButton = renderProjectTypeButton({
    type: ProjectType.LeaseRenewal,
    disabled: !currentTenancy,
    tooltipContent: !currentTenancy ? "No tenancy available" : undefined,
    loading: loading
  });

  const newTenancyProjectButton = hasCreateNewTenancyProjectFeature
    ? renderProjectTypeButton({
        type: ProjectType.NewTenancy,
        disabled: !canCreateNewTenancyProject,
        tooltipContent: newTenancyTooltip,
        loading: loading
      })
    : undefined;

  const generalProjectButton = hasCreateGeneralProjectFeature
    ? renderProjectTypeButton({ type: ProjectType.General })
    : undefined;

  const newManagementProjectButton = hasCreateNewManagementProjectFeature
    ? renderProjectTypeButton({ type: ProjectType.NewManagement })
    : undefined;

  const vacatingTenancyProjectButton = hasCreateVacatingTenancyProjectFeature
    ? renderProjectTypeButton({
        type: ProjectType.VacatingTenancy,
        disabled: !currentTenancy || !canCreateVacatingTenancyProject,
        tooltipContent: !currentTenancy
          ? "No tenancy available"
          : vacatingTenancyTooltip,
        loading: vacatingTenancyButtonLoading
      })
    : undefined;

  const projectButtons = [
    newTenancyProjectButton,
    leaseRenewalProjectButton,
    vacatingTenancyProjectButton,
    newManagementProjectButton,
    generalProjectButton
  ].filter(isPresent);

  return (
    <>
      <Text.BodyL style={{ marginBottom: 20 }}>
        {"Select a project type"}
      </Text.BodyL>
      <Container>
        {projectButtons.map((button, index) => {
          return (
            <View
              key={index}
              style={{
                width: "50%",
                paddingLeft: !isLeftColumn(index) ? 6 : 0,
                paddingRight: isLeftColumn(index) ? 6 : 0,
                marginBottom: 12
              }}
            >
              {button}
            </View>
          );
        })}
      </Container>
    </>
  );
}

function isLeftColumn(index: number): boolean {
  return index % 2 === 0;
}

const Container = styled(View)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;
