import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import {
  UpdateEndOfTenancyMutation,
  useUpdateEndOfTenancyMutation
} from "local/graphql";
import { useCallback } from "react";

export function useRemoveTenancyEndDate({
  tenancyId,
  onCompleted,
  onError
}: {
  tenancyId: string;
  onCompleted?: () => void;
  onError?: () => void;
}): [() => void, MutationResult<UpdateEndOfTenancyMutation>] {
  const [updateEndOfTenancyMutation, result] = useUpdateEndOfTenancyMutation();

  const removeTenancyEndDate = useCallback(async (): Promise<void> => {
    let result: ExecutionResult<UpdateEndOfTenancyMutation>;

    try {
      result = await updateEndOfTenancyMutation({
        variables: {
          id: tenancyId,
          endDate: null,
          reason: null,
          notes: null
        }
      });
      throwIfMutationFailed(result, { dataKey: "updateEndOfTenancy" });
    } catch (error) {
      AiloSentry.captureException(error);
      onError?.();
      return;
    }

    onCompleted?.();
  }, [onCompleted, updateEndOfTenancyMutation, tenancyId, onError]);

  return [removeTenancyEndDate, result];
}
