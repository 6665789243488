import React, { ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { Money } from "@ailo/ui";
import { LeaseTypes } from "./NewTenancyForm";
import {
  useGetTenancyDetailsForEditQuery,
  TenancyFragment
} from "local/graphql";
import { EditTenancyFormWithData } from "./EditTenancyFormWithData";

interface Props {
  tenancyId: string;
  onSuccess?(tenancy: TenancyFragment): void;
  onError?(): void;
  onCancel?(): void;
  style?: StyleProp<ViewStyle>;
}

export function EditTenancyForm({
  onSuccess,
  onError,
  onCancel,
  tenancyId,
  style
}: Props): ReactElement {
  const { data, loading } = useGetTenancyDetailsForEditQuery({
    variables: { tenancyId },
    fetchPolicy: "network-only"
  });

  const tenancy = data?.tenancy;
  const rent = tenancy?.displayRent;
  const agreement = tenancy?.nextTenancyAgreement;
  const previousTenancyEndDate =
    data?.tenancy?.management?.mostRecentTenancy?.endDate;

  if (loading) return <></>;

  return (
    <EditTenancyFormWithData
      tenancyId={tenancyId}
      defaultValues={{
        leaseType: agreement?.fixedTermEndDate
          ? LeaseTypes.FixedTerm
          : LeaseTypes.Periodic,
        agreementStartDate: agreement?.startDate,
        agreementEndDate: agreement?.fixedTermEndDate,
        rentAmount:
          rent?.amountInCents == null
            ? undefined
            : Money.fromCents(rent?.amountInCents),
        rentFrequency: rent?.period,
        rentReviewDate: tenancy?.scheduledRentReviewDate,
        rentStartDate: rent?.effectiveDate
      }}
      previousTenancyEndDate={previousTenancyEndDate || undefined}
      onSuccess={onSuccess}
      onError={onError}
      onCancel={onCancel}
      style={style}
    />
  );
}
