import React, { useCallback, useEffect, useState } from "react";
import { Text, Link, Pressable } from "@ailo/primitives";
import { Image } from "react-native";
import { BaseActionActivity } from "../BaseActionActivity";
import { useEnvironment } from "@ailo/services";

const ACTION_VIEW_MAX_WIDTH = 508;

export function NoInspectionView(): React.ReactElement {
  const { AILO_INSPECTION_APP_LINK, AILO_INSPECTION_APP_QR_IMAGE_URL } =
    useEnvironment();

  const [srcHeight, setSrcHeight] = useState(0);
  const [srcWidth, setSrcWidth] = useState(0);

  const openInspectionAppLink = useCallback(() => {
    window.open(AILO_INSPECTION_APP_LINK, "_blank");
  }, [AILO_INSPECTION_APP_LINK]);

  useEffect(() => {
    if (!AILO_INSPECTION_APP_QR_IMAGE_URL) {
      return;
    }
    Image.getSize(AILO_INSPECTION_APP_QR_IMAGE_URL, (width, height) => {
      setSrcHeight((ACTION_VIEW_MAX_WIDTH / width) * height);
      setSrcWidth(ACTION_VIEW_MAX_WIDTH);
    });
  }, [AILO_INSPECTION_APP_QR_IMAGE_URL, setSrcHeight, setSrcWidth]);

  return (
    <BaseActionActivity>
      <Text.BodyM style={{ marginBottom: 24 }}>
        {"Download and open the"}{" "}
        <Pressable onPress={openInspectionAppLink}>
          <Link variant={"primary"}>{"Ailo Inspect"}</Link>
        </Pressable>{" "}
        {
          "app on your phone to get started. Once you're done at the property tap 'Complete inspection' in the app."
        }
      </Text.BodyM>
      <Image
        source={{
          uri: AILO_INSPECTION_APP_QR_IMAGE_URL
        }}
        resizeMode={"contain"}
        style={{ width: srcWidth, height: srcHeight }}
      />
    </BaseActionActivity>
  );
}
