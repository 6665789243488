import { Colors, Text } from "@ailo/primitives";
import React, { ReactElement, useEffect } from "react";
import { View } from "react-native";
import { ManagementDetailsSection } from "./AgreementDetailsSection";
import { OwnerDetailsSection } from "./OwnerDetailsSection";
import { useAnalytics } from "@ailo/services";
import { BaseFormSeparator } from "local/modals/ui/BaseFormSeparator";

interface Props {
  display: boolean;
  onEditAgreementPress?(): void;
  onEditOwnersPress?(): void;
}

export function ReviewManagementDetailsStep({
  display,
  onEditAgreementPress,
  onEditOwnersPress
}: Props): ReactElement {
  const analytics = useAnalytics();
  useEffect(() => {
    if (display === true) {
      analytics.trackScreenVisited("New Management — Review");
    }
  }, [display, analytics]);

  return (
    <View style={{ display: display ? undefined : "none" }}>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
        {"Review new management details"}
      </Text.BodyM>
      <BaseFormSeparator />
      <ManagementDetailsSection onEditPress={onEditAgreementPress} />
      <BaseFormSeparator />
      <OwnerDetailsSection onEditPress={onEditOwnersPress} />
    </View>
  );
}
