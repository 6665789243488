import React, { ReactElement } from "react";
import { Button } from "@ailo/ui";
import { AutoPayIcon } from "@ailo/primitives";

interface Props {
  label?: string;
  visible?: boolean;
  onPress?: () => void;
}

export function ResetButton({
  label,
  onPress,
  visible = true
}: Props): ReactElement | null {
  return !visible ? null : (
    <Button.Text
      type={"text-small"}
      onPress={onPress}
      leftIcon={(): ReactElement => <AutoPayIcon width={16} height={16} />}
      style={{ borderWidth: 0 }}
    >
      {label ?? "Reset to default"}
    </Button.Text>
  );
}
