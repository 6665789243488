import React, { ReactElement, useCallback } from "react";
import { useWindowDimensions, View } from "react-native";
import {
  BaseModal,
  BaseModalProps as UIBaseModalProps,
  Button,
  ModalVisibility
} from "@ailo/ui";
import { SplitScreen } from "./SplitScreen";
import styled from "styled-components/native";

export type BaseReceiptModalProps = UIBaseModalProps & {
  onActionPress?: () => void;
  title: string;
  renderAllocationsComponent?: () => React.ReactElement;
  visibility: ModalVisibility<void>;
};

export function ReceiptBaseModal({
  onActionPress,
  children,
  title,
  renderAllocationsComponent,
  ...props
}: BaseReceiptModalProps): ReactElement {
  const renderFormComponent = useCallback(
    (): React.ReactElement => <>{children}</>,
    [children]
  );

  const windowWidth = useWindowDimensions().width;

  const footer = (
    <>
      <Button.Secondary onPress={props.visibility.hide}>
        {"Cancel"}
      </Button.Secondary>
      <Button.Primary onPress={() => {}}>{"Receipt"}</Button.Primary>
    </>
  );

  return (
    <BaseModal
      modalWidth={windowWidth * 0.95}
      footer={footer}
      showFooterBorder
      headerAndContentContainerStyle={{
        padding: 0
      }}
      visible={props.visibility.isVisible}
      {...props}
    >
      <MainPanel>
        <SplitScreen
          title={title}
          onDismiss={props.visibility.hide}
          renderFormComponent={renderFormComponent}
          renderRightComponent={renderAllocationsComponent}
        />
      </MainPanel>
    </BaseModal>
  );
}

const MainPanel = styled(View)`
  padding-left: 0px;
`;
