import React, { useMemo, useCallback } from "react";
import { Colors, Pressable, ProjectImage } from "@ailo/primitives";
import {
  EmptyGrid,
  ErrorCard,
  Grid,
  gridBodyMarginTop,
  GridColumnOptions,
  GridRenderRowContainerProps,
  GridSortOrder,
  PaginationNavigation,
  PersonAvatarProps
} from "@ailo/ui";
import { StackActions } from "@react-navigation/native";
import { ProjectListQueryData } from "./useGetProjectRows";
import {
  ProjectListRowFragment,
  ProjectStatus,
  ProjectType
} from "local/graphql";
import { View } from "react-native";
import styled from "styled-components/native";
import { useOnFocus, usePressableNavigation } from "@ailo/services";
import { Screens } from "local/common";

export interface ProjectListRow {
  projectId: string;
  name: string;
  status: ProjectStatus;
  reference: string;
  type: ProjectType;
  key: string;
  dueDate: string;
  person: PersonAvatarProps["person"];
  actions: ProjectListRowFragment["actions"];
}

export interface ProjectListLoadingRow {
  key: string;
}

export interface ProjectListProps {
  queryData: ProjectListQueryData;
  tabNavigation: React.ReactElement;
  errorMessage: string;
  emptyMessage: string;
  secondaryEmptyMessage: React.ReactElement | string;
  columns: Array<
    GridColumnOptions<ProjectListRow> & {
      renderLoadingCell: GridColumnOptions<ProjectListLoadingRow>["renderCell"];
    }
  >;
  sortOrder: GridSortOrder;
  onSortOrderChange?: (sortOrder: GridSortOrder) => void;
  footerButton?: React.ReactElement;
  filterElements: React.ReactElement[];
  isAnyFilterActive: boolean;
}

function RowContainer({
  row,
  style,
  children
}: GridRenderRowContainerProps<{
  key: string;
  projectId: string;
  name: string;
  status: ProjectStatus;
  reference: string;
  type: ProjectType;
}>): React.ReactElement {
  const linkProps = usePressableNavigation({
    link: {
      to: `/projects/${row.projectId}`,
      action: StackActions.push(Screens.Project, {
        id: row.projectId,
        previousScreen: Screens.ProjectList,
        actionId: undefined
      })
    }
  });

  return (
    <Pressable {...linkProps} style={[style, { padding: 0 }]}>
      {children}
    </Pressable>
  );
}

function LoadingRowContainer({
  style,
  children
}: GridRenderRowContainerProps<{ key: string }>): React.ReactElement {
  return <View style={[style, { borderBottomWidth: 0 }]}>{children}</View>;
}

const ProjectFiltersContainer = styled(View)`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.WHITE};
  padding: 16px 16px 8px 16px;
  box-shadow: 0px 1px 4px rgba(28, 30, 38, 0.2);
`;

const GridProjectList: React.FC<ProjectListProps> = ({
  queryData,
  tabNavigation,
  errorMessage,
  emptyMessage,
  secondaryEmptyMessage,
  columns,
  footerButton,
  sortOrder,
  onSortOrderChange,
  filterElements,
  isAnyFilterActive
}) => {
  const {
    data: { data, loading, error, refetch, currentPage, pagesCount },
    requestNextPage,
    requestPreviousPage
  } = queryData;

  const headerCaption = useMemo(() => {
    const filtersComponent = (
      <ProjectFiltersContainer>
        {filterElements.map((element, index) =>
          index > 0 ? (
            <View style={{ marginLeft: 12 }} key={`filter-element-${index}`}>
              {element}
            </View>
          ) : (
            element
          )
        )}
      </ProjectFiltersContainer>
    );

    return (
      <React.Fragment key={"project-list-header-caption"}>
        {tabNavigation}
        {filterElements.length > 0 ? filtersComponent : null}
      </React.Fragment>
    );
  }, [filterElements, tabNavigation]);

  const EmptyGridBody = useCallback(
    (): React.ReactElement => (
      <EmptyGrid
        message={emptyMessage}
        secondaryMessage={secondaryEmptyMessage}
        style={{ marginTop: -gridBodyMarginTop, paddingTop: gridBodyMarginTop }}
      />
    ),
    [emptyMessage, secondaryEmptyMessage]
  );

  useOnFocus(refetch);

  if (loading) {
    return (
      <Grid
        headerCaption={headerCaption}
        rowStyle={{ padding: 0, paddingTop: 16 }}
        rowHeadStyle={{ padding: 0 }}
        rowHeadCellStyle={{ padding: 16 }}
        testID={"project-grid"}
        rows={[{ key: "1" }, { key: "2" }, { key: "3" }]}
        columns={columns.map((col) => ({
          ...col,
          renderCell: col.renderLoadingCell
        }))}
        renderRowContainer={LoadingRowContainer}
      />
    );
  }

  if (error || !data)
    return (
      <Grid.Placeholder headerCaption={tabNavigation}>
        <ErrorCard message={errorMessage} onReload={refetch} />
      </Grid.Placeholder>
    );

  if (!data.projects.length && isAnyFilterActive) {
    return (
      <Grid
        headerCaption={headerCaption}
        testID={"project-grid"}
        columns={columns}
        gridBodyComponent={EmptyGridBody}
        rowHeadStyle={{
          padding: 16
        }}
        rows={[]}
      />
    );
  }

  if (!data.projects.length)
    return (
      <Grid.Placeholder headerCaption={tabNavigation}>
        <EmptyGrid
          message={emptyMessage}
          secondaryMessage={secondaryEmptyMessage}
          bannerImage={<ProjectImage style={{ alignSelf: "center" }} />}
        />
      </Grid.Placeholder>
    );

  return (
    <Grid
      headerCaption={headerCaption}
      footerCaption={
        <StyledContainer>
          <GridCell />
          <PaginationNavigation
            currentPage={currentPage}
            pagesCount={pagesCount}
            style={{ justifyContent: "center" }}
            onGoToPreviousPage={requestPreviousPage}
            onGoToNextPage={requestNextPage}
          />
          <GridCell style={{ justifyContent: "flex-end" }}>
            {footerButton}
          </GridCell>
        </StyledContainer>
      }
      columns={columns}
      rows={data.projects}
      rowHeadStyle={{ padding: 0 }}
      rowHeadCellStyle={{ padding: 16 }}
      renderRowContainer={RowContainer}
      onRowPress={(): void => {}}
      sortOrder={sortOrder}
      onSortOrderChange={onSortOrderChange}
      rowStyle={{
        paddingTop: 16
      }}
    />
  );
};

const StyledContainer = styled(View)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 24px;
  margin-bottom: 32px;
  height: 40px;
  align-items: center;
`;

const GridCell = styled(View)`
  flex-direction: row;
`;

export { GridProjectList };
