import { Colors, Text } from "@ailo/primitives";
import { formatDate } from "@ailo/services";
import { DescriptionList } from "@ailo/ui";
import { Action } from "local/domain/project";
import { ActionType, ManagementEndReasonFragment } from "local/graphql";
import { BaseFormSeparator } from "local/modals/ui/BaseFormSeparator";
import { EditSectionHeader } from "local/tabs/projects/actions/ActionSidebar/ActionActivity/EditSectionHeader";
import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { useFormContext } from "react-hook-form";
import { View } from "react-native";
import { EndManagementFormData } from "../EndManagementFormData";

export function ReviewEndingManagementStep({
  display,
  setCurrentStepNumber,
  endReasons
}: {
  action?: Action<ActionType.AssignTenancyAgreement>;
  display: boolean;
  setCurrentStepNumber: Dispatch<SetStateAction<number>>;
  endReasons: ManagementEndReasonFragment[];
}): ReactElement {
  const { watch } = useFormContext<EndManagementFormData>();
  const endDate = watch("managementEndDate");
  const endReasonCode = watch("managementEndReason");
  const lostReasonCodes = watch("managementLostReason");
  const endNote = watch("managementEndNote");

  const endReason = endReasons.find((reason) => reason.code === endReasonCode);
  const lostReason = lostReasonCodes
    ? endReason?.causes.filter((cause) => lostReasonCodes.includes(cause.code))
    : undefined;

  return (
    <View style={{ display: display ? undefined : "none" }}>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
        {"Please confirm the details are correct"}
      </Text.BodyM>
      <BaseFormSeparator />
      <EditSectionHeader
        title={"Ending a management"}
        onPress={() => setCurrentStepNumber(1)}
        style={{ marginBottom: 20 }}
      />
      <DescriptionList
        style={{ marginBottom: 16 }}
        size={"small"}
        columns={2}
        items={[
          {
            label: "Management end date",
            descriptionBottom: endDate ? formatDate(endDate) : "N/A",
            columnIndex: 0
          },
          {
            label: "Management end reason",
            descriptionBottom: endReason?.label,
            columnIndex: 1
          }
        ]}
      />
      <DescriptionList
        size={"small"}
        itemStyle={{ marginBottom: 16, marginTop: 0 }}
        items={[
          ...(lostReason
            ? [
                {
                  label: "Management lost reason",
                  descriptionBottom: lostReason
                    .map((reason) => reason.label)
                    .join(", ")
                }
              ]
            : []),
          {
            label: "Management end note",
            descriptionBottom: endNote
          }
        ]}
      />
    </View>
  );
}
