import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { DetailsField } from "local/modals/TenancyModalForm/TenancyForms/ReviewTenancyDetailsStep/DetailsField";
import { Money } from "@ailo/ui";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";
import { EditSectionHeader } from "../../EditSectionHeader";
import { BaseActionActivity } from "../../BaseActionActivity";

export function DepositDetailsSection({
  depositAmount
}: {
  depositAmount?: Money;
}): ReactElement {
  return (
    <BaseActionActivity>
      <EditSectionHeader
        title={"Initial Payment"}
        displayEditButton={false}
        style={{ marginBottom: 20 }}
      />
      {depositAmount ? (
        <StyledDetailsField label={"Amount"} value={depositAmount.format()} />
      ) : (
        <View>
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{"N/A"}</Text.BodyS>
        </View>
      )}
    </BaseActionActivity>
  );
}

const StyledDetailsField = styled(DetailsField)`
  margin-bottom: 8px;
`;
