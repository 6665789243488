import React, { ReactElement } from "react";
import { Row } from "local/common";
import { View } from "react-native";
import { Colors, PropertyIcon, Text } from "@ailo/primitives";
import { BorderedBox, BoxWithShadow } from "@ailo/ui";
import { TenancyDetailsData } from "../parseTenancyDetailsData";
import { formatAddress, formatPersonName } from "@ailo/domain-helpers";

export function TenancyDetails({
  data
}: {
  data: TenancyDetailsData;
}): ReactElement {
  return (
    <BorderedBox style={{ padding: 16, alignItems: "flex-start" }}>
      <Row style={{ justifyContent: "flex-start" }}>
        <BoxWithShadow
          style={{
            marginRight: 16
          }}
        >
          <PropertyIcon color={Colors.TEXT.DARK.PLACEHOLDER} />
        </BoxWithShadow>
        <View>
          <View style={{ paddingBottom: 16 }}>
            <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
              {"Property"}
            </Text.BodyS>
            <Text.BodyM weight={"medium"}>
              {formatAddress(data.propertyAddress, {
                format: "street, suburb"
              })}
            </Text.BodyM>
          </View>
          <View>
            <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
              {"Renters"}
            </Text.BodyS>
            <Text.BodyM weight={"medium"} style={{ width: "75%" }}>
              {data.tenants
                .map((tenant) => formatPersonName(tenant))
                .join(", ")}
            </Text.BodyM>
          </View>
        </View>
      </Row>
    </BorderedBox>
  );
}
