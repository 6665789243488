import { LocalDate } from "@ailo/date";
import { useAnalytics } from "@ailo/services";
import {
  DownloadModalWithTimeout,
  useCurrentAgencyOrg,
  useTimer
} from "local/common";
import { GetTransferStatementDataQueryVariables } from "local/graphql";
import React, { ReactElement, useEffect, useMemo, useRef } from "react";
import { CSVLink } from "react-csv";
// eslint-disable-next-line import/no-unresolved
import type Link from "react-csv/components/Link";
import { View } from "react-native";
import { useState } from "reactn";
import { isPresent } from "ts-is-present";
import { formatLineItem, STATEMENT_CSV_HEADERS } from "./formatLineItem";
import { useGetTransferStatementData } from "./useGetTransferStatementData";

export function CsvDownloadModal({
  toggleModal,
  statementQueryVariables,
  rangeEndDate
}: {
  toggleModal: () => void;
  statementQueryVariables: GetTransferStatementDataQueryVariables;
  rangeEndDate: string;
}): ReactElement {
  const agencyOrg = useCurrentAgencyOrg();

  const downloadTimeOutInMs = 30000;
  const [key, setKey] = useState(0);
  const resetModal = (): void => {
    setKey(key + 1);
  };
  const fileName = `${LocalDate.from(rangeEndDate)
    .format("YYYY-MM-DD")
    .replace(/-/g, "")}-TransferSummaryTransactions-${agencyOrg.name.replace(
    / /g,
    ""
  )}.csv`;

  return (
    <CsvDownloadModalWithTimeout
      key={key.toString()}
      toggleModal={toggleModal}
      resetModal={resetModal}
      downloadTimeOutInMs={downloadTimeOutInMs}
      statementQueryVariables={statementQueryVariables}
      fileName={fileName}
    />
  );
}

function CsvDownloadModalWithTimeout({
  toggleModal,
  resetModal,
  downloadTimeOutInMs,
  statementQueryVariables,
  fileName
}: {
  toggleModal: () => void;
  resetModal: () => void;
  downloadTimeOutInMs: number;
  statementQueryVariables: GetTransferStatementDataQueryVariables;
  fileName: string;
}): ReactElement {
  const agencyOrg = useCurrentAgencyOrg();

  const [isLoading, setIsLoading] = useState(true);

  const timerObject = useTimer(
    !!statementQueryVariables.rangeEndBusinessTxId,
    downloadTimeOutInMs
  );

  const csvInstance = useRef<
    { link: { click: () => void } } & Link & HTMLAnchorElement
  >(null);

  const { data, loading } = useGetTransferStatementData({
    statementQueryVariables
  });

  const csvData = useMemo((): string[][] => {
    return (
      data?.transferSummaryStatementData?.transactionLineItemsByStatementTransactionDate?.items
        .map((lineItem) =>
          formatLineItem(lineItem, statementQueryVariables.walletOwnerReference)
        )
        .filter(isPresent) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  useEffect(() => {
    if (!loading && data && csvData.length > 0) {
      setTimeout(() => setIsLoading(false));
    }
  }, [csvData.length, data, loading]);

  const analytics = useAnalytics();
  const trackDownload = (): void =>
    analytics.track(`File Downloaded`, {
      file_type: "text/csv",
      category: "Transactions",
      organisationId: agencyOrg.ailoRN
    });

  return (
    <View>
      <DownloadModalWithTimeout
        toggleModal={toggleModal}
        resetModal={resetModal}
        timerObject={timerObject}
        onDownload={() => {
          csvInstance.current?.link.click();
          trackDownload();
        }}
        loading={isLoading}
      />
      <CSVLink
        data={csvData}
        headers={STATEMENT_CSV_HEADERS}
        filename={fileName}
        ref={csvInstance}
      />
    </View>
  );
}
