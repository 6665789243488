interface PropertyExclusionReason {
  value: string;
  label?: string;
}

export const PropertyExclusionReasons: PropertyExclusionReason[] = [
  { value: "Payment support" },
  { value: "No smartphone (investor)" },
  { value: "No smartphone (renter)" },
  { value: "Cash over counter" },
  { value: "Multi property owner" },
  { value: "Other", label: "Other (detail below)" }
];
