import React from "react";
import { Control, Controller } from "react-hook-form";
import { MultiSelectButton } from "@ailo/ui";
import { ReportCellValue, ReportTableRow } from "../../ReportTable";
import { FilterComponentProps } from "../types";

export type MultiSelectFilterProps = FilterComponentProps & {
  control: Control;
  rows?: ReportTableRow[] | null;
  columnName: string;
} & Pick<Parameters<typeof MultiSelectButton>[0], "menuWidth">;

export const MultiSelectFilter = ({
  control,
  rows,
  name,
  namePlural = name,
  loading,
  columnName,
  menuWidth
}: MultiSelectFilterProps): React.ReactElement<MultiSelectFilterProps> => {
  if (!rows || loading) {
    return (
      <MultiSelectButton.Loading
        key={"multiSelect"}
        entityType={name}
        horizontalAlign={"right"}
      />
    );
  }

  const uniqueColumnValues = getUniqueColumnValues(rows, columnName);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ value, onChange }) => {
        const label = value?.length > 1 ? namePlural : name;
        return (
          <MultiSelectButton
            {...{ value }}
            horizontalAlign={"right"}
            key={"multiSelect"}
            entityType={label}
            optionGroups={[
              {
                label: name,
                options: convertToMultiSelectButtonOptions(uniqueColumnValues)
              }
            ]}
            onChange={(value) => {
              onChange(value);
            }}
            menuWidth={menuWidth}
          />
        );
      }}
    />
  );
};

function convertToMultiSelectButtonOptions(
  uniqueColumnValues: ReportCellValue[]
): { label: string; value: string }[] {
  return uniqueColumnValues.map((uniqueValue) => ({
    value: uniqueValue?.toString() ?? "",
    label: uniqueValue?.toString() ?? ""
  }));
}

function getUniqueColumnValues(
  rows: ReportTableRow[],
  columnName: string
): ReportCellValue[] {
  return rows
    .map((row) => row[columnName])
    .filter((value, index, self) => self.indexOf(value) === index);
}
