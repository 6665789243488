import { Table } from "@ailo/ui";
import {
  FunctionalReportTable,
  ReportTableColumn,
  ReportTableRow
} from "../../../components/ReportTable";

import { useCurrentAgencyOrg } from "local/common";
import React from "react";
import { useGetFirstRentPaymentsTableDataQuery } from "local/graphql";
import { useReportTeamContext } from "../../..";
import { reports } from "../../../reportsDetails";

// need to exclude dates and dollar amounts because of string formatting
const sortableColumns: string[] = ["propertyAddress", "teamName"];

export function TenancyFirstRentPaymentsTable(): React.ReactElement {
  const { teamId } = useReportTeamContext();
  const { id } = useCurrentAgencyOrg();
  // construct variables
  const variables = { managingOrgId: id, teamId };
  // get data
  const visualisationData = useGetFirstRentPaymentsTableDataQuery({
    variables
  });
  const reportData = visualisationData.data?.tenancyFirstRentPaymentReport;
  if (!reportData?.rows) return <Table.Loading />;

  if (reportData.rows.length < 1) return <Table.Loading />;

  const rows = reportData.rows.map((r) => {
    return r as ReportTableRow;
  }) as [ReportTableRow];
  const columns = reportData.columns as [ReportTableColumn];
  return (
    <FunctionalReportTable
      reportTitle={reports.first_rent_payments.reportHeading}
      columns={columns}
      rows={rows}
      sortableColumns={sortableColumns}
    />
  );
}
