import { LinkCrumb, Crumb, usePressableNavigation } from "@ailo/services";
import { MonthYear, MonthYearObject } from "local/common";
import styled from "styled-components/native";
import { monthNumberToName } from "../utils";
import { DefaultProps, RightChevronIcon } from "@ailo/primitives";
import React from "react";
import { Button, WhitePressable } from "@ailo/ui";

export function reconciliationsLinkTo(): string {
  return "/trust/reconciliation";
}

export const ReconciliationsCrumb = styled(Crumb).attrs({
  children: "Reconciliations"
})``;

export const ReconciliationsLinkCrumb = styled(LinkCrumb).attrs({
  children: "Reconciliations",
  link: { to: reconciliationsLinkTo() }
})``;

export function reconciliationLinkTo(monthYear: MonthYearObject): string {
  return `${reconciliationsLinkTo()}/${MonthYear.stringify(monthYear)}`;
}

export const ReconciliationCrumb = styled(Crumb).attrs<MonthYearObject>(
  (props) => ({
    children: `${monthNumberToName(props.month)} ${props.year}`
  })
)<MonthYearObject>``;

export const ReconciliationLinkCrumb = styled(LinkCrumb).attrs<MonthYearObject>(
  (props) => ({
    children: `${monthNumberToName(props.month)} ${props.year}`,
    link: { to: reconciliationLinkTo(props) }
  })
)<MonthYearObject>``;

export function adjustmentsLinkTo(monthYear: MonthYearObject): string {
  return `${reconciliationLinkTo(monthYear)}/adjustment`;
}

export const AdjustmentsCrumb = styled(Crumb).attrs({
  children: `Adjustments`
})``;

function useReconciliationLinkProps(
  props: MonthYearObject
): ReturnType<typeof usePressableNavigation> {
  return usePressableNavigation({
    link: {
      to: `/trust/reconciliation/${MonthYear.stringify(props)}`
    }
  });
}

export function ReconciliationTextButton(
  props: MonthYearObject
): React.ReactElement {
  const linkProps = useReconciliationLinkProps(props);
  return (
    <Button.Text rightIcon={RightChevronIcon} onPress={linkProps.onPress}>
      {"View reconciliation"}
    </Button.Text>
  );
}

export function PressableReconciliationLink({
  style,
  children,
  ...rest
}: DefaultProps<MonthYearObject>): React.ReactElement {
  const linkProps = useReconciliationLinkProps(rest);

  return (
    <WhitePressable {...linkProps} style={style}>
      {children}
    </WhitePressable>
  );
}
