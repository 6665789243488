import { Colors, opacify, Text } from "@ailo/primitives";
import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";

interface ListItemWithFooterProps {
  header: string;
  subHeader: ReactElement;
  rightComponent: ReactElement;
  footer?: ReactElement;
  style?: StyleProp<ViewStyle>;
}

/**
 * Temporary version of ListItem with a footer since
 * adding a footer to the existing ListItem is quite involved.
 * This will be removed when recurring fee entries are implemented.
 */
export function ListItemWithFooter({
  header,
  subHeader,
  rightComponent,
  footer,
  style
}: ListItemWithFooterProps): ReactElement {
  return (
    <ListItemContainer style={style}>
      <Row>
        <View style={{ flex: 1 }}>
          <Text.BodyM weight={"medium"}>{header}</Text.BodyM>
          {subHeader}
        </View>
        {rightComponent}
      </Row>
      {footer}
    </ListItemContainer>
  );
}

const ListItemContainer = styled(View)`
  padding-vertical: 16px;
  padding-right: 16px;
  margin-left: 16px;
  border-bottom-width: 1px;
  border-bottom-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;

const Row = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
