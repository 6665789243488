import { Colors, Text } from "@ailo/primitives";
import React from "react";
import styled from "styled-components/native";
import { AppointmentTableRowContainer } from "./components";

export function AppointmentTableHeader(): React.ReactElement | null {
  return (
    <AppointmentTableRowContainer style={{ paddingTop: 20, paddingBottom: 8 }}>
      <HeaderText>{"Schedule"}</HeaderText>
      <HeaderText>{"Time"}</HeaderText>
    </AppointmentTableRowContainer>
  );
}

const HeaderText = styled(Text.BodyS).attrs({
  weight: "medium",
  color: Colors.TEXT.DARK.SECONDARY
})``;
