import { Claim, ClaimBondFormData } from "../ClaimBondFormData";
import {
  UpsertTenancyBondClaimsMutationVariables,
  UpsertTenancyBondClaim
} from "local/graphql";

export function mapFormToMutationData(
  formData: ClaimBondFormData
): UpsertTenancyBondClaimsMutationVariables["bond"]["claims"] {
  const rentClaim = formData.rent?.amount ? [mapClaim(formData.rent!)] : [];
  const billClaims = formData.bills
    ? formData.bills.filter((bill) => bill.claimed).map(mapClaim)
    : [];

  return billClaims
    .concat(rentClaim)
    .map((claim, index) => ({ ...claim, claimOrder: index }));
}

function mapClaim(claim: Claim): Omit<UpsertTenancyBondClaim, "claimOrder"> {
  return {
    amount: {
      cents: claim.amount!.cents
    },
    liabilityAiloRN: claim.reference!.toString()
  };
}
