import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import React, { ReactElement } from "react";
import { NotesAndKeysButton } from "../NotesAndKeysButton";

export function PropertyKeysButton({
  onViewKeysPress
}: {
  onViewKeysPress: () => void;
}): ReactElement | null {
  const canCreateAndViewPropertyKeys = useHasFeature(
    PlatformFeatureId.CreateAndViewPropertyKeys
  );

  if (!canCreateAndViewPropertyKeys) {
    return null;
  }

  return (
    <NotesAndKeysButton onPress={onViewKeysPress}>
      {"View keys"}
    </NotesAndKeysButton>
  );
}
