import { LocalDate } from "@ailo/date";

export enum effectiveDateStatus {
  PAST = "Past",
  CURRENT = "Now",
  FUTURE = "Future"
}

export function getEffectiveDateStatus({
  date,
  tenancyCurrentDate
}: {
  date: string;
  tenancyCurrentDate: string;
}): string | undefined {
  const currentDate = LocalDate.from(tenancyCurrentDate);
  if (LocalDate.fromString(date).isBefore(currentDate)) {
    return effectiveDateStatus.PAST;
  }
  if (LocalDate.fromString(date).isSame(currentDate)) {
    return effectiveDateStatus.CURRENT;
  }
  if (LocalDate.fromString(date).isAfter(currentDate)) {
    return effectiveDateStatus.FUTURE;
  }
}
