import { Badge, BadgeProps } from "@ailo/ui";
import React from "react";
import { SFC } from "@ailo/primitives";
import { BondDisbursalFailureReason, TenancyBondStatus } from "local/graphql";

type Props = {
  status: TenancyBondStatus;
  failureReason?: BondDisbursalFailureReason;
};

type BondDisplayStatus = Pick<BadgeProps, "children" | "type">;

const claimRequiredDisplayStatus: BondDisplayStatus = {
  children: "Claim required",
  type: "attention"
};

const awaitingRefundDisplayStatus: BondDisplayStatus = {
  children: "Awaiting refund",
  type: "default"
};

const claimCompletedDisplayStatus: BondDisplayStatus = {
  children: "Claim completed",
  type: "success"
};

const claimMismatch: BondDisplayStatus = {
  children: "Claim mismatch",
  type: "critical"
};

const allocationPending: BondDisplayStatus = {
  children: "Allocation pending",
  type: "default"
};

const bondResolvedDisplayStatus: BondDisplayStatus = {
  children: "Bond resolved",
  type: "success"
};

export const BondStatusBadge: SFC<Props> = ({ status, failureReason }) => {
  const displayStatus = getBondDisplayStatus(status, failureReason);

  return <Badge {...displayStatus} />;
};

export function getBondDisplayStatus(
  status: TenancyBondStatus,
  failureReason?: BondDisbursalFailureReason
): BondDisplayStatus {
  switch (status) {
    case TenancyBondStatus.Unclaimed:
      return claimRequiredDisplayStatus;
    case TenancyBondStatus.AwaitingFunds:
    case TenancyBondStatus.FundsReceived:
    case TenancyBondStatus.Disbursing:
      return awaitingRefundDisplayStatus;
    case TenancyBondStatus.DisbursementFailed:
      return getStatus(failureReason);
    case TenancyBondStatus.ClaimSuccessful:
      return claimCompletedDisplayStatus;
    case TenancyBondStatus.BondResolved:
      return bondResolvedDisplayStatus;
  }
}

function getStatus(
  failureReason?: BondDisbursalFailureReason
): BondDisplayStatus {
  if (
    failureReason === "ReceivedLessFundsThanClaimed" ||
    failureReason === "ReceivedMoreFundsThanClaimed"
  ) {
    return claimMismatch;
  }
  return allocationPending;
}
