import React from "react";
import { View } from "react-native";
import { useFormContext } from "react-hook-form";
import { AiloRN, services } from "@ailo/ailorn";
import { SFC } from "@ailo/primitives";
import { TextInput } from "@ailo/ui";
import { PlatformFeatureId, useGetManagementOwnersQuery } from "local/graphql";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";
import { useHasFeature } from "@ailo/domains";

interface Props {
  supplierReference: string;
}

const WalletInput: SFC<Props> = ({ supplierReference, style }) => {
  const { watch } = useFormContext<AddBillFormData>();
  const selectedProperty = watch("managementId");
  const managementId = selectedProperty?.value;
  const { data } = useGetManagementOwnersQuery({
    variables: managementId ? { id: managementId } : undefined,
    skip: !managementId
  });
  const investorPropertyWalletEnabled = useHasFeature(
    PlatformFeatureId.InvestorPropertyWallet
  );
  const numberOfOwners = data?.management?.ownerships?.pageInfo.total;
  const text = getWalletText(
    supplierReference,
    numberOfOwners,
    investorPropertyWalletEnabled
  );

  return (
    <View style={style} testID={"WalletInputFormField"}>
      <TextInput editable={false} placeholder={text} />
    </View>
  );
};

function getWalletText(
  supplierReference: string,
  numberOfOwners: number | undefined,
  investorPropertyWalletEnabled: boolean
): string {
  const supplierAiloRN = AiloRN.fromString(supplierReference);
  if (supplierAiloRN.isA(services.PropertyManagement.management)) {
    if (investorPropertyWalletEnabled) {
      return "Property Wallet";
    } else {
      if (numberOfOwners && numberOfOwners > 1) {
        return "Investors' Ailo Wallets";
      }
      return "Investor's Ailo Wallet";
    }
  }
  return "Agency's Ailo Wallet";
}

export { WalletInput };
