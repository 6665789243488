import { Colors, Text } from "@ailo/primitives";
import { PendingInspectionAppointmentFragment } from "local/graphql";
import moment from "moment";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { AppointmentTableRowContainer } from "../components";
import { AppointmentAddress } from "./AppointmentAddress";
import { AppointmentTableRowMoreButton } from "./AppointmentTableRowMoreButton";

interface Props {
  appointment: PendingInspectionAppointmentFragment;
  position: number;
  style?: StyleProp<ViewStyle>;
}

export function AppointmentTableRow({
  appointment,
  position
}: Props): React.ReactElement {
  return (
    <RowContainer>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <PositionContainer>
          <Text.BodyXS weight={"medium"} color={Colors.TEXT.DARK.SECONDARY}>
            {position}
          </Text.BodyXS>
        </PositionContainer>
        <AppointmentAddress appointment={appointment} />
      </View>
      <Text.BodyXS>{moment(appointment.startTime).format("h:mma")}</Text.BodyXS>
      <AppointmentTableRowMoreButton
        appointment={appointment}
        style={{ alignSelf: "flex-start" }}
      />
    </RowContainer>
  );
}

const PositionContainer = styled(View)`
  height: 24px;
  width: 24px;
  border-width: 1px;
  border-color: ${Colors.GRAYSCALE.OUTLINE};
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

const RowContainer = styled(AppointmentTableRowContainer)`
  padding-top: 16px;
  padding-bottom: 16px;
  border-top-width: 1px;
  border-top-color: ${Colors.GRAYSCALE.OUTLINE};
`;
