import { GridSortOrder } from "@ailo/ui";
import { LocalDate } from "@ailo/date";
import { Address, Money } from "@ailo/domains";
import {
  SortDirection,
  TransactionsQuery,
  TransactionsSortField,
  useTransactionsQuery
} from "local/graphql";
import { PaginatedQueryResult, usePaginatedQuery } from "@ailo/services";
import moment from "moment";

export type TransactionCategory = "all" | "this-month" | "last-month";

export type TransactionListFilters = {
  moneyHolder: string;
  category: TransactionCategory;
  searchQuery?: string;
};

function toISO(date: string): string {
  return moment(date).toISOString();
}

function applyFilter(filter: TransactionListFilters): {
  createdBefore?: string | null;
  createdAfter?: string | null;
} {
  switch (filter.category) {
    case "this-month":
      return {
        createdBefore: undefined,
        createdAfter: toISO(moment().format("YYYY-MM-01"))
      };
    case "last-month":
      return {
        createdBefore: toISO(
          moment(moment().format("YYYY-MM-01"))
            .subtract(1, "day")
            .format("YYYY-MM-DD")
        ),
        createdAfter: toISO(moment().subtract(1, "months").format("YYYY-MM-01"))
      };
    case "all":
    default:
      return {
        createdBefore: undefined,
        createdAfter: undefined
      };
  }
}

function useGetTransactions({
  filters,
  sortOrder = { columnKey: "auditNumber", direction: "DESC" }
}: {
  filters: TransactionListFilters;
  sortOrder?: GridSortOrder;
}): PaginatedQueryResult<TransactionsQuery, any> {
  const { moneyHolder } = filters;
  const transactionsFilter = applyFilter(filters);
  return usePaginatedQuery(useTransactionsQuery, "transactions", {
    variables: {
      moneyHolder: moneyHolder,
      ...transactionsFilter,
      pageSize: 20,
      sort: [
        {
          field: TransactionsSortField.AuditNumber,
          direction:
            sortOrder.direction === "ASC"
              ? SortDirection.Asc
              : SortDirection.Desc
        }
      ]
    },
    fetchPolicy: "cache-and-network"
  });
}

interface TransactionAllocation {
  amount?: Money | null;
  description?: string | null;
}

export interface TransactionRow {
  id: string;
  key: string;
  auditNumber: Number;
  type: string;
  referenceNumber: Number | undefined;
  createdAt: LocalDate;
  description: string | undefined;
  allocations: TransactionAllocation[] | undefined;
  managementId: string | undefined;
  propertyAddress: Address | undefined;
}

export { useGetTransactions };
