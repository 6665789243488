import { AiloSentry } from "@ailo/services";
import { useToastContext } from "@ailo/ui";
import { useDeletePropertyKeyMutation } from "local/graphql";
import { useCallback } from "react";
import { useKeyModalContext } from "../../PropertyKeyModalContext";
import { usePropertyKeyDeletedAnalytics } from "./usePropertyKeyDeletedAnalytics";

export function useDeletePropertyKey(
  keyId: string,
  keyCode: string
): {
  deletePropertyKey: (keyId: string) => Promise<void>;
  loading: boolean;
} {
  const toast = useToastContext();

  const { organisationAilornString } = useKeyModalContext();

  const { trackPropertyKeyDeleted } = usePropertyKeyDeletedAnalytics();

  const [deletePropertyKeyMutation, { loading }] = useDeletePropertyKeyMutation(
    {
      onCompleted: () => {
        trackPropertyKeyDeleted();

        toast.show({ message: `Key ${keyCode} removed`, type: "success" });
      },
      onError: (error) => {
        toast.show({
          message: `Unable to remove key ${keyCode}. Please try again.`,
          type: "error"
        });

        AiloSentry.captureException(error);

        throw new Error(error.message);
      }
    }
  );

  const deletePropertyKey = useCallback(async () => {
    await deletePropertyKeyMutation({
      variables: {
        input: {
          id: keyId
        },
        organisationAilorn: organisationAilornString
      }
    });
  }, [deletePropertyKeyMutation, keyId, organisationAilornString]);

  return { deletePropertyKey, loading };
}
