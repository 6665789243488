import { Colors, Text } from "@ailo/primitives";
import { Card, Button } from "@ailo/ui";
import { useLinkProps } from "@react-navigation/native";
import React, { ReactElement } from "react";
import { View } from "react-native";
import {
  Report,
  useReportSection
} from "../../VisualizationScreen/reportsDetails";
import { PopularReportWrapper } from "./ReportComponentWrappers";

function PopularReportCard({
  reportHeading,
  reportDescription,
  name
}: Report): ReactElement {
  const linkProps = useLinkProps({ to: `/reports/v2/${name}` });
  return (
    <Card
      style={{
        padding: 16,
        marginVertical: 0,
        marginHorizontal: 0,
        flex: 1,
        flexWrap: "nowrap",
        height: "fit-content"
      }}
    >
      <Text.BodyL weight={"medium"} style={{ marginBottom: 4 }}>
        {reportHeading}
      </Text.BodyL>
      <Text.BodyS
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginBottom: 16 }}
        numberOfLines={2}
        ellipsizeMode={"tail"}
      >
        {reportDescription}
      </Text.BodyS>
      <Button.Secondary {...linkProps}>
        <Text.BodyM weight={"medium"}>{"View"}</Text.BodyM>
      </Button.Secondary>
    </Card>
  );
}

export function PopularReportsSection(): ReactElement {
  const popularReportsList = useReportSection()
    .flatMap((section) => {
      return section.reportList;
    })
    .filter((report) => report.isPopular)
    .splice(0, 3)
    .map((report) => <PopularReportCard key={report.name} {...report} />);
  return (
    <View>
      <Text.DisplayS>{"Popular reports"}</Text.DisplayS>
      <PopularReportWrapper>{popularReportsList}</PopularReportWrapper>
    </View>
  );
}
