import { ClaimTenancyData } from "local/domain/bond";
import {
  formatAddress,
  formatLegalEntityName,
  formatNames
} from "@ailo/domain-helpers";
import { isPresent } from "ts-is-present";
import { DescriptionList } from "@ailo/ui";
import React from "react";
import { formatDate } from "@ailo/services";

type Props = Pick<ClaimTenancyData, "tenancyEndDate" | "property" | "tenants">;

export function TenancyInfo({
  tenancyEndDate,
  property,
  tenants
}: Props): React.ReactElement {
  const tenancyItems = [
    {
      label: "Property",
      description: formatAddress(property.address, {
        format: "street, suburb"
      })
    },
    {
      label: "Renters",
      description: formatNames(
        tenants.filter(isPresent).map((tenant) => formatLegalEntityName(tenant))
      )
    },
    {
      label: "Tenancy ended",
      description: tenancyEndDate ? formatDate(tenancyEndDate) : "—"
    }
  ];

  return (
    <DescriptionList
      size={"small"}
      items={tenancyItems}
      testID={"ClaimDetailsTenancyItems"}
    />
  );
}
