import { BusinessTxStatusEnum } from "local/graphql";

export interface WalletEntry {
  isReversal: boolean;
  isTopUpFee: boolean;
  amount: { cents: number };
  status: BusinessTxStatusEnum;
  liability?: { category: string; taxCategory: string } | null;
  paymentMethod?: object | null;
}

export interface TransactionStatus {
  name: string;
  type: "default" | "critical" | "success";
}

export function getWalletEntryTransactionStatus(
  transaction: WalletEntry
): TransactionStatus {
  if (transaction.isReversal) {
    return { name: "Reversed", type: "critical" };
  }

  switch (transaction.status) {
    case BusinessTxStatusEnum.Cleaned:
    case BusinessTxStatusEnum.Fail:
      return { name: "Failed", type: "critical" };
    case BusinessTxStatusEnum.Pending:
      return { name: "Pending", type: "default" };
    case BusinessTxStatusEnum.Success:
      return transaction.amount.cents > 0
        ? { name: "Received", type: "success" }
        : { name: "Transferred", type: "default" };
    default:
      throw new Error(
        "Unknown business transaction status: " + transaction.status
      );
  }
}
