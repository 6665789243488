import { AiloRN, services } from "@ailo/ailorn";
import { ScreenComponent, useAnalytics } from "@ailo/services";
import { useBillScreenAnalyticsPayload } from "local/domain/bill/sidebars/common";
import React, { useEffect } from "react";
import { ScrollView } from "react-native";
import styled from "styled-components/native";
import { useGetExpense } from "../graphql/useGetExpense";
import {
  ExpenseAttachments,
  ExpenseDetails,
  ExpenseDetailViewErrorBoundaryFallback,
  ExpenseHeader,
  ExpenseLineItems,
  ExpenseManagementFeeDescription
} from "./components";
import { ExpenseCancel } from "./components/ExpenseCancel";

interface ExpenseDetailViewProps {
  ailorn: AiloRN;
  onCancelExpensePress?: (ailorn: AiloRN) => void;
  onGoBack?: () => void;
  onClose?: () => void;
}

function ExpenseDetailViewInner({
  ailorn,
  onCancelExpensePress,
  onGoBack,
  onClose
}: ExpenseDetailViewProps): React.ReactElement | null {
  const analytics = useAnalytics();
  const analyticsPayload = useBillScreenAnalyticsPayload({
    billId: ailorn.isA(services.Bill.bill) ? ailorn.internalId : undefined
  });
  useEffect(() => {
    if (analyticsPayload && ailorn.isA(services.Bill.bill)) {
      analytics.trackScreenVisited("Bill", analyticsPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, analyticsPayload]);

  const { data: expense, loading } = useGetExpense({
    ailorn: ailorn
  });

  return (
    <StyledContainer testID={"ViewExpenseSidebarContent"}>
      <ExpenseHeader
        expense={expense}
        loading={loading}
        onGoBack={onGoBack}
        onClose={onClose}
      />
      <ExpenseDetails expense={expense} loading={loading} />
      <ExpenseLineItems expense={expense} loading={loading} />
      <ExpenseManagementFeeDescription expense={expense} loading={loading} />
      <ExpenseAttachments expense={expense} loading={loading} />
      <ExpenseCancel
        expense={expense}
        onCancelExpensePress={onCancelExpensePress}
        loading={loading}
      />
    </StyledContainer>
  );
}

export function ExpenseDetailView(
  props: ExpenseDetailViewProps
): React.ReactElement {
  return (
    <ScreenComponent
      name={"Expense"}
      analytics={false}
      errorBoundaryOptions={ExpenseDetailViewErrorBoundaryFallback(
        props.onClose
      )}
    >
      <ExpenseDetailViewInner {...props} />
    </ScreenComponent>
  );
}

const StyledContainer = styled(ScrollView)`
  padding-bottom: 60px;
`;
