import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { useCallback } from "react";
import {
  CloseProjectMutation,
  useCloseProjectMutation,
  GetManagementProjectsDocument,
  ProjectDetailsFragment
} from "local/graphql";

export function useCloseProject({
  onCompleted,
  onError
}: {
  onCompleted: ({ project }: { project: ProjectDetailsFragment }) => void;
  onError: () => void;
}): [
  (id: string, managementId?: string) => void,
  MutationResult<CloseProjectMutation>
] {
  const [closeProjectMutation, result] = useCloseProjectMutation();

  const closeProject = useCallback(
    async (id: string, managementId?: string): Promise<void> => {
      let result: ExecutionResult<CloseProjectMutation>;

      try {
        result = await closeProjectMutation({
          variables: { id },
          refetchQueries: managementId
            ? [
                {
                  query: GetManagementProjectsDocument,
                  variables: { managementId }
                }
              ]
            : []
        });
        throwIfMutationFailed(result, { dataKey: "closeProject" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }

      const closeProject = result.data?.closeProject;
      if (!closeProject) {
        throw new Error("Something went wrong, cannot close project");
      }
      onCompleted({ project: closeProject });
    },
    [closeProjectMutation, onCompleted, onError]
  );

  return [closeProject, result];
}
