import { DownloadIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import React, { useRef } from "react";
import { CSVLink } from "react-csv";
import { View } from "react-native";
import styled from "styled-components/native";
import { TableData } from "..";
import { FilterConfig } from "../ReportPage/FilterFactory";
import { ReportTableRow } from "../ReportPage/ReportTable";
import { useFilter } from "../ReportPage/utils";

export type DownloadButtonProps<TRow extends ReportTableRow = ReportTableRow> =
  {
    filterConfig?: FilterConfig<TRow>[];
    fileName: string;
    secondaryStyle?: boolean;
  } & TableData<TRow>;

const ReportDownloadButtonWrapper = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

export function ReportDownloadButton<TRow extends ReportTableRow>({
  fileName,
  columns,
  rows,
  filterConfig,
  secondaryStyle
}: DownloadButtonProps<TRow>): React.ReactElement {
  const filter = useFilter(filterConfig);
  const ref = useRef<View | null>(null); // using ref on the wrapping view as CSVLink doesn't

  const enabled =
    fileName != null &&
    fileName?.length > 0 &&
    rows != null &&
    columns != null &&
    columns.length > 0;

  const csvData = enabled
    ? [
        columns.map((c) => c.header),
        ...rows
          .filter(filter)
          .map((row) => columns.map((c) => String(row[c.key])))
      ]
    : null;

  const buttonProps = {
    leftIcon: DownloadIcon,
    disabled: !enabled,
    onPress: () => {
      const linkEl = (
        ref.current as any as HTMLElement
      )?.querySelector<HTMLAnchorElement>("a#csv-link");
      linkEl?.click();
    }
  };

  return (
    <ReportDownloadButtonWrapper ref={ref}>
      {enabled && (
        <CSVLink
          id={"csv-link"}
          style={{ display: "none" }}
          filename={fileName}
          data={csvData!}
          target={"_blank"}
        />
      )}

      {secondaryStyle ? (
        <Button.Secondary {...buttonProps}>{"Download"}</Button.Secondary>
      ) : (
        <Button.Text {...buttonProps}>{"Download"}</Button.Text>
      )}
    </ReportDownloadButtonWrapper>
  );
}
