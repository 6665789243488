import { AiloRN, services } from "@ailo/ailorn";
import { getPriceChangeDecription } from "@ailo/domains";
import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { MoneyInterface, useToastContext } from "@ailo/ui";
import {
  GetManagementRecurringFeesDocument,
  useUpdateRecurringFeeMutation,
  FeeFrequency
} from "local/graphql";
import { useCallback } from "react";

export interface UpdateRecurringFeeDetails {
  ailorn: AiloRN;
  managementId: string;
  futureSchedules?: { startDate: string; amount: MoneyInterface }[];
  description: string | null;
  blueprintId: string;
  blueprintAmount: MoneyInterface;
  frequency: FeeFrequency;
}

export function useUpdateRecurringFee(): [
  updateRecurringFee: (input: UpdateRecurringFeeDetails) => Promise<void>,
  loading: boolean
] {
  const analytics = useAnalytics();
  const toast = useToastContext();

  const [updateRecurringFeeMutation, { loading }] =
    useUpdateRecurringFeeMutation();

  const updateRecurringFee = useCallback(
    async ({
      ailorn,
      managementId,
      futureSchedules,
      description,
      blueprintId,
      blueprintAmount,
      frequency
    }: UpdateRecurringFeeDetails) => {
      if (loading) return;

      try {
        const result = await updateRecurringFeeMutation({
          variables: {
            input: {
              id: ailorn.toString(),
              futureSchedules: futureSchedules?.map((schedule) => ({
                taxAmount: {
                  cents: Math.round(schedule.amount.cents / 11)
                },
                taxInclusiveAmount: schedule.amount,
                startDate: schedule.startDate
              })),
              description
            }
          },
          refetchQueries: [
            {
              query: GetManagementRecurringFeesDocument,
              variables: {
                managementId,
                managementAiloRN: AiloRN.of(
                  services.PropertyManagement.management,
                  managementId
                ).toString()
              }
            }
          ],
          awaitRefetchQueries: true
        });
        throwIfMutationFailed(result, { dataKey: "updateRecurringFee" });

        toast.show({
          type: "success",
          message: "Fee has been updated"
        });

        analytics.track("Fee Edited on Property", {
          feeFrequency: frequency,
          feeBlueprintId: blueprintId,
          feePrice: getPriceChangeDecription({
            current: futureSchedules
              ? futureSchedules[0].amount
              : blueprintAmount,
            original: blueprintAmount
          })
        });
      } catch (error) {
        AiloSentry.captureException(error);
        toast.showFormSubmitError();
      }
    },
    [updateRecurringFeeMutation, toast, loading, analytics]
  );

  return [updateRecurringFee, loading];
}
