import React from "react";
import { View } from "react-native";
import { Button, ErrorAlertScreen, useGlobalModal } from "@ailo/ui";
import {
  didQuerySucceed,
  useInfinitelyPaginatedQuery,
  useOnFocus
} from "@ailo/services";
import { Screens, useRoute } from "local/common";
import {
  Colors,
  HouseWithGradient,
  Text,
  useSynchronisedLoad
} from "@ailo/primitives";
import styled from "styled-components/native";
import { useGetActionsQuery } from "./useGetActions";
import { isEmpty } from "lodash";
import { ActionsList } from "./ActionsList";
import { BaseActionCard } from "./BaseActionCard";
import { ActionModalForm } from "local/modals/ActionModalForm/ActionModalForm";

export function ActionsTab({
  tabNavigation
}: {
  tabNavigation: React.ReactElement;
}): React.ReactElement {
  return (
    <>
      {tabNavigation}
      <ActionsListContainer />
    </>
  );
}

function ActionsListContainer(): React.ReactElement {
  const { id } = useRoute<Screens.Project>().params!;

  const { loading, loadingMore, hasMore, data, refetch, fetchMore, error } =
    useInfinitelyPaginatedQuery(useGetActionsQuery, (data) => data.actions!, {
      variables: {
        projectId: id!,
        pageSize: 10
      }
    });
  useOnFocus(refetch);

  const actionItems = data?.actions?.items;

  const isLoading = useSynchronisedLoad(loading && !data);

  if (isLoading) {
    return (
      <LoadingContainer>
        <BaseActionCard.Loading />
        <BaseActionCard.Loading />
        <BaseActionCard.Loading />
      </LoadingContainer>
    );
  }

  if (!didQuerySucceed({ data, error })) {
    return (
      <ErrorAlertScreen
        variant={"sidebar"}
        title={"There was a problem loading the Actions"}
        onRetry={refetch}
        style={{ maxWidth: 576, marginRight: 60, width: "100%" }}
      />
    );
  }

  if (!actionItems || isEmpty(actionItems)) {
    return <EmptyState projectId={id!} />;
  }

  return (
    <Container>
      <ActionsList
        projectId={id!}
        loadingMore={loadingMore}
        onLoadMore={hasMore ? fetchMore : undefined}
        actions={actionItems}
      />
    </Container>
  );
}

function EmptyState({ projectId }: { projectId: string }): React.ReactElement {
  const modal = useGlobalModal();

  return (
    <View
      style={[
        {
          backgroundColor: Colors.WHITE,
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          maxWidth: 576,
          width: "100%",
          paddingRight: 60
        }
      ]}
    >
      <HouseWithGradient />
      <Text.BodyL weight={"medium"} style={{ marginTop: 16 }}>
        {"No Project Actions"}
      </Text.BodyL>
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY} style={{ marginTop: 8 }}>
        {"Add actions to your project"}
      </Text.BodyS>
      <Button
        style={{ marginTop: 20 }}
        onPress={() =>
          modal.show(
            <ActionModalForm projectId={projectId} onDismiss={modal.hide} />
          )
        }
      >
        {"Create action"}
      </Button>
    </View>
  );
}

const Container = styled(View)`
  width: 100%;
  flex: 1;
`;

const LoadingContainer = styled(View)`
  padding: 32px 64px 0 4px;
  max-width: 576px;
  width: 100%;
  flex: 1;
`;
