import { ClaimTenancyData } from "local/domain/bond";
import { useCallback } from "react";
import { useTotalAmountToClaim } from "../../useTotalAmountToClaim";

export function useTotalClaimedValid(data: ClaimTenancyData): () => boolean {
  const totalClaimed = useTotalAmountToClaim();

  return useCallback(() => {
    return totalClaimed.cents <= data.bond.amount.cents;
  }, [data, totalClaimed]);
}
