import React, { ReactElement, useEffect } from "react";
import {
  withScreenComponent,
  Breadcrumbs,
  LinkCrumb,
  Crumb
} from "@ailo/services";
import { Text } from "@ailo/primitives";
import { Button, useModalVisibility } from "@ailo/ui";
import styled from "styled-components/native";
import { LockButton, UnlockButton } from "./LockUnlockButton";
import { useReconciliationViewScreenData } from "./useReconciliationViewScreenData";
import { PageLayout } from "./PageLayout";
import { UnlockModal, LockModal } from "./LockUnlockModal";
import { ReconciliationPeriod } from "./types";
import { BankBalanceCard, BankBalanceCardLoading } from "./BankBalanceCard";
import {
  LedgerBalancesComponent,
  LedgerBalancesComponentLoading
} from "./LedgerBalancesComponent";
import { MonthYear, Screens, useTimezone, useRoute } from "local/common";
import { ScrollView } from "react-native";
import { monthNumberToName } from "../utils";
import {
  BadgeLoading,
  BalancedBadge,
  UnBalancedBadge
} from "local/tabs/trustaccounting/reconciliation/ReconciliationViewScreen/Badge";

export const ReconciliationViewScreen = withScreenComponent(
  (): ReactElement => {
    const reportMonthYear =
      useRoute<Screens.TrustReconciliationView>().params?.monthYear;
    const periodData = useReconciliationViewScreenData(reportMonthYear);

    if (!reportMonthYear) return <></>;

    const monthName = monthNumberToName(reportMonthYear.month);
    const periodDisplayName = `${monthName} ${reportMonthYear.year}`;

    const title = (
      <Text.DisplayM>{`${periodDisplayName} reconciliation`}</Text.DisplayM>
    );
    const breadcrumbs = (
      <Breadcrumbs>
        <ReconciliationLinkCrumb />
        <Crumb>{periodDisplayName}</Crumb>
      </Breadcrumbs>
    );

    if (periodData.loading)
      return <Loading title={title} breadcrumbs={breadcrumbs} />;
    return (
      <ScrollView>
        <ScreenWithData
          title={title}
          breadcrumbs={breadcrumbs}
          period={periodData}
        />
      </ScrollView>
    );
  }
);

function ScreenWithData({
  title,
  breadcrumbs,
  period
}: {
  title: ReactElement;
  breadcrumbs: ReactElement;
  period: ReconciliationPeriod;
}): ReactElement {
  const timezone = useTimezone();
  const {
    isVisible: isUnlockVisible,
    hide: hideUnlock,
    show: showUnlock
  } = useModalVisibility();
  const {
    isVisible: isLockVisible,
    hide: hideLock,
    show: showLock
  } = useModalVisibility();

  useEffect(() => {
    if (period.locked) {
      hideLock();
    } else {
      hideUnlock();
    }
  }, [hideLock, hideUnlock, period.locked]);

  return (
    <>
      <UnlockModal
        visible={isUnlockVisible && period.locked}
        period={period}
        onCancelPress={hideUnlock}
      />
      <LockModal
        visible={isLockVisible && !period.locked}
        period={period}
        onCancelPress={hideLock}
      />
      <PageLayout
        title={<Text.DisplayM>{title}</Text.DisplayM>}
        breadcrumbs={breadcrumbs}
        balancedBadge={
          period.isReconciling ? <BalancedBadge /> : <UnBalancedBadge />
        }
        lockButton={
          period.locked ? (
            <UnlockButton
              disabled={!period.canAlterLock}
              onPress={showUnlock}
            />
          ) : (
            <LockButton disabled={!period.canAlterLock} onPress={showLock} />
          )
        }
        bankAccountCard={<BankBalanceCard period={period} />}
        ledgerBalancesComponent={
          <LedgerBalancesComponent
            isReconciling={period.isReconciling}
            asOfDate={MonthYear.toEndOfMonthISOString(period, timezone)}
          />
        }
      />
    </>
  );
}

function Loading({
  title,
  breadcrumbs
}: {
  title: ReactElement;
  breadcrumbs: ReactElement;
}): ReactElement {
  return (
    <PageLayout
      title={title}
      breadcrumbs={breadcrumbs}
      balancedBadge={<BadgeLoading />}
      lockButton={<Button.Loading style={{ width: 105 }} />}
      bankAccountCard={<BankBalanceCardLoading />}
      ledgerBalancesComponent={<LedgerBalancesComponentLoading />}
    />
  );
}

const ReconciliationLinkCrumb = styled(LinkCrumb).attrs({
  link: { to: "/trust/reconciliation/" },
  children: "Reconciliations"
})``;
