import React, { FunctionComponentElement as FE, ReactElement } from "react";
import {
  BaseTextLoading,
  BaseTextProps,
  Colors,
  TextComponent,
  opacify,
  Text
} from "@ailo/primitives";
import { Card } from "@ailo/ui";
import { View } from "react-native";
import styled from "styled-components/native";

interface LinePortionCardProps {
  title: string;
  data?: { value: number; name: string }[];
  loading?: boolean;
  format?: (value: number) => string;
}

const SegmentRow = styled(View)`
  flex-direction: row;
`;

const barColor = (
  loading: boolean | undefined,
  allZeroValues: boolean,
  first: boolean
): string => {
  if (loading) return opacify(Colors.CHARCOAL, 0.075);
  if (allZeroValues) return opacify(Colors.OCEAN, 0.2); // when we have all 0 values we want a single subtle coloured line
  if (first) return Colors.OCEAN;
  return opacify(Colors.OCEAN, 0.2);
};

export function LinePortionCard({
  title,
  loading,
  ...props
}: LinePortionCardProps): FE<LinePortionCardProps> {
  const data = props.data ?? [
    { value: 0, name: "" },
    { value: 0, name: "" }
  ];
  const allZeroValues = data.every((d) => d.value === 0);
  const format = props.format ?? ((v) => v?.toString());
  const segmentProps = { loading, format, allZeroValues };

  const portions = data.map((portion, i) => {
    const first = i == 0;
    const last = i == data.length - 1;
    const key = i;
    return { ...portion, first, last, key };
  });

  return (
    <Card
      style={{
        borderRadius: 4,
        shadowColor: opacify(Colors.BLACK, 0.2),
        shadowOffset: { height: 1, width: 0 },
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 16,
        marginTop: 0
      }}
      innerStyle={{
        paddingHorizontal: 16,
        paddingTop: 16,
        paddingBottom: 20
      }}
      contentStyle={{
        flexDirection: "column",
        justifyContent: "flex-start"
      }}
    >
      <Text.BodyL color={Colors.TEXT.DARK.PRIMARY} weight={"medium"}>
        {title}
      </Text.BodyL>
      <SegmentRow>
        {portions.map((p) => (
          <TextSegment
            {...segmentProps}
            {...p}
            key={p.key}
            label={format(p.value)}
            LoadElement={Text.DisplayM.Loading}
            TextElement={Text.DisplayM}
          />
        ))}
      </SegmentRow>
      <SegmentRow>
        {portions.map(({ first, last, value, key }) => (
          <Segment key={key} type={"bar"} {...segmentProps} value={value}>
            <View
              style={{
                backgroundColor: barColor(loading, allZeroValues, first),
                height: "12px",
                width: "100%",
                borderTopLeftRadius: first ? 4 : 0,
                borderBottomLeftRadius: first ? 4 : 0,
                borderTopRightRadius: last ? 4 : 0,
                borderBottomRightRadius: last ? 4 : 0
              }}
            />
          </Segment>
        ))}
      </SegmentRow>
      <SegmentRow>
        {portions.map((p) => (
          <TextSegment
            {...segmentProps}
            {...p}
            key={p.key}
            label={p.name}
            LoadElement={Text.BodyS.Loading}
            TextElement={Text.BodyM}
            color={opacify(Colors.CHARCOAL, 0.6)}
          />
        ))}
      </SegmentRow>
    </Card>
  );
}

type LabelSegmentProps = Omit<SegmentProps, "children" | "type"> & {
  loading?: boolean;
  LoadElement: BaseTextLoading;
  TextElement: TextComponent<BaseTextProps>;
  value: number;
  label: string;
  last: boolean;
  color?: string;
};

function TextSegment({
  loading,
  value,
  label,
  last,
  LoadElement,
  TextElement,
  color,
  allZeroValues
}: LabelSegmentProps): FE<LabelSegmentProps> {
  return (
    <Segment allZeroValues={allZeroValues} value={value} type={"label"}>
      {loading ? (
        <LoadElement
          style={{
            alignSelf: last ? "flex-end" : "flex-start"
          }}
        />
      ) : (
        <TextElement
          style={{
            textAlign: last ? "right" : "left",
            marginTop: 4,
            marginBottom: 4,
            overflow: "visible"
          }}
          numberOfLines={1}
          color={color}
        >
          {label}
        </TextElement>
      )}
    </Segment>
  );
}

interface SegmentProps {
  value: number;
  allZeroValues?: boolean;
  children: ReactElement;
  type: "bar" | "label";
}

function Segment({
  value,
  allZeroValues,
  children,
  type
}: SegmentProps): FE<SegmentProps> {
  return (
    <View
      style={{
        flexBasis: type == "bar" ? 0 : "fit-content",
        flexGrow: allZeroValues ? 1 : value,
        flexDirection: "column"
      }}
    >
      {children}
    </View>
  );
}
