import { BaseModal } from "@ailo/ui";
import React from "react";
import {
  ManagementNoteForm,
  ManagementNoteFormProps
} from "./ManagementNoteForm";

export function ManagementNoteModalForm(
  props: Omit<ManagementNoteFormProps, "onFormSubmit"> & {
    visible: boolean;
  }
): React.ReactElement {
  const {
    onDismiss,
    managementId,
    managementNoteId,
    note,
    visible,
    onSuccess
  } = props;
  return (
    <BaseModal
      title={"Notes"}
      showFooterBorder={false}
      visible={visible}
      headerAndContentContainerStyle={{ paddingBottom: 0 }}
    >
      <ManagementNoteForm
        onDismiss={onDismiss}
        managementId={managementId}
        managementNoteId={managementNoteId}
        note={note}
        onSuccess={onSuccess}
        visible={visible}
      />
    </BaseModal>
  );
}
