import { formatRecurringDateFrequency } from "@ailo/date";
import {
  feeMaxMoney,
  FormField,
  Money,
  MoneyInput,
  MoneyInterface
} from "@ailo/ui";
import {
  CreateOneOffFeeFormData,
  CreateRecurringFeeFormData
} from "local/domain/expense";
import { TaxTreatment } from "local/graphql";
import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ViewStyle } from "react-native";

interface Props {
  onChange?: (price: MoneyInterface | null) => void;
  style?: ViewStyle;
}

export function PriceInput({
  onChange: onChangeProp,
  style
}: Props): ReactElement {
  const { watch, control, errors, setValue } = useFormContext<
    CreateOneOffFeeFormData & CreateRecurringFeeFormData
  >();
  const feeBlueprint = watch("feeBlueprint");
  const {
    priceIncludingTax: defaultPriceIncludingTax,
    frequency,
    taxTreatment
  } = feeBlueprint;

  useEffect(() => {
    // `defaultValue` passed to the `Controller` don't get seen by `watch` so use `setValue` instead
    // https://github.com/react-hook-form/react-hook-form/issues/1943#issue-643902427
    setValue("totalAmount", defaultPriceIncludingTax);
  }, [defaultPriceIncludingTax, setValue]);

  return (
    <Controller
      control={control}
      name={"totalAmount"}
      defaultValue={null}
      render={({ value, onChange, onBlur }) => (
        <FormField
          label={"Price (incl. GST)"}
          helperText={
            taxTreatment === TaxTreatment.NoTax
              ? "No GST"
              : value
              ? `GST Included (${Money.from(value).divide(11).format()})`
              : undefined
          }
          error={(errors.totalAmount as { message: string })?.message}
          style={style}
        >
          <MoneyInput
            {...{ value, onBlur }}
            onChange={(value) => {
              onChange(value);
              onChangeProp?.(value);
            }}
            endAdornment={
              frequency &&
              formatRecurringDateFrequency(frequency, { type: "/FF" })
            }
            endAdornmentWidth={28}
            withTrailingZeros
          />
        </FormField>
      )}
      rules={{
        required: "Enter price",
        validate: (price): boolean | string =>
          price.cents < 0
            ? `Price can't be less than ${Money.zero().format()}.`
            : price.cents > feeMaxMoney.cents
            ? `Price can't be more than ${feeMaxMoney.format()}.`
            : true
      }}
    />
  );
}
