import { AiloRN, services } from "@ailo/ailorn";
import { ProjectType, useCanCreateProjectOfTypeQuery } from "local/graphql";

export function useCanCreateProject(
  managementId: string,
  type: ProjectType
): {
  canCreateNewProject: boolean;
  reason?: string;
  loading?: boolean;
} {
  if (!managementId) {
    throw new Error(
      "New project cannot be created if management isn't specified."
    );
  }
  const { data, error, loading } = useCanCreateProjectOfTypeQuery({
    variables: {
      managementAilorn: AiloRN.of(
        services.PropertyManagement.management,
        managementId
      ).toString(),
      type: ProjectType[type]
    },
    fetchPolicy: "network-only"
  });

  if (loading) {
    return {
      canCreateNewProject: false,
      loading: true
    };
  }

  const ableToCreate = data?.canCreateProjectOfType?.ableToCreate;

  if (error || ableToCreate == null) {
    return {
      canCreateNewProject: false,
      reason: "An error has occurred, please reload the page and try again"
    };
  }

  return {
    canCreateNewProject: ableToCreate,
    reason: data?.canCreateProjectOfType?.notAbleToCreateReason ?? undefined
  };
}
