import { AutoPayIcon, Colors, Text } from "@ailo/primitives";
import { formatPercentage, ListItem, ListItemStyle } from "@ailo/ui";
import { GetManagementRecurringFeesQuery } from "local/graphql";
import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import styled from "styled-components/native";
import { FeeItemMenu } from "./FeeItemMenu";

type ManagementFee = NonNullable<
  NonNullable<
    GetManagementRecurringFeesQuery["management"]
  >["currentOrNextManagementFeeSchedule"]
>;

interface ManagementFeeListItemProps {
  fee: ManagementFee;
  onPress?: () => void;
  onEditPress?: () => void;
  style?: ListItemStyle;
}

export function ManagementFeeListItem({
  fee,
  onEditPress,
  style
}: ManagementFeeListItemProps): ReactElement | null {
  return (
    <ListItem
      testID={undefined}
      header={"Management fee"}
      subHeader={
        <View
          style={{
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <AutoPayIcon
            width={16}
            height={16}
            color={Colors.TEXT.DARK.SECONDARY}
          />
          <Text.BodyS
            color={Colors.TEXT.DARK.SECONDARY}
            style={{ marginLeft: 6 }}
          >
            {"When rent is paid"}
          </Text.BodyS>
        </View>
      }
      rightComponent={
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <AmountAndTaxContainer>
            <Text.BodyM style={{ textAlign: "right" }} weight={"medium"}>
              {formatPercentage(fee.percent)}
            </Text.BodyM>
            <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
              {"incl. GST"}
            </Text.BodyS>
          </AmountAndTaxContainer>
          <FeeItemMenu onEditFeePress={onEditPress} />
        </View>
      }
      style={StyleSheet.flatten([{ paddingRight: 32 }, style])}
    />
  );
}

ManagementFeeListItem.Loading = function Loading({
  color,
  style
}: {
  color?: string;
  style?: ListItemStyle;
}) {
  return (
    <ListItem
      style={{
        verticalAlignCenter: "top",
        verticalAlignRight: "top",
        ...style
      }}
      header={<Text.BodyM.Loading width={120} color={color} />}
      subHeader={<Text.BodyS.Loading width={80} color={color} />}
    />
  );
};

const AmountAndTaxContainer = styled(View)`
  align-items: flex-end;
  justify-content: space-between;
  margin-right: 12px;
`;
