import React, { useCallback, Dispatch, SetStateAction } from "react";
import { GridColumnOptions } from "@ailo/ui";
import { UnscheduledTenanciesListLoadingRow } from "./TenanciesGrid";
import {
  CheckboxCell,
  DueDateCell,
  LastInspectionCell,
  PropertyCell
} from "../Cells";
import { UnscheduledTenanciesListRow } from "../useGetUnscheduledTenanciesRows";

export enum UnscheduledTenanciesColumns {
  Property = "Property",
  LastInspection = "Last inspection",
  InspectionDue = "Inspection due"
}

export function useGetTenanciesGridColumns({
  selectedRowIds,
  setSelectedRowIds
}: {
  selectedRowIds: string[];
  setSelectedRowIds: Dispatch<SetStateAction<string[]>>;
}): Array<
  GridColumnOptions<UnscheduledTenanciesListRow> & {
    renderLoadingCell: GridColumnOptions<UnscheduledTenanciesListLoadingRow>["renderCell"];
  }
> {
  const onRowSelected = useCallback(
    (key: string) => {
      setSelectedRowIds([...selectedRowIds, key]);
    },
    [selectedRowIds, setSelectedRowIds]
  );

  const onRowUnselected = useCallback(
    (key: string) => {
      setSelectedRowIds(selectedRowIds.filter((row) => row != key));
    },
    [selectedRowIds, setSelectedRowIds]
  );

  const CheckboxCellRenderer = useCallback(
    ({ row }: { row: UnscheduledTenanciesListRow }) => {
      return (
        <CheckboxCell
          isSelected={
            selectedRowIds.find((selectedRow) => selectedRow === row.key) !=
            null
          }
          onChange={(nextValue: boolean) => {
            if (nextValue) {
              onRowSelected(row.key);
            } else {
              onRowUnselected(row.key);
            }
          }}
        />
      );
    },
    [onRowSelected, onRowUnselected, selectedRowIds]
  );

  return [
    {
      maxWidth: "32px",
      renderHeaderCell: function EmptyHeaderCell() {
        return <></>;
      },
      renderCell: CheckboxCellRenderer,
      renderLoadingCell: CheckboxCell.Loading,
      name: "checkbox",
      sortable: false,
      verticalAlign: "middle"
    },
    {
      width: 2,
      renderCell: PropertyCell,
      renderLoadingCell: PropertyCell.Loading,
      name: UnscheduledTenanciesColumns.Property
    },
    {
      width: 1,
      renderCell: LastInspectionCell,
      renderLoadingCell: LastInspectionCell.Loading,
      name: UnscheduledTenanciesColumns.LastInspection,
      verticalAlign: "middle"
    },
    {
      width: 1,
      renderCell: DueDateCell,
      renderLoadingCell: DueDateCell.Loading,
      name: UnscheduledTenanciesColumns.InspectionDue,
      verticalAlign: "middle",
      caretPosition: "right",
      sortable: true
    }
  ];
}
