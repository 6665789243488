import { Button } from "@ailo/ui";
import { Screens, useNavigation } from "local/common";
import { useCurrentPropertyPortfolioAilorn } from "local/tabs/properties/components/PropertyScreen/context";
import React, { ReactElement } from "react";

/**
 * Temporary button to access portfolio page from property.
 * Metapod TODO: update or remove when actually building access to portfolio pages based on agreed design.
 */
export function OpenCurrentManagementPortfolioButton(): ReactElement | null {
  const navigation = useNavigation();
  const portfolioAilorn = useCurrentPropertyPortfolioAilorn();
  if (!portfolioAilorn) {
    return null;
  }
  const onPress = (): void => {
    navigation.navigate(Screens.Portfolio, {
      portfolioId: portfolioAilorn.internalId
    });
  };
  return (
    <Button.Text onPress={onPress}>{"[Dev only] Open portfolio"}</Button.Text>
  );
}
