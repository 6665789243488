import { didQueryNotLoadYet, didQuerySucceed } from "@ailo/services";
import { ErrorAlertScreen } from "@ailo/ui";
import { useNavigation } from "@react-navigation/native";
import { Screens } from "local/common";
import { ThumbnailStatus, useGetProjectDetailsQuery } from "local/graphql";
import React, { useEffect } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { ProjectDetails } from "..";
import { ProjectScreenHeader } from "../ProjectScreenHeader";
import { BackButton } from "../ProjectScreenHeader/BackButton";

export function ProjectScreenLeftSection({
  id,
  setProjectFilesOpen
}: {
  id: string;
  setProjectFilesOpen: (isFileOpen: boolean) => void;
}): React.ReactElement {
  const navigation = useNavigation();
  const result = useGetProjectDetailsQuery({
    variables: {
      id
    }
  });
  const project = result?.data?.project;

  useEffect(() => {
    if (didQuerySucceed(result)) {
      const projectFiles = project!.files;
      if (
        !projectFiles ||
        !projectFiles.items.some(
          (file) => file.file?.thumbnailStatus === ThumbnailStatus.Generating
        )
      ) {
        result.stopPolling();
      } else {
        result.startPolling(5000);
      }
    }
  }, [project, result]);

  if (didQueryNotLoadYet(result)) {
    return <ProjectScreenLeftSection.Loading />;
  }

  if (!didQuerySucceed(result)) {
    result.stopPolling();

    const onPress = (): void => {
      navigation.navigate(Screens.PropertiesTab);
    };

    return (
      <ProjectSection>
        <HeaderContainer style={{ marginBottom: 24 }}>
          <BackButton onPress={onPress} />
        </HeaderContainer>
        <ErrorAlertScreen
          variant={"sidebar"}
          title={"There was a problem loading the Project Details"}
          onRetry={result.refetch}
          style={{
            width: 480,
            marginLeft: -60
          }}
        />
      </ProjectSection>
    );
  }

  if (!project) {
    throw new Error("Something went wrong, project is null");
  }

  return (
    <ProjectSection>
      <ProjectScreenHeader style={{ marginBottom: 24 }} project={project} />
      <ProjectDetails
        style={{ flex: 1, width: "100%" }}
        project={project}
        openFileTab={() => setProjectFilesOpen(true)}
      />
    </ProjectSection>
  );
}

ProjectScreenLeftSection.Loading = function ProjectScreenLeftSectionLoading() {
  return (
    <ProjectSection>
      <ProjectScreenHeader.Loading style={{ marginBottom: 24 }} />
      <ProjectDetails.Loading style={{ flex: 1 }} />
    </ProjectSection>
  );
};

const ProjectSection = styled(View)`
  max-width: 360px;
  width: 100%;
  flex: 1;
  align-items: flex-start;
  align-self: flex-end;
  padding-bottom: 60px;
`;

const HeaderContainer = styled(View)`
  height: 32px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;
