import React from "react";
import { DropdownMenuOption } from "@ailo/ui";
import { CancelFormIcon } from "@ailo/primitives";
import { RecoverableError } from "@ailo/services";
import { useGetIngoingTenancyIsVoidable } from "./useGetIngoingTenancyIsVoidable";
import { PlatformFeatureId, UnvoidableTenancyReason } from "local/graphql";
import { useHasFeature } from "@ailo/domains";

export function useGetVoidIngoingTenancyOption(
  tenancyId: string,
  onVoidPress?: () => void
): DropdownMenuOption | undefined {
  const {
    data: voidTenancyAbility,
    loading,
    error,
    refetch
  } = useGetIngoingTenancyIsVoidable(tenancyId);

  const hasVoidIngoingTenancy = useHasFeature(
    PlatformFeatureId.VoidIngoingTenancy
  );

  if (!hasVoidIngoingTenancy) {
    return undefined;
  }

  if (!onVoidPress) {
    return undefined;
  }

  if (loading && !voidTenancyAbility) {
    return undefined;
  }
  if (error || !voidTenancyAbility) {
    throw new RecoverableError(
      "Failed to fetch tenancy voiding ability",
      refetch
    );
  }

  const { voidable, unvoidableReason } = voidTenancyAbility;

  const baseOption = {
    label: "Void ingoing tenancy",
    icon: <CancelFormIcon />,
    onSelect: (): void => onVoidPress?.()
  };
  if (voidable) return baseOption;

  if (unvoidableReason && DISABLED_TOOLTIPS[unvoidableReason]) {
    return {
      ...baseOption,
      disabled: true,
      tooltip: DISABLED_TOOLTIPS[unvoidableReason]
    };
  }
}

const DISABLED_TOOLTIPS = {
  [UnvoidableTenancyReason.TenancyIsNotIngoing]: undefined,
  [UnvoidableTenancyReason.DepositHasBeenPaid]:
    "The initial deposit has been made - please contact Support"
};
