import { useMountedState } from "@ailo/primitives";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { MoveManagementToTeamConfirmModal } from "./MoveManagementToTeamConfirmModal";
import { TeamDropdownRow } from "./TeamDropdownRow";

interface Props {
  allTeams: { id: string; name: string; myTeam: boolean }[];
  assignedTeamId?: string;
  managementId: string;
  managementName: string;
  style?: StyleProp<ViewStyle>;
}

interface ModalInfo {
  teamId: string;
  teamName: string;
  managementId: string;
  managementName: string;
}

export function ManagementTeamSelector({
  allTeams,
  assignedTeamId,
  managementId,
  managementName,
  style
}: Props): React.ReactElement {
  const [modalInfo, setModalInfo] = useMountedState<ModalInfo>();

  return (
    <>
      <TeamDropdownRow
        allTeams={allTeams}
        assignedTeamId={assignedTeamId}
        onTeamChange={(teamDetails): void =>
          setModalInfo({ managementId, managementName, ...teamDetails })
        }
        style={style}
      />
      <MoveManagementToTeamConfirmModal
        data={modalInfo}
        onDismiss={(): void => setModalInfo(undefined)}
      />
    </>
  );
}

ManagementTeamSelector.Error = TeamDropdownRow.Error;
ManagementTeamSelector.Loading = TeamDropdownRow.Loading;
