import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";
import { formatDate, useEnvironment } from "@ailo/services";
import moment from "moment-timezone";
import { DepositStatus } from "../mapDepositStatus";
import { ExternalLink } from "./ExternalLink";
import { DepositData } from "local/domain/propertyManagement";

type Props = {
  deposit?: DepositData;
  isFormerTenancy?: boolean;
};

const DepositSummaryDescription = ({
  deposit,
  isFormerTenancy
}: Props): ReactElement => {
  const env = useEnvironment();

  return (
    <Container>
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
        {getDescription({
          deposit,
          contactUsUrl: env.AILO_CONTACT_US_HELP_URL,
          isFormerTenancy
        })}
      </Text.BodyS>
    </Container>
  );
};

function getDescription({
  deposit,
  contactUsUrl,
  isFormerTenancy
}: {
  deposit?: DepositData;
  contactUsUrl: string;
  isFormerTenancy?: boolean;
}): string | React.ReactElement | undefined {
  if (!deposit) {
    return `Please set up the tenancy and return to this action`;
  }
  const { displayStatus } = deposit;

  switch (displayStatus) {
    case DepositStatus.Unpaid:
    case DepositStatus.Cancelled:
      return `Renters have been invited to download and log in to Ailo to make payment`;
    case DepositStatus.Pending:
      return getPendingDescription(deposit);
    case DepositStatus.Cleared:
    case DepositStatus.Released:
      return getClearedDescription(isFormerTenancy, deposit, contactUsUrl);
    case DepositStatus.Failed:
      return `Please contact the renter to try again`;
  }
}

function getPendingDescription(deposit: DepositData): string {
  if (deposit.expectedClearedAt) {
    return (
      getPaidOnDescription(deposit) +
      "Estimated to clear by " +
      moment(deposit.expectedClearedAt).format("hh:mma, DD MMM yyyy")
    );
  }

  return (
    (getPaidOnDescription(deposit) || "Initial payment made. ") +
    "Estimated to clear in 1-2 business days"
  );
}

function getClearedDescription(
  isFormerTenancy: boolean | undefined,
  deposit: DepositData,
  contactUsUrl: string
): string | React.ReactElement {
  /* eslint-disable */
  return isFormerTenancy ? (
    <>
      {getPaidOnDescription(deposit) + getClearedAtDescription(deposit)}
      <br />
      To transfer or refund,{" "}
      <ExternalLink url={contactUsUrl}>contact us</ExternalLink>
    </>
  ) : (
    `${getPaidOnDescription(deposit)}${getClearedAtDescription(deposit)}
This will automatically be transferred to the investor’s property wallet \
on the tenancy start date: ${formatDate(deposit.tenancyStartDate)}. \
Agency fees will be deducted upon transfer.`
  );
  /* eslint-enable */
}

function getPaidOnDescription(deposit: DepositData): string {
  return deposit.paidAt ? `Paid on ${formatDate(deposit.paidAt)}. ` : "";
}

function getClearedAtDescription(deposit: DepositData): string {
  return deposit.clearedAt
    ? `Cleared on ${formatDate(deposit.clearedAt)}. `
    : "";
}

const Container = styled(View)`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
`;

export { DepositSummaryDescription, getDescription, getPendingDescription };
