import { formatAddress } from "@ailo/domain-helpers";
import { Text } from "@ailo/primitives";
import React, { ReactElement } from "react";
import {
  ApprovedMigratingManagementFragment,
  AwaitingApprovalMigratingManagementFragment,
  ExcludedMigratingManagementFragment,
  LiveMigratingManagementFragment
} from "local/graphql";
import { CellContainer, TextCell } from "@ailo/ui";

function AddressCell({
  row: { body }
}: {
  row:
    | AwaitingApprovalMigratingManagementFragment
    | ExcludedMigratingManagementFragment
    | ApprovedMigratingManagementFragment
    | LiveMigratingManagementFragment;
  rowIndex: number;
}): ReactElement {
  const formattedAddress = formatAddress(body.management?.property, {
    format: "street, suburb"
  });

  return (
    <TextCell weight={"medium"} TextComponent={Text.BodyM}>
      {formattedAddress}
    </TextCell>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <Text.BodyM.Loading width={80} />
    </CellContainer>
  );
}

AddressCell.Loading = Loading;

export { AddressCell };
