import React from "react";
import { View, Image } from "react-native";
import styled from "styled-components/native";
import {
  Text,
  Colors,
  LeftChevronIcon,
  AiloHouses,
  IosAppStore,
  GooglePlayStore
} from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { useAuth, useEnvironment, withScreenComponent } from "@ailo/services";
import { openURL } from "expo-linking";

export const RecommendConsumerAppScreen = withScreenComponent(
  (): React.ReactElement => {
    const { logout } = useAuth();
    const envVars = useEnvironment();

    return (
      <>
        <BackButton
          leftIcon={LeftChevronIcon}
          onPress={(): void => {
            logout();
          }}
        >
          {"Back to Login"}
        </BackButton>
        <InnerContainer>
          <Text.DisplayS
            color={Colors.TEXT.DARK.PRIMARY}
            style={{ textAlign: "center" }}
          >
            {"Oops, looks like you"}&apos;{"ve logged into"}
            {"\n"}
            {"the web app for property managers."}
          </Text.DisplayS>

          <Text.BodyM style={{ marginTop: 8, textAlign: "center" }}>
            {"If you"}&apos;{"re a renter or property investor, please"}
            {"\n"}
            {"install the correct Ailo app on your phone."}
          </Text.BodyM>

          <ImageContainer>
            <Button.Text
              onPress={(): void => {
                openURL(envVars.CONSUMER_APP_IOS_STORE_URL);
              }}
            >
              <Image source={IosAppStore} style={{ width: 120, height: 40 }} />
            </Button.Text>
            <Button.Text
              onPress={(): void => {
                openURL(envVars.CONSUMER_APP_GOOGLE_PLAY_URL);
              }}
              style={{ marginLeft: 14 }}
            >
              <Image
                source={GooglePlayStore}
                style={{ width: 135, height: 40 }}
              />
            </Button.Text>
          </ImageContainer>

          <ButtonTextContainer>
            <Button.Text
              onPress={(): void => {
                logout();
              }}
            >
              <Text.BodyL weight={"medium"}>
                {"Try again with a different account"}
              </Text.BodyL>
            </Button.Text>
          </ButtonTextContainer>
        </InnerContainer>
        <FooterImage source={AiloHouses} />
      </>
    );
  }
);

const InnerContainer = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ButtonTextContainer = styled(View)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 28px;
`;

const BackButton = styled(Button.Text)`
  margin-top: 42px;
  margin-left: 53px;
  align-self: flex-start;
`;

const ImageContainer = styled(View)`
  margin-top: 24px;
  flex-direction: row;
`;

const FooterImage = styled(Image)`
  align-self: center;
  width: 460px;
  height: 150px;
`;
