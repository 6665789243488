import { TextInputFormField, validateMaxLength } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ActionFormData } from "../ActionFormData";

export function ActionNameField({
  onFieldUpdated
}: {
  onFieldUpdated(fieldName: keyof ActionFormData): void;
}): ReactElement {
  const { control, errors } = useFormContext<ActionFormData>();

  return (
    <Controller
      control={control}
      name={"name"}
      defaultValue={null}
      render={({ value, onChange, onBlur }) => (
        <TextInputFormField
          {...{ value, onChange, onBlur }}
          label={"Name"}
          error={errors.name?.message}
          onBlur={() => onFieldUpdated("name")}
          softCharacterLimit={{
            limit: MAX_NAME_LENGTH,
            enforcementLevel: "error"
          }}
        />
      )}
      rules={{
        required: "Please enter an action name",
        validate: validateMaxLength(MAX_NAME_LENGTH)
      }}
    />
  );
}

ActionNameField.Loading = function Loading() {
  return (
    <TextInputFormField.Loading labelWidth={60} style={{ marginBottom: 20 }} />
  );
};

const MAX_NAME_LENGTH = 60;
