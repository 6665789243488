import { StickyBottom } from "@ailo/ui";
import React, { ReactElement } from "react";
import { StyleProp, ViewStyle, View } from "react-native";
import { TabContent } from "./TabContent";

interface Props {
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
}

export function TabContentStickyBottom({
  style,
  children
}: Props): ReactElement {
  return (
    <View
      style={[
        {
          position: "sticky" as any,
          bottom: 0,
          marginTop: TabContent.padding,
          marginHorizontal: -TabContent.padding
        },
        style
      ]}
    >
      <StickyBottom style={{ paddingVertical: 20, paddingHorizontal: 32 }}>
        {children}
      </StickyBottom>
    </View>
  );
}
