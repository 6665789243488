import { useHasFeature } from "@ailo/domains";
import { SearchBoxOption, SelectOption } from "@ailo/ui";
import { ApolloError } from "@apollo/client";
import { PlatformFeatureId } from "local/graphql";
import { useMemo } from "react";
import {
  formatAgencyPropertyToSearchBoxOption,
  formatPropertyToSearchBoxOption
} from "../../../main/AppNavigator/ScreenHeader/GlobalSearchBox";
import { ActivePropertiesQuery, AgencyPropertiesQuery } from "../graphql";
import { useGetAssociatedManagementOption } from "./useGetAssociatedManagementOption";
import { useGetSearchPropertiesCallback } from "./useGetSearchPropertiesCallback";
import { useSearchAgencyPropertiesCallback } from "./useSearchAgencyPropertiesCallback";

function isValidOption(option?: { value?: string; label?: string }): boolean {
  return !!(option?.label?.trim() && option?.value?.trim());
}

export function usePropertyOrAgencyPropertySearch(): {
  searchHandler: (queryString: string) => void;
  options?: SearchBoxOption[];
  error?: ApolloError;
  data?: ActivePropertiesQuery | AgencyPropertiesQuery;
  transformToSelectedManagementOption: (
    selectedOption?: SelectOption
  ) => Promise<SelectOption | undefined>;
} {
  const hasFeaturePropertySearchFromSearchService = useHasFeature(
    PlatformFeatureId.PropertySearchFromSearchService
  );

  const {
    searchProperties,
    data: propertiesData,
    error: propertiesError
  } = useGetSearchPropertiesCallback();

  const propertyOptions = useMemo(
    () =>
      propertiesData?.properties
        .map(formatPropertyToSearchBoxOption)
        .filter(isValidOption) || [],
    [propertiesData]
  );

  const {
    searchAgencyProperties,
    data: agencyPropertiesData,
    error: agencyPropertiesError
  } = useSearchAgencyPropertiesCallback();

  const agencyPropertyOptions = useMemo(
    () =>
      agencyPropertiesData?.agencyProperties
        .map(formatAgencyPropertyToSearchBoxOption)
        .filter(isValidOption) || [],
    [agencyPropertiesData]
  );

  const transformToSelectedManagementOption =
    useGetAssociatedManagementOption();

  const payload = useMemo(
    () =>
      hasFeaturePropertySearchFromSearchService
        ? {
            searchHandler: searchAgencyProperties,
            error: agencyPropertiesError,
            options: agencyPropertyOptions,
            data: agencyPropertiesData,
            transformToSelectedManagementOption
          }
        : {
            searchHandler: searchProperties,
            error: propertiesError,
            options: propertyOptions,
            data: propertiesData,
            transformToSelectedManagementOption
          },
    [
      hasFeaturePropertySearchFromSearchService,
      agencyPropertyOptions,
      agencyPropertiesData,
      agencyPropertiesError,
      propertiesData,
      propertiesError,
      propertyOptions,
      searchAgencyProperties,
      searchProperties,
      transformToSelectedManagementOption
    ]
  );

  return payload;
}
