import { AiloRN, services } from "@ailo/ailorn";
import {
  didQueryNotLoadYet,
  didQuerySucceed,
  useAnalytics,
  useOnFocus
} from "@ailo/services";
import { ErrorAlertScreen } from "@ailo/ui";
import { ExpensesList, ExpensesSectionList } from "local/domain/expense";
import {
  TenancySwitcher,
  useGetManagementTenancies
} from "local/domain/propertyManagement";
import { useGetMostRecentTenancyIdQuery } from "local/graphql";
import React, { ReactElement, useEffect, useState } from "react";

interface TenancyTabContentProps {
  initialTenancyId?: string;
  managementAilorn: AiloRN;
  itemsLimit?: number;
  onExpensePress?: (ailorn: AiloRN) => void;
}

export function TenancyTabContent({
  initialTenancyId,
  managementAilorn,
  itemsLimit = 1000,
  onExpensePress
}: TenancyTabContentProps): ReactElement {
  const managementId = managementAilorn.internalId;
  const managementAilornString = managementAilorn.toString();

  const mostRecentTenancyResult = useGetMostRecentTenancyIdQuery({
    variables: { managementId }
  });
  const tenanciesResult = useGetManagementTenancies(managementId);
  const [tenancyId, setTenancyId] = useState<string | undefined>(
    initialTenancyId
  );

  const mostRecentTenancyId =
    mostRecentTenancyResult.data?.management?.mostRecentTenancy?.id;

  useEffect(() => {
    if (!initialTenancyId && mostRecentTenancyId) {
      setTenancyId(mostRecentTenancyId);
    }
  }, [initialTenancyId, mostRecentTenancyId]);

  const analytics = useAnalytics();
  useEffect(() => {
    if (!tenancyId) return;

    const screenName = "Tenancy Expenses";
    const analyticsPayload = {
      managementId: managementAilornString,
      tenancyId: AiloRN.of(
        services.PropertyManagement.tenancy,
        tenancyId
      ).toString()
    };
    analytics.trackScreenVisited(screenName, analyticsPayload);
  }, [analytics, tenancyId, managementAilornString]);

  useOnFocus(mostRecentTenancyResult.refetch, { skipInitialFocus: true });

  if (
    didQueryNotLoadYet(mostRecentTenancyResult) ||
    tenanciesResult.loading ||
    !tenanciesResult.data ||
    !tenancyId
  ) {
    return (
      <>
        <TenancySwitcher.Loading style={{ marginTop: 16 }} />
        <ExpensesList.Loading itemsLimit={3} />
      </>
    );
  }

  if (!didQuerySucceed(mostRecentTenancyResult)) {
    return (
      <ErrorAlertScreen
        title={"There was a problem loading Expenses"}
        onRetry={() => mostRecentTenancyResult.refetch()}
      />
    );
  }

  if (tenanciesResult.data?.length === 0) {
    return <ExpensesList.Empty />;
  }

  return (
    <>
      <TenancySwitcher
        value={tenancyId}
        managementId={managementId}
        tenancies={tenanciesResult.data}
        style={{ marginTop: 16 }}
        onChange={setTenancyId}
      />
      <ExpensesSectionList
        payerAilorn={AiloRN.of(services.PropertyManagement.tenancy, tenancyId)}
        onExpensePress={onExpensePress}
        itemsLimit={itemsLimit}
      />
    </>
  );
}
