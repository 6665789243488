import React, { useMemo, ReactElement, useState, useCallback } from "react";
import {
  ErrorCard,
  Grid,
  GridColumnOptions,
  GridHeadRow,
  GridRenderRowContainerProps,
  GridSortOrder
} from "@ailo/ui";
import {
  DisbursementCategory,
  DisbursementsHeader
} from "local/tabs/trustaccounting";
import { Colors, opacify } from "@ailo/primitives";
import { View } from "react-native";
import styled from "styled-components/native";
import { ProcessDisbursementsActionHeader } from "local/tabs/trustaccounting/disbursements/DisbursementsList/GridDisbursementsList/ProcessDisbursementsActionHeader";
import { useDisbursementListColumns } from "local/tabs/trustaccounting/disbursements/DisbursementsList/GridDisbursementsList/useDisbursementListColumns";
import { DisbursementRow, useGetDisbursements } from "./useGetDisbursements";
import { EmptyGridCard } from "local/tabs/trustaccounting/EmptyGridCard";

export interface DisbursementListProps {
  tabNavigation: React.ReactElement;
  category?: DisbursementCategory;
}

const GridDisbursementsList: React.FC<DisbursementListProps> = ({
  tabNavigation,
  category
}) => {
  const [selectedDisbursementIds, setSelectedDisbursementIds] = useState<
    string[]
  >([]);

  const [sortOrder, setSortOrder] = useState<GridSortOrder>({
    columnKey: "names",
    direction: "DESC"
  });

  const { data, loading, error } = useGetDisbursements({
    filter: category,
    sortOrder
  });
  const disbursements = data.disbursements;
  const disbursementIds = disbursements.map((row) => row.id);

  const headerCaption = useMemo(() => {
    return (
      <>
        <DisbursementsHeader />
        {tabNavigation}
      </>
    );
  }, [tabNavigation]);

  const DisbursementRowContainer = useCallback(
    ({
      row,
      rowIndex,
      children
    }: GridRenderRowContainerProps<DisbursementRow>) => {
      return (
        <RowContainer
          row={row}
          rowIndex={rowIndex}
          selectedDisbursementIds={selectedDisbursementIds}
        >
          {children}
        </RowContainer>
      );
    },
    [selectedDisbursementIds]
  );

  const columns = useDisbursementListColumns({
    selectedDisbursementIds,
    setSelectedDisbursementIds,
    disbursementIds,
    total: data.pageInfo.total
  });

  const loadingColumns = useMemo(
    () =>
      columns.map((col) => ({
        ...col,
        renderCell: col.renderLoadingCell,
        renderHeaderCell: col.renderLoadingHeaderCell
      })),
    [columns]
  ) as GridColumnOptions<unknown>[];

  const gridHeaderRow = (
    <>
      {selectedDisbursementIds.length > 0 && (
        <ProcessDisbursementsActionHeader
          selectedDisbursementIds={selectedDisbursementIds}
        />
      )}
      <GridHeadRow
        columns={columns}
        sortOrder={sortOrder}
        style={[
          headerCaption
            ? { borderTopLeftRadius: 0, borderTopRightRadius: 0 }
            : null
        ]}
        onSortOrderChange={setSortOrder}
      />
    </>
  );

  if (loading) {
    return (
      <Grid
        bodyStyle={{ marginLeft: 60, marginRight: 60 }}
        columns={loadingColumns}
        scrollView={true}
        headerCaption={headerCaption}
        renderRowContainer={({ row, style, children }) => (
          <View style={[style, { opacity: row.opacity }]}>{children}</View>
        )}
        rows={[
          { key: 1, opacity: 0.75 },
          { key: 2, opacity: 0.5 },
          { key: 3, opacity: 0.25 },
          { key: 4, opacity: 0 }
        ]}
      />
    );
  }

  if (error) {
    return (
      <Grid.Placeholder headerCaption={headerCaption}>
        <ErrorCard
          message={"There's a problem loading disbursements"}
          onReload={() => {}}
        />
      </Grid.Placeholder>
    );
  }

  if (disbursements.length === 0) {
    return (
      <Grid.Placeholder headerCaption={headerCaption}>
        <EmptyGridCard
          primaryText={"To be disbursed"}
          secondaryText={"You have no funds to disburse"}
        />
      </Grid.Placeholder>
    );
  }

  return (
    <Grid
      rows={disbursements}
      scrollView={true}
      columns={columns}
      headerCaption={headerCaption}
      renderRowContainer={DisbursementRowContainer}
      headerOverride={gridHeaderRow}
    />
  );
};

function RowContainer({
  row,
  selectedDisbursementIds,
  children
}: GridRenderRowContainerProps<DisbursementRow> & {
  selectedDisbursementIds: string[];
}): ReactElement {
  const backgroundColor = selectedDisbursementIds.includes(row.id)
    ? opacify(Colors.OCEAN, 0.1)
    : Colors.WHITE;
  return (
    <StyledRowContainer style={{ backgroundColor }}>
      {children}
    </StyledRowContainer>
  );
}

const StyledRowContainer = styled(View)`
  flex-direction: row;
  border-bottom-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;

export { GridDisbursementsList };
