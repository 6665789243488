import React, { ReactElement, useCallback } from "react";
import { ClaimTenancyData, useBondAnalytics } from "local/domain/bond";
import { BaseClaimBondForm } from "./BaseClaimBondForm";
import { useToastContext } from "@ailo/ui";
import { getEditBondClaimsInfoHeader } from "./getEditBondClaimsInfoHeader";
import { isPresent } from "ts-is-present";
import { getState } from "local/common";

type Props = {
  data: ClaimTenancyData;
  onClose(): void;
};

export function EditBondClaimForm({ data, onClose }: Props): ReactElement {
  const toast = useToastContext();
  const { onBondClaimUpdated } = useBondAnalytics();

  const onSubmitSuccess = useCallback(
    (result) => {
      const claims = result.data?.upsertTenancyBondClaims;
      const state = getState(data?.property.address.state);

      onBondClaimUpdated({
        bondId: data.bond.id,
        managementId: data.managementId,
        state,
        claims: claims?.filter(isPresent)
      });

      onClose();
      toast.show({ type: "success", message: "Bond claim updated in Ailo" });
    },
    [onClose, onBondClaimUpdated, toast, data]
  );
  const infoHeader = getEditBondClaimsInfoHeader();

  const formConfiguration = {
    title: "Edit bond claim",
    submitButtonText: "Update claim in Ailo",
    onSubmitSuccess,
    claimLiabilitiesStep: {
      nextButtonLabel: "Next",
      infoHeader
    },
    confirmClaimStep: {
      actionDescription:
        "Please confirm the details are correct before updating the bond claim",
      bondAuthorityActionDescription:
        "Editing the bond claim in Ailo does not directly affect claims submitted to the bond authority. " +
        "You may need to contact the bond authority if any change is required",
      submitEnabledByDefault: true
    }
  };

  return (
    <BaseClaimBondForm
      data={data}
      formConfiguration={formConfiguration}
      onClose={onClose}
    />
  );
}
