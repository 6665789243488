import React, { ReactElement, useCallback } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { IconSkeleton } from "@ailo/primitives";
import { TickButton, Tooltip, useToastContext } from "@ailo/ui";
import { ActionStatus, ActionDetailsFragment } from "local/graphql";
import { useReassignAction } from "./useReassignAction";
import { AssigneeSelector } from "./AssigneeSelector";
import { ActionMenu } from "./ActionMenu";
import { DueDateInputSelector } from "./DueDateInputSelector";
import { useUpdateActionDueDate } from "./useUpdateActionDueDate";
import { CloseButton } from "local/common";
import { useActionStatusContext } from "../ActionStatusContext";

interface Props {
  action: ActionDetailsFragment;
  projectId: string;
  onDismiss: () => void;
}

export function ActionHeader({
  action,
  projectId,
  onDismiss
}: Props): ReactElement {
  const toast = useToastContext();

  const [reassignAction] = useReassignAction({
    projectId,
    onError: () => {
      toast.show({
        type: "error",
        message: "Unable to assign. Please try again."
      });
    }
  });

  const onReassignAction = useCallback(
    (assigneeAilorn: string) => {
      reassignAction(action.id, assigneeAilorn);
    },
    [reassignAction, action]
  );

  const [updateDueDate] = useUpdateActionDueDate({
    projectId,
    onError: () => {
      toast.show({
        type: "error",
        message: "Unable to apply due date. Please try again."
      });
    }
  });

  const onUpdateDueDatePress = useCallback(
    (dueDate: string) => {
      updateDueDate(action, dueDate);
    },
    [updateDueDate, action]
  );

  const { handleActionStatusChange } = useActionStatusContext();
  const isDone = action.status === ActionStatus.Done;
  return (
    <Container>
      <Tooltip tooltipContent={isDone ? "Mark as active" : "Mark as done"}>
        <TickButton done={isDone} onPress={handleActionStatusChange} />
      </Tooltip>
      <RightContent>
        <AssigneeSelector
          assignee={action.assignee}
          onReassignAction={onReassignAction}
        />
        <DueDateInputSelector
          date={action.dueDate}
          onChange={onUpdateDueDatePress}
          style={{ marginLeft: 8 }}
        />
        <ActionMenu
          action={action}
          projectId={projectId}
          onChangeActionStatusPress={handleActionStatusChange}
          style={{ marginLeft: 8 }}
        />
        <CloseButton onDismiss={onDismiss} style={{ marginLeft: 8 }} />
      </RightContent>
    </Container>
  );
}

ActionHeader.Loading = function ActionHeaderLoading({
  onDismiss
}: Pick<Props, "onDismiss">): ReactElement {
  return (
    <Container>
      <IconSkeleton
        style={{ width: 32, height: 32, margin: -2, borderRadius: "50%" }}
      />
      <RightContent>
        <AssigneeSelector.Loading />
        <DueDateInputSelector.Loading style={{ marginLeft: 8 }} />
        <ActionMenu.Loading style={{ marginLeft: 8 }} />
        <CloseButton onDismiss={onDismiss} style={{ marginLeft: 8 }} />
      </RightContent>
    </Container>
  );
};

ActionHeader.Error = function ActionHeaderError({
  onDismiss
}: Pick<Props, "onDismiss">): ReactElement {
  return (
    <Container>
      <View />
      <RightContent>
        <CloseButton onDismiss={onDismiss} />
      </RightContent>
    </Container>
  );
};

const Container = styled(View)`
  padding: 16px 0px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RightContent = styled(View)`
  flex-direction: row;
`;
