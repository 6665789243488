import { LineItem } from "./formatLineItem";

type StatementTransactionLineItem = NonNullable<LineItem>;

export function isIncome(
  lineItem: StatementTransactionLineItem,
  walletOwnerReference: string
): boolean {
  return walletOwnerReference === lineItem.liability?.providerReference;
}

export function isDisbursement(
  lineItem: StatementTransactionLineItem
): boolean {
  const btt = lineItem.businessTransactionType;

  if (!btt) return false;

  if (isReversal(lineItem) || isRefund(lineItem)) {
    return !lineItem.liability;
  }

  return [
    BusinessTransactionTypes[BusinessTransactionTypes.WITHDRAW_MANUAL],
    BusinessTransactionTypes[BusinessTransactionTypes.WITHDRAW_AUTO]
  ].includes(btt);
}

export function isRefund(lineItem: StatementTransactionLineItem): boolean {
  return (
    lineItem.businessTransactionType ===
    BusinessTransactionTypes[BusinessTransactionTypes.REFUND]
  );
}

export function isReversal(lineItem: StatementTransactionLineItem): boolean {
  return (
    lineItem.businessTransactionType ===
    BusinessTransactionTypes[BusinessTransactionTypes.REVERSE]
  );
}

export function isFailed(lineItem: StatementTransactionLineItem): boolean {
  return [
    BusinessTransactionStatusTypes[BusinessTransactionStatusTypes.FAIL],
    BusinessTransactionStatusTypes[BusinessTransactionStatusTypes.CLEANED]
  ].includes(lineItem.businessTransactionStatus);
}

export function isSuccessful(lineItem: StatementTransactionLineItem): boolean {
  return (
    [
      BusinessTransactionStatusTypes[BusinessTransactionStatusTypes.SUCCESS]
    ].includes(lineItem.businessTransactionStatus) && !!lineItem.clearedDate
  );
}

export function isMoneyIn(lineItem: StatementTransactionLineItem): boolean {
  const isOriginallyMoneyIn = lineItem.walletChangeAmount.cents >= 0;

  return walletChangeAmountSignChange(lineItem)
    ? !isOriginallyMoneyIn
    : isOriginallyMoneyIn;
}

export function walletChangeAmountSignChange(
  lineItem: StatementTransactionLineItem
): boolean {
  return (
    isFailed(lineItem) &&
    ((!isReversal(lineItem) && !isRefund(lineItem)) ||
      ((isReversal(lineItem) || isRefund(lineItem)) &&
        isDisbursement(lineItem)))
  );
}

enum BusinessTransactionTypes {
  "PAYMENT_AUTO",
  "PAYMENT_MANUAL",
  "WITHDRAW_AUTO",
  "WITHDRAW_MANUAL",
  "TOP_UP_MANUAL",
  "REFUND",
  "REVERSE"
}

enum BusinessTransactionStatusTypes {
  "SUCCESS",
  "FAIL",
  "CLEANED",
  "PENDING"
}
