import React from "react";
import { SFC } from "@ailo/primitives";
import { GridHeaderDisplay } from "./components";
import { ColWidths } from "../BillGrid/BillGridRows/components";

interface Props {
  gridColWidths: ColWidths;
  numBills: number;
}

interface Statics {
  Loading: typeof Loading;
}

export const BillGridHeader: SFC<Props> & Statics = ({
  style,
  gridColWidths,
  numBills
}) => {
  if (numBills <= 0) return null;

  return <GridHeaderDisplay style={style} gridColWidths={gridColWidths} />;
};

const Loading: SFC<{
  gridColWidths: ColWidths;
}> = ({ style, gridColWidths }) => (
  <GridHeaderDisplay style={style} gridColWidths={gridColWidths} />
);

BillGridHeader.Loading = Loading;
