import { ChatList, useChatListContext } from "@ailo/domains";
import { ErrorAlertScreen, memoWithHoistedStatics } from "@ailo/ui";
import React, { useMemo } from "react";
import { StyleProp, ViewStyle } from "react-native";

interface Props {
  visible?: boolean;
  /*
   * Component to be shown at the top of the list;
   */
  header?: React.ReactElement;
}

function AgencyChatListComponent({
  header,
  visible = true
}: Props): React.ReactElement {
  const {
    chatList: { chats, loading, error, refetch }
  } = useChatListContext();

  const chatListStyle = useMemo(() => getChatListStyle(visible), [visible]);

  if (loading) {
    return <Loading visible={visible} />;
  }

  if (error || !chats) {
    return (
      <ErrorAlertScreen
        variant={"medium"}
        title={"There was a problem loading Chats"}
        onRetry={refetch}
      />
    );
  }

  if (chats.length === 0)
    return (
      <ChatList.Empty
        style={chatListStyle}
        text={
          "To start chatting, find a property and begin your chat from there"
        }
      />
    );

  return (
    <ChatList style={chatListStyle} header={header} borderLeftRadius={4} />
  );
}

function Loading({
  style,
  visible = true
}: {
  style?: StyleProp<ViewStyle>;
  visible?: boolean;
}): React.ReactElement {
  const chatListStyle = useMemo(() => getChatListStyle(visible), [visible]);
  return <ChatList.Loading style={[style, chatListStyle]} />;
}

AgencyChatListComponent.Loading = Loading;

function getChatListStyle(visible: boolean): ViewStyle {
  const baseStyle = { maxWidth: 400 };
  return visible ? baseStyle : { ...baseStyle, display: "none" };
}

export const AgencyChatList = memoWithHoistedStatics(AgencyChatListComponent);
