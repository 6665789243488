import { AiloRN } from "@ailo/ailorn";
import React, { ReactElement } from "react";
import { Colors, Text } from "@ailo/primitives";
import { CentrepayRenter } from "./CentrepayRenter";
import styled from "styled-components/native";
import { View } from "react-native";
import { Address } from "@ailo/domains";

export function CentrepayRentersList({
  managingEntity,
  tenancy,
  renters,
  onDelete,
  property,
  onEdit
}: {
  managingEntity: AiloRN;
  tenancy: AiloRN;
  renters: { tenantLegalEntity: AiloRN<string> }[] | undefined;
  onDelete: () => Promise<void>;
  property?: {
    address?: Address;
  };
  onEdit: () => Promise<unknown>;
}): ReactElement | null {
  if (!renters || renters.length === 0) {
    return (
      <NoCentrepayRentersContainer>
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
          {"Centrepay payments are not setup for this tenancy"}
        </Text.BodyS>
      </NoCentrepayRentersContainer>
    );
  }
  return (
    <View>
      {renters.map((renter, index) => (
        <CentrepayRenter
          key={index}
          managingEntity={managingEntity}
          tenancy={tenancy}
          renter={renter.tenantLegalEntity}
          onDelete={onDelete}
          property={property}
          onEdit={onEdit}
        />
      ))}
    </View>
  );
}

const NoCentrepayRentersContainer = styled(View)`
  padding: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${Colors.CLOUD};
`;
