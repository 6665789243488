import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { useAuth } from "@ailo/services";
import { createStackNavigator } from "@react-navigation/stack";
import { Screens } from "local/common";
import React from "react";
import { AppStackNavigator } from "../AppNavigator";
import { RecommendAiloInsightScreen } from "./RecommendAiloInsightScreen";
import { RecommendConsumerAppScreen } from "./RecommendConsumerAppScreen";
import { useRedirectToHelpIfNeeded } from "./useRedirectToHelpIfNeeded";

const Stack = createStackNavigator();

export const LoginNavigator: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const hasAgencyAccess = useHasFeature(PlatformFeatureId.AgencyAccess);
  const hasConsumerAccess = useHasFeature(PlatformFeatureId.ConsumerAccess);

  if (useRedirectToHelpIfNeeded({ isAuthenticated })) {
    return null;
  }

  if (!isAuthenticated) {
    throw new Error("User has not been redirected to Login Screen");
  }

  if (hasAgencyAccess) {
    return <AppStackNavigator />;
  }

  if (hasConsumerAccess)
    return (
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen
          name={Screens.RecommendConsumerApp}
          // TODO once `ScreenComponent` can be mounted without navigation context dependency,
          // mount the screen directly instead of using a stack navigator
          component={RecommendConsumerAppScreen}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    );

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name={Screens.RecommendAiloInsight}
        // TODO once `ScreenComponent` can be mounted without navigation context dependency,
        // mount the screen directly instead of using a stack navigator
        component={RecommendAiloInsightScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};
