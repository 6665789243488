import { useGetAmountDueAtDateLazyQuery } from "local/graphql";
import { useCallback } from "react";

export function useGetAmountDue(): {
  requestForAmountDueAtDate: (arg: {
    date?: moment.Moment;
    liabilityId?: string;
  }) => void;
  amount?: number;
} {
  const [getAmountDueAtDateLazyQuery, { data: amountDueAtDateData }] =
    useGetAmountDueAtDateLazyQuery();

  return {
    amount: amountDueAtDateData?.amountDueToDate?.cents,
    requestForAmountDueAtDate: useCallback(
      ({ date, liabilityId }): void => {
        getAmountDueAtDateLazyQuery({
          variables: {
            dateTime: date!.add(1, "day").toISOString(),
            liabilityId: liabilityId!
          }
        });
      },
      [getAmountDueAtDateLazyQuery]
    )
  };
}
