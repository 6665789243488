import React from "react";
import { BasePropertyListRow } from "../BasePropertyListRow";
import { ErrorCell } from "./ErrorCell";
import { useGetManagementTeamQuery } from "local/graphql";
import { TextCell } from "@ailo/ui";

function TeamCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, error, refetch } = useGetManagementTeamQuery({
    variables: { managementId }
  });

  if (loading && !data) {
    return <Loading />;
  }
  if (error)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const teamName = data?.management?.team?.name || "Unassigned";

  return <TextCell>{teamName}</TextCell>;
}

function Loading(): React.ReactElement {
  return <TextCell.Loading />;
}

TeamCell.Loading = Loading;

export { TeamCell };
