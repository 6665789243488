import { Money } from "@ailo/ui";
import { TenancyBond } from "local/tabs";
import { GetTenantsAndOutstandingLiabilityQuery } from "local/graphql";
import { LocalDate } from "@ailo/date";

export function parseBond(
  tenancy: GetTenantsAndOutstandingLiabilityQuery["tenancy"]
): TenancyBond | undefined {
  return tenancy && tenancy.bond
    ? {
        id: tenancy.bond.id,
        amount: Money.from(tenancy.bond.amount),
        reference: tenancy.bond.reference || undefined,
        status: tenancy.bond.status,
        failureReason: tenancy.bond.failureReason || undefined,
        createdAt: LocalDate.from(tenancy.bond.createdAt),
        modifiedAt: tenancy.bond.modifiedAt
          ? LocalDate.from(tenancy.bond.modifiedAt)
          : undefined
      }
    : undefined;
}
