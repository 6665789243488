import { TextInputFormField, validateMaxLength } from "@ailo/ui";
import React, { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ActionFormData } from "../ActionFormData";

export function ActionDescriptionField({
  onFieldUpdated
}: {
  onFieldUpdated(fieldName: keyof ActionFormData): void;
}): ReactElement {
  const { control, errors } = useFormContext<ActionFormData>();

  return (
    <Controller
      control={control}
      name={"description"}
      defaultValue={""}
      render={({ value, onChange, onBlur }) => (
        <TextInputFormField
          {...{ value, onChange, onBlur }}
          label={"Description (optional)"}
          error={errors.description?.message}
          onBlur={() => onFieldUpdated("description")}
          multiline
          numberOfLines={3}
        />
      )}
      rules={{
        validate: {
          maxLength: validateMaxLength(2048)
        }
      }}
    />
  );
}

ActionDescriptionField.Loading = function Loading() {
  return (
    <TextInputFormField.Loading
      labelWidth={120}
      multiline
      numberOfLines={3}
      style={{ marginBottom: 20 }}
    />
  );
};
