import { LocalDate } from "@ailo/date";
import { TimePeriods } from "@ailo/ui";
import { DatePeriod, getPeriodConstructor } from "../periods";
import { calculateIncomeOfFeeCatagoriesInPeriod } from "./calculateIncomeOfFeeCatagoriesInPeriod";
import { calculateRowsPeriodAndYearIncomeDelta } from "./calculateRowPeriodAndYearIncomeDelta";
import {
  isFeeNameInFeeCategoriesFilter,
  timeOutsideOfFilter
} from "./filterPredicates";

export type RawRow = {
  incomeAmount?: number | null;
  month?: string | null;
  financialYearAU?: string | null;
  qtr?: string | null;
  feeName?: string | null;
};

export interface RevenueReportRow {
  periodInProgress: boolean;
  periodIncomeDelta: number | null | undefined;
  periodIncomeDeltaPct: number | null | undefined;
  priorYearIncomeDelta: number | null | undefined;
  priorYearIncomeDeltaPct: number | null | undefined;
  incomeAmount: number;
  month?: string | null | undefined;
  financialYearAU?: string | null | undefined;
  qtr?: string | null | undefined;
  feeName?: string | null | undefined;
  rowDate: LocalDate;
  period: DatePeriod;
  [key: string]:
    | string
    | null
    | undefined
    | number
    | boolean
    | LocalDate
    | DatePeriod;
}

export function periodsBetween(
  start: DatePeriod,
  end: DatePeriod
): DatePeriod[] {
  const range: DatePeriod[] = [];
  let current = start;
  while (current.value <= end.value) {
    range.push(current);
    current = current.nextPeriod();
  }
  return range;
}

export function calculateRowColumns(
  rawRows: RawRow[],
  {
    feeCategories,
    startDate,
    endDate,
    periodType
  }: {
    feeCategories?: string[];
    startDate: LocalDate;
    endDate: LocalDate;
    periodType: TimePeriods;
  }
): RevenueReportRow[] {
  const toPeriod = getPeriodConstructor(periodType);
  const startPeriod = toPeriod(startDate);
  const endPeriod = toPeriod(endDate);

  if (startPeriod === undefined)
    throw Error(
      `Cannot construct period of type: ${periodType} with ${startDate}`
    );

  if (endPeriod === undefined)
    throw Error(
      `Cannot construct period of type: ${periodType} with ${endDate}`
    );

  const listOfPeriods = periodsBetween(startPeriod, endPeriod).map(
    (period) => ({
      period,
      periodVal: period.value,
      rowDate: period.startDate,
      incomeAmount: 0,
      feeName: ""
    })
  );

  const withMissingPeriods = rawRows
    .map((row) => {
      const period = toPeriod(
        row.month ?? row.qtr ?? row.financialYearAU ?? LocalDate.today()
      );

      if (period === undefined)
        throw Error(
          `can't create missing periods for values between ${startDate} - ${endDate}`
        );

      return {
        ...row,
        incomeAmount: row.incomeAmount ?? 0,
        period: period,
        periodVal: period.value,
        rowDate: period.startDate
      };
    })
    .concat(listOfPeriods);

  const periodIncome = calculateIncomeOfFeeCatagoriesInPeriod(
    withMissingPeriods
      .sort((a, b) => (a.period.value < b.period.value ? -1 : 1))
      .filter(isFeeNameInFeeCategoriesFilter(feeCategories))
  );

  return periodIncome
    .map(calculateRowsPeriodAndYearIncomeDelta)
    .filter(timeOutsideOfFilter(startDate, endDate));
}
