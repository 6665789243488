import { GenerateOpenChatLink, useChatNavigationContext } from "@ailo/domains";
import { Screens } from "local/common";
import { generateCommsTabUrl } from "local/tabs/comms/ChatScreen";
import { CommonActions } from "@react-navigation/native";
import { AiloRN } from "@ailo/ailorn";
import { useCallbackRef } from "@ailo/primitives";

/**
 * To be used only when inside Screens.CommsTab
 *
 * For absolute use cases outside of the comms tab:
 * @see generateLinkToCommsTab
 */
export function useGenerateOpenChatLink(): GenerateOpenChatLink {
  const {
    navigationParams: { teamId, threadId }
  } = useChatNavigationContext();

  return useCallbackRef(
    (chatAilorn: AiloRN<"chat:chat">) => {
      const to = generateCommsTabUrl({
        teamId,
        chatId: chatAilorn.internalId,
        threadId
      });

      return {
        to,
        action: CommonActions.navigate(Screens.ChatThread, {
          chatId: chatAilorn.internalId,
          threadId
        })
      };
    },
    [teamId, threadId]
  );
}
