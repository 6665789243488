import { useCallback } from "react";
import { DropdownMenuOption, useToastContext } from "@ailo/ui";
import {
  ManagementEndDetailsFragment,
  useClearManagementEndMutation
} from "local/graphql";
import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { AiloRN, nsEntities } from "@ailo/ailorn";
import { isFormerManagement } from "../../utils";

type Props = {
  managementEndDetails: ManagementEndDetailsFragment;
  onSuccess: () => void;
};

type Result = {
  option?: DropdownMenuOption;
  loading?: boolean;
  mutation?: () => void;
};

export function useStopEndingManagement({
  managementEndDetails,
  onSuccess
}: Props): Result | null {
  const toast = useToastContext();
  const analytics = useAnalytics();

  const [clearManagementEndMutation, { loading }] =
    useClearManagementEndMutation();

  const { endDate } = managementEndDetails;

  const hasManagementEnded = isFormerManagement(endDate);

  const stopEndingManagementMutation = useCallback(async (): Promise<void> => {
    if (loading) return;

    const managementId = managementEndDetails.id;

    try {
      const result = await clearManagementEndMutation({
        variables: { managementId }
      });

      throwIfMutationFailed(result, {
        dataKey: "clearManagementEnd"
      });

      onSuccess();

      toast.show({
        type: "success",
        message: hasManagementEnded
          ? "Management restarted"
          : "Management is no longer ending"
      });

      const managementAiloRN = AiloRN.of(
        nsEntities.propertymanagement.management,
        managementId
      );

      hasManagementEnded
        ? analytics.track("Ended Management Restarted", {
            management_id: managementAiloRN,
            end_date: endDate,
            end_reason: managementEndDetails.endReason,
            loss_cause: managementEndDetails.endReason?.causes.map(
              (cause) => cause.label
            ),
            description: managementEndDetails.endNote
          })
        : analytics.track("Ending Management Cancelled", {
            management_id: managementAiloRN
          });
    } catch (error) {
      AiloSentry.captureException(error);
      toast.showFormSubmitError();
    }
  }, [
    clearManagementEndMutation,
    endDate,
    hasManagementEnded,
    loading,
    managementEndDetails,
    onSuccess,
    toast,
    analytics
  ]);

  return {
    mutation: stopEndingManagementMutation,
    loading
  };
}
