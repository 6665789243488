import React from "react";
import { BasePropertyListRow } from "./BasePropertyListRow";

type CellRendererProps = {
  row: BasePropertyListRow;
  rowIndex: number;
};

type CellRendererType = (props: CellRendererProps) => React.ReactElement | null;

/**
 * Fix warning: "React has detected a change in the order of Hooks called by GridBody. This will lead to bugs and errors if not fixed."
 * This happens because:
 * 1) number of rows in the grid vary between renders
 * 2) the cells have some hooks
 * 3) the gridbody calls the functional component as function call rather than using JSX notation
 *
 * Since some of cells call hooks, we need to use the JSX notation to mount the cell (the equivalent of calling React.createElement).
 * The Grid itself receives a render props and it calls the function directly (without calling React.createElement) - the caller of the
 * Grid should pass in sensible render props
 * Don't need to call this when cells are not using hooks (e.g. loading state)
 *
 */

export function createCellRenderer(
  cellComponent: React.ComponentType<CellRendererProps>
): CellRendererType {
  return function renderer(props: {
    row: BasePropertyListRow;
    rowIndex: number;
  }): React.ReactElement | null {
    const Cell = cellComponent;
    return <Cell {...props} />;
  };
}
