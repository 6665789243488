import { mapLazyQueryHook } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import { useSearchManagementsLazyQuery } from "local/graphql";
import { hasPresentKey } from "ts-is-present";

export interface ActivePropertiesQuery {
  properties: Array<{
    id: string;
    managementId: string;
    address: {
      unitStreetNumber?: string | null;
      streetName: string;
      suburb?: string | null;
    };
  }>;
}

export const useSearchManagementPropertiesLazyQuery = mapLazyQueryHook(
  useSearchManagementsLazyQuery,
  (data): ActivePropertiesQuery => {
    if (!data.managementsForOrg) {
      throw new ApolloError({
        errorMessage:
          "useSearchManagementsLazyQuery returned empty `managementsForOrg`"
      });
    }

    const properties = data.managementsForOrg.items
      .filter(hasPresentKey("property"))
      .map((item) => ({
        id: item.property.id,
        managementId: item.id,
        address: item.property.address
      }))
      .filter(hasPresentKey("address"));

    return { properties };
  }
);
