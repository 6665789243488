import { Control } from "react-hook-form";

export type FilterType = {
  string: string;
  multiSelect: { label: string; value: string }[];
};

export enum FilterComponentType {
  Search = "string",
  MultiSelect = "multiSelect",
  Team = "team"
}

export type FilterConfig<Row> = {
  columns: Extract<keyof Row, string>[];
  type: FilterComponentType;
  placeholder?: string;
  menuWidth?: number;
} & Pick<FilterComponentProps, "defaultValue" | "name" | "namePlural">;

export interface FilterComponentProps {
  control: Control;
  defaultValue: string | { value: string; label: string }[];
  name: string;
  namePlural?: string;
  loading: boolean;
}
