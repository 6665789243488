import { AiloRN } from "@ailo/ailorn";
import { Colors, opacify } from "@ailo/primitives";
import { AlertScreen, SectionHeader } from "@ailo/ui";
import React, { ReactElement, useCallback } from "react";
import {
  SectionList,
  SectionListRenderItem,
  StyleProp,
  View,
  ViewStyle
} from "react-native";
import { groupStatementsByMonth } from "./groupStatementsByMonth";
import { StatementListItem } from "./StatementListItem";

export interface Statement {
  id: string;
  rangeStartDate: string;
  rangeEndDate: string;
  file?: {
    ailorn?: string;
    url?: string | null;
    contentType?: string | null;
  } | null;
  statementProgress?: {
    rangeStartBusinessTxId?: string | null;
    rangeEndBusinessTxId?: string | null;
  } | null;
}

interface TransferStatementsListProps {
  statements: Statement[];
  subject: AiloRN;
  style?: StyleProp<ViewStyle>;
}

export function TransferStatementsList({
  statements,
  subject,
  style
}: TransferStatementsListProps): ReactElement {
  const sections = groupStatementsByMonth(statements);

  const renderItem: SectionListRenderItem<Statement, typeof sections[0]> =
    useCallback(
      ({ item, section, index }) => {
        return (
          <StatementListItem
            key={index}
            statement={item}
            subject={subject}
            style={{
              borderType: index === section.data.length - 1 ? "none" : undefined
            }}
          />
        );
      },
      [subject]
    );

  if (sections.length === 0) {
    return <TransferStatementsList.Empty />;
  }

  return (
    <SectionList
      sections={sections}
      renderItem={renderItem}
      renderSectionHeader={({ section: { title } }): ReactElement => {
        return <SectionHeader title={title} style={{ paddingLeft: 16 }} />;
      }}
      stickySectionHeadersEnabled={true}
      style={[{ backgroundColor: Colors.WHITE }, style]}
    />
  );
}

TransferStatementsList.Empty = function Empty(): ReactElement {
  return (
    <AlertScreen
      type={"house-and-document"}
      title={"No transfer statements available"}
      description={
        "Your first statement will be issued when your first transfer is made"
      }
      style={{ minHeight: 288 }}
    />
  );
};

TransferStatementsList.Loading = function Loading({
  itemsLimit = 3,
  style
}: {
  itemsLimit?: number;
  style?: StyleProp<ViewStyle>;
}): ReactElement {
  const listItems = [];
  for (let i = 0; i < itemsLimit; i++) {
    const color = opacify(
      Colors.CHARCOAL,
      0.75 * ((itemsLimit - i) / itemsLimit)
    );
    listItems.push(
      <StatementListItem.Loading
        key={i}
        color={color}
        style={{ borderType: "none" }}
      />
    );
  }

  return (
    <View style={[{ minHeight: 288 }, style]}>
      <SectionHeader.Loading width={80} />
      {listItems}
    </View>
  );
};
