import { RecoverableError } from "@ailo/services";
import { useCurrentAgencyOrg } from "local/common";
import { Book, useViewAccountTagBalancesQuery } from "local/graphql";
import { useMemo } from "react";
import { LedgerBalancesData } from "./types";
import { Money } from "@ailo/money";
import { AccountSubtype } from "@ailo/domains";
import _ from "lodash";

export function useLedgerBalancesData({
  asOfDate
}: {
  asOfDate: string;
}): { loading: true } | ({ loading: false } & LedgerBalancesData) {
  const { defaultTrustAccount } =
    useCurrentAgencyOrg().defaultWalletLegalEntity ?? {};
  const { ailorn: trustAccountAilorn } = defaultTrustAccount ?? {};

  const variables = trustAccountAilorn
    ? {
        trustAccountAilorn,
        asOf: asOfDate,
        book: Book.Accrual,
        accountSubtypes: [
          AccountSubtype.PayableClearing,
          AccountSubtype.PayableCleared
        ]
      }
    : undefined;

  const skip = !variables;

  const { data, loading, refetch } = useViewAccountTagBalancesQuery({
    variables,
    skip
  });

  const parsedData = useMemo<LedgerBalancesData | undefined>(() => {
    if (!data?.accountTagBalances) return undefined;
    return {
      payableBalances: _.flatMap(
        _.groupBy(
          data?.accountTagBalances?.items || [],
          (it) => it?.accountTag.value
        ),
        (items, key) => ({
          entityType: key,
          amount: items.reduce(
            (acc, item) => acc.add(item?.balance ?? Money.zero()),
            Money.zero()
          )
        })
      )
    };
  }, [data?.accountTagBalances]);

  if (skip) {
    return { loading: true };
  }

  if (!parsedData && loading) {
    return { loading: true };
  }

  if (!parsedData)
    throw new RecoverableError("Could not load balances", refetch);

  return {
    loading: false,
    ...parsedData
  };
}
