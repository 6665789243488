import React, { ReactElement, useCallback, useState } from "react";
import { PaginationNavigation } from "@ailo/ui";
import { RelayPageInfo, CursorPaging } from "local/graphql";

interface RelayPaginationNavigationProps {
  pageSize: number;
  totalCount: number;
  pageInfo?: RelayPageInfo;
  fetchMore?: (args: { variables: { paging: CursorPaging } }) => void;
}

export function RelayPaginationNavigation({
  pageSize,
  totalCount,
  pageInfo,
  fetchMore
}: RelayPaginationNavigationProps): ReactElement {
  const pageCount = Math.ceil(totalCount / pageSize);
  const [pageNumber, setPageNumber] = useState(1);
  const onGoToNextPage = useCallback(() => {
    fetchMore?.({
      variables: {
        paging: {
          first: pageSize,
          after: pageInfo?.endCursor
        }
      }
    });
    setPageNumber((pageNumber) => Math.min(pageNumber + 1, pageCount));
  }, [fetchMore, pageCount, pageInfo?.endCursor, pageSize]);

  const onGoToPreviousPage = useCallback(() => {
    fetchMore?.({
      variables: {
        paging: {
          last: pageSize,
          before: pageInfo?.startCursor
        }
      }
    });
    setPageNumber((pageNumber) => Math.max(pageNumber - 1, 1));
  }, [fetchMore, pageInfo?.startCursor, pageSize]);
  return (
    <PaginationNavigation
      currentPage={pageNumber}
      pagesCount={pageCount}
      hasNextPage={pageInfo?.hasNextPage ?? undefined}
      hasPreviousPage={pageInfo?.hasPreviousPage ?? undefined}
      onGoToNextPage={onGoToNextPage}
      onGoToPreviousPage={onGoToPreviousPage}
    />
  );
}
