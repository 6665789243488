import React, { ReactElement } from "react";
import { Colors, HouseWithWindows, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";

export function HealthyMigratingManagementBody(): ReactElement {
  return (
    <Container>
      <View style={{ marginTop: 60, marginBottom: 60 }}>
        <StyledHouseWithWindows />
        <StyledBodyL weight={"medium"}>
          {"Property looks ready to go!"}
        </StyledBodyL>
        <StyledBodyS color={Colors.TEXT.DARK.SECONDARY}>
          {
            "If a property shouldn’t be onboarded to Ailo, please mark it as excluded."
          }
        </StyledBodyS>
      </View>
    </Container>
  );
}

const Container = styled(View)`
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  flex-grow: 1;
`;

const StyledHouseWithWindows = styled(HouseWithWindows)`
  width: 117px;
  height: 80px;
  align-self: center;
`;

const StyledBodyL = styled(Text.BodyL)`
  text-align: center;
  margin-top: 20px;
`;

const StyledBodyS = styled(Text.BodyS)`
  text-align: center;
  max-width: 280px;
  margin-top: 8px;
  margin-bottom: 12px;
`;
