import { useAnalytics } from "@ailo/services";
import { KeyFragment } from "local/graphql";
import { useCallback } from "react";

export function usePropertyKeyAddedAnalytics(
  propertyAilornString: string,
  managementAilornString: string
): { trackPropertyKeyAdded: (key: KeyFragment) => void } {
  const analytics = useAnalytics();

  const trackPropertyKeyAdded = useCallback(
    (key: KeyFragment) => {
      analytics.track("Property Key Added", {
        managementId: managementAilornString,
        propertyId: propertyAilornString,
        keyNumber: key.code,
        keyDescription: key.description
      });
    },
    [analytics, managementAilornString, propertyAilornString]
  );

  return {
    trackPropertyKeyAdded
  };
}
