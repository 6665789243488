import { useGlobalModal } from "@ailo/ui";
import { EndTenancyModalForm } from "local/modals/EndTenancyModalForm/EndTenancyModalForm";
import { useCompleteAction } from "local/domain/project";
import React, { ReactElement, useCallback } from "react";
import { BaseActionActivity } from "../../BaseActionActivity";

export function NoTenancyEndDateView({
  tenancyId,
  projectId,
  actionId
}: {
  tenancyId: string;
  projectId: string;
  actionId: string;
}): ReactElement {
  const modal = useGlobalModal();
  const [completeAction] = useCompleteAction({ projectId });

  const onSuccess = useCallback(async () => {
    await completeAction(actionId);
    modal.hide();
  }, [actionId, completeAction, modal]);

  return (
    <BaseActionActivity
      button={{
        label: "End tenancy",
        onPress: () => {
          modal.show(
            <EndTenancyModalForm
              tenancyId={tenancyId}
              onDismiss={modal.hide}
              onSuccess={onSuccess}
            />
          );
        }
      }}
      description={"Enter the details for the vacating tenancy"}
    />
  );
}
