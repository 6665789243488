import React, { ReactNode } from "react";
import { Colors, Text, WarningSolidIcon } from "@ailo/primitives";
import { ErrorBoundary } from "@ailo/services";
import { View } from "react-native";
import styled from "styled-components/native";
import { Button } from "@ailo/ui";

export function LedgerDetailsErrorBoundary({
  sectionName,
  children
}: {
  sectionName: string;
  children?: ReactNode;
}): React.ReactElement {
  return (
    <ErrorBoundary
      fallbackComponent={({ retry }): React.ReactElement => (
        <Container>
          <WarningSolidIcon
            color={Colors.STATUS.WARNING}
            secondaryColor={Colors.WHITE}
            width={40}
            height={40}
          />
          <Title weight={"medium"}>
            {`There was a problem loading\n${sectionName} ledger`}
          </Title>
          <Subtitle color={Colors.TEXT.DARK.SECONDARY}>
            {"Try"}
            <Button.Text onPress={retry}>
              <Text.BodyS weight={"medium"} color={Colors.AILO_RED}>
                &nbsp;{"reloading"}
              </Text.BodyS>
            </Button.Text>
          </Subtitle>
        </Container>
      )}
    >
      {children}
    </ErrorBoundary>
  );
}

const Container = styled(View)`
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Title = styled(Text.BodyL)`
  text-align: center;
  margin-top: 12px;
`;

const Subtitle = styled(Text.BodyS)`
  text-align: center;
  margin-top: 8px;
`;
