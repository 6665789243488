import { Colors, Text } from "@ailo/primitives";
import { Badge } from "@ailo/ui";
import { LocalDate } from "@ailo/date";
import { InspectionDay } from "local/domain/inspection";
import moment from "moment";
import React from "react";
import { StyleProp, ViewStyle, View } from "react-native";

interface Props {
  inspectionDay: InspectionDay;
}

export function InspectionDayListItemHeader({
  inspectionDay
}: Props): React.ReactElement {
  const { date } = inspectionDay;
  const dayMissed = date.isBefore(LocalDate.today());

  return (
    <View style={{ flexDirection: "row", alignItems: "center", maxWidth: 231 }}>
      <Text.BodyM weight={"medium"}>{date.format("dddd")}</Text.BodyM>
      <Text.BodyS
        weight={"book"}
        color={Colors.TEXT.DARK.SECONDARY}
        numberOfLines={1}
      >{` · ${getTimePeriodText(inspectionDay)}`}</Text.BodyS>
      {dayMissed && (
        <Badge type={"critical"} style={{ marginLeft: 16 }}>
          {"Missed"}
        </Badge>
      )}
    </View>
  );
}

function getTimePeriodText({ appointments }: InspectionDay): string {
  const startTime = moment(appointments[0].startTime);
  const endTime = moment(appointments[appointments.length - 1].startTime).add(
    15,
    "minutes"
  );

  return `${startTime.format("h:mma")} – ${endTime.format("h:mma")}`;
}

InspectionDayListItemHeader.Loading = function Loading({
  color,
  style
}: {
  color?: string;
  style?: StyleProp<ViewStyle>;
}) {
  return <Text.BodyM.Loading width={120} color={color} style={style} />;
};
