import React, { useEffect } from "react";
import { ViewStyle } from "react-native";
import { isEmpty } from "lodash";
import { SFC, StyleProps, useSynchronisedLoad } from "@ailo/primitives";
import {
  RentHistoryList,
  useTenantRentHistory,
  RentLiabilityEntryFragment
} from "@ailo/domains";
import { useInfinitelyPaginatedQuery, useOnFocus } from "@ailo/services";
import { RentBalanceHeader } from "./RentBalanceHeader";
import { LiabilityEntrySortField } from "local/graphql";

interface Props {
  tenancyId: string;
  emptyView?: SFC;
  onLedgerDetailEntryPress: (liabilityEntryId: string) => void;
  style?: StyleProps<ViewStyle>;
}

function TenancyTransactionsList({
  tenancyId,
  emptyView: EmptyView = RentHistoryList.Empty,
  onLedgerDetailEntryPress,
  style
}: Props): React.ReactElement {
  const { loading, data, refetch, loadingMore, hasMore, fetchMore } =
    useInfinitelyPaginatedQuery(useTenantRentHistory, (data) => data, {
      variables: {
        tenancyId,
        pageSize: 10,
        sort: {
          field: LiabilityEntrySortField.EffectiveAt
        }
      }
    });

  useOnFocus(refetch);

  useEffect(() => {
    refetch();
  }, [tenancyId, refetch]);

  if (useSynchronisedLoad(loading)) {
    return <RentHistoryList.Loading style={style} />;
  }

  // const parsedData = parseTenantRentHistory(data);

  if (!data || isEmpty(data.entries)) {
    return <EmptyView style={style} />;
  }

  const { entries, liability, depositData } = data;
  // remove plan-based entries (non ledger entries)
  const rentTransactions = (entries as RentLiabilityEntryFragment[]).filter(
    (liabilityEntry) => {
      return ["ADJUSTMENT", "PAYMENT"].includes(liabilityEntry.entryType);
    }
  );
  return (
    <RentHistoryList
      style={style}
      liabilityFirstPaymentDate={liability.firstPaymentDate}
      liabilityPaidToDate={liability.paidToDate}
      liabilityTerminationDate={liability.terminationDate}
      liabilityEntries={entries}
      hasFooter={true}
      lastPaymentListItemBorderType={"none"}
      listHeaderComponent={
        <RentBalanceHeader
          overdueAmount={liability.overdueAmount}
          nextDueDate={liability.nextDueDate}
          nextDueAmount={liability.nextDueAmount}
          tenancyId={tenancyId}
          tenancyLedgerDownloadDisabled={!(rentTransactions.length > 0)}
        />
      }
      rentHistoryListType={"AGENCY"}
      listItemOnPress={onLedgerDetailEntryPress}
      loadingMore={loadingMore}
      onLoadMore={hasMore ? fetchMore : undefined}
      depositData={depositData}
    />
  );
}

TenancyTransactionsList.Loading = RentHistoryList.Loading;
TenancyTransactionsList.Empty = RentHistoryList.Empty;

export { TenancyTransactionsList };
