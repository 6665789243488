import React, { ReactElement } from "react";
import { Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";
import {
  ButtonWithTooltip,
  ButtonWithTooltipProps,
  isButtonWithTooltipProps
} from "./ButtonWithTooltip";
import { ActionActivityContainer } from "./ActionSidebarContainer";

interface Props {
  description?: string | ReactElement;
  button?: ButtonWithTooltipProps | React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

export function BaseActionActivity({
  description,
  button,
  children,
  footer
}: Props): ReactElement {
  return (
    <ActionActivityContainer>
      {description && (
        <DescriptionContainer>
          <DescriptionElement description={description} />
        </DescriptionContainer>
      )}
      {children}
      {isButtonWithTooltipProps(button) ? (
        <ButtonWithTooltip {...button} />
      ) : (
        button
      )}
      {footer}
    </ActionActivityContainer>
  );
}

function DescriptionElement({
  description
}: {
  description: Props["description"];
}): ReactElement {
  if (!description) {
    return <></>;
  }
  if (typeof description === "string") {
    return <Text.BodyM weight={"book"}>{description}</Text.BodyM>;
  }
  return description;
}

const DescriptionContainer = styled(View)`
  margin-bottom: 24px;
`;
