import React, { ReactElement } from "react";
import { getActionInitiatorDisplayName } from "../utils";
import { ExcludedMigratingManagementFragment } from "local/graphql";
import { TextCell } from "@ailo/ui";

function ExcludedByCell({
  row: { statusChangedBy }
}: {
  row: ExcludedMigratingManagementFragment;
  rowIndex: number;
}): ReactElement {
  return <TextCell>{getActionInitiatorDisplayName(statusChangedBy)}</TextCell>;
}

ExcludedByCell.Loading = TextCell.Loading;

export { ExcludedByCell };
