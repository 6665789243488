import React from "react";
import { InspectionSectionInfoFragment } from "local/graphql";
import { formatDateDefaultToNA } from "@ailo/services";
import { DescriptionList } from "@ailo/ui";
import { getInspectionScheduleDescriptionListItems } from "local/domain/inspection";

interface Props {
  inspectionSectionInfo: InspectionSectionInfoFragment;
}

export function InspectionSectionDescriptionList({
  inspectionSectionInfo
}: Props): React.ReactElement {
  const descriptionListItems = [
    ...getInspectionScheduleDescriptionListItems(inspectionSectionInfo),
    {
      label: "Last inspection",
      description: formatDateDefaultToNA(
        inspectionSectionInfo.lastCompletedRoutineInspection?.completedAt
      )
    }
  ];

  return <DescriptionList size={"small"} items={descriptionListItems} />;
}
