import React from "react";
import { TeamsFilter, useSelectedTeams } from "local/domain/propertyManagement";
import { UnscheduledTenanciesFilterType } from "..";

export function useTeamsFilter(): UnscheduledTenanciesFilterType {
  const {
    propertyListSelectedTeamIds: teamIds,
    propertyListUnassignedSelected: unassignedSelected
  } = useSelectedTeams();
  return {
    isFilterActive: teamIds.length > 0 || unassignedSelected,
    component: <TeamsFilter />
  };
}
