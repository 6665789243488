import { formatDateDefaultToNA } from "@ailo/services";
import { DescriptionList } from "@ailo/ui";
import { getLabelForTenancyVacatingReason } from "local/domain/propertyManagement";
import { BaseReviewDetailsSection } from "local/modals/AddOrEditManagementModalForm/AddOrEditManagementFormSteps/ReviewManagementDetailsStep/BaseReviewDetailsSection";
import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { EndTenancyFormData } from "../EndTenancyFormData";

import { EndTenancyData } from "../useGetDataForEndTenancy";
import {
  AmountDueAtEndDateWarningMessage,
  DepositFundsWarningMessage
} from "../WarningMessages";

interface Props {
  onEditPress(): void;
  endTenancyData?: EndTenancyData;
  amountDueAtEndDate?: number;
}

export function EndTenancyDetailsSection({
  onEditPress,
  endTenancyData,
  amountDueAtEndDate
}: Props): ReactElement {
  const form = useFormContext<EndTenancyFormData>();
  const formData = form.getValues();

  return (
    <BaseReviewDetailsSection title={"End Tenancy"} onEditPress={onEditPress}>
      <DepositFundsWarningMessage
        showDepositFundsWarning={endTenancyData?.showDepositFundsWarning}
        style={{ marginBottom: 20 }}
      />
      <AmountDueAtEndDateWarningMessage
        amountDueAtEndDate={amountDueAtEndDate}
        style={{ marginBottom: 20 }}
      />
      <DescriptionList
        size={"small"}
        columns={2}
        items={[
          {
            label: "End of tenancy date",
            descriptionBottom: formatDateDefaultToNA(formData.endDate),
            columnIndex: 0
          },
          {
            label: "Rent liability will end on",
            descriptionBottom: formatDateDefaultToNA(formData.endDate),
            columnIndex: 1
          },
          {
            label: "Tenancy end reason",
            descriptionBottom: getLabelForTenancyVacatingReason(
              formData.reason
            ),
            columnIndex: 0
          },
          {
            label: "Tenancy end note",
            descriptionBottom: formData.notes ?? "-",
            columnIndex: 0
          }
        ]}
        rowStyle={{
          marginTop: 16
        }}
        style={{ marginBottom: 0 }}
      />
    </BaseReviewDetailsSection>
  );
}
