import { formatAddress } from "@ailo/domain-helpers";
import { getTaxCategoryName } from "@ailo/domains";
import { DateTimeWithTimeZone, Money } from "@ailo/ui";
import { GetTransferStatementDataQuery } from "local/graphql";
import {
  isDisbursement,
  isIncome,
  isMoneyIn,
  isSuccessful
} from "./lineItemHelpers";

type Val<T, K extends keyof NonNullable<T>> = K extends keyof T
  ? T[K]
  : NonNullable<T>[K] | undefined;

export type LineItem = Val<
  Val<
    Val<
      Val<GetTransferStatementDataQuery, "transferSummaryStatementData">,
      "transactionLineItemsByStatementTransactionDate"
    >,
    "items"
  >,
  number
>;

const statementCsvHeaders = [
  "Date Time",
  "Type",
  "Category",
  "Team",
  "Property Address",
  "Amount (excl. GST)($)",
  "GST($)",
  "Total Amount($)",
  "Cleared"
] as const;

type Headers = typeof statementCsvHeaders extends readonly (infer T)[]
  ? T
  : never;

export const STATEMENT_CSV_HEADERS = [...statementCsvHeaders] as string[];

function formatCells(
  lineItem: NonNullable<LineItem>,
  walletOwnerReference: string
): {
  [H in Headers]: string;
} {
  const moneyIn = isMoneyIn(lineItem);
  const transactionIsIncome = isIncome(lineItem, walletOwnerReference);
  const transactionIsTransfer = isDisbursement(lineItem);
  const transactionSuccess = isSuccessful(lineItem);

  const amountExclGst =
    Math.abs(lineItem.btAmount.cents) - Math.abs(lineItem.gst.cents);
  const gstAmount = Math.abs(lineItem.gst.cents);
  const totalAmount = Math.abs(lineItem.btAmount.cents);

  return {
    "Date Time": lineItem.statementTransactionDateTime
      ? DateTimeWithTimeZone.from(lineItem.statementTransactionDateTime).format(
          "DD MMM YYYY h:mm A"
        )
      : "",
    Type: transactionIsTransfer
      ? "Transfer"
      : transactionIsIncome
      ? "Income"
      : "Expense",
    Category: lineItem.liability
      ? getTaxCategoryName(lineItem.liability?.taxCategory)
      : "",
    Team: lineItem.management?.team?.name || "",
    "Property Address":
      (lineItem.management?.property &&
        formatAddress(lineItem.management.property.address, {
          format: "street, suburb, state, postcode"
        })) ||
      "",
    "Amount (excl. GST)($)": Money.fromCents(
      moneyIn ? amountExclGst : -amountExclGst
    ).format(),
    "GST($)": Money.fromCents(moneyIn ? gstAmount : -gstAmount).format(),
    "Total Amount($)": Money.fromCents(
      moneyIn ? totalAmount : -totalAmount
    ).format(),
    Cleared: transactionSuccess ? "yes" : ""
  };
}

export function formatLineItem(
  lineItem: LineItem,
  walletOwnerReference: string
): string[] | undefined {
  if (!lineItem || lineItem.__typename !== "StatementTransactionLineItem") {
    return;
  }
  const cells = formatCells(lineItem, walletOwnerReference);
  return statementCsvHeaders.map((header) => cells[header]);
}
