import { Colors, SearchIcon } from "@ailo/primitives";
import { SelectInput } from "@ailo/ui";
import React, { ReactElement } from "react";
import { formatPersonName } from "@ailo/domain-helpers";

export type LegalEntityNameDetails =
  | {
      id: string;
      firstName: string;
      lastName?: string | null;
      __typename?: "Person";
    }
  | { __typename?: "Company"; id: string; registeredEntityName: string };

function getLegalEntityDisplayValue(
  legalEntity: LegalEntityNameDetails
): string {
  return legalEntity?.__typename === "Company"
    ? legalEntity?.registeredEntityName
    : legalEntity?.__typename === "Person"
    ? formatPersonName(legalEntity)
    : "";
}

interface LegalEntityInputProps {
  value: LegalEntityNameDetails;
  options: LegalEntityNameDetails[];
  onChange(selectedEntity: LegalEntityNameDetails | undefined): void;
  onBlur?: () => void;
  placeholder: string;
  controlShouldRenderValue?: boolean;
  testID?: string;
  hasError?: boolean;
}

export function LegalEntityInput({
  value,
  onChange,
  onBlur,
  options,
  placeholder,
  controlShouldRenderValue,
  testID,
  hasError
}: LegalEntityInputProps): ReactElement {
  return (
    <SelectInput
      {...{ onBlur }}
      label={<SearchSelectLabel />}
      value={
        value
          ? {
              label: getLegalEntityDisplayValue(value),
              value: value.id
            }
          : undefined
      }
      placeholder={placeholder}
      options={options.map((legalEntity) => ({
        label: getLegalEntityDisplayValue(legalEntity),
        value: legalEntity?.id
      }))}
      onChange={(option) =>
        onChange(
          options.find((legalEntity) => legalEntity.id === option?.value)
        )
      }
      useTextInputHeight
      controlShouldRenderValue={controlShouldRenderValue}
      testID={testID}
      hasError={hasError}
    />
  );
}

function SearchSelectLabel(): React.ReactElement {
  return (
    <SearchIcon
      color={Colors.TEXT.DARK.SECONDARY}
      style={{ marginLeft: 10, marginRight: 6, height: 20, width: 20 }}
    />
  );
}
