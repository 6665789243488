import React, { ReactElement, ReactNode } from "react";
import { DefaultProps, Text } from "@ailo/primitives";
import { StyleProp, ViewStyle } from "react-native";
import { BalanceCardContainer, BalanceTitleRow } from "./components";

type BalanceCardProps = DefaultProps<{
  title: ReactNode;
}>;

export function BalanceCard({
  style,
  title,
  children
}: BalanceCardProps): ReactElement {
  return (
    <BalanceCardContainer style={style}>
      <BalanceTitleRow>{title}</BalanceTitleRow>
      {children}
    </BalanceCardContainer>
  );
}
interface LoadingProps {
  style?: StyleProp<ViewStyle>;
  title?: string | ReactNode;
  rowsToLoad?: number;
}

export function BalanceCardLoading({
  style,
  title,
  rowsToLoad
}: LoadingProps): ReactElement {
  return (
    <BalanceCardContainer style={style}>
      <BalanceTitleRow>{title ?? <Text.DisplayS.Loading />}</BalanceTitleRow>
      {Array.from({ length: rowsToLoad || 3 }).map((_, index) => (
        <BalanceTitleRow key={index}>
          <Text.BodyM.Loading width={300} />
        </BalanceTitleRow>
      ))}
    </BalanceCardContainer>
  );
}
