import { AiloRN } from "@ailo/ailorn";
import { useHasFeature } from "@ailo/domains";
import {
  CreateRecurringFeeFormData,
  CreateEventBasedFeeFormData,
  RecurringFeeBlueprintOption,
  useAddExpense
} from "local/domain/expense";
import { AgencyFeeBlueprintOption } from "local/domain/fee/managementFeeBlueprint/ManagementFeeBlueprintModalForm";
import {
  CreateManagementFeeBlueprintMutation,
  CreateRecurringFeeMutation,
  PlatformFeatureId
} from "local/graphql";
import React, { ReactElement } from "react";
import { EditExpenseModalFormData } from "../EditExpenseModalFormController";
import { ExpenseFormData, ExpenseModalForm } from "../ExpenseModalForm";

interface AddAutomaticFeeModalFormControllerProps {
  fee?: EditExpenseModalFormData;
  managementId: string;
  managementAgreementAilorn: AiloRN;
  managingEntityAilorn: AiloRN;
  showPropertyAddress?: boolean;
  recurringFeeOptions: (
    | RecurringFeeBlueprintOption
    | AgencyFeeBlueprintOption
  )[];
  visible: boolean;
  onRecurringFeeSuccess: (
    recurringFee: NonNullable<CreateRecurringFeeMutation["createRecurringFee"]>
  ) => void;
  onEventBasedFeeSuccess: (
    recurringFee: NonNullable<
      CreateManagementFeeBlueprintMutation["createManagementFeeBlueprint"]
    >
  ) => void;
  onDismiss: () => void;
}

export function AddAutomaticFeeModalFormController({
  managementId,
  managementAgreementAilorn,
  managingEntityAilorn,
  recurringFeeOptions,
  visible,
  onEventBasedFeeSuccess,
  onRecurringFeeSuccess,
  onDismiss
}: AddAutomaticFeeModalFormControllerProps): ReactElement | null {
  const { submitEventBasedFee, submitRecurringFee, sending } = useAddExpense({
    managementId,
    managementAgreementId: managementAgreementAilorn.internalId,
    managingEntityAilorn: managingEntityAilorn.toString(),
    onSubmitSuccess: (expense) => {
      if (expense?.__typename === "RecurringFee")
        onRecurringFeeSuccess(expense);
      else if (expense?.__typename === "ManagementFeeBlueprint") {
        onEventBasedFeeSuccess(expense);
      }
    }
  });

  const submit: (data: ExpenseFormData) => Promise<void> = async (data) => {
    data.feeBlueprint?.type === "EventBasedFee"
      ? submitEventBasedFee(data as CreateEventBasedFeeFormData)
      : submitRecurringFee(data as CreateRecurringFeeFormData);
  };

  const canManageEventBasedFees = useHasFeature(
    PlatformFeatureId.EventBasedFee
  );

  return (
    <ExpenseModalForm
      expenseType={"RecurringFee"}
      mode={"add"}
      title={`Add a recurring ${
        canManageEventBasedFees ? "or event-based " : ""
      }fee`}
      feeBlueprints={recurringFeeOptions}
      // Typecasting here because ExpenseFormData should have been a union type instead of an intersection type
      onSubmit={submit as (data: ExpenseFormData) => Promise<void>}
      submitting={sending}
      onCancel={onDismiss}
      visible={visible}
    />
  );
}
