import {
  AiloSentry,
  MutationResultDataMissingError,
  throwIfMutationFailed,
  throwIfQueryFailed,
  useAnalytics,
  withErrorBoundary
} from "@ailo/services";
import { Button, ErrorAlertScreen, useToastContext } from "@ailo/ui";
import { ExecutionResult } from "graphql";
import { Screens, useNavigation, useRoute } from "local/common";
import React, { ReactElement } from "react";
import { TabContent } from "../../common";
import {
  ManageFeeBlueprintForm,
  ManageFeeBlueprintFormData,
  useChangeFeeBlueprintArchiveStatus
} from "../common";
import {
  UpdateFeeBlueprintMutation,
  useUpdateFeeBlueprintMutation,
  useGetFeeBlueprintQuery,
  TaxTreatment,
  FeeBlueprintType
} from "local/graphql";

export const FeeDetailsTab = withErrorBoundary(
  function FeeDetailsTab(): ReactElement {
    const navigation = useNavigation<Screens.SettingsTab>();
    const route = useRoute<Screens.SettingsTab>();
    const analytics = useAnalytics();

    const blueprintId = route.params!.id!;
    const blueprintResult = useGetFeeBlueprintQuery({
      variables: {
        id: blueprintId
      }
    });
    throwIfQueryFailed(blueprintResult, { dataKey: "blueprint" });

    const { unarchive, archive } = useChangeFeeBlueprintArchiveStatus();

    const toasts = useToastContext();
    const [updateMutation] = useUpdateFeeBlueprintMutation();
    const submit = async (data: ManageFeeBlueprintFormData): Promise<void> => {
      let result: ExecutionResult<UpdateFeeBlueprintMutation>;
      try {
        result = await updateMutation({
          variables: {
            input: {
              id: blueprintId,
              taxCategoryId: data.taxCategoryId,
              name: data.name!,
              fixedAmount: data.price ? { cents: data.price.cents } : undefined,
              oneWeekRentPercentage: data.oneWeekRentPercentage,
              event:
                data.type === FeeBlueprintType.EventBasedFee
                  ? data.event
                  : null,
              taxTreatment: data.includesGst
                ? TaxTreatment.Inclusive
                : TaxTreatment.Exclusive,
              ...(data.frequency === "weekly"
                ? {
                    anniversaryDay: data.chargeDateDayOfWeek!
                  }
                : data.frequency === "monthly"
                ? {
                    anniversaryDay: data.chargeDateDayOfMonth!
                  }
                : {
                    anniversaryDay: data.chargeDateDayOfMonth!,
                    anniversaryMonth: data.chargeDateMonth!
                  })
            }
          }
        });
        throwIfMutationFailed(result, { dataKey: "blueprint" });
      } catch (error) {
        if (
          error instanceof MutationResultDataMissingError &&
          error.result.errors?.length === 1 &&
          error.result.errors[0].path?.join(".") === "blueprint" &&
          error.result.errors[0].extensions?.code === "BAD_USER_INPUT"
        ) {
          toasts.show({
            type: "error",
            message: error.result.errors[0].message
          });
          return;
        }
        AiloSentry.captureException(error);
        toasts.showFormSubmitError();
        return;
      }

      toasts.show({
        type: "success",
        message: "Fee template updated"
      });
      navigation.navigate(Screens.SettingsTab, {
        tab: "fees"
      });
      analytics.track("Fee Blueprint Edited", {
        feeBlueprintId: result.data!.blueprint!.id,
        feeFrequency: data.frequency ?? "one-off"
      });
    };

    const blueprint = blueprintResult.data?.blueprint;
    if (
      blueprint &&
      blueprint.taxTreatment !== "inclusive" &&
      blueprint.taxTreatment !== "exclusive"
    ) {
      throw new TypeError(
        `Only blueprints with TaxTreatment.Inclusive or TaxTreatment.Exclusive are supported for now. Blueprint ${blueprint.id} has taxTreatment: ${blueprint.taxTreatment}`
      );
    }

    return (
      <TabContent
        title={"Edit fee template"}
        rightButtons={
          blueprint ? (
            <Button.Secondary
              type={"small"}
              onPress={async () => {
                blueprint.archived
                  ? await unarchive(blueprint)
                  : await archive(blueprint);
                navigation.navigate(Screens.SettingsTab, {
                  tab: "fees"
                });
              }}
            >
              {blueprint.archived ? "Restore" : "Archive"}
            </Button.Secondary>
          ) : undefined
        }
        onGoBack={() =>
          navigation.navigate(Screens.SettingsTab, {
            tab: "fees"
          })
        }
      >
        <ManageFeeBlueprintForm
          style={{ marginTop: 40 }}
          key={String(!!blueprint)}
          mode={"update"}
          loading={!blueprint}
          initialValue={
            blueprint
              ? {
                  name: blueprint.name,
                  taxCategoryId: blueprint.taxCategory.id,
                  type: blueprint.type,
                  chargeType: blueprint.chargeType,
                  frequency: blueprint.frequency ?? null,
                  chargeDateDayOfWeek:
                    blueprint.frequency === "weekly"
                      ? blueprint.anniversaryDay!
                      : null,
                  chargeDateDayOfMonth:
                    blueprint.frequency !== "weekly"
                      ? blueprint.anniversaryDay!
                      : null,
                  chargeDateMonth: blueprint.anniversaryMonth ?? null,
                  price: blueprint.fixedAmount ?? null,
                  oneWeekRentPercentage:
                    blueprint.oneWeekRentPercentage ?? null,
                  includesGst:
                    blueprint.taxTreatment === TaxTreatment.Inclusive,
                  event: blueprint.event?.type ?? null
                }
              : undefined
          }
          onSubmit={submit}
        />
      </TabContent>
    );
  },
  {
    fallbackComponent: function Fallback(props) {
      const navigation = useNavigation<Screens.SettingsTab>();
      return (
        <TabContent
          onGoBack={() =>
            navigation.navigate(Screens.SettingsTab, {
              tab: "fees"
            })
          }
        >
          <ErrorAlertScreen
            title={"There was a problem loading Fee Template Details"}
            onRetry={props.retry}
          />
        </TabContent>
      );
    }
  }
);
