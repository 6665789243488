import { ClaimBondFormData } from "./ClaimBondFormData";
import { Money } from "@ailo/ui";
import { useFormContext } from "react-hook-form";

export function useTotalAmountToClaim(): Money {
  const { watch } = useFormContext<ClaimBondFormData>();
  const rent = watch("rent");
  const bills = watch("bills");
  return calculateTotalAmountClaimed({ rent, bills });
}

function calculateTotalAmountClaimed(formData: ClaimBondFormData): Money {
  const bills = formData.bills ?? [];
  const sumOfClaimedBills = bills
    .filter((bill) => bill.claimed)
    .reduce(
      (amount, bill) => amount.add(bill.amount ?? Money.zero()),
      Money.zero()
    );
  const rent = formData.rent;

  return sumOfClaimedBills.add(rent?.amount ?? Money.zero());
}
