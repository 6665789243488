import React from "react";
import { LedgerDetailsErrorBoundary } from "local/domain/ledger";
import { LedgerEntryDetails } from "./components";

interface Props {
  managementId: string;
  liabilityEntryId: string;
  onClose: () => void;
}

export function LedgerEntrySidebarContent({
  managementId,
  liabilityEntryId,
  onClose
}: Props): React.ReactElement {
  return (
    <LedgerDetailsErrorBoundary sectionName={"Tenancy &amp; Management"}>
      <LedgerEntryDetails
        managementId={managementId}
        liabilityEntryId={liabilityEntryId}
        onClose={onClose}
      />
    </LedgerDetailsErrorBoundary>
  );
}
