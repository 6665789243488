import React, { useState, useCallback, useEffect } from "react";
import { View, StyleProp, ViewStyle } from "react-native";
import { FieldName, UseFormMethods } from "react-hook-form";
import { isEqual } from "lodash";
import {
  useFilesState,
  FilesStateUserFriendlyError,
  FilesStateFile,
  FilesState
} from "@ailo/domains";
import { Colors, Text } from "@ailo/primitives";
import { FileDropZone, ErrorModal } from "@ailo/ui";
import { FileKind } from "local/graphql";

type UploadError = {
  title: string;
  message: string;
};

type UseUploadErrors = {
  uploadError: UploadError | null;
  setUploadError: (error: UploadError) => void;
  resetUploadError: () => void;
};

function useUploadErrors(): UseUploadErrors {
  const [uploadError, setUploadError] = useState<UploadError | null>(null);

  const resetUploadError = useCallback(() => {
    setUploadError(null);
  }, []);

  return {
    uploadError,
    setUploadError,
    resetUploadError
  };
}

/**
 * @deprecated Use "FileList" or "AttachmentsInput" instead
 */
export function FileAttachmentsGroup<
  FormData extends {
    files?: Array<FilesStateFile> | null;
  }
>({
  kind,
  form,
  setFilesState,
  placeholderText = "Attach a file",
  onFieldUpdated,
  style
}: {
  kind: FileKind;
  form: UseFormMethods<FormData>;
  setFilesState?: (fs: FilesState) => void;
  placeholderText?: string;
  onFieldUpdated?(fieldName: keyof FormData): void;
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  const { getValues, setValue, register, unregister } = form;

  const { uploadError, setUploadError, resetUploadError } = useUploadErrors();

  register({ name: "files" as FieldName<FormData> });

  const filesState = useFilesState({
    initialValue: getValues("files") ?? [],
    kind,
    onAddFileError(error: FilesStateUserFriendlyError) {
      setUploadError(error);
    }
  });

  const { files, addFile, removeFile } = filesState;

  useEffect(() => {
    register({ name: "files" as FieldName<FormData> });
    return (): void => unregister("files" as FieldName<FormData>);
  }, [register, unregister]);

  useEffect(() => {
    const previousFiles: FilesStateFile[] = getValues("files") ?? [];
    const prevValue = previousFiles.map((file) => ({
      id: file.id
    }));
    const nextValue = files.map((file) => ({
      id: file.id
    }));
    const valueChanged = !isEqual(prevValue, nextValue);
    if (valueChanged) {
      setValue("files", files, { shouldValidate: true, shouldDirty: true });
      onFieldUpdated?.("files");
    }
  }, [files, setValue, getValues, onFieldUpdated]);

  useEffect(() => {
    if (setFilesState) setFilesState(filesState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View accessible style={style}>
      <Text.BodyL
        weight={"medium"}
        style={{
          marginBottom: 20,
          paddingRight: 32
        }}
      >
        {"Files "}
        <Text.BodyL color={Colors.TEXT.DARK.SECONDARY}>
          {"(optional)"}
        </Text.BodyL>
      </Text.BodyL>
      <FileDropZone
        files={files}
        listItemsNegativeMarginRight={60}
        dropZonePlaceholderText={placeholderText}
        onUploadPress={addFile}
        onFileDeletePress={removeFile}
      />
      {uploadError && (
        <ErrorModal
          title={uploadError.title}
          description={uploadError.message}
          onDismiss={resetUploadError}
        />
      )}
    </View>
  );
}

FileAttachmentsGroup.Loading = function Loading({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement {
  return (
    <View style={style}>
      <Text.BodyL.Loading width={60} style={{ marginBottom: 24 }} />
      <FileDropZone.Loading />
    </View>
  );
};
