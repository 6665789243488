import React from "react";
import { Alert, useOpenUrlTab } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import { getState } from "local/common";
import { getBondAuthorityData } from "local/domain/bond";

export function BondAuthorityAlert({
  state
}: {
  state?: string | null;
}): React.ReactElement | null {
  const bondAuthority = getBondAuthorityData(getState(state || undefined));
  const openBondAuthorityPortal = useOpenUrlTab(bondAuthority?.url || "");

  if (!bondAuthority) return null;

  return (
    <Alert type={"info"} style={{ marginTop: 40 }}>
      {"If the bond claim hasn’t been submitted to the bond board yet, access "}
      <Text.BodyM
        onPress={openBondAuthorityPortal}
        color={Colors.AILO_RED}
        weight={"medium"}
        style={{
          textDecorationLine: "underline",
          fontFamily: "inherit"
        }}
      >
        {bondAuthority?.name}
      </Text.BodyM>
      {" and submit it."}
    </Alert>
  );
}
