import { Colors, Text } from "@ailo/primitives";
import styled from "styled-components/native";

export const ModalTitle = styled(Text.DisplayS)`
  padding: 22px 32px;
  color: ${Colors.TEXT.DARK.PRIMARY};
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: ${Colors.GRAYSCALE.OUTLINE};
  flex: 1;
`;
