import {
  ProjectType,
  LeaseRenewalProjectMetaFragment,
  NewTenancyProjectMetaFragment,
  ProjectFragment,
  GeneralProjectMetaFragment,
  GeneralProjectAddressFragment,
  LeaseRenewalProjectAddressFragment,
  NewTenancyProjectAddressFragment,
  NewManagementProjectMetaFragment,
  NewManagementProjectAddressFragment,
  VacatingTenancyProjectAddressFragment,
  VacatingTenancyProjectMetaFragment,
  RoutineInspectionProjectMetaFragment,
  RoutineInspectionProjectAddressFragment
} from "local/graphql";

export type Project<Type extends ProjectType = ProjectType> =
  ProjectFragment & {
    type: Type;
    meta?: ProjectMeta[Type] | null;
  };

export type ProjectMeta = { [key in ProjectType]: {} } & {
  [ProjectType.General]: GeneralProjectMetaFragment;
  [ProjectType.LeaseRenewal]: LeaseRenewalProjectMetaFragment;
  [ProjectType.NewTenancy]: NewTenancyProjectMetaFragment;
  [ProjectType.NewManagement]: NewManagementProjectMetaFragment;
  [ProjectType.VacatingTenancy]: VacatingTenancyProjectMetaFragment;
  [ProjectType.RoutineInspection]: RoutineInspectionProjectMetaFragment;
};

export function isProjectOfTypeWithMeta<Type extends ProjectType>(
  project: Project,
  type: Type
): project is Project<Type> & { meta: ProjectMeta[Type] } {
  return project.type === type && !!project.meta;
}

export type ProjectWithAddress<Type extends ProjectType = ProjectType> =
  ProjectFragment & {
    type: Type;
    meta?: ProjectAddressFromMeta[Type] | null;
  };

export type ProjectAddressFromMeta = { [key in ProjectType]: {} } & {
  [ProjectType.General]: GeneralProjectAddressFragment;
  [ProjectType.LeaseRenewal]: LeaseRenewalProjectAddressFragment;
  [ProjectType.NewTenancy]: NewTenancyProjectAddressFragment;
  [ProjectType.NewManagement]: NewManagementProjectAddressFragment;
  [ProjectType.VacatingTenancy]: VacatingTenancyProjectAddressFragment;
  [ProjectType.RoutineInspection]: RoutineInspectionProjectAddressFragment;
};

export function isProjectOfTypeWithAddressFromMeta<Type extends ProjectType>(
  project: ProjectWithAddress,
  type: Type
): project is ProjectWithAddress<Type> & {
  meta: ProjectAddressFromMeta[Type];
} {
  return project.type === type && !!project.meta;
}
