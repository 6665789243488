import { useCurrentAgencyOrg } from "local/common";
import React from "react";
import { useGetNotificationsTableDataQuery } from "local/graphql";
import { dateForSort, useReportQuery, useReportTeamContext } from "../../..";
import { reports } from "../../../reportsDetails";
import { ReportPage } from "../../../components";
import { FilterComponentType } from "../../../components/ReportPage/FilterFactory";
import { propertyRowLink } from "../../../components/ReportPage/utils/tableNavigation";

export function NotificationsTable(): React.ReactElement {
  const { id } = useCurrentAgencyOrg();
  const { teamId } = useReportTeamContext();
  const queryResult = useReportQuery(useGetNotificationsTableDataQuery, {
    managingOrgId: id,
    teamId
  });
  const rows = queryResult.data?.notificationsReport?.rows;
  return (
    <ReportPage
      title={reports.property_notifications.reportHeading}
      data={{
        ...queryResult,
        rows
      }}
      columnsConfig={{
        sentAt: {
          header: "Date",
          width: 0.5,
          sortValue: (r) => dateForSort(r.sentAt)
        },
        personName: { header: "Customer", width: 0.5 },
        propertyAddress: { header: "Property address", width: 1.5 },
        personType: { header: "Customer type", width: 0.5 },
        notificationType: { header: "Topic", width: 0.5 },
        notificationChannel: { header: "Channel", width: 0.5 },
        notificationTitle: { header: "Subject line", width: 2 },
        notificationDeliveryStatus: {
          header: "Delivery status",
          width: 0.5,
          badge: {
            valueColorMapping: [
              {
                cellValue: "Sent",
                badgeType: "default"
              },
              {
                cellValue: "Skipped",
                badgeType: "default"
              },
              {
                cellValue: "Delivered",
                badgeType: "success"
              },
              {
                cellValue: "Pushed",
                badgeType: "success"
              },
              {
                cellValue: "Failed",
                badgeType: "attention"
              }
            ]
          }
        }
      }}
      filterConfig={[
        {
          name: "search",
          columns: ["personName", "propertyAddress"],
          type: FilterComponentType.Search,
          defaultValue: "",
          placeholder: "Search by property or customer"
        },
        {
          name: "Topic",
          namePlural: "Topics",
          columns: ["notificationType"],
          defaultValue: [],
          type: FilterComponentType.MultiSelect
        }
      ]}
      defaultSort={{ columnKey: "sentAt", direction: "DESC" }}
      rowLink={propertyRowLink ?? ""}
    />
  );
}
