import React from "react";
import { DrawerElementData } from "../BaseDrawerNavigator";
import { NavigationDrawerGroup, NavigationDrawerGroupProps } from ".";

function createGroupNavigationDrawer({
  icon,
  groupName,
  groupScreenDefault,
  childDrawers
}: NavigationDrawerGroupProps): DrawerElementData[] {
  return childDrawers.map(({ name, component }, index) => ({
    name,
    component,
    renderDrawerItem: createGroupDrawerRenderFunction(
      { icon, groupName, groupScreenDefault, childDrawers },
      index
    )
  }));
}

function createGroupDrawerRenderFunction(
  drawerDetails: NavigationDrawerGroupProps,
  index: number
): undefined | (() => React.ReactElement) {
  if (index !== 0) return undefined;

  return function groupDrawRenderFunction() {
    return (
      <NavigationDrawerGroup
        key={index + drawerDetails.groupName}
        groupName={drawerDetails.groupName}
        icon={drawerDetails.icon}
        groupScreenDefault={drawerDetails.groupScreenDefault}
        childDrawers={drawerDetails.childDrawers}
      />
    );
  };
}

export { createGroupNavigationDrawer };
