import { uuid } from "@ailo/services";
import { Screens, SplitScreenContainer, useRoute } from "local/common";
import React, { ReactElement } from "react";
import { PropertyDetails } from "local/modals/components/PropertyDetails";
import { CreateRentAdjustmentForm } from "./CreateRentAdjustmentForm";

export function CreateRentAdjustmentScreen(): ReactElement {
  const { tenancyId, managementId } =
    useRoute<Screens.CreateRentAdjustment>().params!;

  return (
    <SplitScreenContainer
      left={
        <CreateRentAdjustmentForm
          tenancyId={tenancyId}
          managementId={managementId}
          idempotencyKey={uuid()}
          style={{
            maxWidth: 680, // 620 + 60 (padding)
            width: "100%",
            flexGrow: 1,
            alignSelf: "flex-end"
          }}
        />
      }
      leftSectionSize={"medium"}
      right={<PropertyDetails tenancyId={tenancyId} />}
    />
  );
}
