import { DropdownMenuOption } from "@ailo/ui";
import { RentCreditIcon } from "@ailo/primitives";
import React from "react";

export function useGetRentAdjustmentsOption({
  onSelect,
  disabled
}: {
  onSelect: () => void;
  disabled?: boolean;
}): DropdownMenuOption | undefined {
  return {
    label: "Rent adjustments",
    icon: <RentCreditIcon />,
    onSelect,
    disabled
  };
}
