import React, { useCallback, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { CommsTabStackNavigatorParamList, Screens } from "local/common";
import {
  ChatTeam,
  ChatTeamId,
  useChatAnalytics,
  useWatchChatNavigationParams
} from "@ailo/domains";
import { View } from "react-native";
import { ErrorBoundary } from "@ailo/services";
import { ErrorAlertScreen } from "@ailo/ui";
import { ChatListWithFilterSelector } from "./ChatListWithFilterSelector";
import {
  ChatMessageScreen,
  EmptyChatScreen,
  NewChatMessageScreenContainer
} from "./ChatScreen";
import { ChatScreenOverlay } from "./ChatListWithFilterSelector/Overlay";
import { useSelectTeam } from "local/tabs/comms/navigation";

const ChatStack = createStackNavigator<CommsTabStackNavigatorParamList>();

function ChatTabScreenContent({
  selectedTeamId,
  teams
}: {
  selectedTeamId: ChatTeamId;
  teams: ChatTeam[];
}): React.ReactElement {
  useWatchChatNavigationParams({ params: { teamId: selectedTeamId } });

  const [teamSelectorOpen, setTeamSelectorOpen] = useState(false);
  const selectTeam = useSelectTeam();
  const { trackTeamChanged } = useChatAnalytics();

  const toggleTeamSelector = useCallback(() => {
    setTeamSelectorOpen(!teamSelectorOpen);
  }, [setTeamSelectorOpen, teamSelectorOpen]);

  const onSelectTeam = useCallback(
    (teamId: ChatTeamId) => {
      setTeamSelectorOpen(false);
      trackTeamChanged({ teamId, teams });
      selectTeam(teamId);
    },
    [selectTeam, teams, trackTeamChanged]
  );

  return (
    <ErrorBoundary
      fallbackComponent={({ retry }): React.ReactElement => (
        <ErrorAlertScreen
          variant={"medium"}
          title={"There was a problem loading Chat"}
          onRetry={retry}
        />
      )}
    >
      <View style={{ flex: 1, flexDirection: "row" }}>
        <ChatListWithFilterSelector
          selectedTeamId={selectedTeamId}
          onSelectTeam={onSelectTeam}
          selectorOpen={teamSelectorOpen}
          onPressSelectorButton={toggleTeamSelector}
          teams={teams}
        />

        <View style={{ flex: 1 }}>
          <ChatStack.Navigator
            initialRouteName={Screens.EmptyChat}
            screenOptions={{ headerShown: false, gestureEnabled: false }}
          >
            <ChatStack.Screen
              name={Screens.EmptyChat}
              options={{ title: "Chat" }}
              component={EmptyChatScreen}
            />
            <ChatStack.Screen
              name={Screens.NewChatMessage}
              options={{ title: "New Message" }}
              component={NewChatMessageScreenContainer}
            />
            <ChatStack.Screen
              name={Screens.ChatThread}
              options={{ title: "Chat" }}
              component={ChatMessageScreen}
              // We only ever want one ChatThread Screen in the stack, give them all the same ID regardless of params
              getId={() => ""}
            />
          </ChatStack.Navigator>

          {teamSelectorOpen && (
            <ChatScreenOverlay
              onPress={(): void => setTeamSelectorOpen(false)}
            />
          )}
        </View>
      </View>
    </ErrorBoundary>
  );
}

function Loading(): React.ReactElement {
  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      <ChatListWithFilterSelector.Loading />

      <View style={{ flex: 1 }}>
        <EmptyChatScreen />
      </View>
    </View>
  );
}

ChatTabScreenContent.Loading = Loading;

export { ChatTabScreenContent };
