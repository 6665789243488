import React, { ReactElement } from "react";
import { FormFieldRow, TextInputFormField } from "@ailo/ui";
import { Controller, useFormContext } from "react-hook-form";
import {
  NewManagementFormData,
  NewManagementFormFields
} from "../../NewManagementFormData";
import styled from "styled-components/native";
import { View } from "react-native";

export function AddressInput(): ReactElement {
  const { control, errors } = useFormContext<NewManagementFormData>();
  return (
    <>
      <View style={{ flexDirection: "row", marginBottom: 24 }}>
        <Controller
          control={control}
          render={({ value, onBlur, onChange }): React.ReactElement => {
            return (
              <TextInputFormField
                {...{ value, onBlur, onChange }}
                label={"Unit/street number"}
                placeholder={"Street no."}
                error={errors.address?.unitStreetNumber?.message}
                style={{ width: 120, marginRight: 16 }}
              />
            );
          }}
          name={`${NewManagementFormFields.address}.unitStreetNumber`}
          defaultValue={null}
          rules={{
            required: "Please enter a street number"
          }}
        />
        <Controller
          control={control}
          render={({ value, onBlur, onChange }): React.ReactElement => {
            return (
              <TextInputFormField
                {...{ value, onBlur, onChange }}
                label={"Street name"}
                placeholder={"Street name"}
                error={errors.address?.streetName?.message}
                style={{ flex: 1 }}
              />
            );
          }}
          name={`${NewManagementFormFields.address}.streetName`}
          defaultValue={null}
          rules={{
            required: "Please enter a street name"
          }}
        />
      </View>
      <StyledFormFieldRow>
        <Controller
          control={control}
          render={({ value, onBlur, onChange }): React.ReactElement => {
            return (
              <TextInputFormField
                {...{ value, onBlur, onChange }}
                label={"Suburb"}
                placeholder={"Suburb"}
                error={errors.address?.suburb?.message}
              />
            );
          }}
          name={`${NewManagementFormFields.address}.suburb`}
          defaultValue={null}
          rules={{
            required: "Please enter a suburb"
          }}
        />
        <Controller
          control={control}
          render={({ value, onBlur, onChange }): React.ReactElement => {
            return (
              <TextInputFormField
                {...{ value, onBlur, onChange }}
                label={"Postcode"}
                placeholder={"Postcode"}
                digitsOnly
                error={errors.address?.postcode?.message}
              />
            );
          }}
          name={`${NewManagementFormFields.address}.postcode`}
          defaultValue={null}
          rules={{
            required: "Please enter a postcode"
          }}
        />
      </StyledFormFieldRow>
      <StyledFormFieldRow cols={2}>
        <Controller
          control={control}
          render={({ value, onBlur, onChange }): React.ReactElement => {
            return (
              <TextInputFormField
                {...{ value, onBlur, onChange }}
                label={"State"}
                placeholder={"State"}
                error={errors.address?.state?.message}
              />
            );
          }}
          name={`${NewManagementFormFields.address}.state`}
          defaultValue={null}
          rules={{
            required: "Please enter a state"
          }}
        />
        <Controller
          control={control}
          render={({ value, onBlur, onChange }): React.ReactElement => {
            return (
              <TextInputFormField
                {...{ value, onBlur, onChange }}
                label={"Country"}
                placeholder={"Country"}
                error={errors.address?.country?.message}
              />
            );
          }}
          name={`${NewManagementFormFields.address}.country`}
          defaultValue={"Australia"}
          rules={{
            required: "Please enter a country"
          }}
        />
      </StyledFormFieldRow>
    </>
  );
}

const StyledFormFieldRow = styled(FormFieldRow)`
  margin-bottom: 24px;
`;
