import { AiloRN } from "@ailo/ailorn";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import {
  Screens,
  useCurrentAgencyOrg,
  useRoute,
  WalletTabLabel
} from "local/common";
import React, { ReactElement, useRef } from "react";
import { ScrollView, StyleProp, View, ViewStyle } from "react-native";
import { TransactionList } from "./TransactionList";
import { TransferStatementsTab } from "./TransferStatementsTab/TransferStatementsTab";
import { WalletTabNavigation } from "./WalletTabNavigation";

interface TabsAndContentProps {
  walletOwnerId?: string;
  scrollViewRef?: React.RefObject<ScrollView>;
  style?: StyleProp<ViewStyle>;
}

export function TabsAndContent({
  scrollViewRef,
  style
}: TabsAndContentProps): ReactElement | null {
  const { params } = useRoute<Screens.WalletTab>();
  const contentRef = useRef<View>(null);

  const currentAgencyOrg = useCurrentAgencyOrg();

  const hasAgencyTransferStatementFeature = useHasFeature(
    PlatformFeatureId.AgencyTransferStatement
  );

  const hasTransactionsFeature = useHasFeature(
    PlatformFeatureId.ViewAgencyWalletTransactions
  );

  const tabs: { label: WalletTabLabel }[] = [];

  if (hasAgencyTransferStatementFeature) {
    tabs.push({ label: "Transfer summaries" });
  }

  if (hasTransactionsFeature) {
    tabs.push({ label: "Transactions" });
  }

  if (tabs.length === 0) {
    return null;
  }

  const currentTabLabel = params?.tab ?? tabs[0].label;
  const tabIndex = Math.max(
    tabs.findIndex((tab) => tab.label === currentTabLabel),
    0
  );

  const legalEntityId =
    params?.legalEntityId || currentAgencyOrg.defaultWalletLegalEntity?.id;

  if (!legalEntityId) {
    throw new Error("Could not find current agency's legal entity id");
  }

  const legalEntityAilorn = AiloRN.of("authz:legalentity", legalEntityId);

  const onPageChange = (): void => {
    // When changing pages, scroll so the tabNavigation is at the top of the page
    contentRef.current!.measure((_x, y) => {
      scrollViewRef?.current?.scrollTo({
        y: y + 32,
        animated: false
      });
    });
  };

  return (
    <View ref={contentRef} style={style}>
      {currentTabLabel === "Transfer summaries" ? (
        <TransferStatementsTab
          legalEntityAilorn={legalEntityAilorn}
          tabNavigation={
            <WalletTabNavigation tabs={tabs} selectedTabIndex={tabIndex} />
          }
        />
      ) : currentTabLabel === "Transactions" ? (
        <TransactionList
          walletOwnerAilorn={legalEntityAilorn}
          onPageChange={onPageChange}
          tabNavigation={
            <WalletTabNavigation tabs={tabs} selectedTabIndex={tabIndex} />
          }
        />
      ) : null}
    </View>
  );
}
