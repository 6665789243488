import React, { ReactElement, useMemo } from "react";
import { CreateProjectButton } from "./CreateProjectButton";
import {
  PropertyContentCard,
  PropertyContentCardLoading,
  PropertyContentCardProps
} from "../PropertyContentCard";

const STYLE = { marginTop: 16 };

export const ProjectsPropertyContentCard = React.memo(
  function ProjectsPropertyContentCard({
    onCreateProjectPress,
    children,
    ...propertyContentCardProps
  }: Omit<PropertyContentCardProps, "title" | "titleRight"> & {
    onCreateProjectPress?: () => void;
  }): ReactElement {
    const titleRight = useMemo(
      () =>
        onCreateProjectPress ? (
          <CreateProjectButton onPress={onCreateProjectPress} />
        ) : undefined,
      [onCreateProjectPress]
    );

    return (
      <PropertyContentCard
        style={STYLE}
        title={"Projects"}
        titleRight={titleRight}
        {...propertyContentCardProps}
      >
        {children}
      </PropertyContentCard>
    );
  }
);

export const ProjectsPropertyContentCardLoading = React.memo(
  function ProjectsPropertyContentCardLoading({
    style,
    children
  }: Pick<PropertyContentCardProps, "style" | "children">): ReactElement {
    return (
      <PropertyContentCardLoading style={style} titleWidth={28}>
        {children}
      </PropertyContentCardLoading>
    );
  }
);
