import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AFC } from "@ailo/primitives";
import { BankingDetailsFormData } from "./BankingDetailsFormData";

interface Props {
  defaultValues?: BankingDetailsFormData;
}

const BankingDetailsFormContext: AFC<Props> = ({ children, defaultValues }) => {
  const formMethods = useForm<BankingDetailsFormData>({
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      abaFileFormat: defaultValues?.abaFileFormat || "unbalanced",
      directEntryUserId: defaultValues?.directEntryUserId || ""
    }
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
};

export { BankingDetailsFormContext };
