import { GridSortOrder } from "@ailo/ui";
import { useAnalytics } from "local/common";
import { useCallback, useState } from "react";

interface GridHeaderState {
  onSortOrderChange: (nextState: GridSortOrder) => void;
  sortOrder: GridSortOrder;
}

export function usePropertyListReorder(
  initialSortState: GridSortOrder,
  requestDataFromBeginning: (s: GridSortOrder) => void,
  getAnalyticsInfo: (sortOrder: GridSortOrder) => {
    eventName: string;
    orderBy: string;
  }
): GridHeaderState {
  const analytics = useAnalytics();

  const [sortOrder, setSortOrder] = useState(initialSortState);

  const trackReorderingAnalytics = useCallback(
    (sortOrder: GridSortOrder) => {
      const analyticsInfo = getAnalyticsInfo(sortOrder);
      analytics.trackPropertyListReordered(analyticsInfo.eventName, {
        orderBy: analyticsInfo.orderBy
      });
    },
    [analytics, getAnalyticsInfo]
  );

  const onSortOrderChange = useCallback(
    (sortOrder: GridSortOrder): void => {
      trackReorderingAnalytics(sortOrder);

      setSortOrder(sortOrder);
      requestDataFromBeginning(sortOrder);
    },
    [requestDataFromBeginning, trackReorderingAnalytics]
  );

  return {
    onSortOrderChange,
    sortOrder
  };
}
