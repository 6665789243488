import React, { ReactElement, ReactNode } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ActionFormData } from "./ActionFormData";

export function ActionFormContext({
  initialValue,
  children
}: {
  initialValue?: ActionFormData;
  children?: ReactNode;
}): ReactElement {
  const formMethods = useForm<ActionFormData>({
    mode: "onChange",
    shouldFocusError: true,
    ...(initialValue && { defaultValues: initialValue })
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
}
