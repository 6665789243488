import { AiloRN, nsEntities } from "@ailo/ailorn";
import { createMultiModalSelectFilter } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import { useGetTeamsByOrganisationQuery } from "local/graphql";
import { ReportModalFilter } from ".";
import { unassignedTeamId } from "..";

export const useTeamFilter = (): ReportModalFilter => {
  const { id: organisationId, ailoRN: organisationAilorn } =
    useCurrentAgencyOrg();
  const { data } = useGetTeamsByOrganisationQuery({
    variables: { organisationId, organisationAilorn }
  });
  const filterKey = "teamIds";

  const teams = [
    ...(data?.organisation?.teams.map(({ name, id }) => ({
      value: AiloRN.from(id).internalId,
      label: name
    })) ?? [])
  ];

  const stateForAnalytics = (teams: string[]): string[] =>
    teams.map((t) =>
      AiloRN.of(nsEntities.propertymanagement.team, t).toString()
    );

  return {
    ...createMultiModalSelectFilter({
      props: {
        title: "Teams",
        filterKey,
        placeholderItem: {
          label: "Unassigned",
          value: unassignedTeamId(organisationId)
        },
        options: teams
      },
      errorMessage: "At least one team must be selected",
      atLeastOneSelected: true,
      button: {
        pluralName: "teams"
      }
    }),
    stateForAnalytics
  } as ReportModalFilter;
};
