import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Colors, opacify } from "@ailo/primitives";

interface Props {
  selected?: boolean;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

export function PropertyOnboardingListRowContainer({
  selected,
  style,
  children
}: Props): ReactElement {
  return (
    <View
      style={[
        style,
        selected && { backgroundColor: opacify(Colors.OCEAN, 0.1) }
      ]}
    >
      {children}
    </View>
  );
}
