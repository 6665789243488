import { SigningStatus } from "local/graphql";
import { useEffect, useState } from "react";
import { useGetCompleteAction } from "./useGetCompleteAction";

export function useCompleteActionIfSigned({
  projectId,
  actionId,
  signingStatus
}: {
  projectId: string;
  actionId: string;
  signingStatus?: SigningStatus;
}): void {
  const completeAction = useGetCompleteAction(projectId);
  const [isActionCompleted, setIsActionCompleted] = useState(false);
  useEffect(() => {
    if (!isActionCompleted && signingStatus === SigningStatus.Sent) {
      completeAction(actionId);
      setIsActionCompleted(true);
    }
  }, [actionId, completeAction, isActionCompleted, signingStatus]);
}
