import { Colors, Skeleton } from "@ailo/primitives";
import {
  Badge,
  CellContainer,
  DateTimeWithTimeZone,
  TextCell,
  TextCellContent
} from "@ailo/ui";
import React from "react";
import { BasePropertyListRow } from "../BasePropertyListRow";
import { ErrorCell } from "./ErrorCell";
import { useGetAgreementOfMostRecentTenancyQuery } from "local/graphql";

function AgreementEndDateCell({
  row: { managementId }
}: {
  row: BasePropertyListRow;
}): React.ReactElement {
  const { data, loading, refetch } = useGetAgreementOfMostRecentTenancyQuery({
    variables: { managementId }
  });

  if (loading && !data) {
    return <Loading />;
  }

  const agreement = data?.management?.mostRecentTenancy?.latestTenancyAgreement;
  if (!agreement)
    return (
      <ErrorCell
        onPress={(): void => {
          refetch();
        }}
      />
    );

  const agreementEndDate = agreement.fixedTermEndDate;

  if (!agreementEndDate || !!agreement.allowedToLapseAt) {
    return <TextCell color={Colors.TEXT.DARK.SECONDARY}>{"—"}</TextCell>;
  }

  const daysToEndDate =
    DateTimeWithTimeZone.fromLocalDate(agreementEndDate).daysAfterToday();

  if (daysToEndDate > 0)
    return (
      <AgreementEndDateDisplay
        badgeLabel={`${daysToEndDate} ${daysToEndDate === 1 ? "day" : "days"}`}
        dateLabel={DateTimeWithTimeZone.fromLocalDate(agreementEndDate).format(
          "DD MMM YYYY"
        )}
      />
    );

  return (
    <AgreementEndDateDisplay
      badgeLabel={"Periodic Lease"}
      dateLabel={`Since ${DateTimeWithTimeZone.fromLocalDate(
        agreementEndDate
      ).format("DD MMM YYYY")}`}
    />
  );
}

function AgreementEndDateDisplay({
  badgeLabel,
  dateLabel
}: {
  badgeLabel: string;
  dateLabel: string;
}): React.ReactElement {
  return (
    <CellContainer>
      <Badge style={{ marginTop: 2 }}>{badgeLabel}</Badge>
      <TextCellContent
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginTop: 2 }}
      >
        {dateLabel}
      </TextCellContent>
    </CellContainer>
  );
}

function Loading(): React.ReactElement {
  return (
    <CellContainer>
      <Badge.Loading
        style={{
          width: 60,
          marginTop: 2
        }}
      />
      <Skeleton
        style={{
          width: 70,
          height: 12,
          marginTop: 8
        }}
      />
    </CellContainer>
  );
}

AgreementEndDateCell.Loading = Loading;

export { AgreementEndDateCell };
