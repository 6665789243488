import { Link } from "@ailo/services";
import { CommonActions } from "@react-navigation/native";
import { Screens } from "local/common";
import { AiloRN, ID } from "@ailo/ailorn";

export function generateLinkToStartNewChat({
  contactAilorns
}: {
  contactAilorns: AiloRN<"contact:contact">[];
}): Link {
  const contactIds = contactAilorns.map(
    (contactAilorn) => contactAilorn.internalId
  );
  const queryParams = contactIds.map(
    (contactId) => `initialContactIds=${contactId}`
  );

  return {
    // teamId param of "-" allows for the teamId to default to the selected team from the user's local storage
    to: `/comms/-/new-message?${queryParams.join("&")}`,
    action: navigateToStartNewChat(contactIds)
  };
}

function navigateToStartNewChat(
  contactIds: ID<"contact:contact">[]
): CommonActions.Action {
  return CommonActions.navigate(Screens.CommsTab, {
    screen: Screens.NewChatMessage,
    params: {
      chatId: undefined,
      initialContactIds: contactIds
    }
  });
}
