import React, { useMemo } from "react";
import styled from "styled-components/native";
import { Card } from "@ailo/ui";
import { isValidHealthIssue, MigrationHealthIssue } from "./isValidHealthIssue";
import { KeyedHealthIssue } from "./KeyedHealthIssue";
import { MigrationIssueKey } from "local/graphql";
import { HealthIssuesCountHeader } from "./HealthIssuesCountHeader";

export interface MigratingManagementIssueData {
  migratingManagementId: string;
  hasVacatingTenancy: boolean;
  migrateAsVacant: boolean;
}

interface Props {
  issues?: Record<string, any>;
  migratingManagementIssueData: MigratingManagementIssueData;
  setLoading: (loading: boolean) => void;
}

export function KeyedHealthIssuesCard({
  issues = {},
  migratingManagementIssueData,
  setLoading
}: Props): React.ReactElement {
  const { hasVacatingTenancy, migrateAsVacant } = migratingManagementIssueData;

  const computedHealthIssues = useMemo(() => {
    const issues: MigrationHealthIssue[] = [];

    if (hasVacatingTenancy && migrateAsVacant) {
      issues.push({
        key: MigrationIssueKey.VacatingTenancy,
        description: "There is a vacating tenancy"
      });
    }

    return issues;
  }, [hasVacatingTenancy, migrateAsVacant]);

  const sortedHealthIssues = useMemo(() => {
    const filteredHealthIssues = Object.values(issues).reduce<
      MigrationHealthIssue[]
    >((acc, healthIssue) => {
      return isValidHealthIssue(healthIssue) ? [...acc, healthIssue] : acc;
    }, computedHealthIssues);

    return filteredHealthIssues.sort((a, b) => {
      return (a.key ? 1 : 0) - (b.key ? 1 : 0);
    });
  }, [issues, computedHealthIssues]);

  return (
    <StyledCard borderRadius={8}>
      <HealthIssuesCountHeader issues={issues} />
      {sortedHealthIssues.map((issue, i) => (
        <KeyedHealthIssue
          key={i}
          issueKey={issue.key}
          text={issue.description ?? issue.field ?? ""}
          subtext={issue.value}
          migratingManagementIssueData={migratingManagementIssueData}
          setLoading={setLoading}
        />
      ))}
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  margin: 0;
`;
