import React, { useCallback } from "react";
import { PropertyDetails } from "../components/PropertyDetails";
import { BaseSplitScreenModalForm } from "../BaseSplitScreenModalForm";
import { RentReviewForm, RentReviewFormProps } from "./RentReviewForm";
import { useGlobalModal } from "@ailo/ui";
import { RentFragment } from "local/graphql";

export function RentReviewModalForm(
  props: Omit<RentReviewFormProps, "onFormSubmitting" | "onDismiss">
): React.ReactElement {
  const modal = useGlobalModal();
  const { tenancyId, onSuccess } = props;
  const onOperationSuccess = useCallback(
    async (rent: RentFragment) => {
      await onSuccess?.(rent);
      modal.hide();
    },
    [modal, onSuccess]
  );

  const renderFormComponent = useCallback(
    (onFormSubmitting: (isSubmitting: boolean) => void): React.ReactElement => (
      <RentReviewForm
        {...props}
        onSuccess={onOperationSuccess}
        onDismiss={modal.hide}
        onFormSubmitting={(isSubmitting: boolean) =>
          onFormSubmitting(isSubmitting)
        }
      />
    ),
    [props, modal, onOperationSuccess]
  );

  const renderPropertyDetails = useCallback(
    () => <PropertyDetails tenancyId={tenancyId} />,
    [tenancyId]
  );

  return (
    <BaseSplitScreenModalForm
      title={"Rent review"}
      renderFormComponent={renderFormComponent}
      renderRightComponent={renderPropertyDetails}
      onDismiss={modal.hide}
    />
  );
}
