import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { Button } from "@ailo/ui";
import { CreateReiTokenInput } from "local/graphql";
import { ConnectionFormsStatusModal } from "./ConnectionFormsStatusModal";
import { useConnectReiFormsPopup } from "./useConnectReiFormsPopup";

type Props = {
  connectedEmail?: string;
  onFormCallback?: (data: CreateReiTokenInput) => void;
  onFormCallbackError?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
  errors?: string;
  onError: (error: string | undefined) => void;
};

export function ConnectFormsButton({
  connectedEmail,
  onFormCallback,
  onFormCallbackError,
  disabled,
  children,
  errors,
  onError
}: Props): ReactElement {
  const { isOpen, show, close, cancel } = useConnectReiFormsPopup({
    onFormCallback,
    onFormCallbackError
  });

  return (
    <>
      <ConnectButton disabled={disabled} onPress={show}>
        {children}
      </ConnectButton>
      <ConnectionFormsStatusModal
        emailAddress={connectedEmail}
        onClose={close}
        onCancel={cancel}
        visible={isOpen}
        errors={errors}
        onError={onError}
      />
    </>
  );
}

const ConnectButton = styled(Button.Primary)`
  margin-top: 24px;
`;
