import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { useCallback } from "react";
import { Action, useActionAnalytics } from "local/domain/project";
import {
  UpdateActionDueDateMutation,
  useUpdateActionDueDateMutation
} from "local/graphql";

export function useUpdateActionDueDate({
  projectId,
  onCompleted,
  onError
}: {
  projectId: string;
  onCompleted?: ({ action }: { action: Action }) => void;
  onError: () => void;
}): [
  (action: Action, dueDate: string) => void,
  MutationResult<UpdateActionDueDateMutation>
] {
  const { onActionDueDateChange } = useActionAnalytics(projectId);

  const [updateDueDateMutation, result] = useUpdateActionDueDateMutation();

  const updateDueDate = useCallback(
    async (action: Action, dueDate: string): Promise<void> => {
      let result: ExecutionResult<UpdateActionDueDateMutation>;

      try {
        result = await updateDueDateMutation({
          variables: { id: action.id, dueDate }
        });
        throwIfMutationFailed(result, { dataKey: "updateActionDueDate" });
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
        return;
      }

      const updatedAction = result.data!.updateActionDueDate!;

      onActionDueDateChange(updatedAction, action.dueDate);
      onCompleted?.({ action: updatedAction });
    },
    [onActionDueDateChange, updateDueDateMutation, onCompleted, onError]
  );

  return [updateDueDate, result];
}
