import React, { useMemo } from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { AFC } from "@ailo/primitives";
import { DrawerContent } from "./DrawerContent";
import { isPresent } from "ts-is-present";

const Drawer = createDrawerNavigator();

type DrawerItemRenderProp = (props: { focused: boolean }) => React.ReactElement;

interface DrawerElementData {
  name: string;
  title?: string;
  component: React.ComponentType;
  renderDrawerItem?: DrawerItemRenderProp;
}

interface Props {
  navData: DrawerElementData[];
  drawerHeader?: React.ReactElement;
  drawerFooter?: React.ReactElement;
}

type DrawerNavigatorProps = Omit<
  React.ComponentProps<typeof Drawer.Navigator>,
  "drawerContent" | "children"
>;

const BaseDrawerNavigator: AFC<Props & DrawerNavigatorProps> = ({
  navData,
  drawerHeader,
  drawerFooter,
  initialRouteName,
  ...rest
}) => {
  const drawers = useMemo(() => {
    return navData.map(({ name, title, component }) => (
      <Drawer.Screen
        name={name}
        key={name}
        options={{ title }}
        component={component}
      />
    ));
  }, [navData]);
  return (
    <Drawer.Navigator
      drawerContent={({ state }): React.ReactElement => {
        const focusedDrawerItemIndex = state.index;
        return (
          <DrawerContent
            header={drawerHeader}
            footer={drawerFooter}
            drawerItems={navData
              .map(({ renderDrawerItem }, index) =>
                renderDrawerItem?.({
                  focused: focusedDrawerItemIndex === index
                })
              )
              .filter(isPresent)}
          />
        );
      }}
      initialRouteName={initialRouteName}
      {...rest}
    >
      {drawers}
    </Drawer.Navigator>
  );
};

export { BaseDrawerNavigator, DrawerElementData, DrawerItemRenderProp };
