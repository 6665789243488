import React, { ReactElement } from "react";
import { Row, Step } from "local/common";
import { Colors, Text } from "@ailo/primitives";
import { Separator as CommonSeparator } from "@ailo/ui";
import { View } from "react-native";
import { BondReference } from "./BondReference";
import { ClaimedAndReturnedBondAmounts } from "./ClaimedAndReturnedBondAmounts";
import { ClaimTenancyData, getBondAuthorityData } from "local/domain/bond";
import styled from "styled-components/native";
import { EditButton } from "./EditButton";
import { ClaimBondFormConfiguration } from "local/tabs";
import { getState } from "../../../../../../../../common/utils/states";
import { ConfirmClaimActions } from "./ConfirmClaimActions";

export const Separator = styled(CommonSeparator)`
  margin: 40px -60px 32px 0;
`;

type Props = {
  data: ClaimTenancyData;
  formConfiguration: ClaimBondFormConfiguration;
  visible: boolean;
  onEditPress: () => void;
  enableSubmitButton: () => void;
};

export function ConfirmClaimStep({
  data,
  formConfiguration,
  visible,
  onEditPress,
  enableSubmitButton
}: Props): ReactElement {
  const state = getState(data.property.address.state || undefined);
  const bondAuthority = getBondAuthorityData(state);

  if (!bondAuthority) {
    enableSubmitButton();
  }

  return (
    <Step visible={visible}>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
        {formConfiguration.confirmClaimStep.actionDescription}
      </Text.BodyM>
      <Separator />
      <View>
        <Row>
          <Text.BodyL weight={"medium"}>{"Claim details"}</Text.BodyL>
          <EditButton onEditPress={onEditPress} />
        </Row>

        <ClaimedAndReturnedBondAmounts data={data} />
        <BondReference bondReference={data.bond.reference} />
        <ConfirmClaimActions
          data={data}
          bondAuthority={bondAuthority}
          formConfiguration={formConfiguration}
          enableSubmitButton={enableSubmitButton}
        />
      </View>
    </Step>
  );
}
