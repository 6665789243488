import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { Colors, opacify, SettingsIcon, Text } from "@ailo/primitives";

export function SettingsTitle({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): ReactElement {
  return (
    <Row style={style}>
      <SettingsIcon
        color={opacify(Colors.SPACE_BLACK, 0.6)}
        width={32}
        height={32}
      />
      <Text.DisplayM style={{ marginLeft: 12 }}>{"Settings"}</Text.DisplayM>
    </Row>
  );
}

const Row = styled(View)`
  flex-direction: row;
`;
