import { formatPropertyAddressName } from "@ailo/domains";
import {
  didMutationFail,
  didMutationSucceed,
  didQueryNotLoadYet,
  didQuerySucceed,
  ScreenComponent,
  useAnalytics
} from "@ailo/services";
import {
  Alert,
  AlertScreen,
  ErrorAlertScreen,
  Button,
  SidebarStickyBottom,
  SpinnerOverlay,
  TextInput
} from "@ailo/ui";
import { startCase } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import {
  BillDataFetchError,
  BillHeader,
  useBillScreenAnalyticsPayload,
  ViewBillErrorBoundaryFallback
} from "../common";
import {
  useArchiveBillMutation,
  useGetBillArchiveFormDataQuery
} from "local/graphql";

interface ArchiveBillSidebarContentProps {
  billId: string;
  onArchiveSuccess?(): void;
  onGoBack?: (billId: string) => void;
  onClose?: () => void;
}

const StyledFormContainer = styled(View)`
  padding: 32px;
`;

function ArchiveBillSidebarContentInner({
  billId,
  onArchiveSuccess,
  onGoBack,
  onClose
}: ArchiveBillSidebarContentProps): React.ReactElement | null {
  const analytics = useAnalytics();
  const analyticsPayload = useBillScreenAnalyticsPayload({
    billId
  });
  useEffect(() => {
    if (analyticsPayload) {
      analytics.trackScreenVisited("Cancel Bill", analyticsPayload);
    }
  }, [analytics, analyticsPayload]);

  const goBackToBill = useCallback((): void => {
    onGoBack?.(billId);
  }, [onGoBack, billId]);

  const result = useGetBillArchiveFormDataQuery({
    variables: {
      billId
    }
  });

  const [archiveBill, archiveBillResult] = useArchiveBillMutation({
    onCompleted() {
      const bill = result.data?.billById;
      if (!bill) {
        return;
      }

      analytics.track("Bill Cancelled", {
        ...(analyticsPayload ?? { billId: bill.ailoRN.toString() }),
        billStatus: bill ? startCase(bill.agencyStatus) : undefined
      });

      onArchiveSuccess?.();
    }
  });
  const [archiveReason, setArchiveReason] = useState("");

  if (didQueryNotLoadYet(result)) {
    return (
      <View>
        <BillHeader.Loading title={"Cancel Bill"} onGoBack={goBackToBill} />
      </View>
    );
  }

  if (!didQuerySucceed(result)) {
    if (result.error) {
      throw new BillDataFetchError(result.error.message, result.error);
    }
    throw new BillDataFetchError(
      "useGetBillArchiveFormDataQuery() failed to load"
    );
  }

  const payeeName =
    result.data.billById.supplier?.internalLegalEntity?.organisation?.name ||
    result.data.billById.supplier?.name;
  const property = result.data.billById.management?.property;
  const propertyName = formatPropertyAddressName(property);

  function submit(): void {
    archiveBill({
      variables: {
        billId,
        reason: archiveReason
      }
    });
  }

  if (didMutationSucceed(archiveBillResult)) {
    return (
      <AlertScreen
        variant={"sidebar"}
        type={"house-and-document"}
        title={"Bill Cancelled"}
        description={`Bill ${payeeName ? `for ${payeeName}` : ""} ${
          propertyName ? `at ${propertyName}` : ""
        } has been cancelled.`}
        bottomButtons={[
          {
            type: "secondary",
            label: "View Bill",
            onPress: goBackToBill
          },
          {
            type: "secondary",
            label: "Close",
            onPress: onClose
          }
        ]}
        onClose={onClose}
      />
    );
  }

  if (didMutationFail(archiveBillResult)) {
    return (
      <ErrorAlertScreen
        variant={"sidebar"}
        title={"There was a problem\narchiving this bill"}
        onRetry={submit}
      />
    );
  }

  return (
    <View style={{ flex: 1 }}>
      <BillHeader
        billId={billId}
        title={"Cancel Bill"}
        // Bill has been fetched already at ViewBill, no need to refetch it here
        fetchPolicy={"cache-first"}
        onGoBack={goBackToBill}
      />
      <StyledFormContainer>
        <TextInput
          value={archiveReason}
          placeholder={"Add a reason for cancelling this bill..."}
          multiline
          numberOfLines={4}
          focusLabel={"Reason for cancelling"}
          onChange={(event): void => setArchiveReason(event.nativeEvent.text)}
        />

        <Alert
          type={"info"}
          message={
            "This reason is only visible to you and your team. Please consider sending a message via chat to let the payer of this bill know it has been cancelled."
          }
          style={{ marginTop: 24 }}
        />
      </StyledFormContainer>

      <SidebarStickyBottom>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Button.Secondary onPress={goBackToBill}>{"Close"}</Button.Secondary>
          <Button.Primary disabled={archiveBillResult.loading} onPress={submit}>
            {"Cancel Bill"}
          </Button.Primary>
        </View>
      </SidebarStickyBottom>

      {archiveBillResult.loading && <SpinnerOverlay />}
    </View>
  );
}

export function ArchiveBillSidebarContent(
  props: ArchiveBillSidebarContentProps
): React.ReactElement {
  return (
    <ScreenComponent
      name={"Cancel Bill"}
      analytics={false}
      errorBoundaryOptions={{
        fallbackComponent: ViewBillErrorBoundaryFallback(props.onClose)
      }}
    >
      <ArchiveBillSidebarContentInner {...props} />
    </ScreenComponent>
  );
}
