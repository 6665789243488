import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Money, MoneyInput } from "@ailo/ui";
import { BondDetailsFormData } from "../BondDetailsFormData";

export function AmountInput({
  defaultValue
}: {
  defaultValue?: Money;
}): React.ReactElement {
  const { control, errors } = useFormContext<BondDetailsFormData>();
  const name = "amount";
  return (
    <Controller
      name={name}
      defaultValue={defaultValue || null}
      control={control}
      render={({ onChange, onBlur, value, ref }): React.ReactElement => (
        <MoneyInput
          value={value}
          inputRef={ref}
          error={errors[name]?.message}
          inputTestID={"AmountInput"}
          withTrailingZeros
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
      rules={{
        required: "Enter amount",
        validate: (amount: Money) => amount.cents > 0 || "Enter non-zero amount"
      }}
    />
  );
}
