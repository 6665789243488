import { AiloRN, services } from "@ailo/ailorn";
import { ArchiveBillSidebarContent } from "local/domain/bill";
import { ExpenseDetailView } from "local/domain/expense";
import { ManagementFeeBlueprintsSidebar } from "local/domain/fee/managementFeeBlueprint";
import { SidebarForm } from "local/tabs";
import React, { Dispatch } from "react";
import {
  AddBondDetailsSidebar,
  EditBondDetailsSidebar,
  EditBondReferenceSidebar,
  EditEndTenancySidebar,
  ExpensesListSidebar,
  LeaseRenewalSidebar,
  LedgerEntrySidebarContent,
  PropertyWalletMoneyManagementSettingsSidebarContent,
  RentReviewSidebar
} from "./components";
import { ViewKeysSidebar } from "./components/PropertyContent";
import { ViewBondClaimSideBar } from "./components/ViewBondClaimSidebar";

export function getSidebarContent(
  managementId: string,
  clearSidebarForm: () => void,
  setSidebarForm: Dispatch<React.SetStateAction<SidebarForm | undefined>>,
  sidebarForm?: SidebarForm,
  expenseListSidebarActive?: boolean,
  onOwnerOrTenantPress?: (id: string, entityType: "Person" | "Company") => void
): React.ReactElement | null {
  return sidebarForm?.type === "vacate" ||
    sidebarForm?.type === "editEndTenancy" ? (
    <EditEndTenancySidebar
      isEdit={sidebarForm?.type === "editEndTenancy"}
      tenancyId={sidebarForm.id}
      managementId={managementId}
      onDismiss={clearSidebarForm}
    />
  ) : sidebarForm?.type === "leaseRenewal" ? (
    <LeaseRenewalSidebar
      tenancyId={sidebarForm.id}
      prefillStartDate={sidebarForm.prefill?.startDate}
      onDismiss={clearSidebarForm}
    />
  ) : sidebarForm?.type === "rentReview" ? (
    <RentReviewSidebar
      tenancyId={sidebarForm.id}
      mode={sidebarForm.mode}
      prefill={sidebarForm.prefill}
      onDismiss={clearSidebarForm}
    />
  ) : sidebarForm?.type === "billArchive" ? (
    <ArchiveBillSidebarContent
      billId={sidebarForm.id}
      onGoBack={(billId: string): void => {
        setSidebarForm((previousDetails) => ({
          ...previousDetails,
          id: AiloRN.of(services.Bill.bill, billId).toString(),
          type: "expenseView"
        }));
      }}
      onClose={clearSidebarForm}
    />
  ) : sidebarForm?.type === "liabilityEntryView" ? (
    <LedgerEntrySidebarContent
      managementId={managementId}
      liabilityEntryId={sidebarForm.id}
      onClose={clearSidebarForm}
    />
  ) : sidebarForm?.type === "moneyManagementSettings" ? (
    <PropertyWalletMoneyManagementSettingsSidebarContent
      managementId={managementId}
      onLegalEntityPress={onOwnerOrTenantPress}
      onClose={clearSidebarForm}
    />
  ) : sidebarForm?.type === "expensesList" ? (
    <ExpensesListSidebar
      initialSelectedTab={sidebarForm.initialSelectedTab}
      initialTenancyId={sidebarForm.initialTenancyId}
      managementId={managementId}
      onClose={clearSidebarForm}
      onExpensePress={(ailorn) =>
        setSidebarForm({
          id: ailorn.toString(),
          type: "expenseView",
          initialSelectedTab: "management"
        })
      }
    />
  ) : sidebarForm?.type === "expenseView" ? (
    <ExpenseDetailView
      ailorn={AiloRN.fromString(sidebarForm.id)}
      onCancelExpensePress={
        AiloRN.fromString(sidebarForm.id).isA(services.Bill.bill)
          ? (ailorn: AiloRN): void => {
              return setSidebarForm((previousDetails) => ({
                ...previousDetails,
                id: ailorn.internalId,
                type: "billArchive"
              }));
            }
          : undefined
      }
      onClose={
        expenseListSidebarActive
          ? () =>
              setSidebarForm((previousDetails) => ({
                ...previousDetails,
                id: "",
                type: "expensesList"
              }))
          : clearSidebarForm
      }
    />
  ) : sidebarForm?.type === "addBondDetails" ? (
    <AddBondDetailsSidebar
      managementId={managementId}
      tenancyId={sidebarForm.id}
      onClose={clearSidebarForm}
    />
  ) : sidebarForm?.type === "editBondDetails" ? (
    <EditBondDetailsSidebar
      managementId={managementId}
      tenancyId={sidebarForm.id}
      onClose={clearSidebarForm}
    />
  ) : sidebarForm?.type === "viewBondClaim" ? (
    <ViewBondClaimSideBar
      managementId={managementId}
      tenancyId={sidebarForm.id}
      onClose={clearSidebarForm}
    />
  ) : sidebarForm?.type === "editBondReference" ? (
    <EditBondReferenceSidebar
      managementId={managementId}
      tenancyId={sidebarForm.id}
      onClose={clearSidebarForm}
    />
  ) : sidebarForm?.type === "managementFeeBlueprints" ? (
    <ManagementFeeBlueprintsSidebar
      managementId={sidebarForm.id}
      onClose={clearSidebarForm}
    />
  ) : sidebarForm?.type === "viewKeys" ? (
    <ViewKeysSidebar
      managementId={sidebarForm.id}
      onClose={() => setSidebarForm(undefined)}
    />
  ) : null;
}
