export enum OnboardingListView {
  AWAITING_APPROVAL = "Awaiting Approval",
  EXCLUDED = "Excluded",
  APPROVED = "Approved",
  LIVE = "Live"
}

export interface OnboardingTabParamList {
  tab?: OnboardingListView;
  migratingManagementId?: string;
}
