import { Controller, UseFormMethods } from "react-hook-form";
import React from "react";
import { FormFieldRow, TextInputFormField } from "@ailo/ui";
import { validateCrn } from "../utils/validateCrn";
import { AddCentrepayerFormData } from "./CentrepaySetupModal";

export function CrnInput({
  form
}: {
  form: UseFormMethods<AddCentrepayerFormData>;
}): React.ReactElement {
  return (
    <FormFieldRow style={{ marginTop: 26, marginBottom: 60 }}>
      <Controller
        control={form.control}
        name={"crn"}
        render={({ value, onChange, onBlur }) => (
          <TextInputFormField
            {...{ value, onChange, onBlur }}
            error={form.errors["crn"]?.message}
            helperText={
              "The CRN is different to the CAN (Customer Account Number)"
            }
            label={"CRN (Customer Reference Number)"}
            uppercase={true}
            numberOfLines={1}
            placeholder={"Enter CRN"}
            softCharacterLimit={{
              enforcementLevel: "error",
              limit: 10
            }}
            testID={"AddCentrepayerFormField"}
          />
        )}
        rules={{
          required: "Please enter a CRN",
          validate: { validFormat: validateCrn }
        }}
      />
    </FormFieldRow>
  );
}
