import { Address, formatAddress } from "@ailo/domain-helpers";
import { Colors, Text } from "@ailo/primitives";
import React from "react";
import { View } from "react-native";

export function FormattedAddressCell({
  address
}: {
  address: Address;
}): React.ReactElement {
  return (
    <View style={{ flexDirection: "column" }}>
      <Text.BodyM weight={"medium"} color={Colors.TEXT.DARK.PRIMARY}>
        {formatAddress(address, {
          format: "street"
        })}
      </Text.BodyM>

      {address.suburb && (
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
          {address.suburb}
        </Text.BodyS>
      )}
    </View>
  );
}
