import { useGetManagementNotes } from "local/domain/propertyManagement/hooks/useGetManagementNote";
import {
  ManagementNoteModalForm,
  useGetManagementNoteModalFormStates
} from "local/modals/ManagementNoteModalForm";
import React, { ReactElement, useCallback } from "react";
import { NotesAndKeysButton } from "./NotesAndKeysButton";

export function PropertyNotesButton({
  managementId
}: {
  managementId: string;
}): ReactElement {
  const {
    modalStates: {
      visible: noteFormModalVisible,
      setVisible: setNoteFormModalVisible
    }
  } = useGetManagementNoteModalFormStates();

  const {
    data: managementNote,
    loading: loadingManagementNote,
    refetch: refetchNotes
  } = useGetManagementNotes(managementId);

  const openNotesFormModal = useCallback(() => {
    setNoteFormModalVisible(true);
  }, [setNoteFormModalVisible]);

  if (loadingManagementNote) {
    return <></>;
  }

  return (
    <>
      {managementNote?.note ? (
        <NotesAndKeysButton onPress={openNotesFormModal}>
          {"Edit notes"}
        </NotesAndKeysButton>
      ) : (
        <NotesAndKeysButton onPress={openNotesFormModal}>
          {"Add notes"}
        </NotesAndKeysButton>
      )}
      <ManagementNoteModalForm
        managementId={managementId}
        managementNoteId={managementNote?.id}
        note={managementNote?.note}
        onDismiss={() => {
          setNoteFormModalVisible(false);
        }}
        onSuccess={() => {
          setNoteFormModalVisible(false);
          refetchNotes();
        }}
        visible={noteFormModalVisible}
      />
    </>
  );
}
