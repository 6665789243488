import { ClaimTenancyData } from "local/domain/bond";
import { useFormContext } from "react-hook-form";
import { ClaimBondFormData } from "../ClaimBondFormData";
import { useSubmit } from "./useSubmit";
import { Dispatch, SetStateAction, useCallback } from "react";
import {
  CLAIM_LIABILITIES_STEP,
  CONFIRM_CLAIM_STEP,
  Step,
  STEP_NAMES
} from "./stepNames";
import { ClaimBondFormConfiguration } from "local/tabs";

export function useOnStepChange(
  data: ClaimTenancyData,
  currentStepNumber: number,
  setCurrentStep: (step: Step) => void,
  disableSubmitButton: Dispatch<SetStateAction<boolean>>,
  formConfiguration: ClaimBondFormConfiguration
): (newStep: number) => void {
  const { trigger } = useFormContext<ClaimBondFormData>();
  const submit = useSubmit({ data, formConfiguration });

  return useCallback(
    async (newStep: number): Promise<void> => {
      switch (STEP_NAMES[currentStepNumber - 1]) {
        case CLAIM_LIABILITIES_STEP: {
          const formIsValid = await trigger();
          if (formIsValid) {
            setCurrentStep(newStep);
            disableSubmitButton(
              !formConfiguration.confirmClaimStep.submitEnabledByDefault
            );
          }
          break;
        }
        case CONFIRM_CLAIM_STEP: {
          if (newStep > currentStepNumber) {
            await submit();
            setCurrentStep(CLAIM_LIABILITIES_STEP);
            break;
          }
          goBackToClaimLiabilitiesStep(
            setCurrentStep,
            newStep,
            disableSubmitButton
          );
          break;
        }
        default:
          break;
      }
    },
    [
      currentStepNumber,
      setCurrentStep,
      trigger,
      submit,
      disableSubmitButton,
      formConfiguration.confirmClaimStep.submitEnabledByDefault
    ]
  );
}

function goBackToClaimLiabilitiesStep(
  setCurrentStep: (step: Step) => void,
  newStep: number,
  disableSubmitButton: Dispatch<SetStateAction<boolean>>
): void {
  setCurrentStep(newStep);
  disableSubmitButton(false);
}
