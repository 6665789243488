import { GridSortOrder } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import {
  PlatformFeatureId,
  MigratingManagementSortField,
  SortDirection,
  useGetPagedApprovedMigratingManagementsLazyQuery
} from "local/graphql";
import { useCallback, useMemo } from "react";
import { useGetPropertyOnboardingListQueryReturn } from "./useGetPropertyOnboardingListQueryReturn";
import { PROPERTY_ONBOARDING_LIST_PAGE_SIZE } from "./usePaginationRequest";
import { isPresent } from "ts-is-present";
import {
  ApprovedMigratingManagementExtraVariables,
  KeyedApprovedMigratingManagementFragment,
  PropertyOnboardingListQueryData,
  PropertyOnboardingListRequestParams
} from "../types";
import { ApprovedPropertyOnboardingListColumnNames } from "../Approved/hooks";
import { useHasFeature } from "@ailo/domains";

export type ApprovedPropertyOnboardingListQuery =
  PropertyOnboardingListQueryData<
    KeyedApprovedMigratingManagementFragment,
    ApprovedMigratingManagementExtraVariables
  >;

export function useApprovedPropertyOnboardingListQuery({
  initialSortOrder
}: {
  initialSortOrder: GridSortOrder[];
}): ApprovedPropertyOnboardingListQuery {
  const currentOrg = useCurrentAgencyOrg();
  const hasPublishPropertyFeature = useHasFeature(
    PlatformFeatureId.OnboardingTabPublishProperty
  );
  const filterStatus = useMemo(
    () =>
      hasPublishPropertyFeature ? ["APPROVED", "IN_PROGRESS"] : ["APPROVED"],
    [hasPublishPropertyFeature]
  );

  const [request, { data, loading, called, refetch }] =
    useGetPagedApprovedMigratingManagementsLazyQuery({
      fetchPolicy: "cache-and-network"
    });

  const sendRequest = useCallback(
    ({
      requestParams
    }: {
      requestParams?: PropertyOnboardingListRequestParams;
    }) => {
      const {
        before = false,
        cursor = null,
        sort = gridSortStateToApiParam(initialSortOrder)
      } = requestParams ?? {};

      request({
        variables: {
          cursor,
          before,
          pageSize: PROPERTY_ONBOARDING_LIST_PAGE_SIZE,
          organisationId: currentOrg.ailoRN,
          sort,
          status: filterStatus
        }
      });
    },
    [initialSortOrder, filterStatus, request, currentOrg.ailoRN]
  );

  const pageInfo = data?.migratingManagements?.pageInfo;
  const migratingManagements =
    data?.migratingManagements?.items.filter(isPresent);

  return useGetPropertyOnboardingListQueryReturn({
    sendRequest,
    pageInfo,
    migratingManagements,
    loading: loading && !data,
    called,
    gridSortStateToApiParam,
    refetch
  });
}

const gridSortStateToApiParam = (
  gridSortOrders: GridSortOrder[]
): {
  field: MigratingManagementSortField;
  direction: SortDirection;
}[] => {
  return gridSortOrders.map(({ direction, columnKey }) => {
    const directionForApi =
      direction === "DESC" ? SortDirection.Desc : SortDirection.Asc;

    return { field: getApiSortField(columnKey), direction: directionForApi };
  });
};

function getApiSortField(columnKey: string): MigratingManagementSortField {
  if (columnKey === ApprovedPropertyOnboardingListColumnNames.Address)
    return MigratingManagementSortField.Address;

  if (columnKey === ApprovedPropertyOnboardingListColumnNames.Portfolio)
    return MigratingManagementSortField.Portfolio;

  if (columnKey === ApprovedPropertyOnboardingListColumnNames.ApprovedOn)
    return MigratingManagementSortField.ApprovedAt;

  if (columnKey === ApprovedPropertyOnboardingListColumnNames.PublishStatus)
    return MigratingManagementSortField.PublishStatus;

  throw new Error(
    `Cannot sort awaiting approval property list by column ${columnKey}`
  );
}
