import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { useCallback } from "react";
import {
  CreateReiTokenMutation,
  useCreateReiTokenMutation,
  CreateReiTokenInput
} from "local/graphql";

export function useCreateReiToken({
  onCompleted,
  onError
}: {
  onCompleted?: () => void;
  onError?: () => void;
} = {}): [
  (createReiTokenInput: CreateReiTokenInput) => void,
  MutationResult<CreateReiTokenMutation>
] {
  const [createReiTokenMutation, result] = useCreateReiTokenMutation();
  const analytics = useAnalytics();

  const createReiToken = useCallback(
    async (createReiTokenInput: CreateReiTokenInput): Promise<void> => {
      try {
        const result = await createReiTokenMutation({
          variables: {
            createReiTokenInput,
            organisationAilorn: createReiTokenInput.organisationAilorn
          }
        });

        throwIfMutationFailed(result, { dataKey: "createReiToken" });
        onCompleted?.();

        analytics.track("REI Account Added", {
          state: createReiTokenInput.supportedAustralianState
        });
      } catch (error) {
        AiloSentry.captureException(error);
        onError?.();
      }
    },
    [analytics, createReiTokenMutation, onCompleted, onError]
  );

  return [createReiToken, result];
}
