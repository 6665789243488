import {
  ManagementFilterParams,
  ManagementFilterTypes,
  OccupancyStatus
} from "local/graphql";

export function getOccupancyStatusFilterParams(
  occupancyStatuses?: OccupancyStatus[] | null
): ManagementFilterParams[] {
  return occupancyStatuses && occupancyStatuses.length > 0
    ? [
        {
          name: ManagementFilterTypes.HasOccupancyStatus,
          args: { HasOccupancyStatus: occupancyStatuses }
        }
      ]
    : [];
}
