import { ClaimTenancyData } from "local/domain/bond";
import { useFormContext } from "react-hook-form";
import { ClaimBondFormData } from "../ClaimBondFormData";
import {
  useUpsertTenancyBondClaimsMutation,
  GetClaimTenancyBondDetailsDocument
} from "local/graphql";
import { mapFormToMutationData } from "./mapFormToMutationData";
import { ClaimBondFormConfiguration } from "local/tabs";

export function useSubmit({
  data,
  formConfiguration
}: {
  data: ClaimTenancyData;
  formConfiguration: ClaimBondFormConfiguration;
}): () => void {
  const { handleSubmit } = useFormContext<ClaimBondFormData>();

  const [upsertTenancyBondClaimsMutation] =
    useUpsertTenancyBondClaimsMutation();

  return handleSubmit(async (formData) => {
    const claims = mapFormToMutationData(formData);

    const result = await upsertTenancyBondClaimsMutation({
      variables: {
        bond: {
          bondId: data.bond.id,
          claims
        }
      },
      refetchQueries: [
        {
          query: GetClaimTenancyBondDetailsDocument,
          variables: { tenancyId: data.tenancyId }
        }
      ]
    });

    formConfiguration.onSubmitSuccess(result);
  });
}
