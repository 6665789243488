import React from "react";
import { View } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { SFC } from "@ailo/primitives";
import { Money, MoneyInput } from "@ailo/ui";
import { AddBillFormData } from "local/domain/bill/AddBillForm/AddBillFormData";

interface Props {
  index: number;
}

const LineItemAmountInput: SFC<Props> = ({ index, style }) => {
  const { control, setValue, errors } = useFormContext<AddBillFormData>();
  const name = `lineItems[${index}].amount`;

  return (
    <View style={style}>
      <Controller
        name={name}
        defaultValue={null}
        control={control}
        render={({ onChange, onBlur, value, ref }): React.ReactElement => (
          <MoneyInput
            value={value}
            inputRef={ref}
            error={errors.lineItems?.[index]?.amount?.message}
            inputTestID={"LineItemAmountInput"}
            withTrailingZeros
            onChange={onChange}
            onFocus={() => {
              setValue("fieldUnderInvoiceNumberWasFocused", true);
            }}
            onBlur={onBlur}
          />
        )}
        rules={{
          required: "Enter amount",
          validate: (amount: Money) =>
            amount.cents > 0 || "Enter non-zero amount"
        }}
      />
    </View>
  );
};

export { LineItemAmountInput };
