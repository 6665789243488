import { LeftChevronIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import React from "react";
import { useInspectionPlanner } from "../../InspectionPlannerContext";
import { StyleProp, ViewStyle } from "react-native";

export function BackButton({
  style
}: {
  style?: StyleProp<ViewStyle>;
}): React.ReactElement | null {
  const { setSelectedInspectionDate } = useInspectionPlanner();

  return (
    <Button
      variant={"secondary"}
      style={style}
      square
      type={"small"}
      onPress={() => {
        setSelectedInspectionDate(null);
      }}
    >
      <LeftChevronIcon height={20} width={20} alignCenter />
    </Button>
  );
}
