import React, { ReactElement } from "react";
import { IconButton, Tooltip } from "@ailo/ui";
import { NewMessageIcon } from "@ailo/primitives";
import { useSharedPressableLinkTo } from "@ailo/domains";
import { AiloRN } from "@ailo/ailorn";

interface Props {
  participantContactAilorns: AiloRN<"contact:contact">[];
}

export const MessageContactsIconButton = React.memo(
  function MessageContactsIconButton({
    participantContactAilorns
  }: Props): ReactElement {
    const linkProps = useSharedPressableLinkTo("startNewChat", {
      contactAilorns: participantContactAilorns
    });

    return (
      <Tooltip tooltipContent={"Message"}>
        <IconButton Icon={NewMessageIcon} {...linkProps} />
      </Tooltip>
    );
  }
);
