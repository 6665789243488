import { Colors, IconSkeleton, PlusIcon, Text } from "@ailo/primitives";
import { Button, ErrorAlertScreen } from "@ailo/ui";
import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import styled from "styled-components/native";

export interface AddButtonProps {
  label: string;
  variant?: "solid" | "plus";
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
}

export function AddButton({
  label,
  variant = "plus",
  onPress,
  style
}: AddButtonProps): ReactElement {
  return variant === "plus" ? (
    <Button.Text
      onPress={onPress}
      leftIcon={() => (
        <PlusIcon
          color={Colors.AILO_RED}
          width={24}
          height={24}
          style={{ marginRight: 14 }}
        />
      )}
      backgroundColor={{
        default: Colors.WHITE,
        hovered: Colors.INTERACTION.WHITE.HOVER,
        pressed: Colors.INTERACTION.WHITE.PRESSED,
        active: Colors.INTERACTION.WHITE.PRESSED,
        disabled: Colors.CLOUD
      }}
      style={[
        {
          justifyContent: "flex-start",
          borderRadius: 4,
          paddingHorizontal: 14,
          paddingVertical: 8
        },
        style
      ]}
      contentStyle={{ alignItems: "center" }}
    >
      {label}
    </Button.Text>
  ) : (
    <Button onPress={onPress} style={style ?? undefined}>
      {label}
    </Button>
  );
}

AddButton.Loading = function Loading({
  variant = "plus",
  style
}: Pick<AddButtonProps, "variant" | "style">): ReactElement {
  return variant === "plus" ? (
    <LoadingContainer style={style}>
      <IconSkeleton />
      <Text.BodyM.Loading width={200} style={{ marginLeft: 18 }} />
    </LoadingContainer>
  ) : (
    <Button.Loading style={[{ width: 200 }, style]} />
  );
};

AddButton.Error = function Error({
  message,
  onRetry
}: {
  message?: string;
  onRetry: () => void | Promise<void>;
}): ReactElement {
  return <ErrorAlertScreen title={message} onRetry={onRetry} />;
};

const LoadingContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  padding: 8px 14px;
`;
