import React, { useState, useCallback } from "react";
import { useDownloadBase64File } from "@ailo/domains";
import { Button, ButtonProps } from "@ailo/ui";
import { useGetReiFormRemoteSigningPdf } from "./useGetReiFormRemoteSigningPdf";
import { useReiFormDownloadButtonError } from "./useReiFormDownloadButtonError";

export function ReiFormDownloadSignedButton({
  formId,
  disabled,
  ...props
}: {
  formId: string;
} & ButtonProps): React.ReactElement {
  const [loading, setLoading] = useState(false);

  const { downloadFile } = useDownloadBase64File();

  const getReiFormRemoteSigningPdf = useGetReiFormRemoteSigningPdf(formId);

  const showErrorToast = useReiFormDownloadButtonError();

  const downloadSignedPdf = useCallback(async (): Promise<void> => {
    const form = await getReiFormRemoteSigningPdf();

    if (form.__typename !== "ReiForm") {
      throw new TypeError("Unexpected form type");
    }

    const signedPdfBase64String = form.remoteSigningPDF;

    if (!signedPdfBase64String || typeof signedPdfBase64String !== "string") {
      throw new Error("Could not download signed PDF");
    }

    downloadFile({
      fileContents: signedPdfBase64String,
      fileFormat: "application/pdf",
      fileName: `${form?.reiFormInfo?.name || "form"}_signed.pdf`.replace(
        / /g,
        "_"
      )
    });
  }, [getReiFormRemoteSigningPdf, downloadFile]);

  const onPressDownloadButton = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      await downloadSignedPdf();
    } catch (_error) {
      showErrorToast();
    } finally {
      setLoading(false);
    }
  }, [downloadSignedPdf, showErrorToast]);

  return (
    <Button {...props} loading={loading} onPress={onPressDownloadButton}>
      {"Download a copy"}
    </Button>
  );
}
