import {
  AiloSentry,
  throwIfMutationFailed,
  useAnalytics
} from "@ailo/services";
import { useAlertContext, useToastContext } from "@ailo/ui";
import { useCurrentAgencyOrg } from "local/common";
import {
  FeeBlueprintType,
  FeeFrequency,
  useUpdateFeeBlueprintMutation,
  GetFeeBlueprintsDocument
} from "local/graphql";
import { useCallback } from "react";

interface Blueprint {
  id: string;
  name: string;
  frequency?: FeeFrequency | null;
  type: FeeBlueprintType;
}

export function useChangeFeeBlueprintArchiveStatus(): {
  archive(blueprint: Blueprint): Promise<void>;
  unarchive(blueprint: Blueprint): Promise<void>;
} {
  const { id: organisationId } = useCurrentAgencyOrg();
  const { confirm } = useAlertContext();
  const toasts = useToastContext();
  const analytics = useAnalytics();

  const [updateMutation] = useUpdateFeeBlueprintMutation({
    refetchQueries: [
      {
        query: GetFeeBlueprintsDocument,
        variables: {
          conditions: {
            organisationId,
            archived: true
          }
        }
      },
      {
        query: GetFeeBlueprintsDocument,
        variables: {
          conditions: {
            organisationId,
            archived: false
          }
        }
      }
    ]
  });

  const unarchive = useCallback(
    async (blueprint: Blueprint) => {
      try {
        const result = await updateMutation({
          variables: {
            input: {
              id: blueprint.id,
              archived: false
            }
          }
        });
        throwIfMutationFailed(result, { dataKey: "blueprint" });
      } catch (error) {
        AiloSentry.captureException(error);
        toasts.showFormSubmitError();
        return;
      }

      toasts.show({
        type: "success",
        message: "Fee template restored"
      });
      analytics.track("Fee Blueprint Restored", {
        feeBlueprintId: blueprint.id,
        feeFrequency: blueprint.frequency ?? "one-off"
      });
    },
    [toasts, updateMutation, analytics]
  );

  const archive = useCallback(
    (blueprint: Blueprint) => async () => {
      try {
        const result = await updateMutation({
          variables: {
            input: {
              id: blueprint.id,
              archived: true
            }
          }
        });
        throwIfMutationFailed(result, { dataKey: "blueprint" });
      } catch (error) {
        AiloSentry.captureException(error);
        toasts.showFormSubmitError();
        return;
      }
      toasts.show({
        type: "success",
        message: "Fee template archived"
      });
      analytics.track("Fee Blueprint Archived", {
        feeBlueprintId: blueprint.id,
        feeFrequency: blueprint.frequency ?? "one-off"
      });
    },
    [analytics, toasts, updateMutation]
  );

  const confirmArchive = useCallback(
    async (blueprint: Blueprint) => {
      await confirm({
        title: `Archive ${blueprint.name}`,
        message: `By archiving ${
          blueprint.name
        }, you will no longer offer it to new customers. Existing customers will not be affected${
          blueprint.type === FeeBlueprintType.RecurringFee
            ? `: ${blueprint.name} will continue to be charged until the end of their management agreement`
            : ""
        }.`,
        confirmLabel: "Archive",
        destructive: true,
        onConfirm: archive(blueprint)
      });
    },
    [confirm, archive]
  );

  return { unarchive, archive: confirmArchive };
}
