import { useAnalytics, uuid } from "@ailo/services";
import { startCase } from "lodash";
import { useMemo } from "react";
import { CreateSupplierFormData } from "./CreateSupplierForm";

interface Supplier {
  ailoRN: string;
}

type Data = CreateSupplierFormData;

const fieldsToIgnore = ["addressState", "addressPostcode"];

interface UseCreateSupplierAnalytics {
  trackFormOpened: () => void;
  trackValueUpdated: (
    fieldName: string,
    isValid: boolean | undefined,
    value: string,
    formValues: Data
  ) => void;
  trackSubmitSuccess: (supplier: Supplier, formValues: Data) => void;
}

export function useCreateSupplierAnalytics(): UseCreateSupplierAnalytics {
  const formSessionId = useMemo(() => uuid(), []);
  const analytics = useAnalytics();

  return useMemo(() => {
    const formName = "Add Supplier";

    function trackFormOpened(): void {
      analytics.trackScreenVisited("Add Supplier", {
        form_name: formName,
        form_session_id: formSessionId
      });
    }

    function trackValueUpdated(
      fieldName: string,
      isValid: boolean | undefined,
      value: string,
      formValues: Data
    ): void {
      analytics.track("Form Step Completed", {
        form_name: formName,
        form_session_id: formSessionId,
        step_name: startCase(fieldName),
        value: censorValue(value),
        value_is_valid:
          isValid === undefined ? "Pending" : isValid ? "Yes" : "No",
        steps_completed_this_far: formValuesToStepsCompleted(formValues)
      });
    }

    function trackSubmitSuccess(supplier: Supplier, formValues: Data): void {
      analytics.track("Supplier Added", {
        form_name: formName,
        form_session_id: formSessionId,
        supplier_id: supplier.ailoRN,
        steps_completed: formValuesToStepsCompleted(formValues)
      });
    }

    return {
      trackFormOpened,
      trackValueUpdated,
      trackSubmitSuccess
    };
  }, [analytics, formSessionId]);
}

function formValuesToStepsCompleted(formValues: Data): string[] {
  return Object.keys(formValues)
    .filter((key) => !!formValues[key as keyof Data])
    .filter((key) => !fieldsToIgnore.includes(key))
    .map(startCase);
}

function censorValue(text: string): string {
  return text.length > 1 ? text[0] + text.slice(1).replace(/./g, "*") : text;
}
