import {
  didQueryNotLoadYet,
  throwIfQueryFailed,
  useOnFocus
} from "@ailo/services";
import {
  FormField,
  FormFieldRow,
  SelectInput,
  TextInputFormField
} from "@ailo/ui";
import { capitaliseFirstLetter } from "local/domain/utils";
import { useGetFeeEventsQuery } from "local/graphql";
import React, { ReactElement, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ManageFeeBlueprintFormData } from "./ManageFeeBlueprintForm";

export function FeeEventField({
  mode
}: {
  mode: "create" | "update";
}): ReactElement {
  const form = useFormContext<ManageFeeBlueprintFormData>();

  const feeEventsResult = useGetFeeEventsQuery({
    fetchPolicy: "cache-first"
  });
  throwIfQueryFailed(feeEventsResult, { dataKey: "feeEvents" });
  useOnFocus(feeEventsResult.refetch);
  const loading = didQueryNotLoadYet(feeEventsResult);

  useEffect(() => {
    if (feeEventsResult.data?.feeEvents?.length === 1) {
      form.control.setValue("event", feeEventsResult.data.feeEvents[0].type);
    }
  });

  const disabled = feeEventsResult.data?.feeEvents?.length === 1;

  return (
    <FormFieldRow style={{ marginBottom: 24 }}>
      <Controller
        control={form.control}
        name={"event"}
        defaultValue={null}
        disabled={mode === "update"}
        render={({ value, onChange, onBlur }) =>
          loading ? (
            <FormFieldRow style={{ marginBottom: 24 }}>
              <TextInputFormField.Loading />
            </FormFieldRow>
          ) : (
            <FormField
              label={"How should the fee be triggered"}
              error={form.errors.event?.message}
            >
              <SelectInput
                {...{ value, onBlur }}
                placeholder={"Select"}
                options={feeEventsResult.data!.feeEvents!.map((event) => ({
                  label: capitaliseFirstLetter(event.longDescription),
                  value: event.type
                }))}
                onChange={(option) => {
                  onChange(option.value);
                }}
                disabled={disabled}
                useTextInputHeight
              />
            </FormField>
          )
        }
        rules={{
          required: "Event is required."
        }}
      />
    </FormFieldRow>
  );
}
