import { AiloSentry, throwIfMutationFailed } from "@ailo/services";
import { MutationResult } from "@apollo/client";
import { useCallback } from "react";

import {
  CompleteAssignTenancyAgreementActionMutation,
  useCompleteAssignTenancyAgreementActionMutation
} from "local/graphql";

export function useCompleteAssignTenancyAgreementAction({
  onCompleted,
  onError
}: {
  onCompleted: () => void;
  onError: () => void;
}): [
  ({
    actionId,
    tenancyId,
    startDate,
    fixedTermEndDate
  }: {
    actionId: string;
    tenancyId: string;
    startDate: string;
    fixedTermEndDate?: string | null;
  }) => Promise<void>,
  MutationResult<CompleteAssignTenancyAgreementActionMutation>
] {
  const [completeAssignTenancyAgreementActionMutation, result] =
    useCompleteAssignTenancyAgreementActionMutation();

  const completeAssignTenancyAgreementAction = useCallback(
    async ({
      actionId,
      tenancyId,
      startDate,
      fixedTermEndDate
    }): Promise<void> => {
      const mutationInput = {
        tenancyId,
        agreementDetails: {
          startDate,
          fixedTermEndDate: fixedTermEndDate || null
        }
      };

      try {
        const result = await completeAssignTenancyAgreementActionMutation({
          variables: {
            id: actionId,
            input: mutationInput
          }
        });
        throwIfMutationFailed(result, {
          dataKey: "completeAssignTenancyAgreementAction"
        });
        onCompleted?.();
      } catch (error) {
        AiloSentry.captureException(error);
        onError();
      }
    },
    [onCompleted, completeAssignTenancyAgreementActionMutation, onError]
  );

  return [completeAssignTenancyAgreementAction, result];
}
