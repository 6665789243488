import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components/native";
import {
  ManagementPortfolioListItem,
  ManagementPortfolioListItemLoadingProps
} from "../ManagementPortfolioListItem";
import { Card, ErrorAlertScreen, Separator } from "@ailo/ui";
import { ErrorBoundary } from "@ailo/services";
import { View } from "react-native";
import { Colors } from "@ailo/primitives";

interface Props {
  title: string;
  titleRight?: ReactNode;
  children?: ReactNode;
}

interface LoadingProps
  extends Omit<ManagementPortfolioListItemLoadingProps, "isLastItem"> {
  numberOfItems: number;
}

interface ErrorProps {
  entityDisplayType: string;
  onRetry?: () => void | Promise<void>;
}

interface ErrorBoundaryProps {
  entityDisplayType: string;
  children?: ReactNode;
}

export function ManagementPortfolioContentCard({
  title,
  titleRight,
  children
}: Props): ReactElement {
  return (
    <StyledCard
      title={title}
      titleRight={
        <StyledCardTitleRightContainer>
          {titleRight}
        </StyledCardTitleRightContainer>
      }
      titleStyle={{
        color: Colors.TEXT.DARK.PRIMARY,
        marginHorizontal: 16,
        marginTop: 16,
        marginBottom: 0
      }}
      titleFontWeight={"medium"}
      hasTitleSeparator={false}
    >
      <StyledSeparator />
      <ManagementPortfolioContentErrorBoundary entityDisplayType={title}>
        {children}
      </ManagementPortfolioContentErrorBoundary>
    </StyledCard>
  );
}

ManagementPortfolioContentCard.Loading =
  function ManagementPortfolioContentCardLoading({
    numberOfItems,
    leftIconShape
  }: LoadingProps): ReactElement {
    return (
      <StyledCardLoading
        titleWidth={120}
        titleFontWeight={"medium"}
        variant={"list-card"}
        hasTitleSeparator={false}
      >
        <StyledSeparator />
        {[...Array(numberOfItems).keys()].map((index) => (
          <ManagementPortfolioListItem.Loading
            key={index}
            leftIconShape={leftIconShape}
            isLastItem={index === numberOfItems - 1}
          />
        ))}
      </StyledCardLoading>
    );
  };

ManagementPortfolioContentCard.Error =
  function ManagementPortfolioContentError({
    entityDisplayType,
    onRetry
  }: ErrorProps): React.ReactElement {
    return (
      <StyledCard variant={"list-card"}>
        <ManagementPortfolioContentCard.ErrorContent
          entityDisplayType={entityDisplayType}
          onRetry={onRetry}
        />
      </StyledCard>
    );
  };

ManagementPortfolioContentCard.ErrorContent =
  function ManagementPortfolioContentErrorContent({
    entityDisplayType,
    onRetry
  }: ErrorProps): React.ReactElement {
    return (
      <ErrorAlertScreen
        variant={"medium"}
        title={`There was a problem loading ${entityDisplayType.toLowerCase()}`}
        onRetry={onRetry}
        retryType={"query"}
      />
    );
  };

function ManagementPortfolioContentErrorBoundary({
  entityDisplayType,
  children
}: ErrorBoundaryProps): React.ReactElement {
  return (
    <ErrorBoundary
      fallbackComponent={(): ReactElement => (
        <ManagementPortfolioContentCard.ErrorContent
          entityDisplayType={entityDisplayType}
        />
      )}
    >
      {children}
    </ErrorBoundary>
  );
}

const StyledCard = styled(Card)`
  margin-top: 16px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
`;

const StyledCardTitleRightContainer = styled(View)`
  margin-top: 16px;
  margin-horizontal: 16px;
`;

const StyledCardLoading = styled(Card.Loading)`
  margin-top: 16px;
`;

const StyledSeparator = styled(Separator)`
  margin-left: 0;
`;
