import React, { ReactElement, useCallback, useState } from "react";
import { NestedValue, useForm } from "react-hook-form";
import { StyleProp, ViewStyle } from "react-native";
import { Text } from "@ailo/primitives";
import { ConfirmModal, MoneyInterface } from "@ailo/ui";
import { FilesState, FilesStateFile, useHasFeature } from "@ailo/domains";
import { LeaseAgreementDetailsStep } from "./LeaseAgreementDetailsStep";
import { TenantDetailsStep } from "./TenantDetailsStep";
import { ReviewTenancyDetailsStep } from "./ReviewTenancyDetailsStep";
import { TenantDetails, useCreateTenancy } from "../useCreateTenancy";
import { MultiStepForm } from "local/common";
import { isPresent } from "ts-is-present";
import {
  PlatformFeatureId,
  useGetMostRecentTenancyEndQuery,
  TenancyFragment,
  RentFrequency
} from "local/graphql";
import { DepositDetailsStep } from "./DepositDetailsStep";

export interface NewTenancyFormData {
  leaseType: LeaseTypes;
  agreementStartDate?: string | null;
  rentStartDate?: string | null;
  agreementEndDate?: string | null;
  rentReviewDate?: string | null;
  rentAmount?: MoneyInterface | null;
  rentFrequency?: RentFrequency | null;
  tenants?: NestedValue<{ details: TenantDetails }[]>;
  files?: Array<FilesStateFile> | null;
  depositEnabled?: boolean | null;
  depositAmount?: MoneyInterface | null;
}

export enum LeaseTypes {
  FixedTerm = "Fixed Term",
  Periodic = "Periodic"
}

interface Props {
  managementId: string;
  onTenancyCreated?(tenancy: TenancyFragment): void;
  onError?(): void;
  onCancel?(): void;
  style?: StyleProp<ViewStyle>;
}

type StepIds = "LeaseAgreement" | "Tenants" | "Deposit" | "Review";

type Step = {
  stepId: StepIds;
  render: (visible: boolean) => React.ReactElement;
};

const step1Fields = [
  "leaseType",
  "agreementStartDate",
  "rentStartDate",
  "rentReviewDate",
  "rentAmount",
  "rentFrequency"
];

export function NewTenancyForm({
  onTenancyCreated,
  onError,
  onCancel,
  managementId,
  style
}: Props): ReactElement {
  const { data } = useGetMostRecentTenancyEndQuery({
    variables: { managementId }
  });
  const previousTenancyEndDate = data?.management?.mostRecentTenancy?.endDate;
  const hasDepositsFeature = useHasFeature(PlatformFeatureId.Deposits);

  const [currentStepNumber, setCurrentStep] = useState<number>(1);
  const form = useForm<NewTenancyFormData>({
    defaultValues: { leaseType: LeaseTypes.FixedTerm },
    mode: "onChange"
  });

  const { reset, trigger, watch, formState, handleSubmit } = form;
  const { isSubmitting, isDirty } = formState;

  const leaseType = watch("leaseType");
  const tenantArray = watch("tenants");
  const tenantEmails = tenantArray
    ?.map((tenant) => tenant.details.email)
    .filter(isPresent);

  const fileArray = watch("files");
  const uploadingFiles = fileArray?.filter((file) => !file.id);
  const [filesUploadingErrorModalVisible, setFilesUploadingErrorModalVisible] =
    useState(false);

  const submit = handleSubmit(
    useSubmit({
      managementId,
      onCompleted: async (tenancy: TenancyFragment) => {
        await onTenancyCreated?.(tenancy);
        reset();
        setCurrentStep(1);
      },
      onError
    })
  );

  const [filesState, setFilesState] = useState<FilesState>();

  const cancel = useCallback(() => {
    reset();
    onCancel?.();
    setCurrentStep(1);
  }, [onCancel, reset]);

  const renderStepLeaseAgreement = useCallback(
    (visible: boolean): React.ReactElement => (
      <LeaseAgreementDetailsStep
        key={"New-Tenancy-Form-Step-LeaseAgreement"}
        form={form}
        setFilesState={setFilesState}
        display={visible}
        previousTenancyEndDate={previousTenancyEndDate || undefined}
        allowFileUploads={true}
      />
    ),
    [form, previousTenancyEndDate]
  );

  const renderStepTenants = useCallback(
    (visible: boolean): React.ReactElement => (
      <TenantDetailsStep
        key={"New-Tenancy-Form-Step-Tenants"}
        form={form}
        display={visible}
      />
    ),
    [form]
  );

  const renderStepDeposit = useCallback(
    (visible: boolean): React.ReactElement => (
      <DepositDetailsStep
        key={"New-Tenancy-Form-Step-Deposit"}
        form={form}
        display={visible}
      />
    ),
    [form]
  );

  const renderStepReview = useCallback(
    (visible: boolean): React.ReactElement => (
      <ReviewTenancyDetailsStep
        key={"New-Tenancy-Form-Step-Review"}
        form={form}
        filesState={filesState}
        display={visible}
        onEditLeasePress={(): void => setCurrentStep(1)}
        onEditTenantsPress={(): void => setCurrentStep(2)}
        onEditDepositPress={(): void => setCurrentStep(3)}
      />
    ),
    [form, filesState]
  );

  const steps = [
    {
      stepId: "LeaseAgreement",
      render: renderStepLeaseAgreement
    },
    {
      stepId: "Tenants",
      render: renderStepTenants
    },
    hasDepositsFeature
      ? {
          stepId: "Deposit",
          render: renderStepDeposit
        }
      : undefined,
    {
      stepId: "Review",
      render: renderStepReview
    }
  ].filter(isPresent) as Step[];

  const onNextButtonPress = useCallback(
    async (newStepNumber: number) => {
      if (newStepNumber < currentStepNumber) {
        setCurrentStep(newStepNumber);
        return;
      }
      const currentStepId = steps[currentStepNumber - 1].stepId;

      switch (currentStepId) {
        case "LeaseAgreement": {
          const step1Valid = await trigger(
            leaseType === LeaseTypes.FixedTerm
              ? [...step1Fields, "agreementEndDate"]
              : step1Fields
          );
          if (step1Valid) {
            setCurrentStep(newStepNumber);
          }
          break;
        }
        case "Tenants": {
          if (!tenantArray || tenantArray.length === 0) {
            form.setError("tenants", {
              type: "required",
              message: "Please add at least one renter for a new tenancy"
            });
          } else if (!tenantEmails || tenantEmails.length === 0) {
            form.setError("tenants", {
              type: "required",
              message:
                "Please add at least one renter with an email address to access Ailo"
            });
          } else {
            setCurrentStep(newStepNumber);
          }
          break;
        }
        case "Deposit": {
          const depositEnabled = watch("depositEnabled");
          const depositValid =
            !depositEnabled || (await trigger(["depositAmount"]));

          if (depositValid) {
            setCurrentStep(newStepNumber);
          }
          break;
        }
        case "Review": {
          if (uploadingFiles && uploadingFiles.length > 0) {
            setFilesUploadingErrorModalVisible(true);
          } else {
            submit();
          }
          break;
        }
      }
    },
    [
      currentStepNumber,
      steps,
      trigger,
      leaseType,
      tenantArray,
      tenantEmails,
      form,
      watch,
      uploadingFiles,
      submit
    ]
  );

  return (
    <>
      <MultiStepForm
        style={style}
        title={"Create New Tenancy"}
        submitButtonText={"Create Tenancy"}
        stepValue={currentStepNumber}
        onChange={onNextButtonPress}
        onCancel={cancel}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        steps={steps.map((step) => step.render)}
        headerBackButtonDisabled
        hideCancelButton
        showFooterBackButton
      />
      <ConfirmModal
        visible={filesUploadingErrorModalVisible}
        title={"Files still uploading..."}
        confirmLabel={"Ok"}
        onConfirm={() => setFilesUploadingErrorModalVisible(false)}
      >
        <Text.BodyM weight={"book"}>
          {
            "Please wait while we finish uploading your files. Uploading large files on slow internet connections may affect your wait time."
          }
        </Text.BodyM>
      </ConfirmModal>
    </>
  );
}

function useSubmit({
  managementId,
  onCompleted,
  onError
}: {
  managementId: string;
  onCompleted?: (tenancy: TenancyFragment) => void;
  onError?: () => void;
}): (data: NewTenancyFormData) => Promise<void> {
  const [addNewTenancy] = useCreateTenancy({
    onCompleted,
    onError
  });

  return useCallback(
    async (data: NewTenancyFormData) => {
      const { agreementStartDate, rentStartDate, rentFrequency } = data;
      if (agreementStartDate == null) {
        throw new TypeError(
          "Agreement start date of a new tenancy cannot be null or undefined"
        );
      }

      if (rentStartDate == null) {
        throw new TypeError(
          "Rent start date of a new tenancy cannot be null or undefined"
        );
      }

      if (rentFrequency == null) {
        throw new TypeError(
          "Rent frequency of a new tenancy cannot be null or undefined"
        );
      }

      return addNewTenancy({
        managementId,
        formData: { ...data, agreementStartDate, rentStartDate, rentFrequency }
      });
    },
    [addNewTenancy, managementId]
  );
}
