import { formatAddress, getFormattedRentSchedule } from "@ailo/domain-helpers";
import { LocalDate, Separator } from "@ailo/ui";
import { SplitScreenInfoPanel } from "local/common";
import { InvestorChips, TenantChips } from "local/domain/propertyManagement";
import {
  Address,
  ProjectStatus,
  ProjectType,
  GetPropertyDetailsForTenancyQuery
} from "local/graphql";
import React, { ReactElement } from "react";
import styled from "styled-components/native";
import { isPresent } from "ts-is-present";

export interface DetailsListProps {
  address?: Address;
  type?: ProjectType;
  status?: ProjectStatus;
  reference?: string;
  description?: string | null;
}

export function PropertyDetailsUI({
  tenancy
}: {
  tenancy: NonNullable<GetPropertyDetailsForTenancyQuery["tenancy"]>;
}): ReactElement {
  const { displayRent, liability, management, property, tenantships } = tenancy;

  const rows = [
    [
      {
        label: "Property",
        content:
          formatAddress(property.address, {
            format: "street suburb state"
          }) || null
      }
    ],
    [
      {
        label: "Rent",
        content: displayRent ? getFormattedRentSchedule(displayRent) : null
      },
      {
        label: "Rent paid to date",
        content: liability?.effectivePaidToDate
          ? new LocalDate(liability?.effectivePaidToDate).format("D MMM YYYY")
          : null
      }
    ],
    [
      {
        content: <StyledSeparator />
      }
    ],
    [
      {
        label: "Investors",
        content: management?.owners ? (
          <InvestorChips
            owners={management.owners
              .map((ownership) => ownership?.owner)
              .filter(isPresent)}
          />
        ) : null
      }
    ],
    [
      {
        label: "Renters",
        content: tenantships ? (
          <TenantChips
            tenants={tenantships.items
              .map((tenantship) => tenantship.tenant)
              .filter(isPresent)}
          />
        ) : null
      }
    ]
  ];

  return <SplitScreenInfoPanel rows={rows} />;
}

const StyledSeparator = styled(Separator)`
  margin-top: 2px;
  margin-bottom: 4px;
  margin-left: 0;
  width: 308px;
`;
