import React, { useCallback, useEffect, useState } from "react";
import { Colors, Text } from "@ailo/primitives";
import { Button, ErrorAlertScreen, useToastContext } from "@ailo/ui";
import {
  ClaimedFilesCount,
  InspectionFragment,
  useGetActionDetailsLazyQuery,
  useGetProjectFilesLazyQuery
} from "local/graphql";
import {
  useGenerateInspectionReport,
  useGetInspectionCanGenerateReport
} from "local/domain/inspection";
import { InspectionViewUI } from "./InspectionViewUI";

export function InspectionSynced({
  projectId,
  actionId,
  inspection,
  inspectionFilesCount
}: {
  projectId: string;
  actionId: string;
  inspection: InspectionFragment;
  inspectionFilesCount: ClaimedFilesCount;
}): React.ReactElement {
  const {
    data: canGenerateReport,
    startPolling,
    stopPolling,
    error,
    loading,
    refetch
  } = useGetInspectionCanGenerateReport(inspection.id);

  const { latestReportProgress } = inspection;

  const toast = useToastContext();

  const [shouldRefetchProjectFiles, setShouldRefetchProjectFiles] =
    useState(false);
  const [refetchProjectFiles] = useGetProjectFilesLazyQuery({
    fetchPolicy: "network-only"
  });
  const [refetchAction] = useGetActionDetailsLazyQuery({
    fetchPolicy: "network-only"
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const [generateInspection] = useGenerateInspectionReport();

  const refetchProjectFilesCb = useCallback(() => {
    setTimeout(() => {
      refetchProjectFiles({
        variables: {
          id: projectId,
          pageSize: 1000
        }
      });
      refetchAction({
        variables: {
          id: actionId
        }
      });
      setButtonLoading(false);
      toast.show({
        type: "success",
        message: "Inspection report saved to files"
      });
    }, 3000);
    setShouldRefetchProjectFiles(false);
  }, [refetchProjectFiles, projectId, refetchAction, actionId, toast]);

  useEffect(() => {
    if (canGenerateReport === false) {
      startPolling(5000);
      setButtonLoading(true);
      setShouldRefetchProjectFiles(true);
      return;
    }

    if (canGenerateReport) {
      stopPolling();
      if (
        shouldRefetchProjectFiles === true &&
        latestReportProgress?.completedAt
      ) {
        refetchProjectFilesCb();
      }

      if (latestReportProgress?.failedAt) {
        setButtonLoading(false);
      }
    }
  }, [
    canGenerateReport,
    startPolling,
    stopPolling,
    shouldRefetchProjectFiles,
    latestReportProgress,
    refetchProjectFilesCb
  ]);

  const onGenerateButtonPress = useCallback(async () => {
    setButtonLoading(true);
    await generateInspection(inspection.id);
    await refetch();
  }, [generateInspection, inspection.id, refetch]);

  if (loading) {
    return <Text.Loading variant={"BodyM"} width={100} />;
  }

  if (error) {
    return (
      <ErrorAlertScreen
        title={"There was a problem fetching the inspection information."}
        onRetry={refetch}
      />
    );
  }

  const button = (
    <Button
      onPress={onGenerateButtonPress}
      loading={buttonLoading}
      variant={"primary"}
      style={{
        width: 111
      }}
    >
      {"Create PDF"}
    </Button>
  );

  const footer = buttonLoading ? (
    <>
      <Text.BodyS style={{ marginTop: 12 }} color={Colors.TEXT.DARK.SECONDARY}>
        {"Just a moment, we’re preparing your report."}
      </Text.BodyS>
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
        {"This may take a few minutes depending on the number of photos. "}
      </Text.BodyS>
    </>
  ) : null;

  return (
    <InspectionViewUI
      inspection={inspection}
      inspectionFilesCount={inspectionFilesCount}
      description={"Click create PDF to save the condition report to files."}
      button={button}
      footer={footer}
    />
  );
}
