import React from "react";
import { Colors, Text } from "@ailo/primitives";
import styled from "styled-components/native";

export function ComeBackSoonMessage(): React.ReactElement {
  return (
    <StyledBodyS>
      <StyledBodyS color={Colors.TEXT.DARK.SECONDARY}>
        {"Come back soon "}
      </StyledBodyS>
      <StyledBodyS>{"👋"}</StyledBodyS>
    </StyledBodyS>
  );
}

const StyledBodyS = styled(Text.BodyS)`
  text-align: center;
`;
