import React, { ReactElement } from "react";
import { ActionDetailsFragment } from "local/graphql";
import { Colors, Text } from "@ailo/primitives";
import {
  DepositData,
  DepositStatusBadge,
  getDescription
} from "local/domain/propertyManagement";
import { GenericActionCard } from "../GenericActionCard";
import { useEnvironment } from "@ailo/services";
import { BaseActionCard } from "../BaseActionCard";
import { ActionCardContent } from "../shared/ActionCardContent";
import { ActionCardFooter } from "../shared/ActionCardFooter";
import { Money } from "@ailo/ui";

type Props = {
  action: ActionDetailsFragment;
  projectId: string;
  depositData?: DepositData;
};

export function ManageDepositActionCardBase({
  action,
  projectId,
  depositData
}: Props): ReactElement {
  const env = useEnvironment();

  if (!depositData) {
    return <GenericActionCard projectId={projectId} action={action} />;
  }

  return (
    <BaseActionCard
      action={action}
      projectId={projectId}
      titleRight={
        <Text.BodyL>{Money.from(depositData.amount).format()}</Text.BodyL>
      }
      hasTitleSeparator
    >
      <ActionCardContent
        description={getDescription({
          deposit: depositData,
          contactUsUrl: env.AILO_CONTACT_US_HELP_URL
        })}
        style={{ color: Colors.TEXT.DARK.SECONDARY, marginBottom: 20 }}
      />
      <ActionCardFooter
        action={action}
        additionalStatus={
          <DepositStatusBadge
            style={{ marginLeft: 4 }}
            status={depositData.displayStatus}
          />
        }
      />
    </BaseActionCard>
  );
}
