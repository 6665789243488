import React, { ReactElement } from "react";
import { View } from "react-native";
import { Badge, ListItem, OnOffBadge } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import styled from "styled-components/native";

interface MoneySettingListItem {
  title: string;
  subHeader?: string;
  enabled: boolean;
}

export function MoneySettingListItem({
  title,
  subHeader,
  enabled
}: MoneySettingListItem): ReactElement | null {
  const subHeaderComponent = (
    <View>
      <BadgeContainer>
        <OnOffBadge enabled={enabled} />
      </BadgeContainer>
      {subHeader && (
        <Text.BodyS
          style={{ paddingTop: 4 }}
          color={Colors.TEXT.DARK.SECONDARY}
          textTransform={"capitalize"}
        >
          {subHeader}
        </Text.BodyS>
      )}
    </View>
  );
  return <ListItem header={title} subHeader={subHeaderComponent} />;
}

MoneySettingListItem.Loading = function MoneySettingListItemLoading({
  headerWidth
}: {
  headerWidth?: number;
}): ReactElement | null {
  return (
    <ListItem
      header={<Text.BodyM.Loading width={headerWidth || 120} />}
      subHeader={
        <BadgeContainer>
          <Badge.Loading />
        </BadgeContainer>
      }
    />
  );
};

const BadgeContainer = styled(View)`
  flex-direction: row;
  padding-top: 4px;
`;
