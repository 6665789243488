import React, { ReactElement } from "react";
import {
  DisbursementCategory,
  GridDisbursementsList
} from "local/tabs/trustaccounting";

function DefaultDisbursementsList({
  tabNavigation,
  category
}: {
  tabNavigation: ReactElement;
  category?: DisbursementCategory;
}): ReactElement {
  return (
    <GridDisbursementsList category={category} tabNavigation={tabNavigation} />
  );
}

export { DefaultDisbursementsList };
