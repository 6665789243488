import { useCallback } from "react";
import { DropdownMenuOption, useToastContext } from "@ailo/ui";
import { ProjectStatus } from "local/graphql";
import { useProjectAnalytics } from "local/domain/project";
import { useCloseProject } from "./useCloseProject";
import { useReopenProject } from "./useReopenProject";

export function useChangeStatusOption({
  projectId,
  projectStatus,
  managementId,
  setShowCloseModal,
  icon
}: {
  projectId: string;
  projectStatus: ProjectStatus;
  managementId?: string;
  setShowCloseModal: (show: boolean) => void;
  icon: React.ReactElement;
}): {
  dropdownMenuOption: DropdownMenuOption;
  closeProject: (projectId: string, managementId?: string) => void;
} {
  const toast = useToastContext();
  const { onProjectStatusChanged } = useProjectAnalytics(projectId);

  const [closeProject] = useCloseProject({
    onError: () => {
      toast.show({
        type: "error",
        message: "Unable to close. Please try again."
      });
    },
    onCompleted: ({ project }) => {
      toast.show({
        type: "success",
        message: "Project closed"
      });
      onProjectStatusChanged(project);
    }
  });

  const [reopenProject] = useReopenProject({
    onError: () => {
      toast.show({
        type: "error",
        message: "Unable to open. Please try again."
      });
    },
    onCompleted: ({ project }) => {
      toast.show({
        type: "success",
        message: "Project opened"
      });
      onProjectStatusChanged(project);
    }
  });

  const onChangeProjectStatusPress = useCallback(() => {
    projectStatus === ProjectStatus.Open
      ? setShowCloseModal(true)
      : reopenProject(projectId, managementId);
  }, [
    projectStatus,
    setShowCloseModal,
    projectId,
    reopenProject,
    managementId
  ]);

  return {
    dropdownMenuOption: {
      label:
        projectStatus === ProjectStatus.Open ? "Close Project" : "Open Project",
      icon,
      onSelect: (): void => onChangeProjectStatusPress()
    },
    closeProject
  };
}
