import React, { ReactElement, useMemo } from "react";
import { LedgerBalancesItem } from "../types";
import {
  BalanceCard,
  BalanceCardLoading,
  BalanceRow,
  BalanceTotalRowSuccess,
  BalanceTotalRowWarning
} from "../BalanceCard";
import { Money } from "@ailo/money";
import moment from "moment";
import styled from "styled-components/native";

interface Props {
  asOf: string;
  items: LedgerBalancesItem[];
  isReconciling: boolean;
}

const PAYABLE_BALANCE_CARD_TITLE = "Ledger balances";

const ENTITY_TYPE_DESCRIPTION_MAP: Record<string, string> = {
  "ledgers:system": "Ailo",
  "propertymanagement:managementfolio": "Investors",
  "propertymanagement:tenancy": "Renters",
  "authz:legalentity": "Agency",
  "bill:supplier": "Suppliers"
};

export function PayableBalanceCard({
  asOf,
  items,
  isReconciling
}: Props): ReactElement {
  const balance = useMemo(
    () => items.reduce((acc, { amount }) => acc.add(amount), Money.zero()),
    [items]
  );

  const ledgerBalances = useMemo(
    () =>
      items.map((item, index) => (
        <BalanceRow amount={item.amount} key={index}>
          {ENTITY_TYPE_DESCRIPTION_MAP[item.entityType] ?? "Unknown"}
        </BalanceRow>
      )),
    [items]
  );

  const totalDescription = asOf
    ? `Wallets balances at ${moment(asOf).format("DD MMMM YYYY")}`
    : "No wallet balances available";

  const BalanceTotalRow = isReconciling
    ? BalanceTotalRowSuccess
    : BalanceTotalRowWarning;

  return (
    <BalanceCard title={PAYABLE_BALANCE_CARD_TITLE}>
      {ledgerBalances}
      <BalanceTotalRow amount={balance}>{totalDescription}</BalanceTotalRow>
    </BalanceCard>
  );
}

export const PayableBalanceCardLoading = styled(BalanceCardLoading).attrs({
  title: PAYABLE_BALANCE_CARD_TITLE,
  rowsToLoad: 5
})``;
