import React, { ReactElement, useState, useCallback } from "react";
import { RefinedActionActivityProps } from "../../ActionActivityProps";
import { useCurrentAgencyOrg } from "local/common";
import { useCurrentUser } from "@ailo/domains";
import { formatAddress } from "@ailo/domain-helpers";
import { useGetFormGenerationDataQuery, ProjectType } from "local/graphql";
import { SelectTemplateViewUI } from "./SelectTemplateViewUI";
import { ReiTemplateOption } from "./types";
import { useCreateReiFormFromUserTemplate } from "./useCreateReiFormFromUserTemplate";
import { useCompleteAssignFormAction } from "./useCompleteAssignFormAction";
import { AiloRN } from "@ailo/ailorn";
import { isProjectOfTypeWithMeta, Project } from "local/domain/project/project";
import { getManagementIfExists } from "local/domain/project";

export function SelectTemplateView({
  action,
  projectId,
  displayDeletedFormAlert = false
}: RefinedActionActivityProps & {
  displayDeletedFormAlert?: boolean;
}): ReactElement {
  const organisationAilorn = useCurrentAgencyOrg().ailoRN;

  const { data, loading, error, refetch } = useGetFormGenerationDataQuery({
    variables: {
      personAilorn: useCurrentUser().person.ailoRN,
      organisationAilorn,
      projectId
    },
    fetchPolicy: "cache-and-network"
  });

  const { completeAssignFormAction, loading: isCompletingAction } =
    useCompleteAssignFormAction();

  const onSuccessfulFormGeneration = useCallback(
    async (formAilorn: AiloRN) => {
      await completeAssignFormAction({
        actionId: action.id,
        formAilorn
      });
    },
    [completeAssignFormAction, action.id]
  );

  const [createReiFormFromUserTemplate, isGenerating] =
    useCreateReiFormFromUserTemplate({
      onSuccess: onSuccessfulFormGeneration
    });

  const [selectedReiTemplate, setSelectedReiTemplate] =
    useState<ReiTemplateOption>();

  const project = action.project;
  const management = getManagementIfExists(project);
  const address = management?.property.address;

  const { tenancyAilornString } = getFormPrefillData({ project });

  if (loading) {
    return <SelectTemplateViewUI.Loading />;
  }

  if (error || data?.legalEntity?.__typename !== "Person" || !address) {
    return <SelectTemplateViewUI.Error onRetry={refetch} />;
  }

  const reiTemplates = data.legalEntity.reiTemplatesForOrg;

  return (
    <>
      <SelectTemplateViewUI
        onGenerateFormPress={() => {
          isReiTemplateOptionValid(selectedReiTemplate) &&
            createReiFormFromUserTemplate({
              reiUserTemplateId: +selectedReiTemplate.value,
              name: getReiFormName({
                address,
                templateName: selectedReiTemplate.name
              }),
              organisationAilorn,
              tenancyAilorn: tenancyAilornString
            });
        }}
        generateDisabled={
          !selectedReiTemplate || isGenerating || isCompletingAction
        }
        templateOptions={gqlReiTemplatesToTemplateOptions(reiTemplates)}
        selectedTemplate={selectedReiTemplate}
        onChange={(reiTemplate) => setSelectedReiTemplate(reiTemplate)}
        displayDeletedFormAlert={displayDeletedFormAlert}
      />
    </>
  );
}

function getReiFormName({
  address,
  templateName
}: {
  address: { unitStreetNumber: string; streetName: string };
  templateName?: string;
}): string {
  return `${formatAddress(address, { format: "street" })} - ${templateName}`;
}

function gqlReiTemplatesToTemplateOptions(
  reiTemplates: { id: number; name: string; code: string }[]
): ReiTemplateOption[] {
  return reiTemplates.map((reiTemplate) => ({
    label: `[${reiTemplate.code}] ${reiTemplate.name}`,
    value: reiTemplate.id.toString(),
    name: reiTemplate.name
  }));
}

function isReiTemplateOptionValid(
  reiTemplate: ReiTemplateOption | undefined
): reiTemplate is ReiTemplateOption {
  return !!(reiTemplate && reiTemplate.name && reiTemplate.value);
}

export function getFormPrefillData({ project }: { project: Project }): {
  tenancyAilornString?: string;
} {
  return {
    tenancyAilornString: getTenancyAilornFromProject(project)?.toString()
  };
}

function getTenancyAilornFromProject(project: Project): AiloRN | undefined {
  let tenancyAilornString;

  if (isProjectOfTypeWithMeta(project, ProjectType.LeaseRenewal)) {
    tenancyAilornString = project.meta.leaseRenewalTenancy?.ailoRN;
  }

  if (isProjectOfTypeWithMeta(project, ProjectType.NewTenancy)) {
    tenancyAilornString = project.meta.newTenancy?.ailoRN;
  }

  return tenancyAilornString ? AiloRN.from(tenancyAilornString) : undefined;
}
