import React from "react";
import { ScreenComponent } from "@ailo/services";
import { ViewBondClaimProps } from "./ViewBondClaimProps";
import { ViewBondClaimSideBarContent } from "./components";

export function ViewBondClaimSideBar(
  props: ViewBondClaimProps
): React.ReactElement {
  return (
    <ScreenComponent name={"View Bond Claim"}>
      <ViewBondClaimSideBarContent {...props} />
    </ScreenComponent>
  );
}
