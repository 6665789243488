import { CancelFormIcon } from "@ailo/primitives";
import { DropdownMenuOption } from "@ailo/ui";
import {
  NotAbleToCancelReason,
  useGetStopVacatingDataQuery
} from "local/graphql";
import React from "react";

export interface StopVacatingOptionProps {
  tenancyId: string;
  onPress(): void;
}

export function useGetStopVacatingOption({
  tenancyId,
  onPress
}: StopVacatingOptionProps): DropdownMenuOption | undefined {
  const { data } = useGetStopVacatingDataQuery({
    variables: { id: tenancyId }
  });

  if (!data?.tenancy?.canCancelVacate) {
    return;
  }

  const { ableToCancel, notAbleToCancelReason } = data.tenancy.canCancelVacate;
  const baseOption = {
    label: "Stop vacating tenancy",
    icon: <CancelFormIcon />,
    onSelect: onPress
  };

  if (ableToCancel) {
    return baseOption;
  }

  if (notAbleToCancelReason && DISABLED_TOOLTIPS[notAbleToCancelReason]) {
    return {
      ...baseOption,
      disabled: true,
      tooltip: DISABLED_TOOLTIPS[notAbleToCancelReason]
    };
  }
}

const DISABLED_TOOLTIPS = {
  [NotAbleToCancelReason.ManagementIsEnding]:
    "You must stop ending the management first",
  [NotAbleToCancelReason.ManagementHasIngoingTenancy]:
    "Please contact Support to end the ingoing tenancy",
  [NotAbleToCancelReason.AlreadyChargedForPeriodWithEndDate]:
    "The renters have already been charged for this period, please contact Support",
  [NotAbleToCancelReason.TenancyIsNotVacating]: undefined,
  [NotAbleToCancelReason.TenancyEndDateIsInThePast]: undefined
};
