import React, { useCallback } from "react";
import { Alert, Separator } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { DirectEntryUserIdFormInput } from "./DirectEntryUserIdFormInput";
import { AbaFileFormatInput } from "./AbaFileFormatInput";
import { Row } from "local/common";
import { SaveButton } from "./SaveButton";
import { useErrorSavingDetails } from "./useErrorSavingDetails";
import { Gap } from "./Gap";
import { Loading } from "./BankingDetailFormLoading";
import { StickyBottom } from "./StickyBottom";
import { useFormContext } from "react-hook-form";

export function BankingDetailsForm({
  refetchData
}: {
  refetchData: () => void;
}): React.ReactElement {
  const { reset } = useFormContext();
  const { errorSavingDetails, setError, clearError } = useErrorSavingDetails();
  const onReload = useCallback(() => {
    reset();
    clearError();
  }, [reset, clearError]);

  return (
    <>
      {errorSavingDetails && (
        <Alert type={"error"} style={{ marginTop: 32 }}>
          <Row style={{ width: "100%" }}>
            <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
              {"Unable to save banking details."}
            </Text.BodyM>
            <Text.BodyM color={Colors.TEXT.RED} onPress={onReload}>
              {"Reload"}
            </Text.BodyM>
          </Row>
        </Alert>
      )}

      <Gap height={40} />
      <Header>{"EFT Payments"}</Header>

      <Gap height={20} />
      <DirectEntryUserIdFormInput />

      <Gap height={24} />
      <AbaFileFormatInput />

      <StickyBottom>
        <Separator style={{ marginLeft: -32, marginRight: -32 }} />
        <Gap height={16} />
        <Row>
          <SaveButton
            onSubmit={clearError}
            onError={setError}
            refetchData={refetchData}
          />
        </Row>
      </StickyBottom>
    </>
  );
}

BankingDetailsForm.Loading = Loading;

const Header = styled(Text.BodyL)`
  color: ${Colors.TEXT.DARK.PRIMARY};
`;
