import React, { ReactElement, useState } from "react";
import styled from "styled-components/native";
import { Money, Separator, useGlobalModal } from "@ailo/ui";
import { AgreementDetailsSection } from "./AgreementDetailsSection";
import { TenantDetailsSection } from "./TenantDetailsSection";
import { DepositDetailsSection } from "./DepositDetailsSection";
import { useGetTenancyDetailsForActionQuery } from "local/graphql";
import { LocalDate } from "@ailo/date";
import { TenancyModalForm } from "local/modals/TenancyModalForm";
import { VoidIngoingTenancyModal } from "local/modals/VoidTenancyConfirmModal";

export function DisplayTenancyDetails({
  tenancyId,
  managementId
}: {
  tenancyId: string;
  managementId: string;
}): ReactElement {
  const modal = useGlobalModal();
  const { data, loading } = useGetTenancyDetailsForActionQuery({
    variables: { id: tenancyId }
  });

  const [voidTenancyModalVisible, setVoidTenancyModalVisible] =
    useState<boolean>(false);

  if (loading) {
    return <></>;
  }

  const tenancy = data?.tenancy;

  if (!tenancy) {
    return <></>;
  }

  const {
    startDate,
    voidedAt,
    deposit,
    tenancyAgreements,
    scheduledRentReviewDate: rentReviewDate,
    rents,
    tenantships
  } = tenancy;

  const initialTenancyAgreement = tenancyAgreements?.items[0];
  const agreementStartDate = initialTenancyAgreement?.startDate;
  const agreementEndDate = initialTenancyAgreement?.fixedTermEndDate;

  const initialRent = rents.items[0];
  const {
    period: rentFrequency,
    amountInCents: rentAmount,
    effectiveDate: rentStartDate
  } = initialRent;

  const depositAmount = deposit?.amount.cents;
  const tenants = tenantships?.items;

  const editTenancyAbility: {
    canEdit: boolean;
    cannotEditTooltip?: string;
  } = {
    canEdit: true,
    cannotEditTooltip: undefined
  };
  if (voidedAt) {
    editTenancyAbility.canEdit = false;
    editTenancyAbility.cannotEditTooltip = "You cannot edit a voided tenancy";
  }
  if (startDate && new LocalDate(startDate).isSameOrBefore(LocalDate.today())) {
    editTenancyAbility.canEdit = false;
    editTenancyAbility.cannotEditTooltip =
      "The tenancy has started — please contact Support";
  }
  if (rents.pageInfo.total !== 1) {
    // This is to prevent editing migrated tenancies that migrated with a rent review
    editTenancyAbility.canEdit = false;
    editTenancyAbility.cannotEditTooltip =
      "The tenancy has started — please contact Support";
  }

  const handleEditPress = (): void => {
    modal.show(
      <TenancyModalForm
        managementId={managementId}
        tenancyId={tenancyId}
        onDismiss={modal.hide}
        onSuccess={modal.hide}
      />
    );
  };

  return (
    <>
      <AgreementDetailsSection
        tenancyId={tenancyId}
        voidedAt={voidedAt}
        agreementStartDate={agreementStartDate}
        agreementEndDate={agreementEndDate}
        rentReviewDate={rentReviewDate}
        rentFrequency={rentFrequency}
        rentAmount={rentAmount}
        rentStartDate={rentStartDate}
        handleEditPress={handleEditPress}
        handleVoidPress={() => {
          setVoidTenancyModalVisible(true);
        }}
        editTenancyAbility={editTenancyAbility}
      />

      {tenants && (
        <>
          <StyledSeparator />
          <TenantDetailsSection tenants={tenants} />
        </>
      )}

      <StyledSeparator />
      <DepositDetailsSection
        depositAmount={
          depositAmount ? Money.fromCents(depositAmount) : undefined
        }
      />

      <VoidIngoingTenancyModal
        tenancyId={tenancyId}
        tenancyStartDate={startDate ? LocalDate.from(startDate) : undefined}
        isVisible={voidTenancyModalVisible}
        setIsVisible={setVoidTenancyModalVisible}
      />
    </>
  );
}

const StyledSeparator = styled(Separator)`
  margin-top: 40px;
  margin-bottom: 32px;
  margin-left: 0;
  margin-right: -32px;
`;
