import React, { ReactElement, useCallback } from "react";
import { useForm } from "react-hook-form";
import { StyleProp, ViewStyle } from "react-native";
import { LeaseAgreementDetailsStep } from "./LeaseAgreementDetailsStep";
import { MultiStepForm } from "local/common";
import { NewTenancyFormData } from "./NewTenancyForm";
import { useUpdateIngoingTenancy } from "../useUpdateIngoingTenancy";
import { TenancyFragment } from "local/graphql";

export interface EditTenancyFormData
  extends Pick<
    NewTenancyFormData,
    | "leaseType"
    | "agreementStartDate"
    | "agreementEndDate"
    | "rentReviewDate"
    | "rentAmount"
    | "rentFrequency"
    | "rentStartDate"
  > {}

interface Props {
  tenancyId: string;
  defaultValues: EditTenancyFormData;
  previousTenancyEndDate?: string;
  onSuccess?(tenancy: TenancyFragment): void;
  onError?(): void;
  onCancel?(): void;
  style?: StyleProp<ViewStyle>;
}

export function EditTenancyFormWithData({
  tenancyId,
  defaultValues,
  previousTenancyEndDate,
  onSuccess,
  onError,
  onCancel,
  style
}: Props): ReactElement {
  const form = useForm<EditTenancyFormData>({
    defaultValues,
    mode: "onChange"
  });
  const { reset, formState, handleSubmit } = form;
  const { isSubmitting, isDirty } = formState;

  const submit = handleSubmit(
    useSubmit({
      tenancyId,
      onCompleted: async (tenancy: TenancyFragment) => {
        await onSuccess?.(tenancy);
        reset();
      },
      onError
    })
  );

  const cancel = useCallback(() => {
    reset();
    onCancel?.();
  }, [onCancel, reset]);

  const renderStepLeaseAgreement = useCallback(
    (visible: boolean): React.ReactElement => (
      <LeaseAgreementDetailsStep
        key={"New-Tenancy-Form-Step-LeaseAgreement"}
        form={form}
        display={visible}
        previousTenancyEndDate={previousTenancyEndDate || undefined}
        allowFileUploads={false}
      />
    ),
    [form, previousTenancyEndDate]
  );

  const steps = [
    {
      stepId: "LeaseAgreement",
      render: renderStepLeaseAgreement
    }
  ];

  return (
    <MultiStepForm
      style={style}
      title={"Edit Tenancy"}
      submitButtonText={"Update Tenancy"}
      stepValue={1}
      onChange={() => submit()}
      onCancel={cancel}
      isDirty={isDirty}
      isSubmitting={isSubmitting}
      steps={steps.map((step) => step.render)}
    />
  );
}

function useSubmit({
  tenancyId,
  onCompleted,
  onError
}: {
  tenancyId: string;
  onCompleted?: (tenancy: TenancyFragment) => void;
  onError?: () => void;
}): (data: EditTenancyFormData) => Promise<void> {
  const [editTenancy] = useUpdateIngoingTenancy({
    onCompleted,
    onError
  });

  return useCallback(
    async (data: EditTenancyFormData) => {
      const { agreementStartDate, rentFrequency, rentStartDate } = data;
      if (agreementStartDate == null) {
        throw new TypeError(
          "Agreement start date of a new tenancy cannot be null or undefined"
        );
      }

      if (rentStartDate == null) {
        throw new TypeError(
          "Rent start date of a new tenancy cannot be null or undefined"
        );
      }

      if (rentFrequency == null) {
        throw new TypeError(
          "Rent frequency of a new tenancy cannot be null or undefined"
        );
      }

      return editTenancy({
        tenancyId,
        formData: { ...data, rentStartDate, agreementStartDate, rentFrequency }
      });
    },
    [editTenancy, tenancyId]
  );
}
